import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Utils
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { formatDate, insertHTML } from '../../../Utils/Utils';
// Styles
import './styles.scss';

const propTypes = {
  citations: PropTypes.instanceOf(Object),
  ids: PropTypes.instanceOf(Array),
};

const ShortPubDetails = (props) => {
  const { citations, ids } = props;
  const publications = ids.map(id => citations[id]);

  const renderInfoItem = (title, value) => (
    <div className="short-pub-details__info">
      <span className="short-pub-details__subtitle">
        {`${title}:`}
      </span>
      <span className="short-pub-details__text">
        {` ${value}`}
      </span>
    </div>
  );

  const rowRenderer = (pub, index) => (
    <div
      key={`pub-${index}`}
      className="short-pub-details__item"
    >
      <div className="short-pub-details__item-wrap">
        <Link
          to={`${RELATIVE_PATH}/publication-details/${pub.publicationId}`}
          target="_blank"
          className="short-pub-details__name"
          dangerouslySetInnerHTML={insertHTML(pub.title)}
        />
        <div className="short-pub-details__info-wrap">
          <div className="short-pub-details__column">
            {
              pub.publicationDate ?
                renderInfoItem('Publication date', formatDate(pub.publicationDate)) : null
            }
            {
              pub.impactFactor >= 0 ?
                renderInfoItem('Impact factor', pub.impactFactor) : null
            }
            {
              pub.numberOfCitations ?
                renderInfoItem('Citations', pub.numberOfCitations) : null
            }
          </div>
          <div className="short-pub-details__column">
            {
              pub.mostImportantConcept && pub.mostImportantConcept.name ?
                renderInfoItem('Most important concept', pub.mostImportantConcept.name) : null
            }
            {
              pub.mostImportantCategories && pub.mostImportantCategories.length ?
                renderInfoItem('Most important categories', pub.mostImportantCategories.map(item => item).join(', ')) : null
            }
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <div className="short-pub-details">
      { publications.map(rowRenderer) }
    </div>
  );
};

ShortPubDetails.propTypes = propTypes;

export default ShortPubDetails;
