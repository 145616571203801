import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import defaultHeaderRenderer from 'react-virtualized/dist/es/Table/defaultHeaderRenderer';
import isEmpty from 'lodash.isempty';

// Components
import BarChart from '../../../graphics/BarChart/BarChart';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import SaveAsSetModal from '../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
import FindRelatedResult from '../../FindRelated/FindRelatedResult/FindRelatedResult';
import SetAnalysisGenesDetail from '../../../common/SetAnalysisGenesDetail/SetAnalysisGenesDetail';
import SetAnalysisRankingResult from '../../RankSelection/SetAnalysisRankingResult/SetAnalysisRankingResult';
import SetAnalysisNetworkAnalysisResult from '../../NetworkAnalysis/SetAnalysisNetworkAnalysisResult/SetAnalysisNetworkAnalysisResult';
import FindRelatedProteinOrRegulationResult from '../../FindRelated/FindRelatedProteinOrRegulationResult/FindRelatedProteinOrRegulationResult';
// Utils
import { analysisMethodEnum } from '../../FindRelated/FindRelatedResult/enums';
import { apiKeyEnum } from '../../FindRelated/FindRelatedProteinOrRegulationResult/enums';
// Store
import { selectedMethodSelector, getSelectedCategoryType } from './selectors';
import { initCategories, initTissues } from '../SetAnalysisMethodSelection/actions';
import { getShortConceptsDetailsForNetworkAnalysisAction } from '../../NetworkAnalysis/SetAnalysisNetworkAnalysisResult/actions';
import { getOpenKey as getOpenGeneDetailsKey } from '../../../common/SetAnalysisGenesDetail/selectors';
import { getBarChartOpenKeySelector } from '../../../graphics/BarChart/store/selectors';
import { closeGenesDetail as closeGenesDetailAction } from '../../../common/SetAnalysisGenesDetail/actions';
import { closeBarChartAction } from '../../../graphics/BarChart/store/actions';

const propTypes = {
  closeGenesDetail: PropTypes.func,
  closeBarChartDetail: PropTypes.func,
  selectedMethod: PropTypes.string,
  openGeneDetails: PropTypes.bool,
  openBarChart: PropTypes.bool,
  getShortConceptsDetailsForNetworkAnalysis: PropTypes.func,
  initTissuesAndCategories: PropTypes.func,
  selectedType: PropTypes.instanceOf(Object),
};

class SetAnalysisResults extends React.PureComponent {
  state = {
    addSetPopup: {
      show: false,
      ids: null,
      action: null,
      actionParams: null,
    },
  };

  componentDidMount = () => {
    const { initTissuesAndCategories } = this.props;
    initTissuesAndCategories();
  };

  openAddSetPopup = (page) => {
    this.setState({
      addSetPopup: {
        show: true,
        ids: page.map(item => (item.geneGi ? item.geneGi : item.candidateGi)),
      },
    });
  };

  openNetworkAnalysisAddSetPopup = (dataKey) => {
    const { getShortConceptsDetailsForNetworkAnalysis } = this.props;
    this.setState({
      addSetPopup: {
        show: true,
        ids: null,
        action: getShortConceptsDetailsForNetworkAnalysis,
        actionParams: dataKey,
      },
    });
  };

  closeAddSetPopup = () => {
    this.setState({
      addSetPopup: {
        show: false,
        ids: null,
        action: null,
        actionParams: null,
      },
    });
  };

  createHeaderRendererWithSaveAs = page => (params) => {
    const children = [defaultHeaderRenderer(params)];
    const clickHandler = (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      this.openAddSetPopup(page);
    };
    if (page.length) {
      children.push(
        <i
          role="presentation"
          key="saveAsSetActionBtn"
          style={{
            marginLeft: 20,
            verticalAlign: 4,
          }}
          onClick={clickHandler}
          className="fa fa-floppy-o"
        />
      );
    }
    return children;
  };

  createHeaderRendererWithSaveAsForNetworkAnalysis = page => (params) => {
    const children = [defaultHeaderRenderer(params)];
    const clickHandler = (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      this.openNetworkAnalysisAddSetPopup(params.dataKey);
    };
    if (page.length) {
      children.push(
        <i
          role="presentation"
          key="saveAsSetActionBtn"
          style={{
            marginLeft: 20,
            verticalAlign: 4,
          }}
          onClick={clickHandler}
          className="fa fa-floppy-o"
        />
      );
    }
    return children;
  };

  getSetAnalysisResultsContent = () => {
    const {
      selectedMethod,
      selectedType,
    } = this.props;
    const isTypeSelected = !isEmpty(selectedType);

    switch (selectedMethod) {
      case analysisMethodEnum.PATHWAY:
        return (
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.PATHWAY}
            title="Pathway analysis"
            CSVTitle="pathway_analysis"
            genesDetailAPIKey="pathwayTargetCandidates"
          />
        );
      case analysisMethodEnum.CATEGORY:
        return (
          isTypeSelected &&
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.CATEGORY}
            CSVTitle={`${selectedType.name}_analysis`}
            title={`${selectedType.name} analysis`}
            passedCategoryName={selectedType.name}
            genesDetailAPIKey="categoryTargetCandidates"
          />
        );
      case analysisMethodEnum.REGULATION:
        return (
          <FindRelatedProteinOrRegulationResult
            title="Regulation analysis"
            CSVTitle="regulation_analysis"
            apiKey={apiKeyEnum.regulation}
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
          />
        );
      case analysisMethodEnum.PROTEIN_COMPLEX:
        return (
          <FindRelatedProteinOrRegulationResult
            title="Protein Complex Analysis"
            CSVTitle="protein_complex_analysis"
            apiKey={apiKeyEnum.protein}
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
          />
        );
      case analysisMethodEnum.COMPOUND:
        return (
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.COMPOUND}
            title="Compound analysis"
            CSVTitle="compound_analysis"
            genesDetailAPIKey="compoundTargetCandidates"
          />
        );
      case analysisMethodEnum.PHENOTYPE:
        return (
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.PHENOTYPE}
            title="Phenotype analysis"
            CSVTitle="phenotype_analysis"
            genesDetailAPIKey="phenotypeTargetCandidates"
          />
        );
      case analysisMethodEnum.DISORDER:
        return (
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.DISORDER}
            title="Disorder analysis"
            CSVTitle="disorder_analysis"
            genesDetailAPIKey="disorderTargetCandidates"
          />
        );
      case analysisMethodEnum.METABOLITE:
        return (
          <FindRelatedResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
            analysisMethod={analysisMethodEnum.METABOLITE}
            title="Metabolite analysis"
            CSVTitle="metabolite_analysis"
            genesDetailAPIKey="metaboliteTargetCandidates"
          />
        );
      case analysisMethodEnum.NETWORK:
        return (
          <SetAnalysisNetworkAnalysisResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAsForNetworkAnalysis}
            handleSaveAllAsSetButton={() => this.openNetworkAnalysisAddSetPopup({ dataKey: 'ALL' })}
          />
        );
      case analysisMethodEnum.RANKING:
        return (
          <SetAnalysisRankingResult
            createHeaderRendererWithSaveAs={this.createHeaderRendererWithSaveAs}
          />
        );
      default:
        break;
    }
    return null;
  };

  render() {
    const {
      openGeneDetails,
      openBarChart,
      closeGenesDetail,
      closeBarChartDetail,
    } = this.props;

    const { addSetPopup } = this.state;

    return (
      <div className="row">
        <div className="flex-grid">
          <div id="analyses-result-col" className="col-1">
            {this.getSetAnalysisResultsContent()}
          </div>
        </div>
        {
          openGeneDetails &&
          <ModalComponent
            isOpen={openGeneDetails}
            closeCb={closeGenesDetail}
          >
            <SetAnalysisGenesDetail />
          </ModalComponent>
        }
        {
          openBarChart &&
          <ModalComponent
            isOpen={openBarChart}
            closeCb={closeBarChartDetail}
          >
            <BarChart
              yAxisLabel="Expression (TPM)"
              dataNameKey="tissue"
              source="expression-TPM"
            />
          </ModalComponent>
        }
        <SaveAsSetModal
          isOpen={addSetPopup.show}
          closeCb={this.closeAddSetPopup}
          idsForShortConceptDetails={addSetPopup.ids}
          getShortConceptDetailsFunction={addSetPopup.action}
          getShortConceptDetailsFunctionParams={addSetPopup.actionParams}
        />
      </div>
    );
  }
}

SetAnalysisResults.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedMethod: selectedMethodSelector(state),
    openGeneDetails: getOpenGeneDetailsKey(state),
    openBarChart: getBarChartOpenKeySelector(state),
    selectedType: getSelectedCategoryType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeGenesDetail() {
      dispatch(closeGenesDetailAction());
    },
    closeBarChartDetail() {
      dispatch(closeBarChartAction());
    },
    getShortConceptsDetailsForNetworkAnalysis(data) {
      dispatch(getShortConceptsDetailsForNetworkAnalysisAction(data));
    },
    initTissuesAndCategories() {
      dispatch(initTissues());
      dispatch(initCategories());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAnalysisResults);
