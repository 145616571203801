import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { geneDetailsSetChapterAction } from '../../actions';
import {
  getGeneDetailsExternalLinksAction,
  setGeneDetailsExternalLinksAction,
  toggleGeneDetailsExternalLinksLoaderAction,
  throwGeneDetailsExternalLinksErrorAction,
} from './reducer';

export function* getExternalLinksWorker({ payload }) {
  try {
    yield put(toggleGeneDetailsExternalLinksLoaderAction(true));
    const { data } = yield call(Api.getGeneDetailsExternalLinks, payload);
    yield put(setGeneDetailsExternalLinksAction(data));
    yield put(geneDetailsSetChapterAction('external-links'));
    yield put(toggleGeneDetailsExternalLinksLoaderAction(false));
  } catch (e) {
    yield put(throwGeneDetailsExternalLinksErrorAction(e.message));
    yield put(toggleGeneDetailsExternalLinksLoaderAction(false));
  }
}

function* externalLinksSaga() {
  yield takeLatest(getGeneDetailsExternalLinksAction, getExternalLinksWorker);
}

export default externalLinksSaga;
