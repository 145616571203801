import { createSelector } from 'reselect';

import {filterPaginatedData, sort} from '../../../Utils/Utils';
import { PAGE_SIZE } from './constants';
import {appllyFilters} from './utils';

const sorting = state => state.getIn(['rankSelectionResultReducer', 'sorting']);
const content = state => state.getIn(['rankSelectionResultReducer', 'result', 'content']);
const totalPages = state => state.getIn(['rankSelectionResultReducer', 'result', 'totalPages']);
const totalElements = state => state.getIn(['rankSelectionResultReducer', 'result', 'totalElements']);
const loading = state => state.getIn(['rankSelectionResultReducer', 'loading']);
const pageNumber = state => state.getIn(['rankSelectionResultReducer', 'result', 'page']);
const error = state => state.getIn(['rankSelectionResultReducer', 'error']);
const filters = state => state.getIn(['rankSelectionResultReducer', 'filters']);

export const rowExample = createSelector(
  content,
  (_content, _sorting) => {
    const data = _sorting ? sort(_content, _sorting.toJS()) : _content;
    if (data) {
      return data.toJS()[0];
    } else {
      return {};
    }
  }
);

export const allRankData = createSelector(
  content,
  sorting,
  filters,
  (_content, _sorting, _filters) => sort(appllyFilters(_content, _filters), _sorting.toJS()).toJS()
);

export const allRankIds = createSelector(
  content,
  sorting,
  filters,
  (_content, _sorting, _filters) => sort(appllyFilters(_content, _filters), _sorting.toJS())
    .toJS()
    .map(d => d.geneGi)
);

export const currentPage = createSelector(
  allRankData,
  pageNumber,
  (content, pageNumber) => filterPaginatedData(content, pageNumber, PAGE_SIZE)
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getTotalItems = createSelector(
  totalElements,
  data => data
);

export const getLoadingKey = createSelector(
  loading,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getFilters = createSelector(
  filters,
  data => data.toJS()
);
