import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircosDiagram from '../../../graphics/CircosDiagram/CircosDiagram';
import { getDisorderDiagramSelector } from '../redux/selectors';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  diagram: PropTypes.instanceOf(Object),
};

class ChordDiagram extends React.Component {
  state = {
    showInfoText: false,
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      diagram: {
        data,
        chords,
      },
    } = this.props;
    const { showInfoText } = this.state;

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Chord diagram
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              The Disease chord diagram shows the gene interaction network that is involved in the top 25 diseases (based on number of publications).
              The direct gene - disease relations are explicitly excluded from this view as this section focuses on the interacting genes.
              As not all of the top 25 phenotypes may have associations via the gene’s interaction network, the number of phenotypes shown is between 0 and 25.
            </p>
            <p>
              The ring segments of the chord diagram show the selected gene (gold color), the interacting genes (orange color) and the associated diseases (red color).
              The inner ring shows the associations that exist between these genes and diseases.
              By selecting a segment in the chord diagram the associated relations are highlighted.
              By hovering over a chord in the diagram, the type of relation is shown.
            </p>
          </div>
        }
        {
          data.length && chords.length ?
            <CircosDiagram
              targetGene={{ id: gene, name: 'name' }}
              data={data}
              chords={chords}
              id="chord-diagram-disease-disorders"
              geneName={geneName}
              chartName="Disease"
            /> :
            <span className="gene-details__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

ChordDiagram.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    diagram: getDisorderDiagramSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(ChordDiagram);

