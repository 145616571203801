import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Icons
import { BiCheck } from 'react-icons/bi';
// Components
import OrthologsTable from './OrthologsTable';
import Loader from '../../common/Loader/Loader';
import NoData from '../../common/NoData/NoData';
import Error from '../../common/Error/Error';
import SelectInput from '../../common/Inputs/SelectInput/SelectInput';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { loadNextChapterAction } from '../actions';
// Helpers
import { getSpeciesOptions } from './helpers';
// Styles
import './styles.scss';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  allData: PropTypes.instanceOf(Array),
  filteredData: PropTypes.instanceOf(Array),
  dataPerPage: PropTypes.instanceOf(Object),
  sorting: PropTypes.instanceOf(Object),
  sort: PropTypes.instanceOf(Object),
  getOrthologs: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  filtersAreEmpty: PropTypes.bool,
  setFilters: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
};

const OrthologsChapter = (props) => {
  const {
    gene,
    geneName,
    allData,
    filteredData,
    dataPerPage,
    filters,
    filtersAreEmpty,
    activePage,
    sort,
    sorting,
    totalPages,
    changePage,
    error,
    loading,
    setFilters,
    getOrthologs,
    itsNewConcept,
    loadNextChapter,
  } = props;

  useEffect(() => {
    if (itsNewConcept || dataPerPage.length) {
      getOrthologs(gene);
    } else {
      loadNextChapter('protein-location');
    }
  }, []);

  const [showInfoText, setShowInfoText] = useState(false);

  const handleFilters = (option, value) => {
    setFilters({ value, option: option || [] });
  };

  const selectorStyles = {
    valueContainer: base => ({
      ...base,
      maxHeight: '60px',
      overflow: 'auto',
    }),
  };

  const booleanFiltersOptions = [
    {
      value: true,
      label: <BiCheck size={18} />,
      name: 'true',
    },
    {
      value: false,
      label: '(empty)',
      name: 'false',
    },
  ];

  return (
    <div className="orthologs">
      <div className="gene-details-section-title title2">
        <span>Orthologs</span>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          onClick={() => setShowInfoText(!showInfoText)}
        />
      </div>
      {
        showInfoText &&
        <div className="gene-details-info__main">
          <p>
            This section shows the ortholog-pairs extracted from OMA (<a target="blank" href="https://omabrowser.org/oma/home/">OMA Orthology database</a>) and Ensembl
            (<a target="blank" href="https://www.ensembl.org/index.html">https://www.ensembl.org/index.html</a>).
            This table contains pairwise orthologs (Human to species) where species are limited to
            (RAT/MOUSE/RABBIT/MACAQUE(CRAB-eating/Rhesus). Protein sequences from OMA and Ensembl are mapped to Uniprot
            protein sequences: if there was a perfect match the Uniprot ID/accession number was given for the human
            protein and the Uniprot accession number for the ortholog  (other species). Similarity and identity scores
            shown in this section are calculated by Euretos using Smith-Waterman with Blosum 62 score matrix. In all
            alignments we compare human proteins vs other species. We calculate similarity and identity based on the
            length of the human protein. We run Phobius to predict the transmembrane (TM) domains in each protein.
            Based on this prediction, we calculate percentage extracellular sequence for each protein as well as percentage
            identity /similarity only for the extracellular domain.
          </p>
        </div>
      }
      <div
        className="orthologs__chapter"
      >
        {
          allData.length > 0 && !loading &&
          <>
            <div className="orthologs__filters">
              <span className="single-cell-selector__label">Select: </span>
              <SelectInput
                placeholder="Species"
                closeOnSelect={true}
                isMulti={true}
                bordered={true}
                styles={selectorStyles}
                defaultValue={filters.species}
                customClassName="ortholog-selector"
                options={getSpeciesOptions(allData)}
                onSelect={option => handleFilters(option, 'species')}
              />
              <SelectInput
                options={booleanFiltersOptions}
                placeholder="OMA"
                closeOnSelect={true}
                isMulti={true}
                bordered={true}
                customClassName="ortholog-selector"
                styles={selectorStyles}
                defaultValue={filters.OMA}
                onSelect={option => handleFilters(option, 'predictedByOma')}
              />
              <SelectInput
                options={booleanFiltersOptions}
                placeholder="ENSEMBL"
                closeOnSelect={true}
                isMulti={true}
                bordered={true}
                customClassName="ortholog-selector"
                styles={selectorStyles}
                defaultValue={filters.ENSEMBL}
                onSelect={option => handleFilters(option, 'predictedByEnsembl')}
              />
            </div>
            <OrthologsTable
              dataPerPage={dataPerPage}
              allData={allData}
              filteredData={filteredData}
              error={error}
              loading={loading}
              geneName={geneName}
              sorting={sorting}
              tableWidth={2500}
              totalPages={totalPages}
              changePage={changePage}
              sort={sort}
              activePage={activePage}
            />
          </>
        }
        <Loader isLoading={loading} />
        <NoData
          show={dataPerPage.length === 0 && !loading && filtersAreEmpty}
          customClassName="orthologs__no-data"
        />
        <Error show={error && !loading} error={error} />
      </div>
    </div>
  );
};

OrthologsChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    allData: SELECTORS.getAllOrthologsDataSelector(state),
    filteredData: SELECTORS.getFilteredOrthologsDataSelector(state),
    dataPerPage: SELECTORS.getOrthologsDataSelector(state),
    filters: SELECTORS.getOrthologFiltersSelector(state),
    filtersAreEmpty: SELECTORS.getOrthologFiltersAreEmptySelector(state),
    loading: SELECTORS.getOrthologsLoadingSelector(state),
    error: SELECTORS.getOrthologsErrorSelector(state),
    sorting: SELECTORS.getOrthologsSorting(state),
    totalPages: SELECTORS.getOrthologTotalPagesPagesSelector(state),
    activePage: SELECTORS.getOrthologsPageSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrthologs(id) {
      dispatch(ACTIONS.getGeneDetailsOrthologsAction(id));
    },
    setFilters(data) {
      dispatch(ACTIONS.setOrthologsFiltersAction(data));
    },
    sort(data) {
      dispatch(ACTIONS.sortGeneDetailsOrthologsAction(data));
    },
    changePage(page) {
      dispatch(ACTIONS.changePageGeneDetailsOrthologsAction(page));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrthologsChapter);
