import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import TissuesSelect from '../../common/TissuesSelect/TissuesSelect';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
import ConceptSearchModal from '../../../Concept/ConceptSearchModal/ConceptSearchModal';
import Concept from '../../../common/ConceptItem/Concept';
// Store
import {
  closeRankingModal,
  menuTissuesOpen,
  menuTissuesClose,
  setRankingType as setRankingTypeAction,
  setScoringFormula as setScoringFormulaAction,
  selectTissueType as selectTissueTypeAction,
  selectPenaltyTissueName as selectPenaltyTissueNameAction,
  setDefaultScoringFormula as setDefaultScoringFormulaAction,
} from '../../common/SetAnalysisMethodSelection/actions';
import {
  getMenuTissuesKey,
  getTissuesForMenu,
  getTissuesTypes,
  getSelectedSource,
  getSelectedTissueType,
  getMenuTissuesPenaltyKey,
  getTissuesForPenaltyMenu,
  getSelectedTissueOptionSelector,
  getSelectedPenaltyTissuesSelector,
  getSelectedRankingConcept,
} from '../../common/SetAnalysisMethodSelection/selectors';
import {
  applyMarkerSettings as applyMarkerSettingsAction,
  applyTargetSettings as applyTargetSettingsAction,
  saveRankingConcept as saveRankingConceptAction,
  validateFormulaRequest as validateFormulaRequestAction,
  removeRankingConcept as removeRankingConceptAction,
} from './actions';
import {
  getCurrentScoringFormula,
  getDefaultScoringFormula,
  getValidFormulaKey,
} from './selectors';
// Utils
import { getCaret, setCaretPosition } from '../../../Utils/Utils';
// Styles
import './RankingMarkerSettings.scss';

const propTypes = {
  saveRankingConcept: PropTypes.func,
  removeRankingConcept: PropTypes.func,
  selectTissueType: PropTypes.func,
  selectPenaltyTissueName: PropTypes.func,
  selectedSource: PropTypes.string,
  setScoringFormula: PropTypes.func,
  setDefaultScoringFormula: PropTypes.func,
  currentScoringModel: PropTypes.string,
  validateFormulaRequest: PropTypes.func,
  applyMarkerSettings: PropTypes.func,
  setRankingType: PropTypes.func,
  closeModal: PropTypes.func,
  rankingMarker: PropTypes.bool,
  rankingTarget: PropTypes.bool,
  applyTargetSettings: PropTypes.func,
  tissuesItems: PropTypes.instanceOf(Array),
  selectedPenaltyTissues: PropTypes.instanceOf(Array),
  defaultScoringModel: PropTypes.string,
  validFormulaKey: PropTypes.bool,
  selectedTissue: PropTypes.instanceOf(Object),
  rankingConcept: PropTypes.instanceOf(Object),
  selectedTissueType: PropTypes.string,
};

const RankingMarkerSettings = (props) => {
  const {
    saveRankingConcept,
    selectTissueType,
    selectPenaltyTissueName,
    setScoringFormula,
    currentScoringModel,
    validateFormulaRequest,
    setDefaultScoringFormula,
    rankingMarker,
    rankingTarget,
    applyMarkerSettings,
    applyTargetSettings,
    setRankingType,
    closeModal,
    rankingConcept,
    removeRankingConcept,
    validFormulaKey,
    defaultScoringModel,
    tissuesItems,
    selectedPenaltyTissues,
    selectedSource,
    selectedTissue,
    selectedTissueType,
  } = props;

  const [advancedOptions, setAdvancedOption] = useState(false);
  const [caretPos, setCaretPos] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);

  const inputRef = useRef('');

  const diseaseSelected = (item) => {
    saveRankingConcept(item);
    setShowConceptSearchModal(false);
  };

  const handleSelectTissueType = (type) => {
    selectTissueType(type);
  };

  const handleSelectPenaltyTissueName = (value) => {
    selectPenaltyTissueName(value);
  };

  const showAdvancedOptions = () => {
    setAdvancedOption(!advancedOptions);
  };

  const changeScoringFormula = (e) => {
    setScoringFormula(e.target.value);
    setCaretPos(getCaret(e.target));
    setValidated(false);
  };

  const handleSetCaretPosition = (target) => {
    setCaretPos(getCaret(target));
  };

  const handleGetDefaultScoringFormula = () => {
    setDefaultScoringFormula();
  };

  const addPartToFormula = (e) => {
    if (e.target.nodeName !== 'BUTTON') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const $input = inputRef.current;
    const inputValue = currentScoringModel;
    const partValue = ` ${e.target.value} `;
    const pos = typeof (caretPos) === 'number' ? caretPos : inputValue.length;

    const newFormula = inputValue.substring(0, pos) + partValue + inputValue.substring(pos, inputValue.length);
    setScoringFormula(newFormula);

    setCaretPosition($input, pos + partValue.length);
    setValidated(false);

    setTimeout(() => {
      handleSetCaretPosition($input);
    }, 0);
  };

  const validateFormula = () => {
    validateFormulaRequest();
    setValidated(true);
  };

  const saveSettings = () => {
    if (rankingMarker) {
      applyMarkerSettings();
      setRankingType('rank as marker');
    }
    if (rankingTarget) {
      applyTargetSettings();
      setRankingType('rank as target');
    }
    closeModal();
  };

  const isBtnDisabled = () => {
    if (!rankingConcept) return true;
    if (rankingMarker) {
      if (!selectedTissueType || !selectedSource || (currentScoringModel !== defaultScoringModel && !validFormulaKey)) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={classNames('ranking-settings-modal', {
      'ranking-settings-modal--rank-as-marker': rankingMarker,
      'ranking-settings-modal--rank-as-target': rankingTarget,
    })}
    >
      <div className="flex-grid">
        <div className="col-1 text-center">
          <h3 className="title3">Ranking Settings</h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <div className="label">Concept search:</div>
          <ConceptSearchForm
            onSubmitCallback={() => setShowConceptSearchModal(true)}
          />
          {
            rankingConcept &&
              <div className="flex-grid">
                <div className="col-1 text-center row">
                  <Concept
                    noInfoButton={true}
                    concept={rankingConcept}
                    deleteCb={removeRankingConcept}
                  />
                </div>
              </div>
          }
        </div>
      </div>
      {
        rankingMarker &&
          <>
            <div className="flex-grid">
              <div className="col-1 text-left">
                <div className="label">Tissue:</div>
                <TissuesSelect
                  onSelect={handleSelectTissueType}
                  options={tissuesItems}
                  placeholder="Select tissue"
                  disabled={false}
                  dropDownHeight={120}
                  value={selectedTissue}
                />
              </div>
            </div>
            {
              selectedSource &&
              <div className="flex-grid">
                <div className="col-1 text-right m-10">
                  <span
                    role="presentation"
                    className="link"
                    onClick={showAdvancedOptions}
                  >
                    {advancedOptions ? 'Hide advanced options' : 'Show advanced options'}
                  </span>
                </div>
              </div>
            }
            {
              selectedSource && advancedOptions &&
              <div className="penalty-tissues">
                <div className="label">Penalty tissues (optional):</div>
                <TissuesSelect
                  onSelect={handleSelectPenaltyTissueName}
                  options={tissuesItems.filter(t => t.value !== selectedTissue.value)}
                  placeholder="Select tissue"
                  disabled={false}
                  dropDownHeight={120}
                  isMulti={true}
                  value={selectedPenaltyTissues}
                />
              </div>
            }
            {
              selectedSource && advancedOptions &&
              <div>
                <div className="flex-grid">
                  <div className="col-1 text-left">
                    <div className="label">Adjust scoring formula:</div>
                  </div>
                </div>
                <div className="flex-grid">
                  <div className="col-5 pl-5">
                    <input
                      value={currentScoringModel}
                      ref={inputRef}
                      onClick={(e) => { handleSetCaretPosition(e.target); }}
                      onChange={(e) => { changeScoringFormula(e); }}
                      className="input"
                      type="text"
                    />
                  </div>
                  <div className="col-1">
                    <button
                      onClick={validateFormula}
                      className="btn btn-input-addon"
                    >
                      Validate
                    </button>
                  </div>
                  <div className="col-1 text-center">
                    <span
                      role="presentation"
                      onClick={handleGetDefaultScoringFormula}
                      className="link lh-35"
                    >
                  Get default
                    </span>
                  </div>
                </div>
                {
                  currentScoringModel !== defaultScoringModel &&
                  <div className="flex-grid">
                    <div className="col-1 text-center m-10">
                      {validFormulaKey && <span className="green">Formula is valid</span>}
                      {(!validFormulaKey && !validated) && <span className="gray">Validate formula, please</span>}
                      {(!validFormulaKey && validated) && <span className="red">Formula is invalid</span>}
                    </div>
                  </div>
                }
                <div className="flex-grid">
                  <div
                    role="presentation"
                    className="col-1 text-left m-10"
                    onClick={(e) => { addPartToFormula(e); }}
                  >
                    <button
                      title="Expression value in target tissue"
                      className="btn"
                      value="Et"
                    >
                      Et
                    </button>
                    <button
                      title="10th (highest) percentile expression value in base tissues (all other tissues)"
                      className="btn"
                      value="E10th"
                    >
                      E10th%
                    </button>
                    <button
                      title="Median expression value in base tissues"
                      className="btn"
                      value="Em"
                    >
                      Em
                    </button>
                    <button
                      title="Median expression value in no-go tissues"
                      className="btn"
                      value="Enogo"
                    >
                      E.nogo
                    </button>
                    <button
                      title="Median expression value in penalty tissues"
                      className="btn"
                      value="Epen"
                    >
                      E.pen
                    </button>
                    <button
                      title="90th percentile expression value in base tissues (so lowest 10%) [not used in default algorithm, but available for custom adjustments]"
                      className="btn"
                      value="E90th"
                    >
                      E90th%
                    </button>
                  </div>
                </div>
              </div>
            }
          </>
      }
      <div className="flex-grid ranking-settings-modal__buttons-block">
        <div className="col-1 text-center row">
          <button
            disabled={isBtnDisabled()}
            className="button button-primary mr-15"
            onClick={saveSettings}
          >
              Continue
          </button>
          <button
            type="button"
            className="btn"
            onClick={closeModal}
          >
              Cancel
          </button>
        </div>
      </div>
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={diseaseSelected}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </div>
  );
};

RankingMarkerSettings.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    rankingConcept: getSelectedRankingConcept(state),
    openMenu: getMenuTissuesKey(state),
    openMenuPenalty: getMenuTissuesPenaltyKey(state),
    tissuesItems: getTissuesForMenu(state),
    tissuePenaltyItems: getTissuesForPenaltyMenu(state),
    types: getTissuesTypes(state),
    selectedSource: getSelectedSource(state),
    selectedTissueType: getSelectedTissueType(state),
    currentScoringModel: getCurrentScoringFormula(state),
    defaultScoringModel: getDefaultScoringFormula(state),
    validFormulaKey: getValidFormulaKey(state),
    selectedTissue: getSelectedTissueOptionSelector(state),
    selectedPenaltyTissues: getSelectedPenaltyTissuesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal() {
      dispatch(closeRankingModal());
    },
    menuClose() {
      dispatch(menuTissuesClose());
    },
    menuOpen() {
      dispatch(menuTissuesOpen());
    },
    selectPenaltyTissueName(data) {
      dispatch(selectPenaltyTissueNameAction(data));
    },
    selectTissueType(data) {
      dispatch(selectTissueTypeAction(data));
    },
    setRankingType(data) {
      dispatch(setRankingTypeAction(data));
    },
    saveRankingConcept(data) {
      dispatch(saveRankingConceptAction(data));
    },
    applyMarkerSettings() {
      dispatch(applyMarkerSettingsAction());
    },
    applyTargetSettings() {
      dispatch(applyTargetSettingsAction());
    },
    setScoringFormula(data) {
      dispatch(setScoringFormulaAction(data));
    },
    setDefaultScoringFormula() {
      dispatch(setDefaultScoringFormulaAction());
    },
    validateFormulaRequest() {
      dispatch(validateFormulaRequestAction());
    },
    removeRankingConcept() {
      dispatch(removeRankingConceptAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingMarkerSettings);
