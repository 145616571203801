import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import BoxPlotChart from '../../../../graphics/BoxPlotChart/BoxPlotChart';
import BeesWarmGraph from '../../../../graphics/BeesWarmGraph/BeesWarmGraph';
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
import Spinner from '../../../../common/Spinner/Spinner';
import NoData from '../../../../common/NoData/NoData';
import CellTypeClusterModal from './components/CellTypeClusterModal/CellTypeClusterModal';
// Store
import * as ACTIONS from '../../reducer';
import * as SELECTORS from '../../selectors';
import { getCellTypeClusterModalOpenSelector } from './components/CellTypeClusterModal/store/selectors';
// Constants
import {
  graphTypes,
  sortingHpaOptions,
  sortingHpaByTissuesOptions,
  visibleHpaChartEnums,
  visibleHpaChartOptions
} from '../../constants';
// Utils
import { getChartName, getChartTitle, getCsvName, scaleTransformData } from '../../utils';

const propTypes = {
  hpaLoading: PropTypes.bool,
  hpaCellTypes: PropTypes.instanceOf(Array),
  hpaTissues: PropTypes.instanceOf(Array),
  hpaFilteringOptions: PropTypes.instanceOf(Array),
  hpaSelectedSortingOption: PropTypes.instanceOf(Object),
  setSelectedHpaSortingOption: PropTypes.func,
  hpaSelectedFilteringOptions: PropTypes.instanceOf(Array),
  setSelectedHpaFilteringOptions: PropTypes.func,
  hpaPerTissuesSelectedSortingOption: PropTypes.instanceOf(Object),
  setSelectedHpaPerTissuesSortingOption: PropTypes.func,
  setSelectedHpaPerTissuesFilteringOptions: PropTypes.func,
  hpaPerTissuesFilteringOptions: PropTypes.instanceOf(Array),
  hpaPerTissuesSelectedFilteringOptions: PropTypes.instanceOf(Array),
  hpaPerTissuesFilteringPerCellTypeOptions: PropTypes.instanceOf(Array),
  hpaPerTissuesSelectedFilteringPerCellTypeOptions: PropTypes.instanceOf(Array),
  setSelectedHpaPerTissuesFilteringPerCellTypeOptions: PropTypes.func,
  hpaCellLegendData: PropTypes.instanceOf(Array),
  hpaTissuesLegendData: PropTypes.instanceOf(Array),
  geneName: PropTypes.string,
  hpaPerCellTypeCustoms: PropTypes.instanceOf(Object),
  hpaPerTissuesCustoms: PropTypes.instanceOf(Object),
  switchOpenModal: PropTypes.func,
  changeGraphTransform: PropTypes.func,
  setVisibleHpaChartOption: PropTypes.func,
  hpaVisibleChartOption: PropTypes.string,
  setSelectedCells: PropTypes.func,
  setTissuesSelectionMode: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  isCellTypeClusterModalModalOpen: PropTypes.bool,
};

const HpaCharts = (props) => {
  const {
    hpaLoading,
    hpaCellTypes,
    hpaTissues,
    hpaFilteringOptions,
    hpaSelectedSortingOption,
    setSelectedHpaSortingOption,
    hpaSelectedFilteringOptions,
    setSelectedHpaFilteringOptions,
    hpaPerTissuesSelectedSortingOption,
    setSelectedHpaPerTissuesSortingOption,
    setSelectedHpaPerTissuesFilteringOptions,
    hpaPerTissuesFilteringOptions,
    hpaPerTissuesSelectedFilteringOptions,
    hpaPerTissuesFilteringPerCellTypeOptions,
    hpaPerTissuesSelectedFilteringPerCellTypeOptions,
    setSelectedHpaPerTissuesFilteringPerCellTypeOptions,
    hpaCellLegendData,
    hpaTissuesLegendData,
    geneName,
    hpaPerCellTypeCustoms,
    hpaPerTissuesCustoms,
    switchOpenModal,
    changeGraphTransform,
    setVisibleHpaChartOption,
    hpaVisibleChartOption,
    setSelectedCells,
    setTissuesSelectionMode,
    unsetTissuesSelectionMode,
    isCellTypeClusterModalModalOpen,
  } = props;

  const [isHpaPerTissuesDataFixed, setHpaPerTissuesDataFixed] = useState(false);

  return (
    <>
      <Spinner
        isLoading={hpaLoading}
        containerClassName="row text-center"
      />
      {
        ((hpaCellTypes && hpaCellTypes.length > 0) || (hpaTissues && hpaTissues.length > 0)) &&
        <>
          <div className="gene-expression-visible-hpa-chart__selector-block">
            <span>Plot on x-axis: </span>
            <SelectInput
              options={visibleHpaChartOptions}
              placeholder="Select"
              closeOnSelect={true}
              bordered={true}
              customClassName="gene-expression-sort-methods__selector"
              styles={{menuPortal: base => ({...base, zIndex: 1})}}
              onSelect={option => {
                setHpaPerTissuesDataFixed(false);
                setVisibleHpaChartOption(option.value);
              }}
              defaultValue={visibleHpaChartOptions[0]}
            />
          </div>

          {hpaVisibleChartOption === visibleHpaChartEnums.CELL_TYPE &&
            <div>
              <div className="gene-expression-sort-methods">
                <div
                  className="gene-expression-sort-methods__selector-block gene-expression-sort-methods__selector-block--sorting">
                  <span className="gene-expression-sort-methods__label">Sorting: </span>
                  <SelectInput
                    options={sortingHpaOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{menuPortal: base => ({...base, zIndex: 1})}}
                    onSelect={option => setSelectedHpaSortingOption(option)}
                    defaultValue={sortingHpaOptions.find(o => o.value === hpaSelectedSortingOption.value)}
                  />
                </div>
                <div
                  className="gene-expression-sort-methods__selector-block gene-expression-sort-methods__selector-block--filtering">
                  <span className="gene-expression-sort-methods__label">Cell Type Group: </span>
                  <SelectInput
                    isMulti={true}
                    options={hpaFilteringOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{menuPortal: base => ({...base, zIndex: 1})}}
                    onSelect={options => setSelectedHpaFilteringOptions(options)}
                    defaultValue={hpaSelectedFilteringOptions}
                  />
                </div>
              </div>
              {
                hpaCellTypes && hpaCellTypes.length > 0 &&
                <BoxPlotChart
                  id="hpa-tissues-box-plot"
                  classMode="cell-line-boxplot"
                  sampleName="cellType"
                  sampleGroupName="cellTypeGroup"
                  sampleMeasureKey="ntpm"
                  yAxisText="nTPM"
                  height={900}
                  customColoring={true}
                  customWrapClass="gene-expression-boxplot-wrapper boxplot-chart--hpa"
                  legendData={hpaCellLegendData}
                  sortByDefault={false}
                  isNeededTransform={true}
                  showExpressionSortSelector={true}
                  chartTitle={getChartTitle(graphTypes.HPA_PER_CELL_TYPE, geneName)}
                  expressionName={getChartName(graphTypes.HPA_PER_CELL_TYPE, geneName)}
                  boxPlotTransform={hpaPerCellTypeCustoms.dataTransform}
                  selectedHpaSortingOption={hpaSelectedSortingOption}
                  setSelectedHpaSortingOption={setSelectedHpaSortingOption}
                  changeBoxPlotTransform={e => changeGraphTransform(e, graphTypes.HPA_PER_CELL_TYPE, 'dataTransform')}
                  filteringHpaOptions={hpaFilteringOptions}
                  selectedFilteringHpaOptions={hpaSelectedFilteringOptions}
                  setSelectedHpaFilteringOptions={setSelectedHpaFilteringOptions}
                  tissueData={scaleTransformData(hpaPerCellTypeCustoms.dataTransform, hpaCellTypes, 'ntpm')}
                  showLegend={true}
                  isCsvDownloadingAllowed={true}
                  csvName={getCsvName(graphTypes.HPA_PER_CELL_TYPE, geneName)}
                  selectedCells={hpaPerCellTypeCustoms.selectedCells}
                  setSelectedCells={e => setSelectedCells(e, graphTypes.HPA_PER_CELL_TYPE)}
                  unsetTissuesSelectionMode={() => unsetTissuesSelectionMode(graphTypes.HPA_PER_CELL_TYPE)}
                  tissuesSelectionMode={hpaPerCellTypeCustoms.tissuesSelectionMode}
                  setTissuesSelectionMode={() => setTissuesSelectionMode(graphTypes.HPA_PER_CELL_TYPE)}
                  csvColumns={[
                    {
                      label: 'Cell Type',
                      dataKey: 'cellType',
                    },
                    {
                      label: 'Cell Type Group',
                      dataKey: 'cellTypeGroup'
                    },
                    {
                      label: 'nTPM',
                      dataKey: 'ntpm',
                    },
                  ]}
                  calculateCustomMargin={(isChartDownloading) => {
                    return (
                      {
                        right: 150,
                        bottom: 150,
                        top: isChartDownloading ? 55 : 10,
                        left: isChartDownloading ? 395 : 100,
                      }
                    );
                  }
                  }
                />
              }
            </div>
          }
          {
            hpaVisibleChartOption === visibleHpaChartEnums.TISSUES &&
            <div>
              <div className="gene-expression-sort-methods">
                <div
                  className="gene-expression-sort-methods__selector-block gene-expression-sort-methods__selector-block--sorting">
                  <span className="gene-expression-sort-methods__label">Sorting: </span>
                  <SelectInput
                    options={sortingHpaByTissuesOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{menuPortal: base => ({...base, zIndex: 1})}}
                    onSelect={option => {
                      setHpaPerTissuesDataFixed(false);
                      setSelectedHpaPerTissuesSortingOption(option);
                    }}
                    defaultValue={sortingHpaByTissuesOptions.find(o => o.value === hpaPerTissuesSelectedSortingOption.value)}
                  />
                </div>
                <div
                  className="gene-expression-sort-methods__selector-block gene-expression-sort-methods__selector-block--filtering-by-cell-type-groups">
                  <span className="gene-expression-sort-methods__label">Cell Type Group: </span>
                  <SelectInput
                    isMulti={true}
                    options={hpaPerTissuesFilteringOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{menuPortal: base => ({...base, zIndex: 1})}}
                    defaultValue={hpaPerTissuesSelectedFilteringOptions}
                    onSelect={options => {
                      setHpaPerTissuesDataFixed(false);
                      if (hpaPerTissuesSelectedFilteringPerCellTypeOptions.length > 0) {
                        setSelectedHpaPerTissuesFilteringPerCellTypeOptions([]);
                      }
                      setSelectedHpaPerTissuesFilteringOptions(options);
                    }}
                  />
                </div>
                <div
                  className="gene-expression-sort-methods__selector-block gene-expression-sort-methods__selector-block--filtering-by-cell-type">
                  <span className="gene-expression-sort-methods__label">Cell Type: </span>
                  <SelectInput
                    isMulti={true}
                    options={hpaPerTissuesFilteringPerCellTypeOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{menuPortal: base => ({...base, zIndex: 1})}}
                    defaultValue={hpaPerTissuesSelectedFilteringPerCellTypeOptions}
                    onSelect={options => {
                      setHpaPerTissuesDataFixed(false);
                      if (hpaPerTissuesSelectedFilteringOptions.length > 0) {
                        setSelectedHpaPerTissuesFilteringOptions([]);
                      }
                      setSelectedHpaPerTissuesFilteringPerCellTypeOptions(options);
                    }}
                  />
                </div>
              </div>
              {
                hpaTissues && hpaTissues.length > 0 &&
                <BeesWarmGraph
                  showLegend={true}
                  yAxisText="nTPM"
                  geneName={geneName}
                  data={hpaTissues}
                  legendData={hpaTissuesLegendData}
                  boxPlotTransform={hpaPerTissuesCustoms.dataTransform}
                  selectedSortingOption={hpaPerTissuesSelectedSortingOption}
                  setSelectedSortingOption={setSelectedHpaPerTissuesSortingOption}
                  filteringOptions={hpaPerTissuesFilteringOptions}
                  selectedFilteringOptions={hpaPerTissuesSelectedFilteringOptions}
                  setSelectedFilteringOptions={setSelectedHpaPerTissuesFilteringOptions}
                  filteringPerCellTypeOptions={hpaPerTissuesFilteringPerCellTypeOptions}
                  selectedFilteringPerCellTypeOptions={hpaPerTissuesSelectedFilteringPerCellTypeOptions}
                  setSelectedFilteringPerCellTypeOptions={setSelectedHpaPerTissuesFilteringPerCellTypeOptions}
                  switchOpenModal={switchOpenModal}
                  customWrapClass="gene-expression-beeswarm-wrapper"
                  changeBoxPlotTransform={e => changeGraphTransform(e, graphTypes.HPA_PER_TISSUES, 'dataTransform')}
                  chartTitle={getChartTitle(graphTypes.HPA_PER_TISSUES, geneName)}
                  chartName={getChartName(graphTypes.HPA_PER_TISSUES)}
                  isCsvDownloadingAllowed={true}
                  csvName={getCsvName(graphTypes.HPA_PER_TISSUES, geneName)}
                  selectedCells={hpaPerTissuesCustoms.selectedCells}
                  setSelectedCells={e => setSelectedCells(e, graphTypes.HPA_PER_TISSUES)}
                  unsetTissuesSelectionMode={() => unsetTissuesSelectionMode(graphTypes.HPA_PER_TISSUES)}
                  tissuesSelectionMode={hpaPerTissuesCustoms.tissuesSelectionMode}
                  setTissuesSelectionMode={() => setTissuesSelectionMode(graphTypes.HPA_PER_TISSUES)}
                  setDataFixed={setHpaPerTissuesDataFixed}
                  isDataFixed={isHpaPerTissuesDataFixed}
                  calculateCustomPadding={(isChartDownloading) => {
                    return (
                      {
                        right: 0,
                        bottom: 0,
                        top: isChartDownloading ? 50 : 0,
                        left: isChartDownloading ? 350 : 40,
                      }
                    );
                  }
                  }
                />
              }
            </div>
          }
        </>
      }
      <NoData
        show={
          !hpaLoading &&
          ((hpaVisibleChartOption === visibleHpaChartEnums.CELL_TYPE && (!hpaCellTypes || hpaCellTypes.length === 0)) ||
          (hpaVisibleChartOption === visibleHpaChartEnums.TISSUES && (!hpaTissues || hpaTissues.length === 0)))
        }
        customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
      />
      {
        isCellTypeClusterModalModalOpen && <CellTypeClusterModal />
      }
    </>
  );
};

HpaCharts.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    hpaLoading: SELECTORS.getHpaLoadingSelector(state),
    hpaError: SELECTORS.getHpaErrorSelector(state),
    hpaCellTypes: SELECTORS.getHPACellTypes(state),
    hpaFilteringOptions: SELECTORS.getCellTypeGroupsOptionsForHpaPerCellTypesSelector(state),
    hpaVisibleChartOption: SELECTORS.getHpaVisibleChartOptionSelector(state),
    hpaSelectedSortingOption: SELECTORS.getHpaSelectedSortingOptionSelector(state),
    hpaSelectedFilteringOptions: SELECTORS.getHpaSelectedFilteringOptionSelector(state),
    hpaTissues: SELECTORS.getHPATissues(state),
    hpaPerTissuesSelectedSortingOption: SELECTORS.getHpaPerTissuesSelectedSortingOptionSelector(state),
    hpaPerTissuesFilteringOptions: SELECTORS.getCellTypeGroupsOptionsForHpaPerTissuesSelector(state),
    hpaPerTissuesSelectedFilteringOptions: SELECTORS.getHpaPerTissuesSelectedOptionsSelector(state),
    hpaPerTissuesFilteringPerCellTypeOptions: SELECTORS.getCellTypesOptionsForHpaPerTissuesSelector(state),
    hpaPerTissuesSelectedFilteringPerCellTypeOptions: SELECTORS.getHpaPerTissuesSelectedFilteringPerCellTypeOptionsSelector(state),
    hpaCellLegendData: SELECTORS.getLegendDataForHpaPerCellTypesSelector(state),
    hpaTissuesLegendData: SELECTORS.getLegendDataForHpaPerTissuesSelector(state),
    isCellTypeClusterModalModalOpen: getCellTypeClusterModalOpenSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisibleHpaChartOption(data) {
      dispatch(ACTIONS.setVisibleHpaChartOptionAction(data));
    },
    setSelectedHpaSortingOption(data) {
      dispatch(ACTIONS.setSelectedHpaSortingOptionAction(data));
    },
    setSelectedHpaFilteringOptions(data) {
      dispatch(ACTIONS.setSelectedHpaFilteringOptionsAction(data));
    },
    setSelectedHpaPerTissuesSortingOption(data) {
      dispatch(ACTIONS.setSelectedHpaPerTissuesSortingOptionAction(data));
    },
    setSelectedHpaPerTissuesFilteringOptions(data) {
      dispatch(ACTIONS.setSelectedHpaPerTissuesFilteringOptionsAction(data));
    },
    setSelectedHpaPerTissuesFilteringPerCellTypeOptions(data) {
      dispatch(ACTIONS.setSelectedHpaPerTissuesFilteringPerCellTypeOptionsAction(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HpaCharts);
