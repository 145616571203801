import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  labelValue: PropTypes.string,
};

class FormRadio extends React.Component {
  render() {
    const {
      input: {
        onChange,
        ...restInput
      },
      meta: {
        error,
        touched,
      },
      labelValue,
      ...restCustom
    } = this.props;

    return (
      <div className="inline-block input-radio">
        <div>
          <input
            onChange={event => onChange(event.target.value)}
            className="radio-input"
            id={restInput.value || labelValue}
            {... restInput}
            {... restCustom}
          />
          <label htmlFor={restInput.value || labelValue}>
            <span className="input-radio__text">{ labelValue || restInput.value }</span>
          </label>
        </div>
        {
          touched && error &&
          <div className="error-text">{error}</div>
        }
      </div>
    );
  }
}

FormRadio.propTypes = propTypes;

export default FormRadio;
