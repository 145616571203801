import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Store
import {
  setTargetCandidatesPairsExportedDataAction,
  toggleTargetCandidatesPairsExportedDataLoaderAction,
  throwTargetCandidatesPairsExportedDataErrorAction,
} from '../../reducer';
import { getSingleTargetForCancerSortSelector } from '../../../Algorithms/SingleTargetForCancer/selectors';

export function* exportTargetCandidatesPairsDataWorker({ payload }) {
  try {
    yield put(toggleTargetCandidatesPairsExportedDataLoaderAction(true));
    const { sortBy, sortDirection, sortPath } = yield select(getSingleTargetForCancerSortSelector);
    const sort = sortBy || sortPath;
    const requestData = {
      ...payload,
      ...(sort && {
        params: {
          ...payload.params,
          sort: `${sort},${sortDirection}`,
        },
      }),
    };
    const { data } = yield call(Api.exportWorkflows, requestData);
    yield put(setTargetCandidatesPairsExportedDataAction(data));
  } catch (e) {
    yield put(throwTargetCandidatesPairsExportedDataErrorAction(e.message));
    yield put(toggleTargetCandidatesPairsExportedDataLoaderAction(false));
  }
}
