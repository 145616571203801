import { createSelector } from 'reselect';

import { PAGE_SIZE } from '../../constants';
import { filterPaginatedData } from '../../../Utils/Utils';

const pageChapter = 'geneDetailsPhenotypesChapter';

const phenotypesLoading = state => state.getIn([pageChapter, 'loading']);
const phenotypesError = state => state.getIn([pageChapter, 'error']);
const adverseDrugs = state => state.getIn([pageChapter, 'adverseDrugs']);
const humanPhenotypes = state => state.getIn([pageChapter, 'humanPhenotypes']);
const animalPhenotypes = state => state.getIn([pageChapter, 'animalPhenotypes']);
const diagram = state => state.getIn([pageChapter, 'diagram']);

export const getChordsSelector = createSelector(
  diagram,
  data => data.toJS(),
);

export const getAdverseDrugsDataSelector = createSelector(
  adverseDrugs,
  (adverseDrugsData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = adverseDrugsData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);

export const getHumanPhenotypesDataSelector = createSelector(
  humanPhenotypes,
  (humanPhenotypesData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = humanPhenotypesData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);

export const getAnimalPhenotypesDataSelector = createSelector(
  animalPhenotypes,
  (animalPhenotypesData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = animalPhenotypesData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      pageNumber,
      totalPages,
    };
  }
);

export const getLoadingSelector = createSelector(
  phenotypesLoading,
  loading => loading
);

export const getErrorSelector = createSelector(
  phenotypesError,
  error => error
);
