import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import Loader from '../../common/Loader/Loader';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
// Store
import { activateUser } from './actions';
import {
  getActivationStatus,
  getActivationMessage,
  getActivationLoading,
} from './selectors';

const propTypes = {
  activationMessage: PropTypes.string,
  activationStatus: PropTypes.string,
  activationLoading: PropTypes.string,
  activate: PropTypes.func,
};

const UserActivation = (props) => {
  const {
    activate,
    activationStatus,
    activationMessage,
    activationLoading,
  } = props;
  const navigate = useNavigate();
  const { activationCode, login } = useParams();

  useEffect(() => {
    activate({ activationCode, login });
  }, []);

  return (
    <div className="login">
      <div className="flex-grid pt-15pr">
        <div className="col-1" />
        <div className="col-1 text-center">
          <h1>Euretos AI Platform</h1>
          <div className="logo-container login-euretos-logo inline-block" />
          {
            !activationLoading &&
            <div>
              <span>Your account </span>
              <span className="bold">
                { this.props.match.params.login }
              </span> is:
              <span className="bold">
                { activationStatus ? 'activated' : activationMessage }
              </span>
            </div>
          }
          <Loader isLoading={activationLoading} />
          {
            activationStatus &&
              <div className="mt-15">
                <button
                  className="btn"
                  onClick={() => { navigate(`${RELATIVE_PATH}/login`); }}
                >
                  Go to Login
                </button>
              </div>
          }
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
};

UserActivation.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    activationStatus: getActivationStatus(state),
    activationMessage: getActivationMessage(state),
    activationLoading: getActivationLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activate(data) {
      dispatch(activateUser(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActivation);
