import { fromJS } from 'immutable';

import {
  USER_ACTIVATION_SUCCEEDED,
  USER_ACTIVATION_FAILED,
} from './constants';

const initialValues = fromJS({
  activationStatus: false,
  message: '',
  loading: true,
});

const userActivation = (state = initialValues, action) => {
  switch (action.type) {
    case USER_ACTIVATION_SUCCEEDED:
      return state.update('activationStatus', () => true)
        .update('loading', () => false);

    case USER_ACTIVATION_FAILED:
      return state.update('message', () => action.message)
        .update('loading', () => false);

    default:
      return state;
  }
};

export default userActivation;
