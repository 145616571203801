import { createSelector } from 'reselect';

const links = state => state.getIn(['geneDetailsInternalLinksChapterReducer', 'data']);
const loading = state => state.getIn(['geneDetailsInternalLinksChapterReducer', 'loading']);
const error = state => state.getIn(['geneDetailsInternalLinksChapterReducer', 'error']);

export const getGeneDetailsInternalLinksDataSelector = createSelector(
  links,
  data => data && data.toJS()
);

export const getGeneDetailsInternalLinksLoadingSelector = createSelector(
  loading,
  data => data
);

export const getGeneDetailsInternalLinksErrorSelector = createSelector(
  error,
  data => data
);
