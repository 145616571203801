import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import SimpleTable from '../../../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../../../Concept/ShortConceptCard/ShortConceptCardCell';
import PublicationsList from '../../../../common/PublicationsList/PublicationsList';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Utils
import { getScreenWidth } from '../../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../../constantsCommon';
// Store
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../../../Search/store/actions';
import { literatureTableSortAction } from '../actions';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
};

class GeneLiteratureSection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPublicationsPopup: false,
    };
  }

  genesCell = ({ id, name }, rowIndex) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  columns = [
    {
      label: 'Gene 1',
      dataKey: 'leftGene',
      width: 300,
      exportCSV: true,
      cellRenderer: ({ cellData, rowIndex }) => (
        cellData ? this.genesCell(cellData, rowIndex) : null
      ),
    },
    {
      label: 'Gene 2',
      dataKey: 'rightGene',
      width: 300,
      exportCSV: true,
      cellRenderer: ({ cellData, rowIndex }) => (
        cellData ? this.genesCell(cellData, rowIndex) : null
      ),
    },
    {
      label: 'Literature search',
      dataKey: 'cooccurrence',
      width: 300,
      exportCSV: true,
      cellRenderer: ({ cellData, rowData }) => (
        <span
          role="presentation"
          className="link"
          onClick={
            () => {
              this.openPublicationsPopup([rowData.leftGene.id, rowData.rightGene.id], [rowData.leftGene.name, rowData.rightGene.name]);
            }
          }
        >
          {cellData}
        </span>
      ),
    }];

  openPublicationsPopup = (ids, names) => {
    this.conceptsIds = ids;
    this.conceptsNames = names;
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  render() {
    const {
      data,
      sorting: {
        sortBy,
        sortDirection,
      },
    } = this.props;
    const { showPublicationsPopup } = this.state;

    const screenWidth = getScreenWidth();
    const tableWidth = 900;

    let tableSettings = {
      width: screenWidth < tableWidth ? screenWidth : tableWidth,
      height: 500,
      headerHeight: 50,
      rowHeight: 50,
      sortBy,
      sortDirection,
    };

    if (data.length < 20) {
      tableSettings = Object.assign(
        {},
        tableSettings,
        { autoHeight: true },
      );
    }

    return (
      <div>
        <div className="path-details-section-header">
          Gene literature
        </div>
        <div className="path-details-separator" />
        <SimpleTable
          ref={(ref) => { this.simpleTable = ref; }}
          settings={tableSettings}
          data={data}
          manageable={false}
          sortAction={literatureTableSortAction}
          columns={this.columns}
          fixedHeight={true}
        />
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneLiteratureSection.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
  };
}

export default connect(null, mapDispatchToProps)(GeneLiteratureSection);
