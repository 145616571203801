import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/lib/immutable';
// Components
import Button from '../../common/Buttons/Button/Button';
import ModalComponent from '../../ModalComponent/ModalComponent';
import FormSelect from '../../common/FormElements/FormSelect';
// Store
import * as ACTIONS from '../../Projects/ProjectsTabs/store/reducer';
import * as SELECTORS from '../../Projects/ProjectsTabs/store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  reset: PropTypes.func,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onConfirm: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  getProjectsOptions: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
};

const SelectProjectModal = (props) => {
  const {
    reset,
    isOpen,
    closeCb,
    onConfirm,
    options,
    getProjectsOptions,
    resetProjectsOptions,
  } = props;

  useEffect(() => {
    if (isOpen) {
      getProjectsOptions();
    } else {
      reset();
      resetProjectsOptions();
    }
  }, [isOpen]);

  function handleConfirm() {
    closeCb();
    onConfirm();
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="select-project-modal"
    >
      {
        !!options &&
        <form className="select-project-modal__form">
          <div className="select-project-modal__title title2">
            Save publications
          </div>
          {
            options && options.length > 0 &&
            <div className="select-project-modal__block">
              <label className="select-project-modal__label" htmlFor="project">
                Select project
              </label>
              <Field
                name="project"
                options={options}
                component={FormSelect}
                bordered={true}
                dropDownHeight={110}
                selectedValue={options[0]}
                returnOption={true}
              />
            </div>
          }
          <div className="select-project-modal__controls">
            <Button
              text="Save"
              onClick={handleConfirm}
              customClassName="button button-primary mr-15"
            />
            <Button
              text="Cancel"
              onClick={closeCb}
              customClassName="button"
            />
          </div>
        </form>
      }
    </ModalComponent>
  );
};

SelectProjectModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: SELECTORS.getProjectsOptionsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsOptions() {
      dispatch(ACTIONS.getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(ACTIONS.resetProjectsOptionsAction());
    },
  };
}

const composition = compose(
  reduxForm({
    form: 'SAVE_PUBLICATIONS_TO_PROJECT',
    fields: ['project'],
    destroyOnUnmount: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(SelectProjectModal);
