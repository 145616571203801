import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Components
import FindRelatedResult from '../../../Analytics/FindRelated/FindRelatedResult/FindRelatedResult';
import FindRelatedProteinOrRegulationResult from '../../../Analytics/FindRelated/FindRelatedProteinOrRegulationResult/FindRelatedProteinOrRegulationResult';
// Constants
import { analysisMethodEnum } from '../../../Analytics/FindRelated/FindRelatedResult/enums';
import { apiKeyEnum } from '../../../Analytics/FindRelated/FindRelatedProteinOrRegulationResult/enums';

const propTypes = {
  method: PropTypes.string,
  type: PropTypes.string,
  concepts: PropTypes.instanceOf(Array),
  selectedCategory: PropTypes.string,
  semanticCategories: PropTypes.instanceOf(Array),
};

const FindRelatedContent = (props) => {
  const {
    method,
    type,
    concepts,
    selectedCategory,
    semanticCategories,
  } = props;

  const semanticType = semanticCategories.find(d => d.name === selectedCategory)?.st.find(d => d.id === type)?.name;

  const result = useMemo(() => {
    switch (method) {
      case analysisMethodEnum.PATHWAY:
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            title="Pathway analysis"
            CSVTitle="pathway_analysis"
            genesDetailAPIKey="pathwayTargetCandidates"
          />
        );
      case analysisMethodEnum.REGULATION:
        return (
          <FindRelatedProteinOrRegulationResult
            apiKey={apiKeyEnum.regulation}
            passedConcepts={concepts}
            title="Regulation analysis"
            CSVTitle="regulation_analysis"
          />
        );
      case analysisMethodEnum.PROTEIN_COMPLEX:
        return (
          <FindRelatedProteinOrRegulationResult
            apiKey={apiKeyEnum.protein}
            passedConcepts={concepts}
            title="Protein Complex Analysis"
            CSVTitle="protein_complex_analysis"
          />
        );
      case analysisMethodEnum.COMPOUND:
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            title="Compound analysis"
            CSVTitle="compound_analysis"
            genesDetailAPIKey="compoundTargetCandidates"
          />
        );
      case analysisMethodEnum.PHENOTYPE:
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            title="Phenotype analysis"
            CSVTitle="phenotype_analysis"
            genesDetailAPIKey="phenotypeTargetCandidates"
          />
        );
      case analysisMethodEnum.DISORDER:
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            title="Disorder analysis"
            CSVTitle="disorder_analysis"
            genesDetailAPIKey="phenotypeTargetCandidates"
          />
        );
      case analysisMethodEnum.METABOLITE:
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            title="Metabolite analysis"
            CSVTitle="metabolite_analysis"
            genesDetailAPIKey="metaboliteTargetCandidates"
          />
        );
      case analysisMethodEnum.CATEGORY:
        if (!type) return null;
        return (
          <FindRelatedResult
            analysisMethod={method}
            passedConcepts={concepts}
            passedCategory={type}
            passedCategoryName={semanticType}
            title={`${semanticType} analysis`}
            CSVTitle={`${semanticType}_analysis`}
            genesDetailAPIKey="categoryTargetCandidates"
          />
        );
      default:
        return null;
    }
  }, [type, concepts, method]);

  if (!method || !concepts) {
    return null;
  }

  return result;
};

FindRelatedContent.propTypes = propTypes;

export default React.memo(FindRelatedContent);
