import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './index.scss';

const propTypes = {
  sources: PropTypes.instanceOf(Array),
  pubSources: PropTypes.instanceOf(Array),
  isSentenceCooccurrence: PropTypes.bool,
  changeField: PropTypes.func,
};

const SearchPublicationSources = (props) => {
  const {
    sources,
    pubSources,
    isSentenceCooccurrence,
    changeField,
  } = props;

  const maxCount = useMemo(() => (Math.max.apply(null, pubSources.map(({ count }) => count))), [pubSources]);

  if (!pubSources.length || isSentenceCooccurrence) {
    return null;
  }

  function checkActiveSource({ sourceId }) {
    if (sources.includes(sourceId)) {
      changeField('sources', sources.filter(item => sourceId !== item));
    } else {
      changeField('sources', [...sources, sourceId]);
    }
  }

  function renderSource(source, i) {
    const { name, count, sourceId } = source;

    const activeClass = classNames({
      'search-publication-sources__item': true,
      'search-publication-sources__item_active': sources.includes(sourceId),
    });

    return (
      <div className={activeClass} key={`${name}-${i}`}>
        <div
          className="search-publication-sources__name"
          title={name}
          onClick={() => { checkActiveSource(source); }}
        >
          {name}
        </div>
        <div className="col-1">
          <div
            className="search-publication-sources__count"
            style={{ width: `${((count / maxCount) * 100).toFixed(0)}%` }}
          >
            {count}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="search-publication-sources">
      {pubSources.map(renderSource)}
    </div>
  );
};

SearchPublicationSources.propTypes = propTypes;

export default React.memo(SearchPublicationSources);
