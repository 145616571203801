import { createSelector } from 'reselect';
import { filterPaginatedData, sort } from '../../../Utils/Utils';
import { PAGE_SIZE } from '../constants';

const pageChapter = 'geneDetailsSimilarProteinsPageChapter';
// Chapter
const chapterLoading = state => state.getIn([pageChapter, 'loading']);
const chapterError = state => state.getIn([pageChapter, 'error']);
// Patents
const similarProteins = state => state.getIn([pageChapter, 'similarProteins']);
const similarProteinsSorting = state => state.getIn([pageChapter, 'sorting']);
const similarProteinsPage = state => state.getIn([pageChapter, 'page']);
const similarProteinsTotalPages = state => state.getIn([pageChapter, 'totalPages']);

export const getChapterLoading = createSelector(
  chapterLoading,
  loading => loading
);

export const getChapterError = createSelector(
  chapterError,
  error => error
);

export const getAllSimilarProteinsSelector = createSelector(
  similarProteins,
  similarProteinsSorting,
  (data, sorting) => {
    const sortedData = sort(data, sorting.toJS()).toJS();
    return sortedData;
  }
);

export const getSimilarProteins = createSelector([
  similarProteinsPage,
  getAllSimilarProteinsSelector,
], (page, sortedData) => filterPaginatedData(sortedData, page, PAGE_SIZE));

export const getSimilarProteinsSorting = createSelector(
  similarProteinsSorting,
  data => data.toJS()
);

export const getSimilarProteinsPageSelector = createSelector(
  similarProteinsPage,
  data => data
);

export const getSimilarProteinsTotalPagesSelector = createSelector(
  similarProteinsTotalPages,
  data => data
);
