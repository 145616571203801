import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import 'react-dropdown/style.css';

// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import CreateSet from '../../../Sets/CreateSet/CreateSet';
import ConceptDetails from '../../../Concept/ConceptDetails/ConceptDetails';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import RelationDetailsModal
  from '../../../RelationMap/RelationMapPage/Components/RelationDetailsModal/RelationDetailsModal';
import ExportTable from '../../../common/ExportTable/ExportTable';
// Utils
import {
  getScreenWidth,
  openSetResultInNewTab,
} from '../../../Utils/Utils';
import {RELATIVE_PATH} from '../../../../constantsCommon';
import {getColumnHelpText, getColumnName} from './RankSelectionResultTableColumns';
// Store
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import { openBarChartAction } from '../../../graphics/BarChart/store/actions';
import { setResultReset } from '../../../SetResult/SetResultPage/actions';
import {
  currentPage,
  tableSorting,
  getTotalPages,
  getTotalItems,
  getLoadingKey,
  getPageNumber,
  getError,
  allRankData,
  getFilters,
  rowExample,
} from './selectors';
import {
  sort,
  removeConcept,
  changePageAction,
  reset,
  loading,
  applyFilterAction,
} from './actions';
// Styles
import './RankSelectionResultTable.css';
import {getSelectedConceptSelector} from '../../store/selectors';
import Pagination from '../../../common/Pagination/Pagination';
import ColumnFilterModal from '../ColumnFilterModal/ColumnFilterModal';
import * as s from '../../store/selectors';
import {apiTypes} from '../../../Sets/CreateSet/enums';

const propTypes = {
  removeConcept: PropTypes.func,
  page: PropTypes.instanceOf(Array),
  changePage: PropTypes.func,
  conceptsToAnalyse: PropTypes.instanceOf(Array),
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  loadingKey: PropTypes.bool,
  error: PropTypes.string,
  openBarChart: PropTypes.func,
  selectedConcept: PropTypes.instanceOf(Object),
  columnSort: PropTypes.func,
  allData: PropTypes.instanceOf(Array),
  showFilters: PropTypes.bool,
  showColumnFilterPopup: PropTypes.instanceOf(Object),
  applyFilter: PropTypes.func,
  activeFilters: PropTypes.instanceOf(Object),
  rowExample: PropTypes.instanceOf(Object),
  selectedSetInfo: PropTypes.instanceOf(Object),
  selectedSetItems: PropTypes.instanceOf(Object),
};

class RankSelectionResultTable extends React.Component {
  state = {
    showPublicationsPopup: false,
    conceptDetails: {
      show: false,
      id: null,
    },
    filterSetPopup: {
      show: false,
      config: null,
    },
    relationDetailsPopup: {
      show: false,
      config: null,
    },
    indicationsPopup: {
      show: false,
      data: {},
    },
    showColumnFilterPopup: {
      show: false,
      column: null,
    }
  };
  conceptsNames = [];
  conceptsIds = [];
  simpleTableRef = React.createRef(null);

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  gotoAssociationScorePage = (gene) => {
    window.open(`${RELATIVE_PATH}/association-score/${this.props.selectedConcept.id}/${gene}`, '_blank');
  };

  openPublicationsPopup = () => {
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  removeConcept = (id) => {
    this.props.removeConcept(id);
  };

  showConceptDetails = (id) => {
    this.setState({
      conceptDetails: {
        show: true,
        id,
      },
    });
  };

  openFilteringPopup = (data) => {
    const conceptsIds = data.geneticInteractionsSetResultsData.map(el => el.nodeA);
    const category = data.semanticCategory === 'Genes & Molecular Sequences' ? 'Genes' : '';
    const filterSetPopupConfig = {
      apiType: apiTypes.filterWithConcepts,
      conceptIds: [data.geneGi],
      startConcepts: [{ id: data.geneGi, name: data.geneName}],
      endConceptIds: conceptsIds,
      category,
      categoryName: category,
    };
    this.setState({
      filterSetPopup: {
        show: true,
        config: filterSetPopupConfig,
      },
    });
  };

  closeFilterSetPopup = () => {
    this.setState({
      filterSetPopup: {
        show: false,
        config: null,
      },
    });
  };

  closeConseptDetails = () => {
    this.setState({
      conceptDetails: {
        show: false,
        id: null,
      },
    });
  };

  openRelationDetailsPopup = (data) => {
    const { selectedConcept } = this.props;
    const { geneGi, geneName } = data;
    const { id, name } = selectedConcept;

    this.setState({
      relationDetailsPopup: {
        show: true,
        config: {
          conceptIds: [geneGi, id],
          conceptNames: [geneName, name],
          hideRelationsDetailsInfo: true,
        },
      },
    });
  };

  closeRelationDetailsPopup = () => {
    this.setState({
      relationDetailsPopup: {
        show: false,
        config: null,
      },
    });
  };

  openIndicationsPopup = (data) => {
    this.setState({
      indicationsPopup: {
        show: true,
        data,
      },
    });
  };

  closeIndicationsPopup = () => {
    this.setState({
      indicationsPopup: {
        show: false,
        data: {},
      },
    });
  };

  openColumnFilterPopup = (column) => {
    const { activeFilters } = this.props;

    this.setState({
      showColumnFilterPopup: {
        show: true,
        column,
        initialOperation: activeFilters[column] ? activeFilters[column].operation : null,
        initialValue: activeFilters[column] ? activeFilters[column].value : null,
      },
    });
  };

  closeColumnFilterPopup = () => {
    this.setState({
      showColumnFilterPopup: {
        show: false,
        column: null,
        initialOperation: null,
        initialValue: null,
      },
    });
  };

  applyFilterFunction = (operation, value) => {
    this.props.applyFilter({
      column: this.state.showColumnFilterPopup.column,
      operation,
      value
    });
  };

  handlePageClick = (selected) => {
    this.props.changePage(selected);
  };

  getRowHeight = ({ index }) => {
    let itemsCount = 0;
    try {
      if (this.props.page[index].classifications) {
        itemsCount = this.props.page[index].classifications.length;
      }
    } catch (e) {
      console.log(e);
    }
    if (itemsCount > 2) {
      return itemsCount * 20 + 10; //eslint-disable-line
    }
    return 50;
  };

  goToConceptDetails = (id, semanticCategory) => {
    if (semanticCategory === 'Genes & Molecular Sequences') {
      window.open(`${RELATIVE_PATH}/gene-details/${id}`, '_blank');
    } else {
      this.showConceptDetails(id);
    }
  };

  genesCell = ({ id, name }, rowIndex, semanticCategory) => {
    const link = semanticCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  renderTableHeader = (d, helpText = d.label) => {
    const ascClasses = classNames({
      'fa fa-sort-asc': true,
      active: d.sortBy === d.dataKey && (!d.sortDirection || d.sortDirection === 'ASC'),
    });
    const descClasses = classNames({
      'fa fa-sort-desc': true,
      active: d.sortBy === d.dataKey && d.sortDirection === 'DESC',
    });

    const filterClasses = classNames({
      'ranking-result-filter-button fa fa-filter icon first-info-icon': true,
      'ranking-result-filter-active': Object.keys(this.props.activeFilters).includes(d.dataKey),
    });

    return (
      <div className="header-section">
        <span
          className="ReactVirtualized__Table__headerTruncatedText ReactVirtualized__Table__sortableHeaderColumn vertical-align-middle text-center"
          title={helpText}
        >
          {d.label}
        </span>
        {
          this.props.showFilters &&
          <div>
            <button
              className={filterClasses}
              onClick={(e) => { e.stopPropagation(); this.openColumnFilterPopup(d.dataKey); }}
            />
          </div>
        }
        <span className="sorting-section">
          <i
            className={ascClasses}
            onClick={(e) => {e.stopPropagation(); sort({sortBy: d.dataKey, sortDirection: 'ASC'});}}
          />
          <i
            className={descClasses}
            onClick={(e) => {e.stopPropagation(); sort({sortBy: d.dataKey, sortDirection: 'DESC'});}}
          />
        </span>
      </div>
    );
  };

  render() {
    const {
      page,
      totalPages,
      pageNumber,
      sorting: {sortBy, sortDirection},
      loadingKey,
      error,
      allData,
      rowExample,
    } = this.props;
    const {
      showPublicationsPopup,
      conceptDetails,
      filterSetPopup,
      relationDetailsPopup,
      indicationsPopup,
    } = this.state;

    const columns = [];
    let totalTableLength = 50; // Default first column
    const screenWidth = getScreenWidth();

    const repositionColumn = (arr, value) => {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
        arr.unshift(value);
      }
    };

    const indicationsPopupContent = () => {
      const { data } = indicationsPopup;
      const indicationsKeys = Object.keys(data);
      return indicationsKeys.map((key, i) => (
        <span
          className="indications-popup__item"
          key={i}
        >
          {key}
        </span>
      ));
    };

    let columnsNames = Object.keys(rowExample || {});

    const columnsToRemove = ['geneName', 'classifications', 'tripleIds', 'compoundScoreSetResultsData',
      'geneticInteractionsSetResultsData', 'involvementOtherDiseasesSetResultsData',
      'associationScoreSetResultsData', 'semanticCategory', 'literatureSearchData', 'differentialExpressionExperimentId',
      'targetValidation', 'count', 'mostFrequentIndications', 'rankingTrialStage',
      'set1', 'set2', 'set3', 'set4', 'set5', 'set6', 'effectAggregation'];
    columnsNames = columnsNames.filter(columnName => !columnsToRemove.includes(columnName));

    repositionColumn(columnsNames, 'compoundScore');
    repositionColumn(columnsNames, 'druggableStructure');
    repositionColumn(columnsNames, 'differentialExpression');
    repositionColumn(columnsNames, 'geneVariation');
    repositionColumn(columnsNames, 'literatureSearch');
    repositionColumn(columnsNames, 'openTargetsScore');
    repositionColumn(columnsNames, 'associationScore');
    repositionColumn(columnsNames, 'effectSetScore');
    columnsNames.unshift('classifications');
    columnsNames.unshift('geneName');
    columnsNames.unshift('rowNumber');

    if (page[0] && page[0].targetValidation) {
      columnsNames.push(...['rankingTrialStage', 'count', 'mostFrequentIndications']);
    }

    columnsNames.forEach((columnName) => {
      // skip this data into table view
      if (columnName !== 'id' && columnName !== 'geneGi' && columnName !== 'selected') {
        const columnWidth = 100;

        const columnHeader = columnName;

        const columnSetting = {
          label: getColumnName(columnHeader),
          dataKey: columnName,
          width: columnWidth,
          disableSort: false,
          exportCSV: true,
        };

        if (columnName === 'effectSetScore') {
          if ((this.props.selectedSetInfo.type === 'EFFECT'
            || this.props.selectedSetInfo.setType === 'EFFECT')) {
            Object.assign(columnSetting,{
              label: 'Effect score',
              dataKey: 'effectSetScore',
              exportCSV: true,
              className: 'flexColumn',
              width: 70,
              cellRenderer: ({ rowData }) => (
                rowData['effectSetScore']
              ),
            });

            totalTableLength += 70;
          }
        }

        if (columnName === 'associationScore') {
          Object.assign(columnSetting, {
            className: 'flexColumn',
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      this.gotoAssociationScorePage(rowData.geneGi);
                    }
                  }}
                  className={classes}
                >
                  {rowData[columnName]}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'classifications') {
          Object.assign(columnSetting, {
            className: 'flexColumn',
            width: 375,
            sortValueMapper: (rowData) => {
              const items = rowData.get(columnName) || [];
              return items.toArray().length
                ? items.toArray()[0]
                : '';
            },
            cellRenderer: ({ rowData }) => {
              const items = rowData[columnName] || [];
              return items.map((item, index) => (
                <div
                  title={item}
                  className="text-center cell-row"
                  key={index}
                >
                  {item}
                </div>
              ));
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 375;
        }
        if (columnName === 'rowNumber') {
          Object.assign(columnSetting, {
            headerRenderer: () => { return ''; },
            width: 40,
            exportCSV: false,
            cellRenderer: ({ rowIndex }) => ( rowIndex+1 + pageNumber*20 ),
          });
          totalTableLength += 40;
        }
        if (columnName === 'geneName') {
          Object.assign(columnSetting, {
            width: 270,
            cellRenderer: ({ rowData, rowIndex }) => (
              rowData ?
                this.genesCell({ id: rowData.geneGi, name: rowData.geneName }, rowIndex, rowData.semanticCategory) :
                null
            ),
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 270;
        }
        if (columnName === 'openTargetsScore') {
          Object.assign(columnSetting, {
            width: 120,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      this.openFilteringPopup(rowData);
                    }
                  }}
                >
                  {rowData[columnName]}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 120;
        }
        if (columnName === 'geneVariation') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      this.openRelationDetailsPopup(rowData);
                    }
                  }}
                >
                  {rowData[columnName]}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'differentialExpression') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.differentialExpression !== 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.differentialExpression !== 0) {
                      this.goToConceptDetails(rowData.differentialExpressionExperimentId);
                    }
                  }}
                >
                  {rowData.differentialExpression}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'literatureSearch') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.literatureSearch > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.literatureSearch > 0) {
                      this.conceptsNames = [
                        rowData.geneName,
                        rowData.literatureSearchData[0].synonym.name,
                      ];
                      this.conceptsIds =
                        [
                          rowData.geneGi,
                          rowData.literatureSearchData[0].synonym.id,
                        ];
                      this.openPublicationsPopup();
                    }
                  }}
                >
                  {rowData.literatureSearch}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'compoundScore') {
          Object.assign(columnSetting, {
            width: 110,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.compoundScore > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.compoundScore > 0) {
                      openSetResultInNewTab(rowData.compoundScoreSetResultsData);
                    }
                  }}
                >
                  {rowData.compoundScore}
                </span>
              );
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 110;
        }
        if (columnName === 'druggableStructure') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => (
              rowData && rowData.druggableStructure > 0 ?
                'yes' :
                'no'
            ),
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'rankingTrialStage') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Max Clinical Phase',
            width: 200,
            cellRenderer: ({ rowData }) => (
              <div
                title={rowData[columnName]}
                className="text-center cell-row"
              >
                {rowData[columnName]}
              </div>
            ),
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 200;
        }
        if (columnName === 'count') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Number of Trials',
            width: 100,
            cellRenderer: ({ rowData }) => (
              <div
                title={rowData[columnName]}
                className="text-center cell-row"
              >
                {rowData[columnName]}
              </div>
            ),
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'geneticInteractions') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Protein interactions',
            width: 100,
            cellRenderer: ({ rowData }) => (
              <div
                title={rowData[columnName]}
                className="text-center cell-row"
              >
                {rowData[columnName]}
              </div>
            ),
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 100;
        }
        if (columnName === 'mostFrequentIndications') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Most Frequent Indication in CT',
            width: 300,
            cellRenderer: ({ rowData }) => {
              const indications = rowData[columnName];
              const indicationsKeys = Object.keys(indications);
              const indicationsCount = indicationsKeys.length;
              let cell;
              if (indicationsCount <= 5) {
                cell = (indicationsKeys.map((key, i) => (
                  <div
                    key={i}
                    className="text-center cell-row"
                  >
                    {`${key}`}
                  </div>
                )));
              } else {
                cell = (
                  <div
                    className="text-center link"
                    onClick={() => { this.openIndicationsPopup(indications); }}
                  >
                    {indicationsCount}
                  </div>
                );
              }
              return cell;
            },
            headerRenderer: d => this.renderTableHeader(d, getColumnHelpText(columnHeader)),
          });
          totalTableLength += 300;
        }
        columns.push(columnSetting);
      }
    });

    columns.push({
      dataKey: 'id',
      disableSort: true,
      width: 75,
      cellRenderer: ({ rowData }) => (
        <div className="text-center">
          <button
            type="button"
            onClick={() => { this.removeConcept(rowData.id); }}
            className="btn-icon btn-icon-danger"
            title="Remove"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      ),
    });
    // last column width
    totalTableLength += 75;

    const containerStyle = {
      width: screenWidth > totalTableLength ? totalTableLength : screenWidth - 40,
    };

    let tableSettings = {
      width: totalTableLength,
      headerHeight: 60,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    if (page.length < 10) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    return (
      <div className="setAnalysisRankingResult">
        {
          !loadingKey &&
          <div>
            <div
              style={containerStyle}
              className="flex-grid justify-content-center ranking-result-top-container"
            >
              <div className="col-1">
                <div className="paginationContainer">
                  {
                    totalPages > 1 &&
                    <Pagination
                      pageNumber={pageNumber}
                      totalPages={totalPages}
                      onPageChange={({ selected }) => this.handlePageClick(selected)}
                    />
                  }
                </div>
              </div>
              <div className="col-1">
                <ExportTable
                  content={allData}
                  columns={columns}
                  showExportAll={true}
                  showExportPage={false}
                  showExportSelected={false}
                  fileName="rank_selection"
                />
              </div>
            </div>
            <SimpleTable
              innerRef={this.simpleTableRef}
              settings={tableSettings}
              data={page}
              manageable={false}
              actions={this.actions}
              sortAction={sort}
              columns={columns}
              dynamicHeight={true}
            />
          </div>
        }
        <Loader isLoading={loadingKey && !error} />
        <Error show={!!error} error={error} />
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
        {
          conceptDetails.show &&
          <ModalComponent
            isOpen={conceptDetails.show}
            closeCb={this.closeConseptDetails}
          >
            <ConceptDetails
              conceptId={conceptDetails.id}
              isPopup={true}
            />
          </ModalComponent>
        }
        {
          filterSetPopup.show &&
          <ModalComponent
            isOpen={filterSetPopup.show}
            closeCb={this.closeFilterSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={filterSetPopup.config}
              closePopup={this.closeFilterSetPopup}
            />
          </ModalComponent>
        }
        {
          relationDetailsPopup.show &&
          <RelationDetailsModal
            isOpen={relationDetailsPopup.show}
            closeCb={this.closeRelationDetailsPopup}
            {...relationDetailsPopup.config}
          />
        }
        {
          indicationsPopup.show &&
          <ModalComponent
            isOpen={indicationsPopup.show}
            closeCb={this.closeIndicationsPopup}
          >
            <div className="indications-popup">
              <div className="indications-popup__title">Most frequent indication in CT</div>
              <div className="indications-popup__wrap">
                {indicationsPopupContent()}
              </div>
            </div>
          </ModalComponent>
        }
        <ColumnFilterModal
          isOpen={this.state.showColumnFilterPopup.show}
          columnName={this.state.showColumnFilterPopup.column}
          initialOperation={this.state.showColumnFilterPopup.initialOperation}
          initialValue={this.state.showColumnFilterPopup.initialValue}
          closeCb={this.closeColumnFilterPopup}
          onConfirm={this.applyFilterFunction}
        />
      </div>
    );
  }
}

RankSelectionResultTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    allData: allRankData(state),
    page: currentPage(state),
    rowExample: rowExample(state),
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    totalItems: getTotalItems(state),
    sorting: tableSorting(state),
    conceptsToAnalyse: selectedDataForAnalysis(state),
    loadingKey: getLoadingKey(state),
    error: getError(state),
    selectedConcept: getSelectedConceptSelector(state),
    activeFilters: getFilters(state),
    selectedSetInfo: s.getSelectedSetInfoSelector(state),
    selectedSetItems: s.getRankSelctionSetSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeConcept(id) {
      dispatch(removeConcept(id));
    },
    changePage(data) {
      dispatch(changePageAction(data));
    },
    reset() {
      dispatch(reset());
    },
    loading() {
      dispatch(loading());
    },
    openBarChart(data) {
      dispatch(openBarChartAction(data));
    },
    setResultReset() {
      dispatch(setResultReset());
    },
    columnSort(data) {
      dispatch(sort(data));
    },
    applyFilter(data) {
      dispatch(applyFilterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankSelectionResultTable);
