import { call, put, takeLatest } from 'redux-saga/effects';
// Api
import Api from '../../../../../../../Api/Api';
// store
import * as a from './reducer';

function* getIndicationFinderDiseasesWorker(action) {
  try {
    yield put(a.toggleIndicationFinderDiseasesLoadingAction(true));
    const { id } = action.payload;
    const { data } = yield call(Api.getIndicationFinderDiseases, id);
    yield put(a.setIndicationFinderDiseasesAction(data));
  } catch (error) {
    yield put(a.throwIndicationFinderDiseasesErrorAction(error));
    yield put(a.toggleIndicationFinderDiseasesLoadingAction(false));
  }
}

function* indicationDiseasesFinderSaga() {
  yield takeLatest(a.getIndicationFinderDiseasesAction, getIndicationFinderDiseasesWorker);
}

export default indicationDiseasesFinderSaga;
