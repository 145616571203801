import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../ModalComponent/ModalComponent';
// Styles
import './ConfirmationDialog.scss';

const propTypes = {
  customClassName: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  disabled: PropTypes.bool,
  closeCb: PropTypes.func,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
};

const ConfirmationDialog = (props) => {
  const {
    text,
    isOpen,
    closeCb,
    disabled,
    children,
    onConfirm,
    confirmBtnText = 'Yes',
    cancelBtnText = 'Cancel',
    customClassName,
  } = props;

  const handleConfirm = useCallback(() => {
    onConfirm();
    closeCb();
  }, [onConfirm, closeCb]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      customClassName={customClassName}
    >
      <div className="confirmation-dialog">
        <div className="confirmation-dialog__content">
          {children || text}
        </div>
        <div className="confirmation-dialog__controls">
          <button
            onClick={handleConfirm}
            disabled={disabled}
            className="button button-primary mr-15"
          >
            {confirmBtnText}
          </button>
          <button
            onClick={closeCb}
            className="button"
          >
            {cancelBtnText}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

ConfirmationDialog.propTypes = propTypes;

export default React.memo(ConfirmationDialog);
