import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import RangeInput from '../../../../common/Inputs/RangeInput/RangeInput';

const propTypes = {
  defaultValue: PropTypes.instanceOf(Array),
  onAfterChange: PropTypes.func,
  colors: PropTypes.instanceOf(Array),
  dotColor: PropTypes.string,
  isNegative: PropTypes.bool,
  step: PropTypes.number,
};

const HeatmapColorScale = (props) => {
  const {
    defaultValue,
    onAfterChange,
    colors,
    dotColor,
    isNegative,
    step,
  } = props;

  const [gradientPosition, setGradientPosition] = useState(50);

  function calcGradientPosition(values) {
    const min = values[0];
    const max = values[1];
    const end = defaultValue[1];
    const gradientCenter = ((max - min) / 2) + min;
    const position = (gradientCenter * 100) / end;
    setGradientPosition(position);
  }

  function calcGradientPositionNegative(values) {
    const min = Math.abs(values[1]);
    const max = Math.abs(values[0]);
    const end = Math.abs(defaultValue[0]);
    const gradientCenter = ((max - min) / 2) + min;
    const position = 100 - Math.round((gradientCenter * 100) / end);
    setGradientPosition(position);
  }

  function handleChange(values) {
    if (isNegative) {
      calcGradientPositionNegative(values);
    } else {
      calcGradientPosition(values);
    }
  }

  return (
    <RangeInput
      defaultValue={defaultValue}
      onChange={handleChange}
      onAfterChange={onAfterChange}
      trackStyle={[{
        boxSizing: 'content-box',
        borderTop: '1px solid rgb(238, 238, 240)',
        borderBottom: '1px solid rgb(238, 238, 240)',
        background: `linear-gradient(to right, ${colors[0]}, ${colors[1]})`,
      }]}
      railStyle={{
        boxSizing: 'content-box',
        border: '1px solid rgb(238, 238, 240)',
        background: `linear-gradient(to right, ${colors[0]} ${gradientPosition}%, ${colors[1]} ${gradientPosition}%)`,

      }}
      handleStyle={[{
        borderColor: dotColor,
        marginTop: '-4px',
      }]}
      allowCross={false}
      step={step}
    />
  );
};

HeatmapColorScale.propTypes = propTypes;

export default HeatmapColorScale;
