import { createSelector } from 'reselect';

const indicationFinderDiseases = state => state.getIn(['indicationDiseasesFinderReducer', 'data']);
const indicationFinderDiseasesLoader = state => state.getIn(['indicationDiseasesFinderReducer', 'loading']);
const indicationFinderDiseasesError = state => state.getIn(['indicationDiseasesFinderReducer', 'error']);

export const getIndicationFinderDiseasesSelector = createSelector(
  indicationFinderDiseases,
  data => data && data.toJS()
);

export const getIndicationFinderDiseasesLoadingSelector = createSelector(
  indicationFinderDiseasesLoader,
  data => data
);

export const getIndicationFinderDiseasesErrorSelector = createSelector(
  indicationFinderDiseasesError,
  data => data
);
