import React from 'react';
import PropTypes from 'prop-types';
// Components
import SimilarProteinsTable from './SimilarProteinsTable';
// Styles
import './styles.scss';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  itsNewConcept: PropTypes.bool,
};

class SimilarProteinsChapter extends React.Component {
  state = {
    showInfoText: false,
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      itsNewConcept,
    } = this.props;
    const { showInfoText } = this.state;
    const parentClass = 'gene-details-section-body';

    return (
      <div className="similar-proteins">
        <div className="gene-details-section-title title2">
          <span>Similar Proteins</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              This section shows similar proteins to the human gene/protein, based on the UniRef50 datasource
              (Uniprot database). UniRef50 is built by clustering UniRef90 seed sequences that have at least 50% sequence
              identity to and 80% overlap with the longest sequence in the cluster using MMseqs2 algorithm
              <a target="blank" href="https://www.uniprot.org/help/uniref" className="link">(UniRef)</a>. Proteins from
              a subset of species (RAT/MOUSE/RABBIT/MACAQUE(CRAB-eating/Rhesus) are extracted from the cluster containing
              the human gene/protein (Note that it can occur that a protein with high similarity and identity to the human
              protein, falls in a different cluster than the human protein and is therefore not shown in this section).
              Similarity and identity scores shown in this section are calculated by Euretos using Smith-Waterman with Blosum 62
              score matrix. In all alignments we compare human proteins vs other species. We calculate similarity and
              identity based on the length of the human protein. We run Phobius to predict the transmembrane (TM) domains
              in each protein. Based on this prediction, we calculate percentage extracellular sequence for each protein
              as well as percentage identity /similarity only for the extracellular domain.
            </p>
          </div>
        }
        <SimilarProteinsTable
          gene={gene}
          tableWidth={1400}
          geneName={geneName}
          parentClass={parentClass}
          itsNewConcept={itsNewConcept}
        />
      </div>
    );
  }
}

SimilarProteinsChapter.propTypes = propTypes;
export default SimilarProteinsChapter;

