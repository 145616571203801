import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';

const initialState = fromJS({
  result: {
    recommendations: {},
    publications: {
      content: [],
    },
    synonyms: [],
    pubSources: [],
    searchTypes: null,
  },
  selectedPublicationsIds: [],
  selectedPubmedIds: [],
  categoriesSearch: [],
  cachedConceptIdsForCategorySearch: [],
  categorySearchDto: {},
  searchTerm: '',
  exactSearchTerm: '',
  error: null,
  loading: false,
  searchFilterInitialValues: null,
  savedLink: null,
  reloadingIsDisabled: false,
  searchConcepts: [],
  publicationFilterExtended: false,
});

const reducer = handleActions(
  {
    [a.getSearchDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [a.setSearchDataAction]: (state, { payload }) =>
      state
        .update('result', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null),
    [a.throwSearchDataErrorAction]: (state, { payload }) =>
      state
        .update('result', () => initialState.get('result'))
        .update('categoriesSearch', () => initialState.get('categoriesSearch'))
        .update('loading', () => false)
        .update('error', () => payload),
    [a.cacheConceptIdsForCategorySearchAction]: (state, { payload }) =>
      state
        .update('cachedConceptIdsForCategorySearch', () => fromJS(payload)),
    [a.resetCachedConceptIdsForCategoryAction]: state =>
      state
        .update('cachedConceptIdsForCategorySearch', () => initialState.get('cachedConceptIdsForCategorySearch')),
    [a.saveSearchTermAction]: (state, { payload }) =>
      state
        .update('searchTerm', () => payload),
    [a.saveExactSearchTermAction]: (state, { payload }) =>
      state
        .update('exactSearchTerm', () => payload),
    [a.checkSearchRelatedConceptAction]: (state, { payload }) =>
      state
        .update('categoriesSearch', categoriesSearch => categoriesSearch
          .update('concepts', concepts => concepts.map(
            concept => concept.set('active', concept.get('id') === payload)
          ))
        ),
    [a.setCategorySearchDTOAction]: (state, { payload }) =>
      state
        .update('categorySearchDto', () => fromJS(payload)),
    [a.setCategorySearchDataAction]: (state, { payload }) =>
      state
        .update('categoriesSearch', () => fromJS(payload))
        .update('error', () => null),
    [a.setSearchFilterInitialValuesAction]: (state, { payload }) =>
      state
        .update('searchFilterInitialValues', () => fromJS(payload)),
    [a.updateSearchSavedLinkAction]: (state, { payload }) =>
      state
        .update('savedLink', () => fromJS(payload)),
    [a.updateSelectedPublicationsIdsAction]: (state, { payload }) =>
      state
        .update('selectedPublicationsIds', () => fromJS(payload)),
    [a.updateSelectedPubmedIdsAction]: (state, { payload }) =>
      state
        .update('selectedPubmedIds', () => fromJS(payload)),
    [a.disableReloadingAction]: (state, { payload }) =>
      state
        .update('reloadingIsDisabled', () => fromJS(payload)),
    [a.setSearchConceptsAction]: (state, { payload }) =>
      state
        .update('searchConcepts', () => fromJS(payload)),
    [a.resetSearchConceptsAction]: state =>
      state
        .update('searchConcepts', () => initialState.get('searchConcepts')),
    [a.resetRelatedConceptsAction]: state =>
      state
        .update('categoriesSearch', () => initialState.get('categoriesSearch')),
    [a.clearSelectedPublicationsDataAction]: state =>
      state
        .update('selectedPubmedIds', () => initialState.get('selectedPubmedIds'))
        .update('selectedPublicationsIds', () => initialState.get('selectedPublicationsIds')),
    [a.setPublicationFilterExtendedAction]: (state, { payload }) =>
      state
        .update('publicationFilterExtended', () => payload),
    [a.resetSearchResultsAction]: state =>
      state
        .update('searchTerm', () => initialState.get('searchTerm'))
        .update('exactSearchTerm', () => initialState.get('exactSearchTerm'))
        .update('selectedPublicationsIds', () => initialState.get('selectedPublicationsIds'))
        .update('selectedPubmedIds', () => initialState.get('selectedPubmedIds'))
        .update('result', () => initialState.get('result')),
  },
  initialState
);

export default reducer;
