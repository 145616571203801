/* eslint-disable no-plusplus */
import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';
import { drawGradientLegend } from './Legends/GradientLegend/drawGradientLegend';
import { CELLS_CHART, GENE_CELLS_CHART } from '../../Workflows/Algorithms/SingleCellStudyViewer/constants';

export function drawScatterPlot({
  id,
  type,
  data = [],
  chartData,
  measure,
  chartTitle,
  showTitle,
  legendData,
  filtersAreEmpty,
  handleDrawEnding,
  backgroundGraph,
  topRightText,
  measureExtremes = {},
}) {
  const config = {
    width: 500,
    height: 500,
    margin: {
      top: 50,
      bottom: 80,
      left: 80,
      right: 50,
    },
  };

  if (showTitle) config.margin.top += 20;

  const {
    minX,
    maxX,
    minY,
    maxY,
  } = measure || {};

  function getMaxMeasureExtremes() {
    const { max } = measureExtremes;
    if (!max) return 0;
    if (max > 0 && max <= 0.5) return 0.5;
    if (max > 0.5 && max <= 1) return 1;
    if (max > 1 && max <= 2) return 2;
    if (max > 2 && max <= 3) return 3;
    if (max > 3 && max <= 4) return 4;
    if (max > 4 && max <= 5) return 5;
    return max;
  }

  const domainTo = getMaxMeasureExtremes();

  d3js.selectAll(`#${id} > *`).remove();

  let chartWidth = config.width + config.margin.left + config.margin.right;
  if (type === GENE_CELLS_CHART) {
    chartWidth += 130;
  }

  const chartHeight = config.height + config.margin.top + config.margin.bottom;
  const svg = d3js.select(`#${id}`)
    .append('svg')
    .attr('width', chartWidth)
    .attr('height', chartHeight)
    .append('g')
    .attr('transform',
      `translate(${config.margin.left},${config.margin.top})`);

  const x = d3js.scaleLinear()
    .domain([minX, maxX])
    .range([0, config.width]);

  const y = d3js.scaleLinear()
    .domain([minY, maxY])
    .range([config.height, 0]);

  const xAxis = svg.append('g')
    .attr('transform', `translate(0, ${config.height})`)
    .call(d3js.axisBottom(x));

  xAxis.append('text')
    .attr('y', '3.15em')
    .attr('dx', config.width / 2)
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text('UMAP1');

  xAxis.selectAll('text')
    .style('font-size', '16px')
    .style('cursor', 'default');

  const yAxis = svg.append('g')
    .call(d3js.axisLeft(y));

  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -(config.height / 2))
    .attr('dy', '-2.15em')
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text('UMAP2');

  yAxis.selectAll('text')
    .style('font-size', '16px')
    .style('cursor', 'default');

  if (filtersAreEmpty || data.length === chartData.length || type === GENE_CELLS_CHART) {
    yAxis
      .append('image')
      .attr('xlink:href', backgroundGraph)
      .attr('x', '1px')
      .attr('y', '0px')
      .style('width', '497px')
      .style('height', '497px');
  }

  if ((data.length !== chartData.length && !filtersAreEmpty) || type === GENE_CELLS_CHART) {
    yAxis.append('g')
      .selectAll('dot')
      .data(chartData)
      .join('circle')
      .attr('cx', d => x(d.umapX))
      .attr('cy', d => y(d.umapY))
      .attr('r', 0.5);

    if (type === GENE_CELLS_CHART) {
      const mainColors = d3js.scaleLinear()
        .range(['#BEBEBE', '#000000'])
        .domain([0, domainTo]);
      yAxis.selectAll('circle')
        .style('fill', d => mainColors(d.measure));
    } else {
      yAxis.selectAll('circle')
        .style('fill', d => d.color);
    }
  }

  if (showTitle) {
    const downloadedSvg = d3js.select(`#${id}`)
      .select('svg');

    downloadedSvg
      .insert('text', ':first-child')
      .text(chartTitle)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    if (type === CELLS_CHART) {
      downloadedSvg
        .attr('width', chartWidth + 300);

      // Legend for cells
      const legend = d3js.select(`#${id} svg`)
        .append('g')
        .attr('transform',
          `translate(${config.width},${-30})`);
      const colors = legendData.map(item => item.color);
      const keys = legendData.map(item => item.value);
      drawLegend({
        element: legend,
        keys,
        colors,
      });
    }
  }

  if (type === GENE_CELLS_CHART) {
    const legend = d3js.select(`#${id} svg`)
      .append('g')
      .attr('id', 'legend')
      .attr('transform',
        `translate(${config.width + 130},${70})`);
    drawGradientLegend({ domainTo, element: legend, type: 'SINGLE_CELL' });
  }

  if (topRightText) {
    d3js.select(`#${id} svg`)
      .append('g')
      .attr('id', 'text-legend')
      .attr('transform', 'translate(595,50)')
      .append('text')
      .attr('x', '0')
      .attr('y', '0')
      .style('font-size', '16px')
      .style('fill', 'black')
      .text(topRightText);
  }

  handleDrawEnding();
}
