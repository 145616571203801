import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { defaultProjects } from '../../../Projects/ProjectsTabs/constants';

export const getRelationMapManagementDataAction = createAction('@@relationMap/MANAGEMENT_GET_DATA');
export const setRelationMapManagementDataAction = createAction('@@relationMap/MANAGEMENT_SET_DATA');
export const throwRelationMapManagementErrorAction = createAction('@@relationMap/MANAGEMENT_THROW_ERROR');
export const clearRelationMapManagementAction = createAction('@@relationMap/MANAGEMENT_CLEAR');
export const sortRelationMapManagementAction = createAction('@@relationMap/MANAGEMENT_SORT');
export const filterRelationMapManagementAction = createAction('@@relationMap/MANAGEMENT_FILTER');
export const deleteRelationMapManagementProjectsAction = createAction('@@relationMap/MANAGEMENT_DELETE_PROJECTS');
export const checkRelationMapManagementItemAction = createAction('@@relationMap/MANAGEMENT_CHECK_ITEM');
export const checkAllRelationMapManagementItemsAction = createAction('@@relationMap/MANAGEMENT_CHECK_ALL');
export const dublicateRelationMapManagementProjectsAction = createAction('@@relationMap/MANAGEMENT_DUBLICATE_PROJECTS');
export const showAllRelationMapManagementConceptsAction = createAction('@@relationMap/MANAGEMENT_SHOW_ALL_CONCEPTS');

export const clearRelationMapManagemenActiveProjectIdAction = createAction('@@relationMap/CLEAR__ACTIVE_PROJECT_ID');
export const setRelationMapManagemenActiveProjectIdAction = createAction('@@relationMap/SET_ACTIVE_PROJECT_ID');

const initialState = fromJS({
  projects: null,
  loading: true,
  error: null,
  sorting: { sortBy: 'lastOpened', sortDirection: 'DESC' },
  filters: {
    title: '',
    tag: '',
    concept: '',
  },
  activeProjectId: defaultProjects[0].id,
});

const reducer = handleActions(
  {
    [getRelationMapManagementDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setRelationMapManagementDataAction]: (state, { payload }) =>
      state
        .update('projects', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null),
    [throwRelationMapManagementErrorAction]: (state, { payload }) =>
      state
        .update('projects', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [sortRelationMapManagementAction]: (state, { payload }) =>
      state
        .update('sorting', () => fromJS(payload)),
    [filterRelationMapManagementAction]: (state, { payload }) =>
      state
        .updateIn(['filters', `${payload.key}`], () => fromJS(payload.value)),
    [checkAllRelationMapManagementItemsAction]: (state, { payload }) =>
      state
        .update('projects', data => (
          data.map(item => (
            item.merge(fromJS({ selected: payload }))
          ))
        )),
    [checkRelationMapManagementItemAction]: (state, { payload }) =>
      state
        .update('projects', data => (
          data.map(item => (
            item.get('id') === payload.id ?
              item.merge(fromJS({ selected: payload.checked })) :
              item
          ))
        )),
    [showAllRelationMapManagementConceptsAction]: (state, { payload }) =>
      state
        .update('projects', data => (
          data.map(item => (
            item.get('id') === payload.id ?
              item.merge(fromJS({ showAll: payload.show })) :
              item
          ))
        )),
    [clearRelationMapManagementAction]: state =>
      state
        .merge({
          ...initialState,
          activeProjectId: state.get('activeProjectId'),
        }),
    [setRelationMapManagemenActiveProjectIdAction]: (state, { payload }) =>
      state
        .update('activeProjectId', () => payload),
    [clearRelationMapManagemenActiveProjectIdAction]: state =>
      state
        .update('activeProjectId', () => initialState.get('activeProjectId')),
  },
  initialState
);

export default reducer;
