import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Icons
import { FaInfo } from 'react-icons/fa';
import { AiOutlineUndo } from 'react-icons/ai';
// Utils
import { drawViolinChart } from './drawViolinChart';
import { convertTableToCSVText, exportToFile, exportToPNG, exportToSVG } from '../../Utils/Utils';
import { prepareViolinChartData, scaleTransformData } from '../../GeneDetails/GeneExpressionChapter/utils';
// Components
import Error from '../../common/Error/Error';
import Tooltip from '../../common/Tooltip/Tooltip';
import RadioInput from '../../common/Inputs/RadioInput/RadioInput';
import ButtonCircle from '../../common/Buttons/ButtonCircle/ButtonCircle';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
import LegendComponent from '../LegendComponent/LegendComponent';
import Button from '../../common/Buttons/Button/Button';
import Spinner from '../../common/Spinner/Spinner';
import SelectInput from '../../common/Inputs/SelectInput/SelectInput';
// Constants
import { scaleOptions } from '../../../constantsCommon';
import { graphTypes, proteinExpressionSortingOptions } from '../../GeneDetails/GeneExpressionChapter/constants';
// Styles
import './styles.scss';

const propTypes = {
  chartTitle: PropTypes.string,
  chartWrapId: PropTypes.string,
  geneName: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  isNeededTransform: PropTypes.bool,
  isHiddenExport: PropTypes.bool,
  chartName: PropTypes.string,
  chartData: PropTypes.instanceOf(Array),
  switchOpenModal: PropTypes.func,
  violinPlotTransform: PropTypes.string,
  changeViolinTransform: PropTypes.func,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  showLegend: PropTypes.bool,
  legendsCustoms: PropTypes.instanceOf(Object),
  settings: PropTypes.instanceOf(Object),
  customClassName: PropTypes.string,
  yAxisText: PropTypes.string,
  info: PropTypes.string,
  setTissuesSelectionMode: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setSelectedCells: PropTypes.func,
  selectedCells: PropTypes.instanceOf(Array),
  getSamplesDataForCsvExport: PropTypes.func,
  geneId: PropTypes.string,
  csvName: PropTypes.string,
  csvDownloadedSamplesLoading: PropTypes.bool,
  isCsvDownloadingAllowed: PropTypes.bool,
  showExpressionSortSelector: PropTypes.bool,
  showUniprotSelector: PropTypes.bool,
  setSelectedSortingOption: PropTypes.func,
  selectedSortingOption: PropTypes.instanceOf(Object),
  sortingOptions: PropTypes.instanceOf(Array),
  includeZeros: PropTypes.bool,
  selectedUniprot: PropTypes.string,
  uniprotOptions: PropTypes.instanceOf(Object),
  setSelectedUniprotOption: PropTypes.func,
  sampleMeasure: PropTypes.instanceOf(Object),
  sampleName: PropTypes.instanceOf(Object),
  showProteinExpressionSortSelector: PropTypes.bool,
  setSelectedProteinExpressionOption: PropTypes.func,
  selectedProteinExpression: PropTypes.instanceOf(Object),
};

const ViolinChart = (props) => {
  const {
    error,
    chartTitle,
    chartWrapId,
    geneName,
    geneId,
    chartName,
    chartData,
    type,
    settings,
    isNeededTransform,
    isHiddenExport,
    switchOpenModal,
    violinPlotTransform = 'linear',
    showTooltip,
    hideTooltip,
    showLegend,
    legendsCustoms,
    setShortConceptCardId,
    changeViolinTransform,
    customClassName,
    yAxisText = '',
    info,
    selectedCells,
    setSelectedCells,
    tissuesSelectionMode,
    unsetTissuesSelectionMode,
    setTissuesSelectionMode,
    getSamplesDataForCsvExport,
    csvName,
    csvDownloadedSamplesLoading,
    isCsvDownloadingAllowed,
    showExpressionSortSelector,
    showUniprotSelector,
    showProteinExpressionSortSelector,
    setSelectedSortingOption,
    selectedSortingOption,
    sortingOptions,
    includeZeros,
    selectedUniprot,
    uniprotOptions,
    setSelectedUniprotOption,
    selectedProteinExpression,
    setSelectedProteinExpressionOption,
    sampleMeasure,
    sampleName,
  } = props;

  const downloadLinkRef = useRef(null);
  const [drawing, setDrawing] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [transformedData, setTransformedData] = useState([]);

  const showTooltipHandler = useCallback((d, x, y) => {
    if (d) {
      const config = {
        uniqueKey: `${type}__violin-tooltip`,
        clientX: x,
        clientY: y,
      };
      setShortConceptCardId(d.concept.id);
      showTooltip(config);
    }
  }, [setShortConceptCardId, showTooltip]);

  const isSamplesDataExist = type === graphTypes.CCLE || type === graphTypes.CCLE_PROTEOMICS || type === graphTypes.SANGER_CELL_MODEL;

  const handleCellClick = useCallback((d) => {
    const concept = chartData.find(el => el.concept.name === d);
    if (setSelectedCells && tissuesSelectionMode) {
      setSelectedCells(concept);
    } else if (!tissuesSelectionMode) {
      const tissuesGroup = concept.tissuesGroup || type;
      switchOpenModal(d, concept, tissuesGroup, tissuesGroup);
    }
  }, [chartData, setSelectedCells, tissuesSelectionMode, type]);

  function drawChart(showTitle = false) {
    drawViolinChart({
      data: transformedData,
      htmlRootId: chartWrapId,
      tooltipMode: type,
      type,
      settings,
      showTitle,
      showLegend,
      legendsCustoms,
      chartTitle,
      geneName,
      violinScale: violinPlotTransform,
      hideTooltip: hideTooltip && hideTooltip,
      ...(showTooltip && { showTooltipHandler }),
      yAxisText,
      selectedCells,
      handleCellClick,
      includeZeros,
    });
    setTimeout(() => { setDrawing(false); }, 1500);
  }

  const exportToPNGCb = useCallback(() => {
    drawChart(true);
    const diagram = document.querySelector(`#${chartWrapId} svg`);
    const name = `${chartName}_${geneName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, type === 'CCLE', `${geneName}`);
    }
    drawChart();
  }, [chartWrapId, chartName, geneName, type, drawChart]);

  const exportToSVGCb = useCallback(() => {
    drawChart(true);
    const container = document.querySelector(`#${chartWrapId}`);
    const name = `${chartName}_${geneName}.svg`;
    if (container) {
      exportToSVG(container, name, false, false, type === 'CCLE', `${geneName}`);
    }
    drawChart();
  }, [chartWrapId, chartName, geneName, type, drawChart]);

  const exportToCSV = (data) => {
    if (isSamplesDataExist) {
      const { key: sampleMeasureKey } = sampleMeasure;
      const dataForDownload = scaleTransformData(violinPlotTransform, data.reduce((acc, item) => {
        const samplesByGroup = item.samplesData.map(s => ({ ...s, tissue: item.concept.name, [sampleMeasureKey]: s[sampleMeasureKey].toFixed(2) }));
        return acc.concat(samplesByGroup);
      }, []), sampleMeasureKey);

      const columns = [
        {
          label: 'Tissue',
          dataKey: 'tissue',
        },
        {
          label: [sampleName.label],
          dataKey: [sampleName.key],
        },
        {
          label: [sampleMeasure.label],
          dataKey: [sampleMeasure.key],
        },
      ];

      const csvData = convertTableToCSVText(dataForDownload.flat(), columns);
      exportToFile(downloadLinkRef.current, csvData, csvName);
    } else {
      getSamplesDataForCsvExport({
        geneId,
        type,
        csvName,
        data,
        scale: scaleOptions[violinPlotTransform].value,
        downloadLinkRef: downloadLinkRef.current,
      });
    }
  };

  const handleExportAllToCSV = useCallback(() => {
    exportToCSV(chartData);
  }, [chartData, violinPlotTransform]);

  const handleExportToCSV = useCallback(() => {
    if (selectedCells.length) {
      exportToCSV(selectedCells);
    }
  }, [selectedCells, violinPlotTransform]);

  useEffect(() => {
    if (transformedData) {
      drawChart();
    }
  }, [transformedData, tissuesSelectionMode, selectedCells]);

  useEffect(() => {
    const preparedData = prepareViolinChartData(chartData, type, violinPlotTransform, includeZeros);
    setTransformedData(preparedData);
  }, [chartData, violinPlotTransform, includeZeros]);

  useEffect(() => () => {
    const tooltip = document.querySelector(`.chart-tooltip_${type}`);
    if (tooltip) {
      tooltip.remove();
    }
  }, []);

  return (
    <div className={classNames('target-candidates-chart violin-chart', customClassName)}>
      <div>
        <div className="violin-chart__controls-wrapper">
          {
            <div className="gene-expression-sort-methods">
              {
                showUniprotSelector &&
                <div className="gene-expression-sort-methods__uniprot">
                  <span className="gene-expression-sort-methods__label">Uniprot Accession: </span>
                  <SelectInput
                    options={uniprotOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                    onSelect={option => setSelectedUniprotOption({ option, type })}
                    defaultValue={uniprotOptions.find(o => o.value === selectedUniprot)}
                  />
                </div>
              }
              {
                showProteinExpressionSortSelector &&
                <div className="gene-expression-sort-methods__protein-expression">
                  <span className="gene-expression-sort-methods__label">Protein expression: </span>
                  <SelectInput
                    onSelect={option => setSelectedProteinExpressionOption({ option, type })}
                    defaultValue={selectedProteinExpression}
                    options={proteinExpressionSortingOptions}
                    placeholder="Select"
                    closeOnSelect={true}
                    bordered={true}
                    customClassName="gene-expression-sort-methods__selector"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                  />
                </div>
              }
              {
                showExpressionSortSelector &&
                  <div className="gene-expression-sort-methods__expression-sorting">
                    <span className="gene-expression-sort-methods__label">Sorting: </span>
                    <SelectInput
                      onSelect={option => setSelectedSortingOption({ option, type })}
                      defaultValue={selectedSortingOption}
                      options={sortingOptions}
                      placeholder="Select"
                      closeOnSelect={true}
                      bordered={true}
                      customClassName="gene-expression-sort-methods__selector"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                    />
                  </div>
              }
            </div>
          }
          <div className="boxplot-controls">
            {
              !drawing && isNeededTransform &&
              <div className="boxplot-controls__radio">
                <span className="boxplot-controls__text">Scale:</span>
                <RadioInput
                  value="linear"
                  checked={violinPlotTransform === 'linear'}
                  onChange={changeViolinTransform}
                  labelValue="Linear"
                />
                <RadioInput
                  value="log"
                  checked={violinPlotTransform === 'log'}
                  onChange={changeViolinTransform}
                  labelValue="Log"
                />
              </div>
            }
            {
              !drawing && !isHiddenExport &&
              <div className="bar-chart-controls">
                <div className="bar-chart-export-button-wrapper">
                  <div
                    className="export-button bar-chart-controls__png"
                    onClick={exportToPNGCb}
                    title="Export to png"
                  />
                  PNG
                </div>
                <div className="bar-chart-export-button-wrapper">
                  <div
                    className="export-button"
                    onClick={exportToSVGCb}
                    title="Export to svg"
                  />
                  SVG
                </div>
                {
                  info &&
                  <ButtonCircle
                    icon={<FaInfo size={12} color="#ffffff" />}
                    onClick={() => { setShowInfo(!showInfo); }}
                    customClassName="violin-chart-btn"
                  />
                }
              </div>
            }
          </div>
          {
            showInfo &&
            <div className="violin-chart-info">
              {info}
            </div>
          }
        </div>
        {
          isCsvDownloadingAllowed &&
          <div className="csv-downloading-block">
            <div className="csv-btn-group btn-group">
              {
                !drawing &&
                <>
                  <Spinner
                    isLoading={csvDownloadedSamplesLoading}
                    containerClassName="csv-btn-group__spiner"
                  />
                  <label htmlFor="export">To CSV:</label>
                  {
                    <Button
                      text="All"
                      onClick={handleExportAllToCSV}
                      disabled={csvDownloadedSamplesLoading}
                      customClassName="btn btn-group__first-btn"
                    />
                  }
                  {
                    !tissuesSelectionMode &&
                    <Button
                      text="Allow tissues selection"
                      onClick={setTissuesSelectionMode}
                      disabled={csvDownloadedSamplesLoading}
                      customClassName="btn btn-group__second-btn"
                    />
                  }
                  {
                    tissuesSelectionMode &&
                    <Button
                      text="Export selected"
                      onClick={handleExportToCSV}
                      disabled={!selectedCells.length || csvDownloadedSamplesLoading}
                      customClassName="btn btn-group__second-btn"
                    />
                  }
                  {
                    <Button
                      onClick={unsetTissuesSelectionMode}
                      customClassName="btn btn-group__last-btn"
                    >
                      <AiOutlineUndo size={24} />
                    </Button>
                  }
                  <a //eslint-disable-line
                    hidden={true}
                    ref={downloadLinkRef}
                  />
                </>
              }
            </div>
            {
              tissuesSelectionMode &&
              <div className="violin-chart__csv-text">
                Select tissue names on x-axis to export data as CSV
              </div>
            }
          </div>
        }
      </div>
      <div
        id={chartWrapId}
        className="violin-chart__wrap"
      />
      {
        !drawing && showLegend &&
        <LegendComponent
          legendsParams={legendsCustoms.params}
          legendsColors={legendsCustoms.colors}
          keyToName="name"
        />
      }
      <Error show={!!error} error={error} />
      <Tooltip uniqueKeyProp={`${type}__violin-tooltip`}>
        <ShortConceptCard />
      </Tooltip>
    </div>
  );
};

ViolinChart.propTypes = propTypes;

export default React.memo(ViolinChart);
