import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
// Components
import SelectInput from '../Inputs/SelectInput/SelectInput';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  getSelectGeneOptions: PropTypes.func,
  resetSelectGeneOptions: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  geneName: PropTypes.string,
  customClassName: PropTypes.string,
  clearFieldAfterSelect: PropTypes.bool,
  searchGeneForIndicationFinder: PropTypes.bool,
};

const SelectGene = (props) => {
  const {
    options,
    loading,
    onSubmit,
    getSelectGeneOptions,
    resetSelectGeneOptions,
    geneName,
    customClassName,
    searchGeneForIndicationFinder,
    clearFieldAfterSelect,
    placeholder = 'Type human gene name',
  } = props;

  const [inputValue, setInputValue] = useState(geneName || '');

  function handleInputChange(value) {
    setInputValue(value);
  }

  function fetchOptions(value) {
    handleInputChange(value);
    if (value) {
      getSelectGeneOptions({ searchGeneForIndicationFinder, value });
    } else {
      resetSelectGeneOptions();
    }
  }

  function switchGene(gene) {
    if (clearFieldAfterSelect) {
      handleInputChange('');
    }
    resetSelectGeneOptions();
    onSubmit(gene);
  }

  return (
    <div className={classnames('select-gene', customClassName)}>
      <SelectInput
        bordered={true}
        value={inputValue}
        options={options}
        isLoading={loading}
        onInputChange={fetchOptions}
        onSelect={switchGene}
        filterWithOptions={false}
        title="Type human gene name"
        placeholder={placeholder}
        noOptionsMessage={() => 'There is no data to display'}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

SelectGene.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: SELECTORS.getGeneOptionSelector(state),
    loading: SELECTORS.getGeneLoaderSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSelectGeneOptions(data) {
      dispatch(ACTIONS.getGeneOptionsAction(data));
    },
    resetSelectGeneOptions(data) {
      dispatch(ACTIONS.resetSelectGeneAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectGene);
