import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3js from 'd3';

// Store
import * as ACTIONS from './actions';
import { dataSelectionTableData } from '../../Sets/SetsDataSelection/selectors';
// Styles
import './VennDiagram.scss';

const propTypes = {
  intersectionSelectedAdd: PropTypes.func,
  vennData: PropTypes.instanceOf(Object),
  dataSelection: PropTypes.instanceOf(Array),
};

const VennDiagram = (props) => {
  const {
    dataSelection,
    intersectionSelectedAdd,
    vennData: { data, colors },
  } = props;

  const diagramData = useMemo(() => data.map(el => (
    el.name.length >= 25 ?
      {
        ...el,
        name: `${el.name.slice(0, 25)}...`,
        fullName: el.name,
      } :
      {
        ...el,
        fullName: el.name,
      }
  )), [data]);

  const initDiagram = useCallback(() => {
    if (diagramData.length) {
      window.jQuery('.venn-container').jvenn({
        fontSize: '10px',
        displayMode: diagramData.length === 6 ? 'edwards' : 'classic',
        series: diagramData,
        colors,
        shortNumber: false,
        displayStat: true,
        searchInput: window.jQuery('.search-field'),
        searchStatus: window.jQuery('.search-status'),
        searchMinSize: 1,
        fnClickCallback(e) {
          const items = e.target.list.map(item => Number(item));
          intersectionSelectedAdd(items);
        },
      });
    }

    function getTextTooltipTemplate(d) {
      return (`
        <div class="chart-tooltip-bar__content venn-tooltip">
          <b>${d}</b>
        </div>
      `);
    }

    for (let i = 1; i <= 6; i += 1) {
      const graphData = diagramData[i - 1];
      d3js.select(`#label${i}`)
        .on('mouseover', () => {
          d3js.select(`#label${i}`)
            .append('div')
            .style('position', 'absolute')
            .html(getTextTooltipTemplate(graphData && graphData.fullName));
        })
        .on('mouseout', () => {
          d3js.select(`#label${i} div`)
            .remove();
        });
    }
  }, [diagramData, intersectionSelectedAdd, colors]);

  useEffect(() => {
    initDiagram();
  }, [data, initDiagram]);

  useEffect(() => {
    const elementsArray = document.querySelectorAll('.number-black:not(.number-empty)');
    const dataSelectionArray = dataSelection.filter(el => el.selected === true).map(el => el.id);
    elementsArray.forEach((element) => {
      const { list } = element;
      // eslint-disable-next-line no-param-reassign
      if (list.every(el => dataSelectionArray.includes(+el))) element.style.fontSize = '13px';
    });
  }, [dataSelection]);

  return (
    <div className="venn-diagram">
      <div className="venn-container" />
      <input type="hidden" className="search-field" />
      <input type="hidden" className="search-status" />
    </div>
  );
};

VennDiagram.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    intersectionSelectedAdd(ids) {
      dispatch(ACTIONS.intersectionSelectedAdd(ids));
    },
  };
}

function mapStateToProps(state) {
  return {
    dataSelection: dataSelectionTableData(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VennDiagram);

