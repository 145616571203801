import { createAction } from 'redux-actions';

export const getSearchWithSetsDataAction = createAction('@@search-with-sets/GET_DATA');
export const putSearchWithSetsDataAction = createAction('@@search-with-sets/PUT_DATA');
export const throwSearchWithSetsErrorAction = createAction('@@search-with-sets/THROW_ERROR');
export const resetSearchWithSetsPageAction = createAction('@@search-with-sets/RESET');
export const sortSearchWithSetsDataAction = createAction('@@search-with-sets/SORT');
export const changeSearchWithSetsPageAction = createAction('@@search-with-sets/CHANGE_PAGE');
export const exportSearchWithSetsDataAction = createAction('@@search-with-sets/EXPORT_DATA');
export const updateSearchWithSetsCooccurrenceTypeAction = createAction('@@search-with-sets/UPDATE_COOCCURRENCE_TYPE');

