import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Error from '../../common/Error/Error';
import RestorePasswordForm from './RestorePasswordForm';
// Store
import { restorePassword } from './actions';
// Styles
import './RestorePassword.css';

const propTypes = {
  error: PropTypes.string,
  dispatchRestorePassword: PropTypes.func,
};

const RestorePassword = (props) => {
  const {
    error,
    dispatchRestorePassword,
  } = props;

  return (
    <div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <h3 className="title3">Restore Password</h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <Error show={!!error} error={error} />
          <RestorePasswordForm onSubmit={dispatchRestorePassword} />
        </div>
      </div>
    </div>
  );
};

RestorePassword.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: state.get('restorePassword').get('error'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRestorePassword() {
      dispatch(restorePassword());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestorePassword);
