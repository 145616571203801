import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getCompareSetsDataAction = createAction('@@compare-sets/GET_DATA');
export const setCompareSetsDataAction = createAction('@@compare-sets/SET_DATA');
export const throwCompareSetsErrorAction = createAction('@@compare-sets/THROW_ERROR');
export const resetCompareSetsDataAction = createAction('@@compare-sets/RESET_DATA');

const initialState = fromJS({
  sets: [],
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [getCompareSetsDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setCompareSetsDataAction]: (state, { payload }) =>
      state
        .update('sets', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null),
    [throwCompareSetsErrorAction]: (state, { payload }) =>
      state
        .update('sets', () => fromJS([]))
        .update('loading', () => false)
        .update('error', () => payload),
    [resetCompareSetsDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
