import { createSelector } from 'reselect';

const disease = state => state.getIn(['setAnalysisMethodSelection', 'geneVariationDisease']);

export const getGeneVariationTerm = createSelector(
  disease,
  (data) => {
    if (!data) {
      return data;
    }
    return data.toJS();
  }
);
