import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import SaveAsSetModal from '../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import DropdownMenuComponent from '../../../common/DropdownMenuComponent/DropdownMenuComponent';
import NetworkAnalysisSettings from '../../NetworkAnalysis/NetworkAnalysisSettings/NetworkAnalysisSettings';
import GeneVariationSettings from '../../RankSelection/GeneVariationSettings/GeneVariationSettings';
import DifferentialExpressionSettings from '../../RankSelection/DifferentialExpressionSettings/DifferentialExpressionSettings';
import ExpressionSpecificitySettings from '../../RankSelection/ExpressionSpecificitySettings/ExpressionSpecificitySettings';
import AssociationScoreTerms from '../../RankSelection/AssociationScoreTerms/AssociationScoreTerms';
import RankingMarkerSettings from '../../RankSelection/RankingMarkerSettings/RankingMarkerSettings';
import LiteratureSearchTerms from '../../RankSelection/LiteratureSearchTerms/LiteratureSearchTerms';
import GeneSetOptions from './GeneSetOptions';
import NoGeneSetOptions from './NoGeneSetOptions';
import TargetValidationStageOption from './TargetValidationStageOption';
import Loader from '../../../common/Loader/Loader';
// Store
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import { clearLiteratureSearchTermsAction } from '../../RankSelection/LiteratureSearchTerms/actions';
import { clearAssociationScoreTerms } from '../../RankSelection/AssociationScoreTerms/actions';
import { clearGeneVariationTerm } from '../../RankSelection/GeneVariationSettings/actions';
import { findRelatedTableResetAction } from '../../FindRelated/FindRelatedResult/reducer';
import {
  requestRanking,
  reset as resetRanking,
} from '../../RankSelection/SetAnalysisRankingResult/actions';
import {
  networkAnalysis,
  reset as resetNetwork,
} from '../../NetworkAnalysis/SetAnalysisNetworkAnalysisResult/actions';
import {
  firstSelectedMethodSelector,
  getSelectedCategoryType,
  selectedMethodSelector,
} from '../SetAnalysisResults/selectors';
import { getMethodSelectionLoadingFlag } from '../SetAnalysis/selectors';
import * as SELECTORS from './selectors';
import * as ACTIONS from './actions';
// Utils
import {
  methodTabEnum,
  methodTabLabelEnum,
} from './enum';
import {
  anyKeyTrue,
  setContainsGenes,
  getLocationParams,
  setContainsProteins,
} from '../../../Utils/Utils';
import { analysisMethodEnum } from '../../FindRelated/FindRelatedResult/enums';
// Styles
import './SetAnalysisMethodSelection.scss';

const propTypes = {
  refreshRankingSettings: PropTypes.func,
  setActiveTab: PropTypes.func,
  initAnalyticsMethod: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  selectCategoryType: PropTypes.func,
  analyseData: PropTypes.instanceOf(Array),
  methodSelected: PropTypes.func,
  resetRankingResult: PropTypes.func,
  requestRanking: PropTypes.func,
  resetNetworkResult: PropTypes.func,
  requestNetwork: PropTypes.func,
  selectCategoryName: PropTypes.func,
  rankingTypes: PropTypes.instanceOf(Object),
  selectedTissue: PropTypes.string,
  openRankingModal: PropTypes.func,
  clearAssociationScoreTerms: PropTypes.func,
  setRanking: PropTypes.func,
  clearLiteratureSearchTerms: PropTypes.func,
  clearDifferentialExpressionScore: PropTypes.func,
  clearExpressionSpecificityScore: PropTypes.func,
  rankingType: PropTypes.string,
  clearGeneVariation: PropTypes.func,
  setRankingType: PropTypes.func,
  closeRankingModal: PropTypes.func,
  menuClose: PropTypes.func,
  menuOpen: PropTypes.func,
  selectedConcept: PropTypes.instanceOf(Object),
  differentialExpressionTerm: PropTypes.instanceOf(Object),
  networkAnalysisSettings: PropTypes.instanceOf(Object),
  methodTab: PropTypes.string,
  openMenu: PropTypes.bool,
  categoriesItems: PropTypes.instanceOf(Array),
  types: PropTypes.instanceOf(Array),
  rankingModals: PropTypes.instanceOf(Object),
  associationScoreTerms: PropTypes.instanceOf(Array),
  literatureSearchTerms: PropTypes.instanceOf(Array),
  geneVariationTerm: PropTypes.instanceOf(Object),
  backgroundSetConfig: PropTypes.instanceOf(Object),
  openSelectBackgroundSetPopup: PropTypes.func,
  backgroundCellsFilterOption: PropTypes.string,
  backgroundSetFilterOption: PropTypes.string,
  prevSelectedMethod: PropTypes.string,
  switchShouldUpdateDataFlag: PropTypes.func,
  initTissuesAndCategories: PropTypes.func,
  loadingFlag: PropTypes.bool,
  selectedTissueOption: PropTypes.instanceOf(Object),
  firstSelectedMethod: PropTypes.string,
};

class SetAnalysisMethodSelection extends React.Component {
  state = {
    addSetPopup: {
      show: false,
      ids: null,
    },
  };
  actions = {
    menuClose: this.props.menuClose,
    menuOpen: this.props.menuOpen,
    select: this.props.selectCategoryName,
  };

  componentDidMount() {
    const {
      initAnalyticsMethod,
      location,
    } = this.props;
    const { runAfter } = !!location.search && getLocationParams(location.search);

    if (runAfter) {
      this.analyse(runAfter);
      initAnalyticsMethod(runAfter);
    }
  }

  componentDidUpdate(prevProps) {
    const { firstSelectedMethod, initAnalyticsMethod } = this.props;
    const { firstSelectedMethod: prevFirstSelectedMethod } = prevProps;

    if (firstSelectedMethod !== prevFirstSelectedMethod) {
      if (firstSelectedMethod === analysisMethodEnum.RANKING) {
        this.rankRequest();
      } else if (firstSelectedMethod === analysisMethodEnum.NETWORK) {
        this.networkRequest();
      } else {
        this.analyse(firstSelectedMethod);
        initAnalyticsMethod(firstSelectedMethod);
      }
    }
  }

  openAddSetPopup = () => {
    const { analyseData } = this.props;
    this.setState({
      addSetPopup: {
        show: true,
        ids: analyseData.map(item => (
          item.id
        )),
      },
    });
  };

  closeAddSetPopup = () => {
    this.setState({
      addSetPopup: {
        show: false,
        ids: null,
      },
    });
  };

  selectCategoryType = (data) => {
    const {
      methodSelected,
      selectCategoryType,
    } = this.props;

    selectCategoryType(data);
    methodSelected(analysisMethodEnum.CATEGORY);
  };

  rankRequest = () => {
    this.props.resetRankingResult();
    this.props.requestRanking({
      concepts: this.props.analyseData.map(concept => (
        concept.id
      )),
    });
    this.props.methodSelected(analysisMethodEnum.RANKING);
  };

  networkRequest = () => {
    this.props.resetNetworkResult();
    this.props.requestNetwork({
      post: {
        startSetIds: this.props.analyseData.map(concept => (
          concept.id
        )),
      },
    });
    this.props.methodSelected(analysisMethodEnum.NETWORK);
  };

  analyseClickHandler = e => this.analyse(e.target.dataset.method);

  analyse = (method) => {
    const {
      selectCategoryName,
      methodSelected,
      prevSelectedMethod,
      switchShouldUpdateDataFlag,
      initTissuesAndCategories,
    } = this.props;

    if (prevSelectedMethod === method) {
      switchShouldUpdateDataFlag(true);
    }
    initTissuesAndCategories();

    switch (method) {
      case analysisMethodEnum.PATHWAY:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.PATHWAY);
        break;

      case analysisMethodEnum.REGULATION:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.REGULATION);
        break;

      case analysisMethodEnum.PROTEIN_COMPLEX:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.PROTEIN_COMPLEX);
        break;

      case analysisMethodEnum.COMPOUND:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.COMPOUND);
        break;

      case analysisMethodEnum.PHENOTYPE:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.PHENOTYPE);
        break;

      case analysisMethodEnum.DISORDER:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.DISORDER);
        break;

      case analysisMethodEnum.METABOLITE:
        selectCategoryName(null);
        methodSelected(analysisMethodEnum.METABOLITE);
        break;

      case analysisMethodEnum.CATEGORY:
        break;

      default:
        break;
    }
  };

  rankingClickHandler = (key) => {
    const {
      setRanking,
      rankingType,
      rankingTypes,
      openRankingModal,
      clearAssociationScoreTerms,
      clearLiteratureSearchTerms,
      clearDifferentialExpressionScore,
      clearExpressionSpecificityScore,
      clearGeneVariation,
    } = this.props;

    if (key === 'associationScore') {
      if (!rankingTypes[key]) {
        openRankingModal(key);
      } else {
        clearAssociationScoreTerms();
        setRanking({ [key]: !rankingTypes[key] });
      }
    } else if (key === 'literatureSearch') {
      if (!rankingTypes[key]) {
        openRankingModal(key);
      } else {
        clearLiteratureSearchTerms();
        setRanking({ [key]: !rankingTypes[key] });
      }
    } else if (key === 'differentialExpression') {
      if (!rankingTypes[key]) {
        openRankingModal(key);
      } else {
        clearDifferentialExpressionScore();
        setRanking({ [key]: !rankingTypes[key] });
      }
    } else if (key === 'expressionSpecificity') {
      if (!rankingTypes[key]) {
        openRankingModal(key);
      } else {
        clearExpressionSpecificityScore();
        setRanking({ [key]: !rankingTypes[key] });
      }
    } else if (key === 'geneVariation' && rankingType === 'user selection') {
      if (!rankingTypes[key]) {
        openRankingModal(key);
      } else {
        clearGeneVariation();
        setRanking({ [key]: !rankingTypes[key] });
      }
    } else {
      setRanking({ [key]: !rankingTypes[key] });
    }
  };

  rankingTypeHandler = (e) => {
    if (e.target.value !== 'user selection') {
      const method = e.target.value;
      setTimeout(() => {
        this.props.openRankingModal(method);
      }, 0);
    } else {
      this.props.setRankingType(e.target.value);
    }
    this.props.refreshRankingSettings();
  };

  handleModalClose = () => {
    this.props.closeRankingModal();
  };

  networkAnalysisSettings = () => {
    this.props.openRankingModal('networkAnalysisSettings');
  };

  render() {
    const {
      analyseData,
      methodTab,
      openMenu,
      categoriesItems,
      types,
      rankingTypes,
      rankingType,
      rankingModals,
      associationScoreTerms,
      literatureSearchTerms,
      geneVariationTerm,
      selectedTissue,
      prevSelectedMethod,
      backgroundSetConfig: {
        backgroundSetType,
        cell,
      },
      openSelectBackgroundSetPopup,
      backgroundCellsFilterOption,
      backgroundSetFilterOption,
      setActiveTab,
      loadingFlag,
      selectedTissueOption,
    } = this.props;
    const { addSetPopup } = this.state;

    let backgroundSet;
    if (backgroundSetType === 'CELL') {
      backgroundSet = cell ? cell.name : backgroundCellsFilterOption;
    } else if (backgroundSetType === 'SET') {
      backgroundSet = backgroundSetFilterOption;
    } else {
      backgroundSet = backgroundSetType;
    }
    if (backgroundSet.length > 25) backgroundSet = backgroundSet.substring(0, 25)+'...';

    const analyseDataTextAreaValue = analyseData.map(concept => (
      concept.name
    )).sort()
      .join('\r\n');

    // TODO: make more flexible for not only genes
    const hasGenes = setContainsGenes(analyseData);
    const hasProteins = setContainsProteins(analyseData);
    let selectOptions = [];

    if (hasGenes) {
      selectOptions = ['rank as target', 'rank as marker', 'user selection'].map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ));
    } else {
      selectOptions = ['user selection'].map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ));
    }

    const getRankingIsDisabled = () => (
      anyKeyTrue(rankingTypes) ?
        (rankingTypes.expressionSpecificity && !selectedTissueOption) :
        true
    );

    return (
      <div className="setAnalysisMethodSelection">
        {
          analyseData.length > 0 &&
          <div className="row">
            <div className="flex-grid justify-content-center">
              <div className="col-1 section-header">
                <div className="row">
                  <span className="section-header-label title3">
                    Method selection ({analyseData.length})
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-grid justify-content-center m-20">
              <div className="col-1 text-center">
                <textarea
                  value={analyseDataTextAreaValue}
                  readOnly={true}
                  className="textarea"
                  cols="30"
                  rows="10"
                />
                <div>
                  <button
                    onClick={this.openAddSetPopup}
                    className="button button-primary mt-15"
                  >
                    Save as set
                  </button>
                </div>
              </div>
              <div className="col-2 text-center">
                {
                  loadingFlag ?
                    <Loader isLoading={loadingFlag} /> :
                    <div className="methods-container">
                      <ul className="tabs">
                        <li //eslint-disable-line
                          className={classNames({
                            active: methodTab === methodTabEnum.FIND_RELATED,
                          })}
                          onClick={() => {
                            setActiveTab(methodTabEnum.FIND_RELATED);
                          }}
                        >
                          <p>{methodTabLabelEnum.FIND_RELATED}</p>
                        </li>
                        <li //eslint-disable-line
                          className={classNames({
                            active: methodTab === methodTabEnum.RANK_SELECTION,
                          })}
                          onClick={() => {
                            setActiveTab(methodTabEnum.RANK_SELECTION);
                          }}
                        >
                          <p>{methodTabLabelEnum.RANK_SELECTION}</p>
                        </li>
                        <li //eslint-disable-line
                          className={classNames({
                            active: methodTab === methodTabEnum.NETWORK_ANALYSIS,
                          })}
                          onClick={() => {
                            setActiveTab(methodTabEnum.NETWORK_ANALYSIS);
                          }}
                        >
                          <p>{methodTabLabelEnum.NETWORK_ANALYSIS}</p>
                        </li>
                      </ul>
                      <div className="tabs-container">
                        {
                          methodTab === methodTabEnum.FIND_RELATED &&
                          <div
                            role="presentation"
                            className="row"
                            onClick={this.analyseClickHandler}
                          >
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.PATHWAY}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.PATHWAY,
                                    })}
                                >
                                  Pathway analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.REGULATION}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.REGULATION,
                                    })}
                                >
                                  Regulation analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.PROTEIN_COMPLEX}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.PROTEIN_COMPLEX,
                                    })}
                                >
                                  Protein complex analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.COMPOUND}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.COMPOUND,
                                    })}
                                >
                                  Compound analysis
                                </button>
                              </div>
                            </div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.PHENOTYPE}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.PHENOTYPE,
                                    })}
                                >
                                  Phenotype
                                  analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.DISORDER}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.DISORDER,
                                    })}
                                >
                                  Disorder analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  data-method={analysisMethodEnum.METABOLITE}
                                  className={
                                    classNames({
                                      'search-category__item': true,
                                      'search-category__item--active': prevSelectedMethod === analysisMethodEnum.METABOLITE,
                                    })}
                                >
                                  Metabolite analysis
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <button
                                  type="button"
                                  className="button button-secondary setAnalysisMethodSelection-trim-text"
                                  onClick={openSelectBackgroundSetPopup}
                                >
                                  {`Background set: ${backgroundSet}`}
                                </button>
                              </div>
                            </div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-1 text-center">
                                <DropdownMenuComponent
                                  items={categoriesItems}
                                  openKey={openMenu}
                                  btnClass="button button-primary"
                                  btn={false}
                                  actions={this.actions}
                                  toggleName="Select a Category"
                                />
                              </div>
                              <div className="col-3">
                                {types.length > 0 && <div className="text-left">Select category type:</div>}
                                <div>
                                  {
                                    types.map((type, index) => (
                                      <span
                                        role="presentation"
                                        onClick={() => {
                                          this.selectCategoryType(type);
                                        }}
                                        className="category-type"
                                        key={index}
                                      >
                                        {type.name}
                                      </span>))
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          methodTab === methodTabEnum.RANK_SELECTION &&
                          <div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-5">
                                {
                                  hasGenes &&
                                  <div>
                                    {(rankingType === 'rank as target'
                                      || rankingType === 'user selection'
                                      || rankingType === 'rank as marker') &&
                                      <GeneSetOptions
                                        associationScoreTerms={associationScoreTerms}
                                        literatureSearchTerms={literatureSearchTerms}
                                        geneVariationTerm={geneVariationTerm}
                                        rankingTypes={rankingTypes}
                                        selectedDisease={this.props.selectedConcept}
                                        differentialExpressionTerm={this.props.differentialExpressionTerm}
                                        openRankingModal={this.props.openRankingModal}
                                        rankingClickHandler={this.rankingClickHandler}
                                      />
                                    }
                                  </div>
                                }
                                {
                                  (hasGenes || hasProteins) &&
                                  <TargetValidationStageOption
                                    rankingTypes={rankingTypes}
                                    rankingClickHandler={this.rankingClickHandler}
                                  />
                                }
                                {
                                  !hasGenes &&
                                  <div>
                                    <NoGeneSetOptions
                                      associationScoreTerms={associationScoreTerms}
                                      literatureSearchTerms={literatureSearchTerms}
                                      rankingTypes={rankingTypes}
                                      openRankingModal={this.props.openRankingModal}
                                      rankingClickHandler={this.rankingClickHandler}
                                    />
                                  </div>
                                }
                              </div>
                              <div className="col-1 text-left quick-select-block">
                                <label htmlFor="rankingSelect">Ranking quick select</label>
                                <select
                                  className="select"
                                  value={rankingType}
                                  onChange={(e) => {
                                    this.rankingTypeHandler(e);
                                  }}
                                >
                                  {selectOptions}
                                </select>
                              </div>
                            </div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-5" />
                              <div className="col-1">
                                <button
                                  disabled={getRankingIsDisabled()}
                                  type="button"
                                  onClick={this.rankRequest}
                                  className="button button-primary ranking__button"
                                >
                                  Get ranking
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          methodTab === methodTabEnum.NETWORK_ANALYSIS &&
                          <div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-1">
                                <div className="text-center">
                                  <button
                                    onClick={this.networkAnalysisSettings}
                                    type="button"
                                    data-method="regulatory-network"
                                    className={
                                      classNames({
                                        'search-category__item': true,
                                        'search-category__item--active': prevSelectedMethod === analysisMethodEnum.NETWORK,
                                      })}
                                  >
                                    Regulatory Network Analysis
                                  </button>
                                </div>
                              </div>
                              <div className="col-3" />
                            </div>
                            <div className="flex-grid justify-content-center m-10">
                              <div className="col-5" />
                              <div className="col-1">
                                <button
                                  type="button"
                                  disabled={!selectedTissue || !this.props.networkAnalysisSettings.gene}
                                  onClick={this.networkRequest}
                                  className="button button-primary"
                                >
                                  Get network analysis
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        }
        {
          anyKeyTrue(rankingModals) &&
          <ModalComponent
            isOpen={anyKeyTrue(rankingModals)}
            closeCb={this.handleModalClose}
          >
            <div>
              {
                rankingModals.associationScore &&
                <AssociationScoreTerms
                  rankingType={rankingType}
                />
              }
              {rankingModals.literatureSearch && <LiteratureSearchTerms />}
              {rankingModals.differentialExpression && <DifferentialExpressionSettings />}
              {rankingModals.expressionSpecificity && <ExpressionSpecificitySettings />}
              {rankingModals.geneVariation && <GeneVariationSettings />}
              {
                (rankingModals['rank as marker'] || rankingModals['rank as target']) &&
                <RankingMarkerSettings
                  rankingMarker={rankingModals['rank as marker']}
                  rankingTarget={rankingModals['rank as target']}
                />
              }
              {rankingModals.networkAnalysisSettings && <NetworkAnalysisSettings />}
            </div>
          </ModalComponent>
        }
        <SaveAsSetModal
          isOpen={addSetPopup.show}
          closeCb={this.closeAddSetPopup}
          idsForShortConceptDetails={addSetPopup.ids}
        />
      </div>
    );
  }
}

SetAnalysisMethodSelection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    analyseData: selectedDataForAnalysis(state),
    methodTab: SELECTORS.getMethodTab(state),
    openMenu: SELECTORS.getMenuKey(state),
    categoriesItems: SELECTORS.getCategoriesForMenu(state),
    types: SELECTORS.getCategoriesTypes(state),
    rankingTypes: SELECTORS.getRankingTypes(state),
    rankingType: SELECTORS.getRanking(state),
    rankingModals: SELECTORS.getRankingModals(state),
    associationScoreTerms: SELECTORS.getAssociationTermsSaved(state),
    literatureSearchTerms: SELECTORS.getLiteratureSearchTermsSaved(state),
    tissues: SELECTORS.getTissuesForMenu(state),
    selectedConcept: SELECTORS.getSelectedRankingConcept(state),
    differentialExpressionTerm: SELECTORS.getDifferentialExpressionTermSaved(state),
    geneVariationTerm: SELECTORS.getGeneVariationTermSaved(state),
    networkAnalysisSettings: SELECTORS.getNetworkAnalysisSettings(state),
    backgroundSetConfig: SELECTORS.getBackgroundSetConfigSelector(state),
    backgroundCellsFilterOption: SELECTORS.getBackgroundCellsFilterOptionSelector(state),
    backgroundSetFilterOption: SELECTORS.getBackgroundSetFilterOptionSelector(state),
    prevSelectedMethod: selectedMethodSelector(state),
    selectedTissue: SELECTORS.getSelectedNetworkAnalysisTissueType(state),
    loadingFlag: getMethodSelectionLoadingFlag(state),
    selectedTissueOption: SELECTORS.getSelectedTissueOptionSelector(state),
    selectedType: getSelectedCategoryType(state),
    firstSelectedMethod: firstSelectedMethodSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initAnalyticsMethod(data) {
      dispatch(ACTIONS.initAnalyticsMethod(data));
    },
    setActiveTab(data) {
      dispatch(ACTIONS.tabsStateAction(data));
    },
    methodSelected(data) {
      dispatch(ACTIONS.methodSelectedAction(data));
    },
    menuClose() {
      dispatch(ACTIONS.menuClose());
    },
    menuOpen() {
      dispatch(ACTIONS.menuOpen());
    },
    selectCategoryName(data) {
      dispatch(ACTIONS.selectCategoryName(data));
    },
    selectCategoryType(data) {
      dispatch(ACTIONS.selectCategoryTypeAction(data));
    },
    setRanking(data) {
      dispatch(ACTIONS.setRankingAction(data));
    },
    setRankingType(data) {
      dispatch(ACTIONS.setRankingType(data));
    },
    openRankingModal(key) {
      dispatch(ACTIONS.openRankingModal(key));
    },
    closeRankingModal() {
      dispatch(ACTIONS.closeRankingModal());
    },
    clearAssociationScoreTerms() {
      dispatch(clearAssociationScoreTerms());
    },
    clearLiteratureSearchTerms() {
      dispatch(clearLiteratureSearchTermsAction());
    },
    clearDifferentialExpressionScore() {
      dispatch(ACTIONS.clearDifferentialExpressionScore());
    },
    clearExpressionSpecificityScore() {
      dispatch(ACTIONS.clearExpressionSpecificityScore());
    },
    refreshRankingSettings() {
      dispatch(ACTIONS.refreshRankingSettings());
    },
    requestRanking(data) {
      dispatch(requestRanking(data));
    },
    requestNetwork(data) {
      dispatch(networkAnalysis(data));
    },
    resetRankingResult() {
      dispatch(resetRanking());
    },
    resetNetworkResult() {
      dispatch(resetNetwork());
    },
    clearGeneVariation() {
      dispatch(clearGeneVariationTerm());
    },
    resetCategoryResult() {
      dispatch(findRelatedTableResetAction());
    },
    switchShouldUpdateDataFlag(data) {
      dispatch(ACTIONS.switchShouldUpdateDataFlagAction(data));
    },
    initTissuesAndCategories() {
      dispatch(ACTIONS.initTissues());
      dispatch(ACTIONS.initCategories());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAnalysisMethodSelection);
