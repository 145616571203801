import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';

// Utils
import { EDIT_SET_FORM } from '../../constants';
import { CONCEPT_SEARCH_FORM_FIELD } from '../../../../Concept/ConceptSearchForm/constants';
// Components
import Button from '../../../../common/Buttons/Button/Button';
import FormInput from '../../../../common/FormElements/FormInput';
import FormTextarea from '../../../../common/FormElements/FormTextarea';
import AddTag from '../../../../common/AddTag/AddTag';
import AddTagContent from '../../../../common/AddTag/AddTagContent';
import ConceptSearchField from '../../../../Concept/ConceptSearchForm/ConceptSearchField';
// Store
import { getEditSetTagsSelector, getEditSetFormValuesSelector } from '../../../EditSet/store/selectors';
import { updateEditSetTagsAction } from '../../../EditSet/store/actions';
// Styles
import './styles.scss';

const propTypes = {
  tags: PropTypes.instanceOf(Array),
  updateTags: PropTypes.func,
  formValues: PropTypes.instanceOf(Object),
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  addConcept: PropTypes.func,
  goBack: PropTypes.func,
  setSearchConceptInputValue: PropTypes.func,
};

const EditSetForm = (props) => {
  const {
    tags,
    updateTags,
    change,
    formValues,
    submitting,
    handleSubmit,
    addConcept,
    goBack,
    setSearchConceptInputValue,
  } = props;

  const handleSearchConceptsClick = useCallback((e) => {
    e.preventDefault();
    setSearchConceptInputValue(formValues[CONCEPT_SEARCH_FORM_FIELD]);
    addConcept(true);
  }, [addConcept, formValues, setSearchConceptInputValue]);

  const resetConceptsField = useCallback(() => {
    change(CONCEPT_SEARCH_FORM_FIELD, '');
  }, [change]);

  return (
    <form className="edit-set-form" onSubmit={handleSubmit}>
      <div className="edit-set-form__wrap">
        <div className="edit-set-form__input">
          <div className="edit-set-form__label">
            Set name
            <span className="mandatory-span">
              <sup>*</sup>
            </span>
          </div>
          <Field
            id="name"
            name="name"
            placeholder="Name"
            component={FormInput}
            type="text"
          />
        </div>
        <div className="edit-set-form__input">
          <div className="edit-set-form__label">
            Add concept
          </div>
          <ConceptSearchField
            reset={resetConceptsField}
            inputValue={formValues ? formValues[CONCEPT_SEARCH_FORM_FIELD] : ''}
            onClick={handleSearchConceptsClick}
          />
        </div>
        <div className="edit-set-form__input">
          <div className="edit-set-form__label">
            Add tag
          </div>
          <AddTag
            tagList={tags}
            noReset={true}
            updateTags={updateTags}
          />
        </div>
        {
          tags && tags.length > 0 &&
          <div className="edit-set-form__input">
            <div className="edit-set-form__label">
              Tags
            </div>
            <AddTagContent
              tagList={tags}
              updateTags={updateTags}
            />
          </div>
        }
        <div className="edit-set-form__input">
          <div className="edit-set-form__label">
            Set description
          </div>
          <Field
            id="description"
            name="description"
            placeholder="Description"
            component={FormTextarea}
            type="text"
            rows="5"
          />
        </div>
        <div className="edit-set-form__controls">
          <Button
            text="Cancel"
            customClassName="edit-set-form__btn"
            onClick={goBack}
          />
          <Button
            type="submit"
            text="Save"
            customClassName="edit-set-form__btn button-primary"
            disabled={submitting}
          />
        </div>
      </div>
    </form>
  );
};

EditSetForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    tags: getEditSetTagsSelector(state),
    formValues: getEditSetFormValuesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateTags(data) {
      dispatch(updateEditSetTagsAction(data));
    },
  };
}

function validate(values) {
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter name';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: EDIT_SET_FORM,
    validate,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(EditSetForm);
