import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import * as ACTIONS from '../store/actions';
// Styles
import './styles.scss';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import Sets from '../../../../Sets/SetsList/Sets';
import {checkProjectTab} from '../../../../Projects/ProjectsTabs/utils';
import {personalOption} from '../../../../Projects/ProjectsTabs/constants';
import {withRouter} from '../../../../common/WithRouter/WithRouter';
import {getAvailableSetsAction} from '../../../../Sets/SetsList/reducer';
import {getSelectedSetInfoSelector} from '../store/selectors';

const propTypes = {
  filters: PropTypes.instanceOf(Object),
  setSelectedSetInfo: PropTypes.func,
  getAvailableSets: PropTypes.func,
  selectedSetInfo: PropTypes.instanceOf(Object),
};

const SetSelectionPanel = (props) => {
  const {
    setSelectedSetInfo,
    getAvailableSets,
    selectedSetInfo,
  } = props;

  const [showSelectSetPopup, setShowSelectSetPopup] = useState(false);

  // function selectSetId() {
  function selectSetId({ projectId, selectedSets }) {
    const { id, name } = selectedSets[0];
    const projectOrNull = checkProjectTab(projectId) ? projectId : null;

    setSelectedSetInfo({
      setId: id,
      projectId: projectOrNull || 'personal',
      setName: name,
    });
    setShowSelectSetPopup(false);

    //we need to call list of sets to reload the list of sets in select background set component
    getAvailableSets();
  }

  function getProjectTitle() {
    if (selectedSetInfo?.setName) {
      return selectedSetInfo.projectId ? selectedSetInfo.projectId : personalOption.label;
    } else {
      return '-';
    }
  }

  return (
    <div className="concepts-search-rightbar">
      <div className="concepts-search-sidebar__title title4">
        Parameters
      </div>
      <button className="button button-secondary concepts-search-rightbar__btn" onClick={() => setShowSelectSetPopup(true)}>
        Select Gene Set
      </button>
      <div className="concepts-search-sidebar__title ">
        <b>Name:</b> {selectedSetInfo.setName ? selectedSetInfo.setName : '-'}
      </div>
      <div className="concepts-search-sidebar__title ">
        <b>Project:</b> {getProjectTitle()}
      </div>
      {
        showSelectSetPopup &&
        <ModalComponent
          isOpen={showSelectSetPopup}
          closeCb={() => setShowSelectSetPopup(false)}
        >
          <Sets
            selectFunction={selectSetId}
            closeFunction={() => setShowSelectSetPopup(false)}
          />
        </ModalComponent>
      }
    </div>
  );
};

SetSelectionPanel.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedSetInfo: getSelectedSetInfoSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSetInfo(data) {
      dispatch(ACTIONS.setSelectedSetInfoAction(data));
    },
    getAvailableSets(data) {
      dispatch(getAvailableSetsAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SetSelectionPanel));
