import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';

export function drawBeesWarmGraph({
  data,
  graphId,
  isDataFixed,
  setDataFixed,
  boxPlotTransform,
  yAxisText,
  handleAxisClick,
  showLegend,
  chartTitle,
  legendData,
  isChartDownloading,
  customPadding,
  selectedCells,
}) {
  let sectors = Array.from(new Set(data.map((d) => d.tissueName)));
  const width = sectors.length * 80;
  const height = 1080;
  const margin = {
    top: 50,
    right: 50,
    bottom: 200,
    left: 150,
  };

  const padding = { ...customPadding };

  const bodyHtml = document.querySelector('body');
  const textForYAxis = boxPlotTransform === 'linear' ? `${yAxisText}` : `log2(${yAxisText} + 1)`;

  d3js.selectAll(`#${graphId} > *`).remove();

  let svg = d3js
    .select(`#${graphId}`)
    .append('svg')
    .attr('height', height)
    .attr('width', width + margin.right + margin.left + padding.left + padding.right)
    .append('g')
    .attr('transform', `translate(${padding.left}, ${padding.top})`);

  if (isChartDownloading) {
    d3js.select(`#${graphId}`)
      .select('svg')
      .insert('text', ':first-child')
      .text(`${chartTitle}`)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    // Legend
    if (showLegend) {
      const SVG = d3js.select(`#${graphId} svg`)
        .append('g')
        .attr('transform', () => 'translate(-50, 0)');

      const keys = legendData.map(param => param.name);
      const colors = legendData.map(param => param.color);

      drawLegend({
        element: SVG,
        keys,
        colors,
      });
    }
  }

  let xScale = d3js
    .scaleBand()
    .domain(sectors)
    .range([margin.left, width + margin.left]);

  let yScale = d3js
    .scaleLinear()
    .domain(d3js.extent(data.map((d) => d['ntpm'])))
    .range([height - margin.bottom, margin.top]);

  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', 'chart-tooltip chart-tooltip--popup chart-tooltip_mode');

  function getTooltipTemplate(d) {
    return `
        <div class="chart-tooltip__content">
           <div><b>Cell type: </b> ${d.cellType}</div>
           <div><b>Cell type group:</b> ${d.cellTypeGroup}</div>
           <div><b>Cluster: </b> ${d.cluster}</div>
           <div><b>Read count: </b> ${d.readCount}</div>
           <div><b>Cell count: </b> ${d.cellCount}</div>
           <div><b>nTPM: </b> ${d.ntpm}</div>
        </div>
      `;
  }
  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d, i) {
    tooltip
      .html(getTooltipTemplate(d, i))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
  }

  const xAxis = svg.append('g')
    .attr('transform', `translate(-50, ${height - margin.bottom})`)
    .call(d3js.axisBottom(xScale))
    .attr('class', 'x-axis');

  xAxis.selectAll('text')
    .attr('x', 15)
    .attr('y', 4)
    .style('text-anchor', 'start')
    .style('cursor', 'pointer')
    .style('text-anchor', 'start')
    .style('font-size', '16px')
    .style('font-weight', (d) => {
      if (!isChartDownloading && selectedCells) {
        return selectedCells.find(c => d === c.name) && 700;
      }
      return 400;
    })
    .attr('transform', 'rotate(45)')
    .on('click', d => handleAxisClick(d));

  const yAxis = svg.append('g')
    .attr('transform', `translate(${margin.left - 50},0)`)
    .call(d3js.axisLeft(yScale))
    .attr('class', 'y-axis');

  yAxis.selectAll('text')
    .attr('x', -14)
    .style('font-size', '16px')
    .style('cursor', 'pointer');

  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -((height - margin.bottom) / 2))
    .attr('dy', '-100')
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text(textForYAxis);

  svg.selectAll('.circ')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'circ')
    .attr('stroke', 'black')
    .attr('fill', (d) => d.color)
    .attr('r', 3)
    .attr('cx', (d) => xScale(d.tissueName) - 10)
    .attr('cy', (d) => yScale(d.ntpm))
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave);

  let simulation = d3js.forceSimulation(data)
    .force('x', d3js
      .forceX((d) => xScale(d.tissueName) - 10)
      .strength(0.2))
    .force('y', d3js.forceY((d) => {
      return yScale(d.ntpm);
    }).strength(10))
    .force('collide', d3js.forceCollide(() => {
      return 2;
    }))
    .alphaDecay(0)
    .alpha(0.3)
    .on('tick', tick);

  function tick() {
    d3js.selectAll('.circ')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y);
  }

  const timeout = !isDataFixed ? 5000 : 0;

  setTimeout(function () {
    simulation.alphaDecay(0.1);

    if (!isDataFixed) {
      setDataFixed(true);
    }
  }, timeout);
}
