import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import DropdownMenuComponent from '../../../common/DropdownMenuComponent/DropdownMenuComponent';
import Loader from '../../../common/Loader/Loader';
import Error from '../../../common/Error/Error';
// Store
import * as SELECTORS from './store/selectors';
import { getCategorySearchDataAction } from '../../store/actions';
import { getConceptCardsDataSelector, getConceptCardsLoadingSelector } from '../../../Concept/ConceptCards/store/selectors';
import {
  openOtherCategoriesDropdownAction,
  closeOtherCategoriesDropdownAction,
  getSemanticTypesForFilterAction,
} from './store/reducer';
// Utils
import { apiTypes } from '../../../Sets/CreateSet/enums';
// Styles
import './index.scss';

const propTypes = {
  conceptIds: PropTypes.instanceOf(Array),
  conceptCards: PropTypes.instanceOf(Array),
  isInGeneDetails: PropTypes.bool,
  searchByCategory: PropTypes.func,
  selectedSearchCategory: PropTypes.string,
  searchCategoryTabsData: PropTypes.instanceOf(Array),
  otherCategoriesDropdown: PropTypes.bool,
  openOtherCategoriesDropdown: PropTypes.func,
  closeOtherCategoriesDropdown: PropTypes.func,
  semanticTypesForFilter: PropTypes.instanceOf(Object),
  getSemanticTypesForFilter: PropTypes.func,
  semanticCategoriesForFilter: PropTypes.instanceOf(Array),
  cachedSemanticCategoriesTabData: PropTypes.instanceOf(Array),
  openCreateSetPopup: PropTypes.func,
  gisLoading: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const SearchCategoryTabs = (props) => {
  const {
    conceptIds,
    conceptCards,
    isInGeneDetails,
    searchByCategory,
    selectedSearchCategory,
    searchCategoryTabsData,
    otherCategoriesDropdown,
    openOtherCategoriesDropdown,
    closeOtherCategoriesDropdown,
    semanticTypesForFilter,
    getSemanticTypesForFilter,
    semanticCategoriesForFilter,
    cachedSemanticCategoriesTabData,
    openCreateSetPopup,
    gisLoading,
    loading,
    error,
  } = props;

  const categoryTabs = cachedSemanticCategoriesTabData.length ?
    cachedSemanticCategoriesTabData :
    searchCategoryTabsData;

  function handleOpenCreateSetPopup(categoryName) {
    openCreateSetPopup({
      conceptIds,
      categoryName,
      apiType: apiTypes.filteredFullListApi,
    });
  }

  function applySearchByCategory({ categoryName }) {
    searchByCategory({ categoryName });
    handleOpenCreateSetPopup(categoryName);
  }

  function selectOtherCategory(semanticTypeId) {
    const semanticTypesValues = Object.values(semanticTypesForFilter).flat();
    const category = semanticTypesValues.find(item => item.id === semanticTypeId);
    if (category) {
      searchByCategory({ semanticTypeId });
      handleOpenCreateSetPopup(category.name);
    }
  }

  function fetchSemanticTypesForFilter(semCategory) {
    getSemanticTypesForFilter({
      concepts: conceptCards.map(conceptCard => conceptCard.id),
      semCategory,
    });
  }

  function getCerebrumSCForDD() {
    return semanticCategoriesForFilter.map(({ categoryName, count }) => {
      const semanticTypes = semanticTypesForFilter[categoryName];
      return {
        name: categoryName,
        disabled: count === 0,
        ...(semanticTypes && ({ sub: semanticTypes })),
        ...(!semanticTypes && ({
          loadNestedOptionsCb: () => fetchSemanticTypesForFilter(categoryName),
        })),
      };
    });
  }

  function renderSearchCategory(c, i) {
    const name = c.categoryName.toLowerCase();
    const categoryClassName = classNames({
      'search-category__item-wrap': true,
      'search-category__item-wrap_active': c.count,
      'search-category__item-wrap--small': isInGeneDetails,
      'search-category__item-wrap_selected': selectedSearchCategory === name,
    });
    const categoryItemClassName = classNames({
      'search-category__item': true,
      'search-category__item--disabled': c.count === 0,
    });
    const handleClick = () => {
      if (!c.count) return;
      applySearchByCategory(c);
    };

    return (
      <div
        key={`${name}_${i}`}
        className={categoryClassName}
        onClick={handleClick}
      >
        <div className={categoryItemClassName}>
          {name}
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (!gisLoading && isInGeneDetails) {
      applySearchByCategory({ categoryName: 'Molecular Function' });
    }
  }, [gisLoading, isInGeneDetails]);

  const otherCategoryClassName = classNames({
    'search-category__item-wrap': true,
    'search-category__item-wrap_active': true,
    'search-category__item-wrap--small': isInGeneDetails,
  });

  return (
    <div className="search-category">
      <span className="search-page__subtitle">
        Find related concepts
      </span>
      {
        !loading && categoryTabs.length > 0 &&
        <div className="search-category__wrap">
          {
            categoryTabs.map(renderSearchCategory)
          }
          <div className={otherCategoryClassName}>
            <div className="search-category__item search-category__item_dropdown">
              <DropdownMenuComponent
                items={getCerebrumSCForDD()}
                toggleName="other"
                alignRight={true}
                linkToggleElement={true}
                openKey={otherCategoriesDropdown}
                actions={{
                  menuClose: closeOtherCategoriesDropdown,
                  menuOpen: openOtherCategoriesDropdown,
                  select: selectOtherCategory,
                }}
              />
            </div>
          </div>
        </div>
      }
      <Loader isLoading={loading} />
      <Error
        show={!loading && error}
        error={error}
        customClassName="search-category__error"
      />
    </div>
  );
};

SearchCategoryTabs.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    searchCategoryTabsData: SELECTORS.getSearchCategoryTabsDataSelector(state),
    loading: SELECTORS.getSearchCategoryTabsLoadingSelector(state),
    error: SELECTORS.getSearchCategoryTabsErrorSelector(state),
    otherCategoriesDropdown: SELECTORS.getOtherCategoriesDropdownSelector(state),
    conceptCards: getConceptCardsDataSelector(state),
    semanticCategoriesForFilter: SELECTORS.getSemanticCategoriesForFilterSelector(state),
    semanticTypesForFilter: SELECTORS.getSemanticTypesForFilterSelector(state),
    cachedSemanticCategoriesTabData: SELECTORS.getCachedSemanticCategoriesTabDataSelector(state),
    conceptIds: SELECTORS.getSemanticCategoriesConceptIds(state),
    gisLoading: getConceptCardsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openOtherCategoriesDropdown() {
      dispatch(openOtherCategoriesDropdownAction());
    },
    closeOtherCategoriesDropdown() {
      dispatch(closeOtherCategoriesDropdownAction());
    },
    searchByCategory(data) {
      dispatch(getCategorySearchDataAction(data));
    },
    getSemanticTypesForFilter(data) {
      dispatch(getSemanticTypesForFilterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCategoryTabs);
