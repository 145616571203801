import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Icons
import {
  AiOutlineExclamationCircle,
  AiFillExclamationCircle,
  AiOutlineNodeIndex,
} from 'react-icons/ai';
// Components
import CreateSetInfo from './Components/CreateSetInfo/CreateSetInfo';
import CreateSetFilter from './Components/CreateSetFilter/CreateSetFilter';
import CreateSetContent from './Components/CreateSetContent/CreateSetContent';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
// Store
import {
  getCreateSetConceptIdsSelector,
  getCreateSetConceptsSelector,
  getCreateSetLoadingSelector,
  getcreateSetStartConceptsSelector,
  getCreateSetStartConceptsIdsSelector,
  getCreateSetStartConceptsNamesSelector,
  getLoadedSetInfoSelector,
  getSaveCreatedSetInputsErrorSelector,
  getSavedSetInfoSelector,
  getConfigFromStoreSelector,
  getCreateSetTitleSelector,
} from './store/selectors';
import { getProjectsOptionsAction } from '../../Projects/ProjectsTabs/store/reducer';
import {
  saveCreatedSetAction,
  resetCreateSetAction,
  getCreateSetAllConceptsAction,
  getSetForFilteringAction,
  setDataFromConfigAction,
  updateCreateSetNameAction,
  updateCreateSetTitleAction,
} from './store/actions';
// Constants
import { createSetFilterInitialValues, apiTypes } from './enums';
import {RELATIVE_PATH} from '../../../constantsCommon';
// Styles
import './styles.scss';
import {withRouter} from '../../common/WithRouter/WithRouter';
import {setSelectedSetInfoAction} from '../../FindRelated/Components/EnrichmentAnalysisParams/store/actions';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import EnrichmentAnalysisParamsModal
  from '../../FindRelated/Components/EnrichmentAnalysisParams/EnrichmentAnalysisParamsModal';
import RankSelectionParamsModal from '../../RankSelection/Components/RankSelectionsParams/RankSelectionParamsModal';
import * as RANK_ACTIONS from '../../RankSelection/store/actions';
import {setActiveCategoriesAction} from '../../Concept/ConceptSearchModal/store/actions';
import {BiListUl} from 'react-icons/bi';
import RelMapImg from '../../../theme/assets/img/intermediate-relation-icon.png';

const propTypes = {
  config: PropTypes.instanceOf(Object),
  resetCreateSet: PropTypes.func,
  closePopup: PropTypes.func,
  getCreateSetAllConcepts: PropTypes.func,
  getConcepts: PropTypes.func,
  conceptsLimit: PropTypes.number,
  conceptsLimitMessage: PropTypes.string,
  initialFilters: PropTypes.instanceOf(Object),
  getProjectsOptions: PropTypes.func,
  saveCreatedSet: PropTypes.func,
  saveCreatedSetInputError: PropTypes.bool,
  pageStyles: PropTypes.bool,
  endConceptIds: PropTypes.instanceOf(Array),
  concepts: PropTypes.instanceOf(Array),
  genesOnly: PropTypes.bool,
  loading: PropTypes.bool,
  savedSetInfo: PropTypes.instanceOf(Object),
  navigate: PropTypes.func,
  setSelectedSetInfo: PropTypes.func,
  setRankingSelectedSetInfo: PropTypes.func,
  setActiveCategories: PropTypes.func,
  loadSet: PropTypes.instanceOf(Object),
  loadSavedSet: PropTypes.func,
  loadedSetInfo: PropTypes.instanceOf(Object),
  setDataFromConfig: PropTypes.func,
  startConceptNames: PropTypes.instanceOf(Array),
  storedConfig: PropTypes.instanceOf(Object),
  startConceptIds: PropTypes.instanceOf(Array),
  updateCreateSetName: PropTypes.func,
  storedSetTitle: PropTypes.string,
  updateCreateSetTitle: PropTypes.func,
};

const CreateSet = (props) => {
  const {
    config,
    closePopup,
    getConcepts,
    conceptsLimit,
    initialFilters,
    resetCreateSet,
    saveCreatedSet,
    getProjectsOptions,
    conceptsLimitMessage,
    getCreateSetAllConcepts,
    saveCreatedSetInputError,
    pageStyles,
    endConceptIds,
    concepts,
    genesOnly,
    loading,
    savedSetInfo,
    setSelectedSetInfo,
    setRankingSelectedSetInfo,
    setActiveCategories,
    loadSet,
    loadSavedSet,
    loadedSetInfo,
    setDataFromConfig,
    startConceptNames,
    storedConfig,
    startConceptIds,
    updateCreateSetName,
    storedSetTitle,
    updateCreateSetTitle,
  } = props;

  useEffect(() => {
    getProjectsOptions();
    setDataFromConfig(config);

    if (loadSet && loadSet.id) {
      loadSavedSet({loadSet, config, initialFilters});
    } else {
      handleGetCreateSetAllConcepts();//todo get in this method data from store
    }

    return resetCreateSet;
  }, []);

  const [warning, setWarning] = useState(false);
  const [isOpenSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [isGotoEnrichment, setGotoEnrichment] = useState(false);
  const [isShowEnrichment, setShowEnrichment] = useState(false);
  const [isGotoRank, setGotoRank] = useState(false);
  const [isShowRank, setShowRank] = useState(false);

  function renderLimitBlock() {
    if (!conceptsLimit || !conceptsLimitMessage) {
      return null;
    }

    const limitClass = classNames({
      'create-set__limit': true,
      'create-set__limit_error': warning,
    });

    return (
      <div className={limitClass}>
        {
          warning ?
            <AiFillExclamationCircle size={20} color="red" /> :
            <AiOutlineExclamationCircle size={20} color="#4b3f63" />
        }
        <span className="create-set__limit-text">
          {conceptsLimitMessage}
        </span>
      </div>
    );
  }

  function renderSaveBlock() {
    if (getConcepts) return null;

    const saveBlockBtnClass = classNames({
      'button button-primary': true,
      'create-set__button': true,
      'button-primary--disabled': !saveCreatedSetInputError,
    });

    const saveBtnClass = classNames({
      'button button-primary': true,
      'create-set__save-button': true,
      'button-primary--disabled': !saveCreatedSetInputError,
    });

    return (
      <div className="create-set__save">
        <button
          className={saveBlockBtnClass}
          disabled={concepts && (concepts.length === 0 || concepts.length > 400) || loading}
          title={concepts && (concepts.length === 0 || concepts.length > 400) ? conceptsLimitMessage : 'Open concepts in relation map'}
          onClick={() => {
            onOpenAsRelationMapClick();
          }}
        >
          <img src={RelMapImg} className="create-set__relation-map-icon" alt="Relation map"/>
          Relation map
        </button>
        <button
          className={saveBlockBtnClass}
          onClick={() => {
            if (savedSetInfo.setId) {
              onOpenRankTargetsClick();
            } else {
              setGotoRank(true);
              setOpenSaveConfirmation(true);
            }
          }}
        >
          <BiListUl size={28}/>
          Rank Set
        </button>
        <button
          className={saveBlockBtnClass}
          onClick={() => {
            if (savedSetInfo.setId) {
              onOpenEnrichmentAnalysisClick();
            } else {
              setGotoEnrichment(true);
              setOpenSaveConfirmation(true);
            }
          }}
        >
          <AiOutlineNodeIndex size={28}/>
          Profile Set
        </button>
        <button
          className={saveBtnClass}
          onClick={() => {
            saveCreatedSet({
              leftConcepts: storedConfig.startConcepts,
              skipZeroPublications: initialFilters && initialFilters.SKIP_ZERO_PUBLICATIONS,
              showSavedSuccessfully: !closePopup,
            });
            if (closePopup) closePopup();
          }}
        >
          Save
        </button>
      </div>
    );
  }

  const handleGetCreateSetAllConcepts = useCallback(() => {
    getCreateSetAllConcepts({
      apiType: config.apiType,
      semanticType: config.semanticType,
      category: config.category,
      endConceptIds: config.endConceptIds || config.concepts,
      startConcepts: config.startConcepts,
      categoryName: config.categoryName,
      initialFilters,
    });
  }, [config, initialFilters]);

  const setTitle = useMemo(() => {
    if (loadedSetInfo) {
      updateCreateSetName(loadedSetInfo.name);
      updateCreateSetTitle(loadedSetInfo.name);
      return loadedSetInfo.name;
    }

    let newName = '';
    switch (storedConfig.apiType) {
      case apiTypes.categoryAnalysisApi: {
        if (startConceptNames)
          newName = `All ${storedConfig.recommendationName.toLowerCase()} for ${startConceptNames[0]}`;
        break;
      }
      case apiTypes.classificationFullListApi: {
        newName = `All genes classified as ${storedConfig.recommendationName.toLowerCase()}`;
        break;
      }
      case apiTypes.filteredFullListApi: {
        if (startConceptNames)
          newName = `Related ${storedConfig.categoryName.toLowerCase()} to ${startConceptNames.join(' AND ')}`;
        break;
      }
      case apiTypes.filterWithConcepts: {
        if (startConceptNames)
          newName = storedConfig.setName || `Interactions to ${startConceptNames[0]}`;
        break;
      }
      case apiTypes.targetsForCancer: {
        if (startConceptNames)
          newName = `Marker/Target candidates for ${startConceptNames[0]}`;
        break;
      }
      case apiTypes.rankTargets: {
        if (startConceptNames)
          newName = `Rank Targets for ${startConceptNames[0]} (${storedConfig.setName})`;
        break;
      }
    }

    updateCreateSetName(newName);
    updateCreateSetTitle(newName);
    return newName;
  }, [storedConfig, loadedSetInfo]);

  const conceptNames = useMemo(() => {
    switch (storedConfig.apiType) {
      case apiTypes.categoryAnalysisApi:
      case apiTypes.targetsForCancer:
      case apiTypes.filterWithConcepts:
      case apiTypes.filteredFullListApi: {
        return startConceptNames;
      }
      case apiTypes.classificationFullListApi: {
        return [storedConfig.recommendationName];
      }
      default:
        return [];
    }
  }, [storedConfig]);

  const onOpenAsRelationMapClick = () => {
    localStorage.setItem('uniqConcepts', JSON.stringify(endConceptIds));
    window.open(`${RELATIVE_PATH}/relation-map/personal/new`, '_blank');
  };

  const onOpenEnrichmentAnalysisClick = () => {
    if (!savedSetInfo.setId) {
      setGotoEnrichment(true);
      saveCreatedSet({
        leftConcepts: storedConfig.startConcepts,
        skipZeroPublications: initialFilters && initialFilters.SKIP_ZERO_PUBLICATIONS,
        showSavedSuccessfully: !closePopup,
      });
    } else {
      setSelectedSetInfo(savedSetInfo);
      setShowEnrichment(true);
    }
  };

  const onOpenRankTargetsClick = () => {
    if (!savedSetInfo.setId) {
      setGotoRank(true);
      saveCreatedSet({
        leftConcepts: storedConfig.startConcepts,
        skipZeroPublications: initialFilters && initialFilters.SKIP_ZERO_PUBLICATIONS,
        showSavedSuccessfully: !closePopup,
      });
    } else {
      setActiveCategories(['T902', 'T191']);
      setRankingSelectedSetInfo({
        setId: savedSetInfo.setId,
        projectId: savedSetInfo.projectId || 'personal',
        setName: savedSetInfo.setName,
      });
      setShowRank(true);
    }
  };

  useEffect(() => {
    if (savedSetInfo.setId && isGotoRank) {
      setGotoRank(false);
      onOpenRankTargetsClick();
    }
    if (savedSetInfo.setId && isGotoEnrichment) {
      setGotoEnrichment(false);
      onOpenEnrichmentAnalysisClick();
    }
  }, [savedSetInfo]);

  const mainDivClass = classNames({
    'create-set': true,
    'create-set_popup': !pageStyles,
    'create-set_page': pageStyles,
  });
  const sidebarClass = classNames({
    'create-set__sidebar': !pageStyles,
    'create-set__sidebar-page': pageStyles,
  });

  return (
    <div className={mainDivClass}>
      <div className={sidebarClass}>
        <CreateSetFilter
          startDescription={setTitle}
          categoryName={storedConfig.categoryName}
          initialValues={{ ...createSetFilterInitialValues, ...initialFilters }}
          getCreateSetAllConcepts={handleGetCreateSetAllConcepts}
        />
      </div>
      <div className="create-set__content">
        <div className="create-set__header">
          <span className="create-set__title title2">
            {storedSetTitle}
          </span>
        </div>
        {renderLimitBlock()}
        <CreateSetContent
          getConcepts={getConcepts}
          withCheckboxes={!!getConcepts}
          conceptIds={startConceptIds}
          conceptNames={conceptNames}
          closePopup={closePopup}
          conceptsLimit={conceptsLimit}
          setWarning={() => {
            setWarning(true); }}
          genesOnly={genesOnly}
          startConcepts={storedConfig.startConcepts}//format [{id:..., name: ...}]
        />
        {
          !getConcepts &&
          <CreateSetInfo
            categoryName={storedConfig.categoryName}
            initialFilters={initialFilters}
          />
        }
        {renderSaveBlock()}
        <FreeAccountLimitationDialog entity="set" />
        <ConfirmationDialog
          onConfirm={() => {
            if (isGotoRank)
              onOpenRankTargetsClick();
            else
              onOpenEnrichmentAnalysisClick();
          }}
          onCancel={() => { setOpenSaveConfirmation(false); }}
          isOpen={isOpenSaveConfirmation}
          closeCb={() => { setOpenSaveConfirmation(false); }}
          confirmBtnText="OK"
          text="Your current results will be saved"
        />
        <EnrichmentAnalysisParamsModal
          isOpen={isShowEnrichment}
          closeCb={() => {setShowEnrichment(false);}}
        />
        <RankSelectionParamsModal
          isOpen={isShowRank}
          closeCb={() => {setShowRank(false);}}
        />
      </div>
    </div>
  );
};

CreateSet.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    saveCreatedSetInputError: getSaveCreatedSetInputsErrorSelector(state),
    endConceptIds: getCreateSetConceptIdsSelector(state),
    startConcepts: getcreateSetStartConceptsSelector(state),
    startConceptIds: getCreateSetStartConceptsIdsSelector(state),
    startConceptNames: getCreateSetStartConceptsNamesSelector(state),
    concepts: getCreateSetConceptsSelector(state),
    loading: getCreateSetLoadingSelector(state),
    savedSetInfo: getSavedSetInfoSelector(state),
    loadedSetInfo: getLoadedSetInfoSelector(state),
    storedConfig: getConfigFromStoreSelector(state),
    storedSetTitle: getCreateSetTitleSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCreatedSet(data) {
      dispatch(saveCreatedSetAction(data));
    },
    resetCreateSet(data) {
      dispatch(resetCreateSetAction(data));
    },
    getCreateSetAllConcepts(data) {
      dispatch(getCreateSetAllConceptsAction(data));
    },
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    setSelectedSetInfo(data) {
      dispatch(setSelectedSetInfoAction(data));
    },
    setRankingSelectedSetInfo(data) {
      dispatch(RANK_ACTIONS.setSelectedSetInfoAction(data));
    },
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
    loadSavedSet(data) {
      dispatch(getSetForFilteringAction(data));
    },
    setDataFromConfig(data) {
      dispatch(setDataFromConfigAction(data));
    },
    updateCreateSetName(data) {
      dispatch(updateCreateSetNameAction(data));
    },
    updateCreateSetTitle(data) {
      dispatch(updateCreateSetTitleAction(data));
    }
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSet));
