import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactPaginate from 'react-paginate';
import SimpleTable from '../common/SimpleTable/SimpleTable';

import ExportTable from '../common/ExportTable/ExportTable';
import {
  getAntibodiesAllPatentsSelector,
  getAntibodiesConceptSelector,
  getAntibodiesLoadingSelector,
  getAntibodiesPatentsSelector,
  getAntibodiesSortingSelector,
  getPatentsPageSelector,
  getPatentsTotalPagesPagesSelector,
} from './store/selectors';
import {
  changePagePatentsAntibodiesAction,
  sortAntibodiesTableAction,
} from './store/reducer';
import {AVERAGE_SYMBOL_LENGTH, getParentBlockWidth} from '../Utils/Utils';

import {RELATIVE_PATH} from '../../constantsCommon';
// Styles
import './styles.scss';
import ModalComponent from '../ModalComponent/ModalComponent';

const propTypes = {
  width: PropTypes.number,
  data: PropTypes.array,
  allData: PropTypes.array,
  loading: PropTypes.bool,
  concept: PropTypes.instanceOf(Object),
  conceptsPopupData: PropTypes.array,
  conceptsPopupName: PropTypes.string,
  sorting: PropTypes.instanceOf(Object),
  sortAntibodiesTable: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

class AntibodiesPlabDabTable extends React.Component {

  state = {
    conceptsPopupData: [],
    conceptsPopupName: '',
    showInfoText: false,
  };

  showConceptsPopup = (data, name) => {
    this.setState({ conceptsPopupData: data, conceptsPopupName: name });
  };

  closeConceptsPopup = () => {
    this.setState({
      conceptsPopupData: [],
    });
  };

  getColumnPercentWidth = (percent) => {
    const screenWidth = getParentBlockWidth('antibodies-page');
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { data } = this.props;
    const { heavySequence } = data[index];
    const padding = 10;
    const lineHeight = 20;
    const cellWidth = this.getColumnPercentWidth(16);
    const stringLength = heavySequence.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);

    const height = ((stringRows * lineHeight) < 130) && heavySequence ? 130 : (stringRows * lineHeight);

    return height + padding;
  };

  handlePageClick = (page) => {
    this.props.changePage(page.selected);
  };

  render() {
    const {
      conceptsPopupData,
      conceptsPopupName,
    } = this.state;

    const {
      data,
      allData,
      loading,
      concept,
      sorting: {
        sortBy,
        sortDirection,
      },
      activePage,
      totalPages,
    } = this.props;

    const tableSettings = {
      height: 500,
      width: getParentBlockWidth('antibodies-page'),
      headerHeight: 50,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      rowHeight: this.getRowHeight,
      useDynamicRowHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Sequence heavy chain',
        dataKey: 'heavySequence',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left antibodies-page__cell',
        width: this.getColumnPercentWidth(16),
      },
      {
        label: 'Sequence light chain',
        dataKey: 'lightSequence',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left antibodies-page__cell',
        width: this.getColumnPercentWidth(16),
      },
      {
        label: 'Clone ids',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(11),
        cellRenderer: ({ rowData }) => (
          <div>
            <u>Heavy:</u> {rowData.heavyDefinition}<br/>
            <u>Light:</u> {rowData.lightDefinition}
          </div>
        ),
        csvRenderer: rowData => (`Heavy: ${rowData.heavyDefinition}. Light: ${rowData.lightDefinition}`)
      },
      {
        label: 'Organism',
        dataKey: 'organism',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(9),
      },
      {
        label: 'Targets',
        dataKey: 'targetsMentioned',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(9),
        sortValueMapper: (rowData) => {
          const items = rowData.get('targetsMentioned') && rowData.get('targetsMentioned').toArray() || [];
          return items.length;
        },
        cellRenderer: ({ rowData }) => {
          const { targetsMentioned } = rowData;
          if (!targetsMentioned) return null;
          let cell;

          if (targetsMentioned.length <= 4) {
            cell = (targetsMentioned.map((item, i) => (
              <div key={i} className="table-wrap__cell-row">
                {item}
              </div>
            )));
          } else {
            cell = (
              <div
                className="table-wrap__cell-row link"
                onClick={() => {
                  this.showConceptsPopup(targetsMentioned, 'Targets');
                }}
              >
                {targetsMentioned.length}
              </div>
            );
          }
          return cell;
        },
      },
      {
        label: 'Title',
        dataKey: 'referenceTitle',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(14),
        cellRenderer: ({ rowData }) => {
          if (rowData.publicationId) {
            return <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${RELATIVE_PATH}/publication-details/${rowData.publicationId}`}
            >
              {rowData.referenceTitle}
            </a>;
          } else if (rowData.pubmedId) {
            return <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://pubmed.ncbi.nlm.nih.gov/${rowData.pubmedId}`}
            >
              {rowData.referenceTitle}
            </a>;
          } else {
            return `${rowData.referenceTitle}`;
          }
        }
      },
      {
        label: 'Keywords',
        dataKey: 'keywordsText',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(11),
        cellRenderer: ({ rowData }) => {
          const { keywordsText } = rowData;
          if (!keywordsText) return null;
          let cell;

          if (keywordsText.length <= 4) {
            cell = (keywordsText.map((item, i) => (
              <div key={i} className="table-wrap__cell-row">
                {item}
              </div>
            )));
          } else {
            cell = (
              <div
                className="table-wrap__cell-row link"
                onClick={() => {
                  this.showConceptsPopup(keywordsText, 'Keywords');
                }}
              >
                {keywordsText.length}
              </div>
            );
          }
          return cell;
        },
      },
      {
        label: 'Source',
        dataKey: 'src',
        exportCSV: true,
        disableSort: false,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(7),
      },
      {
        label: 'Last Updated',
        dataKey: 'updateDate',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(7),
      },
      {
        label: 'Reference URLs',
        dataKey: 'urls',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(7),
        cellRenderer: ({rowData}) => (
          rowData.urls.map((url, index) => (
            <a
              key={url+index}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
            >
              Reference
            </a>
          ))
        )
      },
    ];

    const conceptsPopupContent = conceptsPopupData.map((item, i) => (
      <a
        className="indications-popup__item"
        key={i}
      >
        {item}
      </a>
    ));

    return (
      <div className="antibodies-page__table text-center">
        {
          data && data.length > 0 &&
          <div className="table-wrap">
            <div className="controls-block-2items">
              {
                totalPages > 1 &&
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    pageCount={totalPages}
                    forcePage={activePage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              }
              <ExportTable
                content={allData}
                columns={columns}
                fileName={`${concept.name}-antibodies`}
              />
            </div>
            <SimpleTable
              data={data}
              columns={columns}
              settings={tableSettings}
              dynamicHeight={true}
              sortAction={sortAntibodiesTableAction}
            />
          </div>
        }
        {
          conceptsPopupData.length > 0 &&
          <ModalComponent
            isOpen={conceptsPopupData.length > 0}
            closeCb={this.closeConceptsPopup}
          >
            <div className="indications-popup">
              <div className="indications-popup__title">{conceptsPopupName}</div>
              <div className="indications-popup__wrap">
                { conceptsPopupContent }
              </div>
            </div>
          </ModalComponent>
        }
        {
          !loading && (!data || !data.length) &&
            <span className="antibodies-page__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

AntibodiesPlabDabTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getAntibodiesLoadingSelector(state),
    data: getAntibodiesPatentsSelector(state),
    allData: getAntibodiesAllPatentsSelector(state),
    concept: getAntibodiesConceptSelector(state),
    sorting: getAntibodiesSortingSelector(state),
    activePage: getPatentsPageSelector(state),
    totalPages: getPatentsTotalPagesPagesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sortAntibodiesTable(data) {
      dispatch(sortAntibodiesTableAction(data));
    },
    changePage(data) {
      dispatch(changePagePatentsAntibodiesAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AntibodiesPlabDabTable);

