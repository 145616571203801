import { createSelector } from 'reselect';

import { sort } from '../../../Utils/Utils';

import {
  PAGE_SIZE,
} from './utils';

const sorting = state => state.getIn(['pubDetailsTriples', 'sorting']);
const content = state => state.getIn(['pubDetailsTriples', 'result']);
const totalPages = state => state.getIn(['pubDetailsTriples', 'totalPages']);
const page = state => state.getIn(['pubDetailsTriples', 'page']);
const loading = state => state.getIn(['pubDetailsTriples', 'loading']);
const pageNumber = state => state.getIn(['pubDetailsTriples', 'result', 'number']);
const error = state => state.getIn(['pubDetailsTriples', 'error']);

export const getCurrentPageData = createSelector(
  content,
  sorting,
  page,
  (_content, _sorting, _page) => {
    const sortedData = sort(_content, _sorting.toJS());
    const totalLength = sortedData.toJS().length;
    const start = _page * PAGE_SIZE;
    const end = (_page * PAGE_SIZE) + PAGE_SIZE;
    const result = sortedData.toJS().slice(start, end > totalLength ? totalLength : end);
    return result;
  }
);

export const getAllData = createSelector(
  content,
  data => data && data.toJS()
);

export const getContentForExportSelector = createSelector(
  content,
  sorting,
  (_content, _sorting) => {
    const sortedData = sort(_content, _sorting.toJS());
    return sortedData.toJS();
  }
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getSelectedItems = createSelector(
  content,
  data => data.filter(item => item.get('selected')).toJS()
);

export const getLoadingKey = createSelector(
  loading,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
