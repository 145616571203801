import { takeLatest } from 'redux-saga/effects';

import {
  getSingleTargetForCancerTissuesAction,
  getSingleTargetForCancerChartsAction,
} from '../reducer';

import { getSingleTargetForCancerTissuesWorker } from './workers/getSingleTargetForCancerTissuesWorker';
import { getSingleTargetForCancerChartsWorker } from './workers/getSingleTargetForCancerChartsWorker';

function* algorithmSingleTargetForCancerSaga() {
  yield takeLatest(getSingleTargetForCancerTissuesAction, getSingleTargetForCancerTissuesWorker);
  yield takeLatest(getSingleTargetForCancerChartsAction, getSingleTargetForCancerChartsWorker);
}

export default algorithmSingleTargetForCancerSaga;
