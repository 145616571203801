import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form/immutable';

// Components
import FormInput from '../../../../common/FormElements/FormInput';
import FormSelect from '../../../../common/FormElements/FormSelect';
import CreateSetFilterField from '../CreateSetFilterField/CreateSetFilterField';
import CreateSetFilterCheckbox from '../CreateSetFilterCheckbox/CreateSetFilterCheckbox';
// Utils
import {
  geneSetFiltersNames,
  geneSetFiltersLabels,
  taxonomyOptions,
} from '../../enums';

const propTypes = {
  availableFilters: PropTypes.instanceOf(Object),
  conceptsIds: PropTypes.instanceOf(Array),
  searchTerm: PropTypes.string,
  getGeneFilterOptions: PropTypes.func,
  type: PropTypes.string,
};

const CreateSetGeneFilters = (props) => {
  const {
    type,
    availableFilters,
    conceptsIds,
    searchTerm,
    getGeneFilterOptions,
  } = props;

  function getGeneSetFilterOptions(filterName) {
    const { options, prefix } = availableFilters[filterName];

    if (options.length) return;

    const ids = conceptsIds.length && !conceptsIds[0].id ? conceptsIds : conceptsIds.map(c => c.id);//bloody hack
    getGeneFilterOptions({
      requestData: ids,
      prefix,
      filterName,
    });
  }

  return (
    <>
      {
        availableFilters[geneSetFiltersNames.GENE_TAXONOMY_FILTER] &&
        <CreateSetFilterField
          isSection={true}
          fieldName={geneSetFiltersNames.GENE_TAXONOMY_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_TAXONOMY_FILTER}
          infoTooltip={{ id: 'taxonomyTooltip', text: 'Filter on taxonomy' }}
          fieldProps={{
            name: 'taxonomy',
            component: FormSelect,
            options: taxonomyOptions,
            isClearable: true,
            returnOption: true,
            selectedValue: type === 'CREATE_SET' ? taxonomyOptions[0] : taxonomyOptions[8],
          }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_CLASSIFICATION_FILTER] &&
        <CreateSetFilterField
          isSection={true}
          fieldName={geneSetFiltersNames.GENE_CLASSIFICATION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_CLASSIFICATION_FILTER}
          infoTooltip={{ id: 'geneClassificationsTooltip', text: 'Filter on gene classification' }}
          fieldProps={{
            name: 'classificationId',
            component: FormSelect,
            options: availableFilters[geneSetFiltersNames.GENE_CLASSIFICATION_FILTER].options,
            isLoading: availableFilters[geneSetFiltersNames.GENE_CLASSIFICATION_FILTER].isLoading,
            isClearable: true,
            returnOption: true,
            onFocus: () => getGeneSetFilterOptions(geneSetFiltersNames.GENE_CLASSIFICATION_FILTER),
          }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER] &&
        <CreateSetFilterField
          isSection={true}
          fieldName={geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_SUBCELLULAR_LOCATION_FILTER}
          infoTooltip={{ id: 'geneLocationsTooltip', text: 'Filter on cellular location' }}
          fieldProps={{
            name: 'ids',
            component: FormSelect,
            options: availableFilters[geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER].options,
            isLoading: availableFilters[geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER].isLoading,
            isMulti: true,
            isClearable: true,
            hideSelectedOptions: true,
            returnOption: true,
            onFocus: () => getGeneSetFilterOptions(geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER),
          }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER] &&
        <FormSection
          name={geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER}
          className="create-set-filter__wrap"
        >
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldName={geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER}
            fieldLabel={geneSetFiltersLabels.GENE_TISSUE_EXPRESSION_FILTER}
            infoTooltip={{ id: 'tissueExpressionTooltip', text: 'Filter on expression in specific tissue' }}
            fieldProps={{
              name: 'ids',
              component: FormSelect,
              options: availableFilters[geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER].options,
              isLoading: availableFilters[geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER].isLoading,
              isMulti: true,
              hideSelectedOptions: true,
              returnOption: true,
              onFocus: () => getGeneSetFilterOptions(geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER),
            }}
          />
          <div className="create-set-filter__row">
            <CreateSetFilterField
              customClass="create-set-filter__block_no-padding"
              fieldLabel="Minimum expression (TPM)"
              fieldProps={{
                name: 'lowerExpressionThreshold',
                type: 'number',
                placeholder: 'Number',
                component: FormInput,
              }}
            />
            <CreateSetFilterField
              customClass="create-set-filter__block_no-padding"
              fieldLabel="Maximum expression (TPM)"
              fieldProps={{
                name: 'higherExpressionThreshold',
                type: 'number',
                placeholder: 'Number',
                component: FormInput,
              }}
            />
          </div>
        </FormSection>
      }
      {
        availableFilters[geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER] &&
        <FormSection
          name={geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER}
          className="create-set-filter__wrap"
        >
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldName={geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER}
            fieldLabel={geneSetFiltersLabels.GENE_CELL_TYPE_EXPRESSION_FILTER}
            infoTooltip={{ id: 'geneCellTypeExpressionTooltip', text: 'Filter on expression in specific celltypes' }}
            fieldProps={{
              name: 'ids',
              component: FormSelect,
              options: availableFilters[geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER].options,
              isLoading: availableFilters[geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER].isLoading,
              isMulti: true,
              returnOption: true,
              hideSelectedOptions: true,
              onFocus: () => getGeneSetFilterOptions(geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER),
            }}
          />
          <div className="create-set-filter__row">
            <CreateSetFilterField
              customClass="create-set-filter__block_no-padding"
              fieldLabel="Minimum expression (TPM)"
              fieldProps={{
                name: 'lowerExpressionThreshold',
                type: 'number',
                placeholder: 'Number',
                component: FormInput,
              }}
            />
            <CreateSetFilterField
              customClass="create-set-filter__block_no-padding"
              fieldLabel="Maximum expression (TPM)"
              fieldProps={{
                name: 'higherExpressionThreshold',
                type: 'number',
                placeholder: 'Number',
                component: FormInput,
              }}
            />
          </div>
        </FormSection>
      }
      {
        availableFilters[geneSetFiltersNames.GENE_CHEMICAL_ANTAGONISTS_FILTER] &&
        <CreateSetFilterField
          isSection={true}
          fieldName={geneSetFiltersNames.GENE_CHEMICAL_ANTAGONISTS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_CHEMICAL_ANTAGONISTS_FILTER}
          infoTooltip={{ id: 'chemicalAntagonistsTooltip', text: 'Related gene has an antagonist' }}
          fieldProps={{
            name: 'upperThreshold',
            type: 'number',
            placeholder: 'Maximum IC50 (nM)',
            component: FormInput,
          }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_CHEMICAL_AGONIST_FILTER] &&
        <CreateSetFilterField
          isSection={true}
          fieldName={geneSetFiltersNames.GENE_CHEMICAL_AGONIST_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_CHEMICAL_AGONIST_FILTER}
          infoTooltip={{ id: 'chemicalAgonistTooltip', text: 'Related gene has an agonist' }}
          fieldProps={{
            name: 'upperThreshold',
            type: 'number',
            placeholder: 'Maximum IC50 (nM)',
            component: FormInput,
          }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_TOOL_COMPOUNDS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE_TOOL_COMPOUNDS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_TOOL_COMPOUNDS_FILTER}
          infoTooltip={{ id: 'toolCompoundsTooltip', text: 'Related gene has a tool compound' }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE_KNOWN_DRUG_TARGETS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE_KNOWN_DRUG_TARGETS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE_KNOWN_DRUG_TARGETS_FILTER}
          infoTooltip={{ id: 'knownDrugTargetsTooltip', text: 'Related gene is a known drug target' }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_DIRECT_PROTEIN_INTERACTION_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_DIRECT_PROTEIN_INTERACTION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_DIRECT_PROTEIN_INTERACTION_FILTER}
          infoTooltip={{ id: 'directProteinInteractionTooltip', text: `Related gene is bind to ${searchTerm}` }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_TRANSCRIPTION_FACTORSM_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_TRANSCRIPTION_FACTORSM_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_TRANSCRIPTION_FACTORSM_FILTER}
          infoTooltip={{ id: 'transcriptionFactorsTooltip', text: 'Related gene is a transcription factor' }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_FUNCTIONAL_INHIBITION_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_FUNCTIONAL_INHIBITION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_FUNCTIONAL_INHIBITION_FILTER}
          infoTooltip={{ id: 'functionalInhibitionTooltip', text: `Related gene is inhibited by ${searchTerm}` }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_FUNCTIONAL_STIMULATION_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_FUNCTIONAL_STIMULATION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_FUNCTIONAL_STIMULATION_FILTER}
          infoTooltip={{ id: 'functionalStimulationTooltip', text: `Related gene is stimulated by ${searchTerm}` }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_FUNCTIONALLY_INHIBITED_BY_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_FUNCTIONALLY_INHIBITED_BY_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_FUNCTIONALLY_INHIBITED_BY_FILTER}
          infoTooltip={{ id: 'functionallyInhibitedTooltip', text: `${searchTerm} is inhibited by related gene` }}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENES_FUNCTIONAL_STIMULATION_BY_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENES_FUNCTIONAL_STIMULATION_BY_FILTER}
          fieldLabel={geneSetFiltersLabels.GENES_FUNCTIONAL_STIMULATION_BY_FILTER}
          infoTooltip={{ id: 'functionallyStimulatedTooltip', text: `${searchTerm} is stimulated by related gene` }}
        />
      }
    </>
  );
};

CreateSetGeneFilters.propTypes = propTypes;

export default CreateSetGeneFilters;
