export const SPINNER_COLOR = '#2b5566';
export const RED_COLOR = '#f44336';
export const DARK_RED_COLOR = '#d41305';
export const GREEN_COLOR = '#4caf50';
export const DARK_GREEN_COLOR = '#46964a';
export const BLUE_COLOR = '#297EC9';
export const PURPLE_COLOR = '#76649d';
export const GREY_COLOR = '#8a8a8a';
export const GOLD_COLOR = '#b68b64';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FULL_FORMAT = 'yyyy-MM-dd, HH:mm:ss';
export const RELATIVE_PATH = process.env.REACT_APP_RELATIVE_PATH || '';
export const windowHeight = window.innerHeight;

export const CooccurrenceEnum = {
  ABSTRACT: 'ABSTRACT',
  SENTENCE: 'SENTENCE',
  TITLE: 'TITLE',
};

export const EnvironmentsEnum = {
  DEV: 'dev',
  PROD: 'prod',
};

export const scaleOptions = {
  linear: {
    value: 'LINEAR',
  },
  log: {
    value: 'LOG',
  },
};

export const THEME = {
  LIGHT: 'LIGHT',
  GREY: 'GREY',
  SEMI: 'SEMI',
  DARK: 'DARK',
};

export const types = [
  {
    name: 'Gene',
    value: 'T028',
  },
  {
    name: 'Protein',
    value: 'T116',
  },
  {
    name: 'Pathway',
    value: 'T204',
  },
  {
    name: 'Small molecules',
    value: 'T104',
  },
  {
    name: 'any',
    value: '',
  },
];

export const taxonomies = ['Homo sapiens', 'Mus musculus'];

export const conceptTypesIdsEnum = {
  GENE: 'T028',
  PROTEIN: 'T116',
  PATHWAY: 'T204',
  SMALL_MOLECULES: 'T104',
};

export const conceptTypesByIds = {
  [conceptTypesIdsEnum.GENE]: {
    id: conceptTypesIdsEnum.GENE,
    name: 'gene',
  },
  [conceptTypesIdsEnum.PROTEIN]: {
    id: conceptTypesIdsEnum.PROTEIN,
    name: 'protein',
  },
  [conceptTypesIdsEnum.PATHWAY]: {
    id: conceptTypesIdsEnum.PATHWAY,
    name: 'pathway',
  },
  [conceptTypesIdsEnum.SMALL_MOLECULES]: {
    id: conceptTypesIdsEnum.SMALL_MOLECULES,
    name: 'small molecules',
  },
};

export const genesTaxonomies = [
  'Homo sapiens',
  'Mus musculus',
  'Rattus norvegicus',
  'Danio rerio',
  'Macaca mulatta',
];

export const outerUrls = {
  genmab: `${RELATIVE_PATH}/spine-ws/saml2/authenticate/genmab`,
  bms: `${RELATIVE_PATH}/spine-ws/saml2/authenticate/bms`,
};
