import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Components
import ResultsTable from '../ResultsTable/ResultsTable';
import ResultsExpressionGraphsModal from '../ResultsExpressionGraphsModal/ResultsExpressionGraphsModal';
import Loader from '../../../../../common/Loader/Loader';
import Error from '../../../../../common/Error/Error';
// store
import * as SELECTORS from '../../store/selectors';
// Styles
import './styles.scss';
import Heatmap from '../../../../../graphics/Heatmap/Heatmap';
import {HeatMapMeasuresOptions} from '../../constants';

const propTypes = {
  allData: PropTypes.instanceOf(Array),
  heatmap: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const ResultsExpressionSection = (props) => {
  const {
    allData,
    heatmap,
    loading,
    error,
  } = props;

  return (
    <>
      {
        allData && allData.length > 0 && !loading &&
        <div className="results-expression-section">
          <ResultsExpressionGraphsModal />
          <ResultsTable />
        </div>
      }
      {
        heatmap && !loading &&
        <Heatmap
          showHeatMap={true}
          showPublicationsPopup={false}
          titleForDownloading={'Cell lines to genes set heatmap'}
          measureSelector={HeatMapMeasuresOptions}
          showShowOnRelationMap={false}
          showSaveAsSet={false}
          showDeleteSelected={false}
          allowNegativeValues={true}
        />
      }
      <Loader isLoading={loading} />
      <Error show={!!error} error={error} />
    </>
  );
};

ResultsExpressionSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    allData: SELECTORS.getAllResultsDataSelector(state),
    heatmap: SELECTORS.getHeatmapDataSelector(state),
    loading: SELECTORS.getResultsDataLoadingSelector(state),
    error: SELECTORS.getResultsDataErrorSelector(state),
  };
}

export default React.memo(connect(
  mapStateToProps,
  null
)(ResultsExpressionSection));
