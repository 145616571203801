import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  closeCb: PropTypes.func,
};

const AddToRightfindWarningModal = (props) => {
  const {
    isOpen,
    closeCb,
    onConfirm,
  } = props;

  const text = 'Only pubmed references will be added to rightfind';

  if (!isOpen) {
    return null;
  }

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      confirmBtnText="Proceed"
      cancelBtnText="Cancel"
      isOpen={isOpen}
      closeCb={closeCb}
      text={text}
    />
  );
};

AddToRightfindWarningModal.propTypes = propTypes;

export default React.memo(AddToRightfindWarningModal);
