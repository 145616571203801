import { createSelector } from 'reselect';

const unresolvedConceptsSuggestionValues = state => state.getIn(['unresolvedConceptsReducer', 'data']);
const unresolvedConceptsSuggestionLoading = state => state.getIn(['unresolvedConceptsReducer', 'loading']);

export const getUnresolvedConceptsSuggestion = createSelector(
  unresolvedConceptsSuggestionValues,
  data => data && data.toJS()
);

export const getUnresolvedConceptsSuggestionLoading = createSelector(
  unresolvedConceptsSuggestionLoading,
  data => data
);
