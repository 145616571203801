import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';
// Utils
import { getTotalPages } from '../../common/Pagination/utils';

const initialState = fromJS({
  setData: {
    set: null,
    loading: false,
    error: '',
    sorting: {
      sortBy: 'name',
      sortDirection: 'ASC',
    },
    pages: {
      totalPages: 0,
      pageNumber: 0,
    },
  },
  openSetDialog: false,
  method: null,
  category: null,
  type: null,
});

const reducer = handleActions(
  {
    [a.getFindRelatedSetDataAction]: state =>
      state
        .updateIn(['setData', 'loading'], () => true),
    [a.setFindRelatedSetDataAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'set'], () => fromJS(payload))
        .updateIn(['setData', 'error'], () => initialState.get('error'))
        .updateIn(['setData', 'loading'], () => false)
        .updateIn(['setData', 'pages', 'pageNumber'], () => 0)
        .updateIn(['setData', 'pages', 'totalPages'], () => getTotalPages(payload.items)),
    [a.throwFindRelatedSetDataAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'error'], () => payload)
        .updateIn(['setData', 'loading'], () => false),
    [a.sortFindRelatedSetAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'sorting'], () => fromJS(payload))
        .updateIn(['setData', 'pages', 'pageNumber'], () => 0),
    [a.pagginateFindRelatedSetAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'pages', 'pageNumber'], () => payload),
    [a.setFindRelatedMethodAction]: (state, { payload }) =>
      state
        .update('method', () => payload),
    [a.setFindRelatedCategoryAction]: (state, { payload }) =>
      state
        .update('category', () => payload),
    [a.setFindRelatedTypeAction]: (state, { payload }) =>
      state
        .update('type', () => payload),
    [a.closeSetDialogAction]: state =>
      state
        .update('openSetDialog', () => false),
    [a.openSetDialogAction]: state =>
      state
        .update('openSetDialog', () => true),
    [a.resetFindRelatedAction]: () => initialState,
  },
  initialState
);

export default reducer;
