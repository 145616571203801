import { createSelector } from 'reselect';

const buildHeatmapSet = state => state.getIn(['buildHeatmapReducer', 'set']);
const buildHeatmapConcepts = state => state.getIn(['buildHeatmapReducer', 'heatmapConcepts']);
const buildHeatmapLoading = state => state.getIn(['buildHeatmapReducer', 'loading']);
const buildHeatmapError = state => state.getIn(['buildHeatmapReducer', 'error']);
const buildHeatmapAxisSwaped = state => state.getIn(['buildHeatmapReducer', 'axisSwaped']);

export const getBuildHeatmapSetSelector = createSelector(
  buildHeatmapSet,
  data => data && data.toJS()
);

export const getBuildHeatmapConceptsSelector = createSelector(
  buildHeatmapConcepts,
  data => data && data.toJS()
);

export const getBuildHeatmapErrorSelector = createSelector(
  buildHeatmapError,
  data => data
);

export const getBuildHeatmapLoadingSelector = createSelector(
  buildHeatmapLoading,
  data => data
);

export const getBuildHeatmapAxisConceptsSelector = createSelector(
  buildHeatmapConcepts,
  buildHeatmapAxisSwaped,
  (_data, _axisBoolean) => {
    const axisBoolean = _axisBoolean;
    const connectedConceptIds = _data.get('connectedConceptIds').toJS();
    const conceptIds = _data.get('conceptIds').toJS();
    return {
      additionalConcepts: axisBoolean ? connectedConceptIds : conceptIds,
      mainConcepts: axisBoolean ? conceptIds : connectedConceptIds,
    };
  }
);
