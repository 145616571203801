import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  putPatentsSequencesAction,
  patentsSequencesFailedAction,
} from '../../../redux/actions';

export function* getPatentsSequencesWorker(action) {
  try {
    const requestData = action.data;
    requestData.params = action.data.params || {
      size: 20,
      page: 0,
    };
    const response = yield call(Api.getPatentsPatentSequences, requestData);
    yield put(putPatentsSequencesAction(response));
  } catch (e) {
    yield put(patentsSequencesFailedAction(e.message));
  }
}
