/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect';

// Utils
import {
  ABSTRACT_PREDICATE_NAME,
  SENTENCE_PREDICATE_NAME,
  RELATION_MAP_CONCEPTS_LIMIT,
  RelationNumberMetricEnum,
} from '../constants';
// Store
import { getHideSourceSelectedDataIdsSelector } from '../Components/HideSourceModal/store/selectors';
import { getHideRelationTypesSelectedIdsSelector } from '../Components/HideRelationTypesModal/store/selectors';

const relationMapData = state => state.getIn(['relationMapProject', 'data']);
const relationMapError = state => state.getIn(['relationMapProject', 'error']);
const relationMapLayout = state => state.getIn(['relationMapProject', 'layout']);
const relationMapLoading = state => state.getIn(['relationMapProject', 'loading']);
const relationMapCache = state => state.getIn(['relationMapProject', 'cachedData']);
const relationMapIsSaved = state => state.getIn(['relationMapProject', 'isSaved']);
const relationMapSettings = state => state.getIn(['relationMapProject', 'settings']);
const relationMapDimensions = state => state.getIn(['relationMapProject', 'dimensions']);
const relationMapProjectInfo = state => state.getIn(['relationMapProject', 'project']);
const relationMapAllSelected = state => state.getIn(['relationMapProject', 'allSelected']);
const relationMapNodesIsFixed = state => state.getIn(['relationMapProject', 'nodesIsFixed']);
const relationMapSemanticCategories = state => state.getIn(['relationMapProject', 'semanticCategories']);
const relationMapSemanticCategoriesOrder = state => state.getIn(['relationMapProject', 'semanticCategoriesOrder']);
const relationMapRelatedConceptsOptions = state => state.getIn(['relationMapProject', 'relatedConceptsOptions']);

export const getRelationMapFilteredDataSelector = createSelector(
  relationMapData,
  relationMapSettings,
  relationMapSemanticCategories,
  (data, settings, semCategories) => {
    if (!data) return data;
    const { nodes, links } = data.toJS();
    const {
      hideSource,
      hideBelowCount,
      showCooccurrence,
      hideRelationTypes,
      showCloselyRelatedPubs,
    } = settings.toJS();
    const semCat = semCategories.toJS();

    const nodesIds = Object.keys(nodes);
    const linksIds = Object.keys(links);

    const filteredNodes = nodesIds.reduce((n, id) => {
      const node = n[id];
      const filtered = { ...n };

      if (semCat[node.semanticCategory].checked === false) {
        delete filtered[id];
        return filtered;
      }

      return filtered;
    }, nodes);

    const filteredLinks = linksIds.reduce((l, id) => {
      const link = l[id];
      const filtered = { ...l };

      if (
        !showCooccurrence &&
        !showCloselyRelatedPubs &&
        link.triples.some(t =>
          t.predicateName === ABSTRACT_PREDICATE_NAME ||
          t.predicateName === SENTENCE_PREDICATE_NAME ||
          t.predicateName === 'lost relation'
        )
      ) {
        delete filtered[id];
        return filtered;
      }

      if (!filteredNodes[link.source] || !filteredNodes[link.target]) {
        delete filtered[id];
        return filtered;
      }

      if (showCooccurrence) {
        filtered[id].triples = link.triples.filter(t => t.predicateName !== SENTENCE_PREDICATE_NAME);
      }

      if (showCloselyRelatedPubs) {
        filtered[id].triples = link.triples.filter(t => t.predicateName !== ABSTRACT_PREDICATE_NAME);
      }

      if (hideSource) {
        filtered[id].triples = link.triples.filter(t => !t.hidenSource);
      }

      if (hideRelationTypes) {
        filtered[id].triples = link.triples.filter(t => !t.hidenRealationType);
      }

      if (hideBelowCount.hide) {
        const value = showCooccurrence ? link.cooccurrence.relatedPubs :
          showCloselyRelatedPubs ? link.cooccurrence.closelyRelatedPubs : link.triples.length;
        if (value < hideBelowCount.count) {
          delete filtered[id];
          return filtered;
        }
      }

      return filtered;
    }, links);

    return { nodes: filteredNodes, links: filteredLinks };
  }
);

export const getRelationMapDataSelector = createSelector(
  getRelationMapFilteredDataSelector,
  (data) => {
    if (!data) return data;
    return {
      nodes: Object.values(data.nodes),
      links: Object.values(data.links),
    };
  }
);

export const getRelationMapDataWithoutCooccurrenceLinksSelector = createSelector(
  relationMapData,
  (data) => {
    if (!data) return data;
    const { nodes, links } = data.toJS();
    const linksIds = Object.keys(links);
    const filteredLinks = linksIds.reduce((l, id) => {
      const link = l[id];
      const filtered = { ...l };

      if (link.triples.some(t =>
        t.predicateName === ABSTRACT_PREDICATE_NAME ||
        t.predicateName === SENTENCE_PREDICATE_NAME ||
        t.predicateName === 'lost relation'
      )) {
        delete filtered[id];
      }
      return filtered;
    }, links);
    return { nodes, links: filteredLinks };
  }
);

export const getRelationMapLinksSelector = createSelector(
  getRelationMapDataWithoutCooccurrenceLinksSelector,
  data => (data ? Object.values(data.links) : data)
);

export const getRelationMapConceptsIdsSelector = createSelector(
  relationMapData,
  (data) => {
    if (!data) return [];
    const RMData = data && data.toJS();
    return Object.keys(RMData.nodes);
  }
);

export const getRelationMapSelectedConceptsSelector = createSelector(
  getRelationMapDataSelector,
  (data) => {
    if (!data) return [];
    const { nodes } = data;
    return nodes.filter(n => !!n.selected);
  }
);

export const getRelationMapConceptsLimitSelector = createSelector(
  getRelationMapDataSelector,
  (data) => {
    if (!data) return RELATION_MAP_CONCEPTS_LIMIT;
    const { nodes } = data;
    return RELATION_MAP_CONCEPTS_LIMIT - nodes.length;
  }
);

export const getRelationMapConceptsForCustomRelationSelector = createSelector(
  getRelationMapDataWithoutCooccurrenceLinksSelector,
  getRelationMapSelectedConceptsSelector,
  (data, selectedConcepts) => {
    if (!data || selectedConcepts.length !== 2) return [];
    const { links } = data;
    const targetLink = `${selectedConcepts[0].id}_${selectedConcepts[1].id}`;
    const sourseLink = `${selectedConcepts[1].id}_${selectedConcepts[0].id}`;
    const relation = !!links[targetLink] || !!links[sourseLink];
    return !relation ? selectedConcepts : [];
  }
);

export const getRelationMapLoadingSelector = createSelector(
  relationMapLoading,
  data => data
);

export const getRelationMapErrorSelector = createSelector(
  relationMapError,
  data => data
);

export const getRelationMapProjectInfoSelector = createSelector(
  relationMapProjectInfo,
  data => data && data.toJS()
);

export const getRelationMapAllSelectedSelector = createSelector(
  relationMapAllSelected,
  data => data
);

export const getRelationMapSemanticCategoriesMapSelector = createSelector(
  relationMapSemanticCategories,
  data => data && data.toJS()
);

export const getRelationMapSemanticCategoriesOrderSelector = createSelector(
  relationMapSemanticCategoriesOrder,
  data => data && data.toJS()
);

export const getRelationMapSemanticCategoriesSelector = createSelector(
  getRelationMapSemanticCategoriesMapSelector,
  data => data && Object.values(data)
);

export const getRelationMapDataForSaveSelector = createSelector(
  relationMapData,
  relationMapProjectInfo,
  relationMapSettings,
  relationMapLayout,
  getHideSourceSelectedDataIdsSelector,
  getHideRelationTypesSelectedIdsSelector,
  getRelationMapSemanticCategoriesSelector,
  (data, info, _settings, _layout, selectedSources = [], selectedRelationTypes = [], semCategories) => {
    const RMData = data && data.toJS();
    const RMInfo = info && info.toJS();
    const {
      hideLabels,
      hideSource,
      hideBelowCount,
      showCooccurrence,
      hideRelationTypes,
      showCloselyRelatedPubs,
    } = _settings.toJS();
    const layout = _layout.toJS();

    const links = Object.values(RMData.links).filter(
      l => !l.triples.some(t =>
        t.predicateName === ABSTRACT_PREDICATE_NAME ||
        t.predicateName === SENTENCE_PREDICATE_NAME ||
        t.predicateName === 'lost relation'
      )
    );

    const relationNumberMetric = showCooccurrence ? RelationNumberMetricEnum.COOCCURRENCE :
      showCloselyRelatedPubs ? RelationNumberMetricEnum.CLOSELY_RELATED : RelationNumberMetricEnum.TRIPLES;

    return {
      ...RMInfo,
      tags: RMInfo.tags.map(t => t.name),
      concepts: Object.values(RMData.nodes),
      links,
      mapOptions: {
        relationNumberMetric,
        hideLabels,
        hideBelowCount,
        hideSource,
        selectedSources,
        hideRelationTypes,
        selectedRelationTypes,
        layout,
        nodeColors: semCategories,
      },
    };
  }
);

export const getRelationMapSettingsSelector = createSelector(
  relationMapSettings,
  data => data && data.toJS()
);

export const getRelationMapHasCacheSelector = createSelector(
  relationMapCache,
  data => !!data
);

export const getRelationMapNodesIsFixedSelector = createSelector(
  relationMapNodesIsFixed,
  data => data
);

export const getRelationMapLayoutSelector = createSelector(
  relationMapLayout,
  data => data && data.toJS()
);

export const getRelationMapRelatedConceptsOptionsSelector = createSelector(
  relationMapRelatedConceptsOptions,
  data => data && data.toJS()
);

export const getRelationMapIsSavedSelector = createSelector(
  relationMapIsSaved,
  data => data
);

export const getRelationMapDimensionsSelector = createSelector(
  relationMapDimensions,
  data => data && data.toJS()
);

