import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getGeneDetailsAntibodiesAction = createAction('GENE_DETAILS_GET_ANTIBODIES');
export const setGeneDetailsAntibodiesAction = createAction('GENE_DETAILS_SET_ANTIBODIES');
export const toggleGeneDetailsAntibodiesLoaderAction = createAction('GENE_DETAILS_TOGGLE_ANTIBODIES_LOADER');
export const throwGeneDetailsAntibodiesErrorAction = createAction('GENE_DETAILS_THROW_ANTIBODIES_ERROR');

const initialState = fromJS({
  data: {
    antibodyType: {},
    reactivityData: [],
    applicationsData: [],
    antibodyLink: '',
    antibodySum: null,
    antibodyPublications: 0,
  },
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [setGeneDetailsAntibodiesAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('loading', () => false),
    [toggleGeneDetailsAntibodiesLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwGeneDetailsAntibodiesErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
  },
  initialState
);

export default reducer;
