import {
  call,
  put,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import {
  SET_NEW_FULL_SET,
  RESULT_SET_REQUESTED,
  RESET_SET_RESULT_PRIMARY_SETTINGS,
} from '../SetResultPage/constants';
import {
  getRightColumnConceptsId,
  getFullSet,
} from '../SetResultPage/selectors';
import { loading } from '../SetResultPage/actions';

import {
  FILTER_RELATIONS_REQUESTED,
  FILTER_RELATIONS_SUCCEEDED,
  FILTER_RELATIONS_FAILED,
  FILTER_CATEGORIES_REQUESTED,
  FILTER_CATEGORIES_SUCCEEDED,
  FILTER_CATEGORIES_FAILED,
  FILTER_TAXONOMIES_REQUESTED,
  FILTER_TAXONOMIES_SUCCEEDED,
  FILTER_TAXONOMIES_FAILED,
  FILTER_CATEGORIES_TYPE_REQUESTED,
  FILTER_CATEGORIES_TYPE_SUCCEEDED,
  FILTER_CATEGORIES_TYPE_FAILED,
  RELATED_CONCEPTS_REQUESTED,
  RELATED_CONCEPTS_SUCCEEDED,
  RELATED_CONCEPTS_FAILED,
  RELATED_CONCEPTS_LOADING,
  ADD_COLUMN_REQUESTED,
  ADD_COLUMN_SUCCEEDED,
  ADD_COLUMN_FAILED,
  CLOSE_ADD_LINKED_CONCEPTS,
  ADD_COLUMN_LOADING,
} from './constants';
import {
  getSelectedRelationPredicates,
  getSelectedCategories,
  getSelectedTaxonomies,
  getCheckedRelatedConcepts,
} from './selectors';

function* relations() {
  try {
    const rightColumnConceptsId = yield select(getRightColumnConceptsId);
    const requestData = {
      post: rightColumnConceptsId,
    };

    const response = yield call(Api.predicatesForFilter, requestData);
    const resp = response.data.map((relation, index) =>
      Object.assign(relation, {
        opened: false,
        id: index + 1,
        checked: false,
        predicates: relation.predicates.map(item =>
          Object.assign(item, { checked: false })
        ),
      })
    );

    yield put({ type: FILTER_RELATIONS_SUCCEEDED, data: resp });
  } catch (e) {
    yield put({ type: FILTER_RELATIONS_FAILED, message: e.message });
  }
}

function* categories() {
  try {
    const rightColumnConceptsId = yield select(getRightColumnConceptsId);
    const requestData = {
      post: rightColumnConceptsId,
    };

    const response = yield call(Api.semanticCategoriesForFilter, requestData);
    const resp = response.data.map((category, index) =>
      Object.assign(category, {
        opened: false,
        id: index + 1,
        checked: false,
        loading: false,
        types: [],
      })
    );

    yield put({ type: FILTER_CATEGORIES_SUCCEEDED, data: resp });
  } catch (e) {
    yield put({ type: FILTER_CATEGORIES_FAILED, message: e.message });
  }
}

function* semanticTypes(action) {
  const category = action.data;
  try {
    const rightColumnConceptsId = yield select(getRightColumnConceptsId);
    const requestData = {
      post: {
        concepts: rightColumnConceptsId,
        semCategory: category.categoryName,
      },
    };

    const response = yield call(Api.semanticCategoriesTypesForFilter, requestData);

    yield put({
      type: FILTER_CATEGORIES_TYPE_SUCCEEDED,
      data: {
        semanticCategoryId: category.id,
        types: response.data.map((item, index) =>
          Object.assign(item, { id: index, checked: category.checked })
        ),
      },
    });
  } catch (e) {
    yield put({
      type: FILTER_CATEGORIES_TYPE_FAILED,
      data: {
        semanticCategoryId: category.id,
        types: [],
      },
    });
  }
}

function* taxonomiesList() {
  try {
    const rightColumnConceptsId = yield select(getRightColumnConceptsId);
    const requestData = {
      post: rightColumnConceptsId,
    };

    const response = yield call(Api.taxonomiesForFilter, requestData);
    const resp = response.data.map((taxonomy, index) =>
      Object.assign(taxonomy, { id: index + 1, checked: false })
    );

    yield put({ type: FILTER_TAXONOMIES_SUCCEEDED, data: resp });
  } catch (e) {
    yield put({ type: FILTER_TAXONOMIES_FAILED, message: e.message });
  }
}

function* relatedConcepts() {
  try {
    yield put({ type: RELATED_CONCEPTS_LOADING });

    const rightColumnConceptsId = yield select(getRightColumnConceptsId);
    const predicates = yield select(getSelectedRelationPredicates);
    const {
      semCategories,
      semTypes,
    } = yield select(getSelectedCategories);
    const taxonomies = yield select(getSelectedTaxonomies);

    const requestData = {
      post: {
        ids: rightColumnConceptsId,
        filter: {
          predicates,
          semCategories,
          semTypes,
          taxonomies,
        },
      },
    };

    if (predicates.length > 0 || semCategories.length > 0 || semTypes.length > 0 || taxonomies.length > 0) {
      const response = yield call(Api.findLinkedConceptsResultSet, requestData);
      const resp = response.data.map((item, index) =>
        Object.assign(item, {
          id: index + 1,
          checked: false,
          conceptName: item.concept.name,
        })
      );
      yield put({ type: RELATED_CONCEPTS_SUCCEEDED, data: resp });
    } else {
      yield put({ type: RELATED_CONCEPTS_SUCCEEDED, data: [] });
    }
  } catch (e) {
    yield put({ type: RELATED_CONCEPTS_FAILED, message: e.message });
  }
}

function* addColumn() {
  try {
    yield put({ type: ADD_COLUMN_LOADING });
    yield put(loading(true));

    let fullSet = yield select(getFullSet);
    const newColumn = yield select(getCheckedRelatedConcepts);

    /* When adding a fourth concept need to shift existing B to A, and C to B, and newly added will be C */
    if (fullSet[0].nodeC) {
      fullSet = fullSet.map(fullSetItem => ({
        nodeA: fullSetItem.nodeB,
        nodeB: fullSetItem.nodeC,
      }));
    }

    const API = fullSet[0].nodeB ? 'resultSetAddC' : 'resultSetAddB';
    const requestData = {
      post: {
        fullSet,
        newColumn: newColumn.map(i => (
          {
            concept: i.concept,
            linkedNodes: i.linkedNodes,
          }),
        ),
      },
    };

    const response = yield call(Api[API], requestData);

    yield put({ type: ADD_COLUMN_SUCCEEDED });
    yield put({ type: SET_NEW_FULL_SET, data: response.data });
    yield put({ type: CLOSE_ADD_LINKED_CONCEPTS });
    yield put({ type: RESET_SET_RESULT_PRIMARY_SETTINGS });
    yield put({ type: RESULT_SET_REQUESTED });
  } catch (e) {
    yield put({ type: ADD_COLUMN_FAILED, message: e.message });
  }
}

function* setResultPageAddConcepts() {
  yield takeEvery(FILTER_RELATIONS_REQUESTED, relations);
  yield takeEvery(FILTER_CATEGORIES_REQUESTED, categories);
  yield takeEvery(FILTER_TAXONOMIES_REQUESTED, taxonomiesList);
  yield takeEvery(FILTER_CATEGORIES_TYPE_REQUESTED, semanticTypes);
  yield takeLatest(RELATED_CONCEPTS_REQUESTED, relatedConcepts);
  yield takeEvery(ADD_COLUMN_REQUESTED, addColumn);
}

export default setResultPageAddConcepts;
