import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaFilter } from 'react-icons/fa';

// Styles
import './styles.scss';

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  customClassName: PropTypes.string,
  options: PropTypes.instanceOf(Array),
};

const ButtonSelect = (props) => {
  const {
    text,
    options = [],
    onClick,
    onSelect,
    customClassName,
  } = props;

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const handleOnSelect = (d) => {
    onSelect(d);
    setDropdownIsOpen(false);
  };

  const renderOption = (d, i) => (
    <div
      key={`${d}-${i}`}
      onClick={() => { handleOnSelect(d); }}
      className="button-select__option"
    >
      {d}
    </div>
  );

  return (
    <div className={classNames('button-select', customClassName)}>
      <div className="button-select__wrap">
        <button
          type="button"
          onClick={onClick}
          className="button-select__main"
        >
          {text}
        </button>
        <button
          type="button"
          disabled={!options || !options.length}
          onClick={() => { setDropdownIsOpen(!dropdownIsOpen); }}
          className="button-select__toggle"
        >
          <FaFilter color="#4b3f63" size={15} />
        </button>
      </div>
      {
        dropdownIsOpen && options && options.length > 0 &&
        <div className="button-select__dropdown">
          {options.map(renderOption)}
        </div>
      }
    </div>
  );
};

ButtonSelect.propTypes = propTypes;

export default ButtonSelect;
