import { createSelector } from 'reselect';
import {filterPaginatedData, sort} from '../../Utils/Utils';
import {PAGE_SIZE} from '../Utils';

const loading = state => state.getIn(['antibodiesReducer', 'loading']);
const error = state => state.getIn(['antibodiesReducer', 'error']);
const sorting = state => state.getIn(['antibodiesReducer', 'sorting']);

const patents = state => state.getIn(['antibodiesReducer', 'plabdab']);
const wipos = state => state.getIn(['antibodiesReducer', 'wipos']);
const concept = state => state.getIn(['antibodiesReducer', 'concept']);

const patentsPage = state => state.getIn(['antibodiesReducer', 'patentsPage']);
const wiposPage = state => state.getIn(['antibodiesReducer', 'wiposPage']);

export const getAntibodiesLoadingSelector = createSelector(
  loading,
  data => data
);

export const getAntibodiesErrorSelector = createSelector(
  error,
  data => data
);

export const getAntibodiesSortingSelector = createSelector(
  sorting,
  data => data.toJS()
);

export const getAntibodiesPatentsCountSelector = createSelector(
  patents,
  (data) => {
    return data.size;
  }
);

export const getAntibodiesWiposCountSelector = createSelector(
  wipos,
  (data) => {
    return data.size;
  }
);

export const getAntibodiesPatentsSelector = createSelector(
  patents,
  sorting,
  patentsPage,
  (data, sorting, page) => {
    const sorted = sort(data, sorting.toJS());
    return filterPaginatedData(sorted && sorted.toJS(), page, PAGE_SIZE);
  }
);

export const getAntibodiesAllPatentsSelector = createSelector(
  patents,
  sorting,
  (data, sorting) => {
    const sorted = sort(data, sorting.toJS());
    return sorted && sorted.toJS();
  }
);

export const getAntibodiesWiposSelector = createSelector(
  wipos,
  sorting,
  wiposPage,
  (data, sorting, page) => {
    const sorted = sort(data, sorting.toJS());
    return filterPaginatedData(sorted && sorted.toJS(), page, PAGE_SIZE);
  }
);

export const getAntibodiesAllWiposSelector = createSelector(
  wipos,
  sorting,
  (data, sorting) => {
    const sorted = sort(data, sorting.toJS());
    return sorted && sorted.toJS();
  }
);

export const getAntibodiesConceptSelector = createSelector(
  concept,
  data => data && data.toJS()
);

export const getPatentsPageSelector = createSelector(
  patentsPage,
  data => data
);

export const getWiposPageSelector = createSelector(
  wiposPage,
  data => data
);

export const getPatentsTotalPagesPagesSelector = createSelector(
  [
    patents,
  ],
  (data) => {
    return  Math.ceil(data.size / PAGE_SIZE);
  }
);

export const getWiposTotalPagesPagesSelector = createSelector(
  [
    wipos,
  ],
  (data) => {
    return  Math.ceil(data.size / PAGE_SIZE);
  }
);
