import { fromJS } from 'immutable';

import {
  SORT,
  CHECK_ALL,
  CHECK_ITEM,
  REMOVE_SELECTED,
  INVERT_SELECTION,
} from '../../../common/SimpleTable/constants';

import {
  NETWORK_ANALYSIS_SUCCESS,
  NETWORK_ANALYSIS_FAILED,
  NETWORK_ANALYSIS_RESULT_TABLE,
  REMOVE_CONCEPT,
  LOADING,
  NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE,
  NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE, NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV_RESULT_SUCCESS,
} from './constants';
import {
  EMPTY_FILTER,
} from './enum';

const initialValues = fromJS({
  result: {
    content: [],
  },
  error: null,
  sorting: { sortBy: 'score', sortDirection: 'DESC' },
  loading: false,
  firstNodeTermFilter: '',
  fNodeCategoryFilter: EMPTY_FILTER,
  exportAllCSVResult: [],
});

const AnalysisResultNetwork = (state = initialValues, action) => {
  switch (action.type) {
    case NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV_RESULT_SUCCESS:
      return state.set('exportAllCSVResult', action.data);

    case NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE:
      return state.set('firstNodeTermFilter', action.data);

    case NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE:
      return state.set('fNodeCategoryFilter', action.data);

    case NETWORK_ANALYSIS_SUCCESS: {
      let { data } = action;
      let { content } = data;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => (
          Object.assign(item, { id: index + 1 })
        ));
      }
      data = Object.assign(data, { content });
      return state
        .set('result', fromJS(data))
        .set('error', null)
        .set('loading', false);
    }

    case NETWORK_ANALYSIS_FAILED:
      return state.merge(fromJS({ error: action.message, result: initialValues.get('result'), loading: false }));

    case NETWORK_ANALYSIS_RESULT_TABLE + SORT:
      return state.merge(fromJS({ sorting: action.data }));


    case NETWORK_ANALYSIS_RESULT_TABLE + CHECK_ALL:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => item.merge(fromJS({ selected: action.data })))
        ))
      ));

    case NETWORK_ANALYSIS_RESULT_TABLE + CHECK_ITEM:
      return state.update('result', result => (
        result.update('content', content => (
          content.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    case NETWORK_ANALYSIS_RESULT_TABLE + REMOVE_SELECTED:
      return state.update('result', result => (
        result.update('content', content => (
          content.filterNot(item => item.get('selected'))
        ))
      ));

    case NETWORK_ANALYSIS_RESULT_TABLE + INVERT_SELECTION:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => item.merge(fromJS({ selected: !item.get('selected') })))
        ))
      ));

    case NETWORK_ANALYSIS_RESULT_TABLE + REMOVE_CONCEPT:
      return state.update('result', result => (
        result.update('content', content => (
          content.delete(content.findIndex(item => item.get('id') === action.data))
        ))
      ));

    case NETWORK_ANALYSIS_RESULT_TABLE + LOADING:
      return state.merge(fromJS({
        loading: true,
        error: null,
      }));

    default:
      return state;
  }
};

export default AnalysisResultNetwork;
