import { call, put, takeLatest } from 'redux-saga/effects';

import * as a from './reducer';
import Api from '../../../../Api/Api';

function* getGeneOptionsWorker(action) {
  try {
    yield put(a.toggleSelectGeneLoaderAction(true));
    const { value, searchGeneForIndicationFinder } = action.payload;
    const requestData = { query: value };
    let results;

    if (searchGeneForIndicationFinder) {
      const { data } = yield call(Api.searchGenesForIndicationFinder, requestData);
      results = data;
    } else {
      const { data } = yield call(Api.searchGenes, requestData);
      // eslint-disable-next-line prefer-destructuring
      results = data.results;
    }

    const options = results.map(item => ({
      ...item,
      value: item.value || item.name,
      label: item.value || item.name,
    }));
    yield put(a.putGeneOptionsAction(options));
  } catch (error) {
    yield put(a.throwSelectGeneErrorAction(true));
    yield put(a.toggleSelectGeneLoaderAction(false));
  }
}

function* selectGeneSaga() {
  yield takeLatest(a.getGeneOptionsAction, getGeneOptionsWorker);
}

export default selectGeneSaga;
