import { effectOperation } from './constants';

export function fillAggregationData(content, effectColumns, effectOperationValue) {
  content.forEach((rowData) => {
    let result = 0;
    switch (effectOperationValue) {
      case effectOperation.Sum: {
        // eslint-disable-next-line no-return-assign
        effectColumns.forEach(c => result += rowData[c] || 0);
        break;
      }
      case effectOperation.Min: {
        const scores = [];
        effectColumns.forEach((c) => { if (rowData[c] !== undefined) scores.push(rowData[c]); });
        result = Math.min(...scores);
        break;
      }
      case effectOperation.Max: {
        const scores = [];
        effectColumns.forEach((c) => { if (rowData[c] !== undefined) scores.push(rowData[c]); });
        result = Math.max(...scores);
        break;
      }
      case effectOperation.Average: {
        let valuableColumns = 0;
        effectColumns.forEach((c) => {
          if (rowData[c] !== undefined) {
            result += rowData[c];
            valuableColumns += 1;
          }
        });
        if (valuableColumns > 0) {
          result = Math.round(result / valuableColumns);
        } else {
          result = '';
        }
        break;
      }
      case effectOperation.Median: {
        const scores = [];
        effectColumns.forEach((c) => { if (rowData[c] !== undefined) scores.push(rowData[c]); });
        scores.sort((a, b) => a - b);
        const mid = scores.length / 2;
        result = mid % 1 ? scores[mid - 0.5] : (scores[mid - 1] + scores[mid]) / 2;
        break;
      }
      default:
        result = '';
    }
    // eslint-disable-next-line no-param-reassign
    rowData.effectAggregation = result;
  });
}
