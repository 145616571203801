import React from 'react';
import { Outlet } from 'react-router-dom';

// Components
import Header from '../Header/Header';

const LayoutWrapper = () => (
  <main className="main">
    <Header />
    <div className="content">
      <Outlet />
    </div>
  </main>
);

export default LayoutWrapper;
