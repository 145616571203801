import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import Spinner from '../../common/Spinner/Spinner';
import FormInput from '../../common/FormElements/FormInput';
// Constants
import { NEW_PASSWORD_FORM } from './constants';

const propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

const SetNewPasswordForm = (props) => {
  const {
    handleSubmit,
    submitting,
    pristine,
    invalid,
  } = props;

  return (
    <form className="form-restore-password" onSubmit={handleSubmit}>
      <div className="row">
        <Field
          name="newPassword"
          placeholder="New password"
          component={FormInput}
          type="password"
        />
      </div>
      <div className="row">
        <Field
          name="newPasswordRepeat"
          placeholder="Repeat new password"
          component={FormInput}
          type="password"
        />
      </div>
      <div className="row text-center">
        <button className="button button-primary" disabled={pristine || submitting || invalid}>
          Submit
        </button>
        <Spinner isLoading={submitting} />
      </div>
    </form>
  );
};

SetNewPasswordForm.propTypes = propTypes;

function validateForm(values) {
  const errors = {};

  if (!values.get('newPassword') || values.get('newPassword').trim() === '') {
    errors.newPassword = 'Enter password';
  }
  if (!values.get('newPasswordRepeat') || values.get('newPasswordRepeat').trim() === '') {
    errors.newPasswordRepeat = 'Enter password';
  }
  if (values.get('newPassword') &&
    values.get('newPasswordRepeat') &&
    (values.get('newPassword').trim() !== values.get('newPasswordRepeat').trim())) {
    errors.newPasswordRepeat = 'Password is not repeated';
  }

  return errors;
}

export default reduxForm({
  form: NEW_PASSWORD_FORM,
  fields: ['newPassword', 'newPasswordRepeat'],
  validate: validateForm,
})(SetNewPasswordForm);
