import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

// Utils
import { insertHTML, formatDate } from '../../../Utils/Utils';
import { getFirst180Characters, highlightText } from '../../utils';
import { generateReprintsDeskLink, generateRightFindLink } from '../../../Publications/PublicationsManagement/utils';
// Components
import Button from '../../../common/Buttons/Button/Button';
import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { PUBMED_URL_DEFAULT } from '../../constants';
// Styles
import './index.scss';

const propTypes = {
  onChange: PropTypes.func,
  publication: PropTypes.instanceOf(Object),
  pubmedUrlPrefix: PropTypes.string,
  isAvailableForSelecting: PropTypes.bool,
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  rightFindUrlSuffix: PropTypes.string,
};

const SearchResultItem = (props) => {
  const {
    publication,
    onChange,
    showRightFind,
    showReprintDesk,
    pubmedUrlPrefix,
    isAvailableForSelecting,
    rightFindUrlSuffix,
  } = props;

  const {
    id,
    pmid,
    pmcId,
    source,
    title,
    pubDate,
    authors,
    sentences,
    checked,
    highlights,
    abstractInfo,
    highlightsTitle,
  } = publication;

  const date = useMemo(() => (
    pubDate ? formatDate(pubDate) : 'No date available'
  ), [pubDate]);

  const authorsFormatted = useMemo(() => (
    authors ? authors.slice(0, 3).join('; ') : ''
  ), [authors]);

  const handleAddToRightFind = useCallback(() => {
    window.open(generateRightFindLink(pmid, rightFindUrlSuffix), '_blank');
  }, [pmid, rightFindUrlSuffix]);

  const handleAddToReprintsDesk = useCallback(() => {
    window.open(generateReprintsDeskLink(pmid), '_blank');
  }, [pmid]);

  const handleOnChange = useCallback(() => {
    onChange(publication);
  }, [publication, onChange]);

  function getMarkedTitile() {
    if (!highlightsTitle || !highlightsTitle.length) {
      return title;
    }
    return highlightText(highlightsTitle, title);
  }

  function getMarkedText() {
    if (!highlights || !highlights.length) {
      return abstractInfo;
    }
    return highlightText(highlights, abstractInfo);
  }

  function get180MarkedText() {
    const text = getFirst180Characters(abstractInfo);
    if (!highlights || !highlights.length) {
      return text;
    }
    return highlightText(highlights, text);
  }

  function renderSentences(sentencesData) {
    return sentencesData.map((s, i) => {
      const allSentences = sentencesData.reduce((string, item) => (
        `${string} ${item} <br/>`
      ), '');

      return (
        <React.Fragment key={`sentence-${i}`}>
          <span
            className="search-result-item__yellow search-result-item__yellow--hover"
            data-tip={allSentences}
            data-html={true}
            dangerouslySetInnerHTML={insertHTML(getFirst180Characters(s))}
          /><br />
        </React.Fragment>
      );
    });
  }

  return (
    <div className="search-result-item">
      <div className="search-result-item__header">
        <Link
          to={`${RELATIVE_PATH}/publication-details/${id}`}
          className="search-result-item__title search-result-item__link title4"
          dangerouslySetInnerHTML={insertHTML(getMarkedTitile())}
        />
        {
          isAvailableForSelecting &&
          <Checkbox
            id={`publication-checkbox-${id}`}
            checked={checked}
            onChange={handleOnChange}
            wrapperClassName="search-result-item__checkbox"
          />
        }
      </div>
      <div className="search-result-item__info">
        <div
          className="search-result-item__text"
          data-tip={getMarkedText()}
          data-html={true}
          dangerouslySetInnerHTML={insertHTML(get180MarkedText())}
        />
        {
          date &&
          <div className="search-result-item__date">
            <b>Date: </b>
            <span className="search-result-item__yellow">
              {date}
            </span>
          </div>
        }
        {
          pmid &&
          <div className="search-result-item__info-item search-result-item__info-item--inline mr-15">
            <b>PMID: </b>
            <a
              href={(pubmedUrlPrefix || PUBMED_URL_DEFAULT).replace('%s', pmid)}
              target="blank"
              className="search-result-item__link"
            >
              {pmid}
            </a>
          </div>
        }
        {
          pmcId &&
          <div className="search-result-item__info-item search-result-item__info-item--inline">
            <b>PMC ID: </b>
            <a
              href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${pmcId}/`}
              target="blank"
              className="search-result-item__link"
            >
              {pmcId}
            </a>
          </div>
        }
        {
          source &&
          <div className="search-result-item__info-item">
            <b>Source:</b> {source}
          </div>
        }
        {
          authorsFormatted &&
          <div className="search-result-item__info-item">
            <b>Authors: </b>
            <span className="search-result-item__yellow">
              {authorsFormatted}
            </span>
          </div>
        }
        {
          sentences &&
          <div className="search-result-item__info-item">
            <b>Sentences: </b>
            {renderSentences(sentences)}
          </div>
        }
        <div className="publication-management">
          {
            showRightFind && pmid &&
            <Button
              onClick={handleAddToRightFind}
              customClassName="publication-management__single-citation-button mr-5"
              text="Go to RightFind"
            />
          }
          {
            showReprintDesk && pmid &&
            <Button
              onClick={handleAddToReprintsDesk}
              customClassName="publication-management__single-citation-button"
              text="Go to Article Galaxy"
            />
          }
        </div>
      </div>
      <ReactTooltip
        type="light"
        border={true}
        className="search-result-item__tooltip"
      />
    </div>
  );
};

SearchResultItem.propTypes = propTypes;

export default React.memo(SearchResultItem);
