import { createSelector } from 'reselect';

const shortConceptCardData = state => state.getIn(['shortConceptCardReducer', 'data']);
const shortConceptCardId = state => state.getIn(['shortConceptCardReducer', 'conceptId']);
const shortConceptCardPrevId = state => state.getIn(['shortConceptCardReducer', 'prevConceptId']);
const shortConceptCardLoading = state => state.getIn(['shortConceptCardReducer', 'loading']);
const shortConceptCardError = state => state.getIn(['shortConceptCardReducer', 'error']);

export const getShortConceptCardDataSelector = createSelector(
  shortConceptCardData,
  data => data && data.toJS()
);

export const getShortConceptCardIdSelector = createSelector(
  shortConceptCardId,
  data => data
);

export const getShortConceptCardPrevIdSelector = createSelector(
  shortConceptCardPrevId,
  data => data
);

export const getShortConceptCardLoadingSelector = createSelector(
  shortConceptCardLoading,
  data => data
);

export const getShortConceptCardErrorSelector = createSelector(
  shortConceptCardError,
  data => data
);
