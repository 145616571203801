import { createSelector } from 'reselect';

const twoFactorAuthenticationCode = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'code']);
const twoFactorAuthenticationUrl = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'url']);
const twoFactorAuthenticationLoading = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'loading']);
const twoFactorAuthenticationError = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'error']);
const twoFactorAuthenticationSelectedOption = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'selectedOption']);
const twoFactorAuthenticationPrevSelectedOption = state => state.getIn(['twoFactorAuthenticationReducer', 'twoFactorAuthentication', 'prevSelectedOption']);
const authenticatorCodeModalIsOpen = state => state.getIn(['twoFactorAuthenticationReducer', 'authenticatorCodeModal', 'isOpen']);
const authenticatorCodeError = state => state.getIn(['twoFactorAuthenticationReducer', 'authenticatorCodeModal', 'error']);
const authenticatorCodeModalIsLoading = state => state.getIn(['twoFactorAuthenticationReducer', 'authenticatorCodeModal', 'isLoading']);

export const getTwoFactorAuthenticationCodeSelector = createSelector(
  twoFactorAuthenticationCode,
  data => data
);

export const getTwoFactorAuthenticationUrlSelector = createSelector(
  twoFactorAuthenticationUrl,
  data => data
);

export const getTwoFactorAuthenticationLoadingSelector = createSelector(
  twoFactorAuthenticationLoading,
  data => data
);

export const getTwoFactorAuthenticationErrorSelector = createSelector(
  twoFactorAuthenticationError,
  data => data
);

export const getTwoFactorAuthenticationSelectedOption = createSelector(
  twoFactorAuthenticationSelectedOption,
  data => data && data.toJS()
);

export const getTwoFactorAuthenticationPrevSelectedOptionSelector = createSelector(
  twoFactorAuthenticationPrevSelectedOption,
  data => data && data.toJS()
);
export const getAuthenticatorCodeModalIsOpen = createSelector(
  authenticatorCodeModalIsOpen,
  data => data
);

export const getAuthenticatorCodeError = createSelector(
  authenticatorCodeError,
  data => data
);

export const getAuthenticatorCodeModalIsLoading = createSelector(
  authenticatorCodeModalIsLoading,
  data => data
);
