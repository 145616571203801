import {
  createSelector,
} from 'reselect';
import { formValueSelector } from 'redux-form/lib/immutable';

import { sort } from '../../Utils/Utils';
import { COPY_ANALYTICS_ITEM_PROJECT } from './constants';

const projects = state => state.getIn(['projectsTabsReducer', 'projects', 'data']);
const analytics = state => state.getIn(['setAnalysisManagement', 'analytics']);
const loading = state => state.getIn(['setAnalysisManagement', 'loading']);
const error = state => state.getIn(['setAnalysisManagement', 'error']);
const sorting = state => state.getIn(['setAnalysisManagement', 'sorting']);
const titleFilter = state => state.getIn(['setAnalysisManagement', 'filters', 'title']);
const tagFilter = state => state.getIn(['setAnalysisManagement', 'filters', 'tag']);
const setNameFilter = state => state.getIn(['setAnalysisManagement', 'filters', 'setName']);
const values = state => state.getIn(['form', 'COPY_ANALYTICS_ITEM', 'values']);
const analysisManagementActiveProjectId = state => state.getIn(['setAnalysisManagement', 'activeProjectId']);

export const getAllAnalytics = createSelector(
  analytics,
  sorting,
  titleFilter,
  tagFilter,
  setNameFilter,
  (_analytics, _sorting, _titleFilter, _tagFilter, _setNameFilter) => {
    const filteredByTitle = _analytics.filter(item => (
      item.get('title') === null ||
      item.get('title')
        .toLowerCase()
        .indexOf(_titleFilter) !== -1
    ));

    const filteredByTag = filteredByTitle.filter((item) => {
      if (_tagFilter && item.get('tags').size === 0) {
        return false;
      } else if (!_tagFilter) {
        return true;
      }
      const a = item.get('tags').filter(tag => (
        tag.toLowerCase().indexOf(_tagFilter) !== -1
      ));
      return a.size;
    });

    const filteredByConcept = filteredByTag.filter((item) => {
      if (!_setNameFilter || item.get('setsTitles').size === 0) {
        return true;
      }
      return item.get('setsTitles').filter(title => (
        title.toLowerCase().indexOf(_setNameFilter) !== -1
      )).size;
    });

    const sorted = sort(filteredByConcept, _sorting.toJS());
    return sorted.toJS();
  }
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getCheckedAnalyticsDataSelector = createSelector(
  [getAllAnalytics],
  data => (data ? data.filter(item => item.selected === true) : [])
);

export const getFormValuesSelector = formValueSelector(COPY_ANALYTICS_ITEM_PROJECT);

export const copyAnalyticsItemSelector = createSelector(
  values,
  data => data.toJS()
);

export const getActiveProjectIdSelector = createSelector(
  analysisManagementActiveProjectId,
  data => data
);

export const activeProjectDataSelector = createSelector(
  projects,
  analysisManagementActiveProjectId,
  (_data, _activeProjectId) => {
    const data = _data && _data.toJS();
    return data.find(item => item.id === _activeProjectId);
  }
);
export const getIsProjectLockedSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED'
);

export const getIsProjectLockedForUsersSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED_FOR_USERS'
);

export const getIsProjectPublicSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.public
);
