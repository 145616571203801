import { createSelector } from 'reselect';
import { filterPaginatedData, sort } from '../Utils/Utils';
import { CONCEPTS_PAGE_SIZE } from './constants';

const publication = state => state.getIn(['publicationDetails', 'publication', 'data']);
const pubmedId = state => state.getIn(['publicationDetails', 'publication', 'data', 'pubmedId']);
const publicationId = state => state.getIn(['publicationDetails', 'publicationId']);
const publicationLoading = state => state.getIn(['publicationDetails', 'publication', 'loading']);
const publicationError = state => state.getIn(['publicationDetails', 'publication', 'error']);
const concepts = state => state.getIn(['publicationDetails', 'publication', 'data', 'concepts']);
const conceptsSorting = state => state.getIn(['publicationDetails', 'publication', 'sorting']);
const conceptsPage = state => state.getIn(['publicationDetails', 'publication', 'page']);
const conceptsTotalPages = state => state.getIn(['publicationDetails', 'publication', 'totalPages']);
const similarPublications = state => state.getIn(['publicationDetails', 'publication', 'data', 'similarPublications']);
const sliderValue = state => state.getIn(['publicationDetails', 'publication', 'sliderValue']);
const ciations = state => state.getIn(['publicationDetails', 'citations', 'data']);
const ciationsLoading = state => state.getIn(['publicationDetails', 'citations', 'loading']);
const ciationsError = state => state.getIn(['publicationDetails', 'citations', 'error']);
const chapters = state => state.getIn(['publicationDetails', 'chapters']);
const semanticCategories = state => state.getIn(['publicationDetails', 'publication', 'semanticCategories']);
const highlights = state => state.getIn(['publicationDetails', 'publication', 'highlights']);
const prevHighlights = state => state.getIn(['publicationDetails', 'publication', 'prevHighlights']);
const allSelectedHighlights = state => state.getIn(['publicationDetails', 'publication', 'allSelectedHighlights']);
const searchTermsHighlighted = state => state.getIn(['publicationDetails', 'publication', 'searchTermsHighlighted']);

export const getPublicationDetailsDataSelector = createSelector(
  publication,
  data => data && data.toJS()
);

export const getPubmedIdSelector = createSelector(
  pubmedId,
  data => data
);

export const getPublicationDetailsLoadingSelector = createSelector(
  publicationLoading,
  data => data
);

export const getPublicationDetailsErrorSelector = createSelector(
  publicationError,
  data => data
);

export const getPublicationCiationsDataSelector = createSelector(
  ciations,
  data => data && data.toJS()
);

export const getPublicationCitationsLoadingSelector = createSelector(
  ciationsLoading,
  data => data
);

export const getPublicationCitationsErrorSelector = createSelector(
  ciationsError,
  data => data
);

export const getPublicationDetailsConceptsBaseSelector = createSelector(
  concepts,
  conceptsSorting,
  conceptsPage,
  conceptsTotalPages,
  (data, sorting, page, totalPages) => {
    if (!data) return [];
    let newTotalPages = totalPages;
    let sortData = sort(data, sorting.toJS());
    sortData = sortData.toJS();
    newTotalPages = Math.ceil(sortData.length / CONCEPTS_PAGE_SIZE);
    const newData = sortData && filterPaginatedData(sortData, page, CONCEPTS_PAGE_SIZE);
    return {
      page,
      concepts: sortData,
      conceptsPerPage: newData,
      totalPages: newTotalPages,
    };
  }
);

export const getSliderValueSelector = createSelector(
  sliderValue,
  data => data
);

export const getFilteredConceptsSelector = createSelector(
  concepts,
  sliderValue,
  (data, filter) => {
    if (!data) return [];
    const filteredData = data.toJS().filter(concept => concept.scoreCFIDF >= filter);
    return filteredData;
  }
);

export const getPublicationDetailsChaptersSelector = createSelector(
  chapters,
  data => data && data.toJS()
);

export const getPublicationIdSelector = createSelector(
  publicationId,
  data => data
);

export const getSimilarPublications = createSelector(
  similarPublications,
  data => data && data.toJS()
);

export const getConceptsSortingSelector = createSelector(
  conceptsSorting,
  data => data && data.toJS()
);


export const getSemanticCategories = createSelector(
  semanticCategories,
  (data) => {
    if (!data) return [];
    return data && Object.values(data.toJS());
  }
);

export const getHighlightsSelector = createSelector(
  highlights,
  getSemanticCategories,
  (_data, sem) => {
    if (!_data || !sem) return {};
    const data = _data && _data.toJS();
    let checkedData = {};
    const dataValues = Object.values(data);
    const dataKeys = Object.keys(data);
    const checkedSem = sem.filter(category => category.checked).map(category => category.category);
    dataValues.forEach((value, idx) => {
      checkedData = {
        ...checkedData,
        [dataKeys[idx]]: Object.keys(value)
          .filter(key => checkedSem.includes(key))
          // eslint-disable-next-line no-return-assign,no-sequences
          .reduce((res, key) => (res[key] = value[key], res), {}),
      };
    });
    return checkedData;
  }
);

export const getPrevHighlightsSelector = createSelector(
  prevHighlights,
  data => data && data.toJS()
);

export const getAllSelectedHighlightsSelector = createSelector(
  allSelectedHighlights,
  data => data
);

export const getSearchTermsHighlightedSelector = createSelector(
  searchTermsHighlighted,
  data => data
);
