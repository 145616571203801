import {
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';

import {
  fetchTaxonomiesFailureAction,
  fetchTaxonomiesRequestAction,
  fetchTaxonomiesSuccessAction,
} from './reducer';

function* saga() {
  try {
    const requestData = {
      post: {
        'semTypes': [
          '28',
        ],
      },
    };

    const response = yield call(Api.getTaxonomies, requestData);
    const taxonomies = response.data;
    yield put(fetchTaxonomiesSuccessAction(taxonomies));
  } catch (e) {
    yield put(fetchTaxonomiesFailureAction(e.message));
  }
}

function* fetchTaxonomiesSaga() {
  try {
    yield all([
      takeLatest(fetchTaxonomiesRequestAction, saga),
    ]);
  } catch (error) {
    console.log(error);
  }
}

export default fetchTaxonomiesSaga;
