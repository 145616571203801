import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getGeneDetailsLiteratureSearchDataAction = createAction('@@gene-details/GET_LITERATURE_SEARCH_DATA');
export const setGeneDetailsLiteratureSearchDataAction = createAction('@@gene-details/SET_LITERATURE_SEARCH_DATA');
export const throwGeneDetailsLiteratureSearchErrorAction = createAction('@@gene-details/LITERATURE_SEARCH_THROW_ERROR');

const initialState = fromJS({
  data: {
    publications: {
      content: [],
    },
  },
  error: null,
  loading: true,
  graphData: {
    content: [],
    loading: true,
    error: null,
  },
});

const geneDetailsProteinLocationChapter = handleActions(
  {
    [getGeneDetailsLiteratureSearchDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null)
        .updateIn(['data', 'publications', 'content'], () => []),
    [setGeneDetailsLiteratureSearchDataAction]: (state, { payload }) =>
      state
        .update('loading', () => false)
        .update('error', () => null)
        .updateIn(['data', 'publications', 'content'], () => payload.publications.content),
    [throwGeneDetailsLiteratureSearchErrorAction]: (state, { payload }) =>
      state
        .update('loading', () => true)
        .update('error', () => fromJS(payload))
        .updateIn(['data', 'publications', 'content'], () => []),
  },
  initialState
);

export default geneDetailsProteinLocationChapter;
