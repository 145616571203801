import { createSelector } from 'reselect';

import { filterPaginatedData, sort } from '../../Utils/Utils';

import { PAGE_SIZE } from './constants';

const availableSets = state => state.getIn(['availableSets', 'sets']);
const availableSetsPage = state => state.getIn(['availableSets', 'page']);
const availableSetsTotalPages = state => state.getIn(['availableSets', 'totalPages']);
const availableSetsLoading = state => state.getIn(['availableSets', 'loading']);
const availableSetsError = state => state.getIn(['availableSets', 'error']);
const availableSetsSorting = state => state.getIn(['availableSets', 'sorting']);
const availableSetsFilter = state => state.getIn(['availableSets', 'filter']);
const setsListActiveProjectId = state => state.getIn(['availableSets', 'activeProjectId']);

export const getAvailableSetsDataSelector = createSelector(
  availableSets,
  availableSetsPage,
  availableSetsTotalPages,
  availableSetsSorting,
  availableSetsFilter,
  (data, page, totalPages, sorting, filter) => {
    const sortedData = sort(data, sorting.toJS());
    const sets = sortedData && sortedData.toJS();

    let filteredSets = sets;
    let newTotalPages = totalPages;

    if (filter) {
      const filterValueLow = filter.toLowerCase();
      filteredSets = sets.filter(item => (
        item.name.toLowerCase().includes(filterValueLow)
      ));
      newTotalPages = Math.ceil(filteredSets.length / PAGE_SIZE);
    }

    const newData = filteredSets && filterPaginatedData(filteredSets, page, PAGE_SIZE);

    return {
      sets: newData,
      page,
      totalPages: newTotalPages,
    };
  }
);

export const getAvailableSetsListSelector = createSelector(
  availableSets,
  data => data && data.toJS()
);

export const getAvailableSetsLoadingSelector = createSelector(
  availableSetsLoading,
  data => data
);

export const getAvailableSetsErrorSelector = createSelector(
  availableSetsError,
  data => data
);

export const getAvailableSetsFilterSelector = createSelector(
  availableSetsFilter,
  data => data
);

export const getAvailableSetsSortingSelector = createSelector(
  availableSetsSorting,
  data => data && data.toJS()
);

export const getActiveProjectIdSelector = createSelector(
  setsListActiveProjectId,
  data => data
);
