import { createSelector } from 'reselect';

import { CHANGE_PASSWORD_FORM } from './constants';

const _changePasswordForm = state => state.getIn(['form', CHANGE_PASSWORD_FORM]);

export const changePasswordForm = createSelector(
  _changePasswordForm,
  (form) => {
    const newPassword = form.getIn(['values', 'newPassword']);
    const oldPassword = form.getIn(['values', 'oldPassword']);
    return {
      oldPassword,
      newPassword,
    };
  }
);

