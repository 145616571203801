import { createSelector } from 'reselect';

import { sort } from '../../../Utils/Utils';

const sorting = state => state.getIn(['analysisResultRanking', 'sorting']);
const content = state => state.getIn(['analysisResultRanking', 'result', 'content']);
const totalPages = state => state.getIn(['analysisResultRanking', 'result', 'totalPages']);
const totalElements = state => state.getIn(['analysisResultRanking', 'result', 'totalElements']);
const loading = state => state.getIn(['analysisResultRanking', 'loading']);
const pageNumber = state => state.getIn(['analysisResultRanking', 'result', 'number']);
const error = state => state.getIn(['analysisResultRanking', 'error']);
const effectOperation = state => state.getIn(['analysisResultRanking', 'effectOperation']);

export const currentPage = createSelector(
  content,
  sorting,
  (_content, _sorting) => {
    const sorted = sort(_content, _sorting.toJS());
    return sorted.toJS();
  }
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getTotalItems = createSelector(
  totalElements,
  data => data
);

export const getSelectedItems = createSelector(
  content,
  data => data.filter(item => (
    item.get('selected')
  )).toJS()
);

export const getLoadingKey = createSelector(
  loading,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getEffectOperation = createSelector(
  effectOperation,
  data => data
);
