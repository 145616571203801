import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
};

const TermNotFoundSection = (props) => {
  const { recommendations } = props;

  return (
    <div className="search-page__block">
      <div className="term-not-found-title">
        Term not found
      </div>
      <div className="term-not-found-phrases">
        {
          recommendations.slice(0, 3).map((item, index) => (
            <div
              key={index}
              className="term-not-found-phrases"
            >
              <span>{item.infoMessage} {item.recommendationPhrase}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

TermNotFoundSection.propTypes = propTypes;

export default React.memo(TermNotFoundSection);
