import { createSelector } from 'reselect';

const backgroundCells = state => state.getIn(['backgroundSet', 'backgroundCells']);

export const getBackgroundCellsSelector = createSelector(
  backgroundCells,
  data => data && data.toJS()
);

const backgroundCellsLoader = state => state.getIn(['backgroundSet', 'backgroundCellsLoader']);

export const getBackgroundCellsLoaderSelector = createSelector(
  backgroundCellsLoader,
  data => data
);

const backgroundCellsFilterOption = state => state.getIn(['backgroundSet', 'backgroundCellsFilterOption']);

export const getBackgroundCellsFilterOptionSelector = createSelector(
  backgroundCellsFilterOption,
  data => data
);

const backgroundSetConfig = state => state.getIn(['backgroundSet', 'backgroundSetConfiguration']);

export const getBackgroundSetConfigSelector = createSelector(
  backgroundSetConfig,
  data => data.toJS()
);

const backgroundSetOptions = state => state.getIn(['backgroundSet', 'backgroundSetOptions']);

export const getBackgroundSetOptionsSelector = createSelector(
  backgroundSetOptions,
  data => data.toJS()
);

const backgroundSetFilterOption = state => state.getIn(['backgroundSet', 'backgroundSetFilterOption']);

export const getBackgroundSetFilterOptionSelector = createSelector(
  backgroundSetFilterOption,
  data => data
);

const activeProjectId = state => state.getIn(['backgroundSet', 'backgroundSetActiveProjectId']);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);
