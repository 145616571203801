import React from 'react';

// Styles
import './styles.scss';

const VennDiagramIcon = () => (
  <i className="venn-diagram-icon" />
);

export default VennDiagramIcon;
