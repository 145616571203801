import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { geneDiseaseSetChapterAction, loadNextChapterAction } from '../GeneDiseasePage/actions';

import {
  GENE_DISEASE_ANIMAL_MODEL_INIT_REQUESTED,
  GENE_DISEASE_ANIMAL_MODEL_INIT_SUCCEEDED,
  GENE_DISEASE_ANIMAL_MODEL_INIT_FAILED,
} from './constants';


function* sagaAnimalModel(action) {
  try {
    const post = action.data;
    const response = yield call(Api.geneDiseaseAnimalModel, { post });
    yield put({ type: GENE_DISEASE_ANIMAL_MODEL_INIT_SUCCEEDED, data: response.data });
    const {
      associatedPublications,
      chords,
      data,
      fullSetABC,
      indirectPublications,
      publications,
    } = response.data;

    if ((associatedPublications && associatedPublications.length)
      || (chords && chords.length)
      || (data && data.length)
      || (fullSetABC && fullSetABC.length)
      || (indirectPublications && indirectPublications.length)
      || (publications && publications.length)
    ) {
      yield put(geneDiseaseSetChapterAction('animal-model'));
    }
    yield put(loadNextChapterAction('phenotypes'));
  } catch (e) {
    yield put({ type: GENE_DISEASE_ANIMAL_MODEL_INIT_FAILED, message: e.message });
    yield put(loadNextChapterAction('phenotypes'));
  }
}

function* geneDiseaseAnimalModel() {
  yield takeLatest(GENE_DISEASE_ANIMAL_MODEL_INIT_REQUESTED, sagaAnimalModel);
}

export default geneDiseaseAnimalModel;
