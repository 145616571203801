import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmationDialog from '../../../../Modals/ConfirmationDialog/ConfirmationDialog';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onConfirm: PropTypes.func,
  relationMapsToDelete: PropTypes.number,
};

const DeleteRelationMapsModal = (props) => {
  const {
    isOpen,
    closeCb,
    onConfirm,
    relationMapsToDelete,
  } = props;

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      onCancel={closeCb}
      isOpen={isOpen}
      closeCb={closeCb}
      text={
        relationMapsToDelete === 1 ?
          'Are you sure you want to remove relation map?' :
          `Are you sure you want to remove ${relationMapsToDelete} relation maps?`
      }
    />
  );
};

DeleteRelationMapsModal.propTypes = propTypes;

export default React.memo(DeleteRelationMapsModal);
