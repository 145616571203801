import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormInput.scss';

const propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  additionalTitle: PropTypes.string,
  mandatory: PropTypes.bool,
  customClassName: PropTypes.string,
  label: PropTypes.string,
  onChangeCallback: PropTypes.func,
};

const FormInput = (props) => {
  const {
    input: {
      onChange,
      value,
      ...restInput
    },
    meta: {
      error,
      touched,
    },
    label,
    additionalTitle,
    mandatory,
    customClassName,
    onChangeCallback,
    ...restCustom
  } = props;

  // console.log(props);

  const handleOnChange = useCallback((e) => {
    onChange(e.target.value);
    if (onChangeCallback) {
      onChangeCallback(e);
    }
  }, [onChange, onChangeCallback]);

  return (
    <>
      {label && <span className="input__label">{label}</span>}
      <input
        className={classNames('input input-text', customClassName, {
          'input--mandatory': mandatory && !value,
        })}
        onChange={handleOnChange}
        value={value}
        {... restInput}
        {... restCustom}
      />
      <div className={classNames('input-text-bottom-line-wrapper', {
        'with-error': touched && error && additionalTitle,
        'without-additional-text': !additionalTitle,
      })}
      >
        {
          touched && error &&
          <div className="error-text">
            <span className="error-text__text">{error}</span>
          </div>
        }
        {
          additionalTitle &&
          <div>{additionalTitle}</div>
        }
      </div>
    </>
  );
};

FormInput.propTypes = propTypes;

export default React.memo(FormInput);