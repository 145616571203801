import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { defaultProjects } from '../../../Projects/ProjectsTabs/constants';

export const getPublicationsManagementDataAction = createAction('@@publications/MANAGEMENT_GET_DATA');
export const setPublicationsManagementDataAction = createAction('@@publications/MANAGEMENT_SET_DATA');
export const throwPublicationsManagementErrorAction = createAction('@@publications/MANAGEMENT_THROW_ERROR');
export const clearPublicationsManagementAction = createAction('@@publications/MANAGEMENT_CLEAR');
export const updatePublicationsManagementDataAction = createAction('@@publications/UPDATE_DATA');
export const updateSelectedPublicationsIdsAction = createAction('@@publications/UPDATE_SELECTED_IDS_DATA');
export const updateSelectedPubmedIdsAction = createAction('@@publications/UPDATE_SELECTED_PUBMED_IDS_DATA');
export const deletePublicationsManagementItemsAction = createAction('@@publications/DELETE_PUBLICATIONS');
export const getAllPublicationsIdsByProjectAction = createAction('@@publications/GET_ALL_PUBLICATIONS_IDS');
export const getPublicationsDataForExportAction = createAction('@@publications/GET_DATA_FOR_EXPORT');

export const clearActiveProjectIdAction = createAction('@@publications/CLEAR__ACTIVE_PROJECT_ID');
export const setActiveProjectIdAction = createAction('@@publications/SET_ACTIVE_PROJECT_ID');

const initialState = fromJS({
  data: {
    content: [],
  },
  loading: false,
  error: '',
  selectedPublicationsIds: [],
  selectedPubmedIds: [],
  activeProjectId: defaultProjects[0].id,
});

const reducer = handleActions(
  {
    [getPublicationsManagementDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setPublicationsManagementDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null),
    [updatePublicationsManagementDataAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'content'], () => fromJS(payload)),
    [updateSelectedPublicationsIdsAction]: (state, { payload }) =>
      state
        .update('selectedPublicationsIds', () => fromJS(payload)),
    [updateSelectedPubmedIdsAction]: (state, { payload }) =>
      state
        .update('selectedPubmedIds', () => fromJS(payload)),
    [throwPublicationsManagementErrorAction]: (state, { payload }) =>
      state
        .update('publications', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [setActiveProjectIdAction]: (state, { payload }) =>
      state
        .update('activeProjectId', () => payload)
        .update('selectedPubmedIds', () => initialState.get('selectedPubmedIds'))
        .update('selectedPublicationsIds', () => initialState.get('selectedPublicationsIds')),
    [clearActiveProjectIdAction]: state =>
      state
        .update('activeProjectId', () => initialState.get('activeProjectId')),
    [clearPublicationsManagementAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
