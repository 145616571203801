import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import Spinner from '../../common/Spinner/Spinner';
import FormInput from '../../common/FormElements/FormInput';
// Constants
import { ACTIVATE_CREATED_USER_FORM } from './constants';

const propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

const ActivateCreatedUserForm = (props) => {
  const {
    handleSubmit,
    submitting,
    pristine,
    invalid,
  } = props;

  return (
    <form className="form-activate-password" onSubmit={handleSubmit}>
      <div className="row">
        <Field
          name="password"
          placeholder="Input password"
          component={FormInput}
          type="password"
        />
        <Field
          name="passwordRepeat"
          placeholder="Repeat password"
          component={FormInput}
          type="password"
        />
      </div>
      <div className="row text-center">
        <button className="button button-primary" disabled={pristine || submitting || invalid}>
          Submit
        </button>
        <Spinner isLoading={submitting} />
      </div>
    </form>
  );
};

ActivateCreatedUserForm.propTypes = propTypes;

function validateForm(values) {
  const errors = {};
  if (!values.get('password') || values.get('password').trim() === '') {
    errors.password = 'Enter password';
  }
  if (!values.get('passwordRepeat') || values.get('passwordRepeat').trim() === '') {
    errors.passwordRepeat = 'Enter password';
  }
  if (values.get('password') &&
    values.get('passwordRepeat') &&
    (values.get('password').trim() !== values.get('passwordRepeat').trim())) {
    errors.passwordRepeat = 'Password is not repeated';
  }

  return errors;
}

export default reduxForm({
  form: ACTIVATE_CREATED_USER_FORM,
  fields: ['password', 'passwordRepeat'],
  validate: validateForm,
})(ActivateCreatedUserForm);
