import {
  Map,
  fromJS,
} from 'immutable';
import {
  createAction,
  handleActions,
} from 'redux-actions';

import Storage from './Storage/Storage';

const PREFIX = 'AUTH';

// Actions for logging in
export const userLoginRequestAction = createAction(`${PREFIX}/USER_LOGIN_REQUEST`);
export const userLoginSuccessAction = createAction(`${PREFIX}/USER_LOGIN_SUCCESS`);
export const userLoginFailureAction = createAction(`${PREFIX}/USER_LOGIN_FAILURE`);

// Actions for logging out
export const userLogoutRequestAction = createAction(`${PREFIX}/USER_LOGOUT_REQUEST`);
export const userLogoutFailureAction = createAction(`${PREFIX}/USER_LOGOUT_FAILURE`);
export const userLogoutSuccessAction = createAction(`${PREFIX}/USER_LOGOUT_SUCCESS`);

export const tokenExpiredAction = createAction(`${PREFIX}/USER_TOKEN_EXPIRED`);

const persistUser = () => {
  let user = new Map();
  const storageUser = Storage.getValue('user');
  if (storageUser) {
    user = user.merge({ user: storageUser });
  }
  return user;
};

const auth = handleActions({
  [userLoginRequestAction]: state =>
    state
      .set('error', null),
  [userLogoutRequestAction]: state =>
    state
      .set('user', null)
      .set('error', null),
  [userLogoutFailureAction]: (state, { payload }) =>
    state
      .set('error', payload),
  [userLoginSuccessAction]: (state, { payload }) =>
    state
      .set('user', fromJS(payload))
      .set('error', null),
  [userLoginFailureAction]: (state, { payload }) =>
    state
      .set('user', null)
      .set('error', payload),
  [userLogoutSuccessAction]: state =>
    state
      .set('user', null)
      .set('error', null),
  [userLogoutFailureAction]: (state, { payload }) =>
    state
      .set('error', payload),
}, persistUser());

export default auth;
