import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { PAGE_SIZE } from '../../constants';

export const getDisordersDataAction = createAction('GET_DISORDERS_DATA');
export const setDisordersDataAction = createAction('SET_DISORDERS_DATA');
export const toggleDisordersLoaderAction = createAction('TOGGLE_DISORDERS_LOADER');
export const disordersIsFailedAction = createAction('DISORDERS_IS_FAILED');
export const cancerAnimalModelsChangePageAction = createAction('CANCER_ANIMAL_MODELS_CHANGE_PAGE');
export const changeAnimalModelsForCancerSortingAction = createAction('CHANGE_ANIMAL_MODELS_CANCER_SORTING');
export const changeAnimalModelsForDisorderSortingAction = createAction('CHANGE_ANIMAL_MODELS_DISORDER_SORTING');
export const cancerGeneticVariationsChangePageAction = createAction('CANCER_GENETIC_VARIATION_CHANGE_PAGE');
export const cancerDiseaseAnnotationChangePageAction = createAction('CANCER_DISEASE_ANNOTATION_CHANGE_PAGE');
export const disorderAnimalModelsChangePageAction = createAction('DISORDER_ANIMAL_MODELS_CHANGE_PAGE');
export const disorderGeneticVariationsChangePageAction = createAction('DISORDER_GENETIC_VARIATION_CHANGE_PAGE');
export const disorderDiseaseAnnotationChangePageAction = createAction('DISORDER_DISEASE_ANNOTATION_CHANGE_PAGE');

const initialState = fromJS({
  cancer: {
    diagram: {
      data: [],
      chords: [],
    },
    animalModels: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
      sorting: { sortBy: 'predictionType', sortDirection: 'DESC' },
    },
    geneticVariations: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
    },
    diseaseAnnotation: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
    },
  },
  disorder: {
    diagram: {
      data: [],
      chords: [],
    },
    animalModels: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
      sorting: { sortBy: 'predictionType', sortDirection: 'DESC' },
    },
    geneticVariations: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
    },
    diseaseAnnotation: {
      data: [],
      totalPages: 0,
      pageNumber: 0,
    },
  },
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [setDisordersDataAction]: (state, { payload: { cancers, disorders } }) => (
      state
        .updateIn(['cancer', 'diagram', 'data'],
          () => cancers.data.sort((i1, i2) => i1.gene.color.localeCompare(i2.gene.color)))
        .updateIn(['cancer', 'diagram', 'chords'],
          () => cancers.chords)
        .updateIn(['cancer', 'animalModels', 'data'],
          () => cancers.animalModels)
        .updateIn(['cancer', 'animalModels', 'totalPages'],
          () => Math.ceil((cancers.animalModels || []).length / PAGE_SIZE))
        .updateIn(['cancer', 'geneticVariations', 'data'],
          () => cancers.geneticVariations)
        .updateIn(['cancer', 'geneticVariations', 'totalPages'],
          () => Math.ceil((cancers.geneticVariations || []).length / PAGE_SIZE))
        .updateIn(['cancer', 'diseaseAnnotation', 'data'],
          () => cancers.diseaseAnnotation)
        .updateIn(['cancer', 'diseaseAnnotation', 'totalPages'],
          () => Math.ceil((cancers.diseaseAnnotation || []).length / PAGE_SIZE))
        .updateIn(['disorder', 'diagram', 'data'],
          () => disorders.data.sort((i1, i2) => i1.gene.color.localeCompare(i2.gene.color)))
        .updateIn(['disorder', 'diagram', 'chords'],
          () => disorders.chords)
        .updateIn(['disorder', 'animalModels', 'data'],
          () => disorders.animalModels)
        .updateIn(['disorder', 'animalModels', 'totalPages'],
          () => Math.ceil((disorders.animalModels || []).length / PAGE_SIZE))
        .updateIn(['disorder', 'geneticVariations', 'data'],
          () => disorders.geneticVariations)
        .updateIn(['disorder', 'geneticVariations', 'totalPages'],
          () => Math.ceil((disorders.geneticVariations || []).length / PAGE_SIZE))
        .updateIn(['disorder', 'diseaseAnnotation', 'data'],
          () => disorders.diseaseAnnotation)
        .updateIn(['disorder', 'diseaseAnnotation', 'totalPages'],
          () => Math.ceil((disorders.diseaseAnnotation || []).length / PAGE_SIZE))
        .update('error', () => null)
    ),
    [toggleDisordersLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [disordersIsFailedAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [cancerAnimalModelsChangePageAction]: (state, { payload }) =>
      state.updateIn(['cancer', 'animalModels', 'pageNumber'], () => payload),
    [cancerGeneticVariationsChangePageAction]: (state, { payload }) =>
      state.updateIn(['cancer', 'geneticVariations', 'pageNumber'], () => payload),
    [cancerDiseaseAnnotationChangePageAction]: (state, { payload }) =>
      state.updateIn(['cancer', 'diseaseAnnotation', 'pageNumber'], () => payload),
    [disorderAnimalModelsChangePageAction]: (state, { payload }) =>
      state.updateIn(['disorder', 'animalModels', 'pageNumber'], () => payload),
    [disorderGeneticVariationsChangePageAction]: (state, { payload }) =>
      state.updateIn(['disorder', 'geneticVariations', 'pageNumber'], () => payload),
    [disorderDiseaseAnnotationChangePageAction]: (state, { payload }) =>
      state.updateIn(['disorder', 'diseaseAnnotation', 'pageNumber'], () => payload),
    [changeAnimalModelsForDisorderSortingAction]: (state, { payload }) =>
      state.updateIn(['disorder', 'animalModels', 'sorting'], () => fromJS(payload)),
    [changeAnimalModelsForCancerSortingAction]: (state, { payload }) =>
      state.updateIn(['cancer', 'animalModels', 'sorting'], () => fromJS(payload)),
  },
  initialState
);

export default reducer;
