import { createSelector } from 'reselect';

const antibodiesData = state => state.getIn(['geneDetailsAntibodiesChapterReducer', 'data']);
const loading = state => state.getIn(['geneDetailsAntibodiesChapterReducer', 'loading']);
const error = state => state.getIn(['geneDetailsAntibodiesChapterReducer', 'error']);

export const getGeneDetailsAntibodiesDataSelector = createSelector(
  antibodiesData,
  data => data && data.toJS()
);

export const getGeneDetailsAntibodiesLoadingSelector = createSelector(
  loading,
  data => data
);

export const getGeneDetailsAntibodiesErrorSelector = createSelector(
  error,
  data => data
);
