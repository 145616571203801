import { createSelector } from 'reselect';

const cellTypeClusterData = state => state.getIn(['cellTypeClusterModalReducer', 'data']);
const cellTypeClusterDataCurrentPage = state => state.getIn(['cellTypeClusterModalReducer', 'currentPage']);
const cellTypeClusterDataTotalPages = state => state.getIn(['cellTypeClusterModalReducer', 'totalPages']);
const cellTypeClusterDataTotalElements = state => state.getIn(['cellTypeClusterModalReducer', 'totalElements']);
const loading = state => state.getIn(['cellTypeClusterModalReducer', 'loading']);
const exportLoading = state => state.getIn(['cellTypeClusterModalReducer', 'exportLoading']);
const error = state => state.getIn(['cellTypeClusterModalReducer', 'error']);
const isModalOpen = state => state.getIn(['cellTypeClusterModalReducer', 'isModalOpen']);
const selectedCluster = state => state.getIn(['cellTypeClusterModalReducer', 'selectedCluster']);

export const getCellTypeClusterDataSelector = createSelector(
  cellTypeClusterData,
  data => data && data.toJS()
);

export const getCellTypeClusterDataCurrentPageSelector = createSelector(
  cellTypeClusterDataCurrentPage,
  data => data
);

export const getCellTypeClusterDataTotalPagesSelector = createSelector(
  cellTypeClusterDataTotalPages,
  data => data
);

export const getCellTypeClusterDataTotalElementsSelector = createSelector(
  cellTypeClusterDataTotalElements,
  data => data
);

export const getCellTypeClusterLoadingSelector = createSelector(
  loading,
  data => data
);

export const getCellTypeClusterExportLoadingSelector = createSelector(
  exportLoading,
  data => data
);
export const getCellTypeClusterErrorSelector = createSelector(
  error,
  data => data
);

export const getCellTypeClusterModalOpenSelector = createSelector(
  isModalOpen,
  data => data
);

export const getSelectedClusterSelector = createSelector(
  selectedCluster,
  data => data
);
