import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Store
import * as ACTIONS from '../reducer';
import * as SELECTORS from '../selectors';
// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import ViolinChart from '../../../../graphics/ViolinChart/ViolinChart';
import Loader from '../../../../common/Loader/Loader';
import SamplesChart from '../../../../GeneDetails/GeneExpressionChapter/Charts/SamplesChart/SamplesChart';
// Utils
import { getCsvName } from '../../../../GeneDetails/GeneExpressionChapter/utils';
// Constants
import { selectedTissuesColors } from '../constants';
import { scaleOptions } from '../../../../../constantsCommon';
import {
  sortingOptions,
} from '../../../../GeneDetails/GeneExpressionChapter/constants';

const propTypes = {
  geneId: PropTypes.string,
  getChartsData: PropTypes.func,
  resetChartsData: PropTypes.func,
  gtexExpressionData: PropTypes.instanceOf(Array),
  tcgaExpressionData: PropTypes.instanceOf(Array),
  selectedTissuesExpressionData: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  error: PropTypes.string,
  geneName: PropTypes.string,
  tumorLabel: PropTypes.string,
  setSelectedSortingOption: PropTypes.func,
  tcgaExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  gtexExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
};

class SingleTargetForCancerCharts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTransform: {
        GTEX: 'linear',
        TCGA: 'linear',
        BEAT_AML: 'linear',
        SELECTED_TISSUES: 'linear',
      },
      samplesPopup: {
        isModalOpen: false,
        tissueGroupName: '',
        tissueCellLineName: '',
        tissuesGroup: '',
        conceptId: '',
        type: '',
      },
    };
  }

  componentDidMount() {
    const {
      geneId,
      getChartsData,
      tumorLabel,
    } = this.props;
    getChartsData({ id: geneId, label: tumorLabel });
  }

  componentWillUnmount() {
    const { resetChartsData } = this.props;
    resetChartsData();
  }

  changeViolinTransform = (e, key) => {
    this.setState({
      dataTransform: {
        ...this.state.dataTransform,
        [key]: e.target.value,
      },
    });
  };

  switchOpeningCellLineModal = (e, concept, tissuesGroup, type) => {
    const { isModalOpen } = this.state.samplesPopup;

    if (!isModalOpen) {
      this.setState({
        samplesPopup: {
          ...this.state.samplesPopup,
          isModalOpen: true,
          tissueCellLineName: e,
          tissueGroupName: concept.group,
          conceptId: concept.concept.id,
          tissuesGroup,
          type,
        },
      });
    } else {
      this.setState({
        samplesPopup: {
          ...this.state.samplesPopup,
          isModalOpen: false,
          tissueGroupName: '',
          conceptId: '',
          tissueCellLineName: '',
          tissuesGroup: '',
          type: '',
        },
      });
    }
  };

  render() {
    const {
      loading,
      geneId,
      error,
      geneName,
      tumorLabel,
      gtexExpressionData,
      tcgaExpressionData,
      selectedTissuesExpressionData,
      setSelectedSortingOption,
      gtexExpressionSelectedSortingOption,
      tcgaExpressionSelectedSortingOption,
    } = this.props;

    const {
      samplesPopup,
      dataTransform,
    } = this.state;

    const sortOptionsWithoutPairedOption = sortingOptions.slice(0, -1);

    return (
      <div className="target-candidates-charts">
        {
          !loading &&
          <>
            {
              selectedTissuesExpressionData && selectedTissuesExpressionData.length > 0 ?
                <div className="target-candidates-chart">
                  <h3 className="workflows__title workflows__title_center title3">
                    RNA Expression (TPM) of selected tissues
                  </h3>
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={`RNA Expression (TPM) of selected tissues for ${geneName}`}
                      chartName="RNA_Expression_(TPM)_of_selected_tissues"
                      chartWrapId="workflows-selected-tissues-chart"
                      type="SELECTED_TISSUES"
                      geneName={geneName}
                      chartData={selectedTissuesExpressionData}
                      isNeededTransform={true}
                      isHiddenExport={false}
                      changeViolinTransform={e => this.changeViolinTransform(e, 'SELECTED_TISSUES')}
                      violinPlotTransform={dataTransform.SELECTED_TISSUES}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      showLegend={true}
                      settings={{
                        width: selectedTissuesExpressionData.length > 50 ? selectedTissuesExpressionData.length * 30 : 1800,
                        height: 1350,
                      }}
                      legendsCustoms={{
                        params: [
                          { name: 'Normal Tissues' },
                          { name: 'Sensitive Tissues' },
                          { name: 'Tumor Tissues' },
                        ],
                        colors: [
                          selectedTissuesColors.NORMAL_TISSUES[0],
                          selectedTissuesColors.SENSITIVE_TISSUES[0],
                          selectedTissuesColors.TUMOR_TISSUES[0],
                        ],
                      }}
                    />
                  </div>
                </div> :
                <div>There is no data to display</div>
            }
            {
              gtexExpressionData && gtexExpressionData.length > 0 ?
                <div className="target-candidates-chart">
                  <h3 className="workflows__title workflows__title_center title3">
                    RNA (TPM) from GTEx
                  </h3>
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={`RNAseq from GTEx for ${geneName}`}
                      chartName="RNAseq_from_GTEx"
                      chartWrapId="workflows-gtex-chart"
                      type="GTEX"
                      geneName={geneName}
                      chartData={gtexExpressionData}
                      isNeededTransform={true}
                      isHiddenExport={false}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      changeViolinTransform={e => this.changeViolinTransform(e, 'GTEX')}
                      violinPlotTransform={dataTransform.GTEX}
                      showExpressionSortSelector={true}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={gtexExpressionSelectedSortingOption}
                      sortingOptions={sortOptionsWithoutPairedOption}
                    />
                  </div>
                </div> :
                <div>There is no data to display</div>
            }
            {
              tcgaExpressionData && tcgaExpressionData.length > 0 ?
                <div className="target-candidates-chart">
                  <h3 className="workflows__title workflows__title_center title3">
                    RNA expression (TPM) in tumor tissue from TCGA
                  </h3>
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={`RNAseq from TCGA for ${geneName}`}
                      chartName="RNAseq_from_TCGA"
                      chartWrapId="workflows-tcga-chart"
                      type="TCGA"
                      geneName={geneName}
                      chartData={tcgaExpressionData}
                      isNeededTransform={true}
                      isHiddenExport={false}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      changeViolinTransform={e => this.changeViolinTransform(e, 'TCGA')}
                      violinPlotTransform={dataTransform.TCGA}
                      showLegend={true}
                      showExpressionSortSelector={true}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={tcgaExpressionSelectedSortingOption}
                      sortingOptions={sortingOptions}
                      legendsCustoms={{
                        params: [
                          { name: 'Normal Tissues' },
                          { name: 'Tumor Tissues' },
                        ],
                        colors: [
                          selectedTissuesColors.NORMAL_TISSUES[0],
                          selectedTissuesColors.TUMOR_TISSUES[0],
                        ],
                      }}
                    />
                  </div>
                </div> :
                <div>There is no data to display</div>
            }
            {
              error &&
              <div className="text-center error-text">
                Sorry, error occurred.
                <br />
                { error }
              </div>
            }
            {
              samplesPopup.isModalOpen &&
              <ModalComponent
                isOpen={samplesPopup.isModalOpen}
                modalClassName="cell-line-chart-modal"
                closeCb={() => this.switchOpeningCellLineModal()}
              >
                <SamplesChart
                  geneId={geneId}
                  geneName={geneName}
                  tumorLabel={tumorLabel}
                  type={samplesPopup.tissuesGroup}
                  relatedId={samplesPopup.conceptId}
                  conceptName={samplesPopup.conceptName}
                  tissueGroupName={samplesPopup.tissueGroupName}
                  transform={dataTransform[samplesPopup.type]}
                  scale={scaleOptions[dataTransform[samplesPopup.type]].value}
                  csvName={getCsvName(samplesPopup.type, geneName)}
                  tissueCellLineName={samplesPopup.tissueCellLineName}
                  sampleMeasure={
                    {
                      label: 'TPM value',
                      key: 'measure'
                    }
                  }
                  sampleName={
                    {
                      label: 'Sample identifier',
                      key: 'sample'
                    }
                  }
                />
              </ModalComponent>
            }
          </>
        }
        <Loader isLoading={loading} />
      </div>
    );
  }
}

SingleTargetForCancerCharts.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getSingleTargetForCancerChartsSelector(state),
    loading: SELECTORS.getSingleTargetForCancerChartsLoadingSelector(state),
    error: SELECTORS.getSingleTargetForCancerChartsErrorSelector(state),
    gtexExpressionData: SELECTORS.getGtexDataInit(state),
    gtexExpressionSelectedSortingOption: SELECTORS.getGtexSelectedSortingOption(state),
    tcgaExpressionData: SELECTORS.getTcgaDataInit(state),
    tcgaExpressionSelectedSortingOption: SELECTORS.getTcgaSelectedSortingOption(state),
    selectedTissuesExpressionData: SELECTORS.getSelectedTissuesExpressionDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getChartsData(data) {
      dispatch(ACTIONS.getSingleTargetForCancerChartsAction(data));
    },
    resetChartsData() {
      dispatch(ACTIONS.resetSingleTargetForCancerChartsAction());
    },
    setSelectedSortingOption(data) {
      dispatch(ACTIONS.setSelectedSortingOptionAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleTargetForCancerCharts);
