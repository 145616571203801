import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Components
import Loader from '../../common/Loader/Loader';
import PatentTable from './PatentTable';
// Store
import { getPatentsAction } from './redux/actions';
import { loadNextChapterAction } from '../actions';
import {
  getPatents,
  getChapterError,
  getChapterLoading,
} from './redux/selectors';
// Styles
import './PatentsChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  patents: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loading: PropTypes.bool,
  getPatentsData: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
};

class PatentsChapter extends React.Component {
  state = {
    showInfoText: false,
  };

  componentDidMount() {
    const {
      gene,
      getPatentsData,
      itsNewConcept,
      loadNextChapter,
      patents,
    } = this.props;

    if (itsNewConcept || isEmpty(patents)) {
      getPatentsData({ id: gene });
    } else {
      loadNextChapter('clinical-trials');
    }
  }

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      error,
      loading,
      patents,
    } = this.props;
    const { showInfoText } = this.state;
    const parentClass = 'gene-details-section-body';

    return (
      <div className="patents">
        <div className="gene-details-section-title title2">
          <span>Patents</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              This section shows patent applications that are registered for the target. The following information is provided:
            </p>
            <ul>
              <li>The patent title. Click on the link to view the original patent application</li>
              <li>
                The number of therapeutic agents, compounds as well as antibodies, that are included in the patent.
                Click on the link to view the detailed compounds or amino acid sequences.
                If no therapeutic agents are shown it is advised to look at the patent application in more detail.
              </li>
              <li>The type of associated therapeutic agents: compound or amino acid.</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div>
            <PatentTable
              gene={gene}
              geneName={geneName}
              patents={patents}
              parentClass={parentClass}
              itsNewConcept={false}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

PatentsChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    patents: getPatents(state),
    error: getChapterError(state),
    loading: getChapterLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPatentsData(data) {
      dispatch(getPatentsAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatentsChapter);

