import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import Accordion from '../../../Accordion/Accordion';
import SemanticType from '../../../SemanticType/SemanticType';
import SemanticCategory from '../../../SemanticCategory/SemanticCategory';
// Constants
import { conceptTypesIdsEnum } from '../../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  categories: PropTypes.instanceOf(Array),
  mainCategories: PropTypes.instanceOf(Array),
  checkedSC: PropTypes.instanceOf(Array),
  checkedST: PropTypes.instanceOf(Array),
  taxonomies: PropTypes.instanceOf(Array),
  setTaxonomies: PropTypes.func,
  toggleSemanticType: PropTypes.func,
  toggleSemanticCategory: PropTypes.func,
  uploadOnlyByDefaultCategory: PropTypes.bool,
};

const UploadFileTypesSetup = (props) => {
  const {
    categories,
    mainCategories,
    checkedSC,
    checkedST,
    taxonomies,
    setTaxonomies,
    toggleSemanticType,
    toggleSemanticCategory,
    uploadOnlyByDefaultCategory,
  } = props;

  const handleToggleSemanticType = useCallback((id) => {
    if (!checkedST.includes(id)) {
      toggleSemanticType([...checkedST, id]);
      return;
    }
    toggleSemanticType(checkedST.filter(d => d !== id));
    if (id === conceptTypesIdsEnum.GENE) {
      setTaxonomies(taxonomies.map(t => ({ ...t, checked: false })));
    }
  }, [checkedST, toggleSemanticType, taxonomies, setTaxonomies]);

  const handleToggleSemanticCategory = useCallback((name) => {
    const checked = checkedSC.includes(name) ?
      checkedSC.filter(d => d !== name) :
      [...checkedSC, name];
    toggleSemanticCategory(checked);
  }, [checkedSC, toggleSemanticCategory]);

  const handleToggleTaxonomy = useCallback((name) => {
    const updatedTaxonomies = taxonomies.map((t) => (
      t.name === name ?
        ({ ...t, checked: !t.checked }) :
        ({ ...t, checked: false })
    ));
    if (
      !!updatedTaxonomies.find(d => d.checked) &&
      !checkedST.includes(conceptTypesIdsEnum.GENE)
    ) {
      toggleSemanticType([...checkedST, conceptTypesIdsEnum.GENE]);
    }
    setTaxonomies(updatedTaxonomies);
  }, [taxonomies, setTaxonomies, checkedST, toggleSemanticType]);

  const taxonomiesProps = {
    geneTaxonomies: taxonomies,
    toggleTaxonomy: handleToggleTaxonomy,
  };

  return (
    <div className="upload-file-types-setup">
      <Accordion
        title="Select type"
        customClassName="upload-file-accordion"
      >
        {
          mainCategories &&
          <div className="upload-file-types-setup__content">
            <div className="upload-file-types-setup__main">
              {
                mainCategories.map(c => (
                  <SemanticType
                    key={c.semanticTypeId}
                    id={c.semanticTypeId}
                    name={c.categoryName}
                    checked={c.checked}
                    onChange={handleToggleSemanticType}
                    {...(c.semanticTypeId === conceptTypesIdsEnum.GENE && taxonomiesProps)}
                  />
                ))
              }
            </div>
            {
              !uploadOnlyByDefaultCategory &&
              <Accordion title="Other categories">
                <div className="upload-file-types-setup__other">
                  {
                    categories.map(c => (
                      <SemanticCategory
                        key={c.name}
                        {...c}
                        onChange={handleToggleSemanticCategory}
                        onSTchange={handleToggleSemanticType}
                        taxonomiesProps={taxonomiesProps}
                        noCounts={true}
                      />
                    ))
                  }
                </div>
              </Accordion>
            }
          </div>
        }
      </Accordion>
    </div>
  );
};

UploadFileTypesSetup.propTypes = propTypes;

export default React.memo(UploadFileTypesSetup);
