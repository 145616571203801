import { call, put, select } from 'redux-saga/effects';
// Api
import Api from '../../../../../Api/Api';
// Utils
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
import { generateSetForAnalysis } from '../../../../Analytics/common/SetAnalysis/utils';
// Store
import { setAnalysisSets } from '../../../../Analytics/common/SetAnalysis/selectors';
import { toggleSetAnalyticsLoaderAction, selectSet } from '../../../../Analytics/common/SetAnalysis/actions';

export function* selectSetManagementDataItemForAnalysisWorker({ payload }) {
  try {
    yield put(toggleSetAnalyticsLoaderAction(true));
    const { set: { id, setType }, projectId, navigate } = payload;

    if (setType === 'SIMPLE' || setType === 'EFFECT') {
      const setsForAnalysis = yield select(setAnalysisSets);
      const setForAnalysis = setsForAnalysis[0];

      const { data } = checkProjectTab(projectId) ?
        yield call(Api.getProjectSet, { projectId, setId: id }) :
        yield call(Api.getSimpleOrEffectSetContent, id);

      const generatedData = generateSetForAnalysis([data]);
      Object.assign(setForAnalysis, generatedData);

      navigate(
        `${RELATIVE_PATH}/analytics/personal/new`,
        { state: {
          initNewAnalytics: true,
        } }
      );
      yield put(selectSet(setsForAnalysis));
    }
    yield put(toggleSetAnalyticsLoaderAction(false));
  } catch (e) {
    console.log(e.message);
  }
}
