import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Components
import FormInput from '../../../../common/FormElements/FormInput';
import CreateSetUIFilters from '../CreateSetUIFilters/CreateSetUIFilters';
import CreateSetGeneFilters from '../CreateSetGeneFilters/CreateSetGeneFilters';
import CreateSetDiseasesFilters from '../CreateSetDiseasesFilters/CreateSetDiseasesFilters';
import CreateSetFilterField from '../CreateSetFilterField/CreateSetFilterField';
import CreateSetFilterCheckbox from '../CreateSetFilterCheckbox/CreateSetFilterCheckbox';
import CreateSetPublicationsFilters from '../CreateSetPublicationsFilters/CreateSetPublicationsFilters';
// Utils
import {
  prepareFiltersValuesForRequest,
  getSearchFilteredApiRequestPayload,
} from '../../utils';
import {
  geneSetFiltersNames,
  geneSetFiltersLabels,
} from '../../enums';
// Store
import { getSavedSearchTerm } from '../../../../Search/store/selectors';
import {
  getCreateSetConceptsAction,
  getGeneSetFilterOptionsAction,
  setGeneSetFilterDataAction,
  updateCreateSetUIFiltersAction,
  updateCreateSetDescriptionAction,
  getSourceDetailsFilterOptionsAction,
  setSourceDetailsFilterOptionsAction,
} from '../../store/actions';
import {
  getUIFiltersDataSelector,
  getCreateSetAllConceptsSelector,
  getAvailableFiltersSelector,
  getCreateSetFilterPublicationSourceValue,
  getCreateSetNameSelector, getCreateSetConceptIdsSelector, getCreateSetStartConceptsIdsSelector,
} from '../../store/selectors';

const propTypes = {
  getCreateSetConcepts: PropTypes.func,
  getCreateSetAllConcepts: PropTypes.func,
  startConceptIds: PropTypes.instanceOf(Array),
  endConceptIds: PropTypes.instanceOf(Array),
  concepts: PropTypes.instanceOf(Object),
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  availableFilters: PropTypes.instanceOf(Object),
  getGeneSetFilterOptions: PropTypes.func,
  setGeneSetFilterData: PropTypes.func,
  searchTerm: PropTypes.string,
  updateCreateSetDescription: PropTypes.func,
  uiFilters: PropTypes.instanceOf(Object),
  updateUIFilters: PropTypes.func,
  publicationSource: PropTypes.string,
  getSourceDetailsFilterOptions: PropTypes.func,
  setSourceDetailsFilterOptions: PropTypes.func,
  change: PropTypes.func,
  storedSetName: PropTypes.string,
  initialValues: PropTypes.instanceOf(Object),
};

const CreateSetFilter = (props) => {
  const {
    change,
    reset,
    concepts,
    startConceptIds,
    endConceptIds,
    uiFilters,
    searchTerm,
    handleSubmit,
    updateUIFilters,
    availableFilters,
    publicationSource,
    getCreateSetAllConcepts,
    getGeneSetFilterOptions,
    updateCreateSetDescription,
    getSourceDetailsFilterOptions,
    setSourceDetailsFilterOptions,
    storedSetName,
    initialValues,
  } = props;

  function getRequestData(filterData) {
    const filtersData = prepareFiltersValuesForRequest(filterData, availableFilters, true, storedSetName, null, initialValues.toJS().SKIP_ZERO_PUBLICATIONS);
    const { newDescriptionByFilters } = filtersData;

    updateCreateSetDescription(newDescriptionByFilters);

    return getSearchFilteredApiRequestPayload(
      startConceptIds,
      concepts,
      filtersData,
    );
  }

  function handleSubmitForm(formData) {
    const {
      getCreateSetConcepts,
      setGeneSetFilterData,
    } = props;
    const filterData = formData.toJS();
    const requestData = getRequestData(filterData);

    setGeneSetFilterData({
      filter: requestData.data.filter,
      filters: requestData.filters,
    });
    getCreateSetConcepts(requestData);
  }

  function handleResetForm() {
    reset();
    getCreateSetAllConcepts();
    updateCreateSetDescription('');
  }

  return (
    <div className="create-set-filter">
      <div className="create-set-filter__title title5">Available filters</div>
      {
        availableFilters &&
        <form
          className="create-set-filter__form"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="create-set-filter__scrolled-wrap">
            <CreateSetUIFilters
              updateFilters={updateUIFilters}
              conceptName={uiFilters.conceptName}
              cooccurrence={uiFilters.cooccurrence}
            />
            <CreateSetGeneFilters
              type="CREATE_SET"
              availableFilters={availableFilters}
              conceptsIds={endConceptIds}
              searchTerm={searchTerm}
              getGeneFilterOptions={getGeneSetFilterOptions}
            />
            <CreateSetPublicationsFilters
              formValueChange={change}
              conceptIds={startConceptIds}
              availableFilters={availableFilters}
              publicationSource={publicationSource}
              filteredConceptsIds={concepts}
              getSourceDetailsFilterOptions={getSourceDetailsFilterOptions}
              setSourceDetailsFilterOptions={setSourceDetailsFilterOptions}
            />
            <CreateSetDiseasesFilters availableFilters={availableFilters} />
            {
              availableFilters[geneSetFiltersNames.PHENOTYPE_CAUSAL_ANNOTATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.PHENOTYPE_CAUSAL_ANNOTATION_FILTER}
                fieldLabel={geneSetFiltersLabels.PHENOTYPE_CAUSAL_ANNOTATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER}
                fieldLabel={geneSetFiltersLabels.PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_TUMOR_EXPRESSION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_TUMOR_EXPRESSION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_TUMOR_EXPRESSION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_TUMOR_DYSREGULATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_TUMOR_DYSREGULATION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_TUMOR_DYSREGULATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_TUMOR_UPREGULATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_TUMOR_UPREGULATION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_TUMOR_UPREGULATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_TUMOR_DOWNREGULATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_TUMOR_DOWNREGULATION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_TUMOR_DOWNREGULATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_GENETIC_VARIATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_GENETIC_VARIATION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_GENETIC_VARIATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_CAUSAL_ANNOTATION_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_CAUSAL_ANNOTATION_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_CAUSAL_ANNOTATION_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.CANCERS_ANIMAL_MODEL_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.CANCERS_ANIMAL_MODEL_FILTER}
                fieldLabel={geneSetFiltersLabels.CANCERS_ANIMAL_MODEL_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.DRUGS_TARGET_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.DRUGS_TARGET_FILTER}
                fieldLabel={geneSetFiltersLabels.DRUGS_TARGET_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.SMALL_MOLECULES_CHEMICAL_PROBE_FILTER] &&
              <CreateSetFilterCheckbox
                fieldName={geneSetFiltersNames.SMALL_MOLECULES_CHEMICAL_PROBE_FILTER}
                fieldLabel={geneSetFiltersLabels.SMALL_MOLECULES_CHEMICAL_PROBE_FILTER}
              />
            }
            {
              availableFilters[geneSetFiltersNames.SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER] &&
              <CreateSetFilterField
                isSection={true}
                fieldName={geneSetFiltersNames.SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER}
                fieldLabel={geneSetFiltersLabels.SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER}
                fieldProps={{
                  name: 'upperThreshold',
                  type: 'number',
                  placeholder: 'Upper threshold for  IC50 (nm)',
                  component: FormInput,
                }}
              />
            }
            {
              availableFilters[geneSetFiltersNames.SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER] &&
              <CreateSetFilterField
                isSection={true}
                fieldName={geneSetFiltersNames.SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER}
                fieldLabel={geneSetFiltersLabels.SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER}
                fieldProps={{
                  name: 'upperThreshold',
                  type: 'number',
                  placeholder: 'Upper threshold for  EC50 (nm)',
                  component: FormInput,
                }}
              />
            }
          </div>
          <div className="create-set-filter__controls">
            <button
              type="button"
              className="button mr-15"
              onClick={handleResetForm}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={!concepts || !concepts.length}
              className="button button-primary"
            >
              Apply
            </button>
          </div>
        </form>
      }
    </div>
  );
};

CreateSetFilter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concepts: getCreateSetAllConceptsSelector(state),
    availableFilters: getAvailableFiltersSelector(state),
    searchTerm: getSavedSearchTerm(state),
    uiFilters: getUIFiltersDataSelector(state),
    publicationSource: getCreateSetFilterPublicationSourceValue(state),
    storedSetName: getCreateSetNameSelector(state),
    endConceptIds: getCreateSetConceptIdsSelector(state),
    startConceptIds: getCreateSetStartConceptsIdsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCreateSetConcepts(data) {
      dispatch(getCreateSetConceptsAction(data));
    },
    getGeneSetFilterOptions(data) {
      dispatch(getGeneSetFilterOptionsAction(data));
    },
    setGeneSetFilterData(data) {
      dispatch(setGeneSetFilterDataAction(data));
    },
    updateCreateSetDescription(data) {
      dispatch(updateCreateSetDescriptionAction(data));
    },
    updateUIFilters(data) {
      dispatch(updateCreateSetUIFiltersAction(data));
    },
    getSourceDetailsFilterOptions(data) {
      dispatch(getSourceDetailsFilterOptionsAction(data));
    },
    setSourceDetailsFilterOptions(data) {
      dispatch(setSourceDetailsFilterOptionsAction(data));
    },
  };
}

function validate(values) {
  const errors = {
    PUBLICATION_FILTERS: {},
  };

  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountFrom']) > 92233720368547) {
    errors.PUBLICATION_FILTERS.publicationsCountFrom = 'References value is to big';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountFrom']) < 1) {
    errors.PUBLICATION_FILTERS.publicationsCountFrom = 'References value is to small';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountTo']) > 92233720368547) {
    errors.PUBLICATION_FILTERS.publicationsCountTo = 'References value is to big';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountTo']) < 1) {
    errors.PUBLICATION_FILTERS.publicationsCountTo = 'References value is to small';
  }

  return errors;
}

const composition = compose(
  reduxForm({
    form: 'CREATE-SET-FILTER',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: true,
    touchOnChange: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(CreateSetFilter);
