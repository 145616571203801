import {
  DUPLICATE_SET,
} from './constants';

export const duplicateSetAction = data => (
  {
    type: DUPLICATE_SET,
    data,
  }
);
