import { createSelector } from 'reselect';

const pageChapter = 'geneDetailsFunctionalCharacterizationChapter';
const sectionChart = state => state.getIn([pageChapter, 'chart']);
const chartLoading = state => state.getIn([pageChapter, 'chartLoading']);
const chartError = state => state.getIn([pageChapter, 'chartError']);
const sectionTable = state => state.getIn([pageChapter, 'table']);
const totalPages = state => state.getIn([pageChapter, 'table', 'totalPages']);
const pageNumber = state => state.getIn([pageChapter, 'table', 'number']);
const filter = state => state.getIn([pageChapter, 'filter']);
const sorting = state => state.getIn([pageChapter, 'sorting']);
const loading = state => state.getIn([pageChapter, 'loading']);
const error = state => state.getIn([pageChapter, 'error']);

export const getSectionChart = createSelector(
  sectionChart,
  chart => chart.toJS()
);

export const getSectionTable = createSelector(
  sectionTable,
  table => table.toJS()
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const categoryFilter = createSelector(
  filter,
  data => data
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getChartLoading = createSelector(
  chartLoading,
  data => data
);

export const getChartError = createSelector(
  chartError,
  data => data
);
