import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Utils
import { insertHTML, formatDate } from '../../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import { generateReprintsDeskLink, generateRightFindLink } from '../../../../Publications/PublicationsManagement/utils';
import { highlightPublicationText } from '../../utils';
// Components
import Button from '../../../Buttons/Button/Button';
import Checkbox from '../../../Inputs/Checkbox/Checkbox';
// Styles
import './styles.scss';

const propTypes = {
  onChange: PropTypes.func,
  publication: PropTypes.instanceOf(Object),
  pmidList: PropTypes.instanceOf(Array),
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  rightFindUrlSuffix: PropTypes.string,
};

const PublicationsListItem = (props) => {
  const {
    publication,
    pmidList,
    onChange,
    showRightFind,
    showReprintDesk,
    rightFindUrlSuffix,
  } = props;

  const {
    id,
    pmcId,
    title,
    source,
    pubDate,
    authors,
    abstractInfo,
    checked,
    highlights,
    highlightsTitle,
  } = publication;

  const pubMedId = publication.pmid || publication.pubmedId;

  const date = useMemo(() => (
    pubDate ? formatDate(pubDate) : 'Publication date is absent'
  ), [pubDate]);

  const handleAddToRightFind = useCallback(() => {
    window.open(generateRightFindLink(pubMedId, rightFindUrlSuffix), '_blank');
  }, [pubMedId, rightFindUrlSuffix]);

  const handleAddToReprintsDesk = useCallback(() => {
    window.open(generateReprintsDeskLink(pubMedId), '_blank');
  }, [pubMedId]);

  const handleOnChange = useCallback(() => {
    onChange({ id, pmid: pubMedId });
  }, [id, pubMedId]);

  function getMarkedTitile() {
    if (!highlightsTitle || !highlightsTitle.length) {
      return title;
    }
    return highlightPublicationText(highlightsTitle, title);
  }

  function getMarkedText() {
    if (!highlights || !highlights.length) {
      return abstractInfo;
    }
    return highlightPublicationText(highlights, abstractInfo);
  }

  const pubListItemClass = classnames({
    'publications-list-item': true,
    'publications-list-item_selected': pmidList.indexOf(pubMedId) !== -1,
  });

  return (
    <div className={pubListItemClass}>
      <div className="publications-list-item__title-wrapper">
        <Checkbox
          wrapperClassName="publications-list-item__checkbox"
          id={`publication-checkbox-${id}`}
          checked={checked}
          onChange={handleOnChange}
        />
        <Link
          to={`${RELATIVE_PATH}/publication-details/${id}`}
          className="publications-list-item__title link"
          dangerouslySetInnerHTML={insertHTML(getMarkedTitile())}
        />
      </div>
      <span className="publications-list-item__date">
        Date: {date}
      </span>
      <span
        className="publications-list-item__text"
        dangerouslySetInnerHTML={insertHTML(getMarkedText())}
      />
      <div className="publications-list-item__info">
        {
          pubMedId &&
          <span>
            PMID: <a className="link" href={`https://www.ncbi.nlm.nih.gov/pubmed/${pubMedId}`} target="blank">{pubMedId}</a>
          </span>
        }
        {
          pmcId &&
          <span className="pl-5">
            PMC ID: <a className="link" href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${pmcId}/`} target="blank">{pmcId}</a>
          </span>
        }
        {
          source &&
          <span className="pl-5">Source: {source}</span>
        }
        {
          authors &&
          <span className="pl-5">Authors: {authors}</span>
        }
      </div>
      <div className="publication-management">
        {
          showRightFind && pubMedId &&
          <Button
            onClick={handleAddToRightFind}
            customClassName="publication-management__single-citation-button mr-5"
            text="Go to RightFind"
          />
        }
        {
          showReprintDesk && pubMedId &&
          <Button
            onClick={handleAddToReprintsDesk}
            customClassName="publication-management__single-citation-button"
            text="Go to Article Galaxy"
          />
        }
      </div>
    </div>
  );
};

PublicationsListItem.propTypes = propTypes;

export default React.memo(PublicationsListItem);
