import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

// Constants
import { defaultProjects } from '../constants';

export const getProjectsAction = createAction('@@project-tabs/GET_PROJECTS_ACTION');
export const putProjectsAction = createAction('@@project-tabs/PUT_PROJECTS_ACTION');
export const throwProjectsErrorAction = createAction('@@project-tabs/THROW_PROJECTS_ERROR_ACTION');
export const setActiveProjectIdAction = createAction('@@project-tabs/SET_ACTIVE_PROJECT_ID_ACTION');
export const toggleProjectsLoaderAction = createAction('@@project-tabs/TOGGLE_PROJECTS_LOADER_ACTION');
export const clearProjectsDataAction = createAction('@@project-tabs/CLEAR_PROJECTS_DATA_ACTION');
export const clearActiveProjectIdAction = createAction('@@project-tabs/CLEAR_ACTIVE_PROJECT_ID_ACTION');
export const getProjectsOptionsAction = createAction('@@project-tabs/GET_OPTIONS');
export const setProjectsOptionsAction = createAction('@@project-tabs/SET_OPTIONS');
export const toggleProjectsOptionsLoaderAction = createAction('@@project-tabs/TOGGLE_PROJECTS_OPTIONS_LOADER_ACTION');
export const throwProjectsOptionsErrorAction = createAction('@@project-tabs/THROW_PROJECTS_OPTIONS_ERROR_ACTION');
export const resetProjectsOptionsAction = createAction('@@project-tabs/RESET_OPTIONS');

const initialState = fromJS({
  projects: {
    data: defaultProjects,
    error: null,
    loading: false,
  },
  projectsOptions: {
    data: null,
    loading: false,
  },
  activeProjectId: defaultProjects[0].id,
});

const reducer = handleActions({
  [putProjectsAction]: (state, { payload }) =>
    state
      .updateIn(['projects', 'data'], data => data.push(...fromJS(payload)))
      .updateIn(['projects', 'loading'], () => false),
  [setActiveProjectIdAction]: (state, { payload }) =>
    state.update('activeProjectId', () => payload),
  [throwProjectsErrorAction]: (state, { payload }) =>
    state.updateIn(['projects', 'error'], () => payload),
  [toggleProjectsLoaderAction]: (state, { payload }) =>
    state.updateIn(['projects', 'loading'], () => payload),
  [setProjectsOptionsAction]: (state, { payload }) =>
    state
      .updateIn(['projectsOptions', 'data'], () => fromJS(payload))
      .updateIn(['projectsOptions', 'loading'], () => false),
  [toggleProjectsOptionsLoaderAction]: (state, { payload }) =>
    state.updateIn(['projectsOptions', 'loading'], () => payload),
  [throwProjectsOptionsErrorAction]: (state, { payload }) =>
    state.updateIn(['projectsOptions', 'error'], () => payload),
  [resetProjectsOptionsAction]: state =>
    state.update('projectsOptions', () => initialState.get('projectsOptions')),
  [clearProjectsDataAction]: state =>
    state
      .update('projects', () => initialState.get('projects')),
  [clearActiveProjectIdAction]: state =>
    state
      .update('activeProjectId', () => initialState.get('activeProjectId')),
}, initialState);

export default reducer;
