import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history } from '../../index';

// Components
import FindRelatedSet from './Components/FindRelatedSet/FindRelatedSet';
import FindRelatedMethodSelection from './Components/FindRelatedMethodSelection/FindRelatedMethodSelection';
import FindRelatedContent from './Components/FindRelatedContent/FindRelatedContent';
import Heatmap from '../graphics/Heatmap/Heatmap';
import ModalComponent from '../ModalComponent/ModalComponent';
// Store
import * as a from './store/actions';
import { setUsersOptionAction } from '../Header/actions';
import * as s from './store/selectors';
import { getCerebrumSemanticCategoriesSelector, getCurrentFindRelatedMethodSelector } from '../Header/selectors';
import { clearHeatmapAction } from '../graphics/Heatmap/store/reducer';
// Constants
import { RELATIVE_PATH } from '../../constantsCommon';
// Styles
import './styles.scss';
import * as analyticsActions from '../Analytics/common/SetAnalysisMethodSelection/actions';
import {closeGenesDetail as closeGenesDetailAction} from '../common/SetAnalysisGenesDetail/actions';
import {getOpenKey as getOpenGeneDetailsKey} from '../common/SetAnalysisGenesDetail/selectors';
import SetAnalysisGenesDetail from '../common/SetAnalysisGenesDetail/SetAnalysisGenesDetail';
import EnrichmentAnalysisParamsModal from './Components/EnrichmentAnalysisParams/EnrichmentAnalysisParamsModal';
import * as ACTIONS from '../Concept/ConceptSearchModal/store/actions';
import {getSelectedSetInfoSelector} from './Components/EnrichmentAnalysisParams/store/selectors';
import {getBackgroundSetConfigSelector} from '../common/SelectBackgroundSetComponent/store/selectors';

const propTypes = {
  reset: PropTypes.func,
  loadSetData: PropTypes.func,
  method: PropTypes.string,
  initialMethod: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  setMethod: PropTypes.func,
  setCategory: PropTypes.func,
  setType: PropTypes.func,
  concepts: PropTypes.instanceOf(Array),
  semanticCategories: PropTypes.instanceOf(Array),
  clearHeatmap: PropTypes.func,
  updateLastSelectedOption: PropTypes.func,
  isSetDialogOpened: PropTypes.bool,
  openSetDialog: PropTypes.func,
  closeSetDialog: PropTypes.func,
  closeGenesDetail: PropTypes.func,
  openGeneDetails: PropTypes.bool,
  setSelectedSetInfo: PropTypes.func,
  selectedSetInfo: PropTypes.object,
  backgroundSetConfig: PropTypes.object,
};

const FindRelated = (props) => {
  const {
    loadSetData,
    reset,
    method,
    initialMethod,
    category,
    type,
    setMethod,
    setCategory,
    setType,
    concepts,
    semanticCategories,
    clearHeatmap,
    updateLastSelectedOption,
    isSetDialogOpened,
    openSetDialog,
    closeSetDialog,
    openGeneDetails,
    closeGenesDetail,
    backgroundSetConfig,
    selectedSetInfo,
  } = props;
  const navigate = useNavigate();
  const [showEnrichmentAnalysisParamsModal, setShowEnrichmentAnalysisParamsModal] = useState(false);
  const [showInfoText, setShowInfoText] = useState(false);

  const prevLocation = useLocation().pathname;

  function toggleParametrizedSearch() {
    setShowEnrichmentAnalysisParamsModal(!showEnrichmentAnalysisParamsModal);
  }

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname !== prevLocation && method !== 'CATEGORY') {
        //save currently selected method
        updateLastSelectedOption({
          option: 'lastEnrichmentAnalysisSelected',
          value: method,
        });
      }
    }, []);

    return () => {
      unlisten();
    };
  },[method]);

  useEffect(() => {
    if (!method) {
      setMethod(initialMethod || 'PATHWAY');
    }

    if (selectedSetInfo) {
      loadSetData({
        setId: selectedSetInfo.setId,
        projectId: selectedSetInfo.projectId,
      });
    } else {
      navigate(`${RELATIVE_PATH}/sets`);
    }
    return reset;
  }, [showEnrichmentAnalysisParamsModal]);

  useEffect(() => {
    clearHeatmap();
  }, [clearHeatmap, type, method]);

  return (
    <div className="find-related page">
      <div className="find-related__title title2">
        <span className="link" onClick={openSetDialog}>
          BioProfiler by Enrichment Analysis ({selectedSetInfo.setName})
        </span>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          onClick={() => setShowInfoText(!showInfoText)}
        />
      </div>
      {
        showInfoText &&
        <div className="find-related__info-text">
          <p>
            BioProfiler is a user-friendly enrichment analysis tool designed to help biological researchers uncover the deeper biological significance of their gene sets without requiring prior bioinformatics knowledge. By comparing your specific gene list to broader biological categories, BioProfiler provides insights into how your genes are connected to various biological processes, pathways, and more, helping you to understand their potential roles in disease, phenotype, and cellular functions.
            BioProfiler is designed to make advanced bioinformatics analyses accessible, helping you uncover the rich biological stories hidden within your gene sets. Whether you&apos;re investigating disease mechanisms, exploring tissue-specific gene expression, or studying the molecular functions of your genes, BioProfiler is your gateway to deeper insights.
          </p>
          <p>
            Parent-Child Filtering: To ensure clarity and relevance, only the most specific terms (child terms) are displayed in the results if they have parent-child relationships with other terms. This helps focus on the most directly relevant biological insights.
          </p>
          <p>
            Red Font Significance: Any term in red font did not pass the Hochberg-Benjamini multiple testing correction, indicating that these findings may not be statistically significant and should be interpreted with caution.
          </p>
          <p>
            1. &lt;Category&gt; Term: The specific biological term from the category you selected.<br/>
            2. Input-Related : The number of genes from your input set that are related to this biological term.<br/>
            3. Status: Indicates whether the term is enriched (overrepresented) or depleted (underrepresented) in your input set, compared to what would be expected.<br/>
            4. Background-Related : The number of genes in the background set related to the biological term, giving context to the significance of the term&apos;s occurrence in your input set.<br/>
            5. P-Value: The statistical significance of the term&apos;s enrichment or depletion, calculated using Fisher&apos;s exact test. Terms failing to pass the Hochberg-Benjamini multiple testing correction are highlighted in red to draw attention to less statistically significant findings.
          </p>
        </div>
      }
      <FindRelatedMethodSelection
        method={method || initialMethod}
        category={category}
        setMethod={setMethod}
        type={type}
        setCategory={setCategory}
        setType={setType}
        semanticCategories={semanticCategories}
        backgroundSetConfig={backgroundSetConfig}
        openSelectBackgroundSetPopup={toggleParametrizedSearch}
      />
      <FindRelatedContent
        method={method || initialMethod}
        type={type}
        concepts={concepts}
        selectedCategory={category}
        semanticCategories={semanticCategories}
      />
      <Heatmap
        projectId={selectedSetInfo.projectId}
        isSetAnalysis={true}
      />
      {
        isSetDialogOpened &&
        <ModalComponent
          isOpen={isSetDialogOpened}
          closeCb={closeSetDialog}
        >
          <FindRelatedSet name={selectedSetInfo.setName} />
        </ModalComponent>
      }
      {
        openGeneDetails &&
        <ModalComponent
          isOpen={openGeneDetails}
          closeCb={closeGenesDetail}
        >
          <SetAnalysisGenesDetail />
        </ModalComponent>
      }
      <EnrichmentAnalysisParamsModal
        isOpen={showEnrichmentAnalysisParamsModal}
        closeCb={toggleParametrizedSearch}
      />
    </div>
  );
};

FindRelated.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    method: s.getFindRelatedMethodSelector(state),
    initialMethod: getCurrentFindRelatedMethodSelector(state),
    category: s.getFindRelatedCategorySelector(state),
    concepts: s.getFindRelatedSetConceptsSelector(state),
    type: s.getFindRelatedTypeSelector(state),
    semanticCategories: getCerebrumSemanticCategoriesSelector(state),
    isSetDialogOpened: s.getOpenSetDialogSelector(state),
    openGeneDetails: getOpenGeneDetailsKey(state),
    selectedSetInfo: getSelectedSetInfoSelector(state),
    backgroundSetConfig: getBackgroundSetConfigSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeGenesDetail() {
      dispatch(closeGenesDetailAction());
    },
    loadSetData(data) {
      dispatch(a.getFindRelatedSetDataAction(data));
    },
    setMethod(data) {
      dispatch(a.setFindRelatedMethodAction(data));
    },
    setCategory(data) {
      dispatch(a.setFindRelatedCategoryAction(data));
    },
    setType(data) {
      dispatch(a.setFindRelatedTypeAction(data.id));
      dispatch(analyticsActions.selectCategoryTypeAction(data));
    },
    reset() {
      dispatch(a.resetFindRelatedAction());
    },
    clearHeatmap() {
      dispatch(clearHeatmapAction());
    },
    updateLastSelectedOption(data) {
      dispatch(setUsersOptionAction(data));
    },
    openSetDialog() {
      dispatch(a.openSetDialogAction());
    },
    closeSetDialog() {
      dispatch(a.closeSetDialogAction());
    },
    setSelectedSetInfo(data) {
      dispatch(ACTIONS.setSelectedSetInfoAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindRelated);
