import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import { showTooltipAction, hideTooltipAction } from './store/reducer';
// Styles
import './Tooltip.scss';

const propTypes = {
  uniqueKey: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
};

const TooltipBtn = (props) => {
  const {
    children,
    uniqueKey,
    showTooltip,
    hideTooltip,
  } = props;

  function showTooltipHandler({ clientX, clientY }) {
    showTooltip({
      uniqueKey,
      clientX,
      clientY,
    });
  }

  return (
    <div
      className="tooltip__btn"
      onFocus={showTooltipHandler}
      onMouseOver={showTooltipHandler}
      onBlur={hideTooltip}
      onMouseOut={hideTooltip}
    >
      { children }
    </div>
  );
};

TooltipBtn.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
  };
}

export default connect(null, mapDispatchToProps)(TooltipBtn);

