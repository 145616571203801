import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getDiseaseOptionsAction = createAction('@@selectDisease/GET_GENE_OPTIONS');
export const putDiseaseOptionsAction = createAction('@@selectDisease/PUT_GENE_OPTIONS');
export const toggleSelectDiseaseLoaderAction = createAction('@@selectDisease/TOGGLE');
export const throwSelectDiseaseErrorAction = createAction('@@selectDisease/GENE_ERROR');
export const resetSelectDiseaseAction = createAction('@@selectDisease/RESET_ERROR');

const initialState = fromJS({
  options: [],
  loading: false,
  error: false,
});

const reducer = handleActions(
  {
    [putDiseaseOptionsAction]: (state, { payload }) =>
      state
        .update('options', () => fromJS(payload))
        .update('error', () => false)
        .update('loading', () => false),
    [toggleSelectDiseaseLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwSelectDiseaseErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [resetSelectDiseaseAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
