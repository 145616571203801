import { createSelector } from 'reselect';

const searchCategoryTabsData = state => state.getIn(['searchCategoryTabsReducer', 'semanticCategoriesTabData']);
const semanticCategoriesConceptIds = state => state.getIn(['searchCategoryTabsReducer', 'conceptsIds']);
const cachedSemanticCategoriesTabData = state => state.getIn(['searchCategoryTabsReducer', 'cachedSemanticCategoriesTabData']);
const searchCategoryTabsLoading = state => state.getIn(['searchCategoryTabsReducer', 'loading']);
const searchCategoryTabsError = state => state.getIn(['searchCategoryTabsReducer', 'error']);
const otherCategoriesDropdown = state => state.getIn(['searchCategoryTabsReducer', 'otherCategoriesDropdown']);
const semanticCategoriesForFilter = state => state.getIn(['searchCategoryTabsReducer', 'semanticCategoriesForFilter']);
const semanticTypesForFilter = state => state.getIn(['searchCategoryTabsReducer', 'semanticTypesForFilter']);

export const getSearchCategoryTabsDataSelector = createSelector(
  searchCategoryTabsData,
  data => data && data.toJS()
);

export const getCachedSemanticCategoriesTabDataSelector = createSelector(
  cachedSemanticCategoriesTabData,
  data => data && data.toJS()
);

export const getSearchCategoryTabsLoadingSelector = createSelector(
  searchCategoryTabsLoading,
  data => data
);

export const getSearchCategoryTabsErrorSelector = createSelector(
  searchCategoryTabsError,
  data => data
);

export const getOtherCategoriesDropdownSelector = createSelector(
  otherCategoriesDropdown,
  data => data
);

export const getSemanticCategoriesForFilterSelector = createSelector(
  semanticCategoriesForFilter,
  data => data && data.toJS()
);

export const getSemanticTypesForFilterSelector = createSelector(
  semanticTypesForFilter,
  data => data && data.toJS()
);

export const getSemanticCategoriesConceptIds = createSelector(
  semanticCategoriesConceptIds,
  data => data && data.toJS()
);
