import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Loader from '../../../common/Loader/Loader';
import SearchRelatedConceptsCard from './SearchRelatedConceptsCard';
// Store
import * as SELECTORS from './store/selectors';
// Styles
import './SearchRelatedConceptsCard.scss';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  checkRelatedConceptCb: PropTypes.func,
  startNewSearch: PropTypes.func,
  selectedSearchCategory: PropTypes.string,
  categoryConceptsSize: PropTypes.number,
  openCreateSetPopup: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const SearchRelatedConceptsCardWrapper = (props) => {
  const {
    concepts,
    selectedSearchCategory,
    loading,
    error,
    startNewSearch,
    checkRelatedConceptCb,
    categoryConceptsSize,
    openCreateSetPopup,
  } = props;

  if (!concepts) {
    return null;
  }

  return (
    <div className="related-concepts-card-wrapper">
      {
        !loading && concepts && concepts.length > 0 &&
        <SearchRelatedConceptsCard
          startNewSearch={startNewSearch}
          checkRelatedConceptCb={checkRelatedConceptCb}
          selectedSearchCategory={selectedSearchCategory}
          concepts={concepts}
          categoryConceptsSize={categoryConceptsSize}
          openCreateSetPopup={openCreateSetPopup}
        />
      }
      {
        selectedSearchCategory && concepts && !concepts.length && !loading &&
        <div className="find-results-for-wrapper">
          <div className="related-concepts-margin concept-card-title wrapper-for-no-title">
            <span>{`No related ${selectedSearchCategory}`}</span>
          </div>
        </div>
      }
      {
        loading &&
        <div className="related-concepts-card-mock">
          <div className="related-concepts-card-mock__block">Searching Related genes...</div>
          <Loader isLoading={loading} />
        </div>
      }
      {
        !loading && error &&
        <div className="related-concepts-card-mock related-concepts-card-mock_error">
          <div className="related-concepts-card-mock__block">Searching related concepts is failed:</div>
          {error}
        </div>
      }
    </div>
  );
};

SearchRelatedConceptsCardWrapper.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: SELECTORS.getSearchCategoryLoadingSelector(state),
    error: SELECTORS.getSearchCategoryErrorSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(SearchRelatedConceptsCardWrapper);

