import { call, put, takeEvery, select } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { getBackgroundSetConfigSelector } from '../../common/SetAnalysisMethodSelection/selectors';

import {
  findRelatedProteinOrRegulationRequestedAction,
  findRelatedProteinOrRegulationSucceededAction,
  findRelatedProteinOrRegulationFailedAction,
  findRelatedProteinOrRegulationTableLoadingAction,
} from './reducer';
import { getTableSorting } from './selectors';

function* findRelatedProteinOrRegulationAnalysisWorker(action) {
  try {
    yield put(findRelatedProteinOrRegulationTableLoadingAction(true));
    const backgroundSetConfiguration = yield select(getBackgroundSetConfigSelector);
    const { sortBy, sortDirection } = yield select(getTableSorting);
    const { requestData, apiKey } = action.payload;
    requestData.params = {
      ...requestData.params,
      sort: `${sortBy},${sortDirection}`,
    };
    requestData.backgroundSetConfiguration = backgroundSetConfiguration;
    const { data } = yield call(Api[apiKey], requestData);
    yield put(findRelatedProteinOrRegulationSucceededAction(data));
  } catch (e) {
    yield put(findRelatedProteinOrRegulationFailedAction(e.message));
  }
}

function* findRelatedProteinOrRegulationAnalysisWatcher() {
  yield takeEvery(findRelatedProteinOrRegulationRequestedAction, findRelatedProteinOrRegulationAnalysisWorker);
}

export default findRelatedProteinOrRegulationAnalysisWatcher;
