import { takeLatest, call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Utils
import { sortSemanticCategories } from '../../../utils';
// Store
import {
  getSemanticCategoriesTabsDataAction,
  getSemanticTypesForFilterAction,
  semanticCategoriesTabsLoadingAction,
  setSemanticCategoriesTabsDataAction,
  semanticCategoriesTabsErrorAction,
  setSemanticTypesForFilterAction,
} from './reducer';
import { getSemanticTypesForFilterSelector } from './selectors';

function* getSemanticTypesForFilterWorker({ payload }) {
  try {
    const { concepts, semCategory } = payload;
    const requestData = { post: { concepts, semCategory } };
    const response = yield call(Api.semanticCategoriesTypesForFilter, requestData);
    const semanticTypesForFilter = yield select(getSemanticTypesForFilterSelector);
    const newSemanticTypesForFilter = semanticTypesForFilter;

    newSemanticTypesForFilter[semCategory] = response.data.map(semTypeObj => ({
      id: semTypeObj.semType,
      name: semTypeObj.semTypeName,
    }));

    yield put(setSemanticTypesForFilterAction(newSemanticTypesForFilter));
  } catch (e) {
    yield put(semanticCategoriesTabsErrorAction(e.message));
  }
}

function* getSemanticCategoriesTabsDataWorker({ payload }) {
  try {
    yield put(semanticCategoriesTabsLoadingAction(true));
    const { data } = yield call(Api.categoriesTabData, payload);
    const tabsData = {
      ...data,
      otherCategories: sortSemanticCategories(data.otherCategories),
    };
    yield put(setSemanticCategoriesTabsDataAction(tabsData));
    yield put(semanticCategoriesTabsLoadingAction(false));
  } catch (e) {
    yield put(semanticCategoriesTabsErrorAction(e.message));
    yield put(semanticCategoriesTabsLoadingAction(false));
  }
}

function* searchCategoryTabsSaga() {
  yield takeLatest(getSemanticCategoriesTabsDataAction, getSemanticCategoriesTabsDataWorker);
  yield takeLatest(getSemanticTypesForFilterAction, getSemanticTypesForFilterWorker);
}

export default searchCategoryTabsSaga;
