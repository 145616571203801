import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import SelectInput from '../../../../../../../common/Inputs/SelectInput/SelectInput';
// Store
import * as s from '../../../../store/selectors';
import * as a from '../../../../store/actions';
// Styles
import './styles.scss';

const propTypes = {
  setFilters: PropTypes.func,
  setActiveOption: PropTypes.func,
  cellTypeFilter: PropTypes.instanceOf(Object),
  individualFilter: PropTypes.instanceOf(Object),
  conditionFilter: PropTypes.instanceOf(Object),
  selectedFilterValue: PropTypes.instanceOf(Object),
  colourByOptions: PropTypes.instanceOf(Array),
};

const SingleCellFilters = (props) => {
  const {
    setFilters,
    cellTypeFilter,
    individualFilter,
    conditionFilter,
    setActiveOption,
    selectedFilterValue,
    colourByOptions,
  } = props;

  const handleActiveOption = (option) => {
    setActiveOption(option);
  };

  const handleFilters = (option, value) => {
    setFilters({ value, option: option || [] });
  };

  const selectorStyles = {
    valueContainer: base => ({
      ...base,
      maxHeight: '60px',
      overflow: 'auto',
    }),
  };

  return (
    <div className="single-cell-filters">
      <div className="single-cell__studies-filters single-cell__studies-filters--colors">
        <span className="single-cell-selector__label">Colour by: </span>
        <SelectInput
          defaultValue={selectedFilterValue}
          options={colourByOptions}
          onSelect={handleActiveOption}
          placeholder="Select cell study"
          closeOnSelect={true}
          customClassName="single-cell-selector"
        />
      </div>
      <div className="single-cell__studies-filters single-cell__studies-filters--values">
        <span className="single-cell-selector__label">Select: </span>
        <SelectInput
          defaultValue={cellTypeFilter.selectedOptions}
          options={cellTypeFilter.options}
          placeholder="cell type"
          closeOnSelect={true}
          isMulti={true}
          styles={selectorStyles}
          customClassName="single-cell-selector"
          onSelect={option => handleFilters(option, 'cellType')}
        />
        {
          colourByOptions.find(option => option.value === 'patient') &&
          <SelectInput
            title="Type human gene name"
            defaultValue={individualFilter.selectedOptions}
            options={individualFilter.options}
            placeholder="individual"
            closeOnSelect={true}
            isMulti={true}
            styles={selectorStyles}
            customClassName="single-cell-selector"
            onSelect={option => handleFilters(option, 'patient')}
          />
        }
        <SelectInput
          defaultValue={conditionFilter.selectedOptions}
          options={conditionFilter.options}
          placeholder="condition"
          closeOnSelect={true}
          isMulti={true}
          styles={selectorStyles}
          customClassName="single-cell-selector"
          onSelect={option => handleFilters(option, 'condition')}
        />
      </div>
    </div>
  );
};

SingleCellFilters.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    colourByOptions: s.getColourByOptionsSelector(state),
    selectedFilterValue: s.getSelectedFilterValueSelector(state),
    filters: s.getDataFiltersSelector(state),
    cellTypeFilter: s.getMetaDataCellTypeFilter(state),
    individualFilter: s.getMetaDataIndividualFilter(state),
    conditionFilter: s.getMetaDataConditionFilter(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveOption(data) {
      dispatch(a.setMetaDataActiveFilterValueAction(data));
    },
    setFilters(data) {
      dispatch(a.setMetaDataFiltersAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCellFilters);

