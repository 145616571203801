import {
  call,
  put,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Utils
import { history } from '../../../index';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { checkProjectTab } from '../../Projects/ProjectsTabs/utils';
import { mapAndFormatConceptsToSave } from '../../Sets/SaveAsSet/utils';
import { convertTableToCSVText, exportToFile } from '../../Utils/Utils';
import { SELECT_SET_FOR_SET_ANALYSIS } from '../../Analytics/common/SetAnalysis/constants';
import { generateSetForAnalysis, initialSets } from '../../Analytics/common/SetAnalysis/utils';
// Store
import { updateSaveAsSetConceptsAction } from '../../Sets/SaveAsSet/store/actions';
import { getAvailableSetsListSelector } from '../../Sets/SetsList/selectors';
import {
  RESULT_SET_REQUESTED,
  RESULT_SET_SUCCEEDED,
  RESULT_SET_FAILED,
  SHORT_CONCEPTS_DETAILS_REQUESTED,
  SHORT_CONCEPTS_DETAILS_FAILED,
  EXPORT_ALL_TO_CSV,
  EXPORT_ALL_TO_CSV_RESULT_SUCCESS,
  SET_RESULT_PAGE_SAVE_COLUMN_AS_SET,
  GO_TO_SET_RESULT_PAGE,
  SAVE_SET_FOR_SET_RESULT,
} from './constants';
import {
  getConceptsIds,
  getSorting,
  getPairOfConcepts,
  getConceptAndCategory,
  getFullSet,
  getSortForBackend,
  getPageValue,
  getTotalItems,
  getContentForExportAll,
} from './selectors';
import { loading } from './actions';

function* saga(action) {
  try {
    yield put(loading(true));

    const conceptIds = yield select(getConceptsIds);
    const pairOfConcepts = yield select(getPairOfConcepts);
    const conceptAndCategory = yield select(getConceptAndCategory);
    const pageNumber = yield select(getPageValue);

    let fullSet = yield select(getFullSet);
    if (!fullSet.length && localStorage.getItem('setResultPageData')) {
      fullSet = JSON.parse(localStorage.getItem('setResultPageData'));
      localStorage.removeItem('setResultPageData');
    }
    const sort = yield select(getSortForBackend);

    let API = null;
    let data = {};

    const sorting = yield select(getSorting);
    let params = {
      size: 20,
      page: pageNumber,
      sort: `${sorting.sortBy},${sorting.sortDirection}`,
    };

    if (action.data && action.data.params) {
      params = Object.assign(params, action.data.params);
    }

    /* Third case */
    if ((pairOfConcepts.conceptA && pairOfConcepts.conceptB) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && fullSet[0].nodeC)) {
      let response = {};
      if (!fullSet.length) {
        response = yield call(Api.getFullSetForInderectPaths, {
          params: {
            leftConceptId: pairOfConcepts.conceptA,
            rightConceptId: pairOfConcepts.conceptB,
          },
        });
      }

      data = Object.assign(data, {
        post: {
          fullSet: fullSet.length ? fullSet : response.data,
          sort,
        },
      });

      API = Api.resultSetABC;
    }

    /* Second case */
    if ((conceptAndCategory.conceptId && conceptAndCategory.semanticTypeId) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && !fullSet[0].nodeC)) {
      let response = {};
      if (!fullSet.length) {
        response = yield call(Api.getFullSetByCategory, {
          id: conceptAndCategory.conceptId,
          params: {
            semanticType: conceptAndCategory.semanticTypeId,
          },
        });
      }

      data = Object.assign(data, {
        post: {
          fullSet: fullSet.length ? fullSet : response.data,
          sort,
        },
      });
      API = Api.resultSetAB;
    }

    data = Object.assign(data, { params });

    /* First case */
    if ((conceptIds.length > 0) ||
      (fullSet.length && fullSet[0].nodeA && !fullSet[0].nodeB && !fullSet[0].nodeC)) {
      data = Object.assign(data, {
        post: {
          /* First time we send all concepts from set, then fullSet field from response */
          fullSet: fullSet.length ? fullSet : conceptIds.map(id => (
            {
              nodeA: id,
            }
          )),
          sort,
        },
      });
      API = Api.resultSet;
    }

    if (!API) {
      history.push(`${RELATIVE_PATH}/research`);
    } else {
      const response = yield call(API, data);
      yield put({ type: RESULT_SET_SUCCEEDED, data: response.data });
    }
  } catch (e) {
    yield put({ type: RESULT_SET_FAILED, message: e.message });
  }
}

function* shortDetails(action) {
  const { postAction } = action.data;

  try {
    const ids = action.data.concepts;
    const setName = action.data.name;
    const setsForAnalysis = initialSets;
    const setForAnalysis = setsForAnalysis[0];
    const response = yield call(Api.getShortConceptsDetails, { post: ids });
    const generatedData = generateSetForAnalysis([{
      name: setName,
      items: response.data.map(item => (
        {
          id: item.id,
          name: item.name,
        }
      )),
    }]);

    Object.assign(setForAnalysis, generatedData);

    if (postAction === 'analyze') {
      yield put({ type: SELECT_SET_FOR_SET_ANALYSIS, data: setsForAnalysis });
      yield put({ type: 'REFRESH_RANKING_SETTINGS' });
      history.push({
        pathname: `${RELATIVE_PATH}/analytics/personal/new`,
        initNewAnalytics: true,
      });
    }
    if (postAction === 'saveAsSet') {
      yield put(updateSaveAsSetConceptsAction(mapAndFormatConceptsToSave(response.data)));
      history.push(`${RELATIVE_PATH}/sets/add/personal`);
    }
  } catch (e) {
    yield put({ type: SHORT_CONCEPTS_DETAILS_FAILED, message: e.message });
    if (postAction === 'analyze') {
      history.push(`${RELATIVE_PATH}/analytics`);
    }
  }
}

function* goToSetResultSaga(action) {
  try {
    const { data: { id, setType, projectId } } = action;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = !isProjectTab ? Api.getSimpleOrEffectSetContent : Api.getProjectSet;
    const requestData = !isProjectTab ? id : { projectId, setId: id };
    switch (setType) {
      case 'COMPLEX':
        // TODO: Case when processing Set with type COMPLEX
        break;
      case 'EFFECT':
      case 'SIMPLE': //eslint-disable-line
        const response = yield call(apiToCall, requestData); //eslint-disable-line
        const { //eslint-disable-line
          data: {
            items,
            name,
          },
        } = response;

        const conceptIds = items.map(item => ( //eslint-disable-line
          item.id
        ));

        yield put({
          type: SAVE_SET_FOR_SET_RESULT,
          data: {
            conceptIds,
            name,
            id,
            projectId,
          },
        });
        history.push(`${RELATIVE_PATH}/set-result-page`);
        break;
      default:
    }
  } catch (error) {
    console.log(error);
  }
}

function* exportAllToCSVSaga(action) {
  try {
    const conceptIds = yield select(getConceptsIds);
    const pairOfConcepts = yield select(getPairOfConcepts);
    const conceptAndCategory = yield select(getConceptAndCategory);
    const totalItemsValues = yield select(getTotalItems);

    const fullSet = yield select(getFullSet);
    const sort = yield select(getSortForBackend);

    let API = null;
    let data = {};

    const sorting = yield select(getSorting);
    const params = {
      size: totalItemsValues,
      page: 0,
      sort: `${sorting.sortBy},${sorting.sortDirection}`,
    };

    /* Third case */
    if ((pairOfConcepts.conceptA && pairOfConcepts.conceptB) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && fullSet[0].nodeC)) {
      let response = {};
      if (!fullSet.length) {
        response = yield call(Api.getFullSetForInderectPaths, {
          params: {
            leftConceptId: pairOfConcepts.conceptA,
            rightConceptId: pairOfConcepts.conceptB,
          },
        });
      }

      data = Object.assign(data, {
        post: {
          fullSet: fullSet.length ? fullSet : response.data,
          sort,
        },
      });

      API = Api.resultSetABC;
    }

    /* Second case */
    if ((conceptAndCategory.conceptId && conceptAndCategory.semanticTypeId) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && !fullSet[0].nodeC)) {
      let response = {};
      if (!fullSet.length) {
        response = yield call(Api.getFullSetByCategory, {
          id: conceptAndCategory.conceptId,
          params: {
            semanticType: conceptAndCategory.semanticTypeId,
          },
        });
      }

      data = Object.assign(data, {
        post: {
          fullSet: fullSet.length ? fullSet : response.data,
          sort,
        },
      });
      API = Api.resultSetAB;
    }

    data = Object.assign(data, { params });

    /* First case */
    if ((conceptIds.length > 0) ||
      (fullSet.length && fullSet[0].nodeA && !fullSet[0].nodeB && !fullSet[0].nodeC)) {
      data = Object.assign(data, {
        post: {
          /* First time we send all concepts from set, then fullSet field from response */
          fullSet: fullSet.length ? fullSet : conceptIds.map(id => (
            {
              nodeA: id,
            }
          )),
          sort,
        },
      });
      API = Api.resultSet;
    }

    if (!API) {
      history.push(`${RELATIVE_PATH}/research`);
    } else {
      const response = yield call(API, data);
      yield put({ type: EXPORT_ALL_TO_CSV_RESULT_SUCCESS, data: response.data });
    }

    const exportData = yield select(getContentForExportAll);
    const result = convertTableToCSVText(exportData, action.data.columns.filter(column => (
      column.exportCSV
    )));
    exportToFile(action.data.downloadLink, result, 'set_results');
  } catch (e) {
    yield put({ type: RESULT_SET_FAILED, message: e.message });
  }
}

function* saveColumnAsSetSaga(action) {
  try {
    const {
      data: { setId, projectId },
    } = action;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const requestData = isProjectTab ? { projectId, setId } : setId;
    const availableUserSets = yield select(getAvailableSetsListSelector);
    const chosenSet = availableUserSets.find(set => set.id === setId);
    const setData = yield call(apiToCall, requestData);
    const choseSetWithItems = {
      items: setData.data.items,
      ...chosenSet,
    };
    yield put(updateSaveAsSetConceptsAction(mapAndFormatConceptsToSave(choseSetWithItems.items)));
    history.push(`${RELATIVE_PATH}/sets/add/${projectId}`);
  } catch (e) {
    yield put({ type: RESULT_SET_FAILED, message: e.message });
  }
}

function* setResultPage() {
  yield takeLatest(SET_RESULT_PAGE_SAVE_COLUMN_AS_SET, saveColumnAsSetSaga);
  yield takeLatest(EXPORT_ALL_TO_CSV, exportAllToCSVSaga);
  yield takeEvery(RESULT_SET_REQUESTED, saga);
  yield takeLatest(SHORT_CONCEPTS_DETAILS_REQUESTED, shortDetails);
  yield takeEvery(GO_TO_SET_RESULT_PAGE, goToSetResultSaga);
}

export default setResultPage;
