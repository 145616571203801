import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Utils
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import { capitalizeFirstLetter, insertHTML } from '../../../../Utils/Utils';
// Styles
import './styles.scss';

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  synonyms: PropTypes.instanceOf(Array),
  definition: PropTypes.string,
  semanticTypes: PropTypes.instanceOf(Array),
  semanticCategory: PropTypes.string,
};

const ConceptCard = (props) => {
  const {
    id,
    name,
    synonyms,
    definition,
    semanticTypes,
    semanticCategory,
  } = props;
  const isGene = !!semanticTypes.find(t => t.id === 'T028');
  const linkPath = isGene ?
    `${RELATIVE_PATH}/gene-details/${id}` :
    `${RELATIVE_PATH}/concept-details/${id}`;

  return (
    <div className="concept-card">
      <Link
        to={linkPath}
        target="blank"
        className="search-page__subtitle"
      >
        {capitalizeFirstLetter(name)}
      </Link>
      <div className="concept-card__semantic-info">
        {`${semanticCategory}, ${semanticTypes.map(t => t.name).join(', ')}`}
      </div>
      <div
        className="concept-card__definition"
        dangerouslySetInnerHTML={insertHTML(definition)}
      />
      {
        synonyms && synonyms.length > 0 &&
        <div className="concept-card__synonyms">
          <span className="concept-card__synonym bold">
            Synonyms:
          </span>
          {
            synonyms.slice(0, 5).map((s, i, array) => (
              <span key={`syn-${i}`} className="concept-card__synonym">
                {s}{i !== (array.length - 1) && ', '}
              </span>
            ))
          }
        </div>
      }
    </div>
  );
};

ConceptCard.propTypes = propTypes;

export default React.memo(ConceptCard);
