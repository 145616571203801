import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

// Styles
import './styles.scss';

const propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  labelValue: PropTypes.string,
  labelCls: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const Checkbox = (props) => {
  const {
    id = uuidv4(),
    title,
    checked = false,
    className,
    labelCls,
    onChange,
    labelValue,
    readOnly = false,
    disabled = false,
    wrapperClassName,
  } = props;

  const inputClassNames = classNames('checkbox', className);
  const checkboxWrapperClassNames = classNames('checkbox__wrapper', wrapperClassName);

  return (
    <div className={checkboxWrapperClassNames}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        className={inputClassNames}
      />
      <label htmlFor={id} className={labelCls} />
      {
        labelValue &&
        <span title={title}>
          {labelValue}
        </span>
      }
    </div>
  );
};

Checkbox.propTypes = propTypes;

export default React.memo(Checkbox);
