import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { PAGE_SIZE } from '../../../constants';

// Target Candidates Pairs Data
export const setTargetCandidatesPairsDataAction = createAction('@@bispecificAntibody/SET_DATA');
export const targetCandidatesPairsChangePageAction = createAction('@@bispecificAntibody/CHANGE_PAGE');
export const resetTargetCandidatesPairsDataAction = createAction('@@bispecificAntibody/RESET_DATA');
export const setTargetCandidatesPairsFilterByGeneValueAction = createAction('@@bispecificAntibody/SET_FILTER_BY_GENE_VALUE');
export const sortTargetCandidatesPairsDataAction = createAction('@@bispecificAntibody/SORT_DATA');
// Target Candidates Pairs Charts First Gene
export const getTargetCandidatesPairsFirstGeneChartsAction = createAction('@@bispecificAntibody/GET_FIRST_GENE_CHARTS');
export const setTargetCandidatesPairsFirstGeneChartsAction = createAction('@@bispecificAntibody/SET_FIRST_GENE_CHARTS');
export const targetCandidatesPairsFirstGeneChartsLoadingAction = createAction('@@bispecificAntibody/FIRST_GENE_CHARTS_LOADING');
export const targetCandidatesPairsFirstGeneChartsErrorAction = createAction('@@bispecificAntibody/FIRST_GENE_CHARTS_ERROR');
// Target Candidates Pairs Charts Second Gene
export const getTargetCandidatesPairsSecondGeneChartsAction = createAction('@@bispecificAntibody/GET_SECOND_GENE_CHARTS');
export const setTargetCandidatesPairsSecondGeneChartsAction = createAction('@@bispecificAntibody/SET_SECOND_GENE_CHARTS');
export const targetCandidatesPairsSecondGeneChartsLoadingAction = createAction('@@bispecificAntibody/SECOND_GENE_CHARTS_LOADING');
export const targetCandidatesPairsSecondGeneChartsErrorAction = createAction('@@bispecificAntibody/SECOND_GENE_CHARTS_ERROR');
export const targetCandidatesPairsClearChartsDataAction = createAction('@@bispecificAntibody/CLEAR_CHARTS_DATA');

const initialChartsState = {
  charts: {
    gtexExpression: [],
    tcgaExpression: [],
  },
  geneName: '',
  loading: true,
  error: null,
};

const initialState = fromJS({
  table: {
    data: null,
    page: 0,
    totalPages: 0,
    sorting: {
      sortBy: 'cancer',
      sortDirection: 'ASC',
      sortPath: ['cancer', 'name'],
    },
  },
  charts: {
    firstGene: initialChartsState,
    secondGene: initialChartsState,
  },
  filterByGeneValue: '',
});

const reducer = handleActions(
  {
    [resetTargetCandidatesPairsDataAction]: () =>
      initialState,
    [setTargetCandidatesPairsDataAction]: (state, { payload }) =>
      state
        .updateIn(['table', 'data'], () => fromJS(payload))
        .updateIn(['table', 'page'], () => 0)
        .updateIn(['table', 'totalPages'], () => Math.ceil((payload || []).length / PAGE_SIZE))
        .updateIn(['filterByGeneValue'], () => ''),
    [setTargetCandidatesPairsFilterByGeneValueAction]: (state, { payload }) =>
      state
        .update('filterByGeneValue', () => payload)
        .updateIn(['table', 'page'], () => 0),
    [targetCandidatesPairsChangePageAction]: (state, { payload }) =>
      state.updateIn(['table', 'page'], () => payload),
    [sortTargetCandidatesPairsDataAction]: (state, { payload }) =>
      state.updateIn(['table', 'sorting'], () => fromJS(payload)),
    [setTargetCandidatesPairsFirstGeneChartsAction]: (state, { payload }) =>
      state.updateIn(['charts', 'firstGene'], () => fromJS({
        charts: payload.chartsData,
        geneName: payload.geneName,
        loading: false,
        error: null,
      })),
    [targetCandidatesPairsFirstGeneChartsLoadingAction]: (state, { payload }) =>
      state.updateIn(['charts', 'firstGene', 'loading'], () => payload),
    [targetCandidatesPairsFirstGeneChartsErrorAction]: (state, { payload }) =>
      state.updateIn(['charts', 'firstGene', 'error'], () => payload),
    [setTargetCandidatesPairsSecondGeneChartsAction]: (state, { payload }) =>
      state.updateIn(['charts', 'secondGene'], () => fromJS({
        charts: payload.chartsData,
        geneName: payload.geneName,
        loading: false,
        error: null,
      })),
    [targetCandidatesPairsSecondGeneChartsLoadingAction]: (state, { payload }) =>
      state.updateIn(['charts', 'secondGene', 'loading'], () => payload),
    [targetCandidatesPairsSecondGeneChartsErrorAction]: (state, { payload }) =>
      state.updateIn(['charts', 'secondGene', 'error'], () => payload),
    [targetCandidatesPairsClearChartsDataAction]: state =>
      state
        .updateIn(['charts', 'firstGene'], () => fromJS(initialChartsState))
        .updateIn(['charts', 'secondGene'], () => fromJS(initialChartsState)),
  },
  initialState
);

export default reducer;
