import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

// Utils
import { formatDate, insertHTML } from '../../../Utils/Utils';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  source: PropTypes.string,
  pubDate: PropTypes.number,
  authors: PropTypes.instanceOf(Array),
  abstractInfo: PropTypes.string,
  impactFactor: PropTypes.string,
  distanceScore: PropTypes.number,
};

const SimilarPublicationItem = (props) => {
  const {
    id,
    title,
    source,
    pubDate,
    authors,
    abstractInfo,
    impactFactor,
    distanceScore,
  } = props;

  const first180Characters = useMemo(() => (
    abstractInfo ? (`${abstractInfo.substring(0, 180)} ...`) : ''
  ), [abstractInfo]);

  const date = useMemo(() => (
    pubDate ? formatDate(pubDate) : 'No date available'
  ), [pubDate]);

  return (
    <div className="similar-publication-item" key={id}>
      <div
        className="similar-publication-item__score"
        style={{ opacity: Math.max(distanceScore, 0.2) }}
      >
        <span className="similar-publication-item__score-label">
          Score:
        </span>
        <span className="similar-publication-item__score-text">
          {distanceScore.toFixed(2)}
        </span>
      </div>
      <div className="similar-publication-item__content">
        <Link
          to={`${RELATIVE_PATH}/publication-details/${id}`}
          target="blank"
          className="similar-publication-item__link title4"
          dangerouslySetInnerHTML={insertHTML(title)}
        />
        <div className="similar-publication-item__block">
          <span className="similar-publication-item__label">
            {'Source: '}
          </span>
          <span className="similar-publication-item__text">
            {source}
          </span>
        </div>
        <div className="similar-publication-item__block">
          <span className="similar-publication-item__label">
            {'Impact factor: '}
          </span>
          <span className="similar-publication-item__text">
            {impactFactor}
          </span>
        </div>
        <div className="similar-publication-item__block">
          <span className="similar-publication-item__label">
            {'Publication date: '}
          </span>
          <span className="similar-publication-item__text">
            {date}
          </span>
        </div>
        {
          authors && authors.length > 0 &&
          <div className="similar-publication-item__block">
            <span className="similar-publication-item__label">
              {'Authors: '}
            </span>
            <span className="similar-publication-item__text">
              {authors.join(', ')}
            </span>
          </div>
        }
        {
          abstractInfo &&
          <div className="similar-publication-item__block">
            <span className="similar-publication-item__label">
              {'Abstract info: '}
            </span>
            <span
              data-tip={abstractInfo}
              data-html={true}
              className="similar-publication-item__text"
              dangerouslySetInnerHTML={insertHTML(first180Characters)}
            />
            <ReactTooltip
              type="light"
              border={true}
              className="search-result-item__tooltip"
            />
          </div>
        }
      </div>
    </div>
  );
};

SimilarPublicationItem.propTypes = propTypes;

export default React.memo(SimilarPublicationItem);
