import { createSelector } from 'reselect';

import { PAGE_SIZE } from '../../constants';
import { sort, filterPaginatedData } from '../../../Utils/Utils';

const pageChapter = 'geneDetailsTherapeuticCandidatesChapter';
// Chapter
const existingDrugs = state => state.getIn([pageChapter, 'existingDrugs']);
const experimentalResults = state => state.getIn([pageChapter, 'experimentalResults']);
const chapterLoading = state => state.getIn([pageChapter, 'loading']);
const chapterError = state => state.getIn([pageChapter, 'error']);

export const getExistingDrugsData = createSelector(
  existingDrugs,
  (data) => {
    const {
      totalPages,
      pageNumber,
      sorting,
    } = data.toJS();
    const sortedData = sort(data.get('data'), sorting).toJS();
    return {
      data: filterPaginatedData(sortedData, pageNumber, PAGE_SIZE),
      allData: sortedData,
      sorting,
      pageNumber,
      totalPages,
    };
  }
);

export const getExperimentalResultsData = createSelector(
  experimentalResults,
  (data) => {
    const {
      totalPages,
      pageNumber,
      sorting,
    } = data.toJS();
    const sortedData = sort(data.get('data'), sorting).toJS();
    return {
      data: filterPaginatedData(sortedData, pageNumber, PAGE_SIZE),
      allData: sortedData,
      sorting,
      pageNumber,
      totalPages,
    };
  }
);

export const getChapterLoading = createSelector(
  chapterLoading,
  loading => loading
);

export const getChapterError = createSelector(
  chapterError,
  error => error
);

