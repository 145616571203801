import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import Sets from '../../../Sets/SetsList/Sets';

// Store
import * as s from '../../store/selectors';
import * as ACTIONS from '../../store/actions';

// Constants
import {checkProjectTab} from '../../../Projects/ProjectsTabs/utils';

// Styles
import './styles.scss';
import RadioInput from '../../../common/Inputs/RadioInput/RadioInput';
import {personalOption} from '../../../Projects/ProjectsTabs/constants';
import {CooccurrenceEnum, RELATIVE_PATH} from '../../../../constantsCommon';
import {getSelectedConceptSelector} from '../../store/selectors';
import {withRouter} from '../../../common/WithRouter/WithRouter';
import classNames from 'classnames';
import {requestRanking} from '../RankSelectionResult/actions';

const propTypes = {
  method: PropTypes.object,
  setSelectedSetInfo: PropTypes.func,
  selectedSetInfo: PropTypes.object,
  setRankTargetMethods: PropTypes.func,
  selectedConcept: PropTypes.object,
  navigate: PropTypes.func,
  closeCb: PropTypes.func,
  hideOptions: PropTypes.bool,
  currentConfig: PropTypes.object,
  requestRanking: PropTypes.func,
};

const RankTargetsConfig = (props) => {
  const {
    method,
    setSelectedSetInfo,
    selectedSetInfo,
    setRankTargetMethods,
    selectedConcept,
    closeCb,
    hideOptions = true,
    currentConfig,
    requestRanking,
  } = props;

  const [showSelectSetPopup, setShowSelectSetPopup] = useState(false);

  function selectSetId({ projectId, selectedSets }) {
    const { id, name } = selectedSets[0];
    const projectOrNull = checkProjectTab(projectId) ? projectId : null;

    setSelectedSetInfo({
      setId: id,
      projectId: projectOrNull || 'personal',
      setName: name,
    });
    setShowSelectSetPopup(false);
  }

  function gotoRankSelection() {
    closeCb();
    if (!currentConfig
      || selectedConcept.id != currentConfig.conceptId
      || selectedSetInfo.setId !== currentConfig.setId
      || selectedSetInfo.projectId !== currentConfig.projectId) {
      props.navigate(`${RELATIVE_PATH}/rank-selection/${selectedConcept.id}/${selectedSetInfo.setId}/${selectedSetInfo.projectId}`);
    } else {
      requestRanking();
    }
  }

  function getProjectTitle() {
    if (selectedSetInfo?.setName) {
      return selectedSetInfo.projectId ? selectedSetInfo.projectId : personalOption.label;
    } else {
      return '-';
    }
  }

  // eslint-disable-next-line no-unused-vars
  const rankingClickHandler = (key, value) => {
    method[key] = value;
    setRankTargetMethods(method);
  };

  const minimalConfigSelected = () => {
    return selectedSetInfo && selectedSetInfo.setId
      && Object.values(method).some(item => item === true)
      && selectedConcept && selectedConcept.id;
  };

  const optionsClass = classNames({
    'rank-target-params-panel': true,
    'rank-target-params-panel-hide': hideOptions,
  });

  return (
    <div className="rank-target-params">
      <button className="button button-secondary rank-selection-btn" onClick={() => setShowSelectSetPopup(true)}>
        Select Gene Set
      </button>
      <div className="concepts-search-sidebar__title ">
        <b>Name:</b> {selectedSetInfo.setName ? selectedSetInfo.setName : '-'}
      </div>
      <div className="concepts-search-sidebar__title ">
        <b>Project:</b> {getProjectTitle()}
      </div>
      <div className={optionsClass}>
        <div>
          <Checkbox
            id='checkbox-rank-target-classification'
            checked={method.classification}
            onChange={(e) => { rankingClickHandler('classification', e.target.checked); }}
          />
          <span>
            Classification
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-differentialExpression'
            checked={method.differentialExpression}
            onChange={(e) => { rankingClickHandler('differentialExpression', e.target.checked); }}
          />
          <span>
            Differential Expression
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-geneVariation'
            checked={method.geneVariation}
            onChange={(e) => { rankingClickHandler('geneVariation', e.target.checked); }}
          />
          <span>
            Genetic Variation
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-geneticInteractions'
            checked={method.geneticInteractions}
            onChange={(e) => { rankingClickHandler('geneticInteractions', e.target.checked); }}
          />
          <span>
            Protein Interactions
          </span>
        </div>

        <br/>

        <div>
          <Checkbox
            id='checkbox-rank-target-associationScore'
            checked={method.associationScore}
            onChange={(e) => { rankingClickHandler('associationScore', e.target.checked); }}
          />
          <span>
            Association score (0-100)
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-openTargetsScore'
            checked={method.openTargetsScore}
            onChange={(e) => { rankingClickHandler('openTargetsScore', e.target.checked); }}
          />
          <span>
            Open Targets Score
          </span>
        </div>

        <br/>

        <div>
          <Checkbox
            id='checkbox-rank-target-literatureSearch'
            checked={method.literatureSearch}
            onChange={(e) => { rankingClickHandler('literatureSearch', e.target.checked); }}
          />
          <span>
            Literature
          </span>
          <div className='rank-target-literature-radio'>
            <RadioInput
              name="conceptCategory"
              value="Abstract"
              checked={method.literatureSearchType === CooccurrenceEnum.ABSTRACT}
              onChange={() => { rankingClickHandler('literatureSearchType', CooccurrenceEnum.ABSTRACT); }}
              labelValue="Abstract"
              id="literatureSearchAbstract"
            />
            <br/>
            <RadioInput
              name="conceptCategory"
              value="Sentence"
              checked={method.literatureSearchType === CooccurrenceEnum.SENTENCE}
              onChange={() => { rankingClickHandler('literatureSearchType', CooccurrenceEnum.SENTENCE); }}
              labelValue="Sentence"
              id="literatureSearchSentence"
            />
            <br/>
            <RadioInput
              name="conceptCategory"
              value="Title"
              checked={method.literatureSearchType === CooccurrenceEnum.TITLE}
              onChange={() => { rankingClickHandler('literatureSearchType', CooccurrenceEnum.TITLE); }}
              labelValue="Title"
              id="literatureSearchTitle"
            />
          </div>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-druggableStructure'
            checked={method.druggableStructure}
            onChange={(e) => { rankingClickHandler('druggableStructure', e.target.checked); }}
          />
          <span>
            Druggable Structure
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-targetValidationStage'
            checked={method.targetValidationStage}
            onChange={(e) => { rankingClickHandler('targetValidationStage', e.target.checked); }}
          />
          <span>
            Target Validation Stage
          </span>
        </div>
        <div>
          <Checkbox
            id='checkbox-rank-target-compoundScore'
            checked={method.compoundScore}
            onChange={(e) => { rankingClickHandler('compoundScore', e.target.checked); }}
          />
          <span>
            Associated Compounds (&lt;100nm)
          </span>
        </div>
      </div>
      <button
        className="button button-secondary get-ranking-btn"
        onClick={() => gotoRankSelection()}
        disabled={!minimalConfigSelected()}
      >
        Get ranking
      </button>


      {
        showSelectSetPopup &&
        <ModalComponent
          isOpen={showSelectSetPopup}
          closeCb={() => setShowSelectSetPopup(false)}
        >
          <Sets
            selectFunction={selectSetId}
            closeFunction={() => setShowSelectSetPopup(false)}
          />
        </ModalComponent>
      }
    </div>
  );
};

RankTargetsConfig.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    method: s.getRankSelctionMethodSelector(state),
    selectedSetInfo: s.getSelectedSetInfoSelector(state),
    selectedConcept: getSelectedConceptSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSetInfo(data) {
      dispatch(ACTIONS.setSelectedSetInfoAction(data));
    },
    setRankTargetMethods(data) {
      dispatch(ACTIONS.setRankTargetMethodsAction(data));
    },
    requestRanking() {
      dispatch(requestRanking());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RankTargetsConfig));
