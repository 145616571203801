import { createSelector } from 'reselect';

const locationHistory = state => state.getIn(['locationReducer', 'locationHistory']);
const openedChatBotSelector = state => state.getIn(['locationReducer', 'openedChatBot']);

export const getLocationHistorySelector = createSelector(
  locationHistory,
  data => data.toJS()
);

export const getOpenedChatBot = createSelector(
  openedChatBotSelector,
  data => data
);
