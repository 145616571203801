import * as d3js from 'd3';
import { capitalizeFirstLetter } from '../../Utils/Utils';
import { colors, graphTypes, sortingEnums, sortingHpaByTissuesEnums, sortingHpaEnums } from './constants';

export function prepareHealthCellData(healthCellData) {
  const categories = Object.keys(healthCellData);

  const data = categories.reduce((d, c, i, arr) => {
    const items = healthCellData[c];

    d.cellTypeOptions.push({
      type: c,
      value: c,
      label: capitalizeFirstLetter(c),
      median: Number(items[0].maxMedian).toFixed(2),
    });

    items.forEach((item) => {
      const { concept, group } = item;
      const name = capitalizeFirstLetter(concept.name);
      d.healthCellExpression.push({
        ...item,
        concept: {
          ...item.concept,
          name,
        },
      });

      item.points.forEach((p) => {
        d.healthCellExpressionChartData.push({
          name,
          measure: p,
          category: group,
        });
      });
    });

    if (i + 1 === arr.length) {
      d.cellTypeOptions.sort((a, b) => a.value.localeCompare(b.value));
    }

    return d;
  }, {
    cellTypeOptions: [],
    healthCellExpression: [],
    healthCellExpressionChartData: [],
  });

  return data;
}

export function sortExpressionData(data, sortingOption) {
  const expressionData = [...data];
  switch (sortingOption.type) {
    case sortingEnums.DEFAULT:
      return expressionData;
    case sortingEnums.MEDIAN_EXPRESSION:
      return expressionData.sort((a, b) => a.summary.median - b.summary.median);
    case sortingEnums.PAIRED: {
      const uniqueIdentifiers = expressionData
        .map(item => item.controlledUniqueIdentifier)
        .filter((el, idx, arr) => arr.indexOf(el) === idx);

      return uniqueIdentifiers
        .reduce((acc, identifier) => {
          acc.push(expressionData.filter(item => item.controlledUniqueIdentifier === identifier));
          return acc;
        }, [])
        .sort((a, b) => a[0].concept.name.localeCompare(b[0].concept.name))
        .flat();
    }
    default:
      return expressionData;
  }
}

export function sortHpaData(data, sortingOption, key) {
  const expressionData = [...data];

  switch (sortingOption.type) {
    case sortingHpaEnums.ALPHABETICAL:
      return expressionData.sort((a, b) => a[key].localeCompare(b[key]));
    case sortingHpaByTissuesEnums.BY_TISSUES_EXPRESSION:
      // eslint-disable-next-line no-case-declarations
      const expressionDataByTissues = expressionData.reduce((acc, i) => {
        if (acc[i.tissueName]) {
          acc[i.tissueName] = [...acc[i.tissueName], i];
        } else {
          acc[i.tissueName] = [i];
        }
        return acc;
      }, {});
      return Object.values(expressionDataByTissues)
        .sort((a, b) => Math.max.apply(null, a.map(t => t.ntpm )) - Math.max.apply(null, b.map(t => t.ntpm )))
        .flat(2);

    case sortingHpaEnums.BY_EXPRESSION:
      return expressionData.sort((a, b) => b.ntpm - a.ntpm);
    case sortingHpaEnums.BY_EXPRESSION_LOW:
      return expressionData.sort((a, b) => a.ntpm - b.ntpm);
    case sortingHpaEnums.PER_CELL_TYPE_GROUP:
      return expressionData.sort((a, b) => a.cellTypeGroup.localeCompare(b.cellTypeGroup));
    default:
      return expressionData;
  }
}

export function sortGroupedExpressionData(data, sortingOption) {
  const groupedExpressionData = [...data];
  switch (sortingOption.type) {
    case sortingEnums.DEFAULT:
      return groupedExpressionData.sort((b, c) => b.concept.localeCompare(c.concept));
    case sortingEnums.MEDIAN_EXPRESSION:
      return groupedExpressionData.sort((b, c) => b.values[1].summary.median - c.values[1].summary.median);
    default:
      return groupedExpressionData;
  }
}

export function prepareViolinChartData(data, type, violinPlotTransform, includeZeros = false, filterZeros = false) {
  if (!data.length) return [];
  let chartData = [];
  const noSummary = type === 'SINGLE_CELL' || type === 'CPTAC';

  const getPoints = (value) => {
    if (includeZeros) {
      return Array(value.zeros).fill(0)
        .concat(value.points.sort((a, b) => a - b));
    }

    if (filterZeros) {
      return value.points.filter(p => p !== 0)
        .sort((a, b) => a - b);
    }

    return value.points.sort((a, b) => a - b);
  };

  if (violinPlotTransform !== 'log') {
    chartData = data.map(value => ({
      ...value,
      points: getPoints(value),
    }));
  } else if (type === graphTypes.CCLE_PROTEOMICS) {
    chartData = data.map(value => ({
      ...value,
      points: getPoints(value).map(point => Math.log2(point + 1)),
    }));
  } else if (violinPlotTransform === 'log') {
    chartData = data.map(value => ({
      ...value,
      points: getPoints(value).map(point => Math.log2(point + 1)),
      summary: {
        ...value.summary,
        min: Math.log2(value.summary.min + 1),
        max: Math.log2(value.summary.max + 1),
        median: Math.log2(value.summary.median + 1),
        q1: Math.log2(value.summary.q1 + 1),
        q3: Math.log2(value.summary.q3 + 1),
      },
    }));
  }

  function getSummaryObject(points) {
    return {
      min: d3js.min(points),
      max: d3js.max(points),
      median: d3js.median(points),
      q1: d3js.quantile(points, 0.25),
      q3: d3js.quantile(points, 0.75),
    };
  }

  function calcMetrics(el) {
    const { points, colors } = el;
    const cData = {
      extremes: [],
      outliers: [],
    };

    const summary = noSummary ?
      getSummaryObject(points) :
      el.summary;

    const iqr = summary.q3 - summary.q1;
    const lowerOuterFence = summary.q1 - (3 * iqr);
    const upperOuterFence = summary.q3 + (3 * iqr);
    const lowerInnerFence = summary.q1 - (1.5 * iqr);
    const upperInnerFence = summary.q3 + (1.5 * iqr);

    const scatter = () => {
      const range = 10;
      return Math.floor(Math.random() * range) - (range / 2);
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= points.length; i++) {
      const point = points[i];

      if (point < lowerInnerFence) {
        if (point < lowerOuterFence) cData.extremes.push({ point, colors });
        else cData.outliers.push({ point, colors, scatterNumber: scatter() });
      } else if (point > upperInnerFence) {
        if (point > upperOuterFence) cData.extremes.push({ point, colors });
        else cData.outliers.push({ point, colors, scatterNumber: scatter() });
      }

      if (noSummary) {
        if (point < summary.min) {
          summary.min = point;
        } else if (point > summary.max) {
          summary.max = point;
        }
      }
    }

    return {
      ...el,
      ...cData,
      summary,
    };
  }

  if (noSummary) {
    return chartData.map(d => calcMetrics(d));
  } else {
    return chartData.filter(d => d.summary).map(d => calcMetrics(d));
  }
}

// a solution for repeated sample names
export function proposingName(data, name) {
  let newName = name;
  let i = 0;

  do {
    i += 1;
    newName = `${name}__duplicated__${i}`;
    // eslint-disable-next-line no-loop-func
  } while (data.find(item => item.name === newName));

  return newName;
}

export function transformExpressionLinesData(linesData, cellLinesKey, measureKey = 'score') {
  return linesData.map((item) => {
    const points = item[cellLinesKey].map(line => line[measureKey]);

    function getSummaryObject() {
      return {
        min: d3js.min(points),
        max: d3js.max(points),
        median: d3js.median(points),
        q1: d3js.quantile(points, 0.25),
        q3: d3js.quantile(points, 0.75),
      };
    }

    return ({
      ...item,
      points,
      summary: getSummaryObject(),
      samplesData: item[cellLinesKey],
      cellLinesIncluded: item.cellLinesIncluded,
      concept: { ...item.concept, name: capitalizeFirstLetter(item.concept.name) },
    });
  }
  );
}

export function scaleTransformData(dataTransform, data, measureKey, measureKey2) {
  if (dataTransform === 'log') {
    return data.map(item => ({
      ...item,
      [measureKey]: +Math.log2(+item[measureKey] + 1).toFixed(2),
      [measureKey2]: +Math.log2(+item[measureKey2] + 1).toFixed(2),
    }));
  }

  return data.map(item => ({
    ...item,
    [measureKey]: +item[measureKey],
    [measureKey2]: +item[measureKey2],
  }));
}

export function getCsvName(type, geneName, uniprotId, selectedScores) {
  switch (type) {
    case graphTypes.GTEX: {
      return `RNAseq_from_GTEx_${geneName}_selection`;
    }
    case graphTypes.CCLE: {
      return `RNA_expression_(TPM)_in_cancer_cell_lines_from_CCLE_chart_${geneName}_selection`;
    }
    case graphTypes.CCLE_PROTEOMICS: {
      return `Protein_expression_in_cancer_cell_lines_from_CCLE_${uniprotId}_${geneName}_selection`;
    }
    case graphTypes.SANGER_CELL_MODEL: {
      return `Protein_expression_in_cancer_cell_lines_from_Sanger_Cell_Model_Passports_${uniprotId}_${selectedScores}_${geneName}_selection`;
    }
    case graphTypes.CPTAC: {
      return `Protein_expression_in_tumor_and_healthy_tissue_from_CPTAC_${geneName}_selection`;
    }
    case graphTypes.CELL_TYPE: {
      return `RNA_expression_in_healthy_cells_${geneName}_selection`;
    }
    case graphTypes.TCGA: {
      return `RNA_expression_in_tumor_and_healthy_tissue_from_TCGA_${geneName}_selection`;
    }
    case graphTypes.HPA_PER_CELL_TYPE: {
      return `Single_cell_type_expression_per_cell_types_from_Human_Protein_Atlas_${geneName}_selection`;
    }
    case graphTypes.HPA_PER_TISSUES: {
      return `Single_cell_type_expression_per_tissues_from_Human_Protein_Atlas_${geneName}_selection`;
    }
    default:
      return '';
  }
}

export function getChartName(type, geneName) {
  switch (type) {
    case graphTypes.GTEX: {
      return 'RNAseq_from_GTEx';
    }
    case graphTypes.CCLE: {
      return 'RNA_expression_(TPM)_in_cancer_cell_lines_from_CCLE_chart';
    }
    case graphTypes.CCLE_PROTEOMICS: {
      return 'Protein_expression_in_cancer_cell_lines_from_CCLE_chart';
    }
    case graphTypes.SANGER_CELL_MODEL: {
      return 'Protein_expression_in_cancer_cell_lines_from_Sanger_Cell_Model_Passports_chart';
    }
    case graphTypes.CELL_TYPE: {
      return 'RNA_expression_in_healthy_cells';
    }
    case graphTypes.TCGA: {
      return 'RNA_expression_in_tumor_and_healthy_tissue_from_TCGA_chart';
    }
    case graphTypes.HPA_PER_CELL_TYPE: {
      return `Single_cell_type_expression_per_cell_types_from_Human_Protein_Atlas_${geneName}`;
    }
    case graphTypes.HPA_PER_TISSUES: {
      return 'Single_cell_type_expression_per_tissues_from_Human_Protein_Atlas';
    }
    default:
      return '';
  }
}

export function getChartTitle(type, geneName) {
  switch (type) {
    case graphTypes.GTEX: {
      return `RNAseq from GTEx for ${geneName}`;
    }
    case graphTypes.CCLE: {
      return `RNA expression (TPM) in cancer cell lines from CCLE chart for ${geneName}`;
    }
    case graphTypes.CCLE_PROTEOMICS: {
      return `Protein expression in cancer cell lines from CCLE chart for ${geneName}`;
    }
    case graphTypes.SANGER_CELL_MODEL: {
      return `Protein expression in cancer cell lines from Sanger Cell Model Passports for ${geneName}`;
    }
    case graphTypes.CPTAC: {
      return `Protein expression in tumor and healthy tissue from CPTAC for ${geneName}`;
    }
    case graphTypes.CELL_TYPE: {
      return `${geneName} expression in healthy cells`;
    }
    case graphTypes.TCGA: {
      return `RNA expression in tumor and healthy tissue from TCGA for ${geneName}`;
    }
    case graphTypes.HPA_PER_CELL_TYPE: {
      return `Single cell type expression per cell types from Human Protein Atlas for ${geneName}`;
    }
    case graphTypes.HPA_PER_TISSUES: {
      return `Single cell type expression per tissues from Human Protein Atlas for ${geneName}`;
    }
    default:
      return '';
  }
}

export function prepareUniqueObjectData(data, dataKey) {
  return data.reduce((acc, item) => {
    if (acc.includes(item[dataKey])) return acc;
    return [...acc, item[dataKey]];
  }, []).sort((a, b) => a.localeCompare(b));
}

export function prepareDataGroupsWithColor(data) {
  return data.reduce((acc, item, idx) => {
    acc[item] = { color: colors[idx], name: item };
    return acc;
  }, {});

}

export function getIsSamplesDataExist(type) {
  return type === graphTypes.CCLE ||
    type === graphTypes.CCLE_PROTEOMICS ||
    type === graphTypes.SANGER_CELL_MODEL ||
    type === graphTypes.HPA_PER_TISSUES ||
    type === graphTypes.CPTAC;
}
