export const GENE_DISEASE_INIT_REQUESTED = 'GENE_DISEASE_INIT_REQUESTED';
export const GENE_DISEASE_INIT_SUCCEEDED = 'GENE_DISEASE_INIT_SUCCEEDED';
export const GENE_DISEASE_INIT_FAILED = 'GENE_DISEASE_INIT_FAILED';
export const GENE_DISEASE_RESET_STATE = 'GENE_DISEASE_RESET_STATE';
export const GENE_DISEASE_LOAD_CHAPTER = 'GENE_DISEASE_LOAD_CHAPTER';
export const GENE_DISEASE_UNLOAD_CHAPTERS = 'GENE_DISEASE_UNLOAD_CHAPTERS';
export const GENE_DISEASE_SET_LAST_VISITED_GEN_ID = 'GENE_DISEASE_SET_LAST_VISITED_GEN_ID';

export const GENE_DISEASE_FETCH_TISSUES_REQUEST = 'GENE_DISEASE_FETCH_TISSUES_REQUEST';
export const GENE_DISEASE_FETCH_TISSUES_FAILURE = 'GENE_DISEASE_FETCH_TISSUES_FAILURE';
export const GENE_DISEASE_FETCH_TISSUES_SUCCESS = 'GENE_DISEASE_FETCH_TISSUES_SUCCESS';
export const GENE_DISEASE_TISSUES_LOADER = 'GENE_DISEASE_TISSUES_LOADER';
export const GENE_DISEASE_SET_CHAPTER_DATA = 'GENE_DISEASE_SET_CHAPTER_DATA';
