import {
  GENE_DISEASE_INIT_REQUESTED,
  GENE_DISEASE_RESET_STATE,
  GENE_DISEASE_FETCH_TISSUES_REQUEST,
  GENE_DISEASE_LOAD_CHAPTER,
  GENE_DISEASE_UNLOAD_CHAPTERS,
  GENE_DISEASE_SET_LAST_VISITED_GEN_ID,
  GENE_DISEASE_TISSUES_LOADER,
  GENE_DISEASE_SET_CHAPTER_DATA,
} from './constants';

export const initPageAction = data => (
  {
    type: GENE_DISEASE_INIT_REQUESTED,
    data,
  }
);

export const geneDiseaseFetchTissuesRequesAction = data => (
  {
    type: GENE_DISEASE_FETCH_TISSUES_REQUEST,
    data,
  }
);

export const resetGeneDiseaseAction = () => (
  {
    type: GENE_DISEASE_RESET_STATE,
  }
);

export const loadNextChapterAction = payload => (
  {
    type: GENE_DISEASE_LOAD_CHAPTER,
    payload,
  }
);

export const unloadChaptersAction = () => (
  {
    type: GENE_DISEASE_UNLOAD_CHAPTERS,
  }
);

export const geneDiseaseSetLastVisitedGenIdAction = data => (
  {
    type: GENE_DISEASE_SET_LAST_VISITED_GEN_ID,
    data,
  }
);

export const geneDiseaseTissuesLoaderAction = data => (
  {
    type: GENE_DISEASE_TISSUES_LOADER,
    data,
  }
);

export const geneDiseaseSetChapterAction = data => (
  {
    type: GENE_DISEASE_SET_CHAPTER_DATA,
    data,
  }
);
