import React from 'react';
import PropTypes from 'prop-types';

import {
  filterCriteria,
} from '../ComplexSetView/enums';

import './ComplexSetFilterModal.css';

// const filterCriteriaKeys = Object.keys(filterCriteria);

const propTypes = {
  columnsConfiguration: PropTypes.instanceOf(Array),
  availableColumns: PropTypes.instanceOf(Array),
  handleSubmit: PropTypes.func,
  closeHandler: PropTypes.func,
};

class ComplexSetFilterModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      columnsConfiguration = [],
    } = this.props;

    // Defining default value for the operation field in state based on default first column
    const column = columnsConfiguration.find(item => item.colNumber === this.props.availableColumns[0].value); // eslint-disable-line
    const columnType = column.type;
    const defaultOperation = filterCriteria[columnType][Object.keys(filterCriteria[columnType])[0]];

    // Default values for the filter
    this.state = {
      columnNumber: this.props.availableColumns[0].value,
      operation: defaultOperation, //eslint-disable-line
      excluded: false, //eslint-disable-line
      value: '', //eslint-disable-line
    };
  }

  render() {
    const {
      // from parent
      availableColumns,
      handleSubmit,
      columnsConfiguration = [],
    } = this.props;

    // Getting list of available filter criterias based on selected column
    const column = columnsConfiguration.find(item => item.colNumber === this.state.columnNumber);
    const columnType = column.type;
    const availableOperationOptionsObject = filterCriteria[columnType];

    const availableOperationOptions = Object.keys(availableOperationOptionsObject).map((item, itemIndex) => (
      <option
        value={availableOperationOptionsObject[item]}
        key={`operation_options_item_${itemIndex}`}
      >
        {item}
      </option>
    ));

    return (
      <div className="complex-set-filter-modal-root">
        <div className="complex-set-filter-modal-content">
          <div className="page-title">
            Filter Set
          </div>
          <div
            className="close-button"
            onClick={
              () => this.props.closeHandler()
            }
          >
            <svg
              width="40"
              height="40"
            >
              <circle cx="20" cy="20" r="20" fill="black" />
              <g transform="rotate(45 20 20)">
                <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
                <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
              </g>
            </svg>
          </div>
          <div className="main-wrapper">
            <div className="labels">
              <div className="element">Column name:</div>
              <div className="element">Excluded</div>
              <div className="element">Operation</div>
              <div className="element">Value</div>
            </div>
            <div className="controls">
              <div className="element">
                <select
                  onChange={(e) => {
                    // Changing default value for the operation depending on what column was selected
                    const castedValue = Number(e.target.value);
                    const _column = columnsConfiguration.find(item => item.colNumber === castedValue);
                    const _columnType = _column.type;
                    const operationToSet = filterCriteria[_columnType][Object.keys(filterCriteria[_columnType])[0]];
                    this.setState({
                      columnNumber: castedValue,
                      operation: operationToSet, //eslint-disable-line
                      type: columnType,
                    });
                  }}
                >
                  {
                    availableColumns.map((availableColumn, index) => (
                      <option
                        value={availableColumn.value}
                        key={`${availableColumn.label}__${index}`}
                      >
                        {availableColumn.label}
                      </option>
                    ))
                  }
                </select>
              </div>
              <div className="element">
                <select
                  defaultValue={false}
                  onChange={e => this.setState({
                    excluded: e.target.value === 'true', //eslint-disable-line
                  })}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className="element">
                <select
                  onChange={e => this.setState({
                    operation: e.target.value, //eslint-disable-line
                  })}
                >
                  {availableOperationOptions}
                </select>
              </div>
              <div className="element">
                <input
                  onChange={e => this.setState({
                    value: this.state.type === 'NUMBER' ? Number(e.target.value) : e.target.value, //eslint-disable-line
                  })}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => handleSubmit(this.state)}
            >
              Add filter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ComplexSetFilterModal.propTypes = propTypes;

export default ComplexSetFilterModal;
