export const CELL_LINES_CONFIGURATION_FORM = 'CELL_LINES_CONFIGURATION_FORM';

export const HeatmapMeasureEnum = {
  RNA: 'rna',
  PROTEIN: 'protein',
  SANGER: 'sanger'
};

export const HeatMapMeasuresOptions = [
  {
    type: HeatmapMeasureEnum.RNA,
    value: HeatmapMeasureEnum.RNA,
    label: 'CCLE RNA expression',
  },
  {
    type: HeatmapMeasureEnum.PROTEIN,
    value: HeatmapMeasureEnum.PROTEIN,
    label: 'CCLE Protein expression',
  },
  {
    type: HeatmapMeasureEnum.SANGER,
    value: HeatmapMeasureEnum.SANGER,
    label: 'SCMP protein expression',
  },
];
