import React from 'react';
import PropTypes from 'prop-types';

// Components
import BispecificAntibodyTable from './Components/BispecificAntibodyTable';
import BispecificAntibodyForm from './Components/BispecificAntibodyForm';

const propTypes = {
  selectedAlgorithmOption: PropTypes.instanceOf(Object),
};

const BispecificAntibody = (props) => {
  const { selectedAlgorithmOption } = props;

  return (
    <div className="algorithm">
      <div className="algorithm__wrap">
        <div className="algorithm__container">
          <BispecificAntibodyForm name={selectedAlgorithmOption.value} />
        </div>
      </div>
      <div className="algorithm__container">
        <BispecificAntibodyTable />
      </div>
    </div>
  );
};

BispecificAntibody.propTypes = propTypes;

export default BispecificAntibody;
