import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

// Styles
import './RadioInput.scss';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  labelValue: PropTypes.string,
  labelCls: PropTypes.string,
  className: PropTypes.string,
  noLabel: PropTypes.bool,
  wrapClassName: PropTypes.string,
};

const RadioInput = (props) => {
  const {
    id,
    value,
    onChange,
    labelValue,
    labelCls,
    className,
    noLabel = false,
    wrapClassName,
    ...restInput
  } = props;

  const uniqueRadioId = useMemo(() => id || uuidv4(), [id]);

  const inputClassName = classNames(
    'radio-input',
    { 'radio-input_no-margin': noLabel },
    className,
  );

  return (
    <div className={classNames('radio__wrapper', wrapClassName)}>
      <input
        type="radio"
        id={uniqueRadioId}
        value={value}
        onChange={onChange}
        className={inputClassName}
        {...restInput}
      />
      <label htmlFor={uniqueRadioId} className={labelCls} />
      {
        labelValue &&
        <span>{labelValue}</span>
      }
    </div>
  );
};

RadioInput.propTypes = propTypes;

export default React.memo(RadioInput);
