import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import isEmpty from 'lodash.isempty';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ExportTable from '../../common/ExportTable/ExportTable';
// Store
import * as s from './redux/selectors';
import * as a from './redux/reducer';
import { loadNextChapterAction } from '../actions';
// Utils
import { getParentBlockWidth, AVERAGE_SYMBOL_LENGTH } from '../../Utils/Utils';
import { helpText } from './helpText';
// Constants
import { PAGE_SIZE } from './constants';

const propTypes = {
  sort: PropTypes.func,
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  similarProteins: PropTypes.instanceOf(Array),
  allSimilarProteins: PropTypes.instanceOf(Array),
  parentClass: PropTypes.string,
  sorting: PropTypes.instanceOf(Object),
  getSimilarProteinsData: PropTypes.func,
  similarProteinsLoading: PropTypes.bool,
  similarProteinsError: PropTypes.string,
  tableWidth: PropTypes.number,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

const SimilarProteinsTable = (props) => {
  const {
    sort,
    gene,
    page,
    totalPages,
    geneName,
    changePage,
    loadNextChapter,
    parentClass,
    tableWidth,
    sorting: {
      sortBy,
      sortDirection,
    },
    itsNewConcept,
    similarProteins,
    allSimilarProteins,
    similarProteinsError,
    getSimilarProteinsData,
    similarProteinsLoading,
  } = props;
  const simpleTableRef = useRef(null);

  useEffect(() => {
    if (itsNewConcept || isEmpty(similarProteins)) {
      getSimilarProteinsData({ id: gene });
    } else {
      loadNextChapter('orthologs');
    }
  }, []);

  useEffect(() => {
    if (simpleTableRef && simpleTableRef.current) {
      simpleTableRef.current.recomputeRowHeights();
    }
  }, [similarProteins]);

  const getColumnPercentWidth = (percent) => {
    const screenWidth = tableWidth || getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  const getRowHeight = ({ index }) => {
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = getColumnPercentWidth(10);
    const string = similarProteins[index].uniprotId.length;
    const stringRows = Math.ceil((string * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    return (stringRows * lineHeight) + padding;
  };

  const handlePageClick = (page) => {
    changePage(page.selected);
  };

  const renderTableHeader = (label, dataKey, headerSortBy = sortBy, headerSortDirection = sortDirection) => {
    const ascClasses = classNames({
      'fa fa-sort-asc': true,
      active: headerSortBy === dataKey && headerSortDirection === 'ASC',
    });
    const descClasses = classNames({
      'fa fa-sort-desc': true,
      active: headerSortBy === dataKey && headerSortDirection === 'DESC',
    });

    return (
      <div className="header-section">
        <span
          className="ReactVirtualized__Table__headerTruncatedText ReactVirtualized__Table__sortableHeaderColumn vertical-align-middle"
          title={label}
        >
          {label}
        </span>
        <ReactTooltip
          place="top"
          type="light"
          event="mouseover"
          eventOff="mouseout"
          className="similar-proteins__tooltip"
          id={`similar-proteins__${dataKey}-tooltip`}
        >
          { helpText[dataKey] }
        </ReactTooltip>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          data-tip={true}
          data-for={`similar-proteins__${dataKey}-tooltip`}
        />
        <span className="sorting-section">
          <i
            className={ascClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'ASC' })}
          />
          <i
            className={descClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'DESC' })}
          />
        </span>
      </div>
    );
  };

  const getColumns = () => {
    return [
      {
        label: 'Uniprot ID',
        labelForCSV: 'Human Uniprot ID',
        dataKey: 'uniprotId',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: getColumnPercentWidth(10),
        exportCSV: true,
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Species',
        dataKey: 'species',
        className: 'table-wrap__cell',
        width: getColumnPercentWidth(26),
        exportCSV: true,
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Uniprot ID',
        labelForCSV: 'Ortholog Uniprot ID',
        dataKey: 'orthologUniprotId',
        className: 'table-wrap__cell',
        width: getColumnPercentWidth(16),
        exportCSV: true,
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Identity',
        dataKey: 'identity',
        exportCSV: true,
        width: getColumnPercentWidth(12),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (rowData.identity !== null ? `${rowData.identity}%` : '-'),
        csvRenderer: identity => (identity !== null ? identity : '-'),
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Similarity',
        dataKey: 'similarity',
        exportCSV: true,
        width: getColumnPercentWidth(12),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (rowData.similarity !== null ? `${rowData.similarity}%` : '-'),
        csvRenderer: similarity => (similarity !== null ? similarity : '-'),
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Identity (EC)',
        dataKey: 'identityEC',
        exportCSV: true,
        width: getColumnPercentWidth(12),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (rowData.identityEC !== null ? `${rowData.identityEC}%` : '-'),
        csvRenderer: identityEC => (identityEC !== null ? identityEC : '-'),
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
      {
        label: 'Similarity (EC)',
        dataKey: 'similarityEC',
        exportCSV: true,
        width: getColumnPercentWidth(12),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (rowData.similarityEC !== null ? `${rowData.similarityEC}%` : '-'),
        csvRenderer: similarityEC => (similarityEC !== null ? similarityEC : '-'),
        headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      },
    ];
  };

  const tableSettings = {
    height: 500,
    width: tableWidth || getParentBlockWidth(parentClass),
    headerHeight: 50,
    rowHeight: getRowHeight,
    rowClassName: 'table-wrap__row',
    autoHeight: true,
    sortBy,
    sortDirection,
  };

  return (
    <div className="similar-proteins__chapter">
      {
        similarProteins && similarProteins.length > 0 && !similarProteinsLoading &&
          <div className="table-wrap">
            {
              <div className="controls-block-2items">
                {
                  allSimilarProteins.length > PAGE_SIZE &&
                  <div className="paginationContainer">
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={page}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                }
                {
                  allSimilarProteins && allSimilarProteins.length > 0 &&
                  <ExportTable
                    content={allSimilarProteins}
                    columns={getColumns()}
                    fileName={`${geneName}-similar-proteins`}
                  />
                }
              </div>
            }
            <div
              className="similar-proteins__table"
            >
              <div
                className="similar-proteins__table-header"
                style={{ width: tableWidth || getParentBlockWidth(parentClass) }}
              >
                <div
                  className="similar-proteins__table-header-cell"
                  style={{ width: getColumnPercentWidth(20) + 10 }}
                >
                  HUMAN PROTEIN
                </div>
                <div
                  className="similar-proteins__table-header-cell"
                  style={{ width: getColumnPercentWidth(20) }}
                >
                  SPECIES
                </div>
                <div
                  className="similar-proteins__table-header-cell"
                  style={{ width: getColumnPercentWidth(20) }}
                >
                  ORTHOLOG REFERENCES
                </div>
                <div
                  className="similar-proteins__table-header-cell"
                  style={{ width: getColumnPercentWidth(40) }}
                >
                  IDENTITY/SIMILARITY
                </div>
              </div>
              <SimpleTable
                innerRef={simpleTableRef}
                autoSize={true}
                settings={tableSettings}
                data={similarProteins}
                columns={getColumns()}
                sortAction={a.similarProteinsSortAction}
                dynamicHeight={true}
              />
            </div>
          </div>
      }
      {
        similarProteins.length === 0 && !similarProteinsLoading &&
          <span className="similar-proteins__no-data">
            There is no data to display
          </span>
      }
      <Loader isLoading={similarProteinsLoading} />
      <Error show={!!similarProteinsError} error={similarProteinsError} />
    </div>
  );
};

SimilarProteinsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    similarProteins: s.getSimilarProteins(state),
    allSimilarProteins: s.getAllSimilarProteinsSelector(state),
    sorting: s.getSimilarProteinsSorting(state),
    similarProteinsLoading: s.getChapterLoading(state),
    page: s.getSimilarProteinsPageSelector(state),
    totalPages: s.getSimilarProteinsTotalPagesSelector(state),
    similarProteinsError: s.getChapterError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sort(data) {
      dispatch(a.similarProteinsSortAction(data));
    },
    getSimilarProteinsData(data, exportData) {
      dispatch(a.getSimilarProteinsAction(data, exportData));
    },
    changePage(data) {
      dispatch(a.changeSimilarProteinsPageAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimilarProteinsTable);
