import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import SetNewPasswordForm from './SetNewPasswordForm';

// Actions
import { setNewPasswordModalCloseAction, submitNewPasswordAction } from './store/reducer';
import { getActivateUserCheckError, getActivateUserCheckTokenError } from './store/selectors';

// Utils
import { history } from '../../../index';
import { RELATIVE_PATH } from '../../../constantsCommon';

// Styles
import './SetNewPassword.scss';

const propTypes = {
  error: PropTypes.string,
  tokenError: PropTypes.string,
  submitNewPassword: PropTypes.func,
  closeSetNewPasswordModal: PropTypes.func,
};

const RestorePassword = (props) => {
  const {
    submitNewPassword,
    error,
    tokenError,
    closeSetNewPasswordModal,
  } = props;

  const submitNewPasswordForm = () => {
    submitNewPassword();
  };

  return (
    <div>
      {
        !tokenError.length > 0 ?
          <>
            <div className="flex-grid">
              <div className="col-1 text-left">
                <h3 className="title3 text-center">New Password</h3>
              </div>
            </div>
            <div className="flex-grid">
              <div className="col-1 text-left">
                {error &&
                <div className="error-text text-center">Error occurs. Please, try again.</div>
                }
                <SetNewPasswordForm
                  onSubmit={submitNewPasswordForm}
                />
              </div>
            </div>
          </> :
          <div className="error-content">
            <p className="error-text">{tokenError}</p>
            <button
              className="button button-primary mt-15"
              onClick={
                () => {
                  history.replace(`${RELATIVE_PATH}/login`);
                  closeSetNewPasswordModal();
                }
              }
            >
              Go to login page
            </button>
          </div>
      }
    </div>
  );
};

RestorePassword.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: getActivateUserCheckError(state),
    tokenError: getActivateUserCheckTokenError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitNewPassword() {
      dispatch(submitNewPasswordAction());
    },
    closeSetNewPasswordModal() {
      dispatch(setNewPasswordModalCloseAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestorePassword);
