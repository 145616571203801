import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  getFuncCharacterizationChartDataAction,
  funcCharacterizationChartIsFailed,
  funcCharacterizationChartLoader,
} from '../../actions';

export function* initChartWorker(action) {
  try {
    funcCharacterizationChartLoader(true);
    const { id } = action.data;
    const response = yield call(Api.geneDetailFunctionalCharacterization, { id });
    yield put(getFuncCharacterizationChartDataAction(response));
    funcCharacterizationChartLoader(false);
  } catch (e) {
    yield put(funcCharacterizationChartIsFailed(e.message));
  }
}
