export const helpText = {
  uniprotId: 'The human Uniprot ID',
  humanUniprotAccession: 'The human Uniprot accession number',
  humanEnsemblTranscriptId: 'The human Ensembl transcript ID (There can be more than one transcript ID. Sometimes a' +
    'protein sequence could be mapped to more than one Ensembl transcript ID or even sometimes to more than one' +
    'Ensembl gene ID).',
  species: 'The species for the matching ortholog (this can be "Macaca mulatta (rhesus macaque)", "Macaca fascicularis' +
    '(crab-eating macaque)", "Mus musculus (mouse)", "Rattus norvegicus (rat)", or "Oryctolagus Cuniculus (rabbit)). ',
  orthologEnsemblTranscriptId: 'The Ortholog Ensembl transcript ID',
  orthologUniprotAccession: 'The Ortholog Uniprot accession number',
  omaId: 'The Ortholog OMA ID',
  identity: 'The identity score between the human-species ortholog pair for the whole human protein.',
  similarity: 'The similarity score between the human-species ortholog pair for the whole human protein.',
  identityEC: 'The identity score between the human-species ortholog pair for the extracellular domain of the human protein.',
  similarityEC: 'The similarity score between the human-species ortholog pair for the extracellular domain of the human protein.',
  predictedByOma: 'The ortholog pair was predicted by the datasource OMA.',
  predictedByEnsembl: 'The ortholog pair was predicted by the datasource Ensembl.',
};
