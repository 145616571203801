import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import { getGeneDetailsInternalLinksAction } from './store/reducer';
import * as SELECTORS from './store/selectors';
// Components
import NoData from '../../common/NoData/NoData';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  conceptId: PropTypes.string,
  getLinks: PropTypes.func,
};

const InternalLinksChapter = (props) => {
  const {
    data,
    conceptId,
    getLinks,
  } = props;

  const [showInfoDialog, setShowInfoDialog] = useState(false);

  useEffect(() => {
    getLinks(conceptId);
  }, [conceptId, getLinks]);

  return (
    <div className="internal-links">
      <div className="gene-details-section-title title2">
        <span>Internal links</span>
      </div>
      {
        data && data.length > 0 &&
        <ul className="internal-links__list">
          {
            data.map((d) => (
              <li className="internal-links__item" key={d.name}>
                {`${d.name}: `}
                <span
                  onClick={() => {
                    setShowInfoDialog(true);
                    navigator.clipboard.writeText(d.link);
                  }
                  }
                  className="link"
                >
                  {d.link}
                </span>
              </li>
            ))
          }
        </ul>
      }
      <NoData
        show={!data || !data.length}
        customClassName="internal-links__no-data"
      />
      <InfoDialog
        confirmBtnText="Ok"
        isOpen={showInfoDialog}
        closeCb={() => setShowInfoDialog(false)}
        text='The link is copied into the clipboard. Please open a new browser tab and paste a link there to open the "antibody designs" file'
      />
    </div>
  );
};

InternalLinksChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getGeneDetailsInternalLinksDataSelector(state),
    loading: SELECTORS.getGeneDetailsInternalLinksLoadingSelector(state),
    error: SELECTORS.getGeneDetailsInternalLinksErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLinks(id) {
      dispatch(getGeneDetailsInternalLinksAction(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalLinksChapter);
