import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';

// Icons
import { VscChromeClose } from 'react-icons/vsc';
// Components
import ButtonCircle from '../common/Buttons/ButtonCircle/ButtonCircle';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  children: PropTypes.instanceOf(Object),
  customClassName: PropTypes.string,
  modalClassName: PropTypes.string,
};

const ModalComponent = (props) => {
  const {
    closeCb,
    isOpen,
    children,
    customClassName,
    modalClassName,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeCb}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      className={classNames('modal', modalClassName)}
      overlayClassName="overlay-modal"
      testId="modal-testid"
    >
      <ButtonCircle
        icon={<VscChromeClose size={24} color="#ffffff" />}
        onClick={closeCb}
        customClassName="modal-close-btn"
        size={40}
        testId="modal-close-testid"
      />
      <div className={classNames('modal-content', customClassName)}>
        {children}
      </div>
    </Modal>
  );
};

ModalComponent.propTypes = propTypes;

export default React.memo(ModalComponent);
