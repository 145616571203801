import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { PAGE_SIZE } from '../constants';

export const getGeneDetailsOrthologsAction = createAction('@@geneDetailsOrthologs/GET_DATA');
export const setGeneDetailsOrthologsAction = createAction('@@geneDetailsOrthologs/SET_DATA');
export const toggleGeneDetailsOrthologsLoaderAction = createAction('@@geneDetailsOrthologs/TOGGLE_LOADING');
export const throwGeneDetailsOrthologsErrorAction = createAction('@@geneDetailsOrthologs/THROW_ERROR');
export const sortGeneDetailsOrthologsAction = createAction('@@geneDetailsOrthologs/SORT_DATA');
export const changePageGeneDetailsOrthologsAction = createAction('@@geneDetailsOrthologs/CHANGE_PAGE');
export const setOrthologsFiltersAction = createAction('@@geneDetailsOrtholog/SET_FILTERS');

const initialState = fromJS({
  data: [],
  loading: false,
  error: null,
  sorting: {
    sortBy: 'identity',
    sortDirection: 'DESC',
  },
  totalPages: 0,
  page: 0,
  filters: {
    species: [],
    predictedByOma: [],
    predictedByEnsembl: [],
  },
});

const reducer = handleActions(
  {
    [setGeneDetailsOrthologsAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('page', () => 0)
        .update('totalPages', () => Math.ceil((payload).length / PAGE_SIZE))
        .update('error', () => null)
        .update('loading', () => false),
    [toggleGeneDetailsOrthologsLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwGeneDetailsOrthologsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [changePageGeneDetailsOrthologsAction]: (state, { payload }) =>
      state.update('page', () => payload),
    [sortGeneDetailsOrthologsAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [setOrthologsFiltersAction]: (state, { payload }) =>
      state
        .update('page', () => 0)
        .updateIn(['filters', payload.value], () => fromJS(payload.option)),
  },
  initialState
);

export default reducer;
