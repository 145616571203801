import { types } from './constants';

export const getDataAsyncAction = data => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_INIT_DATA_ASYNC,
    data,
  }
);

export const getTableAction = data => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_INIT_DATA,
    data,
  }
);

export const changePageAction = data => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_CHANGE_PAGE,
    data,
  }
);

export const sortTableAction = data => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_SORT_TABLE,
    data,
  }
);

export const geneDetailsIsFailed = message => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_INIT_FAILED,
    message,
  }
);

export const geneDetailsIsLoading = () => (
  {
    type: types.GENE_DETAILS_CLINICAL_TRIALS_LOADING,
  }
);
