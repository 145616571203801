import { takeLatest, call, put } from 'redux-saga/effects';

import { types } from '../constants';
import Api from '../../../../../Api/Api';
import {
  geneDetailsIsFailed,
  geneDetailsIsLoading,
  getTableAction,
} from '../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../actions';

export function* initClinicalTrialsWorker(action) {
  try {
    yield put(geneDetailsIsLoading());
    const { id } = action.data;
    const response = yield call(Api.geneDetailClinicalTrials, { id });
    const {
      clinicalTrials,
      externalApiError,
    } = response.data;

    if (clinicalTrials && clinicalTrials.length) {
      yield put(geneDetailsSetChapterAction('clinical-trials'));
    }
    yield put(getTableAction(clinicalTrials));
    if (externalApiError) {
      yield put(geneDetailsIsFailed(externalApiError));
    }
    yield put(loadNextChapterAction('antibodies'));
  } catch (e) {
    yield put(geneDetailsIsFailed(e.message));
    yield put(loadNextChapterAction('antibodies'));
  }
}


function* rootSaga() {
  yield takeLatest(types.GENE_DETAILS_CLINICAL_TRIALS_INIT_DATA_ASYNC, initClinicalTrialsWorker);
}

export default rootSaga;
