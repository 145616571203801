import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../ModalComponent/ModalComponent';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './FreeAccountLimitationDialog.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  entity: PropTypes.string,
  closeCb: PropTypes.func,
  hideDialog: PropTypes.func,
  customClassName: PropTypes.string,
};

const FreeAccountLimitationDialog = (props) => {
  const {
    entity,
    isOpen,
    closeCb,
    hideDialog,
    customClassName,
  } = props;

  if (!isOpen) {
    return null;
  }

  function handleHide() {
    if (closeCb) {
      closeCb();
    }
    hideDialog();
  }

  function handleUpgrade() {
    handleHide();
    window.open('https://www.euretos.com/academic-plus', '_blank');
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={handleHide}
      customClassName={customClassName}
    >
      <div className="freeuser-confirmation-dialog">
        <div className="freeuser-confirmation-dialog__content">
          Please upgrade to Academic Plus License to create additional {entity}
        </div>
        <div className="freeuser-confirmation-dialog__controls">
          <button
            onClick={handleUpgrade}
            className="button button-primary mr-15"
          >
            Get Academic Plus license
          </button>
          <button
            onClick={handleHide}
            className="button"
          >
            No, not yet
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

FreeAccountLimitationDialog.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isOpen: SELECTORS.getFreeAccountLimitDialogIsOpenSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideDialog(data) {
      dispatch(ACTIONS.hideFreeAccountLimitDialogAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FreeAccountLimitationDialog);
