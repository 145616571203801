export const ProjectStatus = {
  ACTIVE: {
    value: 'ACTIVE',
    label: 'Active',
  },
  CLOSED: {
    value: 'CLOSED',
    label: 'Closed',
  },
  LOCKED: {
    value: 'LOCKED',
    label: 'Locked',
  },
  LOCKED_FOR_USERS: {
    value: 'LOCKED_FOR_USERS',
    label: 'Locked for users',
  },
};

export const ProjectStatusOptions = [
  {
    type: 'ACTIVE',
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    type: 'CLOSED',
    value: 'CLOSED',
    label: 'Closed',
  },
  {
    type: 'LOCKED',
    value: 'LOCKED',
    label: 'Locked',
  },
  {
    type: 'LOCKED_FOR_USERS',
    value: 'LOCKED_FOR_USERS',
    label: 'Locked for users',
  },
];
