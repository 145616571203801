import isEmpty from 'lodash.isempty';
import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import {
  getPath,
} from '../selectors';
import { history } from '../../../../../index';

import {
  PATH_DETAILS_SMALL_MOLECULES_REQUESTED,
  PATH_DETAILS_SMALL_MOLECULES_SUCCEEDED,
  PATH_DETAILS_SMALL_MOLECULES_FAILED,
} from './constants';

function* details() {
  try {
    const post = yield select(getPath);

    if (isEmpty(post)) {
      history.replace(`${RELATIVE_PATH}/research`);
    } else {
      const response = yield call(Api.getPathDetailsSmallMolecules, { post });
      yield put({ type: PATH_DETAILS_SMALL_MOLECULES_SUCCEEDED, data: response.data });
    }
  } catch (e) {
    yield put({ type: PATH_DETAILS_SMALL_MOLECULES_FAILED, message: e.message });
  }
}

function* pathDetailsSmallMoleculesSaga() {
  yield takeEvery(PATH_DETAILS_SMALL_MOLECULES_REQUESTED, details);
}

export default pathDetailsSmallMoleculesSaga;
