import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';
// Utils
import { getTotalPages } from '../../../common/Pagination/utils';

const initialState = fromJS({
  set: null,
  loading: false,
  error: '',
  sorting: {
    sortBy: 'name',
    sortDirection: 'ASC',
  },
  pages: {
    totalPages: 0,
    pageNumber: 0,
  },
  nameFilter: '',
  sourceFilter: '',
  isSaving: false,
});

const reducer = handleActions(
  {
    [a.getEditSetDataAction]: state =>
      state
        .update('loading', () => true),
    [a.setEditSetDataAction]: (state, { payload }) =>
      state
        .update('set', () => fromJS(payload))
        .update('error', () => initialState.get('error'))
        .update('loading', () => false)
        .updateIn(['pages', 'pageNumber'], () => 0)
        .updateIn(['pages', 'totalPages'], () => getTotalPages(payload.items)),
    [a.throwEditSetDataAction]: (state, { payload }) =>
      state
        .update('error', () => payload)
        .update('loading', () => false),
    [a.updateEditSetConceptsAction]: (state, { payload }) =>
      state
        .updateIn(['set', 'items'], () => fromJS(payload)),
    [a.updateEditSetTagsAction]: (state, { payload }) =>
      state
        .updateIn(['set', 'tags'], () => fromJS(payload)),
    [a.sortEditSetAction]: (state, { payload }) =>
      state
        .update('sorting', () => fromJS(payload))
        .updateIn(['pages', 'pageNumber'], () => 0),
    [a.pagginateEditSetAction]: (state, { payload }) =>
      state
        .updateIn(['pages', 'pageNumber'], () => payload),
    [a.filterEditSetAction]: (state, { payload }) =>
      state
        .update(payload.key, () => payload.value)
        .updateIn(['pages', 'pageNumber'], () => 0),
    [a.saveEditSetRequestedAction]: state =>
      state
        .update('isSaving', () => true),
    [a.saveEditSetSucceededAction]: state =>
      state
        .update('isSaving', () => false),
    [a.saveEditSetFailedAction]: state =>
      state
        .update('isSaving', () => false),
    [a.resetEditSetAction]: () => initialState,
  },
  initialState
);

export default reducer;
