import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';

// Components
import SimpleTable from '../../../../common/SimpleTable/SimpleTable';
// Store

const propTypes = {
  items: PropTypes.instanceOf(Array),
  removeConceptFunction: PropTypes.func,
  solveAmbiguityFunction: PropTypes.func,
};

class AmbiguousConceptsTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = null;
    this.simpleTableRef = React.createRef(null);
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  removeConcept = (rowIndex) => {
    this.props.removeConceptFunction(rowIndex);
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  };

  getRowHeight = ({ index }) => {
    const { items } = this.props;
    const rowLength = items[index]?.mappedGenes?.length || 1;
    return rowLength === 1 ? 50 : 28 * rowLength;
  };

  solveAmbiguity = (itemId, id) => {
    this.props.solveAmbiguityFunction({ itemId, id });
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  };

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  getColumns = (width) => {
    this.columns = [
      {
        label: 'Source identifier',
        dataKey: 'geneName',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(width, 30),
      },
      {
        label: 'Name',
        dataKey: 'mappedGenes',
        width: this.getColumnPercentWidth(width, 60),
        className: 'table-wrap__cell table-wrap__cell_left',
        cellRenderer: ({ rowData }) => {
          const genes = rowData.mappedGenes || [];
          let template;
          if (genes.length > 1) {
            template = genes.map((gene, index) => (
              <div key={index} className="ambiguity-row">
                <span className="ambiguity-row-name" title={gene.brainName}>
                  {gene.brainName}
                </span>
                <span className="ambiguity-row-icon">
                  <i
                    role="presentation"
                    onClick={() => { this.solveAmbiguity(rowData.id, gene.id); }}
                    className="fa fa-trash color-red delete-icon"
                  />
                </span>
              </div>
            ));
          } else {
            template = genes.map((gene, index) => (
              <div key={index}>
                {gene.name || gene.brainName}
              </div>
            ));
          }
          return template;
        },
      },
      {
        dataKey: 'types',
        disableSort: true,
        width: this.getColumnPercentWidth(width, 10),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (
          <button
            type="button"
            onClick={() => this.removeConcept(rowData.id)}
            className="btn-icon btn-icon-danger"
          >
            <i className="fa fa-trash" />
          </button>
        ),
      }];
    return this.columns;
  };

  render() {
    const { items } = this.props;

    const tableSettings = {
      width: 900,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
    };

    return (
      <div className="row ambiguous-concepts">
        <div>
          <div className="empty-items-message disambiguation-message">
            Some of the identifiers from the input file match with more than one concept.
            Please select the correct one by deleting the other options:
          </div>
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <SimpleTable
                  innerRef={this.simpleTableRef}
                  autoSize={true}
                  width={width}
                  settings={tableSettings}
                  data={items}
                  columns={this.columns ? this.columns : this.getColumns(width)}
                  dynamicHeight={true}
                /> : null
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

AmbiguousConceptsTable.propTypes = propTypes;

export default AmbiguousConceptsTable;
