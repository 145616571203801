import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};

const HelpText = (props) => {
  const { id, text, children = null } = props;
  const tooltipRef = useRef(false);

  function handleOnCLick() {
    ReactTooltip.hide();
  }

  return (
    <button
      className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
      data-tip={true}
      data-event="click"
      data-for={id}
    >
      <ReactTooltip
        id={id}
        place="top"
        type="light"
        border={true}
        clickable={true}
        className="gene-set-options__tooltip"
        ref={tooltipRef}
        globalEventOff="click"
      >
        <div className="helptext__content">
          <i className="fa fa-times" onClick={handleOnCLick} />
          <div className="helptext__text">
            {text || children}
          </div>
        </div>
      </ReactTooltip>
    </button>
  );
};

HelpText.propTypes = propTypes;

export default HelpText;
