import {createAction} from 'redux-actions';

export const searchGenesForCellLineSelectionAction = createAction('@@cellLineSelection/SEARCH_GENES_FOR_CELL_LINES_SELECTION');
export const setGeneOptionsAction = createAction('@@cellLineSelection/SET_GENE_OPTIONS');
export const setSelectedGenesAction = createAction('@@cellLineSelection/SET_SELECTED_GENES');
export const setSelectedGenesSetAction = createAction('@@cellLineSelection/SET_SELECTED_GENES_SET');
export const getCellLinePanelDataAction = createAction('@@cellLineSelection/GET_CELL_LINES');
export const getCellLinePanelHeatmapDataAction = createAction('@@cellLineSelection/GET_CELL_LINES_HEATMAP');
export const setCellLinePanelDataAction = createAction('@@cellLineSelection/SET_CELL_LINES');
export const setSelectedCellLineDataAction = createAction('@@cellLineSelection/SET_SELECTED_CELL_LINE');
export const setSelectedCellLinesDataAction = createAction('@@cellLineSelection/SET_SELECTED_CELL_LINES');
export const deselectAllCellLineDataAction = createAction('@@cellLineSelection/DESELECT_ALL_CELL_LINES');
export const selectAllCellLineDataAction = createAction('@@cellLineSelection/SELECT_ALL_CELL_LINES');
export const setSelectedCellLinesGroupAction = createAction('@@cellLineSelection/SET_SELECTED_CELL_LINES_GROUP');
export const setProjectIdForCellLinesConfigurationModalAction = createAction('@@cellLineSelection/SET_PROJECT_ID_FOR_CELL_LINES_CONFIGURATION_MODAL');
export const clearProjectIdForCellLinesConfigurationModalAction = createAction('@@cellLineSelection/CLEAR_PROJECT_ID_FOR_CELL_LINES_CONFIGURATION_MODAL');
// cellLinesConfigurationsModal
export const getCellLinesConfigurationsAction = createAction('@@cellLineSelection/GET_CELL_LINES_CONFIGURATIONS');
export const setCellLinesConfigurationsAction = createAction('@@cellLineSelection/SET_CELL_LINES_CONFIGURATIONS');
export const throwCellLinesConfigurationsErrorAction = createAction('@@cellLineSelection/THROW_CELL_LINES_CONFIGURATIONS_ERROR');
export const filterCellLinesConfigurationsAction = createAction('@@cellLineSelection/FILTER_CELL_LINES_CONFIGURATIONS');
export const changeCellLinesConfigurationsPageAction = createAction('@@cellLineSelection/CHANGE_CELL_LINES_CONFIGURATIONS_PAGE');
export const checkCellLinesConfigurationAction = createAction('@@cellLineSelection/CHECK_CELL_LINES_CONFIGURATION');
export const resetCellLinesConfigurationsAction = createAction('@@cellLineSelection/RESET_CELL_LINES_CONFIGURATIONS');
// deleteCellLinesConfigurationModal
export const toggleDeleteCellLinesConfigurationModalIsOpenAction = createAction('@@cellLineSelection/TOGGLE_DELETE_CELL_LINES_CONFIGURATION_MODAL_IS_OPEN');
export const deleteCellLinesConfigurationAction = createAction('@@cellLineSelection/DELETE_CELL_LINES_CONFIGURATION');
// export const saveCellLinesConfigurationAction = createAction('@@cellLineSelection/SAVE_CELL_LINES_CONFIGURATION');
export const saveAsCellLinesConfigurationAction = createAction('@@cellLineSelection/SAVE_AS_CELL_LINES_CONFIGURATION');
export const getAdditionalDataForSelectedConfigurationAction = createAction('@@cellLineSelection/GET_ADDITIONAL_DATA_FOR_SELECTED_CONFIGURATION');
export const getLatestConfigurationDataAction = createAction('@@cellLineSelection/GET_LATEST_CONFIGURATION_DATA');
// get data for results table + charts
export const getResultsDataAction = createAction('@@cellLineSelection/GET_RESULTS_DATA');
export const getResultsHeatmapDataAction = createAction('@@cellLineSelection/GET_RESULTS_HEATMAP_DATA');
export const setResultsDataAction = createAction('@@cellLineSelection/SET_RESULTS_DATA');
export const setResultsHeatmapDataAction = createAction('@@cellLineSelection/SET_RESULTS_HEATMAP_DATA');
export const throwResultsDataErrorAction = createAction('@@cellLineSelection/THROW_RESULTS_DATA_ERROR');
export const changeResultsDataPageAction = createAction('@@cellLineSelection/CHANGE_RESULTS_DATA_PAGE');
export const sortResultsDataAction = createAction('@@cellLineSelection/SORT_RESULTS_DATA');
export const resetDataForNewGenesAction = createAction('@@cellLineSelection/RESET_DATA_FOR_NEW_GENES');
export const resetFormDataAction = createAction('@@cellLineSelection/RESET_FORM_DATA');
export const updateChartScaleAction = createAction('@@cellLineSelection/UPDATE_YSCALE');
