export const ANALYTICS_REQUESTED = 'ANALYTICS_REQUESTED';
export const ANALYTICS_FAILED = 'ANALYTICS_FAILED';
export const ANALYTICS_SUCCEEDED = 'ANALYTICS_SUCCEEDED';
export const ANALYTICS_TABLE = 'ANALYTICS_TABLE';
export const ANALYTICS_TITLE_FILTER = 'ANALYTICS_TITLE_FILTER';
export const ANALYTICS_TAG_FILTER = 'ANALYTICS_TAG_FILTER';
export const ANALYTICS_SET_NAME_FILTER = 'ANALYTICS_SET_NAME_FILTER';
export const ANALYTICS_SHOW_ALL_SET_NAMES = 'ANALYTICS_SHOW_ALL_SET_NAMES';
export const DELETE_ANALYTICS_REQUESTED = 'DELETE_ANALYTICS_REQUESTED';
export const DELETE_ANALYTICS_SUCCEEDED = 'DELETE_ANALYTICS_SUCCEEDED';
export const DELETE_ANALYTICS_FAILED = 'DELETE_ANALYTICS_FAILED';

export const RESET_SET_ANALYSIS_MANAGEMENT = 'RESET_SET_ANALYSIS_MANAGEMENT';

export const ANALYTICS_CHECK_ITEM = 'ANALYTICS_CHECK_ITEM';
export const ANALYTICS_CHECK_ALL = 'ANALYTICS_CHECK_ALL';

export const DUPLICATE_ANALYTICS = 'DUPLICATE_ANALYTICS';
export const COPY_ANALYTICS_ITEM_PROJECT = 'COPY_ANALYTICS_ITEM_PROJECT';

export const CLEAR_ANALYTICS_ACTIVE_PROJECT_ID = 'CLEAR_ANALYTICS_ACTIVE_PROJECT_ID';
export const SET_ANALYTICS_ACTIVE_PROJECT_ID = 'SET_ANALYTICS_ACTIVE_PROJECT_ID';
