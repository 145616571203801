export const PAGE_SIZE = 25;

export function getAntibodiesParams(location) {
  const urlParams = new URLSearchParams(location);

  return {
    id: urlParams.get('id'),
    name: urlParams.get('name'),
  };
}
