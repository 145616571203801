import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { PAGE_SIZE } from '../constants';

export const getSimilarProteinsAction = createAction('@@geneDetailsSimilarProteins/GET_DATA');
export const putSimilarProteinsAction = createAction('@@geneDetailsSimilarProteins/PUT_DATA');
export const similarProteinsLoadingAction = createAction('@@geneDetailsSimilarProteins/TOGGLE_LOADING');
export const similarProteinsFailedAction = createAction('@@geneDetailsSimilarProteins/FAILED');
export const similarProteinsSortAction = createAction('@@geneDetailsSimilarProteins/SORT_DATA');
export const changeSimilarProteinsPageAction = createAction('@@geneDetailsSimilarProteins/CHANGE_DATA_PAGE');

const initialState = fromJS({
  error: null,
  loading: true,
  similarProteins: [],
  sorting: {
    sortBy: 'identity',
    sortDirection: 'DESC',
  },
  totalPages: 0,
  page: 0,
});

const reducer = handleActions(
  {
    [putSimilarProteinsAction]: (state, { payload }) =>
      state
        .merge(fromJS({
          similarProteins: payload || [],
          error: null,
          loading: false,
          page: 0,
          totalPages: Math.ceil((payload || []).length / PAGE_SIZE),
        })),
    [similarProteinsLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [changeSimilarProteinsPageAction]: (state, { payload }) =>
      state.update('page', () => payload),
    [similarProteinsSortAction]: (state, { payload }) =>
      state.merge(fromJS({ sorting: payload })),
    [similarProteinsFailedAction]: (state, { payload }) =>
      state.merge(fromJS({ similarProteins: [], error: payload, loading: false })),
  },
  initialState
);

export default reducer;
