import { createSelector } from 'reselect';

// Utils
import { sort } from '../../Utils/Utils';
import { getCurrentPageData, getTotalPages } from '../../common/Pagination/utils';

const setData = state => state.getIn(['rankSelectionReducer', 'setData', 'set']);
const concepts = state => state.getIn(['rankSelectionReducer', 'setData', 'set', 'items']);
const loading = state => state.getIn(['rankSelectionReducer', 'setData', 'loading']);
const error = state => state.getIn(['rankSelectionReducer', 'setData', 'error']);
const sorting = state => state.getIn(['rankSelectionReducer', 'setData', 'sorting']);
const pages = state => state.getIn(['rankSelectionReducer', 'setData', 'pages']);
const method = state => state.getIn(['rankSelectionReducer', 'method']);
const openSetDialog = state => state.getIn(['rankSelectionReducer', 'openSetDialog']);
const selectedSetInfo = state => state.getIn(['rankSelectionReducer', 'selectedSetInfo']);
const selectedConcept = state => state.getIn(['rankSelectionReducer', 'selectedConcept']);

export const getRankSelctionSetSelector = createSelector(
  setData,
  data => data && data.toJS()
);

export const getRankSelctionSetConceptsSelector = createSelector(
  concepts,
  data => data && data.toJS()
);

export const getRankSelctionSetFinalDataSelector = createSelector(
  concepts,
  pages,
  sorting,
  (data, pagesData, sortingData) => {
    if (!data) return data;
    const { pageNumber } = pagesData.toJS();

    const sorted = sort(data, sortingData.toJS()).toJS();

    const pagginatedConcepts = getCurrentPageData(sorted, pageNumber);

    return {
      pages: { pageNumber, totalPages: getTotalPages(sorted) },
      pagginatedConcepts,
    };
  }
);

export const getRankSelctionSetLoadingSelector = createSelector(
  loading,
  data => data
);

export const getRankSelctionSetErrorSelector = createSelector(
  error,
  data => data
);

export const getRankSelctionMethodSelector = createSelector(
  method,
  data => data && data.toJS()
);

export const getRankSelctionMethodSelectedSelector = createSelector(
  method,
  (data) => {
    if (!data) return data;
    const d = data.toJS();
    return Object.keys(d).find(key => d[key]);
  }
);

export const getOpenSetDialogSelector = createSelector(
  openSetDialog,
  data => data
);

export const getSelectedSetInfoSelector = createSelector(
  selectedSetInfo,
  data => data && data.toJS()
);

export const getSelectedConceptSelector = createSelector(
  selectedConcept,
  data => data && data.toJS()
);
