import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form/lib/immutable';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Components
import FormTextarea from '../../../../../common/FormElements/FormTextarea';
import FormSelect from '../../../../../common/FormElements/FormSelect';
import Loader from '../../../../../common/Loader/Loader';
import ConfirmationDialog from '../../../../../Modals/ConfirmationDialog/ConfirmationDialog';
// Store
import { getProjectsOptionsAction, resetProjectsOptionsAction } from '../../../../../Projects/ProjectsTabs/store/reducer';
import {
  getProjectsOptionsLoadingSelector,
  getProjectsOptionsSelector
} from '../../../../../Projects/ProjectsTabs/store/selectors';
// Constants
import { CELL_LINES_CONFIGURATION_FORM } from '../../constants';

const propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  valid: PropTypes.bool,
  cellLinesConfigurations: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  optionsLoading: PropTypes.bool,
  activeProjectId: PropTypes.string,
  getProjectsOptions: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
  multiselect: PropTypes.bool,
};

const SaveCellLineConfigurationAsModal = (props) => {
  const {
    valid,
    isOpen,
    onCancel,
    onSubmit,
    options,
    optionsLoading,
    activeProjectId,
    getProjectsOptions,
    resetProjectsOptions,
    multiselect,
    cellLinesConfigurations = [],
  } = props;

  const [warningMessage, toggleWarningMessage] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getProjectsOptions();
    } else {
      resetProjectsOptions();
    }
  }, [isOpen]);

  function findCellLinesConfigurationNameRepeat(name) {
    return cellLinesConfigurations.some(c => c.name.toLowerCase() === name.trim().toLowerCase());
  }

  function handleNameChange(e) {
    const nameRepeating = findCellLinesConfigurationNameRepeat(e);

    if (nameRepeating) {
      toggleWarningMessage(true);
    } else if (warningMessage) {
      toggleWarningMessage(false);
    }
  }
  return (
    <ConfirmationDialog
      onConfirm={onSubmit}
      disabled={!valid || warningMessage}
      onCancel={onCancel}
      isOpen={isOpen}
      closeCb={onCancel}
      customClassName="cell-line-configuration-dialog"
    >
      <div className="modal__copy-text cell-line-configuration-dialog__content">
        <form className="copy-modal__form cell-line-configuration-dialog__form">
          <div className="copy-modal__title cell-line-configuration-dialog__title title2">
            Save Cell Lines Configuration
          </div>
          <Loader isLoading={optionsLoading} />
          {
            options && options.length > 0 &&
            <div className="copy-modal__block">
              <label className="copy-modal__label" htmlFor="project">
                Select project
              </label>
              <Field
                name="project"
                options={options}
                component={FormSelect}
                returnOption={true}
                bordered={true}
                dropDownHeight={110}
                selectedValue={options.find(o => o.id === activeProjectId) || options[0]}
              />
            </div>
          }
          {
            !multiselect &&
            <>
              <div className="copy-modal__block cell-line-configuration-dialog__block">
                <label className="copy-modal__label cell-line-configuration-dialog__label" htmlFor="name">Cell Lines Configuration name</label>
                <Field
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  component={FormTextarea}
                  onChange={handleNameChange}
                />
              </div>
              {
                warningMessage &&
                <div className="copy-modal__warning cell-line-configuration-dialog__warning">
                  <span className="copy-modal__warning-text cell-line-configuration-dialog__warning-text">
                      This name already exists
                  </span>
                </div>
              }
            </>
          }
        </form>
      </div>
    </ConfirmationDialog>
  );
};

SaveCellLineConfigurationAsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: getProjectsOptionsSelector(state),
    optionsLoading: getProjectsOptionsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(resetProjectsOptionsAction());
    },
  };
}

function validate(values) {
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter cell lines configuration name';
  }
  if (values.get('name') && values.get('name').trim().length > 1000) {
    errors.name = 'Cell lines configuration name is too long. Enter less than 1000 symbols';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: CELL_LINES_CONFIGURATION_FORM,
    fields: ['name', 'project'],
    validate,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(SaveCellLineConfigurationAsModal);
