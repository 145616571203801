import { types } from './constants';

// Chapter
export const getTherapeuticCandidatesDataAction = data => (
  {
    type: types.GET_THERAPEUTIC_CANDIDATES_DATA,
    data,
  }
);

export const putTherapeuticCandidatesDataAction = data => (
  {
    type: types.PUT_THERAPEUTIC_CANDIDATES_DATA,
    data,
  }
);

export const therapeuticCandidatesDataFailedAction = message => (
  {
    type: types.THERAPEUTIC_CANDIDATES_DATA_FAILED,
    message,
  }
);

export const therapeuticCandidatesExistingDrugsSortAction = data => (
  {
    type: types.THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_SORT,
    data,
  }
);

export const therapeuticCandidatesExperementalResultsSortAction = data => (
  {
    type: types.THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_SORT,
    data,
  }
);

export const therapeuticCandidatesExistingDrugsChangePageAction = data => (
  {
    type: types.THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_CHANGE_PAGE,
    data,
  }
);

export const therapeuticCandidatesExperementalResultsChangePageAction = data => (
  {
    type: types.THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_CHANGE_PAGE,
    data,
  }
);

