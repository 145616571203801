import { put, call, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import * as a from './reducer';

function* fetchTagsSaga() {
  try {
    yield put(a.toggleAddTagDataLoaderAction(true));
    const { data } = yield call(Api.topUsedTags);
    const availableTagsOptions = data.map((item, i) => ({
      id: `${item}__${i}`,
      value: item,
      label: item,
    }));
    yield put(a.fetchTagsSuccessAction(availableTagsOptions));
    yield put(a.toggleAddTagDataLoaderAction(false));
  } catch (e) {
    yield put(a.toggleAddTagDataLoaderAction(false));
  }
}

function* rootSaga() {
  yield takeLatest(a.fetchTagsRequestAction, fetchTagsSaga);
}

export default rootSaga;
