import { call, put, takeEvery, select } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  getRelationMapManagementDataAction,
  setRelationMapManagementDataAction,
  throwRelationMapManagementErrorAction,
  deleteRelationMapManagementProjectsAction,
  dublicateRelationMapManagementProjectsAction,
} from './reducer';
import { getFormValuesSelector } from './selectors';

import { checkProjectTab, getProjectIdForRequest } from '../../../Projects/ProjectsTabs/utils';
import { getProjectsOptionsSelector } from '../../../Projects/ProjectsTabs/store/selectors';

function* getRelationMapManagementDataWorker(action) {
  try {
    const projectId = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiRequest = isProjectTab ? Api.getAllProjectAllRelationMapItems : Api.getAllRelationMapItems;
    const { data } = yield call(apiRequest, projectId);
    const expanded = data.map(project => (
      Object.assign({}, project, {
        showAll: false,
        conceptsCount: project.concepts.length,
      })
    ));
    yield put(setRelationMapManagementDataAction(expanded));
  } catch (e) {
    yield put(throwRelationMapManagementErrorAction(e.message));
  }
}

function* deleteRelationMapManagementProjectsWorker({ payload }) {
  try {
    const { ids, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiRequest = isProjectTab ? Api.deleteProjectRelationMapManagementItems : Api.deleteRelationMapManagementItems;
    yield call(apiRequest, { ids, projectId });
    yield put(getRelationMapManagementDataAction(projectId));
  } catch (e) {
    yield put(throwRelationMapManagementErrorAction(e.message));
  }
}

export function* duplicateRelationMapManagementProjectsWorker({ payload }) {
  try {
    const { ids, projectId, multiselect } = payload;
    const { name, project = {} } = yield select(getFormValuesSelector);
    const options = yield select(getProjectsOptionsSelector);
    const defaultProject = options.some(({ id: optionId }) => optionId === projectId) ? projectId : null;
    const targetProject = project.id || defaultProject;
    const requestData = {
      targetProject: getProjectIdForRequest(targetProject),
      'relation-map-id': ids.join(','),
      projectId: getProjectIdForRequest(projectId),
    };
    if (!multiselect) {
      requestData.name = name.trim();
    }
    yield call(Api.duplicateRelationMapManagementItem, requestData);

    yield put(getRelationMapManagementDataAction(projectId));
  } catch (e) {
    yield put(throwRelationMapManagementErrorAction(e.message));
  }
}

function* relationMapManagement() {
  yield takeEvery(getRelationMapManagementDataAction, getRelationMapManagementDataWorker);
  yield takeEvery(deleteRelationMapManagementProjectsAction, deleteRelationMapManagementProjectsWorker);
  yield takeEvery(dublicateRelationMapManagementProjectsAction, duplicateRelationMapManagementProjectsWorker);
}

export default relationMapManagement;
