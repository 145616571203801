export const getSortPath = (sortByField, columns) => {
  let sortPath = null;
  columns.forEach((item) => {
    if ((sortByField === item.dataKey) && item.sortPath) {
      sortPath = item.sortPath;
    }
  });
  return sortPath;
};

export const getServerSortPath = (sortByField, columns) => {
  let serverSortPath = null;
  columns.forEach((item) => {
    if ((sortByField === item.dataKey) && item.dataPath) {
      serverSortPath = item.dataPath.join('.');
    }
  });
  return serverSortPath;
};

export const getSortValueMapper = (sortByField, columns) => {
  let sortValueMapper = null;
  columns.forEach((item) => {
    if ((sortByField === item.dataKey) && item.sortValueMapper) {
      sortValueMapper = item.sortValueMapper;
    }
  });
  return sortValueMapper;
};