import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
// Utils
import { AVERAGE_SYMBOL_LENGTH, getParentBlockWidth } from '../../../Utils/Utils';
// Store
import {
  getSequences,
  getSequencesLoading,
  getSequencesError,
} from '../redux/selectors';
import { getPatentsSequencesAction } from '../redux/actions';

const propTypes = {
  id: PropTypes.string,
  parentClass: PropTypes.string,
  sequences: PropTypes.instanceOf(Object),
  sequencesLoading: PropTypes.bool,
  sequencesError: PropTypes.string,
  getTherapeuticCandidatesSequences: PropTypes.func,
};

class SequencesTable extends React.Component {
  simpleTableRef = React.createRef(null);

  componentDidMount = () => {
    const {
      id,
      getTherapeuticCandidatesSequences,
    } = this.props;

    getTherapeuticCandidatesSequences({ id });
  };

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (pageNumber) => {
    const {
      id,
      getTherapeuticCandidatesSequences,
    } = this.props;

    getTherapeuticCandidatesSequences({
      id,
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  getColumnPercentWidth = (percent) => {
    const { parentClass } = this.props;
    const screenWidth = getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { sequences: { content } } = this.props;
    const padding = 20;
    const lineHeight = 15;
    const cellWidth = content[index].sequenceType === 'DNA' ?
      this.getColumnPercentWidth(44) :
      this.getColumnPercentWidth(50);
    const stringLength = content[index].sequence.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    return (stringRows * lineHeight) + padding;
  };

  render() {
    const {
      sequences: {
        content,
        number,
        totalPages,
      },
      sequencesLoading,
      parentClass,
      sequencesError,
    } = this.props;

    const tableSettings = {
      width: getParentBlockWidth(parentClass),
      useDynamicRowHeight: true,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
    };

    const columns = [
      {
        label: 'Sequence ID',
        dataKey: 'sequenceId',
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(25),
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="cell-row text-center">{rowData.sequenceId}</div>
        ),
      },
      {
        label: 'Sequence type',
        dataKey: 'sequenceType',
        width: this.getColumnPercentWidth(25),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="cell-row text-center">{rowData.sequenceType}</div>
        ),
      },
      {
        label: 'Sequence',
        dataKey: 'sequence',
        width: this.getColumnPercentWidth(50),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="cell-row text-center cell-row-normal-height">{rowData.sequence}</div>
        ),
      },
    ];

    return (
      <div className="patent-popup__table">
        {
          content && content.length > 0 && !sequencesLoading &&
          <div>
            {
              totalPages > 1 &&
              <div className="controls-block">
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={number}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            }
            <SimpleTable
              innerRef={this.simpleTableRef}
              settings={tableSettings}
              data={content}
              columns={columns}
              dynamicHeight={true}
            />
          </div>
        }
        <Loader isLoading={sequencesLoading} />
        <Error show={!!sequencesError} error={sequencesError} />
      </div>
    );
  }
}

SequencesTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sequences: getSequences(state),
    sequencesLoading: getSequencesLoading(state),
    sequencesError: getSequencesError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTherapeuticCandidatesSequences(data) {
      dispatch(getPatentsSequencesAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SequencesTable);
