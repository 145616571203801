import {
  SORT,
  CHECK_ITEM,
  CHECK_ALL,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../../common/SimpleTable/constants';

import {
  PUBLICATION_DETAILS_TRIPLES_TABLE,
  REMOVE_CONCEPT,
  PUBLICATION_DETAILS_TRIPLES_TABLE_RESET,
  PUBLICATION_DETAILS_TRIPLES_REQUESTED,
  LOADING,
  PUBLICATION_DETAILS_TRIPLES_TABLE_CHANGE_PAGE,
  TRIPLES_BY_IDS_REQUESTED,
} from './constants';

export const changePageAction = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE_CHANGE_PAGE,
    data,
  }
);

export const reset = () => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE_RESET,
  }
);

export const fetchPublicationTriplesAction = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_REQUESTED,
    data,
  }
);

export const fetchTriplesByIdsAction = data => (
  {
    type: TRIPLES_BY_IDS_REQUESTED,
    data,
  }
);

export const sort = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + SORT,
    data,
  }
);

export const checkAll = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelection = () => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + INVERT_SELECTION,
  }
);

export const removeSelected = () => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + REMOVE_SELECTED,
  }
);

export const removeConcept = data => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + REMOVE_CONCEPT,
    data,
  }
);

export const loading = () => (
  {
    type: PUBLICATION_DETAILS_TRIPLES_TABLE + LOADING,
  }
);
