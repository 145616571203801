import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Store
import { setNewFullSet } from '../../../SetResult/SetResultPage/actions';
import { getCachedConceptIdsForCategorySearch } from '../../store/selectors';
// Constants
import { apiTypes } from '../../../Sets/CreateSet/enums';
// Styles
import './SearchRelatedConceptsCard.scss';

const propTypes = {
  checkRelatedConceptCb: PropTypes.func,
  startNewSearch: PropTypes.func,
  concepts: PropTypes.instanceOf(Array),
  selectedSearchCategory: PropTypes.string,
  categoryConceptsSize: PropTypes.number,
  openCreateSetPopup: PropTypes.func,
  conceptIds: PropTypes.instanceOf(Array),
};

class SearchRelatedConceptsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  checkRelatedCb = (concept) => {
    const { checkRelatedConceptCb } = this.props;
    if (!concept.active) {
      checkRelatedConceptCb(concept.id);
    }
    this.triggerNewSearch(concept);
  };

  triggerNewSearch = (concept) => {
    const { startNewSearch } = this.props;
    const {
      recommendation: {
        recommendationPhrase,
        exactRecommendationPhrase,
      },
    } = concept;
    startNewSearch({
      plainValue: recommendationPhrase,
      exactValue: exactRecommendationPhrase,
    });
  };

  switchMoreLessView = () => {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  };

  openCreateSetPopup = () => {
    const { openCreateSetPopup, conceptIds } = this.props;
    openCreateSetPopup({
      conceptIds,
      apiType: apiTypes.filteredFullListApi,
    });
  };

  render() {
    const {
      concepts,
      selectedSearchCategory,
      categoryConceptsSize,
    } = this.props;

    const { showMore } = this.state;

    const maxCount = Math.max.apply(null, concepts.map(concept => concept.count));

    const mappedConcepts = concepts.map(concept => (
      Object.assign(concept, {
        width: `${((concept.count / maxCount) * 100).toFixed(0)}%`,
      })
    ));

    const conceptsToShow = showMore ? mappedConcepts : mappedConcepts.slice(0, 5);

    return (
      <div className="related-concepts-card">
        <div className="search-page__subtitle">
          Related {selectedSearchCategory || 'concepts'} ({categoryConceptsSize})
        </div>
        {
          conceptsToShow.map((concept, index) => {
            const activeClass = classNames({
              'flex-grid': true,
              'related-concept-card-item': true,
              'related-concept-card-item-active': concept.active,
            });
            return (
              <div className={activeClass} key={index}>
                <div
                  title={concept.name}
                  onClick={() => { this.checkRelatedCb(concept); }}
                  className="col-1 related-concept-name"
                >
                  { concept.name }
                </div>
                <div className="col-1">
                  <div
                    style={{ width: concept.width }}
                    className="related-concept-count"
                  >
                    { concept.count }
                  </div>
                </div>
              </div>
            );
          })
        }
        <div className="related-concepts-card-bottom">
          <div className="flex-grid">
            <div className="col-1 more-link">
              <span onClick={this.switchMoreLessView}>
                { showMore ? 'less' : 'Top 15 results' }
              </span>
            </div>
            <div className="col-1 get-full-list">
              <span onClick={this.openCreateSetPopup}>
                open as set
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchRelatedConceptsCard.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    conceptIds: getCachedConceptIdsForCategorySearch(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNewFullSet(data) {
      dispatch(setNewFullSet(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchRelatedConceptsCard);

