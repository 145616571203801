import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Loader from '../../common/Loader/Loader';
// Store
import * as ACTIONS from './reducer';
import * as SELECTORS from './selectors';
// Styles
import './ShortConceptCard.scss';

const propTypes = {
  id: PropTypes.number,
  conceptId: PropTypes.number,
  prevConceptId: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  getShortConceptCardData: PropTypes.func,
  clearShortConceptCardState: PropTypes.func,
  setShortConceptCardPrevId: PropTypes.func,
};

class ShortConceptCard extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id || props.conceptId;
    this.isNewCard = (() => {
      const { prevConceptId, clearShortConceptCardState } = props;
      if (prevConceptId !== this.id) {
        clearShortConceptCardState();
        return true;
      }
      return false;
    })();
  }

  componentDidMount() {
    const {
      getShortConceptCardData,
      setShortConceptCardPrevId,
    } = this.props;

    if (this.id && this.isNewCard) {
      getShortConceptCardData(this.id);
      setShortConceptCardPrevId(this.id);
    }
  }

  getSynonymsJsx = synonyms => (
    synonyms.slice(0, 5).map((synonym, index, array) => (
      <span
        key={index}
        className="short-concept-card__link"
      >
        { synonym }{ index !== (array.length - 1) && ', '}
      </span>
    ))
  );

  getClassificationsJsx = classifications => (
    classifications.map((classification, index, array) => (
      <span
        key={index}
        className="short-concept-card__link"
      >
        { classification.name }{ index !== (array.length - 1) && ', '}
      </span>
    ))
  );

  renderConceptsCardContent = cardsData => cardsData.map(data => (
    <>
      {
        data.name &&
          <div className="short-concept-card__name">
            {data.name}
          </div>
      }
      {
        data.semanticCategory &&
          <div className="short-concept-card__block">
            <p className="short-concept-card__text short-concept-card__text_cat">
              {data.semanticCategory}
            </p>
          </div>
      }
      {
        data.definition &&
          <div className="short-concept-card__block">
            <p className="short-concept-card__text">
              {data.definition}
            </p>
          </div>
      }
      {
        data.synonyms && data.synonyms.length > 0 &&
          <div className="short-concept-card__block">
            <span className="short-concept-card__text short-concept-card__text_bold">
              {'Synonyms: '}
            </span>
            { this.getSynonymsJsx(data.synonyms) }
          </div>
      }
      {
        data.classifications && data.classifications.length > 0 &&
          <div className="short-concept-card__block">
            <span className="short-concept-card__text short-concept-card__text_bold">
              {'Classifications: '}
            </span>
            {this.getClassificationsJsx(data.classifications)}
          </div>
      }
    </>
  ));

  render() {
    const { data, loading } = this.props;

    return (
      <div className="short-concept-card" key={this.id}>
        {
          !loading && data && this.renderConceptsCardContent(data)
        }
        <Loader isLoading={loading} />
      </div>
    );
  }
}

ShortConceptCard.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getShortConceptCardDataSelector(state),
    conceptId: SELECTORS.getShortConceptCardIdSelector(state),
    prevConceptId: SELECTORS.getShortConceptCardPrevIdSelector(state),
    loading: SELECTORS.getShortConceptCardLoadingSelector(state),
    error: SELECTORS.getShortConceptCardErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getShortConceptCardData(id) {
      dispatch(ACTIONS.shortConceptCardRequestedAction(id));
    },
    clearShortConceptCardState() {
      dispatch(ACTIONS.shortConceptCardClearStateAction());
    },
    setShortConceptCardPrevId(id) {
      dispatch(ACTIONS.shortConceptCardSetPrevIdAction(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortConceptCard);
