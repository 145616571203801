import * as d3js from 'd3';

export function drawLegend({
  element, keys, colors,
}) {
  const color = d3js.scaleOrdinal()
    .domain(keys)
    .range(colors);
  const size = 20;

  const MAX_ELEMENT_LENGTH = 20;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length / MAX_ELEMENT_LENGTH; i++) {
    element.selectAll()
      .data(keys.slice(i * 20, (i + 1) * MAX_ELEMENT_LENGTH))
      .enter()
      .append('rect')
      .attr('x', 100 * (i + 1))
      .attr('y', (d, idx) => 100 + (idx * (size + 5)))
      .attr('width', size)
      .attr('height', size)
      .style('fill', d => color(d));

    element.selectAll()
      .data(keys.slice(i * 20, (i + 1) * MAX_ELEMENT_LENGTH))
      .enter()
      .append('text')
      .style('font-size', '14px')
      .attr('x', (100 * (i + 1)) + (size * 1.2))
      .attr('y', (d, idx) => 100 + (idx * (size + 5)) + (size / 1.5))
      .style('fill', '#000')
      .text(d => d)
      .attr('text-anchor', 'left')
      .style('dominant-baseline', 'middle')
      .style('alignment-baseline', 'middle');
  }
}
