import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Store
import * as a from './reducer';

function* loadAntibodiesDataWorker({ payload }) {
  try {
    yield put(a.clearAntibodiesDataAction());
    const { data } = yield call(Api.getAntibodies, payload);
    yield put(a.setAntibodiesDataAction(data));
  } catch (e) {
    yield put(a.throwAntibodiesErrorAction(e.message));
  }
}

function* relationMapManagement() {
  yield takeLatest(a.loadAntibodiesDataAction, loadAntibodiesDataWorker);
}

export default relationMapManagement;
