import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export const useBlocker = ({ retryUnblockRef, savingDataBeforeClosing }) => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    let isActive = false;
    const unblock = block(({ retry }) => {
      retryUnblockRef.current = retry;

      if (isActive) {
        unblock();
        return retry();
      } else if (!isActive) {
        savingDataBeforeClosing({ retryUnblockPage: retry });
      }

      isActive = true;
    });

    return unblock;
  }, [block]);
};
