import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// Components
import SelectInput from '../../../common/Inputs/SelectInput/SelectInput';

const propTypes = {
  options: PropTypes.instanceOf(Array),
  value: PropTypes.instanceOf(Object),
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  fetchOptions: PropTypes.func,
  placeholder: PropTypes.string,
  dropDownHeight: PropTypes.number,
};

const TissuesSelect = (props) => {
  const {
    value,
    isMulti = false,
    options,
    disabled = false,
    onSelect,
    isLoading,
    placeholder,
    fetchOptions,
    dropDownHeight = 180,
  } = props;

  const styles = useMemo(() => ({
    menuList: provided => ({
      ...provided,
      maxHeight: `${dropDownHeight}px`,
      overflow: 'auto',
    }),
  }), [dropDownHeight]);

  return (
    <SelectInput
      bordered={true}
      defaultValue={value}
      styles={styles}
      options={options}
      isMulti={isMulti}
      onSelect={onSelect}
      isDisabled={disabled}
      isLoading={isLoading}
      placeholder={placeholder}
      onFocus={fetchOptions}
      customClassName="tissues-select"
      noOptionsMessage={() => 'There is no data to display'}
    />
  );
};

TissuesSelect.propTypes = propTypes;

export default React.memo(TissuesSelect);
