// Core
import { takeLatest } from 'redux-saga/effects';

// Types
import { types } from '../constants';

// Workers
import { getTherapeuticCandidatesDataWorker } from './workers/getTherapeuticCandidatesDataWorker';

function* watchTherapeuticCandidatesChapter() {
  yield takeLatest(types.GET_THERAPEUTIC_CANDIDATES_DATA, getTherapeuticCandidatesDataWorker);
}

export default watchTherapeuticCandidatesChapter;
