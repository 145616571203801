import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Concept from '../../../common/ConceptItem/Concept';
import RadioInput from '../../../common/Inputs/RadioInput/RadioInput';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
import ConceptSearchModal from '../../../Concept/ConceptSearchModal/ConceptSearchModal';
// Store
import {
  setRankingAction,
  closeRankingModal,
} from '../../common/SetAnalysisMethodSelection/actions';
import {
  setDifferentialExpressionConcept,
  saveDifferentialExpressionConcept,
  removeDifferentialExpressionConcept,
  setDifferentialExpressionCategory,
} from './actions';
import {
  getDifferentialExpressionTerm,
  getDifferentialExpressionSC,
} from './selectors';
// Styles
import './DifferentialExpressionSettings.css';

const propTypes = {
  closeModal: PropTypes.func,
  saveDifferentialExpressionConcept: PropTypes.func,
  setRanking: PropTypes.func,
  setDifferentialExpressionConcept: PropTypes.func,
  removeDifferentialExpressionConcept: PropTypes.func,
  setDifferentialExpressionCategory: PropTypes.func,
  differentialExpressionTerm: PropTypes.instanceOf(Object),
  differentialExpressionSC: PropTypes.string,
  updateTerm: PropTypes.func,
  closeModalCallback: PropTypes.func,
  setRankingCallback: PropTypes.func,
};

const DifferentialExpressionSettings = (props) => {
  const {
    setRanking,
    differentialExpressionTerm,
    differentialExpressionSC,
    saveDifferentialExpressionConcept,
    setDifferentialExpressionConcept,
    removeDifferentialExpressionConcept,
    setDifferentialExpressionCategory,
    closeModal,
    updateTerm,
    closeModalCallback,
    setRankingCallback,
  } = props;
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);

  const goAhead = () => {
    if (updateTerm) {
      updateTerm(differentialExpressionTerm);
      setRankingCallback('differentialExpression');
      closeModalCallback();
    } else {
      saveDifferentialExpressionConcept();
      setRanking({ differentialExpression: true });
      closeModal();
    }
  };

  const itemSelected = (item) => {
    setDifferentialExpressionConcept(item);
  };

  const removeItem = () => {
    removeDifferentialExpressionConcept();
  };

  const changeCategory = (e) => {
    setDifferentialExpressionCategory(e.target.value);
  };

  return (
    <div className="differential-expression-modal">
      <div className="flex-grid">
        <div className="col-1 text-center">
          <h3 className="title3">Differential Expression Score</h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <div className="label">Select category:</div>
          <div className="pl-10">
            <span className="pr-10">
              <RadioInput
                name="conceptCategory"
                value="Disorders"
                checked={differentialExpressionSC === 'Disorders'}
                onChange={changeCategory}
                labelValue="Disorders"
                id="disorderCategory"
              />
            </span>
            <span className="pr-10">
              <RadioInput
                name="conceptCategory"
                value="Chemicals & Drugs"
                checked={differentialExpressionSC === 'Chemicals & Drugs'}
                onChange={changeCategory}
                labelValue="Chemicals & Drugs"
                id="C&DCategory"
              />
            </span>
            <span className="pr-10">
              <RadioInput
                name="conceptCategory"
                value="Physiology"
                checked={differentialExpressionSC === 'Physiology'}
                onChange={changeCategory}
                labelValue="Physiology"
                id="physiologyCategory"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <div className="label">Select concept:</div>
          <ConceptSearchForm
            onSubmitCallback={() => setShowConceptSearchModal(true)}
          />
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-center row">
          {differentialExpressionTerm &&
            <Concept
              noInfoButton={true}
              deleteCb={removeItem}
              concept={differentialExpressionTerm}
            />
          }
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-center row">
          <button
            disabled={!differentialExpressionTerm}
            className="button button-primary mr-15"
            onClick={goAhead}
          >
            Continue
          </button>
          <button
            type="button"
            className="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={itemSelected}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </div>
  );
};

DifferentialExpressionSettings.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    differentialExpressionTerm: getDifferentialExpressionTerm(state),
    differentialExpressionSC: getDifferentialExpressionSC(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal() {
      dispatch(closeRankingModal());
    },
    setRanking(data) {
      dispatch(setRankingAction(data));
    },
    setDifferentialExpressionConcept(data) {
      dispatch(setDifferentialExpressionConcept(data));
    },
    saveDifferentialExpressionConcept() {
      dispatch(saveDifferentialExpressionConcept());
    },
    removeDifferentialExpressionConcept() {
      dispatch(removeDifferentialExpressionConcept());
    },
    setDifferentialExpressionCategory(data) {
      dispatch(setDifferentialExpressionCategory(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DifferentialExpressionSettings);
