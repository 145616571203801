import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import { types } from '../constants';
import {
  geneDetailsIsFailed,
  geneDetailsIsLoading,
  getChartAction,
} from '../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../actions';
import Api from '../../../../../Api/Api';

export function* initConditionOfDysregulationWorker(action) {
  try {
    yield put(geneDetailsIsLoading());
    const { id } = action.data;
    const response = yield call(Api.geneDetailConditionOfDysregulation, { id });
    yield put(getChartAction(response));
    const {
      deregulationTableInfo,
      dysregulationBarsInfo,
    } = response.data;

    if ((deregulationTableInfo && deregulationTableInfo.length) || (dysregulationBarsInfo && dysregulationBarsInfo.length)) {
      yield put(geneDetailsSetChapterAction('condition-of-dysregulation'));
    }

    yield put(loadNextChapterAction('functional-characterization'));
  } catch (e) {
    yield put(geneDetailsIsFailed({ message: e.message }));
    yield put(loadNextChapterAction('functional-characterization'));
  }
}

function* rootSaga() {
  yield takeLatest(types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_INIT_DATA, initConditionOfDysregulationWorker);
}

export default rootSaga;
