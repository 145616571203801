export const sortingTypes = {
  DEFAULT: 'Default',
  MEDIAN_EXPRESSION: 'Median Expression',
  PAIRED: 'Paired',
};

export const sortingEnums = {
  DEFAULT: 'DEFAULT',
  MEDIAN_EXPRESSION: 'MEDIAN_EXPRESSION',
  PAIRED: 'PAIRED',
};

export const sortingOptions = [
  {
    type: sortingEnums.DEFAULT,
    value: sortingEnums.DEFAULT,
    label: sortingTypes.DEFAULT,
  },
  {
    type: sortingEnums.MEDIAN_EXPRESSION,
    value: sortingEnums.MEDIAN_EXPRESSION,
    label: sortingTypes.MEDIAN_EXPRESSION,
  },
  {
    type: sortingEnums.PAIRED,
    value: sortingEnums.PAIRED,
    label: sortingTypes.PAIRED,
  },
];

export const graphTypes = {
  GTEX: 'GTEX',
  CCLE: 'CCLE',
  TCGA: 'TCGA',
  CPTAC: 'CPTAC',
  CELL_TYPE: 'CELL_TYPE',
  CCLE_PROTEOMICS: 'CCLE_PROTEOMICS',
  SANGER_CELL_MODEL: 'SANGER_CELL_MODEL',
  HPA_PER_CELL_TYPE: 'HPA_PER_CELL_TYPE',
  HPA_PER_TISSUES: 'HPA_PER_TISSUES',
};


export const proteinExpressionSortingOptions = [
  {
    type: 'pintensity',
    value: 'pintensity',
    label: 'Log2 protein intensity',
  },
  {
    type: 'zscore',
    value: 'zscore',
    label: 'Z-scores',
  },
];

export const visibleHpaChartEnums = {
  CELL_TYPE: 'Cell type',
  TISSUES: 'Tissues',
};

export const visibleHpaChartOptions = [
  {
    type: visibleHpaChartEnums.CELL_TYPE,
    value: visibleHpaChartEnums.CELL_TYPE,
    label: visibleHpaChartEnums.CELL_TYPE,
  },
  {
    type: visibleHpaChartEnums.TISSUES,
    value: visibleHpaChartEnums.TISSUES,
    label: visibleHpaChartEnums.TISSUES,
  },
];

export const sortingHpaTypes = {
  ALPHABETICAL: 'Alphabetical',
  BY_EXPRESSION: 'By Expression (High to Low)',
  BY_EXPRESSION_LOW: 'By Expression (Low to High)',
  PER_CELL_TYPE_GROUP: 'Per cell type group',
};

export const sortingHpaEnums = {
  ALPHABETICAL: 'ALPHABETICAL',
  BY_EXPRESSION: 'BY_EXPRESSION',
  BY_EXPRESSION_LOW: 'BY_EXPRESSION_LOW',
  PER_CELL_TYPE_GROUP: 'PER_CELL_TYPE_GROUP',
};

export const sortingHpaOptions = [
  {
    type: sortingHpaEnums.ALPHABETICAL,
    value: sortingHpaEnums.ALPHABETICAL,
    label: sortingHpaTypes.ALPHABETICAL,
  },
  {
    type: sortingHpaEnums.BY_EXPRESSION,
    value: sortingHpaEnums.BY_EXPRESSION,
    label: sortingHpaTypes.BY_EXPRESSION,
  },
  {
    type: sortingHpaEnums.BY_EXPRESSION_LOW,
    value: sortingHpaEnums.BY_EXPRESSION_LOW,
    label: sortingHpaTypes.BY_EXPRESSION_LOW,
  },
  {
    type: sortingHpaEnums.PER_CELL_TYPE_GROUP,
    value: sortingHpaEnums.PER_CELL_TYPE_GROUP,
    label: sortingHpaTypes.PER_CELL_TYPE_GROUP,
  },
];

export const sortingHpaByTissuesTypes = {
  ALPHABETICAL: 'Alphabetical',
  BY_TISSUES_EXPRESSION: 'By Expression',
};

export const sortingHpaByTissuesEnums = {
  ALPHABETICAL: 'ALPHABETICAL',
  BY_TISSUES_EXPRESSION: 'BY_TISSUES_EXPRESSION',
};

export const sortingHpaByTissuesOptions = [
  {
    type: sortingHpaEnums.ALPHABETICAL,
    value: sortingHpaEnums.ALPHABETICAL,
    label: sortingHpaTypes.ALPHABETICAL,
  },
  {
    type: sortingHpaByTissuesEnums.BY_TISSUES_EXPRESSION,
    value: sortingHpaByTissuesEnums.BY_TISSUES_EXPRESSION,
    label: sortingHpaByTissuesTypes.BY_TISSUES_EXPRESSION,
  },
];

export const cptacTissuesNames = {
  HEALTHY: 'Normal/Healthy Tissues',
  TUMOR: 'Tumor Tissues'
};

export const colors = ['#920000', '#F5C869', '#798989', '#73B761', '#8b8173', '#224624', '#8250C4', '#33001a',
  '#FEFE6C', '#23FD22', '#A52E2E', '#E8C217', '#73C3C3', '#002054', '#480091', '#D3004C', '#DA6C00', '#009191', '#006DDB', '#FFBBED'];
