import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Concept from '../../../common/ConceptItem/Concept';
import TissuesSelect from '../../common/TissuesSelect/TissuesSelect';
import SelectInput from '../../../common/Inputs/SelectInput/SelectInput';
import SelectGene from '../../../common/SelectGene/SelectGene';
// Store
import * as ACTIONS from '../../common/SetAnalysisMethodSelection/actions';
import * as SELECTORS from '../../common/SetAnalysisMethodSelection/selectors';
import { getAvailableSetsAction } from '../../../Sets/SetsList/reducer';
import { getAvailableSetsListSelector } from '../../../Sets/SetsList/selectors';
// Utils
import { factorLogicValues } from './enum';
// Styles
import './NetworkAnalysisSettings.scss';

const propTypes = {
  selectGeneForNetworkAnalysis: PropTypes.func,
  selectTissueType: PropTypes.func,
  selectedTissueType: PropTypes.string,
  selectSetForNetworkAnalysisSettings: PropTypes.func,
  closeModal: PropTypes.func,
  networkAnalysisSettings: PropTypes.instanceOf(Object),
  tissuesItems: PropTypes.instanceOf(Array),
  allAvailableNotComplexTypeUserSets: PropTypes.instanceOf(Array),
  changeFactorLogicValue: PropTypes.func,
  factorLogicValue: PropTypes.string,
  getAvailableSets: PropTypes.func,
  selectedTissueOption: PropTypes.instanceOf(Object),
};

const NetworkAnalysisSettings = (props) => {
  const {
    closeModal,
    tissuesItems,
    getAvailableSets,
    selectTissueType,
    factorLogicValue,
    selectedTissueType,
    selectedTissueOption,
    changeFactorLogicValue,
    networkAnalysisSettings,
    selectGeneForNetworkAnalysis,
    allAvailableNotComplexTypeUserSets,
    selectSetForNetworkAnalysisSettings,
  } = props;

  useEffect(() => {
    if (!allAvailableNotComplexTypeUserSets.length) {
      getAvailableSets();
    }
  }, []);

  const { selectSetOptions, selectSetValue } = useMemo(() => (
    allAvailableNotComplexTypeUserSets.reduce((d, set) => {
      const data = { ...d };
      const option = {
        value: set.id,
        label: set.name.length > 85 ? `${set.name.slice(0, 85)}...` : set.name,
      };

      if (set.id === networkAnalysisSettings.selectedSetId) {
        data.selectSetValue = option;
      }

      data.selectSetOptions.push(option);

      return data;
    }, { selectSetOptions: [], selectSetValue: null })
  ), [allAvailableNotComplexTypeUserSets, networkAnalysisSettings]);

  const handleSelectSet = useCallback((option) => {
    if (option) {
      selectSetForNetworkAnalysisSettings(option.value);
    } else {
      selectSetForNetworkAnalysisSettings(option);
    }
  }, [selectSetForNetworkAnalysisSettings]);

  return (
    <div className="network-analysis-modal">
      <div className="network-analysis-modal__title title3">
        Network Analysis Settings
      </div>
      <div className="network-analysis-modal__block">
        <div className="label">
          Select Gene:
          <span className="mandatory-span"><sup>*</sup></span>
        </div>
        <SelectGene
          onSubmit={(d) => { selectGeneForNetworkAnalysis({ ...d, name: d.value }); }}
        />
        {
          networkAnalysisSettings.gene && networkAnalysisSettings.gene.name &&
          <div className="network-analysis-modal__concepts">
            <Concept
              noInfoButton={true}
              deleteCb={() => selectGeneForNetworkAnalysis(null)}
              concept={networkAnalysisSettings.gene}
            />
          </div>
        }
      </div>
      <div className="network-analysis-modal__block">
        <div className="label">
          Tissue:
          <span className="mandatory-span"><sup>*</sup></span>
        </div>
        <TissuesSelect
          placeholder="Select tissue"
          value={selectedTissueOption}
          onSelect={selectTissueType}
          options={tissuesItems}
        />
      </div>
      <div className="network-analysis-modal__block">
        <div className="label">
          Inclusion of Transcription Factor logic:
          <span className="mandatory-span"><sup>*</sup></span>
        </div>
        <SelectInput
          bordered={true}
          options={factorLogicValues}
          onSelect={d => changeFactorLogicValue(d.value)}
          value={factorLogicValues.find(d => d.value === factorLogicValue)}
        />
      </div>
      <div className="network-analysis-modal__block">
        <div className="label">
          Select Set:
        </div>
        <SelectInput
          isClearable={true}
          bordered={true}
          value={selectSetValue}
          options={selectSetOptions}
          onSelect={handleSelectSet}
        />
      </div>
      <div className="network-analysis-modal__block network-analysis-modal__block_btns">
        <button
          onClick={closeModal}
          className="button button-primary mr-15"
          disabled={!selectedTissueType || !networkAnalysisSettings.gene || !factorLogicValue}
        >
          Continue
        </button>
        <button
          type="button"
          className="button"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

NetworkAnalysisSettings.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    networkAnalysisSettings: SELECTORS.getNetworkAnalysisSettings(state),
    tissuesItems: SELECTORS.getNetworkAnalysisTissuesForMenu(state),
    types: SELECTORS.getNetworkAnalysisTissuesTypes(state),
    selectedTissueType: SELECTORS.getSelectedNetworkAnalysisTissueType(state),
    allAvailableNotComplexTypeUserSets: getAvailableSetsListSelector(state),
    factorLogicValue: SELECTORS.getFactorLogicValue(state),
    selectedTissueOption: SELECTORS.getSelectedNetworkAnalysisTissueOption(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAvailableSets() {
      dispatch(getAvailableSetsAction());
    },
    changeFactorLogicValue(data) {
      dispatch(ACTIONS.changeFactorLogicValueAction(data));
    },
    closeModal() {
      dispatch(ACTIONS.closeRankingModal());
    },
    selectGeneForNetworkAnalysis(data) {
      dispatch(ACTIONS.selectGeneForNetworkAnalysis(data));
    },
    selectTissueType(data) {
      dispatch(ACTIONS.selectNetworkAnalysisTissueType(data));
    },
    selectSetForNetworkAnalysisSettings(data) {
      dispatch(ACTIONS.selectSetForNetworkAnalysisSettings(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkAnalysisSettings);
