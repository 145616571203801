import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { linksForTables } from '../GeneDiseasePage/enum';
import { insertHTML, openSetResultInNewTab } from '../../Utils/Utils';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Components
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import CircosDiagram from '../../graphics/CircosDiagram/CircosDiagram';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import NoData from '../../common/NoData/NoData';
// Store
import { loadNextChapterAction } from '../GeneDiseasePage/actions';
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import { initAnimalModelAction } from './actions';
import {
  getAnimalModelData,
  getLoading,
  getError,
} from './selectors';
// Styles
import './GeneDiseaseAnimalModelSection.css';

const propTypes = {
  initAnimalModel: PropTypes.func,
  highlightedTissueIds: PropTypes.instanceOf(Array),
  geneId: PropTypes.string,
  diseaseId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  animalModelData: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  loadNextChapter: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  pageName: PropTypes.string,
  navigate: PropTypes.func,
};

class GeneDiseaseAnimalModelSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
      popup: false,
    };
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.initPage();
  }

  initPage = () => {
    const {
      initAnimalModel,
      geneId,
      diseaseId,
      highlightedTissueIds,
      loadNextChapter,
      animalModelData,
      itsNewConcept,
    } = this.props;

    if (highlightedTissueIds && (itsNewConcept || isEmpty(animalModelData))) {
      initAnimalModel({
        geneId,
        diseaseId,
        highlightedTissueIds,
      });
    } else {
      loadNextChapter('metabolites');
    }
  };

  publicationDetails = (id) => {
    this.props.navigate(`${RELATIVE_PATH}/publication-details/${id}`);
  };

  openPublicationsPopup = () => {
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  genesCell = ({ id, name }, rowIndex, columnIndex, pathForLink) => {
    const link = `${RELATIVE_PATH}/${pathForLink}/${id}`;
    const uniqueKey = `animal-model-tooltip-${id}-${rowIndex}-${columnIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      animalModelData,
      gene,
      disease,
      loading,
      error,
      pageName,
    } = this.props;
    const { popup } = this.state;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';

    const tableSettings = {
      width: 1100,
      headerHeight: 70,
      rowHeight: 50,
    };

    const columns = [{
      label: geneName,
      dataKey: 'setANode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setANode ? this.genesCell(rowData.setANode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: 'Gene neighbor node',
      dataKey: 'setBNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setBNode ? this.genesCell(rowData.setBNode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: diseaseName,
      dataKey: 'setCNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setCNode ? this.genesCell(rowData.setCNode, rowIndex, columnIndex, linksForTables.conceptDetails) : null
      ),
    }, {
      label: `Pub count with ${geneName} [A+B]`,
      dataKey: 'pubsAB',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{geneName} </span><br />
            <span>[A+B]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsAB > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsAB > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsAB}
          </span>
        );
      },
    }, {
      label: `Pub count with ${diseaseName} [B+C]`,
      dataKey: 'pubsBC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{diseaseName} </span><br />
            <span>[B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsBC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsBC > 0) {
                this.conceptsNames = [rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsBC}
          </span>
        );
      },
    }, {
      label: `Pub count genes and ${diseaseName} [A+B+C]`,
      dataKey: 'pubsABC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count genes and </span><br />
            <span>{diseaseName} </span><br />
            <span>[A+B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsABC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsABC > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsABC}
          </span>
        );
      },
    }];

    return (
      <div>
        { !loading &&
        <div>
          <div className="gene-disease-section-title">
            <span>Animal model</span>
            <span
              role="presentation"
              className="fa fa-info-circle icon"
              onClick={() => {
                this.setState({
                  showTitleHelperText: !this.state.showTitleHelperText,
                });
              }}
            />
            <div
              className={
                classnames(
                  'gene-disease-section-title-helper-text',
                  { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                )
              }
            >
              This section provides the molecular interactions that {geneName} has with genes that are genetically modified
              in an animal model for {diseaseName}. This is visualised in a <span className="strongBold">circle diagram</span> as follows:
              <ul>
                <li>The <span className="strongBold">colored ring</span> represents
                  the genes associated with {diseaseName} that
                  interact with {geneName} where:
                <ul>
                  <li>Each ring segment represents a gene/protein</li>
                  <li>The top 15 genes/proteins, based on the number of publications mentioning the gene/protein
                      and {diseaseName}, are shown
                  </li>
                  <li>
                      The size of each protein segment is based on the total number of molecular interactions it has
                      with other genes (in general, not specific to the disease)
                  </li>
                </ul>
                </li>
                <li>
                  If a gene is expressed in one of the highlighted tissues (see the ‘Expression’ section), these are
                  shown as <span className="strongBold">expression bars</span> protruding from the gene segment.
                </li>
                <li>
                  The <span className="strongBold">inner circle</span> shows the molecular interactions between the genes.
                </li>
              </ul>
              <div>The following <span className="strongBold">actions</span> are possible on the circle diagram :</div>
              <ul>
                <li><span className="strongBold">Click</span> on a gene segment to highlight its interactions</li>
                <li><span className="strongBold">Hover</span> over any element in the circle diagram for more details</li>
              </ul>
              <div>
                A <span className="strongBold">table</span> is shown that provides the publication and database references for the interactions displayed
                in the circle diagram. If {geneName} has more than the top 15 interactions displayed in the circle diagram,
                these can be accessed here as well.
              </div>
            </div>
          </div>
          <div className="gene-disease-section-body">
            { animalModelData.hasAnimalAssociations ?
              <div>
                {geneName} has at least one animal model that is associated with {diseaseName} as described by
              </div> :
              <div>
                {geneName} does not have any animal model that is associated with {diseaseName}
              </div>
            }
            {
              animalModelData.hasAnimalAssociations && animalModelData.associatedPublications.map(pub => (
                <div
                  key={pub.id}
                  role="presentation"
                  className="search-result-item-title"
                  onClick={() => {
                    this.publicationDetails(pub.id);
                  }}
                  dangerouslySetInnerHTML={insertHTML(pub.title)}
                />
              ))
            }
            {animalModelData.indirectPublications.length > 0 &&
            <div>
              Indirect Publications:
            </div>
            }
            {
              animalModelData.indirectPublications.map((pub, index, array) => (
                <span key={pub.id}>
                  <Link
                    to={`${RELATIVE_PATH}/publication-details/${pub.id}`}
                    className="search-result-item-title"
                    target="blank"
                    dangerouslySetInnerHTML={insertHTML(pub.title)}
                  />
                  { index !== (array.length - 1) && <span>,&nbsp;&nbsp;</span>}
                </span>
              ))
            }
            {
              !animalModelData.hasTransitConnectionToDisease &&
              animalModelData.data &&
              animalModelData.data.length > 0 &&
                <div>
                  {geneName} has molecular interaction with one or more proteins
                  that have an animal model that is associated with {diseaseName}
                </div>
            }
            {
              animalModelData.data ?
                <div>
                  {
                    animalModelData.chords &&
                    animalModelData.chords.length &&
                    <CircosDiagram
                      targetGene={gene}
                      data={animalModelData.data}
                      chords={animalModelData.chords}
                      geneColor={animalModelData.genesColor}
                      id="animalModelCircos"
                      geneName={pageName}
                      chartName="Animal model"
                    />
                  }
                  <NoData
                    show={
                      animalModelData.publications &&
                      !animalModelData.publications.length
                    }
                    customClassName="gene-disease__no-data"
                    message="There is no data to display table."
                  />
                  {
                    animalModelData.publications &&
                    animalModelData.publications.length > 0 &&
                    <div className="gene-disease-animal-model-table">
                      <div className="gene-disease-animal-model-table-header">
                        <button
                          onClick={() => openSetResultInNewTab(animalModelData.fullSetABC)}
                          type="button"
                          className="button button-primary"
                        >
                          <ReactTooltip
                            id="showAllDiseaseGenes"
                            place="bottom"
                            type="light"
                          >
                            Open a table containing all interactions with disease annotated genes
                          </ReactTooltip>
                          <span
                            data-tip={true}
                            data-for="showAllDiseaseGenes"
                          >
                          Show all animal model genes
                          </span>
                        </button>
                      </div>
                      <SimpleTable
                        settings={tableSettings}
                        height={animalModelData.publications.length
                          ? animalModelData.publications.length * tableSettings.rowHeight + tableSettings.headerHeight //eslint-disable-line
                          : tableSettings.headerHeight + 40}
                        data={animalModelData.publications}
                        columns={columns}
                      />
                    </div>
                  }
                </div> :
                <div>
                  {geneName} has no molecular interaction with one or more proteins  that have an animal model that is associated with {diseaseName}”
                </div>
            }
          </div>
        </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneDiseaseAnimalModelSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    animalModelData: getAnimalModelData(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initAnimalModel(data) {
      dispatch(initAnimalModelAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneDiseaseAnimalModelSection));
