import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const locationChangeRequestAction = createAction('@@locationChange/REQUEST');
export const locationChangeSuccessAction = createAction('@@locationChange/SUCCESS');
export const chatBotAction = createAction('@@locationChange/CHAT_BOT_ACTION');

const routeInitialState = fromJS({
  locationBeforeTransitions: null,
  locationAfterTransitions: null,
  locationHistory: [],
  openedChatBot: null,
});

const locationReducer = handleActions(
  {
    [locationChangeSuccessAction]: (state, { payload }) => {
      const locationHistory = state.get('locationHistory').toJS();

      if (locationHistory.length === 3) {
        locationHistory.splice(0, 1);
      }

      locationHistory.push(`${payload.pathname}${payload.search}`);

      return state.merge({
        locationBeforeTransitions: state.get('locationAfterTransitions'),
        locationAfterTransitions: fromJS(payload),
        locationHistory: fromJS(locationHistory),
      });
    },
    [chatBotAction]: (state, { payload }) => (
      state.update('openedChatBot', () => payload)
    ),
  }, routeInitialState
);

export default locationReducer;
