import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Coponents
import Error from '../../../../common/Error/Error';
import Loader from '../../../../common/Loader/Loader';
import SimpleTable from '../../../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../../../Concept/ShortConceptCard/ShortConceptCardCell';
// Utils
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import { getScreenWidth } from '../../../../Utils/Utils';
// Store
import { pathDetailsSmallMoleculesRequested, sort } from './actions';
import {
  getLoading,
  getError,
  getPathDetailsSmallMoleculesTable,
  getPathDetailsSmallMoleculesSorting,
} from './selectors';

const propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  fetchData: PropTypes.func,
  pathDetailsSmallMoleculesTable: PropTypes.instanceOf(Array),
  pathDetailsSmallMoleculesSorting: PropTypes.instanceOf(Object),
};

class SmallMoleculesSection extends React.Component {
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    this.props.fetchData();
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  genesCell = ({ id, name }, rowIndex, goToGeneDetails) => {
    const link = goToGeneDetails ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;

    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  columns = [
    {
      label: 'Gene',
      dataKey: 'gene',
      width: 300,
      exportCSV: true,
      cellRenderer: ({ rowData, rowIndex }) => (
        rowData.gene ? this.genesCell(rowData.gene, rowIndex, true) : null
      ),
    },
    {
      label: 'Molecule',
      dataKey: 'molecule',
      width: 400,
      exportCSV: true,
      cellRenderer: ({ rowData, rowIndex }) => (
        rowData.molecule ? this.genesCell(rowData.molecule, rowIndex) : null
      ),
    },
    {
      label: 'Mechanism',
      dataKey: 'mechanism',
      width: 100,
      exportCSV: true,
    },
    {
      label: 'Value',
      dataKey: 'value',
      width: 100,
      exportCSV: true,
    }];

  render() {
    const {
      loading,
      error,
      pathDetailsSmallMoleculesTable,
      pathDetailsSmallMoleculesSorting: { sortBy, sortDirection },
    } = this.props;

    const screenWidth = getScreenWidth();
    const tableWidth = 900;

    let tableSettings = {
      width: screenWidth < tableWidth ? screenWidth : tableWidth,
      height: 500,
      headerHeight: 50,
      rowHeight: 50,
      sortBy,
      sortDirection,
    };

    if (pathDetailsSmallMoleculesTable.length < 20) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    return (
      <div>
        <div className="path-details-section-header">
          Small molecules
        </div>
        <div className="path-details-separator" />
        {
          !loading && !error &&
          <SimpleTable
            innerRef={this.simpleTableRef}
            settings={tableSettings}
            data={pathDetailsSmallMoleculesTable}
            manageable={false}
            sortAction={sort}
            columns={this.columns}
            fixedHeight={true}
          />
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} error={error} />
      </div>
    );
  }
}

SmallMoleculesSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
    error: getError(state),
    pathDetailsSmallMoleculesTable: getPathDetailsSmallMoleculesTable(state),
    pathDetailsSmallMoleculesSorting: getPathDetailsSmallMoleculesSorting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData() {
      dispatch(pathDetailsSmallMoleculesRequested());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmallMoleculesSection);
