export const textTypeDropdownValues = [
  {
    label: 'Gene identifier',
    value: 'GENE',
  },
  {
    label: 'Protein identifier',
    value: 'PROTEIN',
  },
  {
    label: 'Compound identifier',
    value: 'COMPOUND',
  },
  {
    label: 'Other',
    value: 'OTHER_TEXT',
  },
  {
    label: 'Ignore: When Selected, do not import this column',
    value: 'IGNORE',
  },
];

export const numberTypeDropdownValues = [
  {
    label: 'RNA Expression: FPKM',
    value: 'FPKM',
  },
  {
    label: 'RNA Expression: TPM',
    value: 'TPM',
  },
  {
    label: 'RNA Expression: RPKM',
    value: 'RPKM',
  },
  {
    label: 'Differential Expression: Log2 Fold change',
    value: 'LOG2_FOLD_CHANGE',
  },
  {
    label: 'siRNA: Inhibition',
    value: 'INHIBITION',
  },
  {
    label: 'Pharmacology: IC50',
    value: 'IC50',
  },
  {
    label: 'Pharmacology: EC50',
    value: 'EC50',
  },
  {
    label: 'Statistics: Z-score',
    value: 'Z_SCORE',
  },
  {
    label: 'Statistics: p-value',
    value: 'P_VALUE',
  },
  {
    label: 'Other',
    value: 'OTHER_NUMBER',
  },
  {
    label: 'Ignore: When Selected, do not import this column',
    value: 'IGNORE',
  },
];

export const dropDownValues = {
  textTypeDropdownValues,
  numberTypeDropdownValues,
};
