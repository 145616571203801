import { takeLatest } from 'redux-saga/effects';

import * as a from '../reducer';

import { sagaExpressionChapter } from './workers/getExpressionChapter';
import { sagaSamplesDataForCsvExport } from './workers/getSamplesDataForCsvExport';

function* geneDetailsExpressionChapterSaga() {
  yield takeLatest(a.geneExpressionInitRequest, sagaExpressionChapter);
  yield takeLatest(a.getSamplesDataForCsvExportAction, sagaSamplesDataForCsvExport);
}

export default geneDetailsExpressionChapterSaga;
