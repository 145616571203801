import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

// Utils
import { getScreenWidth, isEmptyObject } from '../../../Utils/Utils';
import { buildSearchLink } from '../../../Search/utils';
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Components
import Error from '../../../common/Error/Error';
import NoData from '../../../common/NoData/NoData';
import Loader from '../../../common/Loader/Loader';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ExportTable from '../../../common/ExportTable/ExportTable';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import GeneDiseaseSection from '../../../Search/Components/GeneDiseaseSection/GeneDiseaseSection';
import { getRecommedationsDataSelector } from '../../../common/PublicationsList/store/selectors';
// Store
import { openGenesDetail } from '../../../common/SetAnalysisGenesDetail/actions';
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
import { getPublicationIdSelector } from '../../selectors';
// Styles
import './PublicationDetailsTriples.css';

const propTypes = {
  currentPageData: PropTypes.instanceOf(Array),
  changePage: PropTypes.func,
  totalPages: PropTypes.number,
  openGenesDetail: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  loadingKey: PropTypes.bool,
  error: PropTypes.string,
  pageNumber: PropTypes.number,
  createHeaderRendererWithSaveAs: PropTypes.func,
  pubId: PropTypes.string,
  pubIdFromState: PropTypes.number,
  conceptIds: PropTypes.instanceOf(Array),
  fetchPublicationTriples: PropTypes.func,
  fetchTriplesByIds: PropTypes.func,
  recommendations: PropTypes.instanceOf(Object),
  allData: PropTypes.instanceOf(Array),
  exportName: PropTypes.string,
};

class PublicationDetailsTriples extends React.Component {
  constructor(props) {
    super(props);
    const {
      createHeaderRendererWithSaveAs,
      currentPageData = [],
      pubId,
      pubIdFromState,
    } = this.props;
    const publicationId = pubId || pubIdFromState;

    this.columns = [
      {
        label: 'Subject',
        dataKey: 'subject',
        sortPath: ['subject', 'name'],
        width: 500,
        exportCSV: true,
        headerRenderer: createHeaderRendererWithSaveAs ? createHeaderRendererWithSaveAs(currentPageData) : undefined,
        cellRenderer: ({ cellData, rowIndex }) =>
          this.conceptCell(cellData, rowIndex),
      },
      {
        label: 'Predicate',
        dataKey: 'predicate',
        sortPath: ['predicate', 'name'],
        width: 400,
        exportCSV: true,
        cellRenderer: ({ cellData }) => (
          <span
            role="presentation"
            title={cellData.name}
          >
            {cellData.name}
          </span>
        ),
      },
      {
        label: 'Object',
        dataKey: 'object',
        sortPath: ['object', 'name'],
        width: 400,
        exportCSV: true,
        cellRenderer: ({ cellData, rowIndex }) =>
          this.conceptCell(cellData, rowIndex),
      },
      {
        label: 'Measures',
        dataKey: 'measures',
        width: 450,
        disableSort: true,
        exportCSV: true,
        csvRenderer: rowData => `${rowData.name} = ${rowData.value || ''}`,
        cellRenderer: ({ rowData }) => {
          const measures = rowData.measures || [];
          const measureSpans = measures.map((measure, index) => <span key={index}>{measure.name} = {measure.value}</span>);
          const measuresTitle = measures.map(measure => `\n${measure.name} = ${measure.value || ''}`);
          return (<div className="flex-column cell-row" title={measuresTitle}>{measureSpans}</div>);
        },
      },
      {
        label: 'Sources',
        dataKey: 'sourcesName',
        width: 250,
        sortValueMapper: item => (item.get('sourcesName').toArray().length
          ? item.get('sourcesName').toArray()[0]
          : ''),
        exportCSV: true,
        cellRenderer: ({ rowData }) => {
          const sources = rowData.sourcesName || [];
          const sourcesTitle = sources.map(source => `\n${source}`);
          const sourceSpans = sources.map((source, index) => <span key={index} title={sourcesTitle}>{source}</span>);
          return (<div className="flex-column" title={sourcesTitle}>{sourceSpans}</div>);
        },
      },
      {
        label: 'Publications',
        dataKey: 'cooccurrence',
        width: 200,
        exportCSV: !!publicationId,
        visible: !!publicationId,
        cellRenderer: ({ rowData }) => {
          const link = buildSearchLink({
            plainValue: `${rowData.object.name} AND ${rowData.subject.name}`,
            exactValue: `${rowData.object.id} AND ${rowData.subject.id}`,
          });

          return (
            <Link to={link} className="link" target="_blank">
              {rowData.cooccurrence}
            </Link>
          );
        },
      }];
    this.simpleTableRef = React.createRef(null);
  }

  componentDidMount() {
    const {
      pubId,
      pubIdFromState,
      fetchPublicationTriples,
      conceptIds,
      fetchTriplesByIds,
    } = this.props;
    const publicationId = pubId || pubIdFromState;
    if (conceptIds) {
      fetchTriplesByIds({ ids: conceptIds });
    } else {
      fetchPublicationTriples({ id: publicationId });
    }
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  conceptCell = ({ id, name, category }, rowIndex) => {
    let path = 'concept-details';
    if (category === 'Genes & Molecular Sequences') path = 'gene-details';
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
        link={`${RELATIVE_PATH}/${path}/${id}`}
      />
    );
  };

  handlePageClick = (page) => {
    this.props.changePage(page.selected);
  };

  openGenesDetail = (concept) => {
    this.props.openGenesDetail({
      concept,
      genesDetailAPIKey: 'categoryTargetCandidates',
    });
  };

  /* Table actions */
  actions = {
    checkAll: ACTIONS.checkAll,
    checkItem: ACTIONS.checkItem,
    invertSelection: ACTIONS.invertSelection,
    removeSelected: ACTIONS.removeSelected,
  };

  getRowHeight = ({ index }) => {
    const { currentPageData } = this.props;
    let length;
    try {
      length = Math.max(currentPageData[index].sourcesName.length, currentPageData[index].measures.length); // eslint-disable-line
    } catch (e) {
      console.log(e);
    }
    if (length <= 2) {
      return 50;
    } if (length > 2 && length <= 11) {
      return (length * 18) + 10;
    } if (length > 11 && length <= 14) {
      return (length * 17) + 10;
    } if (length > 15 && length < 200) {
      return (length * 17.8) + 10;
    }
    return (length * 17.745) + 10;
  };

  render() {
    const {
      currentPageData,
      totalPages,
      pageNumber,
      sorting: { sortBy, sortDirection },
      loadingKey,
      error,
      allData,
      exportName,
      recommendations,
    } = this.props;

    const screenWidth = getScreenWidth();
    const tableWidth = 1100;

    let tableSettings = {
      width: screenWidth < tableWidth ? screenWidth : tableWidth,
      headerHeight: 50,
      sortBy,
      sortDirection,
      rowHeight: this.getRowHeight,
      useDynamicRowHeight: true,
    };

    if (currentPageData.length < 20) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    const wrapperStyle = {
      width: screenWidth > tableWidth ? tableWidth : screenWidth - 40,
    };

    return (
      <div className="pub-details__triples pub-details-container">
        <div className="pub-details__subtitle">Associated links</div>
        {
          !isEmptyObject(recommendations.GENE_DISEASE) &&
          <GeneDiseaseSection
            openInNewTab={true}
            recommendations={recommendations.GENE_DISEASE}
          />
        }
        {
          currentPageData.length > 0 && !loadingKey &&
          <div className="publications-triples-table">
            <div
              className="flex-grid justify-content-flex-end"
              style={wrapperStyle}
            >
              <>
                <div className="paginationContainer">
                  {
                    totalPages > 1 &&
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  }
                </div>
                <ExportTable
                  content={allData}
                  columns={this.columns}
                  fileName={`${exportName}_Associated_links`}
                />
              </>
            </div>
            <SimpleTable
              innerRef={this.simpleTableRef}
              settings={tableSettings}
              data={currentPageData}
              actions={this.actions}
              sortAction={ACTIONS.sort}
              columns={this.columns}
              autoSize={true}
              dynamicHeight={true}
            />
          </div>
        }
        <NoData
          show={currentPageData.length === 0 && !loadingKey}
          customClassName="pub-details-page__no-data"
        />
        <Loader isLoading={loadingKey && !error} />
        <Error
          show={!!error && !loadingKey}
          error={error}
          customClassName="text-center error-text"
        />
      </div>
    );
  }
}

PublicationDetailsTriples.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    allData: SELECTORS.getAllData(state),
    currentPageData: SELECTORS.getCurrentPageData(state),
    pageNumber: SELECTORS.getPageNumber(state),
    totalPages: SELECTORS.getTotalPages(state),
    sorting: SELECTORS.tableSorting(state),
    loadingKey: SELECTORS.getLoadingKey(state),
    error: SELECTORS.getError(state),
    pubIdFromState: getPublicationIdSelector(state),
    recommendations: getRecommedationsDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(ACTIONS.changePageAction(data));
    },
    reset() {
      dispatch(ACTIONS.reset());
    },
    fetchPublicationTriples(data) {
      dispatch(ACTIONS.fetchPublicationTriplesAction(data));
    },
    fetchTriplesByIds(data) {
      dispatch(ACTIONS.fetchTriplesByIdsAction(data));
    },
    loading() {
      dispatch(ACTIONS.loading());
    },
    openGenesDetail(data) {
      dispatch(openGenesDetail(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationDetailsTriples);
