import {
  SORT,
} from '../SimpleTable/constants';

import {
  GENES_DETAIL_OPEN,
  GENES_DETAIL_CLOSE,
  GENES_DETAIL_REQUESTED,
  GENES_DETAIL_TABLE,
  SAVE_NEW_SET_FROM_ANALYTICS,
} from './constants';

export const openGenesDetail = data => (
  {
    type: GENES_DETAIL_OPEN,
    data,
  }
);

export const closeGenesDetail = () => (
  {
    type: GENES_DETAIL_CLOSE,
  }
);

export const initGenesDetail = data => (
  {
    type: GENES_DETAIL_REQUESTED,
    data,
  }
);

export const sort = data => (
  {
    type: GENES_DETAIL_TABLE + SORT,
    data,
  }
);

export const saveNewSetFromAnalyticsAction = data => (
  {
    type: SAVE_NEW_SET_FROM_ANALYTICS,
    data,
  }
);
