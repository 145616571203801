import React from 'react';
import PropTypes from 'prop-types';

// Components
import CreateSetFilterCheckbox from '../CreateSetFilterCheckbox/CreateSetFilterCheckbox';
// Utils
import { geneSetFiltersNames, geneSetFiltersLabels } from '../../enums';

const propTypes = {
  availableFilters: PropTypes.instanceOf(Object),
  conceptsIds: PropTypes.instanceOf(Array),
  searchTerm: PropTypes.string,
  getGeneFilterOptions: PropTypes.func,
};

const CreateSetDiseasesFilters = (props) => {
  const { availableFilters } = props;

  return (
    <>
      {
        availableFilters[geneSetFiltersNames.DISEASES_DYSREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_DYSREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_DYSREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.DISEASES_UPREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_UPREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_UPREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.DISEASES_DOWNREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_DOWNREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_DOWNREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.DISEASES_GENETIC_VARIATION_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_GENETIC_VARIATION_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_GENETIC_VARIATION_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.DISEASES_CAUSAL_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_CAUSAL_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_CAUSAL_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.DISEASES_ANIMAL_MODEL_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.DISEASES_ANIMAL_MODEL_FILTER}
          fieldLabel={geneSetFiltersLabels.DISEASES_ANIMAL_MODEL_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_UPREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_UPREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_UPREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_GENETIC_VARIATION_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_GENETIC_VARIATION_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_GENETIC_VARIATION_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_CAUSAL_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_CAUSAL_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_CAUSAL_FILTER}
        />
      }
      {
        availableFilters[geneSetFiltersNames.GENE2DISEASES_ANIMAL_MODEL_FILTER] &&
        <CreateSetFilterCheckbox
          fieldName={geneSetFiltersNames.GENE2DISEASES_ANIMAL_MODEL_FILTER}
          fieldLabel={geneSetFiltersLabels.GENE2DISEASES_ANIMAL_MODEL_FILTER}
        />
      }
    </>
  );
};

CreateSetDiseasesFilters.propTypes = propTypes;

export default CreateSetDiseasesFilters;
