import { createSelector } from 'reselect';

import { ACTIVATE_CREATED_USER_FORM } from '../constants';

const activateCreatedUserFormSelector = state => state.getIn(['form', ACTIVATE_CREATED_USER_FORM]);
const showActivateUserModalSelector = state => state.getIn(['activateCreatedUserReducer', 'showModal']);
const activateUserCheckError = state => state.getIn(['activateCreatedUserReducer', 'error']);
const activateUserCheckTokenError = state => state.getIn(['activateCreatedUserReducer', 'tokenError']);

export const getActivateUserPasswordForm = createSelector(
  activateCreatedUserFormSelector,
  (form) => {
    const password = form.getIn(['values', 'password']);
    return {
      password,
    };
  }
);

export const getShowActivateUserModal = createSelector(
  showActivateUserModalSelector,
  data => data
);

export const getActivateUserCheckTokenError = createSelector(
  activateUserCheckTokenError,
  data => data,
);

export const getActivateUserCheckError = createSelector(
  activateUserCheckError,
  data => data,
);
