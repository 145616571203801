import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../ModalComponent/ModalComponent';
// Styles
import './InfoDialog.scss';

const propTypes = {
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  confirmBtnText: PropTypes.string,
  customClassName: PropTypes.string,
};

const InfoDialog = (props) => {
  const {
    text,
    isOpen,
    closeCb,
    children,
    onConfirm,
    confirmBtnText = 'Close',
    customClassName,
  } = props;

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    closeCb();
  }, [onConfirm, closeCb]);

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      customClassName={customClassName}
    >
      <div className="info-dialog">
        <div className="info-dialog__content">
          {children || text}
        </div>
        <div className="info-dialog__controls">
          <button onClick={handleConfirm} className="btn">
            {confirmBtnText}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

InfoDialog.propTypes = propTypes;

export default React.memo(InfoDialog);
