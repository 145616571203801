import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  formatHeatmapData,
  formatNetworkAnalysisHeatmapData,
} from '../utils';
import {
  getHeatmapDataAction,
  getNetworkAnalysisHeatmapDataAction,
  setHeatmapDataAction,
  throwHeatmapErrorAction,
  getHeatmapCSVAction,
  setHeatmapCSVAction,
} from './reducer';
import { heatmapCooccurrenceScoreTypes, HeatmapTypeEnum } from '../constants';

function* getHeatmapDataWorker(action) {
  try {
    const requestData = {
      post: action.payload,
    };
    const { data } = yield call(Api.getHeatMapData, requestData);
    yield put(setHeatmapDataAction({
      data: formatHeatmapData(data, heatmapCooccurrenceScoreTypes[action.payload.scoreType]),
      type: heatmapCooccurrenceScoreTypes[action.payload.scoreType],
    }));
  } catch (e) {
    yield put(throwHeatmapErrorAction(e.message));
  }
}

function* getNetworkAnalysisHeatmapDataWorker(action) {
  try {
    const requestData = {
      post: action.payload,
    };
    const { data } = yield call(Api.getHeatMapForNetworkAnalysis, requestData);
    yield put(setHeatmapDataAction({
      data: formatNetworkAnalysisHeatmapData(data),
      dataForSave: data,
      type: HeatmapTypeEnum.SCORE,
    }));
  } catch (e) {
    yield put(throwHeatmapErrorAction(e.message));
  }
}

function* getHeatmapCSVWorker(action) {
  try {
    const requestData = {
      post: action.payload,
    };
    const { data } = yield call(Api.exportHeatMap, requestData);
    yield put(setHeatmapCSVAction(data));
  } catch (e) {
    yield put(throwHeatmapErrorAction(e.message));
  }
}

function* heatmapSaga() {
  yield takeLatest(getHeatmapDataAction, getHeatmapDataWorker);
  yield takeLatest(getHeatmapCSVAction, getHeatmapCSVWorker);
  yield takeLatest(getNetworkAnalysisHeatmapDataAction, getNetworkAnalysisHeatmapDataWorker);
}

export default heatmapSaga;
