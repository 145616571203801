import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { change, submit } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import FormSelect from '../../common/FormElements/FormSelect';
import FormInput from '../../common/FormElements/FormInput';
import PublicationsFilterGraph from '../../graphics/PublicationsFilterGraph/PublicationsFilterGraph';
// Enums
import { SourcesFilterEnum, SourcesOptionsEnum, PublicationSourcesOptions } from './enums';
import { searchFilterSelectOptionsEnum, SearchSourceFilterEnum } from '../../Search/enums';

const propTypes = {
  geneId: PropTypes.string,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  sortingParams: PropTypes.instanceOf(Object),
  changeYearsFormValues: PropTypes.func,
};

const LiteratureFilter = (props) => {
  const {
    handleSubmit,
    initialize,
    geneId,
    sortingParams = {},
    changeYearsFormValues,
  } = props;

  useEffect(() => {
    initialize({
      sortBy: SourcesFilterEnum.PUB_DATE,
      publicationSource: SearchSourceFilterEnum.ABSTRACT,
    });
  }, []);

  return (
    <form
      className="literature__form"
      onSubmit={handleSubmit}
    >
      <PublicationsFilterGraph
        geneId={geneId}
        showTitle={true}
        graphId="literature-section-graph"
        changeYearsValues={changeYearsFormValues}
        dateFrom={sortingParams.dateFrom}
        dateTo={sortingParams.dateTo}
        publicationSource={sortingParams.publicationSource}
      />
      <Field
        name="publicationSource"
        placeholder="Reference sources"
        component={FormSelect}
        bordered={true}
        isClearable={true}
        options={PublicationSourcesOptions}
        customClassName="search-filter-form__select"
      />
      <Field
        name="publicationTypes"
        placeholder="Publication types"
        component={FormSelect}
        isMulti={true}
        className="search-filter-form__select"
        options={searchFilterSelectOptionsEnum.types}
        bordered={true}
      />
      <Field
        name="sortBy"
        placeholder="Sort by"
        component={FormSelect}
        className="search-filter-form__select"
        options={SourcesOptionsEnum}
        bordered={true}
      />
      <div className="search-filter-form__block_rows">
        <span className="search-filter-form__label">
          Publication year
        </span>
        <div className="search-filter-form__row">
          <div className="search-filter-form__input">
            <Field
              name="dateFrom"
              placeholder="From"
              component={FormInput}
              type="number"
              onFocus={(e) => { e.target.placeholder = 'YYYY'; }}
              onBlur={(e) => { e.target.placeholder = 'From'; }}
            />
          </div>
          <div className="search-filter-form__input">
            <Field
              name="dateTo"
              placeholder="To"
              component={FormInput}
              type="number"
              onFocus={(e) => { e.target.placeholder = 'YYYY'; }}
              onBlur={(e) => { e.target.placeholder = 'To'; }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

LiteratureFilter.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    changeYearsFormValues(values) {
      dispatch(change('LITERATURE_FILTER_FORM', 'dateFrom', `${values[0]}`));
      dispatch(change('LITERATURE_FILTER_FORM', 'dateTo', `${values[1]}`));
    },
  };
}

function validate(values) {
  const errors = {};
  const dateFrom = Number(values.get('dateFrom'));
  const dateTo = Number(values.get('dateTo'));

  if (dateFrom < 1700) {
    errors.dateFrom = 'Year from value is to small';
  }
  if (dateFrom > 2100) {
    errors.dateFrom = 'Year from value is to big';
  }
  if (dateTo < 1700) {
    errors.dateTo = 'Year to value is to small';
  }
  if (dateTo > 2100) {
    errors.dateTo = 'Year to value is to big';
  }
  if (dateFrom > dateTo) {
    errors.dateFrom = 'Should be less then Year to value';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: 'LITERATURE_FILTER_FORM',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate,
    onChange: (values, dispatch) => {
      setTimeout(() => {
        dispatch(submit('LITERATURE_FILTER_FORM'));
      }, 1000);
    },
  }),
  connect(null, mapDispatchToProps)
);

export default composition(LiteratureFilter);
