import { createSelector } from 'reselect';

// Utils
import { setTypesEnum } from '../../SaveAsSet/constants';
import { EDIT_SET_FORM } from '../constants';
import { sort } from '../../../Utils/Utils';
import { getCurrentPageData, getTotalPages } from '../../../common/Pagination/utils';

const setData = state => state.getIn(['editSetReducer', 'set']);
const tags = state => state.getIn(['editSetReducer', 'set', 'tags']);
const concepts = state => state.getIn(['editSetReducer', 'set', 'items']);
const loading = state => state.getIn(['editSetReducer', 'loading']);
const error = state => state.getIn(['editSetReducer', 'error']);
const nameFilter = state => state.getIn(['editSetReducer', 'nameFilter']);
const sourceFilter = state => state.getIn(['editSetReducer', 'sourceFilter']);
const sorting = state => state.getIn(['editSetReducer', 'sorting']);
const isSaving = state => state.getIn(['editSetReducer', 'isSaving']);
const formValues = state => state.getIn(['form', EDIT_SET_FORM, 'values']);
const pages = state => state.getIn(['editSetReducer', 'pages']);

export const getEditSetSelector = createSelector(
  setData,
  data => data && data.toJS()
);

export const getEditSetDataSelector = createSelector(
  [getEditSetSelector],
  (data) => {
    if (!data) return data;
    return {
      name: data.name,
      description: data.description,
    };
  }
);

export const getEditSetTypeIsEffectSelector = createSelector(
  [getEditSetSelector],
  (data) => {
    if (!data) return false;
    return data.setType === setTypesEnum.EFFECT;
  }
);

export const getEditSetTagsSelector = createSelector(
  tags,
  data => data && data.toJS()
);

export const getEditSetConceptsSelector = createSelector(
  concepts,
  data => data && data.toJS()
);

export const getEditSetFinalDataSelector = createSelector(
  concepts,
  pages,
  sorting,
  nameFilter,
  sourceFilter,
  (data, pagesData, sortingData, nameFilterValue, sorceFilterValue) => {
    if (!data) return data;
    const { pageNumber } = pagesData.toJS();

    const sorted = sort(data, sortingData.toJS()).toJS();

    const filtered = sorted.filter((d) => {
      let nameIncludes = true;
      let sourceIncludes = true;

      if (nameFilterValue) {
        nameIncludes = !!d.name && d.name.toLowerCase().includes(nameFilterValue.toLowerCase());
      }

      if (sorceFilterValue) {
        sourceIncludes = !!d.sourceTerm && d.sourceTerm.toLowerCase().includes(sorceFilterValue.toLowerCase());
      }

      return nameIncludes && sourceIncludes;
    });

    const pagginatedConcepts = getCurrentPageData(filtered, pageNumber);

    return {
      pages: { pageNumber, totalPages: getTotalPages(filtered) },
      pagginatedConcepts,
    };
  }
);

export const getEditSetDisabledFiltersSelector = createSelector(
  [getEditSetConceptsSelector],
  (data) => {
    const disabled = { nameFilter: true, sourceFilter: true};
    if (!data) return disabled;

    disabled.nameFilter = data.every(d => !d.name);
    disabled.sourceFilter = data.every(d => !d.sourceTerm);

    return disabled;
  }
);

export const getEditSetLoadingSelector = createSelector(
  loading,
  data => data
);

export const getEditSetErrorSelector = createSelector(
  error,
  data => data
);

export const getEditSetFormValuesSelector = createSelector(
  formValues,
  data => data && data.toJS()
);

export const getEditSetSortingSelector = createSelector(
  sorting,
  data => data && data.toJS()
);

export const getEditSetIsSavingSelector = createSelector(
  isSaving,
  data => data
);

export const getEditSetRequestDataSelector = createSelector(
  [
    getEditSetSelector,
    getEditSetFormValuesSelector,
  ],
  (data, form) => ({
    ...data,
    ...form,
    tags: data.tags.map(t => t.name),
  })
);