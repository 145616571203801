import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import RelationMapConceptsList from '../RelationMapConceptsList/RelationMapConceptsList';
import RelationMapRelations from '../RelationMapRelations/RelationMapRelations';
import HideRelationTypesModal from '../HideRelationTypesModal/HideRelationTypesModal';
import HideSourceModal from '../HideSourceModal/HideSourceModal';
import RelationMapLayout from '../RelationMapLayout/RelationMapLayout';
// Utils
import { removeFixedPositionFromNodes } from '../../utils';
// Constants
import { LayoutAlgorhitms } from '../../constants';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  layout: PropTypes.instanceOf(Object),
  settings: PropTypes.instanceOf(Object),
  dimensions: PropTypes.instanceOf(Object),
  isModal: PropTypes.bool,
  allSelected: PropTypes.bool,
  updateConcept: PropTypes.func,
  updateSettings: PropTypes.func,
  toggleAllConcepts: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Object),
  semanticCategoriesOrder: PropTypes.instanceOf(Array),
  selectedConcepts: PropTypes.instanceOf(Array),
  deleteConcepts: PropTypes.func,
  setShowConfirmDialog: PropTypes.func,
  cacheData: PropTypes.func,
  updateLayout: PropTypes.func,
  updateAlgorithm: PropTypes.func,
  updateNodesIsFixed: PropTypes.func,
  updateSemCategories: PropTypes.func,
  updateSemCategoriesOrder: PropTypes.func,
  updateColorsAndShapes: PropTypes.func,
  toggleProportionalLinksLayout: PropTypes.func,
  mergeConcepts: PropTypes.func,
};

const RelationMapSidebar = (props) => {
  const {
    data,
    layout,
    isModal,
    settings,
    cacheData,
    dimensions,
    allSelected,
    updateLayout,
    updateConcept,
    updateSettings,
    deleteConcepts,
    updateAlgorithm,
    toggleAllConcepts,
    updateNodesIsFixed,
    semanticCategories,
    semanticCategoriesOrder,
    selectedConcepts,
    updateSemCategories,
    updateSemCategoriesOrder,
    setShowConfirmDialog,
    updateColorsAndShapes,
    mergeConcepts,
    toggleProportionalLinksLayout,
  } = props;

  const [showRelationTypes, setShowRelationTypes] = useState(false);
  const [showHideSourceModal, setShowHideSourceModal] = useState(false);

  function removeNodesFixedPosition() {
    updateNodesIsFixed({
      fixed: false,
      nodes: removeFixedPositionFromNodes(data.nodes),
    });
  }

  function renderContent() {
    return (
      <div className="relation-map-page__sidebar-wrap">
        {
          !isModal &&
          <RelationMapConceptsList
            concepts={data.nodes}
            allSelected={allSelected}
            cacheData={cacheData}
            updateConcept={updateConcept}
            deleteConcepts={deleteConcepts}
            toggleAllConcepts={toggleAllConcepts}
            selectedConcepts={selectedConcepts}
            setShowConfirmDialog={setShowConfirmDialog}
          />
        }
        <RelationMapRelations
          links={data.links}
          settings={settings}
          updateSettings={updateSettings}
          isCooccurrenceLayout={layout.algorithm === LayoutAlgorhitms.COOCCURRENCE}
          removeNodesFixedPosition={removeNodesFixedPosition}
          openRelationTypes={() => setShowRelationTypes(true)}
          openHideSource={() => setShowHideSourceModal(true)}
        />
        <RelationMapLayout
          layout={layout}
          concepts={data.nodes}
          cacheData={cacheData}
          dimensions={dimensions}
          updateLayout={updateLayout}
          mergeConcepts={mergeConcepts}
          updateAlgorithm={updateAlgorithm}
          semanticCategories={semanticCategories}
          semanticCategoriesOrder={semanticCategoriesOrder}
          updateSemCategories={updateSemCategories}
          updateSemCategoriesOrder={updateSemCategoriesOrder}
          updateColorsAndShapes={updateColorsAndShapes}
          removeNodesFixedPosition={removeNodesFixedPosition}
          toggleProportionalLinksLayout={toggleProportionalLinksLayout}
        />
      </div>
    );
  }

  return (
    <div className="relation-map-page__sidebar">
      { data && renderContent() }
      {
        showRelationTypes &&
        <HideRelationTypesModal
          isOpen={showRelationTypes}
          closeCb={() => setShowRelationTypes(false)}
        />
      }
      {
        showHideSourceModal &&
        <HideSourceModal
          isOpen={showHideSourceModal}
          closeCb={() => setShowHideSourceModal(false)}
        />
      }
    </div>
  );
};

RelationMapSidebar.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    layout: SELECTORS.getRelationMapLayoutSelector(state),
    settings: SELECTORS.getRelationMapSettingsSelector(state),
    allSelected: SELECTORS.getRelationMapAllSelectedSelector(state),
    dimensions: SELECTORS.getRelationMapDimensionsSelector(state),
    semanticCategories: SELECTORS.getRelationMapSemanticCategoriesMapSelector(state),
    semanticCategoriesOrder: SELECTORS.getRelationMapSemanticCategoriesOrderSelector(state),
    selectedConcepts: SELECTORS.getRelationMapSelectedConceptsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateConcept(data) {
      dispatch(ACTIONS.updateRelationMapConceptAction(data));
    },
    toggleAllConcepts(data) {
      dispatch(ACTIONS.toggleAllRelationMapConceptsAction(data));
    },
    deleteConcepts(data) {
      dispatch(ACTIONS.deleteRelationMapConceptsAction(data));
    },
    updateSettings(data) {
      dispatch(ACTIONS.updateRelationMapSettingsAction(data));
    },
    cacheData() {
      dispatch(ACTIONS.cacheRelationMapDataAction());
    },
    updateLayout(data) {
      dispatch(ACTIONS.updateRelationMapLayoutAction(data));
    },
    toggleProportionalLinksLayout(data) {
      dispatch(ACTIONS.toggleRelationMapProportionalLinksLayoutAction(data));
    },
    updateAlgorithm(data) {
      dispatch(ACTIONS.updateRelationMapAlgorithmAction(data));
    },
    updateNodesIsFixed(data) {
      dispatch(ACTIONS.updateRelationMapNodesIsFixedAction(data));
    },
    updateSemCategories(data) {
      dispatch(ACTIONS.updateRelationMapSemCategoriesAction(data));
    },
    updateColorsAndShapes(data) {
      dispatch(ACTIONS.updateRelationMapColorsAndShapesAction(data));
    },
    updateSemCategoriesOrder(data) {
      dispatch(ACTIONS.updateRelationMapSemCategoriesOrderAction(data));
    },
    mergeConcepts(data) {
      dispatch(ACTIONS.mergeRelationMapConceptsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMapSidebar);
