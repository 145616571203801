import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { SearchSourceFilterEnum } from '../../enums';

const propTypes = {
  recommendations: PropTypes.instanceOf(Object),
  startNewSearch: PropTypes.func,
};

const SearchWithSynonymsSection = (props) => {
  const { recommendations, startNewSearch } = props;

  return (
    <div
      className="search-page__link-block"
      onClick={() => {
        startNewSearch({
          plainValue: recommendations.recommendationPhrase,
          exactValue: recommendations.exactRecommendationPhrase,
          publicationSource: SearchSourceFilterEnum.ABSTRACT,
        });
      }}
    >
      Search with synonyms for {recommendations.recommendationPhrase}
    </div>
  );
};

SearchWithSynonymsSection.propTypes = propTypes;

export default React.memo(SearchWithSynonymsSection);
