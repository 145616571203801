import {
  SORT,
} from '../../common/SimpleTable/constants';

import {
  ANALYTICS_REQUESTED,
  ANALYTICS_TABLE,
  ANALYTICS_TITLE_FILTER,
  ANALYTICS_TAG_FILTER,
  ANALYTICS_SET_NAME_FILTER,
  ANALYTICS_SHOW_ALL_SET_NAMES,
  DELETE_ANALYTICS_REQUESTED,
  RESET_SET_ANALYSIS_MANAGEMENT,
  DUPLICATE_ANALYTICS,
  ANALYTICS_CHECK_ITEM,
  ANALYTICS_CHECK_ALL,
  CLEAR_ANALYTICS_ACTIVE_PROJECT_ID,
  SET_ANALYTICS_ACTIVE_PROJECT_ID,
} from './constants';

export const initAnalyticsAction = data => (
  {
    type: ANALYTICS_REQUESTED,
    data,
  }
);

export const sort = data => (
  {
    type: ANALYTICS_TABLE + SORT,
    data,
  }
);

export const setTitleFilter = data => (
  {
    type: ANALYTICS_TITLE_FILTER,
    data,
  }
);

export const setTagFilter = data => (
  {
    type: ANALYTICS_TAG_FILTER,
    data,
  }
);

export const setSetNameFilter = data => (
  {
    type: ANALYTICS_SET_NAME_FILTER,
    data,
  }
);

export const showAllSetsName = data => (
  {
    type: ANALYTICS_SHOW_ALL_SET_NAMES,
    data,
  }
);

export const deleteAnalyticsAction = data => (
  {
    type: DELETE_ANALYTICS_REQUESTED,
    data,
  }
);

export const resetSetAnalysisManagementAction = () => (
  {
    type: RESET_SET_ANALYSIS_MANAGEMENT,
  }
);

export const checkAnalyticsItemAction = data => (
  {
    type: ANALYTICS_CHECK_ITEM,
    data,
  }
);

export const checkAnalyticsAllItemsAction = data => (
  {
    type: ANALYTICS_CHECK_ALL,
    data,
  }
);

export const duplicateAnalyticsAction = data => (
  {
    type: DUPLICATE_ANALYTICS,
    data,
  }
);

export const setAnalyticsActiveProjectIdAction = data => (
  {
    type: SET_ANALYTICS_ACTIVE_PROJECT_ID,
    data,
  }
);

export const clearAnalyticsActiveProjectIdAction = () => (
  {
    type: CLEAR_ANALYTICS_ACTIVE_PROJECT_ID,
  }
);
