import React, { useMemo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { List } from 'react-virtualized';

// Components
import Input from '../Input/Input';
import Checkbox from '../Checkbox/Checkbox';
// Styles
import './MultiCheckboxes.scss';

const propTypes = {
  input: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  filterValue: PropTypes.string,
  filter: PropTypes.func,
};

const MultiCheckboxes = (props) => {
  const {
    input: { onChange, value },
    options,
    filterValue,
    filter,
  } = props;

  const listRef = useRef(null);
  const inputValue = value && value.toJS();
  const idsArray = useMemo(() => (inputValue ? inputValue.map(d => d.id) : []), [inputValue]);

  const [allIsSelected, setAllIsSelected] = useState(false);

  const handleChange = useCallback((option, checked) => {
    if (checked) {
      const filtered = inputValue.filter(d => d.id !== option.id);
      onChange(fromJS(filtered));
    } else {
      onChange(fromJS([...inputValue, option]));
    }
  }, [inputValue]);

  const toggleAll = useCallback(() => {
    if (allIsSelected) {
      onChange(fromJS([]));
    } else {
      onChange(fromJS(options));
    }
    setAllIsSelected(selected => !selected);
  }, [options, allIsSelected, setAllIsSelected]);

  const rowRenderer = useCallback(() => (
    options.map((option) => {
      const checked = idsArray.includes(option.id);
      return (
        <div
          key={option.id}
          className="multi-checkboxes__item"
        >
          <div className="multi-checkboxes__item-wrap">
            <div className="multi-checkboxes__name">
              <Checkbox
                id={`checkbox-${option.id}`}
                checked={checked}
                onChange={() => { handleChange(option, checked); }}
              />
              <span className="multi-checkboxes__name-text">
                {option.fullName}
              </span>
            </div>
            <div className="multi-checkboxes__email">
              {option.email}
            </div>
          </div>
        </div>
      );
    })
  ), [options, idsArray]);

  return (
    <div className="multi-checkboxes">
      <div className="multi-checkboxes__header">
        <div className="multi-checkboxes__header-name">
          <Checkbox
            id="multi-select-all"
            checked={allIsSelected}
            onChange={toggleAll}
          />
          Users
        </div>
        <Input
          value={filterValue}
          placeholder="Filter"
          onChange={e => filter(e.target.value)}
        />
      </div>
      <List
        ref={listRef}
        width={640}
        height={Math.min(options.length * 45, 225)}
        rowCount={options.length}
        rowHeight={45}
        rowRenderer={rowRenderer}
      />
    </div>
  );
};

MultiCheckboxes.propTypes = propTypes;

export default React.memo(MultiCheckboxes);
