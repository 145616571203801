import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';

import { convertTableToCSVText, exportToFile } from '../../../../Utils/Utils';
import { toggleCsvDownloadedSamplesLoadingAction } from '../../reducer';

export function* sagaSamplesDataForCsvExport(action) {
  try {
    const {
      downloadLinkRef,
      data,
      type,
      geneId,
      csvName,
      scale,
    } = action.payload;

    yield put(toggleCsvDownloadedSamplesLoadingAction({ type, status: true }));
    const sampleExpressionFilterDto = data.map(item => ({
      group: item.group,
      relatedId: item.concept.id,
    }));

    const requestBody = {
      geneId,
      type,
      scale,
      sampleExpressionFilterDto,
    };

    const requestData = yield call(Api.getGeneExpressionForMultipleRelated, requestBody);

    const fullData = Object.keys(requestData.data).reduce((acc, item) => {
      const tissue = data.find(i => i.concept.id === Number(item));
      const samplesByGroup = requestData.data[item].map(i => ({ tissue: tissue.concept.name, sample: i.sample, measure: i.measure.toFixed(2) }));
      return acc.concat(samplesByGroup);
    }, []);

    const columns = [
      {
        label: 'Tissue',
        dataKey: 'tissue',
      },
      {
        label: 'Sample identifier',
        dataKey: 'sample',
      },
      {
        label: 'TPM value',
        dataKey: 'measure',
      },
    ];

    const csvData = convertTableToCSVText(fullData.flat(), columns);
    exportToFile(downloadLinkRef, csvData, csvName);
    yield put(toggleCsvDownloadedSamplesLoadingAction({ type, status: false }));
  } catch (e) {
    console.log('error');
  }
}
