import { fromJS } from 'immutable';

import {
  SORT,
} from '../../../../common/SimpleTable/constants';

import {
  PATH_DETAILS_SMALL_MOLECULES_SUCCEEDED,
  PATH_DETAILS_SMALL_MOLECULES_FAILED,
  PATH_DETAILS_SMALL_MOLECULES_TABLE,
} from './constants';

const initialState = fromJS({
  pathDetailsSmallMolecules: [],
  pathDetailsSmallMoleculesSorting: { sortBy: 'score', sortDirection: 'ASC' },
  error: null,
  loading: true,
});

const pathDetailsSmallMolecules = (state = initialState, action) => {
  switch (action.type) {
    case PATH_DETAILS_SMALL_MOLECULES_SUCCEEDED:
      return state.merge(fromJS({
        pathDetailsSmallMolecules: action.data,
        error: null,
        loading: false,
      }));

    case PATH_DETAILS_SMALL_MOLECULES_FAILED:
      return state.merge(fromJS({
        pathDetailsSmallMolecules: [],
        error: action.message,
        loading: false,
      }));

    case PATH_DETAILS_SMALL_MOLECULES_TABLE + SORT:
      return state.merge(fromJS({
        pathDetailsSmallMoleculesSorting: action.data,
      }));

    default:
      return state;
  }
};

export default pathDetailsSmallMolecules;
