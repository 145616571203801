import React from 'react';
import PropTypes from 'prop-types';

import {
  aggregationMethods,
} from '../ComplexSetView/enums';

import './ComplexSetAggregateModal.css';

const aggregationMethodsKeys = Object.keys(aggregationMethods);

const propTypes = {
  availableColumns: PropTypes.instanceOf(PropTypes.object),
  closeHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
};

class ComplexSetAggregateDialog extends React.Component {
  constructor(props) {
    super(props);
    const {
      availableColumns,
    } = this.props;

    const availableNumberColumns = availableColumns.filter(column => column.type === 'NUMBER');

    this.state = {
      identifierColumnNumber: availableColumns[0].colNumber,
      aggregationColumnNumber: availableNumberColumns[0].colNumber,
      operator: aggregationMethods[aggregationMethodsKeys[0]],
    };
  }

  render() {
    const {
      availableColumns,
      handleSubmit,
    } = this.props;
    const allColumns = availableColumns;
    const allNumberColumns = availableColumns
      .filter(column => column.type === 'NUMBER');

    return (
      <div className="complex-set-aggregate-modal-root">
        <div className="complex-set-aggregate-modal-content">
          <div className="page-title">
            Aggregate set
          </div>
          <div
            className="close-button"
            onClick={
              () => this.props.closeHandler()
            }
          >
            <svg
              width="40"
              height="40"
            >
              <circle cx="20" cy="20" r="20" fill="black" />
              <g transform="rotate(45 20 20)">
                <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
                <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
              </g>
            </svg>
          </div>
          <div className="params">
            <div className="section">
              <div className="element">Identifier Column</div>
              <div className="element">Aggregation Column</div>
              <div className="element">Aggregation Method</div>
            </div>
            <div className="section">
              <div className="element">
                <select
                  value={this.state.identifierColumnNumber}
                  onChange={
                    (e) => {
                      this.setState({
                        identifierColumnNumber: Number(e.target.value),
                      });
                    }
                  }
                >
                  {
                    allColumns
                      .filter(column => column.colNumber !== this.state.aggregationColumnNumber)
                      .map((column, idColumnIndex) => (
                        <option
                          key={`idColumn_${column.name}_${idColumnIndex}`}
                          value={column.colNumber}
                        >
                          {column.name}
                        </option>
                      )
                      )
                  }
                </select>
              </div>
              <div className="element">
                <select
                  value={this.state.aggregationColumnNumber}
                  onChange={(e) => {
                    this.setState({
                      aggregationColumnNumber: Number(e.target.value),
                    });
                  }}
                >
                  {
                    allNumberColumns
                      .filter(column => column.colNumber !== this.state.identifierColumnNumber)
                      .map((column, idColumnIndex) => (
                        <option
                          key={`aggregationColumn_${column.name}_${idColumnIndex}`}
                          value={column.colNumber}
                        >
                          {column.name}
                        </option>
                      )
                      )
                  }
                </select>
              </div>
              <div className="element">
                <select
                  value={this.state.operator}
                  onChange={(e) => {
                    this.setState({
                      operator: e.target.value,
                    });
                  }}
                >
                  {
                    aggregationMethodsKeys.map((aggregationMethodsKey, aggregationMethodsKeyIndex) => (
                      <option
                        value={aggregationMethods[aggregationMethodsKey]}
                        key={`${aggregationMethodsKey}__${aggregationMethodsKeyIndex}`}
                      >
                        {aggregationMethodsKey}
                      </option>
                    )
                    )
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="button-section">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => handleSubmit(this.state)}
            >
              Apply aggregation
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ComplexSetAggregateDialog.propTypes = propTypes;

export default ComplexSetAggregateDialog;
