import {
  SORT,
  CHECK_ITEM,
  CHECK_ALL,
  INVERT_SELECTION,
} from '../../../common/SimpleTable/constants';

import {
  RANK_SELECTION_RESULT_TABLE,
  REMOVE_CONCEPT,
  RANK_SELECTION_RESULT_TABLE_RESET,
  RANK_SELECTION_REQUESTED,
  RANK_SELECTION_CHANGE_PAGE,
  LOADING,
  APPLY_FILTER,
  CLEAR_FILTERS,
} from './constants';

export const requestRanking = () => (
  {
    type: RANK_SELECTION_REQUESTED,
  }
);

export const changePageAction = data => (
  {
    type: RANK_SELECTION_CHANGE_PAGE,
    data,
  }
);

export const reset = () => (
  {
    type: RANK_SELECTION_RESULT_TABLE_RESET,
  }
);

export const sort = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + SORT,
    data,
  }
);

export const checkAll = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelection = () => (
  {
    type: RANK_SELECTION_RESULT_TABLE + INVERT_SELECTION,
  }
);

export const removeConcept = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + REMOVE_CONCEPT,
    data,
  }
);

export const loading = () => (
  {
    type: RANK_SELECTION_RESULT_TABLE + LOADING,
    data: true,
  }
);

export const applyFilterAction = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + APPLY_FILTER,
    data,
  }
);

export const clearFiltersAction = data => (
  {
    type: RANK_SELECTION_RESULT_TABLE + CLEAR_FILTERS,
    data,
  }
);

