import { fromJS } from 'immutable';

import { defaultProjects } from '../../Projects/ProjectsTabs/constants';
import { SORT } from '../../common/SimpleTable/constants';
import {
  ANALYTICS_SUCCEEDED,
  ANALYTICS_FAILED,
  ANALYTICS_TABLE,
  ANALYTICS_TITLE_FILTER,
  ANALYTICS_TAG_FILTER,
  ANALYTICS_SET_NAME_FILTER,
  ANALYTICS_SHOW_ALL_SET_NAMES,
  RESET_SET_ANALYSIS_MANAGEMENT,
  ANALYTICS_CHECK_ITEM,
  ANALYTICS_CHECK_ALL,
  CLEAR_ANALYTICS_ACTIVE_PROJECT_ID,
  SET_ANALYTICS_ACTIVE_PROJECT_ID,
} from './constants';

const initialValues = fromJS({
  analytics: [],
  checkedSets: [],
  loading: true,
  error: null,
  sorting: { sortBy: 'lastOpened', sortDirection: 'DESC' },
  filters: {
    title: '',
    tag: '',
    setName: '',
  },
  activeProjectId: defaultProjects[0].id,
});

const SetAnalysisManagement = (state = initialValues, action) => {
  switch (action.type) {
    case ANALYTICS_SUCCEEDED:
      return state.merge(fromJS({ analytics: action.data, loading: false, error: null }));

    case ANALYTICS_FAILED:
      return state.merge(fromJS({ analytics: [], loading: false, error: action.message }));

    case ANALYTICS_TABLE + SORT:
      return state.merge(fromJS({ sorting: action.data }));

    case ANALYTICS_TITLE_FILTER:
      return state.update('filters', filters => (
        filters.update('title', () => action.data.toLowerCase())
      ));

    case ANALYTICS_TAG_FILTER:
      return state.update('filters', filters => (
        filters.update('tag', () => action.data.toLowerCase())
      ));

    case ANALYTICS_SET_NAME_FILTER:
      return state.update('filters', filters => (
        filters.update('setName', () => action.data.toLowerCase())
      ));

    case ANALYTICS_SHOW_ALL_SET_NAMES:
      return state.update('analytics', analytics => (
        analytics.update(analytics.findIndex(item => (
          item.get('id') === action.data.id
        )), item => (
          item.update('showAll', () => action.data.key)
        ))
      ));

    case RESET_SET_ANALYSIS_MANAGEMENT:
      return state
        .merge({
          ...initialValues,
          activeProjectId: state.get('activeProjectId'),
        });
    case ANALYTICS_CHECK_ITEM:
      return state
        .update('analytics', data => (
          data.map(item => (
            item.get('id') === action.data.id ?
              item.merge(fromJS({ selected: action.data.checked })) :
              item
          ))
        )
        );
    case ANALYTICS_CHECK_ALL:
      return state
        .update('analytics', data => (
          data.map(item => (
            item.merge(fromJS({ selected: action.data }))
          ))
        )
        );
    case SET_ANALYTICS_ACTIVE_PROJECT_ID:
      return state
        .update('activeProjectId', () => action.data);
    case CLEAR_ANALYTICS_ACTIVE_PROJECT_ID:
      return state
        .update('activeProjectId', () => initialValues.get('activeProjectId'));
    default:
      return state;
  }
};

export default SetAnalysisManagement;
