/* eslint-disable*/

export const makeDistroChartForNewData = ({
  settings,
  isNeededTitle,
  chartTitle,
  data,
  showTooltipHandler,
  hideTooltip,
  selectedCells,
  handleCellClick,
}) => {
  let chart = {};

  // Defaults
  chart.settings = {
    data: null,
    xName: null,
    yName: null,
    selector: null,
    axisLables: null,
    yTicks: 1,
    scale: 'linear',
    chartSize: {width: 800, height: 400},
    margin: {top: 15, right: 60, bottom: 40, left: 50},
    constrainExtremes: false,
    color: window.d3.scale.category10()
  };

  for (let setting in settings) {
    chart.settings[setting] = settings[setting]
  }

  chart.yFormatter = formatAsFloat;

  chart.data = chart.settings.data;
  chart.groupObjs = {}; //The data organized by grouping and sorted as well as any metadata for the groups
  chart.objs = {mainDiv: null, chartDiv: null, g: null, xAxis: null, yAxis: null};
  chart.colorFunct = null;
  /**
   * Takes an array, function, or object mapping and created a color function from it
   * @param {function|[]|object} colorOptions
   * @returns {function} Function to be used to determine chart colors
   */
  function getColorFunct(colorOptions) {
    if (typeof colorOptions == 'function') {
      return colorOptions
    } else if (Array.isArray(colorOptions)) {
      //  If an array is provided, map it to the domain
      var colorMap = {}, cColor = 0;
      for (let cName in chart.groupObjs) {
        colorMap[cName] = colorOptions[cColor];
        cColor = (cColor + 1) % colorOptions.length;
      }
      return function (group) {
        return colorMap[group];
      }
    } else if (typeof colorOptions == 'object') {
      // if an object is provided, assume it maps to  the colors
      return function (group) {
        return colorOptions[group];
      }
    } else {
      return window.d3.scale.category20();
    }
  }

  /**
   * Takes a percentage as returns the values that correspond to that percentage of the group range witdh
   * @param objWidth Percentage of range band
   * @param gName The bin name to use to get the x shift
   * @returns {{left: null, right: null, middle: null}}
   */
  function getObjWidth(objWidth, gName) {
    let objSize = {left: null, right: null, middle: null};
    let width = chart.xScale.rangeBand() * (objWidth / 110);
    let padding = (chart.xScale.rangeBand() - width) / 2;
    let gShift = chart.xScale(gName);
    objSize.middle = chart.xScale.rangeBand() / 2 + gShift;
    objSize.left = padding + gShift;
    objSize.right = objSize.left + width;
    return objSize;
  }

  function shallowCopy(oldObj) {
    let newObj = {};
    for (let i in oldObj) {
      if (oldObj.hasOwnProperty(i)) {
        newObj[i] = oldObj[i];
      }
    }
    return newObj;
  }

  /**
   * Closure that creates the tooltip hover function
   * @param groupName Name of the x group
   * @param metrics Object to use to get values for the group
   * @returns {Function} A function that provides the values for the tooltip
   */

  function tooltipHover(conceptName, summary, points, metrics) {
    let tooltipString;
    tooltipString = `
        <div><b>Group: </b> ${conceptName}</div>
      `;
    if(summary) {
      const { min, q1, median, q3, max } = summary;
      tooltipString += `
        <div><b>Min: </b> ${formatAsFloat(min)}</div>
        <div><b>First quartile:</b> ${formatAsFloat(q1)}</div>
        <div><b>Median: </b> ${formatAsFloat(median)}</div>
        <div><b>Third quartile: </b> ${formatAsFloat(q3)}</div>
        <div><b>Max: </b> ${formatAsFloat(max)}</div>
      `;

      // tooltipString += "<br\>Min: " + formatAsFloat(min);
      // tooltipString += "<br\>First quartile: " + formatAsFloat(q1);
      // tooltipString += "<br\>Median: " + formatAsFloat(median);
      // tooltipString += "<br\>Third quartile: " + formatAsFloat(q3);
      // tooltipString += "<br\>Max: " + formatAsFloat(max);

      // tooltipString += "<br\>Min: " + formatAsFloat(min);
      // tooltipString += "<br\>First quartile: " + formatAsFloat(q1);
      // tooltipString += "<br\>Median: " + formatAsFloat(median);
      // tooltipString += "<br\>Third quartile: " + formatAsFloat(q3);
      // tooltipString += "<br\>Max: " + formatAsFloat(max);
    }

    tooltipString += `
        <div><b>Number of samples: </b> ${points.length}</div>
      `;

    return function () {
      chart.objs.tooltip.transition().duration(200).style("opacity", 0.9);
      chart.objs.tooltip.html(tooltipString)
    };
  }

  // /**
  //  * Parse the data and calculates base values for the plots
  //  */
  !function prepareData() {
    function calcMetrics(values) {

      var metrics = {
        max: null,
        upperOuterFence: null,
        upperInnerFence: null,
        quartile3: null,
        median: null,
        mean: null,
        iqr: null,
        quartile1: null,
        lowerInnerFence: null,
        lowerOuterFence: null,
        min: null
      };

      metrics.min = window.d3.min(values);
      metrics.quartile1 = window.d3.quantile(values, 0.25);
      metrics.median = window.d3.median(values);
      metrics.mean = window.d3.mean(values);
      metrics.quartile3 = window.d3.quantile(values, 0.75);
      metrics.max = window.d3.max(values);
      metrics.iqr = metrics.quartile3 - metrics.quartile1;

      //The inner fences are the closest value to the IQR without going past it (assumes sorted lists)
      var LIF = metrics.quartile1 - (1.5 * metrics.iqr);
      var UIF = metrics.quartile3 + (1.5 * metrics.iqr);
      for (var i = 0; i <= values.length; i++) {
        if (values[i] < LIF) {
          continue;
        }
        if (!metrics.lowerInnerFence && values[i] >= LIF) {
          metrics.lowerInnerFence = values[i];
          continue;
        }
        if (values[i] > UIF) {
          metrics.upperInnerFence = values[i - 1];
          break;
        }
      }


      metrics.lowerOuterFence = metrics.quartile1 - (3 * metrics.iqr);
      metrics.upperOuterFence = metrics.quartile3 + (3 * metrics.iqr);
      if (!metrics.lowerInnerFence) {
        metrics.lowerInnerFence = metrics.min;
      }
      if (!metrics.upperInnerFence) {
        metrics.upperInnerFence = metrics.max;
      }
      return metrics
    };

    var current_x = null;
    var current_y = null;

    // Group the values
    for (let current_row = 0; current_row < chart.data.length; current_row++) {
      current_x = chart.data[current_row][chart.settings.xName];
      current_y = chart.data[current_row][chart.settings.yName];
      if (!!chart.groupObjs[current_x]) {
        chart.groupObjs[current_x].values.push(current_y);
      } else {
        chart.groupObjs[current_x] = {};
        chart.groupObjs[current_x].values = [current_y];
      }
    }

    const ordered = {};
      Object.keys(chart.groupObjs).sort().forEach(function(key) {
        if (key !== 'Other') {
          ordered[key] = chart.groupObjs[key];
        }
      });

    const Other = chart.groupObjs.Other;
    chart.groupObjs = {...ordered, Other};
  }();

  /**
   * Prepare the chart settings and chart div and svg
   */
  !function prepareSettings() {
    //Set base settings
    chart.margin = chart.settings.margin;
    chart.divWidth = chart.settings.chartSize.width;
    chart.divHeight = chart.settings.chartSize.height;
    chart.width = chart.divWidth - chart.margin.left - chart.margin.right;
    chart.height = chart.divHeight - chart.margin.top - chart.margin.bottom;

    if (chart.settings.axisLabels) {
      chart.xAxisLable = chart.settings.axisLabels.xAxis;
      chart.yAxisLable = chart.settings.axisLabels.yAxis;
    } else {
      chart.xAxisLable = chart.settings.xName;
      chart.yAxisLable = chart.settings.yName;
    }

    if (chart.settings.scale === 'log') {
      chart.yScale = window.d3.scale.log();
      chart.yFormatter = logFormatNumber;
    } else {
      chart.yScale = window.d3.scale.linear();
    }

    if (chart.settings.constrainExtremes === true) {
      let fences = [];
      data.forEach((el) => {
        const { concept } = el;
        const name = concept.name;
        fences.push(chart.groupObjs[name].metrics.lowerInnerFence);
        fences.push(chart.groupObjs[name].metrics.upperInnerFence);
      });
      chart.range = window.d3.extent(fences);
    } else {
      chart.range = [0, window.d3.max(chart.data, (d) => d[chart.settings.yName])]
    }

    chart.colorFunct = getColorFunct(chart.settings.colors);

    // Build Scale functions
    chart.yScale.range([chart.height, 0]).domain(chart.range).nice().clamp(true);
    chart.xScale = window.d3.scale.ordinal().domain(Object.keys(chart.groupObjs)).rangeBands([0, chart.width]);

    //Build Axes Functions
    chart.objs.yAxis = window.d3.svg.axis()
      .scale(chart.yScale)
      .orient("left")
      .tickFormat(chart.yFormatter)
      .outerTickSize(0)
      .innerTickSize(-chart.width + (chart.margin.right + chart.margin.left));
    chart.objs.yAxis.ticks(chart.objs.yAxis.ticks()*chart.settings.yTicks);
    chart.objs.xAxis = window.d3.svg.axis().scale(chart.xScale).orient("bottom").tickSize(5);
  }();
  /**
   * Updates the chart based on the current settings and window size
   * @returns {*}
   */
  chart.update = function () {
    // Update chart size based on view port size
    chart.width = chart.settings.chartSize.width - (chart.margin.left + chart.margin.right);
    chart.height = chart.settings.chartSize.height - (chart.margin.top + chart.margin.bottom);

    // Update scale functions
    chart.xScale.rangeBands([0, chart.width]);
    chart.yScale.range([chart.height, 0]);

    // Update the yDomain if the Violin plot clamp is set to -1 meaning it will extend the violins to make nice points
    if (chart.violinPlots && chart.violinPlots.options.show == true && chart.violinPlots.options._yDomainVP != null) {
      chart.yScale.domain(chart.violinPlots.options._yDomainVP).nice().clamp(true);
    } else {
      chart.yScale.domain(chart.range).nice().clamp(true);
    }

    //Update axes
    chart.objs.g.select('.x.axis')
      .attr("transform", "translate(0," + chart.height + ")").call(chart.objs.xAxis)
      .selectAll("text")
      .attr("y", 4)
      .attr("x", 15)
      .attr("transform", "rotate(45)")
      .style("text-anchor", "start")
      .style("font-size", "16px")
      .style('font-weight', (d) => {
        if (!isNeededTitle && selectedCells) {
          return selectedCells.find(c => d === c.concept.name) && 700;
        }
        return 400;
      })
      .on("mouseover", (e) => showTooltipHandler(data.find(item => item.concept.name === e), window.event.pageX, window.event.pageY - window.scrollY))
      .on("mouseout", hideTooltip)
      .on('click', (d) => handleCellClick(d));
    chart.objs.g.select('.x.axis .label').attr("x", chart.width / 2);
    chart.objs.g.select('.y.axis').call(chart.objs.yAxis.innerTickSize(-chart.width));
    chart.objs.g.select('.y.axis .label').attr("x", -chart.height / 2);
    chart.objs.chartDiv.select('svg').attr("width", chart.width + (chart.margin.left + chart.margin.right)).attr("height", chart.height + (chart.margin.top + chart.margin.bottom));

    return chart;
  };

  /**
   * Prepare the chart html elements
   */
  !function prepareChart() {
    window.d3.select(chart.settings.selector).select('svg').remove();

    // Build main div and chart div
    chart.objs.mainDiv = window.d3.select(chart.settings.selector)
      .style("max-width", chart.divWidth + "px");
    // Add all the divs to make it centered and responsive
    chart.objs.mainDiv.selectAll('div').remove();
    chart.objs.mainDiv.append("div")
      .attr("class", "inner-wrapper")
      .append("div").attr("class", "outer-box")
      .append("div").attr("class", "inner-box");
    // Capture the inner div for the chart (where the chart actually is)
    chart.selector = chart.settings.selector + " .inner-box";
    chart.objs.chartDiv = window.d3.select(chart.selector);

    // Create the svg
    chart.objs.g = chart.objs.chartDiv.append("svg")
      .attr("class", "beeswarm-area")
      .attr("width", chart.width + (chart.margin.left + chart.margin.right))
      .attr("height", chart.height + (chart.margin.top + chart.margin.bottom))
      .append("g")
      .attr("transform", "translate(" + chart.margin.left + "," + chart.margin.top + ")");

    // Create axes
    chart.objs.axes = chart.objs.g.append("g").attr("class", "axis");
    chart.objs.axes.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + chart.height + ")")
      .call(chart.objs.xAxis);

    chart.objs.axes.append("g")
      .attr("class", "y axis")
      .call(chart.objs.yAxis)
      .append("text")
      .attr("class", "label")
      .attr("transform", "rotate(-90)")
      .attr("x", -chart.height)
      .attr("dy", ".21em")
      .style("text-anchor", "middle")
      .style("font-weight", "400")
      .style("font-size", "16px")
      .text(chart.yAxisLable);

    let maxYTextLength = 0;
    chart.objs.axes.selectAll('.y.axis > .tick > text')[0].forEach((item) => {
      if (item.getComputedTextLength() > maxYTextLength) {
        maxYTextLength = Math.round(item.getComputedTextLength());
      }
    });

    chart.objs.axes.selectAll('.y.axis > .label').attr("y", -maxYTextLength - 20);

    for (let cName in chart.groupObjs) {
      if(chart.groupObjs[cName]) {
        chart.groupObjs[cName].g = chart.objs.g.append("g").attr("class", "group");
        chart.update();
      }
    }
  }();

  function formatAsFloat(d) {
    if (d % 1 !== 0) {
      return window.d3.format(".2f")(d);
    } else {
      return window.d3.format(".0f")(d);
    }
  }

  function logFormatNumber(d) {
    var x = Math.log(d) / Math.log(10) + 1e-6;
    return Math.abs(x - Math.floor(x)) < 0.6 ? formatAsFloat(d) : "";
  }

  /**
   * Render a raw data in various forms
   * @param options
   * @param [options.show=true] Toggle the whole plot on and off
   * @param [options.showPlot=false] True or false, show points
   * @param [options.plotType='none'] Options: no scatter = (false or 'none'); scatter points= (true or [amount=% of width (default=10)]); beeswarm points = ('beeswarm')
   * @param [options.pointSize=6] Diameter of the circle in pizels (not the radius)
   * @param [options.showLines=['median']] Can equal any of the metrics lines
   * @param [options.showbeanLines=false] Options: no lines = false
   * @param [options.beanWidth=20] % width
   * @param [options.colors=chart default]
   * @returns {*} The chart object
   *
   */
  chart.renderDataPlots = function (options) {
    chart.dataPlots = {};

    //Defaults
    let defaultOptions = {
      show: true,
      showPlot: false,
      plotType: 'none',
      pointSize: 6,
      showLines: false,//['median'],
      showBeanLines: false,
      beanWidth: 20,
      colors: null
    };
    chart.dataPlots.options = shallowCopy(defaultOptions);
    for (let option in options) {
      chart.dataPlots.options[option] = options[option]
    }
    let dOpts = chart.dataPlots.options;

    //Create notch objects
    for (var cName in chart.groupObjs) {
      if (chart.groupObjs[cName]) {
        chart.groupObjs[cName].dataPlots = {};
        chart.groupObjs[cName].dataPlots.objs = {};
      }
    }
    // The lines don't fit into a group bucket so they live under the dataPlot object
    chart.dataPlots.objs = {};

    /**
     * Take updated options and redraw the data plots
     * @param updateOptions
     */
    chart.dataPlots.change = function (updateOptions) {
      if (updateOptions) {
        for (var key in updateOptions) {
          dOpts[key] = updateOptions[key]
        }
      }

      chart.dataPlots.objs.g.remove();
      for (var cName in chart.groupObjs) {
        chart.groupObjs[cName].dataPlots.objs.g.remove()
      }
      chart.dataPlots.preparePlots();
      chart.dataPlots.update()
    };

    chart.dataPlots.reset = function () {
      chart.dataPlots.change(defaultOptions)
    };
    chart.dataPlots.show = function (opts) {
      if (opts !== undefined) {
        opts.show = true;
        if (opts.reset) {
          chart.dataPlots.reset()
        }
      } else {
        opts = {show: true};
      }
      chart.dataPlots.change(opts)
    };
    chart.dataPlots.hide = function (opts) {
      if (opts !== undefined) {
        opts.show = false;
        if (opts.reset) {
          chart.dataPlots.reset()
        }
      } else {
        opts = {show: false};
      }
      chart.dataPlots.change(opts)
    };

    /**
     * Update the data plot obj values
     */
    chart.dataPlots.update = function () {
      var cName, cGroup, cPlot;

      // Metrics lines
      if (chart.dataPlots.objs.g) {
        var halfBand = chart.xScale.rangeBand() / 2; // find the middle of each band
        for (var cMetric in chart.dataPlots.objs.lines) {
          chart.dataPlots.objs.lines[cMetric].line
            .x(function (d) {
              return chart.xScale(d.x) + halfBand
            });
          chart.dataPlots.objs.lines[cMetric].g
            .datum(chart.dataPlots.objs.lines[cMetric].values)
            .attr('d', chart.dataPlots.objs.lines[cMetric].line);
        }
      }

      for (cName in chart.groupObjs) {
        cGroup = chart.groupObjs[cName];
        if (cGroup) {
          cPlot = cGroup && cGroup.dataPlots;

          if (cPlot.objs.points) {
            if (dOpts.plotType == 'beeswarm') {
              let width = chart.groupObjs.length > 8 ? 100.5 : 110;
              var swarmBounds = getObjWidth(width, cName);
              var yPtScale = chart.yScale.copy()
                .range([Math.floor(chart.yScale.range()[0] / dOpts.pointSize), 0])
                .interpolate(window.d3.interpolateRound)
                .domain(chart.yScale.domain());
              var maxWidth = Math.floor(chart.xScale.rangeBand() / dOpts.pointSize);
              var ptsObj = {};
              var cYBucket = null;
              //  Bucket points
              for (var pt = 0; pt < cGroup.values.length; pt++) {
                cYBucket = yPtScale(cGroup.values[pt]);
                if (ptsObj.hasOwnProperty(cYBucket) !== true) {
                  ptsObj[cYBucket] = [];
                }
                ptsObj[cYBucket].push(cPlot.objs.points.pts[pt]
                  .attr("cx", swarmBounds.middle)
                  .attr("cy", yPtScale(cGroup.values[pt]) * dOpts.pointSize));
              }
              //  Plot buckets
              var rightMax = Math.min(swarmBounds.right - dOpts.pointSize);
              for (var row in ptsObj) {
                var leftMin = swarmBounds.left + (Math.max((maxWidth - ptsObj[row].length) / 2, 0) * dOpts.pointSize);
                var col = 0;
                for (pt in ptsObj[row]) {
                  ptsObj[row][pt].attr("cx", Math.min(leftMin + col * dOpts.pointSize, rightMax) + dOpts.pointSize / 2);
                  col++
                }
              }
            }
          }


          if (cPlot.objs.bean) {
            let beanBounds = getObjWidth(dOpts.beanWidth, cName);
            for (let pt = 0; pt < cGroup.values.length; pt++) {
              cPlot.objs.bean.lines[pt]
                .attr("x1", beanBounds.left)
                .attr("x2", beanBounds.right)
                .attr('y1', chart.yScale(cGroup.values[pt]))
                .attr("y2", chart.yScale(cGroup.values[pt]));
            }
          }
        }
      }
    };
    /**
     * Create the svg elements for the data plots
     */
    chart.dataPlots.preparePlots = function () {
      let cPlot;
      let { dataPlots } = chart;
      if (dOpts && dOpts.colors) {
        dataPlots.colorFunct = getColorFunct(dOpts.colors);
      } else {
        dataPlots.colorFunct = chart.colorFunct
      }

      if (dOpts.show == false) return;
      data.forEach((el) => {
        const { concept: { name }, summary, points } = el;

        if (chart.groupObjs[name] && chart.groupObjs[name].dataPlots) {
          cPlot = chart.groupObjs[name].dataPlots;
          cPlot.objs.g = chart.groupObjs[name].g.append("g").attr("class", "data-plot");
          cPlot.objs.points = {g: null, pts: []};
          cPlot.objs.points.g = cPlot.objs.g.append("g").attr("class", "points-plot");

          chart.objs.tooltip = chart.objs.mainDiv.append('div').attr('class', 'beesWarmTooltip');
          cPlot.objs.points.g.on("mouseover", function () {
            chart.objs.tooltip
              .style("display", null)
              .style("left", (window.event.pageX) + "px")
              .style("top", (window.event.pageY - window.scrollY) + "px");
          }).on("mouseout", function () {
            chart.objs.tooltip.style("display", "none");
          }).on("mousemove", tooltipHover(
            name,
            summary,
            points,
            chart.groupObjs[name].metrics,
          ));

          for (let pt = 0; pt < chart.groupObjs[name].values.length; pt++) {
            cPlot.objs.points.pts.push(cPlot.objs.points.g.append("circle")
              .attr("class", "point")
              .attr('r', dOpts.pointSize / 2)// Options is diameter, r takes radius so divide by 2
              .style("fill", chart.dataPlots.colorFunct(name))
              .attr("data-cellLineName", name)
            )
          }

          if (dOpts.showBeanLines) {
            cPlot.objs.bean = {g: null, lines: []};
            cPlot.objs.bean.g = cPlot.objs.g.append("g").attr("class", "bean-plot");
            for (let pt = 0; pt < chart.groupObjs[name].values.length; pt++) {
              cPlot.objs.bean.lines.push(cPlot.objs.bean.g.append("line")
                .attr("class", "bean line")
                .style("stroke-width", '1')
                .style("stroke", chart.dataPlots.colorFunct(name)));
            }
          }
        }
      });

      chart.update();

    };

    chart.dataPlots.preparePlots();

    chart.dataPlots.update();
    window.d3.select(chart.settings.selector).select('svg').select('.x.axis').selectAll('.tick')[0].forEach((tick) => {
      if(tick.length > 0) {
        const currentTransformX = +tick.attributes.transform.nodeValue.match(/[0-9]{2,4}/)[0];
        tick.attributes.transform.value = `translate(${currentTransformX + 2.5},0)`;
        tick.attributes.transform.nodeValue = `translate(${currentTransformX + 2.5},0)`
        tick.attributes.transform.textContent = `translate(${currentTransformX + 2.5},0)`
      }
    });

    if (isNeededTitle) {
      window.d3.select(chart.settings.selector).select('svg').insert('text', ":first-child").text(chartTitle)
        .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

      window.d3.select(chart.settings.selector)
        .select('svg')
        .attr('width', chart.settings.chartSize.width)
        .attr('height', chart.settings.chartSize.height + 200)
    }

    const styles = '.axis path,.axis line {fill: none;stroke: #888;stroke-width: 2px;shape-rendering: crispEdges;} .chart-area text {font-family: sans-serif;font-size: 14px;}.y.axis .tick line {stroke: lightgrey;opacity: 0.6;stroke-dasharray: 2,1;stroke-width: 1;shape-rendering: crispEdges;}.point {stroke: black;stroke-width: 1px;} svg.chart-area {background-color: #ffffff}';
    window.d3.select(chart.settings.selector).select('svg').append('style').text(styles);
    return chart;
  };

  return chart;
};


/* eslint-enable */
