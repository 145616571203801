import React from 'react';
import PropTypes from 'prop-types';

import { CooccurrenceEnum } from '../../../../constantsCommon';

const propTypes = {
  recommendations: PropTypes.instanceOf(Object),
  startNewSearch: PropTypes.func,
};

const SearchForSentenceCooccurrenceSection = (props) => {
  const { recommendations, startNewSearch } = props;
  return (
    <div
      className="search-page__link-block"
      onClick={() => {
        startNewSearch({
          plainValue: recommendations.recommendationPhrase,
          exactValue: recommendations.exactRecommendationPhrase,
          publicationSource: CooccurrenceEnum.SENTENCE,
        });
      }}
    >
      Search for sentence co-occurrence with {recommendations.recommendationPhrase}
    </div>
  );
};

SearchForSentenceCooccurrenceSection.propTypes = propTypes;

export default React.memo(SearchForSentenceCooccurrenceSection);
