export const factorLogicValues = [
  {
    label: 'Strict',
    value: 'STRICT',
  },
  {
    label: 'Functional',
    value: 'FUNCTIONAL',
  },
  {
    label: 'Loose',
    value: 'LOOSE',
  },
];
