import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import ConceptSearchModal from '../../../Concept/ConceptSearchModal/ConceptSearchModal';

const propTypes = {
  recommendations: PropTypes.instanceOf(Object),
};

const LookingForSomethingElseSection = (props) => {
  const { recommendations } = props;
  const [showSearchConceptModal, setShowSearchConceptModal] = useState(false);

  return (
    <>
      <div
        className="search-page__link-block"
        onClick={() => setShowSearchConceptModal(true)}
      >
        {recommendations.recommendationPhrase}
      </div>
      <ConceptSearchModal
        isOpen={showSearchConceptModal}
        closeCb={() => setShowSearchConceptModal(false)}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </>
  );
};

LookingForSomethingElseSection.propTypes = propTypes;

export default React.memo(LookingForSomethingElseSection);
