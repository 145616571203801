import { call, put, takeEvery } from 'redux-saga/effects';

// Api
import Api from '../../Api/Api';
// Store
import Storage from '../auth/Storage/Storage';
import {
  updateTwoFactorAuthenticationPrevSelectedOptionAction,
  updateTwoFactorAuthenticationSelectedOptionAction
} from '../auth/TwoFactorAuthentication/store/reducer';
// Utils
import { sortSemanticCategories } from '../Search/utils';
// Constants
import {
  CURRENT_USER_REQUESTED,
  CURRENT_USER_SUCCEEDED,
  CURRENT_USER_FAILED,
  ALL_CONCEPT_TYPES_REQUESTED,
  ALL_CONCEPT_TYPES_SUCCEEDED,
  ALL_CONCEPT_TYPES_FAILED,
  CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED,
  CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED,
  CEREBRUM_SEMANTIC_CATEGORIES_FAILED,
  CEREBRUM_ACCESS_MAPPINGS_REQUESTED,
  CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED,
  CEREBRUM_ACCESS_MAPPINGS_FAILED,
  USER_UPDATE_OPTION,
} from './constants';
import { TwoFactorAuthenticationOptions } from '../auth/TwoFactorAuthentication/constants';
import { setUsersLastEnrichmentAnalysisSelectedAction } from './actions';

function* currentUser() {
  try {
    const { data } = yield call(Api.currentUser);
    yield put({ type: CURRENT_USER_SUCCEEDED, data });
    const selectedTwoFAOption = TwoFactorAuthenticationOptions.find(o => o.value === data.twoFA);
    yield put(updateTwoFactorAuthenticationPrevSelectedOptionAction(selectedTwoFAOption));
    yield put(updateTwoFactorAuthenticationSelectedOptionAction(selectedTwoFAOption));

    if (data && data.userProfileOptions) {
      const option = data.userProfileOptions.filter(o => o.option === 'ENRICHMENT_ANALYSIS_LAST_OPTION');
      option && option.length ?
        yield put(setUsersLastEnrichmentAnalysisSelectedAction(option[0].optionData)) :
        yield put(setUsersLastEnrichmentAnalysisSelectedAction(null));
    }
  } catch (e) {
    yield put({ type: CURRENT_USER_FAILED, message: e.message });
  }
}

function* conceptTypes() {
  try {
    const response = yield call(Api.getAllConceptTypes);
    yield put({ type: ALL_CONCEPT_TYPES_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: ALL_CONCEPT_TYPES_FAILED, message: e.message });
  }
}

function* cerebrumSC() {
  try {
    const { token } = Storage.getValue('user') || {};
    if (token) {
      const response = yield call(Api.cerebrumSemanticCategories);
      yield put({ type: CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED, data: sortSemanticCategories(response.data) });
    }
  } catch (e) {
    yield put({ type: CEREBRUM_SEMANTIC_CATEGORIES_FAILED, message: e.message });
  }
}

function* cerebrumAccessMappings() {
  try {
    const { token } = Storage.getValue('user') || {};
    if (token) {
      const { data } = yield call(Api.cerebrumAccessMappings);
      yield put({ type: CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED, data });
    }
  } catch (e) {
    yield put({ type: CEREBRUM_ACCESS_MAPPINGS_FAILED, message: e.message });
  }
}

function* setUsersOption(action) {
  try {
    yield call(
      Api.userAddOption,
      { optionName: action.data.option, optionData: action.data.value }
    );
    yield put(setUsersLastEnrichmentAnalysisSelectedAction(action.data.value));
  } catch (e) {
    console.log('Couldn\'t save users option');
  }
}

function* app() {
  yield takeEvery(CURRENT_USER_REQUESTED, currentUser);
  yield takeEvery(ALL_CONCEPT_TYPES_REQUESTED, conceptTypes);
  yield takeEvery(CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED, cerebrumSC);
  yield takeEvery(CEREBRUM_ACCESS_MAPPINGS_REQUESTED, cerebrumAccessMappings);
  yield takeEvery(USER_UPDATE_OPTION, setUsersOption);
}

export default app;
