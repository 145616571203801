import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getGeneDetailsExternalLinksAction = createAction('GENE_DETAILS_GET_EXTERNAL_LINKS');
export const setGeneDetailsExternalLinksAction = createAction('GENE_DETAILS_SET_EXTERNAL_LINKS');
export const toggleGeneDetailsExternalLinksLoaderAction = createAction('GENE_DETAILS_TOGGLE_EXTERNAL_LINKS_LOADER');
export const throwGeneDetailsExternalLinksErrorAction = createAction('GENE_DETAILS_THROW_EXTERNAL_LINKS_ERROR');

const initialState = fromJS({
  data: null,
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [setGeneDetailsExternalLinksAction]: (state, { payload }) =>
      state.update('data', () => fromJS(payload)),
    [toggleGeneDetailsExternalLinksLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwGeneDetailsExternalLinksErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
  },
  initialState
);

export default reducer;
