import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmationDialog from '../../../../Modals/ConfirmationDialog/ConfirmationDialog';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onConfirm: PropTypes.func,
  publicationsToDelete: PropTypes.number,
};

const DeletePublicationsModal = (props) => {
  const {
    isOpen,
    closeCb,
    onConfirm,
    publicationsToDelete,
  } = props;

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      onCancel={closeCb}
      isOpen={isOpen}
      closeCb={closeCb}
      text={
        publicationsToDelete === 1 ?
          'Are you sure you want to remove the publication?' :
          `Are you sure you want to remove ${publicationsToDelete} publications?`
      }
    />
  );
};

DeletePublicationsModal.propTypes = propTypes;

export default React.memo(DeletePublicationsModal);
