import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaConnectdevelop } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { connect } from 'react-redux';

// Components
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
// Store
import { getHideSourceSelectedDataIdsSelector } from '../HideSourceModal/store/selectors';
import { getHideRelationTypesSelectedIdsSelector } from '../HideRelationTypesModal/store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  links: PropTypes.instanceOf(Array),
  settings: PropTypes.instanceOf(Object),
  openHideSource: PropTypes.func,
  updateSettings: PropTypes.func,
  openRelationTypes: PropTypes.func,
  hideSourceData: PropTypes.instanceOf(Array),
  hideRelationTypesData: PropTypes.instanceOf(Array),
};

const RelationMapRelations = (props) => {
  const {
    links,
    settings,
    updateSettings,
    openHideSource,
    openRelationTypes,
    hideSourceData,
    hideRelationTypesData,
  } = props;

  const [belowCount, setBelowCount] = useState(1);

  function setHideBelowCountHandler() {
    updateSettings({
      key: 'hideBelowCount',
      data: {
        hide: !settings.hideBelowCount.hide,
        count: Number(belowCount),
      },
    });
  }

  function setBelowCountHandler(e) {
    const { hideBelowCount: { hide } } = settings;
    const count = Number(e.target.value);

    setBelowCount(e.target.value);

    if (hide) {
      updateSettings({
        key: 'hideBelowCount',
        data: { hide, count },
      });
    }
  }

  function toggleCooccurrenceCountHandler() {
    const { showCooccurrence } = settings;

    updateSettings({
      key: 'showCooccurrence',
      data: !showCooccurrence,
    });
  }

  function toggleCloselyRelatedPubsHandler() {
    const { showCloselyRelatedPubs } = settings;
    updateSettings({
      key: 'showCloselyRelatedPubs',
      data: !showCloselyRelatedPubs,
    });
  }

  function toggleLabelsHandler() {
    const { hideLabels } = settings;
    updateSettings({
      key: 'hideLabels',
      data: !hideLabels,
    });
  }

  function toggleHideRelationTypesHandler() {
    const { hideRelationTypes } = settings;
    updateSettings({
      key: 'hideRelationTypes',
      data: !hideRelationTypes,
    });
    if (!hideRelationTypes && !hideRelationTypesData.length) {
      openRelationTypes();
    }
  }

  function toggleHideSourceHandler() {
    const { hideSource } = settings;
    updateSettings({
      key: 'hideSource',
      data: !hideSource,
    });
    if (!hideSource && !hideSourceData.length) {
      openHideSource();
    }
  }

  return (
    <>
      <div className="relation-map-page__subtitle">
        <FaConnectdevelop size={22} color="#76649d" />
        <span>Relations</span>
        <div className="relation-map-concepts-list__count">
          {`${links.length}`}
        </div>
      </div>
      <div className="relation-map-relations">
        <div className="relation-map-relations__item">
          <Checkbox
            checked={settings.showCooccurrence}
            onChange={toggleCooccurrenceCountHandler}
            disabled={settings.showCloselyRelatedPubs || settings.hideSource || settings.hideRelationTypes}
          />
          <span className="relation-map-relations__text">
            Show co-occurrence count
          </span>
        </div>
        <div className="relation-map-relations__item">
          <Checkbox
            checked={settings.showCloselyRelatedPubs}
            onChange={toggleCloselyRelatedPubsHandler}
            disabled={settings.showCooccurrence || settings.hideSource || settings.hideRelationTypes}
          />
          <span className="relation-map-relations__text">
            Show sentence co-occurrence
          </span>
        </div>
        <div className={
          classNames('relation-map-relations__item',
            { 'relation-map-relations__item_active': settings.hideLabels })}
        >
          <Checkbox
            checked={settings.hideLabels}
            onChange={toggleLabelsHandler}
          />
          <span className="relation-map-relations__text">
            Hide labels
          </span>
        </div>
        <div className={
          classNames('relation-map-relations__item',
            { 'relation-map-relations__item_active': settings.hideBelowCount.hide })}
        >
          <Checkbox
            checked={settings.hideBelowCount.hide}
            onChange={setHideBelowCountHandler}
          />
          <span className="relation-map-relations__text">
            Hide below count
          </span>
          <input
            type="number"
            min="1"
            max="1000"
            value={belowCount}
            className="relation-map-relations__input"
            onChange={setBelowCountHandler}
          />
        </div>
        <div className="relation-map-relations__item">
          <Checkbox
            checked={settings.hideSource}
            onChange={toggleHideSourceHandler}
            disabled={settings.showCooccurrence || settings.showCloselyRelatedPubs}
          />
          <span className="relation-map-relations__text">
            Hide Source
          </span>
          <button
            className="circle-btn"
            onClick={openHideSource}
          >
            <MdSettings size={16} />
          </button>
        </div>
        <div className="relation-map-relations__item">
          <Checkbox
            checked={settings.hideRelationTypes}
            onChange={toggleHideRelationTypesHandler}
            disabled={settings.showCooccurrence || settings.showCloselyRelatedPubs}
          />
          <span className="relation-map-relations__text">
            Hide Relation types
          </span>
          <button
            className="circle-btn"
            onClick={openRelationTypes}
          >
            <MdSettings size={16} />
          </button>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    hideSourceData: getHideSourceSelectedDataIdsSelector(state),
    hideRelationTypesData: getHideRelationTypesSelectedIdsSelector(state),
  };
}

RelationMapRelations.propTypes = propTypes;

export default connect(mapStateToProps)(RelationMapRelations);