import { createSelector } from 'reselect';

import { PAGE_SIZE } from '../../constants';
import { sort } from '../../../Utils/Utils';

const pageChapter = 'geneDetailsConditionOfDysregulationChapter';
const sectionChart = state => state.getIn([pageChapter, 'chart']);
const sectionTable = state => state.getIn([pageChapter, 'table']);
const totalPages = state => state.getIn([pageChapter, 'table', 'totalPages']);
const pageNumber = state => state.getIn([pageChapter, 'table', 'number']);
const sorting = state => state.getIn([pageChapter, 'sorting']);
const loading = state => state.getIn([pageChapter, 'loading']);
const error = state => state.getIn([pageChapter, 'error']);

export const getSectionChart = createSelector(
  sectionChart,
  chart => chart.toJS()
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getSectionTable = createSelector(
  sectionTable,
  tableSorting,
  (table, sorted) => {
    const sortedTableData = sort(table.get('content'), sorted);
    const tableData = sortedTableData.toJS();
    const totalLength = tableData.length;
    const page = table.get('page');
    const start = page * PAGE_SIZE;
    const end = (page * PAGE_SIZE) + PAGE_SIZE;
    const result = tableData.slice(start, end > totalLength ? totalLength : end);

    return { content: result, allContent: tableData };
  }
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
