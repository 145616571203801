import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import LoginForm from './LoginForm';
import Error from '../../common/Error/Error';
import RestorePassword from '../RestorePassword/RestorePassword';
import ModalComponent from '../../ModalComponent/ModalComponent';
import AuthenticatorCodeModal from '../TwoFactorAuthentication/Components/AuthenticatorCodeModal';
// Store
import { restorePasswordModalOpen, restorePasswordModalClose } from '../RestorePassword/actions';
import { chatBotAction } from '../../../location/locationReducer';
import { userLoginRequestAction } from '../../auth/reducer';
import { getOpenedChatBot } from '../../../location/locationSelector';
import { userForm } from '../selectors';
import { getAuthenticatorCodeModalIsOpen } from '../TwoFactorAuthentication/store/selectors';
import { toggleAuthenticatorCodeModalIsOpenAction } from '../TwoFactorAuthentication/store/reducer';
import { AuthenticatorCodeTypesForModal } from '../TwoFactorAuthentication/constants';
// Styles
import '../../../theme/styles/formControls.css';
import './Login.scss';


const propTypes = {
  showModal: PropTypes.bool,
  error: PropTypes.string,
  dispatchUserLogin: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  helpIsOpen: PropTypes.string,
  setHelpIsOpen: PropTypes.func,
  userFormData: PropTypes.instanceOf(Object),
  authenticatorCodeModalIsOpen: PropTypes.bool,
  toggleAuthenticatorCodeModalIsOpen: PropTypes.func,
};

const Login = (props) => {
  const {
    error,
    showModal,
    closeModal,
    userFormData,
    openModal,
    dispatchUserLogin,
    authenticatorCodeModalIsOpen,
    toggleAuthenticatorCodeModalIsOpen,
  } = props;

  const forgetPassword = (e) => {
    e.preventDefault();
    openModal();
  };

  return (
    <div className="login">
      <div className="flex-grid pt-15pr">
        <div className="col-1" />
        <div className="col-1 text-center">
          <div className="logo-container login-euretos-logo inline-block" />
          <Error
            show={!!error}
            error={error}
            noTitle={true}
            customClassName="text-center error-text"
          />
          <LoginForm
            userFormData={userFormData}
            loginCallback={dispatchUserLogin}
          />
          <div className="flex-grid login-help">
            <div className="col-1">
              <span
                className="login-help__btn"
                onClick={forgetPassword}
              >
                Forgot password?
              </span>
            </div>
            <div className="col-1">
              <a
                target='blank'
                href='https://askeuretos.freshdesk.com/support/home'
                className="login-help__btn link"
              >
                I need help
              </a>
            </div>
          </div>
        </div>
        <div className="col-1" />
      </div>
      {
        showModal &&
        <ModalComponent
          isOpen={showModal}
          closeCb={closeModal}
        >
          <RestorePassword />
        </ModalComponent>
      }
      <AuthenticatorCodeModal
        type={AuthenticatorCodeTypesForModal.LOGIN}
        isOpen={authenticatorCodeModalIsOpen}
        closeCb={() => toggleAuthenticatorCodeModalIsOpen(false)}
        loginError={error}
      />
    </div>
  );
};

Login.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: state.get('auth').get('error'),
    showModal: state.get('restorePassword').get('showModal'),
    helpIsOpen: getOpenedChatBot(state),
    userFormData: userForm(state),
    authenticatorCodeModalIsOpen: getAuthenticatorCodeModalIsOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUserLogin() {
      dispatch(userLoginRequestAction());
    },
    openModal() {
      dispatch(restorePasswordModalOpen());
    },
    closeModal() {
      dispatch(restorePasswordModalClose());
    },
    setHelpIsOpen(data) {
      dispatch(chatBotAction(data));
    },
    toggleAuthenticatorCodeModalIsOpen(data) {
      dispatch(toggleAuthenticatorCodeModalIsOpenAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
