import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { AiOutlineUndo } from 'react-icons/ai';
// Utils
import { exportToPNG, exportToSVG } from '../../Utils/Utils';
import { makeDistroChartForNewData } from './distrochartForNewData';
// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import RadioInput from '../../common/Inputs/RadioInput/RadioInput';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
import Button from '../../common/Buttons/Button/Button';
import Spinner from '../../common/Spinner/Spinner';
// Constants
import { scaleOptions } from '../../../constantsCommon';
// Styles
import './BeesWarmChart.css';

const propTypes = {
  chartData: PropTypes.instanceOf(Array),
  tooltipData: PropTypes.instanceOf(Array),
  switchOpenModal: PropTypes.func,
  geneName: PropTypes.string,
  beesWarmTransform: PropTypes.string,
  chartTitle: PropTypes.string,
  changeBeesWarmTransform: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  chartName: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  setTissuesSelectionMode: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setSelectedCells: PropTypes.func,
  selectedCells: PropTypes.instanceOf(Array),
  getSamplesDataForCsvExport: PropTypes.func,
  geneId: PropTypes.string,
  csvName: PropTypes.string,
  csvDownloadedSamplesLoading: PropTypes.bool,
  activeCellType: PropTypes.string,
};

class BeesWarmChart extends React.Component {
  componentDidMount() {
    this.initBoxplot();
  }

  componentDidUpdate() {
    this.initBoxplot();
  }

  shouldComponentUpdate(nextProps) {
    const { chartData, beesWarmTransform, tooltipData } = this.props;
    if (
      beesWarmTransform !== nextProps.beesWarmTransform ||
      chartData !== nextProps.chartData ||
      tooltipData !== nextProps.tooltipData
    ) {
      return true;
    }
    return false;
  }

  showTooltipHandler(d, x, y) {
    const { setShortConceptCardId, showTooltip } = this.props;
    if (d) {
      const { id } = d.concept;
      const config = {
        uniqueKey: 'beeswarm-tooltip',
        clientX: x,
        clientY: y,
      };
      setShortConceptCardId(id);
      showTooltip(config);
    }
  }

  transformData = () => {
    const {
      chartData,
      beesWarmTransform,
    } = this.props;

    if (beesWarmTransform === 'log') {
      return chartData.map(item => ({
        name: item.name,
        measure: Math.log2(item.measure + 1),
      }));
    }

    return chartData;
  };

  transformGraphData = () => {
    const {
      data,
      beesWarmTransform,
    } = this.props;

    if (beesWarmTransform === 'log') {
      return data.map(cell => ({
        ...cell,
        summary: {
          ...cell.summary,
          min: Math.log2(cell.summary.min + 1),
          max: Math.log2(cell.summary.max + 1),
          median: Math.log2(cell.summary.median + 1),
          q1: Math.log2(cell.summary.q1 + 1),
          q3: Math.log2(cell.summary.q3 + 1),
        },
        points: cell.points.map(item => Math.log2(item + 1)),
      }));
    }

    return data;
  };

  handleCellClick = (d) => {
    const {
      type,
      data,
      setSelectedCells,
      tissuesSelectionMode,
      switchOpenModal,
    } = this.props;
    const concept = data.find(el => el.concept.name === d);
    if (!concept) return;
    if (setSelectedCells && tissuesSelectionMode) {
      setSelectedCells(concept);
    } else if (!tissuesSelectionMode) {
      const tissuesGroup = concept.tissuesGroup || type;
      switchOpenModal(d, concept, tissuesGroup, type);
    }
  };

  initBoxplot = (isNeededTitle = false) => {
    const {
      switchOpenModal,
      chartTitle,
      beesWarmTransform,
      id,
      type,
      hideTooltip,
      selectedCells,
    } = this.props;

    const transformedData = this.transformData();
    const transformGraphData = this.transformGraphData();

    const chart = makeDistroChartForNewData({
      settings: {
        data: transformedData,
        xName: 'name',
        yName: 'measure',
        axisLabels: { xAxis: null, yAxis: beesWarmTransform === 'linear' ? 'TPM' : 'log2(TPM + 1)' },
        selector: `#${id}`,
        chartSize: { height: 750, width: 1400 },
        margin: {
          top: 50, right: 150, bottom: 400, left: 70,
        },
      },
      type,
      switchOpenModal,
      isNeededTitle,
      chartTitle,
      data: transformGraphData,
      showTooltipHandler: this.showTooltipHandler.bind(this),
      hideTooltip,
      handleCellClick: this.handleCellClick,
      selectedCells,
    });
    chart.renderDataPlots({
      showPlot: true, plotType: 'beeswarm', showBeanLines: false, colors: null,
    });
  };

  exportToPNGCb = () => {
    const { geneName, chartName, id } = this.props;
    this.initBoxplot(true);
    const diagram = window.document.querySelector(`#${id} .beeswarm-area`);
    const name = `${geneName}_${chartName}.png`;
    if (diagram) {
      exportToPNG(diagram, name);
    }
    this.initBoxplot();
  };

  exportToSVGCb = () => {
    const { geneName, chartName, id } = this.props;
    this.initBoxplot(true);
    const container = window.document.querySelector(`#${id} .inner-box`);
    const name = `${geneName}_${chartName}.svg`;
    if (container) {
      exportToSVG(container, name);
    }
    this.initBoxplot();
  };

  exportToCSV = (data) => {
    const {
      geneId,
      csvName,
      beesWarmTransform,
      getSamplesDataForCsvExport,
    } = this.props;
    getSamplesDataForCsvExport({
      geneId,
      csvName,
      data,
      type: 'CELL_TYPE',
      scale: scaleOptions[beesWarmTransform].value,
      downloadLinkRef: this.downloadLinkRef,
    });
  };

  handleExportAllToCSV = () => {
    const { data, activeCellType } = this.props;
    const dataForExport = data.filter(d => d.group === activeCellType);
    this.exportToCSV(dataForExport);
  };

  // eslint-disable-next-line consistent-return
  handleExportToCSV = () => {
    const {
      selectedCells,
    } = this.props;

    if (!selectedCells.length) {
      return false;
    }
    this.exportToCSV(selectedCells);
  };

  allowTissuesSelection = () => {
    const { setTissuesSelectionMode } = this.props;
    setTissuesSelectionMode();
  };

  render() {
    const {
      tooltipData,
      changeBeesWarmTransform,
      beesWarmTransform,
      id,
      data,
      selectedCells,
      tissuesSelectionMode,
      unsetTissuesSelectionMode,
      csvDownloadedSamplesLoading,
    } = this.props;

    return (
      <>
        {
          ((data && data.length > 0) || (tooltipData && tooltipData.length > 0)) &&
          <div className="boxplot-controls">
            <div className="boxplot-controls__radio">
              <span className="boxplot-controls__text">Scale:</span>
              <RadioInput
                value="linear"
                checked={beesWarmTransform === 'linear'}
                onChange={changeBeesWarmTransform}
                labelValue="Linear"
              />
              <RadioInput
                value="log"
                checked={beesWarmTransform === 'log'}
                onChange={changeBeesWarmTransform}
                labelValue="Log"
              />
            </div>
            <div className="bar-chart-controls">
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button bar-chart-controls__png"
                  onClick={this.exportToPNGCb}
                  title="Export to png"
                />
                PNG
              </div>
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button"
                  onClick={this.exportToSVGCb}
                  title="Export to svg"
                />
                SVG
              </div>
            </div>
          </div>
        }
        <div className="csv-downloading-block">
          <div className="csv-btn-group btn-group">
            <Spinner isLoading={csvDownloadedSamplesLoading} containerClassName="csv-btn-group__spiner" />
            <label htmlFor="export">To CSV:</label>
            {
              <Button
                text="All"
                onClick={this.handleExportAllToCSV}
                disabled={csvDownloadedSamplesLoading}
                customClassName="btn btn-group__first-btn"
              />
            }
            {
              !tissuesSelectionMode &&
              <Button
                text="Allow tissues selection"
                onClick={this.allowTissuesSelection}
                disabled={csvDownloadedSamplesLoading}
                customClassName="btn btn-group__second-btn"
              />
            }
            {
              tissuesSelectionMode &&
              <Button
                text="Export selected"
                onClick={this.handleExportToCSV}
                disabled={!selectedCells.length || csvDownloadedSamplesLoading}
                customClassName="btn btn-group__second-btn"
              />
            }
            {
              <Button
                onClick={unsetTissuesSelectionMode}
                customClassName="btn btn-group__last-btn"
              >
                <AiOutlineUndo size={24} />
              </Button>
            }
            <a //eslint-disable-line
              hidden={true}
              ref={(ref) => { this.downloadLinkRef = ref; }}
            />
          </div>
          {
            tissuesSelectionMode &&
            <div className="violin-chart__csv-text">
              Select tissue names on x-axis to export data as CSV
            </div>
          }
        </div>
        <div
          id={id}
          className="chart-wrapper"
        />
        <Tooltip uniqueKeyProp="beeswarm-tooltip">
          <ShortConceptCard />
        </Tooltip>
      </>
    );
  }
}

BeesWarmChart.propTypes = propTypes;

export default BeesWarmChart;
