import { takeLatest } from 'redux-saga/effects';

import {
  getPublicationDetailsDataAction,
  getPublicationCitationsAction,
  getPublicationDetailsByPubMedIdDataAction,
} from '../reducer';

import { getPublicationDetailsDataWorker } from './workers/getPublicationDetailsDataWorker';
import { getPublicationCitationsWorker } from './workers/getPublicationCitationsWorker';
import { getPublicationDetailsByPubMedIdDataWorker } from './workers/getPublicationDetailsByPubMedIdDataWorker';

function* createSetSaga() {
  yield takeLatest(getPublicationDetailsDataAction, getPublicationDetailsDataWorker);
  yield takeLatest(getPublicationCitationsAction, getPublicationCitationsWorker);
  yield takeLatest(getPublicationDetailsByPubMedIdDataAction, getPublicationDetailsByPubMedIdDataWorker);
}

export default createSetSaga;
