import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import { checkProjectTab } from '../../../../../Projects/ProjectsTabs/utils';
import {
  getCreateSetAllConceptsAction,
  putLoadedFromSavedSetAction, setDataFromConfigAction,
  throwCreateSetErrorAction
} from '../../actions';
import {apiTypes} from '../../../enums';

export function* getSetForFilteringWorker(action) {
  try {
    const { loadSet, config } = action.payload;
    const { id, projectId } = loadSet;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const requestData = isProjectTab ? { projectId, setId: id } : id;
    const result = yield call(apiToCall, requestData);
    yield put(putLoadedFromSavedSetAction(result.data));

    const creatingData= result.data.creatingData;
    if (creatingData)
      config.startConcepts = creatingData.leftConcepts;
    else
      config.startConcepts = [];
    config.apiType = apiTypes.filterWithConcepts;
    config.concepts = result.data.items.map(i => i.id);
    config.category = result.data.type === 'Mixed' ? null : result.data.type;
    config.setName = result.data.setName;

    const initialFilters = {};
    if (creatingData && creatingData.filter) {
      initialFilters.SKIP_ZERO_PUBLICATIONS = creatingData.filter.skipZeroPublications;
    }

    yield put(getCreateSetAllConceptsAction({
      apiType: config.apiType,
      semanticType: config.semanticType,
      category: config.category,
      endConceptIds: config.concepts,
      startConcepts: config.startConcepts,
      categoryName: config.categoryName,
      initialFilters,
    }));
    yield put(setDataFromConfigAction(config));
  } catch (e) {
    yield put(throwCreateSetErrorAction(e.message));
  }
}
