import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getSamplesDataAction = createAction('@@samples-data/GET_SAMPLES_DATA');
export const clearSamplesDataAction = createAction('@@samples-data/CLEAR_DATA');
export const setSamplesDataAction = createAction('@@samples-data/SET_SAMPLES_DATA');
export const throwSamplesDataErrorAction = createAction('@@samples-data/THROW_SAMPLES_DATA_ERROR');

const initialState = fromJS({
  data: [],
  error: null,
  loading: false,
});

const samplesChartReducer = handleActions({
  [getSamplesDataAction]: state =>
    state
      .update('loading', () => true),
  [setSamplesDataAction]: (state, { payload }) =>
    state
      .update('data', () => fromJS(payload))
      .update('loading', () => false)
      .update('error', () => null),
  [throwSamplesDataErrorAction]: (state, { payload }) =>
    state
      .update('data', () => [])
      .update('loading', () => false)
      .update('error', () => payload),
  [clearSamplesDataAction]: () => initialState,
}, initialState);
export default samplesChartReducer;
