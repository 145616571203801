import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Components
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import Spinner from '../../../common/Spinner/Spinner';
import Loader from '../../../common/Loader/Loader';
import Image from './DelayPatentImage';
import Error from '../../../common/Error/Error';
// Store
import {
  getChemicals,
  getChemicalsLoading,
  getChemicalsError,
} from '../redux/selectors';
import {
  getPatentsChemicalsAction,
  patentsChemicalsLoadingAction,
} from '../redux/actions';
import { AVERAGE_SYMBOL_LENGTH, getParentBlockWidth } from '../../../Utils/Utils';

const propTypes = {
  id: PropTypes.string,
  parentClass: PropTypes.string,
  chemicals: PropTypes.instanceOf(Object),
  chemicalsLoading: PropTypes.bool,
  chemicalsError: PropTypes.string,
  getTherapeuticCandidatesChemicals: PropTypes.func,
  setPatentsLoading: PropTypes.func,
};

class ChemicalsList extends React.Component {
  simpleTableRef = React.createRef(null);

  componentDidMount = () => {
    const {
      id,
      getTherapeuticCandidatesChemicals,
    } = this.props;

    getTherapeuticCandidatesChemicals({ id });
  };

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  componentWillUnmount() {
    const { setPatentsLoading } = this.props;
    setPatentsLoading(true);
  }

  handlePageClick = (pageNumber) => {
    const {
      id,
      getTherapeuticCandidatesChemicals,
    } = this.props;

    getTherapeuticCandidatesChemicals({
      id,
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  getColumnPercentWidth = (percent) => {
    const { parentClass } = this.props;
    const screenWidth = getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { chemicals: { content } } = this.props;
    const { inchikey, image } = content[index];

    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(50);
    const stringLength = inchikey.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    const height = ((stringRows * lineHeight) < 300) && image ? 300 : (stringRows * lineHeight);

    return height + padding;
  };

  render() {
    const {
      chemicals: {
        content,
        number,
        totalPages,
      },
      chemicalsLoading,
      chemicalsError,
      parentClass,
    } = this.props;

    const renderImage = (item, index) => (
      <div className="chemical-item" key={`inchikey-${index}`}>
        <Spinner
          isLoading={!item.image}
          containerClassName="row text-center"
        />
        {
          item.image && item.image.type === 'IMAGE' &&
            <img src={`data:image/png;base64, ${item.image.content}`} alt={item.inchikey} />
        }
        {
          item.image && item.image.type === 'ADDRESS' &&
            <Image alt={item.inchikey} src={item.image.content} deleyMs={(index + 1) * 1000} />
        }
      </div>
    );

    const tableSettings = {
      height: 500,
      width: getParentBlockWidth(parentClass),
      useDynamicRowHeight: true,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
    };

    const columns = [
      {
        label: 'Compound name',
        dataKey: 'compound',
        sortPath: ['compound', 'name'],
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(50),
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.inchikey}
          </div>
        ),
      },
      {
        label: 'Compound image',
        dataKey: 'compoundImage',
        width: this.getColumnPercentWidth(50),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div className="table-wrap__cell-row">
            {
              renderImage(rowData, rowIndex)
            }
          </div>
        ),
      },
    ];

    return (
      <div className="patent-popup__chemicals">

        <div>
          <span className="patent-popup__subtitle">Inchikeys</span>
          {
            totalPages > 1 &&
              <div className="controls-block">
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={number}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
          }
          {
            content && content.length && !chemicalsLoading &&
            <SimpleTable
              innerRef={this.simpleTableRef}
              autoSize={true}
              settings={tableSettings}
              data={content}
              columns={columns}
              dynamicHeight={true}
            />
          }
          <Loader isLoading={chemicalsLoading} />
        </div>
        <Error show={!!chemicalsError} error={chemicalsError} />
      </div>
    );
  }
}

ChemicalsList.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    chemicals: getChemicals(state),
    chemicalsLoading: getChemicalsLoading(state),
    chemicalsError: getChemicalsError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTherapeuticCandidatesChemicals(data) {
      dispatch(getPatentsChemicalsAction(data));
    },
    setPatentsLoading(data) {
      dispatch(patentsChemicalsLoadingAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChemicalsList);
