import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table as VirtualizedTable, CellMeasurer, CellMeasurerCache, Column } from 'react-virtualized';

// Utils
import { getPercentWidth } from '../../Utils/Utils';
import { getSortPath, getServerSortPath, getSortValueMapper } from './utils';

const headerHeight = 50;
const baseRowHeight = 50;

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: baseRowHeight,
  minHeight: baseRowHeight,
});

const noRowsRenderer = () => (
  <div className="noRows">
    There is no data to display
  </div>
);

const propTypes = {
  data: PropTypes.instanceOf(Array),
  columnsData: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  sortCallback: PropTypes.func,
  serverSortCallback: PropTypes.func,
  isServerSortPath: PropTypes.bool,
};

const Table = (props) => {
  const {
    data,
    columnsData,
    width,
    sorting,
    sortCallback,
    serverSortCallback,
    isServerSortPath,
  } = props;

  const ref = useRef(null);

  const sort = useCallback(({ sortBy, sortDirection }) => {
    const sortPath = isServerSortPath ?
      getServerSortPath(sortBy, columnsData) :
      getSortPath(sortBy, columnsData);

    const sortValueMapper = getSortValueMapper(sortBy, columnsData);

    const sortPayload = {
      sortBy,
      sortDirection,
      ...(sortPath && { sortPath }),
      ...(!sortPath && sortValueMapper && { sortValueMapper })
    };

    if (sortCallback) {
      sortCallback(sortPayload);
    }

    if (serverSortCallback && typeof serverSortCallback === 'function') {
      serverSortCallback(sortPayload);
    }
  }, [isServerSortPath, serverSortCallback, sortCallback, columnsData]);

  const rowGetter = useCallback(({ index }) => data[index], [data]);

  const cellRenderer = ({ rowData, columnIndex, key, parent, rowIndex, style }) => {
    const content = columnsData[columnIndex].cellRenderer ?
      columnsData[columnIndex].cellRenderer({ rowData }) :
      rowData[columnsData[columnIndex].dataKey];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild}
            style={{
              ...style,
              width: `${getPercentWidth(width,  columnsData[columnIndex].width)}px`
            }}
            className="table-cell"
          >
            {content}
          </div>
        )}
      </CellMeasurer>
    );
  };

  const columns = columnsData.map((col, i) => (
    <Column
      key={`col_${i}`}
      {...col}
      cellRenderer={cellRenderer}
      width={getPercentWidth(width, col.width)}
    />
  ));

  useEffect(() => {
    cache.clearAll();
    if (ref.current) {
      ref.current.recomputeRowHeights();
    }
  }, [data]);

  return (
    <VirtualizedTable
      ref={ref}
      rowCount={data.length}
      rowGetter={rowGetter}
      rowHeight={cache.rowHeight}
      noRowsRenderer={noRowsRenderer}
      width={width}
      height={Infinity}
      autoHeight={true}
      headerHeight={headerHeight}
      deferredMeasurementCache={cache}
      sort={sort}
      {...sorting}
    >
      {columns}
    </VirtualizedTable>
  );
};

Table.propTypes = propTypes;

export default React.memo(Table);
