import { takeLatest, call, put, select } from 'redux-saga/effects';

// Utils
import Api from '../../../../../../Api/Api';
import { generateAvailableFiltersStructure } from '../../../../../Sets/CreateSet/utils';
import { geneSetFiltersNames, publicationsFiltersNames } from '../../../../../Sets/CreateSet/enums';
// Store
import {
  getGeneFilterOptionsAction,
  setGeneFilterOptionsAction,
  getIntermediateConceptsDataAction,
  setIntermediateConceptsDataAction,
  throwIntermediateConceptsErrorAction,
  setFilteredIntermediateConceptsAction,
  getIntermediateConceptsBeFiltersAction,
  setIntermediateConceptsBeFiltersAction,
  getSourceDetailsFilterOptionsAction,
  setSourceDetailsFilterOptionsAction,
} from './reducer';
import { getIntermediateConceptsBeFiltersSelector } from './selectors';
import { getAccessMappingsSelector, getCerebrumST } from '../../../../../Header/selectors';

function* getIntermediateConceptsDataWorker({ payload }) {
  try {
    const { requestData, filters, firstConceptsFetch } = payload;
    const { data } = yield call(Api.getIntermediateConcepts, requestData);
    if (firstConceptsFetch) {
      const st = yield select(getCerebrumST);
      const options = data.reduce((acc, item) => {
        const typeNames = item.concept.semanticTypes;
        typeNames.forEach((semType) => {
          if (!acc[semType]) {
            acc[semType] = {
              value: semType,
              label: st[semType],
            };
          }
        });
        return acc;
      }, {});
      yield put(setIntermediateConceptsDataAction({ data, options: Object.values(options) }));
    } else {
      yield put(setFilteredIntermediateConceptsAction({
        data,
        filters,
        concepts: data.map(d => d.concept.id),
      }));
    }
  } catch (e) {
    yield put(throwIntermediateConceptsErrorAction(e.message));
  }
}

function* getIntermediateConceptsBeFiltersWorker({ payload: { category, startConceptsIds } }) {
  try {
    const { data } = yield call(Api.getAvailableFilters, { params: { category, startConcepts: startConceptsIds } });
    const availableFilters = generateAvailableFiltersStructure(data);
    yield put(setIntermediateConceptsBeFiltersAction({
      filters: availableFilters,
      type: category,
    }));
  } catch (e) {
    yield put(throwIntermediateConceptsErrorAction(e.message));
  }
}

function* getGeneFilterOptionsWorker({ payload }) {
  try {
    const { data } = payload.filterName === geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER ?
      yield call(Api.tissue) :
      yield call(Api.getGeneSetFilterOptions, payload);

    const availableFilters = yield select(getIntermediateConceptsBeFiltersSelector);
    const filter = availableFilters[payload.filterName];

    yield put(setGeneFilterOptionsAction({
      ...filter,
      isLoading: false,
      options: data.map((item) => {
        const label = payload.filterName === geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER ?
          `${item.name} (${item.typeName})` : item.name;
        return {
          ...item,
          label,
          value: item.name,
        };
      }).sort((a, b) => a.label.localeCompare(b.label)),
    }));
  } catch (e) {
    yield put(throwIntermediateConceptsErrorAction(e.message));
  }
}

export function* getSourceDetailsFilterOptionsWorker({ payload }) {
  try {
    const beFilters = yield select(getIntermediateConceptsBeFiltersSelector);
    const accessMappings = yield select(getAccessMappingsSelector);
    const filter = beFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER];

    const { data } = yield call(Api.getSources, payload);

    const options = data.map(d => ({
      value: d,
      label: accessMappings[d],
    }));

    yield put(setSourceDetailsFilterOptionsAction({
      ...filter,
      isLoading: false,
      options,
    }));
  } catch (e) {
    yield put(throwIntermediateConceptsErrorAction(e.message));
  }
}

function* relationMapSaga() {
  yield takeLatest(getIntermediateConceptsDataAction, getIntermediateConceptsDataWorker);
  yield takeLatest(getIntermediateConceptsBeFiltersAction, getIntermediateConceptsBeFiltersWorker);
  yield takeLatest(getGeneFilterOptionsAction, getGeneFilterOptionsWorker);
  yield takeLatest(getSourceDetailsFilterOptionsAction, getSourceDetailsFilterOptionsWorker);
}

export default relationMapSaga;
