import { createSelector } from 'reselect';

const pageChapter = 'geneDetailsPathwaysChapter';

const pathwaysOptions = state => state.getIn([pageChapter, 'pathwaysOptions']);
const image = state => state.getIn([pageChapter, 'image']);
const pathwaysLoading = state => state.getIn([pageChapter, 'loading']);
const pathwaysError = state => state.getIn([pageChapter, 'error']);

export const getPathwaysOptionsSelector = createSelector(
  pathwaysOptions,
  data => data && data.toJS(),
);

export const getPathwaysImageSelector = createSelector(
  image,
  data => data && data.toJS(),
);

export const getLoadingSelector = createSelector(
  pathwaysLoading,
  loading => loading
);

export const getErrorSelector = createSelector(
  pathwaysError,
  error => error
);
