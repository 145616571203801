import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getIndicationFinderDiseasesAction = createAction('@@indication-diseases-finder/GET_OPTIONS');
export const setIndicationFinderDiseasesAction = createAction('@@indication-diseases-finder/SET_OPTIONS');
export const toggleIndicationFinderDiseasesLoadingAction = createAction('@@indication-diseases-finder/TOGGLE_DATA');
export const resetIndicationFinderDiseasesResetAction = createAction('@@indication-diseases-finder/RESET_DATA');
export const throwIndicationFinderDiseasesErrorAction = createAction('@@indication-diseases-finder/THROW_ERROR');

const initialState = fromJS({
  data: [],
  loading: false,
  error: '',
});

const reducer = handleActions(
  {
    [setIndicationFinderDiseasesAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('error', () => false)
        .update('loading', () => false),
    [toggleIndicationFinderDiseasesLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwIndicationFinderDiseasesErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [resetIndicationFinderDiseasesResetAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
