import { call, put, takeLatest, all } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Store
import {
  shortConceptCardRequestedAction,
  shortConceptCardSucceededAction,
  shortConceptCardLoadingAction,
  shortConceptCardErrorAction,
} from './reducer';

export function* getShortConceptCardDataWorker({ payload }) {
  try {
    yield put(shortConceptCardLoadingAction(true));
    let responceData;

    if (Array.isArray(payload)) {
      const responce = yield all(
        payload.map(id => call(Api.getShortConceptCard, id))
      );
      responceData = responce.map(d => d.data);
    } else {
      const responce = yield call(Api.getShortConceptCard, payload);
      responceData = [responce.data];
    }

    yield put(shortConceptCardSucceededAction(responceData));
  } catch (e) {
    yield put(shortConceptCardErrorAction(e.message));
    yield put(shortConceptCardLoadingAction(false));
  }
}

function* shortConceptCardSaga() {
  yield takeLatest(shortConceptCardRequestedAction, getShortConceptCardDataWorker);
}

export default shortConceptCardSaga;
