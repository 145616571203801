import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';

const propTypes = {
  associationScoreTerms: PropTypes.instanceOf(Array),
  rankingTypes: PropTypes.instanceOf(Object),
  rankingClickHandler: PropTypes.func,
  openRankingModal: PropTypes.func,
  literatureSearchTerms: PropTypes.instanceOf(Array),
};

class NoGeneSetOptions extends React.Component {
  render() {
    const {
      associationScoreTerms,
      rankingTypes,
      rankingClickHandler,
      openRankingModal,
      literatureSearchTerms,
    } = this.props;

    return (
      <div>
        <div className="flex-grid justify-content-center m-5">
          <div className="col-1 text-left">
            <Checkbox
              checked={true}
              readOnly={true}
              className="float-left"
              labelValue="Classification"
            />
          </div>
        </div>
        <div className="flex-grid justify-content-center m-5">
          <div className="col-1 text-left">
            <Checkbox
              checked={rankingTypes.associationScore}
              className="float-left"
              labelValue="Indirect Association Score"
              onChange={() => { rankingClickHandler('associationScore'); }}
            />
            {associationScoreTerms?.map((term, index) => (
              <span
                role="presentation"
                onClick={() => { openRankingModal('associationScore'); }}
                className="link association-term"
                key={index}
              >
                { term.name }
              </span>
            )) ?? null}
          </div>
        </div>
        <div className="flex-grid justify-content-center m-5">
          <div className="col-1 text-left">
            <Checkbox
              className="float-left"
              checked={rankingTypes.literatureSearch}
              labelValue="Literature Search"
              onChange={() => rankingClickHandler('literatureSearch')}
            />
            {literatureSearchTerms?.map((term, index) => (
              <span
                role="presentation"
                onClick={() => { openRankingModal('literatureSearch'); }}
                className="link association-term"
                key={index}
              >
                { term.name }
              </span>
            )) ?? null}
          </div>
        </div>
      </div>
    );
  }
}

NoGeneSetOptions.propTypes = propTypes;

export default NoGeneSetOptions;
