import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Component
import LegendComponent from '../LegendComponent/LegendComponent';
// Utils
import { exportToPNG, exportToSVG } from '../../Utils/Utils';
import { drawStackedBoxPlot } from './drawStackedBoxPlot';
// Constants
import { GREEN_COLOR, RED_COLOR } from '../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  expressionName: PropTypes.string,
  isHiddenExport: PropTypes.bool,
  geneName: PropTypes.string,
  chartTitle: PropTypes.string,
  groupsKey: PropTypes.string,
  subgroups: PropTypes.instanceOf(Array),
  showLegend: PropTypes.bool,
};

const StackedBoxPlot = (props) => {
  const {
    expressionName,
    chartTitle,
    data,
    groupsKey,
    subgroups,
    isHiddenExport,
    id,
    showLegend,
  } = props;

  const initBoxplot = useCallback((showTitle = false) => {
    drawStackedBoxPlot({
      data,
      subgroups,
      groupsKey,
      htmlRoot: id,
      showTitle,
      showLegend,
      chartTitle,
      colors: [GREEN_COLOR, RED_COLOR],
    });
  }, [data, id, chartTitle]);

  const exportToPNGCb = useCallback(() => {
    initBoxplot(true);
    const diagram = document.querySelector(`#${id} svg`);
    const name = `${expressionName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, expressionName);
    }
    initBoxplot();
  }, [id, expressionName, initBoxplot]);

  const exportToSVGCb = useCallback(() => {
    initBoxplot(true);
    const container = document.querySelector(`#${id}`);
    const name = `${expressionName}.svg`;
    if (container) {
      exportToSVG(container, name, false, true, expressionName);
    }
    initBoxplot();
  }, [id, expressionName, initBoxplot]);

  useEffect(() => {
    if (data) {
      initBoxplot();
    }
  }, [data]);

  useEffect(() => () => {
    const tooltip = document.querySelector('.chart-tooltip-stacked-boxplot');
    if (tooltip) {
      tooltip.remove();
    }
  }, []);

  
  return (
    <div className="stacked-box-plot-wrapper">
      <div className="stacked-box-plot-controls">
        {
          !isHiddenExport &&
          <div className="bar-chart-controls">
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button bar-chart-controls__png"
                onClick={exportToPNGCb}
                title="Export to png"
              />
              PNG
            </div>
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button"
                onClick={exportToSVGCb}
                title="Export to svg"
              />
              SVG
            </div>
          </div>
        }
      </div>
      <div
        className="stacked-box-plot"
        id={id}
      />
      <LegendComponent
        legendsParams={[{ name: subgroups[0] }, { name: subgroups[1] }]}
        legendsColors={[GREEN_COLOR, RED_COLOR]}
        keyToName="name"
      />
    </div>
  );
};

StackedBoxPlot.propTypes = propTypes;

export default React.memo(StackedBoxPlot);
