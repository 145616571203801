import { takeLatest } from 'redux-saga/effects';

import {
  getVennSetsAction,
} from '../reducer';

import { getAllSetsWorker } from './workers/getAllSetsWorker';

function* setVennDiagramSaga() {
  yield takeLatest(getVennSetsAction, getAllSetsWorker);
}

export default setVennDiagramSaga;
