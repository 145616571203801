import { createSelector } from 'reselect';

const getTaxonomies = state => state.get('taxonomies');

const getMainTaxonomies = (state, ownProps) => ownProps.exclude;

export const getTaxonomiesListSelector = createSelector(
  getTaxonomies,
  getMainTaxonomies,
  (taxonomies, mainTaxonomies) => {
    let list = taxonomies.get('list').toJS();
    if (mainTaxonomies) {
      const mainTaxonomiesInLowerCase = mainTaxonomies.map(item => item.toLowerCase());
      list = list.filter(item => !mainTaxonomiesInLowerCase.includes(item.toLowerCase()));
    }
    return list;
  }
);

export const getTaxonomiesLoadingSelector = createSelector(
  getTaxonomies,
  taxonomies => taxonomies.getIn(['loading'])
);
