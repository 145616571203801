import { createSelector } from 'reselect';

const loading = state => state.getIn(['setsVennReducer', 'loading']);
const error = state => state.getIn(['setsVennReducer', 'error']);

export const getVennSetsLoading = createSelector(
  loading,
  data => data
);

export const getVennSetsError = createSelector(
  error,
  data => data
);
