import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Utils
import { drawBeesWarmGraph } from './drawBeesWarmGraph';
import { convertTableToCSVText, exportToFile, exportToPNG, exportToSVG } from '../../Utils/Utils';
import { scaleTransformData } from '../../GeneDetails/GeneExpressionChapter/utils';
// Components
import RadioInput from '../../common/Inputs/RadioInput/RadioInput';
import LegendComponent from '../LegendComponent/LegendComponent';
import Spinner from '../../common/Spinner/Spinner';
import Button from '../../common/Buttons/Button/Button';
import {
  graphTypes,
} from '../../GeneDetails/GeneExpressionChapter/constants';
// Icons
import { AiOutlineUndo } from 'react-icons/ai';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  changeBoxPlotTransform: PropTypes.func,
  chartName: PropTypes.string,
  geneName: PropTypes.string,
  yAxisText: PropTypes.string,
  boxPlotTransform: PropTypes.string,
  updateTissuesData: PropTypes.func,
  switchOpenModal: PropTypes.func,
  legendData: PropTypes.instanceOf(Array),
  customWrapClass: PropTypes.string,
  showLegend: PropTypes.bool,
  chartTitle: PropTypes.string,
  calculateCustomPadding: PropTypes.func,
  selectedCells: PropTypes.instanceOf(Array),
  setDataFixed: PropTypes.func,
  isDataFixed: PropTypes.bool,
  isCsvDownloadingAllowed: PropTypes.bool,
  setSelectedCells: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setTissuesSelectionMode: PropTypes.func,
  csvName: PropTypes.string,
};

const graphId = 'beeswarm-graph';

const BeesWarmGraph = (props) => {
  const {
    data,
    chartName,
    chartTitle,
    geneName,
    yAxisText,
    boxPlotTransform,
    updateTissuesData,
    changeBoxPlotTransform,
    switchOpenModal,
    legendData,
    setDataFixed,
    isDataFixed,
    customWrapClass,
    showLegend,
    calculateCustomPadding,
    selectedCells,
    isCsvDownloadingAllowed,
    setSelectedCells,
    unsetTissuesSelectionMode,
    tissuesSelectionMode,
    setTissuesSelectionMode,
    csvName,
  } = props;

  const downloadLinkRef = useRef(null);

  const transformedData = useMemo(() => {
    return scaleTransformData(boxPlotTransform, data, 'ntpm');
  }, [boxPlotTransform, data]);

  const handleAxisClick = useCallback((d) => {
    const concept = { name: d, samplesData: data.filter(el => el.tissueName === d) };

    if (setSelectedCells && tissuesSelectionMode) {
      setSelectedCells(concept);
    } else if (!tissuesSelectionMode) {
      switchOpenModal(d, concept, graphTypes.HPA_PER_TISSUES);
    }
  }, [transformedData, setSelectedCells, tissuesSelectionMode]);

  function drawChart(isChartDownloading) {
    const customPadding = calculateCustomPadding && calculateCustomPadding(isChartDownloading);

    drawBeesWarmGraph({
      data: transformedData,
      graphId,
      isDataFixed,
      setDataFixed,
      updateTissuesData,
      yAxisText,
      boxPlotTransform,
      handleAxisClick,
      showLegend,
      legendData,
      chartTitle,
      isChartDownloading,
      customPadding,
      selectedCells,
    });
  }

  const handleChangeBoxPlotTransform = (e) => {
    setDataFixed(false);
    changeBoxPlotTransform(e);
  };

  const exportToPNGCb = () => {
    drawChart(true);
    const diagram = document.querySelector(`#${graphId} svg`);
    const name = `${chartName}_${geneName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false);
    }
    drawChart();
  };

  const exportToSVGCb = () => {
    drawChart(true);
    const container = document.querySelector(`#${graphId}`);
    const name = `${chartName}_${geneName}.svg`;
    if (container) {
      exportToSVG(container, name, false, false);
    }
    drawChart();
  };

  const exportToCSV = (data) => {
    const columns = [
      {
        label: 'Tissue',
        dataKey: 'tissueName',
      },
      {
        label: 'Cell Type',
        dataKey: 'cellType',
      },
      {
        label: 'Cell Type Group',
        dataKey: 'cellTypeGroup'
      },
      {
        label: 'nTPM',
        dataKey: 'ntpm',
      },
    ];

    const csvData = convertTableToCSVText(data, columns);
    exportToFile(downloadLinkRef.current, csvData, csvName);
  };

  const handleExportAllToCSV = useCallback(() => {
    exportToCSV(transformedData);
  }, [transformedData, boxPlotTransform]);

  const handleExportToCSV = useCallback(() => {
    if (selectedCells.length) {
      exportToCSV(
        scaleTransformData(boxPlotTransform, selectedCells.map( c => c.samplesData).flat(), 'ntpm')
      );
    }
  }, [selectedCells, boxPlotTransform]);

  useEffect(() => {
    if (transformedData.length > 0) {
      drawChart();
    }
  }, [transformedData, tissuesSelectionMode, selectedCells]);

  useEffect(() => () => {
    setDataFixed(false);
    const tooltip = document.querySelector('.chart-tooltip_mode');
    if (tooltip) {
      tooltip.remove();
    }
  }, []);

  return (
    <div className={classNames('beeswarm-graph__wrap', customWrapClass)}>
      <div className="boxplot-controls">
        <div className="boxplot-controls__radio">
          <span className="boxplot-controls__text">Scale:</span>
          <RadioInput
            value="linear"
            checked={boxPlotTransform === 'linear'}
            onChange={handleChangeBoxPlotTransform}
            labelValue="Linear"
          />
          <RadioInput
            value="log"
            checked={boxPlotTransform === 'log'}
            onChange={handleChangeBoxPlotTransform}
            labelValue="Log"
          />
        </div>
        <div className="bar-chart-controls">
          <div className="bar-chart-export-button-wrapper">
            <div
              className="export-button bar-chart-controls__png"
              onClick={exportToPNGCb}
              title="Export to png"
            />
            PNG
          </div>
          <div className="bar-chart-export-button-wrapper">
            <div
              className="export-button"
              onClick={exportToSVGCb}
              title="Export to svg"
            />
            SVG
          </div>
        </div>
      </div>
      {
        isCsvDownloadingAllowed &&
        <div className="csv-downloading-block">
          <div className="csv-btn-group btn-group">
            {
              <>
                <Spinner
                  containerClassName="csv-btn-group__spiner"
                />
                <label htmlFor="export">To CSV:</label>
                {
                  <Button
                    text="All"
                    onClick={handleExportAllToCSV}
                    customClassName="btn btn-group__first-btn"
                  />
                }
                {
                  !tissuesSelectionMode &&
                  <Button
                    text="Allow tissues selection"
                    onClick={setTissuesSelectionMode}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  tissuesSelectionMode &&
                  <Button
                    text="Export selected"
                    onClick={handleExportToCSV}
                    disabled={!selectedCells.length}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  <Button
                    onClick={unsetTissuesSelectionMode}
                    customClassName="btn btn-group__last-btn"
                  >
                    <AiOutlineUndo size={24} />
                  </Button>
                }
                <a //eslint-disable-line
                  hidden={true}
                  ref={downloadLinkRef}
                />
              </>
            }
          </div>
          {
            tissuesSelectionMode &&
            <div className="violin-chart__csv-text">
              Select tissue names on x-axis to export data as CSV
            </div>
          }
        </div>
      }
      <div className="beeswarm-graph-container">
        <div
          id="beeswarm-graph"
          className="beeswarm-graph"
        />
        <LegendComponent
          legendsParams={legendData}
          legendsColors={legendData.map(i => i.color)}
          keyToName="name"
        />
      </div>
    </div>
  );
};

BeesWarmGraph.propTypes = propTypes;

export default React.memo(BeesWarmGraph);
