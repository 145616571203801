export const drawHighlights = (marker, recentHighlights, prevHighlighs, semanticCategories, onMouseover, onMouseout) => {
  let highlights = {};
  let unmark = false;
  const recentHighlightsKeys = recentHighlights && Object.keys(recentHighlights);

  if (prevHighlighs) {
    const prevHighlighsKeys = Object.keys(prevHighlighs);
    if (prevHighlighsKeys.length > recentHighlightsKeys.length) {
      prevHighlighsKeys.forEach((key) => {
        if (!recentHighlightsKeys.includes(key)) {
          highlights = { ...highlights, [key]: prevHighlighs[key] };
          unmark = true;
        }
      });
    } else {
      recentHighlightsKeys.forEach((key) => {
        if (!prevHighlighsKeys.includes(key)) {
          highlights = { ...highlights, [key]: recentHighlights[key] };
        }
      });
    }
  } else {
    highlights = recentHighlights;
  }

  const markHighlights = () => {
    const highlightsValues = highlights && Object.values(highlights);
    const highlightsKeys = highlights && Object.keys(highlights);

    if (highlightsValues) {
      highlightsValues.forEach((el, idx) => {
        const category = semanticCategories.find(item => item.category === highlightsKeys[idx]);
        if (unmark) {
          marker.unmark({ className: category.class });
        } else {
          const markRanges = el.map(({ start, length, id }) => ({ start, length, id }));
          marker.markRanges(markRanges, {
            className: category.class,
            each: (d, r) => {
              if (!!onMouseover && !!onMouseout) {
                d.addEventListener('mouseover', (e) => { onMouseover(e, r.id); });
                d.addEventListener('mouseout', () => { onMouseout(); });
              }
            },
          });
        }
      });
    }
  };

  if (marker) {
    markHighlights();
  }
};

export const makeLink = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => (
    `<a class="link" target="_blank" href="${url}">${url}</a>`
  ));
};
