import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const propTypes = {
  step: PropTypes.number,
  tipProps: PropTypes.instanceOf(Object),
  defaultValue: PropTypes.instanceOf(Array),
  style: PropTypes.instanceOf(Object),
  abilityToExtend: PropTypes.bool,
};

const RangeInput = (props) => {
  const {
    step,
    style = {},
    defaultValue,
    tipProps = { placement: 'top' },
    abilityToExtend,
  } = props;

  useLayoutEffect(() => {
    // fix for moving second tooltips when user extends the chart (only for chart, which extends - search page)
    if(abilityToExtend) {
      const tooltips = document.querySelectorAll('.rc-slider-tooltip-placement-bottom');
      if (tooltips.length) {
        tooltips[0].style.left = document.querySelector('.rc-slider-handle-1').style.left;
        tooltips[1].style.left = document.querySelector('.rc-slider-handle-2').style.left;
      }
    }
  }, [style.width]);

  return (
    <Range
      min={defaultValue[0]}
      max={defaultValue[1]}
      tipFormatter={value => value}
      tipProps={tipProps}
      ariaLabelledByForHandle={value => value}
      step={step}
      {...props}
    />
  );
};

RangeInput.propTypes = propTypes;

export default RangeInput;
