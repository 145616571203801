import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';

export function drawDoubleBoxPlot(args) {
  const {
    htmlRoot,
    data,
    showTitle,
    showLegend,
    chartTitle,
    subgroups,
    groupsKey,
    colors,
    plotYScale,
  } = args;

  const BOX_PLOT_WIDTH = 30;
  const margin =
      { top: 50, right: 150, bottom: 200, left: 80},
    width = data.length * BOX_PLOT_WIDTH,
    svgWidth = data.length * BOX_PLOT_WIDTH + margin.left + margin.right,
    height = 600;

  const yMax = Math.max(d3js.max(data.map(d => d[subgroups[0]] || 0)), d3js.max(data.map(d => d[subgroups[1]] || 0)));
  const yMin = Math.min(d3js.min(data.map(d => d[subgroups[0]] || 0)), d3js.min(data.map(d => d[subgroups[1]] || 0)));

  const yDomain = Math.round(yMax) === 0 ? yMax : Math.round(yMax + Number.EPSILON);
  let yMinDomain = Math.round(yMin) > 0 ? 0 : Math.round(yMin - Number.EPSILON);
  if (yMin > -10 && yMin < yMinDomain) yMinDomain = Math.round(yMin * 100) / 100;

  const bodyHtml = document.querySelector('body');
  d3js.selectAll(`#${htmlRoot} > *`).remove();

  function getBoxPlotTooltipTemplate(d) {
    return `
        <div class="chart-tooltip-boxplot__content chart-tooltip-stacked-boxplot__content">
          <span><b>${d.key}:</b> ${d.value.toFixed(2)}</span><br/>
        </div>
      `;
  }

  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', 'chart-tooltip-boxplot chart-tooltip-stacked-boxplot');

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d) {
    tooltip
      .html(getBoxPlotTooltipTemplate(d))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
  }

  const svg = d3js.select(`#${htmlRoot}`)
    .append('svg')
    .attr('width', svgWidth)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // List of groups = species here = value of the first column called group -> I show them on the X axis
  const groups = data.map(d => d[groupsKey]);

  const x = d3js.scaleBand()
    .domain(groups)
    .range([0, width])
    .padding([0.2]);

  const xAxis = svg.append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3js.axisBottom(x).tickSizeOuter(0));

  // Add Y axis
  var y = d3js.scaleLinear()
    .domain([yMinDomain, yDomain])
    .range([ height, 0 ]);
  const yAxis = svg.append('g')
    .call(d3js.axisLeft(y));

  // Another scale for subgroup position?
  var xSubgroup = d3js.scaleBand()
    .domain(subgroups)
    .range([0, x.bandwidth()])
    .padding([0.05]);

  yAxis.selectAll('text')
    .attr('x', -14)
    .style('font-size', '16px');

  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -((height) / 2))
    .attr('dy', '-50')
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text(plotYScale === 'linear' ? 'TPM' : 'log2(TPM + 1)');

  const color = d3js.scaleOrdinal()
    .domain(subgroups)
    .range(colors);

  // Show the bars
  svg.append('g')
    .selectAll('g')
    // Enter in data = loop group per group
    .data(data)
    .enter()
    .append('g')
    .attr('transform', function(d) { return 'translate(' + x(d.value) + ',0)'; })
    .selectAll('rect')
    .data(function(d) { return subgroups.map(function(key) { return {key: key, value: d[key]}; }); })
    .enter().append('rect')
    .attr('x', function(d) { return xSubgroup(d.key); })
    // .attr('y', function(d) { return y(d.value); })
    .attr('y', function(d) { return y(Math.max(0, d.value)); })
    .attr('width', xSubgroup.bandwidth())
    // .attr('height', function(d) { return height - y(d.value); })
    .attr('height', function(d) { return Math.abs(y(d.value) - y(0)); })
    .attr('fill', function(d) { return color(d.key); })
    .style('cursor', 'pointer')
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave);

  xAxis.selectAll('text')
    .attr('x', 15)
    .attr('y', 4)
    .style('text-anchor', 'start')
    .style('text-anchor', 'start')
    .style('font-size', '16px')
    .attr('transform', 'rotate(45)');

  if (showTitle) {
    const downloadedSvg = d3js.select(`#${htmlRoot}`)
      .select('svg')
      .attr('width', data.length < 15 ? svgWidth + 600 : svgWidth);

    downloadedSvg.append('text')
      .attr('class', 'chartTitle')
      .text(`${chartTitle}`)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    if (showLegend) {
      const SVGElement = d3js.select(`#${htmlRoot} svg`)
        .append('g')
        .attr('transform', () => `translate(-${margin.left}, ${height + 90})`);

      drawLegend({
        element: SVGElement,
        keys: subgroups,
        colors,
      });
    }
  }
}

