import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form/lib/immutable';

// Components
import CreateSetFilterField from '../CreateSetFilterField/CreateSetFilterField';
import FormInput from '../../../../common/FormElements/FormInput';
import FormSelect from '../../../../common/FormElements/FormSelect';
// Utils
import { publicationsFiltersNames } from '../../enums';
import {
  searchFilterSelectOptionsEnum,
  SearchSourceFilterEnum,
  searchSourcesOptions,
} from '../../../../Search/enums';
import { getSourceDataRequestPayload } from '../../utils';

const propTypes = {
  publicationSource: PropTypes.string,
  conceptIds: PropTypes.instanceOf(Array),
  availableFilters: PropTypes.instanceOf(Object),
  filteredConceptsIds: PropTypes.instanceOf(Array),
  getSourceDetailsFilterOptions: PropTypes.func,
  formValueChange: PropTypes.func,
};

const CreateSetPublicationsFilters = (props) => {
  const {
    conceptIds,
    formValueChange,
    availableFilters,
    publicationSource,
    filteredConceptsIds,
    getSourceDetailsFilterOptions,
  } = props;

  function formatSourceDetailsOptionLabel(option) {
    const { label } = option;
    return (
      <div className="source-deatails-option">
        <div className="source-deatails-option__text">
          {label}
        </div>
      </div>
    );
  }

  function handleSourceDetailsFocus() {
    if (!availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER].options.length) {
      const payload = getSourceDataRequestPayload(
        conceptIds,
        filteredConceptsIds,
        publicationSource,
      );
      getSourceDetailsFilterOptions(payload);
    }
  }

  return (
    <FormSection
      name="PUBLICATION_FILTERS"
      className="create-set-filter__wrap"
    >
      {
        availableFilters[publicationsFiltersNames.REFERENCES_FILTER] &&
        <CreateSetFilterField
          customClass="create-set-filter__block_no-padding"
          fieldLabel="Reference sources"
          infoTooltip={{ id: 'referencesTooltip', text: 'Filter on references types' }}
          fieldProps={{
            name: 'publicationSource',
            options: searchSourcesOptions,
            component: FormSelect,
            onChange: () => {
              formValueChange('INTERMEDIATE_CONCEPTS_FILTER', { ...availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER], options: [] });
            },
          }}
        />
      }
      {
        availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER] &&
        <CreateSetFilterField
          customClass="create-set-filter__block_no-padding"
          fieldLabel="Source details filter"
          infoTooltip={{ id: 'sourceDetailsTooltip', text: 'Filter on source details' }}
          fieldProps={{
            name: 'sources',
            component: FormSelect,
            options: availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER].options,
            isLoading: availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER].isLoading,
            isMulti: true,
            isClearable: true,
            returnOption: true,
            hideSelectedOptions: true,
            disabled: publicationSource === SearchSourceFilterEnum.SENTENCE || publicationSource === SearchSourceFilterEnum.TITLE,
            onFocus: handleSourceDetailsFocus,
            formatOptionLabel: formatSourceDetailsOptionLabel,
          }}
        />
      }
      {
        availableFilters[publicationsFiltersNames.PUBLICATION_TYPE_FILTER] &&
        <CreateSetFilterField
          customClass="create-set-filter__block_no-padding"
          fieldLabel="Publication type"
          infoTooltip={{ id: 'publicationTypeTooltip', text: 'Filter on publication types' }}
          fieldProps={{
            name: 'publicationTypes',
            options: searchFilterSelectOptionsEnum.types,
            isMulti: true,
            hideSelectedOptions: true,
            component: FormSelect,
          }}
        />
      }
      {
        availableFilters[publicationsFiltersNames.RESEARCH_SOURCE_FILTER] &&
        <CreateSetFilterField
          customClass="create-set-filter__block_no-padding"
          fieldLabel="Experimental systems"
          infoTooltip={{ id: 'experimentalSystemsTooltip', text: 'Filter on experimental systems' }}
          fieldProps={{
            name: 'researchTypes',
            options: searchFilterSelectOptionsEnum.researchTypes,
            isMulti: true,
            hideSelectedOptions: true,
            component: FormSelect,
          }}
        />
      }
      {
        availableFilters[publicationsFiltersNames.YEAR_FILTER] &&
        <div className="create-set-filter__row">
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldLabel="References date from"
            infoTooltip={{ id: 'yearFromTooltip', text: 'Filter references on publication date' }}
            fieldProps={{
              name: 'dateFrom',
              type: 'number',
              placeholder: 'YYYY',
              component: FormInput,
            }}
          />
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldLabel="References date to"
            infoTooltip={{ id: 'yearToTooltip', text: 'Filter references on publication date' }}
            fieldProps={{
              name: 'dateTo',
              type: 'number',
              placeholder: 'YYYY',
              component: FormInput,
            }}
            labelStyle={{
              maxWidth: '120px',
            }}
          />
        </div>
      }
      {
        availableFilters[publicationsFiltersNames.PUBLICATION_COUNT_FILTER] &&
        <div className="create-set-filter__row">
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldLabel="Number of references from"
            infoTooltip={{ id: 'publicationsCountFrom', text: 'Filter references on number of publications' }}
            fieldProps={{
              name: 'publicationsCountFrom',
              type: 'number',
              placeholder: 'Number',
              component: FormInput,
            }}
          />
          <CreateSetFilterField
            customClass="create-set-filter__block_no-padding"
            fieldLabel="Number of references to"
            infoTooltip={{ id: 'publicationsCountTo', text: 'Filter references on number of publications' }}
            fieldProps={{
              name: 'publicationsCountTo',
              type: 'number',
              placeholder: 'Number',
              component: FormInput,
            }}
          />
        </div>
      }
    </FormSection>
  );
};

CreateSetPublicationsFilters.propTypes = propTypes;

export default CreateSetPublicationsFilters;
