import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import PublicationsList from '../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';

import {
  getAnimalPhenotypesDataSelector,
} from './redux/selectors';
import {
  phenotypesAnimalModelsChangePageAction,
} from './redux/actions';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  animalPhenotypes: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  changePage: PropTypes.func,
};

class AnimalModelsTable extends React.Component {
  state = {
    popup: false,
    showInfoText: false,
  };
  conceptsNames = [];
  conceptsIds = [];

  handlePageClick = (page) => {
    const { changePage } = this.props;

    changePage(page.selected);
  };

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data.phenotype;
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.conceptsNames.push(geneName);
    this.conceptsNames.push(name);
    this.conceptsIds.push(gene);
    this.conceptsIds.push(id);
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  diseaseCell = ({ id, name }, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      animalPhenotypes: {
        data = [],
        pageNumber,
        totalPages,
      },
      width,
    } = this.props;

    const {
      popup,
      showInfoText,
    } = this.state;

    const tableSettings = {
      height: 500,
      width,
      headerHeight: 50,
      rowHeight: 50,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
    };

    const columns = [
      {
        label: 'Phenotype',
        dataKey: 'phenotype',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(50),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.phenotype ? this.diseaseCell(rowData.phenotype, rowIndex) : null
        ),
      },
      {
        label: 'Animal model',
        dataKey: 'mouseModel',
        width: this.getColumnPercentWidth(50),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.mouseModel ? rowData.mouseModel : null}
          </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Animal models
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows phenotypes that are associated with orthologous, non-human gene knockouts and gene variants.
              For each of these phenotype associations all Pubmed articles that co-mention the gene are shown in the right hand column and can be accessed by clicking the link.
            </p>
          </div>
        }
        {
          data.length ?
            <div className="table-wrap">
              {
                totalPages > 1 &&
                <div className="controls-block">
                  <div className="paginationContainer">
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel={<a>...</a>}
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              }
              <SimpleTable
                data={data}
                autoSize={true}
                columns={columns}
                settings={tableSettings}
                dynamicHeight={true}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

AnimalModelsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    animalPhenotypes: getAnimalPhenotypesDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(phenotypesAnimalModelsChangePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnimalModelsTable);

