import { createSelector } from 'reselect';

const hideRelationTypesData = state => state.getIn(['hideRelationTypesModalReducer', 'data']);

export const getHideRelationTypesDataSelector = createSelector(
  hideRelationTypesData,
  data => data && data.toJS()
);

export const getHideRelationTypesSelectedIdsSelector = createSelector(
  getHideRelationTypesDataSelector,
  data => (data ? Object.keys(data).filter(id => data[id].checked === true) : [])
);
