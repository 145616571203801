import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Input.scss';

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  customClassName: PropTypes.string,
  customWrapClassName: PropTypes.string,
};

const Input = (props) => {
  const {
    type = 'text',
    name,
    value,
    onChange,
    onClick,
    disabled = false,
    placeholder,
    customClassName,
    customWrapClassName,
  } = props;

  const handleOnClick = useCallback((e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div className={classNames('input-wrap', customWrapClassName)}>
      <input
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onClick={handleOnClick}
        placeholder={placeholder}
        className={classNames('input', customClassName)}
        data-testid="input-testid"
      />
    </div>
  );
};

Input.propTypes = propTypes;

export default React.memo(Input);
