/* eslint-disable no-nested-ternary */
import { call, put, takeEvery, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import { getSelectedSource } from '../../../Analytics/common/SetAnalysisMethodSelection/selectors';
import { getBarChartGeneIdSelector } from './selectors';
// Actions
import * as a from './actions';

function* saga() {
  try {
    const requestData = {
      gene: yield select(getBarChartGeneIdSelector),
      tissues: yield select(getSelectedSource),
    };
    const { data } = yield call(Api.tissuesForChart, requestData);
    data.sort((d, b) => {
      const key = d.tissueName ? 'tissueName' : d.primaryDiagnosis ? 'primaryDiagnosis' : 'tissue';
      return d[key].localeCompare(b[key]);
    });
    yield put(a.setBarChartDataAction(data));
  } catch (e) {
    yield put(a.throwBarChartErrorAction(e.message));
  }
}

function* barChart() {
  yield takeEvery(a.getBarChartDataAction, saga);
}

export default barChart;
