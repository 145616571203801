import { createAction } from 'redux-actions';

// Set
export const getEditSetDataAction = createAction('@@editSet/GET');
export const setEditSetDataAction = createAction('@@editSet/SET');
export const throwEditSetDataAction = createAction('@@editSet/ERROR');
// Save
export const saveEditSetRequestedAction = createAction('@@editSet/SAVE_REQUESTED');
export const saveEditSetSucceededAction = createAction('@@editSet/SAVE_SUCCEEDED');
export const saveEditSetFailedAction = createAction('@@editSet/SAVE_FAILED');
// Manage
export const updateEditSetConceptsAction = createAction('@@editSet/UPDATE_CONCEPTS');
export const updateEditSetTagsAction = createAction('@@editSet/UPDATE_TAGS');
export const filterEditSetAction = createAction('@@editSet/FILTER');
export const sortEditSetAction = createAction('@@editSet/SORT');
export const resetEditSetAction = createAction('@@editSet/RESET');
export const pagginateEditSetAction = createAction('@@editSet/PAGGINATE');
