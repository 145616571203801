import { Map, fromJS } from 'immutable';

import {
  USER_RESTORE_PASSWORD_SUCCEEDED,
  USER_RESTORE_PASSWORD_FAILED,
  USER_RESTORE_PASSWORD_MODAL_OPEN,
  USER_RESTORE_PASSWORD_MODAL_CLOSE,
} from './constants';

const restorePassword = (state = new Map(), action) => {
  switch (action.type) {
    case USER_RESTORE_PASSWORD_SUCCEEDED:
      return state.merge(fromJS(action.data));

    case USER_RESTORE_PASSWORD_FAILED:
      return state.merge(fromJS(action.data));

    case USER_RESTORE_PASSWORD_MODAL_OPEN:
      return state.merge(fromJS({ showModal: action.data }));

    case USER_RESTORE_PASSWORD_MODAL_CLOSE:
      return state.merge(fromJS({ showModal: action.data }));

    default:
      return state;
  }
};

export default restorePassword;
