import {
  call,
} from 'redux-saga/effects';
import Api from '../../../../../Api/Api';
import { convertTableToCSVText, exportToFile } from '../../../../Utils/Utils';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';

export function* downloadSetWorker(action) {
  try {
    const {
      conceptQuantity,
      downloadLink,
      id,
      setType,
      projectId,
    } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    if (setType === 'COMPLEX') {
      const params = {
        size: conceptQuantity,
        page: 0,
      };

      let response = {};
      let configResponse = {};
      if (isProjectTab) {
        response = yield call(Api.getProjectComplexSetContent, { setId: id, params, projectId });
        configResponse = yield call(Api.getProjectComplexSetConfig, { setId: id, projectId });
      } else {
        response = yield call(Api.getComplexSetContent, { id, params });
        configResponse = yield call(Api.getComplexSetConfig, id);
      }

      const { columnsConfiguration, name } = configResponse.data;
      const { content } = response.data;
      const columns = columnsConfiguration.map(el => ({
        label: el.name,
        dataKey: el.name,
      }));
      const result = content.reduce((acc, item) => {
        const temp = item.reduce((tempAcc, tempItem) => ({
          ...tempAcc,
          [tempItem.columnName]: tempItem.value,
        }),
        {}
        );
        acc.push(temp);
        return acc;
      }, []);
      const csv = convertTableToCSVText(result, columns);
      exportToFile(downloadLink, csv, name);
    } else {
      const columns = [
        {
          label: 'Name',
          dataKey: 'name',
        },
        {
          label: 'Type',
          dataKey: 'types',
        },
      ];
      if (setType === 'EFFECT') {
        columns.splice(1, 0, {
          label: 'Measure',
          dataKey: 'measure',
        });
      }
      const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
      const requestData = isProjectTab ? { projectId, setId: id } : id;
      const { data } = yield call(apiToCall, requestData);

      const { items, name } = data;
      if (items && items.length) {
        const csv = convertTableToCSVText(items, columns);
        exportToFile(downloadLink, csv, name);
      }
    }
  } catch (e) {
    console.log(e);
  }
}
