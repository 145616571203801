import { fromJS } from 'immutable';

import { types } from './constants';

const initialState = fromJS({
  error: null,
  loading: true,
  patents: {},
  patentsSorting: {
    sortBy: 'documentDate',
    sortDirection: 'DESC',
  },
  patentInfo: {},
  patentInfoError: null,
  sequences: {},
  sequencesLoading: true,
  sequencesError: null,
  chemicals: {},
  chemicalsLoading: true,
  chemicalsError: null,
  patentInfoPopup: {
    isOpen: false,
    type: null,
  },
  patentInfoPopupLoading: false,
});

const patentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_PATENTS: {
      const { data } = action.data;

      return state.merge(fromJS({
        patents: data,
        error: null,
        loading: false,
      }));
    }

    case types.PATENTS_LOADING: {
      return state.update('loading', () => action.data);
    }

    case types.PATENTS_EXPORT_LOADING: {
      return state.update('exportLoading', () => action.data);
    }

    case types.PATENTS_SORT: {
      return state.merge(fromJS({ patentsSorting: action.data }));
    }

    case types.PATENTS_FAILED: {
      return state.merge(fromJS({ patents: {}, error: action.message, loading: false }));
    }

    case types.PUT_PATENT_INFO: {
      const { data } = action.data;

      return state.merge(fromJS({ patentInfo: data, patentInfoError: null, patentInfoPopupLoading: false }));
    }

    case types.PATENTS_PATENT_INFO_FAILED: {
      return state.merge(fromJS({ patentInfo: {}, patentInfoError: action.message, patentInfoPopupLoading: false }));
    }

    case types.PUT_PATENTS_SEQUENCES: {
      const { data } = action.data;

      return state.merge(fromJS({ sequences: data, sequencesError: null, sequencesLoading: false }));
    }

    case types.PATENTS_SEQUENCES_FAILED: {
      return state.merge(fromJS({ sequences: {}, sequencesError: action.message, sequencesLoading: false }));
    }

    case types.PUT_PATENTS_CHEMICALS: {
      const { data } = action.data;

      return state.merge(fromJS({ chemicals: data, chemicalsError: null, chemicalsLoading: false }));
    }

    case types.PATENTS_CHEMICALS_LOADING: {
      return state.update('chemicalsLoading', () => action.data);
    }

    case types.PATENTS_CHEMICALS_FAILED: {
      return state.merge(fromJS({ chemicals: {}, chemicalsError: action.message, chemicalsLoading: false }));
    }

    case types.TOGGLE_PATENT_POPUP: {
      return state.merge(fromJS({
        patentInfoPopup: {
          isOpen: !state.getIn(['patentInfoPopup', 'isOpen']),
          type: action.data || null,
        },
      }));
    }

    case types.TOGGLE_PATENT_POPUP_LOADER: {
      return state.merge(fromJS(
        {
          patentInfoPopupLoading: true,
          patentInfo: {},
          chemicals: {},
          sequences: {},
        }
      ));
    }

    default:
      return state;
  }
};

export default patentsReducer;
