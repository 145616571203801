import React from 'react';
import PropTypes from 'prop-types';
// Components
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
// Styles
import './styles.scss';

const propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  hide: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.instanceOf(Object),
};

const HeatmapHeaderSelect = (props) => {
  const {
    disabled,
    onSelect,
    hide,
    placeholder,
    options,
    defaultValue,
  } = props;

  const onChange = (option) => {
    onSelect(option.value);
  };

  return (
    <SelectInput
      defaultValue={defaultValue}
      placeholder={placeholder}
      options={options}
      hide={hide}
      grayMode={true}
      isDisabled={disabled}
      onSelect={onChange}
      classNamePrefix="dropdown"
      customClassName="heatmap-select"
    />
  );
};

HeatmapHeaderSelect.propTypes = propTypes;

export default HeatmapHeaderSelect;
