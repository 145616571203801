import { createSelector } from 'reselect';

const links = state => state.getIn(['geneDetailsExternalLinksChapterReducer', 'data']);
const loading = state => state.getIn(['geneDetailsExternalLinksChapterReducer', 'loading']);
const error = state => state.getIn(['geneDetailsExternalLinksChapterReducer', 'error']);

export const getGeneDetailsExternalLinksDataSelector = createSelector(
  links,
  data => data && data.toJS()
);

export const getGeneDetailsExternalLinksLoadingSelector = createSelector(
  loading,
  data => data
);

export const getGeneDetailsExternalLinksErrorSelector = createSelector(
  error,
  data => data
);
