import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const selectWorkflowsAlgorithmAction = createAction('@@workflows/SELECT_ALGORITHM');
export const resetWorkflowsAlgorithmAction = createAction('@@workflows/RESET_ALGORITHM');
export const getTargetCandidatesPairsDataAction = createAction('@@workflows/GET_DATA');
export const targetCandidatesPairsLoadingAction = createAction('@@workflows/LOADING');
export const targetCandidatesPairsErrorAction = createAction('@@workflows/ERROR');
export const exportTargetCandidatesPairsDataAction = createAction('@@workflows/EXPORT_DATA');
export const setTargetCandidatesPairsExportedDataAction = createAction('@@workflows/SET_EXPORTED_DATA');
export const resetTargetCandidatesPairsExportedDataAction = createAction('@@workflows/RESET_EXPORTED_DATA');
export const toggleTargetCandidatesPairsExportedDataLoaderAction = createAction('@@workflows/TOGGLE_EXPORTED_DATA_LOADER');
export const throwTargetCandidatesPairsExportedDataErrorAction = createAction('@@workflows/THROW_EXPORTED_DATA_ERROR');

const initialState = fromJS({
  selectedAlgorithm: null,
  exportedData: null,
  exportedDataLoading: false,
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [selectWorkflowsAlgorithmAction]: (state, { payload }) =>
      state.update('selectedAlgorithm', () => payload),
    [resetWorkflowsAlgorithmAction]: state =>
      state.update('selectedAlgorithm', () => initialState.get('selectedAlgorithm')),
    [targetCandidatesPairsLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [setTargetCandidatesPairsExportedDataAction]: (state, { payload }) =>
      state
        .update('exportedData', () => payload)
        .update('exportedDataLoading', () => false),
    [resetTargetCandidatesPairsExportedDataAction]: state =>
      state.update('exportedData', () => initialState.get('exportedData')),
    [toggleTargetCandidatesPairsExportedDataLoaderAction]: (state, { payload }) =>
      state.update('exportedDataLoading', () => payload),
    [targetCandidatesPairsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
  },
  initialState
);

export default reducer;
