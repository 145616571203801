import { call, select, put } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import {
  getCreateSetNameSelector,
  getCreateSetTagsSelector,
  getCreateSetConceptsSelector,
  getCreateSetDescriptionSelector,
  getFiltersDataSelector,
  getCreateSelectedProjectSelector,
} from '../../selectors';
import {
  throwSaveSetErrorAction,
  clearSaveSetErrorAction,
  setSavedSuccessfullyAction,
} from '../../actions';
import { showFreeAccountLimitDialogAction } from '../../../../../common/FreeAccountLimitationDialog/store/reducer';
// Utils
import { getSaveAsSetRequestPayload } from '../../../utils';

export function* saveCreatedSetWorker({ payload }) {
  try {
    yield put(clearSaveSetErrorAction());
    const { filter, filters } = yield select(getFiltersDataSelector);
    let customFilter = filter;
    if (!filter && payload.skipZeroPublications !== undefined && payload.skipZeroPublications !== null) {
      customFilter = {skipZeroPublications: payload.skipZeroPublications};
    }

    const projectId = yield select(getCreateSelectedProjectSelector);
    const setName = yield select(getCreateSetNameSelector);

    const requestData = getSaveAsSetRequestPayload({
      name: setName,
      description: yield select(getCreateSetDescriptionSelector),
      tags: yield select(getCreateSetTagsSelector),
      items: yield select(getCreateSetConceptsSelector),
      projectId,
      filter: customFilter,
      filters,
      leftConcepts: payload.leftConcepts
    });

    let savedSetData = null;
    if (projectId) {
      savedSetData = yield call(Api.addProjectSet, requestData);
    } else {
      savedSetData = yield call(Api.editSet, requestData);
    }

    yield put(setSavedSuccessfullyAction({setId: savedSetData.data, projectId, setName}));
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put(showFreeAccountLimitDialogAction());
    } else if (e.response) {
      yield put(throwSaveSetErrorAction(e.response.data.message));
    }
  }
}
