import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  putPatentsAction,
  patentsFailedAction,
  patentsLoadingAction,
  setPatentsExportLoadingAction,
} from '../../actions';
import {
  getPatentsSorting,
} from '../../selectors';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';
import { exportToCSV } from '../../../../../Utils/Utils';

export function* getPatentsWorker(action) {
  try {
    const { exportData } = action;
    if (exportData) {
      yield put(setPatentsExportLoadingAction(true));
    } else {
      yield put(patentsLoadingAction(true));
    }
    const requestData = action.data;
    const { sortBy, sortDirection } = yield select(getPatentsSorting);

    if (!requestData.params) {
      requestData.params = {
        size: 20,
        page: 0,
        sort: 'totalCount,DESC',
      };
    }

    requestData.params = {
      ...requestData.params,
      sort: `${sortBy},${sortDirection}`,
    };

    const response = yield call(Api.getPatents, requestData);
    if (exportData) {
      exportToCSV(exportData.link, exportData.columns, response.data.content, `${exportData.geneName}-patents`);
      yield put(setPatentsExportLoadingAction(false));
    } else {
      yield put(putPatentsAction(response));
    }

    const {
      content,
    } = response.data;
    if (content && content.length) {
      yield put(geneDetailsSetChapterAction('patents'));
    }
    yield put(loadNextChapterAction('clinical-trials'));
    yield put(patentsLoadingAction(false));
  } catch (e) {
    yield put(patentsFailedAction(e.message));
    yield put(loadNextChapterAction('clinical-trials'));
  }
}
