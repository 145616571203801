import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getParentBlockWidth } from '../../Utils/Utils';
// Components
import Loader from '../../common/Loader/Loader';
import ChordDiagram from './ChordDiagram';
import AnimalModelsTable from './AnimalModelsTable';
import HumanPhenotypesTable from './HumanPhenotypesTable';
import AdverseDrugsTable from './AdverseDrugsTable';
// Store
import { loadNextChapterAction } from '../actions';
import { getPhenotypesDataAction } from './redux/actions';
import * as SELECTORS from './redux/selectors';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  getPhenotypesData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  animalPhenotypes: PropTypes.instanceOf(Object),
  humanPhenotypes: PropTypes.instanceOf(Object),
  adverseDrugs: PropTypes.instanceOf(Object),
};

class PhenotypesChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: false,
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      getPhenotypesData,
      itsNewConcept,
      loadNextChapter,
      animalPhenotypes: {
        data: animalPhenotypesData,
      },
      humanPhenotypes: {
        data: humanPhenotypesData,
      },
      adverseDrugs: {
        data: adverseDrugsData,
      },
    } = this.props;

    if (itsNewConcept || !(animalPhenotypesData.length || humanPhenotypesData.length || adverseDrugsData.length)) {
      getPhenotypesData({ id: gene });
    } else {
      loadNextChapter('disorders');
    }
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      error,
      loading,
    } = this.props;

    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div className="phenotypes">
        <div className="gene-details-section-title title2">
          <span>Phenotypes</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              The Phenotypes section provides the following information on phenotypes associated with the gene:
            </p>
            <ul>
              <li>Direct phenotype annotations for the gene</li>
              <li>Phenotypes that are associated with animal gene knockouts or variants of the gene</li>
              <li>Potential side effects that are associated with drugs targeting the gene</li>
              <li>The gene interaction network that is involved in the top 25 phenotypes</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div>
            <ChordDiagram gene={gene} geneName={geneName} />
            <AnimalModelsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <HumanPhenotypesTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <AdverseDrugsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

PhenotypesChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    animalPhenotypes: SELECTORS.getAnimalPhenotypesDataSelector(state),
    humanPhenotypes: SELECTORS.getHumanPhenotypesDataSelector(state),
    adverseDrugs: SELECTORS.getAdverseDrugsDataSelector(state),
    loading: SELECTORS.getLoadingSelector(state),
    error: SELECTORS.getErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPhenotypesData(data) {
      dispatch(getPhenotypesDataAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhenotypesChapter);
