import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import PropTypes from 'prop-types';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import ModalComponent from '../../ModalComponent/ModalComponent';
import RelationMapChart from '../RelationMapPage/Components/RelationMapChart/RelationMapChart';
import RelationMapSidebar from '../RelationMapPage/Components/RelationMapSidebar/RelationMapSidebar';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
// Constants
import { DefaultProjectInfoData, ConfirmDeleteInitialValues } from '../RelationMapPage/constants';
// Store
import * as ACTIONS from '../RelationMapPage/store/actions';
import * as SELECTORS from '../RelationMapPage/store/selectors';
import { resetHideSourceDataAction } from '../RelationMapPage/Components/HideSourceModal/store/reducer';
import { resetHideRelationTypesDataAction } from '../RelationMapPage/Components/HideRelationTypesModal/store/reducer';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  concepts: PropTypes.instanceOf(Array),
  error: PropTypes.string,
  isSaved: PropTypes.bool,
  loading: PropTypes.bool,
  toggleIsSaved: PropTypes.func,
  addNewConcepts: PropTypes.func,
  setProjectInfo: PropTypes.func,
  resetRelationMap: PropTypes.func,
  resetHideSourceModal: PropTypes.func,
  resetHideRelationTypesModal: PropTypes.func,
};

const RelationMapModal = (props) => {
  const noCache = true;
  const {
    data,
    isOpen,
    closeCb,
    error,
    loading,
    concepts,
    isSaved,
    toggleIsSaved,
    addNewConcepts,
    setProjectInfo,
    resetRelationMap,
    resetHideSourceModal,
    resetHideRelationTypesModal,
  } = props;

  const [showConfirmDialog, setShowConfirmDialog] = useState(ConfirmDeleteInitialValues);

  useEffect(() => {
    addNewConcepts({ concepts, noCache });
    setProjectInfo(DefaultProjectInfoData);
    return () => {
      resetRelationMap();
      resetHideSourceModal();
      resetHideRelationTypesModal();
    };
  }, []);

  function closeModals() {
    toggleIsSaved();
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="relation-map-modal">
        <div className="relation-map-modal__content">
          <Measure offset={true}>
            {({ measureRef, contentRect }) => (
              <div className="relation-map-page__chart" ref={measureRef}>
                {
                  !!contentRect.offset.width && !!contentRect.offset.height && !!data &&
                  <RelationMapChart
                    data={data}
                    width={contentRect.offset.width}
                    height={contentRect.offset.height}
                    loading={loading}
                    noCache={noCache}
                    withSave={true}
                    isModal={true}
                    setShowConfirmDialog={setShowConfirmDialog}
                  />
                }
                <Error
                  show={!loading && error}
                  error={error}
                  customClassName="relation-map-page__error"
                />
              </div>
            )}
          </Measure>
          <RelationMapSidebar
            data={data}
            isModal={true}
            setShowConfirmDialog={setShowConfirmDialog}
          />
        </div>
        <Loader
          isLoading={loading}
          withOverlay={true}
        />
        <ConfirmationDialog
          isOpen={showConfirmDialog.show}
          closeCb={() => setShowConfirmDialog(ConfirmDeleteInitialValues)}
          {...showConfirmDialog.config}
        />
        <InfoDialog
          isOpen={isSaved}
          closeCb={closeModals}
          text="Relation map is saved"
        />
        <FreeAccountLimitationDialog entity="relation map" />
      </div>
    </ModalComponent>
  );
};

RelationMapModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getRelationMapDataSelector(state),
    isSaved: SELECTORS.getRelationMapIsSavedSelector(state),
    loading: SELECTORS.getRelationMapLoadingSelector(state),
    error: SELECTORS.getRelationMapErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetRelationMap(data) {
      dispatch(ACTIONS.resetRelationMapAction(data));
    },
    setProjectInfo(data) {
      dispatch(ACTIONS.setRelationMapProjectInfoAction(data));
    },
    addNewConcepts(data) {
      dispatch(ACTIONS.addNewConceptsToRelationMapAction(data));
    },
    toggleIsSaved() {
      dispatch(ACTIONS.toggleRelationMapIsSavedAction());
    },
    resetHideSourceModal() {
      dispatch(resetHideSourceDataAction());
    },
    resetHideRelationTypesModal() {
      dispatch(resetHideRelationTypesDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMapModal);
