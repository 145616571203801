import { fromJS } from 'immutable';

import { types } from './constants';

const initialState = fromJS({
  chart: [],
  chartLoading: false,
  chartError: null,
  table: {
    content: [],
  },
  sorting: {
    sortBy: 'lastUpdateDate',
    sortDirection: 'DESC',
  },
  filter: [],
  error: null,
  loading: false,
});

const geneDiseasePhenotype = (state = initialState, action) => {
  switch (action.type) {
    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_GET_CHART_DATA: {
      const { data } = action.data;
      return state.update('chart', () => fromJS(data));
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_LOADER: {
      const { data } = action;
      return state.update('chartLoading', () => data);
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_FAILED: {
      const { message } = action;
      return state.update('chartError', () => message);
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_GET_TABLE_DATA: {
      const { data } = action;
      return state
        .update('table', () => fromJS(data))
        .update('error', () => null);
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_FILTER_TABLE: {
      return state.set('filter', action.data);
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_SORT_TABLE: {
      const { data } = action;
      return state.update('sorting', () => fromJS(data));
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_LOADER: {
      const { data } = action;
      return state.update('loading', () => data);
    }

    case types.GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_FAILED: {
      const { message } = action;
      return state.update('error', () => message);
    }

    default:
      return state;
  }
};

export default geneDiseasePhenotype;

