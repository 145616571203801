import { handleActions, createAction } from 'redux-actions';
import { fromJS } from 'immutable';

export const showTooltipAction = createAction('SHOW_TOOLTIP');
export const hideTooltipAction = createAction('HIDE_TOOLTIP');

const initialState = fromJS({
  uniqueKey: null,
  clientX: null,
  clientY: null,
});

const reducer = handleActions({
  [showTooltipAction]: (state, { payload }) =>
    state.merge(fromJS(payload)),
  [hideTooltipAction]: state =>
    state.merge(initialState),

}, initialState);

export default reducer;
