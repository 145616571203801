import { fromJS } from 'immutable';
import {
  createAction,
  handleActions,
} from 'redux-actions';

const PREFIX = 'TAXONOMIES';

export const fetchTaxonomiesRequestAction = createAction(`${PREFIX}/FETCH_REQUEST`);
export const fetchTaxonomiesSuccessAction = createAction(`${PREFIX}/FETCH_SUCCESS`);
export const fetchTaxonomiesFailureAction = createAction(`${PREFIX}/FETCH_FAILURE`);
export const resetTaxonomiesAction = createAction(`${PREFIX}/RESET`);

const initialState = fromJS({
  list: [],
  error: '',
  loading: false,
});

const reducer = handleActions({
  [fetchTaxonomiesRequestAction]: state =>
    state.set('loading', true),
  [fetchTaxonomiesSuccessAction]: (state, { payload }) =>
    state
      .set('loading', false)
      .set('list', fromJS(payload)),
  [fetchTaxonomiesFailureAction]: state =>
    state.set('loading', false),
  [resetTaxonomiesAction]: () =>
    initialState,
}, initialState);

export default reducer;
