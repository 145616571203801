import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const userForgotPasswordTokenCheckAction = createAction('@@forgotPassword/CHECK_TOKEN');
export const userForgotPasswordTokenFailureAction = createAction('@@forgotPassword/FAILURE');
export const submitNewPasswordAction = createAction('@@forgotPassword/SUBMIT_NEW_PASSWORD');
export const setNewPasswordModalOpenAction = createAction('@@forgotPassword/MODAL_OPEN');
export const setNewPasswordModalCloseAction = createAction('@@forgotPassword/MODAL_CLOSE');
export const setNewPasswordTokenFailureAction = createAction('@@forgotPassword/TOKEN_FAILURE');

const initialState = fromJS({
  showModal: false,
  error: '',
  tokenError: '',
});

const reducer = handleActions(
  {
    [setNewPasswordModalOpenAction]: state =>
      state
        .update('showModal', () => true),
    [setNewPasswordModalCloseAction]: state =>
      state
        .update('showModal', () => false),
    [userForgotPasswordTokenFailureAction]: (state, payload) =>
      state
        .update('error', () => payload),
    [setNewPasswordTokenFailureAction]: (state, { payload }) =>
      state
        .update('tokenError', () => payload),
  },
  initialState
);

export default reducer;
