import { createSelector } from 'reselect';

const literatureSearchTerms = state => state.getIn(['setAnalysisMethodSelection', 'literatureSearchTerms']);
const literatureSearchSynonyms = state => state.getIn(['setAnalysisMethodSelection', 'literatureSearchSynonyms']);

export const getLiteratureSearchTerms = createSelector(
  literatureSearchTerms,
  data => data.toJS()
);

export const getLiteratureSearchSynonymsKey = createSelector(
  literatureSearchSynonyms,
  data => data
);
