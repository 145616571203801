export const conclusionChartData = [
  {
    id: 'expressionScore',
    order: 1,
    weight: 3,
    color: '#FEFE6C',
    label: 'Tissue Expression: Expression value in target tissue as % of maximum expression value of all tissues',
  },
  {
    id: 'annotationScore',
    order: 2,
    weight: 1,
    color: '#920000',
    label: 'Disease annotation',
  },
  {
    id: 'diffExpressionScore',
    order: 3,
    weight: 3,
    color: '#23FD22',
    label: 'Differential Expression',
  },
  {
    id: 'geneticMutationScore',
    order: 4,
    weight: 5,
    color: '#DA6C00',
    label: 'Genetic mutation',
  },
  {
    id: 'animalModelScoreScore',
    order: 5,
    weight: 4,
    color: '#480091',
    label: 'Animal models',
  },
  /*
  {
    id: 'metabolitesScore',
      order: 6,
    weight: 2,
    color: '#AAAAAA',
    label: 'Metabolites',
  },
*/
  {
    id: 'phenotypeScore',
    order: 7,
    weight: 1,
    color: '#A52E2E',
    label: 'Phenotypes',
  },
  {
    id: 'pathwaysScore',
    order: 8,
    weight: 1,
    color: '#73C3C3',
    label: 'Pathways',
  },
  {
    id: 'cellDysfunctionScore',
    order: 9,
    weight: 1,
    color: '#006DDB',
    label: 'Cellular dysfunction',
  },
  {
    id: 'organOrTissueScore',
    order: 10,
    weight: 1,
    color: '#009191',
    label: 'Organ function',
  },
];
