import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Icons
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
// Components
import ButtonCircle from '../Buttons/ButtonCircle/ButtonCircle';
// Styles
import './styles.scss';

const propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const Slider = (props) => {
  const { slides } = props;
  const sliderRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderIsActive, setSliderIsActive] = useState(false);

  useLayoutEffect(() => {
    setSliderIsActive(sliderRef.current && sliderRef.current.clientWidth < sliderRef.current.scrollWidth);
  }, [slides]);

  function handleClickLeft() {
    const node = sliderRef.current;
    if (!node || activeIndex === 0) return;

    const width = node.childNodes[activeIndex - 1].clientWidth;
    const transform = Number(node.style.transform.replace(/[^\d.]/g, ''));

    sliderRef.current.style.transform = `translateX(-${transform - width}px)`;
    setActiveIndex(activeIndex - 1);
  }

  function handleClickRight() {
    const node = sliderRef.current;
    if (!node || activeIndex === slides.length - 1) return;

    const width = node.childNodes[activeIndex].clientWidth;
    const transform = Number(node.style.transform.replace(/[^\d.]/g, ''));

    sliderRef.current.style.transform = `translateX(-${transform + width}px)`;
    setActiveIndex(activeIndex + 1);
  }

  const renderSlide = (slide, i) => (
    <div key={`slide-${i}`} className="slider__slide">
      {slide}
    </div>
  );

  const renderSlider = () => (
    <div className="slider__wrap">
      <div
        className="slider__track"
        ref={sliderRef}
      >
        {slides.map(renderSlide)}
      </div>
    </div>
  );

  if (sliderIsActive) {
    return (
      <div className="slider slider_is-active">
        <ButtonCircle
          icon={<AiOutlineLeft size={15} color="#4b3f63" />}
          onClick={handleClickLeft}
          disabled={activeIndex === 0}
          size={38}
          customClassName="slider__btn"
        />
        {renderSlider()}
        <ButtonCircle
          icon={<AiOutlineRight size={15} color="#4b3f63" />}
          onClick={handleClickRight}
          disabled={activeIndex === slides.length - 1}
          size={38}
          customClassName="slider__btn"
        />
      </div>
    );
  }

  return (
    <div className="slider">
      {renderSlider()}
    </div>
  );
};

Slider.propTypes = propTypes;

export default Slider;
