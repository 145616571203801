import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Ideogram from 'ideogram';

const propTypes = {
  genomicLocation: PropTypes.instanceOf(Object),
};

const GenomicIdeogram = ({ genomicLocation }) => {
  // eslint-disable-next-line no-unused-vars
  let ideogram;
  const ideogramId = 'ideogram';
  const {
    name,
    stop,
    start,
    assembly,
    organism,
    chromosome,
  } = genomicLocation;

  useEffect(() => {
    if (organism !== 'unknown' && chromosome) {
      ideogram = new Ideogram({
        organism,
        assembly,
        chromosome,
        chrHeight: 600,
        orientation: 'horizontal',
        container: `#${ideogramId}`,
        showNonNuclearChromosomes: true,
        annotations: [{
          name,
          chr: chromosome,
          start,
          stop,
          assembly,
        }],
      });
    }
  }, []);

  if (organism !== 'unknown' && chromosome) {
    return <div id={ideogramId} className="ideogram-container" />;
  }
  return 'There is no data to display';
};

GenomicIdeogram.propTypes = propTypes;

export default GenomicIdeogram;
