import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';
// Utils
import {sortExpressionData, transformExpressionLinesData, sortHpaData, sortGroupedExpressionData } from './utils';
import { generateChartData } from '../../graphics/GroupedViolinChart/utils';
import { cptacTissuesNames } from './constants';

const hpmTissues = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CELL_TYPE']);
// ccle
const сcleExpressionData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CCLE', 'data']);
const ccleExpressionSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CCLE', 'selectedSortingOption']);
// ccle proteomics
const ccleProteomicsAllExpressionsData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CCLE_PROTEOMICS', 'data']);
const ccleProteomicsSelectedUniprot = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CCLE_PROTEOMICS', 'selectedUniprot']);
const ccleProteomicsExpressionSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CCLE_PROTEOMICS', 'selectedSortingOption']);
// sanger cell model
const sangerCellModelAllExpressionsData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'SANGER_CELL_MODEL', 'data']);
const sangerCellModelSelectedUniprot = state => state.getIn(['geneDetailsGeneExpressionChapter', 'SANGER_CELL_MODEL', 'selectedUniprot']);
const sangerCellModelExpressionSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'SANGER_CELL_MODEL', 'selectedSortingOption']);
const sangerCellModelExpressionSelectedProteinOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'SANGER_CELL_MODEL', 'selectedProteinExpressionOption']);
// gtex
const gtexExpressionData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'GTEX', 'data']);
const gtexExpressionSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'GTEX', 'selectedSortingOption']);
// tcga
const tcgaExpressionData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'TCGA', 'data']);
const tcgaExpressionSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'TCGA', 'selectedSortingOption']);
const healthCellDataInit = state => state.getIn(['geneDetailsGeneExpressionChapter', 'healthCellData']);
const loading = state => state.getIn(['geneDetailsGeneExpressionChapter', 'loading']);
const error = state => state.getIn(['geneDetailsGeneExpressionChapter', 'error']);

// HPA
const hpaLoading = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA', 'loading']);
const hpaError = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA', 'error']);
const hpaVisibleChart = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA', 'visibleChart']);
const hpaCellTypes = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_CELL_TYPES', 'cellTypesData']);
const hpaSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_CELL_TYPES', 'selectedSortingOption']);
const hpaSelectedFilteringOptions = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_CELL_TYPES', 'selectedFilteringOptions']);
const hpaCellTypeGroups = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_CELL_TYPES', 'cellTypeGroups']);
const hpaCellTypeGroupsWithColors = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_CELL_TYPES', 'cellTypeGroupsWithColors']);

const hpaTissues = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'tissuesData']);
const hpaPerTissuesSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'selectedSortingOption']);
const hpaPerTissuesCellTypeGroups = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'cellTypeGroups']);
const hpaPerTissuesSelectedFilteringOptions = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'selectedFilteringPerCellTypeGroupsOptions']);
const hpaPerTissuesCellTypes = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'cellTypes']);
const hpaPerTissuesSelectedFilteringPerCellTypeOptions = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'selectedFilteringPerCellTypeOptions']);
const cellTypeGroupsWithColorsForHpaPerTissues = state => state.getIn(['geneDetailsGeneExpressionChapter', 'HPA_PER_TISSUES', 'cellTypeGroupsWithColors']);
// CPTAC
const cptacData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CPTAC', 'data']);
const tumorTissueForCptacData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CPTAC', 'tumorTissueData']);
const healtyTissueForCptacData = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CPTAC', 'healtyTissueData']);
const cptacLoading = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CPTAC', 'loading']);
const cptacSelectedSortingOption = state => state.getIn(['geneDetailsGeneExpressionChapter', 'CPTAC', 'selectedSortingOption']);
const csvDownloadedSamplesLoading = state => state.getIn(['geneDetailsGeneExpressionChapter', 'csvDownloadedSamplesLoading']);
export const getHpmTissues = createSelector(
  hpmTissues,
  data => data && data.toJS()
);

export const getCcleExpressionDataSelector = createSelector(
  сcleExpressionData,
  ccleExpressionSelectedSortingOption,
  (_data, _sortingOption) => {
    const data = _data && _data.toJS();
    const sortingOption = _sortingOption && _sortingOption.toJS();

    if (!data) return [];

    return sortExpressionData(data, sortingOption);
  }
);

export const getCcleExpressionSelectedSortingOptionSelector = createSelector(
  ccleExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getCcleProteomicsAllExpressionsDataSelector = createSelector(
  ccleProteomicsAllExpressionsData,
  data => data && data.toJS()
);

export const getCcleProteomicExpressionsDataSelector = createSelector(
  ccleProteomicsSelectedUniprot,
  ccleProteomicsAllExpressionsData,
  ccleProteomicsExpressionSelectedSortingOption,
  (selectedUniprot, _data, _sortingOption) => {
    const sortingOption = _sortingOption && _sortingOption.toJS();
    const data = _data && _data.toJS();

    const selectedData = data.find(i => i.uniprot === selectedUniprot);

    if (!selectedData) return [];
    return sortExpressionData(selectedData.expressions, sortingOption);
  }
);

export const getCcleProteomicsSelectedUniprotSelector = createSelector(
  ccleProteomicsSelectedUniprot,
  data => data
);

export const getCcleProteomicsAllUniprotOptions = createSelector(
  ccleProteomicsAllExpressionsData,
  _data => {
    const data = _data && _data.toJS();
    return data.map(i => ({
      value: i.uniprot,
      label: i.uniprot,
      type: i.uniprot,
    }));
  }
);

export const getCcleProteomicsExpressionSelectedSortingOptionSelector = createSelector(
  ccleProteomicsExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getSangerCellModelAllExpressionsDataSelector = createSelector(
  sangerCellModelAllExpressionsData,
  data => data && data.toJS()
);

export const getSangerCellModelExpressionsDataSelector = createSelector(
  sangerCellModelSelectedUniprot,
  sangerCellModelAllExpressionsData,
  sangerCellModelExpressionSelectedSortingOption,
  sangerCellModelExpressionSelectedProteinOption,
  (selectedUniprot, _data, _sortingOption, _proteinOption) => {
    const sortingOption = _sortingOption && _sortingOption.toJS();
    const proteinOption = _proteinOption && _proteinOption.toJS();
    const data = _data && _data.toJS();

    const selectedData = data.find(i => i.uniprot === selectedUniprot);

    if (!selectedData) return [];
    return sortExpressionData(transformExpressionLinesData(selectedData.expressions, 'cellLines', proteinOption.value), sortingOption);
  }
);

export const getSangerCellModelSelectedUniprotSelector = createSelector(
  sangerCellModelSelectedUniprot,
  data => data
);

export const getSangerCellModelAllUniprotOptions = createSelector(
  sangerCellModelAllExpressionsData,
  _data => {
    const data = _data && _data.toJS();
    return data.map(i => ({
      value: i.uniprot,
      label: i.uniprot,
      type: i.uniprot,
    }));
  }
);

export const sangerCellModelExpressionSelectedSortingOptionSelector = createSelector(
  sangerCellModelExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const sangerCellModelExpressionSelectedProteinOptionSelector = createSelector(
  sangerCellModelExpressionSelectedProteinOption,
  data => data && data.toJS()
);

export const getGtexExpressionDataSelector = createSelector(
  gtexExpressionData,
  gtexExpressionSelectedSortingOption,
  (_data, _sortingOption) => {
    const data = _data && _data.toJS();
    const sortingOption = _sortingOption && _sortingOption.toJS();

    if (!data) return [];

    return sortExpressionData(data, sortingOption);
  }
);

export const getGtexExpressionSelectedSortingOptionSelector = createSelector(
  gtexExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getTcgaSelectedSortingOption = createSelector(
  tcgaExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getTcgaExpressionDataSelector = createSelector(
  tcgaExpressionData,
  tcgaExpressionSelectedSortingOption,
  (_data, _sortingOption) => {
    const data = _data && _data.toJS();
    const sortingOption = _sortingOption && _sortingOption.toJS();

    if (!data) return [];

    return sortExpressionData(data, sortingOption);
  }
);

export const getHealthCellDataInit = createSelector(
  healthCellDataInit,
  data => data && data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getCsvDownloadedSamplesLoading = createSelector(
  csvDownloadedSamplesLoading,
  data => data && data.toJS()
);

export const getFormValues = formValueSelector('CELL_TYPES_SELECT');

// HPA

export const getHpaLoadingSelector = createSelector(
  hpaLoading,
  data => data
);

export const getHpaErrorSelector = createSelector(
  hpaError,
  data => data
);

export const getAllHpaCellTypesSelector = createSelector(
  hpaCellTypes,
  data => data && data.toJS()
);

export const getHPACellTypes = createSelector(
  hpaCellTypes,
  hpaSelectedSortingOption,
  hpaSelectedFilteringOptions,
  (_data, _sortingOption, _filteringOptions) => {
    let data = _data && _data.toJS();
    const sortingOption = _sortingOption && _sortingOption.toJS();
    const filteringOptions = _filteringOptions && _filteringOptions.toJS().map(i => i.value);
    if (filteringOptions.length) {
      data = data.filter(i => filteringOptions.includes(i.cellTypeGroup));
    }
    return sortHpaData(data, sortingOption, 'cellType');
  }
);

export const getHpaVisibleChartOptionSelector = createSelector(
  hpaVisibleChart,
  data => data
);

export const getHpaSelectedSortingOptionSelector = createSelector(
  hpaSelectedSortingOption,
  data => data && data.toJS()
);

export const getHpaSelectedFilteringOptionSelector = createSelector(
  hpaSelectedFilteringOptions,
  data => data && data.toJS()
);

export const getLegendDataForHpaPerCellTypesSelector = createSelector(
  hpaCellTypeGroupsWithColors,
  _data => {
    const data = _data && _data.toJS();
    return Object.values(data).sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const getAllHPATissuesSelector = createSelector(
  hpaTissues,
  data => data && data.toJS()
);

export const getHPATissues = createSelector(
  hpaTissues,
  hpaPerTissuesSelectedSortingOption,
  hpaPerTissuesSelectedFilteringOptions,
  hpaPerTissuesSelectedFilteringPerCellTypeOptions,
  (_data, _sortingOption, _filteringOptions, _filteringPerCellTypeOptions) => {
    let data = _data && _data.toJS();
    const sortingOption = _sortingOption && _sortingOption.toJS();
    const filteringOptions = _filteringOptions && _filteringOptions.toJS().map(i => i.value);
    const filteringPerCellTypeOptions = _filteringPerCellTypeOptions && _filteringPerCellTypeOptions.toJS().map(i => i.value);
    if (filteringOptions.length) {
      data = data.filter(i => filteringOptions.includes(i.cellTypeGroup));
    }
    if (filteringPerCellTypeOptions.length) {
      data = data.filter(i => filteringPerCellTypeOptions.includes(i.cellType));
    }
    return sortHpaData(data, sortingOption, 'tissueName');
  }
);

export const getHpaPerTissuesSelectedSortingOptionSelector = createSelector(
  hpaPerTissuesSelectedSortingOption,
  data => data && data.toJS()
);

export const getHpaPerTissuesSelectedOptionsSelector = createSelector(
  hpaPerTissuesSelectedFilteringOptions,
  data => data && data.toJS()
);

export const getHpaPerTissuesSelectedFilteringPerCellTypeOptionsSelector = createSelector(
  hpaPerTissuesSelectedFilteringPerCellTypeOptions,
  data => data && data.toJS()
);

export const getLegendDataForHpaPerTissuesSelector = createSelector(
  cellTypeGroupsWithColorsForHpaPerTissues,
  _data => {
    const data = _data && _data.toJS();
    return Object.values(data).sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const getCellTypeGroupsOptionsForHpaPerCellTypesSelector = createSelector(
  hpaCellTypeGroups,
  _data => {
    const data = _data && _data.toJS();
    return data.map(i => ({
      name: i,
      value: i,
      label: i,
    }));
  }
);

export const getCellTypeGroupsOptionsForHpaPerTissuesSelector = createSelector(
  hpaPerTissuesCellTypeGroups,
  _data => {
    const data = _data && _data.toJS();
    return data.map(i => ({
      name: i,
      value: i,
      label: i,
    }));
  }
);

export const getCellTypesOptionsForHpaPerTissuesSelector = createSelector(
  hpaPerTissuesCellTypes,
  _data => {
    const data = _data && _data.toJS();
    return data.map(i => ({
      name: i,
      value: i,
      label: i,
    }));
  }
);
export const getCptacLoadingSelector = createSelector(
  cptacLoading,
  data => data
);

export const getCptacDataSelector = createSelector(
  tumorTissueForCptacData,
  healtyTissueForCptacData,
  ( _tumorTissueData,
    _healtyTissueData,
  ) => {
    const tumorTissueData = _tumorTissueData && _tumorTissueData.toJS();
    const healtyTissueForCptacData = _healtyTissueData && _healtyTissueData.toJS();
    if (!tumorTissueData || !healtyTissueForCptacData) return [];
    return generateChartData(cptacTissuesNames.HEALTHY, healtyTissueForCptacData, cptacTissuesNames.TUMOR, tumorTissueData);
  }
);

export const getAllCptacDataSelector = createSelector(
  cptacData,
  _data => _data && _data.toJS()
);

export const getCptacExpressionSelectedSortingOptionSelector = createSelector(
  cptacSelectedSortingOption,
  data => data && data.toJS()
);

export const getSortedCptacDataSelector = createSelector(
  [getCptacDataSelector, getCptacExpressionSelectedSortingOptionSelector],
  (
    data, selectedSortingOption
  ) => {
    return sortGroupedExpressionData(data, selectedSortingOption);
  }
);

export const getGeneExpressionDataExistSelector = createSelector(
  [
    getGtexExpressionDataSelector,
    getTcgaExpressionDataSelector,
    getCcleExpressionDataSelector,
    getHealthCellDataInit,
    getHpmTissues,
    getCcleProteomicsAllExpressionsDataSelector,
    getSangerCellModelAllExpressionsDataSelector,
    getCptacDataSelector,
    getAllHpaCellTypesSelector,
    getAllHPATissuesSelector,
  ],
  (
    GTEX, TCGA, CCLE, HEALTH_CELL,
    HPM, CCLE_PROTEOMICS, SANGER_CELL_MODEL,
    CPTAC,
    HPA_PER_CELL_TYPE, HPA_PER_TISSUES,
  ) => {
    const checkDataAreExist = data => !!data.length;
    return ({
      BULK_RNA: {
        GTEX: checkDataAreExist(GTEX),
        TCGA: checkDataAreExist(TCGA),
        CCLE: checkDataAreExist(CCLE),
        HEALTH_CELL: checkDataAreExist(HEALTH_CELL.healthCellExpressionChartData),
      },
      PROTEIN_EXPRESSION: {
        HPM: checkDataAreExist(HPM),
        CCLE_PROTEOMICS: checkDataAreExist(CCLE_PROTEOMICS),
        SANGER_CELL_MODEL: checkDataAreExist(SANGER_CELL_MODEL),
        CPTAC: checkDataAreExist(CPTAC),
      },
      SINGLE_CELL_RNA: {
        HPA: checkDataAreExist(HPA_PER_CELL_TYPE) && checkDataAreExist(HPA_PER_TISSUES),
      }
    });
  }
);
