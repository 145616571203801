import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as d3js from 'd3';
import classNames from 'classnames';
// Components
import RangeInput from '../../common/Inputs/RangeInput/RangeInput';
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import NoData from '../../common/NoData/NoData';
import ButtonCircle from '../../common/Buttons/ButtonCircle/ButtonCircle';
// Icons
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
// Store
import * as SELECTORS from './store/selectors';
import * as ACTIONS from './store/reducer';
// Utils
import { getDataForGraphRequest } from './utils';
// Constants
import { GRAPH_WIDTH } from './constants';
// Styles
import './styles.scss';

const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  showTitle: PropTypes.bool,
  publicationSource: PropTypes.string,
  geneId: PropTypes.string,
  graphId: PropTypes.string,
  getGraphData: PropTypes.func,
  changeYearsValues: PropTypes.func,
  clearGraphData: PropTypes.func,
  graphData: PropTypes.instanceOf(Object),
  dateFrom: PropTypes.number,
  dateTo: PropTypes.number,
  graphWidth: PropTypes.number,
  extendedGraphWidth: PropTypes.number,
  publicationFilterExtended: PropTypes.bool,
  abilityToExtend: PropTypes.bool,
  setPublicationFilterExtended: PropTypes.func,
};

const PublicationsFilterGraph = (props) => {
  const {
    error,
    loading,
    graphData = {},
    geneId,
    graphId,
    showTitle,
    dateFrom,
    dateTo,
    publicationSource,
    changeYearsValues,
    clearGraphData,
    getGraphData,
    abilityToExtend,
    extendedGraphWidth,
    publicationFilterExtended,
    setPublicationFilterExtended,
  } = props;

  const isGraphDataExist = !!Object.keys(graphData).length && !!graphData.x.min && !!graphData.x.max;

  const getWidth = () => {
    if (!publicationFilterExtended) {
      return graphData.pubsData && graphData.pubsData.length * 15 < GRAPH_WIDTH ?
        graphData.pubsData.length * 15 : GRAPH_WIDTH;
    }
    return extendedGraphWidth;
  };

  let height = 250;
  let width = getWidth();

  function getDatesArray() {
    const dFrom = dateFrom || graphData.x.min;
    const dTo = dateTo || graphData.x.max;
    return [dFrom, dTo];
  }

  function initBarChart(chartData) {
    const margin = {
      top: 20,
      right: 0,
      bottom: 10,
      left: 0,
    };

    height = height - margin.top - margin.bottom;
    width = width - margin.left - margin.right;

    const tooltip = d3js.select('.search-page-main')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'chart-tooltip');

    function getBarTooltipTemplate(d) {
      return (`
        <div class="chart-tooltip__content">
          <span><b>Year:</b> ${d.year}</span><br/>
          <span><b>Count:</b> ${d.count}</span>
        </div>
      `);
    }

    function getColor({ year }) {
      const [minYear, maxYear] = getDatesArray();
      if (year <= maxYear && year >= minYear) {
        return 'rgb(118, 100, 157)';
      }
      return 'rgb(154, 150, 162)';
    }

    function mouseOver() {
      tooltip
        .style('opacity', 1);
    }

    function mouseMoveBar(d) {
      tooltip
        .html(getBarTooltipTemplate(d))
        .style('left', `${d3js.event.pageX + 5}px`)
        .style('top', `${d3js.event.pageY - 100}px`);
    }

    function mouseLeave() {
      tooltip
        .style('opacity', 0);
    }

    function handleClick({ year }) {
      changeYearsValues([`${year}`, `${year}`]);
    }

    const x = d3js.scaleBand()
      .domain([...chartData.yearsData])
      .range([0, width]);

    const y = d3js.scaleLinear()
      .domain([0, chartData.y.max])
      .range([height, 0]);

    const xAxis = d3js
      .axisBottom(x)
      .tickValues(chartData.tickXValues);

    const yAxis = d3js
      .axisLeft(y)
      .ticks(4);

    const svg = d3js.select(`#${graphId}`).append('svg');
    svg
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.top + margin.bottom);

    svg.xAxis = svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${margin.right},${height})`)
      .call(xAxis);

    svg.yAxis = svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.right},0)`)
      .call(yAxis);

    svg.yAxis.selectAll('text')
      .attr('x', -10)
      .style('font-size', '11px')
      .style('cursor', 'auto');

    svg.yAxis.selectAll('line')
      .attr('x2', -5);

    svg.selectAll('.bar')
      .data(chartData.pubsData)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .style('cursor', 'pointer')
      .on('click', handleClick)
      .on('mouseover', mouseOver)
      .on('mouseleave', mouseLeave)
      .on('mousemove', mouseMoveBar);

    svg.selectAll('.bar-group')
      .append('rect')
      .attr('class', 'bar')
      .attr('height', d => Math.abs(y(d.count) - y(0)))
      .attr('width', x.bandwidth())
      .style('fill', d => getColor(d))
      .attr('x', d => x(d.year))
      .attr('y', d => y(d.count))
      .attr('height', d => Math.abs(y(d.count) - y(0)));

    svg.selectAll('.bar-group')
      .append('rect')
      .attr('class', 'bar-bg')
      .attr('height', height)
      .attr('width', x.bandwidth())
      .attr('x', d => x(d.year))
      .attr('y', 0)
      .style('fill', 'transparent');
  }

  function removeChart() {
    d3js.selectAll(`#${graphId} > *`).remove();
    d3js.selectAll('body .chart-tooltip').remove();
  }

  useEffect(() => {
    if (geneId && publicationSource) {
      if (isGraphDataExist) {
        clearGraphData();
      }
      const requestData = getDataForGraphRequest(geneId, publicationSource);
      getGraphData(requestData);
    }
  }, [geneId, publicationSource]);

  useEffect(() => {
    if (isGraphDataExist) {
      removeChart();
      initBarChart(graphData);
    }
  }, [graphData, dateFrom, dateTo, width]);

  return (
    <div
      className={classNames('publications-graph', { 'publications-graph--expanded': publicationFilterExtended })}>
      {
        isGraphDataExist &&
          <div>
            {
              abilityToExtend &&
              graphData.x.max - graphData.x.min > 20 &&
              <ButtonCircle
                size={36}
                title="Expand/collapse timeline"
                icon={publicationFilterExtended ? <BsArrowsAngleContract size={24}/> : <BsArrowsAngleExpand size={24}/>}
                onClick={() => setPublicationFilterExtended(!publicationFilterExtended)}
              />
            }
            {
              showTitle &&
              <span className="publications-graph__title">
                Publications by years
              </span>
            }
            <div className="publications-graph__filter">
              {
                !error &&
                <div id={graphId} />
              }
              <RangeInput
                abilityToExtend={abilityToExtend}
                style={{ width: width - (width / graphData.pubsData.length) }}
                defaultValue={[graphData.x.min, graphData.x.max]}
                value={getDatesArray()}
                onChange={changeYearsValues}
                onAfterChange={changeYearsValues}
                tipProps={{
                  visible: true,
                  placement: 'bottom',
                }}
                trackStyle={[{
                  background: 'rgb(118, 100, 157)',
                  height: '3px',
                }]}
                railStyle={{
                  height: '3px',
                  background: 'transparent',
                }}
                handleStyle={[{
                  width: '18px',
                  height: '18px',
                  marginTop: '-6px',
                  borderColor: 'rgb(118, 100, 157)',
                }]}
                activeDotStyle={[{
                  borderColor: 'rgb(118, 100, 157)',
                  boxShadow: '0 0 0 5px rgb(118, 100, 157)',
                }]}
                step={1}
              />
            </div>
          </div>
      }
      <Loader isLoading={loading} />
      <Error show={error} error={error} />
      <NoData
        show={!isGraphDataExist && !loading}
        customClassName="publications-graph__no-data"
        message="There is no data to display"
      />
    </div>
  );
};

PublicationsFilterGraph.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    graphData: SELECTORS.getGraphData(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getGraphData(data) {
      dispatch(ACTIONS.getPublicationsGraphDataAction(data));
    },
    clearGraphData(data) {
      dispatch(ACTIONS.clearPublicationsGraphDataAction(data));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicationsFilterGraph);
