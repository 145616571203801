import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

// Components
import SimpleTable from '../../../../common/SimpleTable/SimpleTable';
import Autocomplete from '../../../../common/Inputs/Autocomplete/Autocomplete';
// Store
import { suggestUnresolvedConceptsRequestedAction } from './store/reducer';
import { getUnresolvedConceptsSuggestion, getUnresolvedConceptsSuggestionLoading } from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  items: PropTypes.instanceOf(Array),
  resolveConceptFunction: PropTypes.func,
  removeConceptFunction: PropTypes.func,
  setUnresolvedConceptInputValue: PropTypes.func,
  unresolvedSuggestions: PropTypes.instanceOf(Object),
};

class UnresolvedConceptsTable extends React.Component {
  constructor(props) {
    super(props);
    this.simpleTableRef = React.createRef(null);
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  autoCompleteItemJSX = (item, highlighted) => (
    <div
      key={item.id}
      style={{ background: highlighted ? 'lightgray' : 'white' }}
    >
      {item.name}
    </div>
  );

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  getColumns = (width) => {
    const {
      removeConceptFunction,
      resolveConceptFunction,
      unresolvedSuggestions,
      setUnresolvedConceptInputValue,
    } = this.props;

    this.columns = [
      {
        label: 'Source identifier',
        dataKey: 'geneName',
        width: this.getColumnPercentWidth(width, 30),
        className: 'table-wrap__cell table-wrap__cell_left',
      },
      {
        label: 'Name',
        dataKey: 'mappedGenes',
        width: this.getColumnPercentWidth(width, 60),
        className: 'table-wrap__cell table-wrap__cell_left',
        cellRenderer: ({ rowData }) => {
          const { id, geneName } = rowData;
          return (
            <Autocomplete
              key={id}
              placeholder="Concept name"
              initialValue={geneName}
              onChange={(value) => setUnresolvedConceptInputValue(value, geneName)}
              onSelect={(item) => resolveConceptFunction({ rowId: id, item })}
              options={unresolvedSuggestions[geneName]}
            />
          );
        }
      },
      {
        dataKey: 'types',
        disableSort: true,
        width: this.getColumnPercentWidth(width, 10),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (
          <button
            type="button"
            onClick={() => removeConceptFunction(rowData.id)}
            className="btn-icon btn-icon-danger"
          >
            <i className="fa fa-trash" />
          </button>
        ),
      }];
    return this.columns;
  };

  render() {
    const { items } = this.props;

    const tableSettings = {
      width: 900,
      headerHeight: 50,
      rowHeight: 50,
    };

    return (
      <div className="row unresolved-concepts">
        <div>
          <div className="empty-items-message disambiguation-message">
            Some of the identifiers from the input file were not matched successfully. Please type the correct ones manually:
          </div>
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <SimpleTable
                  innerRef={this.simpleTableRef}
                  autoSize={true}
                  width={width}
                  settings={tableSettings}
                  data={items}
                  columns={this.getColumns(width)}
                  dynamicHeight={true}
                /> : null
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

UnresolvedConceptsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    unresolvedSuggestions: getUnresolvedConceptsSuggestion(state),
    loading: getUnresolvedConceptsSuggestionLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUnresolvedConceptInputValue(inputValue, geneName) {
      dispatch(suggestUnresolvedConceptsRequestedAction({ input: inputValue, geneName }));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnresolvedConceptsTable);
