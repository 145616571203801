import { createSelector } from 'reselect';

const conceptCardsData = state => state.getIn(['conceptCardsReducer', 'conceptCardsData']);
const conceptCardsLoading = state => state.getIn(['conceptCardsReducer', 'loading']);
const conceptCardsError = state => state.getIn(['conceptCardsReducer', 'error']);

export const getConceptCardsDataSelector = createSelector(
  conceptCardsData,
  data => data && data.toJS()
);

export const getConceptCardsLoadingSelector = createSelector(
  conceptCardsLoading,
  data => data
);

export const getConceptCardsErrorSelector = createSelector(
  conceptCardsError,
  data => data
);
