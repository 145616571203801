import {
  OPEN_ADD_LINKED_CONCEPTS,
  CLOSE_ADD_LINKED_CONCEPTS,
  FILTER_CATEGORIES_REQUESTED,
  FILTER_RELATIONS_REQUESTED,
  FILTER_TAXONOMIES_REQUESTED,
  FILTER_OPEN_CATEGORY,
  FILTER_CLOSE_CATEGORY,
  FILTER_OPEN_RELATION,
  FILTER_CLOSE_RELATION,
  FILTER_CHECK_RELATION,
  FILTER_CHECK_TAXONOMY,
  FILTER_CHECK_CATEGORY,
  FILTER_CHECK_CATEGORY_TYPE,
  FILTER_CATEGORIES_TYPE_REQUESTED,
  FILTER_CATEGORIES_TYPE_LOADING,
  FILTER_CHECK_ALL_CATEGORY_TYPE,
  RELATED_CONCEPTS_REQUESTED,
  CHECK_RELATED_CONCEPT,
  SORT_RELATED_CONCEPTS,
  FILTER_RELATED_CONCEPTS,
  ADD_COLUMN_REQUESTED,
  CHECK_ALL_RELATED_CONCEPT,
  FILTER_CHECK_RELATION_TYPE,
  FILTER_CHECK_ALL_RELATION_TYPE,
} from './constants';

export const openAddLinkedConcepts = () => ({
  type: OPEN_ADD_LINKED_CONCEPTS,
});

export const closeAddLinkedConcepts = () => ({
  type: CLOSE_ADD_LINKED_CONCEPTS,
});

export const initFilterCategories = () => ({
  type: FILTER_CATEGORIES_REQUESTED,
});

export const initFilterPredicates = () => ({
  type: FILTER_RELATIONS_REQUESTED,
});

export const initFilterTaxonomies = () => ({
  type: FILTER_TAXONOMIES_REQUESTED,
});

export const openRelation = data => ({
  type: FILTER_OPEN_RELATION,
  data,
});

export const closeRelation = data => ({
  type: FILTER_CLOSE_RELATION,
  data,
});

export const openCategory = data => ({
  type: FILTER_OPEN_CATEGORY,
  data,
});

export const closeCategory = data => ({
  type: FILTER_CLOSE_CATEGORY,
  data,
});

export const checkRelation = data => ({
  type: FILTER_CHECK_RELATION,
  data,
});

export const checkRelationType = data => ({
  type: FILTER_CHECK_RELATION_TYPE,
  data,
});

export const checkAllRelationType = data => ({
  type: FILTER_CHECK_ALL_RELATION_TYPE,
  data,
});

export const checkTaxonomy = data => ({
  type: FILTER_CHECK_TAXONOMY,
  data,
});

export const checkCategoryType = data => ({
  type: FILTER_CHECK_CATEGORY_TYPE,
  data,
});

export const checkAllCategoryTypes = data => ({
  type: FILTER_CHECK_ALL_CATEGORY_TYPE,
  data,
});

export const checkCategory = data => ({
  type: FILTER_CHECK_CATEGORY,
  data,
});

export const initSemanticTypes = data => ({
  type: FILTER_CATEGORIES_TYPE_REQUESTED,
  data,
});

export const semanticTypesLoading = data => ({
  type: FILTER_CATEGORIES_TYPE_LOADING,
  data,
});

export const findRelatedConcepts = () => ({
  type: RELATED_CONCEPTS_REQUESTED,
});

export const checkRelatedConcept = data => ({
  type: CHECK_RELATED_CONCEPT,
  data,
});

export const checkAllRelatedConcepts = (checked, filter) => ({
  type: CHECK_ALL_RELATED_CONCEPT,
  data: {
    checked,
    filter,
  },
});

export const sort = data => ({
  type: SORT_RELATED_CONCEPTS,
  data,
});

export const filter = data => ({
  type: FILTER_RELATED_CONCEPTS,
  data,
});

export const addColumn = () => ({
  type: ADD_COLUMN_REQUESTED,
});
