import { call, put, takeEvery, select } from 'redux-saga/effects';

import Api from '../../../../Api/Api';

import {
  VALIDATE_FORMULA_REQUESTED,
  VALIDATE_FORMULA_SUCCEEDED,
  VALIDATE_FORMULA_FAILED,
} from './constants';
import { getCurrentScoringFormula } from './selectors';

function* saga() {
  try {
    const formula = yield select(getCurrentScoringFormula);
    yield call(Api.validateFormula, { params: { f: formula } });
    yield put({ type: VALIDATE_FORMULA_SUCCEEDED });
  } catch (e) {
    yield put({ type: VALIDATE_FORMULA_FAILED });
  }
}

function* validateFormula() {
  yield takeEvery(VALIDATE_FORMULA_REQUESTED, saga);
}

export default validateFormula;
