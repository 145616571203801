import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const setHideSourceDataAction = createAction('@@hideSource/SET_DATA');
export const updateHideSourceItemAction = createAction('@@hideSource/UPDATE_ITEM');
export const resetHideSourceDataAction = createAction('@@hideSource/RESET');

const initialState = fromJS({
  data: {},
});

const reducer = handleActions(
  {
    [setHideSourceDataAction]: (state, { payload }) =>
      state
        .update('data', data => data.mergeDeep(fromJS(payload))),
    [updateHideSourceItemAction]: (state, { payload }) =>
      state
        .updateIn(['data', `${payload.id}`], () => fromJS(payload)),
    [resetHideSourceDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
