import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const throwAntibodiesErrorAction = createAction('@@antibodies/THROW_ERROR');
export const sortAntibodiesTableAction = createAction('@@antibodies/SORT');
export const loadAntibodiesDataAction = createAction('@@antibodies/GET_DATA');
export const setAntibodiesDataAction = createAction('@@antibodies/SET_DATA');
export const clearAntibodiesDataAction = createAction('@@antibodies/CLEAR_DATA');
export const changePagePatentsAntibodiesAction = createAction('@@antibodies/CHANGE_PAGE_PATENTS');
export const changePageWiposAntibodiesAction = createAction('@@antibodies/CHANGE_PAGE_WIPOS');

const initialState = fromJS({
  loading: true,
  error: null,
  concept: null,
  plabdab: [],
  wipos: [],
  sorting: {
    sortBy: 'updateDate',
    sortDirection: 'DESC',
  },
  patentsPage: 0,
  wiposPage: 0,
});

const reducer = handleActions(
  {
    [loadAntibodiesDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [clearAntibodiesDataAction]: state =>
      state
        .update('plabdab', () => [])
        .update('wipos', () => [])
        .update('error', () => null),
    [setAntibodiesDataAction]: (state, payload) =>
      state
        .update('loading', () => false)
        .update('concept', () => fromJS(payload.payload.concept))
        .update('plabdab', () => fromJS(payload.payload.patents))
        .update('wipos', () => fromJS(payload.payload.wipos))
        .update('error', () => null),
    [throwAntibodiesErrorAction]: (state, { payload }) =>
      state
        .update('plabdab', () => [])
        .update('wipos', () => [])
        .update('loading', () => false)
        .update('error', () => payload),
    [changePagePatentsAntibodiesAction]: (state, { payload }) =>
      state.update('patentsPage', () => payload),
    [changePageWiposAntibodiesAction]: (state, { payload }) =>
      state.update('wiposPage', () => payload),
    [sortAntibodiesTableAction]: (state, payload) =>
      state
        .update('sorting', () => fromJS(payload.payload)),
  },
  initialState
);

export default reducer;
