import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Components
import HeatmapHeaderBtn from '../HeatmapHeaderBtn/HeatmapHeaderBtn';
import HeatmapHeaderSelect from '../HeatmapHeaderSelect/HeatmapHeaderSelect';
// Constants
import {
  HeatmapDeletingEnum,
  HeatmapSortingEnum,
  HeatmapDeletingOptions,
  HeatmapSortingOptions,
  heatmapCooccurrenceTypesOptions,
} from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  noData: PropTypes.bool,
  csvData: PropTypes.string,
  showGoBack: PropTypes.bool,
  showHeatMap: PropTypes.bool,
  showShowOnRelationMap: PropTypes.bool,
  showSaveAsSet: PropTypes.bool,
  showDeleteSelected: PropTypes.bool,
  cellsIsSelected: PropTypes.bool,
  sortByRow: PropTypes.func,
  exportToCSV: PropTypes.func,
  sortByColumn: PropTypes.func,
  sortByCluster: PropTypes.func,
  deleteSelected: PropTypes.func,
  openAddSetPopup: PropTypes.func,
  showOnRelationMap: PropTypes.func,
  deleteSelectedRowCol: PropTypes.func,
  swapAxis: PropTypes.func,
  setScoreType: PropTypes.func,
  measureSelector: PropTypes.object,
};

const HeatmapHeader = (props) => {
  const {
    noData,
    csvData,
    showGoBack,
    showHeatMap,
    showShowOnRelationMap = true,
    showSaveAsSet = true,
    showDeleteSelected = true,
    cellsIsSelected,
    sortByRow,
    exportToCSV,
    sortByColumn,
    sortByCluster,
    deleteSelected,
    openAddSetPopup,
    showOnRelationMap,
    deleteSelectedRowCol,
    swapAxis,
    setScoreType,
    measureSelector,
  } = props;

  const headerClass = classNames('heatmap-header', {
    'heatmap-header_border-top': !showGoBack,
  });

  function handleSorting(sortingType) {
    switch (sortingType) {
      case HeatmapSortingEnum.SORT_COLUMNS:
        sortByColumn();
        break;
      case HeatmapSortingEnum.SORT_ROWS:
        sortByRow();
        break;
      case HeatmapSortingEnum.SORT_COLUMNS_BY_CLUSTER:
        sortByCluster('columns', 'col');
        break;
      case HeatmapSortingEnum.SORT_ROWS_BY_CLUSTER:
        sortByCluster('rows', 'row');
        break;
      default:
        break;
    }
  }

  function handleDeleting(deletingType) {
    switch (deletingType) {
      case HeatmapDeletingEnum.DELETE_SELECTED:
        deleteSelected();
        break;
      case HeatmapDeletingEnum.DELETE_SELECTED_COL:
        deleteSelectedRowCol('columns');
        break;
      case HeatmapDeletingEnum.DELETE_SELECTED_ROW:
        deleteSelectedRowCol('rows');
        break;
      default:
        break;
    }
  }

  function handleScoreTypeOptions(option) {
    setScoreType(option);
  }

  return (
    <div className={headerClass}>
      <HeatmapHeaderBtn
        text="Swap axis"
        disabled={noData}
        onClick={swapAxis}
        hide={!showHeatMap}
      />
      {showShowOnRelationMap &&
        <HeatmapHeaderBtn
          text="Show on relation map"
          disabled={noData || !cellsIsSelected}
          onClick={showOnRelationMap}
          hide={!showHeatMap}
        />
      }
      {
        showSaveAsSet &&
        <HeatmapHeaderBtn
          text="Save as set"
          disabled={noData || !cellsIsSelected}
          onClick={openAddSetPopup}
          hide={!showHeatMap}
        />
      }
      <HeatmapHeaderBtn
        text="Export to CSV"
        onClick={exportToCSV}
        disabled={csvData}
      />
      <HeatmapHeaderSelect
        defaultValue={(measureSelector && measureSelector[0]) || heatmapCooccurrenceTypesOptions[0]}
        options={measureSelector || heatmapCooccurrenceTypesOptions}
        onSelect={handleScoreTypeOptions}
      />
      <HeatmapHeaderSelect
        placeholder="Sorting actions"
        options={HeatmapSortingOptions}
        onSelect={handleSorting}
        hide={!showHeatMap}
        disabled={noData}
      />
      {
        showDeleteSelected &&
        <HeatmapHeaderSelect
          placeholder="Deleting actions"
          options={HeatmapDeletingOptions}
          onSelect={handleDeleting}
          hide={!showHeatMap}
          disabled={noData || !cellsIsSelected}
        />
      }
    </div>
  );
};

HeatmapHeader.propTypes = propTypes;

export default HeatmapHeader;
