import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  setProteinStructureDataAction,
  toggleProteinStructureLoaderAction,
  proteinStructureIsFailedAction,
  getPDBEntriesAction,
  setPDBEntriesErrorAction,
} from '../../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

export function* getProteinStructureDataWorker(action) {
  try {
    yield put(toggleProteinStructureLoaderAction(true));
    const requestData = action.data;
    const response = yield call(Api.geneProteinStructure, requestData);
    yield put(setProteinStructureDataAction(response));
    yield put(toggleProteinStructureLoaderAction(false));

    const { data } = response;

    if (data && data.structure) {
      yield put(geneDetailsSetChapterAction('protein-structure'));
      yield put(getPDBEntriesAction());
    } else {
      yield put(setPDBEntriesErrorAction());
    }

    yield put(loadNextChapterAction('similar-proteins'));
  } catch (e) {
    yield put(proteinStructureIsFailedAction(e.message));
    yield put(loadNextChapterAction('similar-proteins'));
  }
}
