export const CONCEPT_DETAILS_REQUESTED = 'CONCEPT_DETAILS_REQUESTED';
export const CONCEPT_DETAILS_SUCCEEDED = 'CONCEPT_DETAILS_SUCCEEDED';
export const CONCEPT_DETAILS_FAILED = 'CONCEPT_DETAILS_FAILED';
export const CONCEPT_DETAILS_LOADING = 'CONCEPT_DETAILS_LOADING';
export const CONCEPT_CATEGORY_ANALYSIS_REQUESTED = 'CONCEPT_CATEGORY_ANALYSIS_REQUESTED';
export const CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED = 'CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED';
export const CONCEPT_CATEGORY_ANALYSIS_FAILED = 'CONCEPT_CATEGORY_ANALYSIS_FAILED';
export const CONCEPT_CATEGORY_ANALYSIS_LOADING = 'CONCEPT_CATEGORY_ANALYSIS_LOADING';
export const CONCEPT_CATEGORY_ANALYSIS_RESET = 'CONCEPT_CATEGORY_ANALYSIS_RESET';
export const CONCEPT_CATEGORY_ANALYSIS_NODES_REQUESTED = 'CONCEPT_CATEGORY_ANALYSIS_NODES_REQUESTED';
export const CONCEPT_CATEGORY_ANALYSIS_NODES_SUCCEEDED = 'CONCEPT_CATEGORY_ANALYSIS_NODES_SUCCEEDED';
export const CONCEPT_CATEGORY_ANALYSIS_NODES_FAILED = 'CONCEPT_CATEGORY_ANALYSIS_NODES_FAILED';
export const CONCEPT_CATEGORY_ANALYSIS_NODES_LOADING = 'CONCEPT_CATEGORY_ANALYSIS_NODES_LOADING';
export const CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE = 'CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE_';
export const CONCEPT_DETAILS_RESET = 'CONCEPT_DETAILS_RESET';
export const CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED = 'CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED';
export const CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_FAILED = 'CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_FAILED';

export const CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID = 'CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID';
