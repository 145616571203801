import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getGeneOptionsAction = createAction('@@selectGene/GET_GENE_OPTIONS');
export const putGeneOptionsAction = createAction('@@selectGene/PUT_GENE_OPTIONS');
export const toggleSelectGeneLoaderAction = createAction('@@selectGene/TOGGLE_GENE');
export const throwSelectGeneErrorAction = createAction('@@selectGene/GENE_ERROR');
export const resetSelectGeneAction = createAction('@@selectGene/RESET_ERROR');

const initialState = fromJS({
  options: [],
  loading: false,
  error: false,
});

const reducer = handleActions(
  {
    [putGeneOptionsAction]: (state, { payload }) =>
      state
        .update('options', () => fromJS(payload))
        .update('error', () => false)
        .update('loading', () => false),
    [toggleSelectGeneLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwSelectGeneErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [resetSelectGeneAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
