import { put, takeEvery } from 'redux-saga/effects';

import { locationChangeSuccessAction, locationChangeRequestAction } from './locationReducer';

function* saga(action) {
  try {
    yield put(locationChangeSuccessAction(action.payload));
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield takeEvery(locationChangeRequestAction, saga);
}
