import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import * as a from './reducer';

import { getProjectIdForRequest, getProjectTypeForRequest } from '../../../Projects/ProjectsTabs/utils';
import { exportToFile } from '../../../Utils/Utils';

function* getPublicationsManagementDataWorker({ payload }) {
  try {
    const { projectId, params = { page: 0, size: 15 } } = payload;
    const projectTypeForRequest = getProjectTypeForRequest(projectId);
    const projectIdForRequest = getProjectIdForRequest(projectId);
    const requestData = {
      params: {
        ...params,
        id: projectIdForRequest,
      },
      type: projectTypeForRequest,
    };
    const { data } = yield call(Api.getPublications, requestData);
    const content = data.content.map(publication =>
      Object.assign(publication, { checked: false })
    );
    yield put(a.setPublicationsManagementDataAction({
      ...data,
      content,
    }));
  } catch (e) {
    yield put(a.throwPublicationsManagementErrorAction(e.message));
  }
}

function* deletePublicationsManagementItemsWorker({ payload }) {
  try {
    const { projectId, ids } = payload;
    const projectTypeForRequest = getProjectTypeForRequest(projectId);
    const projectIdForRequest = getProjectIdForRequest(projectId);
    const requestData = {
      id: projectIdForRequest,
      type: projectTypeForRequest,
      publicationIds: ids,
    };
    yield call(Api.deletePublications, requestData);
    yield put(a.getPublicationsManagementDataAction({ noLoader: true, projectId }));
  } catch (e) {
    yield put(a.throwPublicationsManagementErrorAction(e.message));
  }
}

function* getAllPublicationsIdsByProjectWorker({ payload }) {
  try {
    const { projectId } = payload;
    const projectTypeForRequest = getProjectTypeForRequest(projectId);
    const projectIdForRequest = getProjectIdForRequest(projectId);
    const requestData = {
      id: projectIdForRequest,
      type: projectTypeForRequest,
    };
    const { data } = yield call(Api.getAllPublicationsIdsByProject, requestData);
    yield put(a.updateSelectedPublicationsIdsAction(data.ids));
    yield put(a.updateSelectedPubmedIdsAction(data.pubmedIds));
  } catch (e) {
    yield put(a.throwPublicationsManagementErrorAction(e.message));
  }
}

function* getPublicationsDataForExportWorker({ payload }) {
  try {
    const { projectId, publicationsIds, downloadLink } = payload;
    const projectTypeForRequest = getProjectTypeForRequest(projectId);
    const projectIdForRequest = getProjectIdForRequest(projectId);
    const requestData = {
      publicationsIds,
      id: projectIdForRequest,
      type: projectTypeForRequest,
    };
    const { data } = yield call(Api.getPublicationsDataForExport, requestData);
    exportToFile(downloadLink, data, 'publications-export', 'nbib');
  } catch (e) {
    yield put(a.throwPublicationsManagementErrorAction(e.message));
  }
}

function* relationMapManagement() {
  yield takeEvery(a.getPublicationsManagementDataAction, getPublicationsManagementDataWorker);
  yield takeEvery(a.getAllPublicationsIdsByProjectAction, getAllPublicationsIdsByProjectWorker);
  yield takeEvery(a.deletePublicationsManagementItemsAction, deletePublicationsManagementItemsWorker);
  yield takeEvery(a.getPublicationsDataForExportAction, getPublicationsDataForExportWorker);
}

export default relationMapManagement;
