import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  setDisordersDataAction,
  toggleDisordersLoaderAction,
  disordersIsFailedAction,
} from '../../reducer';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

export function* getDisordersDataWorker(action) {
  try {
    yield put(toggleDisordersLoaderAction(true));
    const requestData = action.payload;
    const { data } = yield call(Api.getGeneDetailsDisorders, requestData);
    yield put(setDisordersDataAction(data));
    yield put(toggleDisordersLoaderAction(false));
    const {
      animalModels: animalModelsDisorders,
      chords: chordsDisorders,
      data: disordersData,
      diseaseAnnotation: diseaseAnnotationDisorders,
      geneticVariations: geneticVariationsDisorders,
    } = data.disorders;

    const {
      animalModels: animalModelsCancers,
      chords: chordsCancers,
      data: cancersData,
      diseaseAnnotation: diseaseAnnotationCancers,
      geneticVariations: geneticVariationsCancers,
    } = data.cancers;

    if ((animalModelsDisorders && animalModelsDisorders.length)
      || (chordsDisorders && chordsDisorders.length)
      || (disordersData && disordersData.length)
      || (diseaseAnnotationDisorders && diseaseAnnotationDisorders.length)
      || (geneticVariationsDisorders && geneticVariationsDisorders.length)
    ) {
      yield put(geneDetailsSetChapterAction('disease'));
    }

    if ((animalModelsCancers && animalModelsCancers.length)
      || (chordsCancers && chordsCancers.length)
      || (cancersData && cancersData.length)
      || (diseaseAnnotationCancers && diseaseAnnotationCancers.length)
      || (geneticVariationsCancers && geneticVariationsCancers.length)
    ) {
      yield put(geneDetailsSetChapterAction('cancer'));
    }

    yield put(loadNextChapterAction('therapeutic-candidates'));
  } catch (e) {
    yield put(disordersIsFailedAction(e.message));
    yield put(loadNextChapterAction('therapeutic-candidates'));
  }
}
