import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form/immutable';
import ReactTooltip from 'react-tooltip';

const propTypes = {
  isSection: PropTypes.bool,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  customClass: PropTypes.string,
  infoTooltip: PropTypes.instanceOf(Object),
  fieldProps: PropTypes.instanceOf(Object),
  labelStyle: PropTypes.instanceOf(Object),
};

const CreateSetFilterField = (props) => {
  const {
    fieldName,
    fieldLabel,
    infoTooltip,
    fieldProps,
    labelStyle,
    isSection = false,
    customClass = '',
  } = props;

  function renderContent() {
    return (
      <div className={`create-set-filter__block ${customClass}`}>
        <span className="create-set-filter__label">
          <span
            className="create-set-filter__label-text"
            style={labelStyle}
          >
            {fieldLabel}
          </span>
          {
            infoTooltip &&
            <span
              className="fa fa-info-circle icon first-info-icon create-set-filter__icon"
              data-tip={true}
              data-for={infoTooltip.id}
            />
          }
        </span>
        <Field {...fieldProps} />
        {
          infoTooltip &&
          <ReactTooltip
            id={infoTooltip.id}
            place="right"
          >
            {infoTooltip.text}
          </ReactTooltip>
        }
      </div>
    );
  }

  if (isSection) {
    return (
      <FormSection name={fieldName}>
        {renderContent()}
      </FormSection>
    );
  }

  return (renderContent());
};

CreateSetFilterField.propTypes = propTypes;

export default CreateSetFilterField;
