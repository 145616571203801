import { CooccurrenceEnum } from '../../constantsCommon';

export const SearchSourceFilterEnum = {
  ALL: 'ALL',
  ABSTRACT: CooccurrenceEnum.ABSTRACT,
  SENTENCE: CooccurrenceEnum.SENTENCE,
  TITLE: CooccurrenceEnum.TITLE,
  DATABASE: 'DATABASE_ANNOTATION',
  DATABASE_PUBMED: 'DATABASE_PUBMED',
};

export const SearchSortFilterEnum = {
  MOST_RECENT: 'pub_date,desc',
  MOST_RELEVANT: 'score,desc',
  IMPACT_FACTOR: 'snip,desc',
  CITED_BY: 'cited_by,desc',
};

export const searchFilterDatabase = {
  value: SearchSourceFilterEnum.DATABASE,
  label: 'Database annotations',
  type: SearchSourceFilterEnum.DATABASE,
};

export const searchFilterSelectOptionsEnum = {
  sources: [
    {
      value: SearchSourceFilterEnum.ABSTRACT,
      label: 'Abstracts (co-occurrence)',
      type: SearchSourceFilterEnum.ABSTRACT,
    },
    {
      value: SearchSourceFilterEnum.SENTENCE,
      label: 'Abstracts (sentence co-occurrence)',
      type: SearchSourceFilterEnum.SENTENCE,
    },
    {
      value: SearchSourceFilterEnum.TITLE,
      label: 'Abstracts (title co-occurrence)',
      type: SearchSourceFilterEnum.TITLE,
    },
    {
      value: SearchSourceFilterEnum.DATABASE_PUBMED,
      label: 'Database reference to Pubmed article',
      type: SearchSourceFilterEnum.DATABASE_PUBMED,
    },
  ],
  types: [
    {
      value: 'Case Reports',
      label: 'Case Reports',
      type: 'CASE_REPORTS',
    },
    {
      value: 'Clinical Trial',
      label: 'Clinical Trial',
      type: 'CLINICAL_TRIAL',
    },
    {
      value: 'Clinical Study',
      label: 'Clinical Study',
      type: 'CLINICAL_STUDY',
    },
    {
      value: 'Meta-Analysis',
      label: 'Meta-Analysis',
      type: 'META_ANALYSIS',
    },
    {
      value: 'Review',
      label: 'Review',
      type: 'REVIEW',
    },
  ],
  researchTypes: [
    {
      value: 'In-vitro',
      label: 'In-vitro',
      type: 'IN_VITRO',
    },
    {
      value: 'Homo Sapiens',
      label: 'Homo Sapiens',
      type: 'HUMAN',
    },
    {
      value: 'Mus Musculus',
      label: 'Mus Musculus',
      type: 'MOUSE',
    },
    {
      value: 'Rattus Norvegicus',
      label: 'Rattus Norvegicus',
      type: 'RAT',
    },
    {
      value: 'Saccharomyces cerevisiae',
      label: 'Saccharomyces cerevisiae',
      type: 'BAKKERS_YEAST',
    },
  ],
};

export const searchCooccurrenceNamesEnum = {
  ABSTRACT: 'Pubmed',
  SENTENCE: 'Same sentence',
  TITLE: 'Title',
};

export const searchSortingOptions = [
  {
    value: SearchSortFilterEnum.MOST_RECENT,
    label: 'Most Recent',
    type: SearchSortFilterEnum.MOST_RECENT,
  },
  {
    value: SearchSortFilterEnum.MOST_RELEVANT,
    label: 'Most Relevant',
    type: SearchSortFilterEnum.MOST_RELEVANT,
  },
  {
    value: SearchSortFilterEnum.IMPACT_FACTOR,
    label: 'Impact Factor',
    type: SearchSortFilterEnum.IMPACT_FACTOR,
  },
  {
    value: SearchSortFilterEnum.CITED_BY,
    label: 'Cited by',
    type: SearchSortFilterEnum.CITED_BY,
  },
];

export const searchSourcesOptions = [
  {
    value: SearchSourceFilterEnum.ALL,
    label: 'All sources',
    type: SearchSourceFilterEnum.ALL,
  },
  {
    value: SearchSourceFilterEnum.ABSTRACT,
    label: 'Abstracts (co-occurrence)',
    type: SearchSourceFilterEnum.ABSTRACT,
  },
  {
    value: SearchSourceFilterEnum.SENTENCE,
    label: 'Abstracts (sentence co-occurrence)',
    type: SearchSourceFilterEnum.SENTENCE,
  },
  {
    value: SearchSourceFilterEnum.TITLE,
    label: 'Abstracts (title co-occurrence)',
    type: SearchSourceFilterEnum.TITLE,
  },
  searchFilterDatabase,
];

export const PortalActions = {
  MARKERS_IDENTIFY_DISEASE: 'MARKERS_IDENTIFY_DISEASE',
  TARGET_MARKER_ASSESSMENT: 'TARGET_MARKER_ASSESSMENT',
  ENRICHMENT_ANALYSIS: 'ENRICHMENT_ANALYSIS',
  RANK_CANDIDATES: 'RANK_CANDIDATES',
  ANTIBODY_FILTER: 'ANTIBODY_FILTER',
};
