import { createSelector } from 'reselect';

const tooltipUniqueKey = state => state.getIn(['tooltipReducer', 'uniqueKey']);
const tooltipX = state => state.getIn(['tooltipReducer', 'clientX']);
const tooltipY = state => state.getIn(['tooltipReducer', 'clientY']);

export const getTooltipUniqueKeySelector = createSelector(
  tooltipUniqueKey,
  data => data
);

export const getTooltipXSelector = createSelector(
  tooltipX,
  data => data
);

export const getTooltipYSelector = createSelector(
  tooltipY,
  data => data
);
