import { createSelector } from 'reselect';

const heatmapData = state => state.getIn(['heatMapReducer', 'data']);
const heatmapDataForSave = state => state.getIn(['heatMapReducer', 'dataForSave']);
const heatmapLoading = state => state.getIn(['heatMapReducer', 'loading']);
const heatmapError = state => state.getIn(['heatMapReducer', 'error']);
const heatmapSorting = state => state.getIn(['heatMapReducer', 'sorting']);
const heatmapSortRows = state => state.getIn(['heatMapReducer', 'sortRows']);
const heatmapSortColumns = state => state.getIn(['heatMapReducer', 'sortColumns']);
const heatmapType = state => state.getIn(['heatMapReducer', 'type']);
const heatmapCSV = state => state.getIn(['heatMapReducer', 'csvData']);
const heatmapInitialData = state => state.getIn(['heatMapReducer', 'initialData']);
const heatmapInitialNetworkData = state => state.getIn(['heatMapReducer', 'initialNetworkData']);
const heatmapInitialCellLinesData = state => state.getIn(['heatMapReducer', 'initialCellLinesData']);
const heatmapSpinner = state => state.getIn(['heatMapReducer', 'spinner']);
const heatmapScoreType = state => state.getIn(['heatMapReducer', 'scoreType']);

export const getHeatmapDataSelector = createSelector(
  heatmapData,
  data => data && data.toJS()
);

export const getHeatmapLoadingSelector = createSelector(
  heatmapLoading,
  data => data
);

export const getHeatmapErrorSelector = createSelector(
  heatmapError,
  data => data
);

export const getHeatmapTypeSelector = createSelector(
  heatmapType,
  data => data
);

export const getHeatmapSortingSelector = createSelector(
  heatmapSorting,
  data => data && data.toJS()
);

export const getCSVHeatmapData = createSelector(
  heatmapCSV,
  data => data
);

export const getHeatmapSortRowsSelector = createSelector(
  heatmapSortRows,
  data => data
);

export const getHeatmapSortColsSelector = createSelector(
  heatmapSortColumns,
  data => data
);

export const getHeatmapInitialDataSelector = createSelector(
  heatmapInitialData,
  data => data && data.toJS()
);

export const getHeatmapInitialNetworkDataSelector = createSelector(
  heatmapInitialNetworkData,
  data => data && data.toJS()
);

export const getHeatmapInitialCellLinesDataSelector = createSelector(
  heatmapInitialCellLinesData,
  data => data && data.toJS()
);

export const getHeatmapDataForSaveSelector = createSelector(
  heatmapDataForSave,
  data => data && data.toJS()
);

export const getHeatmapSpinnerSelector = createSelector(
  heatmapSpinner,
  data => data
);

export const getHeatmapScoreType = createSelector(
  heatmapScoreType,
  data => data
);
