import { capitalizeFirstLetter } from '../../../Utils/Utils';
import { tissuesSource } from './enums';

export function splitData(data, maxScore, minScore) {
  const score = Math.round(Math.max(maxScore, Math.abs(minScore)));
  const heatMapData = {
    rows: [],
    names: [],
    data: [],
    maxScore: score,
    minScore: -Math.abs(score),
  };
  const barChartData = {
    data: [],
    rows: [],
    maxScore: 0,
  };
  const exportToCSVData = [];

  const tumorsNames = [];
  data.forEach((element) => {
    const tumorsWithScore = Object.values(element.tumorsWithScore);
    tumorsWithScore.forEach(tumor => tumorsNames.push(tumor.name));
  });

  for (let index = 0; index < data.length; index++) { // eslint-disable-line
    const element = data[index];
    const tumorsWithScore = Object.values(element.tumorsWithScore);
    const exportToCSVItem = {
      gene: element.gene,
      antibodies: element.antibodies,
      tumor: element.tumor,
      references: element.references,
    };

    heatMapData.rows.push(String(index));
    barChartData.rows.push(String(index));

    if (barChartData.maxScore < element.tumor.score) {
      barChartData.maxScore = element.tumor.score;
    }

    const newData = tumorsWithScore.map((item) => {
      const name = item.label ? `${item.name} [${item.label}]` : item.name;
      heatMapData.names.push({ name, id: item.id });
      exportToCSVItem[item.id] = item;

      return {
        ...item,
        row: index,
        gene: element.gene,
        tumorName: element.tumor.name,
      };
    });

    const emptyData = [];
    tumorsNames.forEach((name) => {
      if (!tumorsWithScore.find(tumor => tumor.name === name)) {
        emptyData.push({
          gene: element.gene,
          row: index,
          name,
        });
      }
    });

    heatMapData.data = [...heatMapData.data, ...newData, ...emptyData];

    barChartData.data.push({
      score: element.tumor.score,
      row: index,
      gene: element.gene,
    });

    exportToCSVItem.tumorExpression = element.tumorExpression;
    exportToCSVData.push(exportToCSVItem);
  }

  heatMapData.names.sort((a, b) => a.name.localeCompare(b.name));
  heatMapData.rows.reverse();
  barChartData.rows.reverse();

  return {
    heatMapData,
    barChartData,
    exportToCSVData,
  };
}

export function checkAllItems(data, checkBoolean) {
  let childTissues = Object.entries(data.childTissues);
  childTissues = childTissues.map(el => (
    [el[0], {
      ...el[1],
      checkAll: !checkBoolean,
      tissues: el[1].tissues.map(t => ({ ...t, checked: !checkBoolean })),
    }]
  ));
  return {
    ...data,
    checkAll: !checkBoolean,
    childTissues: {
      ...Object.fromEntries(childTissues),
    },
  };
}

export function checkAllGroupItems(data, type) {
  return {
    ...data,
    childTissues: {
      ...data.childTissues,
      [type]: {
        ...data.childTissues[type],
        checkAll: !data.childTissues[type].checkAll,
        tissues: data.childTissues[type].tissues.map(t => ({
          ...t,
          checked: !data.childTissues[type].checkAll,
        })),
      },
    },
  };
}


export function checkItem(data, type, id) {
  return {
    ...data,
    childTissues: {
      ...data.childTissues,
      [type]: {
        ...data.childTissues[type],
        tissues: data.childTissues[type].tissues.map(t => (t.id !== id ? t : { ...t, checked: !t.checked })),
      },
    },
  };
}

export function capitalizeDataName(data) {
  return data.map(el => ({
    ...el,
    name: capitalizeFirstLetter(el.name),
  }));
}

export function sortExpressionData(array) {
  return array.sort((a, b) => a.concept.name.localeCompare(b.concept.name));
}

// Utils for Selected Tissues Expression Data
export function prepareSelectedTissuesExpressionData(tissues, labelIsNeeded, colors) {
  let newTissuesArray = [];
  const tissuesGroups = Object.keys(tissues);
  Object.values(tissues).forEach((data, idx) => {
    newTissuesArray = [
      ...newTissuesArray,
      ...data.map(item => ({
        ...item,
        concept: {
          ...item.concept,
          name:
            `${capitalizeFirstLetter(item.concept.name)} ${labelIsNeeded ? `[${tissuesSource[tissuesGroups[idx]].label}]` : ''}`,
        },
        tissuesGroup: tissuesGroups[idx],
        colors,
      }))];
  });
  return sortExpressionData(newTissuesArray);
}
