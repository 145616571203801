import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AssociationScoreImg from './SetAnalysisImages/association-score.png';
import HelpText from './HelpText';
import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';

const propTypes = {
  associationScoreTerms: PropTypes.instanceOf(Array),
  rankingTypes: PropTypes.instanceOf(Object),
  rankingClickHandler: PropTypes.func,
  openRankingModal: PropTypes.func,
  literatureSearchTerms: PropTypes.instanceOf(Array),
  selectedDisease: PropTypes.instanceOf(Object),
  differentialExpressionTerm: PropTypes.instanceOf(Object),
  geneVariationTerm: PropTypes.instanceOf(Object),
};

const GeneSetOptions = (props) => {
  const {
    rankingTypes,
    selectedDisease,
    openRankingModal,
    geneVariationTerm,
    rankingClickHandler,
    literatureSearchTerms,
    associationScoreTerms,
    differentialExpressionTerm,
  } = props;

  const is191Type = selectedDisease && selectedDisease.semanticTypes && selectedDisease.semanticTypes.indexOf('T191') !== -1;
  const hallmarksOfCancerClass = classNames({ 'label-disabled': !is191Type });

  return (
    <div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="classification"
            checked={rankingTypes.classification}
            labelValue="Classification"
            readOnly={true}
          />
        </div>
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="involvementOtherDiseases"
            checked={rankingTypes.involvementOtherDiseases}
            labelValue="Involvement other diseases"
            onChange={() => rankingClickHandler('involvementOtherDiseases')}
          />
          <HelpText
            id="involvementOtherDiseasesTooltip"
            text="The diseases that a gene is associated with through annotations (so not just co-occurrence in a publication)."
          />
        </div>
      </div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="differentialExpression"
            checked={rankingTypes.differentialExpression}
            labelValue="Differential expression"
            onChange={() => rankingClickHandler('differentialExpression')}
          />
          {
            differentialExpressionTerm &&
            <span
              role="presentation"
              onClick={() => { openRankingModal('differentialExpression'); }}
              className="link association-term"
            >
              { differentialExpressionTerm.name }
            </span>
          }
          <HelpText
            id="differentialExpressionTooltip"
            text="The differential expression of the gene for a given disease, phenotype or compound
              associated with the many thousands of gene expression experiments that are available
              in the platform. The maximum result is shown in Log2fold."
          />
        </div>
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="hallmarksOfCancer"
            checked={rankingTypes.hallmarksOfCancer}
            labelValue="Hallmarks of Cancer"
            onChange={() => rankingClickHandler('hallmarksOfCancer')}
            labelCls={hallmarksOfCancerClass}
            disabled={!is191Type}
          />
        </div>
      </div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="expressionSpecificity"
            checked={rankingTypes.expressionSpecificity}
            labelValue="Expression specificity"
            onChange={() => rankingClickHandler('expressionSpecificity')}
          />
          <HelpText id="expressionSpecificityTooltip">
            <>
              <div>
                The base expression score provides an objective measure on how uniquely a gene’s RNA is expressed in
                a selected tissue. The expression specificity score compares the RNA expression of a gene with the
                10% highest expressed in tissue and the median expression value of all other tissues. It is calculated
                based on the following algorithm: <br />
              </div>
              <h4>Score = [(Et/E10th%) + (Et/Em)]</h4>
              <ul>
                <li>Et = RNA expression value in target tissue in TPM</li>
                <li>E10th% =  10th (highest) percentile expression value in all other tissues</li>
                <li>Em = median expression value in all other tissues</li>
              </ul>
            </>
          </HelpText>
        </div>
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="geneticInteractions"
            checked={rankingTypes.geneticInteractions}
            labelValue="Genetic interactions"
            onChange={() => rankingClickHandler('geneticInteractions')}
          />
          <HelpText
            id="geneticInteractionsTooltip"
            text="The number of specific molecular gene interactions a gene has at its protein level. This excludes
              generic associations, only genes with experimentally observed molecular interaction are shown."
          />
        </div>
      </div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="associationScore"
            checked={rankingTypes.associationScore}
            labelValue="Indirect Association Score"
            onChange={() => rankingClickHandler('associationScore')}
          />
          {
            associationScoreTerms?.map((term, index) => (
              <span
                onClick={() => { openRankingModal('associationScore'); }}
                className="link association-term"
                key={`${index}-${term.name}`}
              >
                { term.name }
              </span>
            )) ?? null
          }
          <HelpText id="associationScoreTooltip">
            <>
              <img
                src={AssociationScoreImg}
                alt="association score"
                className="tooltip__img"
              />
              <span>
                The Association Score provides an objective measure of the level of mutual information two concepts share.
                The Association Score ranks two concepts (whether they have a direct relation or not) in terms of their
                indirect relations, against a reference set of concepts of the same category which have direct relations. <br />
                In cases where no direct relation exists the Association Score acts as a predictive statistical
                function as it compares the level of mutual information of concepts with existing and non existing
                direct relations.
              </span>
            </>
          </HelpText>
        </div>
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="geneVariation"
            checked={rankingTypes.geneVariation}
            labelValue="Gene variation"
            onChange={() => rankingClickHandler('geneVariation')}
          />
          {
            geneVariationTerm &&
            <span
              role="presentation"
              onClick={() => { openRankingModal('geneVariation'); }}
              className="link association-term"
            >
              { geneVariationTerm.name }
            </span>
          }
          <HelpText
            id="geneVariationTooltip"
            text="This score shows whether a gene has a known clinical variant for a given disease."
          />
        </div>
      </div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="literatureSearch"
            checked={rankingTypes.literatureSearch}
            labelValue="Literature Search"
            onChange={() => rankingClickHandler('literatureSearch')}
          />
          {
            literatureSearchTerms?.map((term, index) => (
              <span
                role="presentation"
                onClick={() => { openRankingModal('literatureSearch'); }}
                className="link association-term"
                key={`${index}-${term.name}`}
              >
                { term.name }
              </span>
            )) ?? null
          }
          <HelpText
            id="literatureSearchTooltip"
            text="This score shows the number of references in which the concept from your set and the search term your entered are both mentioned."
          />
        </div>
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="compoundScore"
            checked={rankingTypes.compoundScore}
            labelValue="Associated Compounds (<100nM)"
            onChange={() => rankingClickHandler('compoundScore')}
          />
          <HelpText
            id="compoundScoreTooltip"
            text="The number of compounds with Kd/Ki < 100 nM associated with the gene."
          />
        </div>
      </div>
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="druggableStructure"
            checked={rankingTypes.druggableStructure}
            labelValue="Druggable structure"
            onChange={() => rankingClickHandler('druggableStructure')}
          />
          <HelpText id="druggableStructureTooltip">
            <>
              <span>
                A classification of a gene assigned by the Human Protein Atlas project based on analysing known drug
                target protein classes including enzymes, transporters, receptors and ion-channels. If a gene is part
                of this list, a score of ‘75’ is shown. For more background see the HPA website: <br />
              </span>
              <a href="http://www.proteinatlas.org/humanproteome/druggable" target="blank">
                http://www.proteinatlas.org/humanproteome/druggable
              </a>.
            </>
          </HelpText>
          <a
            className="option-link"
            target="blank"
            href="https://www.proteinatlas.org/humanproteome/tissue/druggable#the_druggable_proteome"
          >
            <i className="fa fa-share" />
          </a>
        </div>
      </div>
    </div>
  );
};

GeneSetOptions.propTypes = propTypes;

export default GeneSetOptions;
