import { call, put, takeEvery, select } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { getBackgroundSetConfigSelector } from '../../common/SetAnalysisMethodSelection/selectors';

import {
  findRelatedAnalysisRequestedAction,
  findRelatedAnalysisSucceededAction,
  findRelatedAnalysisFailedAction,
  findRelatedTableLoadingAction,
} from './reducer';
import { getTableSorting } from './selectors';

const roundPValue = (value) => {
  if (value >= 0.0001) return value.toPrecision(3);
  return value.toExponential(2);
};

function* findRelatedResultWorker(action) {
  try {
    yield put(findRelatedTableLoadingAction(true));
    const backgroundSetConfiguration = yield select(getBackgroundSetConfigSelector);
    const { sortBy, sortDirection } = yield select(getTableSorting);
    const requestData = action.payload;
    requestData.params = {
      ...requestData.params,
      sort: `${sortBy},${sortDirection}`,
    };
    requestData.backgroundSetConfiguration = backgroundSetConfiguration;
    const { data } = yield call(Api.findRelatedAnalysis, requestData);
    data.content = data.content.map(item => ({
      ...item,
      pValue: roundPValue(item.pValue),
    }));
    yield put(findRelatedAnalysisSucceededAction(data));
  } catch (e) {
    yield put(findRelatedAnalysisFailedAction(e.message));
  }
}

function* findRelatedAnalysisResultWatcher() {
  yield takeEvery(findRelatedAnalysisRequestedAction, findRelatedResultWorker);
}

export default findRelatedAnalysisResultWatcher;
