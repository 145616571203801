import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '../../../../Api/Api';

import { PAGE_SIZE } from './utils';
import {
  PUBLICATION_DETAILS_TRIPLES_REQUESTED,
  PUBLICATION_DETAILS_TRIPLES_FAILED,
  PUBLICATION_DETAILS_TRIPLES_SUCCEEDED,
  LOADING,
  PUBLICATION_DETAILS_TRIPLES_TABLE,
  TRIPLES_BY_IDS_REQUESTED,
} from './constants';

function* fetchPublicationDetailsTriplesSaga(action) {
  try {
    const requestData = action.data;
    requestData.publicationId = action.data.id;
    yield put({ type: PUBLICATION_DETAILS_TRIPLES_TABLE + LOADING });
    const response = yield call(Api.publicationTriples, requestData);

    const totalPages = Math.ceil((response.data || []).length / PAGE_SIZE);

    yield put({ type: PUBLICATION_DETAILS_TRIPLES_SUCCEEDED, data: { tableData: response.data, totalPages } });
  } catch (e) {
    yield put({ type: PUBLICATION_DETAILS_TRIPLES_FAILED, message: e.message });
  }
}

function* fetchTriplesByIdsSaga({ data }) {
  try {
    const { hidenSources, ids } = data;
    yield put({ type: PUBLICATION_DETAILS_TRIPLES_TABLE + LOADING });

    const response = yield call(Api.getTriplesByConceptIds, { post: ids });
    let triples = response.data || [];
    if (hidenSources && hidenSources.length > 0) {
      triples = response.data.filter(item => item.sourcesName.some(name => hidenSources.indexOf(name) === -1));
    }
    const totalPages = Math.ceil(triples.length / PAGE_SIZE);

    yield put({ type: PUBLICATION_DETAILS_TRIPLES_SUCCEEDED, data: { tableData: triples, totalPages } });
  } catch (e) {
    yield put({ type: PUBLICATION_DETAILS_TRIPLES_FAILED, message: e.message });
  }
}

function* rootSaga() {
  yield takeLatest(PUBLICATION_DETAILS_TRIPLES_REQUESTED, fetchPublicationDetailsTriplesSaga);
  yield takeLatest(TRIPLES_BY_IDS_REQUESTED, fetchTriplesByIdsSaga);
}

export default rootSaga;
