import {
  SORT,
} from '../../common/SimpleTable/constants';

import {
  CONCEPT_DETAILS_REQUESTED,
  CONCEPT_DETAILS_LOADING,
  CONCEPT_CATEGORY_ANALYSIS_REQUESTED,
  CONCEPT_CATEGORY_ANALYSIS_RESET,
  CONCEPT_CATEGORY_ANALYSIS_NODES_REQUESTED,
  CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE,
  CONCEPT_DETAILS_RESET,
  CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED,
  CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
} from './constants';

export const resetAction = () => (
  {
    type: CONCEPT_DETAILS_RESET,
  }
);

export const conceptDetailsRequest = data => (
  {
    type: CONCEPT_DETAILS_REQUESTED,
    data,
  }
);

export const loading = () => (
  {
    type: CONCEPT_DETAILS_LOADING,
  }
);

export const conceptCategoryAnalysisRequest = data => (
  {
    type: CONCEPT_CATEGORY_ANALYSIS_REQUESTED,
    data,
  }
);

export const conceptCategoryAnalysisReset = () => (
  {
    type: CONCEPT_CATEGORY_ANALYSIS_RESET,
  }
);

export const conceptCategoryAnalysisNodesRequest = data => (
  {
    type: CONCEPT_CATEGORY_ANALYSIS_NODES_REQUESTED,
    data,
  }
);

export const sort = data => (
  {
    type: CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE + SORT,
    data,
  }
);

export const getShortConceptsDetails = data => (
  {
    type: CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED,
    data,
  }
);

export const conceptDetailsSetLastVisitedConceptIdAction = data => (
  {
    type: CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
    data,
  }
);
