import { Map, fromJS } from 'immutable';
import { combineReducers } from 'redux';

import {
  CURRENT_USER_SUCCEEDED,
  CURRENT_USER_FAILED,
  ALL_CONCEPT_TYPES_SUCCEEDED,
  ALL_CONCEPT_TYPES_FAILED,
  CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED,
  CEREBRUM_SEMANTIC_CATEGORIES_FAILED,
  CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED,
  CEREBRUM_ACCESS_MAPPINGS_FAILED,
  USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED,
} from './constants';

const user = (state = new Map(), action) => {
  switch (action.type) {
    case CURRENT_USER_SUCCEEDED:
      return state.merge(fromJS({ currentUser: action.data, error: null }));
    case CURRENT_USER_FAILED:
      return state.merge(fromJS({ currentUser: null, error: action.message }));
    case USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED: {
      return state.merge(fromJS({lastEnrichmentAnalysisSelected: action.data}));
    }
    default:
      return state;
  }
};

const conceptTypesInitialValues = fromJS({ types: [] });

const conceptTypes = (state = conceptTypesInitialValues, action) => {
  switch (action.type) {
    case ALL_CONCEPT_TYPES_SUCCEEDED:
      return state.merge(fromJS({ types: action.data, error: null }));
    case ALL_CONCEPT_TYPES_FAILED:
      return state.merge(fromJS({ types: [], error: action.message }));
    default:
      return state;
  }
};

const cerebrumSemanticCategoriesInitialValues = fromJS({
  sc: [],
  st: [],
  error: null,
  accessMappings: null,
});

const cerebrumSC = (state = cerebrumSemanticCategoriesInitialValues, action) => {
  switch (action.type) {
    case CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED: {
      const semTypes = {};
      action.data.forEach((sc) => {
        sc.st.forEach((st) => { semTypes[st.id] = st.name; });
      });
      return state.merge(fromJS({ sc: action.data, st: semTypes, error: null }));
    }
    case CEREBRUM_SEMANTIC_CATEGORIES_FAILED:
      return state.merge(fromJS({ sc: [], error: action.message }));
    case CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED: {
      const mappings = {};
      action.data.forEach((am) => {
        mappings[am.id] = am.name;
      });
      return state.merge(fromJS({ accessMappings: mappings, error: null }));
    }
    case CEREBRUM_ACCESS_MAPPINGS_FAILED:
      return state.merge(fromJS({ accessMappings: {}, error: action.message }));
    default:
      return state;
  }
};

export default combineReducers({
  conceptTypes,
  user,
  cerebrumSC,
});
