import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Accordion from '../Accordion/Accordion';
import SemanticType from '../SemanticType/SemanticType';
// Constants
import { conceptTypesIdsEnum } from '../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  st: PropTypes.instanceOf(Array),
  name: PropTypes.string,
  count: PropTypes.number,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSTchange: PropTypes.func,
  allSemTypesCount: PropTypes.instanceOf(Object),
  selectedSemanticType: PropTypes.string,
  taxonomiesProps: PropTypes.instanceOf(Object),
  noInputValue: PropTypes.bool,
  noCounts: PropTypes.bool,
};

const SemanticCategory = (props) => {
  const {
    st,
    name,
    count,
    checked,
    disabled,
    onChange,
    onSTchange,
    allSemTypesCount = {},
    selectedSemanticType,
    taxonomiesProps,
    noInputValue,
    noCounts,
  } = props;

  const handleOnChange = useCallback(() => {
    onChange(name, checked);
  }, [name, checked, onChange]);

  const className = classNames({
    'concept-search-sem-category': true,
    'concept-search-sem-category_disabled': disabled,
  });

  return (
    <div className={className}>
      <Accordion
        title={`${name}${count ? ` (${count})` : ''}`}
        withCheckBox={true}
        checkBoxProps={{
          id: name,
          checked,
          onChange: handleOnChange,
          disabled,
        }}
      >
        <div className="concept-search-sem-category__content">
          {
            st.map(c => (
              <SemanticType
                key={`${c.id}-sem-type`}
                {...c}
                checked={c.checked === undefined ? selectedSemanticType === c.id : c.checked}
                onChange={onSTchange}
                count={noCounts ? 0 : allSemTypesCount[c.id]}
                disabled={!noCounts && !noInputValue && !allSemTypesCount[c.id]}
                {...(c.id === conceptTypesIdsEnum.GENE ? taxonomiesProps : {})}
              />
            ))
          }
        </div>
      </Accordion>
    </div>
  );
};

SemanticCategory.propTypes = propTypes;

export default React.memo(SemanticCategory);
