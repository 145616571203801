import { createSelector } from 'reselect';

const geneOptions = state => state.getIn(['selectDisease', 'options']);
const geneLoader = state => state.getIn(['selectDisease', 'loading']);
const geneError = state => state.getIn(['selectDisease', 'error']);

export const getDiseaseOptionSelector = createSelector(
  geneOptions,
  data => data && data.toJS()
);

export const getDiseaseLoaderSelector = createSelector(
  geneLoader,
  data => data
);

export const getDiseaseErrorSelector = createSelector(
  geneError,
  data => data
);
