import { createSelector } from 'reselect';

const activationStatus = state => state.getIn(['userActivation', 'activationStatus']);
const activationMessage = state => state.getIn(['userActivation', 'message']);
const activationLoading = state => state.getIn(['userActivation', 'loading']);

export const getActivationLoading = createSelector(
  activationLoading,
  activationLoadingData => activationLoadingData
);

export const getActivationMessage = createSelector(
  activationMessage,
  activationMessageData => activationMessageData
);

export const getActivationStatus = createSelector(
  activationStatus,
  activationStatusData => activationStatusData
);
