import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import PublicationsList from '../../../../common/PublicationsList/PublicationsList';
import PublicationDetailsTriples from '../../../../PublicationDetails/Components/PublicationDetailsTriples/PublicationDetailsTriples';
// Constants
import { CooccurrenceEnum } from '../../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  link: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  deleteCb: PropTypes.func,
  updateLinkLabel: PropTypes.func,
  conceptIds: PropTypes.instanceOf(Array),
  conceptNames: PropTypes.instanceOf(Array),
  hideRelationsDetailsInfo: PropTypes.bool,
  settings: PropTypes.instanceOf(Object),
};

const RelationDetailsModal = (props) => {
  const {
    link,
    isOpen,
    closeCb,
    deleteCb,
    conceptIds,
    conceptNames,
    updateLinkLabel,
    hideRelationsDetailsInfo,
    settings = { showCloselyRelatedPubs: false },
  } = props;
  const { showCloselyRelatedPubs, showCooccurrence } = settings;

  const getCooccurrenceType = () => {
    if (!showCloselyRelatedPubs && !showCooccurrence) return null;
    if (showCloselyRelatedPubs) return CooccurrenceEnum.SENTENCE;
    return CooccurrenceEnum.ABSTRACT;
  };

  function handleUpdateLinkLabel(e) {
    const { target: { value } } = e;
    const { linkId } = link;
    updateLinkLabel({ linkId, label: value });
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="relation-details">
        <div className="relation-details__header">
          <div className="relation-details__title title3">
            Relations and publications
          </div>
        </div>
        {
          !hideRelationsDetailsInfo &&
          <div className="relation-details__info">
            <div className="relation-details__name">
              <div className="create-set-info__label">
                Relation label
              </div>
              <input
                type="text"
                defaultValue={link.label}
                placeholder="Label"
                className="create-set-input relation-details__input"
                onChange={handleUpdateLinkLabel}
              />
            </div>
            <button
              className="btn btn-danger relation-details__delete"
              onClick={deleteCb}
            >
              Delete relation from map
            </button>
          </div>
        }
        <PublicationDetailsTriples
          exportName={
            conceptNames
              .join(' and ')
              .split(' ')
              .join('_')
          }
          conceptIds={conceptIds}
        />
        <PublicationsList
          names={conceptNames}
          ids={conceptIds}
          cooccurrenceType={getCooccurrenceType()}
        />
      </div>
    </ModalComponent>
  );
};

RelationDetailsModal.propTypes = propTypes;

export default RelationDetailsModal;
