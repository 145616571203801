import { rightFindLink } from './constants';

export const manageCheckedItem = (id, selectedIds) => {
  const newSelectedIds = [...selectedIds];
  if (selectedIds.includes(id)) {
    newSelectedIds.splice(newSelectedIds.indexOf(id), 1);
  } else {
    newSelectedIds.push(id);
  }

  return newSelectedIds;
};

export const generateRightFindLink = (pubmedId, rightFindUrlSuffix) => (
  `${rightFindLink}/order/OpenURLReceive.aspx?pmid=${pubmedId}${rightFindUrlSuffix}`
);

export const generateReprintsDeskLink = pubmedId => (
  `https://www.reprintsdesk.com/landing/po.aspx?pubmedid=${pubmedId}`
);

export const generateMultiRightFindLink = (pubmedIds, rightFindUrlSuffix) => (
  `${rightFindLink}/order/MultiCitation.aspx${pubmedIds.map((pubId, index) => `${index ? '&' : '?'}PMID_${index + 1}=${pubId}`).join('')}${rightFindUrlSuffix}`
);
