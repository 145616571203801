import { fromJS } from 'immutable';

import {
  SORT,
  CHECK_ALL,
  CHECK_ITEM,
  INVERT_SELECTION,
} from '../../../common/SimpleTable/constants';

import {
  RANK_SELECTION_SUCCEEDED,
  RANK_SELECTION_FAILED,
  RANK_SELECTION_RESULT_TABLE,
  REMOVE_CONCEPT,
  RANK_SELECTION_RESULT_TABLE_RESET,
  LOADING,
  RANK_SELECTION_CHANGE_PAGE,
  APPLY_FILTER,
  CLEAR_FILTERS,
  PAGE_SIZE,
} from './constants';

const initialValues = fromJS({
  result: {
    content: [],
    page: 0,
    totalPages: 0,
  },
  error: null,
  sorting: { sortBy: 'geneName', sortDirection: 'ASC' },
  filters: {},// {columnName: {operation: null, value: null}, ...}
  effectColumns: [],
  loading: false,
});

const AnalysisResultRanking = (state = initialValues, action) => {
  switch (action.type) {
    case RANK_SELECTION_SUCCEEDED: {
      let { data } = action;
      let { content } = data;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => (
          Object.assign(item, { id: index + 1 })
        ));
      }
      const totalPages = Math.ceil(content.length / PAGE_SIZE);
      data = Object.assign(data, { content, totalPages, page: 0 });

      return state
        .merge(fromJS({
          result: data,
          error: null,
          loading: false,
          totalPages
        }));
    }

    case RANK_SELECTION_FAILED:
      return state.merge(fromJS({ error: action.message, result: initialValues.get('result'), loading: false }));

    case RANK_SELECTION_RESULT_TABLE + SORT:
      return state.merge(fromJS({ sorting: action.data }));

    case RANK_SELECTION_RESULT_TABLE + CHECK_ALL:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: action.data }))
          ))
        ))
      ));

    case RANK_SELECTION_RESULT_TABLE + CHECK_ITEM:
      return state.update('result', result => (
        result.update('content', content => (
          content.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    case RANK_SELECTION_RESULT_TABLE + INVERT_SELECTION:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: !item.get('selected') }))
          ))
        ))
      ));

    case RANK_SELECTION_RESULT_TABLE + REMOVE_CONCEPT:
      return state.update('result', result => (
        result.update('content', content => (
          content.delete(content.findIndex(item => (
            item.get('id') === action.data
          )))
        ))
      ));

    case RANK_SELECTION_RESULT_TABLE + LOADING:
      return state.merge(fromJS({ loading: action.data }));

    case RANK_SELECTION_RESULT_TABLE_RESET:
      return state.merge(initialValues);

    case RANK_SELECTION_CHANGE_PAGE:
      return state.updateIn(['result', 'page'], () => action.data);

    case RANK_SELECTION_RESULT_TABLE + APPLY_FILTER:
      return state.updateIn(['filters', action.data.column], () => {
        return {operation: action.data.operation, value: action.data.value};
      });

    case RANK_SELECTION_RESULT_TABLE + CLEAR_FILTERS:
      return state.update('filters', () => initialValues.get('filters'));

    default:
      return state;
  }
};

export default AnalysisResultRanking;
