import React from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

import ColorPicker from '../../../graphics/ColorPicker/ColorPicker';

import './SetAnalysisSet.css';

const propTypes = {
  actions: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  set: PropTypes.instanceOf(Object),
};

class SetAnalysisSet extends React.Component {
  state = {
    active: false,
  };

  removeOverlay = () => {
    const { active } = this.state;
    if (!active) {
      this.setState({
        active: true,
      });
    }
  };

  rowRenderer = ({ index, style }) => {
    const { set: { textareaValue } } = this.props;
    return (
      <div key={`concept-${index}`} style={style} className="setanalysis-set__concept">
        {textareaValue[index]}
      </div>
    );
  };

  calcRowHeight = (width, index) => {
    const { set: { textareaValue } } = this.props;
    const averageSymbolLength = 7;
    const lineHeight = 20;
    const stringLength = textareaValue[index] ? textareaValue[index].length : 0;
    const rows = Math.ceil((stringLength * averageSymbolLength) / width);
    return rows * lineHeight;
  };

  render() {
    const {
      actions,
      index,
      set,
    } = this.props;
    const { active } = this.state;

    const cls = classNames('setanalysis-set__wrap', {
      'setanalysis-set__wrap_disabled': !active && !set.textareaValue.length,
    });

    return (
      <div className="setanalysis-set">
        <div className={cls} onClick={this.removeOverlay}>
          <div className="setanalysis-set__inputs">
            <input
              value={set.name}
              onChange={(e) => { actions.nameChanged({ index, value: e.target.value }); }}
              className="input input-text setanalysis-set__input"
              type="text"
            />
            <ColorPicker
              initialColor={set.color}
              index={index}
              colorChanged={actions.colorChanged}
            />
          </div>
          <div className="setanalysis-set__actions">
            <button className="button button-secondary setanalysis-set__btn" onClick={() => { actions.select(index); }}>
              Select set
            </button>
            <button className="button button-secondary setanalysis-set__btn" onClick={() => { actions.upload(index); }}>
              <i className="fa fa-upload" />
            </button>
            <button className="button button-secondary setanalysis-set__btn" onClick={() => { actions.remove(index); }}>
              <i className="fa fa-trash" />
            </button>
          </div>
          {
            set.textareaValue.length > 0 &&
            <div className="setanalysis-set__concepts">
              <AutoSizer disableHeight={true}>
                {({ width }) => (
                  <List
                    width={width}
                    height={200}
                    rowCount={set.textareaValue.length}
                    rowHeight={({ index: rowIndex }) => this.calcRowHeight(width, rowIndex)}
                    rowRenderer={this.rowRenderer}
                  />
                )}
              </AutoSizer>
            </div>
          }
        </div>
      </div>
    );
  }
}

SetAnalysisSet.propTypes = propTypes;

export default SetAnalysisSet;
