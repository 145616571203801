import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

export const suggestUnresolvedConceptsRequestedAction = createAction('@@unresolvedConcepts/SUGGEST_REQUESTED');
export const suggestUnresolvedConceptsSucceededAction = createAction('@@unresolvedConcepts/SUGGEST_SUCCEEDED');
export const suggestUnresolvedConceptsFailedAction = createAction('@@unresolvedConcepts/SUGGEST_FAILED');

const initialState = fromJS({
  data: [],
  error: null,
  loading: false,
});

const reducer = handleActions(
  {
    [suggestUnresolvedConceptsRequestedAction]: state =>
      state
        .update('error', () => initialState.get('error'))
        .update('loading', () => true),
    [suggestUnresolvedConceptsSucceededAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('loading', () => false),
    [suggestUnresolvedConceptsFailedAction]: (state, { payload }) =>
      state
        .update('error', () => payload)
        .update('loading', () => false),
  },
  initialState
);

export default reducer;
