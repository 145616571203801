import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { proteinExpressionSortingOptions, sortingHpaOptions, sortingOptions, visibleHpaChartEnums } from './constants';

export const geneExpressionInitRequest = createAction('@@gene-details/GENE_EXPRESSION_INIT_REQUEST');
export const geneExpressionInitSucceeded = createAction('@@gene-details/GENE_EXPRESSION_INIT_SUCCEEDED');
export const geneExpressionInitFailed = createAction('@@gene-details/GENE_EXPRESSION_INIT_FAILED');
export const geneExpressionInitReset = createAction('@@gene-details/GENE_EXPRESSION_INIT_RESET');
export const geneDetailsHealthCellExpressionInitSucceeded = createAction('@@gene-details/HEALTH_CELL_INIT_SUCCEEDED');
export const setSelectedSortingOptionAction = createAction('@@expression-data/SET_SELECTED_SORTING_OPTION');
export const setSelectedUniprotOptionAction = createAction('@@expression-data/SET_SELECTED_UNIPROT_OPTION');
export const setSelectedProteinExpressionOptionAction = createAction('@@expression-data/SET_SELECTED_PROTEIN_EXPRESSION_OPTION');
// HPA
export const HPADataSucceeded = createAction('@@gene-details/HPA_DATA_SUCCEEDED');
export const HPADataFailed = createAction('@@gene-details/HPA_DATA_FAILED');
export const setVisibleHpaChartOptionAction = createAction('@@gene-details/HPA__VISIBLE_CHART');
export const setSelectedHpaSortingOptionAction = createAction('@@gene-details/HPA_PER_CELL_TYPES_DATA_SET_SORTING_OPTION');
export const setSelectedHpaFilteringOptionsAction = createAction('@@gene-details/HPA__PER_CELL_TYPES_DATA_SET_FILTERING_OPTIONS');

export const setSelectedHpaPerTissuesSortingOptionAction = createAction('@@gene-details/HPA_PER_TISSUES_DATA_SET_SORTING_OPTION');
export const setSelectedHpaPerTissuesFilteringOptionsAction = createAction('@@gene-details/HPA_PER_TISSUES_DATA_SET_FILTERING_OPTIONS');
export const setSelectedHpaPerTissuesFilteringPerCellTypeOptionsAction = createAction('@@gene-details/HPA_PER_TISSUES_DATA_SET_FILTERING_PER_CELL_TYPE_OPTIONS');
// CPTAC
export const CPTACDataSucceeded = createAction('@@gene-details/CPTAC_DATA_SUCCEEDED');
export const CPTACDataFailed = createAction('@@gene-details/CPTAC_DATA_FAILED');
// csv
export const getSamplesDataForCsvExportAction = createAction('@@expression-data/GET_SAMPLES_DATA_FOR_CSV_EXPORT');
export const toggleCsvDownloadedSamplesLoadingAction = createAction('@@expression-data/TOGGLE_DOWNLOADED_CSV_LOADING');

const initialState = fromJS({
  GTEX: {
    data: [],
    selectedSortingOption: sortingOptions[0],
  },
  CCLE: {
    data: [],
    selectedSortingOption: sortingOptions[0],
  },
  CCLE_PROTEOMICS: {
    data: [],
    selectedUniprot: '',
    selectedSortingOption: sortingOptions[0],
  },
  SANGER_CELL_MODEL: {
    data: [],
    selectedUniprot: '',
    selectedProteinExpressionOption: proteinExpressionSortingOptions[0],
    selectedSortingOption: sortingOptions[0],
  },
  TCGA: {
    data: [],
    selectedSortingOption: sortingOptions[0],
  },
  HPA: {
    error: null,
    loading: false,
    visibleChart: visibleHpaChartEnums.CELL_TYPE,
  },
  HPA_PER_CELL_TYPES: {
    cellTypesData: [],
    cellTypeGroups: [],
    cellTypeGroupsWithColors: {},
    selectedSortingOption: sortingHpaOptions[0],
    selectedFilteringOptions: [],
  },
  HPA_PER_TISSUES: {
    tissuesData: [],
    cellTypes: [],
    cellTypeGroups: [],
    cellTypeGroupsWithColors: {},
    selectedFilteringPerCellTypeGroupsOptions: [],
    selectedFilteringPerCellTypeOptions: [],
    selectedSortingOption: sortingHpaOptions[0],
  },
  CELL_TYPE: [],
  healthCellData: {
    healthCellExpression: [],
    healthCellExpressionChartData: [],
    cellTypeOptions: [],
  },
  CPTAC: {
    loading: false,
    data: [],
    tumorTissueData: [],
    healtyTissueData: [],
    selectedSortingOption: sortingOptions[1],
  },
  error: null,
  loading: false,
  csvDownloadedSamplesLoading: {
    GTEX: false,
    TCGA: false,
    CCLE: false,
    CELL_TYPE: false,
    CCLE_PRETEOMICS: false,
    SANGER_CELL_MODEL: false,
    HPA: false,
  },
});

const geneDetailsGeneExpressionChapter = handleActions({
  [geneExpressionInitRequest]: state =>
    state
      .update('loading', () => true)
      .updateIn(['HPA', 'loading'], () => true)
      .updateIn(['CPTAC', 'loading'], () => true),
  [geneExpressionInitSucceeded]: (state, { payload }) => {
    const {
      hpmTissues,
      gtexExpression,
      сcleExpression,
      tcgaExpression,
      ccleProteomicExpressions,
      sangerCellModelExpressions,
    } = payload;

    return state
      .updateIn(['GTEX', 'data'], () => fromJS(gtexExpression))
      .updateIn(['CCLE', 'data'], () => fromJS(сcleExpression))
      .update('CELL_TYPE', () => fromJS(hpmTissues))
      .updateIn(['CCLE_PROTEOMICS', 'data'], () => fromJS(ccleProteomicExpressions))
      .updateIn(['CCLE_PROTEOMICS', 'selectedUniprot'], () => fromJS(ccleProteomicExpressions[0] && ccleProteomicExpressions[0].uniprot))
      .updateIn(['SANGER_CELL_MODEL', 'data'], () => fromJS(sangerCellModelExpressions))
      .updateIn(['SANGER_CELL_MODEL', 'selectedUniprot'], () => fromJS(sangerCellModelExpressions[0] && sangerCellModelExpressions[0].uniprot))
      .updateIn(['TCGA', 'data'], () => fromJS(tcgaExpression))
      .update('loading', () => false)
      .update('error', () => null);
  },
  [geneDetailsHealthCellExpressionInitSucceeded]: (state, { payload }) =>
    state
      .update('healthCellData', () => fromJS(payload)),
  [setSelectedSortingOptionAction]: (state, { payload }) =>
    state
      .updateIn([payload.type, 'selectedSortingOption'], () => fromJS(payload.option)),
  [setSelectedUniprotOptionAction]: (state, { payload }) =>
    state
      .updateIn([payload.type, 'selectedUniprot'], () => fromJS(payload.option.value)),
  [setSelectedProteinExpressionOptionAction]: (state, { payload }) =>
    state
      .updateIn([payload.type, 'selectedProteinExpressionOption'], () => fromJS(payload.option)),
  [geneExpressionInitFailed]: (state, { payload }) =>
    state
      .update('GTEX', () => initialState.get('GTEX'))
      .update('CCLE', () => initialState.get('CCLE'))
      .update('TCGA', () => initialState.get('TCGA'))
      .update('CELL_TYPE', () => initialState.get('CELL_TYPE'))
      .update('CCLE_PROTEOMICS', () => initialState.get('CCLE_PROTEOMICS'))
      .update('SANGER_CELL_MODEL', () => initialState.get('SANGER_CELL_MODEL'))
      .update('loading', () => false)
      .update('error', () => payload),
  [HPADataSucceeded]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_CELL_TYPES', 'cellTypesData'], () => fromJS(payload.cellTypesData))
      .updateIn(['HPA_PER_CELL_TYPES', 'cellTypeGroupsWithColors'], () => fromJS(payload.cellTypeGroupsWithColors))
      .updateIn(['HPA_PER_CELL_TYPES', 'cellTypeGroups'], () => fromJS(payload.cellTypeGroupsForHpaPerCellTypes))
      .updateIn(['HPA_PER_TISSUES', 'tissuesData'], () => fromJS(payload.tissuesData))
      .updateIn(['HPA_PER_TISSUES', 'cellTypes'], () => fromJS(payload.cellTypesForHpaPerTissues))
      .updateIn(['HPA_PER_TISSUES', 'cellTypeGroups'], () => fromJS(payload.cellTypeGroupsForHpaPerTissues))
      .updateIn(['HPA_PER_TISSUES', 'cellTypeGroupsWithColors'], () => fromJS(payload.cellTypeGroupsWithColorsForHpaPerTissues))
      .updateIn(['HPA', 'loading'], ()=> false),
  [setVisibleHpaChartOptionAction]: (state, { payload }) =>
    state
      .updateIn(['HPA', 'visibleChart'], () => fromJS(payload)),
  [setSelectedHpaSortingOptionAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_CELL_TYPES', 'selectedSortingOption'], () => fromJS(payload)),
  [setSelectedHpaFilteringOptionsAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_CELL_TYPES', 'selectedFilteringOptions'], () => fromJS(payload)),
  [setSelectedHpaPerTissuesSortingOptionAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_TISSUES', 'selectedSortingOption'], () => fromJS(payload)),
  [setSelectedHpaPerTissuesFilteringOptionsAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_TISSUES', 'selectedFilteringPerCellTypeGroupsOptions'], () => fromJS(payload)),
  [setSelectedHpaPerTissuesFilteringPerCellTypeOptionsAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_TISSUES', 'selectedFilteringPerCellTypeOptions'], () => fromJS(payload)),
  [setSelectedHpaPerTissuesFilteringPerCellTypeOptionsAction]: (state, { payload }) =>
    state
      .updateIn(['HPA_PER_TISSUES', 'selectedFilteringPerCellTypeOptions'], () => fromJS(payload)),
  [HPADataFailed]: (state, { payload }) =>
    state
      .updateIn(['HPA', 'loading'], () => false)
      .updateIn(['HPA', 'error'], () => payload),
  [CPTACDataFailed]: (state, { payload }) =>
    state
      .updateIn(['CPTAC', 'loading'], () => false)
      .updateIn(['CPTAC', 'error'], () => payload),
  [CPTACDataSucceeded]: (state, { payload }) =>
    state
      .updateIn(['CPTAC', 'loading'], () => false)
      .updateIn(['CPTAC', 'data'], () => fromJS(payload.data))
      .updateIn(['CPTAC', 'tumorTissueData'], () => fromJS(payload.tumorTissueData))
      .updateIn(['CPTAC', 'healtyTissueData'], () => fromJS(payload.healtyTissueData)),
  [toggleCsvDownloadedSamplesLoadingAction]: (state, { payload }) =>
    state
      .updateIn(['csvDownloadedSamplesLoading', payload.type], () => payload.status),
  [geneExpressionInitReset]: state =>
    state
      .merge(initialState),
},
initialState
);
export default geneDetailsGeneExpressionChapter;
