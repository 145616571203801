import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
// Utils
import {
  getParentBlockWidth,
  exportToPNG,
  exportToSVG,
  capitalizeFirstLetter,
} from '../../Utils/Utils';
// Styles
import './BarChartDiagram.scss';

const propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  dataKey: PropTypes.string,
  dataNameKey: PropTypes.string,
  source: PropTypes.string,
  xTooltipKey: PropTypes.string,
  color: PropTypes.instanceOf(Object),
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  geneName: PropTypes.string,
  chartName: PropTypes.string,
  type: PropTypes.string,
  chartTitle: PropTypes.string,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  yAxisLabel: PropTypes.string,
  setShortConceptCardId: PropTypes.func,
};

const defaultColor = {
  positive: 'dodgerblue',
  negative: 'dodgerblue',
};

const BarChartDiagram = (props) => {
  const {
    data,
    dataKey,
    dataNameKey,
    geneName,
    yAxisLabel,
    source = 'bar-chart-source',
    chartName = 'Bar chart',
  } = props;
  const tooltipClassName = `chart-tooltip-bar_${source}`;

  const defaultSize = {
    width: 1200,
    height: source === 'expression-TPM' ? 750 : 700,
  };

  const classes = classNames({
    'bar-chart': true,
    [source]: true,
  });

  function initBarChart(showTitle = false) {
    const {
      type,
      color,
      chartWidth,
      chartHeight,
      chartTitle,
      xTooltipKey,
      setShortConceptCardId,
      showTooltip,
      hideTooltip,
    } = props;
    const { d3 } = window;
    const bodyHtml = document.querySelector('body');
    const parentBlockWidth = getParentBlockWidth('gene-details-section-body');

    const chartData = data.map(d => ({
      ...d,
      ...(dataKey && { value: d[dataKey] }),
      ...(dataNameKey && { name: d[dataNameKey] || d.tissueName || d.primaryDiagnosis }), // TODO fix when backend will unify format CEREBRUM-4161
    }));

    if (parentBlockWidth) {
      defaultSize.width = parentBlockWidth - 25;
    }

    if (chartWidth) {
      defaultSize.width = chartWidth + 300;
    }

    const margin = {
      top: 20,
      right: 120,
      // eslint-disable-next-line no-nested-ternary
      bottom: type === 'antibodies' ? 180 : source === 'expression-TPM' ? 380 : 300,
      left: -10,
    };
    const { width } = defaultSize;
    const height = (chartHeight || defaultSize.height) - margin.top - margin.bottom;
    const chartColor = color || defaultColor;
    const styles = '.axis text {font: 16px sans-serif;} .axis path, .axis line {fill: none;stroke: #888;stroke-width: 2px;shape-rendering: crispEdges;}';

    const x = d3.scale.ordinal()
      .rangeRoundBands([0, width - 2 * margin.right - 2 * margin.left], 0.1); // eslint-disable-line no-mixed-operators

    const y = d3.scale.linear()
      .range([height, 20]);

    const xAxis = d3.svg.axis()
      .scale(x)
      .tickSize(1)
      .orient('bottom');

    const yAxis = d3.svg.axis()
      .scale(y)
      .tickSize(1)
      .orient('left');

    d3.select(`.bar-chart.${source}`)
      .select('svg')
      .remove();

    const chart = d3.select(`.bar-chart.${source}`).append('svg')
      .attr('class', 'bar-chart-diagram')
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.top + margin.bottom + 20);

    d3.select(`.bar-chart.${source}`).select('svg')
      .append('style')
      .text(styles);

    if (showTitle && chartTitle) {
      d3.select(`.bar-chart.${source}`)
        .select('svg')
        .style('padding', '0px')
        .style('padding-top', '10px')
        .style('height', height + 220)
        .insert('text', ':first-child')
        .text(`${chartTitle} for ${geneName}`)
        .attr('style', 'font: 20px sans-serif;transform: translate(50%, 15px);text-anchor: middle;');
    }

    const tooltip = d3.select('body')
      .append('div')
      .style('opacity', 0)
      .attr('class', `chart-tooltip-bar ${tooltipClassName}`);

    function getBarTooltipTemplate(d) {
      return (`
        <div class="chart-tooltip-bar__content">
          <b>${d.value}</b>
        </div>
      `);
    }

    function getBarXTooltipTemplate(d) {
      return (`
        <div class="chart-tooltip-bar__content">
          <span><b>${d.name}</b></span>
          <span>${d[xTooltipKey]}</span>
        </div>
      `);
    }

    function getTextTooltipTemplate(d) {
      return (`
        <div class="chart-tooltip-bar__content">
          <b>${d}</b>
        </div>
      `);
    }

    function mouseOver() {
      tooltip
        .style('opacity', 1);
    }

    function mouseMoveBar(d) {
      tooltip
        .html(getBarTooltipTemplate(d))
        .style('left', `${d3.mouse(bodyHtml)[0]}px`)
        .style('top', `${d3.mouse(bodyHtml)[1]}px`);
    }

    function mouseMoveBarX(d) {
      tooltip
        .html(getBarXTooltipTemplate(d))
        .style('left', `${d3.mouse(bodyHtml)[0]}px`)
        .style('top', `${d3.mouse(bodyHtml)[1]}px`);
    }

    function mouseMoveText(d) {
      tooltip
        .html(getTextTooltipTemplate(d))
        .style('left', `${d3.mouse(bodyHtml)[0]}px`)
        .style('top', `${d3.mouse(bodyHtml)[1]}px`);
    }

    function mouseLeave() {
      tooltip
        .style('opacity', 0);
    }

    function showTooltipHandler(d) {
      const { id } = d;
      const config = {
        uniqueKey: 'barChart-tooltip',
        clientX: d3.event.x,
        clientY: d3.event.y,
      };
      setShortConceptCardId(id);
      showTooltip(config);
    }

    function hideTooltipHandler() {
      hideTooltip();
    }

    const minY = d3.min(chartData, d => d.value);
    const maxY = d3.max(chartData, d => d.value);

    x.domain(chartData.map(d => capitalizeFirstLetter(d.name)));
    y.domain([minY < 0 ? minY : 0, maxY]);

    chart.xAxis = chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.right},${(height)})`)
      .call(xAxis);

    chart.xAxis.selectAll('text')
      .attr('x', 15)
      .attr('y', 0)
      .attr('transform', 'rotate(45)')
      .style('font-size', '16px')
      .style('text-anchor', 'start')
      .style('cursor', type === 'antibodies' ? 'auto' : 'pointer')
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMoveText)
      .on('mouseleave', mouseLeave);

    if (showTooltip) {
      chart.xAxis.selectAll('text')
        .on('mouseover', (d, i) => showTooltipHandler(chartData[i]))
        .on('mouseout', hideTooltipHandler);
    }

    chart.xAxis.selectAll('line')
      .attr('y2', 10);

    chart.yAxis = chart.append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${margin.right},0)`)
      .call(yAxis);

    chart.yAxis.selectAll('text')
      .attr('x', -11)
      .style('font-size', '16px')
      .attr('letter-spacing', '0.5')
      .style('cursor', type === 'antibodies' ? 'auto' : 'pointer');

    chart.yAxis.selectAll('line')
      .attr('x2', -10);

    if (yAxisLabel) {
      chart.yAxis.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('dy', '-3em')
        .attr('letter-spacing', '0.5')
        .style('text-anchor', 'middle')
        .style('font-size', '16px')
        .style('fill', 'black')
        .text(yAxisLabel);
    }

    if (type === 'antibodies') {
      chart.select('.y.axis')
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', `-${(height + margin.top) / 2}`)
        .attr('dy', '-72px')
        .style('text-anchor', 'middle')
        .style('font-size', '16px')
        .style('font-weight', '300')
        .style('fill', 'black')
        .text('Number of Antibodies');
    }

    chart.selectAll('.bar')
      .data(chartData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => (x(capitalizeFirstLetter(d.name))))
      .attr('y', d => (d.value >= 0 ? y(d.value) : y(0)))
      .attr('height', d => Math.abs(y(d.value) - y(0)))
      .attr('width', x.rangeBand())
      .attr('transform', `translate(${margin.right},0)`)
      .style('fill', d => (d.value >= 0 ? chartColor.positive : chartColor.negative))
      .style('cursor', type === 'antibodies' ? 'auto' : 'pointer')
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMoveBar)
      .on('mouseleave', mouseLeave);

    if (xTooltipKey) {
      chart.selectAll('.x .tick')
        .data(chartData)
        .on('mouseover', mouseOver)
        .on('mousemove', mouseMoveBarX)
        .on('mouseleave', mouseLeave);
    }
  }

  function exportToPNGCb() {
    initBarChart(true);
    const diagram = window.document.querySelector(`.${source} svg`);
    const name = `${geneName}_${chartName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, false, source === 'expression-TPM');
    }
    initBarChart();
  }

  function exportToSVGCb() {
    initBarChart(true);
    const container = window.document.querySelector(`.${source}`);
    const name = `${geneName}_${chartName}.svg`;
    if (container) {
      exportToSVG(container, name, false, false, false, source === 'expression-TPM');
    }
    initBarChart();
  }

  useEffect(() => {
    initBarChart();
  }, [data]);

  useEffect(() => () => {
    const tooltip = document.querySelector(`.${tooltipClassName}`);
    if (tooltip) {
      tooltip.remove();
    }
  }, []);

  return (
    <>
      <div className="bar-chart-controls">
        <div className="bar-chart-export-button-wrapper">
          <div
            className="export-button bar-chart-controls__png"
            onClick={exportToPNGCb}
            title="Export to png"
          />
          PNG
        </div>
        <div className="bar-chart-export-button-wrapper">
          <div
            className="export-button"
            onClick={exportToSVGCb}
            title="Export to svg"
          />
          SVG
        </div>
      </div>
      <div className={classes} />
      <Tooltip uniqueKeyProp="barChart-tooltip">
        <ShortConceptCard />
      </Tooltip>
    </>
  );
};

BarChartDiagram.propTypes = propTypes;

export default React.memo(BarChartDiagram);
