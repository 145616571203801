import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import isEmpty from 'lodash.isempty';

// Utils
import { formatDate, AVERAGE_SYMBOL_LENGTH } from '../../Utils/Utils';
// Components
import NoData from '../../common/NoData/NoData';
import ExportTable from '../../common/ExportTable/ExportTable';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationDetails from '../../PublicationDetails/PublicationDetails';
// Store
import { loadNextChapterAction } from '../actions';
import {
  setFuncCharacterizationTableDataAction,
  sortFuncCharacterizationTableAction,
} from './redux/actions';
import {
  getPageNumber,
  getTotalPages,
  getSectionTable,
  tableSorting,
} from './redux/selectors';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  getTableData: PropTypes.func.isRequired,
  sectionTable: PropTypes.instanceOf(Object).isRequired,
  sorting: PropTypes.instanceOf(Object).isRequired,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  width: PropTypes.number,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
};

class FunctionalCharacterizationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPublicationDetailsModal: false,
      pubmedId: null,
    };
    this.simpleTableRef = React.createRef(null);
    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      getTableData,
      itsNewConcept,
      loadNextChapter,
      sectionTable: {
        content,
      },
    } = this.props;

    if (itsNewConcept || isEmpty(content)) {
      getTableData({ id: gene });
    } else {
      loadNextChapter('pathways');
    }
  };

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (pageNumber) => {
    const {
      gene,
      getTableData,
    } = this.props;

    getTableData({
      id: gene,
      params: {
        size: 10,
        page: pageNumber.selected,
      },
    });
  };


  getAllTableDataForExport = (link, columns) => {
    const {
      gene,
      geneName,
      getTableData,
      sectionTable,
    } = this.props;

    getTableData({
      id: gene,
      export: true,
      params: {
        size: sectionTable.totalElements,
        page: 0,
      },
    }, {
      link,
      columns,
      geneName,
    });
  };

  handleModalClose = () => {
    this.setState({
      pubmedId: null,
      showPublicationDetailsModal: false,
    });
  };

  goToPublicationDetails = (data) => {
    this.setState({
      pubmedId: data,
      showPublicationDetailsModal: true,
    });
  };

  handlePageSort = () => {
    const {
      gene,
      pageNumber,
      getTableData,
    } = this.props;

    getTableData({
      id: gene,
      params: {
        size: 10,
        page: pageNumber,
      },
    });
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  getRowHeight = ({ index }) => {
    const { content } = this.props.sectionTable;
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(40);
    const string = content[index].geneRifText.length;
    const stringRows = Math.ceil((string * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    const indicationsCount = content[index].categories ? content[index].categories.length : 0;

    return indicationsCount > stringRows ?
      (indicationsCount * lineHeight) + padding :
      (stringRows * lineHeight) + padding;
  };

  render() {
    const {
      sectionTable: {
        content,
      },
      pageNumber,
      totalPages,
      sorting: {
        sortBy,
        sortDirection,
      },
      width,
    } = this.props;
    const {
      showPublicationDetailsModal,
      pubmedId,
    } = this.state;

    const tableSettings = {
      width,
      height: 800,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Description',
        dataKey: 'geneRifText',
        exportCSV: true,
        width: this.getColumnPercentWidth(40),
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <span
            role="presentation"
            title={rowData.geneRifText}
          >
            {rowData.geneRifText}
          </span>
        ),
      },
      {
        label: 'Type',
        dataKey: 'categories',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        className: 'flexColumn',
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          const { categories } = rowData;
          return categories && categories.map(
            (category, i) => (
              <div
                title={categories.join(', ')}
                className="cell-row"
                key={`${category}_${i}`}
              >
                {category}
              </div>
            )
          );
        },
      },
      {
        label: 'Date',
        dataKey: 'publicationDate',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        cellRenderer: ({ rowData }) => {
          const publicationDate = formatDate(rowData.publicationDate);
          return (
            <div className="cell-row text-center">{publicationDate}</div>
          );
        },
        csvRenderer: rowData => formatDate(rowData),
      },
      {
        label: 'Pubmed',
        dataKey: 'pubmedId',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <span
            role="presentation"
            className="cell-row text-center link"
            onClick={
              () => {
                this.goToPublicationDetails(rowData.pubmedId);
              }
            }
          >
            {rowData.pubmedId}
          </span>
        ),
      },
    ];

    return (
      <div className="gene-detail-func-characterization-table">
        {
          content && content.length > 0 &&
          <>
            <div className="controls-block-2items">
              <div className="paginationContainer">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakClassName="break-me"
                  pageCount={totalPages}
                  forcePage={pageNumber}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
              <ExportTable
                backendCall={(link) => { this.getAllTableDataForExport(link, columns); }}
              />
            </div>
            <SimpleTable
              innerRef={this.simpleTableRef}
              settings={tableSettings}
              data={content}
              columns={columns}
              sortAction={sortFuncCharacterizationTableAction}
              serverSortAction={this.handlePageSort}
              dynamicHeight={true}
            />
          </>
        }
        <NoData
          show={content && content.length === 0}
          customClassName="gene-details__no-data"
        />
        {
          showPublicationDetailsModal &&
          <ModalComponent
            isOpen={showPublicationDetailsModal}
            closeCb={this.handleModalClose}
            customClassName="publication-details__popup"
          >
            <PublicationDetails
              pubmedId={pubmedId}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

FunctionalCharacterizationTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    sectionTable: getSectionTable(state),
    sorting: tableSorting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTableData(data, exportData) {
      dispatch(setFuncCharacterizationTableDataAction(data, exportData));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunctionalCharacterizationTable);
