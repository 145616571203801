import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './styles.scss';

const propTypes = {
  isLoading: PropTypes.bool,
  withOverlay: PropTypes.bool,
};

const Loader = (props) => {
  const { isLoading, withOverlay } = props;

  if (!isLoading) {
    return null;
  }

  const className = classNames({
    'loader-wrap': true,
    'loader-wrap_active': isLoading,
  });

  const renderLoader = () => (
    <div className={className} data-testid="loader-testid">
      <div className="loader">
        <div>
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <div>
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );

  if (withOverlay) {
    return (
      <div className="loader__overlay">
        {renderLoader()}
      </div>
    );
  }

  return renderLoader();
};

Loader.propTypes = propTypes;

export default React.memo(Loader);
