import {
  ADD_SET_TO_SET_ANALYSIS,
  REMOVE_SET_FROM_SET_ANALYSIS,
  RESET_SETS_AT_SET_ANALYSIS,
  SET_COLOR_CHANGE,
  SET_NAME_CHANGE,
  SELECT_SET_FOR_SET_ANALYSIS,
  SET_ANALYTICS_TITLE,
  ANALYTICS_SAVE_REQUESTED,
  ANALYTICS_LOADING,
  ANALYTICS_INIT_REQUESTED,
  ANALYTICS_SET_COMMENT,
  SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL,
  RESET_LAST_SAVE_ANALYTICS_ID,
  SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE,
  SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE,
  RESET_ANALYTICS_TITLE,
  SET_SET_ANALYSIS_ID,
  SET_ANALYTICS_LOADING_FLAG,
  SET_ANALYSIS_UPDATE_TAGS,
  INIT_SETS_TO_SET_ANALYSIS,
  SWITCH_METHOD_SELECTION_LOADING_FLAG,
  PUT_UPLOADED_FILE_TO_SET,
  GET_FILE_DATA_FOR_SET_ANALYSIS,
} from './constants';

export const setShouldClearAllSetsFlagToTrueAction = () => (
  {
    type: SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE,
  }
);

export const toggleSetAnalyticsLoaderAction = data => (
  {
    type: SET_ANALYTICS_LOADING_FLAG,
    data,
  }
);

export const setShouldClearAllSetsFlagToFalseAction = () => (
  {
    type: SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE,
  }
);

export const resetLastSavedAnalyticsIdAction = () => (
  {
    type: RESET_LAST_SAVE_ANALYTICS_ID,
  }
);

export const initAnalytics = data => (
  {
    type: ANALYTICS_INIT_REQUESTED,
    data,
  }
);

export const analyticsLoading = data => (
  {
    type: ANALYTICS_LOADING,
    data,
  }
);

export const setAnalyticsComment = data => (
  {
    type: ANALYTICS_SET_COMMENT,
    data,
  }
);

export const saveAnalyticsAction = data => (
  {
    type: ANALYTICS_SAVE_REQUESTED,
    data,
  }
);

export const setAnalysisUpdateTagsAction = data => (
  {
    type: SET_ANALYSIS_UPDATE_TAGS,
    data,
  }
);

export const setAnalyticsTitle = data => (
  {
    type: SET_ANALYTICS_TITLE,
    data,
  }
);

export const resetAnalyticsTitleAction = () => (
  {
    type: RESET_ANALYTICS_TITLE,
  }
);

export const addSetToSetAnalysis = () => (
  {
    type: ADD_SET_TO_SET_ANALYSIS,
  }
);

export const removeSetFromSetAnalysis = data => (
  {
    type: REMOVE_SET_FROM_SET_ANALYSIS,
    data,
  }
);

export const clearAllSetsAction = () => (
  {
    type: RESET_SETS_AT_SET_ANALYSIS,
  }
);

export const changeColor = data => (
  {
    type: SET_COLOR_CHANGE,
    data,
  }
);

export const nameChanged = data => (
  {
    type: SET_NAME_CHANGE,
    data,
  }
);

export const selectSet = data => (
  {
    type: SELECT_SET_FOR_SET_ANALYSIS,
    data,
  }
);

export const selectSetWithAPICallAction = data => (
  {
    type: SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL,
    data,
  }
);

export const setSetAnalysisIdAction = data => (
  {
    type: SET_SET_ANALYSIS_ID,
    data,
  }
);

export const switchMethodSelectionLoadingFlagAction = data => (
  {
    type: SWITCH_METHOD_SELECTION_LOADING_FLAG,
    data,
  }
);

export const initSetsToSetAnalysis = data => (
  {
    type: INIT_SETS_TO_SET_ANALYSIS,
    data,
  }
);

export const setSetAnalysisPutUploadedSetAction = data => (
  {
    type: PUT_UPLOADED_FILE_TO_SET,
    data,
  }
);

export const getFileDataForSetAnalysisAction = payload => (
  {
    type: GET_FILE_DATA_FOR_SET_ANALYSIS,
    payload,
  }
);
