import { types } from './constants';

export const getFuncCharacterizationChartDataAction = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_GET_CHART_DATA,
    data,
  }
);

export const setFuncCharacterizationChartDataAction = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_SET_CHART_DATA,
    data,
  }
);

export const funcCharacterizationChartIsFailed = message => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_FAILED,
    message,
  }
);

export const funcCharacterizationChartLoader = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_LOADER,
    data,
  }
);

export const getFuncCharacterizationTableDataAction = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_GET_TABLE_DATA,
    data,
  }
);

export const setFuncCharacterizationTableDataAction = (data, exportData) => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_SET_TABLE_DATA,
    data,
    exportData,
  }
);

export const filterFuncCharacterizationTableAction = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_FILTER_TABLE,
    data,
  }
);

export const sortFuncCharacterizationTableAction = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_SORT_TABLE,
    data,
  }
);

export const funcCharacterizationTableIsFailed = message => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_FAILED,
    message,
  }
);

export const funcCharacterizationTableLoader = data => (
  {
    type: types.GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_LOADER,
    data,
  }
);
