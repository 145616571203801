import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';

import {
  scrollToBottom,
} from '../../Utils/Utils';

import './BilevelPartitionDiagram.css';

const propTypes = {
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  selectTypeCb: PropTypes.func,
};

class BilevelPartitionDiagram extends React.Component {
  static WIDTH = 750;
  static HEIGHT = 750;

  componentDidMount() {
    this.initDiagram();
    scrollToBottom('body');
  }

  componentWillUnmount() {
    window.d3.select('#diagramTooltip').remove();
  }

  initDiagram = () => {
    const diagramData = cloneDeep(this.props.data);
    const selectSTCb = this.props.selectTypeCb;

    const margin = {
      top: BilevelPartitionDiagram.HEIGHT / 2,
      right: BilevelPartitionDiagram.WIDTH / 2,
      bottom: BilevelPartitionDiagram.HEIGHT / 2,
      left: BilevelPartitionDiagram.WIDTH / 2,
    };

    const radius = Math.min(margin.top, margin.right, margin.bottom, margin.left) - 10;

    function filter_min_arc_size_text(d) { // eslint-disable-line
      return (d.dx * d.depth * radius / 3) > 14; // eslint-disable-line
    }

    const hue = window.d3.scale.category10();

    const luminance = window.d3.scale.sqrt()
      .domain([0, 1e6])
      .clamp(true)
      .range([90, 20]);

    const svg = window.d3.select('.bilevel-partition-diagram')
      .append('svg')
      .attr('width', margin.left + margin.right)
      .attr('height', margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const partition = window.d3.layout.partition()
      .sort(function(a, b) { return window.d3.ascending(a.name, b.name); }) // eslint-disable-line
      .size([2 * Math.PI, radius]);

    const arc = window.d3.svg.arc()
      .startAngle(function(d) { return d.x; }) // eslint-disable-line
      .endAngle(function(d) { return d.x + d.dx - .01 / (d.depth + .5); }) // eslint-disable-line
      .innerRadius(function(d) { return radius / 3 * d.depth; }) // eslint-disable-line
      .outerRadius(function(d) { return radius / 3 * (d.depth + 1) - 1; }); // eslint-disable-line

    // Tooltip description
    const tooltip = window.d3.select('body')
      .append('div')
      .attr('id', 'diagramTooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('opacity', 0);

    let timeOut;

    function format_number(x) { // eslint-disable-line
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // eslint-disable-line
    }


    function format_description(d) { // eslint-disable-line
      return  '<b>' + d.name + '</b><br> (' + format_number(d.value) + ')'; // eslint-disable-line
    }

    function computeTextRotation(d) {
      return (d.x + d.dx / 2) * 180 / Math.PI - 90; // eslint-disable-line
    }

    function mouseOverArc(d) {
      window.d3.select(this).attr('stroke', 'black');

      tooltip.html(format_description(d));
      return tooltip.transition()
        .duration(50)
        .style('opacity', 0.9);
    }

    function mouseOutArc() {
      window.d3.select(this).attr('stroke',''); // eslint-disable-line
      clearTimeout(timeOut);
      closeTooltip(); // eslint-disable-line
      return tooltip.style('opacity', 0);
    }

    function closeTooltip() {
      timeOut = setTimeout(() => {
        tooltip.style('opacity', 0);
      }, 5000);
    }

    function mouseMoveArc() {
      return tooltip
        .style('top', `${(window.d3.event.pageY - 10)}px`)
        .style('left', `${(window.d3.event.pageX + 10)}px`);
    }

    const root = diagramData;

    partition
      .value(function(d) { return d.size; }) // eslint-disable-line
      .nodes(root)
      .forEach(function(d) { // eslint-disable-line
        d._children = d.children; // eslint-disable-line
        d.sum = d.value; // eslint-disable-line
        d.key = key(d); // eslint-disable-line
        d.fill = fill(d); // eslint-disable-line
      });

    // Now redefine the value function to use the previously-computed sum.
    partition
      .children(function(d, depth) { return depth < 2 ? d._children : null; }) // eslint-disable-line
      .value(function(d) { return d.sum; }); // eslint-disable-line

    const center = svg.append('circle')
      .attr('r', radius / 3)
      .on('click', zoomOut); //eslint-disable-line

    center
      .append('title')
      .text('zoom out');

    const partitioned_data = partition.nodes(root).slice(1); //eslint-disable-line

    let path = svg.selectAll('path')
      .data(partitioned_data)
      .enter()
      .append('path')
      .attr('d', arc)
      .style('fill', function(d) { return d.fill; }) //eslint-disable-line
      .each(function(d) { this._current = updateArc(d); }) //eslint-disable-line
      .on('click', zoomIn) //eslint-disable-line
      .on('mouseover', mouseOverArc)
      .on('mousemove', mouseMoveArc)
      .on('mouseout', mouseOutArc);


    let texts = svg.selectAll('text')
      .data(partitioned_data)
      .enter()
      .append('text')
      .filter(filter_min_arc_size_text)
      .attr('transform', function(d) { return `rotate(${computeTextRotation(d)})`; }) //eslint-disable-line
      .attr('x', function(d) { return radius / 3 * d.depth; }) //eslint-disable-line
      .attr('dx', '6') // margin
      .attr('dy', '.35em') // vertical-align
      .text(function(d) { return d.name; }) //eslint-disable-line
      .each(wrap); //eslint-disable-line

    function zoomIn(p) {
      if (p.depth > 1) p = p.parent; //eslint-disable-line
      if (!p.children) {
        selectSTCb(p);
        return;
      }
      zoom(p, p); //eslint-disable-line
    }

    function wrap() {
      const self = window.d3.select(this);
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      const maxWidth = 120;
      const padding = 3;
      while (textLength > (maxWidth - 2 * padding) && text.length > 0) { //eslint-disable-line
        text = text.slice(0, -1);
        self.text(`${text}...`);
        textLength = self.node().getComputedTextLength();
      }
    }

    function zoomOut(p) {
      if (!p.parent) return;
      zoom(p.parent, p); //eslint-disable-line
    }

    // Zoom to the specified new root.
    function zoom(root, p) { //eslint-disable-line
      if (document.documentElement.__transition__) return;

      // Rescale outside angles to match the new layout.
      let enterArc;
      let exitArc;
      const outsideAngle = window.d3.scale.linear().domain([0, 2 * Math.PI]);

      function insideArc(d) {
        return p.key > d.key //eslint-disable-line
          ? { depth: d.depth - 1, x: 0, dx: 0 } : p.key < d.key
            ? { depth: d.depth - 1, x: 2 * Math.PI, dx: 0 }
            : { depth: 0, x: 0, dx: 2 * Math.PI };
      }

      function outsideArc(d) {
        return { depth: d.depth + 1, x: outsideAngle(d.x), dx: outsideAngle(d.x + d.dx) - outsideAngle(d.x) };
      }

      center.datum(root);

      // When zooming in, arcs enter from the outside and exit to the inside.
      // Entering outside arcs start from the old layout.
      if (root === p) {
        enterArc = outsideArc;
        exitArc = insideArc;
        outsideAngle.range([p.x, p.x + p.dx]);
      }

      const new_data = partition.nodes(root).slice(1); //eslint-disable-line

      path = path.data(new_data, function(d) { return d.key; }); //eslint-disable-line

      // When zooming out, arcs enter from the inside and exit to the outside.
      // Exiting outside arcs transition to the new layout.
      if (root !== p) {
        enterArc = insideArc;
        exitArc = outsideArc;
        outsideAngle.range([p.x, p.x + p.dx]);
      }

      window.d3.transition().duration(window.d3.event.altKey ? 7500 : 750).each(function() { //eslint-disable-line
        path.exit().transition()
          .style('fill-opacity', function(d) { return d.depth === 1 + (root === p) ? 1 : 0; }) //eslint-disable-line
          .attrTween('d', function(d) { return arcTween.call(this, exitArc(d)); }) //eslint-disable-line
          .remove();

        path
          .enter()
          .append('path')
          .style('fill-opacity', function(d) { return d.depth === 2 - (root === p) ? 1 : 0; }) //eslint-disable-line
          .style('fill', function(d) { return d.fill; }) //eslint-disable-line
          .on('click', zoomIn)
          .on('mouseover', mouseOverArc)
          .on('mousemove', mouseMoveArc)
          .on('mouseout', mouseOutArc)
          .each(function(d) { this._current = enterArc(d); }); //eslint-disable-line


        path
          .transition()
          .style('fill-opacity', 1)
          .attrTween('d', function(d) { return arcTween.call(this, updateArc(d)); }); //eslint-disable-line
      });


      texts = texts.data(new_data, function(d) { return d.key; }); //eslint-disable-line

      texts
        .exit()
        .remove();
      texts
        .enter()
        .append('text');

      texts
        .style('opacity', 0)
        .attr('transform', function(d) { return `rotate(${computeTextRotation(d)})`; }) //eslint-disable-line
        .attr('x', function(d) { return radius / 3 * d.depth; }) //eslint-disable-line
        .attr('dx', '6') // margin
        .attr('dy', '.35em') // vertical-align
        .filter(filter_min_arc_size_text)
        .text(function(d) { return d.name; }) //eslint-disable-line
        .each(wrap)
        .transition()
        .delay(750)
        .style('opacity', 1);
    }

    function key(d) {
      const k = [];
      let p = d;
      while (p.depth) {
        k.push(p.name);
        p = p.parent;
      }
      return k.reverse().join('.');
    }

    function fill(d) {
      let p = d;
      while (p.depth > 1) p = p.parent;
      const c = window.d3.lab(hue(p.name));
      c.l = luminance(d.sum);
      return c;
    }

    function arcTween(b) {
      const i = window.d3.interpolate(this._current, b);
      this._current = i(0);
      return function (t) {
        return arc(i(t));
      };
    }

    function updateArc(d) {
      return { depth: d.depth, x: d.x, dx: d.dx };
    }
  };


  render() {
    return (
      <div className="bilevel-partition-diagram" />
    );
  }
}

BilevelPartitionDiagram.propTypes = propTypes;

export default BilevelPartitionDiagram;
