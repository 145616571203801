import React from 'react';
import PropTypes from 'prop-types';
import LiteMol from 'litemol';
import 'litemol/dist/css/LiteMol-plugin.css';
// Components
import SelectInput from '../../common/Inputs/SelectInput/SelectInput';
// Utils
import { trimText } from '../../Utils/Utils';
// Styles
import './ProteinStructure3D.scss';

const propTypes = {
  protein: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func,
  value: PropTypes.string,
  closeCb: PropTypes.func,
};

class ProteinStructure3D extends React.Component {
  constructor(props) {
    super(props);
    this.plugin = null;
    this.state = {
      isExpanded: false,
    };
  }

  componentDidMount() {
    this.initLiteMol();
  }

  componentDidUpdate(prevProps) {
    const { protein } = this.props;
    if (protein.value !== prevProps.protein.value) {
      this.initLiteMol();
    }
  }

  checkSelectorStatus = () => {
    if (document.querySelector('.lm-layout-expanded')) {
      this.setState({ isExpanded: false });
      document.querySelector('.protein-structure-3d__header').classList.remove('protein-structure-3d--expanded');
    } else {
      this.setState({ isExpanded: true });
      document.querySelector('.protein-structure-3d__header').classList.add('protein-structure-3d--expanded');
    }
  };

  initLiteMol = () => {
    const { Plugin } = LiteMol;
    const { Views } = Plugin;
    const { Bootstrap } = LiteMol;
    const {
      Entity: { Transformer },
      Components: { LayoutRegion },
    } = Bootstrap;

    const { protein, closeCb } = this.props;

    if (this.plugin) {
      this.plugin.setLayoutState({ hideControls: true, isExpanded: false });
      this.plugin.destroy();
    }

    const customSpecification = {
      settings: {
        'molecule.model.defaultQuery': `residuesByName('GLY', 'ALA')`, // eslint-disable-line
        'molecule.model.defaultAssemblyName': '1',
      },
      transforms: [
        { transformer: Transformer.Molecule.CreateAssembly, view: Views.Transform.Molecule.CreateAssembly },
        { transformer: Transformer.Molecule.CreateVisual, view: Views.Transform.Molecule.CreateVisual },
      ],
      behaviours: [
        Bootstrap.Behaviour.SetEntityToCurrentWhenAdded,
        Bootstrap.Behaviour.FocusCameraOnSelect,
        Bootstrap.Behaviour.UnselectElementOnRepeatedClick,
        Bootstrap.Behaviour.ApplySelectionToVisual,
        Bootstrap.Behaviour.CreateVisualWhenModelIsAdded,
      ],
      components: [
        Plugin.Components.Visualization.HighlightInfo(LayoutRegion.Main, true),
        Plugin.Components.Entity.Current('LiteMol', Plugin.VERSION.number)(LayoutRegion.Right, true),
        Plugin.Components.Transform.View(LayoutRegion.Right),
      ],
      viewport: { view: Views.Visualization.Viewport, controlsView: Views.Visualization.ViewportControls },
      layoutView: Views.Layout,
      tree: { region: LayoutRegion.Left, view: Views.Entity.Tree },
    };

    this.plugin = Plugin.create({
      target: '#protein-structure-3d',
      customSpecification,
      layoutState: {
        collapsedControlsLayout: Bootstrap.Components.CollapsedControlsLayout.Landscape,
      },
    });

    this.plugin.loadMolecule({
      id: protein.value,
      url: `https://www.ebi.ac.uk/pdbe/static/entry/${protein.value}_updated.cif`,
      format: 'cif',
    });

    document.body.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.plugin.setLayoutState({ hideControls: true, isExpanded: false });
        this.plugin.destroy();
        closeCb();
      }
    });
    document.querySelector('.lm-icon-expand-layout').parentElement.addEventListener('click', this.checkSelectorStatus);
  };

  handleChange = (data) => {
    const { handleChange } = this.props;
    handleChange(data);
  };

  render() {
    const { options, protein } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className="protein-structure-3d">
        <div className="protein-structure-3d__header">
          {
            isExpanded &&
            <div
              title={protein.label}
              className="protein-structure-3d__title title3"
            >
              { trimText(protein.label, 210) }
            </div>
          }
          {
            !isExpanded &&
              <SelectInput
                defaultValue={protein}
                clearable={false}
                options={options}
                placeholder="Select PDB ID"
                onSelect={this.handleChange}
              />
          }
        </div>
        <div
          id="protein-structure-3d"
          className="protein-structure-3d__chart"
        />
      </div>
    );
  }
}

ProteinStructure3D.propTypes = propTypes;

export default ProteinStructure3D;
