import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';
import { sortExpressionData } from '../../../GeneDetails/GeneExpressionChapter/utils';

// form
const singleTargetForCancerTissuesLoading = state => state.getIn(['singleTargetForCancerReducer', 'form', 'tissuesLoading']);
const singleTargetForCancerTissuesError = state => state.getIn(['singleTargetForCancerReducer', 'form', 'tissuesError']);
const singleTargetForCancerColumns = state => state.getIn(['singleTargetForCancerReducer', 'form', 'columns']);
const singleTargetForCancerColumnsOrder = state => state.getIn(['singleTargetForCancerReducer', 'form', 'columnsOrder']);
const singleTargetForCancerListParentsOrder = state => state.getIn(['singleTargetForCancerReducer', 'form', 'parentsOrder']);

const normalTissuesDataSelector = state => state.getIn(['singleTargetForCancerReducer', 'form', 'columns', 'normalTissues', 'childTissues']);
const sensitiveTissuesDataSelector = state => state.getIn(['singleTargetForCancerReducer', 'form', 'columns', 'sensitiveTissues', 'childTissues']);
const tumorTissuesDataSelector = state => state.getIn(['singleTargetForCancerReducer', 'form', 'tumorTissues', 'childTissues']);
const tumorTissuesColumnsSelector = state => state.getIn(['singleTargetForCancerReducer', 'form', 'tumorTissues']);

// content
const singleTargetForCancerData = state => state.getIn(['singleTargetForCancerReducer', 'content', 'data']);
const singleTargetForCancerDataReset = state => state.getIn(['singleTargetForCancerReducer', 'content', 'reset']);
const singleTargetForCancerPageNumber = state => state.getIn(['singleTargetForCancerReducer', 'content', 'pageNumber']);
const singleTargetForCancerTotalPages = state => state.getIn(['singleTargetForCancerReducer', 'content', 'totalPages']);
const singleTargetForCancerTotalElements = state => state.getIn(['singleTargetForCancerReducer', 'content', 'totalElements']);
const singleTargetForCancerSort = state => state.getIn(['singleTargetForCancerReducer', 'content', 'sort']);
const singleTargetForCancerRequestData = state => state.getIn(['singleTargetForCancerReducer', 'content', 'requestData']);

// popup charts
const singleTargetForCancerCharts = state => state.getIn(['singleTargetForCancerReducer', 'charts', 'data']);
const singleTargetForCancerChartsLoading = state => state.getIn(['singleTargetForCancerReducer', 'charts', 'loading']);
const singleTargetForCancerChartsError = state => state.getIn(['singleTargetForCancerReducer', 'charts', 'error']);

const gtexDataInit = state => state.getIn(['singleTargetForCancerReducer', 'GTEX', 'data']);
const gtexExpressionSelectedSortingOption = state => state.getIn(['singleTargetForCancerReducer', 'GTEX', 'selectedSortingOption']);
const tcgaDataInit = state => state.getIn(['singleTargetForCancerReducer', 'TCGA', 'data']);
const tcgaExpressionSelectedSortingOption = state => state.getIn(['singleTargetForCancerReducer', 'TCGA', 'selectedSortingOption']);

const selectedTissuesExpressionData = state => state.getIn(['singleTargetForCancerReducer', 'selectedTissuesExpressionData']);

// form
export const getSingleTargetForCancerColumnsSelector = createSelector(
  singleTargetForCancerColumns,
  singleTargetForCancerColumnsOrder,
  singleTargetForCancerListParentsOrder,
  (_columns, _order, _listOrder) => {
    const columns = _columns && _columns.toJS();
    const order = _order.toJS();
    const listOrder = _listOrder.toJS();
    const newColumns = {};

    Object.values(columns).forEach((c, idx) => {
      const childTissues = {};
      Object.values(c.childTissues).forEach((group, index) => {
        const { sortDirection } = group;
        childTissues[listOrder[index]] = {
          ...group,
          tissues: group.tissues.sort((a, b) => (sortDirection === 'ASC' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))),
        };

        newColumns[order[idx]] = {
          ...c,
          childTissues,
        };
      });
    });

    return newColumns;
  }
);

export const getSingleTargetForCancerListParentsOrder = createSelector(
  singleTargetForCancerListParentsOrder,
  data => data && data.toJS()
);


export const getSingleTargetForCancerColumnsOrderSelector = createSelector(
  singleTargetForCancerColumnsOrder,
  data => data && data.toJS()
);

export const getSingleTargetForCancerTissuesLoadingSelector = createSelector(
  singleTargetForCancerTissuesLoading,
  data => data
);

export const getSingleTargetForCancerTissuesErrorSelector = createSelector(
  singleTargetForCancerTissuesError,
  data => data
);

export const getNormalDataSelector = createSelector(
  normalTissuesDataSelector,
  data => data && data.toJS()
);

export const getTumorDataSelector = createSelector(
  tumorTissuesDataSelector,
  (_data) => {
    const data = _data && _data.toJS();
    const listOrder = Object.keys(data);
    const sortedData = {};
    listOrder.forEach((el) => {
      sortedData[el] = {
        ...data[el],
        tissues: data[el].tissues.sort((a, b) => (data[el].sortDirection === 'ASC' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))),
      };
    });
    return sortedData;
  }
);

export const getSensitiveDataSelector = createSelector(
  sensitiveTissuesDataSelector,
  data => data && data.toJS()
);

export const getTumorTissuesColumnsSelector = createSelector(
  tumorTissuesColumnsSelector,
  data => data && data.toJS()
);

export const getAllTissues = createSelector(
  getNormalDataSelector,
  getSensitiveDataSelector,
  getTumorDataSelector,
  (normalTissues, sensitiveTissues, tumorTissues) => {
    const getCheckedItems = (array) => {
      let allTissues = [];
      let checkedTissues = [];
      Object.values(array).forEach((el) => {
        const ids = el.tissues.filter(tissue => tissue.checked);
        allTissues = [...allTissues, ...el.tissues];
        checkedTissues = [...checkedTissues, ...ids];
      });
      return { tissues: allTissues, checkedTissues };
    };

    const obj = {
      'normalTissues': getCheckedItems(normalTissues),
      'sensitiveTissues': getCheckedItems(sensitiveTissues),
      'tumorTissues': getCheckedItems(tumorTissues),
    };
    return obj;
  }
);

export const getTissuesDataForRequest = createSelector(
  getNormalDataSelector,
  getSensitiveDataSelector,
  getTumorDataSelector,
  (normalTissues, sensitiveTissues, tumorTissues) => {
    const getCheckedItems = (array) => {
      const tissuesObj = {};
      Object.values(array).forEach((el) => {
        const filteredData = el.tissues.filter(tissue => tissue.checked).map(tissue => tissue.cancerId || tissue.id);
        if (filteredData.length > 0) tissuesObj[el.id] = el.tissues.filter(tissue => tissue.checked).map(tissue => tissue.cancerId || tissue.id);
      });
      return tissuesObj;
    };
    const obj = {
      'normalTissues': getCheckedItems(normalTissues),
      'sensitiveTissues': getCheckedItems(sensitiveTissues),
      'tumorTissues': getCheckedItems(tumorTissues),
    };
    return obj;
  }
);

export const getTumorTissuesWithLabelsSelector = createSelector(
  getTumorDataSelector,
  (tumorTissues) => {
    const tissuesObj = {};
    Object.values(tumorTissues).forEach((el) => {
      const filteredData = el.tissues.filter(tissue => tissue.checked).map((tissue) => {
        const { id, label, cancerId } = tissue;
        return { id: cancerId || id, label: label || null };
      });
      if (filteredData.length > 0) tissuesObj[el.id] = filteredData;
    });
    return tissuesObj;
  },
);


export const getSingleTargetForCancerTissuesFormValues = formValueSelector(
  'SINGLE_TARGET_FOR_CANCER_FORM'
);

// content
export const getSingleTargetForCancerDataSelector = createSelector(
  singleTargetForCancerData,
  data => data && data.toJS()
);

export const getSingleTargetForCancerPageNumberSelector = createSelector(
  singleTargetForCancerPageNumber,
  data => data
);

export const getSingleTargetForCancerTotalPagesSelector = createSelector(
  singleTargetForCancerTotalPages,
  data => data
);

export const getSingleTargetForCancerTotalElementsSelector = createSelector(
  singleTargetForCancerTotalElements,
  data => data
);

export const getSingleTargetForCancerSortSelector = createSelector(
  singleTargetForCancerSort,
  data => data && data.toJS()
);

export const getSingleTargetForCancerDataResetSelector = createSelector(
  singleTargetForCancerDataReset,
  data => data
);

export const getSingleTargetForCancerRequestDataSelector = createSelector(
  singleTargetForCancerRequestData,
  data => data && data.toJS()
);

// popup charts
export const getSingleTargetForCancerChartsSelector = createSelector(
  singleTargetForCancerCharts,
  data => data && data.toJS()
);

export const getSingleTargetForCancerChartsLoadingSelector = createSelector(
  singleTargetForCancerChartsLoading,
  data => data
);

export const getSingleTargetForCancerChartsErrorSelector = createSelector(
  singleTargetForCancerChartsError,
  data => data
);

export const getGtexDataInit = createSelector(
  gtexDataInit,
  gtexExpressionSelectedSortingOption,
  (_data, _sortingOption) => {
    if (!_data) return [];
    const data = _data.toJS();
    const sortingOption = _sortingOption.toJS();

    return sortExpressionData(data, sortingOption);
  }
);

export const getGtexSelectedSortingOption = createSelector(
  gtexExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getTcgaSelectedSortingOption = createSelector(
  tcgaExpressionSelectedSortingOption,
  data => data && data.toJS()
);

export const getTcgaDataInit = createSelector(
  tcgaDataInit,
  tcgaExpressionSelectedSortingOption,
  (_data, _sortingOption) => {
    if (!_data) return [];
    const data = _data.toJS();
    const sortingOption = _sortingOption.toJS();

    return sortExpressionData(data, sortingOption);
  }
);

export const getSelectedTissuesExpressionDataSelector = createSelector(
  selectedTissuesExpressionData,
  data => data && data.toJS()
);
