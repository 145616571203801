import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmationDialog from '../../../../Modals/ConfirmationDialog/ConfirmationDialog';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onConfirm: PropTypes.func,
  analyticsItemsToDelete: PropTypes.number,
};

const DeleteAnalyticsModal = (props) => {
  const {
    isOpen,
    closeCb,
    onConfirm,
    analyticsItemsToDelete,
  } = props;

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      onCancel={closeCb}
      isOpen={isOpen}
      closeCb={closeCb}
      text={
        analyticsItemsToDelete === 1 ?
          'Are you sure you want to remove analytics?' :
          `Are you sure you want to remove ${analyticsItemsToDelete} analytics?`
      }
    />
  );
};

DeleteAnalyticsModal.propTypes = propTypes;

export default DeleteAnalyticsModal;
