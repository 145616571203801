export const methodTabEnum = {
  FIND_RELATED: 'FIND_RELATED',
  RANK_SELECTION: 'RANK_SELECTION',
  NETWORK_ANALYSIS: 'NETWORK_ANALYSIS',
};

export const methodTabLabelEnum = {
  FIND_RELATED: 'Enrichment analysis',
  RANK_SELECTION: 'Rank Selection',
  NETWORK_ANALYSIS: 'Network Analysis',
};
