import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Components
import Loader from '../../common/Loader/Loader';
// Utils
import { AsterPlot } from '../../graphics/AsterPlot/AsterPlot';
// Store
import { selectSet } from '../../Analytics/common/SetAnalysis/actions';
import { loadNextChapterAction } from '../GeneDiseasePage/actions';
import { initConclusionAction } from './actions';
import { getError, getLoading, getSectionData } from './selectors';
// Styles
import './GeneDiseaseConclusion.css';

const propTypes = {
  initSectionData: PropTypes.func,
  geneId: PropTypes.string,
  diseaseId: PropTypes.string,
  highlightedTissueIds: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  sectionData: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  itsNewConcept: PropTypes.bool,
};

class GeneDiseaseConclusion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
    };
    this.initPage();
  }

  initPage = () => {
    const {
      initSectionData,
      geneId,
      diseaseId,
      highlightedTissueIds,
      itsNewConcept,
      sectionData: {
        score,
      },
    } = this.props;

    if (itsNewConcept || !score) {
      initSectionData({
        geneId,
        diseaseId,
        highlightedTissueIds,
      });
    }
  };

  render() {
    const {
      loading,
      gene,
      disease,
      error,
      sectionData,
    } = this.props;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';

    return (
      <div>
        {!loading &&
          <div>
            <div className="gene-disease-section-title">
              <span>Conclusion</span>
              <span
                role="presentation"
                className="fa fa-info-circle icon"
                onClick={() => {
                  this.setState({
                    showTitleHelperText: !this.state.showTitleHelperText,
                  });
                }}
              />
              <div
                className={
                  classnames(
                    'gene-disease-section-title-helper-text',
                    { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                  )
                }
              >
                The Gene-Disease workflow summarises all the sections covered in the workflow in a single weighted score. For each aspect a separate score is calculated and weighted as part of the total score. The scoring model does not aim to provide a methodology for providing an absolute score as this is not feasible across disease types. Instead, the score enables relative scoring which is very effective to assess a range of targets for one disease or a range of diseases for one target.
                <br />
                <br />
                The detailed explanation of each score can is available
                <a target="_blank" href="/Gene - Disease Score Help.pdf">here</a>
              </div>
            </div>
            <div className="gene-disease-section-body">
              Based on the analyses in the above sections, a weighted score is calculated (0-100) indicating the involvement of {geneName} in {diseaseName}.
              In the diagram below, the width of each section indicates the weight of that section in the calculation of total score.
              <br />
              <br />
              The score for {geneName} and {diseaseName} = {sectionData.score}
              {
                sectionData.chartData ?
                  <div>
                    <AsterPlot
                      id="conclusionChart"
                      data={sectionData.chartData}
                      score={sectionData.score}
                    />
                  </div> :
                  <div>
                    Sorry, no relevant data to display
                  </div>
              }
            </div>
          </div>
        }
        <Loader isLoading={loading && !error} />
        {error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            {error}
          </div>
        }
      </div>
    );
  }
}

GeneDiseaseConclusion.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionData: getSectionData(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionData(data) {
      dispatch(initConclusionAction(data));
    },
    selectSetForAnalysis(data) {
      dispatch(selectSet(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneDiseaseConclusion);
