import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash.isempty';

// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { openSetResultInNewTab } from '../../Utils/Utils';
import { linksForTables } from '../GeneDiseasePage/enum';
import { generateSetForAnalysis, getSetsForAnalysis, initialSets } from '../../Analytics/common/SetAnalysis/utils';
// Components
import Loader from '../../common/Loader/Loader';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import CircosDiagram from '../../graphics/CircosDiagram/CircosDiagram';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import NoData from '../../common/NoData/NoData';
// Store
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import { loadNextChapterAction } from '../GeneDiseasePage/actions';
import { initMetabolitesAction } from './actions';
import {
  getError,
  getLoading,
  getSectionData,
} from './selectors';
// Styles
import './GeneDiseasePhenotypeSection.css';

const propTypes = {
  initSectionData: PropTypes.func,
  geneId: PropTypes.string,
  diseaseId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  sectionData: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  highlightedTissueIds: PropTypes.instanceOf(Array),
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  pageName: PropTypes.string,
};

class GeneDiseasePhenotypesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
      popup: false,
    };
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.initPage();
  }

  initPage = () => {
    const {
      highlightedTissueIds,
      initSectionData,
      geneId,
      diseaseId,
      itsNewConcept,
      loadNextChapter,
      sectionData,
    } = this.props;

    if (highlightedTissueIds && (itsNewConcept || isEmpty(sectionData))) {
      initSectionData({
        geneId,
        diseaseId,
        highlightedTissueIds,
      });
    } else {
      loadNextChapter('pathways');
    }
  };

  openPublicationsPopup = () => {
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  analyzeSet = (geneName, sectionData) => {
    const {
      gene,
    } = this.props;

    const set = {
      items: sectionData.publications.map(item => ({ id: item.setBNode.id, name: item.setBNode.name })),
      name: `${geneName} neighbor node`,
    };

    const setsForAnalysis = initialSets;
    const setForAnalysis = setsForAnalysis[0];
    const generatedData = generateSetForAnalysis([set]);

    Object.assign(setForAnalysis, generatedData);

    setsForAnalysis[0] = getSetsForAnalysis(setsForAnalysis[0], gene);
    localStorage.setItem('setAnalysisSets', JSON.stringify(setsForAnalysis));
    window.open(`${RELATIVE_PATH}/analytics/personal/new?runAfter=PHENOTYPE`, '_blank');
  };

  genesCell = ({ id, name }, rowIndex, columnIndex, pathForLink) => {
    const link = `${RELATIVE_PATH}/${pathForLink}/${id}`;
    const uniqueKey = `phenotype-tooltip-${id}-${rowIndex}-${columnIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      loading,
      gene,
      disease,
      error,
      sectionData,
      pageName,
    } = this.props;
    const { popup } = this.state;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';

    const tableSettings = {
      width: 1100,
      headerHeight: 70,
      rowHeight: 50,
    };

    const columns = [
      {
        label: geneName,
        dataKey: 'setANode',
        width: 200,
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
          rowData.setANode ? this.genesCell(rowData.setANode, rowIndex, columnIndex, linksForTables.geneDetails) : null
        ),
      },
      {
        label: 'Gene neighbor node',
        dataKey: 'setBNode',
        width: 200,
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
          rowData.setBNode ? this.genesCell(rowData.setBNode, rowIndex, columnIndex, linksForTables.geneDetails) : null
        ),
      },
      {
        label: 'Phenotype',
        dataKey: 'setCNode',
        width: 200,
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
          rowData.setCNode ? this.genesCell(rowData.setCNode, rowIndex, columnIndex, linksForTables.conceptDetails) : null
        ),
      },
      {
        label: `Pub count with ${geneName} [A+B]`,
        dataKey: 'pubsAB',
        width: 150,
        disableSort: true,
        headerRenderer: ({ label }) => (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
              <span>Pub count with </span><br />
              <span>{geneName} </span><br />
              <span>[A+B]</span>
            </span>
          </div>
        ),
        cellRenderer: ({ rowData }) => {
          const linkClass = classnames({
            link: rowData.pubsAB > 0,
          });
          return (
            <span
              role="presentation"
              onClick={() => {
                if (rowData.pubsAB > 0) {
                  this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name];
                  this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id];
                  this.openPublicationsPopup();
                }
              }}
              className={linkClass}
            >
              {rowData.pubsAB}
            </span>
          );
        },
      },
      {
        label: 'Pub count with <phenotype> [B+C]',
        dataKey: 'pubsBC',
        width: 150,
        disableSort: true,
        headerRenderer: ({ label }) => (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
              <span>Pub count with </span><br />
              <span>{'<phenotype>'} </span><br />
              <span>[B+C]</span>
            </span>
          </div>
        ),
        cellRenderer: ({ rowData }) => {
          const linkClass = classnames({
            link: rowData.pubsBC > 0,
          });
          return (
            <span
              role="presentation"
              onClick={() => {
                if (rowData.pubsBC > 0) {
                  this.conceptsNames = [rowData.setBNode.name, rowData.setCNode.name];
                  this.conceptsIds = [rowData.setBNode.id, rowData.setCNode.id];
                  this.openPublicationsPopup();
                }
              }}
              className={linkClass}
            >
              {rowData.pubsBC}
            </span>
          );
        },
      },
      {
        label: 'Pub count genes and <phenotype> [A+C]',
        dataKey: 'pubsAC',
        width: 150,
        disableSort: true,
        headerRenderer: ({ label }) => (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
              <span>Pub count genes and </span><br />
              <span>{'<phenotype>'} </span><br />
              <span>[A+C]</span>
            </span>
          </div>
        ),
        cellRenderer: ({ rowData }) => {
          const linkClass = classnames({
            link: rowData.pubsAC > 0,
          });
          return (
            <span
              role="presentation"
              onClick={() => {
                if (rowData.pubsAC > 0) {
                  this.conceptsNames = [rowData.setANode.name, rowData.setCNode.name];
                  this.conceptsIds = [rowData.setANode.id, rowData.setCNode.id];
                  this.openPublicationsPopup();
                }
              }}
              className={linkClass}
            >
              {rowData.pubsAC}
            </span>
          );
        },
      }];

    return (
      <div>
        {!loading &&
        <div>
          <div className="gene-disease-section-title">
            <span>Phenotypes</span>
            <span
              role="presentation"
              className="fa fa-info-circle icon"
              onClick={() => {
                this.setState({
                  showTitleHelperText: !this.state.showTitleHelperText,
                });
              }}
            />
            <div
              className={
                classnames(
                  'gene-disease-section-title-helper-text',
                  { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                )
              }
            >
              <div>
                This section shows an enrichment analysis on the list of {diseaseName} associated proteins that also interact
                with {geneName}. For this list, the the top 5 associated phenotypes are determined through Fisher Exact test.
                Please note that this top 5 does not represent the top 5 phenotypes for {diseaseName} in general,
                  but only those phenotypes in which {geneName} is also involved. This may therefore include phenotypes
                    which are relatively uncommon for {diseaseName}
              </div>
              <div>This is visualised in a <span className="strongBold">circle diagram</span> as follows:</div>
              <ul>
                <li>The <span className="strongBold">colored ring</span> represents
                  the genes/proteins associated with {diseaseName} that
                  interact with {geneName} where:
                <ul>
                  <li>Each ring segment represents a gene/protein</li>
                  <li>The top 15 genes/proteins, based on the number of publications mentioning the gene/protein
                      and {diseaseName}, are shown
                  </li>
                  <li>
                      The size of each protein segment is based on the total number of molecular interactions it has
                      with other genes (in general, not specific to the disease)
                  </li>
                </ul>
                </li>
                <li>
                  If a gene is expressed in one of the highlighted tissues (see the ‘Expression’ section), these are
                  shown as <span className="strongBold">expression bars</span> protruding from the gene segment.
                </li>
                <li>
                  The <span className="strongBold">inner circle</span> shows the molecular interactions between the genes.
                </li>
              </ul>
              <div>The following <span className="strongBold">actions</span> are possible on the circle diagram :</div>
              <ul>
                <li><span className="strongBold">Click</span> on a gene segment to highlight its interactions</li>
                <li><span className="strongBold">Hover</span> over any element in the circle diagram for more details</li>
              </ul>
              <div>
                A <span className="strongBold">table</span> is shown that provides the publication and database references for the interactions displayed
                in the circle diagram. If {geneName} has more than the top 15 interactions displayed in the circle diagram,
                these can be accessed here as well.
              </div>
            </div>
          </div>
          <div className="gene-disease-section-body">
            {
              sectionData.data && sectionData.data.length > 0
                && ((sectionData.chords && !!sectionData.chords.length)
                || (sectionData.publications && sectionData.publications.length > 0)) ?
                <div>{geneName} is involved in phenotypes associated with {diseaseName}</div> :
                <div>{geneName} is not involved in any of the phenotypes associated with {diseaseName}</div>
            }
            {
              sectionData.data &&
              <div>
                {
                  !!sectionData.chords.length &&
                  <CircosDiagram
                    targetGene={gene}
                    data={sectionData.data}
                    chords={sectionData.chords}
                    geneColor={sectionData.genesColor}
                    id="phenotypesCircos"
                    geneName={pageName}
                    chartName="Phenotypes"
                  />
                }
                <NoData
                  show={
                    sectionData.publications &&
                    !sectionData.publications.length
                  }
                  customClassName="gene-disease__no-data"
                  message="There is no data to display table."
                />
                {
                  sectionData.publications && sectionData.publications.length > 0 &&
                  <div className="gene-disease-metabolites-table">
                    <div className="gene-disease-metabolites-table-header">
                      <button
                        onClick={() => openSetResultInNewTab(sectionData.fullSetABC)}
                        type="button"
                        className="button button-primary"
                      >
                        <ReactTooltip
                          id="showAllPhenotypeGenes"
                          place="bottom"
                          type="light"
                        >
                          Open a table containing all interactions phenotype related genes
                        </ReactTooltip>
                        <span
                          data-tip={true}
                          data-for="showAllPhenotypeGenes"
                        >
                          Show all phenotype genes
                        </span>
                      </button>
                      <button
                        onClick={() => this.analyzeSet(geneName, sectionData)}
                        type="button"
                        className="button button-primary ml-15"
                      >
                        <ReactTooltip
                          id="showAllPhenotypes"
                          place="bottom"
                          type="light"
                        >
                          Open the analytics application to view all related phenotypes
                        </ReactTooltip>
                        <span
                          data-tip={true}
                          data-for="showAllPhenotypes"
                        >
                          Show all phenotypes
                        </span>
                      </button>
                    </div>
                    <SimpleTable
                      fixedHeight={true}
                      settings={tableSettings}
                      height={sectionData.publications.length
                        ? sectionData.publications.length * tableSettings.rowHeight + tableSettings.headerHeight //eslint-disable-line
                        : tableSettings.headerHeight + 40}
                      data={sectionData.publications}
                      columns={columns}
                    />
                  </div>
                }
              </div>
            }
          </div>
        </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            {error}
          </div>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneDiseasePhenotypesSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionData: getSectionData(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionData(data) {
      dispatch(initMetabolitesAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneDiseasePhenotypesSection);
