import {
  SORT,
  CHECK_ITEM,
  CHECK_ALL,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../../common/SimpleTable/constants';

import {
  RANKING_RESULT_TABLE,
  REMOVE_CONCEPT,
  RANKING_RESULT_TABLE_RESET,
  RANKING_REQUESTED,
  LOADING,
  EFFECT_OPERATION,
} from './constants';

export const requestRanking = data => (
  {
    type: RANKING_REQUESTED,
    data,
  }
);

export const reset = () => (
  {
    type: RANKING_RESULT_TABLE_RESET,
  }
);

export const sort = data => (
  {
    type: RANKING_RESULT_TABLE + SORT,
    data,
  }
);

export const checkAll = data => (
  {
    type: RANKING_RESULT_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: RANKING_RESULT_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelection = () => (
  {
    type: RANKING_RESULT_TABLE + INVERT_SELECTION,
  }
);

export const removeSelected = () => (
  {
    type: RANKING_RESULT_TABLE + REMOVE_SELECTED,
  }
);

export const removeConcept = data => (
  {
    type: RANKING_RESULT_TABLE + REMOVE_CONCEPT,
    data,
  }
);

export const loading = () => (
  {
    type: RANKING_RESULT_TABLE + LOADING,
    data: true,
  }
);

export const setEffectOperationAction = data => (
  {
    type: RANKING_RESULT_TABLE + EFFECT_OPERATION,
    data,
  }
);
