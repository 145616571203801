import { createSelector } from 'reselect';

// Dataset
const datasetsOptions = state => state.getIn(['datasetsOptionsReducer', 'datasets']);
const datasetsLoading = state => state.getIn(['datasetsOptionsReducer', 'loading']);
const datasetsError = state => state.getIn(['datasetsOptionsReducer', 'error']);

export const getDatasetsOptionsSelector = createSelector(
  datasetsOptions,
  data => data && data.toJS()
);

export const getDatasetsLoadingSelector = createSelector(
  datasetsLoading,
  data => data
);

export const getDatasetsErrorSelector = createSelector(
  datasetsError,
  data => data
);
