import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
import {
  putSetManagementDataAction,
  toggleSetManagementDataLoaderAction,
  throwSetManagementDataErrorAction,
} from '../../reducer';

export function* getSetManagementDataWorker(action) {
  try {
    yield put(toggleSetManagementDataLoaderAction(true));
    const { projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSets : Api.getSetsList;
    const requestData = isProjectTab ? { projectId } : {
      params: {
        type: 'SIMPLE, EFFECT, COMPLEX',
      },
    };
    const result = yield call(apiToCall, requestData);
    yield put(putSetManagementDataAction(result.data));
  } catch (e) {
    yield put(throwSetManagementDataErrorAction(e.message));
    yield put(toggleSetManagementDataLoaderAction(false));
  }
}
