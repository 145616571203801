import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { geneDiseaseSetChapterAction } from '../GeneDiseasePage/actions';

import {
  GENE_DISEASE_CONCLUSION_INIT_REQUESTED,
  GENE_DISEASE_CONCLUSION_INIT_SUCCEEDED,
  GENE_DISEASE_CONCLUSION_INIT_FAILED,
} from './constants';

function* saga(action) {
  try {
    const post = action.data;
    const response = yield call(Api.geneDiseaseConclusion, { post });
    yield put({ type: GENE_DISEASE_CONCLUSION_INIT_SUCCEEDED, data: response.data });
    const {
      animalModelScoreScore,
      annotationScore,
      cellDysfunctionScore,
      diffExpressionScore,
      expressionScore,
      geneticMutationScore,
      // metabolitesScore,
      organOrTissueScore,
      pathwaysScore,
      phenotypeScore,
    } = response.data;
    if (animalModelScoreScore
      || annotationScore
      || cellDysfunctionScore
      || diffExpressionScore
      || expressionScore
      || geneticMutationScore
      // || metabolitesScore
      || organOrTissueScore
      || pathwaysScore
      || phenotypeScore
    ) {
      yield put(geneDiseaseSetChapterAction('conclusion-section'));
    }
  } catch (e) {
    yield put({ type: GENE_DISEASE_CONCLUSION_INIT_FAILED, message: e.message });
  }
}

function* rootSaga() {
  yield takeLatest(GENE_DISEASE_CONCLUSION_INIT_REQUESTED, saga);
}

export default rootSaga;
