export const types = {
  GET_PROTEIN_STRUCTURE_DATA: 'GET_PROTEIN_STRUCTURE_DATA',
  SET_PROTEIN_STRUCTURE_DATA: 'SET_PROTEIN_STRUCTURE_DATA',
  PROTEIN_STRUCTURE_IS_FAILED: 'PROTEIN_STRUCTURE_IS_FAILED',
  TOGGLE_PROTEIN_STRUCTURE_LOADER: 'TOGGLE_PROTEIN_STRUCTURE_LOADER',
  GET_PDB_ENTRIES: 'GET_PDB_ENTRIES',
  SET_PDB_ENTRIES: 'SET_PDB_ENTRIES',
  SET_PDB_ENTRIES_ERROR: 'SET_PDB_ENTRIES_ERROR',
  UPDATE_PDB_ID: 'UPDATE_PDB_ID',
};
