import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { HiCheck } from 'react-icons/hi';
import { MdOutlineFileUpload } from 'react-icons/md';
import { Field, reduxForm } from 'redux-form/immutable';
import classNames from 'classnames';

// Components
import FormInput from '../../../FormElements/FormInput';
import Button from '../../../Buttons/Button/Button';
import Accordion from '../../../Accordion/Accordion';
import Spinner from '../../../../common/Spinner/Spinner';
// Styles
import './styles.scss';

const propTypes = {
  importSettings: PropTypes.instanceOf(Object),
  setImportSettings: PropTypes.func,
  loading: PropTypes.bool,
  handleSelectFile: PropTypes.func,
  valid: PropTypes.bool,
  change: PropTypes.func,
  fileInputRef: PropTypes.instanceOf(Object),
};

const UploadFileImportSetup = (props) => {
  const { loading, valid, change, handleSelectFile, importSettings, setImportSettings, fileInputRef } = props;

  const disabled = useMemo(() => ({
    columns: importSettings.columnFrom || importSettings.columnTo,
    rows: importSettings.rowFrom || importSettings.rowTo,
  }), [importSettings]);

  const handleInputChange = useCallback((e) => {
    setImportSettings({
      ...importSettings,
      [e.target.name]: e.target.value,
    });
  }, [importSettings, setImportSettings]);

  const handleButtonChange = (e) => {
    if (e.target.name === 'rows') {
      change('rowFrom', '');
      change('rowTo', '');
      setImportSettings({
        ...importSettings,
        rowFrom: '',
        rowTo: '',
      });
    } else if (e.target.name === 'columns') {
      change('columnFrom', '');
      change('columnTo', '');
      setImportSettings({
        ...importSettings,
        columnFrom: '',
        columnTo: '',
      });
    }
  };

  return (
    <form className="upload-file-import-setup">
      <Accordion
        title="Import settings"
        customClassName="upload-file-accordion"
      >
        <div className="upload-file-import-setup__row">
          <div className="upload-file-import-setup__item">
            <Field
              type="number"
              name="columnFrom"
              placeholder="Columns from"
              component={FormInput}
              customClassName="upload-file-import-setup__input"
              onChangeCallback={handleInputChange}
            />
          </div>
          <div className="upload-file-import-setup__item">
            <Field
              type="number"
              name="columnTo"
              placeholder="Columns to"
              component={FormInput}
              customClassName="upload-file-import-setup__input"
              onChangeCallback={handleInputChange}
            />
          </div>
          <Button
            name="columns"
            customClassName="upload-file-import-setup__button"
            disabled={!disabled.columns}
            onClick={handleButtonChange}
          >
            All columns
            {
              !disabled.columns &&
              <HiCheck
                size={22}
                className="upload-file-import-setup__icon"
              />
            }
          </Button>
        </div>
        <div className="upload-file-import-setup__row">
          <div className="upload-file-import-setup__item">
            <Field
              type="number"
              name="rowFrom"
              placeholder="Rows from"
              component={FormInput}
              customClassName="upload-file-import-setup__input"
              onChangeCallback={handleInputChange}
            />
          </div>
          <div className="upload-file-import-setup__item">
            <Field
              type="number"
              name="rowTo"
              placeholder="Rows to"
              component={FormInput}
              customClassName="upload-file-import-setup__index"
              onChangeCallback={handleInputChange}
            />
          </div>
          <Button
            name="rows"
            customClassName="upload-file-import-setup__button"
            disabled={!disabled.rows}
            onClick={handleButtonChange}
          >
            All rows
            {
              !disabled.rows &&
              <HiCheck
                size={22}
                className="upload-file-import-setup__icon"
              />
            }
          </Button>
        </div>
      </Accordion>
      <div className="upload-file__container">
        {
          loading ?
            <Spinner
              isLoading={loading}
              containerClassName="upload-file__spinner"
            /> :
            <MdOutlineFileUpload
              size={32}
              className="upload-file__icon"
            />
        }
        <div className="upload-file__title">
          Drag & drop to Upload file
        </div>
        <div className="upload-file__text">
          or
        </div>
        <input
          id="upload-file"
          ref={fileInputRef}
          type="file"
          accept=".csv, .txt, .tsv, .xls, .xlsx"
          disabled={!valid}
          onChange={handleSelectFile}
          className={classNames('upload-file__btn', { 'upload-file__btn_disabled': !props.valid })}
        />
        <label htmlFor="upload-file" className="upload-file__label">
          Browse file
        </label>
      </div>
    </form>
  );
};

UploadFileImportSetup.propTypes = propTypes;

function validate(values) {
  const errors = {};
  const columnFrom = values.get('columnFrom');
  const columnTo = values.get('columnTo');
  const rowFrom = values.get('rowFrom');
  const rowTo = values.get('rowTo');

  if (columnFrom) {
    if (Number(columnFrom) < 1) {
      errors.columnFrom = 'Column from value is to small';
    } else if (!Number.isInteger(Number(columnFrom))) {
      errors.columnFrom = 'Column from value needs to be integer';
    }
  }

  if (columnTo) {
    if (Number(columnTo) < 1) {
      errors.columnTo = 'Column to value is to small';
    } else if (!Number.isInteger(Number(columnTo))) {
      errors.columnTo = 'Column to value needs to be integer';
    }
  }

  if (rowFrom) {
    if (Number(rowFrom) < 1) {
      errors.rowFrom = 'Row from value is to small';
    } else if (!Number.isInteger(Number(rowFrom))) {
      errors.rowFrom = 'Row from value needs to be integer';
    }
  }

  if (rowTo) {
    if (Number(rowTo) < 1) {
      errors.rowTo = 'Row to value is to small';
    } else if (!Number.isInteger(Number(rowTo))) {
      errors.rowTo = 'Row to value needs to be integer';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'UPLOAD_FILE_IMPOT_SETUP_FORM',
  validate,
})(UploadFileImportSetup);
