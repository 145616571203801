export const PAGE_SIZE = 20;

export const RANK_SELECTION_REQUESTED = 'RANK_SELECTION_REQUESTED';
export const RANK_SELECTION_SUCCEEDED = 'RANK_SELECTION_SUCCEEDED';
export const RANK_SELECTION_FAILED = 'RANK_SELECTION_FAILED';
export const RANK_SELECTION_CHANGE_PAGE = 'RANK_SELECTION_CHANGE_PAGE';
export const RANK_SELECTION_RESULT_TABLE = 'RANK_SELECTION_RESULT_TABLE';
export const RANK_SELECTION_RESULT_TABLE_RESET = 'RANK_SELECTION_RESULT_TABLE_RESET';
export const REMOVE_CONCEPT = 'REMOVE_CONCEPT';
export const LOADING = 'LOADING';
export const APPLY_FILTER = '_APPLY_FILTER';
export const CLEAR_FILTERS = '_CLEAR_FILTERS';

export const operation = {
  contains: 'contains',
  equals: '=',
  notEquals: '!=',
  moreThan: '>',
  lessThan: '<',
};
