import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Input from '../../../../common/Inputs/Input/Input';
import Button from '../../../../common/Buttons/Button/Button';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  mergeLinks: PropTypes.func,
  concepts: PropTypes.instanceOf(Array),
};

const CustomRelationModal = (props) => {
  const {
    isOpen,
    closeCb,
    concepts,
    mergeLinks,
  } = props;

  const [relation, setRelation] = useState('');
  const [nodes, setNodes] = useState(concepts);

  function reverseRelation() {
    setNodes([...nodes.reverse()]);
  }

  function saveRelation() {
    const id = `${nodes[0].id}_${nodes[1].id}`;
    const link = {
      [id]: {
        linkId: id,
        custom: true,
        selected: false,
        twoSided: false,
        source: nodes[0].id,
        target: nodes[1].id,
        triples: [{
          object: nodes[0].id,
          subject: nodes[1].id,
          tripleId: null,
          predicateId: null,
          predicateName: relation,
          accessMappings: ['0-1'],
        }],
      },
    };
    mergeLinks(link);
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="custom-relation">
        <div className="custom-relation__header">
          <div className="custom-relation__title title3">
            Add Custom Relation
          </div>
        </div>
        <div className="custom-relation__body">
          <div className="custom-relation__block">
            <span className="custom-relation__label">Source</span>
            <Input
              value={nodes[0].name}
              disabled={true}
              customClassName="custom-relation__input"
            />
          </div>
          <div className="custom-relation__block">
            <span className="custom-relation__label">Relation</span>
            <Input
              value={relation}
              placeholder="Specify relation"
              customClassName="custom-relation__input"
              onChange={e => setRelation(e.target.value)}
            />
          </div>
          <div className="custom-relation__block">
            <span className="custom-relation__label">Target</span>
            <Input
              value={nodes[1].name}
              disabled={true}
              customClassName="custom-relation__input"
            />
          </div>
        </div>
        <div className="custom-relation__controls">
          <Button
            text="Reverse direction"
            onClick={reverseRelation}
            customClassName="custom-relation__button"
          />
          <Button
            text="Save"
            disabled={!relation}
            onClick={saveRelation}
            customClassName="custom-relation__button custom-relation__button_dark"
          />
        </div>
      </div>
    </ModalComponent>
  );
};

CustomRelationModal.propTypes = propTypes;

export default CustomRelationModal;
