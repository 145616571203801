import { takeLatest, call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { checkGenmabAccount } from '../../../Utils/Utils';
// Store
import { getUserName } from '../../../Header/selectors';
import { geneDetailsSetChapterAction, loadNextChapterAction } from '../../actions';
import {
  getGeneDetailsAntibodiesAction,
  setGeneDetailsAntibodiesAction,
  throwGeneDetailsAntibodiesErrorAction,
  toggleGeneDetailsAntibodiesLoaderAction,
} from './reducer';

export function* getAntibodiesWorker(action) {

  try {
    yield put(toggleGeneDetailsAntibodiesLoaderAction(true));
    const userName = yield select(getUserName);
    const { data } = yield call(Api.getGeneDetailsAntibodiesLinks, action.payload);

    const dataExist = !data.Notice;

    if (dataExist) {
      const antibodyType = data.antibody_type;
      const {
        reactivity,
        applications,
        url,
        publications,
      } = data;
      const allAmount = Object.values(antibodyType).reduce((acc, currentValue) => acc + currentValue);
      const reactivityData = [];
      const applicationsData = [];
      Object.keys(reactivity).forEach((key, idx) => {
        reactivityData.push({
          id: idx,
          name: key,
          measure: reactivity[key],
          value: reactivity[key],
        });
      });

      Object.keys(applications).forEach((key, idx) => {
        applicationsData.push({
          id: idx,
          name: key,
          measure: applications[key],
          value: applications[key],
        });
      });

      const transformedData = {
        antibodyTypes: antibodyType,
        reactivityData,
        applicationsData,
        antibodyLink: url,
        antibodySum: allAmount,
        antibodyPublications: publications,
      };
      yield put(setGeneDetailsAntibodiesAction(transformedData));
      yield put(geneDetailsSetChapterAction('antibodies'));
    } else {
      yield put(setGeneDetailsAntibodiesAction({}));
    }
    if (checkGenmabAccount(userName)) {
      yield put(loadNextChapterAction('internal-links'));
    } else {
      yield put(loadNextChapterAction('external-links'));
    }
  } catch (e) {
    const userName = yield select(getUserName);
    yield put(setGeneDetailsAntibodiesAction({}));
    if (e.response && e.response.status === 404) {
      yield put(throwGeneDetailsAntibodiesErrorAction('There is no data to display'));
    } else {
      yield put(throwGeneDetailsAntibodiesErrorAction('Data is temporarily unavailable'));
    }
    if (checkGenmabAccount(userName)) {
      yield put(loadNextChapterAction('internal-links'));
    } else {
      yield put(loadNextChapterAction('external-links'));
    }
  }
}

function* antibodiesSaga() {
  yield takeLatest(getGeneDetailsAntibodiesAction, getAntibodiesWorker);
}

export default antibodiesSaga;
