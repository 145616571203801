import { fromJS } from 'immutable';

import {
  SET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS_FILTER_OPTION,
  UPDATE_BACKGROUND_SET_CONFIG,
  TOGGLE_BACKGROUND_SET_OPTION,
  TOGGLE_BACKGROUND_CELLS_LOADER,
  SET_BACKGROUND_SET_FILTER_OPTION,
  SWITCH_SHOULD_UPDATE_DATA_FLAG,
  CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
} from './constants';
import { defaultProjects } from '../../../Projects/ProjectsTabs/constants';

const initialValues = fromJS({
  backgroundCells: null,
  backgroundCellsLoader: false,
  backgroundCellsFilterOption: '',
  backgroundSetOptions: {
    allGenesOption: true,
    cellTypeOption: false,
    selectSetOption: false,
  },
  backgroundSetFilterOption: '',
  backgroundSetActiveProjectId: defaultProjects[0].id,
  backgroundSetConfiguration: {
    backgroundSetType: 'GENES',
  },
});

const backgroundSet = (state = initialValues, action) => {
  switch (action.type) {
    case SET_BACKGROUND_CELLS:
      return state
        .update('backgroundCells', () => fromJS(action.data))
        .update('backgroundCellsFilterOption', (backgroundCellsFilterOption) => {
          if (backgroundCellsFilterOption.length) {
            return backgroundCellsFilterOption;
          }
          return Object.keys(action.data)[0];
        });

    case SET_BACKGROUND_CELLS_FILTER_OPTION:
      return state.update('backgroundCellsFilterOption', () => action.data);

    case TOGGLE_BACKGROUND_CELLS_LOADER:
      return state.update('backgroundCellsLoader', () => action.data);

    case UPDATE_BACKGROUND_SET_CONFIG:
      return state.update('backgroundSetConfiguration', () => fromJS(action.data));

    case TOGGLE_BACKGROUND_SET_OPTION:
      return state.update('backgroundSetOptions', () => fromJS(action.data));

    case SET_BACKGROUND_SET_FILTER_OPTION:
      return state.set('backgroundSetFilterOption', action.data);

    case SWITCH_SHOULD_UPDATE_DATA_FLAG:
      return state.set('shouldUpdateDataFlag', action.data);

    case SET_BACKGROUND_SET_ACTIVE_PROJECT_ID:
      return state
        .update('backgroundSetActiveProjectId', () => action.data);

    case CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID:
      return state
        .update('backgroundSetActiveProjectId', () => initialValues.get('backgroundSetActiveProjectId'));

    default:
      return state;
  }
};

export default backgroundSet;
