import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdSettings } from 'react-icons/md';
import { FaCircle, FaSquareFull } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import ColorsAndShapesModal from '../ColorsAndShapesModal/ColorsAndShapesModal';
// Constants
import { RelationMapShapes, ColorShapesInitialValues } from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  cacheData: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Object),
  semanticCategoriesOrder: PropTypes.instanceOf(Array),
  updateSemCategories: PropTypes.func,
  updateColorsAndShapes: PropTypes.func,
  updateSemCategoriesOrder: PropTypes.func,
};

const SemanticCategories = (props) => {
  const {
    cacheData,
    semanticCategories,
    updateSemCategories,
    updateColorsAndShapes,
    semanticCategoriesOrder,
    updateSemCategoriesOrder,
  } = props;
  const [showModal, setShowModal] = useState(ColorShapesInitialValues);

  function showColorsAndShapesModal(config) {
    setShowModal({
      show: true,
      config,
    });
  }

  function closeColorsAndShapesModal() {
    setShowModal({
      show: false,
      config: null,
    });
  }

  function toggleSemanticCategory(item) {
    const { checked } = item;
    updateSemCategories({
      ...item,
      checked: !checked,
    });
  }

  function onDragEnd(result) {
    const {
      source,
      destination,
      draggableId,
    } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newOrder = [...semanticCategoriesOrder];
    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, draggableId);

    updateSemCategoriesOrder(newOrder);
  }

  function renderShape(type, color) {
    switch (type) {
      case RelationMapShapes.SQUARE:
        return (
          <FaSquareFull
            size={20}
            color={`#${color}`}
            className="semantic-categories__shape"
          />
        );
      case RelationMapShapes.RHOMB:
        return (
          <FaSquareFull
            size={20}
            color={`#${color}`}
            style={{ transform: 'rotate(45deg)' }}
            className="semantic-categories__shape"
          />
        );
      default:
        return (
          <FaCircle
            size={20}
            color={`#${color}`}
            className="semantic-categories__shape"
          />
        );
    }
  }

  function renderItem(item, i) {
    if (!semanticCategories[item]) return null;

    const {
      category,
      color,
      shape,
      checked,
    } = semanticCategories[item];

    return (
      <Draggable
        draggableId={category}
        index={i}
        key={`key-sem-cat-${i}`}
      >
        {
          provided => (
            <div
              ref={provided.innerRef}
              className="semantic-categories__item"
              {...provided.draggableProps}
            >
              <Checkbox
                id={`checkbox-sem-cat-${i}`}
                checked={checked}
                onChange={() => { toggleSemanticCategory(semanticCategories[item]); }}
              />
              {renderShape(shape, color)}
              <span className="semantic-categories__text">
                {category}
              </span>
              <div className="semantic-categories__right-block">
                <button
                  className="circle-btn"
                  onClick={() => { showColorsAndShapesModal(semanticCategories[item]); }}
                >
                  <MdSettings size={16} />
                </button>
                <div
                  className="semantic-categories__handle"
                  {...provided.dragHandleProps}
                >
                  .. .. ..
                </div>
                {
                  color &&
                  <div
                    className="semantic-categories__color"
                    style={{ backgroundColor: `#${color}` }}
                  />
                }
              </div>
            </div>
          )
        }
      </Draggable>
    );
  }

  function updateColorsAndShapesHandler(category, updater) {
    cacheData();
    updateColorsAndShapes({ category, updater });
  }

  return (
    <div className="semantic-categories">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'1'}>
          {
            provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {semanticCategoriesOrder.map(renderItem)}
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      {
        showModal.show &&
        <ColorsAndShapesModal
          isOpen={showModal.show}
          apply={updateColorsAndShapesHandler}
          closeCb={closeColorsAndShapesModal}
          {...showModal.config}
        />
      }
    </div>
  );
};

SemanticCategories.propTypes = propTypes;

export default SemanticCategories;
