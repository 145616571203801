import {
  createSelector,
} from 'reselect';

const animalModelData = state => state.getIn(['geneDiseaseAnimalModel', 'data']);
const loading = state => state.getIn(['geneDiseaseAnimalModel', 'loading']);
const error = state => state.getIn(['geneDiseaseAnimalModel', 'error']);

export const getAnimalModelData = createSelector(
  animalModelData,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
