
import { call, put, select } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import { getProteinUniProtSelector } from '../../selectors';
import { setPDBEntriesAction, setPDBEntriesErrorAction } from '../../actions';


export function* getPDBEntriesWorker() {
  try {
    const uniprotID = yield select(getProteinUniProtSelector);
    const requestID = uniprotID.toUpperCase();
    const { data } = yield call(Api.getPDBEntries, requestID);
    const PDB = data[requestID];
    const { data: sumData } = yield call(
      Api.getPDBEntriesSummary,
      PDB.map(item => item.pdb_id),
    );
    const PDBEntries = PDB.map(item => ({
      value: item.pdb_id,
      label: sumData[item.pdb_id][0].title,
      type: item.pdb_id.toUpperCase(),
    }));
    yield put(setPDBEntriesAction(PDBEntries));
  } catch (e) {
    yield put(setPDBEntriesErrorAction());
  }
}
