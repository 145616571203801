import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getParentBlockWidth } from '../../Utils/Utils';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import ClinicalTrialsTable from './ClinicalTrialsTable';
// Store
import * as SELECTORS from './redux/selectors';
import { getDataAsyncAction } from './redux/actions';
import { loadNextChapterAction } from '../actions';
// Styles
import './ClinicalTrialsChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  initSectionTable: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  sectionTable: PropTypes.instanceOf(Object),
  geneName: PropTypes.string,
};

class ClinicalTrialsChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: false,
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      itsNewConcept,
      initSectionTable,
      loadNextChapter,
      sectionTable: {
        content,
      },
    } = this.props;

    if (itsNewConcept || content.length) {
      initSectionTable({ id: gene });
    } else {
      loadNextChapter('antibodies');
    }
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      error,
      loading,
      geneName,
    } = this.props;
    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div className="clinical-trials">
        <div className="gene-details-section-title title2">
          <span>Clinical Trials</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              This section shows clinical trials that are known for the target. The following information is provided:
            </p>
            <ul>
              <li>The indication that is the subject of the clinical trial</li>
              <li>The therapeutic agent that is tested in the clinical trial</li>
              <li>The clinical trial ID number</li>
              <li>The link to the clinical trial</li>
              <li>The source of the clinical trial data (subscription to Pharmaprojects can be activated)</li>
              <li>The phase of the clinical trial</li>
              <li>The reason for termination of the trial</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div className="gene-details-section-body">
            <ClinicalTrialsTable width={chapterWidth} geneName={geneName} />
          </div>
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} erro={error} />
      </div>
    );
  }
}

ClinicalTrialsChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionTable: SELECTORS.getSectionTable(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionTable(data) {
      dispatch(getDataAsyncAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalTrialsChapter);
