import { graphTypes } from '../../GeneDetails/GeneExpressionChapter/constants';
import { drawLegend } from '../LegendSvgComponent/drawLegend';

export function drawBoxPlot(args) {
  const {
    htmlRoot,
    data,
    userConfig,
    clickCallback,
    classMode = 'mode',
    showTitle,
    chartTitle,
    boxPlotTransform,
    type,
    sampleName,
    sampleGroupName,
    sampleMeasureKey,
    yAxisText,
    legendData,
    showLegend,
    handleAxisClick,
    selectedCells,
    isChartDownloading,
  } = args;

  const { d3 } = window;
  const config = {
    fixedScale: null,
    boxWidth: null,
    width: 500,
    height: 500,
    ...userConfig,
    margin: {
      top: isChartDownloading || showTitle ? 55 : 10,
      right: 150,
      bottom: 150,
      left: 95,
      ...(userConfig || {}).margin,
    },
  };

  const dom = {};
  const { height, margin } = config;
  let { width } = config;
  const selection = d3.select(htmlRoot);
  const domHtmlRoot = document.querySelector(htmlRoot);
  const bodyHtml = document.querySelector('body');

  let boxWidth = config.boxWidth === null ? (width / data.length) * 0.5 : config.boxWidth;

  const fixForMozilaIsEnabled = isChartDownloading && data.length > 1000 && navigator.userAgent.includes('Mozilla');
  if (fixForMozilaIsEnabled) {
    boxWidth = 15;
    width /= 1.5;
  }

  const boxWidthHalf = boxWidth / 2;
  const chartHeight = height - margin.bottom;
  const chartMinWidth = (((domHtmlRoot.offsetWidth - (margin.left + margin.right)) * 50) / 100);
  const chartWidth = chartMinWidth > width ? chartMinWidth : width;
  const svgHeight = chartHeight + margin.top + margin.bottom;
  const svgWidth = chartWidth + margin.left + margin.right + 100;

  selection.select('svg').remove();

  if (selection.select('svg').empty()) {
    dom.svg = selection.append('svg')
      .attr('class', 'chart-area')
      .attr('width', svgWidth)
      .attr('height', svgHeight);

    if (isChartDownloading || showTitle) {
      dom.svg.append('text').attr('class', 'chartTitle')
        .text(`${chartTitle}`)
        .on('mouseover', mouseOver)
        .on('mousemove', mouseMoveTitle)
        .on('mouseleave', mouseLeave)
        .attr('style', 'font: 22px sans-serif;transform: translate(50%, 35px);text-anchor: middle;');

      const title = dom.svg.selectAll('.chartTitle')[0][0];
      const titleWidth = title.getComputedTextLength();
      if (titleWidth > svgWidth) {
        dom.svg.attr('width', titleWidth);
      }

      if (showLegend) {
        const SVG = d3.select(`${htmlRoot} svg`)
          .append('g')
          .attr('transform', () => 'translate(-50, -50)');

        const keys = legendData.map(param => param.name);
        const colors = legendData.map(param => param.color);

        drawLegend({
          element: SVG,
          keys,
          colors,
        });
      }
    }

    dom.yAxis = dom.svg.append('g')
      .attr('class', 'boxplot-chart__axis boxplot-chart__axis_y')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    dom.xAxis = dom.svg.append('g')
      .attr('class', 'boxplot-chart__axis boxplot-chart__axis_x')
      .attr('transform', `translate(${margin.left}, ${chartHeight + margin.top})`);
    dom.plotarea = dom.svg.append('g')
      .attr('class', 'plotarea')
      .attr('transform', `translate(${margin.left + 5}, ${margin.top})`);
  } else {
    dom.svg = selection.select('svg');
    dom.svg
      .attr('width', svgWidth)
      .attr('height', svgHeight);
    dom.yAxis = selection.select('.boxplot-chart__axis_y');
    dom.xAxis = selection.select('.boxplot-chart__axis_x');
    dom.plotarea = selection.select('.plotarea');
  }

  const xTicksNames = data.map(d => d[sampleName]);

  let yTicksNames;
  if (config.fixedScale) {
    yTicksNames = config.fixedScale;
  } else {
    let min = d3.min(data, d => d[sampleMeasureKey]);
    let max = d3.max(data, d => d[sampleMeasureKey]);

    if (min >= 0) min = 0;
    if (max <= 0) max = 0;
    if (min === max) {
      if (min < 0) max = 0;
      if (min > 0) min = 0;
    }
    yTicksNames = [min, max];
  }

  const xScale = d3.scale.ordinal()
    .domain(xTicksNames)
    .rangeBands([0, chartWidth]);

  const yScale = d3.scale.linear()
    .domain(yTicksNames)
    .range([chartHeight, 0]);

  const yAxis = d3.svg.axis().orient('left')
    .scale(yScale)
    .ticks(20)
    .tickSize(1);

  const xAxis = d3.svg.axis().orient('bottom')
    .scale(xScale)
    .tickSize(1);

  function getName(d) {
    if (type === graphTypes.CCLE_PROTEOMICS) {
      return `${d.split('__duplicated__')[0]}`;
    }
    return d;
  }

  function getBoxPlotTooltipTemplate(d) {
    if (sampleGroupName) return `
        <div class="chart-tooltip-boxplot__content">
          <span><b>Cell type:</b> ${getName(d[sampleName])}</span><br/>
          <span><b>Cell type group:</b> ${getName(d[sampleGroupName])}</span><br/>
          <span><b>nTPM:</b> ${d[sampleMeasureKey].toFixed(2)}</span><br/>
        </div> 
      `;

    if (d.cancer) return `
      <div class="chart-tooltip-boxplot__content">
        <span><b>${getName(d[sampleName])}</b></span><br/>
        <span>Cancer type: <b>${d.cancer}</b></span><br/>
        <span>Measure: <b>${d[sampleMeasureKey].toFixed(2)}</b></span><br/>
      </div> 
    `;

    return `
      <div class="chart-tooltip-boxplot__content">
        <span><b>${getName(d[sampleName])}</b></span><br/>
        <span>Measure: <b>${d[sampleMeasureKey].toFixed(2)}</b></span><br/>
      </div> 
    `;
  }

  function getTitleTooltipTemplate() {
    return (`<b style="text-transform: capitalize">${chartTitle}</b>`);
  }

  function getTextTooltipTemplate(d) {
    return (`<b style="text-transform: capitalize">${getName(d)}</b>`);
  }

  if (d3.select(`.chart-tooltip-boxplot_${classMode}`)[0][0]) {
    d3.select(`.chart-tooltip-boxplot_${classMode}`).remove();
  }

  const tooltip = d3.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', `chart-tooltip-boxplot chart-tooltip-boxplot_${classMode}`);

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d) {
    tooltip
      .html(getBoxPlotTooltipTemplate(d))
      .style('left', `${d3.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseMoveText(d) {
    tooltip
      .html(getTextTooltipTemplate(d))
      .style('left', `${d3.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseMoveTitle() {
    tooltip
      .html(getTitleTooltipTemplate())
      .style('left', `${d3.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
  }

  function handleBoxplotClick(d) {
    if (clickCallback) {
      clickCallback(`${d.concept.id}`);
    }
  }

  function calcBoxHeight(d) {
    return Math.abs(yScale(d[sampleMeasureKey]) - yScale(0));
  }

  function createBoxPlot(d, i) {
    d3.select(this)
      .append('rect')
      .attr('class', 'range')
      .attr('x', xScale(i))
      .attr('y', yScale(Math.max(0, d[sampleMeasureKey])))
      .attr('height', calcBoxHeight(d))
      .attr('width', boxWidth)
      .style('stroke', d.color.boxColor)
      .style('stroke-width', '2px')
      .style('cursor', 'pointer')
      .style('fill', d.color.boxColor)
      .on('click', handleBoxplotClick)
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMoveBoxPlot)
      .on('mouseleave', mouseLeave);
  }

  function calcBoxplotXPosition(i) {
    return xScale.range()[i] + ((xScale.rangeBand() - boxWidth - boxWidthHalf) / 2);
  }

  function render() {
    // add the y axis
    dom.yAxis.call(yAxis);

    dom.yAxis.selectAll('line')
      .attr('x2', -10);
    dom.yAxis.selectAll('text')
      .attr('x', -12)
      .style('font-size', '16px');
    dom.yAxis.selectAll('text.label').remove();
    let maxYTextLength = 0;
    dom.yAxis.selectAll('.boxplot-chart__axis_y > .tick > text')[0].forEach((item) => {
      if (item.getComputedTextLength() > maxYTextLength) {
        maxYTextLength = Math.round(item.getComputedTextLength());
      }
    });

    const yAxisLabel = yAxisText || 'TPM';
    const textForYAxis = boxPlotTransform === 'linear' ? `${yAxisLabel}` : `log2(${yAxisLabel} + 1)`;
    dom.yAxis.append('text')
      .attr('class', 'label')
      .attr('transform', 'rotate(-90)')
      .attr('y', -maxYTextLength - 30)
      .attr('x', -chartHeight / 2)
      .attr('dy', '0em')
      .style('text-anchor', 'middle')
      .style('font-weight', 'normal')
      .text(textForYAxis);
    // add the x axis
    dom.xAxis.call(xAxis);
    dom.xAxis.selectAll('text')
      .attr('x', 15)
      .attr('y', 0)
      .attr('transform', 'rotate(45)')
      .text(d => getName(d))
      .style('text-anchor', 'start')
      .style('font-size', '16px')
      .style('font-weight', (d) => {
        if (!isChartDownloading && selectedCells) {
          return selectedCells.find(c => d === c.name) && 700;
        }
        return 400;
      })
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMoveText)
      .on('mouseleave', mouseLeave)
      .on('click', d => handleAxisClick(d));


    if (fixForMozilaIsEnabled) {
      dom.xAxis.selectAll('text')
        .style('font-size', '12px');
    }

    dom.xAxis.selectAll('line')
      .attr('y2', 10);
    // destroy and add the boxplots to plotarea
    dom.plotarea.selectAll('g.boxplot')
      .remove();
    dom.plotarea.selectAll('g.boxplot')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'boxplot')
      .attr('transform', (d, i) => `translate(${calcBoxplotXPosition(i)}, 0)`)
      .each(createBoxPlot);
    let computedChartHeight = 0;
    computedChartHeight += dom.svg.select('.boxplot-chart__axis_x')[0][0].getBBox().height;
    computedChartHeight += dom.svg.select('.boxplot-chart__axis_y')[0][0].getBBox().height;
    dom.svg.attr('height', computedChartHeight + margin.top + margin.bottom);
  }
  const styles = '.tick line {stroke: black;}.boxplot-chart__axis_x .tick text {font-size: 16px;letter-spacing: 0.3px;cursor: pointer;}.tick text {font-size: 12px;cursor: pointer;}svg.chart-area {font-family: sans-serif;background-color: #ffffff}';
  dom.svg.append('style').text(styles);
  return render();
}
