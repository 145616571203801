import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import PublicationDetailsInfo from './Components/PublicationDetailsInfo/PublicationDetailsInfo';
import PublicationDetailsTriples from './Components/PublicationDetailsTriples/PublicationDetailsTriples';
import PublicationCitesChartWrap from './Components/PublicationCitesChartWrap/PublicationCitesChartWrap';
import SimilarPublications from './Components/SimilarPublications/SimilarPublications';
// Store
import {
  getPublicationDetailsChaptersSelector, getPubmedIdSelector,
} from './selectors';
import { resetPublicationDetailsChaptersAction } from './reducer';
// Constants
import { PubDetailsChaptersEnum } from './constants';
// Styles
import './styles.scss';

const propTypes = {
  chapters: PropTypes.instanceOf(Object),
  pubmedId: PropTypes.string,
  pubmedIdFromData: PropTypes.string,
  resetPublicationDetailsChapters: PropTypes.func,
};

const PublicationDetails = (props) => {
  const {
    chapters,
    pubmedId,
    pubmedIdFromData,
    resetPublicationDetailsChapters,
  } = props;
  const { publicationId } = useParams();

  useEffect(() => resetPublicationDetailsChapters, []);

  const pubmedIdValue = pubmedIdFromData || pubmedId;

  return (
    <div className="pub-details-page">
      {
        chapters[PubDetailsChaptersEnum.publicationInfo] &&
        <PublicationDetailsInfo pubId={publicationId} pubmedId={pubmedIdValue} />
      }
      {
        pubmedIdValue &&
        chapters[PubDetailsChaptersEnum.publicationCitations] &&
        <PublicationCitesChartWrap pubId={publicationId} />
      }
      {
        chapters[PubDetailsChaptersEnum.publicationTriples] &&
        <PublicationDetailsTriples exportName={publicationId || pubmedIdValue} pubId={publicationId} pubmedId={pubmedIdValue} />
      }
      {
        pubmedIdValue &&
        chapters[PubDetailsChaptersEnum.publicationInfo] &&
        <SimilarPublications pubId={publicationId} />
      }
    </div>
  );
};

PublicationDetails.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pubmedIdFromData: getPubmedIdSelector(state),
    chapters: getPublicationDetailsChaptersSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPublicationDetailsChapters(id) {
      dispatch(resetPublicationDetailsChaptersAction(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicationDetails);
