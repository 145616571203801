import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Components
import SelectInput from '../Inputs/SelectInput/SelectInput';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.bool,
  getSelectGeneOptions: PropTypes.func,
  resetSelectGeneOptions: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  diseaseName: PropTypes.string,
  customClassName: PropTypes.string,
  clearFieldAfterSelect: PropTypes.bool,
};

const SelectDisease = (props) => {
  const {
    error,
    options,
    loading,
    onSubmit,
    getSelectGeneOptions,
    resetSelectGeneOptions,
    diseaseName,
    customClassName,
    clearFieldAfterSelect,
  } = props;

  const [inputValue, setInputValue] = useState(diseaseName || '');

  function handleInputChange(value) {
    setInputValue(value);
  }

  function fetchOptions(value) {
    handleInputChange(value);
    if (value) {
      getSelectGeneOptions(value);
    } else {
      resetSelectGeneOptions();
    }
  }

  function switchGene(gene) {
    if (clearFieldAfterSelect) {
      handleInputChange('');
    }
    resetSelectGeneOptions();
    onSubmit(gene);
  }

  return (
    <div className={classnames('select-disease', customClassName)}>
      <SelectInput
        bordered={true}
        value={inputValue}
        options={options}
        isLoading={loading}
        isDisabled={error}
        onInputChange={fetchOptions}
        onSelect={switchGene}
        title="Select Disease"
        placeholder="Select Disease"
        noOptionsMessage={() => 'There is no data to display'}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

SelectDisease.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: SELECTORS.getDiseaseOptionSelector(state),
    loading: SELECTORS.getDiseaseLoaderSelector(state),
    error: SELECTORS.getDiseaseErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSelectGeneOptions(data) {
      dispatch(ACTIONS.getDiseaseOptionsAction(data));
    },
    resetSelectGeneOptions(data) {
      dispatch(ACTIONS.resetSelectDiseaseAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDisease);
