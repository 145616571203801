import {
  ADD_GENE_VARIATION_TERM,
  REMOVE_GENE_VARIATION_TERM,
  SAVE_GENE_VARIATION_TERM,
  CLEAR_GENE_VARIATION_TERM,
} from './constants';

export const addGeneVariationTerm = data => (
  {
    type: ADD_GENE_VARIATION_TERM,
    data,
  }
);

export const removeGeneVariationTerm = data => (
  {
    type: REMOVE_GENE_VARIATION_TERM,
    data,
  }
);

export const saveGeneVariationTerm = () => (
  {
    type: SAVE_GENE_VARIATION_TERM,
  }
);

export const clearGeneVariationTerm = () => (
  {
    type: CLEAR_GENE_VARIATION_TERM,
  }
);
