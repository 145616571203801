import * as d3js from 'd3';

export const mousePosition = { x: null, y: null };

export function getMousePosition(e) {
  mousePosition.x = navigator.userAgent.indexOf('Firefox') !== -1 ? e.layerX : e.offsetX;
  mousePosition.y = navigator.userAgent.indexOf('Firefox') !== -1 ? e.layerY : e.offsetY;
}

export function getMaxLength(data, changeForDownload) {
  let maxLength = 0;
  data.forEach((item) => {
    if (item.name.length > maxLength) {
      maxLength = item.name.length;
    }
  });
  const wordLength = changeForDownload ? 90 : 35;
  return maxLength < wordLength ? maxLength * 8 : wordLength * 8;
}

export function getSelectedCells() {
  const arr = [];
  d3js.selectAll('#heatmap svg .cell-selected').each((item) => {
    arr.push(item);
  });
  return arr;
}

export function calculateStep(maxValue) {
  if (maxValue >= 1) {
    return undefined;
  }
  return 0.0001;
}