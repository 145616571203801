export const FETCH_COMPLEX_SET_DATA_REQUEST = 'FETCH_COMPLEX_SET_DATA_REQUEST';
export const FETCH_COMPLEX_SET_DATA_SUCCESS = 'FETCH_COMPLEX_SET_DATA_SUCCESS';
export const FETCH_COMPLEX_SET_DATA_FAILURE = 'FETCH_COMPLEX_SET_DATA_FAILURE';

export const DOWNLOAD_COMPLEX_SET_DATA_REQUEST = 'DOWNLOAD_COMPLEX_SET_DATA_REQUEST';

// Actions for show/hide modal for creation Simple Set from Complex Set
export const SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE = 'SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE';
export const HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE = 'HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE';

// Actions for show/hide modal for creation Effect Set from Complex set
export const SHOW_MODAL_FROM_COMPLEX_TO_EFFECT = 'SHOW_MODAL_FROM_COMPLEX_TO_EFFECT';
export const HIDE_MODAL_FROM_COMPLEX_TO_EFFECT = 'HIDE_MODAL_FROM_COMPLEX_TO_EFFECT';

// Actions for form to analyze complex set for simple set creation
export const SIMPLE_SET_ANALYZE_REQUEST = 'SIMPLE_SET_ANALYZE_REQUEST';
export const SIMPLE_SET_ANALYZE_SUCCESS = 'SIMPLE_SET_ANALYZE_SUCCESS';
export const SIMPLE_SET_ANALYZE_FAILURE = 'SIMPLE_SET_ANALYZE_FAILURE';

// Actions for saving simple set from complex set
export const SIMPLE_SET_SAVE_REQUEST = 'SIMPLE_SET_SAVE_REQUEST';
export const SIMPLE_SET_SAVE_SUCCESS = 'SIMPLE_SET_SAVE_SUCCESS';
export const SIMPLE_SET_SAVE_FAILURE = 'SIMPLE_SET_SAVE_FAILURE';

// Actions for form to analyze complex set for effect set creation
export const EFFECT_SET_ANALYZE_REQUEST = 'EFFECT_SET_ANALYZE_REQUEST';
export const EFFECT_SET_ANALYZE_SUCCESS = 'EFFECT_SET_ANALYZE_SUCCESS';
export const EFFECT_SET_ANALYZE_FAILURE = 'EFFECT_SET_ANALYZE_FAILURE';

// Actions for saving effect set from complex set
export const EFFECT_SET_SAVE_REQUEST = 'EFFECT_SET_SAVE_REQUEST';
export const EFFECT_SET_SAVE_SUCCESS = 'EFFECT_SET_SAVE_SUCCESS';
export const EFFECT_SET_SAVE_FAILURE = 'EFFECT_SET_SAVE_FAILURE';

// Actions for processing simple or complex set after analyzing
export const SOLVE_AMBIGUITY = 'SOLVE_AMBIGUITY';
export const REMOVE_CONCEPT = 'REMOVE_CONCEPT';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';
export const AMBIGUITY_TABLE = 'AMBIGUITY_TABLE_';
export const RESOLVE_CONCEPT_COMPLEX = 'RESOLVE_CONCEPT_COMPLEX';

export const COMPLEX_SET_UPDATE_TAGS = 'COMPLEX_SET_UPDATE_TAGS';
export const SIMPLE_SET_UPDATE_TAGS = 'SIMPLE_SET_UPDATE_TAGS';
export const COMPLEX_SET_VIEW_RESET_STATE = 'COMPLEX_SET_VIEW_RESET_STATE';

// Actions for filters
export const COMPLEX_SET_APPLY_FILTER = 'COMPLEX_SET_APPLY_FILTER';
export const COMPLEX_SET_RESET_FILTERS = 'COMPLEX_SET_RESET_FILTERS';
export const COMPLEX_SET_SAVE_FILTERED = 'COMPLEX_SET_SAVE_FILTERED';

// Actions for aggregation
export const COMPLEX_SET_APPLY_AGGREGATION = 'COMPLEX_SET_APPLY_AGGREGATION';
export const COMPLEX_SET_ADD_AGGREGATION_RULE = 'COMPLEX_SET_ADD_AGGREGATION_RULE';

// Actions for updating complex set configuration
export const COMPLEX_SET_UPDATE_CONFIG_REQUEST = 'COMPLEX_SET_UPDATE_CONFIG_REQUEST';
export const COMPLEX_SET_UPDATE_CONFIG_SUCCESS = 'COMPLEX_SET_UPDATE_CONFIG_SUCCESS';
export const COMPLEX_SET_UPDATE_CONFIG_FAILURE = 'COMPLEX_SET_UPDATE_CONFIG_FAILURE';

export const COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL = 'COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL';
export const COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL = 'COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL';

export const COMPLEX_SET_CHANGE_NAME = 'COMPLEX_SET_CHANGE_NAME';
export const COMPLEX_SET_CHANGE_DESCRIPTION = 'COMPLEX_SET_CHANGE_DESCRIPTION';
export const SET_PARENT_TAGS = 'SET_PARENT_TAGS';

// Free user
export const COMPLEX_SET_FAILED_FREE_USER = 'COMPLEX_SET_FAILED_FREE_USER';
export const CLEAR_COMPLEX_SET_ERROR = 'CLEAR_COMPLEX_SET_ERROR';
export const CLEAR_COMPLEX_SET_FREE_USER_ERROR = 'CLEAR_COMPLEX_SET_FREE_USER_ERROR';
