import {
  createSelector,
} from 'reselect';

const geneDetails = state => state.getIn(['geneDetails', 'data']);
const loading = state => state.getIn(['geneDetails', 'loading']);
const error = state => state.getIn(['geneDetails', 'error']);
const pageFlow = state => state.getIn(['geneDetails', 'pageFlow']);
const pageChapters = state => state.getIn(['geneDetails', 'pageChapters']);
const pageChaptersData = state => state.getIn(['geneDetails', 'pageChaptersData']);
const lastVisitedConceptId = state => state.getIn(['geneDetails', 'lastVisitedConceptId']);

export const getLastVisitedConceptId = createSelector(
  lastVisitedConceptId,
  id => id
);

export const getGeneDetails = createSelector(
  geneDetails,
  data => data && data.toJS()
);
export const getLoading = createSelector(
  loading,
  data => data
);
export const getError = createSelector(
  error,
  data => data
);

export const getPageFlow = createSelector(
  pageFlow,
  data => (data ? data.toJS() : [])
);

export const getPageChapters = createSelector(
  pageChapters,
  data => (data ? data.toJS() : [])
);

export const getPageChaptersData = createSelector(
  pageChaptersData,
  data => (data ? data.toJS() : [])
);
