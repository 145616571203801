export const intermedieteConceptsFilterInitialValues = {
  PUBLICATION_FILTERS: {
    publicationSource: 'ALL',
    publicationTypes: null,
    researchTypes: null,
    sources: null,
  },
  GENE_CELL_TYPE_EXPRESSION_FILTER: {
    ids: null,
    lowerExpressionThreshold: 1,
    higherExpressionThreshold: 1000000,
  },
  GENE_TISSUE_EXPRESSION_FILTER: {
    ids: null,
    lowerExpressionThreshold: 1,
    higherExpressionThreshold: 1000000,
  },
};

export const intermediateConceptsSortingTypes = {
  NAME: 'name',
  COUNT: 'count',
};
