import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isActive: PropTypes.bool,
  handleSelect: PropTypes.func,
  options: PropTypes.instanceOf(Array),
};

const AutocompleteDropdown = (props) => {
  const {
    handleSelect,
    isActive,
    options,
  } = props;

  if (!isActive || !options) return null;

  if (options.length === 0) {
    return (
      <div className="autocomplete__dropdown">
        <span className="autocomplete__no-data">
          There is no data to display
        </span>
      </div>
    );
  }

  return (
    <div className="autocomplete__dropdown">
      {
        options.map((option, i) => (
          <button
            key={`autocomplete-option-${i}`}
            className="autocomplete__item"
            onClick={() => handleSelect(option)}
          >
            {option.name}
          </button>
        ))
      }
    </div>
  );
};

AutocompleteDropdown.propTypes = propTypes;

export default React.memo(AutocompleteDropdown);