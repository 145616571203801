import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import PropTypes from 'prop-types';

// Api
import Api from './Api/Api';
// Components
import Private from './navigation/Private';
import Public from './navigation/Public';
// Store
import { getUserTokenSelector } from './components/Header/selectors';
import { userLoginSuccessAction } from './components/auth/reducer';

const propTypes = {
  token: PropTypes.string,
  setUser: PropTypes.func,
};

const App = ({ token, setUser }) => {
  const [searchParams] = useSearchParams();
  const [, setLsUser] = useLocalStorage('user');
  const paramsToken = searchParams.get('token');

  useEffect(() => {
    if (paramsToken) {
      Api.setToken(paramsToken);
      setUser({ token: paramsToken });
      setLsUser({ token: paramsToken });
    }
  }, []);

  if (token){
    return <Private />;
  }

  return <Public />;
};

App.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    token: getUserTokenSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser(data) {
      dispatch(userLoginSuccessAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
