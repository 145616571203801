import { createSelector } from 'reselect';

import { RESTORE_PASSWORD_FORM } from './constants';

const form = state => state.getIn(['form', RESTORE_PASSWORD_FORM]);

export const restorePasswordForm = createSelector(
  form,
  (data) => {
    const email = data.getIn(['values', 'email']);
    return {
      email,
    };
  }
);
