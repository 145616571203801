import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
// Components
import ModalComponent from '../../../../../../ModalComponent/ModalComponent';
import SimpleTable from '../../../../../../common/SimpleTable/SimpleTable';
import Loader from '../../../../../../common/Loader/Loader';
import Error from '../../../../../../common/Error/Error';
import NoData from '../../../../../../common/NoData/NoData';
import ExportTable from '../../../../../../common/ExportTable/ExportTable';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  exportLoading: PropTypes.bool,
  error: PropTypes.string,
  getCellTypeClusterData: PropTypes.func,
  exportCellTypeClusterData: PropTypes.func,
  clearCellTypeClusterData: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  isModalOpen: PropTypes.bool,
  selectedCluster: PropTypes.string,
  toggleCellTypeClusterModal: PropTypes.func,
};

const CellTypeClusterModal = (props) => {
  const {
    data,
    currentPage,
    totalPages,
    totalElements,
    error,
    loading,
    exportLoading,
    isModalOpen,
    selectedCluster,
    getCellTypeClusterData,
    exportCellTypeClusterData,
    clearCellTypeClusterData,
    toggleCellTypeClusterModal,
  } = props;

  const tableRef = useRef();

  const tableWidth = 800;
  const fileName = `cell-type-cluster-${selectedCluster}`;

  useEffect(() => {
    if (selectedCluster) {
      getCellTypeClusterData({ selectedCluster });
    }
  }, [selectedCluster]);

  useEffect(() => () => {
    clearCellTypeClusterData();
  }, []);

  const getColumnPercentWidth = (percent) => {
    return (percent * tableWidth) / 100;
  };

  const handlePageClick = (page) => {
    getCellTypeClusterData({ selectedCluster, page: page.selected });
  };

  const handleCloseModal = () => {
    toggleCellTypeClusterModal({
      isModalOpen: false,
      selectedCluster: '',
    });
  };

  const handleDataExport = (link) => {
    exportCellTypeClusterData({
      allowExportToCsv: true,
      selectedCluster,
      page: 0,
      size: totalElements,
      downloadLink: link,
      columns: getColumns(),
      fileName,
    });
  };

  const getColumns = () => [
    {
      label: 'Tissue',
      dataKey: 'tissueName',
      className: 'table-wrap__cell table-wrap__cell_left',
      width: getColumnPercentWidth(15),
      exportCSV: true,
      disableSort: true,
    },
    {
      label: 'Cell Type Cluster',
      dataKey: 'cellTypeCluster',
      className: 'table-wrap__cell table-wrap__cell_left',
      width: getColumnPercentWidth(35),
      exportCSV: true,
      disableSort: true,
    },
    {
      label: 'Gene Name',
      dataKey: 'geneName',
      className: 'table-wrap__cell table-wrap__cell_left',
      width: getColumnPercentWidth(35),
      exportCSV: true,
      disableSort: true,
    },
    {
      label: 'nTPM',
      dataKey: 'ntpm',
      className: 'table-wrap__cell table-wrap__cell_left',
      width: getColumnPercentWidth(15),
      exportCSV: true,
      disableSort: true,
    },
  ];

  const tableSettings = {
    width: 800,
    headerHeight: 50,
    rowClassName: 'table-wrap__row',
    autoHeight: true,
    rowHeight: 50,
  };

  return (
    isModalOpen &&
    <ModalComponent
      isOpen={isModalOpen}
      modalClassName="cell-type-cluster-modal"
      closeCb={handleCloseModal}
    >
      <div className="cell-type-cluster-modal__content">
        <h3 className="title4 text-center">{`Cell Type Cluster: ${selectedCluster}`}</h3>
        {
          totalPages > 1 &&
            <div className="paginationContainer">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                pageCount={totalPages}
                forcePage={currentPage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
        }
        {
          data.length > 0 &&
          <div className="cell-type-cluster-content__to-csv">
            <ExportTable
              content={data}
              columns={getColumns()}
              backendCall={link => handleDataExport(link)}
              fileName={fileName}
              exportLoading={exportLoading}
            />
          </div>
        }
        <Loader isLoading={loading} />
        {
          data.length > 0 &&
          <SimpleTable
            autoSize={true}
            settings={tableSettings}
            innerRef={tableRef}
            data={data}
            columns={getColumns()}
            dynamicHeight={true}
          />
        }
        <Error show={!loading && !!error} error={error} />
        <NoData
          show={
            !loading &&
            (data && data.length === 0)
          }
        />
      </div>
    </ModalComponent>
  );
};

CellTypeClusterModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getCellTypeClusterDataSelector(state),
    currentPage: SELECTORS.getCellTypeClusterDataCurrentPageSelector(state),
    totalPages: SELECTORS.getCellTypeClusterDataTotalPagesSelector(state),
    totalElements: SELECTORS.getCellTypeClusterDataTotalElementsSelector(state),
    loading: SELECTORS.getCellTypeClusterLoadingSelector(state),
    exportLoading: SELECTORS.getCellTypeClusterExportLoadingSelector(state),
    error: SELECTORS.getCellTypeClusterErrorSelector(state),
    isModalOpen: SELECTORS.getCellTypeClusterModalOpenSelector(state),
    selectedCluster: SELECTORS.getSelectedClusterSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCellTypeClusterData(data) {
      dispatch(ACTIONS.getCellTypeClusterDataAction(data));
    },
    exportCellTypeClusterData(data) {
      dispatch(ACTIONS.exportCellTypeClusterDataAction(data));
    },
    clearCellTypeClusterData(data) {
      dispatch(ACTIONS.clearCellTypeClusterDataAction(data));
    },
    toggleCellTypeClusterModal(data) {
      dispatch(ACTIONS.toggleIsModalOpenAction(data));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CellTypeClusterModal);
