import { createAction } from 'redux-actions';

// Concepts
export const getSearchConceptDataAction = createAction('@@concept-search/GET_DATA');
export const setSearchConceptDataAction = createAction('@@concept-search/SET_DATA');
export const throwSearchConceptDataErrorAction = createAction('@@concept-search/THROW_DATA_ERROR');
export const updateConceptSearchSelectedItemAction = createAction('@@concept-search/UPDATE_SELECTED_ITEM');
export const setSearchConceptInputValueAction = createAction('@@concept-search/SET_INPUT_VALUE');
// Filters
export const setSearchConceptFiltersAction = createAction('@@concept-search/SET_FILTERS');
export const resetSearchConceptFiltersAction = createAction('@@concept-search/RESET_FILTERS');
export const setActiveCategoriesAction = createAction('@@concept-search/ACTIVE_CATEGORIES');
export const setUseCustomSemTypesAction = createAction('@@concept-search/USE_CUSTOM_CATEGORIES');
export const setSelectedSetInfoAction = createAction('@@concept-search/SELECTED_SET_INFO');
// Manage
export const resetSearchConceptAction = createAction('@@concept-search/RESET');
export const resetSearchConceptDataAction = createAction('@@concept-search/RESET_DATA');
// Categories
export const getConceptSearchCategoriesAction = createAction('@@concept-search/GET_CATEGORIES');
export const setConceptSearchCategoriesAction = createAction('@@concept-search/SET_CATEGORIES');
export const throwConceptSearchCategoriesErrorAction = createAction('@@concept-search/THROW_CATEGORIES_ERROR');
