import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  tableSorting,
  categoryFilter,
} from '../../selectors';
import {
  getFuncCharacterizationTableDataAction,
  funcCharacterizationTableIsFailed,
  funcCharacterizationTableLoader,
} from '../../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';
import { exportToCSV } from '../../../../../Utils/Utils';

export function* initTableWorker(action) {
  try {
    yield put(funcCharacterizationTableLoader(true));
    const requestData = action.data;
    const { exportData } = action;
    const { sortBy, sortDirection } = yield select(tableSorting);
    const filter = yield select(categoryFilter);

    if (!requestData.params) {
      requestData.params = {
        size: 10,
        page: 0,
      };
    }

    requestData.params = {
      ...requestData.params,
      sort: `${sortBy === 'publicationDate' ? 'lastUpdateDate' : sortBy},${sortDirection}`,
    };

    const filters = {
      categories: !filter.length ? null : filter,
    };

    requestData.filter = filters;

    const response = yield call(Api.geneDetailFunctionalCharacterizationTable, requestData);
    if (exportData) {
      exportToCSV(exportData.link, exportData.columns, response.data.content, `${exportData.geneName}-functional-characterization`);
    } else {
      yield put(getFuncCharacterizationTableDataAction(response.data));
    }
    yield put(funcCharacterizationTableLoader(false));
    if (response.data.content && response.data.content.length) {
      yield put(geneDetailsSetChapterAction('functional-characterization'));
    }
    yield put(loadNextChapterAction('pathways'));
  } catch (e) {
    yield put(funcCharacterizationTableIsFailed(e.message));
    yield put(loadNextChapterAction('pathways'));
  }
}
