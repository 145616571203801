import { createSelector } from 'reselect';

const content = state => state.getIn(['publicationsListReducer', 'publications', 'content']);
const totalPages = state => state.getIn(['publicationsListReducer', 'publications', 'totalPages']);
const pageNumber = state => state.getIn(['publicationsListReducer', 'publications', 'number']);
const recommendations = state => state.getIn(['publicationsListReducer', 'recommendations']);
const selectedPublicationsIds = state => state.getIn(['publicationsListReducer', 'selectedPublicationsIds']);
const selectedPubmedIds = state => state.getIn(['publicationsListReducer', 'selectedPubmedIds']);
const publicationsLoading = state => state.getIn(['publicationsListReducer', 'loading']);
const publicationsError = state => state.getIn(['publicationsListReducer', 'error']);
const selectAll = state => state.getIn(['publicationsListReducer', 'selectAll']);

export const getPublicationsDataSelector = createSelector(
  content,
  selectedPublicationsIds,
  (_data, _selectedIds) => {
    const data = _data && _data.toJS();
    const selectedIds = _selectedIds && _selectedIds.toJS();
    const newData = data.map((item) => {
      if (selectedIds.includes(item.id)) {
        return {
          ...item,
          checked: true,
        };
      }
      return item;
    });
    return newData;
  }
);

export const getRecommedationsDataSelector = createSelector(
  recommendations,
  data => data.toJS()
);

export const getPublicationsManagementTotalPagesSelector = createSelector(
  totalPages,
  data => data
);

export const getPublicationsManagementpageNumberSelector = createSelector(
  pageNumber,
  data => data
);

export const getSelectedPublicationsIdsSelector = createSelector(
  selectedPublicationsIds,
  data => data && data.toJS()
);

export const getSelectedPubmedIdsSelector = createSelector(
  selectedPubmedIds,
  data => data && data.toJS()
);

export const getPublicationsLoadingSelector = createSelector(
  publicationsLoading,
  loading => loading
);

export const getPublicationsErrorSelector = createSelector(
  publicationsError,
  error => error
);

export const getPublicationsSelectAllSelector = createSelector(
  selectAll,
  data => data
);
