import React from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonCircle from '../../../../common/Buttons/ButtonCircle/ButtonCircle';
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
import RadioInput from '../../../../common/Inputs/RadioInput/RadioInput';
import Button from '../../../../common/Buttons/Button/Button';
import Spinner from '../../../../common/Spinner/Spinner';
// Icons
import { FaInfo } from 'react-icons/fa';
import  { AiOutlineUndo } from 'react-icons/ai';
// Constants
import { proteinExpressionSortingOptions } from '../../constants';

const propTypes = {
  type: PropTypes.string,
  drawing: PropTypes.bool,
  exportToPNGCb: PropTypes.func,
  exportToSVGCb: PropTypes.func,
  isNeededTransform: PropTypes.bool,
  isHiddenExport: PropTypes.bool,
  selectedUniprot: PropTypes.string,
  uniprotOptions: PropTypes.instanceOf(Object),
  showUniprotSelector: PropTypes.bool,
  isCsvDownloadingAllowed: PropTypes.bool,
  setSelectedUniprotOption: PropTypes.func,
  selectedProteinExpression: PropTypes.instanceOf(Object),
  showProteinExpressionSortSelector: PropTypes.bool,
  setSelectedProteinExpressionOption: PropTypes.func,
  selectedSortingOption: PropTypes.instanceOf(Object),
  showExpressionSortSelector: PropTypes.bool,
  setSelectedSortingOption: PropTypes.func,
  sortingOptions: PropTypes.instanceOf(Array),
  chartTransform: PropTypes.string,
  changeChartTransform: PropTypes.func,
  csvDownloadedSamplesLoading: PropTypes.bool,
  info: PropTypes.string,
  showInfo: PropTypes.bool,
  setShowInfo: PropTypes.func,
  selectedCells: PropTypes.instanceOf(Array),
  handleExportToCSV: PropTypes.func,
  handleExportAllToCSV: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setTissuesSelectionMode: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  downloadLinkRef: PropTypes.instanceOf(Object),
};

const ChartController = (props) => {
  const {
    type,
    drawing = false,
    isCsvDownloadingAllowed,
    isNeededTransform,
    isHiddenExport,
    uniprotOptions,
    selectedUniprot,
    showUniprotSelector,
    setSelectedUniprotOption,
    selectedProteinExpression,
    showProteinExpressionSortSelector,
    setSelectedProteinExpressionOption,
    sortingOptions,
    selectedSortingOption,
    showExpressionSortSelector,
    setSelectedSortingOption,
    chartTransform,
    changeChartTransform,
    exportToPNGCb,
    exportToSVGCb,
    info,
    showInfo,
    setShowInfo,
    selectedCells,
    handleExportToCSV,
    handleExportAllToCSV,
    csvDownloadedSamplesLoading,
    tissuesSelectionMode,
    setTissuesSelectionMode,
    unsetTissuesSelectionMode,
    downloadLinkRef,
  } = props;

  return (
    <div>
      <div className="violin-chart__controls-wrapper">
        {
          <div className="gene-expression-sort-methods">
            {
              showUniprotSelector &&
              <div className="gene-expression-sort-methods__uniprot">
                <span className="gene-expression-sort-methods__label">Uniprot Accession: </span>
                <SelectInput
                  options={uniprotOptions}
                  placeholder="Select"
                  closeOnSelect={true}
                  bordered={true}
                  customClassName="gene-expression-sort-methods__selector"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                  onSelect={option => setSelectedUniprotOption({ option, type })}
                  defaultValue={uniprotOptions.find(o => o.value === selectedUniprot)}
                />
              </div>
            }
            {
              showProteinExpressionSortSelector &&
              <div className="gene-expression-sort-methods__protein-expression">
                <span className="gene-expression-sort-methods__label">Protein expression: </span>
                <SelectInput
                  onSelect={option => setSelectedProteinExpressionOption({ option, type })}
                  defaultValue={selectedProteinExpression}
                  options={proteinExpressionSortingOptions}
                  placeholder="Select"
                  closeOnSelect={true}
                  bordered={true}
                  customClassName="gene-expression-sort-methods__selector"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                />
              </div>
            }
            {
              showExpressionSortSelector &&
              <div className="gene-expression-sort-methods__expression-sorting">
                <span className="gene-expression-sort-methods__label">Sorting: </span>
                <SelectInput
                  onSelect={option => setSelectedSortingOption({ option, type })}
                  defaultValue={selectedSortingOption}
                  options={sortingOptions}
                  placeholder="Select"
                  closeOnSelect={true}
                  bordered={true}
                  customClassName="gene-expression-sort-methods__selector"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                />
              </div>
            }
          </div>
        }
        <div className="boxplot-controls">
          {
            !drawing && isNeededTransform &&
            <div className="boxplot-controls__radio">
              <span className="boxplot-controls__text">Scale:</span>
              <RadioInput
                value="linear"
                checked={chartTransform === 'linear'}
                onChange={changeChartTransform}
                labelValue="Linear"
              />
              <RadioInput
                value="log"
                checked={chartTransform === 'log'}
                onChange={changeChartTransform}
                labelValue="Log"
              />
            </div>
          }
          {
            !drawing && !isHiddenExport &&
            <div className="bar-chart-controls">
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button bar-chart-controls__png"
                  onClick={exportToPNGCb}
                  title="Export to png"
                />
                PNG
              </div>
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button"
                  onClick={exportToSVGCb}
                  title="Export to svg"
                />
                SVG
              </div>
              {
                info &&
                <ButtonCircle
                  icon={<FaInfo size={12} color="#ffffff" />}
                  onClick={() => { setShowInfo(!showInfo); }}
                  customClassName="violin-chart-btn"
                />
              }
            </div>
          }
        </div>
        {
          showInfo &&
          <div className="violin-chart-info">
            {info}
          </div>
        }
      </div>
      {
        isCsvDownloadingAllowed &&
        <div className="csv-downloading-block">
          <div className="csv-btn-group btn-group">
            {
              !drawing &&
              <>
                <Spinner
                  isLoading={csvDownloadedSamplesLoading}
                  containerClassName="csv-btn-group__spiner"
                />
                <label htmlFor="export">To CSV:</label>
                {
                  <Button
                    text="All"
                    onClick={handleExportAllToCSV}
                    disabled={csvDownloadedSamplesLoading}
                    customClassName="btn btn-group__first-btn"
                  />
                }
                {
                  !tissuesSelectionMode &&
                  <Button
                    text="Allow tissues selection"
                    onClick={setTissuesSelectionMode}
                    disabled={csvDownloadedSamplesLoading}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  tissuesSelectionMode &&
                  <Button
                    text="Export selected"
                    onClick={handleExportToCSV}
                    disabled={!selectedCells.length || csvDownloadedSamplesLoading}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  <Button
                    onClick={unsetTissuesSelectionMode}
                    customClassName="btn btn-group__last-btn"
                  >
                    <AiOutlineUndo size={24} />
                  </Button>
                }
                <a //eslint-disable-line
                  hidden={true}
                  ref={downloadLinkRef}
                />
              </>
            }
          </div>
          {
            tissuesSelectionMode &&
            <div className="violin-chart__csv-text">
              Select tissue names on x-axis to export data as CSV
            </div>
          }
        </div>
      }
    </div>
  );
};

ChartController.propTypes = propTypes;

export default ChartController;
