import {
  createSelector,
} from 'reselect';

import { sort } from '../../../../Utils/Utils';

const table = state => state.getIn(['pathDetailsSmallMolecules', 'pathDetailsSmallMolecules']);
const error = state => state.getIn(['pathDetailsSmallMolecules', 'error']);
const loading = state => state.getIn(['pathDetailsSmallMolecules', 'loading']);
const sorting = state => state.getIn(['pathDetailsSmallMolecules', 'pathDetailsSmallMoleculesSorting']);

export const getPathDetailsSmallMoleculesSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getPathDetailsSmallMoleculesTable = createSelector(
  table,
  sorting,
  (content, _sorting) => {
    const sorted = sort(content, _sorting.toJS());
    return sorted.toJS();
  }
);

export const getError = createSelector(
  error,
  data => data
);

export const getLoading = createSelector(
  loading,
  data => data
);
