import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

// Components
import AutocompleteDropdown from './AutocompleteDropdown';
// Styles
import './styles.scss';

const propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.instanceOf(Array),
};

const Autocomplete = (props) => {
  const {
    initialValue = '',
    onChange,
    onSelect,
    options,
    ...rest
  } = props;
  const ref = useRef(null);

  const [inputValue, setInputValue] = useState(initialValue);
  const [isActive, setIsActive] = useState(false);

  const debounceOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setInputValue(value);
    debounceOnChange(value);
    setIsActive(true);
  }, [debounceOnChange]);

  const handleFocus = useCallback((e) => {
    const { value } = e.target;
    onChange(value);
    setIsActive(true);
  }, [onChange]);

  const handleSelect = useCallback((option) => {
    setInputValue(option.name);
    onSelect(option);
    setIsActive(false);
  }, [onSelect]);

  useEffect(() => {
    if (isActive) {
      const triggerCloseDropdown = (e) => {
        if (ref?.current && !ref.current.contains(e.target)) {
          setIsActive(false);
        }
      };
      document.body.addEventListener('click', triggerCloseDropdown);
      return () =>
        document.body.removeEventListener('click', triggerCloseDropdown);
    }
  }, [isActive]);

  return (
    <div className="autocomplete" ref={ref}>
      <input
        className="input"
        type="text"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        {...rest}
      />
      <AutocompleteDropdown
        handleSelect={handleSelect}
        isActive={isActive}
        options={options}
      />
    </div>
  );
};

Autocomplete.propTypes = propTypes;

export default React.memo(Autocomplete);