function getFromEnvOrWindow(key) {
  const envVariable = process.env[key];

  if (envVariable) {
    return envVariable;
  }

  return window.env[key];
}

export const CUSTOMER = getFromEnvOrWindow('REACT_APP_CUSTOMER');
export const API_URL = getFromEnvOrWindow('REACT_APP_API');
export const ENV = getFromEnvOrWindow('REACT_APP_ENV');
