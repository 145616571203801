import { createAction } from 'redux-actions';

export const updateSaveAsSetTagsAction = createAction('@@saveAsSet/UPDATE_TAGS');
export const updateSaveAsSetTypeAction = createAction('@@saveAsSet/UPDATE_TYPE');
export const putSaveAsSetComplexDataAction = createAction('@@saveAsSet/PUT_COMPLEX_DATA');
export const toggleSaveAsSetComplexDialogAction = createAction('@@saveAsSet/TOGGLE_COMPLEX_DIALOG');
export const setSaveAsSetComplexDataAction = createAction('@@saveAsSet/SET_COMPLEX_DATA');
export const resetSaveAsSetDataAction = createAction('@@saveAsSet/RESET');
// Save
export const saveAsSetRequestedAction = createAction('@@saveAsSet/REQUESTED');
export const saveAsSetSucceededAction = createAction('@@saveAsSet/SUCCEEDED');
export const throwSaveAsSetErrorAction = createAction('@@saveAsSet/THROW_ERROR');
// Concepts
export const getSaveAsSetConceptsAction = createAction('@@saveAsSet/GET_CONCEPTS');
export const updateSaveAsSetConceptsAction = createAction('@@saveAsSet/UPDATE_CONCEPTS');
export const throwSaveAsSetConceptsErrorAction = createAction('@@saveAsSet/THROW_CONCEPTS_ERROR');
export const sortSaveAsSetConceptsAction = createAction('@@saveAsSet/SORT_CONCEPTS');
export const getSaveAsSetConceptsFromFileAction = createAction('@@saveAsSet/GET_CONCEPTS_FROM_FILE');
export const pagginateSaveAsSetConceptsAction = createAction('@@saveAsSet/PAGGINATE');