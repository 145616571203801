import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FormSection } from 'redux-form/immutable';
import { compose } from 'redux';

// Components
import Input from '../../../../common/Inputs/Input/Input';
import FormInput from '../../../../common/FormElements/FormInput';
import FormSelect from '../../../../common/FormElements/FormSelect';
import CreateSetGeneFilters from '../../../../Sets/CreateSet/Components/CreateSetGeneFilters/CreateSetGeneFilters';
import CreateSetDiseasesFilters from '../../../../Sets/CreateSet/Components/CreateSetDiseasesFilters/CreateSetDiseasesFilters';
import CreateSetFilterField from '../../../../Sets/CreateSet/Components/CreateSetFilterField/CreateSetFilterField';
import CreateSetPublicationsFilters
  from '../../../../Sets/CreateSet/Components/CreateSetPublicationsFilters/CreateSetPublicationsFilters';
// Utils
import { prepareFiltersValuesForRequest, getSearchFilteredApiRequestPayload } from '../../../../Sets/CreateSet/utils';

const propTypes = {
  options: PropTypes.instanceOf(Array),
  conceptsIds: PropTypes.instanceOf(Array),
  conceptsNames: PropTypes.instanceOf(Array),
  startConceptsIds: PropTypes.instanceOf(Array),
  beFilters: PropTypes.instanceOf(Object),
  beFiltersType: PropTypes.string,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  setFilters: PropTypes.func,
  resetFilters: PropTypes.func,
  reset: PropTypes.func,
  getBeFilters: PropTypes.func,
  resetBeFilters: PropTypes.func,
  getFilteredConcepts: PropTypes.func,
  getGeneFilterOptions: PropTypes.func,
  filterValue: PropTypes.string,
  filterConcepts: PropTypes.func,
  change: PropTypes.func,
  getSourceDetailsFilterOptions: PropTypes.func,
  allConceptsIds: PropTypes.instanceOf(Array),
  getIntermediateConcepts: PropTypes.func,
};

class IntermediateConceptsFilter extends Component {
  getGeneConceptsRequestData = (filterData) => {
    const { allConceptsIds, startConceptsIds, beFilters } = this.props;
    const filtersData = prepareFiltersValuesForRequest(filterData, beFilters, false, '', startConceptsIds);
    return getSearchFilteredApiRequestPayload(startConceptsIds, allConceptsIds, filtersData);
  };

  handleSubmit = (formData) => {
    const {
      getBeFilters,
      resetBeFilters,
      beFiltersType,
      getIntermediateConcepts,
      startConceptsIds,
    } = this.props;
    const filterData = formData.toJS();
    const { category = {} } = filterData;

    const { label } = category || { label: '' };

    switch (label) {
      case 'Gene or Genome': {
        getBeFilters({ category: 'Genes', startConceptsIds });
        getIntermediateConcepts({
          requestData: this.getGeneConceptsRequestData(filterData),
          filters: filterData,
        });
        break;
      }

      case 'Diseases': {
        if (beFiltersType === 'Diseases') {
          getIntermediateConcepts({
            requestData: this.getGeneConceptsRequestData(filterData),
            filters: filterData,
          });
        } else {
          getBeFilters({ category: 'Diseases', startConceptsIds });
        }
        break;
      }

      default: {
        if (beFiltersType) {
          resetBeFilters();
          getBeFilters({ startConceptsIds });
        } else {
          getIntermediateConcepts({
            requestData: this.getGeneConceptsRequestData(filterData),
            filters: filterData,
          });
        }
        break;
      }
    }
  };

  handleReset = () => {
    const { reset, resetFilters } = this.props;
    reset();
    resetFilters();
  };

  componentDidMount() {
    const {
      startConceptsIds,
      getBeFilters,
    } = this.props;
    getBeFilters({ startConceptsIds });
  }

  render() {
    const {
      change,
      options,
      conceptsIds,
      allConceptsIds,
      handleSubmit,
      conceptsNames,
      beFilters,
      filterValue,
      filterConcepts,
      startConceptsIds,
      getGeneFilterOptions,
      getSourceDetailsFilterOptions,
    } = this.props;

    return (
      <div className="create-set-filter">
        <div className="create-set-filter__title">
          <div className="title5">Available filters</div>
        </div>
        <form
          className="create-set-filter__form"
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <div className="create-set-filter__scrolled-wrap">
            <div className="create-set-filter__wrap">
              <div className="create-set-filter__block create-set-filter__block_no-padding">
                <span className="create-set-filter__label">
                  <span className="create-set-filter__label-text">
                    Search concepts
                  </span>
                </span>
                <Input
                  value={filterValue}
                  placeholder="Concept name"
                  onChange={filterConcepts}
                />
              </div>
            </div>
            <CreateSetFilterField
              fieldLabel="Category"
              fieldProps={{
                name: 'category',
                options,
                returnOption: true,
                isClearable: true,
                component: FormSelect,
              }}
            />
            {
              conceptsNames.map((c, i) => (
                <div className="create-set-filter__wrap create-set-filter__no-bottom" key={`${c}-${i}`}>
                  <FormSection className="create-set-filter__block create-set-filter__block_no-padding" name={`concept${i + 1}`}>
                    <div className="title5">{c}</div>
                    <div className="create-set-filter__row">
                      <CreateSetFilterField
                        customClass="create-set-filter__block_no-padding create-set-filter__no-bottom"
                        fieldLabel="Number of references from"
                        fieldProps={{
                          name: 'from',
                          type: 'number',
                          placeholder: 'Number',
                          component: FormInput,
                        }}
                      />
                      <CreateSetFilterField
                        customClass="create-set-filter__block_no-padding create-set-filter__no-bottom"
                        fieldLabel="Number of references to"
                        fieldProps={{
                          name: 'to',
                          type: 'number',
                          placeholder: 'Number',
                          component: FormInput,
                        }}
                      />
                    </div>
                  </FormSection>
                </div>
              ))
            }
            {
              beFilters &&
              <>
                <CreateSetPublicationsFilters
                  formValueChange={change}
                  conceptIds={startConceptsIds}
                  filteredConceptsIds={conceptsIds}
                  availableFilters={beFilters}
                  getSourceDetailsFilterOptions={getSourceDetailsFilterOptions}
                />
                <CreateSetGeneFilters
                  availableFilters={beFilters}
                  conceptsIds={conceptsIds}
                  getGeneFilterOptions={getGeneFilterOptions}
                />
                <CreateSetDiseasesFilters availableFilters={beFilters} />
              </>
            }
            <div className="create-set-filter__controls">
              <button
                type="button"
                className="button mr-15"
                onClick={this.handleReset}
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!allConceptsIds || !allConceptsIds.length}
                className="button button-primary"
              >
                Apply
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

IntermediateConceptsFilter.propTypes = propTypes;

function validate(values) {
  const errors = {
    PUBLICATION_FILTERS: {},
    concept1: {},
    concept2: {},
    concept3: {},
  };

  const concepts = ['concept1', 'concept2', 'concept3'];

  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountFrom']) > 92233720368547) {
    errors.PUBLICATION_FILTERS.publicationsCountFrom = 'References value is to big';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountFrom']) < 1) {
    errors.PUBLICATION_FILTERS.publicationsCountFrom = 'References value is to small';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountTo']) > 92233720368547) {
    errors.PUBLICATION_FILTERS.publicationsCountTo = 'References value is to big';
  }
  if (values.getIn(['PUBLICATION_FILTERS', 'publicationsCountTo']) < 1) {
    errors.PUBLICATION_FILTERS.publicationsCountTo = 'References value is to small';
  }

  concepts.forEach(concept => {
    if (values.getIn([concept, 'from']) > 92233720368547) {
      errors[concept].from = 'References value is to big';
    }
    if (values.getIn([concept, 'from']) < 1) {
      errors[concept].from = 'References value is to small';
    }
    if (values.getIn([concept, 'to']) > 92233720368547) {
      errors[concept].to = 'References value is to big';
    }
    if (values.getIn([concept, 'to']) < 1) {
      errors[concept].to = 'References value is to small';
    }
  });

  return errors;
}

const composition = compose(
  reduxForm({
    form: 'INTERMEDIATE_CONCEPTS_FILTER',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: true,
    touchOnChange: true,
  }),
);

export default composition(IntermediateConceptsFilter);
