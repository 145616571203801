import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form/lib/immutable';
// Store
import { getTaxonomiesListSelector, getTaxonomiesLoadingSelector } from './selectors';
import { fetchTaxonomiesRequestAction, resetTaxonomiesAction } from './reducer';

const propTypes = {
  taxonomies: PropTypes.instanceOf(Array).isRequired,
  fetchTaxonomies: PropTypes.func.isRequired,
  resetTaxonomies: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dropDownHeight: PropTypes.number,
  defaultValue: PropTypes.instanceOf(Object),
};

const TaxonomiesField = (props) => {
  const {
    taxonomies,
    dropDownHeight,
    fetchTaxonomies,
    resetTaxonomies,
    ...fieldProps
  } = props;

  const styles = {
    menuList: provided => ({
      ...provided,
      maxHeight: `${dropDownHeight}px`,
      overflow: 'auto',
    }),
  };

  useEffect(() => {
    return resetTaxonomies;
  }, []);

  return (
    <Field
      { ...fieldProps }
      styles={styles}
      bordered={true}
      options={taxonomies.map(el => ({
        value: el,
        label: el,
      }))}
      onFocus={fetchTaxonomies}
    />
  );
};

TaxonomiesField.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
  return {
    taxonomies: getTaxonomiesListSelector(state, ownProps),
    loading: getTaxonomiesLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTaxonomies() {
      dispatch(fetchTaxonomiesRequestAction());
    },
    resetTaxonomies() {
      dispatch(resetTaxonomiesAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxonomiesField);
