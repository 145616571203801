export const samplesChartDataExport = {
  BEAT_AML: false,
  CELL_TYPE: true,
  GTEX: true,
  TCGA: true,
  CCLE: true,
  CPTAC: false,
  CCLE_PROTEOMICS: true,
  SANGER_CELL_MODEL: true,
  HPA_PER_TISSUES: true,
};
