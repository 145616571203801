import { call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  setPublicationDetailsDataAction,
  setPublicationIdDataAction,
  throwPublicationDetailsErrorAction,
  togglePublicationDetailsChapterAction,
} from '../../reducer';
import { PubDetailsChaptersEnum } from '../../constants';
import { calcSliderValue } from '../../Utils';

export function* getPublicationDetailsByPubMedIdDataWorker(action) {
  try {
    const { data } = yield call(Api.pubmedPublication, { id: action.payload });
    const sliderValue = calcSliderValue(data.concepts);
    yield put(setPublicationDetailsDataAction({ data, sliderValue }));
    yield put(setPublicationIdDataAction(data));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationCitations,
      show: true,
    }));
  } catch (e) {
    yield put(throwPublicationDetailsErrorAction(e.message));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationCitations,
      show: true,
    }));
  }
}
