export const columnTypes = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
};

export const filterValues = [
  {
    label: 'contains',
    value: 'contains',
    types: [columnTypes.string],
  },
  {
    label: '=',
    value: '=',
    types: [columnTypes.string, columnTypes.number, columnTypes.boolean],
  },
  {
    label: '!=',
    value: '!=',
    types: [columnTypes.string, columnTypes.number, columnTypes.boolean],
  },
  {
    label: '>',
    value: '>',
    types: [columnTypes.number],
  },
  {
    label: '<',
    value: '<',
    types: [columnTypes.number],
  },
];

export const columnType = {
  geneName: columnTypes.string,
  classifications: columnTypes.string,
  differentialExpression: columnTypes.number,
  associationScore: columnTypes.number,
  geneVariation: columnTypes.number,
  literatureSearch: columnTypes.number,
  compoundScore: columnTypes.number,
  geneticInteractions: columnTypes.number,
  openTargetsScore: columnTypes.number,
  druggableStructure: columnTypes.boolean,
  signalingDomain: columnTypes.number,
  intramembrane: columnTypes.number,
  transMembraneDomain: columnTypes.number,
  secretion: columnTypes.number,
  count: columnTypes.number,
  rankingTrialStage: columnTypes.string,
  mostFrequentIndications: columnTypes.string,
};
