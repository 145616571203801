import { fromJS } from 'immutable';

import {
  GENE_DISEASE_INIT_SUCCEEDED,
  GENE_DISEASE_INIT_FAILED,
  GENE_DISEASE_RESET_STATE,
  GENE_DISEASE_FETCH_TISSUES_SUCCESS,
  GENE_DISEASE_FETCH_TISSUES_FAILURE,
  GENE_DISEASE_LOAD_CHAPTER,
  GENE_DISEASE_UNLOAD_CHAPTERS,
  GENE_DISEASE_SET_LAST_VISITED_GEN_ID,
  GENE_DISEASE_TISSUES_LOADER,
  GENE_DISEASE_SET_CHAPTER_DATA,
} from './constants';

const initialState = fromJS({
  data: {},
  error: null,
  loading: true,
  tissuesData: {},
  tissuesLoader: false,
  lastVisitedGeneId: '',
  lastVisitedDiseaseId: '',
  pageChapters: [
    { name: 'gene-disease-expression', isLoaded: false },
    { name: 'gene-disease-annotation', isLoaded: false },
    { name: 'differential-expression', isLoaded: false },
    { name: 'gene-mutation', isLoaded: false },
    { name: 'animal-model', isLoaded: false },
    { name: 'metabolites', isLoaded: false },
    { name: 'phenotypes', isLoaded: false },
    { name: 'pathways', isLoaded: false },
    { name: 'cell-molecular', isLoaded: false },
    { name: 'organ-tissue', isLoaded: false },
    { name: 'conclusion-section', isLoaded: false },
  ],
  pageChaptersData: [
    { name: 'gene-disease-expression', isDataExist: false },
    { name: 'gene-disease-annotation', isDataExist: false },
    { name: 'differential-expression', isDataExist: false },
    { name: 'gene-mutation', isDataExist: false },
    { name: 'animal-model', isDataExist: false },
    { name: 'metabolites', isDataExist: false },
    { name: 'phenotypes', isDataExist: false },
    { name: 'pathways', isDataExist: false },
    { name: 'cell-molecular', isDataExist: false },
    { name: 'organ-tissue', isDataExist: false },
    { name: 'conclusion-section', isDataExist: false },
  ],
});

const geneDisease = (state = initialState, action) => {
  switch (action.type) {
    case GENE_DISEASE_RESET_STATE:
      return initialState;

    case GENE_DISEASE_INIT_SUCCEEDED:
      return state.merge(fromJS({ data: action.data, error: null, loading: false }));

    case GENE_DISEASE_INIT_FAILED:
      return state.merge(fromJS({ data: {}, error: action.message, loading: false }));

    case GENE_DISEASE_FETCH_TISSUES_SUCCESS:
      return state.merge(fromJS({ tissuesData: action.data, error: null, loading: false }));

    case GENE_DISEASE_FETCH_TISSUES_FAILURE:
      return state.merge(fromJS({ tissuesData: {}, error: action.message, loading: false }));

    case GENE_DISEASE_TISSUES_LOADER: {
      return state.update('tissuesLoader', () => action.data);
    }

    case GENE_DISEASE_SET_LAST_VISITED_GEN_ID:
      return state
        .set('lastVisitedGeneId', action.data.geneId)
        .set('lastVisitedDiseaseId', action.data.diseaseId);

    case GENE_DISEASE_LOAD_CHAPTER: {
      return state.update('pageChapters', data => data.map((item) => {
        if (item.get('name') === action.payload) {
          return item.set('isLoaded', true);
        }
        return item;
      }));
    }

    case GENE_DISEASE_SET_CHAPTER_DATA: {
      return state.update('pageChaptersData', data => data.map((item) => {
        if (item.get('name') === action.data) {
          return item.set('isDataExist', true);
        }
        return item;
      }));
    }

    case GENE_DISEASE_UNLOAD_CHAPTERS: {
      return state
        .update('pageChapters', data => data.map(item => item.set('isLoaded', false)));
    }

    default:
      return state;
  }
};

export default geneDisease;
