import { call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Utils
import {
  prepareHealthCellData,
  transformExpressionLinesData,
  prepareUniqueObjectData,
  prepareDataGroupsWithColor,
  prepareViolinChartData
} from '../../utils';
import { prepareSelectedTissuesExpressionData } from '../../../../Workflows/Algorithms/SingleTargetForCancer/utils';
// Store
import {
  loadNextChapterAction,
  geneDetailsSetChapterAction,
} from '../../../actions';
import * as a from '../../reducer';
// Constants
import { selectedTissuesColors } from '../../../../Workflows/Algorithms/SingleTargetForCancer/constants';
import { proteinExpressionSortingOptions } from '../../constants';

function* getHPAData(id) {
  try {
    const { data: hpaData } = yield call(Api.geneDetailHPAData, { id });

    const cellTypeGroupsForHpaPerCellTypes = prepareUniqueObjectData(hpaData.cellTypes, 'cellTypeGroup');
    const cellTypeGroupsForHpaPerTissues = prepareUniqueObjectData(hpaData.tissues, 'cellTypeGroup');
    const cellTypesForHpaPerTissues = prepareUniqueObjectData(hpaData.tissues, 'cellType');

    const cellTypeGroupsWithColors = prepareDataGroupsWithColor(cellTypeGroupsForHpaPerCellTypes);
    const cellTypeGroupsWithColorsForHpaPerTissues = prepareDataGroupsWithColor(cellTypeGroupsForHpaPerTissues);


    const cellTypesData = hpaData.cellTypes.map(i => ({ ...i, color: cellTypeGroupsWithColors[i.cellTypeGroup].color }));
    const tissuesData = hpaData.tissues.map(i => ({ ...i, color: cellTypeGroupsWithColorsForHpaPerTissues[i.cellTypeGroup].color }));

    yield put(a.HPADataSucceeded({
      tissuesData,
      cellTypesData,
      cellTypeGroupsWithColors,
      cellTypeGroupsForHpaPerCellTypes,
      cellTypeGroupsForHpaPerTissues,
      cellTypesForHpaPerTissues,
      cellTypeGroupsWithColorsForHpaPerTissues,
    }));
  } catch (error) {
    yield put(a.HPADataFailed(error.message));
  }
}

function* getCPTACData(id) {
  try {
    const { data } = yield call(Api.geneDetailCPTACData, { id });

    const transformedCptacData = data.reduce((acc, item) => {
      if (item.tissueType === 'Tumor Tissue') {
        if (acc.tumorTissueData[item.tumorName]) {
          acc.tumorTissueData[item.tumorName] = {...acc.tumorTissueData[item.tumorName], points: [...acc.tumorTissueData[item.tumorName].points, item.value]};
        } else {
          acc.tumorTissueData[item.tumorName] = { ...item, concept: { name: item.tumorName, id: item.geneId }, group: item.tissueType, points: [item.value]};
        }
      }

      if (item.tissueType === 'Normal/Healthy Tissue') {
        if (acc.healtyTissueData[item.tumorName]) {
          acc.healtyTissueData[item.tumorName] = {...acc.healtyTissueData[item.tumorName], points: [...acc.healtyTissueData[item.tumorName].points, item.value]};
        } else {
          acc.healtyTissueData[item.tumorName] = { ...item, concept: { name: item.tumorName, id: item.geneId }, group: item.tissueType, points: [item.value]};
        }
      }
      return acc;
    }, { tumorTissueData: {}, healtyTissueData: {} });

    const tumorTissueData = Object.values(transformedCptacData.tumorTissueData);
    const healtyTissueData =  Object.values(transformedCptacData.healtyTissueData);

    yield put(a.CPTACDataSucceeded({
      data,
      tumorTissueData: prepareViolinChartData(tumorTissueData, 'CPTAC', 'linear', false, true),
      healtyTissueData: prepareViolinChartData(healtyTissueData, 'CPTAC', 'linear', false, true)
    }));

  } catch (error) {
    yield put(a.CPTACDataFailed(error.message));
  }
}

export function* sagaExpressionChapter(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.geneDetailExpression, { id });
    yield* getHPAData(id);
    yield* getCPTACData(id);

    const {
      hpmTissues,
      gxteTissues,
      geneCcleExpressions,
      gtexExpression,
      healthCellExpression,
      tcgaExpression,
      ccleProteomicExpressions,
      sangerCellModelExpressions,
    } = data;

    let geneCcleExpressionSorted = [];
    let gtexExpressionSorted = [];
    let tcgaExpressionSorted = [];
    let geneCcleProteomicExpressionsSorted = [];
    let sangerCellModelExpressionsSorted = [];
    const sortExpressionData = array => array.sort((b, c) => b.concept.name.localeCompare(c.concept.name));

    if (geneCcleExpressions && geneCcleExpressions.length > 0) {
      const transformedCcleData = transformExpressionLinesData(geneCcleExpressions, 'ccleLines');
      geneCcleExpressionSorted = sortExpressionData(transformedCcleData);
    }

    if (ccleProteomicExpressions && ccleProteomicExpressions.length > 0) {
      geneCcleProteomicExpressionsSorted = ccleProteomicExpressions.map(i => ({ ...i, expressions: sortExpressionData(transformExpressionLinesData(i.expressions, 'ccleLines')) }));
    }

    if (sangerCellModelExpressions && sangerCellModelExpressions.length > 0) {
      sangerCellModelExpressionsSorted = sangerCellModelExpressions.map(i => ({ ...i, expressions: sortExpressionData(transformExpressionLinesData(i.expressions, 'cellLines', proteinExpressionSortingOptions[0].value)) }));
    }

    if (gtexExpression && gtexExpression.length > 0) {
      gtexExpressionSorted = sortExpressionData(gtexExpression);
    }

    if (tcgaExpression && tcgaExpression.length > 0) {
      const normalTissueGroup = tcgaExpression.filter(i => i.group === 'Normal Tissue');
      const tumorGroup = tcgaExpression.filter(i => i.group === 'Tumor');

      tcgaExpressionSorted = [
        ...prepareSelectedTissuesExpressionData(
          { TCGA: normalTissueGroup },
          false,
          selectedTissuesColors.NORMAL_TISSUES,
        ),
        ...prepareSelectedTissuesExpressionData(
          { TCGA: tumorGroup },
          false,
          selectedTissuesColors.TUMOR_TISSUES,
        ),
      ];
    }

    if (healthCellExpression) {
      const healthCellData = prepareHealthCellData(healthCellExpression);
      yield put(a.geneDetailsHealthCellExpressionInitSucceeded(healthCellData));
    }

    const geneExpressionData = {
      gtexExpression: gtexExpressionSorted,
      tcgaExpression: tcgaExpressionSorted,
      сcleExpression: geneCcleExpressionSorted,
      ccleProteomicExpressions: geneCcleProteomicExpressionsSorted,
      sangerCellModelExpressions: sangerCellModelExpressionsSorted,
      hpmTissues: hpmTissues ?
        hpmTissues.sort((b, c) => b.name.localeCompare(c.name)) : [],
    };

    yield put(a.geneExpressionInitSucceeded(geneExpressionData));
    if ((gtexExpression && gtexExpression.length) || (gxteTissues && gxteTissues.length) || (hpmTissues && hpmTissues.length)) {
      yield put(geneDetailsSetChapterAction('gene-expression'));
    }

    yield put(loadNextChapterAction('condition-of-dysregulation'));
  } catch (e) {
    yield put(a.geneExpressionInitFailed(e.message));
    yield put(loadNextChapterAction('condition-of-dysregulation'));
  }
}

