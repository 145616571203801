import { startSubmit, stopSubmit } from 'redux-form/immutable';
import { takeLatest, call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Utils
import { history } from '../../../index';
import {
  EFFECT_SET_FROM_COMPLEX_SET_FORM,
  SIMPLE_SET_FROM_COMPLEX_SET_FORM,
} from './enums';
import { convertTableToCSVText, exportToFile } from '../../Utils/Utils';
// Store
import {
  effectSetItemsFormatted,
  getComplexSetToEffectSetFormValues,
  getComplexSetToSimpleSetFormValues,
  getDescription,
  getName,
  setItemsFormatted,
  tagListFormatted,
  getComplexSetTags,
  getTotalElements,
  getColumnsConfiguration,
  getVersion,
} from './selectors';
import {
  FETCH_COMPLEX_SET_DATA_REQUEST,
  FETCH_COMPLEX_SET_DATA_SUCCESS,
  FETCH_COMPLEX_SET_DATA_FAILURE,
  DOWNLOAD_COMPLEX_SET_DATA_REQUEST,
  SIMPLE_SET_ANALYZE_REQUEST,
  SIMPLE_SET_ANALYZE_SUCCESS,
  SIMPLE_SET_SAVE_REQUEST,
  SIMPLE_SET_ANALYZE_FAILURE,
  SIMPLE_SET_SAVE_SUCCESS,
  SIMPLE_SET_SAVE_FAILURE,
  EFFECT_SET_ANALYZE_REQUEST,
  EFFECT_SET_ANALYZE_SUCCESS,
  EFFECT_SET_ANALYZE_FAILURE,
  EFFECT_SET_SAVE_REQUEST,
  COMPLEX_SET_APPLY_FILTER,
  COMPLEX_SET_RESET_FILTERS,
  COMPLEX_SET_SAVE_FILTERED,
  COMPLEX_SET_APPLY_AGGREGATION,
  COMPLEX_SET_ADD_AGGREGATION_RULE,
  COMPLEX_SET_UPDATE_CONFIG_REQUEST,
  COMPLEX_SET_UPDATE_CONFIG_SUCCESS,
  COMPLEX_SET_UPDATE_CONFIG_FAILURE,
  COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL,
  EFFECT_SET_SAVE_SUCCESS,
  EFFECT_SET_SAVE_FAILURE,
  SET_PARENT_TAGS,
  CLEAR_COMPLEX_SET_ERROR,
  HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE,
  HIDE_MODAL_FROM_COMPLEX_TO_EFFECT,
} from './constants';
import { checkProjectTab } from '../../Projects/ProjectsTabs/utils';
import { showFreeAccountLimitDialogAction } from '../../common/FreeAccountLimitationDialog/store/reducer';

function* fetchComplexSetSaga({ data }) {
  const {
    setId,
    projectId,
    discard = true,
    params = {
      size: 20,
      page: 0,
    },
  } = data;
  try {
    // Calling endpoint for reset all the filters for the set in order to show all content of the set
    const isProjectTab = checkProjectTab(projectId);
    if (discard) {
      const apiToCall = isProjectTab ? Api.discardProjectComplexSetFilters : Api.discardComplexSetFilters;
      const requestData = isProjectTab ? { setId, projectId } : setId;
      yield call(apiToCall, requestData);
    }
    const apiToCall = isProjectTab ? Api.getProjectComplexSetContent : Api.getComplexSetContent;
    const apiToCallToGetConfig = isProjectTab ? Api.getProjectComplexSetConfig : Api.getComplexSetConfig;
    const requestData = isProjectTab ? { setId, params, projectId } : { id: setId, params };
    const requestDataForConfig = isProjectTab ? { setId, projectId } : setId;

    const response = yield call(apiToCall, requestData);
    const configResponse = yield call(apiToCallToGetConfig, requestDataForConfig);

    configResponse.data.tags = configResponse.data.tags.map((item, index) => ({
      name: item,
      id: index,
    }));

    const dataForStorage = {
      ...response.data,
      ...configResponse.data,
    };
    yield put({ type: FETCH_COMPLEX_SET_DATA_SUCCESS, data: dataForStorage });
  } catch (e) {
    yield put({ type: FETCH_COMPLEX_SET_DATA_FAILURE });
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
    console.log(e);// eslint-disable-line no-console
  }
}

function* downloadComplexSetSaga(action) {
  try {
    const totalElements = yield select(getTotalElements);
    const columnsConfiguration = yield select(getColumnsConfiguration);
    const columns = columnsConfiguration.map(el => ({
      label: el.name,
      dataKey: el.name,
    }));
    const {
      setId,
      projectId,
      params = {
        size: totalElements,
        page: 0,
      },
      downloadLink,
      setName,
    } = action.data;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectComplexSetContent : Api.getComplexSetContent;
    const requestData = isProjectTab ? { setId, projectId, params } : { id: setId, params };
    const response = yield call(apiToCall, requestData);
    const { content } = response.data;
    const result = content.reduce((acc, item) => {
      const temp = item.reduce((tempAcc, tempItem) => ({
        ...tempAcc,
        [tempItem.columnName]: tempItem.value,
      }),
      {}
      );
      acc.push(temp);
      return acc;
    }, []);
    const csv = convertTableToCSVText(result, columns);
    exportToFile(downloadLink, csv, setName);
  } catch (e) {
    console.log(e);
  }
}

function* analyzeSimpleSetSaga(action) {
  try {
    const { data: { setId, projectId } } = action;
    const simpleSetToComplexSetFormValues = yield select(getComplexSetToSimpleSetFormValues);
    if (simpleSetToComplexSetFormValues.columnNumber) {
      simpleSetToComplexSetFormValues.identifierColumnNumber = Number(simpleSetToComplexSetFormValues.columnNumber);
    }
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.checkSimpleSetDataFromProjectComplexSet : Api.checkSimpleSetDataFromComplexSet;
    const requestData = isProjectTab ? { setId, projectId, requestBody: simpleSetToComplexSetFormValues } : { id: setId, requestBody: simpleSetToComplexSetFormValues };
    const response = yield call(apiToCall, requestData);
    yield put({ type: SIMPLE_SET_ANALYZE_SUCCESS, data: response.data });
    yield put({ type: SET_PARENT_TAGS });
  } catch (e) {
    yield put({ type: SIMPLE_SET_ANALYZE_FAILURE });
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
    console.log(e);// eslint-disable-line no-console
  }
}

function* saveSimpleSetSaga(action) {
  try {
    const { projectId } = action.data;

    yield put({ type: CLEAR_COMPLEX_SET_ERROR });
    yield put(startSubmit(SIMPLE_SET_FROM_COMPLEX_SET_FORM));
    const formValues = yield select(getComplexSetToSimpleSetFormValues);
    if (!formValues.name) {
      yield put(stopSubmit(SIMPLE_SET_FROM_COMPLEX_SET_FORM, { _error: 'Name is required' }));
      return;
    }
    const formattedTags = yield select(tagListFormatted);
    const formattedSetItems = yield select(setItemsFormatted);
    const requestObject = {
      items: formattedSetItems,
      tags: formattedTags,
      taxonomy: formValues.taxonomy,
      name: formValues.name || '',
      description: formValues.description || '',
    };
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.addProjectSet : Api.saveSimpleOrEffectSetFromComplexSet;
    const requestData = isProjectTab ? { ...requestObject, projectId } : requestObject;
    yield call(apiToCall, requestData);

    yield put({ type: SIMPLE_SET_SAVE_SUCCESS });
    history.back();
    yield put(stopSubmit(SIMPLE_SET_FROM_COMPLEX_SET_FORM));
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put({ type: HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE });
      yield put(showFreeAccountLimitDialogAction());
    } else if (e.response) {
      yield put({ type: SIMPLE_SET_SAVE_FAILURE });
      yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
    }
    console.log(e);// eslint-disable-line no-console
  }
}

function* analyzeEffectSetSaga(action) {
  try {
    const { projectId, setId } = action.data;
    const complexSetToEffectSetFormValues = yield select(getComplexSetToEffectSetFormValues);
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.checkEffectSetDataFromProjectComplexSet : Api.checkEffectSetDataFromComplexSet;
    const requestData = isProjectTab ? { setId, projectId, requestBody: complexSetToEffectSetFormValues } : { id: setId, requestBody: complexSetToEffectSetFormValues };
    const response = yield call(apiToCall, requestData);
    yield put({ type: EFFECT_SET_ANALYZE_SUCCESS, data: response.data });
    yield put({ type: SET_PARENT_TAGS });
  } catch (e) {
    yield put({ type: EFFECT_SET_ANALYZE_FAILURE });
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL, data: e.response.data.message });
    console.log(e);// eslint-disable-line no-console
  }
}

function* saveEffectSetSaga(action) {
  try {
    const {
      data: {
        projectId,
      },
    } = action;
    yield put({ type: CLEAR_COMPLEX_SET_ERROR });
    yield put(startSubmit(EFFECT_SET_FROM_COMPLEX_SET_FORM));
    const formValues = yield select(getComplexSetToEffectSetFormValues);
    if (!formValues.name) {
      yield put(stopSubmit(EFFECT_SET_FROM_COMPLEX_SET_FORM, { _error: 'Name is required' }));
      return;
    }
    const formattedTags = yield select(tagListFormatted);
    const formattedSetItems = yield select(effectSetItemsFormatted);
    const requestObject = {
      items: formattedSetItems,
      tags: formattedTags,
      taxonomy: formValues.taxonomy,
      name: formValues.name || '',
      description: formValues.description || '',
    };
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.addProjectSet : Api.saveSimpleOrEffectSetFromComplexSet;
    const requestData = isProjectTab ? { ...requestObject, projectId } : requestObject;
    yield call(apiToCall, requestData);
    yield put({ type: EFFECT_SET_SAVE_SUCCESS });
    history.back();
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put({ type: HIDE_MODAL_FROM_COMPLEX_TO_EFFECT });
      yield put(showFreeAccountLimitDialogAction());
    } else if (e.response) {
      yield put({ type: EFFECT_SET_SAVE_FAILURE });
      yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
    }
    console.log(e);// eslint-disable-line no-console
  }
}

function* complexSetApplyFilterSaga(action) {
  try {
    const {
      data: {
        params,
        setId,
        projectId,
      },
    } = action;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.applyProjectComplexSetFilter : Api.applyComplexSetFilter;
    const apiToCallToGetConfig = isProjectTab ? Api.getProjectComplexSetConfig : Api.getComplexSetConfig;
    const requestData = isProjectTab ? { data: params, setId, projectId } : { data: params, id: setId };
    const requestDataToGetConfig = isProjectTab ? { setId, projectId } : setId;

    const response = yield call(apiToCall, requestData);
    const configResponse = yield call(apiToCallToGetConfig, requestDataToGetConfig);

    const dataForStorage = {
      ...response.data,
      ...configResponse.data,
    };
    yield put({ type: FETCH_COMPLEX_SET_DATA_SUCCESS, data: dataForStorage });
  } catch (e) {
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL, message: e.response.data.message });
    console.log(e);// eslint-disable-line no-console
  }
}

function* saveFilteredSetSaga(action) {
  try {
    const {
      data: {
        setId,
        projectId,
      },
    } = action;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.saveProjectFilteredSet : Api.saveFilteredSet;
    const requestData = isProjectTab ? { setId, projectId } : setId;
    yield call(apiToCall, requestData);
    history.back();
  } catch (e) {
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
    console.log(e);// eslint-disable-line no-console
  }
}

function* complexSetApplyAggregationSaga(action) {
  try {
    const {
      data,
      setId,
      projectId,
    } = action.data;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.applyProjectComplexSetAggregation : Api.applyComplexSetAggregation;
    const apiToCallToGetConfig = isProjectTab ? Api.getProjectComplexSetConfig : Api.getComplexSetConfig;
    const requestData = isProjectTab ? { data, setId, projectId } : { data, id: setId };
    const requestDataForConfig = isProjectTab ? { setId, projectId } : setId;

    const response = yield call(apiToCall, requestData);
    const configResponse = yield call(apiToCallToGetConfig, requestDataForConfig);

    const dataForStorage = {
      ...response.data,
      ...configResponse.data,
    };

    yield put({ type: COMPLEX_SET_ADD_AGGREGATION_RULE, data });
    yield put({ type: FETCH_COMPLEX_SET_DATA_SUCCESS, data: dataForStorage });
  } catch (e) {
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL, data: e.response.data.message });
    console.log(e);
  }
}

function* updateConfigSaga({ data }) {
  try {
    const {
      setId,
      projectId,
      complexSetConfigFormValues,
      config: { columnsConfiguration },
    } = data;

    const name = yield select(getName);
    const description = yield select(getDescription);
    const rawTags = yield select(getComplexSetTags);
    const version = yield select(getVersion);

    const tags = rawTags.map(item => item.name);

    const configuredColumns = columnsConfiguration.map((item) => {
      const { colNumber } = item;
      return {
        ...item,
        content: complexSetConfigFormValues[`${colNumber}_content`],
        type: complexSetConfigFormValues[`${colNumber}_contentType`],
      };
    });

    const valuesToSend = {
      id: setId,
      columnsConfiguration: configuredColumns,
      name,
      description,
      tags,
    };

    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.updateProjectSetConfig : Api.updateSetConfig;
    const requestData = isProjectTab ? { ...valuesToSend, projectId, version } : valuesToSend;
    yield call(apiToCall, requestData);
    yield put({ type: COMPLEX_SET_UPDATE_CONFIG_SUCCESS });
    yield put({ type: FETCH_COMPLEX_SET_DATA_REQUEST, data: { setId, projectId } });
  } catch (e) {
    yield put({ type: COMPLEX_SET_UPDATE_CONFIG_FAILURE });
    yield put({ type: COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL });
  }
}


function* saga() {
  yield takeLatest(COMPLEX_SET_APPLY_AGGREGATION, complexSetApplyAggregationSaga);
  yield takeLatest(FETCH_COMPLEX_SET_DATA_REQUEST, fetchComplexSetSaga);
  yield takeLatest(DOWNLOAD_COMPLEX_SET_DATA_REQUEST, downloadComplexSetSaga);
  yield takeLatest(COMPLEX_SET_RESET_FILTERS, fetchComplexSetSaga);
  yield takeLatest(SIMPLE_SET_ANALYZE_REQUEST, analyzeSimpleSetSaga);
  yield takeLatest(SIMPLE_SET_SAVE_REQUEST, saveSimpleSetSaga);
  yield takeLatest(EFFECT_SET_ANALYZE_REQUEST, analyzeEffectSetSaga);
  yield takeLatest(EFFECT_SET_SAVE_REQUEST, saveEffectSetSaga);
  yield takeLatest(COMPLEX_SET_APPLY_FILTER, complexSetApplyFilterSaga);
  yield takeLatest(COMPLEX_SET_SAVE_FILTERED, saveFilteredSetSaga);
  yield takeLatest(COMPLEX_SET_UPDATE_CONFIG_REQUEST, updateConfigSaga);
}

export default saga;
