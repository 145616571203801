import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';

// Icons
import { MdClear } from 'react-icons/md';
import { RiSearchLine } from 'react-icons/ri';
// Constants
import { CONCEPT_SEARCH_FORM_FIELD } from './constants';
// Components
import FormInput from '../../common/FormElements/FormInput';

const propTypes = {
  inputValue: PropTypes.string,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  onClick: PropTypes.func,
};

const ConceptSearchField = (props) => {
  const {
    inputValue,
    submitting = false,
    reset,
    onClick,
  } = props;

  const submitBtnClassName = classNames({
    'concept-search-form__btn': true,
    'concept-search-form__btn_active': !submitting || !!inputValue,
  });

  return (
    <div className="concept-search-form__field">
      <Field
        type="text"
        name={CONCEPT_SEARCH_FORM_FIELD}
        component={FormInput}
        placeholder="Enter a concept you want to investigate (e.g. BRCA2)"
      />
      {
        !!inputValue &&
        <div
          className="concept-search-form__clear"
          onClick={reset}
        >
          <MdClear size={22} color="#2b5566" />
        </div>
      }
      <button
        type="submit"
        disabled={submitting || !inputValue}
        className={submitBtnClassName}
        {...(onClick && ({ onClick }))}
      >
        <RiSearchLine size={20} color="#2b5566" />
      </button>
    </div>
  );
};

ConceptSearchField.propTypes = propTypes;

export default React.memo(ConceptSearchField);
