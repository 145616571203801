// Core
import { takeLatest } from 'redux-saga/effects';

// Types
import { types } from '../constants';

// Workers
import { getPhenotypesDataWorker } from './workers/getPhenotypesDataWorker';

function* geneDetailPhenotypesSaga() {
  yield takeLatest(types.GET_PHENOTYPES_DATA, getPhenotypesDataWorker);
}

export default geneDetailPhenotypesSaga;
