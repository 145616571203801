import React from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from '../../../common/Loader/Loader';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
// Styles
import './index.scss';

const propTypes = {
  onSubmitCallback: PropTypes.func,
  searchValue: PropTypes.string,
  loading: PropTypes.bool,
  setPortalAction: PropTypes.func,
};

const SearchPageStart = (props) => {
  const { onSubmitCallback, searchValue, loading } = props;

  if (loading) {
    return (
      <Loader
        isLoading={loading}
        withOverlay={true}
      />
    );
  }

  return (
    <div className="search-page-start">
      <div className="search-page-start__logo">
        <div className="euretos-logo" />
      </div>
      <ConceptSearchForm
        initialValues={{ searchValue }}
        onSubmitCallback={onSubmitCallback}
      />
    </div>
  );
};

SearchPageStart.propTypes = propTypes;

export default SearchPageStart;
