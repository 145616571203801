import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../../../common/Buttons/Button/Button';
import ButtonSelect from '../../../common/Buttons/ButtonSelect/ButtonSelect';
// Constants
import { THEME } from '../../../../constantsCommon';
import { analysisMethodEnum } from '../../../Analytics/FindRelated/FindRelatedResult/enums';

const propTypes = {
  method: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  setMethod: PropTypes.func,
  setCategory: PropTypes.func,
  setType: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Array),
  // selectCategoryType: PropTypes.func,
  backgroundSetConfig: PropTypes.instanceOf(Object),
  openSelectBackgroundSetPopup: PropTypes.func,
};

const FindRelatedMethodSelection = (props) => {
  const {
    method,
    category,
    type,
    setMethod,
    setCategory,
    semanticCategories,
    setType,
    // selectCategoryType,
    openSelectBackgroundSetPopup,
    backgroundSetConfig,
  } = props;

  const options = semanticCategories.map(c => c.name);

  const semanticTypes = semanticCategories.find(d => d.name === category)?.st;

  const handleSelectCategory = (d) => {
    setCategory(d);
    setMethod(analysisMethodEnum.CATEGORY);
    // selectCategoryType(d);
  };

  let backgroundSet;
  if (backgroundSetConfig?.backgroundSetType === 'CELL') {
    backgroundSet = backgroundSetConfig?.cell ? backgroundSetConfig?.cell.name : '';
  } else if (backgroundSetConfig?.backgroundSetType === 'SET') {
    backgroundSet = backgroundSetConfig?.setName || '';
  } else {
    backgroundSet = backgroundSetConfig?.backgroundSetType || '';
  }

  return (
    <div className="find-related__method">
      <div className="container">
        <div className="find-related__method-selection">
          <Button
            text="Pathway analysis"
            onClick={() => setMethod(analysisMethodEnum.PATHWAY)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.PATHWAY}
          />
          <Button
            text="Regulation analysis"
            onClick={() => setMethod(analysisMethodEnum.REGULATION)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.REGULATION}
          />
          <Button
            text="Protein complex analysis"
            onClick={() => setMethod(analysisMethodEnum.PROTEIN_COMPLEX)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.PROTEIN_COMPLEX}
          />
          <Button
            text="Compound analysis"
            onClick={() => setMethod(analysisMethodEnum.COMPOUND)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.COMPOUND}
          />
          <Button
            text="Phenotype analysis"
            onClick={() => setMethod(analysisMethodEnum.PHENOTYPE)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.PHENOTYPE}
          />
          <Button
            text="Disorder analysis"
            onClick={() => setMethod(analysisMethodEnum.DISORDER)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.DISORDER}
          />
          <Button
            text="Metabolite analysis"
            onClick={() => setMethod(analysisMethodEnum.METABOLITE)}
            theme={THEME.SEMI}
            active={method === analysisMethodEnum.METABOLITE}
          />
          <Button
            theme={THEME.SEMI}
            onClick={openSelectBackgroundSetPopup}>
            <span className="find-related__trim-text" title={`Background set: ${backgroundSet}`}>Background set: {backgroundSet}</span>
          </Button>
          <ButtonSelect
            text="Select a Category"
            onSelect={handleSelectCategory}
            options={options}
          />
        </div>
        {
          method === analysisMethodEnum.CATEGORY &&
          <div className="find-related__category-selection">
            {semanticTypes.map(c => (
              <Button
                key={c.id}
                text={c.name}
                onClick={() => setType(c)}
                active={c.id === type}
              />
            ))}
          </div>
        }
      </div>
    </div>
  );
};

FindRelatedMethodSelection.propTypes = propTypes;

export default React.memo(FindRelatedMethodSelection);
