import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import CreateSet from '../CreateSet/CreateSet';
import {useLocation} from 'react-router-dom';
import {apiTypes} from '../CreateSet/enums';
import {getCreateSetCategory, getCreateSetParams} from './Utils';
import './styles.scss';
import {connect} from 'react-redux';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import {getSetSavedSuccessfullySelector} from '../CreateSet/store/selectors';
import {clearSavedSuccessfullyAction} from '../CreateSet/store/actions';
import {PortalActions} from '../../Search/enums';
import {RELATION_MAP_CONCEPTS_LIMIT} from '../../RelationMap/RelationMapPage/constants';

const propTypes = {
  config: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  clearSavedSuccessfullyFlag: PropTypes.func,
  savedSuccessfully: PropTypes.bool,
};

const CreateSetPage = (props) => {
  const {
    config,
    clearSavedSuccessfullyFlag,
    savedSuccessfully,
  } = props;

  const [showSaveSuccessDialog, setShowSaveSuccessDialog] = useState(false);
  const [saveDialogText, setSaveDialogText] = useState(null);

  useEffect(() => {
    if (savedSuccessfully && !showSaveSuccessDialog) {
      clearSavedSuccessfullyFlag();
      setShowSaveSuccessDialog(true);
      setSaveDialogText('Your set was successfully saved');
    } else if (savedSuccessfully === false && !showSaveSuccessDialog) {
      clearSavedSuccessfullyFlag();
      setShowSaveSuccessDialog(true);
      setSaveDialogText('Error occurred while saving the set');
    }
  }, [savedSuccessfully]);

  useEffect(() => {
    clearSavedSuccessfullyFlag();//clear flag on reloas
  }, []);

  const location = useLocation();
  const urlParamsObject = getCreateSetParams(location.search);
  const setConfig = {
    ...config,
    apiType: urlParamsObject.savedSetId ? apiTypes.filterWithConcepts : apiTypes.targetsForCancer,
    categoryName: getCreateSetCategory(apiTypes.targetsForCancer),
  };
  if (urlParamsObject.startConceptId) {
    setConfig.conceptIds = [+urlParamsObject.startConceptId];
    setConfig.startConcepts = [{ id: urlParamsObject.startConceptId, name: urlParamsObject.startConceptName}];
  }

  return (
    <div className="create-set-page">
      <CreateSet
        config={setConfig}
        pageStyles={true}
        conceptsLimitMessage={`You can export to relation map no more than ${RELATION_MAP_CONCEPTS_LIMIT} concepts. Maximum amount of concepts on map is ${RELATION_MAP_CONCEPTS_LIMIT}.`}
        genesOnly={urlParamsObject.action === PortalActions.MARKERS_IDENTIFY_DISEASE}
        loadSet={{id: urlParamsObject.savedSetId, projectId: urlParamsObject.projectId}}
      />
      {
        showSaveSuccessDialog &&
        <InfoDialog
          isOpen={showSaveSuccessDialog}
          closeCb={() => setShowSaveSuccessDialog(false)}
          text={saveDialogText}
        />
      }
    </div>
  );
};

CreateSetPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    savedSuccessfully: getSetSavedSuccessfullySelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSavedSuccessfullyFlag(data) {
      dispatch(clearSavedSuccessfullyAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSetPage);
