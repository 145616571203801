import React from 'react';
import PropTypes from 'prop-types';
// Components
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
// Styles
import './styles.scss';

const propTypes = {
  options: PropTypes.instanceOf(Array),
  hide: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  fetchOptions: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Object),
};

const RelatedConceptsSelect = (props) => {
  const {
    value,
    hide,
    options,
    disabled,
    onSelect,
    isLoading,
    placeholder,
    fetchOptions,
  } = props;

  if (hide) {
    return null;
  }

  const onChange = (option) => {
    onSelect(option);
  };

  return (
    <SelectInput
      hide={hide}
      placeholder={placeholder}
      defaultValue={value}
      options={options}
      isLoading={isLoading}
      isDisabled={disabled}
      onSelect={onChange}
      grayMode={true}
      onFocus={fetchOptions}
      customClassName="heatmap-select"
      noOptionsMessage={() => 'There is no data to display'}
    />
  );
};

RelatedConceptsSelect.propTypes = propTypes;

export default RelatedConceptsSelect;
