import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

export const getPublicationsListDataAction = createAction('@@publicationsList/GET_DATA');
export const setPublicationsListDataAction = createAction('@@publicationsList/SET_DATA');
export const getPublicationsListIdsction = createAction('@@publicationsList/GET_IDS');
export const setPublicationsListIdsAction = createAction('@@publicationsList/SET_IDS');
export const updateSelectedPublicationsIdsAction = createAction('@@publicationsList/UPDATE_SELECTED_IDS');
export const updateSelectedPubmedIdsAction = createAction('@@publicationsList/UPDATE_SELECTED_PUBMED_IDS');
export const throwPublicationsListError = createAction('@@publicationsList/THROW_ERROR');
export const resetPublicationsStateAction = createAction('@@publicationsList/RESET_STATE');

const initialState = fromJS({
  publications: {
    content: [],
  },
  recommendations: {},
  selectedPublicationsIds: [],
  selectedPubmedIds: [],
  loading: false,
  error: null,
  selectAll: false,
});

const publicationsListReducer = handleActions(
  {
    [getPublicationsListDataAction]: state =>
      state
        .update('loading', () => true),
    [setPublicationsListDataAction]: (state, { payload }) => (
      state
        .update('publications', () => fromJS(payload.publications))
        .update('recommendations', () => fromJS(payload.recommendations))
        .update('loading', () => false)
        .update('error', () => null)),
    [updateSelectedPublicationsIdsAction]: (state, { payload }) =>
      state
        .update('selectedPublicationsIds', () => fromJS(payload)),
    [updateSelectedPubmedIdsAction]: (state, { payload }) =>
      state
        .update('selectedPubmedIds', () => fromJS(payload)),
    [setPublicationsListIdsAction]: (state, { payload }) =>
      state
        .update('selectAll', () => payload.selectAll)
        .update('selectedPublicationsIds', () => fromJS(payload.selectedPublicationsIds))
        .update('selectedPubmedIds', () => fromJS(payload.selectedPubmedIds)),
    [throwPublicationsListError]: (state, { payload }) =>
      state
        .update('publications', () => initialState.get('publications'))
        .update('recommendations', () => initialState.get('recommendations'))
        .update('loading', () => false)
        .update('error', () => payload),
    [resetPublicationsStateAction]: state => state.merge(initialState),
  },
  initialState
);

export default publicationsListReducer;
