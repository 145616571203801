import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Store
import * as s from '../../store/selectors';
import * as a from '../../store/actions';
// Components
import SingleCellFilters from './Components/SingleCellFilters/SingleCellFilters';
import SingleCellChartByMetaData from './Components/SingleCellChartByMetaData/SingleCellChartByMetaData';

const propTypes = {
  filtersAreEmpty: PropTypes.bool,
  selectedFilterValue: PropTypes.instanceOf(Object),
  metaData: PropTypes.instanceOf(Array),
  filteredMetaData: PropTypes.instanceOf(Array),
  datasets: PropTypes.instanceOf(Object),
  measures: PropTypes.instanceOf(Object),
  getMetaData: PropTypes.func,
  metaDataError: PropTypes.string,
  metaDataLoading: PropTypes.bool,
  metaLegendData: PropTypes.instanceOf(Object),
  metaDataBackgroundGraphs: PropTypes.instanceOf(Array),
  clearMetaData: PropTypes.func,
};

const SingleCellChartsByMetaData = (props) => {
  const {
    datasets,
    measures,
    getMetaData,
    filtersAreEmpty,
    selectedFilterValue,
    metaData,
    filteredMetaData,
    metaDataError,
    metaDataLoading,
    metaLegendData,
    metaDataBackgroundGraphs,
    clearMetaData,
  } = props;

  useEffect(() => {
    if (metaData.length) {
      clearMetaData();
    }
  }, [datasets]);

  useEffect(() => {
    getMetaData({
      datasets: datasets.ids,
      tumorType: datasets.tumorType,
    });
  }, [selectedFilterValue, datasets]);

  return (
    <div className="single-cell-study-dataset">
      <SingleCellFilters />
      <div className="single-cell-study__titles-block">
        {
          datasets.titles.map((title, idx) => (
            <h3 key={idx} className="single-cell-study__title title3">{title}</h3>
          ))
        }
      </div>
      <div className="single-cell-study__charts">
        {
          datasets.ids.map((id, idx) => (
            <SingleCellChartByMetaData
              key={id}
              data={metaData[idx]}
              chartData={filteredMetaData[idx]}
              backgroundGraph={metaDataBackgroundGraphs[idx]}
              legendData={metaLegendData[idx]}
              measure={measures[idx]}
              error={metaDataError}
              loading={metaDataLoading}
              filterValue={selectedFilterValue}
              filtersAreEmpty={filtersAreEmpty}
              noData={!!metaData[idx] && !metaData[idx].length}
              chartTitle={`Single cell, ${datasets.titles[idx]}`}
              chartName="Single_cell"
              chartId={`single-cell-study-chart-by-dataset-${idx}`}
            />
          ))
        }
      </div>
    </div>
  );
};

SingleCellChartsByMetaData.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    metaData: s.getMetaDataSelector(state),
    filteredMetaData: s.getFilteredMetaDataSelector(state),
    metaDataError: s.getMetaDataErrorSelector(state),
    metaDataLoading: s.getMetaDataLoadingSelector(state),
    metaLegendData: s.getMetaLegendDataSelector(state),
    metaDataBackgroundGraphs: s.getMetaDataBackgroundGraphSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMetaData(data) {
      dispatch(a.getMetaDataAction(data));
    },
    clearMetaData() {
      dispatch(a.clearMetaDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCellChartsByMetaData);
