import { categories } from './constants';

export function prepareSemanticCategoriesData(data) {
  const { highlights } = data;
  if (!highlights) return {};
  const highlightsData = Object.values(highlights);
  const semanticCategories = {};

  highlightsData.forEach((highlight) => {
    if (highlight) {
      highlight.forEach((item) => {
        const { semanticCategory: category } = item;
        const semanticCategoriesArray = Object.keys(semanticCategories);
        if (!semanticCategoriesArray.includes(category)) {
          semanticCategories[category] = {
            category,
            color: categories[category].color,
            class: categories[category].class,
            checked: false,
          };
        }
      });
    }
  });

  return semanticCategories;
}

export function prepareHighlightsData(data, semanticData) {
  const { highlights } = data;
  if (!highlights) return {};
  const semanticCategories = Object.keys(semanticData);
  let highlightsData = {};
  const highlightsValues = Object.values(highlights);
  const highlightsKeys = Object.keys(highlights);

  highlightsValues.forEach((highlight, idx) => {
    let highlightsWithSemanticData = {};
    if (highlight) {
      highlight.forEach((item) => {
        semanticCategories.forEach((el) => {
          if (item.semanticCategory === el) {
            // eslint-disable-next-line no-param-reassign
            item = { ...item, length: item.end - item.start };
            highlightsWithSemanticData = {
              ...highlightsWithSemanticData,
              [el]: highlightsWithSemanticData[el] ?
                [...highlightsWithSemanticData[el], item] :
                [item],
            };
          }
        });
        highlightsData = { ...highlightsData, [highlightsKeys[idx]]: highlightsWithSemanticData };
      });
    }
  });
  return highlightsData;
}

export function calcSliderValue(concepts) {
  if (!concepts) return 0;
  let sliderValue = 0.1;
  const filteredData = concepts.filter(concept => concept.scoreCFIDF >= sliderValue);
  if (filteredData.length > 20) {
    const limitedData = concepts.sort((a, b) => b.scoreCFIDF - a.scoreCFIDF).slice(0, 20);
    const minScore = Math.min(...limitedData.map(concept => concept.scoreCFIDF));
    sliderValue = minScore;
  }

  return sliderValue;
}
