import React from 'react';
import PropTypes from 'prop-types';

import './Concept.css';

const propTypes = {
  concept: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  deleteCb: PropTypes.func,
  infoCb: PropTypes.func,
  noInfoButton: PropTypes.bool,
  noDeleteButton: PropTypes.bool,
  mindMapSelection: PropTypes.bool,
};

class Concept extends React.Component {
  render() {
    const {
      concept: {
        id,
        name,
        conceptId,
        color,
        semanticCategory,
      },
      deleteCb,
      infoCb,
      noInfoButton,
      noDeleteButton,
      mindMapSelection,
    } = this.props;

    const style = {
      backgroundColor: `#${color}`,
    };

    const shortName = name && name.length > 30 ? `${name.slice(0, 30)}...` : name;

    return (
      <div className="button-group">
        { mindMapSelection &&
          <button style={style} className="btn btn-color" />
        }
        <button
          title={name}
          className="btn btn-default"
        >
          { shortName }
        </button>
        {!noInfoButton &&
          <button
            type="button"
            onClick={() => {
              if (infoCb) {
                infoCb(id || conceptId, semanticCategory);
              }
            }}
            className="btn-icon btn-info"
          >
            <i className="fa fa-info" />
          </button>
        }
        {!noDeleteButton &&
          <button
            type="button"
            className="btn-icon btn-danger"
            onClick={() => { deleteCb(id); }}
          >
            <i className="fa fa-trash" />
          </button>
        }
      </div>
    );
  }
}

Concept.propTypes = propTypes;

export default Concept;
