import { createSelector } from 'reselect';

const methodTab = state => state.getIn(['setAnalysisMethodSelection', 'methodTab']);

export const getMethodTab = createSelector(
  methodTab,
  data => data
);

const factorLogicValue = state => state.getIn(['setAnalysisMethodSelection', 'factorLogicValue']);
export const getFactorLogicValue = createSelector(
  factorLogicValue,
  value => value
);

const menuKey = state => state.getIn(['setAnalysisMethodSelection', 'openMenu']);

export const getMenuKey = createSelector(
  menuKey,
  data => data
);

const menuTissueKey = state => state.getIn(['setAnalysisMethodSelection', 'openMenuTissues']);

export const getMenuTissuesKey = createSelector(
  menuTissueKey,
  data => data
);

const menuTissuePenaltyKey = state => state.getIn(['setAnalysisMethodSelection', 'openMenuTissuesPenalty']);

export const getMenuTissuesPenaltyKey = createSelector(
  menuTissuePenaltyKey,
  data => data
);

const categories = state => state.getIn(['setAnalysisMethodSelection', 'categories', 'data']);

export const getCategoriesForMenu = createSelector(
  categories,
  (_categories) => {
    const items = [];
    _categories.toJS().forEach((i) => {
      items.push({
        name: i.category,
      });
    });
    return items;
  }
);

const selectedType = state => state.getIn(['setAnalysisMethodSelection', 'categories', 'selectedType']);

export const getSelectedType = createSelector(
  selectedType,
  data => data && data.toJS()
);

const tissues = state => state.getIn(['setAnalysisMethodSelection', 'tissues', 'data']);

export const getTissuesForMenu = createSelector(
  tissues,
  (_tissues) => {
    const items = [];
    _tissues.toJS().forEach((i) => {
      items.push({
        name: i.category,
        value: `${i.id}:${i.type}`,
        label: `${i.name} (${i.typeName})`,
      });
    });
    return items;
  }
);

export const getAllTissues = createSelector(
  tissues,
  data => (data ? data.toJS() : []),
);

const networkAnalysisTissues = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisTissues', 'data']);

export const getNetworkAnalysisTissuesForMenu = createSelector(
  networkAnalysisTissues,
  _networkAnalysisTissues => (
    _networkAnalysisTissues.toJS().map(d => ({
      value: `${d.id}:${d.type}`,
      label: `${d.name} (${d.typeName})`,
      id: d.id,
    }))
  )
);

const selectedNetworkAnalysisTissue = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisTissues', 'selectedName']);

export const getNetworkAnalysisTissuesTypes = createSelector(
  networkAnalysisTissues,
  selectedNetworkAnalysisTissue,
  (_tissues, selectedTissue) => (
    selectedTissue ? _tissues.find(tissue => (
      tissue.get('category') === selectedTissue
    )).get('tissues')
      .toJS() : []
  )
);

const selectedNetworkAnalysisTissueOption = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisTissues', 'selectedTissue']);

export const getSelectedNetworkAnalysisTissueOption = createSelector(
  selectedNetworkAnalysisTissueOption,
  data => data && data.toJS()
);

const selectedNetworkAnalysisTissueType = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisTissues', 'selectedType']);

export const getSelectedNetworkAnalysisTissueType = createSelector(
  selectedNetworkAnalysisTissueType,
  data => data
);

const selectedNetworkAnalysisTissueSource = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisTissues', 'selectedSource']);

export const getSelectedNetworkAnalysisTissueSource = createSelector(
  selectedNetworkAnalysisTissueSource,
  data => data
);

export const getSelectedNetworkAnalysisTissue = createSelector(
  selectedNetworkAnalysisTissueType,
  selectedNetworkAnalysisTissueSource,
  (_type, _source) => ({ gi: _type, source: _source })
);

const selectedCategory = state => state.getIn(['setAnalysisMethodSelection', 'categories', 'selectedName']);

export const getSelectedCategory = createSelector(
  selectedCategory,
  data => data
);

export const getCategoriesTypes = createSelector(
  categories,
  selectedCategory,
  (_categories, _selectedCategory) => (
    _selectedCategory && _categories && _categories.size > 0 ? _categories.find(category => (
      category.get('category') === _selectedCategory
    )).get('types')
      .toJS() : []
  )
);

const selectedTissue = state => state.getIn(['setAnalysisMethodSelection', 'tissues', 'selectedType']);

export const getTissuesTypes = createSelector(
  tissues,
  selectedTissue,
  (_tissues, _selectedTissue) => {
    if (!_selectedTissue || !_tissues || _tissues.size === 0) {
      return [];
    }
    return _tissues.toJS().filter(t => t.id === Number(_selectedTissue));
  }
);

const selectedSource = state => state.getIn(['setAnalysisMethodSelection', 'selectedSource']);

export const getSelectedSource = createSelector(
  selectedSource,
  data => data
);

const selectedTissueType = state => state.getIn(['setAnalysisMethodSelection', 'tissues', 'selectedType']);

export const getSelectedTissueType = createSelector(
  selectedTissueType,
  data => data
);

const tissuesPenalty = state => state.getIn(['setAnalysisMethodSelection', 'penaltyTissues', 'data']);

const rankingSettings = state => state.getIn(['setAnalysisMethodSelection', 'rankingSettings']);

export const getRankingTypes = createSelector(
  rankingSettings,
  data => data.toJS()
);

const rankingType = state => state.getIn(['setAnalysisMethodSelection', 'rankingType']);

export const getRanking = createSelector(
  rankingType,
  data => data
);

const rankingModals = state => state.getIn(['setAnalysisMethodSelection', 'rankingModalOpen']);

export const getRankingModals = createSelector(
  rankingModals,
  data => data.toJS()
);

const savedAssociationScoreTerms = state => state.getIn(['setAnalysisMethodSelection', 'associationScoreTermsSaved']);

export const getAssociationTermsSaved = createSelector(
  savedAssociationScoreTerms,
  data => data.toJS()
);

const savedLiteratureSearchTerms = state => state.getIn(['setAnalysisMethodSelection', 'literatureSearchTermsSaved']);

export const getLiteratureSearchTermsSaved = createSelector(
  savedLiteratureSearchTerms,
  data => data.toJS()
);

export const getTissuesForPenaltyMenu = createSelector(
  tissuesPenalty,
  _tissues => (_tissues ? _tissues.toJS() : [])
);

const rankingConcept = state => state.getIn(['setAnalysisMethodSelection', 'rankingConcept']);

export const getSelectedRankingConcept = createSelector(
  rankingConcept,
  (_concept) => {
    if (!_concept) {
      return _concept;
    }
    return _concept.toJS();
  }
);

const differentialExpressionConceptGi = state => state.getIn(['setAnalysisMethodSelection', 'differentialExpressionConceptSaved', 'id']);

export const getDifferentialExpressionConceptGi = createSelector(
  differentialExpressionConceptGi,
  data => data
);

const geneVariationConceptGi = state => state.getIn(['setAnalysisMethodSelection', 'geneVariationDiseaseSaved', 'id']);

export const getGeneVariationConceptGi = createSelector(
  geneVariationConceptGi,
  data => data
);

const differentialExpressionConceptSaved = state => state.getIn(['setAnalysisMethodSelection', 'differentialExpressionConceptSaved']);

export const getDifferentialExpressionTermSaved = createSelector(
  differentialExpressionConceptSaved,
  data => (
    data ? data.toJS() : data
  )
);


const geneVariationTermSaved = state => state.getIn(['setAnalysisMethodSelection', 'geneVariationDiseaseSaved']);

export const getGeneVariationTermSaved = createSelector(
  geneVariationTermSaved,
  data => (
    data ? data.toJS() : data
  )
);

const shouldReset = state => state.getIn(['setAnalysisMethodSelection', 'shouldReset']);

export const getShouldResetKey = createSelector(
  shouldReset,
  data => data
);

const networkAnalysisSettings = state => state.getIn(['setAnalysisMethodSelection', 'networkAnalysisSettings']);

export const getNetworkAnalysisSettings = createSelector(
  networkAnalysisSettings,
  data => data.toJS()
);

const backgroundCells = state => state.getIn(['setAnalysisMethodSelection', 'backgroundCells']);

export const getBackgroundCellsSelector = createSelector(
  backgroundCells,
  data => data && data.toJS()
);

const backgroundCellsLoader = state => state.getIn(['setAnalysisMethodSelection', 'backgroundCellsLoader']);

export const getBackgroundCellsLoaderSelector = createSelector(
  backgroundCellsLoader,
  data => data
);

const backgroundCellsFilterOption = state => state.getIn(['setAnalysisMethodSelection', 'backgroundCellsFilterOption']);

export const getBackgroundCellsFilterOptionSelector = createSelector(
  backgroundCellsFilterOption,
  data => data
);

const backgroundSetConfig = state => state.getIn(['setAnalysisMethodSelection', 'backgroundSetConfiguration']);

export const getBackgroundSetConfigSelector = createSelector(
  backgroundSetConfig,
  data => data.toJS()
);

const backgroundSetOptions = state => state.getIn(['setAnalysisMethodSelection', 'backgroundSetOptions']);

export const getBackgroundSetOptionsSelector = createSelector(
  backgroundSetOptions,
  data => data.toJS()
);

const backgroundSetFilterOption = state => state.getIn(['setAnalysisMethodSelection', 'backgroundSetFilterOption']);

export const getBackgroundSetFilterOptionSelector = createSelector(
  backgroundSetFilterOption,
  data => data
);

const shouldUpdateDataFlag = state => state.getIn(['setAnalysisMethodSelection', 'shouldUpdateDataFlag']);

export const getShouldUpdateDataFlag = createSelector(
  shouldUpdateDataFlag,
  data => data
);

const activeProjectId = state => state.getIn(['setAnalysisMethodSelection', 'backgroundSetActiveProjectId']);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);

const selectedTissueOption = state => state.getIn(['setAnalysisMethodSelection', 'tissues', 'selectedTissue']);

export const getSelectedTissueOptionSelector = createSelector(
  selectedTissueOption,
  data => data && data.toJS()
);

const selectedPenaltyTissues = state => state.getIn(['setAnalysisMethodSelection', 'selectedPenaltyTissues']);

export const getSelectedPenaltyTissuesSelector = createSelector(
  selectedPenaltyTissues,
  data => data && data.toJS()
);
