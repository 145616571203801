import {
  CHECK_ALL,
  CHECK_ITEM,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../common/SimpleTable/constants';

import {
  SOLVE_AMBIGUITY,
  REMOVE_CONCEPT,
  CLEAR_ITEMS,
  AMBIGUITY_TABLE,
  FETCH_COMPLEX_SET_DATA_REQUEST,
  DOWNLOAD_COMPLEX_SET_DATA_REQUEST,
  SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE,
  HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE,
  SIMPLE_SET_ANALYZE_REQUEST,
  SIMPLE_SET_SAVE_REQUEST,
  SHOW_MODAL_FROM_COMPLEX_TO_EFFECT,
  EFFECT_SET_ANALYZE_REQUEST,
  EFFECT_SET_SAVE_REQUEST,
  COMPLEX_SET_VIEW_RESET_STATE,
  COMPLEX_SET_APPLY_FILTER,
  COMPLEX_SET_RESET_FILTERS,
  COMPLEX_SET_SAVE_FILTERED,
  COMPLEX_SET_APPLY_AGGREGATION,
  COMPLEX_SET_UPDATE_CONFIG_REQUEST,
  HIDE_MODAL_FROM_COMPLEX_TO_EFFECT,
  COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL,
  COMPLEX_SET_CHANGE_NAME,
  COMPLEX_SET_CHANGE_DESCRIPTION,
  RESOLVE_CONCEPT_COMPLEX,
  COMPLEX_SET_UPDATE_TAGS,
  SIMPLE_SET_UPDATE_TAGS,
  CLEAR_COMPLEX_SET_FREE_USER_ERROR,
} from './constants';

export const resetState = () => (
  {
    type: COMPLEX_SET_VIEW_RESET_STATE,
  }
);

export const fetchComplexSetDataRequestAction = data => (
  {
    type: FETCH_COMPLEX_SET_DATA_REQUEST,
    data,
  }
);
export const downloadComplexSetDataRequestAction = data => (
  {
    type: DOWNLOAD_COMPLEX_SET_DATA_REQUEST,
    data,
  }
);

export const showComplexToSimpleDialogAction = () => (
  {
    type: SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE,
  }
);

export const showComplexToEffectDialogAction = () => (
  {
    type: SHOW_MODAL_FROM_COMPLEX_TO_EFFECT,
  }
);

export const hideComplexToSimpleDialogAction = () => (
  {
    type: HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE,
  }
);

export const hideComplexToEffectDialogAction = () => (
  {
    type: HIDE_MODAL_FROM_COMPLEX_TO_EFFECT,
  }
);

export const simpleSetAnalyzeRequestAction = data => (
  {
    type: SIMPLE_SET_ANALYZE_REQUEST,
    data,
  }
);

export const simpleSetSaveRequestAction = data => (
  {
    type: SIMPLE_SET_SAVE_REQUEST,
    data,
  }
);

export const effectSetAnalyzeRequestAction = data => (
  {
    type: EFFECT_SET_ANALYZE_REQUEST,
    data,
  }
);

export const effectSetSaveRequestAction = data => (
  {
    type: EFFECT_SET_SAVE_REQUEST,
    data,
  }
);


// Actions that are used in simple and effect set creation

export const solveAmbiguityAction = data => (
  {
    type: SOLVE_AMBIGUITY,
    data,
  }
);

export const resolveConceptAction = data => (
  {
    type: RESOLVE_CONCEPT_COMPLEX,
    data,
  }
);

export const removeConceptAction = data => (
  {
    type: REMOVE_CONCEPT,
    data,
  }
);

export const clearItemsAction = () => (
  {
    type: CLEAR_ITEMS,
  }
);

export const checkAllAction = data => (
  {
    type: AMBIGUITY_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItemAction = data => (
  {
    type: AMBIGUITY_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelectionAction = () => (
  {
    type: AMBIGUITY_TABLE + INVERT_SELECTION,
  }
);

export const removeSelectedAction = () => (
  {
    type: AMBIGUITY_TABLE + REMOVE_SELECTED,
  }
);

export const applyFilterAction = data => (
  {
    type: COMPLEX_SET_APPLY_FILTER,
    data,
  }
);

export const applyAggregationAction = data => (
  {
    type: COMPLEX_SET_APPLY_AGGREGATION,
    data,
  }
);

export const resetFiltersAction = data => (
  {
    type: COMPLEX_SET_RESET_FILTERS,
    data,
  }
);

export const saveFilteredSetAction = data => (
  {
    type: COMPLEX_SET_SAVE_FILTERED,
    data,
  }
);

export const updateComplexSetConfigAction = data => (
  {
    type: COMPLEX_SET_UPDATE_CONFIG_REQUEST,
    data,
  }
);

export const closeErrorModalAction = () => (
  {
    type: COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL,
  }
);

export const changeSetNameAction = data => (
  {
    type: COMPLEX_SET_CHANGE_NAME,
    data,
  }
);

export const changeSetDescriptionAction = data => (
  {
    type: COMPLEX_SET_CHANGE_DESCRIPTION,
    data,
  }
);

export const complexSetUpdateTagsAction = data => (
  {
    type: COMPLEX_SET_UPDATE_TAGS,
    data,
  }
);

export const simpleSetUpdateTagsAction = data => (
  {
    type: SIMPLE_SET_UPDATE_TAGS,
    data,
  }
);

export const clearSaveSetFreeUserErrorAction = data => (
  {
    type: CLEAR_COMPLEX_SET_FREE_USER_ERROR,
    data,
  }
);
