import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams , useNavigate } from 'react-router-dom';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import EditSetForm from './Components/EditSetForm/EditSetForm';
import EditSetContent from './Components/EditSetContent/EditSetContent';
import VersionDialog from '../../Modals/VersionModal/VersionModal';
// Store
import * as a from './store/actions';
import * as s from './store/selectors';
import { setSearchConceptInputValueAction } from '../../Concept/ConceptSearchModal/store/actions';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  set: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loading: PropTypes.bool,
  isSaving: PropTypes.bool,
  resetEditSet: PropTypes.func,
  saveEditedSet: PropTypes.func,
  getEditSetData: PropTypes.func,
  setSearchConceptInputValue: PropTypes.func,
};

const EditSet = (props) => {
  const {
    set,
    error,
    loading,
    isSaving,
    resetEditSet,
    saveEditedSet,
    getEditSetData,
    setSearchConceptInputValue,
  } = props;
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);
  const { setId, projectId } = useParams();
  const navigate = useNavigate();

  const submitForm = useCallback((overwrite) => {
    saveEditedSet({
      projectId,
      overwrite,
      navigate,
    });
  }, [navigate, projectId, saveEditedSet]);

  useEffect(() => {
    getEditSetData({ setId, projectId });
    return resetEditSet;
  }, []);

  return (
    <div className="edit-set-page">
      <div className="edit-set-page__title title2">
        Edit set
      </div>
      {
        !!set && !loading &&
        <>
          <EditSetForm
            initialValues={set}
            onSubmit={() => submitForm()}
            goBack={() => navigate(`${RELATIVE_PATH}/sets`, { state: { redirectedProjectId: projectId }})}
            addConcept={() => setShowConceptSearchModal(true)}
            setSearchConceptInputValue={setSearchConceptInputValue}
          />
          <EditSetContent
            showConceptSearchModal={showConceptSearchModal}
            setShowConceptSearchModal={setShowConceptSearchModal}
            setSearchConceptInputValue={setSearchConceptInputValue}
          />
        </>
      }
      <Loader
        isLoading={loading || isSaving}
        withOverlay={isSaving}
      />
      <Error
        show={!!error && !loading}
        error={error}
      />
      <VersionDialog
        closeCb={() => navigate(`${RELATIVE_PATH}/sets`, { state: { redirectedProjectId: projectId }})}
        onConfirm={() => submitForm(true)}
        text="Set was changed by other user. Do you want to rewrite changes?"
      />
    </div>
  );
};

EditSet.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    set: s.getEditSetDataSelector(state),
    loading: s.getEditSetLoadingSelector(state),
    error: s.getEditSetErrorSelector(state),
    isSaving: s.getEditSetIsSavingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEditSetData(data) {
      dispatch(a.getEditSetDataAction(data));
    },
    saveEditedSet(data) {
      dispatch(a.saveEditSetRequestedAction(data));
    },
    resetEditSet() {
      dispatch(a.resetEditSetAction());
    },
    setSearchConceptInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSet);
