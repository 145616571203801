import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import {
  updateBackgroundSetConfigAction,
} from '../../../../Analytics/common/SetAnalysisMethodSelection/actions';
import {
  setAvailableSetsAction,
  toggleAvailableSetsLoaderAction,
  throwAvailableSetsErrorAction,
} from '../../reducer';

import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';

export function* getAvailableSetsWorker(action) {
  try {
    yield put(toggleAvailableSetsLoaderAction(true));
    const projectId = action.payload && action.payload.projectId;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSets : Api.getSetsList;
    let requestData = {
      params: {
        type: 'SIMPLE, EFFECT',
      },
    };
    requestData = isProjectTab ? { ...requestData, projectId } : requestData;
    const { data } = yield call(apiToCall, requestData);
    if (action.payload && action.payload.noGenes) {
      yield put(updateBackgroundSetConfigAction({
        backgroundSetType: 'SET',
        setId: data[0].id,
      }));
    }
    yield put(setAvailableSetsAction(data));
  } catch (e) {
    yield put(throwAvailableSetsErrorAction(e.message));
    yield put(toggleAvailableSetsLoaderAction(false));
  }
}
