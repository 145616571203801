import { Map, fromJS } from 'immutable';

import {
  CHANGE_PASSWORD_MODAL_OPEN,
  CHANGE_PASSWORD_MODAL_CLOSE,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
} from './constants';

const changePassword = (state = new Map({ showModal: false }), action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_SUCCEEDED:
      return state.merge(fromJS(action.data));
    case CHANGE_PASSWORD_FAILED:
      return state.merge(fromJS(action.data));
    case CHANGE_PASSWORD_MODAL_OPEN:
      return state.merge(fromJS({ showModal: action.data }));
    case CHANGE_PASSWORD_MODAL_CLOSE:
      return state.merge(fromJS({ showModal: action.data }));
    default:
      return state;
  }
};

export default changePassword;
