/* eslint-disable no-plusplus */
import * as d3js from 'd3';

// Utils
import { drawLegend } from '../LegendSvgComponent/drawLegend';
// Constants
import { graphTypes } from '../../GeneDetails/GeneExpressionChapter/constants';

export function drawViolinChart(violinChartData) {
  const {
    data,
    type,
    settings,
    htmlRootId,
    tooltipMode = 'mode',
    showTitle,
    showLegend,
    legendsCustoms,
    chartTitle,
    violinScale,
    showTooltipHandler,
    hideTooltip,
    yAxisText,
    selectedCells,
    handleCellClick,
  } = violinChartData;

  const config = {
    fixedScale: null,
    width: 1800,
    height: 1020,
    padding: {
      right: 220,
      left: 100,
    },
    margin: {
      top: 50,
      right: 65,
      bottom: 200,
      left: 40,
    },
    ...settings,
  };

  if (showTitle) {
    config.padding.right += 70;
  }

  const bodyHtml = document.querySelector('body');
  const defaultColors = ['rgb(174, 150, 70)', 'rgb(126, 70, 174)', 'rgb(237, 210, 121)'];
  const domains = [];
  const chartData = [];

  let domainFrom = 0;
  let domainTo = 1;
  let maxXTextLength = 0;
  let maxNum = 0;

  for (let i = 0; i < data.length; i++) {
    const d = { ...data[i] };
    const nameLength = d.concept.name.length;
    domains.push(d.concept.name);

    if (nameLength > maxXTextLength) {
      maxXTextLength = nameLength;
    }

    if (d.summary.min < domainFrom) {
      domainFrom = d.summary.min;
    }
    if (d.summary.max > domainTo) {
      domainTo = d.summary.max;
    }

    chartData.push(d);
  }

  if (maxXTextLength * 8 > config.margin.bottom) {
    config.margin.bottom = maxXTextLength * 8;
  }

  if (type !== 'SINGLE_CELL') {
    const domainLength = domainTo.toFixed(0).length;
    if (domainLength <= 1) {
      config.margin.left = 30;
    } else {
      config.margin.left = domainLength * 12;
    }
  }

  const chartHeight = config.height - config.margin.top - config.margin.bottom;

  d3js.selectAll(`#${htmlRootId} > *`).remove();

  function defineColor(d, index) {
    return d.colors !== undefined ? d.colors[index] : defaultColors[index];
  }

  const x0 = d3js.scaleBand()
    .range([0, config.width])
    .domain(domains)
    .padding(0.03);

  const y = d3js.scaleLinear()
    .range([chartHeight, 0])
    .domain([domainFrom, domainTo]);

  const svg = d3js.select(`#${htmlRootId}`).append('svg')
    .attr('width', config.width + config.padding.right + config.padding.left)
    .attr('height', config.height)
    .append('g')
    .attr('transform', `translate(${config.margin.left}, ${config.margin.top})`);

  if (showTitle) {
    d3js.select(`#${htmlRootId}`)
      .select('svg')
      .insert('text', ':first-child')
      .text(chartTitle)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    if (showLegend) {
      const SVG = d3js.select(`#${htmlRootId} svg`)
        .append('g')
        .attr('transform', () => `translate(0, ${config.height - 200})`);
      const keys = legendsCustoms.params.map(param => param.name);
      drawLegend({
        element: SVG,
        keys,
        colors: legendsCustoms.colors,
      });
    }
  }

  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', `chart-tooltip chart-tooltip--popup chart-tooltip_${tooltipMode}`);

  function getTooltipTemplate(d) {
    let template = '';
    if (type === 'SINGLE_CELL') {
      template += `
        <div class="chart-tooltip__content">
          <div><b>Min: </b> ${d.summary.min.toFixed(2)}</div>
          <div><b>Median:</b> ${d.summary.median.toFixed(2)}</div>
          <div><b>Max: </b> ${d.summary.max.toFixed(2)}</div>
          <div><b>Number of cells: </b> ${d.points.length}</div></div>
        </div>
      `;
    } else {
      template += `
        <div class="chart-tooltip__content">
          <div><b>Group:</b> ${d.concept}</div>
          <div><b>Min:</b> ${d.summary.min.toFixed(2)}</div>
          <div><b>First quartile:</b> ${d.summary.q1.toFixed(2)}</div>
          <div><b>Median:</b> ${d.summary.median.toFixed(2)}</div>
          <div><b>Third quartile: </b> ${d.summary.q3.toFixed(2)}</div>
          <div><b>Max: </b> ${d.summary.max.toFixed(2)}</div>
      `;
      if (type !== graphTypes.CCLE && type !== graphTypes.CCLE_PROTEOMICS && type !== graphTypes.SANGER_CELL_MODEL) {
        template += `
            <div><b>Total samples: </b> ${d.summary.totalSamples}</div>
          </div>
        `;
      } else if (type === graphTypes.CCLE_PROTEOMICS) {
        template += `
            <div><b>Number of samples with expression: </b>${d.points.length}</div>
            <div><b>Number of cell lines included: </b>${d.cellLinesIncluded}</div>
          </div>
        `;
      } else {
        template += `
            <div><b>Number of samples: </b> ${d.points.length}</div>
          </div>
        `;
      }
    }
    return template;
  }

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d, i) {
    tooltip
      .html(getTooltipTemplate(d, i))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
    d3js.select(this)
      .attr('stroke', 'none');
  }

  const histogram = d3js.histogram()
    .domain(y.domain())
    .thresholds(y.ticks(30))
    .value(d => d);

  const sumstat = d3js
    .nest()
    .key(d => d.concept.name)
    .rollup(d => histogram(d[0].points))
    .entries(chartData);

  const getDataWithSumstate = d => ({
    ...d,
    concept: d.concept.name,
    sumstat: sumstat.find(el => el.key === d.concept.name).value,
  });

  for (let i = 0; i < sumstat.length; i++) {
    const allBins = sumstat[i].value;
    const lengths = allBins.map(a => a.length);
    const longuest = d3js.max(lengths);
    if (longuest > maxNum) {
      maxNum = longuest;
    }
  }

  const xNum = d3js.scaleLinear()
    .range([0, x0.bandwidth()])
    .domain([-maxNum, maxNum]);

  function createViolinPlot(plotData, i) {
    d3js.select(this)
      .append('path')
      .datum(plotData.sumstat)
      .style('cursor', 'pointer')
      .style('stroke', 'none')
      .style('fill', defineColor(plotData, i))
      .attr('d', d3js.area()
        .x0(d => xNum(-d.length))
        .x1(d => xNum(d.length))
        .y(d => y(d.x0))
        .curve(d3js.curveCatmullRom)
      );
  }

  function createBoxPlot(d) {
    function calcBoxHeight(value) {
      const boxHeight = y(value.summary.q1) - y(value.summary.q3);
      return Math.max(boxHeight, 1) || 0;
    }

    d3js.select(this)
      .append('rect')
      .attr('class', 'range')
      .attr('width', 4)
      .attr('x', xNum(0) - 2)
      .attr('y', y(d.summary.q3))
      .attr('height', calcBoxHeight)
      .style('fill', defineColor(d, 2))
      .style('stroke', defineColor(d, 1))
      .style('stroke-width', '1px')
      .style('cursor', 'pointer');

    // median line
    d3js.select(this)
      .append('line')
      .attr('x1', xNum(0) - 2)
      .attr('x2', xNum(0) + 2)
      .attr('y1', y(d.summary.median))
      .attr('y2', y(d.summary.median))
      .style('stroke', defineColor(d, 1))
      .style('stroke-width', '2.5px')
      .style('pointer-events', 'none')
      .style('z-index', '0');
  }

  function createOutliers(d) {
    const { outliers = [], extremes = [] } = d;

    outliers.forEach((el) => {
      d3js.select(this)
        .append('circle')
        .attr('class', 'point')
        .attr('r', 2)
        .attr('cx', xNum(0) + el.scatterNumber)
        .attr('cy', y(el.point))
        .style('fill', '#fff')
        .style('stroke-width', '1px')
        .style('stroke', defineColor(el, 1));
    });

    extremes.forEach((el) => {
      d3js.select(this)
        .append('circle')
        .attr('class', 'point')
        .attr('r', 2)
        .attr('cx', xNum(0))
        .attr('cy', y(el.point))
        .style('fill', defineColor(el, 0))
        .style('stroke-width', '1px')
        .style('stroke', defineColor(el, 1));
    });
  }

  const xAxis = svg.append('g')
    .attr('transform', `translate(${config.margin.right}, ${chartHeight})`)
    .attr('class', 'x-axis')
    .call(d3js.axisBottom(x0));

  xAxis.selectAll('text')
    .attr('x', 15)
    .attr('y', 4)
    .style('text-anchor', 'start')
    .style('cursor', type === 'SINGLE_CELL' ? 'default' : 'pointer')
    .style('text-anchor', 'start')
    .style('font-size', '16px')
    .style('font-weight', (d) => {
      if (!showTitle && selectedCells) {
        return selectedCells.find(c => d === c.concept.name) && 700;
      }
      return 400;
    })
    .attr('transform', 'rotate(45)')
    .on('mouseover', (d) => {
      if (showTooltipHandler) {
        showTooltipHandler(
          chartData.find(el => el.concept.name === d),
          d3js.event.x,
          d3js.event.y,
        );
      }
    })
    .on('mouseout', hideTooltip && hideTooltip)
    .on('click', d => handleCellClick(d));

  xAxis.selectAll('line')
    .attr('y2', 10);

  const yAxis = svg.append('g')
    .attr('transform', `translate(${config.margin.right},0)`)
    .call(d3js.axisLeft(y).tickSize(-config.width))
    .call(g => g.selectAll('.tick line')
      .attr('stroke-opacity', 0.5)
      .attr('stroke-dasharray', '2,2'))
    .attr('class', 'y-axis')
    .attr('transform', `translate(${config.margin.right},0)`);

  yAxis.selectAll('text')
    .attr('x', -14)
    .style('font-size', '16px')
    .style('cursor', type === 'SINGLE_CELL' ? 'default' : 'pointer');

  const yAxisLabel = yAxisText || 'TPM';
  const textForYAxis = violinScale === 'linear' ? `${yAxisLabel}` : `log2(${yAxisLabel} + 1)`;

  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -((config.height - config.margin.bottom) / 2))
    .attr('dy', type !== 'SINGLE_CELL' ? `-${config.margin.left + 30}px` : '-50')
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text(textForYAxis);

  const group = svg.selectAll('.boxplot-group')
    .data(chartData)
    .enter()
    .append('g')
    .attr('class', 'boxplot-group')
    .attr('transform', d => `translate(${x0(d.concept.name)},0)`);

  group.selectAll('g.boxplot')
    .data(d => [getDataWithSumstate(d)])
    .enter()
    .append('g')
    .attr('class', 'boxplot')
    .attr('transform', () => `translate(${config.margin.right},0)`)
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave)
    .each(createViolinPlot)
    .each(createBoxPlot)
    .each(createOutliers);
}
