import { fromJS, Map } from 'immutable';
import { handleActions } from 'redux-actions';

// Constants
import { LayoutAlgorhitms } from '../constants';
// Actions
import * as a from './actions';

const initialState = fromJS({
  project: null,
  data: null,
  cachedData: null,
  error: null,
  loading: false,
  allSelected: false,
  settings: {
    hideLabels: false,
    hideSource: false,
    showCooccurrence: true,
    hideRelationTypes: false,
    hideBelowCount: { hide: false, count: null },
    showCloselyRelatedPubs: false,
  },
  layout: {
    algorithm: LayoutAlgorhitms.DEFAULT,
    showArrows: false,
    proportionalLines: false,
    shortTexts: false,
  },
  relatedConceptsOptions: {
    options: [],
    loading: false,
  },
  nodesIsFixed: true,
  semanticCategories: null,
  semanticCategoriesOrder: [],
  cachedSemanticCategories: null,
  isSaved: false,
  dimensions: null,
});

const reducer = handleActions(
  {
    [a.getRelationMapProjectDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [a.setRelationMapAllProjectDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload.data))
        .update('layout', () => fromJS(payload.layout))
        .update('project', () => fromJS(payload.project))
        .update('settings', () => fromJS(payload.settings))
        .update('semanticCategories', () => fromJS(payload.semanticCategories))
        .update('semanticCategoriesOrder', () => fromJS(Object.keys(payload.semanticCategories)))
        .update('nodesIsFixed', () => false)
        .update('loading', () => false)
        .update('error', () => null),
    [a.setRelationMapProjectDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload.data))
        .update('semanticCategories', () => fromJS(payload.semanticCategories))
        .update('semanticCategoriesOrder', () => fromJS(Object.keys(payload.semanticCategories)))
        .update('nodesIsFixed', () => false)
        .update('loading', () => false)
        .update('error', () => null),
    [a.setRelationMapProjectInfoAction]: (state, { payload }) =>
      state
        .update('project', () => fromJS(payload)),
    [a.updateRelationMapConceptAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'nodes', `${payload.id}`], concept =>
          concept.merge(fromJS(payload.data))
        ),
    [a.mergeRelationMapConceptsAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'nodes'], nodes => nodes.merge(fromJS(payload))),
    [a.toggleRelationMapLoaderAction]: (state, { payload }) =>
      state
        .update('loading', () => payload),
    [a.toggleAllRelationMapConceptsAction]: (state, { payload }) =>
      state
        .update('allSelected', () => payload)
        .updateIn(['data', 'nodes'], nodes =>
          nodes.map(n => n.set('selected', payload))
        ),
    [a.updateRelationMapSettingsAction]: (state, { payload }) =>
      state
        .updateIn(['settings', `${payload.key}`], () => fromJS(payload.data)),
    [a.deleteRelationMapConceptsAction]: (state, { payload }) =>
      state
        .merge(payload.reduce((s, id) => {
          const newState = { ...s.toJS() };
          delete newState.data.nodes[`${id}`];
          Object.keys(newState.data.links).forEach((k) => {
            if (k.includes(`${id}`)) {
              delete newState.data.links[k];
            }
          });
          const nodesCategories = Object.values(newState.data.nodes).map(n => n.semanticCategory);
          Object.keys(newState.semanticCategories).forEach((k) => {
            if (!nodesCategories.includes(k)) {
              delete newState.semanticCategories[k];
            }
          });
          return fromJS(newState);
        }, new Map({
          data: state.get('data'),
          semanticCategories: state.get('semanticCategories'),
        }))),
    [a.deleteRelationMapLinkAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'links'], links => links.delete(payload)),
    [a.mergeRelationMapLinksAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'links'], links => links.merge(fromJS(payload))),
    [a.updateRelationMapLinkLabelAction]: (state, { payload }) =>
      state
        .updateIn(['data', 'links', `${payload.linkId}`, 'label'], () => payload.label),
    [a.throwRelationMapProjectErrorAction]: (state, { payload }) =>
      state
        .update('data', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [a.updateRelationMapNodesIsFixedAction]: (state, { payload }) =>
      state
        .update('nodesIsFixed', () => payload.fixed)
        .updateIn(['data', 'nodes'], nodes => nodes.merge(fromJS(payload.nodes))),
    [a.updateRelationMapLayoutAction]: (state, { payload }) =>
      state
        .updateIn(['layout', payload.option], () => fromJS(payload.data)),
    [a.toggleRelationMapProportionalLinksLayoutAction]: state =>
      state
        .updateIn(['layout', 'proportionalLines'], proportionalLines => !proportionalLines),
    [a.updateRelationMapAlgorithmAction]: (state, { payload }) =>
      state
        .updateIn(['layout', 'algorithm'], () => payload),
    [a.cacheRelationMapDataAction]: state =>
      state
        .update('cachedData', () => state.get('data'))
        .update('cachedSemanticCategories', () => state.get('semanticCategories')),
    [a.undoRelationMapAction]: state =>
      state
        .update('data', () => state.get('cachedData'))
        .update('cachedData', () => null)
        .update('semanticCategories', () => state.get('cachedSemanticCategories'))
        .update('cachedSemanticCategories', () => null),
    [a.getRelationMapRelatedConceptsOptionsAction]: state =>
      state
        .updateIn(['relatedConceptsOptions', 'loading'], () => true),
    [a.setRelationMapRelatedConceptsOptionsAction]: (state, { payload }) =>
      state
        .updateIn(['relatedConceptsOptions', 'options'], () => fromJS(payload))
        .updateIn(['relatedConceptsOptions', 'loading'], () => false),
    [a.updateRelationMapColorsAndShapesAction]: (state, { payload: { category, updater } }) =>
      state
        .updateIn(['data', 'nodes'], nodes => nodes.map(n => (n.get('semanticCategory') === category ? n.merge(updater) : n)))
        .updateIn(['semanticCategories', category], c => c.merge({ category, ...updater })),
    [a.updateRelationMapSemCategoriesAction]: (state, { payload }) =>
      state
        .updateIn(['semanticCategories', payload.category], () => fromJS(payload)),
    [a.updateRelationMapSemCategoriesOrderAction]: (state, { payload }) =>
      state
        .update('semanticCategoriesOrder', () => fromJS(payload)),
    [a.toggleRelationMapIsSavedAction]: state =>
      state
        .update('loading', () => false)
        .update('isSaved', isSaved => !isSaved),
    [a.setRelationMapDimensionsAction]: (state, { payload }) =>
      state
        .update('dimensions', () => fromJS(payload)),
    [a.resetRelationMapAction]: () => initialState,
  },
  initialState
);

export default reducer;
