import { call, put, takeLatest } from 'redux-saga/effects';

// Store
import * as a from './reducer';
// Utils
import { convertTableToCSVText, exportToFile } from '../../../../../../../Utils/Utils';
// Api
import Api from '../../../../../../../../Api/Api';

export function* cellTypeClusterDataWorker(action) {
  try {
    const { selectedCluster, page } = action.payload;
    const { data } = yield call(Api.getCellTypeClusterData, { selectedCluster, page });
    yield put(a.setCellTypeClusterDataAction(data));
  } catch (e) {
    yield put(a.throwCellTypeClusterDataErrorAction(e.message));
  }
}

export function* exportCellTypeClusterDataWorker(action) {
  try {
    yield put(a.toggleExportCellTypeClusterDataLoadingAction(true));

    const { selectedCluster, page, size, columns, downloadLink, fileName } = action.payload;
    const { data } = yield call(Api.getCellTypeClusterData, { selectedCluster, page, size });
    const csvData = convertTableToCSVText(data.content, columns);
    exportToFile(downloadLink, csvData, fileName);
    yield put(a.toggleExportCellTypeClusterDataLoadingAction(false));
  } catch (e) {
    console.log(e.message);
    yield put(a.toggleExportCellTypeClusterDataLoadingAction(false));
  }
}

function* cellTypeClusterSaga() {
  yield takeLatest(a.getCellTypeClusterDataAction, cellTypeClusterDataWorker);
  yield takeLatest(a.exportCellTypeClusterDataAction, exportCellTypeClusterDataWorker);
}

export default cellTypeClusterSaga;

