import { takeLatest } from 'redux-saga/effects';

import {
  getCreateSetConceptsAction,
  getCreateSetAllConceptsAction,
  saveCreatedSetAction,
  getGeneSetFilterOptionsAction,
  getSourceDetailsFilterOptionsAction,
  getSetForFilteringAction,
} from '../actions';

import { getCreateSetConceptsWorker } from './workers/getCreateSetConceptsWorker';
import { getCreateSetAllConceptsWorker } from './workers/getCreateSetAllConceptsWorker';
import { saveCreatedSetWorker } from './workers/saveCreatedSetWorker';
import { getGeneSetFilterOptionsWorker } from './workers/getGeneSetFilterOptionsWorker';
import { getSourceDetailsFilterOptionsWorker } from './workers/getSourceDetailsFilterOptionsWorker';
import {getSetForFilteringWorker} from './workers/loadSavedSetData';

function* createSetSaga() {
  yield takeLatest(getCreateSetConceptsAction, getCreateSetConceptsWorker);
  yield takeLatest(getCreateSetAllConceptsAction, getCreateSetAllConceptsWorker);
  yield takeLatest(saveCreatedSetAction, saveCreatedSetWorker);
  yield takeLatest(getGeneSetFilterOptionsAction, getGeneSetFilterOptionsWorker);
  yield takeLatest(getSourceDetailsFilterOptionsAction, getSourceDetailsFilterOptionsWorker);
  yield takeLatest(getSetForFilteringAction, getSetForFilteringWorker);
}

export default createSetSaga;
