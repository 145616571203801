export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const CURRENT_USER_REQUESTED = 'CURRENT_USER_REQUESTED';
export const CURRENT_USER_SUCCEEDED = 'CURRENT_USER_SUCCEEDED';
export const CURRENT_USER_FAILED = 'CURRENT_USER_FAILED';
export const ALL_CONCEPT_TYPES_REQUESTED = 'ALL_CONCEPT_TYPES_REQUESTED';
export const ALL_CONCEPT_TYPES_SUCCEEDED = 'ALL_CONCEPT_TYPES_SUCCEEDED';
export const ALL_CONCEPT_TYPES_FAILED = 'ALL_CONCEPT_TYPES_FAILED';

export const CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED = 'CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED';
export const CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED = 'CEREBRUM_SEMANTIC_CATEGORIES_SUCCEEDED';
export const CEREBRUM_SEMANTIC_CATEGORIES_FAILED = 'CEREBRUM_SEMANTIC_CATEGORIES_FAILED';

export const CEREBRUM_ACCESS_MAPPINGS_REQUESTED = 'CEREBRUM_ACCESS_MAPPINGS_REQUESTED';
export const CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED = 'CEREBRUM_ACCESS_MAPPINGS_SUCCEEDED';
export const CEREBRUM_ACCESS_MAPPINGS_FAILED = 'CEREBRUM_ACCESS_MAPPINGS_FAILED';

export const USER_UPDATE_OPTION = 'USER_UPDATE_OPTION';
export const USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED = 'USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED';

export const USERFLOW_DEV_ID = 'ct_htoe7av7ard7vovh7g7trfpuhq';
export const USERFLOW_PROD_ID = 'ct_r2qmhmozavbonm3ywruivpb27q';
export const USERFLOW_CONTENT_ID = '679bf2e5-b915-4c25-9a02-20d64db04631';

export const INFO_VALUES = {
  USERFLOW: 'USERFLOW',
};
