import { call, put, select, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../Api/Api';
// Constants
import { CUSTOMER, ENV } from '../../settings';
// Store
import Storage from './Storage/Storage';
import { userForm } from './selectors';
import {
  userLoginRequestAction,
  userLoginSuccessAction,
  userLoginFailureAction,
  userLogoutRequestAction,
  userLogoutSuccessAction,
  userLogoutFailureAction,
  tokenExpiredAction,
} from './reducer';
import { toggleAuthenticatorCodeModalIsOpenAction } from './TwoFactorAuthentication/store/reducer';
import { getLocationHistorySelector } from '../../location/locationSelector';
// Constants
import { outerUrls } from '../../constantsCommon';
// Utils
import { detectBrowser } from '../Utils/Utils';

function* userLoginSaga({ payload }) {
  try {
    const form = yield select(userForm);
    const requestData = {
      ...form,
      ...(payload?.code && { code: payload.code }),
      browser: `${detectBrowser()}, ${navigator?.userAgentData?.platform ?? 'Unknown browser'}, ${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };
    const user = yield call(Api.loginUser, requestData);
    if (user.data.message === '2FA code is missing') {
      yield put(toggleAuthenticatorCodeModalIsOpenAction(true));
    } else {
      yield put(userLoginSuccessAction(user.data));
      yield call(Storage.setValue, { key: 'user', value: user.data });
      yield call(Api.setToken, user.data.token);
      yield put(toggleAuthenticatorCodeModalIsOpenAction(false));
    }
  } catch (e) {
    const { status, data: { proposedLoginUrl, message } } = e.response;
    if (status === 421) {
      window.open(proposedLoginUrl, '_self');
    } else {
      yield put(userLoginFailureAction(message));
    }
  }
}

function* userLogoutSaga() {
  try {
    yield call(Api.logoutUser);
    yield put(userLogoutSuccessAction());
    yield call(Storage.setValue, { key: 'user', value: null });
  } catch (e) {
    yield put(userLogoutFailureAction(e.message));
  }
}

function* tokenExpiredSaga() {
  try {
    const locationHistory = yield select(getLocationHistorySelector);
    const prevPath = locationHistory[locationHistory.length - 2] || locationHistory[locationHistory.length - 1];
    localStorage.setItem('locationHistory', prevPath);
    yield put(userLogoutSuccessAction());
    yield call(Storage.setValue, { key: 'user', value: { tokenExpired: true } });
    if (CUSTOMER === 'bms' && ENV === 'prod') {
      // window.location = `${API_URL}saml2/authenticate/bms`;
      window.open(outerUrls.bms, '_self');
    }
  } catch (e) {
    yield put(userLogoutFailureAction(e.message));
  }
}

function* rootSaga() {
  yield takeLatest(tokenExpiredAction, tokenExpiredSaga);
  yield takeLatest(userLogoutRequestAction, userLogoutSaga);
  yield takeLatest(userLoginRequestAction, userLoginSaga);
}

export default rootSaga;
