import { types } from './constants';

// Chapter
export const getPatentsAction = (data, exportData) => (
  {
    type: types.GET_PATENTS,
    data,
    exportData,
  }
);

export const putPatentsAction = data => (
  {
    type: types.PUT_PATENTS,
    data,
  }
);

export const patentsLoadingAction = data => (
  {
    type: types.PATENTS_LOADING,
    data,
  }
);

export const patentsFailedAction = message => (
  {
    type: types.PATENTS_FAILED,
    message,
  }
);

export const patentsSortAction = data => (
  {
    type: types.PATENTS_SORT,
    data,
  }
);

// Patent
export const getPatentInfoAction = data => (
  {
    type: types.GET_PATENT_INFO,
    data,
  }
);

export const putPatentsInfoAction = data => (
  {
    type: types.PUT_PATENT_INFO,
    data,
  }
);

export const patentsPatentInfoFailedAction = message => (
  {
    type: types.PATENTS_PATENT_INFO_FAILED,
    message,
  }
);

// Sequences
export const getPatentsSequencesAction = data => (
  {
    type: types.GET_PATENTS_SEQUENCES,
    data,
  }
);

export const putPatentsSequencesAction = data => (
  {
    type: types.PUT_PATENTS_SEQUENCES,
    data,
  }
);

export const patentsSequencesFailedAction = message => (
  {
    type: types.PATENTS_SEQUENCES_FAILED,
    message,
  }
);

// Chemicals
export const getPatentsChemicalsAction = data => (
  {
    type: types.GET_PATENTS_CHEMICALS,
    data,
  }
);

export const putPatentsChemicalsAction = data => (
  {
    type: types.PUT_PATENTS_CHEMICALS,
    data,
  }
);

export const patentsChemicalsFailedAction = message => (
  {
    type: types.PATENTS_CHEMICALS_FAILED,
    message,
  }
);

export const patentsChemicalsLoadingAction = data => (
  {
    type: types.PATENTS_CHEMICALS_LOADING,
    data,
  }
);

// Popup
export const togglePatentInfoPopupAction = data => (
  {
    type: types.TOGGLE_PATENT_POPUP,
    data,
  }
);

export const togglePatentInfoPopupLoaderAction = data => (
  {
    type: types.TOGGLE_PATENT_POPUP_LOADER,
    data,
  }
);

export const setPatentsExportLoadingAction = data => (
  {
    type: types.PATENTS_EXPORT_LOADING,
    data,
  }
);
