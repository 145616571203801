import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  togglePatentInfoPopupAction,
  togglePatentInfoPopupLoaderAction,
  putPatentsInfoAction,
  patentsPatentInfoFailedAction,
} from '../../../redux/actions';

export function* getPatentInfoWorker(action) {
  try {
    const { data } = action;
    yield put(togglePatentInfoPopupAction(data.type));
    yield put(togglePatentInfoPopupLoaderAction());
    const requestData = data;
    const response = yield call(Api.getPatentsPatentInfo, requestData);
    yield put(putPatentsInfoAction(response));
  } catch (e) {
    yield put(patentsPatentInfoFailedAction(e.message));
  }
}
