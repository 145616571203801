import { createSelector } from 'reselect';

const compareSetsData = state => state.getIn(['compareSetsReducer', 'sets']);
const compareSetsLoading = state => state.getIn(['compareSetsReducer', 'loading']);
const compareSetsError = state => state.getIn(['compareSetsReducer', 'error']);

export const getCompareSetsDataSelector = createSelector(
  compareSetsData,
  data => data && data.toJS()
);

export const getCompareSetsLoadingSelector = createSelector(
  compareSetsLoading,
  data => data
);

export const getCompareSetsErrorSelector = createSelector(
  compareSetsError,
  data => data
);
