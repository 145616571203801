import {
  ADD_LITERATURE_SEARCH_TERMS,
  REMOVE_LITERATURE_SEARCH_TERMS,
  SAVE_LITERATURE_SEARCH_TERMS,
  CLEAR_LITERATURE_SEARCH_TERMS,
} from './constants';

export const addLiteratureSearchTermsAction = data => (
  {
    type: ADD_LITERATURE_SEARCH_TERMS,
    data,
  }
);

export const removeLiteratureSearchTermsAction = data => (
  {
    type: REMOVE_LITERATURE_SEARCH_TERMS,
    data,
  }
);

export const saveLiteratureSearchTermsAction = () => (
  {
    type: SAVE_LITERATURE_SEARCH_TERMS,
  }
);

export const clearLiteratureSearchTermsAction = () => (
  {
    type: CLEAR_LITERATURE_SEARCH_TERMS,
  }
);

