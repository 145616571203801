export const types = {
  // Patents
  GET_PATENTS: 'GET_PATENTS',
  PUT_PATENTS: 'PUT_PATENTS',
  PATENTS_FAILED: 'PATENTS_FAILED',
  PATENTS_SORT: 'PATENTS_SORT',
  PATENTS_LOADING: 'PATENTS_LOADING',
  // Patent
  GET_PATENT_INFO: 'GET_PATENT_INFO',
  PUT_PATENT_INFO: 'PUT_PATENT_INFO',
  PATENTS_PATENT_INFO_FAILED: 'PATENTS_PATENT_INFO_FAILED',
  // Sequences
  GET_PATENTS_SEQUENCES: 'GET_PATENTS_SEQUENCES',
  PUT_PATENTS_SEQUENCES: 'PUT_PATENTS_SEQUENCES',
  PATENTS_SEQUENCES_FAILED: 'PATENTS_SEQUENCES_FAILED',
  // Chemicals
  GET_PATENTS_CHEMICALS: 'GET_PATENTS_CHEMICALS',
  PUT_PATENTS_CHEMICALS: 'PUT_PATENTS_CHEMICALS',
  PATENTS_CHEMICALS_FAILED: 'PATENTS_CHEMICALS_FAILED',
  PATENTS_CHEMICALS_LOADING: 'PATENTS_CHEMICALS_LOADING',
  // Popup
  TOGGLE_PATENT_POPUP: 'TOGGLE_PATENT_POPUP',
  TOGGLE_PATENT_POPUP_LOADER: 'TOGGLE_PATENT_POPUP_LOADER',
  // Export
  PATENTS_EXPORT_LOADING: 'PATENTS_EXPORT_LOADING',
};
