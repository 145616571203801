import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { insertHTML } from '../../Utils/Utils';
import { exportPathwayImage } from './Utils';
// Components
import Loader from '../../common/Loader/Loader';
import NoData from '../../common/NoData/NoData';
import ZoomBtn from '../../common/Zoom/ZoomBtn';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Zoom from '../../common/Zoom/Zoom';
import Error from '../../common/Error/Error';
import SelectInput from '../../common/Inputs/SelectInput/SelectInput';
// Store
import { loadNextChapterAction } from '../actions';
import * as SELECTORS from './store/selectors';
import * as ACTIONS from './store/reducer';
// Styles
import './styles.scss';

const propTypes = {
  gene: PropTypes.string.isRequired,
  getPathwaysData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  resetPathwaysData: PropTypes.func,
  geneName: PropTypes.string,
  activeImage: PropTypes.instanceOf(Object),
  getPngImageForExport: PropTypes.func,
  pathwaysOptions: PropTypes.instanceOf(Object),
};

class PathwaysChapter extends React.Component {
  constructor(props) {
    super(props);

    this.initPage();
    this.state = {
      showInfoText: false,
      zoomIsOpen: false,
    };
  }

  initPage = () => {
    const {
      gene,
      getPathwaysData,
      itsNewConcept,
      loadNextChapter,
      resetPathwaysData,
      pathwaysOptions,
    } = this.props;

    if (itsNewConcept || pathwaysOptions.length > 0) {
      resetPathwaysData();
      getPathwaysData({ geneId: gene });
    } else {
      loadNextChapter('phenotypes');
    }
  };

  handleSelectChange = (value) => {
    const {
      gene,
      activeImage,
      getPathwaysData,
      resetPathwaysData,
    } = this.props;
    if (activeImage.reactomeId !== value.reactomeId) {
      resetPathwaysData();
      getPathwaysData({ geneId: gene, reactomeId: value.reactomeId });
    }
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  exportToPNGCb = () => {
    const {
      gene,
      activeImage,
      geneName = '',
      getPngImageForExport,
    } = this.props;
    getPngImageForExport({
      geneName,
      geneId: gene,
      format: 'png',
      reactomeId: activeImage.reactomeId,
    });
  };

  exportToSVGCb = () => {
    const { geneName, activeImage: { image } } = this.props;
    exportPathwayImage(image, geneName, 'svg');
  };

  openZoom = () => {
    this.setState({
      zoomIsOpen: true,
    });
  };

  closeZoom = () => {
    this.setState({
      zoomIsOpen: false,
    });
  };

  render() {
    const {
      loading,
      error,
      activeImage,
      pathwaysOptions,
    } = this.props;
    const { showInfoText, zoomIsOpen } = this.state;
    const selectorValue = pathwaysOptions.find(o => o.reactomeId === activeImage.reactomeId);

    return (
      <div className="pathways">
        <div className="gene-details-section-title title2">
          <span>Pathways</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              Visualization of known pathways for this gene. Image generated by Reactome.
            </p>
            <p>
              Jassal B, Matthews L, Viteri G, Gong C, Lorente P, Fabregat A, Sidiropoulos K, Cook J, Gillespie M, Haw R,
              Loney F, May B, Milacic M, Rothfels K, Sevilla C, Shamovsky V, Shorser S, Varusai T, Weiser J, Wu G, Stein L,
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Hermjakob H, D'Eustachio P. The reactome pathway knowledgebase. Nucleic Acids Res. 2020 Jan 8;48(D1):D498-D503.
              doi: 10.1093/nar/gkz1031.
              <a target="blank" href="https://pubmed.ncbi.nlm.nih.gov/31691815/">
                PubMed PMID: 31691815.
              </a>
            </p>
          </div>
        }
        {
          activeImage && activeImage.image &&
          <>
            <SelectInput
              bordered={true}
              defaultValue={selectorValue}
              options={pathwaysOptions}
              onSelect={this.handleSelectChange}
              customClassName="pathways-selector"
              noOptionsMessage={() => 'There is no data to display'}
            />
            <div className="export-buttons-section-gene-disease" >
              <span className="circos-export-button-wrapper">
                <div
                  className="export-button"
                  onClick={this.exportToPNGCb}
                  title="Export to png"
                />
                PNG
              </span>
              <span className="circos-export-button-wrapper">
                <div
                  className="export-button"
                  onClick={this.exportToSVGCb}
                  title="Export to svg"
                />
               SVG
              </span>
            </div>
            <ZoomBtn
              openZoom={this.openZoom}
            >
              <div
                id="pathways__picture"
                className="pathways__picture"
                dangerouslySetInnerHTML={insertHTML(activeImage.image)}
              />
            </ZoomBtn>
          </>
        }
        <NoData
          show={!loading && !activeImage.image && !error}
          customClassName="pathways__no-data"
        />
        <Loader isLoading={loading} />
        <Error show={error && !loading} error={error} />
        {
          zoomIsOpen &&
          <ModalComponent
            modalClassName="zoom__popup"
            isOpen={zoomIsOpen}
            closeCb={this.toggleInfoText}
          >
            <Zoom
              zoomIsOpen={zoomIsOpen}
              zoomId="pathways-zoom"
              closeZoom={this.closeZoom}
            >
              <div dangerouslySetInnerHTML={insertHTML(activeImage.image)} />
            </Zoom>
          </ModalComponent>
        }
      </div>
    );
  }
}

PathwaysChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pathwaysOptions: SELECTORS.getPathwaysOptionsSelector(state),
    loading: SELECTORS.getLoadingSelector(state),
    error: SELECTORS.getErrorSelector(state),
    activeImage: SELECTORS.getPathwaysImageSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPathwaysData(data) {
      dispatch(ACTIONS.getPathwaysDataAction(data));
    },
    getPngImageForExport(data) {
      dispatch(ACTIONS.getImageForExportAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
    resetPathwaysData() {
      dispatch(ACTIONS.resetPathwaysDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PathwaysChapter);
