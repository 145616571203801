import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';

const initialState = fromJS({
  selectedSetInfo: {
    setId: null,
    projectId: 'personal',
    setName: null,
  },
});

const reducer = handleActions(
  {
    [a.setSelectedSetInfoAction]: (state, { payload }) =>
      state
        .updateIn(['selectedSetInfo'], () => fromJS(payload)),
  },
  initialState
);

export default reducer;
