import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import NoData from '../../common/NoData/NoData';
import LiteratureFilter from './LiteratureFilter';
import SearchResultItem from '../../Search/Components/SearchResultItem/SearchResultItem';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
// Constants
import { SourcesFilterEnum, TitleEnum } from './enums';
import { SearchSourceFilterEnum } from '../../Search/enums';
import { getDataForSearchRequest } from '../../Search/utils';
// Store
import { loadNextChapterAction } from '../actions';
import {
  saveSearchTermAction,
  resetRelatedConceptsAction,
  checkSearchRelatedConceptAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import { getRelatedConcepts } from '../../Search/store/selectors';
import { getGeneDetailsLiteratureSearchDataAction } from './reducer';
import * as SELECTORS from './selectors';
// Styles
import './LiteratureSearchChapter.scss';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  initSectionData: PropTypes.func,
  loadNextChapter: PropTypes.func,
  sectionData: PropTypes.instanceOf(Object),
  itsNewConcept: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  literatureSortingParams: PropTypes.instanceOf(Object),
  changeFormValue: PropTypes.func,
};

class LiteratureSearchChapter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: {
        show: false,
        config: {},
      },
      title: SourcesFilterEnum.PUB_DATE,
    };
    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      geneName,
      itsNewConcept,
      initSectionData,
      loadNextChapter,
      sectionData,
      changeFormValue,
    } = this.props;
    if (itsNewConcept || isEmpty(sectionData)) {
      initSectionData(getDataForSearchRequest({
        plainValue: geneName,
        exactValue: gene,
        size: 5,
        sort: `${SourcesFilterEnum.PUB_DATE},desc`,
        publicationSource: SearchSourceFilterEnum.ABSTRACT,
      }));
      changeFormValue(geneName);
    } else {
      loadNextChapter('protein-structure');
    }
  };

  openPublicationsPopup = () => {
    const {
      publicationSource,
      publicationTypes,
      sortBy,
      dateFrom,
      dateTo,
    } = this.props.literatureSortingParams;

    this.setState({
      popup: {
        show: true,
        config: {
          publicationSource,
          publicationTypes: publicationTypes || [],
          sort: sortBy && `${sortBy},desc`,
          ...(dateFrom && { dateFrom }),
          ...(dateTo && { dateTo }),
        },
      },
    });
  };

  closePublicationsPopup = () => {
    this.setState({
      popup: {
        show: false,
        config: {},
      },
    });
  };

  handleChangeParam = () => {
    const {
      gene,
      geneName,
      initSectionData,
      literatureSortingParams,
    } = this.props;
    if (!literatureSortingParams) return;

    const {
      publicationSource,
      publicationTypes,
      sortBy,
      dateTo,
      dateFrom,
    } = literatureSortingParams;

    initSectionData(getDataForSearchRequest({
      plainValue: geneName,
      exactValue: gene,
      size: 5,
      dateTo,
      dateFrom,
      publicationSource,
      publicationTypes: publicationTypes || [],
      sort: sortBy && `${sortBy},desc`,
    }));
    this.setState({ title: sortBy });
  };

  render() {
    const {
      loading,
      error,
      geneName,
      sectionData: {
        publications: {
          content,
        },
      },
      gene: id,
      literatureSortingParams,
    } = this.props;
    const { popup, title } = this.state;

    return (
      <div id="literatureSearch">
        <div className="gene-details-section-title title2">
          <span>Literature for {geneName}: {TitleEnum[title]}</span>
        </div>
        <div className="literature__wrapper">
          <div className="literature__sidebar">
            <LiteratureFilter
              geneId={id}
              onSubmit={this.handleChangeParam}
              sortingParams={literatureSortingParams}
              initialValues={{
                publicationSource: SearchSourceFilterEnum.ABSTRACT,
                sortBy: SourcesFilterEnum.PUB_DATE,
              }}
            />
          </div>
          {
            !loading && content.length > 0 &&
            <div className="search-results">
              {
                content.map((item, index) => (
                  <SearchResultItem
                    key={index}
                    publication={item}
                  />
                ))
              }
              <div className="show-more-button-wrapper">
                <span onClick={this.openPublicationsPopup} className="show-more-button">Show more...</span>
              </div>
            </div>
          }
          <NoData show={!loading && content.length === 0} />
          <Loader isLoading={loading} />
          <Error show={!!error} error={error} />
        </div>
        {
          popup.show &&
          <ModalComponent
            isOpen={popup.show}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              ids={[id]}
              names={[geneName]}
              filters={popup.config}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

LiteratureSearchChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionData: SELECTORS.getSectionData(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
    relatedConcepts: getRelatedConcepts(state),
    literatureSortingParams: SELECTORS.getLiteratureSortingParams(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionData(data) {
      dispatch(getGeneDetailsLiteratureSearchDataAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
    checkSearchRelatedConcept(data) {
      dispatch(checkSearchRelatedConceptAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    changeFormValue(value) {
      dispatch(saveSearchTermAction(value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiteratureSearchChapter);
