import {
  METHOD_SELECTION_TABS_STATE,
  METHOD_SELECTED,
  RESET_ANALYTICS_METHOD_SELECTION,
  OPEN_MENU_CATEGORY_ANALYSIS,
  CLOSE_MENU_CATEGORY_ANALYSIS,
  CATEGORY_ANALYSIS_CATEGORIES_REQUESTED,
  CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME,
  CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE,
  RANKING_SETTINGS,
  RANKING_TYPE,
  OPEN_RANKING_MODAL,
  CLOSE_RANKING_MODAL,
  TISSUES_REQUESTED,
  OPEN_MENU_TISSUES,
  OPEN_MENU_TISSUES_PENALTY,
  CLOSE_MENU_TISSUES,
  CLOSE_MENU_TISSUES_PENALTY,
  SELECT_TISSUE_NAME,
  SELECT_TISSUE_TYPE,
  SELECT_PENALTY_TISSUE_NAME,
  SELECT_PENALTY_TISSUES,
  DESELECT_PENALTY_TISSUE_TYPE,
  TICK_TISSUE_SOURCE,
  CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES,
  CLEAR_EXPRESSION_SPECIFICITY_SCORE,
  REFRESH_RANKING_SETTINGS,
  SET_SCORING_FORMULA,
  SET_DEFAULT_SCORING_FORMULA,
  METHOD_SELECTION_SHOULD_RESET,
  INIT_ANALYSIS_METHOD_SELECTION,
  SELECT_GENE_FOR_NETWORK_ANALYSIS,
  OPEN_MENU_TISSUES_NETWORK_ANALYSIS,
  CLOSE_MENU_TISSUES_NETWORK_ANALYSIS,
  SELECT_NETWORK_ANALYSIS_TISSUE_NAME,
  SELECT_NETWORK_ANALYSIS_TISSUE_TYPE,
  TICK_NETWORK_ANALYSIS_TISSUE_SOURCE,
  RESET_NETWORK_ANALYSIS_TISSUE_SOURCE,
  SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS,
  RESET_NETWORK_ANALYSIS_SETTINGS,
  CHANGE_FACTOR_LOGIC_VALUE,
  GET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS_FILTER_OPTION,
  UPDATE_BACKGROUND_SET_CONFIG,
  TOGGLE_BACKGROUND_SET_OPTION,
  TOGGLE_BACKGROUND_CELLS_LOADER,
  SET_BACKGROUND_SET_FILTER_OPTION,
  SWITCH_SHOULD_UPDATE_DATA_FLAG,
  CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  FIRST_SELECTED_METHOD,
} from './constants';

export const changeFactorLogicValueAction = data => (
  {
    type: CHANGE_FACTOR_LOGIC_VALUE,
    data,
  }
);

export const shouldReset = data => (
  {
    type: METHOD_SELECTION_SHOULD_RESET,
    data,
  }
);

export const tabsStateAction = data => (
  {
    type: METHOD_SELECTION_TABS_STATE,
    data,
  }
);

export const methodSelectedAction = data => (
  {
    type: METHOD_SELECTED,
    data,
  }
);

export const firstSelectedMethodAction = data => (
  {
    type: FIRST_SELECTED_METHOD,
    data,
  }
);

export const resetSetAnalysisMethodSelectionAction = () => (
  {
    type: RESET_ANALYTICS_METHOD_SELECTION,
  }
);

export const menuOpen = () => (
  {
    type: OPEN_MENU_CATEGORY_ANALYSIS,
  }
);

export const menuClose = () => (
  {
    type: CLOSE_MENU_CATEGORY_ANALYSIS,
  }
);

export const menuTissuesOpen = () => (
  {
    type: OPEN_MENU_TISSUES,
  }
);

export const menuTissuesClose = () => (
  {
    type: CLOSE_MENU_TISSUES,
  }
);

export const menuPenaltyTissuesOpen = () => (
  {
    type: OPEN_MENU_TISSUES_PENALTY,
  }
);

export const menuPenaltyTissuesClose = () => (
  {
    type: CLOSE_MENU_TISSUES_PENALTY,
  }
);

export const initCategories = () => (
  {
    type: CATEGORY_ANALYSIS_CATEGORIES_REQUESTED,
  }
);

export const selectCategoryName = data => (
  {
    type: CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME,
    data,
  }
);

export const selectTissueName = data => (
  {
    type: SELECT_TISSUE_NAME,
    data,
  }
);

export const selectPenaltyTissueName = data => (
  {
    type: SELECT_PENALTY_TISSUE_NAME,
    data,
  }
);

export const selectNetworkAnalysisTissueName = data => (
  {
    type: SELECT_NETWORK_ANALYSIS_TISSUE_NAME,
    data,
  }
);

export const selectTissueType = data => (
  {
    type: SELECT_TISSUE_TYPE,
    data,
  }
);

export const selectPenaltyTissues = data => (
  {
    type: SELECT_PENALTY_TISSUES,
    data,
  }
);

export const selectNetworkAnalysisTissueType = data => (
  {
    type: SELECT_NETWORK_ANALYSIS_TISSUE_TYPE,
    data,
  }
);

export const deselectPenaltyTissueType = data => (
  {
    type: DESELECT_PENALTY_TISSUE_TYPE,
    data,
  }
);

export const selectCategoryTypeAction = data => (
  {
    type: CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE,
    data,
  }
);

export const setRankingAction = data => (
  {
    type: RANKING_SETTINGS,
    data,
  }
);

export const setRankingType = data => (
  {
    type: RANKING_TYPE,
    data,
  }
);

export const openRankingModal = data => (
  {
    type: OPEN_RANKING_MODAL,
    data,
  }
);

export const closeRankingModal = () => (
  {
    type: CLOSE_RANKING_MODAL,
  }
);

export const clearDifferentialExpressionScore = () => (
  {
    type: CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES,
  }
);

export const clearExpressionSpecificityScore = () => (
  {
    type: CLEAR_EXPRESSION_SPECIFICITY_SCORE,
  }
);

export const initTissues = () => (
  {
    type: TISSUES_REQUESTED,
  }
);

export const tickTissueSource = data => (
  {
    type: TICK_TISSUE_SOURCE,
    data,
  }
);

export const tickNetworkAnalysisTissueSource = data => (
  {
    type: TICK_NETWORK_ANALYSIS_TISSUE_SOURCE,
    data,
  }
);

export const resetNetworkAnalysisTissueSource = () => (
  {
    type: RESET_NETWORK_ANALYSIS_TISSUE_SOURCE,
  }
);

export const refreshRankingSettings = () => (
  {
    type: REFRESH_RANKING_SETTINGS,
  }
);

export const setScoringFormula = data => (
  {
    type: SET_SCORING_FORMULA,
    data,
  }
);

export const setDefaultScoringFormula = () => (
  {
    type: SET_DEFAULT_SCORING_FORMULA,
  }
);

export const initAnalyticsMethod = data => (
  {
    type: INIT_ANALYSIS_METHOD_SELECTION,
    data,
  }
);

export const selectGeneForNetworkAnalysis = data => (
  {
    type: SELECT_GENE_FOR_NETWORK_ANALYSIS,
    data,
  }
);

export const menuNetworkAnalysisTissuesOpen = () => (
  {
    type: OPEN_MENU_TISSUES_NETWORK_ANALYSIS,
  }
);

export const menuNetworkAnalysisTissuesClose = () => (
  {
    type: CLOSE_MENU_TISSUES_NETWORK_ANALYSIS,
  }
);

export const selectSetForNetworkAnalysisSettings = data => (
  {
    type: SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS,
    data,
  }
);

export const resetNetworkAnalysisSettings = () => (
  {
    type: RESET_NETWORK_ANALYSIS_SETTINGS,
  }
);

export const getBackgroundCellsAction = () => (
  {
    type: GET_BACKGROUND_CELLS,
  }
);

export const setBackgroundCellsAction = data => (
  {
    type: SET_BACKGROUND_CELLS,
    data,
  }
);

export const setBackgroundCellsFilterOptionAction = data => (
  {
    type: SET_BACKGROUND_CELLS_FILTER_OPTION,
    data,
  }
);

export const toggleBackgroundCellsLoaderAction = data => (
  {
    type: TOGGLE_BACKGROUND_CELLS_LOADER,
    data,
  }
);

export const updateBackgroundSetConfigAction = data => (
  {
    type: UPDATE_BACKGROUND_SET_CONFIG,
    data,
  }
);

export const toggleBackgroundSetOptionAction = data => (
  {
    type: TOGGLE_BACKGROUND_SET_OPTION,
    data,
  }
);

export const setBackgroundSetFilterOptionAction = data => (
  {
    type: SET_BACKGROUND_SET_FILTER_OPTION,
    data,
  }
);

export const switchShouldUpdateDataFlagAction = data => (
  {
    type: SWITCH_SHOULD_UPDATE_DATA_FLAG,
    data,
  }
);

export const clearBackgroundSetActiveProjectId = () => (
  {
    type: CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  }
);

export const setBackgroundSetActiveProjectId = data => (
  {
    type: SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
    data,
  }
);
