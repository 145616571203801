import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const findRelatedProteinOrRegulationRequestedAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_REQUESTED');
export const findRelatedProteinOrRegulationSucceededAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_SUCCEEDED');
export const findRelatedProteinOrRegulationFailedAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_FAILED');

export const findRelatedProteinOrRegulationTableSortAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_SORT');
export const findRelatedProteinOrRegulationTableCheckAllAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_CHECK_ALL');
export const findRelatedProteinOrRegulationTableCheckItemAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_CHECK_ITEM');
export const findRelatedProteinOrRegulationTableRemoveSelectedAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_REMOVE_SELECTED');
export const findRelatedProteinOrRegulationTableInvertSelectionAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_INVERT_SELECTION');
export const findRelatedProteinOrRegulationTableRemoveConceptAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_REMOVE_CONCEPT');
export const findRelatedProteinOrRegulationTableLoadingAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_LOADING');
export const findRelatedProteinOrRegulationTableResetAction = createAction('FIND_RELATED_PROTEIN_OR_REGULATION_TABLE_RESET');

const initialValues = fromJS({
  result: {
    content: [],
  },
  error: null,
  sorting: { sortBy: 'publicationCoOccurrence', sortDirection: 'DESC' },
  loading: false,
});

const reducer = handleActions(
  {
    [findRelatedProteinOrRegulationSucceededAction]: (state, { payload }) => {
      let { content } = payload;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => Object.assign(item, { id: index + 1 }));
      }
      const result = Object.assign(payload, { content });
      return state.merge(fromJS({ result, error: null, loading: false }));
    },
    [findRelatedProteinOrRegulationFailedAction]: (state, { payload }) => (
      state.merge(fromJS({
        error: payload,
        result: initialValues.get('result'),
        loading: false,
      }))
    ),
    [findRelatedProteinOrRegulationTableSortAction]: (state, { payload }) => (
      state.update('sorting', () => fromJS(payload))
    ),
    [findRelatedProteinOrRegulationTableCheckAllAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => (
        content.map(item => item.merge(fromJS({ selected: payload })))
      ))
    ),
    [findRelatedProteinOrRegulationTableCheckItemAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => (
        content.map((item) => {
          if (item.get('id') === payload.id) {
            return item.merge(fromJS({ selected: payload.checked }));
          }
          return item;
        })
      ))
    ),
    [findRelatedProteinOrRegulationTableRemoveSelectedAction]: state => (
      state.updateIn(['result', 'content'], content => content.filterNot(
        item => item.get('selected')
      ))
    ),
    [findRelatedProteinOrRegulationTableInvertSelectionAction]: state => (
      state.updateIn(['result', 'content'], content => content.map(
        item => item.merge(fromJS({ selected: !item.get('selected') }))
      ))
    ),
    [findRelatedProteinOrRegulationTableRemoveConceptAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => content.delete(
        content.findIndex(item => item.get('id') === payload)
      ))
    ),
    [findRelatedProteinOrRegulationTableLoadingAction]: (state, { payload }) => (
      state.update('loading', () => payload)
    ),
    [findRelatedProteinOrRegulationTableResetAction]: state => (
      state.merge(initialValues)
    ),
  },
  initialValues
);

export default reducer;
