export const GENE_DETAILS_REQUESTED = 'GENE_DETAILS_REQUESTED';
export const GENE_DETAILS_SUCCEEDED = 'GENE_DETAILS_SUCCEEDED';
export const GENE_DETAILS_FAILED = 'GENE_DETAILS_FAILED';
export const GENE_DETAILS_LOADING = 'GENE_DETAILS_LOADING';
export const GENE_DETAILS_RESET = 'GENE_DETAILS_RESET';
export const GENE_DETAILS_LOAD_CHAPTER = 'GENE_DETAILS_LOAD_CHAPTER';
export const GENE_DETAILS_LOAD_ALL_CHAPTERS = 'GENE_DETAILS_LOAD_ALL_CHAPTERS';
export const GENE_DETAILS_UNLOAD_CHAPTERS = 'GENE_DETAILS_UNLOAD_CHAPTERS';
export const GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID = 'GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID';
export const GENE_DETAILS_SET_CHAPTER_DATA = 'GENE_DETAILS_SET_CHAPTER_DATA';

export const PAGE_SIZE = 20;
