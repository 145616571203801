import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const showVersionDialogAction = createAction('@@versionDialog/SHOW');
export const hideVersionDialogAction = createAction('@@versionDialog/HIDE');

const initialState = fromJS({
  isOpen: false,
});

const reducer = handleActions(
  {
    [showVersionDialogAction]: state =>
      state
        .update('isOpen', () => true),
    [hideVersionDialogAction]: () => initialState,
  },
  initialState
);

export default reducer;
