import { takeLatest } from 'redux-saga/effects';

import { types } from '../constants';

import { initChartWorker } from './workers/initChartWorker';
import { initTableWorker } from './workers/initTableWorker';

function* rootSaga() {
  yield takeLatest(types.GENE_DETAILS_FUNC_CHARACTERIZATION_SET_CHART_DATA, initChartWorker);
  yield takeLatest(types.GENE_DETAILS_FUNC_CHARACTERIZATION_SET_TABLE_DATA, initTableWorker);
}

export default rootSaga;
