import {
  createSelector,
} from 'reselect';

const annotationData = state => state.getIn(['geneDiseaseAnnotation', 'data']);
const loading = state => state.getIn(['geneDiseaseAnnotation', 'loading']);
const error = state => state.getIn(['geneDiseaseAnnotation', 'error']);

export const getAnnotationData = createSelector(
  annotationData,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
