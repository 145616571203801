import { fromJS } from 'immutable';

import {
  SORT,
} from '../SimpleTable/constants';

import {
  GENES_DETAIL_OPEN,
  GENES_DETAIL_CLOSE,
  GENES_DETAIL_SUCCEEDED,
  GENES_DETAIL_FAILED,
  GENES_DETAIL_TABLE,
} from './constants';

const initialValues = fromJS({
  result: {
    content: [],
    page: 0,
    totalPages: 0,
    totalElements: 0,
    pageable: {
      pageNumber: 0,
    },
  },
  resultForSet: {
    content: [],
    page: 0,
    totalPages: 0,
    totalElements: 0,
    pageable: {
      pageNumber: 0,
    },
  },
  concept: {},
  apiKey: null,
  error: null,
  open: false,
  sorting: { sortBy: 'publicationCoOccurrence', sortDirection: 'DESC' },
  loading: true,
});

const SetAnalysisGenesDetail = (state = initialValues, action) => {
  switch (action.type) {
    case GENES_DETAIL_OPEN:
      return state
        .update('concept', () => fromJS(action.data.concept))
        .update('analyticsGenes', () => fromJS(action.data.genes))
        .update('apiKey', () => action.data.genesDetailAPIKey)
        .update('open', () => true);

    case GENES_DETAIL_CLOSE:
      return initialValues;

    case GENES_DETAIL_SUCCEEDED:
      return state
        .set('result', fromJS(action.data))
        .set('error', null)
        .set('loading', false);

    case GENES_DETAIL_FAILED:
      return state.merge(fromJS({ result: initialValues.get('result'), error: action.message, loading: false }));

    case GENES_DETAIL_TABLE + SORT:
      return state.update('sorting', () => fromJS(action.data));

    default:
      return state;
  }
};

export default SetAnalysisGenesDetail;
