import { createSelector } from 'reselect';

const sorting = state => state.getIn(['findRelatedAnalysis', 'sorting']);
const content = state => state.getIn(['findRelatedAnalysis', 'result', 'content']);
const totalPages = state => state.getIn(['findRelatedAnalysis', 'result', 'totalPages']);
const totalElements = state => state.getIn(['findRelatedAnalysis', 'result', 'totalElements']);
const loading = state => state.getIn(['findRelatedAnalysis', 'loading']);
const pageNumber = state => state.getIn(['findRelatedAnalysis', 'result', 'number']);
const error = state => state.getIn(['findRelatedAnalysis', 'error']);

export const getContentSelector = createSelector(
  content,
  data => data.toJS()
);

export const getTableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getTotalItems = createSelector(
  totalElements,
  data => data
);

export const getSelectedItems = createSelector(
  content,
  data => data.filter(item => item.get('selected')).toJS()
);

export const getLoadingKey = createSelector(
  loading,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
