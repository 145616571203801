import {
  createSelector,
} from 'reselect';

import { conclusionChartData } from './enums';

const sectionData = state => state.getIn(['geneDiseaseConclusion', 'data']);
const loading = state => state.getIn(['geneDiseaseConclusion', 'loading']);
const error = state => state.getIn(['geneDiseaseConclusion', 'error']);

export const getSectionData = createSelector(
  sectionData,
  (_data) => {
    const data = _data.toJS();

    const asterChartData = conclusionChartData.reduce((chartData, item) => {
      const newItem = Object.assign({}, item);
      newItem.score = data[item.id] || 0;
      chartData.push(newItem);

      return chartData;
    }, []);

    let score =
      asterChartData.reduce((a, b) => a + (b.score * b.weight), 0)
      / asterChartData.reduce((a, b) => a + b.weight, 0);

    score = Math.round(score);

    return {
      chartData: asterChartData,
      score,
    };
  }
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
