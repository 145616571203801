import { types } from './constants';

export const getProteinStructureDataAction = data => (
  {
    type: types.GET_PROTEIN_STRUCTURE_DATA,
    data,
  }
);

export const setProteinStructureDataAction = data => (
  {
    type: types.SET_PROTEIN_STRUCTURE_DATA,
    data,
  }
);

export const toggleProteinStructureLoaderAction = data => (
  {
    type: types.TOGGLE_PROTEIN_STRUCTURE_LOADER,
    data,
  }
);

export const proteinStructureIsFailedAction = message => (
  {
    type: types.PROTEIN_STRUCTURE_IS_FAILED,
    message,
  }
);

export const getPDBEntriesAction = data => (
  {
    type: types.GET_PDB_ENTRIES,
    data,
  }
);

export const setPDBEntriesAction = data => (
  {
    type: types.SET_PDB_ENTRIES,
    data,
  }
);

export const updatePDBIdAction = data => (
  {
    type: types.UPDATE_PDB_ID,
    data,
  }
);

export const setPDBEntriesErrorAction = data => (
  {
    type: types.SET_PDB_ENTRIES_ERROR,
    data,
  }
);
