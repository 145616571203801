import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as a from './actions';

const initialState = fromJS({
  file: {},
  error: '',
  loading: false,
  mainCategories: null,
  checkedST: [],
  checkedSC: [],
  taxonomies: [],
  import: {
    rowFrom: '',
    rowTo: '',
    columnFrom: '',
    columnTo: '',
  },
  isComplexSet: false,
});

const reducer = handleActions(
  {
    [a.succeededUploadFileAction]: (state, { payload }) =>
      state
        .update('isComplexSet', () => payload.isComplexSet)
        .update('loading', () => initialState.get('loading'))
        .update('error', () => initialState.get('error')),
    [a.setUploadedFileToStoreAction]: (state, { payload }) =>
      state
        .update('file', () => fromJS(payload)),
    [a.setUploadFileTypeAction]: (state, { payload }) =>
      state
        .update('type', () => fromJS(payload)),
    [a.toggleUploadFileLoadingAction]: (state, { payload }) =>
      state
        .update('loading', () => fromJS(payload)),
    [a.getUploadFileSetDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => initialState.get('error')),
    [a.throwUploadFileErrorAction]: (state, { payload }) =>
      state
        .update('loading', () => initialState.get('loading'))
        .update('error', () => fromJS(payload)),
    [a.getUploadFileCategoriesAction]: state =>
      state
        .update('error', () => initialState.get('error'))
        .update('loading', () => true),
    [a.setUploadFileCategoriesAction]: (state, { payload }) =>
      state
        .update('mainCategories', () => fromJS(payload))
        .update('error', () => initialState.get('error'))
        .update('loading', () => initialState.get('loading')),
    [a.toggleUploadFileSemanticTypeAction]: (state, { payload }) =>
      state
        .update('checkedST', () => fromJS(payload)),
    [a.toggleUploadFileSemanticCategoryAction]: (state, { payload }) =>
      state
        .update('checkedSC', () => fromJS(payload)),
    [a.setUploadFileImportSettingsAction]: (state, { payload }) =>
      state
        .update('import', () => fromJS(payload)),
    [a.setUploadFileTaxonomiesAction]: (state, { payload }) =>
      state
        .update('taxonomies', () => fromJS(payload)),
    [a.resetUploadFileAction]: () => initialState,
  },
  initialState
);

export default reducer;
