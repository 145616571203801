/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import PropTypes from 'prop-types';

// Components
import Error from '../../../common/Error/Error';
import NoData from '../../../common/NoData/NoData';
import Loader from '../../../common/Loader/Loader';
import ShortPubDetails from '../ShortPubDetails/ShortPubDetails';
import PublicationCitesChart from '../PublicationCitesChart/PublicationCitesChart';
// Utils
import { formatChartsData } from '../PublicationCitesChart/utils';
// Store
import * as ACTIONS from '../../reducer';
import * as SELECTORS from '../../selectors';
// Styles
import './styles.scss';

const propTypes = {
  citations: PropTypes.instanceOf(Object),
  pubIdFromState: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  pubId: PropTypes.string,
  getPublicationCitations: PropTypes.func,
  resetPublicationCitations: PropTypes.func,
};

const PublicationCitesChartWrap = (props) => {
  const {
    error,
    pubId,
    loading,
    citations,
    pubIdFromState,
    getPublicationCitations,
    resetPublicationCitations,
  } = props;

  const publicationId = pubId || pubIdFromState;

  const [shortPubDetails, setShortPubDetails] = useState(false);
  const [shortPubDetailsIds, setShortPubDetailsIds] = useState(null);

  useEffect(() => {
    if (publicationId) {
      getPublicationCitations(publicationId);
    }
    return resetPublicationCitations;
  }, [publicationId]);

  const chartsData = useMemo(() => {
    if (citations && Object.keys(citations).length) {
      return formatChartsData(citations);
    }
    return null;
  }, [citations]);

  function openShortPubDetails(pubData) {
    const { ids } = pubData;
    setShortPubDetailsIds(ids);
    setShortPubDetails(true);
  }

  return (
    <div className="cites-charts">
      <div className="cites-charts__wrap pub-details-container">
        <div className="cites-charts__info">
          <div className="pub-details__subtitle">Publication citation</div>
          <div className="cites-charts__description">
            This section shows the number of references in the publication, and the number of citations to this publication.
            The size of the bubble indication is the number of citations of that publication.
            The color depicts the type of publication as explained in the legend.
            The Y-axis represents the source normalized impact per paper.
            (SNIP) This open source indicator measures the average citation impact of the publications of a journal.
            Click on the bubble to see details of that publication.
          </div>
        </div>
        {
          chartsData && !loading &&
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <PublicationCitesChart
                  data={chartsData}
                  contatinerWidth={width}
                  openShortPubDetails={openShortPubDetails}
                /> : null
            )}
          </AutoSizer>
        }
        <NoData
          show={!chartsData && !loading}
          customClassName="cites-charts__no-data"
        />
        {
          shortPubDetails &&
          <ShortPubDetails
            ids={shortPubDetailsIds}
            citations={citations}
          />
        }
        <Error
          show={error && !loading && !chartsData}
          error={error}
          customClassName="text-center error-text"
        />
        <Loader isLoading={!error && loading} />
      </div>
    </div>
  );
};

PublicationCitesChartWrap.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    citations: SELECTORS.getPublicationCiationsDataSelector(state),
    pubIdFromState: SELECTORS.getPublicationIdSelector(state),
    loading: SELECTORS.getPublicationCitationsLoadingSelector(state),
    error: SELECTORS.getPublicationCitationsErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPublicationCitations(id) {
      dispatch(ACTIONS.getPublicationCitationsAction(id));
    },
    resetPublicationCitations() {
      dispatch(ACTIONS.resetPublicationCitationsAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationCitesChartWrap);
