import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Concept from '../../../common/ConceptItem/Concept';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
import ConceptSearchModal from '../../../Concept/ConceptSearchModal/ConceptSearchModal';
// Store
import {
  addAssociationScoreTerms as addAssociationScoreTermsAction,
  removeAssociationScoreTerms as removeAssociationScoreTermsAction,
  saveAssociationScoreTerms,
} from './actions';
import { getAssociationTerms } from './selectors';
import { closeRankingModal, setRankingAction } from '../../common/SetAnalysisMethodSelection/actions';
// Styles
import './AssociationScoreTerms.scss';

const propTypes = {
  terms: PropTypes.instanceOf(Array),
  setRanking: PropTypes.func,
  closeModal: PropTypes.func,
  saveSelected: PropTypes.func,
  addAssociationScoreTerms: PropTypes.func,
  removeAssociationScoreTerms: PropTypes.func,
  updateTerm: PropTypes.func,
  closeModalCallback: PropTypes.func,
  setRankingCallback: PropTypes.func,
};

const AssociationScoreTerms = (props) => {
  const {
    terms,
    setRanking,
    closeModal,
    saveSelected,
    addAssociationScoreTerms,
    removeAssociationScoreTerms,
    updateTerm,
    closeModalCallback,
    setRankingCallback,
  } = props;
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);

  const onSubmit = () => {
    if (updateTerm) {
      updateTerm(terms);
      setRankingCallback('associationScore');
      closeModalCallback();
    } else {
      saveSelected();
      setRanking({ associationScore: true });
      closeModal();
    }
  };

  const renderConcepts = (concept, index) => (
    <Concept
      noInfoButton={true}
      key={index}
      concept={concept}
      deleteCb={removeAssociationScoreTerms}
    />
  );

  return (
    <div className="association-terms-modal">
      <div className="flex-grid">
        <div className="col-1 text-center">
          <h3 className="title3">
            Indirect Association Score Terms
          </h3>
        </div>
      </div>
      <ConceptSearchForm
        onSubmitCallback={() => setShowConceptSearchModal(true)}
      />
      <div className="flex-grid">
        <div className="col-1 text-center row">
          {terms.map(renderConcepts)}
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-center row">
          <button
            disabled={terms.length === 0}
            className="button button-primary mr-15"
            onClick={onSubmit}
          >
            Add
          </button>
          <button
            type="button"
            className="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addAssociationScoreTerms}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </div>
  );
};

AssociationScoreTerms.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    terms: getAssociationTerms(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAssociationScoreTerms(data) {
      dispatch(addAssociationScoreTermsAction(data));
    },
    removeAssociationScoreTerms(data) {
      dispatch(removeAssociationScoreTermsAction(data));
    },
    closeModal() {
      dispatch(closeRankingModal());
    },
    saveSelected() {
      dispatch(saveAssociationScoreTerms());
    },
    setRanking(data) {
      dispatch(setRankingAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationScoreTerms);
