import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import Checkbox from '../../common/Inputs/Checkbox/Checkbox';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
// Store
import * as SELECTORS from './selectors';
import * as ACTIONS from './reducer';
// Utils
import { capitalizeFirstLetter } from '../../Utils/Utils';
// Styles
import './styles.scss';

const propTypes = {
  tableData: PropTypes.instanceOf(Array),
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  checkedSets: PropTypes.instanceOf(Object),
  checkDataSet: PropTypes.func,
  sort: PropTypes.func,
  analysis: PropTypes.bool,
  cacheSelectedConcepts: PropTypes.func,
};

const SetsDataSelection = (props) => {
  const {
    tableData,
    sorting: { sortBy, sortDirection },
    checkedSets,
    sort,
    analysis,
    checkDataSet,
    cacheSelectedConcepts,
  } = props;
  let totalTableLength = 0;

  const getColumnsNames = () => {
    if (!tableData.length) return [];
    const objectNames = Object.keys(tableData[0]);
    const firstColumn = [];
    const lastColumn = [];
    const otherColumns = [];
    objectNames.forEach((name) => {
      if (name === 'concept') {
        firstColumn.push(name);
      } else if (name === 'total') {
        lastColumn.push(name);
      } else {
        otherColumns.push(name);
      }
    });
    return firstColumn.concat(otherColumns).concat(lastColumn);
  };

  const conceptCell = (rowData, rowIndex) => {
    const { id, concept: name } = rowData;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };
  const checkSet = (checked, setName) => {
    checkDataSet({ setName, checked });
    if (analysis) {
      cacheSelectedConcepts();
    }
  };


  const columns = [];
  const columnsNames = getColumnsNames();
  
  columnsNames.forEach((columnName) => {
    if (columnName !== 'id' && columnName !== 'selected' && columnName !== 'type') {
      const columnWidth = columnName === 'concept' ? 215 : columnName === 'total' ? 65 : 100; //eslint-disable-line
      totalTableLength += columnWidth;
      const columnSetting = {
        label: capitalizeFirstLetter(columnName),
        dataKey: columnName,
        width: columnWidth,
        disableSort: columnName !== 'concept' && columnName !== 'total',
      };
      if (columnName !== 'concept' && columnName !== 'total') {
        Object.assign(columnSetting, {
          // eslint-disable-next-line react/prop-types
          cellRenderer: ({ rowData }) => (
            rowData[columnName] ? <i className="fa fa-check green-color" /> : <i className="fa fa-times red-color" />
          ),
          // eslint-disable-next-line react/prop-types
          headerRenderer: ({ dataKey, label }) => (
            <div>
              <Checkbox
                className="vertical-align-middle"
                checked={checkedSets[dataKey]}
                onChange={(e) => { checkSet(e.target.checked, dataKey); }}
              />
              <span title={label} className="headerLabelWithCheckbox">{label}</span>
            </div>
          ),
          className: 'flexColumn',
        });
      }
      if (columnName === 'concept') {
        Object.assign(columnSetting, {
          cellRenderer: ({ rowData, rowIndex }) => conceptCell(rowData, rowIndex),
        });
      }
      if (columnName === 'concept' || columnName === 'total') {
        Object.assign(columnSetting, {
          headerRenderer: ({
            // eslint-disable-next-line react/prop-types
            dataKey, label, sortBy: headerSortBy, sortDirection: headerSortDirection,
          }) => {
            const ascClasses = classNames({
              'fa fa-sort-asc': true,
              'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
            });
            const descClasses = classNames({
              'fa fa-sort-desc': true,
              'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
            });
            return (
              <div className="header-section">
                <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
                  {label}
                </span>
                <span className="sorting-section">
                  <i
                    className={ascClasses}
                    onClick={() => { sort({ sortBy: dataKey, sortDirection: 'ASC' }); }}
                  />
                  <i
                    className={descClasses}
                    onClick={() => { sort({ sortBy: dataKey, sortDirection: 'DESC' }); }}
                  />
                </span>
              </div>
            );
          },
        });
      }
      columns.push(columnSetting);
    }
  });

  let tableSettings = {
    width: totalTableLength + 50,
    height: 700,
    headerHeight: 50,
    rowHeight: 50,
    sortBy,
    sortDirection,
    selectedSort: true,
  };

  if (tableData.length < 13) {
    tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
  }

  const actions = {
    checkAll: ACTIONS.checkAllAction,
    checkItem: ACTIONS.checkItemAction,
  };

  return (
    <div className="set-data-selection">
      {
        tableData.length > 0 &&
        <SimpleTable
          settings={tableSettings}
          data={tableData}
          fixedHeight={true}
          onlySelect={true}
          actions={actions}
          sortAction={ACTIONS.sortVennSetsDataAction}
          columns={columns}
          checkSet={checkSet}
        />
      }
    </div>
  );
};

SetsDataSelection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sets: SELECTORS.getSetsData(state),
    checkedSets: SELECTORS.getCheckedSets(state),
    setsVennDiagramData: SELECTORS.getSetsVennDiagramData(state),
    sorting: SELECTORS.getSorting(state),
    tableData: SELECTORS.dataSelectionTableData(state),
    analyseData: SELECTORS.selectedDataForAnalysis(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sort(data) {
      dispatch(ACTIONS.sortVennSetsDataAction(data));
    },
    checkDataSet(data) {
      dispatch(ACTIONS.checkSetAction(data));
    },
    cacheSelectedConcepts(data) {
      dispatch(ACTIONS.cacheSelectedConcepts(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetsDataSelection);

