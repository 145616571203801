import { createAction } from 'redux-actions';

export const getRankSelectionSetDataAction = createAction('@@rankSelection/GET_SET_DATA');
export const setRankSelectionInputDataAction = createAction('@@rankSelection/SET_INPUT_DATA');
export const setRankSelectionSetDataAction = createAction('@@rankSelection/SET_SET_DATA');
export const throwRankSelectionSetDataAction = createAction('@@rankSelection/SET_DATA_ERROR');
export const sortRankSelectionSetAction = createAction('@@rankSelection/SORT_SET_DATA');
export const pagginateRankSelectionSetAction = createAction('@@rankSelection/PAGGINATE_SET_DATA');
export const selectRankSelectionMethodAction = createAction('@@rankSelection/SELECT_METHOD');
export const setRankTargetMethodsAction = createAction('@@rankSelection/SET_METHODS');
export const setSelectedConceptAction = createAction('@@rankSelection/SET_SELECTED_CONCEPT');
export const resetRankSelectionAction = createAction('@@rankSelection/RESET');
export const closeSetDialogAction =  createAction('@@rankSelection/CLOSE_SET_DIALOG');
export const openSetDialogAction =  createAction('@@rankSelection/OPEN_SET_DIALOG');
export const setSelectedSetInfoAction = createAction('@@rankSelection/SELECTED_SET_INFO');
