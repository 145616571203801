import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import RankSelectionSet from './Components/RankSelectionSet/RankSelectionSet';
import RankSelectionResultTable from './Components/RankSelectionResult/RankSelectionResultTable';
// Store
import * as a from './store/actions';
import * as s from './store/selectors';
import { getCerebrumSemanticCategoriesSelector } from '../Header/selectors';
import { clearHeatmapAction } from '../graphics/Heatmap/store/reducer';
// Constants
import {RELATIVE_PATH, THEME} from '../../constantsCommon';
// Styles
import './styles.scss';
import ModalComponent from '../ModalComponent/ModalComponent';
import RankSelectionParamsModal from './Components/RankSelectionsParams/RankSelectionParamsModal';
import Button from '../common/Buttons/Button/Button';
import {setActiveCategoriesAction} from '../Concept/ConceptSearchModal/store/actions';
import CreateSet from '../Sets/CreateSet/CreateSet';
import {CreateSetModalInitialValues} from './constants';
import {apiTypes} from '../Sets/CreateSet/enums';
import {clearFiltersAction, requestRanking} from './Components/RankSelectionResult/actions';
import SaveAsSetModal from '../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
import {allRankIds} from './Components/RankSelectionResult/selectors';

const propTypes = {
  reset: PropTypes.func,
  getSetData: PropTypes.func,
  method: PropTypes.instanceOf(Object),
  selectedMethod: PropTypes.string,
  selectMethod: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Array),
  isSetDialogOpened: PropTypes.bool,
  closeSetDialog: PropTypes.func,
  setSelectedSetInfo: PropTypes.func,
  selectedSetInfo: PropTypes.object,
  setActiveCategories: PropTypes.func,
  setInputData: PropTypes.func,
  disease: PropTypes.object,
  requestRanking: PropTypes.func,
  clearFilters: PropTypes.func,
  filteredData: PropTypes.instanceOf(Array),
};

const RankSelection = (props) => {
  const {
    getSetData,
    requestRanking,
    isSetDialogOpened,
    closeSetDialog,
    selectedSetInfo,
    setActiveCategories,
    disease,
    setInputData,
    clearFilters,
    filteredData,
  } = props;

  const navigate = useNavigate();

  const { conceptId, setId, projectId} = useParams();

  const [showRankParamsModal, setShowRankParamsModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [createSetModal, setCreateSetModal] = useState(CreateSetModalInitialValues);
  const [addSetPopup, setAddSetPopup] = useState(false);

  useEffect(() => {
    if (!conceptId || !setId) {
      navigate(`${RELATIVE_PATH}/sets`);
    }
    setInputData({
      setId: setId,
      projectId: projectId,
      conceptId: Number(conceptId),
    });
    getSetData({ setId, projectId });
  }, []);

  function toggleRankParamsDialog() {
    setShowRankParamsModal(!showRankParamsModal);
  }

  function closeCreateSetModal() {
    setCreateSetModal(CreateSetModalInitialValues);
  }

  function openCreateSetModal() {
    setCreateSetModal({
      show: true,
      config: {
        conceptIds: [disease.id],
        startConcepts: [{ id: disease.id, name: disease.name}],
        setName: selectedSetInfo.setName,
        apiType: apiTypes.rankTargets,
      },
      initialFilters: {
        SKIP_ZERO_PUBLICATIONS: false,
      },
    });
  }

  function configureRanking() {
    setActiveCategories(['T902', 'T191']);
    setShowRankParamsModal(true);
  }

  function switchFilters() {
    if (showFilters) {
      setShowFilters(false);
      clearFilters();
    } else {
      setShowFilters(true);
    }
  }

  useEffect(() => {
    if (!conceptId || !setId) {
      navigate(`${RELATIVE_PATH}/sets`);
    }
    if (selectedSetInfo && selectedSetInfo.setId
      && disease && disease.id) {
      requestRanking();
    }
  }, [selectedSetInfo, disease]);

  return (
    <div className="rank-selection page">
      <div className="rank-selection__title title2 link" onClick={openCreateSetModal}>
        Rank Targets for {disease.name} ({selectedSetInfo.setName})
      </div>
      <div className="rank-selection__button-bar">
        <Button
          text="Add Ranking Criteria"
          theme={THEME.SEMI}
          onClick={() => configureRanking()}
          customClassName="rank-selection__btn_add_ranking"
        />
        <Button
          text={showFilters ? 'Hide filters' : 'Filters' }
          theme={THEME.SEMI}
          onClick={() => switchFilters()}
          customClassName="rank-selection__btn_filter"
        />
        <Button
          text="Save as set"
          theme={THEME.SEMI}
          onClick={() => setAddSetPopup(true)}
          customClassName="rank-selection__btn_save_as_set"
          disabled={!showFilters}
        />
      </div>
      {
        isSetDialogOpened &&
        <ModalComponent
          isOpen={isSetDialogOpened}
          closeCb={closeSetDialog}
        >
          <RankSelectionSet name={selectedSetInfo.setName} />
        </ModalComponent>
      }
      <RankSelectionResultTable
        showFilters={showFilters}
      />
      <RankSelectionParamsModal
        isOpen={showRankParamsModal}
        closeCb={toggleRankParamsDialog}
        currentConfig={{conceptId, setId, projectId}}
        hideOptions={false}
      />
      {
        createSetModal.show &&
        <ModalComponent
          isOpen={createSetModal.show}
          closeCb={closeCreateSetModal}
          modalClassName="modal_no-paddings"
        >
          <CreateSet
            config={createSetModal.config}
            initialFilters={createSetModal.initialFilters}
            closePopup={closeCreateSetModal}
          />
        </ModalComponent>
      }
      <SaveAsSetModal
        isOpen={addSetPopup}
        closeCb={() => { setAddSetPopup(false); }}
        idsForShortConceptDetails={filteredData}
        showAddConcept={false}
        cancelAction={() => { setAddSetPopup(false); }}
      />
    </div>
  );
};

RankSelection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    method: s.getRankSelctionMethodSelector(state),
    selectedMethod: s.getRankSelctionMethodSelectedSelector(state),
    semanticCategories: getCerebrumSemanticCategoriesSelector(state),
    selectedSetInfo: s.getSelectedSetInfoSelector(state),
    disease: s.getSelectedConceptSelector(state),
    filteredData: allRankIds(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSetData(data) {
      dispatch(a.getRankSelectionSetDataAction(data));
    },
    selectMethod(data) {
      dispatch(a.selectRankSelectionMethodAction(data));
    },
    clearHeatmap() {
      dispatch(clearHeatmapAction());
    },
    closeSetDialog() {
      dispatch(a.closeSetDialogAction());
    },
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
    setInputData(data) {
      dispatch(a.setRankSelectionInputDataAction(data));
    },
    requestRanking() {
      dispatch(requestRanking());
    },
    clearFilters(data) {
      dispatch(clearFiltersAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankSelection);
