import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getConceptCardsDataAction = createAction('@@conceptCard/GET_DATA');
export const setConceptCardsDataAction = createAction('@@conceptCard/SET_DATA');
export const conceptCardsLoadingAction = createAction('@@conceptCard/LOADING');
export const conceptCardsErrorAction = createAction('@@conceptCard/ERROR');
export const clearConceptCardsDataAction = createAction('@@conceptCard/CLEAR_CONCEPT_CARDS');

const initialState = fromJS({
  conceptCardsData: [],
  loading: false,
  error: '',
});

const reducer = handleActions(
  {
    [setConceptCardsDataAction]: (state, { payload }) =>
      state
        .update('conceptCardsData', () => fromJS(payload))
        .update('error', () => ''),
    [conceptCardsLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [conceptCardsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [clearConceptCardsDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
