import { createSelector } from 'reselect';

const publications = state => state.getIn(['publicationsManagementReducer', 'data', 'content']);
const totalPages = state => state.getIn(['publicationsManagementReducer', 'data', 'totalPages']);
const pageNumber = state => state.getIn(['publicationsManagementReducer', 'data', 'number']);
const loading = state => state.getIn(['publicationsManagementReducer', 'loading']);
const error = state => state.getIn(['publicationsManagementReducer', 'error']);
const activeProjectId = state => state.getIn(['publicationsManagementReducer', 'activeProjectId']);
const selectedPublicationsIds = state => state.getIn(['publicationsManagementReducer', 'selectedPublicationsIds']);
const selectedPubmedIds = state => state.getIn(['publicationsManagementReducer', 'selectedPubmedIds']);
const projects = state => state.getIn(['projectsTabsReducer', 'projects', 'data']);

export const getPublicationsManagementDataSelector = createSelector(
  publications,
  selectedPublicationsIds,
  (_data, _selectedIds) => {
    const data = _data && _data.toJS();
    const selectedIds = _selectedIds && _selectedIds.toJS();
    const newData = data.map((item) => {
      if (selectedIds.includes(item.id)) {
        return {
          ...item,
          checked: true,
        };
      }
      return item;
    });
    return newData;
  }
);

export const getSelectedPublicationsIdsSelector = createSelector(
  selectedPublicationsIds,
  data => data && data.toJS()
);

export const getSelectedPubmedIdsSelector = createSelector(
  selectedPubmedIds,
  data => data && data.toJS()
);

export const getPublicationsManagementTotalPagesSelector = createSelector(
  totalPages,
  data => data
);

export const getPublicationsManagementpageNumberSelector = createSelector(
  pageNumber,
  data => data
);

export const getPublicationsManagementLoadingSelector = createSelector(
  loading,
  data => data
);

export const getPublicationsManagementErrorSelector = createSelector(
  error,
  data => data
);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);

export const getActiveProjectDataSelector = createSelector(
  projects,
  activeProjectId,
  (_data, _activeProjectId) => {
    const data = _data && _data.toJS();
    return data.find(item => item.id === _activeProjectId);
  }
);
export const getIsProjectLockedSelector = createSelector(
  [getActiveProjectDataSelector],
  data => data && data.status === 'LOCKED'
);

export const getIsProjectLockedForUsersSelector = createSelector(
  [getActiveProjectDataSelector],
  data => data && data.status === 'LOCKED_FOR_USERS'
);

export const getIsProjectPublicSelector = createSelector(
  [getActiveProjectDataSelector],
  data => data && data.public
);
