import { call, put, takeEvery, select } from 'redux-saga/effects';
// Api
import Api from '../../../../Api/Api';
// Store
import * as a from './reducer';
import * as s from './selectors';
import { userLoginRequestAction } from '../../reducer';
import { changePasswordModalClose } from '../../ChangePassword/actions';
// Constants
import { AuthenticatorCodeTypesForModal, TwoFactorAuthenticationEnums } from '../constants';
import { updateTwoFactorAuthenticationPrevSelectedOptionAction } from './reducer';


function* getTwoFactorAuthenticationDataWorker() {
  try {
    const { data } = yield call(Api.getSecretCode);
    yield put(a.setTwoFactorAuthenticationDataAction(data));
  } catch (e) {
    yield put(a.throwTwoFactorAuthenticationDataErrorAction(e.message));
  }
}

function* checkTwoFactorAuthenticationDataWorker( { payload }) {
  try {
    yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(true));
    const { authenticatorCode, type } = payload;
    const twoFAOption = yield select(s.getTwoFactorAuthenticationSelectedOption);
    const prevTwoFAOption = yield select(s.getTwoFactorAuthenticationPrevSelectedOptionSelector);
    if (type === AuthenticatorCodeTypesForModal.LOGIN) {
      yield put(userLoginRequestAction({ code: authenticatorCode }));
      yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(false));
    } else {
      if (
        twoFAOption.value === TwoFactorAuthenticationEnums.DISABLED ||
        prevTwoFAOption.value === TwoFactorAuthenticationEnums.EVERY_LOGIN ||
        prevTwoFAOption.value === TwoFactorAuthenticationEnums.ONCE_DAY
      ) {
        yield call(Api.checkSecretCode, { code2FA: authenticatorCode });
        yield put(a.toggleAuthenticatorCodeModalIsOpenAction(false));
        yield put(changePasswordModalClose());
      }
      yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(false));
      yield put(a.updateTwoFactorAuthenticationDataAction(authenticatorCode));
    }
  } catch (e) {
    const error = e.response?.data?.message || e.message;
    yield put(a.throwAuthenticatorCodeErrorAction(error));
    yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(false));
  }
}

function* updateTwoFactorAuthenticationDataWorker({ payload }) {
  try {
    yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(true));
    const code2FA = yield select(s.getTwoFactorAuthenticationCodeSelector);
    const twoFAOption = yield select(s.getTwoFactorAuthenticationSelectedOption);
    yield call(Api.update2FAAuth, { secret: code2FA, twoFA: twoFAOption.value, code: payload } );
    yield put(a.toggleAuthenticatorCodeModalIsOpenAction(false));
    yield put(changePasswordModalClose());
    yield put(updateTwoFactorAuthenticationPrevSelectedOptionAction(twoFAOption));
    yield put(a.toggleAuthenticatorCodeModalIsLoadingAction(false));
  } catch (e) {
    const error = e.response?.data?.message || e.message;
    yield put(a.throwAuthenticatorCodeErrorAction(error));
  }
}
function* TwoFactorAuthentication() {
  yield takeEvery(a.getTwoFactorAuthenticationDataAction, getTwoFactorAuthenticationDataWorker);
  yield takeEvery(a.checkTwoFactorAuthenticationDataAction, checkTwoFactorAuthenticationDataWorker);
  yield takeEvery(a.updateTwoFactorAuthenticationDataAction, updateTwoFactorAuthenticationDataWorker);
}

export default TwoFactorAuthentication;
