export const SIMPLE_SET_FROM_COMPLEX_SET_FORM = 'SIMPLE_SET_FROM_COMPLEX_SET_FORM';
export const EFFECT_SET_FROM_COMPLEX_SET_FORM = 'EFFECT_SET_FROM_COMPLEX_SET_FORM';

export const contentTypes = [
  {
    label: 'Gene identifier',
    value: 'GENE',
  },
  {
    label: 'Protein identifier',
    value: 'PROTEIN',
  },
  {
    label: 'Compound identifier',
    value: 'COMPOUND',
  },
  {
    label: 'Other text',
    value: 'OTHER_TEXT',
  },
  {
    label: 'Ignore: When Selected, do not import this column',
    value: 'IGNORE',
  },
  {
    label: 'RNA Expression: FPKM',
    value: 'FPKM',
  },
  {
    label: 'RNA Expression: TPM',
    value: 'TPM',
  },
  {
    label: 'RNA Expression: RPKM',
    value: 'RPKM',
  },
  {
    label: 'Differential Expression: Log2 Fold change',
    value: 'LOG2_FOLD_CHANGE',
  },
  {
    label: 'siRNA: Inhibition',
    value: 'INHIBITION',
  },
  {
    label: 'Pharmacology: IC50',
    value: 'IC50',
  },
  {
    label: 'Pharmacology: EC50',
    value: 'EC50',
  },
  {
    label: 'Statistics: Z-score',
    value: 'Z_SCORE',
  },
  {
    label: 'Statistics: p-value',
    value: 'P_VALUE',
  },
  {
    label: 'Other number',
    value: 'OTHER_NUMBER',
  },
];

export const filterCriteria = {
  NUMBER: {
    '=': 'EQUALS',
    '>': 'MORE_THAN',
    '>=': 'MORE_THAN_OR_EQUAL',
    '<': 'LESS_THAN',
    '<=': 'LESS_THAN_OR_EQUAL',
  },
  TEXT: {
    'contains': 'CONTAINS',
  },
};

export const aggregationMethods = {
  Min: 'MIN',
  Max: 'MAX',
  Median: 'MEDIAN',
  Mean: 'MEAN',
};
