import React from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from '../../../common/Loader/Loader';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import SequencesTable from './SequencesTable';
import ChemicalsList from './ChemicalsList';

const propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  patentInfo: PropTypes.instanceOf(Object),
  togglePatentInfoPopup: PropTypes.func,
  type: PropTypes.string,
};

class PatentPopup extends React.Component {
  render() {
    const {
      loading,
      error,
      patentInfo: {
        patent,
      },
      type,
      togglePatentInfoPopup,
    } = this.props;

    return (
      <ModalComponent
        isOpen={true}
        closeCb={togglePatentInfoPopup}
      >
        {
          patent && !loading &&
          <div className="patent-popup">
            <span className="patent-popup__title">{patent.title}</span>
            <span className="patent-popup__id">ID: {patent.patentId}</span>
            <a className="patent-popup__link link" href={patent.patentLink} target="blank">
              {patent.patentLink}
            </a>
            <p className="patent-popup__text">{patent.abstractText}</p>
            {
              type === 'CHEMICALS' && <ChemicalsList id={patent.patentId} parentClass="patent-popup" />
            }
            {
              type === 'SEQUENCES' && <SequencesTable id={patent.patentId} parentClass="patent-popup" />
            }
          </div>
        }
        {
          loading &&
          <div className="patent-popup">
            <Loader isLoading={loading} />
          </div>
        }
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </ModalComponent>
    );
  }
}

PatentPopup.propTypes = propTypes;

export default PatentPopup;
