export const types = {
  // Chapter
  GET_THERAPEUTIC_CANDIDATES_DATA: 'GET_THERAPEUTIC_CANDIDATES_DATA',
  PUT_THERAPEUTIC_CANDIDATES_DATA: 'PUT_THERAPEUTIC_CANDIDATES_DATA',
  THERAPEUTIC_CANDIDATES_DATA_FAILED: 'THERAPEUTIC_CANDIDATES_DATA_FAILED',
  THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_SORT: 'THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_SORT',
  THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_SORT: 'THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_SORT',
  THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_CHANGE_PAGE: 'THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_CHANGE_PAGE',
  THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_CHANGE_PAGE: 'THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_CHANGE_PAGE',
};
