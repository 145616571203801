import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import defaultHeaderRenderer from 'react-virtualized/dist/es/Table/defaultHeaderRenderer';

// Components
import Error from '../Error/Error';
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../PublicationsList/PublicationsList';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
// Store
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import {
  getGenesDetail,
  getSorting,
  getLoading,
  getError,
  getConcept,
  getCurrentPage,
  getTotalPages,
} from './selectors';
import {
  initGenesDetail,
  sort,
  closeGenesDetail as closeGenesDetailAction,
  saveNewSetFromAnalyticsAction,
} from './actions';
// Styles
import './SetanalysisGenesDetail.css';

const propTypes = {
  init: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  genesDetail: PropTypes.instanceOf(Array),
  concept: PropTypes.instanceOf(Object),
  closeGenesDetail: PropTypes.func,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  saveAsNewSet: PropTypes.func,
};

class SetAnalysisGenesDetail extends React.Component {
  state = {
    showPublicationsPopup: false,
  };
  conceptsNames = [];
  conceptsIds = [];
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.closeGenesDetail();
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handleClickSaveAsNewSet = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const geneGis = this.props.genesDetail.map(item => item.geneGi);
    this.props.saveAsNewSet({
      concepts: geneGis,
      postAction: 'saveAsSet',
    });
  };

  // Max are you sure that it should be like => =>
  createHeaderRendererWithSaveAs = () => (params) => {
    const children = [defaultHeaderRenderer(params)];
    children.push(
      <i
        role="presentation"
        key="saveAsSetActionBtn"
        style={{
          marginLeft: 20,
          verticalAlign: 4,
        }}
        onClick={event => this.handleClickSaveAsNewSet(event)}
        className="fa fa-floppy-o"
      />
    );
    return children;
  };

  getRowHeight = ({ index }) => {
    let itemsCount;
    try {
      if (this.props.genesDetail[index].classifications) {
        itemsCount = this.props.genesDetail[index].classifications.length;
      }
    } catch (e) {
      console.log(e);
    }
    if (itemsCount > 2) {
      return itemsCount * 20 + 10; //eslint-disable-line
    }
    return 50;
  };

  openPublicationsPopup = (data) => {
    const { concept } = this.props;
    const { id, name } = data;
    this.conceptsNames = [concept.name, name];
    this.conceptsIds = [concept.id, id];
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  handleGenesDetailPageClick = (page) => {
    this.props.init({
      page: page.selected,
    });
  };

  conceptCell = (id, name, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      genesDetail,
      sorting: { sortBy, sortDirection },
      loading,
      error,
      totalPages,
      currentPage,
    } = this.props;
    const { showPublicationsPopup } = this.state;

    let tableSettings = {
      width: 1100,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    if (genesDetail.length < 8) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    const columns = [{
      label: 'Gene',
      dataKey: 'geneName',
      width: 400,
      headerRenderer: this.createHeaderRendererWithSaveAs(),
      cellRenderer: ({ rowData, rowIndex }) => (
        rowData.geneGi ? this.conceptCell(rowData.geneGi, rowData.geneName, rowIndex) : null
      ),
    },
    {
      label: 'Classification',
      className: 'flexColumn',
      dataKey: 'classifications',
      width: 400,
      cellRenderer: ({ rowData }) => {
        const items = rowData.classifications || [];
        return items.map((item, index) => (
          <div
            title={item}
            className="text-center cell-row"
            key={index}
          >
            { item }
          </div>
        ));
      },
    },
    {
      label: 'Publications',
      dataKey: 'publicationCoOccurrence',
      width: 300,
      cellRenderer: ({ rowData }) => {
        const isLink = classNames({
          link: rowData.publicationCoOccurrence > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.publicationCoOccurrence > 0) {
                this.openPublicationsPopup({
                  id: rowData.geneGi,
                  name: rowData.geneName,
                });
              }
            }}
            className={isLink}
          >
            {rowData.publicationCoOccurrence}
          </span>
        );
      },
    }];

    return (
      <div className="genes-detail">
        <div className="row">
          <div className="flex-grid">
            <div className="col-1 text-left genes-detail-title">
              Details
            </div>
          </div>
          <div className="flex-grid justify-content-center">
            {
              !loading && !error &&
              <div className="row">
                <div className="col-1">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={currentPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handleGenesDetailPageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  <SimpleTable
                    innerRef={this.simpleTableRef}
                    settings={tableSettings}
                    manageable={false}
                    data={genesDetail}
                    sortAction={sort}
                    columns={columns}
                    dynamicHeight={true}
                  />
                </div>
              </div>
            }
            <Loader isLoading={loading && !error} />
            <Error show={!!error} error={error} />
          </div>
        </div>
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

SetAnalysisGenesDetail.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concept: getConcept(state),
    genesDetail: getGenesDetail(state),
    sorting: getSorting(state),
    loading: getLoading(state),
    error: getError(state),
    totalPages: getTotalPages(state),
    currentPage: getCurrentPage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init(data) {
      dispatch(initGenesDetail(data));
    },
    saveAsNewSet(data) {
      dispatch(saveNewSetFromAnalyticsAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    closeGenesDetail() {
      dispatch(closeGenesDetailAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetAnalysisGenesDetail);
