import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as d3js from 'd3';

// Utils
import {
  HEATMAP_COLOR,
  HeatmapSortByEnum,
  HeatmapTypeEnum,
  heatmapCooccurrenceTypes,
} from '../../constants';
import {
  mousePosition,
  getMousePosition,
  getMaxLength,
  getSelectedCells,
  calculateStep,
} from './utils';
import { exportToPNG, exportToSVG, kFormatter } from '../../../../Utils/Utils';
// Components
import HeatmapColorScale from '../HeatmapColorScale/HeatmapColorScale';
import Loader from '../../../../common/Loader/Loader';
import ShortConceptCard from '../../../../Concept/ShortConceptCard/ShortConceptCard';
import Tooltip from '../../../../common/Tooltip/Tooltip';

const propTypes = {
  sort: PropTypes.func,
  type: PropTypes.string,
  chartData: PropTypes.instanceOf(Object),
  setSelectedCells: PropTypes.func,
  openPublicationsPopup: PropTypes.func,
  toggleHeatmapSpinner: PropTypes.func,
  spinner: PropTypes.bool,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  scoreType: PropTypes.string,
  titleForDownloading: PropTypes.string,
  networkAnalysisTitle: PropTypes.string,
  isSetAnalysis: PropTypes.bool,
};

const HeatmapChart = (props) => {
  const {
    sort,
    type,
    spinner,
    chartData,
    setSelectedCells,
    openPublicationsPopup,
    toggleHeatmapSpinner,
    showTooltip,
    hideTooltip,
    setShortConceptCardId,
    scoreType,
    titleForDownloading,
    networkAnalysisTitle,
    isSetAnalysis,
  } = props;

  const heatmapRef = useRef(null);
  const typeStringTitle = useMemo(() => type === HeatmapTypeEnum.PUBS ? 'References' : 'Score', [type]);

  const [range, setRange] = useState(null);
  const [isColorsScaleDelaying, setIsColorsScaleDelaying] = useState(false);

  useEffect(() => {
    const ref = heatmapRef.current;
    if (ref) {
      ref.addEventListener('mousemove', getMousePosition);
      return () => {
        ref.removeEventListener('mousemove', getMousePosition);
      };
    }
  }, []);

  const sortByColumn = useCallback((d) => {
    sort(d, HeatmapSortByEnum.COLUMN);
  }, [sort]);

  const sortByRow = useCallback((d) => {
    sort(d, HeatmapSortByEnum.ROW);
  }, [sort]);

  const getColors = useCallback((d, maxValue, minValue) => {
    if ((d === 0 && type === HeatmapTypeEnum.PUBS) ||
      (!d && type === HeatmapTypeEnum.SCORE)) {
      return 'url(#white-dashed-pattern)';
    }

    const mainColors = d3js.scaleLinear()
      .range(['white', HEATMAP_COLOR])
      .domain([minValue ? minValue : 0, maxValue]);

    if (range === null) {
      return mainColors(d);
    }

    const rangeColors = d3js.scaleLinear()
      .range(['white', HEATMAP_COLOR])
      .domain([range.min, range.max]);

    if (d < range.min) {
      return 'white';
    } else if (d > range.max) {
      return HEATMAP_COLOR;
    }

    return rangeColors(d);
  }, [range]);

  const drawLegend = useCallback((chartDataParam) => {
    d3js.selectAll('#heatmap-legend > *').remove();
    const { maxValue, minValue, rows, columns } = chartDataParam;
    const legendCell = {
      width: 60,
      height: 15,
    };

    if (rows.length === 0 || columns.length === 0) {
      return;
    }

    let domainFrom = minValue || 0;
    let domainTo = maxValue;

    if (range) {
      domainFrom = range.min;
      domainTo = range.max;
    }

    const mainColors = d3js.scaleLinear()
      .range(['white', HEATMAP_COLOR])
      .domain([domainFrom, domainTo]);

    const getLegendSteps = () => {
      if (domainTo === 0) return [0];
      const steps = {
        0: {
          number: 0,
          text: 0,
        },
      };
      if (domainTo > 0 && domainTo < 1) {
        const stepsLength = 4;
        const step = (domainTo - domainFrom) / stepsLength;

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < stepsLength + 1; i++) {
          const number = domainFrom !== 0 && i === 1 ?
            domainFrom : Math.round(((i * step) + domainFrom) * 10000) / 10000;
          const text = kFormatter(number);

          if (!steps[text]) {
            steps[text] = { number, text };
          }
        }
      } else {
        const stepsLength = Math.min(domainTo - domainFrom, 8);
        const step = (domainTo - domainFrom) / stepsLength;
        const roundCof = domainTo >= 400 ? 50 : 1;

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < stepsLength + 1; i++) {
          const number = domainFrom !== 0 && i === 1 ?
            Math.round(domainFrom / roundCof) * roundCof :
            Math.round(((i * step) + domainFrom) / roundCof) * roundCof;
          const text = kFormatter(number);

          if (!steps[text]) {
            steps[text] = { number, text };
          }
        }
      }
      return steps;
    };

    const steps = Object.values(getLegendSteps());

    const legend = d3js.select('#heatmap-legend')
      .append('svg')
      .attr('width', (steps.length * legendCell.width) + 10)
      .attr('height', legendCell.height + 135)
      .append('g')
      .selectAll('.legend')
      .data(steps)
      .enter()
      .append('g')
      .attr('transform', 'translate(5, 50)')
      .attr('class', 'legend');
    legend.append('rect')
      .attr('x', (d, i) => legendCell.width * i)
      .attr('y', 0)
      .attr('width', legendCell.width)
      .attr('height', legendCell.height)
      .style('fill', (d) => {
        if (d.number === 0 || d === 0) {
          return 'url(#white-dashed-pattern)';
        }
        return mainColors(d.number);
      });
    legend.append('text')
      .attr('class', 'legend-text')
      .text((d) => {
        if (d === 0) return d;
        return d.text;
      })
      .attr('width', legendCell.width)
      .style('font-size', '15px')
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => (legendCell.width * i) + (legendCell.width / 2))
      .attr('y', legendCell.height + 20);

    d3js.select('#heatmap-legend')
      .select('svg')
      .insert('text', ':first-child')
      .text(typeStringTitle)
      .attr('style', 'font: 12px sans-serif; transform: translate(50%, 30px); font-weight: bold;')
      .attr('x', () => (`-${d3js.select('#heatmap-legend')
        .select('text')
        .node()
        .getBoundingClientRect().width / 2}px`
      ));
  }, [range, typeStringTitle]);

  const drawHeatmap = useCallback((chartDataParam, changeForDownload) => {
    d3js.selectAll('#heatmap > *').remove();
    d3js.selectAll('.chart-tooltip').remove();

    const { data, rows, columns, maxValue, minValue } = chartDataParam;

    if (rows.length === 0 || columns.length === 0) return;

    const cellWidth = 30;
    const cellHeight = rows.length > 8 ? 30 : 80;
    const tooltipWidth = 200;
    const width = columns.length * cellWidth;
    const height = rows.length ? rows.length * cellHeight : cellHeight;
    const margin = {
      top: 0,
      right: isSetAnalysis ? 250 : 100,
      bottom: getMaxLength(chartData.columns, changeForDownload),
      left: getMaxLength(chartData.rows, changeForDownload),
    };

    if (changeForDownload) margin.top = 70;

    const svgWidth = width + margin.left + margin.right;
    const svgHeight = height + margin.top + margin.bottom;

    const svg = d3js.select('#heatmap')
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    svg.append('defs').append('svg:pattern')
      .attr('id', 'white-dashed-pattern')
      .attr('width', 8)
      .attr('height', 8)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45)')
      .append('rect')
      .attr('width', 1)
      .attr('height', 8)
      .attr('transform', 'translate(0,0)')
      .style('fill', '#ddd');

    const tooltipTemplate = d => (`
      <div class="chart-tooltip__content">
        <div><b>Row concept:</b> ${d.row.name}</div>
        <div><b>Column concept:</b> ${d.col.name}</div>
        <div><b>${typeStringTitle}:</b> ${type === HeatmapTypeEnum.PUBS ? d[type] : Number.parseFloat(d[type]).toExponential(2)}</div>
      </div>
    `);

    const tooltip = d3js.select('#heatmap-wrapper')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'chart-tooltip');

    function mouseOver() {
      tooltip
        .style('opacity', 1);
      d3js.select(this)
        .attr('stroke', 'dodgerblue');
    }

    function mouseLeave() {
      tooltip
        .style('opacity', 0);
      d3js.select(this)
        .attr('stroke', 'none');
    }

    function mouseDown() {
      const position = d3js.mouse(this);
      svg.append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('class', 'selection')
        .attr('initX', position[0])
        .attr('initY', position[1])
        .attr('x', position[0])
        .attr('y', position[1])
        .attr('width', 1)
        .attr('height', 1);
    }

    function mouseMove(d) {
      const left = mousePosition.x;
      const top = mousePosition.y;
      let transformX = 0;
      let transformY = 0;

      if (left > svgWidth / 2) {
        transformX = -((tooltipWidth / 2) + 10);
      }
      if (top > height / 2) {
        transformY = -50;
      } else {
        transformY = 50;
      }

      tooltip
        .html(tooltipTemplate(d))
        .style('left', `${left}px`)
        .style('top', `${top}px`)
        .style('width', `${tooltipWidth}px`)
        .style('min-width', 'auto')
        .style('text-align', 'left')
        .style('transform', `translate(${transformX}%, ${transformY}%)`);

      const selection = svg.select('.selection');

      if (!selection.empty()) {
        const position = d3js.mouse(this);
        const initX = selection.attr('initX');
        const initY = selection.attr('initY');
        const attrs = {
          x: parseInt(selection.attr('x'), 10),
          y: parseInt(selection.attr('y'), 10),
          width: parseInt(selection.attr('width'), 10),
          height: parseInt(selection.attr('height'), 10),
        };

        if (position[0] > initX && position[1] > initY) {
          attrs.x = +initX;
          attrs.y = +initY;
          attrs.width = position[0] - initX;
          attrs.height = position[1] - initY;
        }

        if (position[0] < initX && position[1] > initY) {
          attrs.x = +position[0];
          attrs.y = +initY;
          attrs.width = initX - position[0];
          attrs.height = position[1] - initY;
        }

        if (position[0] > initX && position[1] < initY) {
          attrs.x = +initX;
          attrs.y = +position[1];
          attrs.width = position[0] - initX;
          attrs.height = initY - position[1];
        }

        if (position[0] < initX && position[1] < initY) {
          attrs.x = +position[0];
          attrs.y = +position[1];
          attrs.width = initX - position[0];
          attrs.height = initY - position[1];
        }

        selection
          .attr('x', attrs.x)
          .attr('y', attrs.y)
          .attr('width', attrs.width)
          .attr('height', attrs.height);

        d3js.selectAll('.cell-selected').classed('cell-selected', false);
        d3js.selectAll('.hm-row').classed('hm-row_active', false);
        d3js.selectAll('.hm-col').classed('hm-col_active', false);

        d3js.selectAll('.cell').each(function(item) {
          if (
            !d3js.select(this).classed('cell-selected') &&
            this.x.baseVal.value + cellWidth >= attrs.x && this.x.baseVal.value <= attrs.x + attrs.width &&
            this.y.baseVal.value + cellHeight >= attrs.y && this.y.baseVal.value <= attrs.y + attrs.height
          ) {
            d3js.select(this).classed('cell-selected', true);
            d3js.select(`.hm-row__${item.row.id}`).classed('hm-row_active', true);
            d3js.select(`.hm-col__${item.col.id}`).classed('hm-col_active', true);
          }
        });
      }
    }

    function mouseUp() {
      svg.selectAll('.selection').remove();
      setSelectedCells(getSelectedCells());
    }

    function mouseOut() {
      if (d3js.event.relatedTarget && d3js.event.relatedTarget.tagName === 'html') {
        svg.selectAll('.selection').remove();
      }
    }

    function trimConceptText(d, trimForDownload) {
      const getConceptLength = () => {
        if (trimForDownload) {
          return 90;
        }
        return isSetAnalysis ? 35 : 25;
      };

      const conceptLength = getConceptLength();
      if (d && d.length < conceptLength) return d;
      return `${d ? d.slice(0, conceptLength) : ''}...`;
    }

    function showTooltipHandler(d) {
      const { id, firstNodeId, lastNodeId } = d;
      const config = {
        uniqueKey: 'heatmap-tooltip',
        clientX: d3js.event.x,
        clientY: d3js.event.y,
      };
      if (firstNodeId && lastNodeId) {
        setShortConceptCardId([firstNodeId, lastNodeId]);
      } else {
        setShortConceptCardId(id);
      }
      showTooltip(config);
    }

    const x = d3js.scaleBand()
      .range([0, width])
      .domain(columns.map(c => c.name))
      .padding(0.03);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .attr('class', 'x-axis')
      .call(d3js.axisBottom(x));

    xAxis.selectAll('text')
      .attr('x', 15)
      .attr('y', 0)
      .style('text-anchor', 'start')
      .style('cursor', 'pointer')
      .attr('transform', 'rotate(45)')
      .attr('class', (d, i) => `hm-col hm-col__${columns[i].id}`)
      .html('')
      .append('tspan')
      .text(d => trimConceptText(d, changeForDownload))
      .on('mouseover', (d, i) => showTooltipHandler(columns[i]))
      .on('mouseout', hideTooltip)
      .on('click', sortByColumn);

    const y = d3js.scaleBand()
      .range([height, 0])
      .domain(rows.map(r => r.name))
      .padding(0.03);

    const yAxis = svg.append('g')
      .call(d3js.axisLeft(y))
      .attr('class', 'y-axis');

    yAxis.selectAll('text')
      .attr('class', (d, i) => `hm-row hm-row__${rows[i].id}`)
      .html('')
      .append('tspan')
      .text(d => trimConceptText(d, changeForDownload))
      .on('mouseover', (d, i) => showTooltipHandler(rows[i]))
      .on('mouseout', hideTooltip)
      .on('click', sortByRow);

    svg.selectAll()
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'cell')
      .attr('x', d => x(d.col.name))
      .attr('y', d => y(d.row.name))
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('cursor', 'pointer')
      .style('fill', d => getColors(d[type], maxValue, minValue))
      .style('stroke', '#f1f1f1')
      .style('stroke-width', '1px')
      .on('click', openPublicationsPopup)
      .on('mouseover', mouseOver)
      .on('mouseleave', mouseLeave)
      .on('mousedown', mouseDown)
      .on('mousemove', mouseMove)
      .on('mouseup', mouseUp)
      .on('mouseout', mouseOut);

    if (changeForDownload) {
      svg
        .attr('transform', `translate(${margin.left + 200},${margin.top + 50})`);

      svg.selectAll('text')
        .style('font-size', '24px');

      const downloadedSvgWidth = svgWidth + 600;
      const downloadedSvgHeight = svgHeight + 300;

      const downloadedSvg = d3js.select('#heatmap')
        .select('svg')
        .attr('width', downloadedSvgWidth)
        .attr('height', downloadedSvgHeight)
        .insert('text', ':first-child')
        .attr('style', 'font: 14px sans-serif; transform: translate(30px, 0px)');

      if (titleForDownloading) {
        downloadedSvg.selectAll('tspan.text')
          .data(titleForDownloading.split('\n'))
          .enter()
          .append('tspan')
          .attr('class', 'text')
          .style('font-size', '28px')
          .style('font-weight', 'bold')
          .text(d => d)
          .attr('x', 20)
          .attr('dx', 10)
          .attr('dy', 28);
      }

      d3js.select('#heatmap svg')
        .append('g')
        .attr('id', 'heatmap-legend')
        .attr('transform', `translate(${margin.left} ,${svgHeight + 130})`);

      drawLegend(chartDataParam, changeForDownload);
    }
  }, [chartData, drawLegend, getColors, hideTooltip, isSetAnalysis, openPublicationsPopup, setSelectedCells, setShortConceptCardId, showTooltip, sortByColumn, sortByRow, titleForDownloading, type, typeStringTitle]);

  const drawChart = useCallback((data) => {
    drawHeatmap(data);
    drawLegend(data);
    setIsColorsScaleDelaying(false);
    if (spinner) toggleHeatmapSpinner(false);
  }, [drawHeatmap, drawLegend, spinner, toggleHeatmapSpinner]);

  useEffect(() => {
    if (chartData) {
      setTimeout(() => {
        drawChart(chartData);
      }, 0);
    }
  }, [chartData, drawChart, range]);

  useEffect(() => {
    if (chartData) {
      setRange({ min: chartData.minValue || 0, max: chartData.maxValue });
    }
  }, [chartData]);

  useEffect(() => {
    setIsColorsScaleDelaying(true);
  }, [scoreType]);

  const exportToPNGCb = useCallback(() => {
    drawHeatmap(chartData, true);
    const diagram = window.document.querySelector('#heatmap svg');
    const title = networkAnalysisTitle ?
      `heatmap-diagram-export(${networkAnalysisTitle})` :
      'heatmap-diagram-export';
    const name = `${title}(by ${heatmapCooccurrenceTypes[scoreType]}).png`;
    if (diagram) {
      exportToPNG(diagram, name);
    }
    drawChart(chartData);
  }, [chartData, drawChart, drawHeatmap, networkAnalysisTitle, scoreType]);

  const exportToSVGCb = useCallback(() => {
    drawHeatmap(chartData, true);
    const container = window.document.querySelector('#heatmap');
    const title = networkAnalysisTitle ?
      `heatmap-diagram-export(${networkAnalysisTitle})` :
      'heatmap-diagram-export';
    const name = `${title}(by ${heatmapCooccurrenceTypes[scoreType]}).svg`;
    if (container) {
      exportToSVG(container, name);
    }
    drawChart(chartData);
  }, [chartData, drawChart, drawHeatmap, networkAnalysisTitle, scoreType]);

  return (
    <div className="heatmap-chart__wrapper">
      <div className="heatmap-chart__bar-chart-controls bar-chart-controls">
        <div className="bar-chart-export-button-wrapper">
          <div
            className="export-button bar-chart-controls__png"
            onClick={exportToPNGCb}
            title="Export to png"
          />
          PNG
        </div>
        <div className="bar-chart-export-button-wrapper">
          <div
            className="export-button"
            onClick={exportToSVGCb}
            title="Export to svg"
          />
          SVG
        </div>
      </div>
      <div
        id="heatmap-wrapper"
        className="heatmap-chart__content-wrapper"
      >
        <div
          id="heatmap"
          ref={heatmapRef}
          className="heatmap-chart__content"
        />
      </div>
      <div className="heatmap-chart__legend">
        <div id="heatmap-legend" />
      </div>
      {!!chartData && !!chartData.maxValue && !isColorsScaleDelaying && (
        <div className="heatmap-range">
          <HeatmapColorScale
            defaultValue={[chartData.minValue || 0, chartData.maxValue]}
            onAfterChange={(data) => {
              toggleHeatmapSpinner(true);
              setRange({ min: data[0], max: data[1] });
            }}
            colors={['white', '#4caf50']}
            dotColor="#4caf50"
            step={calculateStep(chartData.maxValue)}
          />
        </div>
      )}
      <Tooltip uniqueKeyProp="heatmap-tooltip">
        <ShortConceptCard />
      </Tooltip>
      {
        spinner &&
        <div className="heatmap-chart__spinner">
          <Loader isLoading={spinner} />
        </div>
      }
    </div>
  );
};

HeatmapChart.propTypes = propTypes;

export default React.memo(HeatmapChart);
