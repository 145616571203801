export const ADD_SET_TO_SET_ANALYSIS = 'ADD_SET_TO_SET_ANALYSIS';
export const INIT_SETS_TO_SET_ANALYSIS = 'INIT_SETS_TO_SET_ANALYSIS';
export const REMOVE_SET_FROM_SET_ANALYSIS = 'REMOVE_SET_FROM_SET_ANALYSIS';
export const RESET_SETS_AT_SET_ANALYSIS = 'RESET_SETS_AT_SET_ANALYSIS';
export const SET_COLOR_CHANGE = 'SET_COLOR_CHANGE';
export const SET_NAME_CHANGE = 'SET_NAME_CHANGE';
export const SELECT_SET_FOR_SET_ANALYSIS = 'SELECT_SET_FOR_SET_ANALYSIS';
export const SET_ANALYTICS_LOADING_FLAG = 'SET_ANALYTICS_LOADING_FLAG';

export const SET_ANALYTICS_TITLE = 'SET_ANALYTICS_TITLE';
export const RESET_ANALYTICS_TITLE = 'RESET_ANALYTICS_TITLE';
export const SET_ANALYSIS_UPDATE_TAGS = 'SET_ANALYSIS_UPDATE_TAGS';

export const ANALYTICS_SET_COMMENT = 'ANALYTICS_SET_COMMENT';

export const ANALYTICS_SAVE_REQUESTED = 'ANALYTICS_SAVE_REQUESTED';
export const ANALYTICS_SAVE_SUCCEEDED = 'ANALYTICS_SAVE_SUCCEEDED';
export const ANALYTICS_SAVE_FAILED = 'ANALYTICS_SAVE_FAILED';

export const ANALYTICS_LOADING = 'ANALYTICS_LOADING';

export const ANALYTICS_INIT_REQUESTED = 'ANALYTICS_INIT_REQUESTED';
export const ANALYTICS_INIT_SUCCEEDED = 'ANALYTICS_INIT_SUCCEEDED';
export const ANALYTICS_INIT_FAILED = 'ANALYTICS_INIT_FAILED';

export const SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL = 'SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL';

export const SET_LAST_SAVE_ANALYTICS_ID = 'SET_LAST_SAVE_ANALYTICS_ID';
export const RESET_LAST_SAVE_ANALYTICS_ID = 'RESET_LAST_SAVE_ANALYTICS_ID';

export const SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE = 'SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE';
export const SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE = 'SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE';

export const SET_SET_ANALYSIS_ID = 'SET_SET_ANALYSIS_ID';
export const SWITCH_METHOD_SELECTION_LOADING_FLAG = 'SWITCH_METHOD_SELECTION_LOADING_FLAG';

export const PUT_UPLOADED_FILE_TO_SET = 'PUT_UPLOADED_FILE_TO_SET';
export const GET_FILE_DATA_FOR_SET_ANALYSIS = 'GET_FILE_DATA_FOR_SET_ANALYSIS';
