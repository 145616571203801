import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalComponent from '../../ModalComponent/ModalComponent';
import ConceptsSearchSidebar from './Components/ConceptsSearchSidebar/ConceptsSearchSidebar';
import ConceptsSearchContent from './Components/ConceptsSearchContent/ConceptsSearchContent';
// Store
import { getSearchConceptDataAction, resetSearchConceptAction } from './store/actions';
import {
  getActiveCategoriesSelector,
  getSearchConceptFiltersSelector,
  getSearchConceptsInputValueSelector,
  getUseCustomSemTypesSelector,
} from './store/selectors';
// Styles
import './styles.scss';
import {getCerebrumST} from '../../Header/selectors';

const propTypes = {
  filters: PropTypes.instanceOf(Object),
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  getConcepts: PropTypes.func,
  onSubmit: PropTypes.func,
  resetOnClose: PropTypes.bool,
  reset: PropTypes.func,
  formName: PropTypes.string,
  activeCategories: PropTypes.array,
  isUseCustomSemTypes: PropTypes.bool,
  allSemTypes: PropTypes.instanceOf(Object),
  action: PropTypes.string,
};

const ConceptSearchModal = (props) => {
  const {
    filters,
    inputValue,
    isOpen,
    closeCb,
    getConcepts,
    onSubmit,
    resetOnClose,
    reset,
    formName,
    activeCategories,
    isUseCustomSemTypes,
    allSemTypes,
    action = null,
  } = props;

  useEffect(() => reset, []);

  useEffect(() => {
    if (resetOnClose && !isOpen) {
      reset();
    }
  }, [resetOnClose, isOpen, reset]);

  useEffect(() => {
    isOpen && getConcepts({
      phrase: inputValue,
      action,
      ...filters,
    });
  }, [inputValue, filters]);

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="concepts-search">
        <ConceptsSearchSidebar
          filters={filters}
          inputValue={inputValue}
          activeCategories={activeCategories}
          customCategories={isUseCustomSemTypes ? activeCategories.map(id => ({ id, name: allSemTypes[id] })) : null}
        />
        <ConceptsSearchContent
          inputValue={inputValue}
          closeCb={closeCb}
          onSubmit={onSubmit}
          formName={formName}
          activeCategories={activeCategories}
          action={action}
        />
      </div>
    </ModalComponent>
  );
};

ConceptSearchModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    filters: getSearchConceptFiltersSelector(state),
    inputValue: getSearchConceptsInputValueSelector(state),
    activeCategories: getActiveCategoriesSelector(state),
    allSemTypes: getCerebrumST(state),
    isUseCustomSemTypes: getUseCustomSemTypesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConcepts(data) {
      dispatch(getSearchConceptDataAction(data));
    },
    reset() {
      dispatch(resetSearchConceptAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConceptSearchModal);
