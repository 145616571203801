import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getCellTypeClusterDataAction = createAction('@@cell-type-cluster-modal/GET_DATA');
export const exportCellTypeClusterDataAction = createAction('@@cell-type-cluster-modal/EXPORT_DATA');
export const toggleExportCellTypeClusterDataLoadingAction = createAction('@@cell-type-cluster-modal/TOGGLE_EXPORT_DATA_LOADING');
export const clearCellTypeClusterDataAction = createAction('@@cell-type-cluster-modal/CLEAR_DATA');
export const setCellTypeClusterDataAction = createAction('@@cell-type-cluster-modal/SET_DATA');
export const throwCellTypeClusterDataErrorAction = createAction('@@cell-type-cluster-modal/THROW_DATA_ERROR');
export const toggleIsModalOpenAction = createAction('@@cell-type-cluster-modal/TOGGLE_IS_MODAL_OPEN');
const initialState = fromJS({
  data: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  error: null,
  loading: true,
  exportLoading: false,
  selectedCluster: '',
  isModalOpen: false,
});

const cellTypeClusterModalReducer = handleActions({
  [getCellTypeClusterDataAction]: state =>
    state
      .update('loading', () => true),
  [toggleExportCellTypeClusterDataLoadingAction]: (state, { payload }) =>
    state
      .update('exportLoading', () => payload),
  [setCellTypeClusterDataAction]: (state, { payload }) =>
    state
      .update('data', () => fromJS(payload.content))
      .update('totalPages', () => payload.totalPages)
      .update('currentPage', () => payload.pageable.pageNumber)
      .update('totalElements', () => payload.totalElements)
      .update('loading', () => false)
      .update('error', () => null),
  [throwCellTypeClusterDataErrorAction]: (state, { payload }) =>
    state
      .update('data', () => [])
      .update('loading', () => false)
      .update('error', () => payload),
  [toggleIsModalOpenAction]: (state, { payload }) =>
    state
      .update('isModalOpen', () => payload.isModalOpen)
      .update('selectedCluster', () => payload.selectedCluster),
  [clearCellTypeClusterDataAction]: () => initialState,
}, initialState);
export default cellTypeClusterModalReducer;
