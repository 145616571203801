import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const propTypes = {
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  onPageChange: PropTypes.func,
  returnNumber: PropTypes.bool,
};

const Pagination = (props) => {
  const {
    totalPages,
    pageNumber,
    onPageChange,
    returnNumber = false,
  } = props;

  const handleOnPageChange = useCallback((page) => {
    if (returnNumber) {
      onPageChange(page.selected);
    } else {
      onPageChange(page);
    }
  }, [returnNumber, onPageChange]);

  if (totalPages === 0) {
    return null;
  }

  return (
    <div className="pagination-wrap">
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakClassName="break-me"
        pageCount={totalPages}
        forcePage={pageNumber}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={handleOnPageChange}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    </div>
  );
};

Pagination.propTypes = propTypes;

export default React.memo(Pagination);
