import { call, put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../../../Api/Api';
import {
  setActivateUserModalOpenAction,
  setActivateUserModalCloseAction,
  activateUserTokenFailureAction,
  submitPasswordAction,
  activateUserTokenCheckAction,
  activateUserCheckTokenFailureAction,
} from './reducer';
import { getActivateUserPasswordForm } from './selectors';
import { history } from '../../../../index';
import { RELATIVE_PATH } from '../../../../constantsCommon';
import Storage from '../../Storage/Storage';

function* checkTokenSaga(action) {
  try {
    const token = action.payload;
    yield call(Api.checkActivateAccountToken, { token });
    yield call(Storage.setValue, { key: 'token', value: token });
    yield put(setActivateUserModalOpenAction());
  } catch (e) {
    yield put(activateUserCheckTokenFailureAction(e.response.data.message));
    yield put(setActivateUserModalOpenAction());
  }
}

function* activateUserPasswordSaga() {
  try {
    const token = Storage.getValue('token');
    const form = yield select(getActivateUserPasswordForm);
    yield call(Api.activateAccount, {
      token,
      userDto: {
        password: form.password,
      },
    });
    yield put(setActivateUserModalCloseAction());
    history.push(`${RELATIVE_PATH}/login`);
    Storage.removeValue('token');
  } catch (e) {
    yield put(activateUserTokenFailureAction(e.message));
  }
}


function* activateCreatedUserSaga() {
  yield takeEvery(activateUserTokenCheckAction, checkTokenSaga);
  yield takeEvery(submitPasswordAction, activateUserPasswordSaga);
}

export default activateCreatedUserSaga;
