import React, { useEffect } from 'react';
import { useMarker } from 'react-mark.js';
import PropTypes from 'prop-types';

// Utils
import { insertHTML } from '../../../Utils/Utils';
import { drawHighlights } from './Utils';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  semanticCategories: PropTypes.instanceOf(Object),
  highlights: PropTypes.instanceOf(Object),
  prevHighlighs: PropTypes.instanceOf(Object),
};

const PublicationDetailsKeywords = (props) => {
  const { markerRef, marker } = useMarker();

  const {
    data,
    semanticCategories,
    highlights,
    prevHighlighs,
  } = props;

  useEffect(() => {
    drawHighlights(marker, highlights, prevHighlighs, semanticCategories);
  }, [marker, highlights]);
  return (
    <span
      ref={markerRef}
      className="pub-details-info__data"
      dangerouslySetInnerHTML={insertHTML(data.keywordList.join(', '))} // eslint-disable-line
    />
  );
};

PublicationDetailsKeywords.propTypes = propTypes;

export default PublicationDetailsKeywords;
