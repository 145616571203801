import React from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import CloseOnEscape from 'react-close-on-escape';
import groupBy from 'lodash.groupby';

// Components
import Loader from '../../common/Loader/Loader';
import CreateSimpleSetFromComplexSetModal from '../CreateSimpleSetFromComplexSetModal/CreateSimpleSetFromComplexSetModal';
import CreateEffectSetFromComplexSetModal from '../CreateEffectSetFromComplexSetModal/CreateEffectSetFromComplexSetModal';
import ComplexSetAggregateModal from '../ComplexSetAggregateModal/ComplexSetAggregateModal';
import ComplexSetSettingsModal from '../ComplexSetSettingsModal/ComplexSetSettingsModal';
import ComplexSetFilterModal from '../ComplexSetFilterModal/ComplexSetFilterModal';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
// Utils
import { contentTypes } from './enums';
import { getScreenWidth } from '../../Utils/Utils';
import { taxonomies, types } from '../../../constantsCommon';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Store
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
// Styles
import './ComplexSetView.css';

const propTypes = {
  resetComponentState: PropTypes.func,
  fetchSetData: PropTypes.func,
  locationParams: PropTypes.instanceOf(Object),
  applyFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  columnNames: PropTypes.instanceOf(Array),
  content: PropTypes.instanceOf(Array),
  complexSetName: PropTypes.string,
  complexSetDescription: PropTypes.string,
  showComplexToSimpleDialog: PropTypes.func,
  showComplexToEffectDialog: PropTypes.func,
  resetFilters: PropTypes.func,
  saveFilteredSet: PropTypes.func,
  simpleSetAnalyze: PropTypes.func,
  saveSimpleSet: PropTypes.func,
  effectSetAnalyze: PropTypes.func,
  saveEffectSet: PropTypes.func,
  columnsConfiguration: PropTypes.instanceOf(Array),
  showSimpleToComplexDialogVisibilityFlag: PropTypes.bool,
  showComplexToEffectDialogVisibilityFlag: PropTypes.bool,
  columnNamesForDropDown: PropTypes.instanceOf(Array),
  analyzingFlag: PropTypes.bool,
  showFullSimpleSetCreationFormFlag: PropTypes.bool,
  ambigiousItems: PropTypes.instanceOf(Array),
  applyAggregation: PropTypes.func,
  updateComplexSetConfig: PropTypes.func,
  hideComplexToEffectDialog: PropTypes.func,
  hideComplexToSimpleDialog: PropTypes.func,
  closeErrorModal: PropTypes.func,
  aggregationRules: PropTypes.instanceOf(Array),
  contentForSettingsModal: PropTypes.instanceOf(Object),
  commonFailureText: PropTypes.string,
  complexSetToEffectSetFormValues: PropTypes.instanceOf(Object),
  complexSetTags: PropTypes.instanceOf(Array),
  tags: PropTypes.instanceOf(Array),
  ambigiousSorting: PropTypes.instanceOf(Object),
  downloadSet: PropTypes.func,
  clearErrorOnSaveFreeUser: PropTypes.func,
};

class ComplexSetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Array of objects of shape
      * {
      *   columnName,
      *   excluded,
      *   operation,
      *   value,
      * }
      * */
      currentFilters: [],
      currentAggregationRules: [],
      showFilterDialog: false,
      showAggregateDialog: false,
      showConfigurationDialog: false,
      arrayIndex: 0,
      resetSetButtonDisabled: true,
      applyButtonDisabled: false,
    };
  }

  componentWillUnmount() {
    this.props.resetComponentState();
  }

  componentDidMount() {
    const {
      locationParams: {
        setId,
        projectId,
      },
    } = this.props;
    this.props.fetchSetData({ setId, projectId });
  }

  applyFilter = (data) => {
    this.props.applyFilter(data);
  };

  handlePageClick = (e) => {
    const {
      locationParams: {
        setId,
        projectId,
      },
    } = this.props;
    this.props.fetchSetData({
      setId,
      projectId,
      discard: false,
      params: {
        size: 20,
        page: e.selected,
      },
    });
  };

  closeSetSettingsModal = () => {
    this.setState({
      showConfigurationDialog: false,
    });
  };

  updateComplexSetConfigHandler = (data) => {
    const {
      locationParams: {
        projectId,
      },
      updateComplexSetConfig,
    } = this.props;
    this.setState({
      currentFilters: [],
      resetSetButtonDisabled: true,
    });
    updateComplexSetConfig({ ...data, projectId });
  };

  exportToCsv = () => {
    const {
      locationParams: {
        setId,
        projectId,
      },
      complexSetName,
      downloadSet,
    } = this.props;
    downloadSet({
      setId,
      projectId,
      downloadLink: this.downloadLink,
      setName: complexSetName,
    });
  };

  renderContent() {
    const {
      locationParams: {
        setId,
        projectId,
      },
      // from mapStateToProps
      isLoading,
      isError,
      totalPages,
      pageNumber,
      content,
      columnNames,
      complexSetName,
      complexSetDescription,
      aggregationRules,
      contentForSettingsModal,
      complexSetTags,
      // from mapDispatchToProps
      showComplexToSimpleDialog,
      showComplexToEffectDialog,
      resetFilters,
      saveFilteredSet,
      columnsConfiguration,
      columnNamesForDropDown,
      commonFailureText,
    } = this.props;

    if (commonFailureText) {
      return (
        <CloseOnEscape
          onEscape={() => this.props.closeErrorModal}
        >
          <div className="error-modal-container">
            <div className="error-modal-content">
              <div className="page-title">
                Error
              </div>
              <div
                className="close-button"
                onClick={
                  () => this.props.closeErrorModal()
                }
              >
                <svg
                  width="40"
                  height="40"
                >
                  <circle cx="20" cy="20" r="20" fill="black" />
                  <g transform="rotate(45 20 20)">
                    <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
                    <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
                  </g>
                </svg>
              </div>
              <div className="error-modal-text">
                {commonFailureText}
              </div>
            </div>
          </div>
        </CloseOnEscape>
      );
    }

    if (isError) {
      return (
        <div className="text-center error-text">
          Sorry, error occurred.
        </div>
      );
    }

    if (isLoading) {
      return (
        <Loader isLoading={true} />
      );
    }

    const groupedFilters = groupBy(this.state.currentFilters, 'index');
    const groupedFiltersIndexedKeys = Object.keys(groupedFilters);

    const screenWidth = getScreenWidth();
    const tableWidth = 1300;
    const width = screenWidth < tableWidth ? screenWidth : tableWidth;

    let tableSettings = {
      width: content.length * 100 < width ? width : content.length * 100,
      headerHeight: 50,
      rowHeight: 50,
    };

    if (content.length < 20) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    let columns = [];
    if (columnNames.length > 0) {
      columns = columnNames.map(
        columnName => (
          {
            dataKey: { columnName },
            width: 300,
            disableSort: true,
            headerRenderer: ({ dataKey }) => (
              <div
                className="complex-set-table-header"
                title={dataKey.columnName}
              >
                {dataKey.columnName}
              </div>
            ),
            cellRenderer: ({ rowData }) => (
              <span
                className="complex-set-table-content"
                title={rowData[columnName]}
              >
                {rowData[columnName]}
              </span>
            ),
          }
        )
      );
    }

    const complexSetNumberColumns = columnsConfiguration.filter(
      columnConfigurationItem => columnConfigurationItem.type === 'NUMBER');
    const complexSetHasNumberColumns = !!complexSetNumberColumns.length;

    const aggregateButtonClassName = complexSetHasNumberColumns ? 'btn btn-info' : 'btn btn-default';
    const applyButtonClassName = this.state.applyButtonDisabled ? 'btn btn-default' : 'btn btn-info';

    return (
      <div className="complex-set-view-content">
        <div className="text-center complex-set-header">
          {complexSetName}
        </div>
        {
          this.state.showConfigurationDialog &&
          <ComplexSetSettingsModal
            complexSetContent={contentForSettingsModal}
            cancelButtonHandler={this.closeSetSettingsModal}
            setId={setId}
            projectId={projectId}
            setsName={complexSetName}
            description={complexSetDescription}
            tags={complexSetTags}
            submitButtonHandler={data => this.updateComplexSetConfigHandler(data)}
          />
        }
        <div className="button-section">
          <button
            disabled={!content.length}
            onClick={showComplexToSimpleDialog}
            className="button button-primary mr-5 ml-5"
          >
            Create simple set
          </button>
          <button
            disabled={!content.length || !complexSetHasNumberColumns}
            onClick={showComplexToEffectDialog}
            className="button button-primary mr-5"
          >
            Create effect set
          </button>
          <button
            disabled={content && content.length === 0}
            onClick={this.exportToCsv}
            className="button button-primary"
          >
            Download set
          </button>
          <a //eslint-disable-line
            hidden={true}
            ref={(ref) => { this.downloadLink = ref; }}
          />
          <button
            onClick={() => this.setState({
              showFilterDialog: true,
            })}
            className="btn btn-info"
          >
            Add filter
          </button>
          <button
            onClick={() => this.setState({
              showAggregateDialog: true,
            })}
            className={aggregateButtonClassName}
            disabled={!complexSetHasNumberColumns}
          >
            Aggregate set
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={this.state.resetSetButtonDisabled}
            onClick={() => {
              this.setState({
                currentFilters: [],
                currentAggregationRules: [],
                resetSetButtonDisabled: true,
              });
              resetFilters({ setId, projectId });
            }}
          >
            Restore Complex Set
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={this.state.resetSetButtonDisabled}
            onClick={() => {
              saveFilteredSet({ setId, projectId });
              this.setState({
                resetSetButtonDisabled: true,
              });
            }}
          >
            Save Complex Set
          </button>
          <button
            onClick={() => this.setState({
              showConfigurationDialog: true,
            })}
            className="btn btn-info"
          >
            Config set
          </button>
        </div>
        <div className="filter-section">
          {
            this.state.currentFilters.length > 0 &&
            <div className="buttons-block">
              <button
                type="button"
                className={applyButtonClassName}
                disabled={this.state.applyButtonDisabled}
                onClick={() => {
                  this.props.applyFilter({
                    params: this.state.currentFilters.filter(filterItem => filterItem.index === this.state.arrayIndex),
                    setId,
                    projectId,
                  });
                  this.setState({
                    arrayIndex: this.state.arrayIndex + 1,
                    resetSetButtonDisabled: false,
                    applyButtonDisabled: true,
                  });
                }}
              >
                Apply Filters
              </button>
            </div>
          }
          <div className="filters-block">
            {
              groupedFiltersIndexedKeys.map((groupedFiltersIndexedKey, groupedFiltersIndexedKeyIndex) => (
                <div
                  className="filter-content"
                  key={`filter_group_${groupedFiltersIndexedKeyIndex}`}
                >
                  {
                    groupedFilters[groupedFiltersIndexedKey].map((filterInnerItem, filterInnerItemIndex) => {
                      const columnNameToDisplay = columnNamesForDropDown.find(item => item.value === filterInnerItem.columnNumber);
                      return (
                        <div
                          key={`filter_item_${filterInnerItemIndex}`}
                        >
                          {`${columnNameToDisplay.label} ${filterInnerItem.excluded} ${filterInnerItem.operation} ${filterInnerItem.value}`}
                        </div>
                      );
                    })
                  }
                </div>
              ))
            }
          </div>
          <div className="aggregation-rules-block">
            {
              aggregationRules.map((rule, ruleIndex) => {
                const {
                  identifierColumnNumber,
                  aggregationColumnNumber,
                } = rule;

                const identifierColumn = columnNamesForDropDown.find(item => item.value === identifierColumnNumber);
                const aggregationColumn = columnNamesForDropDown.find(item => item.value === aggregationColumnNumber);

                return (
                  <div
                    className="rule-content"
                    key={`aggregation_rule_${ruleIndex}`}
                  >
                    <div>{`Identifier column ${identifierColumn.label}`}</div>
                    <div>{`Aggregation column ${aggregationColumn.label}`}</div>
                    <div>{`Aggregation method ${rule.operator}`}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="table-section">
          {
            totalPages > 1 &&
            <div className="col-5">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel={<a>...</a>} //eslint-disable-line
                breakClassName="break-me"
                pageCount={totalPages}
                forcePage={pageNumber}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
          }
          <div className="complex-set-table-wrapper" style={{ width }}>
            <SimpleTable
              settings={tableSettings}
              data={content}
              columns={columns}
            />
          </div>
        </div>
      </div>
    );
  }

  getNumberColumns = () => {
    const {
      columnsConfiguration = [],
    } = this.props;

    return columnsConfiguration
      .filter(columnConfigurationItem => columnConfigurationItem.type === 'NUMBER')
      .map(column => ({ label: column.name, value: column.colNumber, type: `${column.colNumber}` }));
  };

  getTextColumns = () => {
    const {
      columnsConfiguration = [],
    } = this.props;

    return columnsConfiguration
      .filter(columnConfigurationItem => columnConfigurationItem.type === 'TEXT')
      .map(column => ({ label: column.name, value: column.colNumber, type: `${column.colNumber}` }));
  };

  render() {
    const {
      // from mapStateToProps
      complexSetToEffectSetFormValues,
      showSimpleToComplexDialogVisibilityFlag,
      showComplexToEffectDialogVisibilityFlag,
      columnNames,
      columnNamesForDropDown,
      analyzingFlag,
      showFullSimpleSetCreationFormFlag,
      ambigiousItems,
      // from mapDispatchToProps
      simpleSetAnalyze,
      saveSimpleSet,
      effectSetAnalyze,
      saveEffectSet,
      columnsConfiguration,
      applyAggregation,
      // from URL
      locationParams: {
        setId,
        projectId,
      },
      complexSetDescription,
      complexSetName,
      tags,
      ambigiousSorting,
      clearErrorOnSaveFreeUser,
    } = this.props;
    const {
      currentFilters,
      currentAggregationRules,
    } = this.state;

    const filtersDescription = currentFilters.length ? `${currentFilters.map(filter =>
      `${columnNames[filter.columnNumber]} ${filter.excluded} ${filter.operation} ${filter.value}`)}; ` : null;
    const aggregationsDescription = currentAggregationRules.length ? `${currentAggregationRules.map(aggr =>
      `Idendifier column ${columnNames[aggr.identifierColumnNumber]} Aggregation column ${columnNames[aggr.aggregationColumnNumber]} Aggregation method ${aggr.operator}`)}; ` : null;

    const initialValues = {
      description: [complexSetDescription, filtersDescription, aggregationsDescription].filter(val => val).join('\n'),
      name: complexSetName,
      semanticType: types[0].value,
      identifierColumnNumber: columnNamesForDropDown[0] ? columnNamesForDropDown[0].value : '',
      measureColumnNumber: `${this.getNumberColumns()[0] ? this.getNumberColumns()[0].value : ''}`,
      contentType: contentTypes[0].value,
      taxonomy: taxonomies[0],
    };

    return (
      <div className="complex-set-view-root">
        {this.renderContent()}
        {
          showSimpleToComplexDialogVisibilityFlag &&
          <CloseOnEscape
            onEscape={this.props.hideComplexToSimpleDialog}
          >
            <CreateSimpleSetFromComplexSetModal
              textColumns={this.getTextColumns()}
              setId={setId}
              projectId={projectId}
              actionForAnalyzeStart={simpleSetAnalyze}
              analyzing={analyzingFlag}
              fullSimpleSetForm={showFullSimpleSetCreationFormFlag}
              tags={tags}
              ambigiousItems={ambigiousItems}
              submitHandler={saveSimpleSet}
              closeHandler={this.props.hideComplexToSimpleDialog}
              initialValues={initialValues}
              sorting={ambigiousSorting}
            />
          </CloseOnEscape>
        }
        {
          showComplexToEffectDialogVisibilityFlag &&
          <CloseOnEscape
            onEscape={this.props.hideComplexToEffectDialog}
          >
            <CreateEffectSetFromComplexSetModal
              numberColumns={this.getNumberColumns()}
              textColumns={this.getTextColumns()}
              availableColumns={columnNamesForDropDown}
              setId={setId}
              projectId={projectId}
              actionForAnalyzeStart={effectSetAnalyze}
              analyzing={analyzingFlag}
              fullSimpleSetForm={showFullSimpleSetCreationFormFlag}
              tags={tags}
              ambigiousItems={ambigiousItems}
              submitHandler={saveEffectSet}
              closeHandler={this.props.hideComplexToEffectDialog}
              complexSetToEffectSetFormValues={complexSetToEffectSetFormValues}
              initialValues={initialValues}
              sorting={ambigiousSorting}
            />
          </CloseOnEscape>
        }
        {
          this.state.showFilterDialog &&
          <CloseOnEscape
            onEscape={
              () => { this.setState({ showFilterDialog: false }); }
            }
          >
            <ComplexSetFilterModal
              setId={setId}
              handleSubmit={(data) => {
                this.setState({
                  currentFilters: [...this.state.currentFilters, { ...data, index: Number(this.state.arrayIndex) }],
                  showFilterDialog: false,
                  applyButtonDisabled: false,
                });
              }}
              columnsConfiguration={columnsConfiguration}
              availableColumns={columnNamesForDropDown}
              closeHandler={() => this.setState({
                showFilterDialog: false,
              })}
            />
          </CloseOnEscape>
        }
        {
          this.state.showAggregateDialog &&
          <CloseOnEscape
            onEscape={
              () => { this.setState({ showAggregateDialog: false }); }
            }
          >
            <ComplexSetAggregateModal
              setId={setId}
              availableColumns={columnsConfiguration}
              handleSubmit={(data) => {
                this.setState({
                  currentAggregationRules: [...this.state.currentAggregationRules, { ...data, aggregationIndex: this.state.aggregationIndex }],
                  showAggregateDialog: false,
                  resetSetButtonDisabled: false,
                });
                applyAggregation({ setId, data, projectId });
              }}
              closeHandler={() => this.setState({
                showAggregateDialog: false,
              })}
            />
          </CloseOnEscape>
        }
        <FreeAccountLimitationDialog
          closeCb={clearErrorOnSaveFreeUser}
          entity="set"
        />
      </div>
    );
  }
}

ComplexSetView.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pageNumber: SELECTORS.getPageNumber(state),
    totalPages: SELECTORS.getTotalPages(state),
    totalElements: SELECTORS.getTotalElements(state),
    content: SELECTORS.getContent(state),
    complexSetName: SELECTORS.getName(state),
    complexSetDescription: SELECTORS.getDescription(state),
    isLoading: SELECTORS.getLoadingFlag(state),
    isError: SELECTORS.getErrorFlag(state),
    columnNames: SELECTORS.getColumnNames(state),
    showSimpleToComplexDialogVisibilityFlag: SELECTORS.getShowComplexToSimpleDialogVisibilityFlag(state),
    showComplexToEffectDialogVisibilityFlag: SELECTORS.getShowComplexToEffectDialogVisibilityFlag(state),
    columnNamesForDropDown: SELECTORS.getColumnNamesForDropDown(state),
    analyzingFlag: SELECTORS.getAnalyzingFlag(state),
    showFullSimpleSetCreationFormFlag: SELECTORS.getShowFullSimpleSetCreationFormFlag(state),
    ambigiousItems: SELECTORS.getItemsForAmbigiousTable(state),
    ambigiousSorting: SELECTORS.getAmbigiousSortingSelector(state),
    columnsConfiguration: SELECTORS.getColumnsConfiguration(state),
    aggregationRules: SELECTORS.getAggregationRules(state),
    contentForSettingsModal: SELECTORS.getContentForSettingsModal(state),
    complexSetToEffectSetFormValues: SELECTORS.getComplexSetToEffectSetFormValues(state),
    commonFailureText: SELECTORS.getCommonFailureTextValue(state),
    complexSetTags: SELECTORS.getComplexSetTags(state),
    tags: SELECTORS.getTags(state),
    items: SELECTORS.effectSetItemsFormatted(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeErrorModal() {
      dispatch(ACTIONS.closeErrorModalAction());
    },
    updateComplexSetConfig(data) {
      dispatch(ACTIONS.updateComplexSetConfigAction(data));
    },
    applyAggregation(data) {
      dispatch(ACTIONS.applyAggregationAction(data));
    },
    saveFilteredSet(data) {
      dispatch(ACTIONS.saveFilteredSetAction(data));
    },
    resetFilters(data) {
      dispatch(ACTIONS.resetFiltersAction(data));
    },
    applyFilter(data) {
      dispatch(ACTIONS.applyFilterAction(data));
    },
    resetComponentState() {
      dispatch(ACTIONS.resetState());
    },
    saveSimpleSet(data) {
      dispatch(ACTIONS.simpleSetSaveRequestAction(data));
    },
    simpleSetAnalyze(setId) {
      dispatch(ACTIONS.simpleSetAnalyzeRequestAction(setId));
    },
    saveEffectSet(setId) {
      dispatch(ACTIONS.effectSetSaveRequestAction(setId));
    },
    effectSetAnalyze(setId) {
      dispatch(ACTIONS.effectSetAnalyzeRequestAction(setId));
    },
    showComplexToSimpleDialog() {
      dispatch(ACTIONS.showComplexToSimpleDialogAction());
    },
    showComplexToEffectDialog() {
      dispatch(ACTIONS.showComplexToEffectDialogAction());
    },
    hideComplexToSimpleDialog() {
      dispatch(ACTIONS.hideComplexToSimpleDialogAction());
    },
    hideComplexToEffectDialog() {
      dispatch(ACTIONS.hideComplexToEffectDialogAction());
    },
    fetchSetData(data) {
      dispatch(ACTIONS.fetchComplexSetDataRequestAction(data));
    },
    downloadSet(data) {
      dispatch(ACTIONS.downloadComplexSetDataRequestAction(data));
    },
    clearErrorOnSaveFreeUser() {
      dispatch(ACTIONS.clearSaveSetFreeUserErrorAction());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplexSetView));
