import { call, put, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Store
import {
  loadNextChapterAction,
  geneDetailsSetChapterAction,
} from '../actions';
import {
  setGeneDetailsLiteratureSearchDataAction,
  getGeneDetailsLiteratureSearchDataAction,
  throwGeneDetailsLiteratureSearchErrorAction,
} from './reducer';

function* sagaLiteratureSearchChapter({ payload }) {
  try {
    const { data } = yield call(Api.search, payload);
    yield put(setGeneDetailsLiteratureSearchDataAction(data));
    if (data.publications && data.publications.content.length) {
      yield put(geneDetailsSetChapterAction('literature-search'));
    }
    yield put(loadNextChapterAction('protein-structure'));
  } catch (e) {
    yield put(throwGeneDetailsLiteratureSearchErrorAction(e.message));
    yield put(loadNextChapterAction('protein-structure'));
  }
}

function* geneDetailsExpressionChapterSaga() {
  yield takeLatest(getGeneDetailsLiteratureSearchDataAction, sagaLiteratureSearchChapter);
}

export default geneDetailsExpressionChapterSaga;
