import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';

import FormInput from '../../../../common/FormElements/FormInput';
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';

import { checkAllItems, checkAllGroupItems, checkItem } from '../utils';

const propTypesColumn = {
  tissues: PropTypes.instanceOf(Array),
  updateTissues: PropTypes.func,
  checkAll: PropTypes.bool,
  tissuesObj: PropTypes.instanceOf(Object),
  change: PropTypes.func,
  allTissues: PropTypes.instanceOf(Object),
  changeSortDirection: PropTypes.func,
};

class SingleTargetForCancerTumorsColumn extends React.Component {
  componentDidMount() {
    this.setHiddenContentFieldValue();
  }

  componentDidUpdate() {
    this.setHiddenContentFieldValue();
  }

  sortTissues = (type) => {
    const { changeSortDirection } = this.props;
    changeSortDirection({ type });
  };

  checkTissue = (id, type) => {
    const {
      updateTissues,
      tissuesObj,
    } = this.props;

    const updatedTissues = checkItem(tissuesObj, type, id);
    updateTissues(updatedTissues);
  };

  checkAllTissues = () => {
    const {
      updateTissues,
      checkAll,
      tissuesObj,
    } = this.props;

    const updatedTissues = checkAllItems(tissuesObj, checkAll);
    updateTissues(updatedTissues);
  };

  checkAllGroupTissues = (type) => {
    const {
      updateTissues,
      tissuesObj,
    } = this.props;
    const updatedTissues = checkAllGroupItems(tissuesObj, type);
    updateTissues(updatedTissues);
  };

  setHiddenContentFieldValue = () => {
    const { change, allTissues } = this.props;
    const { tissues, checkedTissues } = allTissues.tumorTissues;
    const value = tissues.length > 0 && checkedTissues.length > 0;
    change('tumorTissuesContent', value);
  };

  render() {
    const { tissues, checkAll } = this.props;

    const setTitleClass = (data, sortDirection) => classNames(
      'tissues-dnd-column__title',
      { 'sort': data.length },
      { 'sort_up': sortDirection === 'DESC' },
      { 'sort_down': sortDirection === 'ASC' },
    );

    return (
      <div className="tissues-dnd-column">
        <div className="tissues-dnd-column__header">
          <Checkbox
            checked={checkAll}
            readOnly={true}
            onChange={this.checkAllTissues}
          />
          <span
            className="tissues-dnd-column__title"
          >
            Tumor Tissues
          </span>
          <Field
            name="tumorLevel"
            type="number"
            placeholder="Min Expr (TPM)"
            component={FormInput}
            autoComplete="off"
            mandatory={true}
            customClassName="form__input form__input_small form__input_light"
          />
        </div>
        <div className="tissues-dnd-column__items">
          <div className="tissues-dnd-column__list">
            {
              tissues.length > 0 &&
              tissues.map(tissue => (
                <div className="tissues-dnd-group-tissues" key={`tissue_${tissue.id}`}>
                  <div className="tissues-dnd-group">
                    <Checkbox
                      checked={tissue.checkAll}
                      readOnly={true}
                      onChange={() => { this.checkAllGroupTissues(tissue.id); }}
                      name={tissue.title}
                    />
                    <span
                      className={setTitleClass(tissue.tissues, tissue.sortDirection)}
                      onClick={() => this.sortTissues(tissue.id)}
                    >
                      { tissue.title }
                    </span>
                  </div>
                  <div className="tissues-dnd-column__list">
                    {
                      tissue.tissues.length > 0 &&
                        tissue.tissues.map((t) => {
                          let title = '';
                          const labelValue = tissue.id === 'TCGA' ? t.nameWithLabel || t.cancerName : t.name;
                          if (tissue.id === 'TCGA') {
                            title = t.label ? `${t.cancerName} [${t.label}]` : t.cancerName;
                          } else title = t.name;
                          const key = t.label ? `tissue_${t.id}_${t.label}` : `tissue_${t.id}`;
                          return (
                            <div
                              className="tissues-dnd-tissue"
                              key={key}
                            >
                              <Checkbox
                                title={title}
                                checked={t.checked}
                                readOnly={true}
                                labelValue={labelValue}
                                onChange={() => {
                                  this.checkTissue(t.id, tissue.id);
                                }}
                              />
                            </div>
                          );
                        })
                    }
                  </div>
                </div>
              )
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

SingleTargetForCancerTumorsColumn.propTypes = propTypesColumn;

export default SingleTargetForCancerTumorsColumn;
