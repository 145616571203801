import { call, put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../../../Api/Api';
import {
  setNewPasswordModalOpenAction,
  userForgotPasswordTokenCheckAction,
  setNewPasswordModalCloseAction,
  userForgotPasswordTokenFailureAction,
  submitNewPasswordAction,
  setNewPasswordTokenFailureAction,
} from './reducer';
import { newPasswordForm } from './selectors';
import { history } from '../../../../index';
import { RELATIVE_PATH } from '../../../../constantsCommon';
import Storage from '../../Storage/Storage';

function* checkTokenSaga(action) {
  try {
    const token = action.payload;
    yield call(Api.checkForgotPasswordToken, { token });
    yield call(Storage.setValue, { key: 'token', value: token });
    yield put(setNewPasswordModalOpenAction());
  } catch (e) {
    yield put(setNewPasswordTokenFailureAction(e.response.data.message));
    yield put(setNewPasswordModalOpenAction());
  }
}

function* newPasswordSaga() {
  try {
    const token = Storage.getValue('token');
    const form = yield select(newPasswordForm);
    yield call(Api.setNewPassword, { token, password: form.newPassword });
    yield put(setNewPasswordModalCloseAction());
    history.push(`${RELATIVE_PATH}/login`);
    Storage.removeValue('token');
  } catch (e) {
    yield put(userForgotPasswordTokenFailureAction(e.message));
  }
}


function* setNewPassword() {
  yield takeEvery(userForgotPasswordTokenCheckAction, checkTokenSaga);
  yield takeEvery(submitNewPasswordAction, newPasswordSaga);
}

export default setNewPassword;
