export const PERSONAL_TAB = 'personal';

export const defaultProjects = [{
  name: 'Personal',
  id: PERSONAL_TAB,
}];

export const personalOption = {
  label: 'Personal',
  type: 'Personal',
  value: 'Personal',
  id: PERSONAL_TAB,
};
