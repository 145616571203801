export const barChartDiagramRows = {
  firstDiagramRow: [{
    id: 'gtex-expression-diagram',
    dataKey: 'gtexTissues',
    countKey: 'gtexTissuesCount',
    namesListKey: 'gtexTissueNames',
    name: 'GTEx',
  }, {
    id: 'tcga-expression-diagram',
    dataKey: 'tcgaTissues',
    countKey: 'tcgaTissuesCount',
    namesListKey: 'tcgaTissueNames',
    name: 'TCGA',
  }],
  secondDiagramRow: [{
    id: 'beatAml-expression-diagram',
    dataKey: 'beatAmlTissues',
    countKey: 'beatAmlTissuesCount',
    namesListKey: 'beatAmlTissueNames',
    name: 'Beat AML',
  }],
  thirdDiagramRow: [{
    id: 'human-protein-map-expression-diagram',
    dataKey: 'hpmTissues',
    countKey: 'hpmTissuesCount',
    namesListKey: 'hpmTissueNames',
    name: 'Human protein map',
    rotateXAxis: true,
  }],
};
