const CELL_MEMBRANE_PROTEIN = 'CELL_MEMBRANE_PROTEIN';
const EXTRACELLULAR_PROTEIN = 'EXTRACELLULAR_PROTEIN';
const INTRACELLULAR_PROTEIN = 'INTRACELLULAR_PROTEIN';
export const EMPTY_FILTER = 'EMPTY_FILTER';

export const firstNodeCategorySelectValues = [
  CELL_MEMBRANE_PROTEIN,
  EXTRACELLULAR_PROTEIN,
  INTRACELLULAR_PROTEIN,
];
