import { takeLatest, call, put, all } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { setHeatmapInitialDataAction } from '../../../graphics/Heatmap/store/reducer';
import {
  getCompareSetsDataAction,
  setCompareSetsDataAction,
  throwCompareSetsErrorAction,
} from './reducer';
import { formatHeatmapInitialData } from '../utils';
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';

function* getCompareSetsDataWorker(action) {
  try {
    const { ids, projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const getRequestData = id => (isProjectTab ? { projectId, setId: id } : id);
    const data = yield all(ids.map(id => call(apiToCall, getRequestData(id))));
    const sets = data.map(d => d.data);
    yield put(setCompareSetsDataAction(sets));
    yield put(setHeatmapInitialDataAction(formatHeatmapInitialData(sets)));
  } catch (e) {
    yield put(throwCompareSetsErrorAction(e.message));
  }
}

function* compareSetsSaga() {
  yield takeLatest(getCompareSetsDataAction, getCompareSetsDataWorker);
}

export default compareSetsSaga;
