export const legendData = [
  {
    color: '#8bc34a',
    name: 'Review',
  },
  {
    color: '#ffeb3b',
    name: 'Clinical trial',
  },
  {
    color: '#51a2fb',
    name: 'Other',
  },
];

export const ChartsTypesEnum = {
  CITED: 'CITED',
  CITEDBY: 'CITEDBY',
};

export const CitedChartConfig = {
  chartType: ChartsTypesEnum.CITED,
  domainXKey: 'cited',
  selector: 'cited-chart',
  fixedSelector: 'cited-chart-fixed',
};

export const CitedByChartConfig = {
  chartType: ChartsTypesEnum.CITEDBY,
  domainXKey: 'citedBy',
  selector: 'cited-by-chart',
  fixedSelector: 'cited-by-chart-fixed',
};
