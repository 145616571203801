import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Icons
import { BiSortDown, BiSortUp } from 'react-icons/bi';
// Components
import Loader from '../../../common/Loader/Loader';
import SimilarPublicationItem from '../SimilarPublicationItem/SimilarPublicationItem';
import SelectInput from '../../../common/Inputs/SelectInput/SelectInput';
// Constatns
import { SimilarPubsSortingEnum, SimilarPubsOptions } from '../../constants';
// Utils
import { toggleSortingDirection } from '../../../Utils/Utils';
// Store
import {
  getSimilarPublications,
  getPublicationDetailsLoadingSelector,
  getPublicationDetailsErrorSelector,
} from '../../selectors';
// Styles
import './styles.scss';

const propTypes = {
  similarPublications: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const SimilarPublications = (props) => {
  const { similarPublications, loading, error } = props;

  const [sorting, setSorting] = useState({
    sortBy: SimilarPubsSortingEnum.SCORE,
    sortDirection: 'DESC',
  });

  const getSortedPublications = useCallback(() => {
    if (!similarPublications) {
      return [];
    }
    const { sortBy, sortDirection } = sorting;
    const comparator = (a, b) => a - b;

    return similarPublications.sort((a, b) => {
      const key = sortBy === SimilarPubsSortingEnum.SCORE ? 'distanceScore' : 'pubDate';
      return sortDirection === 'DESC' ? -comparator(a[key], b[key]) : comparator(a[key], b[key]);
    });
  }, [similarPublications, sorting]);

  function toggleSortDirection() {
    const sortDirection = toggleSortingDirection(sorting.sortDirection);
    setSorting({
      ...sorting,
      sortDirection,
    });
  }

  function toggleSortBy(d) {
    setSorting({
      ...sorting,
      sortBy: d.value,
    });
  }

  return (
    <div className="similar-publications">
      <div className="pub-details__similar-publications pub-details-container">
        {
          similarPublications && !loading && !error &&
          <>
            <div className="similar-publications__header">
              <span className="similar-publications__title">
                Similar publications
              </span>
              {
                similarPublications.length > 0 &&
                <>
                  <SelectInput
                    defaultValue={SimilarPubsOptions[0]}
                    options={SimilarPubsOptions}
                    onSelect={toggleSortBy}
                    customClassName="similar-publications__select"
                  />
                  <button className="circle-btn" onClick={toggleSortDirection}>
                    {
                      sorting.sortDirection === 'ASC' ?
                        <BiSortUp size={20} color="#4b3f63" /> :
                        <BiSortDown size={20} color="#4b3f63" />
                    }
                  </button>
                </>
              }
            </div>
            <div className="similar-publications__content">
              {
                getSortedPublications().map(p => (
                  <SimilarPublicationItem
                    key={p.id}
                    {...p}
                  />
                ))
              }
            </div>
          </>
        }
        {
          similarPublications && similarPublications.length === 0 &&
          <div className="similar-publications__no-data">
            There is no data to display
          </div>
        }
        {
          error && !loading &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            {error}
          </div>
        }
        <Loader isLoading={loading} />
      </div>
    </div>
  );
};

SimilarPublications.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    similarPublications: getSimilarPublications(state),
    loading: getPublicationDetailsLoadingSelector(state),
    error: getPublicationDetailsErrorSelector(state),
  };
}

export default connect(
  mapStateToProps,
  null
)(SimilarPublications);
