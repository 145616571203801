import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sound from 'react-sound';
import classNames from 'classnames';
// Components
import Loader from '../../../../../common/Loader/Loader';
// Store
import * as SELECTORS from './store/selectors';
import * as ACTIONS from './store/reducer';
// Sound
import sound from './sound.mp3';
// Styles
import './styles.scss';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  selectedGene: PropTypes.instanceOf(Object),
  diseasePredictionIsActive: PropTypes.bool,
  handleDiseasePrediction: PropTypes.func,
  getIndicationFinderDiseases: PropTypes.func,
  resetIndicationFinderDiseases: PropTypes.func,
};

const ResultDiseaseBlock = (props) => {
  const {
    data,
    loading,
    selectedGene,
    diseasePredictionIsActive,
    handleDiseasePrediction,
    getIndicationFinderDiseases,
    resetIndicationFinderDiseases,
  } = props;
  const [soundStatus, setSoundStatus] = useState('STOPPED');
  const [selectedDisease, setSelectedDisease] = useState({ name: '', score: '', rank: '' });
  const resultDiseaseRefs = [useRef(null), useRef(null), useRef(null)];

  const renderData = useMemo(() => data.reduce((acc, item) => {
    acc.diseases.push(item.description);
    acc.scores.push(item.score.toFixed(2));
    acc.ranks.push(item.ranking);
    return acc;
  }, { diseases: [], scores: [], ranks: [] }), [data]);

  const isRenderDataExist = useMemo(() => !loading && Object.values(renderData).some(i => i.length !== 0), [renderData, loading]);

  const resetSelectedDisease = () => {
    setSelectedDisease({ name: '', score: '', rank: '' });
  };

  const handleSettingDiseaseSelection = (name, score, rank) => {
    setTimeout(() => {
      setSelectedDisease({ name, score, rank });
      setSoundStatus('STOPPED');
    }, 3000);
  };

  const triggerRotation = () => {
    function setTop(top) {
      resultDiseaseRefs.forEach((ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.style.top = `${top}px`;
      });
    }
    const options = resultDiseaseRefs[0].current.children;
    const randomOption = Math.floor(
      Math.random() * data.length
    );
    const choosenOption = options[randomOption];
    setTop(-choosenOption.offsetTop + 2);
    const { description, score, ranking } = data[randomOption];
    handleSettingDiseaseSelection(description, score.toFixed(2), ranking);
  };

  useEffect(() => {
    if (data) resetIndicationFinderDiseases();
    getIndicationFinderDiseases(selectedGene);
  }, [selectedGene]);

  useEffect(() => {
    if (diseasePredictionIsActive) {
      setSoundStatus('PLAYING');
      resetSelectedDisease();
      triggerRotation();

      handleDiseasePrediction();
    }
  }, [diseasePredictionIsActive]);

  useEffect(() => {
    if (isRenderDataExist) {
      resetSelectedDisease();
      const { diseases, scores, ranks } = renderData;

      setSoundStatus('PLAYING');
      handleSettingDiseaseSelection(diseases[0], scores[0], ranks[0]);
    }
  }, [renderData, isRenderDataExist]);

  const controlClassNames = (dataType, value) => {
    const getClassNames = (isMoving, isSelected) => classNames({
      'disease-block': true,
      'disease-block--moving': isMoving,
      'disease-block--selected': isSelected,
    });

    return getClassNames(!selectedDisease[dataType], value === selectedDisease[dataType]);
  };

  return (
    <div className="result-disease-section">
      <Loader isLoading={loading} />
      {
        isRenderDataExist &&
        <>
          <div className="result-disease-section__block-wrapper result-disease-section__block-wrapper--disease">
            <span className="result-disease-section__title">disease / phenotype term</span>
            <div className="result-disease-block result-disease-block--disease">
              <div className="result-disease-block__container" ref={resultDiseaseRefs[0]}>
                {renderData.diseases.map((disease, i) => (
                  <div className={controlClassNames('name', disease)} key={i}>
                    <span
                      title={disease}
                      className="disease-block__text"
                    >
                      {disease}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="result-disease-section__block-wrapper result-disease-section__block-wrapper--score">
            <span className="result-disease-section__title">score</span>
            <div className="result-disease-block result-disease-block--score">
              <div className="result-disease-block__container" ref={resultDiseaseRefs[1]}>
                {renderData.scores.map((score, i) => (
                  <div className={controlClassNames('score', score)} key={i}>
                    <span className="disease-block__text">{score}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="result-disease-section__block-wrapper result-disease-section__block-wrapper--rank">
            <span className="result-disease-section__title">rank</span>
            <div className="result-disease-block result-disease-block--rank">
              <div className="result-disease-block__container" ref={resultDiseaseRefs[2]}>
                {renderData.ranks.map((rank, i) => (
                  <div className={controlClassNames('rank', rank)} key={i}>
                    <span className="disease-block__text">{rank}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      }
      <Sound
        url={sound}
        autoLoad={true}
        playStatus={soundStatus}
      />
    </div>
  );
};

ResultDiseaseBlock.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getIndicationFinderDiseasesSelector(state),
    loading: SELECTORS.getIndicationFinderDiseasesLoadingSelector(state),
    error: SELECTORS.getIndicationFinderDiseasesErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIndicationFinderDiseases(data2) {
      dispatch(ACTIONS.getIndicationFinderDiseasesAction(data2));
    },
    resetIndicationFinderDiseases(data) {
      dispatch(ACTIONS.resetIndicationFinderDiseasesResetAction(data));
    },
  };
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ResultDiseaseBlock));
