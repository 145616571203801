import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3js from 'd3';

// Components
import NoData from '../../../common/NoData/NoData';
// Constants
import {
  legendData,
  CitedChartConfig,
  CitedByChartConfig,
} from './constants';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  contatinerWidth: PropTypes.number,
  openShortPubDetails: PropTypes.func,
};

const mousePosition = { x: null, y: null };

const PublicationCitesChart = (props) => {
  let activeDot;
  const {
    data,
    contatinerWidth,
    openShortPubDetails,
  } = props;
  const gap = 100;
  const citedRef = useRef(null);
  const citedByRef = useRef(null);

  const getMousePosition = (e) => {
    mousePosition.x = navigator.userAgent.indexOf('Firefox') !== -1 ? e.layerX : e.offsetX;
    mousePosition.y = navigator.userAgent.indexOf('Firefox') !== -1 ? e.layerY : e.offsetY;
  };

  useEffect(() => {
    if (citedRef.current) {
      citedRef.current.addEventListener('mousemove', getMousePosition);
    }
    if (citedByRef.current) {
      citedByRef.current.addEventListener('mousemove', getMousePosition);
    }
    return () => {
      if (citedRef.current) {
        citedRef.current.removeEventListener('mousemove', getMousePosition);
      }
      if (citedByRef.current) {
        citedByRef.current.removeEventListener('mousemove', getMousePosition);
      }
    };
  }, [getMousePosition, citedRef, citedByRef]);

  function getChartsWrapWidth(domains) {
    const { yearsCount } = domains;
    const citedWidth = gap * yearsCount.cited;
    const citedByWidth = gap * yearsCount.citedBy;
    const wHalf = contatinerWidth / 2;

    if (citedWidth > wHalf || citedByWidth > wHalf) {
      return contatinerWidth;
    }
    return wHalf;
  }

  function drawCitedChart(domain, chartData, config, ref, wrapWidth) {
    const { yearsCount } = domain;
    const {
      selector,
      domainXKey,
      fixedSelector,
    } = config;

    const chartDomains = {
      x: domain.x[domainXKey],
      y: domain.y,
      z: domain.z,
    };
    const margin = {
      top: 70,
      left: 40,
      right: 40,
      bottom: 100,
    };

    d3js.select(`#${selector} > *`).remove();
    d3js.select(`#${fixedSelector} > *`).remove();

    const chartHeight = 600;
    const tooltipWidth = 200;
    const chartWidth = Math.max((gap * yearsCount[domainXKey]), wrapWidth);
    const height = chartHeight - margin.top - margin.bottom;
    const width = chartWidth - margin.left - margin.right;

    // Tooltips
    const tooltipTemplate = d => (`
      <div class="cites-tooltip__wrap">
        <div>Publications count: ${d.ids.length}</div>
        <div>Publications year: ${new Date(d.publicationDate).getFullYear()}</div>
        <div>Publications impact factor: ${d.impactFactor}</div>
        <div>Total number of citations: ${d.numberOfCitations}</div>
      </div>
    `);

    const tooltip = d3js.select(`#${selector}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'chart-tooltip');

    function mouseOver() {
      tooltip
        .style('opacity', 1);
      d3js.select(this)
        .attr('stroke', 'dodgerblue');
    }

    function mouseMove(d) {
      const left = mousePosition.x;
      const top = mousePosition.y;
      let transformX = 0;
      let transformY = 0;

      if (left > width / 2) {
        transformX = -(tooltipWidth / 2);
      }
      if (top > height / 2) {
        transformY = -50;
      } else {
        transformY = 50;
      }

      tooltip
        .html(tooltipTemplate(d))
        .style('width', `${tooltipWidth}px`)
        .style('left', `${left}px`)
        .style('top', `${top}px`)
        .style('transform', `translate(${transformX}%, ${transformY}%)`);
    }

    function mouseLeave() {
      tooltip
        .style('opacity', 0);
      d3js.select(this)
        .attr('stroke', 'none');
    }

    function onClick(pubData) {
      if (activeDot) {
        const color = d3js.select(activeDot).attr('color');
        d3js.select(activeDot).style('fill', color);
      }
      d3js.select(this)
        .style('fill', '#76649d');
      activeDot = this;
      openShortPubDetails(pubData);
    }

    // Fixed Y axis
    const svg2 = d3js.select(`#${fixedSelector}`)
      .append('svg')
      .attr('width', margin.left + 1)
      .attr('height', chartHeight)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const y2 = d3js.scaleSymlog()
      .domain([chartDomains.y.min, chartDomains.y.max])
      .range([height, 0]);

    svg2.append('g')
      .attr('transform', 'translate(0,0)')
      .call(d3js.axisLeft(y2));

    // Chart
    const svg = d3js.select(`#${selector}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', chartHeight)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3js.scaleTime()
      .domain([new Date(`${chartDomains.x.min - 1}`), new Date(`${chartDomains.x.max + 1}`)])
      .range([0, width]);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3js
          .axisBottom(x)
          .ticks(d3js.timeYear)
          .tickFormat(d3js.timeFormat('%Y'))
      );

    xAxis.selectAll('text')
      .attr('x', 20)
      .attr('y', 5)
      .style('cursor', 'default')
      .style('text-anchor', 'start')
      .attr('transform', 'rotate(45)');

    const y = d3js.scaleSymlog()
      .domain([chartDomains.y.min, chartDomains.y.max])
      .range([height, 0]);

    const z = d3js.scaleLinear()
      .domain([chartDomains.z.min, chartDomains.z.max])
      .rangeRound([4, 50]);

    svg.append('g')
      .selectAll('dot')
      .data(chartData)
      .enter()
      .append('circle')
      .attr('class', 'bubbles')
      .attr('cx', d => x(new Date(d.publicationDate)))
      .attr('cy', d => y(d.impactFactor))
      .attr('r', d => z(d.numberOfCitations))
      .attr('color', d => d.color)
      .attr('opacity', d => d.opacity)
      .style('fill', d => d.color)
      .style('stroke', 'black')
      .style('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMove)
      .on('mouseleave', mouseLeave)
      .on('click', onClick);

    if (chartWidth > contatinerWidth) {
      // eslint-disable-next-line no-param-reassign
      ref.current.style.boxShadow = 'inset -8px 0 6px -5px #33333338';
      // eslint-disable-next-line no-param-reassign
      ref.current.scrollLeft = chartWidth;
    }
    // eslint-disable-next-line no-param-reassign
    ref.current.scrollLeft = chartWidth;
  }

  function drawLegend() {
    d3js.select('#cited-chart-legend > *').remove();

    const radius = 20;
    const itemWidth = 80;
    const legendWidth = itemWidth * 3;
    const legendHeight = 70;
    const strokeWidth = 1;

    const getItemCenter = (d, index) => ((itemWidth * index) + (itemWidth / 2));

    const svg = d3js.select('#cited-chart-legend')
      .append('svg')
      .attr('width', legendWidth)
      .attr('height', legendHeight);

    const legend = svg.selectAll('.legend')
      .data(legendData)
      .enter()
      .append('g');

    legend.append('circle')
      .attr('cx', getItemCenter)
      .attr('cy', radius + strokeWidth)
      .attr('r', radius)
      .style('stroke', 'black')
      .style('fill', d => d.color);

    legend.append('text')
      .text(d => d.name)
      .style('font-size', '15px')
      .attr('text-anchor', 'middle')
      .attr('x', getItemCenter)
      .attr('y', 60);
  }

  useEffect(() => {
    if (data) {
      const { domains, citedData, citedByData } = data;
      const wrapWidth = getChartsWrapWidth(domains);
      if (citedData.length) {
        drawCitedChart(domains, citedData, CitedChartConfig, citedRef, wrapWidth);
      }
      if (citedByData.length) {
        drawCitedChart(domains, citedByData, CitedByChartConfig, citedByRef, wrapWidth);
      }
      drawLegend();
    }
  }, [data]);

  return (
    <div className="cites-charts__content" style={{ width: `${contatinerWidth}px` }}>
      <div className="cites-charts__block">
        <div className="cites-charts__title">
          Cited chart
        </div>
        {
          data.citedData.length !== 0 ?
            <div className="chart-wrap">
              <div
                id={CitedChartConfig.fixedSelector}
                className="chart-wrap__fixed-block chart-wrap__fixed-block_left"
              />
              <div
                ref={citedRef}
                id={CitedChartConfig.selector}
                className="cited-chart"
              />
            </div> :
            <NoData
              show={true}
              customClassName="cites-charts__no-data"
            />
        }
      </div>
      <div className="cites-charts__block">
        <div className="cites-charts__title">
          Cited by chart
        </div>
        {
          data.citedByData.length !== 0 ?
            <div className="chart-wrap">
              <div
                id={CitedByChartConfig.fixedSelector}
                className="chart-wrap__fixed-block chart-wrap__fixed-block_left"
              />
              <div
                ref={citedByRef}
                id={CitedByChartConfig.selector}
                className="cited-by-chart"
              />
            </div> :
            <NoData
              show={true}
              customClassName="cites-charts__no-data"
            />
        }
      </div>
      <div id="cited-chart-legend" className="cited-chart-legend" />
      <div className="cited-chart-axis-legend">
        <span>X axis - Year</span>
        <span>Y axis - Impact factor</span>
      </div>
    </div>
  );
};

PublicationCitesChart.propTypes = propTypes;

export default PublicationCitesChart;
