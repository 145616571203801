import { put, call, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Store
import * as a from './actions';
// Utils
import { checkProjectTab } from '../../Projects/ProjectsTabs/utils';

function* getFindRelatedSetDataWorker({ payload }) {
  try {
    const { setId, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = !isProjectTab ? Api.getSimpleOrEffectSetContent : Api.getProjectSet;
    const requestData = !isProjectTab ? setId : { projectId, setId };
    const { data } = yield call(apiToCall, requestData);
    yield put(a.setFindRelatedSetDataAction({
      ...data,
      tags: data.tags.map((name, id) => ({ id, name })),
    }));
  } catch (e) {
    yield put(a.throwFindRelatedSetDataAction(e.message));
  }
}

function* editSetSaga() {
  yield takeLatest(a.getFindRelatedSetDataAction, getFindRelatedSetDataWorker);
}

export default editSetSaga;