import { fromJS } from 'immutable';

import {
  SORT,
} from '../../common/SimpleTable/constants';

import {
  CONCEPT_DETAILS_SUCCEEDED,
  CONCEPT_DETAILS_FAILED,
  CONCEPT_DETAILS_LOADING,
  CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED,
  CONCEPT_CATEGORY_ANALYSIS_FAILED,
  CONCEPT_CATEGORY_ANALYSIS_LOADING,
  CONCEPT_CATEGORY_ANALYSIS_RESET,
  CONCEPT_CATEGORY_ANALYSIS_NODES_SUCCEEDED,
  CONCEPT_CATEGORY_ANALYSIS_NODES_FAILED,
  CONCEPT_CATEGORY_ANALYSIS_NODES_LOADING,
  CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE,
  CONCEPT_DETAILS_RESET,
  CONCEPT_DETAILS_REQUESTED,
  CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
} from './constants';

const initialState = fromJS({
  lastVisitedConceptId: '',
  data: {},
  error: null,
  loading: true,
  categoryAnalysis: {
    data: {},
    error: null,
    loading: false,
  },
  categoryAnalysisNodes: {
    data: {
      content: [],
    },
    error: null,
    loading: false,
  },
});

const conceptDetails = (state = initialState, action) => {
  switch (action.type) {
    case CONCEPT_DETAILS_SET_LAST_VISITED_CONCEPT_ID:
      return state.set('lastVisitedConceptId', action.data);

    case CONCEPT_DETAILS_REQUESTED:
      return state.set('loading', true);

    case CONCEPT_DETAILS_SUCCEEDED:
      return state.merge(fromJS({ data: action.data, error: null, loading: false }));

    case CONCEPT_DETAILS_FAILED:
      return state.merge(fromJS({ data: {}, error: action.message, loading: false }));

    case CONCEPT_DETAILS_LOADING:
      return state.update('loading', () => true);

    case CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED:
      return state.update('categoryAnalysis', categoryAnalysis => (
        categoryAnalysis.merge(fromJS({ data: action.data, error: null, loading: false }))
      ));

    case CONCEPT_CATEGORY_ANALYSIS_FAILED:
      return state.update('categoryAnalysis', categoryAnalysis => (
        categoryAnalysis.merge(fromJS({ data: {}, error: action.message, loading: false }))
      ));

    case CONCEPT_CATEGORY_ANALYSIS_LOADING:
      return state.update('categoryAnalysis', categoryAnalysis => (
        categoryAnalysis.update('loading', () => true)
      ));

    case CONCEPT_CATEGORY_ANALYSIS_RESET:
      return state
        .update('categoryAnalysis', () => initialState.get('categoryAnalysis'))
        .update('categoryAnalysisNodes', () => initialState.get('categoryAnalysisNodes'));

    case CONCEPT_CATEGORY_ANALYSIS_NODES_SUCCEEDED:
      return state.update('categoryAnalysisNodes', categoryAnalysisNodes => (
        categoryAnalysisNodes.merge(fromJS({ data: action.data, error: null, loading: false }))
      ));

    case CONCEPT_CATEGORY_ANALYSIS_NODES_FAILED:
      return state.update('categoryAnalysisNodes', categoryAnalysisNodes => (
        categoryAnalysisNodes.merge(fromJS({
          data: initialState.getIn(['categoryAnalysisNodes', 'data']),
          error: action.message,
          loading: false,
        }))
      ));

    case CONCEPT_CATEGORY_ANALYSIS_NODES_LOADING:
      return state.update('categoryAnalysisNodes', categoryAnalysisNodes => (
        categoryAnalysisNodes.update('loading', () => true)
      ));

    case CONCEPT_CATEGORY_ANALYSIS_NODES_TABLE + SORT:
      return state.update('categoryAnalysisNodes', categoryAnalysisNodes => (
        categoryAnalysisNodes.update('sorting', () => fromJS(action.data))
      ));

    case CONCEPT_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
};

export default conceptDetails;
