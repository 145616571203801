import React from 'react';
import PropTypes from 'prop-types';
import * as d3js from 'd3';

import './index.scss';

const propTypes = {
  children: PropTypes.instanceOf(Object),
  closeZoom: PropTypes.func,
  zoomId: PropTypes.string,
};

class Zoom extends React.Component {
  constructor(props) {
    super(props);
    this.svg = null;
    this.g = null;
    this.zoom = null;
  }

  componentDidMount() {
    this.initZoomSVG();
  }

  initZoomSVG = () => {
    const { zoomId } = this.props;
    this.svg = d3js.select(`#${zoomId}`).select('svg');
    this.g = this.svg.select('g');
    const zoomed = () => {
      this.g.attr('transform', d3js.event.transform);
    };

    this.zoom = d3js.zoom()
      .scaleExtent([1, 40])
      .on('zoom', zoomed);

    this.svg.call(this.zoom);
  };

  plusClickHandler = () => {
    this.zoom
      .scaleBy(this.svg.transition().duration(450), 1.3);
  };

  minusClickHandler = () => {
    this.zoom
      .scaleBy(this.svg.transition().duration(450), 1 / 1.3);
  };

  render() {
    const { closeZoom, children, zoomId } = this.props;
    return (
      <div className="zoom">
        <div id={zoomId} className="zoom__content">
          { children }
        </div>
        <div className="zoom-controls">
          <button
            className="zoom-controls__btn zoom-controls__btn_close"
            onClick={closeZoom}
          />
          <button
            className="zoom-controls__btn zoom-controls__btn_plus"
            onClick={this.plusClickHandler}
          />
          <button
            className="zoom-controls__btn zoom-controls__btn_minus"
            onClick={this.minusClickHandler}
          />
        </div>
      </div>
    );
  }
}

Zoom.propTypes = propTypes;

export default Zoom;

