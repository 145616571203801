import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

// Components
import Search from '../components/Search/Search';
import SetAnalysis from '../components/Analytics/common/SetAnalysis/SetAnalysis';
import EditSet from '../components/Sets/EditSet/EditSet';
import SaveAsSet from '../components/Sets/SaveAsSet/SaveAsSet';
import ConceptDetails from '../components/Concept/ConceptDetails/ConceptDetails';
import GeneDetails from '../components/GeneDetails/GeneDetails';
import PublicationDetails from '../components/PublicationDetails/PublicationDetails';
import SetResultPage from '../components/SetResult/SetResultPage/SetResultPage';
import SetsManagement from '../components/Sets/SetsManagement/SetsManagement';
import SetAnalysisManagement from '../components/Analytics/SetAnalysisManagement/SetAnalysisManagement';
import GeneDiseasePage from '../components/GeneDisease/GeneDiseasePage/GeneDiseasePage';
import ComplexSetView from '../components/Sets/ComplexSetView/ComplexSetView';
import PathDetails from '../components/Analytics/NetworkAnalysis/PathDetails/PathDetails';
import Workflows from '../components/Workflows/Workflows';
import SetVennDiagramPage from '../components/Sets/SetVennDiagram/SetVennDiagram';
import CompareSetsPage from '../components/Sets/CompareSetsPage/CompareSetsPage';
import BuildHeatmap from '../components/Sets/BuildHeatmap/BuildHeatmap';
import RelationMapPage from '../components/RelationMap/RelationMapPage/RelationMapPage';
import RelationMapManagement from '../components/RelationMap/RelationMapManagement/RelationMapManagement';
import ProjectsManagement from '../components/Projects/ProjectsManagement/ProjectsManagement';
import PublicationsManagement from '../components/Publications/PublicationsManagement/PublicationsManagement';
import SearchWithSetsPage from '../components/Sets/SearchWithSetsPage/SearchWithSetsPage';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import FindRelated from '../components/FindRelated/FindRelated';
import RankSelection from '../components/RankSelection/RankSelection';
import CreateSetPage from '../components/Sets/CreateSetPage/CreateSetPage';
import ConceptToConceptIndirectChart from '../components/common/ConceptToConceptIndirectChart/ConceptToConceptIndirectChart';
import AntibodiesPage from '../components/AntibodiesPage/AntibodiesPage';
// Constants
import { navigation } from './constants';
import { RELATIVE_PATH } from '../constantsCommon';

const Private = () => {
  const navigate = useNavigate();
  const [prevPath, setPrevPath] = useLocalStorage('locationHistory');

  useEffect(() => {
    if (prevPath) {
      navigate(`${RELATIVE_PATH}${prevPath}`);
      setPrevPath(null);
    }
  }, []);

  return (
    <Routes>
      <Route element={<LayoutWrapper />}>
        <Route
          path={navigation.research}
          element={<Search />}
        />
        <Route
          path={navigation.portal}
          element={<Search />}
        />
        <Route
          path={navigation.projects}
          element={<ProjectsManagement />}
        />
        <Route
          path={navigation.createSetPage}
          element={<CreateSetPage />}
        />
        <Route
          path={navigation.sets}
          element={<SetsManagement />}
        />
        <Route
          path={navigation.addSet}
          element={<SaveAsSet />}
        />
        <Route
          path={navigation.editSet}
          element={<EditSet />}
        />
        <Route
          path={navigation.complexSet}
          element={<ComplexSetView />}
        />
        <Route
          path={navigation.analytics}
          element={<SetAnalysisManagement />}
        />
        <Route
          path={navigation.setAnalysis}
          element={<SetAnalysis />}
        />
        <Route
          path={navigation.workflows}
          element={<Workflows />}
        />
        <Route
          path={navigation.conceptDetails}
          element={<ConceptDetails />}
        />
        <Route
          path={navigation.geneDetails}
          element={<GeneDetails />}
        />
        <Route
          path={navigation.geneDisease}
          element={<GeneDiseasePage />}
        />
        <Route
          path={navigation.publicationDetails}
          element={<PublicationDetails />}
        />
        <Route
          path={navigation.setResult}
          element={<SetResultPage />}
        />
        <Route
          path={navigation.setsMerge}
          element={<SetVennDiagramPage />}
        />
        <Route
          path={navigation.heatmap}
          element={<CompareSetsPage />}
        />
        <Route
          path={navigation.buildHeatmap}
          element={<BuildHeatmap />}
        />
        <Route
          path={navigation.pathDetails}
          element={<PathDetails />}
        />
        <Route
          path={navigation.references}
          element={<PublicationsManagement />}
        />
        <Route
          path={navigation.relationMapManagement}
          element={<RelationMapManagement />}
        />
        <Route
          path={navigation.relationMap}
          element={<RelationMapPage />}
        />
        <Route
          path={navigation.searchWithSets}
          element={<SearchWithSetsPage />}
        />
        <Route
          path={navigation.findRelated}
          element={<FindRelated />}
        />
        <Route
          path={navigation.rankSelection}
          element={<RankSelection />}
        />
        <Route
          path={navigation.conceptToConceptIndirectChart}
          element={<ConceptToConceptIndirectChart />}
        />
        <Route
          path={navigation.antibodiesPage}
          element={<AntibodiesPage />}
        />
        <Route
          path="*"
          element={<Navigate to={navigation.portal} replace={true} />}
        />
      </Route>
    </Routes>
  );
};

export default Private;
