import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Loader from '../../common/Loader/Loader';
import Error from '../../common/Error/Error';
import BarChartDiagram from '../BarChartDiagram/BarChartDiagram';
// Store
import { getBarChartDataAction } from './store/actions';
import * as SELECTORS from './store/selectors';
// Styles
import './BarChart.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  source: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  yAxisLabel: PropTypes.string,
  dataNameKey: PropTypes.string,
  getBarChartData: PropTypes.func,
};

const BarChart = (props) => {
  const {
    data,
    gene,
    source,
    error,
    loading,
    yAxisLabel,
    dataNameKey,
    getBarChartData,
  } = props;

  useEffect(() => {
    getBarChartData();
  }, []);

  return (
    <div className="bar-chart-popup">
      <div className="row">
        <div className="flex-grid">
          <div className="col-1 text-left genes-detail-title">
            Chart
          </div>
        </div>
        <div className="flex-grid justify-content-center">
          {
            !loading && !error && data &&
            <div className="row">
              <BarChartDiagram
                geneName={gene.geneName}
                dataKey="median"
                yAxisLabel={yAxisLabel}
                dataNameKey={dataNameKey}
                data={data}
                source={source}
              />
            </div>
          }
          <Loader isLoading={loading && !error} />
          <Error
            error={error}
            show={!!error}
            customClassName="text-center error-text"
          />
        </div>
      </div>
    </div>
  );
};

BarChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getBarChartDataSelector(state),
    loading: SELECTORS.getBarChartLoadingSelector(state),
    error: SELECTORS.getBarChartErrorSelector(state),
    gene: SELECTORS.getBarChartGeneSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBarChartData() {
      dispatch(getBarChartDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BarChart);
