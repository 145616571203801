import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getGeneDetailsInternalLinksAction = createAction('@@geneDetails/GET_INTERNAL_LINKS');
export const setGeneDetailsInternalLinksAction = createAction('@@geneDetails/SET_INTERNAL_LINKS');
export const throwGeneDetailsInternalLinksErrorAction = createAction('@@geneDetails/INTERNAL_LINKS_ERROR');

const initialState = fromJS({
  data: null,
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [getGeneDetailsInternalLinksAction]: (state) =>
      state
        .update('error', () => null)
        .update('loading', () => true),
    [setGeneDetailsInternalLinksAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('loading', () => false),
    [throwGeneDetailsInternalLinksErrorAction]: (state, { payload }) =>
      state
        .update('error', () => payload)
        .update('loading', () => false),
  },
  initialState
);

export default reducer;
