import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as a from './actions';
// Constants
import { graphFilterOptions } from '../constants';

const initialState = fromJS({
  metaData: {
    data: [],
    uniqueCells: [],
    backgroundGraphs: [],
    error: '',
    loading: false,
  },
  geneData: {
    data: [],
    zerosData: {},
    backgroundGraphs: [],
    measureExtremes: [],
    error: '',
    loading: false,
  },
  dataFilters: {
    cellType: {
      options: [],
      selectedOptions: [],
    },
    patient: {
      options: [],
      selectedOptions: [],
    },
    condition: {
      options: [],
      selectedOptions: [],
    },
  },
  selectedDataset: {},
  measures: [],
  markers: [],
  colorsData: {},
  selectedGene: null,
  colourByOptions: [],
  selectedFilterValue: graphFilterOptions[0],
});

const reducer = handleActions(
  {
    [a.setSelectedDatasetAction]: (state, { payload }) =>
      initialState
        .update('selectedDataset', () => fromJS(payload))
        .update('selectedGene', () => state.get('selectedGene')),
    [a.getColorsDataAction]: state =>
      state
        .update('colorsData', () => fromJS({})),
    [a.setColorsDataAction]: (state, { payload }) =>
      state
        .update('colorsData', () => fromJS(payload.colors))
        .update('markers', () => fromJS(payload.markers)),
    [a.setMetaDataActiveFilterValueAction]: (state, { payload }) =>
      state
        .update('selectedFilterValue', () => fromJS(payload)),
    [a.setMetaDataFiltersAction]: (state, { payload }) =>
      state
        .updateIn(['dataFilters', payload.value, 'selectedOptions'], () => fromJS(payload.option)),
    [a.setMetaDataFiltersOptionsAction]: (state, { payload }) =>
      state
        .update('colourByOptions', () => fromJS(payload.colourByOptions))
        .updateIn(['dataFilters', 'cellType', 'options'], () => fromJS(payload.filtersData.cellType))
        .updateIn(['dataFilters', 'patient', 'options'], () => fromJS(payload.filtersData.patient))
        .updateIn(['dataFilters', 'condition', 'options'], () => fromJS(payload.filtersData.condition)),
    [a.getMetaDataAction]: state =>
      state
        .updateIn(['metaData', 'loading'], () => true)
        .updateIn(['metaData', 'backgroundGraphs'], () => fromJS([])),
    [a.setMetaDataBackgroundsAction]: (state, { payload }) =>
      state
        .updateIn(['metaData', 'backgroundGraphs'], () => fromJS(payload)),
    [a.setMetaDataAction]: (state, { payload }) =>
      state
        .updateIn(['metaData', 'data'], () => fromJS(payload.data))
        .updateIn(['metaData', 'error'], () => '')
        .updateIn(['metaData', 'uniqueCells'], () => fromJS(payload.uniqueCells))
        .update('measures', () => fromJS(payload.measure)),
    [a.throwMetaDataErrorAction]: (state, { payload }) =>
      state
        .updateIn(['metaData', 'loading'], () => false)
        .updateIn(['metaData', 'error'], () => payload),
    [a.toggleMetaDataLoadingAction]: (state, { payload }) =>
      state
        .updateIn(['metaData', 'loading'], () => payload),
    [a.setSelectedGeneAction]: (state, { payload }) =>
      state
        .update('selectedGene', () => fromJS(payload)),
    [a.getGeneDataAction]: state =>
      state
        .updateIn(['geneData', 'loading'], () => true),
    [a.setGeneDataAction]: (state, { payload }) =>
      state
        .updateIn(['geneData', 'data'], () => fromJS(payload.geneData))
        .updateIn(['geneData', 'zerosData'], () => fromJS(payload.zerosData))
        .updateIn(['geneData', 'loading'], () => false)
        .updateIn(['geneData', 'error'], () => '')
        .updateIn(['geneData', 'backgroundGraphs'], () => fromJS(payload.backgroundGraphs))
        .updateIn(['geneData', 'measureExtremes'], () => fromJS(payload.measureExtremes)),
    [a.throwGeneDataErrorAction]: (state, { payload }) =>
      state
        .updateIn(['geneData', 'loading'], () => false)
        .updateIn(['geneData', 'error'], () => payload),
    [a.clearMetaDataAction]: state => state
      .updateIn(['metaData', 'data'], () => initialState.getIn(['metaData', 'data']))
      .updateIn(['metaData', 'uniqueCells'], () => initialState.getIn(['metaData', 'uniqueCells']))
      .updateIn(['metaData', 'backgroundGraphs'], () => initialState.getIn(['metaData', 'backgroundGraphs'])),
    [a.resetSingleCellDataAction]: () => initialState,
  },
  initialState
);

export default reducer;

