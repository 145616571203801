export const getColumnName = (field) => {
  const columns = {
    geneName: 'Gene name',
    classifications: 'Classification',
    differentialExpression: 'Differential expression',
    associationScore: 'Association Score',
    geneVariation: 'Genetic Variation',
    literatureSearch: 'Literature references',
    compoundScore: 'Known Compounds (<100nM)',
    geneticInteractions: 'Protein interactions',
    openTargetsScore: 'Open Targets Score',
    druggableStructure: 'Druggable structure',
    signalingDomain: 'Signaling domain',
    intramembrane: 'Intramembrane',
    transMembraneDomain: 'Transmembrane domain',
    secretion: 'Secretion',
    rankingTrialStage: 'Max Clinical Phase',
    count: 'Number of Trials',
    mostFrequentIndications: 'Most Frequent Indication in CT',
  };

  return columns[field] || field;
};

export const getColumnHelpText = (field) => {
  const columns = {
    geneName: 'Gene name',
    classifications: 'Classification',
    differentialExpression: 'Based on Expression Atlas Experiments',
    associationScore: 'An objective, quantitative measure of the relationship between two biological concepts considering indirect connections with shared biological concepts, such as proteins, phenotypes, pathways, etc.',
    geneVariation: 'Number of variants associated with disease',
    literatureSearch: 'Literature references',
    compoundScore: 'Number of compounds with affinity < 100 nM',
    geneticInteractions: 'Number of Protein Interactions or Regulatory Associations',
    openTargetsScore: 'Assocation score as calculated by Open Targets',
    druggableStructure: 'Annotation from Human Protein Atlas',
    signalingDomain: 'Signaling domain',
    intramembrane: 'Intramembrane',
    transMembraneDomain: 'Transmembrane domain',
    secretion: 'Secretion',
  };

  return columns[field] || field;
};
