import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  func,
  string,
  instanceOf,
  object,
  bool,
} from 'prop-types';

// Components
import Spinner from '../../common/Spinner/Spinner';
import Checkbox from '../../common/Inputs/Checkbox/Checkbox';
// Store
import {
  closeAddLinkedConcepts,
  initFilterCategories,
  initFilterPredicates,
  initFilterTaxonomies,
  openCategory,
  openRelation,
  closeCategory,
  closeRelation,
  checkRelation,
  checkCategoryType,
  checkTaxonomy,
  checkCategory,
  initSemanticTypes,
  semanticTypesLoading,
  checkAllCategoryTypes,
  findRelatedConcepts,
  checkRelatedConcept,
  sort,
  filter,
  addColumn,
  checkAllRelatedConcepts,
  checkRelationType,
  checkAllRelationType,
} from './actions';
import {
  getCategoriesForFilter,
  getRelationsForFilter,
  getTaxonomiesForFilter,
  getRelationsLoading,
  getTaxonomiesLoading,
  getCategoriesLoading,
  getRelatedConcepts,
  getRelatedConceptsLoading,
  getSorting,
  getCheckedRelatedConcepts,
  getFilter,
} from './selectors';
// Styles
import './SetResultPageAddConcepts.css';

const propTypes = {
  initFilterCategories: func,
  initFilterPredicates: func,
  initFilterTaxonomies: func,
  closeCategory: func,
  openCategory: func,
  semanticTypesLoading: func,
  initSemanticTypes: func,
  checkCategory: func,
  checkAllCategoryTypes: func,
  checkCategoryType: func,
  checkRelation: func,
  checkRelationType: func,
  checkTaxonomy: func,
  checkAllRelationType: func,
  checkAllRelatedConcepts: func,
  closeAddLinkedConcepts: func,
  filterValue: string,
  addColumn: func,
  openRelation: func,
  closeRelation: func,
  sort: func,
  filter: func,
  findRelatedConcepts: func,
  checkRelatedConcept: func,
  categoriesLoading: bool,
  taxonomiesLoading: bool,
  relationsLoading: bool,
  relatedConceptsLoading: bool,
  // Specify proper proptypes after investigation
  categories: instanceOf(object),
  taxonomies: instanceOf(object),
  relations: instanceOf(object),
  relatedConcepts: instanceOf(object),
  checkedRelatedConcepts: instanceOf(object),
  sorting: instanceOf(object),
};

class SetResultPageAddConcepts extends React.Component {
  componentDidMount() {
    this.props.initFilterCategories();
    this.props.initFilterPredicates();
    this.props.initFilterTaxonomies();
  }

  openCloseCategoryCb = (category) => {
    if (category.opened) {
      this.props.closeCategory(category.id);
    } else {
      this.props.openCategory(category.id);
    }
    /* Init semantic types */
    if (!category.types.length) {
      this.props.semanticTypesLoading(category.id);
      this.props.initSemanticTypes(category);
    }
  };

  checkCategoryCb = (category) => {
    const currentCheckStatus = category.checked;
    this.props.checkCategory(category.id);
    if (category.types.length) {
      this.props.checkAllCategoryTypes({ categoryId: category.id, checked: !currentCheckStatus });
    }
  };

  checkCategoryTypeCb = (category, type) => {
    const newTypesState = Object.assign({}, {
      types: category.types.map((_type) => {
        if (_type.id === type.id) {
          return Object.assign(_type, { checked: !type.checked });
        }
        return _type;
      }),
    });

    const amountOfChecked = newTypesState.types.filter(typeItem => typeItem.checked).length;

    const allChecked = amountOfChecked === category.types.length;
    const anyChecked = !amountOfChecked;

    this.props.checkCategoryType({
      categoryId: category.id,
      typeId: type.id,
    });

    if (allChecked) {
      this.props.checkRelation(category.id);
    }

    if (!allChecked && !anyChecked && category.checked) {
      this.props.checkCategory(category.id);
    }
  };

  checkRelationTypeCb = (relation, predicate) => {
    const newPredicatesState = Object.assign({}, {
      predicates: relation.predicates.map((item) => {
        if (item.id === predicate.id) {
          return Object.assign(item, { checked: !item.checked });
        }
        return item;
      }),
    });

    const amountOfChecked = newPredicatesState.predicates.filter(predicateItem => predicateItem.checked).length;

    const allChecked = amountOfChecked === relation.predicates.length;
    const anyChecked = !amountOfChecked;

    this.props.checkRelationType({
      relationCategoryId: relation.id,
      predicateId: predicate.id,
    });

    if (allChecked) {
      this.props.checkRelation(relation.id);
    }

    if (!allChecked && !anyChecked && relation.checked) {
      this.props.checkRelation(relation.id);
    }
  };

  checkTaxonomyCb = (id) => {
    this.props.checkTaxonomy(id);
  };

  checkRelationCb = (relation) => {
    const currentCheckStatus = relation.checked;
    this.props.checkRelation(relation.id);
    this.props.checkAllRelationType({ relationId: relation.id, checked: !currentCheckStatus });
  };

  checkAllRelatedWithFilter = (checked) => {
    this.props.checkAllRelatedConcepts(checked, this.props.filterValue);
  };

  close = () => {
    this.props.closeAddLinkedConcepts();
  };

  addRelated = () => {
    this.props.addColumn();
  };

  render() {
    const {
      categories,
      taxonomies,
      relations,
      categoriesLoading,
      taxonomiesLoading,
      relationsLoading,
      relatedConcepts,
      relatedConceptsLoading,
      sorting: {
        sortDirection,
      },
      checkedRelatedConcepts,
    } = this.props;

    const isAnyChecked = categories.some(category => category.checked || category.types.some(type => type.checked)) ||
      taxonomies.some(taxonomy => taxonomy.checked) ||
      relations.some(relation => relation.checked || relation.predicates.some(predicate => predicate.checked));

    const allRelatedChecked = relatedConcepts.length > 0 && (relatedConcepts.length === checkedRelatedConcepts.length);

    const disabledAddRelated = classNames({
      'disabled': checkedRelatedConcepts.length === 0,
    });

    const ascClasses = classNames({
      'fa fa-sort-asc': true,
      'active': sortDirection === 'ASC',
    });
    const descClasses = classNames({
      'fa fa-sort-desc': true,
      'active': sortDirection === 'DESC',
    });
    const searchBtnClasses = classNames('btn', {
      'btn-success': isAnyChecked,
    });

    return (
      <div className="set-result-page-add-concepts">
        <div className="set-result-page-add-concepts-header">
          <span className="set-result-page-add-concepts-header-title">
            Add linked concepts
          </span>
          <span className="set-result-page-add-concepts-header-control">
            <i
              onClick={() => { this.close(); }}
              className="fa fa-times"
            />
          </span>
        </div>
        <div className="set-result-page-add-concepts-filters">
          <div className="flex-grid">
            <div className="col-1 set-result-page-add-concepts-filter-category">
              <div className="set-result-page-add-concepts-filter-title">
                Category filter
              </div>
              <div className="set-result-page-add-concepts-filter-category-body">
                { categories.length > 0 && categories.map((category, index) =>
                  (
                    <div className="filter-item" key={index}>
                      <div className="filter-item-header">
                        <div className="category-checkbox">
                          <Checkbox
                            onChange={() => { this.checkCategoryCb(category); }}
                            checked={category.checked}
                          />
                        </div>
                        <div className="filter-item-color-container">
                          <div className="filter-item-color-icon" />
                        </div>
                        <div className="filter-title filter-title-category">{category.categoryName}</div>
                        <div
                          className="filter-category-switcher"
                          onClick={() => { this.openCloseCategoryCb(category); }}
                        >
                          { !category.opened && <i className="fa fa-angle-down" />}
                          { category.opened && <i className="fa fa-angle-up" /> }
                        </div>
                        <div className="filter-category-amount">({category.count})</div>
                      </div>
                      {
                        category.opened &&
                          <div className="filter-item-body">
                            {
                              category.types.length > 0 && !category.loading &&
                              <div>
                                {
                                  category.types.map((type, categoryIndex) => (
                                    <div
                                      className="filter-category-type"
                                      key={categoryIndex}
                                    >
                                      <div className="filter-category-type-checkbox">
                                        <Checkbox
                                          checked={type.checked}
                                          onChange={() => {
                                            this.checkCategoryTypeCb(category, type);
                                          }}
                                        />
                                      </div>
                                      <div className="filter-category-type-name">
                                        {type.semTypeName}
                                      </div>
                                      <div className="filter-category-type-count">
                                        ({type.count})
                                      </div>
                                    </div>
                                  )
                                  )
                                }
                              </div>
                            }
                            <Spinner isLoading={category.loading} containerClassName="text-center" />
                          </div>
                      }
                    </div>
                  )
                )}
                <Spinner isLoading={categoriesLoading} containerClassName="text-center" />
              </div>
            </div>
            <div className="col-1 set-result-page-add-concepts-filter-taxonomy">
              <div className="set-result-page-add-concepts-filter-title">
                Taxonomy filter
              </div>
              <div className="set-result-page-add-concepts-filter-taxonomy-body">
                {
                  taxonomies.length > 0 &&
                  taxonomies.map((taxonomy, index) =>
                    (
                      <div
                        className="filter-item"
                        key={index}
                      >
                        <div className="filter-item-header">
                          <div className="taxonomy-checkbox">
                            <Checkbox
                              checked={taxonomy.checked}
                              onChange={() => { this.checkTaxonomyCb(taxonomy.id); }}
                            />
                          </div>
                          <div className="filter-title">
                            {taxonomy.taxonomyName}
                          </div>
                          <div className="filter-taxonomy-amount">
                            ({taxonomy.count})
                          </div>
                        </div>
                      </div>
                    )
                  )
                }
                <Spinner isLoading={taxonomiesLoading} containerClassName="text-center" />
              </div>
            </div>
            <div className="col-1 set-result-page-add-concepts-filter-relation">
              <div className="set-result-page-add-concepts-filter-title">
                Relation type filter
              </div>
              <div className="set-result-page-add-concepts-filter-relation-body">
                {
                  relations.length > 0 &&
                  relations.map((relation, index) => {
                    let count = 0;
                    relation.predicates.forEach((predicate) => {
                      count += predicate.count;
                    });
                    return (
                      <div className="filter-item" key={index}>
                        <div className="filter-item-header">
                          <div className="relation-checkbox">
                            <Checkbox
                              checked={relation.checked}
                              onChange={() => { this.checkRelationCb(relation); }}
                            />
                          </div>
                          <div className="filter-title">{relation.categoryName}</div>
                          <div className="filter-relation-switcher">
                            {
                              !relation.opened &&
                            <i
                              onClick={() => { this.props.openRelation(relation.id); }}
                              className="fa fa-angle-down"
                            />
                            }
                            {
                              relation.opened &&
                            <i
                              onClick={() => { this.props.closeRelation(relation.id); }}
                              className="fa fa-angle-up"
                            />
                            }
                          </div>
                          <div className="filter-relation-amount">
                          ({count})
                          </div>
                        </div>
                        {
                          relation.opened &&
                          <div className="filter-item-body">
                            {
                              relation.predicates.map((predicate, predicateIndex) =>
                                (
                                  <div
                                    className="filter-relation-predicate"
                                    key={predicateIndex}
                                  >
                                    <div className="filter-relation-predicate-control">
                                      <Checkbox
                                        checked={predicate.checked}
                                        onChange={() => { this.checkRelationTypeCb(relation, predicate); }}
                                      />
                                    </div>
                                    <div className="filter-relation-predicate-name">
                                      {predicate.name}
                                    </div>
                                    <div className="filter-relation-predicate-count">
                                      ({predicate.count})
                                    </div>
                                  </div>
                                )
                              )
                            }
                          </div>
                        }
                      </div>
                    );
                  })
                }
                <Spinner isLoading={relationsLoading} containerClassName="text-center" />
              </div>
            </div>
          </div>
        </div>
        <div className="set-result-page-add-concepts-results-header">
          <div className="result-header-control">
            <Checkbox
              checked={allRelatedChecked}
              onChange={() => {
                relatedConcepts.length > 0 && // eslint-disable-line no-unused-expressions
                this.checkAllRelatedWithFilter(!allRelatedChecked);
              }}
            />
          </div>
          <div className="result-header-title">Related concept</div>
          <div className="add-related-concepts-sorting-section">
            <i
              className={ascClasses}
              onClick={() => { this.props.sort({ sortBy: 'conceptName', sortDirection: 'ASC' }); }}
            />
            <i
              className={descClasses}
              onClick={() => { this.props.sort({ sortBy: 'conceptName', sortDirection: 'DESC' }); }}
            />
          </div>
          <div className="add-related-concepts-sorting-search">
            <input
              onChange={(e) => { this.props.filter(e.target.value); }}
              className="input"
              type="text"
              placeholder="Search for a concept"
            />
          </div>
          <div className="add-related-concept-search-button">
            <button
              type="button"
              className={searchBtnClasses}
              onClick={this.props.findRelatedConcepts}
            >
              Search
            </button>
          </div>
        </div>
        <div className="set-result-page-add-concepts-results">
          {
            relatedConcepts.length > 0 &&
            relatedConcepts.map((item, index) =>
              (
                <div
                  className="related-concept"
                  key={index}
                >
                  <div className="related-concept-checkbox">
                    <Checkbox
                      checked={item.checked}
                      onChange={
                        () => { this.props.checkRelatedConcept(item.id); }
                      }
                    />
                  </div>
                  <div className="related-concept-name">
                    {item.concept.name}
                  </div>
                </div>
              )
            )
          }
          {
            relatedConcepts.length === 0 &&
            !relatedConceptsLoading &&
              <div className="related-concepts-no-items">
                No concepts based on selected filters.
              </div>
          }
          <Spinner isLoading={relatedConceptsLoading} containerClassName="text-center" />
        </div>
        <div className="set-result-page-add-concepts-results-bottom">
          <div
            className={disabledAddRelated}
            onClick={() => {
              checkedRelatedConcepts.length > 0 && // eslint-disable-line no-unused-expressions
              this.addRelated();
            }}
          >
            Add linked concept
          </div>
        </div>
      </div>
    );
  }
}

SetResultPageAddConcepts.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    categories: getCategoriesForFilter(state),
    categoriesLoading: getCategoriesLoading(state),
    taxonomies: getTaxonomiesForFilter(state),
    taxonomiesLoading: getTaxonomiesLoading(state),
    relations: getRelationsForFilter(state),
    relationsLoading: getRelationsLoading(state),
    relatedConcepts: getRelatedConcepts(state),
    relatedConceptsLoading: getRelatedConceptsLoading(state),
    sorting: getSorting(state),
    checkedRelatedConcepts: getCheckedRelatedConcepts(state),
    filterValue: getFilter(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAddLinkedConcepts() {
      dispatch(closeAddLinkedConcepts());
    },
    initFilterCategories() {
      dispatch(initFilterCategories());
    },
    initFilterPredicates() {
      dispatch(initFilterPredicates());
    },
    initFilterTaxonomies() {
      dispatch(initFilterTaxonomies());
    },
    openCategory(id) {
      dispatch(openCategory(id));
    },
    closeCategory(id) {
      dispatch(closeCategory(id));
    },
    openRelation(id) {
      dispatch(openRelation(id));
    },
    closeRelation(id) {
      dispatch(closeRelation(id));
    },
    checkRelation(id) {
      dispatch(checkRelation(id));
    },
    checkCategory(id) {
      dispatch(checkCategory(id));
    },
    checkCategoryType(data) {
      dispatch(checkCategoryType(data));
    },
    checkTaxonomy(id) {
      dispatch(checkTaxonomy(id));
    },
    initSemanticTypes(data) {
      dispatch(initSemanticTypes(data));
    },
    semanticTypesLoading(id) {
      dispatch(semanticTypesLoading(id));
    },
    checkAllCategoryTypes(data) {
      dispatch(checkAllCategoryTypes(data));
    },
    findRelatedConcepts() {
      dispatch(findRelatedConcepts());
    },
    checkRelatedConcept(id) {
      dispatch(checkRelatedConcept(id));
    },
    sort(data) {
      dispatch(sort(data));
    },
    filter(data) {
      dispatch(filter(data));
    },
    addColumn() {
      dispatch(addColumn());
    },
    checkAllRelatedConcepts(checked, filterValue) {
      dispatch(checkAllRelatedConcepts(checked, filterValue));
    },
    checkRelationType(data) {
      dispatch(checkRelationType(data));
    },
    checkAllRelationType(data) {
      dispatch(checkAllRelationType(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetResultPageAddConcepts);
