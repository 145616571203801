import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Components
import Loader from '../../common/Loader/Loader';
import GeneDiseaseBarChart from '../../GeneDisease/GeneDiseaseBarChart/GeneDiseaseBarChart';
// Constants
import { GOLD_COLOR, PURPLE_COLOR } from '../../../constantsCommon';
// Store
import {
  getSectionChart,
  getChartLoading,
  getChartError,
} from './redux/selectors';
import {
  setFuncCharacterizationChartDataAction,
  setFuncCharacterizationTableDataAction,
  filterFuncCharacterizationTableAction,
} from './redux/actions';
// Styles
import './FunctionalCharacterizationChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  getTableData: PropTypes.func.isRequired,
  filterTable: PropTypes.func.isRequired,
  sectionChart: PropTypes.instanceOf(Array).isRequired,
  initSectionChart: PropTypes.func.isRequired,
  chartError: PropTypes.string,
  chartLoading: PropTypes.bool.isRequired,
  itsNewConcept: PropTypes.bool,
  geneName: PropTypes.string,
};

class FunctionalCharacterizationChart extends React.Component {
  constructor(props) {
    super(props);

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      initSectionChart,
      itsNewConcept,
      sectionChart,
    } = this.props;

    if (itsNewConcept || isEmpty(sectionChart)) {
      initSectionChart({ id: gene });
    }
  };

  handleFilterTable = (data) => {
    const {
      gene,
      getTableData,
      filterTable,
    } = this.props;

    filterTable(data);
    getTableData({ id: gene });
  };

  render() {
    const {
      chartError,
      chartLoading,
      sectionChart,
      geneName,
    } = this.props;

    return (
      <div>
        {
          !chartLoading && sectionChart.length > 0 &&
          <GeneDiseaseBarChart
            data={sectionChart}
            id="func-characterization-diagram"
            color={PURPLE_COLOR}
            colorHighlighted={GOLD_COLOR}
            filterTable={this.handleFilterTable}
            chapterName="Functional Characterization of"
            sourceName={geneName}
          />
        }
        <Loader isLoading={chartLoading} />
        {
          chartError && !chartLoading &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            {chartError}
          </div>
        }
      </div>
    );
  }
}

FunctionalCharacterizationChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionChart: getSectionChart(state),
    chartLoading: getChartLoading(state),
    chartError: getChartError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionChart(data) {
      dispatch(setFuncCharacterizationChartDataAction(data));
    },
    filterTable(data) {
      dispatch(filterFuncCharacterizationTableAction(data));
    },
    getTableData(data) {
      dispatch(setFuncCharacterizationTableDataAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunctionalCharacterizationChart);
