import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import SimpleTable from '../../common/SimpleTable/SimpleTable';
import {
  getParentBlockWidth,
  AVERAGE_SYMBOL_LENGTH,
} from '../../Utils/Utils';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';
import ChemicalModelImage from '../../common/ChemicalModelImage/ChemicalModelImage';

const propTypes = {
  tableData: PropTypes.instanceOf(Object),
  parentClass: PropTypes.string,
  sortAction: PropTypes.func,
  changePageAction: PropTypes.func,
  geneName: PropTypes.string,
};

class ExperimentalResultsTable extends React.Component {
  state = {
    showInfoText: false,
  };
  simpleTableRef = React.createRef(null);

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const { changePageAction } = this.props;

    changePageAction(page.selected);
  };

  getColumnPercentWidth = (percent) => {
    const { parentClass } = this.props;
    const screenWidth = getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { tableData: { data } } = this.props;
    const { compound, compoundImage } = data[index];
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(50);
    const stringLength = compound.name.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);

    const height = ((stringRows * lineHeight) < 300) && compoundImage ? 300 : (stringRows * lineHeight);

    return height + padding;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  compoundNameCell = ({ id, name }, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      tableData: {
        data = [],
        allData = [],
        pageNumber,
        totalPages,
        sorting: {
          sortBy,
          sortDirection,
        },
      },
      geneName,
      sortAction,
      parentClass,
    } = this.props;

    const { showInfoText } = this.state;

    const tableSettings = {
      height: 500,
      width: getParentBlockWidth(parentClass),
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      useDynamicRowHeight: true,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Compound name',
        dataKey: 'compound',
        sortPath: ['compound', 'name'],
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(50),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.compound ? this.compoundNameCell(rowData.compound, rowIndex) : null
        ),
        csvRenderer: rowData => (rowData.compound ? rowData.compound.name : null),
      },
      {
        label: 'Affinity',
        dataKey: 'affinity',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.affinity}
          </div>
        ),
      },
      {
        label: 'Compound image',
        dataKey: 'compoundImage',
        width: this.getColumnPercentWidth(30),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div className="table-wrap__cell-row">
            {
              <ChemicalModelImage data={rowData} index={rowIndex} />
            }
          </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Experimental results
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows published compounds that are known to interact with the target.
              This information is retrieved from multiple data sources including <a className="link" href="https://www.ebi.ac.uk/chembl/" target="blank">Chembl</a>.
            </p>
            <p>
              Per compound the EC50 or IC50 levels are provided. Only compounds with an affinity level of 100nM or less are shown.
            </p>
          </div>
        }
        {
          data.length > 0 ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-therapeuticcandidates-experimental-results`}
                    />
                  }
                </div>
              }
              <SimpleTable
                innerRef={this.simpleTableRef}
                autoSize={true}
                settings={tableSettings}
                data={data}
                columns={columns}
                dynamicHeight={true}
                sortAction={sortAction}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

ExperimentalResultsTable.propTypes = propTypes;

export default ExperimentalResultsTable;
