import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  putPatentsChemicalsAction,
  patentsChemicalsFailedAction,
  patentsChemicalsLoadingAction,
} from '../../../redux/actions';

export function* getPatentsChemicalsWorker(action) {
  try {
    yield put(patentsChemicalsLoadingAction(true));
    const requestData = action.data;

    if (!requestData.params) {
      requestData.params = {
        size: 20,
        page: 0,
      };
    }
    const response = yield call(Api.getPatentsPatentChemicals, requestData);
    yield put(putPatentsChemicalsAction(response));
  } catch (e) {
    yield put(patentsChemicalsFailedAction(e.message));
  }
}
