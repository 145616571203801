import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { change, reset } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Slider from '../../../common/Slider/Slider';
import Button from '../../../common/Buttons/Button/Button';
import SaveAsSetModal from '../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
// Constants
import { THEME, RELATIVE_PATH } from '../../../../constantsCommon';
import { SEARCH_FILTER_FORM } from '../SearchFilter/constants';
import { searchFilterSelectOptionsEnum, searchFilterDatabase } from '../../enums';
import { resetingSearchValues } from '../../constants';
// Store
import { getSearchConceptsSelector } from '../../store/selectors';
import { resetSearchConceptAction, setSearchConceptInputValueAction } from '../../../Concept/ConceptSearchModal/store/actions';
// Utils
import { truncate } from '../../../Utils/Utils';
// Styles
import './index.scss';

const propTypes = {
  searchValues: PropTypes.instanceOf(Object),
  searchConcepts: PropTypes.instanceOf(Array),
  applyFilter: PropTypes.func,
  submitSearchFilters: PropTypes.func,
  toggleSearch: PropTypes.func,
  resetFilters: PropTypes.func,
  resetConceptSearchModal: PropTypes.func,
  setConceptSearchModalInputValue: PropTypes.func,
};

const SearchPageHeader = (props) => {
  const {
    searchValues,
    searchConcepts,
    applyFilter,
    submitSearchFilters,
    toggleSearch,
    resetFilters,
    resetConceptSearchModal,
    setConceptSearchModalInputValue,
  } = props;

  const [showAddSetModal, setShowAddSetModal] = useState(false);

  const applyPassedFilters = useCallback((publicationSource) => {
    resetFilters();
    applyFilter('publicationSource', publicationSource);
    submitSearchFilters({ ...resetingSearchValues, publicationSource });
  }, [resetFilters, applyFilter, submitSearchFilters]);

  const onPubmedClick = useCallback(() => {
    const publicationSource = searchFilterSelectOptionsEnum.sources[0].value;
    applyPassedFilters(publicationSource);
  }, [applyPassedFilters]);

  const onDatabasesClick = useCallback(() => {
    const publicationSource = searchFilterDatabase.value;
    applyPassedFilters(publicationSource);
  }, [applyPassedFilters]);

  const onAddConceptClick = useCallback(() => {
    setConceptSearchModalInputValue('');
    toggleSearch(false);
  }, [setConceptSearchModalInputValue, toggleSearch]);

  const onNewSearchClick = useCallback(() => {
    resetFilters();
    resetConceptSearchModal();
    toggleSearch();
  }, [resetConceptSearchModal, resetFilters, toggleSearch]);

  const onOpenAsRelationMapClick = useCallback(() => {
    localStorage.setItem('uniqConcepts', JSON.stringify(searchConcepts));
    window.open(`${RELATIVE_PATH}/relation-map/personal/new`, '_blank');
  }, [searchConcepts]);

  const renderSearchConcepts = (c, i, arr) => {
    const link = c.semanticCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${c.id}` :
      `${RELATIVE_PATH}/concept-details/${c.id}`;

    return (
      <div className="search-page-header__concept" data-tip={c.name}>
        <Link to={link} className="link">
          {truncate(c.name, 140)}
        </Link>
        {
          i !== arr.length - 1 &&
          <div className="and">AND</div>
        }
      </div>
    );
  };

  return (
    <div className="search-page-header">
      <div className="search-page-header__group search-page-header__group_left">
        <Button
          text="PUBMED"
          onClick={onPubmedClick}
          customClassName="search-page-header__btn"
          active={searchValues && searchValues.publicationSource !== searchFilterDatabase.value}
          theme={THEME.GREY}
        />
        <Button
          text="Databases"
          onClick={onDatabasesClick}
          customClassName="search-page-header__btn"
          active={searchValues && searchValues.publicationSource === searchFilterDatabase.value}
          theme={THEME.GREY}
        />
      </div>
      <div className="search-page-header__concepts">
        {
          searchConcepts && searchConcepts.length > 0 &&
          <Slider
            slides={searchConcepts.map(renderSearchConcepts)}
          />
        }
      </div>
      <div className="search-page-header__group search-page-header__group_right">
        <Button
          text="Add concept"
          onClick={onAddConceptClick}
          customClassName="search-page-header__btn"
          theme={THEME.GREY}
        />
        <Button
          text="New search"
          onClick={onNewSearchClick}
          customClassName="search-page-header__btn"
          theme={THEME.GREY}
        />
        <Button
          text="Save in Sets"
          onClick={() => setShowAddSetModal(true)}
          customClassName="search-page-header__btn"
          theme={THEME.GREY}
        />
        <Button
          text="Open in Relation map"
          onClick={onOpenAsRelationMapClick}
          customClassName="search-page-header__btn"
          theme={THEME.GREY}
        />
      </div>
      <SaveAsSetModal
        isOpen={showAddSetModal}
        closeCb={() => setShowAddSetModal(false)}
        projectId="personal"
        idsForShortConceptDetails={searchConcepts.map(c => c.id)}
      />
    </div>
  );
};

SearchPageHeader.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    searchConcepts: getSearchConceptsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setConceptSearchModalInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
    resetConceptSearchModal() {
      dispatch(resetSearchConceptAction());
    },
    applyFilter(key, value) {
      dispatch(change(SEARCH_FILTER_FORM, key, value));
    },
    resetFilters() {
      dispatch(reset(SEARCH_FILTER_FORM));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPageHeader);
