import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineFundView } from 'react-icons/ai';

// Components
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import Accordion from '../../../../common/Accordion/Accordion';
import SemanticCategories from '../RMSemanticCategories/RMSemanticCategories';
// Utils
import { calcSemanticCategoriesPosition } from '../../utils';
// Constants
import { LayoutAlgorhitms } from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  semanticCategories: PropTypes.instanceOf(Object),
  semanticCategoriesOrder: PropTypes.instanceOf(Array),
  layout: PropTypes.instanceOf(Object),
  concepts: PropTypes.instanceOf(Array),
  dimensions: PropTypes.instanceOf(Object),
  cacheData: PropTypes.func,
  updateLayout: PropTypes.func,
  mergeConcepts: PropTypes.func,
  updateAlgorithm: PropTypes.func,
  updateSemCategories: PropTypes.func,
  updateColorsAndShapes: PropTypes.func,
  updateSemCategoriesOrder: PropTypes.func,
  removeNodesFixedPosition: PropTypes.func,
  getConceptsPublications: PropTypes.func,
  toggleProportionalLinksLayout: PropTypes.func,
};

const RelationMapLayout = (props) => {
  const {
    layout: {
      algorithm,
      showArrows,
      proportionalLines,
    },
    dimensions,
    concepts,
    cacheData,
    updateLayout,
    updateAlgorithm,
    mergeConcepts,
    semanticCategories,
    semanticCategoriesOrder,
    updateSemCategories,
    updateColorsAndShapes,
    updateSemCategoriesOrder,
    removeNodesFixedPosition,
    toggleProportionalLinksLayout,
  } = props;

  const handleShowArrows = useCallback(() => {
    updateLayout({
      option: 'showArrows',
      data: !showArrows,
    });
  }, [showArrows, updateLayout]);

  const selectAlgorithm = useCallback((value) => {
    if (algorithm === value) {
      updateAlgorithm(null);
    } else {
      updateAlgorithm(value);
    }
    removeNodesFixedPosition();
  }, [algorithm, removeNodesFixedPosition, updateAlgorithm]);

  const selectSemanticCategoriesAlgorithm = useCallback(() => {
    if (algorithm !== LayoutAlgorhitms.SEMANTIC_CATEGORIES) {
      const updatedConcepts = calcSemanticCategoriesPosition(concepts, semanticCategoriesOrder, dimensions?.width);
      mergeConcepts(updatedConcepts);
      updateAlgorithm(LayoutAlgorhitms.SEMANTIC_CATEGORIES);
    } else {
      updateAlgorithm(null);
    }

  }, [algorithm, concepts, dimensions?.width, mergeConcepts, semanticCategoriesOrder, updateAlgorithm]);

  const handleUpdateSemanticCategoriesOrder = useCallback((newOrder) => {
    if (algorithm === LayoutAlgorhitms.SEMANTIC_CATEGORIES) {
      const updatedConcepts = calcSemanticCategoriesPosition(concepts, newOrder, dimensions?.width);
      mergeConcepts(updatedConcepts);
    }
    updateSemCategoriesOrder(newOrder);
  }, [algorithm, concepts, mergeConcepts, updateSemCategoriesOrder, dimensions?.width]);

  return (
    <>
      <div className="relation-map-page__subtitle">
        <AiOutlineFundView size={22} color="#76649d" />
        <span>Layout</span>
      </div>
      <div className="relation-map-layout">
        <Accordion title="Algorithms">
          <div className="relation-map-layout__block">
            <div className="relation-map-layout__item">
              <Checkbox
                id="default-layout-radio"
                checked={algorithm === LayoutAlgorhitms.DEFAULT}
                onChange={() => selectAlgorithm(LayoutAlgorhitms.DEFAULT)}
              />
              <span className="relation-map-layout__text">
                Default Layout
              </span>
            </div>
            <div className="relation-map-layout__item">
              <Checkbox
                id="cooccurrence-layout-radio"
                checked={algorithm === LayoutAlgorhitms.COOCCURRENCE}
                onChange={() => selectAlgorithm(LayoutAlgorhitms.COOCCURRENCE)}
              />
              <span className="relation-map-layout__text">
                Cooccurrence Layout
              </span>
            </div>
            <div className="relation-map-layout__item">
              <Checkbox
                id="sem-cat-layout-radio"
                checked={algorithm === LayoutAlgorhitms.SEMANTIC_CATEGORIES}
                onChange={selectSemanticCategoriesAlgorithm}
              />
              <span className="relation-map-layout__text">
                Semantic Categories Layout
              </span>
            </div>
            <div className="relation-map-layout__item">
              <Checkbox
                id="short-texts-radio"
                checked={algorithm === LayoutAlgorhitms.SHORT_TEXTS}
                onChange={() => selectAlgorithm(LayoutAlgorhitms.SHORT_TEXTS)}
              />
              <span className="relation-map-layout__text">
                Short Texts Layout
              </span>
            </div>
          </div>
        </Accordion>
        {
          semanticCategoriesOrder.length > 0 &&
          <Accordion title="Semantic categories">
            <SemanticCategories
              cacheData={cacheData}
              semanticCategories={semanticCategories}
              updateSemCategories={updateSemCategories}
              updateColorsAndShapes={updateColorsAndShapes}
              semanticCategoriesOrder={semanticCategoriesOrder}
              updateSemCategoriesOrder={handleUpdateSemanticCategoriesOrder}
            />
          </Accordion>
        }
        <div className="relation-map-layout__block">
          <div className="relation-map-layout__item">
            <Checkbox
              id="show-arrows-checkbox"
              checked={showArrows}
              onChange={handleShowArrows}
            />
            <span className="relation-map-layout__text">
              Show arrows
            </span>
          </div>
          <div className="relation-map-layout__item">
            <Checkbox
              id="proportional-lines-checkbox"
              checked={proportionalLines}
              onChange={toggleProportionalLinksLayout}
            />
            <span className="relation-map-layout__text">
              Proportional line width
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

RelationMapLayout.propTypes = propTypes;

export default React.memo(RelationMapLayout);
