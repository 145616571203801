import { createSelector } from 'reselect';

import { NEW_PASSWORD_FORM } from '../constants';

const newPasswordFormSelector = state => state.getIn(['form', NEW_PASSWORD_FORM]);
const showNewPasswordModalSelector = state => state.getIn(['newPasswordReducer', 'showModal']);
const newPasswordCheckErrorSelector = state => state.getIn(['newPasswordReducer', 'error']);
const newPasswordCheckTokenErrorSelector = state => state.getIn(['newPasswordReducer', 'tokenError']);

export const newPasswordForm = createSelector(
  newPasswordFormSelector,
  (form) => {
    const newPassword = form.getIn(['values', 'newPassword']);
    return {
      newPassword,
    };
  }
);

export const getShowNewPasswordModal = createSelector(
  showNewPasswordModalSelector,
  data => data
);

export const getActivateUserCheckError = createSelector(
  newPasswordCheckErrorSelector,
  data => data,
);

export const getActivateUserCheckTokenError = createSelector(
  newPasswordCheckTokenErrorSelector,
  data => data,
);
