export const GET_BACKGROUND_CELLS = 'GET_BACKGROUND_CELLS';
export const SET_BACKGROUND_CELLS = 'SET_BACKGROUND_CELLS';
export const SET_BACKGROUND_CELLS_FILTER_OPTION = 'SET_BACKGROUND_CELLS_FILTER_OPTION';
export const TOGGLE_BACKGROUND_CELLS_LOADER = 'TOGGLE_BACKGROUND_CELLS_LOADER';
export const TOGGLE_BACKGROUND_SET_OPTION = 'TOGGLE_BACKGROUND_SET_OPTION';
export const UPDATE_BACKGROUND_SET_CONFIG = 'UPDATE_BACKGROUND_SET_CONFIG';
export const SET_BACKGROUND_SET_FILTER_OPTION = 'SET_BACKGROUND_SET_FILTER_OPTION';
export const SWITCH_SHOULD_UPDATE_DATA_FLAG = 'SWITCH_SHOULD_UPDATE_DATA_FLAG';

export const CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID = 'CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID';
export const SET_BACKGROUND_SET_ACTIVE_PROJECT_ID = 'SET_BACKGROUND_SET_ACTIVE_PROJECT_ID';
