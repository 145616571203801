import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Icons
import { BiExport } from 'react-icons/bi';
import { IoMdAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
// Components
import NoData from '../../common/NoData/NoData';
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import Button from '../../common/Buttons/Button/Button';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
import PublicationsListItem from '../../common/PublicationsList/Components/PublicationsListItem/PublicationsListItem';
import DeletePublicationsModal from './Components/DeletePublicationsModal/DeletePublicationsModal';
import AddToRightfindWarningModal from '../../Modals/AddToRightfindWarningModal/AddToRightfindWarningModal';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import {
  getRightFindUrlSuffix,
  isProjectManagerRightSelector,
  isReprintDeskAllowed,
  isRightFindAllowed,
} from '../../Header/selectors';
// Utils
import { generateMultiRightFindLink, manageCheckedItem } from './utils';
import { publicationsActionsTypes } from './enum';
// Styles
import './styles.scss';

const propTypes = {
  projectId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  isAdminRights: PropTypes.bool,
  isProjectLocked: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  deletePublications: PropTypes.func,
  isProjectLockedForUsers: PropTypes.bool,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  publications: PropTypes.instanceOf(Array),
  clearPublicationsManagement: PropTypes.func,
  getPublicationsManagementData: PropTypes.func,
  getAllPublicationsIdsByProject: PropTypes.func,
  getPublicationsDataForExport: PropTypes.func,
  selectedPublicationsIds: PropTypes.instanceOf(Array),
  selectedPubmedIds: PropTypes.instanceOf(Array),
  updateSelectedPublicationsIds: PropTypes.func,
  updateSelectedPubmedIds: PropTypes.func,
  rightFindUrlSuffix: PropTypes.string,
};

class PublicationsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.columns = null;
    this.state = {
      deletePublications: {
        ids: [],
        show: false,
      },
      allPublicationsAreChecked: false,
      showAddToRightfindWarningModal: false,
    };
  }

  componentDidMount() {
    const {
      projectId,
      getPublicationsManagementData,
    } = this.props;
    const requestData = this.generateRequestData({ projectId });
    getPublicationsManagementData(requestData);
  }

  componentWillUnmount() {
    const { clearPublicationsManagement } = this.props;
    clearPublicationsManagement();
  }

  openDeleteReferencesModal = () => {
    const { selectedPublicationsIds } = this.props;
    this.setState({
      deletePublications: {
        ids: selectedPublicationsIds,
        show: true,
      },
    });
  };

  handleDeletePublications = () => {
    const { projectId, deletePublications } = this.props;
    const { deletePublications: { ids } } = this.state;
    deletePublications({ ids, projectId });
    this.closeDeletePublicationsModal();
  };

  closeDeletePublicationsModal = () => {
    this.setState({
      deletePublications: {
        ids: [],
        show: false,
      },
    });
  };

  handleProjectClick = (projectId) => {
    const { getPublicationsManagementData } = this.props;
    const requestData = this.generateRequestData({ projectId });
    getPublicationsManagementData(requestData);
    this.setState({ allPublicationsAreChecked: false });
  };

  generateRequestData = ({ page = 0, size = 15, projectId }) => {
    const requestData = {
      params: {
        page,
        size,
      },
      projectId,
    };

    return requestData;
  };

  handlePageClick = (page) => {
    const { projectId, getPublicationsManagementData } = this.props;
    const requestData = this.generateRequestData({ page: page.selected, projectId });
    getPublicationsManagementData(requestData);
  };

  projectsActionsAreDisabled = () => {
    const {
      isAdminRights,
      isProjectPublic,
      isProjectLocked,
      isProjectLockedForUsers,
    } = this.props;
    if (isProjectLocked || isProjectPublic || (isProjectLockedForUsers && !isAdminRights)) {
      return true;
    }
    return false;
  };

  publicationsActionIsDisabled = (publicationActionType) => {
    const { selectedPublicationsIds, selectedPubmedIds } = this.props;
    if (this.projectsActionsAreDisabled()) {
      return true;
    }

    switch (publicationActionType) {
      case publicationsActionsTypes.DELETE:
      case publicationsActionsTypes.EXPORT: {
        return !selectedPublicationsIds.length;
      }
      case publicationsActionsTypes.ADD_TO_RIGHT_FIND: {
        return selectedPubmedIds.length < 1;
      }
      default: {
        return true;
      }
    }
  };

  checkPublication = ({ id, pmid }) => {
    const {
      selectedPubmedIds,
      updateSelectedPubmedIds,
      selectedPublicationsIds,
      updateSelectedPublicationsIds,
    } = this.props;
    const newSelectedPublicationsIds = manageCheckedItem(id, selectedPublicationsIds);
    updateSelectedPublicationsIds(newSelectedPublicationsIds);

    if (pmid) {
      const newSelectedPubmedIds = manageCheckedItem(pmid, selectedPubmedIds);
      updateSelectedPubmedIds(newSelectedPubmedIds);
    }
  };

  checkAllPublications = () => {
    const { allPublicationsAreChecked } = this.state;
    const {
      projectId,
      getAllPublicationsIdsByProject,
      updateSelectedPublicationsIds,
    } = this.props;
    this.setState({
      allPublicationsAreChecked: !allPublicationsAreChecked,
    });

    if (!allPublicationsAreChecked) {
      getAllPublicationsIdsByProject({ projectId });
    } else {
      updateSelectedPublicationsIds([]);
    }
  };

  exportPublicationsData = () => {
    const { projectId, selectedPublicationsIds, getPublicationsDataForExport } = this.props;
    getPublicationsDataForExport({
      projectId,
      downloadLink: this.downloadLink,
      publicationsIds: selectedPublicationsIds,
    });
  };

  goRightFind = () => {
    const { selectedPubmedIds, rightFindUrlSuffix } = this.props;
    window.open(generateMultiRightFindLink(selectedPubmedIds, rightFindUrlSuffix), '_blank');
  };

  handleAddToRightfind = () => {
    const { selectedPublicationsIds, selectedPubmedIds } = this.props;

    if (selectedPublicationsIds.length !== selectedPubmedIds.length) {
      this.setState({ showAddToRightfindWarningModal: true });
    } else {
      this.goRightFind();
    }
  };

  render() {
    const {
      error,
      loading,
      projectId,
      totalPages,
      pageNumber,
      publications,
      showRightFind,
      showReprintDesk,
      selectedPubmedIds,
      setActiveProjectId,
      clearActiveProjectId,
      rightFindUrlSuffix,
    } = this.props;

    const { deletePublications, showAddToRightfindWarningModal } = this.state;

    return (
      <div className="publications">
        {
          !error &&
          <div className="row publications-container m-0">
            <div className="management-container__buttons">
              <div className="management-buttons">
                {
                  showRightFind &&
                  <Button
                    onClick={this.handleAddToRightfind}
                    customClassName="button-secondary management-buttons__button mr-5"
                    disabled={this.publicationsActionIsDisabled(publicationsActionsTypes.ADD_TO_RIGHT_FIND)}
                  >
                    <IoMdAdd size={26} />
                    Add to RightFind
                  </Button>
                }
                <Button
                  onClick={this.exportPublicationsData}
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={this.publicationsActionIsDisabled(publicationsActionsTypes.EXPORT)}
                >
                  <BiExport size={26} />
                  Export Publications
                </Button>
                <Button
                  onClick={this.openDeleteReferencesModal}
                  customClassName="button-secondary management-buttons__button"
                  disabled={this.publicationsActionIsDisabled(publicationsActionsTypes.DELETE)}
                >
                  <MdDelete size={26} />
                  Delete
                </Button>
              </div>
            </div>
            <ProjectsTabs
              activeProjectId={projectId}
              setActiveProjectId={setActiveProjectId}
              clearActiveProjectId={clearActiveProjectId}
              handleProjectClick={this.handleProjectClick}
            />
            <div className="publications-list__wrap">
              {
                !!publications.length &&
                <div className="publications__controller">
                  <Button
                    customClassName="button-primary"
                    onClick={this.checkAllPublications}
                    text="Select all"
                  />
                  {
                    totalPages > 1 &&
                    <div className="search-page__pagination">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakLabel={<a>...</a>}
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                </div>
              }
              {
                !loading &&
                <div className="publications-list__content">
                  {
                    publications && publications.length > 0 &&
                    publications.map((item, i) => (
                      <PublicationsListItem
                        key={`publications-list-item-${i}`}
                        publication={item}
                        pmidList={selectedPubmedIds}
                        showRightFind={showRightFind}
                        showReprintDesk={showReprintDesk}
                        rightFindUrlSuffix={rightFindUrlSuffix}
                        onChange={this.checkPublication}
                      />
                    ))
                  }
                  <NoData
                    show={publications && publications.length === 0}
                    customClassName="publications-list__no-data"
                  />
                </div>
              }
            </div>
          </div>
        }
        <Error
          show={!!error && !loading}
          error={error}
        />
        <Loader
          isLoading={loading && !error}
          withOverlay={true}
        />
        <DeletePublicationsModal
          isOpen={deletePublications.show}
          closeCb={this.closeDeletePublicationsModal}
          onConfirm={this.handleDeletePublications}
          publicationsToDelete={deletePublications.ids.length}
        />
        <AddToRightfindWarningModal
          isOpen={showAddToRightfindWarningModal}
          closeCb={() => { this.setState({ showAddToRightfindWarningModal: false }); }}
          onConfirm={this.goRightFind}
        />
        <a //eslint-disable-line
          hidden={true}
          ref={(ref) => { this.downloadLink = ref; }}
        />
      </div>
    );
  }
}

PublicationsManagement.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    showRightFind: isRightFindAllowed(state),
    showReprintDesk: isReprintDeskAllowed(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
    publications: SELECTORS.getPublicationsManagementDataSelector(state),
    selectedPublicationsIds: SELECTORS.getSelectedPublicationsIdsSelector(state),
    selectedPubmedIds: SELECTORS.getSelectedPubmedIdsSelector(state),
    totalPages: SELECTORS.getPublicationsManagementTotalPagesSelector(state),
    pageNumber: SELECTORS.getPublicationsManagementpageNumberSelector(state),
    error: SELECTORS.getPublicationsManagementErrorSelector(state),
    loading: SELECTORS.getPublicationsManagementLoadingSelector(state),
    isProjectLockedForUsers: SELECTORS.getIsProjectLockedForUsersSelector(state),
    isProjectLocked: SELECTORS.getIsProjectLockedSelector(state),
    isProjectPublic: SELECTORS.getIsProjectPublicSelector(state),
    isAdminRights: isProjectManagerRightSelector(state),
    rightFindUrlSuffix: getRightFindUrlSuffix(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPublicationsManagementData(data) {
      dispatch(ACTIONS.getPublicationsManagementDataAction(data));
    },
    getAllPublicationsIdsByProject(data) {
      dispatch(ACTIONS.getAllPublicationsIdsByProjectAction(data));
    },
    getPublicationsDataForExport(data) {
      dispatch(ACTIONS.getPublicationsDataForExportAction(data));
    },
    updateSelectedPublicationsIds(data) {
      dispatch(ACTIONS.updateSelectedPublicationsIdsAction(data));
    },
    updateSelectedPubmedIds(data) {
      dispatch(ACTIONS.updateSelectedPubmedIdsAction(data));
    },
    clearPublicationsManagement() {
      dispatch(ACTIONS.clearPublicationsManagementAction());
    },
    deletePublications(data) {
      dispatch(ACTIONS.deletePublicationsManagementItemsAction(data));
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setActiveProjectIdAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearActiveProjectIdAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicationsManagement);
