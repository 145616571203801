function highlightPublicationText(highlightsArr, text) {
  const getTemplateString = key =>
    `<span class="publications-list-item__highlight">${key}</span>`;

  return highlightsArr
    .sort((a, b) => a.start - b.start)
    .reduce((textArr, h, i, arr) => {
      if (i === 0) {
        textArr.push(text.substring(0, h.start));
      } else {
        textArr.push(text.substring(arr[i - 1].end, h.start));
      }
      textArr.push(getTemplateString(text.substring(h.start, h.end)));
      if (i === arr.length - 1) {
        textArr.push(text.substring(h.end));
      }
      return textArr;
    }, [])
    .join('');
}

export { highlightPublicationText };
