import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import {
  putSetForFilteringAction,
  throwSetManagementDataErrorAction,
} from '../../reducer';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';

export function* getSetForFilteringWorker(action) {
  try {
    const { setId, projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const requestData = isProjectTab ? { projectId, setId } : setId;
    const result = yield call(apiToCall, requestData);
    yield put(putSetForFilteringAction(result.data));
  } catch (e) {
    yield put(throwSetManagementDataErrorAction(e.message));
  }
}
