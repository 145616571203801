import React, {useState} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// Components
import Accordion from '../../../../../common/Accordion/Accordion';
import Loader from '../../../../../common/Loader/Loader';
// Store
import * as SELECTORS from '../../store/selectors';
import * as ACTIONS from '../../store/actions';
// Utils
import { isEmptyObject } from '../../../../../Utils/Utils';
import Button from '../../../../../common/Buttons/Button/Button';
import ModalComponent from '../../../../../ModalComponent/ModalComponent';
import SelectCellLinesConfigurationModal from '../SelectCellLinesConfigurationModal/SelectCellLinesConfigurationModal';
import SaveCellLineConfigurationAsModal from '../SaveCellLineConfigurationAsModal/SaveCellLineConfigurationAsModal';
import NoData from '../../../../../common/NoData/NoData';
import Checkbox from '../../../../../common/Inputs/Checkbox/Checkbox';

const propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getCellLinePanelData: PropTypes.func,
  cellLines: PropTypes.instanceOf(Object),
  selectedCellLines: PropTypes.instanceOf(Array),
  selectedSet: PropTypes.instanceOf(Object),
  setSelectedCellLineData: PropTypes.func,
  saveCellLinesConfiguration: PropTypes.func,
  saveAsCellLinesConfiguration: PropTypes.func,
  getLatestConfigurationData: PropTypes.func,
  getAdditionalDataForSelectedConfiguration: PropTypes.func,
  setSelectedCellLinesGroup: PropTypes.func,
  selectedCellLinesGroups: PropTypes.instanceOf(Array),
  getResultsData: PropTypes.func,
  getResultsHeatmapData: PropTypes.func,
  cellLinesNoData: PropTypes.bool,
  deselectAllCellLines: PropTypes.func,
  selectAllCellLines: PropTypes.func,
};

const CellLineSelectionBlock = (props) => {
  const {
    loading,
    cellLines,
    selectedCellLines,
    setSelectedCellLineData,
    // saveCellLinesConfiguration,
    saveAsCellLinesConfiguration,
    getLatestConfigurationData,
    getAdditionalDataForSelectedConfiguration,
    setSelectedCellLinesGroup,
    selectedCellLinesGroups,
    selectedSet,
    getResultsData,
    getResultsHeatmapData,
    cellLinesNoData,
    deselectAllCellLines,
    selectAllCellLines,
  } = props;

  const [showSelectCellLineModal, setShowSelectCellLineModal] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const isDataExist = !isEmptyObject(cellLines);

  const handleSelectedCellLineData = (cellLineGroup, cellLineItem) => {
    setSelectedCellLineData({ cellLineGroup, cellLineItem });
  };

  // TODO: continue new functionality with saving after data changing
  // const handleSaveCellLinesConfiguration = () => {
  //   saveCellLinesConfiguration();
  // };

  const handleCloseSaveAsCellLinesConfigurationModal = () => {
    setShowSaveAsModal(false);
  };

  const handleOpenSaveAsCellLinesConfigurationModal = () => {
    setShowSaveAsModal(true);
  };

  const handleSaveAsCellLinesConfiguration = () => {
    saveAsCellLinesConfiguration();
    handleCloseSaveAsCellLinesConfigurationModal();
  };

  const handleFetchingSelectedConfigurationData  = (id) => {
    getAdditionalDataForSelectedConfiguration(id);
  };

  const handleOnChange = (e, cellLineGroup) => {
    setSelectedCellLinesGroup( { cellLineGroup, checkedValue: e.target.checked } );
  };

  const handleGetResultsData = () => {
    if (selectedSet && selectedSet.id) {
      getResultsHeatmapData();
    } else {
      getResultsData();
    }
  };

  return (
    <div className="cell-line-selection-block">
      {
        isDataExist &&
        !loading &&
        <>
          <div className="flex-grid ">
            <div className="cell-line-selection-block__leftbuttons col-1">
              <span className="cell-line-selection-block__sub-title">Ranking Profiles:</span>
              <div className="cell-line-selection-block__buttons-wrapper">
                <Button
                  text="Load latest"
                  customClassName="button-secondary cell-line-selection-block__btn"
                  onClick={getLatestConfigurationData}
                />
                <Button
                  text="Load profile"
                  customClassName="button-secondary cell-line-selection-block__btn"
                  onClick={() => setShowSelectCellLineModal(true)}
                />
                <Button
                  text="Save as profile"
                  customClassName="button-secondary cell-line-selection-block__btn"
                  onClick={handleOpenSaveAsCellLinesConfigurationModal}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="cell-line-selection-block__sticky_header">
                <Checkbox
                  wrapperClassName="cell-line-selection-block__select_btn"
                  checked={selectedCellLinesGroups.length === Object.keys(cellLines).length}
                  onChange={(e) => { e.target.checked ? selectAllCellLines() : deselectAllCellLines(); }}
                  labelValue="Select all"
                />
                <h5 className="cell-line-selection-block__title title5">Cancer cell lines included in ranking</h5>
              </div>
              <div className="cell-line-selection-block__cell-selection">
                {
                  Object.keys(cellLines).map(cellLineGroup => {
                    return (
                      <Accordion
                        key={cellLineGroup}
                        title={`${cellLineGroup} (${cellLines[cellLineGroup].length})`}
                        withCheckBox={true}
                        checkBoxProps={{
                          id: cellLineGroup,
                          checked: selectedCellLinesGroups.includes(cellLineGroup),
                          onChange: (e) => handleOnChange(e, cellLineGroup),
                        }}
                        customClassName="cell-line-selection-block__accordion"
                      >
                        <ul
                          key={cellLineGroup}
                          className="cell-line-selection-block__list"
                        >
                          {
                            cellLines[cellLineGroup].map(cellLineItem => (
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                              <li
                                key={cellLineItem.id}
                                onClick={() => handleSelectedCellLineData(cellLineGroup, cellLineItem)}
                                className={classNames({
                                  'cell-line-selection-block__item': true,
                                  'cell-line-selection-block__item--selected': cellLineItem.selected
                                })
                                }
                              >
                                { cellLineItem.name }
                              </li>
                            ))
                          }
                        </ul>
                      </Accordion>
                    );
                  })
                }
              </div>
            </div>
          </div>

          <Button
            text="Get cell line expression"
            disabled={!selectedCellLines.length}
            onClick={handleGetResultsData}
            customClassName="button-primary cell-line-selection-block__search-btn"
          />
        </>
      }
      <NoData
        show={!loading && cellLinesNoData}
        customClassName="cell-line-selection__no-data"
        message="There are no cell lines for the current genes selection"
      />
      <Loader isLoading={loading}/>
      {
        showSelectCellLineModal &&
        <ModalComponent
          isOpen={showSelectCellLineModal}
          closeCb={() => setShowSelectCellLineModal(false)}
        >
          <SelectCellLinesConfigurationModal
            onCloseFunction={() => setShowSelectCellLineModal(false)}
            onSelectFunction={handleFetchingSelectedConfigurationData}
          />
        </ModalComponent>
      }

      {
        showSaveAsModal &&
        <SaveCellLineConfigurationAsModal
          isOpen={showSaveAsModal}
          closeCb={handleCloseSaveAsCellLinesConfigurationModal}
          onSubmit={handleSaveAsCellLinesConfiguration}
          onCancel={handleCloseSaveAsCellLinesConfigurationModal}
        />
      }
    </div>
  );
};

CellLineSelectionBlock.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    cellLines: SELECTORS.getCellLinesDataSelector(state),
    cellLinesNoData: SELECTORS.getShowNoDataMesssageSelector(state),
    selectedCellLinesGroups: SELECTORS.getSelectedCellLinesGroupsSelector(state),
    selectedCellLines: SELECTORS.getSelectedCellLinesSelector(state),
    selectedSet: SELECTORS.getSelectedSetSelector(state),
    loading: SELECTORS.getCellLinesDataLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedCellLineData(data) {
      dispatch(ACTIONS.setSelectedCellLineDataAction(data));
    },
    setSelectedCellLinesGroup(data) {
      dispatch(ACTIONS.setSelectedCellLinesGroupAction(data));
    },
    deselectAllCellLines() {
      dispatch(ACTIONS.deselectAllCellLineDataAction());
    },
    selectAllCellLines() {
      dispatch(ACTIONS.selectAllCellLineDataAction());
    },
    // saveCellLinesConfiguration(data) {
    //   dispatch(ACTIONS.saveCellLinesConfigurationAction(data));
    // },
    saveAsCellLinesConfiguration(data) {
      dispatch(ACTIONS.saveAsCellLinesConfigurationAction(data));
    },
    getAdditionalDataForSelectedConfiguration(data) {
      dispatch(ACTIONS.getAdditionalDataForSelectedConfigurationAction(data));
    },
    getLatestConfigurationData() {
      dispatch(ACTIONS.getLatestConfigurationDataAction());
    },
    getResultsData(data) {
      dispatch(ACTIONS.getResultsDataAction(data));
    },
    getResultsHeatmapData(data) {
      dispatch(ACTIONS.getResultsHeatmapDataAction(data));
    },
  };
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(CellLineSelectionBlock));
