import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

// Components
import RadioInput from '../Inputs/RadioInput/RadioInput';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
import Spinner from '../Spinner/Spinner';
// Store
import {
  getAvailableSetsListSelector,
  getAvailableSetsLoadingSelector,
} from '../../Sets/SetsList/selectors';
import { getAvailableSetsAction } from '../../Sets/SetsList/reducer';
import {
  getBackgroundCellsSelector,
  getBackgroundSetConfigSelector,
  getBackgroundSetOptionsSelector,
  getBackgroundCellsLoaderSelector,
  getBackgroundCellsFilterOptionSelector,
  getActiveProjectIdSelector,
} from './store/selectors';
import {getSelectedSetInfoSelector} from '../../FindRelated/Components/EnrichmentAnalysisParams/store/selectors';

import {
  getBackgroundCellsAction,
  updateBackgroundSetConfigAction,
  toggleBackgroundSetOptionAction,
  setBackgroundCellsFilterOptionAction,
  setBackgroundSetFilterOptionAction,
  clearBackgroundSetActiveProjectId,
  setBackgroundSetActiveProjectId,
} from './store/actions';
// Utils

import { getProjectIdForRequest } from '../../Projects/ProjectsTabs/utils';
// Styles
import './SelectBackgroundSetComponent.css';

const propTypes = {
  sets: PropTypes.instanceOf(Array),
  setsLoading: PropTypes.bool,
  cells: PropTypes.instanceOf(Object),
  cellsLoading: PropTypes.bool,
  backgroundSetOptions: PropTypes.instanceOf(Object),
  backgroundSetConfig: PropTypes.instanceOf(Object),
  toggleBackgroundSetOption: PropTypes.func,
  getAvailableSets: PropTypes.func,
  getBackgroundCells: PropTypes.func,
  updateBackgroundSetConfig: PropTypes.func,
  setBackgroundCellsFilterOption: PropTypes.func,
  setBackgroundSetFilterOption: PropTypes.func,
  backgroundCellsFilterOption: PropTypes.string,
  closePopup: PropTypes.func,
  projectId: PropTypes.string,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  showInline: PropTypes.bool,
  updateStateFunction: PropTypes.func,
  applyFunction: PropTypes.func,
  selectedSetInfo: PropTypes.object,
};

class SelectBackgroundSetComponent extends React.Component {
  state = {
    sortSetsDESC: null,
    backgroundSetConfig: this.props.backgroundSetConfig,
  };

  componentDidMount() {
    const {
      getAvailableSets,
      getBackgroundCells,
    } = this.props;

    getAvailableSets();
    getBackgroundCells();
    if (this.props.updateStateFunction)
      this.props.updateStateFunction({ backgroundSetType: 'GENES' });
  }

  componentWillUnmount() {
    const { toggleBackgroundSetOption } = this.props;

    toggleBackgroundSetOption({
      allGenesOption: false,
      cellTypeOption: false,
      selectSetOption: false,
    });
  }

  toggleBackgroundSetOption = (setOptionName) => {
    const {
      backgroundSetOptions,
      toggleBackgroundSetOption,
    } = this.props;
    const newBackgroundSetOptions = {};

    Object.keys(backgroundSetOptions).forEach((item) => {
      if (setOptionName === item) {
        newBackgroundSetOptions[item] = !backgroundSetOptions[item];
        if (this.cellListRef) {
          this.cellListRef.forceUpdateGrid();
        }
        if (this.setListRef) {
          this.setListRef.forceUpdateGrid();
        }
      } else {
        newBackgroundSetOptions[item] = false;
      }
    });

    toggleBackgroundSetOption(newBackgroundSetOptions);
  };

  calcBackgroundSetOptionHeight = (items, rowHeight) => {
    const height = items.length * rowHeight;
    return height > 300 ? 300 : height;
  };

  selectAllGenes = () => {
    this.setState({ backgroundSetConfig: { backgroundSetType: 'GENES' } });
    this.props.updateStateFunction({ backgroundSetType: 'GENES' });
    this.toggleBackgroundSetOption('allGenesOption');
  };

  selectCell = (element, cell) => {
    if (!element.classList.contains('background-sets-list__item_active')) {
      this.setState({
        backgroundSetConfig: {
          backgroundSetType: 'CELL',
          cell: {
            id: cell.id,
            name: cell.name,
          },
        },
      });
    }
    this.cellListRef.forceUpdateGrid();

    this.props.updateStateFunction({
      backgroundSetType: 'CELL',
      cell: {
        id: cell.id,
        name: cell.name,
      },
    });
  };

  selectSet = (element, set) => {
    const { setBackgroundSetFilterOption, projectId } = this.props;
    if (!element.classList.contains('background-sets-list__item_active')) {
      this.setState({
        backgroundSetConfig: {
          setId: set.id,
          setName: set.name,
          backgroundSetType: 'SET',
          projectId: getProjectIdForRequest(projectId),
        },
      });
    }
    setBackgroundSetFilterOption(set.name);
    this.setListRef.forceUpdateGrid();

    this.props.updateStateFunction({
      setId: set.id,
      setName: set.name,
      backgroundSetType: 'SET',
      projectId: getProjectIdForRequest(projectId),
    });
  };

  setRowRenderer = ({ index, style }, sets) => {
    const { backgroundSetConfig: { setId } } = this.state;
    const cellIsActive = setId ? setId === sets[index].id : false;
    const setRowClass = classNames(
      'background-sets-list__item',
      { 'background-sets-list__item_active': cellIsActive }
    );

    return (
      <div
        key={`set-${index}`}
        style={style}
        className={setRowClass}
        onClick={(e) => { this.selectSet(e.target, sets[index]); }}
      >
        <span className="background-sets-list_item__overflow" title={sets[index].name}>{sets[index].name}</span>
        <RadioInput checked={cellIsActive} noLabel={true} />
      </div>
    );
  };

  cellFilterRowRenderer = ({ index, style }) => {
    const { cells, backgroundCellsFilterOption } = this.props;
    const cellFilterOptions = cells ? Object.keys(cells).map(item => item) : [];
    const cellIsActive = backgroundCellsFilterOption === cellFilterOptions[index];
    const cellFilterRowClass = classNames(
      'background-sets-list__item',
      'background-sets-list__item_filter',
      { 'background-sets-list__item_active': cellIsActive }
    );

    return (
      <div
        key={`cell-filter-${index}`}
        style={style}
        className={cellFilterRowClass}
        onClick={() => { this.selectFilterCellOption(cellFilterOptions[index]); }}
      >
        {cellFilterOptions[index]}
      </div>
    );
  };

  cellRowRenderer = ({ index, style }) => {
    const { cells, backgroundCellsFilterOption } = this.props;
    const { backgroundSetConfig: { cell } } = this.state;
    const cellIsActive = cell ? cell.id === cells[backgroundCellsFilterOption][index].id : false;
    const cellRowClass = classNames(
      'background-sets-list__item',
      { 'background-sets-list__item_active': cellIsActive }
    );

    return (
      <div
        key={`cell-${index}`}
        style={style}
        className={cellRowClass}
        onClick={(e) => { this.selectCell(e.target, cells[backgroundCellsFilterOption][index]); }}
      >
        <span>{cells[backgroundCellsFilterOption][index].name}</span>
        <RadioInput checked={cellIsActive} noLabel={true} />
      </div>
    );
  };

  toggleSortSetsDESC = () => {
    const { sortSetsDESC } = this.state;
    this.setState({ sortSetsDESC: !sortSetsDESC });
  };

  sortSets = (sets) => {
    const { sortSetsDESC } = this.state;

    if (sets.length && sortSetsDESC !== null) {
      sets.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (sortSetsDESC !== null && !sortSetsDESC) {
      return sets.reverse();
    }

    return sets;
  };

  selectFilterCellOption = (filterOption) => {
    const { setBackgroundCellsFilterOption } = this.props;
    setBackgroundCellsFilterOption(filterOption);
    if (this.cellFilterListRef) {
      this.cellFilterListRef.forceUpdateGrid();
    }
  };

  updateBackgroundSetConfig = () => {
    const {
      updateBackgroundSetConfig,
      closePopup,
      showInline,
      updateStateFunction,
      applyFunction,
      selectedSetInfo,
    } = this.props;
    const {backgroundSetConfig} = this.state;

    updateBackgroundSetConfig(backgroundSetConfig);
    if (showInline) {
      updateStateFunction(backgroundSetConfig);
      applyFunction(selectedSetInfo);
    }
    closePopup();
  };

  handleProjectClick = (id) => {
    const { getAvailableSets } = this.props;
    getAvailableSets({ projectId: id });
  };


  render() {
    const {
      sets,
      projectId,
      setsLoading,
      cells,
      cellsLoading,
      backgroundSetOptions: {
        cellTypeOption,
        selectSetOption,
      },
      backgroundCellsFilterOption,
      setActiveProjectId,
      clearActiveProjectId,
      showInline = false,
      selectedSetInfo,
    } = this.props;

    const { backgroundSetConfig: { cell, backgroundSetType }, sortSetsDESC } = this.state;
    const sortedSets = this.sortSets(sets);
    const cellFilterOptions = cells ? Object.keys(cells).map(item => item) : [];

    const mainClass = classNames({'background-set-popup': !showInline, 'background-set-inline': showInline});

    const allGenesItemClass = classNames('background-set-popup__item', {
      'background-set-popup__item_selected': backgroundSetType === 'GENES',
    });
    const cellTypeItemClass = classNames('background-set-popup__item', {
      'background-set-popup__item_active': cellTypeOption && cells && !cellsLoading,
      'background-set-popup__item_selected': backgroundSetType === 'CELL' && !cellsLoading,
    });
    const selectSetItemClass = classNames('background-set-popup__item', {
      'background-set-popup__item_active': selectSetOption,
      'background-set-popup__item_selected': backgroundSetType === 'SET' && !setsLoading,
    });

    const sortBtnClass = classNames('background-sets-sort__btn', {
      'background-sets-sort__btn_up': sortSetsDESC === false,
    });

    const cellsFilterBlockHeight = cellFilterOptions ?
      this.calcBackgroundSetOptionHeight(cellFilterOptions, 40) : 0;

    const cellsBlockHeight = cells ?
      this.calcBackgroundSetOptionHeight(cells[backgroundCellsFilterOption], 40) : 0;

    const setsBlockHeight = sets.length ?
      this.calcBackgroundSetOptionHeight(sets, 40) : 0;

    return (
      <div className={mainClass}>
        <div className="background-set-popup__title">
          Select background set
        </div>
        <div className="background-set-popup__content">
          <div className={allGenesItemClass}>
            <div
              className="background-set-popup__option"
              onClick={this.selectAllGenes}
            >
              <span className="background-set-popup__subtitle">
                All human genes
              </span>
            </div>
          </div>
          <div className={cellTypeItemClass}>
            <div
              className="background-set-popup__option"
              onClick={() => { this.toggleBackgroundSetOption('cellTypeOption'); }}
            >
              <span className="background-set-popup__subtitle">
                {
                  cell ?
                    `Genes expressed in specific cell type "${cell.name}"` :
                    'Genes expressed in specific cell type'
                }
              </span>
              <Spinner
                isLoading={cellsLoading}
                containerClassName="background-set-popup__spinner"
              />
            </div>
            <div className="background-set-popup__data">
              {
                cells && cells[backgroundCellsFilterOption].length > 0 &&
                <div className="background-sets-list">
                  <div className="background-sets-list__column">
                    <AutoSizer disableHeight={true}>
                      {({ width }) => (
                        <List
                          ref={(ref) => { this.cellFilterListRef = ref; }}
                          width={width}
                          height={cellsFilterBlockHeight}
                          rowCount={cellFilterOptions.length}
                          rowHeight={40}
                          rowRenderer={this.cellFilterRowRenderer}
                        />
                      )}
                    </AutoSizer>
                  </div>
                  <div className="background-sets-list__column background-sets-list__column_last">
                    <AutoSizer disableHeight={true}>
                      {({ width }) => (
                        <List
                          ref={(ref) => { this.cellListRef = ref; }}
                          width={width}
                          height={cellsBlockHeight}
                          rowCount={cells[backgroundCellsFilterOption].length}
                          rowHeight={40}
                          rowRenderer={this.cellRowRenderer}
                          data={cells[backgroundCellsFilterOption]}
                        />
                      )}
                    </AutoSizer>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className={selectSetItemClass}>
            <div
              className="background-set-popup__option"
              onClick={() => { this.toggleBackgroundSetOption('selectSetOption'); }}
            >
              <span className="background-set-popup__subtitle">
                Selected Set
              </span>
              <Spinner
                isLoading={cellsLoading}
                containerClassName="background-set-popup__spinner"
              />
            </div>
            <div className="background-set-popup__data">
              <ProjectsTabs
                setActiveProjectId={setActiveProjectId}
                clearActiveProjectId={clearActiveProjectId}
                activeProjectId={projectId}
                handleProjectClick={this.handleProjectClick}
              />
              {
                sets.length > 0 &&
                <div className="background-sets-list">
                  <div className="background-sets-list__items">
                    <AutoSizer disableHeight={true}>
                      {({ width }) => (
                        <List
                          ref={(ref) => { this.setListRef = ref; }}
                          width={width}
                          height={setsBlockHeight}
                          rowCount={sets.length}
                          rowHeight={40}
                          rowRenderer={param => this.setRowRenderer(param, sortedSets)}
                        />
                      )}
                    </AutoSizer>
                  </div>
                  <div className="background-sets-settings">
                    <div className="background-sets-sort">
                      <div
                        className={sortBtnClass}
                        onClick={this.toggleSortSetsDESC}
                      />
                      <div className="background-sets-sort__txt">Sort</div>
                    </div>

                  </div>
                </div>
              }
            </div>
          </div>
          { !showInline &&
            <div className="background-sets-submit">
              <button
                className="background-sets-submit__btn"
                onClick={this.updateBackgroundSetConfig}
              >
                Apply
              </button>
            </div>
          }
          { showInline &&
            <button
              className="button button-secondary concepts-search-rightbar__btn-run"
              disabled={!selectedSetInfo?.setId}
              onClick={this.updateBackgroundSetConfig}
            >
              Get Analysis
            </button>
          }
        </div>
      </div>
    );
  }
}

SelectBackgroundSetComponent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sets: getAvailableSetsListSelector(state),
    setsLoading: getAvailableSetsLoadingSelector(state),
    cells: getBackgroundCellsSelector(state),
    projectId: getActiveProjectIdSelector(state),
    cellsLoading: getBackgroundCellsLoaderSelector(state),
    backgroundSetOptions: getBackgroundSetOptionsSelector(state),
    backgroundSetConfig: getBackgroundSetConfigSelector(state),
    backgroundCellsFilterOption: getBackgroundCellsFilterOptionSelector(state),
    selectedSetInfo: getSelectedSetInfoSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAvailableSets(data) {
      dispatch(getAvailableSetsAction(data));
    },
    getBackgroundCells() {
      dispatch(getBackgroundCellsAction());
    },
    updateBackgroundSetConfig(data) {
      dispatch(updateBackgroundSetConfigAction(data));
    },
    toggleBackgroundSetOption(data) {
      dispatch(toggleBackgroundSetOptionAction(data));
    },
    setBackgroundCellsFilterOption(data) {
      dispatch(setBackgroundCellsFilterOptionAction(data));
    },
    setBackgroundSetFilterOption(data) {
      dispatch(setBackgroundSetFilterOptionAction(data));
    },
    setActiveProjectId(data) {
      dispatch(setBackgroundSetActiveProjectId(data));
    },
    clearActiveProjectId() {
      dispatch(clearBackgroundSetActiveProjectId());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectBackgroundSetComponent);
