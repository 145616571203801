import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// Components
import SelectInput from '../Inputs/SelectInput/SelectInput';
// Styles
import './FormSelect.scss';

const propTypes = {
  options: PropTypes.instanceOf(Array),
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  customClassName: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  returnOption: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  optionKey: PropTypes.string,
  mandatory: PropTypes.bool,
  dropDownHeight: PropTypes.number,
  selectedValue: PropTypes.instanceOf(Object),
  formatOptionLabel: PropTypes.func,
  bordered: PropTypes.bool,
};

const FormSelect = (props) => {
  const {
    input: {
      value,
      onChange,
      onFocus,
    },
    meta: {
      error,
      touched,
    },
    bordered,
    options,
    placeholder,
    disabled,
    isMulti,
    isLoading,
    isClearable,
    isSearchable,
    customClassName,
    returnOption,
    optionKey,
    mandatory,
    dropDownHeight,
    selectedValue,
    formatOptionLabel,
    hideSelectedOptions,
  } = props;

  const defaultSelectorValue = useMemo(() => {
    if (!options) {
      return '';
    }
    if (returnOption) {
      return selectedValue || value || [];
    }
    if (isMulti) {
      return options.filter(option => value.includes(option.value || option.type));
    }
    return options.find(o => value === o.value || value === o.type);
  }, [options, returnOption, isMulti, value, selectedValue]);

  const onSelect = (data) => {
    const key = optionKey || 'value';
    if (!data) {
      return onChange(data);
    }
    if (returnOption) {
      return onChange(data);
    }
    if (isMulti) {
      return onChange(data.map(i => i[key]));
    }
    return onChange(data[key]);
  };

  const styles = {
    valueContainer: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    input: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
    }),
    menuList: provided => ({
      ...provided,
      ...(dropDownHeight && {
        maxHeight: dropDownHeight,
        overflow: 'scroll',
      }),
      textAlign: 'left',
    }),
  };

  return (
    <SelectInput
      onSelect={data => onSelect(data)}
      bordered={bordered}
      onFocus={onFocus}
      mandatory={mandatory}
      isMulti={isMulti}
      isDisabled={disabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={options}
      styles={styles}
      placeholder={placeholder}
      customClassName={customClassName}
      defaultValue={defaultSelectorValue}
      formatOptionLabel={formatOptionLabel}
      hideSelectedOptions={hideSelectedOptions}
      showError={touched && error}
      error={error}
    />
  );
};

FormSelect.propTypes = propTypes;

export default React.memo(FormSelect);
