import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Components
import SetNewPassword from '../SetNewPassword/SetNewPassword';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ActivateCreatedUser from '../ActivateCreatedUser/ActivateCreatedUser';
// Storage
import { setNewPasswordModalCloseAction, userForgotPasswordTokenCheckAction } from '../SetNewPassword/store/reducer';
import { activateUserTokenCheckAction, setActivateUserModalCloseAction } from '../ActivateCreatedUser/store/reducer';
import { getShowNewPasswordModal } from '../SetNewPassword/store/selectors';
import { getShowActivateUserModal } from '../ActivateCreatedUser/store/selectors';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
// Styles
import '../../../theme/styles/formControls.css';

const propTypes = {
  showNewPasswordModal: PropTypes.bool,
  closeSetNewPasswordModal: PropTypes.func,
  showActivateCreatedUserModal: PropTypes.func,
  closeActivateCreatedUserModal: PropTypes.func,
  checkForgotPasswordToken: PropTypes.func,
  checkActivateUserToken: PropTypes.func,
};

const CheckPasswordPage = (props) => {
  const {
    showNewPasswordModal,
    showActivateCreatedUserModal,
    closeSetNewPasswordModal,
    closeActivateCreatedUserModal,
    checkForgotPasswordToken,
    checkActivateUserToken,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const [, url] = location.pathname.split('/');
    if (url === 'forgot-password') {
      checkForgotPasswordToken(token);
    }
    if (url === 'activate-account') {
      checkActivateUserToken(token);
    }
  }, []);

  const handleSetNewPasswordModalClose = () => {
    closeSetNewPasswordModal();
    navigate(`${RELATIVE_PATH}/login`);
  };

  const handleActivateCreatedUserModalClose = () => {
    closeActivateCreatedUserModal();
    navigate(`${RELATIVE_PATH}/login`);
  };

  return (
    <div className="check-password">
      {
        showNewPasswordModal &&
        <ModalComponent
          isOpen={showNewPasswordModal}
          closeCb={handleSetNewPasswordModalClose}
          customClassName="check-token-modal"
        >
          <SetNewPassword />
        </ModalComponent>
      }
      {
        showActivateCreatedUserModal &&
        <ModalComponent
          isOpen={showActivateCreatedUserModal}
          closeCb={handleActivateCreatedUserModalClose}
          customClassName="check-token-modal"
        >
          <ActivateCreatedUser />
        </ModalComponent>
      }
    </div>
  );
};

CheckPasswordPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    showNewPasswordModal: getShowNewPasswordModal(state),
    showActivateCreatedUserModal: getShowActivateUserModal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeSetNewPasswordModal() {
      dispatch(setNewPasswordModalCloseAction());
    },
    checkForgotPasswordToken(data) {
      dispatch(userForgotPasswordTokenCheckAction(data));
    },
    checkActivateUserToken(data) {
      dispatch(activateUserTokenCheckAction(data));
    },
    closeActivateCreatedUserModal() {
      dispatch(setActivateUserModalCloseAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckPasswordPage);
