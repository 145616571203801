import {
  call,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';

import { DUPLICATE_SET } from './constants';

function* duplicateSetSaga(action) {
  try {
    yield call(Api.duplicateSet, { params: { 'set-id': action.data.setId, title: action.data.setName } });
  } catch (e) {
    console.log(e);
  }
}

function* duplicateSet() {
  yield takeLatest(DUPLICATE_SET, duplicateSetSaga);
}

export default duplicateSet;
