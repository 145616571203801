import React, {useRef} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
// Store
import * as SELECTORS from '../../store/selectors';
import * as ACTIONS from '../../store/actions';
// Components
import Loader from '../../../../../common/Loader/Loader';
import Error from '../../../../../common/Error/Error';
import SimpleTable from '../../../../../common/SimpleTable/SimpleTable';
import ExportTable from '../../../../../common/ExportTable/ExportTable';
import ShortConceptCardCell from '../../../../../Concept/ShortConceptCard/ShortConceptCardCell';
// Utils
import { RELATIVE_PATH } from '../../../../../../constantsCommon';
import { AVERAGE_SYMBOL_LENGTH } from '../../../../../Utils/Utils';

const propTypes = {
  allData: PropTypes.instanceOf(Array),
  paginatedData: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  error: PropTypes.string,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
  selectedGenes: PropTypes.instanceOf(Object),
  sorting: PropTypes.instanceOf(Object),
  twoGenesSelected: PropTypes.bool,
};

const ResultsTable = (props) => {
  const {
    allData,
    paginatedData = [],
    loading,
    error,
    page,
    totalPages,
    changePage,
    twoGenesSelected,
    sorting: {
      sortBy,
      sortDirection,
    },
    selectedGenes,
  } = props;

  const TABLE_WIDTH = twoGenesSelected ? 2000 : 1200;
  const simpleTableRef = useRef(null);

  const getRowHeight = ({ index }) => {
    const padding = 15;
    const lineHeight = 25;
    const cellWidth = getColumnPercentWidth(twoGenesSelected ? 14 : 20);
    const string = paginatedData[index]?.cancer?.name?.length || 10;
    const stringRows = Math.ceil((string * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    return (stringRows * lineHeight) + padding;
  };

  const tableSettings = {
    height: 500,
    width: TABLE_WIDTH,
    headerHeight: 50,
    rowHeight: getRowHeight,
    rowClassName: 'table-wrap__row',
    autoHeight: true,
    sortBy,
    sortDirection,
  };

  const handlePageClick = (page) => {
    changePage(page.selected);
  };

  const handleConceptCell = (id, name, index) => {
    const link = `${RELATIVE_PATH}/concept-details/${id}`;
    const uniqueKey = `tooltip-${id}-${index}`;

    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  const measureToFix = (measure) => {
    if (measure == null || !Number.isFinite(measure)) return '';

    return measure.toFixed(2);
  };
  
  const getColumnPercentWidth = (percent) => {
    const screenWidth = TABLE_WIDTH;
    return (percent * screenWidth) / 100;
  };

  const getColumns = () => {
    let columnsForTable = [
      {
        label: 'Tissue',
        dataKey: 'primarySite',
        dataPath: ['primarySite'],
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: getColumnPercentWidth(twoGenesSelected ? 10 : 18),
      },
      {
        label: 'Cell line',
        dataKey: 'cellLine',
        dataPath: ['cellLine', 'name'],
        sortPath: ['cellLine', 'name'],
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: getColumnPercentWidth(twoGenesSelected ? 10 : 18),
        cellRenderer: ({ rowData: { cellLine }, rowIndex }) => (
          handleConceptCell(cellLine.id, cellLine.name, rowIndex)
        ),
      },
      {
        label: 'Cancer type',
        dataKey: 'cancer',
        dataPath: ['cancer', 'name'],
        sortPath: ['cancer', 'name'],
        exportCSV: true,
        className: 'table-wrap__cell',
        width: getColumnPercentWidth(twoGenesSelected ? 14 : 20),
        cellRenderer: ({ rowData: { cancer } }) => (
          <div className="table-wrap__cell-row">
            {cancer.name}
          </div>
        ),
      }];

    if (twoGenesSelected) {
      columnsForTable = [ ...columnsForTable,
        {
          label: 'CCLE RNA',
          dataKey: 'firstGeneRna',
          dataPath: ['measures', 'GENE1', 'rna'],
          sortPath: ['measures', 'GENE1', 'rna'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.rna)}
            </div>
          ),
        },
        {
          label: 'CCLE Protein',
          dataKey: 'firstGeneProtein',
          dataPath: ['measures', 'GENE1', 'protein'],
          sortPath: ['measures', 'GENE1', 'protein'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.protein)}
            </div>
          ),
        },
        {
          label: 'SCMP Protein',
          dataKey: 'firstGeneSanger',
          dataPath: ['measures', 'GENE1', 'sanger'],
          sortPath: ['measures', 'GENE1', 'sanger'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.sanger)}
            </div>
          ),
        },
        {
          label: 'CCLE RNA',
          dataKey: 'secondGeneRna',
          dataPath: ['measures', 'GENE2', 'rna'],
          sortPath: ['measures', 'GENE2', 'rna'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE2.rna)}
            </div>
          ),
        },
        {
          label: 'CCLE Protein',
          dataKey: 'secondGeneProtein',
          dataPath: ['measures', 'GENE2', 'protein'],
          sortPath: ['measures', 'GENE2', 'protein'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE2.protein)}
            </div>
          ),
        },
        {
          label: 'SCMP Protein',
          dataKey: 'secondGeneSanger',
          dataPath: ['measures', 'GENE2', 'sanger'],
          sortPath: ['measures', 'GENE2', 'sanger'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE2.sanger)}
            </div>
          ),
        },
        {
          label: 'CCLE RNA',
          dataKey: 'meanRna',
          dataPath: ['measures', 'MEAN', 'rna'],
          sortPath: ['measures', 'MEAN', 'rna'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.MEAN.rna)}
            </div>
          ),
        },
        {
          label: 'CCLE Protein',
          dataKey: 'meanProtein',
          dataPath: ['measures', 'MEAN', 'protein'],
          sortPath: ['measures', 'MEAN', 'protein'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.MEAN.protein)}
            </div>
          ),
        },
        {
          label: 'SCMP Protein',
          dataKey: 'meanSanger',
          dataPath: ['measures', 'MEAN', 'sanger'],
          sortPath: ['measures', 'MEAN', 'sanger'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.MEAN.sanger)}
            </div>
          ),
        },
        {
          label: 'CCLE RNA',
          dataKey: 'log2Rna',
          dataPath: ['measures', 'LOG2', 'rna'],
          sortPath: ['measures', 'LOG2', 'rna'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.LOG2.rna)}
            </div>
          ),
        },
        {
          label: 'CCLE Protein',
          dataKey: 'log2Protein',
          dataPath: ['measures', 'LOG2', 'protein'],
          sortPath: ['measures', 'LOG2', 'protein'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.LOG2.protein)}
            </div>
          ),
        },
        {
          label: 'SCMP Protein',
          dataKey: 'log2Sanger',
          dataPath: ['measures', 'LOG2', 'sanger'],
          sortPath: ['measures', 'LOG2', 'sanger'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(6),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.LOG2.sanger)}
            </div>
          ),
        }];
    } else {
      columnsForTable = [ ...columnsForTable,
        {
          label: 'CCLE RNA',
          dataKey: 'firstGeneRna',
          dataPath: ['measures', 'GENE1', 'rna'],
          sortPath: ['measures', 'GENE1', 'rna'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(18),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.rna)}
            </div>
          ),
        },
        {
          label: 'CCLE Protein',
          dataKey: 'firstGeneProtein',
          dataPath: ['measures', 'GENE1', 'protein'],
          sortPath: ['measures', 'GENE1', 'protein'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(18),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.protein)}
            </div>
          ),
        },
        {
          label: 'SCMP Protein',
          dataKey: 'firstGeneSanger',
          dataPath: ['measures', 'GENE1', 'sanger'],
          sortPath: ['measures', 'GENE1', 'sanger'],
          exportCSV: true,
          className: 'table-wrap__cell',
          width: getColumnPercentWidth(18),
          cellRenderer: ({ rowData: { measures } }) => (
            <div className="table-wrap__cell-row">
              {measureToFix(measures.GENE1.sanger)}
            </div>
          ),
        }];
    }

    return columnsForTable;
  };

  const getCsvFileName = () => {
    if (selectedGenes.secondGene && selectedGenes.secondGene.name) {
      return `cell-line-selection-${selectedGenes.firstGene.name}-${selectedGenes.secondGene.name}`;
    } else {
      return `cell-line-selection-${selectedGenes.firstGene.name}`;
    }
  };

  return (
    <div className="results-data-block">
      {
        allData && allData.length > 0 && !loading &&
        <div className="table-wrap">
          <div className="controls-block-2items">
            {
              allData.length > 10 &&
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
            }
            {
              allData && allData.length > 0 &&
                 <ExportTable
                   content={allData}
                   columns={getColumns()}
                   fileName={getCsvFileName()}
                 />
            }
          </div>
          <div
            className="similar-proteins__table"
          >
            {
              twoGenesSelected &&
            <div
              className="similar-proteins__table-header"
              style={{ width: TABLE_WIDTH }}
            >
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(14) + 10 }}
              >

              </div>
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(14) }}
              >
              </div>
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(18) }}
              >
                {selectedGenes.firstGene.name}
              </div>
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(18) }}
              >
                {selectedGenes.secondGene.name}
              </div>
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(18) }}
              >
                MEAN
              </div>
              <div
                className="similar-proteins__table-header-cell"
                style={{ width: getColumnPercentWidth(18) }}
              >
                LOG2
              </div>
            </div>
            }
            <SimpleTable
              data={paginatedData}
              innerRef={simpleTableRef}
              // autoSize={true}
              settings={tableSettings}
              columns={getColumns()}
              sortAction={ACTIONS.sortResultsDataAction}
              dynamicHeight={true}
            />
          </div>
        </div>
      }
      <Loader isLoading={loading} />
      <Error show={!!error} error={error} />
    </div>
  );
};

ResultsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    twoGenesSelected: SELECTORS.getIsTwoGenesSelectedSelector(state),
    selectedGenes: SELECTORS.getSelectedGenesSelector(state),
    allData: SELECTORS.getAllResultsDataSelector(state),
    paginatedData: SELECTORS.getPaginatedResultsDataSelector(state),
    sorting: SELECTORS.getResultsDataSortingSelector(state),
    loading: SELECTORS.getResultsDataLoadingSelector(state),
    error: SELECTORS.getResultsDataErrorSelector(state),
    page: SELECTORS.getResultsDataPageSelector(state),
    totalPages: SELECTORS.getResultsDataTotalPagesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(ACTIONS.changeResultsDataPageAction(data));
    },
  };
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsTable));

