import { fromJS } from 'immutable';

import { PAGE_SIZE } from '../../constants';

import { types } from './constants';

const initialState = fromJS({
  chart: [],
  table: {
    content: [],
    page: 0,
    totalPages: 0,
  },
  sorting: {
    sortBy: 'lastUpdateDate',
    sortDirection: 'ASC',
  },
  filter: [],
  error: null,
  loading: false,
});

const geneDetailsClinicalTrials = (state = initialState, action) => {
  switch (action.type) {
    case types.GENE_DETAILS_CLINICAL_TRIALS_INIT_DATA: {
      const { data } = action;
      const totalPages = Math.ceil((data || []).length / PAGE_SIZE);

      return state
        .updateIn(['table', 'content'], () => fromJS(data))
        .updateIn(['table', 'totalPages'], () => totalPages)
        .update('loading', () => false);
    }

    case types.GENE_DETAILS_CLINICAL_TRIALS_INIT_FAILED: {
      return state
        .update('error', () => action.message)
        .update('loading', () => false);
    }

    case types.GENE_DETAILS_CLINICAL_TRIALS_CHANGE_PAGE: {
      return state.updateIn(['table', 'page'], () => action.data);
    }

    case types.GENE_DETAILS_CLINICAL_TRIALS_FILTER_TABLE: {
      return state.set('filter', action.data);
    }

    case types.GENE_DETAILS_CLINICAL_TRIALS_SORT_TABLE: {
      return state.update('sorting', () => action.data);
    }

    case types.GENE_DETAILS_CLINICAL_TRIALS_LOADING:
      return state.update('loading', () => true);

    default:
      return state;
  }
};

export default geneDetailsClinicalTrials;

