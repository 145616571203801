import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { resetCachedConceptIdsForCategoryAction } from '../../../store/actions';

export const getSemanticCategoriesTabsDataAction = createAction('@@semanticCategoryTabs/GET_DATA');
export const setSemanticCategoriesTabsDataAction = createAction('@@semanticCategoryTabs/SET_DATA');
export const cacheSemanticCategoriesTabsData = createAction('@@semanticCategoryTabs/CACHE_DATA');
export const semanticCategoriesTabsLoadingAction = createAction('@@semanticCategoryTabs/LOADING');
export const semanticCategoriesTabsErrorAction = createAction('@@semanticCategoryTabs/ERROR');
export const openOtherCategoriesDropdownAction = createAction('@@semanticCategoryTabs/OPEN_OTHER_CATEGORIES_DROPDOWN');
export const closeOtherCategoriesDropdownAction = createAction('@@semanticCategoryTabs/CLOSE_OTHER_CATEGORIES_DROPDOWN');
export const getSemanticTypesForFilterAction = createAction('@@semanticCategoryTabs/GET_SEMANTIC_TYPES_FOR_FILTER');
export const setSemanticTypesForFilterAction = createAction('@@semanticCategoryTabs/SET_SEMANTIC_TYPES_FOR_FILTER');
export const clearSemanticCategoriesTabsDataAction = createAction('@@semanticCategoryTabs/CLEAR_SEMANTIC_CATEGORIES_TABS_DATA');

const initialState = fromJS({
  conceptsIds: [],
  semanticCategoriesTabData: [],
  cachedSemanticCategoriesTabData: [],
  semanticCategoriesForFilter: [],
  semanticTypesForFilter: {},
  otherCategoriesDropdown: false,
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [setSemanticCategoriesTabsDataAction]: (state, { payload }) =>
      state
        .update('conceptsIds', () => fromJS(payload.conceptsIds))
        .update('semanticCategoriesTabData', () => fromJS(payload.majorCategories))
        .update('cachedSemanticCategoriesTabData', () => fromJS(payload.majorCategories))
        .update('semanticCategoriesForFilter', () => fromJS(payload.otherCategories))
        .update('error', () => null),
    [cacheSemanticCategoriesTabsData]: state =>
      state.update('cachedSemanticCategoriesTabData', () => state.get('semanticCategoriesTabData')),
    [resetCachedConceptIdsForCategoryAction]: state =>
      state.update('cachedSemanticCategoriesTabData', () => initialState.get('cachedSemanticCategoriesTabData')),
    [semanticCategoriesTabsLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [semanticCategoriesTabsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [openOtherCategoriesDropdownAction]: state =>
      state.update('otherCategoriesDropdown', () => true),
    [closeOtherCategoriesDropdownAction]: state =>
      state.update('otherCategoriesDropdown', () => false),
    [setSemanticTypesForFilterAction]: (state, { payload }) =>
      state.update('semanticTypesForFilter', () => fromJS(payload)),
    [clearSemanticCategoriesTabsDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
