import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './styles.scss';

const propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  error: PropTypes.string,
  customClassName: PropTypes.string,
  noTitle: PropTypes.bool,
};

const Error = (props) => {
  const {
    show,
    title = 'Sorry, error occurred',
    error,
    customClassName,
    noTitle = false,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames('error', customClassName)}
      data-testid="error-testid"
    >
      {
        !noTitle &&
        <div className="error__title">
          {title}
        </div>
      }
      <div className="error__message">
        {error}
      </div>
    </div>
  );
};

Error.propTypes = propTypes;

export default React.memo(Error);
