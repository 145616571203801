import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Store
import * as SELECTORS from '../../store/selectors';
import * as ACTIONS from './../../store/actions';
// Components
import SelectInput from '../../../../../common/Inputs/SelectInput/SelectInput';
import {HeatmapMeasureEnum, HeatMapMeasuresOptions} from '../../constants';
import DoubleBoxPlotChart from '../../../../../graphics/DoubleBoxPlotChart/DoubleBoxPlotChart';

const propTypes = {
  dataForDoubleChart: PropTypes.instanceOf(Object),
  firstGeneData: PropTypes.instanceOf(Object),
  secondGeneData: PropTypes.instanceOf(Object),
  selectedGenes: PropTypes.instanceOf(Object),
  twoGenesSelected: PropTypes.bool,
  scale: PropTypes.string,
  changeScale: PropTypes.func,
};

const ResultsExpressionGraphsModal = (props) => {
  const {
    dataForDoubleChart,
    selectedGenes: { firstGene, secondGene },
    changeScale,
    scale,
  } = props;

  const [selectedMeasure, setSelectedMeasure] = useState(HeatMapMeasuresOptions[0]);

  const getMeasureOptions = () => {
    return HeatMapMeasuresOptions.filter(option => {
      return dataForDoubleChart[option.type].length > 0;
    });
  };

  const getTitle = (type, geneName) => {
    switch (type) {
      case 'rna': {
        return `CCLE gene expression for ${geneName}`;
      }
      case 'protein': {
        return `CCLE protein expression for ${geneName}`;
      }
      case 'sanger': {
        return `SCMP gene protein for ${geneName}`;
      }
      default:
        return '';
    }
  };

  const getExpressionName = (type, geneName) => {
    switch (type) {
      case 'rna': {
        return `CCLE_gene_expression_for_${geneName}`;
      }
      case 'protein': {
        return `CCLE_protein_expression_for_${geneName}`;
      }
      case 'sanger': {
        return `SCMP_gene_protein_for_${geneName}`;
      }
      default:
        return '';
    }
  };

  const transformData = (scale) => {
    changeScale(scale);
  };

  const renderDoubleGraphsForSpecificGene = (type) => {
    const title = secondGene ?
      getTitle(type, firstGene.name)+` and ${secondGene.name}`
      : getTitle(type, firstGene.name);
    const expressionName = secondGene ?
      getExpressionName(type, firstGene.name)+`_${secondGene.name}`
      : getExpressionName(type, firstGene.name);

    if (HeatmapMeasureEnum.RNA !== type && scale !== 'linear') {
      changeScale('linear');
    }

    return (
      <div className="cell-line-diagram">
        <h5 className="title5">{title}</h5>
        <DoubleBoxPlotChart
          id={`${type}-boxplot-for-${firstGene.id}-${secondGene?.id}`}
          expressionName={expressionName}
          chartTitle={title}
          data={dataForDoubleChart[type]}
          groupsKey="value"
          showLegend={!!secondGene?.name}
          subgroups={secondGene?.name ?
            [`${firstGene.name}`, `${secondGene.name}`]
            : [`${firstGene.name}`]}
          plotYScale={scale}
          logScale={HeatmapMeasureEnum.RNA === type}
          changeDataTransform={transformData}
        />
      </div>
    );
  };

  return (
    <div className="results-expression-section">
      <SelectInput
        closeOnSelect={true}
        isMulti={false}
        bordered={true}
        defaultValue={getMeasureOptions()[0]}
        customClassName="ortholog-selector"
        options={getMeasureOptions()}
        onSelect={option => setSelectedMeasure(option)}
      />

      {
        selectedMeasure.type === HeatmapMeasureEnum.RNA && dataForDoubleChart.rna.length > 0 && renderDoubleGraphsForSpecificGene('rna')
      }
      {
        selectedMeasure.type === HeatmapMeasureEnum.PROTEIN && dataForDoubleChart.protein.length > 0 && renderDoubleGraphsForSpecificGene('protein')
      }
      {
        selectedMeasure.type === HeatmapMeasureEnum.SANGER && dataForDoubleChart.sanger.length > 0 && renderDoubleGraphsForSpecificGene('sanger')
      }
    </div>
  );
};

ResultsExpressionGraphsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    dataForDoubleChart: SELECTORS.getDataForDoubleGraphsSelector(state),
    selectedGenes: SELECTORS.getSelectedGenesSelector(state),
    scale: SELECTORS.getChartScale(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeScale(data) {
      dispatch(ACTIONS.updateChartScaleAction(data));
    },
  };
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsExpressionGraphsModal));

