import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { connect } from 'react-redux';
import { FaUser } from 'react-icons/fa';
// Store
import { userLogoutRequestAction } from '../reducer';
import { changePasswordModalOpen } from '../ChangePassword/actions';
import { userMenuOpenAction, userMenuCloseAction } from './reducer';
import { getMenuOpenKey } from './selectors';
import { getUserFullName, getUserAccountData, getUserName } from '../../Header/selectors';
// Utils
import { checkGenmabAccount } from '../../Utils/Utils';
// Styles
import './UserDropdown.scss';

const propTypes = {
  open: PropTypes.bool,
  userMenuOpen: PropTypes.func,
  userMenuClose: PropTypes.func,
  changePassword: PropTypes.func,
  logout: PropTypes.func,
  helpIsOpen: PropTypes.string,
  setHelpIsOpen: PropTypes.func,
  userFullName: PropTypes.string,
  accountData: PropTypes.instanceOf(Object),
  username: PropTypes.string,
};

const UserDropdown = (props) => {
  const {
    open,
    logout,
    userFullName,
    userMenuOpen,
    userMenuClose,
    changePassword,
    accountData,
    username,
  } = props;

  function toggle() {
    if (open) {
      userMenuClose();
    } else {
      userMenuOpen();
    }
  }

  const menuOptions = {
    isOpen: open,
    close: userMenuClose,
    toggle: (
      <div onClick={toggle} className="user-block">
        <FaUser size={14} color="#ffffff" />
        <span className="user-block__name">
          {userFullName ? userFullName.slice(0, 20) : ''}
        </span>
      </div>
    ),
    align: 'right',
    textAlign: 'left',
  };

  const isGenmabAccount = useMemo(() => checkGenmabAccount(username), [username]);

  return (
    <div>
      <DropdownMenu {...menuOptions}>
        <li>
          <span
            className="drop-down-menu-item not-clickable-item"
          >
            {accountData.accountType} account till {accountData.expiration}
          </span>
        </li>
        {
          !isGenmabAccount &&
            <>
              <li>
                <span
                  className="drop-down-menu-item"
                  onClick={changePassword}
                >
                  Settings
                </span>
              </li>
              <li>
                <span
                  className="drop-down-menu-item"
                  onClick={logout}
                >
                 Logout
                </span>
              </li>
            </>
        }
      </DropdownMenu>
    </div>
  );
};

UserDropdown.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    open: getMenuOpenKey(state),
    userFullName: getUserFullName(state),
    accountData: getUserAccountData(state),
    username: getUserName(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userMenuOpen() {
      dispatch(userMenuOpenAction());
    },
    userMenuClose() {
      dispatch(userMenuCloseAction());
    },
    logout() {
      dispatch(userLogoutRequestAction());
    },
    changePassword() {
      dispatch(changePasswordModalOpen());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDropdown);
