import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { convertToRGB, sort } from '../../Utils/Utils';

const sets = state => state.getIn(['setsDataSelectionReducer', 'sets']);
const checkedSets = state => state.getIn(['setsDataSelectionReducer', 'checkedSets']);
const dataSelection = state => state.getIn(['setsDataSelectionReducer', 'dataSelection']);
const sorting = state => state.getIn(['setsDataSelectionReducer', 'sorting']);

export const getSetsData = createSelector(
  sets,
  data => data
);

export const getCheckedSets = createSelector(
  checkedSets,
  data => data && data.toJS()
);

export const getSetsVennDiagramData = createSelector(
  [getSetsData],
  (_sets) => {
    const data = [];
    const colors = [];
    _sets.forEach((set) => {
      if (set.active && set.data && set.data.length) {
        data.push(set);
        colors.push(convertToRGB(set.color));
      }
    });

    return {
      data,
      colors,
    };
  }
);

export const dataSelectionTableData = createSelector(
  dataSelection,
  sorting,
  (_dataSelection, _sorting) => {
    const sorted = sort(_dataSelection, _sorting && _sorting.toJS());
    return sorted && sorted.toJS();
  }
);

export const getSorting = createSelector(
  sorting,
  _sorting => _sorting && _sorting.toJS()
);

export const selectedDataForAnalysis = createSelector(
  dataSelection,
  (_dataSelection) => {
    const dataForAnalysis = _dataSelection
      .filter(item => item.get('selected'))
      .map(item => new Map({
        id: item.get('id'),
        name: item.get('concept'),
        type: item.get('type'),
      }));
    return dataForAnalysis && dataForAnalysis.toJS();
  }
);
