import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { AutoSizer } from 'react-virtualized';

// Components
import Error from '../../common/Error/Error';
import NoData from '../../common/NoData/NoData';
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';
import InteractiveCellPic from './InteractiveCellPic';
// Store
import { loadNextChapterAction } from '../actions';
import {
  getProteinLocationDataAction,
  sortProteinLocationDataAction,
  changeProteinLocationPageAction,
} from './reducer';
import * as SELECTORS from './selectors';


import '../GeneDetails.scss';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  getProteinLocationData: PropTypes.func,
  loadNextChapter: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  sorting: PropTypes.instanceOf(Object),
  proteinLocations: PropTypes.instanceOf(Array),
  allProteinLocations: PropTypes.instanceOf(Array),
  imageTags: PropTypes.instanceOf(Array),
  changePage: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number,
};

class ProteinLocationChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: false,
      popup: false,
    };
    this.columns = null;
    this.initPage();
  }

  initPage = () => {
    const {
      gene: id,
      itsNewConcept,
      getProteinLocationData,
      loadNextChapter,
      proteinLocations,
      imageTags,
    } = this.props;
    if (itsNewConcept || (!proteinLocations.length || !imageTags.length)) {
      getProteinLocationData({ id });
    } else {
      loadNextChapter('gene-expression');
    }
  };

  handlePageClick = (page) => {
    const { changePage } = this.props;
    changePage(page.selected);
  };

  toggleInfoText = (stateParam, stateProp) => {
    this.setState({ [stateParam]: !stateProp });
  };

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data;
    this.conceptsNames = [geneName, name];
    this.conceptsIds = [gene, id];
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  locationNameCell = ({ id, name }, rowIndex) => {
    const uniqueKey = `protein-location-tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  getColumns = (width) => {
    this.columns = [
      {
        label: 'Sub-cellular location of gene/protein',
        dataKey: 'name',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(width, 50),
        cellRenderer: ({ rowData, rowIndex }) => this.locationNameCell(rowData, rowIndex),
      },
      {
        label: 'References',
        dataKey: 'count',
        exportCSV: true,
        width: this.getColumnPercentWidth(width, 50),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (
          rowData.count > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openPublicationsPopup(rowData); }}
            >
              {rowData.count}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.count}
            </div>
        ),
      },
    ];
    return this.columns;
  };

  render() {
    const {
      proteinLocations,
      allProteinLocations,
      imageTags,
      sorting: { sortBy, sortDirection },
      loading,
      error,
      page,
      totalPages,
      geneName,
    } = this.props;

    const { showInfoText, popup } = this.state;

    const tableSettings = {
      height: 500,
      headerHeight: 50,
      rowHeight: 50,
      rowClassName: 'table-wrap__row',
      sortBy,
      sortDirection,
    };

    return (
      <div id="protein-location" className="protein-location">
        <div className="gene-details-section-title title2">
          <span>Localization</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText('showInfoText', showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            The protein localization data from Human Protein Atlas is derived from antibody-based profiling by immunofluorescence confocal microscopy, and classified into 32 different organelles and fine subcellular structures. In addition, literature annotations based on Gene Ontology and Uniprot are included in the results.
            <br />
            Image created by Human Protein Atlas: <a href="https://www.proteinatlas.org/humanproteome/cell" target="_blank" rel="noopener noreferrer">https://www.proteinatlas.org/humanproteome/cell</a>.
          </div>
        }
        <InteractiveCellPic locations={imageTags} geneName={geneName} />
        {
          !loading && !error &&
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <div className="table-wrap" style={{ width }}>
                  {
                    <div className="controls-block-2items" style={{ width }}>
                      {
                        totalPages > 1 &&
                        <div className="paginationContainer">
                          <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel={<a>...</a>}
                            breakClassName="break-me"
                            pageCount={totalPages}
                            forcePage={page}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                          />
                        </div>
                      }
                      {
                        proteinLocations.length > 0 &&
                        <ExportTable
                          content={allProteinLocations}
                          columns={this.columns ? this.columns : this.getColumns(width)}
                          fileName={`${geneName}-protein-location`}
                        />
                      }
                    </div>
                  }
                  {
                    proteinLocations.length > 0 &&
                    <SimpleTable
                      width={width}
                      data={proteinLocations}
                      autoSize={true}
                      columns={this.columns ? this.columns : this.getColumns(width)}
                      settings={tableSettings}
                      dynamicHeight={true}
                      sortAction={sortProteinLocationDataAction}
                    />
                  }
                  <NoData
                    show={proteinLocations.length === 0}
                    customClassName="therapeutic-candidates__no-data"
                  />
                </div> : null
            )}
          </AutoSizer>
        }
        <Loader isLoading={loading && !error} />
        <Error show={error && !loading} error={error} />
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

ProteinLocationChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    proteinLocations: SELECTORS.getProteinLocationDataSelector(state),
    allProteinLocations: SELECTORS.getAllProteinLocationDataSelector(state),
    imageTags: SELECTORS.getProteinLocationImageTagsSelector(state),
    loading: SELECTORS.getProteinLocationLoadingSelector(state),
    error: SELECTORS.getProteinLocationErrorSelector(state),
    sorting: SELECTORS.getProteinLocationSortingSelector(state),
    page: SELECTORS.getProteinLocationPageSelector(state),
    totalPages: SELECTORS.getProteinLocationTotalPagesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProteinLocationData(data) {
      dispatch(getProteinLocationDataAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
    changePage(data) {
      dispatch(changeProteinLocationPageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProteinLocationChapter);

