import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

// Components
import Login from '../components/auth/Login/Login';
import UserActivation from '../components/auth/UserActivation/UserActivation';
import CheckPasswordPage from '../components/auth/CheckPasswordPage/CheckPasswordPage';
// Constants
import { navigation } from './constants';
import { outerUrls } from '../constantsCommon';

const Public = () => {
  const { pathname, search } = useLocation();
  const [prevPath, setPrevPath] = useLocalStorage('locationHistory');

  useEffect(() => {
    if (
      pathname &&
      !pathname.includes('authenticate/genmab') &&
      !pathname.includes(`/${navigation.login}`)
    ) {
      setPrevPath(`${pathname}${search || ''}`);
    }
  }, []);

  useEffect(() => {
    if (prevPath && prevPath.includes('loginPage=genmab')) {
      setPrevPath(prevPath.replace('&loginPage=genmab', ''));
      window.open(outerUrls.genmab, '_self');
    }
  }, [prevPath, setPrevPath]);

  return (
    <Routes>
      <Route
        path={navigation.login}
        element={<Login />}
      />
      <Route
        path={navigation.cerebrumLogin}
        element={<Login />}
      />
      <Route
        path={navigation.activate}
        element={<UserActivation />}
      />
      <Route
        path={navigation.forgotPassword}
        element={<CheckPasswordPage />}
      />
      <Route
        path={navigation.activateAccount}
        element={<CheckPasswordPage />}
      />
      <Route
        path="*"
        element={<Navigate to={navigation.login} replace={true} />}
      />
    </Routes>
  );
};

export default Public;
