import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import GeneDiseaseSection from '../GeneDiseaseSection/GeneDiseaseSection';
import DidYouMeanSection from '../DidYouMeanSection/DidYouMeanSection';
import GeneClassesSection from '../GeneClassesSection/GeneClassesSection';
import SearchWithSynonymsSection from '../SearchWithSynonymsSection/SearchWithSynonymsSection';
import ShowingResultsForSection from '../ShowingResultsForSection/ShowingResultsForSection';
import SearchForSentenceCooccurrenceSection from '../SearchForSentenceCooccurrenceSection/SearchForSentenceCooccurrenceSection';
import LookingForSomethingElseSection from '../LookingForSomethingElseSection/LookingForSomethingElseSection';
import TermNotFoundSection from '../TermNotFoundSection/TermNotFoundSection';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Styles
import './index.scss';

const propTypes = {
  recommendations: PropTypes.instanceOf(Object),
  startNewSearch: PropTypes.func,
  openCreateSetPopup: PropTypes.func,
};

const SearchRecomendations = (props) => {
  const { recommendations, startNewSearch, openCreateSetPopup } = props;

  function checkIfRecommendationsContainsKeys() {
    const recommendationsKeys = Object.keys(recommendations);
    const newArr = recommendationsKeys.filter(elem => (
      elem === 'DIFFERENTIAL_EXPRESSION_EXPERIMENT' || elem === 'FIND_RESULTS_FOR'
    ));
    return newArr.length === recommendationsKeys.length;
  }

  if (checkIfRecommendationsContainsKeys()) {
    return null;
  }

  return (
    <div className="search-page-recommendations">
      <span className="search-page__subtitle">Search recommendations:</span>
      {
        !!recommendations.LOOKING_FOR_SOMETHING_ELSE &&
        <LookingForSomethingElseSection
          recommendations={recommendations.LOOKING_FOR_SOMETHING_ELSE[0]}
        />
      }
      {
        !!recommendations.SEARCH_WITH_SYNONYMS &&
        <SearchWithSynonymsSection
          recommendations={recommendations.SEARCH_WITH_SYNONYMS[0]}
          startNewSearch={startNewSearch}
        />
      }
      {
        !!recommendations.SHOWING_RESULTS_FOR &&
        <ShowingResultsForSection
          recommendations={recommendations.SHOWING_RESULTS_FOR[0]}
          startNewSearch={startNewSearch}
        />
      }
      {
        !!recommendations.DID_YOU_MEAN &&
        <DidYouMeanSection
          recommendations={recommendations.DID_YOU_MEAN}
          startNewSearch={startNewSearch}
        />
      }
      {
        !!recommendations.GENE_DISEASE &&
        <GeneDiseaseSection recommendations={recommendations.GENE_DISEASE} />
      }
      {
        !!recommendations.GENE_CLASS &&
        <GeneClassesSection
          recommendations={recommendations.GENE_CLASS}
          openCreateSetPopup={openCreateSetPopup}
        />
      }
      {
        !!recommendations.TERM_NOT_FOUND_INFO &&
        <TermNotFoundSection recommendations={recommendations.TERM_NOT_FOUND_INFO} />
      }
      {
        !!recommendations.GENE_FUNCTIONAL_ROLE &&
        <div className="search-page__link-block">
          <Link to={`${RELATIVE_PATH}/gene-details/${recommendations.GENE_FUNCTIONAL_ROLE[0].concept.id}`}>
            {recommendations.GENE_FUNCTIONAL_ROLE[0].recommendationPhrase}
          </Link>
        </div>
      }
      {
        !!recommendations.SEARCH_FOR_SENTENCE_COOCCURRENCE &&
        <SearchForSentenceCooccurrenceSection
          recommendations={recommendations.SEARCH_FOR_SENTENCE_COOCCURRENCE[0]}
          startNewSearch={startNewSearch}
        />
      }
    </div>
  );
};

SearchRecomendations.propTypes = propTypes;

export default React.memo(SearchRecomendations);
