import { takeLatest, call, put, select } from 'redux-saga/effects';
// Api
import Api from '../../../../../Api/Api';
// Store
import * as a from './actions';
import * as s from './selectors';
// Utils
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
import {
  setCellLinesHeatmapDataAction,
  setHeatmapInitialCellLinesDataAction
} from '../../../../graphics/Heatmap/store/reducer';
import {HeatmapTypeEnum} from '../../../../graphics/Heatmap/constants';
import {HeatmapMeasureEnum} from '../constants';

function* searchGenesForCellLineSelectionWorker({ payload }) {
  try {
    const { value, geneNumber } = payload;
    const requestData = { query: value };

    const { data } = yield call(Api.searchGenes, requestData);

    const options = data.results.map(item => ({
      ...item,
      id: item.data,
      name: item.value || item.name,
      value: item.value || item.name,
      label: item.value || item.name,
    }));

    yield put(a.setGeneOptionsAction( { geneNumber, options }));
  } catch (error) {
    console.log(error);
  }
}

function* getCellLinePanelDataWorker({ payload }) {
  try {
    const { data } = yield call(Api.getCellLinesSelectionData, { gene1: payload.firstGene?.id, gene2: payload.secondGene?.id });

    const keys = Object.keys(data);
    const dataWithAddidtionalInfo = keys.reduce((acc, item) => {
      acc[item] = data[item].map(el => ({ ...el, selected: true }));
      return acc;
    }, {});

    yield put(a.setCellLinePanelDataAction(dataWithAddidtionalInfo));
  } catch (e) {
    console.log(e);
  }
}

function* getCellLinePanelHeatmapDataWorker({ payload }) {
  try {
    const { data } = yield call(Api.getCellLinesSelectionData, { projectId: payload.projectId, setId: payload.id });

    const keys = Object.keys(data);
    const dataWithAddidtionalInfo = keys.reduce((acc, item) => {
      acc[item] = data[item].map(el => ({ ...el, selected: true }));
      return acc;
    }, {});

    yield put(a.setCellLinePanelDataAction(dataWithAddidtionalInfo));
  } catch (e) {
    console.log(e);
  }
}

function* getCellLinesConfigurationsWorker() {
  try {
    const projectId = yield select(s.getActiveProjectIdSelector);
    const isProjectTab = checkProjectTab(projectId);
    const { data } = yield call(Api.getCellLinesConfigurationsData, { projectId: isProjectTab ?  projectId : null });
    yield put(a.setCellLinesConfigurationsAction(data));
  } catch (e) {
    console.log(e);
  }
}

function* deleteCellLinesConfigurationWorker({ payload }) {
  try {
    const { id } = payload;
    yield call(Api.deleteCellLinesConfiguration, id);
    yield put(a.toggleDeleteCellLinesConfigurationModalIsOpenAction(false));
    yield put(a.getCellLinesConfigurationsAction());
  } catch (e) {
    console.log(e);
  }
}

function* getAdditionalDataForSelectedConfigurationWorker({ payload }) {
  try {
    const { data } = yield call(Api.getAdditionalDataForConfiguration, payload);
    yield put(a.setSelectedCellLinesDataAction(data.cellLineIds));
  } catch (e) {
    console.log(e);
  }
}

function* getLatestConfigurationDataWorker() {
  try {
    const { data } = yield call(Api.getLatestConfigurationData);
    yield put(a.setSelectedCellLinesDataAction(data.cellLineIds));
  } catch (e) {
    console.log(e);
  }
}
function* saveAsCellLinesConfigurationWorker() {
  try {
    const cellLinesData = yield select(s.getCellLinesDataSelector);
    const cellLineIds = Object.values(cellLinesData).flat().filter(item => item.selected).map(item => item.id);
    const { name, project = {} } = yield select(s.getFormValuesSelector);
    const isProjectTab = checkProjectTab(project?.id);

    const projectId = isProjectTab ?  project.id : null;
    const projectType = isProjectTab ? 'PROJECT' : 'USER';

    yield call(Api.saveCellLinesConfiguration, { cellLineIds, name, typeId: { id: projectId, type: projectType } });
  } catch (e) {
    console.log(e);
  }
}

function* getResultsDataWorker() {
  try {
    const { firstGene, secondGene } = yield select(s.getSelectedGenesSelector);
    const selectedCellLines = yield select(s.getSelectedCellLinesSelector);
    const dataForRequest = {
      gene1: firstGene.data,
      gene2: secondGene?.data,
      selectedCellLines: selectedCellLines.map(c => c.name),
    };

    const { data } = yield call(Api.getExpressionDataForCellLinesResults, dataForRequest);

    yield put(a.setResultsDataAction(data));
  } catch (e) {
    console.log(e);
  }
}

function* getResultsHeatmapDataWorker() {
  try {
    const { projectId, id } = yield select(s.getSelectedSetSelector);
    const selectedCellLines = yield select(s.getSelectedCellLinesSelector);
    const dataForRequest = {
      set: id,
      project: projectId,
      selectedCellLines: selectedCellLines.map(c => c.name),
    };

    const { data } = yield call(Api.getExpressionHeatmapDataForCellLinesResults, dataForRequest);

    yield put(a.setResultsHeatmapDataAction(data));
    yield put(setCellLinesHeatmapDataAction({
      data,
      dataForSave: data,
      type: HeatmapTypeEnum.SCORE,
      scoreType: HeatmapMeasureEnum.RNA,
    }));
    yield put(setHeatmapInitialCellLinesDataAction(dataForRequest));
  } catch (e) {
    console.log(e);
  }
}

// function* saveCellLinesConfigurationWorker() {
//   try {
//     const cellLinesData = yield select(s.getCellLinesDataSelector);
//     const cellLineIds = Object.values(cellLinesData).flat().filter(item => item.selected).map(item => item.id);
//     yield call(Api.saveCellLinesConfiguration, { cellLineIds, name: null, typeId: { id: null, type: 'USER' } });
//   } catch (e) {
//     console.log(e);
//   }
// }

function* cellLineSelectionSaga() {
  yield takeLatest(a.searchGenesForCellLineSelectionAction, searchGenesForCellLineSelectionWorker);
  yield takeLatest(a.getCellLinePanelDataAction, getCellLinePanelDataWorker);
  yield takeLatest(a.getCellLinePanelHeatmapDataAction, getCellLinePanelHeatmapDataWorker);
  yield takeLatest(a.getCellLinesConfigurationsAction, getCellLinesConfigurationsWorker);
  yield takeLatest(a.deleteCellLinesConfigurationAction, deleteCellLinesConfigurationWorker);
  yield takeLatest(a.getAdditionalDataForSelectedConfigurationAction, getAdditionalDataForSelectedConfigurationWorker);
  yield takeLatest(a.getLatestConfigurationDataAction, getLatestConfigurationDataWorker);
  // yield takeLatest(a.saveCellLinesConfigurationAction, saveCellLinesConfigurationWorker);
  yield takeLatest(a.saveAsCellLinesConfigurationAction, saveAsCellLinesConfigurationWorker);
  yield takeLatest(a.getResultsDataAction, getResultsDataWorker);
  yield takeLatest(a.getResultsHeatmapDataAction, getResultsHeatmapDataWorker);
}

export default cellLineSelectionSaga;
