import { fromJS } from 'immutable';

import { types } from './constants';

const initialState = fromJS({
  loading: false,
  error: null,
  protein: {},
  PDBEntries: null,
  PDBId: null,
  PDBError: false,
});

const proteinStructureReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROTEIN_STRUCTURE_DATA: {
      const { data } = action.data;

      return state.merge(fromJS(
        {
          protein: {
            structure: data.structure,
            uniprot: data.up,
          },
          loading: false,
          error: null,
        }
      ));
    }

    case types.TOGGLE_PROTEIN_STRUCTURE_LOADER: {
      const { data } = action;

      return state.merge(fromJS(
        {
          loading: data,
          error: null,
        }
      ));
    }

    case types.PROTEIN_STRUCTURE_IS_FAILED: {
      const { message } = action;
      return state
        .update('loading', () => false)
        .update('error', () => message)
        .update('PDBError', () => true);
    }

    case types.SET_PDB_ENTRIES: {
      const { data } = action;
      return state
        .update('PDBEntries', () => fromJS(data))
        .update('PDBId', () => fromJS(data[0]))
        .update('PDBError', () => false);
    }

    case types.SET_PDB_ENTRIES_ERROR: {
      return state.update('PDBError', () => true);
    }

    case types.UPDATE_PDB_ID: {
      return state.update('PDBId', () => fromJS(action.data));
    }

    default:
      return state;
  }
};

export default proteinStructureReducer;
