import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const searchRelatedConceptsLoadingAction = createAction('SEARCH_RELATED_CONCEPTS_LOADING');
export const searchRelatedConceptsErrorAction = createAction('SEARCH_RELATED_CONCEPTS_ERROR');

const initialState = fromJS({
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [searchRelatedConceptsLoadingAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [searchRelatedConceptsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
  },
  initialState
);

export default reducer;
