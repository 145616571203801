import React from 'react';
import PropTypes from 'prop-types';

import SingleTargetForCancerGroup from './SingleTargetForCancerGroup';

const propTypes = {
  tissues: PropTypes.instanceOf(Object),
  checkTissue: PropTypes.func,
  checkAllGroupTissues: PropTypes.func,
  changeSortDirection: PropTypes.func,
  columnId: PropTypes.string,
};

class SingleTargetForCancerGroupList extends React.Component {
  render() {
    const {
      tissues,
      checkTissue,
      checkAllGroupTissues,
      changeSortDirection,
      columnId,
    } = this.props;
    return (
      <div className="tissues-dnd-column__list">
        {
          tissues.map(
            (item, i) => (
              <SingleTargetForCancerGroup
                key={item.id}
                tissue={item}
                index={i}
                type={item.id}
                checkTissue={checkTissue}
                checkAllGroupTissues={checkAllGroupTissues}
                changeSortDirection={changeSortDirection}
                columnId={columnId}
              />
            )
          )
        }
      </div>
    );
  }
}

SingleTargetForCancerGroupList.propTypes = propTypes;

export default SingleTargetForCancerGroupList;
