import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { drawGroupedViolinChart } from './drawGroupedViolinChart';
import { convertTableToCSVText, exportToFile, exportToPNG, exportToSVG } from '../../Utils/Utils';
import { transformLogChartData } from './utils';
// Components
import LegendComponent from '../LegendComponent/LegendComponent';
import ChartController from '../../GeneDetails/GeneExpressionChapter/Charts/ChartController/ChartController';
import Tooltip from '../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
// Styles
import './styles.scss';

const propTypes = {
  chartTitle: PropTypes.string,
  chartWrapId: PropTypes.string,
  chartData: PropTypes.instanceOf(Array),
  firstName: PropTypes.string,
  secondName: PropTypes.string,
  type: PropTypes.string,
  chartName: PropTypes.string,
  isNeededTransform: PropTypes.bool,
  isHiddenExport: PropTypes.bool,
  customVerticalLineX: PropTypes.number,
  violinPlotTransform: PropTypes.string,
  setViolinPlotTransform: PropTypes.func,
  yAxisText: PropTypes.string,
  isCsvDownloadingAllowed: PropTypes.bool,
  selectedCells: PropTypes.instanceOf(Array),
  setSelectedCells: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  csvName: PropTypes.string,
  tissuesSelectionMode: PropTypes.bool,
  setTissuesSelectionMode: PropTypes.func,
  showExpressionSortSelector: PropTypes.bool,
  setSelectedSortingOption: PropTypes.func,
  selectedSortingOption: PropTypes.instanceOf(Object),
  sortingOptions: PropTypes.instanceOf(Array),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  switchOpenModal: PropTypes.func,
};

const GroupedViolinChart = (props) => {
  const {
    chartTitle,
    chartWrapId,
    chartData,
    firstName,
    secondName,
    chartName,
    type,
    isNeededTransform,
    isHiddenExport,
    customVerticalLineX,
    violinPlotTransform,
    setViolinPlotTransform,
    yAxisText,
    isCsvDownloadingAllowed,
    csvName,
    selectedCells,
    setSelectedCells,
    unsetTissuesSelectionMode,
    tissuesSelectionMode,
    setTissuesSelectionMode,
    showExpressionSortSelector,
    setSelectedSortingOption,
    selectedSortingOption,
    sortingOptions,
    showTooltip,
    hideTooltip,
    setShortConceptCardId,
    switchOpenModal,
  } = props;

  const downloadLinkRef = useRef(null);
  const [drawing, setDrawing] = useState(true);

  const showTooltipHandler = useCallback((d, x, y) => {
    if (d) {
      const config = {
        uniqueKey: `${type}__grouped_violin-tooltip`,
        clientX: x,
        clientY: y,
      };
      setShortConceptCardId(d.values[0].tumorId || d.values[1].tumorId);
      showTooltip(config);
    }
  }, [setShortConceptCardId, showTooltip]);

  const transformedData = useMemo(() => (
    transformLogChartData(violinPlotTransform, chartData)
  ), [chartData, violinPlotTransform]);

  const handleCellClick = useCallback((d) => {
    const concept = chartData.find(el => el.concept === d);
    if (setSelectedCells && tissuesSelectionMode) {
      setSelectedCells(concept);
    } else if (!tissuesSelectionMode) {
      switchOpenModal(d, concept, type);
    }
  }, [chartData, setSelectedCells, tissuesSelectionMode, type]);

  const drawChart = useCallback((showTitle = false) => {
    drawGroupedViolinChart({
      htmlRootId: chartWrapId,
      data: transformedData,
      tooltipMode: type,
      type,
      showTitle,
      chartTitle,
      firstName,
      secondName,
      violinScale: violinPlotTransform,
      customVerticalLineX,
      yAxisText,
      handleCellClick,
      selectedCells,
      hideTooltip: hideTooltip && hideTooltip,
      ...(showTooltip && { showTooltipHandler }),
    });
    setTimeout(() => { setDrawing(false); }, 2000);
  }, [chartWrapId, transformedData, type, chartTitle, firstName, secondName, violinPlotTransform, selectedCells, tissuesSelectionMode]);

  const exportToPNGCb = useCallback(() => {
    drawChart(true);
    const diagram = document.querySelector(`#${chartWrapId} svg`);
    const name = `${chartName}_${firstName}_${secondName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, `${firstName}_${secondName}`);
    }
    drawChart();
  }, [chartWrapId, chartName, firstName, secondName, drawChart]);

  const exportToSVGCb = useCallback(() => {
    drawChart(true);
    const container = document.querySelector(`#${chartWrapId}`);
    const name = `${chartName}${firstName}_${secondName}.svg`;
    if (container) {
      exportToSVG(container, name, false, false, `${firstName}_${secondName}`);
    }
    drawChart();
  }, [chartWrapId, chartName, firstName, secondName, drawChart]);

  const exportToCSV = (data, scalingValue) => {
    const transformedChartData = transformLogChartData(scalingValue, data);
    const dataForDownload = transformedChartData.reduce((acc, item) => {
      item.values.forEach(el => {
        acc = acc.concat(el.points.map(p => ({...el, value: p.toFixed(2)})));
      });
      return acc;
    }, []);

    const columns = [
      {
        label: 'Tissue',
        dataKey: 'tissueType',
      },
      {
        label: 'Tumor name',
        dataKey: 'tumorName',
      },
      {
        label: 'Samples count',
        dataKey: 'samplesCount',
      },
      {
        label: 'Protein abundance',
        dataKey: 'value',
      },
    ];

    const csvData = convertTableToCSVText(dataForDownload.flat(), columns);
    exportToFile(downloadLinkRef.current, csvData, csvName);
  };

  const handleExportAllToCSV = useCallback(() => {
    exportToCSV(chartData, violinPlotTransform);
  }, [chartData, violinPlotTransform]);

  const handleExportToCSV = useCallback(() => {
    if (selectedCells.length) {
      exportToCSV(selectedCells, violinPlotTransform);
    }
  }, [selectedCells, violinPlotTransform]);

  const changeViolinPlotTransform = useCallback(e => (
    setViolinPlotTransform(e)
  ), []);

  useEffect(() => {
    if (transformedData && transformedData.length) {
      const tooltip = document.querySelector(`.chart-tooltip_${type}`);
      if (tooltip) {
        tooltip.remove();
      }

      drawChart();
    }
  }, [drawChart, transformedData, violinPlotTransform, tissuesSelectionMode]);

  return (
    <div className="target-candidates-chart violin-chart">
      {
        chartData.length &&
        <>
          <ChartController
            type={type}
            drawing={drawing}
            isNeededTransform={isNeededTransform}
            chartTransform={violinPlotTransform}
            changeChartTransform={changeViolinPlotTransform}
            downloadLinkRef={downloadLinkRef}
            exportToPNGCb={exportToPNGCb}
            exportToSVGCb={exportToSVGCb}
            isHiddenExport={isHiddenExport}
            handleExportAllToCSV={handleExportAllToCSV}
            handleExportToCSV={handleExportToCSV}
            isCsvDownloadingAllowed={isCsvDownloadingAllowed}
            csvName={csvName}
            selectedCells={selectedCells}
            setSelectedCells={setSelectedCells}
            unsetTissuesSelectionMode={unsetTissuesSelectionMode}
            tissuesSelectionMode={tissuesSelectionMode}
            setTissuesSelectionMode={setTissuesSelectionMode}
            showExpressionSortSelector={showExpressionSortSelector}
            setSelectedSortingOption={setSelectedSortingOption}
            selectedSortingOption={selectedSortingOption}
            sortingOptions={sortingOptions}
          />
          <div
            id={chartWrapId}
            className="target-candidates-chart__wrap grouped-violin-chart__wrap"
          />
          {
            !drawing &&
            <LegendComponent
              legendsParams={[{ name: firstName }, { name: secondName }]}
              legendsColors={['rgb(126, 70, 174)', 'rgb(174, 150, 70)']}
              keyToName="name"
            />
          }
        </>
      }
      <Tooltip uniqueKeyProp={`${type}__grouped_violin-tooltip`}>
        <ShortConceptCard />
      </Tooltip>
    </div>
  );
};

GroupedViolinChart.propTypes = propTypes;

export default React.memo(GroupedViolinChart);
