import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../../../Modals/ConfirmationDialog/ConfirmationDialog';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onConfirm: PropTypes.func,
  cellLinesConfigurationToDelete: PropTypes.instanceOf(Object),
};

const DeleteCellLineConfigurationModal = (props) => {
  const {
    isOpen,
    closeCb,
    onConfirm,
    cellLinesConfigurationToDelete,
  } = props;

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      onCancel={closeCb}
      isOpen={isOpen}
      closeCb={closeCb}
      text={ `Are you sure you want to remove cell lines configuration ${cellLinesConfigurationToDelete.name}?` }
    />
  );
};

DeleteCellLineConfigurationModal.propTypes = propTypes;

export default React.memo(DeleteCellLineConfigurationModal);
