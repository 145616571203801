import React, { useEffect } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'react-dd-menu/dist/react-dd-menu.min.css';

// Components
import UserDropdown from '../auth/UserDropdown/UserDropdown';
import ChangePassword from '../auth/ChangePassword/ChangePassword';
import ModalComponent from '../ModalComponent/ModalComponent';
import HelpDropdown from './components/HelpDropdown/HelpDropdown';
// Imgs
import LogoImg from '../../theme/assets/img/euretos-logo-white.svg';
// Store
import { disableReloadingAction, updateSearchSavedLinkAction } from '../Search/store/actions';
import { getSearchConceptsIsPresentSelector } from '../Search/store/selectors';
import { getOpenedChatBot } from '../../location/locationSelector';
import { chatBotAction } from '../../location/locationReducer';
import { changePasswordModalClose } from '../auth/ChangePassword/actions';
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
// Styles
import './Header.scss';
import '../../theme/styles/variables.scss';

const propTypes = {
  children: PropTypes.instanceOf(Object),
  showModal: PropTypes.bool,
  showWorkflows: PropTypes.bool,
  initCurrentUser: PropTypes.func,
  initCerebrumSC: PropTypes.func,
  initCerebrumAccessMappings: PropTypes.func,
  closeModal: PropTypes.func,
  showLogo: PropTypes.bool,
  history: PropTypes.instanceOf(Object),
  setHelpIsOpen: PropTypes.func,
  helpIsOpen: PropTypes.string,
  hideProjects: PropTypes.bool,
  currentUser: PropTypes.instanceOf(Object),
  disableReloading: PropTypes.func,
};

const Header = (props) => {
  const {
    children,
    showModal,
    showWorkflows,
    hideProjects,
    initCurrentUser,
    initCerebrumSC,
    initCerebrumAccessMappings,
    closeModal,
    history,
    setHelpIsOpen,
    helpIsOpen,
    currentUser,
    disableReloading,
  } = props;
  const location = useLocation();

  useEffect(() => {
    initCurrentUser();
    initCerebrumSC();
    initCerebrumAccessMappings();
  }, []);

  const headerCls = classNames({
    'header flex-grid header--fixed': true,
  });

  const getActiveLinkClassName = ({ isActive }) => (
    classNames({
      'menu-navigation__item': true,
      'menu-navigation__item--active': isActive,
    })  
  );

  return (
    <div className="main-page">
      <div className={headerCls}>
        <div className="header__logo">
          <NavLink to="/portal">
            <img src={LogoImg} alt="euretos logo" />
          </NavLink>
        </div>
        <nav className="header__navigation menu-navigation">
          <NavLink
            to="/research"
            className={getActiveLinkClassName}
          >
            Search
          </NavLink>
          <NavLink
            to="/sets"
            className={getActiveLinkClassName}
          >
            Sets
          </NavLink>
          <NavLink
            to="/relation-map"
            className={getActiveLinkClassName}
          >
            Relation maps
          </NavLink>
          <NavLink
            to="/analytics"
            className={getActiveLinkClassName}
          >
            Analytics
          </NavLink>
          {
            showWorkflows &&
            <NavLink
              to="/workflows"
              className={getActiveLinkClassName}
            >
              Workflows
            </NavLink>
          }
          <NavLink
            to="/references"
            className={getActiveLinkClassName}
          >
            References
          </NavLink>
          {
            !hideProjects &&
            <NavLink
              to="/projects"
              className={getActiveLinkClassName}
            >
              Projects
            </NavLink>
          }
          <div className="menu-navigation__item menu-navigation__item--profile">
            <UserDropdown
              history={history}
              helpIsOpen={helpIsOpen}
              setHelpIsOpen={setHelpIsOpen}
            />
          </div>
          <div className="menu-navigation__item menu-navigation__item--info">
            <HelpDropdown
              location={location}
              history={history}
              helpIsOpen={helpIsOpen}
              currentUser={currentUser}
              setHelpIsOpen={setHelpIsOpen}
              disableReloading={disableReloading}
            />
          </div>
        </nav>
      </div>
      {children}
      {
        showModal &&
        <ModalComponent
          isOpen={showModal}
          closeCb={closeModal}
        >
          <ChangePassword />
        </ModalComponent>
      }
    </div>
  );
};

Header.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    showModal: SELECTORS.getChangePasswordModalKey(state),
    showLogo: getSearchConceptsIsPresentSelector(state),
    showWorkflows: SELECTORS.isWorkflowsAllowed(state),
    helpIsOpen: getOpenedChatBot(state),
    hideProjects: SELECTORS.isFreeUserSelector(state),
    currentUser: SELECTORS.getCurrentUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal() {
      dispatch(changePasswordModalClose());
    },
    initCurrentUser() {
      dispatch(ACTIONS.appInitCurrentUserAction());
    },
    initCerebrumSC() {
      dispatch(ACTIONS.appInitCerebrumSCAction());
    },
    setHelpIsOpen(data) {
      dispatch(chatBotAction(data));
    },
    initCerebrumAccessMappings() {
      dispatch(ACTIONS.appInitCerebrumAccessMappingsAction());
    },
    updateSearchSavedLink(data) {
      dispatch(updateSearchSavedLinkAction(data));
    },
    disableReloading(data) {
      dispatch(disableReloadingAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
