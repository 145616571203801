import { fromJS } from 'immutable';

import {
  SORT,
  CHECK_ITEM,
  CHECK_ALL,
  REMOVE_SELECTED,
  INVERT_SELECTION,
} from '../../common/SimpleTable/constants';

import {
  RESULT_SET_SUCCEEDED,
  RESULT_SET_FAILED,
  SET_RESULT_PAGE_TABLE,
  SAVE_PAIR_OF_CONCEPTS,
  SAVE_SET_FOR_SET_RESULT,
  SET_RESULT_RESET,
  SAVE_CONCEPT_AND_CATEGORY,
  LOADING,
  SET_A_OPEN_MENU,
  SET_A_CLOSE_MENU,
  SET_B_OPEN_MENU,
  SET_B_CLOSE_MENU,
  SET_C_OPEN_MENU,
  SET_C_CLOSE_MENU,
  RESET_SET_RESULT_PRIMARY_SETTINGS,
  SET_NEW_FULL_SET,
  SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY,
  SET_CURRENT_SET_ID,
  SET_PREVIOUS_SET_ID, EXPORT_ALL_TO_CSV_RESULT_SUCCESS,
} from './constants';

const initialValues = fromJS({
  set: {
    conceptIds: [],
    setName: null,
    id: null,
  },
  pairForNeighbors: {
    conceptA: null,
    conceptB: null,
  },
  categorySetResult: {
    conceptId: null,
    semanticTypeId: null,
  },
  result: {
    content: [],
    number: 0,
  },
  sort: null,
  fullSet: [],
  setADropDownKey: false,
  setBDropDownKey: false,
  setCDropDownKey: false,
  showConfirmAddConceptPopup: false,
  sorting: { sortBy: 'setANode.name', sortDirection: 'ASC' },
  loading: true,
  error: null,
  currentSetId: null,
  previousSetId: null,
  exportCSVResult: {
    result: [],
  },
});

const setResultPage = (state = initialValues, action) => {
  switch (action.type) {
    case EXPORT_ALL_TO_CSV_RESULT_SUCCESS: {
      let data = action.data.resultPage;
      let { content } = action.data.resultPage;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => (
          Object.assign(item, { id: index + 1 })
        ));
        data = Object.assign(data, { content });
      }
      return state.merge(fromJS({
        exportCSVResult: {
          result: data,
          fullSet: action.data.fullSet,
          sort: action.data.sort,
          error: null,
          loading: false,
        },
      }));
    }

    case SET_PREVIOUS_SET_ID:
      return state.set('previousSetId', action.data);

    case SET_CURRENT_SET_ID:
      return state.set('currentSetId', action.data);

    case SAVE_SET_FOR_SET_RESULT:
      return state.update('set', () => (
        fromJS(action.data)
      ));

    case SAVE_PAIR_OF_CONCEPTS:
      return state.update('pairForNeighbors', () => (
        fromJS(action.data)
      ));

    case SAVE_CONCEPT_AND_CATEGORY:
      return state.update('categorySetResult', () => (
        fromJS(action.data)
      ));

    case RESULT_SET_SUCCEEDED: {
      let data = action.data.resultPage;
      let { content } = action.data.resultPage;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => (
          Object.assign(item, { id: index + 1 })
        ));
        data = Object.assign(data, { content });
      }
      return state.merge(fromJS({
        result: data,
        fullSet: action.data.fullSet,
        sort: action.data.sort,
        error: null,
        loading: false,
      }));
    }

    case RESULT_SET_FAILED:
      return state.merge(fromJS({
        result: {
          content: [],
        },
        fullSet: [],
        sort: null,
        error: action.message,
        loading: false,
      }));

    case SET_RESULT_PAGE_TABLE + SORT:
      return state.update('sorting', () => (
        fromJS(action.data)
      ));

    case SET_RESULT_PAGE_TABLE + LOADING: {
      return state.update('loading', () => action.data);
    }

    case SET_RESULT_PAGE_TABLE + CHECK_ALL:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: action.data }))
          ))
        ))
      ));

    case SET_RESULT_PAGE_TABLE + CHECK_ITEM:
      return state.update('result', result => (
        result.update('content', content => (
          content.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    case SET_RESULT_PAGE_TABLE + REMOVE_SELECTED:
      return state.update('result', result => (
        result.update('content', content => (
          content.filterNot(item => item.get('selected'))
        ))
      ));

    case SET_RESULT_PAGE_TABLE + INVERT_SELECTION:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: !item.get('selected') }))
          ))
        ))
      ));

    case SET_A_OPEN_MENU:
      return state.update('setADropDownKey', () => true);

    case SET_A_CLOSE_MENU:
      return state.update('setADropDownKey', () => false);

    case SET_B_OPEN_MENU:
      return state.update('setBDropDownKey', () => true);

    case SET_B_CLOSE_MENU:
      return state.update('setBDropDownKey', () => false);

    case SET_C_OPEN_MENU:
      return state.update('setCDropDownKey', () => true);

    case SET_C_CLOSE_MENU:
      return state.update('setCDropDownKey', () => false);

    case RESET_SET_RESULT_PRIMARY_SETTINGS:
      return state.merge(fromJS({
        set: initialValues.get('set'),
        pairForNeighbors: initialValues.get('pairForNeighbors'),
        categorySetResult: initialValues.get('categorySetResult'),
        result: initialValues.get('result'),
      }));

    case SET_NEW_FULL_SET:
      return state.update('fullSet', () => (
        fromJS(action.data)
      ));

    case SET_RESULT_RESET:
      return initialValues;

    case SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY:
      return state.update('showConfirmAddConceptPopup', () => action.data);

    default:
      return state;
  }
};

export default setResultPage;
