import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
  startNewSearch: PropTypes.func,
};

class DidYouMeanSection extends React.Component {
  state = {
    showMoreRecommendations: false,
  };

  showMoreRecommendations = () => {
    const { showMoreRecommendations } = this.state;
    this.setState({
      showMoreRecommendations: !showMoreRecommendations,
    });
  };

  render() {
    const { recommendations, startNewSearch } = this.props;
    const { showMoreRecommendations } = this.state;
    let didYouMeanAmountToShow = 0;
    let isShowMoreRecommendationVisible = false;

    if (recommendations && recommendations.length) {
      const didYouMeanLength = recommendations.length;

      didYouMeanAmountToShow = didYouMeanLength > 3 && showMoreRecommendations ?
        didYouMeanLength : 3;
      isShowMoreRecommendationVisible = didYouMeanLength > 3;
    }

    return (
      <div>
        <div>Did you mean:</div>
        <div>
          {
            recommendations.slice(0, didYouMeanAmountToShow).map((item, i) => (
              <div
                className="search-page__link-block search-page__small-padding"
                key={i}
                onClick={() => { startNewSearch({ plainValue: item.recommendationPhrase, exactValue: item.exactRecommendationPhrase }); }}
              >
                <span>{ item.recommendationPhrase }</span>
              </div>
            ))
          }
        </div>
        {
          isShowMoreRecommendationVisible &&
          <div
            onClick={() => { this.showMoreRecommendations(); }}
          >
            { !this.state.showMoreRecommendations && 'show more recommendations'}
            { this.state.showMoreRecommendations && 'show less recommendations'}
          </div>
        }
      </div>
    );
  }
}

DidYouMeanSection.propTypes = propTypes;

export default DidYouMeanSection;
