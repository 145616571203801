import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { AutoSizer } from 'react-virtualized';
import classNames from 'classnames';
import { useMarker } from 'react-mark.js';

// Components
import NoData from '../../../common/NoData/NoData';
import Table from '../../../common/SimpleTable/Table';
import Pagination from '../../../common/Pagination/Pagination';
import SaveAsSetModal from '../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import RelationMapModal from '../../../RelationMap/RelationMapModal/RelationMapModal';
import SliderInput from '../../../common/Inputs/SliderInput/SliderInput';
import ButtonSelect from '../../../common/Buttons/ButtonSelect/ButtonSelect';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  conceptsData: PropTypes.instanceOf(Object),
  changePage: PropTypes.func,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  sort: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Array),
  sliderValue: PropTypes.number,
  filteredConcepts: PropTypes.instanceOf(Array),
  setSliderValue: PropTypes.func,
};

const PublicationDetailsConcepts = (props) => {
  const {
    conceptsData: {
      concepts,
      conceptsPerPage,
      page,
      totalPages,
    },
    sort,
    sorting,
    sliderValue,
    setSliderValue,
    filteredConcepts,
    semanticCategories,
    changePage,
  } = props;

  const { markerRef, marker } = useMarker();
  const [addSetPopup, setAddSetPopup] = useState(false);
  const [repationMapPopup, setRepationMapPopup] = useState(false);
  const [saveAsSetSemCategory, setSaveAsSetSemCategory] = useState(null);
  const [relationMapSemCategory, setRelationMapSemCategory] = useState(null);

  const maxScore = useMemo(() => concepts ? Math.max(...concepts.map(concept => concept.scoreCFIDF)) : 0, [concepts]);

  const filteredSemanticCategories = useMemo(() => filteredConcepts.reduce((arr, c) => {
    if (arr.indexOf(c.category) === -1) {
      arr.push(c.category);
    }
    return arr;
  }, []), [filteredConcepts]);

  const drawHighlights = useCallback(() => {
    const checkedCategories = semanticCategories.filter(category => category.checked);
    const checkedConcepts = [];

    concepts.forEach((concept) => {
      const { category, name } = concept;
      const sem = checkedCategories.find(item => item.category === category);
      if (sem) checkedConcepts.push({ category, name, class: sem.class });
    });

    const markHighlights = () => {
      marker.unmark();
      checkedConcepts.forEach((concept) => {
        marker.mark(concept.name, {
          className: concept.class,
          accuracy: 'exactly',
          separateWordSearch: false,
          exclude: ['.category-cell div'],
          filter(textNode) {
            if (textNode.length !== concept.name.length) return false;
            return true;
          },
        });
      });
    };

    if (marker) setTimeout(markHighlights, 100);
  }, [concepts, marker, semanticCategories]);

  const getConcepts = useCallback((category) => (
    filteredConcepts.reduce((arr, d) => {
      if (!category) {
        arr.push(d.id);
        return arr;
      }

      if (d.category === category) {
        arr.push(d.id);
      }

      return arr;
    }, [])
  ), [filteredConcepts]);

  const tooltipRenderer = useMemo(() => {
    if (!concepts || !concepts.length) return null;
    return concepts.map(item => (
      <ReactTooltip
        key={`tip-${item.id}`}
        id={`${item.id}`}
        type="light"
        border={true}
      />
    ));
  }, [concepts]);

  const renderTableHeader = useCallback((label, dataKey, sortBy, sortDirection, infoComponent = null) => {
    const ascClasses = classNames({
      'fa fa-sort-asc': true,
      active: sortBy === dataKey && sortDirection === 'ASC',
    });
    const descClasses = classNames({
      'fa fa-sort-desc': true,
      active: sortBy === dataKey && sortDirection === 'DESC',
    });

    return (
      <div className="header-section">
        <span
          className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
          title={label}
        >
          {label}
        </span>
        {infoComponent}
        <span className="sorting-section">
          <i
            className={ascClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'ASC' })}
          />
          <i
            className={descClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'DESC' })}
          />
        </span>
      </div>
    );
  }, [sort]);

  const infoComponent = useMemo(() => (
    <>
      <i
        className="fa fa-info-circle icon first-info-icon"
        data-tip={true}
        data-for="relevancy-info"
      />
      <ReactTooltip
        id="relevancy-info"
        place="top"
        type="light"
        border={true}
        className="relevancy__tooltip"
      >
        <div className="helptext__text">
          Relevancy is the concept frequency–inverse document frequency (cf-idf), a numerical statistic that is
          intended to reflect how important a word is to a document in a collection of documents.The cf–idf value
          increases proportionally to the number of times a word appears in the document and is offset by the number
          of documents in the collection that contain the word. <br />
          <br />
          Goossen et al. (2011). News personalization using the CF-IDF semantic recommender.
          Proceedings of the International Conference on Web Intelligence, Mining and Semantics; New York: ACM; 2011.
        </div>
      </ReactTooltip>
    </>
  ), []);

  const showTooltipCell = useCallback((rowData, rowIndex, cellIndex) => {
    const { id, name, category } = rowData;
    const link = category === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={`tooltip-${id}-${rowIndex}-${cellIndex}`}
        name={name}
        link={link}
      />
    );
  }, []);

  const columns = [
    {
      dataKey: 'name',
      label: 'Name',
      width: 40,
      headerRenderer: d =>
        renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      cellRenderer: ({ rowData, rowIndex }) => (
        rowData.name ? showTooltipCell(rowData, rowIndex) : null
      ),
    },
    {
      label: 'Category',
      dataKey: 'category',
      width: 40,
      disableSort: true,
      headerRenderer: d =>
        renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      cellRenderer: ({ rowData }) => rowData.category ? rowData.category : null,
    },
    {
      label: 'Relevancy',
      dataKey: 'scoreCFIDF',
      width: 20,
      disableSort: true,
      headerRenderer: d =>
        renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection, infoComponent),
      cellRenderer: ({ rowData }) => rowData.scoreCFIDF ? rowData.scoreCFIDF : null,
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.hide();
  });

  useEffect(() => {
    if (concepts) {
      drawHighlights();
    }
  }, [marker, semanticCategories, page, sorting, concepts, drawHighlights]);

  return (
    <div className="pub-details-concepts">
      {
        conceptsPerPage && conceptsPerPage.length > 0 &&
        <div
          ref={markerRef}
          className="pub-details-concepts__content-wrap"
        >
          <Pagination
            pageNumber={page}
            totalPages={totalPages}
            onPageChange={({ selected }) => changePage(selected)}
          />
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Table
                data={conceptsPerPage}
                columnsData={columns}
                width={width}
                sorting={sorting}
                sortCallback={sort}
              />
            )}
          </AutoSizer>
          <div className="pub-details-concepts__management-wrap">
            <ButtonSelect
              text="Save as set"
              options={filteredSemanticCategories}
              onClick={() => {
                setSaveAsSetSemCategory(null);
                setAddSetPopup(true);
              }}
              onSelect={(d) => {
                setSaveAsSetSemCategory(d);
                setAddSetPopup(true);
              }}
            />
            <div
              className="pub-details-concepts__slider"
              title="Select value to filter out the concepts with relevance score less than selected value"
            >
              <span className="pub-details-concepts__label">
                Filter concepts for set/map based on Relevancy
              </span>
              {
                concepts &&
                <SliderInput
                  defaultValue={sliderValue}
                  startPoint={maxScore}
                  min={0}
                  max={maxScore}
                  step={0.01}
                  trackStyle={[{
                    boxSizing: 'content-box',
                    borderTop: '1px solid #82acbd',
                    borderBottom: '1px solid #82acbd',
                    background: '#82acbd',
                  }]}
                  railStyle={{
                    boxSizing: 'content-box',
                    border: '1px solid transparent',
                  }}
                  handleStyle={[{
                    borderColor: '#82acbd',
                    marginTop: '-4px',
                  }]}
                  marks={{
                    0: { label: 0 },
                    [maxScore]: { label: maxScore.toFixed(2) },
                  }}
                  onAfterChange={(data) => {
                    setSliderValue(data);
                  }}
                />
              }
            </div>
            <ButtonSelect
              text="Open as relation map"
              options={filteredSemanticCategories}
              onClick={() => {
                setRelationMapSemCategory(null);
                setRepationMapPopup(true);
              }}
              onSelect={(d) => {
                setRelationMapSemCategory(d);
                setRepationMapPopup(true);
              }}
            />
          </div>
          <div>
            {tooltipRenderer}
          </div>
        </div>
      }
      <NoData
        show={concepts && concepts.length === 0}
        customClassName="cites-charts__no-data"
      />
      {
        repationMapPopup &&
        <RelationMapModal
          isOpen={repationMapPopup}
          closeCb={() => { setRepationMapPopup(false); }}
          concepts={getConcepts(relationMapSemCategory)}
        />
      }
      <SaveAsSetModal
        isOpen={addSetPopup}
        closeCb={() => { setAddSetPopup(false); }}
        idsForShortConceptDetails={getConcepts(saveAsSetSemCategory)}
      />
    </div>
  );
};

PublicationDetailsConcepts.propTypes = propTypes;

export default React.memo(PublicationDetailsConcepts);
