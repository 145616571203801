import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Concept from '../../../common/ConceptItem/Concept';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
import ConceptSearchModal from '../../../Concept/ConceptSearchModal/ConceptSearchModal';
// Store
import {
  addLiteratureSearchTermsAction,
  removeLiteratureSearchTermsAction,
  saveLiteratureSearchTermsAction,
} from './actions';
import { getLiteratureSearchTerms, getLiteratureSearchSynonymsKey } from './selectors';
import {
  closeRankingModal,
  setRankingAction,
} from '../../common/SetAnalysisMethodSelection/actions';
// Styles
import './LiteratureSearchTerms.css';

const propTypes = {
  addLiteratureSearchTerms: PropTypes.func,
  saveSelected: PropTypes.func,
  setRanking: PropTypes.func,
  closeModal: PropTypes.func,
  terms: PropTypes.instanceOf(Array),
  removeLiteratureSearchTerms: PropTypes.func,
  updateTerm: PropTypes.func,
  closeModalCallback: PropTypes.func,
  setRankingCallback: PropTypes.func,
};

const LiteratureSearchTerms = (props) => {
  const {
    terms,
    saveSelected,
    setRanking,
    closeModal,
    addLiteratureSearchTerms,
    removeLiteratureSearchTerms,
    updateTerm,
    closeModalCallback,
    setRankingCallback,
  } = props;

  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);

  const handleSaveSelected = () => {
    if (updateTerm) {
      updateTerm(terms);
      setRankingCallback('literatureSearch');
      closeModalCallback();
    } else {
      saveSelected();
      setRanking({ literatureSearch: true });
      closeModal();
    }
  };

  const items = terms.map((concept, index) => (
    <Concept
      noInfoButton={true}
      deleteCb={removeLiteratureSearchTerms}
      key={index}
      concept={concept}
    />
  ));

  return (
    <div className="literature-search-modal">
      <div className="flex-grid">
        <div className="col-1 text-center">
          <h3 className="title3">
            Literature Search Terms
          </h3>
        </div>
      </div>
      <div className="flex-grid" style={{ justifyContent: 'center' }}>
        <ConceptSearchForm
          onSubmitCallback={() => setShowConceptSearchModal(true)}
        />
      </div>
      <div className="flex-grid">
        <div className="col-1 text-center row">
          {items}
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-center row">
          <button
            disabled={items.length === 0}
            className="button button-primary mr-15"
            onClick={handleSaveSelected}
          >
            Add
          </button>
          <button
            type="button"
            className="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addLiteratureSearchTerms}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </div>
  );
};

LiteratureSearchTerms.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    terms: getLiteratureSearchTerms(state),
    synonymsKey: getLiteratureSearchSynonymsKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addLiteratureSearchTerms(data) {
      dispatch(addLiteratureSearchTermsAction(data));
    },
    removeLiteratureSearchTerms(data) {
      dispatch(removeLiteratureSearchTermsAction(data));
    },
    closeModal() {
      dispatch(closeRankingModal());
    },
    saveSelected() {
      dispatch(saveLiteratureSearchTermsAction());
    },
    setRanking(data) {
      dispatch(setRankingAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiteratureSearchTerms);
