import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormTextarea.scss';

const propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  customClassName: PropTypes.string,
};

class FormTextarea extends React.Component {
  render() {
    const {
      input: {
        onChange,
        ...restInput
      },
      meta: {
        error,
        touched,
      },
      label,
      customClassName,
      ...restCustom
    } = this.props;

    const clsNames = classNames(
      'textarea__wrap',
      customClassName,
    );

    return (
      <div className={clsNames}>
        { label && <span className="textarea__label">{label}</span> }
        <textarea
          className="textarea input-text"
          onChange={event => onChange(event.target.value)}
          {... restInput}
          {... restCustom}
        />
        {
          touched && error &&
          <div className="error-text">{error}</div>
        }
      </div>
    );
  }
}

FormTextarea.propTypes = propTypes;

export default FormTextarea;
