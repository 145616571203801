import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  setPhenotypesDataAction,
  togglePhenotypesLoaderAction,
  phenotypesIsFailedAction,
} from '../../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

export function* getPhenotypesDataWorker(action) {
  try {
    yield put(togglePhenotypesLoaderAction(true));
    const requestData = action.data;
    const response = yield call(Api.getPhenotypesData, requestData);
    yield put(setPhenotypesDataAction(response));
    yield put(togglePhenotypesLoaderAction(false));

    const {
      adverseDrugs,
      animalPhenotypes,
      chords,
      data,
      humanPhenotypes,
    } = response.data;

    if ((adverseDrugs && adverseDrugs.length)
      || (animalPhenotypes && animalPhenotypes.length)
      || (chords && chords.length)
      || (data && data.length)
      || (humanPhenotypes && humanPhenotypes.length)
    ) {
      yield put(geneDetailsSetChapterAction('phenotypes'));
    }
    yield put(loadNextChapterAction('disorders'));
  } catch (e) {
    yield put(phenotypesIsFailedAction(e.message));
    yield put(loadNextChapterAction('disorders'));
  }
}
