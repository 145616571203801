import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';
// Utils
import { getTotalPages } from '../../common/Pagination/utils';
import {CooccurrenceEnum} from '../../../constantsCommon';

const initialState = fromJS({
  setData: {
    set: null,
    loading: false,
    error: '',
    sorting: {
      sortBy: 'name',
      sortDirection: 'ASC',
    },
    pages: {
      totalPages: 0,
      pageNumber: 0,
    },
  },
  openSetDialog: false,
  selectedSetInfo: {
    setId: null,
    projectId: 'personal',
    setName: null,
  },
  selectedConcept: {
    id: null,
    name: null,
    searchPhrase: null,
  },
  method: {
    classification: true,
    differentialExpression: true,
    expressionSpecificity: false,//todo remove
    associationScore: true,
    openTargetsScore: false,
    literatureSearch: true,
    literatureSearchType: CooccurrenceEnum.ABSTRACT,
    involvementOtherDiseases: false,//todo remove
    hallmarksOfCancer: false,//todo remove
    geneticInteractions: true,//todo remove
    geneVariation: true,
    compoundScore: false,
    druggableStructure: true,
    signalingDomain: false,
    intramembrane: false,
    transMembraneDomain: false,
    secretion: false,
    targetValidationStage: false,
  },
});

const reducer = handleActions(
  {
    [a.getRankSelectionSetDataAction]: state =>
      state
        .updateIn(['setData', 'loading'], () => true),
    [a.setRankSelectionSetDataAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'set'], () => fromJS(payload))
        .updateIn(['setData', 'error'], () => initialState.get('error'))
        .updateIn(['setData', 'loading'], () => false)
        .updateIn(['setData', 'pages', 'pageNumber'], () => 0)
        .updateIn(['setData', 'pages', 'totalPages'], () => getTotalPages(payload.items)),
    [a.throwRankSelectionSetDataAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'error'], () => payload)
        .updateIn(['setData', 'loading'], () => false),
    [a.sortRankSelectionSetAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'sorting'], () => fromJS(payload))
        .updateIn(['setData', 'pages', 'pageNumber'], () => 0),
    [a.pagginateRankSelectionSetAction]: (state, { payload }) =>
      state
        .updateIn(['setData', 'pages', 'pageNumber'], () => payload),
    [a.selectRankSelectionMethodAction]: (state, { payload }) =>
      state
        .update('method', (method) => fromJS({
          ...method,
          ...payload,
        })),
    [a.setRankTargetMethodsAction]: (state, { payload }) =>
      state
        .updateIn(['method'], () => fromJS(payload)),
    [a.setSelectedConceptAction]: (state, { payload }) =>
      state
        .updateIn(['selectedConcept'], () => fromJS(payload)),
    [a.closeSetDialogAction]: state =>
      state
        .update('openSetDialog', () => false),
    [a.openSetDialogAction]: state =>
      state
        .update('openSetDialog', () => true),
    [a.setSelectedSetInfoAction]: (state, { payload }) =>
      state
        .updateIn(['selectedSetInfo'], () => fromJS(payload)),
    [a.resetRankSelectionAction]: () => initialState,
  },
  initialState
);

export default reducer;
