import { createSelector } from 'reselect';
import { filterPaginatedData, sort } from '../../../Utils/Utils';
import { PAGE_SIZE } from '../../SimilarProteinsChapter/constants';
import { filterOrthologsData } from '../helpers';

const orthologs = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'data']);
const orthologsSorting = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'sorting']);
const orthologsPage = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'page']);
const orthologTotalPages = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'totalPages']);
const orthologLoading = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'loading']);
const orthologError = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'error']);
const orthologFilters = state => state.getIn(['geneDetailsOrthologsChapterReducer', 'filters']);

export const getAllOrthologsDataSelector = createSelector(
  orthologs,
  orthologsSorting,
  (data, sorting) => sort(data, sorting.toJS()).toJS());

export const getOrthologFiltersSelector = createSelector(
  orthologFilters,
  data => data.toJS()
);

export const getOrthologFiltersAreEmptySelector = createSelector(
  [getOrthologFiltersSelector],
  (filters) => {
    const filtersKeys = Object.keys(filters);
    return filtersKeys.every(item => !filters[item].length);
  }
);

export const getFilteredOrthologsDataSelector = createSelector(
  [
    orthologFilters,
    getAllOrthologsDataSelector,
    getOrthologFiltersAreEmptySelector,
  ],
  (filters, sortedData, filtersAreEmpty) => {
    let filteredData = sortedData;

    if (!filtersAreEmpty) {
      filteredData = filterOrthologsData(sortedData, filters.toJS());
    }

    return filteredData;
  }
);

export const getOrthologsPageSelector = createSelector(
  orthologsPage,
  data => data
);

export const getOrthologTotalPagesPagesSelector = createSelector(
  [
    orthologTotalPages,
    getOrthologFiltersAreEmptySelector,
    getFilteredOrthologsDataSelector,
  ],
  (totalPages, filtersAreEmpty, filteredData) => {
    let newTotalPages = totalPages;
    if (!filtersAreEmpty) newTotalPages = Math.ceil(filteredData.length / PAGE_SIZE);
    return newTotalPages;
  }
);

export const getOrthologsDataSelector = createSelector(
  [
    getFilteredOrthologsDataSelector,
    getOrthologsPageSelector,
  ],
  (filteredData, page) => filterPaginatedData(filteredData, page, PAGE_SIZE)
);

export const getOrthologsSorting = createSelector(
  orthologsSorting,
  data => data.toJS()
);

export const getOrthologsLoadingSelector = createSelector(
  orthologLoading,
  data => data
);

export const getOrthologsErrorSelector = createSelector(
  orthologError,
  data => data
);
