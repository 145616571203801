import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

// Utils
import { filterPaginatedData, sort } from '../../../../Utils/Utils';
import { generateChartData } from '../../../../graphics/GroupedViolinChart/utils';
// Constants
import { PAGE_SIZE, BISPECIFIC_ANTIBODY_FORM_NAME } from '../../../constants';

const targetCandidatesData = state => state.getIn(['bispecificAntibodyReducer', 'table']);
const targetCandidatesFilterByGeneValue = state => state.getIn(['bispecificAntibodyReducer', 'filterByGeneValue']);
const targetCandidatesFirstGeneCharts = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'firstGene', 'charts']);
const targetCandidatesFirstGeneName = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'firstGene', 'geneName']);
const targetCandidatesFirstGeneLoading = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'firstGene', 'loading']);
const targetCandidatesFirstGeneError = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'firstGene', 'error']);
const targetCandidatesSecondGeneCharts = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'secondGene', 'charts']);
const targetCandidatesSecondGeneName = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'secondGene', 'geneName']);
const targetCandidatesSecondGeneLoading = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'secondGene', 'loading']);
const targetCandidatesSecondGeneError = state => state.getIn(['bispecificAntibodyReducer', 'charts', 'secondGene', 'error']);

export const getTargetCandidatesDataSelector = createSelector(
  targetCandidatesData,
  targetCandidatesFilterByGeneValue,
  (tableData, filterByGeneVal) => {
    const {
      page,
      totalPages,
      sorting,
    } = tableData.toJS();

    const sortedData = sort(tableData.get('data'), sorting);
    const data = sortedData && sortedData.toJS();

    let filteredByGeneData = data;
    let newTotalPages = totalPages;

    if (filterByGeneVal) {
      const filterByGeneValLow = filterByGeneVal.toLowerCase();
      filteredByGeneData = data.filter((item) => {
        const { firstGene, secondGene } = item;
        return firstGene.name.toLowerCase().includes(filterByGeneValLow) ||
          secondGene.name.toLowerCase().includes(filterByGeneValLow);
      });
      newTotalPages = Math.ceil(filteredByGeneData.length / PAGE_SIZE);
    }

    const newData = filteredByGeneData ?
      filterPaginatedData(filteredByGeneData, page, PAGE_SIZE) :
      filteredByGeneData;

    return {
      data: newData,
      page,
      totalPages: newTotalPages,
      sorting,
    };
  }
);

export const getTargetCandidatesDataForExportSelector = createSelector(
  targetCandidatesData,
  targetCandidatesFilterByGeneValue,
  (tableData, filterByGeneVal) => {
    const { sorting } = tableData.toJS();
    const sortedData = sort(tableData.get('data'), sorting);
    const data = sortedData && sortedData.toJS();
    let filteredByGeneData = data;

    if (!filteredByGeneData) {
      return filteredByGeneData;
    }

    if (filterByGeneVal) {
      const filterByGeneValLow = filterByGeneVal.toLowerCase();
      filteredByGeneData = data.filter((item) => {
        const { firstGene, secondGene } = item;
        return firstGene.name.toLowerCase().includes(filterByGeneValLow) ||
          secondGene.name.toLowerCase().includes(filterByGeneValLow);
      });
    }

    return filteredByGeneData.map(d => ({
      ...d,
      cancer: {
        ...d.cancer,
        name: `${d.cancer.name}${d.cancer.label ? ` [${d.cancer.label}]` : ''}`,
      },
    }));
  }
);

export const getTargetCandidatesFilterByGeneValueSelector = createSelector(
  targetCandidatesFilterByGeneValue,
  data => data
);

export const getTargetCandidatesForGtexChartDataSelector = createSelector(
  targetCandidatesFirstGeneName,
  targetCandidatesFirstGeneCharts,
  targetCandidatesSecondGeneName,
  targetCandidatesSecondGeneCharts,
  (firstGeneName, _firstGeneData, secondGeneName, _secondGeneData) => {
    const firstGeneData = _firstGeneData && _firstGeneData.toJS();
    const secondGeneData = _secondGeneData && _secondGeneData.toJS();
    return generateChartData(firstGeneName, firstGeneData.gtexExpression, secondGeneName, secondGeneData.gtexExpression);
  }
);

export const getTargetCandidatesFirstGeneLoadingSelector = createSelector(
  targetCandidatesFirstGeneLoading,
  data => data
);

export const getTargetCandidatesFirstGeneErrorSelector = createSelector(
  targetCandidatesFirstGeneError,
  data => data
);

export const getTargetCandidatesForTcgaChartDataSelector = createSelector(
  targetCandidatesFirstGeneName,
  targetCandidatesFirstGeneCharts,
  targetCandidatesSecondGeneName,
  targetCandidatesSecondGeneCharts,
  (firstGeneName, _firstGeneData, secondGeneName, _secondGeneData) => {
    const firstGeneData = _firstGeneData && _firstGeneData.toJS();
    const secondGeneData = _secondGeneData && _secondGeneData.toJS();
    return generateChartData(firstGeneName, firstGeneData.tcgaExpression, secondGeneName, secondGeneData.tcgaExpression);
  }
);

export const getTargetCandidatesSecondGeneLoadingSelector = createSelector(
  targetCandidatesSecondGeneLoading,
  data => data
);

export const getTargetCandidatesSecondGeneErrorSelector = createSelector(
  targetCandidatesSecondGeneError,
  data => data
);

export const getFormValues = formValueSelector(BISPECIFIC_ANTIBODY_FORM_NAME);
