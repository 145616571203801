import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { TwoFactorAuthenticationOptions } from '../constants';

export const getTwoFactorAuthenticationDataAction = createAction('@@twoFactorAuthentication/GET_TWO_FACTOR_AUTHENTICATION_DATA');
export const setTwoFactorAuthenticationDataAction = createAction('@@twoFactorAuthentication/SET_TWO_FACTOR_AUTHENTICATION_DATA');
export const throwTwoFactorAuthenticationDataErrorAction = createAction('@@twoFactorAuthentication/THROW_TWO_FACTOR_AUTHENTICATION_DATA_ERROR');
export const checkTwoFactorAuthenticationDataAction = createAction('@@twoFactorAuthentication/CHECK_TWO_FACTOR_AUTHENTICATION_DATA');
export const updateTwoFactorAuthenticationDataAction = createAction('@@twoFactorAuthentication/UPDATE_TWO_FACTOR_AUTHENTICATION_DATA');
export const updateTwoFactorAuthenticationSelectedOptionAction = createAction('@@twoFactorAuthentication/UPDATE_TWO_FACTOR_AUTHENTICATION_SELECTED_OPTION');
export const updateTwoFactorAuthenticationPrevSelectedOptionAction = createAction('@@twoFactorAuthentication/UPDATE_TWO_FACTOR_AUTHENTICATION_PREV_SELECTED_OPTION');
export const toggleAuthenticatorCodeModalIsOpenAction = createAction('@@twoFactorAuthentication/TOGGLE_CODE_MODAL');
export const throwAuthenticatorCodeErrorAction = createAction('@@twoFactorAuthentication/THROW_AUTHENTICATOR_CODE_ERROR');
export const clearTwoFactorAuthenticationDataAction = createAction('@@twoFactorAuthentication/CLEAR_TWO_FACTOR_AUTHENTICATION_DATA');
export const toggleAuthenticatorCodeModalIsLoadingAction = createAction('@@twoFactorAuthentication/IS_LOADING');


const initialState = fromJS({
  twoFactorAuthentication: {
    code: null,
    url: '',
    loading: false,
    error: '',
    prevSelectedOption: TwoFactorAuthenticationOptions[0],
    selectedOption: TwoFactorAuthenticationOptions[0],
  },
  authenticatorCodeModal: {
    error: '',
    isOpen: false,
    isLoading: false,
  },
});

const reducer = handleActions(
  {
    [getTwoFactorAuthenticationDataAction]: state =>
      state
        .updateIn(['twoFactorAuthentication', 'loading'], () => true),
    [setTwoFactorAuthenticationDataAction]: (state, { payload }) =>
      state
        .updateIn(['twoFactorAuthentication', 'loading'], () => false)
        .updateIn(['twoFactorAuthentication', 'code'], () => payload.secret)
        .updateIn(['twoFactorAuthentication', 'url'], () => payload.url),
    [throwTwoFactorAuthenticationDataErrorAction]: (state, { payload }) =>
      state
        .updateIn(['twoFactorAuthentication', 'error'], () => fromJS(payload)),
    [updateTwoFactorAuthenticationSelectedOptionAction]: (state, { payload }) =>
      state
        .updateIn(['twoFactorAuthentication', 'selectedOption'], () => fromJS(payload)),
    [updateTwoFactorAuthenticationPrevSelectedOptionAction]: (state, { payload }) =>
      state
        .updateIn(['twoFactorAuthentication', 'prevSelectedOption'], () => fromJS(payload)),
    [throwAuthenticatorCodeErrorAction]: (state, { payload }) =>
      state
        .updateIn(['authenticatorCodeModal', 'error'], () => fromJS(payload))
        .updateIn(['authenticatorCodeModal', 'isLoading'], () => false),
    [toggleAuthenticatorCodeModalIsOpenAction]: (state, { payload }) =>
      state
        .updateIn(['authenticatorCodeModal', 'error'], () => '')
        .updateIn(['authenticatorCodeModal', 'isOpen'], () => payload),
    [toggleAuthenticatorCodeModalIsLoadingAction]: (state, { payload }) =>
      state
        .updateIn(['authenticatorCodeModal', 'isLoading'], () => payload),
    [clearTwoFactorAuthenticationDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
