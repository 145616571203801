import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
// Constants
import { RelationMapShapesOptions } from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  color: PropTypes.string,
  shape: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  category: PropTypes.string,
  apply: PropTypes.func,
};

const ColorsAndShapesModal = (props) => {
  const {
    color,
    shape,
    isOpen,
    closeCb,
    category,
    apply,
  } = props;

  const [selectedShape, setSelectedShape] = useState(RelationMapShapesOptions.find(o => o.value === shape));
  const [selectedColor, setSelectedColor] = useState(color);

  function handleChangeShape(value) {
    setSelectedShape(value);
  }

  function handleChangeColor({ hex }) {
    setSelectedColor(hex);
  }

  function handleApply() {
    const updater = {
      color: selectedColor.replace('#', ''),
      shape: selectedShape.value,
    };
    apply(category, updater);
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="colors-and-shapes-modal">
        <div className="colors-and-shapes-modal__title title3">
          {category}
        </div>
        <SelectInput
          grayMode={true}
          value={selectedShape}
          options={RelationMapShapesOptions}
          onSelect={handleChangeShape}
          customClassName="colors-and-shapes-modal__select heatmap-select"
        />
        <SketchPicker
          width={400}
          disableAlpha={true}
          color={selectedColor}
          onChange={handleChangeColor}
        />
        <button
          onClick={handleApply}
          className="button button-primary colors-and-shapes-modal__btn"
        >
          Apply
        </button>
      </div>
    </ModalComponent>
  );
};

ColorsAndShapesModal.propTypes = propTypes;

export default ColorsAndShapesModal;
