import { fromJS, Set } from 'immutable';

import {
  ADD_LITERATURE_SEARCH_TERMS,
  REMOVE_LITERATURE_SEARCH_TERMS,
  SAVE_LITERATURE_SEARCH_TERMS,
  CLEAR_LITERATURE_SEARCH_TERMS,
} from '../../RankSelection/LiteratureSearchTerms/constants';
import {
  ADD_ASSOCIATION_SCORE_TERMS,
  REMOVE_ASSOCIATION_SCORE_TERMS,
  SAVE_ASSOCIATION_SCORE_TERMS,
  CLEAR_ASSOCIATION_SCORE_TERMS,
} from '../../RankSelection/AssociationScoreTerms/constants';
import {
  VALIDATE_FORMULA_SUCCEEDED,
  VALIDATE_FORMULA_FAILED,
} from '../../RankSelection/RankingMarkerSettings/constants';
import {
  ADD_GENE_VARIATION_TERM,
  REMOVE_GENE_VARIATION_TERM,
  SAVE_GENE_VARIATION_TERM,
  CLEAR_GENE_VARIATION_TERM,
} from '../../RankSelection/GeneVariationSettings/constants';
import { factorLogicValues } from '../../NetworkAnalysis/NetworkAnalysisSettings/enum';

import {
  METHOD_SELECTION_TABS_STATE,
  METHOD_SELECTED,
  RESET_ANALYTICS_METHOD_SELECTION,
  OPEN_MENU_CATEGORY_ANALYSIS,
  CLOSE_MENU_CATEGORY_ANALYSIS,
  CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED,
  CATEGORY_ANALYSIS_CATEGORIES_FAILED,
  CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME,
  CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE,
  RANKING_SETTINGS,
  RANKING_TYPE,
  OPEN_RANKING_MODAL,
  CLOSE_RANKING_MODAL,
  TISSUES_SUCCEEDED,
  TISSUES_FAILED,
  OPEN_MENU_TISSUES,
  CLOSE_MENU_TISSUES,
  SELECT_TISSUE_NAME,
  SELECT_PENALTY_TISSUE_NAME,
  SELECT_TISSUE_TYPE,
  SELECT_PENALTY_TISSUES,
  DESELECT_PENALTY_TISSUE_TYPE,
  SELECT_RANKING_CATEGORY_NAME,
  OPEN_MENU_RANKING_CATEGORIES,
  CLOSE_MENU_RANKING_CATEGORIES,
  SELECT_RANKING_TISSUE_TYPE,
  DESELECT_RANKING_TISSUE_TYPE,
  CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES,
  CLEAR_EXPRESSION_SPECIFICITY_SCORE,
  SAVE_RANKING_CONCEPT,
  REFRESH_RANKING_SETTINGS,
  APPLY_TARGET_SETTINGS,
  APPLY_MARKER_SETTINGS,
  SET_SCORING_FORMULA,
  SET_DEFAULT_SCORING_FORMULA,
  SET_DIFFERENTIAL_EXPRESSION_CONCEPT,
  SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  SET_DIFFERENTIAL_EXPRESSION_CATEGORY,
  METHOD_SELECTION_SHOULD_RESET,
  SELECT_GENE_FOR_NETWORK_ANALYSIS,
  SELECT_NETWORK_ANALYSIS_TISSUE_NAME,
  SELECT_NETWORK_ANALYSIS_TISSUE_TYPE,
  RESET_NETWORK_ANALYSIS_TISSUE_SOURCE,
  TICK_NETWORK_ANALYSIS_TISSUE_SOURCE,
  OPEN_MENU_TISSUES_NETWORK_ANALYSIS,
  CLOSE_MENU_TISSUES_NETWORK_ANALYSIS,
  SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS,
  RESET_NETWORK_ANALYSIS_SETTINGS,
  CHANGE_FACTOR_LOGIC_VALUE,
  SET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS_FILTER_OPTION,
  UPDATE_BACKGROUND_SET_CONFIG,
  TOGGLE_BACKGROUND_SET_OPTION,
  TOGGLE_BACKGROUND_CELLS_LOADER,
  SET_BACKGROUND_SET_FILTER_OPTION,
  SWITCH_SHOULD_UPDATE_DATA_FLAG,
  CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  REMOVE_RANKING_CONCEPT,
  FIRST_SELECTED_METHOD,
} from './constants';
import { methodTabEnum } from './enum';
import { defaultProjects } from '../../../Projects/ProjectsTabs/constants';

const initialValues = fromJS({
  factorLogicValue: factorLogicValues[0].value,
  methodTab: methodTabEnum.FIND_RELATED,
  openMenu: false,
  openMenuTissues: false,
  openMenuRankingCategories: false,
  openMenuTissuesNetworkAnalysis: false,
  categories: {
    selectedName: null,
    selectedType: {},
    data: [],
    error: null,
  },
  rankingCategories: {
    selectedName: null,
    selectedType: new Set(),
    data: [],
    error: null,
  },
  tissues: {
    selectedName: null,
    selectedType: null,
    selectedTissue: null,
    selectedSource: null,
    data: [],
    error: null,
  },
  networkAnalysisTissues: {
    selectedName: null,
    selectedType: null,
    selectedSource: null,
    data: [],
    error: null,
  },
  penaltyTissues: {
    tissues: [], // selectedName, selectedId, selectedSource
    data: [],
    error: null,
  },
  defaultScoringFormula: '(Et/E10th) + (Et/Em) * (1-(Enogo/Et)) + 2*(1+(Et-2*Epen)/(Et+Epen))',
  currentScoringFormula: '(Et/E10th) + (Et/Em) * (1-(Enogo/Et)) + 2*(1+(Et-2*Epen)/(Et+Epen))',
  validFormula: true,
  rankingSettings: {
    classification: true,
    differentialExpression: false,
    expressionSpecificity: false,
    associationScore: false,
    literatureSearch: false,
    involvementOtherDiseases: false,
    hallmarksOfCancer: false,
    geneticInteractions: false,
    geneVariation: false,
    compoundScore: false,
    druggableStructure: false,
    signalingDomain: false,
    intramembrane: false,
    transMembraneDomain: false,
    secretion: false,
    targetValidationStage: false,
  },
  rankingType: 'user selection',
  rankingModalOpen: {
    associationScore: false,
    literatureSearch: false,
    differentialExpression: false,
    expressionSpecificity: false,
    geneVariation: false,
    'rank as target': false,
    'rank as marker': false,
    networkAnalysisSettings: false,
  },
  associationScoreTerms: new Set(),
  associationScoreTermsSaved: new Set(),
  literatureSearchTerms: new Set(),
  literatureSearchTermsSaved: new Set(),
  method: null,
  firstSelectedMethod: null,
  rankingConcept: null,
  geneVariationDisease: null,
  geneVariationDiseaseSaved: null,
  differentialExpressionConcept: null,
  differentialExpressionConceptSaved: null,
  differentialExpressionSC: 'Disorders',
  shouldReset: false,
  networkAnalysisSettings: {
    gene: null,
    tissue: '',
    selectedSetId: '',
  },
  backgroundCells: null,
  backgroundCellsLoader: false,
  backgroundCellsFilterOption: '',
  backgroundSetOptions: {
    allGenesOption: true,
    cellTypeOption: false,
    selectSetOption: false,
  },
  backgroundSetFilterOption: '',
  backgroundSetActiveProjectId: defaultProjects[0].id,
  backgroundSetConfiguration: {
    backgroundSetType: 'GENES',
  },
  shouldUpdateDataFlag: false,
  selectedPenaltyTissues: [],
});

const methodSelection = (state = initialValues, action) => {
  switch (action.type) {
    case CHANGE_FACTOR_LOGIC_VALUE:
      return state.set('factorLogicValue', fromJS(action.data));

    case METHOD_SELECTION_SHOULD_RESET:
      return state.update('shouldReset', () => action.data);

    case METHOD_SELECTION_TABS_STATE:
      return state.set('methodTab', action.data);

    case METHOD_SELECTED:
      return state.merge(fromJS({ method: action.data }));

    case FIRST_SELECTED_METHOD:
      return state.merge(fromJS({ firstSelectedMethod: action.data }));

    case OPEN_MENU_CATEGORY_ANALYSIS:
      return state.merge(fromJS({ openMenu: true }));

    case CLOSE_MENU_CATEGORY_ANALYSIS:
      return state.merge(fromJS({ openMenu: false }));

    case OPEN_MENU_TISSUES_NETWORK_ANALYSIS:
      return state.merge(fromJS({ openMenuTissuesNetworkAnalysis: true }));

    case CLOSE_MENU_TISSUES_NETWORK_ANALYSIS:
      return state.merge(fromJS({ openMenuTissuesNetworkAnalysis: false }));

    case OPEN_MENU_TISSUES:
      return state.merge(fromJS({ openMenuTissues: true }));

    case CLOSE_MENU_TISSUES:
      return state.merge(fromJS({ openMenuTissues: false }));

    case OPEN_MENU_RANKING_CATEGORIES:
      return state.merge(fromJS({ openMenuRankingCategories: true }));

    case CLOSE_MENU_RANKING_CATEGORIES:
      return state.merge(fromJS({ openMenuRankingCategories: false }));

    case CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED:
      return state
        .update('categories', categories => (
          categories.merge(fromJS({ data: action.data, error: null }))
        ))
        .update('rankingCategories', rankingCategories => (
          rankingCategories.merge(fromJS({ data: action.data, error: null }))
        ));

    case CATEGORY_ANALYSIS_CATEGORIES_FAILED:
      return state
        .update('categories', categories => (
          categories.merge(fromJS({ data: [], error: action.data }))
        ))
        .update('rankingCategories', rankingCategories => (
          rankingCategories.merge(fromJS({ data: [], error: action.data }))
        ));

    case CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME:
      return state.update('categories', categories => (
        categories.merge(fromJS({ selectedName: action.data, selectedType: {} }))
      ));

    case SELECT_RANKING_CATEGORY_NAME:
      return state.update('rankingCategories', rankingCategories => (
        rankingCategories.merge(fromJS({ selectedName: action.data }))
      ));

    case SELECT_TISSUE_NAME:
      return state.update('tissues', tissues => (
        tissues.merge(fromJS({ selectedName: action.data, selectedType: null }))
      ));

    case SELECT_PENALTY_TISSUE_NAME:
      return state.update('selectedPenaltyTissues', () => fromJS(action.data));

    case SELECT_TISSUE_TYPE: {
      const idAndSource = action.data.value.split(':');
      return state
        .update('tissues', tissues => tissues.merge(fromJS({ selectedType: idAndSource[0], selectedTissue: action.data })))
        .update('selectedSource', () => idAndSource[1]);
    }

    case SELECT_NETWORK_ANALYSIS_TISSUE_TYPE: {
      const naIdAndSource = action.data.value.split(':');
      return state
        .update('networkAnalysisTissues', networkAnalysisTissues => (
          networkAnalysisTissues.merge(fromJS({
            selectedType: naIdAndSource[0],
            selectedSource: naIdAndSource[1],
            selectedTissue: action.data,
          }))
        ));
    }

    case SELECT_PENALTY_TISSUES: {
      const penaltyIdAndSource = action.data.value.split(':');
      return state
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.update('tissues', tissues => (
            [
              ...tissues,
              {
                selectedId: penaltyIdAndSource[0],
                selectedSource: penaltyIdAndSource[1],
                selectedName: action.data.label,
              },
            ])
          ))
        );
    }

    case DESELECT_PENALTY_TISSUE_TYPE:
      return state
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.update('tissues', tissues => tissues.filter(item => item !== action.data))
        ));

    case SELECT_RANKING_TISSUE_TYPE:
      return state.update('rankingCategories', penaltyTissues => (
        penaltyTissues.update('selectedType', selectedType => (
          selectedType.add(fromJS(action.data))
        ))
      ));

    case DESELECT_RANKING_TISSUE_TYPE:
      return state.update('rankingCategories', penaltyTissues => (
        penaltyTissues.update('selectedType', selectedType => (
          selectedType.delete(selectedType.find(listItem => (
            listItem.get('id') === action.data.id
          )))
        ))
      ));

    case CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE:
      return state.update('categories', categories => (
        categories.merge(fromJS({ selectedType: action.data }))
      ));

    case RANKING_SETTINGS:
      return state.update('rankingSettings', rankingSettings => (
        rankingSettings.merge(fromJS(action.data))
      ));

    case OPEN_RANKING_MODAL:
      return state.update('rankingModalOpen', rankingModalOpen => (
        rankingModalOpen.merge(fromJS({ [action.data]: true }))
      ));

    case CLOSE_RANKING_MODAL:
      return state.update('rankingModalOpen', rankingModalOpen => (
        rankingModalOpen.merge(initialValues.get('rankingModalOpen'))
      ));

    case ADD_ASSOCIATION_SCORE_TERMS:
      return state.update('associationScoreTerms', associationScoreTerms => (
        associationScoreTerms.add(fromJS(action.data))
      ));

    case REMOVE_ASSOCIATION_SCORE_TERMS:
      return state.update('associationScoreTerms', associationScoreTerms => (
        associationScoreTerms.delete(associationScoreTerms.find(listItem => (
          listItem.get('id') === action.data
        )))
      ));

    case SAVE_ASSOCIATION_SCORE_TERMS:
      return state.update('associationScoreTermsSaved', () => (
        state.get('associationScoreTerms')
      ));

    case CLEAR_ASSOCIATION_SCORE_TERMS:
      return state
        .update('associationScoreTermsSaved', () => (
          new Set()
        ))
        .update('associationScoreTerms', () => (
          new Set()
        ));

    case CLEAR_EXPRESSION_SPECIFICITY_SCORE:
      return state
        .update('tissues', tissues => (
          tissues.merge(fromJS({
            selectedName: null,
            selectedId: null,
            selectedSource: null,
          }))
        ))
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.merge(fromJS({ tissues: [] }))
        ))
        .update('currentScoringFormula', () => state.get('defaultScoringFormula'));

    case ADD_LITERATURE_SEARCH_TERMS:
      return state.update('literatureSearchTerms', literatureSearchTerms => (
        literatureSearchTerms.add(fromJS(action.data))
      ));

    case REMOVE_LITERATURE_SEARCH_TERMS:
      return state.update('literatureSearchTerms', literatureSearchTerms => (
        literatureSearchTerms.delete(literatureSearchTerms.find(listItem => (
          listItem.get('id') === action.data
        )))
      ));

    case SAVE_LITERATURE_SEARCH_TERMS:
      return state.update('literatureSearchTermsSaved', () => (
        state.get('literatureSearchTerms')
      ));

    case CLEAR_LITERATURE_SEARCH_TERMS:
      return state
        .update('literatureSearchTermsSaved', () => (
          new Set()
        ))
        .update('literatureSearchTerms', () => (
          new Set()
        ));

    case CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES:
      return state
        .update('differentialExpressionConcept', () => (
          initialValues.get('differentialExpressionConcept')
        ))
        .update('differentialExpressionConceptSaved', () => (
          initialValues.get('differentialExpressionConceptSaved')
        ));

    case RANKING_TYPE:
      return state.merge(fromJS({ rankingType: action.data }));

    case TISSUES_SUCCEEDED:
      return state
        .update('tissues', tissues => (
          tissues.merge(fromJS({ data: action.data, error: null }))
        ))
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.merge(fromJS({ data: action.data, error: null }))
        ))
        .update('networkAnalysisTissues', networkAnalysisTissues => (
          networkAnalysisTissues.merge(fromJS({ data: action.data, error: null }))
        ));

    case TISSUES_FAILED:
      return state
        .update('tissues', tissues => (
          tissues.merge(fromJS({ data: [], error: action.data }))
        ))
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.merge(fromJS({ data: [], error: action.data }))
        ))
        .update('networkAnalysisTissues', networkAnalysisTissues => (
          networkAnalysisTissues.merge(fromJS({ data: [], error: action.data }))
        ));

    case TICK_NETWORK_ANALYSIS_TISSUE_SOURCE:
      return state.update('selectedNetworkAnalysisSource', selectedNetworkAnalysisSource => (
        selectedNetworkAnalysisSource.merge(fromJS(action.data))
      ));

    case RESET_NETWORK_ANALYSIS_TISSUE_SOURCE:
      return state.update('selectedNetworkAnalysisSource', () => (
        initialValues.get('selectedNetworkAnalysisSource')
      ));
    case SAVE_RANKING_CONCEPT:
      return state.update('rankingConcept', () => fromJS(action.data));
    case REMOVE_RANKING_CONCEPT:
      return state.update('rankingConcept', () => initialValues.get('rankingConcept'));
    case ADD_GENE_VARIATION_TERM:
      return state.update('geneVariationDisease', () => (
        fromJS(action.data)
      ));

    case REMOVE_GENE_VARIATION_TERM:
      return state.update('geneVariationDisease', () => (
        initialValues.get('geneVariationDisease')
      ));

    case SAVE_GENE_VARIATION_TERM:
      return state.update('geneVariationDiseaseSaved', () => (
        state.get('geneVariationDisease')
      ));

    case CLEAR_GENE_VARIATION_TERM:
      return state
        .update('geneVariationDiseaseSaved', () => (
          initialValues.get('geneVariationDiseaseSaved')
        ))
        .update('geneVariationDisease', () => (
          initialValues.get('geneVariationDisease')
        ));

    case SET_SCORING_FORMULA:
      return state
        .update('currentScoringFormula', () => (
          fromJS(action.data)
        ))
        .update('validFormula', () => false);

    case SET_DEFAULT_SCORING_FORMULA:
      return state
        .update('currentScoringFormula', () => (
          state.get('defaultScoringFormula')
        ))
        .update('validFormula', () => true);

    case VALIDATE_FORMULA_SUCCEEDED:
      return state.update('validFormula', () => true);

    case VALIDATE_FORMULA_FAILED:
      return state.update('validFormula', () => false);

    case APPLY_TARGET_SETTINGS:
      return state
        .update('associationScoreTerms', associationScoreTerms => (
          associationScoreTerms.add(state.get('rankingConcept'))
        ))
        .update('associationScoreTermsSaved', associationScoreTermsSaved => (
          associationScoreTermsSaved.add(state.get('rankingConcept'))
        ))
        .update('rankingSettings', rankingSettings => (
          rankingSettings.merge(fromJS({
            associationScore: true,
            classification: true,
            geneticInteractions: true,
            druggableStructure: true,
          }))
        ));

    case APPLY_MARKER_SETTINGS:
      return state
        .update('associationScoreTerms', associationScoreTerms => (
          associationScoreTerms.add(state.get('rankingConcept'))
        ))
        .update('associationScoreTermsSaved', associationScoreTermsSaved => (
          associationScoreTermsSaved.add(state.get('rankingConcept'))
        ))
        .update('rankingSettings', rankingSettings => (
          rankingSettings.merge(fromJS({ associationScore: true, expressionSpecificity: true }))
        ));

    case SET_DIFFERENTIAL_EXPRESSION_CONCEPT:
      return state.update('differentialExpressionConcept', () => (
        fromJS(action.data)
      ));

    case SET_DIFFERENTIAL_EXPRESSION_CATEGORY:
      return state.update('differentialExpressionSC', () => (
        fromJS(action.data)
      ));

    case SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT:
      return state.update('differentialExpressionConceptSaved', () => (
        state.get('differentialExpressionConcept')
      ));

    case REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT:
      return state.update('differentialExpressionConcept', () => (
        initialValues.get('differentialExpressionConcept')
      ));

    case REFRESH_RANKING_SETTINGS:
      return state
        .update('rankingCategories', rankingCategories => (
          rankingCategories.merge(fromJS({ selectedName: null, selectedType: new Set() }))
        ))
        .update('tissues', tissues => (
          tissues.merge(fromJS({ selectedName: null, selectedId: null, selectedSource: null }))
        ))
        .update('penaltyTissues', penaltyTissues => (
          penaltyTissues.merge(fromJS({ tissues: [] }))
        ))
        .update('rankingSettings', () => (
          initialValues.get('rankingSettings')
        ))
        .update('associationScoreTerms', () => (
          initialValues.get('associationScoreTerms')
        ))
        .update('associationScoreTermsSaved', () => (
          initialValues.get('associationScoreTermsSaved')
        ))
        .update('literatureSearchTerms', () => (
          initialValues.get('literatureSearchTerms')
        ))
        .update('literatureSearchTermsSaved', () => (
          initialValues.get('literatureSearchTermsSaved')
        ))
        .update('rankingConcept', () => (
          initialValues.get('rankingConcept')
        ))
        .update('validFormula', () => true)
        .update('currentScoringFormula', () => (
          state.get('defaultScoringFormula')
        ))
        .update('differentialExpressionConcept', () => (
          initialValues.get('differentialExpressionConcept')
        ))
        .update('differentialExpressionConceptSaved', () => (
          initialValues.get('differentialExpressionConceptSaved')
        ))
        .update('geneVariationDisease', () => (
          initialValues.get('geneVariationDisease')
        ))
        .update('geneVariationDiseaseSaved', () => (
          initialValues.get('geneVariationDiseaseSaved')
        ))
        .update('rankingType', () => (
          initialValues.get('rankingType')
        ));

    case SELECT_GENE_FOR_NETWORK_ANALYSIS:
      return state.update('networkAnalysisSettings', networkAnalysisSettings => (
        networkAnalysisSettings.set('gene', fromJS(action.data))
      ));

    case SELECT_NETWORK_ANALYSIS_TISSUE_NAME:
      return state.update('networkAnalysisTissues', networkAnalysisTissues => (
        networkAnalysisTissues.merge(fromJS({ selectedName: action.data }))
      ));

    case SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS:
      return state.update('networkAnalysisSettings', networkAnalysisSettings => (
        networkAnalysisSettings.set('selectedSetId', fromJS(action.data))
      ));

    case RESET_NETWORK_ANALYSIS_SETTINGS:
      return state
        .set('selectedNetworkAnalysisSource', initialValues.get('selectedNetworkAnalysisSource'))
        .set('networkAnalysisSettings', initialValues.get('networkAnalysisSettings'));

    case SET_BACKGROUND_CELLS:
      return state
        .update('backgroundCells', () => fromJS(action.data))
        .update('backgroundCellsFilterOption', (backgroundCellsFilterOption) => {
          if (backgroundCellsFilterOption.length) {
            return backgroundCellsFilterOption;
          }
          return Object.keys(action.data)[0];
        });

    case SET_BACKGROUND_CELLS_FILTER_OPTION:
      return state.update('backgroundCellsFilterOption', () => action.data);

    case TOGGLE_BACKGROUND_CELLS_LOADER:
      return state.update('backgroundCellsLoader', () => action.data);

    case UPDATE_BACKGROUND_SET_CONFIG:
      return state.update('backgroundSetConfiguration', () => fromJS(action.data));

    case TOGGLE_BACKGROUND_SET_OPTION:
      return state.update('backgroundSetOptions', () => fromJS(action.data));

    case SET_BACKGROUND_SET_FILTER_OPTION:
      return state.set('backgroundSetFilterOption', action.data);

    case SWITCH_SHOULD_UPDATE_DATA_FLAG:
      return state.set('shouldUpdateDataFlag', action.data);

    case SET_BACKGROUND_SET_ACTIVE_PROJECT_ID:
      return state
        .update('backgroundSetActiveProjectId', () => action.data);

    case CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID:
      return state
        .update('backgroundSetActiveProjectId', () => initialValues.get('backgroundSetActiveProjectId'));

    case RESET_ANALYTICS_METHOD_SELECTION:
      return state.merge(initialValues);

    default:
      return state;
  }
};

export default methodSelection;
