function getColor(pubTypes) {
  if (pubTypes.includes('REVIEW')) {
    return '#8bc34a';
  } else if (pubTypes.includes('CLINICAL_TRIAL')) {
    return '#ffeb3b';
  }
  return '#51a2fb';
}

function createDomains(citations) {
  const domains = {
    y: { min: 0, max: 0 },
    z: { min: 0, max: 0 },
    x: { cited: { min: null, max: null }, citedBy: { min: null, max: null } },
    yearsCount: { cited: 0, citedBy: 0 },
  };
  const pubsPerYear = { cited: {}, citedBy: {} };

  return citations.reduce((acc, c, i, array) => {
    if (!c.publicationDate) {
      return acc;
    }
    const d = { ...acc };
    const date = new Date(c.publicationDate).getFullYear();
    const key = c.type === 'CITED' ? 'cited' : 'citedBy';

    if (i === 0) {
      d.x[key].min = date;
      d.x[key].max = date;
      d.y.max = c.impactFactor;
      d.z.max = c.numberOfCitations;
    } else {
      d.x[key].min = acc.x[key].min ? Math.min(acc.x[key].min, date) : date;
      d.x[key].max = acc.x[key].max ? Math.max(acc.x[key].max, date) : date;
      d.y.max = Math.max(c.impactFactor, d.y.max);
      d.z.max = Math.max(c.numberOfCitations, d.z.max);
    }

    if (!pubsPerYear[key][date]) {
      pubsPerYear[key][date] = 1;
    } else {
      pubsPerYear[key][date] += 1;
    }

    if (array.length - 1 === i) {
      return {
        ...d,
        yearsCount: {
          cited: d.x.cited.max - d.x.cited.min,
          citedBy: d.x.citedBy.max - d.x.citedBy.min,
        },
      };
    }

    return d;
  }, domains);
}

function groupCitations(citations) {
  return citations.reduce((g, citation) => {
    if (!citation.publicationDate) {
      return g;
    }
    const citationDate = new Date(citation.publicationDate);
    const citationDateString = `${citationDate.getMonth()}/${citationDate.getFullYear()}`;
    const impactFactor = citation.impactFactor.toFixed(1);
    const pubsEntity = {
      impactFactor,
      publicationDate: citation.publicationDate,
      publicationTypes: citation.publicationTypes,
      numberOfCitations: citation.numberOfCitations,
      color: getColor(citation.publicationTypes),
      opacity: 0.4,
      ids: [citation.publicationId],
      type: citation.type,
    };
    const sameData = g.find((element) => {
      if (element.impactFactor !== impactFactor) {
        return false;
      }
      const elementDate = new Date(element.publicationDate);
      const elementDateString = `${elementDate.getMonth()}/${elementDate.getFullYear()}`;
      return elementDateString === citationDateString;
    });
    if (sameData) {
      sameData.ids.push(citation.publicationId);
      sameData.numberOfCitations += citation.numberOfCitations;
      sameData.publicationTypes = [...sameData.publicationTypes, ...citation.publicationTypes];
      sameData.color = getColor(sameData.publicationTypes);
      sameData.opacity = 0.7;
    } else {
      g.push(pubsEntity);
    }
    return g;
  }, []);
}

function splitCitationsByType(citations) {
  return citations.reduce((d, c) => {
    if (c.type === 'CITED') {
      d.citedData.push(c);
    } else if (c.type === 'CITED_BY') {
      d.citedByData.push(c);
    }
    return d;
  }, { citedData: [], citedByData: [] });
}

function formatChartsData(citationsObj) {
  const citations = Object.values(citationsObj);
  const { citedData, citedByData } = splitCitationsByType(citations);
  const groupedCitedData = groupCitations(citedData)
    .sort((a, b) => a.numberOfCitations - b.numberOfCitations)
    .reverse();
  const groupedCitedByData = groupCitations(citedByData)
    .sort((a, b) => a.numberOfCitations - b.numberOfCitations)
    .reverse();

  return {
    domains: createDomains([...groupedCitedData, ...groupedCitedByData]),
    citedData: groupedCitedData,
    citedByData: groupedCitedByData,
  };
}

export { formatChartsData };
