import { createSelector } from 'reselect';

const method = state => state.getIn(['setAnalysisMethodSelection', 'method']);
const firstSelectedMethod = state => state.getIn(['setAnalysisMethodSelection', 'firstSelectedMethod']);
const selectedType = state => state.getIn(['setAnalysisMethodSelection', 'categories', 'selectedType']);

export const selectedMethodSelector = createSelector(
  method,
  data => data
);

export const firstSelectedMethodSelector = createSelector(
  firstSelectedMethod,
  data => data
);

export const getSelectedCategoryType = createSelector(
  selectedType,
  data => data.toJS()
);
