import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Icons
import { MdDelete } from 'react-icons/md';
import { SiMoleculer } from 'react-icons/si';
import { BiDetail, BiSortDown, BiSortUp } from 'react-icons/bi';
import { GrSort } from 'react-icons/gr';
// Components
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
// Constants
import { RELATIVE_PATH } from '../../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  cacheData: PropTypes.func,
  allSelected: PropTypes.bool,
  updateConcept: PropTypes.func,
  deleteConcepts: PropTypes.func,
  toggleAllConcepts: PropTypes.func,
  selectedConcepts: PropTypes.instanceOf(Array),
  setShowConfirmDialog: PropTypes.func,
};

const RelationMapConceptsList = (props) => {
  const {
    concepts,
    cacheData,
    allSelected,
    updateConcept,
    deleteConcepts,
    toggleAllConcepts,
    selectedConcepts,
    setShowConfirmDialog,
  } = props;

  const listRef = useRef(null);
  const [sorting, setSorting] = useState(null);
  const [grouping, setGrouping] = useState(false);

  const getSortedConcepts = useCallback(() => {
    if (sorting === null) return concepts;
    const sorted = concepts.sort((a, b) => a.name.localeCompare(b.name));
    if (sorting === 'DESC') {
      return sorted.reverse();
    }
    return sorted;
  }, [sorting, concepts]);

  const getGroupedConcepts = useCallback(() => {
    const groups = concepts.reduce((g, c) => {
      if (c.selected) {
        g.checked.push(c);
      } else {
        g.notChecked.push(c);
      }
      return g;
    }, { checked: [], notChecked: [] });

    if (sorting === 'ASC') {
      groups.checked.sort((a, b) => a.name.localeCompare(b.name));
      groups.notChecked.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sorting === 'DESC') {
      groups.checked.sort((a, b) => -a.name.localeCompare(b.name));
      groups.notChecked.sort((a, b) => -a.name.localeCompare(b.name));
    }

    return [...groups.checked, ...groups.notChecked];
  }, [sorting, grouping, concepts]);

  const conceptsCount = concepts.length;
  const sortedConcepts = grouping ? getGroupedConcepts() : getSortedConcepts();

  function sortConcepts() {
    if (sorting === 'ASC') {
      setSorting('DESC');
    } else {
      setSorting('ASC');
    }
  }

  function toggleConceptHandler(d) {
    const updater = {
      id: d.id,
      data: { selected: !d.selected },
    };
    updateConcept(updater);

    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }

  function toggleAllConceptsHandler() {
    toggleAllConcepts(!allSelected);

    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }

  function deleteConceptsHandler() {
    const ids = selectedConcepts.map(c => c.id);
    setShowConfirmDialog({
      show: true,
      config: {
        onConfirm: () => {
          cacheData();
          deleteConcepts(ids);
        },
        text: `Are you sure you want to remove ${selectedConcepts.length} selected concepts?`,
      },
    });
  }

  function rowRenderer(rowData) {
    const { index, style } = rowData;
    const {
      id,
      name,
      color,
      selected,
      semanticCategory,
    } = sortedConcepts[index];

    const rowClass = classNames(
      'relation-map-concepts-list__item-wrap',
      { 'relation-map-concepts-list__item-wrap_active': selected }
    );

    const rowLink = semanticCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;

    return (
      <div
        style={style}
        key={id}
        className="relation-map-concepts-list__item"
      >
        <div className={rowClass}>
          <div className="relation-map-concepts-list__name">
            <Checkbox
              id={`checkbox-${id}`}
              checked={selected}
              onChange={() => { toggleConceptHandler(sortedConcepts[index]); }}
            />
            <span className="relation-map-concepts-list__name-text">
              {name}
            </span>
          </div>
          <Link to={rowLink} className="circle-btn relation-map-concepts-list__link" target="blank">
            <BiDetail size={16} />
          </Link>
          {
            color &&
            <div
              style={selected ? { backgroundColor: `#${color}` } : null}
              className="relation-map-concepts-list__color"
            />
          }
        </div>
      </div>
    );
  }

  const groupIconClass = classNames(
    'group-icon',
    { 'group-icon_active': grouping }
  );

  return (
    <>
      <div className="relation-map-page__subtitle">
        <SiMoleculer size={22} color="#76649d" />
        <span>Concepts</span>
        <div className="relation-map-concepts-list__count">
          {`${selectedConcepts.length}/${conceptsCount}`}
        </div>
      </div>
      {
        conceptsCount > 0 &&
        <div className="relation-map-concepts-list">
          <div className="relation-map-concepts-list__header">
            <div className="relation-map-concepts-list__header-name">
              <Checkbox
                id="relation-map-concepts-list"
                checked={allSelected}
                onChange={toggleAllConceptsHandler}
              />
              Name
            </div>
            <button className="circle-btn" onClick={sortConcepts}>
              {
                sorting === 'ASC' ?
                  <BiSortUp size={16} color="#4b3f63" /> :
                  <BiSortDown size={16} color="#4b3f63" />
              }
            </button>
            <button className="circle-btn" onClick={() => setGrouping(!grouping)}>
              <GrSort size={14} className={groupIconClass} />
            </button>
            <button className="circle-btn" onClick={deleteConceptsHandler}>
              <MdDelete size={16} color="#4b3f63" />
            </button>
          </div>
          <List
            ref={listRef}
            width={360}
            height={Math.min(conceptsCount * 45, 270)}
            rowCount={conceptsCount}
            rowHeight={45}
            rowRenderer={rowRenderer}
          />
        </div>
      }
    </>
  );
};

RelationMapConceptsList.propTypes = propTypes;

export default React.memo(RelationMapConceptsList);
