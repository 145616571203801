import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
// Utils
import { GREY_COLOR, GOLD_COLOR } from '../../../constantsCommon';
import {
  capitalizeFirstLetter,
  exportToPNG,
  exportToSVG,
} from '../../Utils/Utils';
// Store
import { hideTooltipAction, showTooltipAction } from '../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../Concept/ShortConceptCard/reducer';
// Styles
import './GeneDiseaseBarChart.css';


const propTypes = {
  data: PropTypes.instanceOf(Array),
  color: PropTypes.string,
  colorHighlighted: PropTypes.string,
  rotateXAxis: PropTypes.bool,
  id: PropTypes.string,
  filterTable: PropTypes.func,
  highlightedTissuesIds: PropTypes.instanceOf(Array),
  chapterName: PropTypes.string,
  sourceName: PropTypes.string,
  geneDiseaseName: PropTypes.string,
  showTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  hideTooltip: PropTypes.func,
  diseaseName: PropTypes.string,
};

class GeneDiseaseBarChart extends React.Component {
  componentWillUnmount() {
    this.clearingD3();
  }

  componentDidMount() {
    this.initDiagram(this.props.data, this.props.color, this.props.colorHighlighted);
  }

  componentDidUpdate() {
    this.clearingD3();
    this.initDiagram(this.props.data, this.props.color, this.props.colorHighlighted);
  }

  clearingD3 = () => {
    window.d3.select(`#${this.props.id}`).selectAll('*')
      .remove();
    window.d3.select(`#diagramExpressionBarTooltip-${this.props.id}`).remove();
  };

  initDiagram = (data = [], color = GREY_COLOR, colorHighlighted = GOLD_COLOR) => {
    const { filterTable, diseaseName } = this.props;
    const BAR_HEIGHT = 19;
    const CANVAS_WIDTH = 800;

    const highlightedBarsIds = this.props.highlightedTissuesIds || [];

    const sortedData = data.sort((i, ii) => {
      if (i.measure > ii.measure) {
        return -1;
      }
      if (i.measure < ii.measure) {
        return 1;
      }
      return 0;
    });

    const orderIndexesOfHighlighted = [];
    sortedData.forEach((item, index) => {
      if (highlightedBarsIds.indexOf(item.id) > -1) {
        orderIndexesOfHighlighted.push(index + 1);
      }
    });

    const tissues = sortedData.map(item => capitalizeFirstLetter(item.name));

    tissues.unshift('');

    const tissuesLength = tissues.length;

    const values = sortedData.map(item => (
      item.measure
    ));

    values.unshift(0);

    const MAX_VALUE = Math.max.apply(null, values);

    const stepSize = MAX_VALUE / 10;

    const CANVAS_MIN_HEIGHT = 230;
    const CANVAS_HEIGHT = tissuesLength * 30 > CANVAS_MIN_HEIGHT ? tissuesLength * 30 : CANVAS_MIN_HEIGHT;

    const grid = window.d3.range(12).map(function() {//eslint-disable-line
      return {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: CANVAS_HEIGHT - 70,
      };
    });

    const tickVals = grid.map(function(d,i) {//eslint-disable-line
      return i * stepSize;
    });

    const xscale = window.d3.scale.linear()
      .domain([0, MAX_VALUE + (stepSize * 2)])
      .range([0, CANVAS_WIDTH - 120]);

    const yscale = window.d3.scale.linear()
      .domain([0, tissues.length])
      .range([0, CANVAS_HEIGHT - 70]);

    const canvas = window.d3.select(`#${this.props.id}`)
      .append('svg')
      .attr({
        width: CANVAS_WIDTH + 50,
        height: CANVAS_HEIGHT + 20,
      });

    const tooltip = window.d3.select('body')
      .append('div')
      .attr('id', `diagramExpressionBarTooltip-${this.props.id}`)
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('opacity', 0);

    let timeOut;
    const filterCategoriesArr = [];

    function clickOnBar(d, i) {
      if (!filterTable) return;
      const clickedChart = window.d3.select(this);
      const category = tissues[i];
      const categoryIndex = filterCategoriesArr.indexOf(category);

      if (categoryIndex !== -1) {
        filterCategoriesArr.splice(categoryIndex, 1);
        clickedChart.style('fill', color);
      } else {
        filterCategoriesArr.push(category);
        clickedChart.style('fill', colorHighlighted);
      }
      filterTable(filterCategoriesArr);
    }

    function closeTooltip() {
      timeOut = setTimeout(() => {
        tooltip.style('opacity', 0);
      }, 4000);
    }

    function mouseOverBar(d, i) {
      window.d3.select(this).attr('stroke', 'gray');
      const tooltipText = diseaseName ? `${diseaseName} - ${sortedData[i - 1].name}: ${d}` : `${sortedData[i - 1].name}: ${d}`;
      tooltip.html(`<span class="expression-tooltip"><b>${tooltipText}</b></span>`);
      return tooltip.transition()
        .duration(50)
        .style('opacity', 0.9);
    }

    function mouseOutBar() {
      window.d3.select(this).attr('stroke', '');
      clearTimeout(timeOut);
      closeTooltip();
      return tooltip.style('opacity', 0);
    }

    function mouseMoveArc() {
      return tooltip
        .style('top', `${(window.d3.event.pageY - 10)}px`)
        .style('left', `${(window.d3.event.pageX + 10)}px`);
    }

    const mouseOverSideTitle = (d) => {
      const { showTooltip, setShortConceptCardId, id: parentPropId } = this.props;
      let id;
      const element = this.props.data[d - 1];
      if (element) {
        const {
          concept,
        } = element;

        if (concept) {
          // eslint-disable-next-line prefer-destructuring
          id = concept.id;
        } else {
          // eslint-disable-next-line prefer-destructuring
          id = element.id;
        }

        if (id) {
          const config = {
            uniqueKey: `gene-disease-bar-chart-tooltip_${parentPropId}`,
            clientX: window.d3.event.pageX,
            clientY: window.d3.event.clientY,
          };

          setShortConceptCardId(id);
          showTooltip(config);
        }
      }
    };

    const mouseMoveSideTitle = () => {
      this.props.hideTooltip();
    };

    canvas.append('g')
      .attr('id', `grid-${this.props.id}`)
      .attr('transform', 'translate(150,20)')
      .selectAll('line')
      .data(grid)
      .enter()
      .append('line')
      .attr('class', 'grid-vertical-line')
      .attr({
        x1: (d, i) => i * 30,
        y1: d => d.y1,
        x2: (d, i) => i * 30,
        y2: d => d.y2,
      })
      .style({ stroke: '#adadad', 'stroke-width': '1px' });

    const xAxis = window.d3.svg.axis();
    xAxis
      .orient('bottom')
      .scale(xscale)
      .tickSize(1)
      .tickValues(tickVals);

    const yAxis = window.d3.svg.axis();
    yAxis
      .orient('left')
      .scale(yscale)
      .tickSize(1)
      .tickFormat((d, i) => tissues[i])
      .tickValues(window.d3.range(tissuesLength));

    const y_xis = canvas.append('g') //eslint-disable-line
      .attr('transform', 'translate(150, 20)')
      .attr('id', `yaxis-${this.props.id}`)
      .call(yAxis);

    y_xis.selectAll('text')
      .attr('x', -14)
      .style('font-size', '16px');

    const x_xis = canvas.append('g') //eslint-disable-line
      .attr('transform', `translate(150, ${CANVAS_HEIGHT - 50})`)
      .attr('id', `xaxis--${this.props.id}`)
      .call(xAxis);

    x_xis.selectAll('text')
      .style('font-size', '16px')
      .attr('y', 15);

    if (this.props.rotateXAxis) {
      x_xis.selectAll('.tick')
        .attr('transform', d => (
          `translate(${xscale(d) + 15}, 0)`
        ));
      x_xis.selectAll('line')
        .attr('y2', 10);
      x_xis.selectAll('text')
        .attr('y', 15)
        .attr('dy', -5)
        .attr('x', 10)
        .attr('transform', 'rotate(45)')
        .style('text-anchor', 'start');
    }

    x_xis.selectAll('line')
      .attr('y2', -4);

    canvas.append('g')
      .attr('transform', `translate(${150}, 0)`)
      .attr('id', `bars-${this.props.id}`)
      .selectAll('rect')
      .data(values)
      .enter()
      .append('rect')
      .attr('height', BAR_HEIGHT)
      .attr({ x: 0, y: (d, i) => (yscale(i) + (BAR_HEIGHT / 2)) })
      .style('fill', (d, i) => (orderIndexesOfHighlighted.indexOf(i) > -1 ? colorHighlighted : color))
      .attr('width', () => 0)
      .on('mouseover', mouseOverBar)
      .on('mousemove', mouseMoveArc)
      .on('mouseout', mouseOutBar)
      .on('click', clickOnBar);

    let maxYTextLength = 0;
    canvas.selectAll(`#yaxis-${this.props.id} .tick text`)[0].forEach((item) => {
      if (item.getComputedTextLength() > maxYTextLength) {
        maxYTextLength = Math.round(item.getComputedTextLength()) + 100;
      }
    });
    canvas.select(`#yaxis-${this.props.id}`).attr('transform', `translate(${maxYTextLength + 60}, 20)`);
    canvas.select(`#bars-${this.props.id}`).attr('transform', `translate(${maxYTextLength + 60}, 0)`);
    canvas.select(`#grid-${this.props.id}`).attr('transform', `translate(${maxYTextLength + 60}, 20)`);
    canvas.select(`#xaxis--${this.props.id}`).attr('transform', `translate(${maxYTextLength + 60}, ${CANVAS_HEIGHT - 50})`);

    canvas.attr('width', `${CANVAS_WIDTH + maxYTextLength}`);

    const styles = '.grid-vertical-line {display: none;}.tick text {font-size: 12px;cursor: pointer;}';
    canvas.append('style').text(styles);

    window.d3.select(`#${this.props.id} svg`).selectAll('rect')
      .data(values)
      .attr('width', function(d) { return xscale(d); }); //eslint-disable-line

    canvas.selectAll('.tick')
      .on('mouseover', mouseOverSideTitle)
      .on('mouseout', mouseMoveSideTitle);
  };

  getNameForDownload = (format) => {
    const {
      chapterName = '', sourceName = '', geneDiseaseName = '',
    } = this.props;

    let chartName = `${chapterName}_${sourceName}`;
    if (geneDiseaseName) chartName = `${chartName}_${geneDiseaseName}`;
    return `${chartName}.${format}`;
  };

  exportToPNGCb = () => {
    const { id } = this.props;
    const diagram = window.document.querySelector(`#${id} svg`);
    const name = this.getNameForDownload('png');
    if (diagram) {
      exportToPNG(diagram, name, true);
    }
  };

  exportToSVGCb = () => {
    const { id } = this.props;
    const container = window.document.querySelector(`#${id}`);
    const name = this.getNameForDownload('svg');
    if (container) {
      exportToSVG(container, name, true);
    }
  };

  render() {
    const { id } = this.props;
    const uniqueKey = `gene-disease-bar-chart-tooltip_${id}`;

    return (
      <>
        <div className="export-buttons-section-gene-disease" >
          <span className="circos-export-button-wrapper">
            <div
              className="export-button"
              onClick={this.exportToPNGCb}
              title="Export to png"
            />
              PNG
          </span>
          <span className="circos-export-button-wrapper">
            <div
              className="export-button"
              onClick={this.exportToSVGCb}
              title="Export to svg"
            />
              SVG
          </span>
        </div>
        <div id={id} />
        <Tooltip uniqueKeyProp={uniqueKey}>
          <ShortConceptCard />
        </Tooltip>
      </>
    );
  }
}

GeneDiseaseBarChart.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(GeneDiseaseBarChart);
