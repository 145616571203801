import { call, put, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import { setSearchConceptsAction } from '../../../Search/store/actions';
import * as a from './reducer';

function* getConceptCardsDataWorker({ payload }) {
  try {
    yield put(a.conceptCardsLoadingAction(true));
    const { data } = yield call(Api.conceptCards, payload);
    yield put(a.setConceptCardsDataAction(data));
    yield put(setSearchConceptsAction(data));
    yield put(a.conceptCardsLoadingAction(false));
  } catch (e) {
    yield put(a.conceptCardsErrorAction(e.message));
    yield put(a.conceptCardsLoadingAction(false));
  }
}

function* conceptCardsSaga() {
  yield takeLatest(a.getConceptCardsDataAction, getConceptCardsDataWorker);
}

export default conceptCardsSaga;
