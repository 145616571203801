import { createSelector } from 'reselect';

// Constants
import { SEARCH_FILTER_FORM } from '../Components/SearchFilter/constants';
// Store
import { getUserProfileOptions, getAccessMappingsSelector } from '../../Header/selectors';

const searchFilterValues = state => state.getIn(['form', SEARCH_FILTER_FORM, 'values']);
const publications = state => state.getIn(['searchResult', 'result', 'publications', 'content']);
const totalPages = state => state.getIn(['searchResult', 'result', 'publications', 'totalPages']);
const totalElements = state => state.getIn(['searchResult', 'result', 'publications', 'totalElements']);
const pageNumber = state => state.getIn(['searchResult', 'result', 'publications', 'number']);
const searchLoading = state => state.getIn(['searchResult', 'loading']);
const error = state => state.getIn(['searchResult', 'error']);
const recommendations = state => state.getIn(['searchResult', 'result', 'recommendations']);
const synonyms = state => state.getIn(['searchResult', 'result', 'synonyms']);
const savedSearchTerm = state => state.getIn(['searchResult', 'searchTerm']);
const exactSearchValue = state => state.getIn(['searchResult', 'exactSearchTerm']);
const relatedConcepts = state => state.getIn(['searchResult', 'categoriesSearch']);
const cachedConceptIdsForCategorySearch = state => state.getIn(['searchResult', 'cachedConceptIdsForCategorySearch']);
const categorySearchDto = state => state.getIn(['searchResult', 'categorySearchDto']);
const pubSources = state => state.getIn(['searchResult', 'result', 'pubSources']);
const searchTypes = state => state.getIn(['searchResult', 'result', 'searchTypes']);
const searchFilterInitialValues = state => state.getIn(['searchResult', 'searchFilterInitialValues']);
const searchSevedLink = state => state.getIn(['searchResult', 'savedLink']);
const selectedPublicationsIds = state => state.getIn(['searchResult', 'selectedPublicationsIds']);
const selectedPubmedIds = state => state.getIn(['searchResult', 'selectedPubmedIds']);
const reloadingIsDisabled = state => state.getIn(['searchResult', 'reloadingIsDisabled']);
const searchConcepts = state => state.getIn(['searchResult', 'searchConcepts']);
const publicationFilterExtended = state => state.getIn(['searchResult', 'publicationFilterExtended']);

export const getCategorySearchDto = createSelector(
  categorySearchDto,
  data => data.toJS()
);

export const getCachedConceptIdsForCategorySearch = createSelector(
  cachedConceptIdsForCategorySearch,
  data => data.toJS()
);

export const getExactSearchValue = createSelector(
  exactSearchValue,
  data => data
);

export const getPublications = createSelector(
  publications,
  selectedPublicationsIds,
  (_data, _selectedIds) => {
    const data = _data && _data.toJS();
    const selectedIds = _selectedIds && _selectedIds.toJS();
    const newData = data.map((item) => {
      if (selectedIds.includes(item.id)) {
        return {
          ...item,
          checked: true,
        };
      }
      return item;
    });
    return newData;
  }
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getTotalItems = createSelector(
  totalElements,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getSearchLoading = createSelector(
  searchLoading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getRecommendations = createSelector(
  recommendations,
  data => (
    data ? data.toJS() : {}
  )
);

export const getSavedSearchTerm = createSelector(
  savedSearchTerm,
  data => data
);

export const getSynonyms = createSelector(
  synonyms,
  data => (data ? data.toJS() : [])
);

export const getRelatedConcepts = createSelector(
  relatedConcepts,
  data => data.toJS()
);

export const getPubmedUrlPrefix = createSelector(
  getUserProfileOptions,
  (data) => {
    const pubmedOption = data.find(profileOption => profileOption.get('option') === 'PUBMED_URL');
    return pubmedOption ? pubmedOption.get('optionData') : null;
  }
);

export const getSearchFilterValuesSelector = createSelector(
  searchFilterValues,
  data => data && data.toJS()
);

export const getPubSources = createSelector(
  pubSources,
  getAccessMappingsSelector,
  (_data, accessMappings) => {
    const data = _data && _data.toJS();
    if (data && data.length > 0 && accessMappings) {
      return data.map(d => ({
        ...d,
        name: accessMappings[d.sourceId],
      })).sort((a, b) => b.count - a.count);
    }
    return [];
  }
);

export const getSearchTypesSelector = createSelector(
  searchTypes,
  data => data && data.toJS()
);

export const getSearchFilterInitialValuesSelector = createSelector(
  searchFilterInitialValues,
  data => data && data.toJS()
);

export const getSearchSevedLinkSelector = createSelector(
  searchSevedLink,
  data => data && data.toJS()
);

export const getSelectedPublicationsIdsSelector = createSelector(
  selectedPublicationsIds,
  data => data && data.toJS()
);

export const getSelectedPubmedIdsSelector = createSelector(
  selectedPubmedIds,
  data => data && data.toJS()
);

export const getReloadingIsDisabledSelector = createSelector(
  reloadingIsDisabled,
  data => data
);

export const getSearchConceptsSelector = createSelector(
  searchConcepts,
  data => data && data.toJS()
);

export const getSearchConceptsIsPresentSelector = createSelector(
  searchConcepts,
  data => !!data.size
);

export const getPublicationFilterExtendedSelector = createSelector(
  publicationFilterExtended,
  data => data
);
