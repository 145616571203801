import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Error from '../../../../common/Error/Error';
import AddTag from '../../../../common/AddTag/AddTag';
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';
import AddTagContent from '../../../../common/AddTag/AddTagContent';
// Utils
import { formatDate } from '../../../../Utils/Utils';
import { DATE_FULL_FORMAT } from '../../../../../constantsCommon';
// Store
import { getSetsManagementDataSelector } from '../../../SetsManagement/selectors';
import { getProjectsOptionsSelector } from '../../../../Projects/ProjectsTabs/store/selectors';
import { isFreeUserSelector } from '../../../../Header/selectors';
import {
  getCreateSetTitleSelector,
  getCreateSetNameSelector,
  getCreateSetTagsSelector,
  getCreateSetDescriptionSelector,
  getLoadedSetInfoSelector,
} from '../../store/selectors';
import {
  updateCreateSetTagsAction,
  updateCreateSetNameAction,
  updateCreateSetDescriptionAction,
  selectCreateSetProjectAction,
} from '../../store/actions';

const propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.instanceOf(Array),
  updateCreateSetTags: PropTypes.func,
  updateCreateSetName: PropTypes.func,
  updateCreateSetDescription: PropTypes.func,
  errorOnSave: PropTypes.string,
  storedSetTitle: PropTypes.string,
  sets: PropTypes.instanceOf(Array),
  selectProject: PropTypes.func,
  categoryName: PropTypes.string,
  isFreeUser: PropTypes.bool,
  projectsOptions: PropTypes.instanceOf(Array),
  initialFilters: PropTypes.instanceOf(Object),
  loadedSetInfo: PropTypes.instanceOf(Object),
};

const CreateSetInfo = (props) => {
  const {
    name,
    tags,
    isFreeUser,
    description,
    storedSetTitle,
    categoryName,
    errorOnSave,
    selectProject,
    projectsOptions,
    updateCreateSetTags,
    updateCreateSetName,
    initialFilters,
    updateCreateSetDescription,
    loadedSetInfo,
  } = props;

  useEffect(() => {
    if (loadedSetInfo) return;

    let setDescription = '';

    updateCreateSetName(`${storedSetTitle} - ${formatDate(null, DATE_FULL_FORMAT)}`);

    if (categoryName === 'Genes') {
      const text = initialFilters &&
        initialFilters.PUBLICATION_FILTERS &&
        initialFilters.PUBLICATION_FILTERS.publicationSource ?
        initialFilters.PUBLICATION_FILTERS.publicationSource :
        description;
      setDescription = `${text}\nTaxonomy: Human.`;
    } else {
      setDescription = `${description}\n`;
    }
    updateCreateSetDescription(`${storedSetTitle}.\n${setDescription}`);
  }, [storedSetTitle]);

  return (
    <div className="create-set-info">
      <div className="title-border">
        <span>Save Set Result</span>
      </div>
      <div className="create-set-info__controls">
        <div className="create-set-info__name">
          <div className="create-set-info__label">Set name</div>
          <input
            type="text"
            defaultValue={name}
            placeholder="Name"
            className="create-set-input"
            onChange={(e) => { updateCreateSetName(e.target.value); }}
          />
        </div>
        {
          !isFreeUser &&
          <div className="create-set-info__select">
            <div className="create-set-info__label">Project</div>
            <SelectInput
              grayMode={true}
              options={projectsOptions}
              onSelect={selectProject}
              placeholder="Select project"
              value={loadedSetInfo && loadedSetInfo.projectId ? projectsOptions.find(p => p.id === loadedSetInfo.projectId) : null}
              defaultValue={loadedSetInfo && loadedSetInfo.projectId ? projectsOptions.find(p => p.id === loadedSetInfo.projectId) : null}
            />
          </div>
        }
        <div className="create-set-info__select">
          <div className="create-set-info__label">Add tag</div>
          <AddTag
            tagList={tags}
            updateTags={updateCreateSetTags}
          />
        </div>
        {
          tags && tags.length > 0 &&
          <div className="create-set-info__tags">
            <div className="create-set-info__label">Tags</div>
            <div className="create-set-info__tags-wrap">
              <AddTagContent
                tagList={tags}
                updateTags={updateCreateSetTags}
              />
            </div>
          </div>
        }
        <div className="create-set-info__description">
          <div className="create-set-info__label">Set description</div>
          <textarea
            type="text"
            value={description}
            placeholder="Description"
            className="create-set-textarea"
            onChange={(e) => { updateCreateSetDescription(e.target.value); }}
          />
        </div>
      </div>
      <Error
        show={errorOnSave}
        title="Sorry, error occurred"
        error=""
      />
    </div>
  );
};

CreateSetInfo.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sets: getSetsManagementDataSelector(state),
    name: getCreateSetNameSelector(state),
    description: getCreateSetDescriptionSelector(state),
    tags: getCreateSetTagsSelector(state),
    projectsOptions: getProjectsOptionsSelector(state),
    isFreeUser: isFreeUserSelector(state),
    loadedSetInfo: getLoadedSetInfoSelector(state),
    storedSetTitle: getCreateSetTitleSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCreateSetTags(data) {
      dispatch(updateCreateSetTagsAction(data));
    },
    updateCreateSetName(data) {
      dispatch(updateCreateSetNameAction(data));
    },
    updateCreateSetDescription(data) {
      dispatch(updateCreateSetDescriptionAction(data));
    },
    selectProject(data) {
      dispatch(selectCreateSetProjectAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSetInfo);
