import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';
import { setTypesEnum } from '../constants';
// Utils
import { getTotalPages } from '../../../common/Pagination/utils';

const initialState = fromJS({
  concepts: null,
  tags: [],
  setType: setTypesEnum.SIMPLE,
  sorting: {
    sortBy: 'geneName',
    sortDirection: 'ASC',
  },
  pages: {
    totalPages: 0,
    pageNumber: 0,
  },
  complexSetContent: null,
  complexSetDataToSend: null,
  showComplexSetDialog: false,
  loading: false,
});

const reducer = handleActions(
  {
    [a.getSaveAsSetConceptsAction]: state =>
      state
        .update('loading', () => true),
    [a.updateSaveAsSetConceptsAction]: (state, { payload }) =>
      state
        .set('concepts', fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null)
        .updateIn(['pages', 'pageNumber'], () => 0)
        .updateIn(['pages', 'totalPages'], () => payload ? getTotalPages(payload) : 0),
    [a.updateSaveAsSetTagsAction]: (state, { payload }) =>
      state
        .update('tags', () => fromJS(payload)),
    [a.updateSaveAsSetTypeAction]: (state, { payload }) =>
      state
        .update('setType', () => payload),
    [a.putSaveAsSetComplexDataAction]: (state, { payload }) =>
      state
        .update('setType', () => setTypesEnum.COMPLEX)
        .set('complexSetContent', fromJS(payload)),
    [a.setSaveAsSetComplexDataAction]: (state, { payload }) =>
      state
        .update('complexSetDataToSend', () => fromJS(payload)),
    [a.sortSaveAsSetConceptsAction]: (state, { payload }) =>
      state
        .update('sorting', () => fromJS(payload))
        .updateIn(['pages', 'pageNumber'], () => 0),
    [a.pagginateSaveAsSetConceptsAction]: (state, { payload }) =>
      state
        .updateIn(['pages', 'pageNumber'], () => payload),
    [a.toggleSaveAsSetComplexDialogAction]: (state, { payload }) =>
      state
        .update('showComplexSetDialog', () => payload),
    [a.resetSaveAsSetDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
