import { call, put, takeLatest } from 'redux-saga/effects';

import * as a from './reducer';
import Api from '../../../../Api/Api';

function* getConceptToConceptIndirectWorker({ payload }) {
  try {
    const {concept1, concept2} = payload;
    yield put(a.toggleConceptToConceptLoaderAction(true));
    const response = yield call(Api.conceptToConceptIndirectConcepts, { concept1, concept2 });
    yield put(a.putConceptToConceptDataAction(response.data));
  } catch (e) {
    yield put(a.throwConceptToConceptErrorAction(e.message));
  }
}

function* conceptToConceptIndirectSaga() {
  yield takeLatest(a.getConceptToConceptIndirectAction, getConceptToConceptIndirectWorker);
}

export default conceptToConceptIndirectSaga;
