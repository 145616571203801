import {
  taxonomyOptions,
  geneSetFiltersNames,
  geneSetFiltersLabels,
  geneSetFilterUrlPrefixes,
  publicationsFiltersLabels,
} from './enums';
import { SearchSourceFilterEnum } from '../../Search/enums';

function generateAvailableFiltersStructure(filters) {
  return filters.reduce((f, name) => {
    const availableFilters = { ...f };
    availableFilters[name] = {
      name,
      options: [],
      isLoading: false,
      prefix: geneSetFilterUrlPrefixes[name],
      filterName: name,
    };
    return availableFilters;
  }, {});
}

function checkFilterObject(obj) {
  if (typeof obj !== 'object') return true;
  const values = Object.values(obj);
  for (let index = 0; index < values.length; index++) { // eslint-disable-line
    if (!values[index]) {
      return false;
    } else if (Array.isArray(values[index]) && !values[index].length) {
      return false;
    }
  }
  return true;
}

function getStringValue(pubsFilters, filterName) {
  if (typeof pubsFilters[filterName] === 'string') {
    return pubsFilters[filterName];
  }
  return pubsFilters[filterName].join('; ');
}

function prepareFiltersValuesForRequest(filterData, availableFilters, withDescription = false, startDescription, startConceptsIds, skipZeroPublications = true) {
  const values = {
    filter: {
      publicationSource: 'ALL',
      skipZeroPublications,
    },
    filters: [],
    newDescriptionByFilters: `${startDescription}.\n`,
  };

  Object.keys(filterData).forEach((item) => {
    switch (item) {
      case geneSetFiltersNames.GENE_TAXONOMY_FILTER: {
        if (!availableFilters[item]) break;
        const geneTaxonomyFilter = filterData[item].taxonomy || taxonomyOptions[0];
        const { type, label } = geneTaxonomyFilter;
        const data = { type: item };
        if (type !== 'all concepts') {
          data.taxonomy = type;
        }
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters = `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${label}.\n`;
        }
        break;
      }
      case geneSetFiltersNames.GENE_CLASSIFICATION_FILTER: {
        if (!availableFilters[item] || !checkFilterObject(filterData[item])) break;
        const { classificationId: { id, name } } = filterData[item];
        const data = {
          type: item,
          classificationId: id,
        };
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters = `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${name}.\n`;
        }
        break;
      }
      case geneSetFiltersNames.GENE_SUBCELLULAR_LOCATION_FILTER: {
        if (!availableFilters[item] || !checkFilterObject(filterData[item])) break;
        const { ids } = filterData[item];
        let selectedNames = '';
        const data = {
          type: item,
          ids: ids.map((selected, i) => {
            selectedNames = `${selectedNames}${selected.name}${i === ids.length - 1 ? '. ' : '; '}`;
            return selected.id;
          }),
        };
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters = `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${selectedNames}\n`;
        }
        break;
      }
      case geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER: {
        if (!availableFilters[item] || !checkFilterObject(filterData[item])) break;
        const {
          ids,
          lowerExpressionThreshold,
          higherExpressionThreshold,
        } = filterData[item];
        let selectedNames = '';
        const data = {
          type: item,
          expressions: ids.map((selected, i) => {
            selectedNames = `${selectedNames}${selected.name}${i === ids.length - 1 ? '' : '; '}`;
            return { id: selected.id, source: selected.type };
          }),
          lowerExpressionThreshold,
          higherExpressionThreshold,
        };
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters =
            `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${selectedNames} (${lowerExpressionThreshold} - ${higherExpressionThreshold}).\n`;
        }
        break;
      }
      case geneSetFiltersNames.GENE_CELL_TYPE_EXPRESSION_FILTER: {
        if (!availableFilters[item] || !checkFilterObject(filterData[item])) break;
        const {
          ids,
          lowerExpressionThreshold,
          higherExpressionThreshold,
        } = filterData[item];
        let selectedNames = '';
        const data = {
          type: item,
          ids: ids.map((selected, i) => {
            selectedNames = `${selectedNames}${selected.name}${i === ids.length - 1 ? '' : '; '}`;
            return selected.id;
          }),
          lowerExpressionThreshold,
          higherExpressionThreshold,
        };
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters =
            `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${selectedNames} (${lowerExpressionThreshold} - ${higherExpressionThreshold}).\n`;
        }
        break;
      }
      case geneSetFiltersNames.GENE_CHEMICAL_ANTAGONISTS_FILTER:
      case geneSetFiltersNames.GENE_CHEMICAL_AGONIST_FILTER:
      case geneSetFiltersNames.SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER:
      case geneSetFiltersNames.SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER: {
        if (!availableFilters[item]) break;
        const { upperThreshold } = filterData[item];
        const data = {
          type: item,
          upperThreshold,
        };
        values.filters.push(data);
        if (withDescription) {
          values.newDescriptionByFilters = `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}: ${upperThreshold}.\n`;
        }
        break;
      }
      case 'PUBLICATION_FILTERS': {
        const pubsFilters = filterData[item];

        Object.keys(pubsFilters).forEach((filterName) => {
          if (pubsFilters[filterName]) {
            const stringName = publicationsFiltersLabels[filterName];

            switch (filterName) {
              case 'publicationSource':
                values.filter[filterName] = pubsFilters[filterName];
                if (withDescription) {
                  values.newDescriptionByFilters =
                    `${values.newDescriptionByFilters}${stringName}: ${getStringValue(pubsFilters, filterName)}.\n`;
                }
                break;
              case 'sources':
                if (
                  pubsFilters.publicationSource === SearchSourceFilterEnum.SENTENCE ||
                  pubsFilters.publicationSource === SearchSourceFilterEnum.TITLE
                ) {
                  break;
                }
                values.filter[filterName] = pubsFilters[filterName].map(d => d.value);
                if (withDescription) {
                  values.newDescriptionByFilters =
                    `${values.newDescriptionByFilters}${stringName}: ${pubsFilters[filterName].map(d => d.label).join('; ')}.\n`;
                }
                break;
              default:
                values.filter[filterName] = pubsFilters[filterName];
                if (withDescription) {
                  values.newDescriptionByFilters =
                    `${values.newDescriptionByFilters}${stringName}: ${getStringValue(pubsFilters, filterName)}.\n`;
                }
                break;
            }
          }
        });
        break;
      }
      case 'cooccurrence': {
        values.cooccurrenceType = filterData[item].value;
        break;
      }
      case 'category': {
        break;
      }
      case 'concept1': {
        values.filter.intermediateLimits = {
          [startConceptsIds[0]]: filterData[item],
        };
        break;
      }
      case 'concept2': {
        values.filter.intermediateLimits = {
          [startConceptsIds[1]]: filterData[item],
        };
        break;
      }
      case 'concept3': {
        values.filter.intermediateLimits = {
          [startConceptsIds[2]]: filterData[item],
        };
        break;
      }
      default: {
        if (!availableFilters[item] || !filterData[item]) break;
        const data = { type: item };
        if (withDescription) {
          values.newDescriptionByFilters = `${values.newDescriptionByFilters}${geneSetFiltersLabels[item]}.\n`;
        }
        values.filters.push(data);
        break;
      }
    }
  });

  return values;
}

function getSearchFilteredApiRequestPayload(conceptsLeft, conceptsRight, filtersData) {
  const {
    filters,
    filter,
    cooccurrenceType,
  } = filtersData;

  return {
    data: {
      conceptsLeft,
      ...(conceptsRight && { conceptsRight }),
      ...(filter && { filter }),
    },
    ...(cooccurrenceType && { cooccurrenceType }),
    ...(filters && { filters }),
  };
}

function getSourceDataRequestPayload(conceptsLeft, conceptsRight, type) {
  return {
    data: {
      conceptsLeft,
      conceptsRight,
    },
    type: type || 'ALL',
  };
}

function getSaveAsSetRequestPayload(data) {
  const {
    name,
    description,
    tags,
    items,
    filter,
    filters,
    projectId,
    leftConcepts,
  } = data;

  return {
    name,
    description,
    tags: tags.map(item => item.name),
    items,
    setType: 'SIMPLE',
    creatingData: {
      type: 'CATEGORY_SEARCH',
      ...(filter && { filter }),
      ...(filters && { filters }),
      leftConcepts,
    },
    ...(projectId && { projectId }),
  };
}


export {
  generateAvailableFiltersStructure,
  prepareFiltersValuesForRequest,
  getSearchFilteredApiRequestPayload,
  getSourceDataRequestPayload,
  getSaveAsSetRequestPayload,
};
