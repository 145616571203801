import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  icon: PropTypes.instanceOf(Object),
  size: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyles: PropTypes.instanceOf(Object),
  customClassName: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
};

const ButtonCircle = (props) => {
  const {
    icon,
    size = 26,
    disabled = false,
    onClick = () => { },
    customStyles = {},
    customClassName,
    testId,
    title,
  } = props;

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    ...customStyles,
  };

  if (!icon) {
    return null;
  }

  return (
    <button
      title={title}
      type="button"
      style={{ ...style }}
      className={classNames('circle-btn', customClassName)}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {icon}
    </button>
  );
};

ButtonCircle.propTypes = propTypes;

export default ButtonCircle;
