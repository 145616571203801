import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { geneDiseaseSetChapterAction, loadNextChapterAction } from '../GeneDiseasePage/actions';

import {
  GENE_DISEASE_PHENOTYPE_INIT_REQUESTED,
  GENE_DISEASE_PHENOTYPE_INIT_SUCCEEDED,
  GENE_DISEASE_PHENOTYPE_INIT_FAILED,
} from './constants';

function* saga(action) {
  try {
    const post = action.data;
    const response = yield call(Api.geneDiseasePhenotypeAnalysis, { post });
    yield put({ type: GENE_DISEASE_PHENOTYPE_INIT_SUCCEEDED, data: response.data });
    const {
      chords,
      data,
      fullSetABC,
      publications,
    } = response.data;

    if ((data && data.length)
      && ((chords && chords.length)
        || (fullSetABC && fullSetABC.length)
        || (publications && publications.length))
    ) {
      yield put(geneDiseaseSetChapterAction('phenotypes'));
    }
    yield put(loadNextChapterAction('pathways'));
  } catch (e) {
    yield put({ type: GENE_DISEASE_PHENOTYPE_INIT_FAILED, message: e.message });
    yield put(loadNextChapterAction('pathways'));
  }
}

function* rootSaga() {
  yield takeLatest(GENE_DISEASE_PHENOTYPE_INIT_REQUESTED, saga);
}

export default rootSaga;
