import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AutoSizer } from 'react-virtualized';

// Icons
import { BiEditAlt } from 'react-icons/bi';
// Components
import Table from '../../../../common/SimpleTable/Table';
import Input from '../../../../common/Inputs/Input/Input';
import ConceptSearchModal from '../../../../Concept/ConceptSearchModal/ConceptSearchModal';
import ShortConceptCardCell from '../../../../Concept/ShortConceptCard/ShortConceptCardCell';
import Pagination from '../../../../common/Pagination/Pagination';
import ButtonCircle from '../../../../common/Buttons/ButtonCircle/ButtonCircle';
// Store
import * as a from '../../store/actions';
import * as s from '../../store/selectors';
import { getCerebrumST } from '../../../../Header/selectors';
// Constants
import { EDIT_SET_FORM } from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  sorting: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
  pagginate: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  concepts: PropTypes.instanceOf(Array),
  isEffectSet: PropTypes.bool,
  updateConcepts: PropTypes.func,
  filterConcepts: PropTypes.func,
  sortConcepts: PropTypes.func,
  showConceptSearchModal: PropTypes.bool,
  setShowConceptSearchModal: PropTypes.func,
  setSearchConceptInputValue: PropTypes.func,
  semTypes: PropTypes.instanceOf(Object),
  disabled: PropTypes.instanceOf(Object),
};

const EditSetContent = (props) => {
  const {
    sorting,
    pagginate,
    data,
    concepts,
    isEffectSet,
    updateConcepts,
    filterConcepts,
    sortConcepts,
    showConceptSearchModal,
    setShowConceptSearchModal,
    setSearchConceptInputValue,
    semTypes,
    disabled,
  } = props;
  const { pagginatedConcepts, pages } = data;

  const [showEditRowModal, setShowEditRowModal] = useState({ data: null, show: false });

  const mergeConcepts = useCallback((values) => ({
    ...values.oldConcept,
    id: values.newConcept.id,
    name: values.newConcept.name,
    semtypes: values.newConcept.semanticType.map(id => ({
      id,
      name: semTypes[id],
    })),
  }), [semTypes]);

  const addConcept = useCallback((newConcept) => {
    const concept = mergeConcepts({ newConcept, oldConcept: {}});
    updateConcepts([...concepts, concept]);
  }, [concepts, updateConcepts, mergeConcepts]);

  const removeConcept = useCallback((id) => {
    updateConcepts(concepts.filter(c => c.id !== id));
  }, [concepts, updateConcepts]);

  const handleFilterConcepts = useCallback((key, value) => {
    filterConcepts({ key, value });
  }, [filterConcepts]);

  const handleEditRow = useCallback((newConcept) => {
    const concept = mergeConcepts({ newConcept, oldConcept: showEditRowModal.data });
    const filtered = concepts.filter(c => c.id !== showEditRowModal.data.id);
    filtered.push(concept);
    updateConcepts(filtered);
  }, [concepts, updateConcepts, showEditRowModal.data, mergeConcepts]);

  const columns = [
    {
      dataKey: 'sourceTerm',
      label: 'Source',
      disableSort: disabled.sourceFilter,
      headerRenderer: ({ label }) => (
        <div className="table-header__cell">
          <span className="table-header__text">{label}</span>
          <Input
            placeholder="Search source"
            onChange={e => handleFilterConcepts('sourceFilter', e.target.value)}
            customClassName="table-header__filter edit-set-filter__input"
            customWrapClassName="edit-set-filter__input-wrap"
            disabled={disabled.sourceFilter}
          />
        </div>
      ),
      width: isEffectSet ? 25 : 30,
    },
    {
      dataKey: 'name',
      label: 'Name',
      disableSort: disabled.nameFilter,
      headerRenderer: ({ label }) => (
        <div className="table-header__cell">
          <span className="table-header__text">{label}</span>
          <Input
            placeholder="Search concepts"
            onChange={e => handleFilterConcepts('nameFilter', e.target.value)}
            customClassName="table-header__filter edit-set-filter__input"
            customWrapClassName="edit-set-filter__input-wrap"
            disabled={disabled.nameFilter}
          />
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const { id, name } = rowData;
        return (
          <div className='table-cell-row table-cell-row_break-all'>
            <ShortConceptCardCell
              id={id}
              name={name}
              uniqueKey={`tooltip-${id}-${name}`}
            />
            <ButtonCircle
              size={30}
              icon={<BiEditAlt size={15} color="#4b3f63" />}
              onClick={() => {
                setSearchConceptInputValue(name);
                setShowEditRowModal({ data: rowData, show: true });
              }}
            />
          </div>
        );
      },
      width: isEffectSet ? 25 : 30,
    },
    ...(isEffectSet ? [{
      dataKey: 'measure',
      label: 'Measure',
      width: 15,
      className: 'table-wrap__cell_center',
    }] : []),
    {
      dataKey: 'semtypes',
      label: 'Type',
      sortValueMapper: item => (
        item.get('semtypes') && item.get('semtypes').size && item.get('semtypes').first() ?
          item.get('semtypes').first()
            .get('name') : ''
      ),
      cellRenderer: ({ rowData }) => (
        rowData.semtypes ? rowData.semtypes.map((type, i) => (
          <div key={`${type}_${i}`}>
            {type.name}
          </div>
        )) : null
      ),
      width: isEffectSet ? 25 : 30,
    },
    {
      dataKey: 'id',
      disableSort: true,
      cellRenderer: ({ rowData }) => (
        <button
          type="button"
          onClick={() => removeConcept(rowData.id)}
          className="btn-icon btn-icon-danger"
        >
          <i className="fa fa-trash" />
        </button>
      ),
      width: 10,
      className: 'table-wrap__cell_center',
    },
  ];

  const className = classNames({
    'edit-set-content': true,
    'container': true,
    'edit-set-content_simple': !isEffectSet,
  });

  return (
    <div className={className}>
      <Pagination
        {...pages}
        onPageChange={({ selected }) => pagginate(selected)}
      />
      <AutoSizer disableHeight={true}>
        {({ width }) => (
          <Table
            data={pagginatedConcepts}
            columnsData={columns}
            width={width}
            sorting={sorting}
            sortCallback={sortConcepts}
          />
        )}
      </AutoSizer>
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addConcept}
        onSubmitBtnText="Select concept"
        resetOnClose={true}
        formName={EDIT_SET_FORM}
      />
      <ConceptSearchModal
        isOpen={showEditRowModal.show}
        closeCb={() => setShowEditRowModal({ id: null, show: false })}
        onSubmit={handleEditRow}
        onSubmitBtnText="Select concept"
        resetOnClose={true}
      />
    </div>
  );
};

EditSetContent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: s.getEditSetFinalDataSelector(state),
    concepts: s.getEditSetConceptsSelector(state),
    sorting: s.getEditSetSortingSelector(state),
    isEffectSet: s.getEditSetTypeIsEffectSelector(state),
    semTypes: getCerebrumST(state),
    disabled: s.getEditSetDisabledFiltersSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateConcepts(data) {
      dispatch(a.updateEditSetConceptsAction(data));
    },
    sortConcepts(data) {
      dispatch(a.sortEditSetAction(data));
    },
    filterConcepts(data) {
      dispatch(a.filterEditSetAction(data));
    },
    pagginate(data) {
      dispatch(a.pagginateEditSetAction(data));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSetContent);
