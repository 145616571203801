import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import userflow from 'userflow.js';

// Icons
import { FaInfoCircle } from 'react-icons/fa';
// Utils
import Storage from '../../../../components/auth/Storage/Storage';
import { ENV } from '../../../../settings';
import { USERFLOW_DEV_ID, USERFLOW_PROD_ID, USERFLOW_CONTENT_ID } from '../../constants';
import { EnvironmentsEnum } from '../../../../constantsCommon';

const propTypes = {
  helpIsOpen: PropTypes.string,
  setHelpIsOpen: PropTypes.func,
  disableReloading: PropTypes.func,
  currentUser: PropTypes.instanceOf(Object),
};

const userflowIsActive = Storage.getValue('userflowIsActive') || false;

if (ENV === EnvironmentsEnum.DEV) {
  userflow.init(USERFLOW_DEV_ID);
} else if (ENV === EnvironmentsEnum.PROD) {
  userflow.init(USERFLOW_PROD_ID);
}

const HelpDropdown = (props) => {
  const { currentUser } = props;
  const [userflowIsOpen, setUserflowIsOpen] = useState(userflowIsActive);

  const userflowHandler = useCallback(() => {
    if (userflowIsOpen) {
      userflow.endAll();
    } else {
      userflow.start(USERFLOW_CONTENT_ID);
    }
    setUserflowIsOpen(!userflowIsOpen);
  }, [userflowIsOpen]);

  useEffect(() => {
    if (currentUser) {
      userflow.setUrlFilter((url) => {
        const regexp = new RegExp('[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}|\\d+', 'g');
        const parsed = new URL(url);
        parsed.search = '';
        parsed.pathname = parsed.pathname.replaceAll(regexp, 'ID');
        return parsed.toString();
      });
      userflow.identify(currentUser.id, {
        name: currentUser.firstName,
        email: currentUser.username,
        signed_up_at: currentUser.lastLogin ?
          new Date(currentUser.lastLogin).toISOString() :
          new Date().toISOString(),
      }, { show: false });
    }
  }, [currentUser]);

  useEffect(() => {
    Storage.setValue({
      key: 'userflowIsActive',
      value: userflowIsOpen,
    });
  }, [userflowIsOpen]);

  return (
    <div
      className="user-block"
      onClick={userflowHandler}
    >
      <FaInfoCircle
        size={16}
        color="#ffffff"
      />
    </div>
  );
};

HelpDropdown.propTypes = propTypes;

export default React.memo(HelpDropdown);
