import { createSelector } from 'reselect';

import { LOGIN_FORM } from './constants';

const form = state => state.getIn(['form', LOGIN_FORM]);

export const userForm = createSelector(
  form,
  (data) => {
    if (!data) return {};
    const username = data.getIn(['values', 'username']);
    const password = data.getIn(['values', 'password']);
    return {
      username,
      password,
    };
  }
);
