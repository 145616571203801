import { createSelector } from 'reselect';

import { convertToRGB } from '../../../Utils/Utils';

const sets = state => state.getIn(['setAnalysis', 'sets']);
const selectSetModalKey = state => state.getIn(['setAnalysis', 'selectSetShowModal']);
const reportTitle = state => state.getIn(['setAnalysis', 'title']);
const analyticsTags = state => state.getIn(['setAnalysis', 'tags']);
const createdDate = state => state.getIn(['setAnalysis', 'created']);
const id = state => state.getIn(['setAnalysis', 'id']);
const analyticsLoading = state => state.getIn(['setAnalysis', 'loading']);
const selectedIds = state => state.getIn(['setAnalysis', 'selectedIds']);
const comment = state => state.getIn(['setAnalysis', 'comment']);
const lastSavedAnalyticsId = state => state.getIn(['setAnalysis', 'lastSavedAnalyticsId']);
const shouldClearAllSetsFlag = state => state.getIn(['setAnalysis', 'shouldClearAllSetsFlag']);
const startSet = state => state.getIn(['setAnalysis', 'startSet']);
const heatmapInitialData = state => state.getIn(['setAnalysis', 'heatmapInitialData']);
const version = state => state.getIn(['setAnalysis', 'version']);
const shouldComponentBeUpdated = state => state.getIn(['setAnalysis', 'shouldComponentBeUpdated']);

export const getShouldClearAllSetsFlagValue = createSelector(
  shouldClearAllSetsFlag,
  flagValue => flagValue
);

export const getLastSavedAnalyticsId = createSelector(
  lastSavedAnalyticsId,
  reportId => reportId
);

export const getComment = createSelector(
  comment,
  data => data
);

export const getSelectedIds = createSelector(
  selectedIds,
  data => data.toJS()
);

export const getAnalyticsLoading = createSelector(
  analyticsLoading,
  data => data
);

export const getReportId = createSelector(
  id,
  data => data
);

export const getCreatedDate = createSelector(
  createdDate,
  data => data
);

export const getAnalyticsTags = createSelector(
  analyticsTags,
  data => data.toJS()
);

export const getReportTitle = createSelector(
  reportTitle,
  data => data
);

export const setAnalysisSets = createSelector(
  sets,
  data => data.toJS()
);

export const getActiveSets = createSelector(
  sets,
  data => data.filter(set => set.get('active')).toJS()
);

export const showModalSelector = createSelector(
  selectSetModalKey,
  data => data
);

export const startSetSelector = createSelector(
  startSet,
  data => data
);

export const getVennDiagramData = createSelector(
  sets,
  (_sets) => {
    const data = [];
    const colors = [];
    const setsJS = _sets.toJS();
    setsJS.forEach((set) => {
      if (set.active && set.data && set.data.length) {
        data.push(set);
        colors.push(convertToRGB(set.color));
      }
    });
    return {
      data,
      colors,
    };
  }
);

export const getHeatmapInitialDataSelector = createSelector(
  heatmapInitialData,
  data => data
);


const methodSelectionLoadingFlag = state => state.getIn(['setAnalysis', 'methodSelectionLoadingFlag']);

export const getMethodSelectionLoadingFlag = createSelector(
  methodSelectionLoadingFlag,
  data => data
);

export const getVersion = createSelector(
  version,
  data => data
);

export const getShouldComponentBeUpdatedSelector = createSelector(
  shouldComponentBeUpdated,
  data => data
);
