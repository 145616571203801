import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getVennSetsAction = createAction('GET_VENN_SETS_DATA_ACTION');
export const toggleVennSetsLoaderAction = createAction('TOGGLE_AVAILABLE_SETS_LOADER');
export const throwVennSetsErrorAction = createAction('THROW_AVAILABLE_SETS_ERROR');
export const clearVennDiagramAction = createAction('CLEAR_VENN_DIAGRAM_ACTION');

export const initialState = fromJS({
  loading: true,
  error: null,
});

const setsVennReducer = handleActions(
  {
    [toggleVennSetsLoaderAction]: (state, { payload }) =>
      state
        .update('loading', () => payload),
    [throwVennSetsErrorAction]: (state, { payload }) =>
      state
        .update('error', () => payload),
    [clearVennDiagramAction]: state =>
      state
        .update('loading', () => false)
        .update('error', () => null),
  },
  initialState
);

export default setsVennReducer;
