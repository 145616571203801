import { createSelector } from 'reselect';

const targetCandidatesSelectedAlgorithm = state => state.getIn(['workflowsReducer', 'selectedAlgorithm']);
const targetCandidatesLoading = state => state.getIn(['workflowsReducer', 'loading']);
const targetCandidatesError = state => state.getIn(['workflowsReducer', 'error']);
const targetCandidatesExportedData = state => state.getIn(['workflowsReducer', 'exportedData']);
const targetCandidatesExportedDataLoading = state => state.getIn(['workflowsReducer', 'exportedDataLoading']);

export const getTargetCandidatesSelectedAlgorithmSelector = createSelector(
  targetCandidatesSelectedAlgorithm,
  data => data
);

export const getTargetCandidatesLoadingSelector = createSelector(
  targetCandidatesLoading,
  data => data
);

export const getTargetCandidatesExportedDataSelector = createSelector(
  targetCandidatesExportedData,
  data => data
);

export const getTargetCandidatesExportedDataLoadingSelector = createSelector(
  targetCandidatesExportedDataLoading,
  data => data
);

export const getTargetCandidatesErrorSelector = createSelector(
  targetCandidatesError,
  data => data
);
