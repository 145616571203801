import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Spinner from '../../common/Spinner/Spinner';
// Utils
import { convertTableToCSVText, exportToFile } from '../../Utils/Utils';
// Styles
import './ExportTable.scss';

const propTypes = {
  showExportSelected: PropTypes.bool,
  showExportAll: PropTypes.bool,
  showExportPage: PropTypes.bool,
  selectedItems: PropTypes.instanceOf(Array),
  content: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Object),
  fileName: PropTypes.string,
  backendCall: PropTypes.func,
  exportLoading: PropTypes.bool,
};

const ExportTable = (props) => {
  const {
    columns,
    content,
    fileName,
    backendCall,
    selectedItems,
    exportLoading,
    showExportAll,
    showExportPage,
    showExportSelected,
  } = props;
  const downloadLink = useRef();

  function exportToCSVSelectedOnly() {
    exportToCSVParam(true);
  }

  function exportToCSV() {
    exportToCSVParam(false);
  }

  function exportToCSVParam(selectedOnly) {
    if (backendCall) {
      backendCall(downloadLink.current);
    } else {
      const dataForExport = selectedOnly == true && selectedItems && selectedItems.length ? selectedItems : content;
      const result = convertTableToCSVText(dataForExport, columns.filter(column => column.exportCSV));
      exportToFile(downloadLink.current, result, fileName || 'table-export');
    }
  }

  const buttonIsDisabled = (() => {
    if (backendCall) {
      return false;
    } else if (content && content.length) {
      return false;
    }
    return true;
  })();

  return (
    <div className="export-to-csv">
      <div className="btn-group">
        <label htmlFor="export">To CSV:</label>
        <Spinner isLoading={exportLoading} />
        {
          !exportLoading && showExportSelected &&
          <button
            disabled={!selectedItems || !selectedItems.length}
            onClick={exportToCSVSelectedOnly}
            className="btn btn-group-first"
          >
            Selected
          </button>
        }
        {
          !exportLoading && showExportPage &&
          <button
            className="btn btn-group-last"
            disabled={buttonIsDisabled}
            onClick={exportToCSV}
          >
            Page
          </button>
        }
        {
          !exportLoading && showExportAll &&
          <button
            className="btn btn-group-last"
            disabled={buttonIsDisabled}
            onClick={exportToCSV}
          >
            All
          </button>
        }
      </div>
      <a //eslint-disable-line
        hidden={true}
        ref={downloadLink}
      />
    </div>
  );
};

ExportTable.propTypes = propTypes;
ExportTable.defaultProps = {
  showExportSelected: false,
  showExportAll: true,
  showExportPage: false,
};

export default ExportTable;
