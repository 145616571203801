import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../../../Api/Api';

import * as a from './reducer';
import { createGraphData } from '../utils';

function* getPublicationsGraphWorker(action) {
  try {
    const { data } = yield call(Api.getPublicationsCountByYear, action.payload);
    const graphData = createGraphData(data);
    yield put(a.setPublicationsGraphDataAction(graphData));
  } catch (e) {
    yield put(a.throwPublicationsGraphErrorAction(e.message));
  }
}

function* publicationsFilterGraphSaga() {
  yield takeLatest(a.getPublicationsGraphDataAction, getPublicationsGraphWorker);
}

export default publicationsFilterGraphSaga;
