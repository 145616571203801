import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Components
import Loader from '../../common/Loader/Loader';
import ExistingDrugsTable from './ExistingDrugsTable';
import ExperimentalResultsTable from './ExperimentalResultsTable';
// Store
import { loadNextChapterAction } from '../actions';
import {
  getExistingDrugsData,
  getExperimentalResultsData,
  getChapterError,
  getChapterLoading,
} from './redux/selectors';
import {
  therapeuticCandidatesExperementalResultsChangePageAction,
  therapeuticCandidatesExperementalResultsSortAction,
  therapeuticCandidatesExistingDrugsChangePageAction,
  therapeuticCandidatesExistingDrugsSortAction,
  getTherapeuticCandidatesDataAction,
} from './redux/actions';
// Styles
import './TherapeuticCandidatesChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  existingDrugs: PropTypes.instanceOf(Object),
  experimentalResults: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loading: PropTypes.bool,
  getTherapeuticCandidatesData: PropTypes.func,
  existingDrugsChangePage: PropTypes.func,
  experementalResultsChangePage: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
};

class TherapeuticCandidatesChapter extends React.Component {
  state = {
    showInfoText: false,
  };

  componentDidMount() {
    const {
      gene,
      getTherapeuticCandidatesData,
      itsNewConcept,
      loadNextChapter,
      existingDrugs: {
        data,
      },
    } = this.props;

    if (itsNewConcept || isEmpty(data)) {
      getTherapeuticCandidatesData({ id: gene });
    } else {
      loadNextChapter('patents');
    }
  }

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      existingDrugs,
      experimentalResults,
      error,
      loading,
      existingDrugsChangePage,
      experementalResultsChangePage,
    } = this.props;
    const { showInfoText } = this.state;
    const parentClass = 'gene-details-section-body';

    return (
      <div className="therapeutic-candidates">
        <div className="gene-details-section-title title2">
          <span>Therapeutic Candidates</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              The Therapeutic Candidates section provides the following information on therapeutic agents  associated with the gene:
            </p>
            <ul>
              <li>Known approved drugs that target the gene</li>
              <li>The published compounds that are known to interact with the target including their affinity levels</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div>
            <ExistingDrugsTable
              gene={gene}
              geneName={geneName}
              tableData={existingDrugs}
              sortAction={therapeuticCandidatesExistingDrugsSortAction}
              changePageAction={existingDrugsChangePage}
              parentClass={parentClass}
            />
            <ExperimentalResultsTable
              geneName={geneName}
              tableData={experimentalResults}
              sortAction={therapeuticCandidatesExperementalResultsSortAction}
              changePageAction={experementalResultsChangePage}
              parentClass={parentClass}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

TherapeuticCandidatesChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    existingDrugs: getExistingDrugsData(state),
    experimentalResults: getExperimentalResultsData(state),
    error: getChapterError(state),
    loading: getChapterLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTherapeuticCandidatesData(data) {
      dispatch(getTherapeuticCandidatesDataAction(data));
    },
    existingDrugsChangePage(data) {
      dispatch(therapeuticCandidatesExistingDrugsChangePageAction(data));
    },
    experementalResultsChangePage(data) {
      dispatch(therapeuticCandidatesExperementalResultsChangePageAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TherapeuticCandidatesChapter);

