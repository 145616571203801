import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Input = createSliderWithTooltip(Slider);

const SliderInput = props => (
  <Input
    tipFormatter={value => value}
    tipProps={{ placement: 'top' }}
    {...props}
  />
);

export default React.memo(SliderInput);
