import { startSubmit, stopSubmit } from 'redux-form/immutable';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import Api from '../../../Api/Api';

import { restorePasswordForm } from './selectors';
import {
  USER_RESTORE_PASSWORD_REQUESTED,
  USER_RESTORE_PASSWORD_SUCCEEDED,
  USER_RESTORE_PASSWORD_FAILED,
  RESTORE_PASSWORD_FORM,
} from './constants';
import { restorePasswordModalClose } from './actions';

function* saga() {
  try {
    yield put(startSubmit(RESTORE_PASSWORD_FORM));
    const form = yield select(restorePasswordForm);
    const restore = yield call(Api.restorePassword, { email: form.email });
    yield put({ type: USER_RESTORE_PASSWORD_SUCCEEDED, data: { success: restore.data, error: null } });
    yield put(stopSubmit(RESTORE_PASSWORD_FORM));
    yield put(restorePasswordModalClose());
  } catch (e) {
    yield put({ type: USER_RESTORE_PASSWORD_FAILED, data: { success: false, error: e.response.data.message } });
    yield put(stopSubmit(RESTORE_PASSWORD_FORM));
  }
}

function* restorePassword() {
  yield takeEvery(USER_RESTORE_PASSWORD_REQUESTED, saga);
}

export default restorePassword;
