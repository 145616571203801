import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Loader from '../../../../common/Loader/Loader';
import NoData from '../../../../common/NoData/NoData';
import Error from '../../../../common/Error/Error';
import GroupedViolinChart from '../../../../graphics/GroupedViolinChart/GroupedViolinChart';
// Store
import {
  getTargetCandidatesPairsFirstGeneChartsAction,
  getTargetCandidatesPairsSecondGeneChartsAction,
} from '../store/reducer';
import * as SELECTORS from '../store/selectors';

const propTypes = {
  firstGeneId: PropTypes.string,
  firstGeneName: PropTypes.string,
  secondGeneId: PropTypes.string,
  secondGeneName: PropTypes.string,
  getFirstGeneChartsData: PropTypes.func,
  getSecondGeneChartsData: PropTypes.func,
  firstGeneLoading: PropTypes.bool,
  firstGeneError: PropTypes.string,
  secondGeneLoading: PropTypes.bool,
  secondGeneError: PropTypes.string,
  gtexData: PropTypes.instanceOf(Array),
  tcgaData: PropTypes.instanceOf(Array),
};

const TargetCandidatesPairsCharts = (props) => {
  const {
    gtexData,
    tcgaData,
    firstGeneId,
    secondGeneId,
    firstGeneName,
    firstGeneLoading,
    firstGeneError,
    secondGeneName,
    secondGeneLoading,
    secondGeneError,
    getFirstGeneChartsData,
    getSecondGeneChartsData,
  } = props;

  const loading = firstGeneLoading || secondGeneLoading;
  const [violinPlotTransformForGtex, setViolinPlotTransformForGtex] = useState('linear');
  const [violinPlotTransformForTcga, setViolinPlotTransformForTcga] = useState('linear');

  useEffect(() => {
    getFirstGeneChartsData({ geneId: firstGeneId, geneName: firstGeneName } );
    getSecondGeneChartsData({ geneId: secondGeneId, geneName: secondGeneName });
  }, []);

  return (
    <div className="target-candidates-charts">
      <div>
        <h3 className="workflows__title workflows__title_center title3">
          Expressions (TPM) from GTEx
        </h3>
        {
          !loading && gtexData && gtexData.length > 0 &&
            <GroupedViolinChart
              chartData={gtexData}
              chartTitle="Expressions (TPM) from GTEx"
              chartName="Expressions_(TPM)_from_GTEx"
              chartWrapId="targetCandidatesGtexChart"
              firstName={firstGeneName}
              secondName={secondGeneName}
              type="GTEX"
              isNeededTransform={true}
              isHiddenExport={false}
              violinPlotTransform={violinPlotTransformForGtex}
              setViolinPlotTransform={(e) => setViolinPlotTransformForGtex(e.target.value)}
            />
        }
        <Loader isLoading={loading} />
        <NoData show={!loading && !gtexData.length} />
        <Error show={!!firstGeneError} error={firstGeneError} />
      </div>

      <div>
        <h3 className="workflows__title workflows__title_center title3">
          Expressions (TPM) in tumor tissue from TCGA
        </h3>
        {
          !loading && tcgaData && tcgaData.length > 0 &&
            <GroupedViolinChart
              chartData={tcgaData}
              chartTitle="Expressions (TPM) in tumor tissue from TCGA"
              chartName="Expressions_(TPM)_in_tumor_tissue_from_TCGA"
              chartWrapId="targetCandidatesTcgaChart"
              firstName={firstGeneName}
              secondName={secondGeneName}
              type="TCGA"
              customVerticalLineX={32}
              isNeededTransform={true}
              isHiddenExport={false}
              violinPlotTransform={violinPlotTransformForTcga}
              setViolinPlotTransform={(e) => setViolinPlotTransformForTcga(e.target.value)}
            />
        }
        <Loader isLoading={loading} />
        <NoData show={!loading && !tcgaData.length} />
        <Error show={!!secondGeneError} error={secondGeneError} />
      </div>
    </div>
  );
};

TargetCandidatesPairsCharts.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    gtexData: SELECTORS.getTargetCandidatesForGtexChartDataSelector(state),
    tcgaData: SELECTORS.getTargetCandidatesForTcgaChartDataSelector(state),
    firstGeneLoading: SELECTORS.getTargetCandidatesFirstGeneLoadingSelector(state),
    firstGeneError: SELECTORS.getTargetCandidatesFirstGeneErrorSelector(state),
    secondGeneLoading: SELECTORS.getTargetCandidatesSecondGeneLoadingSelector(state),
    secondGeneError: SELECTORS.getTargetCandidatesSecondGeneErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFirstGeneChartsData(data) {
      dispatch(getTargetCandidatesPairsFirstGeneChartsAction(data));
    },
    getSecondGeneChartsData(data) {
      dispatch(getTargetCandidatesPairsSecondGeneChartsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCandidatesPairsCharts);
