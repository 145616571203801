import {
  GENE_DETAILS_REQUESTED,
  GENE_DETAILS_LOADING,
  GENE_DETAILS_RESET,
  GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
  GENE_DETAILS_LOAD_CHAPTER,
  GENE_DETAILS_UNLOAD_CHAPTERS,
  GENE_DETAILS_SET_CHAPTER_DATA,
} from './constants';

export const resetAction = () => (
  {
    type: GENE_DETAILS_RESET,
  }
);

export const geneDetailsRequest = data => (
  {
    type: GENE_DETAILS_REQUESTED,
    data,
  }
);

export const loading = () => (
  {
    type: GENE_DETAILS_LOADING,
  }
);

export const geneDetailsSetLastVisitedConceptIdAction = data => (
  {
    type: GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
    data,
  }
);

export const loadNextChapterAction = payload => (
  {
    type: GENE_DETAILS_LOAD_CHAPTER,
    payload,
  }
);

export const unloadChaptersAction = () => (
  {
    type: GENE_DETAILS_UNLOAD_CHAPTERS,
  }
);

export const geneDetailsSetChapterAction = data => (
  {
    type: GENE_DETAILS_SET_CHAPTER_DATA,
    data,
  }
);
