import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { defaultProjects } from '../../Projects/ProjectsTabs/constants';

export const getSetManagementDataAction = createAction('@@setManagement/GET_DATA');
export const putSetManagementDataAction = createAction('@@setManagement/PUT_DATA');
export const toggleSetManagementDataLoaderAction = createAction('@@setManagement/TOGGLE_DATA_LOADER');
export const throwSetManagementDataErrorAction = createAction('@@setManagement/THROW_DATA_ERROR');
export const filterSetManagementDataAction = createAction('@@setManagement/FILTER_DATA');
export const sortSetManagementDataAction = createAction('@@setManagement/SORT_DATA');
export const removeSetManagementDataItemAction = createAction('@@setManagement/REMOVE_DATA_ITEM');
export const selectSetManagementDataItemForAnalysisAction = createAction('@@setManagement/SELECT_DATA_ITEM_FOR_ANALYSIS');
export const resetSetManagementDataAction = createAction('@@setManagement/RESET_DATA');

export const checkAllSetsAction = createAction('@@setManagement/CHECK_ALL_SETS_SELECTION_TABLE');
export const checkSetAction = createAction('@@setManagement/CHECK_ITEM_SET_SELECTION_TABLE');
export const duplicateSetsAction = createAction('@@setManagement/DUPLICATE_SETS');

export const getSetForFilteringAction = createAction('@@setManagement/GET_SET_FOR_FILTERING');
export const putSetForFilteringAction = createAction('@@setManagement/PUT_SET_FOR_FILTERING');
export const resetSetForFilteringAction = createAction('@@setManagement/RESET_SET_FOR_FILTERING');

export const downloadSetDataAction = createAction('@@setManagement/DOWNLOAD_SET_DATA');

export const clearSetManagementActiveProjectIdAction = createAction('@@setManagement/CLEAR_ACTIVE_PROJECT_ID');
export const setSetManagementActiveProjectIdAction = createAction('@@setManagement/SET_ACTIVE_PROJECT_ID');

export const setManagementSearchRedirectAction = createAction('@@setManagement/SEARCH_REDIRECT');

const initialState = fromJS({
  data: null,
  checkedSets: [],
  loading: false,
  error: null,
  set: null,
  filters: {
    titleFilter: '',
    tagFilter: '',
  },
  sorting: {
    sortBy: 'lastOpened',
    sortDirection: 'DESC',
  },
  activeProjectId: defaultProjects[0].id,
});

const reducer = handleActions(
  {
    [putSetManagementDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('error', () => null)
        .update('loading', () => false),
    [putSetForFilteringAction]: (state, { payload }) =>
      state
        .update('set', () => fromJS(payload)),
    [resetSetForFilteringAction]: state =>
      state
        .update('set', () => null),
    [toggleSetManagementDataLoaderAction]: (state, { payload }) =>
      state
        .update('error', () => null)
        .update('loading', () => payload),
    [throwSetManagementDataErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [sortSetManagementDataAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [filterSetManagementDataAction]: (state, { payload }) =>
      state.updateIn(['filters', payload.filter], () => payload.value),
    [resetSetManagementDataAction]: (state) => 
      state
        .merge({
          ...initialState,
          activeProjectId: state.get('activeProjectId'),
        }),
    [checkAllSetsAction]: (state, { payload }) =>
      state
        .update('data', data => (
          data.map(item => (
            item.merge(fromJS({ selected: payload }))
          ))
        )),
    [checkSetAction]: (state, { payload }) =>
      state
        .update('data', data => (
          data.map(item => (
            item.get('id') === payload.id ?
              item.merge(fromJS({ selected: payload.checked })) :
              item
          ))
        )),
    [setSetManagementActiveProjectIdAction]: (state, { payload }) =>
      state.update('activeProjectId', () => payload),
    [clearSetManagementActiveProjectIdAction]: state =>
      state
        .update('activeProjectId', () => initialState.get('activeProjectId')),
  },
  initialState
);

export default reducer;
