import { fromJS } from 'immutable';

import { formatDate } from '../../../Utils/Utils';
import { DATE_FULL_FORMAT } from '../../../../constantsCommon';
import {
  ADD_SET_TO_SET_ANALYSIS,
  REMOVE_SET_FROM_SET_ANALYSIS,
  RESET_SETS_AT_SET_ANALYSIS,
  SET_COLOR_CHANGE,
  SET_NAME_CHANGE,
  SELECT_SET_FOR_SET_ANALYSIS,
  SET_ANALYTICS_TITLE,
  ANALYTICS_LOADING,
  ANALYTICS_INIT_SUCCEEDED,
  ANALYTICS_SET_COMMENT,
  SET_LAST_SAVE_ANALYTICS_ID,
  RESET_LAST_SAVE_ANALYTICS_ID,
  SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE,
  SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE,
  ANALYTICS_INIT_REQUESTED,
  SET_ANALYTICS_LOADING_FLAG,
  RESET_ANALYTICS_TITLE,
  SET_SET_ANALYSIS_ID,
  SET_ANALYSIS_UPDATE_TAGS,
  SWITCH_METHOD_SELECTION_LOADING_FLAG,
  ANALYTICS_SAVE_FAILED,
  ANALYTICS_SAVE_SUCCEEDED,
  INIT_SETS_TO_SET_ANALYSIS,
  PUT_UPLOADED_FILE_TO_SET,
} from './constants';
import { initialSets } from './utils';

const initialValues = fromJS({
  shouldClearAllSetsFlag: true,
  shouldComponentBeUpdated: false,
  lastSavedAnalyticsId: null,
  id: null,
  title: 'Title',
  tags: [],
  created: null,
  selectedIds: [],
  comment: '',
  version: null,
  sets: initialSets,
  loading: false,
});

const initialSet = fromJS({
  active: false,
  color: '',
  name: '',
  textareaValue: [],
  data: [],
  originalData: [],
  methodSelectionLoadingFlag: false,
});

const colors = ['#8B572A', '#5A9BD4', '#F15A60', '#CFCF1B', '#FF7500', '#006600'];

const setAnalysis = (state = initialValues, action) => {
  switch (action.type) {
    case SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_FALSE:
      return state.set('shouldClearAllSetsFlag', false);

    case SET_SHOULD_CLEAR_ALL_SETS_FLAG_TO_TRUE:
      return state
        .set('shouldClearAllSetsFlag', true);

    case RESET_LAST_SAVE_ANALYTICS_ID:
      return state
        .set('lastSavedAnalyticsId', null);

    case SET_LAST_SAVE_ANALYTICS_ID:
      return state
        .set('lastSavedAnalyticsId', action.data.reportId)
        .set('version', action.data.version);

    case SET_ANALYTICS_LOADING_FLAG:
      return state.set('loading', action.data);

    case ANALYTICS_INIT_REQUESTED:
      return state.set('loading', true);

    case ANALYTICS_INIT_SUCCEEDED: {
      const {
        id,
        title,
        created,
        tags,
        conceptSets,
        selectedIds,
        comment,
        startSet,
        version,
      } = action.data;

      let initialListOfSets = initialValues.get('sets');
      const tagsOptions = tags.map((item, i) => ({
        value: item,
        label: item,
        name: item,
        id: i,
      }));

      conceptSets.forEach((set, index) => {
        const setInited = initialSet.merge({
          color: set.color,
          name: set.name,
          active: true,
          type: set.setType || set.type,
          data: set.concepts.map(item => `${item.id}`),
          textareaValue: set.concepts.map(item => item.name),
          originalData: set.concepts,
        });
        initialListOfSets = initialListOfSets.set(index, setInited);
      });

      return state.merge(fromJS({
        id,
        title,
        comment,
        created,
        version,
        tags: tagsOptions,
        selectedIds,
        sets: initialListOfSets,
        startSet,
        loading: false,
        shouldComponentBeUpdated: true,
      }));
    }

    case SET_SET_ANALYSIS_ID:
      return state.update('id', () => action.data);

    case ANALYTICS_SET_COMMENT:
      return state.update('comment', () => action.data);

    case ANALYTICS_LOADING:
      return state.update('loading', () => action.data);

    case SET_ANALYSIS_UPDATE_TAGS:
      return state.update('tags', () => fromJS(action.data));

    case RESET_ANALYTICS_TITLE:
      return state.set('title', 'Title');

    case SET_ANALYTICS_TITLE:
      return state.update('title', () => action.data);

    case ADD_SET_TO_SET_ANALYSIS: {
      const setsLength = state.get('sets').size;
      return state.update('sets', sets => (
        sets.push(initialSet.set('color', colors[setsLength]).set('name', `Set  ${(setsLength + 1)}`))
      ));
    }

    case SET_COLOR_CHANGE:
      return state.update('sets', sets => (
        sets.update(action.data.index, set => set.set('color', action.data.color))
      ));

    case SET_NAME_CHANGE:
      return state.update('sets', sets => (
        sets.update(action.data.index, set => (
          set.set('name', action.data.value).set('active', true)
        ))
      ));

    case PUT_UPLOADED_FILE_TO_SET:
      return state.updateIn(['sets', action.data.id], (set) => {
        const initialData = {
          data: [],
          originalData: [],
          textareaValue: [],
          active: true,
        };
        const setData = action.data.items.reduce((acc, item) => {
          item.mappedGenes.forEach((g) => {
            acc.originalData.push({
              ...g,
              name: g.brainName,
              geneName: item.geneName,
              matchStatus: item.matchStatus,
              score: item.score,
            });
            acc.data.push(`${g.id}`);
            acc.textareaValue.push(g.brainName);
          });
          return acc;
        }, initialData);
        return set.merge(fromJS(setData));
      });

    case SELECT_SET_FOR_SET_ANALYSIS:
      return state.set('sets', fromJS(action.data));

    case RESET_SETS_AT_SET_ANALYSIS:
      return state.merge(initialValues).set('title', formatDate(null, DATE_FULL_FORMAT));

    case REMOVE_SET_FROM_SET_ANALYSIS:
      return state.update('sets', sets => (
        sets.set(action.data, initialValues.get('sets').get(action.data))
      ));

    case SWITCH_METHOD_SELECTION_LOADING_FLAG:
      return state.set('methodSelectionLoadingFlag', action.data);

    case INIT_SETS_TO_SET_ANALYSIS:
      return state.set('sets', fromJS(action.data));

    case ANALYTICS_SAVE_SUCCEEDED:
      return state
        .set('loading', false);

    case ANALYTICS_SAVE_FAILED:
      return state.set('loading', false);

    default:
      return state;
  }
};

export default setAnalysis;
