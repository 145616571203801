import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { geneDetailsSetChapterAction, loadNextChapterAction } from '../../actions';
import {
  getGeneDetailsOrthologsAction,
  setGeneDetailsOrthologsAction,
  toggleGeneDetailsOrthologsLoaderAction,
  throwGeneDetailsOrthologsErrorAction,
} from './reducer';

export function* getOrthologsWorker(action) {
  try {
    yield put(toggleGeneDetailsOrthologsLoaderAction(true));
    let { data } = yield call(Api.geneDetailsOrthologs, action.payload);
    data = data.map(item => ({ ...item, ...item.scores }));
    yield put(setGeneDetailsOrthologsAction(data));
    if (data && data.length) {
      yield put(geneDetailsSetChapterAction('orthologs'));
    }
    yield put(loadNextChapterAction('protein-location'));
    yield put(toggleGeneDetailsOrthologsLoaderAction(false));
  } catch (e) {
    yield put(throwGeneDetailsOrthologsErrorAction(e.message));
    yield put(loadNextChapterAction('protein-location'));
  }
}

function* orthologsSaga() {
  yield takeLatest(getGeneDetailsOrthologsAction, getOrthologsWorker);
}

export default orthologsSaga;
