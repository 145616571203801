import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

// Components
import Checkbox from '../../../../../common/Inputs/Checkbox/Checkbox';
import SingleTargetForCancerTissuesList from '../SingleTargetForCancerTissuesList';

const propTypesTissue = {
  tissue: PropTypes.instanceOf(Object),
  checkTissue: PropTypes.func,
  checkAllGroupTissues: PropTypes.func,
  index: PropTypes.number,
  type: PropTypes.string,
  changeSortDirection: PropTypes.func,
  columnId: PropTypes.string,
};

class SingleTargetForCancerGroup extends React.Component {
  sortTissues = (type) => {
    const { changeSortDirection, columnId } = this.props;
    changeSortDirection({ columnId, type });
  };

  render() {
    const {
      tissue: {
        id,
        subId,
        title,
        tissues,
        checkAll,
        sortDirection,
      },
      checkTissue,
      checkAllGroupTissues,
      index,
      type,
    } = this.props;

    const titleClass = classNames(
      'tissues-dnd-column__title',
      { 'sort': tissues.length },
      { 'sort_up': sortDirection === 'DESC' },
      { 'sort_down': sortDirection === 'ASC' },
    );

    return (
      tissues && tissues.length > 0 &&
      <Draggable
        draggableId={subId}
        index={index}
        key={subId}
      >
        {
          (provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={
                classNames(
                  'tissues-dnd-group-wrapper',
                  { 'tissues-dnd-tissue_active': snapshot.isDragging }
                )
              }
              {...provided.draggableProps}
            >
              {
                <div className="tissues-dnd-group">
                  <Checkbox
                    checked={checkAll}
                    readOnly={true}
                    onChange={() => { checkAllGroupTissues(id); }}
                    name={title}
                  />
                  <span
                    className={titleClass}
                    onClick={() => this.sortTissues(id)}
                  >
                    { title }
                  </span>
                  <div
                    className="tissues-dnd-tissue__handle"
                    {...provided.dragHandleProps}
                  >
                    .. .. ..
                  </div>
                </div>
              }
              <SingleTargetForCancerTissuesList
                tissues={tissues}
                type={type}
                parentType={id}
                checkTissue={checkTissue}
              />
            </div>
          )
        }
      </Draggable>
    );
  }
}

SingleTargetForCancerGroup.propTypes = propTypesTissue;

export default SingleTargetForCancerGroup;

