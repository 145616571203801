import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import AddTag from '../../../../common/AddTag/AddTag';
import AddTagContent from '../../../../common/AddTag/AddTagContent';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  project: PropTypes.instanceOf(Object),
  setProjectInfo: PropTypes.func,
};

const RelationMapProjectInfoModal = (props) => {
  const {
    isOpen,
    closeCb,
    project,
    project: { tags },
    setProjectInfo,
  } = props;

  const [projectInfo, setPropjectInfo] = useState(project);

  function updateName(e) {
    const { value } = e.target;
    setPropjectInfo({
      ...projectInfo,
      name: value,
    });
  }

  function updateTags(d) {
    setPropjectInfo({
      ...projectInfo,
      tags: d,
    });
  }

  function updateDescription(e) {
    const { value } = e.target;
    setPropjectInfo({
      ...projectInfo,
      description: value,
    });
  }

  function save() {
    setProjectInfo(projectInfo);
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="create-set-info relation-map-project-info">
        <div className="title2">Project info</div>
        <div className="create-set-info__controls">
          <div className="create-set-info__name">
            <div className="create-set-info__label">Relation map name</div>
            <input
              type="text"
              defaultValue={projectInfo.name}
              placeholder="Name"
              className="create-set-input"
              onChange={updateName}
            />
          </div>
          <div className="create-set-info__select">
            <div className="create-set-info__label">Add tag</div>
            <AddTag
              tagList={projectInfo.tags}
              updateTags={updateTags}
            />
          </div>
          {
            tags && tags.length > 0 &&
            <div className="create-set-info__tags">
              <div className="create-set-info__label">Tags</div>
              <div className="create-set-info__tags-wrap">
                <AddTagContent
                  tagList={projectInfo.tags}
                  updateTags={updateTags}
                />
              </div>
            </div>
          }
          <div className="create-set-info__description">
            <div className="create-set-info__label">Relation map description</div>
            <textarea
              type="text"
              value={projectInfo.description}
              placeholder="Description"
              className="create-set-textarea"
              onChange={updateDescription}
            />
          </div>
        </div>
        <div className="create-set-info__save">
          <button
            className="button button-primary"
            onClick={save}
          >
            Save
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

RelationMapProjectInfoModal.propTypes = propTypes;

export default RelationMapProjectInfoModal;
