import React from 'react';
import * as d3js from 'd3';
import PropTypes from 'prop-types';

// Components
import HeatmapColorScale from '../../../../graphics/Heatmap/Components/HeatmapColorScale/HeatmapColorScale';
import Tooltip from '../../../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../../../Concept/ShortConceptCard/ShortConceptCard';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  sort: PropTypes.func,
  openPublicationsPopup: PropTypes.func,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
};

class SingleTargetForCancerHeatMap extends React.Component {
  static width = 500;
  static svgWidth = 500;
  constructor(props) {
    super(props);
    this.divId = 'single-target-for-cancer-heatmap';
    this.negativeDistanceColor = '#2196f3';
    this.positiveDistanceColor = '#f44336';
    this.cellWidth = 20;
    this.state = {
      rangeNegative: { min: props.data.minScore, max: 0 },
      rangePositive: { min: 0, max: props.data.maxScore },
    };
    this.topScroll = React.createRef();
    this.bottomScroll = React.createRef();
  }

  componentDidMount = () => {
    this.drawHeatMap();
    this.drawLegend();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.data.minScore !== this.props.data.minScore ||
      prevProps.data.maxScore !== this.props.data.maxScore
    ) {
      this.setState({
        rangeNegative: { min: this.props.data.minScore, max: 0 },
        rangePositive: { min: 0, max: this.props.data.maxScore },
      });
    }
    this.drawHeatMap();
    this.drawLegend();
  };

  getColors = (d) => {
    const { rangeNegative, rangePositive } = this.state;
    const {
      data: {
        maxScore,
        minScore,
      },
    } = this.props;

    if (d > 0) {
      if (!rangePositive) {
        const val = d3js.scaleLinear()
          .range(['white', this.positiveDistanceColor])
          .domain([0, maxScore]);
        return val(d);
      }
      if (d < rangePositive.min) {
        return 'white';
      } else if (d > rangePositive.max) {
        return this.positiveDistanceColor;
      }
      const val = d3js.scaleLinear()
        .range(['white', this.positiveDistanceColor])
        .domain([rangePositive.min, rangePositive.max]);
      return val(d);
    }

    if (d < 0) {
      if (!rangeNegative) {
        const val = d3js.scaleLinear()
          .range([this.negativeDistanceColor, 'white'])
          .domain([minScore, 0]);
        return val(d);
      }
      if (d < rangeNegative.min) {
        return this.negativeDistanceColor;
      } else if (d > rangeNegative.max) {
        return 'white';
      }
      const val = d3js.scaleLinear()
        .range([this.negativeDistanceColor, 'white'])
        .domain([rangeNegative.min, rangeNegative.max]);
      return val(d);
    }

    return 'white';
  };

  showTooltipHandler = (d) => {
    const { setShortConceptCardId, showTooltip } = this.props;
    const { id } = d;
    const config = {
      uniqueKey: 'heatmap-tooltip',
      clientX: d3js.event.x,
      clientY: d3js.event.y,
    };
    setShortConceptCardId(id);
    showTooltip(config);
  };

  hideTooltipHandler = () => {
    const { hideTooltip } = this.props;
    hideTooltip();
  };

  drawHeatMap = () => {
    const {
      data: {
        rows,
        names,
        data,
      },
      sort,
      openPublicationsPopup,
      width,
    } = this.props;

    const columnsNames = names.reduce((acc, item) => {
      if (!acc.includes(item.name)) {
        acc.push(item.name);
      }
      return acc;
    }, []);

    SingleTargetForCancerHeatMap.width = columnsNames.length * this.cellWidth;

    const height = rows.length ? rows.length * 150 : 150;
    const margin = {
      top: rows.length > 5 ? 237 : 110,
      right: 0,
      bottom: 200,
      left: 0,
    };
    const legendCell = {
      height: 30,
    };
    const minTooltipWidth = 200;

    d3js.selectAll(`#${this.divId} > *`).remove();

    if (!data.length) {
      return;
    }

    const tooltipTemplate = (d) => {
      if (d.score) {
        const name = d.label ? `${d.name} [${d.label}]` : d.name;
        return (`
          <div class="chart-tooltip__content">
           <div><b>Gene:</b> ${d.gene.name}</div>
           <div><b>Tumor:</b> ${name}</div>
           <div><b>Distance score:</b> ${d.score}</div>
           <div><b>Expression level:</b> ${d.measure.toFixed(2)}</div>
          </div>
        `);
      }
      return (`
        <div class="chart-tooltip__content">
          <div>There is no data to display</div>
        </div>
      `);
    };

    const tooltip = d3js.select(`#${this.divId}`)
      .append('div')
      .style('opacity', 0)
      .style('min-width', `${minTooltipWidth}px`)
      .attr('class', 'chart-tooltip');

    function mouseOver() {
      tooltip
        .style('opacity', 1);
      d3js.select(this)
        .attr('stroke', 'dodgerblue');
    }

    function mouseMove(d) {
      let left = d3js.event.x - width;
      if (left + minTooltipWidth > width) {
        left -= minTooltipWidth;
      }
      let top = (Math.trunc(d3js.mouse(this)[1] / 150) * 150) + 70;
      if (rows.length > 5) {
        top += 70;
      }
      tooltip
        .html(tooltipTemplate(d))
        .style('left', `${left}px`)
        .style('top', `${top}px`);
    }

    function mouseLeave() {
      tooltip
        .style('opacity', 0);
      d3js.select(this)
        .attr('stroke', 'none');
    }

    function onClickHandler(d) {
      openPublicationsPopup(d.gene, { id: d.id, name: d.name });
    }

    function trimConceptText(d) {
      if (d.length < 40) return d;
      return `${d && d.slice(0, 40)}...`;
    }

    const svgWidth = SingleTargetForCancerHeatMap.width + margin.left + margin.right + 125;
    const svg = d3js.select(`#${this.divId}`)
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', height + margin.top + margin.bottom + legendCell.height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    SingleTargetForCancerHeatMap.svgWidth = svgWidth;

    const x = d3js.scaleBand()
      .range([0, SingleTargetForCancerHeatMap.width])
      .domain(names.map(item => item.name))
      .padding(0.01);
    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3js.axisBottom(x));
    xAxis.selectAll('text')
      .attr('x', 15)
      .attr('y', 0)
      .style('text-anchor', 'start')
      .style('cursor', 'pointer')
      .attr('transform', 'rotate(45)')
      .text(d => trimConceptText(d))
      .on('mouseover', d => this.showTooltipHandler(names.find(el => el.name === d)))
      .on('mouseout', this.hideTooltipHandler)
      .on('click', (d) => {
        const sortPath = `tumorsWithScore.${String(names.find(el => el.name === d).id)}.score`;
        sort(sortPath);
      });

    if (rows.length > 5) {
      const xAxisTop = svg.append('g')
        .attr('transform', 'translate(0,0)')
        .call(d3js.axisTop(x));
      xAxisTop.selectAll('text')
        .attr('x', 15)
        .attr('y', 0)
        .style('text-anchor', 'start')
        .style('cursor', 'pointer')
        .attr('transform', 'rotate(315)')
        .text(d => trimConceptText(d))
        .on('mouseover', d => this.showTooltipHandler(names.find(el => el.name === d)))
        .on('mouseout', this.hideTooltipHandler)
        .on('click', (d) => {
          const sortPath = `tumorsWithScore.${String(names.find(el => el.name === d).id)}.score`;
          sort(sortPath);
        });
    }

    const y = d3js.scaleBand()
      .range([height, 0])
      .domain(rows)
      .padding(0.01);

    svg.selectAll()
      .data(data, d => `${d.row}: ${d.label ? `${d.name} [${d.label}]` : d.name}`)
      .enter()
      .append('rect')
      .attr('x', (d) => {
        const name = d.label ? `${d.name} [${d.label}]` : d.name;
        return x(name);
      })
      .attr('y', d => y(d.row))
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('cursor', 'pointer')
      .style('fill', d => this.getColors(d.score))
      .on('click', onClickHandler)
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMove)
      .on('mouseleave', mouseLeave);
  };

  drawLegend = () => {
    const {
      width,
      data: {
        data,
      },
    } = this.props;

    const { rangePositive, rangeNegative } = this.state;
    const height = 30;
    const margin = {
      top: 0,
      right: 0,
      bottom: 150,
      left: 0,
    };
    const legendCell = {
      width: width / 8,
      height: 30,
    };

    d3js.selectAll('#heatmap-legend > *').remove();

    if (!data.length) {
      return;
    }

    const getLegendSteps = () => {
      const positiveSteps = [];
      const negativeSteps = [];
      const stepsLength = 4;

      const positiveStep = Math.round(rangePositive.max - rangePositive.min) / stepsLength;
      positiveSteps.push(rangePositive.min);
      positiveSteps.push(rangePositive.max - positiveStep - positiveStep);
      positiveSteps.push(rangePositive.max - positiveStep);
      positiveSteps.push(rangePositive.max);

      const negativeStep = Math.round(Math.abs(rangeNegative.min) - Math.abs(rangeNegative.max)) / stepsLength;
      negativeSteps.push(rangeNegative.min);
      negativeSteps.push(rangeNegative.min + negativeStep);
      negativeSteps.push(rangeNegative.min + negativeStep + negativeStep);
      negativeSteps.push(rangeNegative.max);

      return [...negativeSteps, ...positiveSteps];
    };

    const svg = d3js.select('#heatmap-legend')
      .append('svg')
      .attr('width', width / 8)
      .attr('height', height + legendCell.height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const legend = svg.selectAll('.legend')
      .data(getLegendSteps())
      .enter()
      .append('g')
      .attr('class', 'legend');

    legend.append('rect')
      .attr('x', (d, i) => legendCell.width * i)
      .attr('y', 0)
      .attr('width', legendCell.width)
      .attr('height', legendCell.height)
      .style('fill', d => this.getColors(d));

    legend.append('text')
      .attr('class', 'legend-text')
      .text(d => Math.round(d))
      .attr('width', legendCell.width)
      .style('font-size', '15px')
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => (legendCell.width * i) + (legendCell.width / 2))
      .attr('y', legendCell.height + 20);
  };

  setNegativeRange = (values) => {
    const min = values[0];
    const max = values[1];
    this.setState({ rangeNegative: { min, max } });
  };

  setPositiveRange = (values) => {
    const min = values[0];
    const max = values[1];
    this.setState({ rangePositive: { min, max } });
  };

  handleScroll = (event) => {
    if (event.target.classList.contains('heatmap__bottom-scroll')) {
      this.topScroll.current.scrollLeft = this.bottomScroll.current.scrollLeft;
    } else {
      this.bottomScroll.current.scrollLeft = this.topScroll.current.scrollLeft;
    }
  };

  render() {
    const {
      width,
      data: {
        rows,
        maxScore,
        minScore,
      },
    } = this.props;

    return (
      <div className="heatmap__wrap">
        <div
          className="heatmap__top-scroll"
          style={{ width }}
          ref={this.topScroll}
          onScroll={this.handleScroll}
        >
          <div style={{ width: SingleTargetForCancerHeatMap.svgWidth }} />
        </div>
        <div
          id={this.divId}
          className="heatmap heatmap__bottom-scroll"
          style={{ width, paddingTop: rows.length > 5 ? '0px' : '2px' }}
          onScroll={this.handleScroll}
          ref={this.bottomScroll}
        />
        <div id="heatmap-legend" />
        <div className="heatmap-range" style={{ width }}>
          <HeatmapColorScale
            defaultValue={[minScore, 0]}
            onAfterChange={this.setNegativeRange}
            colors={['#2196f3', '#ffffff']}
            dotColor="#2196f3"
            isNegative={true}
          />
          <HeatmapColorScale
            defaultValue={[0, maxScore]}
            onAfterChange={this.setPositiveRange}
            colors={['#ffffff', '#f44336']}
            dotColor="#f44336"
          />
        </div>
        <Tooltip uniqueKeyProp="heatmap-tooltip">
          <ShortConceptCard />
        </Tooltip>
      </div>
    );
  }
}

SingleTargetForCancerHeatMap.propTypes = propTypes;

export default SingleTargetForCancerHeatMap;
