import { createSelector } from 'reselect';

const values = state => state.getIn(['form', 'SAVE_PUBLICATIONS_TO_PROJECT', 'values']);
const projectsOptions = state => state.getIn(['selectProjectModal', 'options']);

export const selectProjectSelector = createSelector(
  values,
  data => data && data.toJS()
);

export const getProjectsOptionsSelector = createSelector(
  projectsOptions,
  data => data && data.toJS()
);
