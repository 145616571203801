import { createSelector } from 'reselect';

const loading = state => state.getIn(['barChart', 'loading']);
const error = state => state.getIn(['barChart', 'error']);
const chartData = state => state.getIn(['barChart', 'result']);
const gene = state => state.getIn(['barChart', 'gene']);
const open = state => state.getIn(['barChart', 'open']);

export const getBarChartLoadingSelector = createSelector(
  loading,
  data => data
);

export const getBarChartErrorSelector = createSelector(
  error,
  data => data
);

export const getBarChartDataSelector = createSelector(
  chartData,
  data => data && data.toJS()
);

export const getBarChartGeneSelector = createSelector(
  gene,
  data => data
);

export const getBarChartGeneIdSelector = createSelector(
  gene,
  data => data && data.geneGi
);

export const getBarChartOpenKeySelector = createSelector(
  open,
  data => data
);
