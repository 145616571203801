import { createSelector } from 'reselect';

const pageChapter = 'geneDetailsPatentsChapter';
// Chapter
const chapterLoading = state => state.getIn([pageChapter, 'loading']);
const chapterError = state => state.getIn([pageChapter, 'error']);
// Patents
const patents = state => state.getIn([pageChapter, 'patents']);
const patentsSorting = state => state.getIn([pageChapter, 'patentsSorting']);
// Patent
const patentInfo = state => state.getIn([pageChapter, 'patentInfo']);
const patentInfoError = state => state.getIn([pageChapter, 'patentInfoError']);
// Sequences
const sequences = state => state.getIn([pageChapter, 'sequences']);
const sequencesLoading = state => state.getIn([pageChapter, 'sequencesLoading']);
const sequencesError = state => state.getIn([pageChapter, 'sequencesError']);
// Chemicals
const chemicals = state => state.getIn([pageChapter, 'chemicals']);
const chemicalsLoading = state => state.getIn([pageChapter, 'chemicalsLoading']);
const chemicalsError = state => state.getIn([pageChapter, 'chemicalsError']);
// Popup
const patentInfoPopup = state => state.getIn([pageChapter, 'patentInfoPopup']);
const patentInfoPopupLoading = state => state.getIn([pageChapter, 'patentInfoPopupLoading']);
// Export
const exportLoading = state => state.getIn([pageChapter, 'exportLoading']);

export const getExportLoading = createSelector(
  exportLoading,
  loading => loading
);

export const getChapterLoading = createSelector(
  chapterLoading,
  loading => loading
);

export const getChapterError = createSelector(
  chapterError,
  error => error
);

export const getPatents = createSelector(
  patents,
  data => data.toJS()
);

export const getPatentsSorting = createSelector(
  patentsSorting,
  data => data.toJS()
);

export const getPatentInfo = createSelector(
  patentInfo,
  data => data.toJS()
);

export const getPatentInfoError = createSelector(
  patentInfoError,
  error => error
);

export const getSequences = createSelector(
  sequences,
  data => data.toJS()
);

export const getSequencesLoading = createSelector(
  sequencesLoading,
  loading => loading
);

export const getSequencesError = createSelector(
  sequencesError,
  error => error
);

export const getChemicals = createSelector(
  chemicals,
  data => data.toJS()
);

export const getChemicalsLoading = createSelector(
  chemicalsLoading,
  loading => loading
);

export const getChemicalsError = createSelector(
  chemicalsError,
  error => error
);

export const getPatentInfoPopup = createSelector(
  patentInfoPopup,
  popup => popup.toJS()
);

export const getPatentInfoPopupLoading = createSelector(
  patentInfoPopupLoading,
  loading => loading
);

