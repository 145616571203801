import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { RELATIVE_PATH } from '../../../../constantsCommon';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
  openInNewTab: PropTypes.bool,
};

const GeneDiseaseSection = (props) => {
  const { recommendations, openInNewTab } = props;

  return (
    <div className="search-page__recommendation_block">
      {
        recommendations.map((item, i) => (
          <div
            className="search-page__recommendation_link"
            key={i}
          >
            <Link
              target={openInNewTab && '_blank'}
              key={i}
              className="link"
              to={`${RELATIVE_PATH}/gene-disease/${item.geneId}/${item.diseaseId}`}
            >
              {item.recommendationPhrase}
            </Link>
          </div>
        ))
      }
    </div>
  );
};

GeneDiseaseSection.propTypes = propTypes;

export default React.memo(GeneDiseaseSection);
