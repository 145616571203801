import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { getSetManagementDataAction } from '../../reducer';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';

export function* removeSetManagementDataItemWorker({ payload }) {
  try {
    const { setIds, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    if (!isProjectTab) yield call(Api.deleteSets, setIds);
    else yield call(Api.deleteProjectSet, { projectId, setIds });
    yield put(getSetManagementDataAction({ noLoader: true, projectId }));
  } catch (e) {
    console.log(e.message);
  }
}
