import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QRCode from 'react-qr-code';

// Components
import SelectInput from '../../common/Inputs/SelectInput/SelectInput';
import Button from '../../common/Buttons/Button/Button';
import AuthenticatorCodeModal from './Components/AuthenticatorCodeModal';
// Constants
import {
  AuthenticatorCodeTypesForModal,
  TwoFactorAuthenticationEnums,
  TwoFactorAuthenticationOptions
} from './constants';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { changePasswordModalClose } from '../ChangePassword/actions';
import { appInitCurrentUserAction } from '../../Header/actions';
// Styles
import './styles.scss';


const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  getTwoFactorAuthenticationData: PropTypes.func,
  twoFactorAuthenticationCode: PropTypes.string,
  twoFactorAuthenticationUrl: PropTypes.string,
  twoFactorAuthenticationSelectedOption: PropTypes.instanceOf(Object),
  updateTwoFactorAuthenticationSelectedOption: PropTypes.func,
  toggleAuthenticatorCodeModalIsOpen: PropTypes.func,
  authenticatorCodeModalIsOpen: PropTypes.bool,
  changePasswordModalClose: PropTypes.func,
  clearTwoFactorAuthenticationData: PropTypes.func,
  twoFactorAuthenticationPrevSelectedOption: PropTypes.instanceOf(Object),
  appInitCurrentUser: PropTypes.func,
};

const TwoFactorAuthentication = (props) => {
  const {
    twoFactorAuthenticationCode,
    twoFactorAuthenticationUrl,
    getTwoFactorAuthenticationData,
    authenticatorCodeModalIsOpen,
    toggleAuthenticatorCodeModalIsOpen,
    twoFactorAuthenticationSelectedOption,
    updateTwoFactorAuthenticationSelectedOption,
    twoFactorAuthenticationPrevSelectedOption,
    clearTwoFactorAuthenticationData,
    appInitCurrentUser,
  } = props;

  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const renderInstruction = useMemo(() => {
    if (
      twoFactorAuthenticationPrevSelectedOption?.value === twoFactorAuthenticationSelectedOption?.value ||
      twoFactorAuthenticationPrevSelectedOption?.value === TwoFactorAuthenticationEnums.EVERY_LOGIN ||
      twoFactorAuthenticationPrevSelectedOption?.value === TwoFactorAuthenticationEnums.ONCE_DAY
    ) {
      return null;
    }

    switch (twoFactorAuthenticationSelectedOption?.value) {
      case TwoFactorAuthenticationEnums.EVERY_LOGIN:
      case TwoFactorAuthenticationEnums.ONCE_DAY:
        if (showHelpInfo) {
          return (
            <div>
              <div className="text authentication__text">
                Configure the app for authentication:
                <ol>
                  <li> In the Google Authenticator app, tap the + icon,<br/> then tap Enter a setup key</li>
                  <li>Enter your email address and this key (space are not counted): <br />
                    <b>{twoFactorAuthenticationCode}</b>
                  </li>
                  <li>Make sure that Time Based is selected.</li>
                  <li>Click Add to finish.</li>
                </ol>
              </div>
            </div>
          );
        }
        return (
          <div>
            <div className="text authentication__text">
              Configure the app for authentication: <br />
              <ul>
                <li>In the Google Authenticator app, tap the + icon</li>
                <li>Select the Scan a QR code option</li>
              </ul>
            </div>
            <div className="qr-code">
              <div style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}>
                <QRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={twoFactorAuthenticationUrl}
                  viewBox={'0 0 256 256'}
                />
              </div>
            </div>
            <span
              onClick={() => setShowHelpInfo(true)}
              className="qr-code__error"
            >
              Can&apos;t scan the code
            </span>
          </div>
        );
      case TwoFactorAuthenticationEnums.DISABLED:
        return '';

      default:
        return null;
    }
  }, [showHelpInfo, twoFactorAuthenticationCode, twoFactorAuthenticationPrevSelectedOption, twoFactorAuthenticationSelectedOption, twoFactorAuthenticationUrl]);

  const handleSelectedMethod = useCallback((option) => {
    setShowHelpInfo(false);
    updateTwoFactorAuthenticationSelectedOption(option);
  }, [updateTwoFactorAuthenticationSelectedOption]);

  useEffect(() => {
    if (
      twoFactorAuthenticationPrevSelectedOption?.value === twoFactorAuthenticationSelectedOption?.value ||
      twoFactorAuthenticationPrevSelectedOption?.value === TwoFactorAuthenticationEnums.EVERY_LOGIN ||
      twoFactorAuthenticationPrevSelectedOption?.value === TwoFactorAuthenticationEnums.ONCE_DAY ||
      twoFactorAuthenticationSelectedOption?.value === TwoFactorAuthenticationEnums.DISABLED
    ) {
      return;
    }
    getTwoFactorAuthenticationData();
  }, [getTwoFactorAuthenticationData, twoFactorAuthenticationPrevSelectedOption, twoFactorAuthenticationSelectedOption]);

  useEffect(() => {
    appInitCurrentUser();
    return () => {
      clearTwoFactorAuthenticationData();
      setShowHelpInfo(false);
    };
  }, []);

  return (
    <>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <h3 className="title3">
            Two-factor authentication
          </h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <SelectInput
            bordered={true}
            onSelect={handleSelectedMethod}
            options={TwoFactorAuthenticationOptions}
            defaultValue={twoFactorAuthenticationSelectedOption}
          />
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          {renderInstruction}
        </div>
      </div>

      <div className="authentication__buttons-block">
        {
          showHelpInfo &&
          <Button
            text="Back"
            onClick={() => setShowHelpInfo(false)}
            customClassName="button-primary mr-15"
          />
        }
        <Button
          text="Change 2FA"
          onClick={() => toggleAuthenticatorCodeModalIsOpen(true)}
          customClassName="button-primary"
          disabled={twoFactorAuthenticationPrevSelectedOption?.value === twoFactorAuthenticationSelectedOption?.value}
        />
      </div>
      <AuthenticatorCodeModal
        type={AuthenticatorCodeTypesForModal.TWO_FACTOR_AUTHENTICATION}
        isOpen={authenticatorCodeModalIsOpen}
        closeCb={() => toggleAuthenticatorCodeModalIsOpen(false)}
      />
    </>
  );
};

TwoFactorAuthentication.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    twoFactorAuthenticationCode: SELECTORS.getTwoFactorAuthenticationCodeSelector(state),
    twoFactorAuthenticationUrl: SELECTORS.getTwoFactorAuthenticationUrlSelector(state),
    loading: SELECTORS.getTwoFactorAuthenticationLoadingSelector(state),
    twoFactorAuthenticationSelectedOption: SELECTORS.getTwoFactorAuthenticationSelectedOption(state),
    authenticatorCodeModalIsOpen: SELECTORS.getAuthenticatorCodeModalIsOpen(state),
    twoFactorAuthenticationPrevSelectedOption: SELECTORS.getTwoFactorAuthenticationPrevSelectedOptionSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTwoFactorAuthenticationData() {
      dispatch(ACTIONS.getTwoFactorAuthenticationDataAction());
    },
    updateTwoFactorAuthenticationSelectedOption(data) {
      dispatch(ACTIONS.updateTwoFactorAuthenticationSelectedOptionAction(data));
    },
    toggleAuthenticatorCodeModalIsOpen(data) {
      dispatch(ACTIONS.toggleAuthenticatorCodeModalIsOpenAction(data));
    },
    changePasswordModalClose() {
      dispatch(changePasswordModalClose());
    },
    clearTwoFactorAuthenticationData(data) {
      dispatch(ACTIONS.clearTwoFactorAuthenticationDataAction(data));
    },
    appInitCurrentUser() {
      dispatch(appInitCurrentUserAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuthentication);
