export const NETWORK_ANALYSIS_REQUEST = 'NETWORK_ANALYSIS_REQUEST';
export const NETWORK_ANALYSIS_SUCCESS = 'NETWORK_ANALYSIS_SUCCESS';
export const NETWORK_ANALYSIS_FAILED = 'NETWORK_ANALYSIS_FAILED';

export const NETWORK_ANALYSIS_RESULT_TABLE = 'NETWORK_ANALYSIS_RESULT_TABLE_';
export const REMOVE_CONCEPT = 'REMOVE_CONCEPT';
export const NETWORK_ANALYSIS_RESULT_TABLE_RESET = 'NETWORK_ANALYSIS_RESULT_TABLE_RESET';
export const LOADING = 'LOADING';

export const NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE = 'NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE';
export const NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE = 'NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE';

export const NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV = 'NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV';
export const NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV_RESULT_SUCCESS = 'NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV_RESULT_SUCCESS';

export const NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST = 'NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST';
