import { call, put, takeLatest } from 'redux-saga/effects';

// Store
import * as a from './reducer';
// Api
import Api from '../../../../../../Api/Api';

export function* samplesDataWorker(action) {
  try {
    const {
      geneId,
      relatedId,
      scale,
      type,
      tissueGroupName,
    } = action.payload;

    const requestBody = {
      scale,
      type,
      relatedId,
      id: geneId,
      group: tissueGroupName,
    };

    const { data } = yield call(Api.getGeneDetailPopupChartsData, requestBody);
    yield put(a.setSamplesDataAction(data));
  } catch (e) {
    yield put(a.throwSamplesDataErrorAction(e.message));
  }
}

function* samplesDataSaga() {
  yield takeLatest(a.getSamplesDataAction, samplesDataWorker);
}

export default samplesDataSaga;

