import { types } from './constants';

export const getPhenotypesDataAction = data => (
  {
    type: types.GET_PHENOTYPES_DATA,
    data,
  }
);

export const setPhenotypesDataAction = data => (
  {
    type: types.SET_PHENOTYPES_DATA,
    data,
  }
);

export const togglePhenotypesLoaderAction = data => (
  {
    type: types.TOGGLE_PHENOTYPES_LOADER,
    data,
  }
);

export const phenotypesIsFailedAction = message => (
  {
    type: types.PHENOTYPES_IS_FAILED,
    message,
  }
);

export const phenotypesAdverseDrugsChangePageAction = data => (
  {
    type: types.PHENOTYPES_ADVERSE_DRUGS_CHANGE_PAGE,
    data,
  }
);

export const humanPhenotypesChangePageAction = data => (
  {
    type: types.HUMAN_PHENOTYPES_CHANGE_PAGE,
    data,
  }
);

export const phenotypesAnimalModelsChangePageAction = data => (
  {
    type: types.PHENOTYPES_ANIMAL_MODELS_CHANGE_PAGE,
    data,
  }
);
