/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  exportToPNG,
  exportToSVG,
} from '../../Utils/Utils';

import './InteractiveCellPic.css';

const propTypes = {
  locations: PropTypes.instanceOf(Array),
  geneName: PropTypes.string,
};

class InteractiveCellPic extends Component {
  exportToPNGCb = () => {
    const { geneName } = this.props;
    const diagram = window.document.querySelector('.cellPicture');
    const name = `${geneName}_localization.png`;
    if (diagram) {
      exportToPNG(diagram, name);
    }
  };

  exportToSVGCb = () => {
    const { geneName } = this.props;
    const container = window.document.querySelector('.cellWrapper');
    const name = `${geneName}_localization.svg`;
    if (container) {
      exportToSVG(container, name);
    }
  };

  render() {
    const { locations } = this.props;
    return (
      <div className="interactive-cell-wrap">
        <div className="bar-chart-controls">
          <div className="bar-chart-export-button-wrapper">
            <div
              className="export-button bar-chart-controls__png"
              onClick={this.exportToPNGCb}
              title="Export to png"
            />
            PNG
          </div>
          <div className="bar-chart-export-button-wrapper">
            <div
              className="export-button"
              onClick={this.exportToSVGCb}
              title="Export to svg"
            />
            SVG
          </div>
        </div>
        <div className="cellWrapper">
          <svg
            version="1.1"
            viewBox="0 0 613 490"
            preserveAspectRatio="xMidYMin slice"
            className="cellPicture"
            width="630px"
            height="500px"
          >
            <style dangerouslySetInnerHTML={{
              __html: `
  .cellWrapper {
    text-align: center;
    height: 500px;
  }
  
  .cytosol g path {
    fill: #26ff93;
    opacity: 0.1;
  }
  
  .cytosol--disabled g path {
    fill: #fafafa;
    opacity: 0.1;
  }
  
  .cytosol_ton g path {
    fill: #fff;
  }
  
  .intermediate_filaments_ton path, .nucleoplasm_ton path, .peroxisomes_ton path, .microtubule_organizing_center_ton path, .nuclear_bodies_ton path, .nucleoli_ton path, .nucleoli_fibrillar_center_ton path {
    fill: #F6F6F6;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .intermediate_filaments path {
    fill: #FCF8CE;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .intermediate_filaments--disabled path {
    fill: #e4e4e4;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .actin_filaments path {
    fill: #999999;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .actin_filaments--disabled path {
    fill: #e0e0e0;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .focal_adhesion_sites_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .focal_adhesion_sites path {
    fill: #6F6571;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .focal_adhesion_sites--disabled path {
    fill: #dbdbdb;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubule_organizing_center path {
    fill: #EFC16E;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubule_organizing_center--disabled path {
    fill: #c7c7c7;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .centrosome path {
    fill: #CD2632;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .centrosome--disabled path {
    fill: #c9c9c9;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubules_ton g path, .lysosomes_ton path, .actin_filaments_ton path, .endosomes_ton path, .golgi_apparatus_ton path, .nuclear_speckles_ton path, .rods_and_rings_ton path {
    fill: #EDEDED;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubules_ton--disabled g path, .lysosomes_ton--disabled path, .actin_filaments_ton--disabled path, .endosomes_ton--disabled path, .golgi_apparatus_ton--disabled path, .nuclear_speckles_ton--disabled path, .rods_and_rings_ton--disabled path {
    fill: #f1f1f1;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubules g path {
    fill: #E98080;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubules--disabled g path {
    fill: #e7e7e7;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubule_ends path {
    fill: #B7515C;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubule_ends--disabled path {
    fill: #dddddd;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .secreted_proteins path, .lipid_droplets path  {
    fill: #9F3253;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .secreted_proteins--disabled path, .lipid_droplets--disabled path  {
    fill: #cccccc;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .lysosomes path {
    fill: #D06986;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .lysosomes--disabled path {
    fill: #cfcfcf;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .peroxisomes path {
    fill: #E583A2;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .peroxisomes--disabled path {
    fill: #e0e0e0;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .endosomes path {
    fill: #F5B4CF;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .endosomes--disabled path {
    fill: #cccccc;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .endoplasmic_reticulum_ton g path {
    fill: #EDEDED;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .golgi_apparatus path {
    fill: #D99EBE;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .golgi_apparatus--disabled path {
    fill: #e0e0e0;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoplasm path {
    fill: #8AC0E2;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoplasm--disabled path {
    fill: #ebebeb;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_membrane path {
    fill: #7D64A1;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_membrane--disabled path {
    fill: #d6d6d6;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_bodies path {
    fill: #B6D9E8;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_bodies--disabled path {
    fill: #ececec;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_speckles path {
    fill: #60A2C4;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_speckles--disabled path {
    fill: #d3d3d3;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoli path {
    fill: #CEEAF9;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoli--disabled path {
    fill: #c0c0c0;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoli_fibrillar_center path {
    fill: #7BBCD6;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nucleoli_fibrillar_center--disabled path {
    fill: #ececec;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .rods_and_rings path {
    fill: #F49739;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .rods_and_rings--disabled path {
    fill: rgb(212, 212, 212);
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .mitochondria_ton path {
    fill: #F6F6F6;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .mitochondria path {
    fill: #E7C8CD;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .mitochondria--disabled path {
    fill: #e7e7e7;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .anotherMitohondria {
    fill: #A55978 !important;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .anotherMitohondria--disabled {
    fill: #d3d3d3 !important;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .plasma_membrane_ton g path {
    fill: #FFFFFF;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .plasma_membrane_ton path {
    fill: #EDEDED;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .plasma_membrane g path {
    fill: #FAE4EC;
  }
  
  .plasma_membrane--disabled g path {
    fill: #f3f3f3;
  }
  
  .plasma_membrane path {
    fill: #C02E66;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .plasma_membrane--disabled path {
    fill: #dbdbdb;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .centrosome_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .microtubule_ends_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .secreted_proteins_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .lipid_droplets_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .endoplasmic_reticulum g path {
    fill: #8F83BD;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .endoplasmic_reticulum--disabled g path {
    fill: #cfcfcf;
    stroke: #000000;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
  .nuclear_membrane_ton path {
    fill: #DADADA;
    stroke: #9D9D9C;
    stroke-width: 0.15;
    stroke-miterlimit: 10;
  }
  
`,
            }}
            />
            <g title="Cytosol" className="cytosol_ton">
              <g>
                <title>Cytosol</title>
                <path
                  d="M48.7,227.2c0,0-0.1,0.1-0.2,0.3c-4.6,7.6-9,15.5-13.4,24.3c-18.9,37.4-27.1,55.5-26,92.3
    c1,32.4,8.7,41.2,23.9,58.7c1.9,2.2,3.9,4.5,6,7c12.6,14.9,40.4,21.9,69.8,29.3c11.5,2.9,23.3,5.9,35,9.5
    c32.6,10,64.6,14.1,98.6,18.3c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.5,2,20.6,3.2c21.3,3.4,43.3,6.9,66.4,6.9
    c23.9,0,45.3-3.9,65.6-11.8c34.9-13.6,60.8-20.9,84.3-27.2c0.2-0.1,0.8-0.3,0.9-0.6c0.2-0.4-0.1-1.2-0.7-2c-0.8-1.2-1-2.7-0.4-4.2
    c0.7-1.9,2.5-3.4,4.8-4c0.9-0.2,1.8-0.4,2.7-0.4c3.3,0,5.6,1.8,5.6,4.5c0,1.4,0.2,2.1,0.4,2.5c0.2,0.4,0.4,0.5,0.6,0.5
    c0.1,0,0.3,0,0.4-0.1c9.6-2.6,20-5.4,31-8.7c38.7-11.6,40.6-32.2,44.8-77.5c0.3-3.6,0.7-7.5,1.1-11.4c3.2-32.9-2.7-53.7-8.5-73.8
    c-3.6-12.6-7-24.6-7.8-38.6c-0.9-15.9-1.6-25.4-2.7-33.7c-0.1-0.9-0.4-1.8-1-1.8c-0.2,0-0.7,0.1-1.4,0.7c-1.6,1.3-3.1,2-4.5,2
    c-3.3,0-5.2-3.4-5.6-6.5c-0.4-2.8,0.1-5.1,1.5-6.6c1.5-1.7,3.8-2.2,6.3-1.4c0.8,0.3,1.6,0.2,1.9-0.2c0.2-0.2,0.2-0.7,0.1-1.3
    c-1.3-5.3-3.2-11.3-6.1-20c-8.7-25.5-10.3-35-15.6-77.1c-2.6-20.9-11.9-34.1-30.3-42.9c-18.5-8.9-46.8-13.8-91.9-15.8
    c-23.8-1.1-41-2.7-56.1-4.1c-4.1-0.4-8.1-0.8-12-1.1c-0.5,0-0.6,0.1-0.7,0.1c-0.1,0.1-0.4,0.6-0.3,1.9c0.1,1.3-0.4,2.5-1.2,3.4
    c-0.7,0.8-2.1,1.7-4.6,1.7c-0.7,0-1.5-0.1-2.4-0.2c-3.4-0.6-5-2.7-4.6-6.1c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.1-0.9
    c0,0-0.3-0.2-1.6-0.2c-12.7-0.7-23.8-0.7-36.5,0c-29,1.6-54.3,8.7-76.6,14.9c-9.7,2.7-18.8,5.3-27.4,7.1
    c-28,6.1-46.9,15.4-72.7,49.5c-17.2,22.7-24.1,34.6-32.8,49.7c-4.5,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5
    c-4.3,6.1-8.4,11.9-12.6,18.2c-0.3,0.4-0.3,0.5-0.3,0.5c0.1,0,0.5,0.5,3,1.1c1.8,0.5,3.2,1.7,3.7,3.3c0.7,1.9,0.2,4.2-1.3,6.2
    c-1.3,1.7-3.1,2.7-5,2.7c-1.8,0-3.3-0.8-4.3-2.3C49.6,227.6,48.9,227.2,48.7,227.2z"
                />
              </g>
            </g>
            <g
              title="Cytosol"
              className={locations.includes('Cytosol') || locations.includes('Cytoplasmic bodies') ? 'cytosol' : 'cytosol--disabled'}
            >
              <g>
                <title>Cytosol</title>
                <path
                  d="M48.7,227.2c0,0-0.1,0.1-0.2,0.3c-4.6,7.6-9,15.5-13.4,24.3c-18.9,37.4-27.1,55.5-26,92.3
    c1,32.4,8.7,41.2,23.9,58.7c1.9,2.2,3.9,4.5,6,7c12.6,14.9,40.4,21.9,69.8,29.3c11.5,2.9,23.3,5.9,35,9.5
    c32.6,10,64.6,14.1,98.6,18.3c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.5,2,20.6,3.2c21.3,3.4,43.3,6.9,66.4,6.9
    c23.9,0,45.3-3.9,65.6-11.8c34.9-13.6,60.8-20.9,84.3-27.2c0.2-0.1,0.8-0.3,0.9-0.6c0.2-0.4-0.1-1.2-0.7-2c-0.8-1.2-1-2.7-0.4-4.2
    c0.7-1.9,2.5-3.4,4.8-4c0.9-0.2,1.8-0.4,2.7-0.4c3.3,0,5.6,1.8,5.6,4.5c0,1.4,0.2,2.1,0.4,2.5c0.2,0.4,0.4,0.5,0.6,0.5
    c0.1,0,0.3,0,0.4-0.1c9.6-2.6,20-5.4,31-8.7c38.7-11.6,40.6-32.2,44.8-77.5c0.3-3.6,0.7-7.5,1.1-11.4c3.2-32.9-2.7-53.7-8.5-73.8
    c-3.6-12.6-7-24.6-7.8-38.6c-0.9-15.9-1.6-25.4-2.7-33.7c-0.1-0.9-0.4-1.8-1-1.8c-0.2,0-0.7,0.1-1.4,0.7c-1.6,1.3-3.1,2-4.5,2
    c-3.3,0-5.2-3.4-5.6-6.5c-0.4-2.8,0.1-5.1,1.5-6.6c1.5-1.7,3.8-2.2,6.3-1.4c0.8,0.3,1.6,0.2,1.9-0.2c0.2-0.2,0.2-0.7,0.1-1.3
    c-1.3-5.3-3.2-11.3-6.1-20c-8.7-25.5-10.3-35-15.6-77.1c-2.6-20.9-11.9-34.1-30.3-42.9c-18.5-8.9-46.8-13.8-91.9-15.8
    c-23.8-1.1-41-2.7-56.1-4.1c-4.1-0.4-8.1-0.8-12-1.1c-0.5,0-0.6,0.1-0.7,0.1c-0.1,0.1-0.4,0.6-0.3,1.9c0.1,1.3-0.4,2.5-1.2,3.4
    c-0.7,0.8-2.1,1.7-4.6,1.7c-0.7,0-1.5-0.1-2.4-0.2c-3.4-0.6-5-2.7-4.6-6.1c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.1-0.9
    c0,0-0.3-0.2-1.6-0.2c-12.7-0.7-23.8-0.7-36.5,0c-29,1.6-54.3,8.7-76.6,14.9c-9.7,2.7-18.8,5.3-27.4,7.1
    c-28,6.1-46.9,15.4-72.7,49.5c-17.2,22.7-24.1,34.6-32.8,49.7c-4.5,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5
    c-4.3,6.1-8.4,11.9-12.6,18.2c-0.3,0.4-0.3,0.5-0.3,0.5c0.1,0,0.5,0.5,3,1.1c1.8,0.5,3.2,1.7,3.7,3.3c0.7,1.9,0.2,4.2-1.3,6.2
    c-1.3,1.7-3.1,2.7-5,2.7c-1.8,0-3.3-0.8-4.3-2.3C49.6,227.6,48.9,227.2,48.7,227.2z"
                />
              </g>
            </g>
            <g title="Intermediate filaments" className="intermediate_filaments_ton">
              <title>Intermediate filaments</title>
              <path
                d="M590.8,287.8c0.9-6-0.2-8.3-4.4-13.2
  l-0.9,0.8c4,4.7,4.9,6.7,4.1,12.3c-0.6,4.4-3.2,7.3-6.7,9.6c-2.6-19.4-7.9-39.1-12.9-57.6c-0.5-2-1.1-4-1.6-6
  c2.1-1.9,4.1-4,5.6-6.5c3.7-6,4.5-12.9,2.2-17.9c-1.9-4.1-5.7-6.7-10.9-7.5c-0.5-0.1-1-0.2-1.5-0.3c-0.9-2.6-2-5.2-3.3-7.8
  c-1.2-21.2-4.9-42.7-11.6-61.3c0-0.4-0.1-0.9-0.1-1.3c2.4-2,4.1-4.9,4.7-8.3c0.9-5.5-0.8-12.4-5.2-20.5l-1.1,0.6
  c4.3,7.8,6,14.5,5.1,19.7c-0.5,2.8-1.8,5.2-3.6,7c-1.1-12.3-4.7-24.3-10.8-36.5c1.2-1.8,2.3-3.8,3-6.1c3.3-11.2-0.8-25.8-9.2-32.5
  c-2.7-2.1-5.1-3-7.3-2.6c-3.1,0.5-5.5,3.3-7.6,6.5c-1.5-1.2-3.1-2.1-5-2.8c-3.8-1.3-7.5-0.9-11.1,0.1c-3.8-3.5-8-6.5-13.2-8.6
  c-6.7-2.6-13.7-1.3-20.5,0.4c-4.5-1.3-9.1-2.4-13.8-3.3c-3-1.4-6.1-2.5-9.2-3.6c2.7-2.5,5.6-4.9,8.7-6.8c0.2-0.1,0.4-0.3,0.7-0.4
  c0.3,0.1,0.6,0.3,1,0.4c4.2,1.7,8.5,3.5,13.3,4.4c0.3,0.1,0.7,0.1,1.2,0.3c3.4,0.8,9.8,2.2,11.1-1.2c0.2-0.6,0.1-1.3-0.3-1.9
  c-1.8-3-10.3-6.3-12.1-6.5c-5.3-0.7-10.5,1.3-14.2,3.3c-1.6-0.7-3.3-1.3-5-1.9c-12.3-4.3-23.8-0.5-34.6,3.8l0.4,1.1
  c11-4.4,21.8-8,33.8-3.8c1.4,0.5,2.7,1,4.1,1.5c-3.4,2.1-6.4,4.6-9.3,7.3c-0.9-0.3-1.8-0.5-2.7-0.8c-9.8-2.7-19.4-3-28.7-2
  l-0.3-0.3c-0.2-0.2-0.6-0.3-0.8,0c-0.1,0.1-0.2,0.3-0.2,0.5c-4.4,0.5-8.7,1.4-13,2.4c-1.4,0-2.9,0-4.3,0c-0.8-1.6-1.6-3.2-2.5-4.7
  c-3.3-5.5-7.9-8.6-13.3-8.9c-6.6-0.5-14.1,3.2-19.8,9.7c-0.9-0.1-1.8-0.2-2.7-0.2c-5.7-0.2-11.5,0.2-17.5,0.9
  c0-0.9-0.2-1.7-0.5-2.5c4.3-1,8.8-1.4,13.1-1.8l0.5,0c0.3,0,0.6-0.3,0.5-0.6c0-0.3-0.3-0.6-0.6-0.5l-0.5,0
  c-4.5,0.4-9.2,0.9-13.7,1.9c-1.3-1.7-3.8-2.7-6.9-2.9c-0.5,0-1,0-1.5,0c0.2-0.8,0.3-1.7,0.1-2.6c-0.3-2.1-1.5-4.1-3.1-5.1
  c-4.7-3-9.9,0.8-14.9,4.4c-0.5,0.4-1.1,0.8-1.6,1.2c-2.4-1.1-4.7-1.7-6.6-1.4c-5,1-7.6,5.1-10.1,9.1c-0.6,1-1.3,2-2,3
  c-5.3-0.1-10.4-0.1-15.2,0.5c-0.3-0.4-0.7-0.8-1-1.2c-0.5-0.6-1-1.1-1.4-1.6c-4.3-4.8-9.7-6.7-15.2-5.6c-6.1,1.3-7.7,6.9-9.1,11.8
  c-0.3,1.2-0.6,2.3-1,3.3c-0.4,1-0.8,2-1.4,2.9c-14.7-0.4-28.8,2.8-42.3,13.8c-1.4-0.3-2.9-0.5-4.3-0.7c1.2-0.9,2.3-1.6,3.5-2
  c0.3-0.1,0.5-0.4,0.3-0.7c-0.1-0.3-0.4-0.5-0.7-0.3c-1.5,0.5-3.1,1.5-4.7,2.9c-0.9-0.1-1.7-0.1-2.6-0.1c-0.1,0-0.2,0-0.3,0
  c-11.3,0-22.2,4.3-29.4,11.4c-2.7,2.7-4.4,6-6,9.2c-0.8,1.5-1.6,3-2.5,4.5c-2.2,3.6-4.6,4.9-7.7,6.6c-1.2,0.7-2.5,1.3-3.8,2.2
  c-9.8,6.4-18.8,14-18.2,25.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6c-0.6-11.6,8.8-18.8,17.7-24.6
  c1.3-0.9,2.6-1.5,3.7-2.2c3.1-1.7,5.7-3.1,8.1-7c0.9-1.5,1.7-3.1,2.5-4.6c1.7-3.2,3.2-6.2,5.8-8.9c7-7,17.8-11.2,28.8-11.1
  c0.5,0,0.9,0,1.4,0c-1.4,1.3-2.8,3-4.4,4.9c-1.3,1.7-2.6,3.6-3.7,5.4c-0.6,0.9-1.2,1.9-1.9,2.8c-2-0.4-4.1-0.2-5.9,0.7
  c-1.3,0.7-2.2,1.2-3,1.7c-1.6,1-2.5,1.7-5.7,1.7c-5.4,0.1-8.6,4.1-10.5,9.1c-1.1,0.4-2.2,1-3.3,1.7c-6.2,4.1-10.9,10.7-14.8,17.9
  c-0.1,0.1-0.3,0.2-0.4,0.3c-10.9,9-17,25-22.4,39.2c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c0-1.9-0.6-3.8-1.8-5.8l-1,0.6
  c1.1,1.9,1.7,3.8,1.6,5.6c-2.9,1.3-5.3,3.3-6.9,7.3c-0.3,0.7-0.5,1.4-0.7,2.1c-1.8,0.8-3.7,1.5-5.6,2.1c-1.9,0.6-3.6,1.2-5.2,1.9
  c-9.5,4.4-14.8,17.5-14.3,27.5c0.2,3.9,1,8,2.3,11.9c-9.3,7.8-14.4,17.9-17.5,29.1c-0.9,0.3-1.8,0.8-2.8,1.4
  c-3.7,2.6-3.9,5.4-4.1,7.8c-0.2,2.3-0.3,4.4-3,6.3c-0.8,0.6-1.4,0.8-2,1.1c-1.4,0.6-2.3,1.1-3,3.9c-0.9,3.3,0.6,9,1.9,13.9
  c0.5,2,1.1,3.9,1.3,5.3c0.2,1.2,0.4,2.5,0.7,3.7c-2.1,4.3-4.2,8.6-6.3,13c-0.4-0.5-0.9-1.1-1.3-1.6c-0.2-0.2-0.6-0.3-0.8-0.1
  c-0.2,0.2-0.3,0.6-0.1,0.8c0.6,0.7,1.1,1.3,1.7,2c-0.1,0.1-0.1,0.2-0.2,0.3c-3.7,7.6-6.7,14.8-9.2,21.5c-0.1-0.1-0.3-0.1-0.4-0.2
  c-3.1-1.5-6.5-4.1-9.1-7.1c-0.2-2.8,0-5.5,0.9-8c0.9-2.4,2.3-3.6,4-5.1c0.8-0.7,1.7-1.4,2.5-2.3c3-3.3,3.9-7.1,4.9-11.2l0.1-0.4
  c1-4.3,2.7-6.4,6.1-9.6c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0c-3.2,3.1-5.3,5.4-6.4,10.2l-0.1,0.4
  c-0.9,3.9-1.8,7.6-4.6,10.7c-0.8,0.8-1.6,1.5-2.4,2.2c-1.7,1.5-3.3,2.8-4.3,5.6c-0.8,2.2-1.1,4.5-1,6.9c-1.2-1.7-2.1-3.5-2.5-5.3
  c-0.8-3.1-0.1-6.1,1.9-8.7l-1-0.7c-2.2,2.9-2.9,6.2-2.1,9.7c0.6,2.5,2,5,3.8,7.1c0.3,3.8,1.2,7.6,2,11.2c0.3,1.1,0.5,2.2,0.7,3.2
  c0.7,3,1.5,5.9,2.6,8.8c0,0.2-0.1,0.3-0.1,0.5c-3.2-2.6-6.5-5-11.2-4.2c-11.4,1.9-9.7,15.7-8.7,23.9l0.1,0.8c0.2,1.5,0.3,3.2,0.5,5
  c0.9,10.8,2.1,25.5,13,28.2c1.4,0.3,2.7,0.5,3.9,0.5c2.4,0,4.5-0.6,6.4-1.9c1.7,3.1,3.8,6,6.2,8.7c0.1,0.2,0.2,0.4,0.3,0.5
  c0.1,0.2,0.3,0.3,0.5,0.3c3.3,3.6,7.1,6.9,11.5,9.8c2.4,1.6,4.9,3.1,7.5,4.4c4.6,3.5,10.2,6.4,16.5,8.7c-4.4,2.4-9,4.4-13.5,3.8
  c-5.5-0.8-9.9-5.2-13-13.1l-1.1,0.4c3.2,8.3,7.9,13,13.9,13.8c5.2,0.7,10.4-1.8,15.2-4.4c3,1.1,6,2,9.3,2.9
  c2.1,1.3,4.3,2.5,6.7,3.6c8.1,3.5,16.7,6.3,25.5,8.4c3.8,3.5,10.9,8.9,16.1,8.9c0.6,0,1.1-0.1,1.7-0.2c2.1-0.6,3.1-2.1,4.1-3.6
  c0.1-0.2,0.3-0.4,0.4-0.6c0.3,0.1,0.7,0.1,1,0.2c1,1.6,1.7,3.4,2.5,5.4c0.8,1.9,1.5,3.9,2.6,5.8c2,3.4,4.4,5.2,7.2,5.2
  c0.1,0,0.1,0,0.2,0c4.6,0,8.6-4.8,10.5-7.7c0.8-1.2,1.6-2.3,2.4-3.4c0.1,0,0.2,0.1,0.3,0.2c0.7,0.3,1.4,0.6,2.1,0.9
  c0.2,0.3,0.4,0.6,0.6,1c2.1,3.3,4.2,6.8,6.6,7.8c0.8,0.3,1.6,0.5,2.3,0.5c2.2,0,4.2-1.4,5.9-4c12.2,3.3,25,4.7,38.1,4.9
  c7.8,5.4,16.9,7.2,26.5,7.2c5.1,0,10.4-0.5,15.6-1.3c6.1,1.4,12.2,2.7,18.2,3.8c-4.9,3.3-10.4,5.4-17.1,5.4c0,0,0,0,0,0l0,1.2
  c0,0,0,0,0,0c7.5,0,13.5-2.5,18.8-6.3c9.7,1.7,19.3,2.8,28.8,3.1c0.7,0.9,1.7,1.7,3,2.5c2.9,1.6,7.2,2.5,11.2,2.5
  c2.5,0,4.9-0.3,6.7-1.1c2.8-1.1,4.9-3.3,7-5.5c0.3-0.3,0.7-0.7,1-1c0.1,0,0.2,0,0.4-0.1c2.1-0.5,4.3-1,6.4-1.6
  c2.4,1.8,5.7,2.8,8.4,3.6l0.2,0.1c2.9,0.9,5.8,1.2,8.6,1.2c4.9,0,9.7-1.1,14.3-2.7c0.9,2.2,2.1,4.4,3.7,6.3
  c2.9,3.4,6.6,4.7,10.4,4.7c4.5,0,9.2-2,12.9-4.9c7.2-5.6,10.7-13.6,14-22.6c2.4-0.7,4.7-1.5,7-2.2c1.3,0.3,2.5,0.6,3.8,0.8
  c2,0.4,4.1,0.5,6.1,0.5c9.2,0,18.2-3.6,26.4-9.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3,0.1,0.6,0.4,0.7c0.1,0,0.1,0,0.2,0
  c0.2,0,0.5-0.2,0.6-0.4c0.2-0.5,0.3-1,0.5-1.4c2.7-0.9,5.4-2.1,8.1-3.5c0.4,2.3,1.1,4.3,2.5,5.4c0.9,0.7,1.9,1,3.2,1
  c0.8,0,1.7-0.1,2.6-0.4c6-1.8,9.3-10.1,11-14.6c0.8-2.1,1.7-4.2,2.5-6.3c2-0.8,4-1.7,6-2.5c8.8-0.4,17.5-0.5,26.2,0
  c2.1,0.1,4.4,0.5,6.5,0.8c0.2,0,0.3,0,0.5,0.1c-0.3,1.8-0.7,3.6-1.3,5.2c-2.2,5.8-7.2,8.3-12.1,10.4l0.5,1.1
  c5.2-2.2,10.4-4.9,12.7-11.1c0.7-1.8,1.1-3.6,1.4-5.5c4.7,0.7,9.5,1.1,14-0.6c4.2-1.6,7.8-5.4,9-9.6c1-3.3,0.4-6.4-1.6-9.1
  c-0.2-0.3-0.6-0.3-0.8-0.1c-0.3,0.2-0.3,0.6-0.1,0.8c2.3,3,2,6.1,1.4,8.1c-1.1,3.8-4.4,7.4-8.3,8.8c-4.3,1.6-8.9,1.2-13.4,0.5
  c0.1-1,0.2-2,0.3-3c0.2-1.9,0.4-3.8,0.8-5.6c0.1,0,0.2-0.1,0.4-0.1c5.3-1.6,7.8-6.2,9.2-11.5c1.8-0.6,3.9-1,5.9-1.3
  c-0.6,7-4.3,12.4-15,18.2c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.2,0,0.3-0.1c11.2-6.1,15.1-11.9,15.6-19.4
  c0.8-0.1,1.6-0.2,2.4-0.3c5.8-0.7,11.9-1.4,16.2-5.3c7.6-6.8,5.4-20.2,1.3-29.7c-0.8-1.8-1.7-3.6-2.6-5.3
  c3.4-10.5,4.6-21.7,4.4-33.3c3-2.6,5.5-5.8,4.9-9.7c-0.5-3.2-3.1-5.8-6.2-8c0-0.2,0-0.3-0.1-0.5C587,296.1,590.1,292.9,590.8,287.8
  z M467,30.1c1.5,0.2,9.7,3.4,11.2,6c0.3,0.5,0.2,0.8,0.2,0.9c-0.9,2.4-7,1-9.7,0.4c-0.5-0.1-0.9-0.2-1.2-0.3
  c-4.6-0.9-8.7-2.5-13.1-4.3c0,0,0,0,0,0C457.9,31,462.4,29.5,467,30.1z M569,239.9c5,18.6,10.4,38.5,12.9,58c0,0-0.1,0.1-0.1,0.1
  c-1.1-0.7-2.2-1.4-3.3-2.1c-2-1.2-3.9-2.4-5.2-3.6c2.9-16.5-1-32.1-7.1-47.8c0.5-3.3,0.9-6.6,1.1-9.9c0.1-0.1,0.2-0.1,0.2-0.2
  C568,236.3,568.5,238.1,569,239.9z M568.2,324.6c0,1.2-0.1,2.4-0.2,3.6c-0.2-0.5-0.5-0.9-0.8-1.5c-1.3-2.5-3-5.7-4.2-8.7
  c1.9-3.1,3.6-6.2,5-9.2C568.3,314.1,568.4,319.3,568.2,324.6z M560.6,373.5c-0.1-0.3-0.1-0.6-0.2-0.9l-0.4-2
  c-0.2-1.1-0.4-2.2-0.7-3.3c5.2-9.9,8.4-21.5,9.6-34.7c1.7,3.5,3.7,6.8,5.6,10.1c1.5,2.5,2.9,5,4.3,7.6c-2.8,8.5-7.2,16.1-12.8,22.9
  C564.3,373.4,562.5,373.5,560.6,373.5z M564.9,374.5c-1.1,1.3-2.2,2.5-3.4,3.7c-0.2-1.1-0.5-2.3-0.7-3.6c0.1,0,0.3,0,0.4,0
  C562.5,374.7,563.7,374.6,564.9,374.5z M555.5,387.9c-0.2,1.1-0.4,2.2-0.7,3.3c-4,1.5-7.4,4.2-9.1,9.6c-0.2,0.7-0.4,1.4-0.5,2.1
  c-2.2,0.4-4.1,0-5.9-0.8c1.4-1.5,2.3-3.4,2.3-5.9c5.1-3.3,10-6.9,14.5-10.9C555.8,386.1,555.6,387,555.5,387.9z M525.7,397.7
  C525.7,397.7,525.7,397.7,525.7,397.7c-0.8,0.4-1.6,0.8-2.4,1.2c-3.5,1.6-7.1,3.1-10.8,4.4c6-7.5,11.6-15.4,16.9-23.2
  c-1.6,6.4-4.5,12.2-9.3,16.8c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c5.7-5.4,8.8-12.3,10.2-20.1
  c0.6-0.8,1.2-1.7,1.7-2.5c1-1.5,2-3,3.1-4.5c0.5,0.1,1.1,0.3,1.7,0.4c0.8,0.2,1.6,0.4,2.4,0.6c-2.9,3.1-5.6,6.6-6,9.9
  c-0.4,3.2,1.4,5.4,3.1,7.7c0.3,0.4,0.6,0.7,0.8,1.1c-3.2,2.3-6.6,4.5-10.3,6.5c-0.7-0.1-1.4-0.1-2.1-0.1c-0.3,0-0.6,0.3-0.6,0.6
  C525.1,397.5,525.4,397.7,525.7,397.7z M492.6,412.6c-3,0.3-5.9,0.6-8.9,1c0.4-0.7,0.8-1.4,1.2-2.1c0,0,0,0,0,0
  c3.3-0.6,6.6-1.2,9.8-2C494.1,410.6,493.4,411.6,492.6,412.6z M493.3,413.7c3.1-0.3,6.2-0.6,9.4-0.8c-0.4,0.4-0.8,0.8-1.2,1.2
  c-4.2,1.7-8.5,3.5-12.6,5.3C490.4,417.6,491.9,415.7,493.3,413.7z M467.3,427.5c-4.7,1.6-9.2,3.2-13.5,4.7c-2.1,0.8-4.5,1.8-7,2.8
  c-0.3,0.1-0.7,0.3-1,0.4c-2.7-0.2-5.4-0.5-8.1-1c0.6-0.4,1.2-0.7,1.8-1.1c6.9-1,13.5-3.3,18.8-7.8c1.8-1.6,3.7-3.8,5.3-6.3
  c2.1-0.8,4.1-1.7,6.1-2.6c4-0.6,8-1.1,12-1.6c-1.6,2.9-3.1,5.9-4.6,9C473.8,425.2,470.5,426.4,467.3,427.5z M469.2,428
  c-5.8,2.5-11.6,4.9-17.5,7.1c-0.5,0.2-0.9,0.3-1.4,0.5c-0.6,0-1.3,0-2-0.1c2.1-0.9,4-1.7,5.8-2.3c4.3-1.5,8.7-3.1,13.5-4.7
  C468.2,428.4,468.7,428.2,469.2,428z M387.4,451.6c-0.4-1.4-0.7-2.8-1.1-4.2c6-2.7,12-5.5,17.9-8.4c4-1.9,8-3.8,12-5.7
  c3.9,2.1,7.8,4.1,11.9,5.6c-1.4,0.8-2.8,1.6-4.3,2.5c-2.4-0.1-4.6-0.6-6.7-1.6c-0.3-0.1-0.6,0-0.8,0.3c-0.1,0.3,0,0.6,0.3,0.8
  c1.7,0.8,3.5,1.3,5.4,1.6c-2.9,1.6-5.8,3.3-8.9,4.9C404.7,449.3,396.1,450.8,387.4,451.6z M409.2,449.4c-1.4,0.8-2.8,1.5-4.3,2.3
  c-4.7,2.5-10,5.3-15.4,7.3c-0.7-2-1.3-4.2-1.8-6.3C395,452,402.2,450.9,409.2,449.4z M335.1,449.9c1.2,0.1,2.6,0.1,4.1,0.1
  c3.3,0,7,0,10,0.9c-4.3,1.2-8.8,2.2-13.4,2.9c-5.2,0.9-10.4,1.4-15.5,1.7c0-0.1,0-0.2,0-0.3c-0.3-2.6-0.6-5.2-2.4-7.6
  c-0.2-0.3-0.4-0.5-0.6-0.7c0.8-0.1,1.7-0.2,2.5-0.2c7.3-0.5,13.4-1.4,18.7-2.8c0.5,0.2,0.9,0.3,1.4,0.5c5,1.7,10,3.4,15.1,4.9
  c-1.2,0.4-2.5,0.8-3.7,1.1c-3.4-1.5-7.9-1.5-11.9-1.6c-1.4,0-2.8,0-4-0.1c-0.3,0-0.6,0.2-0.6,0.5
  C334.6,449.6,334.8,449.9,335.1,449.9z M174.1,437c-0.5-0.3-1.1-0.5-1.6-0.8c0.7,0.1,1.4,0.2,2,0.2
  C174.3,436.6,174.2,436.8,174.1,437z M143.1,427.3c-1.5-0.4-2.9-0.6-4.2-0.9c-3.9-0.8-6.8-1.4-9.4-3.6c7.4,0.9,14.9,1.6,22,2.2
  c1.5,1.1,3,2.2,4.6,3.2c-1.7-0.1-3.5,0-5.2,0.7c-0.9,0.4-1.6,0.9-2.2,1.5C147.3,429,145.5,427.9,143.1,427.3z M74,391.8
  c6.1,8,12.6,13.3,19.3,15.9c-0.3,0.1-0.6,0.3-0.8,0.4l-1.3,0.6c-1.2,0.5-2.4,1.1-3.6,1.8C81.9,405.3,77.5,399,74,391.8z
   M40.7,378.8c-7.7-14.4-7.5-31.2-3.7-47.8c-2.2,15.5-0.6,31.4,4.8,46.7C41.4,378.1,41.1,378.5,40.7,378.8
  C40.7,378.8,40.7,378.8,40.7,378.8z M38.4,329c0.9-5.5,2.3-10.8,4.2-16.1c0.4,0.2,0.8,0.4,1.2,0.5c2.9,1.3,6,2.6,8.3,4.5
  c0,0.5,0,0.9,0,1.4c-0.5,2.7-0.9,5.5-1.1,8.2c-0.5,1.8-1.3,3.6-2.1,5.5c-2.4,0.6-4.9,0.3-7-0.8c-1-0.5-2-1.3-3-2
  C38.8,329.8,38.6,329.4,38.4,329z M88,203.4c-0.1-0.2-0.1-0.4-0.2-0.5c0.2-0.2,0.4-0.3,0.6-0.5C88.3,202.8,88.1,203.1,88,203.4z
   M110.2,159.9c3-1.4,5.6-3.4,7-6.4c-0.6,1.6-1.2,3.2-1.9,4.7c-2.2,2.6-4.3,5.3-6.4,8.1C109.2,164.1,109.6,162,110.2,159.9z
   M127.3,147.9c0.3-0.1,0.6-0.1,0.8-0.2c0,0.7,0.1,1.4,0.1,2.2c-0.8,0.3-1.7,0.6-2.6,1c0.3-0.9,0.6-1.8,1-2.8
  C126.9,148,127.1,148,127.3,147.9z M127.2,146.8c0,0,0-0.1,0-0.1c0.3-0.3,0.6-0.7,0.9-1c0,0.3,0,0.6,0,0.9
  C127.8,146.6,127.5,146.7,127.2,146.8z M128.4,151.1c0.1,2.5,0.3,5,0.6,7.5c-2.9,1.6-5.6,3.4-7.6,5.5c1.2-3.9,2.5-7.8,3.8-11.7
  C126.3,151.8,127.3,151.4,128.4,151.1z M161.9,107.5c0.9-1.2,1.1-2.5,1.4-3.8c0.1-0.5,0.2-0.9,0.3-1.3c2.3-0.2,4.6-0.4,7-0.6
  c-2.1,1.3-4.2,2.7-6.3,4.2c-0.3,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.5,0.3,0.8,0.1c2.3-1.6,4.6-3.1,6.9-4.6c-0.6,1-1.1,2-1.7,3.1
  c-0.2,0.2-0.5,0.4-0.7,0.6c-4.9,4.2-9.8,8.4-14.6,12.7c0.5-2.4,1.1-4.8,1.6-7.2C158.5,110.6,160.5,109.4,161.9,107.5z M156.7,110.2
  c0.3-1.3,0.6-2.6,0.8-4c0.2-0.9,0.3-1.9,0.5-3c1.4-0.3,2.9-0.5,4.4-0.7c-0.1,0.3-0.1,0.6-0.2,1c-0.3,1.2-0.5,2.3-1.2,3.3
  C159.9,108.3,158.4,109.3,156.7,110.2z M175.8,102.2c0.3-0.2,0.6-0.5,0.9-0.7c2.7-0.2,5.3-0.5,8-1c-1.5,3.9-3.6,6.2-8.1,9.4
  c-0.3,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.5,0.3,0.8,0.1c5-3.6,7.1-6,8.8-10.6c0.1,0,0.1,0,0.2,0c5.3-1.1,10-3.2,14.3-5.8
  c-4.8,6.9-11.3,11.6-17.7,16.2c-4.1,2.9-8.2,5.8-11.9,9.4C172.2,114,173.8,108.1,175.8,102.2z M175,99.2c0.5-0.8,1-1.7,1.5-2.5
  c-0.3,0.7-0.5,1.4-0.7,2.1C175.5,98.9,175.3,99,175,99.2z M218.9,65.4c4.8-0.9,10.9-3.1,16.3-6.3c1.6-0.3,3.1-0.5,4.7-0.7
  c-4.9,1.6-9.8,3.4-14.4,5.8c-12,6.2-15,11.9-19.1,19.7c-0.4,0.9-0.9,1.8-1.4,2.7c-5.3,1.4-10.6,3.3-16,5.5c0-0.1,0.1-0.1,0.1-0.2
  c0.5-0.9,1-1.8,1.4-2.8c2.5-2.3,5-4.6,7.5-6.9c0.2,0.4,0.4,0.8,0.8,1.1c1.1,1,3,1.3,5.8,0.8c0.3-0.1,0.5-0.4,0.5-0.7
  c-0.1-0.3-0.4-0.5-0.7-0.5c-2.4,0.4-4,0.2-4.8-0.5c-0.3-0.3-0.5-0.7-0.7-1.1c4.1-3.8,8-7.7,11.8-11.6c1.3-1.3,2.6-2.7,3.8-4
  C216.1,65.8,217.5,65.7,218.9,65.4z M217.5,64.4c4.5-2,9.1-3.5,13.9-4.5c-4.4,2.2-9,3.7-12.7,4.4C218.3,64.3,217.9,64.4,217.5,64.4
  z M275.8,43.4c-0.6,0.3-1.2,0.7-1.8,1c-4,2.2-8.1,4.4-12.5,6.4c-3.5-0.6-7-1-10.5-1.3c4.7-3.6,9.9-6.3,15.9-7.7
  c2-0.5,4-0.8,6.2-1.1C273.9,41.6,274.8,42.6,275.8,43.4z M315.6,41.7c0.7,0,1.4,0.1,2,0.1c-1.5,1-3,2.1-4.4,3.2
  c-1.5,1.1-2.9,2.1-4.4,3.2c-2,1.4-4,2.8-6,4.3c0.4-2.7,1.2-5.3,2.5-7.8c0.1-0.2,0.2-0.4,0.3-0.6C308.9,43.2,312.3,42.4,315.6,41.7z
   M306.6,42.6c0.3-0.5,0.7-1,1.1-1.4c1.3,0.1,2.6,0.2,3.8,0.3C309.8,41.9,308.2,42.2,306.6,42.6z M367.1,50.4
  c-1.1,1.2-2.1,2.6-2.9,4c-0.5,0.8-0.8,1.6-1.1,2.3c-8.4,0.4-16.9,1.4-25.4,3.1c0.5-0.5,1-0.9,1.6-1.3
  C348.7,56.5,358,53.5,367.1,50.4z M341.4,56.8c3.9-2.7,8.6-4.8,14.2-5.9c2.2-0.4,4.4-0.7,6.5-0.9c1-0.1,2-0.1,3-0.2
  C357.4,52.5,349.5,55,341.4,56.8z M440.7,47.8c2.2,1.4,2.7,4.3,3,7.1c-1.3-0.4-2.6-0.8-3.9-1.2c-1.6-0.4-3.2-0.8-4.8-1.2
  c-0.1-1.2-0.3-2.4-0.5-3.5c0.8-0.7,1.6-1.4,2.4-2.2C438.5,47,439.7,47.2,440.7,47.8z M451.5,60.8c2.3,0.2,4.7,0.7,7.1,1.1
  c7.1,3.3,14.1,7.4,20.7,12c-2-0.5-4.1-1.2-6.2-1.8c-5.1-1.5-10.3-3.1-15.4-3.7c-7.2-0.9-10.8-2.6-12.3-10.4
  c-0.1-0.4-0.2-0.9-0.2-1.4c3,1,6,2.1,9,3.4c-0.9-0.1-1.7-0.2-2.6-0.3c-0.3,0-0.6,0.2-0.6,0.5C450.9,60.5,451.1,60.8,451.5,60.8z
   M516.9,60.1c3.7,3.4,6.1,8.4,8.6,13.3c1.3,2.6,2.5,5.1,3.9,7.3c2.6,4.2,5,8.2,7,12.2c-0.2,0.3-0.4,0.6-0.6,0.9
  c-0.3-0.4-0.6-0.7-0.9-1c-2.3-2.6-5.7-5-9.3-6.9c-2.7-5.5-6-10.1-9.9-14c-1.5-1.4-2.8-2.9-4.2-4.4c1.7-1.7,3.2-4,4.7-6.3
  C516.5,60.9,516.7,60.5,516.9,60.1z M542.9,121.2c1.5,3.1,2.9,6.3,4.1,9.7c-0.8,0.6-1.7,1-2.7,1.4c-0.5-1.5-1-3-1.5-4.5
  c0-1.2,0.1-2.4,0.1-3.5C542.9,123.2,542.9,122.2,542.9,121.2z M559.4,200.9c0.1,0.5,0.2,1,0.3,1.5c0.3,8.9,0.1,17.6-0.4,26
  c-0.4-0.8-0.7-1.7-1.1-2.5c-6.6-15-13.3-30.3-16.3-47c0.5-1.6,1-3.1,1.4-4.6c3.2,2.8,6.1,5.7,8.8,8.9c1.9,2.3,3.6,4.7,5.1,7
  c0.6,3,1.2,6,1.8,8.9c-4-3-6.1-8.1-8.1-13l-1.1,0.4C552,191.9,554.4,197.7,559.4,200.9z M571.8,278.8c-0.3-0.1-0.6,0-0.8,0.3
  c-2.5,5.4-3.2,9.7,0.9,13.7c-0.7,3.6-1.7,7.3-3.1,11.1c-1-13.2-3.2-26.1-6.5-38.8c1.2-6,2.4-12,3.4-18.1
  c5.5,14.5,8.9,29.1,6.4,44.4c-3-3.4-2.3-6.9-0.1-11.8C572.2,279.2,572.1,278.9,571.8,278.8z M561.7,317.9c-1.7,2.8-3.7,5.5-5.9,8.4
  c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1-15.6,1.3-31.6,4.4-48.3c0.7-3.7,1.4-7.3,2.1-11c3,12.2,5,24.6,5.9,37.2c-0.4,0.2-0.8,0.3-1.2,0.5
  c-2.5,1-5.4,2.1-6.1,4.2C559.8,311.6,560.5,314.7,561.7,317.9z M555.9,341.8c-0.3-4.3-0.5-8.7-0.6-13c0.5-0.6,0.9-1.1,1.4-1.7
  c2.1-2.6,3.9-5.2,5.5-7.8c1.2,2.8,2.7,5.6,4,8c0.4,0.8,0.8,1.6,1.2,2.2C563.3,333.5,559.5,337.5,555.9,341.8z M567.9,330.6
  c-1,13.3-3.9,24.9-8.9,34.8c-1.3-7.3-2.3-14.6-2.9-22C559.8,339,563.7,334.7,567.9,330.6z M554.5,373c-4.2-0.5-8.4-1.3-12.2-2.2
  c1.7-1.6,3.3-3.1,4.5-4.1c0.2-0.2,0.3-0.6,0.1-0.8c-0.2-0.2-0.6-0.3-0.8-0.1c-1.4,1.2-3.3,2.9-5.1,4.7c-1.1-0.2-2.1-0.5-3.1-0.7
  c-0.4-0.1-0.8-0.2-1.3-0.3c4.7-6.8,9.4-13.5,14.6-20.1c2.5,7,4.3,14,5,21C555.6,371.4,555.1,372.2,554.5,373z M556.3,372.4
  c0,0.3,0,0.5,0.1,0.8c-0.2,0-0.4,0-0.5,0C556,372.9,556.2,372.7,556.3,372.4z M553.8,374.1c-4.1,5.8-9,10.9-14.8,15.3
  c-0.3-0.4-0.6-0.7-0.8-1.1c-1.7-2.1-3.2-4.1-2.9-6.8c0.4-3.1,3.2-6.7,6.2-9.7C545.2,372.7,549.5,373.6,553.8,374.1z M463,416.5
  c-7.4,1.1-14.7,2.3-21.9,3.5c-2.5,0.4-5.1,0.8-7.7,1.2c2.5-1.5,4.9-3,7.2-4.5c8.2-0.7,16.4-1.4,24.4-2.3c-0.2,0.3-0.3,0.7-0.5,1
  C464,415.8,463.5,416.1,463,416.5z M463.1,417.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.1-0.7,0.3-1,0.4
  C462.2,418.4,462.6,418.1,463.1,417.8z M434.4,435.1c-0.4,0.2-0.8,0.5-1.2,0.7c0.2-0.3,0.4-0.6,0.5-0.9
  C433.9,435,434.1,435.1,434.4,435.1z M412.8,428.7c-1.5-0.4-3-0.9-4.5-1.3c-0.5-0.3-1-0.6-1.4-0.9c1.2-0.2,2.4-0.4,3.6-0.5
  c5.7-0.8,11.4-1.7,17.1-2.6c-1,0.6-2,1.1-3,1.7c-2,1.1-4,2.2-6.1,3.3c-0.1,0-0.2,0-0.4,0C416.4,428.5,414.6,428.6,412.8,428.7z
   M417.7,431.2c-0.5,0.2-0.9,0.4-1.4,0.6c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.5-0.3-0.7-0.4c0.4-0.2,0.7-0.4,1.1-0.6
  C416.8,431,417.2,431.1,417.7,431.2z M384.3,442.3c-0.6-1.3-1.5-2.5-2.4-3.4c5.1-2.8,10-5.8,14.8-9c2.8,0.5,5.7,1,8.5,1.5
  c1.8,0.3,3.7,0.6,5.7,0.9c-8.2,4.2-16.9,8.2-25.8,11.9C384.9,443.6,384.6,442.9,384.3,442.3z M265.6,430.2
  c-4.7-0.8-9.3,0.8-12.8,4.5c-0.2-0.1-0.4-0.2-0.6-0.2c-9.4-3.9-18.9-7.9-29-10.5c-0.6-0.5-1.3-1.1-1.9-1.6
  c-3.7-3.2-7.6-6.4-11.1-10.1c12.5,2.1,25,3.4,37.5,3.6c9.7,8,21.8,12.6,35.6,15.8c-9.1,1.8-18.3,3.1-27.4,3.1c-0.5,0-1,0-1.5,0
  c3.1-2.8,7-4.1,11-3.4c0.3,0.1,0.6-0.2,0.7-0.5C266.2,430.6,265.9,430.3,265.6,430.2z M170.1,425.7c0.6-0.8,1-1.6,1.5-2.5
  c0.1-0.1,0.2-0.3,0.2-0.4c0.6,1.1,1.2,2.2,1.9,3.3C172.6,425.9,171.4,425.8,170.1,425.7z M174.5,427.4c0.3,0.5,0.6,0.9,0.9,1.4
  c-1.4-0.6-2.8-1.2-4.2-1.8C172.3,427.1,173.5,427.2,174.5,427.4z M167.6,425.4c-1.8-0.8-3.7-1.6-5.6-2.5c-0.6-0.5-1.2-1-1.8-1.4
  c3.7,0,7.4,0,11.1,0c0,0,0,0,0,0.1c-0.2,0.4-0.4,0.7-0.6,1.1c-0.6,1.1-1.2,2-1.9,2.9C168.4,425.5,168,425.4,167.6,425.4z
   M167.7,426.7c-0.2,0.1-0.4,0.3-0.6,0.4c-0.3-0.2-0.5-0.5-0.8-0.7c0.3,0,0.7,0.1,1,0.1C167.4,426.6,167.5,426.6,167.7,426.7z
   M125.2,416.8c-0.3-0.6-0.7-1.3-1.1-2c1.6-0.1,3.1-0.2,4.7-0.4c4.1,0.6,8.1,1.3,12.2,2.2c1.2,1.1,2.4,2.1,3.7,3.2
  C138.1,419.3,131.5,418.4,125.2,416.8z M114.5,404.1c-1.7-0.7-3.5-1.1-5.4-1.1c-2.5-6-4.4-12.4-6.2-18.6c-0.4-1.5-0.9-2.9-1.3-4.4
  c2.6,1,5.1,1.9,7.5,2.7l0.5,0.2c1.3,0.5,2.6,0.9,4,1.4c6.8,10.4,14.5,19.7,22.8,27.8c-2.5,0.4-5,0.8-7.6,1c-2-0.3-4-0.5-6-0.8
  C120.8,409.1,118,405.7,114.5,404.1z M108,410.3c-2.3-1.3-4.5-2.9-6.7-4.6c2.4-0.8,4.8-1.3,7.1-1.4c1,2.3,2.1,4.5,3.3,6.6
  C110.4,410.7,109.2,410.5,108,410.3z M112.4,412.2c0.1,0.2,0.2,0.4,0.3,0.5c-0.5-0.2-1-0.5-1.5-0.7
  C111.6,412.1,112,412.1,112.4,412.2z M104.8,409.7c-2.8-0.6-5.5-1.3-8.2-2.1c1.1-0.5,2.3-0.9,3.5-1.4
  C101.5,407.4,103.1,408.6,104.8,409.7z M100.2,404.8c-10.1-8.4-18.3-20.9-23.4-35.8c0.1,0,0.2,0,0.3,0c2,1.1,4.1,2.1,6.1,3.1
  c5.2,16.1,12.4,24.6,21.4,25.1c0,0,0,0,0,0c0.3,0,0.6-0.2,0.6-0.5c0-0.3-0.2-0.6-0.5-0.6c-9.4-0.5-15.7-10.4-20-23.2
  c5.3,2.5,10.6,4.7,15.6,6.6c0.5,1.8,1,3.5,1.5,5.3c1.8,6.1,3.6,12.4,6.1,18.3C105.4,403.3,102.7,403.9,100.2,404.8z M60.5,290.1
  l0.1,0.7c0.7,3.9,1.3,7.8,1.9,11.8c-1.3-1.8-2.6-3.6-3.9-5.3C59.3,294.9,59.9,292.5,60.5,290.1z M57.8,296
  c-0.9-1.2-1.8-2.3-2.7-3.4c1.3-2.4,3-4,4.8-5.7l0,0.3C59.3,290.2,58.6,293.2,57.8,296z M60.3,282c0.3-0.4,0.7-0.9,1-1.3
  c0,0,0,0.1,0,0.1c-0.2,1.1-0.4,2.2-0.7,3.3l0-0.2C60.5,283.3,60.4,282.6,60.3,282z M61.8,290.6l-0.6-3.4c0.1-0.6,0.3-1.2,0.4-1.9
  c1.2-1.2,2.5-2.6,3.6-4.4c2.1-3.4,3.5-6.9,4.6-10.5c2.9-3.5,5.7-7.2,8.4-10.9c-0.1,4.4,0,8.8,0.2,13.3
  c-6.1,10.1-11.1,20.4-14.3,31.2C63.3,299.5,62.6,295,61.8,290.6z M62,283.2c0.1-0.7,0.3-1.4,0.4-2.2c0.1-0.7,0.2-1.4,0.4-2.2
  c1.2-1.5,2.5-3,3.7-4.5c0.4-0.5,0.8-1,1.2-1.4c-0.9,2.5-2,5-3.5,7.4C63.5,281.4,62.8,282.3,62,283.2z M181.6,95.6
  c-0.5,0.3-1.1,0.5-1.6,0.8c3.4-4.1,6.9-8.1,10.7-12c0,1.4-0.4,2.7-1,4C187,90.8,184.3,93.2,181.6,95.6z M188.2,91.2
  C188.2,91.3,188.2,91.3,188.2,91.2c-0.3,0.6-0.6,1.1-0.8,1.6c-0.6,0.3-1.3,0.6-1.9,0.9C186.4,92.9,187.3,92.1,188.2,91.2z
   M182.2,96.6c1.5-0.7,3-1.5,4.4-2.1c-0.3,0.6-0.5,1.2-0.7,1.8c-0.3,1-0.6,2-0.9,2.8c-2.3,0.5-4.6,0.8-7,1
  C179.5,98.9,180.9,97.8,182.2,96.6z M187.1,96.6c0.3-1,0.7-1.9,1.2-2.9c5.4-2.4,10.7-4.3,16.1-5.8c-0.7,1.3-1.5,2.7-2.4,4.1
  c-4.7,3-9.7,5.5-15.5,6.8C186.7,98.1,186.9,97.4,187.1,96.6z M301.3,59.2c0,0.1,0,0.2,0,0.4c-1.8-0.2-3.5-0.4-5.3-0.6
  c0.2-0.2,0.4-0.3,0.7-0.5C298.2,58.8,299.7,59,301.3,59.2z M297.9,57.6c1.1-0.9,2.3-1.8,3.5-2.6c-0.1,1-0.1,2.1-0.1,3.1
  C300.2,57.9,299,57.8,297.9,57.6z M301.3,60.8c0.1,1.7,0.2,3.5,0.3,5.2c0,0.7,0.1,1.4,0.1,2.1c-3.4-0.7-7.2-0.7-9.6-0.8l-0.2,0
  c-1.9,0-3.9-0.1-6-0.1c2.9-2.4,5.8-4.7,8.8-7C296.9,60.3,299.1,60.6,301.3,60.8z M374.3,49.8c2.1,0.2,4.1,0.5,6.1,1l0.2,0.1
  c0.3,1.1,0.4,2.3,0.5,3.5c-1.6,0.3-3.2,0.6-4.7,0.9l-0.2-0.4C375.7,53.4,375,51.7,374.3,49.8z M375.8,56.6c-0.3,0-0.5,0-0.8,0
  c0.2,0,0.5-0.1,0.7-0.1C375.7,56.5,375.8,56.6,375.8,56.6z M372.2,41.4c1,0.5,2,1.1,2.9,1.7c-0.6,0.5-1.2,0.9-1.6,1.2l-0.2,0.2
  c-0.2,0.1-0.3,0.3-0.5,0.4C372.6,43.7,372.3,42.5,372.2,41.4z M372.7,48.6c-0.1,0-0.1,0-0.2,0C372.6,48.6,372.6,48.5,372.7,48.6
  C372.7,48.5,372.7,48.6,372.7,48.6z M374.3,41.3c1.1,0,2.2,0,3.3,0c-0.5,0.4-1,0.8-1.5,1.2C375.5,42,374.9,41.6,374.3,41.3z
   M374.1,45.4l0.2-0.2c0.4-0.3,1-0.8,1.6-1.2c0.1,0,0.1-0.1,0.2-0.1c0.7,0.5,1.3,1.1,1.8,1.7c-1.5,0.5-3,1-4.5,1.5
  c-0.1-0.3-0.2-0.6-0.3-0.9C373.5,45.9,373.8,45.6,374.1,45.4z M378.7,46.5c0,0,0.1,0.1,0.1,0.1c0.7,0.9,1.1,1.9,1.5,3
  c-2.1-0.5-4.2-0.8-6.4-1c-0.1-0.2-0.1-0.3-0.2-0.5C375.4,47.6,377.1,47,378.7,46.5z M381.2,55.5c0,0.5,0,0.9,0.1,1.4c0,0,0,0,0,0
  c-1.4-0.1-2.8-0.2-4.3-0.2c-0.1-0.1-0.1-0.3-0.2-0.4C378.3,56,379.8,55.7,381.2,55.5z M381.4,58.1c0.3,4.9,1,9.7,7.6,11.9
  c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3-0.1-0.6-0.4-0.7c-5.9-2-6.5-6-6.8-10.8c0.1,0,0.2,0,0.2,0c4.2,0.3,9.5,0.8,15.4,1.6
  c2.3,10,5.9,20.1,10.2,30.1c-3,0.2-6.1,0.5-9.3,0.9c-1.4-0.3-2.9-0.6-4.3-0.9c-2.4-5.3-5.2-10.5-8.1-15.5c-0.6-1.1-1.3-2.2-1.9-3.3
  c-2.5-4.3-5-8.6-7.1-13.2C378.8,57.9,380.1,58,381.4,58.1z M382.3,54.1c-0.1-1-0.2-1.9-0.4-2.9l0,0c2.8,0.7,5.3,1.4,7.9,1.7
  C387.4,53.3,384.8,53.7,382.3,54.1z M396.8,53.1c0.3,1.8,0.7,3.6,1.1,5.4c-5.8-0.7-10.9-1.2-15-1.5c-0.1,0-0.3,0-0.4,0
  c0,0,0-0.1,0-0.1c0-0.5,0-1-0.1-1.5C387.2,54.5,392,53.7,396.8,53.1z M430.6,69.6c1.9,1.1,3.6,2.1,5.2,3.2c0.3,5.6,0.4,11.2,0,16.8
  c-8.1-0.6-16.8-0.6-26.3,0.1c-4.3-9.9-7.9-20-10.2-29.9c10.6,1.4,23.2,3.7,36.1,7.3c0.1,1.4,0.2,2.8,0.3,4.2
  c-1.5-0.9-3-1.8-4.6-2.8c-0.3-0.2-0.6-0.1-0.8,0.2C430.2,69.1,430.3,69.5,430.6,69.6z M488,99.5c-1.5-1.6-5.9-6.5-6.4-11
  c4.2,3.1,8.1,6.6,11.7,10.4c-0.8,5.7-1.5,11.4-2,17.2c-1.2-1.7-2.4-3.3-3.8-4.9c-6.4-7.2-14.9-12.7-25.4-16.4
  c-7.8-9.1-15.6-18-23.4-26.7c14.6,4.4,29.4,10.6,41.7,19.5c-0.1,5,4.5,10.5,6.7,12.7c0.2,0.2,0.6,0.2,0.8,0
  C488.3,100.1,488.3,99.7,488,99.5z M541.2,181.2c3.1,15.9,9.6,30.7,16,45.1c0.7,1.5,1.3,3.1,2,4.6c-0.2,3.2-0.5,6.4-0.8,9.5
  c-0.9,0.8-1.8,1.6-2.7,2.5c-2.7-7.6-5.7-15.1-9-22.5c0.7-1.9,1.2-3.9,1.3-6c1.3,2.9,2.8,5.7,4.3,8.6c0.1,0.2,0.3,0.3,0.5,0.3
  c0.1,0,0.2,0,0.3-0.1c0.3-0.2,0.4-0.5,0.2-0.8c-2-3.7-3.8-7.3-5.4-11c-0.1-1.5-0.4-3.1-0.9-4.7c-1.1-3.7-2.8-6.9-4.8-10
  c-1.1-3.1-2-6.3-3-9.4C539.9,185.2,540.6,183.2,541.2,181.2z M541,172.3c0.5,0.4,1,0.8,1.4,1.2c-0.3,1-0.6,2-0.9,2.9
  C541.4,175,541.2,173.7,541,172.3z M565,244.7c-0.9,5.8-2,11.7-3.2,17.7c-1.1-4-2.2-8-3.5-11.9c0.5-3.1,0.9-6.2,1.2-9.5
  c1-0.8,2-1.6,3-2.4C563.3,240.6,564.2,242.7,565,244.7z M544.8,331.7c-1.4-2.8-2.8-5.5-4.2-8.2c0.6-2,1.2-4,1.9-6
  c0.5-0.4,0.9-0.9,1.4-1.4c7.5-8.5,8.2-16.6,5.8-24.4c0.6-2.4,1.2-4.8,1.8-7.2C552.1,300.8,549.9,316.5,544.8,331.7z M545,334.8
  c0.5,1,1,2.1,1.5,3.1c-1.7,2-3.4,4-5.1,6c0.2-0.4,0.4-0.8,0.5-1.2C543,340,544,337.4,545,334.8z M519.9,367.1
  c-3.6-0.2-7.1-0.4-10.6-0.4c0.6-5.8,1-11.7,1.2-17.7c0.8-1,1.5-2,2.2-3.1c5.5-8.3,8.6-18.3,10.4-29.1c0.5,2.5,1.1,5.1,1.7,7.9
  c0.4,1.7,0.8,3.5,1.2,5.3c-0.3,1.2-0.6,2.5-0.8,4.1c-0.7,6,0.9,12.3,4.5,17.8c-1,2.8-2.1,5.6-3.2,8.4
  C524.3,362.7,522.1,364.9,519.9,367.1z M525.3,363.2c-0.5,1.4-1.1,2.8-1.7,4.2c-0.7-0.1-1.5-0.1-2.2-0.2
  C522.7,365.9,524,364.6,525.3,363.2z M425.6,427.7c-1.4,0.2-2.9,0.3-4.3,0.4c1.3-0.7,2.5-1.4,3.8-2.1c1.9-1.1,3.8-2.2,5.6-3.2
  c3.5-0.6,7-1.1,10.5-1.7c0.8-0.1,1.5-0.2,2.3-0.4C437.5,422.8,431.5,425.1,425.6,427.7z M450.4,419.7c0.1,0,0.2-0.1,0.3-0.2
  c3.4-0.5,6.8-1.1,10.2-1.6c-1.2,0.8-2.4,1.6-3.5,2.5c-8.8,3.1-18,5.4-27.4,6.7C436.7,424.3,443.5,421.8,450.4,419.7z M430.3,421.7
  c-6.7,1.1-13.4,2.1-20,3c-1.6,0.2-3.2,0.5-4.9,0.7c-0.4-0.3-0.8-0.5-1.2-0.8c2.5-1.8,5.1-3.7,7.6-5.7c0.6-0.1,1.1-0.1,1.7-0.2
  c5.9-0.5,12.1-1,18.1-1.5c2.2-0.2,4.4-0.3,6.5-0.5C435.6,418.5,433,420.1,430.3,421.7z M398.6,428.6c0.6-0.4,1.2-0.8,1.8-1.2
  c0.9-0.1,1.9-0.3,2.8-0.4c1.5,0.5,3.1,1,4.6,1.4c0.2,0.1,0.4,0.3,0.6,0.4C405.2,428.8,401.9,428.7,398.6,428.6z M410.2,429.9
  c0.8,0.5,1.7,1,2.5,1.5c0,0,0,0,0,0c-2.6-0.4-5-0.8-7.3-1.1c-0.7-0.1-1.4-0.2-2.1-0.4c1,0,1.9,0,2.9,0
  C407.6,429.9,408.9,429.9,410.2,429.9z M393.8,427.3c-0.8,0.1-1.7,0.3-2.6,0.4c-1.6-0.3-3.2-0.6-4.8-1c-3.5-0.7-7-1.4-10.5-2
  c4.2-0.9,8.4-1.7,12.6-2.4c0.9,0.3,1.7,0.5,2.6,0.8l2.9,0.9c2.1,0.7,4.2,1.4,6.3,2c-0.1,0.1-0.2,0.2-0.3,0.2
  C398,426.6,395.9,426.9,393.8,427.3z M397.8,427.8c-0.4,0.2-0.7,0.5-1.1,0.7c-0.5,0-0.9-0.1-1.4-0.1c-0.2,0-0.5-0.1-0.7-0.1
  C395.6,428.1,396.7,428,397.8,427.8z M355.2,435.4c-1.3-0.4-2.6-0.9-3.9-1.5c0.1,0,0.2-0.1,0.3-0.1c2.3-0.2,4.5-0.4,6.8-0.6
  C357.3,434,356.3,434.7,355.2,435.4z M83.2,367.9c-4-14.3-5.9-31-6.7-42.2c0.4,0.6,0.8,1.2,1.2,1.8c4.5,7.1,9.1,14.3,13.9,21.3
  c1.8,8.5,4.4,17.1,7.3,26.1c0.3,1,0.7,2.1,1,3.1c-5.2-2-10.5-4.3-15.6-6.8c-0.3-1-0.6-1.9-0.9-2.9c0,0,0-0.1,0-0.1
  C83.3,368.1,83.3,368,83.2,367.9z M82.7,370.5c-1.1-0.5-2.1-1.1-3.1-1.6c0.9,0,1.8-0.1,2.7-0.1C82.4,369.4,82.5,370,82.7,370.5z
   M83.7,266.3c5.3-8.2,11.3-16.4,17.6-24.7c0.2-0.3,0.1-0.6-0.1-0.8c-0.3-0.2-0.6-0.1-0.8,0.1c-5.2,6.9-10.2,13.7-14.8,20.5
  c3.7-9.3,8.6-18.4,14.5-27.5c0.9-1.4,1.8-2.8,2.7-4.2c0,1.4,0.5,2.8,1,4.2c0.7,2,1.5,4.3,1.2,7.1c-0.2,1.8-1.5,3.5-2.8,5
  c-0.7,0.8-1.4,1.7-1.9,2.6c-1.5,2.5-2.5,5.3-3.6,7.9c-0.3,0.8-0.6,1.6-0.9,2.4c-2.9,7.2-6,15.1-10,24.8l-0.1,0.4
  c-1.1-2.4-0.6-4.9,0.1-7.6c0.5-2,0.9-4.2,0.8-6.4c0-0.3-0.3-0.6-0.6-0.5c-0.3,0-0.6,0.3-0.5,0.6c0.2,2.1-0.3,4.1-0.8,6.1
  c-0.7,3.1-1.4,6.2,0.5,9.2c-1.2,2.9-2.4,5.9-3.6,8.9c-0.7-5.2-1.3-10.3-1.7-15.5C80.9,274.7,82.2,270.5,83.7,266.3z M79.6,275.3
  c0-0.8-0.1-1.5-0.1-2.3c0.5-0.9,1.1-1.7,1.6-2.6C80.6,272.1,80.1,273.7,79.6,275.3z M108.4,222.7c-0.2-0.3-0.5-0.3-0.8-0.1
  c-0.1,0-0.1,0.1-0.2,0.1c1.4-2.2,2.9-4.5,4.4-6.9c1.2,14.4,1.5,28.4-2.1,42c-1,3.9-1.9,7.7-2.5,11.3c-3.5,6.2-6.9,12.6-9.8,19.2
  c-0.4,0.9-0.7,1.8-1.1,2.7c-0.5-0.2-1-0.4-1.6-0.6c-2.4-0.9-4.7-1.8-6.4-3.2c-0.8-0.6-1.4-1.2-1.8-1.9c0,0,0,0,0,0l0.5-1.1
  c3.9-9.7,7.1-17.6,10-24.8c0.3-0.8,0.6-1.6,0.9-2.4c1-2.6,2-5.3,3.5-7.8c0.5-0.8,1.1-1.6,1.8-2.4c1.4-1.7,2.8-3.4,3.1-5.6
  c0.3-3-0.5-5.5-1.3-7.6c-1.3-3.6-2.2-6.2,3.3-10.1C108.5,223.3,108.6,223,108.4,222.7z M127,206.4c-0.1-4.3-0.3-8.8-0.2-13.6
  c0-0.5,0-1.1,0.1-1.6c1.1-1.8,2.2-3.6,3.2-5.5c1.2-0.6,2.3-1.3,3.4-1.9c1.3,5.2,3,10.4,4.8,15.6c-1.1,18.2,0.2,35.3,3.8,51.1
  c0.6,2.7,1.3,5.3,2.1,7.9c-1.4,1.4-2.8,2.9-4.3,4.4c-19.4,19.5-19.6,38.2-19,59.7c-3-6.3-6.4-12.6-10.5-18.5
  c-0.8-1.2-1.7-2.3-2.5-3.4c-2-9.2-1.9-19.2,0.2-31c0.7-1.3,1.4-2.5,2.1-3.7c4-7.1,8.1-14.3,11.4-21.9
  C127.7,230.4,127.4,219.3,127,206.4z M236.7,70.3c3-1.5,5.9-2.9,8.7-4.3c2.1-0.3,4.3-0.5,6.8-0.5c4.2,0,8.3,0.3,12.5,0.8
  c-0.7,2.7-1.4,5.5-2.1,8.4c-0.6,2.5-1.2,5-1.9,7.5c-12.7-0.8-25.3-0.8-37.8,0.9c1.9-2.6,3.8-5.2,5.7-7.8c0-0.1,0.1-0.1,0.1-0.2
  C231.1,73.3,233.7,71.8,236.7,70.3z M230.6,72.4c0.3-0.4,0.7-0.9,1-1.3c2.8-1.6,5.6-2.9,8.8-3.9c-1.4,0.7-2.8,1.4-4.2,2.1
  C234.1,70.2,232.3,71.3,230.6,72.4z M400.6,115.5c1.7,31.3,7.8,47.5,28.5,75c1.3,1.8,2.7,3.6,4,5.3c3.2,4.3,6.4,8.4,9.4,12.5
  C423.3,250,380.4,296.7,337,322.8c-10,6-20.5,10.4-30.7,14.7c-6.9,2.9-14,5.9-20.9,9.3c0,0,0,0,0,0c-9.7-6.2-20.8-6.3-31.5-6.5
  c-10.3-0.2-20.1-0.3-28.8-5.3c0-0.7,0.1-1.4,0.1-2.2c0.8-20,1.6-40.7-6.9-60.8c-2.9-6.7-5.8-13.3-8.8-19.8
  c2.7-15.4,12.4-22.9,21.7-30.2c9.1-7.1,18.5-14.4,21.4-28.8c1.2-5.8,1.2-11.7,1.2-17.3c0-9.7,0.1-18.9,5.6-27.9
  c5.8-9.5,14.2-13.5,23.2-17.7c4.4-2.1,8.9-4.2,13.2-7.1c5.6-3.7,9.9-8.7,13.6-14.2c2.1-0.5,4.2-0.9,6.3-1.4
  c23.7-5.2,48.3-10.7,72.6-14.4c2.2-0.3,4.3-0.6,6.4-0.9C397.9,99.9,400.2,107.7,400.6,115.5z M395.3,91.1
  C395.3,91.1,395.4,91.1,395.3,91.1C395.4,91.1,395.3,91.1,395.3,91.1C395.3,91.1,395.3,91.1,395.3,91.1z M496.2,88.8
  c1.1,4.8,1.9,9.5,2.4,14.5c-1.3-1.7-2.7-3.3-4.1-4.8C495,95.3,495.6,92,496.2,88.8z M496.1,83.6c-0.3-1.1-0.6-2.2-0.9-3.3
  c0.6,0,1.1,0,1.7,0C496.6,81.4,496.4,82.5,496.1,83.6z M544.6,267.9l1.2,0c-0.2-4,0.3-7.6,1.6-11c1.9,8,3.1,15.9,3.7,23.7
  c-0.7,3.1-1.4,6.1-2.2,9.2c-0.8-2.1-1.7-4.1-2.8-6.2c0.7-6.7-3.1-15.4-5.4-20.6l-0.3-0.7c-1-2.3-2-4.6-3.1-6.9
  c0.7-3.3,1.3-6.5,1.8-9.4c0.1-0.3-0.1-0.6-0.5-0.7c-0.3-0.1-0.6,0.1-0.7,0.5c-0.5,2.5-1,5.2-1.5,7.9c-1.5-3.3-3.2-6.7-4.9-10.1
  c1.5-4.2,4.4-7.7,7.4-11.5c0.1-0.1,0.2-0.3,0.3-0.4c3.2,7.8,5.7,15.6,7.6,23.3C545.1,259,544.4,263.2,544.6,267.9z M549,294
  c1.6,6.8,0.6,13.7-5.9,21.2C545.3,308,547.2,301,549,294z M509.4,245.3c-0.3,3.4-0.4,6.8-0.6,10.2c-2.8-14.1-2.9-30.6-2.7-48
  c2.2,2.5,4.3,5.1,6.3,7.8c-0.3,2.6-0.5,5.1-0.8,7.6C510.8,230.8,510,238.3,509.4,245.3z M509.5,320.8c-4.4,6.7-9.2,13-14.4,19.1
  c7.8-14.7,10.4-31,13.1-48.1l0.5-3.1c0.1,4.6,0.2,9.2,0.3,13.8C509.2,308.6,509.4,314.7,509.5,320.8z M507.6,255.2
  c-5-13.5-10.8-26.4-17.3-38.6c0.5-4.3,0.8-8.6,0.9-13.1c0.1-4.1,0.1-8.3,0.1-12.5c4.6,5,9.2,10,13.6,15.2
  C504.8,223.9,504.9,240.7,507.6,255.2z M500.1,107.4c4.6,6.8,7.9,14.4,10.2,22.6c0.4,2.9,0.8,5.8,1.1,8.7c0.4,4.1,0.6,8.3,0.8,13.2
  c-3-1.9-6.1-3.7-9.1-5.7c-1-5.5-2.4-10.7-4.3-15.7C500.2,122.2,500.6,114.6,500.1,107.4z M512.7,140.1c0.8,4.3,1.4,8.7,1.8,13.2
  c-0.4-0.2-0.8-0.5-1.2-0.7c0-0.1,0.1-0.2,0.1-0.3C513.2,147.8,513,143.9,512.7,140.1z M514.6,154.7c0.3,3.1,0.4,6.2,0.5,9.3
  c-3.5-5.2-7.1-10.4-10.7-15.6C507.8,150.6,511.2,152.6,514.6,154.7z M501.6,144.5c-1.4-1.9-2.7-3.7-4.1-5.6
  c0.2-1.7,0.4-3.5,0.8-5.3c0.1-0.3,0.1-0.6,0.2-0.8C499.7,136.5,500.7,140.4,501.6,144.5z M499.7,143.8c-0.8-0.6-1.6-1.1-2.4-1.7
  c0-0.5,0.1-1,0.1-1.5C498.1,141.7,498.9,142.7,499.7,143.8z M501.7,146.6c0.1,0.2,0.3,0.4,0.4,0.6c1.8,9.9,2.5,20.7,2.8,31.6
  c-4.6-11.5-8.1-23-7.8-35.4C498.7,144.5,500.2,145.6,501.7,146.6z M503.7,149.4c3.9,5.5,7.8,11.1,11.5,16.8
  c0.2,11.2-0.4,22.7-1.3,33.8c-1.3-3.1-2.6-6.2-3.9-9.3c-1.3-3-2.6-5.9-3.8-8.9C506,170.6,505.4,159.7,503.7,149.4z M532.8,186.8
  c1.2,1.4,2.5,2.9,3.7,4.3c-0.6,1.6-1.3,3.3-1.9,5c-5.6-10.3-11.7-20.4-18.2-30.3c-0.1-3.5-0.2-6.9-0.5-10.4
  c0.2,0.1,0.3,0.2,0.5,0.3c1.3,0.8,2.5,1.5,3.8,2.3C518.6,170.5,525.5,178.5,532.8,186.8z M537.4,224.3c-1-2.3-2.1-4.3-3.2-6.2
  c-1.6-2.9-3-5.5-3.9-8.4c1.3-3.5,2.7-7,4.2-10.6c0-0.1,0.1-0.2,0.1-0.3c3.8,7.1,7.4,14.3,10.6,21.5c-1.3,3.2-3.4,6.1-5.7,9
  C538.9,227.7,538.2,226,537.4,224.3z M538.8,230.4c-0.2,0.3-0.5,0.6-0.7,0.9c-2.7,3.4-5.5,6.9-7.2,10.8c-2.3-4.3-4.7-8.7-7.4-13
  c1.9-5.8,4-11.7,6.3-17.7c0.9,2.5,2.2,4.9,3.5,7.3c1,1.9,2.1,3.9,3.1,6.1C537.2,226.7,538,228.5,538.8,230.4z M516.4,168
  c6.3,9.7,12.2,19.5,17.6,29.5c-0.2,0.4-0.3,0.8-0.5,1.2c-1.2,3.1-2.4,6.1-3.6,9.1c-0.2-0.9-0.4-1.9-0.4-2.9
  c-0.5-6.2-3.1-7.5-6.9-9.5c-1-0.5-2-1.1-3.2-1.8c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3-0.1,0.6,0.2,0.8c1.2,0.7,2.3,1.3,3.3,1.8
  c3.9,2,5.8,3,6.3,8.6c0.1,1.7,0.5,3.2,0.9,4.7c-2.4,6.2-4.5,12.2-6.5,18.2c-0.2-0.2-0.3-0.5-0.5-0.7c-1.4-8.6-4.2-16.8-7.4-24.7
  C515.8,191.1,516.5,179.4,516.4,168z M506.2,184.9c0.9,2.1,1.8,4.2,2.7,6.3c1.6,3.7,3.2,7.5,4.8,11.3c-0.3,3.7-0.7,7.4-1.1,11
  c-2.1-2.7-4.2-5.3-6.4-7.9c0-0.9,0-1.8,0-2.7C506.2,197,506.2,190.9,506.2,184.9z M514.6,204.9c2.5,6.4,4.6,12.9,6,19.6
  c-0.4-0.7-0.9-1.3-1.3-2c-1.8-2.6-3.7-5.2-5.6-7.7C513.9,211.6,514.3,208.3,514.6,204.9z M512.8,223c0.2-2.1,0.4-4.2,0.7-6.4
  c1.7,2.2,3.3,4.4,4.8,6.6c1,1.4,1.9,2.8,2.8,4.3c0.2,1.2,0.4,2.4,0.5,3.6c-3.9,12.1-7.2,23.8-9.8,35.5c-0.5-1.4-0.9-2.8-1.3-4.2
  c-0.2-0.8-0.4-1.6-0.6-2.5c0.1-4.9,0.4-9.8,0.7-14.6C511.1,238.4,511.9,230.9,512.8,223z M509.8,264.7c0.5,1.5,1,3,1.5,4.5
  c-0.5,2.5-1,4.9-1.5,7.4C509.7,272.6,509.7,268.6,509.8,264.7z M511.9,271.4c3.2,10.1,5.9,20.5,8.2,31.1
  c-2.9,5.7-6.1,11.2-9.6,16.5c-0.1-5.5-0.2-11-0.4-16.5c-0.2-6.6-0.3-13.3-0.4-20C510.4,278.8,511.1,275.1,511.9,271.4z
   M510.6,321.1c3.6-5.4,6.9-11,9.9-16.8c0.5,2.5,1,5,1.5,7.5c0.1,0.6,0.2,1.2,0.3,1.7c-1.6,11.8-4.7,22.8-10.6,31.8
  c-0.4,0.6-0.8,1.1-1.2,1.7C510.8,338.4,510.8,329.8,510.6,321.1z M521.4,302.7c0.8-1.5,1.5-3,2.2-4.5c-0.1,3.9-0.4,7.8-0.8,11.6
  C522.3,307.4,521.9,305,521.4,302.7z M521,300.9c-1.6-7.3-3.4-14.5-5.5-21.6c2.4,5.3,5.1,10.6,8.1,16
  C522.7,297.2,521.9,299,521,300.9z M512.5,268.8c2.5-11.4,5.6-22.9,9.4-34.7c0.1,1.7,0.2,3.5,0.2,5.2c0,7.3,0.4,15.2,0.8,23.6
  c0.5,9.8,1,20.1,0.9,30.2C519.3,284.8,515.3,276.7,512.5,268.8z M523.2,239.2c0-2.7-0.2-5.4-0.5-8c0.1-0.2,0.2-0.5,0.2-0.7
  c2.7,4.3,5.1,8.7,7.3,13c-1,2.8-1.3,5.9-0.7,9.4c0.7,4.1,2.3,8.1,4.4,12.1c-2.4,9.8-5.4,18.9-9.1,27.4c0.1-9.9-0.4-20-0.9-29.7
  C523.6,254.4,523.2,246.5,523.2,239.2z M530.7,252.8c-0.5-2.8-0.3-5.4,0.4-7.7c1.8,3.5,3.4,7,5,10.4c-0.5,2.6-1.1,5.2-1.7,7.8
  C532.6,259.8,531.3,256.3,530.7,252.8z M535.4,263.9c0.5-2.2,1-4.4,1.5-6.6c0.8,1.8,1.6,3.6,2.4,5.4l0.3,0.7c2,4.6,5.3,12,5.4,18.1
  c-1.4-2.5-2.9-4.9-4.4-7.4c-1.9-3.1-3.8-6.2-5.5-9.3C535.2,264.5,535.3,264.2,535.4,263.9z M540.1,230.6c2.2-2.8,4.4-5.7,5.9-8.9
  c3.2,7.2,6.1,14.6,8.7,22c-3.1,3.2-5.4,6.3-7,9.6C545.7,245.8,543.2,238.2,540.1,230.6z M545.9,218.8c-3.3-7.2-6.8-14.3-10.6-21.4
  c0.7-1.8,1.4-3.5,2.1-5.3c1.3,1.6,2.6,3.3,3.8,5.1c1.6,4.8,3.5,9.5,5.6,14.3C546.9,214.2,546.6,216.6,545.9,218.8z M537.8,190.9
  c0.2-0.6,0.5-1.2,0.7-1.8c0.4,1.5,0.9,2.9,1.4,4.4C539.2,192.6,538.5,191.7,537.8,190.9z M536.9,189.9c-1.1-1.3-2.2-2.6-3.3-3.8
  c-7-8-13.6-15.6-12.5-27.4c3.8,2.3,7.5,4.7,11.2,7.1c1.7,7.1,3.5,14.2,5.6,21.4C537.6,188.1,537.3,189,536.9,189.9z M521.3,157.4
  c0.3-2.3,0.9-4.7,1.9-7.2c1-2.9,2.2-5.4,3.3-7.7c1.9,7,3.6,13.9,5.3,20.7c0.1,0.3,0.1,0.6,0.2,0.9
  C528.4,161.8,524.9,159.6,521.3,157.4z M522.1,149.8c-0.9,2.5-1.5,4.8-1.8,7c-1.1-0.7-2.2-1.4-3.4-2c-0.4-0.2-0.8-0.5-1.2-0.7
  c-0.7-8.4-2-16.5-4.2-24.2c-2.1-13.6-6.2-26.8-12-38.6c9.6,10.1,16.8,21.1,21,32.4c2.1,5.8,3.9,11.5,5.5,17.2
  C524.7,143.4,523.3,146.3,522.1,149.8z M508.8,121.8c-2.3-5.9-5.2-11.5-8.8-16.6c-0.4-5-1.2-9.9-2.2-14.7
  C502.7,100.1,506.4,110.7,508.8,121.8z M497.8,104.1c0.4,0.5,0.7,1,1.1,1.5c0.6,7.2,0.4,14.6-0.9,22.8c-1.6-3.8-3.4-7.4-5.6-10.8
  c0.5-5.9,1.1-11.8,1.9-17.6C495.5,101.3,496.7,102.7,497.8,104.1z M497.6,130.6c-0.2,0.9-0.3,1.9-0.5,2.8c-0.3,1.4-0.5,2.8-0.7,4.1
  c-1.6-2.1-3.2-4.3-4.8-6.4c0.2-3.9,0.4-7.7,0.7-11.6C494.4,123,496.2,126.7,497.6,130.6z M496.3,139.2c-0.1,0.7-0.1,1.4-0.2,2
  c-1.6-1.2-3.2-2.5-4.7-3.7c0-1.5,0.1-3.1,0.1-4.6C493.1,135,494.7,137.1,496.3,139.2z M496.1,142.7c-0.5,13.9,3.8,26.9,8.9,39.4
  c0.1,7,0.1,14,0,21c0,0.4,0,0.9,0,1.3c-4.5-5.2-9.1-10.2-13.6-15.1c0-5.3,0-10.6-0.1-15.8c-0.1-11.4-0.2-22.9,0.1-34.4
  C492.9,140.3,494.5,141.5,496.1,142.7z M490.5,129.7c-8.7-11.3-17.7-22.3-26.9-33.1c9.5,3.6,17.2,8.7,23.1,15.4
  c1.6,1.9,3.1,3.9,4.5,5.9C490.9,121.8,490.7,125.7,490.5,129.7z M490.4,131.5c-0.1,1.7-0.1,3.4-0.2,5.1c-5-4.4-9.6-9.3-13.2-15
  c-2.8-4.4-5.2-8.4-7.5-12.2c-2.9-4.9-5.7-9.4-8.6-13.7c0.2,0.1,0.3,0.1,0.5,0.2C471.3,107.4,481.1,119.3,490.4,131.5z M508.7,261.5
  c-0.2,6.9-0.2,13.9-0.1,21c-0.3,1.6-0.6,3.2-0.8,4.8l-0.7,4.4c-3,18.5-5.8,36.2-15.3,51.8c-1.2-2.2-2.5-4.4-3.7-6.7
  c-2.2-3.9-4.6-7.9-6.5-12.4c-5.4-12.4-6.5-25.8-7.7-38.8c-0.3-3.9-0.7-7.8-1.2-11.7c7.7-18,14.7-35.6,17.4-55.3
  C497.2,232.1,503.4,246.5,508.7,261.5z M403.2,424c-1.6-1.1-3.3-2.2-4.9-3.3c3.8-0.6,7.6-1.1,11.3-1.5
  C407.5,420.9,405.4,422.4,403.2,424z M403.7,425.7c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.2
  C403.4,425.5,403.5,425.6,403.7,425.7z M343.3,430.7c-7.1-1.9-16-0.1-19.6,0.8c-0.9,0.2-1.7,0.5-2.6,0.8
  c-6.4-0.7-12.5-1.9-18.3-3.8c15.7-3.6,31.9-7.2,47.5-6.3c4.9,1.3,9.8,2.5,14.7,3.4c-0.1,0-0.2,0.1-0.2,0.1
  C357.8,427.3,350.6,429,343.3,430.7z M360.9,427.9c-3.2,1.6-6.4,3.3-9.6,4.8c-0.8,0.1-1.7,0.1-2.5,0.2c-1.1-0.5-2.3-1-3.5-1.4
  C350.6,430.2,355.8,429,360.9,427.9z M340.5,431.3c-0.7,0.2-1.3,0.3-2,0.5c-1.9,0.5-3.9,0.9-5.8,1.3c-2.9-0.1-5.7-0.2-8.6-0.5
  C327.3,431.8,334.2,430.4,340.5,431.3z M215,385.8c-6.4-0.6-13.1-1.2-19.5-2.4c-0.4-2.9-0.6-5.8-0.5-8.9c5.9-2.2,12-4.1,18.1-4.2
  c0.2,0,0.4,0,0.6,0c4.1,0,7.8,0.6,11.3,1.6c0.6,4.1,1.5,8.2,2.7,12.3c-2.5,0.8-5,1.5-7.4,2.1C218.5,386.1,216.8,385.9,215,385.8z
   M216.7,387.1c-3.2,0.7-6.3,1.3-9.4,1.9c-3.4,0.7-6.8,1.3-10.1,2.1c-0.6-2.1-1.2-4.3-1.5-6.5c6.4,1.2,12.9,1.8,19.2,2.4
  C215.5,387,216.1,387.1,216.7,387.1z M194,370c-0.1,1.2-0.2,2.4-0.2,3.6c-1.5,0.6-3,1.2-4.4,1.7c-3,1.2-6,2.4-9,3.4
  c-10-4.3-18.2-10.8-26-17.5c-0.7-4.8-1.1-9.1-1.3-12.8c-0.8-14.6,0.9-29.8,2.9-44.8c2.6-1.8,5-3.7,7.3-5.8
  c10.7,15.9,24,29.8,37.7,41.8c-0.4,1.4-0.8,2.8-1.2,4.2C197.3,352.4,194.8,361.1,194,370z M194.3,383.1c-1.6-0.3-3.2-0.7-4.7-1.1
  c-2.6-0.7-5.1-1.6-7.5-2.5c2.6-0.9,5.2-2,7.8-3c1.3-0.5,2.6-1.1,4-1.6C193.8,377.7,193.9,380.5,194.3,383.1z M136.7,392.4
  c3.5,2.1,7,4.2,10.6,6.3c4.1,2.4,8.3,4.8,12.3,7.4c-5,1.8-10.2,3.4-15.5,4.6c-1.7-1.1-3.4-2.2-5.1-3.4
  c-10.2-6.8-15.2-13.2-17.4-20.1C126.5,388.9,131.6,390.7,136.7,392.4z M121.2,385.8c-0.4-1.5-0.7-2.9-0.8-4.5
  c3.8,2.9,7.9,5.7,12,8.3C128.6,388.4,124.9,387.1,121.2,385.8z M147.8,397.7c-2-1.2-4-2.4-6-3.6c6.8,2.3,13.8,4.5,20.7,6.6
  c0.4,1.2,0.8,2.4,1.3,3.6c-1,0.4-1.9,0.8-2.9,1.2C156.6,402.8,152.2,400.2,147.8,397.7z M137.2,391.4c-5.9-3.6-11.7-7.4-17-11.6
  c-0.4-5.4,0.2-11.3,0.9-18c0.5-4.7,1-9.5,1.2-14.9c0.2-5.4,0.2-10.5,0.1-15.4c1,1.2,2,2.4,3,3.6c0.3,0.6,0.5,1.1,0.8,1.7
  c2.3,5.1,4.6,10.5,7.2,15.6c4.8,9.8,11.6,23.6,23.6,28.5c1.4,6.1,3.2,12.4,5.2,18.5C153.7,396.9,145.4,394.1,137.2,391.4z
   M119,378.8c-3.5-2.8-6.8-5.8-9.7-9.1c-2.7-3-5.3-6.1-7.8-9.3c-2.8-5.8-5.3-11.9-7.7-18.1c-1.1-3.1-2.2-6.1-3.2-9.3
  c-1-13.4,0.8-26.1,6.4-40.4c1.4,0.6,2.8,1.3,4,2.2c2.2,1.7,4.1,3.8,6,6.2c2.2,10,6.7,19,13.6,28c-1.8,1.6-3.6,2.4-5.4,2.5
  c-2.8,0.1-5.9-1.4-9.4-4.9c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8c3.5,3.5,6.8,5.2,9.8,5.2c0.1,0,0.3,0,0.4,0
  c2.1-0.1,4.1-1,6-2.7c0.1,5.4,0.2,10.9-0.1,16.8c-0.2,5.4-0.7,10.2-1.2,14.9C119.2,367.8,118.7,373.5,119,378.8z M98.2,356.1
  c-1.8-2.5-3.6-5-5.4-7.5c-0.7-3.5-1.3-7-1.7-10.4c0.5,1.5,1.1,3,1.6,4.5C94.4,347.3,96.2,351.7,98.2,356.1z M89.1,328.5
  c-3.2-10.5-5.6-21.2-7.2-31.9c1.4-3.4,2.7-6.7,4.1-10.1c0.4,0.5,1,1,1.6,1.5c1.9,1.5,4.4,2.5,6.7,3.4c0.5,0.2,1,0.4,1.6,0.6
  C90.8,305.3,88.9,317.1,89.1,328.5z M106.4,298.6c-1.5-1.7-3-3.3-4.8-4.7c-1.3-1-2.8-1.7-4.3-2.4c0.4-0.9,0.7-1.8,1.1-2.7
  c2.4-5.6,5.2-11,8.1-16.3C105.1,282.3,105,290.8,106.4,298.6z M108.7,303.5c0.3,0.4,0.5,0.8,0.8,1.2c4.5,6.6,8.2,13.6,11.5,20.7
  c0,0.8,0,1.7,0.1,2.5C115.2,320,111,312.1,108.7,303.5z M133.2,177.4c0.2-0.2,0.4-0.3,0.6-0.5c-0.2,0.3-0.4,0.6-0.5,1
  C133.3,177.7,133.3,177.6,133.2,177.4z M133.3,182.7c-0.7,0.4-1.3,0.7-2,1.1c0.5-0.9,1.1-1.8,1.6-2.8
  C133,181.6,133.2,182.1,133.3,182.7z M136,175.5c1.6-0.9,3.4-1.7,5.4-2.1c-0.3,1.5-0.5,3.1-0.8,4.6c0,0.2-0.1,0.4-0.1,0.6
  c-2,1.1-3.9,2.2-5.9,3.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.9-0.4-1.7-0.6-2.5C134.5,178.2,135.3,176.8,136,175.5z M135.2,182.9
  c1.7-0.9,3.4-1.9,5.1-2.8c-0.8,5.5-1.4,11-1.8,16.3c-1.5-4.4-2.8-8.8-3.9-13.2C134.8,183.1,135,183,135.2,182.9z M139.4,202.2
  c3,8,6.5,16.1,10.7,24.3c2.7,5.3,5.5,10.4,8.5,15.4c-3.5,4.9-7.9,9.9-13.3,15.6c-0.7-2.4-1.4-4.8-1.9-7.2
  C139.9,235.4,138.6,219.3,139.4,202.2z M167.8,163.5c1.4,3.4,2.8,6.8,4.2,10.2c-1.3,1.2-2.5,2.4-3.5,3.7c-0.6-4.5-1.1-9-1.3-13.5
  C167.4,163.8,167.6,163.7,167.8,163.5z M167.1,162.6c0-0.2,0-0.5,0-0.7c0.1,0.2,0.2,0.4,0.2,0.6
  C167.2,162.5,167.2,162.5,167.1,162.6z M240.3,126.7c-0.5,1.1-0.9,2.1-1.3,3.1c-3.9,3.8-8.4,7-12.7,10.2c-6.9,5-14,10.2-19,17.5
  c-0.3,0.4-0.5,0.8-0.8,1.2c-2.3,0.8-4.7,1.6-7,2.3c-5.1,1.6-10.3,3.3-15.3,5.4c-4.6,1.9-8.3,4.1-11.3,6.5
  c-1.4-3.4-2.8-6.7-4.2-10.1c1-0.7,2-1.4,2.9-2.1c12.5-9,24.7-18.7,36.5-28c2.1-1.7,4.2-3.3,6.3-5c9.3-2.3,18.8-4.2,28.5-5.8
  C242,123.4,241.1,125,240.3,126.7z M244.6,121.8c0.4-0.1,0.7-0.1,1.1-0.2c-1.4,2.1-2.8,4.1-4.4,5.8c0-0.1,0.1-0.2,0.1-0.3
  C242.3,125.1,243.4,123.4,244.6,121.8z M238,132.3c-0.4,1.1-0.8,2.1-1.1,3.1c-2.7,7.6-4.1,11.5-16.9,17.9
  c-3.8,1.9-7.7,3.4-11.7,4.8c4.8-7.2,11.9-12.3,18.7-17.2C230.7,138.2,234.5,135.4,238,132.3z M471.3,272.2c0.1,0.5,0.1,0.9,0.2,1.4
  c-0.7,1.7-1.5,3.5-2.2,5.2c-5.8,13.5-11.8,27.4-16.1,42.4c-6.3,21.9-15.3,35.6-27,48c-4,0.3-7.9,0.7-11.8,1.1
  c-24.8,2.4-50.5,4.9-73.4-0.7c-4.4-1.1-10.1-1.3-15.5-1.6c-6.3-0.3-12.8-0.6-17.1-2.1c-5.8-2.1-9.5-6.2-13.3-10.4
  c-2.5-2.8-5.1-5.7-8.5-8c6.7-3.3,13.6-6.2,20.2-9c10.2-4.3,20.8-8.7,30.9-14.8c43.4-26.1,86.3-72.6,105.7-114.3
  C457.2,228.5,468.2,247.1,471.3,272.2z M443.9,208.3c0.4-0.8,0.8-1.6,1.1-2.5c10.2-23.3,6.9-29.9-0.9-46.1c-1.8-3.7-3.9-8-6-13.1
  c-1.1-2.5-1.9-5-2.5-7.4c10.7,16.3,20.8,29.2,26.2,35.9c10.1,12.5,19.3,26.5,27.4,41.6c-2.4,19.6-9.2,37-16.8,54.8
  C469.1,246.1,457.9,227.5,443.9,208.3z M294.1,356.4c3.8,4.2,7.6,8.5,13.8,10.7c4.5,1.6,11.1,1.9,17.4,2.2c5.4,0.2,11,0.5,15.3,1.5
  c23.1,5.6,48.9,3.1,73.8,0.7c3.5-0.3,7-0.7,10.5-1c-5.8,6-12.3,11.8-19.4,18.2c-5.6,5-11.3,10.2-17.5,16.1
  c-1.9,1.8-3.7,3.6-5.5,5.4c-8.7-5.5-17.8-10.3-27.5-12.9c-15-4.1-30.7-4.4-45.8-4.7c-10.7-0.2-21.6-0.4-32.3-2
  c-8.9-4.9-18-10.4-27.1-16.2c4.4-3.1,8.5-6.6,12.5-10c6.9-5.8,14-11.8,22.2-15.9c0.2-0.1,0.5-0.2,0.7-0.4
  C288.8,350.5,291.5,353.5,294.1,356.4z M375.8,418.8c0.5,0.1,1,0.2,1.5,0.4c-1.3,0.7-2.6,1.4-4,2.1
  C374.1,420.5,374.9,419.7,375.8,418.8z M375.4,417.5c-18.4-4.3-35.8-5.7-53.3-5.7c-13.8-5.3-27.9-11.8-42.2-19.6
  c9.8,1.2,19.7,1.4,29.4,1.6c15.1,0.3,30.7,0.6,45.6,4.6c9.5,2.6,18.4,7.3,26.9,12.7c-1.9,2-3.8,3.8-5.5,5.6
  C376,417,375.7,417.3,375.4,417.5z M376.7,417.9c0.1-0.1,0.2-0.3,0.4-0.4c1.8-1.8,3.6-3.7,5.6-5.8c1.4,0.9,2.7,1.7,4,2.6
  c-2.2,1.1-4.3,2.2-6.5,3.3c-0.5,0.2-1,0.5-1.4,0.8C378.1,418.2,377.4,418,376.7,417.9z M248.8,375.1c8.3,5.4,16.6,10.4,24.8,15
  c-7.7-1.4-15.3-3.5-22.5-6.9c-2.9-1.4-5.6-2.8-8.2-4.3C244.9,377.8,246.9,376.5,248.8,375.1z M241.7,378.3
  c-5.2-2.8-10.1-5.6-15.7-7.3c-0.6-4.1-0.9-8.2-1.1-12.3c5.1,3.7,10,7.1,14.7,10.3c2.7,1.9,5.5,3.6,8.2,5.4
  C245.8,375.8,243.8,377.1,241.7,378.3z M284,347.5c-8.2,4.2-15.4,10.2-22.4,16.1c-4.1,3.5-8.4,7-12.8,10.2
  c-2.8-1.8-5.7-3.7-8.6-5.7c-4.9-3.3-10.1-7-15.5-10.9c-0.2-7,0-14,0.2-20.9c8.9,4.8,19,5,28.8,5.1c10.4,0.2,21.1,0.3,30.3,6
  C284.1,347.5,284.1,347.5,284,347.5z M224.8,370.6c-3.6-1-7.4-1.6-11.7-1.5c-6.2,0.1-12.2,1.9-18.1,4.1c0-1,0.1-2,0.2-3.1
  c0.7-8.8,3.3-17.5,5.8-25.9c0.4-1.2,0.7-2.5,1.1-3.7c7.2,6.3,14.5,12,21.6,17.2C223.8,362.1,224.2,366.4,224.8,370.6z M158.2,287.4
  c1-8,2-16.1,2.4-23.9c0.3-5.3,1.2-9.9,2.6-14.2c4,6.2,8.2,12.1,12.6,17.6c0.2,2.8,0.1,5.6-0.4,8.3c-1.6,8.9-6,15.5-11.8,20.9
  C161.6,293.3,159.9,290.4,158.2,287.4z M162.7,296.9c-2,1.8-4.2,3.6-6.5,5.2c0.2-1.6,0.4-3.3,0.7-4.9c0.4-2.6,0.7-5.2,1.1-7.9
  C159.4,291.9,161,294.4,162.7,296.9z M170.7,244.4c2.1,6.7,4.1,13.6,4.8,20.4c-4.2-5.3-8.2-11-12-16.9c1.3-3.8,3.1-7.3,5-10.7
  C169.3,239.6,170,242,170.7,244.4z M167.6,227.8c4.6-12.5,4.2-26.5,1.1-48.8c1-1.4,2.3-2.8,3.7-4.1c4.8,11.2,9.8,22.2,15,33.4
  c-3.1,4.9-6.4,9.4-9.7,13.7c-3.1,4.1-6.1,8.1-8.8,12.3C168.4,232.1,168,229.9,167.6,227.8z M176.4,275.4c0.4-2.3,0.6-4.7,0.5-7
  c0.4,0.5,0.8,1,1.2,1.5c3.2,3.8,7,7.5,10.8,11c6.8,6.4,13.7,13.1,17.4,21.2c0.1,0.2,0.2,0.5,0.3,0.7c-0.2,6.9-0.7,13.9-1.8,20.7
  c-0.8,5-2.1,10-3.5,14.9c-13.5-11.9-26.6-25.7-37.2-41.4C170.1,291.5,174.7,284.7,176.4,275.4z M223.6,356.3
  c-7-5.2-14.2-10.8-21.2-17c1.5-5.1,2.8-10.3,3.7-15.5c1-6,1.5-12.1,1.7-18.1c1,3,1.6,5.8,2.2,8.6c1.5,7.2,2.8,13.5,11.5,19.8
  c0.8,0.6,1.6,1.1,2.4,1.6C223.6,342.5,223.4,349.4,223.6,356.3z M224,332.8c0,0.5,0,1-0.1,1.6c-0.6-0.4-1.2-0.8-1.8-1.2
  c-8.3-6.1-9.6-12.1-11.1-19.1c-0.8-3.6-1.5-7.3-3.3-11.4c0.1-5.5,0.1-11,0.1-16.3c-0.1-10.2-0.1-20.8,1-31.1c0-0.3,0.1-0.6,0.1-0.9
  c2.7,6,5.4,12.1,8.1,18.2C225.6,292.3,224.8,312.9,224,332.8z M207.8,255.1c-1.2,10.4-1.1,21-1,31.3c0,4.6,0,9.3,0,13.9
  c-3.9-7.7-10.5-14-17-20.2c-3.7-3.5-7.5-7.2-10.7-10.9c-0.7-0.9-1.5-1.8-2.2-2.7c-0.5-7.4-2.8-15-5-22.3c-0.9-2.8-1.7-5.6-2.4-8.3
  c2.8-4.6,6-8.8,9.3-13.2c3.1-4.2,6.4-8.5,9.3-13.2c2.2,4.7,4.5,9.4,6.7,14.2c4.4,9.4,9,19,13.4,28.7
  C208,253.3,207.9,254.2,207.8,255.1z M173.3,174c2.9-2.4,6.6-4.6,11.3-6.6c5-2.1,10.2-3.7,15.2-5.4c1.9-0.6,3.8-1.2,5.8-1.9
  c-3.6,6.4-5.4,13.5-7.1,20.4c-1.4,5.6-2.9,11.5-5.3,16.8c-1.5,3.4-3.3,6.6-5.1,9.6C183,196,178,185.1,173.3,174z M167.2,225.2
  c-0.3-2.1-0.6-4.1-1-6.1c-2.6-15.8-4.6-28.8,1.5-38.7C170.5,200.5,170.9,213.7,167.2,225.2z M168.2,235.6c-2.1,3.4-3.9,7-5.4,11
  c-1-1.5-1.9-3.1-2.9-4.7c2.9-4.1,5.2-8.1,6.9-12.1C167.2,231.8,167.6,233.7,168.2,235.6z M162.3,248c-1.6,4.6-2.6,9.7-2.9,15.4
  c-0.4,7.3-1.2,14.8-2.2,22.3c-4.7-8.5-8.7-17.5-11.6-27c5.5-5.7,10-10.9,13.6-15.8C160.2,244.7,161.3,246.4,162.3,248z M157,287.7
  c-0.4,3.1-0.8,6.3-1.3,9.4c-0.3,2-0.5,4-0.8,6c-3.2,2.2-6.4,4.2-9.7,6.3c-8,5-16.4,10.2-22.3,17.3c-0.2-0.5-0.4-1-0.7-1.4
  c0,0,0,0,0-0.1c-0.7-23.2-1.2-41.5,18.6-61.4c1.3-1.4,2.6-2.7,3.9-3.9C147.8,269.6,152,278.9,157,287.7z M151.9,348.5
  c0.2,3.4,0.6,7.3,1.2,11.6c-1.6-1.4-3.2-2.8-4.8-4.2c-8.6-7.7-15.9-14.7-21.9-21.6c-1-2.2-2-4.4-3-6.6c0.1-0.1,0.1-0.1,0.2-0.2
  c5.8-7,14.1-12.2,22.2-17.2c3-1.9,6-3.7,8.9-5.7C152.7,319.2,151.1,334.2,151.9,348.5z M153.3,361.9c0.8,5.4,1.9,11.4,3.3,17.6
  c-11.1-5-17.6-18.3-22.2-27.6c-2.3-4.6-4.4-9.4-6.4-14c5.5,6,12,12.3,19.6,19C149.4,358.5,151.3,360.2,153.3,361.9z M123.7,331.1
  c-0.5-0.6-0.9-1.1-1.4-1.7c0-0.2,0-0.4,0-0.5c0.1-0.1,0.2-0.2,0.3-0.3C123,329.5,123.4,330.3,123.7,331.1z M154.6,363
  c7.3,6.2,15,12.1,24.1,16.3c-4.5,1.4-9,2.3-13.5,2.1c-2.7-0.1-5.1-0.6-7.4-1.5C156.5,374.1,155.4,368.3,154.6,363z M228,385.3
  c0.2,0.6,0.4,1.3,0.6,1.9c-1.7-0.2-3.4-0.4-5.1-0.6C225,386.2,226.5,385.8,228,385.3z M226.2,372.3c5.1,1.6,9.6,4.1,14.3,6.7
  c-1.6,0.8-3.2,1.6-4.8,2.3c-2.3,1-4.6,1.8-6.9,2.6C227.6,380,226.8,376.2,226.2,372.3z M236.2,382.3c1.9-0.8,3.8-1.7,5.6-2.7
  c2.8,1.6,5.7,3.1,8.9,4.6c8.4,4,17.1,6.2,26,7.5c14.4,7.9,28.5,14.6,42.3,20.1c-12.8,0.1-25.8,0.8-39.4,1.6c-0.7,0-1.5,0.1-2.2,0.1
  c-1.6-1.4-3.2-2.9-4.8-4.3c-7.1-6.5-14.4-13.2-24-17c-6-2.4-12.3-3.8-18.6-4.8c-0.3-0.8-0.6-1.6-0.8-2.5
  C231.5,384.2,233.8,383.3,236.2,382.3z M279.6,414.6c14.6-0.8,28.5-1.6,42.2-1.6c8,3.1,16,5.7,23.8,8c-14.9,0-30.1,3.5-44.9,6.9
  c0,0,0,0-0.1,0c-2.8-1-5.5-2.1-8.1-3.4c-5.2-2.6-9.7-6.1-14-9.8C279,414.6,279.3,414.6,279.6,414.6z M358.3,423
  c3.1,0.4,6.2,0.8,9.3,1.4c-0.3,0.2-0.6,0.3-0.9,0.5C363.9,424.3,361.1,423.7,358.3,423z M353.5,421.3c-1-0.1-2-0.2-3-0.2
  c-8.3-2.2-16.8-4.9-25.3-8.1c16.2,0.2,32.2,1.6,49.2,5.5c-1.6,1.6-3.1,3.1-4.6,4.6c-0.2,0.1-0.4,0.2-0.6,0.3
  C364,422.6,358.8,421.8,353.5,421.3z M371.4,423.7c2.6-1.4,5.1-2.7,7.6-4c2.3,0.6,4.6,1.2,7,1.9c-4.3,0.8-8.7,1.6-13.2,2.6
  c-0.6-0.1-1.2-0.2-1.7-0.3C371.2,423.7,371.3,423.7,371.4,423.7z M380.5,418.8c0.1,0,0.2-0.1,0.3-0.2c2.3-1.2,4.7-2.4,7.1-3.6
  c2.4,1.6,4.8,3.2,7.2,4.9c0,0,0.1,0,0.1,0.1c-2.2,0.3-4.4,0.7-6.6,1.1C385.9,420.3,383.2,419.5,380.5,418.8z M396.6,420.9
  c1.9,1.3,3.7,2.5,5.6,3.8c-0.2,0.2-0.4,0.3-0.7,0.5c-2.4-0.8-4.8-1.5-7.2-2.3l-2.9-0.9c-0.1,0-0.3-0.1-0.4-0.1
  C392.9,421.5,394.7,421.2,396.6,420.9z M396.9,419.7c-0.4-0.3-0.7-0.5-1.1-0.8c-2.2-1.5-4.4-3-6.7-4.5c3.6-1.8,7.3-3.7,11.1-5.5
  c20.9-10.4,42.4-21.1,61.1-34.3c-4.1,3.7-8.2,7.5-12.4,11.2l-2.8,2.5c-11.6,10.3-22.9,20.4-34.7,29.6
  C406.6,418.4,401.8,419,396.9,419.7z M399.6,407.9c-4,2-7.9,3.9-11.7,5.8c-1.5-1-2.9-1.9-4.4-2.8c1.7-1.7,3.4-3.4,5.2-5.2
  c6.2-5.9,11.9-11.1,17.5-16.1c7.3-6.6,14.1-12.7,20.3-19.2c11.7-1,23.5-1.8,34.6-1.4c1.9,0.1,3.7,0.1,5.6,0.1
  C446.8,384.4,422.9,396.3,399.6,407.9z M461.3,367.8c-10.8-0.4-22.2,0.3-33.5,1.3c11.3-12.2,20.3-26.1,26.5-47.6
  c4.3-14.9,10.3-28.8,16.1-42.2c0.5-1.1,1-2.2,1.4-3.3c0.3,3.2,0.6,6.5,0.9,9.7c1.2,13.1,2.3,26.6,7.8,39.1
  c1.9,4.5,4.3,8.6,6.6,12.5c1.4,2.4,2.8,4.8,4,7.2c-0.1,0.1-0.1,0.2-0.2,0.3c-6.8,7.8-14.2,15.3-21.9,22.6c-0.2,0.2-0.4,0.3-0.6,0.5
  C465.9,368,463.6,367.9,461.3,367.8z M490.1,203.4c-0.1,3.9-0.3,7.7-0.7,11.4c-7.9-14.6-16.9-28.2-26.7-40.3
  c-5.7-7-16.5-21-27.8-38.4c-1.5-7.8-1.1-15.6-0.2-23.5c11.3,11.4,19.9,25.4,28.3,39c7.7,12.6,15.8,25.7,25.9,36.7l0.3,0.3
  c0.4,0.4,0.7,0.8,1.1,1.1C490.2,194.2,490.2,198.8,490.1,203.4z M434.3,104.8c-0.2,1.7-0.4,3.5-0.6,5.2c-1-1-2.1-2-3.2-2.9
  c-6.1-5.4-12.3-9.3-18.7-12.1c-0.6-1.4-1.2-2.7-1.8-4.1c9.3-0.6,17.9-0.6,25.8,0C435.5,95.6,434.9,100.3,434.3,104.8z M433.3,133.6
  c-7.2-11.3-14.5-23.9-20.6-37c5.8,2.7,11.5,6.4,17.1,11.3c1.3,1.1,2.5,2.3,3.8,3.5C432.7,118.9,432.3,126.2,433.3,133.6z
   M410.3,94.3c-2.7-1.1-5.3-2-8-2.8c2.2-0.2,4.4-0.4,6.5-0.6C409.3,92.1,409.8,93.2,410.3,94.3z M411,95.9
  c6.7,14.5,14.8,28.4,22.7,40.6c0.7,3.5,1.7,7.1,3.3,10.7c2.2,5.1,4.3,9.4,6.1,13.1c7.6,15.8,10.8,22.3,0.9,45.1
  c-0.3,0.6-0.6,1.2-0.8,1.8c-2.9-4-5.9-7.9-9-12c-1.3-1.8-2.7-3.5-4-5.3c-20.5-27.3-26.6-43.3-28.3-74.4c-0.4-7.9-2.7-15.7-6-23.2
  c1.1-0.1,2.1-0.3,3.1-0.4C403,92.8,407.1,94.1,411,95.9z M295,122.3c-4.3,2.8-8.7,4.9-13.1,7c-9.1,4.3-17.7,8.3-23.7,18.2
  c-5.7,9.3-5.7,19.1-5.7,28.5c0,5.6,0,11.3-1.2,17.1c-2.8,14-11.6,20.8-20.9,28.1c-9.2,7.2-18.7,14.6-21.8,29.3
  c-4.3-9.3-8.6-18.4-12.8-27.3c-2.4-5-4.7-9.9-7-14.9c2-3.2,3.9-6.6,5.5-10.3c2.5-5.5,3.9-11.3,5.4-17c1.8-7.2,3.7-14.7,7.6-21.3
  c4.5-1.5,8.9-3.2,13.2-5.4c13.2-6.6,14.7-10.9,17.5-18.5c0.6-1.6,1.2-3.4,2-5.3c0.7-0.7,1.4-1.4,2.1-2.2c1.9-2.1,3.6-4.4,5.2-6.9
  c20.2-3.2,40.5-7.6,60.3-11.9C304.2,114.4,300.1,118.9,295,122.3z M246.6,120.3c-0.3,0-0.6,0.1-0.8,0.1c0.6-0.7,1.3-1.4,1.9-2
  C247.3,119,246.9,119.6,246.6,120.3z M244,120.7c-9.3,1.5-18.4,3.3-27.3,5.4c12.9-10.2,26.2-20.4,40-29.7
  c-2.1,6.8-4.5,13.3-7.4,19.1C247.3,117,245.5,118.7,244,120.7z M214,126.7c-5.2,1.3-10.4,2.6-15.5,4.1c2.1-3.4,3.7-6.7,5.1-9.9
  c4.1-8.6,7.3-15.5,18.9-19.6c5.2-1.8,9.6-3.2,13.9-4.4c8.1-2.4,15-4.4,22.6-8.9c-0.6,2.2-1.2,4.5-1.9,6.7
  C242.2,104.7,227.9,115.7,214,126.7z M260.5,87.1c1.7-1,3.4-2.2,5.1-3.5c2.7,0.2,5.3,0.4,8,0.7c-1.9,1.1-3.7,2.2-5.6,3.3
  c-3.2,1.9-6.3,4-9.4,6C259.3,91.5,259.9,89.3,260.5,87.1z M260.9,85.5c0.2-0.7,0.4-1.4,0.5-2.1c0.8,0,1.6,0.1,2.4,0.2
  C262.9,84.2,261.9,84.9,260.9,85.5z M265.3,82.5c-1.2-0.1-2.3-0.2-3.5-0.2c0.6-2.4,1.2-4.9,1.8-7.3c0.7-2.9,1.4-5.7,2.1-8.5
  c1.3,0.2,2.6,0.3,4,0.5l2.5,0.3c3.7,0.5,7.2,0.7,10.6,0.9c-2.9,2.4-5.7,4.7-8.6,7.1c-1.7,1.4-3.5,2.9-5.2,4.3
  C267.8,80.6,266.5,81.5,265.3,82.5z M272.4,66.1l-2.5-0.3c-1.3-0.2-2.5-0.3-3.8-0.5c0.7-2.6,1.4-5.1,2.1-7.6
  c8.3,0.5,16.6,1.3,24.9,2.2c-3,2.4-5.9,4.7-8.9,7.1C280.4,66.9,276.5,66.6,272.4,66.1z M267.3,56.5c-0.2,0-0.4,0-0.6,0
  c0.2-0.1,0.5-0.2,0.7-0.3C267.4,56.3,267.3,56.4,267.3,56.5z M267,57.6c-0.7,2.4-1.4,4.9-2.1,7.5c-4.2-0.5-8.5-0.9-12.8-0.9
  c-1.2,0-2.4,0.1-3.6,0.2c5.4-2.6,10.6-4.9,15.6-6.9C265.1,57.5,266,57.6,267,57.6z M221.2,83.2c-0.8,0.1-1.6,0.2-2.4,0.4
  c2-2.2,4.3-4.3,6.9-6.3C224.2,79.3,222.7,81.3,221.2,83.2z M227.9,74.2c-4.3,3-7.9,6.3-11,9.7c-1,0.2-2.1,0.4-3.1,0.6
  c0.7-0.6,1.4-1.2,2.1-1.8c4.4-3.7,8.6-7.2,13.1-10.1C228.6,73.1,228.2,73.6,227.9,74.2z M217.5,85c0.9-0.2,1.8-0.3,2.7-0.4
  c-3.4,4.4-7,8.5-10.9,12C211.7,92.5,214.3,88.6,217.5,85z M221.8,84.3c12.7-1.8,25.5-1.8,38.5-1c-0.3,1-0.5,2.1-0.8,3.1
  c-7.9,4.8-15,6.9-23.4,9.4c-4.3,1.3-8.8,2.6-14,4.4c-12.1,4.3-15.5,11.7-19.5,20.2c-1.5,3.2-3,6.5-5.1,9.9c-0.2,0.3-0.4,0.7-0.6,1
  c-3.4,1-6.8,2-10.1,3.1c7.3-8.4,11.8-17.3,16.3-26c1.5-3,3.1-6,4.7-9c0.5-0.4,1.1-0.9,1.6-1.3C213.8,94.3,217.9,89.4,221.8,84.3z
   M195.8,132.9c-7.5,10.8-15.1,13.2-26.3,16.6c-0.4,0.1-0.9,0.3-1.3,0.4c5.1-3.1,9.9-6.6,14.4-11c0.9-0.9,1.8-1.8,2.6-2.7
  C188.7,135,192.2,133.9,195.8,132.9z M181.7,138.1c-4.5,4.5-9.5,8.1-14.7,11.1c0.1-2.2,0.2-4.4,0.4-6.6c5.1-2,10.2-3.9,15.4-5.6
  C182.4,137.4,182.1,137.7,181.7,138.1z M169.8,150.6c11.4-3.5,19.8-6.1,27.7-18.3c4.7-1.4,9.4-2.7,14.2-3.9
  c-1.4,1.1-2.9,2.3-4.3,3.4c-11.8,9.3-24,19-36.5,28c-0.9,0.6-1.8,1.3-2.7,1.9c-0.4-1.1-0.9-2.2-1.3-3.4c0-2.3,0-4.6,0-6.9
  C167.9,151.2,168.9,150.9,169.8,150.6z M167.4,178.1c0,0.2,0.1,0.4,0.1,0.7c-7.2,10.6-5,24.4-2.4,40.6c0.4,2.6,0.8,5.2,1.3,8
  c0,0.1,0,0.2,0,0.3c-1.7,4.5-4,8.8-7.1,13.3c-2.8-4.8-5.6-9.7-8.2-14.9c-4.6-9-8.4-17.9-11.5-26.8c0.4-6.5,1.1-13.1,2.1-19.9
  c8.2-4.6,16.5-9.3,24.4-14.7C166.3,169.1,166.8,173.6,167.4,178.1z M165.8,149.9c-0.6,0.3-1.1,0.7-1.7,1c-0.7-2-1.5-4-2.2-6
  c1.4-0.6,2.8-1.2,4.3-1.7C166,145.4,165.9,147.7,165.8,149.9z M165.8,151.8c0,1.1-0.1,2.3-0.1,3.4c-0.4-1-0.8-2-1.1-3
  C165,152.1,165.4,152,165.8,151.8z M163.1,151.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-1.9,0.6-3.8,1.3-5.6,2
  c0.5-2.5,1.1-4.9,1.7-7.4c0.9-0.4,1.8-0.8,2.7-1.2C161.6,147.4,162.3,149.4,163.1,151.4z M163.3,152.7c0.1,0,0.2-0.1,0.2-0.1
  c0.8,2,1.5,4,2.3,6c0,1.6,0.1,3.2,0.2,4.7c-7.8,5.3-16,10-24.1,14.6c0.2-1.6,0.5-3.2,0.8-4.7c0.6-0.1,1.3-0.2,2-0.2
  c8.8-0.7,9.7-6.5,10.8-13.1c0.2-1.1,0.4-2.3,0.6-3.5C158.4,155.2,160.9,154,163.3,152.7z M154.2,159.6c-1,6.7-1.8,11.5-9.7,12.1
  c-0.6,0-1.1,0.1-1.6,0.2c0.6-3.6,1.3-7.1,2.1-10.7c0.3-0.1,0.6-0.2,0.8-0.4c3-1.3,5.9-2.6,8.9-4
  C154.5,157.8,154.4,158.7,154.2,159.6z M141.6,172.2c-1.7,0.3-3.2,0.8-4.6,1.5c2.1-3.9,4.3-7.8,6.4-11.7c0.1,0,0.1-0.1,0.2-0.1
  C142.9,165.3,142.2,168.7,141.6,172.2z M135.2,174.6c-0.8,0.5-1.5,1-2.2,1.5c-0.2-1.2-0.5-2.3-0.7-3.5c2.4-3.9,5.6-7.1,9.4-9.8
  C139.5,166.8,137.3,170.8,135.2,174.6z M132,171.2c-0.2-1.2-0.4-2.4-0.6-3.7c2.2-1.1,4.4-2.2,6.7-3.2
  C135.8,166.3,133.7,168.6,132,171.2z M131.4,174.2c0.2,0.9,0.4,1.8,0.6,2.8c-1,0.9-2,1.9-3,2.9C129.6,177.8,130.4,176,131.4,174.2z
   M132.2,178.2c0.1,0.4,0.2,0.7,0.3,1.1c-1.1,1.9-2.1,3.7-3.2,5.5c-0.6,0.3-1.2,0.7-1.8,1c0.2-1.2,0.4-2.3,0.7-3.4
  C129.5,180.9,130.9,179.5,132.2,178.2z M128.2,186.9c-0.3,0.6-0.7,1.2-1,1.8c0-0.4,0.1-0.9,0.2-1.3
  C127.6,187.2,127.9,187,128.2,186.9z M120.7,243.5c-3.3,7.5-7.4,14.7-11.3,21.8c-0.1,0.2-0.3,0.4-0.4,0.7c0.5-2.5,1.1-5.1,1.8-7.8
  c3.8-14.2,3.3-28.9,2-44c4.2-6.6,8.5-13.6,12.9-20.9c-0.1,4.7,0,9,0.2,13.2C126.2,219.2,126.5,230.2,120.7,243.5z M125.8,190.9
  c-4.5,7.5-8.9,14.7-13.1,21.4c-0.2-1.7-0.3-3.4-0.5-5.1c-0.3-3.1-0.8-6.5-1.3-10c0.4-0.4,0.9-0.8,1.4-1.1c4.5-2.8,9.1-5.4,13.7-8
  C125.9,189,125.8,189.9,125.8,190.9z M89.3,333c0.3,4.3,0.9,8.6,1.7,12.9c-4.3-6.3-8.3-12.7-12.3-19c-0.8-1.2-1.5-2.4-2.3-3.7
  c-0.1-1.9-0.2-3.5-0.3-4.9c-0.1-2.3-0.2-4.5-0.2-6.8c1.7-4.3,3.4-8.6,5.2-12.9C82.9,310.3,85.7,321.8,89.3,333z M93.4,351.5
  c2.3,3.2,4.6,6.4,7,9.5c3.7,7.7,7.7,14.9,12.1,21.7c-0.8-0.3-1.7-0.6-2.5-0.9l-0.5-0.2c-2.7-1-5.5-2-8.3-3.1
  c-0.4-1.4-0.8-2.7-1.2-4C97.3,366.3,95.1,358.7,93.4,351.5z M103.8,365.2c1.5,1.8,3,3.6,4.6,5.3c3.2,3.6,6.8,6.8,10.7,9.9
  c0.2,1.7,0.4,3.3,0.8,5c-1.9-0.7-3.7-1.3-5.6-2C110.6,377.6,107.1,371.5,103.8,365.2z M120.3,386.7c2.2,7.4,7.2,14.4,18,21.6
  c1.4,0.9,2.8,1.8,4.2,2.7c-1.6,0.3-3.1,0.6-4.7,0.9c-8.1-7.8-15.6-16.9-22.4-27C117,385.5,118.6,386.1,120.3,386.7z M139.3,415
  c-2.1-0.4-4.1-0.8-6.2-1.2c1.4-0.2,2.9-0.4,4.3-0.7C138,413.8,138.7,414.4,139.3,415z M138.8,413c1.7-0.3,3.4-0.6,5.1-1
  c0.3,0.2,0.7,0.5,1,0.7c3.8,2.5,7.7,5,11.5,7.7c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2-0.1-0.3-0.1c-4.6-2.1-9.4-3.5-14.1-4.6
  C140.7,414.7,139.7,413.8,138.8,413z M145.6,411.6c0,0-0.1,0-0.1-0.1c5.3-1.3,10.5-2.8,15.4-4.7c1.8,1.1,3.5,2.2,5.2,3.4
  c1.4,3.5,2.9,6.9,4.6,10.1c-4,0-8.1,0.1-12.2,0C154.2,417.3,149.8,414.4,145.6,411.6z M162.2,406.3c0.7-0.3,1.4-0.6,2-0.8
  c0.4,1,0.7,1.9,1.1,2.9C164.3,407.7,163.2,407,162.2,406.3z M163.9,401.1c1.5,0.4,2.9,0.9,4.4,1.3c-1.1,0.5-2.3,1-3.4,1.5
  C164.5,403,164.2,402.1,163.9,401.1z M163.4,399.8c-2-6.1-3.8-12.4-5.2-18.5c2.2,0.8,4.5,1.2,7,1.3c5.1,0.2,10.2-1,15.1-2.6
  c2.8,1.2,5.8,2.3,8.9,3.1c1.7,0.5,3.5,0.8,5.2,1.2c0.4,2.4,0.9,4.8,1.6,7c-7.4,1.7-14.6,4-21.8,8c-1.4,0.8-2.9,1.6-4.4,2.3
  C167.7,401.1,165.6,400.4,163.4,399.8z M174.8,400.4c7.1-4,14.3-6.2,21.6-7.9c1.8,5.4,4.5,10.4,8.1,15c0.8,1,1.7,2,2.5,3
  c-11.9-2.2-23.8-5.1-35.5-8.4C172.6,401.6,173.7,401,174.8,400.4z M205.4,406.8c-3.5-4.5-6.1-9.3-7.9-14.6c3.3-0.7,6.6-1.4,10-2
  c4.2-0.8,8.5-1.7,12.8-2.7c2.9,0.3,5.8,0.6,8.7,1c4.1,11.4,10,19.8,17.3,26.2c-12.4-0.3-25-1.6-37.4-3.8
  C207.7,409.6,206.5,408.2,205.4,406.8z M230.4,388.7c6,0.9,12,2.3,17.7,4.6c9.4,3.8,16.7,10.4,23.7,16.8c1.3,1.2,2.6,2.4,3.9,3.6
  c-5.4,0.3-11,0.6-16.7,0.9c-2.9,0.1-5.8,0.2-8.7,0.2c-0.7,0-1.4,0-2.1,0C240.6,408.4,234.6,400.1,230.4,388.7z M259.1,415.7
  c6.1-0.3,12.1-0.6,17.9-0.9c4.6,4,9.5,7.8,15.1,10.7c2.1,1.1,4.3,2,6.5,2.9c-4.1,1-8.3,1.9-12.5,2.8c-14.1-3-26.5-7.4-36.5-15.2
  C252.8,415.9,256,415.8,259.1,415.7z M300.6,429c5.9,2,12.1,3.4,18.5,4.2c-1.4,0.8-2.8,1.7-4,2.7c-1.7-0.3-3.4-0.7-5.2-0.9
  c-7.3-1-14.4-2.1-21-3.4C292.9,430.8,296.8,429.9,300.6,429z M321.4,433.6c2.3,0.3,4.6,0.5,7,0.6c-3.5,0.8-7,1.6-10.5,2.4
  c-0.4-0.1-0.8-0.2-1.2-0.2C318.1,435.2,319.7,434.3,321.4,433.6z M332.8,434.3c1.5,0,2.9,0.1,4.4,0.1c3.7,0,7.5-0.1,11.3-0.3
  c-5.2,2.5-10.5,4.7-15.9,6.7c-4-1.3-8.1-2.6-12.2-3.6C324.5,436.2,328.7,435.3,332.8,434.3z M337.5,433.2c0.4-0.1,0.8-0.2,1.2-0.3
  c1.5-0.3,2.9-0.7,4.4-1c0.3,0.1,0.7,0.2,1,0.3c0.6,0.2,1.3,0.5,1.9,0.8C343.2,433.1,340.4,433.2,337.5,433.2z M354.4,432.5
  c3.7-1.8,7.3-3.7,10.8-5.6c0.3-0.1,0.6-0.1,1-0.2c-2,1.9-4,3.6-6.1,5.2C358.2,432.1,356.3,432.3,354.4,432.5z M368.3,426.3
  c5.5,1,10.9,1.8,16.2,2.4c-7.2,1.1-14.7,2.2-22.2,3C364.3,430,366.3,428.2,368.3,426.3z M371.1,425.6c0.6-0.1,1.1-0.2,1.7-0.4
  c4,0.7,8,1.5,11.9,2.3C380.3,427.1,375.7,426.4,371.1,425.6z M431.5,416.2c-5.9,0.5-12.1,0.9-18,1.5c11.3-8.9,22.2-18.6,33.3-28.5
  l2.8-2.5c6.6-5.9,13-11.7,19.2-17.6c6.2,0,12.4-0.3,18.4-0.5c-2.9,2.4-5.7,5-8.3,7.8c-3.6,3.9-6.7,8-9.7,11.9
  c-5.5,7.2-10.8,14-18.6,19.9c-3.3,2.5-6.8,4.9-10.5,7.3C437.2,415.8,434.4,416,431.5,416.2z M470.1,367.9c3.6-2.9,7.2-5.9,10.5-9
  c4.4-4.2,8-8.6,11-13.1c2.2,4.3,3.9,8.9,4.8,14.2c0.1,0.4,0.1,0.7,0.2,1.1c-1.2,1-2.4,2-3.6,2.9c-1.4,1.1-2.8,2.3-4.3,3.4
  c-0.1,0-0.2,0-0.3,0C482.4,367.6,476.3,367.9,470.1,367.9z M493.7,364.8c1-0.8,2-1.6,3.1-2.5c0.2,1.6,0.3,3.1,0.4,4.6
  c-2.2,0.1-4.4,0.2-6.5,0.3C491.7,366.4,492.7,365.6,493.7,364.8z M492.4,344.8c6.1-7,11.9-14.3,17.1-22c0.1,8.6,0.1,17.3-0.2,25.8
  c-3.4,4.4-7.5,8.1-11.7,11.5c0-0.1,0-0.3-0.1-0.4C496.6,354.1,494.7,349.3,492.4,344.8z M509.2,350.5c-0.2,5.5-0.6,10.9-1.1,16.2
  c-3.3,0-6.5,0.1-9.8,0.2c-0.1-1.8-0.3-3.6-0.5-5.5C501.8,358.2,505.8,354.6,509.2,350.5z M498.4,368.1c3.2-0.1,6.4-0.2,9.6-0.2
  c-0.4,4.2-1,8.4-1.7,12.5c-3.8,3.5-7.6,7-11.5,10.5C497.3,384.1,498.7,376.7,498.4,368.1z M509.2,367.9c3.2,0,6.5,0.1,9.7,0.3
  c-3.6,3.7-7.4,7.3-11.1,10.9C508.3,375.5,508.8,371.7,509.2,367.9z M530.2,353.8c0.1,0.8,0.2,1.5,0.2,2.3c-0.6,0.7-1.3,1.4-1.9,2
  C529.1,356.7,529.6,355.3,530.2,353.8z M526.3,334.3c0.1-0.5,0.1-1,0.2-1.5c1.2,5.5,2.3,11.3,3.1,17
  C526.9,344.8,525.7,339.4,526.3,334.3z M525.9,324.5c-0.9-3.9-1.7-7.6-2.4-11c0.7-5.2,1.1-10.6,1.2-16.1c2,3.6,4,7.2,6.1,10.9
  c2.1,3.8,4.3,7.5,6.3,11.4c-0.8,0.4-1.6,0.7-2.3,1c-3.3,1.4-6.3,2.7-8.2,7.2C526.4,326.7,526.1,325.6,525.9,324.5z M531.8,307.7
  c-2.4-4.2-4.7-8.4-7-12.5c4-8.9,7.3-18.4,9.9-28.7c1.5,2.8,3.2,5.5,4.9,8.3c1.9,3,3.7,6,5.3,8.9c-0.2,1.5-0.7,2.9-1.5,4.1
  c-4,5.7-4.8,7.9-4.3,15.8c0,0.3,0.3,0.5,0.6,0.5c0,0,0,0,0,0c0.3,0,0.6-0.3,0.5-0.6c-0.5-7.6,0.3-9.5,4.1-15.1
  c0.6-0.9,1.1-2,1.4-3.1c1.1,2.1,2,4.2,2.6,6.3c-2.1,8.3-4.5,16.7-7,25.1c-1.1,0.9-2.1,1.7-3.2,2.2
  C536.1,315.3,534,311.5,531.8,307.7z M540.8,318.8c-0.3,1.1-0.7,2.2-1,3.2c-0.3-0.6-0.7-1.3-1-1.9
  C539.4,319.8,540.1,319.3,540.8,318.8z M548.1,255c1.5-3.4,3.8-6.7,7-10c0.6,1.9,1.3,3.7,1.9,5.6c-0.3,2-0.6,3.9-1,5.8
  c-1.2,6.7-2.6,13.5-4.1,20.3C551.2,269.6,550,262.3,548.1,255z M556,244.1c0.7-0.7,1.4-1.3,2.1-2c-0.2,2-0.5,4-0.7,5.9
  C557,246.7,556.5,245.4,556,244.1z M559.6,239.3c0.2-2,0.4-4.1,0.6-6.2c0.6,1.4,1.2,2.9,1.8,4.3C561.2,238,560.4,238.7,559.6,239.3
  z M538.6,185.4c-1.7-6.1-3.3-12.3-4.8-18.6c2,1.4,4,2.9,6,4.4c0.2,2.6,0.6,5.1,1,7.6C540.1,181,539.3,183.2,538.6,185.4z
   M539.6,169.7c-2-1.6-4.1-3.1-6.3-4.6c-0.2-0.7-0.3-1.4-0.5-2.1c-1.7-7.2-3.5-14.6-5.6-22c0.5-0.9,0.9-1.8,1.3-2.6
  c1.4-2.8,2.8-5.4,3.8-8.2c2.4,2.6,5.4,3.9,8.6,3.9c0.1,0,0.3,0,0.4,0c-0.2,3.4-0.5,6.8-0.9,10.2c-0.4,4.2-0.8,8.6-1.1,12.9
  C539.2,161.4,539.3,165.6,539.6,169.7z M531.9,127.7c-4.2-6.2-3.9-16.4-1.1-23.6c0.4,1.7,0.8,3.5,1.2,5.4
  C533.6,117.6,533.3,123,531.9,127.7z M531.3,128.9c0,0.1,0.1,0.1,0.1,0.2c-1,3-2.4,5.7-4,8.7c-0.2,0.5-0.5,1-0.8,1.5
  c-1.5-5.3-3.2-10.7-5.2-16c-4.6-12.5-12.8-24.6-23.8-35.5c-0.3-0.6-0.6-1.1-0.9-1.7c0.4-1.8,0.8-3.7,1.2-5.5c8.6,3,17.3,7,24.5,13
  c2.7,2.3,5.2,4.8,7.6,7.5c0.1,0.3,0.2,0.7,0.3,1C526.6,110,526.1,122,531.3,128.9z M528.2,97.4c-1.6-1.7-3.3-3.2-5-4.7
  c-6.4-5.4-13.9-9.2-21.6-12.1c4.5,0.5,9.5,1.4,15.4,2.9c0.9,0.2,1.8,0.5,2.8,0.9C522.9,88.6,525.7,92.9,528.2,97.4z M521.8,85.2
  c1,0.4,2,0.9,3,1.4c1.3,2.6,2.4,5.5,3.5,8.5C526.3,91.8,524.1,88.4,521.8,85.2z M517.4,82.5c-7.5-1.9-13.4-2.9-19-3.2
  c0.6-2.5,1.3-5,2-7.4c2.4,0,4.7-0.5,6.8-1.4c1.8,1.6,3.6,3.4,5.3,5.2c2.2,2.3,4.2,4.7,6.1,7.3C518.1,82.7,517.7,82.6,517.4,82.5z
   M497.2,79.1c-0.9-0.3-1.7-0.6-2.6-0.8c-0.8-2.6-1.6-5.2-2.4-7.8c0,0,0,0,0,0c2.4,0.8,4.7,1.3,6.9,1.4
  C498.4,74.2,497.8,76.7,497.2,79.1z M494.5,82.2c-0.4-0.6-0.7-1.2-1.1-1.8c0.2,0,0.4,0,0.6,0C494.2,81,494.4,81.6,494.5,82.2z
   M493.7,79.3c-0.3,0-0.6,0-1,0.1c-0.1-0.2-0.3-0.4-0.4-0.6c0.4,0.1,0.9,0.3,1.3,0.4C493.7,79.2,493.7,79.2,493.7,79.3z M494.9,85
  c-1.5-1.4-3.1-2.8-4.7-4.2c0.7-0.1,1.3-0.2,2-0.3C493.1,82,494,83.5,494.9,85z M489.1,79.9c-0.2-0.1-0.3-0.3-0.5-0.4
  c-1.2-1-2.4-2-3.7-2.9c1.9,0.5,3.8,1,5.7,1.6c0.3,0.4,0.6,0.8,0.8,1.3C490.6,79.6,489.8,79.8,489.1,79.9z M488.7,81.2
  c2.3,1.9,4.5,3.9,6.7,5.9c-0.7,3.4-1.3,6.8-1.8,10.2c-3.6-3.8-7.6-7.2-11.9-10.3c0-0.2,0-0.3,0.1-0.5
  C482.3,83.7,485.3,82.1,488.7,81.2z M460,94.1c0,0-0.1,0-0.1,0c-0.2-0.2-0.3-0.4-0.5-0.6C459.6,93.7,459.8,93.9,460,94.1z
   M437,73.6c9.5,6.3,15.8,12.7,21,19.9c-6.3-1.8-13.3-3.1-21-3.7C437.3,84.3,437.3,78.9,437,73.6z M449.4,81.9
  c-3.5-3.3-7.6-6.5-12.5-9.7c-0.1-1.6-0.2-3.1-0.3-4.7c0,0,0,0,0,0C440.9,72.3,445.2,77.1,449.4,81.9z M437,90.9
  c8.2,0.7,15.6,2.1,22.2,4.1c3.2,4.6,6.2,9.5,9.4,14.9c2.3,3.9,4.7,7.9,7.5,12.2c3.9,6.1,8.8,11.3,14.2,15.9
  c-0.3,11.8-0.2,23.6-0.1,35.3c0,4.8,0.1,9.7,0.1,14.6c-0.1-0.1-0.1-0.2-0.2-0.2l-0.3-0.3c-10.1-10.9-18.1-23.9-25.8-36.5
  c-8.5-13.9-17.3-28.2-29.1-39.9c0.2-2,0.5-4,0.7-6.1C436.1,100.4,436.6,95.7,437,90.9z M394.1,91.3c-2,0.3-4,0.5-6.1,0.9
  c-24.4,3.7-49,9.1-72.7,14.4c-1.7,0.4-3.4,0.8-5.2,1.1c3.7-5.9,6.6-12.4,9.6-18.8c9.5,0.7,19.1,1.1,28.9,0.9
  c2.6-0.1,5.2-0.1,7.8-0.2c13.3-0.4,25.7-0.7,37.6,1.3C394,91,394.1,91.2,394.1,91.3z M308.5,108c-19.8,4.4-40.2,8.7-60.4,12
  c0.7-1.2,1.4-2.5,2.1-3.8c6.1-4.7,13.8-7.1,21.4-9.4c11.7-3.6,23.8-7.4,29.2-19.7c5.9,0.6,11.8,1.2,17.8,1.7
  C315.4,95.4,312.4,102,308.5,108z M271.1,105.7c-6.9,2.1-14,4.3-19.9,8.3c2.7-5.7,4.9-12,6.9-18.6c3.5-2.3,7-4.6,10.5-6.8
  c2.3-1.4,4.6-2.8,7-4.1c5.4,0.5,10.8,1.1,16.3,1.7c2.5,0.3,5,0.5,7.5,0.8C294.2,98.6,283,102.1,271.1,105.7z M275.4,83.3
  c-2.8-0.3-5.6-0.5-8.3-0.7c0.9-0.7,1.8-1.4,2.7-2.2c1.7-1.4,3.5-2.9,5.2-4.3c3.2-2.6,6.4-5.3,9.6-7.9c2.6,0.1,5,0.2,7.3,0.2l0.2,0
  c2.3,0,6.3,0.1,9.7,0.8c0,0.4,0,0.9,0.1,1.3C292.9,74.1,284.1,78.4,275.4,83.3z M294.3,58.9c-0.1,0-0.2,0-0.3,0
  c-8.5-0.9-17-1.8-25.5-2.3c0.1-0.3,0.2-0.6,0.3-0.9c1.4-0.5,2.7-1.1,4.1-1.6c2.1,0.4,4.1,0.8,6.2,1.2c5.3,1.1,10.6,2.1,16,3
  C294.8,58.5,294.6,58.7,294.3,58.9z M269.2,54.2c0.1-0.3,0.2-0.6,0.3-0.8c0.4,0.1,0.8,0.2,1.2,0.2C270.2,53.8,269.7,54,269.2,54.2z
   M268.7,52c-1.7-0.3-3.4-0.6-5.2-0.9c2.3-1.1,4.5-2.2,6.7-3.4C269.7,49.1,269.2,50.6,268.7,52z M268.3,53.1
  c-0.2,0.5-0.3,1.1-0.5,1.6c-1.3,0.5-2.6,1-3.9,1.6c-4.7-0.2-9.3-0.2-13.8,0c3.4-1.1,6.8-2.3,10.1-3.7c0.5-0.2,1-0.4,1.4-0.6
  C263.8,52.3,266.1,52.7,268.3,53.1z M261.4,57.4c-5.2,2.2-10.7,4.7-16.4,7.5c-1.3,0.2-2.5,0.5-3.7,0.8c-2.9,0.8-5.6,1.9-8.2,3.2
  c2.4-3.3,4.9-6.5,7.6-9.5c1.6-0.5,3.2-1,4.7-1.5c0.1,0,0.1,0,0.2-0.1C250.8,57.3,256,57.3,261.4,57.4z M230.7,70.2
  c-5.6,3.3-10.7,7.5-15.6,11.7c-1.2,1-2.5,2.1-3.8,3.1c-1.6,0.3-3.2,0.7-4.7,1.1c0.3-0.6,0.6-1.2,0.9-1.7
  c4.1-7.9,6.9-13.2,18.6-19.2c4-2.1,8.2-3.7,12.5-5.1C235.8,63.3,233.3,66.7,230.7,70.2z M209.3,86.7c-1.7,1.3-3.3,2.6-5.1,3.8
  c0.6-1,1.1-2,1.6-3C207,87.2,208.1,86.9,209.3,86.7z M202.3,93.6c0.2-0.3,0.3-0.5,0.5-0.7c3.2-2.1,6.2-4.4,9.1-6.8
  c1.3-0.3,2.6-0.5,3.9-0.8c-3.5,4.3-6.4,8.8-9,13.5c-8.6,7-18.4,12.7-23.7,15.6c-0.3,0.2-0.4,0.5-0.2,0.8c0.2,0.3,0.5,0.4,0.8,0.2
  c4.9-2.7,13.6-7.8,21.7-14c-1.2,2.2-2.3,4.4-3.4,6.6c-4.6,9.1-9.5,18.5-17.4,27.3c-5.7,1.9-11.4,4-17,6.2
  c0.6-6.5,1.5-12.9,2.9-19.4c4-4.1,8.5-7.3,13-10.5C190.3,106.5,197.4,101.5,202.3,93.6z M166.3,141.9c-1.6,0.6-3.2,1.3-4.8,1.9
  c-0.5-1.4-1-2.8-1.4-4.2c1.2-3.7,2.7-7.2,4.7-10.5c1.2-2,2.6-3.9,4.1-5.5C167.7,129.7,166.8,135.8,166.3,141.9z M160.5,144.2
  c-0.7,0.3-1.3,0.6-2,0.8c0.3-1.2,0.7-2.4,1-3.6C159.8,142.4,160.2,143.3,160.5,144.2z M157.2,145.6c-2.3,1-4.6,2-6.9,3.1
  c2.3-4.7,4.7-9.6,6.9-14.3c0.6,1.8,1.1,3.5,1.7,5.3C158.2,141.6,157.7,143.6,157.2,145.6z M155.7,126c3.4-3.8,6.7-7.8,9.8-11.7
  c-2.8,5.6-5.5,11.3-8.2,16.9C156.8,129.5,156.3,127.8,155.7,126z M155.3,124.7c-0.4-1.2-0.7-2.4-1-3.6c0-0.1,0.1-0.2,0.1-0.4
  c4.7-4.2,9.5-8.3,14.3-12.4c-0.1,0.3-0.3,0.5-0.4,0.8C164.1,114.4,159.8,119.6,155.3,124.7z M154.4,125.7c-0.5,0.6-1.1,1.2-1.6,1.8
  c0.3-1.4,0.6-2.7,1-4.1C154,124.2,154.2,125,154.4,125.7z M154.8,127c0.6,1.9,1.2,3.9,1.8,5.8c-2.6,5.5-5.3,11.2-8.1,16.7
  c-0.4,0.2-0.7,0.4-1.1,0.5c1-4.4,2.1-8.8,3.1-13.1c0.6-2.4,1.1-4.7,1.7-7.1C153.1,128.9,154,127.9,154.8,127z M147.7,151.1
  c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.5,0.9-0.7,1.4c0.1-0.6,0.3-1.1,0.4-1.7C147.3,151.3,147.5,151.2,147.7,151.1z M148.4,152.3
  c0.3-0.6,0.6-1.3,1-1.9c2.5-1.1,4.9-2.2,7.4-3.3c-0.6,2.4-1.1,4.9-1.6,7.3c-3.4,1.4-6.7,2.9-9.8,4.7c0.1-0.5,0.2-1,0.3-1.5
  C146.6,155.8,147.5,154.1,148.4,152.3z M144.6,157.2c-0.6,1.2-1.2,2.3-1.8,3.5c-0.3,0.2-0.6,0.4-0.9,0.6c-3.6,1.6-7.2,3.2-10.7,5
  c-0.4-2.4-0.7-4.7-0.9-7c3-1.6,6.1-2.9,8.7-4.1c1.5-0.7,3-1.3,4.1-1.8c0.9-0.4,1.8-0.9,2.8-1.3C145.4,153.8,145,155.5,144.6,157.2z
   M130.2,168.1c0.2,1.5,0.5,3,0.8,4.5c-1.7,2.7-3,5.8-3.9,9.3c-0.5,0.6-1,1.2-1.5,1.8c-0.2,0.3-0.2,0.6,0.1,0.8
  c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c-0.1,0.7-0.3,1.5-0.4,2.2c-4.9,2.7-9.8,5.5-14.5,8.5
  c-0.4,0.2-0.7,0.5-1,0.7c0-0.1,0-0.1,0-0.2c1.9-3.5,3.5-7.3,4.8-11.8c0.2-0.6,0.3-1.2,0.5-1.8c1-2.2,1.8-4.5,2.4-6.6
  C122.1,172.6,126.1,170.2,130.2,168.1z M110.3,193.6c-0.1-0.9-0.3-1.9-0.4-2.9c1.2-1.1,2.3-2.5,3.3-3.9
  C112.4,189.3,111.4,191.5,110.3,193.6z M114.8,181.7c-1.4,2.8-3.1,5.5-5.1,7.6c-0.2-1.7-0.4-3.5-0.6-5.2c1.1-1.3,2.3-2.6,3.5-3.8
  c1.3-0.5,2.3-1,3.1-1.5C115.4,179.8,115.1,180.8,114.8,181.7z M109.4,195.4c-3.2,5.9-7.2,10.7-11.5,15.2c-0.5,0-1,0.1-1.5,0.1
  c0.8-3,1.7-6,2.8-8.9c0.8-2.1,1.6-4.2,2.5-6.1c2.5-1,4.9-2.2,6.9-3.7c0.1-0.1,0.2-0.2,0.3-0.3C109.1,192.9,109.2,194.2,109.4,195.4
  z M107.7,200.5c-0.2,0.8-0.4,1.5-0.6,2.3c-0.5,2.3-1,4.5-3,6.1c-1.3,1.1-2.8,1.4-4.5,1.6C102.5,207.4,105.2,204.2,107.7,200.5z
   M96.8,211.9c-0.3,0.3-0.6,0.6-0.8,0.9c0.1-0.3,0.1-0.5,0.2-0.8C96.4,211.9,96.6,211.9,96.8,211.9z M98.1,201.4
  c-1.1,3.2-2.1,6.4-2.9,9.6c-0.6,0.1-1.2,0.3-1.7,0.6c-0.8,0.4-1.5,0.9-2.1,1.4c-0.1-0.5-0.1-0.9-0.2-1.4c2.4-4.8,4.8-9.6,7.2-14.5
  c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1.1-0.4C99.4,198,98.8,199.6,98.1,201.4z M90.9,218c0.1-0.6,0.3-1.2,0.4-1.9
  c0.1-0.6,0.2-1.1,0.2-1.6c0.6-0.7,1.4-1.4,2.6-1.9c0.3-0.1,0.6-0.2,0.8-0.3c-0.2,0.7-0.4,1.5-0.6,2.2c-0.2,0.2-0.4,0.4-0.5,0.6
  C92.8,216,91.8,217,90.9,218z M93.7,216.7c-0.3,1.2-0.6,2.4-0.9,3.6c-0.5,2-1,4.1-1.5,6.1c0-0.3-0.1-0.7-0.1-1l-0.1-0.6
  c-0.1-0.7-0.2-1.4-0.4-2.2c-0.2-0.9-0.3-1.7-0.4-2.4C91.5,219,92.6,217.9,93.7,216.7z M90.3,214.2c-1.2,1.7-1.3,3.6-1.1,5.6
  c0,0.1,0,0.1-0.1,0.2c-1.6,1.7-3.2,3.5-4.8,5.4c2-3.9,4-7.9,6-11.9C90.3,213.7,90.3,213.9,90.3,214.2z M89.5,222.2
  c0,0.2,0.1,0.5,0.1,0.7c0.1,0.7,0.3,1.4,0.4,2.1l0.1,0.6c0.2,1.1,0.4,2.8,0.4,4.3c-0.2,0.7-0.4,1.5-0.6,2.2
  c-0.1,0.1-0.1,0.2-0.1,0.2c-1,1-2.3,0.9-3.9,0.9c-0.8,0-1.6-0.1-2.5,0.1c0.3-1.5,0.9-2.6,1.6-3.8c0.4-0.6,0.7-1.3,1.1-2
  C87.4,226.1,88.6,224.4,89.5,222.2z M90.8,232.7c0.4-0.7,0.6-1.6,0.7-2.5c0.9-3.2,1.7-6.4,2.4-9.6c0.4-1.8,0.9-3.7,1.4-5.5
  c1.1-1.1,2.1-2.2,3.1-3.3c0,0,0,0,0.1,0c2.2-0.1,4.4-0.2,6.3-1.9c2.2-1.9,2.8-4.4,3.3-6.7c0.4-1.7,0.8-3.2,1.7-4.6
  c0.4,3.1,0.9,6.1,1.1,8.9c0.2,2.2,0.4,4.4,0.6,6.6c-4.4,6.9-8.6,13.4-12.5,19.4c-7,10.7-12.5,21.5-16.5,32.5
  c-1.1,1.7-2.2,3.4-3.3,5.2c-0.2-4.4-0.2-8.8-0.1-13.2c1.4-2.1,2.8-4.3,4.1-6.5C86.7,245.2,89,239,90.8,232.7z M80.7,296.5
  c-1.6,3.9-3.2,7.9-4.8,11.9c0.2-8.8,1.2-17.4,3.1-25.8C79.4,287.2,80,291.9,80.7,296.5z M74.7,311.4c-0.8,1.9-1.5,3.9-2.3,5.8
  c-2.5-3.9-5.1-7.8-7.7-11.6c3.1-10.6,7.9-20.8,13.7-30.8c0.1,1.3,0.2,2.7,0.3,4C76,289.4,74.7,300.3,74.7,311.4z M75.1,321.3
  c-0.6-1-1.2-1.9-1.8-2.9c0.5-1.3,1-2.6,1.6-3.9c0,1.3,0.1,2.7,0.1,4C74.9,319.3,75,320.3,75.1,321.3z M71.9,318.5
  c-1.4,3.5-2.7,6.9-4,10.4c-0.9-5.5-1.8-11.1-2.6-16.6c-0.3-1.7-0.5-3.3-0.8-5C67,311.1,69.5,314.8,71.9,318.5z M64.1,312.5
  c0.9,6.1,1.9,12.3,2.9,18.5c-0.3,0.9-0.7,1.8-1,2.7c-0.8,2-1.6,4.1-2.5,6.3c-0.6,1.5-1.2,3.1-1.9,4.7c-1.6-12.6-0.8-24.2,2-35.3
  C63.8,310.5,64,311.5,64.1,312.5z M61.1,349.4c0.5,3,1,6.1,1.6,9.1c-1.3-0.9-2.5-1.9-3.7-2.9C59.7,353.5,60.4,351.4,61.1,349.4z
   M62,347.2c0.9-2.3,1.8-4.5,2.6-6.7c0.9-2.2,1.7-4.3,2.5-6.3c0.1-0.3,0.2-0.6,0.3-0.9c1.8,10.2,3.8,20.4,6.5,30.4
  c0.2,0.9,0.5,1.8,0.8,2.6c-3.7-2.1-7.2-4.4-10.6-6.8C63.3,355.4,62.6,351.3,62,347.2z M75.1,367.9c-3.1,0.1-6.2,0.1-9.2,0
  c-0.5-2.2-1-4.4-1.4-6.6C67.9,363.7,71.5,365.9,75.1,367.9z M75.1,363.4c-2.9-10.5-5-21.3-6.8-32.2c1.5-3.8,2.9-7.6,4.4-11.4
  c0.8,1.3,1.7,2.6,2.5,3.9c0.7,10.4,2.2,26,5.7,40c0.3,1.4,0.7,2.7,1,4c-1,0-2,0.1-3.1,0.1c-0.4,0-0.9,0-1.3,0
  c-0.4-0.2-0.9-0.5-1.3-0.7C75.8,365.9,75.4,364.6,75.1,363.4z M123.7,416.4c-1.5-0.4-3.1-0.8-4.6-1.3c1.3,0,2.5-0.1,3.8-0.2
  C123.1,415.4,123.4,415.9,123.7,416.4z M143.5,417.2c3.1,0.8,6.2,1.8,9.3,3c-2-0.1-4.1-0.2-6.1-0.3
  C145.6,419,144.6,418.1,143.5,417.2z M155.7,421.4c1.9,0.9,3.8,1.7,5.7,2.5c0.4,0.3,0.8,0.6,1.1,0.9c-3.1-0.3-6.3-0.6-9.6-0.9
  c-0.4,0-0.7-0.1-1.1-0.1c-1.2-0.9-2.4-1.8-3.6-2.7C150.8,421.3,153.3,421.4,155.7,421.4z M164.2,426.2c0.6,0.5,1.2,1,1.8,1.5
  c-0.4,0.2-0.8,0.4-1.3,0.5c-2,0.6-4.1,0.4-6.3,0.2c-1.6-1-3.2-2.1-4.7-3.2C157.4,425.6,160.9,425.9,164.2,426.2z M171.9,420.3
  c-1.5-2.8-2.8-5.8-4.1-8.9c1.2,0.8,2.4,1.7,3.5,2.5c1.4,1,2.7,2,4.1,2.9c-1,0.6-1.9,1.4-2.7,2.5c-0.3,0.3-0.5,0.7-0.7,1
  C172,420.3,171.9,420.3,171.9,420.3z M173.7,420c0.8-1.1,1.8-1.9,2.8-2.5c1.5,1,2.9,1.9,4.4,2.7c-2.2,0-4.5,0-6.7,0.1
  c-0.3,0-0.5,0-0.8,0C173.5,420.2,173.6,420.1,173.7,420z M177.7,416.9c0.8-0.4,1.6-0.7,2.4-1c1.9-0.7,3.9-1.5,5.6-3.1
  c0.2-0.2,0.3-0.6,0-0.8c-0.2-0.2-0.6-0.3-0.8,0c-1.5,1.4-3.3,2.1-5.3,2.8c-1,0.4-2.1,0.8-3.1,1.3c-1.5-1-3-2.1-4.6-3.2
  c-1.6-1.2-3.3-2.4-5-3.5c-0.6-1.4-1.1-2.9-1.7-4.4c1.6-0.7,3.1-1.4,4.7-2.2c12.6,3.6,25.5,6.7,38.4,9c3.9,4.2,8.1,7.8,12.3,11.3
  c0,0,0.1,0.1,0.1,0.1c-2.4-0.6-4.9-1.1-7.3-1.4c-9.7-1.5-20-1.7-30.2-1.7C181.4,419.2,179.5,418.1,177.7,416.9z M213.2,423.1
  c3.2,0.5,6.4,1.2,9.5,2c3.3,2.8,6.6,5.7,9.6,8.9c-17-1.4-32.5-4.9-47-12.6C194.8,421.4,204.3,421.7,213.2,423.1z M225.3,425.7
  c8.5,2.4,16.7,5.7,24.7,9c-5.4-0.1-10.7-0.3-15.9-0.6c-0.1-0.1-0.2-0.2-0.3-0.3C231.1,430.9,228.2,428.3,225.3,425.7z M251.7,435.9
  c-0.6,0.7-1.1,1.5-1.6,2.3c-1.2,1.9-2.6,3-4.3,3.2c-3.7,0.4-7.9-3.4-10.5-6C240.6,435.7,246.1,435.9,251.7,435.9z M255.9,435.9
  c10,0,20.1-1.6,30.2-3.7c7.5,1.6,15.4,2.8,23.7,4c1.4,0.2,2.8,0.4,4.2,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-11.5,2.5-23,4.9-34.4,6.7
  C271.1,442.1,263.4,439,255.9,435.9C255.9,435.9,255.9,435.9,255.9,435.9z M311.9,439c-0.9,0.9-1.8,1.9-2.6,2.9
  c-0.4-0.3-0.7-0.7-1-1.1c-0.2-0.3-0.6-0.3-0.8-0.1c-0.3,0.2-0.3,0.6-0.1,0.8c0.4,0.5,0.8,0.9,1.2,1.3c-0.7,0.8-1.5,1.6-2.2,2.4
  l-0.7,0.7c-0.4,0.4-0.9,0.9-1.3,1.3c-5,0.3-10,0.2-14.9-0.6c-2.6-0.4-5.1-1-7.7-1.6C291.7,443.3,301.8,441.2,311.9,439z
   M306.5,446.7l0.7-0.7c0.8-0.8,1.5-1.7,2.3-2.5c0.9,0.7,2,1.2,3,1.7c0.5,0.3,1.1,0.5,1.6,0.8c-2.6,0.5-5.2,0.8-7.9,1.1
  C306.2,446.9,306.4,446.8,306.5,446.7z M312.9,444.1c-1-0.5-1.9-0.9-2.7-1.5c1.3-1.4,2.5-2.8,3.9-4.1c1.3-0.3,2.5-0.5,3.8-0.8
  c4.4,1,8.7,2.3,13,3.7c-5,1.8-10.1,3.2-15.2,4.3C314.8,445.1,313.9,444.6,312.9,444.1z M332.6,442c1.2,0.4,2.4,0.8,3.6,1.2
  c-4.1,0.9-8.8,1.6-14.1,2.1C325.7,444.4,329.2,443.2,332.6,442z M334.4,441.3c5.2-2,10.4-4.3,15.5-6.7c1.3,0.6,2.6,1.1,4,1.6
  c-4.5,2.7-9.5,4.8-15.5,6.4C337,442.2,335.7,441.8,334.4,441.3z M355.4,436.7c3.5,1,6,0.4,9-0.3c0.8-0.2,1.6-0.4,2.5-0.6
  c4.2-0.8,9.3-0.1,13,2.8c-7.3,3.9-15,7.3-23.1,10c-5.6-1.5-11.1-3.4-16.5-5.3C346.2,441.7,351,439.5,355.4,436.7z M366.6,434.7
  c-0.9,0.2-1.8,0.4-2.6,0.6c-2.5,0.6-4.6,1.1-7.3,0.6c1.3-0.9,2.6-1.8,3.9-2.8c9.8-1,19.4-2.5,28.5-3.9c2,0.2,4,0.3,6,0.4
  c-4.6,3-9.3,5.8-14.1,8.4C377,434.6,371.2,433.7,366.6,434.7z M412.9,432.6c0.5,0.1,0.9,0.1,1.4,0.2c-3.6,1.7-7.2,3.4-10.8,5.1
  c-5.8,2.8-11.7,5.6-17.7,8.3c-0.1-0.3-0.2-0.6-0.3-0.9C395.1,441.3,404.2,437.1,412.9,432.6z M414,430.8c-0.5-0.3-1-0.6-1.6-0.9
  c0.1,0,0.2,0,0.2,0c0.7,0.2,1.3,0.4,2,0.6C414.4,430.5,414.2,430.6,414,430.8z M418.8,429.5c1.1-0.1,2.2-0.2,3.3-0.3
  c-0.9,0.4-1.9,0.8-2.8,1.3c-0.5-0.1-1-0.3-1.6-0.4C418.1,429.9,418.5,429.7,418.8,429.5z M419.4,431.7c1,0.3,2.1,0.5,3.1,0.8
  c-1.3-0.1-2.7-0.2-4-0.4C418.8,432,419.1,431.9,419.4,431.7z M428.8,434c1.2,0.3,2.4,0.5,3.6,0.7c-0.4,0.7-0.9,1.5-1.2,2.3
  c-0.6,0.4-1.3,0.8-1.9,1.1c-3.6-1.4-7.2-3-10.6-4.8C421.9,433.7,425.3,433.9,428.8,434z M434.4,433.9c0.8,0,1.5-0.1,2.2-0.2
  c-0.3,0.2-0.5,0.3-0.8,0.5C435.4,434.1,434.9,434,434.4,433.9C434.4,433.9,434.4,433.9,434.4,433.9z M435.5,432.6
  c1.7-1.9,4-3.4,7-4.6l-0.4-1.1c-3.7,1.5-6.3,3.4-8.2,5.8c-1.6,0.1-3.3,0.1-4.9,0.1c-2.6-0.6-5.2-1.2-7.8-1.9
  c1.6-0.7,3.2-1.4,4.8-2.1c9.6-1.1,18.9-3.1,27.8-6c-4.7,3.2-9.6,6.3-14.7,9.5C437.9,432.4,436.7,432.6,435.5,432.6z M457.9,421.4
  c1.3-0.4,2.5-0.9,3.8-1.4c-1.3,1.9-2.8,3.5-4.2,4.7c-4.4,3.7-9.7,5.9-15.4,7C447.6,428.3,452.8,424.9,457.9,421.4z M464.7,417.4
  c0.2,0,0.5-0.1,0.7-0.1c-0.3,0.1-0.5,0.2-0.8,0.3C464.6,417.5,464.7,417.4,464.7,417.4z M442.5,415.3c3.1-2.1,6-4.1,8.8-6.2
  c7.9-6,13.2-12.8,18.8-20.1c3-3.9,6.1-7.9,9.6-11.8c2.9-3.2,6.1-6,9.4-8.7c2.7-0.1,5.4-0.2,8.1-0.3c0.4,9.3-1.4,17.1-4.3,24.5
  c-1.7,1.5-3.4,3-5.1,4.5c-6.4,5.4-12.9,10.7-19.9,15.8c-0.4,0-0.7,0.1-1.1,0.1c1.8-4.2,2.9-8.7,2.6-12.7c0-0.3-0.3-0.6-0.6-0.5
  c-0.3,0-0.6,0.3-0.5,0.6c0.3,3.9-0.9,8.5-2.8,12.7C457.9,414,450.2,414.7,442.5,415.3z M468.4,413.9c2-0.2,3.9-0.5,5.8-0.8
  c-1.6,0.8-3.2,1.6-4.9,2.3c-1.3,0.2-2.6,0.4-3.9,0.6C466.5,415.4,467.4,414.7,468.4,413.9z M470.3,412.5
  c6.3-4.7,12.4-9.6,18.3-14.6c1.1-1,2.3-2,3.4-2.9c-1.6,3.8-3.5,7.5-5.5,11.2c-3,1.9-6,3.7-9.1,5.3
  C475,411.9,472.7,412.2,470.3,412.5z M494,393.3c4.1-3.6,8.1-7.2,12-10.9c-0.1,0.7-0.2,1.4-0.4,2.1c-0.6,3.2-1.6,6.4-2.9,9.8
  c-4.5,3.9-9.3,7.5-14.3,10.7C490.5,401.1,492.4,397.3,494,393.3z M506.7,384.7c0.2-1.2,0.4-2.4,0.6-3.6c4.4-4.2,8.8-8.4,13-12.7
  c0.9,0.1,1.9,0.1,2.8,0.2c-1.1,2.7-2.2,5.4-3.3,8.2c-4.7,5.7-9.8,11-15.3,15.9C505.5,389.9,506.3,387.2,506.7,384.7z M524.4,368.7
  c0.5,0.1,1,0.1,1.5,0.2c-1.1,1.5-2.2,3.1-3.4,4.6C523.2,371.8,523.8,370.3,524.4,368.7z M524.9,367.6c0.9-2.2,1.7-4.4,2.6-6.6
  c1.1-1.1,2.1-2.2,3.1-3.3c0.1,1.3,0.2,2.5,0.3,3.8c-1.3,2.1-2.7,4.3-4.2,6.3C526.1,367.7,525.5,367.6,524.9,367.6z M530.9,363.5
  c0,1.6,0,3.3,0,4.9c-1-0.2-2-0.3-2.9-0.4C529,366.5,529.9,365,530.9,363.5z M530.7,349c-0.9-6.4-2.2-12.8-3.6-18.9
  c1.6-5.6,4.6-6.8,8.2-8.3c0.8-0.3,1.6-0.7,2.4-1.1c0.5,1,1.1,2,1.6,3C536.7,332,533.8,340.5,530.7,349z M540.1,325.1
  c1.4,2.7,2.8,5.5,4.2,8.2c-1,3-2.2,6-3.4,8.9c-0.7,1.6-1.4,3.1-2.1,4.7c-2.1,2.4-4.2,4.7-6.3,7.1c-0.4-0.6-0.9-1.2-1.3-1.7
  c0-0.3-0.1-0.5-0.1-0.8C534.3,342.6,537.3,333.8,540.1,325.1z M533.2,354.9c1.2-1.3,2.4-2.7,3.6-4c-0.9,1.7-1.8,3.4-2.7,5.1
  C533.8,355.6,533.5,355.2,533.2,354.9z M533.5,357c-0.5,0.9-1.1,1.9-1.6,2.8c-0.1-1.1-0.2-2.1-0.3-3.2c0.3-0.3,0.5-0.5,0.8-0.8
  C532.7,356.1,533.1,356.5,533.5,357z M531.6,354.8c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.3,0-0.4
  C531.5,354.6,531.6,354.7,531.6,354.8z M532,369.7c0.8,0.1,1.7,0.3,2.7,0.6c-0.9,1.4-1.9,2.7-2.8,4.1c-0.1,0.2-0.2,0.4-0.4,0.5
  C531.7,373.2,531.9,371.5,532,369.7z M532,368.6c0.1-2.2,0.1-4.5,0-6.8c0.8-1.3,1.6-2.6,2.3-3.9c1.4,1.4,2.9,2.7,4.5,3.8
  c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.2-0.3,0.1-0.6-0.1-0.8c-1.7-1.2-3.2-2.5-4.6-3.9c1.7-3,3.3-6.1,4.8-9.3
  c2.5-2.8,4.9-5.6,7.3-8.5c1.4,3,2.6,6.1,3.7,9.1c-5.4,6.9-10.4,14-15.3,21.1C534.1,369,533,368.7,532,368.6z M547.8,338.1
  c2.2-2.6,4.3-5.2,6.4-7.9c0.1,4.3,0.3,8.6,0.6,12.9c-1.1,1.3-2.2,2.7-3.3,4.1C550.4,344.1,549.2,341.1,547.8,338.1z M554.2,328.4
  c-2.3,2.9-4.6,5.7-6.9,8.5c-0.6-1.2-1.1-2.5-1.7-3.7c5.8-17,8.1-34.5,6.8-52.6c1.8-8,3.5-16,4.9-23.9c0.2-1.2,0.4-2.4,0.6-3.6
  c1.2,4,2.4,7.9,3.4,11.9c0,0.1-0.1,0.3-0.1,0.4c-0.9,4.2-1.8,8.6-2.5,12.9C555.4,295.7,554,312.2,554.2,328.4z M565.3,242.4
  c-0.6-1.5-1.3-3.1-1.9-4.6c0.9-0.7,1.8-1.4,2.7-2.1C565.9,237.9,565.6,240.2,565.3,242.4z M562.9,236.7c-0.8-2-1.7-4-2.6-6
  c0.5-7.2,0.7-14.7,0.7-22.4c0.3,1.3,0.6,2.6,0.9,3.9c1.3,5.9,2.9,12.2,4.6,18.6c-0.1,1.1-0.1,2.2-0.2,3.4
  C565.2,234.9,564.1,235.8,562.9,236.7z M566.6,226.6c-1.3-5.1-2.6-10-3.6-14.8c-0.7-3.2-1.4-6.4-2.1-9.7c0-0.2,0-0.3,0-0.5
  c0.7,0.3,1.4,0.6,2.2,0.8C565.8,210.3,566.7,218.3,566.6,226.6z M553.1,182.5c-2.8-3.4-6-6.5-9.3-9.4c2.3-8.1,3.8-15.6,4.6-22.7
  c3.2,12.3,5.8,24.8,8.3,36.9C555.5,185.6,554.3,184,553.1,182.5z M542.8,172.2c-0.6-0.5-1.3-1.1-1.9-1.6c-0.4-4.4-0.5-8.9-0.3-13.5
  c0.2-4.3,0.7-8.6,1-12.8c0.3-3.4,0.6-6.9,0.9-10.4c0.4-0.1,0.7-0.1,1.1-0.2c1.4,4.5,2.7,9,4,13.6
  C546.9,155.5,545.2,163.7,542.8,172.2z M543.3,132.6c-0.2,0-0.4,0.1-0.6,0.1c0-0.6,0.1-1.2,0.1-1.8
  C542.9,131.5,543.1,132,543.3,132.6z M532.8,128.8c1.6-5,2.1-10.8,0.4-19.5c-0.3-1.5-0.6-3-0.9-4.4c3.7,7.4,6.8,15.1,9.5,23.1
  c-0.1,1.6-0.1,3.3-0.3,4.9C538.2,133,535.1,131.6,532.8,128.8z M541.8,124.6c-2.2-6.2-4.7-12.2-7.4-18c2.7,3.8,5.2,7.9,7.4,12.2
  c0.1,1.8,0.1,3.6,0.1,5.4C541.8,124.3,541.8,124.4,541.8,124.6z M532,101.6c0.1-0.2,0.2-0.4,0.3-0.5c1-1.7,2.2-3.3,3.3-4.8
  c0.1-0.2,0.2-0.3,0.4-0.5c4,5.7,5.3,13,5.7,20.4C538.8,110.8,535.6,106,532,101.6z M534.6,95.6c-1.1,1.5-2.3,3.1-3.3,4.9
  c-0.1-0.2-0.2-0.4-0.3-0.6c-1.3-4.5-2.7-8.6-4.4-12.3c2.9,1.7,5.6,3.8,7.6,5.9c0.4,0.4,0.7,0.8,1,1.2C535,95,534.8,95.3,534.6,95.6
  z M514.9,72.7c3.5,3.4,6.5,7.5,9,12.2c-1.1-0.5-2.3-1-3.4-1.4c-2.3-3-4.7-5.9-7.3-8.7c-1.6-1.7-3.3-3.4-5-5
  c0.8-0.4,1.6-0.9,2.4-1.5c0,0,0.1,0,0.1-0.1C512,69.7,513.5,71.2,514.9,72.7z M509.9,67.4c-0.8,0.6-1.7,1.2-2.6,1.6
  c-1.6-1.4-3.2-2.7-4.8-4c0.5-1.4,1-2.8,1.5-4.2c1.3,1.4,2.6,2.9,3.9,4.3C508.5,65.9,509.2,66.6,509.9,67.4
  C509.9,67.4,509.9,67.4,509.9,67.4z M506.1,69.5c-1.7,0.7-3.5,1.1-5.5,1.1c0.4-1.5,0.9-3,1.4-4.5C503.4,67.2,504.8,68.4,506.1,69.5
  z M499.5,70.7c-2.1-0.1-4.4-0.5-6.9-1.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-2.4-1.6-4.9-2.5-7.4c1-0.4,2-0.9,3-1.3
  c0.2-0.1,0.5-0.2,0.7-0.3c2.8,1.7,5.5,3.5,8.1,5.5C500.5,67.1,500,68.9,499.5,70.7z M488.8,60.5c-0.1-0.4-0.3-0.9-0.4-1.3
  c-0.3-0.7-0.5-1.5-0.8-2.2c1.4,0.7,2.7,1.5,4.1,2.2C490.8,59.6,489.8,60.1,488.8,60.5z M490.2,68.2c-1.2-0.8-2-1.9-2.9-3.7
  l-1.1,0.6c1.3,2.4,2.3,3.8,4.5,4.8c0.9,2.7,1.7,5.3,2.5,8c-0.6-0.2-1.3-0.4-1.9-0.6c-3.2-4.7-6.7-9-10.4-12.9
  c2.4-0.4,4.8-1.3,7.2-2.3C488.8,64.1,489.5,66.2,490.2,68.2z M489.6,76.7c-2.4-0.7-4.8-1.3-7.2-2c-6.2-4.5-12.7-8.5-19.4-11.9
  c5,1,10.1,1.9,14.9,1.8c0.6,0,1.1-0.1,1.6-0.1C483.1,68.2,486.5,72.3,489.6,76.7z M457.5,69.6c5,0.6,10.2,2.2,15.2,3.6
  c2.2,0.7,4.6,1.4,6.8,1.9c0.8,0.2,1.6,0.4,2.3,0.6c2,1.4,3.9,2.9,5.8,4.5c-3.5,1-6.4,2.9-7,6c-12.9-9.1-28.2-15.4-43.3-19.8
  c-0.3-0.3-0.6-0.6-0.8-0.9c-0.3-3.9-0.8-7.8-1.3-11.8c1.4,0.3,2.9,0.7,4.3,1.1c1.5,0.4,2.9,0.8,4.4,1.3c0.1,0.7,0.2,1.4,0.3,2
  C445.8,66.7,449.9,68.7,457.5,69.6z M424.4,52.2c1.2,0.1,2.4,0.2,3.6,0.4c-0.3,0.2-0.5,0.3-0.8,0.5c-0.4,0.3-0.9,0.5-1.4,0.7
  C425.4,53.3,424.9,52.8,424.4,52.2z M424.7,54.3c-6.5,2.6-17,2.9-22.6-0.8c-0.5-0.3-0.9-0.6-1.3-0.9c7.3-0.7,14.6-1,21.9-0.5
  C423.4,52.8,424.1,53.6,424.7,54.3z M427.9,54.1c0.7-0.4,1.3-0.8,1.9-1.3c1.4,0.2,2.8,0.4,4.2,0.7c0.4,3.5,0.8,7.1,1.2,10.6
  c-2.8-3.2-5.7-6.3-8.5-9.4C427.1,54.6,427.5,54.4,427.9,54.1z M433.9,52.4c-0.9-0.2-1.8-0.3-2.7-0.5c0.8-0.6,1.6-1.3,2.4-1.9
  C433.6,50.8,433.8,51.6,433.9,52.4z M436.5,45.8c-0.7,0-1.5,0.1-2.3,0.2c-0.1-1-0.3-2-0.4-3.1l0-0.1c1.8,0.2,3.6,0.4,5.3,0.6
  C438.2,44.1,437.4,45,436.5,45.8z M434.3,47.1c0.3,0,0.5-0.1,0.8-0.1c-0.2,0.2-0.5,0.4-0.7,0.6C434.4,47.5,434.4,47.3,434.3,47.1z
   M429.4,51.6c-2.1-0.3-4.1-0.5-6.2-0.7c-0.2-0.2-0.5-0.5-0.7-0.7c0.7-0.2,1.6-0.5,2.5-0.8c2.4-0.7,5.3-1.7,8.1-2.2
  c0.1,0.4,0.1,0.8,0.2,1.3C432.1,49.6,430.8,50.7,429.4,51.6z M424.7,48.4c-1.2,0.4-2.2,0.7-3,0.9c-1.2-1.3-2.4-2.7-3.7-4
  c1.2-0.2,2.5-0.3,3.8-0.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-1.6,0-3.2,0.2-4.7,0.5c-0.4-0.5-0.9-1-1.3-1.5l-1.1-1.2
  c6,0.2,12,0.6,17.9,1.1l0.1,0.4c0.1,1,0.3,2.1,0.4,3.1C430.2,46.6,427.2,47.6,424.7,48.4z M420.8,50C420.8,50,420.8,50.1,420.8,50
  c0.1,0.3,0.3,0.5,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-7.3-0.4-14.6-0.1-21.9,0.7c-0.1-0.1-0.1-0.1-0.2-0.2c1.9-0.7,3.7-1.4,5.5-2.1
  c3.9-1.6,7.7-3.1,11.7-3.8C418.1,47,419.4,48.5,420.8,50z M401.5,54.6c3.1,2,7.6,2.9,12.1,2.9c4.3,0,8.7-0.8,12-2.2
  c3.2,3.5,6.4,7,9.6,10.6c-12.9-3.6-25.5-5.9-36.1-7.2c-0.4-1.9-0.8-3.8-1.1-5.7c0.4,0,0.8-0.1,1.3-0.1
  C399.9,53.4,400.6,54,401.5,54.6z M396.5,51.2c-5.1,1.3-9.3,0.2-14.2-1.1l-0.6-0.2c-0.4-1.3-0.9-2.6-1.8-3.8
  c4.1-1.3,8.2-2.6,12.3-3.7c1.2,2.4,2.5,4.9,4.1,7.1C396.3,50,396.4,50.6,396.5,51.2z M391.6,41.3c-4.2,1.2-8.4,2.5-12.5,3.8
  c-0.6-0.7-1.3-1.4-2-2c0.8-0.6,1.6-1.2,2.3-1.9c3.9,0,7.7,0,11.2,0c0.3,0,0.7,0,1,0C391.6,41.2,391.6,41.3,391.6,41.3z M379.8,38.5
  c-0.1,0.5-0.5,1.1-1,1.6c-2.2,0-4.4,0-6.7,0c0-1.4,0.1-2.6,0.5-3.7c0.6-1.4,1.6-1.8,2.5-1.8c0.5,0,0.9,0.1,1.3,0.2
  C378.4,35.5,380.1,37.3,379.8,38.5z M372.2,47c0,0.1,0.1,0.3,0.1,0.4c-0.3,0.1-0.6,0.2-0.9,0.3C371.7,47.5,372,47.3,372.2,47z
   M373.1,49.8c0.7,2.1,1.5,3.9,2.1,5.4l0.1,0.2c-1.2,0.2-2.3,0.4-3.5,0.6c-1,0.2-2,0.4-3,0.5c-1.5,0-3,0.1-4.5,0.1
  c0.3-0.6,0.5-1.1,0.9-1.7c1.1-2,2.6-3.7,4.2-5.3C370.6,49.7,371.9,49.7,373.1,49.8z M360.6,58c-7.7,1.3-15.4,2.5-23.1,3
  C345.2,59.5,352.9,58.5,360.6,58z M362.3,58.9c-2.1,8.2,2.6,16.1,7.8,23.4c0.2,0.3,0.5,0.3,0.8,0.1c0.3-0.2,0.3-0.5,0.1-0.8
  c-5.4-7.6-9.9-15.1-7.5-23c1.8-0.3,3.6-0.6,5.3-1c2.5,0,4.9,0,7.4,0.1c2.1,4.8,4.8,9.4,7.4,13.8c0.6,1.1,1.3,2.2,1.9,3.3
  c2.7,4.8,5.4,9.7,7.7,14.7c-11.7-1.9-23.9-1.5-37-1.2c-2.6,0.1-5.1,0.1-7.7,0.2c-9.6,0.2-19.1-0.2-28.4-0.9
  c4.3-9.4,8.6-18.5,14.9-25.4C344.2,61.9,353.3,60.5,362.3,58.9z M335.7,60.2c-1.7,0.3-3.4,0.7-5.1,1.1c-0.6,0-1.3,0-1.9,0
  c-0.1,0-0.1,0-0.2,0c-8.3,0-17-0.7-26-1.6c0-0.1,0-0.2,0-0.3c5.2,0.7,10.5,1.2,15.8,1.2c6.4,0.1,12.7-0.6,18.9-1.7
  C336.6,59.3,336.1,59.8,335.7,60.2z M302.9,68.3c0-0.8-0.1-1.6-0.1-2.4c-0.1-1.7-0.2-3.3-0.3-5c8.1,0.8,15.9,1.5,23.4,1.6
  c-6.9,1.8-13.7,4-20.5,6.6C304.6,68.8,303.8,68.5,302.9,68.3z M303.8,69.7c-0.3,0.1-0.5,0.2-0.8,0.3c0-0.2,0-0.4,0-0.5
  C303.2,69.6,303.5,69.6,303.8,69.7z M303,71.3c0.8-0.3,1.5-0.6,2.3-0.9c1,0.5,1.9,1.1,2.4,1.9c0.8,1.2,0.8,2.7,0.1,4.5
  c-0.1,0.3,0,0.6,0.3,0.8c0.3,0.1,0.6,0,0.8-0.3c0.9-2.2,0.8-4.1-0.2-5.6c-0.5-0.7-1.2-1.3-2-1.8c7.9-3,15.9-5.4,23.9-7.3
  c1,0,1.9,0,2.9-0.1c-6.3,7.1-10.5,16.2-14.6,25.2c-6-0.5-11.9-1-17.8-1.6C303,81.3,303.2,76.3,303,71.3z M299.9,85.9
  c-2.6-0.3-5.3-0.6-7.9-0.8c-4.9-0.5-9.8-1.1-14.6-1.5c8.1-4.5,16.2-8.4,24.5-11.8C302,76.6,301.7,81.4,299.9,85.9z M301.5,53.4
  c-1.7,1.3-3.5,2.6-5.2,3.9c-5.7-0.9-11.4-2.1-17-3.2c-1.5-0.3-2.9-0.6-4.4-0.9c6.5-2.4,12.7-4.4,18.7-6c3.5-0.9,7-1.9,10.5-2.8
  C302.6,47.3,301.9,50.3,301.5,53.4z M304.9,43.1c-3.9,1-7.7,2-11.6,3c-6.6,1.8-13.4,4-20.6,6.7c-1-0.2-2-0.4-2.9-0.6
  c0.5-1.7,1.1-3.3,1.7-4.8c0.1-0.1,0-0.3,0-0.4c1-0.5,2-1.1,3-1.6c0.8-0.4,1.5-0.8,2.3-1.2c2.1,1.4,4.5,2.1,7.1,0.9
  c2.3-1.1,4-2.9,5.5-4.9c5.2,0.2,10.5,0.5,15.7,0.9c0.4,0,0.7,0.1,1.1,0.1C305.8,41.7,305.3,42.4,304.9,43.1z M259.5,51.7
  c-4.6,1.9-9.4,3.5-14.1,4.9c-0.8,0.1-1.6,0.1-2.5,0.2c2.1-2.3,4.3-4.4,6.7-6.3C252.9,50.8,256.2,51.2,259.5,51.7z M241.2,57
  c-1.2,0.1-2.3,0.3-3.5,0.5c3-2.1,5.5-4.5,7.3-7.2c1,0,1.9,0.1,2.9,0.1C245.6,52.4,243.3,54.7,241.2,57z M191.7,83.3
  c1.9-2,3.9-3.9,6-5.7c-0.1,1.2-0.1,2.4,0,3.4c-2.1,1.9-4.2,3.9-6.3,5.8C191.8,85.7,191.9,84.5,191.7,83.3z M198.8,80
  c0-0.8,0-1.8,0.1-2.7c0-0.3,0-0.5,0.1-0.8c1.4-1.2,2.7-2.3,4.2-3.5c2.7-2.1,5.5-4,8.4-5.6c-0.5,0.5-1,1.1-1.5,1.6
  C206.4,72.7,202.6,76.4,198.8,80z M202.4,72.1c-1.2,0.9-2.3,1.9-3.4,2.8c0-1.8-0.2-3.6-1.4-4.9c-0.2-0.3-0.6-0.4-1-0.5
  c1.9-2,3.8-3.8,5.8-5.4c0.5,0.1,1,0.2,1.5,0.3c2.7,0.5,5.5,1.1,8.3,1.3C208.8,67.5,205.5,69.6,202.4,72.1z M196.7,70.8
  c1.1,1.3,1.2,3.2,1.1,5.2c-2.2,1.9-4.3,4-6.4,6c-0.5-1.5-1.3-2.7-2.4-3.7c2-2.5,4.1-5,6.3-7.4c0.1-0.1,0.1-0.1,0.2-0.2
  c0.3,0,0.5-0.1,0.7-0.1C196.4,70.6,196.6,70.7,196.7,70.8z M190.5,82.9c-4.7,4.8-9,9.8-13.1,14.9c0.8-2.1,1.6-4.3,2.4-6.4
  c2.7-4.2,5.5-8.3,8.6-12.2C189.4,80.1,190.1,81.4,190.5,82.9z M174.1,103.6C174.1,103.6,174.1,103.6,174.1,103.6
  c-1.9,5.9-3.5,11.8-4.7,17.7c-2,2.1-3.9,4.5-5.5,7.1c-1.8,3-3.2,6.1-4.3,9.3c-0.6-1.6-1.1-3.3-1.6-5c3.7-7.7,7.4-15.5,11.3-23.1
  c0.5-0.7,1.1-1.4,1.6-2C171.9,106.4,173,105,174.1,103.6z M172.7,103.3c0.3-0.6,0.6-1.1,0.9-1.7c0.2,0,0.4,0,0.6,0
  c-0.3,0.4-0.7,0.8-1,1.2C173.1,103,172.9,103.2,172.7,103.3z M153.6,118.8c-0.5-1.7-0.9-3.4-1.4-5.2c0.8-0.4,1.7-0.9,2.6-1.3
  c0.1,0,0.1-0.1,0.2-0.1C154.6,114.4,154.1,116.6,153.6,118.8z M152.9,120.5c-2.6,2.3-5.1,4.6-7.7,6.9c0.3-4.2,0.7-8.1,3.4-11.2
  c0.7-0.8,1.6-1.5,2.5-2C151.8,116.3,152.3,118.4,152.9,120.5z M152.9,122.1c-0.6,2.4-1.1,4.8-1.7,7.1c-2.4,2.5-4.8,4.9-7.4,7.3
  c0.8-2.3,1.1-4.9,1.3-7.4C147.7,126.7,150.3,124.4,152.9,122.1z M142.7,136.4c0-1.6-0.1-3.2-0.3-4.7c0.5-0.5,1-0.9,1.5-1.4
  C143.7,132.4,143.4,134.5,142.7,136.4z M142.6,139.2c2.8-2.5,5.5-5,8-7.7c-0.4,1.7-0.8,3.5-1.3,5.2c-1.1,4.6-2.2,9.3-3.3,14
  c-1.2,0.6-2.4,1.1-3.6,1.7c-1.1,0.6-2.6,1.2-4.1,1.8c-2.6,1.1-5.5,2.4-8.3,3.9c-0.2-2.4-0.4-4.9-0.6-7.3c0.9-0.2,1.7-0.4,2.5-0.6
  c4.4-1,8.3-1.9,10-9.2c0.1-0.4,0.2-0.8,0.2-1.2C142.4,139.5,142.5,139.4,142.6,139.2z M129.4,149.5c0-0.7-0.1-1.4-0.1-2.1
  c0.9-0.2,1.7-0.4,2.5-0.7c0,0,0,0.1,0,0.1c0.2,0.3,0.5,0.3,0.8,0.1c0.5-0.3,0.9-0.7,1.4-1c2.4-0.9,4.6-2.1,6.4-4
  c-1.7,5.4-4.7,6.1-8.8,7C131,149.1,130.2,149.3,129.4,149.5z M141.3,138.8c-2.5,2.1-5,4.2-7.7,6.1c-1.3,0.5-2.8,0.9-4.3,1.3
  c0-0.6,0-1.2,0-1.7c3.9-4,7.9-8,12.1-11.8C141.6,134.7,141.6,136.8,141.3,138.8z M130.1,166.9c-3.9,2-7.7,4.2-11.3,6.8
  c0.2-1,0.4-1.9,0.5-2.8c0.4-1.2,0.7-2.3,1.1-3.5c1.6-2.9,5-5.4,8.8-7.5C129.4,162.2,129.7,164.5,130.1,166.9z M123.6,153.3
  c-1.5,4.4-2.9,8.8-4.2,13.2c0-0.8-0.1-1.5-0.1-2.2C119,159.8,118.8,156.5,123.6,153.3z M124.2,151.6c-6.7,3.8-6.4,7.9-6.1,12.7
  c0.1,1.7,0.2,3.4,0.1,5.3c-0.3,1.1-0.5,2.1-0.7,2.9c-0.3,0.9-0.5,1.8-0.8,2.7c-1.7,1.3-3.3,2.7-4.8,4.2c-0.4,0.1-0.8,0.3-1.3,0.4
  c-0.3,0.1-0.5,0.4-0.4,0.7c0,0.1,0.1,0.3,0.3,0.3c-0.5,0.5-1,1.1-1.5,1.7c-0.2-2.2-0.4-4.4-0.4-6.6c2-4.2,3.9-8.4,5.9-12.7
  c0.6-1.4,1.2-2.8,1.9-4.3c2.9-3.5,5.9-6.8,9-10.1C124.9,149.7,124.6,150.6,124.2,151.6z M113.6,162.1c-0.1,0.2-0.2,0.4-0.3,0.6
  c-1.6,3.5-3.2,7-4.8,10.5c0-1.6,0.1-3.1,0.2-4.7C110.3,166.3,112,164.2,113.6,162.1z M108.7,190.3c-0.3,0.2-0.6,0.5-0.8,0.7
  c-1.6,1.2-3.4,2.2-5.3,3c1.7-3.2,3.5-6.1,5.6-8.7C108.3,187,108.5,188.7,108.7,190.3z M100.9,194.8c-0.6,0.2-1.2,0.5-1.7,0.7
  c2.8-5.8,5.6-11.6,8.4-17.4c0.1,1.9,0.2,3.7,0.4,5.6C105.3,187,102.9,190.6,100.9,194.8z M107.4,175.6c-3.2,6.9-6.5,13.7-9.9,20.6
  c-2.3,0.9-4.5,1.9-6.6,3.1c4.4-10.3,10-19.9,16.5-29C107.4,172,107.4,173.8,107.4,175.6z M96.8,197.7c-2,4.1-4,8.2-6.1,12.2
  c-0.3-0.9-0.7-1.8-1.2-2.8l-0.3-0.7c-0.2-0.4-0.4-0.9-0.5-1.3c0.5-1.3,1-2.7,1.5-4c0,0,0,0,0.1,0C92.2,199.7,94.4,198.7,96.8,197.7
  z M88.1,206.9l0.3,0.7c0.6,1.4,1.2,2.6,1.5,3.9c-2.4,4.8-4.8,9.5-7.2,14.3c0.7-3.6,1.6-7.2,2.6-10.6c0.8-2.9,1.7-5.7,2.7-8.4
  C88.1,206.8,88.1,206.8,88.1,206.9z M87.7,223.1c-0.7,1.4-1.6,2.6-2.6,3.5l-0.1,0.2c-0.3,0.7-0.7,1.4-1.1,2
  c-0.8,1.5-1.6,2.8-1.9,4.8c-0.2,0.1-0.5,0.2-0.7,0.3c0.2-1.3,0.4-2.6,0.6-3.9C83.9,227.6,85.8,225.3,87.7,223.1z M81.5,235.3
  c0.1-0.1,0.3-0.2,0.4-0.3c0,0.8,0.2,1.6,0.3,2.5c0.3,1.3,0.6,2.7,0.2,3.8l1.1,0.4c0.5-1.5,0.2-3.1-0.1-4.5
  c-0.2-0.9-0.4-1.7-0.3-2.4c0-0.1,0-0.2,0-0.3c0.8-0.2,1.7-0.1,2.6-0.1c1.2,0,2.4,0.1,3.5-0.4c-1.7,5.7-3.9,11.3-6.9,16.6
  c-0.9,1.7-1.9,3.3-3,4.9c0.2-6.9,0.8-13.6,1.8-20.1C81.3,235.4,81.4,235.4,81.5,235.3z M78.1,257.4c-2.4,3.5-5,6.9-7.7,10.2
  c0.9-3.6,1.5-7.3,2.2-11c0.8-5,1.7-9.8,3.1-14.4c0.9-1.8,1.9-3.7,2.8-5.5c0.6-1.1,1.1-2.2,1.7-3.3C79,241.1,78.4,249.2,78.1,257.4z
   M71.5,256.4c-0.8,4.4-1.5,9-2.8,13.4c-1,1.3-2.1,2.5-3.1,3.8c-0.8,1-1.6,1.9-2.4,2.9c0.6-4,1.1-8.2,1.6-12.4
  c2.8-5.6,5.6-11.3,8.4-16.8C72.5,250.3,72,253.4,71.5,256.4z M61.7,278.3c-0.6,0.7-1.1,1.4-1.7,2.1c-0.3-1.7-0.6-3.3-0.9-5
  c1.4-2.8,2.7-5.5,4.1-8.3C62.8,271,62.3,274.7,61.7,278.3z M59.3,283.2c0.1,0.3,0.1,0.6,0.2,0.9l0.2,1.4c-0.2,0.2-0.3,0.3-0.5,0.5
  c-1.7,1.6-3.4,3.2-4.8,5.6c-0.2-0.3-0.4-0.5-0.6-0.8C55.5,288.2,57.4,285.7,59.3,283.2z M54.6,293.8c1,1.2,1.9,2.5,2.9,3.7
  c-0.3,1.3-0.7,2.5-1,3.8c-1.2,4.4-2.3,8.7-3.3,13c0-0.8-0.1-1.6-0.1-2.4c-0.4-5.9-0.7-11.9,1.1-17
  C54.3,294.5,54.4,294.1,54.6,293.8z M57.6,301.6c0.3-1,0.5-1.9,0.8-2.9c1.6,2.1,3.1,4.3,4.6,6.5c0.1,0.5,0.1,0.9,0.2,1.4
  c-3.5,12.6-4.6,25.8-2.4,40.4c0,0,0,0.1,0,0.1c-1,2.5-1.9,5.1-2.8,7.7c-1-0.8-2-1.7-2.9-2.6c-2.2-6.8-3.1-13.1-3.1-19.1
  c0.3-0.1,0.5-0.3,0.8-0.4c3-1.9,4.5-5,4.2-8.5c-0.2-2.7-1.5-4.8-3.3-6.5C54.7,312.4,56.1,307.1,57.6,301.6z M57.6,356
  c-0.2,0.5-0.3,1.1-0.5,1.6c-0.3-0.7-0.5-1.3-0.8-2c-0.1-0.3-0.2-0.7-0.4-1C56.5,355.1,57,355.6,57.6,356z M49.7,334
  c0.4-0.1,0.7-0.2,1.1-0.4c0.1,5.4,0.8,11,2.5,16.9c-2.7-2.6-5.1-5.4-7.3-8.3c0.7-1.7,1.5-3.5,2.3-5.3C48.8,336,49.3,335,49.7,334z
   M53.4,319.1c1.3,1.4,2.2,3.1,2.4,5.2c0.2,3-1.1,5.7-3.7,7.4c0,0-0.1,0-0.1,0c0-1.3,0.1-2.7,0.2-4c0.3-1,0.5-1.9,0.7-2.8
  c0.3-1.8,0.4-3.6,0.4-5.5C53.3,319.3,53.4,319.2,53.4,319.1z M50.9,332.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.2-0.4,0.3-0.7,0.5-1.1
  C50.8,331.7,50.9,332,50.9,332.3z M41.4,333.3c2.1,1.1,4.6,1.5,6.9,1.1c-0.3,0.7-0.7,1.4-1,2.1c-0.7,1.5-1.4,3.1-2.1,4.7
  c-2-2.7-3.7-5.6-5.2-8.7C40.5,332.7,40.9,333,41.4,333.3z M38.1,331.2c1.8,3.9,4,7.7,6.6,11.3c-1.3,3.2-2.2,6.3-2.1,9
  c0.2,5.1,1.9,8.7,4.5,11.3c-0.4,5.2-1.8,10.4-4.5,13.9C37.4,361.8,35.9,346.3,38.1,331.2z M48.3,356.5l-1.2,0.1
  c0.1,1.5,0.1,3.1,0.1,4.7c-2-2.4-3.3-5.5-3.4-9.8c-0.1-2.3,0.6-5,1.7-7.9c2.5,3.3,5.4,6.4,8.7,9.4c0.3,1,0.7,2,1.1,3
  c0.4,1.1,0.9,2.3,1.3,3.4c-0.6,2.2-1.2,4.4-1.7,6.7c-2.5-0.8-4.7-2-6.5-3.7C48.5,360.2,48.5,358.1,48.3,356.5z M57.3,361.2
  c0.9,2.1,1.7,4.1,2.6,6.1c-1.4-0.2-2.7-0.5-3.9-0.9C56.3,364.7,56.8,362.9,57.3,361.2z M57.8,359.4c0.3-0.8,0.5-1.7,0.8-2.5
  c1.4,1.1,2.8,2.2,4.3,3.3c0.1,0,0.1,0.1,0.2,0.1c0.5,2.5,1,5,1.6,7.5c-1.2-0.1-2.3-0.2-3.4-0.3C60.1,364.9,58.9,362.2,57.8,359.4z
   M64.9,369c1.1,4.4,2.3,8.7,3.7,12.8c-2.4-4-4.7-8.3-6.9-13.1C62.8,368.8,63.9,368.9,64.9,369z M66.1,369c1.6,0.1,3.2,0.1,4.8,0.1
  c1.6,0,3.2,0,4.7-0.1c5,15,13.2,27.6,23.3,36.2c-1.4,0.5-2.8,1.1-4.1,1.7c-0.2-0.1-0.4-0.1-0.7-0.2c-7.8-2.8-15.4-9.6-22.4-20.1
  C69.5,381.1,67.6,375.2,66.1,369z M91.6,409.7l1.3-0.6c0.6-0.3,1.3-0.6,2-0.9c4.1,1.4,8.3,2.4,12.6,3.2c1.7,1,3.4,1.8,5.2,2.6
  c-8.4-0.1-16.5-1.1-24.1-2.8C89.7,410.6,90.7,410.1,91.6,409.7z M109.6,404.3c1.5,0.1,3,0.3,4.4,0.9c3,1.3,5.4,4.1,7.2,7
  c-2.7-0.4-5.5-0.7-8.1-1.1C111.8,408.9,110.7,406.6,109.6,404.3z M121.5,413.4c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.2,0.2,0.3
  c-1.9,0.1-3.7,0.2-5.6,0.2c-0.2,0-0.5,0-0.7,0c-0.4-0.2-0.9-0.3-1.3-0.5c-0.2-0.4-0.5-0.7-0.7-1.1
  C116.4,412.8,119,413.1,121.5,413.4z M115.8,415.1c2.8,1,5.7,1.9,8.6,2.6c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.2,1.2,2.2,1.9,3.1
  c-2.2-0.3-4.4-0.6-6.7-0.9C118.3,418.7,117,416.9,115.8,415.1C115.7,415.1,115.7,415.1,115.8,415.1z M125.9,418.2
  c6.6,1.5,13.4,2.4,20.4,2.9c1.1,0.9,2.3,1.8,3.4,2.7c-7-0.6-14.3-1.4-21.5-2.2C127.4,420.6,126.6,419.5,125.9,418.2z M149.3,431.3
  c0.5-0.5,1.1-1,1.9-1.3c2.2-0.9,4.4-0.7,6.8-0.5c2.6,1.7,5.3,3.2,7.9,4.7c-5.4-0.7-10.8-1.4-16.2-2.3
  C149.7,431.8,149.5,431.5,149.3,431.3z M160.6,429.8c1.5,0.1,3,0,4.4-0.4c0.7-0.2,1.4-0.5,2-0.8c2.4,2.1,4.6,4.3,6.7,6.6
  c-1.5-0.2-3-0.4-4.5-0.5C166.3,433.2,163.4,431.5,160.6,429.8z M175.3,435.1c-2.2-2.5-4.7-4.9-7.3-7.2c0.3-0.2,0.6-0.5,0.8-0.7
  c2.6,1.1,5.2,2.2,7.7,3.2c0.4,0.6,0.9,1.3,1.4,1.9c-0.9,0.9-1.7,1.9-2.5,3C175.3,435.2,175.3,435.2,175.3,435.1z M178.5,431.2
  c0.1,0,0.2,0.1,0.3,0.1c0,0-0.1,0.1-0.1,0.1C178.6,431.4,178.6,431.3,178.5,431.2z M178.6,433.3c0.7,1,1.5,1.9,2.3,2.8
  c-0.6-0.1-1.2-0.1-1.8-0.2c-0.8-0.1-1.6-0.2-2.4-0.3C177.3,434.8,178,434,178.6,433.3z M179.4,432.5c0.2-0.2,0.4-0.4,0.6-0.6
  c5.8,2.3,11.6,4.4,17.5,6.3c-5-0.7-9.9-1.3-14.9-1.9C181.6,435.1,180.5,433.8,179.4,432.5z M177.3,429.5c-0.4-0.6-0.9-1.3-1.3-2
  c5.1,0.7,9.6,1.4,13.5,2.4c12,3,24,6.6,35.5,10c8.9,2.7,17.8,5.3,26.5,7.7c-1.5,0.1-3,0.2-4.5,0.3c-8.2-2.1-16-4-23.7-5.6
  c-5.9-1.3-12.1-2.3-18.3-3.2C195.4,436.5,186.5,433.3,177.3,429.5z M225.3,438.8c-11.6-3.5-23.6-7.1-35.6-10.1
  c-4.1-1-9-1.8-14.5-2.5c-0.9-1.5-1.8-3-2.6-4.6c0-0.1,0.1-0.1,0.1-0.2c0.5,0,1,0,1.6,0c2.9,0,5.8-0.1,8.7-0.1
  c15.6,8.7,32.3,12.4,50.7,13.8c3.2,3.3,7.6,7.3,11.7,7.3c0.2,0,0.4,0,0.6,0c2.1-0.2,3.8-1.5,5.2-3.7c0.6-1,1.3-1.9,2-2.7
  c7.5,3.1,15.2,6.3,23.1,8.6c-7.2,1.1-14.3,2-21.3,2.7C245.1,444.8,235.3,441.9,225.3,438.8z M278.9,445.4c3.4,0.9,6.8,1.7,10.3,2.3
  c3.5,0.6,7,0.8,10.5,0.8c0.4,0,0.7,0,1.1,0c-2.5,0.5-5.1,1.1-7.7,1.7c-4.3,1-8.6,1.9-12.9,2.8c-7.3-1.3-14.7-2.9-22-4.8
  C265.1,447.5,272,446.6,278.9,445.4z M293.3,451.4c3-0.7,6-1.3,8.9-2c-2,2-3.9,4-6,5.8c-4.3-0.4-8.6-0.9-12.9-1.6
  C286.7,452.8,290,452.1,293.3,451.4z M304.3,449c3.9-0.8,7.7-1.5,11.3-1.9c0.5,0.4,0.9,0.8,1.2,1.2c1.6,2.2,1.9,4.7,2.2,7.1
  c0,0.1,0,0.2,0,0.3c-2.6,0.1-5.2,0.2-7.8,0.2c-4.5,0-9-0.2-13.4-0.6C300.1,453.3,302.2,451.2,304.3,449z M356.8,449.8
  c2.5,0.7,5,1.3,7.6,1.8c-3,0.9-5.9,1.8-8.6,2.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.2-1.5-2.4-2.8-3.2
  C354,450.8,355.4,450.3,356.8,449.8z M358.8,449.1c7.7-2.7,15-5.9,22-9.7c1,0.9,1.8,2,2.4,3.4c0.3,0.6,0.6,1.2,0.8,1.9
  c-2.9,1.2-5.9,2.4-8.9,3.6c-2.6,1-5.4,1.8-8.2,2.6C364.2,450.4,361.5,449.8,358.8,449.1z M375.6,449.4c3-1.2,6-2.4,8.9-3.6
  c0.1,0.3,0.2,0.6,0.3,0.9c-3.9,1.8-7.9,3.4-11.9,5c-1.1-0.1-2.1-0.2-3.2-0.4C371.7,450.7,373.7,450.1,375.6,449.4z M385.1,447.8
  c0.4,1.3,0.7,2.6,1,3.9c-1.3,0.1-2.5,0.2-3.8,0.3c-2.3,0.1-4.6,0.1-6.8,0C378.7,450.6,381.9,449.2,385.1,447.8z M424,442.6
  c0.2,0,0.3,0,0.5,0c1.5,0,3.1-0.1,4.7-0.4c-0.1,0.3-0.2,0.6-0.3,0.9c-3.7,1.1-7.5,2.2-11.4,3.2C419.6,445,421.8,443.8,424,442.6z
   M426.1,441.3c1.1-0.6,2.2-1.3,3.3-1.9c0.2,0.1,0.4,0.2,0.7,0.2c-0.2,0.4-0.3,0.8-0.5,1.2C428.4,441.1,427.2,441.3,426.1,441.3z
   M431.2,440.1c0.3,0.1,0.6,0.2,0.9,0.3c-0.4,0.1-0.7,0.2-1.1,0.2C431,440.4,431.1,440.2,431.2,440.1z M431.6,439
  c0.2-0.4,0.3-0.8,0.5-1.2c1.3-0.8,2.7-1.6,4-2.4c2.5,0.4,4.9,0.7,7.4,1c-3,1.2-6.2,2.5-9.4,3.4C433.3,439.5,432.4,439.3,431.6,439z
   M473.2,415c1.5-0.7,3-1.5,4.5-2.3c1.9-0.3,3.8-0.6,5.7-0.9c-0.4,0.7-0.7,1.4-1.1,2C479.3,414.1,476.3,414.5,473.2,415z M480.8,411
  c1.5-0.9,3.1-1.8,4.6-2.7c-0.4,0.7-0.8,1.4-1.2,2.1C483.1,410.6,481.9,410.8,480.8,411z M485.7,410.2c0.6-1,1.1-2.1,1.7-3.1
  c5-3.2,9.8-6.7,14.4-10.5c-1.7,3.9-3.7,7.8-6.1,11.6C492.4,408.9,489.1,409.6,485.7,410.2z M503.7,394.9
  c5.2-4.5,10.1-9.4,14.7-14.6c-2.9,6.9-5.9,13.7-9.1,20.6c-0.6,1.4-1.2,2.7-1.8,4c-3.3,1-6.7,2-10.2,2.8
  C499.9,403.5,502,399.2,503.7,394.9z M508.3,405.9c0.2-0.1,0.5-0.2,0.7-0.2c-0.4,0.5-0.8,1-1.3,1.5
  C507.9,406.8,508.1,406.3,508.3,405.9z M509,404.5c0.5-1,0.9-2,1.4-3.1c3.7-8,7.2-16,10.5-24.1c2.2-2.7,4.3-5.5,6.3-8.3
  c1.2,0.2,2.4,0.3,3.6,0.5c-0.1,2.6-0.4,5.2-0.8,7.6c-6.2,9-12.5,18.2-19.6,26.9C509.9,404.2,509.4,404.3,509,404.5z M538.7,391.1
  c0.8,1.2,1.4,2.4,1.5,3.8c0,0.2,0,0.4,0,0.6c-2,1.3-4,2.5-6,3.7c-1.5-0.9-3-1.7-4.7-2.2C532.8,395.2,535.8,393.2,538.7,391.1z
   M534.9,400.9c0.7,0.5,1.5,0.9,2.2,1.3c-3,2.3-7.7,3.2-11.9,4.1c-1.1,0.2-2.2,0.4-3.1,0.7c4.1-2,8.2-4.2,12.1-6.5
  C534.5,400.7,534.7,400.8,534.9,400.9z M535.5,400c0,0-0.1-0.1-0.1-0.1c1.7-1,3.3-2,4.9-3c-0.2,1.9-1,3.4-2.2,4.6
  C537.2,401,536.4,400.5,535.5,400z M541.4,394.7c-0.2-1.6-0.9-3-1.8-4.3c6.1-4.6,11.2-10,15.5-16.1c0.5,0.1,0.9,0.1,1.4,0.1
  c0.1,3,0,6-0.3,9.1C551.6,387.5,546.6,391.3,541.4,394.7z M552,348.4c1-1.2,2-2.5,3-3.7c0.7,7.5,1.7,15,3.1,22.4
  c-0.3,0.6-0.6,1.2-1,1.7C556.3,362,554.5,355.2,552,348.4z M558.5,368.9c0.1,0.7,0.3,1.3,0.4,2l0.4,2c0,0.2,0.1,0.4,0.1,0.6
  c-0.6,0-1.2-0.1-1.9-0.1c0-0.8-0.1-1.7-0.2-2.5C557.7,370.2,558.1,369.5,558.5,368.9z M557.6,374.5c0.7,0,1.4,0.1,2,0.1
  c0.3,1.6,0.7,3.1,0.9,4.6c-1,1-2.1,2.1-3.2,3.1C557.6,379.7,557.7,377.1,557.6,374.5z M567.9,306.3c-1.4,3.4-3.2,6.9-5.3,10.4
  c-1-2.7-1.5-5.1-0.9-6.8c0.5-1.6,3.1-2.6,5.4-3.5c0.3-0.1,0.6-0.2,0.8-0.3C567.8,306.1,567.9,306.2,567.9,306.3z M573,293.7
  c1.3,1,3,2.1,4.8,3.2c0.9,0.6,1.9,1.2,2.8,1.8c-1.8,1-3.8,1.9-5.8,2.8c-1.5,0.7-3.1,1.4-4.6,2.2C571.4,300.3,572.3,296.9,573,293.7
  z M565.2,203c4.8,0.7,8.3,3.1,10,6.8c2.2,4.6,1.3,11.1-2.1,16.7c-1.4,2.2-3.1,4.1-4.9,5.9c-0.2-0.7-0.4-1.4-0.6-2.1
  c0.4-9.3-0.3-18.6-3.3-27.5C564.6,202.8,564.9,202.9,565.2,203z M562.6,201.1c-0.6-0.2-1.2-0.5-1.8-0.8c0-1.2-0.1-2.5-0.1-3.7
  C561.4,198,562,199.5,562.6,201.1z M559.5,195.3c-0.1-0.6-0.3-1.2-0.4-1.9c0.1,0.2,0.2,0.4,0.3,0.6
  C559.4,194.4,559.4,194.8,559.5,195.3z M549,136.3c5.7,16.9,8.9,36,10.2,55c-0.3-0.5-0.6-1-0.9-1.5c0-0.2-0.1-0.3-0.1-0.5
  c-2.8-13.8-5.8-28.2-9.6-42.4c0.2-2.8,0.4-5.6,0.4-8.4C549.1,137.8,549,137.1,549,136.3z M547.8,132.9c0.2,3.7,0.2,7.3,0,11
  c-1-3.5-2-7-3.1-10.4c1-0.3,1.9-0.8,2.8-1.4C547.6,132.3,547.7,132.6,547.8,132.9z M547.5,128.9c-1.4-3.6-2.9-7-4.6-10.3
  c-0.3-8.5-1.5-17.2-6.3-23.8c0.2-0.2,0.3-0.4,0.5-0.6C543,106.1,546.3,117.5,547.5,128.9z M524.5,53c1.9-0.3,4,0.5,6.4,2.4
  c8.7,7,11.6,21.8,8.8,31.2c-0.6,1.9-1.4,3.6-2.5,5.2c-2-3.9-4.2-7.8-6.8-11.7c-1.4-2.1-2.6-4.6-3.9-7.2c-2.4-4.8-5-10.1-9-13.7
  C519.6,56.2,521.8,53.5,524.5,53z M511.3,56.7c1.8,0.6,3.4,1.5,4.8,2.6c-0.3,0.4-0.5,0.8-0.8,1.2c-1.4,2.2-2.9,4.5-4.5,6
  c-0.7-0.7-1.3-1.5-2-2.2c-1.5-1.6-2.9-3.2-4.4-4.8c0.4-1,0.8-2.1,1.2-3.1c0.1-0.2,0-0.3,0-0.5C507.4,55.9,509.3,56.1,511.3,56.7z
   M504.5,56.1c-0.3,0.9-0.7,1.7-1,2.6c-0.7-0.7-1.3-1.4-2-2C502.5,56.4,503.5,56.2,504.5,56.1z M503,59.9c-0.5,1.5-1,2.9-1.5,4.4
  c-2.3-1.8-4.8-3.4-7.2-4.9c2-0.9,4-1.7,5.9-2.3C501.1,57.9,502.1,58.9,503,59.9z M486.8,48.2c4.9,1.9,8.8,4.7,12.4,7.9
  c-2.1,0.7-4.1,1.6-6.2,2.5c-1.9-1.1-3.9-2.2-5.9-3.2c-0.7-2.1-1.4-4.2-2.1-6.4c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7
  c0.6,1.8,1.2,3.6,1.8,5.3c-5.3-2.6-10.9-4.7-16.6-6.5C475.1,46.8,481,45.9,486.8,48.2z M466.7,48.7c6.8,2,13.3,4.4,19.5,7.5
  c0.4,1.1,0.8,2.2,1.1,3.3c0.2,0.5,0.3,0.9,0.5,1.4c-2.6,1.1-5.2,2-7.8,2.4c-5.2-5.3-10.9-9.8-16.9-13.6c0.7-0.2,1.4-0.4,2.2-0.5
  C465.7,49,466.2,48.9,466.7,48.7z M464.5,48.1c-0.9,0.2-1.9,0.5-2.8,0.7c-1.5-0.9-3-1.7-4.5-2.5C459.6,46.8,462,47.4,464.5,48.1z
   M459.9,49.2c-3.2,0.7-6.5,1.2-9.7,1.2c0,0,0,0,0,0c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6c3.7,0.1,7.5-0.6,11.2-1.5
  c6.1,3.7,11.8,8.2,17,13.4c-0.2,0-0.4,0-0.6,0c-5.1,0.1-10.6-1-15.9-2c-1-0.2-2-0.4-3.1-0.6c-4.6-2.1-9.3-4-13.9-5.5
  c-0.4-3.2-0.8-6.8-3.6-8.5c-0.9-0.6-2-0.9-3.2-1c0.6-0.6,1.3-1.2,1.9-1.8c0.2-0.2,0.4-0.4,0.6-0.5c4,0.5,8,1.1,11.9,1.9
  C455.1,46.5,457.5,47.8,459.9,49.2z M447.8,43.4c-2-0.3-4-0.6-6-0.9c0.3-0.3,0.6-0.6,1-0.9C444.4,42.1,446.1,42.7,447.8,43.4z
   M439.4,40.6c0.7,0.2,1.3,0.4,2,0.6c-0.4,0.4-0.8,0.7-1.2,1.1c-2.2-0.3-4.4-0.5-6.6-0.7l-0.2-1.5c0-0.3-0.3-0.5-0.7-0.5
  c-0.3,0-0.5,0.3-0.5,0.7l0.2,1.3c-6.2-0.6-12.5-0.9-18.8-1.1l-1.6-1.8C420.9,37.7,430,38.1,439.4,40.6z M410.5,38.7l1.4,1.6
  c-3.3-0.1-6.7-0.2-10-0.2C404.8,39.5,407.6,39,410.5,38.7z M396.8,41.2c5.4,0,10.8,0.1,16.2,0.3l1.9,2.1c0.3,0.3,0.6,0.6,0.9,1
  c-3.9,0.9-7.6,2.3-11.2,3.8c-1.9,0.8-3.9,1.6-6,2.3c-0.4-0.5-0.9-1-1.3-1.6c-0.3-2.1-0.5-4.2-0.7-6.3c0-0.3-0.3-0.6-0.6-0.5
  c-0.3,0-0.6,0.3-0.5,0.6c0.1,1.3,0.2,2.7,0.4,4c-0.9-1.5-1.7-3.1-2.5-4.7C394.5,41.8,395.7,41.5,396.8,41.2z M376.4,27.6
  c5,0.3,9.2,3.2,12.4,8.4c0.8,1.3,1.5,2.7,2.2,4c-0.2,0-0.3,0-0.5,0c-3.2,0-6.6,0-10.2,0c0.3-0.4,0.5-0.9,0.6-1.3
  c0.5-2-1.8-4.3-4.2-5c-2.4-0.7-4.3,0.1-5.2,2.3c-0.4,1.1-0.6,2.3-0.6,3.6c-4.1-1.7-8.8-2.8-12.8-3.3
  C363.6,30.5,370.4,27.2,376.4,27.6z M357.3,37.3c3.7,0.4,8.1,1.3,12,2.8c-4.7,0-9.6,0.1-14.6,0.1c0.7-0.7,1.4-1.5,2.1-2.3
  C356.9,37.8,357.1,37.6,357.3,37.3z M336.2,39.1c0.1-0.4,0.2-0.7,0.2-1.1c6-0.7,11.8-1.1,17.5-0.9c0.6,0,1.2,0,1.8,0.1v0
  c-0.9,1.1-1.9,2.1-2.9,3.1c-5.6,0.1-11.3,0.2-17,0.3C336,40.1,336.1,39.6,336.2,39.1z M351.6,41.5c-1.9,1.7-4,3.1-6.1,4.4
  c-3.2,0-6.2-0.4-8.5-2.3c-0.7-0.6-1-1.1-1.1-1.8C341.2,41.6,346.5,41.5,351.6,41.5z M336.2,44.4c2.1,1.8,4.7,2.4,7.4,2.5
  c-3.7,2-7.6,3.7-11.6,5.5l-0.5,0.2l0.5,1.1l0.5-0.2c4.5-2,9.1-4,13.4-6.5c1.1,0,2.2-0.1,3.3-0.1c2.3-0.1,4.4-0.2,6.4,0.1
  c0.3,0,0.6-0.2,0.7-0.5c0-0.3-0.2-0.6-0.5-0.7c-2-0.3-4.2-0.2-6.6-0.1c-0.4,0-0.9,0-1.3,0.1c2-1.3,3.9-2.7,5.6-4.4
  c6.1-0.1,12-0.1,17.6-0.2c0.1,1.5,0.4,3,0.8,4.5c-1,0.9-2,1.8-3,2.8c-2.3,0-4.5,0.2-6.9,0.4c-2.2,0.2-4.5,0.5-6.7,0.9
  c-6.7,1.3-12.1,4-16.5,7.6c-6.8,1.4-13.6,2.2-20.6,2.1c-5.3-0.1-10.6-0.5-15.8-1.2c0-1.4,0.1-2.8,0.2-4.2c2.3-1.7,4.5-3.3,6.8-4.9
  c1.5-1,2.9-2.1,4.4-3.2c1.9-1.4,3.8-2.7,5.7-4c2.5,0.1,5,0.1,7.3,0.1c2.6-0.1,5.3-0.1,7.9-0.2C334.8,42.7,335.2,43.6,336.2,44.4z
   M321.2,40.8c0.2-0.1,0.4-0.2,0.6-0.4c4.5-0.9,9-1.7,13.5-2.2c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.6-0.3,1.2-0.4,1.8
  c-2.6,0.1-5.3,0.1-7.9,0.2C325,40.8,323.1,40.8,321.2,40.8z M334.9,34.6c0.4,0.7,0.5,1.5,0.5,2.4c-3.6,0.4-7.1,1-10.7,1.7
  c1.2-0.7,2.4-1.3,3.6-1.8C330.5,35.9,332.7,35.2,334.9,34.6z M328.5,31.6c2.5,0.1,4.5,0.9,5.7,2.1c-2.1,0.6-4.3,1.2-6.3,2.1
  c-2.3,1-4.4,2.2-6.5,3.5c-2,0.4-3.9,0.8-5.9,1.2c-2.3-0.1-4.6-0.3-6.9-0.4c1.4-1.6,3.1-3.1,4.8-4.3c1.1-0.8,2.3-1.4,3.5-2
  c3.5,1.2,6.7,1.3,9.1-1.4c0.2-0.3,0.4-0.6,0.6-0.9C327.2,31.5,327.8,31.5,328.5,31.6z M318.6,33.1c2.1-0.8,4.2-1.3,6.4-1.4
  C323.4,33.5,321.2,33.7,318.6,33.1z M309.8,28c4.9-3.5,9.6-6.9,13.6-4.4c1.3,0.8,2.3,2.5,2.6,4.3c0.1,0.9,0,1.7-0.2,2.5
  c-3.1,0.2-6,0.9-8.8,2.1c-1.8-0.7-3.8-1.6-5.7-2.5c-0.8-0.4-1.7-0.8-2.5-1.2C309.1,28.5,309.4,28.3,309.8,28z M291.8,36.6
  c2.5-3.9,4.8-7.6,9.3-8.5c1.4-0.3,3.2,0.2,5.2,1c-2.8,1.9-5.7,3.5-8.2,3.4c-0.4,0-0.6,0.2-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6
  c3.1,0.1,6.4-1.8,9.6-4c1,0.5,2,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2c-0.9,0.5-1.8,1-2.7,1.6c-2.1,1.4-4,3.2-5.6,5.1
  c-0.6,0-1.3-0.1-1.9-0.1c-5-0.4-10-0.7-15-0.9C290.8,38.2,291.3,37.4,291.8,36.6z M287.9,40.1c-1.3,1.6-2.7,3-4.5,3.8
  c-2,0.9-3.8,0.6-5.4-0.4c2.2-1.2,4.4-2.4,6.7-3.5C285.8,40,286.9,40.1,287.9,40.1z M282.1,40.1c-1.8,0.9-3.5,1.8-5.2,2.7
  c-0.9-0.7-1.7-1.4-2.4-2.3C276.9,40.2,279.5,40.1,282.1,40.1z M246.9,46.5c0.4-1.1,0.7-2.2,1-3.4c1.4-4.8,2.8-9.8,8.2-11
  c5.1-1.1,10.1,0.8,14.1,5.2c0.4,0.5,0.9,1,1.4,1.6c0.2,0.2,0.3,0.4,0.5,0.6c-1.9,0.3-3.7,0.6-5.5,1c-6.6,1.6-12.3,4.7-17.3,8.7
  c-1.2-0.1-2.4-0.2-3.5-0.2C246.2,48.3,246.6,47.4,246.9,46.5z M243.6,50.3c-2.1,3-5.3,5.6-8.8,7.7c-6.2,1.1-12.1,2.9-17.9,5.4
  c2.8-3,5.7-6,8.7-9.3c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0c-3.7,4-7.2,7.7-10.6,11.3c-3.4,0-6.8-0.7-10.1-1.3
  c-0.2,0-0.3-0.1-0.5-0.1C216.3,53.1,229.7,50,243.6,50.3z M191.5,68.7c1.8-2.3,3.4-4.1,5-5.5c1.5,0.1,2.9,0.4,4.4,0.6
  c-2,1.7-4,3.6-6,5.8c-2.1,0.5-4.7,2.1-6.1,3C189.7,71.3,190.6,70,191.5,68.7z M188.8,74.1c0.5-0.5,2.5-1.8,4.4-2.6
  c-1.8,2-3.5,4-5.1,6.1c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.1-0.4-0.2-0.7-0.3C187.2,75.7,188,74.8,188.8,74.1z M185.9,77.7
  c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.5,0.3,0.7,0.4c-1.8,2.4-3.6,4.8-5.3,7.3c0.1-0.2,0.1-0.3,0.2-0.5l0.4-1
  C183.6,82.1,184.6,79.7,185.9,77.7z M170.5,81.6c3.5,0,4.7-0.8,6.3-1.9c0.7-0.5,1.6-1.1,2.9-1.7c1.5-0.7,3.1-1,4.7-0.7
  c-2.4,3.4-5.2,6.5-9.3,8.4c-2.5,1.2-5.1,1.6-7.9,2.1c-1.9,0.3-3.9,0.7-5.8,1.3C163.1,84.8,165.9,81.6,170.5,81.6z M160.8,90.4
  c2.1-0.8,4.4-1.2,6.5-1.5c2.7-0.5,5.5-1,8.2-2.2c3.1-1.4,5.4-3.5,7.4-5.7c-0.4,0.9-0.8,1.9-1.2,2.8l-0.4,1c-0.9,2-1.7,4-2.5,6.1
  c-2,3.1-3.9,6.3-5.7,9.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0-1,0.1-1.5,0.1c-2.4,0.2-4.7,0.3-7.1,0.6c0.1-0.2,0.2-0.3,0.3-0.5
  c0.6-0.9,1.5-1.5,2.5-2.1c0.5-0.4,1.1-0.7,1.6-1.1c0.2-0.2,0.3-0.6,0.1-0.8c-0.2-0.2-0.6-0.3-0.8-0.1c-0.4,0.4-0.9,0.7-1.5,1.1
  c-1,0.7-2.1,1.4-2.8,2.5c-0.3,0.4-0.5,0.8-0.6,1.3c-1.5,0.2-3.1,0.4-4.6,0.7C158.7,98.4,159.4,94.1,160.8,90.4z M157.2,92.2
  c0.7-0.5,1.4-0.8,2.2-1.2c-1.3,3.8-1.9,8-2.5,11.3c-4.5,0.9-8.9,2.5-12.9,5.2C147.6,101.3,151.9,95.7,157.2,92.2z M142.7,109.9
  c4.4-3.5,9.1-5.3,14.1-6.4c-0.1,0.9-0.3,1.8-0.4,2.5c-0.3,1.6-0.6,3.2-1,4.8c-0.3,0.2-0.7,0.3-1,0.5c-0.8,0.4-1.6,0.8-2.4,1.2
  c-0.3-1.2-0.6-2.3-0.9-3.5c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0.3,1.3,0.7,2.5,1,3.8c-1.1,0.7-2.2,1.5-3.1,2.5
  c-3.1,3.5-3.4,8.1-3.8,12.6c0,0.2,0,0.3,0,0.5c-0.6,0.6-1.2,1.2-1.9,1.7c-0.4-2.3-1-4.5-1.6-6.8c-0.1-0.3-0.4-0.5-0.7-0.4
  c-0.3,0.1-0.5,0.4-0.4,0.7c0.7,2.5,1.3,5,1.7,7.5c-4.1,3.8-8,7.7-11.9,11.6c0-0.6,0-1.1,0-1.7c1-2.6,2-5.2,3.1-7.8
  c0.7-1.7,1.5-3.5,2.2-5.4C136.9,122.2,139.5,115.8,142.7,109.9z M140.3,111.9c-2.6,5.2-4.8,10.7-6.8,15.6c-0.8,1.9-1.5,3.7-2.2,5.4
  c-0.7,1.6-1.3,3.3-2,4.9c0.1-2.9,0.2-5.8,0.5-8.7c0-0.3-0.2-0.6-0.5-0.6c-0.3,0-0.6,0.2-0.6,0.5c-0.3,4-0.5,8-0.5,11.9
  c-0.6,1.7-1.3,3.4-1.9,5c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0-0.2,0.1-0.4,0.1c-0.6,0.2-1.2,0.3-1.8,0.4c-0.9,0.2-1.7,0.4-2.5,0.6
  C125.3,135.4,130.9,120.8,140.3,111.9z M119.8,149.8c1-0.3,2.1-0.6,3.3-0.9c0.1,0,0.3-0.1,0.4-0.1c-2,2.2-3.9,4.3-5.9,6.6
  C118.4,153.6,119.1,151.8,119.8,149.8z M118.4,150.3c-0.3,0.9-0.7,1.7-1,2.5c0.2-0.6,0.4-1.3,0.5-2c0-0.1,0-0.3,0-0.4
  C118.1,150.4,118.3,150.4,118.4,150.3z M111.1,157.3c1.3-3.3,3.2-5.1,5.6-6.3c-0.7,3.5-3,5.7-6,7.3
  C110.8,158,110.9,157.7,111.1,157.3z M85.3,190.6c-0.4-9.6,4.5-22.1,13.6-26.4c1.5-0.7,3.2-1.3,5-1.9c1.6-0.5,3.2-1.1,4.8-1.8
  c-0.6,2.4-1,4.9-1.2,7.5c-7.3,10-13.6,20.7-18.4,32.3c-0.6,0.4-1.2,0.9-1.8,1.4C86.2,198,85.5,194.2,85.3,190.6z M69.9,232.6
  c0.7-0.2,1.5-0.4,2.2-0.5c1-0.2,1.9-0.4,3.1-0.7c0.3-0.1,0.5-0.4,0.4-0.7c-0.1-0.3-0.4-0.5-0.7-0.4c-1.1,0.3-2,0.5-3,0.7
  c-0.5,0.1-1.1,0.2-1.6,0.4c3-10.6,7.9-20.2,16.6-27.6c0.2,0.5,0.3,1,0.5,1.4c-1.2,3.2-2.2,6.4-3.1,9.7c-1.3,4.7-2.4,9.7-3.3,14.7
  c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-2.7,3.7-4.5,7.6-5.8,11.6c-3.2,6.3-6.4,12.6-9.6,19
  C66.3,251.2,67.5,241.6,69.9,232.6z M57.1,266c-1.2-4.6-2.8-10.3-1.9-13.3c0.7-2.4,1.2-2.7,2.4-3.2c0.6-0.2,1.3-0.6,2.2-1.2
  c3.1-2.3,3.3-4.8,3.4-7.2c0.2-2.4,0.3-4.7,3.6-7c0.6-0.4,1.2-0.7,1.8-1c-2.4,9.3-3.6,19.3-4.7,29.1c-0.1,0.5-0.1,1-0.2,1.6
  c-1.6,3.3-3.2,6.5-4.8,9.8c-0.1-0.7-0.3-1.5-0.4-2.2C58.2,269.9,57.7,268,57.1,266z M58.3,277.3c0.3,1.5,0.5,2.9,0.8,4.4
  c-2.1,2.7-4.1,5.4-6.1,8.2c-0.2-0.2-0.3-0.4-0.5-0.6C54.4,285.3,56.3,281.3,58.3,277.3z M51.9,290.4c0.1,0.2,0.3,0.3,0.4,0.5
  c-0.8,1.3-1.6,2.5-2.4,3.8C50.6,293.3,51.2,291.8,51.9,290.4z M50,296.8c1-1.7,2-3.3,3.1-4.9c0.2,0.3,0.5,0.6,0.7,0.9
  c-0.3,0.5-0.5,1.1-0.7,1.7c-1.9,5.3-1.6,11.5-1.2,17.4c0.1,1.5,0.2,3,0.2,4.5c-2.4-1.7-5.2-3-7.8-4.1c-0.4-0.2-0.9-0.4-1.3-0.6
  C44.9,306.6,47.2,301.6,50,296.8z M35.1,318.7c-0.2-1-0.5-2.1-0.7-3.2c-0.7-3-1.5-6.3-1.9-9.5c2.5,2.7,5.6,4.8,8.4,6.2
  c0.2,0.1,0.3,0.1,0.5,0.2c-1.7,4.6-3.1,9.1-4.2,13.3C36.3,323.4,35.7,321.1,35.1,318.7z M30.4,380.7c-10.1-2.5-11.3-16.8-12.1-27.2
  c-0.1-1.8-0.3-3.5-0.5-5l-0.1-0.8c-1-8.3-2.5-20.9,7.7-22.6c4.4-0.7,7.5,1.7,10.7,4.3c-1.5,6.5-2.5,12.7-2.7,18.6
  c-0.6,11.9,1.6,22.4,6.4,31.4C37.2,381.2,34.1,381.6,30.4,380.7z M41.3,379.9c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.2,0.5-0.5,0.7-0.7
  c0.7,2,1.5,4,2.4,6C43.3,383.4,42.2,381.6,41.3,379.9z M59.4,399c-4.7-3.1-8.6-6.6-11.8-10.2c-1.7-3.5-3.2-7.1-4.5-10.6
  c3.2-3.8,4.6-9.3,5.1-14.3c1.8,1.5,4,2.6,6.4,3.4c-1.9,9.2-2,18.3,2,26c1.4,2.8,3.3,5.3,5.6,7.6C61.2,400.1,60.3,399.6,59.4,399z
   M57.6,392.6c-3.8-7.4-3.7-16.2-1.9-25.1c1.5,0.4,3.1,0.7,4.7,1c3.3,7.1,6.7,13.3,10.2,18.6c3.7,9,8.5,17,15.2,23.4
  c-6.8-1.8-13.2-4.2-19-7.1C62.9,400.3,59.7,396.7,57.6,392.6z M73.7,407.7c3.7,1.5,7.7,2.8,11.8,3.8c0,0-0.1,0-0.1,0.1
  c-0.4,0.2-0.8,0.5-1.3,0.7C80.4,411,76.9,409.5,73.7,407.7z M85.6,412.9c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.7
  c0.2,0,0.3,0.1,0.5,0.1c0.9,0.8,1.9,1.6,2.9,2.3C88.9,414,87.2,413.4,85.6,412.9z M90.1,412.7c7.7,1.6,15.8,2.5,24.2,2.5
  c1.1,1.7,2.4,3.3,3.8,5c-8.4-1.2-16.5-2.7-24.1-4.7C92.7,414.6,91.4,413.6,90.1,412.7z M100.7,419c-1-0.5-2-0.9-3-1.5
  c6.8,1.6,14.1,2.9,21.5,4c1.5,1.8,3.2,3.6,4.9,5.4C116,424.8,108.2,422.2,100.7,419z M146.7,432.9c-0.9,1.4-1.8,2.7-3.4,3.2
  c-3.9,1.1-10.3-3-15.1-6.9c6.1,1.4,12.3,2.6,18.6,3.6C146.8,432.8,146.8,432.8,146.7,432.9z M147.6,431.6
  c-7.2-1.2-14.3-2.5-21.2-4.2c-0.4-0.4-0.9-0.8-1.3-1.2c-1.5-1.5-2.9-3-4.2-4.5c2.2,0.3,4.4,0.6,6.6,0.8c3.1,3.4,6.6,4.1,11.2,5
  c1.3,0.3,2.7,0.5,4.2,0.9c2.2,0.5,3.8,1.6,5,2.9C147.7,431.4,147.6,431.5,147.6,431.6z M171.1,441.1c-3.1,4.6-6.5,7.2-9.5,7.2
  c0,0-0.1,0-0.1,0c-2.3-0.1-4.4-1.6-6.2-4.7c-1.1-1.8-1.8-3.7-2.6-5.6c-0.6-1.6-1.3-3.2-2.1-4.7c6.1,0.9,12.2,1.7,18.3,2.5
  c1.5,0.7,2.9,1.4,4.4,2.1C172.6,438.9,171.9,440,171.1,441.1z M175.3,437.5C175.2,437.5,175.2,437.5,175.3,437.5
  c0-0.1,0.1-0.2,0.1-0.3c0.1,0.2,0.2,0.3,0.4,0.5C175.6,437.7,175.4,437.6,175.3,437.5z M176.5,436.7c0.8,0.1,1.7,0.2,2.5,0.3
  c1,0.1,2.1,0.3,3.2,0.4c1.8,1.9,3.6,3.5,5.6,4.8c-3.4-1.1-6.7-2.3-10-3.6C177.3,437.9,176.9,437.3,176.5,436.7z M184.5,447.2
  c-2-0.8-4-4-5.8-6.9c4.1,1.6,8.2,3,12.5,4.2C189.7,446.6,187.4,448.4,184.5,447.2z M230.7,448.4c-0.2-0.2-0.5-0.1-0.7,0.1
  c-12.7-0.2-25.1-1.6-37-4.7c0.3-0.6,0.7-1.3,1-2c0.1-0.3,0-0.6-0.3-0.8c-0.3-0.1-0.6,0-0.8,0.3c-0.3,0.7-0.7,1.4-1.1,2.2
  c-0.8-0.2-1.5-0.4-2.3-0.6c0,0,0,0,0,0c0.2-0.3,0.1-0.6-0.2-0.8c-1.9-1.2-3.7-2.6-5.5-4.3c7,0.8,14,1.7,20.9,2.7
  c5.3,1.5,10.7,2.8,16.4,3.9c0,0,0.1,0,0.1,0c0.3,0,0.5-0.2,0.6-0.5c0-0.2,0-0.4-0.2-0.5c0.4,0.1,0.9,0.2,1.3,0.3
  c6.5,1.4,13.2,3,20.1,4.7C239,448.4,234.9,448.5,230.7,448.4C230.8,448.4,230.8,448.4,230.7,448.4z M232.6,449.6c0.1,0,0.2,0,0.3,0
  c4.6,0,9.2-0.2,13.9-0.5c1.2,0.3,2.4,0.6,3.6,0.9c6.4,1.6,12.7,3.2,19.1,4.7C256.1,456.6,243.3,456.2,232.6,449.6z M250.8,449
  c-0.1,0-0.1,0-0.2,0c1.4-0.1,2.8-0.2,4.2-0.4c7.4,1.9,14.8,3.7,22.2,5.1c-1.4,0.3-2.8,0.5-4.2,0.7
  C265.4,452.6,258.1,450.8,250.8,449z M275.7,455c1.5-0.3,3-0.5,4.5-0.8c4.9,0.8,9.9,1.5,14.9,2c-0.9,0.8-1.8,1.5-2.8,2.1
  C286.7,457.4,281.2,456.2,275.7,455z M293.9,458.6c0.9-0.7,1.9-1.5,2.8-2.3c4.8,0.4,9.7,0.7,14.6,0.7c2.6,0,5.3-0.1,7.9-0.2
  c0.2,1.6,0.6,3.2,1.4,4.6C311.8,461.2,302.9,460.1,293.9,458.6z M341.9,465.4c-4.3,1.8-12.5,1.1-16.9-1.3c-0.8-0.4-1.4-0.9-2-1.4
  c0.6,0,1.3,0,1.9,0c7.9,0,15.7-0.7,23.4-2.2C346.3,462.5,344.4,464.4,341.9,465.4z M349.8,458.9c-9.1,2-18.4,2.7-27.8,2.5
  c-1-1.4-1.4-3-1.6-4.7c5.1-0.3,10.3-0.9,15.6-1.8c5.2-0.9,10.1-2,15-3.4c0.3,0.2,0.6,0.3,0.9,0.5c1.1,0.7,1.8,1.8,2.5,2.9
  c0,0.1,0.1,0.1,0.1,0.2C352.7,456.2,351.2,457.5,349.8,458.9z M352.1,458.4c0.9-0.8,2-1.6,3-2.2c0.3,0.4,0.6,0.8,1,1.2
  C354.7,457.7,353.4,458.1,352.1,458.4z M357.3,457c-0.4-0.4-0.8-0.8-1.1-1.3c0,0,0.1,0,0.1-0.1c3.2-1.4,6.7-2.4,10.2-3.3
  c0.2-0.1,0.4-0.1,0.6-0.2c1.2,0.2,2.3,0.4,3.5,0.5C366.2,454.3,361.8,455.7,357.3,457C357.4,457,357.3,457,357.3,457z M366.1,460.8
  l-0.2-0.1c-2.3-0.7-5.1-1.6-7.3-2.9c4.9-1.4,9.7-3.1,14.5-5c2,0.2,4,0.3,6.1,0.3c1.1,0,2.2,0,3.3-0.1c1.3-0.1,2.7-0.2,4-0.3
  c0.6,2.2,1.1,4.4,1.9,6.6C381.2,461.9,373.6,463.1,366.1,460.8z M415.1,465.6c-6.5,5.1-15.7,7.1-21.6,0.3c-1.5-1.8-2.6-3.8-3.5-5.9
  c5.5-2.1,10.8-4.8,15.6-7.4c2.7-1.4,5.3-2.9,7.9-4.3c5.1-1.2,10.1-2.6,14.9-4C425.2,452.9,421.8,460.3,415.1,465.6z M430.2,442.6
  c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0-0.1,0-0.1c1.2-0.2,2.4-0.5,3.6-0.9c0.2,0.1,0.4,0.1,0.6,0.2C433.2,441.7,431.7,442.1,430.2,442.6z
   M436.7,440.5c-0.2,0-0.3-0.1-0.5-0.1c3.3-1.1,6.7-2.4,9.7-3.7c0.5,0,1,0.1,1.6,0.1C444.1,438,440.5,439.3,436.7,440.5z
   M440.8,441.4c-0.6-0.1-1.3-0.3-1.9-0.4c4.1-1.4,8-2.8,11.6-4.2c0.7,0,1.4,0,2.1,0c5.7,0,11.3-0.6,16.7-2
  C459.7,440.8,449.9,443,440.8,441.4z M473.8,431.9c-0.4,0.3-0.7,0.5-1.1,0.8c-6.2,2-12.6,2.9-19.1,2.9c7.5-2.9,15.1-6.1,22.5-9.4
  C475.3,428.1,474.5,430,473.8,431.9z M477.6,425.6c0.7-0.3,1.4-0.6,2.1-0.9c0.9-0.4,1.7-0.8,2.6-1.2c0,0.4,0,0.8,0,1.1
  c-2.2,2.2-4.5,4.2-6.8,5.9C476.2,428.9,476.9,427.3,477.6,425.6z M476.5,431.4c2-1.6,3.9-3.3,5.8-5c0,0.8,0.1,1.6,0.1,2.3
  C480.5,429.7,478.5,430.6,476.5,431.4z M483.1,418c-0.3,0-0.6,0.3-0.6,0.6c0,0.8,0,1.8-0.1,2.9c0,0.2,0,0.5,0,0.7
  c-1.3,0.5-2.5,0.9-3.8,1.3c1.4-3,2.9-5.8,4.5-8.7c2.9-0.3,5.7-0.7,8.6-1c-1.7,2.3-3.5,4.5-5.4,6.7c-0.9,0.4-1.8,0.8-2.7,1.2
  c0-0.1,0-0.2,0-0.3c0-1.1,0.1-2.1,0.1-2.9C483.7,418.2,483.4,418,483.1,418L483.1,418z M484.3,422.7c-0.2,0.3-0.5,0.5-0.7,0.7
  c0-0.2,0-0.3,0-0.5c0.1,0,0.2-0.1,0.3-0.1C484,422.8,484.1,422.8,484.3,422.7z M483.5,425.2c1.1-1.1,2.2-2.2,3.2-3.4
  c3.7-1.4,7.5-2.8,11.3-4.3c-4.7,4.4-9.5,7.8-14.4,10.5C483.5,427.2,483.5,426.2,483.5,425.2z M500.8,420.9
  c-1.7,4.3-4.8,12.3-10.2,13.9c-2,0.6-3.6,0.5-4.7-0.4c-1.3-1-1.9-2.9-2.2-5.1c5.8-3.1,11.5-7.4,17.2-12.9c0.7-0.3,1.4-0.5,2-0.8
  C502.2,417.4,501.5,419.1,500.8,420.9z M504.2,411.3c-0.1,0.1-0.2,0.3-0.4,0.4c-3.2,0.2-6.4,0.5-9.6,0.8c0.8-1.1,1.5-2.2,2.2-3.4
  c3.5-0.8,7-1.8,10.4-2.8c-0.6,1.3-1.1,2.5-1.6,3.8C504.9,410.5,504.5,410.9,504.2,411.3z M508.8,411.4c-1,0.1-2,0.1-2.9,0.2
  c0.1-0.3,0.2-0.5,0.3-0.8c1.7-1.9,3.3-3.8,4.8-5.7c4.4-1.5,8.6-3.1,12.7-5.1c1.4-0.7,2.8-1.4,4.2-2.1c1.9,0.3,3.6,1.1,5.1,2
  c-6,3.5-12.2,6.6-18.6,9.5C512.6,410,510.7,410.7,508.8,411.4z M544.1,409.2c-0.1,1-0.2,1.9-0.3,2.9c-0.2,0-0.3,0-0.5-0.1
  c-2.2-0.3-4.5-0.7-6.7-0.8c-7.8-0.4-15.6-0.4-23.4-0.1c0.5-0.2,1.1-0.5,1.6-0.7c1.9-0.6,3.8-1.3,5.7-1.8c1.4-0.4,3.1-0.8,4.9-1.1
  c4.3-0.9,9.5-1.9,12.8-4.6c1.5,0.7,3.2,1.3,5.1,1.3c0.5,0,1,0,1.5-0.1C544.5,405.8,544.3,407.5,544.1,409.2z M546.4,402.4
  c0.1-0.4,0.2-0.9,0.4-1.3c1.4-4.6,4.2-7,7.6-8.5C553.1,397,550.9,400.9,546.4,402.4z M556,390.8c0.2-0.9,0.4-1.8,0.6-2.6
  c0.3-1.4,0.5-2.7,0.6-4.1c1.2-1.1,2.4-2.3,3.6-3.4c0.6,3.3,0.9,6.3,0.9,9C559.7,389.9,557.8,390.2,556,390.8z M580.6,384.2
  c-4.1,3.6-9.9,4.3-15.5,5c-0.7,0.1-1.5,0.2-2.2,0.3c0.1-3-0.4-6.2-1-9.8c1.7-1.7,3.3-3.5,4.8-5.4c2.4-0.4,4.5-1.1,6.3-2.2
  c2.5-1.6,4.1-3.9,4.8-6.9c0.1-0.3-0.1-0.6-0.4-0.7c-0.3-0.1-0.6,0.1-0.7,0.4c-0.6,2.7-2,4.7-4.3,6.2c-1.3,0.8-2.9,1.4-4.6,1.8
  c5-6.4,9-13.4,11.8-21.2c0.7,1.4,1.4,2.8,2,4.3C587.9,370.4,585,380.2,580.6,384.2z M588.2,307.2c0.5,3.1-1.3,5.7-3.8,8
  c-0.1-4.8-0.5-9.7-1.1-14.6C585.9,302.6,587.9,304.7,588.2,307.2z M579.3,348.6c-1.2-2.2-2.5-4.4-3.8-6.6
  c-2.2-3.8-4.5-7.7-6.4-11.7c0,0,0,0,0-0.1c0-0.3,0.1-0.5,0.1-0.8c2.7-2.6,5.5-5.1,8.5-7.4c0.7-0.5,1.5-1.1,2.3-1.7
  c1.1-0.8,2.2-1.6,3.3-2.5C583.5,328.5,582.3,338.9,579.3,348.6z M582.1,299.7c0.7,5.6,1.1,11.1,1.2,16.6c-1.3,1.1-2.7,2.1-4,3.1
  c-0.9,0.6-1.7,1.2-2.4,1.8c-2.7,2.1-5.2,4.4-7.7,6.7c0.1-1,0.1-2.1,0.1-3.2c0.2-6.1,0-12.1-0.3-18c0.2-0.4,0.3-0.8,0.5-1.2
  c0.1,0,0.1-0.1,0.2-0.1c1.8-1,3.7-1.8,5.6-2.7c2.2-1,4.4-2,6.4-3.1C581.8,299.5,582,299.6,582.1,299.7z"
              />
            </g>
            <g title="Intermediate filaments" className={locations.includes('Intermediate filaments') ? 'intermediate_filaments' : 'intermediate_filaments--disabled'}>
              <title>Intermediate filaments</title>
              <path
                d="M590.8,287.8c0.9-6-0.2-8.3-4.4-13.2
  l-0.9,0.8c4,4.7,4.9,6.7,4.1,12.3c-0.6,4.4-3.2,7.3-6.7,9.6c-2.6-19.4-7.9-39.1-12.9-57.6c-0.5-2-1.1-4-1.6-6
  c2.1-1.9,4.1-4,5.6-6.5c3.7-6,4.5-12.9,2.2-17.9c-1.9-4.1-5.7-6.7-10.9-7.5c-0.5-0.1-1-0.2-1.5-0.3c-0.9-2.6-2-5.2-3.3-7.8
  c-1.2-21.2-4.9-42.7-11.6-61.3c0-0.4-0.1-0.9-0.1-1.3c2.4-2,4.1-4.9,4.7-8.3c0.9-5.5-0.8-12.4-5.2-20.5l-1.1,0.6
  c4.3,7.8,6,14.5,5.1,19.7c-0.5,2.8-1.8,5.2-3.6,7c-1.1-12.3-4.7-24.3-10.8-36.5c1.2-1.8,2.3-3.8,3-6.1c3.3-11.2-0.8-25.8-9.2-32.5
  c-2.7-2.1-5.1-3-7.3-2.6c-3.1,0.5-5.5,3.3-7.6,6.5c-1.5-1.2-3.1-2.1-5-2.8c-3.8-1.3-7.5-0.9-11.1,0.1c-3.8-3.5-8-6.5-13.2-8.6
  c-6.7-2.6-13.7-1.3-20.5,0.4c-4.5-1.3-9.1-2.4-13.8-3.3c-3-1.4-6.1-2.5-9.2-3.6c2.7-2.5,5.6-4.9,8.7-6.8c0.2-0.1,0.4-0.3,0.7-0.4
  c0.3,0.1,0.6,0.3,1,0.4c4.2,1.7,8.5,3.5,13.3,4.4c0.3,0.1,0.7,0.1,1.2,0.3c3.4,0.8,9.8,2.2,11.1-1.2c0.2-0.6,0.1-1.3-0.3-1.9
  c-1.8-3-10.3-6.3-12.1-6.5c-5.3-0.7-10.5,1.3-14.2,3.3c-1.6-0.7-3.3-1.3-5-1.9c-12.3-4.3-23.8-0.5-34.6,3.8l0.4,1.1
  c11-4.4,21.8-8,33.8-3.8c1.4,0.5,2.7,1,4.1,1.5c-3.4,2.1-6.4,4.6-9.3,7.3c-0.9-0.3-1.8-0.5-2.7-0.8c-9.8-2.7-19.4-3-28.7-2
  l-0.3-0.3c-0.2-0.2-0.6-0.3-0.8,0c-0.1,0.1-0.2,0.3-0.2,0.5c-4.4,0.5-8.7,1.4-13,2.4c-1.4,0-2.9,0-4.3,0c-0.8-1.6-1.6-3.2-2.5-4.7
  c-3.3-5.5-7.9-8.6-13.3-8.9c-6.6-0.5-14.1,3.2-19.8,9.7c-0.9-0.1-1.8-0.2-2.7-0.2c-5.7-0.2-11.5,0.2-17.5,0.9
  c0-0.9-0.2-1.7-0.5-2.5c4.3-1,8.8-1.4,13.1-1.8l0.5,0c0.3,0,0.6-0.3,0.5-0.6c0-0.3-0.3-0.6-0.6-0.5l-0.5,0
  c-4.5,0.4-9.2,0.9-13.7,1.9c-1.3-1.7-3.8-2.7-6.9-2.9c-0.5,0-1,0-1.5,0c0.2-0.8,0.3-1.7,0.1-2.6c-0.3-2.1-1.5-4.1-3.1-5.1
  c-4.7-3-9.9,0.8-14.9,4.4c-0.5,0.4-1.1,0.8-1.6,1.2c-2.4-1.1-4.7-1.7-6.6-1.4c-5,1-7.6,5.1-10.1,9.1c-0.6,1-1.3,2-2,3
  c-5.3-0.1-10.4-0.1-15.2,0.5c-0.3-0.4-0.7-0.8-1-1.2c-0.5-0.6-1-1.1-1.4-1.6c-4.3-4.8-9.7-6.7-15.2-5.6c-6.1,1.3-7.7,6.9-9.1,11.8
  c-0.3,1.2-0.6,2.3-1,3.3c-0.4,1-0.8,2-1.4,2.9c-14.7-0.4-28.8,2.8-42.3,13.8c-1.4-0.3-2.9-0.5-4.3-0.7c1.2-0.9,2.3-1.6,3.5-2
  c0.3-0.1,0.5-0.4,0.3-0.7c-0.1-0.3-0.4-0.5-0.7-0.3c-1.5,0.5-3.1,1.5-4.7,2.9c-0.9-0.1-1.7-0.1-2.6-0.1c-0.1,0-0.2,0-0.3,0
  c-11.3,0-22.2,4.3-29.4,11.4c-2.7,2.7-4.4,6-6,9.2c-0.8,1.5-1.6,3-2.5,4.5c-2.2,3.6-4.6,4.9-7.7,6.6c-1.2,0.7-2.5,1.3-3.8,2.2
  c-9.8,6.4-18.8,14-18.2,25.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6c-0.6-11.6,8.8-18.8,17.7-24.6
  c1.3-0.9,2.6-1.5,3.7-2.2c3.1-1.7,5.7-3.1,8.1-7c0.9-1.5,1.7-3.1,2.5-4.6c1.7-3.2,3.2-6.2,5.8-8.9c7-7,17.8-11.2,28.8-11.1
  c0.5,0,0.9,0,1.4,0c-1.4,1.3-2.8,3-4.4,4.9c-1.3,1.7-2.6,3.6-3.7,5.4c-0.6,0.9-1.2,1.9-1.9,2.8c-2-0.4-4.1-0.2-5.9,0.7
  c-1.3,0.7-2.2,1.2-3,1.7c-1.6,1-2.5,1.7-5.7,1.7c-5.4,0.1-8.6,4.1-10.5,9.1c-1.1,0.4-2.2,1-3.3,1.7c-6.2,4.1-10.9,10.7-14.8,17.9
  c-0.1,0.1-0.3,0.2-0.4,0.3c-10.9,9-17,25-22.4,39.2c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c0-1.9-0.6-3.8-1.8-5.8l-1,0.6
  c1.1,1.9,1.7,3.8,1.6,5.6c-2.9,1.3-5.3,3.3-6.9,7.3c-0.3,0.7-0.5,1.4-0.7,2.1c-1.8,0.8-3.7,1.5-5.6,2.1c-1.9,0.6-3.6,1.2-5.2,1.9
  c-9.5,4.4-14.8,17.5-14.3,27.5c0.2,3.9,1,8,2.3,11.9c-9.3,7.8-14.4,17.9-17.5,29.1c-0.9,0.3-1.8,0.8-2.8,1.4
  c-3.7,2.6-3.9,5.4-4.1,7.8c-0.2,2.3-0.3,4.4-3,6.3c-0.8,0.6-1.4,0.8-2,1.1c-1.4,0.6-2.3,1.1-3,3.9c-0.9,3.3,0.6,9,1.9,13.9
  c0.5,2,1.1,3.9,1.3,5.3c0.2,1.2,0.4,2.5,0.7,3.7c-2.1,4.3-4.2,8.6-6.3,13c-0.4-0.5-0.9-1.1-1.3-1.6c-0.2-0.2-0.6-0.3-0.8-0.1
  c-0.2,0.2-0.3,0.6-0.1,0.8c0.6,0.7,1.1,1.3,1.7,2c-0.1,0.1-0.1,0.2-0.2,0.3c-3.7,7.6-6.7,14.8-9.2,21.5c-0.1-0.1-0.3-0.1-0.4-0.2
  c-3.1-1.5-6.5-4.1-9.1-7.1c-0.2-2.8,0-5.5,0.9-8c0.9-2.4,2.3-3.6,4-5.1c0.8-0.7,1.7-1.4,2.5-2.3c3-3.3,3.9-7.1,4.9-11.2l0.1-0.4
  c1-4.3,2.7-6.4,6.1-9.6c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0c-3.2,3.1-5.3,5.4-6.4,10.2l-0.1,0.4
  c-0.9,3.9-1.8,7.6-4.6,10.7c-0.8,0.8-1.6,1.5-2.4,2.2c-1.7,1.5-3.3,2.8-4.3,5.6c-0.8,2.2-1.1,4.5-1,6.9c-1.2-1.7-2.1-3.5-2.5-5.3
  c-0.8-3.1-0.1-6.1,1.9-8.7l-1-0.7c-2.2,2.9-2.9,6.2-2.1,9.7c0.6,2.5,2,5,3.8,7.1c0.3,3.8,1.2,7.6,2,11.2c0.3,1.1,0.5,2.2,0.7,3.2
  c0.7,3,1.5,5.9,2.6,8.8c0,0.2-0.1,0.3-0.1,0.5c-3.2-2.6-6.5-5-11.2-4.2c-11.4,1.9-9.7,15.7-8.7,23.9l0.1,0.8c0.2,1.5,0.3,3.2,0.5,5
  c0.9,10.8,2.1,25.5,13,28.2c1.4,0.3,2.7,0.5,3.9,0.5c2.4,0,4.5-0.6,6.4-1.9c1.7,3.1,3.8,6,6.2,8.7c0.1,0.2,0.2,0.4,0.3,0.5
  c0.1,0.2,0.3,0.3,0.5,0.3c3.3,3.6,7.1,6.9,11.5,9.8c2.4,1.6,4.9,3.1,7.5,4.4c4.6,3.5,10.2,6.4,16.5,8.7c-4.4,2.4-9,4.4-13.5,3.8
  c-5.5-0.8-9.9-5.2-13-13.1l-1.1,0.4c3.2,8.3,7.9,13,13.9,13.8c5.2,0.7,10.4-1.8,15.2-4.4c3,1.1,6,2,9.3,2.9
  c2.1,1.3,4.3,2.5,6.7,3.6c8.1,3.5,16.7,6.3,25.5,8.4c3.8,3.5,10.9,8.9,16.1,8.9c0.6,0,1.1-0.1,1.7-0.2c2.1-0.6,3.1-2.1,4.1-3.6
  c0.1-0.2,0.3-0.4,0.4-0.6c0.3,0.1,0.7,0.1,1,0.2c1,1.6,1.7,3.4,2.5,5.4c0.8,1.9,1.5,3.9,2.6,5.8c2,3.4,4.4,5.2,7.2,5.2
  c0.1,0,0.1,0,0.2,0c4.6,0,8.6-4.8,10.5-7.7c0.8-1.2,1.6-2.3,2.4-3.4c0.1,0,0.2,0.1,0.3,0.2c0.7,0.3,1.4,0.6,2.1,0.9
  c0.2,0.3,0.4,0.6,0.6,1c2.1,3.3,4.2,6.8,6.6,7.8c0.8,0.3,1.6,0.5,2.3,0.5c2.2,0,4.2-1.4,5.9-4c12.2,3.3,25,4.7,38.1,4.9
  c7.8,5.4,16.9,7.2,26.5,7.2c5.1,0,10.4-0.5,15.6-1.3c6.1,1.4,12.2,2.7,18.2,3.8c-4.9,3.3-10.4,5.4-17.1,5.4c0,0,0,0,0,0l0,1.2
  c0,0,0,0,0,0c7.5,0,13.5-2.5,18.8-6.3c9.7,1.7,19.3,2.8,28.8,3.1c0.7,0.9,1.7,1.7,3,2.5c2.9,1.6,7.2,2.5,11.2,2.5
  c2.5,0,4.9-0.3,6.7-1.1c2.8-1.1,4.9-3.3,7-5.5c0.3-0.3,0.7-0.7,1-1c0.1,0,0.2,0,0.4-0.1c2.1-0.5,4.3-1,6.4-1.6
  c2.4,1.8,5.7,2.8,8.4,3.6l0.2,0.1c2.9,0.9,5.8,1.2,8.6,1.2c4.9,0,9.7-1.1,14.3-2.7c0.9,2.2,2.1,4.4,3.7,6.3
  c2.9,3.4,6.6,4.7,10.4,4.7c4.5,0,9.2-2,12.9-4.9c7.2-5.6,10.7-13.6,14-22.6c2.4-0.7,4.7-1.5,7-2.2c1.3,0.3,2.5,0.6,3.8,0.8
  c2,0.4,4.1,0.5,6.1,0.5c9.2,0,18.2-3.6,26.4-9.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3,0.1,0.6,0.4,0.7c0.1,0,0.1,0,0.2,0
  c0.2,0,0.5-0.2,0.6-0.4c0.2-0.5,0.3-1,0.5-1.4c2.7-0.9,5.4-2.1,8.1-3.5c0.4,2.3,1.1,4.3,2.5,5.4c0.9,0.7,1.9,1,3.2,1
  c0.8,0,1.7-0.1,2.6-0.4c6-1.8,9.3-10.1,11-14.6c0.8-2.1,1.7-4.2,2.5-6.3c2-0.8,4-1.7,6-2.5c8.8-0.4,17.5-0.5,26.2,0
  c2.1,0.1,4.4,0.5,6.5,0.8c0.2,0,0.3,0,0.5,0.1c-0.3,1.8-0.7,3.6-1.3,5.2c-2.2,5.8-7.2,8.3-12.1,10.4l0.5,1.1
  c5.2-2.2,10.4-4.9,12.7-11.1c0.7-1.8,1.1-3.6,1.4-5.5c4.7,0.7,9.5,1.1,14-0.6c4.2-1.6,7.8-5.4,9-9.6c1-3.3,0.4-6.4-1.6-9.1
  c-0.2-0.3-0.6-0.3-0.8-0.1c-0.3,0.2-0.3,0.6-0.1,0.8c2.3,3,2,6.1,1.4,8.1c-1.1,3.8-4.4,7.4-8.3,8.8c-4.3,1.6-8.9,1.2-13.4,0.5
  c0.1-1,0.2-2,0.3-3c0.2-1.9,0.4-3.8,0.8-5.6c0.1,0,0.2-0.1,0.4-0.1c5.3-1.6,7.8-6.2,9.2-11.5c1.8-0.6,3.9-1,5.9-1.3
  c-0.6,7-4.3,12.4-15,18.2c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.2,0,0.3-0.1c11.2-6.1,15.1-11.9,15.6-19.4
  c0.8-0.1,1.6-0.2,2.4-0.3c5.8-0.7,11.9-1.4,16.2-5.3c7.6-6.8,5.4-20.2,1.3-29.7c-0.8-1.8-1.7-3.6-2.6-5.3
  c3.4-10.5,4.6-21.7,4.4-33.3c3-2.6,5.5-5.8,4.9-9.7c-0.5-3.2-3.1-5.8-6.2-8c0-0.2,0-0.3-0.1-0.5C587,296.1,590.1,292.9,590.8,287.8
  z M467,30.1c1.5,0.2,9.7,3.4,11.2,6c0.3,0.5,0.2,0.8,0.2,0.9c-0.9,2.4-7,1-9.7,0.4c-0.5-0.1-0.9-0.2-1.2-0.3
  c-4.6-0.9-8.7-2.5-13.1-4.3c0,0,0,0,0,0C457.9,31,462.4,29.5,467,30.1z M569,239.9c5,18.6,10.4,38.5,12.9,58c0,0-0.1,0.1-0.1,0.1
  c-1.1-0.7-2.2-1.4-3.3-2.1c-2-1.2-3.9-2.4-5.2-3.6c2.9-16.5-1-32.1-7.1-47.8c0.5-3.3,0.9-6.6,1.1-9.9c0.1-0.1,0.2-0.1,0.2-0.2
  C568,236.3,568.5,238.1,569,239.9z M568.2,324.6c0,1.2-0.1,2.4-0.2,3.6c-0.2-0.5-0.5-0.9-0.8-1.5c-1.3-2.5-3-5.7-4.2-8.7
  c1.9-3.1,3.6-6.2,5-9.2C568.3,314.1,568.4,319.3,568.2,324.6z M560.6,373.5c-0.1-0.3-0.1-0.6-0.2-0.9l-0.4-2
  c-0.2-1.1-0.4-2.2-0.7-3.3c5.2-9.9,8.4-21.5,9.6-34.7c1.7,3.5,3.7,6.8,5.6,10.1c1.5,2.5,2.9,5,4.3,7.6c-2.8,8.5-7.2,16.1-12.8,22.9
  C564.3,373.4,562.5,373.5,560.6,373.5z M564.9,374.5c-1.1,1.3-2.2,2.5-3.4,3.7c-0.2-1.1-0.5-2.3-0.7-3.6c0.1,0,0.3,0,0.4,0
  C562.5,374.7,563.7,374.6,564.9,374.5z M555.5,387.9c-0.2,1.1-0.4,2.2-0.7,3.3c-4,1.5-7.4,4.2-9.1,9.6c-0.2,0.7-0.4,1.4-0.5,2.1
  c-2.2,0.4-4.1,0-5.9-0.8c1.4-1.5,2.3-3.4,2.3-5.9c5.1-3.3,10-6.9,14.5-10.9C555.8,386.1,555.6,387,555.5,387.9z M525.7,397.7
  C525.7,397.7,525.7,397.7,525.7,397.7c-0.8,0.4-1.6,0.8-2.4,1.2c-3.5,1.6-7.1,3.1-10.8,4.4c6-7.5,11.6-15.4,16.9-23.2
  c-1.6,6.4-4.5,12.2-9.3,16.8c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c5.7-5.4,8.8-12.3,10.2-20.1
  c0.6-0.8,1.2-1.7,1.7-2.5c1-1.5,2-3,3.1-4.5c0.5,0.1,1.1,0.3,1.7,0.4c0.8,0.2,1.6,0.4,2.4,0.6c-2.9,3.1-5.6,6.6-6,9.9
  c-0.4,3.2,1.4,5.4,3.1,7.7c0.3,0.4,0.6,0.7,0.8,1.1c-3.2,2.3-6.6,4.5-10.3,6.5c-0.7-0.1-1.4-0.1-2.1-0.1c-0.3,0-0.6,0.3-0.6,0.6
  C525.1,397.5,525.4,397.7,525.7,397.7z M492.6,412.6c-3,0.3-5.9,0.6-8.9,1c0.4-0.7,0.8-1.4,1.2-2.1c0,0,0,0,0,0
  c3.3-0.6,6.6-1.2,9.8-2C494.1,410.6,493.4,411.6,492.6,412.6z M493.3,413.7c3.1-0.3,6.2-0.6,9.4-0.8c-0.4,0.4-0.8,0.8-1.2,1.2
  c-4.2,1.7-8.5,3.5-12.6,5.3C490.4,417.6,491.9,415.7,493.3,413.7z M467.3,427.5c-4.7,1.6-9.2,3.2-13.5,4.7c-2.1,0.8-4.5,1.8-7,2.8
  c-0.3,0.1-0.7,0.3-1,0.4c-2.7-0.2-5.4-0.5-8.1-1c0.6-0.4,1.2-0.7,1.8-1.1c6.9-1,13.5-3.3,18.8-7.8c1.8-1.6,3.7-3.8,5.3-6.3
  c2.1-0.8,4.1-1.7,6.1-2.6c4-0.6,8-1.1,12-1.6c-1.6,2.9-3.1,5.9-4.6,9C473.8,425.2,470.5,426.4,467.3,427.5z M469.2,428
  c-5.8,2.5-11.6,4.9-17.5,7.1c-0.5,0.2-0.9,0.3-1.4,0.5c-0.6,0-1.3,0-2-0.1c2.1-0.9,4-1.7,5.8-2.3c4.3-1.5,8.7-3.1,13.5-4.7
  C468.2,428.4,468.7,428.2,469.2,428z M387.4,451.6c-0.4-1.4-0.7-2.8-1.1-4.2c6-2.7,12-5.5,17.9-8.4c4-1.9,8-3.8,12-5.7
  c3.9,2.1,7.8,4.1,11.9,5.6c-1.4,0.8-2.8,1.6-4.3,2.5c-2.4-0.1-4.6-0.6-6.7-1.6c-0.3-0.1-0.6,0-0.8,0.3c-0.1,0.3,0,0.6,0.3,0.8
  c1.7,0.8,3.5,1.3,5.4,1.6c-2.9,1.6-5.8,3.3-8.9,4.9C404.7,449.3,396.1,450.8,387.4,451.6z M409.2,449.4c-1.4,0.8-2.8,1.5-4.3,2.3
  c-4.7,2.5-10,5.3-15.4,7.3c-0.7-2-1.3-4.2-1.8-6.3C395,452,402.2,450.9,409.2,449.4z M335.1,449.9c1.2,0.1,2.6,0.1,4.1,0.1
  c3.3,0,7,0,10,0.9c-4.3,1.2-8.8,2.2-13.4,2.9c-5.2,0.9-10.4,1.4-15.5,1.7c0-0.1,0-0.2,0-0.3c-0.3-2.6-0.6-5.2-2.4-7.6
  c-0.2-0.3-0.4-0.5-0.6-0.7c0.8-0.1,1.7-0.2,2.5-0.2c7.3-0.5,13.4-1.4,18.7-2.8c0.5,0.2,0.9,0.3,1.4,0.5c5,1.7,10,3.4,15.1,4.9
  c-1.2,0.4-2.5,0.8-3.7,1.1c-3.4-1.5-7.9-1.5-11.9-1.6c-1.4,0-2.8,0-4-0.1c-0.3,0-0.6,0.2-0.6,0.5
  C334.6,449.6,334.8,449.9,335.1,449.9z M174.1,437c-0.5-0.3-1.1-0.5-1.6-0.8c0.7,0.1,1.4,0.2,2,0.2
  C174.3,436.6,174.2,436.8,174.1,437z M143.1,427.3c-1.5-0.4-2.9-0.6-4.2-0.9c-3.9-0.8-6.8-1.4-9.4-3.6c7.4,0.9,14.9,1.6,22,2.2
  c1.5,1.1,3,2.2,4.6,3.2c-1.7-0.1-3.5,0-5.2,0.7c-0.9,0.4-1.6,0.9-2.2,1.5C147.3,429,145.5,427.9,143.1,427.3z M74,391.8
  c6.1,8,12.6,13.3,19.3,15.9c-0.3,0.1-0.6,0.3-0.8,0.4l-1.3,0.6c-1.2,0.5-2.4,1.1-3.6,1.8C81.9,405.3,77.5,399,74,391.8z
   M40.7,378.8c-7.7-14.4-7.5-31.2-3.7-47.8c-2.2,15.5-0.6,31.4,4.8,46.7C41.4,378.1,41.1,378.5,40.7,378.8
  C40.7,378.8,40.7,378.8,40.7,378.8z M38.4,329c0.9-5.5,2.3-10.8,4.2-16.1c0.4,0.2,0.8,0.4,1.2,0.5c2.9,1.3,6,2.6,8.3,4.5
  c0,0.5,0,0.9,0,1.4c-0.5,2.7-0.9,5.5-1.1,8.2c-0.5,1.8-1.3,3.6-2.1,5.5c-2.4,0.6-4.9,0.3-7-0.8c-1-0.5-2-1.3-3-2
  C38.8,329.8,38.6,329.4,38.4,329z M88,203.4c-0.1-0.2-0.1-0.4-0.2-0.5c0.2-0.2,0.4-0.3,0.6-0.5C88.3,202.8,88.1,203.1,88,203.4z
   M110.2,159.9c3-1.4,5.6-3.4,7-6.4c-0.6,1.6-1.2,3.2-1.9,4.7c-2.2,2.6-4.3,5.3-6.4,8.1C109.2,164.1,109.6,162,110.2,159.9z
   M127.3,147.9c0.3-0.1,0.6-0.1,0.8-0.2c0,0.7,0.1,1.4,0.1,2.2c-0.8,0.3-1.7,0.6-2.6,1c0.3-0.9,0.6-1.8,1-2.8
  C126.9,148,127.1,148,127.3,147.9z M127.2,146.8c0,0,0-0.1,0-0.1c0.3-0.3,0.6-0.7,0.9-1c0,0.3,0,0.6,0,0.9
  C127.8,146.6,127.5,146.7,127.2,146.8z M128.4,151.1c0.1,2.5,0.3,5,0.6,7.5c-2.9,1.6-5.6,3.4-7.6,5.5c1.2-3.9,2.5-7.8,3.8-11.7
  C126.3,151.8,127.3,151.4,128.4,151.1z M161.9,107.5c0.9-1.2,1.1-2.5,1.4-3.8c0.1-0.5,0.2-0.9,0.3-1.3c2.3-0.2,4.6-0.4,7-0.6
  c-2.1,1.3-4.2,2.7-6.3,4.2c-0.3,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.5,0.3,0.8,0.1c2.3-1.6,4.6-3.1,6.9-4.6c-0.6,1-1.1,2-1.7,3.1
  c-0.2,0.2-0.5,0.4-0.7,0.6c-4.9,4.2-9.8,8.4-14.6,12.7c0.5-2.4,1.1-4.8,1.6-7.2C158.5,110.6,160.5,109.4,161.9,107.5z M156.7,110.2
  c0.3-1.3,0.6-2.6,0.8-4c0.2-0.9,0.3-1.9,0.5-3c1.4-0.3,2.9-0.5,4.4-0.7c-0.1,0.3-0.1,0.6-0.2,1c-0.3,1.2-0.5,2.3-1.2,3.3
  C159.9,108.3,158.4,109.3,156.7,110.2z M175.8,102.2c0.3-0.2,0.6-0.5,0.9-0.7c2.7-0.2,5.3-0.5,8-1c-1.5,3.9-3.6,6.2-8.1,9.4
  c-0.3,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.5,0.3,0.8,0.1c5-3.6,7.1-6,8.8-10.6c0.1,0,0.1,0,0.2,0c5.3-1.1,10-3.2,14.3-5.8
  c-4.8,6.9-11.3,11.6-17.7,16.2c-4.1,2.9-8.2,5.8-11.9,9.4C172.2,114,173.8,108.1,175.8,102.2z M175,99.2c0.5-0.8,1-1.7,1.5-2.5
  c-0.3,0.7-0.5,1.4-0.7,2.1C175.5,98.9,175.3,99,175,99.2z M218.9,65.4c4.8-0.9,10.9-3.1,16.3-6.3c1.6-0.3,3.1-0.5,4.7-0.7
  c-4.9,1.6-9.8,3.4-14.4,5.8c-12,6.2-15,11.9-19.1,19.7c-0.4,0.9-0.9,1.8-1.4,2.7c-5.3,1.4-10.6,3.3-16,5.5c0-0.1,0.1-0.1,0.1-0.2
  c0.5-0.9,1-1.8,1.4-2.8c2.5-2.3,5-4.6,7.5-6.9c0.2,0.4,0.4,0.8,0.8,1.1c1.1,1,3,1.3,5.8,0.8c0.3-0.1,0.5-0.4,0.5-0.7
  c-0.1-0.3-0.4-0.5-0.7-0.5c-2.4,0.4-4,0.2-4.8-0.5c-0.3-0.3-0.5-0.7-0.7-1.1c4.1-3.8,8-7.7,11.8-11.6c1.3-1.3,2.6-2.7,3.8-4
  C216.1,65.8,217.5,65.7,218.9,65.4z M217.5,64.4c4.5-2,9.1-3.5,13.9-4.5c-4.4,2.2-9,3.7-12.7,4.4C218.3,64.3,217.9,64.4,217.5,64.4
  z M275.8,43.4c-0.6,0.3-1.2,0.7-1.8,1c-4,2.2-8.1,4.4-12.5,6.4c-3.5-0.6-7-1-10.5-1.3c4.7-3.6,9.9-6.3,15.9-7.7
  c2-0.5,4-0.8,6.2-1.1C273.9,41.6,274.8,42.6,275.8,43.4z M315.6,41.7c0.7,0,1.4,0.1,2,0.1c-1.5,1-3,2.1-4.4,3.2
  c-1.5,1.1-2.9,2.1-4.4,3.2c-2,1.4-4,2.8-6,4.3c0.4-2.7,1.2-5.3,2.5-7.8c0.1-0.2,0.2-0.4,0.3-0.6C308.9,43.2,312.3,42.4,315.6,41.7z
   M306.6,42.6c0.3-0.5,0.7-1,1.1-1.4c1.3,0.1,2.6,0.2,3.8,0.3C309.8,41.9,308.2,42.2,306.6,42.6z M367.1,50.4
  c-1.1,1.2-2.1,2.6-2.9,4c-0.5,0.8-0.8,1.6-1.1,2.3c-8.4,0.4-16.9,1.4-25.4,3.1c0.5-0.5,1-0.9,1.6-1.3
  C348.7,56.5,358,53.5,367.1,50.4z M341.4,56.8c3.9-2.7,8.6-4.8,14.2-5.9c2.2-0.4,4.4-0.7,6.5-0.9c1-0.1,2-0.1,3-0.2
  C357.4,52.5,349.5,55,341.4,56.8z M440.7,47.8c2.2,1.4,2.7,4.3,3,7.1c-1.3-0.4-2.6-0.8-3.9-1.2c-1.6-0.4-3.2-0.8-4.8-1.2
  c-0.1-1.2-0.3-2.4-0.5-3.5c0.8-0.7,1.6-1.4,2.4-2.2C438.5,47,439.7,47.2,440.7,47.8z M451.5,60.8c2.3,0.2,4.7,0.7,7.1,1.1
  c7.1,3.3,14.1,7.4,20.7,12c-2-0.5-4.1-1.2-6.2-1.8c-5.1-1.5-10.3-3.1-15.4-3.7c-7.2-0.9-10.8-2.6-12.3-10.4
  c-0.1-0.4-0.2-0.9-0.2-1.4c3,1,6,2.1,9,3.4c-0.9-0.1-1.7-0.2-2.6-0.3c-0.3,0-0.6,0.2-0.6,0.5C450.9,60.5,451.1,60.8,451.5,60.8z
   M516.9,60.1c3.7,3.4,6.1,8.4,8.6,13.3c1.3,2.6,2.5,5.1,3.9,7.3c2.6,4.2,5,8.2,7,12.2c-0.2,0.3-0.4,0.6-0.6,0.9
  c-0.3-0.4-0.6-0.7-0.9-1c-2.3-2.6-5.7-5-9.3-6.9c-2.7-5.5-6-10.1-9.9-14c-1.5-1.4-2.8-2.9-4.2-4.4c1.7-1.7,3.2-4,4.7-6.3
  C516.5,60.9,516.7,60.5,516.9,60.1z M542.9,121.2c1.5,3.1,2.9,6.3,4.1,9.7c-0.8,0.6-1.7,1-2.7,1.4c-0.5-1.5-1-3-1.5-4.5
  c0-1.2,0.1-2.4,0.1-3.5C542.9,123.2,542.9,122.2,542.9,121.2z M559.4,200.9c0.1,0.5,0.2,1,0.3,1.5c0.3,8.9,0.1,17.6-0.4,26
  c-0.4-0.8-0.7-1.7-1.1-2.5c-6.6-15-13.3-30.3-16.3-47c0.5-1.6,1-3.1,1.4-4.6c3.2,2.8,6.1,5.7,8.8,8.9c1.9,2.3,3.6,4.7,5.1,7
  c0.6,3,1.2,6,1.8,8.9c-4-3-6.1-8.1-8.1-13l-1.1,0.4C552,191.9,554.4,197.7,559.4,200.9z M571.8,278.8c-0.3-0.1-0.6,0-0.8,0.3
  c-2.5,5.4-3.2,9.7,0.9,13.7c-0.7,3.6-1.7,7.3-3.1,11.1c-1-13.2-3.2-26.1-6.5-38.8c1.2-6,2.4-12,3.4-18.1
  c5.5,14.5,8.9,29.1,6.4,44.4c-3-3.4-2.3-6.9-0.1-11.8C572.2,279.2,572.1,278.9,571.8,278.8z M561.7,317.9c-1.7,2.8-3.7,5.5-5.9,8.4
  c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1-15.6,1.3-31.6,4.4-48.3c0.7-3.7,1.4-7.3,2.1-11c3,12.2,5,24.6,5.9,37.2c-0.4,0.2-0.8,0.3-1.2,0.5
  c-2.5,1-5.4,2.1-6.1,4.2C559.8,311.6,560.5,314.7,561.7,317.9z M555.9,341.8c-0.3-4.3-0.5-8.7-0.6-13c0.5-0.6,0.9-1.1,1.4-1.7
  c2.1-2.6,3.9-5.2,5.5-7.8c1.2,2.8,2.7,5.6,4,8c0.4,0.8,0.8,1.6,1.2,2.2C563.3,333.5,559.5,337.5,555.9,341.8z M567.9,330.6
  c-1,13.3-3.9,24.9-8.9,34.8c-1.3-7.3-2.3-14.6-2.9-22C559.8,339,563.7,334.7,567.9,330.6z M554.5,373c-4.2-0.5-8.4-1.3-12.2-2.2
  c1.7-1.6,3.3-3.1,4.5-4.1c0.2-0.2,0.3-0.6,0.1-0.8c-0.2-0.2-0.6-0.3-0.8-0.1c-1.4,1.2-3.3,2.9-5.1,4.7c-1.1-0.2-2.1-0.5-3.1-0.7
  c-0.4-0.1-0.8-0.2-1.3-0.3c4.7-6.8,9.4-13.5,14.6-20.1c2.5,7,4.3,14,5,21C555.6,371.4,555.1,372.2,554.5,373z M556.3,372.4
  c0,0.3,0,0.5,0.1,0.8c-0.2,0-0.4,0-0.5,0C556,372.9,556.2,372.7,556.3,372.4z M553.8,374.1c-4.1,5.8-9,10.9-14.8,15.3
  c-0.3-0.4-0.6-0.7-0.8-1.1c-1.7-2.1-3.2-4.1-2.9-6.8c0.4-3.1,3.2-6.7,6.2-9.7C545.2,372.7,549.5,373.6,553.8,374.1z M463,416.5
  c-7.4,1.1-14.7,2.3-21.9,3.5c-2.5,0.4-5.1,0.8-7.7,1.2c2.5-1.5,4.9-3,7.2-4.5c8.2-0.7,16.4-1.4,24.4-2.3c-0.2,0.3-0.3,0.7-0.5,1
  C464,415.8,463.5,416.1,463,416.5z M463.1,417.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.1-0.7,0.3-1,0.4
  C462.2,418.4,462.6,418.1,463.1,417.8z M434.4,435.1c-0.4,0.2-0.8,0.5-1.2,0.7c0.2-0.3,0.4-0.6,0.5-0.9
  C433.9,435,434.1,435.1,434.4,435.1z M412.8,428.7c-1.5-0.4-3-0.9-4.5-1.3c-0.5-0.3-1-0.6-1.4-0.9c1.2-0.2,2.4-0.4,3.6-0.5
  c5.7-0.8,11.4-1.7,17.1-2.6c-1,0.6-2,1.1-3,1.7c-2,1.1-4,2.2-6.1,3.3c-0.1,0-0.2,0-0.4,0C416.4,428.5,414.6,428.6,412.8,428.7z
   M417.7,431.2c-0.5,0.2-0.9,0.4-1.4,0.6c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.5-0.3-0.7-0.4c0.4-0.2,0.7-0.4,1.1-0.6
  C416.8,431,417.2,431.1,417.7,431.2z M384.3,442.3c-0.6-1.3-1.5-2.5-2.4-3.4c5.1-2.8,10-5.8,14.8-9c2.8,0.5,5.7,1,8.5,1.5
  c1.8,0.3,3.7,0.6,5.7,0.9c-8.2,4.2-16.9,8.2-25.8,11.9C384.9,443.6,384.6,442.9,384.3,442.3z M265.6,430.2
  c-4.7-0.8-9.3,0.8-12.8,4.5c-0.2-0.1-0.4-0.2-0.6-0.2c-9.4-3.9-18.9-7.9-29-10.5c-0.6-0.5-1.3-1.1-1.9-1.6
  c-3.7-3.2-7.6-6.4-11.1-10.1c12.5,2.1,25,3.4,37.5,3.6c9.7,8,21.8,12.6,35.6,15.8c-9.1,1.8-18.3,3.1-27.4,3.1c-0.5,0-1,0-1.5,0
  c3.1-2.8,7-4.1,11-3.4c0.3,0.1,0.6-0.2,0.7-0.5C266.2,430.6,265.9,430.3,265.6,430.2z M170.1,425.7c0.6-0.8,1-1.6,1.5-2.5
  c0.1-0.1,0.2-0.3,0.2-0.4c0.6,1.1,1.2,2.2,1.9,3.3C172.6,425.9,171.4,425.8,170.1,425.7z M174.5,427.4c0.3,0.5,0.6,0.9,0.9,1.4
  c-1.4-0.6-2.8-1.2-4.2-1.8C172.3,427.1,173.5,427.2,174.5,427.4z M167.6,425.4c-1.8-0.8-3.7-1.6-5.6-2.5c-0.6-0.5-1.2-1-1.8-1.4
  c3.7,0,7.4,0,11.1,0c0,0,0,0,0,0.1c-0.2,0.4-0.4,0.7-0.6,1.1c-0.6,1.1-1.2,2-1.9,2.9C168.4,425.5,168,425.4,167.6,425.4z
   M167.7,426.7c-0.2,0.1-0.4,0.3-0.6,0.4c-0.3-0.2-0.5-0.5-0.8-0.7c0.3,0,0.7,0.1,1,0.1C167.4,426.6,167.5,426.6,167.7,426.7z
   M125.2,416.8c-0.3-0.6-0.7-1.3-1.1-2c1.6-0.1,3.1-0.2,4.7-0.4c4.1,0.6,8.1,1.3,12.2,2.2c1.2,1.1,2.4,2.1,3.7,3.2
  C138.1,419.3,131.5,418.4,125.2,416.8z M114.5,404.1c-1.7-0.7-3.5-1.1-5.4-1.1c-2.5-6-4.4-12.4-6.2-18.6c-0.4-1.5-0.9-2.9-1.3-4.4
  c2.6,1,5.1,1.9,7.5,2.7l0.5,0.2c1.3,0.5,2.6,0.9,4,1.4c6.8,10.4,14.5,19.7,22.8,27.8c-2.5,0.4-5,0.8-7.6,1c-2-0.3-4-0.5-6-0.8
  C120.8,409.1,118,405.7,114.5,404.1z M108,410.3c-2.3-1.3-4.5-2.9-6.7-4.6c2.4-0.8,4.8-1.3,7.1-1.4c1,2.3,2.1,4.5,3.3,6.6
  C110.4,410.7,109.2,410.5,108,410.3z M112.4,412.2c0.1,0.2,0.2,0.4,0.3,0.5c-0.5-0.2-1-0.5-1.5-0.7
  C111.6,412.1,112,412.1,112.4,412.2z M104.8,409.7c-2.8-0.6-5.5-1.3-8.2-2.1c1.1-0.5,2.3-0.9,3.5-1.4
  C101.5,407.4,103.1,408.6,104.8,409.7z M100.2,404.8c-10.1-8.4-18.3-20.9-23.4-35.8c0.1,0,0.2,0,0.3,0c2,1.1,4.1,2.1,6.1,3.1
  c5.2,16.1,12.4,24.6,21.4,25.1c0,0,0,0,0,0c0.3,0,0.6-0.2,0.6-0.5c0-0.3-0.2-0.6-0.5-0.6c-9.4-0.5-15.7-10.4-20-23.2
  c5.3,2.5,10.6,4.7,15.6,6.6c0.5,1.8,1,3.5,1.5,5.3c1.8,6.1,3.6,12.4,6.1,18.3C105.4,403.3,102.7,403.9,100.2,404.8z M60.5,290.1
  l0.1,0.7c0.7,3.9,1.3,7.8,1.9,11.8c-1.3-1.8-2.6-3.6-3.9-5.3C59.3,294.9,59.9,292.5,60.5,290.1z M57.8,296
  c-0.9-1.2-1.8-2.3-2.7-3.4c1.3-2.4,3-4,4.8-5.7l0,0.3C59.3,290.2,58.6,293.2,57.8,296z M60.3,282c0.3-0.4,0.7-0.9,1-1.3
  c0,0,0,0.1,0,0.1c-0.2,1.1-0.4,2.2-0.7,3.3l0-0.2C60.5,283.3,60.4,282.6,60.3,282z M61.8,290.6l-0.6-3.4c0.1-0.6,0.3-1.2,0.4-1.9
  c1.2-1.2,2.5-2.6,3.6-4.4c2.1-3.4,3.5-6.9,4.6-10.5c2.9-3.5,5.7-7.2,8.4-10.9c-0.1,4.4,0,8.8,0.2,13.3
  c-6.1,10.1-11.1,20.4-14.3,31.2C63.3,299.5,62.6,295,61.8,290.6z M62,283.2c0.1-0.7,0.3-1.4,0.4-2.2c0.1-0.7,0.2-1.4,0.4-2.2
  c1.2-1.5,2.5-3,3.7-4.5c0.4-0.5,0.8-1,1.2-1.4c-0.9,2.5-2,5-3.5,7.4C63.5,281.4,62.8,282.3,62,283.2z M181.6,95.6
  c-0.5,0.3-1.1,0.5-1.6,0.8c3.4-4.1,6.9-8.1,10.7-12c0,1.4-0.4,2.7-1,4C187,90.8,184.3,93.2,181.6,95.6z M188.2,91.2
  C188.2,91.3,188.2,91.3,188.2,91.2c-0.3,0.6-0.6,1.1-0.8,1.6c-0.6,0.3-1.3,0.6-1.9,0.9C186.4,92.9,187.3,92.1,188.2,91.2z
   M182.2,96.6c1.5-0.7,3-1.5,4.4-2.1c-0.3,0.6-0.5,1.2-0.7,1.8c-0.3,1-0.6,2-0.9,2.8c-2.3,0.5-4.6,0.8-7,1
  C179.5,98.9,180.9,97.8,182.2,96.6z M187.1,96.6c0.3-1,0.7-1.9,1.2-2.9c5.4-2.4,10.7-4.3,16.1-5.8c-0.7,1.3-1.5,2.7-2.4,4.1
  c-4.7,3-9.7,5.5-15.5,6.8C186.7,98.1,186.9,97.4,187.1,96.6z M301.3,59.2c0,0.1,0,0.2,0,0.4c-1.8-0.2-3.5-0.4-5.3-0.6
  c0.2-0.2,0.4-0.3,0.7-0.5C298.2,58.8,299.7,59,301.3,59.2z M297.9,57.6c1.1-0.9,2.3-1.8,3.5-2.6c-0.1,1-0.1,2.1-0.1,3.1
  C300.2,57.9,299,57.8,297.9,57.6z M301.3,60.8c0.1,1.7,0.2,3.5,0.3,5.2c0,0.7,0.1,1.4,0.1,2.1c-3.4-0.7-7.2-0.7-9.6-0.8l-0.2,0
  c-1.9,0-3.9-0.1-6-0.1c2.9-2.4,5.8-4.7,8.8-7C296.9,60.3,299.1,60.6,301.3,60.8z M374.3,49.8c2.1,0.2,4.1,0.5,6.1,1l0.2,0.1
  c0.3,1.1,0.4,2.3,0.5,3.5c-1.6,0.3-3.2,0.6-4.7,0.9l-0.2-0.4C375.7,53.4,375,51.7,374.3,49.8z M375.8,56.6c-0.3,0-0.5,0-0.8,0
  c0.2,0,0.5-0.1,0.7-0.1C375.7,56.5,375.8,56.6,375.8,56.6z M372.2,41.4c1,0.5,2,1.1,2.9,1.7c-0.6,0.5-1.2,0.9-1.6,1.2l-0.2,0.2
  c-0.2,0.1-0.3,0.3-0.5,0.4C372.6,43.7,372.3,42.5,372.2,41.4z M372.7,48.6c-0.1,0-0.1,0-0.2,0C372.6,48.6,372.6,48.5,372.7,48.6
  C372.7,48.5,372.7,48.6,372.7,48.6z M374.3,41.3c1.1,0,2.2,0,3.3,0c-0.5,0.4-1,0.8-1.5,1.2C375.5,42,374.9,41.6,374.3,41.3z
   M374.1,45.4l0.2-0.2c0.4-0.3,1-0.8,1.6-1.2c0.1,0,0.1-0.1,0.2-0.1c0.7,0.5,1.3,1.1,1.8,1.7c-1.5,0.5-3,1-4.5,1.5
  c-0.1-0.3-0.2-0.6-0.3-0.9C373.5,45.9,373.8,45.6,374.1,45.4z M378.7,46.5c0,0,0.1,0.1,0.1,0.1c0.7,0.9,1.1,1.9,1.5,3
  c-2.1-0.5-4.2-0.8-6.4-1c-0.1-0.2-0.1-0.3-0.2-0.5C375.4,47.6,377.1,47,378.7,46.5z M381.2,55.5c0,0.5,0,0.9,0.1,1.4c0,0,0,0,0,0
  c-1.4-0.1-2.8-0.2-4.3-0.2c-0.1-0.1-0.1-0.3-0.2-0.4C378.3,56,379.8,55.7,381.2,55.5z M381.4,58.1c0.3,4.9,1,9.7,7.6,11.9
  c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3-0.1-0.6-0.4-0.7c-5.9-2-6.5-6-6.8-10.8c0.1,0,0.2,0,0.2,0c4.2,0.3,9.5,0.8,15.4,1.6
  c2.3,10,5.9,20.1,10.2,30.1c-3,0.2-6.1,0.5-9.3,0.9c-1.4-0.3-2.9-0.6-4.3-0.9c-2.4-5.3-5.2-10.5-8.1-15.5c-0.6-1.1-1.3-2.2-1.9-3.3
  c-2.5-4.3-5-8.6-7.1-13.2C378.8,57.9,380.1,58,381.4,58.1z M382.3,54.1c-0.1-1-0.2-1.9-0.4-2.9l0,0c2.8,0.7,5.3,1.4,7.9,1.7
  C387.4,53.3,384.8,53.7,382.3,54.1z M396.8,53.1c0.3,1.8,0.7,3.6,1.1,5.4c-5.8-0.7-10.9-1.2-15-1.5c-0.1,0-0.3,0-0.4,0
  c0,0,0-0.1,0-0.1c0-0.5,0-1-0.1-1.5C387.2,54.5,392,53.7,396.8,53.1z M430.6,69.6c1.9,1.1,3.6,2.1,5.2,3.2c0.3,5.6,0.4,11.2,0,16.8
  c-8.1-0.6-16.8-0.6-26.3,0.1c-4.3-9.9-7.9-20-10.2-29.9c10.6,1.4,23.2,3.7,36.1,7.3c0.1,1.4,0.2,2.8,0.3,4.2
  c-1.5-0.9-3-1.8-4.6-2.8c-0.3-0.2-0.6-0.1-0.8,0.2C430.2,69.1,430.3,69.5,430.6,69.6z M488,99.5c-1.5-1.6-5.9-6.5-6.4-11
  c4.2,3.1,8.1,6.6,11.7,10.4c-0.8,5.7-1.5,11.4-2,17.2c-1.2-1.7-2.4-3.3-3.8-4.9c-6.4-7.2-14.9-12.7-25.4-16.4
  c-7.8-9.1-15.6-18-23.4-26.7c14.6,4.4,29.4,10.6,41.7,19.5c-0.1,5,4.5,10.5,6.7,12.7c0.2,0.2,0.6,0.2,0.8,0
  C488.3,100.1,488.3,99.7,488,99.5z M541.2,181.2c3.1,15.9,9.6,30.7,16,45.1c0.7,1.5,1.3,3.1,2,4.6c-0.2,3.2-0.5,6.4-0.8,9.5
  c-0.9,0.8-1.8,1.6-2.7,2.5c-2.7-7.6-5.7-15.1-9-22.5c0.7-1.9,1.2-3.9,1.3-6c1.3,2.9,2.8,5.7,4.3,8.6c0.1,0.2,0.3,0.3,0.5,0.3
  c0.1,0,0.2,0,0.3-0.1c0.3-0.2,0.4-0.5,0.2-0.8c-2-3.7-3.8-7.3-5.4-11c-0.1-1.5-0.4-3.1-0.9-4.7c-1.1-3.7-2.8-6.9-4.8-10
  c-1.1-3.1-2-6.3-3-9.4C539.9,185.2,540.6,183.2,541.2,181.2z M541,172.3c0.5,0.4,1,0.8,1.4,1.2c-0.3,1-0.6,2-0.9,2.9
  C541.4,175,541.2,173.7,541,172.3z M565,244.7c-0.9,5.8-2,11.7-3.2,17.7c-1.1-4-2.2-8-3.5-11.9c0.5-3.1,0.9-6.2,1.2-9.5
  c1-0.8,2-1.6,3-2.4C563.3,240.6,564.2,242.7,565,244.7z M544.8,331.7c-1.4-2.8-2.8-5.5-4.2-8.2c0.6-2,1.2-4,1.9-6
  c0.5-0.4,0.9-0.9,1.4-1.4c7.5-8.5,8.2-16.6,5.8-24.4c0.6-2.4,1.2-4.8,1.8-7.2C552.1,300.8,549.9,316.5,544.8,331.7z M545,334.8
  c0.5,1,1,2.1,1.5,3.1c-1.7,2-3.4,4-5.1,6c0.2-0.4,0.4-0.8,0.5-1.2C543,340,544,337.4,545,334.8z M519.9,367.1
  c-3.6-0.2-7.1-0.4-10.6-0.4c0.6-5.8,1-11.7,1.2-17.7c0.8-1,1.5-2,2.2-3.1c5.5-8.3,8.6-18.3,10.4-29.1c0.5,2.5,1.1,5.1,1.7,7.9
  c0.4,1.7,0.8,3.5,1.2,5.3c-0.3,1.2-0.6,2.5-0.8,4.1c-0.7,6,0.9,12.3,4.5,17.8c-1,2.8-2.1,5.6-3.2,8.4
  C524.3,362.7,522.1,364.9,519.9,367.1z M525.3,363.2c-0.5,1.4-1.1,2.8-1.7,4.2c-0.7-0.1-1.5-0.1-2.2-0.2
  C522.7,365.9,524,364.6,525.3,363.2z M425.6,427.7c-1.4,0.2-2.9,0.3-4.3,0.4c1.3-0.7,2.5-1.4,3.8-2.1c1.9-1.1,3.8-2.2,5.6-3.2
  c3.5-0.6,7-1.1,10.5-1.7c0.8-0.1,1.5-0.2,2.3-0.4C437.5,422.8,431.5,425.1,425.6,427.7z M450.4,419.7c0.1,0,0.2-0.1,0.3-0.2
  c3.4-0.5,6.8-1.1,10.2-1.6c-1.2,0.8-2.4,1.6-3.5,2.5c-8.8,3.1-18,5.4-27.4,6.7C436.7,424.3,443.5,421.8,450.4,419.7z M430.3,421.7
  c-6.7,1.1-13.4,2.1-20,3c-1.6,0.2-3.2,0.5-4.9,0.7c-0.4-0.3-0.8-0.5-1.2-0.8c2.5-1.8,5.1-3.7,7.6-5.7c0.6-0.1,1.1-0.1,1.7-0.2
  c5.9-0.5,12.1-1,18.1-1.5c2.2-0.2,4.4-0.3,6.5-0.5C435.6,418.5,433,420.1,430.3,421.7z M398.6,428.6c0.6-0.4,1.2-0.8,1.8-1.2
  c0.9-0.1,1.9-0.3,2.8-0.4c1.5,0.5,3.1,1,4.6,1.4c0.2,0.1,0.4,0.3,0.6,0.4C405.2,428.8,401.9,428.7,398.6,428.6z M410.2,429.9
  c0.8,0.5,1.7,1,2.5,1.5c0,0,0,0,0,0c-2.6-0.4-5-0.8-7.3-1.1c-0.7-0.1-1.4-0.2-2.1-0.4c1,0,1.9,0,2.9,0
  C407.6,429.9,408.9,429.9,410.2,429.9z M393.8,427.3c-0.8,0.1-1.7,0.3-2.6,0.4c-1.6-0.3-3.2-0.6-4.8-1c-3.5-0.7-7-1.4-10.5-2
  c4.2-0.9,8.4-1.7,12.6-2.4c0.9,0.3,1.7,0.5,2.6,0.8l2.9,0.9c2.1,0.7,4.2,1.4,6.3,2c-0.1,0.1-0.2,0.2-0.3,0.2
  C398,426.6,395.9,426.9,393.8,427.3z M397.8,427.8c-0.4,0.2-0.7,0.5-1.1,0.7c-0.5,0-0.9-0.1-1.4-0.1c-0.2,0-0.5-0.1-0.7-0.1
  C395.6,428.1,396.7,428,397.8,427.8z M355.2,435.4c-1.3-0.4-2.6-0.9-3.9-1.5c0.1,0,0.2-0.1,0.3-0.1c2.3-0.2,4.5-0.4,6.8-0.6
  C357.3,434,356.3,434.7,355.2,435.4z M83.2,367.9c-4-14.3-5.9-31-6.7-42.2c0.4,0.6,0.8,1.2,1.2,1.8c4.5,7.1,9.1,14.3,13.9,21.3
  c1.8,8.5,4.4,17.1,7.3,26.1c0.3,1,0.7,2.1,1,3.1c-5.2-2-10.5-4.3-15.6-6.8c-0.3-1-0.6-1.9-0.9-2.9c0,0,0-0.1,0-0.1
  C83.3,368.1,83.3,368,83.2,367.9z M82.7,370.5c-1.1-0.5-2.1-1.1-3.1-1.6c0.9,0,1.8-0.1,2.7-0.1C82.4,369.4,82.5,370,82.7,370.5z
   M83.7,266.3c5.3-8.2,11.3-16.4,17.6-24.7c0.2-0.3,0.1-0.6-0.1-0.8c-0.3-0.2-0.6-0.1-0.8,0.1c-5.2,6.9-10.2,13.7-14.8,20.5
  c3.7-9.3,8.6-18.4,14.5-27.5c0.9-1.4,1.8-2.8,2.7-4.2c0,1.4,0.5,2.8,1,4.2c0.7,2,1.5,4.3,1.2,7.1c-0.2,1.8-1.5,3.5-2.8,5
  c-0.7,0.8-1.4,1.7-1.9,2.6c-1.5,2.5-2.5,5.3-3.6,7.9c-0.3,0.8-0.6,1.6-0.9,2.4c-2.9,7.2-6,15.1-10,24.8l-0.1,0.4
  c-1.1-2.4-0.6-4.9,0.1-7.6c0.5-2,0.9-4.2,0.8-6.4c0-0.3-0.3-0.6-0.6-0.5c-0.3,0-0.6,0.3-0.5,0.6c0.2,2.1-0.3,4.1-0.8,6.1
  c-0.7,3.1-1.4,6.2,0.5,9.2c-1.2,2.9-2.4,5.9-3.6,8.9c-0.7-5.2-1.3-10.3-1.7-15.5C80.9,274.7,82.2,270.5,83.7,266.3z M79.6,275.3
  c0-0.8-0.1-1.5-0.1-2.3c0.5-0.9,1.1-1.7,1.6-2.6C80.6,272.1,80.1,273.7,79.6,275.3z M108.4,222.7c-0.2-0.3-0.5-0.3-0.8-0.1
  c-0.1,0-0.1,0.1-0.2,0.1c1.4-2.2,2.9-4.5,4.4-6.9c1.2,14.4,1.5,28.4-2.1,42c-1,3.9-1.9,7.7-2.5,11.3c-3.5,6.2-6.9,12.6-9.8,19.2
  c-0.4,0.9-0.7,1.8-1.1,2.7c-0.5-0.2-1-0.4-1.6-0.6c-2.4-0.9-4.7-1.8-6.4-3.2c-0.8-0.6-1.4-1.2-1.8-1.9c0,0,0,0,0,0l0.5-1.1
  c3.9-9.7,7.1-17.6,10-24.8c0.3-0.8,0.6-1.6,0.9-2.4c1-2.6,2-5.3,3.5-7.8c0.5-0.8,1.1-1.6,1.8-2.4c1.4-1.7,2.8-3.4,3.1-5.6
  c0.3-3-0.5-5.5-1.3-7.6c-1.3-3.6-2.2-6.2,3.3-10.1C108.5,223.3,108.6,223,108.4,222.7z M127,206.4c-0.1-4.3-0.3-8.8-0.2-13.6
  c0-0.5,0-1.1,0.1-1.6c1.1-1.8,2.2-3.6,3.2-5.5c1.2-0.6,2.3-1.3,3.4-1.9c1.3,5.2,3,10.4,4.8,15.6c-1.1,18.2,0.2,35.3,3.8,51.1
  c0.6,2.7,1.3,5.3,2.1,7.9c-1.4,1.4-2.8,2.9-4.3,4.4c-19.4,19.5-19.6,38.2-19,59.7c-3-6.3-6.4-12.6-10.5-18.5
  c-0.8-1.2-1.7-2.3-2.5-3.4c-2-9.2-1.9-19.2,0.2-31c0.7-1.3,1.4-2.5,2.1-3.7c4-7.1,8.1-14.3,11.4-21.9
  C127.7,230.4,127.4,219.3,127,206.4z M236.7,70.3c3-1.5,5.9-2.9,8.7-4.3c2.1-0.3,4.3-0.5,6.8-0.5c4.2,0,8.3,0.3,12.5,0.8
  c-0.7,2.7-1.4,5.5-2.1,8.4c-0.6,2.5-1.2,5-1.9,7.5c-12.7-0.8-25.3-0.8-37.8,0.9c1.9-2.6,3.8-5.2,5.7-7.8c0-0.1,0.1-0.1,0.1-0.2
  C231.1,73.3,233.7,71.8,236.7,70.3z M230.6,72.4c0.3-0.4,0.7-0.9,1-1.3c2.8-1.6,5.6-2.9,8.8-3.9c-1.4,0.7-2.8,1.4-4.2,2.1
  C234.1,70.2,232.3,71.3,230.6,72.4z M400.6,115.5c1.7,31.3,7.8,47.5,28.5,75c1.3,1.8,2.7,3.6,4,5.3c3.2,4.3,6.4,8.4,9.4,12.5
  C423.3,250,380.4,296.7,337,322.8c-10,6-20.5,10.4-30.7,14.7c-6.9,2.9-14,5.9-20.9,9.3c0,0,0,0,0,0c-9.7-6.2-20.8-6.3-31.5-6.5
  c-10.3-0.2-20.1-0.3-28.8-5.3c0-0.7,0.1-1.4,0.1-2.2c0.8-20,1.6-40.7-6.9-60.8c-2.9-6.7-5.8-13.3-8.8-19.8
  c2.7-15.4,12.4-22.9,21.7-30.2c9.1-7.1,18.5-14.4,21.4-28.8c1.2-5.8,1.2-11.7,1.2-17.3c0-9.7,0.1-18.9,5.6-27.9
  c5.8-9.5,14.2-13.5,23.2-17.7c4.4-2.1,8.9-4.2,13.2-7.1c5.6-3.7,9.9-8.7,13.6-14.2c2.1-0.5,4.2-0.9,6.3-1.4
  c23.7-5.2,48.3-10.7,72.6-14.4c2.2-0.3,4.3-0.6,6.4-0.9C397.9,99.9,400.2,107.7,400.6,115.5z M395.3,91.1
  C395.3,91.1,395.4,91.1,395.3,91.1C395.4,91.1,395.3,91.1,395.3,91.1C395.3,91.1,395.3,91.1,395.3,91.1z M496.2,88.8
  c1.1,4.8,1.9,9.5,2.4,14.5c-1.3-1.7-2.7-3.3-4.1-4.8C495,95.3,495.6,92,496.2,88.8z M496.1,83.6c-0.3-1.1-0.6-2.2-0.9-3.3
  c0.6,0,1.1,0,1.7,0C496.6,81.4,496.4,82.5,496.1,83.6z M544.6,267.9l1.2,0c-0.2-4,0.3-7.6,1.6-11c1.9,8,3.1,15.9,3.7,23.7
  c-0.7,3.1-1.4,6.1-2.2,9.2c-0.8-2.1-1.7-4.1-2.8-6.2c0.7-6.7-3.1-15.4-5.4-20.6l-0.3-0.7c-1-2.3-2-4.6-3.1-6.9
  c0.7-3.3,1.3-6.5,1.8-9.4c0.1-0.3-0.1-0.6-0.5-0.7c-0.3-0.1-0.6,0.1-0.7,0.5c-0.5,2.5-1,5.2-1.5,7.9c-1.5-3.3-3.2-6.7-4.9-10.1
  c1.5-4.2,4.4-7.7,7.4-11.5c0.1-0.1,0.2-0.3,0.3-0.4c3.2,7.8,5.7,15.6,7.6,23.3C545.1,259,544.4,263.2,544.6,267.9z M549,294
  c1.6,6.8,0.6,13.7-5.9,21.2C545.3,308,547.2,301,549,294z M509.4,245.3c-0.3,3.4-0.4,6.8-0.6,10.2c-2.8-14.1-2.9-30.6-2.7-48
  c2.2,2.5,4.3,5.1,6.3,7.8c-0.3,2.6-0.5,5.1-0.8,7.6C510.8,230.8,510,238.3,509.4,245.3z M509.5,320.8c-4.4,6.7-9.2,13-14.4,19.1
  c7.8-14.7,10.4-31,13.1-48.1l0.5-3.1c0.1,4.6,0.2,9.2,0.3,13.8C509.2,308.6,509.4,314.7,509.5,320.8z M507.6,255.2
  c-5-13.5-10.8-26.4-17.3-38.6c0.5-4.3,0.8-8.6,0.9-13.1c0.1-4.1,0.1-8.3,0.1-12.5c4.6,5,9.2,10,13.6,15.2
  C504.8,223.9,504.9,240.7,507.6,255.2z M500.1,107.4c4.6,6.8,7.9,14.4,10.2,22.6c0.4,2.9,0.8,5.8,1.1,8.7c0.4,4.1,0.6,8.3,0.8,13.2
  c-3-1.9-6.1-3.7-9.1-5.7c-1-5.5-2.4-10.7-4.3-15.7C500.2,122.2,500.6,114.6,500.1,107.4z M512.7,140.1c0.8,4.3,1.4,8.7,1.8,13.2
  c-0.4-0.2-0.8-0.5-1.2-0.7c0-0.1,0.1-0.2,0.1-0.3C513.2,147.8,513,143.9,512.7,140.1z M514.6,154.7c0.3,3.1,0.4,6.2,0.5,9.3
  c-3.5-5.2-7.1-10.4-10.7-15.6C507.8,150.6,511.2,152.6,514.6,154.7z M501.6,144.5c-1.4-1.9-2.7-3.7-4.1-5.6
  c0.2-1.7,0.4-3.5,0.8-5.3c0.1-0.3,0.1-0.6,0.2-0.8C499.7,136.5,500.7,140.4,501.6,144.5z M499.7,143.8c-0.8-0.6-1.6-1.1-2.4-1.7
  c0-0.5,0.1-1,0.1-1.5C498.1,141.7,498.9,142.7,499.7,143.8z M501.7,146.6c0.1,0.2,0.3,0.4,0.4,0.6c1.8,9.9,2.5,20.7,2.8,31.6
  c-4.6-11.5-8.1-23-7.8-35.4C498.7,144.5,500.2,145.6,501.7,146.6z M503.7,149.4c3.9,5.5,7.8,11.1,11.5,16.8
  c0.2,11.2-0.4,22.7-1.3,33.8c-1.3-3.1-2.6-6.2-3.9-9.3c-1.3-3-2.6-5.9-3.8-8.9C506,170.6,505.4,159.7,503.7,149.4z M532.8,186.8
  c1.2,1.4,2.5,2.9,3.7,4.3c-0.6,1.6-1.3,3.3-1.9,5c-5.6-10.3-11.7-20.4-18.2-30.3c-0.1-3.5-0.2-6.9-0.5-10.4
  c0.2,0.1,0.3,0.2,0.5,0.3c1.3,0.8,2.5,1.5,3.8,2.3C518.6,170.5,525.5,178.5,532.8,186.8z M537.4,224.3c-1-2.3-2.1-4.3-3.2-6.2
  c-1.6-2.9-3-5.5-3.9-8.4c1.3-3.5,2.7-7,4.2-10.6c0-0.1,0.1-0.2,0.1-0.3c3.8,7.1,7.4,14.3,10.6,21.5c-1.3,3.2-3.4,6.1-5.7,9
  C538.9,227.7,538.2,226,537.4,224.3z M538.8,230.4c-0.2,0.3-0.5,0.6-0.7,0.9c-2.7,3.4-5.5,6.9-7.2,10.8c-2.3-4.3-4.7-8.7-7.4-13
  c1.9-5.8,4-11.7,6.3-17.7c0.9,2.5,2.2,4.9,3.5,7.3c1,1.9,2.1,3.9,3.1,6.1C537.2,226.7,538,228.5,538.8,230.4z M516.4,168
  c6.3,9.7,12.2,19.5,17.6,29.5c-0.2,0.4-0.3,0.8-0.5,1.2c-1.2,3.1-2.4,6.1-3.6,9.1c-0.2-0.9-0.4-1.9-0.4-2.9
  c-0.5-6.2-3.1-7.5-6.9-9.5c-1-0.5-2-1.1-3.2-1.8c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3-0.1,0.6,0.2,0.8c1.2,0.7,2.3,1.3,3.3,1.8
  c3.9,2,5.8,3,6.3,8.6c0.1,1.7,0.5,3.2,0.9,4.7c-2.4,6.2-4.5,12.2-6.5,18.2c-0.2-0.2-0.3-0.5-0.5-0.7c-1.4-8.6-4.2-16.8-7.4-24.7
  C515.8,191.1,516.5,179.4,516.4,168z M506.2,184.9c0.9,2.1,1.8,4.2,2.7,6.3c1.6,3.7,3.2,7.5,4.8,11.3c-0.3,3.7-0.7,7.4-1.1,11
  c-2.1-2.7-4.2-5.3-6.4-7.9c0-0.9,0-1.8,0-2.7C506.2,197,506.2,190.9,506.2,184.9z M514.6,204.9c2.5,6.4,4.6,12.9,6,19.6
  c-0.4-0.7-0.9-1.3-1.3-2c-1.8-2.6-3.7-5.2-5.6-7.7C513.9,211.6,514.3,208.3,514.6,204.9z M512.8,223c0.2-2.1,0.4-4.2,0.7-6.4
  c1.7,2.2,3.3,4.4,4.8,6.6c1,1.4,1.9,2.8,2.8,4.3c0.2,1.2,0.4,2.4,0.5,3.6c-3.9,12.1-7.2,23.8-9.8,35.5c-0.5-1.4-0.9-2.8-1.3-4.2
  c-0.2-0.8-0.4-1.6-0.6-2.5c0.1-4.9,0.4-9.8,0.7-14.6C511.1,238.4,511.9,230.9,512.8,223z M509.8,264.7c0.5,1.5,1,3,1.5,4.5
  c-0.5,2.5-1,4.9-1.5,7.4C509.7,272.6,509.7,268.6,509.8,264.7z M511.9,271.4c3.2,10.1,5.9,20.5,8.2,31.1
  c-2.9,5.7-6.1,11.2-9.6,16.5c-0.1-5.5-0.2-11-0.4-16.5c-0.2-6.6-0.3-13.3-0.4-20C510.4,278.8,511.1,275.1,511.9,271.4z
   M510.6,321.1c3.6-5.4,6.9-11,9.9-16.8c0.5,2.5,1,5,1.5,7.5c0.1,0.6,0.2,1.2,0.3,1.7c-1.6,11.8-4.7,22.8-10.6,31.8
  c-0.4,0.6-0.8,1.1-1.2,1.7C510.8,338.4,510.8,329.8,510.6,321.1z M521.4,302.7c0.8-1.5,1.5-3,2.2-4.5c-0.1,3.9-0.4,7.8-0.8,11.6
  C522.3,307.4,521.9,305,521.4,302.7z M521,300.9c-1.6-7.3-3.4-14.5-5.5-21.6c2.4,5.3,5.1,10.6,8.1,16
  C522.7,297.2,521.9,299,521,300.9z M512.5,268.8c2.5-11.4,5.6-22.9,9.4-34.7c0.1,1.7,0.2,3.5,0.2,5.2c0,7.3,0.4,15.2,0.8,23.6
  c0.5,9.8,1,20.1,0.9,30.2C519.3,284.8,515.3,276.7,512.5,268.8z M523.2,239.2c0-2.7-0.2-5.4-0.5-8c0.1-0.2,0.2-0.5,0.2-0.7
  c2.7,4.3,5.1,8.7,7.3,13c-1,2.8-1.3,5.9-0.7,9.4c0.7,4.1,2.3,8.1,4.4,12.1c-2.4,9.8-5.4,18.9-9.1,27.4c0.1-9.9-0.4-20-0.9-29.7
  C523.6,254.4,523.2,246.5,523.2,239.2z M530.7,252.8c-0.5-2.8-0.3-5.4,0.4-7.7c1.8,3.5,3.4,7,5,10.4c-0.5,2.6-1.1,5.2-1.7,7.8
  C532.6,259.8,531.3,256.3,530.7,252.8z M535.4,263.9c0.5-2.2,1-4.4,1.5-6.6c0.8,1.8,1.6,3.6,2.4,5.4l0.3,0.7c2,4.6,5.3,12,5.4,18.1
  c-1.4-2.5-2.9-4.9-4.4-7.4c-1.9-3.1-3.8-6.2-5.5-9.3C535.2,264.5,535.3,264.2,535.4,263.9z M540.1,230.6c2.2-2.8,4.4-5.7,5.9-8.9
  c3.2,7.2,6.1,14.6,8.7,22c-3.1,3.2-5.4,6.3-7,9.6C545.7,245.8,543.2,238.2,540.1,230.6z M545.9,218.8c-3.3-7.2-6.8-14.3-10.6-21.4
  c0.7-1.8,1.4-3.5,2.1-5.3c1.3,1.6,2.6,3.3,3.8,5.1c1.6,4.8,3.5,9.5,5.6,14.3C546.9,214.2,546.6,216.6,545.9,218.8z M537.8,190.9
  c0.2-0.6,0.5-1.2,0.7-1.8c0.4,1.5,0.9,2.9,1.4,4.4C539.2,192.6,538.5,191.7,537.8,190.9z M536.9,189.9c-1.1-1.3-2.2-2.6-3.3-3.8
  c-7-8-13.6-15.6-12.5-27.4c3.8,2.3,7.5,4.7,11.2,7.1c1.7,7.1,3.5,14.2,5.6,21.4C537.6,188.1,537.3,189,536.9,189.9z M521.3,157.4
  c0.3-2.3,0.9-4.7,1.9-7.2c1-2.9,2.2-5.4,3.3-7.7c1.9,7,3.6,13.9,5.3,20.7c0.1,0.3,0.1,0.6,0.2,0.9
  C528.4,161.8,524.9,159.6,521.3,157.4z M522.1,149.8c-0.9,2.5-1.5,4.8-1.8,7c-1.1-0.7-2.2-1.4-3.4-2c-0.4-0.2-0.8-0.5-1.2-0.7
  c-0.7-8.4-2-16.5-4.2-24.2c-2.1-13.6-6.2-26.8-12-38.6c9.6,10.1,16.8,21.1,21,32.4c2.1,5.8,3.9,11.5,5.5,17.2
  C524.7,143.4,523.3,146.3,522.1,149.8z M508.8,121.8c-2.3-5.9-5.2-11.5-8.8-16.6c-0.4-5-1.2-9.9-2.2-14.7
  C502.7,100.1,506.4,110.7,508.8,121.8z M497.8,104.1c0.4,0.5,0.7,1,1.1,1.5c0.6,7.2,0.4,14.6-0.9,22.8c-1.6-3.8-3.4-7.4-5.6-10.8
  c0.5-5.9,1.1-11.8,1.9-17.6C495.5,101.3,496.7,102.7,497.8,104.1z M497.6,130.6c-0.2,0.9-0.3,1.9-0.5,2.8c-0.3,1.4-0.5,2.8-0.7,4.1
  c-1.6-2.1-3.2-4.3-4.8-6.4c0.2-3.9,0.4-7.7,0.7-11.6C494.4,123,496.2,126.7,497.6,130.6z M496.3,139.2c-0.1,0.7-0.1,1.4-0.2,2
  c-1.6-1.2-3.2-2.5-4.7-3.7c0-1.5,0.1-3.1,0.1-4.6C493.1,135,494.7,137.1,496.3,139.2z M496.1,142.7c-0.5,13.9,3.8,26.9,8.9,39.4
  c0.1,7,0.1,14,0,21c0,0.4,0,0.9,0,1.3c-4.5-5.2-9.1-10.2-13.6-15.1c0-5.3,0-10.6-0.1-15.8c-0.1-11.4-0.2-22.9,0.1-34.4
  C492.9,140.3,494.5,141.5,496.1,142.7z M490.5,129.7c-8.7-11.3-17.7-22.3-26.9-33.1c9.5,3.6,17.2,8.7,23.1,15.4
  c1.6,1.9,3.1,3.9,4.5,5.9C490.9,121.8,490.7,125.7,490.5,129.7z M490.4,131.5c-0.1,1.7-0.1,3.4-0.2,5.1c-5-4.4-9.6-9.3-13.2-15
  c-2.8-4.4-5.2-8.4-7.5-12.2c-2.9-4.9-5.7-9.4-8.6-13.7c0.2,0.1,0.3,0.1,0.5,0.2C471.3,107.4,481.1,119.3,490.4,131.5z M508.7,261.5
  c-0.2,6.9-0.2,13.9-0.1,21c-0.3,1.6-0.6,3.2-0.8,4.8l-0.7,4.4c-3,18.5-5.8,36.2-15.3,51.8c-1.2-2.2-2.5-4.4-3.7-6.7
  c-2.2-3.9-4.6-7.9-6.5-12.4c-5.4-12.4-6.5-25.8-7.7-38.8c-0.3-3.9-0.7-7.8-1.2-11.7c7.7-18,14.7-35.6,17.4-55.3
  C497.2,232.1,503.4,246.5,508.7,261.5z M403.2,424c-1.6-1.1-3.3-2.2-4.9-3.3c3.8-0.6,7.6-1.1,11.3-1.5
  C407.5,420.9,405.4,422.4,403.2,424z M403.7,425.7c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.2
  C403.4,425.5,403.5,425.6,403.7,425.7z M343.3,430.7c-7.1-1.9-16-0.1-19.6,0.8c-0.9,0.2-1.7,0.5-2.6,0.8
  c-6.4-0.7-12.5-1.9-18.3-3.8c15.7-3.6,31.9-7.2,47.5-6.3c4.9,1.3,9.8,2.5,14.7,3.4c-0.1,0-0.2,0.1-0.2,0.1
  C357.8,427.3,350.6,429,343.3,430.7z M360.9,427.9c-3.2,1.6-6.4,3.3-9.6,4.8c-0.8,0.1-1.7,0.1-2.5,0.2c-1.1-0.5-2.3-1-3.5-1.4
  C350.6,430.2,355.8,429,360.9,427.9z M340.5,431.3c-0.7,0.2-1.3,0.3-2,0.5c-1.9,0.5-3.9,0.9-5.8,1.3c-2.9-0.1-5.7-0.2-8.6-0.5
  C327.3,431.8,334.2,430.4,340.5,431.3z M215,385.8c-6.4-0.6-13.1-1.2-19.5-2.4c-0.4-2.9-0.6-5.8-0.5-8.9c5.9-2.2,12-4.1,18.1-4.2
  c0.2,0,0.4,0,0.6,0c4.1,0,7.8,0.6,11.3,1.6c0.6,4.1,1.5,8.2,2.7,12.3c-2.5,0.8-5,1.5-7.4,2.1C218.5,386.1,216.8,385.9,215,385.8z
   M216.7,387.1c-3.2,0.7-6.3,1.3-9.4,1.9c-3.4,0.7-6.8,1.3-10.1,2.1c-0.6-2.1-1.2-4.3-1.5-6.5c6.4,1.2,12.9,1.8,19.2,2.4
  C215.5,387,216.1,387.1,216.7,387.1z M194,370c-0.1,1.2-0.2,2.4-0.2,3.6c-1.5,0.6-3,1.2-4.4,1.7c-3,1.2-6,2.4-9,3.4
  c-10-4.3-18.2-10.8-26-17.5c-0.7-4.8-1.1-9.1-1.3-12.8c-0.8-14.6,0.9-29.8,2.9-44.8c2.6-1.8,5-3.7,7.3-5.8
  c10.7,15.9,24,29.8,37.7,41.8c-0.4,1.4-0.8,2.8-1.2,4.2C197.3,352.4,194.8,361.1,194,370z M194.3,383.1c-1.6-0.3-3.2-0.7-4.7-1.1
  c-2.6-0.7-5.1-1.6-7.5-2.5c2.6-0.9,5.2-2,7.8-3c1.3-0.5,2.6-1.1,4-1.6C193.8,377.7,193.9,380.5,194.3,383.1z M136.7,392.4
  c3.5,2.1,7,4.2,10.6,6.3c4.1,2.4,8.3,4.8,12.3,7.4c-5,1.8-10.2,3.4-15.5,4.6c-1.7-1.1-3.4-2.2-5.1-3.4
  c-10.2-6.8-15.2-13.2-17.4-20.1C126.5,388.9,131.6,390.7,136.7,392.4z M121.2,385.8c-0.4-1.5-0.7-2.9-0.8-4.5
  c3.8,2.9,7.9,5.7,12,8.3C128.6,388.4,124.9,387.1,121.2,385.8z M147.8,397.7c-2-1.2-4-2.4-6-3.6c6.8,2.3,13.8,4.5,20.7,6.6
  c0.4,1.2,0.8,2.4,1.3,3.6c-1,0.4-1.9,0.8-2.9,1.2C156.6,402.8,152.2,400.2,147.8,397.7z M137.2,391.4c-5.9-3.6-11.7-7.4-17-11.6
  c-0.4-5.4,0.2-11.3,0.9-18c0.5-4.7,1-9.5,1.2-14.9c0.2-5.4,0.2-10.5,0.1-15.4c1,1.2,2,2.4,3,3.6c0.3,0.6,0.5,1.1,0.8,1.7
  c2.3,5.1,4.6,10.5,7.2,15.6c4.8,9.8,11.6,23.6,23.6,28.5c1.4,6.1,3.2,12.4,5.2,18.5C153.7,396.9,145.4,394.1,137.2,391.4z
   M119,378.8c-3.5-2.8-6.8-5.8-9.7-9.1c-2.7-3-5.3-6.1-7.8-9.3c-2.8-5.8-5.3-11.9-7.7-18.1c-1.1-3.1-2.2-6.1-3.2-9.3
  c-1-13.4,0.8-26.1,6.4-40.4c1.4,0.6,2.8,1.3,4,2.2c2.2,1.7,4.1,3.8,6,6.2c2.2,10,6.7,19,13.6,28c-1.8,1.6-3.6,2.4-5.4,2.5
  c-2.8,0.1-5.9-1.4-9.4-4.9c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8c3.5,3.5,6.8,5.2,9.8,5.2c0.1,0,0.3,0,0.4,0
  c2.1-0.1,4.1-1,6-2.7c0.1,5.4,0.2,10.9-0.1,16.8c-0.2,5.4-0.7,10.2-1.2,14.9C119.2,367.8,118.7,373.5,119,378.8z M98.2,356.1
  c-1.8-2.5-3.6-5-5.4-7.5c-0.7-3.5-1.3-7-1.7-10.4c0.5,1.5,1.1,3,1.6,4.5C94.4,347.3,96.2,351.7,98.2,356.1z M89.1,328.5
  c-3.2-10.5-5.6-21.2-7.2-31.9c1.4-3.4,2.7-6.7,4.1-10.1c0.4,0.5,1,1,1.6,1.5c1.9,1.5,4.4,2.5,6.7,3.4c0.5,0.2,1,0.4,1.6,0.6
  C90.8,305.3,88.9,317.1,89.1,328.5z M106.4,298.6c-1.5-1.7-3-3.3-4.8-4.7c-1.3-1-2.8-1.7-4.3-2.4c0.4-0.9,0.7-1.8,1.1-2.7
  c2.4-5.6,5.2-11,8.1-16.3C105.1,282.3,105,290.8,106.4,298.6z M108.7,303.5c0.3,0.4,0.5,0.8,0.8,1.2c4.5,6.6,8.2,13.6,11.5,20.7
  c0,0.8,0,1.7,0.1,2.5C115.2,320,111,312.1,108.7,303.5z M133.2,177.4c0.2-0.2,0.4-0.3,0.6-0.5c-0.2,0.3-0.4,0.6-0.5,1
  C133.3,177.7,133.3,177.6,133.2,177.4z M133.3,182.7c-0.7,0.4-1.3,0.7-2,1.1c0.5-0.9,1.1-1.8,1.6-2.8
  C133,181.6,133.2,182.1,133.3,182.7z M136,175.5c1.6-0.9,3.4-1.7,5.4-2.1c-0.3,1.5-0.5,3.1-0.8,4.6c0,0.2-0.1,0.4-0.1,0.6
  c-2,1.1-3.9,2.2-5.9,3.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.9-0.4-1.7-0.6-2.5C134.5,178.2,135.3,176.8,136,175.5z M135.2,182.9
  c1.7-0.9,3.4-1.9,5.1-2.8c-0.8,5.5-1.4,11-1.8,16.3c-1.5-4.4-2.8-8.8-3.9-13.2C134.8,183.1,135,183,135.2,182.9z M139.4,202.2
  c3,8,6.5,16.1,10.7,24.3c2.7,5.3,5.5,10.4,8.5,15.4c-3.5,4.9-7.9,9.9-13.3,15.6c-0.7-2.4-1.4-4.8-1.9-7.2
  C139.9,235.4,138.6,219.3,139.4,202.2z M167.8,163.5c1.4,3.4,2.8,6.8,4.2,10.2c-1.3,1.2-2.5,2.4-3.5,3.7c-0.6-4.5-1.1-9-1.3-13.5
  C167.4,163.8,167.6,163.7,167.8,163.5z M167.1,162.6c0-0.2,0-0.5,0-0.7c0.1,0.2,0.2,0.4,0.2,0.6
  C167.2,162.5,167.2,162.5,167.1,162.6z M240.3,126.7c-0.5,1.1-0.9,2.1-1.3,3.1c-3.9,3.8-8.4,7-12.7,10.2c-6.9,5-14,10.2-19,17.5
  c-0.3,0.4-0.5,0.8-0.8,1.2c-2.3,0.8-4.7,1.6-7,2.3c-5.1,1.6-10.3,3.3-15.3,5.4c-4.6,1.9-8.3,4.1-11.3,6.5
  c-1.4-3.4-2.8-6.7-4.2-10.1c1-0.7,2-1.4,2.9-2.1c12.5-9,24.7-18.7,36.5-28c2.1-1.7,4.2-3.3,6.3-5c9.3-2.3,18.8-4.2,28.5-5.8
  C242,123.4,241.1,125,240.3,126.7z M244.6,121.8c0.4-0.1,0.7-0.1,1.1-0.2c-1.4,2.1-2.8,4.1-4.4,5.8c0-0.1,0.1-0.2,0.1-0.3
  C242.3,125.1,243.4,123.4,244.6,121.8z M238,132.3c-0.4,1.1-0.8,2.1-1.1,3.1c-2.7,7.6-4.1,11.5-16.9,17.9
  c-3.8,1.9-7.7,3.4-11.7,4.8c4.8-7.2,11.9-12.3,18.7-17.2C230.7,138.2,234.5,135.4,238,132.3z M471.3,272.2c0.1,0.5,0.1,0.9,0.2,1.4
  c-0.7,1.7-1.5,3.5-2.2,5.2c-5.8,13.5-11.8,27.4-16.1,42.4c-6.3,21.9-15.3,35.6-27,48c-4,0.3-7.9,0.7-11.8,1.1
  c-24.8,2.4-50.5,4.9-73.4-0.7c-4.4-1.1-10.1-1.3-15.5-1.6c-6.3-0.3-12.8-0.6-17.1-2.1c-5.8-2.1-9.5-6.2-13.3-10.4
  c-2.5-2.8-5.1-5.7-8.5-8c6.7-3.3,13.6-6.2,20.2-9c10.2-4.3,20.8-8.7,30.9-14.8c43.4-26.1,86.3-72.6,105.7-114.3
  C457.2,228.5,468.2,247.1,471.3,272.2z M443.9,208.3c0.4-0.8,0.8-1.6,1.1-2.5c10.2-23.3,6.9-29.9-0.9-46.1c-1.8-3.7-3.9-8-6-13.1
  c-1.1-2.5-1.9-5-2.5-7.4c10.7,16.3,20.8,29.2,26.2,35.9c10.1,12.5,19.3,26.5,27.4,41.6c-2.4,19.6-9.2,37-16.8,54.8
  C469.1,246.1,457.9,227.5,443.9,208.3z M294.1,356.4c3.8,4.2,7.6,8.5,13.8,10.7c4.5,1.6,11.1,1.9,17.4,2.2c5.4,0.2,11,0.5,15.3,1.5
  c23.1,5.6,48.9,3.1,73.8,0.7c3.5-0.3,7-0.7,10.5-1c-5.8,6-12.3,11.8-19.4,18.2c-5.6,5-11.3,10.2-17.5,16.1
  c-1.9,1.8-3.7,3.6-5.5,5.4c-8.7-5.5-17.8-10.3-27.5-12.9c-15-4.1-30.7-4.4-45.8-4.7c-10.7-0.2-21.6-0.4-32.3-2
  c-8.9-4.9-18-10.4-27.1-16.2c4.4-3.1,8.5-6.6,12.5-10c6.9-5.8,14-11.8,22.2-15.9c0.2-0.1,0.5-0.2,0.7-0.4
  C288.8,350.5,291.5,353.5,294.1,356.4z M375.8,418.8c0.5,0.1,1,0.2,1.5,0.4c-1.3,0.7-2.6,1.4-4,2.1
  C374.1,420.5,374.9,419.7,375.8,418.8z M375.4,417.5c-18.4-4.3-35.8-5.7-53.3-5.7c-13.8-5.3-27.9-11.8-42.2-19.6
  c9.8,1.2,19.7,1.4,29.4,1.6c15.1,0.3,30.7,0.6,45.6,4.6c9.5,2.6,18.4,7.3,26.9,12.7c-1.9,2-3.8,3.8-5.5,5.6
  C376,417,375.7,417.3,375.4,417.5z M376.7,417.9c0.1-0.1,0.2-0.3,0.4-0.4c1.8-1.8,3.6-3.7,5.6-5.8c1.4,0.9,2.7,1.7,4,2.6
  c-2.2,1.1-4.3,2.2-6.5,3.3c-0.5,0.2-1,0.5-1.4,0.8C378.1,418.2,377.4,418,376.7,417.9z M248.8,375.1c8.3,5.4,16.6,10.4,24.8,15
  c-7.7-1.4-15.3-3.5-22.5-6.9c-2.9-1.4-5.6-2.8-8.2-4.3C244.9,377.8,246.9,376.5,248.8,375.1z M241.7,378.3
  c-5.2-2.8-10.1-5.6-15.7-7.3c-0.6-4.1-0.9-8.2-1.1-12.3c5.1,3.7,10,7.1,14.7,10.3c2.7,1.9,5.5,3.6,8.2,5.4
  C245.8,375.8,243.8,377.1,241.7,378.3z M284,347.5c-8.2,4.2-15.4,10.2-22.4,16.1c-4.1,3.5-8.4,7-12.8,10.2
  c-2.8-1.8-5.7-3.7-8.6-5.7c-4.9-3.3-10.1-7-15.5-10.9c-0.2-7,0-14,0.2-20.9c8.9,4.8,19,5,28.8,5.1c10.4,0.2,21.1,0.3,30.3,6
  C284.1,347.5,284.1,347.5,284,347.5z M224.8,370.6c-3.6-1-7.4-1.6-11.7-1.5c-6.2,0.1-12.2,1.9-18.1,4.1c0-1,0.1-2,0.2-3.1
  c0.7-8.8,3.3-17.5,5.8-25.9c0.4-1.2,0.7-2.5,1.1-3.7c7.2,6.3,14.5,12,21.6,17.2C223.8,362.1,224.2,366.4,224.8,370.6z M158.2,287.4
  c1-8,2-16.1,2.4-23.9c0.3-5.3,1.2-9.9,2.6-14.2c4,6.2,8.2,12.1,12.6,17.6c0.2,2.8,0.1,5.6-0.4,8.3c-1.6,8.9-6,15.5-11.8,20.9
  C161.6,293.3,159.9,290.4,158.2,287.4z M162.7,296.9c-2,1.8-4.2,3.6-6.5,5.2c0.2-1.6,0.4-3.3,0.7-4.9c0.4-2.6,0.7-5.2,1.1-7.9
  C159.4,291.9,161,294.4,162.7,296.9z M170.7,244.4c2.1,6.7,4.1,13.6,4.8,20.4c-4.2-5.3-8.2-11-12-16.9c1.3-3.8,3.1-7.3,5-10.7
  C169.3,239.6,170,242,170.7,244.4z M167.6,227.8c4.6-12.5,4.2-26.5,1.1-48.8c1-1.4,2.3-2.8,3.7-4.1c4.8,11.2,9.8,22.2,15,33.4
  c-3.1,4.9-6.4,9.4-9.7,13.7c-3.1,4.1-6.1,8.1-8.8,12.3C168.4,232.1,168,229.9,167.6,227.8z M176.4,275.4c0.4-2.3,0.6-4.7,0.5-7
  c0.4,0.5,0.8,1,1.2,1.5c3.2,3.8,7,7.5,10.8,11c6.8,6.4,13.7,13.1,17.4,21.2c0.1,0.2,0.2,0.5,0.3,0.7c-0.2,6.9-0.7,13.9-1.8,20.7
  c-0.8,5-2.1,10-3.5,14.9c-13.5-11.9-26.6-25.7-37.2-41.4C170.1,291.5,174.7,284.7,176.4,275.4z M223.6,356.3
  c-7-5.2-14.2-10.8-21.2-17c1.5-5.1,2.8-10.3,3.7-15.5c1-6,1.5-12.1,1.7-18.1c1,3,1.6,5.8,2.2,8.6c1.5,7.2,2.8,13.5,11.5,19.8
  c0.8,0.6,1.6,1.1,2.4,1.6C223.6,342.5,223.4,349.4,223.6,356.3z M224,332.8c0,0.5,0,1-0.1,1.6c-0.6-0.4-1.2-0.8-1.8-1.2
  c-8.3-6.1-9.6-12.1-11.1-19.1c-0.8-3.6-1.5-7.3-3.3-11.4c0.1-5.5,0.1-11,0.1-16.3c-0.1-10.2-0.1-20.8,1-31.1c0-0.3,0.1-0.6,0.1-0.9
  c2.7,6,5.4,12.1,8.1,18.2C225.6,292.3,224.8,312.9,224,332.8z M207.8,255.1c-1.2,10.4-1.1,21-1,31.3c0,4.6,0,9.3,0,13.9
  c-3.9-7.7-10.5-14-17-20.2c-3.7-3.5-7.5-7.2-10.7-10.9c-0.7-0.9-1.5-1.8-2.2-2.7c-0.5-7.4-2.8-15-5-22.3c-0.9-2.8-1.7-5.6-2.4-8.3
  c2.8-4.6,6-8.8,9.3-13.2c3.1-4.2,6.4-8.5,9.3-13.2c2.2,4.7,4.5,9.4,6.7,14.2c4.4,9.4,9,19,13.4,28.7
  C208,253.3,207.9,254.2,207.8,255.1z M173.3,174c2.9-2.4,6.6-4.6,11.3-6.6c5-2.1,10.2-3.7,15.2-5.4c1.9-0.6,3.8-1.2,5.8-1.9
  c-3.6,6.4-5.4,13.5-7.1,20.4c-1.4,5.6-2.9,11.5-5.3,16.8c-1.5,3.4-3.3,6.6-5.1,9.6C183,196,178,185.1,173.3,174z M167.2,225.2
  c-0.3-2.1-0.6-4.1-1-6.1c-2.6-15.8-4.6-28.8,1.5-38.7C170.5,200.5,170.9,213.7,167.2,225.2z M168.2,235.6c-2.1,3.4-3.9,7-5.4,11
  c-1-1.5-1.9-3.1-2.9-4.7c2.9-4.1,5.2-8.1,6.9-12.1C167.2,231.8,167.6,233.7,168.2,235.6z M162.3,248c-1.6,4.6-2.6,9.7-2.9,15.4
  c-0.4,7.3-1.2,14.8-2.2,22.3c-4.7-8.5-8.7-17.5-11.6-27c5.5-5.7,10-10.9,13.6-15.8C160.2,244.7,161.3,246.4,162.3,248z M157,287.7
  c-0.4,3.1-0.8,6.3-1.3,9.4c-0.3,2-0.5,4-0.8,6c-3.2,2.2-6.4,4.2-9.7,6.3c-8,5-16.4,10.2-22.3,17.3c-0.2-0.5-0.4-1-0.7-1.4
  c0,0,0,0,0-0.1c-0.7-23.2-1.2-41.5,18.6-61.4c1.3-1.4,2.6-2.7,3.9-3.9C147.8,269.6,152,278.9,157,287.7z M151.9,348.5
  c0.2,3.4,0.6,7.3,1.2,11.6c-1.6-1.4-3.2-2.8-4.8-4.2c-8.6-7.7-15.9-14.7-21.9-21.6c-1-2.2-2-4.4-3-6.6c0.1-0.1,0.1-0.1,0.2-0.2
  c5.8-7,14.1-12.2,22.2-17.2c3-1.9,6-3.7,8.9-5.7C152.7,319.2,151.1,334.2,151.9,348.5z M153.3,361.9c0.8,5.4,1.9,11.4,3.3,17.6
  c-11.1-5-17.6-18.3-22.2-27.6c-2.3-4.6-4.4-9.4-6.4-14c5.5,6,12,12.3,19.6,19C149.4,358.5,151.3,360.2,153.3,361.9z M123.7,331.1
  c-0.5-0.6-0.9-1.1-1.4-1.7c0-0.2,0-0.4,0-0.5c0.1-0.1,0.2-0.2,0.3-0.3C123,329.5,123.4,330.3,123.7,331.1z M154.6,363
  c7.3,6.2,15,12.1,24.1,16.3c-4.5,1.4-9,2.3-13.5,2.1c-2.7-0.1-5.1-0.6-7.4-1.5C156.5,374.1,155.4,368.3,154.6,363z M228,385.3
  c0.2,0.6,0.4,1.3,0.6,1.9c-1.7-0.2-3.4-0.4-5.1-0.6C225,386.2,226.5,385.8,228,385.3z M226.2,372.3c5.1,1.6,9.6,4.1,14.3,6.7
  c-1.6,0.8-3.2,1.6-4.8,2.3c-2.3,1-4.6,1.8-6.9,2.6C227.6,380,226.8,376.2,226.2,372.3z M236.2,382.3c1.9-0.8,3.8-1.7,5.6-2.7
  c2.8,1.6,5.7,3.1,8.9,4.6c8.4,4,17.1,6.2,26,7.5c14.4,7.9,28.5,14.6,42.3,20.1c-12.8,0.1-25.8,0.8-39.4,1.6c-0.7,0-1.5,0.1-2.2,0.1
  c-1.6-1.4-3.2-2.9-4.8-4.3c-7.1-6.5-14.4-13.2-24-17c-6-2.4-12.3-3.8-18.6-4.8c-0.3-0.8-0.6-1.6-0.8-2.5
  C231.5,384.2,233.8,383.3,236.2,382.3z M279.6,414.6c14.6-0.8,28.5-1.6,42.2-1.6c8,3.1,16,5.7,23.8,8c-14.9,0-30.1,3.5-44.9,6.9
  c0,0,0,0-0.1,0c-2.8-1-5.5-2.1-8.1-3.4c-5.2-2.6-9.7-6.1-14-9.8C279,414.6,279.3,414.6,279.6,414.6z M358.3,423
  c3.1,0.4,6.2,0.8,9.3,1.4c-0.3,0.2-0.6,0.3-0.9,0.5C363.9,424.3,361.1,423.7,358.3,423z M353.5,421.3c-1-0.1-2-0.2-3-0.2
  c-8.3-2.2-16.8-4.9-25.3-8.1c16.2,0.2,32.2,1.6,49.2,5.5c-1.6,1.6-3.1,3.1-4.6,4.6c-0.2,0.1-0.4,0.2-0.6,0.3
  C364,422.6,358.8,421.8,353.5,421.3z M371.4,423.7c2.6-1.4,5.1-2.7,7.6-4c2.3,0.6,4.6,1.2,7,1.9c-4.3,0.8-8.7,1.6-13.2,2.6
  c-0.6-0.1-1.2-0.2-1.7-0.3C371.2,423.7,371.3,423.7,371.4,423.7z M380.5,418.8c0.1,0,0.2-0.1,0.3-0.2c2.3-1.2,4.7-2.4,7.1-3.6
  c2.4,1.6,4.8,3.2,7.2,4.9c0,0,0.1,0,0.1,0.1c-2.2,0.3-4.4,0.7-6.6,1.1C385.9,420.3,383.2,419.5,380.5,418.8z M396.6,420.9
  c1.9,1.3,3.7,2.5,5.6,3.8c-0.2,0.2-0.4,0.3-0.7,0.5c-2.4-0.8-4.8-1.5-7.2-2.3l-2.9-0.9c-0.1,0-0.3-0.1-0.4-0.1
  C392.9,421.5,394.7,421.2,396.6,420.9z M396.9,419.7c-0.4-0.3-0.7-0.5-1.1-0.8c-2.2-1.5-4.4-3-6.7-4.5c3.6-1.8,7.3-3.7,11.1-5.5
  c20.9-10.4,42.4-21.1,61.1-34.3c-4.1,3.7-8.2,7.5-12.4,11.2l-2.8,2.5c-11.6,10.3-22.9,20.4-34.7,29.6
  C406.6,418.4,401.8,419,396.9,419.7z M399.6,407.9c-4,2-7.9,3.9-11.7,5.8c-1.5-1-2.9-1.9-4.4-2.8c1.7-1.7,3.4-3.4,5.2-5.2
  c6.2-5.9,11.9-11.1,17.5-16.1c7.3-6.6,14.1-12.7,20.3-19.2c11.7-1,23.5-1.8,34.6-1.4c1.9,0.1,3.7,0.1,5.6,0.1
  C446.8,384.4,422.9,396.3,399.6,407.9z M461.3,367.8c-10.8-0.4-22.2,0.3-33.5,1.3c11.3-12.2,20.3-26.1,26.5-47.6
  c4.3-14.9,10.3-28.8,16.1-42.2c0.5-1.1,1-2.2,1.4-3.3c0.3,3.2,0.6,6.5,0.9,9.7c1.2,13.1,2.3,26.6,7.8,39.1
  c1.9,4.5,4.3,8.6,6.6,12.5c1.4,2.4,2.8,4.8,4,7.2c-0.1,0.1-0.1,0.2-0.2,0.3c-6.8,7.8-14.2,15.3-21.9,22.6c-0.2,0.2-0.4,0.3-0.6,0.5
  C465.9,368,463.6,367.9,461.3,367.8z M490.1,203.4c-0.1,3.9-0.3,7.7-0.7,11.4c-7.9-14.6-16.9-28.2-26.7-40.3
  c-5.7-7-16.5-21-27.8-38.4c-1.5-7.8-1.1-15.6-0.2-23.5c11.3,11.4,19.9,25.4,28.3,39c7.7,12.6,15.8,25.7,25.9,36.7l0.3,0.3
  c0.4,0.4,0.7,0.8,1.1,1.1C490.2,194.2,490.2,198.8,490.1,203.4z M434.3,104.8c-0.2,1.7-0.4,3.5-0.6,5.2c-1-1-2.1-2-3.2-2.9
  c-6.1-5.4-12.3-9.3-18.7-12.1c-0.6-1.4-1.2-2.7-1.8-4.1c9.3-0.6,17.9-0.6,25.8,0C435.5,95.6,434.9,100.3,434.3,104.8z M433.3,133.6
  c-7.2-11.3-14.5-23.9-20.6-37c5.8,2.7,11.5,6.4,17.1,11.3c1.3,1.1,2.5,2.3,3.8,3.5C432.7,118.9,432.3,126.2,433.3,133.6z
   M410.3,94.3c-2.7-1.1-5.3-2-8-2.8c2.2-0.2,4.4-0.4,6.5-0.6C409.3,92.1,409.8,93.2,410.3,94.3z M411,95.9
  c6.7,14.5,14.8,28.4,22.7,40.6c0.7,3.5,1.7,7.1,3.3,10.7c2.2,5.1,4.3,9.4,6.1,13.1c7.6,15.8,10.8,22.3,0.9,45.1
  c-0.3,0.6-0.6,1.2-0.8,1.8c-2.9-4-5.9-7.9-9-12c-1.3-1.8-2.7-3.5-4-5.3c-20.5-27.3-26.6-43.3-28.3-74.4c-0.4-7.9-2.7-15.7-6-23.2
  c1.1-0.1,2.1-0.3,3.1-0.4C403,92.8,407.1,94.1,411,95.9z M295,122.3c-4.3,2.8-8.7,4.9-13.1,7c-9.1,4.3-17.7,8.3-23.7,18.2
  c-5.7,9.3-5.7,19.1-5.7,28.5c0,5.6,0,11.3-1.2,17.1c-2.8,14-11.6,20.8-20.9,28.1c-9.2,7.2-18.7,14.6-21.8,29.3
  c-4.3-9.3-8.6-18.4-12.8-27.3c-2.4-5-4.7-9.9-7-14.9c2-3.2,3.9-6.6,5.5-10.3c2.5-5.5,3.9-11.3,5.4-17c1.8-7.2,3.7-14.7,7.6-21.3
  c4.5-1.5,8.9-3.2,13.2-5.4c13.2-6.6,14.7-10.9,17.5-18.5c0.6-1.6,1.2-3.4,2-5.3c0.7-0.7,1.4-1.4,2.1-2.2c1.9-2.1,3.6-4.4,5.2-6.9
  c20.2-3.2,40.5-7.6,60.3-11.9C304.2,114.4,300.1,118.9,295,122.3z M246.6,120.3c-0.3,0-0.6,0.1-0.8,0.1c0.6-0.7,1.3-1.4,1.9-2
  C247.3,119,246.9,119.6,246.6,120.3z M244,120.7c-9.3,1.5-18.4,3.3-27.3,5.4c12.9-10.2,26.2-20.4,40-29.7
  c-2.1,6.8-4.5,13.3-7.4,19.1C247.3,117,245.5,118.7,244,120.7z M214,126.7c-5.2,1.3-10.4,2.6-15.5,4.1c2.1-3.4,3.7-6.7,5.1-9.9
  c4.1-8.6,7.3-15.5,18.9-19.6c5.2-1.8,9.6-3.2,13.9-4.4c8.1-2.4,15-4.4,22.6-8.9c-0.6,2.2-1.2,4.5-1.9,6.7
  C242.2,104.7,227.9,115.7,214,126.7z M260.5,87.1c1.7-1,3.4-2.2,5.1-3.5c2.7,0.2,5.3,0.4,8,0.7c-1.9,1.1-3.7,2.2-5.6,3.3
  c-3.2,1.9-6.3,4-9.4,6C259.3,91.5,259.9,89.3,260.5,87.1z M260.9,85.5c0.2-0.7,0.4-1.4,0.5-2.1c0.8,0,1.6,0.1,2.4,0.2
  C262.9,84.2,261.9,84.9,260.9,85.5z M265.3,82.5c-1.2-0.1-2.3-0.2-3.5-0.2c0.6-2.4,1.2-4.9,1.8-7.3c0.7-2.9,1.4-5.7,2.1-8.5
  c1.3,0.2,2.6,0.3,4,0.5l2.5,0.3c3.7,0.5,7.2,0.7,10.6,0.9c-2.9,2.4-5.7,4.7-8.6,7.1c-1.7,1.4-3.5,2.9-5.2,4.3
  C267.8,80.6,266.5,81.5,265.3,82.5z M272.4,66.1l-2.5-0.3c-1.3-0.2-2.5-0.3-3.8-0.5c0.7-2.6,1.4-5.1,2.1-7.6
  c8.3,0.5,16.6,1.3,24.9,2.2c-3,2.4-5.9,4.7-8.9,7.1C280.4,66.9,276.5,66.6,272.4,66.1z M267.3,56.5c-0.2,0-0.4,0-0.6,0
  c0.2-0.1,0.5-0.2,0.7-0.3C267.4,56.3,267.3,56.4,267.3,56.5z M267,57.6c-0.7,2.4-1.4,4.9-2.1,7.5c-4.2-0.5-8.5-0.9-12.8-0.9
  c-1.2,0-2.4,0.1-3.6,0.2c5.4-2.6,10.6-4.9,15.6-6.9C265.1,57.5,266,57.6,267,57.6z M221.2,83.2c-0.8,0.1-1.6,0.2-2.4,0.4
  c2-2.2,4.3-4.3,6.9-6.3C224.2,79.3,222.7,81.3,221.2,83.2z M227.9,74.2c-4.3,3-7.9,6.3-11,9.7c-1,0.2-2.1,0.4-3.1,0.6
  c0.7-0.6,1.4-1.2,2.1-1.8c4.4-3.7,8.6-7.2,13.1-10.1C228.6,73.1,228.2,73.6,227.9,74.2z M217.5,85c0.9-0.2,1.8-0.3,2.7-0.4
  c-3.4,4.4-7,8.5-10.9,12C211.7,92.5,214.3,88.6,217.5,85z M221.8,84.3c12.7-1.8,25.5-1.8,38.5-1c-0.3,1-0.5,2.1-0.8,3.1
  c-7.9,4.8-15,6.9-23.4,9.4c-4.3,1.3-8.8,2.6-14,4.4c-12.1,4.3-15.5,11.7-19.5,20.2c-1.5,3.2-3,6.5-5.1,9.9c-0.2,0.3-0.4,0.7-0.6,1
  c-3.4,1-6.8,2-10.1,3.1c7.3-8.4,11.8-17.3,16.3-26c1.5-3,3.1-6,4.7-9c0.5-0.4,1.1-0.9,1.6-1.3C213.8,94.3,217.9,89.4,221.8,84.3z
   M195.8,132.9c-7.5,10.8-15.1,13.2-26.3,16.6c-0.4,0.1-0.9,0.3-1.3,0.4c5.1-3.1,9.9-6.6,14.4-11c0.9-0.9,1.8-1.8,2.6-2.7
  C188.7,135,192.2,133.9,195.8,132.9z M181.7,138.1c-4.5,4.5-9.5,8.1-14.7,11.1c0.1-2.2,0.2-4.4,0.4-6.6c5.1-2,10.2-3.9,15.4-5.6
  C182.4,137.4,182.1,137.7,181.7,138.1z M169.8,150.6c11.4-3.5,19.8-6.1,27.7-18.3c4.7-1.4,9.4-2.7,14.2-3.9
  c-1.4,1.1-2.9,2.3-4.3,3.4c-11.8,9.3-24,19-36.5,28c-0.9,0.6-1.8,1.3-2.7,1.9c-0.4-1.1-0.9-2.2-1.3-3.4c0-2.3,0-4.6,0-6.9
  C167.9,151.2,168.9,150.9,169.8,150.6z M167.4,178.1c0,0.2,0.1,0.4,0.1,0.7c-7.2,10.6-5,24.4-2.4,40.6c0.4,2.6,0.8,5.2,1.3,8
  c0,0.1,0,0.2,0,0.3c-1.7,4.5-4,8.8-7.1,13.3c-2.8-4.8-5.6-9.7-8.2-14.9c-4.6-9-8.4-17.9-11.5-26.8c0.4-6.5,1.1-13.1,2.1-19.9
  c8.2-4.6,16.5-9.3,24.4-14.7C166.3,169.1,166.8,173.6,167.4,178.1z M165.8,149.9c-0.6,0.3-1.1,0.7-1.7,1c-0.7-2-1.5-4-2.2-6
  c1.4-0.6,2.8-1.2,4.3-1.7C166,145.4,165.9,147.7,165.8,149.9z M165.8,151.8c0,1.1-0.1,2.3-0.1,3.4c-0.4-1-0.8-2-1.1-3
  C165,152.1,165.4,152,165.8,151.8z M163.1,151.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-1.9,0.6-3.8,1.3-5.6,2
  c0.5-2.5,1.1-4.9,1.7-7.4c0.9-0.4,1.8-0.8,2.7-1.2C161.6,147.4,162.3,149.4,163.1,151.4z M163.3,152.7c0.1,0,0.2-0.1,0.2-0.1
  c0.8,2,1.5,4,2.3,6c0,1.6,0.1,3.2,0.2,4.7c-7.8,5.3-16,10-24.1,14.6c0.2-1.6,0.5-3.2,0.8-4.7c0.6-0.1,1.3-0.2,2-0.2
  c8.8-0.7,9.7-6.5,10.8-13.1c0.2-1.1,0.4-2.3,0.6-3.5C158.4,155.2,160.9,154,163.3,152.7z M154.2,159.6c-1,6.7-1.8,11.5-9.7,12.1
  c-0.6,0-1.1,0.1-1.6,0.2c0.6-3.6,1.3-7.1,2.1-10.7c0.3-0.1,0.6-0.2,0.8-0.4c3-1.3,5.9-2.6,8.9-4
  C154.5,157.8,154.4,158.7,154.2,159.6z M141.6,172.2c-1.7,0.3-3.2,0.8-4.6,1.5c2.1-3.9,4.3-7.8,6.4-11.7c0.1,0,0.1-0.1,0.2-0.1
  C142.9,165.3,142.2,168.7,141.6,172.2z M135.2,174.6c-0.8,0.5-1.5,1-2.2,1.5c-0.2-1.2-0.5-2.3-0.7-3.5c2.4-3.9,5.6-7.1,9.4-9.8
  C139.5,166.8,137.3,170.8,135.2,174.6z M132,171.2c-0.2-1.2-0.4-2.4-0.6-3.7c2.2-1.1,4.4-2.2,6.7-3.2
  C135.8,166.3,133.7,168.6,132,171.2z M131.4,174.2c0.2,0.9,0.4,1.8,0.6,2.8c-1,0.9-2,1.9-3,2.9C129.6,177.8,130.4,176,131.4,174.2z
   M132.2,178.2c0.1,0.4,0.2,0.7,0.3,1.1c-1.1,1.9-2.1,3.7-3.2,5.5c-0.6,0.3-1.2,0.7-1.8,1c0.2-1.2,0.4-2.3,0.7-3.4
  C129.5,180.9,130.9,179.5,132.2,178.2z M128.2,186.9c-0.3,0.6-0.7,1.2-1,1.8c0-0.4,0.1-0.9,0.2-1.3
  C127.6,187.2,127.9,187,128.2,186.9z M120.7,243.5c-3.3,7.5-7.4,14.7-11.3,21.8c-0.1,0.2-0.3,0.4-0.4,0.7c0.5-2.5,1.1-5.1,1.8-7.8
  c3.8-14.2,3.3-28.9,2-44c4.2-6.6,8.5-13.6,12.9-20.9c-0.1,4.7,0,9,0.2,13.2C126.2,219.2,126.5,230.2,120.7,243.5z M125.8,190.9
  c-4.5,7.5-8.9,14.7-13.1,21.4c-0.2-1.7-0.3-3.4-0.5-5.1c-0.3-3.1-0.8-6.5-1.3-10c0.4-0.4,0.9-0.8,1.4-1.1c4.5-2.8,9.1-5.4,13.7-8
  C125.9,189,125.8,189.9,125.8,190.9z M89.3,333c0.3,4.3,0.9,8.6,1.7,12.9c-4.3-6.3-8.3-12.7-12.3-19c-0.8-1.2-1.5-2.4-2.3-3.7
  c-0.1-1.9-0.2-3.5-0.3-4.9c-0.1-2.3-0.2-4.5-0.2-6.8c1.7-4.3,3.4-8.6,5.2-12.9C82.9,310.3,85.7,321.8,89.3,333z M93.4,351.5
  c2.3,3.2,4.6,6.4,7,9.5c3.7,7.7,7.7,14.9,12.1,21.7c-0.8-0.3-1.7-0.6-2.5-0.9l-0.5-0.2c-2.7-1-5.5-2-8.3-3.1
  c-0.4-1.4-0.8-2.7-1.2-4C97.3,366.3,95.1,358.7,93.4,351.5z M103.8,365.2c1.5,1.8,3,3.6,4.6,5.3c3.2,3.6,6.8,6.8,10.7,9.9
  c0.2,1.7,0.4,3.3,0.8,5c-1.9-0.7-3.7-1.3-5.6-2C110.6,377.6,107.1,371.5,103.8,365.2z M120.3,386.7c2.2,7.4,7.2,14.4,18,21.6
  c1.4,0.9,2.8,1.8,4.2,2.7c-1.6,0.3-3.1,0.6-4.7,0.9c-8.1-7.8-15.6-16.9-22.4-27C117,385.5,118.6,386.1,120.3,386.7z M139.3,415
  c-2.1-0.4-4.1-0.8-6.2-1.2c1.4-0.2,2.9-0.4,4.3-0.7C138,413.8,138.7,414.4,139.3,415z M138.8,413c1.7-0.3,3.4-0.6,5.1-1
  c0.3,0.2,0.7,0.5,1,0.7c3.8,2.5,7.7,5,11.5,7.7c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2-0.1-0.3-0.1c-4.6-2.1-9.4-3.5-14.1-4.6
  C140.7,414.7,139.7,413.8,138.8,413z M145.6,411.6c0,0-0.1,0-0.1-0.1c5.3-1.3,10.5-2.8,15.4-4.7c1.8,1.1,3.5,2.2,5.2,3.4
  c1.4,3.5,2.9,6.9,4.6,10.1c-4,0-8.1,0.1-12.2,0C154.2,417.3,149.8,414.4,145.6,411.6z M162.2,406.3c0.7-0.3,1.4-0.6,2-0.8
  c0.4,1,0.7,1.9,1.1,2.9C164.3,407.7,163.2,407,162.2,406.3z M163.9,401.1c1.5,0.4,2.9,0.9,4.4,1.3c-1.1,0.5-2.3,1-3.4,1.5
  C164.5,403,164.2,402.1,163.9,401.1z M163.4,399.8c-2-6.1-3.8-12.4-5.2-18.5c2.2,0.8,4.5,1.2,7,1.3c5.1,0.2,10.2-1,15.1-2.6
  c2.8,1.2,5.8,2.3,8.9,3.1c1.7,0.5,3.5,0.8,5.2,1.2c0.4,2.4,0.9,4.8,1.6,7c-7.4,1.7-14.6,4-21.8,8c-1.4,0.8-2.9,1.6-4.4,2.3
  C167.7,401.1,165.6,400.4,163.4,399.8z M174.8,400.4c7.1-4,14.3-6.2,21.6-7.9c1.8,5.4,4.5,10.4,8.1,15c0.8,1,1.7,2,2.5,3
  c-11.9-2.2-23.8-5.1-35.5-8.4C172.6,401.6,173.7,401,174.8,400.4z M205.4,406.8c-3.5-4.5-6.1-9.3-7.9-14.6c3.3-0.7,6.6-1.4,10-2
  c4.2-0.8,8.5-1.7,12.8-2.7c2.9,0.3,5.8,0.6,8.7,1c4.1,11.4,10,19.8,17.3,26.2c-12.4-0.3-25-1.6-37.4-3.8
  C207.7,409.6,206.5,408.2,205.4,406.8z M230.4,388.7c6,0.9,12,2.3,17.7,4.6c9.4,3.8,16.7,10.4,23.7,16.8c1.3,1.2,2.6,2.4,3.9,3.6
  c-5.4,0.3-11,0.6-16.7,0.9c-2.9,0.1-5.8,0.2-8.7,0.2c-0.7,0-1.4,0-2.1,0C240.6,408.4,234.6,400.1,230.4,388.7z M259.1,415.7
  c6.1-0.3,12.1-0.6,17.9-0.9c4.6,4,9.5,7.8,15.1,10.7c2.1,1.1,4.3,2,6.5,2.9c-4.1,1-8.3,1.9-12.5,2.8c-14.1-3-26.5-7.4-36.5-15.2
  C252.8,415.9,256,415.8,259.1,415.7z M300.6,429c5.9,2,12.1,3.4,18.5,4.2c-1.4,0.8-2.8,1.7-4,2.7c-1.7-0.3-3.4-0.7-5.2-0.9
  c-7.3-1-14.4-2.1-21-3.4C292.9,430.8,296.8,429.9,300.6,429z M321.4,433.6c2.3,0.3,4.6,0.5,7,0.6c-3.5,0.8-7,1.6-10.5,2.4
  c-0.4-0.1-0.8-0.2-1.2-0.2C318.1,435.2,319.7,434.3,321.4,433.6z M332.8,434.3c1.5,0,2.9,0.1,4.4,0.1c3.7,0,7.5-0.1,11.3-0.3
  c-5.2,2.5-10.5,4.7-15.9,6.7c-4-1.3-8.1-2.6-12.2-3.6C324.5,436.2,328.7,435.3,332.8,434.3z M337.5,433.2c0.4-0.1,0.8-0.2,1.2-0.3
  c1.5-0.3,2.9-0.7,4.4-1c0.3,0.1,0.7,0.2,1,0.3c0.6,0.2,1.3,0.5,1.9,0.8C343.2,433.1,340.4,433.2,337.5,433.2z M354.4,432.5
  c3.7-1.8,7.3-3.7,10.8-5.6c0.3-0.1,0.6-0.1,1-0.2c-2,1.9-4,3.6-6.1,5.2C358.2,432.1,356.3,432.3,354.4,432.5z M368.3,426.3
  c5.5,1,10.9,1.8,16.2,2.4c-7.2,1.1-14.7,2.2-22.2,3C364.3,430,366.3,428.2,368.3,426.3z M371.1,425.6c0.6-0.1,1.1-0.2,1.7-0.4
  c4,0.7,8,1.5,11.9,2.3C380.3,427.1,375.7,426.4,371.1,425.6z M431.5,416.2c-5.9,0.5-12.1,0.9-18,1.5c11.3-8.9,22.2-18.6,33.3-28.5
  l2.8-2.5c6.6-5.9,13-11.7,19.2-17.6c6.2,0,12.4-0.3,18.4-0.5c-2.9,2.4-5.7,5-8.3,7.8c-3.6,3.9-6.7,8-9.7,11.9
  c-5.5,7.2-10.8,14-18.6,19.9c-3.3,2.5-6.8,4.9-10.5,7.3C437.2,415.8,434.4,416,431.5,416.2z M470.1,367.9c3.6-2.9,7.2-5.9,10.5-9
  c4.4-4.2,8-8.6,11-13.1c2.2,4.3,3.9,8.9,4.8,14.2c0.1,0.4,0.1,0.7,0.2,1.1c-1.2,1-2.4,2-3.6,2.9c-1.4,1.1-2.8,2.3-4.3,3.4
  c-0.1,0-0.2,0-0.3,0C482.4,367.6,476.3,367.9,470.1,367.9z M493.7,364.8c1-0.8,2-1.6,3.1-2.5c0.2,1.6,0.3,3.1,0.4,4.6
  c-2.2,0.1-4.4,0.2-6.5,0.3C491.7,366.4,492.7,365.6,493.7,364.8z M492.4,344.8c6.1-7,11.9-14.3,17.1-22c0.1,8.6,0.1,17.3-0.2,25.8
  c-3.4,4.4-7.5,8.1-11.7,11.5c0-0.1,0-0.3-0.1-0.4C496.6,354.1,494.7,349.3,492.4,344.8z M509.2,350.5c-0.2,5.5-0.6,10.9-1.1,16.2
  c-3.3,0-6.5,0.1-9.8,0.2c-0.1-1.8-0.3-3.6-0.5-5.5C501.8,358.2,505.8,354.6,509.2,350.5z M498.4,368.1c3.2-0.1,6.4-0.2,9.6-0.2
  c-0.4,4.2-1,8.4-1.7,12.5c-3.8,3.5-7.6,7-11.5,10.5C497.3,384.1,498.7,376.7,498.4,368.1z M509.2,367.9c3.2,0,6.5,0.1,9.7,0.3
  c-3.6,3.7-7.4,7.3-11.1,10.9C508.3,375.5,508.8,371.7,509.2,367.9z M530.2,353.8c0.1,0.8,0.2,1.5,0.2,2.3c-0.6,0.7-1.3,1.4-1.9,2
  C529.1,356.7,529.6,355.3,530.2,353.8z M526.3,334.3c0.1-0.5,0.1-1,0.2-1.5c1.2,5.5,2.3,11.3,3.1,17
  C526.9,344.8,525.7,339.4,526.3,334.3z M525.9,324.5c-0.9-3.9-1.7-7.6-2.4-11c0.7-5.2,1.1-10.6,1.2-16.1c2,3.6,4,7.2,6.1,10.9
  c2.1,3.8,4.3,7.5,6.3,11.4c-0.8,0.4-1.6,0.7-2.3,1c-3.3,1.4-6.3,2.7-8.2,7.2C526.4,326.7,526.1,325.6,525.9,324.5z M531.8,307.7
  c-2.4-4.2-4.7-8.4-7-12.5c4-8.9,7.3-18.4,9.9-28.7c1.5,2.8,3.2,5.5,4.9,8.3c1.9,3,3.7,6,5.3,8.9c-0.2,1.5-0.7,2.9-1.5,4.1
  c-4,5.7-4.8,7.9-4.3,15.8c0,0.3,0.3,0.5,0.6,0.5c0,0,0,0,0,0c0.3,0,0.6-0.3,0.5-0.6c-0.5-7.6,0.3-9.5,4.1-15.1
  c0.6-0.9,1.1-2,1.4-3.1c1.1,2.1,2,4.2,2.6,6.3c-2.1,8.3-4.5,16.7-7,25.1c-1.1,0.9-2.1,1.7-3.2,2.2
  C536.1,315.3,534,311.5,531.8,307.7z M540.8,318.8c-0.3,1.1-0.7,2.2-1,3.2c-0.3-0.6-0.7-1.3-1-1.9
  C539.4,319.8,540.1,319.3,540.8,318.8z M548.1,255c1.5-3.4,3.8-6.7,7-10c0.6,1.9,1.3,3.7,1.9,5.6c-0.3,2-0.6,3.9-1,5.8
  c-1.2,6.7-2.6,13.5-4.1,20.3C551.2,269.6,550,262.3,548.1,255z M556,244.1c0.7-0.7,1.4-1.3,2.1-2c-0.2,2-0.5,4-0.7,5.9
  C557,246.7,556.5,245.4,556,244.1z M559.6,239.3c0.2-2,0.4-4.1,0.6-6.2c0.6,1.4,1.2,2.9,1.8,4.3C561.2,238,560.4,238.7,559.6,239.3
  z M538.6,185.4c-1.7-6.1-3.3-12.3-4.8-18.6c2,1.4,4,2.9,6,4.4c0.2,2.6,0.6,5.1,1,7.6C540.1,181,539.3,183.2,538.6,185.4z
   M539.6,169.7c-2-1.6-4.1-3.1-6.3-4.6c-0.2-0.7-0.3-1.4-0.5-2.1c-1.7-7.2-3.5-14.6-5.6-22c0.5-0.9,0.9-1.8,1.3-2.6
  c1.4-2.8,2.8-5.4,3.8-8.2c2.4,2.6,5.4,3.9,8.6,3.9c0.1,0,0.3,0,0.4,0c-0.2,3.4-0.5,6.8-0.9,10.2c-0.4,4.2-0.8,8.6-1.1,12.9
  C539.2,161.4,539.3,165.6,539.6,169.7z M531.9,127.7c-4.2-6.2-3.9-16.4-1.1-23.6c0.4,1.7,0.8,3.5,1.2,5.4
  C533.6,117.6,533.3,123,531.9,127.7z M531.3,128.9c0,0.1,0.1,0.1,0.1,0.2c-1,3-2.4,5.7-4,8.7c-0.2,0.5-0.5,1-0.8,1.5
  c-1.5-5.3-3.2-10.7-5.2-16c-4.6-12.5-12.8-24.6-23.8-35.5c-0.3-0.6-0.6-1.1-0.9-1.7c0.4-1.8,0.8-3.7,1.2-5.5c8.6,3,17.3,7,24.5,13
  c2.7,2.3,5.2,4.8,7.6,7.5c0.1,0.3,0.2,0.7,0.3,1C526.6,110,526.1,122,531.3,128.9z M528.2,97.4c-1.6-1.7-3.3-3.2-5-4.7
  c-6.4-5.4-13.9-9.2-21.6-12.1c4.5,0.5,9.5,1.4,15.4,2.9c0.9,0.2,1.8,0.5,2.8,0.9C522.9,88.6,525.7,92.9,528.2,97.4z M521.8,85.2
  c1,0.4,2,0.9,3,1.4c1.3,2.6,2.4,5.5,3.5,8.5C526.3,91.8,524.1,88.4,521.8,85.2z M517.4,82.5c-7.5-1.9-13.4-2.9-19-3.2
  c0.6-2.5,1.3-5,2-7.4c2.4,0,4.7-0.5,6.8-1.4c1.8,1.6,3.6,3.4,5.3,5.2c2.2,2.3,4.2,4.7,6.1,7.3C518.1,82.7,517.7,82.6,517.4,82.5z
   M497.2,79.1c-0.9-0.3-1.7-0.6-2.6-0.8c-0.8-2.6-1.6-5.2-2.4-7.8c0,0,0,0,0,0c2.4,0.8,4.7,1.3,6.9,1.4
  C498.4,74.2,497.8,76.7,497.2,79.1z M494.5,82.2c-0.4-0.6-0.7-1.2-1.1-1.8c0.2,0,0.4,0,0.6,0C494.2,81,494.4,81.6,494.5,82.2z
   M493.7,79.3c-0.3,0-0.6,0-1,0.1c-0.1-0.2-0.3-0.4-0.4-0.6c0.4,0.1,0.9,0.3,1.3,0.4C493.7,79.2,493.7,79.2,493.7,79.3z M494.9,85
  c-1.5-1.4-3.1-2.8-4.7-4.2c0.7-0.1,1.3-0.2,2-0.3C493.1,82,494,83.5,494.9,85z M489.1,79.9c-0.2-0.1-0.3-0.3-0.5-0.4
  c-1.2-1-2.4-2-3.7-2.9c1.9,0.5,3.8,1,5.7,1.6c0.3,0.4,0.6,0.8,0.8,1.3C490.6,79.6,489.8,79.8,489.1,79.9z M488.7,81.2
  c2.3,1.9,4.5,3.9,6.7,5.9c-0.7,3.4-1.3,6.8-1.8,10.2c-3.6-3.8-7.6-7.2-11.9-10.3c0-0.2,0-0.3,0.1-0.5
  C482.3,83.7,485.3,82.1,488.7,81.2z M460,94.1c0,0-0.1,0-0.1,0c-0.2-0.2-0.3-0.4-0.5-0.6C459.6,93.7,459.8,93.9,460,94.1z
   M437,73.6c9.5,6.3,15.8,12.7,21,19.9c-6.3-1.8-13.3-3.1-21-3.7C437.3,84.3,437.3,78.9,437,73.6z M449.4,81.9
  c-3.5-3.3-7.6-6.5-12.5-9.7c-0.1-1.6-0.2-3.1-0.3-4.7c0,0,0,0,0,0C440.9,72.3,445.2,77.1,449.4,81.9z M437,90.9
  c8.2,0.7,15.6,2.1,22.2,4.1c3.2,4.6,6.2,9.5,9.4,14.9c2.3,3.9,4.7,7.9,7.5,12.2c3.9,6.1,8.8,11.3,14.2,15.9
  c-0.3,11.8-0.2,23.6-0.1,35.3c0,4.8,0.1,9.7,0.1,14.6c-0.1-0.1-0.1-0.2-0.2-0.2l-0.3-0.3c-10.1-10.9-18.1-23.9-25.8-36.5
  c-8.5-13.9-17.3-28.2-29.1-39.9c0.2-2,0.5-4,0.7-6.1C436.1,100.4,436.6,95.7,437,90.9z M394.1,91.3c-2,0.3-4,0.5-6.1,0.9
  c-24.4,3.7-49,9.1-72.7,14.4c-1.7,0.4-3.4,0.8-5.2,1.1c3.7-5.9,6.6-12.4,9.6-18.8c9.5,0.7,19.1,1.1,28.9,0.9
  c2.6-0.1,5.2-0.1,7.8-0.2c13.3-0.4,25.7-0.7,37.6,1.3C394,91,394.1,91.2,394.1,91.3z M308.5,108c-19.8,4.4-40.2,8.7-60.4,12
  c0.7-1.2,1.4-2.5,2.1-3.8c6.1-4.7,13.8-7.1,21.4-9.4c11.7-3.6,23.8-7.4,29.2-19.7c5.9,0.6,11.8,1.2,17.8,1.7
  C315.4,95.4,312.4,102,308.5,108z M271.1,105.7c-6.9,2.1-14,4.3-19.9,8.3c2.7-5.7,4.9-12,6.9-18.6c3.5-2.3,7-4.6,10.5-6.8
  c2.3-1.4,4.6-2.8,7-4.1c5.4,0.5,10.8,1.1,16.3,1.7c2.5,0.3,5,0.5,7.5,0.8C294.2,98.6,283,102.1,271.1,105.7z M275.4,83.3
  c-2.8-0.3-5.6-0.5-8.3-0.7c0.9-0.7,1.8-1.4,2.7-2.2c1.7-1.4,3.5-2.9,5.2-4.3c3.2-2.6,6.4-5.3,9.6-7.9c2.6,0.1,5,0.2,7.3,0.2l0.2,0
  c2.3,0,6.3,0.1,9.7,0.8c0,0.4,0,0.9,0.1,1.3C292.9,74.1,284.1,78.4,275.4,83.3z M294.3,58.9c-0.1,0-0.2,0-0.3,0
  c-8.5-0.9-17-1.8-25.5-2.3c0.1-0.3,0.2-0.6,0.3-0.9c1.4-0.5,2.7-1.1,4.1-1.6c2.1,0.4,4.1,0.8,6.2,1.2c5.3,1.1,10.6,2.1,16,3
  C294.8,58.5,294.6,58.7,294.3,58.9z M269.2,54.2c0.1-0.3,0.2-0.6,0.3-0.8c0.4,0.1,0.8,0.2,1.2,0.2C270.2,53.8,269.7,54,269.2,54.2z
   M268.7,52c-1.7-0.3-3.4-0.6-5.2-0.9c2.3-1.1,4.5-2.2,6.7-3.4C269.7,49.1,269.2,50.6,268.7,52z M268.3,53.1
  c-0.2,0.5-0.3,1.1-0.5,1.6c-1.3,0.5-2.6,1-3.9,1.6c-4.7-0.2-9.3-0.2-13.8,0c3.4-1.1,6.8-2.3,10.1-3.7c0.5-0.2,1-0.4,1.4-0.6
  C263.8,52.3,266.1,52.7,268.3,53.1z M261.4,57.4c-5.2,2.2-10.7,4.7-16.4,7.5c-1.3,0.2-2.5,0.5-3.7,0.8c-2.9,0.8-5.6,1.9-8.2,3.2
  c2.4-3.3,4.9-6.5,7.6-9.5c1.6-0.5,3.2-1,4.7-1.5c0.1,0,0.1,0,0.2-0.1C250.8,57.3,256,57.3,261.4,57.4z M230.7,70.2
  c-5.6,3.3-10.7,7.5-15.6,11.7c-1.2,1-2.5,2.1-3.8,3.1c-1.6,0.3-3.2,0.7-4.7,1.1c0.3-0.6,0.6-1.2,0.9-1.7
  c4.1-7.9,6.9-13.2,18.6-19.2c4-2.1,8.2-3.7,12.5-5.1C235.8,63.3,233.3,66.7,230.7,70.2z M209.3,86.7c-1.7,1.3-3.3,2.6-5.1,3.8
  c0.6-1,1.1-2,1.6-3C207,87.2,208.1,86.9,209.3,86.7z M202.3,93.6c0.2-0.3,0.3-0.5,0.5-0.7c3.2-2.1,6.2-4.4,9.1-6.8
  c1.3-0.3,2.6-0.5,3.9-0.8c-3.5,4.3-6.4,8.8-9,13.5c-8.6,7-18.4,12.7-23.7,15.6c-0.3,0.2-0.4,0.5-0.2,0.8c0.2,0.3,0.5,0.4,0.8,0.2
  c4.9-2.7,13.6-7.8,21.7-14c-1.2,2.2-2.3,4.4-3.4,6.6c-4.6,9.1-9.5,18.5-17.4,27.3c-5.7,1.9-11.4,4-17,6.2
  c0.6-6.5,1.5-12.9,2.9-19.4c4-4.1,8.5-7.3,13-10.5C190.3,106.5,197.4,101.5,202.3,93.6z M166.3,141.9c-1.6,0.6-3.2,1.3-4.8,1.9
  c-0.5-1.4-1-2.8-1.4-4.2c1.2-3.7,2.7-7.2,4.7-10.5c1.2-2,2.6-3.9,4.1-5.5C167.7,129.7,166.8,135.8,166.3,141.9z M160.5,144.2
  c-0.7,0.3-1.3,0.6-2,0.8c0.3-1.2,0.7-2.4,1-3.6C159.8,142.4,160.2,143.3,160.5,144.2z M157.2,145.6c-2.3,1-4.6,2-6.9,3.1
  c2.3-4.7,4.7-9.6,6.9-14.3c0.6,1.8,1.1,3.5,1.7,5.3C158.2,141.6,157.7,143.6,157.2,145.6z M155.7,126c3.4-3.8,6.7-7.8,9.8-11.7
  c-2.8,5.6-5.5,11.3-8.2,16.9C156.8,129.5,156.3,127.8,155.7,126z M155.3,124.7c-0.4-1.2-0.7-2.4-1-3.6c0-0.1,0.1-0.2,0.1-0.4
  c4.7-4.2,9.5-8.3,14.3-12.4c-0.1,0.3-0.3,0.5-0.4,0.8C164.1,114.4,159.8,119.6,155.3,124.7z M154.4,125.7c-0.5,0.6-1.1,1.2-1.6,1.8
  c0.3-1.4,0.6-2.7,1-4.1C154,124.2,154.2,125,154.4,125.7z M154.8,127c0.6,1.9,1.2,3.9,1.8,5.8c-2.6,5.5-5.3,11.2-8.1,16.7
  c-0.4,0.2-0.7,0.4-1.1,0.5c1-4.4,2.1-8.8,3.1-13.1c0.6-2.4,1.1-4.7,1.7-7.1C153.1,128.9,154,127.9,154.8,127z M147.7,151.1
  c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.5,0.9-0.7,1.4c0.1-0.6,0.3-1.1,0.4-1.7C147.3,151.3,147.5,151.2,147.7,151.1z M148.4,152.3
  c0.3-0.6,0.6-1.3,1-1.9c2.5-1.1,4.9-2.2,7.4-3.3c-0.6,2.4-1.1,4.9-1.6,7.3c-3.4,1.4-6.7,2.9-9.8,4.7c0.1-0.5,0.2-1,0.3-1.5
  C146.6,155.8,147.5,154.1,148.4,152.3z M144.6,157.2c-0.6,1.2-1.2,2.3-1.8,3.5c-0.3,0.2-0.6,0.4-0.9,0.6c-3.6,1.6-7.2,3.2-10.7,5
  c-0.4-2.4-0.7-4.7-0.9-7c3-1.6,6.1-2.9,8.7-4.1c1.5-0.7,3-1.3,4.1-1.8c0.9-0.4,1.8-0.9,2.8-1.3C145.4,153.8,145,155.5,144.6,157.2z
   M130.2,168.1c0.2,1.5,0.5,3,0.8,4.5c-1.7,2.7-3,5.8-3.9,9.3c-0.5,0.6-1,1.2-1.5,1.8c-0.2,0.3-0.2,0.6,0.1,0.8
  c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c-0.1,0.7-0.3,1.5-0.4,2.2c-4.9,2.7-9.8,5.5-14.5,8.5
  c-0.4,0.2-0.7,0.5-1,0.7c0-0.1,0-0.1,0-0.2c1.9-3.5,3.5-7.3,4.8-11.8c0.2-0.6,0.3-1.2,0.5-1.8c1-2.2,1.8-4.5,2.4-6.6
  C122.1,172.6,126.1,170.2,130.2,168.1z M110.3,193.6c-0.1-0.9-0.3-1.9-0.4-2.9c1.2-1.1,2.3-2.5,3.3-3.9
  C112.4,189.3,111.4,191.5,110.3,193.6z M114.8,181.7c-1.4,2.8-3.1,5.5-5.1,7.6c-0.2-1.7-0.4-3.5-0.6-5.2c1.1-1.3,2.3-2.6,3.5-3.8
  c1.3-0.5,2.3-1,3.1-1.5C115.4,179.8,115.1,180.8,114.8,181.7z M109.4,195.4c-3.2,5.9-7.2,10.7-11.5,15.2c-0.5,0-1,0.1-1.5,0.1
  c0.8-3,1.7-6,2.8-8.9c0.8-2.1,1.6-4.2,2.5-6.1c2.5-1,4.9-2.2,6.9-3.7c0.1-0.1,0.2-0.2,0.3-0.3C109.1,192.9,109.2,194.2,109.4,195.4
  z M107.7,200.5c-0.2,0.8-0.4,1.5-0.6,2.3c-0.5,2.3-1,4.5-3,6.1c-1.3,1.1-2.8,1.4-4.5,1.6C102.5,207.4,105.2,204.2,107.7,200.5z
   M96.8,211.9c-0.3,0.3-0.6,0.6-0.8,0.9c0.1-0.3,0.1-0.5,0.2-0.8C96.4,211.9,96.6,211.9,96.8,211.9z M98.1,201.4
  c-1.1,3.2-2.1,6.4-2.9,9.6c-0.6,0.1-1.2,0.3-1.7,0.6c-0.8,0.4-1.5,0.9-2.1,1.4c-0.1-0.5-0.1-0.9-0.2-1.4c2.4-4.8,4.8-9.6,7.2-14.5
  c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1.1-0.4C99.4,198,98.8,199.6,98.1,201.4z M90.9,218c0.1-0.6,0.3-1.2,0.4-1.9
  c0.1-0.6,0.2-1.1,0.2-1.6c0.6-0.7,1.4-1.4,2.6-1.9c0.3-0.1,0.6-0.2,0.8-0.3c-0.2,0.7-0.4,1.5-0.6,2.2c-0.2,0.2-0.4,0.4-0.5,0.6
  C92.8,216,91.8,217,90.9,218z M93.7,216.7c-0.3,1.2-0.6,2.4-0.9,3.6c-0.5,2-1,4.1-1.5,6.1c0-0.3-0.1-0.7-0.1-1l-0.1-0.6
  c-0.1-0.7-0.2-1.4-0.4-2.2c-0.2-0.9-0.3-1.7-0.4-2.4C91.5,219,92.6,217.9,93.7,216.7z M90.3,214.2c-1.2,1.7-1.3,3.6-1.1,5.6
  c0,0.1,0,0.1-0.1,0.2c-1.6,1.7-3.2,3.5-4.8,5.4c2-3.9,4-7.9,6-11.9C90.3,213.7,90.3,213.9,90.3,214.2z M89.5,222.2
  c0,0.2,0.1,0.5,0.1,0.7c0.1,0.7,0.3,1.4,0.4,2.1l0.1,0.6c0.2,1.1,0.4,2.8,0.4,4.3c-0.2,0.7-0.4,1.5-0.6,2.2
  c-0.1,0.1-0.1,0.2-0.1,0.2c-1,1-2.3,0.9-3.9,0.9c-0.8,0-1.6-0.1-2.5,0.1c0.3-1.5,0.9-2.6,1.6-3.8c0.4-0.6,0.7-1.3,1.1-2
  C87.4,226.1,88.6,224.4,89.5,222.2z M90.8,232.7c0.4-0.7,0.6-1.6,0.7-2.5c0.9-3.2,1.7-6.4,2.4-9.6c0.4-1.8,0.9-3.7,1.4-5.5
  c1.1-1.1,2.1-2.2,3.1-3.3c0,0,0,0,0.1,0c2.2-0.1,4.4-0.2,6.3-1.9c2.2-1.9,2.8-4.4,3.3-6.7c0.4-1.7,0.8-3.2,1.7-4.6
  c0.4,3.1,0.9,6.1,1.1,8.9c0.2,2.2,0.4,4.4,0.6,6.6c-4.4,6.9-8.6,13.4-12.5,19.4c-7,10.7-12.5,21.5-16.5,32.5
  c-1.1,1.7-2.2,3.4-3.3,5.2c-0.2-4.4-0.2-8.8-0.1-13.2c1.4-2.1,2.8-4.3,4.1-6.5C86.7,245.2,89,239,90.8,232.7z M80.7,296.5
  c-1.6,3.9-3.2,7.9-4.8,11.9c0.2-8.8,1.2-17.4,3.1-25.8C79.4,287.2,80,291.9,80.7,296.5z M74.7,311.4c-0.8,1.9-1.5,3.9-2.3,5.8
  c-2.5-3.9-5.1-7.8-7.7-11.6c3.1-10.6,7.9-20.8,13.7-30.8c0.1,1.3,0.2,2.7,0.3,4C76,289.4,74.7,300.3,74.7,311.4z M75.1,321.3
  c-0.6-1-1.2-1.9-1.8-2.9c0.5-1.3,1-2.6,1.6-3.9c0,1.3,0.1,2.7,0.1,4C74.9,319.3,75,320.3,75.1,321.3z M71.9,318.5
  c-1.4,3.5-2.7,6.9-4,10.4c-0.9-5.5-1.8-11.1-2.6-16.6c-0.3-1.7-0.5-3.3-0.8-5C67,311.1,69.5,314.8,71.9,318.5z M64.1,312.5
  c0.9,6.1,1.9,12.3,2.9,18.5c-0.3,0.9-0.7,1.8-1,2.7c-0.8,2-1.6,4.1-2.5,6.3c-0.6,1.5-1.2,3.1-1.9,4.7c-1.6-12.6-0.8-24.2,2-35.3
  C63.8,310.5,64,311.5,64.1,312.5z M61.1,349.4c0.5,3,1,6.1,1.6,9.1c-1.3-0.9-2.5-1.9-3.7-2.9C59.7,353.5,60.4,351.4,61.1,349.4z
   M62,347.2c0.9-2.3,1.8-4.5,2.6-6.7c0.9-2.2,1.7-4.3,2.5-6.3c0.1-0.3,0.2-0.6,0.3-0.9c1.8,10.2,3.8,20.4,6.5,30.4
  c0.2,0.9,0.5,1.8,0.8,2.6c-3.7-2.1-7.2-4.4-10.6-6.8C63.3,355.4,62.6,351.3,62,347.2z M75.1,367.9c-3.1,0.1-6.2,0.1-9.2,0
  c-0.5-2.2-1-4.4-1.4-6.6C67.9,363.7,71.5,365.9,75.1,367.9z M75.1,363.4c-2.9-10.5-5-21.3-6.8-32.2c1.5-3.8,2.9-7.6,4.4-11.4
  c0.8,1.3,1.7,2.6,2.5,3.9c0.7,10.4,2.2,26,5.7,40c0.3,1.4,0.7,2.7,1,4c-1,0-2,0.1-3.1,0.1c-0.4,0-0.9,0-1.3,0
  c-0.4-0.2-0.9-0.5-1.3-0.7C75.8,365.9,75.4,364.6,75.1,363.4z M123.7,416.4c-1.5-0.4-3.1-0.8-4.6-1.3c1.3,0,2.5-0.1,3.8-0.2
  C123.1,415.4,123.4,415.9,123.7,416.4z M143.5,417.2c3.1,0.8,6.2,1.8,9.3,3c-2-0.1-4.1-0.2-6.1-0.3
  C145.6,419,144.6,418.1,143.5,417.2z M155.7,421.4c1.9,0.9,3.8,1.7,5.7,2.5c0.4,0.3,0.8,0.6,1.1,0.9c-3.1-0.3-6.3-0.6-9.6-0.9
  c-0.4,0-0.7-0.1-1.1-0.1c-1.2-0.9-2.4-1.8-3.6-2.7C150.8,421.3,153.3,421.4,155.7,421.4z M164.2,426.2c0.6,0.5,1.2,1,1.8,1.5
  c-0.4,0.2-0.8,0.4-1.3,0.5c-2,0.6-4.1,0.4-6.3,0.2c-1.6-1-3.2-2.1-4.7-3.2C157.4,425.6,160.9,425.9,164.2,426.2z M171.9,420.3
  c-1.5-2.8-2.8-5.8-4.1-8.9c1.2,0.8,2.4,1.7,3.5,2.5c1.4,1,2.7,2,4.1,2.9c-1,0.6-1.9,1.4-2.7,2.5c-0.3,0.3-0.5,0.7-0.7,1
  C172,420.3,171.9,420.3,171.9,420.3z M173.7,420c0.8-1.1,1.8-1.9,2.8-2.5c1.5,1,2.9,1.9,4.4,2.7c-2.2,0-4.5,0-6.7,0.1
  c-0.3,0-0.5,0-0.8,0C173.5,420.2,173.6,420.1,173.7,420z M177.7,416.9c0.8-0.4,1.6-0.7,2.4-1c1.9-0.7,3.9-1.5,5.6-3.1
  c0.2-0.2,0.3-0.6,0-0.8c-0.2-0.2-0.6-0.3-0.8,0c-1.5,1.4-3.3,2.1-5.3,2.8c-1,0.4-2.1,0.8-3.1,1.3c-1.5-1-3-2.1-4.6-3.2
  c-1.6-1.2-3.3-2.4-5-3.5c-0.6-1.4-1.1-2.9-1.7-4.4c1.6-0.7,3.1-1.4,4.7-2.2c12.6,3.6,25.5,6.7,38.4,9c3.9,4.2,8.1,7.8,12.3,11.3
  c0,0,0.1,0.1,0.1,0.1c-2.4-0.6-4.9-1.1-7.3-1.4c-9.7-1.5-20-1.7-30.2-1.7C181.4,419.2,179.5,418.1,177.7,416.9z M213.2,423.1
  c3.2,0.5,6.4,1.2,9.5,2c3.3,2.8,6.6,5.7,9.6,8.9c-17-1.4-32.5-4.9-47-12.6C194.8,421.4,204.3,421.7,213.2,423.1z M225.3,425.7
  c8.5,2.4,16.7,5.7,24.7,9c-5.4-0.1-10.7-0.3-15.9-0.6c-0.1-0.1-0.2-0.2-0.3-0.3C231.1,430.9,228.2,428.3,225.3,425.7z M251.7,435.9
  c-0.6,0.7-1.1,1.5-1.6,2.3c-1.2,1.9-2.6,3-4.3,3.2c-3.7,0.4-7.9-3.4-10.5-6C240.6,435.7,246.1,435.9,251.7,435.9z M255.9,435.9
  c10,0,20.1-1.6,30.2-3.7c7.5,1.6,15.4,2.8,23.7,4c1.4,0.2,2.8,0.4,4.2,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-11.5,2.5-23,4.9-34.4,6.7
  C271.1,442.1,263.4,439,255.9,435.9C255.9,435.9,255.9,435.9,255.9,435.9z M311.9,439c-0.9,0.9-1.8,1.9-2.6,2.9
  c-0.4-0.3-0.7-0.7-1-1.1c-0.2-0.3-0.6-0.3-0.8-0.1c-0.3,0.2-0.3,0.6-0.1,0.8c0.4,0.5,0.8,0.9,1.2,1.3c-0.7,0.8-1.5,1.6-2.2,2.4
  l-0.7,0.7c-0.4,0.4-0.9,0.9-1.3,1.3c-5,0.3-10,0.2-14.9-0.6c-2.6-0.4-5.1-1-7.7-1.6C291.7,443.3,301.8,441.2,311.9,439z
   M306.5,446.7l0.7-0.7c0.8-0.8,1.5-1.7,2.3-2.5c0.9,0.7,2,1.2,3,1.7c0.5,0.3,1.1,0.5,1.6,0.8c-2.6,0.5-5.2,0.8-7.9,1.1
  C306.2,446.9,306.4,446.8,306.5,446.7z M312.9,444.1c-1-0.5-1.9-0.9-2.7-1.5c1.3-1.4,2.5-2.8,3.9-4.1c1.3-0.3,2.5-0.5,3.8-0.8
  c4.4,1,8.7,2.3,13,3.7c-5,1.8-10.1,3.2-15.2,4.3C314.8,445.1,313.9,444.6,312.9,444.1z M332.6,442c1.2,0.4,2.4,0.8,3.6,1.2
  c-4.1,0.9-8.8,1.6-14.1,2.1C325.7,444.4,329.2,443.2,332.6,442z M334.4,441.3c5.2-2,10.4-4.3,15.5-6.7c1.3,0.6,2.6,1.1,4,1.6
  c-4.5,2.7-9.5,4.8-15.5,6.4C337,442.2,335.7,441.8,334.4,441.3z M355.4,436.7c3.5,1,6,0.4,9-0.3c0.8-0.2,1.6-0.4,2.5-0.6
  c4.2-0.8,9.3-0.1,13,2.8c-7.3,3.9-15,7.3-23.1,10c-5.6-1.5-11.1-3.4-16.5-5.3C346.2,441.7,351,439.5,355.4,436.7z M366.6,434.7
  c-0.9,0.2-1.8,0.4-2.6,0.6c-2.5,0.6-4.6,1.1-7.3,0.6c1.3-0.9,2.6-1.8,3.9-2.8c9.8-1,19.4-2.5,28.5-3.9c2,0.2,4,0.3,6,0.4
  c-4.6,3-9.3,5.8-14.1,8.4C377,434.6,371.2,433.7,366.6,434.7z M412.9,432.6c0.5,0.1,0.9,0.1,1.4,0.2c-3.6,1.7-7.2,3.4-10.8,5.1
  c-5.8,2.8-11.7,5.6-17.7,8.3c-0.1-0.3-0.2-0.6-0.3-0.9C395.1,441.3,404.2,437.1,412.9,432.6z M414,430.8c-0.5-0.3-1-0.6-1.6-0.9
  c0.1,0,0.2,0,0.2,0c0.7,0.2,1.3,0.4,2,0.6C414.4,430.5,414.2,430.6,414,430.8z M418.8,429.5c1.1-0.1,2.2-0.2,3.3-0.3
  c-0.9,0.4-1.9,0.8-2.8,1.3c-0.5-0.1-1-0.3-1.6-0.4C418.1,429.9,418.5,429.7,418.8,429.5z M419.4,431.7c1,0.3,2.1,0.5,3.1,0.8
  c-1.3-0.1-2.7-0.2-4-0.4C418.8,432,419.1,431.9,419.4,431.7z M428.8,434c1.2,0.3,2.4,0.5,3.6,0.7c-0.4,0.7-0.9,1.5-1.2,2.3
  c-0.6,0.4-1.3,0.8-1.9,1.1c-3.6-1.4-7.2-3-10.6-4.8C421.9,433.7,425.3,433.9,428.8,434z M434.4,433.9c0.8,0,1.5-0.1,2.2-0.2
  c-0.3,0.2-0.5,0.3-0.8,0.5C435.4,434.1,434.9,434,434.4,433.9C434.4,433.9,434.4,433.9,434.4,433.9z M435.5,432.6
  c1.7-1.9,4-3.4,7-4.6l-0.4-1.1c-3.7,1.5-6.3,3.4-8.2,5.8c-1.6,0.1-3.3,0.1-4.9,0.1c-2.6-0.6-5.2-1.2-7.8-1.9
  c1.6-0.7,3.2-1.4,4.8-2.1c9.6-1.1,18.9-3.1,27.8-6c-4.7,3.2-9.6,6.3-14.7,9.5C437.9,432.4,436.7,432.6,435.5,432.6z M457.9,421.4
  c1.3-0.4,2.5-0.9,3.8-1.4c-1.3,1.9-2.8,3.5-4.2,4.7c-4.4,3.7-9.7,5.9-15.4,7C447.6,428.3,452.8,424.9,457.9,421.4z M464.7,417.4
  c0.2,0,0.5-0.1,0.7-0.1c-0.3,0.1-0.5,0.2-0.8,0.3C464.6,417.5,464.7,417.4,464.7,417.4z M442.5,415.3c3.1-2.1,6-4.1,8.8-6.2
  c7.9-6,13.2-12.8,18.8-20.1c3-3.9,6.1-7.9,9.6-11.8c2.9-3.2,6.1-6,9.4-8.7c2.7-0.1,5.4-0.2,8.1-0.3c0.4,9.3-1.4,17.1-4.3,24.5
  c-1.7,1.5-3.4,3-5.1,4.5c-6.4,5.4-12.9,10.7-19.9,15.8c-0.4,0-0.7,0.1-1.1,0.1c1.8-4.2,2.9-8.7,2.6-12.7c0-0.3-0.3-0.6-0.6-0.5
  c-0.3,0-0.6,0.3-0.5,0.6c0.3,3.9-0.9,8.5-2.8,12.7C457.9,414,450.2,414.7,442.5,415.3z M468.4,413.9c2-0.2,3.9-0.5,5.8-0.8
  c-1.6,0.8-3.2,1.6-4.9,2.3c-1.3,0.2-2.6,0.4-3.9,0.6C466.5,415.4,467.4,414.7,468.4,413.9z M470.3,412.5
  c6.3-4.7,12.4-9.6,18.3-14.6c1.1-1,2.3-2,3.4-2.9c-1.6,3.8-3.5,7.5-5.5,11.2c-3,1.9-6,3.7-9.1,5.3
  C475,411.9,472.7,412.2,470.3,412.5z M494,393.3c4.1-3.6,8.1-7.2,12-10.9c-0.1,0.7-0.2,1.4-0.4,2.1c-0.6,3.2-1.6,6.4-2.9,9.8
  c-4.5,3.9-9.3,7.5-14.3,10.7C490.5,401.1,492.4,397.3,494,393.3z M506.7,384.7c0.2-1.2,0.4-2.4,0.6-3.6c4.4-4.2,8.8-8.4,13-12.7
  c0.9,0.1,1.9,0.1,2.8,0.2c-1.1,2.7-2.2,5.4-3.3,8.2c-4.7,5.7-9.8,11-15.3,15.9C505.5,389.9,506.3,387.2,506.7,384.7z M524.4,368.7
  c0.5,0.1,1,0.1,1.5,0.2c-1.1,1.5-2.2,3.1-3.4,4.6C523.2,371.8,523.8,370.3,524.4,368.7z M524.9,367.6c0.9-2.2,1.7-4.4,2.6-6.6
  c1.1-1.1,2.1-2.2,3.1-3.3c0.1,1.3,0.2,2.5,0.3,3.8c-1.3,2.1-2.7,4.3-4.2,6.3C526.1,367.7,525.5,367.6,524.9,367.6z M530.9,363.5
  c0,1.6,0,3.3,0,4.9c-1-0.2-2-0.3-2.9-0.4C529,366.5,529.9,365,530.9,363.5z M530.7,349c-0.9-6.4-2.2-12.8-3.6-18.9
  c1.6-5.6,4.6-6.8,8.2-8.3c0.8-0.3,1.6-0.7,2.4-1.1c0.5,1,1.1,2,1.6,3C536.7,332,533.8,340.5,530.7,349z M540.1,325.1
  c1.4,2.7,2.8,5.5,4.2,8.2c-1,3-2.2,6-3.4,8.9c-0.7,1.6-1.4,3.1-2.1,4.7c-2.1,2.4-4.2,4.7-6.3,7.1c-0.4-0.6-0.9-1.2-1.3-1.7
  c0-0.3-0.1-0.5-0.1-0.8C534.3,342.6,537.3,333.8,540.1,325.1z M533.2,354.9c1.2-1.3,2.4-2.7,3.6-4c-0.9,1.7-1.8,3.4-2.7,5.1
  C533.8,355.6,533.5,355.2,533.2,354.9z M533.5,357c-0.5,0.9-1.1,1.9-1.6,2.8c-0.1-1.1-0.2-2.1-0.3-3.2c0.3-0.3,0.5-0.5,0.8-0.8
  C532.7,356.1,533.1,356.5,533.5,357z M531.6,354.8c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.3,0-0.4
  C531.5,354.6,531.6,354.7,531.6,354.8z M532,369.7c0.8,0.1,1.7,0.3,2.7,0.6c-0.9,1.4-1.9,2.7-2.8,4.1c-0.1,0.2-0.2,0.4-0.4,0.5
  C531.7,373.2,531.9,371.5,532,369.7z M532,368.6c0.1-2.2,0.1-4.5,0-6.8c0.8-1.3,1.6-2.6,2.3-3.9c1.4,1.4,2.9,2.7,4.5,3.8
  c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.2-0.3,0.1-0.6-0.1-0.8c-1.7-1.2-3.2-2.5-4.6-3.9c1.7-3,3.3-6.1,4.8-9.3
  c2.5-2.8,4.9-5.6,7.3-8.5c1.4,3,2.6,6.1,3.7,9.1c-5.4,6.9-10.4,14-15.3,21.1C534.1,369,533,368.7,532,368.6z M547.8,338.1
  c2.2-2.6,4.3-5.2,6.4-7.9c0.1,4.3,0.3,8.6,0.6,12.9c-1.1,1.3-2.2,2.7-3.3,4.1C550.4,344.1,549.2,341.1,547.8,338.1z M554.2,328.4
  c-2.3,2.9-4.6,5.7-6.9,8.5c-0.6-1.2-1.1-2.5-1.7-3.7c5.8-17,8.1-34.5,6.8-52.6c1.8-8,3.5-16,4.9-23.9c0.2-1.2,0.4-2.4,0.6-3.6
  c1.2,4,2.4,7.9,3.4,11.9c0,0.1-0.1,0.3-0.1,0.4c-0.9,4.2-1.8,8.6-2.5,12.9C555.4,295.7,554,312.2,554.2,328.4z M565.3,242.4
  c-0.6-1.5-1.3-3.1-1.9-4.6c0.9-0.7,1.8-1.4,2.7-2.1C565.9,237.9,565.6,240.2,565.3,242.4z M562.9,236.7c-0.8-2-1.7-4-2.6-6
  c0.5-7.2,0.7-14.7,0.7-22.4c0.3,1.3,0.6,2.6,0.9,3.9c1.3,5.9,2.9,12.2,4.6,18.6c-0.1,1.1-0.1,2.2-0.2,3.4
  C565.2,234.9,564.1,235.8,562.9,236.7z M566.6,226.6c-1.3-5.1-2.6-10-3.6-14.8c-0.7-3.2-1.4-6.4-2.1-9.7c0-0.2,0-0.3,0-0.5
  c0.7,0.3,1.4,0.6,2.2,0.8C565.8,210.3,566.7,218.3,566.6,226.6z M553.1,182.5c-2.8-3.4-6-6.5-9.3-9.4c2.3-8.1,3.8-15.6,4.6-22.7
  c3.2,12.3,5.8,24.8,8.3,36.9C555.5,185.6,554.3,184,553.1,182.5z M542.8,172.2c-0.6-0.5-1.3-1.1-1.9-1.6c-0.4-4.4-0.5-8.9-0.3-13.5
  c0.2-4.3,0.7-8.6,1-12.8c0.3-3.4,0.6-6.9,0.9-10.4c0.4-0.1,0.7-0.1,1.1-0.2c1.4,4.5,2.7,9,4,13.6
  C546.9,155.5,545.2,163.7,542.8,172.2z M543.3,132.6c-0.2,0-0.4,0.1-0.6,0.1c0-0.6,0.1-1.2,0.1-1.8
  C542.9,131.5,543.1,132,543.3,132.6z M532.8,128.8c1.6-5,2.1-10.8,0.4-19.5c-0.3-1.5-0.6-3-0.9-4.4c3.7,7.4,6.8,15.1,9.5,23.1
  c-0.1,1.6-0.1,3.3-0.3,4.9C538.2,133,535.1,131.6,532.8,128.8z M541.8,124.6c-2.2-6.2-4.7-12.2-7.4-18c2.7,3.8,5.2,7.9,7.4,12.2
  c0.1,1.8,0.1,3.6,0.1,5.4C541.8,124.3,541.8,124.4,541.8,124.6z M532,101.6c0.1-0.2,0.2-0.4,0.3-0.5c1-1.7,2.2-3.3,3.3-4.8
  c0.1-0.2,0.2-0.3,0.4-0.5c4,5.7,5.3,13,5.7,20.4C538.8,110.8,535.6,106,532,101.6z M534.6,95.6c-1.1,1.5-2.3,3.1-3.3,4.9
  c-0.1-0.2-0.2-0.4-0.3-0.6c-1.3-4.5-2.7-8.6-4.4-12.3c2.9,1.7,5.6,3.8,7.6,5.9c0.4,0.4,0.7,0.8,1,1.2C535,95,534.8,95.3,534.6,95.6
  z M514.9,72.7c3.5,3.4,6.5,7.5,9,12.2c-1.1-0.5-2.3-1-3.4-1.4c-2.3-3-4.7-5.9-7.3-8.7c-1.6-1.7-3.3-3.4-5-5
  c0.8-0.4,1.6-0.9,2.4-1.5c0,0,0.1,0,0.1-0.1C512,69.7,513.5,71.2,514.9,72.7z M509.9,67.4c-0.8,0.6-1.7,1.2-2.6,1.6
  c-1.6-1.4-3.2-2.7-4.8-4c0.5-1.4,1-2.8,1.5-4.2c1.3,1.4,2.6,2.9,3.9,4.3C508.5,65.9,509.2,66.6,509.9,67.4
  C509.9,67.4,509.9,67.4,509.9,67.4z M506.1,69.5c-1.7,0.7-3.5,1.1-5.5,1.1c0.4-1.5,0.9-3,1.4-4.5C503.4,67.2,504.8,68.4,506.1,69.5
  z M499.5,70.7c-2.1-0.1-4.4-0.5-6.9-1.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-2.4-1.6-4.9-2.5-7.4c1-0.4,2-0.9,3-1.3
  c0.2-0.1,0.5-0.2,0.7-0.3c2.8,1.7,5.5,3.5,8.1,5.5C500.5,67.1,500,68.9,499.5,70.7z M488.8,60.5c-0.1-0.4-0.3-0.9-0.4-1.3
  c-0.3-0.7-0.5-1.5-0.8-2.2c1.4,0.7,2.7,1.5,4.1,2.2C490.8,59.6,489.8,60.1,488.8,60.5z M490.2,68.2c-1.2-0.8-2-1.9-2.9-3.7
  l-1.1,0.6c1.3,2.4,2.3,3.8,4.5,4.8c0.9,2.7,1.7,5.3,2.5,8c-0.6-0.2-1.3-0.4-1.9-0.6c-3.2-4.7-6.7-9-10.4-12.9
  c2.4-0.4,4.8-1.3,7.2-2.3C488.8,64.1,489.5,66.2,490.2,68.2z M489.6,76.7c-2.4-0.7-4.8-1.3-7.2-2c-6.2-4.5-12.7-8.5-19.4-11.9
  c5,1,10.1,1.9,14.9,1.8c0.6,0,1.1-0.1,1.6-0.1C483.1,68.2,486.5,72.3,489.6,76.7z M457.5,69.6c5,0.6,10.2,2.2,15.2,3.6
  c2.2,0.7,4.6,1.4,6.8,1.9c0.8,0.2,1.6,0.4,2.3,0.6c2,1.4,3.9,2.9,5.8,4.5c-3.5,1-6.4,2.9-7,6c-12.9-9.1-28.2-15.4-43.3-19.8
  c-0.3-0.3-0.6-0.6-0.8-0.9c-0.3-3.9-0.8-7.8-1.3-11.8c1.4,0.3,2.9,0.7,4.3,1.1c1.5,0.4,2.9,0.8,4.4,1.3c0.1,0.7,0.2,1.4,0.3,2
  C445.8,66.7,449.9,68.7,457.5,69.6z M424.4,52.2c1.2,0.1,2.4,0.2,3.6,0.4c-0.3,0.2-0.5,0.3-0.8,0.5c-0.4,0.3-0.9,0.5-1.4,0.7
  C425.4,53.3,424.9,52.8,424.4,52.2z M424.7,54.3c-6.5,2.6-17,2.9-22.6-0.8c-0.5-0.3-0.9-0.6-1.3-0.9c7.3-0.7,14.6-1,21.9-0.5
  C423.4,52.8,424.1,53.6,424.7,54.3z M427.9,54.1c0.7-0.4,1.3-0.8,1.9-1.3c1.4,0.2,2.8,0.4,4.2,0.7c0.4,3.5,0.8,7.1,1.2,10.6
  c-2.8-3.2-5.7-6.3-8.5-9.4C427.1,54.6,427.5,54.4,427.9,54.1z M433.9,52.4c-0.9-0.2-1.8-0.3-2.7-0.5c0.8-0.6,1.6-1.3,2.4-1.9
  C433.6,50.8,433.8,51.6,433.9,52.4z M436.5,45.8c-0.7,0-1.5,0.1-2.3,0.2c-0.1-1-0.3-2-0.4-3.1l0-0.1c1.8,0.2,3.6,0.4,5.3,0.6
  C438.2,44.1,437.4,45,436.5,45.8z M434.3,47.1c0.3,0,0.5-0.1,0.8-0.1c-0.2,0.2-0.5,0.4-0.7,0.6C434.4,47.5,434.4,47.3,434.3,47.1z
   M429.4,51.6c-2.1-0.3-4.1-0.5-6.2-0.7c-0.2-0.2-0.5-0.5-0.7-0.7c0.7-0.2,1.6-0.5,2.5-0.8c2.4-0.7,5.3-1.7,8.1-2.2
  c0.1,0.4,0.1,0.8,0.2,1.3C432.1,49.6,430.8,50.7,429.4,51.6z M424.7,48.4c-1.2,0.4-2.2,0.7-3,0.9c-1.2-1.3-2.4-2.7-3.7-4
  c1.2-0.2,2.5-0.3,3.8-0.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-1.6,0-3.2,0.2-4.7,0.5c-0.4-0.5-0.9-1-1.3-1.5l-1.1-1.2
  c6,0.2,12,0.6,17.9,1.1l0.1,0.4c0.1,1,0.3,2.1,0.4,3.1C430.2,46.6,427.2,47.6,424.7,48.4z M420.8,50C420.8,50,420.8,50.1,420.8,50
  c0.1,0.3,0.3,0.5,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-7.3-0.4-14.6-0.1-21.9,0.7c-0.1-0.1-0.1-0.1-0.2-0.2c1.9-0.7,3.7-1.4,5.5-2.1
  c3.9-1.6,7.7-3.1,11.7-3.8C418.1,47,419.4,48.5,420.8,50z M401.5,54.6c3.1,2,7.6,2.9,12.1,2.9c4.3,0,8.7-0.8,12-2.2
  c3.2,3.5,6.4,7,9.6,10.6c-12.9-3.6-25.5-5.9-36.1-7.2c-0.4-1.9-0.8-3.8-1.1-5.7c0.4,0,0.8-0.1,1.3-0.1
  C399.9,53.4,400.6,54,401.5,54.6z M396.5,51.2c-5.1,1.3-9.3,0.2-14.2-1.1l-0.6-0.2c-0.4-1.3-0.9-2.6-1.8-3.8
  c4.1-1.3,8.2-2.6,12.3-3.7c1.2,2.4,2.5,4.9,4.1,7.1C396.3,50,396.4,50.6,396.5,51.2z M391.6,41.3c-4.2,1.2-8.4,2.5-12.5,3.8
  c-0.6-0.7-1.3-1.4-2-2c0.8-0.6,1.6-1.2,2.3-1.9c3.9,0,7.7,0,11.2,0c0.3,0,0.7,0,1,0C391.6,41.2,391.6,41.3,391.6,41.3z M379.8,38.5
  c-0.1,0.5-0.5,1.1-1,1.6c-2.2,0-4.4,0-6.7,0c0-1.4,0.1-2.6,0.5-3.7c0.6-1.4,1.6-1.8,2.5-1.8c0.5,0,0.9,0.1,1.3,0.2
  C378.4,35.5,380.1,37.3,379.8,38.5z M372.2,47c0,0.1,0.1,0.3,0.1,0.4c-0.3,0.1-0.6,0.2-0.9,0.3C371.7,47.5,372,47.3,372.2,47z
   M373.1,49.8c0.7,2.1,1.5,3.9,2.1,5.4l0.1,0.2c-1.2,0.2-2.3,0.4-3.5,0.6c-1,0.2-2,0.4-3,0.5c-1.5,0-3,0.1-4.5,0.1
  c0.3-0.6,0.5-1.1,0.9-1.7c1.1-2,2.6-3.7,4.2-5.3C370.6,49.7,371.9,49.7,373.1,49.8z M360.6,58c-7.7,1.3-15.4,2.5-23.1,3
  C345.2,59.5,352.9,58.5,360.6,58z M362.3,58.9c-2.1,8.2,2.6,16.1,7.8,23.4c0.2,0.3,0.5,0.3,0.8,0.1c0.3-0.2,0.3-0.5,0.1-0.8
  c-5.4-7.6-9.9-15.1-7.5-23c1.8-0.3,3.6-0.6,5.3-1c2.5,0,4.9,0,7.4,0.1c2.1,4.8,4.8,9.4,7.4,13.8c0.6,1.1,1.3,2.2,1.9,3.3
  c2.7,4.8,5.4,9.7,7.7,14.7c-11.7-1.9-23.9-1.5-37-1.2c-2.6,0.1-5.1,0.1-7.7,0.2c-9.6,0.2-19.1-0.2-28.4-0.9
  c4.3-9.4,8.6-18.5,14.9-25.4C344.2,61.9,353.3,60.5,362.3,58.9z M335.7,60.2c-1.7,0.3-3.4,0.7-5.1,1.1c-0.6,0-1.3,0-1.9,0
  c-0.1,0-0.1,0-0.2,0c-8.3,0-17-0.7-26-1.6c0-0.1,0-0.2,0-0.3c5.2,0.7,10.5,1.2,15.8,1.2c6.4,0.1,12.7-0.6,18.9-1.7
  C336.6,59.3,336.1,59.8,335.7,60.2z M302.9,68.3c0-0.8-0.1-1.6-0.1-2.4c-0.1-1.7-0.2-3.3-0.3-5c8.1,0.8,15.9,1.5,23.4,1.6
  c-6.9,1.8-13.7,4-20.5,6.6C304.6,68.8,303.8,68.5,302.9,68.3z M303.8,69.7c-0.3,0.1-0.5,0.2-0.8,0.3c0-0.2,0-0.4,0-0.5
  C303.2,69.6,303.5,69.6,303.8,69.7z M303,71.3c0.8-0.3,1.5-0.6,2.3-0.9c1,0.5,1.9,1.1,2.4,1.9c0.8,1.2,0.8,2.7,0.1,4.5
  c-0.1,0.3,0,0.6,0.3,0.8c0.3,0.1,0.6,0,0.8-0.3c0.9-2.2,0.8-4.1-0.2-5.6c-0.5-0.7-1.2-1.3-2-1.8c7.9-3,15.9-5.4,23.9-7.3
  c1,0,1.9,0,2.9-0.1c-6.3,7.1-10.5,16.2-14.6,25.2c-6-0.5-11.9-1-17.8-1.6C303,81.3,303.2,76.3,303,71.3z M299.9,85.9
  c-2.6-0.3-5.3-0.6-7.9-0.8c-4.9-0.5-9.8-1.1-14.6-1.5c8.1-4.5,16.2-8.4,24.5-11.8C302,76.6,301.7,81.4,299.9,85.9z M301.5,53.4
  c-1.7,1.3-3.5,2.6-5.2,3.9c-5.7-0.9-11.4-2.1-17-3.2c-1.5-0.3-2.9-0.6-4.4-0.9c6.5-2.4,12.7-4.4,18.7-6c3.5-0.9,7-1.9,10.5-2.8
  C302.6,47.3,301.9,50.3,301.5,53.4z M304.9,43.1c-3.9,1-7.7,2-11.6,3c-6.6,1.8-13.4,4-20.6,6.7c-1-0.2-2-0.4-2.9-0.6
  c0.5-1.7,1.1-3.3,1.7-4.8c0.1-0.1,0-0.3,0-0.4c1-0.5,2-1.1,3-1.6c0.8-0.4,1.5-0.8,2.3-1.2c2.1,1.4,4.5,2.1,7.1,0.9
  c2.3-1.1,4-2.9,5.5-4.9c5.2,0.2,10.5,0.5,15.7,0.9c0.4,0,0.7,0.1,1.1,0.1C305.8,41.7,305.3,42.4,304.9,43.1z M259.5,51.7
  c-4.6,1.9-9.4,3.5-14.1,4.9c-0.8,0.1-1.6,0.1-2.5,0.2c2.1-2.3,4.3-4.4,6.7-6.3C252.9,50.8,256.2,51.2,259.5,51.7z M241.2,57
  c-1.2,0.1-2.3,0.3-3.5,0.5c3-2.1,5.5-4.5,7.3-7.2c1,0,1.9,0.1,2.9,0.1C245.6,52.4,243.3,54.7,241.2,57z M191.7,83.3
  c1.9-2,3.9-3.9,6-5.7c-0.1,1.2-0.1,2.4,0,3.4c-2.1,1.9-4.2,3.9-6.3,5.8C191.8,85.7,191.9,84.5,191.7,83.3z M198.8,80
  c0-0.8,0-1.8,0.1-2.7c0-0.3,0-0.5,0.1-0.8c1.4-1.2,2.7-2.3,4.2-3.5c2.7-2.1,5.5-4,8.4-5.6c-0.5,0.5-1,1.1-1.5,1.6
  C206.4,72.7,202.6,76.4,198.8,80z M202.4,72.1c-1.2,0.9-2.3,1.9-3.4,2.8c0-1.8-0.2-3.6-1.4-4.9c-0.2-0.3-0.6-0.4-1-0.5
  c1.9-2,3.8-3.8,5.8-5.4c0.5,0.1,1,0.2,1.5,0.3c2.7,0.5,5.5,1.1,8.3,1.3C208.8,67.5,205.5,69.6,202.4,72.1z M196.7,70.8
  c1.1,1.3,1.2,3.2,1.1,5.2c-2.2,1.9-4.3,4-6.4,6c-0.5-1.5-1.3-2.7-2.4-3.7c2-2.5,4.1-5,6.3-7.4c0.1-0.1,0.1-0.1,0.2-0.2
  c0.3,0,0.5-0.1,0.7-0.1C196.4,70.6,196.6,70.7,196.7,70.8z M190.5,82.9c-4.7,4.8-9,9.8-13.1,14.9c0.8-2.1,1.6-4.3,2.4-6.4
  c2.7-4.2,5.5-8.3,8.6-12.2C189.4,80.1,190.1,81.4,190.5,82.9z M174.1,103.6C174.1,103.6,174.1,103.6,174.1,103.6
  c-1.9,5.9-3.5,11.8-4.7,17.7c-2,2.1-3.9,4.5-5.5,7.1c-1.8,3-3.2,6.1-4.3,9.3c-0.6-1.6-1.1-3.3-1.6-5c3.7-7.7,7.4-15.5,11.3-23.1
  c0.5-0.7,1.1-1.4,1.6-2C171.9,106.4,173,105,174.1,103.6z M172.7,103.3c0.3-0.6,0.6-1.1,0.9-1.7c0.2,0,0.4,0,0.6,0
  c-0.3,0.4-0.7,0.8-1,1.2C173.1,103,172.9,103.2,172.7,103.3z M153.6,118.8c-0.5-1.7-0.9-3.4-1.4-5.2c0.8-0.4,1.7-0.9,2.6-1.3
  c0.1,0,0.1-0.1,0.2-0.1C154.6,114.4,154.1,116.6,153.6,118.8z M152.9,120.5c-2.6,2.3-5.1,4.6-7.7,6.9c0.3-4.2,0.7-8.1,3.4-11.2
  c0.7-0.8,1.6-1.5,2.5-2C151.8,116.3,152.3,118.4,152.9,120.5z M152.9,122.1c-0.6,2.4-1.1,4.8-1.7,7.1c-2.4,2.5-4.8,4.9-7.4,7.3
  c0.8-2.3,1.1-4.9,1.3-7.4C147.7,126.7,150.3,124.4,152.9,122.1z M142.7,136.4c0-1.6-0.1-3.2-0.3-4.7c0.5-0.5,1-0.9,1.5-1.4
  C143.7,132.4,143.4,134.5,142.7,136.4z M142.6,139.2c2.8-2.5,5.5-5,8-7.7c-0.4,1.7-0.8,3.5-1.3,5.2c-1.1,4.6-2.2,9.3-3.3,14
  c-1.2,0.6-2.4,1.1-3.6,1.7c-1.1,0.6-2.6,1.2-4.1,1.8c-2.6,1.1-5.5,2.4-8.3,3.9c-0.2-2.4-0.4-4.9-0.6-7.3c0.9-0.2,1.7-0.4,2.5-0.6
  c4.4-1,8.3-1.9,10-9.2c0.1-0.4,0.2-0.8,0.2-1.2C142.4,139.5,142.5,139.4,142.6,139.2z M129.4,149.5c0-0.7-0.1-1.4-0.1-2.1
  c0.9-0.2,1.7-0.4,2.5-0.7c0,0,0,0.1,0,0.1c0.2,0.3,0.5,0.3,0.8,0.1c0.5-0.3,0.9-0.7,1.4-1c2.4-0.9,4.6-2.1,6.4-4
  c-1.7,5.4-4.7,6.1-8.8,7C131,149.1,130.2,149.3,129.4,149.5z M141.3,138.8c-2.5,2.1-5,4.2-7.7,6.1c-1.3,0.5-2.8,0.9-4.3,1.3
  c0-0.6,0-1.2,0-1.7c3.9-4,7.9-8,12.1-11.8C141.6,134.7,141.6,136.8,141.3,138.8z M130.1,166.9c-3.9,2-7.7,4.2-11.3,6.8
  c0.2-1,0.4-1.9,0.5-2.8c0.4-1.2,0.7-2.3,1.1-3.5c1.6-2.9,5-5.4,8.8-7.5C129.4,162.2,129.7,164.5,130.1,166.9z M123.6,153.3
  c-1.5,4.4-2.9,8.8-4.2,13.2c0-0.8-0.1-1.5-0.1-2.2C119,159.8,118.8,156.5,123.6,153.3z M124.2,151.6c-6.7,3.8-6.4,7.9-6.1,12.7
  c0.1,1.7,0.2,3.4,0.1,5.3c-0.3,1.1-0.5,2.1-0.7,2.9c-0.3,0.9-0.5,1.8-0.8,2.7c-1.7,1.3-3.3,2.7-4.8,4.2c-0.4,0.1-0.8,0.3-1.3,0.4
  c-0.3,0.1-0.5,0.4-0.4,0.7c0,0.1,0.1,0.3,0.3,0.3c-0.5,0.5-1,1.1-1.5,1.7c-0.2-2.2-0.4-4.4-0.4-6.6c2-4.2,3.9-8.4,5.9-12.7
  c0.6-1.4,1.2-2.8,1.9-4.3c2.9-3.5,5.9-6.8,9-10.1C124.9,149.7,124.6,150.6,124.2,151.6z M113.6,162.1c-0.1,0.2-0.2,0.4-0.3,0.6
  c-1.6,3.5-3.2,7-4.8,10.5c0-1.6,0.1-3.1,0.2-4.7C110.3,166.3,112,164.2,113.6,162.1z M108.7,190.3c-0.3,0.2-0.6,0.5-0.8,0.7
  c-1.6,1.2-3.4,2.2-5.3,3c1.7-3.2,3.5-6.1,5.6-8.7C108.3,187,108.5,188.7,108.7,190.3z M100.9,194.8c-0.6,0.2-1.2,0.5-1.7,0.7
  c2.8-5.8,5.6-11.6,8.4-17.4c0.1,1.9,0.2,3.7,0.4,5.6C105.3,187,102.9,190.6,100.9,194.8z M107.4,175.6c-3.2,6.9-6.5,13.7-9.9,20.6
  c-2.3,0.9-4.5,1.9-6.6,3.1c4.4-10.3,10-19.9,16.5-29C107.4,172,107.4,173.8,107.4,175.6z M96.8,197.7c-2,4.1-4,8.2-6.1,12.2
  c-0.3-0.9-0.7-1.8-1.2-2.8l-0.3-0.7c-0.2-0.4-0.4-0.9-0.5-1.3c0.5-1.3,1-2.7,1.5-4c0,0,0,0,0.1,0C92.2,199.7,94.4,198.7,96.8,197.7
  z M88.1,206.9l0.3,0.7c0.6,1.4,1.2,2.6,1.5,3.9c-2.4,4.8-4.8,9.5-7.2,14.3c0.7-3.6,1.6-7.2,2.6-10.6c0.8-2.9,1.7-5.7,2.7-8.4
  C88.1,206.8,88.1,206.8,88.1,206.9z M87.7,223.1c-0.7,1.4-1.6,2.6-2.6,3.5l-0.1,0.2c-0.3,0.7-0.7,1.4-1.1,2
  c-0.8,1.5-1.6,2.8-1.9,4.8c-0.2,0.1-0.5,0.2-0.7,0.3c0.2-1.3,0.4-2.6,0.6-3.9C83.9,227.6,85.8,225.3,87.7,223.1z M81.5,235.3
  c0.1-0.1,0.3-0.2,0.4-0.3c0,0.8,0.2,1.6,0.3,2.5c0.3,1.3,0.6,2.7,0.2,3.8l1.1,0.4c0.5-1.5,0.2-3.1-0.1-4.5
  c-0.2-0.9-0.4-1.7-0.3-2.4c0-0.1,0-0.2,0-0.3c0.8-0.2,1.7-0.1,2.6-0.1c1.2,0,2.4,0.1,3.5-0.4c-1.7,5.7-3.9,11.3-6.9,16.6
  c-0.9,1.7-1.9,3.3-3,4.9c0.2-6.9,0.8-13.6,1.8-20.1C81.3,235.4,81.4,235.4,81.5,235.3z M78.1,257.4c-2.4,3.5-5,6.9-7.7,10.2
  c0.9-3.6,1.5-7.3,2.2-11c0.8-5,1.7-9.8,3.1-14.4c0.9-1.8,1.9-3.7,2.8-5.5c0.6-1.1,1.1-2.2,1.7-3.3C79,241.1,78.4,249.2,78.1,257.4z
   M71.5,256.4c-0.8,4.4-1.5,9-2.8,13.4c-1,1.3-2.1,2.5-3.1,3.8c-0.8,1-1.6,1.9-2.4,2.9c0.6-4,1.1-8.2,1.6-12.4
  c2.8-5.6,5.6-11.3,8.4-16.8C72.5,250.3,72,253.4,71.5,256.4z M61.7,278.3c-0.6,0.7-1.1,1.4-1.7,2.1c-0.3-1.7-0.6-3.3-0.9-5
  c1.4-2.8,2.7-5.5,4.1-8.3C62.8,271,62.3,274.7,61.7,278.3z M59.3,283.2c0.1,0.3,0.1,0.6,0.2,0.9l0.2,1.4c-0.2,0.2-0.3,0.3-0.5,0.5
  c-1.7,1.6-3.4,3.2-4.8,5.6c-0.2-0.3-0.4-0.5-0.6-0.8C55.5,288.2,57.4,285.7,59.3,283.2z M54.6,293.8c1,1.2,1.9,2.5,2.9,3.7
  c-0.3,1.3-0.7,2.5-1,3.8c-1.2,4.4-2.3,8.7-3.3,13c0-0.8-0.1-1.6-0.1-2.4c-0.4-5.9-0.7-11.9,1.1-17
  C54.3,294.5,54.4,294.1,54.6,293.8z M57.6,301.6c0.3-1,0.5-1.9,0.8-2.9c1.6,2.1,3.1,4.3,4.6,6.5c0.1,0.5,0.1,0.9,0.2,1.4
  c-3.5,12.6-4.6,25.8-2.4,40.4c0,0,0,0.1,0,0.1c-1,2.5-1.9,5.1-2.8,7.7c-1-0.8-2-1.7-2.9-2.6c-2.2-6.8-3.1-13.1-3.1-19.1
  c0.3-0.1,0.5-0.3,0.8-0.4c3-1.9,4.5-5,4.2-8.5c-0.2-2.7-1.5-4.8-3.3-6.5C54.7,312.4,56.1,307.1,57.6,301.6z M57.6,356
  c-0.2,0.5-0.3,1.1-0.5,1.6c-0.3-0.7-0.5-1.3-0.8-2c-0.1-0.3-0.2-0.7-0.4-1C56.5,355.1,57,355.6,57.6,356z M49.7,334
  c0.4-0.1,0.7-0.2,1.1-0.4c0.1,5.4,0.8,11,2.5,16.9c-2.7-2.6-5.1-5.4-7.3-8.3c0.7-1.7,1.5-3.5,2.3-5.3C48.8,336,49.3,335,49.7,334z
   M53.4,319.1c1.3,1.4,2.2,3.1,2.4,5.2c0.2,3-1.1,5.7-3.7,7.4c0,0-0.1,0-0.1,0c0-1.3,0.1-2.7,0.2-4c0.3-1,0.5-1.9,0.7-2.8
  c0.3-1.8,0.4-3.6,0.4-5.5C53.3,319.3,53.4,319.2,53.4,319.1z M50.9,332.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.2-0.4,0.3-0.7,0.5-1.1
  C50.8,331.7,50.9,332,50.9,332.3z M41.4,333.3c2.1,1.1,4.6,1.5,6.9,1.1c-0.3,0.7-0.7,1.4-1,2.1c-0.7,1.5-1.4,3.1-2.1,4.7
  c-2-2.7-3.7-5.6-5.2-8.7C40.5,332.7,40.9,333,41.4,333.3z M38.1,331.2c1.8,3.9,4,7.7,6.6,11.3c-1.3,3.2-2.2,6.3-2.1,9
  c0.2,5.1,1.9,8.7,4.5,11.3c-0.4,5.2-1.8,10.4-4.5,13.9C37.4,361.8,35.9,346.3,38.1,331.2z M48.3,356.5l-1.2,0.1
  c0.1,1.5,0.1,3.1,0.1,4.7c-2-2.4-3.3-5.5-3.4-9.8c-0.1-2.3,0.6-5,1.7-7.9c2.5,3.3,5.4,6.4,8.7,9.4c0.3,1,0.7,2,1.1,3
  c0.4,1.1,0.9,2.3,1.3,3.4c-0.6,2.2-1.2,4.4-1.7,6.7c-2.5-0.8-4.7-2-6.5-3.7C48.5,360.2,48.5,358.1,48.3,356.5z M57.3,361.2
  c0.9,2.1,1.7,4.1,2.6,6.1c-1.4-0.2-2.7-0.5-3.9-0.9C56.3,364.7,56.8,362.9,57.3,361.2z M57.8,359.4c0.3-0.8,0.5-1.7,0.8-2.5
  c1.4,1.1,2.8,2.2,4.3,3.3c0.1,0,0.1,0.1,0.2,0.1c0.5,2.5,1,5,1.6,7.5c-1.2-0.1-2.3-0.2-3.4-0.3C60.1,364.9,58.9,362.2,57.8,359.4z
   M64.9,369c1.1,4.4,2.3,8.7,3.7,12.8c-2.4-4-4.7-8.3-6.9-13.1C62.8,368.8,63.9,368.9,64.9,369z M66.1,369c1.6,0.1,3.2,0.1,4.8,0.1
  c1.6,0,3.2,0,4.7-0.1c5,15,13.2,27.6,23.3,36.2c-1.4,0.5-2.8,1.1-4.1,1.7c-0.2-0.1-0.4-0.1-0.7-0.2c-7.8-2.8-15.4-9.6-22.4-20.1
  C69.5,381.1,67.6,375.2,66.1,369z M91.6,409.7l1.3-0.6c0.6-0.3,1.3-0.6,2-0.9c4.1,1.4,8.3,2.4,12.6,3.2c1.7,1,3.4,1.8,5.2,2.6
  c-8.4-0.1-16.5-1.1-24.1-2.8C89.7,410.6,90.7,410.1,91.6,409.7z M109.6,404.3c1.5,0.1,3,0.3,4.4,0.9c3,1.3,5.4,4.1,7.2,7
  c-2.7-0.4-5.5-0.7-8.1-1.1C111.8,408.9,110.7,406.6,109.6,404.3z M121.5,413.4c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.2,0.2,0.3
  c-1.9,0.1-3.7,0.2-5.6,0.2c-0.2,0-0.5,0-0.7,0c-0.4-0.2-0.9-0.3-1.3-0.5c-0.2-0.4-0.5-0.7-0.7-1.1
  C116.4,412.8,119,413.1,121.5,413.4z M115.8,415.1c2.8,1,5.7,1.9,8.6,2.6c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.2,1.2,2.2,1.9,3.1
  c-2.2-0.3-4.4-0.6-6.7-0.9C118.3,418.7,117,416.9,115.8,415.1C115.7,415.1,115.7,415.1,115.8,415.1z M125.9,418.2
  c6.6,1.5,13.4,2.4,20.4,2.9c1.1,0.9,2.3,1.8,3.4,2.7c-7-0.6-14.3-1.4-21.5-2.2C127.4,420.6,126.6,419.5,125.9,418.2z M149.3,431.3
  c0.5-0.5,1.1-1,1.9-1.3c2.2-0.9,4.4-0.7,6.8-0.5c2.6,1.7,5.3,3.2,7.9,4.7c-5.4-0.7-10.8-1.4-16.2-2.3
  C149.7,431.8,149.5,431.5,149.3,431.3z M160.6,429.8c1.5,0.1,3,0,4.4-0.4c0.7-0.2,1.4-0.5,2-0.8c2.4,2.1,4.6,4.3,6.7,6.6
  c-1.5-0.2-3-0.4-4.5-0.5C166.3,433.2,163.4,431.5,160.6,429.8z M175.3,435.1c-2.2-2.5-4.7-4.9-7.3-7.2c0.3-0.2,0.6-0.5,0.8-0.7
  c2.6,1.1,5.2,2.2,7.7,3.2c0.4,0.6,0.9,1.3,1.4,1.9c-0.9,0.9-1.7,1.9-2.5,3C175.3,435.2,175.3,435.2,175.3,435.1z M178.5,431.2
  c0.1,0,0.2,0.1,0.3,0.1c0,0-0.1,0.1-0.1,0.1C178.6,431.4,178.6,431.3,178.5,431.2z M178.6,433.3c0.7,1,1.5,1.9,2.3,2.8
  c-0.6-0.1-1.2-0.1-1.8-0.2c-0.8-0.1-1.6-0.2-2.4-0.3C177.3,434.8,178,434,178.6,433.3z M179.4,432.5c0.2-0.2,0.4-0.4,0.6-0.6
  c5.8,2.3,11.6,4.4,17.5,6.3c-5-0.7-9.9-1.3-14.9-1.9C181.6,435.1,180.5,433.8,179.4,432.5z M177.3,429.5c-0.4-0.6-0.9-1.3-1.3-2
  c5.1,0.7,9.6,1.4,13.5,2.4c12,3,24,6.6,35.5,10c8.9,2.7,17.8,5.3,26.5,7.7c-1.5,0.1-3,0.2-4.5,0.3c-8.2-2.1-16-4-23.7-5.6
  c-5.9-1.3-12.1-2.3-18.3-3.2C195.4,436.5,186.5,433.3,177.3,429.5z M225.3,438.8c-11.6-3.5-23.6-7.1-35.6-10.1
  c-4.1-1-9-1.8-14.5-2.5c-0.9-1.5-1.8-3-2.6-4.6c0-0.1,0.1-0.1,0.1-0.2c0.5,0,1,0,1.6,0c2.9,0,5.8-0.1,8.7-0.1
  c15.6,8.7,32.3,12.4,50.7,13.8c3.2,3.3,7.6,7.3,11.7,7.3c0.2,0,0.4,0,0.6,0c2.1-0.2,3.8-1.5,5.2-3.7c0.6-1,1.3-1.9,2-2.7
  c7.5,3.1,15.2,6.3,23.1,8.6c-7.2,1.1-14.3,2-21.3,2.7C245.1,444.8,235.3,441.9,225.3,438.8z M278.9,445.4c3.4,0.9,6.8,1.7,10.3,2.3
  c3.5,0.6,7,0.8,10.5,0.8c0.4,0,0.7,0,1.1,0c-2.5,0.5-5.1,1.1-7.7,1.7c-4.3,1-8.6,1.9-12.9,2.8c-7.3-1.3-14.7-2.9-22-4.8
  C265.1,447.5,272,446.6,278.9,445.4z M293.3,451.4c3-0.7,6-1.3,8.9-2c-2,2-3.9,4-6,5.8c-4.3-0.4-8.6-0.9-12.9-1.6
  C286.7,452.8,290,452.1,293.3,451.4z M304.3,449c3.9-0.8,7.7-1.5,11.3-1.9c0.5,0.4,0.9,0.8,1.2,1.2c1.6,2.2,1.9,4.7,2.2,7.1
  c0,0.1,0,0.2,0,0.3c-2.6,0.1-5.2,0.2-7.8,0.2c-4.5,0-9-0.2-13.4-0.6C300.1,453.3,302.2,451.2,304.3,449z M356.8,449.8
  c2.5,0.7,5,1.3,7.6,1.8c-3,0.9-5.9,1.8-8.6,2.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.2-1.5-2.4-2.8-3.2
  C354,450.8,355.4,450.3,356.8,449.8z M358.8,449.1c7.7-2.7,15-5.9,22-9.7c1,0.9,1.8,2,2.4,3.4c0.3,0.6,0.6,1.2,0.8,1.9
  c-2.9,1.2-5.9,2.4-8.9,3.6c-2.6,1-5.4,1.8-8.2,2.6C364.2,450.4,361.5,449.8,358.8,449.1z M375.6,449.4c3-1.2,6-2.4,8.9-3.6
  c0.1,0.3,0.2,0.6,0.3,0.9c-3.9,1.8-7.9,3.4-11.9,5c-1.1-0.1-2.1-0.2-3.2-0.4C371.7,450.7,373.7,450.1,375.6,449.4z M385.1,447.8
  c0.4,1.3,0.7,2.6,1,3.9c-1.3,0.1-2.5,0.2-3.8,0.3c-2.3,0.1-4.6,0.1-6.8,0C378.7,450.6,381.9,449.2,385.1,447.8z M424,442.6
  c0.2,0,0.3,0,0.5,0c1.5,0,3.1-0.1,4.7-0.4c-0.1,0.3-0.2,0.6-0.3,0.9c-3.7,1.1-7.5,2.2-11.4,3.2C419.6,445,421.8,443.8,424,442.6z
   M426.1,441.3c1.1-0.6,2.2-1.3,3.3-1.9c0.2,0.1,0.4,0.2,0.7,0.2c-0.2,0.4-0.3,0.8-0.5,1.2C428.4,441.1,427.2,441.3,426.1,441.3z
   M431.2,440.1c0.3,0.1,0.6,0.2,0.9,0.3c-0.4,0.1-0.7,0.2-1.1,0.2C431,440.4,431.1,440.2,431.2,440.1z M431.6,439
  c0.2-0.4,0.3-0.8,0.5-1.2c1.3-0.8,2.7-1.6,4-2.4c2.5,0.4,4.9,0.7,7.4,1c-3,1.2-6.2,2.5-9.4,3.4C433.3,439.5,432.4,439.3,431.6,439z
   M473.2,415c1.5-0.7,3-1.5,4.5-2.3c1.9-0.3,3.8-0.6,5.7-0.9c-0.4,0.7-0.7,1.4-1.1,2C479.3,414.1,476.3,414.5,473.2,415z M480.8,411
  c1.5-0.9,3.1-1.8,4.6-2.7c-0.4,0.7-0.8,1.4-1.2,2.1C483.1,410.6,481.9,410.8,480.8,411z M485.7,410.2c0.6-1,1.1-2.1,1.7-3.1
  c5-3.2,9.8-6.7,14.4-10.5c-1.7,3.9-3.7,7.8-6.1,11.6C492.4,408.9,489.1,409.6,485.7,410.2z M503.7,394.9
  c5.2-4.5,10.1-9.4,14.7-14.6c-2.9,6.9-5.9,13.7-9.1,20.6c-0.6,1.4-1.2,2.7-1.8,4c-3.3,1-6.7,2-10.2,2.8
  C499.9,403.5,502,399.2,503.7,394.9z M508.3,405.9c0.2-0.1,0.5-0.2,0.7-0.2c-0.4,0.5-0.8,1-1.3,1.5
  C507.9,406.8,508.1,406.3,508.3,405.9z M509,404.5c0.5-1,0.9-2,1.4-3.1c3.7-8,7.2-16,10.5-24.1c2.2-2.7,4.3-5.5,6.3-8.3
  c1.2,0.2,2.4,0.3,3.6,0.5c-0.1,2.6-0.4,5.2-0.8,7.6c-6.2,9-12.5,18.2-19.6,26.9C509.9,404.2,509.4,404.3,509,404.5z M538.7,391.1
  c0.8,1.2,1.4,2.4,1.5,3.8c0,0.2,0,0.4,0,0.6c-2,1.3-4,2.5-6,3.7c-1.5-0.9-3-1.7-4.7-2.2C532.8,395.2,535.8,393.2,538.7,391.1z
   M534.9,400.9c0.7,0.5,1.5,0.9,2.2,1.3c-3,2.3-7.7,3.2-11.9,4.1c-1.1,0.2-2.2,0.4-3.1,0.7c4.1-2,8.2-4.2,12.1-6.5
  C534.5,400.7,534.7,400.8,534.9,400.9z M535.5,400c0,0-0.1-0.1-0.1-0.1c1.7-1,3.3-2,4.9-3c-0.2,1.9-1,3.4-2.2,4.6
  C537.2,401,536.4,400.5,535.5,400z M541.4,394.7c-0.2-1.6-0.9-3-1.8-4.3c6.1-4.6,11.2-10,15.5-16.1c0.5,0.1,0.9,0.1,1.4,0.1
  c0.1,3,0,6-0.3,9.1C551.6,387.5,546.6,391.3,541.4,394.7z M552,348.4c1-1.2,2-2.5,3-3.7c0.7,7.5,1.7,15,3.1,22.4
  c-0.3,0.6-0.6,1.2-1,1.7C556.3,362,554.5,355.2,552,348.4z M558.5,368.9c0.1,0.7,0.3,1.3,0.4,2l0.4,2c0,0.2,0.1,0.4,0.1,0.6
  c-0.6,0-1.2-0.1-1.9-0.1c0-0.8-0.1-1.7-0.2-2.5C557.7,370.2,558.1,369.5,558.5,368.9z M557.6,374.5c0.7,0,1.4,0.1,2,0.1
  c0.3,1.6,0.7,3.1,0.9,4.6c-1,1-2.1,2.1-3.2,3.1C557.6,379.7,557.7,377.1,557.6,374.5z M567.9,306.3c-1.4,3.4-3.2,6.9-5.3,10.4
  c-1-2.7-1.5-5.1-0.9-6.8c0.5-1.6,3.1-2.6,5.4-3.5c0.3-0.1,0.6-0.2,0.8-0.3C567.8,306.1,567.9,306.2,567.9,306.3z M573,293.7
  c1.3,1,3,2.1,4.8,3.2c0.9,0.6,1.9,1.2,2.8,1.8c-1.8,1-3.8,1.9-5.8,2.8c-1.5,0.7-3.1,1.4-4.6,2.2C571.4,300.3,572.3,296.9,573,293.7
  z M565.2,203c4.8,0.7,8.3,3.1,10,6.8c2.2,4.6,1.3,11.1-2.1,16.7c-1.4,2.2-3.1,4.1-4.9,5.9c-0.2-0.7-0.4-1.4-0.6-2.1
  c0.4-9.3-0.3-18.6-3.3-27.5C564.6,202.8,564.9,202.9,565.2,203z M562.6,201.1c-0.6-0.2-1.2-0.5-1.8-0.8c0-1.2-0.1-2.5-0.1-3.7
  C561.4,198,562,199.5,562.6,201.1z M559.5,195.3c-0.1-0.6-0.3-1.2-0.4-1.9c0.1,0.2,0.2,0.4,0.3,0.6
  C559.4,194.4,559.4,194.8,559.5,195.3z M549,136.3c5.7,16.9,8.9,36,10.2,55c-0.3-0.5-0.6-1-0.9-1.5c0-0.2-0.1-0.3-0.1-0.5
  c-2.8-13.8-5.8-28.2-9.6-42.4c0.2-2.8,0.4-5.6,0.4-8.4C549.1,137.8,549,137.1,549,136.3z M547.8,132.9c0.2,3.7,0.2,7.3,0,11
  c-1-3.5-2-7-3.1-10.4c1-0.3,1.9-0.8,2.8-1.4C547.6,132.3,547.7,132.6,547.8,132.9z M547.5,128.9c-1.4-3.6-2.9-7-4.6-10.3
  c-0.3-8.5-1.5-17.2-6.3-23.8c0.2-0.2,0.3-0.4,0.5-0.6C543,106.1,546.3,117.5,547.5,128.9z M524.5,53c1.9-0.3,4,0.5,6.4,2.4
  c8.7,7,11.6,21.8,8.8,31.2c-0.6,1.9-1.4,3.6-2.5,5.2c-2-3.9-4.2-7.8-6.8-11.7c-1.4-2.1-2.6-4.6-3.9-7.2c-2.4-4.8-5-10.1-9-13.7
  C519.6,56.2,521.8,53.5,524.5,53z M511.3,56.7c1.8,0.6,3.4,1.5,4.8,2.6c-0.3,0.4-0.5,0.8-0.8,1.2c-1.4,2.2-2.9,4.5-4.5,6
  c-0.7-0.7-1.3-1.5-2-2.2c-1.5-1.6-2.9-3.2-4.4-4.8c0.4-1,0.8-2.1,1.2-3.1c0.1-0.2,0-0.3,0-0.5C507.4,55.9,509.3,56.1,511.3,56.7z
   M504.5,56.1c-0.3,0.9-0.7,1.7-1,2.6c-0.7-0.7-1.3-1.4-2-2C502.5,56.4,503.5,56.2,504.5,56.1z M503,59.9c-0.5,1.5-1,2.9-1.5,4.4
  c-2.3-1.8-4.8-3.4-7.2-4.9c2-0.9,4-1.7,5.9-2.3C501.1,57.9,502.1,58.9,503,59.9z M486.8,48.2c4.9,1.9,8.8,4.7,12.4,7.9
  c-2.1,0.7-4.1,1.6-6.2,2.5c-1.9-1.1-3.9-2.2-5.9-3.2c-0.7-2.1-1.4-4.2-2.1-6.4c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7
  c0.6,1.8,1.2,3.6,1.8,5.3c-5.3-2.6-10.9-4.7-16.6-6.5C475.1,46.8,481,45.9,486.8,48.2z M466.7,48.7c6.8,2,13.3,4.4,19.5,7.5
  c0.4,1.1,0.8,2.2,1.1,3.3c0.2,0.5,0.3,0.9,0.5,1.4c-2.6,1.1-5.2,2-7.8,2.4c-5.2-5.3-10.9-9.8-16.9-13.6c0.7-0.2,1.4-0.4,2.2-0.5
  C465.7,49,466.2,48.9,466.7,48.7z M464.5,48.1c-0.9,0.2-1.9,0.5-2.8,0.7c-1.5-0.9-3-1.7-4.5-2.5C459.6,46.8,462,47.4,464.5,48.1z
   M459.9,49.2c-3.2,0.7-6.5,1.2-9.7,1.2c0,0,0,0,0,0c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6c3.7,0.1,7.5-0.6,11.2-1.5
  c6.1,3.7,11.8,8.2,17,13.4c-0.2,0-0.4,0-0.6,0c-5.1,0.1-10.6-1-15.9-2c-1-0.2-2-0.4-3.1-0.6c-4.6-2.1-9.3-4-13.9-5.5
  c-0.4-3.2-0.8-6.8-3.6-8.5c-0.9-0.6-2-0.9-3.2-1c0.6-0.6,1.3-1.2,1.9-1.8c0.2-0.2,0.4-0.4,0.6-0.5c4,0.5,8,1.1,11.9,1.9
  C455.1,46.5,457.5,47.8,459.9,49.2z M447.8,43.4c-2-0.3-4-0.6-6-0.9c0.3-0.3,0.6-0.6,1-0.9C444.4,42.1,446.1,42.7,447.8,43.4z
   M439.4,40.6c0.7,0.2,1.3,0.4,2,0.6c-0.4,0.4-0.8,0.7-1.2,1.1c-2.2-0.3-4.4-0.5-6.6-0.7l-0.2-1.5c0-0.3-0.3-0.5-0.7-0.5
  c-0.3,0-0.5,0.3-0.5,0.7l0.2,1.3c-6.2-0.6-12.5-0.9-18.8-1.1l-1.6-1.8C420.9,37.7,430,38.1,439.4,40.6z M410.5,38.7l1.4,1.6
  c-3.3-0.1-6.7-0.2-10-0.2C404.8,39.5,407.6,39,410.5,38.7z M396.8,41.2c5.4,0,10.8,0.1,16.2,0.3l1.9,2.1c0.3,0.3,0.6,0.6,0.9,1
  c-3.9,0.9-7.6,2.3-11.2,3.8c-1.9,0.8-3.9,1.6-6,2.3c-0.4-0.5-0.9-1-1.3-1.6c-0.3-2.1-0.5-4.2-0.7-6.3c0-0.3-0.3-0.6-0.6-0.5
  c-0.3,0-0.6,0.3-0.5,0.6c0.1,1.3,0.2,2.7,0.4,4c-0.9-1.5-1.7-3.1-2.5-4.7C394.5,41.8,395.7,41.5,396.8,41.2z M376.4,27.6
  c5,0.3,9.2,3.2,12.4,8.4c0.8,1.3,1.5,2.7,2.2,4c-0.2,0-0.3,0-0.5,0c-3.2,0-6.6,0-10.2,0c0.3-0.4,0.5-0.9,0.6-1.3
  c0.5-2-1.8-4.3-4.2-5c-2.4-0.7-4.3,0.1-5.2,2.3c-0.4,1.1-0.6,2.3-0.6,3.6c-4.1-1.7-8.8-2.8-12.8-3.3
  C363.6,30.5,370.4,27.2,376.4,27.6z M357.3,37.3c3.7,0.4,8.1,1.3,12,2.8c-4.7,0-9.6,0.1-14.6,0.1c0.7-0.7,1.4-1.5,2.1-2.3
  C356.9,37.8,357.1,37.6,357.3,37.3z M336.2,39.1c0.1-0.4,0.2-0.7,0.2-1.1c6-0.7,11.8-1.1,17.5-0.9c0.6,0,1.2,0,1.8,0.1v0
  c-0.9,1.1-1.9,2.1-2.9,3.1c-5.6,0.1-11.3,0.2-17,0.3C336,40.1,336.1,39.6,336.2,39.1z M351.6,41.5c-1.9,1.7-4,3.1-6.1,4.4
  c-3.2,0-6.2-0.4-8.5-2.3c-0.7-0.6-1-1.1-1.1-1.8C341.2,41.6,346.5,41.5,351.6,41.5z M336.2,44.4c2.1,1.8,4.7,2.4,7.4,2.5
  c-3.7,2-7.6,3.7-11.6,5.5l-0.5,0.2l0.5,1.1l0.5-0.2c4.5-2,9.1-4,13.4-6.5c1.1,0,2.2-0.1,3.3-0.1c2.3-0.1,4.4-0.2,6.4,0.1
  c0.3,0,0.6-0.2,0.7-0.5c0-0.3-0.2-0.6-0.5-0.7c-2-0.3-4.2-0.2-6.6-0.1c-0.4,0-0.9,0-1.3,0.1c2-1.3,3.9-2.7,5.6-4.4
  c6.1-0.1,12-0.1,17.6-0.2c0.1,1.5,0.4,3,0.8,4.5c-1,0.9-2,1.8-3,2.8c-2.3,0-4.5,0.2-6.9,0.4c-2.2,0.2-4.5,0.5-6.7,0.9
  c-6.7,1.3-12.1,4-16.5,7.6c-6.8,1.4-13.6,2.2-20.6,2.1c-5.3-0.1-10.6-0.5-15.8-1.2c0-1.4,0.1-2.8,0.2-4.2c2.3-1.7,4.5-3.3,6.8-4.9
  c1.5-1,2.9-2.1,4.4-3.2c1.9-1.4,3.8-2.7,5.7-4c2.5,0.1,5,0.1,7.3,0.1c2.6-0.1,5.3-0.1,7.9-0.2C334.8,42.7,335.2,43.6,336.2,44.4z
   M321.2,40.8c0.2-0.1,0.4-0.2,0.6-0.4c4.5-0.9,9-1.7,13.5-2.2c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.6-0.3,1.2-0.4,1.8
  c-2.6,0.1-5.3,0.1-7.9,0.2C325,40.8,323.1,40.8,321.2,40.8z M334.9,34.6c0.4,0.7,0.5,1.5,0.5,2.4c-3.6,0.4-7.1,1-10.7,1.7
  c1.2-0.7,2.4-1.3,3.6-1.8C330.5,35.9,332.7,35.2,334.9,34.6z M328.5,31.6c2.5,0.1,4.5,0.9,5.7,2.1c-2.1,0.6-4.3,1.2-6.3,2.1
  c-2.3,1-4.4,2.2-6.5,3.5c-2,0.4-3.9,0.8-5.9,1.2c-2.3-0.1-4.6-0.3-6.9-0.4c1.4-1.6,3.1-3.1,4.8-4.3c1.1-0.8,2.3-1.4,3.5-2
  c3.5,1.2,6.7,1.3,9.1-1.4c0.2-0.3,0.4-0.6,0.6-0.9C327.2,31.5,327.8,31.5,328.5,31.6z M318.6,33.1c2.1-0.8,4.2-1.3,6.4-1.4
  C323.4,33.5,321.2,33.7,318.6,33.1z M309.8,28c4.9-3.5,9.6-6.9,13.6-4.4c1.3,0.8,2.3,2.5,2.6,4.3c0.1,0.9,0,1.7-0.2,2.5
  c-3.1,0.2-6,0.9-8.8,2.1c-1.8-0.7-3.8-1.6-5.7-2.5c-0.8-0.4-1.7-0.8-2.5-1.2C309.1,28.5,309.4,28.3,309.8,28z M291.8,36.6
  c2.5-3.9,4.8-7.6,9.3-8.5c1.4-0.3,3.2,0.2,5.2,1c-2.8,1.9-5.7,3.5-8.2,3.4c-0.4,0-0.6,0.2-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6
  c3.1,0.1,6.4-1.8,9.6-4c1,0.5,2,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2c-0.9,0.5-1.8,1-2.7,1.6c-2.1,1.4-4,3.2-5.6,5.1
  c-0.6,0-1.3-0.1-1.9-0.1c-5-0.4-10-0.7-15-0.9C290.8,38.2,291.3,37.4,291.8,36.6z M287.9,40.1c-1.3,1.6-2.7,3-4.5,3.8
  c-2,0.9-3.8,0.6-5.4-0.4c2.2-1.2,4.4-2.4,6.7-3.5C285.8,40,286.9,40.1,287.9,40.1z M282.1,40.1c-1.8,0.9-3.5,1.8-5.2,2.7
  c-0.9-0.7-1.7-1.4-2.4-2.3C276.9,40.2,279.5,40.1,282.1,40.1z M246.9,46.5c0.4-1.1,0.7-2.2,1-3.4c1.4-4.8,2.8-9.8,8.2-11
  c5.1-1.1,10.1,0.8,14.1,5.2c0.4,0.5,0.9,1,1.4,1.6c0.2,0.2,0.3,0.4,0.5,0.6c-1.9,0.3-3.7,0.6-5.5,1c-6.6,1.6-12.3,4.7-17.3,8.7
  c-1.2-0.1-2.4-0.2-3.5-0.2C246.2,48.3,246.6,47.4,246.9,46.5z M243.6,50.3c-2.1,3-5.3,5.6-8.8,7.7c-6.2,1.1-12.1,2.9-17.9,5.4
  c2.8-3,5.7-6,8.7-9.3c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0c-3.7,4-7.2,7.7-10.6,11.3c-3.4,0-6.8-0.7-10.1-1.3
  c-0.2,0-0.3-0.1-0.5-0.1C216.3,53.1,229.7,50,243.6,50.3z M191.5,68.7c1.8-2.3,3.4-4.1,5-5.5c1.5,0.1,2.9,0.4,4.4,0.6
  c-2,1.7-4,3.6-6,5.8c-2.1,0.5-4.7,2.1-6.1,3C189.7,71.3,190.6,70,191.5,68.7z M188.8,74.1c0.5-0.5,2.5-1.8,4.4-2.6
  c-1.8,2-3.5,4-5.1,6.1c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.1-0.4-0.2-0.7-0.3C187.2,75.7,188,74.8,188.8,74.1z M185.9,77.7
  c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.5,0.3,0.7,0.4c-1.8,2.4-3.6,4.8-5.3,7.3c0.1-0.2,0.1-0.3,0.2-0.5l0.4-1
  C183.6,82.1,184.6,79.7,185.9,77.7z M170.5,81.6c3.5,0,4.7-0.8,6.3-1.9c0.7-0.5,1.6-1.1,2.9-1.7c1.5-0.7,3.1-1,4.7-0.7
  c-2.4,3.4-5.2,6.5-9.3,8.4c-2.5,1.2-5.1,1.6-7.9,2.1c-1.9,0.3-3.9,0.7-5.8,1.3C163.1,84.8,165.9,81.6,170.5,81.6z M160.8,90.4
  c2.1-0.8,4.4-1.2,6.5-1.5c2.7-0.5,5.5-1,8.2-2.2c3.1-1.4,5.4-3.5,7.4-5.7c-0.4,0.9-0.8,1.9-1.2,2.8l-0.4,1c-0.9,2-1.7,4-2.5,6.1
  c-2,3.1-3.9,6.3-5.7,9.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0-1,0.1-1.5,0.1c-2.4,0.2-4.7,0.3-7.1,0.6c0.1-0.2,0.2-0.3,0.3-0.5
  c0.6-0.9,1.5-1.5,2.5-2.1c0.5-0.4,1.1-0.7,1.6-1.1c0.2-0.2,0.3-0.6,0.1-0.8c-0.2-0.2-0.6-0.3-0.8-0.1c-0.4,0.4-0.9,0.7-1.5,1.1
  c-1,0.7-2.1,1.4-2.8,2.5c-0.3,0.4-0.5,0.8-0.6,1.3c-1.5,0.2-3.1,0.4-4.6,0.7C158.7,98.4,159.4,94.1,160.8,90.4z M157.2,92.2
  c0.7-0.5,1.4-0.8,2.2-1.2c-1.3,3.8-1.9,8-2.5,11.3c-4.5,0.9-8.9,2.5-12.9,5.2C147.6,101.3,151.9,95.7,157.2,92.2z M142.7,109.9
  c4.4-3.5,9.1-5.3,14.1-6.4c-0.1,0.9-0.3,1.8-0.4,2.5c-0.3,1.6-0.6,3.2-1,4.8c-0.3,0.2-0.7,0.3-1,0.5c-0.8,0.4-1.6,0.8-2.4,1.2
  c-0.3-1.2-0.6-2.3-0.9-3.5c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0.3,1.3,0.7,2.5,1,3.8c-1.1,0.7-2.2,1.5-3.1,2.5
  c-3.1,3.5-3.4,8.1-3.8,12.6c0,0.2,0,0.3,0,0.5c-0.6,0.6-1.2,1.2-1.9,1.7c-0.4-2.3-1-4.5-1.6-6.8c-0.1-0.3-0.4-0.5-0.7-0.4
  c-0.3,0.1-0.5,0.4-0.4,0.7c0.7,2.5,1.3,5,1.7,7.5c-4.1,3.8-8,7.7-11.9,11.6c0-0.6,0-1.1,0-1.7c1-2.6,2-5.2,3.1-7.8
  c0.7-1.7,1.5-3.5,2.2-5.4C136.9,122.2,139.5,115.8,142.7,109.9z M140.3,111.9c-2.6,5.2-4.8,10.7-6.8,15.6c-0.8,1.9-1.5,3.7-2.2,5.4
  c-0.7,1.6-1.3,3.3-2,4.9c0.1-2.9,0.2-5.8,0.5-8.7c0-0.3-0.2-0.6-0.5-0.6c-0.3,0-0.6,0.2-0.6,0.5c-0.3,4-0.5,8-0.5,11.9
  c-0.6,1.7-1.3,3.4-1.9,5c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0-0.2,0.1-0.4,0.1c-0.6,0.2-1.2,0.3-1.8,0.4c-0.9,0.2-1.7,0.4-2.5,0.6
  C125.3,135.4,130.9,120.8,140.3,111.9z M119.8,149.8c1-0.3,2.1-0.6,3.3-0.9c0.1,0,0.3-0.1,0.4-0.1c-2,2.2-3.9,4.3-5.9,6.6
  C118.4,153.6,119.1,151.8,119.8,149.8z M118.4,150.3c-0.3,0.9-0.7,1.7-1,2.5c0.2-0.6,0.4-1.3,0.5-2c0-0.1,0-0.3,0-0.4
  C118.1,150.4,118.3,150.4,118.4,150.3z M111.1,157.3c1.3-3.3,3.2-5.1,5.6-6.3c-0.7,3.5-3,5.7-6,7.3
  C110.8,158,110.9,157.7,111.1,157.3z M85.3,190.6c-0.4-9.6,4.5-22.1,13.6-26.4c1.5-0.7,3.2-1.3,5-1.9c1.6-0.5,3.2-1.1,4.8-1.8
  c-0.6,2.4-1,4.9-1.2,7.5c-7.3,10-13.6,20.7-18.4,32.3c-0.6,0.4-1.2,0.9-1.8,1.4C86.2,198,85.5,194.2,85.3,190.6z M69.9,232.6
  c0.7-0.2,1.5-0.4,2.2-0.5c1-0.2,1.9-0.4,3.1-0.7c0.3-0.1,0.5-0.4,0.4-0.7c-0.1-0.3-0.4-0.5-0.7-0.4c-1.1,0.3-2,0.5-3,0.7
  c-0.5,0.1-1.1,0.2-1.6,0.4c3-10.6,7.9-20.2,16.6-27.6c0.2,0.5,0.3,1,0.5,1.4c-1.2,3.2-2.2,6.4-3.1,9.7c-1.3,4.7-2.4,9.7-3.3,14.7
  c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-2.7,3.7-4.5,7.6-5.8,11.6c-3.2,6.3-6.4,12.6-9.6,19
  C66.3,251.2,67.5,241.6,69.9,232.6z M57.1,266c-1.2-4.6-2.8-10.3-1.9-13.3c0.7-2.4,1.2-2.7,2.4-3.2c0.6-0.2,1.3-0.6,2.2-1.2
  c3.1-2.3,3.3-4.8,3.4-7.2c0.2-2.4,0.3-4.7,3.6-7c0.6-0.4,1.2-0.7,1.8-1c-2.4,9.3-3.6,19.3-4.7,29.1c-0.1,0.5-0.1,1-0.2,1.6
  c-1.6,3.3-3.2,6.5-4.8,9.8c-0.1-0.7-0.3-1.5-0.4-2.2C58.2,269.9,57.7,268,57.1,266z M58.3,277.3c0.3,1.5,0.5,2.9,0.8,4.4
  c-2.1,2.7-4.1,5.4-6.1,8.2c-0.2-0.2-0.3-0.4-0.5-0.6C54.4,285.3,56.3,281.3,58.3,277.3z M51.9,290.4c0.1,0.2,0.3,0.3,0.4,0.5
  c-0.8,1.3-1.6,2.5-2.4,3.8C50.6,293.3,51.2,291.8,51.9,290.4z M50,296.8c1-1.7,2-3.3,3.1-4.9c0.2,0.3,0.5,0.6,0.7,0.9
  c-0.3,0.5-0.5,1.1-0.7,1.7c-1.9,5.3-1.6,11.5-1.2,17.4c0.1,1.5,0.2,3,0.2,4.5c-2.4-1.7-5.2-3-7.8-4.1c-0.4-0.2-0.9-0.4-1.3-0.6
  C44.9,306.6,47.2,301.6,50,296.8z M35.1,318.7c-0.2-1-0.5-2.1-0.7-3.2c-0.7-3-1.5-6.3-1.9-9.5c2.5,2.7,5.6,4.8,8.4,6.2
  c0.2,0.1,0.3,0.1,0.5,0.2c-1.7,4.6-3.1,9.1-4.2,13.3C36.3,323.4,35.7,321.1,35.1,318.7z M30.4,380.7c-10.1-2.5-11.3-16.8-12.1-27.2
  c-0.1-1.8-0.3-3.5-0.5-5l-0.1-0.8c-1-8.3-2.5-20.9,7.7-22.6c4.4-0.7,7.5,1.7,10.7,4.3c-1.5,6.5-2.5,12.7-2.7,18.6
  c-0.6,11.9,1.6,22.4,6.4,31.4C37.2,381.2,34.1,381.6,30.4,380.7z M41.3,379.9c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.2,0.5-0.5,0.7-0.7
  c0.7,2,1.5,4,2.4,6C43.3,383.4,42.2,381.6,41.3,379.9z M59.4,399c-4.7-3.1-8.6-6.6-11.8-10.2c-1.7-3.5-3.2-7.1-4.5-10.6
  c3.2-3.8,4.6-9.3,5.1-14.3c1.8,1.5,4,2.6,6.4,3.4c-1.9,9.2-2,18.3,2,26c1.4,2.8,3.3,5.3,5.6,7.6C61.2,400.1,60.3,399.6,59.4,399z
   M57.6,392.6c-3.8-7.4-3.7-16.2-1.9-25.1c1.5,0.4,3.1,0.7,4.7,1c3.3,7.1,6.7,13.3,10.2,18.6c3.7,9,8.5,17,15.2,23.4
  c-6.8-1.8-13.2-4.2-19-7.1C62.9,400.3,59.7,396.7,57.6,392.6z M73.7,407.7c3.7,1.5,7.7,2.8,11.8,3.8c0,0-0.1,0-0.1,0.1
  c-0.4,0.2-0.8,0.5-1.3,0.7C80.4,411,76.9,409.5,73.7,407.7z M85.6,412.9c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.7
  c0.2,0,0.3,0.1,0.5,0.1c0.9,0.8,1.9,1.6,2.9,2.3C88.9,414,87.2,413.4,85.6,412.9z M90.1,412.7c7.7,1.6,15.8,2.5,24.2,2.5
  c1.1,1.7,2.4,3.3,3.8,5c-8.4-1.2-16.5-2.7-24.1-4.7C92.7,414.6,91.4,413.6,90.1,412.7z M100.7,419c-1-0.5-2-0.9-3-1.5
  c6.8,1.6,14.1,2.9,21.5,4c1.5,1.8,3.2,3.6,4.9,5.4C116,424.8,108.2,422.2,100.7,419z M146.7,432.9c-0.9,1.4-1.8,2.7-3.4,3.2
  c-3.9,1.1-10.3-3-15.1-6.9c6.1,1.4,12.3,2.6,18.6,3.6C146.8,432.8,146.8,432.8,146.7,432.9z M147.6,431.6
  c-7.2-1.2-14.3-2.5-21.2-4.2c-0.4-0.4-0.9-0.8-1.3-1.2c-1.5-1.5-2.9-3-4.2-4.5c2.2,0.3,4.4,0.6,6.6,0.8c3.1,3.4,6.6,4.1,11.2,5
  c1.3,0.3,2.7,0.5,4.2,0.9c2.2,0.5,3.8,1.6,5,2.9C147.7,431.4,147.6,431.5,147.6,431.6z M171.1,441.1c-3.1,4.6-6.5,7.2-9.5,7.2
  c0,0-0.1,0-0.1,0c-2.3-0.1-4.4-1.6-6.2-4.7c-1.1-1.8-1.8-3.7-2.6-5.6c-0.6-1.6-1.3-3.2-2.1-4.7c6.1,0.9,12.2,1.7,18.3,2.5
  c1.5,0.7,2.9,1.4,4.4,2.1C172.6,438.9,171.9,440,171.1,441.1z M175.3,437.5C175.2,437.5,175.2,437.5,175.3,437.5
  c0-0.1,0.1-0.2,0.1-0.3c0.1,0.2,0.2,0.3,0.4,0.5C175.6,437.7,175.4,437.6,175.3,437.5z M176.5,436.7c0.8,0.1,1.7,0.2,2.5,0.3
  c1,0.1,2.1,0.3,3.2,0.4c1.8,1.9,3.6,3.5,5.6,4.8c-3.4-1.1-6.7-2.3-10-3.6C177.3,437.9,176.9,437.3,176.5,436.7z M184.5,447.2
  c-2-0.8-4-4-5.8-6.9c4.1,1.6,8.2,3,12.5,4.2C189.7,446.6,187.4,448.4,184.5,447.2z M230.7,448.4c-0.2-0.2-0.5-0.1-0.7,0.1
  c-12.7-0.2-25.1-1.6-37-4.7c0.3-0.6,0.7-1.3,1-2c0.1-0.3,0-0.6-0.3-0.8c-0.3-0.1-0.6,0-0.8,0.3c-0.3,0.7-0.7,1.4-1.1,2.2
  c-0.8-0.2-1.5-0.4-2.3-0.6c0,0,0,0,0,0c0.2-0.3,0.1-0.6-0.2-0.8c-1.9-1.2-3.7-2.6-5.5-4.3c7,0.8,14,1.7,20.9,2.7
  c5.3,1.5,10.7,2.8,16.4,3.9c0,0,0.1,0,0.1,0c0.3,0,0.5-0.2,0.6-0.5c0-0.2,0-0.4-0.2-0.5c0.4,0.1,0.9,0.2,1.3,0.3
  c6.5,1.4,13.2,3,20.1,4.7C239,448.4,234.9,448.5,230.7,448.4C230.8,448.4,230.8,448.4,230.7,448.4z M232.6,449.6c0.1,0,0.2,0,0.3,0
  c4.6,0,9.2-0.2,13.9-0.5c1.2,0.3,2.4,0.6,3.6,0.9c6.4,1.6,12.7,3.2,19.1,4.7C256.1,456.6,243.3,456.2,232.6,449.6z M250.8,449
  c-0.1,0-0.1,0-0.2,0c1.4-0.1,2.8-0.2,4.2-0.4c7.4,1.9,14.8,3.7,22.2,5.1c-1.4,0.3-2.8,0.5-4.2,0.7
  C265.4,452.6,258.1,450.8,250.8,449z M275.7,455c1.5-0.3,3-0.5,4.5-0.8c4.9,0.8,9.9,1.5,14.9,2c-0.9,0.8-1.8,1.5-2.8,2.1
  C286.7,457.4,281.2,456.2,275.7,455z M293.9,458.6c0.9-0.7,1.9-1.5,2.8-2.3c4.8,0.4,9.7,0.7,14.6,0.7c2.6,0,5.3-0.1,7.9-0.2
  c0.2,1.6,0.6,3.2,1.4,4.6C311.8,461.2,302.9,460.1,293.9,458.6z M341.9,465.4c-4.3,1.8-12.5,1.1-16.9-1.3c-0.8-0.4-1.4-0.9-2-1.4
  c0.6,0,1.3,0,1.9,0c7.9,0,15.7-0.7,23.4-2.2C346.3,462.5,344.4,464.4,341.9,465.4z M349.8,458.9c-9.1,2-18.4,2.7-27.8,2.5
  c-1-1.4-1.4-3-1.6-4.7c5.1-0.3,10.3-0.9,15.6-1.8c5.2-0.9,10.1-2,15-3.4c0.3,0.2,0.6,0.3,0.9,0.5c1.1,0.7,1.8,1.8,2.5,2.9
  c0,0.1,0.1,0.1,0.1,0.2C352.7,456.2,351.2,457.5,349.8,458.9z M352.1,458.4c0.9-0.8,2-1.6,3-2.2c0.3,0.4,0.6,0.8,1,1.2
  C354.7,457.7,353.4,458.1,352.1,458.4z M357.3,457c-0.4-0.4-0.8-0.8-1.1-1.3c0,0,0.1,0,0.1-0.1c3.2-1.4,6.7-2.4,10.2-3.3
  c0.2-0.1,0.4-0.1,0.6-0.2c1.2,0.2,2.3,0.4,3.5,0.5C366.2,454.3,361.8,455.7,357.3,457C357.4,457,357.3,457,357.3,457z M366.1,460.8
  l-0.2-0.1c-2.3-0.7-5.1-1.6-7.3-2.9c4.9-1.4,9.7-3.1,14.5-5c2,0.2,4,0.3,6.1,0.3c1.1,0,2.2,0,3.3-0.1c1.3-0.1,2.7-0.2,4-0.3
  c0.6,2.2,1.1,4.4,1.9,6.6C381.2,461.9,373.6,463.1,366.1,460.8z M415.1,465.6c-6.5,5.1-15.7,7.1-21.6,0.3c-1.5-1.8-2.6-3.8-3.5-5.9
  c5.5-2.1,10.8-4.8,15.6-7.4c2.7-1.4,5.3-2.9,7.9-4.3c5.1-1.2,10.1-2.6,14.9-4C425.2,452.9,421.8,460.3,415.1,465.6z M430.2,442.6
  c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0-0.1,0-0.1c1.2-0.2,2.4-0.5,3.6-0.9c0.2,0.1,0.4,0.1,0.6,0.2C433.2,441.7,431.7,442.1,430.2,442.6z
   M436.7,440.5c-0.2,0-0.3-0.1-0.5-0.1c3.3-1.1,6.7-2.4,9.7-3.7c0.5,0,1,0.1,1.6,0.1C444.1,438,440.5,439.3,436.7,440.5z
   M440.8,441.4c-0.6-0.1-1.3-0.3-1.9-0.4c4.1-1.4,8-2.8,11.6-4.2c0.7,0,1.4,0,2.1,0c5.7,0,11.3-0.6,16.7-2
  C459.7,440.8,449.9,443,440.8,441.4z M473.8,431.9c-0.4,0.3-0.7,0.5-1.1,0.8c-6.2,2-12.6,2.9-19.1,2.9c7.5-2.9,15.1-6.1,22.5-9.4
  C475.3,428.1,474.5,430,473.8,431.9z M477.6,425.6c0.7-0.3,1.4-0.6,2.1-0.9c0.9-0.4,1.7-0.8,2.6-1.2c0,0.4,0,0.8,0,1.1
  c-2.2,2.2-4.5,4.2-6.8,5.9C476.2,428.9,476.9,427.3,477.6,425.6z M476.5,431.4c2-1.6,3.9-3.3,5.8-5c0,0.8,0.1,1.6,0.1,2.3
  C480.5,429.7,478.5,430.6,476.5,431.4z M483.1,418c-0.3,0-0.6,0.3-0.6,0.6c0,0.8,0,1.8-0.1,2.9c0,0.2,0,0.5,0,0.7
  c-1.3,0.5-2.5,0.9-3.8,1.3c1.4-3,2.9-5.8,4.5-8.7c2.9-0.3,5.7-0.7,8.6-1c-1.7,2.3-3.5,4.5-5.4,6.7c-0.9,0.4-1.8,0.8-2.7,1.2
  c0-0.1,0-0.2,0-0.3c0-1.1,0.1-2.1,0.1-2.9C483.7,418.2,483.4,418,483.1,418L483.1,418z M484.3,422.7c-0.2,0.3-0.5,0.5-0.7,0.7
  c0-0.2,0-0.3,0-0.5c0.1,0,0.2-0.1,0.3-0.1C484,422.8,484.1,422.8,484.3,422.7z M483.5,425.2c1.1-1.1,2.2-2.2,3.2-3.4
  c3.7-1.4,7.5-2.8,11.3-4.3c-4.7,4.4-9.5,7.8-14.4,10.5C483.5,427.2,483.5,426.2,483.5,425.2z M500.8,420.9
  c-1.7,4.3-4.8,12.3-10.2,13.9c-2,0.6-3.6,0.5-4.7-0.4c-1.3-1-1.9-2.9-2.2-5.1c5.8-3.1,11.5-7.4,17.2-12.9c0.7-0.3,1.4-0.5,2-0.8
  C502.2,417.4,501.5,419.1,500.8,420.9z M504.2,411.3c-0.1,0.1-0.2,0.3-0.4,0.4c-3.2,0.2-6.4,0.5-9.6,0.8c0.8-1.1,1.5-2.2,2.2-3.4
  c3.5-0.8,7-1.8,10.4-2.8c-0.6,1.3-1.1,2.5-1.6,3.8C504.9,410.5,504.5,410.9,504.2,411.3z M508.8,411.4c-1,0.1-2,0.1-2.9,0.2
  c0.1-0.3,0.2-0.5,0.3-0.8c1.7-1.9,3.3-3.8,4.8-5.7c4.4-1.5,8.6-3.1,12.7-5.1c1.4-0.7,2.8-1.4,4.2-2.1c1.9,0.3,3.6,1.1,5.1,2
  c-6,3.5-12.2,6.6-18.6,9.5C512.6,410,510.7,410.7,508.8,411.4z M544.1,409.2c-0.1,1-0.2,1.9-0.3,2.9c-0.2,0-0.3,0-0.5-0.1
  c-2.2-0.3-4.5-0.7-6.7-0.8c-7.8-0.4-15.6-0.4-23.4-0.1c0.5-0.2,1.1-0.5,1.6-0.7c1.9-0.6,3.8-1.3,5.7-1.8c1.4-0.4,3.1-0.8,4.9-1.1
  c4.3-0.9,9.5-1.9,12.8-4.6c1.5,0.7,3.2,1.3,5.1,1.3c0.5,0,1,0,1.5-0.1C544.5,405.8,544.3,407.5,544.1,409.2z M546.4,402.4
  c0.1-0.4,0.2-0.9,0.4-1.3c1.4-4.6,4.2-7,7.6-8.5C553.1,397,550.9,400.9,546.4,402.4z M556,390.8c0.2-0.9,0.4-1.8,0.6-2.6
  c0.3-1.4,0.5-2.7,0.6-4.1c1.2-1.1,2.4-2.3,3.6-3.4c0.6,3.3,0.9,6.3,0.9,9C559.7,389.9,557.8,390.2,556,390.8z M580.6,384.2
  c-4.1,3.6-9.9,4.3-15.5,5c-0.7,0.1-1.5,0.2-2.2,0.3c0.1-3-0.4-6.2-1-9.8c1.7-1.7,3.3-3.5,4.8-5.4c2.4-0.4,4.5-1.1,6.3-2.2
  c2.5-1.6,4.1-3.9,4.8-6.9c0.1-0.3-0.1-0.6-0.4-0.7c-0.3-0.1-0.6,0.1-0.7,0.4c-0.6,2.7-2,4.7-4.3,6.2c-1.3,0.8-2.9,1.4-4.6,1.8
  c5-6.4,9-13.4,11.8-21.2c0.7,1.4,1.4,2.8,2,4.3C587.9,370.4,585,380.2,580.6,384.2z M588.2,307.2c0.5,3.1-1.3,5.7-3.8,8
  c-0.1-4.8-0.5-9.7-1.1-14.6C585.9,302.6,587.9,304.7,588.2,307.2z M579.3,348.6c-1.2-2.2-2.5-4.4-3.8-6.6
  c-2.2-3.8-4.5-7.7-6.4-11.7c0,0,0,0,0-0.1c0-0.3,0.1-0.5,0.1-0.8c2.7-2.6,5.5-5.1,8.5-7.4c0.7-0.5,1.5-1.1,2.3-1.7
  c1.1-0.8,2.2-1.6,3.3-2.5C583.5,328.5,582.3,338.9,579.3,348.6z M582.1,299.7c0.7,5.6,1.1,11.1,1.2,16.6c-1.3,1.1-2.7,2.1-4,3.1
  c-0.9,0.6-1.7,1.2-2.4,1.8c-2.7,2.1-5.2,4.4-7.7,6.7c0.1-1,0.1-2.1,0.1-3.2c0.2-6.1,0-12.1-0.3-18c0.2-0.4,0.3-0.8,0.5-1.2
  c0.1,0,0.1-0.1,0.2-0.1c1.8-1,3.7-1.8,5.6-2.7c2.2-1,4.4-2,6.4-3.1C581.8,299.5,582,299.6,582.1,299.7z"
              />
            </g>
            <g title="Actin filaments" className="actin_filaments_ton">
              <title>Actin filaments</title>
              <path
                d="M590,304.7c1.9-3.8,3.7-7.7,5.5-11.7
  c0.1-0.3,0-0.7-0.3-0.9c-0.3-0.2-0.7,0-0.9,0.3c-1.6,3.5-3.2,6.9-4.8,10.3c-5.7-24.5-12.9-49.9-22-77.4
  c-2.4-15.3-4.8-28.5-6.9-38.8c0.9-6.5,1.9-13,3-19.4c0.1-0.4-0.2-0.7-0.5-0.8c-0.3-0.1-0.7,0.2-0.8,0.5c-0.9,5.3-1.8,10.6-2.5,16
  c-0.3-1.6-0.7-3.1-1-4.5c-5.1-21.4-9.3-46-11.3-66.8c1.5,1.5,3,3.1,4.3,4.6c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.4-0.2
  c0.3-0.2,0.3-0.6,0.1-0.9c-1.7-1.9-3.5-3.9-5.5-5.8c-0.5-5.2-0.8-10.2-1-14.8c2.7,1.4,5.5,2.7,8.1,4.1c0.1,0.1,0.2,0.1,0.3,0.1
  c0.2,0,0.5-0.1,0.6-0.3c0.2-0.3,0-0.7-0.3-0.9c-2.9-1.5-5.8-3-8.8-4.5c-0.3-11.5,0.4-20.6,2.6-25.3c0.2-0.3,0-0.7-0.3-0.9
  c-0.3-0.2-0.7,0-0.9,0.3c-2.4,5.2-3,14.9-2.8,25.2c-2.9-1.4-5.8-2.9-8.8-4.3c-2-9.2-4.2-18.5-6.6-28.1c7.2,1.8,14.4,3.7,21.7,5.8
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-7.5-2.2-15-4.1-22.4-5.9c-0.6-2.5-1.3-5-2-7.6c6.2,1.7,12.2,3.5,18,5.5
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-6-2.1-12.2-4-18.8-5.7c-0.2-0.8-0.4-1.6-0.6-2.4c2.2,0.6,4.5,1.3,6.7,2
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-2.5-0.8-4.9-1.5-7.4-2.2c-0.3-1-0.5-2-0.8-3c-0.1-0.3-0.4-0.6-0.8-0.5
  c-0.3,0.1-0.6,0.4-0.5,0.8c0.2,0.8,0.4,1.5,0.6,2.2c-14.5-4-29.7-7-45.6-8.8c-0.4-0.8-0.7-1.6-1.1-2.4c-0.2-0.3-0.5-0.5-0.9-0.3
  c-0.3,0.2-0.5,0.5-0.3,0.9c0.3,0.5,0.5,1.1,0.8,1.6c-20.2-2.2-41.6-2.7-64.3-1.4c-6.4-2.6-12.5-5-18.1-7.1
  c43.8-2.3,81.4,1.4,126,12.1c0.3,0.1,0.7-0.1,0.8-0.5c0.1-0.3-0.1-0.7-0.5-0.8c-45.7-10.9-84.1-14.5-129.3-11.9
  c-6.1-2.4-11.6-4.4-16.1-6.1c-0.3-0.1-0.7,0-0.8,0.4c-0.1,0.3,0,0.7,0.4,0.8c3.9,1.5,8.4,3.2,13.4,5.1c-3.2,0.2-6.4,0.4-9.6,0.7
  c-10,0.8-20.4,1.9-31.4,3.3c-7.4-1.2-14.7-2.3-21.9-3.3c-0.4,0-0.7,0.2-0.7,0.6c0,0.4,0.2,0.7,0.6,0.7c5.8,0.8,11.6,1.6,17.5,2.6
  c-21.8,2.8-46,6.7-73.9,11.8c22.4-13,36.4-19,44.9-21.7c0.3-0.1,0.5-0.5,0.4-0.8c-0.1-0.3-0.5-0.5-0.8-0.4
  c-8.9,2.8-23.9,9.3-48.2,23.6c-7.1,1.3-14.4,2.7-22,4.1c14.3-9.1,28.7-16.9,42.9-23.5c0.3-0.1,0.5-0.5,0.3-0.9
  c-0.1-0.3-0.5-0.5-0.9-0.3c-15.2,7-30.5,15.4-45.8,25.3c-10.7,2-21.9,4.2-33.7,6.6c17.4-11.7,34.8-20.3,51.9-25.7
  c0.3-0.1,0.5-0.5,0.4-0.8c-0.1-0.3-0.5-0.5-0.8-0.4c-18.1,5.7-36.4,14.9-54.8,27.5c-4.5,0.9-9.1,1.8-13.8,2.7
  c4.4-4.6,8.9-9,13.4-13.4c0.3-0.2,0.3-0.7,0-0.9c-0.2-0.3-0.7-0.3-0.9,0c-5,4.8-9.9,9.8-14.7,14.8c-6.9,1.4-13.9,2.8-21.1,4.3
  c-0.4,0.1-0.6,0.4-0.5,0.8c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c6.6-1.3,13-2.6,19.3-3.9c-2.2,2.4-4.5,4.8-6.7,7.2
  c-6.9,1-13.8,2.1-20.9,3.1c-0.4,0.1-0.6,0.4-0.5,0.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0.1,0,0.1,0c6.5-0.9,12.9-1.9,19.3-2.9
  c-1.5,1.7-3,3.3-4.5,5c-7.5,2.1-15,4.3-22.4,6.6c-0.3,0.1-0.5,0.5-0.4,0.8c0.1,0.3,0.3,0.5,0.6,0.5c0.1,0,0.1,0,0.2,0
  c6.8-2.1,13.6-4.1,20.5-6c-5.8,6.7-11.5,13.6-17,20.6c-6.6,2.3-13.3,4.5-19.9,6.8c-0.3,0.1-0.5,0.5-0.4,0.8
  c0.1,0.3,0.3,0.4,0.6,0.4c0.1,0,0.1,0,0.2,0c6-2.1,12-4.1,18-6.2c-0.4,0.6-0.9,1.1-1.3,1.7c-15.4,16.2-30.1,34.4-43.3,53.9
  c-5.5,4.3-11.2,8.7-17,13.2c-0.3,0.2-0.3,0.6-0.1,0.9c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1c4.7-3.7,9.3-7.3,13.9-10.8
  c-0.6,0.9-1.2,1.8-1.8,2.7c-16,24.2-30.2,47.2-43.1,70.9c-10.6,15.3-20.9,31.1-31,47.6c-0.7-1.7-1.5-3.4-2.2-5.1
  c-0.1-0.3-0.5-0.5-0.9-0.3c-0.3,0.1-0.5,0.5-0.3,0.9c0.8,2,1.7,4,2.6,5.9c-1.2,2-2.4,4-3.7,6c-0.2-1.3-0.5-2.4-0.7-3.4
  c-0.1-0.4-0.4-0.6-0.8-0.5c-0.4,0.1-0.6,0.4-0.5,0.8c0.3,1.3,0.6,2.9,0.9,4.8c-2.4,4-4.8,8-7.1,12c-0.2,0.3-0.1,0.7,0.2,0.9
  c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3c2.1-3.6,4.2-7.2,6.3-10.8c0.7,3.5,1.5,7.9,2.7,12.9c-4.1,10.4-8.1,21.2-11.9,32.5
  c-1.1-1.5-2.3-3-3.4-4.6c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c1.3,1.9,2.6,3.6,3.9,5.4c-0.3,1-0.7,2-1,3
  c-0.1,0.3,0.1,0.7,0.4,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.6-0.4c0.2-0.7,0.5-1.5,0.7-2.2c4.7,5.9,9.7,11.2,15.1,15.9
  c-1,3.4-2,6.9-3.1,10.4c-1.7-1.1-3.2-2.3-4.5-3.3c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9c1.4,1.2,3.1,2.4,4.9,3.6
  c-1.8,6.3-3.6,12.7-5.4,19.2c-0.1,0.3,0.1,0.7,0.5,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.6-0.5c1.8-6.4,3.5-12.6,5.3-18.8
  c8.2,5.4,20.1,11.5,35,18c3.1,4.3,6.5,8.5,10.2,12.7c-5.6-2.2-11.2-4.4-16.6-6.7c-0.3-0.1-0.7,0-0.8,0.4c-0.1,0.3,0,0.7,0.4,0.8
  c6.2,2.6,12.5,5.1,19,7.6c9.8,11,21.6,21.7,35.7,31.5c-29.5-6.4-49.8-14.3-59.5-23.2c-0.3-0.2-0.7-0.2-0.9,0
  c-0.2,0.3-0.2,0.7,0,0.9c10.7,9.9,33.4,17.9,63.1,24.1c4.6,3.1,9.4,6.1,14.5,8.9c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3
  c0.2-0.3,0.1-0.7-0.2-0.9c-4.1-2.3-8-4.7-11.7-7.1c13.6,2.8,28.6,5.2,44.6,7.3c7.8,5.3,15.8,10.3,24,15c0.1,0.1,0.2,0.1,0.3,0.1
  c0.2,0,0.4-0.1,0.6-0.3c0.2-0.3,0.1-0.7-0.2-0.9c-7.4-4.2-14.6-8.7-21.7-13.5c21.2,2.7,44,4.8,67.4,6.4
  c32.7,13.1,60.6,22.6,78,23.3c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.7-0.6-0.7c-16.5-0.7-42.9-9.5-73.8-21.7
  c9.9,0.6,19.9,1.2,29.9,1.7c35.8,9,71.5,16.9,104.8,23.2c-7.4,0.8-14.8,1.3-21.9,1.5c-0.4,0-0.6,0.3-0.6,0.7c0,0.4,0.3,0.6,0.6,0.6
  c0,0,0,0,0,0c8.5-0.2,17.2-0.8,26.1-2c4.3,0.8,8.5,1.6,12.8,2.3c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.6-0.5c0.1-0.4-0.2-0.7-0.5-0.8
  c-2.9-0.5-5.9-1.1-8.9-1.6c37.3-5.4,77.2-19.2,115.1-40.1c1.4-0.8,2.9-1.6,4.3-2.4c15.4-2,31.1-4.2,47.3-6.6
  c-0.2,1-0.4,1.9-0.5,2.9c-0.1,0.4,0.2,0.7,0.5,0.8c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.6-0.5c0.2-1.1,0.4-2.2,0.6-3.3
  c7-1.1,14.1-2.2,21.3-3.3c0.4-0.1,0.6-0.4,0.5-0.7c-0.1-0.4-0.4-0.6-0.7-0.5c-7,1.1-14,2.2-20.9,3.3c1.4-8.3,2.4-17.5,3.1-27.4
  c0.1-0.1,0.3-0.2,0.4-0.4c6.7-5.7,12.9-11.3,18.2-16.9c1.4,12.7,3,25.9,4.7,39.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0.1,0,0.1,0
  c0.4,0,0.6-0.4,0.6-0.7c-1.8-14.2-3.4-27.7-4.9-40.7c1.3-1.4,2.6-2.7,3.8-4.1c1.5,11.9,2.9,24,4.1,36.5c0,0.3,0.3,0.6,0.6,0.6
  c0,0,0,0,0.1,0c0.4,0,0.6-0.4,0.6-0.7c-1.3-12.9-2.7-25.4-4.3-37.6c1.9-2.2,3.7-4.3,5.3-6.4c0.9,13.2,1.6,26.4,2,39.3
  c0,0.4,0.3,0.6,0.6,0.6c0,0,0,0,0,0c0.4,0,0.6-0.3,0.6-0.7c-0.5-13.4-1.2-27.1-2.1-40.8c1.8-2.4,3.5-4.8,5-7.2
  c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2c-1.2,1.9-2.6,3.9-4.1,5.9c-0.8-12.3-1.9-24.6-3-36.7c3.2-5.6,6.3-11.5,9.4-17.7
  c3.1,13.6,5.7,27.1,7.9,40.6c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c0.4-0.1,0.6-0.4,0.5-0.7C596.1,333,593.3,319,590,304.7z
   M565,217.7c-0.6-1.8-1.2-3.6-1.8-5.4c-1.4-4-2.8-8.1-4.3-12.2c0.4-3.2,0.8-6.5,1.2-9.7C561.7,198.2,563.3,207.4,565,217.7z
   M457.7,433.9c-80.9,8.5-150.1,10.1-209.6,4.6c-9.9-0.9-19.5-2-28.7-3.2c-13.2-3.7-26.2-7.4-39-11.3c-2.3-1-4.6-1.9-6.8-2.9
  c-11.7-5-23.8-10.2-35.8-15.2c-8.3-3.5-16.3-6.7-24.2-9.8c-1.5-0.6-2.9-1.2-4.4-1.8c-5.4-5.4-10.6-11-15.7-16.8
  c-18.2-20.6-34-42.9-47.1-66.5c13.3-39.5,27.4-74.2,43-106.2c4.7-9.6,9.5-18.8,14.4-27.9c14-17.1,28.3-33.2,42.9-48.3
  c2.2-2.3,4.4-4.5,6.7-6.8c22.8-17,42.7-31.1,60-43C251,67,288.7,56.4,325,48.7c27.5-2.7,54.9-4.3,82.6-3.9
  c25,6.3,49.8,13.8,73.3,22.3c4.5,2.3,8.9,4.6,13.2,7c21.6,49.9,42.8,107.8,59.5,178.3c-1.1,25-1.3,49.6-1.5,72.7
  c-0.1,13.3-0.2,26-0.5,38C524.5,395.8,493.8,415.2,457.7,433.9z M551.6,365.2c-0.3,10.2-0.6,19.8-1.2,28.9
  c-14.8,12.3-32.2,24.3-51.1,35c-12.9,1.7-25.6,3.2-38,4.5C495.8,415.4,525.4,396.4,551.6,365.2z M454.5,435.5
  c-7.6,3.9-15.4,7.8-23.4,11.8c-46.6,3-106.7,3.2-164.2,0.6c-12.9-3.3-25.8-6.6-38.7-10.2c6.4,0.8,13,1.5,19.8,2.1
  c23.3,2.1,48,3.2,74.3,3.2C362.5,443,406.5,440.5,454.5,435.5z M38.7,334.6c2.3-7.4,4.7-14.7,7.1-21.8
  C58.9,336,74.5,358,92.5,378.4c4.5,5,9,9.9,13.7,14.7c-18.1-7.3-35-14.4-50.2-23.3C48.5,357.7,42.9,345.6,38.7,334.6z M53.6,368.5
  c-7.3-4.5-14.3-9.4-20.7-15c1.7-5.7,3.4-11.4,5.2-16.9C42,346.6,47.1,357.5,53.6,368.5z M44.9,311.2c-2.3,7-4.7,14.1-6.9,21.4
  c-4.1-11.2-6.9-21.3-8.8-29.7c1.7-4.2,3.3-8.3,5-12.4C37.6,297.5,41.1,304.4,44.9,311.2z M34.9,288.9c8.5-20.1,17.6-38.8,27.5-57
  c12-17.3,24.3-33.8,36.9-49.5c-3.8,7.1-7.5,14.4-11.2,21.8C72.7,236,58.7,270.4,45.5,309.5C41.7,302.8,38.2,295.9,34.9,288.9z
   M108.4,395.4c2.6,2.7,5.3,5.3,8,7.8c-19.8-7-37-13.6-51.4-19.9c-2.7-3.7-5.2-7.4-7.6-11.1C72.9,381,90.1,388.1,108.4,395.4z
   M111.6,396.7c0.5,0.2,1,0.4,1.6,0.6c7.8,3.1,15.9,6.3,24.1,9.8c10.5,4.4,21.1,8.9,31.5,13.3c-0.7-0.2-1.4-0.4-2.1-0.6
  c-16.9-5.2-32.6-10.4-47.1-15.5C116.8,401.8,114.2,399.3,111.6,396.7z M166.2,421c4.5,1.4,9.1,2.8,13.7,4.2
  c4.9,2.1,9.8,4.2,14.7,6.3c-21.8-3.8-41.6-8.5-60-13.8c-4.3-3.6-8.6-7.4-12.8-11.2C135.6,411.3,150.4,416.1,166.2,421z M191,428.5
  c6.2,1.8,12.4,3.6,18.7,5.4c-3.2-0.5-6.3-1-9.5-1.5C197.2,431.1,194.1,429.8,191,428.5z M555.6,221.4c-0.7,8.7-1.3,17.5-1.7,26.2
  c-16.4-67.8-37-124-57.9-172.5c5.6,3.1,11.1,6.4,16.4,9.6c14.9,35.9,27.8,68.1,38.7,97.9C552.8,195.6,554.2,208.5,555.6,221.4z
   M553.1,188c1.5,4.2,3,8.3,4.5,12.4c-0.5,4.7-1,9.4-1.4,14.1C555.2,205.7,554.2,196.9,553.1,188z M556.3,228.8
  c1.6,16.4,3.1,32.9,4.6,49.9c-1.9-9-3.9-17.9-5.9-26.5C555.3,244.4,555.7,236.6,556.3,228.8z M514.3,85.9
  c8.7,5.5,16.7,11.1,23.6,16.9c5.3,25.7,9.2,49.9,12.4,73.8C540.7,150.9,529.2,121.8,514.3,85.9z M513.4,83.9
  c-0.7-1.7-1.4-3.4-2.1-5.1c8.1,3.4,16.1,6.9,23.7,10.4c0.9,3.9,1.7,7.8,2.5,11.6C530.4,95,522.2,89.4,513.4,83.9z M511.5,82.7
  c-5.3-3.2-10.8-6.4-16.4-9.5c-0.1-0.3-0.3-0.7-0.4-1c5,1.9,10,3.9,14.9,5.9C510.2,79.6,510.9,81.2,511.5,82.7z M439.4,46.1
  c14.6,1,29.4,2.6,44.5,5c2.8,6.1,5.6,12.4,8.4,18.8c-3.6-1.4-7.2-2.7-10.9-4C467.5,58.7,453.2,52.1,439.4,46.1z M471.8,62.5
  c-19-6.5-38.6-12.4-58.5-17.6c7.4,0.2,14.8,0.5,22.3,0.9C447.3,50.9,459.7,56.5,471.8,62.5z M493,71.5c0.1,0.2,0.2,0.4,0.3,0.6
  c-1-0.6-2.1-1.1-3.1-1.7C491.1,70.8,492.1,71.2,493,71.5z M494,70.5c-2.8-6.5-5.7-12.9-8.5-19.2c4.6,0.7,9.3,1.5,13.9,2.4
  c3.3,7.7,6.4,15.3,9.5,22.7C504,74.4,499,72.4,494,70.5z M484.8,49.9c-1.1-2.5-2.2-4.9-3.4-7.3c4.8,0.6,9.5,1.2,14,2
  c1.1,2.6,2.2,5.2,3.3,7.7C494.1,51.4,489.4,50.6,484.8,49.9z M483.3,49.7c-16.1-2.5-31.9-4.1-47.4-5.1c-4.5-1.9-8.9-3.8-13.2-5.6
  c20.3,0.1,39.5,1.3,57.3,3.4C481.1,44.8,482.2,47.2,483.3,49.7z M432,44.4c-8.1-0.5-16.2-0.7-24.2-0.8c-5.5-1.4-10.9-2.7-16.4-3.9
  c9.5-0.4,18.8-0.6,27.8-0.6C423.4,40.7,427.7,42.5,432,44.4z M387.7,38.4c8.5-0.9,16.8-1.6,24.9-2.1c1.1,0.5,2.3,0.9,3.4,1.4
  C406.8,37.8,397.4,38,387.7,38.4z M386.5,39.8c5.2,1.2,10.4,2.4,15.7,3.7c-21.9-0.1-43.6,1-65.4,2.9c4.4-0.9,8.7-1.7,13.1-2.4
  c6.4-1.1,12.6-2.1,18.8-3C374.7,40.4,380.6,40.1,386.5,39.8z M349.6,42.6c-8.2,1.4-16.4,3.1-24.8,4.8c-22.5,2.2-45.2,5.3-68.3,8.7
  c32.1-6.3,64.2-10.9,94.8-13.8C350.8,42.4,350.2,42.5,349.6,42.6z M313.4,49.9c-31.5,7.1-63.9,16.3-96.3,26.4
  c9.3-6.3,17.8-11.9,25.7-16.9C266.8,55.8,290.2,52.5,313.4,49.9z M239.7,59.9c-8.2,5.2-17.1,11.1-26.8,17.8
  c-5.8,1.8-11.6,3.7-17.3,5.5c8.3-6.9,16.7-13.4,25-19.5c4.2-1,8.4-1.9,12.6-2.8C235.3,60.6,237.5,60.3,239.7,59.9z M209.2,80.2
  c-14.6,10-31,21.7-49.4,35.3c11-10.7,22-20.7,33-30C198.3,83.7,203.7,82,209.2,80.2z M217.4,64.5c-8.4,6.2-16.8,12.8-25.2,19.9
  c-11.3,3.7-22.6,7.5-33.8,11.3c5.4-5.5,10.9-10.7,16.5-15.6c2.6-2.3,5.2-4.6,7.9-6.8C194.2,70.1,205.7,67.2,217.4,64.5z
   M185.1,71.2c1.5-1.2,3-2.4,4.5-3.6c7.6-1.2,15.1-2.3,22.6-3.5c0.8-0.1,1.6-0.3,2.5-0.4C204.7,66.1,194.9,68.6,185.1,71.2z
   M156.3,97.7c11-3.8,22.1-7.5,33.2-11.1c-12.4,10.5-24.7,22-37,34.3c-8.9,6.7-18.3,13.7-28.2,21.3c9.1-14.3,18.6-27.9,28.6-40.9
  C154,100.1,155.2,98.9,156.3,97.7z M146.5,127c-0.2,0.2-0.5,0.5-0.7,0.7c-13.1,13.5-26,27.9-38.5,43c4.9-8.7,9.9-17.2,15.1-25.4
  C130.7,138.9,138.8,132.8,146.5,127z M553.4,325c0.2-21.7,0.4-44.7,1.3-68c2.5,10.7,4.9,21.7,7.1,33c1.5,17.2,3,34.7,4.8,52.9
  c-4.5,6.6-9.1,12.8-13.7,18.6C553.2,349.9,553.3,337.7,553.4,325z M564.2,302.3c2.1,11.4,4.2,23.1,6,35.1c-0.8,1.3-1.6,2.5-2.5,3.8
  C566.5,327.8,565.4,314.9,564.2,302.3z M563.1,289.8c-0.2-2-0.4-4-0.5-6.1c-1.9-21.3-3.7-41.9-5.7-62.3c0.5-6.1,1.1-12.1,1.7-18.2
  c2.7,7.7,5.3,15.2,7.7,22.6c4.3,27.7,8.7,62,12.1,98.1c-2.3,4.1-4.7,8-7.1,11.8C568.8,319.8,566.1,304.5,563.1,289.8z M546.3,113.1
  c2,20.3,6.1,44.2,11.2,65.6c0.6,2.4,1.2,5,1.8,7.9c-0.5,3.6-0.9,7.2-1.4,10.8c-1.8-5-3.6-10.1-5.6-15.3
  c-3.2-25.2-7.3-50.8-12.8-77.9c2.3,2,4.5,3.9,6.5,5.9C546.2,111.1,546.2,112.1,546.3,113.1z M545,94c0.2,4.7,0.5,9.5,0.9,14.1
  c-2.1-2-4.4-4-6.8-6c-0.8-4-1.7-8.1-2.6-12.2C539.4,91.3,542.2,92.6,545,94z M534.6,87.6c-7.7-3.6-15.8-7.1-24-10.5
  c-3.1-7.4-6.3-15.1-9.7-23.1c8.9,1.7,18,3.6,27.1,5.8C530.4,69.3,532.6,78.6,534.6,87.6z M527.7,58.4c-9.2-2.2-18.4-4.2-27.4-5.9
  c-1.1-2.6-2.2-5.1-3.3-7.8c10.1,1.6,19.6,3.6,28.7,6C526.4,53.3,527,55.9,527.7,58.4z M524.7,46.9c0.2,0.8,0.4,1.6,0.7,2.5
  c-9.1-2.3-18.8-4.3-28.9-6c-0.3-0.7-0.6-1.3-0.8-2c-0.1-0.3-0.5-0.5-0.9-0.3c-0.3,0.1-0.5,0.5-0.3,0.9c0.2,0.4,0.3,0.8,0.5,1.2
  c-4.6-0.7-9.3-1.4-14.1-2c-0.5-1.1-1-2.1-1.5-3.2C495.2,39.9,510.2,42.8,524.7,46.9z M477.8,37.8c0.5,1.1,1,2.1,1.5,3.2
  c-18.6-2.2-38.6-3.3-59.8-3.3c-1.3-0.5-2.5-1-3.8-1.5C437.6,35.1,458.3,35.6,477.8,37.8z M379.2,28.9c4.2-0.3,8.4-0.6,12.4-0.9
  c5.6,2.1,11.7,4.5,18.2,7.1c-8.8,0.6-17.8,1.4-27,2.5c-10.3-2.2-20.5-4.2-30.7-6C361.6,30.5,370.6,29.6,379.2,28.9z M347.7,32.2
  c10.4,1.7,20.8,3.7,31.3,6c-3.5,0.4-6.9,0.9-10.5,1.4c-4.1,0.3-8.3,0.6-12.4,1c-35,3.1-71.9,8.6-108.8,16.1
  c6.8-4.3,13-8.1,18.7-11.4C297.3,39.5,324,35.2,347.7,32.2z M262.3,45.9c-5.7,3.4-11.9,7.2-18.6,11.5c-3.6,0.7-7.2,1.5-10.8,2.3
  c-2.8,0.4-5.6,0.9-8.4,1.3c5.4-3.8,10.8-7.5,16.2-11C248.1,48.5,255.3,47.2,262.3,45.9z M237.3,50.6c-5.2,3.4-10.4,7-15.7,10.8
  c-3.2,0.5-6.5,1-9.7,1.5c-6.7,1-13.4,2.1-20.3,3.1c4-3.1,8.1-6.1,12.1-8.9C215.5,54.8,226.6,52.6,237.3,50.6z M188,60.3
  c4.3-0.9,8.5-1.7,12.6-2.5c-3.9,2.7-7.7,5.6-11.6,8.6c-2.6,0.4-5.3,0.8-7.9,1.2C183.4,65.2,185.7,62.7,188,60.3z M179.7,69.1
  c2.4-0.4,4.8-0.7,7.3-1.1c-1.6,1.3-3.3,2.6-4.9,4c-2.2,0.6-4.4,1.2-6.5,1.8C176.9,72.3,178.3,70.7,179.7,69.1z M179.7,74
  c-1.9,1.6-3.8,3.3-5.7,5c-4.6,4.1-9.1,8.3-13.6,12.8c4.4-5.5,9-10.9,13.6-16.2C175.9,75.1,177.8,74.6,179.7,74z M144.1,110.9
  c-7.9,10.8-15.4,21.9-22.7,33.6c-3.1,2.4-6.2,4.8-9.4,7.2C122,137.3,132.8,123.6,144.1,110.9z M105.5,161.1c1.3-2,2.6-4,4-5.9
  c3.3-2.6,6.6-5.1,9.9-7.6c-5.7,9.3-11.3,18.8-16.7,28.7c-11.7,14.3-23.1,29.3-34.3,45C79.7,201.3,92,181.7,105.5,161.1z
   M55.8,241.7c-7.7,14.6-14.8,29.7-21.6,45.7c-1.1-2.3-2.2-4.7-3.2-7C39,267,47.3,254.2,55.8,241.7z M26.2,288.1
  c1.3-2.2,2.6-4.3,3.9-6.5c1.1,2.4,2.2,4.9,3.4,7.3c-1.6,3.8-3.2,7.7-4.8,11.7C27.6,295.7,26.8,291.5,26.2,288.1z M16.9,336.5
  c3.7-11,7.5-21.4,11.5-31.5c2,8.5,4.8,18.6,9,29.6c-1.8,5.9-3.7,11.8-5.5,17.9C26.5,347.7,21.5,342.4,16.9,336.5z M29.5,365.1
  c1-3.5,2-6.9,3-10.3c7,6,14.5,11.3,22.5,16c2.4,3.8,4.9,7.7,7.6,11.5C48.5,376.1,37.3,370.3,29.5,365.1z M66.6,385.4
  c14.7,6.3,32.2,13,52.2,20c4.2,3.9,8.4,7.7,12.8,11.4c-19.7-5.7-37.6-12.1-54.6-18.7C73.3,393.9,69.8,389.7,66.6,385.4z
   M115.3,431.4c-14.3-9.6-26.3-20.2-36.4-31.2c17.1,6.6,35.2,12.9,55.1,18.6c8.5,7.1,17.3,13.8,26.3,20.1
  C143.6,436.7,128.6,434.2,115.3,431.4z M163.2,439.3c-9-6.1-17.7-12.6-26.1-19.6c19.2,5.4,39.9,10.1,62.8,13.9
  c9.9,4.2,19.6,8.2,29,12C204.9,444,182.9,441.9,163.2,439.3z M233.1,446c-8.9-3.6-18.2-7.4-27.8-11.4c4.5,0.7,9.1,1.4,13.9,2
  c13.7,3.8,27.4,7.5,41.2,11C251.2,447.1,242.1,446.6,233.1,446z M494.7,431.6C455.7,453,414.5,467,376.2,472
  c-32.5-6.1-67.4-13.7-102.6-22.4c24.4,1,48.9,1.5,72.4,1.5c29.7,0,57.8-0.8,82.1-2.3c-0.6,0.3-1.2,0.6-1.7,0.9
  c-6.4,3.1-12.9,6.4-19.6,9.8c-0.3,0.2-0.5,0.6-0.3,0.9c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.2,0,0.3-0.1c6.7-3.4,13.3-6.6,19.6-9.8
  c1.5-0.7,3-1.5,4.5-2.2c10.5-0.7,20.3-1.5,29.1-2.4c0.4,0,0.6-0.4,0.6-0.7c0-0.4-0.4-0.6-0.7-0.6c-8,0.9-16.7,1.6-25.9,2.2
  c8.1-4,16-7.9,23.6-11.9c12.4-1.3,25.1-2.8,38.1-4.4C495.7,431,495.2,431.3,494.7,431.6z M547.1,422.3c-15.2,2.3-30,4.4-44.5,6.3
  c17.3-10,33.6-21.2,47.6-32.8C549.5,405.5,548.5,414.3,547.1,422.3z M551.7,392.8c0.6-9.2,1-19,1.2-29.3c4.8-5.8,9.4-12,14-18.7
  c1,10,2,20.3,3.2,30.9C564.6,381.4,558.5,387.1,551.7,392.8z M571.3,374.5c-1.2-10.7-2.2-21.1-3.2-31.3c0.9-1.3,1.7-2.6,2.6-3.9
  c1.6,10.1,3,20.5,4.4,31.1C573.8,371.7,572.6,373.1,571.3,374.5z M581.5,362.5c-1.7,2.2-3.5,4.4-5.4,6.6
  c-1.4-10.7-2.9-21.2-4.5-31.4c2.4-3.7,4.7-7.5,7-11.5C579.7,338.1,580.7,350.3,581.5,362.5z M579.5,321.9
  c-3-32.1-6.9-62.8-10.7-88.6c8,24.8,14.6,48.2,19.9,71.2C585.7,310.6,582.6,316.4,579.5,321.9z"
              />
            </g>
            <g
              title="Actin filaments"
              className={locations.includes('Actin filaments') ? 'actin_filaments' : 'actin_filaments--disabled'}
            >
              <title>Actin filaments</title>
              <path
                d="M590,304.7c1.9-3.8,3.7-7.7,5.5-11.7
  c0.1-0.3,0-0.7-0.3-0.9c-0.3-0.2-0.7,0-0.9,0.3c-1.6,3.5-3.2,6.9-4.8,10.3c-5.7-24.5-12.9-49.9-22-77.4
  c-2.4-15.3-4.8-28.5-6.9-38.8c0.9-6.5,1.9-13,3-19.4c0.1-0.4-0.2-0.7-0.5-0.8c-0.3-0.1-0.7,0.2-0.8,0.5c-0.9,5.3-1.8,10.6-2.5,16
  c-0.3-1.6-0.7-3.1-1-4.5c-5.1-21.4-9.3-46-11.3-66.8c1.5,1.5,3,3.1,4.3,4.6c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.4-0.2
  c0.3-0.2,0.3-0.6,0.1-0.9c-1.7-1.9-3.5-3.9-5.5-5.8c-0.5-5.2-0.8-10.2-1-14.8c2.7,1.4,5.5,2.7,8.1,4.1c0.1,0.1,0.2,0.1,0.3,0.1
  c0.2,0,0.5-0.1,0.6-0.3c0.2-0.3,0-0.7-0.3-0.9c-2.9-1.5-5.8-3-8.8-4.5c-0.3-11.5,0.4-20.6,2.6-25.3c0.2-0.3,0-0.7-0.3-0.9
  c-0.3-0.2-0.7,0-0.9,0.3c-2.4,5.2-3,14.9-2.8,25.2c-2.9-1.4-5.8-2.9-8.8-4.3c-2-9.2-4.2-18.5-6.6-28.1c7.2,1.8,14.4,3.7,21.7,5.8
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-7.5-2.2-15-4.1-22.4-5.9c-0.6-2.5-1.3-5-2-7.6c6.2,1.7,12.2,3.5,18,5.5
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-6-2.1-12.2-4-18.8-5.7c-0.2-0.8-0.4-1.6-0.6-2.4c2.2,0.6,4.5,1.3,6.7,2
  c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-2.5-0.8-4.9-1.5-7.4-2.2c-0.3-1-0.5-2-0.8-3c-0.1-0.3-0.4-0.6-0.8-0.5
  c-0.3,0.1-0.6,0.4-0.5,0.8c0.2,0.8,0.4,1.5,0.6,2.2c-14.5-4-29.7-7-45.6-8.8c-0.4-0.8-0.7-1.6-1.1-2.4c-0.2-0.3-0.5-0.5-0.9-0.3
  c-0.3,0.2-0.5,0.5-0.3,0.9c0.3,0.5,0.5,1.1,0.8,1.6c-20.2-2.2-41.6-2.7-64.3-1.4c-6.4-2.6-12.5-5-18.1-7.1
  c43.8-2.3,81.4,1.4,126,12.1c0.3,0.1,0.7-0.1,0.8-0.5c0.1-0.3-0.1-0.7-0.5-0.8c-45.7-10.9-84.1-14.5-129.3-11.9
  c-6.1-2.4-11.6-4.4-16.1-6.1c-0.3-0.1-0.7,0-0.8,0.4c-0.1,0.3,0,0.7,0.4,0.8c3.9,1.5,8.4,3.2,13.4,5.1c-3.2,0.2-6.4,0.4-9.6,0.7
  c-10,0.8-20.4,1.9-31.4,3.3c-7.4-1.2-14.7-2.3-21.9-3.3c-0.4,0-0.7,0.2-0.7,0.6c0,0.4,0.2,0.7,0.6,0.7c5.8,0.8,11.6,1.6,17.5,2.6
  c-21.8,2.8-46,6.7-73.9,11.8c22.4-13,36.4-19,44.9-21.7c0.3-0.1,0.5-0.5,0.4-0.8c-0.1-0.3-0.5-0.5-0.8-0.4
  c-8.9,2.8-23.9,9.3-48.2,23.6c-7.1,1.3-14.4,2.7-22,4.1c14.3-9.1,28.7-16.9,42.9-23.5c0.3-0.1,0.5-0.5,0.3-0.9
  c-0.1-0.3-0.5-0.5-0.9-0.3c-15.2,7-30.5,15.4-45.8,25.3c-10.7,2-21.9,4.2-33.7,6.6c17.4-11.7,34.8-20.3,51.9-25.7
  c0.3-0.1,0.5-0.5,0.4-0.8c-0.1-0.3-0.5-0.5-0.8-0.4c-18.1,5.7-36.4,14.9-54.8,27.5c-4.5,0.9-9.1,1.8-13.8,2.7
  c4.4-4.6,8.9-9,13.4-13.4c0.3-0.2,0.3-0.7,0-0.9c-0.2-0.3-0.7-0.3-0.9,0c-5,4.8-9.9,9.8-14.7,14.8c-6.9,1.4-13.9,2.8-21.1,4.3
  c-0.4,0.1-0.6,0.4-0.5,0.8c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c6.6-1.3,13-2.6,19.3-3.9c-2.2,2.4-4.5,4.8-6.7,7.2
  c-6.9,1-13.8,2.1-20.9,3.1c-0.4,0.1-0.6,0.4-0.5,0.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0.1,0,0.1,0c6.5-0.9,12.9-1.9,19.3-2.9
  c-1.5,1.7-3,3.3-4.5,5c-7.5,2.1-15,4.3-22.4,6.6c-0.3,0.1-0.5,0.5-0.4,0.8c0.1,0.3,0.3,0.5,0.6,0.5c0.1,0,0.1,0,0.2,0
  c6.8-2.1,13.6-4.1,20.5-6c-5.8,6.7-11.5,13.6-17,20.6c-6.6,2.3-13.3,4.5-19.9,6.8c-0.3,0.1-0.5,0.5-0.4,0.8
  c0.1,0.3,0.3,0.4,0.6,0.4c0.1,0,0.1,0,0.2,0c6-2.1,12-4.1,18-6.2c-0.4,0.6-0.9,1.1-1.3,1.7c-15.4,16.2-30.1,34.4-43.3,53.9
  c-5.5,4.3-11.2,8.7-17,13.2c-0.3,0.2-0.3,0.6-0.1,0.9c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1c4.7-3.7,9.3-7.3,13.9-10.8
  c-0.6,0.9-1.2,1.8-1.8,2.7c-16,24.2-30.2,47.2-43.1,70.9c-10.6,15.3-20.9,31.1-31,47.6c-0.7-1.7-1.5-3.4-2.2-5.1
  c-0.1-0.3-0.5-0.5-0.9-0.3c-0.3,0.1-0.5,0.5-0.3,0.9c0.8,2,1.7,4,2.6,5.9c-1.2,2-2.4,4-3.7,6c-0.2-1.3-0.5-2.4-0.7-3.4
  c-0.1-0.4-0.4-0.6-0.8-0.5c-0.4,0.1-0.6,0.4-0.5,0.8c0.3,1.3,0.6,2.9,0.9,4.8c-2.4,4-4.8,8-7.1,12c-0.2,0.3-0.1,0.7,0.2,0.9
  c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3c2.1-3.6,4.2-7.2,6.3-10.8c0.7,3.5,1.5,7.9,2.7,12.9c-4.1,10.4-8.1,21.2-11.9,32.5
  c-1.1-1.5-2.3-3-3.4-4.6c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c1.3,1.9,2.6,3.6,3.9,5.4c-0.3,1-0.7,2-1,3
  c-0.1,0.3,0.1,0.7,0.4,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.6-0.4c0.2-0.7,0.5-1.5,0.7-2.2c4.7,5.9,9.7,11.2,15.1,15.9
  c-1,3.4-2,6.9-3.1,10.4c-1.7-1.1-3.2-2.3-4.5-3.3c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9c1.4,1.2,3.1,2.4,4.9,3.6
  c-1.8,6.3-3.6,12.7-5.4,19.2c-0.1,0.3,0.1,0.7,0.5,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.6-0.5c1.8-6.4,3.5-12.6,5.3-18.8
  c8.2,5.4,20.1,11.5,35,18c3.1,4.3,6.5,8.5,10.2,12.7c-5.6-2.2-11.2-4.4-16.6-6.7c-0.3-0.1-0.7,0-0.8,0.4c-0.1,0.3,0,0.7,0.4,0.8
  c6.2,2.6,12.5,5.1,19,7.6c9.8,11,21.6,21.7,35.7,31.5c-29.5-6.4-49.8-14.3-59.5-23.2c-0.3-0.2-0.7-0.2-0.9,0
  c-0.2,0.3-0.2,0.7,0,0.9c10.7,9.9,33.4,17.9,63.1,24.1c4.6,3.1,9.4,6.1,14.5,8.9c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3
  c0.2-0.3,0.1-0.7-0.2-0.9c-4.1-2.3-8-4.7-11.7-7.1c13.6,2.8,28.6,5.2,44.6,7.3c7.8,5.3,15.8,10.3,24,15c0.1,0.1,0.2,0.1,0.3,0.1
  c0.2,0,0.4-0.1,0.6-0.3c0.2-0.3,0.1-0.7-0.2-0.9c-7.4-4.2-14.6-8.7-21.7-13.5c21.2,2.7,44,4.8,67.4,6.4
  c32.7,13.1,60.6,22.6,78,23.3c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.7-0.6-0.7c-16.5-0.7-42.9-9.5-73.8-21.7
  c9.9,0.6,19.9,1.2,29.9,1.7c35.8,9,71.5,16.9,104.8,23.2c-7.4,0.8-14.8,1.3-21.9,1.5c-0.4,0-0.6,0.3-0.6,0.7c0,0.4,0.3,0.6,0.6,0.6
  c0,0,0,0,0,0c8.5-0.2,17.2-0.8,26.1-2c4.3,0.8,8.5,1.6,12.8,2.3c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.6-0.5c0.1-0.4-0.2-0.7-0.5-0.8
  c-2.9-0.5-5.9-1.1-8.9-1.6c37.3-5.4,77.2-19.2,115.1-40.1c1.4-0.8,2.9-1.6,4.3-2.4c15.4-2,31.1-4.2,47.3-6.6
  c-0.2,1-0.4,1.9-0.5,2.9c-0.1,0.4,0.2,0.7,0.5,0.8c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.6-0.5c0.2-1.1,0.4-2.2,0.6-3.3
  c7-1.1,14.1-2.2,21.3-3.3c0.4-0.1,0.6-0.4,0.5-0.7c-0.1-0.4-0.4-0.6-0.7-0.5c-7,1.1-14,2.2-20.9,3.3c1.4-8.3,2.4-17.5,3.1-27.4
  c0.1-0.1,0.3-0.2,0.4-0.4c6.7-5.7,12.9-11.3,18.2-16.9c1.4,12.7,3,25.9,4.7,39.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0.1,0,0.1,0
  c0.4,0,0.6-0.4,0.6-0.7c-1.8-14.2-3.4-27.7-4.9-40.7c1.3-1.4,2.6-2.7,3.8-4.1c1.5,11.9,2.9,24,4.1,36.5c0,0.3,0.3,0.6,0.6,0.6
  c0,0,0,0,0.1,0c0.4,0,0.6-0.4,0.6-0.7c-1.3-12.9-2.7-25.4-4.3-37.6c1.9-2.2,3.7-4.3,5.3-6.4c0.9,13.2,1.6,26.4,2,39.3
  c0,0.4,0.3,0.6,0.6,0.6c0,0,0,0,0,0c0.4,0,0.6-0.3,0.6-0.7c-0.5-13.4-1.2-27.1-2.1-40.8c1.8-2.4,3.5-4.8,5-7.2
  c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2c-1.2,1.9-2.6,3.9-4.1,5.9c-0.8-12.3-1.9-24.6-3-36.7c3.2-5.6,6.3-11.5,9.4-17.7
  c3.1,13.6,5.7,27.1,7.9,40.6c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c0.4-0.1,0.6-0.4,0.5-0.7C596.1,333,593.3,319,590,304.7z
   M565,217.7c-0.6-1.8-1.2-3.6-1.8-5.4c-1.4-4-2.8-8.1-4.3-12.2c0.4-3.2,0.8-6.5,1.2-9.7C561.7,198.2,563.3,207.4,565,217.7z
   M457.7,433.9c-80.9,8.5-150.1,10.1-209.6,4.6c-9.9-0.9-19.5-2-28.7-3.2c-13.2-3.7-26.2-7.4-39-11.3c-2.3-1-4.6-1.9-6.8-2.9
  c-11.7-5-23.8-10.2-35.8-15.2c-8.3-3.5-16.3-6.7-24.2-9.8c-1.5-0.6-2.9-1.2-4.4-1.8c-5.4-5.4-10.6-11-15.7-16.8
  c-18.2-20.6-34-42.9-47.1-66.5c13.3-39.5,27.4-74.2,43-106.2c4.7-9.6,9.5-18.8,14.4-27.9c14-17.1,28.3-33.2,42.9-48.3
  c2.2-2.3,4.4-4.5,6.7-6.8c22.8-17,42.7-31.1,60-43C251,67,288.7,56.4,325,48.7c27.5-2.7,54.9-4.3,82.6-3.9
  c25,6.3,49.8,13.8,73.3,22.3c4.5,2.3,8.9,4.6,13.2,7c21.6,49.9,42.8,107.8,59.5,178.3c-1.1,25-1.3,49.6-1.5,72.7
  c-0.1,13.3-0.2,26-0.5,38C524.5,395.8,493.8,415.2,457.7,433.9z M551.6,365.2c-0.3,10.2-0.6,19.8-1.2,28.9
  c-14.8,12.3-32.2,24.3-51.1,35c-12.9,1.7-25.6,3.2-38,4.5C495.8,415.4,525.4,396.4,551.6,365.2z M454.5,435.5
  c-7.6,3.9-15.4,7.8-23.4,11.8c-46.6,3-106.7,3.2-164.2,0.6c-12.9-3.3-25.8-6.6-38.7-10.2c6.4,0.8,13,1.5,19.8,2.1
  c23.3,2.1,48,3.2,74.3,3.2C362.5,443,406.5,440.5,454.5,435.5z M38.7,334.6c2.3-7.4,4.7-14.7,7.1-21.8
  C58.9,336,74.5,358,92.5,378.4c4.5,5,9,9.9,13.7,14.7c-18.1-7.3-35-14.4-50.2-23.3C48.5,357.7,42.9,345.6,38.7,334.6z M53.6,368.5
  c-7.3-4.5-14.3-9.4-20.7-15c1.7-5.7,3.4-11.4,5.2-16.9C42,346.6,47.1,357.5,53.6,368.5z M44.9,311.2c-2.3,7-4.7,14.1-6.9,21.4
  c-4.1-11.2-6.9-21.3-8.8-29.7c1.7-4.2,3.3-8.3,5-12.4C37.6,297.5,41.1,304.4,44.9,311.2z M34.9,288.9c8.5-20.1,17.6-38.8,27.5-57
  c12-17.3,24.3-33.8,36.9-49.5c-3.8,7.1-7.5,14.4-11.2,21.8C72.7,236,58.7,270.4,45.5,309.5C41.7,302.8,38.2,295.9,34.9,288.9z
   M108.4,395.4c2.6,2.7,5.3,5.3,8,7.8c-19.8-7-37-13.6-51.4-19.9c-2.7-3.7-5.2-7.4-7.6-11.1C72.9,381,90.1,388.1,108.4,395.4z
   M111.6,396.7c0.5,0.2,1,0.4,1.6,0.6c7.8,3.1,15.9,6.3,24.1,9.8c10.5,4.4,21.1,8.9,31.5,13.3c-0.7-0.2-1.4-0.4-2.1-0.6
  c-16.9-5.2-32.6-10.4-47.1-15.5C116.8,401.8,114.2,399.3,111.6,396.7z M166.2,421c4.5,1.4,9.1,2.8,13.7,4.2
  c4.9,2.1,9.8,4.2,14.7,6.3c-21.8-3.8-41.6-8.5-60-13.8c-4.3-3.6-8.6-7.4-12.8-11.2C135.6,411.3,150.4,416.1,166.2,421z M191,428.5
  c6.2,1.8,12.4,3.6,18.7,5.4c-3.2-0.5-6.3-1-9.5-1.5C197.2,431.1,194.1,429.8,191,428.5z M555.6,221.4c-0.7,8.7-1.3,17.5-1.7,26.2
  c-16.4-67.8-37-124-57.9-172.5c5.6,3.1,11.1,6.4,16.4,9.6c14.9,35.9,27.8,68.1,38.7,97.9C552.8,195.6,554.2,208.5,555.6,221.4z
   M553.1,188c1.5,4.2,3,8.3,4.5,12.4c-0.5,4.7-1,9.4-1.4,14.1C555.2,205.7,554.2,196.9,553.1,188z M556.3,228.8
  c1.6,16.4,3.1,32.9,4.6,49.9c-1.9-9-3.9-17.9-5.9-26.5C555.3,244.4,555.7,236.6,556.3,228.8z M514.3,85.9
  c8.7,5.5,16.7,11.1,23.6,16.9c5.3,25.7,9.2,49.9,12.4,73.8C540.7,150.9,529.2,121.8,514.3,85.9z M513.4,83.9
  c-0.7-1.7-1.4-3.4-2.1-5.1c8.1,3.4,16.1,6.9,23.7,10.4c0.9,3.9,1.7,7.8,2.5,11.6C530.4,95,522.2,89.4,513.4,83.9z M511.5,82.7
  c-5.3-3.2-10.8-6.4-16.4-9.5c-0.1-0.3-0.3-0.7-0.4-1c5,1.9,10,3.9,14.9,5.9C510.2,79.6,510.9,81.2,511.5,82.7z M439.4,46.1
  c14.6,1,29.4,2.6,44.5,5c2.8,6.1,5.6,12.4,8.4,18.8c-3.6-1.4-7.2-2.7-10.9-4C467.5,58.7,453.2,52.1,439.4,46.1z M471.8,62.5
  c-19-6.5-38.6-12.4-58.5-17.6c7.4,0.2,14.8,0.5,22.3,0.9C447.3,50.9,459.7,56.5,471.8,62.5z M493,71.5c0.1,0.2,0.2,0.4,0.3,0.6
  c-1-0.6-2.1-1.1-3.1-1.7C491.1,70.8,492.1,71.2,493,71.5z M494,70.5c-2.8-6.5-5.7-12.9-8.5-19.2c4.6,0.7,9.3,1.5,13.9,2.4
  c3.3,7.7,6.4,15.3,9.5,22.7C504,74.4,499,72.4,494,70.5z M484.8,49.9c-1.1-2.5-2.2-4.9-3.4-7.3c4.8,0.6,9.5,1.2,14,2
  c1.1,2.6,2.2,5.2,3.3,7.7C494.1,51.4,489.4,50.6,484.8,49.9z M483.3,49.7c-16.1-2.5-31.9-4.1-47.4-5.1c-4.5-1.9-8.9-3.8-13.2-5.6
  c20.3,0.1,39.5,1.3,57.3,3.4C481.1,44.8,482.2,47.2,483.3,49.7z M432,44.4c-8.1-0.5-16.2-0.7-24.2-0.8c-5.5-1.4-10.9-2.7-16.4-3.9
  c9.5-0.4,18.8-0.6,27.8-0.6C423.4,40.7,427.7,42.5,432,44.4z M387.7,38.4c8.5-0.9,16.8-1.6,24.9-2.1c1.1,0.5,2.3,0.9,3.4,1.4
  C406.8,37.8,397.4,38,387.7,38.4z M386.5,39.8c5.2,1.2,10.4,2.4,15.7,3.7c-21.9-0.1-43.6,1-65.4,2.9c4.4-0.9,8.7-1.7,13.1-2.4
  c6.4-1.1,12.6-2.1,18.8-3C374.7,40.4,380.6,40.1,386.5,39.8z M349.6,42.6c-8.2,1.4-16.4,3.1-24.8,4.8c-22.5,2.2-45.2,5.3-68.3,8.7
  c32.1-6.3,64.2-10.9,94.8-13.8C350.8,42.4,350.2,42.5,349.6,42.6z M313.4,49.9c-31.5,7.1-63.9,16.3-96.3,26.4
  c9.3-6.3,17.8-11.9,25.7-16.9C266.8,55.8,290.2,52.5,313.4,49.9z M239.7,59.9c-8.2,5.2-17.1,11.1-26.8,17.8
  c-5.8,1.8-11.6,3.7-17.3,5.5c8.3-6.9,16.7-13.4,25-19.5c4.2-1,8.4-1.9,12.6-2.8C235.3,60.6,237.5,60.3,239.7,59.9z M209.2,80.2
  c-14.6,10-31,21.7-49.4,35.3c11-10.7,22-20.7,33-30C198.3,83.7,203.7,82,209.2,80.2z M217.4,64.5c-8.4,6.2-16.8,12.8-25.2,19.9
  c-11.3,3.7-22.6,7.5-33.8,11.3c5.4-5.5,10.9-10.7,16.5-15.6c2.6-2.3,5.2-4.6,7.9-6.8C194.2,70.1,205.7,67.2,217.4,64.5z
   M185.1,71.2c1.5-1.2,3-2.4,4.5-3.6c7.6-1.2,15.1-2.3,22.6-3.5c0.8-0.1,1.6-0.3,2.5-0.4C204.7,66.1,194.9,68.6,185.1,71.2z
   M156.3,97.7c11-3.8,22.1-7.5,33.2-11.1c-12.4,10.5-24.7,22-37,34.3c-8.9,6.7-18.3,13.7-28.2,21.3c9.1-14.3,18.6-27.9,28.6-40.9
  C154,100.1,155.2,98.9,156.3,97.7z M146.5,127c-0.2,0.2-0.5,0.5-0.7,0.7c-13.1,13.5-26,27.9-38.5,43c4.9-8.7,9.9-17.2,15.1-25.4
  C130.7,138.9,138.8,132.8,146.5,127z M553.4,325c0.2-21.7,0.4-44.7,1.3-68c2.5,10.7,4.9,21.7,7.1,33c1.5,17.2,3,34.7,4.8,52.9
  c-4.5,6.6-9.1,12.8-13.7,18.6C553.2,349.9,553.3,337.7,553.4,325z M564.2,302.3c2.1,11.4,4.2,23.1,6,35.1c-0.8,1.3-1.6,2.5-2.5,3.8
  C566.5,327.8,565.4,314.9,564.2,302.3z M563.1,289.8c-0.2-2-0.4-4-0.5-6.1c-1.9-21.3-3.7-41.9-5.7-62.3c0.5-6.1,1.1-12.1,1.7-18.2
  c2.7,7.7,5.3,15.2,7.7,22.6c4.3,27.7,8.7,62,12.1,98.1c-2.3,4.1-4.7,8-7.1,11.8C568.8,319.8,566.1,304.5,563.1,289.8z M546.3,113.1
  c2,20.3,6.1,44.2,11.2,65.6c0.6,2.4,1.2,5,1.8,7.9c-0.5,3.6-0.9,7.2-1.4,10.8c-1.8-5-3.6-10.1-5.6-15.3
  c-3.2-25.2-7.3-50.8-12.8-77.9c2.3,2,4.5,3.9,6.5,5.9C546.2,111.1,546.2,112.1,546.3,113.1z M545,94c0.2,4.7,0.5,9.5,0.9,14.1
  c-2.1-2-4.4-4-6.8-6c-0.8-4-1.7-8.1-2.6-12.2C539.4,91.3,542.2,92.6,545,94z M534.6,87.6c-7.7-3.6-15.8-7.1-24-10.5
  c-3.1-7.4-6.3-15.1-9.7-23.1c8.9,1.7,18,3.6,27.1,5.8C530.4,69.3,532.6,78.6,534.6,87.6z M527.7,58.4c-9.2-2.2-18.4-4.2-27.4-5.9
  c-1.1-2.6-2.2-5.1-3.3-7.8c10.1,1.6,19.6,3.6,28.7,6C526.4,53.3,527,55.9,527.7,58.4z M524.7,46.9c0.2,0.8,0.4,1.6,0.7,2.5
  c-9.1-2.3-18.8-4.3-28.9-6c-0.3-0.7-0.6-1.3-0.8-2c-0.1-0.3-0.5-0.5-0.9-0.3c-0.3,0.1-0.5,0.5-0.3,0.9c0.2,0.4,0.3,0.8,0.5,1.2
  c-4.6-0.7-9.3-1.4-14.1-2c-0.5-1.1-1-2.1-1.5-3.2C495.2,39.9,510.2,42.8,524.7,46.9z M477.8,37.8c0.5,1.1,1,2.1,1.5,3.2
  c-18.6-2.2-38.6-3.3-59.8-3.3c-1.3-0.5-2.5-1-3.8-1.5C437.6,35.1,458.3,35.6,477.8,37.8z M379.2,28.9c4.2-0.3,8.4-0.6,12.4-0.9
  c5.6,2.1,11.7,4.5,18.2,7.1c-8.8,0.6-17.8,1.4-27,2.5c-10.3-2.2-20.5-4.2-30.7-6C361.6,30.5,370.6,29.6,379.2,28.9z M347.7,32.2
  c10.4,1.7,20.8,3.7,31.3,6c-3.5,0.4-6.9,0.9-10.5,1.4c-4.1,0.3-8.3,0.6-12.4,1c-35,3.1-71.9,8.6-108.8,16.1
  c6.8-4.3,13-8.1,18.7-11.4C297.3,39.5,324,35.2,347.7,32.2z M262.3,45.9c-5.7,3.4-11.9,7.2-18.6,11.5c-3.6,0.7-7.2,1.5-10.8,2.3
  c-2.8,0.4-5.6,0.9-8.4,1.3c5.4-3.8,10.8-7.5,16.2-11C248.1,48.5,255.3,47.2,262.3,45.9z M237.3,50.6c-5.2,3.4-10.4,7-15.7,10.8
  c-3.2,0.5-6.5,1-9.7,1.5c-6.7,1-13.4,2.1-20.3,3.1c4-3.1,8.1-6.1,12.1-8.9C215.5,54.8,226.6,52.6,237.3,50.6z M188,60.3
  c4.3-0.9,8.5-1.7,12.6-2.5c-3.9,2.7-7.7,5.6-11.6,8.6c-2.6,0.4-5.3,0.8-7.9,1.2C183.4,65.2,185.7,62.7,188,60.3z M179.7,69.1
  c2.4-0.4,4.8-0.7,7.3-1.1c-1.6,1.3-3.3,2.6-4.9,4c-2.2,0.6-4.4,1.2-6.5,1.8C176.9,72.3,178.3,70.7,179.7,69.1z M179.7,74
  c-1.9,1.6-3.8,3.3-5.7,5c-4.6,4.1-9.1,8.3-13.6,12.8c4.4-5.5,9-10.9,13.6-16.2C175.9,75.1,177.8,74.6,179.7,74z M144.1,110.9
  c-7.9,10.8-15.4,21.9-22.7,33.6c-3.1,2.4-6.2,4.8-9.4,7.2C122,137.3,132.8,123.6,144.1,110.9z M105.5,161.1c1.3-2,2.6-4,4-5.9
  c3.3-2.6,6.6-5.1,9.9-7.6c-5.7,9.3-11.3,18.8-16.7,28.7c-11.7,14.3-23.1,29.3-34.3,45C79.7,201.3,92,181.7,105.5,161.1z
   M55.8,241.7c-7.7,14.6-14.8,29.7-21.6,45.7c-1.1-2.3-2.2-4.7-3.2-7C39,267,47.3,254.2,55.8,241.7z M26.2,288.1
  c1.3-2.2,2.6-4.3,3.9-6.5c1.1,2.4,2.2,4.9,3.4,7.3c-1.6,3.8-3.2,7.7-4.8,11.7C27.6,295.7,26.8,291.5,26.2,288.1z M16.9,336.5
  c3.7-11,7.5-21.4,11.5-31.5c2,8.5,4.8,18.6,9,29.6c-1.8,5.9-3.7,11.8-5.5,17.9C26.5,347.7,21.5,342.4,16.9,336.5z M29.5,365.1
  c1-3.5,2-6.9,3-10.3c7,6,14.5,11.3,22.5,16c2.4,3.8,4.9,7.7,7.6,11.5C48.5,376.1,37.3,370.3,29.5,365.1z M66.6,385.4
  c14.7,6.3,32.2,13,52.2,20c4.2,3.9,8.4,7.7,12.8,11.4c-19.7-5.7-37.6-12.1-54.6-18.7C73.3,393.9,69.8,389.7,66.6,385.4z
   M115.3,431.4c-14.3-9.6-26.3-20.2-36.4-31.2c17.1,6.6,35.2,12.9,55.1,18.6c8.5,7.1,17.3,13.8,26.3,20.1
  C143.6,436.7,128.6,434.2,115.3,431.4z M163.2,439.3c-9-6.1-17.7-12.6-26.1-19.6c19.2,5.4,39.9,10.1,62.8,13.9
  c9.9,4.2,19.6,8.2,29,12C204.9,444,182.9,441.9,163.2,439.3z M233.1,446c-8.9-3.6-18.2-7.4-27.8-11.4c4.5,0.7,9.1,1.4,13.9,2
  c13.7,3.8,27.4,7.5,41.2,11C251.2,447.1,242.1,446.6,233.1,446z M494.7,431.6C455.7,453,414.5,467,376.2,472
  c-32.5-6.1-67.4-13.7-102.6-22.4c24.4,1,48.9,1.5,72.4,1.5c29.7,0,57.8-0.8,82.1-2.3c-0.6,0.3-1.2,0.6-1.7,0.9
  c-6.4,3.1-12.9,6.4-19.6,9.8c-0.3,0.2-0.5,0.6-0.3,0.9c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.2,0,0.3-0.1c6.7-3.4,13.3-6.6,19.6-9.8
  c1.5-0.7,3-1.5,4.5-2.2c10.5-0.7,20.3-1.5,29.1-2.4c0.4,0,0.6-0.4,0.6-0.7c0-0.4-0.4-0.6-0.7-0.6c-8,0.9-16.7,1.6-25.9,2.2
  c8.1-4,16-7.9,23.6-11.9c12.4-1.3,25.1-2.8,38.1-4.4C495.7,431,495.2,431.3,494.7,431.6z M547.1,422.3c-15.2,2.3-30,4.4-44.5,6.3
  c17.3-10,33.6-21.2,47.6-32.8C549.5,405.5,548.5,414.3,547.1,422.3z M551.7,392.8c0.6-9.2,1-19,1.2-29.3c4.8-5.8,9.4-12,14-18.7
  c1,10,2,20.3,3.2,30.9C564.6,381.4,558.5,387.1,551.7,392.8z M571.3,374.5c-1.2-10.7-2.2-21.1-3.2-31.3c0.9-1.3,1.7-2.6,2.6-3.9
  c1.6,10.1,3,20.5,4.4,31.1C573.8,371.7,572.6,373.1,571.3,374.5z M581.5,362.5c-1.7,2.2-3.5,4.4-5.4,6.6
  c-1.4-10.7-2.9-21.2-4.5-31.4c2.4-3.7,4.7-7.5,7-11.5C579.7,338.1,580.7,350.3,581.5,362.5z M579.5,321.9
  c-3-32.1-6.9-62.8-10.7-88.6c8,24.8,14.6,48.2,19.9,71.2C585.7,310.6,582.6,316.4,579.5,321.9z"
              />
            </g>
            <g title="Focal adhesion sites" className="focal_adhesion_sites_ton">
              <title>Focal adhesion sites</title>
              <path
                d="M134.5,443.8c-0.2,0.2-0.5,0.2-0.8,0.2
  c-1.4-0.2-2.8-1.1-3.9-1.8c-0.3-0.2-0.7-0.4-1-0.6c-0.4-0.3-1.2-0.8-0.9-1.4c0.2-0.4,0.9-0.4,1.3-0.3c1.1,0.3,2.3,0.5,3.4,0.7
  c0.9,0.1,2.2,0.1,2.7,1c0.2,0.4,0.2,0.9,0,1.3C135.2,443.2,134.9,443.6,134.5,443.8z"
              />
              <path
                d="M191.2,458.6c-1.2,0-2.3-1.2-3.3-1.8
  c-0.7-0.4-1.7-0.8-2.1-1.6c-0.4-0.6,0.2-1.1,0.8-1.1c0.9-0.1,1.8,0.3,2.6,0.5c0.6,0.1,1.2,0.2,1.8,0.3c0.8,0.2,2.4,0.6,2.2,1.7
  C193,457.4,192.1,458.5,191.2,458.6z"
              />
              <path
                d="M313.4,472.3c-0.6-0.1-1.1-0.2-1.7-0.4
  c-0.9-0.3-2.2-0.7-2.8-1.5c-0.3-0.4,0-1,0.3-1.2c1-0.6,2.7-0.6,3.8-0.6c1,0,2.3-0.1,2.8,1c0.3,0.9,0,2.1-0.9,2.5
  C314.4,472.3,313.9,472.4,313.4,472.3z"
              />
              <path
                d="M351.8,475.4c-0.9,0.4-2.3,0.4-2.9,0.6
  c-1,0.3-2.2,0.4-3.3,0.3c-1.1-0.2-1.9-1.4-1.3-2.5c0.3-0.6,1.2-0.8,1.8-0.7c1.9,0.1,4,0.2,5.9,0.7c0.4,0.1,0.4,0.4,0.4,0.8
  C352.5,474.9,352.2,475.2,351.8,475.4z"
              />
              <path
                d="M410.6,459.2c-0.6,0.7-1.9,1.4-2.3,1.8
  c-0.8,0.7-1.8,1.3-2.8,1.7c-1.1,0.3-2.3-0.4-2.3-1.6c0-0.7,0.7-1.2,1.3-1.4c1.8-0.7,3.7-1.6,5.6-1.9c0.4-0.1,0.6,0.2,0.7,0.5
  C411,458.5,410.8,458.9,410.6,459.2z"
              />
              <path
                d="M391.5,477.2c-1.6-0.1-3.3-0.9-4.5-1.9
  c-0.3-0.3-0.8-1-0.2-1.3c0.8-0.4,2.1,0,2.9,0c1.5,0,3.1-0.3,4.5-0.1c1,0.1,1.2,1.3,0.6,1.9C394,476.9,392.8,477.3,391.5,477.2z"
              />
              <path
                d="M459.8,446.5c-0.3-0.1-0.7-0.1-0.9-0.3
  c-0.4-0.3-0.3-0.8,0-1.1c0.4-0.4,1-0.6,1.5-0.8c1-0.3,3.8-1.8,4.2,0c0.1,0.3,0.1,0.6,0.1,0.8c0,0.7,0.1,1.6-0.7,1.9
  C462.5,447.3,461.1,446.7,459.8,446.5z"
              />
              <path
                d="M547.6,430.2c-0.1,0.1-0.3,0.2-0.4,0.3
  c-0.6,0.3-1.5,0.6-2,0c-0.9-0.9-0.3-2.5,0.1-3.4c0.3-0.8,1.1-3.1,2.3-2.8c1,0.2,1,1.9,1.1,2.7C548.7,428.3,548.7,429.4,547.6,430.2
  z"
              />
              <path
                d="M569.2,420.6c-0.4,0-0.8-0.1-1.2-0.2
  c-0.3-0.1-0.7-0.1-0.8-0.4c-0.1-0.1-0.1-0.6,0-0.7c0.5-0.3,1.1-0.5,1.6-0.8c0.7-0.3,1.4-0.6,2.2-0.8c0.6-0.2,1.3-0.5,2-0.3
  c0.8,0.3,0.9,1.4,0.8,2.1C573.7,421.5,570.4,420.8,569.2,420.6z"
              />
              <path
                d="M575.6,419.5c-0.4,0.1-0.8,0-1-0.6
  c-0.4-1.3-0.3-3-0.2-4.4c0-0.6,0-1.3,0.5-1.6c0.5-0.4,0.9,0.1,1.1,0.5c0.7,1.6,2.4,4.7,0.3,5.9
  C576.1,419.3,575.8,419.4,575.6,419.5z"
              />
              <path
                d="M578.9,412.4c-0.3-1.4-0.3-2.8,0-4.2
  c0.1-0.8,0-2.2,0.9-2.6c1.3-0.6,1.9,3.2,2.1,3.8c0.4,1.1,0.6,2.3,0.1,3.4c-0.2,0.5-0.7,1-1.2,1.3c-1,0.4-1.5-0.5-1.8-1.3
  C578.9,412.7,578.9,412.6,578.9,412.4z"
              />
              <path
                d="M585.8,408.5c-0.4,0.7-1.3,1.6-2.2,1.3
  c-0.9-0.3-0.9-1.3-0.9-2.1c0-0.8,0-1.5,0.2-2.3c0.2-0.7,0.3-1.3,0.4-2c0.1-0.5,0.2-1.3,0.7-1.5c0.6-0.3,0.9,0.9,1.1,1.3
  c0.4,1,0.7,2,0.9,3.1C586.2,407,586.2,407.8,585.8,408.5C585.9,408.5,585.8,408.5,585.8,408.5z"
              />
              <path
                d="M587.3,356.5c-0.6,0-1.2-0.5-1-1.2
  c0.2-0.8,0.4-1.6,0.7-2.4c0.3-0.7,0.4-1.5,0.9-2c0.2-0.2,0.8-0.3,1.1-0.4c0.7-0.2,2-0.4,2.5,0.3c0.5,0.6-0.1,1.5-0.5,2
  c-0.2,0.3-0.4,0.6-0.6,0.9c-0.7,1-1.5,2.1-2.5,2.7C587.8,356.4,587.5,356.5,587.3,356.5z"
              />
              <path
                d="M598,351.7c-0.1,0-0.2,0.1-0.3,0.1
  c-0.4,0.2-1.2,0.3-1.4-0.2c-0.4-0.9-0.1-2.1,0-3.1c0-1.1,0-2.2,0.4-3.3c0.1-0.2,0.5-0.4,0.7-0.5c0.3,0,0.5,0.1,0.6,0.3
  c0.7,0.9,0.8,2.2,1.1,3.3c0.2,0.7,0.6,1.5,0.3,2.2C599.2,351.2,598.5,351.5,598,351.7z"
              />
              <path
                d="M594.7,294.7c-0.1,0-0.2,0-0.3,0
  c-0.4-0.1-0.9-0.6-0.8-1c0,0,0.2-0.7,0.2-0.7c0.3-1.1,0.7-3.1,0.8-4c0.1-0.7,0.7-1.1,1.3-1.1c1,0.1,1.4,1.5,1.4,2.3
  c0,0.5-0.3,1-0.6,1.4c-0.6,0.9-1,1.8-1.6,2.6C595,294.5,594.9,294.7,594.7,294.7z"
              />
              <path
                d="M562.4,169.7c-0.2,0-0.4-0.1-0.5-0.2
  c-0.4-0.4-0.4-0.8-0.4-1.3c-0.1-1-0.1-2.1,0-3.1c0.1-0.9,0.8-1.6,1.8-1.8c1.9-0.3,1.6,1.9,1.2,3.1c-0.2,0.7-0.6,1.3-0.8,2
  c-0.1,0.4-0.2,1-0.6,1.3C563,169.8,562.7,169.8,562.4,169.7z"
              />
              <path
                d="M555.2,119.9c-0.3,0.2-0.8,0.5-1.1,0.5
  c-0.6,0-0.8-0.8-1.1-1.2c-0.4-0.9-0.9-1.7-1.4-2.6c-0.2-0.3-0.4-0.7-0.4-1c0-0.2,0.3-0.6,0.5-0.7c0.5-0.3,1.1,0.1,1.4,0.5
  c1,1.2,3.2,1.9,2.5,3.8C555.6,119.4,555.4,119.7,555.2,119.9z"
              />
              <path
                d="M556.5,101.4c-0.1,0-0.3,0-0.4,0
  c-0.9-0.2-1.6-0.9-2.2-1.5c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.5-0.9-0.9c-0.2-0.4-0.2-1,0.1-1.3c0.3-0.3,0.7-0.4,1.1-0.3
  c0.7,0.1,1.4,0.6,2.1,0.9c0.6,0.3,1.2,0.6,1.9,0.8c0.5,0.2,0.8,0.7,0.8,1.2C558.1,100.2,557.6,101.4,556.5,101.4z"
              />
              <path
                d="M547.6,71.7c-0.7,0.4-1.3-0.6-1.1-1.3
  c0,0,0.6-3,0.6-3c0.2-1,1.4-1.2,2.1-0.9c0.8,0.4,1.2,1.3,0.7,2.2c-0.3,0.5-0.7,0.9-1.1,1.4c-0.3,0.5-0.6,1-1,1.4
  C547.7,71.6,547.7,71.6,547.6,71.7z"
              />
              <path
                d="M552.1,66.9c-0.1,0.1-0.2,0.1-0.3,0.1
  c-0.5,0.1-0.9-0.2-1.3-0.5c-0.5-0.3-0.9-0.6-1.4-0.8c-0.9-0.5-0.5-1.6,0.4-1.7c0.5-0.1,1.1,0.1,1.7,0.2c0.4,0.1,1,0.1,1.3,0.5
  C552.9,65.2,552.8,66.4,552.1,66.9z"
              />
              <path
                d="M546.8,58.3c-0.4,0-0.7-0.3-1-0.5
  c-0.4-0.3-0.9-0.5-1.4-0.8c-0.4-0.2-0.8-0.4-1.1-0.8c-0.1-0.2-0.2-0.5-0.1-0.8c0-0.2,0.3-0.6,0.4-0.6c0.4-0.1,0.8,0,1.1,0.1
  c0.5,0.1,0.9,0.2,1.4,0.3c0.7,0.2,1.4,0.3,1.5,1.1C547.7,56.8,547.7,58.2,546.8,58.3z"
              />
              <path
                d="M524.9,45.5c-0.8,0-1.1-0.8-1.3-1.4
  c-0.2-0.7-0.8-1.7-0.3-2.4c0.5-0.6,1.4-1,2-0.4c0.3,0.3,0.3,0.9,0.3,1.3C525.6,43.2,526.1,45.5,524.9,45.5z"
              />
              <path
                d="M536.5,51c-0.2,0-0.4,0-0.6-0.1
  c-1.2-0.6-2.5-1-3.7-1.5c-0.4-0.2-1-0.4-0.9-1c0-0.3,0.4-0.8,0.7-0.9c0.7-0.2,1.4,0,2.1,0.1c1.3,0.1,3.7,0.1,3.2,2.1
  C537.2,50.4,536.9,50.9,536.5,51z"
              />
              <path
                d="M523.2,40.6c-0.6,1-1.4,0.4-2.2,0
  c-0.6-0.3-1.8-0.6-1.9-1.5c0-0.3,0.2-0.8,0.5-1c0.5-0.2,1.1-0.2,1.6-0.2c0.7,0,2,0,2.1,0.9C523.5,39.4,523.4,40.1,523.2,40.6z"
              />
              <path
                d="M495.2,42.2c-0.8,0.1-1.4-0.7-1.8-1.3
  c-0.5-0.7-1.1-1.6-1.1-2.5c0-0.3,0.2-0.8,0.5-1c0.5-0.3,1.5-0.8,2.1-0.5c0.5,0.2,0.5,0.8,0.6,1.2c0.2,0.9,0.2,1.8,0.3,2.7
  C495.8,41.3,495.9,42.1,495.2,42.2z"
              />
              <path
                d="M476.9,35.6c-0.2,0-0.5-0.2-0.7-0.4
  c-0.6-0.8-1.3-1.5-1.9-2.3c-0.3-0.5-0.8-1-1-1.6c-0.2-0.5-0.1-0.9,0.1-1.3c0.1-0.1,0.3-0.2,0.3-0.3c0.5-0.3,1-0.7,1.6-0.4
  c0.4,0.2,0.6,0.8,0.8,1.2c0.3,0.8,0.6,1.6,1,2.4c0.2,0.4,0.4,0.9,0.7,1.4C478.2,35,477.5,35.7,476.9,35.6z"
              />
              <path
                d="M376.8,22.5c-0.5,0.1-1.2,0-1.7-0.1
  c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-1.1-0.1-1.4-0.3c-1-0.6-0.4-1.8,0.3-2.4c0.2,0,0.4-0.1,0.7-0.1c0.8,0,1.7,0.6,2.4,0.9
  c0.3,0.1,0.5,0.2,0.8,0.4c0.1,0.1,0.3,0.1,0.5,0.2c0.6,0.3,0.6,1.1,0,1.5C377,22.5,376.9,22.5,376.8,22.5z"
              />
              <path
                d="M328,29.4c-0.9,0.1-1.8,0.1-2.7,0.2
  c-0.7,0-1.7,0.4-2.3-0.1c-0.4-0.3-0.2-0.9,0-1.3c0.3-0.7,0.7-1.4,1.4-1.5c1.3-0.1,2.5,0.7,3.8,1c0.6,0.1,0.8,0.6,0.7,1.1
  C328.8,29.2,328.4,29.3,328,29.4z"
              />
              <path
                d="M312.3,22.4c-0.3-0.1-0.5-0.2-0.7-0.5
  c-0.4-0.6,0.2-1.2,0.8-1.5c0.7-0.3,1.5-0.6,2.2-1c0.4-0.2,0.9-0.4,1.4-0.5c0.9-0.1,1.9,0.5,2.2,1.3c0.1,0.4,0.1,0.7-0.1,1
  c-0.3,0.6-1.4,0.5-1.9,0.6c-0.8,0.1-1.6,0.3-2.4,0.4C313.3,22.3,312.8,22.5,312.3,22.4z"
              />
              <path
                d="M284.9,25.6c-0.3,0-0.7,0-0.8-0.3
  c-0.2-0.4,0-1.1,0.4-1.3c0.2,0,0.3-0.2,0.5-0.2c1-0.4,1.9-0.9,2.9-1.3c0.5-0.2,1-0.5,1.5-0.4c0.8,0.2,1.2,1.1,1.3,1.8
  c0.3,1.4-1.8,1.3-2.7,1.4c-0.9,0.2-1.9,0.3-2.8,0.3C285.1,25.5,285,25.5,284.9,25.6z"
              />
              <path
                d="M256.1,30.4c-0.2,0-0.3-0.1-0.5-0.1
  c-0.6-0.3-0.6-1.3,0.1-1.5c1.6-0.5,3.1-1,4.7-1.4c0.3-0.1,0.5-0.1,0.8-0.1c0.8,0.1,0.7,1,0.6,1.6c-0.1,0.4-0.2,0.9-0.6,1.2
  c-0.4,0.2-1,0.2-1.4,0.2c-0.9,0-1.8,0.1-2.7,0.1C256.8,30.4,256.5,30.5,256.1,30.4z"
              />
              <path
                d="M201.4,46.6c-1.3,0.1-0.6-1.7-0.2-2.3
  c0.5-0.7,1.1-1.3,1.9-1.6c0.6-0.2,1.5-0.2,1.9,0.4c0.4,0.7-0.2,1.2-0.7,1.6c-0.5,0.4-1,0.8-1.5,1.1
  C202.3,46.2,201.9,46.5,201.4,46.6z"
              />
              <path
                d="M163.5,65.7c-0.2-0.1-0.3-0.3-0.4-0.6
  c-0.1-0.5,0.1-1.2,0.4-1.6c0.7-0.8,2.3-0.6,3.3-0.7c0.3,0,0.5-0.1,0.8-0.1c0.6-0.1,1.3,0.1,1.4,0.8c0.1,0.6-0.4,1.1-1,1.3
  c-0.8,0.3-1.5,0.5-2.3,0.8C165.1,65.8,164.1,66.1,163.5,65.7z"
              />
              <path
                d="M155.7,73.7c0,0-0.1,0-0.1-0.1
  c-0.2-0.1-0.4-0.6-0.4-0.8c-0.1-0.4,0-0.8,0.1-1.1c0.3-0.9,1.1-1,2-1.1c1.1-0.1,2.3-0.2,3.4-0.2c0.5,0,0.6,0.4,0.6,0.8
  c0,0.5-0.1,0.9-0.6,1.1c-0.7,0.3-1.4,0.5-2,0.8C157.9,73.4,156.6,74,155.7,73.7z"
              />
              <path
                d="M147.6,84.6c-0.3,0-0.5-0.2-0.7-0.4
  c-0.4-0.7-0.5-2.3,0.5-2.5c0.5-0.1,1-0.3,1.5-0.4c0.4-0.1,0.9-0.2,1.3-0.4c0.4-0.1,0.8-0.2,1.2-0.3c0.2,0,1-0.2,1.1,0.2
  c0.1,0.7-0.1,1.3-0.7,1.8c-0.6,0.5-1.4,0.9-2.1,1.3C149.2,84.1,148.3,84.7,147.6,84.6z"
              />
              <path
                d="M130.1,106.9c-0.8-0.8-0.2-2.4,0.8-2.7
  c1.6-0.4,3.3-0.7,4.9-1c0.8-0.2,1.3,0.5,0.9,1.2c-0.2,0.4-0.7,0.6-1.1,0.7c-1.1,0.5-2.1,0.9-3.2,1.5c-0.6,0.3-1.3,0.6-2,0.5
  C130.4,107,130.2,107,130.1,106.9z"
              />
              <path
                d="M89.9,171.2c-0.2-0.2-0.5-0.4-0.6-0.7
  c-0.2-0.3-0.5-0.6-0.5-0.9c0-0.4,0.4-0.8,0.6-1c0.6-0.5,1.2-0.9,1.9-1.4c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.5-0.5,0.8-0.5
  c0.4,0,0.8,0.4,0.9,0.8c0,0.9-0.7,1.5-1.2,2.2c-0.5,0.8-1,1.6-1.8,2.1C90.6,171.5,90.2,171.4,89.9,171.2z"
              />
              <path
                d="M28.2,276.6c-0.1,0-0.2-0.1-0.4-0.2
  c-0.6-0.5-0.9-1.3-1.4-1.9c-0.5-0.7-0.7-1.4-0.3-2.2c0.4-0.7,1.4-1.5,2-0.6c0.5,0.7,0.5,1.8,0.6,2.6c0,0.3,0.1,0.6,0.1,0.9
  c0.1,0.2,0.2,0.4,0.2,0.7C29.1,276.3,28.6,276.7,28.2,276.6z"
              />
              <path
                d="M24.7,285.8c-0.6-0.1-0.7-0.7-0.9-1.2
  c-0.3-0.7-0.6-1.5-0.9-2.2c-0.4-0.8-0.1-1.6,0.5-2.2c0.2-0.3,0.5-0.6,0.9-0.7c0.3-0.1,0.7,0.1,0.9,0.3c0.3,0.5,0.3,1.2,0.3,1.8
  c0.1,1,0.1,2.1,0.3,3.1c0.1,0.5-0.3,1.2-0.8,1.2C24.8,285.8,24.8,285.8,24.7,285.8z"
              />
              <path
                d="M17.2,303.5c-0.1,0-0.3,0-0.4,0
  c-0.7-0.1-1.1-0.9-0.8-1.5c0.2-0.5,0.6-0.8,0.8-1.2c0.3-0.5,0.6-0.9,1-1.3c0.2-0.2,0.8-0.6,1.1-0.6c0.4,0,0.4,0.5,0.4,0.8
  c-0.1,0.9-0.5,1.7-0.8,2.5C18.4,302.7,18,303.4,17.2,303.5z"
              />
              <path
                d="M12.7,332.9
  C12.7,332.9,12.7,332.9,12.7,332.9c-0.9-0.2-1.5-1.1-2-1.6c-0.3-0.3-0.5-0.5-0.6-0.9c-0.1-0.6,0-1.2,0.1-1.8
  c0.1-0.4,0.3-1.3,0.8-1.4c0.7-0.1,1.1,0.9,1.3,1.3c0.5,1,0.9,2.1,1.4,3.1C14,332.2,13.4,333,12.7,332.9z"
              />
              <path
                d="M16,341.7c0,0.2-0.1,0.4-0.1,0.6
  c-0.3,1.2-1.3,3.5-2.8,2.2c-0.7-0.6-1-1.7-0.6-2.5c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.4-0.5,0.5-0.7
  c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.5,0.6-1.5,1.4-1.2c0.6,0.2,0.5,1.1,0.5,1.6C16.1,340.4,16.1,341.1,16,341.7z"
              />
              <path
                d="M24.6,362.8c-0.2,0-0.3,0-0.5,0
  c-0.5-0.2-1-0.3-1.5-0.5c-1-0.5-1.9-1.1-2.9-1.7c-0.2-0.2-0.5-0.7-0.5-0.9c0-0.3,0.1-0.6,0.2-0.9c0.7-2.2,2.8,0.4,3.6,1.2
  c0.5,0.5,1.1,1.1,1.6,1.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3C25.2,362.6,25,362.8,24.6,362.8z"
              />
              <path
                d="M22.9,387.6c-0.5-0.1-0.9-0.4-1-0.9
  c-0.1-0.6,0.2-1.2,0.3-1.8c0.2-0.5,0.5-1,0.9-1.4c0.8-0.9,1.2,0.3,1.2,1.1c0,0.5-0.1,1.1-0.2,1.7c-0.1,0.5-0.2,1.1-0.7,1.3
  C23.3,387.6,23.1,387.6,22.9,387.6z"
              />
              <path
                d="M59.3,392.6c-0.1,0-0.2,0-0.4,0
  c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.3-0.4-1.8-0.7c-0.6-0.4-0.4-1.1-0.2-1.7c0.2-0.4,0.4-0.9,0.6-1.2
  c0.2-0.2,0.5-0.2,0.8-0.2c0.5,0,0.9,0.3,1.4,0.6c0.6,0.4,1.3,0.8,1.9,1.3c0.6,0.4,1.3,0.8,1.7,1.3c0.2,0.2,0.1,0.5,0,0.8
  C59.8,392.4,59.6,392.5,59.3,392.6z"
              />
              <path
                d="M53.4,410c-1.1,0-2-0.9-2.8-1.5
  c-0.6-0.5-1.3-1-1.8-1.5c-0.5-0.4-0.9-1-0.7-1.7c0.1-0.6,0.5-1.2,1.1-1.4c0.2-0.1,0.7-0.2,0.9-0.1c0.4,0.1,0.8,0.4,1,0.8
  c0.6,0.7,1,1.5,1.5,2.3c0.4,0.7,1.2,1.2,1.5,2c0.1,0.3,0.1,0.6-0.1,0.9C53.8,410,53.7,410,53.4,410z"
              />
            </g>
            <g
              title="Focal adhesion sites"
              className={locations.includes('Focal adhesion sites') ? 'focal_adhesion_sites' : 'focal_adhesion_sites--disabled'}
            >
              <title>Focal adhesion sites</title>
              <path
                d="M134.5,443.8c-0.2,0.2-0.5,0.2-0.8,0.2
  c-1.4-0.2-2.8-1.1-3.9-1.8c-0.3-0.2-0.7-0.4-1-0.6c-0.4-0.3-1.2-0.8-0.9-1.4c0.2-0.4,0.9-0.4,1.3-0.3c1.1,0.3,2.3,0.5,3.4,0.7
  c0.9,0.1,2.2,0.1,2.7,1c0.2,0.4,0.2,0.9,0,1.3C135.2,443.2,134.9,443.6,134.5,443.8z"
              />
              <path
                d="M191.2,458.6c-1.2,0-2.3-1.2-3.3-1.8
  c-0.7-0.4-1.7-0.8-2.1-1.6c-0.4-0.6,0.2-1.1,0.8-1.1c0.9-0.1,1.8,0.3,2.6,0.5c0.6,0.1,1.2,0.2,1.8,0.3c0.8,0.2,2.4,0.6,2.2,1.7
  C193,457.4,192.1,458.5,191.2,458.6z"
              />
              <path
                d="M313.4,472.3c-0.6-0.1-1.1-0.2-1.7-0.4
  c-0.9-0.3-2.2-0.7-2.8-1.5c-0.3-0.4,0-1,0.3-1.2c1-0.6,2.7-0.6,3.8-0.6c1,0,2.3-0.1,2.8,1c0.3,0.9,0,2.1-0.9,2.5
  C314.4,472.3,313.9,472.4,313.4,472.3z"
              />
              <path
                d="M351.8,475.4c-0.9,0.4-2.3,0.4-2.9,0.6
  c-1,0.3-2.2,0.4-3.3,0.3c-1.1-0.2-1.9-1.4-1.3-2.5c0.3-0.6,1.2-0.8,1.8-0.7c1.9,0.1,4,0.2,5.9,0.7c0.4,0.1,0.4,0.4,0.4,0.8
  C352.5,474.9,352.2,475.2,351.8,475.4z"
              />
              <path
                d="M410.6,459.2c-0.6,0.7-1.9,1.4-2.3,1.8
  c-0.8,0.7-1.8,1.3-2.8,1.7c-1.1,0.3-2.3-0.4-2.3-1.6c0-0.7,0.7-1.2,1.3-1.4c1.8-0.7,3.7-1.6,5.6-1.9c0.4-0.1,0.6,0.2,0.7,0.5
  C411,458.5,410.8,458.9,410.6,459.2z"
              />
              <path
                d="M391.5,477.2c-1.6-0.1-3.3-0.9-4.5-1.9
  c-0.3-0.3-0.8-1-0.2-1.3c0.8-0.4,2.1,0,2.9,0c1.5,0,3.1-0.3,4.5-0.1c1,0.1,1.2,1.3,0.6,1.9C394,476.9,392.8,477.3,391.5,477.2z"
              />
              <path
                d="M459.8,446.5c-0.3-0.1-0.7-0.1-0.9-0.3
  c-0.4-0.3-0.3-0.8,0-1.1c0.4-0.4,1-0.6,1.5-0.8c1-0.3,3.8-1.8,4.2,0c0.1,0.3,0.1,0.6,0.1,0.8c0,0.7,0.1,1.6-0.7,1.9
  C462.5,447.3,461.1,446.7,459.8,446.5z"
              />
              <path
                d="M547.6,430.2c-0.1,0.1-0.3,0.2-0.4,0.3
  c-0.6,0.3-1.5,0.6-2,0c-0.9-0.9-0.3-2.5,0.1-3.4c0.3-0.8,1.1-3.1,2.3-2.8c1,0.2,1,1.9,1.1,2.7C548.7,428.3,548.7,429.4,547.6,430.2
  z"
              />
              <path
                d="M569.2,420.6c-0.4,0-0.8-0.1-1.2-0.2
  c-0.3-0.1-0.7-0.1-0.8-0.4c-0.1-0.1-0.1-0.6,0-0.7c0.5-0.3,1.1-0.5,1.6-0.8c0.7-0.3,1.4-0.6,2.2-0.8c0.6-0.2,1.3-0.5,2-0.3
  c0.8,0.3,0.9,1.4,0.8,2.1C573.7,421.5,570.4,420.8,569.2,420.6z"
              />
              <path
                d="M575.6,419.5c-0.4,0.1-0.8,0-1-0.6
  c-0.4-1.3-0.3-3-0.2-4.4c0-0.6,0-1.3,0.5-1.6c0.5-0.4,0.9,0.1,1.1,0.5c0.7,1.6,2.4,4.7,0.3,5.9
  C576.1,419.3,575.8,419.4,575.6,419.5z"
              />
              <path
                d="M578.9,412.4c-0.3-1.4-0.3-2.8,0-4.2
  c0.1-0.8,0-2.2,0.9-2.6c1.3-0.6,1.9,3.2,2.1,3.8c0.4,1.1,0.6,2.3,0.1,3.4c-0.2,0.5-0.7,1-1.2,1.3c-1,0.4-1.5-0.5-1.8-1.3
  C578.9,412.7,578.9,412.6,578.9,412.4z"
              />
              <path
                d="M585.8,408.5c-0.4,0.7-1.3,1.6-2.2,1.3
  c-0.9-0.3-0.9-1.3-0.9-2.1c0-0.8,0-1.5,0.2-2.3c0.2-0.7,0.3-1.3,0.4-2c0.1-0.5,0.2-1.3,0.7-1.5c0.6-0.3,0.9,0.9,1.1,1.3
  c0.4,1,0.7,2,0.9,3.1C586.2,407,586.2,407.8,585.8,408.5C585.9,408.5,585.8,408.5,585.8,408.5z"
              />
              <path
                d="M587.3,356.5c-0.6,0-1.2-0.5-1-1.2
  c0.2-0.8,0.4-1.6,0.7-2.4c0.3-0.7,0.4-1.5,0.9-2c0.2-0.2,0.8-0.3,1.1-0.4c0.7-0.2,2-0.4,2.5,0.3c0.5,0.6-0.1,1.5-0.5,2
  c-0.2,0.3-0.4,0.6-0.6,0.9c-0.7,1-1.5,2.1-2.5,2.7C587.8,356.4,587.5,356.5,587.3,356.5z"
              />
              <path
                d="M598,351.7c-0.1,0-0.2,0.1-0.3,0.1
  c-0.4,0.2-1.2,0.3-1.4-0.2c-0.4-0.9-0.1-2.1,0-3.1c0-1.1,0-2.2,0.4-3.3c0.1-0.2,0.5-0.4,0.7-0.5c0.3,0,0.5,0.1,0.6,0.3
  c0.7,0.9,0.8,2.2,1.1,3.3c0.2,0.7,0.6,1.5,0.3,2.2C599.2,351.2,598.5,351.5,598,351.7z"
              />
              <path
                d="M594.7,294.7c-0.1,0-0.2,0-0.3,0
  c-0.4-0.1-0.9-0.6-0.8-1c0,0,0.2-0.7,0.2-0.7c0.3-1.1,0.7-3.1,0.8-4c0.1-0.7,0.7-1.1,1.3-1.1c1,0.1,1.4,1.5,1.4,2.3
  c0,0.5-0.3,1-0.6,1.4c-0.6,0.9-1,1.8-1.6,2.6C595,294.5,594.9,294.7,594.7,294.7z"
              />
              <path
                d="M562.4,169.7c-0.2,0-0.4-0.1-0.5-0.2
  c-0.4-0.4-0.4-0.8-0.4-1.3c-0.1-1-0.1-2.1,0-3.1c0.1-0.9,0.8-1.6,1.8-1.8c1.9-0.3,1.6,1.9,1.2,3.1c-0.2,0.7-0.6,1.3-0.8,2
  c-0.1,0.4-0.2,1-0.6,1.3C563,169.8,562.7,169.8,562.4,169.7z"
              />
              <path
                d="M555.2,119.9c-0.3,0.2-0.8,0.5-1.1,0.5
  c-0.6,0-0.8-0.8-1.1-1.2c-0.4-0.9-0.9-1.7-1.4-2.6c-0.2-0.3-0.4-0.7-0.4-1c0-0.2,0.3-0.6,0.5-0.7c0.5-0.3,1.1,0.1,1.4,0.5
  c1,1.2,3.2,1.9,2.5,3.8C555.6,119.4,555.4,119.7,555.2,119.9z"
              />
              <path
                d="M556.5,101.4c-0.1,0-0.3,0-0.4,0
  c-0.9-0.2-1.6-0.9-2.2-1.5c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.5-0.9-0.9c-0.2-0.4-0.2-1,0.1-1.3c0.3-0.3,0.7-0.4,1.1-0.3
  c0.7,0.1,1.4,0.6,2.1,0.9c0.6,0.3,1.2,0.6,1.9,0.8c0.5,0.2,0.8,0.7,0.8,1.2C558.1,100.2,557.6,101.4,556.5,101.4z"
              />
              <path
                d="M547.6,71.7c-0.7,0.4-1.3-0.6-1.1-1.3
  c0,0,0.6-3,0.6-3c0.2-1,1.4-1.2,2.1-0.9c0.8,0.4,1.2,1.3,0.7,2.2c-0.3,0.5-0.7,0.9-1.1,1.4c-0.3,0.5-0.6,1-1,1.4
  C547.7,71.6,547.7,71.6,547.6,71.7z"
              />
              <path
                d="M552.1,66.9c-0.1,0.1-0.2,0.1-0.3,0.1
  c-0.5,0.1-0.9-0.2-1.3-0.5c-0.5-0.3-0.9-0.6-1.4-0.8c-0.9-0.5-0.5-1.6,0.4-1.7c0.5-0.1,1.1,0.1,1.7,0.2c0.4,0.1,1,0.1,1.3,0.5
  C552.9,65.2,552.8,66.4,552.1,66.9z"
              />
              <path
                d="M546.8,58.3c-0.4,0-0.7-0.3-1-0.5
  c-0.4-0.3-0.9-0.5-1.4-0.8c-0.4-0.2-0.8-0.4-1.1-0.8c-0.1-0.2-0.2-0.5-0.1-0.8c0-0.2,0.3-0.6,0.4-0.6c0.4-0.1,0.8,0,1.1,0.1
  c0.5,0.1,0.9,0.2,1.4,0.3c0.7,0.2,1.4,0.3,1.5,1.1C547.7,56.8,547.7,58.2,546.8,58.3z"
              />
              <path
                d="M524.9,45.5c-0.8,0-1.1-0.8-1.3-1.4
  c-0.2-0.7-0.8-1.7-0.3-2.4c0.5-0.6,1.4-1,2-0.4c0.3,0.3,0.3,0.9,0.3,1.3C525.6,43.2,526.1,45.5,524.9,45.5z"
              />
              <path
                d="M536.5,51c-0.2,0-0.4,0-0.6-0.1
  c-1.2-0.6-2.5-1-3.7-1.5c-0.4-0.2-1-0.4-0.9-1c0-0.3,0.4-0.8,0.7-0.9c0.7-0.2,1.4,0,2.1,0.1c1.3,0.1,3.7,0.1,3.2,2.1
  C537.2,50.4,536.9,50.9,536.5,51z"
              />
              <path
                d="M523.2,40.6c-0.6,1-1.4,0.4-2.2,0
  c-0.6-0.3-1.8-0.6-1.9-1.5c0-0.3,0.2-0.8,0.5-1c0.5-0.2,1.1-0.2,1.6-0.2c0.7,0,2,0,2.1,0.9C523.5,39.4,523.4,40.1,523.2,40.6z"
              />
              <path
                d="M495.2,42.2c-0.8,0.1-1.4-0.7-1.8-1.3
  c-0.5-0.7-1.1-1.6-1.1-2.5c0-0.3,0.2-0.8,0.5-1c0.5-0.3,1.5-0.8,2.1-0.5c0.5,0.2,0.5,0.8,0.6,1.2c0.2,0.9,0.2,1.8,0.3,2.7
  C495.8,41.3,495.9,42.1,495.2,42.2z"
              />
              <path
                d="M476.9,35.6c-0.2,0-0.5-0.2-0.7-0.4
  c-0.6-0.8-1.3-1.5-1.9-2.3c-0.3-0.5-0.8-1-1-1.6c-0.2-0.5-0.1-0.9,0.1-1.3c0.1-0.1,0.3-0.2,0.3-0.3c0.5-0.3,1-0.7,1.6-0.4
  c0.4,0.2,0.6,0.8,0.8,1.2c0.3,0.8,0.6,1.6,1,2.4c0.2,0.4,0.4,0.9,0.7,1.4C478.2,35,477.5,35.7,476.9,35.6z"
              />
              <path
                d="M376.8,22.5c-0.5,0.1-1.2,0-1.7-0.1
  c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-1.1-0.1-1.4-0.3c-1-0.6-0.4-1.8,0.3-2.4c0.2,0,0.4-0.1,0.7-0.1c0.8,0,1.7,0.6,2.4,0.9
  c0.3,0.1,0.5,0.2,0.8,0.4c0.1,0.1,0.3,0.1,0.5,0.2c0.6,0.3,0.6,1.1,0,1.5C377,22.5,376.9,22.5,376.8,22.5z"
              />
              <path
                d="M328,29.4c-0.9,0.1-1.8,0.1-2.7,0.2
  c-0.7,0-1.7,0.4-2.3-0.1c-0.4-0.3-0.2-0.9,0-1.3c0.3-0.7,0.7-1.4,1.4-1.5c1.3-0.1,2.5,0.7,3.8,1c0.6,0.1,0.8,0.6,0.7,1.1
  C328.8,29.2,328.4,29.3,328,29.4z"
              />
              <path
                d="M312.3,22.4c-0.3-0.1-0.5-0.2-0.7-0.5
  c-0.4-0.6,0.2-1.2,0.8-1.5c0.7-0.3,1.5-0.6,2.2-1c0.4-0.2,0.9-0.4,1.4-0.5c0.9-0.1,1.9,0.5,2.2,1.3c0.1,0.4,0.1,0.7-0.1,1
  c-0.3,0.6-1.4,0.5-1.9,0.6c-0.8,0.1-1.6,0.3-2.4,0.4C313.3,22.3,312.8,22.5,312.3,22.4z"
              />
              <path
                d="M284.9,25.6c-0.3,0-0.7,0-0.8-0.3
  c-0.2-0.4,0-1.1,0.4-1.3c0.2,0,0.3-0.2,0.5-0.2c1-0.4,1.9-0.9,2.9-1.3c0.5-0.2,1-0.5,1.5-0.4c0.8,0.2,1.2,1.1,1.3,1.8
  c0.3,1.4-1.8,1.3-2.7,1.4c-0.9,0.2-1.9,0.3-2.8,0.3C285.1,25.5,285,25.5,284.9,25.6z"
              />
              <path
                d="M256.1,30.4c-0.2,0-0.3-0.1-0.5-0.1
  c-0.6-0.3-0.6-1.3,0.1-1.5c1.6-0.5,3.1-1,4.7-1.4c0.3-0.1,0.5-0.1,0.8-0.1c0.8,0.1,0.7,1,0.6,1.6c-0.1,0.4-0.2,0.9-0.6,1.2
  c-0.4,0.2-1,0.2-1.4,0.2c-0.9,0-1.8,0.1-2.7,0.1C256.8,30.4,256.5,30.5,256.1,30.4z"
              />
              <path
                d="M201.4,46.6c-1.3,0.1-0.6-1.7-0.2-2.3
  c0.5-0.7,1.1-1.3,1.9-1.6c0.6-0.2,1.5-0.2,1.9,0.4c0.4,0.7-0.2,1.2-0.7,1.6c-0.5,0.4-1,0.8-1.5,1.1
  C202.3,46.2,201.9,46.5,201.4,46.6z"
              />
              <path
                d="M163.5,65.7c-0.2-0.1-0.3-0.3-0.4-0.6
  c-0.1-0.5,0.1-1.2,0.4-1.6c0.7-0.8,2.3-0.6,3.3-0.7c0.3,0,0.5-0.1,0.8-0.1c0.6-0.1,1.3,0.1,1.4,0.8c0.1,0.6-0.4,1.1-1,1.3
  c-0.8,0.3-1.5,0.5-2.3,0.8C165.1,65.8,164.1,66.1,163.5,65.7z"
              />
              <path
                d="M155.7,73.7c0,0-0.1,0-0.1-0.1
  c-0.2-0.1-0.4-0.6-0.4-0.8c-0.1-0.4,0-0.8,0.1-1.1c0.3-0.9,1.1-1,2-1.1c1.1-0.1,2.3-0.2,3.4-0.2c0.5,0,0.6,0.4,0.6,0.8
  c0,0.5-0.1,0.9-0.6,1.1c-0.7,0.3-1.4,0.5-2,0.8C157.9,73.4,156.6,74,155.7,73.7z"
              />
              <path
                d="M147.6,84.6c-0.3,0-0.5-0.2-0.7-0.4
  c-0.4-0.7-0.5-2.3,0.5-2.5c0.5-0.1,1-0.3,1.5-0.4c0.4-0.1,0.9-0.2,1.3-0.4c0.4-0.1,0.8-0.2,1.2-0.3c0.2,0,1-0.2,1.1,0.2
  c0.1,0.7-0.1,1.3-0.7,1.8c-0.6,0.5-1.4,0.9-2.1,1.3C149.2,84.1,148.3,84.7,147.6,84.6z"
              />
              <path
                d="M130.1,106.9c-0.8-0.8-0.2-2.4,0.8-2.7
  c1.6-0.4,3.3-0.7,4.9-1c0.8-0.2,1.3,0.5,0.9,1.2c-0.2,0.4-0.7,0.6-1.1,0.7c-1.1,0.5-2.1,0.9-3.2,1.5c-0.6,0.3-1.3,0.6-2,0.5
  C130.4,107,130.2,107,130.1,106.9z"
              />
              <path
                d="M89.9,171.2c-0.2-0.2-0.5-0.4-0.6-0.7
  c-0.2-0.3-0.5-0.6-0.5-0.9c0-0.4,0.4-0.8,0.6-1c0.6-0.5,1.2-0.9,1.9-1.4c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.5-0.5,0.8-0.5
  c0.4,0,0.8,0.4,0.9,0.8c0,0.9-0.7,1.5-1.2,2.2c-0.5,0.8-1,1.6-1.8,2.1C90.6,171.5,90.2,171.4,89.9,171.2z"
              />
              <path
                d="M28.2,276.6c-0.1,0-0.2-0.1-0.4-0.2
  c-0.6-0.5-0.9-1.3-1.4-1.9c-0.5-0.7-0.7-1.4-0.3-2.2c0.4-0.7,1.4-1.5,2-0.6c0.5,0.7,0.5,1.8,0.6,2.6c0,0.3,0.1,0.6,0.1,0.9
  c0.1,0.2,0.2,0.4,0.2,0.7C29.1,276.3,28.6,276.7,28.2,276.6z"
              />
              <path
                d="M24.7,285.8c-0.6-0.1-0.7-0.7-0.9-1.2
  c-0.3-0.7-0.6-1.5-0.9-2.2c-0.4-0.8-0.1-1.6,0.5-2.2c0.2-0.3,0.5-0.6,0.9-0.7c0.3-0.1,0.7,0.1,0.9,0.3c0.3,0.5,0.3,1.2,0.3,1.8
  c0.1,1,0.1,2.1,0.3,3.1c0.1,0.5-0.3,1.2-0.8,1.2C24.8,285.8,24.8,285.8,24.7,285.8z"
              />
              <path
                d="M17.2,303.5c-0.1,0-0.3,0-0.4,0
  c-0.7-0.1-1.1-0.9-0.8-1.5c0.2-0.5,0.6-0.8,0.8-1.2c0.3-0.5,0.6-0.9,1-1.3c0.2-0.2,0.8-0.6,1.1-0.6c0.4,0,0.4,0.5,0.4,0.8
  c-0.1,0.9-0.5,1.7-0.8,2.5C18.4,302.7,18,303.4,17.2,303.5z"
              />
              <path
                d="M12.7,332.9
  C12.7,332.9,12.7,332.9,12.7,332.9c-0.9-0.2-1.5-1.1-2-1.6c-0.3-0.3-0.5-0.5-0.6-0.9c-0.1-0.6,0-1.2,0.1-1.8
  c0.1-0.4,0.3-1.3,0.8-1.4c0.7-0.1,1.1,0.9,1.3,1.3c0.5,1,0.9,2.1,1.4,3.1C14,332.2,13.4,333,12.7,332.9z"
              />
              <path
                d="M16,341.7c0,0.2-0.1,0.4-0.1,0.6
  c-0.3,1.2-1.3,3.5-2.8,2.2c-0.7-0.6-1-1.7-0.6-2.5c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.4-0.5,0.5-0.7
  c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.5,0.6-1.5,1.4-1.2c0.6,0.2,0.5,1.1,0.5,1.6C16.1,340.4,16.1,341.1,16,341.7z"
              />
              <path
                d="M24.6,362.8c-0.2,0-0.3,0-0.5,0
  c-0.5-0.2-1-0.3-1.5-0.5c-1-0.5-1.9-1.1-2.9-1.7c-0.2-0.2-0.5-0.7-0.5-0.9c0-0.3,0.1-0.6,0.2-0.9c0.7-2.2,2.8,0.4,3.6,1.2
  c0.5,0.5,1.1,1.1,1.6,1.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3C25.2,362.6,25,362.8,24.6,362.8z"
              />
              <path
                d="M22.9,387.6c-0.5-0.1-0.9-0.4-1-0.9
  c-0.1-0.6,0.2-1.2,0.3-1.8c0.2-0.5,0.5-1,0.9-1.4c0.8-0.9,1.2,0.3,1.2,1.1c0,0.5-0.1,1.1-0.2,1.7c-0.1,0.5-0.2,1.1-0.7,1.3
  C23.3,387.6,23.1,387.6,22.9,387.6z"
              />
              <path
                d="M59.3,392.6c-0.1,0-0.2,0-0.4,0
  c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.3-0.4-1.8-0.7c-0.6-0.4-0.4-1.1-0.2-1.7c0.2-0.4,0.4-0.9,0.6-1.2
  c0.2-0.2,0.5-0.2,0.8-0.2c0.5,0,0.9,0.3,1.4,0.6c0.6,0.4,1.3,0.8,1.9,1.3c0.6,0.4,1.3,0.8,1.7,1.3c0.2,0.2,0.1,0.5,0,0.8
  C59.8,392.4,59.6,392.5,59.3,392.6z"
              />
              <path
                d="M53.4,410c-1.1,0-2-0.9-2.8-1.5
  c-0.6-0.5-1.3-1-1.8-1.5c-0.5-0.4-0.9-1-0.7-1.7c0.1-0.6,0.5-1.2,1.1-1.4c0.2-0.1,0.7-0.2,0.9-0.1c0.4,0.1,0.8,0.4,1,0.8
  c0.6,0.7,1,1.5,1.5,2.3c0.4,0.7,1.2,1.2,1.5,2c0.1,0.3,0.1,0.6-0.1,0.9C53.8,410,53.7,410,53.4,410z"
              />
            </g>
            <g title="Microtubule organizing center" className="microtubule_organizing_center_ton">
              <title>Microtubule organizing center</title>
              <path
                d="M244.3,269c-0.8,6.6-7.8,10.7-14,11.1
  c-8.3,0.5-18-4-19.8-12.7c-0.4-2-0.6-4.5-0.1-6.4c1.4-6.5,8.2-10.4,14.4-11.4c2.1-0.3,4.4-0.4,6.4-0.2c9.3,0.9,14.3,9.4,13.3,18.2
  C244.4,268.1,244.3,268.6,244.3,269z"
              />
            </g>
            <g
              title="Microtubule organizing center"
              className={locations.includes('Microtubule organizing center') ? 'microtubule_organizing_center' : 'microtubule_organizing_center--disabled'}
            >
              <title>Microtubule organizing center</title>
              <path
                d="M244.3,269c-0.8,6.6-7.8,10.7-14,11.1
  c-8.3,0.5-18-4-19.8-12.7c-0.4-2-0.6-4.5-0.1-6.4c1.4-6.5,8.2-10.4,14.4-11.4c2.1-0.3,4.4-0.4,6.4-0.2c9.3,0.9,14.3,9.4,13.3,18.2
  C244.4,268.1,244.3,268.6,244.3,269z"
              />
            </g>
            <g title="Centrosome" className="centrosome_ton">
              <title>Centrosome</title>
              <path
                d="M234.6,259.6c-0.1,1.7-0.7,8.3-0.9,10.1
  c-0.2,1.8-5.8,2-5.8,0c0.1-2.9,0.4-8.5,0.6-10.5C228.8,257.2,234.7,257.5,234.6,259.6z"
              />
              <path
                d="M226.8,262.6c-1.7,0-6.9,0-8.4,0
  c-2.7,0-3,6-0.2,6c2,0,7.2,0,8.8,0C229.1,268.7,229.5,262.6,226.8,262.6z"
              />
            </g>
            <g
              title="Centrosome"
              className={locations.includes('Centrosome') ? 'centrosome' : 'centrosome--disabled'}
            >
              <title>Centrosome</title>
              <path
                d="M234.6,259.6c-0.1,1.7-0.7,8.3-0.9,10.1
  c-0.2,1.8-5.8,2-5.8,0c0.1-2.9,0.4-8.5,0.6-10.5C228.8,257.2,234.7,257.5,234.6,259.6z"
              />
              <path
                d="M226.8,262.6c-1.7,0-6.9,0-8.4,0
  c-2.7,0-3,6-0.2,6c2,0,7.2,0,8.8,0C229.1,268.7,229.5,262.6,226.8,262.6z"
              />
            </g>
            <g title="Microtubules" className="microtubules_ton">
              <title>Microtubules</title>
              <g>
                <path
                  d="M128,182.5c2.4,7.4,7.3,14.9,14.7,22.2
    c4.8,4.7,10.2,9.2,16.8,13.6c10.5,7.1,22,13,33.2,18.7l0.5,0.2c1.5,0.7,2.9,1.5,4.4,2.2c1.8,0.9,3.6,1.8,5.3,2.7
    c1.8,0.9,3.6,1.8,5.4,2.8c1.8,1,3.5,2,5.3,3.1c0.9,0.5,1.8,1.2,2.6,1.8c0.8,0.6,3.3,1.5,2.6,2.9c-0.6,1.3-2,0-2.7-0.5
    c-1.1-0.9-2.3-1.6-3.5-2.3c-1.6-1-3.3-2-5-3c-1.7-1-3.4-1.9-5.1-2.7c-1.7-0.9-3.4-1.8-5.2-2.6c-0.2-0.1-0.4-0.2-0.6-0.3
    c-1.5-0.7-3-1.5-4.4-2.2l-0.5-0.2c-11.2-5.7-22.8-11.6-33.4-18.8c-6.6-4.5-12.2-9-17.1-13.9c-7.5-7.5-12.6-15.3-15.1-23
    c-0.2-0.5,0.1-1.1,0.6-1.3C127.3,181.7,127.9,182,128,182.5z"
                />
                <path
                  d="M229.8,250.2c1.4-5.4,1.9-11.1,0.1-17.2
    c-3.2-10.9-10.7-20.4-18-29.6c-1.1-1.4-2.3-2.9-3.4-4.3c-21.9-28.1-41.4-56.2-40.8-90.2c0.3-17.7,6.1-44.2,21.3-60.5
    c0.4-0.4,1-0.4,1.4-0.1c0.4,0.4,0.4,1,0,1.4c-14.8,15.9-20.5,41.9-20.8,59.2c-0.6,33.4,18.8,61.1,40.4,89c1.1,1.4,2.2,2.8,3.4,4.3
    c7.4,9.3,15.1,19,18.3,30.3c1.9,6.6,1.4,12.7-0.1,18.4c-0.2,0.6-0.2,1.5-0.9,1.8c-0.5,0.2-1.1-0.1-1.3-0.6
    C229.3,251.5,229.7,250.7,229.8,250.2z"
                />
                <path
                  d="M225.5,250.7c-4.9-21-28.8-37.8-47.3-49.1
    c-13.3-8.1-27.2-17.3-37.6-32.1c-7.9-11.2-13.7-23.7-18.9-35.5c-0.2-0.5,0-1.1,0.5-1.3c0.5-0.2,1.1,0,1.3,0.5
    c5.2,11.7,11,24.1,18.7,35.2c10.2,14.5,23.9,23.5,37,31.6c18.9,11.5,43.3,28.7,48.3,50.5c0.2,0.8,0.8,2.5-0.6,2.6
    C225.8,253,225.7,251.4,225.5,250.7z"
                />
                <path
                  d="M243.7,257c0.4-0.4,0.8-0.7,1.3-1.1
    c11.1-9.7,19.1-22.2,26.7-34.6c-2.6,8.4-6.2,16.3-11.6,23.8c-2.7,3.7-7.8,11-13.9,14.3c-0.7,0.4-2.1,0.9-1.9,1.9
    c0.1,0.9,1,0.7,1.6,0.5c7-3.2,12.9-11.4,15.8-15.6c6.9-9.7,11.1-20,13.7-31c7.6-12.3,15.7-24.7,26.9-33.8
    c6.6-5.4,14.2-9.3,22.2-12.4c-3.3,3.9-6.5,8.2-9.2,12.8c-5.6,9.3-9.3,19.6-12.9,29.5c-0.5,1.5-1.1,3-1.6,4.4
    c-7.8,21.3-18,34.5-32.9,42.7c-6.7,3.7-13.2,5.2-20.6,5.7c-0.8,0-2.3,0-2.4,1.1c-0.1,1.4,1.8,1,2.6,0.9c7.6-0.5,14.4-2,21.4-5.9
    c15.3-8.5,25.8-22,33.8-43.8c0.5-1.5,1.1-3,1.6-4.4c3.6-9.8,7.3-20,12.7-29.1c3.4-5.6,7.3-10.7,11.4-15.3c6.9-2.4,14-4.4,21-6.3
    l0.3-0.1c7.6-2.1,15.4-4.2,22.9-6.1c20.8-5.5,42.3-11.2,63-18.3c27-9.2,55.3-20.1,79.2-37.8c13.7-10.1,23.9-22.9,32.1-40.1
    c0.2-0.5,0-1.1-0.5-1.3c-0.5-0.2-1.1,0-1.3,0.5c-8,16.9-18.1,29.5-31.5,39.4c-23.7,17.5-51.8,28.4-78.7,37.5
    c-20.7,7-42.2,12.7-62.9,18.2c-7.5,2-15.3,4.1-22.9,6.2l-0.3,0.1c-5.9,1.6-11.8,3.2-17.6,5.2c3.4-3.5,6.8-6.8,10-9.7l1-0.9
    c8.8-8.1,18-16.4,26-25.8c3.9-4.6,7.7-9.3,11.4-14c5.9-7.3,11.9-14.9,18.5-21.8c30.6-32.2,69-43.1,105.9-50.1
    c0.5-0.1,0.9-0.6,0.8-1.2c-0.1-0.5-0.6-0.9-1.2-0.8c-37.2,7-75.9,18.1-106.9,50.7c-6.6,7-12.7,14.6-18.6,21.9
    c-3.7,4.6-7.5,9.4-11.4,13.9c-7.9,9.2-17,17.5-25.8,25.6l-1,0.9c-4.1,3.7-8.5,7.9-12.7,12.6c-9.4,3.4-18.4,7.7-26.2,14
    c-10,8.2-17.5,18.8-24.4,29.7c2-10.7,2.9-22.2,3.5-34.6c0.7-12.6,2.2-26,8.7-38.3c5-9.4,12.2-18.3,22.8-27.8
    c4.4-4,9.2-8.1,15.6-13.4c3.6-3,7.5-6.1,11.2-9.1c14.4-11.5,29.3-23.4,39.9-38.4c0.3-0.5,0.2-1.1-0.2-1.4
    c-0.5-0.3-1.1-0.2-1.4,0.2c-10.5,14.8-25.2,26.6-39.5,38c-3.7,3-7.6,6-11.2,9.1c-6.4,5.3-11.2,9.5-15.6,13.5
    c-10.8,9.8-18.1,18.8-23.2,28.4c-6.7,12.6-8.2,26.3-8.9,39.2c-0.8,14.5-1.8,27.6-4.6,39.7c-0.7,1.2-1.4,2.3-2.2,3.5
    c-0.2,0.3-0.4,0.7-0.6,1c-1.8,2.8-3.5,5.7-5.3,8.5c-1.8,2.8-3.7,5.6-5.6,8.4c-1,1.4-1.9,2.7-2.9,4.1c-1,1.3-2.1,2.6-3.2,3.9
    c-2,2.6-4.4,4.9-6.6,7.4c-0.7,0.7-1.4,1.4-2.2,2c-0.8,0.5-1.6,1-2.2,1.6c-0.4,0.4-0.7,0.8-0.8,1.3c0,0,0,0,0,0.1
    C241.3,258,242.8,257.8,243.7,257z"
                />
                <path
                  d="M236.3,313.9c3.6,7,8.6,15.9,15.9,23.4
    c2.2,2.3,4.5,4.5,6.8,6.6c5.1,4.8,9.9,9.4,14,15c16.8,23.1,18.4,48.7,20,75.7l0.2,2.7c0.4,6.8,0.8,13.9,2.7,20.4
    c0.1,0.4,0.5,0.7,1,0.7c0.1,0,0.2,0,0.3,0c0.5-0.2,0.8-0.7,0.7-1.2c-1.9-6.3-2.3-13.2-2.6-19.9l-0.2-2.7
    c-1.6-27.4-3.2-53.2-20.4-76.8c-4.2-5.8-9.3-10.6-14.2-15.3c-2.2-2.1-4.5-4.3-6.7-6.6c-7.1-7.3-12-16.1-15.6-22.9
    c-6-11.7-8.7-21.8-8.4-31.9c0-0.7,0.4-2.9-0.8-2.8c-0.5,0-1,0.5-1.1,0.9c-0.2,0.6-0.1,1.3-0.1,1.9
    C227.3,291.6,230.1,301.9,236.3,313.9z M238.2,285.8c1.4,3,2.8,6.1,4.4,9c4.5,8.1,10.6,15.1,17.1,22.2
    c5.6,6.2,12.1,12.7,20.4,20.5l1.9,1.8c5.3,5,10.7,10.1,15.9,15.2c14.1,13.8,25.8,30.7,37.1,53.3l0.9,1.8c2.5,5.1,5.2,10.4,8,15.5
    c7.3,13.2,17.1,21.4,30.9,25.7c13.2,4.1,27.1,6.5,40.6,8.7c3.3,0.5,6.5,1.1,9.7,1.7c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.3,1-0.8
    c0.1-0.5-0.3-1.1-0.8-1.2c-3.2-0.6-6.5-1.1-9.8-1.7c-13.4-2.2-27.2-4.6-40.3-8.6c-13.2-4.1-22.7-12-29.7-24.7
    c-2.8-5-5.4-10.3-7.9-15.4l-0.9-1.8c-11.3-22.8-23.2-39.9-37.4-53.8c-5.3-5.2-10.7-10.3-16-15.3l-1.9-1.8
    c-8.3-7.8-14.7-14.3-20.3-20.4c-6.4-7-12.5-13.9-16.8-21.8c-1.6-2.9-3-5.9-4.3-8.9c-0.9-2-1.8-4.1-2.9-6.1
    c-0.4-0.9-0.8-1.8-1.5-2.5c-1.1-1.4-1.8,0.8-1.2,1.7c0.3,0.5,0.6,1,0.8,1.6C236.4,281.6,237.3,283.7,238.2,285.8z M239.5,272.6
    c-0.5,0.3-0.3,1.1,0,1.4c0.4,0.5,1.1,0.7,1.7,0.9c3.4,1.3,6.9,2.7,10.3,4c2.4,0.9,4.9,1.9,7.3,2.8c1.2,0.5,2.3,1,3.5,1.3
    c8.2,3.2,16.3,6.6,24.2,10.5c4.3,7.1,8,14.9,11.5,22.4c3.1,6.7,6.1,13,9.3,18.5c19.6,33,60.9,74.7,146.1,86.6
    c15.3,2.1,30,3.2,44.1,3.2c31.2,0,59.1-5.3,83.2-15.8c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5
    c-34.7,15.1-77.1,19.3-126.2,12.5c-84.4-11.8-125.2-53.1-144.6-85.7c-3.2-5.4-6.1-11.7-9.2-18.3c-3.1-6.6-6.3-13.5-10-19.9
    c1.6,0.8,3.3,1.7,4.9,2.6c6.3,3.5,12,8.1,17.6,12.5l1.8,1.4c16,12.6,29.3,22.5,41.8,31.2c25.7,17.8,60.5,39.7,101.5,50.7
    c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,1-0.7c0.1-0.5-0.2-1.1-0.7-1.2c-40.7-10.9-75.3-32.7-100.9-50.4c-12.5-8.6-25.7-18.5-41.7-31.1
    l-1.8-1.4c-5.6-4.5-11.5-9.1-17.9-12.7c-2.5-1.4-5.1-2.8-7.7-4.1c-1.5-2.4-3-4.7-4.7-6.9c2.6-0.1,5.1-0.3,7.7-0.6
    c5.6-0.5,11.4-1,17.2-0.6c23.8,1.4,47.2,8.6,68.9,16.2c24.4,8.5,48.5,18.9,71.9,28.9c7.8,3.3,15.8,6.8,23.8,10.1
    c2.4,1,4.9,2.1,7.4,3.1c22,9.4,44.7,19.1,68.1,19.8c0.5,0,1.1,0,1.6,0c16.8,0,28.7-8,38.2-15.9c0.4-0.4,0.5-1,0.1-1.4
    c-0.4-0.4-1-0.5-1.4-0.1c-17,14.1-29,15.7-38.5,15.4c-23-0.7-45.6-10.3-67.4-19.6c-2.5-1.1-5-2.1-7.4-3.2
    c-7.9-3.3-16-6.8-23.7-10.1c-23.4-10-47.6-20.4-72-28.9c-10.4-3.6-21.2-7.2-32.2-10c2.1,0.1,4.1,0.2,6.1,0.2
    c12.2,0,23.7-2.1,34.4-6.4c9.6-3.9,18.8-8.9,27.7-13.7c9.7-5.3,19.7-10.8,30.2-14.7c41.6-15.6,93.3-12.6,138.1,8.2
    c0.5,0.2,1.1,0,1.3-0.5c0.2-0.5,0-1.1-0.5-1.3c-45.3-21-97.5-24-139.6-8.2c-10.7,4-20.8,9.5-30.5,14.8c-8.8,4.8-18,9.8-27.5,13.6
    c-16.6,6.7-35.5,8-56.1,4c-13.7-2.7-26.2-7.4-39.6-12.4L288,271c-5.6-2.1-10.6-2.9-17-2.8c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
    c6.2-0.1,10.9,0.6,16.2,2.7l1.7,0.6c8.3,3.1,16.8,6.3,25.5,8.9c-2.1-0.2-4.1-0.4-6.2-0.5c-5.9-0.3-11.8,0.2-17.5,0.6
    c-3.1,0.3-6.1,0.5-9,0.6c-6.3-7.7-13.9-13.6-24-15.7c-1.4-0.3-4.9-0.3-8.9-0.3c-0.8,0-1.7-0.1-2.5,0.2c-0.9,0.4-0.4,1.5,0.3,1.8
    c0.9,0.4,2.2,0.1,3.1,0.1c1.2,0,2.3-0.1,3.5-0.1c1.3,0,2.8-0.1,4.1,0.2c9,1.9,16,7.1,21.8,13.8c-5.5,0.1-10.8-0.4-16.1-2.1
    c-1.2-0.5-2.3-0.9-3.5-1.3c-0.2-0.1-0.4-0.1-0.5-0.2c-4.8-2.2-9.4-4.8-14-7.5c-0.7-0.4-1.4-1-2.2-1.2c-0.6-0.2-1.8-0.5-2.2,0.1
    c-0.3,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.3-0.1,0.4C240.3,272.4,239.9,272.4,239.5,272.6z M272.7,285c2.7,0.2,5.4,0.3,8.1,0.2
    c1.3,1.6,2.5,3.3,3.7,5C280.6,288.4,276.7,286.7,272.7,285z"
                />
                <path
                  d="M239.9,253c6.7-6.9,9.2-15.6,7.6-26.4
    c-0.6-3.8-1.5-7.6-2.4-11.2c-0.8-3.4-1.7-6.9-2.3-10.4c-1.9-11.4-2.2-22.9-0.8-34.4c1.4-12.4,5.1-23.1,11.1-32.6
    c2.2-3.5,4.5-6.9,6.8-10.2c4.9-7.1,9.9-14.5,13.8-22.6c10-21.1,19.9-50.9,9.9-72.7c-0.2-0.5,0-1.1,0.5-1.3c0.5-0.2,1.1,0,1.3,0.5
    c10.3,22.6,0.3,52.9-9.9,74.4c-3.9,8.2-9,15.7-13.9,22.9c-2.2,3.3-4.6,6.7-6.7,10.1c-5.9,9.3-9.5,19.7-10.8,31.8
    c-1.3,11.2-1,22.6,0.8,33.8c0.6,3.4,1.4,6.9,2.2,10.2c0.9,3.7,1.9,7.6,2.4,11.4c1.7,11.4-1.1,20.8-8.3,28.2
    c-0.4,0.4-1.1,1.3-1.8,1.1c-0.5-0.1-0.7-0.8-0.6-1.2C238.9,253.9,239.5,253.5,239.9,253z"
                />
                <path
                  d="M198.8,264.8c-0.7,0.2-1.5,0.4-2.2,0.6
    c-2.7,0.8-5.3,1.7-7.9,2.7c-23.9,9.5-40.7,25.9-57.2,43.2c-18.6,19.6-37.7,37.8-65.6,45.8c-5.9,1.7-11.5,2.8-17.1,3.6
    c-4.4-2.2-8.8-4.5-13.5-6.9c-4.4-2.4-8.9-4.9-13.2-7.3c-2.6-1.5-5.2-3-7.8-4.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4
    c2.6,1.5,5.2,2.9,7.8,4.4c4.3,2.4,8.8,5,13.2,7.3c3.7,2,7.3,3.9,10.8,5.6c-9.1,1.1-17.9,1.3-26.8,1.4c-0.6,0-1,0.5-1,1
    c0,0.5,0.5,1,1,1c9.9-0.1,19.7-0.4,30-1.8c5.9,2.9,11.8,5.4,17.9,7.5c7.4,2.5,16.6,4.7,26.5,4.7c8.8,0,18-1.7,26.8-6.5
    c14.1-7.6,21.5-20,29.3-33.2l1.7-2.9c11.4-19.1,25.3-33.2,43.7-44.5c1.9-1.2,3.9-2.3,6-3.5c3.8-2.1,7.6-4.3,11-6.8
    c-1.1,1.7-2.2,3.4-3.3,5.2l-0.1,0.2c-4,6.4-7.1,11.7-9.8,16.7c-2.9,5.3-5.6,10.7-8.2,16.1c-4.3,9.1-5.9,18.6-7.4,27.7
    c-0.8,4.8-1.6,9.8-2.8,14.8c-5.8,23.7-25.3,39.3-44.1,54.4l-2.7,2.2c-0.4,0.3-0.5,1-0.2,1.4c0.2,0.2,0.5,0.4,0.8,0.4
    c0.2,0,0.4-0.1,0.6-0.2l2.7-2.2c18.2-14.6,38.8-31.2,44.8-55.5c1.2-5.1,2.1-10.1,2.9-15c1.5-9.4,3-18.3,7.2-27.2
    c2.5-5.3,5.2-10.6,8.1-16c2.7-4.9,5.8-10.2,9.8-16.6l0.1-0.2c1.2-1.9,2.3-3.7,3.6-5.5c1.2-1.8,2.6-3.5,4-5.1
    c0.4-0.5,0.9-0.8,1.3-1.3c0.2-0.2,0.3-0.4,0.4-0.7c0-0.2,0.1-0.7,0-0.9c-0.3-0.5-1-0.7-1.5-0.5c-0.3,0.1-0.6,0.4-0.8,0.6
    c-1.9,1.4-3.5,3.3-5.4,4.7c-2.4,1.9-5,3.5-7.6,5c-1.4,0.8-2.9,1.7-4.3,2.5c-2,1.1-4.1,2.3-6.1,3.5c-18.7,11.4-32.8,25.8-44.4,45.1
    l-1.7,2.9c-7.7,12.9-14.9,25.1-28.5,32.4c-18.1,9.7-38.1,6.3-51.7,1.6c-5.1-1.7-10-3.8-15-6.2c4.7-0.8,9.5-1.8,14.5-3.2
    c28.4-8.1,47.7-26.5,66.5-46.3c17-17.8,32.9-33.4,56.5-42.7c1.8-0.7,3.7-1.4,5.6-2c1.1-0.3,2.1-0.6,3.2-0.9
    c1.9-0.5,3.8-0.9,5.7-1.3c1.8-0.4,3.7-0.4,5.5-0.6c0.6-0.1,1.9-0.3,1.9-1c0-0.7-0.8-1.2-1.4-1.4c-0.4-0.1-0.8-0.1-1.3,0
    c-0.7,0.1-1.4,0.3-2,0.4c-0.1,0-0.2,0-0.3,0.1C203.8,263.9,201.3,264.3,198.8,264.8z M199.6,256.9c-1.6-0.5-3.2-1-4.7-1.5
    c-1.3-0.4-2.5-0.8-3.9-1.1c1.9-0.2,3.8-0.4,5.7-0.5c1.7,0,3.4,0.1,5.1,0.2c1.8,0.1,3.7,0.3,5.5,0.8c1.5,0.4,2.9,1,4.4,1.2
    c0.6,0.1,1.6-0.1,1.4-0.9c-0.1-0.5-0.6-1-1.1-1.2c-0.8-0.4-1.7-0.4-2.5-0.6c-1.2-0.3-2.5-0.5-3.7-0.7c-2.8-0.5-5.7-0.8-8.6-0.7
    c-1.8,0-3.5,0.2-5.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.4c-0.4,0.1-0.9,0.1-1.3,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.5,0.1-0.6,0.1
    c-12-3.3-25.6-6.1-39-3.6c-10,1.9-18.9,6.6-27.6,11.3c-3.5,1.9-7,3.7-10.6,5.4c-21.1-2.4-39.6-8.5-60.6-15.3
    c-2.1-0.7-4.3-1.4-6.4-2.1c-0.5-0.2-1.1,0.1-1.3,0.6c-0.2,0.5,0.1,1.1,0.6,1.3c2.2,0.7,4.3,1.4,6.4,2.1
    c19.9,6.5,37.7,12.3,57.7,15c-1.1,0.5-2.2,0.9-3.3,1.3c-19.7,7.4-40.6,7.3-60.3,6c-0.5,0-1,0.4-1.1,0.9c0,0.6,0.4,1,0.9,1.1
    c6,0.4,12.1,0.7,18.2,0.7c14.3,0,28.9-1.5,42.9-6.8c2.2-0.8,4.3-1.7,6.4-2.7c4.4,0.5,8.8,0.8,13.4,1c1.1,0,2.2,0.1,3.3,0.1
    c14.9,0,29.7-3.3,48.6-10.6c3.9-1.5,7.7-2.7,11.4-3.5c2.7,0.8,5.3,1.5,7.8,2.3c1.5,0.4,3,0.9,4.6,1.5c1.4,0.4,2.7,0.9,4.1,1.3
    c1.1,0.3,2.2,0.8,3.4,1.2c0.7,0.2,2.2,0.5,2.5-0.5c0.4-1.3-1.5-1.5-2.3-1.7c-0.6-0.2-1.2-0.4-1.8-0.5
    C203.1,258,201.3,257.5,199.6,256.9z M182.5,254c-2.6,0.7-5.3,1.6-8.1,2.7c-20.1,7.8-35.4,10.9-51.2,10.5
    c-3.4-0.1-6.8-0.3-10.1-0.6c2.6-1.3,5.3-2.7,7.8-4.1c8.5-4.6,17.3-9.3,27-11.1C159.7,249.2,171.7,251.2,182.5,254z"
                />
                <path
                  d="M515.8,168.5c-22.9,7-47.1,10.2-70.6,13.3
    c-3.6,0.5-7.2,0.9-10.8,1.4c-5.4,0.7-10.9,1.4-16.7,2.1c-37.6,4.5-80.1,9.6-107.5,36.9c-5.3,5.2-10.2,11.4-15,17.4
    c-8.8,11.1-18,22.5-30.1,29.5c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.7-0.2-0.9-0.5c-0.3-0.5-0.1-1.1,0.4-1.4
    c11.8-6.8,20.8-18.1,29.5-29c4.8-6,9.8-12.3,15.2-17.6c27.9-27.7,70.8-32.9,108.7-37.4c5.8-0.7,11.3-1.3,16.7-2.1
    c3.6-0.5,7.2-1,10.8-1.4c23.4-3.1,47.6-6.2,70.3-13.2c14.9-4.6,25.6-10.9,34.6-20.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4
    C542,157.3,531.1,163.8,515.8,168.5z"
                />
                <path
                  d="M233.5,249.5c0.7-2.6,1.6-5.1,2.5-7.5
    c1.8-5.3,3.7-10.8,4.3-16.6c1.1-10.7-2.9-21.1-6.7-31.2c-1.9-5-3.9-10.1-5.2-15.2c-6.2-24-9.3-45-9.5-64.3
    c-0.1-6.5,0.2-12.9,0.8-18.9c1.5-14.7,5.3-29.2,9-43.1l0.2-0.7c0.1-0.5,0.7-0.9,1.2-0.7c0.5,0.1,0.9,0.7,0.7,1.2l-0.2,0.7
    c-3.7,13.9-7.4,28.3-8.9,42.8c-0.6,6-0.9,12.3-0.8,18.7c0.2,19.1,3.3,40,9.4,63.8c1.3,5,3.2,10,5.1,14.9c3.9,10.3,8,21,6.9,32.1
    c-0.6,5.9-2.5,11.6-4.4,17c-0.9,2.6-1.8,5.2-2.6,7.9c-0.2,0.7-0.4,2.6-1.6,2.1C232.7,252.2,233.3,250.2,233.5,249.5z"
                />
                <path
                  d="M198.3,410c5.6-10.4,11.2-21,16.1-31.9
    c-0.2-2-0.3-4-0.5-5.9l-0.3-3.2c-0.2-2.6-0.5-5.2-0.7-7.9c-2.6-27.1-5.4-57.4,5.6-82.8c0.3-0.8,0.5-1.6,1-2.2
    c0.5-0.5,1.4-0.9,1.7-0.1c0.4,1-0.6,2.3-1,3.2c-10.8,25-8,55-5.5,81.8c0.3,2.7,0.5,5.3,0.7,7.9l0.3,3.2c0.1,0.8,0.1,1.5,0.2,2.3
    c3.4-7.8,6.3-15.8,8.4-24c3.2-12.2,2.1-23.6,0.4-36.1c-0.7-5.5-1.4-10.9-1.7-16.4c-0.3-5.5-0.4-11,0.2-16.5
    c0.1-0.9,0.2-1.7,0.3-2.6c0.1-0.6,0.1-1.5,0.6-2c0.7-0.7,1.5,0.2,1.6,0.9c0.1,0.9-0.2,1.9-0.3,2.8c-0.2,0.9-0.3,1.8-0.3,2.7
    c-0.1,1.2-0.2,2.5-0.2,3.7c-0.2,5.8,0.1,11.7,0.7,17.4c0.3,3.2,0.7,6.4,1.2,9.6c1.7,12.7,2.8,24.3-0.5,36.9
    c-2.4,9.4-5.9,18.6-9.9,27.6c1.1,13.1,1.8,27.3-1.6,39.8c-1.8,6.9-5.9,13-9.8,19c-1.7,2.7-3.5,5.4-5.1,8.1
    c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.4c1.6-2.8,3.4-5.5,5.1-8.2c3.8-5.9,7.8-11.9,9.6-18.4
    c3-11.1,2.7-23.6,1.8-35.5c-4.5,9.8-9.6,19.3-14.7,28.8c-2.1,4-4.4,8.2-6.5,12.2c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0-0.3,0-0.5-0.1
    c-0.5-0.3-0.7-0.9-0.4-1.3C193.9,418.2,196.1,414,198.3,410z"
                />
              </g>
            </g>
            <g
              title="Microtubules"
              className={locations.includes('Microtubules') || locations.includes('Cytokinetic bridge') || locations.includes('Midbody') || locations.includes('Midbody ring') || locations.includes('Mitotic spindle') ? 'microtubules' : 'microtubules--disabled'}
            >
              <title>Microtubules</title>
              <g>
                <path
                  d="
    M128,182.5c2.4,7.4,7.3,14.9,14.7,22.2c4.8,4.7,10.2,9.2,16.8,13.6c10.5,7.1,22,13,33.2,18.7l0.5,0.2c1.5,0.7,2.9,1.5,4.4,2.2
    c1.8,0.9,3.6,1.8,5.3,2.7c1.8,0.9,3.6,1.8,5.4,2.8c1.8,1,3.5,2,5.3,3.1c0.9,0.5,1.8,1.2,2.6,1.8c0.8,0.6,3.3,1.5,2.6,2.9
    c-0.6,1.3-2,0-2.7-0.5c-1.1-0.9-2.3-1.6-3.5-2.3c-1.6-1-3.3-2-5-3c-1.7-1-3.4-1.9-5.1-2.7c-1.7-0.9-3.4-1.8-5.2-2.6
    c-0.2-0.1-0.4-0.2-0.6-0.3c-1.5-0.7-3-1.5-4.4-2.2l-0.5-0.2c-11.2-5.7-22.8-11.6-33.4-18.8c-6.6-4.5-12.2-9-17.1-13.9
    c-7.5-7.5-12.6-15.3-15.1-23c-0.2-0.5,0.1-1.1,0.6-1.3C127.3,181.7,127.9,182,128,182.5z"
                />
                <path
                  d="
    M229.8,250.2c1.4-5.4,1.9-11.1,0.1-17.2c-3.2-10.9-10.7-20.4-18-29.6c-1.1-1.4-2.3-2.9-3.4-4.3c-21.9-28.1-41.4-56.2-40.8-90.2
    c0.3-17.7,6.1-44.2,21.3-60.5c0.4-0.4,1-0.4,1.4-0.1c0.4,0.4,0.4,1,0,1.4c-14.8,15.9-20.5,41.9-20.8,59.2
    c-0.6,33.4,18.8,61.1,40.4,89c1.1,1.4,2.2,2.8,3.4,4.3c7.4,9.3,15.1,19,18.3,30.3c1.9,6.6,1.4,12.7-0.1,18.4
    c-0.2,0.6-0.2,1.5-0.9,1.8c-0.5,0.2-1.1-0.1-1.3-0.6C229.3,251.5,229.7,250.7,229.8,250.2z"
                />
                <path
                  d="
    M225.5,250.7c-4.9-21-28.8-37.8-47.3-49.1c-13.3-8.1-27.2-17.3-37.6-32.1c-7.9-11.2-13.7-23.7-18.9-35.5c-0.2-0.5,0-1.1,0.5-1.3
    c0.5-0.2,1.1,0,1.3,0.5c5.2,11.7,11,24.1,18.7,35.2c10.2,14.5,23.9,23.5,37,31.6c18.9,11.5,43.3,28.7,48.3,50.5
    c0.2,0.8,0.8,2.5-0.6,2.6C225.8,253,225.7,251.4,225.5,250.7z"
                />
                <path
                  d="
    M243.7,257c0.4-0.4,0.8-0.7,1.3-1.1c11.1-9.7,19.1-22.2,26.7-34.6c-2.6,8.4-6.2,16.3-11.6,23.8c-2.7,3.7-7.8,11-13.9,14.3
    c-0.7,0.4-2.1,0.9-1.9,1.9c0.1,0.9,1,0.7,1.6,0.5c7-3.2,12.9-11.4,15.8-15.6c6.9-9.7,11.1-20,13.7-31
    c7.6-12.3,15.7-24.7,26.9-33.8c6.6-5.4,14.2-9.3,22.2-12.4c-3.3,3.9-6.5,8.2-9.2,12.8c-5.6,9.3-9.3,19.6-12.9,29.5
    c-0.5,1.5-1.1,3-1.6,4.4c-7.8,21.3-18,34.5-32.9,42.7c-6.7,3.7-13.2,5.2-20.6,5.7c-0.8,0-2.3,0-2.4,1.1c-0.1,1.4,1.8,1,2.6,0.9
    c7.6-0.5,14.4-2,21.4-5.9c15.3-8.5,25.8-22,33.8-43.8c0.5-1.5,1.1-3,1.6-4.4c3.6-9.8,7.3-20,12.7-29.1c3.4-5.6,7.3-10.7,11.4-15.3
    c6.9-2.4,14-4.4,21-6.3l0.3-0.1c7.6-2.1,15.4-4.2,22.9-6.1c20.8-5.5,42.3-11.2,63-18.3c27-9.2,55.3-20.1,79.2-37.8
    c13.7-10.1,23.9-22.9,32.1-40.1c0.2-0.5,0-1.1-0.5-1.3c-0.5-0.2-1.1,0-1.3,0.5c-8,16.9-18.1,29.5-31.5,39.4
    c-23.7,17.5-51.8,28.4-78.7,37.5c-20.7,7-42.2,12.7-62.9,18.2c-7.5,2-15.3,4.1-22.9,6.2l-0.3,0.1c-5.9,1.6-11.8,3.2-17.6,5.2
    c3.4-3.5,6.8-6.8,10-9.7l1-0.9c8.8-8.1,18-16.4,26-25.8c3.9-4.6,7.7-9.3,11.4-14c5.9-7.3,11.9-14.9,18.5-21.8
    c30.6-32.2,69-43.1,105.9-50.1c0.5-0.1,0.9-0.6,0.8-1.2c-0.1-0.5-0.6-0.9-1.2-0.8c-37.2,7-75.9,18.1-106.9,50.7
    c-6.6,7-12.7,14.6-18.6,21.9c-3.7,4.6-7.5,9.4-11.4,13.9c-7.9,9.2-17,17.5-25.8,25.6l-1,0.9c-4.1,3.7-8.5,7.9-12.7,12.6
    c-9.4,3.4-18.4,7.7-26.2,14c-10,8.2-17.5,18.8-24.4,29.7c2-10.7,2.9-22.2,3.5-34.6c0.7-12.6,2.2-26,8.7-38.3
    c5-9.4,12.2-18.3,22.8-27.8c4.4-4,9.2-8.1,15.6-13.4c3.6-3,7.5-6.1,11.2-9.1c14.4-11.5,29.3-23.4,39.9-38.4
    c0.3-0.5,0.2-1.1-0.2-1.4c-0.5-0.3-1.1-0.2-1.4,0.2c-10.5,14.8-25.2,26.6-39.5,38c-3.7,3-7.6,6-11.2,9.1
    c-6.4,5.3-11.2,9.5-15.6,13.5c-10.8,9.8-18.1,18.8-23.2,28.4c-6.7,12.6-8.2,26.3-8.9,39.2c-0.8,14.5-1.8,27.6-4.6,39.7
    c-0.7,1.2-1.4,2.3-2.2,3.5c-0.2,0.3-0.4,0.7-0.6,1c-1.8,2.8-3.5,5.7-5.3,8.5c-1.8,2.8-3.7,5.6-5.6,8.4c-1,1.4-1.9,2.7-2.9,4.1
    c-1,1.3-2.1,2.6-3.2,3.9c-2,2.6-4.4,4.9-6.6,7.4c-0.7,0.7-1.4,1.4-2.2,2c-0.8,0.5-1.6,1-2.2,1.6c-0.4,0.4-0.7,0.8-0.8,1.3
    c0,0,0,0,0,0.1C241.3,258,242.8,257.8,243.7,257z"
                />
                <path
                  d="
    M236.3,313.9c3.6,7,8.6,15.9,15.9,23.4c2.2,2.3,4.5,4.5,6.8,6.6c5.1,4.8,9.9,9.4,14,15c16.8,23.1,18.4,48.7,20,75.7l0.2,2.7
    c0.4,6.8,0.8,13.9,2.7,20.4c0.1,0.4,0.5,0.7,1,0.7c0.1,0,0.2,0,0.3,0c0.5-0.2,0.8-0.7,0.7-1.2c-1.9-6.3-2.3-13.2-2.6-19.9
    l-0.2-2.7c-1.6-27.4-3.2-53.2-20.4-76.8c-4.2-5.8-9.3-10.6-14.2-15.3c-2.2-2.1-4.5-4.3-6.7-6.6c-7.1-7.3-12-16.1-15.6-22.9
    c-6-11.7-8.7-21.8-8.4-31.9c0-0.7,0.4-2.9-0.8-2.8c-0.5,0-1,0.5-1.1,0.9c-0.2,0.6-0.1,1.3-0.1,1.9
    C227.3,291.6,230.1,301.9,236.3,313.9z M238.2,285.8c1.4,3,2.8,6.1,4.4,9c4.5,8.1,10.6,15.1,17.1,22.2
    c5.6,6.2,12.1,12.7,20.4,20.5l1.9,1.8c5.3,5,10.7,10.1,15.9,15.2c14.1,13.8,25.8,30.7,37.1,53.3l0.9,1.8c2.5,5.1,5.2,10.4,8,15.5
    c7.3,13.2,17.1,21.4,30.9,25.7c13.2,4.1,27.1,6.5,40.6,8.7c3.3,0.5,6.5,1.1,9.7,1.7c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.3,1-0.8
    c0.1-0.5-0.3-1.1-0.8-1.2c-3.2-0.6-6.5-1.1-9.8-1.7c-13.4-2.2-27.2-4.6-40.3-8.6c-13.2-4.1-22.7-12-29.7-24.7
    c-2.8-5-5.4-10.3-7.9-15.4l-0.9-1.8c-11.3-22.8-23.2-39.9-37.4-53.8c-5.3-5.2-10.7-10.3-16-15.3l-1.9-1.8
    c-8.3-7.8-14.7-14.3-20.3-20.4c-6.4-7-12.5-13.9-16.8-21.8c-1.6-2.9-3-5.9-4.3-8.9c-0.9-2-1.8-4.1-2.9-6.1
    c-0.4-0.9-0.8-1.8-1.5-2.5c-1.1-1.4-1.8,0.8-1.2,1.7c0.3,0.5,0.6,1,0.8,1.6C236.4,281.6,237.3,283.7,238.2,285.8z M239.5,272.6
    c-0.5,0.3-0.3,1.1,0,1.4c0.4,0.5,1.1,0.7,1.7,0.9c3.4,1.3,6.9,2.7,10.3,4c2.4,0.9,4.9,1.9,7.3,2.8c1.2,0.5,2.3,1,3.5,1.3
    c8.2,3.2,16.3,6.6,24.2,10.5c4.3,7.1,8,14.9,11.5,22.4c3.1,6.7,6.1,13,9.3,18.5c19.6,33,60.9,74.7,146.1,86.6
    c15.3,2.1,30,3.2,44.1,3.2c31.2,0,59.1-5.3,83.2-15.8c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5
    c-34.7,15.1-77.1,19.3-126.2,12.5c-84.4-11.8-125.2-53.1-144.6-85.7c-3.2-5.4-6.1-11.7-9.2-18.3c-3.1-6.6-6.3-13.5-10-19.9
    c1.6,0.8,3.3,1.7,4.9,2.6c6.3,3.5,12,8.1,17.6,12.5l1.8,1.4c16,12.6,29.3,22.5,41.8,31.2c25.7,17.8,60.5,39.7,101.5,50.7
    c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,1-0.7c0.1-0.5-0.2-1.1-0.7-1.2c-40.7-10.9-75.3-32.7-100.9-50.4c-12.5-8.6-25.7-18.5-41.7-31.1
    l-1.8-1.4c-5.6-4.5-11.5-9.1-17.9-12.7c-2.5-1.4-5.1-2.8-7.7-4.1c-1.5-2.4-3-4.7-4.7-6.9c2.6-0.1,5.1-0.3,7.7-0.6
    c5.6-0.5,11.4-1,17.2-0.6c23.8,1.4,47.2,8.6,68.9,16.2c24.4,8.5,48.5,18.9,71.9,28.9c7.8,3.3,15.8,6.8,23.8,10.1
    c2.4,1,4.9,2.1,7.4,3.1c22,9.4,44.7,19.1,68.1,19.8c0.5,0,1.1,0,1.6,0c16.8,0,28.7-8,38.2-15.9c0.4-0.4,0.5-1,0.1-1.4
    c-0.4-0.4-1-0.5-1.4-0.1c-17,14.1-29,15.7-38.5,15.4c-23-0.7-45.6-10.3-67.4-19.6c-2.5-1.1-5-2.1-7.4-3.2
    c-7.9-3.3-16-6.8-23.7-10.1c-23.4-10-47.6-20.4-72-28.9c-10.4-3.6-21.2-7.2-32.2-10c2.1,0.1,4.1,0.2,6.1,0.2
    c12.2,0,23.7-2.1,34.4-6.4c9.6-3.9,18.8-8.9,27.7-13.7c9.7-5.3,19.7-10.8,30.2-14.7c41.6-15.6,93.3-12.6,138.1,8.2
    c0.5,0.2,1.1,0,1.3-0.5c0.2-0.5,0-1.1-0.5-1.3c-45.3-21-97.5-24-139.6-8.2c-10.7,4-20.8,9.5-30.5,14.8c-8.8,4.8-18,9.8-27.5,13.6
    c-16.6,6.7-35.5,8-56.1,4c-13.7-2.7-26.2-7.4-39.6-12.4L288,271c-5.6-2.1-10.6-2.9-17-2.8c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
    c6.2-0.1,10.9,0.6,16.2,2.7l1.7,0.6c8.3,3.1,16.8,6.3,25.5,8.9c-2.1-0.2-4.1-0.4-6.2-0.5c-5.9-0.3-11.8,0.2-17.5,0.6
    c-3.1,0.3-6.1,0.5-9,0.6c-6.3-7.7-13.9-13.6-24-15.7c-1.4-0.3-4.9-0.3-8.9-0.3c-0.8,0-1.7-0.1-2.5,0.2c-0.9,0.4-0.4,1.5,0.3,1.8
    c0.9,0.4,2.2,0.1,3.1,0.1c1.2,0,2.3-0.1,3.5-0.1c1.3,0,2.8-0.1,4.1,0.2c9,1.9,16,7.1,21.8,13.8c-5.5,0.1-10.8-0.4-16.1-2.1
    c-1.2-0.5-2.3-0.9-3.5-1.3c-0.2-0.1-0.4-0.1-0.5-0.2c-4.8-2.2-9.4-4.8-14-7.5c-0.7-0.4-1.4-1-2.2-1.2c-0.6-0.2-1.8-0.5-2.2,0.1
    c-0.3,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.3-0.1,0.4C240.3,272.4,239.9,272.4,239.5,272.6z M272.7,285c2.7,0.2,5.4,0.3,8.1,0.2
    c1.3,1.6,2.5,3.3,3.7,5C280.6,288.4,276.7,286.7,272.7,285z"
                />
                <path
                  d="
    M239.9,253c6.7-6.9,9.2-15.6,7.6-26.4c-0.6-3.8-1.5-7.6-2.4-11.2c-0.8-3.4-1.7-6.9-2.3-10.4c-1.9-11.4-2.2-22.9-0.8-34.4
    c1.4-12.4,5.1-23.1,11.1-32.6c2.2-3.5,4.5-6.9,6.8-10.2c4.9-7.1,9.9-14.5,13.8-22.6c10-21.1,19.9-50.9,9.9-72.7
    c-0.2-0.5,0-1.1,0.5-1.3c0.5-0.2,1.1,0,1.3,0.5c10.3,22.6,0.3,52.9-9.9,74.4c-3.9,8.2-9,15.7-13.9,22.9c-2.2,3.3-4.6,6.7-6.7,10.1
    c-5.9,9.3-9.5,19.7-10.8,31.8c-1.3,11.2-1,22.6,0.8,33.8c0.6,3.4,1.4,6.9,2.2,10.2c0.9,3.7,1.9,7.6,2.4,11.4
    c1.7,11.4-1.1,20.8-8.3,28.2c-0.4,0.4-1.1,1.3-1.8,1.1c-0.5-0.1-0.7-0.8-0.6-1.2C238.9,253.9,239.5,253.5,239.9,253z"
                />
                <path
                  d="
    M198.8,264.8c-0.7,0.2-1.5,0.4-2.2,0.6c-2.7,0.8-5.3,1.7-7.9,2.7c-23.9,9.5-40.7,25.9-57.2,43.2c-18.6,19.6-37.7,37.8-65.6,45.8
    c-5.9,1.7-11.5,2.8-17.1,3.6c-4.4-2.2-8.8-4.5-13.5-6.9c-4.4-2.4-8.9-4.9-13.2-7.3c-2.6-1.5-5.2-3-7.8-4.4
    c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4c2.6,1.5,5.2,2.9,7.8,4.4c4.3,2.4,8.8,5,13.2,7.3c3.7,2,7.3,3.9,10.8,5.6
    c-9.1,1.1-17.9,1.3-26.8,1.4c-0.6,0-1,0.5-1,1c0,0.5,0.5,1,1,1c9.9-0.1,19.7-0.4,30-1.8c5.9,2.9,11.8,5.4,17.9,7.5
    c7.4,2.5,16.6,4.7,26.5,4.7c8.8,0,18-1.7,26.8-6.5c14.1-7.6,21.5-20,29.3-33.2l1.7-2.9c11.4-19.1,25.3-33.2,43.7-44.5
    c1.9-1.2,3.9-2.3,6-3.5c3.8-2.1,7.6-4.3,11-6.8c-1.1,1.7-2.2,3.4-3.3,5.2l-0.1,0.2c-4,6.4-7.1,11.7-9.8,16.7
    c-2.9,5.3-5.6,10.7-8.2,16.1c-4.3,9.1-5.9,18.6-7.4,27.7c-0.8,4.8-1.6,9.8-2.8,14.8c-5.8,23.7-25.3,39.3-44.1,54.4l-2.7,2.2
    c-0.4,0.3-0.5,1-0.2,1.4c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l2.7-2.2c18.2-14.6,38.8-31.2,44.8-55.5
    c1.2-5.1,2.1-10.1,2.9-15c1.5-9.4,3-18.3,7.2-27.2c2.5-5.3,5.2-10.6,8.1-16c2.7-4.9,5.8-10.2,9.8-16.6l0.1-0.2
    c1.2-1.9,2.3-3.7,3.6-5.5c1.2-1.8,2.6-3.5,4-5.1c0.4-0.5,0.9-0.8,1.3-1.3c0.2-0.2,0.3-0.4,0.4-0.7c0-0.2,0.1-0.7,0-0.9
    c-0.3-0.5-1-0.7-1.5-0.5c-0.3,0.1-0.6,0.4-0.8,0.6c-1.9,1.4-3.5,3.3-5.4,4.7c-2.4,1.9-5,3.5-7.6,5c-1.4,0.8-2.9,1.7-4.3,2.5
    c-2,1.1-4.1,2.3-6.1,3.5c-18.7,11.4-32.8,25.8-44.4,45.1l-1.7,2.9c-7.7,12.9-14.9,25.1-28.5,32.4c-18.1,9.7-38.1,6.3-51.7,1.6
    c-5.1-1.7-10-3.8-15-6.2c4.7-0.8,9.5-1.8,14.5-3.2c28.4-8.1,47.7-26.5,66.5-46.3c17-17.8,32.9-33.4,56.5-42.7
    c1.8-0.7,3.7-1.4,5.6-2c1.1-0.3,2.1-0.6,3.2-0.9c1.9-0.5,3.8-0.9,5.7-1.3c1.8-0.4,3.7-0.4,5.5-0.6c0.6-0.1,1.9-0.3,1.9-1
    c0-0.7-0.8-1.2-1.4-1.4c-0.4-0.1-0.8-0.1-1.3,0c-0.7,0.1-1.4,0.3-2,0.4c-0.1,0-0.2,0-0.3,0.1C203.8,263.9,201.3,264.3,198.8,264.8
    z M199.6,256.9c-1.6-0.5-3.2-1-4.7-1.5c-1.3-0.4-2.5-0.8-3.9-1.1c1.9-0.2,3.8-0.4,5.7-0.5c1.7,0,3.4,0.1,5.1,0.2
    c1.8,0.1,3.7,0.3,5.5,0.8c1.5,0.4,2.9,1,4.4,1.2c0.6,0.1,1.6-0.1,1.4-0.9c-0.1-0.5-0.6-1-1.1-1.2c-0.8-0.4-1.7-0.4-2.5-0.6
    c-1.2-0.3-2.5-0.5-3.7-0.7c-2.8-0.5-5.7-0.8-8.6-0.7c-1.8,0-3.5,0.2-5.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.4c-0.4,0.1-0.9,0.1-1.3,0.2
    c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.5,0.1-0.6,0.1c-12-3.3-25.6-6.1-39-3.6c-10,1.9-18.9,6.6-27.6,11.3c-3.5,1.9-7,3.7-10.6,5.4
    c-21.1-2.4-39.6-8.5-60.6-15.3c-2.1-0.7-4.3-1.4-6.4-2.1c-0.5-0.2-1.1,0.1-1.3,0.6c-0.2,0.5,0.1,1.1,0.6,1.3
    c2.2,0.7,4.3,1.4,6.4,2.1c19.9,6.5,37.7,12.3,57.7,15c-1.1,0.5-2.2,0.9-3.3,1.3c-19.7,7.4-40.6,7.3-60.3,6c-0.5,0-1,0.4-1.1,0.9
    c0,0.6,0.4,1,0.9,1.1c6,0.4,12.1,0.7,18.2,0.7c14.3,0,28.9-1.5,42.9-6.8c2.2-0.8,4.3-1.7,6.4-2.7c4.4,0.5,8.8,0.8,13.4,1
    c1.1,0,2.2,0.1,3.3,0.1c14.9,0,29.7-3.3,48.6-10.6c3.9-1.5,7.7-2.7,11.4-3.5c2.7,0.8,5.3,1.5,7.8,2.3c1.5,0.4,3,0.9,4.6,1.5
    c1.4,0.4,2.7,0.9,4.1,1.3c1.1,0.3,2.2,0.8,3.4,1.2c0.7,0.2,2.2,0.5,2.5-0.5c0.4-1.3-1.5-1.5-2.3-1.7c-0.6-0.2-1.2-0.4-1.8-0.5
    C203.1,258,201.3,257.5,199.6,256.9z M182.5,254c-2.6,0.7-5.3,1.6-8.1,2.7c-20.1,7.8-35.4,10.9-51.2,10.5
    c-3.4-0.1-6.8-0.3-10.1-0.6c2.6-1.3,5.3-2.7,7.8-4.1c8.5-4.6,17.3-9.3,27-11.1C159.7,249.2,171.7,251.2,182.5,254z"
                />
                <path
                  d="
    M515.8,168.5c-22.9,7-47.1,10.2-70.6,13.3c-3.6,0.5-7.2,0.9-10.8,1.4c-5.4,0.7-10.9,1.4-16.7,2.1c-37.6,4.5-80.1,9.6-107.5,36.9
    c-5.3,5.2-10.2,11.4-15,17.4c-8.8,11.1-18,22.5-30.1,29.5c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.7-0.2-0.9-0.5
    c-0.3-0.5-0.1-1.1,0.4-1.4c11.8-6.8,20.8-18.1,29.5-29c4.8-6,9.8-12.3,15.2-17.6c27.9-27.7,70.8-32.9,108.7-37.4
    c5.8-0.7,11.3-1.3,16.7-2.1c3.6-0.5,7.2-1,10.8-1.4c23.4-3.1,47.6-6.2,70.3-13.2c14.9-4.6,25.6-10.9,34.6-20.6
    c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4C542,157.3,531.1,163.8,515.8,168.5z"
                />
                <path
                  d="
    M233.5,249.5c0.7-2.6,1.6-5.1,2.5-7.5c1.8-5.3,3.7-10.8,4.3-16.6c1.1-10.7-2.9-21.1-6.7-31.2c-1.9-5-3.9-10.1-5.2-15.2
    c-6.2-24-9.3-45-9.5-64.3c-0.1-6.5,0.2-12.9,0.8-18.9c1.5-14.7,5.3-29.2,9-43.1l0.2-0.7c0.1-0.5,0.7-0.9,1.2-0.7
    c0.5,0.1,0.9,0.7,0.7,1.2l-0.2,0.7c-3.7,13.9-7.4,28.3-8.9,42.8c-0.6,6-0.9,12.3-0.8,18.7c0.2,19.1,3.3,40,9.4,63.8
    c1.3,5,3.2,10,5.1,14.9c3.9,10.3,8,21,6.9,32.1c-0.6,5.9-2.5,11.6-4.4,17c-0.9,2.6-1.8,5.2-2.6,7.9c-0.2,0.7-0.4,2.6-1.6,2.1
    C232.7,252.2,233.3,250.2,233.5,249.5z"
                />
                <path
                  d="
    M198.3,410c5.6-10.4,11.2-21,16.1-31.9c-0.2-2-0.3-4-0.5-5.9l-0.3-3.2c-0.2-2.6-0.5-5.2-0.7-7.9c-2.6-27.1-5.4-57.4,5.6-82.8
    c0.3-0.8,0.5-1.6,1-2.2c0.5-0.5,1.4-0.9,1.7-0.1c0.4,1-0.6,2.3-1,3.2c-10.8,25-8,55-5.5,81.8c0.3,2.7,0.5,5.3,0.7,7.9l0.3,3.2
    c0.1,0.8,0.1,1.5,0.2,2.3c3.4-7.8,6.3-15.8,8.4-24c3.2-12.2,2.1-23.6,0.4-36.1c-0.7-5.5-1.4-10.9-1.7-16.4
    c-0.3-5.5-0.4-11,0.2-16.5c0.1-0.9,0.2-1.7,0.3-2.6c0.1-0.6,0.1-1.5,0.6-2c0.7-0.7,1.5,0.2,1.6,0.9c0.1,0.9-0.2,1.9-0.3,2.8
    c-0.2,0.9-0.3,1.8-0.3,2.7c-0.1,1.2-0.2,2.5-0.2,3.7c-0.2,5.8,0.1,11.7,0.7,17.4c0.3,3.2,0.7,6.4,1.2,9.6
    c1.7,12.7,2.8,24.3-0.5,36.9c-2.4,9.4-5.9,18.6-9.9,27.6c1.1,13.1,1.8,27.3-1.6,39.8c-1.8,6.9-5.9,13-9.8,19
    c-1.7,2.7-3.5,5.4-5.1,8.1c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.4c1.6-2.8,3.4-5.5,5.1-8.2
    c3.8-5.9,7.8-11.9,9.6-18.4c3-11.1,2.7-23.6,1.8-35.5c-4.5,9.8-9.6,19.3-14.7,28.8c-2.1,4-4.4,8.2-6.5,12.2
    c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.3C193.9,418.2,196.1,414,198.3,410z"
                />
              </g>
            </g>
            <g title="Microtubule ends" className="microtubule_ends_ton">
              <title>Microtubules ends</title>
              <path
                d="M434.4,462.6c0,0-0.1,0-0.1,0
  c-1-0.1-4-0.6-7.5-1.1c-2.8-0.5-5.7-0.9-7.6-1.2c-0.6-0.1-0.6-0.6-0.5-1.2c0.1-0.6,0.2-1,0.8-0.9c1.9,0.3,4.8,0.7,7.6,1.2
  c3.3,0.5,6.4,1,7.3,1.1c0.6,0.1,1,0.6,1,1.2C435.5,462.1,435,462.6,434.4,462.6z"
              />
              <path
                d="M570.6,411.2c-0.2-0.6-0.2-1.1,0.4-1.3
  c2.9-1,11.5-4.5,13.7-5.6c0.5-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.1,1.2-0.5,1.5c-2.3,1.1-11,4.8-14,5.7
  C571.4,412.2,570.9,412,570.6,411.2z"
              />
              <path
                d="M583.4,348.6c-0.4-0.4-0.8-0.8-0.3-1.2
  c1.3-1,4.3-3.4,6.8-5.5c1.3-1.1,2.5-2.1,3.3-2.7c0.5-0.4,1.2-0.3,1.5,0.2c0.4,0.5,0.3,1.2-0.2,1.5c-0.8,0.6-2,1.6-3.3,2.7
  c-2.5,2-5.5,4.5-6.8,5.5C584.3,349.3,583.9,349.1,583.4,348.6z"
              />
              <path
                d="M588.2,264.5c-0.2,0-0.3,0-0.5-0.1
  c-2.4-1.2-10.5-4.8-13.3-6c-0.6-0.2-0.4-0.7-0.1-1.3c0.2-0.6,0.4-1,0.9-0.8c3,1.1,11,4.8,13.5,6c0.5,0.3,0.8,0.9,0.5,1.5
  C588.9,264.3,588.6,264.5,588.2,264.5z"
              />
              <path
                d="M547.4,150c-0.4-0.4-0.7-0.9-0.3-1.4
  c3.2-3.1,8-9,10.3-11.9c0.4-0.5,1.1-0.6,1.5-0.2c0.5,0.4,0.6,1.1,0.2,1.5c-2.3,3-7.2,8.9-10.5,12.1
  C548.3,150.5,547.9,150.5,547.4,150z"
              />
              <path
                d="M540.1,69.6c-0.5-0.3-0.9-0.6-0.6-1.2
  c1.1-1.7,4.7-9.1,5.7-11.1c0.3-0.6,0.9-0.8,1.5-0.5c0.6,0.3,0.8,0.9,0.5,1.5c-1,2.2-4.7,9.5-5.8,11.4
  C541.2,69.9,540.8,70,540.1,69.6z"
              />
              <path
                d="M499.1,43.4c-0.5,0-0.5-0.4-0.6-0.9
  c-0.1-0.6-0.2-1.1,0.4-1.2c3.5-0.7,9.1-1.6,14.5-2.4c0.6-0.1,1.2,0.3,1.2,0.9c0.1,0.6-0.3,1.2-0.9,1.2c-5.4,0.8-10.9,1.7-14.4,2.4
  C499.2,43.4,499.2,43.4,499.1,43.4z"
              />
              <path
                d="M376.6,48.7c-0.5-0.4-0.8-0.9-0.5-1.4
  c3.2-4.2,5.5-8.9,7.2-12.5c0.3-0.6,0.9-0.8,1.5-0.5c0.6,0.3,0.8,0.9,0.5,1.5c-1.7,3.7-4.1,8.5-7.4,12.9
  C377.6,49.1,377.2,49.1,376.6,48.7z"
              />
              <path
                d="M284.6,35.3c-1.2-3.3-2.8-6.3-4.3-9.1
  c-0.3-0.5-0.1-1.2,0.4-1.5c0.5-0.3,1.2-0.1,1.5,0.4c1.6,2.9,3.2,5.9,4.4,9.4c0.2,0.6-0.2,0.7-0.8,0.9
  C285.2,35.7,284.8,35.7,284.6,35.3z"
              />
              <path
                d="M229.5,53.2c-0.7-0.2-1-0.2-0.8-0.8
  c1-3.8,2.1-8.3,3.3-12.5c0.2-0.6,0.8-0.9,1.4-0.8c0.6,0.2,0.9,0.8,0.8,1.4c-1.2,4.2-2.3,8.6-3.2,12.5
  C230.7,53.4,230.2,53.3,229.5,53.2z"
              />
              <path
                d="M183.9,56.4c-0.7-0.4-0.9-0.6-0.5-1.1
  c2.7-3.7,4.5-6,6.9-8.5c0.4-0.4,1.1-0.5,1.6-0.1c0.4,0.4,0.5,1.1,0.1,1.6c-2.3,2.5-4.1,4.7-6.7,8.3C185,56.9,184.6,56.8,183.9,56.4
  z"
              />
              <path
                d="M123.5,138.4c-0.4-1-0.9-2.2-1.4-3.4
  c-1-2.4-2.2-5.2-3.1-7.6c-0.2-0.6,0.1-1.2,0.7-1.4c0.6-0.2,1.2,0.1,1.4,0.7c0.8,2.4,2,5.1,3,7.5c0.5,1.2,1,2.4,1.5,3.4
  c0.2,0.6-0.1,0.9-0.7,1.1C124.1,138.8,123.7,138.8,123.5,138.4z"
              />
              <path
                d="M127.7,187.3c-1.8-4.2-3-8.4-3.6-12.4
  c-0.1-0.6,0.3-1.2,0.9-1.2c0.6-0.1,1.2,0.3,1.2,0.9c0.6,3.9,1.7,7.9,3.5,11.9c0.2,0.6-0.1,0.8-0.7,1
  C128.3,187.7,127.8,187.8,127.7,187.3z"
              />
              <path
                d="M52.3,254.1c-0.1,0-0.2,0-0.3,0
  c-3.4-1-8.4-2.6-11.9-3.9c-0.6-0.2-0.9-0.8-0.7-1.4c0.2-0.6,0.8-0.9,1.4-0.7c3.5,1.3,8.4,2.8,11.8,3.9c0.6,0.2,0.6,0.7,0.4,1.3
  C53,253.8,52.8,254.1,52.3,254.1z"
              />
              <path
                d="M45.9,277.3
  C45.9,277.3,45.9,277.3,45.9,277.3c-3.8-0.2-6.9-0.4-9.8-0.6c-1.3-0.1-2.5-0.2-3.8-0.2c-0.6,0-1.1-0.6-1-1.2c0-0.6,0.6-1.1,1.2-1
  c1.3,0.1,2.5,0.2,3.8,0.2c2.9,0.2,6,0.4,9.7,0.5c0.6,0,0.7,0.5,0.7,1.1C46.6,276.8,46.5,277.3,45.9,277.3z"
              />
              <path
                d="M20.8,348.2c-3.4-1.9-7.1-4.1-9.4-5.3
  c-0.5-0.3-0.7-1-0.5-1.5c0.3-0.5,1-0.7,1.5-0.5c2.3,1.2,6.1,3.4,9.4,5.4c0.5,0.3,0.3,0.8,0,1.4C21.6,348.2,21.3,348.5,20.8,348.2z"
              />
              <path
                d="M20.3,364.8c-1.9,0-3.6,0-5.4-0.1
  c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1l0.1,0c3.6,0.1,7.1,0.2,11.3-0.1c0.6,0,0.7,0.5,0.7,1.1c0,0.6,0,1.1-0.6,1.1
  C24.2,364.8,22.2,364.8,20.3,364.8z"
              />
              <path
                d="M128,421.3c-0.3,0-0.7-0.2-0.9-0.4
  c-0.4-0.5-0.3-1.2,0.2-1.5c3-2.2,6-4.6,8.5-6.7c0.5-0.4,1.2-0.3,1.5,0.1c0.4,0.5,0.3,1.2-0.1,1.5c-2.5,2.1-5.6,4.5-8.6,6.8
  C128.5,421.2,128.2,421.3,128,421.3z"
              />
              <path
                d="M187.9,432.7c-0.2,0-0.4,0-0.5-0.1
  c-0.5-0.3-0.7-1-0.4-1.5c1.2-2.3,3.3-6.1,4.8-8.9l0.6-1.1c0.3-0.5,0.6-0.3,1.2,0c0.5,0.3,1.1,0.5,0.8,1.1l-0.6,1.1
  c-1.5,2.8-3.5,6.6-4.8,8.9C188.7,432.5,188.3,432.7,187.9,432.7z"
              />
              <path
                d="M194.6,454.8c-0.2,0-0.3,0-0.5-0.1
  c-0.5-0.3-0.8-0.9-0.5-1.5l0.9-1.9c1.1-2.4,1.8-3.8,3.6-7c0.3-0.5,0.7-0.3,1.2-0.1c0.5,0.3,1,0.6,0.7,1.1c-1.7,3.1-2.4,4.5-3.5,6.9
  l-0.9,1.9C195.4,454.5,195,454.8,194.6,454.8z"
              />
              <path
                d="M301.1,469.9c-0.4,0-0.8-0.2-1-0.6
  c-1.8-3.6-3.8-9.1-4.7-12.3c-0.2-0.6,0.3-0.7,0.9-0.9c0.6-0.2,1.1-0.3,1.3,0.3c0.9,3,2.8,8.4,4.5,11.9c0.3,0.5,0,1.2-0.5,1.5
  C301.4,469.9,301.3,469.9,301.1,469.9z"
              />
              <path
                d="M472.7,397.3c-0.1,0-0.2,0-0.3,0
  c-1.2-0.3-2.4-0.6-3.6-0.8c-4.3-1-8.4-1.9-12.2-3c-0.6-0.2-0.4-0.6-0.2-1.2c0.2-0.6,0.3-1,0.8-0.9c3.8,1.1,7.8,2,12.1,3
  c1.2,0.3,2.4,0.6,3.6,0.8c0.6,0.1,1,0.7,0.8,1.3C473.6,397,473.2,397.3,472.7,397.3z"
              />
            </g>
            <g
              title="Microtubule ends"
              className={locations.includes('Microtubule ends') ? 'microtubule_ends' : 'microtubule_ends--disabled'}
            >
              <title>Microtubules ends</title>
              <path
                d="
  M434.4,462.6c0,0-0.1,0-0.1,0c-1-0.1-4-0.6-7.5-1.1c-2.8-0.5-5.7-0.9-7.6-1.2c-0.6-0.1-0.6-0.6-0.5-1.2c0.1-0.6,0.2-1,0.8-0.9
  c1.9,0.3,4.8,0.7,7.6,1.2c3.3,0.5,6.4,1,7.3,1.1c0.6,0.1,1,0.6,1,1.2C435.5,462.1,435,462.6,434.4,462.6z"
              />
              <path
                d="
  M570.6,411.2c-0.2-0.6-0.2-1.1,0.4-1.3c2.9-1,11.5-4.5,13.7-5.6c0.5-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.1,1.2-0.5,1.5
  c-2.3,1.1-11,4.8-14,5.7C571.4,412.2,570.9,412,570.6,411.2z"
              />
              <path
                d="
  M583.4,348.6c-0.4-0.4-0.8-0.8-0.3-1.2c1.3-1,4.3-3.4,6.8-5.5c1.3-1.1,2.5-2.1,3.3-2.7c0.5-0.4,1.2-0.3,1.5,0.2
  c0.4,0.5,0.3,1.2-0.2,1.5c-0.8,0.6-2,1.6-3.3,2.7c-2.5,2-5.5,4.5-6.8,5.5C584.3,349.3,583.9,349.1,583.4,348.6z"
              />
              <path
                d="
  M588.2,264.5c-0.2,0-0.3,0-0.5-0.1c-2.4-1.2-10.5-4.8-13.3-6c-0.6-0.2-0.4-0.7-0.1-1.3c0.2-0.6,0.4-1,0.9-0.8c3,1.1,11,4.8,13.5,6
  c0.5,0.3,0.8,0.9,0.5,1.5C588.9,264.3,588.6,264.5,588.2,264.5z"
              />
              <path
                d="
  M547.4,150c-0.4-0.4-0.7-0.9-0.3-1.4c3.2-3.1,8-9,10.3-11.9c0.4-0.5,1.1-0.6,1.5-0.2c0.5,0.4,0.6,1.1,0.2,1.5
  c-2.3,3-7.2,8.9-10.5,12.1C548.3,150.5,547.9,150.5,547.4,150z"
              />
              <path
                d="
  M540.1,69.6c-0.5-0.3-0.9-0.6-0.6-1.2c1.1-1.7,4.7-9.1,5.7-11.1c0.3-0.6,0.9-0.8,1.5-0.5c0.6,0.3,0.8,0.9,0.5,1.5
  c-1,2.2-4.7,9.5-5.8,11.4C541.2,69.9,540.8,70,540.1,69.6z"
              />
              <path
                d="
  M499.1,43.4c-0.5,0-0.5-0.4-0.6-0.9c-0.1-0.6-0.2-1.1,0.4-1.2c3.5-0.7,9.1-1.6,14.5-2.4c0.6-0.1,1.2,0.3,1.2,0.9
  c0.1,0.6-0.3,1.2-0.9,1.2c-5.4,0.8-10.9,1.7-14.4,2.4C499.2,43.4,499.2,43.4,499.1,43.4z"
              />
              <path
                d="
  M376.6,48.7c-0.5-0.4-0.8-0.9-0.5-1.4c3.2-4.2,5.5-8.9,7.2-12.5c0.3-0.6,0.9-0.8,1.5-0.5c0.6,0.3,0.8,0.9,0.5,1.5
  c-1.7,3.7-4.1,8.5-7.4,12.9C377.6,49.1,377.2,49.1,376.6,48.7z"
              />
              <path
                d="
  M284.6,35.3c-1.2-3.3-2.8-6.3-4.3-9.1c-0.3-0.5-0.1-1.2,0.4-1.5c0.5-0.3,1.2-0.1,1.5,0.4c1.6,2.9,3.2,5.9,4.4,9.4
  c0.2,0.6-0.2,0.7-0.8,0.9C285.2,35.7,284.8,35.7,284.6,35.3z"
              />
              <path
                d="
  M229.5,53.2c-0.7-0.2-1-0.2-0.8-0.8c1-3.8,2.1-8.3,3.3-12.5c0.2-0.6,0.8-0.9,1.4-0.8c0.6,0.2,0.9,0.8,0.8,1.4
  c-1.2,4.2-2.3,8.6-3.2,12.5C230.7,53.4,230.2,53.3,229.5,53.2z"
              />
              <path
                d="
  M183.9,56.4c-0.7-0.4-0.9-0.6-0.5-1.1c2.7-3.7,4.5-6,6.9-8.5c0.4-0.4,1.1-0.5,1.6-0.1c0.4,0.4,0.5,1.1,0.1,1.6
  c-2.3,2.5-4.1,4.7-6.7,8.3C185,56.9,184.6,56.8,183.9,56.4z"
              />
              <path
                d="
  M123.5,138.4c-0.4-1-0.9-2.2-1.4-3.4c-1-2.4-2.2-5.2-3.1-7.6c-0.2-0.6,0.1-1.2,0.7-1.4c0.6-0.2,1.2,0.1,1.4,0.7
  c0.8,2.4,2,5.1,3,7.5c0.5,1.2,1,2.4,1.5,3.4c0.2,0.6-0.1,0.9-0.7,1.1C124.1,138.8,123.7,138.8,123.5,138.4z"
              />
              <path
                d="
  M127.7,187.3c-1.8-4.2-3-8.4-3.6-12.4c-0.1-0.6,0.3-1.2,0.9-1.2c0.6-0.1,1.2,0.3,1.2,0.9c0.6,3.9,1.7,7.9,3.5,11.9
  c0.2,0.6-0.1,0.8-0.7,1C128.3,187.7,127.8,187.8,127.7,187.3z"
              />
              <path
                d="
  M52.3,254.1c-0.1,0-0.2,0-0.3,0c-3.4-1-8.4-2.6-11.9-3.9c-0.6-0.2-0.9-0.8-0.7-1.4c0.2-0.6,0.8-0.9,1.4-0.7
  c3.5,1.3,8.4,2.8,11.8,3.9c0.6,0.2,0.6,0.7,0.4,1.3C53,253.8,52.8,254.1,52.3,254.1z"
              />
              <path
                d="
  M45.9,277.3C45.9,277.3,45.9,277.3,45.9,277.3c-3.8-0.2-6.9-0.4-9.8-0.6c-1.3-0.1-2.5-0.2-3.8-0.2c-0.6,0-1.1-0.6-1-1.2
  c0-0.6,0.6-1.1,1.2-1c1.3,0.1,2.5,0.2,3.8,0.2c2.9,0.2,6,0.4,9.7,0.5c0.6,0,0.7,0.5,0.7,1.1C46.6,276.8,46.5,277.3,45.9,277.3z"
              />
              <path
                d="
  M20.8,348.2c-3.4-1.9-7.1-4.1-9.4-5.3c-0.5-0.3-0.7-1-0.5-1.5c0.3-0.5,1-0.7,1.5-0.5c2.3,1.2,6.1,3.4,9.4,5.4
  c0.5,0.3,0.3,0.8,0,1.4C21.6,348.2,21.3,348.5,20.8,348.2z"
              />
              <path
                d="
  M20.3,364.8c-1.9,0-3.6,0-5.4-0.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1l0.1,0c3.6,0.1,7.1,0.2,11.3-0.1
  c0.6,0,0.7,0.5,0.7,1.1c0,0.6,0,1.1-0.6,1.1C24.2,364.8,22.2,364.8,20.3,364.8z"
              />
              <path
                d="
  M128,421.3c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.3-1.2,0.2-1.5c3-2.2,6-4.6,8.5-6.7c0.5-0.4,1.2-0.3,1.5,0.1c0.4,0.5,0.3,1.2-0.1,1.5
  c-2.5,2.1-5.6,4.5-8.6,6.8C128.5,421.2,128.2,421.3,128,421.3z"
              />
              <path
                d="
  M187.9,432.7c-0.2,0-0.4,0-0.5-0.1c-0.5-0.3-0.7-1-0.4-1.5c1.2-2.3,3.3-6.1,4.8-8.9l0.6-1.1c0.3-0.5,0.6-0.3,1.2,0
  c0.5,0.3,1.1,0.5,0.8,1.1l-0.6,1.1c-1.5,2.8-3.5,6.6-4.8,8.9C188.7,432.5,188.3,432.7,187.9,432.7z"
              />
              <path
                d="
  M194.6,454.8c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.8-0.9-0.5-1.5l0.9-1.9c1.1-2.4,1.8-3.8,3.6-7c0.3-0.5,0.7-0.3,1.2-0.1
  c0.5,0.3,1,0.6,0.7,1.1c-1.7,3.1-2.4,4.5-3.5,6.9l-0.9,1.9C195.4,454.5,195,454.8,194.6,454.8z"
              />
              <path
                d="
  M301.1,469.9c-0.4,0-0.8-0.2-1-0.6c-1.8-3.6-3.8-9.1-4.7-12.3c-0.2-0.6,0.3-0.7,0.9-0.9c0.6-0.2,1.1-0.3,1.3,0.3
  c0.9,3,2.8,8.4,4.5,11.9c0.3,0.5,0,1.2-0.5,1.5C301.4,469.9,301.3,469.9,301.1,469.9z"
              />
              <path
                d="
  M472.7,397.3c-0.1,0-0.2,0-0.3,0c-1.2-0.3-2.4-0.6-3.6-0.8c-4.3-1-8.4-1.9-12.2-3c-0.6-0.2-0.4-0.6-0.2-1.2c0.2-0.6,0.3-1,0.8-0.9
  c3.8,1.1,7.8,2,12.1,3c1.2,0.3,2.4,0.6,3.6,0.8c0.6,0.1,1,0.7,0.8,1.3C473.6,397,473.2,397.3,472.7,397.3z"
              />
            </g>
            <g title="Secreted proteins" className="secreted_proteins_ton">
              <title>Secreted proteins</title>
              <path
                d="M55.4,228.6c0.6-0.5,0.1-1.6-0.6-1.8
  C52.8,226.4,54.1,229.8,55.4,228.6z"
              />
              <path
                d="M57,227.6c1.1-0.5,1-2-0.2-2.1
  C54.5,225.2,55.4,228.4,57,227.6z"
              />
              <path
                d="M55.9,223.3c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C58.1,222.4,56.4,222.3,55.9,223.3z"
              />
              <path
                d="M52.8,226.6c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C52.1,226.1,52.4,226.4,52.8,226.6z"
              />
              <path
                d="M55.7,222.5c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C55.4,224.2,55.9,223.2,55.7,222.5z"
              />
              <path
                d="M49.8,223.5c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C49.7,223.1,49.8,223.3,49.8,223.5z"
              />
              <path
                d="M50.7,219.5c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C49.7,219.9,50.5,219.7,50.7,219.5z"
              />
              <path
                d="M47.5,224.1c1.4-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C45.6,223.5,46.4,224.4,47.5,224.1z"
              />
              <path
                d="M50,212.5c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C52.6,213.3,51.3,211.7,50,212.5z"
              />
              <path
                d="M43.6,215.6c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C44.3,215.2,43.9,215.3,43.6,215.6z"
              />
              <path
                d="M41.3,224c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C39,222.1,39.8,225.2,41.3,224z"
              />
              <path
                d="M365,15.4c0.6-0.5,0.1-1.6-0.6-1.8
  C362.4,13.2,363.7,16.6,365,15.4z"
              />
              <path
                d="M357.9,15.9c1.1-0.5,1-2-0.2-2.1
  C355.4,13.5,356.3,16.6,357.9,15.9z"
              />
              <path
                d="M360.3,20.3c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C362.5,19.4,360.8,19.3,360.3,20.3z"
              />
              <path
                d="M360.5,13.3c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C359.8,12.8,360.1,13.1,360.5,13.3z"
              />
              <path
                d="M363.7,17.8c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C363.4,19.5,363.9,18.5,363.7,17.8z"
              />
              <path
                d="M358.1,17.9c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C358.1,17.5,358.1,17.7,358.1,17.9z"
              />
              <path
                d="M356.8,10.3c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C355.9,10.7,356.6,10.6,356.8,10.3z"
              />
              <path
                d="M358.5,21.8c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C356.6,21.3,357.4,22.2,358.5,21.8z"
              />
              <path
                d="M366.4,9.3c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C369,10.1,367.7,8.5,366.4,9.3z"
              />
              <path
                d="M350.7,11.3c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C351.4,10.8,351,11,350.7,11.3z"
              />
              <path
                d="M372,14.9c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C369.6,13,370.4,16.1,372,14.9z"
              />
              <path
                d="M516,436.1c0.7-0.2,0.9-1.3,0.4-1.8
  C514.8,433,514.3,436.6,516,436.1z"
              />
              <path
                d="M519.8,440.5c0.7-0.2,0.9-1.3,0.4-1.8
  C518.6,437.3,518,440.9,519.8,440.5z"
              />
              <path
                d="M517.9,436.1c1.2,0.1,1.9-1.2,0.9-1.9
  C516.9,432.8,516.1,436,517.9,436.1z"
              />
              <path
                d="M518.2,444c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C520.4,443,518.8,442.9,518.2,444z"
              />
              <path
                d="M516.4,448.9c0.2,0.6,0.7,1.1,1.3,0.8
  c0.7-0.4,0.7-1.4,0.1-1.8c-0.5-0.4-1.4-0.2-1.4,0.5C516.3,448.6,516.3,448.7,516.4,448.9z"
              />
              <path
                d="M525.3,443.4c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C524.4,443.8,525.1,443.6,525.3,443.4z"
              />
              <path
                d="M517.6,439.2c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C515.7,438.7,516.5,439.5,517.6,439.2z"
              />
              <path
                d="M513.1,435.6c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C515.7,436.4,514.3,434.8,513.1,435.6z"
              />
              <path
                d="M514.4,439.7c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C515.1,439.2,514.6,439.4,514.4,439.7z"
              />
              <path
                d="M511.5,448.4c0.4,0.3,1,0.3,1.5-0.2
  c0.3-0.3,0.5-0.8,0.3-1.2C512.6,445.6,509.9,447.3,511.5,448.4z"
              />
              <path
                d="M579.6,189.3c-0.3-0.7-1.4-0.7-1.8-0.1
  C576.6,190.9,580.2,191,579.6,189.3z"
              />
              <path
                d="M588.3,193.7c1.1-0.5,1-2-0.2-2.1
  C585.7,191.3,586.7,194.5,588.3,193.7z"
              />
              <path
                d="M576,186.9c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C578.2,186,576.5,185.9,576,186.9z"
              />
              <path
                d="M588.2,188.6c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C587.5,188.1,587.8,188.5,588.2,188.6z"
              />
              <path
                d="M583.8,187.8c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C583.4,189.6,583.9,188.5,583.8,187.8z"
              />
              <path
                d="M582.6,184.4c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C582.6,184,582.6,184.2,582.6,184.4z"
              />
              <path
                d="M578.3,185.6c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C577.4,186.1,578.1,185.9,578.3,185.6z"
              />
              <path
                d="M584.7,180c1.3-0.3,0.8-1.8-0.1-2.2
  c-1.1-0.5-1.7,0.9-1.1,1.7C583.7,179.8,584.4,180.1,584.7,180z"
              />
              <path
                d="M576.1,192.2c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C574.3,191.6,575.1,192.5,576.1,192.2z"
              />
              <path
                d="M573.9,183.8c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C576.5,184.6,575.2,183,573.9,183.8z"
              />
              <path
                d="M573,187c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C573.7,186.6,573.3,186.8,573,187z"
              />
              <path
                d="M580.3,186.7c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C578,184.8,578.8,187.9,580.3,186.7z"
              />
            </g>
            <g
              title="Secreted proteins"
              className={locations.includes('Secreted proteins') ? 'secreted_proteins' : 'secreted_proteins--disabled'}
            >
              <title>Secreted proteins</title>
              <path
                d="M55.4,228.6c0.6-0.5,0.1-1.6-0.6-1.8
  C52.8,226.4,54.1,229.8,55.4,228.6z"
              />
              <path
                d="M57,227.6c1.1-0.5,1-2-0.2-2.1
  C54.5,225.2,55.4,228.4,57,227.6z"
              />
              <path
                d="M55.9,223.3c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C58.1,222.4,56.4,222.3,55.9,223.3z"
              />
              <path
                d="M52.8,226.6c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C52.1,226.1,52.4,226.4,52.8,226.6z"
              />
              <path
                d="M55.7,222.5c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C55.4,224.2,55.9,223.2,55.7,222.5z"
              />
              <path
                d="M49.8,223.5c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C49.7,223.1,49.8,223.3,49.8,223.5z"
              />
              <path
                d="M50.7,219.5c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C49.7,219.9,50.5,219.7,50.7,219.5z"
              />
              <path
                d="M47.5,224.1c1.4-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C45.6,223.5,46.4,224.4,47.5,224.1z"
              />
              <path
                d="M50,212.5c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C52.6,213.3,51.3,211.7,50,212.5z"
              />
              <path
                d="M43.6,215.6c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C44.3,215.2,43.9,215.3,43.6,215.6z"
              />
              <path
                d="M41.3,224c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C39,222.1,39.8,225.2,41.3,224z"
              />
              <path
                d="M365,15.4c0.6-0.5,0.1-1.6-0.6-1.8
  C362.4,13.2,363.7,16.6,365,15.4z"
              />
              <path
                d="M357.9,15.9c1.1-0.5,1-2-0.2-2.1
  C355.4,13.5,356.3,16.6,357.9,15.9z"
              />
              <path
                d="M360.3,20.3c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C362.5,19.4,360.8,19.3,360.3,20.3z"
              />
              <path
                d="M360.5,13.3c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C359.8,12.8,360.1,13.1,360.5,13.3z"
              />
              <path
                d="M363.7,17.8c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C363.4,19.5,363.9,18.5,363.7,17.8z"
              />
              <path
                d="M358.1,17.9c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C358.1,17.5,358.1,17.7,358.1,17.9z"
              />
              <path
                d="M356.8,10.3c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C355.9,10.7,356.6,10.6,356.8,10.3z"
              />
              <path
                d="M358.5,21.8c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C356.6,21.3,357.4,22.2,358.5,21.8z"
              />
              <path
                d="M366.4,9.3c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C369,10.1,367.7,8.5,366.4,9.3z"
              />
              <path
                d="M350.7,11.3c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C351.4,10.8,351,11,350.7,11.3z"
              />
              <path
                d="M372,14.9c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C369.6,13,370.4,16.1,372,14.9z"
              />
              <path
                d="M516,436.1c0.7-0.2,0.9-1.3,0.4-1.8
  C514.8,433,514.3,436.6,516,436.1z"
              />
              <path
                d="M519.8,440.5c0.7-0.2,0.9-1.3,0.4-1.8
  C518.6,437.3,518,440.9,519.8,440.5z"
              />
              <path
                d="M517.9,436.1c1.2,0.1,1.9-1.2,0.9-1.9
  C516.9,432.8,516.1,436,517.9,436.1z"
              />
              <path
                d="M518.2,444c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C520.4,443,518.8,442.9,518.2,444z"
              />
              <path
                d="M516.4,448.9c0.2,0.6,0.7,1.1,1.3,0.8
  c0.7-0.4,0.7-1.4,0.1-1.8c-0.5-0.4-1.4-0.2-1.4,0.5C516.3,448.6,516.3,448.7,516.4,448.9z"
              />
              <path
                d="M525.3,443.4c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C524.4,443.8,525.1,443.6,525.3,443.4z"
              />
              <path
                d="M517.6,439.2c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C515.7,438.7,516.5,439.5,517.6,439.2z"
              />
              <path
                d="M513.1,435.6c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C515.7,436.4,514.3,434.8,513.1,435.6z"
              />
              <path
                d="M514.4,439.7c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C515.1,439.2,514.6,439.4,514.4,439.7z"
              />
              <path
                d="M511.5,448.4c0.4,0.3,1,0.3,1.5-0.2
  c0.3-0.3,0.5-0.8,0.3-1.2C512.6,445.6,509.9,447.3,511.5,448.4z"
              />
              <path
                d="M579.6,189.3c-0.3-0.7-1.4-0.7-1.8-0.1
  C576.6,190.9,580.2,191,579.6,189.3z"
              />
              <path
                d="M588.3,193.7c1.1-0.5,1-2-0.2-2.1
  C585.7,191.3,586.7,194.5,588.3,193.7z"
              />
              <path
                d="M576,186.9c-0.3,0.7-0.2,1.7,0.6,2
  c1.2,0.4,2.1-0.8,1.9-1.8C578.2,186,576.5,185.9,576,186.9z"
              />
              <path
                d="M588.2,188.6c0.9,0.4,2.3-0.1,2.1-1.3
  c-0.2-1.5-2.6-1.1-2.8,0.2C587.5,188.1,587.8,188.5,588.2,188.6z"
              />
              <path
                d="M583.8,187.8c-0.1-0.5-1.1-1.1-1.6-1.1
  c-0.5,0-0.9,0.3-1,0.7c-0.2,0.8,0.5,2,1.4,2C583.4,189.6,583.9,188.5,583.8,187.8z"
              />
              <path
                d="M582.6,184.4c0.2,0.8,1,1.6,1.9,1.1
  c1-0.5,0.9-1.9,0.1-2.4c-0.7-0.5-1.9-0.3-2,0.7C582.6,184,582.6,184.2,582.6,184.4z"
              />
              <path
                d="M578.3,185.6c0.8-1.1-0.5-1.9-1.5-1.7
  c-1.1,0.3-0.8,1.8,0.2,2C577.4,186.1,578.1,185.9,578.3,185.6z"
              />
              <path
                d="M584.7,180c1.3-0.3,0.8-1.8-0.1-2.2
  c-1.1-0.5-1.7,0.9-1.1,1.7C583.7,179.8,584.4,180.1,584.7,180z"
              />
              <path
                d="M576.1,192.2c1.5-0.4,1.6-2.4,0.1-2.9
  c-1-0.3-2.2,0.3-2,1.4C574.3,191.6,575.1,192.5,576.1,192.2z"
              />
              <path
                d="M573.9,183.8c-0.8,0.5-1.2,1.6-0.1,2.4
  c0.7,0.5,1.9,0.6,2.2-0.3C576.5,184.6,575.2,183,573.9,183.8z"
              />
              <path
                d="M573,187c-0.3,0.3-0.5,0.7-0.3,1.2
  c0.5,1.1,2.1,1.6,2.8,0.4c0.5-0.9-0.6-1.8-1.4-1.9C573.7,186.6,573.3,186.8,573,187z"
              />
              <path
                d="M580.3,186.7c0.4-0.3,0.6-0.9,0.2-1.5
  c-0.2-0.3-0.6-0.8-1.1-0.7C578,184.8,578.8,187.9,580.3,186.7z"
              />
            </g>
            <g title="Lipid droplets" className="lipid_droplets_ton">
              <title>Lipid droplets</title>
              <path
                d="M429.8,340.9c2.3-2,1.8-7.3-1-8.6
  c-4.4-2-9.5,4.8-6.4,8.3C424.3,342.5,427.9,342.7,429.8,340.9z"
              />
              <path
                d="M428.9,154.2c1.2-3-2.3-7-5.5-5.3
  C417.9,152.1,426.9,159.5,428.9,154.2z"
              />
              <path
                d="M461.9,284.3c4.8,0.1,4.4-9.6-1-8.3
  C456.6,276.9,457.6,284.1,461.9,284.3z"
              />
              <path
                d="M244.6,320.3c4.8,0.1,4.4-9.6-1-8.3
  C239.3,312.9,240.3,320.1,244.6,320.3z"
              />
              <path
                d="M305.8,128.7c4.8,0.1,4.4-9.6-1-8.3
  C300.5,121.4,301.6,128.6,305.8,128.7z"
              />
              <path
                d="M424.5,345.6c2-4.3-7-7.8-8-2.3
  C415.7,347.6,422.7,349.5,424.5,345.6z"
              />
              <path
                d="M354.2,363.6c2.3-2,1.8-7.3-1-8.6
  c-4.4-2-9.5,4.8-6.4,8.3C348.6,365.2,352.2,365.4,354.2,363.6z"
              />
              <path
                d="M338.5,132.6c2-4.3-7-7.8-8-2.3
  C329.7,134.6,336.7,136.5,338.5,132.6z"
              />
              <path
                d="M322.8,349.6c2-4.3-7-7.8-8-2.3
  C314,351.6,321,353.5,322.8,349.6z"
              />
              <path
                d="M309.4,347.9c5,3,9.2-5.6,5.9-9.1
  C310.4,333.6,303.6,344.5,309.4,347.9z"
              />
              <path
                d="M471.9,215c5,2.6,9.2-4.8,5.9-7.8
  C472.9,202.7,466.1,212.1,471.9,215z"
              />
              <path
                d="M384.9,132.7c-2.2-3.4-9.5-1.2-7.5,3.1
  C379.4,140.2,388,137.4,384.9,132.7z"
              />
              <path
                d="M304,347.2c-3.8,4,3.8,6.8,4.1,1.5
  c0.2-3-2.7-3.8-4.4-1.5"
              />
              <path
                d="M433.5,346.3c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C433.1,346.3,433.3,346.3,433.5,346.3z"
              />
              <path
                d="M444.2,304.3c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C443.7,304.3,444,304.3,444.2,304.3z"
              />
              <path
                d="M444.7,176.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C444.2,176.1,444.5,176.1,444.7,176.1z"
              />
              <path
                d="M277.7,140.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C277.2,140.1,277.5,140.1,277.7,140.1z"
              />
              <path
                d="M289.7,368.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C289.2,368.1,289.5,368.1,289.7,368.1z"
              />
              <path
                d="M371.4,369.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C370.9,369.1,371.1,369.1,371.4,369.1z"
              />
              <path
                d="M440.6,156.1c-0.6-6.1-8.5-1.6-6.5,2.3
  C436.3,162.5,441,160.4,440.6,156.1z"
              />
              <path
                d="M264.5,352.2c0.8,3.1,6,4.3,7.6,1
  C274.6,347.3,263,346.7,264.5,352.2z"
              />
              <path
                d="M254,357.6c4.1,4.6,7.8-3.7,3.8-5.7
  C253.6,349.9,251,354.4,254,357.6z"
              />
              <path
                d="M474.5,224.3c2.4-3.2-2.3-9.3-5.6-5.9
  C465.6,221.8,471.2,228.8,474.5,224.3z"
              />
              <path
                d="M481.3,217.6c-0.6-6.1-8.5-1.6-6.5,2.3
  C477,224,481.7,221.9,481.3,217.6z"
              />
              <path
                d="M305.3,129.6c2,4.8-5.7,7.6-8.4,4.3
  C293,129.1,303,124.2,305.3,129.6z"
              />
              <path
                d="M314.3,133.1c-3.5,1.7-9-3.1-5.3-5.6
  C312.7,124.8,319.1,130.6,314.3,133.1z"
              />
              <path
                d="M306.9,138.3c-6-1.3-0.7-7.7,2.9-5.5
  C313.7,135.2,311.1,139.1,306.9,138.3z"
              />
              <path
                d="M402.9,135.7c-3.5,1.7-9-3.1-5.3-5.6
  C401.4,127.5,407.8,133.3,402.9,135.7z"
              />
              <path
                d="M394.9,141.6c-6-1.3-0.7-7.7,2.9-5.5
  C401.7,138.6,399.1,142.5,394.9,141.6z"
              />
              <path
                d="M307,341.6c-0.6-6.1-8.5-1.6-6.5,2.3
  C302.7,348,307.4,345.9,307,341.6z"
              />
              <path
                d="M312.9,357.4c6-2,2.9-11.2-2.7-8.5
  C305.6,351,307.5,359.2,312.9,357.4z"
              />
              <path
                d="M436.7,339.9c6-2,2.9-11.2-2.7-8.5
  C429.4,333.5,431.3,341.7,436.7,339.9z"
              />
            </g>
            <g
              title="Lipid droplets"
              className={locations.includes('Lipid droplets') || locations.includes('Vesicles') ? 'lipid_droplets' : 'lipid_droplets--disabled'}
            >
              <title>Lipid droplets</title>
              <path
                d="M429.8,340.9c2.3-2,1.8-7.3-1-8.6
  c-4.4-2-9.5,4.8-6.4,8.3C424.3,342.5,427.9,342.7,429.8,340.9z"
              />
              <path
                d="M428.9,154.2c1.2-3-2.3-7-5.5-5.3
  C417.9,152.1,426.9,159.5,428.9,154.2z"
              />
              <path
                d="M461.9,284.3c4.8,0.1,4.4-9.6-1-8.3
  C456.6,276.9,457.6,284.1,461.9,284.3z"
              />
              <path
                d="M244.6,320.3c4.8,0.1,4.4-9.6-1-8.3
  C239.3,312.9,240.3,320.1,244.6,320.3z"
              />
              <path
                d="M305.8,128.7c4.8,0.1,4.4-9.6-1-8.3
  C300.5,121.4,301.6,128.6,305.8,128.7z"
              />
              <path
                d="M424.5,345.6c2-4.3-7-7.8-8-2.3
  C415.7,347.6,422.7,349.5,424.5,345.6z"
              />
              <path
                d="M354.2,363.6c2.3-2,1.8-7.3-1-8.6
  c-4.4-2-9.5,4.8-6.4,8.3C348.6,365.2,352.2,365.4,354.2,363.6z"
              />
              <path
                d="M338.5,132.6c2-4.3-7-7.8-8-2.3
  C329.7,134.6,336.7,136.5,338.5,132.6z"
              />
              <path
                d="M322.8,349.6c2-4.3-7-7.8-8-2.3
  C314,351.6,321,353.5,322.8,349.6z"
              />
              <path
                d="M309.4,347.9c5,3,9.2-5.6,5.9-9.1
  C310.4,333.6,303.6,344.5,309.4,347.9z"
              />
              <path
                d="M471.9,215c5,2.6,9.2-4.8,5.9-7.8
  C472.9,202.7,466.1,212.1,471.9,215z"
              />
              <path
                d="M384.9,132.7c-2.2-3.4-9.5-1.2-7.5,3.1
  C379.4,140.2,388,137.4,384.9,132.7z"
              />
              <path
                d="M304,347.2c-3.8,4,3.8,6.8,4.1,1.5
  c0.2-3-2.7-3.8-4.4-1.5"
              />
              <path
                d="M433.5,346.3c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C433.1,346.3,433.3,346.3,433.5,346.3z"
              />
              <path
                d="M444.2,304.3c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C443.7,304.3,444,304.3,444.2,304.3z"
              />
              <path
                d="M444.7,176.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C444.2,176.1,444.5,176.1,444.7,176.1z"
              />
              <path
                d="M277.7,140.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C277.2,140.1,277.5,140.1,277.7,140.1z"
              />
              <path
                d="M289.7,368.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C289.2,368.1,289.5,368.1,289.7,368.1z"
              />
              <path
                d="M371.4,369.1c4.4-0.2,3.6-7.8-2-5.9
  c-4.1,1.4-2.4,5.4,1.3,5.8C370.9,369.1,371.1,369.1,371.4,369.1z"
              />
              <path
                d="M440.6,156.1c-0.6-6.1-8.5-1.6-6.5,2.3
  C436.3,162.5,441,160.4,440.6,156.1z"
              />
              <path
                d="M264.5,352.2c0.8,3.1,6,4.3,7.6,1
  C274.6,347.3,263,346.7,264.5,352.2z"
              />
              <path
                d="M254,357.6c4.1,4.6,7.8-3.7,3.8-5.7
  C253.6,349.9,251,354.4,254,357.6z"
              />
              <path
                d="M474.5,224.3c2.4-3.2-2.3-9.3-5.6-5.9
  C465.6,221.8,471.2,228.8,474.5,224.3z"
              />
              <path
                d="M481.3,217.6c-0.6-6.1-8.5-1.6-6.5,2.3
  C477,224,481.7,221.9,481.3,217.6z"
              />
              <path
                d="M305.3,129.6c2,4.8-5.7,7.6-8.4,4.3
  C293,129.1,303,124.2,305.3,129.6z"
              />
              <path
                d="M314.3,133.1c-3.5,1.7-9-3.1-5.3-5.6
  C312.7,124.8,319.1,130.6,314.3,133.1z"
              />
              <path
                d="M306.9,138.3c-6-1.3-0.7-7.7,2.9-5.5
  C313.7,135.2,311.1,139.1,306.9,138.3z"
              />
              <path
                d="M402.9,135.7c-3.5,1.7-9-3.1-5.3-5.6
  C401.4,127.5,407.8,133.3,402.9,135.7z"
              />
              <path
                d="M394.9,141.6c-6-1.3-0.7-7.7,2.9-5.5
  C401.7,138.6,399.1,142.5,394.9,141.6z"
              />
              <path
                d="M307,341.6c-0.6-6.1-8.5-1.6-6.5,2.3
  C302.7,348,307.4,345.9,307,341.6z"
              />
              <path
                d="M312.9,357.4c6-2,2.9-11.2-2.7-8.5
  C305.6,351,307.5,359.2,312.9,357.4z"
              />
              <path
                d="M436.7,339.9c6-2,2.9-11.2-2.7-8.5
  C429.4,333.5,431.3,341.7,436.7,339.9z"
              />
            </g>
            <g title="Lysosomes" className="lysosomes_ton">
              <title>Lysosomes</title>
              <path
                d="M501.2,380.7c0.1-0.2,0.2-0.5,0.3-0.7
  c1.1-2.1,3.8-1.7,4.9,0.2c0.8,1.5,0.7,3.9-0.8,4.9C502.8,387.1,500.4,383.1,501.2,380.7z"
              />
              <path
                d="M187.7,141.7c0.1-0.2,0.2-0.5,0.3-0.7
  c1.1-2.1,3.8-1.7,4.9,0.2c0.8,1.5,0.7,3.9-0.8,4.9C189.3,148.1,186.9,144.1,187.7,141.7z"
              />
              <path
                d="M175,382.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C176.7,386.9,174.8,384.6,175,382.2z"
              />
              <path
                d="M188.5,226.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C190.2,230.9,188.3,228.6,188.5,226.2z"
              />
              <path
                d="M426,364.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C427.7,368.9,425.8,366.6,426,364.2z"
              />
              <path
                d="M370.6,108.7c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C372.3,113.4,370.4,111.1,370.6,108.7z"
              />
              <path
                d="M235.5,107.7c-2-0.4-2.7-2.8-2.2-4.8
  c0.4-1.7,2.1-2.8,3.8-2.3c3.2,0.9,3.1,6.4-0.2,7.1C236.4,107.7,235.9,107.8,235.5,107.7z"
              />
              <path
                d="M180.1,315.4c-2.4-0.3-4.5-3.8-1.7-5.2
  c2.4-1.2,5.9,2.4,3.8,4.6C181.6,315.3,180.8,315.5,180.1,315.4z"
              />
              <path
                d="M110.6,298.9c-0.1-0.1-0.2-0.3-0.3-0.4
  c-0.9-1.5-1.1-3.9,0.8-4.7c2.3-0.9,4.3,1.5,4.2,3.7C115.1,300.2,112.1,300.7,110.6,298.9z"
              />
              <path
                d="M77.4,374.2c-0.1-0.1-0.2-0.3-0.3-0.4
  c-0.9-1.5-1.1-3.9,0.8-4.7c2.3-0.9,4.3,1.5,4.2,3.7C81.8,375.6,78.9,376.1,77.4,374.2z"
              />
              <path
                d="M151.6,195.1c0.2-2.2,2.1-4.1,4.6-2.1
  c2.3,1.8,2,7-1.8,6.2C152.2,198.7,151.4,196.8,151.6,195.1z"
              />
              <path
                d="M537.2,295.8c0.4-0.5,1-0.8,1.9-0.8
  c2.9-0.1,4.4,4.9,1.2,5.7C537.4,301.4,535.8,297.6,537.2,295.8z"
              />
              <path
                d="M388.5,55.1c0.4-0.5,1-0.8,1.9-0.8
  c2.9-0.1,4.4,4.9,1.2,5.7C388.7,60.7,387.1,56.8,388.5,55.1z"
              />
              <path
                d="M473.6,318c1.8-3.7,6.5-1.1,4.7,3
  C476.3,325.4,471.9,321.4,473.6,318z"
              />
              <path
                d="M440.7,97.8c-0.9-3.1,3.5-7,4.8-2.1
  C446.6,99.6,442.1,102.3,440.7,97.8z"
              />
              <path
                d="M418,393c1.3-3.1,7.5-2.5,5.2,2
  C421.4,398.4,416.5,396.4,418,393z"
              />
              <path
                d="M482.3,140.9c3.6,0.7,4,5.1,0.4,6.2
  C477.8,148.6,477.4,139.9,482.3,140.9z"
              />
            </g>
            <g
              title="Lysosomes"
              className={locations.includes('Lysosomes') || locations.includes('Vesicles') ? 'lysosomes' : 'lysosomes--disabled'}
            >
              <title>Lysosomes</title>
              <path
                d="M501.2,380.7c0.1-0.2,0.2-0.5,0.3-0.7
  c1.1-2.1,3.8-1.7,4.9,0.2c0.8,1.5,0.7,3.9-0.8,4.9C502.8,387.1,500.4,383.1,501.2,380.7z"
              />
              <path
                d="M187.7,141.7c0.1-0.2,0.2-0.5,0.3-0.7
  c1.1-2.1,3.8-1.7,4.9,0.2c0.8,1.5,0.7,3.9-0.8,4.9C189.3,148.1,186.9,144.1,187.7,141.7z"
              />
              <path
                d="M175,382.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C176.7,386.9,174.8,384.6,175,382.2z"
              />
              <path
                d="M188.5,226.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C190.2,230.9,188.3,228.6,188.5,226.2z"
              />
              <path
                d="M426,364.2c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C427.7,368.9,425.8,366.6,426,364.2z"
              />
              <path
                d="M370.6,108.7c0-0.5,0.2-1.1,0.5-1.6
  c1.1-2.1,4.1-3.7,6-1.7c1.9,2.1,0.4,5.7-2,6.7C372.3,113.4,370.4,111.1,370.6,108.7z"
              />
              <path
                d="M235.5,107.7c-2-0.4-2.7-2.8-2.2-4.8
  c0.4-1.7,2.1-2.8,3.8-2.3c3.2,0.9,3.1,6.4-0.2,7.1C236.4,107.7,235.9,107.8,235.5,107.7z"
              />
              <path
                d="M180.1,315.4c-2.4-0.3-4.5-3.8-1.7-5.2
  c2.4-1.2,5.9,2.4,3.8,4.6C181.6,315.3,180.8,315.5,180.1,315.4z"
              />
              <path
                d="M110.6,298.9c-0.1-0.1-0.2-0.3-0.3-0.4
  c-0.9-1.5-1.1-3.9,0.8-4.7c2.3-0.9,4.3,1.5,4.2,3.7C115.1,300.2,112.1,300.7,110.6,298.9z"
              />
              <path
                d="M77.4,374.2c-0.1-0.1-0.2-0.3-0.3-0.4
  c-0.9-1.5-1.1-3.9,0.8-4.7c2.3-0.9,4.3,1.5,4.2,3.7C81.8,375.6,78.9,376.1,77.4,374.2z"
              />
              <path
                d="M151.6,195.1c0.2-2.2,2.1-4.1,4.6-2.1
  c2.3,1.8,2,7-1.8,6.2C152.2,198.7,151.4,196.8,151.6,195.1z"
              />
              <path
                d="M537.2,295.8c0.4-0.5,1-0.8,1.9-0.8
  c2.9-0.1,4.4,4.9,1.2,5.7C537.4,301.4,535.8,297.6,537.2,295.8z"
              />
              <path
                d="M388.5,55.1c0.4-0.5,1-0.8,1.9-0.8
  c2.9-0.1,4.4,4.9,1.2,5.7C388.7,60.7,387.1,56.8,388.5,55.1z"
              />
              <path
                d="M473.6,318c1.8-3.7,6.5-1.1,4.7,3
  C476.3,325.4,471.9,321.4,473.6,318z"
              />
              <path
                d="M440.7,97.8c-0.9-3.1,3.5-7,4.8-2.1
  C446.6,99.6,442.1,102.3,440.7,97.8z"
              />
              <path
                d="M418,393c1.3-3.1,7.5-2.5,5.2,2
  C421.4,398.4,416.5,396.4,418,393z"
              />
              <path
                d="M482.3,140.9c3.6,0.7,4,5.1,0.4,6.2
  C477.8,148.6,477.4,139.9,482.3,140.9z"
              />
            </g>
            <g title="Peroxisomes" className="peroxisomes_ton">
              <title>Peroxisomes</title>
              <path
                d="M558.5,272.6c0.7-0.4,3.9-5,2.8-5.7
  c-0.7-0.4-2.2,2.6-2.6,3.2C558.3,270.8,556.3,274,558.5,272.6z"
              />
              <path
                d="M525.6,202.2c-0.1-2.3-7.6-0.5-5.7,1.5
  c0.8,0.9,2.6,0.8,3.6,0.4C524.1,203.8,525.7,203,525.6,202.2z"
              />
              <path
                d="M566,160c0.5-2.1-7.1,1.7-3.7,2.8
  C563.9,163.3,565.7,161.4,566,160z"
              />
              <path
                d="M494,264.8c0.3-1,0.4-5.2-1.4-3.3
  C491.4,262.7,493,267.9,494,264.8z"
              />
              <path
                d="M539.1,244.8c0,0.2,0.2,0.4,0.6,0.7
  c1,0.6,2.6,0.4,3.6,0.1c0.8-0.3,2.8-1.4,1.4-2.3C543.6,242.6,539.1,243.7,539.1,244.8z"
              />
              <path
                d="M517.7,129.2c0.1-1.9-0.8-3.2-2.1-1.2
  C514.2,130.3,517.5,132.2,517.7,129.2z"
              />
              <path
                d="M478.8,200.8c1,1.1,2.4,1.6,3.2,0.4
  c1-1.7-3.7-5.4-4.3-3.1C477.5,198.9,478,200,478.8,200.8z"
              />
              <path
                d="M518.2,305.5c1.4,0.6,3.2-3.8,1.5-4.3
  c-1.6-0.4-2.8,2.7-1.9,3.9C517.9,305.3,518.1,305.4,518.2,305.5z"
              />
              <path
                d="M469.3,147.7c0.2,0.1,0.5,0.1,1-0.1
  c2.5-1.3,0.3-7.7-0.9-3.4C469.2,144.8,468.6,147.2,469.3,147.7z"
              />
              <path
                d="M427.9,76.5c0,0.2,0.2,0.5,0.6,0.7
  c2.5,1.4,6.6-3.9,2.4-2.6C430.3,74.9,427.9,75.7,427.9,76.5z"
              />
              <path
                d="M70.3,341.6c-0.2,0.1-0.4,0.4-0.4,0.9
  c-0.2,2.9,6.4,4.2,3.4,1C72.8,343,71.1,341.2,70.3,341.6z"
              />
              <path
                d="M352.8,409.1c-0.2,0.1-0.4,0.4-0.4,0.9
  c-0.2,2.9,6.4,4.2,3.4,1C355.3,410.5,353.6,408.7,352.8,409.1z"
              />
              <path
                d="M543.1,184.3c0.1-0.5,0-1-0.3-1.5
  c-0.6-1-4.2-4.4-3.6-1.5C539.4,182.1,542.6,187,543.1,184.3z"
              />
              <path
                d="M563.8,194c0.1,0.2,0.5,0.5,1.2,0.6
  c0.7,0.2,4.6,0.1,4.1-1.4c-0.3-1.1-2.5-0.8-3.3-0.7C564.7,192.7,563.5,193.4,563.8,194z"
              />
              <path
                d="M412.8,449c0.7,0.5,6.2,1.5,6.3,0.3
  c0.1-0.8-3.3-1-4-1.1C414.4,448.1,410.8,447.5,412.8,449z"
              />
              <path
                d="M464.1,390.6c2.1-1-2.6-7.2-3.7-4.6
  c-0.5,1.1,0.3,2.7,1.1,3.5C462,389.8,463.4,390.9,464.1,390.6z"
              />
              <path
                d="M518.9,410.6c2.1-0.4-4.4-5.8-4-2.2
  C515.1,409.9,517.6,410.8,518.9,410.6z"
              />
              <path
                d="M394.1,386.8c1.1-0.1,5-1.7,2.5-2.6
  C394.9,383.5,390.8,387.1,394.1,386.8z"
              />
              <path
                d="M430.5,420.1c-0.2,0.1-0.3,0.3-0.4,0.8
  c-0.1,1.2,0.6,2.5,1.4,3.4c0.6,0.6,2.4,2,2.7,0.3C434.4,423.3,431.5,419.6,430.5,420.1z"
              />
              <path
                d="M527.7,353.9c1.8-0.6,2.6-2,0.3-2.4
  C525.3,351.1,524.9,354.9,527.7,353.9z"
              />
              <path
                d="M407.2,354.4c1.8-0.6,2.6-2,0.3-2.4
  C404.8,351.6,404.4,355.4,407.2,354.4z"
              />
              <path
                d="M165.7,328.9c1.8-0.6,2.6-2,0.3-2.4
  C163.3,326.1,162.9,329.9,165.7,328.9z"
              />
              <path
                d="M446.5,347.1c-0.6,1.3-0.5,2.9,0.9,3.1
  c1.9,0.3,3.5-5.6,1.1-5.2C447.7,345.2,446.9,346.1,446.5,347.1z"
              />
              <path
                d="M366.6,425.4c0,1.5,4.8,1.4,4.5-0.4
  c-0.2-1.7-3.6-1.5-4.4-0.2C366.6,425,366.6,425.2,366.6,425.4z"
              />
              <path
                d="M491.3,317c0,0.2,0.1,0.5,0.5,0.8
  c2.2,1.8,7.1-2.8,2.8-2.2C493.9,315.8,491.5,316.2,491.3,317z"
              />
              <path
                d="M487.5,399.3c0.5-0.1,0.9-0.5,1.2-0.9
  c0.7-0.9,2.3-5.6-0.1-3.9C488,395,484.9,400,487.5,399.3z"
              />
              <path
                d="M486.9,422.2c-0.2,0.2-0.2,0.6-0.1,1.3
  c0.1,0.7,1.7,4.2,2.9,3.2c0.9-0.8-0.3-2.6-0.7-3.3C488.5,422.5,487.3,421.7,486.9,422.2z"
              />
              <path
                d="M152.6,427c0.5,0.7,5.4,3.4,5.9,2.3
  c0.3-0.7-2.8-1.9-3.4-2.3C154.4,426.6,151.1,424.9,152.6,427z"
              />
              <path
                d="M219.7,387.7c2.3-0.3-0.2-7.6-2-5.6
  c-0.8,0.9-0.5,2.7-0.1,3.6C217.9,386.3,218.9,387.8,219.7,387.7z"
              />
              <path
                d="M265.4,424c2.1,0.3-2.3-6.9-3.1-3.4
  C261.9,422.2,264,423.9,265.4,424z"
              />
              <path
                d="M154.5,362c1.1,0.2,5.2-0.1,3.2-1.7
  C156.3,359.2,151.2,361.2,154.5,362z"
              />
              <path
                d="M178.5,405.1c-0.2,0-0.4,0.2-0.6,0.6
  c-0.5,1.1-0.2,2.6,0.3,3.6c0.3,0.8,1.6,2.6,2.4,1.2C181.2,409.4,179.6,405,178.5,405.1z"
              />
              <path
                d="M301.2,384.9c1.9,0,3.1-1.1,1-2.2
  C299.8,381.5,298.2,385,301.2,384.9z"
              />
              <path
                d="M178.5,244.2c1.9,0,3.1-1.1,1-2.2
  C177.1,240.8,175.5,244.2,178.5,244.2z"
              />
              <path
                d="M181,176c1.9,0,3.1-1.1,1-2.2
  C179.6,172.6,178,176.1,181,176z"
              />
              <path
                d="M356.6,27.9c1.9,0,3.1-1.1,1-2.2
  C355.2,24.4,353.6,27.9,356.6,27.9z"
              />
              <path
                d="M486.2,164.9c1.9,0,3.1-1.1,1-2.2
  C484.8,161.5,483.2,165,486.2,164.9z"
              />
              <path
                d="M470.2,85.4c1.9,0,3.1-1.1,1-2.2
  C468.8,82,467.2,85.5,470.2,85.4z"
              />
              <path
                d="M509.5,281.9c1.9,0,3.1-1.1,1-2.2
  C508.1,278.5,506.5,281.9,509.5,281.9z"
              />
              <path
                d="M216.7,340.9c-1,1.1-1.4,2.6-0.1,3.2
  c1.7,0.9,5.1-4.2,2.7-4.6C218.5,339.5,217.5,340.1,216.7,340.9z"
              />
              <path
                d="M100.6,356.7c-0.4,1.5,4.1,2.8,4.4,1.1
  c0.3-1.7-3-2.6-4.1-1.5C100.7,356.4,100.6,356.6,100.6,356.7z"
              />
              <path
                d="M268.8,326.6c-0.1,0.2-0.1,0.5,0.2,0.9
  c1.6,2.4,7.6-0.4,3.3-1.2C271.6,326.2,269.2,325.8,268.8,326.6z"
              />
              <path
                d="M239.1,403.4c0.5,0,1-0.1,1.5-0.5
  c0.9-0.6,4-4.5,1.1-3.7C241,399.5,236.4,403.2,239.1,403.4z"
              />
              <path
                d="M231.4,424.9c-0.2,0.1-0.4,0.5-0.5,1.2
  c-0.1,0.7,0.3,4.6,1.8,4c1.1-0.4,0.6-2.5,0.4-3.3C232.7,425.7,231.9,424.6,231.4,424.9z"
              />
              <path
                d="M112.5,138.4c-0.8,0.4-4.1,4.8-3.1,5.5
  c0.7,0.4,2.3-2.5,2.7-3.1C112.6,140.3,114.8,137.2,112.5,138.4z"
              />
              <path
                d="M141.8,210.5c0,2.3,7.6,0.9,5.8-1.2
  c-0.8-0.9-2.6-0.9-3.6-0.6C143.4,208.9,141.8,209.6,141.8,210.5z"
              />
              <path
                d="M99.4,250.5c-0.6,2,7.2-1.3,3.8-2.6
  C101.7,247.4,99.7,249.2,99.4,250.5z"
              />
              <path
                d="M176.6,149.5c-0.4,1-0.7,5.2,1.2,3.4
  C179.1,151.8,177.8,146.5,176.6,149.5z"
              />
              <path
                d="M130.5,167.2c0-0.2-0.1-0.5-0.5-0.7
  c-1-0.6-2.5-0.6-3.6-0.3c-0.8,0.2-2.8,1.2-1.5,2.2C125.9,169.2,130.4,168.3,130.5,167.2z"
              />
              <path
                d="M146.1,283.8c-0.2,1.9,0.7,3.2,2,1.3
  C149.7,282.9,146.5,280.8,146.1,283.8z"
              />
              <path
                d="M188.5,214.2c-0.9-1.1-2.3-1.8-3.2-0.6
  c-1.1,1.6,3.5,5.6,4.1,3.3C189.7,216.1,189.3,215,188.5,214.2z"
              />
              <path
                d="M154.4,107.7c-1.4-0.6-3.4,3.7-1.7,4.2
  c1.6,0.5,3-2.6,2.1-3.8C154.7,107.9,154.6,107.7,154.4,107.7z"
              />
              <path
                d="M195.3,267.7c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C195.3,270.6,196.1,268.2,195.3,267.7z"
              />
              <path
                d="M63.6,300.5c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C63.6,303.3,64.3,301,63.6,300.5z"
              />
              <path
                d="M26.1,331c-0.1-0.2-0.3-0.4-0.8-0.6
  c-2.8-0.7-5.4,5.4-1.7,3.1C24.2,333.2,26.3,331.8,26.1,331z"
              />
              <path
                d="M523.7,61.8c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C523.6,64.7,524.4,62.3,523.7,61.8z"
              />
              <path
                d="M451.6,313.9c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C451.6,316.8,452.3,314.4,451.6,313.9z"
              />
              <path
                d="M169.5,362.4c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C169.4,365.3,170.2,362.9,169.5,362.4z"
              />
              <path
                d="M562.5,372.9c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C562.4,375.8,563.2,373.4,562.5,372.9z"
              />
              <path
                d="M123.5,227.4c-0.1,0.5,0,1,0.2,1.5
  c0.5,1,3.9,4.6,3.5,1.6C127.1,229.8,124.1,224.8,123.5,227.4z"
              />
              <path
                d="M103.3,216.7c-0.1-0.2-0.5-0.5-1.2-0.7
  c-0.7-0.2-4.6-0.3-4.2,1.2c0.3,1.1,2.4,0.9,3.2,0.8C102.3,217.9,103.6,217.3,103.3,216.7z"
              />
              <path
                d="M350.9,43.1c-0.6-0.6-5.7-2.9-6.1-1.7
  c-0.3,0.7,3,1.7,3.6,1.9C349.2,43.7,352.6,45,350.9,43.1z"
              />
              <path
                d="M287.9,88.7c-2.3,0.5,0.9,7.6,2.5,5.3
  c0.7-1,0.3-2.7-0.3-3.6C289.9,89.9,288.7,88.5,287.9,88.7z"
              />
              <path
                d="M238.9,57c-2.1-0.1,3,6.6,3.4,3.1
  C242.6,58.5,240.3,57,238.9,57z"
              />
              <path
                d="M355.4,108c-1.1-0.1-5.2,0.6-3,2
  C353.8,110.9,358.7,108.4,355.4,108z"
              />
              <path
                d="M320.1,54.3c0.2,0,0.4-0.2,0.5-0.7
  c0.3-1.1-0.1-2.6-0.6-3.6c-0.4-0.7-1.9-2.5-2.5-0.9C317,50.2,318.9,54.5,320.1,54.3z"
              />
              <path
                d="M217.8,110.3c-1.9,0.2-3,1.4-0.8,2.3
  C219.5,113.6,220.7,110,217.8,110.3z"
              />
              <path
                d="M295.5,135c0.8-1.2,1.2-2.7-0.2-3.2
  c-1.8-0.7-4.6,4.7-2.2,4.8C293.8,136.6,294.8,135.9,295.5,135z"
              />
              <path
                d="M390.8,76.5c0.3-1.5-4.4-2.4-4.5-0.6
  c-0.1,1.7,3.2,2.3,4.2,1.1C390.7,76.8,390.7,76.7,390.8,76.5z"
              />
              <path
                d="M245,154.3c0.1-0.2,0-0.5-0.3-0.9
  c-1.8-2.2-7.6,1.1-3.2,1.5C242.2,154.9,244.7,155.1,245,154.3z"
              />
              <path
                d="M267.1,75c-0.5,0-1,0.2-1.4,0.6
  c-0.9,0.7-3.5,4.9-0.8,3.8C265.6,79,269.8,74.9,267.1,75z"
              />
              <path
                d="M272.7,52.8c0.2-0.2,0.4-0.6,0.4-1.3
  c0-0.7-0.8-4.5-2.1-3.8c-1,0.5-0.3,2.6-0.1,3.3C271.3,52.1,272.2,53.2,272.7,52.8z"
              />
            </g>
            <g
              title="Peroxisomes"
              className={locations.includes('Peroxisomes') || locations.includes('Vesicles') ? 'peroxisomes' : 'peroxisomes--disabled'}
            >
              <title>Peroxisomes</title>
              <path
                d="M558.5,272.6c0.7-0.4,3.9-5,2.8-5.7
  c-0.7-0.4-2.2,2.6-2.6,3.2C558.3,270.8,556.3,274,558.5,272.6z"
              />
              <path
                d="M525.6,202.2c-0.1-2.3-7.6-0.5-5.7,1.5
  c0.8,0.9,2.6,0.8,3.6,0.4C524.1,203.8,525.7,203,525.6,202.2z"
              />
              <path
                d="M566,160c0.5-2.1-7.1,1.7-3.7,2.8
  C563.9,163.3,565.7,161.4,566,160z"
              />
              <path
                d="M494,264.8c0.3-1,0.4-5.2-1.4-3.3
  C491.4,262.7,493,267.9,494,264.8z"
              />
              <path
                d="M539.1,244.8c0,0.2,0.2,0.4,0.6,0.7
  c1,0.6,2.6,0.4,3.6,0.1c0.8-0.3,2.8-1.4,1.4-2.3C543.6,242.6,539.1,243.7,539.1,244.8z"
              />
              <path
                d="M517.7,129.2c0.1-1.9-0.8-3.2-2.1-1.2
  C514.2,130.3,517.5,132.2,517.7,129.2z"
              />
              <path
                d="M478.8,200.8c1,1.1,2.4,1.6,3.2,0.4
  c1-1.7-3.7-5.4-4.3-3.1C477.5,198.9,478,200,478.8,200.8z"
              />
              <path
                d="M518.2,305.5c1.4,0.6,3.2-3.8,1.5-4.3
  c-1.6-0.4-2.8,2.7-1.9,3.9C517.9,305.3,518.1,305.4,518.2,305.5z"
              />
              <path
                d="M469.3,147.7c0.2,0.1,0.5,0.1,1-0.1
  c2.5-1.3,0.3-7.7-0.9-3.4C469.2,144.8,468.6,147.2,469.3,147.7z"
              />
              <path
                d="M427.9,76.5c0,0.2,0.2,0.5,0.6,0.7
  c2.5,1.4,6.6-3.9,2.4-2.6C430.3,74.9,427.9,75.7,427.9,76.5z"
              />
              <path
                d="M70.3,341.6c-0.2,0.1-0.4,0.4-0.4,0.9
  c-0.2,2.9,6.4,4.2,3.4,1C72.8,343,71.1,341.2,70.3,341.6z"
              />
              <path
                d="M352.8,409.1c-0.2,0.1-0.4,0.4-0.4,0.9
  c-0.2,2.9,6.4,4.2,3.4,1C355.3,410.5,353.6,408.7,352.8,409.1z"
              />
              <path
                d="M543.1,184.3c0.1-0.5,0-1-0.3-1.5
  c-0.6-1-4.2-4.4-3.6-1.5C539.4,182.1,542.6,187,543.1,184.3z"
              />
              <path
                d="M563.8,194c0.1,0.2,0.5,0.5,1.2,0.6
  c0.7,0.2,4.6,0.1,4.1-1.4c-0.3-1.1-2.5-0.8-3.3-0.7C564.7,192.7,563.5,193.4,563.8,194z"
              />
              <path
                d="M412.8,449c0.7,0.5,6.2,1.5,6.3,0.3
  c0.1-0.8-3.3-1-4-1.1C414.4,448.1,410.8,447.5,412.8,449z"
              />
              <path
                d="M464.1,390.6c2.1-1-2.6-7.2-3.7-4.6
  c-0.5,1.1,0.3,2.7,1.1,3.5C462,389.8,463.4,390.9,464.1,390.6z"
              />
              <path
                d="M518.9,410.6c2.1-0.4-4.4-5.8-4-2.2
  C515.1,409.9,517.6,410.8,518.9,410.6z"
              />
              <path
                d="M394.1,386.8c1.1-0.1,5-1.7,2.5-2.6
  C394.9,383.5,390.8,387.1,394.1,386.8z"
              />
              <path
                d="M430.5,420.1c-0.2,0.1-0.3,0.3-0.4,0.8
  c-0.1,1.2,0.6,2.5,1.4,3.4c0.6,0.6,2.4,2,2.7,0.3C434.4,423.3,431.5,419.6,430.5,420.1z"
              />
              <path
                d="M527.7,353.9c1.8-0.6,2.6-2,0.3-2.4
  C525.3,351.1,524.9,354.9,527.7,353.9z"
              />
              <path
                d="M407.2,354.4c1.8-0.6,2.6-2,0.3-2.4
  C404.8,351.6,404.4,355.4,407.2,354.4z"
              />
              <path
                d="M165.7,328.9c1.8-0.6,2.6-2,0.3-2.4
  C163.3,326.1,162.9,329.9,165.7,328.9z"
              />
              <path
                d="M446.5,347.1c-0.6,1.3-0.5,2.9,0.9,3.1
  c1.9,0.3,3.5-5.6,1.1-5.2C447.7,345.2,446.9,346.1,446.5,347.1z"
              />
              <path
                d="M366.6,425.4c0,1.5,4.8,1.4,4.5-0.4
  c-0.2-1.7-3.6-1.5-4.4-0.2C366.6,425,366.6,425.2,366.6,425.4z"
              />
              <path
                d="M491.3,317c0,0.2,0.1,0.5,0.5,0.8
  c2.2,1.8,7.1-2.8,2.8-2.2C493.9,315.8,491.5,316.2,491.3,317z"
              />
              <path
                d="M487.5,399.3c0.5-0.1,0.9-0.5,1.2-0.9
  c0.7-0.9,2.3-5.6-0.1-3.9C488,395,484.9,400,487.5,399.3z"
              />
              <path
                d="M486.9,422.2c-0.2,0.2-0.2,0.6-0.1,1.3
  c0.1,0.7,1.7,4.2,2.9,3.2c0.9-0.8-0.3-2.6-0.7-3.3C488.5,422.5,487.3,421.7,486.9,422.2z"
              />
              <path
                d="M152.6,427c0.5,0.7,5.4,3.4,5.9,2.3
  c0.3-0.7-2.8-1.9-3.4-2.3C154.4,426.6,151.1,424.9,152.6,427z"
              />
              <path
                d="M219.7,387.7c2.3-0.3-0.2-7.6-2-5.6
  c-0.8,0.9-0.5,2.7-0.1,3.6C217.9,386.3,218.9,387.8,219.7,387.7z"
              />
              <path
                d="M265.4,424c2.1,0.3-2.3-6.9-3.1-3.4
  C261.9,422.2,264,423.9,265.4,424z"
              />
              <path
                d="M154.5,362c1.1,0.2,5.2-0.1,3.2-1.7
  C156.3,359.2,151.2,361.2,154.5,362z"
              />
              <path
                d="M178.5,405.1c-0.2,0-0.4,0.2-0.6,0.6
  c-0.5,1.1-0.2,2.6,0.3,3.6c0.3,0.8,1.6,2.6,2.4,1.2C181.2,409.4,179.6,405,178.5,405.1z"
              />
              <path
                d="M301.2,384.9c1.9,0,3.1-1.1,1-2.2
  C299.8,381.5,298.2,385,301.2,384.9z"
              />
              <path
                d="M178.5,244.2c1.9,0,3.1-1.1,1-2.2
  C177.1,240.8,175.5,244.2,178.5,244.2z"
              />
              <path
                d="M181,176c1.9,0,3.1-1.1,1-2.2
  C179.6,172.6,178,176.1,181,176z"
              />
              <path
                d="M356.6,27.9c1.9,0,3.1-1.1,1-2.2
  C355.2,24.4,353.6,27.9,356.6,27.9z"
              />
              <path
                d="M486.2,164.9c1.9,0,3.1-1.1,1-2.2
  C484.8,161.5,483.2,165,486.2,164.9z"
              />
              <path
                d="M470.2,85.4c1.9,0,3.1-1.1,1-2.2
  C468.8,82,467.2,85.5,470.2,85.4z"
              />
              <path
                d="M509.5,281.9c1.9,0,3.1-1.1,1-2.2
  C508.1,278.5,506.5,281.9,509.5,281.9z"
              />
              <path
                d="M216.7,340.9c-1,1.1-1.4,2.6-0.1,3.2
  c1.7,0.9,5.1-4.2,2.7-4.6C218.5,339.5,217.5,340.1,216.7,340.9z"
              />
              <path
                d="M100.6,356.7c-0.4,1.5,4.1,2.8,4.4,1.1
  c0.3-1.7-3-2.6-4.1-1.5C100.7,356.4,100.6,356.6,100.6,356.7z"
              />
              <path
                d="M268.8,326.6c-0.1,0.2-0.1,0.5,0.2,0.9
  c1.6,2.4,7.6-0.4,3.3-1.2C271.6,326.2,269.2,325.8,268.8,326.6z"
              />
              <path
                d="M239.1,403.4c0.5,0,1-0.1,1.5-0.5
  c0.9-0.6,4-4.5,1.1-3.7C241,399.5,236.4,403.2,239.1,403.4z"
              />
              <path
                d="M231.4,424.9c-0.2,0.1-0.4,0.5-0.5,1.2
  c-0.1,0.7,0.3,4.6,1.8,4c1.1-0.4,0.6-2.5,0.4-3.3C232.7,425.7,231.9,424.6,231.4,424.9z"
              />
              <path
                d="M112.5,138.4c-0.8,0.4-4.1,4.8-3.1,5.5
  c0.7,0.4,2.3-2.5,2.7-3.1C112.6,140.3,114.8,137.2,112.5,138.4z"
              />
              <path
                d="M141.8,210.5c0,2.3,7.6,0.9,5.8-1.2
  c-0.8-0.9-2.6-0.9-3.6-0.6C143.4,208.9,141.8,209.6,141.8,210.5z"
              />
              <path
                d="M99.4,250.5c-0.6,2,7.2-1.3,3.8-2.6
  C101.7,247.4,99.7,249.2,99.4,250.5z"
              />
              <path
                d="M176.6,149.5c-0.4,1-0.7,5.2,1.2,3.4
  C179.1,151.8,177.8,146.5,176.6,149.5z"
              />
              <path
                d="M130.5,167.2c0-0.2-0.1-0.5-0.5-0.7
  c-1-0.6-2.5-0.6-3.6-0.3c-0.8,0.2-2.8,1.2-1.5,2.2C125.9,169.2,130.4,168.3,130.5,167.2z"
              />
              <path
                d="M146.1,283.8c-0.2,1.9,0.7,3.2,2,1.3
  C149.7,282.9,146.5,280.8,146.1,283.8z"
              />
              <path
                d="M188.5,214.2c-0.9-1.1-2.3-1.8-3.2-0.6
  c-1.1,1.6,3.5,5.6,4.1,3.3C189.7,216.1,189.3,215,188.5,214.2z"
              />
              <path
                d="M154.4,107.7c-1.4-0.6-3.4,3.7-1.7,4.2
  c1.6,0.5,3-2.6,2.1-3.8C154.7,107.9,154.6,107.7,154.4,107.7z"
              />
              <path
                d="M195.3,267.7c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C195.3,270.6,196.1,268.2,195.3,267.7z"
              />
              <path
                d="M63.6,300.5c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C63.6,303.3,64.3,301,63.6,300.5z"
              />
              <path
                d="M26.1,331c-0.1-0.2-0.3-0.4-0.8-0.6
  c-2.8-0.7-5.4,5.4-1.7,3.1C24.2,333.2,26.3,331.8,26.1,331z"
              />
              <path
                d="M523.7,61.8c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C523.6,64.7,524.4,62.3,523.7,61.8z"
              />
              <path
                d="M451.6,313.9c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C451.6,316.8,452.3,314.4,451.6,313.9z"
              />
              <path
                d="M169.5,362.4c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C169.4,365.3,170.2,362.9,169.5,362.4z"
              />
              <path
                d="M562.5,372.9c-0.2-0.1-0.5-0.1-1,0.1
  c-2.6,1.2-0.7,7.6,0.7,3.4C562.4,375.8,563.2,373.4,562.5,372.9z"
              />
              <path
                d="M123.5,227.4c-0.1,0.5,0,1,0.2,1.5
  c0.5,1,3.9,4.6,3.5,1.6C127.1,229.8,124.1,224.8,123.5,227.4z"
              />
              <path
                d="M103.3,216.7c-0.1-0.2-0.5-0.5-1.2-0.7
  c-0.7-0.2-4.6-0.3-4.2,1.2c0.3,1.1,2.4,0.9,3.2,0.8C102.3,217.9,103.6,217.3,103.3,216.7z"
              />
              <path
                d="M350.9,43.1c-0.6-0.6-5.7-2.9-6.1-1.7
  c-0.3,0.7,3,1.7,3.6,1.9C349.2,43.7,352.6,45,350.9,43.1z"
              />
              <path
                d="M287.9,88.7c-2.3,0.5,0.9,7.6,2.5,5.3
  c0.7-1,0.3-2.7-0.3-3.6C289.9,89.9,288.7,88.5,287.9,88.7z"
              />
              <path
                d="M238.9,57c-2.1-0.1,3,6.6,3.4,3.1
  C242.6,58.5,240.3,57,238.9,57z"
              />
              <path
                d="M355.4,108c-1.1-0.1-5.2,0.6-3,2
  C353.8,110.9,358.7,108.4,355.4,108z"
              />
              <path
                d="M320.1,54.3c0.2,0,0.4-0.2,0.5-0.7
  c0.3-1.1-0.1-2.6-0.6-3.6c-0.4-0.7-1.9-2.5-2.5-0.9C317,50.2,318.9,54.5,320.1,54.3z"
              />
              <path
                d="M217.8,110.3c-1.9,0.2-3,1.4-0.8,2.3
  C219.5,113.6,220.7,110,217.8,110.3z"
              />
              <path
                d="M295.5,135c0.8-1.2,1.2-2.7-0.2-3.2
  c-1.8-0.7-4.6,4.7-2.2,4.8C293.8,136.6,294.8,135.9,295.5,135z"
              />
              <path
                d="M390.8,76.5c0.3-1.5-4.4-2.4-4.5-0.6
  c-0.1,1.7,3.2,2.3,4.2,1.1C390.7,76.8,390.7,76.7,390.8,76.5z"
              />
              <path
                d="M245,154.3c0.1-0.2,0-0.5-0.3-0.9
  c-1.8-2.2-7.6,1.1-3.2,1.5C242.2,154.9,244.7,155.1,245,154.3z"
              />
              <path
                d="M267.1,75c-0.5,0-1,0.2-1.4,0.6
  c-0.9,0.7-3.5,4.9-0.8,3.8C265.6,79,269.8,74.9,267.1,75z"
              />
              <path
                d="M272.7,52.8c0.2-0.2,0.4-0.6,0.4-1.3
  c0-0.7-0.8-4.5-2.1-3.8c-1,0.5-0.3,2.6-0.1,3.3C271.3,52.1,272.2,53.2,272.7,52.8z"
              />
            </g>
            <g title="Endosomes" className="endosomes_ton">
              <title>Endosomes</title>
              <path
                d="M433.2,118.5c0.4-1.4,1.6-2.4,3.3-1.4
  c2.7,1.6,0.7,6.8-2.2,4.7C433.2,121,432.9,119.6,433.2,118.5z"
              />
              <path
                d="M126.4,341.3c0.1-0.8,0.7-1.6,1.6-1.7
  c1.7-0.3,3,1.5,2.5,3.1c-0.5,1.8-2.6,2.5-3.7,0.9C126.4,342.9,126.2,342.1,126.4,341.3z"
              />
              <path
                d="M351.6,60.2c-0.1-2.7,5.3-4.1,5.3,0.4
  C356.9,65.2,351.7,63.1,351.6,60.2z"
              />
              <path
                d="M453.6,74.5c0.7-3.3,6.3-3.6,4.8,1.6
  C456.9,81,452.8,78.5,453.6,74.5z"
              />
              <path
                d="M518,196.7c1.5-3.1,5.7-1.8,5.2,1.5
  c-0.7,3.8-5.6,3.3-5.4-0.5C517.8,197.3,517.9,196.9,518,196.7z"
              />
              <path
                d="M313.2,421.4c0.2-2.8,3.5-3.6,4.4-0.7
  C318.7,424.1,312.9,426,313.2,421.4z"
              />
              <path
                d="M391.5,442.8c0.9-2.8,4.4-1.7,3.7,1.2
  C394.2,447.7,390.7,445.4,391.5,442.8z"
              />
              <path
                d="M215.9,456.1c-1.9-1.4,0-5.5,2.6-3.6
  C221,454.4,218.3,457.8,215.9,456.1z"
              />
              <path
                d="M56.3,242.3c-1.8-2.7,1.7-6.2,4-2.7
  C62.7,243.1,58.2,245.3,56.3,242.3z"
              />
              <path
                d="M238.8,82.3c-1.8-2.7,1.7-6.2,4-2.7
  C245.2,83.1,240.7,85.3,238.8,82.3z"
              />
              <path
                d="M568,295.7c-1.8-2.7,1.7-6.2,4-2.7
  C574.4,296.5,569.9,298.7,568,295.7z"
              />
              <path
                d="M186.1,403.1c0.1,0,0.1,0,0.2-0.1
  c2.2-0.7,4.8,3,2,4C186,408,183.5,404.1,186.1,403.1z"
              />
              <path
                d="M124.8,207.8c0.3-0.4,0.7-0.8,1.2-1
  c3.2-1.1,4.3,4,1.2,5C124.7,212.7,123.7,209.6,124.8,207.8z"
              />
              <path
                d="M190.7,113.3c0.6-1,2.1-1.8,3.5-1
  c2.4,1.2,1,4.9-1.7,4.3C190.2,116,190,114.4,190.7,113.3z"
              />
              <path
                d="M405,50.6c-0.3-3.2,4.2-4.7,4.9-1
  C410.5,53.4,405.3,54,405,50.6z"
              />
              <path
                d="M590.3,366.4c-1.7-1.8-0.1-6.4,2.7-3.4
  C595.2,365.4,592.8,369,590.3,366.4z"
              />
              <path
                d="M545.9,231.4c-0.9-2.6,3.8-6,4.8-2
  c0.7,2.6-2.5,5-4.5,2.7C546.1,231.8,546,231.6,545.9,231.4z"
              />
              <path
                d="M514.4,318c-0.3-0.7-0.2-1.6,0.7-2.4
  c2.2-2.2,5.8,1.1,3.6,3.3C517.3,320.2,515,319.4,514.4,318z"
              />
              <path
                d="M542.9,277.5c-0.7-2.5,3-3.5,3.7-0.8
  c0.7,2.5-2.8,3.4-3.7,1C543,277.6,543,277.6,542.9,277.5z"
              />
              <path
                d="M560.5,343.6c-0.9-2.5,2.8-4.8,3.9-1.4
  C565.6,345.6,561.5,346.5,560.5,343.6z"
              />
              <path
                d="M534.9,400.4c-1.9-2.4,0.9-6.1,3.4-3.5
  C540.9,399.7,537,403.1,534.9,400.4z"
              />
              <path
                d="M465.3,410.1c-0.4-0.8-0.3-1.8,0.5-2.8
  c1.9-2.1,5.7,0.1,4.2,2.8C468.7,412.6,466.1,411.8,465.3,410.1z"
              />
              <path
                d="M460.1,437.9c1.4-2.7,5.9-0.8,4,2.5
  C462.1,443.9,458.5,440.8,460.1,437.9z"
              />
              <path
                d="M278.2,443.2c0.1-3.1,4.9-3.5,4.6,0.8
  C282.4,448.7,278.1,446.2,278.2,443.2z"
              />
              <path
                d="M221.7,404.5c0.4-3.3,4.4-2.3,4.3,0.6
  C225.8,408.2,221.4,407.8,221.7,404.5C221.7,404.5,221.7,404.5,221.7,404.5z"
              />
              <path
                d="M127,387.8c1.7,0.7,2.1,4,0.3,4.5
  c-1.9,0.5-4.5-3.9-1.3-4.6C126.4,387.6,126.7,387.7,127,387.8z"
              />
              <path
                d="M51.3,383.2c0.1-0.1,0.2-0.2,0.3-0.3
  c2.4-1.9,5.1,2.2,3.2,3.9C53,388.5,49.4,385.3,51.3,383.2z"
              />
              <path
                d="M45.5,327.8c-0.7-1.2,0.5-3.1,2.3-3
  c0.1,0,0.3,0,0.4,0c2.5,0.4,2.4,4.4-0.9,4C46.4,328.7,45.8,328.3,45.5,327.8z"
              />
              <path
                d="M148.6,139.1c0.1-0.4,0.5-0.7,1-0.9
  c1.6-0.5,3.4,2,2.4,3.3C150.7,143.6,148,140.8,148.6,139.1z"
              />
            </g>
            <g
              title="Endosomes"
              className={locations.includes('Endosomes') || locations.includes('Vesicles') ? 'endosomes' : 'endosomes--disabled'}
            >
              <title>Endosomes</title>
              <path
                d="M433.2,118.5c0.4-1.4,1.6-2.4,3.3-1.4
  c2.7,1.6,0.7,6.8-2.2,4.7C433.2,121,432.9,119.6,433.2,118.5z"
              />
              <path
                d="M126.4,341.3c0.1-0.8,0.7-1.6,1.6-1.7
  c1.7-0.3,3,1.5,2.5,3.1c-0.5,1.8-2.6,2.5-3.7,0.9C126.4,342.9,126.2,342.1,126.4,341.3z"
              />
              <path
                d="M351.6,60.2c-0.1-2.7,5.3-4.1,5.3,0.4
  C356.9,65.2,351.7,63.1,351.6,60.2z"
              />
              <path
                d="M453.6,74.5c0.7-3.3,6.3-3.6,4.8,1.6
  C456.9,81,452.8,78.5,453.6,74.5z"
              />
              <path
                d="M518,196.7c1.5-3.1,5.7-1.8,5.2,1.5
  c-0.7,3.8-5.6,3.3-5.4-0.5C517.8,197.3,517.9,196.9,518,196.7z"
              />
              <path
                d="M313.2,421.4c0.2-2.8,3.5-3.6,4.4-0.7
  C318.7,424.1,312.9,426,313.2,421.4z"
              />
              <path
                d="M391.5,442.8c0.9-2.8,4.4-1.7,3.7,1.2
  C394.2,447.7,390.7,445.4,391.5,442.8z"
              />
              <path
                d="M215.9,456.1c-1.9-1.4,0-5.5,2.6-3.6
  C221,454.4,218.3,457.8,215.9,456.1z"
              />
              <path
                d="M56.3,242.3c-1.8-2.7,1.7-6.2,4-2.7
  C62.7,243.1,58.2,245.3,56.3,242.3z"
              />
              <path
                d="M238.8,82.3c-1.8-2.7,1.7-6.2,4-2.7
  C245.2,83.1,240.7,85.3,238.8,82.3z"
              />
              <path
                d="M568,295.7c-1.8-2.7,1.7-6.2,4-2.7
  C574.4,296.5,569.9,298.7,568,295.7z"
              />
              <path
                d="M186.1,403.1c0.1,0,0.1,0,0.2-0.1
  c2.2-0.7,4.8,3,2,4C186,408,183.5,404.1,186.1,403.1z"
              />
              <path
                d="M124.8,207.8c0.3-0.4,0.7-0.8,1.2-1
  c3.2-1.1,4.3,4,1.2,5C124.7,212.7,123.7,209.6,124.8,207.8z"
              />
              <path
                d="M190.7,113.3c0.6-1,2.1-1.8,3.5-1
  c2.4,1.2,1,4.9-1.7,4.3C190.2,116,190,114.4,190.7,113.3z"
              />
              <path
                d="M405,50.6c-0.3-3.2,4.2-4.7,4.9-1
  C410.5,53.4,405.3,54,405,50.6z"
              />
              <path
                d="M590.3,366.4c-1.7-1.8-0.1-6.4,2.7-3.4
  C595.2,365.4,592.8,369,590.3,366.4z"
              />
              <path
                d="M545.9,231.4c-0.9-2.6,3.8-6,4.8-2
  c0.7,2.6-2.5,5-4.5,2.7C546.1,231.8,546,231.6,545.9,231.4z"
              />
              <path
                d="M514.4,318c-0.3-0.7-0.2-1.6,0.7-2.4
  c2.2-2.2,5.8,1.1,3.6,3.3C517.3,320.2,515,319.4,514.4,318z"
              />
              <path
                d="M542.9,277.5c-0.7-2.5,3-3.5,3.7-0.8
  c0.7,2.5-2.8,3.4-3.7,1C543,277.6,543,277.6,542.9,277.5z"
              />
              <path
                d="M560.5,343.6c-0.9-2.5,2.8-4.8,3.9-1.4
  C565.6,345.6,561.5,346.5,560.5,343.6z"
              />
              <path
                d="M534.9,400.4c-1.9-2.4,0.9-6.1,3.4-3.5
  C540.9,399.7,537,403.1,534.9,400.4z"
              />
              <path
                d="M465.3,410.1c-0.4-0.8-0.3-1.8,0.5-2.8
  c1.9-2.1,5.7,0.1,4.2,2.8C468.7,412.6,466.1,411.8,465.3,410.1z"
              />
              <path
                d="M460.1,437.9c1.4-2.7,5.9-0.8,4,2.5
  C462.1,443.9,458.5,440.8,460.1,437.9z"
              />
              <path
                d="M278.2,443.2c0.1-3.1,4.9-3.5,4.6,0.8
  C282.4,448.7,278.1,446.2,278.2,443.2z"
              />
              <path
                d="M221.7,404.5c0.4-3.3,4.4-2.3,4.3,0.6
  C225.8,408.2,221.4,407.8,221.7,404.5C221.7,404.5,221.7,404.5,221.7,404.5z"
              />
              <path
                d="M127,387.8c1.7,0.7,2.1,4,0.3,4.5
  c-1.9,0.5-4.5-3.9-1.3-4.6C126.4,387.6,126.7,387.7,127,387.8z"
              />
              <path
                d="M51.3,383.2c0.1-0.1,0.2-0.2,0.3-0.3
  c2.4-1.9,5.1,2.2,3.2,3.9C53,388.5,49.4,385.3,51.3,383.2z"
              />
              <path
                d="M45.5,327.8c-0.7-1.2,0.5-3.1,2.3-3
  c0.1,0,0.3,0,0.4,0c2.5,0.4,2.4,4.4-0.9,4C46.4,328.7,45.8,328.3,45.5,327.8z"
              />
              <path
                d="M148.6,139.1c0.1-0.4,0.5-0.7,1-0.9
  c1.6-0.5,3.4,2,2.4,3.3C150.7,143.6,148,140.8,148.6,139.1z"
              />
            </g>
            <g title="Endoplasmic reticulum" className="endoplasmic_reticulum_ton">
              <title>Endoplasmic reticulum</title>
              <g>
                <path
                  d="M425.7,314.6c0.1,0,0.3,0,0.4,0
    c0.3-0.1,0.7-0.2,0.9-0.5c3.1-2.8,4.6-4.9,6.9-9.5c0.4-0.9,1.2-2.2,2.2-3.8c2.4-4,5.6-9.4,7.1-13.8c2.4-6.9,5.7-17.3,6.8-27.6
    c0.2-1.7,0.8-3.7,2.9-3.3c0.8,0.2,1.6,0.7,2.1,1.4c0.4,0.5,0.7,1,1.4,1.2c0.2,0.1,0.3,0.1,0.5,0.1c0.9,0,1.7-0.7,1.9-1.6
    c0.7-3.4,0.8-10.8,0.1-18c-0.6-6.7-3.8-23.1-5.6-28.8c-0.3-0.8-1-1.4-1.9-1.4c-0.2,0-0.4,0-0.6,0.1c-0.9,0.3-1.5,1.2-1.3,2.2
    c0,0.4-0.1,0.8-0.4,1.1c-0.4,0.4-1.4,0.8-1.9,0.7c-1.3-0.1-1.7-1.2-1.9-2.3c-0.1-0.4-0.2-0.8-0.3-1.1l-0.3-0.9
    c-0.4-1.1-0.7-2.3-1.1-3.4c-0.4-1.1-0.8-2.2-1.2-3.2c-0.4-1-0.9-2.1-1.4-3.1c-0.5-1-1-2.1-1.6-3.2c-0.6-1.1-1.2-2.2-1.8-3.3
    c-0.7-1.1-1.4-2.3-2.1-3.4c-0.8-1.2-1.6-2.3-2.5-3.6c-0.9-1.3-1.9-2.6-2.9-3.9l-0.2-0.2c-1-1.3-2-2.6-3-3.8
    c-10.8-13.7-23.2-24.6-34-29.9c-4.4-2.2-12.2-4.3-18-5.5c-6-1.3-12.2-1.7-16.3-1.9c-1.4-0.1-2.6-0.2-3.3-0.2
    c-1.7-0.2-3.5-0.2-5.5-0.2l-0.8,0c-1.9,0-4.1,0-5.9-0.2l-0.1,0c-1,0-1.9,0.8-1.9,1.8c-0.1,1.1,0.7,2,1.8,2.1
    c2,0.2,4.1,0.2,6.2,0.2c2.1,0,4.2,0.1,6,0.2c0.5,0,1.2,0.1,2.1,0.1c3.6,0.2,9.5,0.6,15.2,1.6c4.9,0.8,14,3,19.1,5.6
    c7.4,3.7,19.1,11.7,32.6,28.8c12.5,15.9,16,23.9,19.4,38.6c0.2,0.9,1,1.5,1.9,1.5c0.5,0,1-0.2,1.3-0.5c0.8-0.8,0.3-2.3,1.2-3
    c1.7-1.2,3.3,0.7,3.6,2.2c0,0.2,0.1,0.4,0.1,0.6c0.3,1.2,0.5,2.5,0.8,3.7l0.1,0.3c0.1,0.6,0.2,1.2,0.3,1.8c0.2,1,0.4,2,0.5,3
    c0.1,0.8,0.3,1.5,0.4,2.2c0.3,1.4,0.5,2.9,0.8,4.7l0.1,0.6c0.2,1.1,0.3,2.2,0.4,3.3l0,0.4c0.1,1.6,0.2,3.3,0.3,4.9
    c0,1,0.2,1.9,0.2,2.9c0.1,1.8-0.5,4.3-2.9,4.1c-1.9-0.2-2-2.5-2.1-4c0-1-0.1-2.1-0.1-3.1c-0.1-1.9-0.2-4.1-0.4-6.3
    c-0.1-1.8-0.3-3.6-0.6-5.5c-0.2-1.4-0.4-2.7-0.6-4.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.9-1-1.6-1.9-1.6c-0.1,0-0.2,0-0.3,0
    c-0.5,0.1-0.9,0.3-1.2,0.8c-0.3,0.4-0.4,1-0.3,1.5c0.3,1.9,3.2,18.7,1.3,33.1c-1,8-3.9,18.5-5.6,23.4c-2.1,6.2-5.5,11.7-7.9,15.8
    c-0.9,1.6-1.7,2.9-2.2,3.9c-2.2,4.4-3.5,6.1-6.1,8.4c-0.4,0.3-0.6,0.8-0.6,1.3c0,0.5,0.2,1,0.5,1.4
    C424.7,314.4,425.2,314.6,425.7,314.6z"
                />
                <path
                  d="M335.1,349.2c3.5,0,6.7-0.2,9.5-0.5
    c1.9-0.1,3.8-0.3,5.4-0.3c0.1,0,0.2,0,0.3,0c0.9-0.2,1.6-1,1.6-2c0-1.1-0.9-1.9-2-1.9c-1.8,0-3.6,0.2-5.5,0.3l-0.1,0
    c-2.8,0.2-5.9,0.5-9.3,0.5c-1.6,0-3.1-0.1-4.6-0.2l-0.1,0c-1,0-1.9,0.8-2,1.8c0,0.5,0.1,1,0.5,1.4c0.3,0.4,0.8,0.6,1.3,0.7
    C331.9,349.2,333.4,349.2,335.1,349.2z"
                />
                <path
                  d="M257.4,198c0.1,0,0.3,0,0.4,0
    c0.6-0.1,1-0.5,1.3-1c0.2-0.3,0.3-0.6,0.5-0.8c0.4-0.7,0.7-1.4,1.1-2c0.4-0.6,0.7-1.1,1.1-1.7c0.7-1,1.3-2.1,1.9-3.1
    c0.3-0.6,0.6-1.3,1.3-1.6c0.8-0.3,1.6,0.4,1.7,1.1c0.1,0.4,0,0.7,0,1.1c0,0.2-0.2,1,0,1.2c0.6,0.8,1.6-0.8,2-1.2
    c0.5-0.5,1.1-1,1.6-1.6l0.1-0.2c0.3-0.4,0.7-0.7,0.9-1.2l0.1-0.2c0.5-0.6,0.8-1.2,0.7-1.7c-0.1-0.1-0.2-0.4-0.7-0.4
    c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.5,0.1-0.8,0.1c-0.8,0-1.2-0.2-1.4-0.7c-0.3-0.7,0.2-1.6,0.6-2.1
    c0.3-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.6-0.6,0.8-0.9l0.2-0.2c1.3-1.4,2.5-2.8,3.8-4.1c2.9-3,6.1-5.8,9.6-8.7c2-1.7,4-3.3,6.3-5
    c1.1-0.9,3.8-2.6,4.3-0.2c0.2,0.7-0.2,1.3-0.4,1.9c-0.2,0.8,0.5,0.7,1,0.6c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.7-0.6,1-0.7
    c0.2-0.1,0.6-0.2,0.9-0.4c0.8-0.3,1.4-0.7,2.1-1.2c0.5-0.4,1.3-0.5,1.4-1.2c0.1-1.2-1.9-0.7-2.5-0.8c-0.9-0.2-1.3-1.2-1.1-2
    c0.2-1,1.2-1.5,2.1-1.9c0.8-0.3,1.7-0.7,2.5-1.1l0.2-0.1c0.8-0.3,1.5-0.7,2.2-1c3.2-1.5,6.3-2.7,9-3.7c2.6-1,5.3-1.9,8.3-2.8
    c2.3-0.7,4.7-1.3,6.9-1.9c1.8-0.4,3.3-0.8,4.7-1.1c0.5-0.1,0.9-0.4,1.2-0.8c0.3-0.4,0.4-0.9,0.3-1.4c-0.2-0.9-1-1.5-1.9-1.5
    c-0.1,0-0.3,0-0.4,0c-6.6,1.3-29.3,6.6-45.2,18.7l-0.6,0.4c-1.5,1.2-3,2.3-4.5,3.5c-1.4,1.2-2.8,2.3-4.2,3.5
    c-1.4,1.2-2.7,2.4-3.9,3.5c-1.3,1.2-2.5,2.4-3.6,3.5c-0.3,0.3-0.6,0.6-0.8,0.9c-0.7,0.7-1.4,1.6-2.4,1.9c-1.5,0.3-2.8-1.2-2.3-2.6
    c0.4-1.2,1.2-2.1,2.1-2.9c0.8-0.7,1.5-1.4,2.3-2.2c1.3-1.3,2.7-2.6,4-3.8l1.1-1c10.3-9.2,16.9-14.2,28.5-19.5
    c0.5-0.2,0.8-0.6,1-1.1c0.2-0.5,0.2-1-0.1-1.5c-0.3-0.7-1-1.2-1.8-1.2c-0.3,0-0.6,0.1-0.8,0.2c-1.3,0.6-2.6,1.2-4,1.9
    c-0.4,0.2-0.8,0.4-1.2,0.6c-0.8,0.4-1.4,0.7-2.1,1.1c-1.1,0.6-2.2,1.3-3.2,2c-1,0.7-2,1.3-2.9,1.8c-0.9,0.5-2,0.6-2.8,0
    c-0.8-0.5-1.4-1.5-1.3-2.5c0.1-0.8,0.8-1.5,1-2.2c0.2-0.6,0.1-1.4-0.2-2c-0.6-1-2.5-1.1-3.3-0.4c-7.5,5.8-13.6,10.8-18.2,15.1
    c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1.1,0.5,1.5c0.4,0.4,0.9,0.7,1.5,0.7c0.5,0,1-0.2,1.4-0.5c1.4-1.3,2.9-2.6,4.2-3.7l1.4-1.2
    c0.9-0.8,1.8-1.5,2.7-2.2l0.8-0.7c0.6-0.5,1.1-0.9,1.7-1.4c1.1-0.8,3-1.7,4.3-0.9c1.3,0.8,0.9,2.7,0.3,3.8c-0.7,1.2-2,1.8-3.1,2.6
    c-1.2,0.9-2.3,1.9-3.5,2.9c0,0-2.9,2.6-2.9,2.6c-9.5,8.5-16.6,16.3-19.9,22.1c-0.3,0.5-0.3,1-0.2,1.5c0.1,0.5,0.5,0.9,0.9,1.2
    c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.4-0.4,1.7-1c0.7-1.2,1.5-2.3,2.3-3.4c0.2-0.3,0.4-0.5,0.5-0.8c0.7-1,1.9-2.1,3.2-1.8
    c1.8,0.5,1.6,3.1,0.8,4.3c-0.7,1-1.5,1.8-2.2,2.8c-0.7,1-1.3,2-1.9,3c-1.3,2-2.5,4-3.7,6.2c-0.2,0.5-0.3,1-0.2,1.5
    c0.1,0.5,0.5,0.9,0.9,1.2C256.8,197.9,257.1,198,257.4,198z"
                />
                <path
                  d="M448.2,203.2c0.1,0,0.3,0,0.4,0
    c0.1,0,0.1,0,0.2-0.1c0.9-0.3,1.3-1.2,1.4-2.1c0.1-1.4-0.4-2.7-0.5-4.1c-0.1-1.3,0.3-2.8,1.9-2.8c1.6,0,2.8,1.4,3.7,2.5
    c0.4,0.5,1,0.8,1.6,0.8c0.4,0,0.9-0.1,1.2-0.4c0.4-0.3,0.7-0.8,0.8-1.3c0.1-0.5-0.1-1.1-0.4-1.5c-1.7-2.2-2.6-4.7-3.5-7.1
    c-0.5-1.3-1-2.6-1.5-3.7c-0.3-0.7-1-1.1-1.8-1.1c-0.3,0-0.6,0.1-0.9,0.2c-0.5,0.2-0.8,0.7-1,1.2c-0.2,0.5-0.1,1.1,0.1,1.5l0.1,0.3
    c0.7,1.3,1.9,3.6,0.3,4.7c-0.4,0.3-1.1,0.4-1.6,0.5c-2,0.1-3.3-2.1-4.2-3.6c-0.3-0.5-0.6-0.9-0.8-1.3c-0.6-1-1.2-2.1-1.8-3.2
    c-1-1.7-1.9-3-2.8-4.4c-0.4-0.5-1-0.8-1.6-0.8c-0.4,0-0.8,0.1-1.2,0.4c-0.4,0.3-0.7,0.8-0.8,1.3c-0.1,0.5,0.1,1,0.4,1.5
    c3.1,4.2,8.1,13.7,10.5,21.2C446.6,202.6,447.3,203.2,448.2,203.2z"
                />
                <path
                  d="M466.7,251.6c1,0,1.9-0.8,2-1.8
    c1-10.2-2.2-23.8-3.5-28.9c-0.2-0.7-0.3-1.2-0.4-1.5c-0.2-0.9-1-1.6-2-1.6c-0.1,0-0.3,0-0.4,0c-1.1,0.2-1.8,1.3-1.5,2.4
    c0.1,0.4,0.2,0.9,0.4,1.6c1.2,4.9,4.3,18,3.4,27.6c-0.1,0.5,0.1,1.1,0.5,1.5c0.3,0.4,0.8,0.7,1.4,0.7
    C466.6,251.6,466.7,251.6,466.7,251.6z"
                />
                <path
                  d="M366,345.6c-0.1-1.1,0.7-2,1.8-2.1
    c1.1-0.1,3.1,0,3.2-1.5c0.2-2.5-3.1-2-4.6-1.9c-1.5,0.1-3,0.2-4.6,0.4c-2,0.2-3.9,0.3-5.9,0.5c-3,0.2-6,0.3-8.9,0.4l-2.1,0.1
    c-1,0-1.9-0.8-1.9-1.8c0-0.5,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.3-0.6l1.2,0c1,0,2.1-0.1,3.1-0.1c1.1-0.1,2.1-0.1,3.3-0.2
    c1.7-0.1,3.6-0.2,5.4-0.3l0.4,0c0.7,0,1.5-0.1,2.2-0.2c0.5-0.1,1.1-0.6,1-1.1c0-0.8-0.8-1.5-1.5-1.8c-0.4-0.2-0.8-0.4-0.9-0.8
    c-0.1-0.2,0-0.5,0.2-0.7c0.6-0.7,1.6-0.6,2.3-0.7c0.7-0.1,1.3-0.5,2-0.5c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0c0.6,0,1.2,0,1.7,0.3
    c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.6,0.3l0.1,0.1l0,0.1c0.2,0.6-1.2,1.6-1.5,2.1c-0.4,0.7-0.4,1.6,0.4,1.9
    c0.2,0.1,0.5,0.1,0.8,0.1c0.3,0,0.7,0,1-0.1l0.1,0c0.6-0.1,1.3-0.2,1.9-0.3l0.2,0c0.8-0.1,1.6-0.3,2.4-0.4c1.5-0.2,3-0.4,4.4-0.7
    c2.8-0.6,5.9-1.3,8.9-2.1c2-0.6,4-1.2,5.6-1.8c1-0.3,2-0.7,3.2-1.2l1-0.4c2.9-1.2,5.9-2.4,8.7-3.9c0.1-0.1,0.3-0.1,0.4-0.2
    c0.4-0.2,0.8-0.4,1-0.7c0.9-1-0.1-2.4-1.2-2.6c-0.4-0.1-0.7-0.1-1-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.7,0.8-1.6,1-1.8
    c0.9-0.9,2.4-1.3,3.6-1.7l0.2-0.1l0.2-0.1c0.2-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.2,0.8,0.6c0.1,0.7-0.3,1.4-0.2,2.1
    c0.1,0.6,0.6,1.1,1.2,1.1c0.4,0,0.8-0.2,1.3-0.5c0.7-0.4,1.3-0.9,2-1.3c0.9-0.6,2.1-1.5,3.2-2.5c0.4-0.3,0.8-0.5,1.3-0.5
    c0.6,0,1.1,0.2,1.4,0.7c0.3,0.4,0.5,0.9,0.5,1.4c0,0.5-0.3,1-0.7,1.3c-1.1,1-2.3,1.9-3.7,2.8c-0.7,0.5-1.3,0.9-2,1.3
    c-1.2,0.8-5.7,2.7-4,4.8c1.1,1.4,2.7,0.5,3.8-0.3c0.4-0.3,0.9-0.6,1.4-0.9c1-0.5,2-0.6,2.7,0.4c0.6,0.9,0.5,2.1-0.4,2.8
    c-12.7,9.3-30.9,15.5-50,17c-0.1,0-0.1,0-0.2,0C366.9,347.4,366,346.6,366,345.6z M403.9,329.9c-1.6,0.3-2.9,1.1-4.4,1.7l-0.6,0.3
    c-2.3,1-4.8,2-7.5,2.9c-1.4,0.5-2.9,1-4.4,1.4c-2.8,0.8-5.6,1.5-8.4,2.1c-0.9,0.2-2.1,0.4-2.6,1.3c-0.4,0.8-0.1,1.6,0.6,2.1
    c0.4,0.3,1,0.4,1.5,0.5c1.5,0.1,3.1-0.4,4.5-0.8c0.9-0.2,1.9-0.5,2.8-0.7c0.8-0.2,1.6-0.4,2.4-0.6c2.1-0.5,4.6-1.2,7.1-2.1
    c2.3-0.8,4.6-1.7,7-2.8c1.1-0.5,2.1-0.9,3.1-1.4c0.8-0.4,1.6-0.8,1.7-1.8c0.1-0.9-0.5-1.9-1.4-2
    C404.8,329.8,404.3,329.9,403.9,329.9z"
                />
                <path
                  d="M366.7,355.7c1.7-0.1,6.1-0.3,11.2-1.2
    c0.5-0.1,1-0.4,1.3-0.8c0.3-0.4,0.4-1,0.3-1.5c-0.2-1-1-1.6-2-1.6c-0.1,0-0.3,0-0.4,0c-4.9,0.9-9,1.1-10.6,1.2c-1.1,0-2,1-2,2.1
    C364.7,354.8,365.6,355.7,366.7,355.7L366.7,355.7z"
                />
                <path
                  d="M274.7,334.9c-7.3-3.4-16.4-11.8-25.1-23.1
    c-10.1-13.1-12.2-23-14.9-35.6l-0.5-2.2c-0.1-0.5,0-1,0.3-1.5c0.3-0.4,0.7-0.7,1.2-0.9c0.1,0,0.3,0,0.4,0c0.9,0,1.7,0.6,1.9,1.6
    c0.3,1.2,0.4,2.5,1.3,3.4c0.6,0.6,1.5,0.7,2.2,0.3c1.6-0.9,1.1-2.5,0.8-4c-0.2-0.8-0.3-1.6-0.5-2.3c-0.4-2-0.8-4-1.2-6.2
    c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.1,0,0.3,0,0.4,0c0.9,0,1.7,0.6,1.9,1.5c0.4,2.1,0.8,4.2,1.2,6.3
    c1.8,9.7,3.4,18.1,11.1,29.9c9.5,14.5,15.1,19.5,22.8,23.9c0.9,0.5,1.2,1.7,0.7,2.6c-0.3,0.5-0.7,0.8-1.3,0.9c-0.1,0-0.2,0-0.4,0
    c-0.3,0-0.7-0.1-1-0.3c-0.7-0.4-1.4-0.8-2.1-1.3c-0.8-0.6-2.4-0.9-3.2-0.2c-0.7,0.6-0.9,1.9-0.5,2.6c0.5,1,1.6,1.5,2.5,2
    c0.7,0.4,1.4,0.8,2.1,1.1c0.5,0.2,0.8,0.6,1,1.1c0.2,0.5,0.2,1-0.1,1.5c-0.3,0.7-1,1.1-1.8,1.1C275.3,335.1,275,335,274.7,334.9z
     M241.6,282.4c-1.5,0.9-1,3.2-0.6,4.6c0.3,1.1,0.8,2.2,1.2,3.3c0.9,2.4,1.8,4.4,2.6,6.3c1,2.3,2,4.2,3.2,6.1
    c1.3,2.2,2.9,4.4,4.7,6.7c1.2,1.6,2.3,2.9,3.3,4c2.3,2.7,4.1,4.7,5.9,6.5c0.5,0.5,0.9,1,1.4,1.5c0.8,0.9,1.8,2,3,2.3
    c1,0.2,2.1-0.3,2.3-1.3c0.5-2.1-1.7-3.2-2.9-4.4c-0.7-0.7-1.3-1.4-1.9-2.1c-1.2-1.4-2.4-2.8-3.5-4.3c-2.6-3.4-5-7-6.8-9.7
    c-1.1-1.7-2.1-3.4-3-5c-1.6-2.9-3.1-6-4.2-9c-0.4-1.1-0.8-2.3-1.1-3.5c-0.3-1-1.2-2-2.2-2.3C242.6,282,242.1,282.1,241.6,282.4z"
                />
                <path
                  d="M265.8,340.5c0.7,0,1.3-0.3,1.6-0.8
    c0.6-0.9,0.4-2.2-0.5-2.8c-4.7-3.3-9.8-8.1-12.3-11c-0.4-0.4-0.9-0.7-1.5-0.7c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.7,0.8-0.7,1.4
    c0,0.5,0.1,1.1,0.5,1.5c2.7,3.1,8,8.1,13,11.6C265,340.3,265.4,340.5,265.8,340.5z"
                />
                <path
                  d="M234,256c1.1,0,2-0.9,2-2
    c0-1.8,0.1-3.5,0.2-5.2c0.3-3.5,0.7-6.7,1.2-9.9c0.2-0.9,0.3-1.9,0.4-2.8l0.1-0.5c0.1-0.6,0.2-1.2,0.3-1.7
    c0.1-0.4,0.1-0.9,0.2-1.3c0.2-1,0.6-2,1.6-2.6c1-0.6,2.2-0.4,2.8,0.6c0.7,1,0.5,2.6,0.3,3.8c-0.4,2.6-0.8,5.1-1.1,7.7
    c-0.3,2.1-0.5,4.3-0.6,6.4c0,0.5,0.1,1,0.5,1.4c0.3,0.4,0.8,0.6,1.3,0.6c0.3,0,0.4,0,0.5,0c0.9-0.2,1.5-0.9,1.5-1.8
    c0.5-8.5,2.8-21.7,4.3-27.6c0.1-0.5,0-1-0.2-1.5c-0.3-0.4-0.7-0.8-1.2-0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.9,0-1.7,0.6-1.9,1.5
    c-0.3,1.2-0.4,2.5-1,3.7c-0.5,1-1.5,2-2.7,1.9c-1.9-0.2-2-2.4-1.7-3.8c0.1-0.6,0.2-1.2,0.3-1.8c0.5-2.7,1-5.4,1.1-8.2
    c0-1.1-0.8-2-1.9-2c-1.1,0-2,0.8-2,1.9c-0.1,3-1,8-2.1,13.7C234,234.3,232,245,232,254C232,255.1,232.9,256,234,256z"
                />
                <path
                  d="M226.5,237.6c1,0,1.9-0.8,2-1.8
    c0.3-2.3,1.2-8.2,2.5-14.5c0.1-0.5,0-1.1-0.3-1.5c-0.3-0.5-0.8-0.8-1.3-0.9c-0.1,0-0.3,0-0.4,0c-1,0-1.8,0.7-2,1.6
    c-1.3,6.4-2.3,12.5-2.6,14.8c-0.1,1.1,0.6,2.1,1.8,2.3C226.3,237.6,226.4,237.6,226.5,237.6z"
                />
              </g>
            </g>
            <g
              title="Endoplasmic reticulum"
              className={locations.includes('Endoplasmic reticulum') ? 'endoplasmic_reticulum' : 'endoplasmic_reticulum--disabled'}
            >
              <title>Endoplasmic reticulum</title>
              <g>
                <path
                  d="M425.7,314.6c0.1,0,0.3,0,0.4,0
    c0.3-0.1,0.7-0.2,0.9-0.5c3.1-2.8,4.6-4.9,6.9-9.5c0.4-0.9,1.2-2.2,2.2-3.8c2.4-4,5.6-9.4,7.1-13.8c2.4-6.9,5.7-17.3,6.8-27.6
    c0.2-1.7,0.8-3.7,2.9-3.3c0.8,0.2,1.6,0.7,2.1,1.4c0.4,0.5,0.7,1,1.4,1.2c0.2,0.1,0.3,0.1,0.5,0.1c0.9,0,1.7-0.7,1.9-1.6
    c0.7-3.4,0.8-10.8,0.1-18c-0.6-6.7-3.8-23.1-5.6-28.8c-0.3-0.8-1-1.4-1.9-1.4c-0.2,0-0.4,0-0.6,0.1c-0.9,0.3-1.5,1.2-1.3,2.2
    c0,0.4-0.1,0.8-0.4,1.1c-0.4,0.4-1.4,0.8-1.9,0.7c-1.3-0.1-1.7-1.2-1.9-2.3c-0.1-0.4-0.2-0.8-0.3-1.1l-0.3-0.9
    c-0.4-1.1-0.7-2.3-1.1-3.4c-0.4-1.1-0.8-2.2-1.2-3.2c-0.4-1-0.9-2.1-1.4-3.1c-0.5-1-1-2.1-1.6-3.2c-0.6-1.1-1.2-2.2-1.8-3.3
    c-0.7-1.1-1.4-2.3-2.1-3.4c-0.8-1.2-1.6-2.3-2.5-3.6c-0.9-1.3-1.9-2.6-2.9-3.9l-0.2-0.2c-1-1.3-2-2.6-3-3.8
    c-10.8-13.7-23.2-24.6-34-29.9c-4.4-2.2-12.2-4.3-18-5.5c-6-1.3-12.2-1.7-16.3-1.9c-1.4-0.1-2.6-0.2-3.3-0.2
    c-1.7-0.2-3.5-0.2-5.5-0.2l-0.8,0c-1.9,0-4.1,0-5.9-0.2l-0.1,0c-1,0-1.9,0.8-1.9,1.8c-0.1,1.1,0.7,2,1.8,2.1
    c2,0.2,4.1,0.2,6.2,0.2c2.1,0,4.2,0.1,6,0.2c0.5,0,1.2,0.1,2.1,0.1c3.6,0.2,9.5,0.6,15.2,1.6c4.9,0.8,14,3,19.1,5.6
    c7.4,3.7,19.1,11.7,32.6,28.8c12.5,15.9,16,23.9,19.4,38.6c0.2,0.9,1,1.5,1.9,1.5c0.5,0,1-0.2,1.3-0.5c0.8-0.8,0.3-2.3,1.2-3
    c1.7-1.2,3.3,0.7,3.6,2.2c0,0.2,0.1,0.4,0.1,0.6c0.3,1.2,0.5,2.5,0.8,3.7l0.1,0.3c0.1,0.6,0.2,1.2,0.3,1.8c0.2,1,0.4,2,0.5,3
    c0.1,0.8,0.3,1.5,0.4,2.2c0.3,1.4,0.5,2.9,0.8,4.7l0.1,0.6c0.2,1.1,0.3,2.2,0.4,3.3l0,0.4c0.1,1.6,0.2,3.3,0.3,4.9
    c0,1,0.2,1.9,0.2,2.9c0.1,1.8-0.5,4.3-2.9,4.1c-1.9-0.2-2-2.5-2.1-4c0-1-0.1-2.1-0.1-3.1c-0.1-1.9-0.2-4.1-0.4-6.3
    c-0.1-1.8-0.3-3.6-0.6-5.5c-0.2-1.4-0.4-2.7-0.6-4.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.9-1-1.6-1.9-1.6c-0.1,0-0.2,0-0.3,0
    c-0.5,0.1-0.9,0.3-1.2,0.8c-0.3,0.4-0.4,1-0.3,1.5c0.3,1.9,3.2,18.7,1.3,33.1c-1,8-3.9,18.5-5.6,23.4c-2.1,6.2-5.5,11.7-7.9,15.8
    c-0.9,1.6-1.7,2.9-2.2,3.9c-2.2,4.4-3.5,6.1-6.1,8.4c-0.4,0.3-0.6,0.8-0.6,1.3c0,0.5,0.2,1,0.5,1.4
    C424.7,314.4,425.2,314.6,425.7,314.6z"
                />
                <path
                  d="M335.1,349.2c3.5,0,6.7-0.2,9.5-0.5
    c1.9-0.1,3.8-0.3,5.4-0.3c0.1,0,0.2,0,0.3,0c0.9-0.2,1.6-1,1.6-2c0-1.1-0.9-1.9-2-1.9c-1.8,0-3.6,0.2-5.5,0.3l-0.1,0
    c-2.8,0.2-5.9,0.5-9.3,0.5c-1.6,0-3.1-0.1-4.6-0.2l-0.1,0c-1,0-1.9,0.8-2,1.8c0,0.5,0.1,1,0.5,1.4c0.3,0.4,0.8,0.6,1.3,0.7
    C331.9,349.2,333.4,349.2,335.1,349.2z"
                />
                <path
                  d="M257.4,198c0.1,0,0.3,0,0.4,0
    c0.6-0.1,1-0.5,1.3-1c0.2-0.3,0.3-0.6,0.5-0.8c0.4-0.7,0.7-1.4,1.1-2c0.4-0.6,0.7-1.1,1.1-1.7c0.7-1,1.3-2.1,1.9-3.1
    c0.3-0.6,0.6-1.3,1.3-1.6c0.8-0.3,1.6,0.4,1.7,1.1c0.1,0.4,0,0.7,0,1.1c0,0.2-0.2,1,0,1.2c0.6,0.8,1.6-0.8,2-1.2
    c0.5-0.5,1.1-1,1.6-1.6l0.1-0.2c0.3-0.4,0.7-0.7,0.9-1.2l0.1-0.2c0.5-0.6,0.8-1.2,0.7-1.7c-0.1-0.1-0.2-0.4-0.7-0.4
    c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.5,0.1-0.8,0.1c-0.8,0-1.2-0.2-1.4-0.7c-0.3-0.7,0.2-1.6,0.6-2.1
    c0.3-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.6-0.6,0.8-0.9l0.2-0.2c1.3-1.4,2.5-2.8,3.8-4.1c2.9-3,6.1-5.8,9.6-8.7c2-1.7,4-3.3,6.3-5
    c1.1-0.9,3.8-2.6,4.3-0.2c0.2,0.7-0.2,1.3-0.4,1.9c-0.2,0.8,0.5,0.7,1,0.6c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.7-0.6,1-0.7
    c0.2-0.1,0.6-0.2,0.9-0.4c0.8-0.3,1.4-0.7,2.1-1.2c0.5-0.4,1.3-0.5,1.4-1.2c0.1-1.2-1.9-0.7-2.5-0.8c-0.9-0.2-1.3-1.2-1.1-2
    c0.2-1,1.2-1.5,2.1-1.9c0.8-0.3,1.7-0.7,2.5-1.1l0.2-0.1c0.8-0.3,1.5-0.7,2.2-1c3.2-1.5,6.3-2.7,9-3.7c2.6-1,5.3-1.9,8.3-2.8
    c2.3-0.7,4.7-1.3,6.9-1.9c1.8-0.4,3.3-0.8,4.7-1.1c0.5-0.1,0.9-0.4,1.2-0.8c0.3-0.4,0.4-0.9,0.3-1.4c-0.2-0.9-1-1.5-1.9-1.5
    c-0.1,0-0.3,0-0.4,0c-6.6,1.3-29.3,6.6-45.2,18.7l-0.6,0.4c-1.5,1.2-3,2.3-4.5,3.5c-1.4,1.2-2.8,2.3-4.2,3.5
    c-1.4,1.2-2.7,2.4-3.9,3.5c-1.3,1.2-2.5,2.4-3.6,3.5c-0.3,0.3-0.6,0.6-0.8,0.9c-0.7,0.7-1.4,1.6-2.4,1.9c-1.5,0.3-2.8-1.2-2.3-2.6
    c0.4-1.2,1.2-2.1,2.1-2.9c0.8-0.7,1.5-1.4,2.3-2.2c1.3-1.3,2.7-2.6,4-3.8l1.1-1c10.3-9.2,16.9-14.2,28.5-19.5
    c0.5-0.2,0.8-0.6,1-1.1c0.2-0.5,0.2-1-0.1-1.5c-0.3-0.7-1-1.2-1.8-1.2c-0.3,0-0.6,0.1-0.8,0.2c-1.3,0.6-2.6,1.2-4,1.9
    c-0.4,0.2-0.8,0.4-1.2,0.6c-0.8,0.4-1.4,0.7-2.1,1.1c-1.1,0.6-2.2,1.3-3.2,2c-1,0.7-2,1.3-2.9,1.8c-0.9,0.5-2,0.6-2.8,0
    c-0.8-0.5-1.4-1.5-1.3-2.5c0.1-0.8,0.8-1.5,1-2.2c0.2-0.6,0.1-1.4-0.2-2c-0.6-1-2.5-1.1-3.3-0.4c-7.5,5.8-13.6,10.8-18.2,15.1
    c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1.1,0.5,1.5c0.4,0.4,0.9,0.7,1.5,0.7c0.5,0,1-0.2,1.4-0.5c1.4-1.3,2.9-2.6,4.2-3.7l1.4-1.2
    c0.9-0.8,1.8-1.5,2.7-2.2l0.8-0.7c0.6-0.5,1.1-0.9,1.7-1.4c1.1-0.8,3-1.7,4.3-0.9c1.3,0.8,0.9,2.7,0.3,3.8c-0.7,1.2-2,1.8-3.1,2.6
    c-1.2,0.9-2.3,1.9-3.5,2.9c0,0-2.9,2.6-2.9,2.6c-9.5,8.5-16.6,16.3-19.9,22.1c-0.3,0.5-0.3,1-0.2,1.5c0.1,0.5,0.5,0.9,0.9,1.2
    c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.4-0.4,1.7-1c0.7-1.2,1.5-2.3,2.3-3.4c0.2-0.3,0.4-0.5,0.5-0.8c0.7-1,1.9-2.1,3.2-1.8
    c1.8,0.5,1.6,3.1,0.8,4.3c-0.7,1-1.5,1.8-2.2,2.8c-0.7,1-1.3,2-1.9,3c-1.3,2-2.5,4-3.7,6.2c-0.2,0.5-0.3,1-0.2,1.5
    c0.1,0.5,0.5,0.9,0.9,1.2C256.8,197.9,257.1,198,257.4,198z"
                />
                <path
                  d="M448.2,203.2c0.1,0,0.3,0,0.4,0
    c0.1,0,0.1,0,0.2-0.1c0.9-0.3,1.3-1.2,1.4-2.1c0.1-1.4-0.4-2.7-0.5-4.1c-0.1-1.3,0.3-2.8,1.9-2.8c1.6,0,2.8,1.4,3.7,2.5
    c0.4,0.5,1,0.8,1.6,0.8c0.4,0,0.9-0.1,1.2-0.4c0.4-0.3,0.7-0.8,0.8-1.3c0.1-0.5-0.1-1.1-0.4-1.5c-1.7-2.2-2.6-4.7-3.5-7.1
    c-0.5-1.3-1-2.6-1.5-3.7c-0.3-0.7-1-1.1-1.8-1.1c-0.3,0-0.6,0.1-0.9,0.2c-0.5,0.2-0.8,0.7-1,1.2c-0.2,0.5-0.1,1.1,0.1,1.5l0.1,0.3
    c0.7,1.3,1.9,3.6,0.3,4.7c-0.4,0.3-1.1,0.4-1.6,0.5c-2,0.1-3.3-2.1-4.2-3.6c-0.3-0.5-0.6-0.9-0.8-1.3c-0.6-1-1.2-2.1-1.8-3.2
    c-1-1.7-1.9-3-2.8-4.4c-0.4-0.5-1-0.8-1.6-0.8c-0.4,0-0.8,0.1-1.2,0.4c-0.4,0.3-0.7,0.8-0.8,1.3c-0.1,0.5,0.1,1,0.4,1.5
    c3.1,4.2,8.1,13.7,10.5,21.2C446.6,202.6,447.3,203.2,448.2,203.2z"
                />
                <path
                  d="M466.7,251.6c1,0,1.9-0.8,2-1.8
    c1-10.2-2.2-23.8-3.5-28.9c-0.2-0.7-0.3-1.2-0.4-1.5c-0.2-0.9-1-1.6-2-1.6c-0.1,0-0.3,0-0.4,0c-1.1,0.2-1.8,1.3-1.5,2.4
    c0.1,0.4,0.2,0.9,0.4,1.6c1.2,4.9,4.3,18,3.4,27.6c-0.1,0.5,0.1,1.1,0.5,1.5c0.3,0.4,0.8,0.7,1.4,0.7
    C466.6,251.6,466.7,251.6,466.7,251.6z"
                />
                <path
                  d="M366,345.6c-0.1-1.1,0.7-2,1.8-2.1
    c1.1-0.1,3.1,0,3.2-1.5c0.2-2.5-3.1-2-4.6-1.9c-1.5,0.1-3,0.2-4.6,0.4c-2,0.2-3.9,0.3-5.9,0.5c-3,0.2-6,0.3-8.9,0.4l-2.1,0.1
    c-1,0-1.9-0.8-1.9-1.8c0-0.5,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.3-0.6l1.2,0c1,0,2.1-0.1,3.1-0.1c1.1-0.1,2.1-0.1,3.3-0.2
    c1.7-0.1,3.6-0.2,5.4-0.3l0.4,0c0.7,0,1.5-0.1,2.2-0.2c0.5-0.1,1.1-0.6,1-1.1c0-0.8-0.8-1.5-1.5-1.8c-0.4-0.2-0.8-0.4-0.9-0.8
    c-0.1-0.2,0-0.5,0.2-0.7c0.6-0.7,1.6-0.6,2.3-0.7c0.7-0.1,1.3-0.5,2-0.5c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0c0.6,0,1.2,0,1.7,0.3
    c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.6,0.3l0.1,0.1l0,0.1c0.2,0.6-1.2,1.6-1.5,2.1c-0.4,0.7-0.4,1.6,0.4,1.9
    c0.2,0.1,0.5,0.1,0.8,0.1c0.3,0,0.7,0,1-0.1l0.1,0c0.6-0.1,1.3-0.2,1.9-0.3l0.2,0c0.8-0.1,1.6-0.3,2.4-0.4c1.5-0.2,3-0.4,4.4-0.7
    c2.8-0.6,5.9-1.3,8.9-2.1c2-0.6,4-1.2,5.6-1.8c1-0.3,2-0.7,3.2-1.2l1-0.4c2.9-1.2,5.9-2.4,8.7-3.9c0.1-0.1,0.3-0.1,0.4-0.2
    c0.4-0.2,0.8-0.4,1-0.7c0.9-1-0.1-2.4-1.2-2.6c-0.4-0.1-0.7-0.1-1-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.7,0.8-1.6,1-1.8
    c0.9-0.9,2.4-1.3,3.6-1.7l0.2-0.1l0.2-0.1c0.2-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.2,0.8,0.6c0.1,0.7-0.3,1.4-0.2,2.1
    c0.1,0.6,0.6,1.1,1.2,1.1c0.4,0,0.8-0.2,1.3-0.5c0.7-0.4,1.3-0.9,2-1.3c0.9-0.6,2.1-1.5,3.2-2.5c0.4-0.3,0.8-0.5,1.3-0.5
    c0.6,0,1.1,0.2,1.4,0.7c0.3,0.4,0.5,0.9,0.5,1.4c0,0.5-0.3,1-0.7,1.3c-1.1,1-2.3,1.9-3.7,2.8c-0.7,0.5-1.3,0.9-2,1.3
    c-1.2,0.8-5.7,2.7-4,4.8c1.1,1.4,2.7,0.5,3.8-0.3c0.4-0.3,0.9-0.6,1.4-0.9c1-0.5,2-0.6,2.7,0.4c0.6,0.9,0.5,2.1-0.4,2.8
    c-12.7,9.3-30.9,15.5-50,17c-0.1,0-0.1,0-0.2,0C366.9,347.4,366,346.6,366,345.6z M403.9,329.9c-1.6,0.3-2.9,1.1-4.4,1.7l-0.6,0.3
    c-2.3,1-4.8,2-7.5,2.9c-1.4,0.5-2.9,1-4.4,1.4c-2.8,0.8-5.6,1.5-8.4,2.1c-0.9,0.2-2.1,0.4-2.6,1.3c-0.4,0.8-0.1,1.6,0.6,2.1
    c0.4,0.3,1,0.4,1.5,0.5c1.5,0.1,3.1-0.4,4.5-0.8c0.9-0.2,1.9-0.5,2.8-0.7c0.8-0.2,1.6-0.4,2.4-0.6c2.1-0.5,4.6-1.2,7.1-2.1
    c2.3-0.8,4.6-1.7,7-2.8c1.1-0.5,2.1-0.9,3.1-1.4c0.8-0.4,1.6-0.8,1.7-1.8c0.1-0.9-0.5-1.9-1.4-2
    C404.8,329.8,404.3,329.9,403.9,329.9z"
                />
                <path
                  d="M366.7,355.7c1.7-0.1,6.1-0.3,11.2-1.2
    c0.5-0.1,1-0.4,1.3-0.8c0.3-0.4,0.4-1,0.3-1.5c-0.2-1-1-1.6-2-1.6c-0.1,0-0.3,0-0.4,0c-4.9,0.9-9,1.1-10.6,1.2c-1.1,0-2,1-2,2.1
    C364.7,354.8,365.6,355.7,366.7,355.7L366.7,355.7z"
                />
                <path
                  d="M274.7,334.9c-7.3-3.4-16.4-11.8-25.1-23.1
    c-10.1-13.1-12.2-23-14.9-35.6l-0.5-2.2c-0.1-0.5,0-1,0.3-1.5c0.3-0.4,0.7-0.7,1.2-0.9c0.1,0,0.3,0,0.4,0c0.9,0,1.7,0.6,1.9,1.6
    c0.3,1.2,0.4,2.5,1.3,3.4c0.6,0.6,1.5,0.7,2.2,0.3c1.6-0.9,1.1-2.5,0.8-4c-0.2-0.8-0.3-1.6-0.5-2.3c-0.4-2-0.8-4-1.2-6.2
    c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.1,0,0.3,0,0.4,0c0.9,0,1.7,0.6,1.9,1.5c0.4,2.1,0.8,4.2,1.2,6.3
    c1.8,9.7,3.4,18.1,11.1,29.9c9.5,14.5,15.1,19.5,22.8,23.9c0.9,0.5,1.2,1.7,0.7,2.6c-0.3,0.5-0.7,0.8-1.3,0.9c-0.1,0-0.2,0-0.4,0
    c-0.3,0-0.7-0.1-1-0.3c-0.7-0.4-1.4-0.8-2.1-1.3c-0.8-0.6-2.4-0.9-3.2-0.2c-0.7,0.6-0.9,1.9-0.5,2.6c0.5,1,1.6,1.5,2.5,2
    c0.7,0.4,1.4,0.8,2.1,1.1c0.5,0.2,0.8,0.6,1,1.1c0.2,0.5,0.2,1-0.1,1.5c-0.3,0.7-1,1.1-1.8,1.1C275.3,335.1,275,335,274.7,334.9z
     M241.6,282.4c-1.5,0.9-1,3.2-0.6,4.6c0.3,1.1,0.8,2.2,1.2,3.3c0.9,2.4,1.8,4.4,2.6,6.3c1,2.3,2,4.2,3.2,6.1
    c1.3,2.2,2.9,4.4,4.7,6.7c1.2,1.6,2.3,2.9,3.3,4c2.3,2.7,4.1,4.7,5.9,6.5c0.5,0.5,0.9,1,1.4,1.5c0.8,0.9,1.8,2,3,2.3
    c1,0.2,2.1-0.3,2.3-1.3c0.5-2.1-1.7-3.2-2.9-4.4c-0.7-0.7-1.3-1.4-1.9-2.1c-1.2-1.4-2.4-2.8-3.5-4.3c-2.6-3.4-5-7-6.8-9.7
    c-1.1-1.7-2.1-3.4-3-5c-1.6-2.9-3.1-6-4.2-9c-0.4-1.1-0.8-2.3-1.1-3.5c-0.3-1-1.2-2-2.2-2.3C242.6,282,242.1,282.1,241.6,282.4z"
                />
                <path
                  d="M265.8,340.5c0.7,0,1.3-0.3,1.6-0.8
    c0.6-0.9,0.4-2.2-0.5-2.8c-4.7-3.3-9.8-8.1-12.3-11c-0.4-0.4-0.9-0.7-1.5-0.7c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.7,0.8-0.7,1.4
    c0,0.5,0.1,1.1,0.5,1.5c2.7,3.1,8,8.1,13,11.6C265,340.3,265.4,340.5,265.8,340.5z"
                />
                <path
                  d="M234,256c1.1,0,2-0.9,2-2
    c0-1.8,0.1-3.5,0.2-5.2c0.3-3.5,0.7-6.7,1.2-9.9c0.2-0.9,0.3-1.9,0.4-2.8l0.1-0.5c0.1-0.6,0.2-1.2,0.3-1.7
    c0.1-0.4,0.1-0.9,0.2-1.3c0.2-1,0.6-2,1.6-2.6c1-0.6,2.2-0.4,2.8,0.6c0.7,1,0.5,2.6,0.3,3.8c-0.4,2.6-0.8,5.1-1.1,7.7
    c-0.3,2.1-0.5,4.3-0.6,6.4c0,0.5,0.1,1,0.5,1.4c0.3,0.4,0.8,0.6,1.3,0.6c0.3,0,0.4,0,0.5,0c0.9-0.2,1.5-0.9,1.5-1.8
    c0.5-8.5,2.8-21.7,4.3-27.6c0.1-0.5,0-1-0.2-1.5c-0.3-0.4-0.7-0.8-1.2-0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.9,0-1.7,0.6-1.9,1.5
    c-0.3,1.2-0.4,2.5-1,3.7c-0.5,1-1.5,2-2.7,1.9c-1.9-0.2-2-2.4-1.7-3.8c0.1-0.6,0.2-1.2,0.3-1.8c0.5-2.7,1-5.4,1.1-8.2
    c0-1.1-0.8-2-1.9-2c-1.1,0-2,0.8-2,1.9c-0.1,3-1,8-2.1,13.7C234,234.3,232,245,232,254C232,255.1,232.9,256,234,256z"
                />
                <path
                  d="M226.5,237.6c1,0,1.9-0.8,2-1.8
    c0.3-2.3,1.2-8.2,2.5-14.5c0.1-0.5,0-1.1-0.3-1.5c-0.3-0.5-0.8-0.8-1.3-0.9c-0.1,0-0.3,0-0.4,0c-1,0-1.8,0.7-2,1.6
    c-1.3,6.4-2.3,12.5-2.6,14.8c-0.1,1.1,0.6,2.1,1.8,2.3C226.3,237.6,226.4,237.6,226.5,237.6z"
                />
              </g>
            </g>
            <g title="Golgi apparatus" className="golgi_apparatus_ton">
              <title>Golgi apparatus</title>
              <path
                d="M213.1,238.5c0.3,0.1,0.6,0.1,1,0.1
  c1.6-0.1,3.2-1.7,3.9-3c0.9-1.8,0.4-4.2,0.2-6.1c-0.4-2.7-0.3-5.5-0.1-8.3c0.1-1.5,0.3-3.1,0.4-4.6c0.3-3.1,0.3-6.3,0.9-9.4
  c0.7-3.9,1.8-7.7,3.1-11.5c2.7-7.8,7.2-15,11.3-22.1c3.2-5.7,6.8-11.1,11.6-15.5c1.8-1.6,3.8-3,5.9-4.2c1.8-1,4.2-1.8,5.6-3.4
  c1.9-2,2.5-7.1-1.3-7c-3.6,0-6.7,3-9.1,5.3c-2.7,2.5-4.8,5.6-6.9,8.6c-1.1,1.7-2.2,3.3-3.4,5c-1.6,2.3-3.1,4.8-4.6,7.2
  c-1.8,2.9-3.4,5.8-5.1,8.8c-0.9,1.7-1.9,3.3-2.8,5c-3.1,5.4-5.2,11.4-6.7,17.4c-1.5,6.1-2.4,12.2-3.2,18.4c-0.5,3.7-1,7.4-1.9,11
  C211.3,232.3,210,237.8,213.1,238.5z"
              />
              <path
                d="M203.4,224.9c0.7,1.2,2.2,1.2,3.2,0.4
  c1.3-1.1,1.7-3,1.7-4.6c0-1.9-0.3-3.8-0.3-5.6c0-3,0.6-5.9,1.4-8.8c2.7-9.6,7.9-18.3,12.8-26.9c1.5-2.6,3-5.2,4.6-7.8
  c1.5-2.5,2.9-5.1,4.3-7.6c2.4-4.4,4.8-8.8,7.9-12.9c2.2-2.9,4.8-5.2,7.4-7.7c1.1-1,2-2.3,1.9-3.9c-0.2-1.6-1.7-2.4-3.2-2.3
  c-2.2,0.2-4,1.7-5.1,3.5c-1.7,2.8-3.5,5.6-5.1,8.4c-1.6,2.7-3.2,5.5-4.7,8.3c-0.8,1.5-1.7,3.1-2.5,4.6c-1.3,2.5-2.7,5.1-4.2,7.5
  c-4.5,7.5-10.3,17.6-15.2,28.1c-2.3,5.1-3.2,10.6-4.4,16c-0.3,1.6-0.7,3.3-0.9,4.9C202.8,220.7,202.3,222.9,203.4,224.9
  C203.4,224.8,203.4,224.9,203.4,224.9z"
              />
              <path
                d="M199.3,212.7c0.6,0,1.3-0.2,1.8-0.8
  c1.2-1.4,1.5-3.4,1.9-5.1c0.8-4.1,2-8,3.4-11.9c2.5-6.7,5.9-13.8,10.8-22.1c2.5-4.3,5.3-8.7,8.1-13c1.8-2.7,3.4-5.6,5-8.3
  c1.9-3.3,4.4-6.5,4.7-10.5c0.1-1.3-0.2-3-1.8-3.2c-3-0.4-4.2,3.1-4.9,5.3c-0.4,1.2-0.6,2.5-1,3.8c-0.6,2-1.5,3.9-2.4,5.8
  c-1.2,2.4-2.6,4.8-4.1,7.1c-2.9,4.6-5.9,9.3-8.6,14.1c-1.3,2.2-2.5,4.5-3.6,6.8c-1.9,3.9-4.1,7.9-5.4,12.1
  c-0.8,2.7-2.1,5.2-3.3,7.7c-1.1,2.3-2.4,4.6-3,7c-0.3,1.3-0.2,2.8,0.5,4C197.8,212.2,198.5,212.7,199.3,212.7z"
              />
              <path
                d="M195.2,194.8c3.4,0,5.1-4.7,6-7.3
  c1.7-4.7,4-9.1,6.6-13.4c2.6-4.4,5.4-8.8,8-13.2c1.4-2.3,3-4.5,4.3-6.9c0.9-1.7,1.9-4.1,1.2-6c-0.8-2.4-3.2-1.4-4.3,0.2
  c-1.5,2.2-2.1,5-3.1,7.4c-1.2,2.9-2.6,5.8-4.3,8.5c-2.7,4.4-5.4,8.7-8.2,13c-1.7,2.7-3.5,5.2-5.4,7.8c-1.5,2.1-3.5,4.6-3.6,7.3
  c0,1.2,0.8,2.3,2,2.5C194.7,194.7,195,194.8,195.2,194.8z"
              />
              <path
                d="M198.4,173.1c3.9-1.8,5.5-6.2,7.6-9.7
  c0.7-1.2,1.5-2.4,2.3-3.6c0.8-1.3,1.6-2.6,2.3-4c0.8-1.7,2.8-6.7-0.7-7.1c-2.1-0.3-3.1,2.4-3.6,4c-0.7,2.4-1.1,4.8-2.2,7
  c-0.9,1.7-1.9,3.4-3.2,4.7c-1.5,1.5-3.3,2.1-5.1,3.3c-0.8,0.5-1.5,1.1-2,1.9c-0.8,1.4-0.4,3,1.1,3.7
  C196.1,173.7,197.3,173.6,198.4,173.1z"
              />
              <path
                d="M232.7,183.3c-2.5,5.3-4.9,10.6-6.5,16.3
  c-0.6,2.3-1.1,4.7-0.6,7.1c0.4,1.6,1.9,3.6,3.6,2.4c1.6-1.1,1.4-2.9,1.4-4.6c0-3.3,0.8-6.6,1.8-9.8c1.4-4.6,3-9.2,5.5-13.3
  c1.7-2.8,3.7-5.4,6-7.6c2.1-2,4.7-3.5,7.2-4.9c1.5-0.9,3.6-2.3,2.8-4.3c-0.6-1.6-2.4-1.9-3.9-1.7c-2.3,0.4-4.3,1.7-5.9,3.2
  c-1.7,1.7-3.1,3.2-4.5,5.2c-1.1,1.6-2.2,3.2-3.1,4.8C235.1,178.4,233.9,180.9,232.7,183.3z"
              />
            </g>
            <g
              title="Golgi apparatus"
              className={locations.includes('Golgi apparatus') ? 'golgi_apparatus' : 'golgi_apparatus--disabled'}
            >
              <title>Golgi apparatus</title>
              <path
                d="M213.1,238.5c0.3,0.1,0.6,0.1,1,0.1
  c1.6-0.1,3.2-1.7,3.9-3c0.9-1.8,0.4-4.2,0.2-6.1c-0.4-2.7-0.3-5.5-0.1-8.3c0.1-1.5,0.3-3.1,0.4-4.6c0.3-3.1,0.3-6.3,0.9-9.4
  c0.7-3.9,1.8-7.7,3.1-11.5c2.7-7.8,7.2-15,11.3-22.1c3.2-5.7,6.8-11.1,11.6-15.5c1.8-1.6,3.8-3,5.9-4.2c1.8-1,4.2-1.8,5.6-3.4
  c1.9-2,2.5-7.1-1.3-7c-3.6,0-6.7,3-9.1,5.3c-2.7,2.5-4.8,5.6-6.9,8.6c-1.1,1.7-2.2,3.3-3.4,5c-1.6,2.3-3.1,4.8-4.6,7.2
  c-1.8,2.9-3.4,5.8-5.1,8.8c-0.9,1.7-1.9,3.3-2.8,5c-3.1,5.4-5.2,11.4-6.7,17.4c-1.5,6.1-2.4,12.2-3.2,18.4c-0.5,3.7-1,7.4-1.9,11
  C211.3,232.3,210,237.8,213.1,238.5z"
              />
              <path
                d="M203.4,224.9c0.7,1.2,2.2,1.2,3.2,0.4
  c1.3-1.1,1.7-3,1.7-4.6c0-1.9-0.3-3.8-0.3-5.6c0-3,0.6-5.9,1.4-8.8c2.7-9.6,7.9-18.3,12.8-26.9c1.5-2.6,3-5.2,4.6-7.8
  c1.5-2.5,2.9-5.1,4.3-7.6c2.4-4.4,4.8-8.8,7.9-12.9c2.2-2.9,4.8-5.2,7.4-7.7c1.1-1,2-2.3,1.9-3.9c-0.2-1.6-1.7-2.4-3.2-2.3
  c-2.2,0.2-4,1.7-5.1,3.5c-1.7,2.8-3.5,5.6-5.1,8.4c-1.6,2.7-3.2,5.5-4.7,8.3c-0.8,1.5-1.7,3.1-2.5,4.6c-1.3,2.5-2.7,5.1-4.2,7.5
  c-4.5,7.5-10.3,17.6-15.2,28.1c-2.3,5.1-3.2,10.6-4.4,16c-0.3,1.6-0.7,3.3-0.9,4.9C202.8,220.7,202.3,222.9,203.4,224.9
  C203.4,224.8,203.4,224.9,203.4,224.9z"
              />
              <path
                d="M199.3,212.7c0.6,0,1.3-0.2,1.8-0.8
  c1.2-1.4,1.5-3.4,1.9-5.1c0.8-4.1,2-8,3.4-11.9c2.5-6.7,5.9-13.8,10.8-22.1c2.5-4.3,5.3-8.7,8.1-13c1.8-2.7,3.4-5.6,5-8.3
  c1.9-3.3,4.4-6.5,4.7-10.5c0.1-1.3-0.2-3-1.8-3.2c-3-0.4-4.2,3.1-4.9,5.3c-0.4,1.2-0.6,2.5-1,3.8c-0.6,2-1.5,3.9-2.4,5.8
  c-1.2,2.4-2.6,4.8-4.1,7.1c-2.9,4.6-5.9,9.3-8.6,14.1c-1.3,2.2-2.5,4.5-3.6,6.8c-1.9,3.9-4.1,7.9-5.4,12.1
  c-0.8,2.7-2.1,5.2-3.3,7.7c-1.1,2.3-2.4,4.6-3,7c-0.3,1.3-0.2,2.8,0.5,4C197.8,212.2,198.5,212.7,199.3,212.7z"
              />
              <path
                d="M195.2,194.8c3.4,0,5.1-4.7,6-7.3
  c1.7-4.7,4-9.1,6.6-13.4c2.6-4.4,5.4-8.8,8-13.2c1.4-2.3,3-4.5,4.3-6.9c0.9-1.7,1.9-4.1,1.2-6c-0.8-2.4-3.2-1.4-4.3,0.2
  c-1.5,2.2-2.1,5-3.1,7.4c-1.2,2.9-2.6,5.8-4.3,8.5c-2.7,4.4-5.4,8.7-8.2,13c-1.7,2.7-3.5,5.2-5.4,7.8c-1.5,2.1-3.5,4.6-3.6,7.3
  c0,1.2,0.8,2.3,2,2.5C194.7,194.7,195,194.8,195.2,194.8z"
              />
              <path
                d="M198.4,173.1c3.9-1.8,5.5-6.2,7.6-9.7
  c0.7-1.2,1.5-2.4,2.3-3.6c0.8-1.3,1.6-2.6,2.3-4c0.8-1.7,2.8-6.7-0.7-7.1c-2.1-0.3-3.1,2.4-3.6,4c-0.7,2.4-1.1,4.8-2.2,7
  c-0.9,1.7-1.9,3.4-3.2,4.7c-1.5,1.5-3.3,2.1-5.1,3.3c-0.8,0.5-1.5,1.1-2,1.9c-0.8,1.4-0.4,3,1.1,3.7
  C196.1,173.7,197.3,173.6,198.4,173.1z"
              />
              <path
                d="M232.7,183.3c-2.5,5.3-4.9,10.6-6.5,16.3
  c-0.6,2.3-1.1,4.7-0.6,7.1c0.4,1.6,1.9,3.6,3.6,2.4c1.6-1.1,1.4-2.9,1.4-4.6c0-3.3,0.8-6.6,1.8-9.8c1.4-4.6,3-9.2,5.5-13.3
  c1.7-2.8,3.7-5.4,6-7.6c2.1-2,4.7-3.5,7.2-4.9c1.5-0.9,3.6-2.3,2.8-4.3c-0.6-1.6-2.4-1.9-3.9-1.7c-2.3,0.4-4.3,1.7-5.9,3.2
  c-1.7,1.7-3.1,3.2-4.5,5.2c-1.1,1.6-2.2,3.2-3.1,4.8C235.1,178.4,233.9,180.9,232.7,183.3z"
              />
            </g>
            <g title="Nucleoplasm" className="nucleoplasm_ton">
              <title>Nucleoplasm</title>
              <path
                d="M409.3,174.2c20.1,21.7,35.6,44.5,27.5,92.6
  c-8,48.1-45.2,57.7-60.9,60.6c-15.6,2.8-71.8,11.1-96.7-14.1c-25-25.2-32.7-45.4-22.9-88c9.8-42.6,42.6-64.8,71-71.2
  C355.7,147.7,389,152.3,409.3,174.2z"
              />
            </g>
            <g
              title="Nucleoplasm"
              className={locations.includes('Nucleoplasm') ? 'nucleoplasm' : 'nucleoplasm--disabled'}
            >
              <title>Nucleoplasm</title>
              <path
                d="M409.3,174.2c20.1,21.7,35.6,44.5,27.5,92.6
  c-8,48.1-45.2,57.7-60.9,60.6c-15.6,2.8-71.8,11.1-96.7-14.1c-25-25.2-32.7-45.4-22.9-88c9.8-42.6,42.6-64.8,71-71.2
  C355.7,147.7,389,152.3,409.3,174.2z"
              />
            </g>
            <g title="Nuclear membrane" className="nuclear_membrane_ton">
              <title>Nuclear membrane</title>
              <path
                d="M410.6,175c0.5,0,1-0.2,1.4-0.6
  c0.9-0.8,0.9-2.1,0.1-3c-14.4-15.5-36.2-24-61.3-24c-7,0-14.1,0.7-21.1,2c-1.1,0.2-1.9,1.3-1.7,2.5c0.2,1.1,1.3,1.9,2.5,1.7
  c6.7-1.3,13.5-1.9,20.3-1.9c24,0,44.7,8.1,58.2,22.7C409.5,174.8,410.1,175,410.6,175z"
              />
              <path
                d="M438.6,269.2c1,0,1.9-0.7,2.1-1.8
  c7.7-46.2-6-70-22.1-88.7c-0.8-0.9-2.1-1-3-0.2c-0.9,0.8-1,2.1-0.2,3c15.4,18,28.6,40.8,21.1,85.3c-0.2,1.1,0.6,2.2,1.7,2.4
  C438.4,269.2,438.5,269.2,438.6,269.2z"
              />
              <path
                d="M385.7,329.1c0.2,0,0.4,0,0.6-0.1
  c26.4-7.4,44.3-24.7,51.8-50.1c0.3-1.1-0.3-2.3-1.4-2.6c-1.1-0.3-2.3,0.3-2.6,1.4c-7.2,24.3-23.7,40.2-49,47.3
  c-1.1,0.3-1.8,1.5-1.5,2.6C383.9,328.5,384.7,329.1,385.7,329.1z"
              />
              <path
                d="M337.6,334.9c16.6,0,31.6-2.3,39.1-3.6
  c1.1-0.2,1.9-1.3,1.7-2.4c-0.2-1.1-1.3-1.9-2.4-1.7c-7.3,1.3-22.1,3.6-38.3,3.6c-27,0-46.6-5.9-58.1-17.7c-0.8-0.8-2.1-0.8-3,0
  c-0.8,0.8-0.8,2.1,0,3C288.8,328.5,309.4,334.9,337.6,334.9z"
              />
              <path
                d="M270.5,308.6c0.5,0,1-0.2,1.4-0.5
  c0.9-0.8,1-2.1,0.2-3c-15.5-17.7-23.8-35.8-17.3-71.4c0.2-1.1-0.5-2.2-1.7-2.4c-1.1-0.2-2.2,0.5-2.4,1.7
  c-6.8,37.2,1.9,56.3,18.2,74.9C269.3,308.4,269.9,308.6,270.5,308.6z"
              />
              <path
                d="M254.5,226.9c1,0,1.8-0.7,2-1.6
  c11.7-50.9,53.7-66.3,66.3-69.9c1.1-0.3,1.8-1.5,1.5-2.6c-0.3-1.1-1.5-1.8-2.6-1.5c-13.2,3.7-57.1,19.8-69.3,73
  c-0.3,1.1,0.4,2.3,1.6,2.5C254.2,226.9,254.4,226.9,254.5,226.9z"
              />
            </g>
            <g
              title="Nuclear membrane"
              className={locations.includes('Nuclear membrane') ? 'nuclear_membrane' : 'nuclear_membrane--disabled'}
            >
              <title>Nuclear membrane</title>
              <path
                d="M410.6,175c0.5,0,1-0.2,1.4-0.6
  c0.9-0.8,0.9-2.1,0.1-3c-14.4-15.5-36.2-24-61.3-24c-7,0-14.1,0.7-21.1,2c-1.1,0.2-1.9,1.3-1.7,2.5c0.2,1.1,1.3,1.9,2.5,1.7
  c6.7-1.3,13.5-1.9,20.3-1.9c24,0,44.7,8.1,58.2,22.7C409.5,174.8,410.1,175,410.6,175z"
              />
              <path
                d="M438.6,269.2c1,0,1.9-0.7,2.1-1.8
  c7.7-46.2-6-70-22.1-88.7c-0.8-0.9-2.1-1-3-0.2c-0.9,0.8-1,2.1-0.2,3c15.4,18,28.6,40.8,21.1,85.3c-0.2,1.1,0.6,2.2,1.7,2.4
  C438.4,269.2,438.5,269.2,438.6,269.2z"
              />
              <path
                d="M385.7,329.1c0.2,0,0.4,0,0.6-0.1
  c26.4-7.4,44.3-24.7,51.8-50.1c0.3-1.1-0.3-2.3-1.4-2.6c-1.1-0.3-2.3,0.3-2.6,1.4c-7.2,24.3-23.7,40.2-49,47.3
  c-1.1,0.3-1.8,1.5-1.5,2.6C383.9,328.5,384.7,329.1,385.7,329.1z"
              />
              <path
                d="M337.6,334.9c16.6,0,31.6-2.3,39.1-3.6
  c1.1-0.2,1.9-1.3,1.7-2.4c-0.2-1.1-1.3-1.9-2.4-1.7c-7.3,1.3-22.1,3.6-38.3,3.6c-27,0-46.6-5.9-58.1-17.7c-0.8-0.8-2.1-0.8-3,0
  c-0.8,0.8-0.8,2.1,0,3C288.8,328.5,309.4,334.9,337.6,334.9z"
              />
              <path
                d="M270.5,308.6c0.5,0,1-0.2,1.4-0.5
  c0.9-0.8,1-2.1,0.2-3c-15.5-17.7-23.8-35.8-17.3-71.4c0.2-1.1-0.5-2.2-1.7-2.4c-1.1-0.2-2.2,0.5-2.4,1.7
  c-6.8,37.2,1.9,56.3,18.2,74.9C269.3,308.4,269.9,308.6,270.5,308.6z"
              />
              <path
                d="M254.5,226.9c1,0,1.8-0.7,2-1.6
  c11.7-50.9,53.7-66.3,66.3-69.9c1.1-0.3,1.8-1.5,1.5-2.6c-0.3-1.1-1.5-1.8-2.6-1.5c-13.2,3.7-57.1,19.8-69.3,73
  c-0.3,1.1,0.4,2.3,1.6,2.5C254.2,226.9,254.4,226.9,254.5,226.9z"
              />
            </g>
            <g title="Nuclear bodies" className="nuclear_bodies_ton">
              <title>Nuclear bodies</title>
              <path
                d="M335.8,253.4c-1.4-1.2-1.7-4.3,0.5-4.8
  c1.6-0.3,3.7,0.6,4.3,2.1C341.6,253.6,337.9,255.3,335.8,253.4z"
              />
              <path
                d="M293.5,283.9c-1.5-2.4,2.6-4.8,4.2-2.4
  C299.6,284.4,295.3,286.8,293.5,283.9z"
              />
              <path
                d="M328.9,189.8c0.5-1.8,2.2-2.3,3.7-1.4
  c1.9,1.1,2,3.7-0.1,4.7C330.2,194.2,328.2,192.2,328.9,189.8z"
              />
              <path
                d="M354.5,217.2c-1.6-1.2-1.5-3.2,0.1-4.4
  c1.9-1.5,4.6-0.5,4.7,1.9C359.5,217.6,356.7,218.8,354.5,217.2z"
              />
              <path
                d="M415.1,251c-0.1,0.6-0.6,1.2-1.7,1.6
  c-1.9,0.6-4-1.1-3.1-3.2C411.5,246.3,415.5,248.9,415.1,251z"
              />
              <path
                d="M323.2,318.5c-0.1,0.4-0.4,0.7-1.1,1
  c-1.2,0.4-2.4-0.7-1.9-2C320.9,315.6,323.4,317.2,323.2,318.5z"
              />
              <path
                d="M361.7,185c-0.1,0.4-0.4,0.7-1.1,1
  c-1.2,0.4-2.4-0.7-1.9-2C359.4,182.1,361.9,183.7,361.7,185z"
              />
              <path
                d="M401.9,213c-3.8,0.2-4.5-7.7,0.7-5.9
  C405.7,208.2,405.6,212.8,401.9,213z"
              />
              <path
                d="M393.2,176.5c-2.5,1.6-6.1-3.9-1.9-4.5
  C393.8,171.6,395.6,175,393.2,176.5z"
              />
              <path
                d="M367.4,167.5c-2.4,0-5.2-4.1-1.3-5.4
  c2-0.7,4.1,1.9,3.4,3.7C369,167,368.2,167.5,367.4,167.5z"
              />
              <path
                d="M332.1,293c-0.7-1.4-0.3-3.2,2.1-3.9
  c3.2-0.9,8.2,3.4,3.6,5.6C335.2,295.9,332.9,294.6,332.1,293z"
              />
              <path
                d="M386.1,310.3c-1.3-0.2-2.4-1.3-1.9-3.2
  c0.7-2.5,5.9-3.7,5.6,0.3C389.5,309.6,387.6,310.5,386.1,310.3z"
              />
              <path
                d="M261.3,270.6c-0.4-0.5-0.5-1.1-0.5-1.8
  c-0.1-3.3,4-4.6,4.8-1.1C266.3,270.4,263.7,273.8,261.3,270.6z"
              />
              <path
                d="M293.6,215c-0.3-0.5-0.6-1.1-0.6-1.8
  c-0.2-4.5,5.5-1.5,4.9,1.2C297.2,217.1,294.7,216.7,293.6,215z"
              />
            </g>
            <g
              title="Nuclear bodies"
              className={locations.includes('Nuclear bodies') ? 'nuclear_bodies' : 'nuclear_bodies--disabled'}
            >
              <title>Nuclear bodies</title>
              <path
                d="M335.8,253.4c-1.4-1.2-1.7-4.3,0.5-4.8
  c1.6-0.3,3.7,0.6,4.3,2.1C341.6,253.6,337.9,255.3,335.8,253.4z"
              />
              <path
                d="M293.5,283.9c-1.5-2.4,2.6-4.8,4.2-2.4
  C299.6,284.4,295.3,286.8,293.5,283.9z"
              />
              <path
                d="M328.9,189.8c0.5-1.8,2.2-2.3,3.7-1.4
  c1.9,1.1,2,3.7-0.1,4.7C330.2,194.2,328.2,192.2,328.9,189.8z"
              />
              <path
                d="M354.5,217.2c-1.6-1.2-1.5-3.2,0.1-4.4
  c1.9-1.5,4.6-0.5,4.7,1.9C359.5,217.6,356.7,218.8,354.5,217.2z"
              />
              <path
                d="M415.1,251c-0.1,0.6-0.6,1.2-1.7,1.6
  c-1.9,0.6-4-1.1-3.1-3.2C411.5,246.3,415.5,248.9,415.1,251z"
              />
              <path
                d="M323.2,318.5c-0.1,0.4-0.4,0.7-1.1,1
  c-1.2,0.4-2.4-0.7-1.9-2C320.9,315.6,323.4,317.2,323.2,318.5z"
              />
              <path
                d="M361.7,185c-0.1,0.4-0.4,0.7-1.1,1
  c-1.2,0.4-2.4-0.7-1.9-2C359.4,182.1,361.9,183.7,361.7,185z"
              />
              <path
                d="M401.9,213c-3.8,0.2-4.5-7.7,0.7-5.9
  C405.7,208.2,405.6,212.8,401.9,213z"
              />
              <path
                d="M393.2,176.5c-2.5,1.6-6.1-3.9-1.9-4.5
  C393.8,171.6,395.6,175,393.2,176.5z"
              />
              <path
                d="M367.4,167.5c-2.4,0-5.2-4.1-1.3-5.4
  c2-0.7,4.1,1.9,3.4,3.7C369,167,368.2,167.5,367.4,167.5z"
              />
              <path
                d="M332.1,293c-0.7-1.4-0.3-3.2,2.1-3.9
  c3.2-0.9,8.2,3.4,3.6,5.6C335.2,295.9,332.9,294.6,332.1,293z"
              />
              <path
                d="M386.1,310.3c-1.3-0.2-2.4-1.3-1.9-3.2
  c0.7-2.5,5.9-3.7,5.6,0.3C389.5,309.6,387.6,310.5,386.1,310.3z"
              />
              <path
                d="M261.3,270.6c-0.4-0.5-0.5-1.1-0.5-1.8
  c-0.1-3.3,4-4.6,4.8-1.1C266.3,270.4,263.7,273.8,261.3,270.6z"
              />
              <path
                d="M293.6,215c-0.3-0.5-0.6-1.1-0.6-1.8
  c-0.2-4.5,5.5-1.5,4.9,1.2C297.2,217.1,294.7,216.7,293.6,215z"
              />
            </g>
            <g title="Nuclear speckles" className="nuclear_speckles_ton">
              <title>Nuclear speckles</title>
              <path
                d="M405.3,185.5c0,0.1,0.1,0.2,0.1,0.3
  c0.5,1.5,0.2,3.9,1.3,5.1c1.3,1.4,5.2,0.5,4.6,3.5c-0.7,3.1-5.3,1.8-6.7,4.2c-1,1.6,0.3,5.2-1.7,6c-1.1,0.5-2.1,0-3.2-0.1
  c-1.3-0.1-2.6,0.3-3.9,0.3c-2.9-0.1-1-2-1.9-3.8c-0.8-1.4-4.5-2.8-2.8-4.9c1.3-1.6,5.6-1.7,4.8-4.8c-0.4-1.9-2.8-3.8-1.2-5.9
  c1.6-2.1,4.2-0.5,6.5-1C402.7,183.9,404.6,184,405.3,185.5z"
              />
              <path
                d="M418.9,198.3c0.4-0.1,0.8-0.2,1.3-0.1
  c1.1,0.3,3.5,0.9,4.4,1.6c1.5,1.2,0.4,2.8,0.3,4.3c-0.1,1,0.2,1.8,0.5,2.7c0.3,1,0.1,1.4-0.7,2.1c-0.8,0.7-2.2,0.8-2.8,1.4
  c-0.7,0.8,0.3,2,0.5,2.7c0.2,1.1,0.1,1.4-0.5,2.3c-0.9,1.6-2.2,2.9-4.2,2.9c-2.2,0-5.6-0.5-7.7-1.2c-1.5-0.5-1.2-2.1-1.4-3.3
  c-0.2-1.5-1.2-3-1.2-4.6c0-1.7,1.9-2.4,2.3-3.9c0.8-3.1-3.9-4.7-2.8-7.5c0.6-1.4,2-1,3.2-1.2c1.6-0.2,3.7-1.9,4.8,0
  c0.5,0.8,0.5,1.6,1.4,2C417.2,199.2,418,198.6,418.9,198.3z"
              />
              <path
                d="M371,212.2c-0.4,0.2-0.8,0.4-1.2,0.4
  c-0.6,0.1-1.3,0-1.9,0.2c-0.8,0.4-1.1,1.4-2.1,1.6c-1.5,0.3-2.4-1.2-3.4-2.1c-1.2-1.2-3.1-0.6-4.5-1.1c-1.6-0.6-0.8-1.8-1.7-2.9
  c-0.9-1-2.8-0.2-3.6-1.3c-0.7-0.9,0.1-2.5-0.1-3.6c-0.2-1.3-0.9-2.5-1-3.8c-0.2-1.8,0.8-1.8,1.9-2.9c1.5-1.6,0.8-5.3,3.8-4.9
  c1.9,0.3,4.2,2.8,4.8-0.2c0.4-1.9,1.6-5.3,4.1-3c1.1,1,1.7,0.9,3.2,0.7c1.6-0.2,1.7,0.1,1.6,1.6c-0.1,1.5,0.3,3.2,2.1,3.2
  c2,0,3.2-1,5.1,0.4c2.4,1.8,0.9,4.5,1.3,6.9c0.3,1.9,1.2,3.1-0.8,4.3c-2.1,1.3-1.9,1.6-1.2,3.8c0.5,1.6,0.1,3.4-1.9,3
  c-1.3-0.2-2.5-0.9-3.9-0.7C371.3,212.1,371.1,212.2,371,212.2z"
              />
              <path
                d="M433.4,239.6c-0.1,0.1-0.2,0.3-0.3,0.4
  c-0.8,0.9-2.4,1-3.3,1.8c-1.4,1.3-2.6,5.1-5.1,2.8c-0.9-0.8-2-6.1-4.2-3.7c-1.1,1.3,0.1,2.8-1.5,4c-1,0.8-2.2,0.7-3.5,0.9
  c-1.9,0.3-8.8,1.2-7.8-3c0.5-2.1,5.3-2.8,2.1-5.6c-1.3-1.2-3.5-1.6-4.6-3.1c-0.9-1.3-1-4.7,0.1-6c1.7-1.9,4.3-0.8,6.4-1.4
  c2-0.6,1.4-2.3,2.7-3.3c2.1-1.6,4.1,0.9,4.9,2.6c0.6,1.4,1.6,3.2,3.4,2.8c1.6-0.3,2.8-2.2,4.5-2.4c2.8-0.3,1.8,3.7,2.7,5.4
  C431.2,234.1,435.9,236,433.4,239.6z"
              />
              <path
                d="M292.5,267.7c0,0.2,0.1,0.3,0.1,0.5
  c0.1,1.5-1.4,2.9-0.6,4.5c0.6,1.1,1.9,1.6,2.7,2.6c1.1,1.5,0.4,2.6-1.2,3c-1.2,0.3-2.9,1-4,1c-1.4,0-2.2-1.5-3.6-1.6
  c-1.6,0-2.3,1.9-1.9,3.2c0.4,1.4,2,2,2.8,3.2c1.6,2.7-0.3,5.7-3.1,6.3c-1.7,0.4-3.2-0.4-4.8-0.1c-1.5,0.2-2.5,1.2-4,0.1
  c-2.1-1.5-0.6-3.8-0.6-5.7c0-2.6-3-1.5-4.6-1.7c-1.4-0.2-2.6-1-2.6-2.4c0-1,1.1-2.3,0.7-3.3c-0.4-1.1-1.9-1.4-1.9-2.7
  c0-1,0.8-1.8,1.4-2.4c0.2-0.2,0.9-0.6,1-0.9c0.2-0.5-0.3-0.9-0.2-1.4c0.3-2.8,3.2-2.1,5-1.7c4.2,0.9,2.4-3.2,3.9-5.3
  c0.6-0.8,1.8-1.2,2.6-1.8c0.8-0.7,1.4-1.9,2.6-1.3c1.1,0.5,1.2,2.1,1.8,3c0.6,0.9,1.9,1.5,2.9,1.7
  C289.1,265.3,291.9,264.4,292.5,267.7z"
              />
              <path
                d="M302.8,308.5c-0.1,0.1-0.2,0.3-0.3,0.4
  c-0.9,1.1-2.7,1.2-3.5,2.5c-0.8,1.4-0.8,3.3-2.9,3.3c-2.7,0.1-4.4-3.1-7.3-1.7c-1.4,0.7-2.8,1.6-4.4,0.7c-1.1-0.6-1.1-1.7-1.8-2.5
  c-1.3-1.6-4-1-3-3.7c0.6-1.7,2.8-2.3,1.2-4.1c-1.5-1.6-3.4-2.8-1.9-5.1c2.3-3.5,6.5-7,9.1-1.8c0.3,0.7-0.1,1.6,0.6,2.3
  c0.6,0.5,1.3,0.5,2,0.3c1.6-0.5,2.6-2.1,4.1-2.8c4-1.8,2.9,2.7,4.2,4.8C300.1,303.3,305,304.7,302.8,308.5z"
              />
              <path
                d="M349.1,214.5c-0.1,0.1-0.2,0.2-0.3,0.3
  c-1.3,1.5-0.9,4.5-2.9,5.5c-2.7,1.3-5.5-2-8.2-1.8c-2,0.2-4.2,1.4-3.7-1.9c0.5-3.8-7.2,0.2-7.5-4.1c-0.1-1.8,0.8-3.5,2.6-3.6
  c4-0.3,0.9-4.3,1.2-7.1c0.4-3,3.2-2.8,5.5-2.5c1.1,0.1,2,0.2,3.1-0.1c0.8-0.2,1.9-1.1,2.7-0.9c2.1,0.4,0.5,3.5,2,4.5
  c1.9,1.3,3.5-2.5,4.7-3.2c2.7-1.6,3.8,2.2,3.8,4c0,0.7,0,1.5-0.3,2.2c-0.3,0.5-0.9,0.8-1.1,1.4c-0.8,1.6,0.5,2.6,0.6,4
  C351.5,212.9,350,213.6,349.1,214.5z"
              />
              <path
                d="M354,272.7c-0.2,0.3-0.4,0.5-0.7,0.9
  c-1.6,1.9-1,4.6-2.1,6.6c-1.3,2.4-4.9,2.5-5.1-0.6c-0.1-1.4,0.6-2.7-1-3.5c-1.2-0.6-3.1,0-3.8,1.2c-1,1.7,0.7,5.6-2.8,5.2
  c-1.5-0.2-5.6-3.1-6.8-4.1c-2-1.6-1.4-4.9,0-7c1.1-1.7,3.1-2.8,2.8-5.1c-0.3-2.1-1.2-4,0.5-5.9c1.5-1.7,3.2-0.4,4.7-1.3
  c1.4-0.9,0.7-3.2,2-4.2c1.3-1,3.5,0.3,5,0.1c2.2-0.2,5.2-1,6.4,1.4c0.7,1.6,1.2,1.9,2.9,2.2c1.4,0.2,2.3,0.2,2.7,1.8
  c0.2,0.9,0.7,2.6,0.3,3.5c-1.1,2.4-5.9,1.5-5.2,4.9C354.2,270.9,354.8,271.5,354,272.7z"
              />
              <path
                d="M373.1,323.1c-0.1,0-0.1,0.1-0.2,0.1
  c-1.1,0.7-2,0.9-2.8,2.1c-1.2,1.8-2.2,2.2-4.3,1.5c-2.2-0.7-2.1-1.7-3.1-3.4c-1.1-1.8-3.2-1.1-4.9-1.3c-2.1-0.3-2-2-2.6-3.5
  c-0.6-1.3-1.9-2.9-0.5-4.3c0.8-0.8,2.1-0.5,3-1c1.4-0.9,0.7-2.1,0.5-3.4c-0.3-2.4,2.1-3,3.9-3c2.1,0,3.8,1.8,5.8,1.8
  c3.5,0,2.1-3.3,3.9-5c1.1-1,3.4-1.8,4.8-1.3c1.8,0.6,1.6,2.4,1.4,3.9c-0.2,2.1,0.3,4.6,2.9,4.3c1.4-0.1,3-1.2,3.1,0.9
  c0,1-0.4,2-0.3,3c0.1,0.8,0.5,1.8,0.4,2.6c-0.3,2.3-2.5,0.6-3.9,0.9c-1.8,0.4-1.8,0.8-1.9,2.5c-0.1,1.5-0.9,1.9-2.3,1.7
  C374.6,322.1,374.1,322.5,373.1,323.1z"
              />
              <path
                d="M400.1,310c-1.1,0.5-2.1,1.1-2.9,1.4
  c-0.9,0.3-2.2,0.7-3,0.1c-1.1-0.9-0.5-2.6-0.8-3.8c-0.4-1.9-3.3-3.1-3.3-4.9c0-2.8,4.3-3,5.1-4.9c0.5-1.3-1.9-2.9-2-4.1
  c-0.1-1.4,1.9-1.8,2.9-2.2c2-0.6,3.4-1.5,4.5,0.5c1.3,2.3,4.1,1,4.8,3.8c0.3,1.2,0.8,1.9,2.3,1.7c1.4-0.2,2.2-0.8,3.1,0.6
  c0.8,1.2,1.6,4.1,0.7,5.5c-1.8,2.9-6.2,0-6.4,3.9c-0.1,1.5-0.9,1.9-2.3,1.7C402.1,309.1,401.1,309.5,400.1,310z"
              />
              <path
                d="M327.1,189.2c0,0.2,0.1,0.3,0.1,0.5
  c0.8,3.6-2.4,2.7-3.5,5.5c-1.1,2.7-0.6,4.5-4.2,3.3c-1.7-0.5-1.6-0.9-3.5,0c-3.9,1.8-6.6-1.7-8.1-4.8c-0.7-1.4-3.1-2-3.3-3.6
  c-0.2-2,2.1-3,2-5c-0.1-1.2-0.7-2.3,0-3.5c0.7-1.1,2.2-0.9,2.8-1.9c1.3-2.2-1.7-4.1,1.5-5.4c3.6-1.6,3,1.6,4.7,3.1
  c3.9,3.4,6.2-3.2,9.2-2.4c1,0.3,2.9,3.5,3.5,4.3c1.2,1.7,5.2,4.2,1.1,6C326.6,186.6,326.5,186.5,327.1,189.2z"
              />
              <path
                d="M302.2,204.1c-0.7,0.9-1.1,1.9-1.5,3
  c-1,2.5-4.5,1.1-6.7,1.5c-1.7,0.3-3.4,1-4.3,2.5c-0.5,0.9-0.4,1.4-1.2,2c-0.8,0.5-1.8,0.6-2.6,0.4c-1.6-0.4-2.5-1.6-3.8-2.3
  c-0.9-0.5-2.1-0.9-2.5-2c-0.5-1.3,0.6-2.1,0.8-3.3c0.1-1.3-0.7-2.1-0.9-3.3c-0.1-1.1,0.4-1.8-0.1-2.9c-1.2-2.3-4,0-4.9-2.1
  c-0.8-1.9,1.2-6.1,3.1-6.7c1.5-0.5,3.5,1.3,5-0.1c1.3-1.3-0.6-3.9,1-5.3c0.8-0.7,4-1.3,4.9-0.8c1.6,0.8,0.1,3.3,0.7,4.4
  c0.8,1.8,2.5,1,3.5-0.3c0.9-1.1,0.6-3.7,2.7-3.6c0.9,0,1.2,0.9,2.1,1.1c0.9,0.1,2.6-0.5,3.4-0.8c1.6-0.5,4.4-1,4.6,1.4
  c0.1,1.5-1.3,2.7-0.7,4.2c0.5,1.2,2.5,2.1,2.3,3.5c-0.2,2.1-2.8,2-3.7,3.3c-0.6,0.9,0.3,1.6,0.6,2.4c0.6,1.6,0.3,2.1-0.9,3.1
  C302.6,203.6,302.4,203.8,302.2,204.1z"
              />
              <path
                d="M348.5,246.9c-0.1,0.1-0.2,0.2-0.2,0.3
  c-1.4,1.7-3,2.6-4.5,0.2c-1.5-2.4-2.5-0.7-4.7-0.7c-2.6,0-4.3-0.6-5.7-3c-0.8-1.4-2.9-6.8-0.3-7.9c1.8-0.8,9.2,1.4,7.2-3.4
  c-0.5-1.2-1.9-1.5-2.7-2.3c-0.7-0.7-2.2-2.8-1.6-3.9c0.8-1.5,2.8-1.3,4.1-1.9c1.7-0.8,0.6-3,2.1-3.7c0.8-0.3,3,0.2,3.7,0.6
  c1.7,0.9,2.7,4.9,5,3.9c1.2-0.5,1.6-1.9,2.7-2.5c3.9-2,4.7,2.4,6.1,4.5c0.7,1.1,2.3,2.1,2.3,3.6c0,2.3-2.4,3.6-2.7,5.7
  c-0.3,1.7,3.5,4.6,1.1,5.9c-0.7,0.4-1.9-0.1-2.7,0.4c-0.6,0.4-0.7,1-0.9,1.6c-0.5,1.5-0.7,2.9-2.7,2.5
  C352,246.6,350.2,245.2,348.5,246.9z"
              />
              <path
                d="M365.6,260.5c-0.3-0.1-0.5-0.2-0.8-0.1
  c-2.2,0.1-3.5,2-5.4-0.2c-0.6-0.8-0.5-0.9-1.5-1.2c-0.8-0.2-1.5-0.4-2.3-0.5c-2.3-0.6-3-2.7-3.5-4.8c-1-4.1,2.8-4.3,4.3-7.4
  c0.6-1.2,0.2-2.8,2.1-2.8c2.2,0,3.7,0.8,5.7-0.5c1.2-0.8,2.3-2.1,3.8-0.9c0.7,0.6,0.8,1.7,1.5,2.4c0.7,0.7,1.6,0.8,2.5,1.2
  c1.9,0.9,2.2,2.2,1.8,4c-0.5,2.4,0.3,4.1,0.9,6.3c0.5,1.6-0.1,3.1-2,2.9c-1.3-0.1-2.5-1.5-3.7-0.6c-1,0.7-1,2.3-2.4,2.4
  C366.3,260.7,366,260.6,365.6,260.5z"
              />
              <path
                d="M384.2,183.9c-0.1,0-0.3,0-0.4,0
  c-0.8,0.1-2.4,0.2-2.9,1c-0.5,0.8,0.4,1.6,0,2.4c-1.1,1.9-3.4-0.2-4.1-1.2c-1.3-1.8-1.9-3.2-4-4.2c-1.3-0.6-3.6-2-2.7-3.7
  c0.7-1.3,2.5-1.6,3.4-2.7c1-1.3-1.8-3.2-2.2-4.3c-0.7-1.8,0.1-3.1,2-3.7c1-0.3,2.5,0,3.2-0.9c0.7-0.8,0.1-2.2,0.5-3.1
  c1.6-3,6.6,1.1,8.6,1.1c1,0,1.7-0.8,2.7-0.8c4,0.2,1.4,3.8,3,5.7c1.5,1.8,4.4,2.5,5.4,4.8c0.6,1.2,0.2,2.5,0.6,3.8
  c0.3,1.3,1,2,0.7,3.5c-0.4,2.1-2.1,2-3.3,3.5c-0.9,1.1-0.4,2.5-1.5,3.4c-1.8,1.4-3-0.8-4.1-1.9
  C387.6,185.3,386.2,183.9,384.2,183.9z"
              />
              <path
                d="M413.2,293.5c-0.1,0.1-0.3,0.2-0.4,0.3
  c-2.9,2.3-3.5-1-6.5-0.8c-2.9,0.2-4.3,1.4-4.8-2.3c-0.3-1.8,0.1-1.8-1.5-3.1c-1.5-1.3-1.9-1.2-1.8-3.2c0.2-1.9-0.4-5.9,1.5-6.9
  c1.9-1.1,4,1.7,5.7,0.2c2-1.7,2.4-6.2,5.7-5.9c1.3,0.1,1.8,1.6,2.9,1.7c2.5,0.2,2.9-3.3,5.5-1c3,2.6-0.1,3.3-0.7,5.5
  c-1.4,5,5.6,4.2,6.2,7.3c0.2,1-1.9,4.1-2.3,5c-1,1.8-1.5,6.5-4.9,3.6C415.3,292,415.3,291.8,413.2,293.5z"
              />
              <path
                d="M399.7,273.8c-0.1,0.1-0.3,0.2-0.4,0.3
  c-2.9,2.3-3.5-1-6.5-0.8c-2.9,0.2-4.3,1.4-4.8-2.3c-0.3-1.8,0.1-1.8-1.5-3.1c-1.5-1.3-1.9-1.2-1.8-3.2c0.2-1.9-0.4-5.9,1.5-6.9
  c1.9-1.1,4,1.7,5.7,0.2c2-1.7,2.4-6.2,5.7-5.9c1.3,0.1,1.8,1.6,2.9,1.7c2.5,0.2,2.9-3.3,5.5-1c3,2.6-0.1,3.3-0.7,5.5
  c-1.4,5,5.6,4.2,6.2,7.3c0.2,1-1.9,4.1-2.3,5c-1,1.8-1.5,6.5-4.9,3.6C401.9,272.2,401.9,272.1,399.7,273.8z"
              />
              <path
                d="M290.6,221.6c0.1,0.1,0.3,0.2,0.4,0.3
  c3.1,1.9,0.3,3.6,1.5,6.4c1.2,2.6,2.8,3.5-0.5,5.3c-1.6,0.9-1.7,0.5-2.4,2.5c-0.6,1.9-0.4,2.2-2.4,2.8c-1.5,0.4-2.9,1.2-4.5,1.4
  c-1.9,0.3-2.6-0.7-4-1.6c-0.9-0.6-1.6-1.5-2.5-2.1c-2-1.2-4.7,0.7-5.7-2c-1-2.7,0.7-3.3,2.9-3.5c1.2-0.1,2.7-1.3,3-2.5
  c0.5-1.9-1.4-2.4-2.6-3.3c-1.1-0.8-1.6-1.9-0.6-3c1-1.1,2.4-0.6,3.2-1.9c0.7-1.1,0.7-2.6,0.9-3.9c0.3-2.9,3.3-5.7,6.2-3.9
  c0.8,0.5,1.3,1.1,2.2,1.2c0.7,0.1,1.5-0.1,2.2,0c1.6,0.4,1.8,1.9,1.3,3.3C288.4,220.1,288.2,220.2,290.6,221.6z"
              />
              <path
                d="M320.3,215.2c2.2,1.4,2.9,2.1,1.8,4.5
  c-0.7,1.7,0.2,3.9-0.9,5.4c-1.2,1.7-2.9,1-4.6,1.2c-1.8,0.3-2.3,1.6-3.2,3.1c-0.5,1-1,2.1-2.3,1.9c-2-0.4-2.9-2.5-4.7-3.3
  c-1.4-0.7-3.6-1.1-4-2.9c-0.2-1.3,0.5-2.4-0.1-3.7c-0.6-1.3-2.2-2-2.3-3.6c-0.1-1,0.5-2.4,1.5-2.9c0.9-0.4,1.8,0,2.4-1
  c0.8-1.2,0.4-5.3,2.5-5.3c1.4,0,2.2,1.8,3.4,2.1c1.7,0.4,2.3-1.6,3.3-2.5c2.1-1.9,7.7-0.4,6.6,3.1c-0.5,1.6-2.3,1.9-0.4,3.5
  C319.7,214.8,320,215,320.3,215.2z"
              />
              <path
                d="M351,305.4c1.2,0.5,4,0.9,4.1,2.6
  c0.1,2.2-5.2,3.2-5.9,5.2c-1.7,4.5,9.2,5.4,6.2,10.1c-0.9,1.4-2.2,0.8-3.5,1.1c-1.3,0.3-1.7,1.6-2.8,2.2c-1.3,0.7-2.3-0.3-3.5-0.7
  c-2.4-0.8-4.2,2.3-6.4,0.5c-1.9-1.6,0.2-4.9-2-6.2c-2.6-1.5-7.5-0.1-8.5-3.7c-0.4-1.5,1-2.6,0.7-3.8c-0.4-1.5-1.8-1.6-2.7-2.6
  c-0.7-0.8-0.7-2.4-0.4-3.4c0.2-0.8,0.8-1.5,1.7-1.7c1.8-0.4,3.8,1.7,5.6,0.3c1.6-1.2,1.3-4.4,1.8-6.2c0.8-2.7,2.7-1.3,4.7-1.3
  c1.9-0.1,2.2-2.6,3.9-3.3c2.7-1.2,5.6,1.3,5.5,4C349.2,301.2,347.7,303.9,351,305.4z"
              />
              <path
                d="M310.6,307.9c-0.8-0.9-2.2-0.4-3.2-1
  c-1.7-0.9-2.3-2.7-1.7-4.5c0.5-1.5,3.9-3.6,1.9-5.5c-1.1-1-2.1-0.5-2.5-2.5c-0.3-1.4-0.1-2.6,1.2-3.3c1.6-0.8,2.6-1.1,3.6-2.6
  c0.9-1.4,2.3-3.3,4.1-1.9c1,0.7,1.9,1.8,3.1,2.1c1.2,0.2,1-0.4,1.9-1c1.5-1,5.9-0.3,7,1c2.2,2.6-2.3,5.3,0.9,7.8
  c0.9,0.7,1.8,0.7,2.3,2c0.5,1.2,0.1,3.1,0.1,4.3c0.1,1.4-0.7,1.7-2.1,1.9c-1.3,0.2-2.2,0.2-2.1,1.7c0,0.9,1,2.7-0.1,3.4
  c-1,0.7-2.6-0.7-3.6-0.9c-1.5-0.3-1.9,0.5-2.4,1.6c-1.3,2.8-4.8,1.5-6.9,0.3C311.2,310.1,311.4,308.7,310.6,307.9z"
              />
              <path
                d="M277.9,261.8c-0.3,0.2-0.6,0.4-1,0.7
  c-1.2,0.9-2.5,1.9-3.5,0.2c-0.6-1-0.5-3.2-1.9-3.7c-0.7-0.2-1.6,0.9-2.1,1.3c-2.6,2.2-4.7,0.7-5.6-2.1c-0.9-2.7-1.6-4.4-4.8-4.6
  c-3.4-0.2-4.3-2.9-3.3-6c0.7-2.3-1.9-6.5,1.5-7.5c1.6-0.5,3.4,0.2,5-0.2c1.7-0.4,2.4-1.6,3.2-3c1.2-2.2,2.1-1.4,4.3-0.8
  c1.4,0.4,2.6,0.3,4,0.3c1.9,0,2.2,0.6,2.9,2.2c0.6,1.4,2.4,3,2.1,4.6c-0.4,2.1-2.6,2.8-2.6,5.2c0,1-0.3,2.2,0.3,3.1
  c0.6,0.9,1.7,1,2.5,1.7c1.5,1.3,1.6,4.4,1.3,6.2C280,260.5,279,261.1,277.9,261.8z"
              />
              <path
                d="M429,270.6c-1.2-0.5-1.9-2.2-3.2-1
  c-0.7,0.6-0.5,2.2-1,3c-2.1,3.9-4.6-1.3-6.9-1.7c-1.5-0.3-2.6,0-2.8-1.8c-0.2-1.6,0.7-3.2,0-4.7c-0.7-1.5-2.2-2.6-3.1-4
  c-1.6-2.8-0.6-4.9,2.5-5.8c1.4-0.4,2.2-1,2.7-2.3c0.6-1.3,0.5-2.7,2.2-3c0.8-0.1,1.6,0.3,2.4,0.1c0.6-0.2,0.8-0.8,1.3-1
  c1.7-0.7,4.5-0.2,5.1,1.7c0.6,1.6-0.8,3.9-0.2,5.3c1,2.3,3.7,0,5.3,0.1c2,0.2,2.6,2.8,2,4.4c-0.8,2.2-3.1,2.8-3,5.5
  C432.5,267,432.4,272.2,429,270.6z"
              />
              <path
                d="M357.9,163.4c0.9,0.8,2.2,1.7,2.3,2.9
  c0.1,1.4-1.5,2.2-1.1,3.7c0.3,1.1,2.5,2.3,2.1,3.4c-0.2,0.5-1.4,0.9-1.9,1.2c-0.8,0.6-1.5,1.2-1.9,2.2c-0.8,1.9,0.2,4-0.7,5.8
  c-0.4,0.8-0.9,1.5-1.3,2.4c-0.4,0.9-0.6,1.6-1.7,1.8c-1.9,0.4-5-0.7-5.7-2.7c-0.4-1.3,0.5-3.2-1.4-3.5c-1.5-0.2-2.5,1.6-3.8,1.2
  c-1.4-0.4-2.3-2.5-3.3-3.4c-0.7-0.6-1.8-1-2.4-1.7c-1-1.2-0.9-2.7-2.4-3.4c-1.5-0.7-4-1.3-4.6-3.1c-0.5-1.5,0-3.3,1.6-3.8
  c1.9-0.5,3.1-0.9,4.6-2.2c1.5-1.3,3.1-2.6,5.2-1.8c2.1,0.9,3.6,1.5,6,1.1c2.3-0.4,4.3-2,6.8-1.8c1.4,0.1,2.5,0.4,3.6,1.4
  C357.7,163.2,357.8,163.3,357.9,163.4z"
              />
              <path
                d="M319.6,174.2c1.5,0.5,3.4,1.9,4.4,0.1
  c0.5-0.9,0.4-1.8,1.6-2c1-0.2,3.2,1,3.8-0.3c0.3-0.5-0.3-1.4-0.4-1.9c-0.1-0.9,0.2-1.5,0.6-2.2c0.9-1.4,0.2-1.9-0.6-3.1
  c-0.7-1.1,0.1-2.6-0.6-3.6c-0.7-1-3-1-4.1-1.3c-1.3-0.3-3-0.7-4.1,0.5c-0.9,0.9-0.8,2-2.2,2.3c-4.5,0.7-1.1,3.5-0.8,6.2
  c0.1,1.5-1.2,1.7-1,3.2C316.6,174.1,318.1,173.6,319.6,174.2z"
              />
              <path
                d="M285.3,241.8c-1,0.1-2,1.1-2.4,2.1
  c-0.6,1.4,0.2,1.8,1,2.7c1.8,2.1-1.8,2.5-1.9,4.6c0,1.8,1.8,2.6,2.8,3.8c0.9,1.1,1.1,2.1,2.8,1.7c3.8-0.7,1.9-4.2,3.7-6.5
  c0.7-0.9,2.3-0.7,3-1.7c0.6-1-0.3-2-0.7-2.9c-0.4-1-0.6-3-1.6-3.7c-0.9-0.6-2,0.1-2.9,0.4c-1.2,0.4-1.8,0.2-3-0.4
  C285.8,241.8,285.5,241.8,285.3,241.8z"
              />
              <path
                d="M340.6,185.8c-1.1,0-2,0-2.5,1.3
  c-0.8,2.2,1.1,3.5,2.7,4.4c1,0.6,0.9,1,0.8,2.1c-0.2,1.9,0.8,2,2.3,1.8c1.1-0.2,2.8,0.3,3.7-0.6c0.9-0.9-0.1-2.4,1.1-3.3
  c1.1-0.9,2.7-2.2,0.8-3.4c-0.8-0.5-1.6-0.5-2.1-1.5c-0.3-0.6-0.1-1.7-0.7-2.1c-1.3-0.8-3.4,1.3-4.7,1.4
  C341.5,185.9,341.1,185.9,340.6,185.8z"
              />
              <path
                d="M303.8,277.9c-0.1,0-0.1,0.1-0.2,0.1
  c-1,1-1,2.6,0.5,2.8c1.3,0.2,2-0.8,2.4,0.9c0.3,1.7,0.2,5.7,2.9,4.9c1.5-0.4,2.1-1.9,3.5-2.4c0.9-0.4,2.3-0.3,3.3-0.4
  c2.7-0.1,1.6-2,1.5-3.7c-0.2-1.7,0.8-3.9-1.9-3.7c-2,0.2-2.7,0-4.1-1.4c-1.8-1.8-3,0.5-4.5,1.5
  C306.1,277.3,304.7,277.2,303.8,277.9z"
              />
            </g>
            <g
              title="Nuclear speckles"
              className={locations.includes('Nuclear speckles') ? 'nuclear_speckles' : 'nuclear_speckles--disabled'}
            >
              <title>Nuclear speckles</title>
              <path
                d="M405.3,185.5c0,0.1,0.1,0.2,0.1,0.3
  c0.5,1.5,0.2,3.9,1.3,5.1c1.3,1.4,5.2,0.5,4.6,3.5c-0.7,3.1-5.3,1.8-6.7,4.2c-1,1.6,0.3,5.2-1.7,6c-1.1,0.5-2.1,0-3.2-0.1
  c-1.3-0.1-2.6,0.3-3.9,0.3c-2.9-0.1-1-2-1.9-3.8c-0.8-1.4-4.5-2.8-2.8-4.9c1.3-1.6,5.6-1.7,4.8-4.8c-0.4-1.9-2.8-3.8-1.2-5.9
  c1.6-2.1,4.2-0.5,6.5-1C402.7,183.9,404.6,184,405.3,185.5z"
              />
              <path
                d="M418.9,198.3c0.4-0.1,0.8-0.2,1.3-0.1
  c1.1,0.3,3.5,0.9,4.4,1.6c1.5,1.2,0.4,2.8,0.3,4.3c-0.1,1,0.2,1.8,0.5,2.7c0.3,1,0.1,1.4-0.7,2.1c-0.8,0.7-2.2,0.8-2.8,1.4
  c-0.7,0.8,0.3,2,0.5,2.7c0.2,1.1,0.1,1.4-0.5,2.3c-0.9,1.6-2.2,2.9-4.2,2.9c-2.2,0-5.6-0.5-7.7-1.2c-1.5-0.5-1.2-2.1-1.4-3.3
  c-0.2-1.5-1.2-3-1.2-4.6c0-1.7,1.9-2.4,2.3-3.9c0.8-3.1-3.9-4.7-2.8-7.5c0.6-1.4,2-1,3.2-1.2c1.6-0.2,3.7-1.9,4.8,0
  c0.5,0.8,0.5,1.6,1.4,2C417.2,199.2,418,198.6,418.9,198.3z"
              />
              <path
                d="M371,212.2c-0.4,0.2-0.8,0.4-1.2,0.4
  c-0.6,0.1-1.3,0-1.9,0.2c-0.8,0.4-1.1,1.4-2.1,1.6c-1.5,0.3-2.4-1.2-3.4-2.1c-1.2-1.2-3.1-0.6-4.5-1.1c-1.6-0.6-0.8-1.8-1.7-2.9
  c-0.9-1-2.8-0.2-3.6-1.3c-0.7-0.9,0.1-2.5-0.1-3.6c-0.2-1.3-0.9-2.5-1-3.8c-0.2-1.8,0.8-1.8,1.9-2.9c1.5-1.6,0.8-5.3,3.8-4.9
  c1.9,0.3,4.2,2.8,4.8-0.2c0.4-1.9,1.6-5.3,4.1-3c1.1,1,1.7,0.9,3.2,0.7c1.6-0.2,1.7,0.1,1.6,1.6c-0.1,1.5,0.3,3.2,2.1,3.2
  c2,0,3.2-1,5.1,0.4c2.4,1.8,0.9,4.5,1.3,6.9c0.3,1.9,1.2,3.1-0.8,4.3c-2.1,1.3-1.9,1.6-1.2,3.8c0.5,1.6,0.1,3.4-1.9,3
  c-1.3-0.2-2.5-0.9-3.9-0.7C371.3,212.1,371.1,212.2,371,212.2z"
              />
              <path
                d="M433.4,239.6c-0.1,0.1-0.2,0.3-0.3,0.4
  c-0.8,0.9-2.4,1-3.3,1.8c-1.4,1.3-2.6,5.1-5.1,2.8c-0.9-0.8-2-6.1-4.2-3.7c-1.1,1.3,0.1,2.8-1.5,4c-1,0.8-2.2,0.7-3.5,0.9
  c-1.9,0.3-8.8,1.2-7.8-3c0.5-2.1,5.3-2.8,2.1-5.6c-1.3-1.2-3.5-1.6-4.6-3.1c-0.9-1.3-1-4.7,0.1-6c1.7-1.9,4.3-0.8,6.4-1.4
  c2-0.6,1.4-2.3,2.7-3.3c2.1-1.6,4.1,0.9,4.9,2.6c0.6,1.4,1.6,3.2,3.4,2.8c1.6-0.3,2.8-2.2,4.5-2.4c2.8-0.3,1.8,3.7,2.7,5.4
  C431.2,234.1,435.9,236,433.4,239.6z"
              />
              <path
                d="M292.5,267.7c0,0.2,0.1,0.3,0.1,0.5
  c0.1,1.5-1.4,2.9-0.6,4.5c0.6,1.1,1.9,1.6,2.7,2.6c1.1,1.5,0.4,2.6-1.2,3c-1.2,0.3-2.9,1-4,1c-1.4,0-2.2-1.5-3.6-1.6
  c-1.6,0-2.3,1.9-1.9,3.2c0.4,1.4,2,2,2.8,3.2c1.6,2.7-0.3,5.7-3.1,6.3c-1.7,0.4-3.2-0.4-4.8-0.1c-1.5,0.2-2.5,1.2-4,0.1
  c-2.1-1.5-0.6-3.8-0.6-5.7c0-2.6-3-1.5-4.6-1.7c-1.4-0.2-2.6-1-2.6-2.4c0-1,1.1-2.3,0.7-3.3c-0.4-1.1-1.9-1.4-1.9-2.7
  c0-1,0.8-1.8,1.4-2.4c0.2-0.2,0.9-0.6,1-0.9c0.2-0.5-0.3-0.9-0.2-1.4c0.3-2.8,3.2-2.1,5-1.7c4.2,0.9,2.4-3.2,3.9-5.3
  c0.6-0.8,1.8-1.2,2.6-1.8c0.8-0.7,1.4-1.9,2.6-1.3c1.1,0.5,1.2,2.1,1.8,3c0.6,0.9,1.9,1.5,2.9,1.7
  C289.1,265.3,291.9,264.4,292.5,267.7z"
              />
              <path
                d="M302.8,308.5c-0.1,0.1-0.2,0.3-0.3,0.4
  c-0.9,1.1-2.7,1.2-3.5,2.5c-0.8,1.4-0.8,3.3-2.9,3.3c-2.7,0.1-4.4-3.1-7.3-1.7c-1.4,0.7-2.8,1.6-4.4,0.7c-1.1-0.6-1.1-1.7-1.8-2.5
  c-1.3-1.6-4-1-3-3.7c0.6-1.7,2.8-2.3,1.2-4.1c-1.5-1.6-3.4-2.8-1.9-5.1c2.3-3.5,6.5-7,9.1-1.8c0.3,0.7-0.1,1.6,0.6,2.3
  c0.6,0.5,1.3,0.5,2,0.3c1.6-0.5,2.6-2.1,4.1-2.8c4-1.8,2.9,2.7,4.2,4.8C300.1,303.3,305,304.7,302.8,308.5z"
              />
              <path
                d="M349.1,214.5c-0.1,0.1-0.2,0.2-0.3,0.3
  c-1.3,1.5-0.9,4.5-2.9,5.5c-2.7,1.3-5.5-2-8.2-1.8c-2,0.2-4.2,1.4-3.7-1.9c0.5-3.8-7.2,0.2-7.5-4.1c-0.1-1.8,0.8-3.5,2.6-3.6
  c4-0.3,0.9-4.3,1.2-7.1c0.4-3,3.2-2.8,5.5-2.5c1.1,0.1,2,0.2,3.1-0.1c0.8-0.2,1.9-1.1,2.7-0.9c2.1,0.4,0.5,3.5,2,4.5
  c1.9,1.3,3.5-2.5,4.7-3.2c2.7-1.6,3.8,2.2,3.8,4c0,0.7,0,1.5-0.3,2.2c-0.3,0.5-0.9,0.8-1.1,1.4c-0.8,1.6,0.5,2.6,0.6,4
  C351.5,212.9,350,213.6,349.1,214.5z"
              />
              <path
                d="M354,272.7c-0.2,0.3-0.4,0.5-0.7,0.9
  c-1.6,1.9-1,4.6-2.1,6.6c-1.3,2.4-4.9,2.5-5.1-0.6c-0.1-1.4,0.6-2.7-1-3.5c-1.2-0.6-3.1,0-3.8,1.2c-1,1.7,0.7,5.6-2.8,5.2
  c-1.5-0.2-5.6-3.1-6.8-4.1c-2-1.6-1.4-4.9,0-7c1.1-1.7,3.1-2.8,2.8-5.1c-0.3-2.1-1.2-4,0.5-5.9c1.5-1.7,3.2-0.4,4.7-1.3
  c1.4-0.9,0.7-3.2,2-4.2c1.3-1,3.5,0.3,5,0.1c2.2-0.2,5.2-1,6.4,1.4c0.7,1.6,1.2,1.9,2.9,2.2c1.4,0.2,2.3,0.2,2.7,1.8
  c0.2,0.9,0.7,2.6,0.3,3.5c-1.1,2.4-5.9,1.5-5.2,4.9C354.2,270.9,354.8,271.5,354,272.7z"
              />
              <path
                d="M373.1,323.1c-0.1,0-0.1,0.1-0.2,0.1
  c-1.1,0.7-2,0.9-2.8,2.1c-1.2,1.8-2.2,2.2-4.3,1.5c-2.2-0.7-2.1-1.7-3.1-3.4c-1.1-1.8-3.2-1.1-4.9-1.3c-2.1-0.3-2-2-2.6-3.5
  c-0.6-1.3-1.9-2.9-0.5-4.3c0.8-0.8,2.1-0.5,3-1c1.4-0.9,0.7-2.1,0.5-3.4c-0.3-2.4,2.1-3,3.9-3c2.1,0,3.8,1.8,5.8,1.8
  c3.5,0,2.1-3.3,3.9-5c1.1-1,3.4-1.8,4.8-1.3c1.8,0.6,1.6,2.4,1.4,3.9c-0.2,2.1,0.3,4.6,2.9,4.3c1.4-0.1,3-1.2,3.1,0.9
  c0,1-0.4,2-0.3,3c0.1,0.8,0.5,1.8,0.4,2.6c-0.3,2.3-2.5,0.6-3.9,0.9c-1.8,0.4-1.8,0.8-1.9,2.5c-0.1,1.5-0.9,1.9-2.3,1.7
  C374.6,322.1,374.1,322.5,373.1,323.1z"
              />
              <path
                d="M400.1,310c-1.1,0.5-2.1,1.1-2.9,1.4
  c-0.9,0.3-2.2,0.7-3,0.1c-1.1-0.9-0.5-2.6-0.8-3.8c-0.4-1.9-3.3-3.1-3.3-4.9c0-2.8,4.3-3,5.1-4.9c0.5-1.3-1.9-2.9-2-4.1
  c-0.1-1.4,1.9-1.8,2.9-2.2c2-0.6,3.4-1.5,4.5,0.5c1.3,2.3,4.1,1,4.8,3.8c0.3,1.2,0.8,1.9,2.3,1.7c1.4-0.2,2.2-0.8,3.1,0.6
  c0.8,1.2,1.6,4.1,0.7,5.5c-1.8,2.9-6.2,0-6.4,3.9c-0.1,1.5-0.9,1.9-2.3,1.7C402.1,309.1,401.1,309.5,400.1,310z"
              />
              <path
                d="M327.1,189.2c0,0.2,0.1,0.3,0.1,0.5
  c0.8,3.6-2.4,2.7-3.5,5.5c-1.1,2.7-0.6,4.5-4.2,3.3c-1.7-0.5-1.6-0.9-3.5,0c-3.9,1.8-6.6-1.7-8.1-4.8c-0.7-1.4-3.1-2-3.3-3.6
  c-0.2-2,2.1-3,2-5c-0.1-1.2-0.7-2.3,0-3.5c0.7-1.1,2.2-0.9,2.8-1.9c1.3-2.2-1.7-4.1,1.5-5.4c3.6-1.6,3,1.6,4.7,3.1
  c3.9,3.4,6.2-3.2,9.2-2.4c1,0.3,2.9,3.5,3.5,4.3c1.2,1.7,5.2,4.2,1.1,6C326.6,186.6,326.5,186.5,327.1,189.2z"
              />
              <path
                d="M302.2,204.1c-0.7,0.9-1.1,1.9-1.5,3
  c-1,2.5-4.5,1.1-6.7,1.5c-1.7,0.3-3.4,1-4.3,2.5c-0.5,0.9-0.4,1.4-1.2,2c-0.8,0.5-1.8,0.6-2.6,0.4c-1.6-0.4-2.5-1.6-3.8-2.3
  c-0.9-0.5-2.1-0.9-2.5-2c-0.5-1.3,0.6-2.1,0.8-3.3c0.1-1.3-0.7-2.1-0.9-3.3c-0.1-1.1,0.4-1.8-0.1-2.9c-1.2-2.3-4,0-4.9-2.1
  c-0.8-1.9,1.2-6.1,3.1-6.7c1.5-0.5,3.5,1.3,5-0.1c1.3-1.3-0.6-3.9,1-5.3c0.8-0.7,4-1.3,4.9-0.8c1.6,0.8,0.1,3.3,0.7,4.4
  c0.8,1.8,2.5,1,3.5-0.3c0.9-1.1,0.6-3.7,2.7-3.6c0.9,0,1.2,0.9,2.1,1.1c0.9,0.1,2.6-0.5,3.4-0.8c1.6-0.5,4.4-1,4.6,1.4
  c0.1,1.5-1.3,2.7-0.7,4.2c0.5,1.2,2.5,2.1,2.3,3.5c-0.2,2.1-2.8,2-3.7,3.3c-0.6,0.9,0.3,1.6,0.6,2.4c0.6,1.6,0.3,2.1-0.9,3.1
  C302.6,203.6,302.4,203.8,302.2,204.1z"
              />
              <path
                d="M348.5,246.9c-0.1,0.1-0.2,0.2-0.2,0.3
  c-1.4,1.7-3,2.6-4.5,0.2c-1.5-2.4-2.5-0.7-4.7-0.7c-2.6,0-4.3-0.6-5.7-3c-0.8-1.4-2.9-6.8-0.3-7.9c1.8-0.8,9.2,1.4,7.2-3.4
  c-0.5-1.2-1.9-1.5-2.7-2.3c-0.7-0.7-2.2-2.8-1.6-3.9c0.8-1.5,2.8-1.3,4.1-1.9c1.7-0.8,0.6-3,2.1-3.7c0.8-0.3,3,0.2,3.7,0.6
  c1.7,0.9,2.7,4.9,5,3.9c1.2-0.5,1.6-1.9,2.7-2.5c3.9-2,4.7,2.4,6.1,4.5c0.7,1.1,2.3,2.1,2.3,3.6c0,2.3-2.4,3.6-2.7,5.7
  c-0.3,1.7,3.5,4.6,1.1,5.9c-0.7,0.4-1.9-0.1-2.7,0.4c-0.6,0.4-0.7,1-0.9,1.6c-0.5,1.5-0.7,2.9-2.7,2.5
  C352,246.6,350.2,245.2,348.5,246.9z"
              />
              <path
                d="M365.6,260.5c-0.3-0.1-0.5-0.2-0.8-0.1
  c-2.2,0.1-3.5,2-5.4-0.2c-0.6-0.8-0.5-0.9-1.5-1.2c-0.8-0.2-1.5-0.4-2.3-0.5c-2.3-0.6-3-2.7-3.5-4.8c-1-4.1,2.8-4.3,4.3-7.4
  c0.6-1.2,0.2-2.8,2.1-2.8c2.2,0,3.7,0.8,5.7-0.5c1.2-0.8,2.3-2.1,3.8-0.9c0.7,0.6,0.8,1.7,1.5,2.4c0.7,0.7,1.6,0.8,2.5,1.2
  c1.9,0.9,2.2,2.2,1.8,4c-0.5,2.4,0.3,4.1,0.9,6.3c0.5,1.6-0.1,3.1-2,2.9c-1.3-0.1-2.5-1.5-3.7-0.6c-1,0.7-1,2.3-2.4,2.4
  C366.3,260.7,366,260.6,365.6,260.5z"
              />
              <path
                d="M384.2,183.9c-0.1,0-0.3,0-0.4,0
  c-0.8,0.1-2.4,0.2-2.9,1c-0.5,0.8,0.4,1.6,0,2.4c-1.1,1.9-3.4-0.2-4.1-1.2c-1.3-1.8-1.9-3.2-4-4.2c-1.3-0.6-3.6-2-2.7-3.7
  c0.7-1.3,2.5-1.6,3.4-2.7c1-1.3-1.8-3.2-2.2-4.3c-0.7-1.8,0.1-3.1,2-3.7c1-0.3,2.5,0,3.2-0.9c0.7-0.8,0.1-2.2,0.5-3.1
  c1.6-3,6.6,1.1,8.6,1.1c1,0,1.7-0.8,2.7-0.8c4,0.2,1.4,3.8,3,5.7c1.5,1.8,4.4,2.5,5.4,4.8c0.6,1.2,0.2,2.5,0.6,3.8
  c0.3,1.3,1,2,0.7,3.5c-0.4,2.1-2.1,2-3.3,3.5c-0.9,1.1-0.4,2.5-1.5,3.4c-1.8,1.4-3-0.8-4.1-1.9
  C387.6,185.3,386.2,183.9,384.2,183.9z"
              />
              <path
                d="M413.2,293.5c-0.1,0.1-0.3,0.2-0.4,0.3
  c-2.9,2.3-3.5-1-6.5-0.8c-2.9,0.2-4.3,1.4-4.8-2.3c-0.3-1.8,0.1-1.8-1.5-3.1c-1.5-1.3-1.9-1.2-1.8-3.2c0.2-1.9-0.4-5.9,1.5-6.9
  c1.9-1.1,4,1.7,5.7,0.2c2-1.7,2.4-6.2,5.7-5.9c1.3,0.1,1.8,1.6,2.9,1.7c2.5,0.2,2.9-3.3,5.5-1c3,2.6-0.1,3.3-0.7,5.5
  c-1.4,5,5.6,4.2,6.2,7.3c0.2,1-1.9,4.1-2.3,5c-1,1.8-1.5,6.5-4.9,3.6C415.3,292,415.3,291.8,413.2,293.5z"
              />
              <path
                d="M399.7,273.8c-0.1,0.1-0.3,0.2-0.4,0.3
  c-2.9,2.3-3.5-1-6.5-0.8c-2.9,0.2-4.3,1.4-4.8-2.3c-0.3-1.8,0.1-1.8-1.5-3.1c-1.5-1.3-1.9-1.2-1.8-3.2c0.2-1.9-0.4-5.9,1.5-6.9
  c1.9-1.1,4,1.7,5.7,0.2c2-1.7,2.4-6.2,5.7-5.9c1.3,0.1,1.8,1.6,2.9,1.7c2.5,0.2,2.9-3.3,5.5-1c3,2.6-0.1,3.3-0.7,5.5
  c-1.4,5,5.6,4.2,6.2,7.3c0.2,1-1.9,4.1-2.3,5c-1,1.8-1.5,6.5-4.9,3.6C401.9,272.2,401.9,272.1,399.7,273.8z"
              />
              <path
                d="M290.6,221.6c0.1,0.1,0.3,0.2,0.4,0.3
  c3.1,1.9,0.3,3.6,1.5,6.4c1.2,2.6,2.8,3.5-0.5,5.3c-1.6,0.9-1.7,0.5-2.4,2.5c-0.6,1.9-0.4,2.2-2.4,2.8c-1.5,0.4-2.9,1.2-4.5,1.4
  c-1.9,0.3-2.6-0.7-4-1.6c-0.9-0.6-1.6-1.5-2.5-2.1c-2-1.2-4.7,0.7-5.7-2c-1-2.7,0.7-3.3,2.9-3.5c1.2-0.1,2.7-1.3,3-2.5
  c0.5-1.9-1.4-2.4-2.6-3.3c-1.1-0.8-1.6-1.9-0.6-3c1-1.1,2.4-0.6,3.2-1.9c0.7-1.1,0.7-2.6,0.9-3.9c0.3-2.9,3.3-5.7,6.2-3.9
  c0.8,0.5,1.3,1.1,2.2,1.2c0.7,0.1,1.5-0.1,2.2,0c1.6,0.4,1.8,1.9,1.3,3.3C288.4,220.1,288.2,220.2,290.6,221.6z"
              />
              <path
                d="M320.3,215.2c2.2,1.4,2.9,2.1,1.8,4.5
  c-0.7,1.7,0.2,3.9-0.9,5.4c-1.2,1.7-2.9,1-4.6,1.2c-1.8,0.3-2.3,1.6-3.2,3.1c-0.5,1-1,2.1-2.3,1.9c-2-0.4-2.9-2.5-4.7-3.3
  c-1.4-0.7-3.6-1.1-4-2.9c-0.2-1.3,0.5-2.4-0.1-3.7c-0.6-1.3-2.2-2-2.3-3.6c-0.1-1,0.5-2.4,1.5-2.9c0.9-0.4,1.8,0,2.4-1
  c0.8-1.2,0.4-5.3,2.5-5.3c1.4,0,2.2,1.8,3.4,2.1c1.7,0.4,2.3-1.6,3.3-2.5c2.1-1.9,7.7-0.4,6.6,3.1c-0.5,1.6-2.3,1.9-0.4,3.5
  C319.7,214.8,320,215,320.3,215.2z"
              />
              <path
                d="M351,305.4c1.2,0.5,4,0.9,4.1,2.6
  c0.1,2.2-5.2,3.2-5.9,5.2c-1.7,4.5,9.2,5.4,6.2,10.1c-0.9,1.4-2.2,0.8-3.5,1.1c-1.3,0.3-1.7,1.6-2.8,2.2c-1.3,0.7-2.3-0.3-3.5-0.7
  c-2.4-0.8-4.2,2.3-6.4,0.5c-1.9-1.6,0.2-4.9-2-6.2c-2.6-1.5-7.5-0.1-8.5-3.7c-0.4-1.5,1-2.6,0.7-3.8c-0.4-1.5-1.8-1.6-2.7-2.6
  c-0.7-0.8-0.7-2.4-0.4-3.4c0.2-0.8,0.8-1.5,1.7-1.7c1.8-0.4,3.8,1.7,5.6,0.3c1.6-1.2,1.3-4.4,1.8-6.2c0.8-2.7,2.7-1.3,4.7-1.3
  c1.9-0.1,2.2-2.6,3.9-3.3c2.7-1.2,5.6,1.3,5.5,4C349.2,301.2,347.7,303.9,351,305.4z"
              />
              <path
                d="M310.6,307.9c-0.8-0.9-2.2-0.4-3.2-1
  c-1.7-0.9-2.3-2.7-1.7-4.5c0.5-1.5,3.9-3.6,1.9-5.5c-1.1-1-2.1-0.5-2.5-2.5c-0.3-1.4-0.1-2.6,1.2-3.3c1.6-0.8,2.6-1.1,3.6-2.6
  c0.9-1.4,2.3-3.3,4.1-1.9c1,0.7,1.9,1.8,3.1,2.1c1.2,0.2,1-0.4,1.9-1c1.5-1,5.9-0.3,7,1c2.2,2.6-2.3,5.3,0.9,7.8
  c0.9,0.7,1.8,0.7,2.3,2c0.5,1.2,0.1,3.1,0.1,4.3c0.1,1.4-0.7,1.7-2.1,1.9c-1.3,0.2-2.2,0.2-2.1,1.7c0,0.9,1,2.7-0.1,3.4
  c-1,0.7-2.6-0.7-3.6-0.9c-1.5-0.3-1.9,0.5-2.4,1.6c-1.3,2.8-4.8,1.5-6.9,0.3C311.2,310.1,311.4,308.7,310.6,307.9z"
              />
              <path
                d="M277.9,261.8c-0.3,0.2-0.6,0.4-1,0.7
  c-1.2,0.9-2.5,1.9-3.5,0.2c-0.6-1-0.5-3.2-1.9-3.7c-0.7-0.2-1.6,0.9-2.1,1.3c-2.6,2.2-4.7,0.7-5.6-2.1c-0.9-2.7-1.6-4.4-4.8-4.6
  c-3.4-0.2-4.3-2.9-3.3-6c0.7-2.3-1.9-6.5,1.5-7.5c1.6-0.5,3.4,0.2,5-0.2c1.7-0.4,2.4-1.6,3.2-3c1.2-2.2,2.1-1.4,4.3-0.8
  c1.4,0.4,2.6,0.3,4,0.3c1.9,0,2.2,0.6,2.9,2.2c0.6,1.4,2.4,3,2.1,4.6c-0.4,2.1-2.6,2.8-2.6,5.2c0,1-0.3,2.2,0.3,3.1
  c0.6,0.9,1.7,1,2.5,1.7c1.5,1.3,1.6,4.4,1.3,6.2C280,260.5,279,261.1,277.9,261.8z"
              />
              <path
                d="M429,270.6c-1.2-0.5-1.9-2.2-3.2-1
  c-0.7,0.6-0.5,2.2-1,3c-2.1,3.9-4.6-1.3-6.9-1.7c-1.5-0.3-2.6,0-2.8-1.8c-0.2-1.6,0.7-3.2,0-4.7c-0.7-1.5-2.2-2.6-3.1-4
  c-1.6-2.8-0.6-4.9,2.5-5.8c1.4-0.4,2.2-1,2.7-2.3c0.6-1.3,0.5-2.7,2.2-3c0.8-0.1,1.6,0.3,2.4,0.1c0.6-0.2,0.8-0.8,1.3-1
  c1.7-0.7,4.5-0.2,5.1,1.7c0.6,1.6-0.8,3.9-0.2,5.3c1,2.3,3.7,0,5.3,0.1c2,0.2,2.6,2.8,2,4.4c-0.8,2.2-3.1,2.8-3,5.5
  C432.5,267,432.4,272.2,429,270.6z"
              />
              <path
                d="M357.9,163.4c0.9,0.8,2.2,1.7,2.3,2.9
  c0.1,1.4-1.5,2.2-1.1,3.7c0.3,1.1,2.5,2.3,2.1,3.4c-0.2,0.5-1.4,0.9-1.9,1.2c-0.8,0.6-1.5,1.2-1.9,2.2c-0.8,1.9,0.2,4-0.7,5.8
  c-0.4,0.8-0.9,1.5-1.3,2.4c-0.4,0.9-0.6,1.6-1.7,1.8c-1.9,0.4-5-0.7-5.7-2.7c-0.4-1.3,0.5-3.2-1.4-3.5c-1.5-0.2-2.5,1.6-3.8,1.2
  c-1.4-0.4-2.3-2.5-3.3-3.4c-0.7-0.6-1.8-1-2.4-1.7c-1-1.2-0.9-2.7-2.4-3.4c-1.5-0.7-4-1.3-4.6-3.1c-0.5-1.5,0-3.3,1.6-3.8
  c1.9-0.5,3.1-0.9,4.6-2.2c1.5-1.3,3.1-2.6,5.2-1.8c2.1,0.9,3.6,1.5,6,1.1c2.3-0.4,4.3-2,6.8-1.8c1.4,0.1,2.5,0.4,3.6,1.4
  C357.7,163.2,357.8,163.3,357.9,163.4z"
              />
              <path
                d="M319.6,174.2c1.5,0.5,3.4,1.9,4.4,0.1
  c0.5-0.9,0.4-1.8,1.6-2c1-0.2,3.2,1,3.8-0.3c0.3-0.5-0.3-1.4-0.4-1.9c-0.1-0.9,0.2-1.5,0.6-2.2c0.9-1.4,0.2-1.9-0.6-3.1
  c-0.7-1.1,0.1-2.6-0.6-3.6c-0.7-1-3-1-4.1-1.3c-1.3-0.3-3-0.7-4.1,0.5c-0.9,0.9-0.8,2-2.2,2.3c-4.5,0.7-1.1,3.5-0.8,6.2
  c0.1,1.5-1.2,1.7-1,3.2C316.6,174.1,318.1,173.6,319.6,174.2z"
              />
              <path
                d="M285.3,241.8c-1,0.1-2,1.1-2.4,2.1
  c-0.6,1.4,0.2,1.8,1,2.7c1.8,2.1-1.8,2.5-1.9,4.6c0,1.8,1.8,2.6,2.8,3.8c0.9,1.1,1.1,2.1,2.8,1.7c3.8-0.7,1.9-4.2,3.7-6.5
  c0.7-0.9,2.3-0.7,3-1.7c0.6-1-0.3-2-0.7-2.9c-0.4-1-0.6-3-1.6-3.7c-0.9-0.6-2,0.1-2.9,0.4c-1.2,0.4-1.8,0.2-3-0.4
  C285.8,241.8,285.5,241.8,285.3,241.8z"
              />
              <path
                d="M340.6,185.8c-1.1,0-2,0-2.5,1.3
  c-0.8,2.2,1.1,3.5,2.7,4.4c1,0.6,0.9,1,0.8,2.1c-0.2,1.9,0.8,2,2.3,1.8c1.1-0.2,2.8,0.3,3.7-0.6c0.9-0.9-0.1-2.4,1.1-3.3
  c1.1-0.9,2.7-2.2,0.8-3.4c-0.8-0.5-1.6-0.5-2.1-1.5c-0.3-0.6-0.1-1.7-0.7-2.1c-1.3-0.8-3.4,1.3-4.7,1.4
  C341.5,185.9,341.1,185.9,340.6,185.8z"
              />
              <path
                d="M303.8,277.9c-0.1,0-0.1,0.1-0.2,0.1
  c-1,1-1,2.6,0.5,2.8c1.3,0.2,2-0.8,2.4,0.9c0.3,1.7,0.2,5.7,2.9,4.9c1.5-0.4,2.1-1.9,3.5-2.4c0.9-0.4,2.3-0.3,3.3-0.4
  c2.7-0.1,1.6-2,1.5-3.7c-0.2-1.7,0.8-3.9-1.9-3.7c-2,0.2-2.7,0-4.1-1.4c-1.8-1.8-3,0.5-4.5,1.5
  C306.1,277.3,304.7,277.2,303.8,277.9z"
              />
            </g>
            <g title="Nucleoli" className="nucleoli_ton">
              <title>Nucleoli</title>
              <path
                d="M333.7,254.1c-0.6-2.4-0.6-4.9-1.4-7.3
  c-2.4-7.1-10.2-9.2-17-9.3c-2.1,0-4.4,0.2-6.4,0.7c-4.1,1.1-7.9,2.3-10.4,5.9c-2.7,3.9-5,8.3-4.6,13.2c0.1,1.4,0.5,2.7,1.1,4
  c1,2.1,1.9,4.1,3.5,5.8c2.5,2.7,7,5,10.7,5.4c5.1,0.5,10.8,0.9,15.7-1.1c3.6-1.5,8.2-5.2,9.2-9.2
  C334.6,259.6,334.3,256.7,333.7,254.1C333.7,254.2,333.7,254.1,333.7,254.1z"
              />
              <path
                d="M397.6,220.8c-1.9-2.2-3.8-3.3-6.3-4.8
  c-2.1-1.2-3.5-2.4-5.8-2.8c-2.5-0.5-5-0.8-7.5-0.4c-5,0.9-10.3,2.6-13.1,7.2c-2.6,4.2-2.3,10.5,0.2,14.6c2.3,3.8,6.4,5.7,10.1,7.9
  c3.4,2.1,6.3,5.6,10.4,6.2c3.3,0.4,7.8-0.9,10.4-2.9c1.8-1.4,3.1-3.3,4-5.3c0.8-1.8,1.2-3.8,1.7-5.7c0.6-2.5,0.6-4.8-0.1-7.3
  C400.6,225.1,399.3,222.8,397.6,220.8z"
              />
              <path
                d="M390.1,278.4c0-0.1-0.1-0.2-0.1-0.4
  c-1.1-3.5-4.8-7.9-7.8-10.1c-2.6-1.8-5.8-2.4-8.9-2.5c-3.3-0.1-5.5,1.4-8.5,2.6c-2.4,0.9-4.7,2.2-6.8,3.7c-3.7,2.7-6.3,6.7-7,11.2
  c-0.4,2.1-0.3,4.3,0,6.5c0.4,2.5,0.9,5,2.5,7.1c1.6,2,3.9,3.9,6.4,4.8c3.7,1.3,9.6,1.5,13.3,0.1c3.1-1.2,5.8-3.2,8.6-5.1
  c2.2-1.4,4.6-2.9,6.3-5c1.3-1.6,2-4.3,2.4-6.2C390.9,283.1,390.7,280.6,390.1,278.4z"
              />
            </g>
            <g
              title="Nucleoli"
              className={locations.includes('Nucleoli') ? 'nucleoli' : 'nucleoli--disabled'}
            >
              <title>Nucleoli</title>
              <path
                d="M333.7,254.1c-0.6-2.4-0.6-4.9-1.4-7.3
  c-2.4-7.1-10.2-9.2-17-9.3c-2.1,0-4.4,0.2-6.4,0.7c-4.1,1.1-7.9,2.3-10.4,5.9c-2.7,3.9-5,8.3-4.6,13.2c0.1,1.4,0.5,2.7,1.1,4
  c1,2.1,1.9,4.1,3.5,5.8c2.5,2.7,7,5,10.7,5.4c5.1,0.5,10.8,0.9,15.7-1.1c3.6-1.5,8.2-5.2,9.2-9.2
  C334.6,259.6,334.3,256.7,333.7,254.1C333.7,254.2,333.7,254.1,333.7,254.1z"
              />
              <path
                d="M397.6,220.8c-1.9-2.2-3.8-3.3-6.3-4.8
  c-2.1-1.2-3.5-2.4-5.8-2.8c-2.5-0.5-5-0.8-7.5-0.4c-5,0.9-10.3,2.6-13.1,7.2c-2.6,4.2-2.3,10.5,0.2,14.6c2.3,3.8,6.4,5.7,10.1,7.9
  c3.4,2.1,6.3,5.6,10.4,6.2c3.3,0.4,7.8-0.9,10.4-2.9c1.8-1.4,3.1-3.3,4-5.3c0.8-1.8,1.2-3.8,1.7-5.7c0.6-2.5,0.6-4.8-0.1-7.3
  C400.6,225.1,399.3,222.8,397.6,220.8z"
              />
              <path
                d="M390.1,278.4c0-0.1-0.1-0.2-0.1-0.4
  c-1.1-3.5-4.8-7.9-7.8-10.1c-2.6-1.8-5.8-2.4-8.9-2.5c-3.3-0.1-5.5,1.4-8.5,2.6c-2.4,0.9-4.7,2.2-6.8,3.7c-3.7,2.7-6.3,6.7-7,11.2
  c-0.4,2.1-0.3,4.3,0,6.5c0.4,2.5,0.9,5,2.5,7.1c1.6,2,3.9,3.9,6.4,4.8c3.7,1.3,9.6,1.5,13.3,0.1c3.1-1.2,5.8-3.2,8.6-5.1
  c2.2-1.4,4.6-2.9,6.3-5c1.3-1.6,2-4.3,2.4-6.2C390.9,283.1,390.7,280.6,390.1,278.4z"
              />
            </g>
            <g title="Nucleoli fibrillar center" className="nucleoli_fibrillar_center_ton">
              <title>Nucleoli fibrillar center</title>
              <path
                d="M303.2,254.8c0.2,0.8,0.1,1.1,1.1,1.2
  c1.5,0.2,3.2,0.9,3.8-0.9c0.3-0.9,1.4-1.1,1.2-2.2c-0.2-0.8-0.9-2.2-1.7-2.5c-1.1-0.5-2.3-0.8-3.4,0c-0.5,0.4-1.1,0.7-1.4,1.1
  c-0.8,1.1,0.2,2,0.5,3.1C303.2,254.8,303.2,254.8,303.2,254.8z"
              />
              <path
                d="M308.8,250.3c1.5,1,2.6,0.8,4.3,0.5
  c0.6-0.1,1.5-0.1,1.9-0.7c0.4-0.5,0.7-1.2,0.5-1.9c-0.1-0.4-0.4-0.8-0.5-1.2c-0.1-0.5,0.1-1-0.1-1.5c-0.4-0.7-1.3-0.9-2-1
  c-0.7-0.1-1.6-0.2-2.3-0.2c-0.8,0.1-1.1,0.9-1.6,1.3c-0.6,0.5-0.9,0.8-1,1.6c-0.1,0.9-0.2,2.1,0.5,2.8
  C308.6,250.1,308.7,250.2,308.8,250.3z"
              />
              <path
                d="M317.7,251.4c1.1,0.3,1.8,1.7,3.1,1.7
  c0.6,0,1.2-0.2,1.9-0.2c0.6,0,1.6,0.2,2-0.4c0.8-1.1-0.3-2.6-0.7-3.6c-0.5-1.4-0.7-2.2-2.1-2.9c-0.6-0.3-1.6-1-2.3-1
  c-0.8,0-1.8,0.7-2.5,1c-0.8,0.4-1.1,1.2-1.1,2c0,0.7,0,1.6,0.2,2.2C316.3,251,317,251.2,317.7,251.4z"
              />
              <path
                d="M311.2,257.3c0.4,0.4,0.6,0.8,1.2,1
  c0.6,0.2,1.3-0.1,1.9-0.2c0.7-0.1,1.4-0.6,2-0.8c0.5-0.2,1.2-0.3,1.6-0.8c1-1.3-0.8-3.2-1.4-4.3c-0.4-0.7-1-1.5-1.8-1.4
  c-0.5,0.1-0.9,0.1-1.4,0.3c-0.5,0.1-0.6,0.4-1,0.6c-0.7,0.5-1.6,0.2-2.3,0.8c-0.5,0.4-0.4,0.9-0.3,1.5c0.1,0.5,0,0.9-0.1,1.4
  C309.4,256.4,310.6,256.7,311.2,257.3z"
              />
              <path
                d="M375.8,225.6c0.9,0.4,1.9,0.6,2.6,0.5
  c0.9-0.1,1.5-0.8,2.4-1c0.9-0.2,1.4-0.7,1.5-1.6c0.1-1-0.2-2.2-0.7-3c-0.5-0.8-1.8-1.8-2.9-1.8c-0.5,0-0.6,0.3-1,0.5
  c-0.5,0.2-0.9,0-1.4,0c-0.7,0-1.5,0.5-1.8,1.1c-0.2,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.4,0.6-0.5,0.9c-0.2,0.6-0.2,1.8,0.2,2.3
  C374.3,224.8,375,225.2,375.8,225.6z"
              />
              <path
                d="M375.7,234.3c0.6,0.1,1.1,0.1,1.7,0.2
  c1.2,0.3,2.2-0.6,3.3-1.1c1.1-0.4,2.6-1.1,2.6-2.4c0-0.5-0.4-0.9-0.6-1.3c-0.8-2-2.2-3.9-5-3.1c-0.9,0.3-1.9,0.7-2.6,1.3
  c-0.7,0.6-1,1.5-1.5,2.2c-1,1.2-0.6,2.3,0.3,3.1c0.4,0.4,0.9,0.8,1.5,1C375.6,234.3,375.7,234.3,375.7,234.3z"
              />
              <path
                d="M380.6,239.8c0.3,0.3,0.7,0.4,1.2,0.4
  c1.1-0.2,1.4-0.1,2.3,0.5c0.6,0.4,1.6,0.3,2.1-0.3c0.3-0.3,0.1-0.8,0.1-1.2c0-0.5,0.1-0.9,0.2-1.4c0.1-0.7-0.1-1-0.5-1.6
  c-0.3-0.5-0.4-1.1-0.7-1.6c-0.4-0.7-1.3-0.7-2-0.7c-0.7,0-1.8-0.4-2.5,0c-0.7,0.4-1.1,0.9-1.1,1.7c-0.1,1.1-0.9,2-0.1,3.1
  C379.9,239,380.2,239.5,380.6,239.8z"
              />
              <path
                d="M384.4,229.5c0.6,0.8,1.7,0.3,2.6,0
  c1.5-0.5,5.8,0.2,4.4-2.9c-0.3-0.7-0.7-1.3-0.9-2c-0.4-1.1-0.9-2.7-2.3-3c-1.1-0.3-2.2-0.8-3.3-0.3c-0.8,0.4-1.7,1.3-1.9,2.2
  c-0.1,0.7-0.7,1-0.8,1.8c-0.1,0.6,0.1,2.1,0.5,2.6c0.2,0.3,0.7,0.3,1,0.5c0.3,0.3,0.5,0.7,0.7,1.1
  C384.4,229.4,384.4,229.5,384.4,229.5z"
              />
              <path
                d="M386.9,235.9c0.3,0.6,0.7,1.4,1.3,1.7
  c0.4,0.2,1.1,0.3,1.6,0.4c0.6,0.1,1.5,0.4,2.1,0.2c0.6-0.2,1.1-1.1,1.4-1.7c0.2-0.4,0.5-0.5,0.6-1c0.1-0.6-0.1-1.2,0-1.8
  c0.5-2.3-1.4-4.2-3.7-4.2c-0.6,0-0.9,0.4-1.4,0.7c-0.5,0.3-1.1,0.4-1.6,0.8c-0.4,0.4-0.5,1.2-0.7,1.7
  C386.1,233.8,386.4,234.9,386.9,235.9z"
              />
              <path
                d="M364.1,287.6c1.3-0.9,1.1-2.7,0.9-4.1
  c-0.1-0.7,0-1.7-0.4-2.4c-0.6-1.1-2-2.4-3.3-2.3c-1,0.1-1.3,1.1-1.9,1.9c-1.2,1.5-1.1,3.1,0,4.8c0.3,0.4,0.9,0.8,1,1.2
  c0.5,1.2,2.5,1.6,3.5,1.1C363.9,287.7,364,287.6,364.1,287.6z"
              />
              <path
                d="M369.3,296.6c0.2,0,0.3,0,0.5,0
  c1.2,0.2,2.5,0.5,3.2-0.7c0.7-1.2,0.5-2.1,0.6-3.3c0.1-0.8,0.3-1.7-0.3-2.4c-0.3-0.4-0.9-0.5-1.4-0.7c-0.8-0.4-1.7-0.4-2.6-0.4
  c-0.9,0-1.6-0.4-2.5-0.4c-1.3,0.1-1.4,0.9-2.2,1.6c-0.6,0.6-1.3,1.1-1.4,2c-0.1,1.1,0.8,1.5,1.1,2.4c0.4,1.1,1.2,1.7,2.2,2
  c0.5,0.2,0.9,0.3,1.4,0.3C368.4,296.8,368.9,296.7,369.3,296.6z"
              />
              <path
                d="M378.1,289.2c0.2-0.2,0.4-0.5,0.7-0.7
  c0.6-0.4,1.2-0.6,1.9-0.8c0.6-0.1,1.3-0.3,1.4-1c0.1-0.5-0.2-1-0.3-1.5c-0.3-1.1-0.4-1.6-1.4-2.2c-0.8-0.5-1.7-0.9-2.7-1
  c-1-0.1-1.7,0.6-2.6,0.7c-0.9,0.1-1.4,0.8-1.4,1.6c0,0.9-0.4,1.6-0.5,2.5c0,0.6,0.3,1.7,0.8,2.1c1.2,1,2.6,1.8,3.9,0.4
  C378,289.3,378.1,289.3,378.1,289.2z"
              />
              <path
                d="M371.4,287.7c0.8-0.2,0.9-1.2,1-1.9
  c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.7-1.5,0.6-2.2c-0.1-0.9-1.2-1-1.9-1.3c-0.4-0.1-0.6-0.2-1-0.1c-1.2,0.2-2.6,0.1-3.7,0.7
  c-1.1,0.6-0.9,1.9-0.9,3c0,0.8-0.4,1.6,0.3,2.2c0.7,0.7,2.3,1.7,3.4,1.5c0.3-0.1,0.5-0.4,0.7-0.5c0.3-0.2,0.7-0.1,1.1-0.1
  C371.3,287.7,371.3,287.7,371.4,287.7z"
              />
              <path
                d="M370.4,280.5c0.3-0.2,0.7-0.6,0.9-0.9
  c0.4-0.6,0.6-1.3,0.6-2c0.1-1.2-0.4-3.6-1.7-4.1c-0.7-0.3-1.4-0.1-2.1,0c-0.6,0.1-0.8,0.4-1.4,0.7c-0.8,0.4-1.8,0.5-2.5,1.1
  c-1,0.8-1,3.4-0.1,4.2c0.3,0.3,0.7,0.4,1,0.6c0.8,0.5,1.8,0.7,2.7,0.8c0.8,0.1,1.7,0.2,2.4-0.2
  C370.3,280.6,370.3,280.6,370.4,280.5z"
              />
              <path
                d="M377.5,281.6c0.6-0.1,1.6-0.2,2.1-0.6
  c0.3-0.3,0.7-0.9,0.9-1.3c0.3-0.5,0.9-1.2,0.9-1.8c0-0.6-0.6-1.4-1-1.9c-0.3-0.4-0.3-0.6-0.7-0.9c-0.5-0.3-1.1-0.3-1.6-0.6
  c-2-1.3-4.4-0.2-5.2,1.9c-0.2,0.6,0.1,1,0.1,1.5c0.1,0.6-0.1,1.2,0.2,1.8c0.2,0.5,0.9,0.9,1.4,1.2
  C375.3,281.6,376.4,281.7,377.5,281.6z"
              />
              <path
                d="M317.4,261.6c0.5,1.2,2.1,1.6,3.2,1.5
  c0.8-0.1,2-0.1,2.7-0.7c0.6-0.5,0.8-1.9,1-2.7c0.3-0.9,0-1.7-0.4-2.5c-0.4-0.9-1-1.2-1.7-1.9c-0.7-0.7-1.4-0.8-2.4-0.6
  c-0.8,0.2-0.9,0.6-1.3,1.3c-0.5,1.1-1.8,1.5-2.3,2.6c-0.2,0.6,0.1,1.1,0.4,1.6C317.1,260.7,317.2,261.1,317.4,261.6z"
              />
              <path
                d="M308.6,264.4c0.1,0.9,0.8,1.2,1.6,1.3
  c0.8,0.1,1.8,0.7,2.7,0.5c0.5-0.1,0.7-0.5,1.1-0.8c0.4-0.4,0.9-0.6,1.4-0.9c1.2-0.7,0.7-1.8,0.2-2.8c-0.4-0.8-1-2.4-2-2.8
  c-0.8-0.4-3.1-0.5-3.8,0c-1,0.6-0.9,1.5-0.8,2.5C308.9,262.5,308.4,263.4,308.6,264.4z"
              />
              <path
                d="M302.3,262.1c0.1,0.1,0.2,0.2,0.3,0.3
  c1,1,2.4,0.5,3.6,0.6c1.3,0.1,2.4,0,2.2-1.7c-0.1-0.7,0-1.3,0.2-1.9c0.1-0.6,0.2-1.2-0.4-1.5c-0.4-0.3-0.9-0.4-1.4-0.6
  c-0.4-0.2-0.6-0.6-1.1-0.7c-0.6-0.1-1.4-0.1-1.9,0.1c-0.6,0.2-0.9,0.7-1.4,1C301.1,258.6,301.6,261,302.3,262.1z"
              />
            </g>
            <g
              title="Nucleoli fibrillar center"
              className={locations.includes('Nucleoli fibrillar center') ? 'nucleoli_fibrillar_center' : 'nucleoli_fibrillar_center--disabled'}
            >
              <title>Nucleoli fibrillar center</title>
              <path
                d="M303.2,254.8c0.2,0.8,0.1,1.1,1.1,1.2
  c1.5,0.2,3.2,0.9,3.8-0.9c0.3-0.9,1.4-1.1,1.2-2.2c-0.2-0.8-0.9-2.2-1.7-2.5c-1.1-0.5-2.3-0.8-3.4,0c-0.5,0.4-1.1,0.7-1.4,1.1
  c-0.8,1.1,0.2,2,0.5,3.1C303.2,254.8,303.2,254.8,303.2,254.8z"
              />
              <path
                d="M308.8,250.3c1.5,1,2.6,0.8,4.3,0.5
  c0.6-0.1,1.5-0.1,1.9-0.7c0.4-0.5,0.7-1.2,0.5-1.9c-0.1-0.4-0.4-0.8-0.5-1.2c-0.1-0.5,0.1-1-0.1-1.5c-0.4-0.7-1.3-0.9-2-1
  c-0.7-0.1-1.6-0.2-2.3-0.2c-0.8,0.1-1.1,0.9-1.6,1.3c-0.6,0.5-0.9,0.8-1,1.6c-0.1,0.9-0.2,2.1,0.5,2.8
  C308.6,250.1,308.7,250.2,308.8,250.3z"
              />
              <path
                d="M317.7,251.4c1.1,0.3,1.8,1.7,3.1,1.7
  c0.6,0,1.2-0.2,1.9-0.2c0.6,0,1.6,0.2,2-0.4c0.8-1.1-0.3-2.6-0.7-3.6c-0.5-1.4-0.7-2.2-2.1-2.9c-0.6-0.3-1.6-1-2.3-1
  c-0.8,0-1.8,0.7-2.5,1c-0.8,0.4-1.1,1.2-1.1,2c0,0.7,0,1.6,0.2,2.2C316.3,251,317,251.2,317.7,251.4z"
              />
              <path
                d="M311.2,257.3c0.4,0.4,0.6,0.8,1.2,1
  c0.6,0.2,1.3-0.1,1.9-0.2c0.7-0.1,1.4-0.6,2-0.8c0.5-0.2,1.2-0.3,1.6-0.8c1-1.3-0.8-3.2-1.4-4.3c-0.4-0.7-1-1.5-1.8-1.4
  c-0.5,0.1-0.9,0.1-1.4,0.3c-0.5,0.1-0.6,0.4-1,0.6c-0.7,0.5-1.6,0.2-2.3,0.8c-0.5,0.4-0.4,0.9-0.3,1.5c0.1,0.5,0,0.9-0.1,1.4
  C309.4,256.4,310.6,256.7,311.2,257.3z"
              />
              <path
                d="M375.8,225.6c0.9,0.4,1.9,0.6,2.6,0.5
  c0.9-0.1,1.5-0.8,2.4-1c0.9-0.2,1.4-0.7,1.5-1.6c0.1-1-0.2-2.2-0.7-3c-0.5-0.8-1.8-1.8-2.9-1.8c-0.5,0-0.6,0.3-1,0.5
  c-0.5,0.2-0.9,0-1.4,0c-0.7,0-1.5,0.5-1.8,1.1c-0.2,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.4,0.6-0.5,0.9c-0.2,0.6-0.2,1.8,0.2,2.3
  C374.3,224.8,375,225.2,375.8,225.6z"
              />
              <path
                d="M375.7,234.3c0.6,0.1,1.1,0.1,1.7,0.2
  c1.2,0.3,2.2-0.6,3.3-1.1c1.1-0.4,2.6-1.1,2.6-2.4c0-0.5-0.4-0.9-0.6-1.3c-0.8-2-2.2-3.9-5-3.1c-0.9,0.3-1.9,0.7-2.6,1.3
  c-0.7,0.6-1,1.5-1.5,2.2c-1,1.2-0.6,2.3,0.3,3.1c0.4,0.4,0.9,0.8,1.5,1C375.6,234.3,375.7,234.3,375.7,234.3z"
              />
              <path
                d="M380.6,239.8c0.3,0.3,0.7,0.4,1.2,0.4
  c1.1-0.2,1.4-0.1,2.3,0.5c0.6,0.4,1.6,0.3,2.1-0.3c0.3-0.3,0.1-0.8,0.1-1.2c0-0.5,0.1-0.9,0.2-1.4c0.1-0.7-0.1-1-0.5-1.6
  c-0.3-0.5-0.4-1.1-0.7-1.6c-0.4-0.7-1.3-0.7-2-0.7c-0.7,0-1.8-0.4-2.5,0c-0.7,0.4-1.1,0.9-1.1,1.7c-0.1,1.1-0.9,2-0.1,3.1
  C379.9,239,380.2,239.5,380.6,239.8z"
              />
              <path
                d="M384.4,229.5c0.6,0.8,1.7,0.3,2.6,0
  c1.5-0.5,5.8,0.2,4.4-2.9c-0.3-0.7-0.7-1.3-0.9-2c-0.4-1.1-0.9-2.7-2.3-3c-1.1-0.3-2.2-0.8-3.3-0.3c-0.8,0.4-1.7,1.3-1.9,2.2
  c-0.1,0.7-0.7,1-0.8,1.8c-0.1,0.6,0.1,2.1,0.5,2.6c0.2,0.3,0.7,0.3,1,0.5c0.3,0.3,0.5,0.7,0.7,1.1
  C384.4,229.4,384.4,229.5,384.4,229.5z"
              />
              <path
                d="M386.9,235.9c0.3,0.6,0.7,1.4,1.3,1.7
  c0.4,0.2,1.1,0.3,1.6,0.4c0.6,0.1,1.5,0.4,2.1,0.2c0.6-0.2,1.1-1.1,1.4-1.7c0.2-0.4,0.5-0.5,0.6-1c0.1-0.6-0.1-1.2,0-1.8
  c0.5-2.3-1.4-4.2-3.7-4.2c-0.6,0-0.9,0.4-1.4,0.7c-0.5,0.3-1.1,0.4-1.6,0.8c-0.4,0.4-0.5,1.2-0.7,1.7
  C386.1,233.8,386.4,234.9,386.9,235.9z"
              />
              <path
                d="M364.1,287.6c1.3-0.9,1.1-2.7,0.9-4.1
  c-0.1-0.7,0-1.7-0.4-2.4c-0.6-1.1-2-2.4-3.3-2.3c-1,0.1-1.3,1.1-1.9,1.9c-1.2,1.5-1.1,3.1,0,4.8c0.3,0.4,0.9,0.8,1,1.2
  c0.5,1.2,2.5,1.6,3.5,1.1C363.9,287.7,364,287.6,364.1,287.6z"
              />
              <path
                d="M369.3,296.6c0.2,0,0.3,0,0.5,0
  c1.2,0.2,2.5,0.5,3.2-0.7c0.7-1.2,0.5-2.1,0.6-3.3c0.1-0.8,0.3-1.7-0.3-2.4c-0.3-0.4-0.9-0.5-1.4-0.7c-0.8-0.4-1.7-0.4-2.6-0.4
  c-0.9,0-1.6-0.4-2.5-0.4c-1.3,0.1-1.4,0.9-2.2,1.6c-0.6,0.6-1.3,1.1-1.4,2c-0.1,1.1,0.8,1.5,1.1,2.4c0.4,1.1,1.2,1.7,2.2,2
  c0.5,0.2,0.9,0.3,1.4,0.3C368.4,296.8,368.9,296.7,369.3,296.6z"
              />
              <path
                d="M378.1,289.2c0.2-0.2,0.4-0.5,0.7-0.7
  c0.6-0.4,1.2-0.6,1.9-0.8c0.6-0.1,1.3-0.3,1.4-1c0.1-0.5-0.2-1-0.3-1.5c-0.3-1.1-0.4-1.6-1.4-2.2c-0.8-0.5-1.7-0.9-2.7-1
  c-1-0.1-1.7,0.6-2.6,0.7c-0.9,0.1-1.4,0.8-1.4,1.6c0,0.9-0.4,1.6-0.5,2.5c0,0.6,0.3,1.7,0.8,2.1c1.2,1,2.6,1.8,3.9,0.4
  C378,289.3,378.1,289.3,378.1,289.2z"
              />
              <path
                d="M371.4,287.7c0.8-0.2,0.9-1.2,1-1.9
  c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.7-1.5,0.6-2.2c-0.1-0.9-1.2-1-1.9-1.3c-0.4-0.1-0.6-0.2-1-0.1c-1.2,0.2-2.6,0.1-3.7,0.7
  c-1.1,0.6-0.9,1.9-0.9,3c0,0.8-0.4,1.6,0.3,2.2c0.7,0.7,2.3,1.7,3.4,1.5c0.3-0.1,0.5-0.4,0.7-0.5c0.3-0.2,0.7-0.1,1.1-0.1
  C371.3,287.7,371.3,287.7,371.4,287.7z"
              />
              <path
                d="M370.4,280.5c0.3-0.2,0.7-0.6,0.9-0.9
  c0.4-0.6,0.6-1.3,0.6-2c0.1-1.2-0.4-3.6-1.7-4.1c-0.7-0.3-1.4-0.1-2.1,0c-0.6,0.1-0.8,0.4-1.4,0.7c-0.8,0.4-1.8,0.5-2.5,1.1
  c-1,0.8-1,3.4-0.1,4.2c0.3,0.3,0.7,0.4,1,0.6c0.8,0.5,1.8,0.7,2.7,0.8c0.8,0.1,1.7,0.2,2.4-0.2
  C370.3,280.6,370.3,280.6,370.4,280.5z"
              />
              <path
                d="M377.5,281.6c0.6-0.1,1.6-0.2,2.1-0.6
  c0.3-0.3,0.7-0.9,0.9-1.3c0.3-0.5,0.9-1.2,0.9-1.8c0-0.6-0.6-1.4-1-1.9c-0.3-0.4-0.3-0.6-0.7-0.9c-0.5-0.3-1.1-0.3-1.6-0.6
  c-2-1.3-4.4-0.2-5.2,1.9c-0.2,0.6,0.1,1,0.1,1.5c0.1,0.6-0.1,1.2,0.2,1.8c0.2,0.5,0.9,0.9,1.4,1.2
  C375.3,281.6,376.4,281.7,377.5,281.6z"
              />
              <path
                d="M317.4,261.6c0.5,1.2,2.1,1.6,3.2,1.5
  c0.8-0.1,2-0.1,2.7-0.7c0.6-0.5,0.8-1.9,1-2.7c0.3-0.9,0-1.7-0.4-2.5c-0.4-0.9-1-1.2-1.7-1.9c-0.7-0.7-1.4-0.8-2.4-0.6
  c-0.8,0.2-0.9,0.6-1.3,1.3c-0.5,1.1-1.8,1.5-2.3,2.6c-0.2,0.6,0.1,1.1,0.4,1.6C317.1,260.7,317.2,261.1,317.4,261.6z"
              />
              <path
                d="M308.6,264.4c0.1,0.9,0.8,1.2,1.6,1.3
  c0.8,0.1,1.8,0.7,2.7,0.5c0.5-0.1,0.7-0.5,1.1-0.8c0.4-0.4,0.9-0.6,1.4-0.9c1.2-0.7,0.7-1.8,0.2-2.8c-0.4-0.8-1-2.4-2-2.8
  c-0.8-0.4-3.1-0.5-3.8,0c-1,0.6-0.9,1.5-0.8,2.5C308.9,262.5,308.4,263.4,308.6,264.4z"
              />
              <path
                d="M302.3,262.1c0.1,0.1,0.2,0.2,0.3,0.3
  c1,1,2.4,0.5,3.6,0.6c1.3,0.1,2.4,0,2.2-1.7c-0.1-0.7,0-1.3,0.2-1.9c0.1-0.6,0.2-1.2-0.4-1.5c-0.4-0.3-0.9-0.4-1.4-0.6
  c-0.4-0.2-0.6-0.6-1.1-0.7c-0.6-0.1-1.4-0.1-1.9,0.1c-0.6,0.2-0.9,0.7-1.4,1C301.1,258.6,301.6,261,302.3,262.1z"
              />
            </g>
            <g title="Rods and rings" className="rods_and_rings_ton">
              <title>Rods and rings</title>
              <path
                d="M214.9,93.5c-2.5-2.7-7.7-2.5-10.7-0.9
  c-1.8,1-3.2,2.5-3.4,4.6c-0.3,2.1,0.4,5.9,1.6,7.6c1.2,1.6,2.9,2.7,4.9,2.8c2.8,0.3,5.1-0.3,6.7-1.6c1.6-1.3,2.7-3.4,2.9-6.2
  C217.2,97.5,216.4,95.2,214.9,93.5z M209.9,103.3c-1.8,0.4-3.8-0.4-4.6-2.7c-0.7-2.2-0.3-4.6,2.1-5.4c1.9-0.6,4.7,0.4,5.3,2.5
  C213.5,99.9,212.2,102.7,209.9,103.3z"
              />
              <path
                d="M535.6,273.1c-3-5.9-15.3-1.5-16,4.4
  c-0.3,2.2,0.4,6.3,1.8,8.1c1.2,1.7,5,3.3,7,3.7c2.2,0.4,4.3-0.2,5.9-1.8c3.5-3.5,2.9-8.9,1.8-13.3
  C536,273.9,535.8,273.5,535.6,273.1z M529.4,284.5c-2.1-0.1-4.5-1.6-5-3.4c-0.8-2.4-0.4-5.1,2.3-5.9c2.1-0.6,5.1,0.5,5.9,2.7
  c0.7,2.1,0.7,4.9-1.4,6.2C530.7,284.4,530.1,284.5,529.4,284.5z"
              />
              <path
                d="M202,360.9c0.3,0,0.5,0,0.7,0.1
  c0.7,0.3,0.8,1.4,1.2,2c0.9,1.3,4.1,10.1,6.6,8.9c2.8-1.3-0.9-7.4-1.7-8.9c-0.9-1.6-2.2-2.1-3.6-3.3c-1-0.9-1-1.9-2.5-2.1
  c-1.2-0.2-2.9,0.6-4.1,0.8c-1.8,0.3-6.7,0-7.6,2.2c-1.6,4,6,1.8,7.5,1.5C199.2,361.9,200.9,361,202,360.9z"
              />
              <path
                d="M407.6,390.6c0.3,1,0.4,2,0.5,3
  c0.2,2.1,0,4.2,0.5,6.3c0.5,2,3.1,4.2,5.1,4.4c0.9,0.1,2.4,0.3,2.6-1c0.2-1.1-1-1.9-1.9-2.3c-2-1.1-2.5-3.2-2.7-5.3
  c-0.1-1.3-0.3-2.6-0.6-3.8c-0.2-1.1-0.1-2.3-0.4-3.4c-0.5-2.3-2.1-4.5-4.3-5.3c-1-0.4-2.3-0.6-3.1,0.2c-0.7,0.6-0.6,1.5,0,2.2
  c0.6,0.7,1.7,0.8,2.4,1.4c1,0.8,1.5,2.2,1.8,3.4C407.5,390.5,407.5,390.5,407.6,390.6z"
              />
              <path
                d="M409.6,99.9c0.3,0.4,0.8,0.6,1.6,0.5
  c1.2-0.2,1.4-1.2,1.7-2.1c0.6-2,1.8-3.7,3-5.3c0.9-1.2,2.7-3.8,0.7-4.9c-1.5-0.8-3.4,1.8-4.2,2.8C411.8,91.8,408.3,98.1,409.6,99.9
  z"
              />
              <path
                d="M422.4,101.6c0.3,1,0.8,1.9,1.8,2.1
  c1.2,0.3,2-0.9,2.1-1.9c0.5-3.3-2.2-5.4-3.7-8c-0.8-1.3-1.1-2.4-1.5-3.7c-0.5-1.6-2.2-3-3.2-1c-1.9,3.6,2.4,7.7,3.9,10.7
  C422,100.3,422.2,101,422.4,101.6z"
              />
            </g>
            <g
              title="Rods and rings"
              className={locations.includes('Rods and rings') ? 'rods_and_rings' : 'rods_and_rings--disabled'}
            >
              <title>Rods and rings</title>
              <path
                d="M214.8,93.7c-2.5-2.7-7.7-2.5-10.7-0.9
  c-1.8,1-3.2,2.5-3.4,4.6c-0.3,2.1,0.4,5.9,1.6,7.6c1.2,1.6,2.9,2.7,4.9,2.8c2.8,0.3,5.1-0.3,6.7-1.6c1.6-1.3,2.7-3.4,2.9-6.2
  C217.1,97.7,216.4,95.4,214.8,93.7z M209.8,103.5c-1.8,0.4-3.8-0.4-4.6-2.7c-0.7-2.2-0.3-4.6,2.1-5.4c1.9-0.6,4.7,0.4,5.3,2.5
  C213.4,100.1,212.1,102.9,209.8,103.5z"
              />
              <path
                d="M535.6,273.1c-3-5.9-15.3-1.5-16,4.4
  c-0.3,2.2,0.4,6.3,1.8,8.1c1.2,1.7,5,3.3,7,3.7c2.2,0.4,4.3-0.2,5.9-1.8c3.5-3.5,2.9-8.9,1.8-13.3
  C536,273.9,535.8,273.5,535.6,273.1z M529.4,284.5c-2.1-0.1-4.5-1.6-5-3.4c-0.8-2.4-0.4-5.1,2.3-5.9c2.1-0.6,5.1,0.5,5.9,2.7
  c0.7,2.1,0.7,4.9-1.4,6.2C530.7,284.4,530.1,284.5,529.4,284.5z"
              />
              <path
                d="M202,360.9c0.3,0,0.5,0,0.7,0.1
  c0.7,0.3,0.8,1.4,1.2,2c0.9,1.3,4.1,10.1,6.6,8.9c2.8-1.3-0.9-7.4-1.7-8.9c-0.9-1.6-2.2-2.1-3.6-3.3c-1-0.9-1-1.9-2.5-2.1
  c-1.2-0.2-2.9,0.6-4.1,0.8c-1.8,0.3-6.7,0-7.6,2.2c-1.6,4,6,1.8,7.5,1.5C199.2,361.9,200.9,361,202,360.9z"
              />
              <path
                d="M407.6,390.6c0.3,1,0.4,2,0.5,3
  c0.2,2.1,0,4.2,0.5,6.3c0.5,2,3.1,4.2,5.1,4.4c0.9,0.1,2.4,0.3,2.6-1c0.2-1.1-1-1.9-1.9-2.3c-2-1.1-2.5-3.2-2.7-5.3
  c-0.1-1.3-0.3-2.6-0.6-3.8c-0.2-1.1-0.1-2.3-0.4-3.4c-0.5-2.3-2.1-4.5-4.3-5.3c-1-0.4-2.3-0.6-3.1,0.2c-0.7,0.6-0.6,1.5,0,2.2
  c0.6,0.7,1.7,0.8,2.4,1.4c1,0.8,1.5,2.2,1.8,3.4C407.5,390.5,407.5,390.5,407.6,390.6z"
              />
              <path
                d="M409.6,99.9c0.3,0.4,0.8,0.6,1.6,0.5
  c1.2-0.2,1.4-1.2,1.7-2.1c0.6-2,1.8-3.7,3-5.3c0.9-1.2,2.7-3.8,0.7-4.9c-1.5-0.8-3.4,1.8-4.2,2.8C411.8,91.8,408.3,98.1,409.6,99.9
  z"
              />
              <path
                d="M422.4,101.6c0.3,1,0.8,1.9,1.8,2.1
  c1.2,0.3,2-0.9,2.1-1.9c0.5-3.3-2.2-5.4-3.7-8c-0.8-1.3-1.1-2.4-1.5-3.7c-0.5-1.6-2.2-3-3.2-1c-1.9,3.6,2.4,7.7,3.9,10.7
  C422,100.3,422.2,101,422.4,101.6z"
              />
            </g>
            <g title="Mitochondria" className="mitochondria_ton">
              <title>Mitochondria</title>
              <path
                d="M281.3,410.3c-2.6,5.5-13.8,2.8-23.1-3
  c-12.2-7.7,0.2-26.3,11-15.6C277.5,399.9,283.6,405.3,281.3,410.3z"
              />
              <path
                d="M463.6,106c13.9-0.7,23.1,0.5,32.1,17.7
  c5.9,11.2-7,14.4-10.6,7.1c-5.2-10.5-9.4-13.4-21.9-12.6C454.7,118.8,452.5,106.5,463.6,106z"
              />
              <path
                d="M495,340.9c-11,17,0.4,13.3-11.7,30.9
  c-6.5,9.5-16.4,0.6-12.6-6.1c10.6-18.6,0.8-16.5,12.6-32.5C488.4,326.1,500,333.3,495,340.9z"
              />
              <path
                d="M132.5,351.2c11.1,0.8,14.3,1,22.2-5.3
  c7.1-5.7,12.3,3.3,6.4,9.4c-6.8,7.1-16,11.7-27.4,8.8C123,361.3,122.8,350.5,132.5,351.2z"
              />
              <path
                d="M324,73.1c5.8-12,16.3-4.1,19.1,2.7
  c2.8,6.8,0.4,12.7-7.8,13C326,89,318.8,83.8,324,73.1z"
              />
              <path
                d="M483.2,333.2c-11.8,16.1-2,13.9-12.6,32.5
  c-3.8,6.6,6.1,15.6,12.6,6.1c12.1-17.6,0.7-13.9,11.7-30.9C500,333.3,488.4,326.1,483.2,333.2z M478,353.2
  c-0.1-0.7,0.1-1.4,0.3-2.1c0.2-0.8,0.3-1.7,0.5-2.5c0.2-0.8,0.7-1.4,1.7-1.3c0.4,0.1,0.7,0.2,1.1,0.3c0.9,0.2,1.8,0.5,2.8,0.7
  c0.9,0.2,2.8,0.4,2.8-1c0-0.8-0.8-1.2-1.4-1.4c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-3.2-1.2-3-2.9c0-0.5,0.3-1.1,0.6-1.5
  c0.5-0.7,1.3-1.2,2.1-1c0.7,0.2,1.3,0.7,2,1c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.2-0.4-1.4c-0.1-0.1-0.3-0.2-0.4-0.2
  c-0.8-0.4-2.4-1-1.9-2.2c0.3-0.7,1-1.1,1.7-0.9c0.7,0.2,1.2,0.8,1.9,1.2c0.5,0.3,1.1,0.1,1.4-0.3c0.3-0.5,0.1-1.1-0.3-1.4
  c-0.4-0.3-0.9-0.6-1.3-0.9c-0.6-0.4-0.6-0.9,0-1.4c0.3-0.2,0.7-0.3,1.1-0.3c1.5-0.2,3.1,0.3,4.3,1.3c0.5,0.4,0.9,0.9,1.2,1.5
  c0.2,0.3,0.3,0.7,0.4,1c0.2,0.8,0.3,1.7-0.3,2.4c-0.6,0.6-1.5,0-2.1-0.2c-0.3-0.1-0.9-0.1-1.1,0.2c-0.5,0.4-0.5,1.1,0,1.5
  c0.4,0.3,1,0.6,1.2,1c0.2,0.4,0.2,0.9-0.1,1.3c-0.2,0.4-0.5,0.8-0.9,1c-0.9,0.5-2-0.3-2.8-0.6c-0.5-0.2-1.2,0-1.4,0.5
  c-0.2,0.4-0.1,0.9,0.3,1.2c0.1,0.1,0.2,0.1,0.2,0.1c0.7,0.3,1.5,0.7,1.8,1.4c0.3,0.7,0.1,1.5-0.1,2.2c-0.2,0.7-0.4,1.5-1.1,1.9
  c-0.5,0.3-1.2,0.4-1.9,0.3c-0.6-0.1-1.2-0.2-1.7-0.3c-0.6-0.1-1.1,0.3-1.2,0.9c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.3,1.1,0.3
  c0.5,0.1,0.9,0.1,1.4,0.2c0.5,0,1.1,0,1.5,0.4c0.4,0.4,0.5,1.1,0.3,1.6c-0.6,1.3-2.4,0.1-2.9,1.3c-0.2,0.4-0.1,0.8,0.1,1.2
  c0.2,0.3,0.6,0.4,1,0.5c0.4,0,0.8,0.1,1.2,0.3c1,0.6,0.5,2.3-0.5,2.6c-0.7,0.2-1.4-0.2-2-0.4c-0.5-0.2-1.1,0.1-1.4,0.6
  c-0.2,0.4,0,0.9,0.3,1.2c0.6,0.5,1.7,0.3,2.1,1c0.3,0.5,0.1,1.1-0.3,1.5c-0.6,0.4-1.3,0.3-1.9,0.1c-0.6-0.2-1.4-0.6-1.9,0.1
  c-0.7,1,0.4,1.5,1.1,1.9c0.3,0.2,0.7,0.5,0.8,0.9c0.2,0.6-0.2,1.3-0.5,1.8c-0.4,0.7-1,1.3-1.7,1.7c-0.7,0.4-1.6,0-2.2-0.4
  c-0.3-0.2-0.7-0.5-1-0.7c-0.5-0.3-1.1-0.2-1.5,0.3c-0.2,0.2-0.2,0.6-0.1,0.9c0.3,0.9,2.6,1.1,2.1,2.4c-0.5,1.3-2.5,0.9-3.4,0.4
  c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.4-0.8-0.8-1.1-1.3c-0.3-0.4-0.5-0.9-0.6-1.3c-0.5-1.4-0.3-3.1,1.2-3.9c0.7-0.4,1.4,0.1,2,0.4
  c0.9,0.4,1.7,0.9,2.5,1.5c0.5,0.3,1.1,0.2,1.5-0.3c0.3-0.5,0.2-1.1-0.3-1.5c-0.3-0.2-0.6-0.4-0.9-0.5c-0.7-0.4-1.3-0.7-2-1.1
  c-0.6-0.3-1.2-0.6-1.5-1.2c-0.3-0.5-0.1-1.2,0.3-1.6c0.5-0.6,1.2-0.7,1.9-0.4c0.6,0.2,1.1,0.4,1.7,0.7c0.5,0.3,1.1,0,1.4-0.5
  c0.3-0.5,0-1.2-0.5-1.4c-0.3-0.2-0.7-0.3-1-0.4c-0.6-0.3-1.5-0.5-1.7-1.3c-0.1-0.4,0.2-0.9,0.6-1.1c0.4-0.3,1-0.2,1.4-0.1
  c0.5,0.1,1.1,0.3,1.6,0.5c1.3,0.5,2-1.5,0.8-2c-1-0.4-1.9-0.9-2.5-1.7C478.2,354.1,478.1,353.7,478,353.2z"
              />
              <path
                d="M154.7,345.9c-7.9,6.3-11.1,6.1-22.2,5.3
  c-9.6-0.7-9.4,10.1,1.2,12.9c11.3,2.9,20.6-1.7,27.4-8.8C167,349.1,161.7,340.2,154.7,345.9z M161.3,349.1c-0.4,0.1-1-0.5-1.2-0.8
  c-0.3-0.3-0.7-0.4-1.1-0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.4-0.3,0.9-0.1,1.3c0.2,0.5,0.7,0.8,1,1.3c0.4,0.6,0.6,1.3,0.5,1.9
  c-0.2,1.1-1.4,2.2-2.4,2.7c-0.3,0.2-0.7,0.2-1.1,0.1c-0.7-0.2-1.1-0.8-1.6-1.3c-0.5-0.6-1.2-1.3-2-1c-0.1,0-0.1,0.1-0.2,0.1
  c-1.1,0.7-0.2,1.9,0.3,2.6c0.5,0.7,0.9,1.6,0.6,2.5c-0.2,0.6-0.8,1-1.4,1.3c-0.7,0.4-1.4,0.7-2.1,1c-0.8,0.5-2.2,1.2-2.8,0
  c-0.2-0.4-0.3-0.9-0.4-1.3c-0.2-0.6-0.4-1.5-1.2-1.4c-1.4,0.1-1.2,1.8-0.8,2.7c0.2,0.5,0.3,1.2-0.2,1.5c-0.4,0.2-1,0.2-1.4,0
  c-0.9-0.5-0.6-2-0.7-2.9c-0.1-0.6-0.7-1-1.3-0.8c-0.1,0-0.1,0-0.2,0.1c-1,0.4-0.6,1.3-0.6,2.1c0.1,1.1-0.7,2.1-1.8,2.2
  c-0.7,0-1.4-0.1-2.1-0.3c-1.6-0.4-2.3-2.3-1.7-3.8c0,0,0.1-0.6,0.1-0.6c0.2-0.6-0.2-1.2-0.8-1.3c-0.2-0.1-0.5,0-0.7,0
  c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.6c-0.2,0.8-0.2,2-1.1,2.3c-0.5,0.2-1.1,0-1.6-0.3c-0.7-0.5-1.4-1.3-1.9-2c-0.4-0.6-0.6-1.3-0.7-2
  c0-0.6,0.1-1.2,0.4-1.6c0.3-0.4,0.8-0.7,1.3-0.4c0.5,0.4,0.5,1.1,0.5,1.6c0,0,0,1,1,1.2c1.2,0.2,1.4-1.5,1.7-2.2
  c0.2-0.4,0.5-0.9,0.8-1.2c0.4-0.4,1-0.5,1.6-0.5c0.7,0,1.3,0.4,1.6,1c0.4,0.7,0.3,1.5,0.3,2.3c0,0.7,0,1.6,0.7,2.1
  c0.5,0.3,1.3,0.3,1.7-0.1c0.4-0.4,0.5-1,0.6-1.6c0.1-0.9,0.2-1.8,1-2.4c0.7-0.5,1.5-0.7,2.3-0.8c1-0.2,2.4-0.3,3.3,0.4
  c0.5,0.4,0.9,1,1.2,1.6c0.4,0.7,0.8,1.5,1.1,2.3c0.2,0.6,0.3,1.5,1.1,1.7c0.6,0.2,1.2-0.3,1.3-0.9c0.2-0.7-0.2-1.4-0.4-2
  c-0.3-0.7-0.6-1.4-0.9-2.1c-0.4-1-0.6-2,0.2-2.8c0.4-0.5,1-0.9,1.6-1.1c0.3-0.1,0.6-0.2,1-0.3c1.3-0.2,2.2,0.8,2.9,1.7
  c0.4,0.5,1.1,0.5,1.5,0.2c0.5-0.4,0.5-1.1,0.2-1.5c-0.4-0.5-1.3-1.2-1.4-1.9c-0.1-1.3,1.2-2.2,2.2-2.6c1.7-0.7,2.7,0.1,3.2,0.8
  C161.2,347.5,162,348.9,161.3,349.1z"
              />
              <path
                d="M335.3,88.7c8.2-0.3,10.6-6.2,7.8-13
  c-2.8-6.8-13.3-14.6-19.1-2.7C318.8,83.8,326,89,335.3,88.7z M324.5,80c-0.1-1.8,0.6-3.6,1.2-5.3c0.3-0.8,0.7-1.6,1.2-2.4
  c0.5-0.7,1.1-1.6,1.8-2.1c0.4-0.3,1.3-0.7,1.7-0.1c0.3,0.4,0.3,1,0.1,1.5c-0.2,0.7-0.6,1.2-0.9,1.9c-0.4,1.1-1,2.2-1.4,3.4
  c-0.2,0.6,0.1,1.3,0.8,1.5c0.1,0,0.3,0.1,0.4,0.1c0.5,0,1-0.3,1.1-0.8c0.2-0.7,0.5-1.4,0.9-2.1c0.6-1.1,1.2-2.3,1.9-3.4
  c0.3-0.4,0.6-0.8,1.1-0.9c0.8-0.3,1.7,0,2.2,0.7c0.8,1.2-0.2,2.6-0.8,3.6c-0.6,0.9-1.1,1.8-1.4,2.3c-0.3,0.6-0.1,1.3,0.4,1.6
  c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6c0.5-0.8,1-1.6,1.4-2.4c0.3-0.6,0.6-1.4,1.5-1.4c0.8,0,1.3,0.7,1.6,1.4
  c1.2,2.9,1.2,5.6,0.1,7.3c-0.3,0.5-0.9,1.5-1.6,1.4c-1.2-0.2-0.3-2-0.4-2.7c-0.1-0.4-0.4-0.7-0.8-0.9c-0.6-0.2-1.3,0.1-1.5,0.8
  c-0.2,0.7-0.5,1.4-0.8,2c-0.4,0.8-1,1.4-1.9,1.4c-0.4,0-0.9-0.1-1.2-0.4c-0.2-0.2-0.5-0.8-0.5-1.2c0.1-1.3,1.1-2.8-0.2-3.8
  c-0.2-0.2-0.6-0.3-0.9-0.3c-1,0.2-1.4,1.7-2,2.5c-0.9,1.3-2.6,1-3.6,0c0,0-0.1-0.1-0.1-0.1C324.8,81.9,324.5,80.9,324.5,80z"
              />
              <path
                d="M258.2,407.3c9.3,5.9,20.6,8.6,23.1,3
  c2.3-5-3.7-10.4-12.1-18.6C258.4,381,246,399.6,258.2,407.3z M258,404.6c-0.7-0.3-0.3-1.2,0-1.7c0.6-1,1.3-1.9,1.9-2.9
  c0.3-0.5,0.2-1.2-0.4-1.5c-0.5-0.3-1.2-0.2-1.5,0.4c-0.3,0.5-1.5,3.1-2.4,2.1c-0.4-0.4-0.4-1.1-0.4-1.6c0-0.7-0.1-1.4,0.1-2.1
  c0.1-0.3,0.1-0.6,0.2-1c0.3-1.1,0.9-2.2,1.6-3.1c0.8-1,1.9-1.7,3.1-2.2c0.3-0.1,1.4-0.5,1.3,0.2c-0.1,0.5-0.7,1-1,1.4
  c-0.5,0.6-1.3,1.4-1.2,2.2c0.1,0.6,0.8,1.1,1.4,0.8c0.6-0.2,0.9-1,1.2-1.5c0.5-0.8,1.2-1.5,1.8-2.2c0.3-0.4,0.7-0.6,1.1-0.6
  c0.6,0,1.3,0.3,1.7,0.9c0.2,0.3,0.2,0.7,0.1,1c-0.2,0.7-0.8,1.2-1.3,1.7c-0.5,0.5-1.2,1.1-0.8,1.8c0.3,0.7,1.3,0.9,1.8,0.3
  c0.6-0.7,1.2-1.6,2-2c0.7-0.4,1.4,0.1,1.8,0.8c0.8,1.3-0.8,2.3-1.5,3.2c-0.5,0.6-0.9,1.2-1.3,1.8c-0.7,1-1.4,2-1.9,3
  c-0.3,0.6,0,1.2,0.5,1.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.6c0.2-0.5,0.5-0.9,0.7-1.3c1-1.4,1.9-2.9,3.1-4.1
  c0.4-0.4,0.7-0.8,1.3-0.8c0.5,0,0.9,0.3,1.1,0.7c0.5,1-0.2,2-0.7,2.9c-0.3,0.4-0.7,0.9-0.8,1.4c-0.2,0.6,0.1,1.2,0.7,1.4
  c0.1,0,0.2,0.1,0.3,0.1c0.7,0,1.1-0.8,1.4-1.3c0.3-0.5,0.9-1.5,1.6-1.5c0.4,0,0.7,0.3,1,0.6c0.4,0.4,0.5,1.1,0.5,1.6
  c-0.1,0.6-0.6,0.9-0.9,1.4c-0.5,0.7-1,1.4-1.3,2.2c-0.2,0.6,0,1.2,0.6,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.7,0,0.9-0.6,1.2-1.1
  c0.2-0.5,0.7-1.3,1.3-1.3c0.7-0.1,0.9,1,0.8,1.5c-0.2,2.1-2.9,2.7-4.6,2.7c-0.6,0-1.2-0.1-1.9-0.1c-0.6-0.1-1.2,0-1.7-0.4
  c-0.7-0.4-0.4-1.1-0.5-1.8c-0.2-0.7-0.8-1.2-1.6-0.9c-0.6,0.3-0.9,1.1-1.6,1.3c-0.6,0.2-1.3,0-1.9-0.2c-0.8-0.4-1.6-0.8-2.4-1.1
  c-0.8-0.4-2-0.7-2.2-1.7c-0.2-0.8,0.5-1.5,1-2.1c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.1-0.4-1.6,0c-0.5,0.5-0.8,1.1-1.2,1.5
  c-0.2,0.2-0.5,0.4-0.9,0.5C258.5,404.7,258.3,404.7,258,404.6z"
              />
              <path
                d="M495.7,123.6c-9.1-17.2-18.3-18.4-32.1-17.7
  c-11,0.5-8.9,12.8-0.4,12.3c12.5-0.8,16.7,2,21.9,12.6C488.7,138,501.6,134.9,495.7,123.6z M491.5,132.8c-0.9,0.1-1.8-0.2-2.6-0.6
  c-0.4-0.2-0.8-0.5-1.1-0.8c-0.5-0.6-0.7-1.2-0.1-1.7c0.4-0.4,1-0.5,1.4-0.8c0.5-0.3,0.7-0.8,0.4-1.4c-0.3-0.5-0.9-0.5-1.4-0.3
  c-0.7,0.3-1.6,1-2.4,0.5c-0.8-0.4-0.3-1.5,0.1-2c0.5-0.6,1.2-0.9,1.7-1.4c0.3-0.2,0.5-0.6,0.4-1c0-0.1-0.1-0.3-0.2-0.4
  c-0.4-0.5-1.1-0.4-1.6,0c-0.6,0.4-1.2,1-1.9,1.3c-0.5,0.2-1-0.1-1.2-0.6c-0.2-0.5,0.1-1.1,0.4-1.6c0.3-0.4,0.7-0.7,0.6-1.3
  c0-0.2-0.1-0.3-0.3-0.4c-0.9-0.8-1.6,0.4-2.4,0.6c-1,0.3-1.1-0.9-1-1.6c0.1-0.5,0.5-1.1,0.4-1.6c-0.2-0.9-1.2-0.8-1.8-0.4
  c-0.5,0.4-1,0.7-1.7,0.7c-0.6,0-1.1-0.3-1.7-0.5c-0.8-0.3-1.6-0.6-2.4-0.7c-0.6-0.1-1.1-0.2-1.7-0.3c-0.5-0.1-1.1-0.1-1.5-0.4
  c-0.4-0.4-0.2-0.9-0.3-1.4c-0.1-0.4-0.5-0.8-1-0.7c-0.3,0-0.7,0.3-0.9,0.6c-0.2,0.4-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.4-1.2,0.4
  c-0.5-0.1-0.9-0.3-1.1-0.7c-0.4-0.8-0.1-3.1-1.6-2.7c-0.5,0.1-0.9,0.7-0.7,1.2c0.2,0.8,0.5,2-0.7,2.1c-1.2,0.1-2.5-0.6-3.2-1.5
  c-0.4-0.5-0.6-1.1-0.8-1.7c-0.2-0.7-0.2-1.5,0-2.2c0.1-0.4,0.5-1.3,1.2-1.1c0.5,0.2,0.5,1.1,0.5,1.6c0,0.5,0.5,0.9,1.1,0.9
  c0.6,0,1-0.5,1-1c0-0.8-0.4-1.7-0.2-2.5c0.2-1,1.9-1.1,2.6-0.7c0.9,0.5,0.6,1.8,1,2.6c0.4,1,2,0.6,1.9-0.5c0-0.6-0.2-1.4,0-2
  c0.1-0.3,0.3-0.5,0.6-0.5c0.4-0.1,0.9,0,1.2,0.3c0.3,0.4,0.1,1,0,1.4c-0.1,0.5,0.4,1,0.9,1c1.1,0.1,1-1.1,1.4-1.7
  c0.2-0.3,0.5-0.5,0.9-0.6c0.6-0.2,1.4-0.2,1.7,0.5c0.3,0.7,0.1,1.5,0,2.3c-0.1,0.5-0.2,1-0.2,1.4c-0.1,0.5,0.3,1,0.8,1.1
  c0.6,0.1,1.1-0.3,1.2-0.8c0.2-0.9,0.2-2,0.6-2.9c0.4-0.7,1.3-0.9,2.1-0.8c1.3,0.2,0.7,1.9,0.4,2.7c-0.3,0.8-0.6,1.5-0.8,2.3
  c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.5,0.1,1.1,0.7,1.2c0.8,0.2,1.2-0.4,1.4-1c0.3-0.8,0.5-1.6,0.7-2.4c0.2-0.6,0.4-1.4,1.1-1.6
  c0.4-0.1,0.9-0.1,1.3,0c0.5,0.2,1.1,0.6,1,1.2c-0.1,1-2.1,2.7-0.7,3.5c0.3,0.2,0.8,0.2,1.1,0c0.6-0.4,0.9-1.3,1.8-1.4
  c0.8-0.1,1.5,0.6,1.7,1.3c0.4,1.2-1.3,2-1.4,3.1c-0.1,1,1.1,1.7,1.8,0.8c0.4-0.5,1-1.2,1.7-1.1c0.6,0.1,1,0.5,1.3,1
  c0.3,0.5,0.4,1,0.2,1.6c-0.3,0.8-1.9,1.4-1.4,2.4c0.2,0.5,0.8,0.7,1.3,0.5c0.6-0.3,1.1-0.6,1.8-0.3c1.1,0.4,1.5,2.2,0.3,2.8
  c-0.6,0.3-1.2,0.4-1.1,1.2c0.1,0.5,0.6,0.9,1.1,0.8c0.7-0.1,1.5-0.4,1.9,0.4c0.2,0.4,0.2,1.1,0.1,1.6c-0.3,1.1-1.2,1.9-2.2,2.4
  C492.5,132.7,492,132.8,491.5,132.8z"
              />
              <path
                d="M131.4,243.7c-15.2,16.1-13.2,26.2-7.6,40.3
  c4.5,11.3,16.9,4.2,13.3-4.4c-5.3-12.7-3.7-18.2,5.7-27.7c1.7-1.7,3.3-3.6,4.6-5.7c1-1.6,2.1-3.5,2.2-5.3c0.2-1.9-0.8-3.9-2.4-4.9
  c-4.2-2.7-9.3,1.6-12.4,4.3C133.7,241.4,132.5,242.5,131.4,243.7z"
              />
              <path
                d="M131.4,243.7c-15.2,16.1-13.2,26.2-7.6,40.3
  c4.5,11.3,16.9,4.2,13.3-4.4c-5.3-12.7-3.7-18.2,5.7-27.7c1.7-1.7,3.3-3.6,4.6-5.7c1-1.6,2.1-3.5,2.2-5.3c0.2-1.9-0.8-3.9-2.4-4.9
  c-4.2-2.7-9.3,1.6-12.4,4.3C133.7,241.4,132.5,242.5,131.4,243.7z M143.7,244.4c-0.1,0-0.3,0-0.5,0c-1.3,0.3-1.5,1.3-0.6,2.2
  c1,1.1-0.4,3.7-1.9,2.9c-0.6-0.3-0.9-0.8-1.4-1.2c-0.5-0.4-1.1-0.5-1.6,0.2c-0.5,0.5-0.2,1.1,0.2,1.6c0.6,0.6,1.6,1.3,1.4,2.3
  c-0.2,1-1.4,0.9-2.2,0.7c-0.8-0.2-1.4-0.8-2.1-1.3c-0.4-0.2-0.9-0.2-1.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.7,0,1.3,0.5,1.7
  c0.7,0.5,1.5,0.8,2,1.5c0.4,0.5,0.2,1.1-0.2,1.4c-0.5,0.4-1.2,0.4-1.8,0.2c-0.6-0.2-1-0.5-1.6-0.1c-0.2,0.1-0.3,0.3-0.4,0.4
  c-0.6,1.2,1,1.5,1.5,2.3c0.7,1-0.6,1.5-1.4,1.6c-0.6,0.1-1.3-0.1-1.8,0.2c-0.8,0.6-0.5,1.5,0.2,2c0.6,0.4,1.1,0.8,1.3,1.5
  c0.2,0.6,0.1,1.3,0,2c0,0.9,0,1.9,0.1,2.8c0.1,0.6,0.2,1.2,0.2,1.9c0.1,0.5,0.3,1.2,0.1,1.7c-0.2,0.6-0.9,0.6-1.4,0.9
  c-0.4,0.3-0.6,0.9-0.4,1.4c0.1,0.3,0.6,0.6,0.9,0.7c0.5,0,1.1-0.2,1.6,0c0.5,0.2,0.7,0.7,0.8,1.1c0.1,0.5,0,1.1-0.4,1.4
  c-0.7,0.8-3.3,1.3-2.3,2.8c0.3,0.5,1,0.7,1.5,0.3c0.8-0.5,1.9-1.3,2.5-0.1c0.6,1.2,0.2,2.8-0.4,3.9c-0.4,0.6-0.9,1.1-1.5,1.5
  c-0.7,0.4-1.5,0.8-2.4,0.8c-0.5,0-1.6,0-1.6-0.8c0-0.6,1-0.9,1.5-1.1c0.6-0.2,0.8-0.9,0.6-1.5c-0.2-0.6-0.9-0.8-1.5-0.6
  c-0.9,0.4-1.7,1.1-2.6,1.2c-1.2,0.2-1.9-1.5-1.7-2.5c0.2-1.2,1.7-1.3,2.4-2c0.9-0.8-0.1-2.3-1.2-1.8c-0.7,0.3-1.4,0.8-2.1,0.7
  c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.4-0.4-1-0.1-1.3c0.3-0.5,1-0.4,1.5-0.5c0.6-0.1,0.9-0.8,0.8-1.3c-0.3-1.2-1.5-0.6-2.3-0.8
  c-0.4-0.1-0.7-0.4-1-0.7c-0.5-0.6-0.7-1.4-0.1-1.9c0.7-0.6,1.6-0.7,2.4-0.9c0.6-0.1,1.1-0.2,1.5-0.3c0.6-0.1,1-0.7,0.9-1.3
  c-0.1-0.6-0.7-1-1.3-0.9c-1,0.2-2.2,0.6-3.3,0.5c-0.9-0.1-1.5-1-1.6-1.9c-0.2-1.4,1.8-1.5,2.8-1.5c0.9,0,1.8,0,2.8,0
  c0.3,0,0.6,0,0.9,0c0.6,0,1.1-0.6,1-1.2c-0.1-0.9-0.8-1.1-1.6-1.1c-0.9,0-1.9,0.1-2.8,0.2c-0.7,0-1.7,0.2-2.1-0.5
  c-0.2-0.3-0.4-0.9-0.4-1.3c0-0.6,0.3-1.3,0.9-1.5c1.1-0.3,3.6,1.1,4-0.7c0.1-0.4-0.1-0.9-0.4-1.2c-0.7-0.5-1.7-0.5-2.2-1.3
  c-0.4-0.8,0.1-1.8,0.8-2.3c1.2-0.8,2.5,0.5,3.8,0.3c1.1-0.2,1.4-1.8,0.2-2.2c-0.7-0.2-1.6-0.5-1.8-1.3c-0.1-0.6,0.2-1.3,0.6-1.8
  c0.4-0.5,1-0.9,1.6-0.9c1,0,2.1,1.5,3.1,0.5c0.4-0.4,0.5-1.1,0.1-1.5c-0.5-0.5-1-1-1-1.8c0.1-1.4,1.8-2.4,2.9-1.4
  c0.7,0.7,2.3,1.3,2.6-0.2c0.1-1-1.3-1.7-0.7-2.8c1.2-2.3,5.5,2,5.4-1c0-0.9-1-1.7-0.9-2.6c0.2-1.9,3.3-0.6,4.1,0
  c0.8,0.6,1.4,1.6,1.5,2.7C147.3,243.5,145.6,244.4,143.7,244.4z"
              />
            </g>
            <g
              title="Mitochondria"
              className={locations.includes('Mitochondria') ? 'mitochondria' : 'mitochondria--disabled'}
            >
              <title>Mitochondria</title>
              <path
                d="M131.4,243.7c-15.2,16.1-13.2,26.2-7.6,40.3
  c4.5,11.3,16.9,4.2,13.3-4.4c-5.3-12.7-3.7-18.2,5.7-27.7c1.7-1.7,3.3-3.6,4.6-5.7c1-1.6,2.1-3.5,2.2-5.3c0.2-1.9-0.8-3.9-2.4-4.9
  c-4.2-2.7-9.3,1.6-12.4,4.3C133.7,241.4,132.5,242.5,131.4,243.7z"
              />
              <path
                d="M281.3,410.3c-2.6,5.5-13.8,2.8-23.1-3
  c-12.2-7.7,0.2-26.3,11-15.6C277.5,399.9,283.6,405.3,281.3,410.3z"
              />
              <path
                d="M463.6,106c13.9-0.7,23.1,0.5,32.1,17.7
  c5.9,11.2-7,14.4-10.6,7.1c-5.2-10.5-9.4-13.4-21.9-12.6C454.7,118.8,452.5,106.5,463.6,106z"
              />
              <path
                d="M495,340.9c-11,17,0.4,13.3-11.7,30.9
  c-6.5,9.5-16.4,0.6-12.6-6.1c10.6-18.6,0.8-16.5,12.6-32.5C488.4,326.1,500,333.3,495,340.9z"
              />
              <path
                d="M132.5,351.2c11.1,0.8,14.3,1,22.2-5.3
  c7.1-5.7,12.3,3.3,6.4,9.4c-6.8,7.1-16,11.7-27.4,8.8C123,361.3,122.8,350.5,132.5,351.2z"
              />
              <path
                d="M324,73.1c5.8-12,16.3-4.1,19.1,2.7
  c2.8,6.8,0.4,12.7-7.8,13C326,89,318.8,83.8,324,73.1z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M483.2,333.2c-11.8,16.1-2,13.9-12.6,32.5
  c-3.8,6.6,6.1,15.6,12.6,6.1c12.1-17.6,0.7-13.9,11.7-30.9C500,333.3,488.4,326.1,483.2,333.2z M478,353.2
  c-0.1-0.7,0.1-1.4,0.3-2.1c0.2-0.8,0.3-1.7,0.5-2.5c0.2-0.8,0.7-1.4,1.7-1.3c0.4,0.1,0.7,0.2,1.1,0.3c0.9,0.2,1.8,0.5,2.8,0.7
  c0.9,0.2,2.8,0.4,2.8-1c0-0.8-0.8-1.2-1.4-1.4c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-3.2-1.2-3-2.9c0-0.5,0.3-1.1,0.6-1.5
  c0.5-0.7,1.3-1.2,2.1-1c0.7,0.2,1.3,0.7,2,1c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.2-0.4-1.4c-0.1-0.1-0.3-0.2-0.4-0.2
  c-0.8-0.4-2.4-1-1.9-2.2c0.3-0.7,1-1.1,1.7-0.9c0.7,0.2,1.2,0.8,1.9,1.2c0.5,0.3,1.1,0.1,1.4-0.3c0.3-0.5,0.1-1.1-0.3-1.4
  c-0.4-0.3-0.9-0.6-1.3-0.9c-0.6-0.4-0.6-0.9,0-1.4c0.3-0.2,0.7-0.3,1.1-0.3c1.5-0.2,3.1,0.3,4.3,1.3c0.5,0.4,0.9,0.9,1.2,1.5
  c0.2,0.3,0.3,0.7,0.4,1c0.2,0.8,0.3,1.7-0.3,2.4c-0.6,0.6-1.5,0-2.1-0.2c-0.3-0.1-0.9-0.1-1.1,0.2c-0.5,0.4-0.5,1.1,0,1.5
  c0.4,0.3,1,0.6,1.2,1c0.2,0.4,0.2,0.9-0.1,1.3c-0.2,0.4-0.5,0.8-0.9,1c-0.9,0.5-2-0.3-2.8-0.6c-0.5-0.2-1.2,0-1.4,0.5
  c-0.2,0.4-0.1,0.9,0.3,1.2c0.1,0.1,0.2,0.1,0.2,0.1c0.7,0.3,1.5,0.7,1.8,1.4c0.3,0.7,0.1,1.5-0.1,2.2c-0.2,0.7-0.4,1.5-1.1,1.9
  c-0.5,0.3-1.2,0.4-1.9,0.3c-0.6-0.1-1.2-0.2-1.7-0.3c-0.6-0.1-1.1,0.3-1.2,0.9c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.3,1.1,0.3
  c0.5,0.1,0.9,0.1,1.4,0.2c0.5,0,1.1,0,1.5,0.4c0.4,0.4,0.5,1.1,0.3,1.6c-0.6,1.3-2.4,0.1-2.9,1.3c-0.2,0.4-0.1,0.8,0.1,1.2
  c0.2,0.3,0.6,0.4,1,0.5c0.4,0,0.8,0.1,1.2,0.3c1,0.6,0.5,2.3-0.5,2.6c-0.7,0.2-1.4-0.2-2-0.4c-0.5-0.2-1.1,0.1-1.4,0.6
  c-0.2,0.4,0,0.9,0.3,1.2c0.6,0.5,1.7,0.3,2.1,1c0.3,0.5,0.1,1.1-0.3,1.5c-0.6,0.4-1.3,0.3-1.9,0.1c-0.6-0.2-1.4-0.6-1.9,0.1
  c-0.7,1,0.4,1.5,1.1,1.9c0.3,0.2,0.7,0.5,0.8,0.9c0.2,0.6-0.2,1.3-0.5,1.8c-0.4,0.7-1,1.3-1.7,1.7c-0.7,0.4-1.6,0-2.2-0.4
  c-0.3-0.2-0.7-0.5-1-0.7c-0.5-0.3-1.1-0.2-1.5,0.3c-0.2,0.2-0.2,0.6-0.1,0.9c0.3,0.9,2.6,1.1,2.1,2.4c-0.5,1.3-2.5,0.9-3.4,0.4
  c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.4-0.8-0.8-1.1-1.3c-0.3-0.4-0.5-0.9-0.6-1.3c-0.5-1.4-0.3-3.1,1.2-3.9c0.7-0.4,1.4,0.1,2,0.4
  c0.9,0.4,1.7,0.9,2.5,1.5c0.5,0.3,1.1,0.2,1.5-0.3c0.3-0.5,0.2-1.1-0.3-1.5c-0.3-0.2-0.6-0.4-0.9-0.5c-0.7-0.4-1.3-0.7-2-1.1
  c-0.6-0.3-1.2-0.6-1.5-1.2c-0.3-0.5-0.1-1.2,0.3-1.6c0.5-0.6,1.2-0.7,1.9-0.4c0.6,0.2,1.1,0.4,1.7,0.7c0.5,0.3,1.1,0,1.4-0.5
  c0.3-0.5,0-1.2-0.5-1.4c-0.3-0.2-0.7-0.3-1-0.4c-0.6-0.3-1.5-0.5-1.7-1.3c-0.1-0.4,0.2-0.9,0.6-1.1c0.4-0.3,1-0.2,1.4-0.1
  c0.5,0.1,1.1,0.3,1.6,0.5c1.3,0.5,2-1.5,0.8-2c-1-0.4-1.9-0.9-2.5-1.7C478.2,354.1,478.1,353.7,478,353.2z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M154.7,345.9c-7.9,6.3-11.1,6.1-22.2,5.3
  c-9.6-0.7-9.4,10.1,1.2,12.9c11.3,2.9,20.6-1.7,27.4-8.8C167,349.1,161.7,340.2,154.7,345.9z M161.3,349.1c-0.4,0.1-1-0.5-1.2-0.8
  c-0.3-0.3-0.7-0.4-1.1-0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.4-0.3,0.9-0.1,1.3c0.2,0.5,0.7,0.8,1,1.3c0.4,0.6,0.6,1.3,0.5,1.9
  c-0.2,1.1-1.4,2.2-2.4,2.7c-0.3,0.2-0.7,0.2-1.1,0.1c-0.7-0.2-1.1-0.8-1.6-1.3c-0.5-0.6-1.2-1.3-2-1c-0.1,0-0.1,0.1-0.2,0.1
  c-1.1,0.7-0.2,1.9,0.3,2.6c0.5,0.7,0.9,1.6,0.6,2.5c-0.2,0.6-0.8,1-1.4,1.3c-0.7,0.4-1.4,0.7-2.1,1c-0.8,0.5-2.2,1.2-2.8,0
  c-0.2-0.4-0.3-0.9-0.4-1.3c-0.2-0.6-0.4-1.5-1.2-1.4c-1.4,0.1-1.2,1.8-0.8,2.7c0.2,0.5,0.3,1.2-0.2,1.5c-0.4,0.2-1,0.2-1.4,0
  c-0.9-0.5-0.6-2-0.7-2.9c-0.1-0.6-0.7-1-1.3-0.8c-0.1,0-0.1,0-0.2,0.1c-1,0.4-0.6,1.3-0.6,2.1c0.1,1.1-0.7,2.1-1.8,2.2
  c-0.7,0-1.4-0.1-2.1-0.3c-1.6-0.4-2.3-2.3-1.7-3.8c0,0,0.1-0.6,0.1-0.6c0.2-0.6-0.2-1.2-0.8-1.3c-0.2-0.1-0.5,0-0.7,0
  c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.6c-0.2,0.8-0.2,2-1.1,2.3c-0.5,0.2-1.1,0-1.6-0.3c-0.7-0.5-1.4-1.3-1.9-2c-0.4-0.6-0.6-1.3-0.7-2
  c0-0.6,0.1-1.2,0.4-1.6c0.3-0.4,0.8-0.7,1.3-0.4c0.5,0.4,0.5,1.1,0.5,1.6c0,0,0,1,1,1.2c1.2,0.2,1.4-1.5,1.7-2.2
  c0.2-0.4,0.5-0.9,0.8-1.2c0.4-0.4,1-0.5,1.6-0.5c0.7,0,1.3,0.4,1.6,1c0.4,0.7,0.3,1.5,0.3,2.3c0,0.7,0,1.6,0.7,2.1
  c0.5,0.3,1.3,0.3,1.7-0.1c0.4-0.4,0.5-1,0.6-1.6c0.1-0.9,0.2-1.8,1-2.4c0.7-0.5,1.5-0.7,2.3-0.8c1-0.2,2.4-0.3,3.3,0.4
  c0.5,0.4,0.9,1,1.2,1.6c0.4,0.7,0.8,1.5,1.1,2.3c0.2,0.6,0.3,1.5,1.1,1.7c0.6,0.2,1.2-0.3,1.3-0.9c0.2-0.7-0.2-1.4-0.4-2
  c-0.3-0.7-0.6-1.4-0.9-2.1c-0.4-1-0.6-2,0.2-2.8c0.4-0.5,1-0.9,1.6-1.1c0.3-0.1,0.6-0.2,1-0.3c1.3-0.2,2.2,0.8,2.9,1.7
  c0.4,0.5,1.1,0.5,1.5,0.2c0.5-0.4,0.5-1.1,0.2-1.5c-0.4-0.5-1.3-1.2-1.4-1.9c-0.1-1.3,1.2-2.2,2.2-2.6c1.7-0.7,2.7,0.1,3.2,0.8
  C161.2,347.5,162,348.9,161.3,349.1z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M335.3,88.7c8.2-0.3,10.6-6.2,7.8-13
  c-2.8-6.8-13.3-14.6-19.1-2.7C318.8,83.8,326,89,335.3,88.7z M324.5,80c-0.1-1.8,0.6-3.6,1.2-5.3c0.3-0.8,0.7-1.6,1.2-2.4
  c0.5-0.7,1.1-1.6,1.8-2.1c0.4-0.3,1.3-0.7,1.7-0.1c0.3,0.4,0.3,1,0.1,1.5c-0.2,0.7-0.6,1.2-0.9,1.9c-0.4,1.1-1,2.2-1.4,3.4
  c-0.2,0.6,0.1,1.3,0.8,1.5c0.1,0,0.3,0.1,0.4,0.1c0.5,0,1-0.3,1.1-0.8c0.2-0.7,0.5-1.4,0.9-2.1c0.6-1.1,1.2-2.3,1.9-3.4
  c0.3-0.4,0.6-0.8,1.1-0.9c0.8-0.3,1.7,0,2.2,0.7c0.8,1.2-0.2,2.6-0.8,3.6c-0.6,0.9-1.1,1.8-1.4,2.3c-0.3,0.6-0.1,1.3,0.4,1.6
  c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6c0.5-0.8,1-1.6,1.4-2.4c0.3-0.6,0.6-1.4,1.5-1.4c0.8,0,1.3,0.7,1.6,1.4
  c1.2,2.9,1.2,5.6,0.1,7.3c-0.3,0.5-0.9,1.5-1.6,1.4c-1.2-0.2-0.3-2-0.4-2.7c-0.1-0.4-0.4-0.7-0.8-0.9c-0.6-0.2-1.3,0.1-1.5,0.8
  c-0.2,0.7-0.5,1.4-0.8,2c-0.4,0.8-1,1.4-1.9,1.4c-0.4,0-0.9-0.1-1.2-0.4c-0.2-0.2-0.5-0.8-0.5-1.2c0.1-1.3,1.1-2.8-0.2-3.8
  c-0.2-0.2-0.6-0.3-0.9-0.3c-1,0.2-1.4,1.7-2,2.5c-0.9,1.3-2.6,1-3.6,0c0,0-0.1-0.1-0.1-0.1C324.8,81.9,324.5,80.9,324.5,80z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M258.2,407.3c9.3,5.9,20.6,8.6,23.1,3
  c2.3-5-3.7-10.4-12.1-18.6C258.4,381,246,399.6,258.2,407.3z M258,404.6c-0.7-0.3-0.3-1.2,0-1.7c0.6-1,1.3-1.9,1.9-2.9
  c0.3-0.5,0.2-1.2-0.4-1.5c-0.5-0.3-1.2-0.2-1.5,0.4c-0.3,0.5-1.5,3.1-2.4,2.1c-0.4-0.4-0.4-1.1-0.4-1.6c0-0.7-0.1-1.4,0.1-2.1
  c0.1-0.3,0.1-0.6,0.2-1c0.3-1.1,0.9-2.2,1.6-3.1c0.8-1,1.9-1.7,3.1-2.2c0.3-0.1,1.4-0.5,1.3,0.2c-0.1,0.5-0.7,1-1,1.4
  c-0.5,0.6-1.3,1.4-1.2,2.2c0.1,0.6,0.8,1.1,1.4,0.8c0.6-0.2,0.9-1,1.2-1.5c0.5-0.8,1.2-1.5,1.8-2.2c0.3-0.4,0.7-0.6,1.1-0.6
  c0.6,0,1.3,0.3,1.7,0.9c0.2,0.3,0.2,0.7,0.1,1c-0.2,0.7-0.8,1.2-1.3,1.7c-0.5,0.5-1.2,1.1-0.8,1.8c0.3,0.7,1.3,0.9,1.8,0.3
  c0.6-0.7,1.2-1.6,2-2c0.7-0.4,1.4,0.1,1.8,0.8c0.8,1.3-0.8,2.3-1.5,3.2c-0.5,0.6-0.9,1.2-1.3,1.8c-0.7,1-1.4,2-1.9,3
  c-0.3,0.6,0,1.2,0.5,1.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.6c0.2-0.5,0.5-0.9,0.7-1.3c1-1.4,1.9-2.9,3.1-4.1
  c0.4-0.4,0.7-0.8,1.3-0.8c0.5,0,0.9,0.3,1.1,0.7c0.5,1-0.2,2-0.7,2.9c-0.3,0.4-0.7,0.9-0.8,1.4c-0.2,0.6,0.1,1.2,0.7,1.4
  c0.1,0,0.2,0.1,0.3,0.1c0.7,0,1.1-0.8,1.4-1.3c0.3-0.5,0.9-1.5,1.6-1.5c0.4,0,0.7,0.3,1,0.6c0.4,0.4,0.5,1.1,0.5,1.6
  c-0.1,0.6-0.6,0.9-0.9,1.4c-0.5,0.7-1,1.4-1.3,2.2c-0.2,0.6,0,1.2,0.6,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.7,0,0.9-0.6,1.2-1.1
  c0.2-0.5,0.7-1.3,1.3-1.3c0.7-0.1,0.9,1,0.8,1.5c-0.2,2.1-2.9,2.7-4.6,2.7c-0.6,0-1.2-0.1-1.9-0.1c-0.6-0.1-1.2,0-1.7-0.4
  c-0.7-0.4-0.4-1.1-0.5-1.8c-0.2-0.7-0.8-1.2-1.6-0.9c-0.6,0.3-0.9,1.1-1.6,1.3c-0.6,0.2-1.3,0-1.9-0.2c-0.8-0.4-1.6-0.8-2.4-1.1
  c-0.8-0.4-2-0.7-2.2-1.7c-0.2-0.8,0.5-1.5,1-2.1c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.1-0.4-1.6,0c-0.5,0.5-0.8,1.1-1.2,1.5
  c-0.2,0.2-0.5,0.4-0.9,0.5C258.5,404.7,258.3,404.7,258,404.6z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M495.7,123.6c-9.1-17.2-18.3-18.4-32.1-17.7
  c-11,0.5-8.9,12.8-0.4,12.3c12.5-0.8,16.7,2,21.9,12.6C488.7,138,501.6,134.9,495.7,123.6z M491.5,132.8c-0.9,0.1-1.8-0.2-2.6-0.6
  c-0.4-0.2-0.8-0.5-1.1-0.8c-0.5-0.6-0.7-1.2-0.1-1.7c0.4-0.4,1-0.5,1.4-0.8c0.5-0.3,0.7-0.8,0.4-1.4c-0.3-0.5-0.9-0.5-1.4-0.3
  c-0.7,0.3-1.6,1-2.4,0.5c-0.8-0.4-0.3-1.5,0.1-2c0.5-0.6,1.2-0.9,1.7-1.4c0.3-0.2,0.5-0.6,0.4-1c0-0.1-0.1-0.3-0.2-0.4
  c-0.4-0.5-1.1-0.4-1.6,0c-0.6,0.4-1.2,1-1.9,1.3c-0.5,0.2-1-0.1-1.2-0.6c-0.2-0.5,0.1-1.1,0.4-1.6c0.3-0.4,0.7-0.7,0.6-1.3
  c0-0.2-0.1-0.3-0.3-0.4c-0.9-0.8-1.6,0.4-2.4,0.6c-1,0.3-1.1-0.9-1-1.6c0.1-0.5,0.5-1.1,0.4-1.6c-0.2-0.9-1.2-0.8-1.8-0.4
  c-0.5,0.4-1,0.7-1.7,0.7c-0.6,0-1.1-0.3-1.7-0.5c-0.8-0.3-1.6-0.6-2.4-0.7c-0.6-0.1-1.1-0.2-1.7-0.3c-0.5-0.1-1.1-0.1-1.5-0.4
  c-0.4-0.4-0.2-0.9-0.3-1.4c-0.1-0.4-0.5-0.8-1-0.7c-0.3,0-0.7,0.3-0.9,0.6c-0.2,0.4-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.4-1.2,0.4
  c-0.5-0.1-0.9-0.3-1.1-0.7c-0.4-0.8-0.1-3.1-1.6-2.7c-0.5,0.1-0.9,0.7-0.7,1.2c0.2,0.8,0.5,2-0.7,2.1c-1.2,0.1-2.5-0.6-3.2-1.5
  c-0.4-0.5-0.6-1.1-0.8-1.7c-0.2-0.7-0.2-1.5,0-2.2c0.1-0.4,0.5-1.3,1.2-1.1c0.5,0.2,0.5,1.1,0.5,1.6c0,0.5,0.5,0.9,1.1,0.9
  c0.6,0,1-0.5,1-1c0-0.8-0.4-1.7-0.2-2.5c0.2-1,1.9-1.1,2.6-0.7c0.9,0.5,0.6,1.8,1,2.6c0.4,1,2,0.6,1.9-0.5c0-0.6-0.2-1.4,0-2
  c0.1-0.3,0.3-0.5,0.6-0.5c0.4-0.1,0.9,0,1.2,0.3c0.3,0.4,0.1,1,0,1.4c-0.1,0.5,0.4,1,0.9,1c1.1,0.1,1-1.1,1.4-1.7
  c0.2-0.3,0.5-0.5,0.9-0.6c0.6-0.2,1.4-0.2,1.7,0.5c0.3,0.7,0.1,1.5,0,2.3c-0.1,0.5-0.2,1-0.2,1.4c-0.1,0.5,0.3,1,0.8,1.1
  c0.6,0.1,1.1-0.3,1.2-0.8c0.2-0.9,0.2-2,0.6-2.9c0.4-0.7,1.3-0.9,2.1-0.8c1.3,0.2,0.7,1.9,0.4,2.7c-0.3,0.8-0.6,1.5-0.8,2.3
  c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.5,0.1,1.1,0.7,1.2c0.8,0.2,1.2-0.4,1.4-1c0.3-0.8,0.5-1.6,0.7-2.4c0.2-0.6,0.4-1.4,1.1-1.6
  c0.4-0.1,0.9-0.1,1.3,0c0.5,0.2,1.1,0.6,1,1.2c-0.1,1-2.1,2.7-0.7,3.5c0.3,0.2,0.8,0.2,1.1,0c0.6-0.4,0.9-1.3,1.8-1.4
  c0.8-0.1,1.5,0.6,1.7,1.3c0.4,1.2-1.3,2-1.4,3.1c-0.1,1,1.1,1.7,1.8,0.8c0.4-0.5,1-1.2,1.7-1.1c0.6,0.1,1,0.5,1.3,1
  c0.3,0.5,0.4,1,0.2,1.6c-0.3,0.8-1.9,1.4-1.4,2.4c0.2,0.5,0.8,0.7,1.3,0.5c0.6-0.3,1.1-0.6,1.8-0.3c1.1,0.4,1.5,2.2,0.3,2.8
  c-0.6,0.3-1.2,0.4-1.1,1.2c0.1,0.5,0.6,0.9,1.1,0.8c0.7-0.1,1.5-0.4,1.9,0.4c0.2,0.4,0.2,1.1,0.1,1.6c-0.3,1.1-1.2,1.9-2.2,2.4
  C492.5,132.7,492,132.8,491.5,132.8z"
              />
              <path
                className={locations.includes('Mitochondria') ? 'anotherMitohondria' : 'anotherMitohondria--disabled'}
                d="M131.4,243.7c-15.2,16.1-13.2,26.2-7.6,40.3
  c4.5,11.3,16.9,4.2,13.3-4.4c-5.3-12.7-3.7-18.2,5.7-27.7c1.7-1.7,3.3-3.6,4.6-5.7c1-1.6,2.1-3.5,2.2-5.3c0.2-1.9-0.8-3.9-2.4-4.9
  c-4.2-2.7-9.3,1.6-12.4,4.3C133.7,241.4,132.5,242.5,131.4,243.7z M143.7,244.4c-0.1,0-0.3,0-0.5,0c-1.3,0.3-1.5,1.3-0.6,2.2
  c1,1.1-0.4,3.7-1.9,2.9c-0.6-0.3-0.9-0.8-1.4-1.2c-0.5-0.4-1.1-0.5-1.6,0.2c-0.5,0.5-0.2,1.1,0.2,1.6c0.6,0.6,1.6,1.3,1.4,2.3
  c-0.2,1-1.4,0.9-2.2,0.7c-0.8-0.2-1.4-0.8-2.1-1.3c-0.4-0.2-0.9-0.2-1.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.7,0,1.3,0.5,1.7
  c0.7,0.5,1.5,0.8,2,1.5c0.4,0.5,0.2,1.1-0.2,1.4c-0.5,0.4-1.2,0.4-1.8,0.2c-0.6-0.2-1-0.5-1.6-0.1c-0.2,0.1-0.3,0.3-0.4,0.4
  c-0.6,1.2,1,1.5,1.5,2.3c0.7,1-0.6,1.5-1.4,1.6c-0.6,0.1-1.3-0.1-1.8,0.2c-0.8,0.6-0.5,1.5,0.2,2c0.6,0.4,1.1,0.8,1.3,1.5
  c0.2,0.6,0.1,1.3,0,2c0,0.9,0,1.9,0.1,2.8c0.1,0.6,0.2,1.2,0.2,1.9c0.1,0.5,0.3,1.2,0.1,1.7c-0.2,0.6-0.9,0.6-1.4,0.9
  c-0.4,0.3-0.6,0.9-0.4,1.4c0.1,0.3,0.6,0.6,0.9,0.7c0.5,0,1.1-0.2,1.6,0c0.5,0.2,0.7,0.7,0.8,1.1c0.1,0.5,0,1.1-0.4,1.4
  c-0.7,0.8-3.3,1.3-2.3,2.8c0.3,0.5,1,0.7,1.5,0.3c0.8-0.5,1.9-1.3,2.5-0.1c0.6,1.2,0.2,2.8-0.4,3.9c-0.4,0.6-0.9,1.1-1.5,1.5
  c-0.7,0.4-1.5,0.8-2.4,0.8c-0.5,0-1.6,0-1.6-0.8c0-0.6,1-0.9,1.5-1.1c0.6-0.2,0.8-0.9,0.6-1.5c-0.2-0.6-0.9-0.8-1.5-0.6
  c-0.9,0.4-1.7,1.1-2.6,1.2c-1.2,0.2-1.9-1.5-1.7-2.5c0.2-1.2,1.7-1.3,2.4-2c0.9-0.8-0.1-2.3-1.2-1.8c-0.7,0.3-1.4,0.8-2.1,0.7
  c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.4-0.4-1-0.1-1.3c0.3-0.5,1-0.4,1.5-0.5c0.6-0.1,0.9-0.8,0.8-1.3c-0.3-1.2-1.5-0.6-2.3-0.8
  c-0.4-0.1-0.7-0.4-1-0.7c-0.5-0.6-0.7-1.4-0.1-1.9c0.7-0.6,1.6-0.7,2.4-0.9c0.6-0.1,1.1-0.2,1.5-0.3c0.6-0.1,1-0.7,0.9-1.3
  c-0.1-0.6-0.7-1-1.3-0.9c-1,0.2-2.2,0.6-3.3,0.5c-0.9-0.1-1.5-1-1.6-1.9c-0.2-1.4,1.8-1.5,2.8-1.5c0.9,0,1.8,0,2.8,0
  c0.3,0,0.6,0,0.9,0c0.6,0,1.1-0.6,1-1.2c-0.1-0.9-0.8-1.1-1.6-1.1c-0.9,0-1.9,0.1-2.8,0.2c-0.7,0-1.7,0.2-2.1-0.5
  c-0.2-0.3-0.4-0.9-0.4-1.3c0-0.6,0.3-1.3,0.9-1.5c1.1-0.3,3.6,1.1,4-0.7c0.1-0.4-0.1-0.9-0.4-1.2c-0.7-0.5-1.7-0.5-2.2-1.3
  c-0.4-0.8,0.1-1.8,0.8-2.3c1.2-0.8,2.5,0.5,3.8,0.3c1.1-0.2,1.4-1.8,0.2-2.2c-0.7-0.2-1.6-0.5-1.8-1.3c-0.1-0.6,0.2-1.3,0.6-1.8
  c0.4-0.5,1-0.9,1.6-0.9c1,0,2.1,1.5,3.1,0.5c0.4-0.4,0.5-1.1,0.1-1.5c-0.5-0.5-1-1-1-1.8c0.1-1.4,1.8-2.4,2.9-1.4
  c0.7,0.7,2.3,1.3,2.6-0.2c0.1-1-1.3-1.7-0.7-2.8c1.2-2.3,5.5,2,5.4-1c0-0.9-1-1.7-0.9-2.6c0.2-1.9,3.3-0.6,4.1,0
  c0.8,0.6,1.4,1.6,1.5,2.7C147.3,243.5,145.6,244.4,143.7,244.4z"
              />
            </g>
            <g title="Plasma membrane" className="plasma_membrane_ton">
              <title>Plasma membrane</title>
              <g>
                <path
                  d="M360,483.4c-23.3,0-45.4-3.5-66.8-7
    c-7.1-1.1-13.8-2.2-20.6-3.2c-10.4-1.5-20.7-2.7-30.6-4c-34-4.3-66.2-8.3-99-18.4c-11.6-3.6-23.4-6.6-34.9-9.5
    c-31.1-7.8-57.9-14.6-71-30.1c-2.1-2.5-4.1-4.8-6-7c-15.1-17.4-23.4-27-24.5-60.2c-1.2-37.3,7.2-55.6,26.2-93.4
    c4.5-8.8,8.9-16.8,13.5-24.5c0.7-1.2,1.6-1.4,2.3-1.4c1.3,0,2.6,1,4,3c0.5,0.8,1.4,1.2,2.3,1.2c1.1,0,2.3-0.6,3.1-1.7
    c1-1.4,1.3-2.8,0.9-4c-0.3-0.9-1.1-1.5-2.1-1.8c-2.6-0.7-4.2-1.3-4.7-2.7c-0.5-1.2,0.3-2.3,0.5-2.7c4.2-6.3,8.3-12.1,12.6-18.3
    c6.7-9.5,13.6-19.2,21.8-32.4c7.4-11.8,12.5-20.7,17.1-28.5c8.7-15.1,15.6-27,32.9-49.9c26.2-34.7,45.5-44.2,74.1-50.4
    c8.5-1.8,17.6-4.4,27.3-7.1c22.4-6.3,47.9-13.4,77.1-15c12.8-0.7,24-0.7,36.7,0c1.7,0.1,2.7,0.5,3.3,1.2c0.7,0.9,0.5,1.8,0.4,2.7
    c0,0.2-0.1,0.4-0.1,0.7c-0.2,1.8,0.1,3,2.6,3.5c2.3,0.4,4,0.1,4.8-0.7c0.5-0.6,0.6-1.2,0.6-1.6c-0.1-1.7,0.2-2.9,1-3.7
    c0.4-0.4,1.3-1,2.6-0.9c3.9,0.3,7.8,0.7,12,1.1c15.1,1.4,32.3,3,56,4.1c87.9,3.9,119.3,19.3,124.5,60.8
    c5.2,41.9,6.9,51.4,15.5,76.7c3,8.7,4.8,14.8,6.2,20.2c0.4,1.7,0,2.8-0.5,3.4c-0.6,0.8-1.7,1.3-2.9,1.3c-0.5,0-1.1-0.1-1.7-0.3
    c-1.6-0.5-2.9-0.3-3.7,0.7c-0.9,1-1.2,2.7-0.9,4.7c0.2,1.7,1.3,4.4,3.2,4.4c0.8,0,1.9-0.5,3-1.4c1-0.9,2-1.3,3-1.3
    c1.8,0,3.1,1.4,3.4,3.9c1.1,8.4,1.8,18,2.7,33.9c0.8,13.8,4.1,25.6,7.7,38.1c5.8,20.3,11.8,41.4,8.5,74.7c-0.4,4-0.7,7.8-1.1,11.4
    c-4.3,46.4-6.2,67.5-46.5,79.6c-11,3.3-21.5,6.1-31.1,8.7c-1.6,0.4-3-0.2-3.8-1.6c-0.5-0.9-0.7-2.1-0.7-3.6c0-1.4-1.6-2.1-3.2-2.1
    c-0.7,0-1.3,0.1-2.1,0.3c-1.6,0.4-2.7,1.3-3.2,2.5c-0.3,0.7-0.2,1.5,0.2,2c1.1,1.6,1.4,3,1,4.2c-0.4,1-1.3,1.8-2.6,2.1
    c-24.4,6.5-49.3,13.6-84.1,27.1C405.9,479.5,384.2,483.4,360,483.4z M48.7,227.2c0,0-0.1,0.1-0.2,0.3c-4.6,7.6-9,15.5-13.4,24.3
    c-18.9,37.4-27.1,55.5-26,92.3c1,32.4,8.7,41.2,23.9,58.7c1.9,2.2,3.9,4.5,6,7c12.6,14.9,40.4,21.9,69.8,29.3
    c11.5,2.9,23.3,5.9,35,9.5c32.6,10,64.6,14.1,98.6,18.3c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.5,2,20.6,3.2
    c21.3,3.4,43.3,6.9,66.4,6.9c23.9,0,45.3-3.9,65.6-11.8c34.9-13.6,60.8-20.9,84.3-27.2c0.2-0.1,0.8-0.3,0.9-0.6
    c0.2-0.4-0.1-1.2-0.7-2c-0.8-1.2-1-2.7-0.4-4.2c0.7-1.9,2.5-3.4,4.8-4c0.9-0.2,1.8-0.4,2.7-0.4c3.3,0,5.6,1.8,5.6,4.5
    c0,1.4,0.2,2.1,0.4,2.5c0.2,0.4,0.4,0.5,0.6,0.5c0.1,0,0.3,0,0.4-0.1c9.6-2.6,20-5.4,31-8.7c38.7-11.6,40.6-32.2,44.8-77.5
    c0.3-3.6,0.7-7.5,1.1-11.4c3.2-32.9-2.7-53.7-8.5-73.8c-3.6-12.6-7-24.6-7.8-38.6c-0.9-15.9-1.6-25.4-2.7-33.7
    c-0.1-0.9-0.4-1.8-1-1.8c-0.2,0-0.7,0.1-1.4,0.7c-1.6,1.3-3.1,2-4.5,2c-3.3,0-5.2-3.4-5.6-6.5c-0.4-2.8,0.1-5.1,1.5-6.6
    c1.5-1.7,3.8-2.2,6.3-1.4c0.8,0.3,1.6,0.2,1.9-0.2c0.2-0.2,0.2-0.7,0.1-1.3c-1.3-5.3-3.2-11.3-6.1-20c-8.7-25.5-10.3-35-15.6-77.1
    c-2.6-20.9-11.9-34.1-30.3-42.9c-18.5-8.9-46.8-13.8-91.9-15.8c-23.8-1.1-41-2.7-56.1-4.1c-4.1-0.4-8.1-0.8-12-1.1
    c-0.5,0-0.6,0.1-0.7,0.1c-0.1,0.1-0.4,0.6-0.3,1.9c0.1,1.3-0.4,2.5-1.2,3.4c-0.7,0.8-2.1,1.7-4.6,1.7c-0.7,0-1.5-0.1-2.4-0.2
    c-3.4-0.6-5-2.7-4.6-6.1c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.1-0.9c0,0-0.3-0.2-1.6-0.2c-12.7-0.7-23.8-0.7-36.5,0
    c-29,1.6-54.3,8.7-76.6,14.9c-9.7,2.7-18.8,5.3-27.4,7.1c-28,6.1-46.9,15.4-72.7,49.5c-17.2,22.7-24.1,34.6-32.8,49.7
    c-4.5,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5c-4.3,6.1-8.4,11.9-12.6,18.2c-0.3,0.4-0.3,0.5-0.3,0.5
    c0.1,0,0.5,0.5,3,1.1c1.8,0.5,3.2,1.7,3.7,3.3c0.7,1.9,0.2,4.2-1.3,6.2c-1.3,1.7-3.1,2.7-5,2.7c-1.8,0-3.3-0.8-4.3-2.3
    C49.6,227.6,48.9,227.2,48.7,227.2z"
                />
              </g>
              <path
                d="M360,483.7c-23.3,0-45.5-3.5-66.8-7
  c-7.1-1.1-13.8-2.2-20.6-3.2c-10.4-1.5-20.7-2.7-30.6-4c-34.1-4.3-66.2-8.3-99-18.4c-11.6-3.6-23.4-6.6-34.9-9.5
  C77,433.8,50.1,427,37,411.5c-2.1-2.4-4-4.7-5.9-6.8l-0.1-0.1c-15.1-17.5-23.5-27.1-24.5-60.4c-1.2-37.4,7.2-55.7,26.3-93.6
  c4.5-8.8,8.9-16.9,13.5-24.5c0.8-1.3,1.8-1.6,2.5-1.6c1.4,0,2.7,1,4.2,3.1c0.5,0.7,1.2,1.1,2.1,1.1c1.1,0,2.1-0.6,2.9-1.6
  c0.9-1.3,1.3-2.6,0.9-3.7c-0.3-0.8-1-1.4-1.9-1.6c-2.7-0.7-4.3-1.4-4.8-2.8c-0.5-1.3,0.3-2.5,0.5-2.9c4.2-6.3,8.3-12.1,12.6-18.3
  c6.7-9.5,13.6-19.2,21.8-32.4c7.4-11.8,12.5-20.7,17-28.5c8.7-15.1,15.6-27.1,32.9-49.9c26.3-34.7,45.6-44.3,74.3-50.5
  c8.5-1.8,17.6-4.4,27.2-7.1c22.4-6.3,47.9-13.4,77.2-15C322,14.1,328,14,333.8,14c6,0,12.2,0.2,18.7,0.6c1.8,0.1,2.9,0.5,3.5,1.3
  c0.7,0.9,0.6,2,0.4,2.9c0,0.2-0.1,0.4-0.1,0.7c-0.2,1.8,0.1,2.8,2.4,3.2c2.1,0.4,3.8,0.1,4.6-0.7c0.5-0.5,0.5-1.1,0.5-1.4
  c-0.1-1.8,0.3-3.1,1.1-3.9c0.4-0.4,1.3-1.1,2.8-0.9c3.8,0.3,7.8,0.7,11.8,1.1l0.1,0c15.1,1.4,32.3,3,56,4.1
  c88,3.9,119.5,19.3,124.7,61c5.2,41.9,6.9,51.4,15.5,76.6c3,8.8,4.8,14.8,6.2,20.2c0.5,1.8,0,2.9-0.5,3.6c-0.7,0.9-1.8,1.4-3.1,1.4
  c-0.6,0-1.2-0.1-1.7-0.3c-1.5-0.5-2.7-0.3-3.5,0.6c-0.8,0.9-1.1,2.5-0.8,4.5c0.2,1.7,1.3,4.2,3,4.2c0.8,0,1.8-0.5,2.8-1.4
  c1.1-0.9,2.1-1.4,3.2-1.4c1.9,0,3.3,1.5,3.6,4.1c1.1,8.4,1.8,18,2.7,33.9c0.8,13.8,4.1,25.6,7.7,38.1c5.8,20.4,11.8,41.4,8.6,74.8
  c-0.4,3.8-0.7,7.4-1,10.9l0,0.5c-4.3,46.5-6.2,67.7-46.7,79.8c-11,3.3-21.5,6.1-31.1,8.7c-1.7,0.5-3.3-0.2-4.1-1.7
  c-0.5-0.9-0.7-2.2-0.7-3.7c0-1.2-1.5-1.8-3-1.8c-0.6,0-1.3,0.1-2,0.3c-1.5,0.4-2.6,1.2-3,2.4c-0.2,0.7-0.2,1.3,0.1,1.8
  c1.1,1.7,1.5,3.1,1,4.4c-0.4,1.1-1.4,1.9-2.7,2.3c-24.4,6.5-49.2,13.5-84,27.1C406,479.7,384.2,483.7,360,483.7z M48.7,225.1
  c-0.6,0-1.4,0.2-2.1,1.3C42,234,37.6,242,33.2,250.8c-19,37.8-27.4,56-26.2,93.3c1,33.1,9.3,42.7,24.4,60.1l0.1,0.1
  c1.9,2.2,3.8,4.4,5.9,6.8c13,15.4,39.8,22.2,70.9,30c11.4,2.9,23.3,5.9,34.9,9.5c32.8,10.1,64.9,14.1,99,18.4
  c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.4,2,20.6,3.2c21.4,3.4,43.4,7,66.8,7c24.1,0,45.8-3.9,66.4-11.9
  c34.8-13.6,59.7-20.6,84.1-27.2c1.2-0.3,2-1,2.4-2c0.4-1.1,0.1-2.5-0.9-4c-0.4-0.6-0.5-1.4-0.2-2.2c0.5-1.3,1.7-2.2,3.3-2.7
  c0.7-0.2,1.5-0.3,2.1-0.3c1.7,0,3.5,0.7,3.5,2.3c0,1.5,0.2,2.7,0.7,3.5c0.7,1.3,2,1.9,3.5,1.5c9.6-2.6,20.1-5.4,31.1-8.7
  c40.1-12,42.1-33.1,46.3-79.4l0-0.5c0.3-3.5,0.7-7.1,1-10.9c3.2-33.3-2.8-54.3-8.5-74.6c-3.6-12.5-6.9-24.3-7.7-38.2
  c-0.9-15.9-1.6-25.5-2.7-33.9c-0.3-2.3-1.5-3.6-3.1-3.6c-0.9,0-1.9,0.4-2.8,1.2c-1.1,1-2.2,1.5-3.1,1.5c-2.1,0-3.3-2.8-3.5-4.6
  c-0.3-2.1,0.1-3.9,1-4.9c0.9-1,2.3-1.3,4-0.7c0.5,0.2,1.1,0.3,1.6,0.3c1.1,0,2.1-0.4,2.7-1.2c0.4-0.6,0.9-1.6,0.4-3.2
  c-1.4-5.4-3.2-11.4-6.2-20.1c-8.6-25.3-10.2-34.7-15.5-76.7c-5.2-41.4-36.5-56.7-124.2-60.6c-23.7-1.1-40.9-2.7-56-4.1l-0.1,0
  c-4.1-0.4-8-0.8-11.8-1.1c-1.3-0.1-2,0.4-2.4,0.8c-0.7,0.7-1,1.9-0.9,3.5c0,0.4,0,1.2-0.6,1.8c-0.9,0.9-2.7,1.2-5,0.8
  c-2.7-0.5-3.1-1.8-2.8-3.7c0-0.2,0.1-0.5,0.1-0.7c0.1-0.9,0.3-1.8-0.3-2.5c-0.5-0.7-1.5-1-3.1-1.1c-6.5-0.4-12.6-0.6-18.7-0.6
  c-5.8,0-11.7,0.2-18,0.5c-29.2,1.6-54.6,8.7-77.1,15c-9.6,2.7-18.8,5.2-27.3,7.1c-28.5,6.2-47.8,15.6-74,50.3
  c-17.3,22.8-24.2,34.8-32.9,49.9c-4.5,7.8-9.7,16.7-17.1,28.5c-8.2,13.2-15.2,23-21.9,32.4c-4.3,6.1-8.4,11.9-12.6,18.3
  c-0.2,0.3-0.9,1.4-0.5,2.4c0.5,1.2,2,1.8,4.5,2.5c1.1,0.3,1.9,1,2.3,2c0.4,1.2,0.1,2.8-1,4.2c-0.9,1.2-2.1,1.8-3.3,1.8
  c-1,0-2-0.5-2.5-1.3C51.2,226,49.9,225.1,48.7,225.1z M360,481.3c-23.2,0-45.2-3.5-66.5-6.9c-7.1-1.1-13.8-2.2-20.6-3.2
  c-10.4-1.5-20.6-2.7-30.6-4l-0.1,0c-33.9-4.3-66-8.3-98.6-18.3c-11.7-3.6-23.5-6.6-35-9.5c-29.4-7.4-57.2-14.5-69.9-29.4
  c-2.1-2.5-4.2-4.9-6-7c-15.3-17.6-22.9-26.4-23.9-58.9c-1.2-36.8,7.1-54.9,26-92.4c4.4-8.8,8.8-16.7,13.4-24.3
  c0.2-0.3,0.2-0.4,0.4-0.4c0.3,0,1.1,0.5,2.2,2.1c0.9,1.4,2.4,2.1,4.1,2.1c1.8,0,3.6-0.9,4.8-2.6c1.4-1.9,1.9-4.1,1.2-5.9
  c-0.5-1.6-1.8-2.7-3.6-3.2c-1.6-0.4-2.7-0.8-3.1-1.2l-0.1-0.1l0-0.1c0-0.1,0-0.3,0.3-0.7c4.1-6.3,8.2-12.1,12.6-18.2
  c6.7-9.5,13.7-19.3,21.9-32.5c7.4-11.8,12.5-20.7,17.1-28.6c8.7-15.1,15.6-26.9,32.8-49.7c25.8-34.1,44.8-43.5,72.9-49.6
  c8.6-1.9,17.7-4.4,27.4-7.1c22.3-6.2,47.6-13.3,76.7-14.9c6.3-0.4,12.2-0.5,17.9-0.5c6,0,12.1,0.2,18.6,0.6c0.6,0,1.1,0.1,1.5,0.2
  l0.3,0.2c0,0.2,0,0.5-0.1,1c0,0.3-0.1,0.5-0.1,0.8c-0.4,3.3,1,5.2,4.4,5.8c0.8,0.2,1.6,0.2,2.3,0.2c2.4,0,3.7-0.9,4.4-1.6
  c0.8-0.8,1.2-2,1.2-3.2c-0.1-1.3,0.2-1.9,0.4-2.1c0.1-0.1,0.3-0.3,0.9-0.2c3.9,0.3,7.8,0.7,11.9,1.1l0.1,0
  c15.2,1.4,32.3,3,56.1,4.1c45.1,2,73.5,6.9,92,15.8C546,48,555.4,61.3,558,82.2c5.3,42.1,6.9,51.7,15.6,77.1c3,8.7,4.8,14.7,6.1,20
  c0.2,0.7,0.1,1.2-0.1,1.5c-0.2,0.3-0.7,0.4-1.2,0.4c-0.3,0-0.7-0.1-1-0.2c-0.7-0.2-1.5-0.4-2.2-0.4c-1.5,0-2.9,0.6-3.8,1.7
  c-1.3,1.5-1.8,3.7-1.4,6.4c0.4,3,2.3,6.3,5.4,6.3c1.4,0,2.8-0.7,4.4-1.9c0.8-0.7,1.4-0.8,1.6-0.8c0.6,0,1.1,0.7,1.2,2
  c1.1,8.3,1.8,17.9,2.7,33.8c0.8,14,4.2,26,7.8,38.6c5.7,20.1,11.7,41,8.5,73.9c-0.4,3.8-0.7,7.5-1,11l0,0.5
  c-4.2,45.4-6.1,66.1-45,77.7c-11.1,3.3-21.5,6.1-31.1,8.7c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.6c-0.2-0.4-0.5-1.2-0.5-2.6
  c0-2.5-2.2-4.2-5.4-4.2c-0.8,0-1.7,0.1-2.6,0.4c-2.3,0.6-4,2-4.6,3.8c-0.5,1.4-0.4,2.8,0.4,4c0.6,0.9,0.9,1.7,0.7,2.2
  c-0.2,0.5-0.8,0.7-1.1,0.8c-23.5,6.3-49.4,13.6-84.3,27.2C405.4,477.4,383.9,481.3,360,481.3z M48.8,227.5c0,0-0.1,0.1-0.1,0.1
  c-4.6,7.6-9,15.5-13.4,24.3c-18.8,37.4-27.1,55.5-26,92.2c1,32.3,8.6,41.1,23.8,58.6c1.8,2.1,3.9,4.5,6,7
  c12.5,14.8,40.3,21.8,69.6,29.2c11.5,2.9,23.3,5.9,35,9.5c32.6,10,64.6,14.1,98.5,18.3l0.1,0c9.9,1.2,20.2,2.5,30.6,4
  c6.8,1,13.5,2,20.6,3.2c21.3,3.4,43.3,6.9,66.4,6.9c23.8,0,45.3-3.8,65.5-11.8c34.9-13.6,60.8-20.9,84.3-27.2
  c0.3-0.1,0.7-0.3,0.8-0.5c0.1-0.3,0-0.9-0.7-1.8c-0.9-1.3-1-2.9-0.5-4.4c0.7-2,2.6-3.5,5-4.1c0.9-0.2,1.9-0.4,2.7-0.4
  c3.5,0,5.9,1.9,5.9,4.7c0,1.3,0.2,2,0.4,2.4c0.2,0.3,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.3-0.1c9.6-2.6,20-5.4,31-8.7
  c38.6-11.5,40.5-32.1,44.6-77.3l0-0.5c0.3-3.5,0.7-7.2,1-11c3.2-32.8-2.7-53.6-8.5-73.7c-3.6-12.7-7-24.6-7.8-38.7
  c-0.9-15.8-1.6-25.4-2.7-33.7c-0.1-0.9-0.4-1.6-0.8-1.6c-0.1,0-0.5,0-1.3,0.7c-1.6,1.4-3.2,2.1-4.7,2.1c-3.4,0-5.4-3.5-5.9-6.7
  c-0.4-2.9,0.2-5.2,1.5-6.8c1.5-1.7,4-2.3,6.5-1.4c0.7,0.2,1.4,0.1,1.6-0.1c0.1-0.2,0.1-0.6,0-1.1c-1.3-5.3-3.2-11.3-6.1-20
  c-8.7-25.5-10.3-35-15.6-77.2c-2.6-20.8-11.9-33.9-30.2-42.8c-18.4-8.9-46.7-13.8-91.8-15.8c-23.8-1.1-41-2.7-56.1-4.1l-0.1,0
  c-4.1-0.4-8.1-0.8-11.9-1.1c-0.3,0-0.5,0.1-0.5,0.1c0,0-0.3,0.4-0.3,1.7c0.1,1.3-0.4,2.6-1.3,3.5c-0.8,0.8-2.2,1.7-4.8,1.7
  c-0.7,0-1.6-0.1-2.4-0.2c-3.6-0.7-5.2-2.8-4.8-6.4c0-0.3,0.1-0.5,0.1-0.8c0-0.2,0.1-0.5,0.1-0.6c-0.3-0.1-0.8-0.2-1.3-0.2
  c-6.5-0.4-12.5-0.6-18.5-0.6c-5.8,0-11.6,0.2-17.9,0.5c-29,1.6-54.2,8.7-76.5,14.9c-9.7,2.7-18.8,5.3-27.4,7.1
  c-27.9,6-46.8,15.3-72.6,49.4c-17.2,22.7-24,34.6-32.7,49.6c-4.6,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5
  c-4.3,6.1-8.4,11.9-12.6,18.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.3,0.2,1,0.5,2.8,1c1.9,0.5,3.3,1.8,3.9,3.5c0.7,2,0.2,4.3-1.3,6.4
  c-1.3,1.8-3.2,2.8-5.2,2.8c-1.8,0-3.5-0.9-4.5-2.4C49.6,228,49,227.6,48.8,227.5z"
              />
            </g>
            <g
              title="Plasma membrane"
              className={locations.includes('Plasma membrane') || locations.includes('Cell junctions') ? 'plasma_membrane' : 'plasma_membrane--disabled'}
            >
              <title>Plasma membrane</title>
              <g>
                <path
                  d="M360,483.4c-23.3,0-45.4-3.5-66.8-7c-7.1-1.1-13.8-2.2-20.6-3.2c-10.4-1.5-20.7-2.7-30.6-4
    c-34-4.3-66.2-8.3-99-18.4c-11.6-3.6-23.4-6.6-34.9-9.5c-31.1-7.8-57.9-14.6-71-30.1c-2.1-2.5-4.1-4.8-6-7
    c-15.1-17.4-23.4-27-24.5-60.2c-1.2-37.3,7.2-55.6,26.2-93.4c4.5-8.8,8.9-16.8,13.5-24.5c0.7-1.2,1.6-1.4,2.3-1.4c1.3,0,2.6,1,4,3
    c0.5,0.8,1.4,1.2,2.3,1.2c1.1,0,2.3-0.6,3.1-1.7c1-1.4,1.3-2.8,0.9-4c-0.3-0.9-1.1-1.5-2.1-1.8c-2.6-0.7-4.2-1.3-4.7-2.7
    c-0.5-1.2,0.3-2.3,0.5-2.7c4.2-6.3,8.3-12.1,12.6-18.3c6.7-9.5,13.6-19.2,21.8-32.4c7.4-11.8,12.5-20.7,17.1-28.5
    c8.7-15.1,15.6-27,32.9-49.9c26.2-34.7,45.5-44.2,74.1-50.4c8.5-1.8,17.6-4.4,27.3-7.1c22.4-6.3,47.9-13.4,77.1-15
    c12.8-0.7,24-0.7,36.7,0c1.7,0.1,2.7,0.5,3.3,1.2c0.7,0.9,0.5,1.8,0.4,2.7c0,0.2-0.1,0.4-0.1,0.7c-0.2,1.8,0.1,3,2.6,3.5
    c2.3,0.4,4,0.1,4.8-0.7c0.5-0.6,0.6-1.2,0.6-1.6c-0.1-1.7,0.2-2.9,1-3.7c0.4-0.4,1.3-1,2.6-0.9c3.9,0.3,7.8,0.7,12,1.1
    c15.1,1.4,32.3,3,56,4.1c87.9,3.9,119.3,19.3,124.5,60.8c5.2,41.9,6.9,51.4,15.5,76.7c3,8.7,4.8,14.8,6.2,20.2
    c0.4,1.7,0,2.8-0.5,3.4c-0.6,0.8-1.7,1.3-2.9,1.3c-0.5,0-1.1-0.1-1.7-0.3c-1.6-0.5-2.9-0.3-3.7,0.7c-0.9,1-1.2,2.7-0.9,4.7
    c0.2,1.7,1.3,4.4,3.2,4.4c0.8,0,1.9-0.5,3-1.4c1-0.9,2-1.3,3-1.3c1.8,0,3.1,1.4,3.4,3.9c1.1,8.4,1.8,18,2.7,33.9
    c0.8,13.8,4.1,25.6,7.7,38.1c5.8,20.3,11.8,41.4,8.5,74.7c-0.4,4-0.7,7.8-1.1,11.4c-4.3,46.4-6.2,67.5-46.5,79.6
    c-11,3.3-21.5,6.1-31.1,8.7c-1.6,0.4-3-0.2-3.8-1.6c-0.5-0.9-0.7-2.1-0.7-3.6c0-1.4-1.6-2.1-3.2-2.1c-0.7,0-1.3,0.1-2.1,0.3
    c-1.6,0.4-2.7,1.3-3.2,2.5c-0.3,0.7-0.2,1.5,0.2,2c1.1,1.6,1.4,3,1,4.2c-0.4,1-1.3,1.8-2.6,2.1c-24.4,6.5-49.3,13.6-84.1,27.1
    C405.9,479.5,384.2,483.4,360,483.4z M48.7,227.2c0,0-0.1,0.1-0.2,0.3c-4.6,7.6-9,15.5-13.4,24.3c-18.9,37.4-27.1,55.5-26,92.3
    c1,32.4,8.7,41.2,23.9,58.7c1.9,2.2,3.9,4.5,6,7c12.6,14.9,40.4,21.9,69.8,29.3c11.5,2.9,23.3,5.9,35,9.5
    c32.6,10,64.6,14.1,98.6,18.3c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.5,2,20.6,3.2c21.3,3.4,43.3,6.9,66.4,6.9
    c23.9,0,45.3-3.9,65.6-11.8c34.9-13.6,60.8-20.9,84.3-27.2c0.2-0.1,0.8-0.3,0.9-0.6c0.2-0.4-0.1-1.2-0.7-2c-0.8-1.2-1-2.7-0.4-4.2
    c0.7-1.9,2.5-3.4,4.8-4c0.9-0.2,1.8-0.4,2.7-0.4c3.3,0,5.6,1.8,5.6,4.5c0,1.4,0.2,2.1,0.4,2.5c0.2,0.4,0.4,0.5,0.6,0.5
    c0.1,0,0.3,0,0.4-0.1c9.6-2.6,20-5.4,31-8.7c38.7-11.6,40.6-32.2,44.8-77.5c0.3-3.6,0.7-7.5,1.1-11.4c3.2-32.9-2.7-53.7-8.5-73.8
    c-3.6-12.6-7-24.6-7.8-38.6c-0.9-15.9-1.6-25.4-2.7-33.7c-0.1-0.9-0.4-1.8-1-1.8c-0.2,0-0.7,0.1-1.4,0.7c-1.6,1.3-3.1,2-4.5,2
    c-3.3,0-5.2-3.4-5.6-6.5c-0.4-2.8,0.1-5.1,1.5-6.6c1.5-1.7,3.8-2.2,6.3-1.4c0.8,0.3,1.6,0.2,1.9-0.2c0.2-0.2,0.2-0.7,0.1-1.3
    c-1.3-5.3-3.2-11.3-6.1-20c-8.7-25.5-10.3-35-15.6-77.1c-2.6-20.9-11.9-34.1-30.3-42.9c-18.5-8.9-46.8-13.8-91.9-15.8
    c-23.8-1.1-41-2.7-56.1-4.1c-4.1-0.4-8.1-0.8-12-1.1c-0.5,0-0.6,0.1-0.7,0.1c-0.1,0.1-0.4,0.6-0.3,1.9c0.1,1.3-0.4,2.5-1.2,3.4
    c-0.7,0.8-2.1,1.7-4.6,1.7c-0.7,0-1.5-0.1-2.4-0.2c-3.4-0.6-5-2.7-4.6-6.1c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.1-0.9
    c0,0-0.3-0.2-1.6-0.2c-12.7-0.7-23.8-0.7-36.5,0c-29,1.6-54.3,8.7-76.6,14.9c-9.7,2.7-18.8,5.3-27.4,7.1
    c-28,6.1-46.9,15.4-72.7,49.5c-17.2,22.7-24.1,34.6-32.8,49.7c-4.5,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5
    c-4.3,6.1-8.4,11.9-12.6,18.2c-0.3,0.4-0.3,0.5-0.3,0.5c0.1,0,0.5,0.5,3,1.1c1.8,0.5,3.2,1.7,3.7,3.3c0.7,1.9,0.2,4.2-1.3,6.2
    c-1.3,1.7-3.1,2.7-5,2.7c-1.8,0-3.3-0.8-4.3-2.3C49.6,227.6,48.9,227.2,48.7,227.2z"
                />
              </g>
              <path
                d="M360,483.7
  c-23.3,0-45.5-3.5-66.8-7c-7.1-1.1-13.8-2.2-20.6-3.2c-10.4-1.5-20.7-2.7-30.6-4c-34.1-4.3-66.2-8.3-99-18.4
  c-11.6-3.6-23.4-6.6-34.9-9.5C77,433.8,50.1,427,37,411.5c-2.1-2.4-4-4.7-5.9-6.8l-0.1-0.1c-15.1-17.5-23.5-27.1-24.5-60.4
  c-1.2-37.4,7.2-55.7,26.3-93.6c4.5-8.8,8.9-16.9,13.5-24.5c0.8-1.3,1.8-1.6,2.5-1.6c1.4,0,2.7,1,4.2,3.1c0.5,0.7,1.2,1.1,2.1,1.1
  c1.1,0,2.1-0.6,2.9-1.6c0.9-1.3,1.3-2.6,0.9-3.7c-0.3-0.8-1-1.4-1.9-1.6c-2.7-0.7-4.3-1.4-4.8-2.8c-0.5-1.3,0.3-2.5,0.5-2.9
  c4.2-6.3,8.3-12.1,12.6-18.3c6.7-9.5,13.6-19.2,21.8-32.4c7.4-11.8,12.5-20.7,17-28.5c8.7-15.1,15.6-27.1,32.9-49.9
  c26.3-34.7,45.6-44.3,74.3-50.5c8.5-1.8,17.6-4.4,27.2-7.1c22.4-6.3,47.9-13.4,77.2-15C322,14.1,328,14,333.8,14
  c6,0,12.2,0.2,18.7,0.6c1.8,0.1,2.9,0.5,3.5,1.3c0.7,0.9,0.6,2,0.4,2.9c0,0.2-0.1,0.4-0.1,0.7c-0.2,1.8,0.1,2.8,2.4,3.2
  c2.1,0.4,3.8,0.1,4.6-0.7c0.5-0.5,0.5-1.1,0.5-1.4c-0.1-1.8,0.3-3.1,1.1-3.9c0.4-0.4,1.3-1.1,2.8-0.9c3.8,0.3,7.8,0.7,11.8,1.1
  l0.1,0c15.1,1.4,32.3,3,56,4.1c88,3.9,119.5,19.3,124.7,61c5.2,41.9,6.9,51.4,15.5,76.6c3,8.8,4.8,14.8,6.2,20.2
  c0.5,1.8,0,2.9-0.5,3.6c-0.7,0.9-1.8,1.4-3.1,1.4c-0.6,0-1.2-0.1-1.7-0.3c-1.5-0.5-2.7-0.3-3.5,0.6c-0.8,0.9-1.1,2.5-0.8,4.5
  c0.2,1.7,1.3,4.2,3,4.2c0.8,0,1.8-0.5,2.8-1.4c1.1-0.9,2.1-1.4,3.2-1.4c1.9,0,3.3,1.5,3.6,4.1c1.1,8.4,1.8,18,2.7,33.9
  c0.8,13.8,4.1,25.6,7.7,38.1c5.8,20.4,11.8,41.4,8.6,74.8c-0.4,3.8-0.7,7.4-1,10.9l0,0.5c-4.3,46.5-6.2,67.7-46.7,79.8
  c-11,3.3-21.5,6.1-31.1,8.7c-1.7,0.5-3.3-0.2-4.1-1.7c-0.5-0.9-0.7-2.2-0.7-3.7c0-1.2-1.5-1.8-3-1.8c-0.6,0-1.3,0.1-2,0.3
  c-1.5,0.4-2.6,1.2-3,2.4c-0.2,0.7-0.2,1.3,0.1,1.8c1.1,1.7,1.5,3.1,1,4.4c-0.4,1.1-1.4,1.9-2.7,2.3c-24.4,6.5-49.2,13.5-84,27.1
  C406,479.7,384.2,483.7,360,483.7z M48.7,225.1c-0.6,0-1.4,0.2-2.1,1.3C42,234,37.6,242,33.2,250.8c-19,37.8-27.4,56-26.2,93.3
  c1,33.1,9.3,42.7,24.4,60.1l0.1,0.1c1.9,2.2,3.8,4.4,5.9,6.8c13,15.4,39.8,22.2,70.9,30c11.4,2.9,23.3,5.9,34.9,9.5
  c32.8,10.1,64.9,14.1,99,18.4c9.9,1.2,20.2,2.5,30.6,4c6.7,0.9,13.4,2,20.6,3.2c21.4,3.4,43.4,7,66.8,7c24.1,0,45.8-3.9,66.4-11.9
  c34.8-13.6,59.7-20.6,84.1-27.2c1.2-0.3,2-1,2.4-2c0.4-1.1,0.1-2.5-0.9-4c-0.4-0.6-0.5-1.4-0.2-2.2c0.5-1.3,1.7-2.2,3.3-2.7
  c0.7-0.2,1.5-0.3,2.1-0.3c1.7,0,3.5,0.7,3.5,2.3c0,1.5,0.2,2.7,0.7,3.5c0.7,1.3,2,1.9,3.5,1.5c9.6-2.6,20.1-5.4,31.1-8.7
  c40.1-12,42.1-33.1,46.3-79.4l0-0.5c0.3-3.5,0.7-7.1,1-10.9c3.2-33.3-2.8-54.3-8.5-74.6c-3.6-12.5-6.9-24.3-7.7-38.2
  c-0.9-15.9-1.6-25.5-2.7-33.9c-0.3-2.3-1.5-3.6-3.1-3.6c-0.9,0-1.9,0.4-2.8,1.2c-1.1,1-2.2,1.5-3.1,1.5c-2.1,0-3.3-2.8-3.5-4.6
  c-0.3-2.1,0.1-3.9,1-4.9c0.9-1,2.3-1.3,4-0.7c0.5,0.2,1.1,0.3,1.6,0.3c1.1,0,2.1-0.4,2.7-1.2c0.4-0.6,0.9-1.6,0.4-3.2
  c-1.4-5.4-3.2-11.4-6.2-20.1c-8.6-25.3-10.2-34.7-15.5-76.7c-5.2-41.4-36.5-56.7-124.2-60.6c-23.7-1.1-40.9-2.7-56-4.1l-0.1,0
  c-4.1-0.4-8-0.8-11.8-1.1c-1.3-0.1-2,0.4-2.4,0.8c-0.7,0.7-1,1.9-0.9,3.5c0,0.4,0,1.2-0.6,1.8c-0.9,0.9-2.7,1.2-5,0.8
  c-2.7-0.5-3.1-1.8-2.8-3.7c0-0.2,0.1-0.5,0.1-0.7c0.1-0.9,0.3-1.8-0.3-2.5c-0.5-0.7-1.5-1-3.1-1.1c-6.5-0.4-12.6-0.6-18.7-0.6
  c-5.8,0-11.7,0.2-18,0.5c-29.2,1.6-54.6,8.7-77.1,15c-9.6,2.7-18.8,5.2-27.3,7.1c-28.5,6.2-47.8,15.6-74,50.3
  c-17.3,22.8-24.2,34.8-32.9,49.9c-4.5,7.8-9.7,16.7-17.1,28.5c-8.2,13.2-15.2,23-21.9,32.4c-4.3,6.1-8.4,11.9-12.6,18.3
  c-0.2,0.3-0.9,1.4-0.5,2.4c0.5,1.2,2,1.8,4.5,2.5c1.1,0.3,1.9,1,2.3,2c0.4,1.2,0.1,2.8-1,4.2c-0.9,1.2-2.1,1.8-3.3,1.8
  c-1,0-2-0.5-2.5-1.3C51.2,226,49.9,225.1,48.7,225.1z M360,481.3c-23.2,0-45.2-3.5-66.5-6.9c-7.1-1.1-13.8-2.2-20.6-3.2
  c-10.4-1.5-20.6-2.7-30.6-4l-0.1,0c-33.9-4.3-66-8.3-98.6-18.3c-11.7-3.6-23.5-6.6-35-9.5c-29.4-7.4-57.2-14.5-69.9-29.4
  c-2.1-2.5-4.2-4.9-6-7c-15.3-17.6-22.9-26.4-23.9-58.9c-1.2-36.8,7.1-54.9,26-92.4c4.4-8.8,8.8-16.7,13.4-24.3
  c0.2-0.3,0.2-0.4,0.4-0.4c0.3,0,1.1,0.5,2.2,2.1c0.9,1.4,2.4,2.1,4.1,2.1c1.8,0,3.6-0.9,4.8-2.6c1.4-1.9,1.9-4.1,1.2-5.9
  c-0.5-1.6-1.8-2.7-3.6-3.2c-1.6-0.4-2.7-0.8-3.1-1.2l-0.1-0.1l0-0.1c0-0.1,0-0.3,0.3-0.7c4.1-6.3,8.2-12.1,12.6-18.2
  c6.7-9.5,13.7-19.3,21.9-32.5c7.4-11.8,12.5-20.7,17.1-28.6c8.7-15.1,15.6-26.9,32.8-49.7c25.8-34.1,44.8-43.5,72.9-49.6
  c8.6-1.9,17.7-4.4,27.4-7.1c22.3-6.2,47.6-13.3,76.7-14.9c6.3-0.4,12.2-0.5,17.9-0.5c6,0,12.1,0.2,18.6,0.6c0.6,0,1.1,0.1,1.5,0.2
  l0.3,0.2c0,0.2,0,0.5-0.1,1c0,0.3-0.1,0.5-0.1,0.8c-0.4,3.3,1,5.2,4.4,5.8c0.8,0.2,1.6,0.2,2.3,0.2c2.4,0,3.7-0.9,4.4-1.6
  c0.8-0.8,1.2-2,1.2-3.2c-0.1-1.3,0.2-1.9,0.4-2.1c0.1-0.1,0.3-0.3,0.9-0.2c3.9,0.3,7.8,0.7,11.9,1.1l0.1,0
  c15.2,1.4,32.3,3,56.1,4.1c45.1,2,73.5,6.9,92,15.8C546,48,555.4,61.3,558,82.2c5.3,42.1,6.9,51.7,15.6,77.1c3,8.7,4.8,14.7,6.1,20
  c0.2,0.7,0.1,1.2-0.1,1.5c-0.2,0.3-0.7,0.4-1.2,0.4c-0.3,0-0.7-0.1-1-0.2c-0.7-0.2-1.5-0.4-2.2-0.4c-1.5,0-2.9,0.6-3.8,1.7
  c-1.3,1.5-1.8,3.7-1.4,6.4c0.4,3,2.3,6.3,5.4,6.3c1.4,0,2.8-0.7,4.4-1.9c0.8-0.7,1.4-0.8,1.6-0.8c0.6,0,1.1,0.7,1.2,2
  c1.1,8.3,1.8,17.9,2.7,33.8c0.8,14,4.2,26,7.8,38.6c5.7,20.1,11.7,41,8.5,73.9c-0.4,3.8-0.7,7.5-1,11l0,0.5
  c-4.2,45.4-6.1,66.1-45,77.7c-11.1,3.3-21.5,6.1-31.1,8.7c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.6c-0.2-0.4-0.5-1.2-0.5-2.6
  c0-2.5-2.2-4.2-5.4-4.2c-0.8,0-1.7,0.1-2.6,0.4c-2.3,0.6-4,2-4.6,3.8c-0.5,1.4-0.4,2.8,0.4,4c0.6,0.9,0.9,1.7,0.7,2.2
  c-0.2,0.5-0.8,0.7-1.1,0.8c-23.5,6.3-49.4,13.6-84.3,27.2C405.4,477.4,383.9,481.3,360,481.3z M48.8,227.5c0,0-0.1,0.1-0.1,0.1
  c-4.6,7.6-9,15.5-13.4,24.3c-18.8,37.4-27.1,55.5-26,92.2c1,32.3,8.6,41.1,23.8,58.6c1.8,2.1,3.9,4.5,6,7
  c12.5,14.8,40.3,21.8,69.6,29.2c11.5,2.9,23.3,5.9,35,9.5c32.6,10,64.6,14.1,98.5,18.3l0.1,0c9.9,1.2,20.2,2.5,30.6,4
  c6.8,1,13.5,2,20.6,3.2c21.3,3.4,43.3,6.9,66.4,6.9c23.8,0,45.3-3.8,65.5-11.8c34.9-13.6,60.8-20.9,84.3-27.2
  c0.3-0.1,0.7-0.3,0.8-0.5c0.1-0.3,0-0.9-0.7-1.8c-0.9-1.3-1-2.9-0.5-4.4c0.7-2,2.6-3.5,5-4.1c0.9-0.2,1.9-0.4,2.7-0.4
  c3.5,0,5.9,1.9,5.9,4.7c0,1.3,0.2,2,0.4,2.4c0.2,0.3,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.3-0.1c9.6-2.6,20-5.4,31-8.7
  c38.6-11.5,40.5-32.1,44.6-77.3l0-0.5c0.3-3.5,0.7-7.2,1-11c3.2-32.8-2.7-53.6-8.5-73.7c-3.6-12.7-7-24.6-7.8-38.7
  c-0.9-15.8-1.6-25.4-2.7-33.7c-0.1-0.9-0.4-1.6-0.8-1.6c-0.1,0-0.5,0-1.3,0.7c-1.6,1.4-3.2,2.1-4.7,2.1c-3.4,0-5.4-3.5-5.9-6.7
  c-0.4-2.9,0.2-5.2,1.5-6.8c1.5-1.7,4-2.3,6.5-1.4c0.7,0.2,1.4,0.1,1.6-0.1c0.1-0.2,0.1-0.6,0-1.1c-1.3-5.3-3.2-11.3-6.1-20
  c-8.7-25.5-10.3-35-15.6-77.2c-2.6-20.8-11.9-33.9-30.2-42.8c-18.4-8.9-46.7-13.8-91.8-15.8c-23.8-1.1-41-2.7-56.1-4.1l-0.1,0
  c-4.1-0.4-8.1-0.8-11.9-1.1c-0.3,0-0.5,0.1-0.5,0.1c0,0-0.3,0.4-0.3,1.7c0.1,1.3-0.4,2.6-1.3,3.5c-0.8,0.8-2.2,1.7-4.8,1.7
  c-0.7,0-1.6-0.1-2.4-0.2c-3.6-0.7-5.2-2.8-4.8-6.4c0-0.3,0.1-0.5,0.1-0.8c0-0.2,0.1-0.5,0.1-0.6c-0.3-0.1-0.8-0.2-1.3-0.2
  c-6.5-0.4-12.5-0.6-18.5-0.6c-5.8,0-11.6,0.2-17.9,0.5c-29,1.6-54.2,8.7-76.5,14.9c-9.7,2.7-18.8,5.3-27.4,7.1
  c-27.9,6-46.8,15.3-72.6,49.4c-17.2,22.7-24,34.6-32.7,49.6c-4.6,7.9-9.7,16.8-17.1,28.6c-8.3,13.2-15.2,23-21.9,32.5
  c-4.3,6.1-8.4,11.9-12.6,18.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.3,0.2,1,0.5,2.8,1c1.9,0.5,3.3,1.8,3.9,3.5c0.7,2,0.2,4.3-1.3,6.4
  c-1.3,1.8-3.2,2.8-5.2,2.8c-1.8,0-3.5-0.9-4.5-2.4C49.6,228,49,227.6,48.8,227.5z"
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

InteractiveCellPic.propTypes = propTypes;

export default InteractiveCellPic;
