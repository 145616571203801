import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import NoData from '../../common/NoData/NoData';
import Pagination from '../../common/Pagination/Pagination';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ExportTable from '../../common/ExportTable/ExportTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import SearchWithSetsCooccurrenceSelect from './Components/SearchWIthSetsCooccurrenceSelect/SearchWithSetsCooccurrenceSelect';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
import { defaultPublicationsPopupState } from './constants';
import { cooccurrenceTypesOptions } from '../CreateSet/enums';
// Utils
import { getSearchWithSetsRequestData } from './utils';
// Store
import * as ACTIONS from './store/actions';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  sort: PropTypes.instanceOf(Object),
  pages: PropTypes.instanceOf(Object),
  getSearchWithSetsData: PropTypes.func,
  sortSearchWithSetsData: PropTypes.func,
  resetSearchWithSetsPage: PropTypes.func,
  changeSearchWithSetsPage: PropTypes.func,
  exportSearchWithSetsData: PropTypes.func,
  updateCooccurrenceType: PropTypes.func,
  cooccurrenceType: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const SearchWithSetsPage = (props) => {
  const {
    data,
    error,
    sort,
    pages,
    loading,
    cooccurrenceType,
    updateCooccurrenceType,
    getSearchWithSetsData,
    sortSearchWithSetsData,
    resetSearchWithSetsPage,
    changeSearchWithSetsPage,
    exportSearchWithSetsData,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, sets, longTimeRequestDialog } = location.state || {};

  const tableSettings = {
    ...sort,
    width: 1000,
    rowHeight: 50,
    headerHeight: 50,
  };

  const tableRef = useRef();
  const tableDownloadRef = useRef();
  const [showLongTimeRequestDialog, setShowLongTimeRequestDialog] = useState(longTimeRequestDialog);
  const [publicationsPopup, setPublicationsPopup] = useState(defaultPublicationsPopupState);

  useEffect(() => {
    if (projectId && sets) {
      const requestData = getSearchWithSetsRequestData(projectId, sets, cooccurrenceType, sort);
      getSearchWithSetsData(requestData);
    } else {
      navigate(`${RELATIVE_PATH}/sets`);
    }
  }, [projectId, sets, sort, cooccurrenceType, getSearchWithSetsData, navigate]);

  useEffect(() => resetSearchWithSetsPage, []);

  function openPublicationsPopup(concepts) {
    const { conceptsNames, conceptsIds } = Object.values(concepts)
      .reduce((acc, item) => {
        acc.conceptsIds.push(item.concept.id);
        acc.conceptsNames.push(item.concept.name);
        return acc;
      }, {
        conceptsNames: [],
        conceptsIds: [],
      });

    setPublicationsPopup({
      show: true,
      conceptsNames,
      conceptsIds,
    });
  }

  function handlePageSort(d) {
    sortSearchWithSetsData({
      sortDirection: d.sortDirection,
      sortBy: d.sortPath ? d.sortPath[d.sortPath.length - 1] : d.sortBy,
    });
  }

  function handleOnPageChange({ selected }) {
    changeSearchWithSetsPage(selected);
  }

  function handlePageExport() {
    const requestData = getSearchWithSetsRequestData(projectId, sets, cooccurrenceType, sort);
    exportSearchWithSetsData({
      requestData,
      downloadLink: tableDownloadRef.current,
      fileName: sets.reduce((fileName, set, i) =>
        fileName.concat(`${i !== 0 ? ', ' : ''}`, set.name), ''),
    });
  }

  function getTableColumns(tableData, tableWidth) {
    const setsIds = Object.keys(tableData[0].conceptInfo);
    return setsIds.reduce((columns, setId, i, arr) => {
      const d = tableData[0].conceptInfo[setId];
      const width = tableWidth / arr.length;

      columns.push({
        label: d.setName,
        dataKey: setId,
        sortPath: ['conceptInfo', setId],
        width,
        cellRenderer: (cell) => {
          const { rowData, rowIndex } = cell;
          const { name, id, category } = rowData.conceptInfo[setId].concept;
          const link = category === 'Genes & Molecular Sequences' ?
            `${RELATIVE_PATH}/gene-details/${id}` :
            `${RELATIVE_PATH}/concept-details/${id}`;

          return (
            <ShortConceptCardCell
              id={id}
              name={name}
              link={link}
              uniqueKey={`tooltip-${id}-${rowIndex}`}
            />
          );
        },
      });

      if (i === arr.length - 1) {
        columns.push({
          label: 'References',
          dataKey: 'count',
          width,
          cellRenderer: cell => (
            <div
              className="link"
              onClick={() => openPublicationsPopup(cell.rowData.conceptInfo)}
            >
              {cell.rowData.count}
            </div>
          ),
        });
      }

      return columns;
    }, []);
  }

  return (
    <div className="search-with-sets">
      <div className="search-with-sets__title title">
        Search with Sets
      </div>
      {
        data &&
        <div className="search-with-sets__content">
          <div className="search-with-sets__table">
            <div className="search-with-sets__table-header">
              <SearchWithSetsCooccurrenceSelect
                defaultValue={cooccurrenceTypesOptions[0]}
                options={cooccurrenceTypesOptions}
                onSelect={updateCooccurrenceType}
              />
              <Pagination
                {...pages}
                onPageChange={handleOnPageChange}
              />
              {
                data.length > 0 &&
                <ExportTable backendCall={handlePageExport} />
              }
            </div>
            {
              data.length > 0 &&
              <SimpleTable
                innerRef={tableRef}
                data={data}
                columns={getTableColumns(data, tableSettings.width)}
                settings={tableSettings}
                serverSortAction={handlePageSort}
              />
            }
            <NoData show={data.length === 0} />
          </div>
        </div>
      }
      <Loader
        withOverlay={true}
        isLoading={loading}
      />
      <Error
        show={error && !loading}
        error={error}
      />
      {
        publicationsPopup.show &&
        <ModalComponent
          isOpen={publicationsPopup.show}
          closeCb={() => setPublicationsPopup(defaultPublicationsPopupState)}
        >
          <PublicationsList
            names={publicationsPopup.conceptsNames}
            ids={publicationsPopup.conceptsIds}
            cooccurrenceType={cooccurrenceType}
          />
        </ModalComponent>
      }
      <InfoDialog
        isOpen={showLongTimeRequestDialog}
        closeCb={() => setShowLongTimeRequestDialog(false)}
        confirmBtnText="Ok"
        text="Please wait while your operation is executing, it may take a few minutes"
      />
      <a //eslint-disable-line
        hidden={true}
        ref={tableDownloadRef}
      />
    </div>
  );
};

SearchWithSetsPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getSearchWithSetsDataSelector(state),
    loading: SELECTORS.getSearchWithSetsLoadingSelector(state),
    error: SELECTORS.getSearchWithSetsErrorSelector(state),
    sort: SELECTORS.getSearchWithSetsSortSelector(state),
    pages: SELECTORS.getSearchWithSetsPagesSelector(state),
    cooccurrenceType: SELECTORS.getSearchWithSetsCooccurrenceTypeSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSearchWithSetsData(data) {
      dispatch(ACTIONS.getSearchWithSetsDataAction(data));
    },
    sortSearchWithSetsData(data) {
      dispatch(ACTIONS.sortSearchWithSetsDataAction(data));
    },
    resetSearchWithSetsPage(data) {
      dispatch(ACTIONS.resetSearchWithSetsPageAction(data));
    },
    changeSearchWithSetsPage(data) {
      dispatch(ACTIONS.changeSearchWithSetsPageAction(data));
    },
    exportSearchWithSetsData(data) {
      dispatch(ACTIONS.exportSearchWithSetsDataAction(data));
    },
    updateCooccurrenceType(data) {
      dispatch(ACTIONS.updateSearchWithSetsCooccurrenceTypeAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchWithSetsPage);
