import { fromJS } from 'immutable';

import {
  OPEN_ADD_LINKED_CONCEPTS,
  CLOSE_ADD_LINKED_CONCEPTS,
  FILTER_TAXONOMIES_SUCCEEDED,
  FILTER_TAXONOMIES_FAILED,
  FILTER_CATEGORIES_SUCCEEDED,
  FILTER_CATEGORIES_FAILED,
  FILTER_RELATIONS_SUCCEEDED,
  FILTER_RELATIONS_FAILED,
  FILTER_OPEN_RELATION,
  FILTER_CLOSE_RELATION,
  FILTER_OPEN_CATEGORY,
  FILTER_CLOSE_CATEGORY,
  FILTER_CHECK_RELATION,
  FILTER_CHECK_CATEGORY,
  FILTER_CHECK_TAXONOMY,
  FILTER_CATEGORIES_TYPE_SUCCEEDED,
  FILTER_CATEGORIES_TYPE_FAILED,
  FILTER_CATEGORIES_TYPE_LOADING,
  FILTER_CHECK_CATEGORY_TYPE,
  FILTER_CHECK_ALL_CATEGORY_TYPE,
  RELATED_CONCEPTS_SUCCEEDED,
  RELATED_CONCEPTS_FAILED,
  RELATED_CONCEPTS_LOADING,
  CHECK_RELATED_CONCEPT,
  SORT_RELATED_CONCEPTS,
  FILTER_RELATED_CONCEPTS,
  ADD_COLUMN_FAILED,
  ADD_COLUMN_SUCCEEDED,
  ADD_COLUMN_LOADING,
  CHECK_ALL_RELATED_CONCEPT,
  FILTER_CHECK_RELATION_TYPE,
  FILTER_CHECK_ALL_RELATION_TYPE,
} from './constants';

const initialValues = fromJS({
  taxonomies: {
    data: [],
    error: null,
    loading: true,
  },
  categories: {
    data: [],
    error: null,
    loading: true,
  },
  relations: {
    data: [],
    error: null,
    loading: true,
  },
  linkedConcepts: {
    data: [],
    error: null,
    loading: false,
  },
  openKey: false,
  sorting: { sortBy: 'conceptName', sortDirection: 'ASC' },
  filter: '',
  loading: false,
  error: null,
});

const setResultPageAddConcepts = (state = initialValues, action) => {
  switch (action.type) {
    case CHECK_ALL_RELATED_CONCEPT:
      return state.update('linkedConcepts', linkedConcepts =>
        linkedConcepts.update('data', data =>
          data
            .filter(concept => concept
              .getIn(['concept', 'name'], '')
              .toLowerCase()
              .indexOf(action.data.filter) !== -1)
            .map(concept =>
              concept.update('checked', () =>
                action.data.checked
              )
            )
        )
      );

    case ADD_COLUMN_LOADING:
      return state.update('loading', () => true);

    case ADD_COLUMN_SUCCEEDED:
      return state.merge(fromJS({ loading: false, error: null }));

    case ADD_COLUMN_FAILED:
      return state.merge(fromJS({ loading: false, error: action.message }));

    case FILTER_RELATED_CONCEPTS:
      return state.merge(fromJS({
        filter: action.data.toLowerCase(),
      }));

    case SORT_RELATED_CONCEPTS:
      return state.update('sorting', sorting =>
        sorting.merge(fromJS(action.data))
      );

    case RELATED_CONCEPTS_SUCCEEDED:
      return state.update('linkedConcepts', linkedConcepts =>
        linkedConcepts.merge(fromJS({ data: action.data, error: null, loading: false }))
      );

    case RELATED_CONCEPTS_FAILED:
      return state.update('linkedConcepts', linkedConcepts =>
        linkedConcepts.merge(fromJS({ data: [], error: action.message, loading: false }))
      );

    case RELATED_CONCEPTS_LOADING:
      return state.update('linkedConcepts', linkedConcepts =>
        linkedConcepts.merge(fromJS({ data: [], error: null, loading: true }))
      );

    case CHECK_RELATED_CONCEPT:
      return state.update('linkedConcepts', linkedConcepts =>
        linkedConcepts.update('data', data =>
          data.update(data.findIndex(concept =>
            concept.get('id') === action.data
          ), concept =>
            concept.update('checked', checked =>
              !checked
            )
          )
        )
      );

    case FILTER_TAXONOMIES_SUCCEEDED:
      return state.update('taxonomies', taxonomies =>
        taxonomies.merge(fromJS({ data: action.data, error: null, loading: false }))
      );

    case FILTER_TAXONOMIES_FAILED:
      return state.update('taxonomies', taxonomies =>
        taxonomies.merge(fromJS({ data: [], error: action.message, loading: false }))
      );

    case FILTER_RELATIONS_SUCCEEDED:
      return state.update('relations', relations =>
        relations.merge(fromJS({ data: action.data, error: null, loading: false }))
      );

    case FILTER_RELATIONS_FAILED:
      return state.update('relations', relations =>
        relations.merge(fromJS({ data: [], error: action.message, loading: false }))
      );

    case FILTER_CATEGORIES_SUCCEEDED:
      return state.update('categories', categories =>
        categories.merge(fromJS({ data: action.data, error: null, loading: false }))
      );

    case FILTER_CATEGORIES_FAILED:
      return state.update('categories', categories =>
        categories.merge(fromJS({ data: [], error: action.message, loading: false }))
      );

    case OPEN_ADD_LINKED_CONCEPTS:
      return state.update('openKey', () => true);

    case CLOSE_ADD_LINKED_CONCEPTS:
      return initialValues;

    case FILTER_OPEN_RELATION:
      return state.update('relations', relations =>
        relations.update('data', data =>
          data.update(data.findIndex(relation =>
            relation.get('id') === action.data
          ), relation =>
            relation.update('opened', () =>
              true
            )
          )
        )
      );

    case FILTER_CLOSE_RELATION:
      return state.update('relations', relations =>
        relations.update('data', data =>
          data.update(data.findIndex(relation =>
            relation.get('id') === action.data
          ), relation =>
            relation.update('opened', () => false)
          )
        )
      );

    case FILTER_OPEN_CATEGORY:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data
          ), category =>
            category.update('opened', () => true)
          )
        )
      );

    case FILTER_CLOSE_CATEGORY:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data
          ), category =>
            category.update('opened', () => false)
          )
        )
      );

    case FILTER_CHECK_RELATION:
      return state.update('relations', relations =>
        relations.update('data', data =>
          data.update(data.findIndex(relation =>
            relation.get('id') === action.data
          ), relation =>
            relation.update('checked', checked => !checked)
          )
        )
      );

    case FILTER_CHECK_TAXONOMY:
      return state.update('taxonomies', taxonomies =>
        taxonomies.update('data', data =>
          data.update(data.findIndex(taxonomy =>
            taxonomy.get('id') === action.data
          ), taxonomy =>
            taxonomy.update('checked', checked => !checked)
          )
        )
      );

    case FILTER_CHECK_CATEGORY:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data
          ), category =>
            category.update('checked', checked => !checked)
          )
        )
      );

    case FILTER_CATEGORIES_TYPE_SUCCEEDED:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data.semanticCategoryId
          ), category =>
            category
              .update('types', () => fromJS(action.data.types))
              .update('loading', () => false)
          )
        )
      );


    case FILTER_CATEGORIES_TYPE_FAILED:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data.semanticCategoryId
          ), category =>
            category
              .update('types', () => fromJS(action.data.types))
              .update('loading', () => false)
          )
        )
      );

    case FILTER_CATEGORIES_TYPE_LOADING:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data
          ), category =>
            category.update('loading', () => true)
          )
        )
      );

    case FILTER_CHECK_CATEGORY_TYPE:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data.categoryId
          ), category =>
            category.update('types', types =>
              types.update(types.findIndex(type =>
                type.get('id') === action.data.typeId
              ), type =>
                type.update('checked', checked => !checked)
              )
            )
          )
        )
      );

    case FILTER_CHECK_ALL_CATEGORY_TYPE:
      return state.update('categories', categories =>
        categories.update('data', data =>
          data.update(data.findIndex(category =>
            category.get('id') === action.data.categoryId
          ), category =>
            category.update('types', types =>
              types.map(type =>
                type.set('checked', action.data.checked)
              )
            )
          )
        )
      );

    case FILTER_CHECK_RELATION_TYPE:
      return state.update('relations', relations =>
        relations.update('data', data =>
          data.update(data.findIndex(relation =>
            relation.get('id') === action.data.relationCategoryId
          ), relation =>
            relation.update('predicates', predicates =>
              predicates.update(predicates.findIndex(predicate =>
                predicate.get('id') === action.data.predicateId
              ), predicate =>
                predicate.update('checked', checked => !checked)
              )
            )
          )
        )
      );

    case FILTER_CHECK_ALL_RELATION_TYPE:
      return state.update('relations', relations =>
        relations.update('data', data =>
          data.update(data.findIndex(relation =>
            relation.get('id') === action.data.relationId
          ), relation =>
            relation.update('predicates', predicates =>
              predicates.map(predicate => predicate.set('checked', action.data.checked))
            )
          )
        )
      );

    default:
      return state;
  }
};

export default setResultPageAddConcepts;
