export const analysisMethodEnum = {
  PATHWAY: 'PATHWAY',
  DISORDER: 'DISORDER',
  PHENOTYPE: 'PHENOTYPE',
  METABOLITE: 'METABOLITE',
  CATEGORY: 'CATEGORY',
  COMPOUND: 'COMPOUND',
  REGULATION: 'REGULATION',
  PROTEIN_COMPLEX: 'PROTEIN_COMPLEX',

  RANKING: 'RANKING',
  NETWORK: 'NETWORK',
};

export const analysisMethodNameEnum = {
  PATHWAY: 'Pathway',
  DISORDER: 'Disorder',
  PHENOTYPE: 'Phenotype',
  METABOLITE: 'Metabolite',
  CATEGORY: 'Category',
  COMPOUND: 'Compound',
  REGULATION: 'Regulation',
  PROTEIN_COMPLEX: 'Protein complex',

  RANKING: 'RANKING',
  NETWORK: 'NETWORK',
};
