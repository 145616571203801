import { call, put, takeEvery, select } from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { checkProjectTab, getProjectIdForRequest } from '../../Projects/ProjectsTabs/utils';

import {
  ANALYTICS_REQUESTED,
  ANALYTICS_FAILED,
  ANALYTICS_SUCCEEDED,
  DELETE_ANALYTICS_REQUESTED,
  DELETE_ANALYTICS_FAILED,
  DELETE_ANALYTICS_SUCCEEDED,
  DUPLICATE_ANALYTICS,
} from './constants';
import { copyAnalyticsItemSelector } from './selectors';
import { getProjectsOptionsSelector } from '../../Projects/ProjectsTabs/store/selectors';

function* saga(action) {
  try {
    const projectId = action.data;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getAllProjectAnalytics : Api.getAllAnalytics;
    const response = yield call(apiToCall, isProjectTab && projectId);
    const expanded = response.data.map(report => (
      Object.assign({}, report, { showAll: false })
    ));
    yield put({ type: ANALYTICS_SUCCEEDED, data: expanded });
  } catch (e) {
    yield put({ type: ANALYTICS_FAILED, message: e.message });
  }
}

function* deleteReport(action) {
  try {
    const {
      ids,
      projectId,
    } = action.data;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.deleteProjectAnalytics : Api.deleteAnalytics;
    const requestData = isProjectTab ? { ids, projectId } : ids;
    const response = yield call(apiToCall, requestData);
    yield put({ type: DELETE_ANALYTICS_SUCCEEDED, data: response.data });
    yield put({ type: ANALYTICS_REQUESTED, data: projectId });
  } catch (e) {
    yield put({ type: DELETE_ANALYTICS_FAILED, message: e.message });
  }
}

function* duplicateAnalytics(action) {
  try {
    const { ids, projectId, multiselect } = action.data;
    const { name, project = {} } = yield select(copyAnalyticsItemSelector);
    const options = yield select(getProjectsOptionsSelector);
    const defaultProject = options.some(({ id: optionId }) => optionId === projectId) ? projectId : null;
    const targetProject = project.id || defaultProject;
    const requestData = {
      projectId: getProjectIdForRequest(projectId),
      targetProject: getProjectIdForRequest(targetProject),
      'analytics-id': ids.join(','),
    };
    if (!multiselect) {
      requestData.title = name.trim();
    }
    yield call(Api.duplicateAnalyticsItem, requestData);
    yield put({ type: ANALYTICS_REQUESTED, data: projectId });
  } catch (e) {
    yield put({ type: DELETE_ANALYTICS_FAILED, message: e.message });
  }
}

function* SetAnalysisManagement() {
  yield takeEvery(ANALYTICS_REQUESTED, saga);
  yield takeEvery(DELETE_ANALYTICS_REQUESTED, deleteReport);
  yield takeEvery(DUPLICATE_ANALYTICS, duplicateAnalytics);
}

export default SetAnalysisManagement;
