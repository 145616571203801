import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ScatterPlot from '../../../../../../../graphics/ScatterPlot/ScatterPlot';
import Loader from '../../../../../../../common/Loader/Loader';
import Error from '../../../../../../../common/Error/Error';
import NoData from '../../../../../../../common/NoData/NoData';
// Constants
import { GENE_CELLS_CHART } from '../../../../constants';

const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  noData: PropTypes.bool,
  geneName: PropTypes.string,
  chartId: PropTypes.string,
  chartData: PropTypes.instanceOf(Array),
  filtersAreEmpty: PropTypes.bool,
  backgroundGraph: PropTypes.string,
  chartTitle: PropTypes.string,
  chartName: PropTypes.string,
  measure: PropTypes.instanceOf(Object),
  measureExtremes: PropTypes.instanceOf(Object),
};

const SingleCellChartByGene = (props) => {
  const {
    error,
    noData,
    loading,
    geneName,
    chartId,
    chartData,
    chartTitle,
    chartName,
    filtersAreEmpty,
    backgroundGraph,
    measure,
    measureExtremes,
  } = props;

  const dataForRender = !loading && !noData;

  const singleCellChartCls = classNames({
    'single-cell-chart-wrapper': true,
    'single-cell-chart-wrapper--flex-end': dataForRender,
  });

  return (
    <div className={singleCellChartCls}>
      {
        dataForRender &&
        <div className="single-cell-chart">
          <ScatterPlot
            chartData={chartData}
            chartId={chartId}
            type={GENE_CELLS_CHART}
            geneName={geneName}
            chartTitle={chartTitle}
            chartName={chartName}
            measureExtremes={measureExtremes}
            backgroundGraph={backgroundGraph}
            filtersAreEmpty={filtersAreEmpty}
            measure={measure}
            yAxisText="Expression level"
            info={`
              Raw feature ${geneName} counts for each cell are divided by the total counts for that cell and 
              multiplied by the scale.factor (10.000). This is then natural-log transformed using log1p.
            `}
            customClassName="scatter-plot-block--gene-type"
          />
        </div>
      }
      <Loader isLoading={loading} />
      <Error
        show={!loading && !!error}
        error={error}
      />
      <NoData
        show={noData}
        customClassName="single-cell-study__no-results"
      />
    </div>
  );
};

SingleCellChartByGene.propTypes = propTypes;

export default SingleCellChartByGene;
