import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Measure from 'react-measure';
import { Link } from 'react-router-dom';
import { startOfDay, isAfter } from 'date-fns';

// Icons
import { MdDelete } from 'react-icons/md';
import { IoMdAdd, IoMdCopy } from 'react-icons/io';
// Components
import Loader from '../../common/Loader/Loader';
import Button from '../../common/Buttons/Button/Button';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
import CopyRelationMapsModal from './Components/CopyRelationMapsModal/CopyRelationMapsModal';
import DeleteRelationMapsModal from './Components/DeleteRelationMapsModal/DeleteRelationMapsModal';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
// Utils
import { formatDate, AVERAGE_SYMBOL_LENGTH, proposingCopyName, checkIsReturnToThePageAfterAutoSave } from '../../Utils/Utils';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { isFreeUserSelector, isProjectManagerRightSelector } from '../../Header/selectors';
import { showFreeAccountLimitDialogAction } from '../../common/FreeAccountLimitationDialog/store/reducer';
import { getLocationHistorySelector } from '../../../location/locationSelector';
import { getRelationMapLoadingSelector } from '../RelationMapPage/store/selectors';
// Styles
import './RelationMapManagement.scss';

const propTypes = {
  relationMaps: PropTypes.instanceOf(Array),
  getRelationMapManagementData: PropTypes.func,
  sortRelationMapManagement: PropTypes.func,
  filterRelationMapManagement: PropTypes.func,
  deleteRelationMapManagementProjects: PropTypes.func,
  duplicateRelationMapManagementProjects: PropTypes.func,
  toggleShowAllRelationMapConcepts: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  clearRelationMapManagement: PropTypes.func,
  projectIsSaving: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  isFreeUser: PropTypes.bool,
  isProjectManagerUser: PropTypes.bool,
  showFreeAccountLimitDialog: PropTypes.func,
  projectId: PropTypes.string,
  isProjectLocked: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  isProjectLockedForUsers: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  locationHistory: PropTypes.instanceOf(Object),
  locationParams: PropTypes.instanceOf(Object),
  navigationType: PropTypes.string,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  navigate: PropTypes.func,
};

class RelationMap extends React.Component {
  constructor(props) {
    super(props);
    this.columns = null;
    this.state = {
      deleteRelationMaps: {
        ids: [],
        name: '',
        show: false,
      },
      copyRelationMaps: {
        ids: null,
        name: '',
        show: false,
      },
    };
    this.simpleTableRef = React.createRef(null);
    this.actions = {
      checkAll: ACTIONS.checkAllRelationMapManagementItemsAction,
      checkItem: ACTIONS.checkRelationMapManagementItemAction,
    };
  }

  componentDidMount() {
    const {
      projectId,
      projectIsSaving,
      getRelationMapManagementData,
    } = this.props;

    if (!projectIsSaving) {
      getRelationMapManagementData(projectId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      projectId,
      projectIsSaving,
      getRelationMapManagementData,
    } = this.props;

    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }

    if (
      (prevProps.projectIsSaving && !projectIsSaving) ||
      (prevProps.projectId !== projectId)) {
      getRelationMapManagementData(projectId);
    }
  }

  componentWillUnmount() {
    const { clearRelationMapManagement } = this.props;
    clearRelationMapManagement();
  }

  filterByTitle = (e) => {
    const { target: { value } } = e;
    const { filterRelationMapManagement } = this.props;

    filterRelationMapManagement({
      key: 'title',
      value,
    });
  };

  filterByTag = (e) => {
    const { target: { value } } = e;
    const { filterRelationMapManagement } = this.props;

    filterRelationMapManagement({
      key: 'tag',
      value,
    });
  };

  filterByConcept = (e) => {
    const { target: { value } } = e;
    const { filterRelationMapManagement } = this.props;

    filterRelationMapManagement({
      key: 'concept',
      value,
    });
  };

  getCheckedRelationMapsIds = () => {
    const { relationMaps } = this.props;
    return relationMaps.reduce((arr, p) => {
      if (p.selected) {
        arr.push(p.id);
      }
      return arr;
    }, []);
  };

  handleSort = (sortBy, sortDirection, sortValueMapper) => {
    const { sortRelationMapManagement } = this.props;

    sortRelationMapManagement({
      sortBy,
      sortDirection,
      ...(sortValueMapper && { sortValueMapper }),
    });
  };

  handleDeleteRelationMaps = () => {
    const { deleteRelationMapManagementProjects, projectId } = this.props;
    const { deleteRelationMaps: { ids } } = this.state;

    deleteRelationMapManagementProjects({ ids, projectId });
    this.closeDeleteRelationMapsModal();
  };

  handleStartNewRelationMap = () => {
    const {
      navigate,
      projectId,
      isFreeUser,
      relationMaps,
      showFreeAccountLimitDialog,
    } = this.props;

    if (isFreeUser && relationMaps.length >= 1) {
      showFreeAccountLimitDialog();
    } else {
      localStorage.removeItem('uniqConcepts');
      navigate(`${RELATIVE_PATH}/relation-map/${projectId}/new`);
    }
  };

  handleCopyRelationMaps = () => {
    const { duplicateRelationMapManagementProjects, projectId } = this.props;
    const { copyRelationMaps: { ids, multiselect } } = this.state;
    duplicateRelationMapManagementProjects({ ids, projectId, multiselect });
    this.closeCopyRelationMapsModal();
  };

  openDeleteRelationMapsModal = (rowData, ids) => {
    const { id, name } = rowData || {};
    this.setState({
      deleteRelationMaps: {
        ids: rowData ? [id] : ids,
        name,
        show: true,
      },
    });
  };

  closeDeleteRelationMapsModal = () => {
    this.setState({
      deleteRelationMaps: {
        ids: [],
        name: '',
        show: false,
      },
    });
  };

  openCopyRelationMapsModal = (rowData, multiselect, checkedIds) => {
    const { relationMaps, isFreeUser, showFreeAccountLimitDialog } = this.props;
    if (isFreeUser && relationMaps.length >= 1) {
      showFreeAccountLimitDialog();
    } else {
      let copyRelationMapConfig = {};
      if (multiselect) {
        copyRelationMapConfig = { ids: checkedIds, multiselect };
      } else {
        const name = proposingCopyName(relationMaps, rowData.name, 'copy');
        copyRelationMapConfig = { name, ids: [rowData.id] };
      }

      this.setState({
        copyRelationMaps: {
          show: true,
          ...copyRelationMapConfig,
        },
      });
    }
  };

  closeCopyRelationMapsModal = () => {
    this.setState({
      copyRelationMaps: {
        ids: null,
        name: '',
        show: false,
      },
    });
  };

  getRowHeight = (width, i) => {
    const { relationMaps } = this.props;
    const { showAll, concepts } = relationMaps[i];
    if (!concepts.length || !showAll) {
      return 50;
    }
    const padding = 20;
    const lineHeight = 30;
    const colWidth = ((width / 4) - padding) * 0.9;
    const conceptsStr = concepts.map(c => c.name).join(', ');
    const rows = Math.ceil((conceptsStr.length * AVERAGE_SYMBOL_LENGTH) / colWidth);
    return (rows * lineHeight);
  };

  getColumns = (width) => {
    const { toggleShowAllRelationMapConcepts, filters } = this.props;
    const colWidthBig = width / 4;
    const colWidthSmall = width / 8;

    this.columns = [
      {
        label: 'Title',
        dataKey: 'name',
        width: colWidthBig,
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          const { projectId } = this.props;
          return (
            <div className="title-section">
              <div className="mind-map-small-icon" />
              <Link
                title={rowData.name}
                to={`${RELATIVE_PATH}/relation-map/${projectId}/${rowData.id}`}
                className="title-section-name link"
              >
                <span className="title-overflow" title={rowData.name}>
                  {rowData.name}
                </span>
              </Link>
            </div>
          );
        },
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            active: headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            active: headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
              <input
                onChange={this.filterByTitle}
                className="header-input input"
                type="input"
                placeholder="Search titles"
                value={filters.titleFilter}
              />
            </div>
          );
        },
      },
      {
        label: 'Tags',
        dataKey: 'tags',
        width: colWidthBig,
        disableSort: true,
        headerRenderer: ({ label }) => (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
              {label}
            </span>
            <input
              onChange={this.filterByTag}
              className="header-input input"
              type="input"
              placeholder="Search tags"
              value={filters.tagFilter}
            />
          </div>
        ),
        cellRenderer: ({ rowData }) => (
          <div
            className="relation-map-tags-list"
            title={rowData.tags.join(', ')}
          >
            {rowData.tags.map((tag, index, array) => (
              <span key={index}>
                <span>{tag}</span>
                { index !== (array.length - 1) && <span>, </span>}
              </span>
            ))}
          </div>
        ),
      },
      {
        label: 'Concepts',
        dataKey: 'concepts',
        width: colWidthBig,
        disableSort: true,
        headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
          const sortValueMapper = item => (
            item.get(dataKey).size && item.get(dataKey).first() ?
              item.get(dataKey).first()
                .get('name') : ''
          );
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            active: sortBy === dataKey && sortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            active: sortBy === dataKey && sortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC', sortValueMapper);
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC', sortValueMapper);
                  }}
                />
              </span>
              <input
                onChange={this.filterByConcept}
                className="header-input input"
                type="input"
                placeholder="Search concepts"
                value={filters.conceptFilter}
              />
            </div>
          );
        },
        cellRenderer: ({ rowData }) => {
          const conceptListClasses = classNames({
            'concepts-list': true,
            'concept-list-wide': rowData.showAll,
          });
          const iconClasses = classNames({
            'fa': true,
            'fa-angle-down': rowData.showAll,
            'fa-angle-up': !rowData.showAll,
          });
          return (
            <div className="concepts-wrapper">
              <div className={conceptListClasses}>
                {
                  rowData.concepts.map((concept, i, array) => {
                    const {
                      id,
                      name,
                      category,
                    } = concept;
                    const link = category === 'Genes & Molecular Sequences' ?
                      `${RELATIVE_PATH}/gene-details/${id}` :
                      `${RELATIVE_PATH}/concept-details/${id}`;
                    const conceptName = i !== array.length - 1 ? `${name},` : name;
                    const uniqueKey = `tooltip-${id}-${i}`;
                    return (
                      <ShortConceptCardCell
                        id={id}
                        link={link}
                        key={uniqueKey}
                        name={conceptName}
                        uniqueKey={uniqueKey}
                      />
                    );
                  })
                }
              </div>
              {
                rowData.concepts.length > 0 &&
                <div className="concepts-list-controls">
                  <i
                    role="presentation"
                    className={iconClasses}
                    onClick={() => {
                      toggleShowAllRelationMapConcepts({
                        id: rowData.id,
                        show: !rowData.showAll,
                      });
                    }}
                  />
                </div>
              }
            </div>
          );
        },
      },
      {
        label: 'Concepts count',
        dataKey: 'conceptsCount',
        width: colWidthSmall,
        disableSort: true,
        headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            active: sortBy === dataKey && sortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            active: sortBy === dataKey && sortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span
                className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
                title={label}
              >
                {label}
              </span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
            </div>
          );
        },
        cellRenderer: ({ rowData }) => (
          <div className="concepts-wrapper">
            <div className="concepts-list">
              {rowData.concepts.length}
            </div>
          </div>
        ),
      },
      {
        label: 'Last opened',
        dataKey: 'lastOpened',
        width: colWidthSmall,
        disableSort: true,
        headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            active: sortBy === dataKey && sortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            active: sortBy === dataKey && sortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
            </div>
          );
        },
        cellRenderer: ({ rowData }) => {
          const lastOpened = isAfter(startOfDay(new Date()), new Date(rowData.lastOpened)) ?
            formatDate(rowData.lastOpened) :
            formatDate(rowData.lastOpened, 'HH:mm');
          return (
            <span>{lastOpened}</span>
          );
        },
      },
      {
        dataKey: 'id',
        disableSort: true,
        width: colWidthSmall,
        cellRenderer: ({ rowData }) => {
          const deleteIconClassName = classNames({
            'management-controls__item': true,
            'visually-hidden': this.projectsActionsAreDisabled(),
          });
          return (
            <div className="management-controls">
              <div
                title="Copy relation map"
                className="management-controls__item"
                onClick={() => { this.openCopyRelationMapsModal(rowData); }}
              >
                <IoMdCopy size={28} />
              </div>
              <div
                role="presentation"
                onClick={() => { this.openDeleteRelationMapsModal(rowData); }}
                className={deleteIconClassName}
              >
                <MdDelete size={28} />
              </div>
            </div>
          );
        },
      },
    ];
    return this.columns;
  };

  projectsActionsAreDisabled = () => {
    const {
      isFreeUser,
      isProjectManagerUser,
      isProjectPublic,
      isProjectLocked,
      isProjectLockedForUsers,
    } = this.props;
    if (isProjectLocked || isProjectPublic || (isProjectLockedForUsers && (isFreeUser || !isProjectManagerUser))) {
      return true;
    }
    return false;
  };

  handleProjectClick = (id) => {
    const {
      getRelationMapManagementData,
    } = this.props;
    getRelationMapManagementData(id);
  };

  render() {
    const {
      error,
      relationMaps,
      sorting: { sortBy, sortDirection },
      loading,
      projectId,
      location,
      locationHistory,
      navigationType,
      projectIsSaving,
      setActiveProjectId,
      clearActiveProjectId,
    } = this.props;

    const {
      deleteRelationMaps,
      copyRelationMaps,
    } = this.state;

    const isLoading = loading && projectIsSaving;
    const checkedIds = relationMaps ? this.getCheckedRelationMapsIds() : [];

    const tableSettings = {
      headerHeight: 65,
      sortBy,
      sortDirection,
    };

    const redirectToTheProjectBool = navigationType === 'POP' || checkIsReturnToThePageAfterAutoSave(locationHistory, '/relation-map');

    return (
      <div className="relation-map">
        {
          !isLoading && !error && relationMaps !== null &&
          <div className="row relation-map-container m-0">
            <div className="management-container__buttons">
              <Button
                customClassName="management__create-button button button-primary"
                disabled={this.projectsActionsAreDisabled()}
                onClick={this.handleStartNewRelationMap}
              >
                <IoMdAdd size={28} />
                Create
              </Button>
              <div className="management-buttons">
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={!checkedIds.length}
                  onClick={() => this.openCopyRelationMapsModal(null, true, checkedIds)}
                >
                  <IoMdCopy size={26} />
                  Copy
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button"
                  disabled={!checkedIds.length || this.projectsActionsAreDisabled()}
                  onClick={() => {
                    this.openDeleteRelationMapsModal(null, checkedIds);
                  }}
                >
                  <MdDelete size={26} />
                  Delete
                </Button>
              </div>
            </div>
            <ProjectsTabs
              activeProjectId={projectId}
              setActiveProjectId={setActiveProjectId}
              clearActiveProjectId={clearActiveProjectId}
              redirectToTheProject={redirectToTheProjectBool}
              redirectedProjectId={location.state?.redirectedProjectId}
            />
            {
              relationMaps !== null &&
              <Measure offset={true}>
                {({ measureRef, contentRect }) => (
                  <div className="projects-info" ref={measureRef}>
                    {
                      !!contentRect.offset.width && !!contentRect.offset.height &&
                      <SimpleTable
                        innerRef={this.simpleTableRef}
                        data={relationMaps}
                        width={contentRect.offset.width}
                        height={contentRect.offset.height}
                        columns={this.getColumns(contentRect.offset.width)}
                        settings={{
                          ...tableSettings,
                          rowHeight: ({ index }) => this.getRowHeight(contentRect.offset.width, index),
                        }}
                        actions={this.actions}
                        sortAction={ACTIONS.sortRelationMapManagementAction}
                        onlySelect={true}
                        fixedHeight={true}
                        noRowsMsg="There is no data to display"
                      />
                    }
                  </div>
                )}
              </Measure>
            }
          </div>
        }
        {
          error && !isLoading &&
          <div className="text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        <Loader
          isLoading={loading && !error}
          withOverlay={true}
        />
        <DeleteRelationMapsModal
          isOpen={deleteRelationMaps.show}
          closeCb={this.closeDeleteRelationMapsModal}
          onConfirm={this.handleDeleteRelationMaps}
          relationMapsToDelete={deleteRelationMaps.ids.length}
        />
        <CopyRelationMapsModal
          activeProjectId={projectId}
          isOpen={copyRelationMaps.show}
          onCancel={this.closeCopyRelationMapsModal}
          onSubmit={this.handleCopyRelationMaps}
          multiselect={copyRelationMaps.multiselect}
          initialValues={{ name: copyRelationMaps.name || '' }}
          relationMaps={relationMaps}
        />
        <FreeAccountLimitationDialog entity="relation map" />
      </div>
    );
  }
}

RelationMap.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    relationMaps: SELECTORS.getRelationMapManagementProjectsSelector(state),
    error: SELECTORS.getRelationMapManagementErrorSelector(state),
    loading: SELECTORS.getRelationMapManagementLoadingSelector(state),
    sorting: SELECTORS.getRelationMapManagementSortingSelector(state),
    filters: SELECTORS.getRelationMapManagementFiltersSelector(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
    locationHistory: getLocationHistorySelector(state),
    isProjectLocked: SELECTORS.getIsProjectLockedSelector(state),
    isProjectPublic: SELECTORS.getIsProjectPublicSelector(state),
    isProjectLockedForUsers: SELECTORS.getIsProjectLockedForUsersSelector(state),
    projectIsSaving: getRelationMapLoadingSelector(state),
    isFreeUser: isFreeUserSelector(state),
    isProjectManagerUser: isProjectManagerRightSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRelationMapManagementData(data) {
      dispatch(ACTIONS.getRelationMapManagementDataAction(data));
    },
    sortRelationMapManagement(sorting) {
      dispatch(ACTIONS.sortRelationMapManagementAction(sorting));
    },
    filterRelationMapManagement(data) {
      dispatch(ACTIONS.filterRelationMapManagementAction(data));
    },
    deleteRelationMapManagementProjects(id) {
      dispatch(ACTIONS.deleteRelationMapManagementProjectsAction(id));
    },
    clearRelationMapManagement() {
      dispatch(ACTIONS.clearRelationMapManagementAction());
    },
    duplicateRelationMapManagementProjects(data) {
      dispatch(ACTIONS.dublicateRelationMapManagementProjectsAction(data));
    },
    toggleShowAllRelationMapConcepts(data) {
      dispatch(ACTIONS.showAllRelationMapManagementConceptsAction(data));
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setRelationMapManagemenActiveProjectIdAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearRelationMapManagemenActiveProjectIdAction());
    },
    showFreeAccountLimitDialog() {
      dispatch(showFreeAccountLimitDialogAction());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMap));
