import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Store
import { getSetManagementDataAction, throwSetManagementDataErrorAction } from '../../reducer';
import { copySetSelector } from '../../selectors';
import { getProjectIdForRequest } from '../../../../Projects/ProjectsTabs/utils';
import { getProjectsOptionsSelector } from '../../../../Projects/ProjectsTabs/store/selectors';

export function* duplicateSetManagementWorker({ payload }) {
  try {
    const { setIds, projectId, multiselect } = payload;
    const { name, project = {} } = yield select(copySetSelector);
    const options = yield select(getProjectsOptionsSelector);
    const defaultProject = options.some(({ id }) => id === projectId) ? projectId : null;
    const targetProject = project.id || defaultProject;

    const requestData = {
      targetProject: getProjectIdForRequest(targetProject),
      'set-id': setIds.join(','),
      projectId: getProjectIdForRequest(projectId),
    };

    if (!multiselect) {
      requestData.title = name.trim();
    }

    yield call(Api.duplicateProjectSets, requestData);
    yield put(getSetManagementDataAction({ projectId }));
  } catch (e) {
    yield put(throwSetManagementDataErrorAction(e.message));
  }
}
