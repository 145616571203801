import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Concept from '../../../common/ConceptItem/Concept';
import SelectDisease from '../../../common/SelectDisease/SelectDisease';
// Store
import {
  addGeneVariationTerm,
  removeGeneVariationTerm,
  saveGeneVariationTerm,
} from './actions';
import { getGeneVariationTerm } from './selectors';
import { closeRankingModal, setRankingAction } from '../../common/SetAnalysisMethodSelection/actions';
// Styles
import './GeneVariationSettings.css';

const propTypes = {
  addGeneVariationTerm: PropTypes.func,
  saveSelected: PropTypes.func,
  setRanking: PropTypes.func,
  closeModal: PropTypes.func,
  removeLiteratureSearchTerms: PropTypes.func,
  term: PropTypes.instanceOf(Object),
  updateTerm: PropTypes.func,
  closeModalCallback: PropTypes.func,
  setRankingCallback: PropTypes.func,
};

const GeneVariationSettings = (props) => {
  const {
    term,
    closeModal,
    setRanking,
    saveSelected,
    addGeneVariationTerm,
    removeLiteratureSearchTerms,
    updateTerm,
    closeModalCallback,
    setRankingCallback,
  } = props;

  const handleSaveSelected = () => {
    if (updateTerm) {
      updateTerm(term);
      setRankingCallback('geneVariation');
      closeModalCallback();
    } else {
      saveSelected();
      setRanking({ geneVariation: true });
      closeModal();
    }
  };

  return (
    <div className="gene-variation-modal">
      <div className="flex-grid">
        <div className="col-1 text-center">
          <h3 className="title3">
            Gene Variation Term
          </h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 pl-10">
          <SelectDisease onSubmit={addGeneVariationTerm} />
        </div>
      </div>
      {
        term &&
        <div className="flex-grid">
          <div className="col-1 text-center row">
            <Concept
              noInfoButton={true}
              deleteCb={removeLiteratureSearchTerms}
              concept={term}
            />
          </div>
        </div>
      }
      <div className="flex-grid">
        <div className="col-1 text-center row">
          <button
            disabled={!term}
            className="button button-primary mr-15"
            onClick={handleSaveSelected}
          >
            Add
          </button>
          <button
            type="button"
            className="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

GeneVariationSettings.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    term: getGeneVariationTerm(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addGeneVariationTerm(data) {
      dispatch(addGeneVariationTerm(data));
    },
    removeLiteratureSearchTerms(data) {
      dispatch(removeGeneVariationTerm(data));
    },
    closeModal() {
      dispatch(closeRankingModal());
    },
    saveSelected() {
      dispatch(saveGeneVariationTerm());
    },
    setRanking(data) {
      dispatch(setRankingAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneVariationSettings);
