import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

// Styles
import './LegendComponent.scss';

const propTypes = {
  legendsParams: PropTypes.instanceOf(Array),
  legendsColors: PropTypes.instanceOf(Array),
  keyToName: PropTypes.string,
  title: PropTypes.string,
  showTooltips: PropTypes.bool,
  keyToTooltip: PropTypes.string,
};

const LegendComponent = (props) => {
  const {
    legendsParams,
    legendsColors,
    keyToName,
    title,
    showTooltips,
    keyToTooltip,
  } = props;

  const getTooltipContent = useCallback((item) => {
    switch (keyToTooltip) {
      case 'markers': {
        return `Markers:\n ${item[keyToTooltip].join(', ')}`;
      }
      default:
        return '';
    }
  }, [keyToTooltip]);

  return (
    <ul className="legend-wrapper">
      {
        title &&
        <span className="legend__title">{title}</span>
      }
      {
        legendsParams.map((item, index) => (
          <li
            data-tip={true}
            data-for={item[keyToName]}
            className={classNames({ 'legend__item--hover': showTooltips && !!item[keyToTooltip] })}
            key={`legend-${index}`}
          >
            <span style={{ backgroundColor: legendsColors[index] }} className="legend__color-block" />
            <span className="legend__text">
              {item[keyToName]}
            </span>
            {
              showTooltips && !!item[keyToTooltip] &&
              <ReactTooltip
                id={item[keyToName]}
                place="bottom"
                type="light"
                className="legend__tooltip"
              >
                {getTooltipContent(item)}
              </ReactTooltip>
            }
          </li>
        )
        )}
    </ul>
  );
};

LegendComponent.propTypes = propTypes;

export default React.memo(LegendComponent);
