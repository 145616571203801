import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import RelationMapChart from './Components/RelationMapChart/RelationMapChart';
import RelationMapHeader from './Components/RelationMapHeader/RelationMapHeader';
import RelationMapSidebar from './Components/RelationMapSidebar/RelationMapSidebar';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import VersionDialog from '../../Modals/VersionModal/VersionModal';
// Constants
import { DefaultProjectInfoData, ConfirmDeleteInitialValues } from './constants';
// Store
import * as ACTIONS from './store/actions';
import * as SELECTORS from './store/selectors';
import { resetHideSourceDataAction } from './Components/HideSourceModal/store/reducer';
import { resetHideRelationTypesDataAction } from './Components/HideRelationTypesModal/store/reducer';
import {
  getIsProjectLockedForUsersSelector,
  getIsProjectLockedSelector,
  getIsProjectPublicSelector,
} from '../../Projects/ProjectsTabs/store/selectors';
import { isProjectManagerRightSelector } from '../../Header/selectors';
// Utils
import { useBlocker } from './hooks/useBlocker';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loading: PropTypes.bool,
  addNewConcepts: PropTypes.func,
  setProjectInfo: PropTypes.func,
  saveRelationMap: PropTypes.func,
  getRelationMapData: PropTypes.func,
  resetHideSourceModal: PropTypes.func,
  resetHideRelationTypesModal: PropTypes.func,
  isProjectLockedForUsers: PropTypes.bool,
  isAdminRights: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  isProjectLocked: PropTypes.bool,
  version: PropTypes.number,
};

const RelationMapPage = (props) => {
  const {
    data,
    resetHideRelationTypesModal,
    resetHideSourceModal,
    getRelationMapData,
    saveRelationMap,
    isProjectLockedForUsers,
    isAdminRights,
    isProjectPublic,
    isProjectLocked,
    setProjectInfo,
    addNewConcepts,
    loading,
    error,
  } = props;
  const { mapId, projectId } = useParams();
  const saveActionsAreDisabled = isProjectLocked || isProjectPublic || (isProjectLockedForUsers && !isAdminRights);

  const [showConfirmDialog, setShowConfirmDialog] = useState(ConfirmDeleteInitialValues);

  const dataIsPresent = useRef();
  const retryUnblockRef = useRef();

  const savingMapBeforeClosing = ({ retryUnblockPage }) => {
    const dataCheck = dataIsPresent.current || data;
    if (dataCheck && !saveActionsAreDisabled) {
      saveRelationMap({ projectId, retryUnblockPage });
    }
  };

  useEffect(() => { dataIsPresent.current = !!data; }, [data]);

  useEffect(() => {
    if (!mapId) return;
    if (mapId === 'new') {
      const storageData = localStorage.getItem('uniqConcepts');
      if (storageData) {
        addNewConcepts({ concepts: JSON.parse(storageData).map(d => d.id || d.conceptId) });
      }
      setProjectInfo(DefaultProjectInfoData);
    } else {
      getRelationMapData({ mapId, projectId });
    }
  }, [mapId, getRelationMapData, setProjectInfo, addNewConcepts, projectId]);

  useEffect(() => {
    return () => {
      resetHideSourceModal();
      resetHideRelationTypesModal();
    };
  }, []);

  useBlocker({
    retryUnblockRef,
    savingDataBeforeClosing: savingMapBeforeClosing,
  });

  return (
    <div className="relation-map-page">
      <RelationMapHeader />
      <div className="relation-map-page__content">
        <Measure offset={true}>
          {({ measureRef, contentRect }) => (
            <div className="relation-map-page__chart" ref={measureRef}>
              {
                !!contentRect.offset.width && !!contentRect.offset.height && !!data &&
                <RelationMapChart
                  data={data}
                  width={contentRect.offset.width}
                  height={contentRect.offset.height}
                  loading={loading}
                  setShowConfirmDialog={setShowConfirmDialog}
                />
              }
              <Error
                show={!loading && error}
                error={error}
                customClassName="relation-map-page__error"
              />
            </div>
          )}
        </Measure>
        <RelationMapSidebar
          data={data}
          setShowConfirmDialog={setShowConfirmDialog}
        />
      </div>
      <VersionDialog
        closeCb={() => { retryUnblockRef.current(); } }
        onConfirm={() => saveRelationMap({ projectId, overwrite: true, retryUnblockPage: retryUnblockRef.current })}
        text="Relation map was changed by other user. Do you want to rewrite changes?"
      />
      <Loader
        isLoading={loading}
        withOverlay={true}
      />
      <ConfirmationDialog
        isOpen={showConfirmDialog.show}
        closeCb={() => setShowConfirmDialog(ConfirmDeleteInitialValues)}
        {...showConfirmDialog.config}
      />
    </div>
  );
};

RelationMapPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getRelationMapDataSelector(state),
    loading: SELECTORS.getRelationMapLoadingSelector(state),
    error: SELECTORS.getRelationMapErrorSelector(state),
    isProjectLocked: getIsProjectLockedSelector(state),
    isProjectPublic: getIsProjectPublicSelector(state),
    isAdminRights: isProjectManagerRightSelector(state),
    isProjectLockedForUsers: getIsProjectLockedForUsersSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRelationMapData(data) {
      dispatch(ACTIONS.getRelationMapProjectDataAction(data));
    },
    setProjectInfo(data) {
      dispatch(ACTIONS.setRelationMapProjectInfoAction(data));
    },
    saveRelationMap(data) {
      dispatch(ACTIONS.saveRelationMapAction(data));
    },
    resetHideSourceModal() {
      dispatch(resetHideSourceDataAction());
    },
    resetHideRelationTypesModal() {
      dispatch(resetHideRelationTypesDataAction());
    },
    addNewConcepts(data) {
      dispatch(ACTIONS.addNewConceptsToRelationMapAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMapPage);
