import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  gene: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  disease: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  geneSynonyms: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  geneClassifications: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  diseaseClassifications: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  diseaseSynonyms: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  predicate: PropTypes.string,
};

class GeneDiseaseIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllGeneSynonyms: false,
      showAllDiseaseSynonyms: false,
      showAllGeneClassifications: false,
      showAllDiseaseClassifications: false,
    };
  }

  render() {
    const {
      gene,
      geneClassifications,
      geneSynonyms,
      disease,
      diseaseClassifications,
      diseaseSynonyms,
      predicate,
    } = this.props;

    const backgroundGeneColor = `#${gene.color}` || '#fafafa';
    const backgroundDiseaseColor = `#${disease.color}` || '#fafafa';

    const geneClassificationsFirstTenValues = geneClassifications.slice(0, 10).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item.name }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const geneClassificationsRestValues = geneClassifications.slice(10, geneClassifications.length).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item.name }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const diseaseClassificationsFirstTenValues = diseaseClassifications.slice(0, 10).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item.name }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const diseaseClassificationsRestValues = diseaseClassifications.slice(10, diseaseClassifications.length).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item.name }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const geneSynonymsFirstTenValues = geneSynonyms.slice(0, 10).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const geneSynonymsRestValues = geneSynonyms.slice(10, geneSynonyms.length).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { index === 0 && ', ' }
        { item }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const diseaseSynonymsFirstTenValues = diseaseSynonyms.slice(0, 10).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { item }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    const diseaseSynonymsRestValues = diseaseSynonyms.slice(10, diseaseSynonyms.length).map((item, index, array) => (
      <span className="frame-classification" key={index}>
        { index === 0 && ', ' }
        { item }
        { index !== (array.length - 1) && ', ' }
      </span>
    ));

    return (
      <div className="flex-grid">
        <div className="col-2 flex-grid justify-content-center">
          <div className="concept-frame" style={{ backgroundColor: backgroundGeneColor }}>
            <div className="concept-frame-name">
              { gene.name }
            </div>
            <div className="concept-frame-details">
              <span className="concept-frame-details-title">classifications: </span>
              <span>{ geneClassificationsFirstTenValues } </span>
              {this.state.showAllDiseaseClassifications &&
                <span>{ geneClassificationsRestValues } </span>
              }
              {geneClassifications.length > 10 &&
                <span
                  role="presentation"
                  onClick={() => {
                    this.setState({ showAllGeneClassifications: !this.state.showAllGeneClassifications });
                  }}
                >
                  {!this.state.showAllGeneClassifications &&
                    <span className="seeAll">more</span>
                  }
                  {this.state.showAllGeneClassifications &&
                    <span className="seeAll">less</span>
                  }
                </span>
              }
            </div>
            <div className="concept-frame-details">
              <span className="concept-frame-details-title">synonyms: </span>
              <span>{ geneSynonymsFirstTenValues } </span>
              {this.state.showAllGeneSynonyms &&
                <span>{ geneSynonymsRestValues } </span>
              }
              {geneSynonyms.length > 10 &&
                <span
                  role="presentation"
                  onClick={() => {
                    this.setState({ showAllGeneSynonyms: !this.state.showAllGeneSynonyms });
                  }}
                >
                  {!this.state.showAllGeneSynonyms &&
                    <span className="seeAll">more</span>
                  }
                  {this.state.showAllGeneSynonyms &&
                    <span className="seeAll">less</span>
                  }
                </span>
              }
            </div>
          </div>
        </div>
        <div className="col-1 flex-grid justify-content-center arrow-container">
          <div className="link-name">{ predicate }</div>
          { predicate &&
            <div className="arrow">
              <i className="fa fa-long-arrow-right" aria-hidden="true" />
            </div>
          }
        </div>
        <div className="col-2 flex-grid justify-content-center">
          <div className="concept-frame" style={{ backgroundColor: backgroundDiseaseColor }}>
            <div className="concept-frame-name">
              { disease.name }
            </div>
            <div className="concept-frame-details">
              <span className="concept-frame-details-title">classifications: </span>
              <span>{ diseaseClassificationsFirstTenValues } </span>
              {this.state.showAllDiseaseClassifications &&
                <span>{ diseaseClassificationsRestValues } </span>
              }
              {diseaseClassifications.length > 10 &&
                <span
                  role="presentation"
                  onClick={() => {
                    this.setState({ showAllDiseaseClassifications: !this.state.showAllDiseaseClassifications });
                  }}
                >
                  {!this.state.showAllDiseaseClassifications &&
                    <span className="seeAll">more</span>
                  }
                  {this.state.showAllDiseaseClassifications &&
                    <span className="seeAll">less</span>
                  }
                </span>
              }
            </div>
            <div className="concept-frame-details">
              <span className="concept-frame-details-title">synonyms: </span>
              <span>{ diseaseSynonymsFirstTenValues } </span>
              {this.state.showAllDiseaseSynonyms &&
                <span>{ diseaseSynonymsRestValues } </span>
              }
              { diseaseSynonyms.length > 10 &&
                <span
                  role="presentation"
                  onClick={() => {
                    this.setState({ showAllDiseaseSynonyms: !this.state.showAllDiseaseSynonyms });
                  }}
                >
                  { !this.state.showAllDiseaseSynonyms &&
                    <span className="seeAll">more</span>
                  }
                  { this.state.showAllDiseaseSynonyms &&
                    <span className="seeAll">less</span>
                  }
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GeneDiseaseIntro.propTypes = propTypes;

export default GeneDiseaseIntro;
