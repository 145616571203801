// Core
import { takeLatest } from 'redux-saga/effects';

// Actions
import { getDisordersDataAction } from '../reducer';

// Workers
import { getDisordersDataWorker } from './workers/getDisordersDataWorker';

function* geneDetailsDisordersSaga() {
  yield takeLatest(getDisordersDataAction, getDisordersDataWorker);
}

export default geneDetailsDisordersSaga;
