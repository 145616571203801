import { createSelector } from 'reselect';

const graphData = state => state.getIn(['publicationsFilterGraphReducer', 'graphData']);
const loading = state => state.getIn(['publicationsFilterGraphReducer', 'loading']);
const error = state => state.getIn(['publicationsFilterGraphReducer', 'error']);

export const getGraphData = createSelector(
  graphData,
  data => data && data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

