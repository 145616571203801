import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import Heatmap from '../../graphics/Heatmap/Heatmap';
import ConceptSearchModal from '../../Concept/ConceptSearchModal/ConceptSearchModal';
import ConceptSearchForm from '../../Concept/ConceptSearchForm/ConceptSearchForm';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { MAX_RECORDS_COUNT } from '../../graphics/Heatmap/constants';
import { trimText } from '../../Utils/Utils';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { getHeatmapDataSelector } from '../../graphics/Heatmap/store/selectors';
import { setHeatmapInitialDataAction, setHeatmapDataAction } from '../../graphics/Heatmap/store/reducer';
// Styles
import './styles.scss';

const propTypes = {
  set: PropTypes.instanceOf(Object),
  getBuildHeatmapData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  addConcept: PropTypes.func,
  heatmapConcepts: PropTypes.instanceOf(Object),
  resetBuildHeatmapData: PropTypes.func,
  chartData: PropTypes.instanceOf(Object),
  setHeatmapInitialData: PropTypes.func,
  setHeatmapData: PropTypes.func,
  buildHeatmapConcepts: PropTypes.instanceOf(Object),
};

const BuildHeatmap = (props) => {
  const {
    getBuildHeatmapData,
    set,
    addConcept,
    heatmapConcepts,
    resetBuildHeatmapData,
    chartData,
    loading,
    error,
    setHeatmapInitialData,
    setHeatmapData,
    buildHeatmapConcepts: {
      mainConcepts,
      additionalConcepts, 
    }
  } = props;
  const navigate = useNavigate();
  const { state: { id, setName, recordsCount, projectId } } = useLocation();
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);

  const records = useMemo(() => chartData ?
    chartData.rows.length * chartData.columns.length :
    recordsCount,
  [chartData, recordsCount]);

  const showHeatMap = records <= MAX_RECORDS_COUNT;
  const disableSelector = (chartData ? records + chartData.rows.length : recordsCount) <= MAX_RECORDS_COUNT;
  const title = setName ? `Build heat map for "${setName}"` : '';
  const titleForDownloading = setName ? `Heat map of ${trimText(setName, 60)}` : '';

  useEffect(() => {
    if (id) {
      getBuildHeatmapData({ id, projectId });
    } else {
      navigate(`${RELATIVE_PATH}/reserch`);
    }
  }, [getBuildHeatmapData, id, navigate, projectId]);

  useEffect(() => {
    if (additionalConcepts.length > 0 && mainConcepts.length > 0) {
      setHeatmapInitialData(heatmapConcepts);
    }
    if (
      (mainConcepts.length === 0 && chartData) ||
      (additionalConcepts.length === 0 && chartData)
    ) {
      setHeatmapData({ data: null, type: '' });
    }
  }, [heatmapConcepts]);

  useEffect(() => resetBuildHeatmapData, []);

  return (
    <div className="new-option-set-page">
      {
        set &&
        <Heatmap
          title={title}
          showHeatMap={showHeatMap}
          buildHeatmap={true}
          projectId={projectId}
          titleForDownloading={titleForDownloading}
        >
          {
            mainConcepts && mainConcepts.length !== 0 ? (
              <div className="new-option-set-page__add-concept">
                <span className="form-label vertical-align-top">
                  <label htmlFor="addConcepts">Add concepts:</label>
                </span>
                <div
                  className="build-heatmap-add-concept"
                  data-tip={true}
                  data-for="addConceptTooltip"
                >
                  <ConceptSearchForm onSubmitCallback={() => setShowConceptSearchModal(true)} />
                </div>
                {
                  !disableSelector &&
                  <div className="new-option-set-page__info-text">
                    {
                      'There are too many records already. You can\'t add one more concept. \n' +
                      'For building graphics can\'t be more than 30 000 records.'
                    }
                  </div>
                }
                {
                  !additionalConcepts.length > 0 && !chartData &&
                  <div className="new-option-set-page__info-text">
                    Choose the first concept to adding it to the heatmap
                  </div>
                }
              </div>
            ) : null
          }
        </Heatmap>
      }
      <Error show={error && !loading} error={error} />
      <Loader isLoading={loading} />
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addConcept}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </div>
  );
};

BuildHeatmap.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    set: SELECTORS.getBuildHeatmapSetSelector(state),
    heatmapConcepts: SELECTORS.getBuildHeatmapConceptsSelector(state),
    chartData: getHeatmapDataSelector(state),
    error: SELECTORS.getBuildHeatmapErrorSelector(state),
    loading: SELECTORS.getBuildHeatmapLoadingSelector(state),
    buildHeatmapConcepts: SELECTORS.getBuildHeatmapAxisConceptsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBuildHeatmapData(data) {
      dispatch(ACTIONS.getBuildHeatmapDataAction(data));
    },
    addConcept(data) {
      dispatch(ACTIONS.buildHeatmapAddConceptAction(data));
    },
    resetBuildHeatmapData(data) {
      dispatch(ACTIONS.resetBuildHeatmapDataAction(data));
    },
    setHeatmapInitialData(data) {
      dispatch(setHeatmapInitialDataAction(data));
    },
    setHeatmapData(data) {
      dispatch(setHeatmapDataAction(data));
    },
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildHeatmap);
