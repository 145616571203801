import { fromJS } from 'immutable';
import {
  createAction,
  handleActions,
} from 'redux-actions';

const PREFIX = 'USER_MENU';
export const userMenuOpenAction = createAction(`${PREFIX}/OPEN`);
export const userMenuCloseAction = createAction(`${PREFIX}/CLOSE`);

const initialState = fromJS({
  open: false,
});

const reducer = handleActions({
  [userMenuOpenAction]: state =>
    state.set('open', true),
  [userMenuCloseAction]: state =>
    state.set('open', false),
}, initialState);

export default reducer;
