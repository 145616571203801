import {
  createSelector,
} from 'reselect';

const differentialExpressionData = state => state.getIn(['geneDiseaseDifferentialExpressionSection', 'data']);
const loading = state => state.getIn(['geneDiseaseDifferentialExpressionSection', 'loading']);
const error = state => state.getIn(['geneDiseaseDifferentialExpressionSection', 'error']);

export const getDifferentialExpressionData = createSelector(
  differentialExpressionData,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
