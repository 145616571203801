import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '../../../Api/Api';

import * as ACTIONS from './reducer';

function* getAddConceptOptionsWorker(action) {
  try {
    yield put(ACTIONS.toggleAddConceptLoaderAction(true));
    const requestData = { input: action.payload };
    const { data } = yield call(Api.findConcepts, requestData);
    const options = data.map(item => ({
      ...item,
      value: item.name,
      label: item.name,
    }));
    yield put(ACTIONS.putAddConceptOptionsAction(options));
  } catch (error) {
    yield put(ACTIONS.throwAddConceptErrorAction(true));
    yield put(ACTIONS.toggleAddConceptLoaderAction(false));
  }
}

function* addConceptSaga() {
  yield takeLatest(ACTIONS.getAddConceptOptionsAction, getAddConceptOptionsWorker);
}

export default addConceptSaga;
