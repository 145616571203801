import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3js from 'd3';

// Icons
import { MdImage, MdSettingsBackupRestore, MdEdit, MdGridOn, MdSave } from 'react-icons/md';
// Components
import RelationDetailsModal from '../RelationDetailsModal/RelationDetailsModal';
import RelationMapProjectInfoModal from '../RelationMapProjectInfoModal/RelationMapProjectInfoModal';
import ShortConceptCard from '../../../../Concept/ShortConceptCard/ShortConceptCard';
import Tooltip from '../../../../common/Tooltip/Tooltip';
// Utils
import { exportToPNG, trimText } from '../../../../Utils/Utils';
import { RelationDetailsModalInitialValues, LINK_COLOR, LayoutAlgorhitms, RelationMapShapes } from '../../constants';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';
import { showTooltipAction, hideTooltipAction } from '../../../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../../../Concept/ShortConceptCard/reducer';
// Styles
import './styles.scss';

let SVG;
let ZOOM;
let SVG_GROUP;
let ZOOM_VALUE;

function initZoom() {
  SVG = d3js
    .select('#relation-map-chart')
    .select('svg');
  SVG_GROUP = SVG.select('g');

  const zoomed = () => {
    const { transform } = d3js.event;
    SVG_GROUP.attr('transform', transform);
    ZOOM_VALUE = transform;
  };

  ZOOM = d3js.zoom()
    .scaleExtent([1, 40])
    .on('zoom', zoomed);

  SVG.call(ZOOM);

  if (ZOOM_VALUE) {
    SVG.call(
      ZOOM.transform,
      d3js.zoomIdentity
        .translate(ZOOM_VALUE.x, ZOOM_VALUE.y)
        .scale(ZOOM_VALUE.k),
    );
  }
}

function plusClickHandler() {
  ZOOM.scaleBy(SVG.transition().duration(450), 1.3);
}

function minusClickHandler() {
  ZOOM.scaleBy(SVG.transition().duration(450), 1 / 1.3);
}

const propTypes = {
  data: PropTypes.instanceOf(Object),
  layout: PropTypes.instanceOf(Object),
  undo: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  isModal: PropTypes.bool,
  loading: PropTypes.bool,
  noCache: PropTypes.bool,
  hasCache: PropTypes.bool,
  withSave: PropTypes.bool,
  project: PropTypes.instanceOf(Object),
  settings: PropTypes.instanceOf(Object),
  cacheData: PropTypes.func,
  deleteLink: PropTypes.func,
  nodesIsFixed: PropTypes.bool,
  updateConcept: PropTypes.func,
  setProjectInfo: PropTypes.func,
  updateLinkLabel: PropTypes.func,
  updateNodesIsFixed: PropTypes.func,
  setShowConfirmDialog: PropTypes.func,
  setDimensions: PropTypes.func,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  saveRelationMap: PropTypes.func,
  semCategoriesOrder: PropTypes.instanceOf(Array),
  setShortConceptCardId: PropTypes.func,
};

const RelationMapChart = (props) => {
  const {
    data,
    layout,
    undo,
    width,
    height,
    loading,
    project,
    noCache,
    hasCache,
    withSave,
    settings,
    cacheData,
    deleteLink,
    nodesIsFixed,
    updateConcept,
    setProjectInfo,
    updateLinkLabel,
    updateNodesIsFixed,
    setShowConfirmDialog,
    setShortConceptCardId,
    saveRelationMap,
    setDimensions,
    showTooltip,
    hideTooltip,
  } = props;
  const downloadLinkRef = useRef(null);

  const [showProjectInfoModal, setShowProjectInfoModal] = useState(false);
  const [showRelationDetailsModal, setShowRelationDetailsModal] = useState(RelationDetailsModalInitialValues);

  function exportToPNGHandler() {
    const diagram = window.document.querySelector('#relation-map-chart svg');
    const name = `${project.name}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false);
    }
  }

  function exportToSIFHandler() {
    const linkedNodes = [];

    let exportData = data.links.reduce((string, link) => {
      link.triples.forEach((t) => {
        if (t.subject === link.source.conceptId) {
          string += `${link.source.name}\t${t.predicateName}\t${link.target.name}\n`;
        } else {
          string += `${link.target.name}\t${t.predicateName}\t${link.source.name}\n`;
        }
      });
      if (linkedNodes.indexOf(link.source.id) === -1) {
        linkedNodes.push(link.source.id);
      }
      if (linkedNodes.indexOf(link.target.id) === -1) {
        linkedNodes.push(link.target.id);
      }
      return string;
    }, '');

    data.nodes.forEach((n) => {
      if (linkedNodes.indexOf(n.conceptId) === -1) {
        exportData += `${n.name}\n`;
      }
    });

    const clickEvent = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': false,
    });

    const blob = new Blob([exportData], { type: ' type: "text/sif;charset=UTF-8"' });
    const csvUrl = window.URL.createObjectURL(blob);

    downloadLinkRef.current.setAttribute('download', 'export.sif');
    downloadLinkRef.current.setAttribute('href', csvUrl);

    downloadLinkRef.current.dispatchEvent(clickEvent);
  }

  function showRelationDetails(link) {
    const { source, target } = link;
    setShowRelationDetailsModal({
      show: true,
      config: {
        link,
        conceptIds: [source.conceptId, target.conceptId],
        conceptNames: [source.name, target.name],
      },
    });
  }

  function deleteLinkHandler() {
    const { config: { link: { linkId } } } = showRelationDetailsModal;
    setShowConfirmDialog({
      show: true,
      config: {
        onConfirm: () => {
          if (!noCache) {
            cacheData();
          }
          deleteLink(linkId);
          setShowRelationDetailsModal(RelationDetailsModalInitialValues);
        },
        text: 'Are you sure you want to remove link?',
      },
    });
  }

  const toggleConcept = useCallback((d) => {
    const updater = {
      id: d.id,
      data: { selected: !d.selected },
    };
    updateConcept(updater);
  }, [updateConcept]);

  const updateConceptPosition = useCallback((d) => {
    updateConcept({
      id: d.id,
      data: {
        x: d.x,
        y: d.y,
        fx: d.fx,
        fy: d.fy,
      },
    });
  }, [updateConcept]);

  const updateAllConceptsPosition = useCallback(() => {
    updateNodesIsFixed({
      fixed: true,
      nodes: data.nodes.reduce((nodes, item) => {
        const newNodes = { ...nodes };
        newNodes[item.id] = {
          ...item,
          x: item.x,
          y: item.y,
          fx: item.x,
          fy: item.y,
        };
        return newNodes;
      }, {}),
    });
  }, [data.nodes, updateNodesIsFixed]);


  const getLinkValue = useCallback((d) => {
    const { showCooccurrence, showCloselyRelatedPubs } = settings;

    if (showCooccurrence && d.cooccurrence) {
      return d.cooccurrence.relatedPubs;
    }

    if (showCloselyRelatedPubs && d.cooccurrence) {
      return d.cooccurrence.closelyRelatedPubs;
    }

    return d.triples.length;
  }, [settings]);

  const getLinkTextValue = useCallback((d) => {
    const value = getLinkValue(d);
    const { predicateName } = d.triples[0] || { predicateName: null };
    if (value === 0) {
      return null;
    }
    return value === 1 ? d.label || predicateName : value;
  }, [getLinkValue]);

  const getLinkCircleRadius = useCallback((d) => {
    return `${getLinkTextValue(d)}`.length > 3 ? 14 : 10;
  }, [getLinkTextValue]);

  const getLinksTextTransform = useCallback((d) => {
    const value = String(getLinkTextValue(d));
    return `translate(-${value.length * 2.5}, 3)`;
  }, [getLinkTextValue]);

  const getLinkWidth = useCallback((d, quantiles) => {
    const { proportionalLines } = layout;
    const value = getLinkValue(d);

    if (proportionalLines) {
      if (value === 0) {
        return '1';
      } else if (value <= quantiles.q1) {
        return '2';
      } else if (value <= quantiles.m) {
        return '3';
      } else if (value <= quantiles.q3) {
        return '4';
      } else if (quantiles.q3 < value) {
        return '4';
      }
    }

    return '1';
  }, [getLinkValue, layout]);

  const getLinkArrow = useCallback((d, quantiles, isEndLink) => {
    const { proportionalLines } = layout;
    const prefix = isEndLink ? 'end' : 'start';
    const value = getLinkValue(d);

    if (!proportionalLines) {
      return `url(#arrow-${prefix})`;
    }

    if (value === 0) {
      return `url(#arrow-${prefix})`;
    } else if (value <= quantiles.q1) {
      return `url(#arrow-${prefix}-2)`;
    } else if (value <= quantiles.m) {
      return `url(#arrow-${prefix}-3)`;
    }
    return `url(#arrow-${prefix}-4)`;
  }, [getLinkValue, layout]);

  const getArrows = useCallback((svg, id, refX, size, orient) => {
    svg.append('defs')
      .append('marker')
      .attr('id', id)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', refX)
      .attr('refY', 0)
      .attr('markerWidth', size)
      .attr('markerHeight', size)
      .attr('orient', orient)
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5 L10,0 L0, -5')
      .style('stroke', LINK_COLOR)
      .style('opacity', '1');
  }, []);

  const removeChart = useCallback(() => {
    d3js.selectAll('#relation-map-chart > *').remove();
  }, []);

  const drawChart = useCallback(() => {
    const { hideLabels } = settings;
    const { proportionalLines, algorithm } = layout;
    const quantiles = { q1: null, m: null, q3: null };
    const zoomValue = SVG_GROUP ? SVG_GROUP.attr('transform') : null;
    const isCoocurenceAlgorithm = algorithm === LayoutAlgorhitms.COOCCURRENCE;
    const maxValue = isCoocurenceAlgorithm ? Math.max(...data.links.map(l => getLinkValue(l))) : 0;

    const svg = d3js.select('#relation-map-chart')
      .append('svg')
      .attr('width', width - 5)
      .attr('height', height - 5)
      .style('background-color', '#fff')
      .append('g')
      .attr('transform', zoomValue);

    getArrows(svg, 'arrow-end', 16, 10, 'auto');
    getArrows(svg, 'arrow-start', 16, 10, 'auto-start-reverse');

    if (proportionalLines) {
      const quantileData = data.links
        .map(l => getLinkValue(l))
        .sort((a, b) => a - b);

      quantiles.q1 = d3js.quantile(quantileData, 0.25);
      quantiles.m = d3js.quantile(quantileData, 0.5);
      quantiles.q3 = d3js.quantile(quantileData, 0.75);

      getArrows(svg, 'arrow-end-2', 14, 8, 'auto');
      getArrows(svg, 'arrow-start-2', 14, 8, 'auto-start-reverse');
      getArrows(svg, 'arrow-end-3', 13, 5, 'auto');
      getArrows(svg, 'arrow-start-3', 13, 5, 'auto-start-reverse');
      getArrows(svg, 'arrow-end-4', 12, 5, 'auto');
      getArrows(svg, 'arrow-start-4', 12, 5, 'auto-start-reverse');
    }

    const simulation = d3js.forceSimulation()
      .force('link', d3js
        .forceLink()
        .id(d => d.id)
        .distance(d => (isCoocurenceAlgorithm ?
          Math.round((100 - ((getLinkValue(d) * 100) / maxValue)) * 5) : 500)
        )
      )
      .force('charge', d3js.forceManyBody().distanceMax(500))
      .force('collide', d3js.forceCollide().radius(80))
      .force('center', d3js.forceCenter(width / 2, height / 2));

    function dragstarted(d) {
      if (!d3js.event.active) simulation.alphaTarget(0.1).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3js.event.x;
      d.fy = d3js.event.y;
    }

    function dragended(d) {
      if (!d3js.event.active) simulation.alphaTarget(0);
      d.fx = d3js.event.x;
      d.fy = d3js.event.y;
      simulation.alpha(0);
      updateConceptPosition(d);
    }

    function showTooltipHandler(d) {
      const { conceptId } = d;
      const config = {
        uniqueKey: 'relationmap-tooltip',
        clientX: d3js.event.x,
        clientY: d3js.event.y,
      };

      setShortConceptCardId(conceptId);
      showTooltip(config);
    }

    const link = svg.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(data.links)
      .enter()
      .append('g')
      .on('click', showRelationDetails);

    const line = link.append('line')
      .filter(d => getLinkValue(d) > 0)
      .attr('stroke', LINK_COLOR)
      .attr('stroke-width', d => getLinkWidth(d, quantiles));

    if (layout.showArrows) {
      line.attr('marker-end', d => getLinkArrow(d, quantiles, true));
      line.attr('marker-start', d => (d.twoSided ? getLinkArrow(d, quantiles, false) : null));
    }

    const linkCircle = !hideLabels && link
      .filter(d => getLinkValue(d) > 1)
      .append('circle')
      .attr('fill', '#ffffff')
      .attr('stroke', '#76649d')
      .attr('stroke-width', '2px')
      .attr('r', getLinkCircleRadius);

    const linkText = !hideLabels && link
      .append('text')
      .attr('fill', '#2C243E')
      .attr('style', 'font-size: 9px')
      .attr('transform', getLinksTextTransform)
      .attr('class', d => (getLinkValue(d) === 1 ? 'link-text' : 'link-text-circle'))
      .text(d => getLinkTextValue(d));

    const linkUserLabel = !hideLabels && link
      .filter(d => getLinkValue(d) > 1)
      .append('text')
      .attr('fill', '#2C243E')
      .attr('style', 'font-size: 9px')
      .attr('transform', d => `translate(${getLinkCircleRadius(d) + 5}, 3)`)
      .attr('class', 'link-text')
      .text(d => d.label);

    const node = svg.append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(data.nodes)
      .enter()
      .append('g')
      .call(d3js.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended));

    node.filter(d => d.shape === RelationMapShapes.CIRCLE)
      .append('circle')
      .attr('r', 6)
      .style('fill', d => `#${d.color}`);

    node.filter(d => d.shape === RelationMapShapes.SQUARE)
      .append('rect')
      .attr('width', 12)
      .attr('height', 12)
      .attr('transform', 'translate(-6, -6)')
      .style('fill', d => `#${d.color}`);

    node.filter(d => d.shape === RelationMapShapes.RHOMB)
      .append('rect')
      .attr('width', 12)
      .attr('height', 12)
      .attr('transform', 'translate(0, -8) rotate(45)')
      .style('fill', d => `#${d.color}`);

    node.append('text')
      .text(d => d.name)
      .text(d => (
        algorithm === LayoutAlgorhitms.SHORT_TEXTS ?
          trimText(d.name, 25) : d.name
      ))
      .attr('x', 0)
      .attr('y', (d) => (
        algorithm === LayoutAlgorhitms.SEMANTIC_CATEGORIES && (d.index % 2) ?
          20 : -12
      ))
      .on('mouseover', showTooltipHandler)
      .on('mouseout', hideTooltip)
      .on('click', toggleConcept)
      .attr('class', d => (
        d.selected ?
          'node-text node-text_selected' :
          'node-text'
      ));

    function ticked() {
      line
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      if (!hideLabels) {
        linkCircle
          .attr('cx', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.x + 30 :
              (d.source.x + d.target.x) / 2
          ))
          .attr('cy', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.y - 45 :
              (d.source.y + d.target.y) / 2
          ));

        linkText
          .attr('x', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.x - 45 :
              (d.source.x + d.target.x) / 2
          ))
          .attr('y', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.y - 45 :
              (d.source.y + d.target.y) / 2
          ));

        linkUserLabel
          .attr('x', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.x - 45 :
              (d.source.x + d.target.x) / 2
          ))
          .attr('y', d => (
            d.source.x === d.target.x && d.source.y === d.target.y ?
              d.source.y - 45 :
              (d.source.y + d.target.y) / 2
          ));
      }

      node.attr('transform', d => `translate(${d.x},${d.y})`);
    }

    simulation
      .nodes(data.nodes)
      .on('tick', ticked);

    simulation
      .force('link')
      .links(data.links);

    if (!nodesIsFixed) {
      setTimeout(updateAllConceptsPosition, 100);
    }
  }, [data, getArrows, getLinkArrow, getLinkCircleRadius, getLinkTextValue, getLinkValue, getLinkWidth, getLinksTextTransform, height, hideTooltip, layout, nodesIsFixed, setShortConceptCardId, settings, showTooltip, toggleConcept, updateAllConceptsPosition, updateConceptPosition, width]);

  const initChart = useCallback(() => {
    removeChart();
    drawChart();
    initZoom();
  }, [drawChart, removeChart]);

  useEffect(() => {
    if (!loading && data) {
      initChart();
    }
  }, [data, layout, settings, loading, height, initChart]);

  useEffect(() => {
    setDimensions({ width, height });
  }, [width, height, setDimensions]);

  useEffect(() => {
    if (layout.algorithm === LayoutAlgorhitms.DEFAULT) {
      SVG.call(
        ZOOM.transform,
        d3js.zoomIdentity
          .translate(0, 0)
          .scale(1),
      );
    }
  }, [layout.algorithm]);

  return (
    <div className="relation-map-chart">
      {
        project &&
        <div
          className="relation-map-chart__title"
          onClick={() => setShowProjectInfoModal(true)}
        >
          <span>{project.name}</span>
          <MdEdit size={20} />
        </div>
      }
      <div id="relation-map-chart" />
      <div className="relation-map-chart__controls">
        <button
          disabled={!data}
          className="zoom-controls__btn zoom-controls__btn_plus circle-btn"
          onClick={plusClickHandler}
        />
        <button
          disabled={!data}
          className="zoom-controls__btn zoom-controls__btn_minus circle-btn"
          onClick={minusClickHandler}
        />
        {
          !noCache &&
          <button
            className="circle-btn"
            disabled={!hasCache}
            onClick={undo}
          >
            <MdSettingsBackupRestore size={22} />
          </button>
        }
        {
          withSave &&
          <button
            className="circle-btn"
            onClick={() => { saveRelationMap({ noReset: true }); }}
          >
            <MdSave size={22} />
          </button>
        }
        <button
          disabled={!data}
          className="circle-btn"
          onClick={exportToPNGHandler}
        >
          <MdImage size={22} />
        </button>
        <button
          disabled={!data}
          className="circle-btn"
          onClick={exportToSIFHandler}
        >
          <MdGridOn size={22} />
        </button>
      </div>
      <Tooltip uniqueKeyProp="relationmap-tooltip" delay={1500}>
        <ShortConceptCard key={`relationmap-tooltip-${Math.random()}`} />
      </Tooltip>
      {
        showProjectInfoModal &&
        <RelationMapProjectInfoModal
          project={project}
          isOpen={showProjectInfoModal}
          setProjectInfo={setProjectInfo}
          closeCb={() => setShowProjectInfoModal(false)}
        />
      }
      {
        showRelationDetailsModal.show &&
        <RelationDetailsModal
          settings={settings}
          deleteCb={deleteLinkHandler}
          updateLinkLabel={updateLinkLabel}
          isOpen={showRelationDetailsModal.show}
          closeCb={() => setShowRelationDetailsModal(RelationDetailsModalInitialValues)}
          {...showRelationDetailsModal.config}
        />
      }
      <a // eslint-disable-line
        hidden={true}
        style={{ display: 'none' }}
        ref={downloadLinkRef}
      />
    </div>
  );
};

RelationMapChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    layout: SELECTORS.getRelationMapLayoutSelector(state),
    project: SELECTORS.getRelationMapProjectInfoSelector(state),
    settings: SELECTORS.getRelationMapSettingsSelector(state),
    hasCache: SELECTORS.getRelationMapHasCacheSelector(state),
    nodesIsFixed: SELECTORS.getRelationMapNodesIsFixedSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProjectInfo(data) {
      dispatch(ACTIONS.setRelationMapProjectInfoAction(data));
    },
    updateConcept(data) {
      dispatch(ACTIONS.updateRelationMapConceptAction(data));
    },
    deleteLink(data) {
      dispatch(ACTIONS.deleteRelationMapLinkAction(data));
    },
    undo() {
      dispatch(ACTIONS.undoRelationMapAction());
    },
    cacheData() {
      dispatch(ACTIONS.cacheRelationMapDataAction());
    },
    updateNodesIsFixed(data) {
      dispatch(ACTIONS.updateRelationMapNodesIsFixedAction(data));
    },
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
    updateLinkLabel(data) {
      dispatch(ACTIONS.updateRelationMapLinkLabelAction(data));
    },
    saveRelationMap(data) {
      dispatch(ACTIONS.saveRelationMapAction(data));
    },
    setDimensions(data) {
      dispatch(ACTIONS.setRelationMapDimensionsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMapChart);

