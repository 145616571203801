export const TwoFactorAuthenticationOptions = [
  {
    value: 'DISABLED',
    label: 'Disabled (default)',
  },
  {
    value: 'EVERY_LOGIN',
    label: 'Ask on each login',
  },
  {
    value: 'ONCE_DAY',
    label: 'Ask once a day',
  },
];

export const TwoFactorAuthenticationEnums = {
  DISABLED: 'DISABLED',
  EVERY_LOGIN: 'EVERY_LOGIN',
  ONCE_DAY: 'ONCE_DAY',
};

export const AuthenticatorCodeTypesForModal = {
  LOGIN: 'LOGIN',
  TWO_FACTOR_AUTHENTICATION: 'TWO_FACTOR_AUTHENTICATION',
};
