import {
  createSelector,
} from 'reselect';

const sectionData = state => state.getIn(['geneDiseaseCellMolecular', 'data']);
const loading = state => state.getIn(['geneDiseaseCellMolecular', 'loading']);
const error = state => state.getIn(['geneDiseaseCellMolecular', 'error']);

export const getSectionData = createSelector(
  sectionData,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
