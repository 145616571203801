import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getPathwaysDataAction = createAction('@@pathways/GET_DATA');
export const setPathwaysDataAction = createAction('@@pathways/SET_DATA');
export const togglePathwaysLoaderAction = createAction('@@pathways/TOGGLE_LOADER');
export const throwPathwaysErrorAction = createAction('@@pathways/THROW_ERROR');
export const resetPathwaysDataAction = createAction('@@pathways/RESET_DATA');
export const getImageForExportAction = createAction('@@pathways/GET_IMAGE_FOR_EXPORT');

const initialState = fromJS({
  loading: false,
  error: null,
  image: {},
  pathwaysOptions: [],
});

const reducer = handleActions(
  {
    [getPathwaysDataAction]: state =>
      state
        .update('loading', () => true),
    [setPathwaysDataAction]: (state, { payload }) =>
      state
        .update('image', () => fromJS(payload.defaultImage))
        .update('pathwaysOptions', () => fromJS(payload.options)),
    [togglePathwaysLoaderAction]: (state, { payload }) =>
      state
        .update('loading', () => payload),
    [throwPathwaysErrorAction]: (state, { payload }) =>
      state
        .update('error', () => payload)
        .update('loading', () => false),
    [resetPathwaysDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
