import { takeLatest } from 'redux-saga/effects';

import { types } from '../constants';

import { getProteinStructureDataWorker } from './workers/getProteinStructureDataWorker';
import { getPDBEntriesWorker } from './workers/getPDBEntriesWorker';

function* geneDetailProteinStructureSaga() {
  yield takeLatest(types.GET_PROTEIN_STRUCTURE_DATA, getProteinStructureDataWorker);
  yield takeLatest(types.GET_PDB_ENTRIES, getPDBEntriesWorker);
}

export default geneDetailProteinStructureSaga;
