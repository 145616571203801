import {
  USER_ACTIVATION_REQUESTED,
} from './constants';

export const activateUser = data => (
  {
    type: USER_ACTIVATION_REQUESTED,
    data,
  }
);
