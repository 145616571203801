// Core
import { takeLatest } from 'redux-saga/effects';

// Workers
import { getPathwaysChartDataWorker } from './workers/getPathwaysChartDataWorker';
import { getImageForExportWorker } from './workers/getImageForExportWorker';
import { getPathwaysDataAction, getImageForExportAction } from '../reducer';

function* geneDetailPathwaysSaga() {
  yield takeLatest(getPathwaysDataAction, getPathwaysChartDataWorker);
  yield takeLatest(getImageForExportAction, getImageForExportWorker);
}

export default geneDetailPathwaysSaga;
