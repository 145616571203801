import { createSelector } from 'reselect';
import { getCurrentPageData } from '../../../common/Pagination/utils';

const searchWithSetsData = state => state.getIn(['searchWithSetsPageReducer', 'data']);
const searchWithSetsLoading = state => state.getIn(['searchWithSetsPageReducer', 'loading']);
const searchWithSetsError = state => state.getIn(['searchWithSetsPageReducer', 'error']);
const searchWithSetsSort = state => state.getIn(['searchWithSetsPageReducer', 'sort']);
const searchWithSetsPages = state => state.getIn(['searchWithSetsPageReducer', 'pages']);
const searchWithSetsCooccurrenceType = state => state.getIn(['searchWithSetsPageReducer', 'cooccurrenceType']);

export const getSearchWithSetsDataSelector = createSelector(
  searchWithSetsData,
  searchWithSetsPages,
  (data, pages) => {
    if (!data) return data;
    const d = data && data.toJS();
    const { pageNumber } = pages.toJS();
    return getCurrentPageData(d, pageNumber);
  }
);

export const getSearchWithSetsLoadingSelector = createSelector(
  searchWithSetsLoading,
  data => data
);

export const getSearchWithSetsErrorSelector = createSelector(
  searchWithSetsError,
  data => data
);

export const getSearchWithSetsSortSelector = createSelector(
  searchWithSetsSort,
  data => data && data.toJS()
);

export const getSearchWithSetsPagesSelector = createSelector(
  searchWithSetsPages,
  data => data && data.toJS()
);

export const getSearchWithSetsCooccurrenceTypeSelector = createSelector(
  searchWithSetsCooccurrenceType,
  data => data
);
