import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form/lib/immutable';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Components
import FormTextarea from '../../../../common/FormElements/FormTextarea';
import ConfirmationDialog from '../../../../Modals/ConfirmationDialog/ConfirmationDialog';
import Loader from '../../../../common/Loader/Loader';
import FormSelect from '../../../../common/FormElements/FormSelect';
// Store
import {
  getProjectsOptionsSelector,
  getProjectsOptionsLoadingSelector,
} from '../../../../Projects/ProjectsTabs/store/selectors';
import { getProjectsOptionsAction, resetProjectsOptionsAction } from '../../../../Projects/ProjectsTabs/store/reducer';
// Constants
import { COPY_RELATION_MAP_PROJECT } from '../../constants';

const propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  valid: PropTypes.bool,
  relationMaps: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  optionsLoading: PropTypes.bool,
  activeProjectId: PropTypes.string,
  getProjectsOptions: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
  multiselect: PropTypes.bool,
};

const CopyRelationMapsModal = (props) => {
  const {
    valid,
    isOpen,
    onCancel,
    onSubmit,
    options,
    optionsLoading,
    relationMaps,
    activeProjectId,
    getProjectsOptions,
    resetProjectsOptions,
    multiselect,
  } = props;

  const [warningMessage, toggleWarningMessage] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getProjectsOptions();
    } else {
      resetProjectsOptions();
    }
  }, [isOpen]);

  function findRelationMapNameRepeat(name) {
    return relationMaps.some(map => map.name.toLowerCase() === name.trim().toLowerCase());
  }

  function handleNameChange(e) {
    const nameRepeating = findRelationMapNameRepeat(e);

    if (nameRepeating) {
      toggleWarningMessage(true);
    } else if (warningMessage) {
      toggleWarningMessage(false);
    }
  }
  return (
    <ConfirmationDialog
      onConfirm={onSubmit}
      disabled={!valid || warningMessage}
      onCancel={onCancel}
      isOpen={isOpen}
      closeCb={onCancel}
      customClassName="copy-modal"
    >
      <div className="modal__copy-text">
        <form className="copy-modal__form">
          <div className="copy-modal__title title2">
            { `Copy Relation ${multiselect ? 'Maps' : 'Map'}` }
          </div>
          <Loader isLoading={optionsLoading} />
          {
            options && options.length > 0 &&
             <div className="copy-modal__block">
               <label className="copy-modal__label" htmlFor="project">
                 Select project
               </label>
               <Field
                 name="project"
                 options={options}
                 component={FormSelect}
                 returnOption={true}
                 bordered={true}
                 dropDownHeight={110}
                 selectedValue={options.find(o => o.id === activeProjectId) || options[0]}
               />
             </div>
          }
          {
            !multiselect &&
              <>
                <div className="copy-modal__block">
                  <label className="copy-modal__label" htmlFor="name">Relation map name</label>
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    component={FormTextarea}
                    onChange={handleNameChange}
                  />
                </div>
                {
                  warningMessage &&
                  <div className="copy-modal__warning">
                    <span className="copy-modal__warning-text">
                      This name already exists
                    </span>
                  </div>
                }
              </>
          }
        </form>
      </div>
    </ConfirmationDialog>
  );
};

CopyRelationMapsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: getProjectsOptionsSelector(state),
    optionsLoading: getProjectsOptionsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(resetProjectsOptionsAction());
    },
  };
}

function validate(values) {
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter relation map name';
  }
  if (values.get('name') && values.get('name').trim().length > 1000) {
    errors.name = 'Relation map name is too long. Enter less than 1000 symbols';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: COPY_RELATION_MAP_PROJECT,
    fields: ['name', 'project'],
    validate,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(CopyRelationMapsModal);
