import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

// Utils
import { exportToFile } from '../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Components
import Spinner from '../../../common/Spinner/Spinner';
import Loader from '../../../common/Loader/Loader';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import PatentTable from '../../../GeneDetails/Patents/PatentTable';
import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import SingleTargetForCancerHeatMap from './Components/SingleTargetForCancerHeatmap';
import SingleTargetForCancerBarChart from './Components/SingleTargetForCancerBarChart';
import SingleTargetForCancerCharts from './Components/SingleTargetForCancerCharts';
// Store
import {
  getTargetCandidatesLoadingSelector,
  getTargetCandidatesExportedDataSelector,
  getTargetCandidatesExportedDataLoadingSelector,
} from '../../store/selectors';
import {
  getTargetCandidatesPairsDataAction,
  exportTargetCandidatesPairsDataAction,
  resetTargetCandidatesPairsExportedDataAction,
} from '../../store/reducer';
import {
  setSingleTargetForCancerDataAction,
  sortSingleTargetForCancerDataAction,
} from './reducer';
import {
  hideTooltipAction,
  showTooltipAction,
} from '../../../common/Tooltip/store/reducer';
import {
  shortConceptCardSetIdAction,
} from '../../../Concept/ShortConceptCard/reducer';
import * as SELECTORS from './selectors';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  sort: PropTypes.instanceOf(Object),
  requestData: PropTypes.instanceOf(Object),
  exportedData: PropTypes.string,
  resetExportedData: PropTypes.func,
  loading: PropTypes.bool,
  exportedDataLoading: PropTypes.bool,
  sortData: PropTypes.func,
  exportData: PropTypes.func,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  getTargetCandidatesPairsData: PropTypes.func,
  setName: PropTypes.string,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
};

class SingleTargetForCancerContent extends React.Component {
  constructor(props) {
    super(props);
    this.columns = null;
    this.tumorSortDirection = true;
    this.conceptsIds = [];
    this.conceptsNames = [];
    this.state = {
      publicationsPopup: false,
      antibodiesPopup: {
        id: null,
        name: null,
        show: false,
      },
      chartsPopup: {
        gene: null,
        tumorLabel: null,
        show: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { exportedData } = this.props;
    if (exportedData && !prevProps.exportedData) {
      this.exportToCSV(exportedData);
    }
  }

  openPublicationsPopup = (gene, tumor) => {
    this.conceptsIds = [gene.id, tumor.id];
    this.conceptsNames = [gene.name, tumor.name];
    this.setState({ publicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ publicationsPopup: false });
  };

  openAntibodiesPopup = (id, name) => {
    this.setState({
      antibodiesPopup: {
        id,
        name,
        show: true,
      },
    });
  };

  closeAntibodiesPopup = () => {
    this.setState({
      antibodiesPopup: {
        id: null,
        name: null,
        show: false,
      },
    });
  };

  openChartsPopup = (id, name) => {
    const {
      data: {
        tableData,
      },
    } = this.props;
    const { label } = tableData.find(item => item.gene.id === id).tumor;
    this.setState({
      chartsPopup: {
        gene: {
          id: String(id),
          name,
        },
        tumorLabel: label,
        show: true,
      },
    });
  };

  closeChartsPopup = () => {
    this.setState({
      chartsPopup: {
        gene: null,
        show: false,
        tumorLabel: null,
      },
    });
  };

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  genesCell = ({ id, name, label }, rowIndex, showLink) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    let labelElement;
    if (label) {
      const nameLength = 35;
      const labelLength = 25;
      if (label.length < labelLength && name.length < nameLength) labelElement = () => <><br />[{label}]</>;
      if (label.length > labelLength && name.length < nameLength) labelElement = () => <><br />{`[${label.slice(0, labelLength)}...]`}</>;
      if (name.length > nameLength) labelElement = () => '...';
    } else labelElement = () => '';
    return (
      <ShortConceptCardCell
        id={id}
        link={showLink && link}
        uniqueKey={uniqueKey}
        name={name}
        label={labelElement()}
      />
    );
  };

  getColumns = (width) => {
    this.columns = [
      {
        label: 'Target',
        dataKey: 'gene',
        dataPath: ['gene', 'name'],
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(width, 35),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.gene ? this.genesCell(rowData.gene, rowIndex, true) : null
        ),
      },
      {
        label: 'Antibodies from patents',
        dataKey: 'antibodies',
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(width, 20),
        cellRenderer: ({ rowData }) => (
          rowData.antibodies > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openAntibodiesPopup(rowData.gene.id, rowData.gene.name); }}
            >
              {rowData.antibodies}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.antibodies}
            </div>
        ),
      },
      {
        label: 'Tumor',
        dataKey: 'tumor',
        dataPath: ['tumor', 'name'],
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(width, 30),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.tumor ? this.genesCell(rowData.tumor, rowIndex, false) : null
        ),
      },
      {
        label: 'References',
        dataKey: 'references',
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(width, 15),
        cellRenderer: ({ rowData }) => (
          rowData.references > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openPublicationsPopup(rowData.gene, rowData.tumor); }}
            >
              {rowData.references}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.references}
            </div>
        ),
      },
    ];
    return this.columns;
  };

  handlePageClick = (page) => {
    const {
      requestData,
      getTargetCandidatesPairsData,
    } = this.props;

    requestData.params = {
      size: 20,
      page: page.selected,
    };

    getTargetCandidatesPairsData(requestData);
  };

  handlePageSort = () => {
    const {
      requestData,
      pageNumber,
      getTargetCandidatesPairsData,
    } = this.props;

    requestData.params = {
      size: 20,
      page: pageNumber,
    };

    getTargetCandidatesPairsData(requestData);
  };

  handlePageExport = () => {
    const {
      requestData,
      exportData,
    } = this.props;

    exportData({ requestData });
  };

  exportToCSV = (data) => {
    const { resetExportedData, setName } = this.props;

    exportToFile(this.downloadLink, data, `Workflow for cancer ${setName}`);
    resetExportedData();
  };

  sortDataFromHeatMap = (newSortPath) => {
    const {
      sortData,
      sort: {
        sortBy,
        sortDirection,
        sortPath,
      },
    } = this.props;
    let newSortDirection = null;

    if (newSortPath !== sortPath) {
      newSortDirection = 'DESC';
    } else {
      newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    }

    sortData({
      sortBy,
      sortDirection: newSortDirection,
      sortPath: newSortPath,
    });

    this.handlePageSort();
  };

  render() {
    const {
      data: {
        tableData,
        heatMapData,
        barChartData,
      },
      sort: {
        sortBy,
        sortDirection,
      },
      totalPages,
      totalElements,
      pageNumber,
      loading,
      exportedDataLoading,
      showTooltip,
      hideTooltip,
      setShortConceptCardId,
    } = this.props;

    const {
      antibodiesPopup,
      publicationsPopup,
      chartsPopup,
    } = this.state;

    const tableSettings = {
      height: 500,
      headerHeight: 70,
      headerClassName: 'single-target-cancer-table__header',
      rowHeight: 150,
      sortBy,
      sortDirection,
      rowClassName: 'table-wrap__row',
    };

    const tableBlockClass = classNames('single-target-cancer__table', {
      'single-target-cancer__table_big': heatMapData && heatMapData.rows.length > 5,
    });

    const exportBtnClass = classNames('table__btn_export', {
      'table__btn_export_loading': exportedDataLoading,
    });

    const loaderClass = classNames('loading-overlay', {
      'loading-overlay_fixed': !tableData,
    });

    return (
      <div className="single-target-cancer">
        <div className={tableBlockClass}>
          {
            tableData && tableData.length > 0 &&
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                width ?
                  <div className="table-wrap" style={{ width }}>
                    <div className="single-target-cancer__table-total">
                      {`Total results: ${totalElements}`}
                    </div>
                    <div className="table__controls">
                      {
                        totalPages > 1 &&
                        <div className="table-wrap__pagination">
                          <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            pageCount={totalPages}
                            forcePage={pageNumber}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                          />
                        </div>
                      }
                      <button
                        className={exportBtnClass}
                        onClick={this.handlePageExport}
                      >
                        <span className="text-wrap">
                          Export to CSV
                        </span>
                        <Spinner
                          size="18"
                          isLoading={exportedDataLoading}
                          containerClassName="export-spinner"
                        />
                      </button>
                      <a //eslint-disable-line
                        hidden={true}
                        ref={(ref) => { this.downloadLink = ref; }}
                      />
                    </div>
                    <SimpleTable
                      ref={(ref) => { this.simpleTable = ref; }}
                      data={tableData}
                      autoSize={true}
                      width={width}
                      columns={this.columns ? this.columns : this.getColumns(width)}
                      settings={tableSettings}
                      dynamicHeight={true}
                      sortAction={sortSingleTargetForCancerDataAction}
                      serverSortAction={this.handlePageSort}
                      isServerSortPath={true}
                    />
                  </div> : null
              )}
            </AutoSizer>
          }
        </div>
        <div className="single-target-cancer__heatmap">
          {
            heatMapData && heatMapData.data.length > 0 &&
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                width ?
                  <SingleTargetForCancerHeatMap
                    data={heatMapData}
                    width={width}
                    sort={this.sortDataFromHeatMap}
                    openPublicationsPopup={this.openPublicationsPopup}
                    showTooltip={showTooltip}
                    hideTooltip={hideTooltip}
                    setShortConceptCardId={setShortConceptCardId}
                  /> :
                  null
              )}
            </AutoSizer>
          }
        </div>
        <div className="single-target-cancer__barchart">
          {
            barChartData &&
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                width ?
                  <SingleTargetForCancerBarChart
                    data={barChartData}
                    width={width}
                    openChartsPopup={this.openChartsPopup}
                  /> :
                  null
              )}
            </AutoSizer>
          }
        </div>
        {
          !loading && tableData && tableData.length === 0 &&
          <div className="algorithm-table__error single-target-cancer_nodata">
            There is no data to display
          </div>
        }
        {
          antibodiesPopup.show &&
          <ModalComponent
            isOpen={antibodiesPopup.show}
            closeCb={this.closeAntibodiesPopup}
          >
            <PatentTable
              geneName={antibodiesPopup.name}
              gene={String(antibodiesPopup.id)}
              itsNewConcept={true}
              parentClass="algorithm-antibodies"
              tableWidth={1000}
              hideInfo={true}
            />
          </ModalComponent>
        }
        {
          publicationsPopup &&
          <ModalComponent
            isOpen={publicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
        {
          chartsPopup.show &&
          <ModalComponent
            isOpen={chartsPopup.show}
            closeCb={this.closeChartsPopup}
            customClassName="target-candidates-pairs-chart"
          >
            <SingleTargetForCancerCharts
              geneId={chartsPopup.gene.id}
              geneName={chartsPopup.gene.name}
              tumorLabel={chartsPopup.tumorLabel}
            />
          </ModalComponent>
        }
        {
          loading &&
          <div className={loaderClass}>
            <div className="sticky-container">
              <Loader isLoading={loading} />
            </div>
          </div>
        }
      </div>
    );
  }
}

SingleTargetForCancerContent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getSingleTargetForCancerDataSelector(state),
    sort: SELECTORS.getSingleTargetForCancerSortSelector(state),
    exportedData: getTargetCandidatesExportedDataSelector(state),
    requestData: SELECTORS.getSingleTargetForCancerRequestDataSelector(state),
    pageNumber: SELECTORS.getSingleTargetForCancerPageNumberSelector(state),
    totalPages: SELECTORS.getSingleTargetForCancerTotalPagesSelector(state),
    totalElements: SELECTORS.getSingleTargetForCancerTotalElementsSelector(state),
    loading: getTargetCandidatesLoadingSelector(state),
    exportedDataLoading: getTargetCandidatesExportedDataLoadingSelector(state),
    setName: SELECTORS.getSingleTargetForCancerTissuesFormValues(state, 'setName'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSingleTargetForCancerData(data) {
      dispatch(setSingleTargetForCancerDataAction(data));
    },
    getTargetCandidatesPairsData(data) {
      dispatch(getTargetCandidatesPairsDataAction(data));
    },
    sortData(data) {
      dispatch(sortSingleTargetForCancerDataAction(data));
    },
    exportData(data) {
      dispatch(exportTargetCandidatesPairsDataAction(data));
    },
    resetExportedData() {
      dispatch(resetTargetCandidatesPairsExportedDataAction());
    },
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleTargetForCancerContent);
