import { createAction } from 'redux-actions';

export const getUploadFileSetDataAction = createAction('@@uploadFile/GET_SET_DATA');
export const throwUploadFileErrorAction = createAction('@@uploadFile/ERROR');
export const setUploadedFileToStoreAction = createAction('@@uploadFile/SET_FILE_TO_STORE');
export const resetUploadFileAction = createAction('@@uploadFile/RESET');
export const setUploadFileTypeAction = createAction('@@uploadFile/SET_TYPE');
export const toggleUploadFileLoadingAction = createAction('@@uploadFile/TOGGLE_LOADING');
export const succeededUploadFileAction = createAction('@@uploadFile/SUCCEEDED');
export const uploadFileForSetAnalysisAction = createAction('@@uploadFile/SET_ANALYSIS');
// Categories
export const getUploadFileCategoriesAction = createAction('@@uploadFile/GET_CATEGORIES');
export const setUploadFileCategoriesAction = createAction('@@uploadFile/SET_CATEGORIES');
// Setup
export const toggleUploadFileSemanticTypeAction = createAction('@@uploadFile/TOGGLE_ST');
export const toggleUploadFileSemanticCategoryAction = createAction('@@uploadFile/TOGGLE_SC');
export const setUploadFileImportSettingsAction = createAction('@@uploadFile/SET_IMPORT_SETTINGS');
// Taxonomies
export const getUploadFileTaxonomiesAction = createAction('@@uploadFile/GET_TAXONOMIES');
export const setUploadFileTaxonomiesAction = createAction('@@uploadFile/SET_TAXONOMIES');
