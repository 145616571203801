import { SearchSourceFilterEnum } from '../../Search/enums';

export const SourcesFilterEnum = {
  PUB_DATE: 'pub_date',
  SNIP: 'snip',
  CITED_BY: 'cited_by',
  SCORE: 'score',
};

export const SourcesOptionsEnum = [
  {
    value: SourcesFilterEnum.PUB_DATE,
    label: 'Most Recent',
    type: SourcesFilterEnum.PUB_DATE,
  },
  {
    value: SourcesFilterEnum.SCORE,
    label: 'Most Relevant',
    type: SourcesFilterEnum.SCORE,
  },
  {
    value: SourcesFilterEnum.SNIP,
    label: 'Impact factor',
    type: SourcesFilterEnum.SNIP,
  },
  {
    value: SourcesFilterEnum.CITED_BY,
    label: 'Cited by',
    type: SourcesFilterEnum.CITED_BY,
  },
];

export const TitleEnum = {
  pub_date: 'Latest Publications',
  snip: 'High Impact Publications',
  cited_by: 'Most Cited Publications',
  score: 'Most Relevant',
};

export const PublicationSourcesOptions = [
  {
    value: SearchSourceFilterEnum.ABSTRACT,
    label: 'Abstracts (co-occurrence)',
    type: SearchSourceFilterEnum.ABSTRACT,
  },
  {
    value: SearchSourceFilterEnum.SENTENCE,
    label: 'Abstracts (sentence co-occurrence)',
    type: SearchSourceFilterEnum.SENTENCE,
  },
  {
    value: SearchSourceFilterEnum.TITLE,
    label: 'Abstracts (title co-occurrence)',
    type: SearchSourceFilterEnum.TITLE,
  },
  {
    value: SearchSourceFilterEnum.DATABASE,
    label: 'Database annotations',
    type: SearchSourceFilterEnum.DATABASE,
  }
];
