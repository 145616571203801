import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import { getSavedSearchTerm, getSynonyms } from '../../../Search/store/selectors';
import {
  setPublicationDetailsDataAction,
  throwPublicationDetailsErrorAction,
  togglePublicationDetailsChapterAction,
} from '../../reducer';
// Utils
import { PubDetailsChaptersEnum } from '../../constants';
import { prepareHighlightsData, prepareSemanticCategoriesData, calcSliderValue } from '../../Utils';

export function* getPublicationDetailsDataWorker({ payload }) {
  try {
    const searchInputValue = yield select(getSavedSearchTerm) || '';
    const synonyms = yield select(getSynonyms) || [];
    const filteredSymbols = ['and', 'or', ')', '('];

    let searchTerms = synonyms.map(syn => ({
      id: null,
      name: syn,
    }));

    if (!searchTerms.length) {
      searchTerms = searchInputValue.split(' ')
        .filter(word => filteredSymbols.indexOf(word.toLowerCase()) === -1)
        .map(name => ({ id: null, name }));
    }

    const { data } = yield call(Api.publication, { post: { id: payload, searchTerms } });
    const semanticCategories = prepareSemanticCategoriesData(data);
    const highlights = prepareHighlightsData(data, semanticCategories);
    const sliderValue = calcSliderValue(data.concepts);
    yield put(setPublicationDetailsDataAction({
      data,
      semanticCategories,
      highlights,
      sliderValue,
    }));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationCitations,
      show: true,
    }));
  } catch (e) {
    yield put(throwPublicationDetailsErrorAction(e.message));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationCitations,
      show: true,
    }));
  }
}
