import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Constants
import { THEME } from '../../../../constantsCommon';
// Styles
import './index.scss';

const propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  active: PropTypes.bool,
  theme: PropTypes.oneOf([THEME.LIGHT, THEME.GREY, THEME.DARK, THEME.SEMI]),
  testId: PropTypes.string,
};

const Button = (props) => {
  const {
    text = '',
    type = 'button',
    disabled = false,
    onClick = () => { },
    customClassName,
    children,
    active,
    theme,
    testId,
    ...attrs
  } = props;

  const Tag = attrs.href ? 'a' : 'button';

  const className = classNames('button', customClassName, {
    'button_disabled': disabled,
    'button_grey': theme === THEME.GREY,
    'button_semi': theme === THEME.SEMI,
    'button_active': active,
  });

  return (
    <Tag
      {...attrs}
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {children || text}
    </Tag>
  );
};

Button.propTypes = propTypes;

export default React.memo(Button);
