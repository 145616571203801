import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import NoData from '../../../../common/NoData/NoData';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { mergeRelationMapLinksAction } from '../../store/actions';
import { getRelationMapLinksSelector } from '../../store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  updateItem: PropTypes.func,
  mergeLinks: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  links: PropTypes.instanceOf(Array),
};

const HideRelationTypesModal = (props) => {
  const {
    data,
    links,
    isOpen,
    closeCb,
    updateItem,
    mergeLinks,
  } = props;

  const listRef = useRef(null);
  const predicates = data ? Object.values(data).sort((a, b) => a.name.localeCompare(b.name)) : [];

  function onChange(item) {
    updateItem({
      ...item,
      checked: !item.checked,
    });
    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }

  function rowRenderer(rowData) {
    const { index, style } = rowData;
    const { id, name, checked } = predicates[index];

    return (
      <div
        key={id}
        style={style}
        className="relation-map-concepts-list__item"
      >
        <div className="relation-map-concepts-list__item-wrap">
          <div className="relation-map-concepts-list__name">
            <Checkbox
              id={`rt-${id}`}
              checked={checked}
              onChange={() => onChange(predicates[index])}
            />
            {name}
          </div>
        </div>
      </div>
    );
  }

  function apply() {
    const updatedLinks = links.reduce((newData, link) => {
      const d = { ...newData };
      d[link.linkId] = {
        ...link,
        triples: link.triples.map(t => ({
          ...t,
          hidenRealationType: data[t.predicateId].checked,
        })),
      };
      return d;
    }, {});
    mergeLinks(updatedLinks);
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="relation-types-modal">
        <div className="relation-types-modal__header">
          <div className="relation-types-modal__title title3">
            Hide Relation Types
          </div>
        </div>
        {
          data && predicates.length > 0 &&
          <>
            <div className="relation-types-modal__list">
              <List
                ref={listRef}
                width={370}
                height={Math.min(45 * predicates.length, 270)}
                rowCount={predicates.length}
                rowHeight={45}
                rowRenderer={rowRenderer}
              />
            </div>
            <div className="create-set-info__save">
              <button
                onClick={apply}
                className="button button-primary"
              >
                Apply
              </button>
            </div>
          </>
        }
        <NoData show={predicates.length === 0} />
      </div>
    </ModalComponent>
  );
};

HideRelationTypesModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getHideRelationTypesDataSelector(state),
    links: getRelationMapLinksSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setData(data) {
      dispatch(ACTIONS.setHideRelationTypesDataAction(data));
    },
    updateItem(data) {
      dispatch(ACTIONS.updateHideRelationTypesItemAction(data));
    },
    mergeLinks(data) {
      dispatch(mergeRelationMapLinksAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HideRelationTypesModal);
