import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu';

// Components
import Spinner from '../../common/Spinner/Spinner';
// Styles
import './DropdownMenuComponent.css';

const propTypes = {
  loadOptions: PropTypes.func,
  items: PropTypes.instanceOf(Object),
  openKey: PropTypes.bool,
  actions: PropTypes.instanceOf(Object),
  toggleName: PropTypes.string,
  btnClass: PropTypes.string,
  penaltyTissuesKey: PropTypes.bool,
  setResultColumn: PropTypes.string,
  savedSetId: PropTypes.string,
  disableRemove: PropTypes.bool,
  linkToggleElement: PropTypes.bool,
  alignRight: PropTypes.bool,
  annotationPage: PropTypes.bool,
  isOnSetResultPage: PropTypes.bool,
  savedProjectId: PropTypes.string,
  btn: PropTypes.bool,
};

class DropdownMenuComponent extends React.Component {
  state = {
    loadingOption: '',
  };

  componentDidMount() {
    if (this.props.loadOptions) {
      this.props.loadOptions();
    }
  }

  toggle = () => {
    if (this.props.items.length > 0) {
      if (this.props.openKey) {
        this.props.actions.menuClose();
      } else {
        this.props.actions.menuOpen();
      }
    }
  };

  select = (name, column, setId, projectId) => {
    const { actions } = this.props;
    actions.select(name, column, setId, projectId);
    actions.menuClose();
  };

  handleAsyncItemClick = (loadOptionsCb, optionName) => {
    loadOptionsCb();
    this.setState({
      loadingOption: optionName,
    });
  };

  render() {
    const {
      openKey,
      actions: { menuClose },
      toggleName,
      items,
      btnClass = '',
      penaltyTissuesKey,
      setResultColumn,
      savedSetId,
      savedProjectId,
      disableRemove,
      linkToggleElement,
      alignRight,
      annotationPage,
      isOnSetResultPage,
      btn,
    } = this.props;

    const buttonClass = classNames({
      btn: true && !!btn,
      [btnClass]: true,
      'toggleBtn-container': true,
    });

    const toggleElement = (
      <button
        className={buttonClass}
        onClick={this.toggle}
      >
        <div className="toggleBtn">{ toggleName }</div>
        { openKey && isOnSetResultPage && <i className="fa fa-cog toggle-icon" /> }
        { !openKey && isOnSetResultPage && <i className="fa fa-cog toggle-icon " /> }
        { openKey && !annotationPage && !isOnSetResultPage && <i className="fa fa-angle-down toggle-icon" /> }
        { !openKey && !annotationPage && !isOnSetResultPage && <i className="fa fa-angle-right toggle-icon" /> }
        { openKey && annotationPage && !isOnSetResultPage && <i className="fa fa-angle-up toggle-icon" /> }
        { !openKey && annotationPage && !isOnSetResultPage && <i className="fa fa-angle-down toggle-icon " /> }
      </button>
    );

    const toggleSpanElement = (
      <div
        role="presentation"
        className="toggleMenuElement"
        onClick={this.toggle}
      >
        { toggleName }
        { openKey && <i className="fa fa-arrow-up" /> }
        { !openKey && <i className="fa fa-arrow-down" /> }
      </div>
    );

    const menuOptions = {
      isOpen: openKey,
      close: menuClose,
      toggle: linkToggleElement ? toggleSpanElement : toggleElement,
      align: 'left',
      menuAlign: alignRight ? 'right' : 'left',
      textAlign: 'left',
      className: 'dropdown-menu-component',
      enterTimeout: 0,
      leaveTimeout: 0,
      animate: false,
      closeOnInsideClick: false,
    };

    const itemsMenu = items.map((item, index) => {
      if (item.sub && item.sub.length > 0) {
        const nestedProps = {
          toggle: (
            <div className="dropdown-menu-component">
              <div className="inline-block w-90pr">{ item.name }</div>
              <div className="inline-block w-10pr text-right">
                <i className="fa fa-angle-right toggle-icon" />
              </div>
            </div>
          ),
          animate: false,
          delay: 0,
          enterTimeout: 0,
          leaveTimeout: 0,
        };
        return (
          <NestedDropdownMenu key={index} {...nestedProps}>
            { item.sub.map((sub, key) => (
              <li // eslint-disable-line
                key={key}
                onClick={() => { this.select((sub.id || sub.name), setResultColumn, savedProjectId); }}
              >
                <span className="dropdown-menu-component">{ sub.name }</span>
              </li>
            ))}
          </NestedDropdownMenu>
        );
      } else if (item.loadNestedOptionsCb) {
        const { disabled } = item;
        const listClass = classNames({
          disabled,
        });
        return (
          <li key={index} className={listClass}>
            <div
              role="presentation"
              className="dropdown-menu-component"
              onClick={() => { this.handleAsyncItemClick(item.loadNestedOptionsCb, item.name); }}
            >
              <div className="inline-block w-90pr">{ item.name }</div>
              <div className="inline-block w-10pr text-right dropdown-menu-component__arrow">
                {
                  this.state.loadingOption === item.name ?
                    <Spinner isLoading={true} size="16" /> :
                    <i className="fa fa-angle-right toggle-icon" />
                }
              </div>
            </div>
          </li>
        );
      }
      if (penaltyTissuesKey) {
        const cl = classNames({
          'dropdown-menu-component': true,
          penaltyTissuesMenuItems: true,
          bold: item.selectedTypesAmount > 0,
        });
        return (
          <li // eslint-disable-line
            key={index}
            onClick={() => { this.select(item.name, setResultColumn, savedProjectId); }}
          >
            <div className={cl}>
              <div className="inline">{ item.name } </div>
              <div className="inline float-right">{ item.typeName }</div>
            </div>
          </li>
        );
      }
      /* For set result header drop downs */
      const disabled = (!savedSetId && item.action === 'edit') || (disableRemove && item.action === 'remove') || item.disabled;
      const listClass = classNames({
        disabled,
      });
      return (
        <li
          key={index}
          className={listClass}
        >
          <span
            role="presentation"
            onClick={() => {
              if (!disabled) {
                this.select((item.action || item.name), setResultColumn, savedSetId, savedProjectId);
              }
            }}
            className="dropdown-menu-component"
          >
            { item.name }
          </span>
        </li>
      );
    });

    return (
      <DropdownMenu {...menuOptions}>
        { itemsMenu }
      </DropdownMenu>
    );
  }
}

DropdownMenuComponent.propTypes = propTypes;

export default DropdownMenuComponent;
