import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';

export function drawStackedBoxPlot(args) {
  const {
    htmlRoot,
    data,
    showTitle,
    showLegend,
    chartTitle,
    subgroups,
    groupsKey,
    colors,
  } = args;

  const BOX_PLOT_WIDTH = 30;
  const margin =
    { top: 50, right: 150, bottom: 200, left: 80},
    width = data.length * BOX_PLOT_WIDTH,
    svgWidth = data.length * BOX_PLOT_WIDTH + margin.left + margin.right,
    height = 600;

  const yDomain = Math.round(d3js.max(data, d => d[subgroups[0]]) + d3js.max(data, d => d[subgroups[1]]));
  const bodyHtml = document.querySelector('body');
  d3js.selectAll(`#${htmlRoot} > *`).remove();

  function getBoxPlotTooltipTemplate(d) {
    return `
        <div class="chart-tooltip-boxplot__content chart-tooltip-stacked-boxplot__content">
          <span><b>${groupsKey}:</b> ${d.data[groupsKey]}</span><br/>
          <span><b>${subgroups[0]}:</b> ${d.data[subgroups[0]].toFixed(2)}</span><br/>
          <span><b>${subgroups[1]}:</b> ${d.data[subgroups[1]].toFixed(2)}</span><br/>
        </div>
      `;
  }

  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', 'chart-tooltip-boxplot chart-tooltip-stacked-boxplot');

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d) {
    tooltip
      .html(getBoxPlotTooltipTemplate(d))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
  }

  const svg = d3js.select(`#${htmlRoot}`)
    .append('svg')
    .attr('width', svgWidth)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const groups = data.map(d => (d[groupsKey]));

  const x = d3js.scaleBand()
    .domain(groups)
    .range([0, width])
    .padding([0.2]);

  const xAxis = svg.append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3js.axisBottom(x).tickSizeOuter(0));

  const y = d3js.scaleLinear()
    .domain([0, yDomain])
    .range([ height, 0 ]);

  const yAxis = svg.append('g')
    .call(d3js.axisLeft(y));

  yAxis.selectAll('text')
    .attr('x', -14)
    .style('font-size', '16px');

  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -((height) / 2))
    .attr('dy', '-50')
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text('Protein abundance');

  const color = d3js.scaleOrdinal()
    .domain(subgroups)
    .range(colors);

  const stackedData = d3js.stack()
    .keys(subgroups)(data);

  svg.append('g')
    .selectAll('g')
    .data(stackedData)
    .join('g')
    .attr('fill', d => color(d.key))
    .selectAll('rect')
    // enter a second time = loop subgroup per subgroup to add all rectangles
    .data(d => d)
    .join('rect')
    .attr('x', d => x(d.data[groupsKey]))
    .attr('y', d => y(d[1]))
    .attr('height', d => y(d[0]) - y(d[1]))
    .attr('width',x.bandwidth())
    .style('cursor', 'pointer')
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave);

  xAxis.selectAll('text')
    .attr('x', 15)
    .attr('y', 4)
    .style('text-anchor', 'start')
    .style('text-anchor', 'start')
    .style('font-size', '16px')
    .attr('transform', 'rotate(45)');

  if (showTitle) {
    const downloadedSvg = d3js.select(`#${htmlRoot}`)
      .select('svg')
      .attr('width', data.length < 15 ? svgWidth + 600 : svgWidth);

    downloadedSvg.append('text')
      .attr('class', 'chartTitle')
      .text(`${chartTitle}`)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    if (showLegend) {
      const SVGElement = d3js.select(`#${htmlRoot} svg`)
        .append('g')
        .attr('transform', () => `translate(-${margin.left}, ${height + 90})`);

      drawLegend({
        element: SVGElement,
        keys: subgroups,
        colors,
      });
    }
  }
}

