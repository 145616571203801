import {
  SORT,
} from '../../../common/SimpleTable/constants';

import {
  PATH_DETAILS_REQUESTED,
  SET_PATH_FOR_PATH_DETAILS,
  PATH_DETAILS_TABLE,
  PATH_DETAILS_RESET, GENE_LITERATURE_TABLE,
} from './constants';

export const setPathForPathDetails = data => (
  {
    type: SET_PATH_FOR_PATH_DETAILS,
    data,
  }
);

export const pathDetailsRequested = () => (
  {
    type: PATH_DETAILS_REQUESTED,
  }
);

export const sort = data => (
  {
    type: PATH_DETAILS_TABLE + SORT,
    data,
  }
);

export const literatureTableSortAction = data => (
  {
    type: GENE_LITERATURE_TABLE + SORT,
    data,
  }
);

export const resetPathDetailsAction = () => (
  {
    type: PATH_DETAILS_RESET,
  }
);
