import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as a from './actions';

const initialState = fromJS({
  result: null,
  gene: null,
  error: null,
  open: false,
  loading: false,
});

const reducer = handleActions(
  {
    [a.getBarChartDataAction]: state =>
      state
        .update('error', () => null)
        .update('loading', () => true),
    [a.setBarChartDataAction]: (state, { payload }) =>
      state
        .update('result', () => fromJS(payload))
        .update('error', () => null)
        .update('loading', () => false),
    [a.throwBarChartErrorAction]: (state, { payload }) =>
      state
        .update('error', () => payload)
        .update('loading', () => false),
    [a.openBarChartAction]: (state, { payload }) =>
      state
        .update('gene', () => payload)
        .update('open', () => true),
    [a.closeBarChartAction]: () => initialState,
  },
  initialState
);

export default reducer;
