import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import SematicMain from './SematicMain';
import SematicWithTaxonomies from './SematicWithTaxonomies';
// Constants
import { conceptTypesIdsEnum } from '../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  geneTaxonomies: PropTypes.instanceOf(Array),
  toggleTaxonomy: PropTypes.func,
};

const SemanticType = (props) => {
  const {
    id,
    onChange,
    checked,
    disabled = false,
    geneTaxonomies,
  } = props;

  const isGene = useMemo(() =>
    id === conceptTypesIdsEnum.GENE && geneTaxonomies && geneTaxonomies.length,
  [id, geneTaxonomies]);

  const handleOnChange = useCallback(() => {
    onChange(id, checked);
  }, [id, checked, onChange]);

  const className = classNames({
    'semantic-type': true,
    'semantic-type_disabled': disabled,
    'semantic-type_nopadding': isGene,
  });

  return (
    <div className={className}>
      {
        isGene ?
          <SematicWithTaxonomies
            {...props}
            handleOnChange={handleOnChange}
          /> :
          <SematicMain
            {...props}
            handleOnChange={handleOnChange}
          />
      }
    </div>
  );
};

SemanticType.propTypes = propTypes;

export default React.memo(SemanticType);
