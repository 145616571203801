import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Column,
  Table,
  AutoSizer,
  WindowScroller,
} from 'react-virtualized';
import classNames from 'classnames';

import Checkbox from '../Inputs/Checkbox/Checkbox';

import { getScreenWidth } from '../../Utils/Utils';
import {
  setHeatmapInitialDataAction,
  setHeatmapInitialNetworkDataAction,
} from '../../graphics/Heatmap/store/reducer';

import './SimpleTable.scss';

const propTypes = {
  dispatchAction: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  settings: PropTypes.instanceOf(Object),
  columns: PropTypes.instanceOf(Array),
  actions: PropTypes.instanceOf(Object),
  setHeatmapInitialData: PropTypes.func,
  setHeatmapInitialNetworkData: PropTypes.func,
  sortAction: PropTypes.func,
  serverSortAction: PropTypes.func,
  manageable: PropTypes.bool,
  onlySelect: PropTypes.bool,
  singleSelect: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fixedHeight: PropTypes.bool,
  showOnHeatMap: PropTypes.bool,
  networkAnalysis: PropTypes.bool,
  dynamicHeight: PropTypes.bool,
  rowClassName: PropTypes.func,
  isServerSortPath: PropTypes.bool,
  noRowsMsg: PropTypes.string,
  innerRef: PropTypes.instanceOf(Object),
};

class SimpleTable extends React.Component {
  dynamicHeight = () => {
    const {
      data,
      settings: {
        rowHeight,
        headerHeight,
      },
    } = this.props;

    return data.reduce((height, d, index) => {
      if (typeof rowHeight === 'function') {
        return height += rowHeight({ index });
      }
      return height += rowHeight;
    }, headerHeight);
  };

  rowGetter = ({ index }) => (
    this.props.data[index]
  );

  removeSelected = () => {
    this.props.dispatchAction(this.props.actions.removeSelected);
  };

  showOnHeatmap = () => {
    const { setHeatmapInitialData } = this.props;
    const selectedRows = this.props.data.filter(item => item.selected);
    let selectedIds = [];
    let connectedIds = [];
    const filteredConnectedIds = [];

    if (this.props.data[0].candidateGi) {
      selectedIds = selectedRows.map(item => item.candidateGi);
      selectedRows.forEach((item) => {
        connectedIds = connectedIds.concat(item.connectedConcepts);
        connectedIds.forEach((itemId) => {
          if (filteredConnectedIds.indexOf(itemId) === -1) {
            filteredConnectedIds.push(itemId);
          }
        });
      });
    } else if (this.props.data[0].geneGi) {
      selectedIds = selectedRows.map(item => item.geneGi);
      selectedRows.forEach((item) => {
        connectedIds = connectedIds.concat(item.connectedGenes.map(_item => _item.geneGi));
        connectedIds.forEach((itemId) => {
          if (filteredConnectedIds.indexOf(itemId) === -1) {
            filteredConnectedIds.push(itemId);
          }
        });
      });
    }

    setHeatmapInitialData({
      conceptIds: selectedIds,
      connectedConceptIds: connectedIds,
    });
  };

  showOnHeatMapForNetworkAnal = () => {
    const { setHeatmapInitialNetworkData } = this.props;
    const selectedRows = this.props.data.filter(item => item.selected);
    const dataForNetworkHeatmapRequest = selectedRows.map(item => (
      {
        path: item.path,
        pathScore: item.score,
      }
    ));
    setHeatmapInitialNetworkData(dataForNetworkHeatmapRequest);
  };

  invertSelection = () => {
    this.props.dispatchAction(this.props.actions.invertSelection);
  };

  checkItem = (e, id) => {
    const data = {
      checked: e.target.checked,
      id,
    };
    this.props.dispatchAction(this.props.actions.checkItem, data);
  };

  checkOneItem = (e, id) => {
    const {
      dispatchAction,
      actions: {
        checkOneItemAction,
      },
    } = this.props;
    const data = {
      checked: e.target.checked,
      id,
    };
    dispatchAction(checkOneItemAction, data);
  };

  checkAll = (key) => {
    this.props.dispatchAction(this.props.actions.checkAll, key);
  };

  checkIfAllChecked = () => (
    this.props.data.every(item => (
      item.selected
    ))
  );

  checkIfAnyChecked = () => (
    this.props.data.some(item => (
      item.selected
    ))
  );

  noRowsRenderer = () => {
    const { noRowsMsg = 'No rows' } = this.props;
    return (
      <div className="noRows">
        {noRowsMsg}
      </div>
    );
  };

  getSortPath = (sortByField) => {
    let sortPath = null;
    this.props.columns.forEach((item) => {
      if ((sortByField === item.dataKey) && item.sortPath) {
        sortPath = item.sortPath; // eslint-disable-line
      }
    });
    return sortPath;
  };

  getServerSortPath = (sortByField) => {
    let serverSortPath = null;
    this.props.columns.forEach((item) => {
      if ((sortByField === item.dataKey) && item.dataPath) {
        serverSortPath = item.dataPath.join('.');
      }
    });
    return serverSortPath;
  };

  getSortValueMapper = (sortByField) => {
    let sortValueMapper = null;
    this.props.columns.forEach((item) => {
      if ((sortByField === item.dataKey) && item.sortValueMapper) {
        sortValueMapper = item.sortValueMapper; // eslint-disable-line
      }
    });
    return sortValueMapper;
  };

  sort = ({ sortBy, sortDirection }) => {
    const {
      sortAction,
      dispatchAction,
      serverSortAction,
      isServerSortPath,
    } = this.props;

    const sortPath = isServerSortPath ?
      this.getServerSortPath(sortBy) :
      this.getSortPath(sortBy);

    const sortValueMapper = this.getSortValueMapper(sortBy);

    let sortPayload = null;

    if (sortPath) {
      sortPayload = { sortBy, sortDirection, sortPath };
    } else if (sortValueMapper) {
      sortPayload = { sortBy, sortDirection, sortValueMapper };
    } else {
      sortPayload = { sortBy, sortDirection };
    }

    if (sortAction) {
      dispatchAction(sortAction, sortPayload);
    }

    if (serverSortAction && typeof serverSortAction === 'function') {
      serverSortAction(sortPayload);
    }
  };

  render() {
    const {
      data,
      columns,
      manageable,
      onlySelect,
      singleSelect,
      width,
      height,
      fixedHeight,
      showOnHeatMap,
      networkAnalysis,
      dynamicHeight,
      rowClassName,
    } = this.props;

    let {
      settings,
    } = this.props;

    if (width) {
      settings = Object.assign({}, settings, { width });
    }

    if (height) {
      settings = Object.assign({}, settings, { height });
    }
    const allChecked = this.checkIfAllChecked();
    const expandableColumns = columns.slice(0);
    const screenWidth = getScreenWidth();

    const multiCheckColumn = {
      dataKey: 'id',
      disableSort: true,
      width: 50,
      cellRenderer: ({ rowData }) => (
        <Checkbox
          onChange={(e) => { this.checkItem(e, rowData.id); }}
          checked={rowData.selected}
        />
      ),
      headerRenderer: ({
        sortBy: headerSortBy,
        sortDirection: headerSortDirection,
      }) => {
        const dataKey = 'selected';
        const ascClasses = classNames({
          'fa fa-sort-asc': true,
          'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
        });
        const descClasses = classNames({
          'fa fa-sort-desc': true,
          'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
        });
        return (
          <div className="header-section">
            <Checkbox
              onChange={(e) => {
                this.checkAll(e.target.checked);
              }}
              checked={allChecked}
            />
            {
              settings.selectedSort &&
              <span className="sorting-section">
                <i
                  className={ascClasses}
                  onClick={() => {
                    this.sort({ sortBy: dataKey, sortDirection: 'ASC' });
                  }}
                />
                <i
                  className={descClasses}
                  onClick={() => {
                    this.sort({ sortBy: dataKey, sortDirection: 'DESC' });
                  }}
                />
              </span>
            }
          </div>
        );
      },
    };

    const singleCheckColumn = {
      dataKey: 'id',
      disableSort: true,
      width: 50,
      cellRenderer: ({ rowData }) => (
        <Checkbox
          onChange={(e) => { this.checkOneItem(e, rowData.id); }}
          checked={rowData.selected}
        />
      ),
    };

    const checkColumn = singleSelect ? singleCheckColumn : multiCheckColumn;

    if (manageable || onlySelect) {
      expandableColumns.unshift(checkColumn);
    }

    const ColumnsElements = expandableColumns
      .filter(item => !(item.visible === false))
      .map((column, index) => (
        <Column key={`col_${index}`} {...column} />
      ));

    const wrapperStyle = {
      width: settings.width,
    };

    const wrapperTableStyle = {
      width: screenWidth >= settings.width ? settings.width : '100%',
    };

    return (
      <div className="block-center scrollable-x-table" style={wrapperTableStyle}>
        {
          dynamicHeight &&
            <div className="dynamic-height-table">
              <div className="dynamic-height-table__wrap">
                <AutoSizer disableHeight={true}>
                  {
                    () => (
                      <Table
                        ref={this.props.innerRef}
                        {...settings}
                        height={this.dynamicHeight()}
                        sort={this.sort}
                        rowCount={data.length}
                        rowGetter={this.rowGetter}
                        noRowsRenderer={this.noRowsRenderer}
                        autoHeight={false}
                        rowClassName={rowClassName}
                      >
                        {ColumnsElements}
                      </Table>
                    )
                  }
                </AutoSizer>
              </div>
            </div>
        }
        {
          !fixedHeight && !dynamicHeight &&
            <WindowScroller>
              {({
                height, //eslint-disable-line
                isScrolling,
                onChildScroll,
                scrollTop,
              }) => (
                <Table
                  ref={this.props.innerRef}
                  {...settings}
                  autoHeight={true}
                  height={height || 0}
                  isScrolling={isScrolling}
                  onScroll={data.length ? onChildScroll : () => null}
                  scrollTop={scrollTop}
                  sort={this.sort}
                  rowCount={data.length}
                  rowGetter={this.rowGetter}
                  noRowsRenderer={this.noRowsRenderer}
                  rowClassName={rowClassName}
                >
                  {ColumnsElements}
                </Table>
              )}
            </WindowScroller>
        }
        {
          fixedHeight && !dynamicHeight &&
          <Table
            ref={this.props.innerRef}
            {...settings}
            sort={this.sort}
            rowCount={data.length}
            rowGetter={this.rowGetter}
            noRowsRenderer={this.noRowsRenderer}
            rowClassName={rowClassName}
          >
            {ColumnsElements}
          </Table>
        }
        {
          manageable &&
          <div className="table-bottom" style={wrapperStyle}>
            <div className="flex-grid">
              <div className="col-1">
                <button
                  type="button"
                  onClick={this.invertSelection}
                  className="btn btn-info"
                >
                  <i className="fa fa-adjust" />
                </button>
              </div>
              {
                showOnHeatMap &&
                <div className="col-1 text-center">
                  <button
                    disabled={!this.checkIfAnyChecked()}
                    type="button"
                    onClick={networkAnalysis ? this.showOnHeatMapForNetworkAnal : this.showOnHeatmap}
                    className="button button-primary"
                  >
                    Show on heatmap
                  </button>
                </div>
              }
              <div className="col-1 button-container-right">
                <button
                  disabled={!this.checkIfAnyChecked()}
                  type="button"
                  onClick={this.removeSelected}
                  className="btn btn-danger"
                >
                  <i className="fa fa-trash-o" />
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

SimpleTable.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    dispatchAction(action, data) {
      dispatch(action(data));
    },
    setHeatmapInitialData(data) {
      dispatch(setHeatmapInitialDataAction(data));
    },
    setHeatmapInitialNetworkData(data) {
      dispatch(setHeatmapInitialNetworkDataAction(data));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(SimpleTable);
