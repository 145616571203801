import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import NoData from '../../../../common/NoData/NoData';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { mergeRelationMapLinksAction } from '../../store/actions';
import { getRelationMapLinksSelector } from '../../store/selectors';
import { getAccessMappingsSelector } from '../../../../Header/selectors';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  updateItem: PropTypes.func,
  mergeLinks: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  links: PropTypes.instanceOf(Array),
};

const HideSourceModal = (props) => {
  const {
    data,
    links,
    isOpen,
    closeCb,
    updateItem,
    mergeLinks,
  } = props;

  const listRef = useRef(null);
  const sources = data ? Object.values(data).sort((a, b) => a.name.localeCompare(b.name)) : [];

  function onChange(item) {
    updateItem({
      ...item,
      checked: !item.checked,
    });
    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }

  function rowRenderer(rowData) {
    const { index, style } = rowData;
    const { name, checked } = sources[index];

    return (
      <div
        style={style}
        key={index}
        className="relation-map-concepts-list__item"
      >
        <div className="relation-map-concepts-list__item-wrap">
          <div className="relation-map-concepts-list__name">
            <Checkbox
              id={`hide-source-checkbox-${index}`}
              checked={checked}
              onChange={() => { onChange(sources[index]); }}
            />
            {name}
          </div>
        </div>
      </div>
    );
  }

  function apply() {
    const updatedLinks = links.reduce((newData, link) => {
      const d = { ...newData };
      d[link.linkId] = {
        ...link,
        triples: link.triples.map(t => ({
          ...t,
          hidenSource: t.accessMappings.every(s => data[s].checked),
        })),
      };
      return d;
    }, {});

    mergeLinks(updatedLinks);
    closeCb();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="hide-source-modal">
        <div className="hide-source-modal__header">
          <div className="hide-source-modal__title title3">
            Hide Source
          </div>
        </div>
        {
          data && sources.length > 0 &&
          <>
            <div className="hide-source-modal__list">
              <List
                ref={listRef}
                width={370}
                height={Math.min(sources.length * 45, 270)}
                rowCount={sources.length}
                rowHeight={45}
                rowRenderer={rowRenderer}
              />
            </div>
            <div className="create-set-info__save">
              <button
                onClick={apply}
                className="button button-primary"
              >
                Apply
              </button>
            </div>
          </>
        }
        <NoData show={sources.length === 0} />
      </div>
    </ModalComponent>
  );
};

HideSourceModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getHideSourceDataSelector(state),
    links: getRelationMapLinksSelector(state),
    accessMappings: getAccessMappingsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setData(data) {
      dispatch(ACTIONS.setHideSourceDataAction(data));
    },
    updateItem(data) {
      dispatch(ACTIONS.updateHideSourceItemAction(data));
    },
    mergeLinks(data) {
      dispatch(mergeRelationMapLinksAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HideSourceModal);
