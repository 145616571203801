import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const fetchTagsRequestAction = createAction('@@addTag/FETCH_TAGS_REQUEST');
export const fetchTagsSuccessAction = createAction('@@addTag/FETCH_TAGS_SUCCESS');
export const fetchTagsFailureAction = createAction('@@addTag/FETCH_TAGS_FAILURE');
export const toggleAddTagDataLoaderAction = createAction('@@addTag/TOGGLE_LOADER');

const initialState = fromJS({
  availableTags: [],
  isLoading: false,
  error: null,
});

const reducer = handleActions({
  [fetchTagsRequestAction]: state =>
    state.set('availableTags', fromJS([])),
  [fetchTagsSuccessAction]: (state, { payload }) =>
    state.set('availableTags', fromJS(payload)),
  [toggleAddTagDataLoaderAction]: (state, { payload }) =>
    state.update('isLoading', () => payload),
}, initialState);

export default reducer;

