import { call, put, takeLatest } from 'redux-saga/effects';

import * as a from './reducer';
import Api from '../../../../Api/Api';

function* getDiseaseOptionsWorker({ payload }) {
  try {
    yield put(a.toggleSelectDiseaseLoaderAction(true));
    const { data } = yield call(Api.searchDiseases, { query: payload });

    const options = data.results.map(item => ({
      ...item,
      name: item.value,
      label: item.value,
      id: item.key,
    }));

    yield put(a.putDiseaseOptionsAction(options));
  } catch (error) {
    yield put(a.throwSelectDiseaseErrorAction(true));
    yield put(a.toggleSelectDiseaseLoaderAction(false));
  }
}

function* selectDiseaseSaga() {
  yield takeLatest(a.getDiseaseOptionsAction, getDiseaseOptionsWorker);
}

export default selectDiseaseSaga;
