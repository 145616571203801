import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
// Constants
import { defaultProjects } from '../../../../Projects/ProjectsTabs/constants';
// Utils
import { getTotalPages } from '../../../../common/Pagination/utils';
// Actions
import * as a from './actions';

const initialState = fromJS({
  genes: {
    options: [],
    firstGene: {
      loading: false,
      value: '',
    },
    secondGene: {
      loading: false,
      value: '',
    },
    genesSet: {
      id: null,
      name: null,
      projectId: null,
    }
  },
  cellLinesData: {},
  cellLinesNoData: false,
  loading: false,
  cellLinesConfigurationsModal: {
    data: [],
    loading: false,
    error: '',
    activeProjectId: defaultProjects[0].id,
    filterValue: '',
    pages: {
      totalPages: 0,
      pageNumber: 0,
    },
  },
  deleteCellLinesConfigurationModal: {
    isOpen: false,
  },
  resultsData: {
    data: [],
    chartScale: 'linear',
    heatmap: null,
    loading: false,
    error: '',
    totalPages: 0,
    page: 0,
    sorting: {
      sortBy: 'cancer',
      sortDirection: 'ASC',
      sortPath: ['cancer', 'name'],
    },
  }
});

const reducer = handleActions(
  {
    [a.searchGenesForCellLineSelectionAction]: (state, { payload }) =>
      state
        // .updateIn(['genes', 'options'], () => fromJS([]))
        .updateIn(['genes', payload.geneNumber, 'loading'], () => true),
    [a.setGeneOptionsAction]: (state, { payload }) =>
      state
        .updateIn(['genes', 'options'], () => fromJS(payload.options))
        .updateIn(['genes', payload.geneNumber, 'loading'], () => false),
    [a.setSelectedGenesAction]: (state, { payload }) =>
      state
        .updateIn(['genes', 'genesSet'], () => initialState.get('genes').get('genesSet'))
        .updateIn(['genes', payload.geneNumber, 'value'], () => fromJS(payload.gene)),
    [a.setSelectedGenesSetAction]: (state, { payload }) =>
      state
        .updateIn(['genes'], () => initialState.get('genes'))
        .updateIn(['genes', 'genesSet'], () => fromJS(payload)),
    [a.getCellLinePanelDataAction]: (state) =>
      state
        .update('loading', () => true),
    [a.setCellLinePanelDataAction]: (state, {payload}) =>
      state
        .update('cellLinesData', () => fromJS(payload))
        .update('cellLinesNoData', () => Object.keys(payload).length === 0)
        .update('loading', () => false),
    [a.setSelectedCellLineDataAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesData', payload.cellLineGroup], content =>
          content.map((item) => {
            if (item.get('id') === payload.cellLineItem.id) {
              return item.merge(fromJS({selected: !item.get('selected')}));
            }
            return item;
          })),
    [a.setSelectedCellLinesDataAction]: (state, { payload }) =>
      state
        .update('cellLinesData', content => {
          const data = content.toJS();
          return fromJS(Object.keys(data).reduce((acc, item) => {
            acc[item] = data[item].map(el => {
              if (payload.includes(el.id)) return { ...el, selected: true };
              return ({ ...el, selected: false });
            });
            return acc;
          }, {}));
        }),
    [a.deselectAllCellLineDataAction]: (state) =>
      state
        .update('cellLinesData', content => {
          const data = content.toJS();
          return fromJS(Object.keys(data).reduce((acc, item) => {
            acc[item] = data[item].map(el => {
              return ({ ...el, selected: false });
            });
            return acc;
          }, {}));
        }),
    [a.selectAllCellLineDataAction]: (state) =>
      state
        .update('cellLinesData', content => {
          const data = content.toJS();
          return fromJS(Object.keys(data).reduce((acc, item) => {
            acc[item] = data[item].map(el => {
              return ({ ...el, selected: true });
            });
            return acc;
          }, {}));
        }),
    [a.setSelectedCellLinesGroupAction]:(state, {payload}) =>
      state
        .updateIn(['cellLinesData', payload.cellLineGroup], content =>
          content.map((item) => item.merge(fromJS({ selected: payload.checkedValue })))),
    [a.setProjectIdForCellLinesConfigurationModalAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'activeProjectId'], () => payload),
    [a.clearProjectIdForCellLinesConfigurationModalAction]: state =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'activeProjectId'], () => initialState.get('activeProjectId')),
    [a.getCellLinesConfigurationsAction]: (state) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'loading'], () => true)
        .updateIn(['cellLinesConfigurationsModal', 'error'], () => ''),
    [a.setCellLinesConfigurationsAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'loading'], () => false)
        .updateIn(['cellLinesConfigurationsModal', 'data'], () => fromJS(payload))
        .updateIn(['cellLinesConfigurationsModal', 'pages', 'pageNumber'], () => 0)
        .updateIn(['cellLinesConfigurationsModal', 'pages', 'totalPages'], () => payload ? getTotalPages(payload, 10) : 0),
    [a.throwCellLinesConfigurationsErrorAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'loading'], () => false)
        .updateIn(['cellLinesConfigurationsModal', 'error'], () => payload),
    [a.changeCellLinesConfigurationsPageAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'pages', 'pageNumber'], () => payload),
    [a.filterCellLinesConfigurationsAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'pages', 'pageNumber'], () => 0)
        .updateIn(['cellLinesConfigurationsModal', 'filterValue'], () => payload),
    [a.checkCellLinesConfigurationAction]: (state, {payload}) =>
      state
        .updateIn(['cellLinesConfigurationsModal', 'data'], (data) =>
          data.map((item) => {
            if (item.get('id') === payload.id) {
              return item.merge(fromJS({selected: payload.checked}));
            } else if (item.get('selected')) {
              return item.merge(fromJS({selected: false}));
            }
            return item;
          })),
    [a.resetCellLinesConfigurationsAction]: (state) =>
      state
        .update('cellLinesConfigurationsModal', () => initialState.get('cellLinesConfigurationsModal')),
    [a.toggleDeleteCellLinesConfigurationModalIsOpenAction]: (state, {payload}) =>
      state
        .updateIn(['deleteCellLinesConfigurationModal', 'isOpen'], () => payload),
    [a.getResultsDataAction]: (state) =>
      state
        .updateIn(['resultsData', 'loading'], () => true)
        .updateIn(['resultsData', 'error'], () => ''),
    [a.getResultsHeatmapDataAction]: (state) =>
      state
        .updateIn(['resultsData', 'loading'], () => true)
        .updateIn(['resultsData', 'error'], () => ''),
    [a.setResultsDataAction]: (state, { payload }) =>
      state
        .updateIn(['resultsData', 'loading'], () => false)
        .updateIn(['resultsData', 'page'], () => 0)
        .updateIn(['resultsData', 'totalPages'], () => Math.ceil((payload || []).length / 10))
        .updateIn(['resultsData', 'heatmap'], () => null)
        .updateIn(['resultsData', 'data'], () => fromJS(payload)),
    [a.setResultsHeatmapDataAction]: (state, { payload }) =>
      state
        .updateIn(['resultsData', 'loading'], () => false)
        .updateIn(['resultsData', 'data'], () => null)
        .updateIn(['resultsData', 'heatmap'], () => fromJS(payload)),
    [a.throwResultsDataErrorAction]: (state, { payload }) =>
      state
        .updateIn(['resultsData', 'loading'], () => false)
        .updateIn(['resultsData', 'error'], () => payload),
    [a.changeResultsDataPageAction]: (state, { payload }) =>
      state
        .updateIn(['resultsData', 'page'], () => payload),
    [a.sortResultsDataAction]: (state, { payload }) =>
      state
        .updateIn(['resultsData', 'sorting'], () => fromJS(payload)),
    [a.resetDataForNewGenesAction]: (state) =>
      state
        .update('cellLinesData', () => initialState.get('cellLinesData'))
        .update('resultsData', () => initialState.get('resultsData')),
    [a.resetFormDataAction]: (state) =>
      state
        .update('genes', () => initialState.get('genes')),
    [a.updateChartScaleAction]: (state, {payload}) =>
      state
        .updateIn(['resultsData', 'chartScale'], () => payload),
  },
  initialState
);

export default reducer;
