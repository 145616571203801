import { call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import * as a from '../../reducer';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

export function* getPathwaysChartDataWorker({ payload }) {
  try {
    const { data } = yield call(Api.getGeneDetailsReactomes, payload);
    if (data) {
      const { defaultImage, reactomes } = data;
      const filteredReactomes = reactomes.reduce((acc, r) => {
        if (acc.find(i => i.pathwayName === r.pathwayName)) return acc;
        acc.push(r);
        return acc;
      }, []);

      const options = filteredReactomes.map(item => ({
        ...item,
        value: item.pathwayName,
        label: item.pathwayName,
      }));
      yield put(a.setPathwaysDataAction({ defaultImage, options }));
      yield put(geneDetailsSetChapterAction('pathways'));
    }
    yield put(a.togglePathwaysLoaderAction(false));
    yield put(loadNextChapterAction('phenotypes'));
  } catch (e) {
    yield put(a.throwPathwaysErrorAction(e.message));
    yield put(loadNextChapterAction('phenotypes'));
  }
}
