import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import { AVERAGE_SYMBOL_LENGTH } from '../../../Utils/Utils';
import { getCancerDiseaseAnnotationSelector } from '../redux/selectors';
import { cancerDiseaseAnnotationChangePageAction } from '../redux/reducer';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../../common/ExportTable/ExportTable';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  diseaseAnnotationData: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  changePage: PropTypes.func,
};

class DiseaseAnnotationTable extends React.Component {
  state = {
    showPublicationsPopup: false,
    showInfoText: false,
  };
  simpleTableRef = React.createRef(null);
  conceptsNames = [];
  conceptsIds = [];

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const { changePage } = this.props;
    changePage(page.selected);
  };

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data;
    this.conceptsNames = [geneName, name];
    this.conceptsIds = [gene, id];
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  getRowHeight = ({ index }) => {
    const { diseaseAnnotationData: { data } } = this.props;
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(35);
    const stringLength = data[index].disease.name.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    const rowHeight = (stringRows * lineHeight) + padding;
    return rowHeight;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  showTooltipCell = ({ id, name }, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      diseaseAnnotationData: {
        data = [],
        allData = [],
        pageNumber,
        totalPages,
      },
      geneName,
      width,
    } = this.props;

    const {
      showPublicationsPopup,
      showInfoText,
    } = this.state;

    const tableSettings = {
      height: 500,
      width,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
    };

    const columns = [
      {
        label: 'Disease',
        dataKey: 'disease',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(35),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.disease ? this.showTooltipCell(rowData.disease, rowIndex) : null
        ),
      },
      {
        label: 'Inference concept',
        dataKey: 'inferenceConcept',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(35),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.inferenceConcept ? this.showTooltipCell(rowData.inferenceConcept, rowIndex) : null
        ),
      },
      {
        label: 'References',
        dataKey: 'publicationCount',
        width: this.getColumnPercentWidth(30),
        className: 'table-wrap__cell',
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData }) => (
          rowData.publicationCount > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openPublicationsPopup(rowData.disease); }}
            >
              {rowData.publicationCount}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.publicationCount}
            </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Disease annotation
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows diseases that have at least 5 gene co-mentions in Pubmed and in addition have an association with the gene based on either:
            </p>
            <ul>
              <li>A disease related phenotype (see Phenotypes section) or</li>
              <li>A manual disease annotation (see Functional Characterization section) or</li>
              <li>A molecular interaction with a known gene variant for the disease or</li>
              <li>A co-mention with the disease in a patent (see Patents section).</li>
            </ul>
            <p>
              The ‘Type of association’ column shows which of the four type of disease association is found.
              In case of an association based on a gene variant, the gene name is shown.
              For each of these disease associations all Pubmed articles that co-mention the gene are shown in the right hand column and can be accessed by clicking the link.
            </p>
          </div>
        }
        {
          data.length ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-cancer-disease-annotation`}
                    />
                  }
                </div>
              }
              <SimpleTable
                innerRef={this.simpleTableRef}
                data={data}
                autoSize={true}
                columns={columns}
                settings={tableSettings}
                dynamicHeight={true}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

DiseaseAnnotationTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    diseaseAnnotationData: getCancerDiseaseAnnotationSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(cancerDiseaseAnnotationChangePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiseaseAnnotationTable);

