import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import {
  getTooltipXSelector,
  getTooltipYSelector,
  getTooltipUniqueKeySelector,
} from './store/selectors';
// Styles
import './Tooltip.scss';

const propTypes = {
  children: PropTypes.instanceOf(Object),
  uniqueKey: PropTypes.string,
  uniqueKeyProp: PropTypes.string,
  delay: PropTypes.number,
  clientX: PropTypes.number,
  clientY: PropTypes.number,
};

const Tooltip = (props) => {
  const { innerHeight, innerWidth } = window;
  const {
    delay = 500,
    clientX,
    clientY,
    children,
    uniqueKey,
    uniqueKeyProp,
  } = props;

  const timeout = useRef();
  const tooltipRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const width = 800;
  const height = tooltipRef && tooltipRef.current ? tooltipRef.current.offsetHeight : 300;

  useEffect(() => {
    if (uniqueKey === uniqueKeyProp) {
      timeout.current = setTimeout(() => {
        setIsActive(true);
      }, delay);
    } else {
      setIsActive(false);
      clearTimeout(timeout.current);
    }
  }, [delay, uniqueKey, uniqueKeyProp]);

  const tooltipStyles = useMemo(() => {
    const bottom = innerHeight - clientY;
    const left = clientX + width > innerWidth ? innerWidth - width - 20 : clientX;

    return bottom < height ?
      {
        bottom: `${bottom + 50}px`,
        left: `${left}px`,
        width: `${width}px`,
      } : {
        top: `${clientY + 40}px`,
        left: `${left}px`,
        width: `${width}px`,
      };
  }, [innerHeight, clientY, clientX, innerWidth, height]);

  const tooltipArrowStyles = useMemo(() => {
    const halfWidth = width / 2;
    const marginLeft = clientX + width > innerWidth ?
      ((halfWidth + clientX) - innerWidth) + 20 :
      -halfWidth + 6;

    return innerHeight - clientY < height ?
      {
        marginLeft: `${marginLeft}px`,
        bottom: '-6px',
      } : {
        marginLeft: `${marginLeft}px`,
        top: '-6px',
      };
  }, [clientX, clientY, height, innerHeight, innerWidth]);

  if (!isActive) {
    return null;
  }

  return (
    <div
      className="tooltip"
      style={tooltipStyles}
      ref={tooltipRef}
    >
      <span
        className="tooltip__arrow"
        style={tooltipArrowStyles}
      />
      {children}
    </div>
  );
};

Tooltip.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    clientX: getTooltipXSelector(state),
    clientY: getTooltipYSelector(state),
    uniqueKey: getTooltipUniqueKeySelector(state),
  };
}

export default connect(mapStateToProps)(Tooltip);

