import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const activateUserTokenCheckAction = createAction('@@activateUser/CHECK_TOKEN');
export const activateUserTokenFailureAction = createAction('@@activateUser/FAILURE');
export const activateUserCheckTokenFailureAction = createAction('@@activateUser/TOKEN_FAILURE');
export const submitPasswordAction = createAction('@@activateUser/SUBMIT_NEW_PASSWORD');
export const setActivateUserModalOpenAction = createAction('@@activateUser/MODAL_OPEN');
export const setActivateUserModalCloseAction = createAction('@@activateUser/MODAL_CLOSE');

const initialState = fromJS({
  showModal: false,
  error: null,
  tokenError: '',
});

const reducer = handleActions(
  {
    [setActivateUserModalOpenAction]: state =>
      state
        .update('showModal', () => true),
    [setActivateUserModalCloseAction]: state =>
      state
        .update('showModal', () => false),
    [activateUserTokenFailureAction]: (state, payload) =>
      state
        .update('error', () => payload),
    [activateUserCheckTokenFailureAction]: (state, { payload }) =>
      state
        .update('tokenError', () => payload),
  },
  initialState
);

export default reducer;
