import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../../Api/Api';
import {
  setTargetCandidatesPairsFirstGeneChartsAction,
  targetCandidatesPairsFirstGeneChartsLoadingAction,
  targetCandidatesPairsFirstGeneChartsErrorAction,
} from '../../reducer';

export function* getTargetCandidatesPairsFirstGeneChartsWorker(action) {
  try {
    yield put(targetCandidatesPairsFirstGeneChartsLoadingAction(true));
    const { geneId, geneName } = action.payload;
    const requestData = { id: geneId };
    const { data } = yield call(Api.geneDetailExpression, requestData);
    const {
      gtexExpression,
      tcgaExpression,
    } = data;
    const tcgaExpressionFiltered = tcgaExpression.filter(el => el.group === 'Tumor');
    yield put(setTargetCandidatesPairsFirstGeneChartsAction({
      geneName,
      chartsData: {
        gtexExpression,
        tcgaExpression: tcgaExpressionFiltered,
      },
    }));
  } catch (e) {
    yield put(targetCandidatesPairsFirstGeneChartsErrorAction(e.message));
    yield put(targetCandidatesPairsFirstGeneChartsLoadingAction(false));
  }
}
