export const HEATMAP_COLOR = '#4caf50';
export const MAX_RECORDS_COUNT = 30000;

export const HeatmapSortByEnum = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
};

export const HeatmapTypeEnum = {
  PUBS: 'pubsCount',
  SCORE: 'score',
};

export const HeatmapSortDirEnum = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const HeatmapDeletingEnum = {
  DELETE_SELECTED: 'DELETE_SELECTED',
  DELETE_SELECTED_ROW: 'DELETE_SELECTED_ROW',
  DELETE_SELECTED_COL: 'DELETE_SELECTED_COL',
};

export const HeatmapSortingEnum = {
  SORT_ROWS: 'SORT_ROWS',
  SORT_COLUMNS: 'SORT_COLUMNS',
  SORT_ROWS_BY_CLUSTER: 'SORT_ROWS_BY_CLUSTER',
  SORT_COLUMNS_BY_CLUSTER: 'SORT_COLUMNS_BY_CLUSTER',
};

export const HeatmapDeletingOptions = [
  { value: HeatmapDeletingEnum.DELETE_SELECTED, label: 'Delete selected' },
  { value: HeatmapDeletingEnum.DELETE_SELECTED_ROW, label: 'Delete selected rows' },
  { value: HeatmapDeletingEnum.DELETE_SELECTED_COL, label: 'Delete selected columns' },
];

export const HeatmapSortingOptions = [
  { value: HeatmapSortingEnum.SORT_ROWS, label: 'Sort rows' },
  { value: HeatmapSortingEnum.SORT_COLUMNS, label: 'Sort columns' },
  { value: HeatmapSortingEnum.SORT_ROWS_BY_CLUSTER, label: 'Sort rows by cluster' },
  { value: HeatmapSortingEnum.SORT_COLUMNS_BY_CLUSTER, label: 'Sort columns by cluster' },
];

export const heatmapCooccurrenceTypes = {
  ABSTRACT: 'Abstract cooccurrence',
  SENTENCE: 'Sentence cooccurrence',
  TITLE: 'Title cooccurrence',
  ABSTRACT_JACKARD: 'Abstract cooccurrence (Jackard Index)',
  SENTENCE_JACKARD: 'Sentence cooccurrence (Jackard Index)',
  TITLE_JACKARD: 'Title cooccurrence (Jackard Index)',
};

export const heatmapCooccurrenceScoreTypes = {
  ABSTRACT: HeatmapTypeEnum.PUBS,
  SENTENCE: HeatmapTypeEnum.PUBS,
  TITLE: HeatmapTypeEnum.PUBS,
  ABSTRACT_JACKARD: HeatmapTypeEnum.SCORE,
  SENTENCE_JACKARD: HeatmapTypeEnum.SCORE,
  TITLE_JACKARD: HeatmapTypeEnum.SCORE,
};

export const heatmapCooccurrenceEnum = {
  ABSTRACT: 'ABSTRACT',
  SENTENCE: 'SENTENCE',
  TITLE: 'TITLE',
  ABSTRACT_JACKARD: 'ABSTRACT_JACKARD',
  SENTENCE_JACKARD: 'SENTENCE_JACKARD',
  TITLE_JACKARD: 'TITLE_JACKARD',
};

export const heatmapCooccurrenceTypesOptions = [
  {
    type: heatmapCooccurrenceTypes.ABSTRACT,
    value: heatmapCooccurrenceEnum.ABSTRACT,
    label: heatmapCooccurrenceTypes.ABSTRACT,
  },
  {
    type: heatmapCooccurrenceTypes.SENTENCE,
    value: heatmapCooccurrenceEnum.SENTENCE,
    label: heatmapCooccurrenceTypes.SENTENCE,
  },
  {
    type: heatmapCooccurrenceTypes.TITLE,
    value: heatmapCooccurrenceEnum.TITLE,
    label: heatmapCooccurrenceTypes.TITLE,
  },
  {
    type: heatmapCooccurrenceTypes.ABSTRACT_JACKARD,
    value: heatmapCooccurrenceEnum.ABSTRACT_JACKARD,
    label: heatmapCooccurrenceTypes.ABSTRACT_JACKARD,
  },
  {
    type: heatmapCooccurrenceTypes.SENTENCE_JACKARD,
    value: heatmapCooccurrenceEnum.SENTENCE_JACKARD,
    label: heatmapCooccurrenceTypes.SENTENCE_JACKARD,
  },
  {
    type: heatmapCooccurrenceTypes.TITLE_JACKARD,
    value: heatmapCooccurrenceEnum.TITLE_JACKARD,
    label: heatmapCooccurrenceTypes.TITLE_JACKARD,
  },
];
