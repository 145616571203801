import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { compose } from 'redux';
import { reduxForm } from 'redux-form/lib/immutable';
// Components
import FormInput from '../../../common/FormElements/FormInput';
import Error from '../../../common/Error/Error';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import Spinner from '../../../common/Spinner/Spinner';
// Store
import { checkTwoFactorAuthenticationDataAction } from '../store/reducer';
import {
  getAuthenticatorCodeError,
  getTwoFactorAuthenticationErrorSelector,
  getAuthenticatorCodeModalIsLoading,
} from '../store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  authError: PropTypes.string,
  loginError: PropTypes.string,
  type: PropTypes.string,
  checkTwoFactorAuthenticationData: PropTypes.func,
  isLoading: PropTypes.bool,
};

const AuthenticatorCodeModal = (props) => {
  const {
    isOpen,
    closeCb,
    error,
    authError,
    loginError,
    isLoading,
  } = props;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="authenticator-code-modal">
        <div className="authenticator-code-modal__header">
          <h3 className="authenticator-code-modal__title title3">
            Enter the code
          </h3>
        </div>
        <div className="authenticator-code-modal__content">
          <div className="text">
            Enter the 6-digit code displayed in the app
          </div>
          <form className="form-restore-password">
            <div className="row">
              <Field
                type="number"
                name="authenticatorCode"
                placeholder="Authenticator Code"
                component={FormInput}
                onKeyPress={onKeyPress}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
              />
            </div>
          </form>
          <Spinner
            isLoading={isLoading}
            containerClassName="row text-center"
          />
          <Error
            show={!!error}
            error={error}
            noTitle={true}
          />
          <Error
            show={!!authError || !!loginError}
            error={authError || loginError}
            noTitle={true}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

AuthenticatorCodeModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: getAuthenticatorCodeError(state),
    authError: getTwoFactorAuthenticationErrorSelector(state),
    isLoading: getAuthenticatorCodeModalIsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkTwoFactorAuthenticationData(data) {
      dispatch(checkTwoFactorAuthenticationDataAction(data));
    },
  };
}

function validationAuthenticatorCodeForm(values) {
  const errors = {};
  if (values.get('authenticatorCode') &&
    (values.get('authenticatorCode').trim().length < 6 || values.get('authenticatorCode').trim().length > 6)) {
    errors.authenticatorCode = 'The authenticator code should contain 6 numbers';
  }
  return errors;
}

let timeout = null;

const composition = compose(
  reduxForm({
    form: 'AUTHENTICATOR_CODE',
    fields: ['authenticatorCode'],
    onChange: (values, dispatch, props) => {
      const { authenticatorCode } = values.toJS();
      if (authenticatorCode?.length === 6) {
        clearTimeout(timeout);
        timeout = setTimeout(() => props.submit(), 1500);
      }
    },
    onSubmit: (formData, dispatch, props) => {
      const { type } = props;
      const { authenticatorCode } = formData.toJS();
      dispatch(checkTwoFactorAuthenticationDataAction({ authenticatorCode, type }));
    },
    validate: validationAuthenticatorCodeForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(AuthenticatorCodeModal);
