export const formatConceptsToSave = (concept, length, type) => ({
  id: length + 1,
  geneName: concept.name,
  mappedGenes: [{
    id: concept.id,
    brainName: concept.name,
    ...(type && { type }),
  }],
});

export const mapAndFormatConceptsToSave = (concepts, type) => (
  concepts.map((c, i) => formatConceptsToSave(c, i, type))
);
