import { fromJS } from 'immutable';

import {
  CHECK_ITEM,
  CHECK_ALL,
} from '../../common/SimpleTable/constants';

import {
  DIFFERENTIAL_EXPRESSION_INIT_SUCCEEDED,
  DIFFERENTIAL_EXPRESSION_INIT_FAILED,
  DIFFERENTIAL_EXPRESSION_TABLE_,
} from './constants';

const initialState = fromJS({
  data: {},
  error: null,
  loading: true,
});

const geneDiseaseExpression = (state = initialState, action) => {
  switch (action.type) {
    case DIFFERENTIAL_EXPRESSION_INIT_SUCCEEDED: {
      let { data } = action;
      let publications = data.publications || [];
      publications = publications.map((item, index) => Object.assign(item, { id: index + 1 }));
      data = Object.assign(data, { publications });

      return state.merge(fromJS({ data: action.data, error: null, loading: false }));
    }

    case DIFFERENTIAL_EXPRESSION_INIT_FAILED:
      return state.merge(fromJS({ data: {}, error: action.message, loading: false }));

    case DIFFERENTIAL_EXPRESSION_TABLE_ + CHECK_ALL:
      return state.update('data', data => (
        data.update('publications', publications => (
          publications.map(item => item.merge(fromJS({ selected: action.data })))
        ))
      ));

    case DIFFERENTIAL_EXPRESSION_TABLE_ + CHECK_ITEM:
      return state.update('data', data => (
        data.update('publications', publications => (
          publications.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    default:
      return state;
  }
};

export default geneDiseaseExpression;
