import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  index: PropTypes.number,
  data: PropTypes.instanceOf(Object),
};

class ChemicalModelImage extends React.Component {
  componentDidMount() {
    const {
      index,
      data: {
        compound: {
          correctCompoundImage,
        },
      },
    } = this.props;
    const chemViewer = new window.Kekule.ChemWidget.Viewer(document);
    const dataForImage = window.atob(correctCompoundImage);
    const myMolecule = window.Kekule.IO.loadFormatData(dataForImage, 'sd');

    chemViewer
      .setDimension('400px', '310px')
      .setEnableToolbar(false)
      .setEnableDirectInteraction(false)
      .setAutofit(true);
    chemViewer.getRenderConfigs().getColorConfigs()
      .setUseAtomSpecifiedColor(true);
    chemViewer.appendToElem(document.getElementById(`wrapper${index}`)).setChemObj(myMolecule);
  }

  componentWillUnmount() {
    const {
      index,
    } = this.props;
    document.getElementById(`wrapper${index}`).remove();
  }

  render() {
    const {
      index,
    } = this.props;
    return (
      <div id={`wrapper${index}`} />
    );
  }
}

ChemicalModelImage.propTypes = propTypes;

export default ChemicalModelImage;
