import { createSelector } from 'reselect';

const locationData = state => state.getIn(['literatureSearchReducer', 'data']);
const loading = state => state.getIn(['literatureSearchReducer', 'loading']);
const error = state => state.getIn(['literatureSearchReducer', 'error']);
const literatureSortingParams = state => state.getIn(['form', 'LITERATURE_FILTER_FORM', 'values']);

export const getSectionData = createSelector(
  locationData,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getLiteratureSortingParams = createSelector(
  literatureSortingParams,
  data => data && data.toJS()
);
