import { createSelector } from 'reselect';

import { PAGE_SIZE } from '../../constants';
import { filterPaginatedData } from '../../../Utils/Utils';

const pageChapter = 'geneDetailsDisordersChapter';

const disordersLoading = state => state.getIn([pageChapter, 'loading']);
const disordersError = state => state.getIn([pageChapter, 'error']);
const cancerDiagram = state => state.getIn([pageChapter, 'cancer', 'diagram']);
const cancerAnimalModels = state => state.getIn([pageChapter, 'cancer', 'animalModels']);
const cancerGeneticVariations = state => state.getIn([pageChapter, 'cancer', 'geneticVariations']);
const cancerDiseaseAnnotation = state => state.getIn([pageChapter, 'cancer', 'diseaseAnnotation']);
const disorderDiagram = state => state.getIn([pageChapter, 'disorder', 'diagram']);
const disorderAnimalModels = state => state.getIn([pageChapter, 'disorder', 'animalModels']);
const disorderGeneticVariations = state => state.getIn([pageChapter, 'disorder', 'geneticVariations']);
const disorderDiseaseAnnotation = state => state.getIn([pageChapter, 'disorder', 'diseaseAnnotation']);

export const getLoadingSelector = createSelector(
  disordersLoading,
  loading => loading
);

export const getErrorSelector = createSelector(
  disordersError,
  error => error
);

// Cancer
export const getCancerDiagramSelector = createSelector(
  cancerDiagram,
  data => data.toJS(),
);

export const getCancerAnimalModelsSelector = createSelector(
  cancerAnimalModels,
  (animalModelsData) => {
    const {
      data,
      pageNumber,
      totalPages,
      sorting,
    } = animalModelsData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
      sorting,
    };
  }
);

export const getCancerGeneticVariationsSelector = createSelector(
  cancerGeneticVariations,
  (geneticVariationsData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = geneticVariationsData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);

export const getCancerDiseaseAnnotationSelector = createSelector(
  cancerDiseaseAnnotation,
  (diseaseAnnotationData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = diseaseAnnotationData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);

// Disorder
export const getDisorderDiagramSelector = createSelector(
  disorderDiagram,
  data => data.toJS(),
);


export const getDisorderAnimalModelsSelector = createSelector(
  disorderAnimalModels,
  (animalModelsData) => {
    const {
      data,
      pageNumber,
      totalPages,
      sorting,
    } = animalModelsData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
      sorting,
    };
  }
);

export const getDisorderGeneticVariationsSelector = createSelector(
  disorderGeneticVariations,
  (geneticVariationsData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = geneticVariationsData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);

export const getDisorderDiseaseAnnotationSelector = createSelector(
  disorderDiseaseAnnotation,
  (diseaseAnnotationData) => {
    const {
      data,
      pageNumber,
      totalPages,
    } = diseaseAnnotationData.toJS();
    return {
      data: filterPaginatedData(data, pageNumber, PAGE_SIZE),
      allData: data,
      pageNumber,
      totalPages,
    };
  }
);
