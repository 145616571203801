import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './FormCheckbox.scss';

const propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  infoTooltip: PropTypes.instanceOf(Object),
  labelValue: PropTypes.string,
};

class FormCheckbox extends React.Component {
  render() {
    const {
      input: {
        value,
        onChange,
        ...restInput
      },
      meta: {
        error,
        touched,
      },
      labelValue,
      infoTooltip,
      ...restCustom
    } = this.props;

    return (
      <div className="cr-checkbox">
        <label>
          <input
            type="checkbox"
            className="cr-checkbox__input"
            checked={value}
            onChange={e => onChange(e.target.checked)}
            {... restInput}
            {... restCustom}
          />
          <div className="cr-checkbox__label">
            {
              labelValue &&
              <div className="cr-checkbox__label-value">
                <span>
                  {labelValue}
                  {
                    infoTooltip &&
                    <span
                      className="fa fa-info-circle icon first-info-icon cr-checkbox__icon"
                      data-tip={true}
                      data-for={infoTooltip.id}
                    />
                  }
                </span>
              </div>
            }
          </div>
        </label>
        {
          touched && error &&
          <div className="error-text">{error}</div>
        }
        {
          infoTooltip &&
          <ReactTooltip
            id={infoTooltip.id}
            place="right"
          >
            {infoTooltip.text}
          </ReactTooltip>
        }
      </div>
    );
  }
}

FormCheckbox.propTypes = propTypes;

export default FormCheckbox;
