import { takeLatest, call, put, select, all } from 'redux-saga/effects';

// Store
import * as a from './actions';
import * as s from './selectors';
import { getDatasetsAction, setDatasetsAction, throwDatasetsErrorAction } from '../Components/DatasetsSelector/store/reducer';
// Utils
import Api from '../../../../../Api/Api';
import { getFiltersOptions, getUniqueDataValues } from '../utils';
import { graphFilterOptions } from '../constants';
import { isEmptyObject } from '../../../../Utils/Utils';

function* getDatasetsWorker() {
  try {
    const { data } = yield call(Api.getSingleCellDatasets);
    const datasetOptions = data.map(i => ({
      ...i,
      name: i.tumorType,
      value: i.tumorType,
      label: i.tumorType,
    }));
    yield put(setDatasetsAction(datasetOptions));
  } catch (e) {
    yield put(throwDatasetsErrorAction(e.message));
  }
}

function* getColorsDataWorker({ payload }) {
  try {
    const { data: colors } = yield call(Api.getSingleCellColors, payload);
    const { data: markers } = yield call(Api.getSingleCellMarkers);
    colors.cellType = colors.cell_type;
    delete colors.cell_type;
    yield put(a.setColorsDataAction({ colors, markers }));

    const colourByOptions = graphFilterOptions.filter((option) => {
      if (isEmptyObject(colors[option.value])) return null;
      return colors[option.value];
    });

    const filtersData = getFiltersOptions(colors);
    yield put(a.setMetaDataFiltersOptionsAction({ colourByOptions, filtersData }));
  } catch (e) {
    console.log(e);
  }
}

function* getMetaDataWorker({ payload: { datasets, tumorType } }) {
  try {
    const { field } = yield select(s.getSelectedFilterValueSelector);
    const data = yield select(s.getMetaDataSelector);
    if (!data.length) {
      const result = yield all(datasets.map(dataset => call(Api.getSingleCellMetaData, { dataset })));
      const metaData = result.map(d => d.data);
      const additionalData = metaData.map(item => getUniqueDataValues(item));

      yield put(a.setMetaDataAction({
        data: metaData,
        measure: additionalData.map(b => b.measure),
        uniqueCells: additionalData.map(b => b.uniqueCells),
      }));
    }
    const backgrounds = yield all(datasets.map(dataset => call(Api.getSingleCellGraphBackground,
      {
        dataset,
        field,
        tumorType,
        diameter: 1,
      })));
    yield put(a.setMetaDataBackgroundsAction(backgrounds.map(b => b.data)));
    yield put(a.toggleMetaDataLoadingAction(false));
  } catch (e) {
    yield put(a.throwMetaDataErrorAction(e.message));
  }
}

function* getGeneDataWorker({ payload: { datasets, tumorType, geneId } }) {
  try {
    const dataFilters = yield select(s.getDataFiltersSelector);
    const dataFiltersKeys = Object.keys(dataFilters);

    const requestBody = dataFiltersKeys.reduce((acc, key) => {
      const selectedFilters = dataFilters[key].selectedOptions.map(option => option.value);
      if (selectedFilters.length) {
        acc[key] = selectedFilters;
      }
      return acc;
    }, {});

    if (!isEmptyObject(requestBody)) {
      requestBody.cell_type = requestBody.cellType;
      delete requestBody.cellType;
    }

    const result = yield all(datasets.map(dataset => call(Api.getSingleCellGeneData, { params: { dataset }, requestBody, geneId })));
    const resultData = result.reduce((acc, r) => {
      acc.geneData.push(r.data.data);
      acc.zerosData.push(r.data.zeroCelltypes);
      acc.measureExtremes.push(r.data.measureExtremes);

      return acc;
    }, { geneData: [], zerosData: [], measureExtremes: [] });

    const backgrounds = yield all(datasets.map(dataset => call(Api.getSingleCellGraphBackground,
      {
        dataset,
        tumorType,
        diameter: 1,
        requestBody,
      })));

    yield put(a.setGeneDataAction({
      ...resultData,
      backgroundGraphs: backgrounds.map(b => b.data),
    }));
  } catch (e) {
    yield put(a.throwGeneDataErrorAction(e.message));
  }
}

function* singleCellStudySaga() {
  yield takeLatest(getDatasetsAction, getDatasetsWorker);
  yield takeLatest(a.getColorsDataAction, getColorsDataWorker);
  yield takeLatest(a.getMetaDataAction, getMetaDataWorker);
  yield takeLatest(a.getGeneDataAction, getGeneDataWorker);
}

export default singleCellStudySaga;
