export const PAGE_SIZE = 20;

export const algorithmEnums = {
  biSpecificAntibody: 'BISPECIFIC_ANTIBODY',
  singleTargetForCancer: 'SINGLE_TARGET_FOR_CANCER',
  singleCellStudyViewer: 'SINGLE_CELL_STUDY_VIEWER',
  indicationFinder: 'INDICATION_FINDER',
  cellLineSelection: 'CELL_LINE_SELECTION',
};

export const pageAlgorithmEnums = {
  biSpecificAntibody: 'TARGET_DISCOVERY_BISPECIFIC_ANTIBODY',
  singleTargetForCancer: 'TARGET_DISCOVERY_SINGLE_TARGET_FOR_CANCER',
  singleCellStudyViewer: 'TARGET_DISCOVERY_SINGLE_CELL_STUDY',
  indicationFinder: 'TARGET_DISCOVERY_INDICATION_FINDER',
  cellLineSelection: 'TARGET_DISCOVERY_CELL_LINE_SELECTION',
};

export const algorithmOptions = [
  {
    value: 'Bispecific Antibody',
    label: 'Bispecific Antibody',
    type: pageAlgorithmEnums.biSpecificAntibody,
  },
  {
    value: 'Single Target for Cancer',
    label: 'Single Target for Cancer',
    type: pageAlgorithmEnums.singleTargetForCancer,
  },
  {
    value: 'Single Cell Study Viewer',
    label: 'Single Cell Study Viewer',
    type: pageAlgorithmEnums.singleCellStudyViewer,
  },
  {
    value: 'Indication Finder',
    label: 'Indication Finder',
    type: pageAlgorithmEnums.indicationFinder,
  },
  {
    value: 'Cell Line Selection',
    label: 'Cell Line Selection',
    type: pageAlgorithmEnums.cellLineSelection,
  },
];

export const measureOptions = [
  {
    value: 'Median expression',
    label: 'Median expression',
    type: 'MEDIAN_EXPRESSION',
  },
  {
    value: 'Third quartile expression level',
    label: 'Third quartile expression level',
    type: 'THIRD_QUARTILE_EXPRESSION',
  },
  {
    value: 'Average of third quartile and maximum expression level',
    label: 'Average of third quartile and maximum expression level',
    type: 'AVERAGE_OF_THIRD_QUARTILE',
  },
  {
    value: 'Maximum expression level',
    label: 'Maximum expression level',
    type: 'MAXIMUM_EXPRESSION',
  },
];

export const BISPECIFIC_ANTIBODY_FORM_NAME = 'BISPECIFIC_ANTIBODY_FORM';
