import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { scrollIntoView } from '../../../Utils/Utils';
// Store
import {
  CATEGORY_ANALYSIS_CATEGORIES_REQUESTED,
  CATEGORY_ANALYSIS_CATEGORIES_FAILED,
  CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED,
  TISSUES_REQUESTED,
  TISSUES_SUCCEEDED,
  TISSUES_FAILED,
  INIT_ANALYSIS_METHOD_SELECTION,
  GET_BACKGROUND_CELLS,
} from './constants';
import {
  methodSelectedAction,
  selectCategoryName,
  selectCategoryTypeAction,
  setBackgroundCellsAction,
  toggleBackgroundCellsLoaderAction,
} from './actions';
import { getCerebrumSemanticCategoriesSelector } from '../../../Header/selectors';

function* saga() {
  try {
    const categories = yield select(getCerebrumSemanticCategoriesSelector);
    const data = categories.map(({ name, st }) => ({
      name,
      category: name,
      types: st,
    }));
    yield put({ type: CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED, data });
  } catch (e) {
    yield put({ type: CATEGORY_ANALYSIS_CATEGORIES_FAILED, message: e.message });
  }
}

function* tissue() {
  try {
    const response = yield call(Api.tissue);
    yield put({ type: TISSUES_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: TISSUES_FAILED, message: e.message });
  }
}

function* selectAnalyticsMethod(action) {
  try {
    let data = null;
    let categoryName = null;
  
    if (action.data === 'T049') {
      data = { id: 'T049', name: 'Cell or Molecular Dysfunction' };
      categoryName = 'Disorders';
    } else if (action.data === 'T042') {
      data = { id: 'T042', name: 'Organ or Tissue Function' };
      categoryName = 'Physiology';
    }
  
    if (data && categoryName) {
      yield put(selectCategoryName(categoryName));
      yield put(selectCategoryTypeAction(data));
      yield put(methodSelectedAction('category'));
      scrollIntoView('#analyses-result-col');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getBackgroundCells() {
  try {
    yield put(toggleBackgroundCellsLoaderAction(true));
    const response = yield call(Api.getBackgroundCells);
    yield put(setBackgroundCellsAction(response.data));
    yield put(toggleBackgroundCellsLoaderAction(false));
  } catch (e) {
    console.log(e);
  }
}

function* setAnalysisMethodSelection() {
  yield takeLatest(CATEGORY_ANALYSIS_CATEGORIES_REQUESTED, saga);
  yield takeEvery(TISSUES_REQUESTED, tissue);
  yield takeEvery(GET_BACKGROUND_CELLS, getBackgroundCells);
  yield takeLatest(INIT_ANALYSIS_METHOD_SELECTION, selectAnalyticsMethod);
}

export default setAnalysisMethodSelection;
