// Core
import { takeLatest } from 'redux-saga/effects';

// Types
import { types } from '../constants';

// Workers
import { getPatentsWorker } from './workers/getPatentsWorker';
import { getPatentInfoWorker } from './workers/getPatentInfoWorker';
import { getPatentsSequencesWorker } from './workers/getPatentsSequencesWorker';
import { getPatentsChemicalsWorker } from './workers/getPatentsChemicalsWorker';

function* watchTherapeuticCandidatesChapter() {
  yield takeLatest(types.GET_PATENTS, getPatentsWorker);
  yield takeLatest(types.GET_PATENT_INFO, getPatentInfoWorker);
  yield takeLatest(types.GET_PATENTS_SEQUENCES, getPatentsSequencesWorker);
  yield takeLatest(types.GET_PATENTS_CHEMICALS, getPatentsChemicalsWorker);
}

export default watchTherapeuticCandidatesChapter;
