import {
  call,
  put,
} from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  putSimilarProteinsAction,
  similarProteinsFailedAction,
  similarProteinsLoadingAction,
} from '../../reducer';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

export function* getSimilarProteinsWorker(action) {
  try {
    yield put(similarProteinsLoadingAction(true));
    const requestData = action.payload;

    const { data } = yield call(Api.geneDetailsSimilarProteins, requestData);
    yield put(putSimilarProteinsAction(data));

    if (data && data.length) {
      yield put(geneDetailsSetChapterAction('similar-proteins'));
    }
    yield put(loadNextChapterAction('orthologs'));
    yield put(similarProteinsLoadingAction(false));
  } catch (e) {
    yield put(similarProteinsFailedAction(e.message));
    yield put(loadNextChapterAction('orthologs'));
  }
}
