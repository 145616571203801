import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/lib/immutable';
import PropTypes from 'prop-types';

// Components
import FormTextarea from '../../../../common/FormElements/FormTextarea';
import ConfirmationDialog from '../../../../Modals/ConfirmationDialog/ConfirmationDialog';
import FormSelect from '../../../../common/FormElements/FormSelect';
import Loader from '../../../../common/Loader/Loader';
// Store
import { getProjectsOptionsAction, resetProjectsOptionsAction } from '../../../../Projects/ProjectsTabs/store/reducer';
import {
  getProjectsOptionsLoadingSelector,
  getProjectsOptionsSelector,
} from '../../../../Projects/ProjectsTabs/store/selectors';

const propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  valid: PropTypes.bool,
  analytics: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  optionsLoading: PropTypes.bool,
  activeProjectId: PropTypes.string,
  getProjectsOptions: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
  multiselect: PropTypes.bool,
};

const CopyAnalyticsModal = (props) => {
  const {
    valid,
    isOpen,
    onCancel,
    onConfirm,
    options,
    optionsLoading,
    analytics,
    activeProjectId,
    getProjectsOptions,
    resetProjectsOptions,
    multiselect,
  } = props;

  const [warningMessage, toggleWarningMessage] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getProjectsOptions();
    } else {
      resetProjectsOptions();
    }
  }, [isOpen]);

  function findAnalyticsNameRepeat(title) {
    return analytics.some(a => a.title.toLowerCase() === title.trim().toLowerCase());
  }

  function handleNameChange(e) {
    const nameRepeating = findAnalyticsNameRepeat(e);

    if (nameRepeating) {
      toggleWarningMessage(true);
    } else if (warningMessage) {
      toggleWarningMessage(false);
    }
  }

  return (
    <ConfirmationDialog
      onConfirm={onConfirm}
      disabled={!valid || warningMessage}
      onCancel={onCancel}
      isOpen={isOpen}
      closeCb={onCancel}
      customClassName="copy-modal"
    >
      <form className="copy-modal__form">
        <div className="copy-modal__title title2">
          Copy Analytics
        </div>
        <Loader isLoading={optionsLoading} />
        {
          options && options.length > 0 &&
          <div className="copy-modal__block">
            <label className="copy-modal__label" htmlFor="project">
              Select project
            </label>
            <Field
              name="project"
              options={options}
              component={FormSelect}
              returnOption={true}
              bordered={true}
              dropDownHeight={110}
              selectedValue={options.find(o => o.id === activeProjectId) || options[0]}
            />
          </div>
        }
        {
          !multiselect &&
          <>
            <div className="copy-modal__block">
              <label className="copy-modal__label" htmlFor="name">
                Analytics name
              </label>
              <Field
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                onChange={handleNameChange}
                component={FormTextarea}
              />
            </div>
            {
              warningMessage &&
              <div className="copy-modal__warning">
                <span className="copy-modal__warning-text">
                  This name already exists
                </span>
              </div>
            }
          </>
        }
      </form>
    </ConfirmationDialog>
  );
};

CopyAnalyticsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: getProjectsOptionsSelector(state),
    optionsLoading: getProjectsOptionsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(resetProjectsOptionsAction());
    },
  };
}

function validationCopyAnalyticsItemForm(values) {
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter analytics name';
  }
  if (values.get('name') && values.get('name').trim().length > 1000) {
    errors.name = 'Analytics name is too long. Enter less than 1000 symbols';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: 'COPY_ANALYTICS_ITEM',
    fields: ['name', 'project'],
    validate: validationCopyAnalyticsItemForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(CopyAnalyticsModal);
