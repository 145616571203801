import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Store
import { getReportTitle } from '../selectors';

const propTypes = {
  editTitle: PropTypes.bool,
  reportTitleFromStore: PropTypes.string,
  onChangeHandler: PropTypes.func,
  saveIconClickHandler: PropTypes.func,
};

const EditableTitleInput = ({
  editTitle,
  reportTitleFromStore,
  onChangeHandler,
  saveIconClickHandler,
}) => (
  <div>
    {
      editTitle &&
      <div className="edit-title__wrapper">
        <input
          type="text"
          value={reportTitleFromStore}
          onChange={e => onChangeHandler(e.target.value)}
          className="input report-name-field"
          placeholder="Title"
        />
        <span>
          <i
            role="presentation"
            className="fa fa-save report-name-controls"
            onClick={saveIconClickHandler}
          />
        </span>
      </div>
    }
    {
      !editTitle &&
      <div>
        <span className="report-title title2">
          {reportTitleFromStore || 'Title'}
        </span>
        <span>
          <i
            role="presentation"
            className="fa fa-pencil report-name-controls"
            onClick={saveIconClickHandler}
          />
        </span>
      </div>
    }
  </div>
);

EditableTitleInput.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    reportTitleFromStore: getReportTitle(state),
  };
}

export default connect(mapStateToProps)(EditableTitleInput);
