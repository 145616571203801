import { fromJS } from 'immutable';

import {
  SORT,
  CHECK_ALL,
  CHECK_ITEM,
  REMOVE_SELECTED,
  INVERT_SELECTION,
} from '../../../common/SimpleTable/constants';

import {
  PUBLICATION_DETAILS_TRIPLES_SUCCEEDED,
  PUBLICATION_DETAILS_TRIPLES_FAILED,
  PUBLICATION_DETAILS_TRIPLES_TABLE,
  REMOVE_CONCEPT,
  LOADING,
  PUBLICATION_DETAILS_TRIPLES_TABLE_CHANGE_PAGE,
} from './constants';

const initialValues = fromJS({
  result: [],
  error: null,
  sorting: { sortBy: 'subject', sortDirection: 'ASC' },
  loading: false,
  totalPages: 0,
  page: 0,
});

const reducer = (state = initialValues, action) => {
  switch (action.type) {
    case PUBLICATION_DETAILS_TRIPLES_TABLE_CHANGE_PAGE:
      return state.set('page', action.data);

    case PUBLICATION_DETAILS_TRIPLES_SUCCEEDED:
      return state
        .set('result', fromJS(action.data.tableData))
        .set('totalPages', action.data.totalPages)
        .set('error', null)
        .set('loading', false);

    case PUBLICATION_DETAILS_TRIPLES_FAILED:
      return state.merge(fromJS({ error: action.message, result: initialValues.get('result'), loading: false }));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + SORT:
      return state.merge(fromJS({ sorting: action.data }));


    case PUBLICATION_DETAILS_TRIPLES_TABLE + CHECK_ALL:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => item.merge(fromJS({ selected: action.data })))
        ))
      ));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + CHECK_ITEM:
      return state.update('result', result => (
        result.update('content', content => (
          content.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + REMOVE_SELECTED:
      return state.update('result', result => (
        result.update('content', content => (
          content.filterNot(item => item.get('selected'))
        ))
      ));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + INVERT_SELECTION:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => item.merge(fromJS({ selected: !item.get('selected') })))
        ))
      ));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + REMOVE_CONCEPT:
      return state.update('result', result => (
        result.update('content', content => (
          content.delete(content.findIndex(item => item.get('id') === action.data))
        ))
      ));

    case PUBLICATION_DETAILS_TRIPLES_TABLE + LOADING:
      return state.merge(fromJS({ loading: true }));

    default:
      return state;
  }
};

export default reducer;
