import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import { getGeneDetailsExternalLinksAction } from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const getLinks = d => [
  {
    name: 'ClinVar',
    link: d.CLINVAR,
  },
  {
    name: 'Depmap',
    link: d.DEPMAP,
  },
  {
    name: 'GTEx',
    link: d.GTEX,
  },
  {
    name: 'Human Protein Atlas',
    link: d.HUMAN,
  },
  {
    name: 'NCBI Gene',
    link: d.NCBI,
  },
  {
    name: 'OMIM',
    link: d.OMIM,
  },
  {
    name: 'Uniprot',
    link: d.UNIPROT,
  },
  {
    name: 'Skyline',
    link: d.SKYLINE,
  },
  {
    name: 'ImmGen Human/Mouse Comparison',
    link: d.IMMGENE,
  },
  {
    name: 'Quantitative Proteome Map',
    link: d.TSOMIX,
  },
  {
    name: 'Therapeutic Antibody Database',
    link: d.TABS,
  },
  {
    name: 'Therapeutic Antibody Database',
    text: d.TABS_NOT_AVAILABLE,
  },
];

const propTypes = {
  data: PropTypes.instanceOf(Object),
  conceptId: PropTypes.string,
  getExternalLinks: PropTypes.func,
};

const ExternalLinksChapter = (props) => {
  const {
    data,
    conceptId,
    getExternalLinks,
  } = props;

  useEffect(() => {
    getExternalLinks(conceptId);
  }, [conceptId, getExternalLinks]);

  const renderLink = (name, link, text) => {
    if (link) {
      return (
        <li className="external-links__item" key={name}>
          {`${name}: `}
          <a className="link" href={link} target="blank">
            {link}
          </a>
        </li>
      );
    }

    if (text) {
      return (
        <li className="external-links__item" key={name}>
          {`${name}: ${text}`}
        </li>
      );
    }

    return null;
  };

  const links = data ? getLinks(data) : [];

  return (
    <div className="external-links">
      <div className="gene-details-section-title title2">
        <span>External links</span>
      </div>
      <ul className="external-links__list">
        { links.sort((a, b) => a.name.localeCompare(b.name)).map(l => renderLink(l.name, l.link, l.text)) }
      </ul>
    </div>
  );
};

ExternalLinksChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getGeneDetailsExternalLinksDataSelector(state),
    loading: SELECTORS.getGeneDetailsExternalLinksLoadingSelector(state),
    error: SELECTORS.getGeneDetailsExternalLinksErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getExternalLinks(id) {
      dispatch(getGeneDetailsExternalLinksAction(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalLinksChapter);
