import { createAction } from 'redux-actions';

export const getFindRelatedSetDataAction = createAction('@@findRelated/GET_SET_DATA');
export const setFindRelatedSetDataAction = createAction('@@findRelated/SET_SET_DATA');
export const throwFindRelatedSetDataAction = createAction('@@findRelated/SET_DATA_ERROR');
export const sortFindRelatedSetAction = createAction('@@findRelated/SORT_SET_DATA');
export const pagginateFindRelatedSetAction = createAction('@@findRelated/PAGGINATE_SET_DATA');
export const setFindRelatedMethodAction = createAction('@@findRelated/SET_METHOD');
export const setFindRelatedCategoryAction = createAction('@@findRelated/SET_CATEGORY');
export const setFindRelatedTypeAction = createAction('@@findRelated/SET_TYPE');
export const resetFindRelatedAction = createAction('@@findRelated/RESET');
export const closeSetDialogAction =  createAction('@@findRelated/CLOSE_SET_DIALOG');
export const openSetDialogAction =  createAction('@@findRelated/OPEN_SET_DIALOG');
