import { createSelector } from 'reselect';

const samplesData = state => state.getIn(['samplesReducer', 'data']);
const loading = state => state.getIn(['samplesReducer', 'loading']);
const error = state => state.getIn(['samplesReducer', 'error']);

export const getSamplesData = createSelector(
  samplesData,
  data => data && data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
