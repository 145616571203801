import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddTag from '../../common/AddTag/AddTag';
import AddTagContent from '../../common/AddTag/AddTagContent';
import ModalComponent from '../../ModalComponent/ModalComponent';
// Store
import {
  changeSetNameAction,
  changeSetDescriptionAction,
  complexSetUpdateTagsAction,
} from '../ComplexSetView/actions';
import { duplicateSetAction } from './actions';
// Utils
import { dropDownValues } from './enum';
// Styles
import './ComplexSetSettingsModal.css';

const propTypes = {
  complexSetContent: PropTypes.instanceOf(Object),
  fileContents: PropTypes.instanceOf(Array),
  fileName: PropTypes.string,
  cancelButtonHandler: PropTypes.func,
  submitButtonHandler: PropTypes.func,
  setId: PropTypes.string,
  setsName: PropTypes.string,
  description: PropTypes.string,
  changeSetName: PropTypes.func,
  changeSetDescription: PropTypes.func,
  isFormHiddenInCreateMode: PropTypes.bool,
  tags: PropTypes.instanceOf(Array),
  duplicateSet: PropTypes.func,
  complexSetUpdateTags: PropTypes.func,
};

class ComplexSetSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      complexSetContent: {
        config: {
          columnsConfiguration,
        },
      },
    } = this.props;

    let objectForState = {};
    columnsConfiguration.forEach((item, index) => {
      objectForState = {
        showCreateNewSetModal: false,
        setName: '',
        isNameChanged: false,
        [`${index}_content`]: item.content,
        [`${index}_contentType`]: item.type,
        ...objectForState,
      };
    });

    this.state = objectForState;
  }

  componentDidMount() {
    const {
      complexSetContent: {
        config: {
          columnsConfiguration,
        },
      },
    } = this.props;

    let dataToInit = {};
    columnsConfiguration.forEach((item, index) => {
      dataToInit = {
        [`${index}_content`]: dropDownValues.textTypeDropdownValues[0].value,
        [`${index}_contentType`]: 'TEXT',
        ...dataToInit,
      };
    });
  }

  changeSetName = (value) => {
    const {
      state: { setName },
    } = this;
    if (setName.length === 0) {
      this.setState({
        setName: this.props.setsName,
      });
    }

    this.props.changeSetName(value);
    if (setName !== value) {
      this.setState({
        isNameChanged: true,
      });
    } else {
      this.setState({
        isNameChanged: false,
      });
    }
  };

  onSubmit = () => {
    const {
      complexSetContent: {
        config,
      },
      submitButtonHandler,
      cancelButtonHandler,
      setId,
      setsName,
      description,
    } = this.props;

    const { isNameChanged } = this.state;
    if (isNameChanged) {
      this.setState({
        showCreateNewSetModal: true,
      });
    } else {
      submitButtonHandler({
        config,
        complexSetConfigFormValues: this.state,
        setsName,
        description,
        setId: setId || '',
      });
      cancelButtonHandler();
    }
  };

  onSubmitNestedModal = () => {
    const {
      complexSetContent: {
        config,
      },
      submitButtonHandler,
      cancelButtonHandler,
      setId,
      setsName,
      description,
    } = this.props;

    this.setState({ showPublicationsPopup: false });

    submitButtonHandler({
      config,
      complexSetConfigFormValues: this.state,
      setsName,
      description,
      setId: setId || '',
    });
    cancelButtonHandler();
  };

  onCreateCopy = () => {
    const {
      cancelButtonHandler,
      duplicateSet,
      setId,
      setsName,
    } = this.props;

    this.setState({ showPublicationsPopup: false });
    duplicateSet({ setId, setName: setsName });
    cancelButtonHandler();
  };

  closeNestedModal = () => {
    this.setState({
      showCreateNewSetModal: false,
    });
  };

  changeSetDescription = (value) => {
    this.props.changeSetDescription(value);
  };

  render() {
    const {
      complexSetContent: {
        config: {
          columnsConfiguration,
        },
      },
      fileContents,
      fileName,
      cancelButtonHandler,
      setsName,
      description,
      tags,
      isFormHiddenInCreateMode,
      complexSetUpdateTags,
    } = this.props;

    const { showCreateNewSetModal } = this.state;

    return (
      <div className="complex-set-settings-root">
        <div className="complex-set-settings-content">
          <div
            className="close-button"
            onClick={cancelButtonHandler}
          >
            <svg
              width="40"
              height="40"
            >
              <circle cx="20" cy="20" r="20" fill="black" />
              <g transform="rotate(45 20 20)">
                <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
                <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
              </g>
            </svg>
          </div>
          <div className="overflow-container">
            <div className="main">
              <form>
                {!isFormHiddenInCreateMode &&
                  <div>
                    <p className="complex-set-settings-bold">Edit complex set</p>
                    <div className="complex-set-settings-top-fields-wrap">
                      <div className="row">
                        <span className="form-label form-label-top">
                          <label className="complex-set-settings-bold" htmlFor="setsName">Name:</label>
                        </span>
                        <span className="form-control">
                          <input
                            type="text"
                            className="input input-text"
                            name="setsName"
                            defaultValue={setsName}
                            onChange={e => this.changeSetName(e.target.value)}
                          />
                        </span>
                      </div>
                      <div className="row">
                        <span className="form-label form-label-top">
                          <label className="complex-set-settings-bold" htmlFor="description">Description:</label>
                        </span>
                        <span className="form-control">
                          <textarea
                            className="textarea input-text"
                            type="text"
                            name="description"
                            defaultValue={description}
                            onChange={e => this.changeSetDescription(e.target.value)}
                          />
                        </span>
                      </div>
                      <div className="row">
                        <span className="form-label form-label-top">
                          <label className="complex-set-settings-bold" htmlFor="description">Tags:</label>
                        </span>
                        <span className="form-control">
                          <AddTag
                            tagList={tags}
                            updateTags={complexSetUpdateTags}
                          />
                          <AddTagContent
                            tagList={tags}
                            updateTags={complexSetUpdateTags}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                }
                {
                  fileName ?
                    <div className="header">
                      <div className="message">
                        File name to upload: {fileName}
                      </div>
                    </div> :
                    <div className="page-title">
                      Columns configuration
                    </div>
                }
                <div className="settings-table">
                  <div className="settings-table-header">
                    <div className="tableCell">
                      Column number
                    </div>
                    <div className="tableCell">
                      First element in the column
                    </div>
                    <div className="tableCell">
                      Column content type
                    </div>
                    <div className="tableCell">
                      Set contents of column
                    </div>
                  </div>
                  <div className="settings-table-body">
                    {
                      columnsConfiguration.map((item, index) => (
                        <div
                          className="row"
                          key={index}
                        >
                          <div className="tableCell">{item.colNumber + 1}</div>
                          <div className="tableCell">{item.name}</div>
                          <div className="tableCell">
                            <select
                              name={`${index}_contentType`}
                              value={this.state[`${index}_contentType`]}
                              onChange={(e) => {
                                this.setState({
                                  [`${index}_contentType`]: e.target.value,
                                  [`${index}_content`]: e.target.value === 'TEXT' ? dropDownValues.textTypeDropdownValues[0].value : dropDownValues.numberTypeDropdownValues[0].value,
                                });
                              }}
                            >
                              <option value="TEXT">Text</option>
                              <option value="NUMBER">Number</option>
                            </select>
                          </div>
                          <div className="tableCell">
                            <select
                              name={`${index}_content`}
                              value={this.state[`${index}_content`]}
                              onChange={(e) => {
                                this.setState({
                                  [`${index}_content`]: e.target.value,
                                });
                              }}
                            >
                              {
                                this.state[`${index}_contentType`] === 'TEXT' &&
                                dropDownValues.textTypeDropdownValues.map((valueItem, valueIndex) => (
                                  <option
                                    value={valueItem.value}
                                    key={`select_value_${index}_${valueIndex}`}
                                  >
                                    {valueItem.label}
                                  </option>
                                ))
                              }
                              {
                                this.state[`${index}_contentType`] === 'NUMBER' &&
                                dropDownValues.numberTypeDropdownValues.map((valueItem, valueIndex) => (
                                  <option
                                    value={valueItem.value}
                                    key={`select_value_${index}_${valueIndex}`}
                                  >
                                    {valueItem.label}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </form>
              <div className="button-section">
                <button
                  className="complex-set-button complex-set-button btn btn-default"
                  type="button"
                  onClick={cancelButtonHandler}
                >
                  Cancel
                </button>
                <button
                  className="complex-set-button btn btn-success"
                  type="button"
                  onClick={this.onSubmit}
                >
                  Submit
                </button>
              </div>
              {
                fileContents &&
                <hr />
              }
              {
                fileContents &&
                <div className="file-content">
                  <div className="file-content-header">
                    File Contents
                  </div>
                  <div className="file-content-table">
                    {
                      fileContents.map((fileContentItem, index) => (
                        <div
                          className="file-content-column"
                          key={`file_content_column_${index}`}
                        >
                          <div className="column-header">
                            {fileContentItem.columnName}
                          </div>
                          {
                            fileContentItem.values.map((fileContentItemValue, fileContentItemIndex) => (
                              <div
                                className="column-value"
                                key={`column_value_${fileContentItemIndex}_${fileContentItemValue}`}
                              >
                                {fileContentItemValue || ''}
                              </div>
                            ))
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          {
            showCreateNewSetModal &&
            <ModalComponent
              isOpen={showCreateNewSetModal}
              closeCb={this.closeNestedModal}
            >
              <div className="nested-modal-content">
                <p className="nested-modal-main-text">Make a set copy with new name?</p>
                <div className="nested-modal-button-section">
                  <button
                    className="complex-set-button complex-set-button btn btn-default"
                    type="button"
                    onClick={this.onSubmitNestedModal}
                  >
                    No
                  </button>
                  <button
                    className="complex-set-button btn btn-success"
                    type="button"
                    onClick={this.onCreateCopy}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </ModalComponent>
          }
        </div>
      </div>
    );
  }
}

ComplexSetSettingsModal.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    changeSetName(v) {
      dispatch(changeSetNameAction(v));
    },
    changeSetDescription(v) {
      dispatch(changeSetDescriptionAction(v));
    },
    duplicateSet(v) {
      dispatch(duplicateSetAction(v));
    },
    complexSetUpdateTags(data) {
      dispatch(complexSetUpdateTagsAction(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(ComplexSetSettingsModal);
