/* eslint-disable no-plusplus */
import hcluster from 'hclusterjs';

import { HeatmapSortByEnum, HeatmapSortDirEnum } from './constants';

function formatHeatmapData(heatmapData, scoreType) {
  const { matrix, rows, columns } = heatmapData;
  const data = [];
  let maxValue = 0;

  for (let i = 0; i < matrix.length; i++) {
    const mRow = matrix[i];
    for (let j = 0; j < mRow.length; j++) {
      const pubsCount = mRow[j];
      data.push({
        [scoreType]: pubsCount,
        row: rows[i],
        col: columns[j],
      });
      maxValue = pubsCount > maxValue ? pubsCount : maxValue;
    }
  }

  return {
    data,
    rows,
    columns,
    maxValue,
  };
}

function formatNetworkAnalysisHeatmapData(heatmapData) {
  const { matrix, rows, columns } = heatmapData;
  const data = [];
  const newItems = [];
  let maxValue = 0;

  const swappedAxis = !!columns[0].firstNode;

  for (let i = 0; i < matrix.length; i++) {
    const mRow = matrix[i];
    for (let j = 0; j < mRow.length; j++) {
      const score = mRow[j];
      const networkData = swappedAxis ? columns[j] : rows[j];
      const getKey = bool => (bool ? 'col' : 'row');
      const col = {
        name: `${networkData.firstNode.name} - ${networkData.lastNode.name}`,
        id: `${networkData.firstNode.id}${networkData.lastNode.id}`,
        firstNodeId: networkData.firstNode.id,
        lastNodeId: networkData.lastNode.id,
      };
      if (i === 0) {
        newItems.push(col);
      }
      data.push({
        [getKey(swappedAxis)]: col,
        [getKey(!swappedAxis)]: swappedAxis ? rows[i] : columns[i],
        score,
        firstNode: networkData.firstNode,
        lastNode: networkData.lastNode,
      });
      maxValue = score > maxValue ? score : maxValue;
    }
  }

  return {
    data,
    rows: swappedAxis ? rows : newItems,
    columns: swappedAxis ? newItems : columns,
    maxValue,
  };
}

function formatCellLineSelectionHeatmapData(heatmapData, measureType) {
  const { matrix, rows, columns } = heatmapData;
  const data = [];
  const newItems = [];
  let maxValue = 0;
  let minValue = 0;
  let valueExists = false;

  const swappedAxis = columns.length > rows.length;

  for (let i = 0; i < matrix.length; i++) {
    const mRow = matrix[i];
    for (let j = 0; j < mRow.length; j++) {
      const score = mRow[j] && mRow[j][measureType] ? mRow[j][measureType] : null;
      if (score !== null) valueExists = true;
      const getKey = bool => (bool ? 'col' : 'row');
      const col = {
        name: `${columns[j].cellLine.name} - ${columns[j].cancer.name}`,
        id: `${columns[j].cellLine.id}`,
      };
      if (i === 0) {
        newItems.push(col);
      }
      data.push({
        [getKey(swappedAxis)]: col,
        [getKey(!swappedAxis)]: rows[i],
        score,
      });
      maxValue = score > maxValue ? score : maxValue;
      minValue = score < minValue ? score : minValue;
    }
  }

  if (valueExists) {
    return {
      data,
      rows: swappedAxis ? rows : newItems,
      columns: swappedAxis ? newItems : rows,
      maxValue,
      minValue,
    };
  } else {
    return null;
  }
}

function sortHeatmap(data, sorting, type) {
  const sortedData = { ...data };
  const { sortBy, sortDirection, sortKey } = sorting;

  const comparator = (a, b) => a[type] - b[type];
  const reverseComp = (a, b) => -comparator(a, b);
  const sort = (filterKey, mapKey) => sortedData.data
    .filter(item => item[filterKey].name === sortKey)
    .sort(sortDirection === HeatmapSortDirEnum.DESC ? reverseComp : comparator)
    .map(item => item[mapKey]);

  if (sortBy === HeatmapSortByEnum.COLUMN) {
    sortedData.rows = sort('col', 'row');
  } else {
    sortedData.columns = sort('row', 'col');
  }

  return sortedData;
}

function sortHeatmapByCluster(data, key, type) {
  const formatInputData = (acc, item) => {
    const { name, id } = item[key];

    if (!acc[name]) {
      acc[name] = {
        id,
        name,
        [type]: [item[type]],
      };
    } else {
      acc[name][type].push(item[type]);
    }

    return acc;
  };
  const formatedData = data.reduce(formatInputData, {});
  const hclusterData = Object.values(formatedData).map(f => Object.assign({}, f));
  const clusters = hcluster()
    .verbose(false)
    .posKey(type)
    .data(hclusterData);

  return clusters.orderedNodes().map(c => ({ name: c.name, id: c.id }));
}

function getSelectedCellsMap(cells) {
  return cells.reduce((map, cell) => {
    const {
      row, col, firstNode, lastNode,
    } = cell;
    const newMap = { ...map };
    newMap[row.id] = row;
    if (firstNode && lastNode) {
      newMap[firstNode.id] = firstNode;
      newMap[lastNode.id] = lastNode;
    } else {
      newMap[col.id] = col;
    }
    return newMap;
  }, {});
}

function deleteSelectedCells(chartData, cells, type) {
  const { data, rows, columns } = chartData;
  const selectedRowsIds = [];
  const selectedColumnsIds = [];
  let maxValue = 0;

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    selectedRowsIds.push(cell.row.id);
    selectedColumnsIds.push(cell.col.id);
  }

  const newChartData = {
    data: data.filter(d =>
      !selectedRowsIds.includes(d.row.id) &&
      !selectedColumnsIds.includes(d.col.id)
    ),
    rows: rows.filter(r => !selectedRowsIds.includes(r.id)),
    columns: columns.filter(c => !selectedColumnsIds.includes(c.id)),
  };

  for (let i = 0; i < newChartData.data.length; i++) {
    const d = newChartData.data[i];
    maxValue = d[type] > maxValue ?
      d[type] :
      maxValue;
  }

  return {
    ...newChartData,
    maxValue,
  };
}

function deleteSelectedRowColCells(chartData, cells, type, dataType) {
  const { data } = chartData;
  const key = dataType === 'columns' ? 'col' : 'row';
  const selectedIds = [];
  let maxValue = 0;

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    selectedIds.push(cell[key].id);
  }

  const newChartData = {
    ...chartData,
    data: data.filter(d => !selectedIds.includes(d[key].id)),
    [dataType]: chartData[dataType].filter(d => !selectedIds.includes(d.id)),
  };

  for (let i = 0; i < newChartData.data.length; i++) {
    const d = newChartData.data[i];
    maxValue = d[type] > maxValue ?
      d[type] :
      maxValue;
  }

  return {
    ...newChartData,
    maxValue,
  };
}

function deleteSelectedConcepts(chartData, cells) {
  const { rows, columns } = chartData;
  const selectedRowsIds = [];
  const selectedColumnsIds = [];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    selectedRowsIds.push(cell.row.id);
    selectedColumnsIds.push(cell.col.id);
  }
  return {
    conceptIds: columns.map(c => c.id).filter(c => !selectedColumnsIds.includes(c)),
    connectedConceptIds: rows.map(r => r.id).filter(r => !selectedRowsIds.includes(r)),
  };
}

function deleteSelectedRowColConcepts(chartData, cells, dataType) {
  const key = dataType === 'columns' ? 'col' : 'row';
  const key2 = dataType === 'columns' ? 'conceptIds' : 'connectedConceptIds';
  const conceptData = dataType === 'columns' ?
    { 'connectedConceptIds': chartData.rows.map(el => el.id) } :
    { 'conceptIds': chartData.columns.map(el => el.id) };
  const selectedIds = [];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    selectedIds.push(cell[key].id);
  }
  return {
    ...conceptData,
    [key2]: chartData[dataType].map(d => d.id).filter(d => !selectedIds.includes(d)),
  };
}

function formatHeatmapToString(chartData, type) {
  const { data, columns, rows } = chartData;
  const rowsNames = rows.map(r => r.name);
  const columnsNames = columns.map(c => c.name);
  let stringRows = ` ,"${columnsNames.join('", "')}"\n`;
  rowsNames.reverse().forEach((r) => {
    const row = [r];
    columnsNames.forEach((c) => {
      const val = data.find(d => d.row.name === r && d.col.name === c);
      row.push(val[type]);
    });
    stringRows += `"${row.join('", "')}"\n`;
  });
  return stringRows;
}

export {
  formatHeatmapData,
  sortHeatmap,
  sortHeatmapByCluster,
  getSelectedCellsMap,
  deleteSelectedCells,
  formatHeatmapToString,
  formatNetworkAnalysisHeatmapData,
  formatCellLineSelectionHeatmapData,
  deleteSelectedConcepts,
  deleteSelectedRowColCells,
  deleteSelectedRowColConcepts,
};
