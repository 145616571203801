import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import Error from '../Error/Error';
import UploadFileImportSetup from './Components/UploadFileImportSetup/UploadFileImportSetup';
import UploadFileTypesSetup from './Components/UploadFileTypesSetup/UploadFileTypesSetup';
// Store
import * as a from './store/actions';
import * as s from './store/selectors';
// Constants
import { allowedFileTypes } from './constants';
// Styles
import './styles.scss';

const propTypes = {
  resetUploadedFile: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  fileType: PropTypes.string,
  taxonomies: PropTypes.instanceOf(Array),
  uploadFile: PropTypes.func,
  customClass: PropTypes.string,
  projectId: PropTypes.string,
  callbackAction: PropTypes.func,
  categories: PropTypes.instanceOf(Array),
  mainCategories: PropTypes.instanceOf(Array),
  getMainCategories: PropTypes.func,
  checkedSC: PropTypes.instanceOf(Array),
  checkedST: PropTypes.instanceOf(Array),
  toggleSemanticType: PropTypes.func,
  toggleSemanticCategory: PropTypes.func,
  importSettings: PropTypes.instanceOf(Object),
  setImportSettings: PropTypes.func,
  getTaxonomies: PropTypes.func,
  setTaxonomies: PropTypes.func,
  uploadOnlySimple: PropTypes.func,
  defaultValues: PropTypes.instanceOf(Object),
  uploadOnlyByDefaultCategory: PropTypes.bool,
};

const UploadFile = (props) => {
  const {
    fileType,
    taxonomies,
    error,
    loading,
    customClass,
    projectId,
    uploadFile,
    resetUploadedFile,
    callbackAction,
    categories,
    mainCategories,
    getMainCategories,
    checkedSC,
    checkedST,
    toggleSemanticType,
    toggleSemanticCategory,
    importSettings,
    setImportSettings,
    getTaxonomies,
    setTaxonomies,
    uploadOnlySimple,
    defaultValues,
    uploadOnlyByDefaultCategory,
  } = props;

  const fileInputRef = useRef(null);

  useEffect(() => {
    getMainCategories();
    getTaxonomies();
    return resetUploadedFile;
  }, []);

  const handleSelectFile = (e) => {
    const fileAcceptType = e.target.files[0].name.split('.').pop();

    if (allowedFileTypes.includes(fileAcceptType)) {
      uploadFile({
        eventTarget: e.target,
        fileType,
        projectId,
        callbackAction,
        uploadOnlySimple,
        sc: checkedSC,
        st: uploadOnlyByDefaultCategory ? [defaultValues.mainCategories[0].semanticTypeId] : checkedST,
        taxonomies: uploadOnlyByDefaultCategory ? defaultValues.taxonomies[0].name : taxonomies.find(t => t.checked)?.name,
        ...importSettings,
      });
    }

    fileInputRef.current.value = null;
  };

  return (
    <div className={classNames('upload-file', customClass)}>
      <UploadFileTypesSetup
        categories={categories}
        checkedSC={checkedSC}
        checkedST={checkedST}
        toggleSemanticType={toggleSemanticType}
        toggleSemanticCategory={toggleSemanticCategory}
        setTaxonomies={setTaxonomies}
        uploadOnlyByDefaultCategory={uploadOnlyByDefaultCategory}
        taxonomies={uploadOnlyByDefaultCategory ? defaultValues.taxonomies : taxonomies}
        mainCategories={uploadOnlyByDefaultCategory ? defaultValues.mainCategories : mainCategories}
      />
      <UploadFileImportSetup
        importSettings={importSettings}
        setImportSettings={setImportSettings}
        loading={loading}
        handleSelectFile={handleSelectFile}
        fileInputRef={fileInputRef}
      />
      <Error show={!!error && !loading} error={error} />
    </div>
  );
};

UploadFile.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    fileType: s.getUploadedFileTypeSelector(state),
    taxonomies: s.getUploadedFileTaxonomiesSelector(state),
    loading: s.getUploadFileLoadingSelector(state),
    error: s.getUploadFileErrorSelector(state),
    categories: s.getUploadFileCategoriesSelector(state),
    mainCategories: s.getUploadFileMainCategoriesSelector(state),
    checkedSC: s.getUploadFileCheckedSCSelector(state),
    checkedST: s.getUploadFileCheckedSTSelector(state),
    importSettings: s.getUploadedFileImportSettingsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadFile(data) {
      dispatch(a.getUploadFileSetDataAction(data));
    },
    setType(value) {
      dispatch(a.setUploadFileTypeAction(value));
    },
    getTaxonomies(data) {
      dispatch(a.getUploadFileTaxonomiesAction(data));
    },
    setTaxonomies(data) {
      dispatch(a.setUploadFileTaxonomiesAction(data));
    },
    getMainCategories() {
      dispatch(a.getUploadFileCategoriesAction());
    },
    resetUploadedFile() {
      dispatch(a.resetUploadFileAction());
    },
    toggleSemanticType(data) {
      dispatch(a.toggleUploadFileSemanticTypeAction(data));
    },
    toggleSemanticCategory(data) {
      dispatch(a.toggleUploadFileSemanticCategoryAction(data));
    },
    setImportSettings(data) {
      dispatch(a.setUploadFileImportSettingsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile);
