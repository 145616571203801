const Storage = {
  setValue: (data) => {
    localStorage.setItem(data.key, JSON.stringify(data.value));
  },
  getValue: key => (
    JSON.parse(localStorage.getItem(key))
  ),
  removeValue: key => (
    JSON.parse(localStorage.removeItem(key))
  ),
};

export default Storage;
