import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { linksForTables } from '../GeneDiseasePage/enum';
import { insertHTML, openSetResultInNewTab } from '../../Utils/Utils';
// Components
import Loader from '../../common/Loader/Loader';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import CircosDiagram from '../../graphics/CircosDiagram/CircosDiagram';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import NoData from '../../common/NoData/NoData';
// Store
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import { loadNextChapterAction } from '../GeneDiseasePage/actions';
import { initGeneMutationAction } from './actions';
import {
  getGeneMutationdata,
  getError,
  getLoading,
} from './selectors';
// Styles
import './GeneDiseaseGeneMutationSection.css';

const propTypes = {
  initGeneMutation: PropTypes.func,
  geneId: PropTypes.string,
  diseaseId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  geneMutationData: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  highlightedTissueIds: PropTypes.instanceOf(Array),
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  pageName: PropTypes.string,
};

class GeneDiseaseGeneMutationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
      popup: false,
    };
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.initPage();
  }

  initPage = () => {
    const {
      initGeneMutation,
      geneId,
      diseaseId,
      highlightedTissueIds,
      geneMutationData: {
        data,
      },
      itsNewConcept,
      loadNextChapter,
    } = this.props;

    if (highlightedTissueIds && (itsNewConcept || (data && !data.length))) {
      initGeneMutation({
        geneId,
        diseaseId,
        highlightedTissueIds,
      });
    } else {
      loadNextChapter('animal-model');
    }
  };

  openPublicationsPopup = () => {
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  genesCell = ({ id, name }, rowIndex, columnIndex, pathForLink) => {
    const link = `${RELATIVE_PATH}/${pathForLink}/${id}`;
    const uniqueKey = `mutation-tooltip-${id}-${rowIndex}-${columnIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      loading,
      error,
      gene,
      geneMutationData,
      disease,
      pageName,
    } = this.props;
    const { popup } = this.state;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';
    const tableSettings = {
      width: 1100,
      headerHeight: 70,
      rowHeight: 50,
    };
    const columns = [{
      label: geneName,
      dataKey: 'setANode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setANode ? this.genesCell(rowData.setANode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: 'Gene neighbor node',
      dataKey: 'setBNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setBNode ? this.genesCell(rowData.setBNode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: diseaseName,
      dataKey: 'setCNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setCNode ? this.genesCell(rowData.setCNode, rowIndex, columnIndex, linksForTables.conceptDetails) : null
      ),
    }, {
      label: `Pub count with ${geneName} [A+B]`,
      dataKey: 'pubsAB',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{geneName} </span><br />
            <span>[A+B]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsAB > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsAB > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsAB}
          </span>
        );
      },
    }, {
      label: `Pub count with ${diseaseName} [B+C]`,
      dataKey: 'pubsBC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{diseaseName} </span><br />
            <span>[B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsBC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsBC > 0) {
                this.conceptsNames = [rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsBC}
          </span>
        );
      },
    }, {
      label: `Pub count genes and ${diseaseName} [A+B+C]`,
      dataKey: 'pubsABC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count genes and </span><br />
            <span>{diseaseName} </span><br />
            <span>[A+B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsABC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsABC > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsABC}
          </span>
        );
      },
    }];

    return (
      <div>
        { !loading && (
          <div>
            <div className="gene-disease-section-title">
              <span>Genetic mutations</span>
              <span
                role="presentation"
                className="fa fa-info-circle icon"
                onClick={() => {
                  this.setState({
                    showTitleHelperText: !this.state.showTitleHelperText,
                  });
                }}
              />
              <div
                className={
                  classnames(
                    'gene-disease-section-title-helper-text',
                    { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                  )
                }
              >
                This section provides the molecular interactions that {geneName} has with proteins that have a genetic mutation
                for {diseaseName}. This is visualised in a <span className="strongBold">circle diagram</span> as follows:
                <ul>
                  <li>The <span className="strongBold">colored ring</span> represents
                    the genes/proteins associated with {diseaseName} that
                    interact with {geneName} where:
                  <ul>
                    <li>Each ring segment represents a gene/protein</li>
                    <li>The top 15 genes/proteins, based on the number of publications mentioning the gene/protein
                        and {diseaseName}, are shown
                    </li>
                    <li>
                        The size of each protein segment is based on the total number of molecular interactions it has
                        with other genes (in general, not specific to the disease)
                    </li>
                  </ul>
                  </li>
                  <li>
                    If a gene is expressed in one of the highlighted tissues (see the ‘Expression’ section), these are
                    shown as <span className="strongBold">expression bars</span> protruding from the gene segment.
                  </li>
                  <li>
                    The <span className="strongBold">inner circle</span> shows the molecular interactions between the genes.
                  </li>
                </ul>
                <div>The following <span className="strongBold">actions</span> are possible on the circle diagram :</div>
                <ul>
                  <li><span className="strongBold">Click</span> on a gene segment to highlight its interactions</li>
                  <li><span className="strongBold">Hover</span> over any element in the circle diagram for more details</li>
                </ul>
                <div>A <span className="strongBold">table</span> is shown that provides the publication and database references for the interactions displayed
                  in the circle diagram. If {geneName} has more than the top 15 interactions displayed in the circle diagram,
                  these can be accessed here as well.
                </div>
              </div>
            </div>
            <div className="gene-disease-section-body">
              {
                geneMutationData.hasGeneticMutations &&
                  <div>{geneName} has a genetic mutation that is associated with {diseaseName} as described in </div>
              }
              {
                geneMutationData.mutationPublications.map(pub => (
                  <Link
                    key={pub.id}
                    to={`${RELATIVE_PATH}/publication-details/${pub.id}`}
                    className="search-result-item-title"
                    target="blank"
                    dangerouslySetInnerHTML={insertHTML(pub.title)}
                  />
                ))
              }
              {
                (!geneMutationData.hasGeneticMutations === 0) &&
                <div>{geneName} does not have any genetic mutation that is associated with {diseaseName}</div>
              }
              {
                geneMutationData.data && geneMutationData.data.length > 0 &&
                  <div>{geneName} has molecular interaction with one or more proteins that have a genetic mutation for {diseaseName}</div>
              }
              {
                !geneMutationData.data || geneMutationData.data.length === 0 ?
                  <div>{geneName} has no molecular interaction with any proteins that have a genetic mutation for {diseaseName}
                  </div> :
                  <CircosDiagram
                    targetGene={gene}
                    data={geneMutationData.data}
                    chords={geneMutationData.chords}
                    geneColor={geneMutationData.genesColor}
                    id="geneMutationCircos"
                    geneName={pageName}
                    chartName="Genetic mutations"
                  />
              }
              <NoData
                show={
                  geneMutationData.publications &&
                  !geneMutationData.publications.length
                }
                customClassName="gene-disease__no-data"
                message="There is no data to display table."
              />
              {
                geneMutationData.publications &&
                geneMutationData.publications.length > 0 &&
                <div className="gene-disease-gene-mutation-table">
                  <div className="gene-disease-gene-mutation-table-header">
                    <button
                      onClick={() => openSetResultInNewTab(geneMutationData.fullSetABC)}
                      type="button"
                      className="button button-primary"
                    >
                      <ReactTooltip
                        id="showAllDiseaseGenes"
                        place="bottom"
                        type="light"
                      >
                        Open a table containing all interactions with disease mutated genes
                      </ReactTooltip>
                      <span
                        data-tip={true}
                        data-for="showAllDiseaseGenes"
                      >
                        Show all mutated genes
                      </span>
                    </button>
                  </div>
                  <SimpleTable
                    fixedHeight={true}
                    settings={tableSettings}
                    height={
                      geneMutationData.publications.length ?
                        geneMutationData.publications.length * tableSettings.rowHeight + tableSettings.headerHeight : //eslint-disable-line
                        tableSettings.headerHeight + 40
                    }
                    data={geneMutationData.publications}
                    columns={columns}
                  />
                </div>
              }
            </div>
          </div>
        )
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneDiseaseGeneMutationSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    geneMutationData: getGeneMutationdata(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initGeneMutation(data) {
      dispatch(initGeneMutationAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneDiseaseGeneMutationSection);
