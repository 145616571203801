import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  toggleSingleTargetForCancerTissueLoaderAction,
  singleTargetForCancerTissueErrorAction,
  setGtexTissuesAction,
  setBeatmlDataTissuesAction,
  setTcgaTissuesAction,
  setTummorTissuesAction,
} from '../../reducer';

export function* getSingleTargetForCancerTissuesWorker() {
  try {
    yield put(toggleSingleTargetForCancerTissueLoaderAction(true));

    const { data: gtexData } = yield call(Api.getTissuesBySource, 'GTEX');
    const { data: beatamlData } = yield call(Api.getTissuesBySource, 'BEAT_AML');
    const { data: tcgaData } = yield call(Api.getTissuesBySource, 'TCGA');

    const getTissuesIds = (data, tissuesObj) => data.map((t) => {
      const dataObj = tissuesObj;
      dataObj[t.id] = t;
      dataObj[t.id].checked = true;
      return t.id;
    });

    const gtexTissues = {};
    const gtexTissuesIds = getTissuesIds(gtexData, gtexTissues);
    yield put(setGtexTissuesAction({
      gtexTissuesIds,
      gtexTissues: Object.values(gtexTissues),
    }));

    const tcgaGroupedData = {
      TUMOR: [],
      NORMAL: [],
    };

    tcgaData.forEach((el) => {
      if (el.group.includes('TUMOR')) tcgaGroupedData.TUMOR.push(el);
      if (el.group.includes('NORMAL')) tcgaGroupedData.NORMAL.push(el);
    });

    const normalTcgaDataTissues = {};
    const normalTcgaDataTissuesIds = getTissuesIds(tcgaGroupedData.NORMAL, normalTcgaDataTissues);

    yield put(setTcgaTissuesAction({
      tcgaDataTissuesIds: normalTcgaDataTissuesIds,
      tcgaTissues: Object.values(normalTcgaDataTissues),
    }));

    const beatamlGroupedData = {
      TUMOR: [],
      NORMAL: [],
    };
    beatamlData.forEach((el) => {
      if (el.group.includes('Tumor')) beatamlGroupedData.TUMOR.push(el);
      if (el.group.includes('Normal')) beatamlGroupedData.NORMAL.push(el);
    });

    const normalBeatamlDataTissues = {};
    const normalBeatamlDataTissuesIds = getTissuesIds(beatamlGroupedData.NORMAL, normalBeatamlDataTissues);

    yield put(setBeatmlDataTissuesAction({
      beatamlDataTissuesIds: normalBeatamlDataTissuesIds,
      beatamlDataTissues: Object.values(normalBeatamlDataTissues),
    }));

    const tcgaDataTissuesWithLabels = [];

    Object.values(tcgaGroupedData.TUMOR).forEach((item) => {
      item.cancers.forEach((cancer) => {
        const { cancerId, cancerName, labels } = cancer;
        if (labels.length === 0) {
          tcgaDataTissuesWithLabels.push({
            ...cancer,
            id: `${cancerId}_${cancerName}`,
            name: cancerName,
          });
        } else if (labels.length === 1) {
          tcgaDataTissuesWithLabels.push({
            ...cancer,
            id: `${cancerId}_${labels[0]}`,
            name: cancerName,
            label: labels[0],
          });
        } else if (labels.length === 2) {
          tcgaDataTissuesWithLabels.push({
            ...cancer,
            id: `${cancerId}_${labels[1]}`,
            name: cancerName,
            label: labels[1],
            nameWithLabel: `${cancerName} [${labels[1]}]`,
          });
        } else if (labels.length > 2) {
          labels.forEach((label) => {
            tcgaDataTissuesWithLabels.push({
              ...cancer,
              label,
              id: `${cancerId}_${label}`,
              name: cancerName,
              nameWithLabel: `${cancerName} [${label}]`,
            });
          });
        }
      });
    });

    yield put(setTummorTissuesAction({
      beatamlDataTissues: Object.values(beatamlGroupedData.TUMOR).map(el => ({ ...el, checked: true })),
      tcgaDataTissues: tcgaDataTissuesWithLabels.map(el => ({ ...el, checked: true })),
    }));

    yield put(toggleSingleTargetForCancerTissueLoaderAction(false));
  } catch (e) {
    yield put(singleTargetForCancerTissueErrorAction(e.message));
    yield put(toggleSingleTargetForCancerTissueLoaderAction(false));
  }
}
