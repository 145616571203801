import React from 'react';
import PropTypes from 'prop-types';

// Components
import Input from '../../../../common/Inputs/Input/Input';

const propTypes = {
  conceptName: PropTypes.string,
  updateFilters: PropTypes.func,
};

const CreateSetUIFilters = (props) => {
  const { conceptName, updateFilters } = props;

  function handleFilterByName(e) {
    const { target: { value } } = e;
    updateFilters({
      filterType: 'conceptName',
      value,
    });
  }

  return (
    <div className="create-set-filter__wrap">
      <div className="create-set-filter__block create-set-filter__block_no-padding">
        <span className="create-set-filter__label">
          <span className="create-set-filter__label-text">
            Search concepts
          </span>
        </span>
        <Input
          value={conceptName}
          placeholder="Concept name"
          onChange={handleFilterByName}
        />
      </div>
    </div>
  );
};

CreateSetUIFilters.propTypes = propTypes;

export default CreateSetUIFilters;
