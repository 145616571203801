export const types = {
  GENE_DETAILS_FUNC_CHARACTERIZATION_GET_CHART_DATA: 'GENE_DETAILS_FUNC_CHARACTERIZATION_GET_CHART_DATA',
  GENE_DETAILS_FUNC_CHARACTERIZATION_SET_CHART_DATA: 'GENE_DETAILS_FUNC_CHARACTERIZATION_SET_CHART_DATA',
  GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_LOADER: 'GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_LOADER',
  GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_FAILED: 'GENE_DETAILS_FUNC_CHARACTERIZATION_CHART_FAILED',
  GENE_DETAILS_FUNC_CHARACTERIZATION_GET_TABLE_DATA: 'GENE_DETAILS_FUNC_CHARACTERIZATION_GET_TABLE_DATA',
  GENE_DETAILS_FUNC_CHARACTERIZATION_EXPORT_TABLE_DATA: 'GENE_DETAILS_FUNC_CHARACTERIZATION_EXPORT_TABLE_DATA',
  GENE_DETAILS_FUNC_CHARACTERIZATION_SET_TABLE_DATA: 'GENE_DETAILS_FUNC_CHARACTERIZATION_SET_TABLE_DATA',
  GENE_DETAILS_FUNC_CHARACTERIZATION_FILTER_TABLE: 'GENE_DETAILS_FUNC_CHARACTERIZATION_FILTER_TABLE',
  GENE_DETAILS_FUNC_CHARACTERIZATION_SORT_TABLE: 'GENE_DETAILS_FUNC_CHARACTERIZATION_SORT_TABLE',
  GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_LOADER: 'GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_LOADER',
  GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_FAILED: 'GENE_DETAILS_FUNC_CHARACTERIZATION_TABLE_FAILED',
};
