import {
  SAVE_RANKING_CONCEPT,
  APPLY_MARKER_SETTINGS,
  APPLY_TARGET_SETTINGS,
  REMOVE_RANKING_CONCEPT,
} from '../../common/SetAnalysisMethodSelection/constants';

import {
  VALIDATE_FORMULA_REQUESTED,
} from './constants';

export const saveRankingConcept = data => (
  {
    type: SAVE_RANKING_CONCEPT,
    data,
  }
);

export const applyMarkerSettings = () => (
  {
    type: APPLY_MARKER_SETTINGS,
  }
);

export const validateFormulaRequest = () => (
  {
    type: VALIDATE_FORMULA_REQUESTED,
  }
);

export const applyTargetSettings = () => (
  {
    type: APPLY_TARGET_SETTINGS,
  }
);

export const removeRankingConcept = data => (
  {
    type: REMOVE_RANKING_CONCEPT,
    data,
  }
);
