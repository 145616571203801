import { createSelector } from 'reselect';

// Utils
import { getFilteredColoredMetaData, filterViolinGeneData } from '../utils';
import { isEmptyObject } from '../../../../Utils/Utils';
// Dataset
const selectedDataset = state => state.getIn(['singleCellStudyReducer', 'selectedDataset']);
// Colors
const colorsData = state => state.getIn(['singleCellStudyReducer', 'colorsData']);
// Filters
const colourByOptions = state => state.getIn(['singleCellStudyReducer', 'colourByOptions']);
const dataFilters = state => state.getIn(['singleCellStudyReducer', 'dataFilters']);
const selectedFilterValue = state => state.getIn(['singleCellStudyReducer', 'selectedFilterValue']);
const metaDataCellTypeFilter = state => state.getIn(['singleCellStudyReducer', 'dataFilters', 'cellType']);
const metaDataIndividualFilter = state => state.getIn(['singleCellStudyReducer', 'dataFilters', 'patient']);
const metaDataConditionFilter = state => state.getIn(['singleCellStudyReducer', 'dataFilters', 'condition']);
// tumorTissue Metadata
const metaData = state => state.getIn(['singleCellStudyReducer', 'metaData', 'data']);
const metaDataLoading = state => state.getIn(['singleCellStudyReducer', 'metaData', 'loading']);
const metaDataError = state => state.getIn(['singleCellStudyReducer', 'metaData', 'error']);
const metaDataBackgrounds = state => state.getIn(['singleCellStudyReducer', 'metaData', 'backgroundGraphs']);
const metaDataUniqueCells = state => state.getIn(['singleCellStudyReducer', 'metaData', 'uniqueCells']);
const metaDataMarkers = state => state.getIn(['singleCellStudyReducer', 'markers']);
// Genedata
const selectedGene = state => state.getIn(['singleCellStudyReducer', 'selectedGene']);
const geneData = state => state.getIn(['singleCellStudyReducer', 'geneData', 'data']);
const geneZeroData = state => state.getIn(['singleCellStudyReducer', 'geneData', 'zerosData']);
const geneDataLoading = state => state.getIn(['singleCellStudyReducer', 'geneData', 'loading']);
const geneDataError = state => state.getIn(['singleCellStudyReducer', 'geneData', 'error']);
const geneDataBackgrounds = state => state.getIn(['singleCellStudyReducer', 'geneData', 'backgroundGraphs']);
const geneDataMeasureExtremes = state => state.getIn(['singleCellStudyReducer', 'geneData', 'measureExtremes']);
// Measures
const measures = state => state.getIn(['singleCellStudyReducer', 'measures']);

export const getSelectedDatasetSelector = createSelector(
  selectedDataset,
  data => data && data.toJS()
);

export const getColorsDataSelector = createSelector(
  colorsData,
  selectedFilterValue,
  (_data, _activeFilterValue) => {
    const data = _data && _data.toJS();
    const activeFilterValue = _activeFilterValue && _activeFilterValue.toJS();
    return data[activeFilterValue.value];
  }
);

export const getSelectedFilterValueSelector = createSelector(
  selectedFilterValue,
  data => data && data.toJS()
);

export const getMetaDataCellTypeFilter = createSelector(
  metaDataCellTypeFilter,
  data => data && data.toJS()
);

export const getMetaDataIndividualFilter = createSelector(
  metaDataIndividualFilter,
  data => data && data.toJS()
);

export const getMetaDataConditionFilter = createSelector(
  metaDataConditionFilter,
  data => data && data.toJS()
);

export const getColourByOptionsSelector = createSelector(
  colourByOptions,
  data => data && data.toJS()
);

export const getDataFiltersSelector = createSelector(
  dataFilters,
  data => data && data.toJS()
);

export const getDataFiltersAreEmptySelector = createSelector(
  [getDataFiltersSelector], (filters) => {
    const filtersKeys = Object.keys(filters);
    return filtersKeys.every(item => !filters[item].selectedOptions.length);
  }
);

export const getMetaUniqueCellsSelector = createSelector(
  metaDataUniqueCells,
  uniqueCells => uniqueCells && uniqueCells.toJS()
);

export const getMetaDataMarkersSelector = createSelector(
  metaDataMarkers,
  data => data && data.toJS()
);

export const getMetaLegendDataSelector = createSelector(
  [
    getMetaUniqueCellsSelector,
    getColorsDataSelector,
    getSelectedFilterValueSelector,
    getMetaDataMarkersSelector,
  ], (uniqueCells, colors, filterValue, markers) => {
    if (!!uniqueCells.length && !isEmptyObject(colors)) {
      return uniqueCells.map(i => (
        i[filterValue.value]
          .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
          .map((item) => {
            const markersData = markers.find(m => m.cellType === item);
            return ({
              value: item,
              color: colors[item][0],
              markers: markersData && markersData.markers,
            });
          })
      ));
    }
    return [];
  }
);

export const getMetaDataBackgroundGraphSelector = createSelector(
  metaDataBackgrounds,
  data => data && data.toJS()
);

export const getMetaDataLoadingSelector = createSelector(
  metaDataLoading,
  data => data
);

export const getMetaDataErrorSelector = createSelector(
  metaDataError,
  data => data
);

export const getSelectedGeneSelector = createSelector(
  selectedGene,
  data => data && data.toJS()
);

export const getGeneDataBackgroundGraphsSelector = createSelector(
  geneDataBackgrounds,
  data => data && data.toJS()
);

export const getGeneDataLoadingSelector = createSelector(
  geneDataLoading,
  data => data
);

export const getGeneDataErrorSelector = createSelector(
  geneDataError,
  data => data
);

export const getMeasuresSelector = createSelector(
  measures,
  data => data && data.toJS()
);

export const getMetaDataSelector = createSelector(
  metaData,
  data => data && data.toJS()
);

export const getFilteredMetaDataSelector = createSelector(
  [
    getMetaDataSelector,
    getColorsDataSelector,
    getDataFiltersSelector,
    getSelectedFilterValueSelector,
  ],
  (data, colors, filtersData, selectedFilter) => (
    data.map(d => getFilteredColoredMetaData(
      d,
      colors,
      filtersData,
      selectedFilter.value,
    ))
  )
);

export const getGeneDataSelector = createSelector(
  geneData,
  data => data && data.toJS()
);

export const getGeneZeroDataSelector = createSelector(
  geneZeroData,
  data => data && data.toJS()
);

export const getGeneDataMeasureExtremesSelector = createSelector(
  geneDataMeasureExtremes,
  data => data && data.toJS()
);

export const getFilteredViolinDataSelector = createSelector(
  [
    getGeneDataSelector,
    getGeneZeroDataSelector,
    getDataFiltersSelector,
    getMetaUniqueCellsSelector,
    getColorsDataSelector,
    getSelectedDatasetSelector,
  ],
  (data, zeroData, filtersData, uniqueCells, colors, dataset) =>
    data.map((d, idx) => (
      filterViolinGeneData(
        d,
        zeroData[idx],
        filtersData,
        uniqueCells[idx],
        colors,
        dataset,
      )
    ))
);
