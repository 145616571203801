import { createSelector } from 'reselect';

const geneOptions = state => state.getIn(['selectGene', 'options']);
const geneLoader = state => state.getIn(['selectGene', 'loading']);
const geneError = state => state.getIn(['selectGene', 'error']);

export const getGeneOptionSelector = createSelector(
  geneOptions,
  data => data && data.toJS()
);

export const getGeneLoaderSelector = createSelector(
  geneLoader,
  data => data
);

export const getGeneErrorSelector = createSelector(
  geneError,
  data => data
);
