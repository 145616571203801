import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { geneDetailsSetChapterAction, loadNextChapterAction } from '../../actions';
import {
  getGeneDetailsInternalLinksAction,
  setGeneDetailsInternalLinksAction,
  throwGeneDetailsInternalLinksErrorAction,
} from './reducer';

export function* getInternalLinksWorker({ payload }) {
  try {
    const { data } = yield call(Api.getGeneDetailsInternalLinks, payload);
    yield put(setGeneDetailsInternalLinksAction(data));
    yield put(geneDetailsSetChapterAction('internal-links'));
    yield put(loadNextChapterAction('external-links'));
  } catch (e) {
    yield put(throwGeneDetailsInternalLinksErrorAction(e.message));
  }
}

function* internalLinksSaga() {
  yield takeLatest(getGeneDetailsInternalLinksAction, getInternalLinksWorker);
}

export default internalLinksSaga;
