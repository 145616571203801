import { createSelector } from 'reselect';

const pageChapter = 'geneDetailsProteinStructureChapter';

const proteinStructureLoading = state => state.getIn([pageChapter, 'loading']);
const proteinStructureError = state => state.getIn([pageChapter, 'error']);
const protein = state => state.getIn([pageChapter, 'protein']);
const proteinUniProt = state => state.getIn([pageChapter, 'protein', 'uniprot']);
const proteinPDBEntries = state => state.getIn([pageChapter, 'PDBEntries']);
const proteinPDBEntriesError = state => state.getIn([pageChapter, 'PDBError']);
const proteinPDBId = state => state.getIn([pageChapter, 'PDBId']);

export const getProteinStructureSelector = createSelector(
  protein,
  data => data && data.toJS(),
);

export const getLoadingSelector = createSelector(
  proteinStructureLoading,
  loading => loading
);

export const getErrorSelector = createSelector(
  proteinStructureError,
  error => error
);

export const getProteinUniProtSelector = createSelector(
  proteinUniProt,
  data => data,
);

export const getProteinPDBEntriesSelector = createSelector(
  proteinPDBEntries,
  data => data && data.toJS()
);

export const getProteinPDBErrorSelector = createSelector(
  proteinPDBEntriesError,
  data => data
);

export const getProteinPDBIdSelector = createSelector(
  proteinPDBId,
  data => data && data.toJS()
);
