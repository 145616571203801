import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Utils
import { insertHTML } from '../../../../Utils/Utils';
// Styles
import './styles.scss';
import classNames from 'classnames';

const propTypes = {
  concept: PropTypes.instanceOf(Object),
  semanticTypes: PropTypes.instanceOf(Object),
  submitSearch: PropTypes.func,
  selected: PropTypes.bool,
};

const ConceptsSearchItem = (props) => {
  const {
    concept,
    semanticTypes,
    submitSearch,
    selected,
  } = props;
  const {
    id,
    name,
    synonyms,
    definitions,
    semanticType,
  } = concept;

  const handleClick = useCallback(() => {
    submitSearch(concept);
  }, [concept, submitSearch]);

  const conceptSearchContent = classNames({
    'concepts-search-item': true,
    'concepts-search-item__checked': selected,
  });

  return (
    <div key={id} className={conceptSearchContent} onClick={handleClick}>
      <div className="concepts-search-item__header">
        <span className="concepts-search-item__title">
          {name}
        </span>
      </div>
      {
        semanticType && semanticType.length > 0 &&
        <div className="concepts-search-item__block">
          <span className="concepts-search-item__sub-title">
            Semantic types:
          </span>
          <span className="concepts-search-item__text">
            {semanticType.map(t => semanticTypes[t]).join(', ')}.
          </span>
        </div>
      }
      {
        synonyms && synonyms.length > 0 &&
        <div className="concepts-search-item__block">
          <span className="concepts-search-item__sub-title">
            Synonyms:
          </span>
          <span className="concepts-search-item__text">
            {synonyms.join(', ')}.
          </span>
        </div>
      }
      {
        definitions &&
        <div className="concepts-search-item__block">
          <span className="concepts-search-item__sub-title">
            Definitions:
          </span>
          <span
            className="concepts-search-item__text"
            dangerouslySetInnerHTML={insertHTML(definitions)}
          />
        </div>
      }
    </div>
  );
};

ConceptsSearchItem.propTypes = propTypes;

export default ConceptsSearchItem;
