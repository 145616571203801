export const getDatabaseIdentifier = (key, value = 'Homo_sapiens') => {
  switch (key) {
    case 'mim':
      return {
        name: 'OMIM',
        link: 'https://www.omim.org/entry/',
      };

    case 'hgnc':
      return {
        name: 'HGNC',
        link: 'https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/HGNC:',
      };

    case 'hprd':
      return {
        name: 'HPRD',
        link: 'http://www.hprd.org/protein/',
      };

    case 'entrezgene':
      return {
        name: 'entrezgene',
        link: 'https://www.ncbi.nlm.nih.gov/gene/',
      };

    case 'umls':
      return {
        name: 'UMLS',
        link: null,
      };

    case 'uniprot':
      return {
        name: 'Uniprot',
        link: 'https://www.uniprot.org/uniprot/',
      };

    case 'vega':
      return {
        name: 'VEGA',
        link: `http://vega.archive.ensembl.org/${value}/Gene/Summary?g=`,
      };

    case 'ensembl':
      return {
        name: 'Ensemble',
        link: `https://www.ensembl.org/${value}/Gene/Summary?db=core;g=`,
      };

    default:
      return {
        name: '',
        link: '',
      };
  }
};
