import { fromJS } from 'immutable';

import {
  SORT,
  CHECK_ALL,
  CHECK_ITEM,
  REMOVE_SELECTED,
  INVERT_SELECTION,
} from '../../../common/SimpleTable/constants';

import {
  RANKING_SUCCEEDED,
  RANKING_FAILED,
  RANKING_RESULT_TABLE,
  REMOVE_CONCEPT,
  RANKING_RESULT_TABLE_RESET,
  LOADING,
  effectOperation,
  EFFECT_OPERATION,
  EFFECT_COLUMNS,
} from './constants';
import { fillAggregationData } from './utils';

const initialValues = fromJS({
  result: {
    content: [],
  },
  error: null,
  sorting: { sortBy: 'geneName', sortDirection: 'ASC' },
  effectOperation: effectOperation.Sum,
  effectColumns: [],
  loading: false,
});

const AnalysisResultRanking = (state = initialValues, action) => {
  switch (action.type) {
    case RANKING_SUCCEEDED: {
      let { data } = action;
      let { content } = data;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => (
          Object.assign(item, { id: index + 1 })
        ));
      }
      data = Object.assign(data, { content });
      return state.merge(fromJS({ result: data, error: null, loading: false }));
    }

    case RANKING_FAILED:
      return state.merge(fromJS({ error: action.message, result: initialValues.get('result'), loading: false }));

    case RANKING_RESULT_TABLE + SORT:
      return state.merge(fromJS({ sorting: action.data }));

    case RANKING_RESULT_TABLE + CHECK_ALL:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: action.data }))
          ))
        ))
      ));

    case RANKING_RESULT_TABLE + CHECK_ITEM:
      return state.update('result', result => (
        result.update('content', content => (
          content.map((item) => {
            if (item.get('id') === action.data.id) {
              return item.merge(fromJS({ selected: action.data.checked }));
            }
            return item;
          })
        ))
      ));

    case RANKING_RESULT_TABLE + REMOVE_SELECTED:
      return state.update('result', result => (
        result.update('content', content => (
          content.filterNot(item => (
            item.get('selected')
          ))
        ))
      ));

    case RANKING_RESULT_TABLE + INVERT_SELECTION:
      return state.update('result', result => (
        result.update('content', content => (
          content.map(item => (
            item.merge(fromJS({ selected: !item.get('selected') }))
          ))
        ))
      ));

    case RANKING_RESULT_TABLE + REMOVE_CONCEPT:
      return state.update('result', result => (
        result.update('content', content => (
          content.delete(content.findIndex(item => (
            item.get('id') === action.data
          )))
        ))
      ));

    case RANKING_RESULT_TABLE + LOADING:
      return state.merge(fromJS({ loading: action.data }));

    case RANKING_RESULT_TABLE_RESET:
      return state.merge(initialValues);

    case RANKING_RESULT_TABLE + EFFECT_COLUMNS:
      return state.merge(fromJS({ effectColumns: action.data }));

    case RANKING_RESULT_TABLE + EFFECT_OPERATION: {
      fillAggregationData(action.data.page, state.get('effectColumns'), action.data.operation);
      return state
        .updateIn(['result', 'content'], () => fromJS(action.data.page))
        .merge(fromJS({ effectOperation: action.data.operation }));
    }

    default:
      return state;
  }
};

export default AnalysisResultRanking;
