export const rankingCategoryEnum = {
  CLASSIFICATION: 'CLASSIFICATION',
  DIFFERENTIAL_EXPRESSION: 'DIFFERENTIAL_EXPRESSION',
  EXPRESSION_SPECIFICITY: 'EXPRESSION_SPECIFICITY',
  ASSOCIATION_SCORE: 'ASSOCIATION_SCORE',
  LITERATURE_SEARCH: 'LITERATURE_SEARCH',
  INVOLVEMENT_OTHER_DISEASES: 'INVOLVEMENT_OTHER_DISEASES',
  GENETIC_INTERACTIONS: 'GENETIC_INTERACTIONS',
  GENE_VARIATION: 'GENE_VARIATION',
  COMPOUND_SCORE: 'COMPOUND_SCORE',
  DRUGGABLE_STRUCTURE: 'DRUGGABLE_STRUCTURE',
  HALLMARKS_OF_CANCER: 'HALLMARKS_OF_CANCER',
  TARGET_VALIDATION_STAGE: 'TARGET_VALIDATION_STAGE',
};

export const rankingCategoryRequestEnum = {
  CLASSIFICATION: 'classification',
  DIFFERENTIAL_EXPRESSION: 'differentialExpression',
  EXPRESSION_SPECIFICITY: 'expressionSpecificity',
  ASSOCIATION_SCORE: 'associationScore',
  LITERATURE_SEARCH: 'literatureSearch',
  INVOLVEMENT_OTHER_DISEASES: 'involvementOtherDiseases',
  GENETIC_INTERACTIONS: 'geneticInteractions',
  GENE_VARIATION: 'geneVariation',
  COMPOUND_SCORE: 'compoundScore',
  DRUGGABLE_STRUCTURE: 'druggableStructure',
  HALLMARKS_OF_CANCER: 'hallmarksOfCancer',
  TARGET_VALIDATION_STAGE: 'targetValidationStage',
};
