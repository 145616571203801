import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Sets from '../../../Sets/SetsList/Sets';
import Heatmap from '../../../graphics/Heatmap/Heatmap';
import UploadSetModal from '../../../Modals/UploadSetModal/UploadSetModal';
import SetAnalysisSet from '../SetAnalysisSet/SetAnalysisSet';
import VennDiagram from '../../../graphics/VennDiagram/VennDiagram';
import SetAnalysisResults from '../SetAnalysisResults/SetAnalysisResults';
import SetsDataSelection from '../../../Sets/SetsDataSelection/SetsDataSelection';
import SetAnalysisMethodSelection from '../SetAnalysisMethodSelection/SetAnalysisMethodSelection';
import SelectBackgroundSetPopup from '../../../common/SelectBackgroundSetComponent/SelectBackgroundSetComponent';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import AddTag from '../../../common/AddTag/AddTag';
import AddTagContent from '../../../common/AddTag/AddTagContent';
import Loader from '../../../common/Loader/Loader';
import EditableTitleInput from './EditableTitleInput/EditableTitleInput';
import VersionDialog from '../../../Modals/VersionModal/VersionModal';
// Store
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
import { getAvailableSetsAction } from '../../../Sets/SetsList/reducer';
import { getLocationHistorySelector } from '../../../../location/locationSelector';
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import {
  refreshRankingSettings,
  resetSetAnalysisMethodSelectionAction,
  resetNetworkAnalysisSettings as resetNetworkAnalysisSettingsAction,
} from '../SetAnalysisMethodSelection/actions';
import {
  initStorage,
  reset as resetSetAnalysisDataSelectionAction,
} from '../../../Sets/SetsDataSelection/reducer';
import {
  getIsProjectLockedForUsersSelector,
  getIsProjectLockedSelector,
  getIsProjectPublicSelector,
} from '../../../Projects/ProjectsTabs/store/selectors';
import { isProjectManagerRightSelector } from '../../../Header/selectors';
import { resetSaveAsSetDataAction } from '../../../Sets/SaveAsSet/store/actions';
import { analyticsLoading } from './actions';
// Utils
import { getUniqConcepts, getLocationParams, formatDate } from '../../../Utils/Utils';
import { withRouter } from '../../../common/WithRouter/WithRouter';
import { DATE_FULL_FORMAT } from '../../../../constantsCommon';
// Styles
import './SetAnalysis.css';

const propTypes = {
  sets: PropTypes.instanceOf(Array),
  activeSets: PropTypes.instanceOf(Array),
  saveAnalytics: PropTypes.func,
  initDataSelectionStorageWithActualSets: PropTypes.func,
  initAnalytics: PropTypes.func,
  removeSet: PropTypes.func,
  changeColor: PropTypes.func,
  nameChanged: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  setAnalyticsComment: PropTypes.func,
  analyticsTags: PropTypes.instanceOf(Array),
  comment: PropTypes.string,
  vennDiagramData: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  lastSavedAnalyticsId: PropTypes.string,
  clearSets: PropTypes.func,
  shouldClearAllSetsFlag: PropTypes.bool,
  setShouldClearAllSetsFlagToTrue: PropTypes.func,
  setAnalyticsTitle: PropTypes.func,
  resetAnalyticsTitle: PropTypes.func,
  selectedIds: PropTypes.instanceOf(Array),
  getAvailableSets: PropTypes.func,
  navigate: PropTypes.func,
  navigationType: PropTypes.string,
  locationParams: PropTypes.instanceOf(Object),
  locationHistory: PropTypes.instanceOf(Array),
  setSetAnalysisId: PropTypes.func,
  resetSetAnalysisDataSelection: PropTypes.func,
  selectSetWithAPICall: PropTypes.func,
  setAnalysisUpdateTags: PropTypes.func,
  refreshRanking: PropTypes.func,
  analyseData: PropTypes.instanceOf(Array),
  resetNetworkAnalysisSettings: PropTypes.func,
  isProjectLocked: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  isAdminRights: PropTypes.bool,
  isProjectLockedForUsers: PropTypes.bool,
  initSetsToSetAnalysis: PropTypes.func,
  resetSetAnalysisMethodSelection: PropTypes.func,
  reportTitleFromStore: PropTypes.string,
  setSetAnalysisPutUploadedSet: PropTypes.func,
  resetSaveAsSet: PropTypes.func,
  blocker: PropTypes.func,
  version: PropTypes.number,
  analyticsLoading: PropTypes.func,
  shouldComponentBeUpdated: PropTypes.bool,
  firstSelectedMethod: PropTypes.string,
};

class SetAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editTitle: false,
      savingAnalytics: false,
      selectSetPopup: false,
      selectBackgroundSetPopup: false,
      screenshot: '',
    };
    this.retryUnblockRef = React.createRef();
  }

  componentDidMount() {
    const {
      sets,
      location,
      locationParams: {
        projectId,
        analyticsId,
      },
      initSetsToSetAnalysis,
      setAnalyticsTitle,
    } = this.props;

    let isActiveBlock = false;

    const unblock = this.props.blocker(({ retry }) => {
      this.retryUnblockRef = retry;
      if (isActiveBlock) {
        unblock();
        return retry();
      } else if (!isActiveBlock) {
        this.routerWillLeave();
      }

      isActiveBlock = true;
    });

    const storageData = JSON.parse(localStorage.getItem('setAnalysisSets'));
    const { runAfter } = !!location.search && getLocationParams(location.search);

    if (location.state?.initNewAnalytics) {
      this.resetAnalytics();
      initSetsToSetAnalysis(sets);
      return;
    }

    if (analyticsId === 'new' && !runAfter) {
      this.resetAnalytics();
      return;
    }

    if (runAfter) {
      this.initDataSelectionStorage();
    } else {
      this.initAnalytics(analyticsId, projectId);
    }

    if (storageData) {
      initSetsToSetAnalysis(storageData);
      setAnalyticsTitle(formatDate(null, DATE_FULL_FORMAT));
      localStorage.removeItem('setAnalysisSets');
    }
  }

  componentDidUpdate(prevProps) {
    const { sets, activeSets, shouldComponentBeUpdated } = this.props;
    const { sets: prevSets, activeSets: prevActiveSets, shouldComponentBeUpdated: prevShouldComponentBeUpdated } = prevProps;

    if (prevActiveSets !== activeSets) {
      this.initDataSelectionStorage();
      return;
    }

    this.setDiagramScreenshot();

    if (prevShouldComponentBeUpdated !== shouldComponentBeUpdated && shouldComponentBeUpdated) {
      this.setDiagramScreenshot();
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sets.length; i++) {
      const { name, colour, textareaValue } = sets[i];
      const { name: prevName, colour: prevColour, textareaValue: prevTextareaValue } = prevSets[i];
      if (name !== prevName || colour !== prevColour || textareaValue.length !== prevTextareaValue.length) {
        this.initDataSelectionStorage();
        break;
      }
    }
  }

  componentWillUnmount() {
    this.props.setShouldClearAllSetsFlagToTrue();
  }

  clearAllSets = () => {
    const { clearSets, shouldClearAllSetsFlag } = this.props;
    if (shouldClearAllSetsFlag) {
      clearSets();
    }
  };

  initAnalytics = (analyticsId, projectId) => {
    const {
      initAnalytics,
      lastSavedAnalyticsId,
    } = this.props;

    const id = analyticsId ? analyticsId : lastSavedAnalyticsId;

    if (id) {
      initAnalytics({ id, projectId });
    }
  };

  resetAnalytics = () => {
    const {
      resetNetworkAnalysisSettings,
      resetSetAnalysisDataSelection,
      resetSetAnalysisMethodSelection,
      refreshRanking,
    } = this.props;

    this.clearAllSets();
    refreshRanking();
    resetNetworkAnalysisSettings();
    resetSetAnalysisDataSelection();
    resetSetAnalysisMethodSelection();
  };

  initDataSelectionStorage = () => {
    const {
      activeSets,
      selectedIds,
      initDataSelectionStorageWithActualSets,
      getAvailableSets,
    } = this.props;
    const amountOfSets = activeSets.length;
    if (!amountOfSets) return;
    const { checkedSets, dataSelection, noGenes } = getUniqConcepts(activeSets, selectedIds);

    if (noGenes) {
      getAvailableSets({ noGenes: true });
    }

    initDataSelectionStorageWithActualSets({
      activeSets,
      dataSelection,
      checkedSets,
    });
  };

  saveActionsAreDisabled = () => {
    const {
      isAdminRights,
      isProjectPublic,
      isProjectLocked,
      isProjectLockedForUsers,
    } = this.props;
    if (isProjectLocked || isProjectPublic || (isProjectLockedForUsers && !isAdminRights)) {
      return true;
    }
    return false;
  };

  routerWillLeave(nextLocation = '/', overwrite) {
    if (nextLocation === '/login') return true;
    const { saveAnalytics, locationParams: { projectId } } = this.props;
    let screenshot = JSON.parse(localStorage.getItem('screenshot'));

    this.setState({
      savingAnalytics: true,
    });

    const saveActionsAreDisabled = this.saveActionsAreDisabled();

    if (!saveActionsAreDisabled) {
      saveAnalytics({
        overwrite,
        path: nextLocation,
        screenshot,
        projectId,
        retryUnblockPage: this.retryUnblockRef,
      });
    }

    return false;
  }

  setDiagramScreenshot() {
    const diagram = window.document.querySelector('#canvasEllipse>svg');
    let screenshot = '';

    if (diagram) {
      const stringifiedSVG = new XMLSerializer().serializeToString(diagram);
      screenshot = `data:image/svg+xml;base64,${window.btoa(encodeURIComponent(stringifiedSVG))}`;
      localStorage.setItem('screenshot', JSON.stringify(screenshot));
    }
  }

  editTitle = () => {
    const { editTitle } = this.state;
    this.setState({ editTitle: !editTitle });
  };

  selectSet = (id) => {
    this.setState({
      selectSet: true,
      uploadFile: false,
      setForId: id,
      selectSetPopup: true,
    });
  };

  uploadSet = (id) => {
    this.setState({
      selectSet: false,
      uploadFile: true,
      setForId: id,
      selectSetPopup: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      selectSetPopup: false,
    });
  };

  openSelectBackgroundSetPopup = () => {
    this.setState({ selectBackgroundSetPopup: true });
  };

  closeSelectBackgroundSetPopup = () => {
    this.setState({ selectBackgroundSetPopup: false });
  };

  selectSetWithAPICall = ({ projectId, selectedSets }) => {
    const { selectSetWithAPICall } = this.props;
    const { setForId } = this.state;

    selectSetWithAPICall({
      projectId,
      id: setForId,
      selectedSets,
    });
  };

  hadleUploadSetFile = (items) => {
    const { setSetAnalysisPutUploadedSet } = this.props;
    const { setForId } = this.state;
    setSetAnalysisPutUploadedSet({ id: setForId, items });
  };

  setActions = {
    select: this.selectSet,
    upload: this.uploadSet,
    remove: this.props.removeSet,
    colorChanged: this.props.changeColor,
    nameChanged: this.props.nameChanged,
  };

  render() {
    const {
      analyticsTags,
      sets,
      comment,
      vennDiagramData,
      loading,
      error,
      setAnalysisUpdateTags,
      analyseData,
      locationParams: { projectId },
      reportTitleFromStore,
      analyticsLoading,
      firstSelectedMethod,
      location,
    } = this.props;

    const {
      editTitle,
      selectSet,
      selectSetPopup,
      uploadFile,
      savingAnalytics,
      selectBackgroundSetPopup,
    } = this.state;

    return (
      <div className="set-analysis-page">
        {
          !loading &&
          !savingAnalytics &&
          <div>
            <div className="row report-info mt-20">
              <div className="report-info-title">
                <EditableTitleInput
                  saveIconClickHandler={this.editTitle}
                  editTitle={editTitle}
                  onChangeHandler={this.props.setAnalyticsTitle}
                />
              </div>
              <div className="report-info-tags">
                <AddTag
                  tagList={analyticsTags}
                  updateTags={setAnalysisUpdateTags}
                  noReset={true}
                />
                <AddTagContent
                  tagList={analyticsTags}
                  updateTags={setAnalysisUpdateTags}
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="flex-grid justify-content-center">
                <div className="col-1 section-header">
                  <div className="row">
                    <span className="section-header-label title3">
                      Data input
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-grid justify-content-center">
                <div className="col-1">
                  <div className="setAnalysis block-center">
                    <div className="sets setanalysis">
                      {
                        sets.map((set, index) => (
                          <SetAnalysisSet
                            key={index}
                            set={set}
                            index={index}
                            actions={this.setActions}
                          />
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              vennDiagramData.data.length > 0 &&
              <div className="row mt-20">
                <div className="flex-grid justify-content-center">
                  <div className="col-1 section-header">
                    <div className="row">
                      <span className="section-header-label title3">
                        Data selection
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-grid justify-content-center mt-20 sets-venn-diagram__container">
                  <div className="col-1">
                    <VennDiagram
                      vennData={vennDiagramData}
                    />
                  </div>
                  <div className="col-2">
                    <SetsDataSelection
                      analysis={true}
                      initDataSelectionStorage={this.initDataSelectionStorage}
                    />
                    <span className="set-data__concept-count">
                      {`Concept selected: ${analyseData.length}`}
                    </span>
                  </div>
                </div>
              </div>
            }
            <div className="row mt-20">
              <SetAnalysisMethodSelection
                location={location}
                firstSelectedMethod={firstSelectedMethod}
                openSelectBackgroundSetPopup={this.openSelectBackgroundSetPopup}
              />
            </div>
            <div className="row mt-20">
              {
                vennDiagramData.data.length > 0 &&
                <SetAnalysisResults />
              }
            </div>
            <div className="row mt-20">
              <Heatmap
                projectId={projectId}
                isSetAnalysis={true}
                networkAnalysisTitle={reportTitleFromStore || 'Title'}
              />
            </div>
            <div className="row mt-20">
              <div className="report-comment">
                <label htmlFor="report-comment">Notes:</label>
                <textarea
                  name="report-comment"
                  value={comment}
                  onChange={(e) => { this.props.setAnalyticsComment(e.target.value); }}
                />
              </div>
            </div>
          </div>
        }
        <UploadSetModal
          onSubmit={this.hadleUploadSetFile}
          isOpen={selectSetPopup && uploadFile}
          closeCb={this.handleModalClose}
          callbackAction={ACTIONS.getFileDataForSetAnalysisAction}
        />
        {
          selectSetPopup && selectSet &&
          <ModalComponent
            isOpen={selectSetPopup}
            closeCb={this.handleModalClose}
          >
            <Sets
              selectFunction={this.selectSetWithAPICall}
              closeFunction={this.handleModalClose}
            />
          </ModalComponent>
        }
        {
          selectBackgroundSetPopup &&
          <ModalComponent
            isOpen={selectBackgroundSetPopup}
            closeCb={this.closeSelectBackgroundSetPopup}
          >
            <SelectBackgroundSetPopup
              closePopup={this.closeSelectBackgroundSetPopup}
            />
          </ModalComponent>
        }
        <VersionDialog
          onConfirm={() => { this.routerWillLeave('/', true); }}
          closeCb={() => {
            this.retryUnblockRef();
            analyticsLoading(false);
          }}
          text="Analytics was changed by other user. Do you want to rewrite changes?"
        />
        <Loader isLoading={loading && !error} />
        {
          loading && !error && savingAnalytics &&
          <div className="saving-project-message">Saving project...</div>
        }
      </div>
    );
  }
}

SetAnalysis.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    comment: SELECTORS.getComment(state),
    sets: SELECTORS.setAnalysisSets(state),
    activeSets: SELECTORS.getActiveSets(state),
    vennDiagramData: SELECTORS.getVennDiagramData(state),
    analyticsTags: SELECTORS.getAnalyticsTags(state),
    loading: SELECTORS.getAnalyticsLoading(state),
    selectedIds: SELECTORS.getSelectedIds(state),
    lastSavedAnalyticsId: SELECTORS.getLastSavedAnalyticsId(state),
    shouldClearAllSetsFlag: SELECTORS.getShouldClearAllSetsFlagValue(state),
    locationHistory: getLocationHistorySelector(state),
    analyseData: selectedDataForAnalysis(state),
    isProjectLocked: getIsProjectLockedSelector(state),
    isProjectPublic: getIsProjectPublicSelector(state),
    isAdminRights: isProjectManagerRightSelector(state),
    isProjectLockedForUsers: getIsProjectLockedForUsersSelector(state),
    reportTitleFromStore: SELECTORS.getReportTitle(state),
    version: SELECTORS.getVersion(state),
    shouldComponentBeUpdated: SELECTORS.getShouldComponentBeUpdatedSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShouldClearAllSetsFlagToTrue() {
      dispatch(ACTIONS.setShouldClearAllSetsFlagToTrueAction());
    },
    initSetsToSetAnalysis(data) {
      dispatch(ACTIONS.initSetsToSetAnalysis(data));
    },
    removeSet(id) {
      dispatch(ACTIONS.removeSetFromSetAnalysis(id));
    },
    clearSets() {
      dispatch(ACTIONS.clearAllSetsAction());
    },
    changeColor(data) {
      dispatch(ACTIONS.changeColor(data));
    },
    nameChanged(data) {
      dispatch(ACTIONS.nameChanged(data));
    },
    initDataSelectionStorageWithActualSets(sets) {
      dispatch(initStorage(sets));
    },
    setAnalyticsTitle(data) {
      dispatch(ACTIONS.setAnalyticsTitle(data));
    },
    resetAnalyticsTitle() {
      dispatch(ACTIONS.resetAnalyticsTitleAction());
    },
    saveAnalytics(data) {
      dispatch(ACTIONS.saveAnalyticsAction(data));
    },
    initAnalytics(data) {
      dispatch(ACTIONS.initAnalytics(data));
    },
    setAnalyticsComment(data) {
      dispatch(ACTIONS.setAnalyticsComment(data));
    },
    getAvailableSets(data) {
      dispatch(getAvailableSetsAction(data));
    },
    setSetAnalysisId(data) {
      dispatch(ACTIONS.setSetAnalysisIdAction(data));
    },
    resetSetAnalysisDataSelection() {
      dispatch(resetSetAnalysisDataSelectionAction());
    },
    selectSetWithAPICall(data) {
      dispatch(ACTIONS.selectSetWithAPICallAction(data));
    },
    setAnalysisUpdateTags(data) {
      dispatch(ACTIONS.setAnalysisUpdateTagsAction(data));
    },
    refreshRanking() {
      dispatch(refreshRankingSettings());
    },
    resetNetworkAnalysisSettings() {
      dispatch(resetNetworkAnalysisSettingsAction());
    },
    resetSetAnalysisMethodSelection() {
      dispatch(resetSetAnalysisMethodSelectionAction());
    },
    setSetAnalysisPutUploadedSet(data) {
      dispatch(ACTIONS.setSetAnalysisPutUploadedSetAction(data));
    },
    resetSaveAsSet() {
      dispatch(resetSaveAsSetDataAction());
    },
    analyticsLoading(data) {
      dispatch(analyticsLoading(data));
    }
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAnalysis));
