import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Store
import { setTargetCandidatesPairsDataAction } from '../../../Algorithms/BispecificAntibody/store/reducer';
import { getSingleTargetForCancerSortSelector } from '../../../Algorithms/SingleTargetForCancer/selectors';
import {
  setSingleTargetForCancerDataAction,
  setSingleTargetForCancerRequestDataAction,
} from '../../../Algorithms/SingleTargetForCancer/reducer';
import { targetCandidatesPairsLoadingAction, targetCandidatesPairsErrorAction } from '../../reducer';
// Utils
import { capitalizeDataName, splitData } from '../../../Algorithms/SingleTargetForCancer/utils';
import { algorithmEnums } from '../../../constants';

export function* getTargetCandidatesPairsDataWorker({ payload }) {
  try {
    yield put(targetCandidatesPairsLoadingAction(true));
    const { algorithm } = payload;

    switch (algorithm) {
      case algorithmEnums.biSpecificAntibody: {
        const { data } = yield call(Api.postWorkflows, payload);
        yield put(setTargetCandidatesPairsDataAction(data.targetGenePairs));
        break;
      }

      case algorithmEnums.singleTargetForCancer: {
        yield put(setSingleTargetForCancerRequestDataAction(payload));
        const { sortBy, sortDirection, sortPath } = yield select(getSingleTargetForCancerSortSelector);

        const sort = sortBy || sortPath;
        const requestData = {
          ...payload,
          ...(sort && {
            params: {
              ...payload.params,
              sort: `${sort},${sortDirection}`,
            },
          }),
        };
        const { data } = yield call(Api.postWorkflows, requestData);

        const chartsData = splitData(data.rows.content, data.max, data.min);
        const { heatMapData } = chartsData;
        heatMapData.names = capitalizeDataName(heatMapData.names);
        heatMapData.data = capitalizeDataName(heatMapData.data);
        const singleTargetForCancerData = {
          ...chartsData,
          heatMapData,
          tableData: data.rows.content,
        };
        yield put(setSingleTargetForCancerDataAction({
          data: singleTargetForCancerData,
          totalPages: data.rows.totalPages,
          totalElements: data.rows.totalElements,
          pageNumber: data.rows.pageable.pageNumber,
        }));
        break;
      }

      default:
        break;
    }
    yield put(targetCandidatesPairsErrorAction(null));
    yield put(targetCandidatesPairsLoadingAction(false));
  } catch (e) {
    yield put(targetCandidatesPairsErrorAction(e.message));
    yield put(targetCandidatesPairsLoadingAction(false));
  }
}
