import { createSelector } from 'reselect';

import { sort } from '../../Utils/Utils';

const openKey = state => state.getIn(['setResultPageAddConcepts', 'openKey']);
const relations = state => state.getIn(['setResultPageAddConcepts', 'relations', 'data']);
const relationsLoading = state => state.getIn(['setResultPageAddConcepts', 'relations', 'loading']);
const categories = state => state.getIn(['setResultPageAddConcepts', 'categories', 'data']);
const categoriesLoading = state => state.getIn(['setResultPageAddConcepts', 'categories', 'loading']);
const taxonomies = state => state.getIn(['setResultPageAddConcepts', 'taxonomies', 'data']);
const taxonomiesLoading = state => state.getIn(['setResultPageAddConcepts', 'taxonomies', 'loading']);

const relatedConcepts = state => state.getIn(['setResultPageAddConcepts', 'linkedConcepts', 'data']);
const relatedConceptsLoading = state => state.getIn(['setResultPageAddConcepts', 'linkedConcepts', 'loading']);

const sorting = state => state.getIn(['setResultPageAddConcepts', 'sorting']);
const filter = state => state.getIn(['setResultPageAddConcepts', 'filter']);

export const getFilter = createSelector(
  filter,
  filterResult => filterResult
);

export const getSorting = createSelector(
  sorting,
  sortingResult => sortingResult.toJS()
);

export const getRelatedConcepts = createSelector(
  relatedConcepts,
  getSorting,
  getFilter,
  (relatedConceptsResult, sortingResult, filterResult) => {
    const filtered = relatedConceptsResult.filter((item) => {
      const name = item.get('concept').get('name') || '';
      return name.toLowerCase().indexOf(filterResult) !== -1;
    });
    const sorted = sort(filtered, sortingResult);
    return sorted.toJS();
  }
);

export const getCheckedRelatedConcepts = createSelector(
  relatedConcepts,
  relatedConceptsResult =>
    relatedConceptsResult
      .filter(item =>
        item.get('checked'))
      .toJS()
);

export const getRelatedConceptsLoading = createSelector(
  relatedConceptsLoading,
  relatedConceptsLoadingResult => relatedConceptsLoadingResult
);

export const getOpenKey = createSelector(
  openKey,
  openKeyResult => openKeyResult
);

export const getRelationsForFilter = createSelector(
  relations,
  relationsResult => relationsResult.toJS()
);

export const getSelectedRelationPredicates = createSelector(
  getRelationsForFilter,
  (relationsResult) => {
    const predicates = [];
    relationsResult.forEach((relation) => {
      relation.predicates.forEach((predicate) => {
        if (predicate.checked) {
          predicates.push(predicate);
        }
      });
    });

    return predicates.length > 0 ?
      predicates.map(predicate =>
        ({
          predicateId: predicate.id,
        })
      ) : [];
  }
);

export const getRelationsLoading = createSelector(
  relationsLoading,
  relationsLoadingResult => relationsLoadingResult
);

export const getCategoriesForFilter = createSelector(
  categories,
  categoriesResult => categoriesResult.toJS()
);

export const getSelectedCategories = createSelector(
  getCategoriesForFilter,
  (categoriesResult) => {
    const selectedCategories = [];
    const selectedCategoriesTypes = [];

    categoriesResult.forEach((category) => {
      if (category.checked) {
        selectedCategories.push(category.categoryName);
      } else {
        category.types.forEach((type) => {
          if (type.checked) {
            selectedCategoriesTypes.push(type.semType);
          }
        });
      }
    });
    return {
      semCategories: selectedCategories,
      semTypes: selectedCategoriesTypes,
    };
  }
);

export const getCategoriesLoading = createSelector(
  categoriesLoading,
  categoriesLoadingResult => categoriesLoadingResult
);

export const getTaxonomiesForFilter = createSelector(
  taxonomies,
  taxonomiesResult => taxonomiesResult.toJS()
);

export const getSelectedTaxonomies = createSelector(
  getTaxonomiesForFilter,
  taxonomiesResult =>
    taxonomiesResult
      .filter(taxonomy => taxonomy.checked)
      .map(item => item.taxonomyName)
);

export const getTaxonomiesLoading = createSelector(
  taxonomiesLoading,
  taxonomiesLoadingResult => taxonomiesLoadingResult
);
