/* eslint-disable no-plusplus */
import { graphFilterEnum, graphFilterOptions } from './constants';
import { isEmptyObject, roundNumber } from '../../../Utils/Utils';

const graphFilterValues = Object.values(graphFilterEnum);

export function getUniqueDataValues(data) {
  const uniqueCells = graphFilterValues.reduce((acc, item) => {
    acc[item] = [];
    return acc;
  }, {});

  const measure = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const d = data[i];

    graphFilterValues.forEach((value) => {
      if (!uniqueCells[value].includes(d[value])) {
        uniqueCells[value].push(d[value]);
      }
    });

    if (d.umapX > measure.maxX) {
      measure.maxX = d.umapX;
    } else if (d.umapX < measure.minX) {
      measure.minX = d.umapX;
    }
    if (d.umapY > measure.maxY) {
      measure.maxY = d.umapY;
    } else if (d.umapY < measure.minY) {
      measure.minY = d.umapY;
    }

    if (i === data.length - 1) {
      measure.maxX = roundNumber(measure.maxX);
      measure.minX = roundNumber(measure.minX);
      measure.maxY = roundNumber(measure.maxY);
      measure.minY = roundNumber(measure.minY);
    }
  }

  return { uniqueCells, measure };
}

export function getFiltersOptions(data) {
  return graphFilterValues.reduce((acc, item) => {
    acc[item] = Object.keys(data[item])
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
      .map(el => ({
        label: el,
        value: el,
        name: el,
      }));
    return acc;
  }, {});
}

export function filterMetaData(data, filters) {
  return Object.keys(filters).reduce((filteredData, key) => {
    const values = filters[key].selectedOptions.map(option => option.value);
    if (values.length) {
      return filteredData.filter(d => values.includes(d[key]));
    }
    return filteredData;
  }, data);
}

export function addColorsToSingleCellData(data, colors, filterValue) {
  const coloredData = [];
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    coloredData.push({
      ...d,
      color: colors[d[filterValue]][0],
    });
  }

  return coloredData;
}

export function getFilteredColoredMetaData(data, colors, filters, filterValue) {
  if (!data || !data.length || isEmptyObject(colors)) return [];
  const filteredData = filterMetaData(data, filters);
  return addColorsToSingleCellData(filteredData, colors, filterValue);
}

export function filterViolinGeneData(data, zeroData, filters, uniqueCells, colors, dataset) {
  if (!data || !data.length || !uniqueCells || isEmptyObject(colors)) return [];
  const { splitByCondition } = dataset;
  const { value: filterValue } = graphFilterOptions[0];
  const filteredData = filterMetaData(data, filters);

  const splitByConditionData = splitByCondition.enabled && splitByCondition.mapping.reduce((acc, item) => {
    acc[item.condition] = item;
    return acc;
  }, {});

  const cells = Object.values(uniqueCells[filterValue]).reduce((acc, item) => {
    acc.base[item] = {
      points: [],
      concept: { name: item },
      colors: colors[item],
      zeros: zeroData[item],
    };
    Object.keys(splitByConditionData).forEach((c) => {
      const name = `${item}_${splitByConditionData[c].suffix}`;
      acc.splited[name] = {
        points: [],
        concept: { name },
        colors: splitByConditionData[c].colors,
        zeros: zeroData[item],
      };
    });
    return acc;
  }, { base: {}, splited: {} });

  for (let i = 0; i < filteredData.length; i++) {
    const d = filteredData[i];
    const key = d[filterValue];
    cells.base[key] = {
      ...cells.base[key],
      points: [...cells.base[key].points, d.measure],
    };
    if (splitByCondition.enabled) {
      const condition = `${d.cellType}_${splitByConditionData[d.condition].suffix}`;
      cells.splited[condition] = {
        ...cells.splited[condition],
        points: [...cells.splited[condition].points, d.measure],
      };
    }
  }

  const values = Object.keys(cells).reduce((acc, item) => {
    acc[item] = Object.values(cells[item]).sort((a, b) => a.concept.name.localeCompare(b.concept.name));
    return acc;
  }, {});

  return values;
}
