import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Components
import GenomicIdeogram from './GenomicIdeogram';
// Utils
import { insertHTML } from '../../Utils/Utils';
import { apiTypes } from '../../Sets/CreateSet/enums';
import { getDatabaseIdentifier } from './utils';
import { RELATIVE_PATH } from '../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  synonyms: PropTypes.instanceOf(Array),
  identifiers: PropTypes.instanceOf(Array),
  classifications: PropTypes.instanceOf(Array),
  relatedProteinDescription: PropTypes.instanceOf(Array),
  description: PropTypes.string,
  openCreateSetPopup: PropTypes.func,
  genomicLocation: PropTypes.instanceOf(Object),
  taxonomies: PropTypes.instanceOf(Array),
};

const DefinitionChapter = (props) => {
  const {
    synonyms = [],
    identifiers = [],
    taxonomies = [],
    classifications = [],
    relatedProteinDescription = [],
    description,
    openCreateSetPopup,
    genomicLocation,
  } = props;

  const [showInfoText, setShowInfoText] = useState(false);
  const [showMoreSynonyms, setShowMoreSynonyms] = useState(false);

  const getSynonymsData = useCallback(() => {
    const sorted = synonyms.sort((a, b) => a.key.localeCompare(b.key));
    if (synonyms.length > 10 && !showMoreSynonyms) {
      return sorted.slice(0, 10);
    }
    return sorted;
  }, [synonyms, showMoreSynonyms]);

  function renderLink(id, index, array, key) {
    const taxonomy = taxonomies[0]?.split(' ').join('_');
    const databaseIdentifier = getDatabaseIdentifier(key, taxonomy);
    return databaseIdentifier.link ?
      (
        <span key={`identifier-${index}`}>
          <a

            className="link"
            target="_blank"
            rel="noreferrer"
            href={`${databaseIdentifier.link}${id}`}
          >
            {id}
          </a>
          {array.length - 1 !== index && <span>, </span>}
        </span>
      ) : (
        <span key={`identifier-${index}`}>
          {array.length - 1 === index ? `${id}` : `${id}, `}
        </span>
      );
  }

  function renderIdentifire(d, i) {
    const { key, values } = d;
    const taxonomy = taxonomies[0]?.split(' ').join('_');
    const databaseIdentifier = getDatabaseIdentifier(key, taxonomy);
    if (!databaseIdentifier) {
      return null;
    }

    return (
      <span key={`identifier-${i}`}>
        {`${databaseIdentifier.name}: `}
        {values.map((id, index, array) => renderLink(id, index, array, key))}
      </span>
    );
  }

  function renderSynonym(d, i, array) {
    const { key, values } = d;
    const half = (array.length - 1) / 2;
    const className = classNames({
      'col-1': i <= half,
      'col-2': i > half,
    });
    return (
      <span key={`synonym-${i}`} className={className}>
        {`${key}: `}
        {`[${values.join(', ')}]`}
      </span>
    );
  }

  function renderClassification(d, i, array) {
    const { id, name } = d;
    return (
      <span
        key={`classification-${i}`}
        onClick={() => { openCreateSetPopup([id], apiTypes.classificationFullListApi, name); }}
        className="link"
      >
        {name}{i !== (array.length - 1) && ', '}
      </span>
    );
  }

  function renderRelatedProteinDescription(d, i) {
    const { name, description: descr, id } = d;
    return (
      <div key={`related-protein-description-${i}`}>
        <Link
          target="blank"
          to={`${RELATIVE_PATH}/concept-details/${id}`}
          className="concept-card-protein-classification link"
        >
          {name}
        </Link>
        <span>
          {`: ${descr}`}
        </span>
      </div>
    );
  }

  function renderRelatedProteinName(d, i) {
    const { name, synonyms: syn, id } = d;
    return (
      <div key={`related-protein-name-${i}`} >
        <Link
          target="blank"
          to={`${RELATIVE_PATH}/concept-details/${id}`}
          className="concept-card-protein-classification link"
        >
          {name}
        </Link>
        <span>
          {`: ${syn.sort().join('; ')}`}
        </span>
      </div>
    );
  }

  return (
    <div id="conceptDefinition" className="gene-details-definition">
      <div className="gene-details-section-title title2">
        Definition:
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          onClick={() => setShowInfoText(!showInfoText)}
        />
      </div>
      {
        showInfoText &&
        <div className="gene-details-info__main">
          <p>
            The Definition section provides a high level description of the gene including gene classifications and known synonyms.
            It contains the following:
          </p>
          <ul>
            <li>
              Synonyms, including database identifiers, that are known to reference to the gene.
              The databases that contain the synonym are shown between brackets.
            </li>
            <li>
              Classifications of the gene and gene products.
            </li>
            <li>
              A description of the gene from various sources (Entrezgene, UMLS and others).
            </li>
            <li>
              The gene product(s) coded by the gene including high level descriptions from various sources.
            </li>
          </ul>
        </div>
      }
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Description:</div>
        <div dangerouslySetInnerHTML={insertHTML(description)} />
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Classification:</div>
        <div>{classifications.map(renderClassification)}</div>
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Encoded proteins:</div>
        <div className="flex-column-auto">
          {relatedProteinDescription.map(renderRelatedProteinDescription)}
        </div>
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Protein synonyms:</div>
        <div className="flex-column-auto">
          {relatedProteinDescription.map(renderRelatedProteinName)}
        </div>
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Genomic location:</div>
        <div className="flex-column-auto">
          <GenomicIdeogram genomicLocation={genomicLocation} />
        </div>
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Synonyms:</div>
        <div className="gene-details-definition__synonyms">
          {getSynonymsData().map(renderSynonym)}
        </div>
        {
          synonyms.length > 10 &&
          <div className="concept-synonyms-link link" onClick={() => setShowMoreSynonyms(!showMoreSynonyms)}>
            {!showMoreSynonyms ? 'Show more...' : 'Show less'}
          </div>
        }
      </section>
      <section className="gene-details-definition__section">
        <div className="concept-sub-title title5">Database identifiers:</div>
        <div className="flex-column-auto">
          {identifiers.map(renderIdentifire)}
        </div>
      </section>
    </div>
  );
};

DefinitionChapter.propTypes = propTypes;

export default React.memo(DefinitionChapter);
