import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Loader from '../../common/Loader/Loader';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Zoom from '../../common/Zoom/Zoom';
import ZoomBtn from '../../common/Zoom/ZoomBtn';
import ProteinStructure3D from '../../graphics/ProteinStructure3D/ProteinStructure3D';
// Utils
import { exportToPNG, exportToSVG } from '../../Utils/Utils';
// Store
import { loadNextChapterAction } from '../actions';
import { getProteinStructureDataAction, updatePDBIdAction } from './redux/actions';
import * as SELECTORS from './redux/selectors';
// Styles
import './ProteinStructureChapter.scss';

const propTypes = {
  gene: PropTypes.string.isRequired,
  getProteinStructureData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  geneName: PropTypes.string,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  proteinStructure: PropTypes.instanceOf(Object),
  PDBEntries: PropTypes.instanceOf(Array),
  PDBId: PropTypes.instanceOf(Object),
  updatePDBId: PropTypes.func,
  PDBError: PropTypes.bool,
};

class ProteinStructureChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zoomIsOpen: false,
      showInfoText: false,
      showProtein3DInfo: false,
      showProteinStructure3D: false,
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      getProteinStructureData,
      itsNewConcept,
      loadNextChapter,
      proteinStructure,
    } = this.props;

    if (itsNewConcept || !proteinStructure) {
      getProteinStructureData({ id: gene });
    } else {
      loadNextChapter('similar-proteins');
    }
  };

  toggleInfoText = (stateParam, stateProp) => {
    this.setState({ [stateParam]: !stateProp });
  };

  insertHTML = structure => ({ __html: atob(structure) });

  toggleProteinStructure3D = (value) => {
    this.setState({ showProteinStructure3D: value });
  };

  exportToPNGCb = () => {
    const { geneName } = this.props;
    const diagram = window.document.querySelector('.protein-structure__picture svg');
    const name = `${geneName}_Protein_2D_structure.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, true);
    }
  };

  exportToSVGCb = () => {
    const { geneName } = this.props;
    const container = window.document.querySelector('.protein-structure__picture');
    const name = `${geneName}_Protein_2D_structure.svg`;
    if (container) {
      exportToSVG(container, name, false, true);
    }
  };

  openZoom = () => {
    this.setState({
      zoomIsOpen: true,
    });
  };

  closeZoom = () => {
    this.setState({
      zoomIsOpen: false,
    });
  };

  render() {
    const {
      error,
      loading,
      proteinStructure,
      PDBEntries,
      PDBError,
      PDBId,
      updatePDBId,
    } = this.props;

    const {
      zoomIsOpen,
      showInfoText,
      showProtein3DInfo,
      showProteinStructure3D,
    } = this.state;

    return (
      <div className="protein-structure">
        <div className="gene-details-section-title title2">
          <span>Protein Structure</span>
        </div>
        <div className="gene-details-section-body">
          <div className="protein-structure__block">
            <span className="gene-expression-block-title-main">Protein 2D structure</span>
            <button
              className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
              onClick={() => this.toggleInfoText('showInfoText', showInfoText)}
            />
            {
              showInfoText &&
              <div className="gene-details-info__main">
                <p>
                  Visualization of annotated sequence features and experimental proteomic data in the context of protein topology.
                </p>
                <p>
                  Image generated by Protter: interactive protein feature visualization and integration with experimental proteomic data.
                  <br />Omasits U, Ahrens CH, Müller S, Wollscheid B.
                  <br />Bioinformatics. 2014 Mar 15;30(6):884-6. doi: 10.1093/bioinformatics/btt607.
                </p>
              </div>
            }
            {
              !loading &&
              <>
                {
                  proteinStructure && proteinStructure.structure ?
                    <>
                      <span className="export-button-wrapper">
                        <div
                          className="export-button"
                          onClick={this.exportToPNGCb}
                          title="Export to png"
                        />
                          PNG
                      </span>
                      <span className="export-button-wrapper">
                        <div
                          className="export-button"
                          onClick={this.exportToSVGCb}
                          title="Export to svg"
                        />
                          SVG
                      </span>
                      <ZoomBtn
                        openZoom={this.openZoom}
                      >
                        <div
                          className="protein-structure__picture"
                          dangerouslySetInnerHTML={this.insertHTML(proteinStructure.structure)}
                        />
                      </ZoomBtn>
                    </> :
                    <div className="gene-details__no-data">
                      There is no data to display
                    </div>
                }
              </>
            }
            <Loader isLoading={loading} />
            {
              error &&
              <div className="row text-center error-text">
                Sorry, error occurred.
                <br />
                { error }
              </div>
            }
          </div>
          <div className="protein-structure__block">
            <span className="gene-expression-block-title-main">Protein 3D structure</span>
            <button
              className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
              onClick={() => this.toggleInfoText('showProtein3DInfo', showProtein3DInfo)}
            />
            {
              showProtein3DInfo &&
              <div className="gene-details-info__main gene-details-info__main_light">
                The Protein 3D structure section provides the 3D structure of Protein if available in The Protein Data Bank

                H.M. Berman, J. Westbrook, Z. Feng, G. Gilliland, T.N. Bhat, H. Weissig, I.N. Shindyalov, P.E. Bourne.
                Nucleic Acids Res. 2000 Jan 1;28(1):235-42. DOI: 10.1093/nar/28.1.235
              </div>
            }
            {
              !PDBError && PDBEntries && PDBId &&
              <button
                className="protein-structure__3d-link"
                onClick={() => this.toggleProteinStructure3D(true)}
              >
                Click here to review the 3d image of the protein
              </button>
            }
            {
              PDBError &&
              <div className="gene-details__no-data">
                There is no data to display
              </div>
            }
          </div>
        </div>
        {
          zoomIsOpen &&
          <ModalComponent
            modalClassName="zoom__popup"
            isOpen={zoomIsOpen}
            closeCb={this.toggleInfoText}
          >
            <Zoom
              zoomIsOpen={zoomIsOpen}
              closeZoom={this.closeZoom}
              zoomId="protein-structure-zoom"
            >
              <div dangerouslySetInnerHTML={this.insertHTML(proteinStructure.structure)} />
            </Zoom>
          </ModalComponent>
        }
        {
          showProteinStructure3D &&
          <ModalComponent
            modalClassName="protein-structure-3d__popup modal_no-paddings"
            isOpen={showProteinStructure3D}
            closeCb={() => this.toggleProteinStructure3D(false)}
          >
            <ProteinStructure3D
              protein={PDBId}
              options={PDBEntries}
              handleChange={updatePDBId}
              closeCb={() => this.toggleProteinStructure3D(false)}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

ProteinStructureChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    proteinStructure: SELECTORS.getProteinStructureSelector(state),
    loading: SELECTORS.getLoadingSelector(state),
    error: SELECTORS.getErrorSelector(state),
    PDBEntries: SELECTORS.getProteinPDBEntriesSelector(state),
    PDBId: SELECTORS.getProteinPDBIdSelector(state),
    PDBError: SELECTORS.getProteinPDBErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProteinStructureData(data) {
      dispatch(getProteinStructureDataAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
    updatePDBId(data) {
      dispatch(updatePDBIdAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProteinStructureChapter);
