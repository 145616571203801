export const getColumnName = (field, procents) => {
  const columns = {
    geneName: 'Gene name',
    classifications: 'Classification',
    involvementOtherDiseases: 'Involvement other diseases',
    differentialExpression: 'Differential expression',
    hallmarksOfCancer: 'Hallmarks of Cancer',
    expressionSpecificity: 'Expression specificity',
    geneticInteractions: 'Genetic interactions',
    associationScore: 'Indirect Association Score',
    geneVariation: 'Gene variation',
    literatureSearch: 'Literature Search',
    compoundScore: 'Associated Compounds (<100nM)',
    druggableStructure: 'Druggable structure',
    signalingDomain: 'Signaling domain',
    intramembrane: 'Intramembrane',
    transMembraneDomain: 'Transmembrane domain',
    secretion: 'Secretion',
  };

  return columns[field] || (field + (procents ? ', %' : ''));
};
