import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

// Utils
import { withRouter } from '../../../common/WithRouter/WithRouter';
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { exportToPNG, scrollIntoView } from '../../../Utils/Utils';
import { generateSetForAnalysis, initialSets } from '../../common/SetAnalysis/utils';
import { analysisMethodEnum } from '../../FindRelated/FindRelatedResult/enums';
// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import CircosDiagram from '../../../graphics/CircosDiagram/CircosDiagram';
import FindRelatedResult from '../../FindRelated/FindRelatedResult/FindRelatedResult';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import GeneLiteratureSection from './GeneLiteratureSection/GeneLiteratureSection';
import SmallMoleculesSection from './SmallMoleculesSection/SmallMoleculesSection';
// Store
import {
  pathDetailsRequested,
  resetPathDetailsAction,
  sort,
} from './actions';
import {
  getLoading,
  getError,
  getPathDetailsTable,
  getPathDetailsSorting,
  getChordsDetailsDiagram,
  getRegionsDetailsDiagram,
  getTargetGeneDetailsDiagram,
  getGeneLiteratureData,
  getLiteratureTableSorting,
  getSortedGeneLiteratureData,
} from './selectors';
// Styles
import './PathDetails.css';

const propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  fetchData: PropTypes.func,
  pathDetailsTable: PropTypes.instanceOf(Array),
  pathDetailsSorting: PropTypes.instanceOf(Object),
  chordsDetailsDiagram: PropTypes.instanceOf(Array),
  regionsDetailsDiagram: PropTypes.instanceOf(Array),
  targetGeneDetailsDiagram: PropTypes.instanceOf(Object),
  resetPathDetails: PropTypes.func,
  sortedGeneLiteratureData: PropTypes.instanceOf(Array),
  literatureTableSorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  locationParams: PropTypes.instanceOf(Object),
};

class PathDetails extends React.Component {
  constructor(props) {
    super(props);
    this.tableSettings = {
      width: 900,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      sortBy: props.pathDetailsSorting.sortBy,
      sortDirection: props.pathDetailsSorting.sortDirection,
    };
    this.tableColumns = [
      {
        label: 'Gene name',
        dataKey: 'gene',
        width: 300,
        exportCSV: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.gene ? this.genesCell(rowData.gene, rowIndex) : null
        ),
      },
      {
        label: 'Gene classifications',
        dataKey: 'classifications',
        width: 300,
        exportCSV: true,
        className: 'flexColumn',
        cellRenderer: ({ rowData }) => {
          const classifications = rowData.classifications || [];
          return classifications.map((classification, index) => (
            <div
              title={classification.name}
              className="text-center cell-row"
              key={index}
            >
              { classification.name }
            </div>
          ));
        },
      },
      {
        label: 'Expression level',
        dataKey: 'expressionValue',
        width: 150,
        exportCSV: true,
      },
      {
        label: 'Effect score',
        dataKey: 'score',
        width: 150,
        exportCSV: true,
      },
    ];
    this.simpleTableRef = React.createRef(null);
  }

  componentDidMount() {
    this.props.fetchData();
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  componentWillUnmount() {
    this.props.resetPathDetails();
  }

  genesCell = ({ id, name }, rowIndex) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  exportToPNGCb = () => {
    const diagram = window.document.querySelector('#pathDetailsDiagram svg');
    if (diagram) {
      exportToPNG(diagram);
    } else {
      console.log('element to export not found');
    }
  };

  getRowHeight = ({ index }) => {
    let length;
    try {
      length = this.props.pathDetailsTable[index].classifications.length; // eslint-disable-line
    } catch (e) {
      console.log(e);
    }
    if (length > 2) {
      return (length * 20) + 10;
    }
    return 50;
  };

  analysePath = () => {
    const { locationParams: { projectId } } = this.props;
    const setAnalysisSets = initialSets;
    const set = {
      items: this.props.pathDetailsTable.map(item => ({ id: item.gene.id, name: item.gene.name })),
      name: 'path set',
    };

    const setForAnalysis = setAnalysisSets[0];
    const generatedData = generateSetForAnalysis([set]);

    Object.assign(setForAnalysis, generatedData);

    localStorage.setItem('setAnalysisSets', JSON.stringify(setAnalysisSets));
    window.open(`${RELATIVE_PATH}/analytics/${projectId}/new?runAfter=PATHWAY`, '_blank');
  };

  render() {
    const {
      loading,
      error,
      pathDetailsTable,
      chordsDetailsDiagram,
      regionsDetailsDiagram,
      targetGeneDetailsDiagram,
      sortedGeneLiteratureData,
      literatureTableSorting,
    } = this.props;

    return (
      <div className="path-details">
        {
          !loading && !error &&
          <div className="flex-grid align-self-center path-details-container">
            <div className="col-1 text-center path-details-left-column path-details-columns">
              <div className="path-details-columns-sidebar">
                <div className="path-details-left-column-title">
                  Actions
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#path-details-definition'); }}
                  className="path-details-left-column-action"
                >
                  Definition
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#pathway-details'); }}
                  className="path-details-left-column-action"
                >
                  Pathway details
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#gene-literature'); }}
                  className="path-details-left-column-action"
                >
                  Gene Literature
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#path-details-small-molecules'); }}
                  className="path-details-left-column-action"
                >
                  Small molecules
                </div>
              </div>
            </div>
            <div className="col-6 path-details-columns path-details-right-column">
              <h3 className="title3 text-center">Path Details</h3>
              <div id="path-details-definition" className="path-details-section">
                <div className="path-details-section-header">
                  Definition
                </div>
                <div className="path-details-separator" />
                <div className="path-details-diagram-wrapper">
                  <div className="path-details-diagram">
                    <CircosDiagram
                      id="pathDetailsDiagram"
                      targetGene={targetGeneDetailsDiagram}
                      geneColor={targetGeneDetailsDiagram.color}
                      geneName={targetGeneDetailsDiagram.name}
                      chartName="Path details"
                      data={regionsDetailsDiagram}
                      chords={chordsDetailsDiagram}
                      mode="pathDetails"
                    />
                  </div>
                </div>
                <div className="path-details-table-view">
                  <div className="path-details-table-view-header">
                    <button
                      onClick={this.analysePath}
                      type="button"
                      className="btn btn-success"
                    >
                      <ReactTooltip
                        id="showPathOnAnalyticsPage"
                        place="bottom"
                        type="light"
                      >
                        Analyse path on analytics page
                      </ReactTooltip>
                      <span
                        data-tip={true}
                        data-for="showPathOnAnalyticsPage"
                      >
                        Analyse path
                      </span>
                    </button>
                  </div>
                  <SimpleTable
                    innerRef={this.simpleTableRef}
                    settings={this.tableSettings}
                    data={pathDetailsTable}
                    manageable={false}
                    sortAction={sort}
                    columns={this.tableColumns}
                  />
                </div>
              </div>
              <div id="pathway-details" className="path-details-section">
                <FindRelatedResult
                  title="Pathway analysis"
                  isPathDetailsChild={true}
                  tableWidth={this.tableSettings.width}
                  analysisMethod={analysisMethodEnum.PATHWAY}
                  genesDetailAPIKey="pathwayTargetCandidates"
                />
              </div>
              <div id="gene-literature" className="path-details-section">
                <GeneLiteratureSection
                  data={sortedGeneLiteratureData}
                  sorting={literatureTableSorting}
                />
              </div>
              <div id="path-details-small-molecules" className="path-details-section">
                <SmallMoleculesSection />
              </div>
            </div>
          </div>
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} error={error} />
      </div>
    );
  }
}

PathDetails.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
    error: getError(state),
    pathDetailsTable: getPathDetailsTable(state),
    pathDetailsSorting: getPathDetailsSorting(state),
    chordsDetailsDiagram: getChordsDetailsDiagram(state),
    regionsDetailsDiagram: getRegionsDetailsDiagram(state),
    targetGeneDetailsDiagram: getTargetGeneDetailsDiagram(state),
    geneLiteratureData: getGeneLiteratureData(state),
    sortedGeneLiteratureData: getSortedGeneLiteratureData(state),
    literatureTableSorting: getLiteratureTableSorting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPathDetails() {
      dispatch(resetPathDetailsAction());
    },
    fetchData() {
      dispatch(pathDetailsRequested());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PathDetails));
