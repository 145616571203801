import cloneDeep from 'lodash.clonedeep';
import { call, put, all } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { setVennSetsAction } from '../../../SetsDataSelection/reducer';
import { throwVennSetsErrorAction, toggleVennSetsLoaderAction } from '../../reducer';
import { getUniqConcepts } from '../../../../Utils/Utils';
import { generateSetForAnalysis, initialSets } from '../../../../Analytics/common/SetAnalysis/utils';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';

export function* getAllSetsWorker(action) {
  try {
    yield put(toggleVennSetsLoaderAction(true));

    const { ids, projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const getRequestData = id => (isProjectTab ? { projectId, setId: id } : id);
    const data = yield all(ids.map(id => call(apiToCall, getRequestData(id))));
    const setsData = data.map(el => el.data);

    let sets = cloneDeep(initialSets);
    setsData.forEach((set, idx) => {
      const generatedData = generateSetForAnalysis([set]);
      Object.assign(sets[idx], generatedData);
    });

    sets = sets.filter(set => set.active);

    const selectedIds = [];
    sets.forEach(set => selectedIds.push(...set.data));
    const { checkedSets, dataSelection } = getUniqConcepts(sets, selectedIds);
    yield put(setVennSetsAction({
      sets,
      selectedIds,
      checkedSets,
      dataSelection,
    }));
    yield put(toggleVennSetsLoaderAction(false));
  } catch (e) {
    yield put(throwVennSetsErrorAction(e.message));
    yield put(toggleVennSetsLoaderAction(false));
  }
}

