import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { insertHTML } from '../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../constantsCommon';

const propTypes = {
  titleText: PropTypes.string,
  semanticCategories: PropTypes.instanceOf(Object),
  highlights: PropTypes.instanceOf(Object),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
};

const PublicationDetailsTitle = (props) => {
  const {
    titleText,
    semanticCategories,
    highlights,
    showTooltip,
    hideTooltip,
    setShortConceptCardId,
  } = props;

  const uniqueKey = 'publication-title-tooltip';

  function getTemplateString(key, className = '', id, category) {
    const link = category === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    return `<a target="_blank" href="${link}" class="pub-details-highlight pub-details-link ${className}" data-id="${id}">${key}</a>`;
  }

  function getMarkedText() {
    if (!highlights) return titleText;
    const highlightsValues = Object.values(highlights);

    if (!titleText || highlightsValues.length === 0) {
      return titleText;
    }

    return highlightsValues
      .flat()
      .sort((a, b) => a.start - b.start)
      .reduce((textArr, h, i, arr) => {
        const key = titleText.substring(h.start, h.end);
        const category = semanticCategories.find(c => c.category === h.semanticCategory);

        if (i === 0) {
          textArr.push(titleText.substring(0, h.start));
        } else {
          textArr.push(titleText.substring(arr[i - 1].end, h.start));
        }
        textArr.push(getTemplateString(key, category.class, h.id, category.category));
        if (i === arr.length - 1) {
          textArr.push(titleText.substring(h.end));
        }

        return textArr;
      }, [])
      .join('');
  }

  function showTooltipListener({ clientX, clientY, target }) {
    const { dataset: { id } } = target;
    setShortConceptCardId(id);
    showTooltip({ uniqueKey, clientX, clientY });
  }

  function hideTooltipListener() {
    hideTooltip();
  }

  useLayoutEffect(() => {
    const htmlHighlights = document.querySelectorAll('.pub-details__title');

    if (htmlHighlights.length > 0) {
      htmlHighlights.forEach((h) => {
        h.addEventListener('mouseenter', showTooltipListener);
        h.addEventListener('mouseleave', hideTooltipListener);
      });
    }
  }, [highlights]);

  const text = getMarkedText();

  return (
    <div
      className="pub-details__title"
      dangerouslySetInnerHTML={insertHTML(text)}
    />
  );
};

PublicationDetailsTitle.propTypes = propTypes;

export default PublicationDetailsTitle;
