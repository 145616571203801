import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';
import { graphTypes } from '../../GeneDetails/GeneExpressionChapter/constants';

export function drawGroupedViolinChart({
  htmlRootId,
  data,
  tooltipMode = 'mode',
  type,
  showTitle,
  chartTitle,
  violinScale,
  firstName,
  secondName,
  customVerticalLineX,
  yAxisText,
  handleCellClick,
  selectedCells,
  showTooltipHandler,
  hideTooltip,
}) {
  d3js.selectAll(`#${htmlRootId} > *`).remove();
  const yAxisMaxLength = d3js.max(data, item => d3js.max(item.values, d => d.summary.max)).toFixed(0).length * 10;
  const xAxisMaxLength = d3js.max(data, item => item.concept.length) * 8;

  const config = {
    fixedScale: null,
    width: data.length * (customVerticalLineX || 40) * 2,
    height: 1000,
    padding: {
      right: xAxisMaxLength,
    },
    margin: {
      top: 50,
      right: 65,
      bottom: xAxisMaxLength,
      left: yAxisMaxLength,
    },
  };

  const bodyHtml = document.querySelector('body');
  const chartHeight = config.height - config.margin.top - config.margin.bottom;
  const colors = ['rgb(126, 70, 174)', 'rgb(174, 150, 70)', 'rgb(237, 210, 121)', 'rgb(115, 92, 173)'];
  const outlineColors = ['rgb(250, 178, 147)', 'rgb(154,150,162)'];
  const verticalLineX = customVerticalLineX || 40;
  
  const x0 = d3js.scaleBand()
    .range([0, config.width])
    .domain([...data.map(d => d.concept)])
    .padding(0.03);

  const y = d3js.scaleLinear()
    .range([chartHeight, 0])
    .domain([0, d3js.max(data, item => d3js.max(item.values, d => d.summary.max))]);

  const svg = d3js.select(`#${htmlRootId}`).append('svg')
    .attr('width', config.width + config.padding.right)
    .attr('height', config.height)
    .append('g')
    .attr('transform', `translate(${config.margin.left}, ${config.margin.top})`);

  if (showTitle) {
    d3js.select(`#${htmlRootId}`)
      .select('svg')
      .insert('text', ':first-child')
      .text(`${chartTitle} for ${firstName} and ${secondName}`)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 30px);text-anchor: middle;');

    // Legend
    const SVG = d3js.select(`#${htmlRootId} svg`)
      .append('g')
      .attr('transform', () => `translate(0, ${config.height - 200})`);
    const keys = [firstName, secondName];
    drawLegend({
      element: SVG,
      keys,
      colors,
    });
  }

  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', `chart-tooltip chart-tooltip--popup chart-tooltip_${tooltipMode}`);

  function getTooltipTemplate(d, i) {
    let template = '';
    template += `
      <div class="chart-tooltip__content">
        <div><b>Target ${i + 1}:</b> ${d.name}</div>
        <div><b>Group:</b> ${d.concept}</div>
        <div><b>Min:</b> ${d.summary.min.toFixed(2)}</div>
        <div><b>First quartile:</b> ${d.summary.q1.toFixed(2)}</div>
        <div><b>Median:</b> ${d.summary.median.toFixed(2)}</div>
        <div><b>Third quartile: </b> ${d.summary.q3.toFixed(2)}</div>
        <div><b>Max: </b> ${d.summary.max.toFixed(2)}</div> 
    `;
    if (type === graphTypes.CPTAC) {
      template += `
        <div><b>Number of samples with expression: </b> ${d.points.length}</div>
        <div><b>Total number of samples: </b> ${d.samplesCount}</div>
      </div>`;
    } else {
      template += `<div><b>Number of samples: </b> ${d.points.length}</div></div>`;
    }
    return template;
  }

  function getTextTooltipTemplate(d) {
    return (`<b style="text-transform: capitalize">${d}</b>`);
  }

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d, i) {
    tooltip
      .html(getTooltipTemplate(d, i))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseMoveText(d) {
    tooltip
      .html(getTextTooltipTemplate(d))
      .style('left', `${d3js.mouse(bodyHtml)[0] + 30}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1] + 30}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
    d3js.select(this)
      .attr('stroke', 'none');
  }

  const histogram = d3js.histogram()
    .domain(y.domain())
    .thresholds(y.ticks(30))
    .value(d => d);

  const sumstat = d3js
    .nest()
    .key(d => d.concept)
    .rollup(d => d[0].values.map(el => histogram(el.points)))
    .entries(data);

  function calcOutliers(values) {
    return values.map((d) => {
      const obj = d;
      const cExtremes = [];
      const cOutliers = [];
      const { summary: { q1, q3 } } = obj;
      let { points } = obj;
      points = points.sort(d3js.ascending);
      const iqr = q3 - q1;
      const lowerOuterFence = q1 - (3 * iqr);
      const upperOuterFence = q3 + (3 * iqr);

      const lowerInnerFence = q1 - (1.5 * iqr);
      const upperInnerFence = q3 + (1.5 * iqr);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= points.length; i++) {
        const point = points[i];

        if (point < lowerInnerFence) {
          if (point < lowerOuterFence) cExtremes.push(point);
          else cOutliers.push(point);
        } else if (point > upperInnerFence) {
          if (point > upperOuterFence) cExtremes.push(point);
          else cOutliers.push(point);
        }
      }

      obj.outliers = cOutliers;
      obj.extremes = cExtremes;
      return obj;
    });
  }

  const getDataWithSumstate = ({ values = [], concept = {} }) => {
    const dataWithConcept = values.map((rawItem, index) => {
      const item = rawItem;
      item.concept = concept;
      item.sumstat = sumstat.find(el => el.key === concept).value[index];
      return item;
    });
    return calcOutliers(dataWithConcept);
  };

  let maxNum = 0;

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const i in sumstat) {
    const allBins = sumstat[i].value[0];
    const lengths = allBins.map(a => a.length);
    const longuest = d3js.max(lengths);
    if (longuest > maxNum) { maxNum = longuest; }
  }
  const xNum = d3js.scaleLinear()
    .range([0, x0.bandwidth() / 2])
    .domain([-maxNum * 2, maxNum * 2]);

  function createViolinPlot(plotData, i) {
    d3js.select(this)
      .append('path')
      .datum(plotData.sumstat)
      .style('cursor', 'pointer')
      .style('stroke', 'none')
      .style('fill', colors[i])
      .attr('d', d3js.area()
        .x0((d) => i === 0 ? xNum(-d.length) : xNum(-d.length) + verticalLineX)
        .x1((d) => i === 0 ? xNum(d.length) : xNum(d.length) + verticalLineX)
        .y(d => y(d.x0))
        .curve(d3js.curveCatmullRom));
  }

  function createBoxPlot(d, i) {
    function calcBoxHeight(value) {
      if (!value.summary.q1 || !value.summary.q3) return;
      const boxHeight = y(value.summary.q1) - y(value.summary.q3);
      return Math.max(boxHeight, 1);
    }

    d3js.select(this)
      .append('rect')
      .attr('class', 'range')
      .attr('width', 4)
      .attr('x', i === 0 ? xNum(0) - 2 : (xNum(0) - 2) + verticalLineX)
      .attr('y', y(d.summary.q3))
      .attr('height', calcBoxHeight)
      .style('fill', outlineColors[0])
      .style('stroke', outlineColors[1])
      .style('stroke-width', '1px')
      .style('cursor', 'pointer');

    // median line
    d3js.select(this)
      .append('line')
      .attr('x1', i === 0 ? xNum(0) - 2 : (xNum(0) - 2) + verticalLineX)
      .attr('x2', i === 0 ? xNum(0) + 2 : xNum(0) + 2 + verticalLineX)
      .attr('y1', y(d.summary.median))
      .attr('y2', y(d.summary.median))
      .style('stroke', outlineColors[1])
      .style('stroke-width', '2.5px')
      .style('pointer-events', 'none')
      .style('z-index', '0');
  }

  function createOutliers(d, i) {
    const { outliers = [], extremes = [] } = d;
    const scatter = () => {
      const range = 10;
      return Math.floor(Math.random() * range) - (range / 2);
    };

    outliers.forEach((el) => {
      d3js.select(this)
        .append('circle')
        .attr('class', 'point')
        .attr('r', 2)
        .attr('cx', i === 0 ? xNum(0) + scatter() : xNum(0) + scatter() + verticalLineX)
        .attr('cy', y(el))
        .style('fill', '#fff')
        .style('stroke-width', '1px')
        .style('stroke', i === 0 ? outlineColors[0] : outlineColors[1]);
    });

    extremes.forEach((el) => {
      d3js.select(this)
        .append('circle')
        .attr('class', 'point')
        .attr('r', 2)
        .attr('cx', i === 0 ? xNum(0) + scatter() : xNum(0) + scatter() + verticalLineX)
        .attr('cy', y(el))
        .style('fill', i === 0 ? outlineColors[1] : outlineColors[0])
        .style('stroke-width', '1px')
        .style('stroke', i === 0 ? outlineColors[0] : outlineColors[1]);
    });
  }

  const xAxis = svg.append('g')
    .attr('transform', `translate(${config.margin.right}, ${chartHeight})`)
    .attr('class', 'x-axis')
    .call(d3js.axisBottom(x0));

  xAxis.selectAll('text')
    .attr('x', 15)
    .attr('y', 4)
    .attr('transform', 'rotate(45)')
    .style('text-anchor', 'start')
    .style('font-size', '16px')
    .style('font-weight', (d) => {
      if (!showTitle && selectedCells) {
        return selectedCells.find(c => d === c.concept) && 700;
      }
      return 400;
    })
    .style('cursor', 'pointer')
    .on('click', d => handleCellClick(d));

  if(showTooltipHandler) {
    xAxis.selectAll('text')
      .on('mouseover', (d) => {
        showTooltipHandler(
          data.find(el => el.concept === d),
          d3js.event.x,
          d3js.event.y,
        );
      })
      .on('mouseout', hideTooltip && hideTooltip);
  } else {
    xAxis.selectAll('text')
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMoveText)
      .on('mouseleave', mouseLeave);
  }


  xAxis.selectAll('line')
    .attr('y2', 10);

  const yAxis = svg.append('g')
    .attr('transform', `translate(${config.margin.right},0)`)
    .call(d3js.axisLeft(y).tickSize(-config.width))
    .call(g => g.selectAll('.tick line')
      .attr('stroke-opacity', 0.5)
      .attr('stroke-dasharray', '2,2'))
    .attr('class', 'y-axis')
    .attr('transform', `translate(${config.margin.right},0)`);

  yAxis.selectAll('text')
    .attr('x', -9)
    .style('font-size', '16px');

  const textForYAxis = yAxisText ? yAxisText : 'TPM';
  yAxis.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -config.height / 3)
    .attr('dy', `-${yAxisMaxLength + 30}px`)
    .style('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('fill', 'black')
    .text(violinScale === 'linear' ? textForYAxis : `log2(${textForYAxis} + 1)`);

  const group = svg.selectAll('.boxplot-group')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'boxplot-group')
    .attr('transform', d => `translate(${x0(d.concept)},0)`);

  group.selectAll('g.boxplot')
    .data(d => getDataWithSumstate(d))
    .enter()
    .append('g')
    .attr('class', 'boxplot')
    .attr('transform', () => `translate(${config.margin.right},0)`)
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave)
    .each(createViolinPlot)
    .each(createBoxPlot)
    .each(createOutliers);
}
