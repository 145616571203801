import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// Components
import RadioInput from '../../common/Inputs/RadioInput/RadioInput';
import LegendComponent from '../LegendComponent/LegendComponent';
import Spinner from '../../common/Spinner/Spinner';
import Button from '../../common/Buttons/Button/Button';
// Icons
import { AiOutlineUndo } from 'react-icons/ai';
// Utils
import {
  convertTableToCSVText,
  exportToFile,
  exportToPNG,
  exportToSVG,
} from '../../Utils/Utils';
import { proposingName, scaleTransformData } from '../../GeneDetails/GeneExpressionChapter/utils';
import { drawBoxPlot } from './drawBoxPlot';
// Constants
import {
  RED_COLOR,
  DARK_RED_COLOR,
  GREEN_COLOR,
  DARK_GREEN_COLOR,
  windowHeight,
} from '../../../constantsCommon';
import {
  graphTypes,
} from '../../GeneDetails/GeneExpressionChapter/constants';
// Styles
import './BoxPlotChart.scss';

const propTypes = {
  id: PropTypes.string,
  tissueData: PropTypes.instanceOf(Array),
  cancerData: PropTypes.instanceOf(Array),
  height: PropTypes.number,
  width: PropTypes.number,
  clickCallback: PropTypes.func,
  axisClickCallback: PropTypes.func,
  switchOpenModal: PropTypes.func,
  classMode: PropTypes.string,
  isTumorAndTissues: PropTypes.bool,
  geneName: PropTypes.string,
  expressionName: PropTypes.string,
  tissueCellLineName: PropTypes.string,
  chartTitle: PropTypes.string,
  transform: PropTypes.string,
  isHiddenExport: PropTypes.bool,
  showTitle: PropTypes.bool,
  customMargin: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  csvName: PropTypes.string,
  downloadCsv: PropTypes.bool,
  isCcleGraph: PropTypes.bool,
  yAxisText: PropTypes.string,
  sampleMeasureKey: PropTypes.string,
  sampleName: PropTypes.string,
  sampleGroupName: PropTypes.string,
  isNeededTransform: PropTypes.bool,
  changeBoxPlotTransform: PropTypes.func,
  sortByDefault: PropTypes.bool,
  boxPlotTransform: PropTypes.string,
  legendData: PropTypes.instanceOf(Array),
  customColoring: PropTypes.bool,
  customWrapClass: PropTypes.string,
  calculateCustomMargin: PropTypes.func,
  showLegend: PropTypes.bool,
  csvColumns: PropTypes.instanceOf(Array),
  dataForCsvDownloading: PropTypes.instanceOf(Array),
  selectedCells: PropTypes.instanceOf(Array),
  isCsvDownloadingAllowed: PropTypes.bool,
  setSelectedCells: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setTissuesSelectionMode: PropTypes.func,
};

const BoxPlotChart = (props) => {
  const {
    id,
    cancerData,
    isTumorAndTissues = false,
    height,
    width,
    clickCallback,
    axisClickCallback,
    classMode,
    switchOpenModal,
    expressionName,
    transform,
    showTitle,
    showLegend,
    type,
    chartTitle,
    yAxisText,
    sortByDefault,
    boxPlotTransform = 'linear',
    csvName,
    isNeededTransform,
    changeBoxPlotTransform,
    isHiddenExport,
    downloadCsv,
    legendData,
    tissueData,
    csvColumns,
    dataForCsvDownloading,
    sampleMeasureKey,
    sampleName,
    sampleGroupName,
    customColoring,
    customWrapClass,
    calculateCustomMargin,
    selectedCells,
    isCsvDownloadingAllowed,
    setSelectedCells,
    unsetTissuesSelectionMode,
    tissuesSelectionMode,
    setTissuesSelectionMode,
  } = props;

  const BOX_AREA_WIDTH = 30;
  const downloadLinkRef = useRef(null);

  const handleAxisClick = useCallback((d) => {
    if (axisClickCallback) {
      axisClickCallback(d);
    }

    const concept = { name: d, samplesData: tissueData.filter(el => el.cellType === d) };
    if (setSelectedCells && tissuesSelectionMode) {
      setSelectedCells(concept);
    }
  }, [tissueData, setSelectedCells, tissuesSelectionMode]);

  useEffect(() => {
    initBoxplot();
  }, []);

  useEffect(() => {
    deleteTooltips();
    initBoxplot();
  }, [tissueData, boxPlotTransform]);

  useEffect(() => deleteTooltips, []);

  const initBoxplot = (isChartDownloading) => {
    const mappedTissuesDataWithColor = tissueData ? tissueData.map(item => {
      return (
        Object.assign({}, item, {
          color: {
            boxColor: customColoring ? item.color : GREEN_COLOR,
            lineColor: customColoring ? item.color : DARK_GREEN_COLOR,
          },
        })
      );
    }) : [];

    const mappedCancerDataWithColor = cancerData ? cancerData.map(item => (
      Object.assign({}, item, {
        color: {
          boxColor: customColoring ? item.color : RED_COLOR,
          lineColor: customColoring ? item.color : DARK_RED_COLOR,
        },
      })
    )) : [];

    const data = mappedTissuesDataWithColor.concat(mappedCancerDataWithColor);

    const boxplotHeight = height || (windowHeight * 90) / 100;
    const boxplotWidth = width || data.length * BOX_AREA_WIDTH;
    let sortedData = data.slice().sort((a, b) => {
      const nameA = a[sampleMeasureKey];
      const nameB = b[sampleMeasureKey];
      let comparison = 0;
      if (nameA > nameB) {
        comparison = -1;
      } else if (nameA < nameB) {
        comparison = 1;
      }
      return comparison;
    });

    if (isTumorAndTissues) {
      const tumor = [];
      const tissues = [];
      sortedData.forEach((item) => {
        if (item.color.boxColor === RED_COLOR) {
          tumor.push(item);
        } else if (item.color.boxColor === GREEN_COLOR) {
          tissues.push(item);
        }
      });
      sortedData = tissues.concat(tumor);
    }

    if (type === graphTypes.CCLE_PROTEOMICS) {
      sortedData = sortedData.reduce((acc, line) => {
        if (!acc.find(i => line.name === i.name)) {
          acc.push(line);
        } else {
          acc.push({ ...line, name: proposingName(sortedData, line.name) });
        }
        return acc;
      }, []);
    }

    const userConfig = {
      fixedScale: null,
      width: boxplotWidth,
      height: boxplotHeight,
      boxWidth: 20,
      margin: calculateCustomMargin && calculateCustomMargin(isChartDownloading),
    };

    drawBoxPlot({
      htmlRoot: `#${id}`,
      data: sortByDefault ? sortedData : data,
      isTumorAndTissues,
      userConfig,
      clickCallback,
      classMode,
      sampleMeasureKey,
      sampleName,
      sampleGroupName,
      switchOpenModal,
      isChartDownloading,
      showTitle,
      expressionName,
      chartTitle,
      transform,
      type,
      yAxisText,
      boxPlotTransform,
      legendData,
      showLegend,
      selectedCells,
      handleAxisClick,
    });
  };

  const deleteTooltips = () => {
    const tooltip = document.querySelectorAll(`.chart-tooltip-boxplot_${classMode}`);
    if (tooltip.length) tooltip.forEach(item => item.remove());
  };

  const exportToPNGCb = () => {
    initBoxplot(true);
    const diagram = window.document.querySelector(`#${id} svg`);
    const name = `${expressionName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false);
    }
    initBoxplot();
  };

  const exportToSVGCb = () => {
    initBoxplot(true);
    const container = window.document.querySelector(`#${id}`);
    const name = `${expressionName}.svg`;
    if (container) {
      exportToSVG(container, name, false, true);
    }
    initBoxplot();
  };

  const exportToCSVCb = (data) => {
    const csvData = convertTableToCSVText(data, csvColumns);
    exportToFile(downloadLinkRef.current, csvData, csvName);
  };

  const handleExportAllToCSV = useCallback(() => {
    exportToCSVCb(dataForCsvDownloading || tissueData);
  }, [dataForCsvDownloading, tissueData, boxPlotTransform]);

  const handleExportToCSV = useCallback(() => {
    if (selectedCells.length) {
      exportToCSVCb(
        scaleTransformData(boxPlotTransform, selectedCells.map( c => c.samplesData).flat(), sampleMeasureKey)
      );
    }
  }, [selectedCells, boxPlotTransform]);

  const handleChangeBoxPlotTransform = (e) => {
    changeBoxPlotTransform(e);
  };

  return (
    <div className={customWrapClass}>
      <div className="boxplot-controls">
        {
          isNeededTransform &&
            <div className="boxplot-controls__radio">
              <span className="boxplot-controls__text">Scale:</span>
              <RadioInput
                value="linear"
                checked={boxPlotTransform === 'linear'}
                onChange={handleChangeBoxPlotTransform}
                labelValue="Linear"
              />
              <RadioInput
                value="log"
                checked={boxPlotTransform === 'log'}
                onChange={handleChangeBoxPlotTransform}
                labelValue="Log"
              />
            </div>
        }
        {
          !isHiddenExport &&
            <div className="bar-chart-controls">
              {
                downloadCsv &&
                <>
                  <div className="bar-chart-export-button-wrapper">
                    <div
                      className="export-button bar-chart-controls__csv"
                      onClick={handleExportAllToCSV}
                      title="Export to csv"
                    />
                    CSV
                  </div>
                  <a //eslint-disable-line
                    hidden={true}
                    ref={downloadLinkRef}
                  />
                </>
              }
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button bar-chart-controls__png"
                  onClick={exportToPNGCb}
                  title="Export to png"
                />
                PNG
              </div>
              <div className="bar-chart-export-button-wrapper">
                <div
                  className="export-button"
                  onClick={exportToSVGCb}
                  title="Export to svg"
                />
                SVG
              </div>
            </div>
        }
      </div>
      {
        isCsvDownloadingAllowed &&
        <div className="csv-downloading-block">
          <div className="csv-btn-group btn-group">
            {
              <>
                <Spinner
                  containerClassName="csv-btn-group__spiner"
                />
                <label htmlFor="export">To CSV:</label>
                {
                  <Button
                    text="All"
                    onClick={handleExportAllToCSV}
                    customClassName="btn btn-group__first-btn"
                  />
                }
                {
                  !tissuesSelectionMode &&
                  <Button
                    text="Allow tissues selection"
                    onClick={setTissuesSelectionMode}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  tissuesSelectionMode &&
                  <Button
                    text="Export selected"
                    onClick={handleExportToCSV}
                    disabled={!selectedCells.length}
                    customClassName="btn btn-group__second-btn"
                  />
                }
                {
                  <Button
                    onClick={unsetTissuesSelectionMode}
                    customClassName="btn btn-group__last-btn"
                  >
                    <AiOutlineUndo size={24} />
                  </Button>
                }
                <a //eslint-disable-line
                  hidden={true}
                  ref={downloadLinkRef}
                />
              </>
            }
          </div>
          {
            tissuesSelectionMode &&
            <div className="violin-chart__csv-text">
              Select tissue names on x-axis to export data as CSV
            </div>
          }
        </div>
      }
      <div className="boxplot-chart-container">
        <div id={id} className="boxplot-chart" />
        {
          legendData &&
          <LegendComponent
            legendsParams={legendData}
            legendsColors={legendData.map(i => i.color)}
            keyToName="name"
          />
        }
      </div>
    </div>
  );
};

BoxPlotChart.propTypes = propTypes;

export default BoxPlotChart;
