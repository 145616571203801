import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { intersectionSelectedAdd } from '../../graphics/VennDiagram/actions';

export const setVennSetsAction = createAction('@@setsDataSelection/SET_VENN_SETS_ACTION');
export const sortVennSetsDataAction = createAction('@@setsDataSelection/SORT_VENN_SETS_DATA_ACTION');
export const checkAllAction = createAction('@@setsDataSelection/CHECK_ALL_VENN_SETS_DATA_ACTION');
export const checkItemAction = createAction('@@setsDataSelection/CHECK_ITEM_VENN_SETS_DATA_ACTION');
export const checkSetAction = createAction('@@setsDataSelection/CHECK_SET_VENN_SETS_DATA_ACTION');
export const cacheSelectedConcepts = createAction('@@setsDataSelection/CACHE_SELECTED_CONCEPTS');
export const initStorage = createAction('@@setsDataSelection/INIT_STORAGE');
export const reset = createAction('@@setsDataSelection/RESET');

export const initialState = fromJS({
  sets: [],
  checkedSets: {},
  sorting: { sortBy: 'selected', sortDirection: 'DESC' },
  selectedIds: [],
  dataSelection: [],
  intersectionData: [],
});

const setsDataSelectionReducer = handleActions(
  {
    [setVennSetsAction]: (state, { payload }) =>
      state
        .update('sets', () => payload.sets)
        .update('selectedIds', () => fromJS(payload.selectedIds))
        .update('dataSelection', () => fromJS(payload.dataSelection))
        .update('checkedSets', () => fromJS(payload.checkedSets)),
    [checkAllAction]: (state, { payload }) =>
      state
        .update('dataSelection', dataSelection => (
          dataSelection.map(concept => (
            concept.merge(fromJS({ selected: payload }))
          ))
        ))
        .update('checkedSets', checkedSets => fromJS(checkedSets).map(() => payload))
        .update('selectedIds', () => {
          if (payload) {
            return state.get('dataSelection').map(concept => concept.get('id'));
          }
          return initialState.get('selectedIds');
        })
        .update('intersectionData', () => (
          initialState.get('intersectionData')
        )),
    [checkItemAction]: (state, { payload }) =>
      state
        .update('checkedSets', checkedSets => (
          fromJS(checkedSets).map(() => false)
        ))
        .update('dataSelection', dataSelection => (
          dataSelection.map((concept) => {
            if (concept.get('id') === payload.id) {
              return concept.merge(fromJS({ selected: payload.checked }));
            }
            return concept;
          })
        ))
        .update('selectedIds', (selectedIds) => {
          if (payload.checked) {
            return selectedIds.push(payload.id);
          }
          return selectedIds.delete(selectedIds.findIndex(id => id === payload.id));
        })
        .update('intersectionData', () => (
          initialState.get('intersectionData')
        )),
    [checkSetAction]: (state, { payload }) =>
      state
        .update('checkedSets', checkedSets =>
          fromJS(checkedSets).update(payload.setName, () => payload.checked)
        )
        .update('dataSelection', dataSelection => (
          dataSelection.map((concept) => {
            if (concept.get(payload.setName)) {
              return concept.merge(fromJS({ selected: payload.checked }));
            }
            return concept;
          })
        )),
    [sortVennSetsDataAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [intersectionSelectedAdd]: (state, { payload }) =>
      state
        .update('intersectionData', () => fromJS(payload))
        .update('checkedSets', checkedSets => checkedSets.map(() => (false)))
        .update('dataSelection', dataSelection => (
          dataSelection.map((concept) => { // Check intersected concepts
            const id = payload.includes(concept.get('id'));
            const isSelected = concept.get('selected');
            if (id && !isSelected) {
              return concept.merge(fromJS({ selected: true }));
            }
            if (id && isSelected) {
              return concept.merge(fromJS({ selected: false }));
            }
            return concept;
          })
        ))
        .update('selectedIds', () => (
          state.get('dataSelection')
            .map(concept => concept.get('id'))
        )),
    [cacheSelectedConcepts]: state =>
      state
        .update('selectedIds', () => (
          state.get('dataSelection')
            .filter(concept => concept.get('selected'))
            .map(concept => concept.get('id'))
        )),
    [initStorage]: (state, { payload }) =>
      state
        .update('sets', () => fromJS(payload.activeSets))
        .update('dataSelection', () => fromJS(payload.dataSelection))
        .update('checkedSets', () => fromJS(payload.checkedSets)),
    [reset]: () => initialState,
  },
  initialState
);

export default setsDataSelectionReducer;
