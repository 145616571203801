import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ConceptCard from './Components/ConceptCard/ConceptCard';
import Loader from '../../common/Loader/Loader';
import Error from '../../common/Error/Error';
// Store
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const ConceptCards = (props) => {
  const {
    data,
    loading,
    error,
  } = props;

  if (loading) {
    return (
      <div className="concept-card-mock">
        <div className="concept-card-mock__block">
          Searching Concept cards...
        </div>
        <Loader isLoading={loading} />
      </div>
    );
  }

  if (!loading && error) {
    return (
      <Error
        show={!loading && !!error}
        error={error}
      />
    );
  }

  return (
    data.map(d => (
      <ConceptCard
        key={d.id}
        {...d}
      />
    ))
  );
};

ConceptCards.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getConceptCardsDataSelector(state),
    loading: SELECTORS.getConceptCardsLoadingSelector(state),
    error: SELECTORS.getConceptCardsErrorSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(ConceptCards);
