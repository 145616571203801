import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const setHideRelationTypesDataAction = createAction('@@hideRelationTypes/SET_DATA');
export const resetHideRelationTypesDataAction = createAction('@@hideRelationTypes/RESET');
export const updateHideRelationTypesItemAction = createAction('@@hideRelationTypes/UPDATE_ITEM');

const initialState = fromJS({
  data: null,
});

const reducer = handleActions(
  {
    [setHideRelationTypesDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload)),
    [updateHideRelationTypesItemAction]: (state, { payload }) =>
      state
        .updateIn(['data', `${payload.id}`], () => fromJS(payload)),
    [resetHideRelationTypesDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
