import {
  SORT,
} from '../../../../common/SimpleTable/constants';

import {
  PATH_DETAILS_SMALL_MOLECULES_REQUESTED,
  PATH_DETAILS_SMALL_MOLECULES_TABLE,
} from './constants';

export const pathDetailsSmallMoleculesRequested = () => (
  {
    type: PATH_DETAILS_SMALL_MOLECULES_REQUESTED,
  }
);

export const sort = data => (
  {
    type: PATH_DETAILS_SMALL_MOLECULES_TABLE + SORT,
    data,
  }
);
