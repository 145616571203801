import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import ExportTable from '../../../common/ExportTable/ExportTable';
import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
// Utils
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Store
import { switchShouldUpdateDataFlagAction } from '../../common/SetAnalysisMethodSelection/actions';
import { getShouldUpdateDataFlag } from '../../common/SetAnalysisMethodSelection/selectors';
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import * as SELECTORS from './selectors';
import * as ACTIONS from './reducer';

const propTypes = {
  removeConcept: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Array),
  content: PropTypes.instanceOf(Array),
  findRelatedAnalysis: PropTypes.func,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  createHeaderRendererWithSaveAs: PropTypes.func,
  apiKey: PropTypes.string,
  title: PropTypes.string,
  CSVTitle: PropTypes.string,
  selectedData: PropTypes.instanceOf(Array),
  resetFindRelatedTable: PropTypes.func,
  shouldUpdateDataFlag: PropTypes.bool,
  switchShouldUpdateDataFlag: PropTypes.func,
  passedConcepts: PropTypes.instanceOf(Array),
};

class FindRelatedProteinOrRegulationResult extends React.Component {
  state = {
    showPublicationsPopup: false,
  };
  actions = {
    checkAll: ACTIONS.findRelatedProteinOrRegulationTableCheckAllAction,
    checkItem: ACTIONS.findRelatedProteinOrRegulationTableCheckItemAction,
    invertSelection: ACTIONS.findRelatedProteinOrRegulationTableInvertSelectionAction,
    removeSelected: ACTIONS.findRelatedProteinOrRegulationTableRemoveSelectedAction,
  };
  conceptsNames = [];
  conceptsIds = [];
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    const { apiKey } = this.props;
    this.fetchFindRelatedData(apiKey);
  }

  componentDidUpdate(prevProps) {
    const { apiKey, shouldUpdateDataFlag } = this.props;
    const { apiKey: prevApiKey } = prevProps;

    if (apiKey !== prevApiKey || shouldUpdateDataFlag) {
      this.fetchFindRelatedData(apiKey);
    }

    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  componentWillUnmount() {
    const {
      resetFindRelatedTable,
    } = this.props;
    resetFindRelatedTable();
  }

  fetchFindRelatedData(apiKey, parameters) {
    const {
      findRelatedAnalysis,
      selectedData,
      switchShouldUpdateDataFlag,
      passedConcepts,
    } = this.props;
    const params = parameters || { size: 20, page: 0 };
    if (passedConcepts) {
      findRelatedAnalysis({
        apiKey,
        requestData: {
          concepts: passedConcepts.map(concept => concept.id),
          params,
        },
      });
    } else {
      findRelatedAnalysis({
        apiKey,
        requestData: {
          concepts: selectedData.map(concept => concept.id),
          params,
        },
      });
      switchShouldUpdateDataFlag(false);
    }
  }

  removeConcept = (id) => {
    const { removeConcept } = this.props;
    removeConcept(id);
  };

  showHideAllConcepts = () => {
    const {
      totalPages,
      totalItems,
      apiKey,
    } = this.props;
    const params = {
      size: totalPages === 1 ? 20 : totalItems,
      page: 0,
    };

    this.fetchFindRelatedData(apiKey, params);
  };

  handlePageClick = (pageNumber) => {
    const { apiKey } = this.props;
    const params = {
      size: 20,
      page: pageNumber.selected,
    };

    this.fetchFindRelatedData(apiKey, params);
  };

  handlePageSort = () => {
    const {
      apiKey,
      pageNumber,
    } = this.props;
    const params = {
      size: 20,
      page: pageNumber,
    };

    this.fetchFindRelatedData(apiKey, params);
  };

  getRowHeight = ({ index }) => {
    const { content } = this.props;
    const { interactingProteins, classifications } = content[index];
    const proteinsLength = interactingProteins ? interactingProteins.length : 0;
    const classificationsLength = classifications ? classifications.length : 0;
    const length = Math.max(proteinsLength, classificationsLength);
    if (length <= 2) {
      return 50;
    } if (length > 2 && length <= 11) {
      return (length * 18) + 10;
    }
    return (length * 17) + 10;
  };

  openPublicationsPopup = () => {
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  genesCell = ({ id, name }, rowIndex, columnIndex) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}-${columnIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        key={uniqueKey}
        name={name}
      />
    );
  };

  getColumnPercentWidth = (percent) => {
    const width = 1300;
    return (percent * (width - 100)) / 100;
  };

  render() {
    const {
      title,
      content,
      loading,
      error,
      CSVTitle,
      totalPages,
      pageNumber,
      selectedItems,
      sorting: { sortBy, sortDirection },
      createHeaderRendererWithSaveAs,
    } = this.props;

    const { showPublicationsPopup } = this.state;

    const tableSettings = {
      width: 1300,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
      className: 'result-table',
    };

    const columns = [
      {
        label: 'Genes',
        dataKey: 'geneName',
        width: this.getColumnPercentWidth(28),
        exportCSV: true,
        ...(!!createHeaderRendererWithSaveAs && { headerRenderer: createHeaderRendererWithSaveAs(content) }),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData ? this.genesCell({ id: rowData.geneGi, name: rowData.geneName }, rowIndex) : null
        ),
      },
      {
        label: 'Regulated Genes',
        dataKey: 'interactingProteins',
        dataKeySub: 'geneName',
        width: this.getColumnPercentWidth(28),
        exportCSV: true,
        className: 'flexColumn',
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.interactingProteins ? rowData.interactingProteins.map((protein, cellIndex) => (
            this.genesCell({ id: protein.geneGi, name: protein.geneName }, rowIndex, cellIndex)
          )) : null
        ),
      },
      {
        label: 'Classification',
        dataKey: 'classifications',
        width: this.getColumnPercentWidth(30),
        exportCSV: true,
        className: 'flexColumn',
        cellRenderer: ({ rowData }) => {
          const classifications = rowData.classifications || [];
          return classifications.map((classification, index) => (
            <div
              title={classification}
              className="text-center cell-row"
              key={index}
            >
              { classification }
            </div>
          ));
        },
      },
      {
        label: 'Publications',
        dataKey: 'publicationCoOccurrence',
        width: this.getColumnPercentWidth(15),
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey }) => (
          <span
            role="presentation"
            className={rowData.publicationCoOccurrence === 0 ? '' : 'link'}
            onClick={() => {
              if (rowData[dataKey] > 0) {
                this.conceptsNames = [
                  rowData.geneName,
                  ...rowData.regulatedGenes.map(gene => gene.geneName),
                ];
                this.conceptsIds = [
                  rowData.geneGi,
                  ...rowData.regulatedGenes.map(gene => gene.geneGi),
                ];
                this.openPublicationsPopup();
              }
            }}
          >
            {rowData.publicationCoOccurrence}
          </span>
        ),
      },
      {
        dataKey: 'id',
        disableSort: true,
        width: 50,
        cellRenderer: ({ rowData }) => (
          <div className="text-center">
            <button
              type="button"
              onClick={() => { this.removeConcept(rowData.id); }}
              className="btn-icon btn-icon-danger"
              title="Remove"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="setAnalysisPathwayResult">
        <div className="analysis-title mt-20 title2">{title}</div>
        <div className="path-details-separator" />
        {
          !loading &&
          <div className="table-wrap">
            <div className="controls-block table-wrap__controls">
              {
                totalPages > 1 &&
                <div className="table-wrap__pagination">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={pageNumber}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              }
              <div className="table-wrap__to-csv">
                <ExportTable
                  content={content}
                  columns={columns}
                  showExportAll={false}
                  showExportPage={true}
                  showExportSelected={true}
                  selectedItems={selectedItems}
                  fileName={CSVTitle}
                />
                <button
                  disabled={!totalPages}
                  className="button button-primary ml-15"
                  onClick={() => this.showHideAllConcepts()}
                >
                  { totalPages === 1 ? <span>Show pages</span> : <span>Show all</span> }
                </button>
              </div>
            </div>
            <SimpleTable
              innerRef={this.simpleTableRef}
              data={content}
              autoSize={true}
              columns={columns}
              settings={tableSettings}
              dynamicHeight={true}
              showOnHeatMap={true}
              manageable={true}
              actions={this.actions}
              sortAction={ACTIONS.findRelatedProteinOrRegulationTableSortAction}
              serverSortAction={this.handlePageSort}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} error={error} />
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

FindRelatedProteinOrRegulationResult.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    content: SELECTORS.getContentSelector(state),
    pageNumber: SELECTORS.getPageNumber(state),
    totalPages: SELECTORS.getTotalPages(state),
    totalItems: SELECTORS.getTotalItems(state),
    selectedItems: SELECTORS.getSelectedItems(state),
    sorting: SELECTORS.getTableSorting(state),
    loading: SELECTORS.getLoadingKey(state),
    error: SELECTORS.getError(state),
    selectedData: selectedDataForAnalysis(state),
    shouldUpdateDataFlag: getShouldUpdateDataFlag(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeConcept(id) {
      dispatch(ACTIONS.findRelatedProteinOrRegulationTableRemoveConceptAction(id));
    },
    findRelatedAnalysis(data) {
      dispatch(ACTIONS.findRelatedProteinOrRegulationRequestedAction(data));
    },
    resetFindRelatedTable() {
      dispatch(ACTIONS.findRelatedProteinOrRegulationTableResetAction());
    },
    switchShouldUpdateDataFlag(data) {
      dispatch(switchShouldUpdateDataFlagAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindRelatedProteinOrRegulationResult);
