import React from 'react';
import PropTypes from 'prop-types';

// Components
import Accordion from '../Accordion/Accordion';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import SematicMain from './SematicMain';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  geneTaxonomies: PropTypes.instanceOf(Array),
  checkedTaxonomy: PropTypes.string,
  toggleTaxonomy: PropTypes.func,
};

const SemanticWithTaxonomies = (props) => {
  const {
    id,
    name,
    count,
    checked,
    disabled,
    handleOnChange,
    checkedTaxonomy = '',
    geneTaxonomies,
    toggleTaxonomy,
  } = props;

  return (
    <Accordion
      titleElement={
        <SematicMain
          id={id}
          handleOnChange={handleOnChange}
          checked={checked}
          disabled={disabled}
          name={name}
          count={count}
          withTaxonomies={true}
        />
      }
      customClassName="semantic-type__accordion"
    >
      {
        geneTaxonomies.map(t => (
          <div key={t.name} className="semantic-type__taxonomie">
            <Checkbox
              onChange={() => toggleTaxonomy(t.name)}
              checked={checkedTaxonomy === t.name || t.checked}
            />
            <span>
              {`${t.name}${t.count ? ` (${t.count})` : ''}`}
            </span>
          </div>
        ))
      }
    </Accordion>
  );
};

SemanticWithTaxonomies.propTypes = propTypes;

export default React.memo(SemanticWithTaxonomies);
