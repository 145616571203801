import {
  CHECK_ITEM,
  CHECK_ALL,
} from '../../common/SimpleTable/constants';

import {
  GENE_DISEASE_ANNOTATION_INIT_REQUESTED,
  GENE_DISEASE_ANNOTATION_TABLE_,
} from './constants';

export const initAnnotationAction = data => (
  {
    type: GENE_DISEASE_ANNOTATION_INIT_REQUESTED,
    data,
  }
);

export const checkAll = data => (
  {
    type: GENE_DISEASE_ANNOTATION_TABLE_ + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: GENE_DISEASE_ANNOTATION_TABLE_ + CHECK_ITEM,
    data,
  }
);
