import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Creatable from 'react-select/creatable';

// Store
import { fetchTagsRequestAction } from './store/reducer';
import { getAvailableTags, getAddTagDataLoadingSelector } from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  fetchTags: PropTypes.func,
  availableTags: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  updateTags: PropTypes.func,
  noReset: PropTypes.bool,
  tagList: PropTypes.instanceOf(Array),
};

const AddTag = (props) => {
  const {
    noReset = false,
    tagList,
    fetchTags,
    updateTags,
    isLoading,
    availableTags,
    placeholder = 'Select or create tag',
  } = props;

  useEffect(() => {
    fetchTags();
    return () => {
      if (!noReset) {
        updateTags([]);
      }
    };
  }, []);

  const addTag = useCallback((option) => {
    tagList.push({
      id: tagList.length + 1,
      name: option.value,
    });
    updateTags(tagList);
  }, [tagList, updateTags]);

  return (
    <div className="add-tag">
      <Creatable
        className="add-tag__select"
        placeholder={placeholder}
        options={availableTags}
        isLoading={isLoading}
        isSearchable={true}
        classNamePrefix="dropdown"
        onChange={addTag}
      />
    </div>
  );
};

AddTag.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    availableTags: getAvailableTags(state),
    isLoading: getAddTagDataLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTags() {
      dispatch(fetchTagsRequestAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTag);
