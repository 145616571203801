import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';

const propTypes = {
  rankingTypes: PropTypes.instanceOf(Object),
  rankingClickHandler: PropTypes.func,
};

class TargetValidationStageOption extends React.Component {
  render() {
    const {
      rankingTypes,
      rankingClickHandler,
    } = this.props;

    return (
      <div className="flex-grid justify-content-center m-5">
        <div className="col-1 text-left">
          <Checkbox
            className="float-left"
            id="targetValidationStage"
            checked={rankingTypes.targetValidationStage}
            labelValue="Target Validation Stage"
            onChange={() => rankingClickHandler('targetValidationStage')}
          />
        </div>
      </div>
    );
  }
}

TargetValidationStageOption.propTypes = propTypes;

export default TargetValidationStageOption;
