import { createSelector } from 'reselect';

// Utils
import { sort } from '../../Utils/Utils';
import { getCurrentPageData, getTotalPages } from '../../common/Pagination/utils';

const setData = state => state.getIn(['findRelatedReducer', 'setData', 'set']);
const concepts = state => state.getIn(['findRelatedReducer', 'setData', 'set', 'items']);
const loading = state => state.getIn(['findRelatedReducer', 'setData', 'loading']);
const error = state => state.getIn(['findRelatedReducer', 'setData', 'error']);
const sorting = state => state.getIn(['findRelatedReducer', 'setData', 'sorting']);
const pages = state => state.getIn(['findRelatedReducer', 'setData', 'pages']);
const method = state => state.getIn(['findRelatedReducer', 'method']);
const category = state => state.getIn(['findRelatedReducer', 'category']);
const type = state => state.getIn(['findRelatedReducer', 'type']);
const openSetDialog = state => state.getIn(['findRelatedReducer', 'openSetDialog']);


export const getFindRelatedSetSelector = createSelector(
  setData,
  data => data && data.toJS()
);

export const getFindRelatedSetConceptsSelector = createSelector(
  concepts,
  data => data && data.toJS()
);

export const getFindRelatedSetFinalDataSelector = createSelector(
  concepts,
  pages,
  sorting,
  (data, pagesData, sortingData) => {
    if (!data) return data;
    const { pageNumber } = pagesData.toJS();

    const sorted = sort(data, sortingData.toJS()).toJS();

    const pagginatedConcepts = getCurrentPageData(sorted, pageNumber);

    return {
      pages: { pageNumber, totalPages: getTotalPages(sorted) },
      pagginatedConcepts,
    };
  }
);

export const getFindRelatedSetLoadingSelector = createSelector(
  loading,
  data => data
);

export const getFindRelatedSetErrorSelector = createSelector(
  error,
  data => data
);

export const getFindRelatedSetSortingSelector = createSelector(
  sorting,
  data => data && data.toJS()
);

export const getFindRelatedMethodSelector = createSelector(
  method,
  data => data
);

export const getFindRelatedCategorySelector = createSelector(
  category,
  data => data
);

export const getFindRelatedTypeSelector = createSelector(
  type,
  data => data
);

export const getOpenSetDialogSelector = createSelector(
  openSetDialog,
  data => data
);
