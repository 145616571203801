import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import classNames from 'classnames';
import { startOfDay, isAfter } from 'date-fns';

// Icons
import { MdDelete } from 'react-icons/md';
import { IoMdAdd, IoMdCopy } from 'react-icons/io';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import Button from '../../common/Buttons/Button/Button';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
import CopyAnalyticsModal from './Components/CopyAnalyticsModal/CopyAnalyticsModal';
import DeleteAnalyticsModal from './Components/DeleteAnalyticsModal/DeleteAnalyticsModal';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import {
  formatDate,
  getScreenWidth,
  proposingCopyName,
  AVERAGE_SYMBOL_LENGTH,
  checkIsReturnToThePageAfterAutoSave,
} from '../../Utils/Utils';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Store
import { resetLastSavedAnalyticsIdAction } from '../common/SetAnalysis/actions';
import { getAnalyticsLoading } from '../common/SetAnalysis/selectors';
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
import { showFreeAccountLimitDialogAction } from '../../common/FreeAccountLimitationDialog/store/reducer';
import { getLocationHistorySelector } from '../../../location/locationSelector';
import { isFreeUserSelector, isProjectManagerRightSelector } from '../../Header/selectors';
// Styles
import './SetAnalysisManagement.css';

const propTypes = {
  init: PropTypes.func,
  deleteAnalytics: PropTypes.func,
  sort: PropTypes.func,
  setTitleFilter: PropTypes.func,
  setTagFilter: PropTypes.func,
  setSetNameFilter: PropTypes.func,
  analytics: PropTypes.instanceOf(Array),
  showAllSetsName: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  resetLastSavedAnalyticsId: PropTypes.func,
  navigate: PropTypes.func,
  locationParams: PropTypes.instanceOf(Object),
  resetSetAnalysisManagement: PropTypes.func,
  analyticsIsSaving: PropTypes.bool,
  isFreeUser: PropTypes.bool,
  isProjectManagerUser: PropTypes.bool,
  showFreeAccountLimitDialog: PropTypes.func,
  checkedAnalyticsData: PropTypes.instanceOf(Array),
  projectId: PropTypes.string,
  isProjectLocked: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  isProjectLockedForUsers: PropTypes.bool,
  locationHistory: PropTypes.instanceOf(Object),
  duplicateAnalytics: PropTypes.func,
  newAnalyticsItemName: PropTypes.string,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  navigationType: PropTypes.string,
};

class SetAnalysisManagement extends React.Component {
  state = {
    deleteAnalyticsItems: {
      ids: [],
      show: false,
    },
    copyAnalyticsItems: {
      ids: [],
      show: false,
    },
  };
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    const {
      init,
      projectId,
      analyticsIsSaving,
    } = this.props;
    if (!analyticsIsSaving) {
      init(projectId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      init,
      projectId,
      analyticsIsSaving,
    } = this.props;
    if (
      (prevProps.analyticsIsSaving && !analyticsIsSaving) ||
      (prevProps.projectId !== projectId)) {
      init(projectId);
    }
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  componentWillUnmount() {
    const { resetSetAnalysisManagement } = this.props;
    resetSetAnalysisManagement();
  }

  sortCb = (sorting) => {
    this.props.sort(sorting);
  };

  filterByTitle = (str) => {
    this.props.setTitleFilter(str.trim());
  };

  filterByTag = (str) => {
    this.props.setTagFilter(str);
  };

  filterBySetName = (str) => {
    this.props.setSetNameFilter(str);
  };

  redirect = () => {
    const {
      navigate,
      projectId,
      analytics,
      isFreeUser,
      showFreeAccountLimitDialog,
    } = this.props;
    if (isFreeUser && analytics.length >= 1) {
      showFreeAccountLimitDialog();
    } else {
      navigate(`${RELATIVE_PATH}/analytics/${projectId}/new`);
    }
  };

  toggleShowAllConcepts = (data) => {
    this.props.showAllSetsName(data);
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  };

  getRowHeight = ({ index }) => {
    const { analytics } = this.props;
    const PADDING = 20;
    const cellWidth = (getScreenWidth() / 4 - PADDING) * 0.9; //eslint-disable-line
    if (analytics.length > 0 && analytics[index].showAll) {
      const setNamesStr = analytics[index].setsTitles.map(concept => (
        concept
      )).join(', ');
      const numberOfRows = Math.ceil(setNamesStr.length * AVERAGE_SYMBOL_LENGTH / cellWidth); //eslint-disable-line
      return numberOfRows * 20 + 30; //eslint-disable-line
    }
    return 50;
  };

  redirectProject = (id) => {
    const {
      navigate,
      projectId,
    } = this.props;

    navigate(`${RELATIVE_PATH}/analytics/${projectId}/${id}`);
  };

  openConfirmDeletePopup = rowData => (!rowData.id ?
    this.setState({ deleteAnalyticsItems: { ids: this.props.checkedAnalyticsData.map(item => item.id), show: true } }) :
    this.setState({ deleteAnalyticsItems: { ids: [rowData.id], show: true } }));

  closeConfirmDeletePopup = () => {
    this.setState({
      deleteAnalyticsItems: {
        ids: [],
        show: false,
      },
    });
  };

  removeAnalyticsManagementDataItems = () => {
    const { projectId, deleteAnalytics } = this.props;
    const { deleteAnalyticsItems: { ids } } = this.state;
    deleteAnalytics({ ids, projectId });
  };

  getCheckedAnalyticsIds = () => {
    const { analytics } = this.props;
    return analytics.reduce((arr, analytic) => {
      if (analytic.selected) {
        arr.push(analytic.id);
      }
      return arr;
    }, []);
  };

  actions = {
    checkAll: ACTIONS.checkAnalyticsAllItemsAction,
    checkItem: ACTIONS.checkAnalyticsItemAction,
  };

  handleProjectClick = (id) => {
    const {
      init,
    } = this.props;
    init(id);
  };

  handleCopyAnalyticsItems = () => {
    const { duplicateAnalytics, projectId } = this.props;
    const { copyAnalyticsItems: { ids, multiselect } } = this.state;
    duplicateAnalytics({ ids, projectId, multiselect });
    this.closeCopyAnalyticsItemsModal();
  };

  openCopyAnalyticsItemsModal = (rowData, multiselect, checkedIds) => {
    const { analytics, isFreeUser, showFreeAccountLimitDialog } = this.props;
    if (isFreeUser && analytics.length >= 1) {
      showFreeAccountLimitDialog();
    } else {
      let copyAnalyticsItemsConfig = {};
      if (multiselect) {
        copyAnalyticsItemsConfig = { ids: checkedIds, multiselect };
      } else {
        const name = proposingCopyName(analytics, rowData.title, 'copy');
        copyAnalyticsItemsConfig = { name, ids: [rowData.id] };
      }

      this.setState({
        copyAnalyticsItems: {
          show: true,
          ...copyAnalyticsItemsConfig,
        },
      });
    }
  };

  closeCopyAnalyticsItemsModal = () => {
    this.setState({
      copyAnalyticsItems: {
        ids: null,
        name: '',
        show: false,
      },
    });
  };

  projectsActionsAreDisabled = () => {
    const {
      isFreeUser,
      isProjectManagerUser,
      isProjectPublic,
      isProjectLocked,
      isProjectLockedForUsers,
    } = this.props;
    if (isProjectLocked || isProjectPublic || (isProjectLockedForUsers && (isFreeUser || !isProjectManagerUser))) {
      return true;
    }
    return false;
  };

  render() {
    const screenWidth = getScreenWidth();

    const {
      analytics,
      sorting: { sortBy, sortDirection },
      loading,
      error,
      sort,
      projectId,
      location,
      locationHistory,
      navigationType,
      setActiveProjectId,
      clearActiveProjectId,
      analyticsIsSaving,
      newAnalyticsItemName,
      resetLastSavedAnalyticsId,
    } = this.props;

    const {
      deleteAnalyticsItems,
      copyAnalyticsItems,
    } = this.state;

    const isLoading = loading || analyticsIsSaving;
    const checkedIds = analytics ? this.getCheckedAnalyticsIds() : [];

    const tableSettings = {
      width: 900,
      headerHeight: 65,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    const columns = [{
      label: 'Title',
      dataKey: 'title',
      width: screenWidth / 2,
      disableSort: true,
      cellRenderer: ({ rowData }) => (
        <div className="title-section">
          <div className="set-icon" />
          <div
            role="presentation"
            className="title-section-name link"
            title={rowData.title}
            onClick={() => { this.redirectProject(rowData.id); }}
          >
            {rowData.title}
          </div>
        </div>
      ),
      headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
        const ascClasses = classNames({
          'fa fa-sort-asc': true,
          active: sortBy === dataKey && sortDirection === 'ASC',
        });
        const descClasses = classNames({
          'fa fa-sort-desc': true,
          active: sortBy === dataKey && sortDirection === 'DESC',
        });
        return (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
            <span className="sorting-section">
              <i
                role="presentation"
                className={ascClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'ASC' }); }}
              />
              <i
                role="presentation"
                className={descClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'DESC' }); }}
              />
            </span>
            <input
              onChange={(e) => { this.filterByTitle(e.target.value); }}
              className="header-input input"
              type="input"
              placeholder="Search titles"
            />
          </div>
        );
      },
    }, {
      label: 'Tags',
      dataKey: 'tags',
      width: screenWidth / 2,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
          <input
            onChange={(e) => { this.filterByTag(e.target.value); }}
            className="header-input input"
            type="input"
            placeholder="Search tags"
          />
        </div>
      ),
      cellRenderer: ({ rowData }) => (
        <div
          className="tags-section"
          title={rowData.tags.join(', ')}
        >
          {rowData.tags.map((tag, index, array) => (
            <span key={index}>
              <span>{tag}</span>
              {index !== (array.length - 1) && <span>, </span>}
            </span>
          ))}
        </div>
      ),
    }, {
      label: 'Sets',
      dataKey: 'setsTitles',
      width: screenWidth / 2,
      disableSort: true,
      headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
        const sortValueMapper = item => (
          item.get(dataKey).size && item.get(dataKey).first() ?
            item.get(dataKey).first() : ''
        );
        const ascClasses = classNames({
          'fa fa-sort-asc': true,
          active: sortBy === dataKey && sortDirection === 'ASC',
        });
        const descClasses = classNames({
          'fa fa-sort-desc': true,
          active: sortBy === dataKey && sortDirection === 'DESC',
        });
        return (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
            <span className="sorting-section">
              <i
                role="presentation"
                className={ascClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'ASC', sortValueMapper }); }}
              />
              <i
                role="presentation"
                className={descClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'DESC', sortValueMapper }); }}
              />
            </span>
            <input
              onChange={(e) => { this.filterBySetName(e.target.value); }}
              className="header-input input"
              type="input"
              placeholder="Search set names"
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        const conceptListClasses = classNames({
          'concepts-list': true,
          'concept-list-wide': rowData.showAll,
        });
        return (
          <div className="concepts-wrapper">
            <div className={conceptListClasses}>
              {
                rowData.setsTitles.map((setTitle, index, array) => (
                  <span key={index}>
                    <span>{setTitle}</span>
                    {index !== (array.length - 1) && <span>, </span>}
                  </span>
                ))
              }
            </div>
            {
              rowData.setsTitles.length > 1 &&
              <div className="concepts-list-controls">
                {
                  !rowData.showAll &&
                  <i
                    role="presentation"
                    className="fa fa-angle-down"
                    onClick={() => { this.toggleShowAllConcepts({ id: rowData.id, key: !rowData.showAll }); }}
                  />
                }
                {
                  rowData.showAll &&
                  <i
                    role="presentation"
                    className="fa fa-angle-up"
                    onClick={() => { this.toggleShowAllConcepts({ id: rowData.id, key: !rowData.showAll }); }}
                  />
                }
              </div>
            }
          </div>
        );
      },
    }, {
      label: 'Last opened',
      dataKey: 'lastOpened',
      width: screenWidth / 4,
      disableSort: true,
      headerRenderer: ({ dataKey, label, sortBy, sortDirection }) => { //eslint-disable-line
        const ascClasses = classNames({
          'fa fa-sort-asc': true,
          active: sortBy === dataKey && sortDirection === 'ASC',
        });
        const descClasses = classNames({
          'fa fa-sort-desc': true,
          active: sortBy === dataKey && sortDirection === 'DESC',
        });
        return (
          <div className="header-section">
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>{label}</span>
            <span className="sorting-section">
              <i
                role="presentation"
                className={ascClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'ASC' }); }}
              />
              <i
                role="presentation"
                className={descClasses}
                onClick={() => { this.sortCb({ sortBy: dataKey, sortDirection: 'DESC' }); }}
              />
            </span>
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        const lastOpened = isAfter(startOfDay(new Date()), new Date(rowData.lastOpened)) ?
          formatDate(rowData.lastOpened) :
          formatDate(rowData.lastOpened, 'HH:mm');
        return (
          <span>{lastOpened}</span>
        );
      },
    }, {
      dataKey: 'id',
      disableSort: true,
      width: screenWidth / 2,
      cellRenderer: ({ rowData }) => {
        const getIconClassName = showForAllProjects => classNames({
          'management-controls__item': true,
          'visually-hidden': !showForAllProjects && this.projectsActionsAreDisabled(),
        });
        return (
          <div className="management-controls">
            <div
              className={getIconClassName(true)}
              title="Copy analytics"
              onClick={() => { this.openCopyAnalyticsItemsModal(rowData); }}
            >
              <IoMdCopy size={28} />
            </div>
            <div
              role="presentation"
              title="Delete analytics"
              onClick={() => { this.openConfirmDeletePopup(rowData); }}
              className={getIconClassName(false)}
            >
              <MdDelete size={28} />
            </div>
          </div>
        );
      },
    }];

    const redirectToTheProjectBool = navigationType === 'POP' || checkIsReturnToThePageAfterAutoSave(locationHistory, '/analytics');

    return (
      <div className="reports-management">
        {
          !isLoading && !error &&
          <div className="row reports-management-container m-0">
            <div className="management-container__buttons">
              <Button
                customClassName="management__create-button button-primary"
                disabled={this.projectsActionsAreDisabled()}
                onClick={
                  () => {
                    resetLastSavedAnalyticsId();
                    this.redirect();
                  }
                }
              >
                <IoMdAdd size={28} />Create
              </Button>
              <div className="management-buttons">
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={!checkedIds.length}
                  onClick={() => {
                    this.openCopyAnalyticsItemsModal(null, true, checkedIds);
                  }}
                >
                  <IoMdCopy size={26} />
                  Copy
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button"
                  disabled={!checkedIds.length || this.projectsActionsAreDisabled()}
                  onClick={() => {
                    this.openConfirmDeletePopup(checkedIds);
                  }}
                >
                  <MdDelete size={26} /> Delete
                </Button>
              </div>
            </div>
            <ProjectsTabs
              activeProjectId={projectId}
              setActiveProjectId={setActiveProjectId}
              clearActiveProjectId={clearActiveProjectId}
              redirectToTheProject={redirectToTheProjectBool}
              redirectedProjectId={location.state?.redirectedProjectId}
            />
            <Measure offset={true}>
              {
                ({ measureRef, contentRect }) => (
                  <div className="reports-info" ref={measureRef}>
                    {
                      !!contentRect.offset.width && !!contentRect.offset.height &&
                      <SimpleTable
                        innerRef={this.simpleTableRef}
                        settings={tableSettings}
                        width={contentRect.offset.width}
                        height={contentRect.offset.height}
                        fixedHeight={true}
                        onlySelect={true}
                        data={analytics}
                        actions={this.actions}
                        sortAction={sort}
                        columns={columns}
                        noRowsMsg="There is no data to display"
                      />
                    }
                  </div>
                )
              }
            </Measure>
          </div>
        }
        <Loader
          isLoading={isLoading && !error}
          withOverlay={true}
        />
        <DeleteAnalyticsModal
          isOpen={deleteAnalyticsItems.show}
          closeCb={this.closeConfirmDeletePopup}
          onConfirm={this.removeAnalyticsManagementDataItems}
          analyticsItemsToDelete={deleteAnalyticsItems.ids.length}
        />
        <CopyAnalyticsModal
          copyName={newAnalyticsItemName}
          analytics={analytics}
          isOpen={copyAnalyticsItems.show}
          activeProjectId={projectId}
          onConfirm={this.handleCopyAnalyticsItems}
          onCancel={this.closeCopyAnalyticsItemsModal}
          multiselect={copyAnalyticsItems.multiselect}
          initialValues={{ name: copyAnalyticsItems.name || '' }}
        />
        <FreeAccountLimitationDialog entity="project" />
        <Error
          show={!isLoading && !!error}
          title="Sorry, error occurred"
          error={error}
        />
      </div>
    );
  }
}

SetAnalysisManagement.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    analytics: SELECTORS.getAllAnalytics(state),
    checkedAnalyticsData: SELECTORS.getCheckedAnalyticsDataSelector(state),
    error: SELECTORS.getError(state),
    loading: SELECTORS.getLoading(state),
    sorting: SELECTORS.getSorting(state),
    isFreeUser: isFreeUserSelector(state),
    isProjectManagerUser: isProjectManagerRightSelector(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
    newAnalyticsItemName: SELECTORS.getFormValuesSelector(state, 'name'),
    isProjectLockedForUsers: SELECTORS.getIsProjectLockedForUsersSelector(state),
    isProjectLocked: SELECTORS.getIsProjectLockedSelector(state),
    isProjectPublic: SELECTORS.getIsProjectPublicSelector(state),
    locationHistory: getLocationHistorySelector(state),
    analyticsIsSaving: getAnalyticsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetLastSavedAnalyticsId() {
      dispatch(resetLastSavedAnalyticsIdAction());
    },
    init(data) {
      dispatch(ACTIONS.initAnalyticsAction(data));
    },
    sort(sorting) {
      dispatch(ACTIONS.sort(sorting));
    },
    setTitleFilter(str) {
      dispatch(ACTIONS.setTitleFilter(str));
    },
    setTagFilter(str) {
      dispatch(ACTIONS.setTagFilter(str));
    },
    setSetNameFilter(str) {
      dispatch(ACTIONS.setSetNameFilter(str));
    },
    showAllSetsName(data) {
      dispatch(ACTIONS.showAllSetsName(data));
    },
    deleteAnalytics(data) {
      dispatch(ACTIONS.deleteAnalyticsAction(data));
    },
    resetSetAnalysisManagement() {
      dispatch(ACTIONS.resetSetAnalysisManagementAction());
    },
    showFreeAccountLimitDialog() {
      dispatch(showFreeAccountLimitDialogAction());
    },
    duplicateAnalytics(data) {
      dispatch(ACTIONS.duplicateAnalyticsAction(data));
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setAnalyticsActiveProjectIdAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearAnalyticsActiveProjectIdAction());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetAnalysisManagement));

