export function generateSetForAnalysis(setsData) {
  const initialSet = {
    name: '',
    data: [],
    originalData: [],
    textareaValue: [],
    setType: setsData[0].setType,
    active: true,
  };

  return setsData.reduce((result, d, i) => {
    const set = {
      ...result,
      name: i === 0 ? d.name : result.name.concat(', ', d.name),
      originalData: result.originalData.concat(d.items),
    };

    d.items.forEach(({ id, name }) => {
      const itemId = `${id}`;
      if (!set.data.includes(itemId)) {
        set.data.push(itemId);
      }
      if (!set.textareaValue.includes(name)) {
        set.textareaValue.push(name);
      }
    });

    return set;
  }, initialSet);
}

export const initialSets = [
  {
    name: 'Set 1',
    color: '#8B572A',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
  {
    name: 'Set 2',
    color: '#5A9BD4',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
  {
    name: 'Set 3',
    color: '#F15A60',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
  {
    name: 'Set 4',
    color: '#CFCF1B',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
  {
    name: 'Set 5',
    color: '#FF7500',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
  {
    name: 'Set 6',
    color: '#006600',
    active: false,
    textareaValue: [],
    data: [],
    originalData: [],
  },
];

export function getTargetTissuesToSet(targetTissues = []) {
  const selectedSources = {};
  targetTissues.forEach((item) => {
    selectedSources[item.source] = true;
  });
  return selectedSources;
}

export function getSetsForAnalysis(rawData, gene) {
  const data = rawData;
  data.originalData.push({
    id: gene.id,
    name: gene.name,
  });
  data.originalData = data.originalData.reduce((acc, item) => {
    if (!acc.find(element => element && element.id === item.id)) {
      acc.push(item);
    }
    return acc;
  }, []);
  return {
    ...data,
    textareaValue: data.originalData.map(item => item.name),
    data: data.originalData.map(item => `${item.id}`),
  };
}
