import {
  ADD_ASSOCIATION_SCORE_TERMS,
  REMOVE_ASSOCIATION_SCORE_TERMS,
  SAVE_ASSOCIATION_SCORE_TERMS,
  CLEAR_ASSOCIATION_SCORE_TERMS,
} from './constants';

export const addAssociationScoreTerms = data => (
  {
    type: ADD_ASSOCIATION_SCORE_TERMS,
    data,
  }
);

export const removeAssociationScoreTerms = data => (
  {
    type: REMOVE_ASSOCIATION_SCORE_TERMS,
    data,
  }
);

export const saveAssociationScoreTerms = () => (
  {
    type: SAVE_ASSOCIATION_SCORE_TERMS,
  }
);

export const clearAssociationScoreTerms = () => (
  {
    type: CLEAR_ASSOCIATION_SCORE_TERMS,
  }
);
