import {
  startSubmit,
  stopSubmit,
} from 'redux-form/immutable';

import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { getUserName } from '../../Header/selectors';

import { changePasswordForm } from './selectors';
import {
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_FORM,
} from './constants';
import { changePasswordModalClose } from './actions';

function* saga() {
  try {
    yield put(startSubmit(CHANGE_PASSWORD_FORM));
    const form = yield select(changePasswordForm);
    const userName = yield select(getUserName);
    const sendData = {
      email: userName,
      username: userName,
      oldPassword: form.oldPassword,
      newPassword: form.newPassword,
    };
    const response = yield call(Api.changePassword, sendData);
    yield put({ type: CHANGE_PASSWORD_SUCCEEDED, data: { success: response, error: null } });
    yield put(stopSubmit(CHANGE_PASSWORD_FORM));
    yield put(changePasswordModalClose());
  } catch (e) {
    const error = e.response?.data?.message ?? e.message;
    yield put({ type: CHANGE_PASSWORD_FAILED, data: { success: false, error } });
    yield put(stopSubmit(CHANGE_PASSWORD_FORM));
  }
}

function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD_REQUESTED, saga);
}

export default changePassword;
