import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../../ModalComponent/ModalComponent';
// Styles
import './ColumnFilterModal.scss';
import Creatable from 'react-select/creatable';
import {columnType, filterValues} from './constants';
import {getColumnName} from '../RankSelectionResult/RankSelectionResultTableColumns';

const propTypes = {
  customClassName: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  columnName: PropTypes.string,
  onConfirm: PropTypes.func,
  closeCb: PropTypes.func,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  filterValue: PropTypes.string,
  operation: PropTypes.string,
  initialValue: PropTypes.string,
  initialOperation: PropTypes.string,
};

const ColumnFilterModal = (props) => {
  const {
    isOpen,
    closeCb,
    columnName,
    onConfirm,
    customClassName,
    initialValue,
    initialOperation,
  } = props;

  const [operation, setOperation] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  if (operation == null && initialOperation != null) {
    setOperation(initialOperation);
  }
  if (filterValue == null && initialValue != null) {
    setFilterValue(initialValue);
  }

  useEffect(() => {
    setOperation(initialOperation);
    setFilterValue(initialValue);
  }, []);

  const handleConfirm = () => {
    onConfirm(operation, filterValue);
    closeCb();
    setOperation(null);
    setFilterValue(null);
  };

  const setOperationValue = useCallback((option) => {
    setOperation(option.value);
  }, [operation]);

  const applyFilterValue = useCallback((e) => {
    setFilterValue(e.target.value);
  }, [filterValue]);

  if (!isOpen) {
    return null;
  }

  const availableOptions = filterValues.filter(v => v.types.includes(columnType[columnName]));

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      customClassName={customClassName}
    >
      <div className="confirm-filter-modal">
        <h2>Filter options</h2>
        <div className="confirm-filter-modal__content">
          <table className='confirm-filter-modal__table'>
            <tr>
              <td>Column:</td>
              <td>{getColumnName(columnName)}</td>
            </tr>
            <tr>
              <td>Operation:</td>
              <td>
                <Creatable
                  defaultValue={{ value: initialOperation, label: initialOperation }}
                  className="confirm-filter-modal__select"
                  options={availableOptions}
                  isSearchable={false}
                  classNamePrefix="dropdown"
                  onChange={setOperationValue}
                />
              </td>
            </tr>
            <tr>
              <td>Value:</td>
              <td>
                <input
                  value={filterValue}
                  onChange={applyFilterValue}
                  className="input"
                  type="text"
                />
              </td>
            </tr>
          </table>
        </div>
        <div className="confirm-filter-modal__controls">
          <button
            onClick={handleConfirm}
            disabled={!filterValue || !operation}
            className="button button-primary mr-15"
          >
            Apply
          </button>
          <button
            onClick={closeCb}
            className="button"
          >
            Cancel
          </button>
        </div>
        <br/>
      </div>
      <br/>
    </ModalComponent>
  );
};

ColumnFilterModal.propTypes = propTypes;

export default React.memo(ColumnFilterModal);
