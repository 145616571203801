import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { CONCEPTS_PAGE_SIZE } from './constants';

export const getPublicationDetailsDataAction = createAction('GET_PUBLICATION_DETAILS_DATA');
export const getPublicationDetailsByPubMedIdDataAction = createAction('GET_PUBLICATION_DETAILS_BY_PUBMED_ID_DATA');
export const getPublicationIdByPubMedIdDataAction = createAction('GET_PUBLICATION_ID_BY_PUBMED_ID_DATA');
export const setPublicationDetailsDataAction = createAction('SET_PUBLICATION_DETAILS_DATA');
export const setPublicationIdDataAction = createAction('SET_PUBLICATION_ID_DATA');
export const togglePublicationDetailsLoaderAction = createAction('TOGGLE_PUBLICATION_DETAILS_LOADER');
export const throwPublicationDetailsErrorAction = createAction('THROW_PUBLICATION_DETAILS_ERROR');
export const getPublicationCitationsAction = createAction('GET_PUBLICATION_CITATIONS');
export const setPublicationCitationsAction = createAction('SET_PUBLICATION_CITATIONS');
export const togglePublicationCitationsLoaderAction = createAction('TOGGLE_PUBLICATION_CITATIONS_LOADER');
export const throwPublicationCitationsErrorAction = createAction('THROW_PUBLICATION_CITATIONS_ERROR');
export const resetPublicationCitationsAction = createAction('RESET_PUBLICATION_CITATIONS');
export const togglePublicationDetailsChapterAction = createAction('TOGGLE_PUBLICATION_DETAILS_CHAPTER');
export const resetPublicationDetailsChaptersAction = createAction('RESET_PUBLICATION_DETAILS_CHAPTERS');
export const sortPublicationDetailsConceptsAction = createAction('SORT_PUBLICATIONS');
export const changeConceptsPageAction = createAction('CHANGE_CONCEPTS_PAGE');
export const updateSemCategoriesAction = createAction('UPDATE_PUB_SEM_CATEGORIES');
export const setSliderValueAction = createAction('SET_SLIDER_VALUE');
export const savePrevHighlightsAction = createAction('SAVE_PREV_HIGHLIGHTS');
export const toggleAllHighlightsAction = createAction('TOGGLE_ALL_HIGHLIGHTS');
export const toggleSearchTermsHighlightedAction = createAction('TOGGLE_SEARCH_TERMS_HIGHLIGHTED');

const initialState = fromJS({
  publication: {
    data: null,
    loading: false,
    error: null,
    page: 0,
    totalPages: 0,
    sorting: {
      sortBy: 'scoreCFIDF',
      sortDirection: 'DESC',
    },
    sliderValue: 0.1,
    semanticCategories: null,
    prevHighlights: {},
    highlights: {},
    allSelectedHighlights: false,
    searchTermsHighlighted: true,
  },
  citations: {
    data: null,
    loading: false,
    error: null,
  },
  chapters: {
    publicationInfo: true,
    publicationCitations: false,
    publicationTriples: false,
  },
});

const reducer = handleActions(
  {
    [getPublicationDetailsDataAction]: state =>
      state.updateIn(['publication', 'loading'], () => true),
    [setPublicationDetailsDataAction]: (state, { payload }) =>
      state
        .updateIn(['publication', 'data'], () => fromJS(payload.data))
        .updateIn(['publication', 'loading'], () => false)
        .updateIn(['publication', 'page'], () => 0)
        .updateIn(['publication', 'totalPages'], () => Math.ceil((payload.data.concepts || []).length / CONCEPTS_PAGE_SIZE))
        .updateIn(['publication', 'sliderValue'], () => payload.sliderValue)
        .updateIn(['publication', 'totalPages'], () => Math.ceil((payload.data.concepts || []).length / CONCEPTS_PAGE_SIZE))
        .updateIn(['publication', 'semanticCategories'], () => fromJS(payload.semanticCategories))
        .updateIn(['publication', 'highlights'], () => fromJS(payload.highlights))
        .updateIn(['publication', 'searchTermsHighlighted'], () => !!fromJS(payload.data.abstractInfoHighlighted))
        .updateIn(['publication', 'error'], () => null),
    [changeConceptsPageAction]: (state, { payload }) =>
      state.updateIn(['publication', 'page'], () => payload),
    [setPublicationIdDataAction]: (state, { payload }) =>
      state
        .updateIn(['publicationId'], () => fromJS(payload.id)),
    [throwPublicationDetailsErrorAction]: (state, { payload }) =>
      state
        .updateIn(['publication', 'loading'], () => false)
        .updateIn(['publication', 'error'], () => payload),
    [sortPublicationDetailsConceptsAction]: (state, { payload }) =>
      state.updateIn(['publication', 'sorting'], () => fromJS(payload)),
    [setSliderValueAction]: (state, { payload }) =>
      state.updateIn(['publication', 'sliderValue'], () => fromJS(payload)),
    [updateSemCategoriesAction]: (state, { payload }) =>
      state
        .updateIn(['publication', 'semanticCategories', payload.category], () => fromJS(payload)),
    [setSliderValueAction]: (state, { payload }) =>
      state.updateIn(['publication', 'sliderValue'], () => fromJS(payload)),
    [savePrevHighlightsAction]: (state, { payload }) =>
      state.updateIn(['publication', 'prevHighlights'], () => fromJS(payload)),
    [toggleAllHighlightsAction]: (state, { payload }) =>
      state
        .updateIn(['publication', 'allSelectedHighlights'], () => fromJS(payload))
        .updateIn(['publication', 'semanticCategories'], categories => categories.map(n => n.set('checked', payload))),
    [toggleSearchTermsHighlightedAction]: (state, { payload }) =>
      state
        .updateIn(['publication', 'searchTermsHighlighted'], () => fromJS(payload)),
    [getPublicationCitationsAction]: state =>
      state.updateIn(['citations', 'loading'], () => true),
    [setPublicationCitationsAction]: (state, { payload }) =>
      state
        .updateIn(['citations', 'data'], () => fromJS(payload))
        .updateIn(['citations', 'loading'], () => false)
        .updateIn(['citations', 'error'], () => null),
    [throwPublicationCitationsErrorAction]: (state, { payload }) =>
      state
        .updateIn(['citations', 'loading'], () => true)
        .updateIn(['citations', 'error'], () => payload),
    [resetPublicationCitationsAction]: state =>
      state.update('citations', () => initialState.get('citations')),
    [togglePublicationDetailsChapterAction]: (state, { payload }) =>
      state.updateIn(['chapters', payload.name], () => payload.show),
    [resetPublicationDetailsChaptersAction]: state =>
      state
        .update('chapters', () => initialState.get('chapters'))
        .update('publication', () => initialState.get('publication')),
  },
  initialState
);

export default reducer;
