import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';

import {
  GENE_DISEASE_INIT_REQUESTED,
  GENE_DISEASE_INIT_SUCCEEDED,
  GENE_DISEASE_INIT_FAILED,
  GENE_DISEASE_FETCH_TISSUES_REQUEST,
  GENE_DISEASE_FETCH_TISSUES_FAILURE,
  GENE_DISEASE_FETCH_TISSUES_SUCCESS,
} from './constants';
import {
  loadNextChapterAction,
  geneDiseaseTissuesLoaderAction,
  geneDiseaseSetChapterAction,
} from './actions';

function* saga(action) {
  try {
    const post = action.data;
    const response = yield call(Api.geneDiseaseIntro, { post });
    yield put({ type: GENE_DISEASE_INIT_SUCCEEDED, data: response.data });
    const {
      diseaseClassifications,
      diseaseSynonyms,
      geneClassifications,
      geneSynonyms,
    } = response.data;

    if ((diseaseClassifications && diseaseClassifications.length)
      || (diseaseSynonyms && diseaseSynonyms.length)
      || (geneClassifications && geneClassifications.length)
      || (geneSynonyms && geneSynonyms.length)
    ) {
      yield put(geneDiseaseSetChapterAction('gene-disease-expression'));
    }
    yield put(loadNextChapterAction('gene-disease-expression'));
  } catch (e) {
    yield put({ type: GENE_DISEASE_INIT_FAILED, message: e.message });
    yield put(loadNextChapterAction('gene-disease-expression'));
  }
}

function* fetchTissuesSaga(action) {
  try {
    yield put(geneDiseaseTissuesLoaderAction(true));
    const post = action.data;
    const response = yield call(Api.geneDiseaseExpressions, { post });
    yield put({ type: GENE_DISEASE_FETCH_TISSUES_SUCCESS, data: response.data });
    yield put(loadNextChapterAction('gene-disease-annotation'));
    yield put(geneDiseaseTissuesLoaderAction(false));
  } catch (e) {
    yield put({ type: GENE_DISEASE_FETCH_TISSUES_FAILURE, message: e.message });
    yield put(loadNextChapterAction('gene-disease-annotation'));
  }
}

function* geneDisease() {
  yield takeLatest(GENE_DISEASE_INIT_REQUESTED, saga);
  yield takeLatest(GENE_DISEASE_FETCH_TISSUES_REQUEST, fetchTissuesSaga);
}

export default geneDisease;
