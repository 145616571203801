import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';

import FormInput from '../../../../common/FormElements/FormInput';

import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';

import SingleTargetForCancerGroupList from './SingleTargetForCancerGroup/SingleTargetForCancerGroupList';
import { checkAllItems, checkAllGroupItems, checkItem } from '../utils';

const propTypesColumn = {
  tissues: PropTypes.instanceOf(Array),
  column: PropTypes.instanceOf(Object),
  allTissues: PropTypes.instanceOf(Object),
  change: PropTypes.func,
  checkTissue: PropTypes.func,
  checkAllTissues: PropTypes.func,
  updateTissues: PropTypes.func,
  changeSortDirection: PropTypes.func,
};

class SingleTargetForCancerColumn extends React.Component {
  componentDidMount() {
    this.setHiddenContentFieldValue();
  }

  componentDidUpdate() {
    this.setHiddenContentFieldValue();
  }

  setHiddenContentFieldValue = () => {
    const { column, change, allTissues } = this.props;
    const { tissues, checkedTissues } = allTissues[column.id];
    const value = tissues.length > 0 && checkedTissues.length > 0;
    change(`${column.id}Content`, value);
  };

  checkTissue = (id, type) => {
    const {
      column,
      updateTissues,
    } = this.props;

    const updatedTissues = checkItem(column, type, id);
    updateTissues({ type: column.id, updatedTissues });
  };

  checkAllTissues = () => {
    const {
      column,
      updateTissues,
    } = this.props;
    const updatedTissues = checkAllItems(column, column.checkAll);
    updateTissues({
      type: column.id,
      updatedTissues,
    });
  };

  checkAllGroupTissues = (type) => {
    const {
      column,
      updateTissues,
    } = this.props;
    const checkBoolean = !column.childTissues[type].checkAll;
    const updatedTissues = checkAllGroupItems(column, type, checkBoolean);
    updateTissues({ type: column.id, updatedTissues });
  };

  render() {
    const {
      tissues,
      column,
      allTissues,
      changeSortDirection,
    } = this.props;

    const tissuesLength = allTissues[column.id].tissues.length;
    return (
      <div className="tissues-dnd-column">
        <div className="tissues-dnd-column__header">
          <Checkbox
            checked={column.checkAll}
            readOnly={true}
            onChange={() => this.checkAllTissues(column.id)}
          />
          <span
            className="tissues-dnd-column__title"
          >
            {column.title}
          </span>
          <Field
            name={column.id}
            type="number"
            placeholder="Max Expr (TPM)"
            component={FormInput}
            autoComplete="off"
            mandatory={column.id === 'sensitiveTissues' ? tissuesLength > 0 : true}
            customClassName="form__input form__input_small form__input_light"
          />
        </div>
        <Droppable
          droppableId={column.id}
          key={column.id}
          data-react-beautiful-dnd-scroll-container={0}
          type="droppableItem"
        >
          {
            (provided, snapshot) => (
              <div
                className={
                  classNames(
                    'tissues-dnd-column__items',
                    { 'tissues-dnd-column__items_active': snapshot.isDraggingOver }
                  )
                }
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <SingleTargetForCancerGroupList
                  tissues={tissues}
                  checkAllGroupTissues={this.checkAllGroupTissues}
                  checkTissue={this.checkTissue}
                  allTissues={allTissues}
                  changeSortDirection={changeSortDirection}
                  columnId={column.id}
                />
                {provided.placeholder}
                {
                  tissuesLength === 0 &&
                  <div className="tissues-dnd-column__empty">
                    Drag and drop tissues here
                  </div>
                }
              </div>
            )
          }
        </Droppable>
      </div>
    );
  }
}

SingleTargetForCancerColumn.propTypes = propTypesColumn;

export default SingleTargetForCancerColumn;
