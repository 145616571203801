import React from 'react';
import PropTypes from 'prop-types';

// Components
import Checkbox from '../Inputs/Checkbox/Checkbox';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  withTaxonomies: PropTypes.bool,
};

const SemanticMain = ({
  handleOnChange, checked, disabled, name, count, withTaxonomies,
}) => (
  <>
    <Checkbox
      onChange={handleOnChange}
      checked={checked}
      disabled={disabled}
    />
    <span className="semantic-type__title">
      {`${name}${count ? ` (${count})` : ''}`}
    </span>
    {
      withTaxonomies &&
      <span className="semantic-type__taxonomie-title">
        Taxonomies
      </span>
    }
  </>
);

SemanticMain.propTypes = propTypes;

export default React.memo(SemanticMain);
