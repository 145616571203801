import { call, put } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  putTherapeuticCandidatesDataAction,
  therapeuticCandidatesDataFailedAction,
} from '../../actions';
import {
  geneDetailsSetChapterAction,
  loadNextChapterAction,
} from '../../../../actions';

function* getCompoundImages(experimentalResultsWithAffinity) {
  try {
    const compoundIds = experimentalResultsWithAffinity.map(item => (item.compound.id));
    const { data } = yield call(Api.getCompoundImagesData, compoundIds);
    return experimentalResultsWithAffinity.map((rawItem) => {
      const item = rawItem;
      const { image = '' } = data.find(respItem => respItem.id === item.compound.id) || {};
      item.compound.correctCompoundImage = image;
      return item;
    });
  } catch (e) {
    console.log(e);
    return experimentalResultsWithAffinity;
  }
}

export function* getTherapeuticCandidatesDataWorker(action) {
  try {
    const { id } = action.data;
    const response = yield call(Api.getTherapeuticCandidatesData, { id });
    const {
      data: {
        existingDrugs,
        experimentalResults,
        externalApiError,
      },
    } = response;
    let experimentalResultsWithAffinity = experimentalResults.map(item => (
      { ...item, affinity: `${item.measure.left}: ${item.measure.right}` }
    ));

    experimentalResultsWithAffinity = yield getCompoundImages(experimentalResultsWithAffinity);

    const existingDrugsWithAmount = existingDrugs.map(el => (
      { ...el, indications: { items: el.indications, itemsAmount: el.indications.length } }
    ));
    yield put(putTherapeuticCandidatesDataAction({
      existingDrugs: existingDrugsWithAmount,
      experimentalResults: experimentalResultsWithAffinity,
    }));
    if (externalApiError) {
      yield put(therapeuticCandidatesDataFailedAction(externalApiError));
    }
    if ((existingDrugs && existingDrugs.length) || (experimentalResults && experimentalResults.length)) {
      yield put(geneDetailsSetChapterAction('therapeutic-candidates'));
    }
    yield put(loadNextChapterAction('patents'));
  } catch (e) {
    yield put(therapeuticCandidatesDataFailedAction(e.message));
    yield put(loadNextChapterAction('patents'));
  }
}
