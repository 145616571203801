import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import UploadFile from '../../common/UploadFile/UploadFile';
import ModalComponent from '../../ModalComponent/ModalComponent';
import SaveAsSetContent from '../../Sets/SaveAsSet/Components/SaveAsSetContent/SaveAsSetContent';
import Error from '../../common/Error/Error';
// Store
import { getUploadedFileIsComlpexSelector } from '../../common/UploadFile/store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onSubmit: PropTypes.func,
  callbackAction: PropTypes.func,
  isComplexSet: PropTypes.bool,
};

const UploadSetModal = (props) => {
  const {
    isOpen,
    closeCb,
    onSubmit,
    callbackAction,
    isComplexSet,
  } = props;

  const handleSubmit = useCallback((data) => {
    onSubmit(data);
    closeCb();
  }, [onSubmit, closeCb]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
    >
      <div className="upload-set">
        <div className="upload-set__title title2">
          Upload Set
        </div>
        <UploadFile callbackAction={callbackAction} />
        <Error
          show={isComplexSet}
          error="You have uploaded Complex set, please change it to Simple set"
        />
        <SaveAsSetContent
          onSubmit={handleSubmit}
          withReset={true}
          disabled={isComplexSet}
        />
      </div>
    </ModalComponent>
  );
};

UploadSetModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isComplexSet: getUploadedFileIsComlpexSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(UploadSetModal);
