import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import classNames from 'classnames';
// Styles
import './styles.scss';

const propTypes = {
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.instanceOf(Object),
  customClassName: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  onInputChange: PropTypes.func,
  components: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  hide: PropTypes.bool,
  mandatory: PropTypes.bool,
  onSelectResetsInput: PropTypes.bool,
  menuPortalTarget: PropTypes.instanceOf(Object),
  hideSelectedOptions: PropTypes.bool,
  styles: PropTypes.instanceOf(Object),
  formatOptionLabel: PropTypes.instanceOf(Object),
  bordered: PropTypes.bool,
  grayMode: PropTypes.bool,
  showError: PropTypes.bool,
  error: PropTypes.string,
  filterWithOptions: PropTypes.bool,
};

const SelectInput = (props) => {
  const {
    hide,
    onSelect,
    onFocus,
    placeholder,
    options,
    title,
    components,
    styles,
    mandatory,
    isMulti,
    isLoading,
    isDisabled,
    isClearable,
    isSearchable = true,
    filterWithOptions = true,
    customClassName,
    noOptionsMessage,
    defaultValue,
    onInputChange,
    onSelectResetsInput,
    menuPortalTarget,
    formatOptionLabel,
    hideSelectedOptions,
    bordered,
    grayMode,
    showError,
    error,
  } = props;

  const [value, setValue] = useState(null || defaultValue);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback((option) => {
    onSelect(option);
    if (onSelectResetsInput) {
      setValue(null);
    } else {
      setValue(option);
    }
  }, [onSelect, setValue]);

  const handleInputChange = useCallback((v) => {
    setInputValue(v);
    if (onInputChange) {
      onInputChange(v);
    }
  }, [onInputChange]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (hide) {
    return null;
  }

  const selectorStyles = {
    control: provided => ({
      ...provided,
      border: bordered ? '1px solid var(--main-border-color)' : 'none',
      backgroundColor: grayMode ? '#eeeef0' : '#fff',
    }),
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: false,
    trim: false,
    matchFromStart: true,
  };

  const filterOption = filterWithOptions && isSearchable ? createFilter(filterConfig) : null;

  return (
    <div className={classNames({
      'cr-select': true,
      'selector--mandatory': mandatory && !value,
    })}
    >
      <Select
        value={value}
        inputValue={inputValue}
        title={title}
        options={options}
        isMulti={isMulti}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        onChange={handleChange}
        onFocus={onFocus}
        styles={{ ...styles, ...selectorStyles }}
        filterOption={filterOption}
        onInputChange={handleInputChange}
        classNamePrefix="dropdown"
        placeholder={placeholder}
        components={components}
        noOptionsMessage={noOptionsMessage}
        menuPortalTarget={menuPortalTarget}
        onSelectResetsInput={onSelectResetsInput}
        className={classNames('select', customClassName)}
        hideSelectedOptions={hideSelectedOptions}
        formatOptionLabel={formatOptionLabel}
      />
      {
        showError &&
        <div className="error-text">{error}</div>
      }
    </div>
  );
};

SelectInput.propTypes = propTypes;

export default React.memo(SelectInput);
