import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import TooltipBtn from '../../common/Tooltip/TooltipBtn';
import ShortConceptCard from './ShortConceptCard';

const propTypes = {
  id: PropTypes.number,
  uniqueKey: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
};

class ShortConceptCardCell extends React.PureComponent {
  cellTextJSX = () => {
    const { link, name, label } = this.props;
    if (link) {
      return (
        <Link
          className="link"
          target="blank"
          to={link}
        >
          {name}
        </Link>
      );
    }
    return (
      <span>{name} {label}</span>
    );
  };

  cellJSX = () => {
    const { id, uniqueKey } = this.props;
    if (id) {
      return (
        <>
          <TooltipBtn uniqueKey={uniqueKey}>
            { this.cellTextJSX() }
          </TooltipBtn>
          <Tooltip uniqueKeyProp={uniqueKey}>
            <ShortConceptCard id={id} />
          </Tooltip>
        </>
      );
    }
    return this.cellTextJSX();
  };

  render() {
    const { uniqueKey } = this.props;
    return (
      <div className="table-wrap__cell-row" key={uniqueKey}>
        { this.cellJSX() }
      </div>
    );
  }
}

ShortConceptCardCell.propTypes = propTypes;

export default ShortConceptCardCell;
