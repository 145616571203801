import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { insertHTML } from '../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../constantsCommon';
// Components
import Tooltip from '../../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../../Concept/ShortConceptCard/ShortConceptCard';

const propTypes = {
  semanticCategories: PropTypes.instanceOf(Object),
  highlights: PropTypes.instanceOf(Object),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  abstractText: PropTypes.string,
};

const PublicationDetailsAbstract = (props) => {
  const {
    highlights,
    showTooltip,
    hideTooltip,
    abstractText,
    semanticCategories,
    setShortConceptCardId,
  } = props;
  const uniqueKey = 'astract-tooltip';

  function getTemplateString(key, className = '', id, category) {
    const link = category === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    return `<a target="_blank" href="${link}" class="pub-details-highlight pub-details-link ${className}" data-id="${id}">${key}</a>`;
  }

  function getMarkedText() {
    if (!highlights) return abstractText;
    const highlightsValues = Object.values(highlights);

    if (!abstractText || highlightsValues.length === 0) {
      return abstractText;
    }

    return highlightsValues
      .flat()
      .sort((a, b) => a.start - b.start)
      .reduce((textArr, h, i, arr) => {
        const key = abstractText.substring(h.start, h.end);
        const category = semanticCategories.find(c => c.category === h.semanticCategory);

        if (i === 0) {
          textArr.push(abstractText.substring(0, h.start));
        } else {
          textArr.push(abstractText.substring(arr[i - 1].end, h.start));
        }
        textArr.push(getTemplateString(key, category.class, h.id, category.category));
        if (i === arr.length - 1) {
          textArr.push(abstractText.substring(h.end));
        }

        return textArr;
      }, [])
      .join('');
  }

  function showTooltipListener({ clientX, clientY, target }) {
    const { dataset: { id } } = target;
    setShortConceptCardId(id);
    showTooltip({ uniqueKey, clientX, clientY });
  }

  function hideTooltipListener() {
    hideTooltip();
  }

  useLayoutEffect(() => {
    const htmlHighlights = document.querySelectorAll('.pub-details-highlight');

    if (htmlHighlights.length > 0) {
      htmlHighlights.forEach((h) => {
        h.addEventListener('mouseenter', showTooltipListener);
        h.addEventListener('mouseleave', hideTooltipListener);
      });
    }
  }, [highlights]);

  const text = getMarkedText();

  return (
    <>
      <div
        className="pub-details-article__content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={
          insertHTML(text ? text.split('<br>').join('<br> ') : 'No abstract available.')
        }
      />
      <Tooltip uniqueKeyProp={uniqueKey}>
        <ShortConceptCard />
      </Tooltip>
    </>
  );
};

PublicationDetailsAbstract.propTypes = propTypes;

export default PublicationDetailsAbstract;
