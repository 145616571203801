import { createAction } from 'redux-actions';

// DATA
export const getRelationMapSetDataAction = createAction('@@relationMap/GET_SET_DATA');
export const getRelationMapProjectDataAction = createAction('@@relationMap/GET_PROJECT_DATA');
export const setRelationMapProjectDataAction = createAction('@@relationMap/SET_PROJECT_DATA');
export const setRelationMapProjectInfoAction = createAction('@@relationMap/SET_PROJECT_INFO');
export const setRelationMapAllProjectDataAction = createAction('@@relationMap/SET_PROJECT_DATA_ALL');
export const getRelationMapRelatedConceptsOptionsAction = createAction('@@relationMap/GET_RELATED_CONCEPTS_OPTIONS');
export const setRelationMapRelatedConceptsOptionsAction = createAction('@@relationMap/SET_RELATED_CONCEPTS_OPTIONS');
// CONCEPTS || NODES
export const updateRelationMapConceptAction = createAction('@@relationMap/UPDATE_CONCEPT');
export const mergeRelationMapConceptsAction = createAction('@@relationMap/MERGE_CONCEPTS');
export const deleteRelationMapConceptsAction = createAction('@@relationMap/DELETE_CONCEPTS');
export const addNewConceptsToRelationMapAction = createAction('@@relationMap/ADD_NEW_CONCEPT');
export const toggleAllRelationMapConceptsAction = createAction('@@relationMap/TOGGLE_ALL_CONCEPTS');
export const updateRelationMapNodesIsFixedAction = createAction('@@relationMap/UPDATE_NODES_IS_FIXED');
// LINKS
export const mergeRelationMapLinksAction = createAction('@@relationMap/MERGE_LINKS');
export const deleteRelationMapLinkAction = createAction('@@relationMap/DELETE_LINK');
export const updateRelationMapLinkLabelAction = createAction('@@relationMap/UPDATE_LINK_LABEL');
// LAYOUT
export const updateRelationMapLayoutAction = createAction('@@relationMap/UPDATE_LAYOUT');
export const updateRelationMapAlgorithmAction = createAction('@@relationMap/UPDATE_ALGORITHM');
export const toggleRelationMapProportionalLinksLayoutAction = createAction('@@relationMap/TOGGLE_PROPORTIONAL_LINKS_LAYOUT');
// SETTINGS
export const updateRelationMapSettingsAction = createAction('@@relationMap/UPDATE_SETTINGS');
// SEMANTIC CATEGORIES
export const updateRelationMapSemCategoriesAction = createAction('@@relationMap/UPDATE_SEMANTIC_CATEGORIES');
export const updateRelationMapSemCategoriesOrderAction = createAction('@@relationMap/UPDATE_SEMANTIC_CATEGORIES_ORDER');
export const updateRelationMapColorsAndShapesAction = createAction('@@relationMap/UPDATE_COLORS_AND_SHAPES');
// MANAGE
export const undoRelationMapAction = createAction('@@relationMap/UNDO');
export const saveRelationMapAction = createAction('@@relationMap/SAVE');
export const resetRelationMapAction = createAction('@@relationMap/RESET');
export const cacheRelationMapDataAction = createAction('@@relationMap/CACHE_DATA');
export const toggleRelationMapLoaderAction = createAction('@@relationMap/TOGGLE_LOADER');
export const setRelationMapDimensionsAction = createAction('@@relationMap/SET_DIMENSIONS');
export const toggleRelationMapIsSavedAction = createAction('@@relationMap/TOGGLE_IS_SAVED');
export const throwRelationMapProjectErrorAction = createAction('@@relationMap/THROW_PROJECT_ERROR');
