import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getParentBlockWidth } from '../../../Utils/Utils';
// Components
import Loader from '../../../common/Loader/Loader';
import ChordDiagram from './ChordDiagram';
import GeneticVariationsTable from './GeneticVariationsTable';
import AnimalModelsTable from './AnimalModelsTable';
import DiseaseAnnotationTable from './DiseaseAnnotationTable';
// Store
import * as SELECTORS from '../redux/selectors';
import { getDisordersDataAction } from '../redux/reducer';
import { loadNextChapterAction } from '../../actions';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  getDisordersData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  diseaseAnnotationData: PropTypes.instanceOf(Object),
  animalPhenotypes: PropTypes.instanceOf(Object),
  geneticVariations: PropTypes.instanceOf(Object),
};

class DiseaseDisordersChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: false,
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      getDisordersData,
      itsNewConcept,
      loadNextChapter,
      diseaseAnnotationData: {
        data: diseaseAnnotationData,
      },
      animalPhenotypes: {
        data: animalPhenotypesData,
      },
      geneticVariations: {
        data: geneticVariationsData,
      },
    } = this.props;

    if (itsNewConcept || !(diseaseAnnotationData.length || animalPhenotypesData.length || geneticVariationsData.length)) {
      getDisordersData({ id: gene });
    } else {
      loadNextChapter('therapeutic-candidates');
    }
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      error,
      loading,
    } = this.props;

    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div className="disease-disorders">
        <div className="gene-details-section-title title2">
          <span>Disease</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              The Disease section provides the following information on non-neoplastic diseases associated with the gene:
            </p>
            <ul>
              <li>Diseases associated through various types of disease relevant annotations</li>
              <li>Diseases associated with variants of the gene</li>
              <li>Diseases that are associated with animal gene knockouts or variants of the gene</li>
              <li>The gene interaction network that is involved in the top 25 diseases</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div>
            <ChordDiagram gene={gene} geneName={geneName} />
            <GeneticVariationsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <AnimalModelsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <DiseaseAnnotationTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

DiseaseDisordersChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: SELECTORS.getLoadingSelector(state),
    diseaseAnnotationData: SELECTORS.getDisorderDiseaseAnnotationSelector(state),
    animalPhenotypes: SELECTORS.getDisorderAnimalModelsSelector(state),
    geneticVariations: SELECTORS.getDisorderGeneticVariationsSelector(state),
    error: SELECTORS.getErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDisordersData(data) {
      dispatch(getDisordersDataAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiseaseDisordersChapter);
