import {
  createSelector,
} from 'reselect';

const conceptDetails = state => state.getIn(['conceptDetails', 'data']);
const loading = state => state.getIn(['conceptDetails', 'loading']);
const error = state => state.getIn(['conceptDetails', 'error']);
const categoryAnalysisData = state => state.getIn(['conceptDetails', 'categoryAnalysis', 'data']);
const categoryAnalysisError = state => state.getIn(['conceptDetails', 'categoryAnalysis', 'error']);
const categoryAnalysisLoading = state => state.getIn(['conceptDetails', 'categoryAnalysis', 'loading']);
const categoryAnalysisST = state => state.getIn(['conceptDetails', 'selectedST']);

const categoryAnalysisNodesCurrentPages = state => state.getIn(['conceptDetails', 'categoryAnalysisNodes', 'data', 'number']);
const categoryAnalysisNodesTotalPages = state => state.getIn(['conceptDetails', 'categoryAnalysisNodes', 'data', 'totalPages']);
const categoryAnalysisNodesTotalItems = state => state.getIn(['conceptDetails', 'categoryAnalysisNodes', 'data', 'totalElements']);
const categoryAnalysisNodesError = state => state.getIn(['conceptDetails', 'categoryAnalysisNodes', 'error']);
const categoryAnalysisNodesLoading = state => state.getIn(['conceptDetails', 'categoryAnalysisNodes', 'loading']);

const lastVisitedConceptId = state => state.getIn(['conceptDetails', 'lastVisitedConceptId']);

export const getLastVisitedConceptId = createSelector(
  lastVisitedConceptId,
  id => id
);

export const getConceptDetails = createSelector(
  conceptDetails,
  data => data.toJS()
);
export const getLoadingKey = createSelector(
  loading,
  data => data
);
export const getError = createSelector(
  error,
  data => data
);

export const getCategoryAnalysisData = createSelector(
  categoryAnalysisData,
  data => data.toJS()
);

export const getCategoryAnalysisError = createSelector(
  categoryAnalysisError,
  data => data
);

export const getCategoryAnalysisLoading = createSelector(
  categoryAnalysisLoading,
  data => data
);

export const getCategoryAnalysisST = createSelector(
  categoryAnalysisST,
  data => data.toJS()
);

export const getCategoryAnalysisNodesError = createSelector(
  categoryAnalysisNodesError,
  data => data
);

export const getCategoryAnalysisNodesCurrentPages = createSelector(
  categoryAnalysisNodesCurrentPages,
  data => data
);

export const getCategoryAnalysisNodesTotalPages = createSelector(
  categoryAnalysisNodesTotalPages,
  data => data
);

export const getCategoryAnalysisNodesTotalItems = createSelector(
  categoryAnalysisNodesTotalItems,
  data => data
);

export const getCategoryAnalysisNodesLoading = createSelector(
  categoryAnalysisNodesLoading,
  data => data
);
