import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { openSetResultInNewTab } from '../../Utils/Utils';
import { linksForTables } from '../GeneDiseasePage/enum';
// Components
import Loader from '../../common/Loader/Loader';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import CircosDiagram from '../../graphics/CircosDiagram/CircosDiagram';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import NoData from '../../common/NoData/NoData';
// Store
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import { loadNextChapterAction } from '../GeneDiseasePage/actions';
import {
  getLoading,
  getError,
  getDifferentialExpressionData,
} from './selectors';
import {
  initDifferentialExpressionAction,
} from './actions';
// Styles
import './GeneDiseaseDifferentialExpressionSection.css';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';

const propTypes = {
  initDifferentialExpression: PropTypes.func,
  geneId: PropTypes.string,
  diseaseId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  expressionData: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  pageName: PropTypes.string,
};

class GeneDiseaseDifferentialExpressionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
      popup: false,
    };
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.initPage();
  }

  initPage = () => {
    const {
      initDifferentialExpression,
      geneId,
      diseaseId,
      loadNextChapter,
      expressionData,
      itsNewConcept,
    } = this.props;

    if (itsNewConcept || isEmpty(expressionData)) {
      initDifferentialExpression({
        geneId,
        diseaseId,
      });
    } else {
      loadNextChapter('gene-mutation');
    }
  };

  redirectTo = (id, pathForLink) => `${RELATIVE_PATH}/${pathForLink}/${id}`;

  openPublicationsPopup = () => {
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  genesCell = ({ id, name }, rowIndex, columnIndex, pathForLink) => {
    const link = `${RELATIVE_PATH}/${pathForLink}/${id}`;
    const uniqueKey = `differential-expression-tooltip-${id}-${rowIndex}-${columnIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      gene,
      disease,
      error,
      loading,
      expressionData,
      pageName,
    } = this.props;
    const { popup } = this.state;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';

    const tableSettings = {
      width: 1100,
      headerHeight: 70,
      rowHeight: 50,
    };

    const columns = [{
      label: geneName,
      dataKey: 'setANode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setANode ? this.genesCell(rowData.setANode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: 'Gene neighbor node',
      dataKey: 'setBNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setBNode ? this.genesCell(rowData.setBNode, rowIndex, columnIndex, linksForTables.geneDetails) : null
      ),
    }, {
      label: diseaseName,
      dataKey: 'setCNode',
      width: 200,
      disableSort: true,
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => (
        rowData.setCNode ? this.genesCell(rowData.setCNode, rowIndex, columnIndex, linksForTables.conceptDetails) : null
      ),
    }, {
      label: `Pub count with ${geneName} [A+B]`,
      dataKey: 'pubsAB',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{geneName} </span><br />
            <span>[A+B]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsAB > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsAB > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsAB}
          </span>
        );
      },
    }, {
      label: `Pub count with ${diseaseName} [B+C]`,
      dataKey: 'pubsBC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count with </span><br />
            <span>{diseaseName} </span><br />
            <span>[B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsBC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsBC > 0) {
                this.conceptsNames = [rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsBC}
          </span>
        );
      },
    }, {
      label: `Pub count genes and ${diseaseName} [A+B+C]`,
      dataKey: 'pubsABC',
      width: 150,
      disableSort: true,
      headerRenderer: ({ label }) => (
        <div className="header-section">
          <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
            <span>Pub count genes and </span><br />
            <span>{diseaseName} </span><br />
            <span>[A+B+C]</span>
          </span>
        </div>
      ),
      cellRenderer: ({ rowData }) => {
        const linkClass = classnames({
          link: rowData.pubsABC > 0,
        });
        return (
          <span
            role="presentation"
            onClick={() => {
              if (rowData.pubsABC > 0) {
                this.conceptsNames = [rowData.setANode.name, rowData.setBNode.name, rowData.setCNode.name];
                this.conceptsIds = [rowData.setANode.id, rowData.setBNode.id, rowData.setCNode.id];
                this.openPublicationsPopup();
              }
            }}
            className={linkClass}
          >
            {rowData.pubsABC}
          </span>
        );
      },
    }];

    return (
      <div>
        {
          !loading && (
            <div>
              <div className="gene-disease-section-title">
                <span>Differential Expression</span>
                <span
                  role="presentation"
                  className="fa fa-info-circle icon"
                  onClick={() => {
                    this.setState({
                      showTitleHelperText: !this.state.showTitleHelperText,
                    });
                  }}
                />
                <div
                  className={
                    classnames(
                      'gene-disease-section-title-helper-text',
                      { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                    )
                  }
                >
                  This section provides the molecular interactions that {geneName} has with genes that are differentially expressed
                  for {diseaseName}. This is visualised in a <span className="strongBold">circle diagram</span> as follows:
                  <ul>
                    <li>The <span className="strongBold">outer colored ring</span> represents
                      the disregulated genes for {diseaseName} that
                      interact with {geneName} where:
                    <ul>
                      <li>Each ring segment represents a gene/protein</li>
                      <li>The top 15 genes/proteins, based on the number of publications mentioning the gene/protein
                          and {diseaseName}, are shown
                      </li>
                      <li>
                          The size of each protein segment is based on the total number of molecular interactions it has
                          with other genes (in general, not specific to the disease)
                      </li>
                    </ul>
                    </li>
                    <li>
                      The <span className="strongBold">inner colored ring</span> displays the differential expression values (log2fold) per gene.
                    </li>
                    <li>
                      If a gene is expressed in one of the highlighted tissues (see the ‘Expression’ section), these are
                      shown as <span className="strongBold">expression bars</span> protruding from the gene segment.
                    </li>
                    <li>
                      The <span className="strongBold">inner circle</span> shows the molecular interactions between the genes.
                    </li>
                  </ul>
                  <div>The following <span className="strongBold">actions</span> are possible on the circle diagram :</div>
                  <ul>
                    <li><span className="strongBold">Click</span> on a gene segment to highlight its interactions</li>
                    <li><span className="strongBold">Hover</span> over any element in the circle diagram for more details</li>
                  </ul>
                  <div>
                    A <span className="strongBold">table</span> is shown that provides the publication and database references for the interactions displayed
                    in the circle diagram. If {geneName} has more than the top 15 interactions displayed in the circle diagram,
                    these can be accessed here as well.
                  </div>
                </div>
              </div>
              <div className="differential-expression-section-body">
                {
                  expressionData.dysregulatedExperiments.length === 0 &&
                  <div>
                    { geneName } is not dysregulated in any differntial expression experiment for { diseaseName }
                  </div>
                }
                {
                  expressionData.dysregulatedExperiments.length > 0 &&
                  <div>
                    { geneName } is dysregulated in at least 1 experimental model of { diseaseName }:
                  </div>
                }
                {
                  expressionData.dysregulatedExperiments.map((concept, i) => (
                    <Link
                      key={`concept-${i}`}
                      className="link"
                      target="blank"
                      to={this.redirectTo(concept.id, linksForTables.conceptDetails)}
                    >
                      {concept.name}
                    </Link>
                  ))
                }
                {
                  expressionData.geneNeighbourNode ?
                    <div>
                      { geneName } has molecular interaction with one ore more proteins that are dysregulated in
                      differential expression experiments for { diseaseName }
                    </div> :
                    <div>
                      {geneName} has no molecular interaction with any proteins that are dysregulated in differential expression experiments for {diseaseName}
                    </div>
                }
                {
                  expressionData.chords &&
                  expressionData.chords.length > 0 &&
                  <CircosDiagram
                    targetGene={gene}
                    data={expressionData.data}
                    chords={expressionData.chords}
                    geneColor={expressionData.genesColor}
                    id="differentialExpressionCircos"
                    geneName={pageName}
                    chartName="Differential Expression"
                  />
                }
                <NoData
                  show={
                    expressionData.publications &&
                    !expressionData.publications.length
                  }
                  customClassName="gene-disease__no-data"
                  message="There is no data to display table."
                />
                {
                  expressionData.publications &&
                  expressionData.publications.length > 0 &&
                  <div className="differential-expression-table">
                    <div className="differential-expression-table-header">
                      <button
                        onClick={() => openSetResultInNewTab(expressionData.fullSetABC)}
                        type="button"
                        className="button button-primary"
                      >
                        <ReactTooltip
                          id="showAllDiseaseGenes"
                          place="bottom"
                          type="light"
                        >
                          Open a table containing all interactions with dysregulated genes
                        </ReactTooltip>
                        <span
                          data-tip={true}
                          data-for="showAllDiseaseGenes"
                        >
                          Show all dysregulated genes
                        </span>
                      </button>
                    </div>
                    <SimpleTable
                      fixedHeight={true}
                      settings={tableSettings}
                      height={(expressionData.publications.length * tableSettings.rowHeight) + tableSettings.headerHeight}
                      data={expressionData.publications}
                      columns={columns}
                    />
                  </div>
                }
              </div>
            </div>
          )
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneDiseaseDifferentialExpressionSection.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    expressionData: getDifferentialExpressionData(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initDifferentialExpression(data) {
      dispatch(initDifferentialExpressionAction(data));
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneDiseaseDifferentialExpressionSection);
