export const prepeareUploadFileRequestData = (formData, {
  columnFrom, columnTo, rowFrom, rowTo, sc, st, projectId, taxonomies
}) => (
  {
    formData,
    params: {
      ...(columnFrom && { columnFrom }),
      ...(columnTo && { columnTo }),
      ...(rowFrom && { rowFrom }),
      ...(rowTo && { rowTo }),
      ...(!!sc.length && { sc }),
      ...(!!st.length && { st }),
      ...(!!taxonomies && { tax: taxonomies }),
    },
    ...(projectId && { projectId }),
  }
);

export const transformFileDataToConcepts = (data, conceptType) => (
  data.map((c, i) => ({
    ...c,
    id: i + 1,
    mappedGenes: c.mappedGenes.map(g => ({ ...g, type: conceptType })),
  }))
);
