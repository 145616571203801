import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import { setGeneSetFilterOptionsAction, throwCreateSetErrorAction } from '../../actions';
import { getAvailableFiltersSelector } from '../../selectors';
// Enums
import { geneSetFiltersNames } from '../../../enums';

export function* getGeneSetFilterOptionsWorker({ payload }) {
  try {
    const { filterName } = payload;
    const { data } = filterName === geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER ?
      yield call(Api.tissue) :
      yield call(Api.getGeneSetFilterOptions, payload);

    const availableFilters = yield select(getAvailableFiltersSelector);
    const filter = availableFilters[filterName];
    const options = data.map((d) => {
      const label = filterName === geneSetFiltersNames.GENE_TISSUE_EXPRESSION_FILTER ?
        `${d.name} (${d.typeName})` : d.name;
      return {
        ...d,
        label,
        value: d.name,
      };
    }).sort((a, b) => a.label.localeCompare(b.label));

    yield put(setGeneSetFilterOptionsAction({
      ...filter,
      isLoading: false,
      options,
    }));
  } catch (e) {
    yield put(throwCreateSetErrorAction(e.message));
  }
}
