/* eslint-disable no-param-reassign */
import {
  ABSTRACT_PREDICATE_NAME,
  SENTENCE_PREDICATE_NAME,
  RelationNumberMetricEnum,
  DefaultRelationMapOptions,
  RelationMapShapes,
} from './constants';

/* eslint-disable no-plusplus */
function destructureProjectData(data) {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    version: data.version,
    tags: data.tags.map((t, i) => ({ id: `tag_${i}`, name: t })),
  };
}

function prepareProjectData(data, hideSourceData, hideRelationTypeData) {
  const { concepts, links, mapOptions } = data;
  const {
    layout,
    hideLabels,
    hideSource,
    nodeColors,
    hideBelowCount,
    hideRelationTypes,
    relationNumberMetric,
  } = mapOptions || DefaultRelationMapOptions;

  const showCooccurrence = relationNumberMetric === RelationNumberMetricEnum.COOCCURRENCE;
  const showCloselyRelatedPubs = relationNumberMetric === RelationNumberMetricEnum.CLOSELY_RELATED;
  const semanticCategories = nodeColors ?
    nodeColors.reduce((acc, item) => {
      const semCat = { ...acc };
      semCat[item.category] = {
        ...item,
        checked: true,
      };
      return semCat;
    }, {}) :
    {};

  const byIdConcepts = concepts.reduce((c, item) => {
    const byId = { ...c };

    if (!nodeColors && !semanticCategories[item.semanticCategory]) {
      semanticCategories[item.semanticCategory] = {
        color: item.color,
        checked: true,
        shape: RelationMapShapes.CIRCLE,
        category: item.semanticCategory,
      };
    }

    byId[item.conceptId] = {
      ...item,
      id: item.conceptId,
      fx: item.x,
      fy: item.y,
      shape: semanticCategories[item.semanticCategory].shape,
      color: semanticCategories[item.semanticCategory].color,
    };

    return byId;
  }, {});

  const byIdLinks = links.reduce((l, item) => {
    const byId = { ...l };
    byId[`${item.source}_${item.target}`] = {
      linkId: `${item.source}_${item.target}`,
      twoSided: item.triples.some(t => t.object === item.source && t.subject === item.target),
      ...item,
      triples: item.triples.map(t => ({
        ...t,
        hidenSource: t.accessMappings.every(accessMapping => hideSourceData.includes(accessMapping)),
        hidenRealationType: hideRelationTypeData.includes(`${t.predicateId}`),
      })),
    };
    return byId;
  }, {});

  return {
    data: {
      nodes: byIdConcepts,
      links: byIdLinks,
    },
    settings: {
      hideLabels: showCooccurrence || showCloselyRelatedPubs ? false : hideLabels,
      hideSource: showCooccurrence || showCloselyRelatedPubs ? false : hideSource,
      hideBelowCount,
      hideRelationTypes,
      showCooccurrence,
      showCloselyRelatedPubs,
    },
    layout,
    project: destructureProjectData(data),
    semanticCategories,
  };
}

function getLinksAndNodesFromTriples(triples = []) {
  const data = {
    links: {},
    nodes: {},
  };

  if (!triples.length) {
    return data;
  }

  return triples.reduce((acc, triple) => {
    const sourceId = triple.subjectGi;
    const targetId = triple.objectGi;
    const subjectLink = !!acc.links[`${sourceId}_${targetId}`];
    const objectLink = !!acc.links[`${targetId}_${sourceId}`];
    const newTriple = {
      hidenSource: false,
      hidenRealationType: false,
      tripleId: triple.id,
      predicateId: triple.predicateGi,
      predicateName: triple.predicateName,
      accessMappings: triple.accessMappings,
    };

    if (subjectLink) {
      acc.links[`${sourceId}_${targetId}`].triples.push(newTriple);
    } else if (objectLink) {
      acc.links[`${targetId}_${sourceId}`].triples.push(newTriple);
      acc.links[`${targetId}_${sourceId}`].twoSided = true;
    } else {
      acc.links[`${sourceId}_${targetId}`] = {
        linkId: `${sourceId}_${targetId}`,
        source: sourceId,
        target: targetId,
        selected: false,
        twoSided: false,
        custom: false,
        triples: [newTriple],
      };
    }

    return acc;
  }, data);
}

function mergeLinksAndNodes(newConcepts, newLinksAndNodes, currentData, semCategories) {
  const data = { ...newLinksAndNodes };
  const semanticCategories = { ...semCategories };

  newConcepts.forEach((c) => {
    if (!semanticCategories[c.semanticCategory]) {
      semanticCategories[c.semanticCategory] = {
        color: c.color,
        checked: true,
        shape: RelationMapShapes.CIRCLE,
        category: c.semanticCategory,
      };
    }

    data.nodes[c.id] = {
      id: c.id,
      name: c.name,
      conceptId: c.id,
      selected: false,
      color: semanticCategories[c.semanticCategory].color,
      shape: semanticCategories[c.semanticCategory].shape,
      semanticCategory: c.semanticCategory,
    };
  });

  return {
    nodes: {
      ...data.nodes,
      ...(currentData && currentData.nodes),
    },
    links: {
      ...data.links,
      ...(currentData && currentData.links),
    },
    semanticCategories,
  };
}

function addCooccurrenceLinks(cooccurrenceData, links, predicateName) {
  return Object.keys(cooccurrenceData).reduce((acc, d) => {
    const ids = d.replace(/[()]/g, '').split(',');
    const sourceId = Number(ids[0]);
    const targetId = Number(ids[1]);

    if (!links[`${sourceId}_${targetId}`] && !links[`${targetId}_${sourceId}`] && cooccurrenceData[d] > 0) {
      acc[`${sourceId}_${targetId}`] = {
        linkId: `${sourceId}_${targetId}`,
        source: sourceId,
        target: targetId,
        selected: false,
        twoSided: false,
        custom: false,
        triples: [{
          object: sourceId,
          subject: targetId,
          tripleId: null,
          predicateId: null,
          predicateName,
          accessMappings: ['0--2'],
        }],
      };
    }

    return acc;
  }, {});
}

function addCooccurrenceToLinks(data, relatedPubs, closelyRelatedPubs) {
  const { nodes, links, semanticCategories } = data;
  const relatedPubsLinks = addCooccurrenceLinks(relatedPubs, links, ABSTRACT_PREDICATE_NAME);
  const closelyRelatedPubsLinks = addCooccurrenceLinks(closelyRelatedPubs, links, SENTENCE_PREDICATE_NAME);
  const allLinks = {
    ...links,
    ...relatedPubsLinks,
    ...closelyRelatedPubsLinks,
  };
  const allLinksIds = Object.keys(allLinks);

  for (let i = 0; i < allLinksIds.length; i++) {
    const id = allLinksIds[i];
    const l = allLinks[id];

    allLinks[id].cooccurrence = {
      relatedPubs: relatedPubs[`(${l.source},${l.target})`] || relatedPubs[`(${l.target},${l.source})`] || 0,
      closelyRelatedPubs: closelyRelatedPubs[`(${l.source},${l.target})`] || closelyRelatedPubs[`(${l.target},${l.source})`] || 0,
    };

    if (l.triples[0].predicateName === SENTENCE_PREDICATE_NAME && !!relatedPubsLinks[id]) {
      l.triples.push({
        ...l.triples[0],
        predicateName: ABSTRACT_PREDICATE_NAME,
      });
    }
  }

  return {
    data: {
      nodes,
      links: allLinks,
    },
    semanticCategories,
  };
}

function removeFixedPositionFromNodes(nodes) {
  return nodes.reduce((newNodes, n) => {
    const nodesWithoutPosition = { ...newNodes };
    nodesWithoutPosition[n.id] = {
      ...n,
      fx: null,
      fy: null,
      x: null,
      y: null,
    };
    return nodesWithoutPosition;
  }, {});
}

function getRelationTypesModalData(links, selectedIds = []) {
  return links.reduce((newData, link) => {
    const d = { ...newData };
    link.triples.forEach((t) => {
      const added = !!d[t.predicateId];
      if (!added) {
        d[t.predicateId] = {
          id: t.predicateId,
          name: t.predicateName,
          checked: selectedIds.includes(`${t.predicateId}`),
        };
      }
    });
    return d;
  }, {});
}

function getHideSorceModalData(links, accessMappings, selectedIds = []) {
  return links.reduce((newData, link) => {
    const d = { ...newData };
    link.triples.forEach((t) => {
      if (t.accessMappings) {
        t.accessMappings.forEach((item) => {
          const added = !!d[item];
          if (!added) {
            d[item] = {
              id: item,
              checked: selectedIds.includes(`${item}`),
              name: accessMappings[item] || item,
            };
          }
        });
      }
    });
    return d;
  }, {});
}

function calcSemanticCategoriesPosition(concepts, semanticCategoriesOrder, width) {
  const step = 100;
  const maxPerRow = Math.floor(width / step);
  let y = 0;

  const conceptsByCategory = concepts.reduce((categories, c) => {
    const newCategories = { ...categories };
    const category = newCategories[c.semanticCategory];

    if (!category) {
      newCategories[c.semanticCategory] = [[c]];
      return newCategories;
    }

    for (let i = 0; i < newCategories[c.semanticCategory].length; i++) {
      const row = newCategories[c.semanticCategory][i];
      const nextRow = newCategories[c.semanticCategory][i + 1];

      if (row.length !== maxPerRow) {
        row.push(c);
        break;
      }

      if (!nextRow) {
        newCategories[c.semanticCategory].push([c]);
        break;
      }
    }

    return newCategories;
  }, {});

  semanticCategoriesOrder.forEach((category) => {
    if (conceptsByCategory[category]) {
      conceptsByCategory[category].forEach((row) => {
        y += step;
        row.forEach((c, i, arr) => {
          const conceptNumber = (i + 1);
          const x = (width / (arr.length + 1)) * conceptNumber;
          c.x = x;
          c.fx = x;
          c.y = y;
          c.fy = y;
          c.index = i;
        });
      });
    }
  });

  const updatedConcepts = Object.values(conceptsByCategory).reduce((map, row) => {
    const newMap = { ...map };

    row.forEach((c) => {
      c.forEach((item) => {
        newMap[item.id] = item;
      });
    });

    return newMap;
  }, {});

  return updatedConcepts;
}

export {
  prepareProjectData,
  mergeLinksAndNodes,
  addCooccurrenceToLinks,
  destructureProjectData,
  getLinksAndNodesFromTriples,
  removeFixedPositionFromNodes,
  getRelationTypesModalData,
  getHideSorceModalData,
  calcSemanticCategoriesPosition,
};
