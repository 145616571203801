import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './styles.scss';

const propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  customClassName: PropTypes.string,
};

const NoData = (props) => {
  const {
    show,
    message = 'There is no data to display',
    customClassName,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames('nodata', customClassName)}
      data-testid="nodata-testid"
    >
      <div className="nodata__message">{message}</div>
    </div>
  );
};

NoData.propTypes = propTypes;

export default React.memo(NoData);
