import { call } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Utils
import { exportPathwayImage } from '../../../Utils';

export function* getImageForExportWorker({ payload }) {
  try {
    const { geneName, ...requestData } = payload;
    const { data } = yield call(Api.getGeneDetailsReactomes, requestData);
    if (data) {
      const { defaultImage: { image } } = data;
      exportPathwayImage(image, geneName, 'png');
    }
  } catch (e) {
    console.log(e);
  }
}
