export const helpText = {
  uniprotId: 'The human Uniprot ID',
  species: 'The species for the matching ortholog (this can be "Macaca mulatta (rhesus macaque)", "Macaca fascicularis' +
    '(crab-eating macaque)", "Mus musculus (mouse)", "Rattus norvegicus (rat)", or "Oryctolagus Cuniculus (rabbit)).',
  orthologUniprotId: 'The Ortholog Uniprot ID',
  identity: 'The identity score between the human-species ortholog pair for the whole human protein.',
  similarity: 'The similarity score between the human-species ortholog pair for the whole human protein.',
  identityEC: 'The identity score between the human-species ortholog pair for the extracellular domain of the human protein.',
  similarityEC: 'The similarity score between the human-species ortholog pair for the extracellular domain of the human protein.',
};
