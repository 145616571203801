import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ActivateCreatedUserForm from './ActivateCreatedUserForm';

// Actions
import { submitPasswordAction } from './store/reducer';
import { getActivateUserCheckTokenError, getActivateUserCheckError } from './store/selectors';
import { history } from '../../../index';

// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';

// Styles
import './ActivateCreatedUser.scss';

const propTypes = {
  error: PropTypes.string,
  submitPassword: PropTypes.func,
  tokenError: PropTypes.string,
};

const ActivateCreatedUser = (props) => {
  const {
    submitPassword,
    error,
    tokenError,
  } = props;
  const submitPasswordForm = () => {
    submitPassword();
  };

  return (
    <div>
      {
        !tokenError.length > 0 ?
          <>
            <div className="flex-grid">
              <div className="col-1 text-left">
                <h3 className="title3 text-center">Set Password</h3>
              </div>
            </div>
            <div className="flex-grid">
              <div className="col-1 text-left">
                {error &&
                <div className="error-text text-center">Error occurs. Please, try again.</div>
                }
                <ActivateCreatedUserForm
                  onSubmit={submitPasswordForm}
                />
              </div>
            </div>
          </> :
          <div className="error-content">
            <p className="error-text">{tokenError}</p>
            <button
              className="button button-primary mt-15"
              onClick={
                () => { history.replace(`${RELATIVE_PATH}/login`); }
              }
            >
              Go to login page
            </button>
          </div>
      }
    </div>
  );
};

ActivateCreatedUser.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: getActivateUserCheckError(state),
    tokenError: getActivateUserCheckTokenError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitPassword() {
      dispatch(submitPasswordAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateCreatedUser);
