import { createSelector } from 'reselect';
import isEmpty from 'lodash.isempty';

import { getHighlightedTissuesIdsByPubsCount as getIds } from './utils';

const gene = state => state.getIn(['geneDisease', 'data', 'gene']);
const disease = state => state.getIn(['geneDisease', 'data', 'disease']);
const predicate = state => state.getIn(['geneDisease', 'data', 'predicate']);
const loading = state => state.getIn(['geneDisease', 'loading']);
const error = state => state.getIn(['geneDisease', 'error']);
const pageFlow = state => state.getIn(['geneDisease', 'pageFlow']);
const geneClassifications = state => state.getIn(['geneDisease', 'data', 'geneClassifications']);
const diseaseClassifications = state => state.getIn(['geneDisease', 'data', 'diseaseClassifications']);
const geneSynonyms = state => state.getIn(['geneDisease', 'data', 'geneSynonyms']);
const diseaseSynonyms = state => state.getIn(['geneDisease', 'data', 'diseaseSynonyms']);
const tissuesData = state => state.getIn(['geneDisease', 'tissuesData']);
const tissuesLoader = state => state.getIn(['geneDisease', 'tissuesLoader']);
const pageChapters = state => state.getIn(['geneDisease', 'pageChapters']);
const pageChaptersData = state => state.getIn(['geneDisease', 'pageChaptersData']);
const lastVisitedGeneId = state => state.getIn(['geneDisease', 'lastVisitedGeneId']);
const lastVisitedDiseaseId = state => state.getIn(['geneDisease', 'lastVisitedDiseaseId']);

export const getTissuesData = createSelector(
  tissuesData,
  data => data.toJS()
);

export const getTissuesLoader = createSelector(
  tissuesLoader,
  data => data
);

export const getHighlightedTissuesIdsByPubsCount = createSelector(
  getTissuesData,
  data => (!isEmpty(data) && getIds(data)) || []
);

export const getGeneClassifications = createSelector(
  geneClassifications,
  data => (data ? data.toJS() : [])
);

export const getDiseaseClassifications = createSelector(
  diseaseClassifications,
  data => (data ? data.toJS() : [])
);

export const getGeneSynonyms = createSelector(
  geneSynonyms,
  data => (data ? data.toJS() : [])
);

export const getDiseaseSynonyms = createSelector(
  diseaseSynonyms,
  data => (data ? data.toJS() : [])
);

export const getGene = createSelector(
  gene,
  data => (data ? data.toJS() : {})
);

export const getDisease = createSelector(
  disease,
  data => (data ? data.toJS() : {})
);

export const getPredicate = createSelector(
  predicate,
  data => data
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getPageFlow = createSelector(
  pageFlow,
  data => (data ? data.toJS() : [])
);

export const getPageChapters = createSelector(
  pageChapters,
  data => (data ? data.toJS() : [])
);

export const getLastVisitedGeneId = createSelector(
  lastVisitedGeneId,
  id => id
);

export const getLastVisitedDiseaseId = createSelector(
  lastVisitedDiseaseId,
  id => id
);

export const getPageChaptersData = createSelector(
  pageChaptersData,
  data => (data ? data.toJS() : [])
);
