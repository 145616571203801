import { takeEvery, call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Actions
import * as a from './reducer';

export function* getPublicationsDataWorker({ payload }) {
  try {
    const { data } = yield call(Api.search, payload);
    yield put(a.setPublicationsListDataAction(data));
  } catch (e) {
    yield put(a.throwPublicationsListError(e.message));
  }
}

export function* getPublicationsIdsWorker({ payload }) {
  try {
    const requestData = { ...payload };
    delete requestData.params.sort;
    delete requestData.params.page;
    delete requestData.params.size;
    const { data } = yield call(Api.getSearchPuplicationsIds, requestData);
    const slice = {
      selectAll: true,
      selectedPublicationsIds: [],
      selectedPubmedIds: [],
    };
    yield put(a.setPublicationsListIdsAction(
      data.reduce((result, d) => {
        result.selectedPublicationsIds.push(d.id);
        if (d.pubmedId) {
          result.selectedPubmedIds.push(Number(d.pubmedId));
        }
        return result;
      }, slice)
    ));
  } catch (e) {
    yield put(a.throwPublicationsListError(e.message));
  }
}

function* watchPublicationsList() {
  yield takeEvery(a.getPublicationsListDataAction, getPublicationsDataWorker);
  yield takeEvery(a.getPublicationsListIdsction, getPublicationsIdsWorker);
}

export default watchPublicationsList;
