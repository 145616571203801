export const METHOD_SELECTION_TABS_STATE = 'METHOD_SELECTION_TABS_STATE';
export const METHOD_SELECTED = 'METHOD_SELECTED';
export const FIRST_SELECTED_METHOD = 'FIRST_SELECTED_METHOD';
// export const METHOD_SELECTION_RESET = 'METHOD_SELECTION_RESET';

export const CATEGORY_ANALYSIS_CATEGORIES_REQUESTED = 'CATEGORY_ANALYSIS_CATEGORIES_REQUESTED';
export const CATEGORY_ANALYSIS_CATEGORIES_FAILED = 'CATEGORY_ANALYSIS_CATEGORIES_FAILED';
export const CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED = 'CATEGORY_ANALYSIS_CATEGORIES_SUCCEEDED';

export const CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME = 'CATEGORY_ANALYSIS_SELECT_CATEGORY_NAME';
export const CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE = 'CATEGORY_ANALYSIS_SELECT_CATEGORY_TYPE';

export const SELECT_RANKING_CATEGORY_NAME = 'SELECT_RANKING_CATEGORY_NAME';

export const OPEN_MENU_CATEGORY_ANALYSIS = 'OPEN_MENU_CATEGORY_ANALYSIS';
export const CLOSE_MENU_CATEGORY_ANALYSIS = 'CLOSE_MENU_CATEGORY_ANALYSIS';

export const OPEN_MENU_TISSUES = 'OPEN_MENU_TISSUES';
export const CLOSE_MENU_TISSUES = 'CLOSE_MENU_TISSUES';

export const OPEN_MENU_TISSUES_PENALTY = 'OPEN_MENU_TISSUES_PENALTY';
export const CLOSE_MENU_TISSUES_PENALTY = 'CLOSE_MENU_TISSUES_PENALTY';

export const RANKING_SETTINGS = 'RANKING_SETTINGS';
export const RANKING_TYPE = 'RANKING_TYPE';

export const OPEN_RANKING_MODAL = 'OPEN_RANKING_MODAL';
export const CLOSE_RANKING_MODAL = 'CLOSE_RANKING_MODAL';

export const TISSUES_REQUESTED = 'TISSUES_REQUESTED';
export const TISSUES_SUCCEEDED = 'TISSUES_SUCCEEDED';
export const TISSUES_FAILED = 'TISSUES_FAILED';

export const SELECT_TISSUE_NAME = 'SELECT_TISSUE_NAME';
export const SELECT_PENALTY_TISSUE_NAME = 'SELECT_PENALTY_TISSUE_NAME';
export const SELECT_TISSUE_TYPE = 'SELECT_TISSUE_TYPE';

export const SELECT_PENALTY_TISSUES = 'SELECT_PENALTY_TISSUES';
export const DESELECT_PENALTY_TISSUE_TYPE = 'DESELECT_PENALTY_TISSUE_TYPE';

export const TICK_TISSUE_SOURCE = 'TICK_TISSUE_SOURCE';

export const OPEN_MENU_RANKING_CATEGORIES = 'OPEN_MENU_RANKING_CATEGORIES';
export const CLOSE_MENU_RANKING_CATEGORIES = 'CLOSE_MENU_RANKING_CATEGORIES';

export const SELECT_RANKING_TISSUE_TYPE = 'SELECT_RANKING_TISSUE_TYPE';
export const DESELECT_RANKING_TISSUE_TYPE = 'DESELECT_RANKING_TISSUE_TYPE';

export const CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES = 'CLEAR_DIFFERENTIAL_EXPRESSION_CATEGORIES';

export const CLEAR_EXPRESSION_SPECIFICITY_SCORE = 'CLEAR_EXPRESSION_SPECIFICITY_SCORE';

export const SAVE_RANKING_CONCEPT = 'SAVE_RANKING_CONCEPT';

export const REFRESH_RANKING_SETTINGS = 'REFRESH_RANKING_SETTINGS';

export const REMOVE_RANKING_CONCEPT = 'REMOVE_RANKING_CONCEPT';

export const APPLY_TARGET_SETTINGS = 'APPLY_TARGET_SETTINGS';
export const APPLY_MARKER_SETTINGS = 'APPLY_MARKER_SETTINGS';

export const SET_SCORING_FORMULA = 'SET_SCORING_FORMULA';
export const SET_DEFAULT_SCORING_FORMULA = 'SET_DEFAULT_SCORING_FORMULA';

export const SET_DIFFERENTIAL_EXPRESSION_CONCEPT = 'SET_DIFFERENTIAL_EXPRESSION_CONCEPT';
export const SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT = 'SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT';
export const REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT = 'REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT';

export const SET_DIFFERENTIAL_EXPRESSION_CATEGORY = 'SET_DIFFERENTIAL_EXPRESSION_CATEGORY';

export const METHOD_SELECTION_SHOULD_RESET = 'METHOD_SELECTION_SHOULD_RESET';

export const INIT_ANALYSIS_METHOD_SELECTION = 'INIT_ANALYSIS_METHOD_SELECTION';

export const SELECT_GENE_FOR_NETWORK_ANALYSIS = 'SELECT_GENE_FOR_NETWORK_ANALYSIS';
export const OPEN_MENU_TISSUES_NETWORK_ANALYSIS = 'OPEN_MENU_TISSUES_NETWORK_ANALYSIS';
export const CLOSE_MENU_TISSUES_NETWORK_ANALYSIS = 'CLOSE_MENU_TISSUES_NETWORK_ANALYSIS';
export const SELECT_NETWORK_ANALYSIS_TISSUE_NAME = 'SELECT_NETWORK_ANALYSIS_TISSUE_NAME';
export const SELECT_NETWORK_ANALYSIS_TISSUE_TYPE = 'SELECT_NETWORK_ANALYSIS_TISSUE_TYPE';
export const TICK_NETWORK_ANALYSIS_TISSUE_SOURCE = 'TICK_NETWORK_ANALYSIS_TISSUE_SOURCE';
export const RESET_NETWORK_ANALYSIS_TISSUE_SOURCE = 'RESET_NETWORK_ANALYSIS_TISSUE_SOURCE';
export const SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS = 'SELECT_SET_FOR_NETWORK_ANALYSIS_SETTINGS';
export const RESET_NETWORK_ANALYSIS_SETTINGS = 'RESET_NETWORK_ANALYSIS_SETTINGS';

export const CHANGE_FACTOR_LOGIC_VALUE = 'CHANGE_FACTOR_LOGIC_VALUE';

export const GET_BACKGROUND_CELLS = 'GET_BACKGROUND_CELLS';
export const SET_BACKGROUND_CELLS = 'SET_BACKGROUND_CELLS';
export const SET_BACKGROUND_CELLS_FILTER_OPTION = 'SET_BACKGROUND_CELLS_FILTER_OPTION';
export const TOGGLE_BACKGROUND_CELLS_LOADER = 'TOGGLE_BACKGROUND_CELLS_LOADER';
export const TOGGLE_BACKGROUND_SET_OPTION = 'TOGGLE_BACKGROUND_SET_OPTION';
export const UPDATE_BACKGROUND_SET_CONFIG = 'UPDATE_BACKGROUND_SET_CONFIG';
export const SET_BACKGROUND_SET_FILTER_OPTION = 'SET_BACKGROUND_SET_FILTER_OPTION';
export const SWITCH_SHOULD_UPDATE_DATA_FLAG = 'SWITCH_SHOULD_UPDATE_DATA_FLAG';

export const CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID = 'CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID';
export const SET_BACKGROUND_SET_ACTIVE_PROJECT_ID = 'SET_BACKGROUND_SET_ACTIVE_PROJECT_ID';

export const RESET_ANALYTICS_METHOD_SELECTION = 'RESET_ANALYTICS_METHOD_SELECTION';
