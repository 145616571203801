import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Components
import FormSelect from '../../../../common/FormElements/FormSelect';
import BeesWarmChart from '../../../../graphics/BeesWarmChart/BeesWarmChart';
import Spinner from '../../../../common/Spinner/Spinner';
import NoData from '../../../../common/NoData/NoData';
// Constants
import { graphTypes } from '../../constants';
// Utils
import { getChartName, getChartTitle } from '../../utils';
// Store
import { getFormValues } from '../../selectors';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  chartData: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  activeCellType: PropTypes.string,
  geneName: PropTypes.string,
  switchOpenModal: PropTypes.func,
  changeBeesWarmTransform: PropTypes.func,
  beesWarmTransform: PropTypes.string,
  geneId: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  setTissuesSelectionMode: PropTypes.func,
  unsetTissuesSelectionMode: PropTypes.func,
  tissuesSelectionMode: PropTypes.bool,
  setSelectedCells: PropTypes.func,
  selectedCells: PropTypes.instanceOf(Array),
  getSamplesDataForCsvExport: PropTypes.func,
  csvName: PropTypes.string,
  csvDownloadedSamplesLoading: PropTypes.bool,
  clearSelectedTissues: PropTypes.func,
  showInfoText: PropTypes.bool,
  toggleInfoText: PropTypes.func,
};

class CellTypeExpressionChart extends React.Component {
  renderRNALinks = () => {
    const infoLinks = [
      { text: 'Gene Expression Omnibus', link: 'https://www.ncbi.nlm.nih.gov/geo/' },
      { text: 'BLUEPRINT', link: 'https://www.blueprint-epigenome.eu/' },
      { text: 'DICE database', link: 'https://dice-database.org/' },
    ];

    return infoLinks.map(item => (
      <li className="external-links__item" key={item.text}>
        {`${item.text}: `}
        <a className="link" href={item.link} target="blank">
          {item.link}
        </a>
      </li>
    ));
  };

  formatOptionLabel = ({ value, label, median }) => (
    <div className="healthy-cells-option" key={value}>
      <div className="healthy-cells-option__Label">
        {label}
      </div>
      <div className="healthy-cells-option__median">
        {median}
      </div>
    </div>
  );

  render() {
    const {
      id,
      data,
      type,
      chartData,
      geneId,
      options,
      activeCellType,
      geneName,
      switchOpenModal,
      showInfoText,
      toggleInfoText,
      changeBeesWarmTransform,
      beesWarmTransform,
      loading,
      showTooltip,
      hideTooltip,
      setShortConceptCardId,
      selectedCells,
      setSelectedCells,
      unsetTissuesSelectionMode,
      tissuesSelectionMode,
      setTissuesSelectionMode,
      getSamplesDataForCsvExport,
      csvName,
      clearSelectedTissues,
      csvDownloadedSamplesLoading,
    } = this.props;

    return (
      <div
        id="bulk-rna-expression__health_cell"
        className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
      >
        <span className="gene-expression-block-title-main">
          RNA expression in healthy cells
        </span>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          onClick={() => toggleInfoText('CELL_TYPE')}
        />
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            RNA expression in healthy cells is based on the Euretos’ cell type expression library, which is an annotated
            collection of mRNA-sequencing samples extracted from public sources (Gene Expression Omnibus, BLUEPRINT, DICE
            database). The cell type library focuses on isolated, primary cell types from individuals without a defined
            pathological state (healthy, normal, and control samples). A minimum of two samples is collected for each cell
            type. The collection contains some pooled samples and samples from cultured primary cells.
            <h4 className="extarnal-links__title title4">References:</h4>
            <ul className="external-links__list gene-details-info__rna-links">
              {this.renderRNALinks()}
            </ul>
          </div>
        }
        {
          data && options.length > 0 &&
          <div className="gene-expression__block">
            <form className="gene-expression__select">
              <Field
                name="cellTypes"
                component={FormSelect}
                options={options}
                placeholder="Select option"
                bordered={true}
                onChange={clearSelectedTissues}
                formatOptionLabel={this.formatOptionLabel}
              />
            </form>
            {
              activeCellType &&
              <div className="gene-expression-diagram">
                <BeesWarmChart
                  id={id}
                  data={data}
                  geneId={geneId}
                  type={type}
                  geneName={geneName}
                  showTooltip={showTooltip}
                  hideTooltip={hideTooltip}
                  chartData={chartData.filter(d => d.category === activeCellType)}
                  activeCellType={activeCellType}
                  switchOpenModal={switchOpenModal}
                  beesWarmTransform={beesWarmTransform}
                  chartTitle={getChartTitle(graphTypes.CELL_TYPE, geneName)}
                  chartName={getChartName(graphTypes.CELL_TYPE)}
                  setShortConceptCardId={setShortConceptCardId}
                  changeBeesWarmTransform={changeBeesWarmTransform}
                  selectedCells={selectedCells}
                  setSelectedCells={setSelectedCells}
                  unsetTissuesSelectionMode={unsetTissuesSelectionMode}
                  tissuesSelectionMode={tissuesSelectionMode}
                  setTissuesSelectionMode={setTissuesSelectionMode}
                  getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                  csvName={csvName}
                  csvDownloadedSamplesLoading={csvDownloadedSamplesLoading}
                />
              </div>
            }
          </div>
        }
        <NoData
          show={!loading && data && options.length === 0}
          customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
        />
        <Spinner
          isLoading={loading}
          containerClassName="row text-center"
        />
      </div>
    );
  }
}

CellTypeExpressionChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    activeCellType: getFormValues(state, 'cellTypes'),
  };
}

const composition = compose(
  reduxForm({
    form: 'CELL_TYPES_SELECT',
  }),
  connect(mapStateToProps)
);

export default composition(CellTypeExpressionChart);
