import { handleActions, createAction } from 'redux-actions';
import { fromJS } from 'immutable';

export const shortConceptCardRequestedAction = createAction('SHORT_CONCEPT_CARD_REQUESTED');
export const shortConceptCardSucceededAction = createAction('SHORT_CONCEPT_CARD_SUCCEEDED');
export const shortConceptCardSetIdAction = createAction('SHORT_CONCEPT_CARD_SET_ID');
export const shortConceptCardSetPrevIdAction = createAction('SHORT_CONCEPT_CARD_SET_PREV_ID');
export const shortConceptCardLoadingAction = createAction('SHORT_CONCEPT_CARD_LOADING');
export const shortConceptCardErrorAction = createAction('SHORT_CONCEPT_CARD_ERROR');
export const shortConceptCardClearStateAction = createAction('SHORT_CONCEPT_CARD_CLEAR_STATE');

const initialState = fromJS({
  data: null,
  conceptId: null,
  prevConceptId: null,
  error: '',
  loading: false,
});

const reducer = handleActions({
  [shortConceptCardSucceededAction]: (state, { payload }) =>
    state.merge({
      data: fromJS(payload),
      loading: false,
      error: '',
    }),
  [shortConceptCardSetIdAction]: (state, { payload }) =>
    state.update('conceptId', () => payload),
  [shortConceptCardSetPrevIdAction]: (state, { payload }) =>
    state.update('prevConceptId', () => payload),
  [shortConceptCardLoadingAction]: (state, { payload }) =>
    state.update('loading', () => payload),
  [shortConceptCardErrorAction]: (state, { payload }) =>
    state.update('error', () => payload),
  [shortConceptCardClearStateAction]: state =>
    state.merge(initialState),
}, initialState);

export default reducer;
