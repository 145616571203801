import React from 'react';

import { measureOptions } from '../../constants';

import SingleTargetForCancerForm from './SingleTargetForCancerForm';
import SingleTargetForCancerContent from './SingleTargetForCancerContent';

import './index.scss';

const initialValues = {
  normalTissueMeasureType: measureOptions[0].type,
  measureType: measureOptions[2].type,
};

export default class SingleTargetForCancer extends React.Component {
  render() {
    return (
      <div className="algorithm">
        <div className="algorithm__wrap">
          <div className="algorithm__container">
            <SingleTargetForCancerForm initialValues={initialValues} />
          </div>
        </div>
        <div className="algorithm__container">
          <SingleTargetForCancerContent />
        </div>
      </div>
    );
  }
}
