import { createSelector } from 'reselect';
import { intermediateConceptsSortingTypes } from '../enum';

const intermediateConceptsData = state => state.getIn(['intermediateConceptsReducer', 'data']);
const intermediateConceptsAllData = state => state.getIn(['intermediateConceptsReducer', 'allData']);
const intermediateConceptsLoading = state => state.getIn(['intermediateConceptsReducer', 'loading']);
const intermediateConceptsError = state => state.getIn(['intermediateConceptsReducer', 'error']);
const intermediateConceptsFilters = state => state.getIn(['intermediateConceptsReducer', 'filters']);
const intermediateConceptsOptions = state => state.getIn(['intermediateConceptsReducer', 'options']);
const intermediateConceptsAllSelected = state => state.getIn(['intermediateConceptsReducer', 'allSelected']);
const filterIntermediateConceptsValue = state => state.getIn(['intermediateConceptsReducer', 'filterValue']);
const intermediateConceptsBeFilters = state => state.getIn(['intermediateConceptsReducer', 'beFilters']);
const intermediateConceptsBeFiltersType = state => state.getIn(['intermediateConceptsReducer', 'beFiltersType']);
const intermediateConceptsGeneGenomeConcepts = state => state.getIn(['intermediateConceptsReducer', 'geneGenomeConcepts']);
const intermediateConceptsSorting = state => state.getIn(['intermediateConceptsReducer', 'sorting']);

export const getIntermediateConceptsAllDataSelector = createSelector(
  intermediateConceptsAllData,
  data => data && data.toJS()
);

export const getIntermediateConceptsFilteredDataSelector = createSelector(
  intermediateConceptsData,
  intermediateConceptsFilters,
  filterIntermediateConceptsValue,
  intermediateConceptsGeneGenomeConcepts,
  intermediateConceptsSorting,
  (_data, _filters, searchFilter, geneGenomeConcepts, sorting) => {
    if (!_data) return _data;
    const data = _data.toJS();
    const filters = _filters.toJS();
    const { sortBy, sortDirection } = sorting.toJS();
    const geneConcepts = geneGenomeConcepts && geneGenomeConcepts.toJS();

    const filteredConcepts = data.reduce((filtered, c) => {
      const { concept } = c;

      if (geneConcepts && geneConcepts.indexOf(concept.id) === -1) {
        return filtered;
      }

      if (filters.category && !concept.semanticTypes.includes(filters.category.value)) {
        return filtered;
      }

      if (searchFilter && !concept.name.toLowerCase().includes(searchFilter.toLowerCase())) {
        return filtered;
      }

      filtered.push(c);
      return filtered;
    }, []);

    const comparator = (a, b) => {
      if (sortBy === intermediateConceptsSortingTypes.NAME) return a.concept.name.localeCompare(b.concept.name);
      if (sortBy === intermediateConceptsSortingTypes.COUNT) return a.count - b.count;
      return a.conceptsCount.find(e => e.name === sortBy).count - b.conceptsCount.find(e => e.name === sortBy).count;
    };

    const reverseComp = (a, b) => -comparator(a, b);

    return filteredConcepts.sort(sortDirection === 'DESC' ? reverseComp : comparator);
  }
);

export const getIntermediateConceptsLoadingSelector = createSelector(
  intermediateConceptsLoading,
  data => data
);

export const getIntermediateConceptsErrorSelector = createSelector(
  intermediateConceptsError,
  data => data
);

export const getIntermediateConceptsFiltersSelector = createSelector(
  intermediateConceptsFilters,
  data => data && data.toJS()
);

export const getIntermediateConceptsOptionsSelector = createSelector(
  intermediateConceptsOptions,
  data => data && data.toJS()
);

export const getIntermediateConceptsAllSelectedSelector = createSelector(
  intermediateConceptsAllSelected,
  data => data
);

export const getFilterIntermediateConceptsValueSelector = createSelector(
  filterIntermediateConceptsValue,
  data => data
);

export const getIntermediateConceptsBeFiltersSelector = createSelector(
  intermediateConceptsBeFilters,
  data => data && data.toJS()
);

export const getIntermediateConceptsBeFiltersTypeSelector = createSelector(
  intermediateConceptsBeFiltersType,
  data => data
);

export const getIntermediateConceptsSortingSelector = createSelector(
  intermediateConceptsSorting,
  data => data && data.toJS()
);
