import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import GeneDiseaseBarChart from '../GeneDiseaseBarChart/GeneDiseaseBarChart';
// Utils
import { barChartDiagramRows } from './enum';
// Styles
import './GeneDiseaseExpressionSection.css';

const propTypes = {
  tissuesData: PropTypes.instanceOf(Object),
  gene: PropTypes.instanceOf(Object),
  diseaseName: PropTypes.string,
  highlightedTissuesIds: PropTypes.instanceOf(Array),
  geneDiseaseName: PropTypes.string,
};

class GeneDiseaseExpressionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
    };
  }

  render() {
    const {
      tissuesData,
      highlightedTissuesIds,
      gene,
      diseaseName,
      geneDiseaseName,
    } = this.props;

    const geneName = gene ? gene.name : '';

    return (
      <div id="gene-disease-expression">
        <div>
          <div className="gene-disease-section-title">
            <span>Expression</span>
            <span
              role="presentation"
              className="fa fa-info-circle icon"
              onClick={() => {
                this.setState({
                  showTitleHelperText: !this.state.showTitleHelperText,
                });
              }}
            />
            <div
              className={
                classnames(
                  'gene-disease-section-title-helper-text',
                  { 'gene-disease-section-title-helper-text--visible': this.state.showTitleHelperText }
                )
              }
            >
              This section shows RNA and protein expression of {geneName} in healthy tissues.
              RNA expression is available from GTEx, TCGA and Beat-AML, In the expression graphs, the top 3 tissues that
              are associated with {diseaseName} are highlighted. These are the tissues that are mentioned most in
              literature in conjunction
              with {diseaseName}. If the top 1 tissue has 10 times (or more) more publications than tissues
              2 and 3 combined, only the top 1 tissue is highlighted.
            </div>
          </div>
          <div className="gene-disease-section-body">
            <div>
              {geneName} has the following RNA and protein expression patterns in healthy tissues.
              The top 3 tissues associated with {diseaseName} are highlighted. See the information panel above for more details.
            </div>
            <div className="gene-expression-block-title">
              <span className="gene-expression-block-title-main">RNA Expression </span>
              <span className="gene-expression-block-title-sub">(TPM)</span>
            </div>
            <div className="gene-expression-block-diagrams">
              {
                highlightedTissuesIds && barChartDiagramRows.firstDiagramRow.map((item, index) => (
                  <div className="gene-expression-block-diagram" key={index}>
                    <div className="gene-expression-block-diagram-title" title={tissuesData[item.namesListKey]}>
                      { item.name } ({ tissuesData[item.countKey] })
                    </div>
                    <div className="gene-expression-diagram">
                      <GeneDiseaseBarChart
                        highlightedTissuesIds={highlightedTissuesIds}
                        data={tissuesData[item.dataKey]}
                        id={item.id}
                        chapterName="RNA Expression"
                        sourceName={item.name}
                        geneDiseaseName={geneDiseaseName}
                        diseaseName={diseaseName}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="gene-expression-block-diagrams">
              {
                highlightedTissuesIds && barChartDiagramRows.secondDiagramRow.map((item, index) => (
                  <div className="gene-expression-block-diagram" key={index}>
                    <div className="gene-expression-block-diagram-title" title={tissuesData[item.namesListKey]}>
                      { item.name } ({ tissuesData[item.countKey] })
                    </div>
                    <div className="gene-expression-diagram">
                      <GeneDiseaseBarChart
                        highlightedTissuesIds={highlightedTissuesIds}
                        data={tissuesData[item.dataKey]}
                        id={item.id}
                        chapterName="RNA Expression"
                        sourceName={item.name}
                        geneDiseaseName={geneDiseaseName}
                        diseaseName={diseaseName}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="gene-expression-block-title">
              <span className="gene-expression-block-title-main">Protein abundance </span>
              <span className="gene-expression-block-title-sub">{`(${tissuesData.hpmTissuesCount})`}</span>
            </div>
            <div className="gene-expression-block-diagrams">
              {
                highlightedTissuesIds && barChartDiagramRows.thirdDiagramRow.map((item, index) => (
                  <div className="gene-expression-block-diagram" key={index}>
                    <div className="gene-expression-block-diagram-title" title={tissuesData[item.namesListKey]}>
                      { item.name } ({ tissuesData[item.countKey] })
                    </div>
                    <div className="gene-expression-diagram">
                      <GeneDiseaseBarChart
                        rotateXAxis={item.rotateXAxis}
                        highlightedTissuesIds={highlightedTissuesIds}
                        data={tissuesData[item.dataKey]}
                        id={item.id}
                        chapterName="Protein abundance"
                        sourceName={item.name}
                        geneDiseaseName={geneDiseaseName}
                        diseaseName={diseaseName}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GeneDiseaseExpressionSection.propTypes = propTypes;

export default GeneDiseaseExpressionSection;

