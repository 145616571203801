import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { geneDiseaseSetChapterAction, loadNextChapterAction } from '../GeneDiseasePage/actions';

import {
  GENE_DISEASE_METABOLITES_INIT_REQUESTED,
  GENE_DISEASE_METABOLITES_INIT_SUCCEEDED,
  GENE_DISEASE_METABOLITES_INIT_FAILED,
} from './constants';

function* sagaMetabolites(action) {
  try {
    const post = action.data;
    const response = yield call(Api.geneDiseaseMetabolites, { post });
    yield put({ type: GENE_DISEASE_METABOLITES_INIT_SUCCEEDED, data: response.data });
    const {
      associatedMetabolitesNbrs,
      chords,
      data,
      fullSetABC,
      publications,
    } = response.data;

    if ((associatedMetabolitesNbrs && associatedMetabolitesNbrs.length)
      || (chords && chords.length)
      || (data && data.length)
      || (fullSetABC && fullSetABC.length)
      || (publications && publications.length)
    ) {
      yield put(geneDiseaseSetChapterAction('metabolites'));
    }
    yield put(loadNextChapterAction('phenotypes'));
  } catch (e) {
    yield put({ type: GENE_DISEASE_METABOLITES_INIT_FAILED, message: e.message });
    yield put(loadNextChapterAction('phenotypes'));
  }
}

function* geneDiseaseAnimalModel() {
  yield takeLatest(GENE_DISEASE_METABOLITES_INIT_REQUESTED, sagaMetabolites);
}

export default geneDiseaseAnimalModel;
