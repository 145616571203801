import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '../../Api/Api';

import {
  GENE_DETAILS_REQUESTED,
  GENE_DETAILS_SUCCEEDED,
  GENE_DETAILS_FAILED,
} from './constants';
import {
  loadNextChapterAction,
} from './actions';

function* details(action) {
  try {
    const id = action.data;
    const response = yield call(Api.geneDetailDefinition, { id });
    yield put({ type: GENE_DETAILS_SUCCEEDED, data: response.data });
    yield put(loadNextChapterAction('literature-search'));
  } catch (e) {
    yield put({ type: GENE_DETAILS_FAILED, message: e.message });
    yield put(loadNextChapterAction('literature-search'));
  }
}


function* geneDetailsSaga() {
  yield takeLatest(GENE_DETAILS_REQUESTED, details);
}

export default geneDetailsSaga;
