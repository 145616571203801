export const PubDetailsChaptersEnum = {
  publicationInfo: 'publicationInfo',
  publicationCitations: 'publicationCitations',
  publicationTriples: 'publicationTriples',
};

export const SimilarPubsSortingEnum = {
  DATE: 'DATE',
  SCORE: 'SCORE',
};

export const SimilarPubsOptions = [
  {
    value: SimilarPubsSortingEnum.SCORE,
    label: 'Score',
  },
  {
    value: SimilarPubsSortingEnum.DATE,
    label: 'Date',
  },
];

export const CONCEPTS_PAGE_SIZE = 10;

export const categories = {
  'Genes & Molecular Sequences': { color: 'DA6C00', class: 'genes_molecular_sequences' },
  'Disorders': { color: '6B0000', class: 'disorders' },
  'Phenomena': { color: 'B6DBFF', class: 'phenomena' },
  'Chemicals & Drugs': { color: 'CCCCCC', class: 'chemicals_drugs' },
  'Occupations': { color: '6DB6FF', class: 'occupations' },
  'Concepts & Ideas': { color: 'FF6DB6', class: 'concepts_ideas' },
  'Physiology': { color: '009191', class: 'physiology' },
  'Anatomy': { color: 'FEFE6C', class: 'anatomy' },
  'Geographic Areas': { color: 'B66DFF', class: 'geographic_areas' },
  'Objects': { color: '006DDB', class: 'objects' },
  'Living Beings': { color: '480091', class: 'living_beings' },
  'Organizations': { color: '914800', class: 'organizations' },
};
