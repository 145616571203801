import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';

// Icons
import { BiEditAlt } from 'react-icons/bi';
// Components
import Loader from '../../../../common/Loader/Loader';
import Button from '../../../../common/Buttons/Button/Button';
import Table from '../../../../common/SimpleTable/Table';
import AmbiguousConceptsTable from '../AmbiguousConceptsTable/AmbiguousConceptsTable';
import UnresolvedConceptsTable from '../UnresolvedConceptsTable/UnresolvedConceptsTable';
import ComplexSetSettingsModal from '../../../ComplexSetSettingsModal/ComplexSetSettingsModal';
import Pagination from '../../../../common/Pagination/Pagination';
import ButtonCircle from '../../../../common/Buttons/ButtonCircle/ButtonCircle';
import ConceptSearchModal from '../../../../Concept/ConceptSearchModal/ConceptSearchModal';
// Store
import * as s from '../../store/selectors';
import * as a from '../../store/actions';
import { getUploadFileNameSelector } from '../../../../common/UploadFile/store/selectors';
import { sortSaveAsSetConceptsAction } from '../../store/actions';
import { getCerebrumST } from '../../../../Header/selectors';
import { setSearchConceptInputValueAction } from '../../../../Concept/ConceptSearchModal/store/actions';
// Styles
import './styles.scss';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  pagginatedConcepts: PropTypes.instanceOf(Array),
  ambiguousConcepts: PropTypes.instanceOf(Array),
  unresolvedConcepts: PropTypes.instanceOf(Array),
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  updateConcepts: PropTypes.func,
  fileName: PropTypes.string,
  complexSetContent: PropTypes.instanceOf(Object),
  complexSetFileContent: PropTypes.instanceOf(Object),
  showComplexSetDialog: PropTypes.bool,
  closeCompexDialog: PropTypes.func,
  setComplexData: PropTypes.func,
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
  withReset: PropTypes.bool,
  disabled: PropTypes.bool,
  pages: PropTypes.instanceOf(Object),
  pagginate: PropTypes.func,
  sortConcepts: PropTypes.func,
  semTypes: PropTypes.instanceOf(Object),
  setSearchConceptInputValue: PropTypes.func,
  withFileUpload: PropTypes.bool,
};

const SaveAsSetContent = (props) => {
  const {
    concepts,
    ambiguousConcepts,
    unresolvedConcepts,
    updateConcepts,
    sorting,
    loading,
    fileName,
    complexSetContent,
    complexSetFileContent,
    showComplexSetDialog,
    closeCompexDialog,
    setComplexData,
    onSubmit,
    reset,
    withReset,
    disabled = false,
    pages,
    pagginate,
    pagginatedConcepts,
    sortConcepts,
    semTypes,
    withFileUpload,
    setSearchConceptInputValue,
  } = props;

  const [showEditRowModal, setShowEditRowModal] = useState({ data: null, show: false });

  const mergeConcepts = useCallback((mappedGene, rowData) => ({
    ...rowData,
    mappedGenes: [{
      id: mappedGene.id,
      brainName: mappedGene.name,
      semanticTypes: mappedGene.semanticType.map(id => ({
        id,
        name: semTypes[id],
      })),
    }],
  }), [semTypes]);

  const removeConcept = useCallback((id) => {
    updateConcepts(concepts.filter(c => c.id !== id));
  }, [concepts, updateConcepts]);

  const solveAmbiguityFunction = useCallback(({ itemId, id }) => {
    const newConcepts = concepts.map((c) => {
      if (c.id === itemId) {
        return ({
          ...c,
          mappedGenes: c.mappedGenes.filter(d => d.id !== id),
        });
      }
      return c;
    });
    updateConcepts(newConcepts);
  }, [concepts, updateConcepts]);

  const resolveConceptFunction = useCallback(({ item, rowId }) => {
    const { id, name } = item;
    const newConcepts = concepts.map((c) => {
      if (c.id === rowId) {
        return ({
          ...c,
          matchStatus: 'match',
          mappedGenes: [{ id, name }],
        });
      }
      return c;
    });
    updateConcepts(newConcepts);
  }, [concepts, updateConcepts]);

  const handleEditRow = useCallback((newConcept) => {
    const concept = mergeConcepts(newConcept, showEditRowModal.data);
    const filtered = concepts.filter(c => c.id !== showEditRowModal.data.id);
    filtered.push(concept);
    updateConcepts(filtered);
  }, [concepts, updateConcepts, showEditRowModal.data, mergeConcepts]);

  const columns = useMemo(() => [
    {
      dataKey: 'geneName',
      label: 'Source identifier',
      width: 45,
    },
    {
      dataKey: 'mappedGenes',
      label: 'Name',
      sortValueMapper: item => (
        item.get('mappedGenes').size && item.get('mappedGenes').first() ?
          item.get('mappedGenes').first()
            .get('brainName') : ''
      ),
      cellRenderer: ({ rowData }) => (
        rowData.mappedGenes.map((g, i) => (
          <div className='table-cell-row' key={`${g.id}_${i}`}>
            <span>{g.name || g.brainName}</span>
            {
              withFileUpload && rowData.mappedGenes.length === 1 &&
              <ButtonCircle
                size={30}
                icon={<BiEditAlt size={15} color="#4b3f63" />}
                onClick={() => {
                  setSearchConceptInputValue(g.name || g.brainName);
                  setShowEditRowModal({ data: rowData, show: true });
                }}
              />
            }
          </div>
        ))
      ),
      width: 45,
    },
    {
      dataKey: 'id',
      disableSort: true,
      cellRenderer: ({ rowData }) => (
        <button
          type="button"
          onClick={() => removeConcept(rowData.id)}
          className="btn-icon btn-icon-danger"
        >
          <i className="fa fa-trash" />
        </button>
      ),
      width: 10,
      className: 'table-wrap__cell_center',
    },
  ], [removeConcept, setSearchConceptInputValue]);

  useEffect(() => {
    if (withReset) {
      return reset;
    }
  }, []);

  return (
    <div className="save-as-set-content container">
      {
        ambiguousConcepts.length > 0 &&
        <AmbiguousConceptsTable
          items={ambiguousConcepts}
          removeConceptFunction={removeConcept}
          solveAmbiguityFunction={solveAmbiguityFunction}
        />
      }
      {
        unresolvedConcepts.length > 0 &&
        <UnresolvedConceptsTable
          items={unresolvedConcepts}
          removeConceptFunction={removeConcept}
          resolveConceptFunction={resolveConceptFunction}
        />
      }
      {
        concepts &&
        concepts.length > 0 &&
        <>
          <Pagination
            {...pages}
            onPageChange={({ selected }) => pagginate(selected)}
          />
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Table
                data={pagginatedConcepts}
                columnsData={columns}
                width={width}
                sorting={sorting}
                sortCallback={sortConcepts}
              />
            )}
          </AutoSizer>
        </>
      }
      <Loader isLoading={loading} />
      {
        showComplexSetDialog &&
        complexSetContent &&
        complexSetContent.config &&
        <ComplexSetSettingsModal
          isFormHiddenInCreateMode={true}
          complexSetContent={complexSetContent}
          fileContents={complexSetFileContent}
          fileName={fileName}
          cancelButtonHandler={closeCompexDialog}
          submitButtonHandler={setComplexData}
        />
      }
      {
        !!onSubmit &&
        <div className="save-as-set-content__controls">
          <Button
            text="Apply"
            onClick={() => onSubmit(concepts)}
            disabled={disabled}
          />
        </div>
      }
      <ConceptSearchModal
        isOpen={showEditRowModal.show}
        closeCb={() => setShowEditRowModal({ id: null, show: false })}
        onSubmit={handleEditRow}
        onSubmitBtnText="Select concept"
        resetOnClose={true}
      />
    </div>
  );
};

SaveAsSetContent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concepts: s.getSaveAsSetConceptsSelector(state),
    pagginatedConcepts: s.getSaveAsSetPagginatedConceptsSelector(state),
    ambiguousConcepts: s.getAmbiguousConceptsSelector(state),
    unresolvedConcepts: s.getUnresolvedConceptsSelector(state),
    complexSetContent: s.getSaveAsSetComplexDataSelector(state),
    complexSetFileContent: s.getSaveAsSetComplexFileSelector(state),
    showComplexSetDialog: s.getSaveAsSetComplexDialogSelector(state),
    fileName: getUploadFileNameSelector(state),
    sorting: s.getSaveAsSetSortingSelector(state),
    loading: s.getSaveAsSetLoadingSelector(state),
    pages: s.getSaveAsSetPagesSelector(state),
    semTypes: getCerebrumST(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateConcepts(data) {
      dispatch(a.updateSaveAsSetConceptsAction(data));
    },
    closeCompexDialog() {
      dispatch(a.toggleSaveAsSetComplexDialogAction(false));
    },
    setComplexData(data) {
      dispatch(a.setSaveAsSetComplexDataAction(data));
    },
    reset() {
      dispatch(a.resetSaveAsSetDataAction());
    },
    pagginate(data) {
      dispatch(a.pagginateSaveAsSetConceptsAction(data));
    },
    sortConcepts(data) {
      dispatch(sortSaveAsSetConceptsAction(data));
    },
    setSearchConceptInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveAsSetContent);
