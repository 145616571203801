import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form/immutable';

// Components
import Input from '../../../../common/Inputs/Input/Input';
import Error from '../../../../common/Error/Error';
import Loader from '../../../../common/Loader/Loader';
import NoData from '../../../../common/NoData/NoData';
import ConceptsSearchItem from '../ConceptsSearchItem/ConceptsSearchItem';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';
import { getCerebrumST } from '../../../../Header/selectors';
// Constants
import { CONCEPT_SEARCH_FORM_FIELD } from '../../../ConceptSearchForm/constants';
// Styles
import './styles.scss';
import classNames from 'classnames';
import {PortalActions} from '../../../../Search/enums';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  suggestions: PropTypes.instanceOf(Array),
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  closeCb: PropTypes.func,
  semanticTypes: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
  formName: PropTypes.string,
  activeCategories: PropTypes.instanceOf(Array),
  resetConceptSearchField: PropTypes.func,
  threePanels: PropTypes.bool,
  selectedConceptId: PropTypes.number,
  action: PropTypes.string,
};

const ConceptsSearchContent = (props) => {
  const {
    concepts,
    suggestions,
    inputValue,
    setInputValue,
    error,
    loading,
    closeCb,
    semanticTypes,
    onSubmit,
    formName,
    resetConceptSearchField,
    activeCategories,
    threePanels,
    selectedConceptId,
    action,
  } = props;

  const submitSearch = useCallback((concept) => {
    if (formName && !threePanels) {
      resetConceptSearchField(formName);
    }
    onSubmit(concept);
    if (!threePanels) closeCb();
    else {
      //highlight selection
    }
  }, [onSubmit, closeCb, resetConceptSearchField, formName]);

  const handleInputChange = useCallback(({ target: { value } }) => {
    setInputValue(value);
  }, [setInputValue]);

  useEffect(() => {
    if (inputValue) setInputValue(inputValue);
  }, []);

  const placeholderText = () => {
    switch (activeCategories && activeCategories[0]) {
      case 'T902':
      case 'T047':
        return 'Type disease name';
      case 'T028':
        return 'Type gene name';
      default:
        return 'Type concept name';
    }
  };

  const twoOrThrePanelsClass = classNames({
    'concepts-search-content': true,
    'concepts-search-content__3blocks': threePanels,
  });

  return (
    <div className={twoOrThrePanelsClass}>
      <h3 className="concepts-search-content__title title3">
        Concept selection
      </h3>
      <Input
        value={inputValue}
        placeholder={placeholderText()}
        onChange={handleInputChange}
        customWrapClassName="concepts-search-content__input"
      />
      <div className="concepts-search-content__wrap">
        {
          !loading && concepts && concepts.length > 0 &&
          concepts.map(c => (
            <ConceptsSearchItem
              key={c.id}
              concept={c}
              semanticTypes={semanticTypes}
              submitSearch={submitSearch}
              selected={c.id === selectedConceptId}
            />
          ))
        }
        {
          !loading && suggestions && suggestions.length > 0 &&
          <div className="concepts-search-content__suggestions">
            Suggestions:
            {
              suggestions.map((s, i) => (
                <div
                  key={s.id}
                  onClick={() => setInputValue(s.name)}
                  className="link concepts-search-content__suggestion"
                >
                  {i === suggestions.length - 1 ? `${s.name}.` : `${s.name},`}
                </div>
              ))
            }
          </div>
        }
        {
          (action === PortalActions.ANTIBODY_FILTER
            && (!concepts || concepts.length === 0)
            && inputValue && inputValue.length > 1) &&
          <div
            key="free-search-id"
            onClick={() => submitSearch({name: inputValue})}
            className="link concepts-search-content__suggestion"
          >
            {`Search as text "${inputValue}".`}
          </div>
        }
        <Loader isLoading={loading}/>
        <Error show={!loading && !!error} error={error}/>
        <NoData
          show={
            !loading &&
            (concepts && concepts.length === 0) &&
            (suggestions && suggestions.length === 0)
          }
          message="There is no data to display: please check spelling of your search input"
        />
        <NoData
          show={
            !loading &&
            (inputValue && inputValue.length < 2)
          }
          message="Type at least 2 symbols to search the concept"
        />
      </div>
    </div>
  );
};

ConceptsSearchContent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concepts: SELECTORS.getSearchConceptsDataSelector(state),
    loading: SELECTORS.getSearchConceptsLoadingSelector(state),
    error: SELECTORS.getSearchConceptsErrorSelector(state),
    suggestions: SELECTORS.getSearchConceptsSuggestionsSelector(state),
    semanticTypes: getCerebrumST(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputValue(data) {
      dispatch(ACTIONS.setSearchConceptInputValueAction(data));
    },
    resetConceptSearchField(formName) {
      dispatch(change(formName, CONCEPT_SEARCH_FORM_FIELD, ''));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConceptsSearchContent);
