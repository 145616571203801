import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DragDropContext } from 'react-beautiful-dnd';
// Components
import Button from '../../../common/Buttons/Button/Button';
import Sets from '../../../Sets/SetsList/Sets';
import FormInput from '../../../common/FormElements/FormInput';
import Loader from '../../../common/Loader/Loader';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import FormSelect from '../../../common/FormElements/FormSelect';
import SingleTargetForCancerColumn from './Components/SingleTargetForCancerColumn';
import SingleTargetForCancerTumorsColumn from './Components/SingleTargetForCancerTumorsColumn';
import SaveAsSetModal from '../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
// Icons
import { MdOutlineFileUpload } from 'react-icons/md';
// Store
import { getTargetCandidatesPairsDataAction } from '../../store/reducer';
import * as ACTIONS from './reducer';
import * as SELECTORS from './selectors';
// Constants
import { measureOptions } from '../../constants';
// Utils
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';

const propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  resetStore: PropTypes.func,
  updateChildList: PropTypes.func,
  change: PropTypes.func,
  getSingleTargetForCancerTissues: PropTypes.func,
  getTargetCandidatesPairsData: PropTypes.func,
  updateColumns: PropTypes.func,
  tissues: PropTypes.instanceOf(Object),
  tissuesLoading: PropTypes.bool,
  columns: PropTypes.instanceOf(Object),
  columnsOrder: PropTypes.instanceOf(Array),
  tumorTissues: PropTypes.instanceOf(Object),
  setColumnsSortDirection: PropTypes.func,
  checkTissue: PropTypes.func,
  updateTissues: PropTypes.func,
  updateTumorTissues: PropTypes.func,
  checkAllTissues: PropTypes.func,
  error: PropTypes.string,
  toggleCheckALlTumorTissues: PropTypes.func,
  sensitiveTissues: PropTypes.instanceOf(Object),
  tumorTissuesColumns: PropTypes.instanceOf(Object),
  allTissues: PropTypes.instanceOf(Object),
  parentsListOrder: PropTypes.instanceOf(Array),
  setTumorTissuesSortDirection: PropTypes.func,
  checkedTissuesForRequest: PropTypes.instanceOf(Object),
  checkedTumorTissuesWithLabel: PropTypes.instanceOf(Object),
};

class SingleTargetForCancerForm extends React.Component {
  state = {
    selectSetPopup: false,
    uploadSetPopup: false,
  };

  componentDidMount() {
    const { getSingleTargetForCancerTissues } = this.props;
    getSingleTargetForCancerTissues();
  }

  openSelectSetPopup = () => {
    this.setState({ selectSetPopup: true });
  };

  closeSelectSetPopup = () => {
    this.setState({ selectSetPopup: false });
  };

  openUploadSetPopup = () => {
    this.setState({ uploadSetPopup: true });
  };

  closeUploadSetPopup = () => {
    this.setState({ uploadSetPopup: false });
  };

  selectSetId = ({ projectId, selectedSets }) => {
    const { id, name } = selectedSets[0];
    const { change } = this.props;
    change('setId', id);
    change('setName', name);
    change('projectId', projectId);
  };

  setDataAfterSavingSet = ({ id, name, projectId }) => {
    const { change } = this.props;
    change('setId', id);
    change('setName', name);
    change('projectId', projectId);
  };

  handleSubmit = (prop) => {
    const data = prop.toJS();
    const {
      getTargetCandidatesPairsData,
      checkedTissuesForRequest,
      checkedTumorTissuesWithLabel,
    } = this.props;
    const {
      measureType,
      setId,
      projectId,
      tumorLevel,
      normalTissues,
      normalTissueMeasureType,
      sensitiveTissues,
    } = data;

    let requestData = {
      setId,
      tumorLevel,
      measureType,
      normalTissueMeasureType,
      expressionType: 'ABSOLUTE',
      algorithm: 'SINGLE_TARGET_FOR_CANCER',
      tumorTissues: checkedTumorTissuesWithLabel,
      normalTissues: {
        tissues: checkedTissuesForRequest.normalTissues,
        maxValue: normalTissues,
      },
      sensitiveTissues: {
        tissues: checkedTissuesForRequest.sensitiveTissues,
        maxValue: sensitiveTissues,
      },
    };

    const isProjectTab = checkProjectTab(projectId);
    requestData = isProjectTab ? { ...requestData, projectId } : requestData;

    getTargetCandidatesPairsData(requestData);
  };

  reset = () => {
    const {
      reset,
      resetStore,
      tissues,
      sensitiveTissues,
      parentsListOrder,
      updateChildList,
    } = this.props;
    const resetedTissues = {};
    parentsListOrder.forEach((el) => {
      resetedTissues[el] = {
        ...tissues[el],
        tissues: [...tissues[el].tissues, ...sensitiveTissues[el].tissues],
        tissuesIds: [...tissues[el].tissuesIds, ...sensitiveTissues[el].tissuesIds],
      };
    });
    resetStore();
    reset();
    updateChildList({ parentName: 'normalTissues', data: resetedTissues });
  };

  onDragEnd = (result) => {
    const {
      destination,
      source,
      draggableId,
    } = result;

    const {
      columns,
      updateColumns,
    } = this.props;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumnId = [source.droppableId][0];
    const startColumn = columns[startColumnId];
    const finishColumnId = [destination.droppableId][0];
    const finishColumn = columns[finishColumnId];
    if (startColumn !== finishColumn) {
      const name = draggableId.split('-');
      const tissueName = name[1];
      const number = !Number.isNaN(+name[0]) && +name[0];
      const { tissuesIds: startTissuesIds, tissues: startTissues } = columns[startColumnId].childTissues[tissueName];
      const { tissuesIds: finishTissuesIds, tissues: finishTissues } = columns[finishColumnId].childTissues[tissueName];
      let finishChildTissuesIds;
      let finishChildTissues;
      let payload;

      if (!number) {
        finishChildTissuesIds = [...finishTissuesIds, ...startTissuesIds];
        finishChildTissues = [...finishTissues, ...startTissues];

        payload = {
          tissueName,
          start: {
            id: startColumnId,
            tissuesIds: [],
            tissues: [],
          },
          finish: {
            id: finishColumnId,
            tissuesIds: finishChildTissuesIds,
            tissues: finishChildTissues,
          },
        };
      } else {
        const startChildTissuesIds = startTissuesIds.filter(el => el !== number);
        finishChildTissuesIds = [...finishTissuesIds, number];

        const element = startTissues.find(el => el.id === number);
        const startChildTissues = startTissues.filter(el => el.id !== number);
        finishChildTissues = [...finishTissues, element];

        payload = {
          tissueName,
          start: {
            id: startColumnId,
            tissuesIds: startChildTissuesIds,
            tissues: startChildTissues,
          },
          finish: {
            id: finishColumnId,
            tissuesIds: finishChildTissuesIds,
            tissues: finishChildTissues,
          },
        };
      }
      updateColumns(payload);
    }
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      tissues,
      tumorTissues,
      allTissues,
      tumorTissuesColumns,
      tissuesLoading,
      columns,
      columnsOrder,
      change,
      updateTissues,
      checkTissue,
      updateTumorTissues,
      checkAllTissues,
      toggleCheckALlTumorTissues,
      setColumnsSortDirection,
      setTumorTissuesSortDirection,
      error,
    } = this.props;

    const { selectSetPopup } = this.state;

    const resetBtnClass = classNames(
      'button mr-15',
      { 'button--disabled': pristine || submitting }
    );

    return (
      <form className="algorithm-form__wrap" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="stc-form-title">
          <h3 className="workflows__title title3">
            Single Target for Cancer algorithm
          </h3>
          <p className="workflows__text">
            Calculates a score based on the difference in expression between tumor samples from TCGA and healthy tissues samples from GTEx.
            The higher the score, the bigger the difference in expression. Positive values indicate higher expression in tumor than in healthy tissue.
            Two different threshold values for expression in healthy tissue can be set. Only genes with expression below user defined expression levels in healthy
            tissue are included. One threshold value for expression in tumor can be set. Only genes with expression in at least 1 tumor above the user defined
            tumor threshold are included.
          </p>
        </div>
        {
          !tissuesLoading && tissues &&
          <div className="stc-form">
            <div className="stc-form__select-set-block">
              <div className="stc-form__input">
                <Field
                  name="setName"
                  type="text"
                  placeholder="Click to select gene set"
                  component={FormInput}
                  onClick={this.openSelectSetPopup}
                  autoComplete="off"
                  mandatory={true}
                />
              </div>
              <Button
                onClick={this.openUploadSetPopup}
              >
                <MdOutlineFileUpload
                  size={26}
                  className="upload-file__icon"
                />
              </Button>
              <div>
              </div>
            </div>
            <div className="tissues">
              <div className="tissues__normal">
                <div className="tissues__input">
                  <Field
                    bordered={true}
                    optionKey="type"
                    name="normalTissueMeasureType"
                    component={FormSelect}
                    options={measureOptions}
                    placeholder="Select measure"
                  />
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <div className="tissues-normal">
                    {
                      columnsOrder.map((columnId) => {
                        const column = columns[columnId];
                        const columnTissues = Object.values(column.childTissues);
                        return (
                          <SingleTargetForCancerColumn
                            key={columnId}
                            tissues={columnTissues}
                            column={column}
                            change={change}
                            changeSortDirection={setColumnsSortDirection}
                            updateTissues={updateTissues}
                            checkTissue={checkTissue}
                            checkAllTissues={checkAllTissues}
                            allTissues={allTissues}
                          />
                        );
                      })
                    }
                  </div>
                </DragDropContext>
              </div>
              <div className="tissues__tumor">
                <div className="tissues__input">
                  <Field
                    optionKey="type"
                    bordered={true}
                    name="measureType"
                    component={FormSelect}
                    options={measureOptions}
                    placeholder="Select measure"
                  />
                </div>
                <SingleTargetForCancerTumorsColumn
                  tissuesObj={tumorTissuesColumns}
                  tissues={Object.values(tumorTissues)}
                  checkAll={tumorTissuesColumns.checkAll}
                  updateTissues={updateTumorTissues}
                  checkAllAction={toggleCheckALlTumorTissues}
                  changeSortDirection={setTumorTissuesSortDirection}
                  change={change}
                  allTissues={allTissues}
                />
              </div>
            </div>
            <div className="stc-form__btn-wrap">
              <button
                type="button"
                className={resetBtnClass}
                disabled={pristine || submitting}
                onClick={this.reset}
              >
                Reset
              </button>
              <button
                type="submit"
                className="button button-primary"
              >
                Search
              </button>
            </div>
          </div>
        }
        <Loader isLoading={tissuesLoading} />
        {
          error &&
          <div className="text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        {
          selectSetPopup &&
          <ModalComponent
            isOpen={selectSetPopup}
            closeCb={this.closeSelectSetPopup}
          >
            <Sets
              selectFunction={this.selectSetId}
              closeFunction={this.closeSelectSetPopup}
            />
          </ModalComponent>
        }
        <SaveAsSetModal
          withFileUpload={true}
          uploadOnlySimple={true}
          defaultValues={{
            mainCategories: [{ categoryName: 'Genes', semanticTypeId: 'T028', checked: true }],
            taxonomies: [{ name: 'homo sapiens', checked: true }],
          }}
          uploadOnlyByDefaultCategory={true}
          isOpen={this.state.uploadSetPopup}
          closeCb={this.closeUploadSetPopup}
          onSavingSet={this.setDataAfterSavingSet}
        />
      </form>
    );
  }
}

SingleTargetForCancerForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    tissues: SELECTORS.getNormalDataSelector(state),
    allTissues: SELECTORS.getAllTissues(state),
    checkedTissuesForRequest: SELECTORS.getTissuesDataForRequest(state),
    checkedTumorTissuesWithLabel: SELECTORS.getTumorTissuesWithLabelsSelector(state),
    tumorTissues: SELECTORS.getTumorDataSelector(state),
    sensitiveTissues: SELECTORS.getSensitiveDataSelector(state),
    tissuesLoading: SELECTORS.getSingleTargetForCancerTissuesLoadingSelector(state),
    columns: SELECTORS.getSingleTargetForCancerColumnsSelector(state),
    tumorTissuesColumns: SELECTORS.getTumorTissuesColumnsSelector(state),
    columnsOrder: SELECTORS.getSingleTargetForCancerColumnsOrderSelector(state),
    parentsListOrder: SELECTORS.getSingleTargetForCancerListParentsOrder(state),
    error: SELECTORS.getSingleTargetForCancerTissuesErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSingleTargetForCancerTissues() {
      dispatch(ACTIONS.getSingleTargetForCancerTissuesAction());
    },
    updateChildList(data) {
      dispatch(ACTIONS.updateSingleTargetForCancerTissuesFormColumnAction(data));
    },
    updateColumns(data) {
      dispatch(ACTIONS.updateSingleTargetForCancerTissuesFormColumnsAction(data));
    },
    getTargetCandidatesPairsData(data) {
      dispatch(getTargetCandidatesPairsDataAction(data));
    },
    resetStore() {
      dispatch(ACTIONS.resetSingleTargetForCancerStoreAction());
    },
    setColumnsSortDirection(data) {
      dispatch(ACTIONS.setSingleTargetForCancerTissuesColumnsSortDirectionAction(data));
    },
    checkTissue(data) {
      dispatch(ACTIONS.singleTargetForCancerCheckTissueAction(data));
    },
    updateTumorTissues(data) {
      dispatch(ACTIONS.singleTargetForCancerUpdateTumorTissuesAction(data));
    },
    updateTissues(data) {
      dispatch(ACTIONS.singleTargetForCancerUpdateTissuesAction(data));
    },
    checkAllTissues(data) {
      dispatch(ACTIONS.singleTargetForCancerCheckAllTissuesAction(data));
    },
    setTumorTissuesSortDirection(data) {
      dispatch(ACTIONS.singleTargetForCancerTumorTissuesColumnsSortDirectionAction(data));
    },
    toggleCheckALlTumorTissues() {
      dispatch(ACTIONS.singleTargetForCancerToggleCheckALlTumorTissuesAction());
    },
  };
}

function validate(values) {
  const formValues = values.toJS();
  const errors = {};

  if (!formValues.setName) {
    errors.setName = 'Required';
  }

  if (!formValues.normalTissueMeasureType) {
    errors.normalTissueMeasureType = 'Required';
  }

  if (!formValues.measureType) {
    errors.measureType = 'Required';
  }

  if (!formValues.normalTissues && formValues.normalTissuesContent) {
    errors.normalTissues = 'Fill Expression field';
  }

  if (!formValues.sensitiveTissues && formValues.sensitiveTissuesContent) {
    errors.sensitiveTissues = 'Fill Expression field';
  }

  if (!formValues.tumorLevel && formValues.tumorTissuesContent) {
    errors.tumorLevel = 'Fill Expression field';
  }

  if (!formValues.normalTissuesContent) {
    errors.normalTissues = 'Check at least 1 tissue';
  }

  if (formValues.sensitiveTissues && !formValues.sensitiveTissuesContent) {
    errors.sensitiveTissues = 'Check at least 1 tissue';
  }

  if (!formValues.tumorTissuesContent) {
    errors.tumorLevel = 'Check at least 1 tissue';
  }

  return errors;
}

const composition = compose(
  reduxForm({
    form: 'SINGLE_TARGET_FOR_CANCER_FORM',
    destroyOnUnmount: false,
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(SingleTargetForCancerForm);
