import {
  SET_DIFFERENTIAL_EXPRESSION_CONCEPT,
  SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  SET_DIFFERENTIAL_EXPRESSION_CATEGORY,
} from '../../common/SetAnalysisMethodSelection/constants';

export const setDifferentialExpressionConcept = data => (
  {
    type: SET_DIFFERENTIAL_EXPRESSION_CONCEPT,
    data,
  }
);

export const saveDifferentialExpressionConcept = () => (
  {
    type: SAVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  }
);

export const removeDifferentialExpressionConcept = () => (
  {
    type: REMOVE_DIFFERENTIAL_EXPRESSION_CONCEPT,
  }
);

export const setDifferentialExpressionCategory = data => (
  {
    type: SET_DIFFERENTIAL_EXPRESSION_CATEGORY,
    data,
  }
);
