import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

// Icons
import {
  AiOutlineExclamationCircle,
  AiFillExclamationCircle,
} from 'react-icons/ai';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
// Components
import Loader from '../../../../common/Loader/Loader';
import NoData from '../../../../common/NoData/NoData';
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import IntermediateConceptsFilter from './IntermediateConceptsFilter';
import ButtonCircle from '../../../../common/Buttons/ButtonCircle/ButtonCircle';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Utils
import { intermediateConceptsSortingTypes, intermedieteConceptsFilterInitialValues } from './enum';
import { getSearchFilteredApiRequestPayload } from '../../../../Sets/CreateSet/utils';
import { toggleSortingDirection } from '../../../../Utils/Utils';
// Styles
import './styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  conceptsIds: PropTypes.instanceOf(Array),
  conceptsNames: PropTypes.instanceOf(Array),
  beFilters: PropTypes.instanceOf(Object),
  beFiltersType: PropTypes.string,
  getFilteredConcepts: PropTypes.func,
  getBeFilters: PropTypes.func,
  resetBeFilters: PropTypes.func,
  getIntermediateConcepts: PropTypes.func,
  resetIntermediateConcepts: PropTypes.func,
  setFilters: PropTypes.func,
  resetFilters: PropTypes.func,
  toggleConcept: PropTypes.func,
  toggleAllConcepts: PropTypes.func,
  allSelected: PropTypes.bool,
  getConcepts: PropTypes.func,
  filterValue: PropTypes.string,
  conceptsLimit: PropTypes.number,
  conceptsLimitMessage: PropTypes.string,
  getGeneFilterOptions: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  sortConcepts: PropTypes.func,
  filterConcepts: PropTypes.func,
  getSourceDetailsFilterOptions: PropTypes.func,
  allData: PropTypes.instanceOf(Array),
};

const IntermediateConceptsModal = (props) => {
  const {
    data,
    allData,
    isOpen,
    closeCb,
    loading,
    options,
    setFilters,
    resetFilters,
    allSelected,
    getConcepts,
    conceptsIds,
    conceptsNames,
    toggleConcept,
    toggleAllConcepts,
    conceptsLimit,
    conceptsLimitMessage,
    beFilters,
    beFiltersType,
    getBeFilters,
    resetBeFilters,
    getFilteredConcepts,
    getGeneFilterOptions,
    getIntermediateConcepts,
    resetIntermediateConcepts,
    filterValue,
    sorting: { sortBy, sortDirection },
    sortConcepts,
    getSourceDetailsFilterOptions,
    filterConcepts: filter,
  } = props;

  const listRef = useRef(null);
  const [warning, setWarning] = useState(false);

  useEffect(() => resetIntermediateConcepts, []);

  useEffect(() => {
    if (conceptsIds && conceptsIds.length) {
      const requestData = getSearchFilteredApiRequestPayload(conceptsIds, null, { filter: { publicationSource: 'ALL' }});
      getIntermediateConcepts({ requestData, firstConceptsFetch: true });
    }
  }, [conceptsIds, getIntermediateConcepts]);

  const getSelectedConcepts = useCallback(() => {
    const selectedIds = data.filter(d => d.selected).map(d => d.concept.id);

    if (selectedIds.length > conceptsLimit) {
      setWarning(true);
    } else {
      getConcepts(selectedIds);
      closeCb();
    }
  }, [closeCb, conceptsLimit, data, getConcepts]);

  const toggleConceptHandler = useCallback((index) => {
    const concept = data[index];
    toggleConcept({
      id: concept.concept.id,
      selected: !concept.selected,
    });
    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }, [data, toggleConcept]);

  const toggleAllConceptsHandler = useCallback(() => {
    toggleAllConcepts(!allSelected);
    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }, [allSelected, toggleAllConcepts]);

  const handleSortConcepts = useCallback((sortByValue) => {
    const { sorting } = props;
    sortConcepts({
      sortBy: sortByValue,
      sortDirection: toggleSortingDirection(sorting.sortDirection),
    });
    if (listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  }, [props, sortConcepts]);

  const rowRenderer = useCallback((rowData) => {
    const { index, style } = rowData;
    const { concept, conceptsCount, count = 0 } = data[index];
    const itemClassNames = classNames(
      'intermediate-concepts__item-wrap',
      { 'intermediate-concepts__item-wrap_two': conceptsCount.length === 2 },
      { 'intermediate-concepts__item-wrap_three': conceptsCount.length === 3 },
    );

    return (
      <div
        style={style}
        key={concept.id}
        className="intermediate-concepts__item"
      >
        <div className={itemClassNames}>
          <div className="intermediate-concepts__name">
            <Checkbox
              id={`intermediate-checkbox-${concept.id}`}
              checked={data[index].selected}
              onChange={() => { toggleConceptHandler(index); }}
            />
            <span className="intermediate-concepts__name-text" title={concept.name}>
              {concept.name}
            </span>
          </div>
          {
            conceptsCount.map((c, i) => (
              <div key={`c-${i}`} className="intermediate-concepts__count">
                {c.count}
              </div>
            ))
          }
          <div className="intermediate-concepts__count">
            {count}
          </div>
        </div>
      </div>
    );
  }, [data, toggleConceptHandler]);

  const renderTitle = useMemo(() => {
    return (
      <>
        <div className="intermediate-concepts__title">
          {`Intermediate concepts of ${conceptsNames.join(' and ')}`}
        </div>
        {
          data &&
          <div className="intermediate-concepts__results">
            {`Number of results: ${data.length}`}
          </div>
        }
      </>
    );
  }, [conceptsNames, data]);

  const renderLimit = useMemo(() => {
    if (data && conceptsLimit > 0 && conceptsLimitMessage) {
      const limitClass = classNames(
        'intermediate-concepts__limit',
        { 'intermediate-concepts__limit_error': warning }
      );

      return (
        <div className={limitClass}>
          {
            warning ?
              <AiFillExclamationCircle size={20} color="red" /> :
              <AiOutlineExclamationCircle size={20} color="#4b3f63" />
          }
          <span className="intermediate-concepts__limit-text">
            {conceptsLimitMessage}
          </span>
        </div>
      );
    }
    return null;
  }, [conceptsLimit, conceptsLimitMessage, data, warning]);

  const filterConcepts = useCallback((e) => {
    filter(e.target.value);
  }, [filter]);

  const renderHeader = useMemo(() => {
    if (!data) {
      return null;
    }

    const headerClassNames = classNames(
      'intermediate-concepts__header',
      { 'intermediate-concepts__header_two': conceptsNames.length === 2 },
      { 'intermediate-concepts__header_three': conceptsNames.length === 3 },
    );

    return (
      <div className={headerClassNames}>
        <div className="intermediate-concepts__name">
          <Checkbox
            id="intermediate-concepts-checkbox"
            checked={allSelected}
            onChange={toggleAllConceptsHandler}
          />
          <span>Name</span>
          <ButtonCircle
            icon={
              sortBy === intermediateConceptsSortingTypes.NAME && sortDirection === 'ASC' ?
                <BiSortUp size={16} color="#4b3f63" /> :
                <BiSortDown size={16} color="#4b3f63" />
            }
            onClick={() => { handleSortConcepts(intermediateConceptsSortingTypes.NAME); }}
          />
        </div>
        {
          conceptsNames.map((c, i) => (
            <div
              key={`cn-${i}`}
              className="intermediate-concepts__count intermediate-concepts__count--by-concepts"
            >
              <span>{c}</span>
              <ButtonCircle
                icon={
                  sortBy === c && sortDirection === 'ASC' ?
                    <BiSortUp size={16} color="#4b3f63" /> :
                    <BiSortDown size={16} color="#4b3f63" />
                }
                onClick={() => { handleSortConcepts(c); }}
              />
            </div>
          ))
        }
        <div className="intermediate-concepts__count">
          <span>References</span>
          <ButtonCircle
            icon={
              sortBy === intermediateConceptsSortingTypes.COUNT && sortDirection === 'ASC' ?
                <BiSortUp size={16} color="#4b3f63" /> :
                <BiSortDown size={16} color="#4b3f63" />
            }
            onClick={() => { handleSortConcepts(intermediateConceptsSortingTypes.COUNT); }}
          />
        </div>
      </div>
    );
  }, [allSelected, conceptsNames, data, handleSortConcepts, sortBy, sortDirection, toggleAllConceptsHandler]);

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="intermediate-concepts">
        <div className="intermediate-concepts__sidebar">
          {
            data &&
            allData &&
            <IntermediateConceptsFilter
              options={options}
              setFilters={setFilters}
              resetFilters={resetFilters}
              conceptsNames={conceptsNames}
              startConceptsIds={conceptsIds}
              beFilters={beFilters}
              filterValue={filterValue}
              filterConcepts={filterConcepts}
              beFiltersType={beFiltersType}
              getBeFilters={getBeFilters}
              resetBeFilters={resetBeFilters}
              getFilteredConcepts={getFilteredConcepts}
              getGeneFilterOptions={getGeneFilterOptions}
              getIntermediateConcepts={getIntermediateConcepts}
              initialValues={intermedieteConceptsFilterInitialValues}
              conceptsIds={data.map(c => c.concept.id)}
              allConceptsIds={allData.map(c => c.concept.id)}
              getSourceDetailsFilterOptions={getSourceDetailsFilterOptions}
            />
          }
        </div>
        <div className="intermediate-concepts__content">
          {renderTitle}
          {renderLimit}
          {renderHeader}
          <div className="intermediate-concepts__list">
            {
              !loading && data && data.length > 0 &&
              <AutoSizer disableHeight={true}>
                {({ width }) => (
                  <List
                    ref={listRef}
                    width={width}
                    height={Math.min(data.length * 45, 400)}
                    rowCount={data.length}
                    rowHeight={45}
                    rowRenderer={rowRenderer}
                  />
                )}
              </AutoSizer>
            }
            <NoData
              show={!loading && data && data.length === 0}
              customClassName="intermediate-concepts__no-data"
            />
            <Loader isLoading={loading} />
          </div>
          {
            data &&
            <div className="create-set-info__save">
              <button
                className={classNames(
                  'button button-primary',
                  { 'button-primary--disabled': !data.find(c => c.selected === true) },
                )}
                onClick={getSelectedConcepts}
              >
                Select Concepts
              </button>
            </div>
          }
        </div>
      </div>
    </ModalComponent>
  );
};

IntermediateConceptsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    allData: SELECTORS.getIntermediateConceptsAllDataSelector(state),
    data: SELECTORS.getIntermediateConceptsFilteredDataSelector(state),
    loading: SELECTORS.getIntermediateConceptsLoadingSelector(state),
    options: SELECTORS.getIntermediateConceptsOptionsSelector(state),
    filters: SELECTORS.getIntermediateConceptsFiltersSelector(state),
    beFilters: SELECTORS.getIntermediateConceptsBeFiltersSelector(state),
    beFiltersType: SELECTORS.getIntermediateConceptsBeFiltersTypeSelector(state),
    allSelected: SELECTORS.getIntermediateConceptsAllSelectedSelector(state),
    filterValue: SELECTORS.getFilterIntermediateConceptsValueSelector(state),
    sorting: SELECTORS.getIntermediateConceptsSortingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIntermediateConcepts(data) {
      dispatch(ACTIONS.getIntermediateConceptsDataAction(data));
    },
    resetIntermediateConcepts(data) {
      dispatch(ACTIONS.resetIntermediateConceptsAction(data));
    },
    setFilters(data) {
      dispatch(ACTIONS.setIntermediateConceptsFiltersAction(data));
    },
    resetFilters(data) {
      dispatch(ACTIONS.resetIntermediateConceptsFiltersAction(data));
    },
    toggleConcept(data) {
      dispatch(ACTIONS.toggleIntermediateConceptAction(data));
    },
    toggleAllConcepts(data) {
      dispatch(ACTIONS.toggleAllIntermediateConceptsAction(data));
    },
    filterConcepts(data) {
      dispatch(ACTIONS.filterIntermediateConceptsAction(data));
    },
    getBeFilters(data) {
      dispatch(ACTIONS.getIntermediateConceptsBeFiltersAction(data));
    },
    resetBeFilters(data) {
      dispatch(ACTIONS.resetIntermediateConceptsBeFiltersAction(data));
    },
    getGeneFilterOptions(data) {
      dispatch(ACTIONS.getGeneFilterOptionsAction(data));
    },
    getFilteredConcepts(data) {
      dispatch(ACTIONS.getFilteredIntermediateConceptsAction(data));
    },
    sortConcepts(data) {
      dispatch(ACTIONS.sortIntermediateConceptsAction(data));
    },
    getSourceDetailsFilterOptions(data) {
      dispatch(ACTIONS.getSourceDetailsFilterOptionsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntermediateConceptsModal);
