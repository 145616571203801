export const graphFilterEnum = {
  CELL_TYPE: 'cellType',
  PATIENT: 'patient',
  CONDITION: 'condition',
};

export const graphFilterOptions = [
  {
    option: 'Cell Type',
    label: 'Cell Type',
    value: graphFilterEnum.CELL_TYPE,
    field: 'CELL_TYPE',
  },
  {
    option: 'Individual',
    label: 'Individual',
    value: graphFilterEnum.PATIENT,
    field: 'PATIENT',
  },
  {
    option: 'Condition',
    label: 'Condition',
    value: graphFilterEnum.CONDITION,
    field: 'CONDITION',
  },
];

export const CELLS_CHART = 'CELLS_CHART';
export const GENE_CELLS_CHART = 'GENE_CELLS_CHART';
