import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Error from '../../../components/common/Error/Error';
import Loader from '../../../components/common/Loader/Loader';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import ModalComponent from '../../ModalComponent/ModalComponent';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import HeatmapHeader from './Components/HeatmapHeader/HeatmapHeader';
import HeatmapChart from './Components/HeatmapChart/HeatmapChart';
import SaveAsSetModal from '../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
// Utils
import {
  sortHeatmap,
  getSelectedCellsMap,
  sortHeatmapByCluster,
  deleteSelectedCells,
  formatHeatmapToString,
  deleteSelectedConcepts,
  deleteSelectedRowColCells,
  deleteSelectedRowColConcepts,
} from './utils';
import { toggleSortingDirection, exportToFile } from '../../Utils/Utils';
import { RELATIVE_PATH } from '../../../constantsCommon';
import {HeatmapSortDirEnum, heatmapCooccurrenceEnum, HeatmapTypeEnum} from './constants';
import { RELATION_MAP_CONCEPTS_LIMIT } from '../../RelationMap/RelationMapPage/constants';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { buildHeatmapUpdateConceptsAction, swapBuildHeatmapAxisAction } from '../../Sets/BuildHeatmap/store/reducer';
import { showTooltipAction, hideTooltipAction } from '../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../Concept/ShortConceptCard/reducer';
// Styles
import './styles.scss';

const propTypes = {
  initialData: PropTypes.instanceOf(Object),
  initialNetworkData: PropTypes.instanceOf(Array),
  initialCellLinesData: PropTypes.instanceOf(Object),
  chartData: PropTypes.instanceOf(Object),
  sorting: PropTypes.instanceOf(Object),
  sortHeatmapData: PropTypes.func,
  setHeatmapData: PropTypes.func,
  type: PropTypes.string,
  getHeatmapData: PropTypes.func,
  getNetworkAnalysisHeatmapData: PropTypes.func,
  getCellLinesHeatmapData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  clearHeatmap: PropTypes.func,
  children: PropTypes.instanceOf(Object),
  showHeatMap: PropTypes.bool,
  showPublicationsPopup: PropTypes.bool,
  buildHeatmap: PropTypes.bool,
  title: PropTypes.string,
  csvData: PropTypes.string,
  getCSVData: PropTypes.func,
  updateConcepts: PropTypes.func,
  rowsSortDir: PropTypes.string,
  colsSortDir: PropTypes.string,
  sortHeatmapRows: PropTypes.func,
  sortHeatmapColumns: PropTypes.func,
  savedNetworkData: PropTypes.instanceOf(Object),
  swapHeatmapAxis: PropTypes.func,
  swapBuildHeatmapAxis: PropTypes.func,
  spinner: PropTypes.bool,
  toggleHeatmapSpinner: PropTypes.func,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  scoreType: PropTypes.string,
  setScoreType: PropTypes.func,
  setHeatmapInitialData: PropTypes.func,
  projectId: PropTypes.string,
  titleForDownloading: PropTypes.string,
  networkAnalysisTitle: PropTypes.string,
  isSetAnalysis: PropTypes.bool,
  setHeatmapCSV: PropTypes.func,
  measureSelector: PropTypes.array,
  showShowOnRelationMap: PropTypes.bool,
  showSaveAsSet: PropTypes.bool,
  showDeleteSelected: PropTypes.bool,
  allowNegativeValues: PropTypes.bool,
};

const Heatmap = (props) => {
  const {
    type,
    title,
    csvData,
    sorting,
    loading,
    error,
    children,
    showHeatMap = true,
    showPublicationsPopup = true,
    showShowOnRelationMap = true,
    showSaveAsSet = true,
    showDeleteSelected,
    buildHeatmap,
    rowsSortDir,
    colsSortDir,
    chartData,
    initialData,
    initialNetworkData,
    initialCellLinesData,
    clearHeatmap,
    getCSVData,
    getHeatmapData,
    getCellLinesHeatmapData,
    sortHeatmapData,
    getNetworkAnalysisHeatmapData,
    setHeatmapData,
    sortHeatmapColumns,
    sortHeatmapRows,
    updateConcepts,
    savedNetworkData,
    swapHeatmapAxis,
    swapBuildHeatmapAxis,
    spinner,
    toggleHeatmapSpinner,
    showTooltip,
    hideTooltip,
    setShortConceptCardId,
    scoreType,
    setScoreType,
    setHeatmapInitialData,
    projectId,
    networkAnalysisTitle,
    titleForDownloading,
    isSetAnalysis,
    setHeatmapCSV,
    measureSelector,
    allowNegativeValues,
  } = props;
  const noData = !chartData || chartData.rows.length === 0 || chartData.columns.length === 0;

  const [selectedCells, setSelectedCells] = useState([]);
  const [publicationsPopup, setPublicationsPopup] = useState({ show: false, concept: null });
  const [addSetPopupIsOpen, setAddSetPopupIsOpen] = useState({ show: false, ids: [] });
  const [relationMapWarning, setRelationMapWarning] = useState({ show: false, count: 0 });
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    if (csvData) {
      exportToFile(downloadLinkRef.current, csvData, 'heatmap-diagram-export');
    }
  }, [csvData]);

  useEffect(() => {
    if (
      showHeatMap &&
      initialData &&
      initialData.conceptIds.length &&
      initialData.connectedConceptIds.length
    ) {
      getHeatmapData({ ...initialData, scoreType });
    }
  }, [getHeatmapData, initialData, scoreType, showHeatMap]);

  useEffect(() => {
    if (
      showHeatMap &&
      initialCellLinesData
    ) {
      getCellLinesHeatmapData(savedNetworkData, scoreType);
    }
  }, [getCellLinesHeatmapData, scoreType]);

  useEffect(() => {
    if (
      showHeatMap &&
      initialNetworkData &&
      initialNetworkData.length
    ) {
      getNetworkAnalysisHeatmapData(initialNetworkData);
    }
  }, [getNetworkAnalysisHeatmapData, initialNetworkData, showHeatMap]);

  useEffect(() => {
    if (csvData) {
      setHeatmapCSV(null);
    }
  }, [csvData, setHeatmapCSV, scoreType]);

  useEffect(() => clearHeatmap, []);

  function openPublicationsPopup(d) {
    const {
      pubsCount,
      score,
      col,
      row,
    } = d;
    if ((!pubsCount && !score) || !showPublicationsPopup) return;

    setPublicationsPopup({
      show: true,
      concept: {
        names: [row.name, col.name].filter(Boolean),
        ids: [row.id, col.id].filter(Boolean),
      },
    });
  }

  function closePublicationsPopup() {
    setPublicationsPopup({
      show: false,
      concept: null,
    });
  }

  function sort(d, sortBy) {
    const newSorting = {
      sortBy,
      sortDirection: sorting.sortKey === d ?
        toggleSortingDirection(sorting.sortDirection) :
        sorting.sortDirection,
      sortKey: d,
    };
    sortHeatmapData({
      data: sortHeatmap(chartData, newSorting, type),
      sorting: newSorting,
    });
  }

  function openAddSetPopup() {
    const selectedCellsMap = getSelectedCellsMap(selectedCells);
    const ids = Object.keys(selectedCellsMap);

    setAddSetPopupIsOpen({ show: true, ids });
  }

  function closeAddSetPopup() {
    setAddSetPopupIsOpen({ show: false, ids: [] });
  }

  function showOnRelationMap() {
    const selectedCellsMap = getSelectedCellsMap(selectedCells);

    const uniqConcepts = Object
      .values(selectedCellsMap)
      .map(c => ({ conceptId: c.id, name: c.name }));

    if (uniqConcepts.length > RELATION_MAP_CONCEPTS_LIMIT) {
      setRelationMapWarning({ show: true, count: uniqConcepts.length });
    } else {
      localStorage.setItem('uniqConcepts', JSON.stringify(uniqConcepts));
      window.open(`${RELATIVE_PATH}/relation-map/${projectId}/new`, '_blank');
    }
  }

  function exportToCSV() {
    if (!showHeatMap) {
      getCSVData({ ...initialData, scoreType });
    } else {
      const stringHeatmap = formatHeatmapToString(chartData, type);
      exportToFile(downloadLinkRef.current, stringHeatmap, 'heatmap-diagram-export');
    }
  }

  function deleteSelected() {
    toggleHeatmapSpinner(true);
    if (buildHeatmap) {
      updateConcepts(deleteSelectedConcepts(chartData, selectedCells));
    } else {
      const newChartData = deleteSelectedCells(chartData, selectedCells, type);
      setHeatmapData({ data: newChartData, type });
      setHeatmapInitialData(deleteSelectedConcepts(chartData, selectedCells));
      setSelectedCells([]);
    }
  }

  function deleteSelectedRowCol(dataType) {
    toggleHeatmapSpinner(true);
    if (buildHeatmap) {
      updateConcepts(deleteSelectedRowColConcepts(chartData, selectedCells, dataType));
    } else {
      const newChartData = deleteSelectedRowColCells(chartData, selectedCells, type, dataType);
      setHeatmapData({ data: newChartData, type });
      setHeatmapInitialData(deleteSelectedRowColConcepts(chartData, selectedCells, dataType));
      setSelectedCells([]);
    }
  }

  function sortByCluster(keyType, sortType) {
    toggleHeatmapSpinner(true);
    const newChartData = { ...chartData };
    newChartData[keyType] = sortHeatmapByCluster(chartData.data, sortType, type);
    setHeatmapData({ data: newChartData, type });
  }

  function sortByColumn() {
    toggleHeatmapSpinner(true);
    const newChartData = { ...chartData };
    newChartData.columns.sort((a, b) => a.name.localeCompare(b.name));
    if (colsSortDir === HeatmapSortDirEnum.DESC) {
      newChartData.columns.reverse();
    }
    sortHeatmapColumns({
      sorting: toggleSortingDirection(colsSortDir),
      data: newChartData,
    });
  }

  function sortByRow() {
    toggleHeatmapSpinner(true);
    const newChartData = { ...chartData };
    newChartData.rows.sort((a, b) => a.name.localeCompare(b.name));
    if (rowsSortDir === HeatmapSortDirEnum.DESC) {
      newChartData.rows.reverse();
    }
    sortHeatmapRows({
      sorting: toggleSortingDirection(rowsSortDir),
      data: newChartData,
    });
  }

  function swapAxis() {
    toggleHeatmapSpinner(true);
    if (buildHeatmap) {
      swapBuildHeatmapAxis();
    } else {
      const newChartData = { ...chartData };
      const newSavedNetworkData = { ...savedNetworkData };
      const newData = newChartData.data.map(el => ({
        ...el,
        row: el.col,
        col: el.row,
      }),
      );

      const newSavedData = {
        ...newSavedNetworkData,
        rows: newSavedNetworkData && newSavedNetworkData.columns,
        columns: newSavedNetworkData && newSavedNetworkData.rows,
      };

      swapHeatmapAxis({
        data: {
          ...newChartData,
          data: newData,
          columns: newChartData.rows,
          rows: newChartData.columns,
        },
        dataForSave: newSavedData,
      });
    }
  }

  function getPublicationsListCooccurrenceType() {
    switch (scoreType) {
      case heatmapCooccurrenceEnum.ABSTRACT_JACKARD:
        return heatmapCooccurrenceEnum.ABSTRACT;
      case heatmapCooccurrenceEnum.SENTENCE_JACKARD:
        return heatmapCooccurrenceEnum.SENTENCE;
      case heatmapCooccurrenceEnum.TITLE_JACKARD:
        return heatmapCooccurrenceEnum.TITLE;
      default:
        return scoreType;
    }
  }

  return (
    <div className="heatmap-chart">
      {
        (initialData !== null || initialNetworkData !== null || initialCellLinesData != null) &&
        <HeatmapHeader
          noData={noData}
          csvData={csvData}
          showHeatMap={showHeatMap}
          cellsIsSelected={selectedCells.length > 0}
          sortByRow={sortByRow}
          exportToCSV={exportToCSV}
          sortByColumn={sortByColumn}
          sortByCluster={sortByCluster}
          deleteSelected={deleteSelected}
          deleteSelectedRowCol={deleteSelectedRowCol}
          openAddSetPopup={openAddSetPopup}
          showOnRelationMap={showOnRelationMap}
          swapAxis={swapAxis}
          scoreType={scoreType}
          setScoreType={setScoreType}
          showShowOnRelationMap={showShowOnRelationMap}
          showSaveAsSet={showSaveAsSet}
          showDeleteSelected={showDeleteSelected}
          measureSelector={measureSelector}
        />
      }
      {
        showHeatMap &&
        <>
          {
            title &&
            <h2 className="heatmap-chart__title title3">
              {title}
            </h2>
          }
          {children}
          <Loader isLoading={loading} />
          {
            !noData &&
            <HeatmapChart
              sort={sort}
              type={type}
              chartData={chartData}
              setSelectedCells={setSelectedCells}
              openPublicationsPopup={openPublicationsPopup}
              isNetworkAnalysis={!!initialNetworkData || !!savedNetworkData}
              spinner={spinner}
              toggleHeatmapSpinner={toggleHeatmapSpinner}
              showTooltip={showTooltip}
              hideTooltip={hideTooltip}
              setShortConceptCardId={setShortConceptCardId}
              scoreType={scoreType}
              titleForDownloading={titleForDownloading}
              networkAnalysisTitle={networkAnalysisTitle}
              isSetAnalysis={isSetAnalysis}
              allowNegativeValues={allowNegativeValues}
            />
          }
          {
            (initialData !== null || initialNetworkData !== null || initialCellLinesData != null) &&
            noData && !loading && showHeatMap &&
            <span className="heatmap-chart__no-data">
              There is no data to display
            </span>
          }
          <Error
            show={error && !loading}
            error={error}
          />
        </>
      }
      {
        !showHeatMap &&
        <div className="text-center heatmap-chart__info-text">
          {
            'There are too many records for drawing heatmap. You can only download data in CSV format. \n' +
            'For building graphic result of the multiplication ' +
            'operation of chosen set\'s concepts can\'t be more than 30 000.'
          }
        </div>
      }
      {
        publicationsPopup.show &&
        <ModalComponent
          isOpen={publicationsPopup.show}
          closeCb={closePublicationsPopup}
        >
          <PublicationsList
            names={publicationsPopup.concept.names}
            ids={publicationsPopup.concept.ids}
            filters={{ publicationSource: getPublicationsListCooccurrenceType() }}
          />
        </ModalComponent>
      }
      <SaveAsSetModal
        isOpen={addSetPopupIsOpen.show}
        closeCb={closeAddSetPopup}
        projectId={projectId}
        idsForShortConceptDetails={addSetPopupIsOpen.ids}
      />
      <InfoDialog
        isOpen={relationMapWarning.show}
        closeCb={() => setRelationMapWarning({ show: false, count: 0 })}
        text={
          `You have selected ${relationMapWarning.count} concepts.
          Maximum amount of concepts on map is ${RELATION_MAP_CONCEPTS_LIMIT}.`
        }
        confirmBtnText="Ok"
      />
      <a // eslint-disable-line
        hidden={true}
        ref={downloadLinkRef}
      />
    </div>
  );
};

Heatmap.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    chartData: SELECTORS.getHeatmapDataSelector(state),
    loading: SELECTORS.getHeatmapLoadingSelector(state),
    error: SELECTORS.getHeatmapErrorSelector(state),
    sorting: SELECTORS.getHeatmapSortingSelector(state),
    type: SELECTORS.getHeatmapTypeSelector(state),
    csvData: SELECTORS.getCSVHeatmapData(state),
    rowsSortDir: SELECTORS.getHeatmapSortRowsSelector(state),
    colsSortDir: SELECTORS.getHeatmapSortColsSelector(state),
    initialData: SELECTORS.getHeatmapInitialDataSelector(state),
    initialNetworkData: SELECTORS.getHeatmapInitialNetworkDataSelector(state),
    initialCellLinesData: SELECTORS.getHeatmapInitialCellLinesDataSelector(state),
    savedNetworkData: SELECTORS.getHeatmapDataForSaveSelector(state),
    spinner: SELECTORS.getHeatmapSpinnerSelector(state),
    scoreType: SELECTORS.getHeatmapScoreType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHeatmapData(data) {
      dispatch(ACTIONS.getHeatmapDataAction(data));
    },
    getNetworkAnalysisHeatmapData(data) {
      dispatch(ACTIONS.getNetworkAnalysisHeatmapDataAction(data));
    },
    getCellLinesHeatmapData(data, scoreType) {
      dispatch(ACTIONS.setCellLinesHeatmapDataAction({data, type: HeatmapTypeEnum.SCORE, scoreType}));
    },
    sortHeatmapData(data) {
      dispatch(ACTIONS.sortHeatmapDataAction(data));
    },
    sortHeatmapRows(data) {
      dispatch(ACTIONS.sortHeatmapRowsAction(data));
    },
    sortHeatmapColumns(data) {
      dispatch(ACTIONS.sortHeatmapColumnsAction(data));
    },
    setHeatmapData(data) {
      dispatch(ACTIONS.setHeatmapDataAction(data));
    },
    clearHeatmap() {
      dispatch(ACTIONS.clearHeatmapAction());
    },
    getCSVData(data) {
      dispatch(ACTIONS.getHeatmapCSVAction(data));
    },
    updateConcepts(data) {
      dispatch(buildHeatmapUpdateConceptsAction(data));
    },
    swapHeatmapAxis(data) {
      dispatch(ACTIONS.swapHeatmapAxisAction(data));
    },
    toggleHeatmapSpinner(data) {
      dispatch(ACTIONS.toggleHeatmapSpinnerAction(data));
    },
    swapBuildHeatmapAxis(data) {
      dispatch(swapBuildHeatmapAxisAction(data));
    },
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
    setScoreType(data) {
      dispatch(ACTIONS.setScoreTypeAction(data));
    },
    setHeatmapCSV(data) {
      dispatch(ACTIONS.setHeatmapCSVAction(data));
    },
    setHeatmapInitialData(data) {
      dispatch(ACTIONS.setHeatmapInitialDataAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Heatmap);
