import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactPaginate from 'react-paginate';
import SimpleTable from '../common/SimpleTable/SimpleTable';
import ExportTable from '../common/ExportTable/ExportTable';

import {
  getAntibodiesAllWiposSelector,
  getAntibodiesConceptSelector,
  getAntibodiesLoadingSelector,
  getAntibodiesSortingSelector,
  getAntibodiesWiposSelector,
  getWiposPageSelector,
  getWiposTotalPagesPagesSelector,
} from './store/selectors';
import {
  changePageWiposAntibodiesAction,
  sortAntibodiesTableAction,
} from './store/reducer';
import {AVERAGE_SYMBOL_LENGTH, getParentBlockWidth} from '../Utils/Utils';
import {RELATIVE_PATH} from '../../constantsCommon';

// Styles
import './styles.scss';
import ModalComponent from '../ModalComponent/ModalComponent';

const propTypes = {
  width: PropTypes.number,
  data: PropTypes.array,
  allData: PropTypes.array,
  loading: PropTypes.bool,
  concept: PropTypes.instanceOf(Object),
  conceptsPopupData: PropTypes.array,
  conceptsPopupName: PropTypes.string,
  sorting: PropTypes.instanceOf(Object),
  sortAntibodiesTable: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

class AntibodiesPlabDabTable extends React.Component {

  state = {
    conceptsPopupData: [],
    conceptsPopupName: '',
    showInfoText: false,
  };

  simpleTableRef = React.createRef(null);

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  closeConceptsPopup = () => {
    this.setState({
      conceptsPopupData: [],
    });
  };

  getColumnPercentWidth = (percent) => {
    const screenWidth = getParentBlockWidth('antibodies-page');
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { data } = this.props;
    const { heavySequence } = data[index];
    const padding = 10;
    const lineHeight = 20;
    const cellWidth = this.getColumnPercentWidth(60);
    const stringLength = heavySequence.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);

    const height = ((stringRows * lineHeight) < 130) && heavySequence ? 130 : (stringRows * lineHeight);

    return height + padding;
  };

  handlePageClick = (page) => {
    this.props.changePage(page.selected);
  };

  render() {
    const {
      conceptsPopupData,
      conceptsPopupName,
    } = this.state;

    const {
      data,
      allData,
      loading,
      concept,
      sorting: {
        sortBy,
        sortDirection,
      },
      activePage,
      totalPages,
    } = this.props;

    const tableSettings = {
      height: 500,
      width: getParentBlockWidth('antibodies-page'),
      headerHeight: 50,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      rowHeight: this.getRowHeight,
      // useDynamicRowHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Sequence chain',
        dataKey: 'heavySequence',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(60),
      },
      {
        label: 'Title',
        dataKey: 'referenceTitle',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(20),
        cellRenderer: ({ rowData }) => {
          if (rowData.publicationId) {
            return <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${RELATIVE_PATH}/publication-details/${rowData.publicationId}`}
            >
              {rowData.referenceTitle}
            </a>;
          } else if (rowData.pubmedId) {
            return <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://pubmed.ncbi.nlm.nih.gov/${rowData.pubmedId}`}
            >
              {rowData.referenceTitle}
            </a>;
          } else {
            return `${rowData.referenceTitle}`;
          }
        }
      },
      {
        label: 'Last Updated',
        dataKey: 'updateDate',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(10),
      },
      {
        label: 'Reference URLs',
        dataKey: 'urls',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(10),
        cellRenderer: ({rowData}) => (
          rowData.urls.map(url => (
            <a
              key={url}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
            >
              Reference
            </a>
          ))
        )
      },
    ];

    const conceptsPopupContent = conceptsPopupData.map((item, i) => (
      <a
        className="indications-popup__item"
        key={i}
      >
        {item}
      </a>
    ));

    return (
      <div className="antibodies-page__table text-center">
        {
          !loading && data && data.length > 0 &&
          <div className="table-wrap">
            <div className="controls-block-2items">
              {
                totalPages > 1 &&
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    pageCount={totalPages}
                    forcePage={activePage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              }
              <ExportTable
                content={allData}
                columns={columns}
                fileName={`${concept.name}-antibodies`}
              />
            </div>
            <SimpleTable
              data={data}
              innerRef={this.simpleTableRef}
              autoSize={true}
              columns={columns}
              settings={tableSettings}
              dynamicHeight={true}
              sortAction={sortAntibodiesTableAction}
            />
          </div>
        }
        {
          conceptsPopupData.length > 0 &&
          <ModalComponent
            isOpen={conceptsPopupData.length > 0}
            closeCb={this.closeConceptsPopup}
          >
            <div className="indications-popup">
              <div className="indications-popup__title">{conceptsPopupName}</div>
              <div className="indications-popup__wrap">
                { conceptsPopupContent }
              </div>
            </div>
          </ModalComponent>
        }
        {
          !loading && (!data || !data.length) &&
            <span className="antibodies-page__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

AntibodiesPlabDabTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getAntibodiesLoadingSelector(state),
    data: getAntibodiesWiposSelector(state),
    allData: getAntibodiesAllWiposSelector(state),
    concept: getAntibodiesConceptSelector(state),
    sorting: getAntibodiesSortingSelector(state),
    activePage: getWiposPageSelector(state),
    totalPages: getWiposTotalPagesPagesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sortAntibodiesTable(data) {
      dispatch(sortAntibodiesTableAction(data));
    },
    changePage(data) {
      dispatch(changePageWiposAntibodiesAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AntibodiesPlabDabTable);

