import { createSelector } from 'reselect';

const conceptsIDs = state => state.getIn(['setResultPage', 'set', 'conceptIds']);
const setName = state => state.getIn(['setResultPage', 'set', 'setName']);
const setId = state => state.getIn(['setResultPage', 'set', 'id']);
const projectId = state => state.getIn(['setResultPage', 'set', 'projectId']);

const sorting = state => state.getIn(['setResultPage', 'sorting']);
const loading = state => state.getIn(['setResultPage', 'loading']);
const error = state => state.getIn(['setResultPage', 'error']);

const content = state => state.getIn(['setResultPage', 'result', 'content']);
const exportContent = state => state.getIn(['setResultPage', 'exportCSVResult', 'result', 'content']);

const fullSet = state => state.getIn(['setResultPage', 'fullSet']);
const sortForBackend = state => state.getIn(['setResultPage', 'sort']);

const totalPages = state => state.getIn(['setResultPage', 'result', 'totalPages']);
const totalElements = state => state.getIn(['setResultPage', 'result', 'totalElements']);
const pageNumber = state => state.getIn(['setResultPage', 'result', 'number']);

const pairOfConcepts = state => state.getIn(['setResultPage', 'pairForNeighbors']);
const conceptAndCategory = state => state.getIn(['setResultPage', 'categorySetResult']);

const setADropDownKey = state => state.getIn(['setResultPage', 'setADropDownKey']);
const setBDropDownKey = state => state.getIn(['setResultPage', 'setBDropDownKey']);
const setCDropDownKey = state => state.getIn(['setResultPage', 'setCDropDownKey']);
const currentSetId = state => state.getIn(['setResultPage', 'currentSetId']);
const previousSetId = state => state.getIn(['setResultPage', 'previousSetId']);

export const getContentForExportAll = createSelector(
  exportContent,
  contentData => contentData.toJS()
);

export const getPreviousSetId = createSelector(
  previousSetId,
  id => id
);

export const getCurrentSetId = createSelector(
  currentSetId,
  id => id
);

export const getProjectId = createSelector(
  projectId,
  id => id
);

export const getConceptAndCategory = createSelector(
  conceptAndCategory,
  data => data.toJS()
);

export const getPage = createSelector(
  content,
  data => data.toJS()
);

export const getPageValue = createSelector(
  pageNumber,
  numberValue => numberValue
);

export const getPairOfConcepts = createSelector(
  pairOfConcepts,
  data => data.toJS()
);

export const getConceptsIds = createSelector(
  conceptsIDs,
  data => data.toJS()
);

export const getSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getSetId = createSelector(
  setId,
  data => data
);

export const getSetName = createSelector(
  setName,
  data => data
);

export const getLoadingKey = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getTotalItems = createSelector(
  totalElements,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const getSelectedItems = createSelector(
  getPage,
  data => data.filter(item => (
    item.selected
  ))
);

export const getSetAOpenKey = createSelector(
  setADropDownKey,
  data => data
);

export const getSetBOpenKey = createSelector(
  setBDropDownKey,
  data => data
);

export const getSetCOpenKey = createSelector(
  setCDropDownKey,
  data => data
);

export const getShowConfirmAddConceptPopup = state => state.getIn(['setResultPage', 'showConfirmAddConceptPopup']);

export const getFullSet = createSelector(
  fullSet,
  data => data.toJS()
);

export const getSortForBackend = createSelector(
  sortForBackend,
  data => (
    data === null ? data : data.toJS()
  )
);

export const getRightColumnConceptsId = createSelector(
  getFullSet,
  (fullSet) => { //eslint-disable-line
    if (fullSet.length && fullSet[0].nodeC) {
      return fullSet.map(row => (
        row.nodeC
      ));
    } else if (fullSet.length && fullSet[0].nodeB) {
      return fullSet.map(row => (
        row.nodeB
      ));
    } else if (fullSet.length && fullSet[0].nodeA) {
      return fullSet.map(row => (
        row.nodeA
      ));
    }
    return [];
  }
);
