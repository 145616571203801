import {
  createSelector,
} from 'reselect';

import {
  sort,
} from '../../../Utils/Utils';

const path = state => state.getIn(['pathDetails', 'path']);
const pathDetailsTable = state => state.getIn(['pathDetails', 'pathDetails', 'table']);
const chordsDetailsDiagram = state => state.getIn(['pathDetails', 'pathDetails', 'chords']);
const regionsDetailsDiagram = state => state.getIn(['pathDetails', 'pathDetails', 'regions']);
const targetGeneDetailsDiagram = state => state.getIn(['pathDetails', 'pathDetails', 'targetGene']);
const error = state => state.getIn(['pathDetails', 'error']);
const loading = state => state.getIn(['pathDetails', 'loading']);
const pathDetailsSorting = state => state.getIn(['pathDetails', 'pathDetailsSorting']);
const literatureTableSorting = state => state.getIn(['pathDetails', 'literatureTableSorting']);
const geneLiteratureData = state => state.getIn(['pathDetails', 'pathDetails', 'literatureTable']);

export const getLiteratureTableSorting = createSelector(
  literatureTableSorting,
  data => data.toJS()
);

export const getPathDetailsSorting = createSelector(
  pathDetailsSorting,
  data => data.toJS()
);

export const getGeneLiteratureData = createSelector(
  geneLiteratureData,
  data => data
);

export const getSortedGeneLiteratureData = createSelector(
  getGeneLiteratureData,
  getLiteratureTableSorting,
  (literatureData, literatureTableSortingValue) => {
    const {
      sortBy,
      sortDirection,
    } = literatureTableSortingValue;

    const plainLiteratureData = literatureData ? literatureData.toJS() : [];
    let result = [];

    if (sortBy === 'leftGene' || sortBy === 'rightGene') {
      result = plainLiteratureData.sort((i1, i2) => {
        if (i1[sortBy].name > i2[sortBy].name) {
          return 1;
        } else if (i1[sortBy].name < i2[sortBy].name) {
          return -1;
        }
        return 0;
      });
    }

    if (sortBy === 'cooccurrence') {
      result = plainLiteratureData.sort((i1, i2) =>
        i1.cooccurrence - i2.cooccurrence
      );
    }

    if (!sortBy) {
      result = plainLiteratureData;
    }

    return sortDirection === 'ASC' ? result : result.reverse();
  }
);

export const getPath = createSelector(
  path,
  data => data.toJS()
);

export const getPathDetailsTable = createSelector(
  pathDetailsTable,
  pathDetailsSorting,
  (content, sorting) => {
    const sorted = sort(content, sorting.toJS());
    return sorted.toJS();
  }
);

export const getError = createSelector(
  error,
  data => data
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getChordsDetailsDiagram = createSelector(
  chordsDetailsDiagram,
  data => data.toJS()
);

export const getRegionsDetailsDiagram = createSelector(
  regionsDetailsDiagram,
  data => data.toJS()
);

export const getTargetGeneDetailsDiagram = createSelector(
  targetGeneDetailsDiagram,
  data => data.toJS()
);
