import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

// Components
import Table from '../../../common/SimpleTable/Table';
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import Pagination from '../../../common/Pagination/Pagination';
// Store
import * as s from '../../store/selectors';
import * as a from '../../store/actions';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loading: PropTypes.bool,
  sorting: PropTypes.instanceOf(Object),
  pagginate: PropTypes.func,
  sortConcepts: PropTypes.func,
};

const RankSelectionSet = (props) => {
  const {
    data,
    error,
    loading,
    sorting,
    pagginate,
    sortConcepts,
  } = props;
  const { pagginatedConcepts, pages } = data || {};

  const columns = [
    {
      dataKey: 'name',
      label: 'Name',
      width: 100,
    },
  ];

  return (
    <div className="container">
      {
        data &&
        <>
          <Pagination
            {...pages}
            onPageChange={({ selected }) => pagginate(selected)}
          />
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Table
                data={pagginatedConcepts}
                columnsData={columns}
                width={width}
                sorting={sorting}
                sortCallback={sortConcepts}
              />
            )}
          </AutoSizer>
        </>
      }
      <Loader isLoading={loading} />
      <Error
        show={!!error && !loading}
        error={error}
      />
    </div>
  );
};

RankSelectionSet.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: s.getRankSelctionSetFinalDataSelector(state),
    loading: s.getRankSelctionSetLoadingSelector(state),
    error: s.getRankSelctionSetErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sortConcepts(data) {
      dispatch(a.sortRankSelectionSetAction(data));
    },
    pagginate(data) {
      dispatch(a.pagginateRankSelectionSetAction(data));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RankSelectionSet);