/* eslint-disable no-plusplus */
import * as d3js from 'd3';
import { kFormatter } from '../../../../Utils/Utils';

export function drawGradientLegend({ domainTo, element, type }) {
  const legendCell = {
    width: 40,
    height: 30,
  };
  const domainFrom = 0;

  const mainColors = d3js.scaleLinear()
    .range(['#BEBEBE', '#000000'])
    .domain([domainFrom, domainTo]);

  const getLegendSteps = () => {
    if (domainTo === 0) return [0];
    const steps = {
      0: {
        number: 0,
        text: 0,
      },
    };
    if ((domainTo > 0 && domainTo < 1) || (type === 'SINGLE_CELL' && domainTo > 0 && domainTo <= 1)) {
      const stepsLength = 4;
      const step = (domainTo - domainFrom) / stepsLength;

      for (let i = 1; i < stepsLength + 1; i++) {
        const number = domainFrom !== 0 && i === 1 ?
          domainFrom : Math.round(((i * step) + domainFrom) * 10000) / 10000;
        const text = kFormatter(number);

        if (!steps[text]) {
          steps[text] = { number, text };
        }
      }
    } else {
      const stepsLength = Math.min(domainTo, 8);
      const step = (domainTo - domainFrom) / stepsLength;
      const roundCof = domainTo >= 400 ? 50 : 1;

      for (let i = 1; i < stepsLength + 1; i++) {
        const number = domainFrom !== 0 && i === 1 ?
          Math.round(domainFrom / roundCof) * roundCof :
          Math.round(((i * step) + domainFrom) / roundCof) * roundCof;
        const text = kFormatter(number);

        if (!steps[text]) {
          steps[text] = { number, text };
        }
      }
    }
    return steps;
  };

  const steps = Object.values(getLegendSteps()).sort((a, b) => a.number - b.number);

  const legend = element
    .attr('width', legendCell.width + 35)
    .attr('height', steps.length * legendCell.height)
    .append('g')
    .selectAll('.legend')
    .data(steps)
    .enter()
    .append('g')
    .attr('class', 'legend');

  legend.append('rect')
    .attr('x', 0)
    .attr('y', (d, i) => legendCell.height * i)
    .attr('width', legendCell.width)
    .attr('height', legendCell.height)
    .style('fill', d => mainColors(d.number));

  legend.append('text')
    .attr('class', 'legend-text')
    .text(d => parseFloat(d.text.toFixed(1)))
    .attr('width', legendCell.width)
    .style('font-size', '15px')
    .attr('text-anchor', 'middle')
    .attr('x', legendCell.width + 20)
    .attr('y', (d, i) => (legendCell.height * i) + (legendCell.height / 1.5));
}
