import {
  SORT,
  CHECK_ITEM,
  CHECK_ALL,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../../common/SimpleTable/constants';

import {
  NETWORK_ANALYSIS_RESULT_TABLE,
  REMOVE_CONCEPT,
  NETWORK_ANALYSIS_RESULT_TABLE_RESET,
  NETWORK_ANALYSIS_REQUEST,
  LOADING,
  NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE,
  NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE,
  NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV,
  NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST,
} from './constants';

export const exportAllToCSVAction = data => (
  {
    type: NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV,
    data,
  }
);


export const firstNodeTermFilterChange = data => (
  {
    type: NETWORK_ANALYSIS_FIRST_NODE_TERM_FILTER_CHANGE,
    data,
  }
);

export const firstNodeCategoryFilterChange = data => (
  {
    type: NETWORK_ANALYSIS_FIRST_NODE_CATEGORY_FILTER_CHANGE,
    data,
  }
);

export const reset = () => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE_RESET,
  }
);

export const networkAnalysis = data => (
  {
    type: NETWORK_ANALYSIS_REQUEST,
    data,
  }
);

export const sort = data => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + SORT,
    data,
  }
);

export const checkAll = data => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelection = () => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + INVERT_SELECTION,
  }
);

export const removeSelected = () => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + REMOVE_SELECTED,
  }
);

export const removeConcept = data => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + REMOVE_CONCEPT,
    data,
  }
);

export const loading = () => (
  {
    type: NETWORK_ANALYSIS_RESULT_TABLE + LOADING,
  }
);

export const getShortConceptsDetailsForNetworkAnalysisAction = data => (
  {
    type: NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST,
    data,
  }
);
