import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

// Constants
import { CooccurrenceEnum } from '../../../../constantsCommon';
import {
  HeatmapTypeEnum,
  HeatmapSortByEnum,
  HeatmapSortDirEnum,
} from '../constants';
import {formatCellLineSelectionHeatmapData} from '../utils';

export const getHeatmapDataAction = createAction('@@heatmap/GET_DATA');
export const getNetworkAnalysisHeatmapDataAction = createAction('@@heatmap/GET_NETWORK_ANALYSIS_DATA');
export const setCellLinesHeatmapDataAction = createAction('@@heatmap/GET_CELL_LINES_DATA');
export const setHeatmapDataAction = createAction('@@heatmap/SET_DATA');
export const throwHeatmapErrorAction = createAction('@@heatmap/THROW_ERROR');
export const sortHeatmapDataAction = createAction('@@heatmap/SORT_DATA');
export const sortHeatmapRowsAction = createAction('@@heatmap/SORT_ROWS');
export const sortHeatmapColumnsAction = createAction('@@heatmap/SORT_COLUMNS');
export const clearHeatmapAction = createAction('@@heatmap/CLEAR');
export const getHeatmapCSVAction = createAction('@@heatmap/GET_CSV');
export const setHeatmapCSVAction = createAction('@@heatmap/SET_CSV');
export const setHeatmapInitialDataAction = createAction('@@heatmap/SET_INITIAL_DATA');
export const setHeatmapInitialCellLinesDataAction = createAction('@@heatmap/SET_INITIAL_CELL_LINES_DATA');
export const setHeatmapInitialNetworkDataAction = createAction('@@heatmap/SET_INITIAL_NETWORK_DATA');
export const swapHeatmapAxisAction = createAction('@@heatmap/SWAP_AXIS');
export const toggleHeatmapSpinnerAction = createAction('@@heatmap/TOGGLE_SPINNER');
export const setScoreTypeAction = createAction('@@heatmap/SET_SCORE_TYPE');

const initialState = fromJS({
  data: null,
  dataForSave: null,
  loading: false,
  error: null,
  sorting: {
    sortBy: HeatmapSortByEnum.ROW,
    sortDirection: HeatmapSortDirEnum.DESC,
    sortKey: null,
  },
  sortRows: HeatmapSortDirEnum.DESC,
  sortColumns: HeatmapSortDirEnum.DESC,
  type: HeatmapTypeEnum.PUBS,
  scoreType: CooccurrenceEnum.ABSTRACT,
  csvData: null,
  initialData: null,
  initialNetworkData: null,
  axisSwaped: false,
  spinner: false,
});

const reducer = handleActions(
  {
    [getHeatmapDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setHeatmapDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload.data))
        .update('dataForSave', () => (payload.dataForSave ? fromJS(payload.dataForSave) : null))
        .update('type', () => fromJS(payload.type))
        .update('loading', () => false)
        .update('error', () => null),
    [setCellLinesHeatmapDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(formatCellLineSelectionHeatmapData(payload.data, fromJS(payload.scoreType))))
        .update('dataForSave', () => (payload.data ? fromJS(payload.data) : null))
        .update('type', () => HeatmapTypeEnum.SCORE)
        .update('scoreType', () => fromJS(payload.scoreType))
        .update('loading', () => false)
        .update('error', () => null),
    [throwHeatmapErrorAction]: (state, { payload }) =>
      state
        .update('data', () => null)
        .update('dataForSave', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [sortHeatmapDataAction]: (state, { payload }) =>
      state
        .update('sorting', () => fromJS(payload.sorting))
        .update('data', () => fromJS(payload.data)),
    [sortHeatmapRowsAction]: (state, { payload }) =>
      state
        .update('sortRows', () => fromJS(payload.sorting))
        .update('data', () => fromJS(payload.data)),
    [sortHeatmapColumnsAction]: (state, { payload }) =>
      state
        .update('sortColumns', () => fromJS(payload.sorting))
        .update('data', () => fromJS(payload.data)),
    [setHeatmapCSVAction]: (state, { payload }) =>
      state
        .update('csvData', () => fromJS(payload)),
    [setHeatmapInitialDataAction]: (state, { payload }) =>
      state
        .update('initialData', () => fromJS(payload))
        .update('initialNetworkData', () => null)
        .update('initialCellLinesData', () => null),
    [setHeatmapInitialNetworkDataAction]: (state, { payload }) =>
      state
        .update('initialData', () => null)
        .update('initialNetworkData', () => fromJS(payload))
        .update('initialCellLinesData', () => null),
    [setHeatmapInitialCellLinesDataAction]: (state, { payload }) =>
      state
        .update('initialData', () => null)
        .update('initialNetworkData', () => null)
        .update('initialCellLinesData', () => fromJS(payload)),
    [swapHeatmapAxisAction]: (state, { payload }) =>
      state
        .update('initialData', initialData => (
          !state.get('axisSwaped') && initialData ?
            fromJS({
              conceptIds: initialData.get('connectedConceptIds'),
              connectedConceptIds: initialData.get('conceptIds'),
            }) : initialData))
        .update('dataForSave', () => fromJS(payload.dataForSave))
        .update('data', () => fromJS(payload.data))
        .update('axisSwaped', axisSwaped => !axisSwaped),
    [setScoreTypeAction]: (state, { payload }) =>
      state
        .update('scoreType', () => payload),
    [toggleHeatmapSpinnerAction]: (state, { payload }) =>
      state
        .update('spinner', () => payload),
    [clearHeatmapAction]: () => initialState,
  },
  initialState
);

export default reducer;
