import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import ChangePasswordForm from './ChangePasswordForm';
import Error from '../../common/Error/Error';
import TwoFactorAuthentication from '../TwoFactorAuthentication/TwoFactorAuthentication';
// Store
import { changePassword } from './actions';
// Styles
import './ChangePassword.css';

const propTypes = {
  error: PropTypes.string,
  changePassword: PropTypes.func,
};

const ChangePassword = (props) => {
  const {
    error,
    changePassword,
  } = props;
  
  return (
    <div className="change-password-content">
      <div className="flex-grid">
        <div className="col-1 text-left">
          <h3 className="title3">
            Change Password
          </h3>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col-1 text-left">
          <Error
            show={!!error}
            error={error}
            noTitle={true}
          />
          <ChangePasswordForm onSubmit={changePassword} />
        </div>
      </div>
      <TwoFactorAuthentication />
    </div>
  );
};

ChangePassword.propTypes = propTypes;

function mapStateToProps(state) {
  return { error: state.get('changePassword').get('error') };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword() {
      dispatch(changePassword());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
