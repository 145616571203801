import { takeEvery, call, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';

// Store
import * as a from './actions';
import * as s from './selectors';
import { getProjectIdForRequest, getProjectTypeForRequest } from '../../../Projects/ProjectsTabs/utils';
import { getProjectsOptionsSelector } from '../../../Projects/ProjectsTabs/store/selectors';

function* savePublicationsToProjectWorker({ payload }) {
  try {
    const { ids } = payload;
    const projectSelector = yield select(s.selectProjectSelector);
    const options = yield select(getProjectsOptionsSelector);
    const projectId = projectSelector ? projectSelector.project.id : options[0].id;
    const projectTypeForRequest = getProjectTypeForRequest(projectId);
    const projectIdForRequest = getProjectIdForRequest(projectId);
    const requestData = {
      publicationIds: ids,
      id: projectIdForRequest,
      type: projectTypeForRequest,
    };
    yield call(Api.savePublicationsToProject, requestData);
  } catch (e) {
    console.log(e.message);
  }
}

function* selectProjectModalSaga() {
  yield takeEvery(a.savePublicationsToProjectAction, savePublicationsToProjectWorker);
}

export default selectProjectModalSaga;
