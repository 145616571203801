import { call, put, takeEvery } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Utils
import { history } from '../../../index';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { mapAndFormatConceptsToSave } from '../../Sets/SaveAsSet/utils';
// Store
import { updateSaveAsSetConceptsAction } from '../../Sets/SaveAsSet/store/actions';
import {
  CONCEPT_DETAILS_REQUESTED,
  CONCEPT_DETAILS_SUCCEEDED,
  CONCEPT_DETAILS_FAILED,
  CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED,
  CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_FAILED,
  CONCEPT_CATEGORY_ANALYSIS_REQUESTED,
  CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED,
  CONCEPT_CATEGORY_ANALYSIS_FAILED,
  CONCEPT_CATEGORY_ANALYSIS_LOADING,
} from './constants';

function* details(action) {
  try {
    const id = action.data;
    const response = yield call(Api.concept, { id });
    yield put({ type: CONCEPT_DETAILS_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: CONCEPT_DETAILS_FAILED, message: e.message });
  }
}

function* analysis(action) {
  try {
    const id = action.data;
    yield put({ type: CONCEPT_CATEGORY_ANALYSIS_LOADING });
    const response = yield call(Api.conceptCategoryAnalysis, { id });
    yield put({ type: CONCEPT_CATEGORY_ANALYSIS_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: CONCEPT_CATEGORY_ANALYSIS_FAILED, message: e.message });
  }
}

function* shortDetails(action) {
  const { postAction } = action.data;

  try {
    const ids = action.data.concepts;
    const response = yield call(Api.getShortConceptsDetails, { post: ids });
    if (postAction === 'saveAsSet') {
      yield put(updateSaveAsSetConceptsAction(mapAndFormatConceptsToSave(response.data)));
      history.push(`${RELATIVE_PATH}/sets/add/personal`);
    }
  } catch (e) {
    yield put({ type: CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_FAILED, message: e.message });
    if (postAction === 'analyze') {
      history.push(`${RELATIVE_PATH}/analytics`);
    }
  }
}

function* conceptDetailsSaga() {
  yield takeEvery(CONCEPT_DETAILS_REQUESTED, details);
  yield takeEvery(CONCEPT_CATEGORY_ANALYSIS_REQUESTED, analysis);
  yield takeEvery(CONCEPT_DETAILS_SHORT_CONCEPTS_DETAILS_REQUESTED, shortDetails);
}

export default conceptDetailsSaga;
