import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

// Components
import Loader from '../../../../common/Loader/Loader';
import PublicationsList from '../../../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../../../common/SimpleTable/SimpleTable';
import PatentTable from '../../../../GeneDetails/Patents/PatentTable';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../../../Concept/ShortConceptCard/ShortConceptCardCell';
import TargetCandidatesPairsCharts from '../Components/TargetCandidatesPairsCharts';
// Utils
import {
  AVERAGE_SYMBOL_LENGTH,
  convertTableToCSVText,
  exportToFile,
} from '../../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../../constantsCommon';
// Store
import { getTargetCandidatesErrorSelector, getTargetCandidatesLoadingSelector } from '../../../store/selectors';
import {
  targetCandidatesPairsChangePageAction,
  setTargetCandidatesPairsFilterByGeneValueAction,
  sortTargetCandidatesPairsDataAction,
} from '../store/reducer';
import * as SELECTORS from '../store/selectors';

const propTypes = {
  tableData: PropTypes.instanceOf(Object),
  exportData: PropTypes.instanceOf(Array),
  setFilterByGeneValue: PropTypes.func,
  changePage: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  setName: PropTypes.string,
  filterByGeneValue: PropTypes.string,
};

class TargetCandidatesPairsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicationsPopup: false,
      antibodiesPopup: {
        id: null,
        name: null,
        show: false,
      },
      chartsPopup: {
        firstGene: null,
        secondGene: null,
        show: false,
      },
    };
    this.columnsWidth = [20, 10];
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.columns = null;
    this.simpleTableRef = React.createRef(null);
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const { changePage } = this.props;
    changePage(page.selected);
  };

  exportToCSV = (columns) => {
    const { exportData, setName = 'table-export' } = this.props;
    const result = convertTableToCSVText(
      exportData,
      columns.filter(column => column.exportCSV),
    );
    exportToFile(this.downloadLink, result, setName);
  };

  openPublicationsPopup = (data) => {
    const { firstGene, secondGene } = data;
    this.conceptsNames = [firstGene.name, secondGene.name];
    this.conceptsIds = [firstGene.id, secondGene.id];
    this.setState({ publicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ publicationsPopup: false });
  };

  openAntibodiesPopup = (id, name) => {
    this.setState({
      antibodiesPopup: {
        id,
        name,
        show: true,
      },
    });
  };

  closeAntibodiesPopup = () => {
    this.setState({
      antibodiesPopup: {
        id: null,
        name: null,
        show: false,
      },
    });
  };

  openChartsPopup = (firstGene, secondGene) => {
    this.setState({
      chartsPopup: {
        firstGene: {
          id: String(firstGene.id),
          name: firstGene.name,
        },
        secondGene: {
          id: String(secondGene.id),
          name: secondGene.name,
        },
        show: true,
      },
    });
  };

  closeChartsPopup = () => {
    this.setState({
      chartsPopup: {
        firstGeneId: null,
        secondGeneId: null,
        show: false,
      },
    });
  };

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  getRowHeight = (width, index) => {
    const { tableData: { data } } = this.props;
    const {
      cancer,
      firstGene,
      secondGene,
    } = data[index];
    const padding = 25;
    const lineHeight = 30;
    const iconWidth = 25;

    const cellWidth = this.getColumnPercentWidth(width, this.columnsWidth[0]) - padding;
    const getRows = (stringLength, itemWidth) =>
      Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / itemWidth);

    const maxRows = Math.max(
      getRows(cancer.name.length + (cancer.label && cancer.label.length), cellWidth),
      getRows(firstGene.name.length, cellWidth - iconWidth),
      getRows(secondGene.name.length, cellWidth - iconWidth),
    );

    return Math.max((maxRows * lineHeight), 50) + padding;
  };

  genesCell = ({ id, name }, rowIndex) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  filterByGene = (e) => {
    const { setFilterByGeneValue } = this.props;
    setFilterByGeneValue(e.target.value);
  };

  showTooltipCell = ({ id, name, label }, rowIndex) => {
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    const labelElement = () => label && <><br /> [{label}]</>;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
        label={labelElement()}
      />
    );
  };

  getColumns = (width) => {
    this.columns = [
      {
        label: 'Cancer',
        dataKey: 'cancerName',
        dataPath: ['cancer', 'name'],
        sortPath: ['cancer', 'name'],
        className: 'table-wrap__cell table-wrap__cell_left hexelect-tr',
        headerClassName: 'hexelect-th hexelect-th_br',
        width: this.getColumnPercentWidth(width, this.columnsWidth[0]),
        exportCSV: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.cancer ? this.showTooltipCell(rowData.cancer, rowIndex) : null
        ),
      },
      {
        label: 'Target 1',
        dataKey: 'firstGeneName',
        dataPath: ['firstGene', 'name'],
        sortPath: ['firstGene', 'name'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        width: this.getColumnPercentWidth(width, this.columnsWidth[0]),
        exportCSV: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.firstGene ? this.genesCell(rowData.firstGene, rowIndex) : null
        ),
      },
      {
        label: 'TPM Tumor',
        dataKey: 'firstGeneTmpMedian',
        dataPath: ['firstGene', 'tmpMedian'],
        sortPath: ['firstGene', 'tmpMedian'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { firstGene } }) => (
          <div className="table-wrap__cell-row">
            {firstGene.tmpMedian}
          </div>
        ),
      },
      {
        label: 'TPM normal',
        dataKey: 'firstGeneMedian',
        dataPath: ['firstGene', 'tissueMaxValue', 'median'],
        sortPath: ['firstGene', 'tissueMaxValue', 'median'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { firstGene: { tissueMaxValue } } }) => (
          <div className="table-wrap__cell-row">
            {tissueMaxValue.median}
          </div>
        ),
      },
      {
        label: 'Antibodies',
        dataKey: 'firstGeneAntibodiesCount',
        dataPath: ['firstGene', 'antibodiesCount'],
        sortPath: ['firstGene', 'antibodiesCount'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th hexelect-th_br',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { firstGene: { id, antibodiesCount, name } } }) => (
          antibodiesCount > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openAntibodiesPopup(id, name); }}
            >
              {antibodiesCount}
            </div> :
            <div className="table-wrap__cell-row">
              {antibodiesCount}
            </div>
        ),
      },
      {
        label: 'Target 2',
        dataKey: 'secondGeneName',
        dataPath: ['secondGene', 'name'],
        sortPath: ['secondGene', 'name'],
        width: this.getColumnPercentWidth(width, this.columnsWidth[0]),
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        exportCSV: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.secondGene ? this.genesCell(rowData.secondGene, rowIndex) : null
        ),
      },
      {
        label: 'TPM Tumor',
        dataKey: 'secondGeneTmpMedian',
        dataPath: ['secondGene', 'tmpMedian'],
        sortPath: ['secondGene', 'tmpMedian'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { secondGene } }) => (
          <div className="table-wrap__cell-row">
            {secondGene.tmpMedian}
          </div>
        ),
      },
      {
        label: 'TPM normal',
        dataKey: 'secondGeneMedian',
        dataPath: ['secondGene', 'tissueMaxValue', 'median'],
        sortPath: ['secondGene', 'tissueMaxValue', 'median'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { secondGene: { tissueMaxValue } } }) => (
          <div className="table-wrap__cell-row">
            {tissueMaxValue.median}
          </div>
        ),
      },
      {
        label: 'Antibodies',
        dataKey: 'secondGeneAntibodiesCount',
        dataPath: ['secondGene', 'antibodiesCount'],
        sortPath: ['secondGene', 'antibodiesCount'],
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th hexelect-th_br',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        exportCSV: true,
        cellRenderer: ({ rowData: { secondGene: { id, antibodiesCount, name } } }) => (
          antibodiesCount > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openAntibodiesPopup(id, name); }}
            >
              {antibodiesCount}
            </div> :
            <div className="table-wrap__cell-row">
              {antibodiesCount}
            </div>
        ),
      },
      {
        label: 'References',
        dataKey: 'countOfArticlesWithGenePair',
        width: this.getColumnPercentWidth(width, this.columnsWidth[1]),
        className: 'table-wrap__cell',
        headerClassName: 'hexelect-th',
        exportCSV: true,
        cellRenderer: ({ rowData }) => {
          const {
            firstGene,
            secondGene,
            countOfArticlesWithGenePair,
          } = rowData;
          return (
            <div className="table-wrap__cell-row table-wrap__cell-row_chart">
              {
                countOfArticlesWithGenePair > 0 ?
                  <div
                    className="table-wrap__cell-row link"
                    onClick={() => { this.openPublicationsPopup(rowData); }}
                  >
                    {rowData.countOfArticlesWithGenePair}
                  </div> :
                  <div className="table-wrap__cell-row">
                    {rowData.countOfArticlesWithGenePair}
                  </div>
              }
              <div className="chart-icons-wrap">
                <div
                  className="chart-icon"
                  onClick={() => { this.openChartsPopup(firstGene, secondGene); }}
                ><span />
                </div>
              </div>
            </div>
          );
        },
      },
    ];
    return this.columns;
  };

  render() {
    const {
      tableData: {
        data = [],
        page,
        totalPages,
        sorting: {
          sortBy,
          sortDirection,
        },
      },
      loading,
      error,
      filterByGeneValue,
    } = this.props;

    const {
      publicationsPopup,
      antibodiesPopup,
      chartsPopup,
    } = this.state;

    const tableSettings = {
      height: 500,
      headerHeight: 100,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      sortBy,
      sortDirection,
    };

    return (
      <div className="algorithm-table">
        {
          !loading && !error && data &&
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <div className="table-wrap" style={{ width }}>
                  <div
                    className="controls-block table-wrap__controls table-wrap__controls_filter"
                    style={{ width }}
                  >
                    {(filterByGeneValue || data.length > 0) &&
                    <div className="table-filter">
                      <input
                        name="Filter by genes"
                        placeholder="Filter by Genes"
                        onChange={this.filterByGene}
                        className="table-filter__input"
                        type="text"
                        value={filterByGeneValue}
                      />
                    </div>}
                    {data.length > 0 &&
                    <>
                      {
                        totalPages > 1 &&
                        <div className="table-wrap__pagination">
                          <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakClassName="break-me"
                            pageCount={totalPages}
                            forcePage={page}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                          />
                        </div>
                      }
                      <button
                        className="table__btn_export"
                        onClick={() => {
                          this.exportToCSV(this.columns);
                        }}
                      >
                        <span className="text-wrap">
                           Export to CSV
                        </span>
                      </button>
                      <a //eslint-disable-line
                        hidden={true}
                        ref={(ref) => {
                          this.downloadLink = ref;
                        }}
                      /></>}
                  </div>
                  {
                    data.length > 0 &&
                    <SimpleTable
                      innerRef={this.simpleTableRef}
                      data={data}
                      autoSize={true}
                      width={width}
                      columns={this.columns ? this.columns : this.getColumns(width)}
                      settings={{
                        ...tableSettings,
                        rowHeight: ({ index }) => this.getRowHeight(width, index),
                      }}
                      dynamicHeight={true}
                      sortAction={sortTargetCandidatesPairsDataAction}
                    />
                  }
                  {
                    data.length === 0 &&
                    <div className="algorithm-table__error">
                      There is no data to display
                    </div>
                  }
                </div> : null
            )}
          </AutoSizer>
        }
        <Loader isLoading={loading} />
        {
          error && !loading &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
        {
          publicationsPopup &&
          <ModalComponent
            isOpen={publicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
        {
          antibodiesPopup.show &&
          <ModalComponent
            isOpen={antibodiesPopup.show}
            closeCb={this.closeAntibodiesPopup}
          >
            <PatentTable
              geneName={antibodiesPopup.name}
              gene={String(antibodiesPopup.id)}
              itsNewConcept={true}
              parentClass="algorithm-antibodies"
              tableWidth={1000}
              hideInfo={true}
            />
          </ModalComponent>
        }
        {
          chartsPopup.show &&
          <ModalComponent
            isOpen={chartsPopup.show}
            closeCb={this.closeChartsPopup}
            customClassName="target-candidates-pairs-chart"
          >
            <TargetCandidatesPairsCharts
              firstGeneId={chartsPopup.firstGene.id}
              firstGeneName={chartsPopup.firstGene.name}
              secondGeneId={chartsPopup.secondGene.id}
              secondGeneName={chartsPopup.secondGene.name}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

TargetCandidatesPairsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    tableData: SELECTORS.getTargetCandidatesDataSelector(state),
    exportData: SELECTORS.getTargetCandidatesDataForExportSelector(state),
    loading: getTargetCandidatesLoadingSelector(state),
    error: getTargetCandidatesErrorSelector(state),
    setName: SELECTORS.getFormValues(state, 'setName'),
    filterByGeneValue: SELECTORS.getTargetCandidatesFilterByGeneValueSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(targetCandidatesPairsChangePageAction(data));
    },
    setFilterByGeneValue(data) {
      dispatch(setTargetCandidatesPairsFilterByGeneValueAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCandidatesPairsTable);

