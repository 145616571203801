import { createSelector } from 'reselect';

const currentScoringFormula = state => state.getIn(['setAnalysisMethodSelection', 'currentScoringFormula']);
const defaultScoringFormula = state => state.getIn(['setAnalysisMethodSelection', 'defaultScoringFormula']);
const validFormula = state => state.getIn(['setAnalysisMethodSelection', 'validFormula']);

export const getCurrentScoringFormula = createSelector(
  currentScoringFormula,
  data => data
);

export const getDefaultScoringFormula = createSelector(
  defaultScoringFormula,
  data => data
);

export const getValidFormulaKey = createSelector(
  validFormula,
  data => data
);
