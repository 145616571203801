import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';

const propTypesTissue = {
  tissue: PropTypes.instanceOf(Object),
  checkTissue: PropTypes.func,
  index: PropTypes.number,
  type: PropTypes.string,
  parentType: PropTypes.string,
};

class SingleTargetForCancerTissue extends React.Component {
  render() {
    const {
      tissue: {
        id,
        name,
        checked,
      },
      checkTissue,
      index,
      type,
      parentType,
    } = this.props;


    return (
      <Draggable
        draggableId={`${id}-${type}`}
        index={index}
        key={id}
      >
        {
          (provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={
                classNames(
                  'tissues-dnd-tissue',
                  { 'tissues-dnd-tissue_active': snapshot.isDragging }
                )
              }
              {...provided.draggableProps}
            >
              <Checkbox
                checked={checked}
                readOnly={true}
                onChange={() => { checkTissue(id, parentType); }}
                name={name}
              />
              <span
                className="tissues-dnd-tissue__name"
                title={name}
              >
                { name }
              </span>
              <div
                className="tissues-dnd-tissue__handle"
                {...provided.dragHandleProps}
              >
                .. .. ..
              </div>
            </div>
          )
        }
      </Draggable>
    );
  }
}

SingleTargetForCancerTissue.propTypes = propTypesTissue;

export default SingleTargetForCancerTissue;
