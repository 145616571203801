import { createSelector } from 'reselect';

const searchCategoryLoading = state => state.getIn(['searchRelatedConceptsReducer', 'loading']);
const searchCategoryError = state => state.getIn(['searchRelatedConceptsReducer', 'error']);

export const getSearchCategoryLoadingSelector = createSelector(
  searchCategoryLoading,
  data => data
);

export const getSearchCategoryErrorSelector = createSelector(
  searchCategoryError,
  data => data
);
