import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

import './ColorPicker.css';

const propTypes = {
  colorChanged: PropTypes.func,
  index: PropTypes.number,
  initialColor: PropTypes.string,
};

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.props.colorChanged({
      index: this.props.index,
      color: color.hex,
    });
  };

  render() {
    const styles = reactCSS({
      'default': { //eslint-disable-line
        color: {
          width: '100%',
          height: '100%',
          background: this.props.initialColor,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          borderTopRightRadius: '5px',
          overflow: 'hidden',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div className="colorPickerContainer">
        <div
          role="presentation"
          style={styles.swatch}
          onClick={this.handleClick}
        >
          <div style={styles.color} />
        </div>
        { this.state.displayColorPicker ?
          <div style={styles.popover}>
            <div
              role="presentation"
              style={styles.cover}
              onClick={this.handleClose}
            />
            <SketchPicker
              color={this.props.initialColor}
              onChange={this.handleChange}
            />
          </div> : null }
      </div>
    );
  }
}

ColorPicker.propTypes = propTypes;

export default ColorPicker;
