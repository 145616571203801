import { takeLatest } from 'redux-saga/effects';

import {
  getSetManagementDataAction,
  removeSetManagementDataItemAction,
  selectSetManagementDataItemForAnalysisAction,
  duplicateSetsAction,
  getSetForFilteringAction,
  downloadSetDataAction,
  setManagementSearchRedirectAction,
} from '../reducer';

import { getSetManagementDataWorker } from './workers/getSetManagementDataWorker';
import { removeSetManagementDataItemWorker } from './workers/removeSetManagementDataItemWorker';
import { selectSetManagementDataItemForAnalysisWorker } from './workers/selectSetManagementDataItemForAnalysisWorker';
import { duplicateSetManagementWorker } from './workers/duplicateSetManagement';
import { getSetForFilteringWorker } from './workers/getSetForFiltering';
import { downloadSetWorker } from './workers/downloadSet';
import { setManagementSearchRedirectWorker } from './workers/setManagementSearchRedirectWorker';

function* setManagementSaga() {
  yield takeLatest(getSetManagementDataAction, getSetManagementDataWorker);
  yield takeLatest(removeSetManagementDataItemAction, removeSetManagementDataItemWorker);
  yield takeLatest(selectSetManagementDataItemForAnalysisAction, selectSetManagementDataItemForAnalysisWorker);
  yield takeLatest(duplicateSetsAction, duplicateSetManagementWorker);
  yield takeLatest(getSetForFilteringAction, getSetForFilteringWorker);
  yield takeLatest(downloadSetDataAction, downloadSetWorker);
  yield takeLatest(setManagementSearchRedirectAction, setManagementSearchRedirectWorker);
}

export default setManagementSaga;
