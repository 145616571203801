import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getPublicationsGraphDataAction = createAction('@@publications-graph/GET_DATA');
export const setPublicationsGraphDataAction = createAction('@@publications-graph/SET_DATA');
export const clearPublicationsGraphDataAction = createAction('@@publications-graph/CLEAR_DATA');
export const throwPublicationsGraphErrorAction = createAction('@@publications-graph/THROW_ERROR');

const initialState = fromJS({
  graphData: {},
  loading: true,
  error: null,
});

const reducer = handleActions(
  {
    [getPublicationsGraphDataAction]: state =>
      state
        .update('loading', () => true),
    [setPublicationsGraphDataAction]: (state, { payload }) =>
      state
        .update('loading', () => false)
        .update('error', () => null)
        .update('graphData', () => fromJS(payload)),
    [throwPublicationsGraphErrorAction]: (state, { payload }) =>
      state
        .update('loading', () => false)
        .update('error', () => fromJS(payload)),
    [clearPublicationsGraphDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
