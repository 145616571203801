import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Components
import Loader from '../../common/Loader/Loader';
import BarChartDiagram from '../../graphics/BarChartDiagram/BarChartDiagram';
import ConditionOfDysregulationTable from './ConditionOfDysregulationTable';
// Utils
import { getParentBlockWidth } from '../../Utils/Utils';
import { GOLD_COLOR, PURPLE_COLOR } from '../../../constantsCommon';
// Store
import {
  getSectionChart,
  getLoading,
  getError,
} from './redux/selectors';
import { getDataAsyncAction } from './redux/actions';
import { loadNextChapterAction } from '../actions';
// Styles
import './ConditionOfDysregulationChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  sectionChart: PropTypes.instanceOf(Array).isRequired,
  initSectionChart: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
  geneName: PropTypes.string,
};

class ConditionOfDysregulationChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: false,
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      initSectionChart,
      itsNewConcept,
      loadNextChapter,
      sectionChart,
    } = this.props;

    if (itsNewConcept || isEmpty(sectionChart)) {
      initSectionChart({ id: gene });
    } else {
      loadNextChapter('functional-characterization');
    }
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      error,
      loading,
      sectionChart,
      geneName,
    } = this.props;

    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div>
        {
          !loading &&
          <div>
            <div className="gene-details-section-title title2">
              <span>Conditions of Dysregulation</span>
              <button
                className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                onClick={() => this.toggleInfoText(showInfoText)}
              />
            </div>
            {
              showInfoText &&
              <div className="gene-details-info__main">
                <p>
                  The Conditions of Dysregulation section contains an overview of differential expression experiments for which the gene is known to be dysregulated from
                  <a className="link" href="https://www.ebi.ac.uk/gxa/home" target="blank"> Expression Atlas</a> and
                  <a className="link" href="https://www.ncbi.nlm.nih.gov/geo/" target="blank"> Gene Expression Omnibus</a>.
                </p>
                <p>The bar charts shows the top 10 upregulated (in gold) and downregulated (in purple) experiments based on Log2Fold change value. Hover over the bar to see the Log2Fold value.</p>
                The table contains the following information:
                <ul>
                  <li>The condition which the differential expression experiment assesses</li>
                  <li>The Log2fold change measurement</li>
                  <li>The source of the biopsy such as tissue, cell type or cell line.</li>
                  <li>A link to the detail page of the experiment. Click this link to access the original Expression Atlas or Gene Expression Omnibus page.</li>
                </ul>
              </div>
            }
            <div className="gene-details-section-body">
              {
                sectionChart.length ?
                  <div>
                    <BarChartDiagram
                      geneName={geneName}
                      chartName="Conditions_of_Dysregulation"
                      chartTitle="Conditions of Dysregulation"
                      source="condition-of-dysregulation-diagram"
                      data={sectionChart}
                      xTooltipKey="conditions"
                      color={{ positive: GOLD_COLOR, negative: PURPLE_COLOR }}
                    />
                    <ConditionOfDysregulationTable
                      geneName={geneName}
                      width={chapterWidth}
                    />
                  </div> :
                  <span className="gene-details__no-data">
                    There is no data to display
                  </span>
              }
            </div>
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            {error}
          </div>
        }
      </div>
    );
  }
}

ConditionOfDysregulationChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sectionChart: getSectionChart(state),
    loading: getLoading(state),
    error: getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionChart(data) {
      dispatch(getDataAsyncAction(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionOfDysregulationChapter);
