import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { SORT } from '../../../common/SimpleTable/constants';
import { setTypesEnum } from '../../../Sets/SaveAsSet/constants';
import { convertTableToCSVText, exportToFile } from '../../../Utils/Utils';
import { EMPTY_FILTER } from './enum';
// Store
import {
  updateSaveAsSetTypeAction,
  updateSaveAsSetConceptsAction,
} from '../../../Sets/SaveAsSet/store/actions';
import {
  getActiveSets,
  setAnalysisSets,
  startSetSelector,
} from '../../common/SetAnalysis/selectors';
import {
  getNetworkAnalysisSettings,
  getFactorLogicValue,
  getSelectedNetworkAnalysisTissue,
} from '../../common/SetAnalysisMethodSelection/selectors';
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import { getHeatmapDataForSaveSelector } from '../../../graphics/Heatmap/store/selectors';
import {
  NETWORK_ANALYSIS_REQUEST,
  NETWORK_ANALYSIS_FAILED,
  NETWORK_ANALYSIS_SUCCESS,
  LOADING,
  NETWORK_ANALYSIS_RESULT_TABLE,
  NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV,
  NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST,
} from './constants';
import {
  getFirstNodeCategoryFilterValue,
  getFirstNodeTermFilterValue,
  getTotalItems,
  tableSorting,
} from './selectors';

export function* getNetworkAnalysisRequestParams(action) {
  let requestData = {};
  try {
    const settings = yield select(getNetworkAnalysisSettings);
    const conceptsToAnalyse = yield select(selectedDataForAnalysis);
    const tissue = yield select(getSelectedNetworkAnalysisTissue);
    const sets = yield select(setAnalysisSets);
    const firstNodeCategoryFilterValue = yield select(getFirstNodeCategoryFilterValue);
    const firstNodeTermFilterValue = yield select(getFirstNodeTermFilterValue);
    const factorLogicValue = yield select(getFactorLogicValue);
    const {
      sortBy,
      sortDirection,
    } = yield select(tableSorting);

    let startSet = yield select(startSetSelector);
    startSet = sets[0].setType === 'EFFECT' ? sets[0].id : startSet;

    requestData = Object.assign({}, action.data);
    if (!requestData.post) {
      requestData.post = {};
    }

    if (!requestData.params) {
      requestData.params = {
        size: 20,
        page: 0,
      };
    }

    const filter = {
      firstNodeTerm: firstNodeTermFilterValue,
      fNodeCategory: firstNodeCategoryFilterValue === EMPTY_FILTER ? null : firstNodeCategoryFilterValue,
    };

    requestData.params = {
      ...requestData.params,
      sort: `${sortBy},${sortDirection}`,
    };
    requestData.post.startSetIds = conceptsToAnalyse.map(concept => (
      concept.id
    ));
    requestData.post.tfLogic = factorLogicValue;
    requestData.post.filter = filter;
    requestData.post.targetGeneId = settings.gene && (settings.gene.id || settings.gene.data);
    requestData.post.startSet = startSet;
    requestData.post.targetTissue = tissue;
    requestData.post.additionalSet = settings.selectedSetId;
    requestData.post.heatmap = yield select(getHeatmapDataForSaveSelector);
  } catch (e) {
    console.log(e);
  }
  return requestData;
}

function* saga(action) {
  try {
    const requestData = yield getNetworkAnalysisRequestParams(action);

    yield put({ type: NETWORK_ANALYSIS_RESULT_TABLE + LOADING });
    const response = yield call(Api.buildNetworkPath, requestData);
    yield put({ type: NETWORK_ANALYSIS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: NETWORK_ANALYSIS_FAILED, message: e.response.data.message });
  }
}

function* getNetworkAnalysisConceptsInfoSaga(action) {
  try {
    const settings = yield select(getNetworkAnalysisSettings);
    const conceptsToAnalyse = yield select(selectedDataForAnalysis);
    const tissue = yield select(getSelectedNetworkAnalysisTissue);
    const sets = yield select(setAnalysisSets);
    const firstNodeCategoryFilterValue = yield select(getFirstNodeCategoryFilterValue);
    const firstNodeTermFilterValue = yield select(getFirstNodeTermFilterValue);
    const factorLogicValue = yield select(getFactorLogicValue);
    const startSet = sets[0].setType === 'EFFECT' ? sets[0].id : null;
    const requestData = Object.assign({}, action.data);
    if (!requestData.post) {
      requestData.post = {};
    }

    const filter = {
      firstNodeTerm: firstNodeTermFilterValue,
      fNodeCategory: firstNodeCategoryFilterValue === EMPTY_FILTER ? null : firstNodeCategoryFilterValue,
    };

    requestData.post.startSetIds = conceptsToAnalyse.map(concept => (
      concept.id
    ));
    requestData.post.tfLogic = factorLogicValue;
    requestData.post.filter = filter;
    requestData.post.targetGeneId = settings.gene && (settings.gene.id || settings.gene.data);
    requestData.post.startSet = startSet;
    requestData.post.targetTissue = tissue;
    requestData.post.additionalSet = settings.selectedSetId;
    let columnToSave;
    switch (action.data.dataKey) {
      case 'lastNode':
        columnToSave = 'LAST';
        break;
      case 'firstNode':
        columnToSave = 'FIRST';
        break;
      default:
        columnToSave = action.data.dataKey;
    }
    requestData.columnToSave = columnToSave;

    const response = yield call(Api.getAsSet, requestData);
    const {
      data: {
        simpleSet: {
          content,
        },
      },
    } = response;

    const activeSets = yield select(getActiveSets);

    const resultOriginalDataFromActiveSets = activeSets.reduce((acc, activeSet) =>
      [...acc, ...activeSet.originalData], []);

    const contentWithIds = content.map((item, index) => ({
      ...item,
      id: index + 1,
    }));

    const cleanedUpContent = contentWithIds.map((item) => {
      const {
        id,
        mappedGenes,
        score,
      } = item;
      return {
        id,
        mappedGenes,
        score,
      };
    });

    const cleanedUpContentWithScores = cleanedUpContent.map((item) => {
      const elementByContentIdInActiveSets = resultOriginalDataFromActiveSets.find(activeSetItem =>
        item.mappedGenes[0].id === activeSetItem.id
      );
      if (elementByContentIdInActiveSets) {
        item.score = item.score || Number(elementByContentIdInActiveSets.measure) || 0; // eslint-disable-line
      } else {
        item.score = item.score || 0; // eslint-disable-line
      }
      return item;
    });

    const setType =
      cleanedUpContentWithScores.some(item => item.score) ?
        setTypesEnum.EFFECT :
        setTypesEnum.SIMPLE;
    yield put(updateSaveAsSetTypeAction(setType));
    yield put(updateSaveAsSetConceptsAction(cleanedUpContentWithScores));
  } catch (e) {
    yield put({ type: NETWORK_ANALYSIS_FAILED, message: e.message });
  }
}


function* exportAllToCSVSaga(action) {
  try {
    const settings = yield select(getNetworkAnalysisSettings);
    const conceptsToAnalyse = yield select(selectedDataForAnalysis);
    const tissue = yield select(getSelectedNetworkAnalysisTissue);
    const sets = yield select(setAnalysisSets);
    const firstNodeCategoryFilterValue = yield select(getFirstNodeCategoryFilterValue);
    const firstNodeTermFilterValue = yield select(getFirstNodeTermFilterValue);
    const factorLogicValue = yield select(getFactorLogicValue);
    const totalItems = yield select(getTotalItems);
    const {
      sortBy,
      sortDirection,
    } = yield select(tableSorting);
    const startSet = sets[0].setType === 'EFFECT' ? sets[0].id : null;
    const requestData = Object.assign({}, action.data);
    if (!requestData.post) {
      requestData.post = {};
    }

    if (!requestData.params) {
      requestData.params = {
        size: totalItems,
        page: 0,
      };
    }

    const filter = {
      firstNodeTerm: firstNodeTermFilterValue,
      fNodeCategory: firstNodeCategoryFilterValue === EMPTY_FILTER ? null : firstNodeCategoryFilterValue,
    };

    requestData.params = {
      ...requestData.params,
      sort: `${sortBy},${sortDirection}`,
    };
    requestData.post.startSetIds = conceptsToAnalyse.map(concept => (
      concept.id
    ));
    requestData.post.tfLogic = factorLogicValue;
    requestData.post.filter = filter;
    requestData.post.targetGeneId = settings.gene && (settings.gene.id || settings.gene.data);
    requestData.post.startSet = startSet;
    requestData.post.targetTissue = tissue;
    requestData.post.additionalSet = settings.selectedSetId;

    const response = yield call(Api.buildNetworkPath, requestData);
    const result = convertTableToCSVText(response.data.content, action.data.columns.filter(column => (
      column.exportCSV
    )));
    exportToFile(action.data.downloadLink, result, 'table-export');
  } catch (e) {
    console.log(e);
  }
}

function* pathwayAnalysis() {
  yield takeLatest(NETWORK_ANALYSIS_EXPORT_ALL_TO_CSV, exportAllToCSVSaga);
  yield takeLatest(NETWORK_ANALYSIS_REQUEST, saga);
  yield takeLatest(NETWORK_ANALYSIS_RESULT_TABLE + SORT, saga);
  yield takeLatest(NETWORK_ANALYSIS_CONCEPT_LIST_FOR_SAVE_AS_SET_REQUEST, getNetworkAnalysisConceptsInfoSaga);
}

export default pathwayAnalysis;
