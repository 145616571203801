import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

// Components
import FormCheckbox from '../../../../common/FormElements/FormCheckbox';

const propTypes = {
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  infoTooltip: PropTypes.instanceOf(Object),
};

const CreateSetFilterCheckbox = (props) => {
  const { fieldName, fieldLabel, infoTooltip } = props;

  return (
    <div className="create-set-filter__block">
      <Field
        name={fieldName}
        component={FormCheckbox}
        labelValue={fieldLabel}
        infoTooltip={infoTooltip}
      />
    </div>
  );
};

CreateSetFilterCheckbox.propTypes = propTypes;

export default CreateSetFilterCheckbox;
