import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import isEmpty from 'lodash.isempty';

// Utils
import { getParentBlockWidth, AVERAGE_SYMBOL_LENGTH, formatDate } from '../../Utils/Utils';
// Components
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ExportTable from '../../common/ExportTable/ExportTable';
import PatentPopup from './PatentPopup/PatentPopup';
// Store
import {
  getPatentInfo,
  getPatentInfoPopup,
  getPatentInfoPopupLoading,
  getPatentInfoError,
  getPatentsSorting,
  getPatents,
  getChapterLoading,
  getExportLoading,
} from './redux/selectors';
import {
  getPatentsAction,
  getPatentInfoAction,
  togglePatentInfoPopupAction,
  patentsSortAction,
} from './redux/actions';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  exportLoading: PropTypes.bool,
  patents: PropTypes.instanceOf(Object),
  patentInfo: PropTypes.instanceOf(Object),
  parentClass: PropTypes.string,
  patentsSorting: PropTypes.instanceOf(Object).isRequired,
  getPatentsData: PropTypes.func,
  getPatentsPatentInfo: PropTypes.func,
  patentsLoading: PropTypes.bool,
  patentInfoPopup: PropTypes.instanceOf(Object),
  patentInfoPopupLoading: PropTypes.bool,
  patentsError: PropTypes.string,
  patentInfoError: PropTypes.string,
  togglePatentInfoPopup: PropTypes.func,
  tableWidth: PropTypes.number,
  itsNewConcept: PropTypes.bool,
};

class PatentTable extends React.Component {
  constructor(props) {
    super(props);
    this.simpleTableRef = React.createRef(null);
    this.initPage();
  }

  initPage = () => {
    const {
      gene,
      patents,
      getPatentsData,
      itsNewConcept,
    } = this.props;

    if (itsNewConcept || isEmpty(patents)) {
      getPatentsData({ id: gene });
    }
  };

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePatentClick = (id, type) => {
    this.props.getPatentsPatentInfo({ id, type });
  };

  handlePageClick = (pageNumber) => {
    const {
      gene,
      getPatentsData,
    } = this.props;

    getPatentsData({
      id: gene,
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  getAllTableDataForExport = (link, columns) => {
    const {
      gene,
      geneName,
      getPatentsData,
      patents,
    } = this.props;

    getPatentsData({
      id: gene,
      export: true,
      params: {
        size: patents.totalElements,
        page: 0,
      },
    }, {
      link,
      columns,
      geneName,
    });
  };

  handlePageSort = () => {
    const {
      gene,
      patents: {
        number,
      },
      getPatentsData,
    } = this.props;

    getPatentsData({
      id: gene,
      params: {
        size: 20,
        page: number,
      },
    });
  };

  getColumnPercentWidth = (percent) => {
    const { parentClass, tableWidth } = this.props;
    const screenWidth = tableWidth || getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const {
      patents: {
        content,
      },
    } = this.props;
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(60);
    const string = content[index].patentTitle.length;
    const stringRows = Math.ceil((string * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    return (stringRows * lineHeight) + padding;
  };

  render() {
    const {
      patents: {
        content = [],
        number,
        totalPages,
      },
      patentsSorting: {
        sortBy,
        sortDirection,
      },
      patentsLoading,
      parentClass,
      patentInfo,
      patentInfoPopup,
      togglePatentInfoPopup,
      patentInfoPopupLoading,
      patentsError,
      patentInfoError,
      tableWidth,
      exportLoading,
    } = this.props;

    const tableSettings = {
      height: 500,
      width: tableWidth || getParentBlockWidth(parentClass),
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Patent Title',
        dataKey: 'patentTitle',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(50),
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData }) => (
          <a
            href={rowData.patentLink}
            target="blank"
            className="table-wrap__cell-row link lowercase"
          >
            {rowData.patentTitle}
          </a>
        ),
      },
      {
        label: 'Publication Date',
        dataKey: 'documentDate',
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(15),
        exportCSV: true,
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {formatDate(rowData.documentDate)}
          </div>
        ),
        csvRenderer: rowData => formatDate(rowData),
      },
      {
        label: 'Amino acids',
        dataKey: 'aminoAcids',
        exportCSV: true,
        width: this.getColumnPercentWidth(10),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => {
          if (rowData.aminoAcids > 0) {
            return (
              <div
                className="table-wrap__cell-row link"
                onClick={() => { this.handlePatentClick(rowData.patentId, 'SEQUENCES'); }}
              >
                {rowData.aminoAcids}
              </div>
            );
          }
          return <div className="table-wrap__cell-row">0</div>;
        },
      },
      {
        label: 'Compound',
        dataKey: 'chemicals',
        exportCSV: true,
        width: this.getColumnPercentWidth(10),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => {
          if (rowData.chemicals > 0) {
            return (
              <div
                className="table-wrap__cell-row link"
                onClick={() => { this.handlePatentClick(rowData.patentId, 'CHEMICALS'); }}
              >
                {rowData.chemicals}
              </div>
            );
          }
          return <div className="table-wrap__cell-row">0</div>;
        },
      },
      {
        label: 'Type',
        dataKey: 'type',
        dataPath: ['type', 'name'],
        exportCSV: true,
        width: this.getColumnPercentWidth(15),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.type.name}
          </div>
        ),
      },
    ];

    return (
      <div className="patents__chapter">
        {
          content.length > 0 && !patentsLoading &&
          <div className="table-wrap">
            {
              <div className="controls-block-2items">
                {
                  totalPages > 1 &&
                  <div className="paginationContainer">
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={number}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                }
                {
                  content && content.length > 0 &&
                  <ExportTable
                    backendCall={(link) => { this.getAllTableDataForExport(link, columns); }}
                    exportLoading={exportLoading}
                  />
                }
              </div>
            }
            <SimpleTable
              innerRef={this.simpleTableRef}
              autoSize={true}
              settings={tableSettings}
              data={content}
              columns={columns}
              sortAction={patentsSortAction}
              serverSortAction={this.handlePageSort}
              dynamicHeight={true}
            />
          </div>
        }
        {
          content.length === 0 && !patentsLoading &&
          <span className="patents__no-data">
            There is no data to display
          </span>
        }
        <Loader isLoading={patentsLoading} />
        {
          patentInfoPopup.isOpen &&
          <PatentPopup
            error={patentInfoError}
            loading={patentInfoPopupLoading}
            patentInfo={patentInfo}
            type={patentInfoPopup.type}
            togglePatentInfoPopup={togglePatentInfoPopup}
          />
        }
        {
          patentsError &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { patentsError }
          </div>
        }
      </div>
    );
  }
}

PatentTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    patents: getPatents(state),
    patentInfo: getPatentInfo(state),
    patentInfoPopup: getPatentInfoPopup(state),
    patentInfoPopupLoading: getPatentInfoPopupLoading(state),
    patentInfoError: getPatentInfoError(state),
    patentsSorting: getPatentsSorting(state),
    patentsLoading: getChapterLoading(state),
    exportLoading: getExportLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPatentsData(data, exportData) {
      dispatch(getPatentsAction(data, exportData));
    },
    getPatentsPatentInfo(data) {
      dispatch(getPatentInfoAction(data));
    },
    togglePatentInfoPopup() {
      dispatch(togglePatentInfoPopupAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatentTable);
