import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ConceptDetails from '../../Concept/ConceptDetails/ConceptDetails';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';
import { AVERAGE_SYMBOL_LENGTH } from '../../Utils/Utils';

import {
  changePageAction,
  sortTableAction,
} from './redux/actions';
import {
  getPageNumber,
  getTotalPages,
  getSectionTable,
  tableSorting,
} from './redux/selectors';

const propTypes = {
  width: PropTypes.number,
  changePage: PropTypes.func,
  sectionTable: PropTypes.instanceOf(Object).isRequired,
  sorting: PropTypes.instanceOf(Object).isRequired,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  geneName: PropTypes.string,
};

class ConditionOfDysregulationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConceptDetailsModal: false,
      conceptId: null,
    };
    this.simpleTableRef = React.createRef(null);
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const {
      changePage,
    } = this.props;

    changePage(page.selected);
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  getCountItems = (array) => {
    const getCount = length => Math.ceil((length * AVERAGE_SYMBOL_LENGTH) / this.getColumnPercentWidth(40));

    if (array.length < 1) return 1;
    const lettersLength = array.reduce((acc, currentValue) => acc + getCount(currentValue.name.length), 0);
    if (array.length > lettersLength) return array.length;
    return lettersLength;
  };

  getRowHeight = ({ index }) => {
    const { content } = this.props.sectionTable;
    const padding = 20;
    const lineHeight = 30;
    const conditionCount = this.getCountItems(content[index].condition);
    const originCount = this.getCountItems(content[index].origin);
    let rowHeight;

    if (conditionCount > originCount) {
      rowHeight = (conditionCount * lineHeight) + padding;
    } else {
      rowHeight = (originCount * lineHeight) + padding;
    }
    return rowHeight;
  };

  handleModalClose = () => {
    this.setState({
      showConceptDetailsModal: false,
    });
  };

  goToConceptDetails = (data) => {
    this.setState({
      conceptId: data,
      showConceptDetailsModal: true,
    });
  };

  showTooltipCell = ({ id, name }, rowIndex, cellIndex) => {
    const uniqueKey = `tooltip-${id}-${rowIndex}-${cellIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
        key={uniqueKey}
      />
    );
  };

  render() {
    const {
      showConceptDetailsModal,
    } = this.state;

    const {
      sectionTable: {
        content,
        allContent,
      },
      pageNumber,
      totalPages,
      width,
      sorting: {
        sortBy,
        sortDirection,
      },
      geneName,
    } = this.props;

    const columns = [
      {
        label: 'Condition',
        dataKey: 'condition',
        exportCSV: true,
        width: this.getColumnPercentWidth(40),
        className: 'table-wrap__cell table-wrap__cell_left',
        sortValueMapper: item => (item.get('condition').toArray().length
          ? item.get('condition').toArray()[0].get('name')
          : ''),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.condition ?
            rowData.condition.map((condition, cellIndex) => (
              this.showTooltipCell(condition, rowIndex, cellIndex)
            )) : null
        ),
        csvRenderer: rowData => rowData.name,
      },
      {
        label: 'Dysregulation',
        dataKey: 'dysregulationValue',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        className: 'flexColumn',
        cellRenderer: ({ rowData }) => (
          <span className="table-wrap__cell-row text-center">
            {rowData.dysregulationValue.toFixed(1)}
          </span>
        ),
      },
      {
        label: 'Origin',
        dataKey: 'origin',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        className: 'table-wrap__cell table-wrap__cell_left',
        sortValueMapper: item => (item.get('origin').toArray().length
          ? item.get('origin').toArray()[0].get('name')
          : ''),
        cellRenderer: ({ rowData }) => {
          const origins = rowData.origin || [];
          return origins.map((origin, index) => (
            origin ? this.showTooltipCell(origin, index) : null
          ));
        },
        csvRenderer: rowData => rowData.name,
      },
      {
        label: 'Experiment name',
        dataKey: 'experiment',
        exportCSV: true,
        width: this.getColumnPercentWidth(20),
        className: 'table-wrap__cell table-wrap__cell_left',
        sortPath: ['experiment', 'name'],
        cellRenderer: ({ rowData }) => (
          <span
            role="presentation"
            className="cell-row text-center link"
            onClick={
              () => {
                this.goToConceptDetails(rowData.experiment.id);
              }
            }
          >
            {rowData.experiment.name}
          </span>
        ),

      },
    ];

    const tableSettings = {
      width,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    return (
      <div className="gene-detail-condition-of-dysregulation-table">
        {
          <div className="controls-block-2items">
            {
              totalPages > 1 &&
              <div className="paginationContainer">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  pageCount={totalPages}
                  forcePage={pageNumber}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
            }
            {
              allContent && allContent.length > 0 &&
              <ExportTable
                content={allContent}
                columns={columns}
                fileName={`${geneName}-condition-of-dysregulation`}
              />
            }
          </div>
        }
        {
          content && content.length > 0 &&
          <SimpleTable
            innerRef={this.simpleTableRef}
            settings={tableSettings}
            data={content}
            columns={columns}
            sortAction={sortTableAction}
            dynamicHeight={true}
          />
        }
        {
          showConceptDetailsModal &&
          <ModalComponent
            isOpen={showConceptDetailsModal}
            closeCb={this.handleModalClose}
          >
            <ConceptDetails
              conceptId={this.state.conceptId}
              isPopup={true}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

ConditionOfDysregulationTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    sectionTable: getSectionTable(state),
    sorting: tableSorting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(changePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConditionOfDysregulationTable);
