import { createSelector } from 'reselect';

const hideSourceData = state => state.getIn(['hideSourceModalReducer', 'data']);

export const getHideSourceDataSelector = createSelector(
  hideSourceData,
  data => data && data.toJS()
);

export const getHideSourceSelectedDataIdsSelector = createSelector(
  getHideSourceDataSelector,
  data => (data ? Object.keys(data).filter(id => data[id].checked === true) : [])
);
