export function getSpeciesOptions(data) {
  const speciesData = data.reduce((acc, item) => {
    if (!acc.includes(item.species)) {
      acc.push(item.species);
    }
    return acc;
  }, []);

  return speciesData.map(item => ({
    value: item,
    label: item,
    name: item,
  }));
}

export function filterOrthologsData(data, filters) {
  const filtersKeys = Object.keys(filters);
  return filtersKeys.reduce((acc, item) => {
    const valuesArray = filters[item].map(i => i.value);
    if (valuesArray.length) {
      return acc.filter(i => valuesArray.includes(i[item]));
    }
    return acc;
  }, data);
}
