import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import { AVERAGE_SYMBOL_LENGTH } from '../../../Utils/Utils';
import { getDisorderAnimalModelsSelector } from '../redux/selectors';
import { changeAnimalModelsForDisorderSortingAction, disorderAnimalModelsChangePageAction } from '../redux/reducer';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../../common/ExportTable/ExportTable';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  animalPhenotypes: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  changePage: PropTypes.func,
};

class AnimalModelsTable extends React.Component {
  state = {
    popup: false,
    showInfoText: false,
  };
  conceptsNames = [];
  conceptsIds = [];
  simpleTableRef = React.createRef(null);

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const { changePage } = this.props;

    changePage(page.selected);
  };

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data;
    this.conceptsNames = [geneName, name];
    this.conceptsIds = [gene, id];
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  getRowHeight = ({ index }) => {
    const { animalPhenotypes: { data } } = this.props;
    const padding = 25;
    const lineHeight = 35;
    const cellWidth = this.getColumnPercentWidth(25);
    const stringLength = data[index].disease.name.length;
    const stringRows = Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    const rowHeight = (stringRows * lineHeight) + padding;
    return rowHeight;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  diseaseCell = ({ id, name }, rowIndex) => {
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      animalPhenotypes: {
        data = [],
        allData = [],
        pageNumber,
        totalPages,
        sorting: { sortBy, sortDirection },
      },
      geneName,
      width,
    } = this.props;

    const {
      popup,
      showInfoText,
    } = this.state;

    const tableSettings = {
      height: 500,
      width,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Disease',
        dataKey: 'disease',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(35),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.disease ? this.diseaseCell(rowData.disease, rowIndex) : null
        ),
      },
      {
        label: 'Animal model',
        dataKey: 'animalModelMeasureValue',
        exportCSV: true,
        width: this.getColumnPercentWidth(35),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            <a className="link" target="_blank" href={`http://www.informatics.jax.org/accession/${rowData.modelId}`} rel="noreferrer">
              {rowData.animalModelMeasureValue}
            </a>
          </div>
        ),
      },
      {
        label: 'Annotation/Prediction',
        dataKey: 'predictionType',
        exportCSV: true,
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(15),
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.predictionType}
          </div>
        ),
      },
      {
        label: 'Prediction score',
        dataKey: 'predictionScore',
        exportCSV: true,
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(15),
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div className="table-wrap__cell-row">
            {rowData.predictionScore ? rowData.predictionScore : null}
          </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Animal models
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows disorders that are associated with orthologous, non-human gene knockouts and gene variants.
              These disease association are based either on direct annotations from various databases or Phenodigm predictions.
            </p>
            <p>
              The predicted associations are based on the <a className="link" href="https://www.sanger.ac.uk/science/tools/phenodigm" target="blank">Phenodigm scoring algorithm</a> developed by the Wellcome Sanger Institute.
              This score enables an automated method to provide evidence about gene–disease associations by analysing animal and human phenotype information.
            </p>
            <p>
              If available, the two allele types of the animal model are shown as allele1/allelel2.
              For each of these disease associations all Pubmed articles that co-mention the gene are shown in the right hand column and can be accessed by clicking the link.
            </p>
          </div>
        }
        {
          data.length ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-disease-animal-model`}
                    />
                  }
                </div>
              }
              <SimpleTable
                innerRef={this.simpleTableRef}
                data={data}
                autoSize={true}
                columns={columns}
                settings={tableSettings}
                dynamicHeight={true}
                sortAction={changeAnimalModelsForDisorderSortingAction}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

AnimalModelsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    animalPhenotypes: getDisorderAnimalModelsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(disorderAnimalModelsChangePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnimalModelsTable);

