import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  getRankingTypes,
  getAssociationTermsSaved,
  getLiteratureSearchTermsSaved,
  getDifferentialExpressionConceptGi,
  getSelectedSource,
  getSelectedTissueType,
  getSelectedPenaltyTissuesSelector,
  getRanking,
  getSelectedRankingConcept,
  getGeneVariationConceptGi,
  getGeneVariationTermSaved,
  getDifferentialExpressionTermSaved,
  getSelectedTissueOptionSelector,
} from '../../common/SetAnalysisMethodSelection/selectors';
import {
  getCurrentScoringFormula,
} from '../RankingMarkerSettings/selectors';
import {
  getKeysByBool,
} from '../../../Utils/Utils';

import {
  RANKING_FAILED,
  RANKING_SUCCEEDED,
  RANKING_REQUESTED,
  LOADING,
  RANKING_RESULT_TABLE,
  EFFECT_COLUMNS,
} from './constants';
import { getActiveSets } from '../../common/SetAnalysis/selectors';
import { getEffectOperation } from './selectors';
import { fillAggregationData } from './utils';

export function* getDataForRankRequest(action) {
  try {
    let requestData = {
      conceptGis: action.data.concepts,
    };

    const rankingTypes = yield select(getRankingTypes);
    const ranking = yield select(getRanking);
    const rankingConcept = yield select(getSelectedRankingConcept);

    requestData = Object.assign(requestData, {
      rankingItems: getKeysByBool(rankingTypes, true),
    });

    if (ranking === 'rank as target' || ranking === 'rank as marker') {
      Object.assign(requestData, {
        disorderGi: rankingConcept.id,
      });
    }

    const associationTerms = yield select(getAssociationTermsSaved);

    if (requestData.rankingItems.indexOf('associationScore') > -1) {
      requestData = Object.assign(requestData, {
        associationScore: {
          conceptsGis: associationTerms.map(term => (
            term.id
          )),
        },
      });
    }

    if (requestData.rankingItems.indexOf('literatureSearch') > -1) {
      const phrases = yield select(getLiteratureSearchTermsSaved);
      requestData = Object.assign(requestData, {
        literatureSearch: {
          phrases: phrases.map(phrase => (
            { id: phrase.id, name: phrase.name }
          )),
        },
      });
    }

    if (requestData.rankingItems.indexOf('differentialExpression') > -1) {
      const id = yield select(getDifferentialExpressionConceptGi);
      const { name } = yield select(getDifferentialExpressionTermSaved);
      requestData = Object.assign(requestData, {
        differentialExpression: {
          id,
          name,
        },
      });
    }

    if (requestData.rankingItems.indexOf('geneVariation') > -1) {
      if (ranking === 'user selection') {
        const id = yield select(getGeneVariationConceptGi);
        const { name } = yield select(getGeneVariationTermSaved);
        requestData = Object.assign(requestData, {
          geneVariation: {
            id,
            name,
          },
        });
      } else if (ranking === 'rank as target' || ranking === 'rank as marker') {
        requestData = Object.assign(requestData, {
          geneVariation: {},
        });
      }
    }

    if (requestData.rankingItems.indexOf('expressionSpecificity') > -1) {
      const targetTissue = yield select(getSelectedTissueType);
      const penaltyTissues = yield select(getSelectedPenaltyTissuesSelector);
      const selectedTissueOption = yield select(getSelectedTissueOptionSelector);
      const currentFormula = yield select(getCurrentScoringFormula);
      const selectedSource = yield select(getSelectedSource);

      const selectedTissueValues = selectedTissueOption.value.split(':');

      requestData = Object.assign(requestData, {
        expressionSpecificity: {
          targetTissue: {
            gi: targetTissue || selectedTissueValues[0],
            source: selectedSource || selectedTissueValues[1],
          },
          scoreFormula: currentFormula,
          ...(penaltyTissues && {
            penaltyTissues: penaltyTissues.map((t) => {
              const tissueData = t.value.split(':');
              return {
                gi: tissueData[0],
                source: tissueData[1],
              };
            }),
          }),
        },
      });
    }

    return requestData;
  } catch (error) {
    console.log(error);
  }
}

export function* fillEffectScores(content) {
  try {
    const sets = yield select(getActiveSets);
    const effectColumns = [];

    sets.forEach((set, index) => {
      if (set.type === 'EFFECT' || set.setType === 'EFFECT') {
        content.forEach((item) => {
          const setItem = set.originalData.find(d => d.id === item.geneGi);
          if (setItem) {
            // eslint-disable-next-line no-param-reassign
            item[`set${index + 1}`] = Number(setItem.score || setItem.measure); // eslint-disable-line no-plusplus
          }
        });
        effectColumns.push(`set${index + 1}`);
      }
    });
    yield put({ type: RANKING_RESULT_TABLE + EFFECT_COLUMNS, data: effectColumns });
    const effectOperationValue = yield select(getEffectOperation);
    fillAggregationData(content, effectColumns, effectOperationValue);
  } catch (error) {
    console.log(error);
  }
}

function* rankRequestSaga(action) {
  try {
    yield put({ type: RANKING_RESULT_TABLE + LOADING, data: true });

    const response = yield call(Api.ranking, {
      post: yield getDataForRankRequest(action),
      params: action.data.params || {
        size: 20,
        page: 0,
      },
    });

    response.data.content.forEach((item) => {
      if (item.literatureSearchData) {
        item.literatureSearchData.forEach((litSearchItem) => {
          // eslint-disable-next-line no-param-reassign
          item[`litSearchItem.${litSearchItem.synonym.name}`] = litSearchItem.literatureSearch;
        });
      }
      if (item.targetValidation) {
        const targetValidationKeys = Object.keys(item.targetValidation);
        targetValidationKeys.forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item[key] = item.targetValidation[key];
        });
      }
    });

    yield fillEffectScores(response.data.content);

    yield put({ type: RANKING_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: RANKING_FAILED, message: e.message });
  }
}

function* rankingAnalysis() {
  yield takeLatest(RANKING_REQUESTED, rankRequestSaga);
}

export default rankingAnalysis;
