import { call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  setPublicationCitationsAction,
  throwPublicationCitationsErrorAction,
  togglePublicationDetailsChapterAction,
} from '../../reducer';
import { PubDetailsChaptersEnum } from '../../constants';

export function* getPublicationCitationsWorker(action) {
  try {
    const id = action.payload;
    const { data } = yield call(Api.getPublicationCitation, id);
    const byIdData = data.reduce((obj, citation) => {
      const newObj = { ...obj };
      newObj[citation.publicationId] = citation;
      return newObj;
    }, {});
    yield put(setPublicationCitationsAction(byIdData));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationTriples,
      show: true,
    }));
  } catch (e) {
    yield put(throwPublicationCitationsErrorAction(e.message));
    yield put(togglePublicationDetailsChapterAction({
      name: PubDetailsChaptersEnum.publicationTriples,
      show: true,
    }));
  }
}
