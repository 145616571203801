import React, { useState } from 'react';
// Components
import SelectGene from '../../../common/SelectGene/SelectGene';
import Button from '../../../common/Buttons/Button/Button';
import ResultDiseaseBlock from './Components/ResultDiseaseBlock/ResultDiseaseBlock';
// Utils
import { isEmptyObject } from '../../../Utils/Utils';
// Styles
import './styles.scss';

const IndicationFinder = () => {
  const [selectedGene, setSelectedGene] = useState({});
  const [diseasePredictionIsActive, setDiseasePredictionIsActive] = useState(false);

  const handleGeneSearch = (gene) => {
    if (!isEmptyObject(selectedGene)) setSelectedGene({});
    setSelectedGene(gene);
  };

  const handleDiseasePrediction = () => {
    setDiseasePredictionIsActive(!diseasePredictionIsActive);
  };

  const handleClearOnFocus = () => {
    setSelectedGene({});
  };

  return (
    <div className="indication-finder">
      <div className="indication-finder__sidebar">
        <span className="indication-finder__sidebar-text">
          Discover novel indications for your gene of interest!
        </span>
      </div>
      <div className="indication-finder__main">
        <p className="indication-finder__main-text">
          Euretos has developed computational disease models that systematically evaluate gene-disease associations
          across the whole indication landscape. We use the biological networks (such as coexpression, protein-protein
          interactions) around each gene, as well as the full repertoire of known gene-disease associations, in order to
          calculate predictive scores for each potential gene-disease combination. By this approach we can calculate
          predictive scores for any gene-disease combination – whether there are any genetic associations known or not.
        </p>
        <p className="indication-finder__main-text">
          Type in your target gene of interest to find one of the top-20 disease terms that are predicted by our model
          to be associated with your target.
        </p>
        <div className="indication-finder__select-block">
          <span className="indication-finder__label">
             Enter a human gene name and select it
          </span>
          <SelectGene
            geneName={selectedGene.name}
            onSubmit={handleGeneSearch}
            clearFieldAfterSelect={false}
            searchGeneForIndicationFinder={true}
            onFocus={handleClearOnFocus}
            customClassName="indication-finder__select-gene"
          />
        </div>
        {
          !isEmptyObject(selectedGene) &&
          <>
            <div className="indication-finder__select-block">
              <span className="indication-finder__label">
               Press the button for a disease prediction
              </span>
              <Button
                text="Get disease prediction"
                onClick={handleDiseasePrediction}
                customClassName="indication-finder__btn"
                disabled={diseasePredictionIsActive}
              />
            </div>
            <ResultDiseaseBlock
              selectedGene={selectedGene}
              handleDiseasePrediction={handleDiseasePrediction}
              diseasePredictionIsActive={diseasePredictionIsActive}
            />
            <div className="indication-finder__select-block">
              <span className="indication-finder__label">
              Find out more about the gene
              </span>
              <Button
                target="_blank"
                href={`gene-details/${selectedGene.id}`}
                disabled={!selectedGene}
                text="Open gene detail page"
                customClassName="indication-finder__btn"
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default React.memo(IndicationFinder);
