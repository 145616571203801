import { createSelector } from 'reselect';

const availableTags = state => state.getIn(['addTag', 'availableTags']);
const isLoading = state => state.getIn(['addTag', 'isLoading']);

export const getAvailableTags = createSelector(
  availableTags,
  data => data && data.toJS()
);

export const getAddTagDataLoadingSelector = createSelector(
  isLoading,
  data => data
);
