import { createSelector } from 'reselect';

// Utils
import { createSetSortingTypes } from '../enums';

const createSetConcepts = state => state.getIn(['createSetReducer', 'concepts']);
const createSetAllConcepts = state => state.getIn(['createSetReducer', 'allConcepts']);
const createSetStartConcepts = state => state.getIn(['createSetReducer', 'config', 'startConcepts']);
const createSetTitle = state => state.getIn(['createSetReducer', 'title']);
const createSetName = state => state.getIn(['createSetReducer', 'name']);
const createSetDescription = state => state.getIn(['createSetReducer', 'description']);
const createSetTags = state => state.getIn(['createSetReducer', 'tags']);
const createSetLoading = state => state.getIn(['createSetReducer', 'loading']);
const saveCreatedSetLoading = state => state.getIn(['createSetReducer', 'saveLoading']);
const createSetError = state => state.getIn(['createSetReducer', 'error']);
const saveSetError = state => state.getIn(['createSetReducer', 'errorOnSave']);
const setSavedSuccessfully = state => state.getIn(['createSetReducer', 'setSavedSuccessfully']);
const getAvailableFilters = state => state.getIn(['createSetReducer', 'availableFilters']);
const getFiltersData = state => state.getIn(['createSetReducer', 'filtersData']);
const getPubFiltersData = state => state.getIn(['createSetReducer', 'filtersData', 'filter']);
const getUIFiltersData = state => state.getIn(['createSetReducer', 'uiFilters']);
const createSetSorting = state => state.getIn(['createSetReducer', 'sorting']);
const createSelectedProject = state => state.getIn(['createSetReducer', 'selectedProject']);
const createSetPublicationSourceValue = state => state.getIn(['createSetReducer', 'publicationSource']);
const createSetFilterValues = state => state.getIn(['form', 'CREATE-SET-FILTER', 'values']);
const getSavedSetInfo = state => state.getIn(['createSetReducer', 'savedSetInfo']);
const getLoadedSetInfo = state => state.getIn(['createSetReducer', 'loadedSetData']);
const getConfigFromStore = state => state.getIn(['createSetReducer', 'config']);

export const getCreateSetConceptsSelector = createSelector(
  createSetConcepts,
  getUIFiltersData,
  createSetSorting,
  (data, filters, sorting) => {
    if (!data) return data;
    const concepts = data.toJS();
    const { conceptName } = filters.toJS();
    const { sortBy, sortDirection } = sorting.toJS();

    const filteredConcepts = concepts.reduce((filtered, c) => {
      if (conceptName && !c.name.toLowerCase().includes(conceptName.toLowerCase())) {
        return filtered;
      }
      filtered.push(c);
      return filtered;
    }, []);

    const comparator = (a, b) => (
      sortBy === createSetSortingTypes.NAME ?
        a.name.localeCompare(b.name) :
        a.count - b.count
    );
    const reverseComp = (a, b) => -comparator(a, b);

    return filteredConcepts.sort(sortDirection === 'DESC' ? reverseComp : comparator);
  }
);

export const getCreateSetConceptIdsSelector = createSelector(//NOTE: same as above, not sure, maybe just make sense to use above one
  createSetConcepts,
  getUIFiltersData,
  (data, filters) => {
    if (!data) return data;
    const concepts = data.toJS();
    const { conceptName } = filters.toJS();

    return concepts.reduce((filtered, c) => {
      if (conceptName && !c.name.toLowerCase().includes(conceptName.toLowerCase())) {
        return filtered;
      }
      filtered.push(c);
      return filtered;
    }, []);
  }
);

export const getCreateSetAllConceptsSelector = createSelector(
  createSetAllConcepts,
  data => data && data.toJS().map(c => c.id)
);

export const getCreateSetTitleSelector = createSelector(
  createSetTitle,
  data => data
);

export const getCreateSetNameSelector = createSelector(
  createSetName,
  data => data
);

export const getCreateSetDescriptionSelector = createSelector(
  createSetDescription,
  data => data
);

export const getCreateSetTagsSelector = createSelector(
  createSetTags,
  data => data && data.toJS()
);

export const getCreateSetLoadingSelector = createSelector(
  createSetLoading,
  data => data
);

export const getSaveCreatedSetLoadingSelector = createSelector(
  saveCreatedSetLoading,
  data => data
);

export const getCreateSetErrorSelector = createSelector(
  createSetError,
  data => data
);

export const getSaveCreatedSetInputsErrorSelector = createSelector(
  createSetName,
  createSetConcepts,
  (name, concepts) => !!name && !!concepts && concepts.size > 0
);

export const getAvailableFiltersSelector = createSelector(
  getAvailableFilters,
  data => data && data.toJS()
);

export const getFiltersDataSelector = createSelector(
  getFiltersData,
  data => data && data.toJS()
);

export const getCreateSetPubFiltersDataSelector = createSelector(
  getPubFiltersData,
  data => data && data.toJS()
);

export const getErrorOnSave = createSelector(
  saveSetError,
  data => data
);

export const getSetSavedSuccessfullySelector = createSelector(
  setSavedSuccessfully,
  data => data
);

export const getUIFiltersDataSelector = createSelector(
  getUIFiltersData,
  data => data && data.toJS()
);

export const getCreateSetSortingSelector = createSelector(
  createSetSorting,
  data => data && data.toJS()
);

export const getCreateSetPublicationSourceValueSelector = createSelector(
  createSetPublicationSourceValue,
  data => data
);

export const getCreateSetFilterCooccurrenceValue = createSelector(
  createSetFilterValues,
  (data) => {
    const { cooccurrence } = data && data.toJS();
    const { value } = cooccurrence || { value: null };
    return value;
  }
);

export const getCreateSetFilterPublicationSourceValue = createSelector(
  createSetFilterValues,
  (data) => {
    const { PUBLICATION_FILTERS: { publicationSource } } = data && data.toJS();
    return publicationSource;
  }
);

export const getCreateSelectedProjectSelector = createSelector(
  createSelectedProject,
  data => data
);

export const getSavedSetInfoSelector = createSelector(
  getSavedSetInfo,
  data => data && data.toJS()
);

export const getLoadedSetInfoSelector = createSelector(
  getLoadedSetInfo,
  data => data && data.toJS()
);

export const getcreateSetStartConceptsSelector = createSelector(
  createSetStartConcepts,
  data => data && data.toJS()
);

export const getCreateSetStartConceptsIdsSelector = createSelector(
  createSetStartConcepts,
  data => data && data.toJS().map(c => c.id)
);

export const getCreateSetStartConceptsNamesSelector = createSelector(
  createSetStartConcepts,
  data => data && data.toJS().map(c => c.name)
);

export const getConfigFromStoreSelector = createSelector(
  getConfigFromStore,
  data => data && data.toJS()
);
