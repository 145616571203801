import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Button from '../../../../../common/Buttons/Button/Button';
import SelectInput from '../../../../../common/Inputs/SelectInput/SelectInput';
import ModalComponent from '../../../../../ModalComponent/ModalComponent';
import Sets from '../../../../../Sets/SetsList/Sets';
// Store
import * as ACTIONS from './../../store/actions';
import * as SELECTORS from './../../store/selectors';
import {checkProjectTab} from '../../../../../Projects/ProjectsTabs/utils';

const propTypes = {
  genesData: PropTypes.instanceOf(Object),
  selectedGenes: PropTypes.instanceOf(Object),
  selectedSet: PropTypes.instanceOf(Object),
  setSelectedGenes: PropTypes.func,
  setSelectedGenesSet: PropTypes.func,
  getCellLinePanelData: PropTypes.func,
  getCellLinePanelHeatmapData: PropTypes.func,
  resetDataForNewGenes: PropTypes.func,
  resetFormData: PropTypes.func,
  getSelectGeneOptions: PropTypes.func,
};

const CellLineSelectionForm = (props) => {
  const {
    genesData: {
      options: geneOptions,
      firstGene,
      secondGene,
    },
    selectedGenes,
    selectedSet,
    setSelectedGenes,
    setSelectedGenesSet,
    getCellLinePanelData,
    getCellLinePanelHeatmapData,
    resetDataForNewGenes,
    resetFormData,
    getSelectGeneOptions
  } = props;

  const [showSelectSetPopup, setShowSelectSetPopup] = useState(false);
  const [savedSetName, setSavedSetName] = useState('');
  const [firstGeneValue, setFirstGeneValue] = useState('');
  const [secondGeneValue, setSecondGeneValue] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedSet && selectedSet.id) {
      getCellLinePanelHeatmapData(selectedSet);
    } else {
      getCellLinePanelData(selectedGenes);
    }
  };

  function fetchOptions(value, geneNumber) {
    if (value) {
      getSelectGeneOptions({ value, geneNumber});
    }
  }

  function selectSetId({ projectId, selectedSets }) {
    const { id, name } = selectedSets[0];
    const projectOrNull = checkProjectTab(projectId) ? projectId : null;

    setFirstGeneValue(null);
    setSecondGeneValue(null);
    setSelectedGenes({ geneNumber: 'firstGene', gene: null });
    setSelectedGenes({ geneNumber: 'secondGene', gene: null });
    setSavedSetName(name);
    setSelectedGenesSet({ id, name,  projectId: projectOrNull });
    setShowSelectSetPopup(false);
  }

  useEffect(() => {
    resetDataForNewGenes();
  }, [selectedGenes.firstGene.name, selectedGenes.secondGene && selectedGenes.secondGene.name]);

  const resetData = (e) => {
    e.preventDefault();
    setSavedSetName('');
    setFirstGeneValue(null);
    setSecondGeneValue(null);
    setSelectedGenes({ geneNumber: 'firstGene', gene: null });
    setSelectedGenes({ geneNumber: 'secondGene', gene: null });
    resetDataForNewGenes();
    resetFormData();
  };

  return (
    <form
      className="cell-line-selection-wrap"
      onSubmit={onSubmit}
    >
      <div className="cell-line-selection-form">
        <div className="select-gene cell-line-selection-form__select-gene">
          <SelectInput
            bordered={true}
            value={firstGeneValue}
            options={geneOptions}
            isLoading={firstGene.loading}
            onInputChange={(v) => fetchOptions(v, 'firstGene')}
            onSelect={(gene) => {
              setSavedSetName('');
              setFirstGeneValue(gene);
              setSelectedGenes({ geneNumber: 'firstGene', gene });
            }}
            defaultValue={firstGeneValue}
            filterWithOptions={false}
            title="Type human gene name"
            placeholder="Select target gene"
            noOptionsMessage={() => 'There is no data to display'}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="select-gene cell-line-selection-form__select-gene">
          <SelectInput
            bordered={true}
            value={secondGeneValue}
            options={geneOptions}
            isLoading={secondGene.loading}
            onInputChange={(v) => fetchOptions(v, 'secondGene')}
            onSelect={(gene) => {
              setSavedSetName('');
              setSecondGeneValue(gene);
              setSelectedGenes({ geneNumber: 'secondGene', gene }); }}
            defaultValue={secondGeneValue}
            filterWithOptions={false}
            title="Type human gene name"
            placeholder="Add additional target gene"
            noOptionsMessage={() => 'There is no data to display'}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="cell-line-selection-form">
        <p className="workflows__text cell-line-selection-form__text">Or select set of genes:</p>
        <input
          name="setName"
          value={savedSetName}
          type="text"
          placeholder="Click to select gene set"
          className="cell-line-selection-form__select-set-input"
          onClick={() => {setShowSelectSetPopup(true);}}
        />
      </div>
      <div className="cell-line-selection-form">
        <Button
          type="submit"
          text="Select cell lines"
          disabled={!selectedGenes.firstGene && !savedSetName}
          customClassName="button-primary"
        />
        <Button
          type="reset"
          text="Reset"
          onClick={resetData}
          customClassName="button-primary cell-line-selection-form__button"
        />
      </div>
      {
        showSelectSetPopup &&
        <ModalComponent
          isOpen={showSelectSetPopup}
          closeCb={() => setShowSelectSetPopup(false)}
        >
          <Sets
            selectFunction={selectSetId}
            closeFunction={() => setShowSelectSetPopup(false)}
          />
        </ModalComponent>
      }
    </form>
  );
};

CellLineSelectionForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    genesData: SELECTORS.getGenesDataSelector(state),
    selectedGenes: SELECTORS.getSelectedGenesSelector(state),
    selectedSet: SELECTORS.getSelectedSetSelector(state),
    cellLinesData: SELECTORS.getCellLinesDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSelectGeneOptions(data) {
      dispatch(ACTIONS.searchGenesForCellLineSelectionAction(data));
    },
    setSelectedGenes(data) {
      dispatch(ACTIONS.setSelectedGenesAction(data));
      dispatch(ACTIONS.resetDataForNewGenesAction());
    },
    setSelectedGenesSet(data) {
      dispatch(ACTIONS.setSelectedGenesSetAction(data));
      dispatch(ACTIONS.resetDataForNewGenesAction());
    },
    getCellLinePanelData(data) {
      dispatch(ACTIONS.getCellLinePanelDataAction(data));
    },
    getCellLinePanelHeatmapData(data) {
      dispatch(ACTIONS.getCellLinePanelHeatmapDataAction(data));
    },
    resetDataForNewGenes() {
      dispatch(ACTIONS.resetDataForNewGenesAction());
    },
    resetFormData() {
      dispatch(ACTIONS.resetFormDataAction());
    },
  };
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CellLineSelectionForm));
