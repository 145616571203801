import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import * as a from './actions';
import { change } from 'redux-form';
// Api
import Api from '../../../Api/Api';
// Store
import { searchRelatedConceptsErrorAction, searchRelatedConceptsLoadingAction } from '../Components/SearchRelatedConceptsCard/store/reducer';
import { cacheSemanticCategoriesTabsData } from '../Components/SearchCategoryTab/store/reducer';
import { getSemanticCategoriesConceptIds } from '../Components/SearchCategoryTab/store/selectors';
import { getCachedConceptIdsForCategorySearch, getSearchFilterValuesSelector } from './selectors';
import { history } from '../../../index';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
import { SEARCH_FILTER_FORM } from '../Components/SearchFilter/constants';
import { SearchSourceFilterEnum } from '../enums';
// Utils
import { buildSearchLink, checkFiltersApplying, getSearchLinkParams } from '../utils';

function* searchByCategoryWorker({ payload: categorySearchDto }) {
  try {
    yield put(searchRelatedConceptsLoadingAction(true));
    const conceptGis = yield select(getSemanticCategoriesConceptIds);
    const cachedConceptIdsForCategorySearch = yield select(getCachedConceptIdsForCategorySearch);

    if (cachedConceptIdsForCategorySearch.length === 0) {
      yield put(a.cacheConceptIdsForCategorySearchAction(conceptGis));
      yield put(cacheSemanticCategoriesTabsData());
    }
    yield put(a.setCategorySearchDTOAction(categorySearchDto));

    const requestData = { post: { categorySearchDto, conceptGis, cooccurrenceType: SearchSourceFilterEnum.ALL } };
    const { data } = yield call(Api.searchByCategory, requestData);
    yield put(a.setCategorySearchDataAction({
      ...data,
      concepts: data.concepts.map(item => Object.assign(item, { active: false })),
    }));
    yield put(searchRelatedConceptsLoadingAction(false));
  } catch (e) {
    yield put(a.throwCategorySearchDataErrorAction(e.message));
    yield put(searchRelatedConceptsLoadingAction(false));
    yield put(searchRelatedConceptsErrorAction(e.message));
  }
}

function* getSearchResultsWorker({ payload }) {
  try {
    const { isNewSearch, requestData } = payload;
    const { data } = yield call(Api.search, requestData);
    const { content } = data.publications;
    const { publicationSource } = requestData.post;
    const formData = yield select(getSearchFilterValuesSelector);
    const isFiltersApplied = checkFiltersApplying(formData);

    const runForDatabaseRecords = content.length === 0 && publicationSource !== SearchSourceFilterEnum.DATABASE && !isFiltersApplied && isNewSearch;
    if (runForDatabaseRecords) {
      yield put(change(SEARCH_FILTER_FORM, 'publicationSource', SearchSourceFilterEnum.DATABASE));
      const searchParams = getSearchLinkParams(window.location.search);
      const link = buildSearchLink({
        ...searchParams,
        publicationSource: SearchSourceFilterEnum.DATABASE
      });

      history.push(`${RELATIVE_PATH}/research?${link.search}`);
    } else {
      yield put(a.setSearchDataAction(data));
    }
  } catch (e) {
    yield put(a.throwSearchDataErrorAction(e.message));
  }
}

function* searchSaga() {
  yield takeLatest(a.getSearchDataAction, getSearchResultsWorker);
  yield takeEvery(a.getCategorySearchDataAction, searchByCategoryWorker);
}

export default searchSaga;
