function generateChartData(firstName, firstData, secondName, secondData) {
  const conceptNames = firstData.concat(secondData).reduce((acc, item) => {
    if (!acc.includes(item.concept.name)) acc.push(item.concept.name);
    return acc;
  }, []);

  return conceptNames.reduce((acc, item) => {
    const firstElement = firstData.find(el => el.concept.name === item);
    const secondElement = secondData.find(el => el.concept.name === item);
    const group = (firstElement && firstElement.group) || (secondElement && secondElement.group);
    acc.push({
      concept: item,
      group,
      values: [
        {
          ...
          firstElement ? {
            ...firstElement,
            name: firstName,
            points: firstElement.points.sort(),
            summary: firstElement.summary,
            samplesCount: firstElement.samplesCount,
          } : { name: firstName, points: [], summary: {} }
        },
        {
          ...
          secondElement ? {
            ...secondElement,
            name: secondName,
            points: secondElement.points.sort(),
            summary: secondElement.summary,
            samplesCount: secondElement.samplesCount,
          } : { name: secondName, points: [], summary: {} }
        },
      ]
    });
    return acc;
  }, []);
}

function transformLogChartData(violinPlotTransform, chartData) {
  if (violinPlotTransform === 'log') {
    return chartData.map(item => ({
      ...item,
      values: item.values.map(value => ({
        ...value,
        points: value.points.map(point => Math.log2(point + 1)),
        summary: {
          ...value.summary,
          min: Math.log2(value.summary.min + 1),
          max: Math.log2(value.summary.max + 1),
          median: Math.log2(value.summary.median + 1),
          q1: Math.log2(value.summary.q1 + 1),
          q3: Math.log2(value.summary.q3 + 1),
        },
      }
      )),
    }));
  }
  return chartData;
}

export { generateChartData, transformLogChartData };
