import { fromJS } from 'immutable';

import { PAGE_SIZE } from '../../constants';

import { types } from './constants';

const initialState = fromJS({
  existingDrugs: {
    data: [],
    totalPages: 0,
    pageNumber: 0,
    sorting: {
      sortBy: 'compound',
      sortDirection: 'DESC',
      sortPath: ['compound', 'name'],
    },
  },
  experimentalResults: {
    data: [],
    totalPages: 0,
    pageNumber: 0,
    sorting: {
      sortBy: 'compound',
      sortDirection: 'DESC',
      sortPath: ['compound', 'name'],
    },
  },
  error: null,
  loading: true,
});

const therapeuticCandidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_THERAPEUTIC_CANDIDATES_DATA: {
      const {
        existingDrugs,
        experimentalResults,
      } = action.data;

      return state.merge(fromJS({
        existingDrugs: {
          data: existingDrugs,
          totalPages: Math.ceil((existingDrugs || []).length / PAGE_SIZE),
          pageNumber: 0,
          sorting: {
            sortBy: 'compound',
            sortDirection: 'DESC',
            sortPath: ['compound', 'name'],
          },
        },
        experimentalResults: {
          data: experimentalResults,
          totalPages: Math.ceil((experimentalResults || []).length / PAGE_SIZE),
          pageNumber: 0,
          sorting: {
            sortBy: 'compound',
            sortDirection: 'DESC',
            sortPath: ['compound', 'name'],
          },
        },
        error: null,
        loading: false,
      }));
    }

    case types.THERAPEUTIC_CANDIDATES_DATA_FAILED: {
      const { message } = action;
      return state
        .update('error', () => message)
        .update('loading', () => false);
    }

    case types.THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_SORT: {
      const { data } = action;

      return state.update('existingDrugs', existingDrugs => (
        existingDrugs.set('sorting', fromJS(data))
      ));
    }

    case types.THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_SORT: {
      const { data } = action;

      return state.update('experimentalResults', experimentalResults => (
        experimentalResults.set('sorting', fromJS(data))
      ));
    }

    case types.THERAPEUTIC_CANDIDATES_EXISTING_DRUGS_CHANGE_PAGE: {
      const { data } = action;

      return state.update('existingDrugs', existingDrugs => (
        existingDrugs.set('pageNumber', fromJS(data))
      ));
    }

    case types.THERAPEUTIC_CANDIDATES_EXPERIMENTAL_RESULTS_CHANGE_PAGE: {
      const { data } = action;

      return state.update('experimentalResults', experimentalResults => (
        experimentalResults.set('pageNumber', fromJS(data))
      ));
    }

    default:
      return state;
  }
};

export default therapeuticCandidatesReducer;
