import { takeLatest, call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { exportToFile } from '../../../Utils/Utils';
// Store
import * as a from './actions';

function* getSearchWithSetsDataWorker({ payload }) {
  try {
    const { data } = yield call(Api.getSearchWithSetsData, payload);
    yield put(a.putSearchWithSetsDataAction(data));
  } catch (e) {
    yield put(a.throwSearchWithSetsErrorAction(e.message));
  }
}

function* exportSearchWithSetsDataWorker({ payload }) {
  try {
    const { requestData, downloadLink, fileName } = payload;
    const { data } = yield call(Api.exportSearchWithSetsData, requestData);
    exportToFile(downloadLink, data, fileName);
  } catch (e) {
    yield put(a.throwSearchWithSetsErrorAction(e.message));
  }
}

function* searchSaga() {
  yield takeLatest(a.getSearchWithSetsDataAction, getSearchWithSetsDataWorker);
  yield takeLatest(a.exportSearchWithSetsDataAction, exportSearchWithSetsDataWorker);
}

export default searchSaga;
