import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';
import { geneDiseaseSetChapterAction, loadNextChapterAction } from '../GeneDiseasePage/actions';

import {
  DIFFERENTIAL_EXPRESSION_INIT_REQUESTED,
  DIFFERENTIAL_EXPRESSION_INIT_SUCCEEDED,
  DIFFERENTIAL_EXPRESSION_INIT_FAILED,
} from './constants';

function* sagaDifferentialExpression(action) {
  try {
    const post = action.data;
    const response = yield call(Api.diffeentialExpression, { post });
    yield put({ type: DIFFERENTIAL_EXPRESSION_INIT_SUCCEEDED, data: response.data });
    const {
      chords,
      data,
      fullSetABC,
      publications,
    } = response.data;

    if ((chords && chords.length)
      || (data && data.length)
      || (fullSetABC && fullSetABC.length)
      || (publications && publications.length)
    ) {
      yield put(geneDiseaseSetChapterAction('differential-expression'));
    }
    yield put(loadNextChapterAction('gene-mutation'));
  } catch (e) {
    yield put({ type: DIFFERENTIAL_EXPRESSION_INIT_FAILED, message: e.message });
    yield put(loadNextChapterAction('gene-mutation'));
  }
}

function* saga() {
  yield takeLatest(DIFFERENTIAL_EXPRESSION_INIT_REQUESTED, sagaDifferentialExpression);
}

export default saga;
