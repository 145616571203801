import { put, call, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import {
  suggestUnresolvedConceptsFailedAction,
  suggestUnresolvedConceptsRequestedAction,
  suggestUnresolvedConceptsSucceededAction,
} from './reducer';

function* suggestUnresolvedConceptValuesSaga({ payload }) {
  try {
    const { input, geneName } = payload;
    const { data } = yield call(Api.conceptSuggest, {
      params: {
        phrase: input,
        withDegree: false,
      },
    });
    yield put(suggestUnresolvedConceptsSucceededAction({
      [geneName]: data,
    }));
  } catch (e) {
    yield put(suggestUnresolvedConceptsFailedAction(e.message));
  }
}

function* unresolvedConceptsSaga() {
  yield takeLatest(suggestUnresolvedConceptsRequestedAction, suggestUnresolvedConceptValuesSaga);
}

export default unresolvedConceptsSaga;
