import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Spinner from '../../../common/Spinner/Spinner';
import NoData from '../../../common/NoData/NoData';

const propTypes = {
  deleyMs: PropTypes.number,
  alt: PropTypes.string,
  src: PropTypes.string,
};

class Image extends Component {
  state = {
    shouldRender: false,
    showErrorMessage: false,
  };

  componentDidMount() {
    const { deleyMs } = this.props;
    this.timer = setTimeout(() => {
      this.setState({ shouldRender: true });
    }, deleyMs);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { alt, src } = this.props;
    const { shouldRender } = this.state;
    return (
      <>
        {
          shouldRender ?
            <>
              <img
                src={src}
                alt={alt}
                onError={(e) => {
                  this.setState({ showErrorMessage: true });
                  e.target.onerror = null;
                  e.target.style.display = 'none';
                }}
              />
              <NoData
                message="No image available"
                show={this.state.showErrorMessage}
                customClassName="patent-image__no-data"
              />
            </> :
            <Spinner
              isLoading={true}
              containerClassName="row text-center"
            />
        }
      </>
    );
  }
}

Image.propTypes = propTypes;

export default Image;
