import {
  USER_RESTORE_PASSWORD_REQUESTED,
  USER_RESTORE_PASSWORD_MODAL_OPEN,
  USER_RESTORE_PASSWORD_MODAL_CLOSE,
} from './constants';

export const restorePassword = () => (
  {
    type: USER_RESTORE_PASSWORD_REQUESTED,
  }
);

export const restorePasswordModalOpen = () => (
  {
    type: USER_RESTORE_PASSWORD_MODAL_OPEN,
    data: true,
  }
);

export const restorePasswordModalClose = () => (
  {
    type: USER_RESTORE_PASSWORD_MODAL_CLOSE,
    data: false,
  }
);
