import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const setDatasetsAction = createAction('@@datasets/SET_DATA');
export const getDatasetsAction = createAction('@@datasets/GET_DATA');
export const throwDatasetsErrorAction = createAction('@@datasets/THROW_ERROR');

const initialState = fromJS({
  datasets: [],
  loading: true,
  error: '',
});

const reducer = handleActions(
  {
    [setDatasetsAction]: (state, { payload }) =>
      state
        .update('datasets', () => fromJS(payload))
        .update('error', () => '')
        .update('loading', () => false),
    [throwDatasetsErrorAction]: (state, { payload }) =>
      state
        .update('error', () => payload),
  },
  initialState
);

export default reducer;
