import { fromJS } from 'immutable';

import { PAGE_SIZE } from '../../constants';

import { types } from './constants';

const initialState = fromJS({
  chart: [],
  table: {
    content: [],
    page: 0,
  },
  sorting: {
    sortBy: 'lastUpdateDate',
    sortDirection: 'DESC',
  },
  filter: [],
  error: null,
  loading: false,
});

const geneDetailsConditionsOfDysregulation = (state = initialState, action) => {
  switch (action.type) {
    case types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_INIT_CHART: {
      const { data } = action.data;

      const chartData = data.dysregulationBarsInfo.map(item => (
        {
          name: item.experiment.name,
          value: item.dysregulationValue,
          conditions: item.condition.map(cond => cond.name),
        }
      ));
      const totalPages = Math.ceil((data.deregulationTableInfo || []).length / PAGE_SIZE);

      return state.merge(fromJS({
        chart: chartData,
        table: {
          content: data.deregulationTableInfo,
          page: 0,
          totalPages,
        },
        loading: false,
      }));
    }

    case types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_CHANGE_PAGE: {
      return state.update('table', table =>
        table.update('page', () =>
          action.data
        )
      );
    }

    case types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_FILTER_TABLE: {
      return state.set('filter', action.data);
    }

    case types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_SORT_TABLE: {
      return state.merge(fromJS({ sorting: action.data }));
    }

    case types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_LOADING:
      return state.merge(fromJS({ loading: true }));

    default:
      return state;
  }
};

export default geneDetailsConditionsOfDysregulation;

