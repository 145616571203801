import { createSelector } from 'reselect';

import { PAGE_SIZE } from '../../constants';

const pageChapter = 'geneDetailsClinicalTrialsChapter';
const sectionTable = state => state.getIn([pageChapter, 'table']);
const totalPages = state => state.getIn([pageChapter, 'table', 'totalPages']);
const pageNumber = state => state.getIn([pageChapter, 'table', 'number']);
const sorting = state => state.getIn([pageChapter, 'sorting']);
const loading = state => state.getIn([pageChapter, 'loading']);
const error = state => state.getIn([pageChapter, 'error']);

export const getSectionTable = createSelector(
  sectionTable,
  (table) => {
    const tableData = table.toJS();
    const { content = [] } = tableData;
    const totalLength = content.length || 0;
    const start = tableData.page * PAGE_SIZE;
    const end = (tableData.page * PAGE_SIZE) + PAGE_SIZE;
    const result = content.length ? content.slice(start, end > totalLength ? totalLength : end) : [];

    return { content: result, allData: content };
  }
);

export const getTotalPages = createSelector(
  totalPages,
  data => data
);

export const getPageNumber = createSelector(
  pageNumber,
  data => data
);

export const tableSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
