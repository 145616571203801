import React from 'react';
import PropTypes from 'prop-types';

// Components
import ScatterPlot from '../../../../../../../graphics/ScatterPlot/ScatterPlot';
import Loader from '../../../../../../../common/Loader/Loader';
import Error from '../../../../../../../common/Error/Error';
import LegendComponent from '../../../../../../../graphics/LegendComponent/LegendComponent';
import NoData from '../../../../../../../common/NoData/NoData';
// Constants
import { CELLS_CHART } from '../../../../constants';

const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  chartId: PropTypes.string,
  legendData: PropTypes.instanceOf(Object),
  filterValue: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Array),
  chartData: PropTypes.instanceOf(Array),
  backgroundGraph: PropTypes.string,
  filtersAreEmpty: PropTypes.bool,
  chartTitle: PropTypes.string,
  chartName: PropTypes.string,
  measure: PropTypes.instanceOf(Object),
  noData: PropTypes.bool,
};

const SingleCellChartByMetaData = (props) => {
  const {
    data,
    error,
    loading,
    chartId,
    filterValue,
    chartData,
    legendData,
    chartTitle,
    chartName,
    filtersAreEmpty,
    backgroundGraph,
    measure,
    noData,
  } = props;

  const dataForRender = !loading && (!!backgroundGraph || !noData);

  const renderLegend = () => (
    <LegendComponent
      legendsParams={legendData}
      legendsColors={legendData.map(item => item.color)}
      showTooltips={true}
      keyToTooltip="markers"
      keyToName="value"
    />
  );

  return (
    <div className="single-cell-chart-wrapper">
      <Loader isLoading={loading} />
      {
        dataForRender &&
        <div className="single-cell-chart">
          <ScatterPlot
            data={data}
            chartData={chartData}
            type={CELLS_CHART}
            chartTitle={chartTitle}
            chartName={chartName}
            filterBy={filterValue.value}
            chartId={chartId}
            legendData={legendData}
            backgroundGraph={backgroundGraph}
            filtersAreEmpty={filtersAreEmpty}
            measure={measure}
            legend={renderLegend}
            customClassName="scatter-plot-block--meta-type"
          />
        </div>
      }
      <NoData
        show={noData}
        customClassName="single-cell-study__no-results"
      />
      <Error
        show={!loading && !!error}
        error={error}
      />
    </div>
  );
};

SingleCellChartByMetaData.propTypes = propTypes;

export default SingleCellChartByMetaData;
