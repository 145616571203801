import { createAction } from 'redux-actions';
// Datasets
export const getDatasetsAction = createAction('@@single-cell-study/GET_DATASETS');
export const setDatasetsAction = createAction('@@single-cell-study/SET_DATASETS');
export const setSelectedDatasetAction = createAction('@@single-cell-study/SET_SELECTED_DATASET');
// Colors
export const getColorsDataAction = createAction('@@single-cell-study/GET_COLORS_DATA');
export const setColorsDataAction = createAction('@@single-cell-study/SET_COLORS_DATA');
// Filters
export const setMetaDataActiveFilterValueAction = createAction('@@single-cell-study/SET_META_DATA_ACTIVE_FILTER_VALUE');
export const setMetaDataFiltersOptionsAction = createAction('@@single-cell-study/SET_META_DATA_FILTERS_OPTIONS');
export const setMetaDataFiltersAction = createAction('@@single-cell-study/SET_META_DATA_FILTERS');
// Meta data
export const setMetaDataBackgroundsAction = createAction('@@single-cell-study/SET_META_BACKGROUNDS');
export const getMetaDataAction = createAction('@@single-cell-study/GET_META_DATA');
export const setMetaDataAction = createAction('@@single-cell-study/SET_META_DATA');
export const toggleMetaDataLoadingAction = createAction('@@single-cell-study/TOGGLE_META_DATA_LOADING');
export const throwMetaDataErrorAction = createAction('@@single-cell-study/THROW_META_DATA_ERROR');
// Gene data selector
export const setSelectedGeneAction = createAction('@@single-cell-study/SET_SELECTED_GENE');
// Gene data
export const getGeneDataAction = createAction('@@single-cell-study/GET_GENE_DATA');
export const setGeneDataAction = createAction('@@single-cell-study/SET_GENE_DATA');
export const throwGeneDataErrorAction = createAction('@@single-cell-study/THROW_GENE_DATA_ERROR');
// Manage
export const resetSingleCellDataAction = createAction('@@single-cell-study/RESET_ALL_DATA');
export const clearMetaDataAction = createAction('@@single-cell-study/CLEAR_META_DATA');
