import React from 'react';
import PropTypes from 'prop-types';
import Circos from 'circos';
import { connect } from 'react-redux';

// Utils
import { exportToPNG, exportToSVG } from '../../Utils/Utils';
// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import ShortConceptCard from '../../Concept/ShortConceptCard/ShortConceptCard';
// Store
import { hideTooltipAction, showTooltipAction } from '../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../Concept/ShortConceptCard/reducer';
// Styles
import './CircosDiagram.scss';

const defaultProps = {
  hideTextTrack: false,
};

const propTypes = {
  id: PropTypes.string,
  hideTextTrack: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  chords: PropTypes.instanceOf(Array),
  checkQTL: PropTypes.func,
  targetGene: PropTypes.instanceOf(Object),
  geneColor: PropTypes.string,
  isQTL: PropTypes.bool,
  mode: PropTypes.string,
  geneName: PropTypes.string,
  chartName: PropTypes.string,
  showTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  hideTooltip: PropTypes.func,
};

class CircosDiagram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBlockId: '',
      fieldNameForDiagram: props.mode === 'pathDetails' ? 'score' : 'neighboursCount',
      fieldNameForHeatmap: props.mode === 'pathDetails' ? 'score' : 'log2fold',
    };
  }

  componentWillUnmount() {
    window.d3.select(`#${this.props.id}`)
      .selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
      .remove();
    if (document.querySelector('.circos-tooltip')) {
      document.querySelector('.circos-tooltip').remove();
    }
  }

  componentDidMount() {
    this.initDiagram(this.props.data, this.props.chords);
  }

  componentDidUpdate() {
    window.d3.select(`#${this.props.id}`)
      .selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
      .remove();
    this.initDiagram(this.props.data, this.props.chords);
  }

  cutHomoSapiensFromGeneName = (geneName) => {
    if (geneName.indexOf('homo sapiens') === -1) {
      return geneName;
    }
    return geneName.substring(0, geneName.indexOf(' (homo sapiens)'));
  };

  getSectorLength = (value, minValue) => (
    value > minValue ? value : minValue
  );

  changeTextColor = (datum) => {
    if (datum.block_id === this.state.selectedBlockId) {
      return '#FF0000';
    }
    return '#000000';
  };

  changeTextFontWeight = (datum) => {
    if (datum.block_id === this.state.selectedBlockId) {
      return 'bold';
    }
    return 'normal';
  };

  changeChordColor = (datum, index, targetGene) => {
    if (datum.source.id === this.state.selectedBlockId || datum.target.id === this.state.selectedBlockId) {
      return '#FF0000';
    }
    return targetGene && (datum.target.id * 1 === targetGene.gene.id ||
      datum.source.id * 1 === targetGene.gene.id) ?
      '#AE9646' :
      '#969696';
  };

  prettifyTicksText = () => {
    const { id } = this.props;

    function wrap() {
      const self = window.d3.select(this);
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      const maxWidth = 100;
      const padding = 3;

      while (textLength > (maxWidth - 2 * padding) && text.length > 0) { // eslint-disable-line
        text = text.slice(0, -1);
        self.text(`${text}...`);
        textLength = self.node().getComputedTextLength();
      }
    }

    if (window.d3.selectAll(`#${id} text textPath`)[0].length) {
      window.d3.selectAll(`#${id} text textPath`).each(wrap);
    } else if (window.d3.selectAll(`#${id} text`)[0].length) {
      window.d3.selectAll(`#${id} text`).each(wrap);
    }
  };

  clickHandler = (datum) => {
    const {
      block_id, //eslint-disable-line
      id,
    } = datum.values ? datum.values[0] : datum;

    const clickedId = block_id || id; //eslint-disable-line
    const idToSetInTheState = clickedId === this.state.selectedBlockId ? '' : clickedId;
    const that = this;
    this.setState({
      selectedBlockId: idToSetInTheState,
    }, () => that.myCircos.render());
    this.prettifyTicksText();
    this.rotateLabels();
  };

  getById = (data, id) => (
    data.find(item => item.id * 1 === id * 1)
  );

  findLowestLog2foldValue = (data) => {
    let lowestValue = 0;
    data.forEach((dataItem) => {
      dataItem.heatmap.forEach((heatMapItem) => {
        if (heatMapItem[this.state.fieldNameForHeatmap] < lowestValue) {
          lowestValue = heatMapItem[this.state.fieldNameForHeatmap];
        }
      });
    });
    if (lowestValue < 0) {
      return -lowestValue;
    }
    return lowestValue;
  };

  sectorClickHandler = (data) => {
    if (data.id !== 0) {
      this.props.checkQTL(data.id);
    }
  };

  getDiagramMainItem = (item, MIN_SECTOR_HEIGHT_DEFAULT) => (
    {
      label: item.gene.name || '',
      len: this.getSectorLength(item[this.state.fieldNameForDiagram], MIN_SECTOR_HEIGHT_DEFAULT),
      color: item.gene.id == this.props.targetGene.id ? '#AE9646' : `#${item.gene.color}` || `#${this.props.geneColor}`, // eslint-disable-line eqeqeq
      id: `${item.gene.id}`,
    }
  );

  // return array of shape {id, label, len}
  getDiagramDataForQTL = () => {
    const resultArray = [];

    // Data is taken from the QTL doc. Variables are for readabilitty and for this comment :)
    const totalLengthOfChromosome1 = 274330000;
    const totalLengthOfChromosome2 = 151940000;
    const totalLengthOfChromosome3 = 132850000;
    const totalLengthOfChromosome4 = 130910000;
    const totalLengthOfChromosome5 = 104530000;
    const totalLengthOfChromosome6 = 170840000;
    const totalLengthOfChromosome7 = 121840000;
    const totalLengthOfChromosome8 = 138970000;
    const totalLengthOfChromosome9 = 139510000;
    const totalLengthOfChromosome10 = 69360000;
    const totalLengthOfChromosome11 = 79170000;
    const totalLengthOfChromosome12 = 6160000;
    const totalLengthOfChromosome13 = 208340000;
    const totalLengthOfChromosome14 = 141760000;
    const totalLengthOfChromosome15 = 140410000;
    const totalLengthOfChromosome16 = 79940000;
    const totalLengthOfChromosome17 = 63490000;
    const totalLengthOfChromosome18 = 55980000;
    const totalLengthOfChromosomeX = 125940000;
    const totalLengthOfChromosomeY = 43550000;

    resultArray.push({
      id: '1',
      len: totalLengthOfChromosome1,
      label: 'chromosome 1',
    });
    resultArray.push({
      id: '2',
      len: totalLengthOfChromosome2,
      label: 'chromosome 2',
    });
    resultArray.push({
      id: '3',
      len: totalLengthOfChromosome3,
      label: 'chromosome 3',
    });
    resultArray.push({
      id: '4',
      len: totalLengthOfChromosome4,
      label: 'chromosome 4',
    });
    resultArray.push({
      id: '5',
      len: totalLengthOfChromosome5,
      label: 'chromosome 5',
    });
    resultArray.push({
      id: '6',
      len: totalLengthOfChromosome6,
      label: 'chromosome 6',
    });
    resultArray.push({
      id: '7',
      len: totalLengthOfChromosome7,
      label: 'chromosome 7',
    });
    resultArray.push({
      id: '8',
      len: totalLengthOfChromosome8,
      label: 'chromosome 8',
    });
    resultArray.push({
      id: '9',
      len: totalLengthOfChromosome9,
      label: 'chromosome 9',
    });
    resultArray.push({
      id: '10',
      len: totalLengthOfChromosome10,
      label: 'chromosome 10',
    });
    resultArray.push({
      id: '11',
      len: totalLengthOfChromosome11,
      label: 'chromosome 11',
    });
    resultArray.push({
      id: '12',
      len: totalLengthOfChromosome12,
      label: 'chromosome 12',
    });
    resultArray.push({
      id: '13',
      len: totalLengthOfChromosome13,
      label: 'chromosome 13',
    });
    resultArray.push({
      id: '14',
      len: totalLengthOfChromosome14,
      label: 'chromosome 14',
    });
    resultArray.push({
      id: '15',
      len: totalLengthOfChromosome15,
      label: 'chromosome 15',
    });
    resultArray.push({
      id: '16',
      len: totalLengthOfChromosome16,
      label: 'chromosome 16',
    });
    resultArray.push({
      id: '17',
      len: totalLengthOfChromosome17,
      label: 'chromosome 17',
    });
    resultArray.push({
      id: '18',
      len: totalLengthOfChromosome18,
      label: 'chromosome 18',
    });
    resultArray.push({
      id: 'X',
      len: totalLengthOfChromosomeX,
      label: 'chromosome x',
    });
    resultArray.push({
      id: 'Y',
      len: totalLengthOfChromosomeY,
      label: 'chromosome y',
    });
    return resultArray;
  };

  initDiagram = (data = [], chords = [], isNeededToTrimLabels = true) => {
    const MAX_SECTOR_HEIGHT = Math.max.apply(null, data.map(item => item[this.state.fieldNameForDiagram]));
    const MIN_SECTOR_HEIGHT = Math.min.apply(null, data.map(item => item[this.state.fieldNameForDiagram]));
    const MIN_SECTOR_HEIGHT_DEFAULT = MAX_SECTOR_HEIGHT / 10 || 10;

    const HISTOGRAM_WIDTH = (MIN_SECTOR_HEIGHT > MIN_SECTOR_HEIGHT_DEFAULT ? MIN_SECTOR_HEIGHT : MIN_SECTOR_HEIGHT_DEFAULT) / 10;

    this.myCircos = new Circos({
      container: `#${this.props.id}`,
      width: 850,
      height: 850,
    });

    let targetGene = null;

    if (!this.props.isQTL) {
      let indexOfTargetGene = null;
      targetGene = data.find((item, index) => {
        if (item.gene.id === this.props.targetGene.id) {
          indexOfTargetGene = index;
          return true;
        }
        return false;
      });

      if (targetGene) {
        data.splice(indexOfTargetGene, 1);
        data.unshift(targetGene);
      }
    }

    let diagramData;

    if (this.props.isQTL) {
      diagramData = this.getDiagramDataForQTL(data);
    } else {
      diagramData = data.map(item => this.getDiagramMainItem(item, MIN_SECTOR_HEIGHT_DEFAULT));
    }

    const textTrackData = [];
    let textTrackDataConfig;

    const openToolTip = (datum, index, nodes, event) => {
      const { key, id } = datum;
      const { showTooltip, setShortConceptCardId } = this.props;

      const config = {
        uniqueKey: `circos-diagram-tooltip_${this.props.id}`,
        clientX: event.pageX,
        clientY: event.clientY,
      };

      setShortConceptCardId(+key || +id);
      showTooltip(config);
    };

    const {
      hideTooltip,
    } = this.props;

    if (!this.props.hideTextTrack) {
      data.forEach((item) => {
        const sector = this.getById(diagramData, item.gene.id);
        textTrackData.push({
          block_id: sector.id,
          position: sector.len / 2,
          value: this.cutHomoSapiensFromGeneName(sector.label),
        });
      });

      textTrackDataConfig = {
        innerRadius: 320,
        outerRadius: 400,
        style: {
          'font-size': 18,
          fill: a => this.changeTextColor(a),
          'font-weight': a => this.changeTextFontWeight(a),
          opacity: 1,
          cursor: 'pointer',
          'user-select': 'none',
        },
        events: {
          mouseover(datum, index, nodes, event) {
            openToolTip(datum, index, nodes, event);
            return null;
          },
          mouseout() {
            hideTooltip();
          },
          click: this.clickHandler,
        },
      };
    }

    this.myCircos.layout(diagramData, {
      labels: {
        position: 'center',
        display: false,
        size: '14px',
        color: '#000',
        radialOffset: 20,
      },
      ticks: {
        display: false,
      },
      events: {
        mouseover(datum, index, nodes, event) {
          openToolTip(datum, index, nodes, event);
        },
        mouseout() {
          hideTooltip();
        },
        click: this.clickHandler,
      },
    });

    const heatmapData = [];
    if (data[0].heatmap) {
      const lowestLog2foldValueInHeatMap = this.findLowestLog2foldValue(data);
      data.forEach((item) => {
        const sector = this.getById(diagramData, item.gene.id);
        const heatmapArray = item.heatmap;
        let currentOffset = 0;
        heatmapArray.forEach((heatmapArrayItem) => {
          heatmapData.push({
            block_id: `${item.gene.id}`,
            start: currentOffset,
            end: (sector.len / heatmapArray.length) + currentOffset,
            value: heatmapArrayItem[this.state.fieldNameForHeatmap] + lowestLog2foldValueInHeatMap,
            name: item.gene.name,
            realValue: heatmapArrayItem[this.state.fieldNameForHeatmap],
          });
          currentOffset += sector.len / heatmapArray.length;
        });
      });
    }

    const heatmapSettings = {
      innerRadius: 210,
      outerRadius: 240,
      min: null,
      max: null,
      color: 'PuOr',
      logScale: false,
      tooltipContent(datum) {
        if (datum.name) {
          return `<span>${datum.name} : ${datum.realValue}</span>`;
        }
        return `<span>${datum.values[0].name} : ${datum.values[0].realValue}</span>`;
      },
      events: {},
    };

    const histogramData = [];
    if (data[0].histogram) {
      data.forEach((item) => {
        const sector = this.getById(diagramData, item.gene.id);

        item.histogram.forEach((histogramItem, index) => {
          const offset = index === 0 ? 0 : HISTOGRAM_WIDTH / 10;

          histogramData.push({
            block_id: `${item.gene.id}`,
            start: sector.len / 2 + HISTOGRAM_WIDTH * index + offset, // eslint-disable-line
            end: sector.len / 2 + HISTOGRAM_WIDTH * (index + 1) + offset, // eslint-disable-line
            value: histogramItem.measure,
            name: histogramItem.name,
            sourceName: item.topTissuesSource,
          });
        });

        histogramData.push({
          block_id: `${item.gene.id}`,
          start: sector.len / 2 + 120, // eslint-disable-line
          end: sector.len / 2 + 170, // eslint-disable-line
          value: 0,
        });
      });
    }

    const histogramSettings = {
      opacity: 1,
      color: 'Purples',
      logScale: true,
      tooltipContent(datum) {
        return `<span><b>${datum.name}(${datum.sourceName}):${datum.value}</b></span>`;
      },
    };

    const chordsData = [];

    chords.forEach((chord) => {
      const target = this.getById(diagramData, chord.object.id);
      const subject = this.getById(diagramData, chord.subject.id);
      const chordWidth = HISTOGRAM_WIDTH;

      chordsData.push(
        {
          target: {
            id: `${chord.object.id}`,
            start: target.len / 2 - chordWidth, // eslint-disable-line
            end: target.len / 2 + chordWidth, // eslint-disable-line
          },
          source: {
            id: `${chord.subject.id}`,
            start: subject.len / 2 - chordWidth, // eslint-disable-line
            end: subject.len / 2 + chordWidth, // eslint-disable-line
          },
          value: 4,
          id: chord.id,
        }
      );
    });

    const chordsSettings = {
      tooltipContent: (d) => {
        const link = this.getById(chords, d.id);
        return `<span>${link.subject.name} ${link.predicate.name} ${link.object.name}</span>`;
      },
      color: (datum, index) => this.changeChordColor(datum, index, targetGene),
    };

    const highlightData = [];

    if (this.props.isQTL) {
      data.forEach((item) => {
        if (item.end - item.start < 0) {
          highlightData.push({
            block_id: `${item.chromosome}`,
            start: 0,
            end: 1,
            color: item.qtlConcept.color,
            id: item.qtlConcept.id,
            name: item.qtlConcept.name,
          });
        } else {
          highlightData.push({
            block_id: `${item.chromosome}`,
            start: item.start,
            end: item.end,
            color: item.qtlConcept.color,
            id: item.qtlConcept.id,
            name: item.qtlConcept.name,
          });
        }
      });
    }

    const highlightsSettings = {
      innerRadius: 250,
      outerRadius: 300,
      min: null,
      max: null,
      color(d) {
        return `#${d.color}`;
      },
      strokeColor: null,
      strokeWidth: 1,
      opacity: 1,
      logScale: false,
      tooltipContent: null,
      events: {
        mouseover(datum, index, nodes, event) {
          openToolTip(datum, index, nodes, event);
          return null;
        },
        mouseout() {
          hideTooltip();
        },
        click: this.sectorClickHandler,
      },
    };

    if (data[0].histogram) {
      this.myCircos.histogram(`${this.props.id}-histogram`, histogramData, histogramSettings);
    }

    this.myCircos.chords(`${this.props.id}-chords`, chordsData, chordsSettings);
    this.myCircos.heatmap(`${this.props.id}-heatmap`, heatmapData, heatmapSettings);
    this.myCircos.highlight(`${this.props.id}-highlights`, highlightData, highlightsSettings);
    if (!this.props.hideTextTrack) {
      this.myCircos.text(`${this.props.id}-text`, textTrackData, textTrackDataConfig);
    }
    this.myCircos.render();
    if (isNeededToTrimLabels) {
      this.prettifyTicksText();
    }
    this.rotateLabels();
  };

  rotateLabels = () => {
    window.d3.selectAll('.block').each(function () {
      const rotateParam = window.d3.select(this).attr('transform');
      const isTextExist = !window.d3.select(this)
        .select('text')
        .empty();
      const isPathExist = !window.d3.select(this)
        .select('path')
        .empty();
      if (parseInt(rotateParam.match(/[0-9.]+/g)[0], 10) > 180 && isTextExist) { //eslint-disable-line
        const transformAttr = window.d3.select(this)
          .select('text')
          .attr('transform')
          .split('\n');
        let rotate = '';
        let translate = '';
        if (transformAttr.length > 1) {
          rotate = transformAttr[1].trim();
          translate = transformAttr[2].trim();
        } else {
          const splitedTransformAttr = transformAttr[0].split(' ');
          rotate = splitedTransformAttr[0]; //eslint-disable-line
          translate = 'translate(320, 0)';
        }
        window.d3.select(this)
          .select('text')
          .attr('transform', `${rotate} ${translate} rotate(180)`)
          .attr('text-anchor', 'end');
      } else if (isPathExist) {
        window.d3.select(this)
          .selectAll('.bin')
          .remove();
      }
    });
  };

  exportToPNGCb = () => {
    const { geneName, chartName, id } = this.props;
    window.d3.select(`#${this.props.id}`).selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
      .remove();
    this.initDiagram(this.props.data, this.props.chords, false);
    const diagram = window.document.querySelector(`#${id} svg`);
    const name = `${chartName}_${geneName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, true);
      window.d3.select(`#${this.props.id}`).selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
        .remove();
      this.initDiagram(this.props.data, this.props.chords);
    }
  };

  exportToSVGCb = () => {
    const { geneName, chartName, id } = this.props;
    window.d3.select(`#${this.props.id}`).selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
      .remove();
    this.initDiagram(this.props.data, this.props.chords, false);
    const container = window.document.querySelector(`#${id} div:not(.export-buttons-section):not(.export-button)`);
    const name = `${chartName}_${geneName}.svg`;
    if (container) {
      exportToSVG(container, name, true);
      window.d3.select(`#${this.props.id}`).selectAll('*:not(.export-buttons-section):not(.circos-export-button-wrapper):not(.export-button)')
        .remove();
      this.initDiagram(this.props.data, this.props.chords);
    }
  };

  render() {
    const uniqueKey = `circos-diagram-tooltip_${this.props.id}`;
    return (
      <>
        <div id={this.props.id} className="circosDiagram">
          <div className="export-buttons-section" >
            <span className="circos-export-button-wrapper">
              <div
                className="export-button"
                onClick={this.exportToPNGCb}
                title="Export to png"
              />
              PNG
            </span>
            <span className="circos-export-button-wrapper">
              <div
                className="export-button"
                onClick={this.exportToSVGCb}
                title="Export to svg"
              />
              SVG
            </span>
          </div>
        </div>
        <Tooltip uniqueKeyProp={uniqueKey}>
          <ShortConceptCard />
        </Tooltip>
      </>
    );
  }
}

CircosDiagram.defaultProps = defaultProps;
CircosDiagram.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(CircosDiagram);
