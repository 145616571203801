import { fromJS } from 'immutable';

import { GENE_DISEASE_CONCLUSION_INIT_FAILED, GENE_DISEASE_CONCLUSION_INIT_SUCCEEDED } from './constants';

const initialState = fromJS({
  data: {},
  error: null,
  loading: true,
});

const geneDiseasePhenotype = (state = initialState, action) => {
  switch (action.type) {
    case GENE_DISEASE_CONCLUSION_INIT_SUCCEEDED: {
      const { data } = action;
      return state.merge(fromJS({ data, error: null, loading: false }));
    }

    case GENE_DISEASE_CONCLUSION_INIT_FAILED:
      return state.merge(fromJS({ data: {}, error: action.message, loading: false }));

    default:
      return state;
  }
};

export default geneDiseasePhenotype;
