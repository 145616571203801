import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { sortingOptions } from '../../../GeneDetails/GeneExpressionChapter/constants';

// tissues
export const getSingleTargetForCancerTissuesAction = createAction('@@singleTargetForCancer/GET_TISSUES');
export const toggleSingleTargetForCancerTissueLoaderAction = createAction('@@singleTargetForCancer/TOGGLE_TISSUES_LOADER');
export const singleTargetForCancerTissueErrorAction = createAction('@@singleTargetForCancer/TISSUES_ERROR');
export const singleTargetForCancerUpdateTissuesAction = createAction('@@singleTargetForCancer/UPDATE_TISSUES');
export const singleTargetForCancerCheckTissueAction = createAction('@@singleTargetForCancer/CHECK_TISSUE');
export const singleTargetForCancerCheckAllTissuesAction = createAction('@@singleTargetForCancer/CHECK_ALL_TISSUES');
export const singleTargetForCancerUpdateTumorTissuesAction = createAction('@@singleTargetForCancer/UPDATE_TUMOR_TISSUES');
export const singleTargetForCancerTumorTissuesColumnsSortDirectionAction = createAction('@@singleTargetForCancer/SORT_TUMOR_TISSUES');
export const singleTargetForCancerToggleCheckALlTumorTissuesAction = createAction('@@singleTargetForCancer/TOGGLE_CHECK_ALL_TUMOR_TISSUES');
// new data expression
export const setGtexTissuesAction = createAction('@@singleTargetForCancer/SET_GTEX_TISSUES');
export const setBeatmlDataTissuesAction = createAction('@@singleTargetForCancer/SET_BEATML_DATA_TISSUES');
export const setTcgaTissuesAction = createAction('@@singleTargetForCancer/SET_TCGA_DATA_TISSUES');
export const setTummorTissuesAction = createAction('@@singleTargetForCancer/SET_TUMMOR_TISSUES');
// columns
export const updateSingleTargetForCancerTissuesFormColumnAction = createAction('@@singleTargetForCancer/TISSUES_FORM_COLUMN');
export const updateSingleTargetForCancerTissuesFormColumnsAction = createAction('@@singleTargetForCancer/TISSUES_FORM_COLUMNS');
export const setSingleTargetForCancerTissuesColumnsSortDirectionAction = createAction('@@singleTargetForCancer/TISSUES_COLUMNS_SORT_DIRECTION');
// content
export const setSingleTargetForCancerDataAction = createAction('@@singleTargetForCancer/SET_DATA');
export const sortSingleTargetForCancerDataAction = createAction('@@singleTargetForCancer/SORT_DATA');
export const setSingleTargetForCancerRequestDataAction = createAction('@@singleTargetForCancer/SET_REQUEST_DATA');
// charts
export const getSingleTargetForCancerChartsAction = createAction('@@singleTargetForCancer/GET_CHARTS');
export const setSingleTargetForCancerChartsAction = createAction('@@singleTargetForCancer/SET_CHARTS');
export const toogleSingleTargetForCancerChartsLoaderAction = createAction('@@singleTargetForCancer/TOGGLE_CHARTS_LOADER');
export const throwSingleTargetForCancerChartsErrorAction = createAction('@@singleTargetForCancer/THROW_CHARTS_ERROR');
export const resetSingleTargetForCancerChartsAction = createAction('@@singleTargetForCancer/RESET_CHARTS');
// reset
export const resetSingleTargetForCancerStoreAction = createAction('@@singleTargetForCancer/RESET_STORE');
// gtexData
export const setGtexExpressionForSingleTargetForCancer = createAction('@@singleTargetForCancer/GTEX_EXPRESSION_INIT_SUCCEEDED');
export const setTcgaExpressionForSingleTargetForCancer = createAction('@@singleTargetForCancer/TCGA_EXPRESSION_INIT_SUCCEEDED');
// sorting option for expressions
export const setSelectedSortingOptionAction = createAction('@@singleTargetForCancer/SET_SELECTED_SORTING_OPTION');
// selected tissues expression data
export const setSelectedTissuesExpressionData = createAction('@@singleTargetForCancer/SELECTED_TISSUES_EXPRESSION_INIT_SUCCEEDED');

const initialState = fromJS({
  form: {
    tissuesLoading: false,
    tissuesError: null,
    columnsOrder: ['normalTissues', 'sensitiveTissues'],
    parentsOrder: ['GTEX', 'BEAT_AML', 'TCGA'],
    columns: {
      normalTissues: {
        id: 'normalTissues',
        title: 'Normal Tissues',
        checkAll: true,
        childTissues: {
          GTEX: {
            subId: 'normal-GTEX',
            id: 'GTEX',
            title: 'GTEx Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
          BEAT_AML: {
            subId: 'normal-BEAT_AML',
            id: 'BEAT_AML',
            title: 'Beat AML Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
          TCGA: {
            subId: 'normal-TCGA',
            id: 'TCGA',
            title: 'TCGA Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
        },
      },
      sensitiveTissues: {
        id: 'sensitiveTissues',
        title: 'Sensitive Tissues',
        checkAll: false,
        childTissues: {
          GTEX: {
            subId: 'sensitive-GTEX',
            id: 'GTEX',
            title: 'GTEx Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
          BEAT_AML: {
            subId: 'sensitive-BEAT_AML',
            id: 'BEAT_AML',
            title: 'Beat AML Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
          TCGA: {
            subId: 'sensitive-TCGA',
            id: 'TCGA',
            title: 'TCGA Tissues',
            tissuesIds: [],
            tissues: [],
            checkAll: true,
            sortDirection: 'ASC',
          },
        },
      },
    },
    tumorTissues: {
      childTissues: {
        BEAT_AML: {
          id: 'BEAT_AML',
          title: 'Beat AML Tissues',
          tissues: [],
          checkAll: true,
          sortDirection: 'ASC',
        },
        TCGA: {
          id: 'TCGA',
          title: 'TCGA Tissues',
          tissues: [],
          checkAll: true,
          sortDirection: 'ASC',
        },
      },
      checkAll: true,
    },
  },
  content: {
    data: {},
    sort: { sortBy: '', sortDirection: 'DESC' },
    requestData: null,
  },
  GTEX: {
    data: [],
    selectedSortingOption: sortingOptions[0],
  },
  TCGA: {
    data: [],
    selectedSortingOption: sortingOptions[0],
  },
  selectedTissuesExpressionData: [],
  charts: {
    loading: false,
    error: null,
  },
});

const reducer = handleActions(
  {
    // Tissues
    [toggleSingleTargetForCancerTissueLoaderAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'tissuesLoading'], () => payload)
        .updateIn(['form', 'tissuesError'], () => null),
    [singleTargetForCancerTissueErrorAction]: (state, { payload }) =>
      state.updateIn(['form', 'tissuesError'], () => payload),
    [singleTargetForCancerUpdateTissuesAction]: (state, { payload }) =>
      state.updateIn(['form', 'columns', payload.type], () => fromJS(payload.updatedTissues)),
    [singleTargetForCancerUpdateTumorTissuesAction]: (state, { payload }) =>
      state.updateIn(['form', 'tumorTissues'], () => fromJS(payload)),
    [singleTargetForCancerCheckTissueAction]: (state, { payload }) =>
      state.updateIn(['form', 'tissues', `${payload.id}`, 'checked'], checked => !checked),
    [singleTargetForCancerTumorTissuesColumnsSortDirectionAction]: (state, { payload }) =>
      state.updateIn(['form', 'tumorTissues', 'childTissues', payload.type, 'sortDirection'],
        sortDirection => (sortDirection === 'ASC' ? 'DESC' : 'ASC')),
    [singleTargetForCancerToggleCheckALlTumorTissuesAction]: state =>
      state.updateIn(['form', 'tumorTissues', 'checkAll'], checkAll => !checkAll),
    [setTummorTissuesAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'tumorTissues', 'childTissues', 'BEAT_AML', 'tissues'], () => fromJS(payload.beatamlDataTissues))
        .updateIn(['form', 'tumorTissues', 'childTissues', 'TCGA', 'tissues'], () => fromJS(payload.tcgaDataTissues)),
    [setGtexTissuesAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'GTEX', 'tissuesIds'], () => fromJS(payload.gtexTissuesIds))
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'GTEX', 'tissues'], () => fromJS(payload.gtexTissues)),
    [setBeatmlDataTissuesAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'BEAT_AML', 'tissuesIds'], () => fromJS(payload.beatamlDataTissuesIds))
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'BEAT_AML', 'tissues'], () => fromJS(payload.beatamlDataTissues)),
    [setTcgaTissuesAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'TCGA', 'tissuesIds'], () => fromJS(payload.tcgaDataTissuesIds))
        .updateIn(['form', 'columns', 'normalTissues', 'childTissues', 'TCGA', 'tissues'], () => fromJS(payload.tcgaTissues)),
    [updateSingleTargetForCancerTissuesFormColumnAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'columns', payload.parentName, 'childTissues'], () => fromJS(payload.data)),
    [updateSingleTargetForCancerTissuesFormColumnsAction]: (state, { payload }) =>
      state
        .updateIn(['form', 'columns', payload.start.id, 'childTissues', payload.tissueName, 'tissuesIds'], () => fromJS(payload.start.tissuesIds))
        .updateIn(['form', 'columns', payload.finish.id, 'childTissues', payload.tissueName, 'tissuesIds'], () => fromJS(payload.finish.tissuesIds))
        .updateIn(['form', 'columns', payload.start.id, 'childTissues', payload.tissueName, 'tissues'], () => fromJS(payload.start.tissues))
        .updateIn(['form', 'columns', payload.finish.id, 'childTissues', payload.tissueName, 'tissues'], () => fromJS(payload.finish.tissues)),
    [setSingleTargetForCancerTissuesColumnsSortDirectionAction]: (state, { payload }) =>
      state.updateIn(['form', 'columns', payload.columnId, 'childTissues', payload.type, 'sortDirection'],
        sortDirection => (sortDirection === 'ASC' ? 'DESC' : 'ASC')
      ),
    [singleTargetForCancerCheckAllTissuesAction]: (state, { payload }) =>
      state.updateIn(['form', 'columns', payload.id, 'checkAll'], () => payload.checked),
    // Content
    [setSingleTargetForCancerDataAction]: (state, { payload }) =>
      state
        .updateIn(['content', 'data'], () => fromJS(payload.data))
        .updateIn(['content', 'totalPages'], () => payload.totalPages)
        .updateIn(['content', 'totalElements'], () => payload.totalElements)
        .updateIn(['content', 'pageNumber'], () => payload.pageNumber),
    [sortSingleTargetForCancerDataAction]: (state, { payload }) =>
      state.updateIn(['content', 'sort'], () => fromJS(payload)),
    [setSingleTargetForCancerRequestDataAction]: (state, { payload }) =>
      state.updateIn(['content', 'requestData'], () => fromJS(payload)),
    // Charts
    [setSingleTargetForCancerChartsAction]: state =>
      state
        .updateIn(['charts', 'error'], () => null),
    [toogleSingleTargetForCancerChartsLoaderAction]: (state, { payload }) =>
      state.updateIn(['charts', 'loading'], () => payload),
    [throwSingleTargetForCancerChartsErrorAction]: (state, { payload }) =>
      state.updateIn(['charts', 'error'], () => payload),
    [resetSingleTargetForCancerChartsAction]: state =>
      state.update('charts', () => initialState.get('charts')),
    [resetSingleTargetForCancerStoreAction]: state =>
      state
        .updateIn(['form', 'columns', 'sensitiveTissues'], () =>
          initialState.getIn(['form', 'columns', 'sensitiveTissues']))
        .updateIn(['form', 'tumorTissues', 'sortDirection'], () =>
          initialState.getIn(['form', 'tumorTissues', 'sortDirection']))
        .updateIn(['form', 'tumorTissues', 'checkAll'], () =>
          initialState.getIn(['form', 'tumorTissues', 'checkAll']))
        .updateIn(['content', 'data'], () =>
          initialState.getIn(['content', 'data']))
        .updateIn(['content', 'sort'], () =>
          initialState.getIn(['content', 'sort'])),
    [setGtexExpressionForSingleTargetForCancer]: (state, { payload }) =>
      state
        .updateIn(['GTEX', 'data'], () => fromJS(payload)),
    [setTcgaExpressionForSingleTargetForCancer]: (state, { payload }) =>
      state
        .updateIn(['TCGA', 'data'], () => fromJS(payload)),
    [setSelectedSortingOptionAction]: (state, { payload }) =>
      state
        .updateIn([payload.type, 'selectedSortingOption'], () => fromJS(payload.option)),
    [setSelectedTissuesExpressionData]: (state, { payload }) =>
      state.update('selectedTissuesExpressionData', () => fromJS(payload)),
  },
  initialState
);

export default reducer;
