import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import SingleCellChartsByMetaData from './Components/SingleCellChartsByMetaData/SingleCellChartsByMetaData';
import SingleCellChartsByGeneData from './Components/SingleCellChartsByGeneData/SingleCellChartsByGeneData';
import DatasetsSelector from './Components/DatasetsSelector/DatasetsSelector';
// Store
import * as s from './store/selectors';
import * as a from './store/actions';
import { getDatasetsOptionsSelector } from './Components/DatasetsSelector/store/selectors';
// Styles
import './styles.scss';
import { isEmptyObject } from '../../../Utils/Utils';

const propTypes = {
  measures: PropTypes.instanceOf(Array),
  getColorsData: PropTypes.func,
  filtersAreEmpty: PropTypes.bool,
  selectedFilterValue: PropTypes.instanceOf(Object),
  resetSingleCellData: PropTypes.func,
  selectedDataset: PropTypes.instanceOf(Object),
  setSelectedDataset: PropTypes.func,
  urlParams: PropTypes.instanceOf(Object),
};

const SingleCellStudyViewer = (props) => {
  const {
    urlParams,
    measures,
    getColorsData,
    selectedDataset = {},
    setSelectedDataset,
    filtersAreEmpty,
    selectedFilterValue,
    resetSingleCellData,
  } = props;

  useEffect(() => () => {
    resetSingleCellData();
  }, []);

  useEffect(() => {
    if (selectedDataset.tumorType) {
      getColorsData(selectedDataset.tumorType);
    }
  }, [selectedDataset]);

  const handleActiveDataset = (option) => {
    setSelectedDataset(option);
  };

  const datasets = useMemo(() => {
    if (!isEmptyObject(selectedDataset)) {
      return ({
        ids: selectedDataset.datasets.map(d => d.id),
        titles: selectedDataset.datasets.map(d => d.name),
        tumorType: selectedDataset.tumorType,
      });
    }
    return null;
  }, [selectedDataset]);

  return (
    <div className="algorithm single-cell-study">
      <div className="algorithm__wrap">
        <div className="algorithm__container">
          <div className="stc-form-title">
            <h3 className="workflows__title title3">
              Single Cell Study Viewer
            </h3>
            <p className="workflows__text">
              The single cell study viewer provides an interactive viewer to explore single cell RNAseq data from
              selected publications. Count data were downloaded for respective studies and processed according to the
              methods described in the original publication. Cell types are defined according to annotations and/or
              expression of marker genes as described in the publication.
            </p>
            <p className="workflows__text">
              In order to explore expression of your gene of interest, we enable visualisation of expression in a
              dimensionality reduction plot (UMAP) as well as a violin plot, with selection options for condition
              (tumor or normal tissue), patient ID, or cell type of interest.
            </p>
          </div>
          <DatasetsSelector
            tumorType={urlParams.tumorType}
            selectedDataset={selectedDataset}
            handleActiveDataset={handleActiveDataset}
          />
          {
            datasets &&
              <>
                <SingleCellChartsByMetaData
                  datasets={datasets}
                  measures={measures}
                  filtersAreEmpty={filtersAreEmpty}
                  selectedFilterValue={selectedFilterValue}
                />
                <SingleCellChartsByGeneData
                  datasets={datasets}
                  geneInfo={urlParams}
                  measures={measures}
                  filtersAreEmpty={filtersAreEmpty}
                  selectedDataset={selectedDataset}
                  selectedFilterValue={selectedFilterValue}
                />
              </>
          }
        </div>
      </div>
    </div>
  );
};

SingleCellStudyViewer.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    datasetsOptions: getDatasetsOptionsSelector(state),
    selectedDataset: s.getSelectedDatasetSelector(state),
    filtersAreEmpty: s.getDataFiltersAreEmptySelector(state),
    measures: s.getMeasuresSelector(state),
    selectedFilterValue: s.getSelectedFilterValueSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getColorsData(data) {
      dispatch(a.getColorsDataAction(data));
    },
    resetSingleCellData() {
      dispatch(a.resetSingleCellDataAction());
    },
    setSelectedDataset(data) {
      dispatch(a.setSelectedDatasetAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCellStudyViewer);
