import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ProjectForm from '../ProjectForm/ProjectForm';
import Error from '../../../../common/Error/Error';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Store
import * as a from '../../store/reducer';
import * as s from '../../store/selectors';
// Styles
import './styles.scss';


const propTypes = {
  data: PropTypes.instanceOf(Object),
  formValues: PropTypes.instanceOf(Object),
  projectUsers: PropTypes.instanceOf(Array),
  getProjectUsersData: PropTypes.func,
  getProjectData: PropTypes.func,
  activeProjectId: PropTypes.string,
  userNameFilter: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onSubmit: PropTypes.func,
  isAdminRights: PropTypes.bool,
  filterProjectsManagementData: PropTypes.func,
};

const ProjectModal = (props) => {
  const {
    data,
    formValues,
    projectUsers,
    getProjectData,
    getProjectUsersData,
    activeProjectId,
    userNameFilter,
    title,
    type,
    error,
    isOpen,
    closeCb,
    onSubmit,
    isAdminRights,
    filterProjectsManagementData,
  } = props;

  useEffect(() => {
    getProjectUsersData();
    if (type === 'EDIT' && activeProjectId) {
      getProjectData(activeProjectId);
    }
  }, [activeProjectId, type]);

  const handleFormSubmit = useCallback(() => {
    if (type === 'EDIT' && activeProjectId) {
      onSubmit({ id: activeProjectId, version: data.version });
    } else {
      onSubmit({ version: null });
    }
  }, [type, data, activeProjectId, onSubmit]);

  const filterUsers = useCallback((value) => {
    filterProjectsManagementData({
      key: 'userName',
      value,
    });
  }, [filterProjectsManagementData]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="project-modal">
        <div className="title2 project-modal__title">
          {title}
        </div>
        {
          !error &&
          <ProjectForm
            type={type}
            handleFormSubmit={handleFormSubmit}
            usersData={projectUsers}
            formValues={formValues}
            data={data}
            isAdminRights={isAdminRights}
            userNameFilter={userNameFilter}
            filterUsers={filterUsers}
          />
        }
        <Error
          show={!!error}
          error={error}
          customClassName="text-center error-text"
        />
      </div>
    </ModalComponent>
  );
};

ProjectModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: s.getProjectDataSelector(state),
    error: s.getPopupErrorSelector(state),
    formValues: s.getProjectFormSelector(state),
    projectUsers: s.getProjectUsersSelector(state),
    userNameFilter: s.getUserNameFilterSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectData(id) {
      dispatch(a.getProjectDataAction(id));
    },
    getProjectUsersData() {
      dispatch(a.getUsersByDomainAction());
    },
    filterProjectsManagementData(data) {
      dispatch(a.filterProjectsManagementDataAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectModal);
