import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import PublicationsList from '../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';

import {
  getHumanPhenotypesDataSelector,
} from './redux/selectors';
import {
  humanPhenotypesChangePageAction,
} from './redux/actions';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  humanPhenotypes: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  changePage: PropTypes.func,
};

class HumanPhenotypesTable extends React.Component {
  state = {
    popup: false,
    showInfoText: false,
  };
  conceptsNames = [];
  conceptsIds = [];

  handlePageClick = (page) => {
    const { changePage } = this.props;

    changePage(page.selected);
  };

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data.phenotype;
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.conceptsNames.push(geneName);
    this.conceptsNames.push(name);
    this.conceptsIds.push(gene);
    this.conceptsIds.push(id);
    this.setState({ popup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ popup: false });
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  phenotypeCell = ({ id, name }, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      humanPhenotypes: {
        data = [],
        allData = [],
        pageNumber,
        totalPages,
      },
      geneName,
      width,
    } = this.props;

    const {
      popup,
      showInfoText,
    } = this.state;

    const tableSettings = {
      height: 500,
      width,
      headerHeight: 50,
      rowHeight: 50,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
    };

    const columns = [
      {
        label: 'Phenotype',
        dataKey: 'phenotype',
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(50),
        disableSort: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.phenotype ? this.phenotypeCell(rowData.phenotype, rowIndex) : null
        ),
      },
      {
        label: 'References',
        dataKey: 'publicationsCount',
        exportCSV: true,
        width: this.getColumnPercentWidth(50),
        className: 'table-wrap__cell',
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          rowData.publicationsCount > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openPublicationsPopup(rowData); }}
            >
              {rowData.publicationsCount}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.publicationsCount}
            </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Human phenotypes
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows direct phenotypes annotations from various databases.
              For each of these phenotype associations all Pubmed articles that co-mention the gene are shown in the right hand column and can be accessed by clicking the link.
            </p>
          </div>
        }
        {
          data.length ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakLabel={<a>...</a>}
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-human-phenotype`}
                    />
                  }
                </div>
              }
              <SimpleTable
                data={data}
                autoSize={true}
                columns={columns}
                settings={tableSettings}
                dynamicHeight={true}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
        {
          popup &&
          <ModalComponent
            isOpen={popup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

HumanPhenotypesTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    humanPhenotypes: getHumanPhenotypesDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(humanPhenotypesChangePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HumanPhenotypesTable);

