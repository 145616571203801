export const specialTissues = [
  'frontal cortex',
  'frontal lobe',
  'globus pallidus',
  'hippocampus',
  'hypothalamus',
  'locus coeruleus',
  'medulla oblongata',
  'middle frontal gyrus',
  'middle temporal gyrus',
  'nucleus accumbens (basal ganglia)',
  'occipital cortex',
  'occipital lobe',
  'parietal lobe',
  'prefrontal cortex',
  'putamen (basal ganglia)',
  'spinal cord',
  'substantia nigra',
  'thalamus',
  'tibial nerve',
  'temporal lobe',
  'amygdala',
  'anterior cingulate cortex of brain',
  'brain',
  'caudate (basal ganglia) of brain',
  'cerebral cortex',
  'cerebellar hemisphere of brain',
  'cerebellum',
  'cerebral meninges',
  'diencephalon',
  'dura mater',
];

export const linksForTables = {
  geneDetails: 'gene-details',
  conceptDetails: 'concept-details',
};
