import { call, put, select } from 'redux-saga/effects';

import Api from '../../../../../../Api/Api';
import {
  setSingleTargetForCancerChartsAction,
  toogleSingleTargetForCancerChartsLoaderAction,
  throwSingleTargetForCancerChartsErrorAction,
  setGtexExpressionForSingleTargetForCancer,
  setTcgaExpressionForSingleTargetForCancer,
  setSelectedTissuesExpressionData,
} from '../../reducer';
import { getTissuesDataForRequest } from '../../selectors';
import {
  sortExpressionData,
  prepareSelectedTissuesExpressionData,
} from '../../utils';
import { selectedTissuesColors } from '../../constants';

export function* getSingleTargetForCancerChartsWorker(action) {
  try {
    yield put(toogleSingleTargetForCancerChartsLoaderAction(true));
    const { id } = action.payload;
    const result = yield call(Api.geneDetailExpression, { id });
    const {
      gtexExpression,
      tcgaExpression,
    } = result.data;

    let gtexExpressionSorted = [];
    let tcgaExpressionSorted = [];

    if (gtexExpression) {
      gtexExpressionSorted = sortExpressionData(gtexExpression);
      yield put(setGtexExpressionForSingleTargetForCancer(gtexExpressionSorted));
    }

    if (tcgaExpression) {
      const normalTissueGroup = tcgaExpression.filter(i => i.group === 'Normal Tissue');
      const tumorGroup = tcgaExpression.filter(i => i.group === 'Tumor');

      tcgaExpressionSorted = [
        ...prepareSelectedTissuesExpressionData(
          { TCGA: normalTissueGroup },
          false,
          selectedTissuesColors.NORMAL_TISSUES,
        ),
        ...prepareSelectedTissuesExpressionData(
          { TCGA: tumorGroup },
          false,
          selectedTissuesColors.TUMOR_TISSUES,
        ),
      ];
      yield put(setTcgaExpressionForSingleTargetForCancer(tcgaExpressionSorted));
    }

    // Chart for selected Tissues
    const selectedTissues = yield select(getTissuesDataForRequest);
    const { data: { sensitiveTissues, normalTissues, tumorTissues } } = yield call(Api.selectedTissuesExpression, { id, selectedTissues });

    const selectedTissuesExpressionData = [
      ...prepareSelectedTissuesExpressionData(normalTissues, true, selectedTissuesColors.NORMAL_TISSUES),
      ...prepareSelectedTissuesExpressionData(sensitiveTissues, true, selectedTissuesColors.SENSITIVE_TISSUES),
      ...prepareSelectedTissuesExpressionData(tumorTissues, true, selectedTissuesColors.TUMOR_TISSUES),
    ];

    yield put(setSelectedTissuesExpressionData(selectedTissuesExpressionData));

    yield put(setSingleTargetForCancerChartsAction());
    yield put(toogleSingleTargetForCancerChartsLoaderAction(false));
  } catch (e) {
    yield put(throwSingleTargetForCancerChartsErrorAction(e.message));
    yield put(toogleSingleTargetForCancerChartsLoaderAction(false));
  }
}
