export const PUBMED_URL_DEFAULT = 'https://www.ncbi.nlm.nih.gov/pubmed/%s';

export const semanticCategoriesOrder = [
  'Genes & Molecular Sequences',
  'Disorders',
  'Chemicals & Drugs',
  'Physiology',
  'Anatomy',
  'Procedures',
  'Living Beings',
  'Concepts & Ideas',
  'Organizations',
  'Devices',
  'Objects',
  'Occupations',
  'Phenomena',
  'Activities & Behaviors',
  'Geographic Areas',
];

export const resetingSearchValues = {
  dateFrom: null,
  dateTo: null,
  pubSource: null,
  researchTypes: [],
  publicationTypes: [],
  publicationSource: null,
  impactFactorFrom: null,
  impactFactorTo: null,
  sources: [],
};
