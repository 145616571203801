import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

// Utils
import { manageableScrollInToView } from '../../Utils/Utils';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import GeneDiseaseExpressionSection from '../GeneDiseaseExpressionSection/GeneDiseaseExpressionSection';
import GeneDiseaseAnnotationSection from '../GeneDiseaseAnnotationSection/GeneDiseaseAnnotationSection';
import GeneDiseaseGeneMutationSection from '../GeneDiseaseGeneMutationSection/GeneDiseaseGeneMutationSection';
import GeneDiseaseAnimalModelSection from '../GeneDiseaseAnimalModelSection/GeneDiseaseAnimalModelSection';
import GeneDiseasePhenotypeSection from '../GeneDiseasePhenotypeSection/GeneDiseasePhenotypeSection';
import GeneDiseasePathwaySection from '../GeneDiseasePathwaySection/GeneDiseasePathwaySection';
import GeneDiseaseDifferentialExpressionSection from '../GeneDiseaseDifferentialExpressionSection/GeneDiseaseDifferentialExpressionSection';
import GeneDiseaseCellMolecularDysfunction from '../GeneDiseaseCellMollecularDysfunction/GeneDiseaseCellMolecularDysfunction';
import GeneDiseaseOrganTissueFunction from '../GeneDiseaseOrganTissueFunction/GeneDiseaseOrganTissueFunction';
import GeneDiseaseConclusion from '../GeneDiseaseConclusion/GeneDiseaseConclusion';
import GeneDiseaseIntro from './GeneDiseaseIntro';
// Store
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
// Styles
import './GeneDiseasePage.css';

const propTypes = {
  initPage: PropTypes.func,
  pageChapters: PropTypes.instanceOf(Array),
  pageChaptersData: PropTypes.instanceOf(Array),
  error: PropTypes.string,
  loading: PropTypes.bool,
  diseaseClassifications: PropTypes.instanceOf(Array),
  geneClassifications: PropTypes.instanceOf(Array),
  geneSynonyms: PropTypes.instanceOf(Array),
  diseaseSynonyms: PropTypes.instanceOf(Array),
  predicate: PropTypes.string,
  gene: PropTypes.instanceOf(Object),
  disease: PropTypes.instanceOf(Object),
  resetGeneDisease: PropTypes.func,
  fetchTissues: PropTypes.func,
  highlightedTissuesIds: PropTypes.instanceOf(Array),
  tissuesData: PropTypes.instanceOf(Object),
  tissuesLoader: PropTypes.bool,
  lastVisitedGeneId: PropTypes.string,
  lastVisitedDiseaseId: PropTypes.string,
  setLastVisitedConceptId: PropTypes.func,
  locationParams: PropTypes.instanceOf(Object),
};

class GeneDiseasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleHelperText: false,
    };
  }

  componentDidMount() {
    this.itsNewConcept = this.props.error
      ? true
      : this.props.locationParams.diseaseId !== this.props.lastVisitedDiseaseId || this.props.locationParams.geneId !== this.props.lastVisitedGeneId;
    this.init();
  }
  init = () => {
    const {
      locationParams: { geneId, diseaseId },
      resetGeneDisease,
      initPage,
      fetchTissues,
      setLastVisitedConceptId,
    } = this.props;

    if (this.itsNewConcept) {
      resetGeneDisease();
      setLastVisitedConceptId({ geneId, diseaseId });
      initPage({ geneId, diseaseId });
      fetchTissues({ geneId, diseaseId });
    }
  };

  render() {
    const {
      gene,
      geneClassifications,
      geneSynonyms,
      disease,
      diseaseClassifications,
      diseaseSynonyms,
      predicate,
      loading,
      error,
      highlightedTissuesIds,
      tissuesData,
      tissuesLoader,
      pageChapters,
      locationParams: { geneId, diseaseId },
      pageChaptersData,
    } = this.props;

    const geneName = gene ? gene.name : '';
    const diseaseName = disease ? disease.name : '';
    const tissues = !tissuesLoader && highlightedTissuesIds.length > 0 && !isEmpty(tissuesData);
    return (
      <div className="gene-disease-page">
        {
          !loading &&
          <div className="flex-grid align-self-center gene-disease-container">
            <div className="col-1 text-center concept-details-left-column concept-details-columns">
              <div className="concept-details-columns-sidebar">
                <div className="gene-disease-left-column-title title2">
                  Contents
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#gene-disease-expression', '.gene-disease-header'); }}
                  className="gene-disease-left-column-action"
                >
                  Expression
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#gene-disease-annotation', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[1].isLoaded || !pageChaptersData[1].isDataExist }
                    )
                  }
                >
                  Genetic annotations
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#differential-expression', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[2].isLoaded || !pageChaptersData[2].isDataExist }
                    )
                  }
                >
                  Differential expression
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#gene-mutation', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[3].isLoaded || !pageChaptersData[3].isDataExist }
                    )
                  }
                >
                  Gene mutation
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#animal-model', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[4].isLoaded || !pageChaptersData[4].isDataExist }
                    )
                  }
                >
                  Animal model
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#phenotypes', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[6].isLoaded || !pageChaptersData[6].isDataExist }
                    )
                  }
                >
                  Phenotypes
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#pathways', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[7].isLoaded || !pageChaptersData[7].isDataExist }
                    )
                  }
                >
                  Pathways
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#cell-molecular', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[8].isLoaded || !pageChaptersData[8].isDataExist }
                    )
                  }
                >
                  Cell or molecular dysfunction
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#organ-tissue', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[9].isLoaded || !pageChaptersData[9].isDataExist }
                    )
                  }
                >
                  Organ or Tissue function
                </div>
                <div
                  role="presentation"
                  onClick={() => { manageableScrollInToView('#conclusion-section', '.gene-disease-header'); }}
                  className={
                    classnames(
                      'gene-disease-left-column-action',
                      { 'disabled': !pageChapters[10].isLoaded || !pageChaptersData[10].isDataExist }
                    )
                  }
                >
                  Conclusion
                </div>
              </div>
            </div>
            <div className="col-6 gene-disease-columns gene-disease-right-column pr-10 pl-10">
              <div className="gene-disease-header title3">
                <span>Role of { geneName } in { diseaseName }</span>
              </div>
              <div className="gene-disease-content">
                <div
                  id="gene-disease-intro"
                  className="gene-disease-intro"
                >
                  <div className="first-section-container">
                    <span
                      role="presentation"
                      className="fa fa-info-circle icon first-info-icon"
                      onClick={() => {
                        this.setState({
                          showTitleHelperText: !this.state.showTitleHelperText,
                        });
                      }}
                    />
                    <div
                      className={
                        classnames(
                          'gene-disease-title-helper-text',
                          { 'gene-disease-title-helper-text--visible': this.state.showTitleHelperText }
                        )
                      }
                    >
                      <div className="simple-text">
                        The gene - disease overview assesses 10 key aspects that are relevant to assess how a gene of interest is associated with a specific disease.
                         The following aspects are assessed:
                      </div>
                      <div>
                        1.
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#gene-disease-expression', '.gene-disease-header'); }}
                        > Healthy expression
                        </span> of the gene of interest (RNA & protein) in disease associated tissues
                      </div>
                      <div className="simple-text">Interactions of the gene of interest with: </div>
                      <div>
                        2. Genes/proteins that
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#gene-disease-annotation', '.gene-disease-header'); }}
                        > have an annotation for involvement
                        </span> in the disease (i.e. the disease specific genetic interactome, see aspects 7 - 10)
                      </div>
                      <div>
                        3. Genes/proteins that are
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#differential-expression', '.gene-disease-header'); }}
                        > differentially expressed
                        </span> for the disease
                      </div>
                      <div>
                        4. Genes/proteins that have a
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#gene-mutation', '.gene-disease-header'); }}
                        > gene mutation annotation
                        </span> for the disease
                      </div>
                      <div>
                        5. Genes/proteins that are
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#animal-model', '.gene-disease-header'); }}
                        > genetically modified
                        </span> in an animal model for the disease
                      </div>
                      <div>
                        7.
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#phenotypes', '.gene-disease-header'); }}
                        > Phenotypes
                        </span> associated with the disease of interest
                      </div>
                      <div>
                        8.
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#pathways', '.gene-disease-header'); }}
                        > Pathways
                        </span> associated with the disease of interest
                      </div>
                      <div>
                        9.
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#cell-molecular', '.gene-disease-header'); }}
                        > Cell or molecular functions
                        </span> associated with the disease of interest
                      </div>
                      <div>
                        10.
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#organ-tissue', '.gene-disease-header'); }}
                        > Organ and tissue functions
                        </span> associated with the disease of interest
                      </div>
                      <div className="simple-text">
                        A summary
                        <span
                          role="presentation"
                          className="pointer underlined strongBold"
                          onClick={() => { manageableScrollInToView('#conclusion-section', '.gene-disease-header'); }}
                        > gene-disease association score
                        </span> is provided based on the level of association of the gene of interestwith each of these 10 aspects.
                      </div>
                    </div>
                  </div>
                  {
                    gene && disease &&
                    <GeneDiseaseIntro
                      gene={gene}
                      geneClassifications={geneClassifications}
                      geneSynonyms={geneSynonyms}
                      disease={disease}
                      diseaseClassifications={diseaseClassifications}
                      diseaseSynonyms={diseaseSynonyms}
                      predicate={predicate}
                    />
                  }
                </div>
                {
                  tissues && pageChapters[0].isLoaded &&
                  <GeneDiseaseExpressionSection
                    tissuesData={tissuesData}
                    geneId={geneId}
                    diseaseId={diseaseId}
                    diseaseName={diseaseName}
                    highlightedTissuesIds={highlightedTissuesIds}
                    gene={gene}
                    geneDiseaseName={`${geneName} in ${diseaseName}`}
                  />
                }
                {
                  tissues && pageChapters[1].isLoaded &&
                  <div id="gene-disease-annotation">
                    <GeneDiseaseAnnotationSection
                      geneId={geneId}
                      diseaseId={diseaseId}
                      diseaseName={diseaseName}
                      highlightedTissueIds={highlightedTissuesIds}
                      predicate={predicate}
                      gene={gene}
                      disease={disease}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  pageChapters[2].isLoaded &&
                  <div id="differential-expression">
                    <GeneDiseaseDifferentialExpressionSection
                      gene={gene}
                      disease={disease}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[3].isLoaded &&
                  <div id="gene-mutation">
                    <GeneDiseaseGeneMutationSection
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[4].isLoaded &&
                  <div id="animal-model">
                    <GeneDiseaseAnimalModelSection
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[6].isLoaded &&
                  <div id="phenotypes">
                    <GeneDiseasePhenotypeSection
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[7].isLoaded &&
                  <div id="pathways">
                    <GeneDiseasePathwaySection
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[8].isLoaded &&
                  <div id="cell-molecular">
                    <GeneDiseaseCellMolecularDysfunction
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[9].isLoaded &&
                  <div id="organ-tissue">
                    <GeneDiseaseOrganTissueFunction
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                      pageName={`Role of ${geneName} in ${diseaseName}`}
                    />
                  </div>
                }
                {
                  tissues && pageChapters[10].isLoaded &&
                  <div id="conclusion-section">
                    <GeneDiseaseConclusion
                      gene={gene}
                      disease={disease}
                      highlightedTissueIds={highlightedTissuesIds}
                      geneId={geneId}
                      diseaseId={diseaseId}
                      itsNewConcept={this.itsNewConcept}
                    />
                  </div>
                }
                <Loader isLoading={tissuesLoader} />
                {
                  !tissuesLoader && !tissues && isEmpty(tissuesData) &&
                  <div className="gene-disease-page__no-data row">
                    There is no tissues to display data.
                  </div>
                }
              </div>
            </div>
          </div>
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} error={error} />
      </div>
    );
  }
}

GeneDiseasePage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    gene: SELECTORS.getGene(state),
    geneClassifications: SELECTORS.getGeneClassifications(state),
    geneSynonyms: SELECTORS.getGeneSynonyms(state),
    disease: SELECTORS.getDisease(state),
    diseaseClassifications: SELECTORS.getDiseaseClassifications(state),
    diseaseSynonyms: SELECTORS.getDiseaseSynonyms(state),
    predicate: SELECTORS.getPredicate(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
    pageFlowMap: SELECTORS.getPageFlow(state),
    tissuesData: SELECTORS.getTissuesData(state),
    tissuesLoader: SELECTORS.getTissuesLoader(state),
    highlightedTissuesIds: SELECTORS.getHighlightedTissuesIdsByPubsCount(state),
    pageChapters: SELECTORS.getPageChapters(state),
    lastVisitedGeneId: SELECTORS.getLastVisitedGeneId(state),
    lastVisitedDiseaseId: SELECTORS.getLastVisitedDiseaseId(state),
    pageChaptersData: SELECTORS.getPageChaptersData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTissues(data) {
      dispatch(ACTIONS.geneDiseaseFetchTissuesRequesAction(data));
    },
    resetGeneDisease() {
      dispatch(ACTIONS.resetGeneDiseaseAction());
    },
    initPage(data) {
      dispatch(ACTIONS.initPageAction(data));
    },
    setLastVisitedConceptId(data) {
      dispatch(ACTIONS.geneDiseaseSetLastVisitedGenIdAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneDiseasePage));
