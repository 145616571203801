import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import PieChart from '../../graphics/PieChart/PieChart';
import BarChartDiagram from '../../graphics/BarChartDiagram/BarChartDiagram';
import Loader from '../../common/Loader/Loader';
// Store
import { getGeneDetailsAntibodiesAction } from './store/reducer';
import { loadNextChapterAction } from '../actions';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';
import { PURPLE_COLOR } from '../../../constantsCommon';

const propTypes = {
  gene: PropTypes.string,
  initAntibodiesData: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  geneName: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  itsNewConcept: PropTypes.bool,
  loadNextChapter: PropTypes.func,
};

const AntibodiesChapter = (props) => {
  const {
    gene,
    data,
    itsNewConcept,
    loadNextChapter,
    initAntibodiesData,
    geneName,
    data: {
      antibodyTypes,
      reactivityData,
      applicationsData,
      antibodyLink,
      antibodySum,
      antibodyPublications,
    },
    error,
    loading,
  } = props;

  const [showInfoText, setShowInfoText] = useState(false);

  useEffect(() => {
    if (itsNewConcept || data.length) {
      initAntibodiesData(gene);
    } else {
      loadNextChapter('external-links');
    }
  }, []);

  const toggleInfoText = () => {
    setShowInfoText(!showInfoText);
  };

  return (
    <div className="antibodies-chapter">
      <div className="gene-details-section-title title2">
        <span>Antibodies</span>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          onClick={toggleInfoText}
        />
      </div>
      {
        showInfoText &&
        <div className="gene-details-info__main">
          <p>
            This section provides information on available commercial antibodies in <a className="link" href="https://www.antibodypedia.com/" target="blank"> Antibodypedia </a>.
          </p>
          <p>
            <a className="link" href="https://www.antibodypedia.com/" target="blank"> Antibodypedia </a>is an open access, online antibody database tool that helps you select the right antibody for
            the right application. The resource comprises antibodies against human proteins and provides the research
            community with information on the performance of antibodies in specific applications based on experimental
            results and publications.
          </p>
        </div>
      }
      {
        antibodyLink && antibodyLink.length > 0 && antibodyPublications > 0 ?
          <div className="gene-expression-antibodies-title-sub">
            Antibodypedia has <a href={antibodyLink} target="_blank" rel="noreferrer">{antibodySum}</a> antibodies, of which <a href={antibodyLink} target="_blank" rel="noreferrer">{antibodyPublications}</a> have publications.
          </div> :
          <div className="gene-expression-antibodies-title-sub">
            Antibodypedia has <a href={antibodyLink} target="_blank" rel="noreferrer">{antibodySum || 0}</a> antibodies.
          </div>
      }
      {
        !loading &&
        <>
          <div className="antibodies-graphics">
            <span className="gene-details-per-section__title"> Antibodies types </span>
            {
              antibodyTypes && Object.keys(antibodyTypes).length > 0 ?
                <PieChart
                  id="antybody-types-id"
                  expressionName={geneName}
                  title="Antibodies types"
                  chartName="antybody_types"
                  data={antibodyTypes}
                  isHiddenExport={false}
                  geneName={geneName}
                  antibodySum={antibodySum}
                /> :
                <div className="therapeutic-candidates__no-data therapeutic-candidates__no-data_block">
                  {error || 'Data is temporarily unavailable'}
                </div>
            }
          </div>
          <div className="antibodies-graphics">
            <span className="gene-details-per-section__title"> Antibodies per reactivity</span>
            {
              reactivityData && reactivityData.length > 0 ?
                <div className="gene-expression-diagram">
                  <BarChartDiagram
                    source="antibodies-reactivity"
                    type="antibodies"
                    data={reactivityData}
                    dataKey="measure"
                    rotateXAxis={true}
                    color={{ positive: PURPLE_COLOR, negative: PURPLE_COLOR }}
                    geneName={geneName}
                    chartWidth={500}
                    chartHeight={430}
                    chartName="Number_of_antibodies_per_reactivity"
                    chartTitle="Antibodies per reactivity"
                  />
                </div> :
                <div className="therapeutic-candidates__no-data therapeutic-candidates__no-data_block">
                  {error || 'Data is temporarily unavailable'}
                </div>
            }
          </div>
          <div className="antibodies-graphics">
            <span className="gene-details-per-section__title"> Antibodies per application</span>
            {
              applicationsData && applicationsData.length > 0 ?
                <div className="gene-expression-diagram">
                  <BarChartDiagram
                    source="antibodies-application"
                    data={applicationsData}
                    type="antibodies"
                    dataKey="measure"
                    rotateXAxis={true}
                    color={{ positive: PURPLE_COLOR, negative: PURPLE_COLOR }}
                    geneName={geneName}
                    chartWidth={500}
                    chartHeight={430}
                    chartName="Number_of_antibodies_per_application"
                    chartTitle="Antibodies per application"
                  />
                </div> :
                <div className="therapeutic-candidates__no-data therapeutic-candidates__no-data_block">
                  {error || 'Data is temporarily unavailable'}
                </div>
            }
          </div>
        </>
      }
      <Loader isLoading={loading && !error} />
    </div>
  );
};

AntibodiesChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getGeneDetailsAntibodiesDataSelector(state),
    loading: SELECTORS.getGeneDetailsAntibodiesLoadingSelector(state),
    error: SELECTORS.getGeneDetailsAntibodiesErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initAntibodiesData(id) {
      dispatch(getGeneDetailsAntibodiesAction(id));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AntibodiesChapter);
