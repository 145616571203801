import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TissuesSelect from '../../common/TissuesSelect/TissuesSelect';
// Store
import * as ACTIONS from '../../common/SetAnalysisMethodSelection/actions';
import * as SELECTORS from '../../common/SetAnalysisMethodSelection/selectors';
import {
  getCurrentScoringFormula,
  getDefaultScoringFormula,
  getValidFormulaKey,
} from '../RankingMarkerSettings/selectors';
import { validateFormulaRequest } from '../RankingMarkerSettings/actions';
// Utils
import { getCaret, setCaretPosition } from '../../../Utils/Utils';
// Styles
import '../RankingMarkerSettings/RankingMarkerSettings.scss';

const propTypes = {
  selectTissueType: PropTypes.func,
  selectedSource: PropTypes.string,
  setScoringFormula: PropTypes.func,
  setDefaultScoringFormula: PropTypes.func,
  currentScoringModel: PropTypes.string,
  validateFormulaRequest: PropTypes.func,
  setRanking: PropTypes.func,
  closeModal: PropTypes.func,
  selectPenaltyTissueName: PropTypes.func,
  tissuesItems: PropTypes.instanceOf(Array),
  selectedPenaltyTissues: PropTypes.instanceOf(Array),
  defaultScoringModel: PropTypes.string,
  validFormulaKey: PropTypes.bool,
  selectedTissue: PropTypes.instanceOf(Object),
};

class ExpressionSpecificitySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedOptions: false,
      caretPos: null,
      validated: false,
    };
  }

  showAdvancedOptions = () => {
    this.setState({ advancedOptions: !this.state.advancedOptions });
  };

  changeScoringFormula = (e) => {
    this.props.setScoringFormula(e.target.value);
    this.setState({ caretPos: getCaret(e.target) });
    this.setState({ validated: false });
  };

  setCaretPosition = (target) => {
    this.setState({ caretPos: getCaret(target) });
  };

  getDefaultScoringFormula = () => {
    this.props.setDefaultScoringFormula();
  };

  addPartToFormula = (e) => {
    if (e.target.nodeName !== 'BUTTON') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const $input = this.modelInput;
    const inputValue = this.props.currentScoringModel;
    const partValue = ` ${e.target.value} `;
    const pos = typeof (this.state.caretPos) === 'number' ? this.state.caretPos : inputValue.length;

    const newFormula = inputValue.substring(0, pos) + partValue + inputValue.substring(pos, inputValue.length);
    this.props.setScoringFormula(newFormula);

    setCaretPosition($input, pos + partValue.length);
    this.setState({ validated: false });

    setTimeout(() => {
      this.setCaretPosition($input);
    }, 0);
  };

  validateFormula = () => {
    this.props.validateFormulaRequest();
    this.setState({ validated: true });
  };

  saveSettings = () => {
    this.props.setRanking({ expressionSpecificity: true });
    this.props.closeModal();
  };

  render() {
    const {
      tissuesItems,
      selectedSource,
      currentScoringModel,
      defaultScoringModel,
      validFormulaKey,
      selectTissueType,
      selectedTissue,
      selectPenaltyTissueName,
      selectedPenaltyTissues,
    } = this.props;

    return (
      <div className="ranking-settings-modal">
        <div className="flex-grid">
          <div className="col-1 text-center">
            <h3 className="title3">Expression Specificity Settings</h3>
          </div>
        </div>
        <div className="flex-grid">
          <p className="ranking-settings-modal__text">
            Select the tissue for which you want to calculate the expression specificity. You can select normal tissues
            from GTEx, TCGA and Beat-AML databases. The expression specificity is calculated between the selected tissue
            and all other tissues from the databases.
          </p>
        </div>
        <div className="ranking-settings-modal__tissues">
          <div className="label">
            Tissue:
            <span className="mandatory-span"><sup>*</sup></span>
          </div>
          <TissuesSelect
            onSelect={selectTissueType}
            options={tissuesItems}
            placeholder="Select tissue"
            disabled={false}
            dropDownHeight={120}
            value={selectedTissue}
          />
        </div>
        {
          selectedSource &&
          <div className="flex-grid">
            <div className="col-1 text-right m-10">
              <span
                role="presentation"
                className="link"
                onClick={this.showAdvancedOptions}
              >
                { this.state.advancedOptions ? 'Hide advanced options' : 'Show advanced options' }
              </span>
            </div>
          </div>
        }
        {
          selectedSource && this.state.advancedOptions &&
          <div className="penalty-tissues">
            <div className="label">Penalty tissues (optional):</div>
            <TissuesSelect
              onSelect={selectPenaltyTissueName}
              options={tissuesItems.filter(t => t.value !== selectedTissue.value)}
              placeholder="Select tissue"
              disabled={false}
              dropDownHeight={120}
              isMulti={true}
              value={selectedPenaltyTissues}
            />
          </div>
        }
        {
          selectedSource && this.state.advancedOptions &&
          <div>
            <div className="flex-grid">
              <div className="col-1 text-left">
                <div className="label">Adjust scoring formula:</div>
              </div>
            </div>
            <div className="flex-grid">
              <div className="col-5 pl-5">
                <input
                  value={currentScoringModel}
                  ref={(ref) => { this.modelInput = ref; }}
                  onClick={(e) => { this.setCaretPosition(e.target); }}
                  onChange={(e) => { this.changeScoringFormula(e); }}
                  className="input"
                  type="text"
                />
              </div>
              <div className="col-1">
                <button
                  onClick={this.validateFormula}
                  className="btn btn-input-addon"
                >
                  Validate
                </button>
              </div>
              <div className="col-1 text-center">
                <span
                  role="presentation"
                  onClick={this.getDefaultScoringFormula}
                  className="link lh-35"
                >
                  Get default
                </span>
              </div>
            </div>
            {
              currentScoringModel !== defaultScoringModel &&
              <div className="flex-grid">
                <div className="col-1 text-center m-10">
                  { validFormulaKey && <span className="green">Formula is valid</span> }
                  { (!validFormulaKey && !this.state.validated) && <span className="gray">Validate formula, please</span> }
                  { (!validFormulaKey && this.state.validated) && <span className="red">Formula is invalid</span> }
                </div>
              </div>
            }
            <div className="flex-grid">
              <div
                role="presentation"
                className="col-1 text-left m-10"
                onClick={(e) => { this.addPartToFormula(e); }}
              >
                <button
                  title="Expression value in target tissue"
                  className="btn"
                  value="Et"
                >
                  Et
                </button>
                <button
                  title="10th (highest) percentile expression value in base tissues (all other tissues)"
                  className="btn"
                  value="E10th"
                >
                  E10th%
                </button>
                <button
                  title="Median expression value in base tissues"
                  className="btn"
                  value="Em"
                >
                  Em
                </button>
                <button
                  title="Median expression value in no-go tissues"
                  className="btn"
                  value="Enogo"
                >
                  E.nogo
                </button>
                <button
                  title="Median expression value in penalty tissues"
                  className="btn"
                  value="Epen"
                >
                  E.pen
                </button>
                <button
                  title="90th percentile expression value in base tissues (so lowest 10%) [not used in default algorithm, but available for custom adjustments]"
                  className="btn"
                  value="E90th"
                >
                  E90th%
                </button>
              </div>
            </div>
          </div>
        }
        <div className="flex-grid" style={{ paddingTop: '50px' }}>
          <div className="col-1 text-center row mt-20">
            <button
              disabled={
                !selectedTissue ||
                !selectedSource ||
                (currentScoringModel !== defaultScoringModel && !validFormulaKey)}
              className="button button-primary mr-15"
              onClick={this.saveSettings}
            >
              Continue
            </button>
            <button type="button" className="button" onClick={this.props.closeModal} >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ExpressionSpecificitySettings.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    tissuesItems: SELECTORS.getTissuesForMenu(state),
    currentScoringModel: getCurrentScoringFormula(state),
    defaultScoringModel: getDefaultScoringFormula(state),
    validFormulaKey: getValidFormulaKey(state),
    selectedSource: SELECTORS.getSelectedSource(state),
    selectedTissue: SELECTORS.getSelectedTissueOptionSelector(state),
    selectedPenaltyTissues: SELECTORS.getSelectedPenaltyTissuesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal() {
      dispatch(ACTIONS.closeRankingModal());
    },
    selectPenaltyTissueName(data) {
      dispatch(ACTIONS.selectPenaltyTissueName(data));
    },
    selectTissueType(data) {
      dispatch(ACTIONS.selectTissueType(data));
    },
    setRanking(data) {
      dispatch(ACTIONS.setRankingAction(data));
    },
    setScoringFormula(data) {
      dispatch(ACTIONS.setScoringFormula(data));
    },
    setDefaultScoringFormula() {
      dispatch(ACTIONS.setDefaultScoringFormula());
    },
    validateFormulaRequest() {
      dispatch(validateFormulaRequest());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionSpecificitySettings);
