import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import { setSourceDetailsFilterOptionsAction, throwCreateSetErrorAction } from '../../actions';
import { getAvailableFiltersSelector } from '../../selectors';
import { getAccessMappingsSelector } from '../../../../../Header/selectors';
// Enums
import { publicationsFiltersNames } from '../../../enums';

export function* getSourceDetailsFilterOptionsWorker({ payload }) {
  try {
    const availableFilters = yield select(getAvailableFiltersSelector);
    const accessMappings = yield select(getAccessMappingsSelector);
    const filter = availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER];

    const { data } = yield call(Api.getSources, payload);

    const options = data.map(d => ({
      value: d,
      label: accessMappings[d],
    }));

    yield put(setSourceDetailsFilterOptionsAction({
      ...filter,
      isLoading: false,
      options,
    }));
  } catch (e) {
    yield put(throwCreateSetErrorAction(e.message));
  }
}
