import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
  startNewSearch: PropTypes.func,
};

const ShowingResultsForSection = (props) => {
  const { recommendations, startNewSearch } = props;

  return (
    <div>
      <div>
        Showing results for {recommendations.recommendationPhrase}
      </div>
      <div>
        <span
          className="search-page__link-block"
          onClick={() => startNewSearch({ plainValue: recommendations.recommendationPhrase })}
        >
          search with {` ${recommendations.originalInputPhrase} `} instead
        </span>
      </div>
    </div>
  );
};

ShowingResultsForSection.propTypes = propTypes;

export default React.memo(ShowingResultsForSection);
