import React from 'react';
import PropTypes from 'prop-types';
import MDSpinner from 'react-md-spinner';

// Constants
import { SPINNER_COLOR } from '../../../constantsCommon';

const propTypes = {
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  containerClassName: PropTypes.string,
};

const Spinner = (props) => {
  const { size, isLoading, containerClassName = '' } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <div className={containerClassName} data-testid="spinner-testid">
      <MDSpinner singleColor={SPINNER_COLOR} size={size} />
    </div>
  );
};

Spinner.propTypes = propTypes;

export default React.memo(Spinner);
