export const tissuesSource = {
  'GTEX': {
    label: 'GTEx',
  },
  'TCGA': {
    label: 'TCGA',
  },
  'BEAT_AML': {
    label: 'Beat AML',
  },
};
