import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Components
import Sets from '../../../../Sets/SetsList/Sets';
import FormInput from '../../../../common/FormElements/FormInput';
import FormSelect from '../../../../common/FormElements/FormSelect';
import FormRadio from '../../../../common/FormElements/FormRadio';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Utils
import { measureOptions, BISPECIFIC_ANTIBODY_FORM_NAME } from '../../../constants';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
// Store
import { getTargetCandidatesPairsDataAction } from '../../../store/reducer';
import { resetTargetCandidatesPairsDataAction } from '../store/reducer';
import { getFormValues } from '../store/selectors';

const propTypes = {
  name: PropTypes.string,
  relativeExpression: PropTypes.string,
  handleSubmit: PropTypes.func,
  getTargetCandidatesPairsData: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  reset: PropTypes.func,
  resetStore: PropTypes.func,
  change: PropTypes.func,
};

class TargetCandidatesPairsForm extends React.Component {
  state = {
    selectSetPopup: false,
  };

  openSelectSetPopup = () => {
    this.setState({ selectSetPopup: true });
  };

  closeSelectSetPopup = () => {
    this.setState({ selectSetPopup: false });
  };

  selectSetId = ({ projectId, selectedSets }) => {
    const { id, name } = selectedSets[0];
    const { change } = this.props;
    change('setId', id);
    change('setName', name);
    change('projectId', projectId);
  };

  fetchTargetCandidatesData = (data) => {
    const { getTargetCandidatesPairsData } = this.props;
    const {
      algorithm,
      measureType,
      setId,
      projectId,
      tissueLevel,
      tumorLevel,
      expressionType,
    } = data;
    let requestData = {
      algorithm,
      measureType,
      setId,
      tissueLevel,
      tumorLevel,
      expressionType,
    };
    const isProjectTab = checkProjectTab(projectId);
    requestData = isProjectTab ? { ...requestData, projectId } : requestData;

    getTargetCandidatesPairsData(requestData);
  };

  reset = () => {
    const {
      reset,
      resetStore,
    } = this.props;

    resetStore();
    reset();
  };

  handleSubmit = (prop) => {
    const { name } = this.props;
    const data = prop.toJS();

    if (data.expressionLevel === 'AbsoluteExpressionLevel') {
      data.expressionType = 'ABSOLUTE';
    } else if (data.expressionLevel === 'RelativeExpressionLevel') {
      data.expressionType = 'RELATIVE';
    }

    if (name === 'Hex Elect') {
      data.algorithm = 'HEX_ELECT';
    } else if (name === 'Bispecific Antibody') {
      data.algorithm = 'BISPECIFIC_ANTIBODY';
    }

    this.fetchTargetCandidatesData(data);
  };

  render() {
    const {
      name,
      relativeExpression,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    const { selectSetPopup } = this.state;

    const resetBtnClass = classNames(
      'button mr-15',
      { 'button--disabled': pristine || submitting }
    );

    const submitBtnClass = classNames(
      'button button-primary',
      { 'button-primary--disabled': !valid }
    );

    return (
      <div className="algorithm-form__wrap">
        <form
          className="algorithm-form"
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <div className="algorithm-form__info">
            <h3 className="workflows__title title3">{name} Algorithm</h3>
            <p className="workflows__text">
              Search for pairs of target candidates.
              The protein pairs are highly expressed in tumor tissue, but are not co-expressed on any healthy tissue.
            </p>
          </div>
          <div className="algorithm-form__block">
            <div className="algorithm-form__input">
              <Field
                name="setName"
                type="text"
                placeholder="Select saved set"
                component={FormInput}
                onClick={this.openSelectSetPopup}
                autoComplete="off"
                mandatory={true}
              />
            </div>
            <div className="algorithm-form__radio">
              <Field
                type="radio"
                name="expressionLevel"
                component={FormRadio}
                value="AbsoluteExpressionLevel"
                labelValue="Absolute Expression Level (TPM)"
              />
            </div>
            {
              relativeExpression === 'AbsoluteExpressionLevel' &&
              <div className="algorithm-form__input">
                <Field
                  name="tissueLevel"
                  type="number"
                  placeholder="Normal tissue level (transcripts per million)"
                  component={FormInput}
                  step="0.001"
                  additionalTitle="Healthy tissue"
                  mandatory={true}
                />
              </div>
            }
            {
              relativeExpression === 'RelativeExpressionLevel' &&
              <div className="algorithm-form__input">
                <Field
                  name="tissueLevel"
                  type="number"
                  placeholder="Normal tissue level (0 - 100%)"
                  mandatory={true}
                  component={FormInput}
                  step="0.001"
                  additionalTitle="Healthy tissue"
                />
              </div>
            }
            <div className="algorithm-form__buttons-wrap">
              <button
                className={resetBtnClass}
                type="button"
                disabled={pristine || submitting}
                onClick={this.reset}
              >
                Reset
              </button>
              <button
                className={submitBtnClass}
                type="submit"
                disabled={!valid}
              >
                Search
              </button>
            </div>
          </div>
          <div className="algorithm-form__block">
            <div className="algorithm-form__input">
              <Field
                name="measureType"
                component={FormSelect}
                mandatory={true}
                optionKey="type"
                options={measureOptions}
                placeholder="Select measure"
              />
            </div>
            <div className="algorithm-form__radio">
              <Field
                type="radio"
                name="expressionLevel"
                component={FormRadio}
                value="RelativeExpressionLevel"
                labelValue="Relative Expression Level (%)"
              />
            </div>
            {
              relativeExpression === 'AbsoluteExpressionLevel' &&
              <div className="algorithm-form__input">
                <Field
                  name="tumorLevel"
                  type="number"
                  placeholder="Tumor tissue level (transcripts per million)"
                  component={FormInput}
                  step="0.001"
                  additionalTitle="Tumor"
                  mandatory={true}
                />
              </div>
            }
            {
              relativeExpression === 'RelativeExpressionLevel' &&
              <div className="algorithm-form__input">
                <Field
                  name="tumorLevel"
                  type="number"
                  placeholder="Tumor tissue level (0 - 100%)"
                  component={FormInput}
                  step="0.001"
                  additionalTitle="Tumor"
                  mandatory={true}
                />
              </div>
            }
          </div>
        </form>
        {
          selectSetPopup &&
          <ModalComponent
            isOpen={selectSetPopup}
            closeCb={this.closeSelectSetPopup}
          >
            <Sets
              selectFunction={this.selectSetId}
              closeFunction={this.closeSelectSetPopup}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

TargetCandidatesPairsForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    relativeExpression: getFormValues(state, 'expressionLevel'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTargetCandidatesPairsData(data) {
      dispatch(getTargetCandidatesPairsDataAction(data));
    },
    resetStore() {
      dispatch(resetTargetCandidatesPairsDataAction());
    },
  };
}

function validate(values) {
  const errors = {};

  if (!values.get('setName')) {
    errors.setId = 'Required';
  }

  if (!values.get('setId')) {
    errors.setId = 'Required';
  }

  if (!values.get('measureType')) {
    errors.measureType = 'Required';
  }

  if (!values.get('expressionLevel')) {
    errors.expressionLevel = 'Required';
  }

  if (!values.get('tissueLevel')) {
    errors.tissueLevel = 'Required';
  }

  if (!values.get('tumorLevel')) {
    errors.tumorLevel = 'Required';
  }

  return errors;
}

const composition = compose(
  reduxForm({
    form: BISPECIFIC_ANTIBODY_FORM_NAME,
    destroyOnUnmount: false,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(TargetCandidatesPairsForm);
