import React from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from '../../../../../../../common/Loader/Loader';
import ViolinChart from '../../../../../../../graphics/ViolinChart/ViolinChart';
import NoData from '../../../../../../../common/NoData/NoData';

const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  geneName: PropTypes.string,
  chartId: PropTypes.string,
  chartTitle: PropTypes.string,
  chartName: PropTypes.string,
  noData: PropTypes.bool,
  includeZeros: PropTypes.bool,
};

const SingleCellViolinChart = (props) => {
  const {
    data = [],
    error,
    loading,
    geneName,
    chartId,
    chartTitle,
    chartName,
    noData,
    includeZeros,
  } = props;

  const dataForRender = !loading && !noData;

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <div>
      {
        dataForRender &&
        <ViolinChart
          error={error}
          loading={loading}
          type="SINGLE_CELL"
          chartData={data}
          showLegend={false}
          isNeededTransform={false}
          geneName={geneName}
          chartTitle={chartTitle}
          chartName={chartName}
          chartWrapId={chartId}
          customClassName="single-cell-study-violin-chart"
          yAxisText="Expression level"
          includeZeros={includeZeros}
          info={`
        Raw feature ${geneName} counts for each cell are divided by the total counts for that cell and 
        multiplied by the scale.factor (10.000). This is then natural-log transformed using log1p.
      `}
          settings={{
            width: data.length > 25 ? data.length * 25 : 510,
            height: 650,
            padding: {
              right: 170,
              left: 20,
            },
            margin: {
              top: 50,
              right: 40,
              bottom: 250,
              left: 30,
            },
          }}
        />
      }
      <NoData
        show={noData}
        customClassName="single-cell-study__no-results"
      />
    </div>
  );
};

SingleCellViolinChart.propTypes = propTypes;

export default React.memo(SingleCellViolinChart);
