function formatHeatmapInitialData(sets) {
  const initialData = {
    conceptIds: [],
    connectedConceptIds: [],
  };

  return sets.reduce((initial, set, i) => {
    const newInitial = { ...initial };
    if (i === 0) {
      set.items.forEach(concept => newInitial.conceptIds.push(concept.id));
    } else {
      set.items.forEach(concept => newInitial.connectedConceptIds.push(concept.id));
    }
    return newInitial;
  }, initialData);
}

export { formatHeatmapInitialData };
