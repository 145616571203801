import { SearchSourceFilterEnum } from '../../Search/enums';

export function createGraphData(pubsData) {
  if (pubsData.length === 0) return {};
  const minShowedPubsYear = 1995;

  const startMinX = Math.min(...pubsData.map(d => d.year));

  let yearsData = pubsData.map(d => d.year).sort((a, b) => a - b);
  const countsData = pubsData.map(d => d.count);

  let minX = 0;
  let maxX = 0;
  let minY = 0;
  let maxY = 0;

  if (yearsData.length) {
    minX = Math.min(...yearsData);
    maxX = Math.max(...yearsData);
  }

  if (countsData.length) {
    minY = Math.min(...countsData);
    maxY = Math.max(...countsData);
  }

  // eslint-disable-next-line no-empty,no-plusplus
  for (let i = minX; i <= maxX; i++) {
    if (!yearsData.includes(i)) {
      pubsData.push({ year: i, count: 0 });
    }
  }

  yearsData = pubsData.map(d => d.year).sort((a, b) => a - b);

  const tickXValues = startMinX < minShowedPubsYear ? [2000, maxX] : [minX, maxX];

  return {
    pubsData,
    tickXValues,
    yearsData,
    countsData,
    startMinX,
    y: { min: minY, max: maxY },
    x: { min: minX, max: maxX },
  };
}

export function getDataForGraphRequest(phrase, publicationSource) {
  const source = publicationSource || SearchSourceFilterEnum.ABSTRACT;
  return {
    source,
    phrase,
  };
}
