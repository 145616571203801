import { Map } from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Enums
import { EnvironmentsEnum } from './constantsCommon';
// Store
import rootSaga from './rootSaga';
import createMainReducer from './reducers';

export default function configureStore(initialState = new Map()) {
  const reducers = createMainReducer();

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
  ];

  const composeEnhancers =
    process.env.NODE_ENV !== EnvironmentsEnum.PROD &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'Cerebrum',
      }) : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
  );

  const store = createStore(
    reducers,
    initialState,
    enhancer
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
