import {
  CHANGE_PASSWORD_MODAL_OPEN,
  CHANGE_PASSWORD_MODAL_CLOSE,
  CHANGE_PASSWORD_REQUESTED,
} from './constants';

export const changePasswordModalOpen = () => (
  {
    type: CHANGE_PASSWORD_MODAL_OPEN,
    data: true,
  }
);

export const changePasswordModalClose = () => (
  {
    type: CHANGE_PASSWORD_MODAL_CLOSE,
    data: false,
  }
);

export const changePassword = () => (
  {
    type: CHANGE_PASSWORD_REQUESTED,
  }
);
