import { createSelector } from 'reselect';

import { sort } from '../../../Utils/Utils';
import { COPY_RELATION_MAP_PROJECT } from '../constants';

const relationMapProjects = state => state.getIn(['relationMapManagement', 'projects']);
const loading = state => state.getIn(['relationMapManagement', 'loading']);
const error = state => state.getIn(['relationMapManagement', 'error']);
const sorting = state => state.getIn(['relationMapManagement', 'sorting']);
const titleFilter = state => state.getIn(['relationMapManagement', 'filters', 'title']);
const tagFilter = state => state.getIn(['relationMapManagement', 'filters', 'tag']);
const conceptFilter = state => state.getIn(['relationMapManagement', 'filters', 'concept']);
const values = state => state.getIn(['form', COPY_RELATION_MAP_PROJECT, 'values']);

const projects = state => state.getIn(['projectsTabsReducer', 'projects', 'data']);
const activeProjectId = state => state.getIn(['relationMapManagement', 'activeProjectId']);

export const getRelationMapManagementProjectsSelector = createSelector(
  relationMapProjects,
  sorting,
  titleFilter,
  tagFilter,
  conceptFilter,
  (_projects, _sorting, _titleFilter, _tagFilter, _conceptFilter) => {
    if (!_projects) return _projects;

    const sorted = sort(_projects, _sorting.toJS());
    const sortedProjects = sorted && sorted.toJS();
    let filteredProjects = sortedProjects;

    if (_titleFilter) {
      const filterValueLow = _titleFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p => (
        p.name.toLowerCase().includes(filterValueLow)
      ));
    }

    if (_tagFilter) {
      const filterValueLow = _tagFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p => (
        p.tags.filter(t => t && t.toLowerCase().includes(filterValueLow)).length
      ));
    }

    if (_conceptFilter) {
      const filterValueLow = _conceptFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p => (
        p.concepts.filter(c => c.name.toLowerCase().includes(filterValueLow)).length
      ));
    }

    return filteredProjects;
  }
);

export const getRelationMapManagementLoadingSelector = createSelector(
  loading,
  data => data
);

export const getRelationMapManagementErrorSelector = createSelector(
  error,
  data => data
);

export const getRelationMapManagementSortingSelector = createSelector(
  sorting,
  data => data.toJS()
);

export const getRelationMapManagementFiltersSelector = createSelector(
  titleFilter,
  tagFilter,
  conceptFilter,
  (_titleFilter, _tagFilter, _conceptFilter) => ({
    titleFilter: _titleFilter,
    tagFilter: _tagFilter,
    conceptFilter: _conceptFilter,
  })
);

export const getFormValuesSelector = createSelector(
  values,
  data => data && data.toJS()
);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);

export const activeProjectDataSelector = createSelector(
  projects,
  activeProjectId,
  (_data, _activeProjectId) => {
    const data = _data && _data.toJS();
    return data.find(item => item.id === _activeProjectId);
  }
);
export const getIsProjectLockedSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED'
);

export const getIsProjectLockedForUsersSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED_FOR_USERS'
);

export const getIsProjectPublicSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.public
);
