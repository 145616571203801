import React from 'react';
import PropTypes from 'prop-types';
// Components
import SelectInput from '../../../../common/Inputs/SelectInput/SelectInput';

const propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  hide: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.instanceOf(Object),
};

const SearchWithSetsCooccurrenceSelect = (props) => {
  const {
    disabled,
    onSelect,
    hide,
    placeholder,
    options,
    defaultValue,
  } = props;

  return (
    <SelectInput
      bordered={true}
      customClassName="heatmap-select"
      placeholder={placeholder}
      defaultValue={defaultValue}
      hide={hide}
      options={options}
      isDisabled={disabled}
      onSelect={onSelect}
    />
  );
};

SearchWithSetsCooccurrenceSelect.propTypes = propTypes;

export default SearchWithSetsCooccurrenceSelect;
