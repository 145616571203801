import {
  createSelector,
} from 'reselect';
import {
  Map,
} from 'immutable';

import {
  SIMPLE_SET_FROM_COMPLEX_SET_FORM,
  EFFECT_SET_FROM_COMPLEX_SET_FORM,
} from './enums';

const pageNumber = state => state.getIn(['complexSetView', 'data', 'number']);
const totalPages = state => state.getIn(['complexSetView', 'data', 'totalPages']);
const totalElements = state => state.getIn(['complexSetView', 'data', 'totalElements']);
const content = state => state.getIn(['complexSetView', 'data', 'content']);
const name = state => state.getIn(['complexSetView', 'data', 'name']);
const version = state => state.getIn(['complexSetView', 'data', 'version']);
const description = state => state.getIn(['complexSetView', 'data', 'description']);
const complexSetTags = state => state.getIn(['complexSetView', 'data', 'tags']);
const loading = state => state.getIn(['complexSetView', 'loading']);
const error = state => state.getIn(['complexSetView', 'error']);
const showComplexToSimpleDialog = state => state.getIn(['complexSetView', 'showSimpleSetCreationDialog']);
const showComplexToEffectDialog = state => state.getIn(['complexSetView', 'showEffectSetCreationDialog']);
const analyzingFlag = state => state.getIn(['complexSetView', 'analyzing']);
const formField = state => state.getIn(['form']);
const showFullSimpleSetCreationFormFlag = state => state.getIn(['complexSetView', 'showSimpleSetCreationFormAfterAnalyzing']);
const tags = state => state.getIn(['complexSetView', 'tags']).toJS();
const allItems = state => state.getIn(['complexSetView', 'allItems']);
const configuration = state => state.getIn(['complexSetView', 'data', 'columnsConfiguration']);
const aggregationRules = state => state.getIn(['complexSetView', 'aggregationRules']);
const commonFailureText = state => state.getIn(['complexSetView', 'commonFailureText']);
const ambigiousSorting = state => state.getIn(['complexSetView', 'ambigiousSorting']);
const saveSetError = state => state.getIn(['complexSetView', 'errorOnSave']);
const saveSetErrorFreeUser = state => state.getIn(['complexSetView', 'errorOnSaveFreeUser']);
const setSavedSuccessfully = state => state.getIn(['complexSetView', 'setSavedSuccessfully']);

export const getCommonFailureTextValue = createSelector(
  commonFailureText,
  value => value
);

export const getContentForSettingsModal = createSelector(
  content,
  configuration,
  name,
  description,
  (contentData, config) => ({
    content: contentData ? contentData.toJS() : [],
    name,
    description,
    config: {
      columnsConfiguration: config ? config.toJS() : [],
    },
  })
);

export const getAggregationRules = createSelector(
  aggregationRules,
  rules => rules.toJS()
);

export const getColumnsConfiguration = createSelector(
  configuration,
  config => config ? config.toJS() : [] // eslint-disable-line no-confusing-arrow
);

export const effectSetItemsFormatted = createSelector(
  allItems,
  (items) => {
    const formatted = items
      .filter(item => (
        item.get('mappedGenes').size
      ))
      .map((item) => {
        const mappedGenes = item.get('mappedGenes').get(0);
        return new Map({
          name: mappedGenes.get('brainName'),
          id: mappedGenes.get('id'),
          measure: item.get('measure'),
        });
      });
    return formatted.toJS();
  }
);

export const setItemsFormatted = createSelector(
  allItems,
  (items) => {
    const formatted = items
      .filter(item => (
        item.get('mappedGenes').size
      ))
      .map((item) => {
        const mappedGenes = item.get('mappedGenes').get(0);
        return new Map({
          name: mappedGenes.get('brainName'),
          id: mappedGenes.get('id'),
        });
      });
    return formatted.toJS();
  }
);

export const tagListFormatted = createSelector(
  tags,
  data => data.map(tag => tag.name)
);

export const getItemsForAmbigiousTable = createSelector(
  allItems,
  (items) => {
    const result = items.toJS().filter(item => (
      item.mappedGenes.length > 1
    ));
    return result;
  }
);

export const getUnresolvedItems = createSelector(
  allItems,
  _items => _items.toJS().filter(item => item.mappedGenes.length === 0)
);

export const getAmbigiousSortingSelector = createSelector(
  ambigiousSorting,
  sorting => sorting,
);

export const getTags = createSelector(
  tags,
  setTags => setTags,
);

export const getShowFullSimpleSetCreationFormFlag = createSelector(
  showFullSimpleSetCreationFormFlag,
  flag => flag
);

export const getAnalyzingFlag = createSelector(
  analyzingFlag,
  flag => flag
);

export const getShowComplexToSimpleDialogVisibilityFlag = createSelector(
  showComplexToSimpleDialog,
  flag => flag
);

export const getShowComplexToEffectDialogVisibilityFlag = createSelector(
  showComplexToEffectDialog,
  flag => flag
);

export const getLoadingFlag = createSelector(
  loading,
  isLoading => isLoading,
);

export const getErrorFlag = createSelector(
  error,
  errorFlag => errorFlag
);

export const getPageNumber = createSelector(
  pageNumber,
  number => number
);

export const getTotalPages = createSelector(
  totalPages,
  pages => pages
);

export const getTotalElements = createSelector(
  totalElements,
  elementsCount => elementsCount
);

export const getContent = createSelector(
  content,
  (contentItems) => {
    const contentItemsPlainArray = contentItems ? contentItems.toJS() : [];
    const result = contentItemsPlainArray.reduce((acc, item) => {
      const temp = item.reduce((tempAcc, tempItem) => (
        {
          ...tempAcc,
          [tempItem.columnName]: tempItem.value,
        }),
      {}
      );
      acc.push(temp);
      return acc;
    },
    []
    );

    return result;
  }
);

export const getColumnNamesForDropDown = createSelector(
  configuration,
  (configData) => {
    const numbersAndNames = configData ? configData.toJS() : [];
    const result = numbersAndNames.map(item => ({ value: item.colNumber, label: item.name }));
    return result;
  }
);

export const getColumnNames = createSelector(
  getContent,
  (_content) => {
    let columnNames = [];
    if (_content.length > 0) {
      columnNames = Object.keys(_content[0]);
    }
    return columnNames;
  }
);

const effectForm = createSelector(
  formField,
  field => field.get(EFFECT_SET_FROM_COMPLEX_SET_FORM)
);

export const getComplexSetToEffectSetFormValues = createSelector(
  effectForm,
  form => form ? form.get('values').toJS() : {} // eslint-disable-line no-confusing-arrow
);

const simpleForm = createSelector(
  formField,
  field => field.get(SIMPLE_SET_FROM_COMPLEX_SET_FORM)
);

export const getComplexSetToSimpleSetFormValues = createSelector(
  simpleForm,
  form => form ? form.get('values').toJS() : {} // eslint-disable-line no-confusing-arrow
);

export const getName = createSelector(
  name,
  setName => setName,
);

export const getVersion = createSelector(
  version,
  data => data,
);

export const getDescription = createSelector(
  description,
  setDescription => setDescription,
);

export const getComplexSetTags = createSelector(
  complexSetTags,
  setComplexSetTags => (setComplexSetTags ? setComplexSetTags.toJS() : []),
);

export const getErrorOnSave = createSelector(
  saveSetError,
  data => data
);

export const getErrorOnSaveFreeUser = createSelector(
  saveSetErrorFreeUser,
  data => data
);

export const getSetSavedSuccessfullySelector = createSelector(
  setSavedSuccessfully,
  data => data
);

