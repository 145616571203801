import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { PAGE_SIZE } from './constants';

export const getProteinLocationDataAction = createAction('GET_PROTEIN_LOCATION_DATA');
export const setProteinLocationDataAction = createAction('SET_PROTEIN_LOCATION_DATA');
export const sortProteinLocationDataAction = createAction('SORT_PROTEIN_LOCATION_DATA');
export const changeProteinLocationPageAction = createAction('CHANGE_PROTEIN_LOCATION_PAGE');
export const toggleProteinLocationLoaderAction = createAction('TOGGLE_PROTEIN_LOCATION_LOADER');
export const throwProteinLocationErrorAction = createAction('THROW_PROTEIN_LOCATION_ERROR');

const initialState = fromJS({
  data: {
    proteinLocations: [],
    imageTags: [],
  },
  sorting: {
    sortBy: 'count',
    sortDirection: 'DESC',
  },
  totalPages: 0,
  page: 0,
  error: null,
  loading: true,
});

const reducer = handleActions(
  {
    [setProteinLocationDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('page', () => 0)
        .update('totalPages', () => Math.ceil((payload.proteinLocations || []).length / PAGE_SIZE))
        .update('error', () => null),
    [sortProteinLocationDataAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [changeProteinLocationPageAction]: (state, { payload }) =>
      state.update('page', () => payload),
    [toggleProteinLocationLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwProteinLocationErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
  },
  initialState
);

export default reducer;
