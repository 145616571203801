import { createSelector } from 'reselect';

const c2cData = state => state.getIn(['c2cIndirectReducer', 'data']);
const c2cLoader = state => state.getIn(['c2cIndirectReducer', 'loading']);
const c2cError = state => state.getIn(['c2cIndirectReducer', 'error']);

export const getConceptToConceptDataSelector = createSelector(
  c2cData,
  data => data && data.toJS()
);

export const getConceptToConceptLoaderSelector = createSelector(
  c2cLoader,
  data => data
);

export const getConceptToConceptErrorSelector = createSelector(
  c2cError,
  data => data
);
