import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import Spinner from '../../common/Spinner/Spinner';
import FormInput from '../../common/FormElements/FormInput';
// Utils
import { checkGenmabAccount } from '../../Utils/Utils';
// Constants
import { LOGIN_FORM } from '../constants';
import { outerUrls } from '../../../constantsCommon';
import { CUSTOMER, ENV } from '../../../settings';

const propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  userFormData: PropTypes.instanceOf(Object),
  loginCallback: PropTypes.func,
};

const LoginForm = (props) => {
  const {
    submitting,
    handleSubmit,
    loginCallback,
    userFormData: { username },
  } = props;


  const checkGenmab = useCallback(() => {
    const isGenmabAccount = checkGenmabAccount(username);
    if (isGenmabAccount) {
      window.open(outerUrls.genmab, '_self');
    }
    return isGenmabAccount;
  }, [username]);

  const handleSubmitForm = useCallback(() => {
    const isGenmabAccount = checkGenmab();
    if (!isGenmabAccount) {
      loginCallback();
    }
  }, [loginCallback, checkGenmab]);

  if (CUSTOMER === 'bms' && ENV === 'prod') {
    window.open(outerUrls.bms, '_self');
  }

  return (
    <form className="form-login" onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="row">
        <Field
          name="username"
          placeholder="Email"
          component={FormInput}
          type="text"
          onBlur={checkGenmab}
          autoComplete="username"
        />
      </div>
      <div className="row">
        <Field
          name="password"
          placeholder="Password"
          component={FormInput}
          type="password"
          autoComplete="current-password"
        />
      </div>
      <div className="row">
        <button
          type="submit"
          className="button button-primary login__btn"
          disabled={submitting}
        >
          Login
        </button>
        <Spinner isLoading={submitting} />
      </div>
    </form>
  );
};

LoginForm.propTypes = propTypes;

function validateLoginForm(values) {
  const errors = {};

  if (!values.get('username') || values.get('username').trim() === '') {
    errors.username = 'Enter email';
  }
  if (!values.get('password') || values.get('password').trim() === '') {
    errors.password = 'Enter password';
  }

  return errors;
}

export default reduxForm({
  form: LOGIN_FORM,
  fields: ['username', 'password'],
  validate: validateLoginForm,
})(LoginForm);
