import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import ReactPaginate from 'react-paginate';
import { isAfter, startOfDay } from 'date-fns';
// Icons
import { MdDelete } from 'react-icons/md';
// Components
import ProjectsTabs from '../../../../../Projects/ProjectsTabs/ProjectsTabs';
import SimpleTable from '../../../../../common/SimpleTable/SimpleTable';
import NoData from '../../../../../common/NoData/NoData';
import Loader from '../../../../../common/Loader/Loader';
import Error from '../../../../../common/Error/Error';
import DeleteCellLineConfigurationModal from '../DeleteCellLineConfigurationModal/DeleteCellLineConfigurationModal';
import Button from '../../../../../common/Buttons/Button/Button';
// Utils
import { formatDate } from '../../../../../Utils/Utils';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';

const propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  cellLinesConfigurations: PropTypes.instanceOf(Object),
  projectId: PropTypes.string,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  getCellLinesConfiguration: PropTypes.func,
  filterValue: PropTypes.string,
  filterCellLinesConfigurations: PropTypes.func,
  changeCellLinesConfigurationsPage: PropTypes.func,
  resetCellLinesConfigurations: PropTypes.func,
  onSelectFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  deleteCellLinesConfiguration: PropTypes.func,
  deleteCellLinesConfigurationModalIsOpen: PropTypes.bool,
  toggleDeleteCellLinesConfigurationModalIsOpen: PropTypes.func,
};

const SelectCellLinesConfigurationModal = (props) => {
  const {
    loading,
    error,
    projectId,
    onSelectFunction,
    onCloseFunction,
    setActiveProjectId,
    clearActiveProjectId,
    cellLinesConfigurations: {
      data: cellLinesConfigurations,
      page: pageNumber,
      totalPages,
    },
    getCellLinesConfiguration,
    filterCellLinesConfigurations,
    changeCellLinesConfigurationsPage,
    resetCellLinesConfigurations,
    deleteCellLinesConfiguration,
    deleteCellLinesConfigurationModalIsOpen,
    toggleDeleteCellLinesConfigurationModalIsOpen,
  } = props;
  
  const simpleTableRef = useRef(null);
  const [cellLinesConfigurationToDelete, setCellLinesConfigurationToDelete] = useState('');

  useEffect(() => {
    getCellLinesConfiguration();
  }, [projectId]);

  useEffect(() => resetCellLinesConfigurations, []);

  useEffect(() => {
    if (simpleTableRef && simpleTableRef.current) {
      simpleTableRef.current.recomputeRowHeights();
    }
  }, [cellLinesConfigurations]);

  const getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  const getColumns = (width) => {
    return [
      {
        label: 'Name',
        dataKey: 'name',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: getColumnPercentWidth(width, 50),
      },
      {
        label: 'Saved',
        className: 'table-wrap__cell',
        dataKey: 'updated',
        width: getColumnPercentWidth(width, 25),
        cellRenderer: ({ rowData }) => {
          const lastSaved = isAfter(startOfDay(new Date()), new Date(rowData.updated)) ?
            formatDate(rowData.updated) :
            formatDate(rowData.updated, 'HH:mm');
          return (
            <span>{lastSaved}</span>
          );
        },
      },
      {
        label: 'Delete',
        className: 'table-wrap__cell',
        dataKey: 'id',
        width: getColumnPercentWidth(width, 25),
        cellRenderer: ({ rowData }) => {
          return (
            <div
              role="presentation"
              onClick={() => { handleOpenDeleteCellLinesConfiguration(rowData); }}
              className="cell-line-configuration-table__icon"
            >
              <MdDelete
                color="#9a96a2"
                size={26} />
            </div>
          );
        },
      },
    ];
  };

  const handleFilterCellLinesConfigurations = (e) => {
    filterCellLinesConfigurations(e.target.value);
  };

  const handlePageClick = (page) => {
    changeCellLinesConfigurationsPage(page.selected);
  };

  const handleOpenDeleteCellLinesConfiguration = (item) => {
    toggleDeleteCellLinesConfigurationModalIsOpen(true);
    setCellLinesConfigurationToDelete(item);
  };

  const handleCloseDeleteCellLinesConfiguration = () => {
    toggleDeleteCellLinesConfigurationModalIsOpen(false);
    setCellLinesConfigurationToDelete('');
  };

  const handleDeleteCellLinesConfiguration = () => {
    deleteCellLinesConfiguration({ id: cellLinesConfigurationToDelete.id });
  };

  const handleSelectCellLinesConfiguration = () => {
    const selectedSet = cellLinesConfigurations.find(c => c.selected);
    onSelectFunction(selectedSet.id);
    onCloseFunction();
  };

  const tableSettings = {
    width: 900,
    headerHeight: 50,
    rowHeight: 50,
  };

  return (
    <div className="sets-page">
      <div className="sets-page__title title2">Ranking profile</div>
      <ProjectsTabs
        activeProjectId={projectId}
        setActiveProjectId={setActiveProjectId}
        clearActiveProjectId={clearActiveProjectId}
      />
      <div className="sets-page__content">
        {
          !loading && !error && cellLinesConfigurations &&
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              width ?
                <div className="table-wrap" style={{ width }}>
                  <div
                    className="controls-block table-wrap__controls table-wrap__controls_filter"
                    style={{ width }}
                  >
                    <div className="table-filter">
                      <input
                        name="Filter ranking profiles"
                        placeholder="Filter ranking profiles"
                        className="table-filter__input"
                        type="text"
                        onChange={(e) => handleFilterCellLinesConfigurations(e)}
                      />
                    </div>
                    {
                      totalPages > 1 &&
                      <div className="table-wrap__pagination">
                        <ReactPaginate
                          previousLabel="previous"
                          nextLabel="next"
                          breakClassName="break-me"
                          pageCount={totalPages}
                          forcePage={pageNumber}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          subContainerClassName="pages pagination"
                          activeClassName="active"
                        />
                      </div>
                    }
                  </div>
                  {
                    cellLinesConfigurations.length > 0 &&
                    <SimpleTable
                      innerRef={simpleTableRef}
                      data={cellLinesConfigurations}
                      autoSize={true}
                      width={width}
                      columns={getColumns(width)}
                      settings={{
                        ...tableSettings,
                      }}
                      dynamicHeight={true}
                      singleSelect={true}
                      onlySelect={true}
                      actions={ { checkOneItemAction: ACTIONS.checkCellLinesConfigurationAction } }
                    />
                  }
                  <NoData
                    show={cellLinesConfigurations.length === 0}
                    customClassName="algorithm-table__error"
                  />
                </div> : null
            )}
          </AutoSizer>
        }
        <Error show={!!error} error={error} />
        <Loader isLoading={loading} />
      </div>
      {
        !loading && !error && cellLinesConfigurations &&
        <div className="sets-page__footer">
          <Button
            text="Cancel"
            customClassName="button mr-15"
            onClick={onCloseFunction}
          />
          <Button
            text="Select"
            customClassName="button button-primary"
            onClick={handleSelectCellLinesConfiguration}
          />
        </div>
      }

      <DeleteCellLineConfigurationModal
        isOpen={deleteCellLinesConfigurationModalIsOpen}
        onConfirm={handleDeleteCellLinesConfiguration}
        closeCb={handleCloseDeleteCellLinesConfiguration}
        cellLinesConfigurationToDelete={cellLinesConfigurationToDelete}
      />
    </div>
  );
};

SelectCellLinesConfigurationModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: SELECTORS.getCellLinesConfigurationsLoadingSelector(state),
    error: SELECTORS.getCellLinesConfigurationsErrorSelector(state),
    cellLinesConfigurations: SELECTORS.getCellLinesConfigurationsSelector(state),
    filterValue: SELECTORS.getCellLinesConfigurationsFilterSelector(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
    deleteCellLinesConfigurationModalIsOpen: SELECTORS.getDeleteCellLinesConfigurationModalIsOpenSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCellLinesConfiguration() {
      dispatch(ACTIONS.getCellLinesConfigurationsAction());
    },
    changeCellLinesConfigurationsPage(data) {
      dispatch(ACTIONS.changeCellLinesConfigurationsPageAction(data));
    },
    filterCellLinesConfigurations(data) {
      dispatch(ACTIONS.filterCellLinesConfigurationsAction(data));
    },
    resetCellLinesConfigurations(data) {
      dispatch(ACTIONS.resetCellLinesConfigurationsAction(data));
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setProjectIdForCellLinesConfigurationModalAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearProjectIdForCellLinesConfigurationModalAction());
    },
    toggleDeleteCellLinesConfigurationModalIsOpen(data) {
      dispatch(ACTIONS.toggleDeleteCellLinesConfigurationModalIsOpenAction(data));
    },
    deleteCellLinesConfiguration(data) {
      dispatch(ACTIONS.deleteCellLinesConfigurationAction(data));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCellLinesConfigurationModal);
