import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Constants
import { CooccurrenceEnum } from '../../../../constantsCommon';
// Utils
import { getTotalPages } from '../../../common/Pagination/utils';
// Actions
import * as a from './actions';

const initialState = fromJS({
  data: null,
  sort: {
    sortBy: 'count',
    sortDirection: 'DESC',
  },
  pages: {
    totalPages: 0,
    pageNumber: 0,
  },
  cooccurrenceType: CooccurrenceEnum.ABSTRACT,
  loading: null,
  error: null,
});

const reducer = handleActions(
  {
    [a.getSearchWithSetsDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [a.putSearchWithSetsDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null)
        .updateIn(['pages', 'pageNumber'], () => 0)
        .updateIn(['pages', 'totalPages'], () => getTotalPages(payload)),
    [a.throwSearchWithSetsErrorAction]: (state, { payload }) =>
      state
        .update('data', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [a.sortSearchWithSetsDataAction]: (state, { payload }) =>
      state
        .update('sort', () => fromJS(payload))
        .updateIn(['pages', 'pageNumber'], () => 0),
    [a.changeSearchWithSetsPageAction]: (state, { payload }) =>
      state
        .updateIn(['pages', 'pageNumber'], () => payload),
    [a.updateSearchWithSetsCooccurrenceTypeAction]: (state, { payload }) =>
      state
        .update('cooccurrenceType', () => payload.value),
    [a.resetSearchWithSetsPageAction]: () => initialState,
  },
  initialState
);

export default reducer;
