import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import SearchPageMain from './Components/SearchPageMain/SearchPageMain';
import SearchPageStart from './Components/SearchPageStart/SearchPageStart';
import ConceptSearchModal from '../Concept/ConceptSearchModal/ConceptSearchModal';
// Store
import { getConceptCardsDataAction, clearConceptCardsDataAction } from '../Concept/ConceptCards/store/reducer';
import {
  setSearchConceptInputValueAction,
  resetSearchConceptAction,
} from '../Concept/ConceptSearchModal/store/actions';
import { getSemanticCategoriesTabsDataAction, clearSemanticCategoriesTabsDataAction } from './Components/SearchCategoryTab/store/reducer';
import * as ACTIONS from './store/actions';
import * as SELECTORS from './store/selectors';
// Utils
import { getDataForSearchRequest, getSearchLinkParams, buildSearchLink } from './utils';
// Styles
import './index.scss';
import {PortalActions} from './enums';
import {navigation} from '../../navigation/constants';
import {RELATIVE_PATH} from '../../constantsCommon';
import SearchPageStartPortal from './Components/SearchPageStartPortal/SearchPageStartPortal';
import EnrichmentAnalysisParamsModal from '../FindRelated/Components/EnrichmentAnalysisParams/EnrichmentAnalysisParamsModal';
import RankSelectionParamsModal from '../RankSelection/Components/RankSelectionsParams/RankSelectionParamsModal';

const propTypes = {
  searchValues: PropTypes.instanceOf(Object),
  searchConcepts: PropTypes.instanceOf(Array),
  savedLink: PropTypes.instanceOf(Object),
  getSearchResults: PropTypes.func,
  loading: PropTypes.bool,
  saveExactSearchTerm: PropTypes.func,
  totalPages: PropTypes.number,
  savedSearchTerm: PropTypes.string,
  getConceptCardsData: PropTypes.func,
  clearConceptCards: PropTypes.func,
  clearSemanticCategories: PropTypes.func,
  getSemanticCategoriesTabsData: PropTypes.func,
  resetSearchResults: PropTypes.func,
  resetSearchConcepts: PropTypes.func,
  resetRelatedConcepts: PropTypes.func,
  resetConceptSearchModal: PropTypes.func,
  setSearchFilterInitialValues: PropTypes.func,
  resetCachedConceptIdsForCategory: PropTypes.func,
  clearSelectedPublicationsData: PropTypes.func,
  reloadingIsDisabled: PropTypes.bool,
  disableReloading: PropTypes.func,
  publicationFilterExtended: PropTypes.bool,
  setPublicationFilterExtended: PropTypes.func,
  updateSavedLink: PropTypes.func,
};

const Search = (props) => {
  const {
    savedLink,
    totalPages,
    loading,
    searchValues,
    searchConcepts,
    savedSearchTerm,
    getSearchResults,
    resetSearchResults,
    saveExactSearchTerm,
    getConceptCardsData,
    clearConceptCards,
    resetSearchConcepts,
    resetRelatedConcepts,
    resetConceptSearchModal,
    clearSemanticCategories,
    setSearchFilterInitialValues,
    getSemanticCategoriesTabsData,
    resetCachedConceptIdsForCategory,
    clearSelectedPublicationsData,
    disableReloading,
    reloadingIsDisabled,
    publicationFilterExtended,
    setPublicationFilterExtended,
    updateSavedLink,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [showSearchConceptModal, setShowSearchConceptModal] = useState({ show: false, isNewSearch: true });
  const [showEnrichmentAnalysisParamsModal, setShowEnrichmentAnalysisParamsModal] = useState(false);
  const [showRankParamsModal, setShowRankParamsModal] = useState(false);
  const [getPortalAction, setPortalAction] = useState({ portalAction: null });

  function startSearch(search) {
    const searchParams = getSearchLinkParams(search);
    if (!searchParams.plainValue) return;

    const requestData = getDataForSearchRequest(searchParams);

    setSearchFilterInitialValues({
      ...requestData.post,
      sort: requestData.params.sort,
    });

    if (searchParams.exactValue) {
      saveExactSearchTerm(searchParams.exactValue);
    }

    getSearchResults({ requestData, isNewSearch: showSearchConceptModal.isNewSearch });
    getConceptCardsData(requestData);
    getSemanticCategoriesTabsData(requestData);
    setPublicationFilterExtended(false);
  }

  function onConceptSearchSubmit(concept) {
    if (getPortalAction === PortalActions.MARKERS_IDENTIFY_DISEASE) {
      navigate({
        pathname: `${RELATIVE_PATH}/${navigation.createSetPage}`,
        search: `action=${PortalActions.MARKERS_IDENTIFY_DISEASE}&startConceptId=${concept.id}&startConceptName=${concept.name}`
      });
    } else if (getPortalAction === PortalActions.TARGET_MARKER_ASSESSMENT) {
      navigate({ pathname: `${RELATIVE_PATH}/gene-details/${concept.id}`});
    } else if (getPortalAction === PortalActions.ANTIBODY_FILTER) {
      const id = concept.id || '';
      const name = concept.name || '';
      navigate({
        pathname: `${RELATIVE_PATH}/antibodies`,
        search: `id=${id}&name=${name}`,
      });
    } else if (showSearchConceptModal.isNewSearch) {
      navigate(buildSearchLink({
        plainValue: concept.name,
        exactValue: concept.id,
      }));
    } else {
      const selectedConcepts = searchConcepts.filter(c => c.id !== concept.id);
      selectedConcepts.push(concept);
      navigate(buildSearchLink({
        plainValue: selectedConcepts.reduce((v, c) => (v ? `${v} AND ${c.name}` : `${c.name}`), ''),
        exactValue: selectedConcepts.reduce((v, c) => (v ? `${v} AND ${c.id}` : `${c.id}`), ''),
        ...searchValues,
      }));
    }
  }

  function resetSearchPage() {
    resetSearchResults();
    clearConceptCards();
    resetSearchConcepts();
    resetRelatedConcepts();
    clearSemanticCategories();
    resetCachedConceptIdsForCategory();
    clearSelectedPublicationsData();
    resetConceptSearchModal();
  }

  function toggleSearch(isNewSearch = true) {
    setShowSearchConceptModal(({ show }) => ({
      show: !show,
      isNewSearch,
    }));
  }

  function toggleParametrizedSearch() {
    setShowEnrichmentAnalysisParamsModal(!showEnrichmentAnalysisParamsModal);
  }

  function toggleRankParamsDialog() {
    setShowRankParamsModal(!showRankParamsModal);
  }

  useEffect(() => {
    if (reloadingIsDisabled) return;
    if (location.search) {
      startSearch(location.search);
      setShowSearchConceptModal({
        ...showSearchConceptModal,
        isNewSearch: false,
      });
      updateSavedLink(location);
    } else if (savedLink && location.pathname !== '/portal') {
      navigate(savedLink);
    } else if (location.pathname !== '/portal') {
      resetSearchPage();
    }
  }, [location]);

  useEffect(() => () => disableReloading(false));

  return (
    <div className="search-page">
      {
        searchConcepts.length === 0 && location.pathname === '/research' &&
        <SearchPageStart
          onSubmitCallback={toggleSearch}
          searchValue={savedSearchTerm}
          loading={loading}
          setPortalAction={setPortalAction}
        />
      }
      {
        location.pathname === '/portal' &&
        <SearchPageStartPortal
          onSubmitCallback={toggleSearch}
          searchParamsCallback={toggleParametrizedSearch}
          openRankParamsDialog={toggleRankParamsDialog}
          searchValue={savedSearchTerm}
          loading={loading}
          setPortalAction={setPortalAction}
        />
      }
      {
        location.pathname !== '/portal' && searchConcepts.length > 0 &&
        <SearchPageMain
          searchValues={searchValues}
          toggleSearch={toggleSearch}
          resetSearchPage={resetSearchPage}
          loading={loading}
          totalPages={totalPages}
          publicationFilterExtended={publicationFilterExtended}
          setPublicationFilterExtended={setPublicationFilterExtended}
        />
      }
      <ConceptSearchModal
        isOpen={showSearchConceptModal.show}
        closeCb={toggleSearch}
        onSubmit={onConceptSearchSubmit}
        onSubmitBtnText="Search by concepts"
        action={getPortalAction}
      />
      <EnrichmentAnalysisParamsModal
        isOpen={showEnrichmentAnalysisParamsModal}
        closeCb={toggleParametrizedSearch}
      />
      <RankSelectionParamsModal
        isOpen={showRankParamsModal}
        closeCb={toggleRankParamsDialog}
      />
    </div>
  );
};

Search.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    savedLink: SELECTORS.getSearchSevedLinkSelector(state),
    savedSearchTerm: SELECTORS.getSavedSearchTerm(state),
    searchValues: SELECTORS.getSearchFilterValuesSelector(state),
    searchConcepts: SELECTORS.getSearchConceptsSelector(state),
    loading: SELECTORS.getSearchLoading(state),
    totalPages: SELECTORS.getTotalPages(state),
    reloadingIsDisabled: SELECTORS.getReloadingIsDisabledSelector(state),
    publicationFilterExtended: SELECTORS.getPublicationFilterExtendedSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSearchResults(data) {
      dispatch(ACTIONS.getSearchDataAction(data));
    },
    resetSearchResults() {
      dispatch(ACTIONS.resetSearchResultsAction());
    },
    saveExactSearchTerm(data) {
      dispatch(ACTIONS.saveExactSearchTermAction(data));
    },
    resetRelatedConcepts() {
      dispatch(ACTIONS.resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(ACTIONS.resetCachedConceptIdsForCategoryAction());
    },
    setSearchFilterInitialValues(data) {
      dispatch(ACTIONS.setSearchFilterInitialValuesAction(data));
    },
    clearSelectedPublicationsData() {
      dispatch(ACTIONS.clearSelectedPublicationsDataAction());
    },
    disableReloading(data) {
      dispatch(ACTIONS.disableReloadingAction(data));
    },
    resetSearchConcepts() {
      dispatch(ACTIONS.resetSearchConceptsAction());
    },
    setConceptSearchModalInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
    resetConceptSearchModal() {
      dispatch(resetSearchConceptAction());
    },
    getConceptCardsData(data) {
      dispatch(getConceptCardsDataAction(data));
    },
    clearConceptCards() {
      dispatch(clearConceptCardsDataAction());
    },
    getSemanticCategoriesTabsData(data) {
      dispatch(getSemanticCategoriesTabsDataAction(data));
    },
    clearSemanticCategories() {
      dispatch(clearSemanticCategoriesTabsDataAction());
    },
    setPublicationFilterExtended(data) {
      dispatch(ACTIONS.setPublicationFilterExtendedAction(data));
    },
    updateSavedLink(data) {
      dispatch(ACTIONS.updateSearchSavedLinkAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
