export const RANKING_REQUESTED = 'RANKING_REQUESTED';
export const RANKING_SUCCEEDED = 'RANKING_SUCCEEDED';
export const RANKING_FAILED = 'RANKING_FAILED';
export const RANKING_RESULT_TABLE = 'RANKING_RESULT_TABLE_';
export const RANKING_RESULT_TABLE_RESET = 'RANKING_RESULT_TABLE_RESET';
export const REMOVE_CONCEPT = 'REMOVE_CONCEPT';
export const LOADING = 'LOADING';
export const EFFECT_OPERATION = 'EFFECT_OPERATION';
export const EFFECT_COLUMNS = 'EFFECT_COLUMNS';

export const effectOperation = {
  Sum: 'Sum',
  Min: 'Min',
  Max: 'Max',
  Average: 'Average',
  Median: 'Median',
};
