import { createSelector } from 'reselect';

// Utils
import { setTypesEnum, SAVE_AS_SET_FORM } from '../constants';
import { sort } from '../../../Utils/Utils';
import { getCurrentPageData } from '../../../common/Pagination/utils';

const concepts = state => state.getIn(['saveAsSetReducer', 'concepts']);
const loading = state => state.getIn(['saveAsSetReducer', 'loading']);
const tags = state => state.getIn(['saveAsSetReducer', 'tags']);
const sorting = state => state.getIn(['saveAsSetReducer', 'sorting']);
const setType = state => state.getIn(['saveAsSetReducer', 'setType']);
const formValues = state => state.getIn(['form', SAVE_AS_SET_FORM, 'values']);
const complexSetContent = state => state.getIn(['saveAsSetReducer', 'complexSetContent']);
const complexSetDataToSend = state => state.getIn(['saveAsSetReducer', 'complexSetDataToSend']);
const showComplexSetDialog = state => state.getIn(['saveAsSetReducer', 'showComplexSetDialog']);
const pages = state => state.getIn(['saveAsSetReducer', 'pages']);

export const getSaveAsSetConceptsSelector = createSelector(
  concepts,
  sorting,
  (data, sortingData) => {
    if (!data) return data;
    const sorted = sort(data, sortingData.toJS());
    return sorted.toJS();
  }
);

export const getSaveAsSetTagsSelector = createSelector(
  tags,
  data => data && data.toJS()
);

export const getSaveAsSetTagsForSaveSelector = createSelector(
  [getSaveAsSetTagsSelector],
  data => data.map(d => d.name)
);

export const getSaveAsSetSortingSelector = createSelector(
  sorting,
  data => data && data.toJS()
);

export const getSaveAsSetPagesSelector = createSelector(
  pages,
  data => data && data.toJS()
);

export const getSaveAsSetFormValuesSelector = createSelector(
  formValues,
  data => data && data.toJS()
);

export const getUnresolvedConceptsSelector = createSelector(
  [getSaveAsSetConceptsSelector],
  data => data ? data.filter(item => item.mappedGenes.length === 0) : []
);

export const getAmbiguousConceptsSelector = createSelector(
  [getSaveAsSetConceptsSelector],
  data => data ? data.filter(item => item.mappedGenes.length > 1) : []
);

export const getSaveAsSetConceptsForSaveSelector = createSelector(
  [getSaveAsSetConceptsSelector],
  data => data ? data.reduce((c, d) => {
    if (d.mappedGenes.length !== 0) {
      c.push({
        name: d.mappedGenes[0].brainName,
        id: d.mappedGenes[0].id,
        measure: d.mappedGenes[0].score || d.score,
        note: d.note || '',
        sourceTerm: d.geneName,
      });
    }
    return c;
  }, []) : []
);

export const getSaveAsSetTypeSelector = createSelector(
  setType,
  data => data
);

export const getSaveAsSetTypeIsSimpleOrEffectSelector = createSelector(
  [getSaveAsSetTypeSelector],
  data => data === setTypesEnum.SIMPLE || data === setTypesEnum.EFFECT
);

export const getSaveAsSetComplexDataSelector = createSelector(
  complexSetContent,
  data => data && data.toJS()
);

export const getSaveAsSetComplexDialogSelector = createSelector(
  showComplexSetDialog,
  data => data
);

export const getSaveAsSetComplexDataToSendSelector = createSelector(
  complexSetDataToSend,
  data => data && data.toJS()
);

export const getSaveAsSetComplexFileSelector = createSelector(
  [getSaveAsSetComplexDataSelector],
  (data) => {
    if (!data) return data;
    const { content: { content } } = data;

    const file = content.reduce((result, c) => {
      const f = { ...result };
      c.forEach((item) => {
        if (!f[item.columnName]) {
          f[item.columnName] = {
            columnName: item.columnName,
            values: [item.value],
          };
        } else {
          f[item.columnName].values.push(item.value);
        }
      });
      return f;
    }, {});

    return Object.values(file);
  }
);

export const getSaveAsSetDisabledSelector = createSelector(
  [
    getSaveAsSetConceptsSelector,
    getUnresolvedConceptsSelector,
    getAmbiguousConceptsSelector,
    getSaveAsSetComplexDataSelector,
  ],
  (conceptsData, unresolvedConcepts, ambiguousConcepts, complexData) => {
    if (complexData && complexData.content) {
      return false;
    }
    return !conceptsData?.length || !!ambiguousConcepts.length || !!unresolvedConcepts.length;
  }
);

export const getSaveAsSetLoadingSelector = createSelector(
  loading,
  data => data
);

export const getSaveAsSetPagginatedConceptsSelector = createSelector(
  [
    getSaveAsSetConceptsSelector,
    getSaveAsSetPagesSelector,
  ],
  (data, pagesData) => {
    if (!data) return [];
    const { pageNumber } = pagesData;
    return getCurrentPageData(data, pageNumber);
  }
);
