import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import Api from '../../../Api/Api';

import {
  USER_ACTIVATION_REQUESTED,
  USER_ACTIVATION_SUCCEEDED,
  USER_ACTIVATION_FAILED,
} from './constants';


function* saga(action) {
  try {
    const data = {
      params: {
        code: action.data.activationCode,
        email: action.data.login,
      },
    };

    yield call(Api.activateUser, data);
    yield put({ type: USER_ACTIVATION_SUCCEEDED });
  } catch (e) {
    yield put({ type: USER_ACTIVATION_FAILED, message: e.message });
  }
}

function* activationSaga() {
  yield takeEvery(USER_ACTIVATION_REQUESTED, saga);
}

export default activationSaga;
