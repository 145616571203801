import { createSelector } from 'reselect';

const projects = state => state.getIn(['projectsTabsReducer', 'projects', 'data']);
const projectsError = state => state.getIn(['projectsTabsReducer', 'projects', 'error']);
const projectsLoading = state => state.getIn(['projectsTabsReducer', 'projects', 'loading']);
const projectsOptions = state => state.getIn(['projectsTabsReducer', 'projectsOptions', 'data']);
const projectsOptionsLoading = state => state.getIn(['projectsTabsReducer', 'projectsOptions', 'loading']);
const activeProjectId = state => state.getIn(['projectsTabsReducer', 'activeProjectId']);

export const getProjectsSelector = createSelector(
  projects,
  (_data) => {
    const data = _data && _data.toJS();
    return data.filter(item => item.status !== 'CLOSED');
  }
);

export const getProjectsLoadingSelector = createSelector(
  projectsLoading,
  data => data
);

export const getProjectsErrorSelector = createSelector(
  projectsError,
  data => data
);

export const activeProjectDataSelector = createSelector(
  projects,
  activeProjectId,
  (_data, _activeProjectId) => {
    const data = _data && _data.toJS();
    return data.find(item => item.id === _activeProjectId);
  }
);

export const getActiveProjectIdSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.id
);

export const getIsProjectLockedSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED'
);

export const getIsProjectLockedForUsersSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED_FOR_USERS'
);

export const getIsProjectPublicSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.public
);

export const getProjectsOptionsSelector = createSelector(
  projectsOptions,
  data => data && data.toJS()
);

export const getProjectsOptionsLoadingSelector = createSelector(
  projectsOptionsLoading,
  data => data
);
