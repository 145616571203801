import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BiPlus, BiMinus } from 'react-icons/bi';

// Components
import RadioInput from '../Inputs/RadioInput/RadioInput';
import Checkbox from '../Inputs/Checkbox/Checkbox';
// Styles
import './styles.scss';

const propTypes = {
  title: PropTypes.string,
  titleElement: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withRadioBtn: PropTypes.bool,
  radioBtnProps: PropTypes.instanceOf(Object),
  withCheckBox: PropTypes.bool,
  checkBoxProps: PropTypes.instanceOf(Object),
  customClassName: PropTypes.string,
};

const AccordionItem = (props) => {
  const {
    title,
    titleElement,
    children,
    withRadioBtn = false,
    radioBtnProps = {},
    withCheckBox = false,
    checkBoxProps = {},
    customClassName,
  } = props;

  const [isActive, setIsActive] = useState(false);

  function renderTitle() {
    if (titleElement) {
      return titleElement;
    }

    return (
      <div className="accordion-item__title-wrap">
        {
          withRadioBtn &&
          <RadioInput {...radioBtnProps} />
        }
        {
          withCheckBox &&
          <Checkbox {...checkBoxProps} />
        }
        <span>
          {title}
        </span>
        <div className="circle-btn">
          {
            isActive ?
              <BiMinus size={16} color="#4b3f63" /> :
              <BiPlus size={16} color="#4b3f63" />
          }
        </div>
      </div>
    );
  }

  const accordionItemClass = classNames(
    'accordion-item',
    { 'accordion-item_active': isActive },
    customClassName,
  );

  return (
    <div className={accordionItemClass}>
      <div
        className="accordion-item__title"
        onClick={() => setIsActive(!isActive)}
      >
        {renderTitle()}
      </div>
      <div
        className="accordion-item__content"
        style={isActive ? { maxHeight: '100%' } : null}
      >
        {children}
      </div>
    </div>
  );
};

AccordionItem.propTypes = propTypes;

export default React.memo(AccordionItem);
