import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { PAGE_SIZE } from './constants';
import { defaultProjects } from '../../Projects/ProjectsTabs/constants';

export const getAvailableSetsAction = createAction('@availableSets/GET');
export const setAvailableSetsAction = createAction('@availableSets/SET');
export const toggleAvailableSetsLoaderAction = createAction('@availableSets/LOADER');
export const throwAvailableSetsErrorAction = createAction('@availableSets/ERROR');
export const changeAvailableSetsPageAction = createAction('@availableSets/PAGE_CHANGE');
export const filterAvailableSetsAction = createAction('@availableSets/FILTER');
export const sortAvailableSetsAction = createAction('@availableSets/SORT');
export const checkOneAvailableSetAction = createAction('@availableSets/CHECK_ONE');
export const checkAvailableSetAction = createAction('@availableSets/CHECK');
export const checkAllAvailableSetsAction = createAction('@availableSets/CHECK_ALL');
export const resetAvailableSetsAction = createAction('@availableSets/RESET');
export const setSetsListActiveProjectIdAction = createAction('@availableSets/ADD_PROJECT_ID');
export const clearSetsListActiveProjectIdAction = createAction('@availableSets/CLEAR_PROJECT_ID');

const initialState = fromJS({
  sets: [],
  page: 0,
  totalPages: 0,
  loading: false,
  error: null,
  filter: '',
  sorting: { sortBy: 'lastOpened', sortDirection: 'DESC' },
  activeProjectId: defaultProjects[0].id,
});

const reducer = handleActions(
  {
    [setAvailableSetsAction]: (state, { payload }) =>
      state
        .update('sets', () => fromJS(payload))
        .update('page', () => 0)
        .update('loading', () => false)
        .update('totalPages', () => Math.ceil((payload || []).length / PAGE_SIZE)),
    [toggleAvailableSetsLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwAvailableSetsErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [changeAvailableSetsPageAction]: (state, { payload }) =>
      state.update('page', () => payload),
    [filterAvailableSetsAction]: (state, { payload }) =>
      state
        .update('page', () => 0)
        .update('filter', () => payload),
    [sortAvailableSetsAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [checkOneAvailableSetAction]: (state, { payload }) =>
      state.update('sets', sets => sets.map((set) => {
        if (set.get('id') === payload.id) {
          return set.merge(fromJS({ selected: payload.checked }));
        } else if (set.get('selected')) {
          return set.merge(fromJS({ selected: false }));
        }
        return set;
      })),
    [checkAllAvailableSetsAction]: (state, { payload }) =>
      state.update('sets', sets =>
        sets.map(set => set.merge(fromJS({ selected: payload })))
      ),
    [checkAvailableSetAction]: (state, { payload }) =>
      state.update('sets', sets =>
        sets.map((set) => {
          if (set.get('id') === payload.id) {
            return set.merge(fromJS({ selected: payload.checked }));
          }
          return set;
        })
      ),
    [setSetsListActiveProjectIdAction]: (state, { payload }) =>
      state.update('activeProjectId', () => payload),
    [clearSetsListActiveProjectIdAction]: state =>
      state
        .update('activeProjectId', () => initialState.get('activeProjectId')),
    [resetAvailableSetsAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
