import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import Spinner from '../../common/Spinner/Spinner';
import FormInput from '../../common/FormElements/FormInput';
// Constants
import { RESTORE_PASSWORD_FORM } from './constants';

const propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

class RestorePasswordForm extends React.Component {
  render() {
    const {
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <form className="form-restore-password" onSubmit={handleSubmit}>
        <div className="row">
          <Field
            name="email"
            placeholder="Email"
            component={FormInput}
            type="text"
          />
        </div>
        <div className="row text-center">
          <button className="btn" disabled={submitting}>
            Send
          </button>
          <Spinner isLoading={submitting} />
        </div>
      </form>
    );
  }
}

RestorePasswordForm.propTypes = propTypes;

export default reduxForm({
  form: RESTORE_PASSWORD_FORM,
})(RestorePasswordForm);
