import { takeLatest } from 'redux-saga/effects';

import {
  getTargetCandidatesPairsFirstGeneChartsAction,
  getTargetCandidatesPairsSecondGeneChartsAction,
} from '../reducer';

import { getTargetCandidatesPairsFirstGeneChartsWorker } from './workers/getTargetCandidatesPairsFirstGeneChartsWorker';
import { getTargetCandidatesPairsSecondGeneChartsWorker } from './workers/getTargetCandidatesPairsSecondGeneChartsWorker';

function* algorithmHexElectSaga() {
  yield takeLatest(getTargetCandidatesPairsFirstGeneChartsAction, getTargetCandidatesPairsFirstGeneChartsWorker);
  yield takeLatest(getTargetCandidatesPairsSecondGeneChartsAction, getTargetCandidatesPairsSecondGeneChartsWorker);
}

export default algorithmHexElectSaga;
