import { call, put, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';
import { prepeareUploadFileRequestData } from '../utils';
// Store
import * as a from './actions';

function* uploadFileWorker({ payload }) {
  try {
    const isProjectTab = checkProjectTab(payload.projectId);
    const formData = new FormData();
    const file = payload.eventTarget.files[0];
    yield call(() => formData.append('file', file));
    const requestData = prepeareUploadFileRequestData(formData, payload);

    const { data: fileData } =
      yield call(isProjectTab ? Api.uploadProjectFile : Api.uploadFile, requestData);

    yield put(payload.callbackAction({ fileData, fileType: payload.fileType, uploadOnlySimple: payload.uploadOnlySimple }));
    yield put(a.setUploadedFileToStoreAction(file));
    yield put(a.succeededUploadFileAction({ isComplexSet: !!fileData.complexSet }));
  } catch (e) {
    yield put(a.throwUploadFileErrorAction(e.response?.data?.message));
  }
}

function* getUploadFileCategoriesWorker() {
  try {
    const { data } = yield call(Api.getMajorSemanticCategories);
    yield put(a.setUploadFileCategoriesAction(data));
  } catch (error) {
    yield put(a.throwUploadFileErrorAction(error.message));
  }
}

function* getUploadFileTaxonomiesWorker() {
  try {
    const { data } = yield call(Api.getMainTaxonomies);
    yield put(a.setUploadFileTaxonomiesAction(data.map(d => ({ name: d, checked: false }))));
  } catch (error) {
    console.log(error.message);
  }
}

function* uploadFileSaga() {
  yield takeLatest(a.getUploadFileSetDataAction, uploadFileWorker);
  yield takeLatest(a.getUploadFileCategoriesAction, getUploadFileCategoriesWorker);
  yield takeLatest(a.getUploadFileTaxonomiesAction, getUploadFileTaxonomiesWorker);
}

export default uploadFileSaga;
