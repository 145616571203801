import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
// Components
import Button from '../../../common/Buttons/Button/Button';
import FormInput from '../../../common/FormElements/FormInput';
import FormSelect from '../../../common/FormElements/FormSelect';
import SearchPublicationSources from '../SearchPublicationSources/SearchPublicationSources';
// Enums
import {
  searchFilterSelectOptionsEnum,
  searchSortingOptions,
} from '../../enums';
// Constants
import { SEARCH_FILTER_FORM } from './constants';
import { THEME } from '../../../../constantsCommon';
// Styles
import './SearchFilter.scss';

const propTypes = {
  handleSubmit: PropTypes.func,
  handleOnSubmit: PropTypes.func,
  reset: PropTypes.func,
  valid: PropTypes.bool,
  change: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  sources: PropTypes.instanceOf(Array),
  pubSources: PropTypes.instanceOf(Array),
  searchValue: PropTypes.string,
  searchValues: PropTypes.instanceOf(Object),
  changeYearsFormValues: PropTypes.func,
  publicationFilterExtended: PropTypes.bool,
  setPublicationFilterExtended: PropTypes.func,
  isDatabase: PropTypes.bool,
  isSentenceCooccurrence: PropTypes.bool,
};

const SearchFilter = (props) => {
  const {
    reset,
    valid,
    sources,
    pristine,
    pubSources,
    submitting,
    handleSubmit,
    handleOnSubmit,
    change: changeField,
    isDatabase,
    isSentenceCooccurrence,
  } = props;

  const publicationSourcesClassName = classNames({
    'search-filter-form__select': true,
    'search-filter-form__select_hidden': isDatabase,
  });

  return (
    <form
      className="search-filter-form"
      onSubmit={handleSubmit(() => { handleOnSubmit(); })}
    >
      <div className="search-filter-form__block">
        {
          !isDatabase ?
            <>
              <Field
                name="sort"
                placeholder="Sort by"
                component={FormSelect}
                className="search-filter-form__select"
                options={searchSortingOptions}
                bordered={true}
                isClearable={true}
              />
              <Field
                name="publicationSource"
                placeholder="Reference sources"
                component={FormSelect}
                className={publicationSourcesClassName}
                options={searchFilterSelectOptionsEnum.sources}
                bordered={true}
                isClearable={true}
                disabled={isSentenceCooccurrence}
              />
              <Field
                name="publicationTypes"
                placeholder="Publication types"
                component={FormSelect}
                isMulti={true}
                className="search-filter-form__select"
                options={searchFilterSelectOptionsEnum.types}
                bordered={true}
                disabled={isSentenceCooccurrence}
              />
              <div className="search-filter-form__block_rows">
                <span className="search-filter-form__label">
                  Publication year
                </span>
                <div className="search-filter-form__row">
                  <div className="search-filter-form__input">
                    <Field
                      name="dateFrom"
                      placeholder="From"
                      component={FormInput}
                      type="number"
                      onFocus={(e) => { e.target.placeholder = 'YYYY'; }}
                      onBlur={(e) => { e.target.placeholder = 'From'; }}
                    />
                  </div>
                  <div className="search-filter-form__input">
                    <Field
                      name="dateTo"
                      placeholder="To"
                      component={FormInput}
                      type="number"
                      onFocus={(e) => { e.target.placeholder = 'YYYY'; }}
                      onBlur={(e) => { e.target.placeholder = 'To'; }}
                    />
                  </div>
                </div>
                <span className="search-filter-form__label">
                  Impact factor
                </span>
                <div className="search-filter-form__row">
                  <div className="search-filter-form__input">
                    <Field
                      name="impactFactorFrom"
                      placeholder="From"
                      component={FormInput}
                      type="number"
                    />
                  </div>
                  <div className="search-filter-form__input">
                    <Field
                      name="impactFactorTo"
                      placeholder="To"
                      component={FormInput}
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </> :
            <SearchPublicationSources
              sources={sources}
              pubSources={pubSources}
              changeField={changeField}
              isSentenceCooccurrence={isSentenceCooccurrence}
            />
        }
        <div className="search-filter-form__row">
          <Button
            text="Reset"
            theme={THEME.GREY}
            onClick={reset}
            disabled={pristine || submitting}
            customClassName="search-filter-form__btn"
          />
          <Button
            text="Apply"
            type="submit"
            theme={THEME.GREY}
            disabled={submitting || !valid}
            customClassName="search-filter-form__btn"
          />
        </div>
      </div>
    </form>
  );
};

SearchFilter.propTypes = propTypes;

function validate(values) {
  const errors = {};
  const dateFrom = Number(values.get('dateFrom'));
  const dateTo = Number(values.get('dateTo'));
  const impactFactorFrom = Number(values.get('impactFactorFrom'));
  const impactFactorTo = Number(values.get('impactFactorTo'));
  if (dateFrom > 2100) {
    errors.dateFrom = 'Year from value is to big';
  }
  if (dateFrom < 1700) {
    errors.dateFrom = 'Year from value is to small';
  }
  if (dateTo > 2100) {
    errors.dateTo = 'Year to value is to big';
  }
  if (dateTo < 1700) {
    errors.dateTo = 'Year to value is to small';
  }
  if (dateFrom > dateTo) {
    errors.dateFrom = 'Should be less then Year to value';
  }
  if (impactFactorFrom > impactFactorTo) {
    errors.impactFactorFrom = 'Should be less then Impact factor to value';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: SEARCH_FILTER_FORM,
    destroyOnUnmount: true,
    validate,
  }),
);

export default composition(SearchFilter);
