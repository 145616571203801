import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Components
import Loader from '../../../common/Loader/Loader';
import PathDetails from '../PathDetails/PathDetails';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import ExportTable from '../../../common/ExportTable/ExportTable';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
// Utils
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { firstNodeCategorySelectValues, EMPTY_FILTER } from './enum';
import {
  convertTableToCSVText,
  exportToFile,
  scrollToBottom,
  getScreenWidth,
} from '../../../Utils/Utils';
// Store
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import {
  getNetworkAnalysisSettings,
  getSelectedNetworkAnalysisTissue,
} from '../../common/SetAnalysisMethodSelection/selectors';
import { setPathForPathDetails } from '../PathDetails/actions';
import { openGenesDetail as openGenesDetailAction } from '../../../common/SetAnalysisGenesDetail/actions';
import {
  currentPage,
  tableSorting,
  getTotalPages,
  getTotalItems,
  getSelectedItems,
  getLoadingKey,
  getPageNumber,
  getError,
  getFirstNodeTermFilterValue,
  getFirstNodeCategoryFilterValue,
} from './selectors';
import {
  sort,
  checkAll,
  checkItem,
  invertSelection,
  removeSelected,
  removeConcept,
  reset,
  networkAnalysis,
  loading,
  firstNodeTermFilterChange,
  firstNodeCategoryFilterChange,
  exportAllToCSVAction,
} from './actions';
// Styles
import './SetAnalysisNetworkAnalysisResult.css';

const propTypes = {
  removeConcept: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Array),
  page: PropTypes.instanceOf(Array),
  changePage: PropTypes.func,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  openGenesDetail: PropTypes.func,
  pageNumber: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  loadingKey: PropTypes.bool,
  error: PropTypes.string,
  createHeaderRendererWithSaveAs: PropTypes.func,
  setPathForPathDetails: PropTypes.func,
  filterByFirstNode: PropTypes.func,
  selectedNetworkAnalysisTissue: PropTypes.instanceOf(Object),
  networkAnalysisSettings: PropTypes.instanceOf(Object),
  firstNodeTermFilterValue: PropTypes.string,
  firstNodeCategoryFilterValue: PropTypes.string,
  changeFirstNodeTermFilterValue: PropTypes.func,
  changeFirstNodeCategoryFilterValue: PropTypes.func,
  triggerExportingAllToCSV: PropTypes.func,
  handleSaveAllAsSetButton: PropTypes.func,
};

class SetAnalysisNetworkAnalysisResult extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPathDetailsModal: false,
    };

    const {
      createHeaderRendererWithSaveAs,
      page,
    } = this.props;

    this.columns = [
      {
        label: 'First node',
        dataKey: 'firstNode',
        width: 500,
        exportCSV: true,
        headerRenderer: createHeaderRendererWithSaveAs ? createHeaderRendererWithSaveAs(page) : undefined,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.firstNode ? this.genesCell(rowData.firstNode, rowIndex) : null
        ),
      },
      {
        label: 'First node category',
        dataKey: 'fnodeCategory',
        width: 450,
        exportCSV: true,
      },
      {
        label: 'Last node',
        dataKey: 'lastNode',
        width: 500,
        exportCSV: true,
        headerRenderer: createHeaderRendererWithSaveAs ? createHeaderRendererWithSaveAs(page) : undefined,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.lastNode ? this.genesCell(rowData.lastNode, rowIndex) : null
        ),
      },
      {
        label: 'Path',
        dataKey: 'intermediateNodes',
        exportCSV: true,
        visible: false,
      },
      {
        label: 'Path length',
        dataKey: 'length',
        width: 200,
        exportCSV: true,
      },
      {
        label: 'Path score',
        dataKey: 'score',
        width: 300,
        exportCSV: true,
        cellRenderer: ({ rowData }) => (
          (
            <span
              role="presentation"
              className="link"
              onClick={
                () => {
                  this.goToPathDetails(rowData);
                }
              }
            >
              {rowData.score.toFixed(2)}
            </span>
          )
        ),
      },
      {
        label: '% of Active Genes',
        dataKey: 'pag',
        width: 350,
        exportCSV: true,
      }];
  }

  componentDidMount() {
    scrollToBottom('body');
  }

  removeConcept = (id) => {
    this.props.removeConcept(id);
  };

  genesCell = ({ id, name }, rowIndex) => {
    const link = `${RELATIVE_PATH}/gene-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  /*
  This will be used after implementation of type-ahead filter style
  requestNewData = debounce(
    () => {
      this.props.filterByFirstNode({
        post: {
          startSetIds: this.props.conceptsToAnalyse.map(concept => (
            concept.id
          )),
          filter: {
            firstNodeTerm: this.state.filterValue,
          },
        },
        params: {
          size: this.props.totalPages === 1 ? 20 : this.props.totalItems,
          page: 0,
        },
      });
    },
    200
  );

  throttledHandleInputChange = (e) => {
    this.setState({
      filterValue: e.target.value,
    }, () => {
      if (this.state.filterValue.length > 2) {
        this.requestNewData();
      }
    });
  }
  */

  // Temporary solution
  handleInputChange = (e) => {
    this.props.changeFirstNodeTermFilterValue(e.target.value);
  };

  handleFilterButtonClick = () => {
    const {
      totalPages,
      totalItems,
      filterByFirstNode,
    } = this.props;

    filterByFirstNode({
      params: {
        size: totalPages === 1 ? 20 : totalItems,
        page: 0,
      },
    });
  };

  // End of temporary solution

  exportToCSV = (selected, columns) => {
    const dataForExport = selected ? this.props.selectedItems : this.props.page;
    const result = convertTableToCSVText(dataForExport, columns.filter(column => (
      column.exportCSV
    )));
    exportToFile(this.downloadLink, result, 'table-export');
  };

  exportAllToCSV = (columns) => {
    this.props.triggerExportingAllToCSV({
      columns,
      downloadLink: this.downloadLink,
    });
  };

  /* Pagination switcher */
  showHideAllConcepts = () => {
    const {
      changePage,
      totalPages,
      totalItems,
    } = this.props;

    changePage({
      params: {
        size: totalPages === 1 ? 20 : totalItems,
        page: 0,
      },
    });
  };

  handleModalClose = () => {
    this.setState({
      showPathDetailsModal: false,
    });
  };

  handlePageClick = (pageNumber) => {
    this.props.changePage({
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  openGenesDetail = (concept) => {
    this.props.openGenesDetail({
      concept,
      genesDetailAPIKey: 'pathwayTargetCandidates',
    });
  };

  goToPathDetails = (data) => {
    const {
      selectedNetworkAnalysisTissue,
      networkAnalysisSettings,
    } = this.props;
    this.props.setPathForPathDetails({
      path: data.path,
      targetTissue: selectedNetworkAnalysisTissue,
      targetGeneId: networkAnalysisSettings.gene && (networkAnalysisSettings.gene.id || networkAnalysisSettings.gene.data),
      additionalSet: networkAnalysisSettings.selectedSetId,
    });
    this.setState({
      showPathDetailsModal: true,
    });
  };

  firstNodeCategoryFilterChangeHandler = (e) => {
    const {
      filterByFirstNode,
      changeFirstNodeCategoryFilterValue,
      totalPages,
      totalItems,
    } = this.props;

    changeFirstNodeCategoryFilterValue(e.target.value);

    filterByFirstNode({
      params: {
        size: totalPages === 1 ? totalItems : 20,
        page: 0,
      },
    });
  };

  /* Table actions */
  actions = {
    checkAll,
    checkItem,
    invertSelection,
    removeSelected,
  };

  render() {
    const {
      showPathDetailsModal,
    } = this.state;

    const {
      page,
      totalPages,
      pageNumber,
      sorting: { sortBy, sortDirection },
      selectedItems,
      loadingKey,
      error,
      handleSaveAllAsSetButton,
    } = this.props;

    const screenWidth = getScreenWidth();
    const tableWidth = 1300;

    let tableSettings = {
      width: screenWidth < tableWidth ? screenWidth : tableWidth,
      headerHeight: 50,
      rowHeight: 50,
      sortBy,
      sortDirection,
    };

    if (page.length < 20) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    const wrapperStyle = {
      width: screenWidth > tableWidth ? tableWidth : screenWidth - 40,
    };

    return (
      <div className="setAnalysisNetworkResult">
        {
          showPathDetailsModal &&
          <ModalComponent
            isOpen={showPathDetailsModal}
            closeCb={this.handleModalClose}
            customClassName="path-details__popup"
          >
            <div className="path-details__popup-wrap">
              <PathDetails />
            </div>
          </ModalComponent>
        }
        <div className="analysis-title title2 mt-20">Network analysis</div>
        {
          !loadingKey &&
          <div>
            <div className="flex-grid justify-content-center network-result-top-container" style={wrapperStyle}>
              <div className="col-1 node-filter-wrapper">
                {
                  /*
                  This will be used after implementation of type-ahead filter style
                  <input
                    value={this.state.filterValue}
                    className="input input-text"
                    onChange={this.throttledHandleInputChange}
                    placeholder="Filter first node"
                  />
                  */
                }
                <input
                  className="input input-text"
                  onChange={this.handleInputChange}
                  placeholder="Filter by concept"
                  value={this.props.firstNodeTermFilterValue}
                />
                <button
                  type="button"
                  className="btn"
                  onClick={this.handleFilterButtonClick}
                >
                  Filter
                </button>
              </div>
              <div className="col-1 node-filter-wrapper">
                <select
                  className="select"
                  onChange={
                    (e) => { this.firstNodeCategoryFilterChangeHandler(e); }
                  }
                  value={this.props.firstNodeCategoryFilterValue}
                >
                  <option value={EMPTY_FILTER}>{EMPTY_FILTER}</option>
                  {
                    firstNodeCategorySelectValues.map((valueItem, index) => (
                      <option
                        key={`${valueItem}__${index}`}
                        value={valueItem}
                      >
                        {valueItem}
                      </option>
                    )
                    )
                  }
                </select>
              </div>
              <div className="col-1 table-buttons-container">
                <button
                  disabled={!page.length}
                  onClick={handleSaveAllAsSetButton}
                  className="btn btn-group-first"
                >
                  Save all as set
                </button>
              </div>
              <div className="col-2 table-buttons-container">
                <ExportTable
                  content={page}
                  columns={this.columns}
                  selectedItems={selectedItems}
                  showExportAll={false}
                  showExportSelected={true}
                  showExportPage={true}
                  fileName="network_analysis"
                />
                <button
                  disabled={!page.length}
                  className="button button-primary ml-15"
                  onClick={() => this.showHideAllConcepts()}
                >
                  { totalPages === 1 ? <span>Show pages</span> : <span>Show all</span> }
                </button>
              </div>
              <div className="col-1 pagination-wrapper">
                <div className="paginationContainer">
                  {
                    totalPages > 1 &&
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel={<a>...</a>} //eslint-disable-line
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  }
                </div>
              </div>
            </div>
            <SimpleTable
              settings={tableSettings}
              showOnHeatMap={true}
              data={page}
              manageable={true}
              actions={this.actions}
              sortAction={sort}
              columns={this.columns}
              networkAnalysis={true}
            />
          </div>
        }
        <Loader isLoading={loadingKey && !error} />
        {
          error &&
          <div className="text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

SetAnalysisNetworkAnalysisResult.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    page: currentPage(state),
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    totalItems: getTotalItems(state),
    selectedItems: getSelectedItems(state),
    sorting: tableSorting(state),
    conceptsToAnalyse: selectedDataForAnalysis(state),
    loadingKey: getLoadingKey(state),
    error: getError(state),
    selectedNetworkAnalysisTissue: getSelectedNetworkAnalysisTissue(state),
    networkAnalysisSettings: getNetworkAnalysisSettings(state),
    firstNodeTermFilterValue: getFirstNodeTermFilterValue(state),
    firstNodeCategoryFilterValue: getFirstNodeCategoryFilterValue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    triggerExportingAllToCSV(columns) {
      dispatch(exportAllToCSVAction(columns));
    },
    changeFirstNodeTermFilterValue(data) {
      dispatch(firstNodeTermFilterChange(data));
    },
    changeFirstNodeCategoryFilterValue(data) {
      dispatch(firstNodeCategoryFilterChange(data));
    },
    removeConcept(id) {
      dispatch(removeConcept(id));
    },
    changePage(data) {
      dispatch(networkAnalysis(data));
    },
    filterByFirstNode(data) {
      dispatch(networkAnalysis(data));
    },
    filterByFirstNodeCategory(data) {
      dispatch(networkAnalysis(data));
    },
    reset() {
      dispatch(reset());
    },
    networkAnalysis(data) {
      dispatch(networkAnalysis(data));
    },
    loading() {
      dispatch(loading());
    },
    openGenesDetail(data) {
      dispatch(openGenesDetailAction(data));
    },
    setPathForPathDetails(data) {
      dispatch(setPathForPathDetails(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAnalysisNetworkAnalysisResult);
