import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Measure from 'react-measure';
import { startOfDay, isAfter } from 'date-fns';

// Icons
import {BiDna, BiIntersect, BiListUl } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';
import { FcHeatMap } from 'react-icons/fc';
import { IoMdAdd, IoMdCopy } from 'react-icons/io';
import { MdDelete, MdEdit } from 'react-icons/md';
import { ImListNumbered, ImList } from 'react-icons/im';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import { RiSearchLine } from 'react-icons/ri';
import { AiOutlineNodeIndex } from 'react-icons/ai';
import { GiSettingsKnobs } from 'react-icons/gi';
import VennDiagramIcon from '../../common/Icons/VennDiagramIcon/VennDiagramIcon';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import Button from '../../common/Buttons/Button/Button';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import CopySetsModal from './Components/CopySetsModal/CopySetsModal';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Store
import {
  resetLastSavedAnalyticsIdAction,
  selectSet,
  setShouldClearAllSetsFlagToFalseAction,
  clearAllSetsAction,
} from '../../Analytics/common/SetAnalysis/actions';
import {
  setResultReset as setResultResetAction,
  goToSetResultSetAction,
  setCurrentSetIdForSetResultAction,
} from '../../SetResult/SetResultPage/actions';
import * as ACTIONS from './reducer';
import * as SELECTORS from './selectors';
import { isFreeUserSelector, isProjectManagerRightSelector } from '../../Header/selectors';
import { showFreeAccountLimitDialogAction } from '../../common/FreeAccountLimitationDialog/store/reducer';
import { getLocationHistorySelector } from '../../../location/locationSelector';
import * as RANK_ACTIONS from '../../RankSelection/store/actions';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { formatDate, getScreenWidth, proposingCopyName, checkIsReturnToThePage } from '../../Utils/Utils';
import { setTypes, setsActionsTypes, iconsTypes } from './enum';
import { MAX_RECORDS_COUNT, LONG_TIME_REQUEST_COUNT } from './constants';
import { MAX_RECORDS_COUNT as MAX_RECORDS_FOR_BUILD_HEATMAP } from '../../graphics/Heatmap/constants';
// Styles
import './SetsManagement.scss';
import {setSelectedSetInfoAction} from '../../FindRelated/Components/EnrichmentAnalysisParams/store/actions';
import RankSelectionParamsModal from '../../RankSelection/Components/RankSelectionsParams/RankSelectionParamsModal';
import {setActiveCategoriesAction} from '../../Concept/ConceptSearchModal/store/actions';

const propTypes = {
  sets: PropTypes.instanceOf(Array),
  checkedSets: PropTypes.instanceOf(Array),
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
  setCurrentSetIdForSetResult: PropTypes.func,
  setResultReset: PropTypes.func,
  resetLastSavedAnalyticsId: PropTypes.func,
  setShouldClearAllSetsFlagToFalse: PropTypes.func,
  clearAllSets: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  sortSetManagementData: PropTypes.func,
  filterSetManagementData: PropTypes.func,
  getSetManagementData: PropTypes.func,
  removeSetManagementDataItem: PropTypes.func,
  selectSetManagementDataItemForAnalysis: PropTypes.func,
  resetSetManagementData: PropTypes.func,
  duplicateSets: PropTypes.func,
  getSet: PropTypes.func,
  set: PropTypes.instanceOf(Object),
  resetSet: PropTypes.func,
  downloadSet: PropTypes.func,
  isFreeUser: PropTypes.bool,
  isProjectManagerUser: PropTypes.bool,
  showFreeAccountLimitDialog: PropTypes.func,
  projectId: PropTypes.string,
  isProjectLocked: PropTypes.bool,
  isProjectPublic: PropTypes.bool,
  isProjectLockedForUsers: PropTypes.bool,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  redirectToSearch: PropTypes.func,
  navigate: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  navigationType: PropTypes.string,
  locationHistory: PropTypes.instanceOf(Array),
  setSelectedSetInfo: PropTypes.func,
  setRankingSelectedSetInfo: PropTypes.func,
  setActiveCategories: PropTypes.func,
  goToSetResult: PropTypes.func,
};

class SetsManagement extends React.Component {
  state = {
    setIdsToDelete: [],
    showConfirmDeletePopup: false,
    showConfirmCopyPopup: false,
    copySets: {},
    showHeatMapRecordsPopup: false,
    showRankParamsModal: false,
  };
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    const {
      projectId,
      getSetManagementData,
    } = this.props;
    getSetManagementData({ projectId });
  }

  componentDidUpdate(prevProps) {
    const { getSetManagementData, projectId } = this.props;
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
    if (prevProps.projectId !== projectId) {
      getSetManagementData({ projectId });
    }
  }

  componentWillUnmount() {
    const { resetSetManagementData } = this.props;
    resetSetManagementData();
  }

  editSet = (id) => {
    const { sets, navigate, projectId } = this.props;
    const setForEdit = sets.find(set => id === set.id);

    if (setForEdit.setType !== 'COMPLEX') {
      navigate(`${RELATIVE_PATH}/sets/edit/${projectId}/${setForEdit.id}`);
    } else {
      navigate(`${RELATIVE_PATH}/complex-set/${projectId}/${setForEdit.id}`);
    }
  };

  addSet = () => {
    const {
      sets,
      navigate,
      isFreeUser,
      showFreeAccountLimitDialog,
      projectId,
    } = this.props;

    if (isFreeUser && sets.length >= 2) {
      showFreeAccountLimitDialog();
    } else {
      navigate({
        pathname: `${RELATIVE_PATH}/sets/add/${projectId}`,
      });
    }
  };

  openConfirmDeletePopup = (rowData) => {
    this.setState({ showConfirmDeletePopup: true });
    return !rowData.id ?
      this.setState({ setIdsToDelete: this.props.checkedSets.map(set => set.id) }) :
      this.setState({ setIdsToDelete: [rowData.id] });
  };

  closeConfirmDeletePopup = () => {
    this.setState({
      setIdsToDelete: [],
      showConfirmDeletePopup: false,
    });
  };

  openConfirmCopyPopup = (rowData, multiselect) => {
    const {
      sets,
      isFreeUser,
      checkedSets,
      showFreeAccountLimitDialog,
    } = this.props;

    if (isFreeUser && sets.length >= 2) {
      showFreeAccountLimitDialog();
    } else {
      let copySetsConfig = {};
      if (multiselect) {
        copySetsConfig = { ids: checkedSets.map(s => s.id), multiselect };
      } else {
        const name = proposingCopyName(sets, rowData.name, 'copy');
        copySetsConfig = { name, ids: [rowData.id] };
      }

      this.setState({
        showConfirmCopyPopup: true,
        copySets: copySetsConfig,
      });
    }
  };

  getFilterSet = (rowData) => {
    const { getSet, projectId, navigate } = this.props;
    getSet({ setId: rowData.id, projectId });

    navigate({
      pathname: `${RELATIVE_PATH}/create-set-page`,
      search: `savedSetId=${rowData.id}&projectId=${projectId}`,
    });
  };

  closeConfirmCopyPopup = () => {
    this.setState({
      showConfirmCopyPopup: false,
      copySets: {},
    });
  };

  openHeatMapRecordsPopup = () => {
    this.setState({
      showHeatMapRecordsPopup: true,
    });
  };

  closeHeatMapRecordsPopup = () => {
    this.setState({
      showHeatMapRecordsPopup: false,
    });
  };

  toggleRankParamsDialog = () => {
    this.setState({
      showRankParamsModal: !this.state.showRankParamsModal,
    });
  };

  removeSetManagementDataItem = () => {
    const { removeSetManagementDataItem, projectId } = this.props;
    const { setIdsToDelete } = this.state;
    removeSetManagementDataItem({ setIds: setIdsToDelete, projectId });
    this.closeConfirmDeletePopup();
  };

  duplicateSetManagement = () => {
    const { duplicateSets, projectId } = this.props;
    const { copySets: { ids, multiselect } } = this.state;
    duplicateSets({ setIds: ids, projectId, multiselect });
    this.closeConfirmCopyPopup();
  };

  getRowHeight = ({ index }) => {
    const PADDING = 20;
    if (this.props.sets.length > 0 && this.props.sets[index].showAll) {
      return 200 + PADDING;
    }
    return 50;
  };

  goToSetResult = (setType = '', setName = '', id = null, projectId) => {
    const {
      goToSetResult,
      setResultReset,
      setCurrentSetIdForSetResult,
    } = this.props;

    setResultReset();
    goToSetResult({
      setName,
      id,
      setType,
      projectId,
    });
    setCurrentSetIdForSetResult(id);
  };

  goToVennDiagram = () => {
    const { navigate, checkedSets, projectId } = this.props;
    navigate(
      `${RELATIVE_PATH}/sets-merge-page`,
      { state: { checkedSets, projectId }}
    );
  };

  goToCompareSets = () => {
    const { navigate, checkedSets, projectId } = this.props;
    const compareSetsData = checkedSets.reduce((acc, set, i) => {
      if (i !== 0) {
        acc.ids.push(set.id);
        acc.setsNames.push(set.name);
        acc.recordsCount *= set.conceptQuantity;
        return acc;
      }
      return {
        ids: [set.id],
        setsNames: [set.name],
        recordsCount: set.conceptQuantity,
      };
    }, {});

    if (compareSetsData.recordsCount > MAX_RECORDS_COUNT) {
      this.openHeatMapRecordsPopup();
      return;
    }

    navigate(
      `${RELATIVE_PATH}/heatmap-page`,
      { state: { projectId, ...compareSetsData }}
    );
  };

  goToBuildHeatmapPage = (rowData) => {
    const { navigate, projectId } = this.props;

    navigate(
      `${RELATIVE_PATH}/build-heatmap-page`,
      { state: {
        id: rowData.id,
        buildHeatmap: true,
        setName: rowData.name,
        recordsCount: rowData.conceptQuantity,
        projectId,
      }}
    );
  };

  goToSearchWithSetsPage = () => {
    const { navigate, checkedSets, projectId } = this.props;

    const recordsCount = checkedSets.reduce((count, { conceptQuantity }) => (
      count === 0 ? conceptQuantity : count * conceptQuantity
    ), 0);

    if (recordsCount > MAX_RECORDS_COUNT) {
      this.openHeatMapRecordsPopup();
      return;
    }

    navigate(
      `${RELATIVE_PATH}/search-with-sets`,
      {
        state: {
          projectId,
          sets: checkedSets,
          longTimeRequestDialog: recordsCount > LONG_TIME_REQUEST_COUNT,
        }
      });
  };

  goToFindRelatedPage = (rowData) => {
    const { navigate, projectId, setSelectedSetInfo } = this.props;
    setSelectedSetInfo({
      setId: rowData.id,
      projectId,
      setName: rowData.name,
    });
    navigate(`${RELATIVE_PATH}/find-related`);
  };

  goToRankSelectionPage = (rowData) => {
    const {
      projectId,
      setRankingSelectedSetInfo,
      setActiveCategories
    } = this.props;
    setActiveCategories(['T902', 'T191']);
    setRankingSelectedSetInfo({
      setId: rowData.id,
      projectId,
      setName: rowData.name,
    });
    this.setState({
      showRankParamsModal: true,
    });
  };

  analyzeSet = (set) => {
    const {
      navigate,
      clearAllSets,
      setShouldClearAllSetsFlagToFalse,
      selectSetManagementDataItemForAnalysis,
      resetLastSavedAnalyticsId,
      projectId,
    } = this.props;

    clearAllSets();
    setShouldClearAllSetsFlagToFalse();
    selectSetManagementDataItemForAnalysis({ set, projectId, navigate });
    resetLastSavedAnalyticsId();
  };

  actions = {
    checkAll: ACTIONS.checkAllSetsAction,
    checkItem: ACTIONS.checkSetAction,
  };

  downloadSet = (data) => {
    const { downloadSet, projectId } = this.props;

    const {
      setType,
      id,
      conceptQuantity,
    } = data;
    downloadSet({
      setType,
      id,
      conceptQuantity,
      downloadLink: this.downloadLink,
      projectId,
    });
  };

  projectsActionsAreDisabled = () => {
    const {
      isFreeUser,
      isProjectManagerUser,
      isProjectPublic,
      isProjectLocked,
      isProjectLockedForUsers,
    } = this.props;
    if (isProjectLocked || isProjectPublic || (isProjectLockedForUsers && (isFreeUser || !isProjectManagerUser))) {
      return true;
    }
    return false;
  };

  setActionIsDisabled = (setActionType) => {
    const { sets } = this.props;
    if (!sets) return true;
    const selectedSets = sets.filter(set => set.selected === true);
    if (!selectedSets.length) {
      return true;
    }
    const complexIsSelected = selectedSets.find(set => set.setType === 'COMPLEX');

    switch (setActionType) {
      case setsActionsTypes.DELETE: {
        return selectedSets.length === 0 || this.projectsActionsAreDisabled();
      }
      case setsActionsTypes.COPY: {
        return selectedSets.length === 0;
      }
      case setsActionsTypes.COMPARE: {
        if (complexIsSelected) {
          return true;
        }
        if (selectedSets.length !== 2) {
          return true;
        }
        return false;
      }
      case setsActionsTypes.VENN: {
        if (complexIsSelected) {
          return true;
        }
        if (selectedSets.length >= 2 && selectedSets.length <= 6) {
          return false;
        }
        return true;
      }
      case setsActionsTypes.INTERSECT: {
        if (complexIsSelected) {
          return true;
        }
        if (selectedSets.length >= 2 && selectedSets.length <= 4) {
          return false;
        }
        return true;
      }
      default: {
        return true;
      }
    }
  };

  handleProjectClick = (id) => {
    const { getSetManagementData } = this.props;
    getSetManagementData({ projectId: id });
  };

  render() {
    const screenWidth = getScreenWidth();
    const {
      sets,
      navigate,
      filters: {
        titleFilter,
        tagFilter,
      },
      sorting: {
        sortBy,
        sortDirection,
      },
      loading,
      error,
      sortSetManagementData,
      filterSetManagementData,
      setActiveProjectId,
      clearActiveProjectId,
      projectId,
      redirectToSearch,
      location,
      locationHistory,
    } = this.props;

    const {
      showConfirmDeletePopup,
      showConfirmCopyPopup,
      setIdsToDelete,
      copySets,
      showHeatMapRecordsPopup,
      showRankParamsModal,
    } = this.state;

    const tableSettings = {
      width: 900,
      height: 0,
      headerHeight: 65,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    function renderSetIcon({ setType, type }) {
      const styles = {
        fill: '#4b3f63',
        width: 20,
        height: 20,
        marginRight: 5,
      };
      if (setType === 'COMPLEX') return <BsFillGrid3X3GapFill style={styles} />;
      if (setType === 'SIMPLE' && type === 'Genes') return <BiDna style={styles} />;
      if (setType === 'EFFECT' && type === 'Genes') return <BiDna style={styles} />;
      if (setType === 'SIMPLE') return <ImList style={styles} />;
      return <ImListNumbered style={styles} />;
    }

    const columns = [
      {
        label: 'Title',
        dataKey: 'name',
        width: screenWidth,
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          let title = `Title: ${rowData.name}`;
          if (rowData.description.length > 0) title += `\n${rowData.description}`;
          return (
            <div className="title-section">
              {renderSetIcon(rowData)}
              <div
                className={rowData.conceptQuantity > 0 ? 'title-section-name set-title link' : 'title-section-name'}
                title={title}
                onClick={() => {
                  if (rowData.conceptQuantity > 0) {
                    if (rowData.setType === 'COMPLEX') {
                      navigate(`${RELATIVE_PATH}/complex-set/${projectId}/${rowData.id}`);
                    } else {
                      this.getFilterSet(rowData);
                    }
                  }
                }
                }
              >
                {rowData.name}
              </div>
            </div>
          );
        },
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
                {label}
              </span>
              <span className="sorting-section">
                <i
                  className={ascClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'ASC' }); }}
                />
                <i
                  className={descClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'DESC' }); }}
                />
              </span>
              <input
                onChange={(e) => { filterSetManagementData({ value: e.target.value, filter: 'titleFilter' }); }}
                value={titleFilter}
                className="header-input input"
                type="input"
                placeholder="Search titles"
              />
            </div>
          );
        },
      },
      {
        label: 'Tags',
        dataKey: 'tags',
        width: screenWidth / 2,
        disableSort: true,
        headerRenderer: ({ label }) => (
          <div className="header-section">
            <span
              className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
              title={label}
            >
              {label}
            </span>
            <input
              onChange={(e) => { filterSetManagementData({ value: e.target.value, filter: 'tagFilter' }); }}
              value={tagFilter}
              className="header-input input"
              type="input"
              placeholder="Search tags"
            />
          </div>
        ),
        cellRenderer: ({ rowData }) => (
          <div
            className="tag-section"
            title={rowData.tags.join(', ')}
          >
            {
              rowData.tags.map((tag, index, array) =>
                (
                  <span key={index}>
                    <span>{tag}</span>
                    {
                      index !== (array.length - 1) && <span>, </span>
                    }
                  </span>
                ))
            }
          </div>
        ),
      },
      {
        label: 'Concepts',
        dataKey: 'conceptQuantity',
        width: screenWidth / 4,
        disableSort: true,
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
                {label}
              </span>
              <span className="sorting-section">
                <i
                  className={ascClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'ASC' }); }}
                />
                <i
                  className={descClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'DESC' }); }}
                />
              </span>
            </div>
          );
        },
      },
      {
        label: 'Type',
        dataKey: 'advancedSetType',
        width: screenWidth / 6,
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          const valueToRender = rowData.setType ? setTypes[rowData.setType] : null;
          return <span>{valueToRender}</span>;
        },
      },
      {
        label: 'Last edited',
        dataKey: 'lastOpened',
        width: screenWidth / 4,
        disableSort: true,
        headerRenderer: ({
          dataKey,
          label,
          sortBy: lastOpenedSortBy,
          sortDirection: lastOpenedSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': lastOpenedSortBy === dataKey && lastOpenedSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': lastOpenedSortBy === dataKey && lastOpenedSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span
                className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
                title={label}
              >
                {label}
              </span>
              <span className="sorting-section">
                <i
                  className={ascClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'ASC' }); }}
                />
                <i
                  className={descClasses}
                  onClick={() => { sortSetManagementData({ sortBy: dataKey, sortDirection: 'DESC' }); }}
                />
              </span>
            </div>
          );
        },
        cellRenderer: ({ rowData }) => {
          const lastOpened = isAfter(startOfDay(new Date()), new Date(rowData.lastOpened)) ?
            formatDate(rowData.lastOpened) :
            formatDate(rowData.lastOpened, 'HH:mm');
          return (
            <span>{lastOpened}</span>
          );
        },
      },
      {
        dataKey: 'id',
        disableSort: true,
        width: screenWidth / 2,
        className: 'table-wrap__cell--left',
        cellRenderer: ({ rowData }) => {
          const iconIsHidden = (iconType) => {
            const getClassNames = value => classNames({
              'visually-hidden': value,
              'management-controls__item': true,
            });
            switch (iconType) {
              case iconsTypes.BUILD_HEATMAP: {
                return getClassNames(rowData.setType === 'COMPLEX' || rowData.conceptQuantity > MAX_RECORDS_FOR_BUILD_HEATMAP);
              }
              case iconsTypes.FILTER:
              case iconsTypes.ANALYZE:
              case iconsTypes.SET_RESULTS_PAGE:{
                return getClassNames(rowData.setType === 'COMPLEX');
              }
              case iconsTypes.DELETE:
              case iconsTypes.EDIT: {
                return getClassNames(this.projectsActionsAreDisabled());
              }
              case iconsTypes.DOWNLOAD: {
                return getClassNames(false);
              }
              case iconsTypes.SEARCH: {
                return getClassNames(
                  rowData.setType === 'COMPLEX' ||
                !rowData.conceptQuantity ||
                rowData.conceptQuantity > 5
                );
              }
              default: {
                return true;
              }}
          };

          return (
            <div className="management-controls">
              <div
                className="management-controls__item"
                title="Rank selection"
                onClick={() => {
                  this.goToRankSelectionPage(rowData);
                }}
              >
                <BiListUl size={28}/>
              </div>
              <div
                className="management-controls__item"
                title="BioProfiler by Enrichment Analysis"
                onClick={() => {
                  this.goToFindRelatedPage(rowData);
                }}
              >
                <AiOutlineNodeIndex size={28}/>
              </div>
              <div
                className={iconIsHidden(iconsTypes.SEARCH)}
                title="Go to search"
                onClick={() => {
                  redirectToSearch({setId: rowData.id, projectId});
                }}
              >
                <RiSearchLine size={28}/>
              </div>
              <div
                className={iconIsHidden(iconsTypes.BUILD_HEATMAP)}
                title="Build heatmap"
                onClick={() => {
                  this.goToBuildHeatmapPage(rowData);
                }}
              >
                <HiOutlineViewGridAdd size={28}/>
              </div>
              <div
                className={iconIsHidden(iconsTypes.ANALYZE)}
                title="Analyze set"
                onClick={() => {
                  this.analyzeSet(rowData);
                }}
              >
                <VennDiagramIcon/>
              </div>
              <div
                className={iconIsHidden(iconsTypes.SET_RESULTS_PAGE)}
                title="Add related concepts"
                onClick={() => {
                  this.goToSetResult(rowData.setType, rowData.name, rowData.id, projectId);
                }}
              >
                <GiSettingsKnobs size={28}/>
              </div>
              <div
                title="Download set"
                className={iconIsHidden(iconsTypes.DOWNLOAD)}
                onClick={() => {
                  this.downloadSet(rowData);
                }}
              >
                <FiDownload size={28}/>
              </div>
              <a //eslint-disable-line
                hidden={true}
                ref={(ref) => {
                  this.downloadLink = ref;
                }}
              />
              <div
                className={iconIsHidden(iconsTypes.EDIT)}
                title="Edit set"
                onClick={() => {
                  this.editSet(rowData.id);
                }}
              >
                <MdEdit size={28}/>
              </div>
              <div
                title="Copy set"
                className="management-controls__item"
                onClick={() => {
                  this.openConfirmCopyPopup(rowData);
                }}
              >
                <IoMdCopy size={28}/>
              </div>
              <div
                className={iconIsHidden(iconsTypes.DELETE)}
                title="Delete set"
                onClick={() => {
                  this.openConfirmDeletePopup(rowData);
                }}
              >
                <MdDelete size={28}/>
              </div>
            </div>
          );
        },
      },
    ];

    const redirectToTheProjectBool = checkIsReturnToThePage(locationHistory) && this.props.navigationType === 'POP';

    return (
      <div className="sets-management">
        {
          !error &&
          <div className="row sets-management__container m-0">
            <div className="management-container__buttons">
              <Button
                customClassName="management__create-button button-primary"
                disabled={this.projectsActionsAreDisabled()}
                onClick={this.addSet}
              >
                <IoMdAdd size={28} />
                Create
              </Button>
              <div className="management-buttons">
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={this.setActionIsDisabled(setsActionsTypes.INTERSECT)}
                  onClick={this.goToSearchWithSetsPage}
                >
                  <BiIntersect size={26} />
                  Search with Sets
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={this.setActionIsDisabled(setsActionsTypes.VENN)}
                  onClick={this.goToVennDiagram}
                >
                  <VennDiagramIcon />
                  Venn Diagram
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={this.setActionIsDisabled(setsActionsTypes.COMPARE)}
                  onClick={this.goToCompareSets}
                >
                  <FcHeatMap size={26} className="heatmap-icon" />
                  Heatmap
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button mr-5"
                  disabled={this.setActionIsDisabled(setsActionsTypes.COPY)}
                  onClick={() => this.openConfirmCopyPopup(null, true)}
                >
                  <IoMdCopy size={26} />
                  Copy
                </Button>
                <Button
                  customClassName="button-secondary management-buttons__button"
                  disabled={this.setActionIsDisabled(setsActionsTypes.DELETE)}
                  onClick={this.openConfirmDeletePopup}
                >
                  <MdDelete size={26} />
                  Delete
                </Button>
              </div>
            </div>
            <ProjectsTabs
              activeProjectId={projectId}
              redirectedProjectId={location.state?.redirectedProjectId}
              redirectToTheProject={redirectToTheProjectBool}
              setActiveProjectId={setActiveProjectId}
              clearActiveProjectId={clearActiveProjectId}
            />
            {
              sets !== null &&
              <Measure offset={true}>
                {
                  ({ measureRef, contentRect }) => (
                    <div className="sets-info" ref={measureRef}>
                      {
                        !!contentRect.offset.width && !!contentRect.offset.height &&
                        <SimpleTable
                          innerRef={this.simpleTableRef}
                          settings={tableSettings}
                          width={contentRect.offset.width}
                          height={contentRect.offset.height}
                          fixedHeight={true}
                          manageable={false}
                          data={sets}
                          actions={this.actions}
                          onlySelect={true}
                          sortAction={ACTIONS.sortSetManagementDataAction}
                          columns={columns}
                          noRowsMsg="There is no data to display"
                        />
                      }
                    </div>
                  )
                }
              </Measure>
            }
          </div>
        }
        <Loader
          isLoading={loading && !error}
          withOverlay={true}
        />
        <Error
          show={!loading && error}
          title="Sorry, error occurred"
          error={error}
        />
        <ConfirmationDialog
          onConfirm={this.removeSetManagementDataItem}
          onCancel={this.closeConfirmDeletePopup}
          isOpen={showConfirmDeletePopup}
          closeCb={this.closeConfirmDeletePopup}
          text={
            setIdsToDelete.length === 1 ?
              'Are you sure you want to remove set?' :
              `Are you sure you want to remove ${setIdsToDelete.length} sets?`
          }
        />
        <CopySetsModal
          isOpen={showConfirmCopyPopup}
          activeProjectId={projectId}
          onConfirm={this.duplicateSetManagement}
          onCancel={this.closeConfirmCopyPopup}
          multiselect={copySets.multiselect}
          initialValues={{ name: copySets.name || '' }}
        />
        <FreeAccountLimitationDialog entity="set" />
        <InfoDialog
          modalClassName="modal_no-paddings"
          isOpen={showHeatMapRecordsPopup}
          closeCb={this.closeHeatMapRecordsPopup}
          onConfirm={this.closeHeatMapRecordsPopup}
          text={
            'There are too many records for comparing.\n Please choose a couple of sets with ' +
            'lower amount of concepts.\n Result of the multiplication operation of chosen set\'s ' +
            'concepts can\'t be more than 100 000.'
          }
        />
        <RankSelectionParamsModal
          isOpen={showRankParamsModal}
          closeCb={this.toggleRankParamsDialog}
        />
      </div>
    );
  }
}

SetsManagement.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sets: SELECTORS.getSetsManagementDataSelector(state),
    filters: SELECTORS.getSetsManagementDataFiltersSelector(state),
    sorting: SELECTORS.getSetsManagementDataSortingSelector(state),
    loading: SELECTORS.getSetsManagementLoadingSelector(state),
    error: SELECTORS.getSetsManagementErrorSelector(state),
    checkedSets: SELECTORS.getCheckedSetsSelector(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
    set: SELECTORS.getFilteringSet(state),
    isFreeUser: isFreeUserSelector(state),
    isProjectManagerUser: isProjectManagerRightSelector(state),
    isProjectLockedForUsers: SELECTORS.getIsProjectLockedForUsersSelector(state),
    isProjectLocked: SELECTORS.getIsProjectLockedSelector(state),
    isProjectPublic: SELECTORS.getIsProjectPublicSelector(state),
    locationHistory: getLocationHistorySelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShouldClearAllSetsFlagToFalse() {
      dispatch(setShouldClearAllSetsFlagToFalseAction());
    },
    clearAllSets() {
      dispatch(clearAllSetsAction());
    },
    resetLastSavedAnalyticsId() {
      dispatch(resetLastSavedAnalyticsIdAction());
    },
    setCurrentSetIdForSetResult(setId) {
      dispatch(setCurrentSetIdForSetResultAction(setId));
    },
    getSetManagementData(data) {
      dispatch(ACTIONS.getSetManagementDataAction(data));
    },
    removeSetManagementDataItem(data) {
      dispatch(ACTIONS.removeSetManagementDataItemAction(data));
    },
    setResultReset() {
      dispatch(setResultResetAction());
    },
    selectSetForAnalysis(data) {
      dispatch(selectSet(data));
    },
    filterSetManagementData(data) {
      dispatch(ACTIONS.filterSetManagementDataAction(data));
    },
    sortSetManagementData(sorting) {
      dispatch(ACTIONS.sortSetManagementDataAction(sorting));
    },
    selectSetManagementDataItemForAnalysis(set) {
      dispatch(ACTIONS.selectSetManagementDataItemForAnalysisAction(set));
    },
    resetSetManagementData() {
      dispatch(ACTIONS.resetSetManagementDataAction());
    },
    duplicateSets(id) {
      dispatch(ACTIONS.duplicateSetsAction(id));
    },
    getSet(data) {
      dispatch(ACTIONS.getSetForFilteringAction(data));
    },
    resetSet() {
      dispatch(ACTIONS.resetSetForFilteringAction());
    },
    downloadSet(data) {
      dispatch(ACTIONS.downloadSetDataAction(data));
    },
    showFreeAccountLimitDialog() {
      dispatch(showFreeAccountLimitDialogAction());
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setSetManagementActiveProjectIdAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearSetManagementActiveProjectIdAction());
    },
    redirectToSearch(data) {
      dispatch(ACTIONS.setManagementSearchRedirectAction(data));
    },
    setSelectedSetInfo(data) {
      dispatch(setSelectedSetInfoAction(data));
    },
    setRankingSelectedSetInfo(data) {
      dispatch(RANK_ACTIONS.setSelectedSetInfoAction(data));
    },
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
    goToSetResult(data) {
      dispatch(goToSetResultSetAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetsManagement));
