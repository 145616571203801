import { createSelector } from 'reselect';

import { filterPaginatedData, sort } from '../../Utils/Utils';

import { PAGE_SIZE } from './constants';

const proteinLocationData = state => state.getIn(['geneDetailsProteinLocationChapter', 'data', 'proteinLocations']);
const proteinLocationImageTags = state => state.getIn(['geneDetailsProteinLocationChapter', 'data', 'imageTags']);
const proteinLocationLoading = state => state.getIn(['geneDetailsProteinLocationChapter', 'loading']);
const proteinLocationError = state => state.getIn(['geneDetailsProteinLocationChapter', 'error']);
const proteinLocationSorting = state => state.getIn(['geneDetailsProteinLocationChapter', 'sorting']);
const proteinLocationPage = state => state.getIn(['geneDetailsProteinLocationChapter', 'page']);
const proteinLocationTotalPages = state => state.getIn(['geneDetailsProteinLocationChapter', 'totalPages']);

export const getProteinLocationDataSelector = createSelector(
  proteinLocationData,
  proteinLocationSorting,
  proteinLocationPage,
  (data, sorting, page) => {
    const sortedData = sort(data, sorting.toJS());
    const paginatedData = filterPaginatedData(sortedData.toJS(), page, PAGE_SIZE);
    return paginatedData;
  }
);

export const getAllProteinLocationDataSelector = createSelector(
  proteinLocationData,
  proteinLocationSorting,
  proteinLocationPage,
  (data, sorting) => sort(data, sorting.toJS()).toJS()
);

export const getProteinLocationImageTagsSelector = createSelector(
  proteinLocationImageTags,
  data => data && data.toJS()
);

export const getProteinLocationSortingSelector = createSelector(
  proteinLocationSorting,
  data => data && data.toJS()
);

export const getProteinLocationPageSelector = createSelector(
  proteinLocationPage,
  data => data
);

export const getProteinLocationTotalPagesSelector = createSelector(
  proteinLocationTotalPages,
  data => data
);

export const getProteinLocationLoadingSelector = createSelector(
  proteinLocationLoading,
  data => data
);

export const getProteinLocationErrorSelector = createSelector(
  proteinLocationError,
  data => data
);
