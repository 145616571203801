export const GENES_DETAIL_OPEN = 'GENES_DETAIL_OPEN';
export const GENES_DETAIL_CLOSE = 'GENES_DETAIL_CLOSE';
export const GENES_DETAIL_REQUESTED = 'GENES_DETAIL_REQUESTED';
export const GENES_DETAIL_SUCCEEDED = 'GENES_DETAIL_SUCCEEDED';
export const GENES_DETAIL_FAILED = 'GENES_DETAIL_FAILED';
export const GENES_DETAIL_TABLE = 'GENES_DETAIL_TABLE_';
export const GENE_DETAIL_SHORT_CONCEPTS_DETAILS_REQUESTED = 'GENE_DETAIL_SHORT_CONCEPTS_DETAILS_REQUESTED';
export const GENE_DETAIL_SHORT_CONCEPTS_DETAILS_SUCCEEDED = 'GENE_DETAIL_SHORT_CONCEPTS_DETAILS_SUCCEEDED';
export const GENE_DETAIL_SHORT_CONCEPTS_DETAILS_FAILED = 'GENE_DETAIL_SHORT_CONCEPTS_DETAILS_FAILED';


export const CONCEPT_DETAILS_GENES_DETAIL_REQUESTED = 'CONCEPT_DETAILS_GENES_DETAIL_REQUESTED';
export const SAVE_NEW_SET_FROM_ANALYTICS = 'SAVE_NEW_SET_FROM_ANALYTICS';
