export const SET_PATH_FOR_PATH_DETAILS = 'SET_PATH_FOR_PATH_DETAILS';

export const PATH_DETAILS_REQUESTED = 'PATH_DETAILS_REQUESTED';
export const PATH_DETAILS_SUCCEEDED = 'PATH_DETAILS_SUCCEEDED';
export const PATH_DETAILS_FAILED = 'PATH_DETAILS_FAILED';

export const PATH_DETAILS_TABLE = 'PATH_DETAILS_TABLE_';
export const GENE_LITERATURE_TABLE = 'GENE_LITERATURE_TABLE_';

export const PATH_DETAILS_RESET = 'PATH_DETAILS_RESET';
