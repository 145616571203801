import { createSelector } from 'reselect';
import { CELL_LINES_CONFIGURATION_FORM } from '../constants';
import { PAGE_SIZE } from '../../../../Sets/SetsList/constants';
import {filterPaginatedData, sort} from '../../../../Utils/Utils';

const genesData = state => state.getIn(['cellLineSelectionReducer', 'genes']);
const cellLinesData = state => state.getIn(['cellLineSelectionReducer', 'cellLinesData']);
const cellLinesDataLoading = state => state.getIn(['cellLineSelectionReducer', 'loading']);
const cellLinesNoData = state => state.getIn(['cellLineSelectionReducer', 'cellLinesNoData']);
const activeProjectId = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'activeProjectId']);
const сellLinesConfigurationsLoading = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'loading']);
const сellLinesConfigurationsError = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'error']);
const cellLinesConfigurations = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'data']);
const cellLinesConfigurationsPageNumber = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'pages', 'pageNumber']);
const cellLinesConfigurationsTotalPages = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'pages', 'totalPages']);
const cellLinesConfigurationsFilter = state => state.getIn(['cellLineSelectionReducer', 'cellLinesConfigurationsModal', 'filterValue']);
const deleteCellLinesConfigurationModalIsOpen = state => state.getIn(['cellLineSelectionReducer', 'deleteCellLinesConfigurationModal', 'isOpen']);
const resultsData = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'data']);
const resultsDataLoading = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'loading']);
const resultsDataError = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'error']);
const resultsDataPage = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'page']);
const resultsDataTotalPages = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'totalPages']);
const resultsDataSorting = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'sorting']);
const heatmapData = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'heatmap']);
const chartScale = state => state.getIn(['cellLineSelectionReducer', 'resultsData', 'chartScale']);
const values = state => state.getIn(['form', CELL_LINES_CONFIGURATION_FORM, 'values']);

export const getGenesDataSelector = createSelector(
  genesData,
  data => data && data.toJS()
);

export const getSelectedGenesSelector = createSelector(
  [getGenesDataSelector],
  data => ({
    firstGene: data.firstGene.value,
    secondGene: data.secondGene.value,
  })
);

export const getSelectedSetSelector = createSelector(
  [getGenesDataSelector],
  data => data.genesSet
);

export const getIsTwoGenesSelectedSelector = createSelector(
  [getSelectedGenesSelector],
  data => Object.values(data).every(i => !!i)
);

export const getCellLinesDataSelector = createSelector(
  cellLinesData,
  data => data && data.toJS()
);

export const getShowNoDataMesssageSelector = createSelector(
  cellLinesNoData,
  data => data
);

export const getSelectedCellLinesGroupsSelector = createSelector(
  [getCellLinesDataSelector],
  data => {
    if (data?.length) return [];
    const groups = Object.keys(data);
    return groups.reduce((acc, item) => {
      if (data[item].every( i => i.selected)) acc = [...acc, item];
      return acc;
    }, []);
  }
);

export const getSelectedCellLinesSelector = createSelector(
  [getCellLinesDataSelector],
  data => {
    if (data?.length) return [];
    return Object.values(data).flat().filter(i => i.selected);
  }
);

export const getCellLinesDataLoadingSelector = createSelector(
  cellLinesDataLoading,
  data => data
);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);

export const getCellLinesConfigurationsLoadingSelector = createSelector(
  сellLinesConfigurationsLoading,
  data => data
);

export const getCellLinesConfigurationsErrorSelector = createSelector(
  сellLinesConfigurationsError,
  data => data
);

export const getCellLinesConfigurationsSelector = createSelector(
  cellLinesConfigurations,
  cellLinesConfigurationsFilter,
  cellLinesConfigurationsPageNumber,
  cellLinesConfigurationsTotalPages,
  (_data, filter, page, totalPages) => {

    const data = _data && _data.toJS();
    let filteredData = data;
    let newTotalPages = totalPages;

    if (filter) {
      const filterValueLow = filter.toLowerCase();
      filteredData = data.filter(item => (
        item.name?.toLowerCase().includes(filterValueLow)
      ));
      newTotalPages = Math.ceil(filteredData.length / PAGE_SIZE);
    }

    const newData = filteredData && filterPaginatedData(filteredData, page, PAGE_SIZE);

    return {
      page,
      data: newData,
      totalPages: newTotalPages,
    };
  }
);

export const getCellLinesConfigurationsFilterSelector = createSelector(
  cellLinesConfigurationsFilter,
  data => data
);

export const getDeleteCellLinesConfigurationModalIsOpenSelector = createSelector(
  deleteCellLinesConfigurationModalIsOpen,
  data => data
);

export const getFormValuesSelector = createSelector(
  values,
  data => data && data.toJS()
);

export const getResultsDataSortingSelector = createSelector(
  resultsDataSorting,
  data => data && data.toJS()
);

export const getAllResultsDataSelector = createSelector(
  resultsData,
  resultsDataSorting,
  (data, sorting) => {
    if(!data) return data;
    const sortedData = sort(data, sorting.toJS());
    return sortedData.toJS();
  }
);

export const getHeatmapDataSelector = createSelector(
  heatmapData,
  (data) => {
    if(!data) return data;
    return data.toJS();
  }
);

export const getPaginatedResultsDataSelector = createSelector(
  resultsData,
  resultsDataPage,
  resultsDataSorting,
  (data, page, sorting) => {
    if(!data) return data;
    const sortedData = sort(data, sorting.toJS());
    return filterPaginatedData(sortedData.toJS(), page, PAGE_SIZE);
  }
);

export const getResultsDataLoadingSelector = createSelector(
  resultsDataLoading,
  data => data
);

export const getResultsDataErrorSelector = createSelector(
  resultsDataError,
  data => data
);

export const getResultsDataPageSelector = createSelector(
  resultsDataPage,
  data => data
);

export const getResultsDataTotalPagesSelector = createSelector(
  resultsDataTotalPages,
  data => data
);

export const getChartScale = createSelector(
  chartScale,
  data => data
);

function getOrTransform2Log(value, scale) {
  if (scale === 'linear')
    return value;
  else
    return +Math.log2(+value + 1).toFixed(2);
}

export const getDataForDoubleGraphsSelector = createSelector(
  [
    resultsData,
    genesData,
    chartScale,
  ], (_data, _genes, scale) => {
    const data = _data && _data.toJS();
    const genes = _genes && _genes.toJS();
    const result = {rna: [], protein: [], sanger: []};

    if (!genes.firstGene.value) return;

    data.forEach(row => {
      if (row.measures.GENE1.rna || row.measures.GENE2.rna) {
        const rnaObj = {
          cancer: row.cancer.name,
          value: row.cellLine.name,
        };
        rnaObj[genes.firstGene.value.name] = getOrTransform2Log(row.measures.GENE1.rna, scale);
        if (genes.secondGene.value)
          rnaObj[genes.secondGene.value.name] = getOrTransform2Log(row.measures.GENE2.rna, scale);
        result.rna.push(rnaObj);
      }
      if (row.measures.GENE1.protein || row.measures.GENE2.protein) {
        const rnaObj = {
          cancer: row.cancer.name,
          value: row.cellLine.name,
        };
        rnaObj[genes.firstGene.value.name] = getOrTransform2Log(row.measures.GENE1.protein, scale);
        if (genes.secondGene.value)
          rnaObj[genes.secondGene.value.name] = getOrTransform2Log(row.measures.GENE2.protein, scale);
        result.protein.push(rnaObj);
      }
      if (row.measures.GENE1.sanger || row.measures.GENE2.sanger) {
        const rnaObj = {
          cancer: row.cancer.name,
          value: row.cellLine.name,
        };
        rnaObj[genes.firstGene.value.name] = getOrTransform2Log(row.measures.GENE1.sanger, scale);
        if (genes.secondGene.value)
          rnaObj[genes.secondGene.value.name] = getOrTransform2Log(row.measures.GENE2.sanger, scale);
        result.sanger.push(rnaObj);
      }
    });

    result.rna.sort(function(a, b) { return b[genes.firstGene.value.name] - a[genes.firstGene.value.name]; });
    result.protein.sort(function(a, b) { return b[genes.firstGene.value.name] - a[genes.firstGene.value.name]; });
    result.sanger.sort(function(a, b) { return b[genes.firstGene.value.name] - a[genes.firstGene.value.name]; });

    return result;
  }
);
