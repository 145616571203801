import { fromJS } from 'immutable';

import { PAGE_SIZE } from '../../constants';

import { types } from './constants';

const initialState = fromJS({
  loading: false,
  error: null,
  diagram: {
    data: [],
    chords: [],
    color: '',
  },
  adverseDrugs: {
    data: [],
    totalPages: 0,
    pageNumber: 0,
  },
  humanPhenotypes: {
    data: [],
    totalPages: 0,
    pageNumber: 0,
  },
  animalPhenotypes: {
    data: [],
    totalPages: 0,
    pageNumber: 0,
  },
});

const phenotypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PHENOTYPES_DATA: {
      const {
        data,
        chords,
        genesColor,
        adverseDrugs,
        humanPhenotypes,
        animalPhenotypes,
      } = action.data.data;

      return state.merge(fromJS(
        {
          diagram: {
            data: data.sort((i1, i2) => i1.gene.color.localeCompare(i2.gene.color)),
            chords,
            color: genesColor,
          },
          adverseDrugs: {
            data: adverseDrugs,
            pageNumber: 0,
            totalPages: Math.ceil((adverseDrugs || []).length / PAGE_SIZE),
          },
          humanPhenotypes: {
            data: humanPhenotypes,
            pageNumber: 0,
            totalPages: Math.ceil((humanPhenotypes || []).length / PAGE_SIZE),
          },
          animalPhenotypes: {
            data: animalPhenotypes,
            pageNumber: 0,
            totalPages: Math.ceil((animalPhenotypes || []).length / PAGE_SIZE),
          },
          loading: false,
          error: null,
        }
      ));
    }

    case types.TOGGLE_PHENOTYPES_LOADER: {
      const { data } = action;

      return state.merge(fromJS(
        {
          loading: data,
          error: null,
        }
      ));
    }

    case types.PHENOTYPES_IS_FAILED: {
      const { message } = action;

      return state.merge(fromJS(
        {
          loading: false,
          error: message,
        }
      ));
    }

    case types.PHENOTYPES_ADVERSE_DRUGS_CHANGE_PAGE: {
      const { data } = action;

      return state.update('adverseDrugs', adverseDrugs => (
        adverseDrugs.set('pageNumber', data)
      ));
    }

    case types.PHENOTYPES_ANIMAL_MODELS_CHANGE_PAGE: {
      const { data } = action;

      return state.update('animalPhenotypes', animalPhenotypes => (
        animalPhenotypes.set('pageNumber', data)
      ));
    }

    case types.HUMAN_PHENOTYPES_CHANGE_PAGE: {
      const { data } = action;

      return state.update('humanPhenotypes', humanPhenotypes => (
        humanPhenotypes.set('pageNumber', data)
      ));
    }

    default:
      return state;
  }
};

export default phenotypesReducer;
