import {
  specialTissues,
} from './enum';

export const getHighlightedTissuesIdsByPubsCount = (data = {}) => {
  const tissuesSources = Object.keys(data);
  let allTissues = [];

  tissuesSources.forEach((tissuesSource) => {
    if (typeof data[tissuesSource][0] === 'object') {
      allTissues = [...allTissues, ...data[tissuesSource]];
    }
  });

  if (allTissues.length === 0) {
    return false;
  }

  const unique = {};
  const allTissuesDistinct = [];
  allTissues.forEach((x) => {
    if (!unique[x.id]) {
      allTissuesDistinct.push(x);
      unique[x.id] = true;
    }
  });

  const sortedAllTissues = allTissuesDistinct.filter(item => item.id).sort((i, ii) => {
    if (i.diseasePubs > ii.diseasePubs) {
      return -1;
    }
    if (i.diseasePubs < ii.diseasePubs) {
      return 1;
    }
    return 0;
  });

  let previousPubsCount = null;

  const topThreeTissues = sortedAllTissues.slice(0, 3);

  if (topThreeTissues[0].name === 'brain') {
    return topThreeTissues.filter((item, index) => { //eslint-disable-line
      if (index === 0) {
        return true;
      }
      if ((specialTissues.includes(item.name)) && item.diseasePubs > 0) {
        return true;
      }
    })
      .map(item => item.id);
  }

  return topThreeTissues
    .filter((item, index) => {
      const match = index === 0 || item.diseasePubs > previousPubsCount * 0.1;
      if (match) {
        previousPubsCount = item.diseasePubs;
      }
      return match;
    })
    .map(item => item.id);
};
