import * as d3js from 'd3';
import { drawLegend } from '../LegendSvgComponent/drawLegend';
import { colors } from './colors';
import { calculatePercent } from '../../Utils/Utils';

export function drawPieChart(args) {
  const {
    htmlRoot,
    data,
    showTitle,
    chartTitle,
    chartName,
    geneName,
    antibodySum,
  } = args;

  const config = {
    width: 400,
    height: 500,
    margin: 40,
    tooltipWidth: 200,
  };

  let {
    width,
    height,
  } = config;

  const radius = (Math.min(width, height) / 2) - config.margin;
  const bodyHtml = document.querySelector('body');

  d3js.select(`#${htmlRoot} svg`).remove();

  const tooltipTextTemplate = (d) => {
    const percent = calculatePercent(antibodySum, d.value);
    return (`
      <div class="chart-tooltip__content">
        <span><b>${d.key}</b></span><br/>
        <span>Percent: <b>${percent}%</b></span><br/>
        <span>Number of antibodies: <b>${d.value}</b></span><br/>
      </div>
    `);
  };
  const tooltip = d3js.select('body')
    .append('div')
    .style('opacity', 0)
    .attr('class', `chart-tooltip chart-tooltip_${chartName}`);

  function mouseOver() {
    tooltip
      .style('opacity', 1);
  }

  function mouseMoveBoxPlot(d) {
    tooltip
      .html(tooltipTextTemplate(d.data))
      .style('left', `${d3js.mouse(bodyHtml)[0]}px`)
      .style('top', `${d3js.mouse(bodyHtml)[1]}px`);
  }

  function mouseLeave() {
    tooltip
      .style('opacity', 0);
  }

  const svg = d3js.select(`#${htmlRoot}`)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`);

  if (showTitle) {
    width += 250;
    height += 100;
    d3js.select(`#${htmlRoot}`)
      .select('svg')
      .attr('width', width)
      .attr('height', height)
      .insert('text', ':first-child')
      .text(`${chartTitle} for ${geneName}`)
      .attr('style', 'font: 22px sans-serif;transform: translate(50%, 10%);text-anchor: middle;');

    d3js.select(`#${htmlRoot} svg`)
      .select('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Legend
    const SVG = d3js.select(`#${htmlRoot} svg`)
      .append('g')
      .attr('transform', `translate(0, ${height - 200})`);
    const keys = Object.keys(data);
    drawLegend({
      element: SVG,
      keys,
      colors,
    });
  }
  const color = d3js.scaleOrdinal()
    .domain(data)
    .range(colors);

  const pie = d3js.pie()
    .value(d => d.value);
  const transformedData = pie(d3js.entries(data));

  svg
    .selectAll()
    .data(transformedData)
    .enter()
    .append('path')
    .attr('d', d3js.arc()
      .innerRadius(0)
      .outerRadius(radius)
    )
    .attr('fill', d => color(d.data.key))
    .attr('stroke', 'black')
    .style('stroke-width', '2px')
    .style('opacity', 0.7)
    .on('mouseover', mouseOver)
    .on('mousemove', mouseMoveBoxPlot)
    .on('mouseleave', mouseLeave);
}

