import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import BispecificAntibody from './Algorithms/BispecificAntibody/BispecificAntibody';
import SingleTargetForCancer from './Algorithms/SingleTargetForCancer/SingleTargetForCancer';
import SingleCellStudyViewer from './Algorithms/SingleCellStudyViewer/SingleCellStudyViewer';
import SelectInput from '../common/Inputs/SelectInput/SelectInput';
import IndicationFinder from './Algorithms/IndicationFinder/IndicationFinder';
import CellLineSelection from './Algorithms/CellLineSelection/CellLineSelection';
// Store
import {
  resetTargetCandidatesPairsDataAction,
  targetCandidatesPairsClearChartsDataAction,
} from './Algorithms/BispecificAntibody/store/reducer';
import {
  selectWorkflowsAlgorithmAction,
  resetWorkflowsAlgorithmAction,
} from './store/reducer';
import {
  getTargetCandidatesSelectedAlgorithmSelector,
  getTargetCandidatesErrorSelector,
} from './store/selectors';
import { getWorkflowsAlgorithms } from '../Header/selectors';
// Constants
import { algorithmOptions, pageAlgorithmEnums } from './constants';
// Utils
import { getLocationParams } from '../Utils/Utils';
// Styles
import './Workflows.scss';

const propTypes = {
  algorithms: PropTypes.instanceOf(Array),
  selectAlgorithm: PropTypes.func,
  selectedAlgorithm: PropTypes.instanceOf(Object),
  resetWorkflowsAlgorithm: PropTypes.func,
  resetTargetCandidatesPairsData: PropTypes.func,
  error: PropTypes.string,
};

const Workflows = (props) => {
  const {
    algorithms,
    selectAlgorithm,
    selectedAlgorithm,
    resetWorkflowsAlgorithm,
    resetTargetCandidatesPairsData,
    error,
  } = props;
  const location = useLocation();
  const urlParams = getLocationParams(location.search);

  useEffect(() => {
    if (urlParams.geneId) {
      selectAlgorithm(algorithmOptions.find(item => item.type === pageAlgorithmEnums.singleCellStudyViewer));
    } else if (urlParams.algorithm) {
      selectAlgorithm(algorithmOptions.find(item => item.type === urlParams.algorithm));
    }
  }, []);

  useEffect(() => () => {
    if (error) {
      resetWorkflowsAlgorithm();
      resetTargetCandidatesPairsData();
      targetCandidatesPairsClearChartsDataAction();
    } else {
      resetTargetCandidatesPairsData();
      targetCandidatesPairsClearChartsDataAction();
    }
  });

  function renderAlgorithm() {
    const { type } = selectedAlgorithm || {};
    switch (type) {
      case pageAlgorithmEnums.biSpecificAntibody:
        return <BispecificAntibody selectedAlgorithmOption={selectedAlgorithm} />;

      case pageAlgorithmEnums.singleTargetForCancer:
        return <SingleTargetForCancer />;

      case pageAlgorithmEnums.singleCellStudyViewer:
        return <SingleCellStudyViewer urlParams={urlParams} />;

      case pageAlgorithmEnums.indicationFinder:
        return <IndicationFinder />;

      case pageAlgorithmEnums.cellLineSelection:
        return <CellLineSelection urlParams={urlParams} />;

      default:
        return null;
    }
  }

  const availableSelectAlgorithms = algorithmOptions.filter(
    option => algorithms.find(algorithm => algorithm.name === option.type)
  );

  // console.log('algorithmOptions', algorithmOptions, 'algorithms', algorithms);
  return (
    <div className="workflows">
      <div className="workflows__head">
        <h2 className="workflows__h1 title">
          Workflows
        </h2>
        <p className="workflows__text text-center">
          Select Workflows method for your research
        </p>
        <form className="workflows__algorithms-form">
          {
            algorithms.length > 0 ?
              <SelectInput
                defaultValue={selectedAlgorithm}
                options={availableSelectAlgorithms}
                onSelect={selectAlgorithm}
                placeholder="Select algorithm"
                closeOnSelect={true}
                bordered={true}
                onSelectResetsInput={true}
              /> :
              <div className="form__input-mock" />
          }
        </form>
      </div>
      <div className="workflows__body">
        {renderAlgorithm()}
      </div>
    </div>
  );
};

Workflows.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    algorithms: getWorkflowsAlgorithms(state),
    selectedAlgorithm: getTargetCandidatesSelectedAlgorithmSelector(state),
    error: getTargetCandidatesErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectAlgorithm(state) {
      dispatch(selectWorkflowsAlgorithmAction(state));
    },
    resetWorkflowsAlgorithm() {
      dispatch(resetWorkflowsAlgorithmAction());
    },
    resetTargetCandidatesPairsData() {
      dispatch(resetTargetCandidatesPairsDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Workflows);
