import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
// Utils
import { RELATIVE_PATH } from '../../constantsCommon';
import { manageableScrollInToView, checkGenmabAccount } from '../Utils/Utils';
import { withRouter } from '../common/WithRouter/WithRouter';
// Components
import Error from '../common/Error/Error';
import Loader from '../common/Loader/Loader';
import CreateSet from '../Sets/CreateSet/CreateSet';
import ModalComponent from '../ModalComponent/ModalComponent';
import ProteinLocationChapter from './ProteinLocationChapter/ProteinLocationChapter';
import ProteinStructureChapter from './ProteinStructure/ProteinStructureChapter';
import GeneExpressionChapter from './GeneExpressionChapter/GeneExpressionChapter';
import FunctionalCharacterizationChapter from './FunctionalCharacterizationChapter/FunctionalCharacterizationChapter';
import PathwaysChapter from './PathwaysChapter/PathwaysChapter';
import PhenotypesChapter from './PhenotypesChapter/PhenotypesChapter';
import DiseaseDisordersChapter from './DisordersChapter/DiseaseChapter/DiseaseDisordersChapter';
import CancerDisordersChapter from './DisordersChapter/CancerChapter/CancerDisordersChapter';
import ConditionOfDysregulationChapter from './ConditionsOfDysregulationChapter/ConditionOfDysregulationChapter';
import TherapeuticCandidatesChapter from './TherapeuticCandidatesChapter/TherapeuticCandidatesChapter';
import PatentsChapter from './Patents/PatentsChapter';
import ClinicalTrialsChapter from './ClinicalTrialsChapter/ClinicalTrialsChapter';
import LiteratureSearch from './LiteratureSearchChapter/LiteratureSearchChapter';
import ExternalLinksChapter from './ExternalLinksChapter/ExternalLinksChapter';
import AntibodiesChapter from './AntibodiesChapter/AntibodiesChapter';
import DefinitionChapter from './DefinitionChapter/DefinitionChapter';
import SimilarProteinsChapter from './SimilarProteinsChapter/SimilarProteinsChapter';
import OrthologsChapter from './OrthologsChapter/OrthologsChapter';
import SelectGene from '../common/SelectGene/SelectGene';
import InternalLinksChapter from './InternalLinksChapter/InternalLinksChapter';
// Icons
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
// Store
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
import { getUserName, isSingleCellAllowedSelector } from '../Header/selectors';
import { getGeneExpressionDataExistSelector } from './GeneExpressionChapter/selectors';
// Styles
import './GeneDetails.scss';

const propTypes = {
  concept: PropTypes.instanceOf(Object),
  reset: PropTypes.func,
  geneDetailsRequest: PropTypes.func,
  locationParams: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  setLastVisitedConceptId: PropTypes.func,
  lastVisitedConceptId: PropTypes.string,
  pageChapters: PropTypes.instanceOf(Array),
  loadNextChapter: PropTypes.func,
  unloadChapters: PropTypes.func,
  pageChaptersData: PropTypes.instanceOf(Array),
  navigate: PropTypes.func,
  userName: PropTypes.string,
  isSingleCellAllowed: PropTypes.bool,
  geneExpressionDataExist: PropTypes.instanceOf(Object),
};

class GeneDetails extends React.Component {
  constructor(props) {
    super(props);
    this.itsNewConcept = props.error ?
      true :
      props.locationParams.conceptId !== props.lastVisitedConceptId;
    this.state = {
      showInfoText: false,
      createSetPopup: {
        show: false,
        config: null,
      },
      geneExpressionAccordion: {
        GENE_EXPRESSION: false,
        BULK_RNA: false,
        PROTEIN_EXPRESSION: false,
        SINGLE_CELL_RNA: false,
      },
    };
    this.initPage();
  }

  initPage = () => {
    const {
      reset,
      setLastVisitedConceptId,
      geneDetailsRequest,
      locationParams: {
        conceptId,
      },
      loadNextChapter,
    } = this.props;

    if (this.itsNewConcept) {
      reset();
      setLastVisitedConceptId(conceptId);
      geneDetailsRequest(conceptId);
    } else {
      loadNextChapter('literature-search');
    }
  };

  componentDidUpdate(prevProps) {
    const { locationParams: { conceptId } } = this.props;

    if (conceptId !== prevProps.locationParams.conceptId) {
      this.itsNewConcept = true;
      this.initPage();
    }
  }

  componentWillUnmount() {
    const { unloadChapters } = this.props;
    unloadChapters();
  }

  publicationDetails = (id) => {
    const { navigate } = this.props;
    navigate(`${RELATIVE_PATH}/publication-details/${id}`);
  };

  geneDetails = (id) => {
    const { navigate, geneDetailsRequest } = this.props;
    navigate(`${RELATIVE_PATH}/gene-details/${id}`);
    geneDetailsRequest(id);
  };

  toggleInfoText = () => {
    const { showInfoText } = this.state;
    this.setState({ showInfoText: !showInfoText });
  };

  toggleDefinitionInfoText = () => {
    const { showDefinitionInfoText } = this.state;
    this.setState({ showDefinitionInfoText: !showDefinitionInfoText });
  };

  toggleGeneExpressionAccordion = (e, key) => {
    e.stopPropagation();
    this.setState({
      geneExpressionAccordion: {
        ...this.state.geneExpressionAccordion,
        [key]: !this.state.geneExpressionAccordion[key]
      }
    });
  };

  openCreateSetPopup = (conceptIds, apiType, recommendationName) => {
    this.setState({
      createSetPopup: {
        show: true,
        config: {
          apiType,
          conceptIds,
          recommendationName,
          startConcepts: [{ id: conceptIds[0], name: recommendationName}],
        },
      },
    });
  };

  closeCreateSetPopup = () => {
    this.setState({
      createSetPopup: {
        show: false,
        props: null,
      },
    });
  };

  handleGeneSearch = ({ key }) => {
    this.props.navigate(`${RELATIVE_PATH}/gene-details/${key}`);
  };

  render() {
    const {
      concept,
      loading,
      error,
      pageChapters,
      userName,
      pageChaptersData,
      locationParams: { conceptId },
      geneExpressionDataExist,
      isSingleCellAllowed,
    } = this.props;

    const {
      showInfoText,
      createSetPopup,
      geneExpressionAccordion,
    } = this.state;

    const isGenmabAccount = checkGenmabAccount(userName);
    const isGeneBelongsToHuman = concept.taxonomies?.includes('homo sapiens');

    const semanticTypes = concept.semanticTypes ? concept.semanticTypes.map((type, index, array) => (
      <span key={index}>
        { index === 0 && ', ' }{ type.name }concept{ index !== (array.length - 1) && ', '}
      </span>
    )) : '';

    return (
      <div className="gene-details">
        {
          !loading && !error &&
          <>
            <div className="gene-details__sidebar">
              <div className="gene-details__sidebar-wrap">
                <SelectGene
                  clearFieldAfterSelect={true}
                  onSubmit={this.handleGeneSearch}
                />
                <div className="concept-details-menu">
                  <div className="concept-details-left-column-title title2">
                    Sections
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#conceptDefinition', '.concept-name_fixed', true); }}
                    className="concept-details-left-column-action"
                  >
                    Definition
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#literatureSearch', '.concept-name_fixed', true); }}
                    className={pageChapters[0].isLoaded && pageChaptersData[0].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Literature
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#protein-structure', '.concept-name_fixed', true); }}
                    className={pageChapters[1].isLoaded && pageChaptersData[1].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Protein Structure
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#similar-proteins', '.concept-name_fixed', true); }}
                    className={pageChapters[2].isLoaded && pageChaptersData[2].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Similar Proteins
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#orthologs', '.concept-name_fixed', true); }}
                    className={pageChapters[3].isLoaded && pageChaptersData[3].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Orthologs
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#protein-location', '.concept-name_fixed', true); }}
                    className={pageChapters[4].isLoaded && pageChaptersData[4].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Localization
                  </div>
                  <div className="concept-details__accordion-wrapper">
                    <div
                      className={classNames({
                        'concept-details-left-column-action': true,
                        'disabled': !pageChapters[5].isLoaded || !pageChaptersData[5].isDataExist
                      })}
                    >
                      <div
                        role="presentation"
                        onClick={() => { manageableScrollInToView('#gene-expression', '.concept-name_fixed', true); }}
                      >
                        Gene expression
                      </div>
                      {
                        geneExpressionAccordion.GENE_EXPRESSION ?
                          <HiMinusSm
                            size={20}
                            className="accordion__icon"
                            onClick={(e) => this.toggleGeneExpressionAccordion(e,'GENE_EXPRESSION')}
                          /> :
                          <HiPlusSm
                            size={20}
                            className="accordion__icon"
                            onClick={(e) => this.toggleGeneExpressionAccordion(e,'GENE_EXPRESSION')}
                          />
                      }
                    </div>
                    {
                      geneExpressionAccordion.GENE_EXPRESSION &&
                      <div>
                        <div
                          className={classNames({
                            'concept-details__accordion-item': true,
                            'disabled': !geneExpressionDataExist.BULK_RNA.GTEX && !geneExpressionDataExist.BULK_RNA.TCGA
                              && !geneExpressionDataExist.BULK_RNA.CCLE && !geneExpressionDataExist.BULK_RNA.HEALTH_CELL
                          })}
                        >
                          <div className="concept-details__accordion-header">
                            <div
                              role="presentation"
                              className="concept-details-accordion-header__text"
                              onClick={() => { manageableScrollInToView('#bulk-rna-expression', '.concept-name_fixed', true); }}
                            >
                            Bulk RNA expression
                            </div>
                            {
                              geneExpressionAccordion.BULK_RNA ?
                                <HiMinusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e,'BULK_RNA')}
                                /> :
                                <HiPlusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e,'BULK_RNA')}
                                />
                            }
                          </div>
                          {
                            geneExpressionAccordion.BULK_RNA &&
                          <ul className="concept-details__accordion-menu">
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.BULK_RNA.GTEX
                              })}
                              onClick={() => { manageableScrollInToView('#bulk-rna-expression__gtex', '.concept-name_fixed', true); }}
                            >
                              GTEX
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.BULK_RNA.TCGA
                              })}
                              onClick={() => { manageableScrollInToView('#bulk-rna-expression__tcga', '.concept-name_fixed', true); }}
                            >
                              TCGA
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.BULK_RNA.CCLE
                              })}
                              onClick={() => { manageableScrollInToView('#bulk-rna-expression__ccle', '.concept-name_fixed', true); }}
                            >
                              CCLE
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.BULK_RNA.HEALTH_CELL
                              })}
                              onClick={() => { manageableScrollInToView('#bulk-rna-expression__health_cell', '.concept-name_fixed', true); }}
                            >
                              Euretos cell type library
                            </li>
                          </ul>
                          }
                        </div>
                        <div
                          className={classNames({
                            'concept-details__accordion-item': true,
                            'disabled': !geneExpressionDataExist.SINGLE_CELL_RNA.HPA && (!isSingleCellAllowed || !isGeneBelongsToHuman)
                          })}
                        >
                          <div className="concept-details__accordion-header">
                            <div
                              role="presentation"
                              className="concept-details-accordion-header__text"
                              onClick={() => { manageableScrollInToView('#single-cell-rna', '.concept-name_fixed', true); }}
                            >
                            Single cell RNA expression
                            </div>
                            {
                              geneExpressionAccordion.SINGLE_CELL_RNA ?
                                <HiMinusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e, 'SINGLE_CELL_RNA')}
                                /> :
                                <HiPlusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e, 'SINGLE_CELL_RNA')}
                                />
                            }
                          </div>
                          {
                            geneExpressionAccordion.SINGLE_CELL_RNA &&
                          <ul className="concept-details__accordion-menu">
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.SINGLE_CELL_RNA.HPA
                              })}
                              onClick={() => { manageableScrollInToView('#single-cell-rna', '.concept-name_fixed', true); }}
                            >
                              Human protein atlas
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !isSingleCellAllowed || !isGeneBelongsToHuman
                              })}
                              onClick={() => { manageableScrollInToView('#single-cell-rna__cell-study-viewer', '.concept-name_fixed', true); }}
                            >
                              Single cell study viewer
                            </li>
                          </ul>
                          }
                        </div>
                        <div
                          className={classNames({
                            'concept-details__accordion-item': true,
                            'disabled': !geneExpressionDataExist.PROTEIN_EXPRESSION.HPM
                              && !geneExpressionDataExist.PROTEIN_EXPRESSION.CCLE_PROTEOMICS
                              && !geneExpressionDataExist.PROTEIN_EXPRESSION.SANGER_CELL_MODEL
                              && !geneExpressionDataExist.PROTEIN_EXPRESSION.CPTAC
                          })}
                        >
                          <div className="concept-details__accordion-header">
                            <div
                              role="presentation"
                              className="concept-details-accordion-header__text"
                              onClick={() => { manageableScrollInToView('#protein-expression', '.concept-name_fixed', true); }}
                            >
                            Protein expression
                            </div>
                            {
                              geneExpressionAccordion.PROTEIN_EXPRESSION ?
                                <HiMinusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e, 'PROTEIN_EXPRESSION')}
                                /> :
                                <HiPlusSm
                                  size={20}
                                  className="accordion__icon"
                                  onClick={(e) => this.toggleGeneExpressionAccordion(e, 'PROTEIN_EXPRESSION')}
                                />
                            }
                          </div>
                          {
                            geneExpressionAccordion.PROTEIN_EXPRESSION &&
                          <ul className="concept-details__accordion-menu">
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.PROTEIN_EXPRESSION.HPM
                              })}
                              onClick={() => { manageableScrollInToView('#protein-expression__hpm', '.concept-name_fixed', true); }}
                            >
                              Human Proteome Map
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.PROTEIN_EXPRESSION.CCLE_PROTEOMICS
                              })}
                              onClick={() => { manageableScrollInToView('#protein-expression__ccle', '.concept-name_fixed', true); }}
                            >
                              CCLE
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.PROTEIN_EXPRESSION.SANGER_CELL_MODEL
                              })}
                              onClick={() => { manageableScrollInToView('#protein-expression__sanger-cell-model', '.concept-name_fixed', true); }}
                            >
                              Sanger Cell Model Passports
                            </li>
                            <li
                              role="presentation"
                              className={classNames({
                                'concept-details__accordion-menu-item': true,
                                'disabled': !geneExpressionDataExist.PROTEIN_EXPRESSION.CPTAC
                              })}
                              onClick={() => { manageableScrollInToView('#protein-expression__cptac', '.concept-name_fixed', true); }}
                            >
                              CPTAC
                            </li>
                          </ul>
                          }
                        </div>
                      </div>
                    }
                  </div>

                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#condition-of-dysregulation', '.concept-name_fixed', true); }}
                    className={pageChapters[6].isLoaded && pageChaptersData[6].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Conditions of Dysregulation
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#functional-characterization', '.concept-name_fixed', true); }}
                    className={pageChapters[7].isLoaded && pageChaptersData[7].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Functional Characterization
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#pathways', '.concept-name_fixed', true); }}
                    className={pageChapters[8].isLoaded && pageChaptersData[8].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Pathways
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#phenotypes', '.concept-name_fixed', true); }}
                    className={pageChapters[9].isLoaded && pageChaptersData[9].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Phenotypes
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#disease-disorders', '.concept-name_fixed', true); }}
                    className={pageChapters[10].isLoaded && pageChaptersData[10].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Disease
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#cancer-disorders', '.concept-name_fixed', true); }}
                    className={pageChapters[10].isLoaded && pageChaptersData[10].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Cancer
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#therapeutic-candidates', '.concept-name_fixed', true); }}
                    className={pageChapters[11].isLoaded && pageChaptersData[12].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Therapeutic Candidates
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#patents', '.concept-name_fixed', true); }}
                    className={pageChapters[12].isLoaded && pageChaptersData[13].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Patents
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#clinical-trials', '.concept-name_fixed', true); }}
                    className={pageChapters[13].isLoaded && pageChaptersData[14].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Clinical Trials
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#antibodies', '.concept-name_fixed', true); }}
                    className={pageChapters[14].isLoaded && pageChaptersData[15].isDataExist ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    Antibodies
                  </div>
                  {
                    isGenmabAccount &&
                    <div
                      role="presentation"
                      onClick={() => { manageableScrollInToView('#internal-links', '.concept-name_fixed', true); }}
                      className={pageChapters[15].isLoaded ?
                        'concept-details-left-column-action' :
                        'concept-details-left-column-action disabled'
                      }
                    >
                      Internal Links
                    </div>
                  }
                  <div
                    role="presentation"
                    onClick={() => { manageableScrollInToView('#external-links', '.concept-name_fixed', true); }}
                    className={pageChapters[16].isLoaded ?
                      'concept-details-left-column-action' :
                      'concept-details-left-column-action disabled'
                    }
                  >
                    External Links
                  </div>
                </div>
              </div>
            </div>
            <div className="gene-details__content">
              <div className="concept-name-gene-details concept-name_fixed">
                <div className="concept-name-gene-details-wrapper">
                  <div className="concept-name-gene-details-title title">{ concept.name }</div>
                  <button
                    className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                    onClick={this.toggleInfoText}
                  />
                </div>
                {
                  showInfoText &&
              <div className="gene-details-info__main">
                <p>
                  The Gene Detail Page brings gene related information together from the many semantically integrated sources in the Euretos knowledge base.
                  It provides predominantly information only available in databases which the user would need to be aware of, visit individually and then retrieve one by one.
                  The information is divided in separate sections that can be accessed easily via the left hand side menu.
                </p>
              </div>
                }
              </div>
              <div className="concept-category">
                <span>{concept.semanticCategory}</span>
                {semanticTypes}
              </div>
              <div className="flex-grid">
                <div className="col-4">
                  <DefinitionChapter
                    synonyms={concept.synonyms}
                    taxonomies={concept.taxonomies}
                    identifiers={concept.identifiers}
                    classifications={concept.classifications}
                    relatedProteinDescription={concept.relatedProteinDescription}
                    description={concept.description}
                    genomicLocation={concept.genomicLocation}
                    openCreateSetPopup={this.openCreateSetPopup}
                  />
                </div>
              </div>
              <div id="literatureSearch">
                {
                  pageChapters[0].isLoaded &&
                  <LiteratureSearch
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="protein-structure">
                {
                  pageChapters[1].isLoaded &&
                  <ProteinStructureChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="similar-proteins">
                {
                  pageChapters[2].isLoaded &&
                  <SimilarProteinsChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="orthologs">
                {
                  pageChapters[3].isLoaded &&
                  <OrthologsChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="protein-location">
                {
                  pageChapters[4].isLoaded &&
                  <ProteinLocationChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="gene-expression">
                {
                  pageChapters[5].isLoaded &&
                  <GeneExpressionChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                    isSingleCellAllowed={isSingleCellAllowed}
                    isGeneBelongsToHuman={isGeneBelongsToHuman}
                  />
                }
              </div>
              <div id="condition-of-dysregulation">
                {
                  pageChapters[6].isLoaded &&
                  <ConditionOfDysregulationChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="functional-characterization">
                {
                  pageChapters[7].isLoaded &&
                  <FunctionalCharacterizationChapter
                    gene={conceptId}
                    itsNewConcept={this.itsNewConcept}
                    geneName={concept.name}
                  />
                }
              </div>
              <div id="pathways">
                {
                  pageChapters[8].isLoaded &&
                  <PathwaysChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="phenotypes">
                {
                  pageChapters[9].isLoaded &&
                  <PhenotypesChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="disease-disorders">
                {
                  pageChapters[10].isLoaded &&
                  <DiseaseDisordersChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="cancer-disorders">
                {
                  pageChapters[10].isLoaded &&
                  <CancerDisordersChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="therapeutic-candidates">
                {
                  pageChapters[11].isLoaded &&
                  <TherapeuticCandidatesChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="patents">
                {
                  pageChapters[12].isLoaded &&
                  <PatentsChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="clinical-trials" className="clinical-trials">
                {
                  pageChapters[13].isLoaded &&
                  <ClinicalTrialsChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              <div id="antibodies" className="antibodies">
                {
                  pageChapters[14].isLoaded &&
                  <AntibodiesChapter
                    gene={conceptId}
                    geneName={concept.name}
                    itsNewConcept={this.itsNewConcept}
                  />
                }
              </div>
              {
                isGenmabAccount &&
                <div id="internal-links">
                  {
                    pageChapters[15].isLoaded &&
                    <InternalLinksChapter
                      conceptId={conceptId}
                    />
                  }
                </div>
              }
              <div id="external-links">
                {
                  pageChapters[16].isLoaded &&
                  <ExternalLinksChapter
                    conceptId={conceptId}
                  />
                }
              </div>
            </div>
          </>
        }
        <Loader isLoading={loading && !error} />
        <Error show={!!error} error={error} />
        {
          createSetPopup.show &&
          <ModalComponent
            isOpen={createSetPopup.show}
            closeCb={this.closeCreateSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={createSetPopup.config}
              closePopup={this.closeCreateSetPopup}
            />
          </ModalComponent>
        }
      </div>
    );
  }
}

GeneDetails.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concept: SELECTORS.getGeneDetails(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
    lastVisitedConceptId: SELECTORS.getLastVisitedConceptId(state),
    pageChapters: SELECTORS.getPageChapters(state),
    pageChaptersData: SELECTORS.getPageChaptersData(state),
    userName: getUserName(state),
    isSingleCellAllowed: isSingleCellAllowedSelector(state),
    geneExpressionDataExist: getGeneExpressionDataExistSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLastVisitedConceptId(data) {
      dispatch(ACTIONS.geneDetailsSetLastVisitedConceptIdAction(data));
    },
    geneDetailsRequest(data) {
      dispatch(ACTIONS.geneDetailsRequest(data));
    },
    reset() {
      dispatch(ACTIONS.resetAction());
    },
    loadNextChapter(data) {
      dispatch(ACTIONS.loadNextChapterAction(data));
    },
    unloadChapters() {
      dispatch(ACTIONS.unloadChaptersAction());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneDetails));
