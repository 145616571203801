import React from 'react';
import PropTypes from 'prop-types';

import SingleTargetForCancerTissue from './SingleTargetForCancerTissue';

const propTypes = {
  tissues: PropTypes.instanceOf(Object),
  checkTissue: PropTypes.func,
  type: PropTypes.string,
  parentType: PropTypes.string,
  isDragging: PropTypes.bool,
};

class SingleTargetForCancerTissuesList extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { tissues } = this.props;
    if (nextProps.tissues === tissues) {
      return false;
    }
    return true;
  }

  render() {
    const {
      tissues,
      checkTissue,
      type,
      parentType,
      isDragging,
    } = this.props;
    return (
      <div className="tissues-dnd-column__list">
        {
          tissues.map(
            (item, i) => (
              <SingleTargetForCancerTissue
                key={item.id}
                tissue={item}
                index={i}
                type={type}
                checkTissue={checkTissue}
                isDragging={isDragging}
                parentType={parentType}
              />
            )
          )
        }
        {
          tissues.length === 0 &&
          <div className="tissues-dnd-column__empty">
            Drag and drop tissues here
          </div>
        }
      </div>
    );
  }
}

SingleTargetForCancerTissuesList.propTypes = propTypes;

export default SingleTargetForCancerTissuesList;
