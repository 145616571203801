import { types } from './constants';

export const getDataAsyncAction = data => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_INIT_DATA,
    data,
  }
);

export const getChartAction = data => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_INIT_CHART,
    data,
  }
);

export const changePageAction = data => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_CHANGE_PAGE,
    data,
  }
);

export const sortTableAction = data => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_SORT_TABLE,
    data,
  }
);

export const geneDetailsIsFailed = data => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_INIT_FAILED,
    data,
  }
);

export const geneDetailsIsLoading = () => (
  {
    type: types.GENE_DETAILS_CONDITION_OF_DYSREGULATION_LOADING,
  }
);
