import { PERSONAL_TAB } from './constants';

export const checkProjectTab = projectId => projectId && projectId !== PERSONAL_TAB;

export const getProjectIdForRequest = projectId => (
  projectId === PERSONAL_TAB ? null : projectId
);

export const getProjectTypeForRequest = projectId => (
  projectId === PERSONAL_TAB ? 'USER' : 'PROJECT'
);
