import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import ReactPaginate from 'react-paginate';

// Icons
import {
  AiOutlineExclamationCircle,
  AiFillExclamationCircle,
} from 'react-icons/ai';
// Components
import NoData from '../../common/NoData/NoData';
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ProjectsTabs from '../../Projects/ProjectsTabs/ProjectsTabs';
// Utils
import { AVERAGE_SYMBOL_LENGTH, checkIfAnyChecked } from '../../Utils/Utils';
// Store
import * as ACTIONS from './reducer';
import * as SELECTORS from './selectors';

// Styles
import './styles.scss';

const propTypes = {
  setsData: PropTypes.instanceOf(Object),
  getAvailableSets: PropTypes.func,
  selectFunction: PropTypes.func,
  closeFunction: PropTypes.func,
  filterSets: PropTypes.func,
  filterValue: PropTypes.string,
  loading: PropTypes.bool,
  resetSets: PropTypes.func,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  error: PropTypes.string,
  changePage: PropTypes.func,
  multiSelect: PropTypes.bool,
  conceptsLimit: PropTypes.number,
  conceptsLimitMessage: PropTypes.string,
  projectId: PropTypes.string,
  setActiveProjectId: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
};

class Sets extends React.Component {
  constructor(props) {
    super(props);
    this.columns = null;
    this.actions = {
      checkOneItemAction: ACTIONS.checkOneAvailableSetAction,
      checkItem: ACTIONS.checkAvailableSetAction,
      checkAll: ACTIONS.checkAllAvailableSetsAction,
    };
    this.state = {
      warning: false,
    };
    this.simpleTableRef = React.createRef(null);
  }

  componentDidMount() {
    const { getAvailableSets, projectId } = this.props;
    getAvailableSets({ projectId });
  }

  componentDidUpdate(prevProps) {
    const { getAvailableSets, projectId } = this.props;

    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }

    if (prevProps.projectId !== this.props.projectId) {
      getAvailableSets({ projectId });
    }
  }

  componentWillUnmount() {
    const { resetSets } = this.props;
    resetSets();
  }

  getColumnPercentWidth = (width, percent) => (
    (percent * width) / 100
  );

  getRowHeight = (width, index) => {
    const { setsData: { sets } } = this.props;
    const padding = 10;
    const lineHeight = 28;

    const cellWidth = this.getColumnPercentWidth(width, 50) - padding;
    const getRows = (stringLength, itemWidth) =>
      Math.ceil((stringLength * AVERAGE_SYMBOL_LENGTH) / itemWidth);
    const maxRows = getRows(sets[index].name.length, cellWidth);

    return Math.max((maxRows * lineHeight), 50) + padding;
  };

  getColumns = (width) => {
    this.columns = [
      {
        label: 'Name',
        dataKey: 'name',
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(width, 50),
      },
      {
        label: 'Number of concepts',
        className: 'table-wrap__cell',
        dataKey: 'conceptQuantity',
        width: this.getColumnPercentWidth(width, 25),
      },
      {
        label: 'Set type',
        className: 'table-wrap__cell',
        dataKey: 'setType',
        width: this.getColumnPercentWidth(width, 25),
      },
    ];
    return this.columns;
  };

  filterSets = (e) => {
    const { filterSets } = this.props;
    filterSets(e.target.value);
  };

  handlePageClick = (page) => {
    const { changePage } = this.props;
    changePage(page.selected);
  };

  handleSelectSet = () => {
    const {
      setsData: { sets },
      selectFunction,
      closeFunction,
      conceptsLimit,
      projectId,
    } = this.props;
    const selectedSets = sets.filter(set => set.selected);

    if (selectedSets[0].conceptQuantity > conceptsLimit) {
      this.setState({ warning: true });
    } else {
      selectFunction({ projectId, selectedSets });
      closeFunction();
    }
  };

  handleProjectClick = (id) => {
    const { getAvailableSets } = this.props;
    getAvailableSets({ projectId: id });
  };

  render() {
    const {
      setsData: {
        sets,
        page,
        totalPages,
      },
      sorting: {
        sortBy,
        sortDirection,
      },
      filterValue,
      loading,
      error,
      closeFunction,
      multiSelect = false,
      conceptsLimit,
      conceptsLimitMessage,
      setActiveProjectId,
      clearActiveProjectId,
      projectId,
    } = this.props;
    const { warning } = this.state;

    const tableSettings = {
      width: 900,
      headerHeight: 50,
      rowHeight: 50,
      sortBy,
      sortDirection,
    };

    const limitClass = classnames(
      'sets-page__limit',
      { 'sets-page__limit_error': warning }
    );

    return (
      <div className="sets-page">
        <div className="sets-page__title title2">Select set</div>
        <ProjectsTabs
          activeProjectId={projectId}
          setActiveProjectId={setActiveProjectId}
          clearActiveProjectId={clearActiveProjectId}
        />
        <div className="sets-page__content">
          {
            !loading && !error && sets &&
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                width ?
                  <div className="table-wrap" style={{ width }}>
                    {
                      conceptsLimit && conceptsLimitMessage &&
                      <div className={limitClass}>
                        {
                          warning ?
                            <AiFillExclamationCircle size={20} color="red" /> :
                            <AiOutlineExclamationCircle size={20} color="#4b3f63" />
                        }
                        <span className="sets-page__limit-text">
                          {conceptsLimitMessage}
                        </span>
                      </div>
                    }
                    <div
                      className="controls-block table-wrap__controls table-wrap__controls_filter"
                      style={{ width }}
                    >
                      <div className="table-filter">
                        <input
                          name="Filter sets"
                          placeholder="Filter sets"
                          onChange={this.filterSets}
                          className="table-filter__input"
                          type="text"
                          value={filterValue}
                        />
                      </div>
                      {
                        totalPages > 1 &&
                        <div className="table-wrap__pagination">
                          <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakClassName="break-me"
                            pageCount={totalPages}
                            forcePage={page}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                          />
                        </div>
                      }
                    </div>
                    {
                      sets.length > 0 &&
                      <SimpleTable
                        innerRef={this.simpleTableRef}
                        data={sets}
                        autoSize={true}
                        width={width}
                        columns={this.columns ? this.columns : this.getColumns(width)}
                        settings={{
                          ...tableSettings,
                          rowHeight: ({ index }) => this.getRowHeight(width, index),
                        }}
                        dynamicHeight={true}
                        sortAction={ACTIONS.sortAvailableSetsAction}
                        singleSelect={!multiSelect}
                        onlySelect={true}
                        actions={this.actions}
                      />
                    }
                    <NoData
                      show={sets.length === 0}
                      customClassName="algorithm-table__error"
                    />
                  </div> : null
              )}
            </AutoSizer>
          }
          <Error show={!!error} error={error} />
          <Loader isLoading={loading} />
        </div>
        {
          !loading && !error && sets &&
          <div className="sets-page__footer">
            <button
              type="button"
              className="button mr-15"
              onClick={closeFunction}
            >
              Cancel
            </button>
            <button
              className="button button-primary"
              disabled={!checkIfAnyChecked(sets)}
              onClick={this.handleSelectSet}
            >
              Select
            </button>
          </div>
        }
      </div>
    );
  }
}

Sets.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    setsData: SELECTORS.getAvailableSetsDataSelector(state),
    loading: SELECTORS.getAvailableSetsLoadingSelector(state),
    error: SELECTORS.getAvailableSetsErrorSelector(state),
    sorting: SELECTORS.getAvailableSetsSortingSelector(state),
    filterValue: SELECTORS.getAvailableSetsFilterSelector(state),
    projectId: SELECTORS.getActiveProjectIdSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAvailableSets(data) {
      dispatch(ACTIONS.getAvailableSetsAction(data));
    },
    changePage(data) {
      dispatch(ACTIONS.changeAvailableSetsPageAction(data));
    },
    filterSets(data) {
      dispatch(ACTIONS.filterAvailableSetsAction(data));
    },
    resetSets(data) {
      dispatch(ACTIONS.resetAvailableSetsAction(data));
    },
    setActiveProjectId(data) {
      dispatch(ACTIONS.setSetsListActiveProjectIdAction(data));
    },
    clearActiveProjectId() {
      dispatch(ACTIONS.clearSetsListActiveProjectIdAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sets);
