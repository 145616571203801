export const OPEN_ADD_LINKED_CONCEPTS = 'OPEN_ADD_LINKED_CONCEPTS';
export const CLOSE_ADD_LINKED_CONCEPTS = 'CLOSE_ADD_LINKED_CONCEPTS';

export const FILTER_CATEGORIES_REQUESTED = 'FILTER_CATEGORIES_REQUESTED';
export const FILTER_CATEGORIES_SUCCEEDED = 'FILTER_CATEGORIES_SUCCEEDED';
export const FILTER_CATEGORIES_FAILED = 'FILTER_CATEGORIES_FAILED';

export const FILTER_CATEGORIES_TYPE_REQUESTED = 'FILTER_CATEGORIES_TYPE_REQUESTED';
export const FILTER_CATEGORIES_TYPE_SUCCEEDED = 'FILTER_CATEGORIES_TYPE_SUCCEEDED';
export const FILTER_CATEGORIES_TYPE_FAILED = 'FILTER_CATEGORIES_TYPE_FAILED';

export const FILTER_CATEGORIES_TYPE_LOADING = 'FILTER_CATEGORIES_TYPE_LOADING';

export const FILTER_RELATIONS_REQUESTED = 'FILTER_RELATIONS_REQUESTED';
export const FILTER_RELATIONS_SUCCEEDED = 'FILTER_RELATIONS_SUCCEEDED';
export const FILTER_RELATIONS_FAILED = 'FILTER_RELATIONS_FAILED';

export const FILTER_TAXONOMIES_REQUESTED = 'FILTER_TAXONOMIES_REQUESTED';
export const FILTER_TAXONOMIES_SUCCEEDED = 'FILTER_TAXONOMIES_SUCCEEDED';
export const FILTER_TAXONOMIES_FAILED = 'FILTER_TAXONOMIES_FAILED';

export const FILTER_OPEN_RELATION = 'FILTER_OPEN_RELATION';
export const FILTER_CLOSE_RELATION = 'FILTER_CLOSE_RELATION';

export const FILTER_OPEN_CATEGORY = 'FILTER_OPEN_CATEGORY';
export const FILTER_CLOSE_CATEGORY = 'FILTER_CLOSE_CATEGORY';

export const FILTER_CHECK_RELATION = 'FILTER_CHECK_RELATION';
export const FILTER_CHECK_RELATION_TYPE = 'FILTER_CHECK_RELATION_TYPE';
export const FILTER_CHECK_TAXONOMY = 'FILTER_CHECK_TAXONOMY';
export const FILTER_CHECK_CATEGORY = 'FILTER_CHECK_CATEGORY';
export const FILTER_CHECK_CATEGORY_TYPE = 'FILTER_CHECK_CATEGORY_TYPE';

export const FILTER_CHECK_ALL_CATEGORY_TYPE = 'FILTER_CHECK_ALL_CATEGORY_TYPE';
export const FILTER_CHECK_ALL_RELATION_TYPE = 'FILTER_CHECK_ALL_RELATION_TYPE';

export const RELATED_CONCEPTS_REQUESTED = 'RELATED_CONCEPTS_REQUESTED';
export const RELATED_CONCEPTS_SUCCEEDED = 'RELATED_CONCEPTS_SUCCEEDED';
export const RELATED_CONCEPTS_FAILED = 'RELATED_CONCEPTS_FAILED';

export const RELATED_CONCEPTS_LOADING = 'RELATED_CONCEPTS_LOADING';

export const CHECK_RELATED_CONCEPT = 'CHECK_RELATED_CONCEPT';

export const CHECK_ALL_RELATED_CONCEPT = 'CHECK_ALL_RELATED_CONCEPT';

export const SORT_RELATED_CONCEPTS = 'SORT_RELATED_CONCEPTS';

export const FILTER_RELATED_CONCEPTS = 'FILTER_RELATED_CONCEPTS';

export const ADD_COLUMN_REQUESTED = 'ADD_COLUMN_REQUESTED';
export const ADD_COLUMN_SUCCEEDED = 'ADD_COLUMN_SUCCEEDED';
export const ADD_COLUMN_FAILED = 'ADD_COLUMN_FAILED';

export const ADD_COLUMN_LOADING = 'ADD_COLUMN_LOADING';
