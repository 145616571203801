import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '../../../Api/Api';
import {
  loadNextChapterAction,
  geneDetailsSetChapterAction,
} from '../actions';

import {
  getProteinLocationDataAction,
  setProteinLocationDataAction,
  toggleProteinLocationLoaderAction,
  throwProteinLocationErrorAction,
} from './reducer';

function* geneDetailsProteinLocationWorker(action) {
  try {
    yield put(toggleProteinLocationLoaderAction(true));
    const { id } = action.payload;
    const { data } = yield call(Api.getProteinLocation, id);
    yield put(setProteinLocationDataAction(data));

    if (data.proteinLocations && data.proteinLocations.length) {
      yield put(geneDetailsSetChapterAction('protein-location'));
    }

    yield put(toggleProteinLocationLoaderAction(false));
    yield put(loadNextChapterAction('gene-expression'));
  } catch (e) {
    yield put(throwProteinLocationErrorAction(e.message));
    yield put(toggleProteinLocationLoaderAction(false));
    yield put(loadNextChapterAction('gene-expression'));
  }
}

function* geneDetailsProteinLocationSaga() {
  yield takeLatest(getProteinLocationDataAction, geneDetailsProteinLocationWorker);
}

export default geneDetailsProteinLocationSaga;
