import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import {
  getParentBlockWidth,
  AVERAGE_SYMBOL_LENGTH,
} from '../../Utils/Utils';
import PublicationsList from '../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';

const propTypes = {
  gene: PropTypes.string,
  geneName: PropTypes.string,
  tableData: PropTypes.instanceOf(Object),
  parentClass: PropTypes.string,
  sortAction: PropTypes.func,
  changePageAction: PropTypes.func,
};

class ExistingDrugsTable extends React.Component {
  state = {
    indicationsPopupData: [],
    publicationsPopup: false,
    showInfoText: false,
  };
  simpleTableRef = React.createRef(null);
  conceptsNames = [];
  conceptsIds = [];

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const { changePageAction } = this.props;

    changePageAction(page.selected);
  };

  showIndicationsPopup = (indicationsPopupData) => {
    this.setState({ indicationsPopupData });
  };

  closeIndicationsPopup = () => {
    this.setState({
      indicationsPopupData: [],
    });
  };

  openPublicationsPopup = (data) => {
    const { gene, geneName } = this.props;
    const { id, name } = data.compound;
    this.conceptsNames = [];
    this.conceptsIds = [];
    this.conceptsNames.push(geneName);
    this.conceptsNames.push(name);
    this.conceptsIds.push(gene);
    this.conceptsIds.push(id);
    this.setState({ publicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ publicationsPopup: false });
  };

  getColumnPercentWidth = (percent) => {
    const { parentClass } = this.props;
    const screenWidth = getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  getRowHeight = ({ index }) => {
    const { tableData: { data } } = this.props;
    const padding = 20;
    const lineHeight = 30;
    const firstCellWidth = this.getColumnPercentWidth(30);
    const secondCellWidth = this.getColumnPercentWidth(40);
    const stringlength = data[index].compound.name.length;
    const stringRows = Math.ceil((stringlength * AVERAGE_SYMBOL_LENGTH) / firstCellWidth);
    let indicationsCount = 0;
    let rowHeight;

    if (data[index].indications.items.length <= 3) {
      data[index].indications.items.forEach((item) => {
        indicationsCount += Math.ceil((item.name.length * AVERAGE_SYMBOL_LENGTH) / secondCellWidth);
      });
    } else {
      indicationsCount = 1;
    }

    if (indicationsCount > stringRows) {
      rowHeight = (indicationsCount * lineHeight) + padding;
    } else {
      rowHeight = (stringRows * lineHeight) + padding;
    }
    return rowHeight;
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  compoundNameCell = ({ id, name }, index) => {
    const uniqueKey = `tooltip-${id}-${index}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      tableData: {
        data = [],
        allData = [],
        pageNumber,
        totalPages,
        sorting: {
          sortBy,
          sortDirection,
        },
      },
      geneName,
      sortAction,
      parentClass,
    } = this.props;

    const {
      indicationsPopupData,
      publicationsPopup,
      showInfoText,
    } = this.state;

    const indicationsPopupContent = indicationsPopupData.map((item, i) => (
      <a
        className="indications-popup__item"
        key={i}
      >
        {item.name}
      </a>
    ));

    const tableSettings = {
      height: 500,
      width: getParentBlockWidth(parentClass),
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      rowClassName: 'table-wrap__row',
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Therapeutic agent',
        dataKey: 'compound',
        sortPath: ['compound', 'name'],
        exportCSV: true,
        className: 'table-wrap__cell table-wrap__cell_left',
        width: this.getColumnPercentWidth(30),
        cellRenderer: ({ rowData, rowIndex }) => {
          const cell = rowData.compound ?
            this.compoundNameCell(rowData.compound, rowIndex) : null;
          return cell;
        },
        csvRenderer: rowData => (rowData.compound ? rowData.compound.name : null),
      },
      {
        label: 'Indications',
        dataKey: 'indications',
        sortPath: ['indications', 'itemsAmount'],
        exportCSV: true,
        width: this.getColumnPercentWidth(40),
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => {
          const { indications: { items, itemsAmount } } = rowData;
          let cell;

          if (itemsAmount <= 3) {
            cell = (items.map((item, i) => (
              <div key={i} className="table-wrap__cell-row">
                {item.name}
              </div>
            )));
          } else {
            cell = (
              <div
                className="table-wrap__cell-row link"
                onClick={() => { this.showIndicationsPopup(items); }}
              >
                {itemsAmount}
              </div>
            );
          }
          return cell;
        },
        csvRenderer: rowData => rowData.indications.items.map(row => row.name).join('; '),
      },
      {
        label: 'Source',
        dataKey: 'sources',
        exportCSV: true,
        width: this.getColumnPercentWidth(15),
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          const sources = rowData.sources || [];
          return sources.map((source, index) =>
            (source === 'Pharmaprojects' ?
              <span key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={rowData.url ? rowData.url : 'https://citeline.informa.com/drugs/results'}
                >
                  {source}
                </a>
              </span>
              : <span key={index}>{source}</span>)
          );
        },
      },
      {
        label: 'References',
        dataKey: 'publicationCount',
        exportCSV: true,
        className: 'table-wrap__cell',
        width: this.getColumnPercentWidth(15),
        cellRenderer: ({ rowData }) => (
          rowData.publicationCount > 0 ?
            <div
              className="table-wrap__cell-row link"
              onClick={() => { this.openPublicationsPopup(rowData); }}
            >
              {rowData.publicationCount}
            </div> :
            <div className="table-wrap__cell-row">
              {rowData.publicationCount}
            </div>
        ),
      },
    ];

    return (
      <div className="therapeutic-candidates__chapter">
        <span className="therapeutic-candidates__title">
          Existing drugs
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </span>
        {
          showInfoText &&
          <div className="gene-details-info__main gene-details-info__main_light">
            <p>
              This section shows existing drugs that target the gene.
              For each drug the indications for which it is being used (including off-label use) is shown.
              For each of these drug associations all Pubmed articles that co-mention the gene are shown in the right hand column and can be accessed by clicking the link.
              Subscriptions to Pharmaprojects can be activated to include data from that source here.
            </p>
          </div>
        }
        {
          data.length > 0 ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-therapeuticcandidates-existing-drugs`}
                    />
                  }
                </div>
              }
              <SimpleTable
                innerRef={this.simpleTableRef}
                data={data}
                autoSize={true}
                columns={columns}
                settings={tableSettings}
                dynamicHeight={true}
                sortAction={sortAction}
              />
              {
                indicationsPopupData.length > 0 &&
                <ModalComponent
                  isOpen={indicationsPopupData.length > 0}
                  closeCb={this.closeIndicationsPopup}
                >
                  <div className="indications-popup">
                    <div className="indications-popup__title">Indications</div>
                    <div className="indications-popup__wrap">
                      { indicationsPopupContent }
                    </div>
                  </div>
                </ModalComponent>
              }
              {
                publicationsPopup &&
                <ModalComponent
                  isOpen={publicationsPopup}
                  closeCb={this.closePublicationsPopup}
                >
                  <PublicationsList
                    names={this.conceptsNames}
                    ids={this.conceptsIds}
                  />
                </ModalComponent>
              }
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

ExistingDrugsTable.propTypes = propTypes;
export default ExistingDrugsTable;
