// Core
import { takeLatest } from 'redux-saga/effects';
// Actions
import { getSimilarProteinsAction } from '../reducer';
// Workers
import { getSimilarProteinsWorker } from './workers/getSimilarProteinsWorker';

function* watchSimilarProteinsChapter() {
  yield takeLatest(getSimilarProteinsAction, getSimilarProteinsWorker);
}

export default watchSimilarProteinsChapter;
