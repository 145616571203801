import { takeLatest } from 'redux-saga/effects';

import {
  getAvailableSetsAction,
} from '../reducer';

import { getAvailableSetsWorker } from './workers/getAvailableSetsWorker';

function* createSetSaga() {
  yield takeLatest(getAvailableSetsAction, getAvailableSetsWorker);
}

export default createSetSaga;
