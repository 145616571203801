import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const findRelatedAnalysisRequestedAction = createAction('FIND_RELATED_ANALYSIS_REQUESTED');
export const findRelatedAnalysisSucceededAction = createAction('FIND_RELATED_ANALYSIS_SUCCEEDED');
export const findRelatedAnalysisFailedAction = createAction('FIND_RELATED_ANALYSIS_FAILED');
export const findRelatedTableSortAction = createAction('FIND_RELATED_TABLE_SORT');
export const findRelatedTableCheckAllAction = createAction('FIND_RELATED_TABLE_CHECK_ALL');
export const findRelatedTableCheckItemAction = createAction('FIND_RELATED_TABLE_CHECK_ITEM');
export const findRelatedTableRemoveSelectedAction = createAction('FIND_RELATED_TABLE_REMOVE_SELECTED');
export const findRelatedTableInvertSelectionAction = createAction('FIND_RELATED_TABLE_INVERT_SELECTION');
export const findRelatedTableRemoveConceptAction = createAction('FIND_RELATED_TABLE_REMOVE_CONCEPT');
export const findRelatedTableLoadingAction = createAction('FIND_RELATED_TABLE_LOADING');
export const findRelatedTableResetAction = createAction('FIND_RELATED_TABLE_RESET');

const initialValues = fromJS({
  result: {
    content: [],
  },
  error: null,
  sorting: { sortBy: 'pValue', sortDirection: 'ASC' },
  loading: false,
});

const reducer = handleActions(
  {
    [findRelatedAnalysisSucceededAction]: (state, { payload }) => {
      let { content } = payload;
      if (content.length && !content[0].id) {
        content = content.map((item, index) => Object.assign(item, { id: index + 1 }));
      }
      const result = Object.assign(payload, { content });
      return state.merge(fromJS({ result, error: null, loading: false }));
    },
    [findRelatedAnalysisFailedAction]: (state, { payload }) => (
      state.merge(fromJS({
        error: payload,
        result: initialValues.get('result'),
        loading: false,
      }))
    ),
    [findRelatedTableSortAction]: (state, { payload }) => (
      state.update('sorting', () => fromJS(payload))
    ),
    [findRelatedTableCheckAllAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => (
        content.map(item => item.merge(fromJS({ selected: payload })))
      ))
    ),
    [findRelatedTableCheckItemAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => (
        content.map((item) => {
          if (item.get('id') === payload.id) {
            return item.merge(fromJS({ selected: payload.checked }));
          }
          return item;
        })
      ))
    ),
    [findRelatedTableRemoveSelectedAction]: state => (
      state.updateIn(['result', 'content'], content => content.filterNot(
        item => item.get('selected')
      ))
    ),
    [findRelatedTableInvertSelectionAction]: state => (
      state.updateIn(['result', 'content'], content => content.map(
        item => item.merge(fromJS({ selected: !item.get('selected') }))
      ))
    ),
    [findRelatedTableRemoveConceptAction]: (state, { payload }) => (
      state.updateIn(['result', 'content'], content => content.delete(
        content.findIndex(item => item.get('id') === payload)
      ))
    ),
    [findRelatedTableLoadingAction]: (state, { payload }) => (
      state.update('loading', () => payload)
    ),
    [findRelatedTableResetAction]: state => (
      state.merge(initialValues)
    ),
  },
  initialValues
);

export default reducer;
