import {
  createSelector,
} from 'reselect';

import {
  sort,
} from '../../Utils/Utils';

const openKey = state => state.getIn(['setAnalysisGenesDetail', 'open']);
const api = state => state.getIn(['setAnalysisGenesDetail', 'apiKey']);
const concept = state => state.getIn(['setAnalysisGenesDetail', 'concept']);
const analyticsGenes = state => state.getIn(['setAnalysisGenesDetail', 'analyticsGenes']);
const genesDetail = state => state.getIn(['setAnalysisGenesDetail', 'result', 'content']);
const sorting = state => state.getIn(['setAnalysisGenesDetail', 'sorting']);
const loading = state => state.getIn(['setAnalysisGenesDetail', 'loading']);
const error = state => state.getIn(['setAnalysisGenesDetail', 'error']);
const page = state => state.getIn(['setAnalysisGenesDetail', 'result', 'pageable', 'pageNumber']);
const totalPages = state => state.getIn(['setAnalysisGenesDetail', 'result', 'totalPages']);
const totalElements = state => state.getIn(['setAnalysisGenesDetail', 'result', 'totalElements']);

export const getTotalElements = createSelector(
  totalElements,
  elements => elements
);

export const getCurrentPage = createSelector(
  page,
  pageValue => pageValue
);

export const getTotalPages = createSelector(
  totalPages,
  totalPagesValue => totalPagesValue
);

export const getOpenKey = createSelector(
  openKey,
  data => data
);

export const getApi = createSelector(
  api,
  data => data
);

export const getConcept = createSelector(
  concept,
  data => data.toJS()
);

export const getAnalyticsGenes = createSelector(
  analyticsGenes,
  data => data
);

export const getGenesDetail = createSelector(
  genesDetail,
  sorting,
  (_genesDetail, _sorting) => {
    const sorted = sort(_genesDetail, _sorting.toJS());
    return sorted.toJS();
  }
);

export const getSorting = createSelector(
  sorting,
  data => data.toJS()
);

export const getLoading = createSelector(
  loading,
  data => data
);

export const getError = createSelector(
  error,
  data => data
);
