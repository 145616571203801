import React from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from '../../../common/Loader/Loader';
import ConceptSearchForm from '../../../Concept/ConceptSearchForm/ConceptSearchForm';
import PortalPage from '../Portal/PortalPage';
// Styles
import './index.scss';

const propTypes = {
  onSubmitCallback: PropTypes.func,
  searchValue: PropTypes.string,
  loading: PropTypes.bool,
  setPortalAction: PropTypes.func,
  searchParamsCallback: PropTypes.func,
  openRankParamsDialog: PropTypes.func,
};

const SearchPageStartPortal = (props) => {
  const {
    onSubmitCallback,
    searchParamsCallback,
    openRankParamsDialog,
    searchValue,
    loading,
    setPortalAction
  } = props;

  if (loading) {
    return (
      <Loader
        isLoading={loading}
        withOverlay={true}
      />
    );
  }

  return (
    <div className="search-page-start-portal">
      <div className="search-page-start-portal__logo">
        <div className="euretos-logo-white" />
      </div>
      <ConceptSearchForm
        initialValues={{ searchValue }}
        onSubmitCallback={onSubmitCallback}
        setPortalAction={setPortalAction}
      />
      <PortalPage
        openConceptSearch={onSubmitCallback}
        openConceptParamsSearch={searchParamsCallback}
        openRankParamsDialog={openRankParamsDialog}
        setPortalAction={setPortalAction}
      />
    </div>
  );
};

SearchPageStartPortal.propTypes = propTypes;

export default SearchPageStartPortal;
