import { createAction } from 'redux-actions';

export const getSearchDataAction = createAction('@@search/GET_DATA');
export const setSearchDataAction = createAction('@@search/SET_DATA');
export const throwSearchDataErrorAction = createAction('@@search/THROW_ERROR');
export const resetSearchResultsAction = createAction('@@search/RESET');
export const cacheConceptIdsForCategorySearchAction = createAction('@@search/CACHE_CONCEPT_IDS_FOR_CATEGORY_SEARCH');
export const getCategorySearchDataAction = createAction('@@search/GET_CATEGORY_SEARCH_DATA');
export const setCategorySearchDataAction = createAction('@@search/SET_CATEGORY_SEARCH_DATA');
export const setCategorySearchDTOAction = createAction('@@search/SET_CATEGORY_SEARCH_DTO');
export const throwCategorySearchDataErrorAction = createAction('@@search/THROW_CATEGORY_SEARCH_ERROR');
export const saveExactSearchTermAction = createAction('@@search/SAVE_EXACT_SEARCH_TERM');
export const updateSearchTermDirtyAction = createAction('@@search/UPDATE_SEARCH_TERM_DIRTY');
export const checkSearchRelatedConceptAction = createAction('@@search/CHECK_RELATED_CONCEPT');
export const resetRelatedConceptsAction = createAction('@@search/RESET_RELATED_CONCEPTS');
export const resetCachedConceptIdsForCategoryAction = createAction('@@search/RESET_CACHED_CONCEPT_IDS_FOR_CATEGORY');
export const saveSearchTermAction = createAction('@@search/SAVE_TERM');
export const setSearchFilterInitialValuesAction = createAction('@@search/SET_FILTER_ININTIAL_VALUES');
export const updateSearchSavedLinkAction = createAction('@@search/UPDATE_SAVED_LINK');
export const updateSelectedPublicationsIdsAction = createAction('@@search/UPDATE_SELECTED_PUBLICATIONS_IDS_DATA');
export const updateSelectedPubmedIdsAction = createAction('@@search/UPDATE_SELECTED_PUBMED_IDS_DATA');
export const clearSelectedPublicationsDataAction = createAction('@@search/RESET_SELECTED_PUBLICATIONS_DATA');
export const disableReloadingAction = createAction('@@search/DISABLE_RELOADING_ACTION');
export const setSearchConceptsAction = createAction('@@search/SET_CONCEPTS');
export const resetSearchConceptsAction = createAction('@@search/RESET_CONCEPTS');
export const setPublicationFilterExtendedAction = createAction('@@search/SET_PUBLICATION_FILTER_EXTENDED');
