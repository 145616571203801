import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import Concept from '../ConceptItem/Concept';

const propTypes = {
  tagList: PropTypes.instanceOf(Array),
  updateTags: PropTypes.func,
};

const AddTagContent = (props) => {
  const {
    tagList = [],
    updateTags,
  } = props;

  const removeTag = useCallback((id) => {
    updateTags(tagList.filter(item => item.id !== id));
  }, [tagList, updateTags]);

  return (
    <div className="add-tag-content">
      {
        tagList.map((tag, i) => (
          <Concept
            key={i}
            concept={tag}
            deleteCb={removeTag}
            noInfoButton={true}
          />
        ))
      }
    </div>
  );
};

AddTagContent.propTypes = propTypes;

export default React.memo(AddTagContent);
