import {
  CURRENT_USER_SUCCEEDED,
  CURRENT_USER_REQUESTED,
  CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED,
  CEREBRUM_ACCESS_MAPPINGS_REQUESTED,
  USER_UPDATE_OPTION,
  USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED,
} from './constants';

export const appInitCurrentUserAction = () => (
  {
    type: CURRENT_USER_REQUESTED,
  }
);

export const setInitCurrentUserAction = (data) => (
  {
    type: CURRENT_USER_SUCCEEDED,
    data,
  }
);

export const setUsersOptionAction = (data) => (
  {
    type: USER_UPDATE_OPTION,
    data,
  }
);

export const setUsersLastEnrichmentAnalysisSelectedAction = (data) => (
  {
    type: USER_UPDATE_LAST_ENRICHMENT_ANALYSIS_SELECTED,
    data,
  }
);

export const appInitCerebrumSCAction = () => (
  {
    type: CEREBRUM_SEMANTIC_CATEGORIES_REQUESTED,
  }
);

export const appInitCerebrumAccessMappingsAction = () => (
  {
    type: CEREBRUM_ACCESS_MAPPINGS_REQUESTED,
  }
);
