import { fromJS } from 'immutable';

import {
  GENE_DETAILS_SUCCEEDED,
  GENE_DETAILS_FAILED,
  GENE_DETAILS_LOADING,
  GENE_DETAILS_RESET,
  GENE_DETAILS_REQUESTED,
  GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID,
  GENE_DETAILS_LOAD_CHAPTER,
  GENE_DETAILS_UNLOAD_CHAPTERS,
  GENE_DETAILS_SET_CHAPTER_DATA,
} from './constants';

const initialState = fromJS({
  lastVisitedConceptId: '',
  data: {},
  error: null,
  loading: true,
  pageChapters: [
    { name: 'literature-search', isLoaded: false },
    { name: 'protein-structure', isLoaded: false },
    { name: 'similar-proteins', isLoaded: false },
    { name: 'orthologs', isLoaded: false },
    { name: 'protein-location', isLoaded: false },
    { name: 'gene-expression', isLoaded: false },
    { name: 'condition-of-dysregulation', isLoaded: false },
    { name: 'functional-characterization', isLoaded: false },
    { name: 'pathways', isLoaded: false },
    { name: 'phenotypes', isLoaded: false },
    { name: 'disorders', isLoaded: false },
    { name: 'therapeutic-candidates', isLoaded: false },
    { name: 'patents', isLoaded: false },
    { name: 'clinical-trials', isLoaded: false },
    { name: 'antibodies', isLoaded: false },
    { name: 'internal-links', isLoaded: false },
    { name: 'external-links', isLoaded: false },
  ],
  pageChaptersData: [
    { name: 'literature-search', isDataExist: false },
    { name: 'protein-structure', isDataExist: false },
    { name: 'similar-proteins', isDataExist: false },
    { name: 'orthologs', isLoaded: false },
    { name: 'protein-location', isDataExist: false },
    { name: 'gene-expression', isDataExist: false },
    { name: 'condition-of-dysregulation', isDataExist: false },
    { name: 'functional-characterization', isDataExist: false },
    { name: 'pathways', isDataExist: false },
    { name: 'phenotypes', isDataExist: false },
    { name: 'disease', isDataExist: false },
    { name: 'cancer', isDataExist: false },
    { name: 'therapeutic-candidates', isDataExist: false },
    { name: 'patents', isDataExist: false },
    { name: 'clinical-trials', isDataExist: false },
    { name: 'antibodies', isLoaded: false },
    { name: 'internal-links', isDataExist: false },
    { name: 'external-links', isDataExist: false },
  ],
});

const geneDetails = (state = initialState, action) => {
  switch (action.type) {
    case GENE_DETAILS_SET_LAST_VISITED_CONCEPT_ID:
      return state.set('lastVisitedConceptId', action.data);

    case GENE_DETAILS_REQUESTED:
      return state.set('loading', true);

    case GENE_DETAILS_SUCCEEDED:
      return state.merge(fromJS({ data: action.data, error: null, loading: false }));

    case GENE_DETAILS_FAILED:
      return state.merge(fromJS({ data: {}, error: action.message, loading: false }));

    case GENE_DETAILS_LOADING:
      return state.update('loading', () => true);

    case GENE_DETAILS_RESET:
      return initialState;

    case GENE_DETAILS_LOAD_CHAPTER: {
      return state.update('pageChapters', data => data.map((item) => {
        if (item.get('name') === action.payload) {
          return item.set('isLoaded', true);
        }
        return item;
      }));
    }

    case GENE_DETAILS_SET_CHAPTER_DATA: {
      return state.update('pageChaptersData', data => data.map((item) => {
        if (item.get('name') === action.data) {
          return item.set('isDataExist', true);
        }
        return item;
      }));
    }

    case GENE_DETAILS_UNLOAD_CHAPTERS: {
      return state
        .update('pageChapters', data => data.map(item => item.set('isLoaded', false)));
    }

    default:
      return state;
  }
};

export default geneDetails;
