import { createSelector } from 'reselect';

const differentialExpressionTerm = state => state.getIn(['setAnalysisMethodSelection', 'differentialExpressionConcept']);
const differentialExpressionSC = state => state.getIn(['setAnalysisMethodSelection', 'differentialExpressionSC']);

export const getDifferentialExpressionTerm = createSelector(
  differentialExpressionTerm,
  data => (data ? data.toJS() : data)
);

export const getDifferentialExpressionSC = createSelector(
  differentialExpressionSC,
  data => data
);
