import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const propTypes = {
  children: PropTypes.instanceOf(Object),
  openZoom: PropTypes.func,
};

class ZoomBtn extends React.PureComponent {
  render() {
    const {
      children,
      openZoom,
    } = this.props;

    return (
      <div
        className="zoom__btn"
        onClick={openZoom}
      >
        { children }
      </div>
    );
  }
}

ZoomBtn.propTypes = propTypes;

export default ZoomBtn;

