import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Loader from '../../../../../common/Loader/Loader';
import Error from '../../../../../common/Error/Error';
import SelectInput from '../../../../../common/Inputs/SelectInput/SelectInput';
// Store
import * as a from './store/reducer';
import * as s from './store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  handleActiveDataset: PropTypes.func,
  selectedDataset: PropTypes.instanceOf(Object),
  getDatasets: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  error: PropTypes.string,
  tumorType: PropTypes.string,
};

const DatasetsSelector = (props) => {
  const {
    handleActiveDataset,
    selectedDataset = {},
    getDatasets,
    tumorType,
    options,
    loading,
    error,
  } = props;

  useEffect(() => {
    getDatasets();
  }, []);

  useEffect(() => {
    if (options.length) {
      if (tumorType) {
        handleActiveDataset(options.find(d => d.tumorType === tumorType));
      } else {
        handleActiveDataset(options[0]);
      }
    }
  }, [options]);

  return (
    <div className="single-cell-study__dataset-block">
      {
        options.length > 0 &&
        <>
          <div className="single-cell-study__tumor-type">
            <span className="single-cell-selector__label">Tumor type: </span>
            <SelectInput
              onSelect={handleActiveDataset}
              options={options}
              placeholder="Select"
              closeOnSelect={true}
              onSelectResetsInput={true}
              customClassName="single-cell-selector"
              defaultValue={selectedDataset}
              styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
              menuPortalTarget={document.body}
            />
          </div>
          <div className="single-cell-study__study">
            <span>Study: </span> { selectedDataset.study }
          </div>
        </>
      }
      <Loader isLoading={loading} />
      <Error show={!!error && !loading} error={error} />
    </div>
  );
};

DatasetsSelector.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    options: s.getDatasetsOptionsSelector(state),
    loading: s.getDatasetsLoadingSelector(state),
    error: s.getDatasetsErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDatasets() {
      dispatch(a.getDatasetsAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetsSelector);

