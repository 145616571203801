import { createSelector } from 'reselect';
import { getCerebrumSemanticCategoriesSelector } from '../../../Header/selectors';

const file = state => state.get('uploadFileReducer', 'file');
const type = state => state.getIn(['uploadFileReducer', 'type']);
const taxonomies = state => state.getIn(['uploadFileReducer', 'taxonomies']);
const loading = state => state.getIn(['uploadFileReducer', 'loading']);
const error = state => state.getIn(['uploadFileReducer', 'error']);
const mainCategories = state => state.getIn(['uploadFileReducer', 'mainCategories']);
const checkedSTypes = state => state.getIn(['uploadFileReducer', 'checkedST']);
const checkedSCategories = state => state.getIn(['uploadFileReducer', 'checkedSC']);
const importSettings = state => state.getIn(['uploadFileReducer', 'import']);
const isComplexSet = state => state.getIn(['uploadFileReducer', 'isComplexSet']);

export const getUploadedFileSelector = createSelector(
  file,
  data => data && data.toJS()
);

export const getUploadFileNameSelector = createSelector(
  [getUploadedFileSelector],
  data => data && data.name
);

export const getUploadedFileTypeSelector = createSelector(
  type,
  data => data
);

export const getUploadedFileIsComlpexSelector = createSelector(
  isComplexSet,
  data => data
);

export const getUploadedFileTaxonomiesSelector = createSelector(
  taxonomies,
  data => data && data.toJS()
);

export const getUploadFileLoadingSelector = createSelector(
  loading,
  data => data,
);

export const getUploadFileErrorSelector = createSelector(
  error,
  data => data
);

export const getUploadFileCheckedSTSelector = createSelector(
  checkedSTypes,
  data => data && data.toJS()
);

export const getUploadFileCheckedSCSelector = createSelector(
  checkedSCategories,
  data => data && data.toJS()
);

export const getUploadFileCategoriesSelector = createSelector(
  [
    getUploadFileCheckedSTSelector,
    getUploadFileCheckedSCSelector,
    getCerebrumSemanticCategoriesSelector,
  ],
  (checkedST, checkedSC, sc) => {
    if (!sc || !sc.length) return sc;
    return sc.map(c => ({
      ...c,
      checked: checkedSC.includes(c.name),
      st: c.st.map(t => ({ ...t, checked: checkedST.includes(t.id) })),
    }));
  }
);

export const getUploadFileMainCategoriesSelector = createSelector(
  checkedSTypes,
  mainCategories,
  (_checked, data) => {
    const st = data && data.toJS();
    if (!st || !st.length) return st;
    const checked = _checked.toJS();
    return st.map(t => ({ ...t, checked: checked.includes(t.semanticTypeId) }));
  }
);

export const getUploadedFileImportSettingsSelector = createSelector(
  importSettings,
  data => data && data.toJS()
);
