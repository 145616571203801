import { PAGE_ROWS_COUNT } from './constants';

function getTotalPages(data, pageSize = PAGE_ROWS_COUNT) {
  return Math.ceil(data.length / pageSize);
}

function getCurrentPageData(array, page) {
  const totalLength = array.length;
  const start = page * PAGE_ROWS_COUNT;
  const end = (page * PAGE_ROWS_COUNT) + PAGE_ROWS_COUNT;
  return array.slice(start, end > totalLength ? totalLength : end);
}

export {
  getTotalPages,
  getCurrentPageData,
};
