import { fromJS, Set } from 'immutable';

import {
  CHECK_ALL,
  CHECK_ITEM,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../common/SimpleTable/constants';

import {
  FETCH_COMPLEX_SET_DATA_REQUEST,
  FETCH_COMPLEX_SET_DATA_SUCCESS,
  FETCH_COMPLEX_SET_DATA_FAILURE,
  SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE,
  HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE,
  SIMPLE_SET_ANALYZE_SUCCESS,
  SIMPLE_SET_ANALYZE_REQUEST,
  SOLVE_AMBIGUITY,
  REMOVE_CONCEPT,
  CLEAR_ITEMS,
  AMBIGUITY_TABLE,
  SHOW_MODAL_FROM_COMPLEX_TO_EFFECT,
  HIDE_MODAL_FROM_COMPLEX_TO_EFFECT,
  EFFECT_SET_ANALYZE_SUCCESS,
  COMPLEX_SET_VIEW_RESET_STATE,
  EFFECT_SET_ANALYZE_REQUEST,
  COMPLEX_SET_ADD_AGGREGATION_RULE,
  COMPLEX_SET_RESET_FILTERS,
  COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL,
  COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL,
  COMPLEX_SET_CHANGE_NAME,
  COMPLEX_SET_CHANGE_DESCRIPTION,
  SET_PARENT_TAGS, RESOLVE_CONCEPT_COMPLEX,
  COMPLEX_SET_UPDATE_TAGS,
  SIMPLE_SET_UPDATE_TAGS,
  CLEAR_COMPLEX_SET_ERROR,
  CLEAR_COMPLEX_SET_FREE_USER_ERROR,
} from './constants';

const initialValues = fromJS({
  loading: false,
  error: false,
  data: {},
  allItems: [],
  tags: new Set(),
  aggregationRules: [],
  showSimpleSetCreationDialog: false,
  showEffectSetCreationDialog: false,
  showSimpleSetCreationFormAfterAnalyzing: false,
  showEffectSetCreationFormAfterAnalyzing: false,
  analyzing: false,
  commonFailureText: '',
  ambigiousSorting: { sortBy: 'geneName', sortDirection: 'ASC' },
  errorOnSave: null,
  setSavedSuccessfully: null,
});

const ComplexSetViewReducer = (state = initialValues, action) => {
  switch (action.type) {
    case REMOVE_CONCEPT:
      return state.update('allItems', items => (
        items.delete(items.findIndex(item => item.get('id') === action.data))
      ));

    case CLEAR_ITEMS:
      return initialValues;

    case COMPLEX_SET_ADD_AGGREGATION_RULE:
      return state.update('aggregationRules', rules => (
        rules.push(action.data)
      ));

    case COMPLEX_SET_RESET_FILTERS:
      return state.set('aggregationRules', fromJS([]));

    case SOLVE_AMBIGUITY:
      return state.update('allItems', items => (
        items.map((item) => {
          if (item.get('id') === action.data.itemId) {
            return item.update('mappedGenes', genes => (
              genes.delete(genes.findIndex(gene => (
                gene.get('id') === action.data.id
              )))
            ));
          }
          return item;
        })
      ));

    case RESOLVE_CONCEPT_COMPLEX:
      return state.update('allItems', items => (
        items.map((item) => {
          if (item.get('id') === action.data.rowId) {
            return item.merge(fromJS({ matchStatus: 'match', mappedGenes: [{ id: action.data.item.id }] }));
          }
          return item;
        })
      ));

    case FETCH_COMPLEX_SET_DATA_REQUEST:
      return state
        .set('data', fromJS({}))
        .set('loading', true)
        .set('error', false);

    case SHOW_MODAL_FROM_COMPLEX_TO_SIMPLE:
      return state.set('showSimpleSetCreationDialog', true);

    case HIDE_MODAL_FROM_COMPLEX_TO_SIMPLE:
      return state
        .set('showSimpleSetCreationDialog', false)
        .set('showSimpleSetCreationFormAfterAnalyzing', false);

    case SHOW_MODAL_FROM_COMPLEX_TO_EFFECT:
      return state.set('showEffectSetCreationDialog', true);

    case HIDE_MODAL_FROM_COMPLEX_TO_EFFECT:
      return state
        .set('showSimpleSetCreationFormAfterAnalyzing', false)
        .set('showEffectSetCreationDialog', false);

    case FETCH_COMPLEX_SET_DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('setSavedSuccessfully', true)
        .merge({ data: fromJS(action.data) });

    case FETCH_COMPLEX_SET_DATA_FAILURE:
      return state
        .set('loading', false)
        .set('error', true);

    case SIMPLE_SET_ANALYZE_REQUEST:
      return state.set('analyzing', true);

    case EFFECT_SET_ANALYZE_REQUEST:
      return state.set('analyzing', true);

    case SIMPLE_SET_ANALYZE_SUCCESS: {
      const simpleSetAnalyzeItemsOriginal = state.get('allItems');
      /* Check if array has ID, otherwise add it */
      let simpleSetAnalyzeData = action.data;
      if (simpleSetAnalyzeData.length && !simpleSetAnalyzeData[0].id) {
        simpleSetAnalyzeData = simpleSetAnalyzeData.map((item, index) => Object.assign(item, { id: index + 1 + simpleSetAnalyzeItemsOriginal.size }));
      }

      return state
        .set('analyzing', false)
        .set('allItems', fromJS(simpleSetAnalyzeData))
        .set('showSimpleSetCreationFormAfterAnalyzing', true);
    }

    case EFFECT_SET_ANALYZE_SUCCESS: {
      const effectSetAnalyzeItemsOriginal = state.get('allItems');
      /* Check if array has ID, otherwise add it */
      let effectSetAnalyzeData = action.data;
      if (effectSetAnalyzeData.length && !effectSetAnalyzeData[0].id) {
        effectSetAnalyzeData = effectSetAnalyzeData.map((item, index) => Object.assign(item, { id: index + 1 + effectSetAnalyzeItemsOriginal.size }));
      }

      return state
        .set('analyzing', false)
        .set('allItems', fromJS(effectSetAnalyzeData))
        .set('showSimpleSetCreationFormAfterAnalyzing', true);
    }

    case SIMPLE_SET_UPDATE_TAGS: {
      return state.update('tags', () => fromJS(action.data));
    }

    case COMPLEX_SET_UPDATE_TAGS: {
      return state.updateIn(['data', 'tags'], () => fromJS(action.data));
    }

    case AMBIGUITY_TABLE + CHECK_ALL:
      return state.update('allItems', items => items.map((item) => {
        if (item.get('mappedGenes').size > 1) {
          return item.set('selected', action.data);
        }
        return item;
      }));

    case AMBIGUITY_TABLE + CHECK_ITEM:
      return state.update('allItems', items => (
        items.map((item) => {
          if (item.get('id') === action.data.id) {
            return item.merge(fromJS({ selected: action.data.checked }));
          }
          return item;
        })
      ));

    case AMBIGUITY_TABLE + REMOVE_SELECTED:
      return state.update('allItems', items => (
        items.filterNot(item => item.get('selected'))
      ));

    case AMBIGUITY_TABLE + INVERT_SELECTION:
      return state.update('allItems', items => (
        items.map(item => item.merge(fromJS({ selected: !item.get('selected') })))
      ));

    case COMPLEX_SET_VIEW_RESET_STATE:
      return initialValues;

    case COMPLEX_SET_SHOW_COMMON_FAILURE_MODAL:
      return state
        .set('commonFailureText', action.data)
        .set('loading', false);

    case COMPLEX_SET_HIDE_COMMON_FAILURE_MODAL:
      return state
        .set('commonFailureText', '')
        .set('analyzing', false);

    case COMPLEX_SET_CHANGE_NAME:
      return state.updateIn(['data'], x => x.set('name', action.data));

    case COMPLEX_SET_CHANGE_DESCRIPTION:
      return state.updateIn(['data'], x => x.set('description', action.data));

    case SET_PARENT_TAGS:
      return state.set('tags', new Set(state.getIn(['data', 'tags'])));

    case CLEAR_COMPLEX_SET_ERROR: {
      return state
        .set('savedSuccessfully', false)
        .set('error', null)
        .set('errorOnSave', null);
    }

    case CLEAR_COMPLEX_SET_FREE_USER_ERROR: {
      return state
        .set('savedSuccessfully', false)
        .set('error', null)
        .set('errorOnSave', null);
    }

    default:
      return state;
  }
};

export default ComplexSetViewReducer;
