import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import { AVERAGE_SYMBOL_LENGTH } from '../../Utils/Utils';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ExportTable from '../../common/ExportTable/ExportTable';

import {
  changePageAction,
  sortTableAction,
} from './redux/actions';
import {
  getPageNumber,
  getTotalPages,
  getSectionTable,
  tableSorting,
} from './redux/selectors';

const propTypes = {
  width: PropTypes.number,
  changePage: PropTypes.func,
  sectionTable: PropTypes.instanceOf(Object).isRequired,
  sorting: PropTypes.instanceOf(Object).isRequired,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  geneName: PropTypes.string,
};

class ClinicalTrialsTable extends React.Component {
  constructor(props) {
    super(props);
    this.simpleTableRef = React.createRef(null);
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  handlePageClick = (page) => {
    const {
      changePage,
    } = this.props;

    changePage(page.selected);
  };

  getColumnPercentWidth = (percent) => {
    const { width } = this.props;
    return (percent * width) / 100;
  };

  getRowHeight = ({ index }) => {
    const { content } = this.props.sectionTable;
    const {
      disease,
      chemicalsAndDrugs,
      trialId,
      reasonForTermination,
    } = content[index];
    const padding = 20;
    const lineHeight = 30;
    const cellWidth = this.getColumnPercentWidth(18);
    const diseaseNameLength = disease.name ? disease.name.length : 0;
    const chemicalsAndDrugsNameLength = chemicalsAndDrugs.name ? chemicalsAndDrugs.name.length : 0;
    const trialIdLength = trialId ? trialId.length : 0;
    const reasonForTerminationLength = reasonForTermination ? reasonForTermination.length : 0;
    const maxLength = Math.max(diseaseNameLength, chemicalsAndDrugsNameLength, trialIdLength, trialIdLength, reasonForTerminationLength);
    const rows = Math.ceil((maxLength * AVERAGE_SYMBOL_LENGTH) / cellWidth);
    return (rows * lineHeight) + padding;
  };

  diseaseCell = ({ id, name }, rowIndex) => {
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      sectionTable: {
        content,
        allData = [],
      },
      pageNumber,
      geneName,
      totalPages,
      width,
      sorting: {
        sortBy,
        sortDirection,
      },
    } = this.props;

    const tableSettings = {
      width,
      headerHeight: 50,
      rowHeight: this.getRowHeight,
      autoHeight: true,
      sortBy,
      sortDirection,
    };

    const columns = [
      {
        label: 'Disease',
        dataKey: 'disease',
        width: this.getColumnPercentWidth(18),
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData.disease ? this.diseaseCell(rowData.disease, rowIndex) : null
        ),
        csvRenderer: rowData => (rowData.disease ? rowData.disease.name : null),
      },
      {
        label: 'Chemicals & drugs',
        dataKey: 'chemicalsAndDrugs',
        width: this.getColumnPercentWidth(18),
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData }) => <span>{rowData.chemicalsAndDrugs.name}</span>,
        csvRenderer: rowData => (rowData.chemicalsAndDrugs ? rowData.chemicalsAndDrugs.name : null),
      },
      {
        label: 'Clinical trial description',
        dataKey: 'trialId',
        width: this.getColumnPercentWidth(18),
        disableSort: true,
        exportCSV: true,
        className: 'flexColumn',
        cellRenderer: ({ rowData }) => {
          if (rowData.sources.includes('ClinicalTrials')) {
            return <a className="link" target="_blank" href={`https://clinicaltrials.gov/ct2/show/${rowData.trialId}`} rel="noreferrer">{rowData.trialId}</a>;
          }
          return <span>{rowData.trialId}</span>;
        },
      },
      {
        label: 'Source',
        dataKey: 'sources',
        width: this.getColumnPercentWidth(16),
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData }) => {
          const sources = rowData.sources || [];
          return sources.map((source, index) =>
            (source === 'Pharmaprojects' ?
              <span key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={rowData.url ? rowData.url : 'https://citeline.informa.com/drugs/results'}
                >
                  {source}
                </a>
              </span>
              : <span key={index}>{source}</span>)
          );
        },
      },
      {
        label: 'Phase',
        dataKey: 'phase',
        width: this.getColumnPercentWidth(12),
        disableSort: true,
        exportCSV: true,
        className: 'flexColumn',
        cellRenderer: ({ rowData }) => <span>{rowData.phase}</span>,
      },
      {
        label: 'Reason for termination',
        dataKey: 'reasonForTermination',
        width: this.getColumnPercentWidth(18),
        disableSort: true,
        exportCSV: true,
        cellRenderer: ({ rowData }) => <span>{rowData.reasonForTermination}</span>,
      },
    ];

    return (
      <div className="gene-detail-clinical-trials-table">
        {
          content && content.length > 0 ?
            <div className="table-wrap">
              {
                <div className="controls-block-2items">
                  {
                    totalPages > 1 &&
                    <div className="paginationContainer">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakClassName="break-me"
                        pageCount={totalPages}
                        forcePage={pageNumber}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  }
                  {
                    allData && allData.length > 0 &&
                    <ExportTable
                      content={allData}
                      columns={columns}
                      fileName={`${geneName}-clinical-trials`}
                    />
                  }
                </div>
              }
              <SimpleTable
                innerRef={this.simpleTableRef}
                settings={tableSettings}
                data={content}
                columns={columns}
                sortAction={sortTableAction}
                dynamicHeight={true}
              />
            </div> :
            <span className="therapeutic-candidates__no-data">
              There is no data to display
            </span>
        }
      </div>
    );
  }
}

ClinicalTrialsTable.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    sectionTable: getSectionTable(state),
    sorting: tableSorting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage(data) {
      dispatch(changePageAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicalTrialsTable);
