import { fromJS } from 'immutable';
// Actions
import { createAction, handleActions } from 'redux-actions';
// Utils
import { intermediateConceptsSortingTypes } from '../enum';
import { publicationsFiltersNames } from '../../../../../Sets/CreateSet/enums';
// Concepts
export const getIntermediateConceptsDataAction = createAction('@@intermediateConcepts/GET_DATA');
export const setIntermediateConceptsDataAction = createAction('@@intermediateConcepts/SET_DATA');
export const resetIntermediateConceptsAction = createAction('@@intermediateConcepts/RESET');
export const toggleIntermediateConceptAction = createAction('@@intermediateConcepts/TOGGLE');
export const toggleAllIntermediateConceptsAction = createAction('@@intermediateConcepts/TOGGLE_ALL');
export const throwIntermediateConceptsErrorAction = createAction('@@intermediateConcepts/THROW_ERROR');
export const getFilteredIntermediateConceptsAction = createAction('@@intermedieteConcepts/GET_FILTERED_CONCEPTS');
export const setFilteredIntermediateConceptsAction = createAction('@@intermedieteConcepts/SET_FILTERED_CONCEPTS');
export const sortIntermediateConceptsAction = createAction('@@intermedieteConcepts/SORT_INTERMEDIETE');
// UI Filters
export const setIntermediateConceptsFiltersAction = createAction('@@intermediateConcepts/SET_FILTERS');
export const resetIntermediateConceptsFiltersAction = createAction('@@intermediateConcepts/RESET_FILTERS');
export const filterIntermediateConceptsAction = createAction('@@intermediateConcepts/FILTER_INTERMEDIATE_CONCEPTS');
// BE Filters
export const getGeneFilterOptionsAction = createAction('@@intermedieteConcepts/GET_GENE_FILTER_OPTIONS');
export const setGeneFilterOptionsAction = createAction('@@intermedieteConcepts/SET_GENE_FILTER_OPTIONS');
export const getIntermediateConceptsBeFiltersAction = createAction('@@intermediateConcepts/GET_BE_FILTERS');
export const setIntermediateConceptsBeFiltersAction = createAction('@@intermediateConcepts/SET_BE_FILTERS');
export const resetIntermediateConceptsBeFiltersAction = createAction('@@intermediateConcepts/RESET_BE_FILTERS');
export const getSourceDetailsFilterOptionsAction = createAction('@@intermediateConcepts/GET_SOURCE_DETAILS_FILTER_OPTIONS');
export const setSourceDetailsFilterOptionsAction = createAction('@@intermediateConcepts/SET_SOURCE_DETAILS_FILTER_OPTIONS');

const initialState = fromJS({
  data: null,
  allData: null,
  error: null,
  loading: false,
  options: null,
  filters: {},
  allSelected: false,
  filterValue: '',
  beFilters: null,
  beFiltersType: null,
  geneGenomeConcepts: null,
  sorting: {
    sortBy: intermediateConceptsSortingTypes.NAME,
    sortDirection: 'ASC',
  },
});

const reducer = handleActions(
  {
    [getIntermediateConceptsDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setIntermediateConceptsDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload.data))
        .update('allData', () => fromJS(payload.data))
        .update('options', () => fromJS(payload.options))
        .update('loading', () => false)
        .update('error', () => null),
    [throwIntermediateConceptsErrorAction]: (state, { payload }) =>
      state
        .update('data', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [toggleIntermediateConceptAction]: (state, { payload }) =>
      state
        .update('data', data => data.map(d => (
          d.getIn(['concept', 'id']) === payload.id ?
            d.set('selected', payload.selected) : d
        ))),
    [toggleAllIntermediateConceptsAction]: (state, { payload }) =>
      state
        .update('allSelected', () => payload)
        .update('data', data => data.map(d => d.set('selected', payload))),
    [setIntermediateConceptsFiltersAction]: (state, { payload }) =>
      state
        .update('filters', () => fromJS(payload)),
    [filterIntermediateConceptsAction]: (state, { payload }) =>
      state
        .update('filterValue', () => fromJS(payload)),
    [setIntermediateConceptsBeFiltersAction]: (state, { payload }) =>
      state
        .update('beFilters', () => fromJS(payload.filters))
        .update('beFiltersType', () => fromJS(payload.type)),
    [resetIntermediateConceptsBeFiltersAction]: state =>
      state
        .update('beFilters', () => null)
        .update('beFiltersType', () => null)
        .update('geneGenomeConcepts', () => null),
    [getGeneFilterOptionsAction]: (state, { payload }) => (
      state.updateIn(['beFilters', payload.filterName, 'isLoading'], () => true)
    ),
    [setGeneFilterOptionsAction]: (state, { payload }) =>
      state.updateIn(['beFilters', payload.filterName], () => fromJS(payload)),
    [getSourceDetailsFilterOptionsAction]: state => (
      state.updateIn(['beFilters', publicationsFiltersNames.SOURCE_DETAILS_FILTER, 'isLoading'], () => true)),
    [setSourceDetailsFilterOptionsAction]: (state, { payload }) =>
      state.updateIn(['beFilters', publicationsFiltersNames.SOURCE_DETAILS_FILTER], () => fromJS(payload)),
    [getFilteredIntermediateConceptsAction]: state =>
      state
        .update('loading', () => true),
    [setFilteredIntermediateConceptsAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload.data))
        .update('geneGenomeConcepts', () => fromJS(payload.concepts))
        .update('filters', () => fromJS(payload.filters))
        .update('loading', () => false),
    [sortIntermediateConceptsAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [resetIntermediateConceptsFiltersAction]: state =>
      state
        .update('filters', () => initialState.get('filters'))
        .update('geneGenomeConcepts', () => initialState.get('geneGenomeConcepts')),
    [resetIntermediateConceptsAction]: () => initialState,
  },
  initialState
);

export default reducer;
