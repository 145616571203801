import React from 'react';
import PropTypes from 'prop-types';

import { getParentBlockWidth } from '../../Utils/Utils';

import FunctionalCharacterizationTable from './FunctionalCharacterizationTable';
import FunctionalCharacterizationChart from './FunctionalCharacterizationChart';

import './FunctionalCharacterizationChapter.css';

const propTypes = {
  gene: PropTypes.string.isRequired,
  itsNewConcept: PropTypes.bool,
  geneName: PropTypes.string,
};

class FunctionalCharacterizationChapter extends React.Component {
  state = {
    showInfoText: false,
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const { gene, itsNewConcept, geneName } = this.props;
    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div className="functional-characterization">
        <div className="gene-details-section-title title2">
          <span>Functional Characterization</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              The Functional Characteristics section contain humanly curated annotations of gene functions which are submitted by scientists and reviewed by
              <a className="link" href="https://www.ncbi.nlm.nih.gov/gene/about-generif" target="blank"> NIH/NCBI</a>.
              The annotations are quotations from Pubmed articles, including a link to Pubmed reference.
            </p>
            <p>
              The annotations are classified in 6 categories: protein interactions, cell functions, pathways, phenotypes, diseases and response to chemicals.
              For each category the number of functional annotations are shown in a bar chart. Click on the bars in the bar chart to filter the table for these types of annotations.
              Multiple selections are possible.
            </p>
            <p>
              The table contains the excerpt from the Pubmed article containing the functional aspect, the classification of the annotation, the data when the annotation was made (this is not the date of the publication) and a link to the Pubmed article.
              Click on the link to open a pop-up of the Publication Detail page. Here the original Pubmed reference can be found by clicking on the title of the publication.
            </p>
          </div>
        }
        <div className="gene-details-section-body">
          <FunctionalCharacterizationChart
            gene={gene}
            itsNewConcept={itsNewConcept}
            geneName={geneName}
          />
          <FunctionalCharacterizationTable
            gene={gene}
            geneName={geneName}
            width={chapterWidth}
            itsNewConcept={itsNewConcept}
          />
        </div>
      </div>
    );
  }
}

FunctionalCharacterizationChapter.propTypes = propTypes;
export default FunctionalCharacterizationChapter;
