import React from 'react';
import * as d3js from 'd3';
import PropTypes from 'prop-types';

import Cursor from '../../../../../theme/assets/img/cursor.svg';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  openChartsPopup: PropTypes.func,
};

class SingleTargetForCancerBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.divId = 'single-target-for-cancer-bar-chart';
  }

  componentDidMount = () => {
    this.drawChart();
  };

  componentDidUpdate = () => {
    this.drawChart();
  };

  drawChart = () => {
    const {
      width,
      data: {
        data,
        maxScore,
        rows,
      },
      openChartsPopup,
    } = this.props;
    const height = data.length ? data.length * 150 : 150;
    const margin = {
      top: rows.length > 5 ? 240 : 154,
      right: 0,
      bottom: 0,
      left: 0,
    };

    d3js.selectAll(`#${this.divId} > *`).remove();

    if (!data.length) {
      return;
    }

    const svg = d3js.select(`#${this.divId}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3js.scaleLinear()
      .range([0, width])
      .domain([0, maxScore]);
    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3js.axisBottom(x))
      .select('.domain')
      .remove();
    xAxis.selectAll('.tick')
      .remove();

    const y = d3js.scaleBand()
      .range([height, 0])
      .domain(rows)
      .paddingInner(0.7)
      .paddingOuter(0.35);
    const yAxis = svg.append('g')
      .call(d3js.axisLeft(y))
      .select('.domain')
      .remove();
    yAxis.selectAll('.tick')
      .remove();

    const bars = svg.selectAll('.bar-wrap')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar-wrap');

    function calcBarWidth(score) {
      const barWidth = Math.max(30, x(score || 0));
      return Math.min(barWidth, width);
    }

    bars.append('rect')
      .attr('class', 'bar')
      .attr('x', x(0))
      .attr('y', d => y(d.row))
      .attr('height', y.bandwidth())
      .attr('width', d => calcBarWidth(d.score))
      .attr('fill', '#76649d')
      .attr('opacity', '.70')
      .style('cursor', 'pointer')
      .on('click', (d) => {
        openChartsPopup(d.gene.id, d.gene.name);
      });

    bars.append('svg:image')
      .attr('xlink:href', Cursor)
      .attr('width', 20)
      .attr('height', 20)
      .attr('x', x(0))
      .attr('y', d => y(d.row) + 5)
      .style('pointer-events', 'none');

    bars.append('text')
      .attr('class', 'label')
      .attr('x', x(0))
      .attr('y', d => y(d.row) - 5)
      .text(d => d.score);
  };

  render() {
    return (
      <div id={this.divId} className="barchart" />
    );
  }
}

SingleTargetForCancerBarChart.propTypes = propTypes;

export default SingleTargetForCancerBarChart;
