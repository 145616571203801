import { operation } from './constants';
import {fromJS} from 'immutable';

const customFilter = ['classifications', 'mostFrequentIndications'];

export const appllyFilters = (data, filters) => {
  let filteredData = data.toJS();
  const filtersJS = filters.toJS();

  Object.keys(filtersJS).forEach((key) => {
    if (customFilter.includes(key)) {
      filteredData = filteredData.filter((row) => checkCustomFilter(row[key], filtersJS[key], key));
    } else {
      filteredData = filteredData.filter((row) => checkFilter(row[key], filtersJS[key]));
    }
  });

  return fromJS(filteredData);
};

function checkFilter(rowElement, filter) {
  switch (filter.operation) {
    case operation.contains:
      return rowElement.toUpperCase().includes(filter.value.toUpperCase());
    case operation.equals:
      return typeof(rowElement) === 'number' ? rowElement == filter.value : rowElement.toUpperCase() == filter.value.toUpperCase();
    case operation.lessThan:
      return rowElement < filter.value;
    case operation.moreThan:
      return rowElement > filter.value;
    case operation.notEquals:
      return typeof(rowElement) === 'number' ? rowElement != filter.value : rowElement.toUpperCase() != filter.value.toUpperCase();
  }

  return true;
}

function checkCustomFilter(rowElement, filter, column) {
  if (column === 'classifications') {//strings array
    switch (filter.operation) {
      case operation.contains:
        return rowElement.some(str => str.toUpperCase().includes(filter.value.toUpperCase()));
      case operation.equals:
        return rowElement.some(str => str.toUpperCase() === filter.value.toUpperCase());
      case operation.notEquals:
        return rowElement.every(str => str.toUpperCase() !== filter.value.toUpperCase());
    }
  }

  if (column === 'mostFrequentIndications') {//object of keys
    if (!Object.keys(rowElement).length) return filter.operation === operation.notEquals;//for not equal any request is true for empty object

    switch (filter.operation) {
      case operation.contains:
        return Object.keys(rowElement).some(str => str.toUpperCase().includes(filter.value.toUpperCase()));
      case operation.equals:
        return Object.keys(rowElement).some(str => str.toUpperCase() === filter.value.toUpperCase());
      case operation.notEquals:
        return Object.keys(rowElement).every(str => str.toUpperCase() !== filter.value.toUpperCase());
    }
  }

  return true;
}
