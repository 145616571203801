import { RELATIVE_PATH } from '../../constantsCommon';
import { semanticCategoriesOrder } from './constants';
import { SearchSortFilterEnum, SearchSourceFilterEnum } from './enums';

function getDataForSearchRequest(data = {}) {
  const {
    exactValue,
    plainValue,
    page,
    size,
    dateFrom,
    dateTo,
    pubSource,
    researchTypes = [],
    publicationTypes = [],
    isPublicationOnly,
    impactFactorFrom,
    impactFactorTo,
    sources = [],
  } = data;

  const defaultPublicationSource = exactValue ? SearchSourceFilterEnum.ABSTRACT : null;
  const publicationSource = data.publicationSource || data.cooccurrenceType || defaultPublicationSource;
  const cooccurrenceType = exactValue ? publicationSource : null;
  const sort = data.sort || SearchSortFilterEnum.MOST_RECENT;

  const post = {
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...(pubSource && { pubSource }),
    ...(researchTypes && researchTypes.length ? { researchTypes } : []),
    ...(publicationTypes && publicationTypes.length ? { publicationTypes } : []),
    ...(publicationSource && { publicationSource }),
    ...(impactFactorFrom && { impactFactorFrom }),
    ...(impactFactorTo && { impactFactorTo }),
    ...(sources && sources.length ? { sources } : []),
  };

  const params = {
    page: page || 0,
    size: size || 15,
    phrase: (exactValue || plainValue || '').trim(),
    ...(sort && { sort }),
    ...(cooccurrenceType && { cooccurrenceType }),
    ...(isPublicationOnly && { isPublicationOnly }),
  };

  return { params, post };
}

function getSearchLinkParams(location) {
  const urlParams = new URLSearchParams(location);

  return {
    exactValue: urlParams.get('exactValue'),
    plainValue: urlParams.get('plainValue') || urlParams.get('name'),
    page: urlParams.get('page'),
    size: urlParams.get('size'),
    sort: urlParams.get('sort'),
    dateFrom: urlParams.get('dateFrom'),
    dateTo: urlParams.get('dateTo'),
    pubSource: urlParams.get('pubSource'),
    researchTypes: urlParams.getAll('researchTypes'),
    publicationTypes: urlParams.getAll('publicationTypes'),
    publicationSource: urlParams.get('publicationSource'),
    isPublicationOnly: urlParams.get('isPublicationOnly'),
    impactFactorFrom: urlParams.get('impactFactorFrom'),
    impactFactorTo: urlParams.get('impactFactorTo'),
    sources: urlParams.getAll('sources'),
    cooccurrenceType: urlParams.get('cooccurrenceType'),
  };
}

function buildSearchLink(data = {}) {
  const pathname = `${RELATIVE_PATH}/research`;
  const search = new URLSearchParams();

  Object.keys(data).forEach((item) => {
    switch (item) {
      case 'sources':
      case 'publicationTypes':
      case 'researchTypes': {
        if (data[item]) {
          data[item].forEach(d => search.append(item, d));
        }
        break;
      }
      default: {
        if (data[item]) {
          search.append(item, data[item]);
        }
        break;
      }
    }
  });

  return { pathname, search: search.toString() };
}

function getSearchLink(location) {
  return `${RELATIVE_PATH}/${location.pathname}${location.search}`;
}

function getFirst180Characters(text) {
  return text && text.length > 180 ? `${text.substring(0, 180)}...` : text;
}

function sortSemanticCategories(semCats) {
  return semCats.map(d => ({
    ...d,
    index: semanticCategoriesOrder.indexOf(d.categoryName || d.category || d.name),
  })).sort((a, b) => a.index - b.index);
}

function highlightText(highlightsArr, text) {
  const getTemplateString = key =>
    `<span class="publications-list-item__highlight">${key}</span>`;

  return highlightsArr
    .filter(h => text.length > h.start)
    .sort((a, b) => a.start - b.start)
    .reduce((textArr, h, i, arr) => {
      if (i === 0) {
        textArr.push(text.substring(0, h.start));
      } else {
        textArr.push(text.substring(arr[i - 1].end, h.start));
      }
      textArr.push(getTemplateString(text.substring(h.start, h.end)));
      if (i === arr.length - 1) {
        textArr.push(text.substring(h.end));
      }
      return textArr;
    }, [])
    .join('') || text;
}

function checkFiltersApplying(formData) {
  if (!formData) return false;
  return !!Object.keys(formData).filter(field => {
    return field !== 'publicationSource' && field !== 'sort';
  }).length;
}

export {
  getDataForSearchRequest,
  getSearchLinkParams,
  buildSearchLink,
  getSearchLink,
  getFirst180Characters,
  sortSemanticCategories,
  highlightText,
  checkFiltersApplying,
};
