import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Components
import Loader from '../../common/Loader/Loader';
import TaxonomiesField from '../Taxonomies/TaxonomiesField';
import FormRadio from '../../common/FormElements/FormRadio';
import FormInput from '../../common/FormElements/FormInput';
import FormTextarea from '../../common/FormElements/FormTextarea';
import AddTag from '../../common/AddTag/AddTag';
import AddTagContent from '../../common/AddTag/AddTagContent';
import FormSelect from '../../common/FormElements/FormSelect';
import AmbiguousConceptsTable from '../SaveAsSet/Components/AmbiguousConceptsTable/AmbiguousConceptsTable';
import UnresolvedConceptsTable from '../SaveAsSet/Components/UnresolvedConceptsTable/UnresolvedConceptsTable';
// Utils
import { checkAmbiguity } from '../../Utils/Utils';
import { taxonomies, types } from '../../../constantsCommon';
import { SIMPLE_SET_FROM_COMPLEX_SET_FORM } from '../ComplexSetView/enums';
// Store
import {
  clearItemsAction,
  removeConceptAction,
  solveAmbiguityAction,
  resolveConceptAction,
  simpleSetUpdateTagsAction,
} from '../ComplexSetView/actions';
import * as SELECTORS from '../ComplexSetView/selectors';

const propTypes = {
  solveAmbiguity: PropTypes.func,
  removeConcept: PropTypes.func,
  resolveConcept: PropTypes.func,
  analyzing: PropTypes.bool,
  setId: PropTypes.string,
  actionForAnalyzeStart: PropTypes.func,
  submitHandler: PropTypes.func,
  fullSimpleSetForm: PropTypes.bool,
  tags: PropTypes.instanceOf(Array),
  ambigiousItems: PropTypes.instanceOf(Array),
  unresolvedItems: PropTypes.instanceOf(Array),
  closeHandler: PropTypes.func,
  error: PropTypes.string,
  textColumns: PropTypes.instanceOf(Array),
  sorting: PropTypes.instanceOf(Object),
  updateTags: PropTypes.func,
  projectId: PropTypes.string,
};

class CreateSimpleSetFromComplexSetModal extends React.Component {
  renderContent() {
    const {
      // from parent
      analyzing,
      setId,
      projectId,
      actionForAnalyzeStart,
      submitHandler,
      fullSimpleSetForm,
      tags,
      ambigiousItems,
      unresolvedItems,
      error,
      textColumns = [],
      sorting,
      updateTags,
    } = this.props;

    if (analyzing) {
      return (
        <Loader isLoading={true} />
      );
    }

    return (
      <div className="simple-from-complex__content">
        <form className="simple-from-complex__form">
          {
            fullSimpleSetForm &&
              <>
                <div className="row">
                  <span className="form-label form-label-top">
                    <label htmlFor="name">Set name:</label>
                  </span>
                  <span className="form-control">
                    <Field
                      id="name"
                      name="name"
                      placeholder="Name"
                      component={FormInput}
                      type="text"
                    />
                  </span>
                </div>
                <div className="row">
                  <span className="form-label form-label-top">
                    <label htmlFor="description">Description:</label>
                  </span>
                  <span className="form-control">
                    <Field
                      id="description"
                      name="description"
                      placeholder="Description"
                      rows="5"
                      component={FormTextarea}
                      type="text"
                    />
                  </span>
                </div>
                <div className="row">
                  <span className="form-label form-label-top">
                    <label htmlFor="description">Tags:</label>
                  </span>
                  <span className="form-control">
                    <AddTag
                      tagList={tags}
                      updateTags={updateTags}
                    />
                    <AddTagContent
                      tagList={tags}
                      updateTags={updateTags}
                    />
                  </span>
                </div>
              </>
          }
          {
            !fullSimpleSetForm &&
              <>
                <div className="row">
                  <span className="form-label center-label">
                    <label htmlFor="identifierColumnName">Column name :</label>
                  </span>
                  <div className="form-control create-set-from-complex__select">
                    <Field
                      bordered={true}
                      name="identifierColumnNumber"
                      component={FormSelect}
                      options={textColumns}
                      customClassName="form-select"
                    />
                  </div>
                </div>
                <div className="row">
                  <span className="form-label">
                    <label htmlFor="semanticType">Type:</label>
                  </span>
                  <span className="form-control">
                    {
                      types.map((type, index) => (
                        <Field
                          key={index}
                          name="semanticType"
                          labelValue={type.name}
                          component={FormRadio}
                          value={type.value}
                          type="radio"
                        />
                      ))
                    }
                  </span>
                </div>
                <div className="row">
                  <span className="form-label">
                    <label htmlFor="taxonomy">Taxonomy:</label>
                  </span>
                  <div className="form-control">
                    {taxonomies.map((taxonomy, index) => (
                      <Field
                        key={`${index}__${taxonomy}`}
                        name="taxonomy"
                        component={FormRadio}
                        value={taxonomy}
                        type="radio"
                      />
                    ))
                    }
                    <div className="create-set-from-complex__select">
                      <TaxonomiesField
                        name="taxonomy"
                        dropDownHeight={110}
                        exclude={taxonomies}
                        component={FormSelect}
                      />
                    </div>
                  </div>
                </div>
              </>
          }
          {
            error &&
            <div className="error-block">
              {error}
            </div>
          }
          {
            !analyzing && checkAmbiguity(ambigiousItems) &&
            <AmbiguousConceptsTable
              items={ambigiousItems}
              solveAmbiguityFunction={this.props.solveAmbiguity}
              removeConceptFunction={this.props.removeConcept}
              sorting={sorting}
            />
          }
          {
            unresolvedItems && unresolvedItems.length > 0 &&
            <UnresolvedConceptsTable
              items={unresolvedItems}
              sorting={sorting}
              resolveConceptFunction={this.props.resolveConcept}
              removeConceptFunction={this.props.removeConcept}
            />
          }
          <div className="row button-row">
            {
              fullSimpleSetForm ?
                <div>
                  <button
                    className="btn btn-success"
                    type="button"
                    disabled={checkAmbiguity(ambigiousItems)}
                    onClick={() => submitHandler({ setId, projectId })}
                  >
                    Save as simple set
                  </button>
                </div>
                :
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => actionForAnalyzeStart({ setId, projectId })}
                >
                  Analyze
                </button>
            }
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="simple-from-complex-root">
        <div className="simple-from-complex-content">
          <div className="page-title">
            Create simple set
          </div>
          <div
            className="close-button"
            onClick={
              () => this.props.closeHandler()
            }
          >
            <svg
              width="40"
              height="40"
            >
              <circle cx="20" cy="20" r="20" fill="black" />
              <g transform="rotate(45 20 20)">
                <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
                <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
              </g>
            </svg>
          </div>
          {
            this.renderContent()
          }
        </div>
      </div>
    );
  }
}

CreateSimpleSetFromComplexSetModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    unresolvedItems: SELECTORS.getUnresolvedItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    solveAmbiguity(data) {
      dispatch(solveAmbiguityAction(data));
    },
    resolveConcept(data) {
      dispatch(resolveConceptAction(data));
    },
    removeConcept(index) {
      dispatch(removeConceptAction(index));
    },
    clearItems() {
      dispatch(clearItemsAction());
    },
    updateTags(data) {
      dispatch(simpleSetUpdateTagsAction(data));
    },
  };
}

const composition = compose(
  reduxForm({
    form: SIMPLE_SET_FROM_COMPLEX_SET_FORM,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(CreateSimpleSetFromComplexSetModal);
