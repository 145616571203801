import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getParentBlockWidth } from '../../../Utils/Utils';
// Components
import Loader from '../../../common/Loader/Loader';
import ChordDiagram from './ChordDiagram';
import GeneticVariationsTable from './GeneticVariationsTable';
import AnimalModelsTable from './AnimalModelsTable';
import DiseaseAnnotationTable from './DiseaseAnnotationTable';
// Store
import { getLoadingSelector, getErrorSelector } from '../redux/selectors';

const propTypes = {
  gene: PropTypes.string.isRequired,
  geneName: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

class CancerDisordersChapter extends React.Component {
  state = {
    showInfoText: false,
  };

  toggleInfoText = (stateProp) => {
    this.setState({ showInfoText: !stateProp });
  };

  render() {
    const {
      gene,
      geneName,
      error,
      loading,
    } = this.props;
    const { showInfoText } = this.state;
    const chapterWidth = getParentBlockWidth('gene-details-section-body');

    return (
      <div className="disease-disorders">
        <div className="gene-details-section-title title2">
          <span>Cancer</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText(showInfoText)}
          />
        </div>
        {
          showInfoText &&
          <div className="gene-details-info__main">
            <p>
              The Cancer section provides the following information on neoplastic diseases associated with the gene:
            </p>
            <ul>
              <li>Neoplastic Diseases associated through various types of disease relevant annotations</li>
              <li>Neoplastic Diseases associated with variants of the gene</li>
              <li>Neoplastic Diseases that are associated with animal gene knockouts or variants of the gene</li>
              <li>The gene interaction network that is involved in the top 25 Neoplastic diseases</li>
            </ul>
          </div>
        }
        {
          !loading &&
          <div>
            <ChordDiagram gene={gene} geneName={geneName} />
            <GeneticVariationsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <AnimalModelsTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
            <DiseaseAnnotationTable
              gene={gene}
              geneName={geneName}
              width={chapterWidth}
            />
          </div>
        }
        <Loader isLoading={loading && !error} />
        {
          error &&
          <div className="row text-center error-text">
            Sorry, error occurred.
            <br />
            { error }
          </div>
        }
      </div>
    );
  }
}

CancerDisordersChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getLoadingSelector(state),
    error: getErrorSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(CancerDisordersChapter);
