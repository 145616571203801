import React from 'react';
import PropTypes from 'prop-types';

import './AsterPlot.css';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  score: PropTypes.number,
};

export class AsterPlot extends React.PureComponent {
  componentDidMount() {
    this.initDiagram(this.props.data);
  }

  componentDidUpdate() {
    window.d3.select(`#${this.props.id}`).selectAll('*')
      .remove();
    this.initDiagram(this.props.data);
  }

  initDiagram(data) {
    const width = 500;
    const height = 500;
    const radius = Math.min(width, height) / 2;
    const innerRadius = 0.3 * radius;

    const pie = window.d3.layout.pie()
      .sort(null)
      .value(d => (
        d.width
      ));

    const tip = window.d3.tip()
      .attr('class', 'aster--d3-tip')
      .offset([0, 0])
      .html(d => (
        d.data.label + ': <span style=\'color:orangered\'>' + d.data.score + '</span>' // eslint-disable-line prefer-template
      ));

    const arc = window.d3.svg.arc()
      .innerRadius(innerRadius)
      .outerRadius((d) => {
        const outerRadius = ((radius - innerRadius) * (d.data.score / 100.0)) + innerRadius;
        return outerRadius > radius ? radius : outerRadius;
      });

    const outlineArc = window.d3.svg.arc()
      .innerRadius(innerRadius)
      .outerRadius(radius);

    const svg = window.d3.select(`#${this.props.id}`).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    svg.call(tip);

    data.forEach((d) => {
      d.width = d.weight; // eslint-disable-line no-param-reassign
    });

    // for (let i = 0; i < data.score; i++) { console.log(data[i].id) }

    svg.selectAll('.aster--solidArc')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('fill', d => (
        d.data.color
      ))
      .attr('class', 'aster--solidArc')
      .attr('stroke', 'gray')
      .attr('d', arc)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);

    svg.selectAll('.outlineArc')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', 'gray')
      .attr('class', 'outlineArc')
      .attr('d', outlineArc);


    // calculate the weighted mean score
    const score = data.reduce((a, b) => (
      a + (b.score * b.weight)
    ), 0)
      /
      data.reduce((a, b) => (
        a + b.weight
      ), 0);

    svg.append('svg:text')
      .attr('class', 'aster--score')
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle') // text-align: right
      .text(Math.round(this.props.score || score));
  }

  render() {
    return (
      <div id={this.props.id} className="aster" />
    );
  }
}

AsterPlot.propTypes = propTypes;
AsterPlot.defaultProps = {
  data: [],
};
