import { fromJS } from 'immutable';

import {
  SORT,
} from '../../../common/SimpleTable/constants';

import {
  SET_PATH_FOR_PATH_DETAILS,
  PATH_DETAILS_SUCCEEDED,
  PATH_DETAILS_FAILED,
  PATH_DETAILS_TABLE,
  PATH_DETAILS_RESET,
  GENE_LITERATURE_TABLE,
} from './constants';

const initialState = fromJS({
  path: {},
  pathDetails: {
    table: [],
    chords: [],
    regions: [],
    targetGene: {},
    literatureTable: [],
  },
  error: null,
  loading: true,
  pathDetailsSorting: { sortBy: '', sortDirection: 'ASC' },
  literatureTableSorting: { sortBy: '', sortDirection: 'ASC' },
});

const pathDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATH_FOR_PATH_DETAILS:
      return state.merge(fromJS({
        path: action.data,
      }));

    case PATH_DETAILS_SUCCEEDED:
      return state.merge(fromJS({
        pathDetails: action.data,
        error: null,
        loading: false,
      }));

    case PATH_DETAILS_FAILED:
      return state.merge(fromJS({
        pathDetails: {
          table: [],
          chords: [],
          regions: [],
          targetGene: {},
        },
        error: action.message,
        loading: false,
      }));

    case PATH_DETAILS_TABLE + SORT:
      return state.merge(fromJS({
        pathDetailsSorting: action.data,
      }));

    case GENE_LITERATURE_TABLE + SORT:
      return state.merge(fromJS({
        literatureTableSorting: action.data,
      }));

    case PATH_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
};

export default pathDetails;
