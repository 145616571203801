import { put, select, call, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';
import { mapAndFormatConceptsToSave } from '../utils';
import { setTypesEnum } from '../constants';
import { getFullTypeByID } from '../../../Utils/Utils';
import { transformFileDataToConcepts } from '../../../common/UploadFile/utils';
// Store
import * as s from './selectors';
import * as a from './actions';
import { showFreeAccountLimitDialogAction } from '../../../common/FreeAccountLimitationDialog/store/reducer';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';

function* saveAsSetWorker({ payload }) {
  try {
    const { formData, isPopup, navigate, onSavingSet } = payload;
    const projectId = formData.project.id;
    const isProjectTab = checkProjectTab(projectId);

    const items = yield select(s.getSaveAsSetConceptsForSaveSelector);
    const tags = yield select(s.getSaveAsSetTagsForSaveSelector);
    const setType = yield select(s.getSaveAsSetTypeSelector);
    const setIsSimple = yield select(s.getSaveAsSetTypeIsSimpleOrEffectSelector);
    const complexSetDataToSend = yield select(s.getSaveAsSetComplexDataToSendSelector);

    const addProjectSet = setIsSimple ? Api.addProjectSet : Api.updateProjectSetConfig;
    const addPersonalSet = setIsSimple ? Api.addSet : Api.updateSetConfig;
    const apiToCall = isProjectTab ? addProjectSet : addPersonalSet;

    if (setType === setTypesEnum.COMPLEX) {
      const {
        complexSetConfigFormValues,
        config: { id, columnsConfiguration },
      } = complexSetDataToSend;

      const data = {
        id,
        tags,
        setType,
        columnsConfiguration: columnsConfiguration.map(c => ({
          ...c,
          content: complexSetConfigFormValues[`${c.colNumber}_content`],
          type: complexSetConfigFormValues[`${c.colNumber}_contentType`],
        })),
        ...formData,
        ...(isProjectTab && { projectId }),
      };
      yield call(apiToCall, data);
    } else {
      const data = {
        tags,
        items,
        setType,
        ...formData,
        ...(isProjectTab && { projectId }),
      };
      const savedSetData = yield call(apiToCall, data);
      if (onSavingSet) {
        onSavingSet({
          name: formData.name,
          projectId: formData.project.id,
          id: isProjectTab ? savedSetData.data.id : savedSetData.data,
        });
      }
    }

    if (!isPopup) {
      navigate(`${RELATIVE_PATH}/sets`, { state: { redirectedProjectId: projectId }});
    }
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put(showFreeAccountLimitDialogAction());
    } else {
      yield put(a.throwSaveAsSetErrorAction(e.message));
    }
  }
}

function* getSaveAsSetConceptsWorker({ payload }) {
  try {
    const { data } = yield call(Api.getShortConceptsDetails, { post: payload });
    yield put(a.updateSaveAsSetConceptsAction(mapAndFormatConceptsToSave(data)));
  } catch (e) {
    yield put(a.throwSaveAsSetConceptsErrorAction(e.message));
  }
}

function* getFileDataForSaveAsSetWorker({ payload }) {
  try {
    const { fileData, fileType, uploadOnlySimple } = payload;
    if (fileData.isSimple) {
      const conceptType = getFullTypeByID(fileType);
      const concepts = transformFileDataToConcepts(fileData.simpleSet.content, conceptType);
      yield put(a.toggleSaveAsSetComplexDialogAction(false));
      yield put(a.updateSaveAsSetTypeAction(setTypesEnum.SIMPLE));
      yield put(a.updateSaveAsSetConceptsAction(concepts));
    }
    if (!uploadOnlySimple && fileData.complexSet) {
      yield put(a.updateSaveAsSetConceptsAction(null));
      yield put(a.toggleSaveAsSetComplexDialogAction(true));
      yield put(a.putSaveAsSetComplexDataAction(fileData.complexSet));
    }
  } catch (e) {
    yield put(a.throwSaveAsSetErrorAction(e.response.data.message));
  }
}

function* saveAsSetSaga() {
  yield takeLatest(a.saveAsSetRequestedAction, saveAsSetWorker);
  yield takeLatest(a.getSaveAsSetConceptsAction, getSaveAsSetConceptsWorker);
  yield takeLatest(a.getSaveAsSetConceptsFromFileAction, getFileDataForSaveAsSetWorker);
}

export default saveAsSetSaga;
