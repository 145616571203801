import {
  GET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS,
  SET_BACKGROUND_CELLS_FILTER_OPTION,
  UPDATE_BACKGROUND_SET_CONFIG,
  TOGGLE_BACKGROUND_SET_OPTION,
  TOGGLE_BACKGROUND_CELLS_LOADER,
  SET_BACKGROUND_SET_FILTER_OPTION,
  SWITCH_SHOULD_UPDATE_DATA_FLAG,
  CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
} from './constants';

export const getBackgroundCellsAction = () => (
  {
    type: GET_BACKGROUND_CELLS,
  }
);

export const setBackgroundCellsAction = data => (
  {
    type: SET_BACKGROUND_CELLS,
    data,
  }
);

export const setBackgroundCellsFilterOptionAction = data => (
  {
    type: SET_BACKGROUND_CELLS_FILTER_OPTION,
    data,
  }
);

export const toggleBackgroundCellsLoaderAction = data => (
  {
    type: TOGGLE_BACKGROUND_CELLS_LOADER,
    data,
  }
);

export const updateBackgroundSetConfigAction = data => (
  {
    type: UPDATE_BACKGROUND_SET_CONFIG,
    data,
  }
);

export const toggleBackgroundSetOptionAction = data => (
  {
    type: TOGGLE_BACKGROUND_SET_OPTION,
    data,
  }
);

export const setBackgroundSetFilterOptionAction = data => (
  {
    type: SET_BACKGROUND_SET_FILTER_OPTION,
    data,
  }
);

export const switchShouldUpdateDataFlagAction = data => (
  {
    type: SWITCH_SHOULD_UPDATE_DATA_FLAG,
    data,
  }
);

export const clearBackgroundSetActiveProjectId = () => (
  {
    type: CLEAR_BACKGROUND_SET_ACTIVE_PROJECT_ID,
  }
);

export const setBackgroundSetActiveProjectId = data => (
  {
    type: SET_BACKGROUND_SET_ACTIVE_PROJECT_ID,
    data,
  }
);
