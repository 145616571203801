import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';

const propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  closeCb: PropTypes.func,
  hideDialog: PropTypes.func,
  text: PropTypes.string,
};

const VersionModal = (props) => {
  const {
    isOpen,
    closeCb,
    hideDialog,
    onConfirm,
    text,
  } = props;

  if (!isOpen) {
    return null;
  }

  function handleClose() {
    if (closeCb) {
      closeCb();
    }
    hideDialog();
  }

  function handleConfirm() {
    onConfirm();
    hideDialog();
  }

  return (
    <ConfirmationDialog
      onConfirm={handleConfirm}
      onCancel={handleClose}
      cancelBtnText="No"
      isOpen={isOpen}
      closeCb={handleClose}
      text={text}
    />
  );
};

VersionModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isOpen: SELECTORS.getVersionDialogIsOpenSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideDialog(data) {
      dispatch(ACTIONS.hideVersionDialogAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VersionModal);
