import React from 'react';
import PropTypes from 'prop-types';

// Components
import CreateSet from '../CreateSet/CreateSet';
import ModalComponent from '../../ModalComponent/ModalComponent';

const propTypes = {
  config: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
};

const CreateSetModal = (props) => {
  const {
    config,
    isOpen,
    closeCb,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <CreateSet
        config={config}
        closePopup={closeCb}
      />
    </ModalComponent>
  );
};

CreateSetModal.propTypes = propTypes;

export default React.memo(CreateSetModal);
