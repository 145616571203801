import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

// Component
import LegendComponent from '../LegendComponent/LegendComponent';
// Utils
import { calculatePercent, exportToPNG, exportToSVG } from '../../Utils/Utils';
import { drawPieChart } from './drawPieChart';
import { colors } from './colors';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  chartName: PropTypes.string,
  expressionName: PropTypes.string,
  isHiddenExport: PropTypes.bool,
  id: PropTypes.string,
  geneName: PropTypes.string,
  title: PropTypes.string,
  antibodySum: PropTypes.number,
};

const PieChart = (props) => {
  const {
    expressionName,
    chartName,
    title,
    data,
    isHiddenExport,
    id,
    geneName,
    antibodySum,
  } = props;

  const legendsParams = useMemo(() => (
    data && Object.entries(data).map(el => (
      { label: `${el[0]}, ${calculatePercent(antibodySum, el[1])}%, number of antibodies - ${el[1]}` }
    ))
  ), [data, antibodySum]);

  const initBoxplot = useCallback((showTitle = false) => {
    drawPieChart({
      data,
      htmlRoot: id,
      showTitle,
      chartTitle: title,
      chartName,
      geneName,
      antibodySum,
    });
  }, [data, id, title, chartName, geneName, antibodySum]);

  const exportToPNGCb = useCallback(() => {
    initBoxplot(true);
    const diagram = document.querySelector(`#${id} svg`);
    const name = `${expressionName}_${chartName}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, expressionName);
    }
    initBoxplot();
  }, [id, expressionName, chartName, initBoxplot]);

  const exportToSVGCb = useCallback(() => {
    initBoxplot(true);
    const container = document.querySelector(`#${id}`);
    const name = `${expressionName}_${chartName}.svg`;
    if (container) {
      exportToSVG(container, name, false, true, expressionName);
    }
    initBoxplot();
  }, [id, expressionName, chartName, initBoxplot]);

  useEffect(() => {
    if (data) {
      initBoxplot();
    }
  }, [data]);

  useEffect(() => () => {
    const tooltip = document.querySelector(`.chart-tooltip_${chartName}`);
    if (tooltip) {
      tooltip.remove();
    }
  }, []);

  return (
    <div className="pie-chart-chapter">
      <div className="pie-plot-controls">
        {
          !isHiddenExport &&
          <div className="bar-chart-controls">
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button bar-chart-controls__png"
                onClick={exportToPNGCb}
                title="Export to png"
              />
              PNG
            </div>
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button"
                onClick={exportToSVGCb}
                title="Export to svg"
              />
              SVG
            </div>
          </div>
        }
      </div>
      <div
        className="pie-chart"
        id={id}
      />
      <LegendComponent
        legendsParams={legendsParams || []}
        legendsColors={colors}
        keyToName="label"
      />
    </div>
  );
};

PieChart.propTypes = propTypes;

export default React.memo(PieChart);
