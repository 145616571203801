import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloud from 'd3-cloud';

// Store
import {
  conceptDetailsRequest,
  loading,
  conceptCategoryAnalysisReset,
} from '../ConceptDetails/actions';
import {
  getConceptDetails,
  getLoadingKey,
} from '../ConceptDetails/selectors';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Styles
import './WordCloud.css';

const propTypes = {
  categoryAnalysisReset: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  words: PropTypes.instanceOf(Array),
  navigate: PropTypes.func,
};

class WordCloud extends React.Component {
  static WIDTH = 600;
  static HEIGHT = 400;

  conceptDetails = (id, isGene) => {
    const detailsPath = isGene ? 'gene-details' : 'concept-details';
    this.props.categoryAnalysisReset();
    this.props.navigate(`${RELATIVE_PATH}/${detailsPath}/${id}`);
  };

  componentDidMount() {
    const { words } = this.props;
    const fullWidth = this.props.width || WordCloud.WIDTH;
    const fullHeight = this.props.height || WordCloud.HEIGHT;

    const scores = this.props.words.map(word => (
      word.score
    ));

    const maxScore = Math.max.apply(null, scores);
    const minScore = Math.min.apply(null, scores);

    const margin = {
      top: 20,
      right: 20,
      bottom: 40,
      left: 20,
    };
    const width = fullWidth - margin.left - margin.right;
    const height = fullHeight - margin.top - margin.bottom;

    const fontSize = window.d3.scale.linear().domain([minScore, maxScore])
      .range([15, 30]);

    const svg = window.d3.select('.word-cloud').append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    cloud()
      .timeInterval(10)
      .size([width, height])
      .words(words)
      .rotate(() => 0)
      .font('monospace')
      .fontSize(d => fontSize(d.score))
      .text(d => d.name)
      .spiral('archimedean')
      .on('end', draw)
      .start();

    const wordcloud = svg.append('g')
      .attr('class', 'wordcloud')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    function draw(words) {
      wordcloud.selectAll('text')
        .data(words)
        .enter()
        .append('text')
        .attr('class', d => d.color === '#FFFFFF' ? 'word word-white' : 'word')
        .style('font-size', d => `${d.size}px`)
        .style('font-family', d => d.font)
        .style('fill', d => d.color)
        .attr('text-anchor', 'middle')
        .attr('transform', d => `translate(${[d.x, d.y]})rotate(${d.rotate})`)
        .text(d => d.text)
        .on('click', conceptDetailsClick);
    }

    let conceptDetailsClick = (d) => {
      this.conceptDetails(d.id, d.categoryName === 'Genes');
    };
  }

  render() {
    return (
      <div className="word-cloud" />
    );
  }
}

WordCloud.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concept: getConceptDetails(state),
    loadingKey: getLoadingKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    conceptDetailsRequest(data) {
      dispatch(conceptDetailsRequest(data));
    },
    loading() {
      dispatch(loading());
    },
    categoryAnalysisReset() {
      dispatch(conceptCategoryAnalysisReset());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WordCloud));
