import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { insertHTML } from '../../../Utils/Utils';
// Styles
import './index.scss';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
  startNewSearch: PropTypes.func,
};

const FindResultsForSection = (props) => {
  const {
    recommendations,
    startNewSearch,
  } = props;

  if (!recommendations || !recommendations.FIND_RESULTS_FOR) {
    return null;
  }

  return (
    <div className="find-results-for-wrapper">
      <div className="find-results-for">
        <div className="find-results-for-title">Find results for:</div>
        {
          recommendations.map((item, index) => (
            <div key={index} className="find-result-for-concept">
              <div
                role="presentation"
                onClick={() => {
                  startNewSearch({ plainValue: item.recommendationPhrase, exactValue: item.exactRecommendationPhrase });
                }}
                className="find-result-for-concept-name"
              >
                { item.recommendationPhrase }
              </div>
              {
                item.conceptInfo &&
                <div>
                  <div className="find-result-for-concept-st">
                    {item.conceptInfo.semanticTypes.map((type, ind, array) => (
                      <span key={ind}>{ type }{ ind !== (array.length - 1) && ', '}</span>
                    ))}
                  </div>
                  <div
                    className="find-result-for-concept-description"
                    dangerouslySetInnerHTML={insertHTML(item.conceptInfo.description)}
                  />
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

FindResultsForSection.propTypes = propTypes;

export default FindResultsForSection;
