import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {withRouter} from '../../../common/WithRouter/WithRouter';

// Components
import SetSelectionPanel from './SetSelectionPanel/SetSelectionPanel';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import SelectBackgroundSetPopup
  from '../../../common/SelectBackgroundSetComponent/SelectBackgroundSetComponent';

// Store
import { resetSearchConceptAction, setSelectedSetInfoAction } from './store/actions';
import {
  getSelectedSetInfoSelector,
} from './store/selectors';
import {switchShouldUpdateDataFlagAction} from '../../../Analytics/common/SetAnalysisMethodSelection/actions';
import {RELATIVE_PATH} from '../../../../constantsCommon';

// Styles
import '../../../Concept/ConceptSearchModal/styles.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  resetOnClose: PropTypes.bool,
  reset: PropTypes.func,
  formName: PropTypes.string,
  navigate: PropTypes.func,
  selectedSetInfo: PropTypes.object,
  switchShouldUpdateDataFlag: PropTypes.func,
  setSelectedSetInfo: PropTypes.func,
};

const EnrichmentAnalysisParamsModal = (props) => {
  const {
    isOpen,
    closeCb,
    resetOnClose,
    reset,
    navigate,
    setSelectedSetInfo,
    selectedSetInfo,
    switchShouldUpdateDataFlag,
  } = props;

  useEffect(() => reset, []);

  useEffect(() => {
    if (resetOnClose && !isOpen) {
      reset();
    }
  }, [resetOnClose, isOpen, reset]);

  const location = useLocation();
  const goToFindRelatedPage = () => {
    setSelectedSetInfo(selectedSetInfo);
    if (location && location.pathname === '/find-related') {
      switchShouldUpdateDataFlag(true);//todo
    } else {
      navigate(`${RELATIVE_PATH}/find-related`);
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="concepts-search-params">
        <SetSelectionPanel
        />
        <SelectBackgroundSetPopup
          closePopup={closeCb}
          showInline={true}
          applyFunction={goToFindRelatedPage}
          updateStateFunction={() => {}}
        />
      </div>
    </ModalComponent>
  );
};

EnrichmentAnalysisParamsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedSetInfo: getSelectedSetInfoSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reset() {
      dispatch(resetSearchConceptAction());
    },
    switchShouldUpdateDataFlag(data) {
      dispatch(switchShouldUpdateDataFlagAction(data));
    },
    setSelectedSetInfo(data) {
      dispatch(setSelectedSetInfoAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrichmentAnalysisParamsModal));
