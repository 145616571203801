import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Icons
import { FaInfo } from 'react-icons/fa';
// Components
import Loader from '../../common/Loader/Loader';
import ButtonCircle from '../../common/Buttons/ButtonCircle/ButtonCircle';
// Utils
import { drawScatterPlot } from './drawScatterPlot';
import { exportToPNG, exportToSVG } from '../../Utils/Utils';
// Styles
import './styles.scss';

const propTypes = {
  type: PropTypes.string,
  chartId: PropTypes.string,
  geneName: PropTypes.string,
  chartName: PropTypes.string,
  chartTitle: PropTypes.string,
  chartData: PropTypes.instanceOf(Array),
  backgroundGraph: PropTypes.string,
  filtersAreEmpty: PropTypes.bool,
  legendData: PropTypes.instanceOf(Object),
  measure: PropTypes.instanceOf(Object),
  yAxisText: PropTypes.string,
  info: PropTypes.string,
  customClassName: PropTypes.string,
  measureExtremes: PropTypes.instanceOf(Object),
  legend: PropTypes.func,
  data: PropTypes.instanceOf(Object),
};

const ScatterPlot = (props) => {
  const {
    type,
    chartId,
    geneName,
    chartName,
    chartTitle,
    data,
    chartData,
    legendData,
    backgroundGraph,
    filtersAreEmpty,
    measure,
    yAxisText,
    info,
    legend,
    customClassName,
    measureExtremes,
  } = props;

  const [drawing, setDrawing] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const getTitle = () => chartTitle;

  const getTitleForDownload = () => {
    if (geneName) return `${chartName}_${geneName}`;
    return chartName;
  };

  const drawChart = (showTitle) => {
    drawScatterPlot({
      data,
      chartData,
      type,
      id: chartId,
      showTitle,
      geneName,
      legendData,
      backgroundGraph,
      filtersAreEmpty,
      chartTitle: getTitle(),
      handleDrawEnding: () => setDrawing(false),
      measure,
      measureExtremes,
      topRightText: yAxisText,
    });
  };

  useEffect(() => {
    if (measure) {
      drawChart();
    }
  }, [chartData, measure]);

  const exportToPNGCb = () => {
    drawChart(true);
    const diagram = window.document.querySelector(`#${chartId} svg`);
    const title = getTitleForDownload();
    const name = `${title}.png`;
    if (diagram) {
      exportToPNG(diagram, name, false, false, type === 'CCLE', `${geneName}`);
    }
    drawChart();
  };

  const exportToSVGCb = () => {
    drawChart(true);
    const container = window.document.querySelector(`#${chartId}`);
    const title = getTitleForDownload();
    const name = `${title}.svg`;
    if (container) {
      exportToSVG(container, name, false, false, type === 'CCLE', `${geneName}`);
    }
    drawChart();
  };

  return (
    <>
      <div className={classNames('scatter-plot-block', customClassName)}>
        {
          !drawing &&
          <div className="bar-chart-controls">
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button bar-chart-controls__png"
                onClick={exportToPNGCb}
                title="Export to png"
              />
              PNG
            </div>
            <div className="bar-chart-export-button-wrapper">
              <div
                className="export-button"
                onClick={exportToSVGCb}
                title="Export to svg"
              />
              SVG
            </div>
            {
              info &&
              <ButtonCircle
                icon={<FaInfo size={12} color="#ffffff" />}
                onClick={() => { setShowInfo(!showInfo); }}
                customClassName="scatter-plot-btn"
              />
            }
          </div>
        }
        {
          showInfo &&
          <div className="scatter-plot-info">
            {info}
          </div>
        }
        <div id={chartId} className="scatter-plot" />
        <Loader isLoading={drawing} />
      </div>
      { !drawing && legendData && legend() }
    </>
  );
};

ScatterPlot.propTypes = propTypes;

export default ScatterPlot;
