import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Measure from 'react-measure';
import classnames from 'classnames';

// Utils
import { withRouter } from '../../common/WithRouter/WithRouter';
import { getScreenWidth, scrollToTop } from '../../Utils/Utils';
import { buildSearchLink } from '../../Search/utils';
import { SearchSourceFilterEnum } from '../../Search/enums';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { RELATION_MAP_CONCEPTS_LIMIT } from '../../RelationMap/RelationMapPage/constants';
import { PERSONAL_TAB } from '../../Projects/ProjectsTabs/constants';
// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import InfoDialog from '../../Modals/InfoDialog/InfoDialog';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ExportTable from '../../common/ExportTable/ExportTable';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import DropdownMenuComponent from '../../common/DropdownMenuComponent/DropdownMenuComponent';
import SetResultPageAddConcepts from '../SetResultPageAddConcepts/SetResultPageAddConcepts';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
// Store
import {
  openAddLinkedConcepts,
  closeAddLinkedConcepts,
} from '../SetResultPageAddConcepts/actions';
import {
  clearAllSetsAction,
  selectSet,
  setShouldClearAllSetsFlagToFalseAction,
} from '../../Analytics/common/SetAnalysis/actions';
import {
  resetRelatedConceptsAction,
  resetCachedConceptIdsForCategoryAction,
} from '../../Search/store/actions';
import {
  getAvailableSetsListSelector,
  getAvailableSetsLoadingSelector,
} from '../../Sets/SetsList/selectors';
import { getOpenKey } from '../SetResultPageAddConcepts/selectors';
import { getAvailableSetsAction } from '../../Sets/SetsList/reducer';
import { saveAsSetRequestedAction } from '../../Sets/SaveAsSet/store/actions';
import * as ACTIONS from './actions';
import * as SELECTORS from './selectors';
// Styles
import './SetResultPage.css';

const propTypes = {
  loading: PropTypes.func,
  init: PropTypes.func,
  setACloseMenu: PropTypes.func,
  selectedContent: PropTypes.instanceOf(Array),
  content: PropTypes.instanceOf(Array),
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  resetSetResultPrimarySettings: PropTypes.func,
  fullSet: PropTypes.instanceOf(Array),
  setNewFullSet: PropTypes.func,
  availableUserSets: PropTypes.instanceOf(Array),
  getShortConceptsDetails: PropTypes.func,
  clearAllSets: PropTypes.func,
  selectSetForAnalysis: PropTypes.func,
  openAddLinkedConcepts: PropTypes.func,
  openConfirmAddLinkedConceptPopup: PropTypes.func,
  closeConfirmAddLinkedConceptPopup: PropTypes.func,
  resetRelatedConcepts: PropTypes.func,
  resetCachedConceptIdsForCategory: PropTypes.func,
  setAOpenMenu: PropTypes.func,
  setBCloseMenu: PropTypes.func,
  setBOpenMenu: PropTypes.func,
  setCCloseMenu: PropTypes.func,
  setCOpenMenu: PropTypes.func,
  conceptIds: PropTypes.instanceOf(Array),
  pairOfConcepts: PropTypes.instanceOf(Object),
  conceptAndCategory: PropTypes.instanceOf(Object),
  sorting: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  loadingKey: PropTypes.bool,
  setAOpenKey: PropTypes.bool,
  setBOpenKey: PropTypes.bool,
  setCOpenKey: PropTypes.bool,
  addLinkedConceptsOpenKey: PropTypes.bool,
  showConfirmAddConceptPopup: PropTypes.bool,
  setName: PropTypes.string,
  setId: PropTypes.string,
  previousSetId: PropTypes.string,
  currentSetId: PropTypes.string,
  setPreviousSetIdForSetResult: PropTypes.func,
  setCurrentSetIdForSetResult: PropTypes.func,
  setSorting: PropTypes.func,
  saveColumnAsSet: PropTypes.func,
  fetchSimpleSets: PropTypes.func,
  setShouldClearAllSetsFlagToFalse: PropTypes.func,
  navigate: PropTypes.func,
  simpleSetsLoading: PropTypes.bool,
  projectId: PropTypes.string,
  triggerExportingAllToCSV: PropTypes.func,
};

class SetResultPage extends React.Component {
  static SIDE_MARGIN = 25;
  static COLUMN_ACTIONS = {
    filter: 'filter',
    save: 'save',
    edit: 'edit',
    remove: 'remove',
  };
  static COLUMN_NAME_MAP = {
    setANode: 'nodeA',
    setBNode: 'nodeB',
    setCNode: 'nodeC',
  };

  state = {
    relationMapWarning: false,
  };

  componentDidMount() {
    const {
      previousSetId,
      currentSetId,
      fetchSimpleSets,
    } = this.props;

    if (currentSetId !== previousSetId || !currentSetId) {
      this.props.loading(true);
      this.props.init();
    }
    this.props.loading(true);
    fetchSimpleSets();
    scrollToTop('body');
  }

  componentWillUnmount() {
    const {
      currentSetId,
      setPreviousSetIdForSetResult,
      setCurrentSetIdForSetResult,
    } = this.props;

    setPreviousSetIdForSetResult(currentSetId);
    setCurrentSetIdForSetResult(null);
  }

  exportAllToCSV = (link, columns) => {
    const {
      triggerExportingAllToCSV,
    } = this.props;
    triggerExportingAllToCSV({
      columns,
      downloadLink: link,
    });
  };

  handlePageClick = (pageNumber) => {
    this.props.loading(true);
    this.props.init({
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  showHideAllConcepts = () => {
    this.props.loading(true);
    this.props.init({
      params: {
        size: this.props.totalPages === 1 ? 20 : this.props.totalItems,
        page: 0,
      },
    });
  };

  serverSortHandler = () => {
    this.props.loading(true);
    this.props.init({
      params: {
        size: this.props.totalItems === this.props.content.length ? this.props.totalItems : 20,
        page: this.props.pageNumber,
      },
    });
  };

  showOnMindmap = () => {
    const { projectId = PERSONAL_TAB, selectedContent } = this.props;
    const conceptIds = [];

    selectedContent.forEach((tableRow) => {
      ['setANode', 'setBNode', 'setCNode'].forEach((key) => {
        if (tableRow[key] && tableRow[key].id) {
          conceptIds.push({
            conceptId: tableRow[key].id,
            name: tableRow[key].name,
          });
        }
      });
    });

    if (conceptIds.length > RELATION_MAP_CONCEPTS_LIMIT) {
      this.setState({ relationMapWarning: true });
    } else {
      localStorage.setItem('uniqConcepts', JSON.stringify(conceptIds));
      window.open(`${RELATIVE_PATH}/relation-map/${projectId}/new`, '_blank');
    }
  };

  dropdownCb = (value, column, setId, projectId) => {
    if (value in SetResultPage.COLUMN_ACTIONS) {
      if (value === SetResultPage.COLUMN_ACTIONS.save) {
        this.saveAsSet(column, setId, projectId);
      }
      if (value === SetResultPage.COLUMN_ACTIONS.edit) {
        this.editSet(setId);
      }
      if (value === SetResultPage.COLUMN_ACTIONS.remove) {
        this.removeSet(column);
      }
    } else {
      this.analyseColumnConceptsWithSavedSet(value, column);
    }
  };

  removeSet = (column) => {
    this.props.resetSetResultPrimarySettings();
    let cutFullSet = this.props.fullSet.map((fullSetRow) => {
      delete fullSetRow[SetResultPage.COLUMN_NAME_MAP[column]]; //eslint-disable-line
      return fullSetRow;
    });

    if (SetResultPage.COLUMN_NAME_MAP[column] === 'nodeB' && this.props.fullSet[0].nodeC) {
      cutFullSet = cutFullSet.map(fullSetRow => (
        {
          nodeA: fullSetRow.nodeA,
          nodeB: fullSetRow.nodeC,
        }
      ));
    }

    if (SetResultPage.COLUMN_NAME_MAP[column] === 'nodeA' && this.props.fullSet[0].nodeC) {
      cutFullSet = cutFullSet.map(fullSetRow => (
        {
          nodeA: fullSetRow.nodeB,
          nodeB: fullSetRow.nodeC,
        }
      ));
    } else if (SetResultPage.COLUMN_NAME_MAP[column] === 'nodeA' && this.props.fullSet[0].nodeB) {
      cutFullSet = cutFullSet.map(fullSetRow => (
        {
          nodeA: fullSetRow.nodeB,
        }
      ));
    }

    this.props.setNewFullSet(cutFullSet);
    this.props.init();
  };

  editSet = (setId) => {
    if (setId) {
      this.props.navigate(`${RELATIVE_PATH}/sets/edit/${setId}`);
    }
  };

  // Save column as new set
  saveAsSet = (column, setId, projectId) => {
    this.props.loading(true);
    if (setId) {
      this.props.saveColumnAsSet({ setId, projectId });
    } else {
      /* In case of set is not saved already */
      const setFromColumn = this.props.fullSet.map(row => row[SetResultPage.COLUMN_NAME_MAP[column]]);
      this.props.getShortConceptsDetails({
        concepts: setFromColumn,
        postAction: 'saveAsSet',
      });
    }
  };

  analyseColumnConceptsWithSavedSet = (savedSetId, column) => {
    this.props.loading(true);

    const selectedSet = this.props.availableUserSets.filter(list => list.id === savedSetId);
    const setFromColumn = this.props.fullSet.map(row => row[SetResultPage.COLUMN_NAME_MAP[column]]);

    this.props.clearAllSets();
    this.props.setShouldClearAllSetsFlagToFalse();
    this.props.getShortConceptsDetails({
      name: column,
      concepts: setFromColumn,
      postAction: 'analyze',
    });

    if (selectedSet.length > 0) {
      /* init set for analytics page */
      this.props.selectSetForAnalysis({
        /* Id of set */
        id: 1,
        /* set itself */
        selectedSets: [selectedSet[0]],
      });
    }
  };

  handleAddNewConceptClick = () => {
    const {
      fullSet,
      openAddLinkedConcepts, //eslint-disable-line
      openConfirmAddLinkedConceptPopup,
    } = this.props;
    if (fullSet[0].nodeC) {
      openConfirmAddLinkedConceptPopup();
    } else {
      openAddLinkedConcepts();
    }
  };

  handleConfirmAddNewConceptPopup = () => {
    this.props.closeConfirmAddLinkedConceptPopup();
    this.props.openAddLinkedConcepts();
  };

  triggerNewSearch = (params = {}) => {
    const {
      navigate,
      resetRelatedConcepts,
      resetCachedConceptIdsForCategory,
    } = this.props;

    const {
      formValue,
      database,
      plainSearch,
      exactSearch,
    } = params;

    const link = buildSearchLink({
      plainValue: formValue || plainSearch,
      exactValue: exactSearch,
      ...(database && { publicationSource: SearchSourceFilterEnum.DATABASE }),
    });

    resetRelatedConcepts();
    resetCachedConceptIdsForCategory();
    navigate(link);
  };

  getSetsForMenu = () => {
    const {
      setId,
      availableUserSets,
      simpleSetsLoading,
    } = this.props;
    const setsForMenu = [{ name: 'current column only' }];
    if (!availableUserSets.length || simpleSetsLoading) return setsForMenu;
    const updatedSets = availableUserSets.filter(set => set.id !== setId).map(set => ({
      id: set.id,
      name: set.name,
    }));
    return [...setsForMenu, ...updatedSets];
  };

  actions = {
    checkAll: ACTIONS.checkAll,
    checkItem: ACTIONS.checkItem,
    invertSelection: ACTIONS.invertSelection,
    removeSelected: ACTIONS.removeSelected,
  };

  dropDownAActions = {
    menuClose: this.props.setACloseMenu,
    menuOpen: this.props.setAOpenMenu,
    select: this.dropdownCb,
  };

  dropDownBActions = {
    menuClose: this.props.setBCloseMenu,
    menuOpen: this.props.setBOpenMenu,
    select: this.dropdownCb,
  };

  dropDownCActions = {
    menuClose: this.props.setCCloseMenu,
    menuOpen: this.props.setCOpenMenu,
    select: this.dropdownCb,
  };

  showTooltipCell = ({ id, name }, rowIndex) => {
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  render() {
    const {
      conceptIds,
      pairOfConcepts,
      conceptAndCategory,
      content,
      selectedContent,
      sorting: { sortBy, sortDirection },
      setName,
      setId,
      projectId,
      loadingKey,
      error,
      pageNumber,
      totalPages,
      setAOpenKey,
      setBOpenKey,
      setCOpenKey,
      fullSet,
      addLinkedConceptsOpenKey,
      showConfirmAddConceptPopup,
      closeConfirmAddLinkedConceptPopup,
      simpleSetsLoading,
    } = this.props;
    const { relationMapWarning } = this.state;

    const loading = loadingKey || simpleSetsLoading;
    const setsForMenu = this.getSetsForMenu();

    const setMenuItems = [
      {
        name: 'Save set as',
        action: SetResultPage.COLUMN_ACTIONS.save,
      },
      {
        name: 'Remove set',
        action: SetResultPage.COLUMN_ACTIONS.remove,
      },
      {
        name: 'Perform set analysis',
        sub: setsForMenu,
      }];

    const screenWidth = getScreenWidth();

    const tableWidth = screenWidth - SetResultPage.SIDE_MARGIN * 2; //eslint-disable-line
    let columns = [];

    const tableSettingForSetResult = [
      {
        label: setName || 'Setname',
        dataKey: 'setANode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const _setName = setName || 'Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          const name = `A: ${_setName}`;
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  disableRemove={true}
                  savedSetId={setId}
                  savedProjectId={projectId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setAOpenKey}
                  actions={this.dropDownAActions}
                  toggleName={name}
                  isOnSetResultPage={true}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
      },
      {
        dataKey: 'setBNode',
        disableSort: true,
        width: tableWidth / 6,
      },
      {
        dataKey: 'setCNode',
        disableSort: true,
        width: tableWidth / 6,
      },
      {
        label: 'References',
        dataKey: 'pubsA',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = rowData.setANode.name;
          const exactSearch = rowData.setANode.id;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        dataKey: 'pubsAB',
        disableSort: true,
        width: tableWidth / 8,
      },
      {
        dataKey: 'pubsABC',
        disableSort: true,
        width: tableWidth / 8,
      },
      {
        dataKey: 'pubsAC',
        disableSort: true,
        width: tableWidth / 8,
      }];

    const tableSettingForNeighborResult = [
      {
        label: 'A: Setname',
        dataKey: 'setANode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const name = 'A: Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  savedSetId={setId}
                  savedProjectId={projectId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setAOpenKey}
                  actions={this.dropDownAActions}
                  toggleName={name}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>

          );
        },
      },
      {
        label: 'B: Setname',
        dataKey: 'setBNode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const name = 'B: Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  savedSetId={setId}
                  savedProjectId={projectId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setBOpenKey}
                  actions={this.dropDownBActions}
                  toggleName={name}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
      },
      {
        label: 'C: Setname',
        dataKey: 'setCNode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const name = 'C: Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  savedProjectId={projectId}
                  savedSetId={setId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setCOpenKey}
                  actions={this.dropDownCActions}
                  toggleName={name}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>

          );
        },
      },
      {
        label: 'References -A&B-',
        dataKey: 'pubsAB',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References -A&B-</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setANode.name} AND ${rowData.setBNode.name}`;
          const exactSearch = `${rowData.setANode.id} AND ${rowData.setBNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        label: 'References -A&C-',
        dataKey: 'pubsAC',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References -A&C-</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setANode.name} AND ${rowData.setCNode.name}`;
          const exactSearch = `${rowData.setANode.id} AND ${rowData.setCNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        label: 'References -B&C-',
        dataKey: 'pubsBC',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References -B&C-</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setBNode.name} AND ${rowData.setCNode.name}`;
          const exactSearch = `${rowData.setBNode.id} AND ${rowData.setCNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        label: 'References -A,B&C-',
        dataKey: 'pubsABC',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References -A,B&C-</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setANode.name} AND ${rowData.setBNode.name} AND ${rowData.setCNode.name}`;
          const exactSearch = `${rowData.setANode.id} AND ${rowData.setBNode.id} AND ${rowData.setCNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      }];

    const tableSettingCategory = [
      {
        label: 'A: Setname',
        dataKey: 'setANode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const name = 'A: Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  savedSetId={setId}
                  savedProjectId={projectId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setAOpenKey}
                  actions={this.dropDownAActions}
                  toggleName={name}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>

          );
        },
      },
      {
        label: 'B: Setname',
        dataKey: 'setBNode',
        disableSort: true,
        width: tableWidth / 6,
        exportCSV: true,
        cellRenderer: ({ rowData, dataKey, rowIndex }) => (
          rowData[dataKey].name ? this.showTooltipCell(rowData[dataKey], rowIndex) : null
        ),
        headerRenderer: ({ dataKey }) => {
          const name = 'B: Setname';
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}.name` && sortDirection === 'ASC',
          });
          return (
            <div className="header-container">
              <div className="set-result-custom-header header-section header-section-left">
                <DropdownMenuComponent
                  savedSetId={setId}
                  savedProjectId={projectId}
                  setResultColumn={dataKey}
                  items={setMenuItems}
                  openKey={setBOpenKey}
                  actions={this.dropDownBActions}
                  toggleName={name}
                  btn={true}
                  btnClass="button"
                />
              </div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}.name`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>

          );
        },
      },
      {
        dataKey: 'setCNode',
        disableSort: true,
        width: tableWidth / 6,
      },
      {
        label: 'Database Entries',
        dataKey: 'dbPubs',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">Database Entries</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setANode.name} AND ${rowData.setBNode.name}`;
          const exactSearch = `${rowData.setANode.id} AND ${rowData.setBNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch, database: true });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        label: 'References',
        dataKey: 'pubsAB',
        disableSort: true,
        width: tableWidth / 8,
        exportCSV: true,
        headerRenderer: ({ dataKey }) => {
          const arrowUpClassName = classnames({
            'arrow-element': true,
            'arrow-up': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'DESC',
          });
          const arrowDownClassName = classnames({
            'arrow-element': true,
            'arrow-down': true,
            'active': sortBy === `${dataKey}` && sortDirection === 'ASC',
          });

          return (
            <div
              className="header-container"
            >
              <div className="header-section header-section-left">References</div>
              <div
                className="header-section header-section-right"
                onClick={
                  () => {
                    this.props.setSorting({ sortBy: `${dataKey}`, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                    this.serverSortHandler();
                  }
                }
              >
                <i className={arrowUpClassName} />
                <i className={arrowDownClassName} />
              </div>
            </div>
          );
        },
        cellRenderer: ({ rowData, dataKey }) => {
          const formValue = `${rowData.setANode.name} AND ${rowData.setBNode.name}`;
          const exactSearch = `${rowData.setANode.id} AND ${rowData.setBNode.id}`;
          return (
            <span
              role="presentation"
              className="link"
              onClick={() => {
                if (rowData[dataKey] > 0) {
                  this.triggerNewSearch({ formValue, exactSearch });
                }
              }}
              title={rowData[dataKey]}
            >
              {rowData[dataKey]}
            </span>
          );
        },
      },
      {
        dataKey: 'pubsABC',
        disableSort: true,
        width: tableWidth / 8,
      },
      {
        dataKey: 'pubsAC',
        disableSort: true,
        width: tableWidth / 8,
      }];

    const tableSettings = {
      width: tableWidth,
      headerHeight: 60,
      rowHeight: 40,
      sortBy,
      sortDirection,
    };
    if ((conceptIds.length > 0) || (fullSet.length && fullSet[0].nodeA && !fullSet[0].nodeB && !fullSet[0].nodeC)) {
      columns = tableSettingForSetResult;
    } else if ((pairOfConcepts.conceptA && pairOfConcepts.conceptB) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && fullSet[0].nodeC)) {
      columns = tableSettingForNeighborResult;
    } else if ((conceptAndCategory.conceptId && conceptAndCategory.semanticTypeId) ||
      (fullSet.length && fullSet[0].nodeA && fullSet[0].nodeB && !fullSet[0].nodeC)) {
      columns = tableSettingCategory;
    }

    return (
      <div className="set-result-page">
        <ConfirmationDialog
          onConfirm={this.handleConfirmAddNewConceptPopup}
          onCancel={closeConfirmAddLinkedConceptPopup}
          isOpen={showConfirmAddConceptPopup}
          closeCb={closeConfirmAddLinkedConceptPopup}
          text="Are you sure you want to remove the first column to add a new one?"
        />
        {
          !loading &&
          <div className="set-result-page-content">
            <div className="set-result-page-title">
              <div className="set-result-custom-header set-result-custom-header-button">
                <button
                  type="button"
                  onClick={this.handleAddNewConceptClick}
                  className="button button-primary set-result__button"
                >
                  <i className="fa fa-plus mr-5" />
                  Add linked concepts
                </button>
              </div>
              {
                totalPages > 1 &&
                <div className="set-result-page-pagination">
                  <div className="paginationContainer">
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              }
              <div className="set-result-custom-header set-result-custom-header-button">
                <ExportTable
                  content={content}
                  columns={columns}
                  backendCall={(link) => { this.exportAllToCSV(link, columns); }}
                  showExportSelected={true}
                  selectedItems={selectedContent}
                  fileName="set_results"
                />
                <div className="set-result-relation-map-action">
                  <button type="button" disabled={!selectedContent.length} onClick={this.showOnMindmap} className="btn">
                    <div className="relation-map-icon" />
                  </button>
                </div>
              </div>
            </div>
            {
              !error && !loading &&
              <Measure offset={true}>
                {
                  ({ measureRef, contentRect }) => (
                    <div className="set-result-table-container" ref={measureRef}>
                      {
                        !!contentRect.offset.width &&
                        <div className="set-result-table-container-padding">
                          <SimpleTable
                            settings={tableSettings}
                            data={content}
                            width={contentRect.offset.width - 50}
                            manageable={true}
                            onlySelect={true}
                            sortAction={ACTIONS.sort}
                            actions={this.actions}
                            serverSortAction={this.serverSortHandler}
                            columns={columns}
                          />
                        </div>
                      }
                      {
                        addLinkedConceptsOpenKey && <SetResultPageAddConcepts />
                      }
                    </div>
                  )}
              </Measure>
            }
            {addLinkedConceptsOpenKey && <div className="setResultOverlay" />}
            <Error show={!!error} error={error} />
          </div>
        }
        <Loader isLoading={loading && !error} />
        <InfoDialog
          isOpen={relationMapWarning}
          closeCb={() => { this.setState({ relationMapWarning: false }); }}
          text={`Maximum amount of concepts on map is ${RELATION_MAP_CONCEPTS_LIMIT}.`}
          confirmBtnText="Ok"
        />
      </div>
    );
  }
}

SetResultPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    pageNumber: SELECTORS.getPageNumber(state),
    totalPages: SELECTORS.getTotalPages(state),
    totalItems: SELECTORS.getTotalItems(state),
    content: SELECTORS.getPage(state),
    selectedContent: SELECTORS.getSelectedItems(state),
    conceptIds: SELECTORS.getConceptsIds(state),
    pairOfConcepts: SELECTORS.getPairOfConcepts(state),
    conceptAndCategory: SELECTORS.getConceptAndCategory(state),
    sorting: SELECTORS.getSorting(state),
    setName: SELECTORS.getSetName(state),
    setId: SELECTORS.getSetId(state),
    projectId: SELECTORS.getProjectId(state),
    loadingKey: SELECTORS.getLoadingKey(state),
    error: SELECTORS.getError(state),
    fullSet: SELECTORS.getFullSet(state),
    setAOpenKey: SELECTORS.getSetAOpenKey(state),
    setBOpenKey: SELECTORS.getSetBOpenKey(state),
    setCOpenKey: SELECTORS.getSetCOpenKey(state),
    availableUserSets: getAvailableSetsListSelector(state),
    addLinkedConceptsOpenKey: getOpenKey(state),
    showConfirmAddConceptPopup: SELECTORS.getShowConfirmAddConceptPopup(state),
    currentSetId: SELECTORS.getCurrentSetId(state),
    previousSetId: SELECTORS.getPreviousSetId(state),
    simpleSetsLoading: getAvailableSetsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShouldClearAllSetsFlagToFalse() {
      dispatch(setShouldClearAllSetsFlagToFalseAction());
    },
    fetchSimpleSets() {
      dispatch(getAvailableSetsAction());
    },
    saveColumnAsSet(data) {
      dispatch(ACTIONS.saveColumnAsSetAction(data));
    },
    setSorting(sortingParams) {
      dispatch(ACTIONS.sort(sortingParams));
    },
    triggerExportingAllToCSV(columns) {
      dispatch(ACTIONS.exportAllToCSVAction(columns));
    },
    setPreviousSetIdForSetResult(data) {
      dispatch(ACTIONS.setPreviousSetIdForSetResultAction(data));
    },
    setCurrentSetIdForSetResult(data) {
      dispatch(ACTIONS.setCurrentSetIdForSetResultAction(data));
    },
    init(data) {
      dispatch(ACTIONS.initSetResult(data));
    },
    loading(data) {
      dispatch(ACTIONS.loading(data));
    },
    setResultReset() {
      dispatch(ACTIONS.setResultReset());
    },
    setAOpenMenu() {
      dispatch(ACTIONS.setAOpenMenu());
    },
    setACloseMenu() {
      dispatch(ACTIONS.setACloseMenu());
    },
    setBOpenMenu() {
      dispatch(ACTIONS.setBOpenMenu());
    },
    setBCloseMenu() {
      dispatch(ACTIONS.setBCloseMenu());
    },
    setCOpenMenu() {
      dispatch(ACTIONS.setCOpenMenu());
    },
    setCCloseMenu() {
      dispatch(ACTIONS.setCCloseMenu());
    },
    selectSetForAnalysis(data) {
      dispatch(selectSet(data));
    },
    saveNewSet(data) {
      dispatch(saveAsSetRequestedAction(data));
    },
    clearAllSets() {
      dispatch(clearAllSetsAction());
    },
    getShortConceptsDetails(data) {
      dispatch(ACTIONS.getShortConceptsDetails(data));
    },
    resetSetResultPrimarySettings() {
      dispatch(ACTIONS.resetSetResultPrimarySettings());
    },
    setNewFullSet(data) {
      dispatch(ACTIONS.setNewFullSet(data));
    },
    openAddLinkedConcepts() {
      dispatch(openAddLinkedConcepts());
    },
    closeAddLinkedConcepts() {
      dispatch(closeAddLinkedConcepts());
    },
    resetRelatedConcepts() {
      dispatch(resetRelatedConceptsAction());
    },
    resetCachedConceptIdsForCategory() {
      dispatch(resetCachedConceptIdsForCategoryAction());
    },
    openConfirmAddLinkedConceptPopup() {
      dispatch(ACTIONS.toggleConfirmAddLinkedConceptPopup(true));
    },
    closeConfirmAddLinkedConceptPopup() {
      dispatch(ACTIONS.toggleConfirmAddLinkedConceptPopup(false));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SetResultPage));
