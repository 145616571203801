import isEmpty from 'lodash.isempty';
import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { history } from '../../../../index';
import {
  PATH_DETAILS_REQUESTED,
  PATH_DETAILS_SUCCEEDED,
  PATH_DETAILS_FAILED,
} from './constants';
import {
  getPath,
} from './selectors';

function* details() {
  try {
    const post = yield select(getPath);

    if (isEmpty(post)) {
      history.replace(`${RELATIVE_PATH}/research`);
    } else {
      const response = yield call(Api.getPathDetails, { post });
      yield put({ type: PATH_DETAILS_SUCCEEDED, data: response.data });
    }
  } catch (e) {
    yield put({ type: PATH_DETAILS_FAILED, message: e.message });
  }
}

function* pathDetailsSaga() {
  yield takeEvery(PATH_DETAILS_REQUESTED, details);
}

export default pathDetailsSaga;
