import { takeLatest } from 'redux-saga/effects';

import {
  getTargetCandidatesPairsDataAction,
  exportTargetCandidatesPairsDataAction,
} from '../reducer';

import { getTargetCandidatesPairsDataWorker } from './workers/getTargetCandidatesPairsDataWorker';
import { exportTargetCandidatesPairsDataWorker } from './workers/exportTargetCandidatesPairsDataWorker';

function* workflowsSaga() {
  yield takeLatest(getTargetCandidatesPairsDataAction, getTargetCandidatesPairsDataWorker);
  yield takeLatest(exportTargetCandidatesPairsDataAction, exportTargetCandidatesPairsDataWorker);
}

export default workflowsSaga;
