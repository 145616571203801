export const LINK_COLOR = '#e0e0e0';
export const RELATION_MAP_CONCEPTS_LIMIT = 400;
export const ABSTRACT_PREDICATE_NAME = 'Abstract co-occurrence';
export const SENTENCE_PREDICATE_NAME = 'Sentence co-occurrence';

export const CreateSetModalInitialValues = {
  show: false,
  config: null,
};

export const IntermediateConceptsInitialValues = {
  show: false,
  ids: null,
};

export const DefaultProjectInfoData = {
  name: 'Project Name',
  tags: [],
  description: '',
};

export const RelationDetailsModalInitialValues = {
  show: false,
  config: null,
};

export const ConfirmDeleteInitialValues = {
  show: false,
  config: null,
};

export const EmptyData = {
  nodes: [],
  links: [],
};

export const LayoutAlgorhitms = {
  DEFAULT: 'DEFAULT',
  COOCCURRENCE: 'COOCCURRENCE',
  SEMANTIC_CATEGORIES: 'SEMANTIC_CATEGORIES',
  SHORT_TEXTS: 'SHORT_TEXTS',
};

export const RelationNumberMetricEnum = {
  TRIPLES: 'TRIPLES',
  COOCCURRENCE: 'COOCCURRENCE',
  CLOSELY_RELATED: 'CLOSELY_RELATED',
};

export const DefaultRelationMapOptions = {
  layout: {
    algorithm: LayoutAlgorhitms.DEFAULT,
    showArrows: false,
    proportionalLines: false,
  },
  nodeColors: null,
  hideLabels: false,
  hideSource: false,
  hideBelowCount: { hide: false, count: null },
  hideRelationTypes: false,
  relationNumberMetric: null,
};

export const RelationMapShapes = {
  CIRCLE: 'CIRCLE',
  SQUARE: 'SQUARE',
  RHOMB: 'RHOMB',
};

export const ColorShapesInitialValues = {
  show: false,
  config: null,
};

export const RelationMapShapesOptions = [
  {
    value: 'CIRCLE',
    label: 'Circle',
  },
  {
    value: 'SQUARE',
    label: 'Square',
  },
  {
    value: 'RHOMB',
    label: 'Rhomb',
  },
];
