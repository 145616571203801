import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Store
import {
  conceptDetailsRequest,
  loading,
  conceptCategoryAnalysisReset,
} from '../ConceptDetails/actions';
import {
  getConceptDetails,
  getLoadingKey,
} from '../ConceptDetails/selectors';
// Utils
import { RELATIVE_PATH } from '../../../constantsCommon';
import { generateNumber } from '../../Utils/Utils';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Styles
import './CollapsibleTreeDiagram.css';

const propTypes = {
  loading: PropTypes.func,
  categoryAnalysisReset: PropTypes.func,
  conceptDetailsRequest: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  height: PropTypes.number,
  navigate: PropTypes.func,
};

class CollapsibleTreeDiagram extends React.Component {
  static DEFAULT_DIAGRAM_WIDTH = 600;
  static DEFAULT_DIAGRAM_HEIGHT = 600;

  conceptDetails = (id) => {
    this.props.loading();
    this.props.categoryAnalysisReset();
    this.props.navigate(`${RELATIVE_PATH}/concept-details/${id}`);
    this.props.conceptDetailsRequest(id);
  };

  componentWillUnmount() {
    window.d3.select('#tree-container').selectAll('*')
      .remove();
  }

  componentDidMount() {
    /* Set uid field in case if both parent and child have same concepts */
    const arrayOfUniqueId = [];
    const { data } = this.props;
    this.setUniqueNumber(data, arrayOfUniqueId);
    data.children = data.children.map((child) => {
      if (child.children && child.children instanceof Array) {
        child.children = child.children.map(ch => ( // eslint-disable-line
          this.setUniqueNumber(ch, arrayOfUniqueId)
        ));
      }
      return this.setUniqueNumber(child, arrayOfUniqueId);
    });

    this.initDiagram(data);
  }

  setUniqueNumber = (obj = {}, alreadyUsedValues = []) => {
    const number = generateNumber();
    if (alreadyUsedValues.indexOf(number) === -1) {
      alreadyUsedValues.push(number);
      /* Cache the original id */
      obj.originalId = obj.id; // eslint-disable-line
      obj.id = number; // eslint-disable-line
      return obj;
    }
    return this.setUniqueNumber(obj, alreadyUsedValues);
  };

  initDiagram = (diagramData) => {
    const treeData = diagramData;
    let maxLabelLength = 0;
    let selectedNode = null;
    let draggingNode = null;
    const panSpeed = 200;
    const panBoundary = 20; // Within 20px from edges will pan when dragging.
    let panTimer;
    let i = 0;
    const duration = 750;
    let root;
    let nodes;
    let links;
    let dragStarted;
    let dragListener;
    let domNode;
    let relCoords;
    let svgGroup;
    let zoomListener;
    let updateTempConnector;

    const viewerWidth = this.props.width || CollapsibleTreeDiagram.DEFAULT_DIAGRAM_WIDTH;
    const viewerHeight = this.props.height || CollapsibleTreeDiagram.DEFAULT_DIAGRAM_HEIGHT;

    let tree = window.d3.layout.tree()
      .size([viewerHeight, viewerWidth]);

    // define a window.d3 diagonal projection for use by the node paths later on.
    const diagonal = window.d3.svg.diagonal()
      .projection(function(d) { // eslint-disable-line
        const x = d.y;
        const y = d.x;
        return [x, y];
      });

    // A recursive helper function for performing some setup by walking through all nodes
    function visit(parent, visitFn, childrenFn) {
      if (!parent) return;

      visitFn(parent);

      const children = childrenFn(parent);
      if (children) {
        const count = children.length;
        for (let i = 0; i < count; i++) { // eslint-disable-line
          visit(children[i], visitFn, childrenFn);
        }
      }
    }

    // Call visit function to establish maxLabelLength
    visit(treeData, function(d) { // eslint-disable-line
      // totalNodes++;
      maxLabelLength = Math.max(d.name.length, maxLabelLength);

    }, function(d) { // eslint-disable-line
      return d.children && d.children.length > 0 ? d.children : null;
    });


    // sort the tree according to the node names

    function sortTree() {
      tree.sort(function(a, b) { // eslint-disable-line
        return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
      });
    }
    // Sort the tree initially incase the JSON isn't in a sorted order.
    sortTree();

    // TODO: Pan function, can be better implemented.

    function pan(domNode, direction) { // eslint-disable-line
      const speed = panSpeed;
      let translateX;
      let translateY;
      let scale;
      if (panTimer) {
        clearTimeout(panTimer);
        const translateCoords = window.d3.transform(svgGroup.attr('transform'));
        if (direction === 'left' || direction === 'right') {
          translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
          translateY = translateCoords.translate[1]; // eslint-disable-line
        } else if (direction === 'up' || direction === 'down') {
          translateX = translateCoords.translate[0]; // eslint-disable-line
          translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
        }
        scale = zoomListener.scale();
        svgGroup.transition().attr('transform', `translate(${translateX},${translateY})scale(${scale})`);
        window.d3.select(domNode).select('g.node')
          .attr('transform', `translate(${translateX},${translateY})`);
        zoomListener.scale(zoomListener.scale());
        zoomListener.translate([translateX, translateY]);
        panTimer = setTimeout(() => {
          pan(domNode, direction);
        }, 50);
      }
    }

    // Define the zoom function for the zoomable tree
    function zoom() {
      svgGroup.attr('transform', `translate(${window.d3.event.translate})scale(${window.d3.event.scale})`);
    }

    // define the zoomListener which calls the zoom function on the 'zoom' event constrained within the scaleExtents
    zoomListener = window.d3.behavior.zoom().scaleExtent([0.8, 1.5])
      .on('zoom', zoom);

    function initiateDrag(d, domNode) { // eslint-disable-line
      draggingNode = d;
      window.d3.select(domNode).select('#tree-container .ghostCircle')
        .attr('pointer-events', 'none');
      window.d3.selectAll('#tree-container .ghostCircle').attr('class', 'ghostCircle show');
      window.d3.select(domNode).attr('class', 'node activeDrag');

      svgGroup.selectAll('g.node').sort(function(a) { //eslint-disable-line
        if (a.id !== draggingNode.id) return 1; // a is not the hovered element, send 'a' to the back
        return -1; // a is the hovered element, bring 'a' to the front
      });
      // if nodes has children, remove the links and nodes
      if (nodes.length > 1) {
        // remove link paths
        links = tree.links(nodes);
        svgGroup.selectAll('path.link')
          .data(links, function(d) { //eslint-disable-line
            return d.target.id;
          })
          .remove();
        // remove child nodes
        svgGroup.selectAll('g.node')
          .data(nodes, function(d) { //eslint-disable-line
            return d.id;
          })
          .filter(function(d) { //eslint-disable-line
            if (d.id === draggingNode.id) {
              return false;
            }
            return true;
          })
          .remove();
      }

      // remove parent link
      tree.links(tree.nodes(draggingNode.parent));
      svgGroup.selectAll('path.link').filter(function(d) { //eslint-disable-line
        return d.target.id === draggingNode.id;
      })
        .remove();

      dragStarted = null;
    }

    // define the baseSvg, attaching a class for styling and the zoomListener
    const baseSvg = window.d3.select('#tree-container').append('svg')
      .attr('width', viewerWidth)
      .attr('height', viewerHeight)
      .attr('class', 'overlay')
      .call(zoomListener);


    // Define the drag listeners for drag/drop behaviour of nodes.
    dragListener = window.d3.behavior.drag() //eslint-disable-line
      .on('dragstart', function(d) { //eslint-disable-line
        if (d === root) {
          return;
        }
        dragStarted = true;
        nodes = tree.nodes(d);
        window.d3.event.sourceEvent.stopPropagation();
        // it's important that we suppress the mouseover event on the node being dragged. Otherwise it will absorb the mouseover event and the underlying node will not detect it window.d3.select(this).attr('pointer-events', 'none');
      })
      .on('drag', function(d) { //eslint-disable-line
        if (d === root) {
          return;
        }
        if (dragStarted) {
          domNode = this;
          initiateDrag(d, domNode);
        }

        // get coords of mouseEvent relative to svg container to allow for panning
        relCoords = window.d3.mouse(window.jQuery('#tree-container svg').get(0));
        if (relCoords[0] < panBoundary) {
          panTimer = true;
          pan(this, 'left');
        } else if (relCoords[0] > (window.jQuery('#tree-container svg').width() - panBoundary)) {
          panTimer = true;
          pan(this, 'right');
        } else if (relCoords[1] < panBoundary) {
          panTimer = true;
          pan(this, 'up');
        } else if (relCoords[1] > (window.jQuery('#tree-container svg').height() - panBoundary)) {
          panTimer = true;
          pan(this, 'down');
        } else {
          try {
            clearTimeout(panTimer);
          } catch (e) {
            console.log(e);
          }
        }

        d.x0 += window.d3.event.dy; //eslint-disable-line
        d.y0 += window.d3.event.dx; //eslint-disable-line
        const node = window.d3.select(this);
        node.attr('transform', `translate(${d.y0},${d.x0})`);
        updateTempConnector();
      })
      .on('dragend', function(d) { //eslint-disable-line
        if (d === root) {
          return;
        }
        domNode = this;
        if (selectedNode) {
          // now remove the element from the parent, and insert it into the new elements children
          const index = draggingNode.parent.children.indexOf(draggingNode);
          if (index > -1) {
            draggingNode.parent.children.splice(index, 1);
          }
          if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
            if (typeof selectedNode.children !== 'undefined') {
              selectedNode.children.push(draggingNode);
            } else {
              selectedNode._children.push(draggingNode);
            }
          } else {
            selectedNode.children = [];
            selectedNode.children.push(draggingNode);
          }
          // Make sure that the node being added to is expanded so user can see added node is correctly moved
          expand(selectedNode); //eslint-disable-line
          sortTree();
          endDrag(); //eslint-disable-line
        } else {
          endDrag(); //eslint-disable-line
        }
      });

    function endDrag() {
      selectedNode = null;
      window.d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
      window.d3.select(domNode).attr('class', 'node');
      // now restore the mouseover event or we won't be able to drag a 2nd time
      window.d3.select(domNode).select('.ghostCircle')
        .attr('pointer-events', '');
      updateTempConnector();
      if (draggingNode !== null) {
        update(root); //eslint-disable-line
        centerNode(draggingNode); //eslint-disable-line
        draggingNode = null;
      }
    }

    function expand(d) {
      if (d._children) {
        d.children = d._children; //eslint-disable-line
        d.children.forEach(expand);
        d._children = null; //eslint-disable-line
      }
    }

    const overCircle = function(d) { //eslint-disable-line
      selectedNode = d;
      updateTempConnector();
    };
    const outCircle = function() { //eslint-disable-line
      selectedNode = null;
      updateTempConnector();
    };

    // Function to update the temporary connector indicating dragging affiliation
    updateTempConnector = function() { //eslint-disable-line
      let data = [];
      if (draggingNode !== null && selectedNode !== null) {
        // have to flip the source coordinates since we did this for the existing connectors on the original tree
        data = [{
          source: {
            x: selectedNode.y0,
            y: selectedNode.x0,
          },
          target: {
            x: draggingNode.y0,
            y: draggingNode.x0,
          },
        }];
      }
      const link = svgGroup.selectAll('.templink').data(data);

      link.enter().append('path')
        .attr('class', 'templink')
        .attr('d', window.d3.svg.diagonal())
        .attr('pointer-events', 'none');

      link.attr('d', window.d3.svg.diagonal());

      link.exit().remove();
    };

    // Function to center node when clicked/dropped so node doesn't get lost when collapsing/moving with large amount of children.

    function centerNode(source) {
      const scale = zoomListener.scale();
      let x = -source.y0;
      let y = -source.x0;
      x = x * scale + viewerWidth / 2; //eslint-disable-line
      y = y * scale + viewerHeight / 2; //eslint-disable-line
      window.d3.select('#tree-container g').transition()
        .duration(duration)
        .attr('transform', `translate(${x},${y})scale(${scale})`);
      zoomListener.scale(scale);
      zoomListener.translate([x, y]);
    }

    // Toggle children function

    function toggleChildren(d) {
      if (d.children) {
        d._children = d.children; //eslint-disable-line
        d.children = null; //eslint-disable-line
      } else if (d._children) {
        d.children = d._children; //eslint-disable-line
        d._children = null; //eslint-disable-line
      }
      return d;
    }

    // Toggle children on click.
    const click = (d) => {
      if (window.d3.event.defaultPrevented) {
        return;
      } // click suppressed
      if ((d.hasOwnProperty('parent') && d.parent.hasOwnProperty('parent'))) { //eslint-disable-line
        this.conceptDetails(d.originalId);
      } else {
        d = toggleChildren(d); //eslint-disable-line
        update(d); //eslint-disable-line
        centerNode(d);
      }
    };

    function hasSuchParent(treeElement, parentName) {
      if (treeElement.parent) {
        if (treeElement.parent.name === parentName) {
          return true;
        }
        return hasSuchParent(treeElement.parent, parentName);
      }
      return false;
    }

    function update(source) {
      // Compute the new height, function counts total children of root node and sets tree height accordingly.
      // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
      // This makes the layout more consistent.
      const levelWidth = [1];
      const childCount = function(level, n) { //eslint-disable-line

        if (n.children && n.children.length > 0) {
          if (levelWidth.length <= level + 1) levelWidth.push(0);

          levelWidth[level + 1] += n.children.length;
          n.children.forEach(function(d) { //eslint-disable-line
            childCount(level + 1, d);
          });
        }
      };
      childCount(0, root);
      const nodesPixels = tree.nodes(root).length > 6 ? 25 : 60; // 25/60 pixels per line
      const newHeight = window.d3.max(levelWidth) * nodesPixels;
      tree = tree.size([newHeight, viewerWidth]);
      // Compute the new tree layout.
      nodes = tree.nodes(root).reverse();
      links = tree.links(nodes);

      // Set widths between levels based on maxLabelLength.
      nodes.forEach(function(d) { //eslint-disable-line
        // d.y = (d.depth * (maxLabelLength * 5)); //maxLabelLength * 10px
        // alternatively to keep a fixed scale one can set a fixed depth per level
        // Normalize for fixed-depth by commenting out below line
        d.y = (d.depth * 90); //eslint-disable-line

        if (d.name === 'is a' || hasSuchParent(d, 'is a')) { //eslint-disable-line
          d.y = -d.y; //eslint-disable-line
        }
      });

      // Update the nodes…
      const node = svgGroup.selectAll('g.node')
        .data(nodes, function(d) { //eslint-disable-line
          return d.id || (d.id = ++i); //eslint-disable-line
        });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node.enter().append('g')
        .call(dragListener)
        .attr('class', 'node')
        .attr('transform', function() { //eslint-disable-line
          return `translate(${source.y0},${source.x0})`;
        })
        .on('click', click);

      nodeEnter.append('circle')
        .attr('class', 'nodeCircle')
        .attr('r', 0)
        .style('fill', function(d) { //eslint-disable-line
          return d._children ? 'lightsteelblue' : '#fff';
        });

      nodeEnter.append('text')
        .attr('x', function(d) { //eslint-disable-line
          if ((d.name === 'is a' || hasSuchParent(d, 'is a')) && (d.children || d._children)) {
            return d.name.length * 4;
          }
          if ((d.name === 'is parent of' || hasSuchParent(d, 'is parent of')) && (d.children || d._children)) {
            return d.name.length * 4;
          }
          if (!d.parent) {
            return d.name.length * 4;
          }
          return d.children || d._children || hasSuchParent(d, 'is a') ? -10 : 10;
        })
        .attr('dy', function(d) { //eslint-disable-line
          return d.children || d._children ? '-0.5em' : '0.35em';
        })
        .attr('class', 'nodeText')
        .attr('text-anchor', function(d) { //eslint-disable-line
          return d.children || d._children || hasSuchParent(d, 'is a') ? 'end' : 'start';
        })
        .text(function(d) { //eslint-disable-line
          return d.name;
        })
        .style('fill-opacity', 0);

      // phantom node to give us mouseover in a radius around it
      nodeEnter.append('circle')
        .attr('class', 'ghostCircle')
        .attr('r', 30)
        .attr('opacity', 0.2) // change this to zero to hide the target area
        .style('fill', 'red')
        .attr('pointer-events', 'mouseover')
        .on('mouseover', function(node) { //eslint-disable-line
          overCircle(node);
        })
        .on('mouseout', function(node) { //eslint-disable-line
          outCircle(node);
        });

      // Update the text to reflect whether node has children or not.
      node.select('text')
        .attr('x', function(d) { //eslint-disable-line
          if ((d.name === 'is a' || hasSuchParent(d, 'is a')) && (d.children || d._children)) {
            return d.name.length * 4;
          }
          if ((d.name === 'is parent of' || hasSuchParent(d, 'is parent of')) && (d.children || d._children)) {
            return d.name.length * 4;
          }
          if (!d.parent) {
            return d.name.length * 4;
          }
          return d.children || d._children || hasSuchParent(d, 'is a') ? -10 : 10;
        })
        .attr('dy', function(d) { //eslint-disable-line
          return d.children || d._children ? '-0.5em' : '0.35em';
        })
        .attr('text-anchor', function(d) { //eslint-disable-line
          return d.children || d._children || hasSuchParent(d, 'is a') ? 'end' : 'start';
        })
        .text(function(d) { //eslint-disable-line
          return d.name;
        });

      // Change the circle fill depending on whether it has children and is collapsed
      node.select('circle.nodeCircle')
        .attr('r', 4.5)
        .style('fill', function(d) { //eslint-disable-line
          return d._children ? 'lightsteelblue' : '#fff';
        });

      // Transition nodes to their new position.
      const nodeUpdate = node.transition()
        .duration(duration)
        .attr('transform', function(d) { //eslint-disable-line
          const x = d.y;
          const y = d.x;
          return `translate(${x},${y})`;
        });

      // Fade the text in
      nodeUpdate.select('text')
        .style('fill-opacity', 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node.exit().transition()
        .duration(duration)
        .attr('transform', function(d) { //eslint-disable-line
          return `translate(${source.y},${source.x})`;
        })
        .remove();

      nodeExit.select('circle')
        .attr('r', 0);

      nodeExit.select('text')
        .style('fill-opacity', 0);

      // Update the links…
      const link = svgGroup.selectAll('path.link')
        .data(links, function(d) { //eslint-disable-line
          return d.target.id;
        });

      // Enter any new links at the parent's previous position.
      link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function(d) { //eslint-disable-line
          const o = {
            x: source.x0,
            y: source.y0,
          };
          return diagonal({
            source: o,
            target: o,
          });
        });

      // Transition links to their new position.
      link.transition()
        .duration(duration)
        .attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(duration)
        .attr('d', function(d) { //eslint-disable-line
          const o = {
            x: source.x,
            y: source.y,
          };
          return diagonal({
            source: o,
            target: o,
          });
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function(d) { //eslint-disable-line
        d.x0 = d.x; //eslint-disable-line
        d.y0 = d.y; //eslint-disable-line
      });
    }

    // Append a group which holds all nodes and which the zoom Listener can act upon.
    svgGroup = baseSvg.append('g');

    // Define the root
    root = treeData;
    root.x0 = viewerHeight / 2;
    root.y0 = 0;

    // Layout the tree initially and center on the root node.
    update(root);
    centerNode(root);
  };

  render() {
    return (
      <div id="tree-container" tabIndex="1" /> // eslint-disable-line
    );
  }
}

CollapsibleTreeDiagram.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concept: getConceptDetails(state),
    loadingKey: getLoadingKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    conceptDetailsRequest(data) {
      dispatch(conceptDetailsRequest(data));
    },
    loading() {
      dispatch(loading());
    },
    categoryAnalysisReset() {
      dispatch(conceptCategoryAnalysisReset());
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollapsibleTreeDiagram));
