import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getConceptToConceptIndirectAction = createAction('@@c2cIndirect/GET_C2C_INDIRECT_CONCEPTS');
export const putConceptToConceptDataAction = createAction('@@c2cIndirect/PUT_C2C_DATA');
export const toggleConceptToConceptLoaderAction = createAction('@@c2cIndirect/C2C_LOADING_TOGGLE');
export const throwConceptToConceptErrorAction = createAction('@@c2cIndirect/C2C_ERROR');
export const resetSelectDiseaseAction = createAction('@@c2cIndirect/RESET_ERROR');

const initialState = fromJS({
  data: {
    intermediates: null,
    score: null,
    concept1: null,
    concept2: null,
  },
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [putConceptToConceptDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('error', () => false)
        .update('loading', () => false),
    [toggleConceptToConceptLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [throwConceptToConceptErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [resetSelectDiseaseAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
