import React, { useContext } from 'react';
import { useNavigate, useLocation, useParams, useNavigationType, UNSAFE_NavigationContext } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const { block } = useContext(UNSAFE_NavigationContext).navigator;

    const navigate = useNavigate();
    const location = useLocation();
    const locationParams = useParams();
    const navigationType = useNavigationType();
    const blocker = block;

    return (
      <Component
        blocker={blocker}
        navigate={navigate}
        location={location}
        locationParams={locationParams}
        navigationType={navigationType}
        {...props}
      />
    );
  };
  
  return Wrapper;
};
