import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
};

const HeatmapHeaderBtn = (props) => {
  const {
    text,
    disabled,
    onClick,
    hide,
  } = props;

  if (hide) {
    return null;
  }

  return (
    <button
      className="button button-secondary mr-5"
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

HeatmapHeaderBtn.propTypes = propTypes;

export default HeatmapHeaderBtn;
